import { gql } from "apollo-boost";

export const GENERATE_QUOTE_PDF = gql`
  mutation consumerGenerateQuotePdf($id: ID!) {
    consumerGenerateQuotePdf(bookingId: $id){
        pdf
    }
  }
`;

export const GET_BOOKING_SUMMARY_PDF = gql`
   query consumerGetBooking($bookingId: String!) {
    consumerGetBooking(bookingId: $bookingId) {
      bookingConfirmationUrl
    }
  }
`;
