import { DateTime as d } from "luxon";
import { DATE_TYPE, DateTimeFormat } from "../components/utils";

export const getLocalizedTaxSyntex = (country?: string) => {
  if (country === "United States") {
    return "Taxes & Fees";
  } else {
    return "VAT/TAX";
  }
}

export const getLocalizedBookingSyntex = (country?: string) => {
  if (country === "United States") {
    return "Reservation";
  } else {
    return "Booking";
  }
}


export const getLocalizedCancelBookingSyntex = (country?: string) => {
  if (country === "United States") {
    return "Cancel Reservation";
  } else {
    return "Cancel Booking";
  }
}

export const getLocalizedBookedSyntex = (country?: string) => {
  if (country === "United States") {
    return "Reserved";
  } else {
    return "Booked";
  }
}
export const getLocalizedOrganisationSyntex = (country?: string) => {
  if (country === "United States") {
    return "Location";
  } else {
    return "Organisation";
  }
}

export const getLocalizedRASyntex = (country?: string) => {
  if (country === "United States") {
    return "Contract";
  } else {
    return "Rental Agreement";
  }
}

export const getLocalizedVatNumberSyntex = (country?: string) => {
  if (country === "United States") {
    return "Tax number";
  } else {
    return "VAT number";
  }
}

export const getLocalizedInsuranceNumberSyntex = (country?: string) => {
  if (country === "United States") {
    return "Driver Insurance Number";
  } else {
    return "National Insurance Number";
  }
}

export const getLocalizedSundrySyntex = (country?: string) => {
  if (country === "United States") {
    return "Past Due";
  } else {
    return "Sundry";
  }
}

export const getLocalizedDateFormat = (
  country: string,
  date: string,
  dateType: string,
  timeZone?: string
) => {
  if (country === "United States") {
    if (dateType === DATE_TYPE.EXPANDED) {
      return d.fromISO(date).setZone(timeZone).toFormat(DateTimeFormat.US_DATE_EXPANDED);
    }
    return d.fromISO(date).setZone(timeZone).toFormat(DateTimeFormat.US_DATE_CONDENSED);
  } else {
    if (dateType === DATE_TYPE.EXPANDED) {
      return d.fromISO(date).setZone(timeZone).toFormat(DateTimeFormat.DEFAULT_DATE_EXPANDED);
    }
    return d.fromISO(date).setZone(timeZone).toFormat(DateTimeFormat.DEFAULT_DATE_CONDENSED);
  }
};

export const getLocalizedDateFormatString = (country: string, dateType: string) => {
  if (country === "United States") {
    if (dateType === DATE_TYPE.EXPANDED) {
      return DateTimeFormat.US_DATE_EXPANDED;
    }
    return DateTimeFormat.US_DATE_CONDENSED;
  } else {
    if (dateType === DATE_TYPE.EXPANDED) {
      return DateTimeFormat.DEFAULT_DATE_EXPANDED;
    }
    return DateTimeFormat.DEFAULT_DATE_CONDENSED;
  }
}


export const getDefaultCountryCode = (country?: string) => {
  if (country === "United States") {
    return "US";
  } else {
    return "GB";
  }
}