import React from "react"

export const LightUtility4x4_2SeaterSvg = {
  interior: {
    width:224,
    height:472,
    svg: (<><g>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="25.1" y1="31.7" x2="25.1" y2="166.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="25.1" y1="170.6" x2="25.1" y2="321"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="41.9" y1="194.2" x2="180.9" y2="194.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="25.1" y1="161.2" x2="196.7" y2="161.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="183.3" y1="194.2" x2="183.3" y2="194.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="26.5" y1="17.6" x2="26.5" y2="30.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.7" y1="17.6" x2="195.7" y2="30.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.7" y1="31" x2="196.7" y2="168.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.7" y1="170.5" x2="196.7" y2="339.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.8" y1="161.3" x2="188.8" y2="189.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="31.1" y1="186.5" x2="31.1" y2="330.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="190.5" y1="187.7" x2="190.6" y2="338.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="25.1" y1="161.2" x2="27.4" y2="168.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="27.9" y1="170.5" x2="33.5" y2="189.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="163.3" x2="25.1" y2="163.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.7" y1="163.3" x2="202" y2="163.3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M160.3,31.2c-0.6-2.7-2.9-4.5-5.6-4.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="160.3" y1="31.2" x2="188" y2="161.3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M67.4,26.7c-2.6,0-4.8,1.7-5.6,4.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="67.4" y1="26.7" x2="154.7" y2="26.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="61.8" y1="30.9" x2="33.5" y2="161.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="160.2" y1="30.8" x2="199.1" y2="30.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M202,33.5c0-1.5-1.2-2.7-2.7-2.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="161.1" x2="202" y2="166"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="134" x2="202" y2="161.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="33.5" x2="202" y2="134"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.1" y1="256" x2="202.1" y2="322.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="239.4" x2="202.1" y2="256"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="170.5" x2="202" y2="239.4"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M23,30.8c-1.5,0-2.8,1.3-2.8,2.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="23" y1="30.8" x2="61.8" y2="30.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="161.1" x2="20.2" y2="166"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="133.7" x2="20.2" y2="161.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="33.6" x2="20.2" y2="133.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.3" y1="255.7" x2="20.3" y2="322.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.3" y1="239.7" x2="20.3" y2="255.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="170.5" x2="20.3" y2="239.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M180,194.2c4.4,0.2,8.3-1.9,8.8-4.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M180,194.2c4.4,0.2,8.3-1.9,8.8-4.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M179.8,191.2c2.4,0,4.4-1.3,4.4-2.9c0,0,0-0.1,0-0.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="41.7" y1="191.1" x2="179.8" y2="191.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M33.5,189.5c0.4,2.8,4.3,4.9,8.8,4.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M38.2,188.3c0,1.6,2,2.8,4.4,2.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M206.3,25c0-4-3.3-7.3-7.3-7.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="23.2" y1="17.6" x2="198.9" y2="17.6"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.3" y1="25" x2="206.3" y2="32.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.1,34.4c1.2,0,2.2-1,2.2-2.2l0,0"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M201.9,32.4c0.1,1.1,1.1,1.9,2.2,1.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M199.1,23.3c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8
      c-1,0-1.8,0.8-1.8,1.8C197.3,22.5,198.1,23.3,199.1,23.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="26.5" y1="17.7" x2="26.5" y2="30.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M22.8,30.7c-1.5,0-2.7,1.2-2.7,2.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M23.2,17.6c-4,0-7.3,3.3-7.3,7.3v7.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M18,34.3c1.1,0,2-0.8,2.2-1.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M15.8,32.1C15.8,32.1,15.8,32.1,15.8,32.1c0,1.2,1,2.2,2.2,2.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M23,23.2c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8
      C21.2,22.4,22,23.2,23,23.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.8,128c0.7-0.6,1.1-1.4,1.1-2.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="134.5" x2="209.8" y2="128"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M210.9,44.3c0-1.8-0.8-3.3-2.1-4.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="34.3" x2="208.8" y2="40"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="210.9" y1="44.3" x2="210.9" y2="125.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="34.8" y1="294.8" x2="34.8" y2="379.3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M39.2,295.1c0,0,0-0.1,0-0.1c0-2.4-1-4.3-2.2-4.3c-1.2,0-2.2,1.9-2.2,4.3
      "/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M37,383.6c1.2,0,2.2-2,2.2-4.4c0,0,0-0.1,0-0.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M34.8,379.3c0,2.4,1,4.3,2.2,4.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39.1" y1="294.8" x2="39.1" y2="379.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="187.3" y1="294.8" x2="187.3" y2="379.3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M187.2,294.9c0-2.4-1-4.3-2.2-4.3c-1.2,0-2.2,1.9-2.2,4.3
      c0,0,0,0.1,0,0.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M185.1,383.6c1.2,0,2.2-1.9,2.2-4.3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M182.9,379.1C182.9,379.2,182.9,379.2,182.9,379.1c0,2.5,1,4.4,2.2,4.4"
      />
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="182.9" y1="294.8" x2="182.9" y2="379.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M11.6,125.7c0,0.9,0.4,1.7,1.1,2.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.4" y1="134.5" x2="12.6" y2="128"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M13.6,40c-1.3,1.1-2,2.7-2,4.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.4" y1="34.3" x2="13.6" y2="40"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="11.6" y1="44.3" x2="11.6" y2="125.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M188.8,169.8c0-0.1,0-0.1,0-0.2c0-1.5-2.4-2.6-5.3-2.6"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="188.8" y1="169.8" x2="184.1" y2="188.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="183.6" y1="167" x2="143.3" y2="167"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="126.9" y1="167" x2="71.7" y2="167"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M152.5,23.7c0-1.5-1.2-2.8-2.8-2.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="152.5" y1="23.7" x2="152.5" y2="26.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M71.5,21c-1.6,0-2.9,1.3-2.9,2.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149.7" y1="21" x2="71.5" y2="21"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="68.6" y1="23.9" x2="68.6" y2="26.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="193.8" y1="29.1" x2="162.1" y2="29.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M193.8,29.1c1.1,0,1.2,1,1.2,1.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M162.1,29.1c-1.1,0-1.2,1-1.2,1.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M193,29.1v-0.3c0-0.6-1.7-2.2-3.4-2.2c-1.7,0-3.4,1.6-3.4,2.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="186.2" y1="29.1" x2="186.2" y2="28.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="28.2" y1="28.9" x2="59.8" y2="28.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M28.2,28.9c-1.1,0-1.2,1-1.2,1.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M59.8,28.9c1.1,0,1.2,1,1.2,1.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M28.9,28.9v-0.3c0-0.6,1.7-2.2,3.4-2.2c1.7,0,3.4,1.6,3.4,2.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="35.7" y1="29" x2="35.7" y2="28.6"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="120.3" y1="165.8" x2="116.8" y2="165.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M185.8,165.8c0.6,0,1.1-0.5,1.1-1.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="185.8" y1="162.3" x2="116.8" y2="162.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="181.3" y1="167" x2="181.3" y2="165.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="177.9" y1="165.8" x2="177.9" y2="167"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M126.8,166.7c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M128.8,165.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.6,0.4-1.6,0.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="145.9" y1="167.2" x2="145.8" y2="168.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="145.8" y1="168.2" x2="156" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="145.9" y1="167.4" x2="156.4" y2="168.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="128.3" y1="167.5" x2="145.8" y2="168.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="128.5" y1="165.8" x2="145.9" y2="167.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="174.9" y1="168.8" x2="158.1" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="156.1" y1="168.8" x2="138.3" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="174.9" y1="169.7" x2="160" y2="169.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="157.7" y1="169.7" x2="138.2" y2="169.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="156.1" y1="169" x2="156.4" y2="168.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="156.1" y1="169" x2="158.2" y2="169.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="156.4" y1="168.1" x2="158.4" y2="169"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="158.2" y1="169.9" x2="160" y2="169.9"/>
    <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="158.4,169 160,169 160,169.9 	"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="174.9" y1="169.7" x2="174.9" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="138.2" y1="169.7" x2="138.2" y2="168.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="177.5" y1="165.7" x2="177.5" y2="164"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="174.2" y1="164.1" x2="174.2" y2="165.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M177.5,164c0-0.7-0.5-1.2-1.2-1.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M175.5,162.8c-0.7,0-1.3,0.6-1.3,1.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="176.3" y1="162.8" x2="175.5" y2="162.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="181.7" y1="166.7" x2="181.3" y2="166.7"/>
    <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="177.9,166.7 173.8,166.7 173.8,165.8 	"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="181.7" y1="166.7" x2="181.7" y2="165.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="119.9" y1="167" x2="119.9" y2="165.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="123.3" y1="165.8" x2="123.3" y2="167"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="119.4" y1="166.7" x2="119.9" y2="166.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="123.3" y1="166.7" x2="126.8" y2="166.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="119.4" y1="166.7" x2="119.4" y2="165.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="185.8" y1="165.8" x2="120.2" y2="165.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="186.9" y1="163.4" x2="186.9" y2="164.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M186.9,163.5C186.9,163.4,186.9,163.4,186.9,163.5c0-0.6-0.5-1.1-1.1-1.1
      "/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.6" y1="163.1" x2="190.6" y2="187.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="26.8" y1="170.7" x2="30.9" y2="185.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M115.6,164.7c0,0.6,0.5,1.1,1.1,1.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="115.6" y1="163.5" x2="115.6" y2="164.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M116.7,162.4c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="127" y1="165.8" x2="127" y2="164"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="123.6" y1="164.1" x2="123.6" y2="165.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M127,164c0-0.7-0.5-1.2-1.2-1.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M125,162.8c-0.7,0-1.3,0.6-1.3,1.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="125.8" y1="162.8" x2="125" y2="162.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="194.6" y1="166.2" x2="193.6" y2="166.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="190.2" y1="166.2" x2="186.8" y2="166.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M192.7,170l1.5-6.2c0-0.5-0.5-0.9-1.6-0.9c-0.9,0-1.6-0.2-1.9,0.9
      l-1.2,5.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="190.4" y1="170.8" x2="191.9" y2="170.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M189.5,169.3c-0.2,1,0.1,1.4,0.8,1.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M192.7,170c-0.2,0.7-0.3,0.9-0.8,0.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="34.6" y1="166.2" x2="37.9" y2="166.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M211,299.8c0-1.6-1-3-2.4-3.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M208.4,402.1c1.6-0.5,2.6-2,2.6-3.6"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.1" y1="322.1" x2="202.1" y2="405.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.3" y1="322.3" x2="20.4" y2="405.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="211" y1="299.5" x2="211" y2="398.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.4" y1="402.1" x2="202.3" y2="405.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.7" y1="296.2" x2="202.2" y2="293.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M11.6,398.6c0,1.7,1.1,3.1,2.6,3.6"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="11.6" y1="299.6" x2="11.6" y2="398.6"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M14,296.4c-1.5,0.7-2.3,2.1-2.4,3.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="14.2" y1="402.2" x2="20.3" y2="405.6"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="14" y1="296.4" x2="20.3" y2="293.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="193.3" y1="186.6" x2="193.3" y2="337.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="29.2" y1="186.2" x2="29.2" y2="325.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M193.3,186.6c0-4.1-2.7-4.7-2.7,0.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="17" y1="255.5" x2="20.3" y2="255.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M126.7,167.5c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0l16.7,0.1l0.8-0.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="126.8" y1="166.7" x2="126.7" y2="167.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="129.1" y1="165.8" x2="129.1" y2="167.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M55.1,166.7c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M57.1,165.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.6,0.4-1.6,0.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="74.2" y1="167.2" x2="74.1" y2="168.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="74.1" y1="168.1" x2="84.3" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="74.2" y1="167.4" x2="84.7" y2="168.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="56.6" y1="167.5" x2="74.1" y2="168.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="56.8" y1="165.8" x2="74.2" y2="167.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="103.2" y1="168.8" x2="86.4" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="84.5" y1="168.8" x2="66.6" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="103.3" y1="169.7" x2="88.3" y2="169.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="86" y1="169.7" x2="66.5" y2="169.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="84.4" y1="169" x2="84.7" y2="168.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="84.4" y1="169" x2="86.5" y2="169.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="84.7" y1="168.1" x2="86.7" y2="169"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="86.5" y1="169.9" x2="88.3" y2="169.9"/>
    <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="86.7,169 88.3,169 88.3,169.9 	"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="103.3" y1="169.7" x2="103.3" y2="168.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="66.5" y1="169.7" x2="66.5" y2="168.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M55,167.5c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0l16.7,0.1l0.8-0.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="55.1" y1="166.7" x2="55" y2="167.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="57.4" y1="165.8" x2="57.4" y2="167.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="55" y1="167" x2="38.8" y2="167"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.4" y1="165.9" x2="36.9" y2="165.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M105.9,165.9c0.6,0,1.1-0.5,1.1-1.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="106" y1="162.5" x2="36.9" y2="162.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="101.4" y1="167.1" x2="101.4" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="98" y1="165.9" x2="98" y2="167.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="97.7" y1="165.9" x2="97.7" y2="164.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="94.3" y1="164.2" x2="94.3" y2="165.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M97.7,164.1c0-0.7-0.5-1.2-1.2-1.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M95.6,162.9c-0.7,0-1.3,0.6-1.3,1.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="96.4" y1="162.9" x2="95.6" y2="162.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="101.9" y1="166.8" x2="101.4" y2="166.8"/>
    <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="98,166.8 93.9,166.8 93.9,165.9 	"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="101.9" y1="166.8" x2="101.9" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40" y1="167.1" x2="40" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="43.4" y1="165.9" x2="43.4" y2="167.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39.6" y1="166.8" x2="40" y2="166.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="43.4" y1="166.8" x2="47.3" y2="166.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="47.5" y1="166.8" x2="47.5" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39.6" y1="166.8" x2="39.6" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.9" y1="165.9" x2="58.5" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="55.8" y1="165.9" x2="48" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="48.1" y1="165.9" x2="40.4" y2="165.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="107" y1="163.5" x2="107" y2="164.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M107,163.6C107,163.6,107,163.5,107,163.6c0-0.6-0.5-1.1-1.1-1.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M35.7,164.8c0,0.6,0.5,1.1,1.1,1.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="35.7" y1="163.6" x2="35.7" y2="164.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M36.8,162.5c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="47.1" y1="165.9" x2="47.1" y2="164.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="43.8" y1="164.2" x2="43.8" y2="165.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M47.1,164.1c0-0.7-0.5-1.2-1.2-1.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M45.1,162.9c-0.7,0-1.3,0.6-1.3,1.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="45.9" y1="162.9" x2="45.1" y2="162.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M29.3,139.7c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7
      c-1.5,0-2.7,1.2-2.7,2.7C26.6,138.5,27.8,139.7,29.3,139.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M29.3,138.5c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
      c-0.8,0-1.5,0.7-1.5,1.5C27.8,137.8,28.5,138.5,29.3,138.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M29.3,137.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
      c-0.4,0-0.7,0.3-0.7,0.7C28.7,137.4,29,137.7,29.3,137.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="27.6" y1="170.5" x2="10" y2="170.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M27.6,168.9c1.2,0,1.2,1.6,0,1.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M10.1,170.5c-1.2,0-1.2-1.6,0-1.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="18.8" y1="161.9" x2="18.8" y2="166"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="161.3" x2="19.3" y2="161.3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M19.4,161.3c-0.5,0-0.7,0.3-0.7,0.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="27.5" y1="168.9" x2="10.1" y2="168.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M27.2,168.8c-1.6-3.1-2.2-2.8-8.9-2.8c-7.2-0.1-6.6-0.2-8.3,2.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.5" y1="170.5" x2="213.2" y2="170.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M196.2,168.9c-1.2,0-1.2,1.6,0,1.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M213,170.5c1.2,0,1.2-1.6,0-1.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204.4" y1="161.9" x2="204.4" y2="166"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.9" y1="161.3" x2="203.8" y2="161.3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.7,161.3c0.5,0,0.7,0.3,0.7,0.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.6" y1="168.9" x2="213" y2="168.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M195.9,168.8c1.6-3.1,2.2-2.8,8.9-2.8c7.2-0.1,6.6-0.2,8.3,2.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M38.2,188.2C38.2,188.2,38.2,188.3,38.2,188.2c0,1.7,2,2.9,4.4,3"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M38.7,167c-2.9,0-5.2,1.2-5.2,2.6c0,0.1,0,0.1,0,0.2l4.7,18.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="26.6" y1="166.2" x2="28.7" y2="166.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="32.1" y1="166.2" x2="35.5" y2="166.2"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M29.6,170l-1.5-6.2c0-0.5,0.5-0.9,1.6-0.9c0.9,0,1.6-0.2,1.9,0.9l1.2,5.4
      "/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="32" y1="170.8" x2="30.4" y2="170.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M32.8,169.3c0.2,1-0.1,1.4-0.8,1.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M29.6,170c0.2,0.7,0.3,0.9,0.8,0.9"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M31.1,186.5c-0.2-3.5-1.9-2.3-1.9-0.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="266.1" x2="31.1" y2="266.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="190.5" y1="266.1" x2="202" y2="266.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M18.4,239.5c-0.8,0-1.4,0.6-1.4,1.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="239.5" x2="18.4" y2="239.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="17" y1="240.8" x2="17" y2="255.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="18.3" y1="240.7" x2="18.3" y2="250.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="240.7" x2="18.3" y2="240.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="18.3" y1="250.5" x2="20.3" y2="250.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="205.3" y1="255.5" x2="202" y2="255.5"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205.3,240.8c0-0.8-0.6-1.4-1.4-1.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.1" y1="239.5" x2="203.9" y2="239.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="205.3" y1="240.8" x2="205.3" y2="255.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204" y1="240.7" x2="204" y2="250.5"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.1" y1="240.7" x2="204" y2="240.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204" y1="250.5" x2="202" y2="250.5"/>
    <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="185.8,193.9 180.4,280.2 180.4,353.3 	"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="25.1" y1="320.8" x2="25.1" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.7" y1="339.5" x2="196.7" y2="425.6"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="31.1" y1="330.1" x2="31.1" y2="423"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="190.6" y1="338.3" x2="190.7" y2="423"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.1" y1="405.1" x2="202.2" y2="426.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.4" y1="405.2" x2="20.4" y2="426.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.4" y1="459.7" x2="74.3" y2="459.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M74.3,432.1l-2.8,1.9c-2.5,5.1-2.5,19.3,0,23.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="74.3" y1="459.7" x2="71.5" y2="457.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M147.4,432.1l2.8,1.9c2.5,5.1,2.5,19.3,0,23.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.4" y1="459.7" x2="150.2" y2="457.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="91.5" y1="432.1" x2="74.3" y2="432.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="110.8" y1="432.1" x2="91.5" y2="432.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="131.5" y1="432.1" x2="110.8" y2="432.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.4" y1="432.1" x2="131.5" y2="432.1"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M32,425.8v0.9c0,0.7-1.8,2.3-3.6,2.3c-1.8,0-3.6-1.7-3.5-2.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="24.8" y1="426.3" x2="24.8" y2="426.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="24.8" y1="426.3" x2="24.8" y2="425.7"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M199.6,426.5v0.4c0,0.7-1.8,2.3-3.6,2.3c-1.8,0-3.6-1.7-3.5-2.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="192.5" y1="426.5" x2="192.5" y2="426.9"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="199.6" y1="426.5" x2="199.6" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="192.5" y1="426.5" x2="192.5" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="134.5" y1="425.6" x2="131.3" y2="432.4"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="88.8" y1="425.9" x2="91.7" y2="432.1"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="30.9" y1="422.8" x2="190.9" y2="422.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M190.6,425.8c1.5,0,2.7-1.2,2.7-2.7"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="193.3" y1="337.5" x2="193.3" y2="423.3"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="134.3" y1="425.8" x2="190.7" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="88.7" y1="425.8" x2="134.3" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="32.1" y1="425.8" x2="88.7" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="24.4" y1="425.8" x2="32.1" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.1" y1="425.8" x2="24.4" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="199.4" y1="425.8" x2="202.4" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="192.4" y1="425.8" x2="199.4" y2="425.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="190.7" y1="425.8" x2="192.4" y2="425.8"/>
    <path fill="none" stroke="#231F20" stroke-width="0.5" d="M29.2,423c0,1.6,1.3,2.8,3,2.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="29.2" y1="325.7" x2="29.2" y2="423"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="180.4" y1="353.2" x2="180.4" y2="422.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" x1="42" y1="357.6" x2="42" y2="422.4"/>
    <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="37.1,193.4 42,280.7 42,357.7 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M57.6,203.6h35.5c0,0,5.2,1,7.3,10.1l0.3,22.7c0,0-2.7,13-10,17h-32
      c0,0-6.5-5-9.4-16.9v-23.2C49.4,213.4,52.1,204.8,57.6,203.6z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M101.5,249.7l-0.8-1.4h-4.5l-3,3.3l-3.5,1.9l-14.8-0.2v0l-15.1,0.5
      l-3.2-2.5l-2.4-3.2l-5.1,0.3l-1.4,2.5l-0.8,6.6v20.1l0.6,5.2l1.9,3.6l2.3,2.3l3.6,1.8h5h2.2l0.2,1.5h12.2v0h12.2l0.2-1.5h2.2h5
      l3.6-1.8l2.3-2.3l1.9-3.6l0.6-5.2v-20.1l-0.6-5.3C102.2,251.3,101.9,250.4,101.5,249.7z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="74.9,276.7 59.7,276.7 59.7,290 62.5,293.2 74.9,293.2 
      87.3,293.2 90.1,290 90.1,276.7 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128.7,203.6h35.5c0,0,5.2,1,7.3,10.1l0.3,22.7c0,0-2.7,13-10,17h-32
      c0,0-6.5-5-9.4-16.9v-23.2C120.6,213.4,123.2,204.8,128.7,203.6z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M172.6,249.7l-0.8-1.4h-4.5l-3,3.3l-3.5,1.9l-14.8-0.2v0l-15.1,0.5
      l-3.2-2.5l-2.4-3.2l-5.1,0.3l-1.4,2.5l-0.8,6.6v20.1l0.6,5.2l1.9,3.6l2.3,2.3l3.6,1.8h5h2.2l0.2,1.5H146v0h12.2l0.2-1.5h2.2h5
      l3.6-1.8l2.3-2.3l1.9-3.6l0.6-5.2v-20.1l-0.6-5.3C173.3,251.3,173,250.4,172.6,249.7z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="146,276.7 130.8,276.7 130.8,290 133.6,293.2 146,293.2 
      158.4,293.2 161.2,290 161.2,276.7 	"/>
  </g></>)
  }, 
  exterior: {
    width:724,
    height:945,
    svg: (<><g id="left">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M237.5,619.5V509.7v0L227,420.4l-40.3-20.5h3.3c1.2,0,1.2-1.7,0-1.7h-1
        l0-0.1c-1.6-1-6.6-2.1-11.4-2.9l0.1,0l-3.6-1.8l0,0c0.4-0.6,0.2-1.6-0.7-2.1l-4.8-2.6c-0.8-0.4-1.9,0.1-2.2,0.7l0,0l-3-1.5l-1.1,0
        l-0.5-7.7c-0.1-1-1.2-1.9-2.2-1.9l0,0l-6.1-109.7v0c0-6.5-5.1-11.9-11.5-12.5l-4.1-4l-2.3,0l-1.2-1.6l-0.4-0.1v1.7l-2.3,0
        l-1.2-1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="237.5" y1="656" x2="237.5" y2="620.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M88.4,657.8l0.1,0.4l0.1,0h1.2v0v0.7h0.7l0,0v6.3c0,1,0.8,1.7,1.7,1.7
        c1,0,1.7-0.8,1.7-1.7c0,0,0,0,0,0l-0.1-6.3h0.6v-0.7v0h7.2l0,0.1v1.1c-0.1,0.6,1.8,2.3,3.5,2.3c1.8,0,3.6-1.6,3.6-2.3l0-1.1H120
        v0.1l0.8,3.5H99.3l-2.8,1.9c-2.5,5.1-2.5,19.3,0,23.8l2.8,1.9h73.1l2.8-1.9c2.5-4.5,2.5-18.7,0-23.8l-2.8-1.9h-18.8v-0.1l0.5-3.5
        l0.2,0h3v0.1c0,1.3,4.1,0.8,4.1,0v-0.1c0,0,76.2,0,76.2,0V656"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M130.2,250.7l-0.4-0.1v1.7l-2.3,0l-1.2-1.6l-0.4-0.1v1.7l-2.3,0
        l-1.2-1.6l-0.4-0.1v1.7l-2.3,0l-1.2-1.6l-0.4-0.1v1.7l-2.3,0l-1.2-1.6l-0.4-0.1v1.7l-2.3,0l-1.2-1.6l-0.4-0.1v1.7l-2.3,0l-1.2-1.6
        l-0.4-0.1v1.7l-2.3,0l-1.2-1.6l-0.4-0.1v1.7h-3.9l-4.2,1.8v5h-2v-11.3H80.2v2l0,0h-3.7v7.6l0,0l-4.6-0.6c-0.1,0-0.2,0-0.3,0
        c-2,0-3.7,1.7-3.7,3.7c0,2,1.7,3.7,3.7,3.7l0.2,0l5-0.6h0c0.5,0,0.9-0.1,1.3-0.3l0,0l4,13.9l0.1,0c-5.2-2.3-11-3.6-17-3.6
        c-23,0-41.6,18.6-41.6,41.6c0,21.9,16.9,39.8,38.4,41.5l0,0.3l0.9,23.3h0H37v1.4h25.8l0.8,20.7l-0.2,0.4v4.2h7l-0.1-1.2v1.2h-7.9"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M162,405.8v72.6c0,2.6,2.1,4.8,4.8,4.8h0h36.5c2.5,0,4.6-2.1,4.6-4.6
        V423c0-1.8-1-3.3-2.5-4.1l-35.4-17.5v-0.1c-0.9-0.5-1.8-0.8-2.8-0.8C164.3,400.6,162,402.9,162,405.8c0,0.2,0,0.4,0,0.5V405.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M164.6,482.5v-81.2l-0.1-0.1c-1.5,0.9-2.5,2.6-2.5,4.4
        c0,0.2,0,0.4,0,0.6l0-0.5v72.6C162.1,480.2,163.1,481.8,164.6,482.5L164.6,482.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227.3,614.6c4,0,7.3-3.3,7.3-7.3c0,0,0-0.1,0-0.1l0-83.8
        c0-0.1,0-0.2,0-0.3c0-4-3.3-7.3-7.3-7.3c-4,0-7.3,3.3-7.3,7.3v84.2l0-0.4c0,0.2,0,0.3,0,0.5C220,611.4,223.3,614.6,227.3,614.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M231.7,523.2c0,0,0-0.1,0-0.1c0-2.4-1.9-4.3-4.3-4.3
        c-2.4,0-4.3,1.9-4.3,4.3l-0.1,84.3c0,2.4,2,4.3,4.4,4.3c2.4,0,4.4-2,4.4-4.4c0,0,0-0.1,0-0.1L231.7,523.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M65.2,352.7c20.7,0,37.4-16.8,37.4-37.4c0-20.7-16.8-37.4-37.4-37.4
        c-20.7,0-37.4,16.8-37.4,37.4C27.8,335.9,44.5,352.7,65.2,352.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M65.2,621.6c23,0,41.6-18.6,41.6-41.6c0-23-18.6-41.6-41.6-41.6
        c-23,0-41.6,18.6-41.6,41.6C23.6,603,42.2,621.6,65.2,621.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M175.1,663.7l-2.8-1.9H99.3l-2.8,1.9c-2.5,5.1-2.5,19.3,0,23.8l2.8,1.9
        h73.1l2.8-1.9C177.7,683,177.7,668.8,175.1,663.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M189.9,398.2l-1.1-0.1c-3.3-2.1-19.9-4.3-22.3-4.1
        c-1,0.1-2.4,1.2-3.5,2.5h-1.8V396c0-0.5-0.1-0.6-0.4-0.6H157l0,0c-0.1-0.3-0.4-0.5-0.7-0.5h-6.8c-0.5,0-0.9,0.4-0.9,0.9v2.4
        c0,0.5,0.4,0.8,0.8,0.8h6.7c0.4,0,0.8-0.2,0.9-0.6l0,0h3.7c0.3,0,0.5-0.1,0.4-0.6v-0.4h1c-0.2,0.3-0.4,0.5-0.5,0.7
        c-1.2,0-1.2,1.7,0,1.7h28.2C191.2,399.8,191.1,398.2,189.9,398.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M127.1,258.3h6.8v-0.4c0-0.6-1.7-2.2-3.4-2.2c-1.7,0-3.4,1.6-3.4,2.2
        V258.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M103.7,258.3h6.8v-0.4c0-0.6-1.7-2.2-3.4-2.2c-1.7,0-3.4,1.6-3.4,2.2
        V258.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M161.5,372l-0.7,0.5c0,0-0.5,0.3-1.5,0.4h-0.1l-4.4-77.6l0.2-0.1
        c2.8,3.6,4.7,8.8,4.7,12.8L163,367l0,1.4l-0.4,1.6l-0.5,1.2L161.5,372"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M84.2,648.3l1.1,4.1l0,0.1c-0.5,0.8-1.3,1.6-1.8,2.2
        c-0.7,0.8-1.5,3.5-1.4,6.5v1.7h0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M70.4,519.9v0.3c0,6.5-1.2,10.7,7.4,13.1l1.5,0.1c1.1,0.7,2.3,1,4.4,1.6
        c4,1,10.8,3.3,14.7,4.9c3.9,1.6,14.2,6,15.9,16.8c1.6,10.8,1.2,34.9,0.3,42.1c-0.9,7.2-5.1,16-15.3,19.3
        c-10.2,3.3-14.1,4.2-17.7,4.8c-3.1,0.5-7.9,8.7-5.9,10"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="62.4,407.1 70.3,407.1 70.4,407.1 70.4,508.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M59.9,627.4l-0.6-2.5l-0.7,0.2c-1.2,0.3-1.6-1.5-0.5-1.8l6.5-1.8l0.6,0
        c23,0,41.6-18.6,41.6-41.6c0-23-18.6-41.6-41.6-41.6c-2.1,0-4.2,0.2-6.2,0.5l0.1-0.1l1.1-13.6l-15.4-0.9c-0.7,0-1.2-0.6-1.2-1.2
        v-3.4c0-0.7,0.5-1.2,1.2-1.3l16-2.1l0.1,0l0.3-4.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M82.5,663c1.2,0,1.2,1.7,0,1.7H72.1h0.1c-1.2,0-1.2-1.7,0-1.7l0.4,0V661
        c0-1.1,0.1-2.1,0.2-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M70.4,405.7v-33.6c0.3-4.5,2.9-6.1,6.4-6.9l0-0.1
        c-0.1-0.7,0-1.3,0.1-2.2c0.1-0.8,0.6-1.8,1.7-3.1c2.4-2.8,5.2-3.8,7.7-4.5L88,355c2.8-0.7,7.7-1.8,12.6-4.3
        c7.4-3.7,10.8-5.3,12.9-8c0.8-1.1,1.4-2.3,2-4c1.1-3.1,2.8-8.4,3.2-22.8c0.5-14.4-1.2-20.5-2-25.3c-0.5-2.6-0.9-3.9-2.6-5.9
        c-1-1.2-2.3-2.5-3.6-3.2c-3.2-1.7-9.5-5.7-11.7-6.9c-1.7-1-4.4-2.3-5.4-3.1c-0.6-0.5-1.1-1-1.4-1.6c-0.3-0.5-0.4-1.1-0.4-1.7
        l0.1-6.3l0.6-0.3v-13.7H80.2l0,2.1h-3.7v7.6l-4.5-0.6c-0.1,0-0.2,0-0.3,0c-2,0-3.7,1.7-3.7,3.7c0,2,1.7,3.7,3.7,3.7
        c0.1,0,0.1,0,0.2,0l4.9-0.6h0c0.4,0,0.9-0.1,1.2-0.3v0l4,13.9l0.3,0.1c14.4,6.5,24.4,21,24.4,37.9c0,23-18.6,41.6-41.6,41.6
        c-1.2,0-2.3,0-3.5-0.1l0.2,0.3l0.9,23.3H37v1.4h25.8l0.8,20.7l-0.2,0.4v4.2h7L70.4,405.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M136.3,468.5c0-0.5-0.4-1-1-1h-2.1c-0.5,0-0.9,0.4-0.9,0.9v16.2
        c0,0.5,0.4,1,1,1h2.2c0.5,0,0.9-0.4,0.9-0.9V468.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M138.5,287.1c0.1-0.2,0.4-0.2,1.1-0.2c0.6,0,0.9,0,1,0.3
        c0.8,2,0.8,5.9,0,8.3c-0.1,0.3-0.4,0.4-1,0.3c-0.6,0-1,0-1.1-0.2C137.8,293.2,137.8,289.4,138.5,287.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M70.2,515.1l-25.5,3.4c-0.7,0.1-1.2,0.7-1.2,1.3v3.4
        c0,0.7,0.5,1.2,1.2,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M44.7,524.4l25.4,1.4l0.2-0.1c-0.1-1.6,0-3.4,0-5.5v-5.1"/>
      <rect x="59.3" y="401.2" fill="none" stroke="#231F20" stroke-width="0.5" width="3" height="110.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="159.8" y1="387.8" x2="153.2" y2="268.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="145.3" y1="393.7" x2="145.3" y2="654.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="163.2" y1="387.9" x2="79.8" y2="387.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="237.5" y1="620.3" x2="237.5" y2="509.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="226.9" y1="420.4" x2="237.5" y2="509.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="213.3" y1="489" x2="81.4" y2="489"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="157.2" y1="658.2" x2="88.5" y2="658.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M147.6,271.6c-0.1-6.9-5.7-12.5-12.6-12.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="92.3" y1="259.1" x2="135" y2="259.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="148.6" y1="387.9" x2="147.6" y2="271.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="141.7" y1="261" x2="141.7" y2="256.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M153.2,268.7c-0.1-6.5-5.1-11.9-11.5-12.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="215.8" y1="658.2" x2="215.8" y2="418.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="213.3" y1="658.2" x2="213.3" y2="416.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70.3" y1="372.1" x2="70.3" y2="509.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="81.7" y1="393.8" x2="81.7" y2="489.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="226.9" y1="420.4" x2="186.6" y2="399.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="177.5" y1="395.2" x2="163.2" y2="387.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="227.2" y1="424.3" x2="179.5" y2="400"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="167.7,394 167.4,393.8 81.7,393.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.2,483.1c2.5,0,4.6-2.1,4.6-4.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M207.8,423c0-1.8-1-3.3-2.5-4.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="207.8" y1="423" x2="207.8" y2="478.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M169.9,401.3c-0.9-0.5-1.8-0.8-2.8-0.8c-2.9,0-5.2,2.3-5.2,5.2
        c0,0.2,0,0.4,0,0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="205.3" y1="418.9" x2="169.9" y2="401.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M162,478.3c0,2.6,2.1,4.8,4.8,4.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="162" y1="405.7" x2="162" y2="478.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.2" y1="483.1" x2="166.8" y2="483.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="92.3,261.5 92.3,247.8 80.2,247.8 80.2,261.5 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="80.2" y1="261.6" x2="92.3" y2="261.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="80.2,249.9 76.5,249.9 76.5,257.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="234.6" y1="523" x2="234.6" y2="607.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220" y1="523.1" x2="220" y2="607.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="231.7" y1="523.2" x2="231.7" y2="607.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="222.9" y1="523.1" x2="222.9" y2="607.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227.3,614.6c4,0,7.3-3.3,7.3-7.3c0,0,0-0.1,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M220,606.9c0,0.2,0,0.3,0,0.5c0,4,3.3,7.3,7.3,7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M234.7,523.4c0-0.1,0-0.2,0-0.3c0-4-3.3-7.3-7.3-7.3
        c-4,0-7.3,3.3-7.3,7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M231.7,523.3c0,0,0-0.1,0-0.1c0-2.4-1.9-4.3-4.3-4.3
        c-2.4,0-4.3,1.9-4.3,4.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227.3,611.7c2.4,0,4.4-2,4.4-4.4c0,0,0-0.1,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M223,607.4c0,2.4,2,4.3,4.4,4.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="165.6" y1="656" x2="165.6" y2="656"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M76.8,263.8c1.7-0.1,3-1.4,3-3.1c0-1.6-1.3-3-2.9-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M71.7,264.3c0.1,0,0.1,0,0.2,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M72,256.9c-0.1,0-0.2,0-0.3,0c-2,0-3.7,1.7-3.7,3.7c0,2,1.7,3.7,3.7,3.7
        "/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="76.8" y1="263.8" x2="71.9" y2="264.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="76.9" y1="257.6" x2="72" y2="256.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M76.7,262.2c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
        c-0.8,0-1.5,0.7-1.5,1.5C75.2,261.5,75.9,262.2,76.7,262.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M71.7,262.4c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8
        c-1,0-1.8,0.8-1.8,1.8C69.9,261.6,70.7,262.4,71.7,262.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="78.1" y1="263.4" x2="82.1" y2="277.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="61.9" y1="357" x2="62.8" y2="380.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62.8" y1="381.7" x2="63.6" y2="402.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="161.3" y1="489" x2="161.3" y2="658.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="157.2" y1="489" x2="157.2" y2="658.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M77.8,524.7c7.8,2.4,24.1,7.1,29.6,9.6c6,2.7,15,7.5,16.8,18.6
        c1.8,11.1,3.3,38.3,0.9,48.2c-2.4,9.9-5.4,18.6-15.3,22.5c-9.9,3.9-25.8,7.8-30.9,9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M70.4,520.3c-0.1,6.5-1.2,10.7,7.4,13.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M77.8,524.7c-1.7-0.5-3.7,2.6-1.4,5.8c2.2,3.1,3.3,3.6,7.3,4.6
        c4,1,10.8,3.3,14.7,4.9c3.9,1.6,14.2,6,15.9,16.8c1.6,10.8,1.2,34.9,0.3,42.1c-0.9,7.2-5.1,16-15.3,19.3
        c-10.2,3.3-14.1,4.2-17.7,4.8c-3.6,0.6-9.3,11.2-4.6,10"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M81.1,364.6c-5.5,0.6-10.3,1.3-10.7,7.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="92.4" y1="271.5" x2="94.6" y2="285.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="91.5" y1="347.4" x2="89.3" y2="354.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="141.7" y1="256.3" x2="137.6" y2="252.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M157.1,391.4c0-0.9-0.7-1.6-1.6-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M155.5,400.2c0.3,0,0.6-0.1,0.8-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="157.1" y1="391.4" x2="157.1" y2="395.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="155.5" y1="400.2" x2="150.4" y2="400.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M150.3,389.7c-0.9,0-1.6,0.7-1.6,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="155.5" y1="389.7" x2="150.3" y2="389.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="148.7" y1="391.3" x2="148.7" y2="395.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M155.2,392.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
        c-0.5,0-0.9,0.4-0.9,0.9C154.3,392.5,154.7,392.9,155.2,392.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M150.7,392.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
        c-0.5,0-1,0.4-1,0.9C149.7,392.5,150.1,392.9,150.7,392.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M96.7,391.1c0-0.9-0.7-1.6-1.6-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M95,400c0.9,0,1.7-0.7,1.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="96.7" y1="391.1" x2="96.7" y2="398.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M88.3,398.3c0,0.9,0.7,1.7,1.7,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="95" y1="400" x2="89.9" y2="400"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.8,389.5c-0.9,0-1.6,0.7-1.6,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="95" y1="389.5" x2="89.8" y2="389.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M94.7,392.7c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
        c-0.5,0-0.9,0.4-0.9,0.9C93.8,392.2,94.2,392.7,94.7,392.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M90.2,392.7c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
        c-0.5,0-1,0.4-1,0.9C89.3,392.2,89.7,392.7,90.2,392.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M94.7,398.4c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
        c-0.5,0-0.9,0.4-0.9,0.9C93.8,398,94.2,398.4,94.7,398.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M90.2,398.4c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
        c-0.5,0-1,0.4-1,0.9C89.3,398,89.7,398.4,90.2,398.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="162" y1="415.4" x2="162" y2="415.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M136.3,480.7c2-1.1,3.3-3.8,3.3-6.6c0-4-2.4-7.2-5.4-7.2
        c-3,0-5.4,3.2-5.4,7.2c0,3,1.4,5.7,3.4,6.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M136.3,468.5c0-0.5-0.4-1-1-1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M133.2,467.5c-0.5,0-0.9,0.4-0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="135.3" y1="467.5" x2="133.2" y2="467.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M135.4,485.6c0.5,0,0.9-0.4,0.9-0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="136.3" y1="468.5" x2="136.3" y2="484.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M132.3,484.7c0,0.5,0.4,1,1,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="132.3" y1="468.5" x2="132.3" y2="484.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="135.4" y1="485.6" x2="133.2" y2="485.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="136.2" y1="480.7" x2="132.3" y2="480.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M134.2,484.2c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1
        c-0.6,0-1.1,0.5-1.1,1.1C133.1,483.7,133.6,484.2,134.2,484.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="65.4" y1="493.4" x2="70.3" y2="488.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="65.5" y1="493.2" x2="65.5" y2="496.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="65.1" y1="525.6" x2="71.2" y2="538.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="66.4" y1="499.6" x2="65.1" y2="515.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="64.3" y1="525.5" x2="63.3" y2="538.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="60.1" y1="525.3" x2="59.1" y2="538.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="213.5" y1="654.7" x2="161.3" y2="654.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="157.2" y1="654.7" x2="87.6" y2="654.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="79.8" y1="364.7" x2="79.8" y2="525.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M138.5,287.2c0.1-0.2,0.4-0.2,1.1-0.2c0.6,0,0.9,0,1,0.3
        c0.8,2,0.8,5.9,0,8.3c-0.1,0.3-0.4,0.4-1,0.3c-0.6,0-1,0-1.1-0.2C137.8,293.2,137.8,289.4,138.5,287.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M159.4,378.4c1-0.1,2.1,0.8,2.2,1.9l0.5,7.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="167.4" y1="393.9" x2="169" y2="390.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="68.4" y1="356.8" x2="67.5" y2="380.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="67.4" y1="381.7" x2="66.8" y2="397.8"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="70.3,392.4 63.4,402.9 63.4,407.1 70.4,407.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62.3" y1="407.1" x2="70.3" y2="407.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="172.4" y1="661.9" x2="99.3" y2="661.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="172.4" y1="689.4" x2="99.3" y2="689.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M99.3,661.9l-2.8,1.9c-2.5,5.1-2.5,19.3,0,23.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="99.3" y1="689.4" x2="96.5" y2="687.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="119.9" y1="658" x2="120.8" y2="661.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="90.5" y1="658.9" x2="90.5" y2="665.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M92.3,667c1,0,1.7-0.8,1.7-1.7c0,0,0,0,0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M90.5,665.2c0,1,0.8,1.7,1.7,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="94" y1="665.2" x2="93.9" y2="658.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="93.9" y1="658.3" x2="93.9" y2="658.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="94.5,658.9 89.8,658.9 89.8,658.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="94.5" y1="658.9" x2="94.5" y2="658.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="189.9" y1="399.9" x2="161.7" y2="399.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="189.9" y1="398.2" x2="161.7" y2="398.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M189.9,398.2c1.2,0,1.2,1.6,0,1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M161.7,399.9c-1.2,0-1.2-1.6,0-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M188.8,398.1c-3.3-2.1-19.9-4.3-22.3-4.1c-1.5,0.1-4,2.8-4.8,4.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="161.2" y1="397.5" x2="162.2" y2="397.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="161.2" y1="396.4" x2="163.1" y2="396.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M161.2,397.9c0,0.5-0.1,0.6-0.4,0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M157.1,395.8c0-0.4-0.4-0.8-0.8-0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M156.1,399.1c0.5,0,1-0.4,1-1v-2.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M149.5,394.9c-0.5,0-0.9,0.4-0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="156.3" y1="394.9" x2="149.5" y2="394.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M148.6,398.3c0,0.5,0.4,0.8,0.8,0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="148.6" y1="395.9" x2="148.6" y2="398.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="156.1" y1="399.1" x2="149.4" y2="399.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="155.2" y1="394.9" x2="155.2" y2="399.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="150.6" y1="395" x2="150.6" y2="399.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="161.2" y1="397.9" x2="161.2" y2="396"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M161.2,396c0-0.5-0.1-0.6-0.4-0.6H157"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="160.8" y1="398.5" x2="157.1" y2="398.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="158.9" y1="395.4" x2="158.9" y2="398.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="158.9" y1="396" x2="157.1" y2="396"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="158.9" y1="397.8" x2="157.1" y2="397.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M76.8,365.2c-0.1-0.7,0-1.3,0.1-2.2c0.1-0.8,0.6-1.8,1.7-3.1
        c2.4-2.8,5.2-3.8,7.7-4.5L88,355c2.8-0.7,7.7-1.8,12.6-4.3c7.4-3.7,10.8-5.3,12.9-8c0.8-1.1,1.4-2.3,2-4c1.1-3.1,2.8-8.4,3.2-22.8
        c0.5-14.4-1.2-20.6-2-25.3c-0.5-2.6-0.9-3.9-2.6-5.9c-1-1.2-2.3-2.5-3.6-3.2c-3.2-1.7-9.5-5.7-11.7-6.9c-1.7-1-4.4-2.3-5.4-3.1
        c-0.6-0.5-1.1-1-1.4-1.6c-0.3-0.5-0.4-1.1-0.4-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M91.7,263.5c6.3,0.2,12.5,3.6,18.3,6.3c5.8,2.7,15.1,6.8,17.3,15.3
        c2.2,8.5,3.2,20.2,3.2,29.9c0,9.7-1.2,21.9-2.9,28.4c-1.7,6.6-6.3,9.7-11.2,12.2c-4.9,2.4-15.8,6.6-21.4,7.5
        c-5.6,1-8.5,1.2-13.8,1.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="91.7" y1="261.8" x2="91.6" y2="268.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.8" y1="306.4" x2="119" y2="307.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.5" y1="325.7" x2="118.1" y2="324.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M166.2,389.4c0.4-0.5,1.5-1,2.2-0.7l4.8,2.6c0.9,0.5,1,1.5,0.7,2.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="98.6" y1="252.3" x2="94.3" y2="254.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="94.3" y1="254.1" x2="94.3" y2="259.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="134.1,250.7 133.7,250.6 133.7,252.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="137.6" y1="252.3" x2="98.6" y2="252.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="133.7" y1="252.3" x2="129.8" y2="252.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="131.4,252.3 130.2,250.7 129.8,250.6 129.8,252.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="135.4" y1="252.3" x2="134.1" y2="250.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="129.8" y1="252.3" x2="125.9" y2="252.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="127.5,252.3 126.3,250.7 125.9,250.6 125.9,252.3 122,252.3 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="123.6,252.3 122.4,250.7 122,250.6 122,252.3 118.1,252.3 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="119.7,252.3 118.5,250.7 118.1,250.6 118.1,252.3 114.2,252.3 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="115.8,252.3 114.6,250.7 114.2,250.6 114.2,252.3 110.3,252.3 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="111.9,252.3 110.7,250.7 110.3,250.6 110.3,252.3 106.4,252.3 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="108,252.3 106.8,250.7 106.4,250.6 106.4,252.3 102.5,252.3 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="104.1,252.3 102.9,250.7 102.5,250.6 102.5,252.3 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M133.9,258.3v-0.4c0-0.6-1.7-2.2-3.4-2.2c-1.7,0-3.4,1.6-3.4,2.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="127.1" y1="258.3" x2="127.1" y2="257.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="98.9,258.3 135,258.3 135,259.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="98.9" y1="258.3" x2="98.9" y2="259.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M110.5,258.3v-0.4c0-0.6-1.7-2.2-3.4-2.2c-1.7,0-3.4,1.6-3.4,2.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="103.7" y1="258.3" x2="103.7" y2="257.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M71.5,356.5c-1.5,2.7-3.5,7.3-3.6,13.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M155.6,400.2c0.9-0.1,1.6-0.8,1.6-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M148.7,398.8c0.1,0.8,0.8,1.4,1.6,1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="88.3" y1="391" x2="88.3" y2="398.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70.3" y1="380.3" x2="37" y2="380.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70.4" y1="381.7" x2="37" y2="381.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="37" y1="380.3" x2="37" y2="381.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M44.8,518.5c-0.7,0.1-1.2,0.7-1.2,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="44.8" y1="518.5" x2="70.4" y2="515.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M43.6,523.2c0,0.7,0.5,1.2,1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="43.6" y1="523.2" x2="43.6" y2="519.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70.1" y1="525.8" x2="44.8" y2="524.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="164.6" y1="401.4" x2="164.6" y2="482.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.6" y1="625.2" x2="77.6" y2="620"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.1" y1="623.4" x2="64.6" y2="621.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.1,623.4c-1.1,0.3-0.7,2.1,0.5,1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62.7" y1="632.1" x2="76.4" y2="628.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62.2" y1="630.3" x2="77.8" y2="626.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M62.2,630.3c-1.1,0.3-0.7,2.1,0.5,1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="82" y1="657.9" x2="36.5" y2="657.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="81.9,656.3 36.5,656.3 36.5,657.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="77.4" y1="619.7" x2="78.7" y2="625"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M82.5,663c1.2,0,1.2,1.7,0,1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M72.2,663c-1.2,0-1.2,1.7,0,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="82.1" y1="663" x2="82.1" y2="661.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="72.6" y1="663" x2="72.6" y2="661"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="82.5" y1="663" x2="72.2" y2="663"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="82.5" y1="664.6" x2="72.1" y2="664.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M73.1,656.3c0.4-2,1.2-3.8,2.6-4.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M72.6,661c0-1.1,0.1-2.1,0.2-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M82.1,661.3c-0.1-3.1,0.7-5.7,1.4-6.5c0.5-0.6,1.3-1.3,1.8-2.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M84.8,650.7h-7.4c-0.6,0-1.1,0.1-1.7,0.7c-0.7,0.6-1.3,1.4-1.4,1.7
        c-0.1,0.3-1.6-0.2-1.2-0.9c0.8-1.4,2.4-3.3,3.4-3.3h8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="102.9" y1="542.4" x2="102.9" y2="562.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="102.9" y1="597.7" x2="102.9" y2="616.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M144.3,658.3v0.5c0,0.8-1.8,2.9-3.6,2.8c-1.8,0-3.6-2.1-3.5-2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="137.1" y1="658.9" x2="137.1" y2="658.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M108.8,659v0.4c0,0.7-1.8,2.3-3.6,2.3c-1.8,0-3.6-1.7-3.5-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="101.6" y1="659" x2="101.6" y2="659.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.8" y1="659" x2="108.8" y2="658.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="101.6" y1="659" x2="101.6" y2="658.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M62.8,636.6c1.4-0.3,2.4-1.6,2.4-3c0-0.8-0.3-1.5-0.7-2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M56.8,630.7c-1.9,0.2-3.3,1.8-3.3,3.7c0,2,1.7,3.7,3.7,3.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62.8" y1="636.6" x2="58" y2="638.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="61.8" y1="630.6" x2="56.8" y2="630.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M62.2,635.1c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
        c-0.8,0-1.5,0.7-1.5,1.5C60.7,634.5,61.3,635.1,62.2,635.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M57.2,636.2c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8
        c-1,0-1.8,0.8-1.8,1.8C55.4,635.4,56.2,636.2,57.2,636.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M154.9,295.3c2.8,3.6,4.7,8.8,4.7,12.8L163,367c0.1,3.9-1.5,5.2-3.7,6"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M44.7,518.5c-0.7,0.1-1.2,0.7-1.2,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="44.7" y1="518.5" x2="70.4" y2="515.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M43.6,523.2c0,0.7,0.5,1.2,1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="43.6" y1="523.2" x2="43.6" y2="519.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70.1" y1="525.8" x2="44.7" y2="524.4"/>
      <rect x="62.4" y="414.1" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <rect x="62.4" y="414.1" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <rect x="62.4" y="496.3" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <rect x="62.4" y="477.4" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,309.7c3.1,0,5.6,2.5,5.6,5.5c0,3.1-2.5,5.6-5.5,5.6
          c-3.1,0-5.6-2.4-5.6-5.5C59.6,312.3,62.1,309.7,65.1,309.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,289.8c14.1-0.1,25.6,11.3,25.7,25.3
          c0.1,14.1-11.3,25.6-25.3,25.7c-14.1,0.1-25.6-11.3-25.7-25.3C39.6,301.4,51,289.9,65.1,289.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,289.8c14.1-0.1,25.6,11.3,25.7,25.3
          c0.1,14.1-11.3,25.6-25.3,25.7c-14.1,0.1-25.6-11.3-25.7-25.3C39.6,301.4,51,289.9,65.1,289.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,290.8c13.5-0.1,24.6,10.8,24.6,24.3
          c0.1,13.5-10.8,24.6-24.3,24.6c-13.5,0.1-24.6-10.8-24.7-24.3C40.7,301.9,51.5,290.9,65.1,290.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M84.1,328.9c-2.1,2.9-4.8,5.2-7.8,6.9l-0.5-0.3l-4.2-12.7l0-0.6
          c0.1-0.2,0.5-0.9,0.9-1.5c0.4-0.5,1-1.2,1.1-1.4l0.6-0.2l13.4-0.1l0.5,0.4C87.5,322.6,86.2,325.9,84.1,328.9z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,308.2c3.9,0,7.1,3.1,7.1,7c0,3.9-3.1,7.1-7,7.1
          c-3.9,0-7.1-3.1-7.1-7C58.1,311.4,61.2,308.2,65.1,308.2"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,308.2c3.9,0,7.1,3.1,7.1,7c0,3.9-3.1,7.1-7,7.1
          c-3.9,0-7.1-3.1-7.1-7C58.1,311.4,61.2,308.2,65.1,308.2z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M72.3,312.8c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4
          c-1.3,0-2.4-1.1-2.4-2.4C69.8,313.9,70.9,312.8,72.3,312.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M72.3,313.9c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
          c-0.8,0-1.4-0.6-1.4-1.4C70.8,314.5,71.5,313.9,72.3,313.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M83.9,301.5c2.1,2.9,3.5,6.2,4.1,9.5l-0.5,0.4l-13.4,0.1l-0.6-0.2
          c-0.1-0.1-0.7-0.8-1.1-1.3c-0.4-0.5-0.9-1.3-0.9-1.5l0-0.6l4-12.8l0.5-0.3C79.1,296.3,81.8,298.6,83.9,301.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65,307.8c0.4-1.3,1.8-2,3-1.6c1.3,0.4,2,1.8,1.6,3.1
          c-0.4,1.3-1.8,2-3.1,1.6C65.3,310.4,64.6,309.1,65,307.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M66,308.1c0.2-0.7,1-1.2,1.8-0.9c0.7,0.2,1.2,1,0.9,1.8
          c-0.2,0.7-1,1.2-1.8,0.9C66.1,309.6,65.7,308.9,66,308.1"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M57.8,293.2c3.4-1.1,7-1.4,10.3-1l0.2,0.6l-4.1,12.8l-0.4,0.5
          c-0.2,0.1-1,0.4-1.6,0.7c-0.6,0.2-1.5,0.4-1.7,0.4l-0.6-0.2l-10.9-7.8l-0.2-0.6C51.4,296.2,54.4,294.3,57.8,293.2z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M41.9,315.5c0-3.6,0.8-7.1,2.2-10.1l0.6,0l10.9,7.8l0.4,0.5
          c0,0.2,0.1,1.1,0.1,1.7c0,0.7-0.1,1.6-0.1,1.7l-0.4,0.5l-10.8,8l-0.6,0C42.7,322.5,41.9,319.1,41.9,315.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M60.9,321.5c-1.1,0.8-2.6,0.6-3.4-0.5c-0.8-1.1-0.6-2.6,0.5-3.4
          c1.1-0.8,2.6-0.6,3.4,0.5C62.2,319.2,61.9,320.7,60.9,321.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M60.3,320.7c-0.6,0.5-1.5,0.3-2-0.3c-0.5-0.6-0.3-1.5,0.3-2
          c0.6-0.5,1.5-0.3,2,0.3C61,319.3,60.9,320.2,60.3,320.7"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M58.1,337.5c-3.4-1.1-6.5-2.9-8.9-5.3l0.2-0.6l10.8-7.9l0.6-0.2
          c0.2,0,1.1,0.2,1.7,0.4c0.6,0.2,1.5,0.6,1.6,0.6l0.4,0.5l4.2,12.7l-0.2,0.6C65.1,338.8,61.5,338.6,58.1,337.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M69.7,321.3c0.4,1.3-0.3,2.7-1.5,3.1c-1.3,0.4-2.7-0.3-3.1-1.5
          c-0.4-1.3,0.3-2.6,1.5-3.1C67.9,319.3,69.3,320,69.7,321.3"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M68.7,321.6c0.2,0.8-0.2,1.5-0.9,1.8c-0.7,0.3-1.5-0.2-1.8-0.9
          c-0.2-0.7,0.2-1.6,0.9-1.8C67.7,320.5,68.5,320.9,68.7,321.6"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,309.7c3.1,0,5.6,2.5,5.6,5.5c0,3.1-2.5,5.6-5.5,5.6
          c-3.1,0-5.6-2.4-5.6-5.5C59.6,312.3,62.1,309.7,65.1,309.7"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.2,311.4c2.1,0,3.9,1.7,3.9,3.9c0,2.1-1.7,3.9-3.9,3.9
          c-2.1,0-3.9-1.7-3.9-3.9C61.3,313.2,63,311.4,65.2,311.4z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M65,289.7c14.1-0.1,25.6,11.3,25.7,25.4c0.1,14.1-11.3,25.6-25.4,25.7
          c-14.1,0.1-25.6-11.3-25.7-25.4C39.5,301.3,50.9,289.8,65,289.7z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M65,307.8c0.4-1.3,1.8-2,3-1.6c1.3,0.4,2,1.8,1.6,3.1
          c-0.4,1.3-1.8,2-3.1,1.6C65.3,310.4,64.6,309.1,65,307.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M66,308.1c0.2-0.7,1-1.2,1.8-0.9c0.7,0.2,1.2,1,0.9,1.8
          c-0.2,0.7-1,1.2-1.8,0.9C66.1,309.6,65.7,308.9,66,308.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M72.3,312.8c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4
          c-1.3,0-2.4-1.1-2.4-2.4C69.8,313.9,70.9,312.8,72.3,312.8z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M72.3,313.9c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
          c-0.8,0-1.4-0.6-1.4-1.4C70.8,314.5,71.5,313.9,72.3,313.9z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M69.7,321.3c0.4,1.3-0.3,2.7-1.5,3.1c-1.3,0.4-2.7-0.3-3.1-1.5
          c-0.4-1.3,0.3-2.6,1.5-3.1C67.9,319.3,69.3,320,69.7,321.3z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M68.7,321.6c0.2,0.8-0.2,1.5-0.9,1.8c-0.7,0.3-1.5-0.2-1.8-0.9
          c-0.2-0.7,0.2-1.6,0.9-1.8C67.7,320.5,68.5,320.9,68.7,321.6z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M60.9,321.5c-1.1,0.8-2.6,0.6-3.4-0.5c-0.8-1.1-0.6-2.6,0.5-3.4
          c1.1-0.8,2.6-0.6,3.4,0.5C62.2,319.2,61.9,320.7,60.9,321.5z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M60.3,320.7c-0.6,0.5-1.5,0.3-2-0.3c-0.5-0.6-0.3-1.5,0.3-2
          c0.6-0.5,1.5-0.3,2,0.3C61,319.3,60.9,320.2,60.3,320.7z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58,313.1c-1.1-0.8-1.3-2.3-0.6-3.4c0.8-1.1,2.3-1.3,3.4-0.6
          c1.1,0.8,1.3,2.3,0.6,3.4C60.6,313.7,59.1,313.9,58,313.1"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58,313.1c-1.1-0.8-1.3-2.3-0.6-3.4c0.8-1.1,2.3-1.3,3.4-0.6
          c1.1,0.8,1.3,2.3,0.6,3.4C60.6,313.7,59.1,313.9,58,313.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58.6,312.3c-0.6-0.5-0.8-1.3-0.3-2c0.5-0.6,1.3-0.8,2-0.3
          c0.6,0.5,0.8,1.3,0.3,2C60.1,312.6,59.2,312.8,58.6,312.3"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58.6,312.3c-0.6-0.5-0.8-1.3-0.3-2c0.5-0.6,1.3-0.8,2-0.3
          c0.6,0.5,0.8,1.3,0.3,2C60.1,312.6,59.2,312.8,58.6,312.3z"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M65.2,617.5c20.7,0,37.4-16.8,37.4-37.4c0-20.7-16.8-37.4-37.4-37.4
          c-20.7,0-37.4,16.8-37.4,37.4C27.8,600.7,44.5,617.5,65.2,617.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M65.2,621.6c23,0,41.6-18.6,41.6-41.6c0-23-18.6-41.6-41.6-41.6
          c-23,0-41.6,18.6-41.6,41.6C23.6,603,42.2,621.6,65.2,621.6"/>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,574.5c3.1,0,5.6,2.5,5.6,5.5c0,3.1-2.5,5.6-5.5,5.6
            c-3.1,0-5.6-2.4-5.6-5.5C59.6,577,62.1,574.5,65.1,574.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,554.6c14.1-0.1,25.6,11.3,25.7,25.3
            c0.1,14.1-11.3,25.6-25.3,25.7c-14.1,0.1-25.6-11.3-25.7-25.3C39.6,566.2,51,554.7,65.1,554.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,554.6c14.1-0.1,25.6,11.3,25.7,25.3
            c0.1,14.1-11.3,25.6-25.3,25.7c-14.1,0.1-25.6-11.3-25.7-25.3C39.6,566.2,51,554.7,65.1,554.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,555.6c13.5-0.1,24.6,10.8,24.6,24.3
            c0.1,13.5-10.8,24.6-24.3,24.6c-13.5,0.1-24.6-10.8-24.7-24.3C40.7,566.7,51.5,555.7,65.1,555.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M84.1,593.7c-2.1,2.9-4.8,5.2-7.8,6.9l-0.5-0.3l-4.2-12.7l0-0.6
            c0.1-0.2,0.5-0.9,0.9-1.5c0.4-0.5,1-1.2,1.1-1.3l0.6-0.2l13.4-0.1l0.5,0.4C87.5,587.4,86.2,590.7,84.1,593.7z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,573c3.9,0,7.1,3.1,7.1,7c0,3.9-3.1,7.1-7,7.1
            c-3.9,0-7.1-3.1-7.1-7C58.1,576.2,61.2,573,65.1,573"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,573c3.9,0,7.1,3.1,7.1,7c0,3.9-3.1,7.1-7,7.1
            c-3.9,0-7.1-3.1-7.1-7C58.1,576.2,61.2,573,65.1,573z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M72.3,577.6c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4
            c-1.3,0-2.4-1.1-2.4-2.4C69.8,578.7,70.9,577.6,72.3,577.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M72.3,578.6c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
            c-0.8,0-1.4-0.6-1.4-1.4C70.8,579.3,71.5,578.6,72.3,578.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M83.9,566.3c2.1,2.9,3.5,6.2,4.1,9.5l-0.5,0.4l-13.4,0.1l-0.6-0.2
            c-0.1-0.1-0.7-0.8-1.1-1.3c-0.4-0.5-0.9-1.3-0.9-1.5l0-0.6l4-12.8l0.5-0.3C79.1,561.1,81.8,563.4,83.9,566.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65,572.6c0.4-1.3,1.8-2,3-1.6c1.3,0.4,2,1.8,1.6,3.1
            c-0.4,1.3-1.8,2-3.1,1.6C65.3,575.2,64.6,573.9,65,572.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M66,572.9c0.2-0.7,1-1.2,1.8-0.9c0.7,0.2,1.2,1,0.9,1.8
            c-0.2,0.7-1,1.2-1.8,0.9C66.1,574.4,65.7,573.6,66,572.9"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M57.8,558c3.4-1.1,7-1.4,10.3-1l0.2,0.6l-4.1,12.8l-0.4,0.5
            c-0.2,0.1-1,0.4-1.6,0.7c-0.6,0.2-1.5,0.4-1.7,0.4l-0.6-0.2l-10.9-7.8l-0.2-0.6C51.4,561,54.4,559.1,57.8,558z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M41.9,580.3c0-3.6,0.8-7.1,2.2-10.1l0.6,0l10.9,7.8l0.4,0.5
            c0,0.2,0.1,1.1,0.1,1.7c0,0.7-0.1,1.6-0.1,1.7l-0.4,0.5l-10.8,8l-0.6,0C42.7,587.3,41.9,583.9,41.9,580.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M60.9,586.2c-1.1,0.8-2.6,0.6-3.4-0.5c-0.8-1.1-0.6-2.6,0.5-3.4
            c1.1-0.8,2.6-0.6,3.4,0.5C62.2,583.9,61.9,585.5,60.9,586.2"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M60.3,585.4c-0.6,0.5-1.5,0.3-2-0.3c-0.5-0.6-0.3-1.5,0.3-2
            c0.6-0.5,1.5-0.3,2,0.3C61,584.1,60.9,585,60.3,585.4"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M58.1,602.3c-3.4-1.1-6.5-2.9-8.9-5.3l0.2-0.6l10.8-7.9l0.6-0.2
            c0.2,0,1.1,0.2,1.7,0.4c0.6,0.2,1.5,0.6,1.6,0.6l0.4,0.5l4.2,12.7l-0.2,0.6C65.1,603.6,61.5,603.4,58.1,602.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M69.7,586.1c0.4,1.3-0.3,2.7-1.5,3.1c-1.3,0.4-2.7-0.3-3.1-1.5
            c-0.4-1.3,0.3-2.6,1.5-3.1C67.9,584.1,69.3,584.8,69.7,586.1"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M68.7,586.4c0.2,0.8-0.2,1.5-0.9,1.8c-0.7,0.3-1.5-0.2-1.8-0.9
            c-0.2-0.7,0.2-1.6,0.9-1.8C67.7,585.3,68.5,585.6,68.7,586.4"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.1,574.5c3.1,0,5.6,2.5,5.6,5.5c0,3.1-2.5,5.6-5.5,5.6
            c-3.1,0-5.6-2.4-5.6-5.5C59.6,577,62.1,574.5,65.1,574.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65.2,576.2c2.1,0,3.9,1.7,3.9,3.9c0,2.1-1.7,3.9-3.9,3.9
            c-2.1,0-3.9-1.7-3.9-3.9C61.3,578,63,576.2,65.2,576.2z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M65,554.5c14.1-0.1,25.6,11.3,25.7,25.4c0.1,14.1-11.3,25.6-25.4,25.7
            c-14.1,0.1-25.6-11.3-25.7-25.4C39.5,566.1,50.9,554.6,65,554.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M65,572.6c0.4-1.3,1.8-2,3-1.6c1.3,0.4,2,1.8,1.6,3.1
            c-0.4,1.3-1.8,2-3.1,1.6C65.3,575.2,64.6,573.9,65,572.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M66,572.9c0.2-0.7,1-1.2,1.8-0.9c0.7,0.2,1.2,1,0.9,1.8
            c-0.2,0.7-1,1.2-1.8,0.9C66.1,574.4,65.7,573.6,66,572.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M72.3,577.6c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4
            c-1.3,0-2.4-1.1-2.4-2.4C69.8,578.7,70.9,577.6,72.3,577.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M72.3,578.6c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
            c-0.8,0-1.4-0.6-1.4-1.4C70.8,579.3,71.5,578.6,72.3,578.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M69.7,586.1c0.4,1.3-0.3,2.7-1.5,3.1c-1.3,0.4-2.7-0.3-3.1-1.5
            c-0.4-1.3,0.3-2.6,1.5-3.1C67.9,584.1,69.3,584.8,69.7,586.1z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M68.7,586.4c0.2,0.8-0.2,1.5-0.9,1.8c-0.7,0.3-1.5-0.2-1.8-0.9
            c-0.2-0.7,0.2-1.6,0.9-1.8C67.7,585.3,68.5,585.6,68.7,586.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M60.9,586.2c-1.1,0.8-2.6,0.6-3.4-0.5c-0.8-1.1-0.6-2.6,0.5-3.4
            c1.1-0.8,2.6-0.6,3.4,0.5C62.2,583.9,61.9,585.5,60.9,586.2z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M60.3,585.4c-0.6,0.5-1.5,0.3-2-0.3c-0.5-0.6-0.3-1.5,0.3-2
            c0.6-0.5,1.5-0.3,2,0.3C61,584.1,60.9,585,60.3,585.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58,577.9c-1.1-0.8-1.3-2.3-0.6-3.4c0.8-1.1,2.3-1.3,3.4-0.6
            c1.1,0.8,1.3,2.3,0.6,3.4C60.6,578.4,59.1,578.7,58,577.9"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58,577.9c-1.1-0.8-1.3-2.3-0.6-3.4c0.8-1.1,2.3-1.3,3.4-0.6
            c1.1,0.8,1.3,2.3,0.6,3.4C60.6,578.4,59.1,578.7,58,577.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58.6,577.1c-0.6-0.5-0.8-1.3-0.3-2c0.5-0.6,1.3-0.8,2-0.3
            c0.6,0.5,0.8,1.3,0.3,2C60.1,577.4,59.2,577.6,58.6,577.1"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M58.6,577.1c-0.6-0.5-0.8-1.3-0.3-2c0.5-0.6,1.3-0.8,2-0.3
            c0.6,0.5,0.8,1.3,0.3,2C60.1,577.4,59.2,577.6,58.6,577.1z"/>
        </g>
      </g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70.3" y1="509.9" x2="70.3" y2="515.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M88.4,657.8c0,0-0.1-0.5-0.2-1.1c-0.1-0.6-0.3-1.3-0.7-2.1
        c-3.4-6.6-12.2-22.3-12.2-22.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="79" y1="632.5" x2="77" y2="633"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="59.9" y1="627.4" x2="60.7" y2="630.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.1,638c0,0-0.2,0.1-0.4,0.1c-0.2,0-0.5,0-0.5,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="81.8" y1="658.2" x2="81.8" y2="656"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="85.3" y1="652.6" x2="86.4" y2="652.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M71.7,656.3c0,0-2.6-4.3-4.9-9.4c-2.1-4.7-4.2-10.2-4.2-10.2"/>
    </g>
  </g>
  <g id="right">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M485.6,627.5V517.7v0l10.6-89.3l40.3-20.5h-3.3c-1.2,0-1.2-1.7,0-1.7h1
        l0-0.1c1.6-1,6.6-2.1,11.4-2.9l-0.1,0l3.6-1.8l0,0c-0.4-0.6-0.2-1.6,0.7-2.1l4.8-2.6c0.8-0.4,1.9,0.1,2.2,0.7l0,0l3-1.5l1.1,0
        l0.5-7.7c0.1-1,1.2-1.9,2.2-1.9l0,0l6.1-109.7v0c0-6.5,5.1-11.9,11.5-12.5l4.1-4l2.3,0l1.2-1.6l0.4-0.1v1.7l2.3,0l1.2-1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="485.6" y1="664" x2="485.6" y2="628.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M634.8,665.8l-0.1,0.4l-0.1,0h-1.2v0v0.7h-0.7l0,0v6.3
        c0,1-0.8,1.7-1.7,1.7c-1,0-1.7-0.8-1.7-1.7c0,0,0,0,0,0l0.1-6.3h-0.6v-0.7v0h-7.2l0,0.1v1.1c0.1,0.6-1.8,2.3-3.5,2.3
        c-1.8,0-3.6-1.6-3.6-2.3l0-1.1h-11.2v0.1l-0.8,3.5h21.6l2.8,1.9c2.5,5.1,2.5,19.3,0,23.8l-2.8,1.9h-73.1l-2.8-1.9
        c-2.5-4.5-2.5-18.7,0-23.8l2.8-1.9h18.8v-0.1l-0.5-3.5l-0.2,0h-3v0.1c0,1.3-4.1,0.8-4.1,0v-0.1c0,0-76.2,0-76.2,0V664"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M592.9,258.7l0.4-0.1v1.7l2.3,0l1.2-1.6l0.4-0.1v1.7l2.3,0l1.2-1.6
        l0.4-0.1v1.7l2.3,0l1.2-1.6l0.4-0.1v1.7l2.3,0l1.2-1.6l0.4-0.1v1.7l2.3,0l1.2-1.6l0.4-0.1v1.7l2.3,0l1.2-1.6l0.4-0.1v1.7l2.3,0
        l1.2-1.6l0.4-0.1v1.7h3.9l4.2,1.8v5h2v-11.3h12.2v2l0,0h3.7v7.6l0,0l4.6-0.6c0.1,0,0.2,0,0.3,0c2,0,3.7,1.7,3.7,3.7
        c0,2-1.7,3.7-3.7,3.7l-0.2,0l-5-0.6h0c-0.5,0-0.9-0.1-1.3-0.3l0,0l-4,13.9l-0.1,0c5.2-2.3,11-3.6,17-3.6c23,0,41.6,18.6,41.6,41.6
        c0,21.9-16.9,39.8-38.4,41.5l0,0.3l-0.9,23.3h0h25.8v1.4h-25.8l-0.8,20.7l0.2,0.4v4.2h-7l0.1-1.2v1.2h7.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M561.1,413.8v72.6c0,2.6-2.1,4.8-4.8,4.8h0h-36.5
        c-2.5,0-4.6-2.1-4.6-4.6V431c0-1.8,1-3.3,2.5-4.1l35.4-17.5v-0.1c0.9-0.5,1.8-0.8,2.8-0.8C558.8,408.6,561.1,410.9,561.1,413.8
        c0,0.2,0,0.4,0,0.5V413.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M558.5,490.5v-81.2l0.1-0.1c1.5,0.9,2.5,2.6,2.5,4.4c0,0.2,0,0.4,0,0.6
        l0-0.5v72.6C561.1,488.2,560,489.8,558.5,490.5L558.5,490.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.8,622.6c-4,0-7.3-3.3-7.3-7.3c0,0,0-0.1,0-0.1l0-83.8
        c0-0.1,0-0.2,0-0.3c0-4,3.3-7.3,7.3-7.3c4,0,7.3,3.3,7.3,7.3v84.2l0-0.4c0,0.2,0,0.3,0,0.5C503.1,619.4,499.9,622.6,495.8,622.6"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M491.5,531.2c0,0,0-0.1,0-0.1c0-2.4,1.9-4.3,4.3-4.3
        c2.4,0,4.3,1.9,4.3,4.3l0.1,84.3c0,2.4-2,4.3-4.4,4.3c-2.4,0-4.4-2-4.4-4.4c0,0,0-0.1,0-0.1L491.5,531.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M658,360.7c-20.7,0-37.4-16.8-37.4-37.4c0-20.7,16.8-37.4,37.4-37.4
        c20.7,0,37.4,16.8,37.4,37.4C695.4,343.9,678.6,360.7,658,360.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M658,629.6c-23,0-41.6-18.6-41.6-41.6c0-23,18.6-41.6,41.6-41.6
        c23,0,41.6,18.6,41.6,41.6C699.5,611,680.9,629.6,658,629.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M548,671.7l2.8-1.9h73.1l2.8,1.9c2.5,5.1,2.5,19.3,0,23.8l-2.8,1.9
        h-73.1l-2.8-1.9C545.5,691,545.5,676.8,548,671.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M533.2,406.2l1.1-0.1c3.3-2.1,19.9-4.3,22.3-4.1c1,0.1,2.4,1.2,3.5,2.5
        h1.8V404c0-0.5,0.1-0.6,0.4-0.6h3.7l0,0c0.1-0.3,0.4-0.5,0.7-0.5h6.8c0.5,0,0.9,0.4,0.9,0.9v2.4c0,0.5-0.4,0.8-0.8,0.8H567
        c-0.4,0-0.8-0.2-0.9-0.6l0,0h-3.7c-0.3,0-0.5-0.1-0.4-0.6v-0.4h-1c0.2,0.3,0.4,0.5,0.5,0.7c1.2,0,1.2,1.7,0,1.7h-28.2
        C532,407.8,532,406.2,533.2,406.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M596.1,266.3h-6.8v-0.4c0-0.6,1.7-2.2,3.4-2.2c1.7,0,3.4,1.6,3.4,2.2
        V266.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M619.5,266.3h-6.8v-0.4c0-0.6,1.7-2.2,3.4-2.2c1.7,0,3.4,1.6,3.4,2.2
        V266.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M561.6,380l0.7,0.5c0,0,0.5,0.3,1.5,0.4h0.1l4.4-77.6l-0.2-0.1
        c-2.8,3.6-4.7,8.8-4.7,12.8l-3.4,58.9l0,1.4l0.4,1.6l0.5,1.2L561.6,380"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M638.9,656.3l-1.1,4.1l0,0.1c0.5,0.8,1.3,1.6,1.8,2.2
        c0.7,0.8,1.5,3.5,1.4,6.5v1.7h-0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M652.8,527.9v0.3c0,6.5,1.2,10.7-7.4,13.1l-1.5,0.1
        c-1.1,0.7-2.3,1-4.4,1.6c-4,1-10.8,3.3-14.7,4.9c-3.9,1.6-14.2,6-15.9,16.8c-1.6,10.8-1.2,34.9-0.3,42.1c0.9,7.2,5.1,16,15.3,19.3
        c10.2,3.3,14.1,4.2,17.7,4.8c3.1,0.5,7.9,8.7,5.9,10"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="660.7,415.1 652.8,415.1 652.8,415.1 652.8,516.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M663.2,635.4l0.6-2.5l0.7,0.2c1.2,0.3,1.6-1.5,0.5-1.8l-6.5-1.8l-0.6,0
        c-23,0-41.6-18.6-41.6-41.6c0-23,18.6-41.6,41.6-41.6c2.1,0,4.2,0.2,6.2,0.5l-0.1-0.1l-1.1-13.6l15.4-0.9c0.7,0,1.2-0.6,1.2-1.2
        v-3.4c0-0.7-0.5-1.2-1.2-1.3l-16-2.1l-0.1,0l-0.3-4.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M640.6,671c-1.2,0-1.2,1.7,0,1.7H651H651c1.2,0,1.2-1.7,0-1.7l-0.4,0
        V669c0-1.1-0.1-2.1-0.2-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M652.8,413.7v-33.6c-0.3-4.5-2.9-6.1-6.4-6.9l0-0.1
        c0.1-0.7,0-1.3-0.1-2.2c-0.1-0.8-0.6-1.8-1.7-3.1c-2.4-2.8-5.2-3.8-7.7-4.5l-1.6-0.4c-2.8-0.7-7.7-1.8-12.6-4.3
        c-7.4-3.7-10.8-5.3-12.9-8c-0.8-1.1-1.4-2.3-2-4c-1.1-3.1-2.8-8.4-3.2-22.8c-0.5-14.4,1.2-20.5,2-25.3c0.5-2.6,0.9-3.9,2.6-5.9
        c1-1.2,2.3-2.5,3.6-3.2c3.2-1.7,9.5-5.7,11.7-6.9c1.7-1,4.4-2.3,5.4-3.1c0.6-0.5,1.1-1,1.4-1.6c0.3-0.5,0.4-1.1,0.4-1.7l-0.1-6.3
        l-0.6-0.3v-13.7h12.2l0,2.1h3.7v7.6l4.5-0.6c0.1,0,0.2,0,0.3,0c2,0,3.7,1.7,3.7,3.7c0,2-1.7,3.7-3.7,3.7c-0.1,0-0.1,0-0.2,0
        l-4.9-0.6h0c-0.4,0-0.9-0.1-1.2-0.3v0l-4,13.9l-0.3,0.1c-14.4,6.5-24.4,21-24.4,37.9c0,23,18.6,41.6,41.6,41.6
        c1.2,0,2.3,0,3.5-0.1l-0.2,0.3l-0.9,23.3h25.8v1.4h-25.8l-0.8,20.7l0.2,0.4v4.2h-7L652.8,413.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M586.9,476.5c0-0.5,0.4-1,1-1h2.1c0.5,0,0.9,0.4,0.9,0.9v16.2
        c0,0.5-0.4,1-1,1h-2.2c-0.5,0-0.9-0.4-0.9-0.9V476.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M584.6,295.1c-0.1-0.2-0.4-0.2-1.1-0.2c-0.6,0-0.9,0-1,0.3
        c-0.8,2-0.8,5.9,0,8.3c0.1,0.3,0.4,0.4,1,0.3c0.6,0,1,0,1.1-0.2C585.4,301.2,585.3,297.4,584.6,295.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M652.9,523.1l25.5,3.4c0.7,0.1,1.2,0.7,1.2,1.3v3.4
        c0,0.7-0.5,1.2-1.2,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M678.4,532.4l-25.4,1.4l-0.2-0.1c0.1-1.6,0-3.4,0-5.5v-5.1"/>
      <rect x="660.8" y="409.2" fill="none" stroke="#231F20" stroke-width="0.5" width="3" height="110.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="563.3" y1="395.8" x2="569.9" y2="276.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="577.8" y1="401.7" x2="577.8" y2="662.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="560" y1="395.9" x2="643.4" y2="395.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="485.6" y1="628.3" x2="485.6" y2="517.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="496.2" y1="428.4" x2="485.6" y2="517.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="509.8" y1="497" x2="641.7" y2="497"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="565.9" y1="666.2" x2="634.6" y2="666.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M575.5,279.6c0.1-6.9,5.7-12.5,12.6-12.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="630.8" y1="267.1" x2="588.1" y2="267.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="574.5" y1="395.9" x2="575.5" y2="279.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="581.5" y1="269" x2="581.5" y2="264.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M569.9,276.7c0.1-6.5,5.1-11.9,11.5-12.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="507.3" y1="666.2" x2="507.3" y2="426.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="509.8" y1="666.2" x2="509.8" y2="424.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="652.8" y1="380.1" x2="652.8" y2="517.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="641.4" y1="401.8" x2="641.4" y2="497.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="496.2" y1="428.4" x2="536.5" y2="407.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="545.6" y1="403.2" x2="560" y2="395.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="495.9" y1="432.3" x2="543.7" y2="408"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="555.4,402 555.7,401.8 641.4,401.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M519.9,491.1c-2.5,0-4.6-2.1-4.6-4.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M515.3,431c0-1.8,1-3.3,2.5-4.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="515.3" y1="431" x2="515.3" y2="486.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M553.2,409.3c0.9-0.5,1.8-0.8,2.8-0.8c2.9,0,5.2,2.3,5.2,5.2
        c0,0.2,0,0.4,0,0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="517.8" y1="426.9" x2="553.2" y2="409.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M561.1,486.3c0,2.6-2.1,4.8-4.8,4.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="561.1" y1="413.7" x2="561.1" y2="486.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="519.9" y1="491.1" x2="556.4" y2="491.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="630.8,269.5 630.8,255.8 643,255.8 643,269.5 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="643" y1="269.6" x2="630.8" y2="269.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="643,257.9 646.6,257.9 646.6,265.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="488.5" y1="531" x2="488.5" y2="615.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="503.1" y1="531.1" x2="503.1" y2="615.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.5" y1="531.2" x2="491.5" y2="615.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="500.2" y1="531.1" x2="500.2" y2="615.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.8,622.6c-4,0-7.3-3.3-7.3-7.3c0,0,0-0.1,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M503.1,614.9c0,0.2,0,0.3,0,0.5c0,4-3.3,7.3-7.3,7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M488.5,531.4c0-0.1,0-0.2,0-0.3c0-4,3.3-7.3,7.3-7.3
        c4,0,7.3,3.3,7.3,7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M491.5,531.3c0,0,0-0.1,0-0.1c0-2.4,1.9-4.3,4.3-4.3
        c2.4,0,4.3,1.9,4.3,4.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.8,619.7c-2.4,0-4.4-2-4.4-4.4c0,0,0-0.1,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M500.2,615.4c0,2.4-2,4.3-4.4,4.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="557.5" y1="664" x2="557.6" y2="664"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M646.3,271.8c-1.7-0.1-3-1.4-3-3.1c0-1.6,1.3-3,2.9-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M651.4,272.3c-0.1,0-0.1,0-0.2,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M651.2,264.9c0.1,0,0.2,0,0.3,0c2,0,3.7,1.7,3.7,3.7
        c0,2-1.7,3.7-3.7,3.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="646.3" y1="271.8" x2="651.2" y2="272.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="646.2" y1="265.6" x2="651.2" y2="264.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M646.4,270.2c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5
        c0.8,0,1.5,0.7,1.5,1.5C647.9,269.5,647.3,270.2,646.4,270.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M651.4,270.4c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8
        c1,0,1.8,0.8,1.8,1.8C653.2,269.6,652.4,270.4,651.4,270.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="645.1" y1="271.4" x2="641.1" y2="285.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="661.2" y1="365" x2="660.3" y2="388.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="660.3" y1="389.7" x2="659.5" y2="410.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="561.8" y1="497" x2="561.8" y2="666.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="565.9" y1="497" x2="565.9" y2="666.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M645.3,532.7c-7.8,2.4-24.1,7.1-29.6,9.6c-6,2.7-15,7.5-16.8,18.6
        c-1.8,11.1-3.3,38.3-0.9,48.2c2.4,9.9,5.4,18.6,15.3,22.5c9.9,3.9,25.8,7.8,30.9,9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M652.8,528.3c0.1,6.5,1.2,10.7-7.4,13.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M645.3,532.7c1.7-0.5,3.7,2.6,1.4,5.8c-2.2,3.1-3.3,3.6-7.3,4.6
        c-4,1-10.8,3.3-14.7,4.9c-3.9,1.6-14.2,6-15.9,16.8c-1.6,10.8-1.2,34.9-0.3,42.1c0.9,7.2,5.1,16,15.3,19.3
        c10.2,3.3,14.1,4.2,17.7,4.8c3.6,0.6,9.3,11.2,4.6,10"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M642.1,372.6c5.5,0.6,10.3,1.3,10.7,7.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="630.7" y1="279.5" x2="628.5" y2="293.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="631.7" y1="355.4" x2="633.8" y2="362.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="581.5" y1="264.3" x2="585.5" y2="260.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M566,399.4c0-0.9,0.7-1.6,1.6-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M567.7,408.2c-0.3,0-0.6-0.1-0.8-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="566" y1="399.4" x2="566" y2="403.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="567.7" y1="408.2" x2="572.7" y2="408.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M572.8,397.7c0.9,0,1.6,0.7,1.6,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="567.7" y1="397.7" x2="572.8" y2="397.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="574.4" y1="399.3" x2="574.4" y2="403.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M567.9,400.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
        c0.5,0,0.9,0.4,0.9,0.9C568.9,400.5,568.5,400.9,567.9,400.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M572.5,400.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
        c0.5,0,1,0.4,1,0.9C573.4,400.5,573,400.9,572.5,400.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M626.5,399.1c0-0.9,0.7-1.6,1.6-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M628.1,408c-0.9,0-1.7-0.7-1.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="626.5" y1="399.1" x2="626.5" y2="406.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M634.9,406.3c0,0.9-0.7,1.7-1.7,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="628.1" y1="408" x2="633.2" y2="408"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M633.3,397.5c0.9,0,1.6,0.7,1.6,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="628.1" y1="397.5" x2="633.3" y2="397.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M628.4,400.7c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
        c0.5,0,0.9,0.4,0.9,0.9C629.3,400.2,628.9,400.7,628.4,400.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M632.9,400.7c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
        c0.5,0,1,0.4,1,0.9C633.9,400.2,633.5,400.7,632.9,400.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M628.4,406.4c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
        c0.5,0,0.9,0.4,0.9,0.9C629.3,406,628.9,406.4,628.4,406.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M632.9,406.4c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
        c0.5,0,1,0.4,1,0.9C633.9,406,633.5,406.4,632.9,406.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="561.1" y1="423.4" x2="561.1" y2="423.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M586.9,488.7c-2-1.1-3.3-3.8-3.3-6.6c0-4,2.4-7.2,5.4-7.2
        c3,0,5.4,3.2,5.4,7.2c0,3-1.4,5.7-3.4,6.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M586.9,476.5c0-0.5,0.4-1,1-1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M589.9,475.5c0.5,0,0.9,0.4,0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="587.8" y1="475.5" x2="589.9" y2="475.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M587.7,493.6c-0.5,0-0.9-0.4-0.9-0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="586.9" y1="476.5" x2="586.9" y2="492.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M590.9,492.7c0,0.5-0.4,1-1,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="590.9" y1="476.5" x2="590.9" y2="492.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="587.7" y1="493.6" x2="589.9" y2="493.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="586.9" y1="488.7" x2="590.8" y2="488.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M588.9,492.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
        c0.6,0,1.1,0.5,1.1,1.1C590,491.7,589.5,492.2,588.9,492.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="657.8" y1="501.4" x2="652.9" y2="496.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="657.7" y1="501.2" x2="657.7" y2="504.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="658" y1="533.6" x2="652" y2="546.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="656.7" y1="507.6" x2="658" y2="523.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="658.8" y1="533.5" x2="659.8" y2="546.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="663" y1="533.3" x2="664.1" y2="546.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="509.6" y1="662.7" x2="561.8" y2="662.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="565.9" y1="662.7" x2="635.6" y2="662.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="643.4" y1="372.7" x2="643.4" y2="533.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M584.6,295.2c-0.1-0.2-0.4-0.2-1.1-0.2c-0.6,0-0.9,0-1,0.3
        c-0.8,2-0.8,5.9,0,8.3c0.1,0.3,0.4,0.4,1,0.3c0.6,0,1,0,1.1-0.2C585.4,301.2,585.3,297.4,584.6,295.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M563.8,386.4c-1-0.1-2.1,0.8-2.2,1.9l-0.5,7.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="555.7" y1="401.9" x2="554.1" y2="398.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="654.8" y1="364.8" x2="655.7" y2="388.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="655.7" y1="389.7" x2="656.4" y2="405.8"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="652.8,400.4 659.7,410.9 659.7,415.1 652.7,415.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="660.8" y1="415.1" x2="652.8" y2="415.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="550.8" y1="669.9" x2="623.9" y2="669.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="550.8" y1="697.4" x2="623.9" y2="697.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M623.9,669.9l2.8,1.9c2.5,5.1,2.5,19.3,0,23.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="623.9" y1="697.4" x2="626.6" y2="695.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="603.2" y1="666" x2="602.3" y2="669.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="632.6" y1="666.9" x2="632.6" y2="673.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M630.9,675c-1,0-1.7-0.8-1.7-1.7c0,0,0,0,0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M632.6,673.2c0,1-0.8,1.7-1.7,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="629.1" y1="673.2" x2="629.2" y2="666.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="629.2" y1="666.3" x2="629.2" y2="666.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="628.6,666.9 633.4,666.9 633.4,666.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="628.6" y1="666.9" x2="628.6" y2="666.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="533.2" y1="407.9" x2="561.4" y2="407.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="533.2" y1="406.2" x2="561.4" y2="406.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M533.2,406.2c-1.2,0-1.2,1.6,0,1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M561.4,407.9c1.2,0,1.2-1.6,0-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M534.3,406.1c3.3-2.1,19.9-4.3,22.3-4.1c1.5,0.1,4,2.8,4.8,4.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="561.9" y1="405.5" x2="561" y2="405.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="561.9" y1="404.4" x2="560.1" y2="404.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M561.9,405.9c0,0.5,0.1,0.6,0.4,0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M566,403.8c0-0.4,0.4-0.8,0.8-0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M567,407.1c-0.5,0-1-0.4-1-1v-2.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M573.7,402.9c0.5,0,0.9,0.4,0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="566.8" y1="402.9" x2="573.7" y2="402.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M574.6,406.3c0,0.5-0.4,0.8-0.8,0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="574.6" y1="403.9" x2="574.6" y2="406.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="567" y1="407.1" x2="573.7" y2="407.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="567.9" y1="402.9" x2="567.9" y2="407.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="572.5" y1="403" x2="572.5" y2="407.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="561.9" y1="405.9" x2="561.9" y2="404"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M561.9,404c0-0.5,0.1-0.6,0.4-0.6h3.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="562.4" y1="406.5" x2="566.1" y2="406.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="564.2" y1="403.4" x2="564.2" y2="406.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="564.2" y1="404" x2="566" y2="404"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="564.2" y1="405.8" x2="566" y2="405.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M646.3,373.2c0.1-0.7,0-1.3-0.1-2.2c-0.1-0.8-0.6-1.8-1.7-3.1
        c-2.4-2.8-5.2-3.8-7.7-4.5l-1.6-0.4c-2.8-0.7-7.7-1.8-12.6-4.3c-7.4-3.7-10.8-5.3-12.9-8c-0.8-1.1-1.4-2.3-2-4
        c-1.1-3.1-2.8-8.4-3.2-22.8c-0.5-14.4,1.2-20.6,2-25.3c0.5-2.6,0.9-3.9,2.6-5.9c1-1.2,2.3-2.5,3.6-3.2c3.2-1.7,9.5-5.7,11.7-6.9
        c1.7-1,4.4-2.3,5.4-3.1c0.6-0.5,1.1-1,1.4-1.6c0.3-0.5,0.4-1.1,0.4-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M631.4,271.5c-6.3,0.2-12.5,3.6-18.3,6.3c-5.8,2.7-15.1,6.8-17.3,15.3
        c-2.2,8.5-3.2,20.2-3.2,29.9c0,9.7,1.2,21.9,2.9,28.4c1.7,6.6,6.3,9.7,11.2,12.2c4.9,2.4,15.8,6.6,21.4,7.5
        c5.6,1,8.5,1.2,13.8,1.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="631.4" y1="269.8" x2="631.5" y2="276.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="617.3" y1="314.4" x2="604.1" y2="315.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="617.7" y1="333.7" x2="605" y2="332.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M556.9,397.4c-0.4-0.5-1.5-1-2.2-0.7l-4.8,2.6c-0.9,0.5-1,1.5-0.7,2.1"
        />
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="624.6" y1="260.3" x2="628.8" y2="262.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="628.8" y1="262.1" x2="628.8" y2="267.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="589,258.7 589.4,258.6 589.4,260.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="585.5" y1="260.3" x2="624.6" y2="260.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="589.4" y1="260.3" x2="593.3" y2="260.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="591.7,260.3 592.9,258.7 593.3,258.6 593.3,260.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="587.8" y1="260.3" x2="589" y2="258.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="593.3" y1="260.3" x2="597.2" y2="260.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="595.6,260.3 596.8,258.7 597.2,258.6 597.2,260.3 601.1,260.3 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="599.5,260.3 600.7,258.7 601.1,258.6 601.1,260.3 605,260.3 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="603.4,260.3 604.6,258.7 605,258.6 605,260.3 608.9,260.3 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="607.3,260.3 608.5,258.7 608.9,258.6 608.9,260.3 612.9,260.3 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="611.2,260.3 612.4,258.7 612.9,258.6 612.9,260.3 616.8,260.3 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="615.1,260.3 616.3,258.7 616.8,258.6 616.8,260.3 620.7,260.3 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="619,260.3 620.3,258.7 620.7,258.6 620.7,260.3 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M589.2,266.3v-0.4c0-0.6,1.7-2.2,3.4-2.2c1.7,0,3.4,1.6,3.4,2.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="596.1" y1="266.3" x2="596.1" y2="265.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="624.2,266.3 588.1,266.3 588.1,267.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="624.2" y1="266.3" x2="624.2" y2="267.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M612.7,266.3v-0.4c0-0.6,1.7-2.2,3.4-2.2c1.7,0,3.4,1.6,3.4,2.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="619.5" y1="266.3" x2="619.5" y2="265.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M651.6,364.5c1.5,2.7,3.5,7.3,3.6,13.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M567.6,408.2c-0.9-0.1-1.6-0.8-1.6-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M574.4,406.8c-0.1,0.8-0.8,1.4-1.6,1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="634.9" y1="399" x2="634.9" y2="406.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="652.8" y1="388.3" x2="686.1" y2="388.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="652.7" y1="389.7" x2="686.1" y2="389.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="686.1" y1="388.3" x2="686.1" y2="389.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M678.3,526.5c0.7,0.1,1.2,0.7,1.2,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="678.3" y1="526.5" x2="652.8" y2="523.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M679.5,531.2c0,0.7-0.5,1.2-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="679.5" y1="531.2" x2="679.5" y2="527.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="653" y1="533.8" x2="678.3" y2="532.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="558.5" y1="409.4" x2="558.5" y2="490.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="664.6" y1="633.2" x2="645.6" y2="628"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="665.1" y1="631.4" x2="658.5" y2="629.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M665.1,631.4c1.1,0.3,0.7,2.1-0.5,1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="660.4" y1="640.1" x2="646.8" y2="636.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="660.9" y1="638.3" x2="645.4" y2="634.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M660.9,638.3c1.1,0.3,0.7,2.1-0.5,1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="641.2" y1="665.9" x2="686.6" y2="665.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="641.3,664.3 686.6,664.3 686.6,665.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="645.7" y1="627.7" x2="644.4" y2="633"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M640.6,671c-1.2,0-1.2,1.7,0,1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M651,671c1.2,0,1.2,1.7,0,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="641" y1="671" x2="641" y2="669.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="650.5" y1="671" x2="650.5" y2="669"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="640.6" y1="671" x2="651" y2="671"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="640.6" y1="672.6" x2="651" y2="672.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M650.1,664.3c-0.4-2-1.2-3.8-2.6-4.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M650.5,669c0-1.1-0.1-2.1-0.2-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M641,669.3c0.1-3.1-0.7-5.7-1.4-6.5c-0.5-0.6-1.3-1.3-1.8-2.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M638.4,658.7h7.4c0.6,0,1.1,0.1,1.7,0.7c0.7,0.6,1.3,1.4,1.4,1.7
        c0.1,0.3,1.6-0.2,1.2-0.9c-0.8-1.4-2.4-3.3-3.4-3.3h-8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="620.3" y1="550.4" x2="620.3" y2="570.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="620.3" y1="605.7" x2="620.3" y2="624.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M578.8,666.3v0.5c0,0.8,1.8,2.9,3.6,2.8c1.8,0,3.6-2.1,3.5-2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="586" y1="666.9" x2="586" y2="666.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M614.3,667v0.4c0,0.7,1.8,2.3,3.6,2.3c1.8,0,3.6-1.7,3.5-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="621.5" y1="667" x2="621.5" y2="667.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="614.3" y1="667" x2="614.3" y2="666.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="621.5" y1="667" x2="621.5" y2="666.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M660.3,644.6c-1.4-0.3-2.4-1.6-2.4-3c0-0.8,0.3-1.5,0.7-2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M666.3,638.7c1.9,0.2,3.3,1.8,3.3,3.7c0,2-1.7,3.7-3.7,3.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="660.3" y1="644.6" x2="665.1" y2="646.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="661.3" y1="638.6" x2="666.3" y2="638.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M661,643.1c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5
        c0.8,0,1.5,0.7,1.5,1.5C662.5,642.5,661.8,643.1,661,643.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M665.9,644.2c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8
        c1,0,1.8,0.8,1.8,1.8C667.7,643.4,666.9,644.2,665.9,644.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M568.2,303.3c-2.8,3.6-4.7,8.8-4.7,12.8l-3.4,58.9
        c-0.1,3.9,1.5,5.2,3.7,6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M678.4,526.5c0.7,0.1,1.2,0.7,1.2,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="678.4" y1="526.5" x2="652.8" y2="523.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M679.6,531.2c0,0.7-0.5,1.2-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="679.6" y1="531.2" x2="679.6" y2="527.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="653" y1="533.8" x2="678.4" y2="532.4"/>
      <rect x="652.8" y="422.1" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <rect x="652.8" y="422.1" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <rect x="652.8" y="504.3" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <rect x="652.8" y="485.4" fill="none" stroke="#231F20" stroke-width="0.5" width="8" height="3.1"/>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,317.7c-3.1,0-5.6,2.5-5.6,5.5c0,3.1,2.5,5.6,5.5,5.6
          c3.1,0,5.6-2.4,5.6-5.5C663.5,320.3,661.1,317.7,658,317.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,297.8c-14.1-0.1-25.6,11.3-25.7,25.3
          c-0.1,14.1,11.3,25.6,25.3,25.7c14.1,0.1,25.6-11.3,25.7-25.3C683.5,309.4,672.2,297.9,658.1,297.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,297.8c-14.1-0.1-25.6,11.3-25.7,25.3
          c-0.1,14.1,11.3,25.6,25.3,25.7c14.1,0.1,25.6-11.3,25.7-25.3C683.5,309.4,672.2,297.9,658.1,297.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,298.8c-13.5-0.1-24.6,10.8-24.6,24.3
          c-0.1,13.5,10.8,24.6,24.3,24.6c13.5,0.1,24.6-10.8,24.7-24.3C682.5,309.9,671.6,298.9,658.1,298.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M639,336.9c2.1,2.9,4.8,5.2,7.8,6.9l0.5-0.3l4.2-12.7l0-0.6
          c-0.1-0.2-0.5-0.9-0.9-1.5c-0.4-0.5-1-1.2-1.1-1.4l-0.6-0.2l-13.4-0.1l-0.5,0.4C635.6,330.6,636.9,333.9,639,336.9z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,316.2c-3.9,0-7.1,3.1-7.1,7c0,3.9,3.1,7.1,7,7.1
          c3.9,0,7.1-3.1,7.1-7C665.1,319.4,661.9,316.2,658,316.2"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,316.2c-3.9,0-7.1,3.1-7.1,7c0,3.9,3.1,7.1,7,7.1
          c3.9,0,7.1-3.1,7.1-7C665.1,319.4,661.9,316.2,658,316.2z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M650.9,320.8c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4
          c1.3,0,2.4-1.1,2.4-2.4C653.3,321.9,652.2,320.8,650.9,320.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M650.9,321.9c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
          c0.8,0,1.4-0.6,1.4-1.4C652.3,322.5,651.7,321.9,650.9,321.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M639.2,309.5c-2.1,2.9-3.5,6.2-4.1,9.5l0.5,0.4l13.4,0.1l0.6-0.2
          c0.1-0.1,0.7-0.8,1.1-1.3c0.4-0.5,0.9-1.3,0.9-1.5l0-0.6l-4-12.8l-0.5-0.3C644.1,304.3,641.4,306.6,639.2,309.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,315.8c-0.4-1.3-1.8-2-3-1.6c-1.3,0.4-2,1.8-1.6,3.1
          c0.4,1.3,1.8,2,3.1,1.6C657.8,318.4,658.5,317.1,658.1,315.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M657.2,316.1c-0.2-0.7-1-1.2-1.8-0.9c-0.7,0.2-1.2,1-0.9,1.8
          c0.2,0.7,1,1.2,1.8,0.9C657,317.6,657.4,316.9,657.2,316.1"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M665.3,301.2c-3.4-1.1-7-1.4-10.3-1l-0.2,0.6l4.1,12.8l0.4,0.5
          c0.2,0.1,1,0.4,1.6,0.7c0.6,0.2,1.5,0.4,1.7,0.4l0.6-0.2l10.9-7.8l0.2-0.6C671.8,304.2,668.8,302.3,665.3,301.2z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M681.3,323.5c0-3.6-0.8-7.1-2.2-10.1l-0.6,0l-10.9,7.8l-0.4,0.5
          c0,0.2-0.1,1.1-0.1,1.7c0,0.7,0.1,1.6,0.1,1.7l0.4,0.5l10.8,8l0.6,0C680.4,330.5,681.3,327.1,681.3,323.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M662.3,329.5c1.1,0.8,2.6,0.6,3.4-0.5c0.8-1.1,0.6-2.6-0.5-3.4
          c-1.1-0.8-2.6-0.6-3.4,0.5C661,327.2,661.2,328.7,662.3,329.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M662.9,328.7c0.6,0.5,1.5,0.3,2-0.3c0.5-0.6,0.3-1.5-0.3-2
          c-0.6-0.5-1.5-0.3-2,0.3C662.1,327.3,662.2,328.2,662.9,328.7"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M665,345.5c3.4-1.1,6.5-2.9,8.9-5.3l-0.2-0.6l-10.8-7.9l-0.6-0.2
          c-0.2,0-1.1,0.2-1.7,0.4c-0.6,0.2-1.5,0.6-1.6,0.6l-0.4,0.5l-4.2,12.7l0.2,0.6C658.1,346.8,661.6,346.6,665,345.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M653.4,329.3c-0.4,1.3,0.3,2.7,1.5,3.1c1.3,0.4,2.7-0.3,3.1-1.5
          c0.4-1.3-0.3-2.6-1.5-3.1C655.2,327.3,653.9,328,653.4,329.3"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M654.4,329.6c-0.2,0.8,0.2,1.5,0.9,1.8c0.7,0.3,1.5-0.2,1.8-0.9
          c0.2-0.7-0.2-1.6-0.9-1.8C655.5,328.5,654.7,328.9,654.4,329.6"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,317.7c-3.1,0-5.6,2.5-5.6,5.5c0,3.1,2.5,5.6,5.5,5.6
          c3.1,0,5.6-2.4,5.6-5.5C663.5,320.3,661.1,317.7,658,317.7"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,319.4c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9
          c2.1,0,3.9-1.7,3.9-3.9C661.9,321.2,660.1,319.4,658,319.4z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,297.7c-14.1-0.1-25.6,11.3-25.7,25.4
          c-0.1,14.1,11.3,25.6,25.4,25.7c14.1,0.1,25.6-11.3,25.7-25.4C683.6,309.3,672.3,297.8,658.1,297.7z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M658.1,315.8c-0.4-1.3-1.8-2-3-1.6c-1.3,0.4-2,1.8-1.6,3.1
          c0.4,1.3,1.8,2,3.1,1.6C657.8,318.4,658.5,317.1,658.1,315.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M657.2,316.1c-0.2-0.7-1-1.2-1.8-0.9c-0.7,0.2-1.2,1-0.9,1.8
          c0.2,0.7,1,1.2,1.8,0.9C657,317.6,657.4,316.9,657.2,316.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M650.9,320.8c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4
          c1.3,0,2.4-1.1,2.4-2.4C653.3,321.9,652.2,320.8,650.9,320.8z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M650.9,321.9c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
          c0.8,0,1.4-0.6,1.4-1.4C652.3,322.5,651.7,321.9,650.9,321.9z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M653.4,329.3c-0.4,1.3,0.3,2.7,1.5,3.1c1.3,0.4,2.7-0.3,3.1-1.5
          c0.4-1.3-0.3-2.6-1.5-3.1C655.2,327.3,653.9,328,653.4,329.3z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M654.4,329.6c-0.2,0.8,0.2,1.5,0.9,1.8c0.7,0.3,1.5-0.2,1.8-0.9
          c0.2-0.7-0.2-1.6-0.9-1.8C655.5,328.5,654.7,328.9,654.4,329.6z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M662.3,329.5c1.1,0.8,2.6,0.6,3.4-0.5c0.8-1.1,0.6-2.6-0.5-3.4
          c-1.1-0.8-2.6-0.6-3.4,0.5C661,327.2,661.2,328.7,662.3,329.5z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M662.9,328.7c0.6,0.5,1.5,0.3,2-0.3c0.5-0.6,0.3-1.5-0.3-2
          c-0.6-0.5-1.5-0.3-2,0.3C662.1,327.3,662.2,328.2,662.9,328.7z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M665.2,321.1c1.1-0.8,1.3-2.3,0.6-3.4c-0.8-1.1-2.3-1.3-3.4-0.6
          c-1.1,0.8-1.3,2.3-0.6,3.4C662.6,321.7,664.1,321.9,665.2,321.1"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M665.2,321.1c1.1-0.8,1.3-2.3,0.6-3.4c-0.8-1.1-2.3-1.3-3.4-0.6
          c-1.1,0.8-1.3,2.3-0.6,3.4C662.6,321.7,664.1,321.9,665.2,321.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M664.6,320.3c0.6-0.5,0.8-1.3,0.3-2c-0.5-0.6-1.3-0.8-2-0.3
          c-0.6,0.5-0.8,1.3-0.3,2C663.1,320.6,664,320.8,664.6,320.3"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M664.6,320.3c0.6-0.5,0.8-1.3,0.3-2c-0.5-0.6-1.3-0.8-2-0.3
          c-0.6,0.5-0.8,1.3-0.3,2C663.1,320.6,664,320.8,664.6,320.3z"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M658,625.5c-20.7,0-37.4-16.8-37.4-37.4c0-20.7,16.8-37.4,37.4-37.4
          c20.7,0,37.4,16.8,37.4,37.4C695.4,608.7,678.6,625.5,658,625.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M658,629.6c-23,0-41.6-18.6-41.6-41.6c0-23,18.6-41.6,41.6-41.6
          c23,0,41.6,18.6,41.6,41.6C699.5,611,680.9,629.6,658,629.6"/>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,582.5c-3.1,0-5.6,2.5-5.6,5.5c0,3.1,2.5,5.6,5.5,5.6
            c3.1,0,5.6-2.4,5.6-5.5C663.5,585,661.1,582.5,658,582.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,562.6c-14.1-0.1-25.6,11.3-25.7,25.3
            c-0.1,14.1,11.3,25.6,25.3,25.7c14.1,0.1,25.6-11.3,25.7-25.3C683.5,574.2,672.2,562.7,658.1,562.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,562.6c-14.1-0.1-25.6,11.3-25.7,25.3
            c-0.1,14.1,11.3,25.6,25.3,25.7c14.1,0.1,25.6-11.3,25.7-25.3C683.5,574.2,672.2,562.7,658.1,562.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,563.6c-13.5-0.1-24.6,10.8-24.6,24.3
            c-0.1,13.5,10.8,24.6,24.3,24.6c13.5,0.1,24.6-10.8,24.7-24.3C682.5,574.7,671.6,563.7,658.1,563.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M639,601.7c2.1,2.9,4.8,5.2,7.8,6.9l0.5-0.3l4.2-12.7l0-0.6
            c-0.1-0.2-0.5-0.9-0.9-1.5c-0.4-0.5-1-1.2-1.1-1.3l-0.6-0.2l-13.4-0.1l-0.5,0.4C635.6,595.4,636.9,598.7,639,601.7z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,581c-3.9,0-7.1,3.1-7.1,7c0,3.9,3.1,7.1,7,7.1
            c3.9,0,7.1-3.1,7.1-7C665.1,584.2,661.9,581,658,581"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,581c-3.9,0-7.1,3.1-7.1,7c0,3.9,3.1,7.1,7,7.1
            c3.9,0,7.1-3.1,7.1-7C665.1,584.2,661.9,581,658,581z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M650.9,585.6c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4
            c1.3,0,2.4-1.1,2.4-2.4C653.3,586.7,652.2,585.6,650.9,585.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M650.9,586.6c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
            c0.8,0,1.4-0.6,1.4-1.4C652.3,587.3,651.7,586.6,650.9,586.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M639.2,574.3c-2.1,2.9-3.5,6.2-4.1,9.5l0.5,0.4l13.4,0.1l0.6-0.2
            c0.1-0.1,0.7-0.8,1.1-1.3c0.4-0.5,0.9-1.3,0.9-1.5l0-0.6l-4-12.8l-0.5-0.3C644.1,569.1,641.4,571.4,639.2,574.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,580.6c-0.4-1.3-1.8-2-3-1.6c-1.3,0.4-2,1.8-1.6,3.1
            c0.4,1.3,1.8,2,3.1,1.6C657.8,583.2,658.5,581.9,658.1,580.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M657.2,580.9c-0.2-0.7-1-1.2-1.8-0.9c-0.7,0.2-1.2,1-0.9,1.8
            c0.2,0.7,1,1.2,1.8,0.9C657,582.4,657.4,581.6,657.2,580.9"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M665.3,566c-3.4-1.1-7-1.4-10.3-1l-0.2,0.6l4.1,12.8l0.4,0.5
            c0.2,0.1,1,0.4,1.6,0.7c0.6,0.2,1.5,0.4,1.7,0.4l0.6-0.2l10.9-7.8l0.2-0.6C671.8,569,668.8,567.1,665.3,566z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M681.3,588.3c0-3.6-0.8-7.1-2.2-10.1l-0.6,0l-10.9,7.8l-0.4,0.5
            c0,0.2-0.1,1.1-0.1,1.7c0,0.7,0.1,1.6,0.1,1.7l0.4,0.5l10.8,8l0.6,0C680.4,595.3,681.3,591.9,681.3,588.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M662.3,594.2c1.1,0.8,2.6,0.6,3.4-0.5c0.8-1.1,0.6-2.6-0.5-3.4
            c-1.1-0.8-2.6-0.6-3.4,0.5C661,591.9,661.2,593.5,662.3,594.2"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M662.9,593.4c0.6,0.5,1.5,0.3,2-0.3c0.5-0.6,0.3-1.5-0.3-2
            c-0.6-0.5-1.5-0.3-2,0.3C662.1,592.1,662.2,593,662.9,593.4"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M665,610.3c3.4-1.1,6.5-2.9,8.9-5.3l-0.2-0.6l-10.8-7.9l-0.6-0.2
            c-0.2,0-1.1,0.2-1.7,0.4c-0.6,0.2-1.5,0.6-1.6,0.6l-0.4,0.5l-4.2,12.7l0.2,0.6C658.1,611.6,661.6,611.4,665,610.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M653.4,594.1c-0.4,1.3,0.3,2.7,1.5,3.1c1.3,0.4,2.7-0.3,3.1-1.5
            c0.4-1.3-0.3-2.6-1.5-3.1C655.2,592.1,653.9,592.8,653.4,594.1"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M654.4,594.4c-0.2,0.8,0.2,1.5,0.9,1.8c0.7,0.3,1.5-0.2,1.8-0.9
            c0.2-0.7-0.2-1.6-0.9-1.8C655.5,593.3,654.7,593.6,654.4,594.4"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,582.5c-3.1,0-5.6,2.5-5.6,5.5c0,3.1,2.5,5.6,5.5,5.6
            c3.1,0,5.6-2.4,5.6-5.5C663.5,585,661.1,582.5,658,582.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658,584.2c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9
            c2.1,0,3.9-1.7,3.9-3.9C661.9,586,660.1,584.2,658,584.2z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M658.1,562.5c-14.1-0.1-25.6,11.3-25.7,25.4
            c-0.1,14.1,11.3,25.6,25.4,25.7c14.1,0.1,25.6-11.3,25.7-25.4C683.6,574.1,672.3,562.6,658.1,562.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M658.1,580.6c-0.4-1.3-1.8-2-3-1.6c-1.3,0.4-2,1.8-1.6,3.1
            c0.4,1.3,1.8,2,3.1,1.6C657.8,583.2,658.5,581.9,658.1,580.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M657.2,580.9c-0.2-0.7-1-1.2-1.8-0.9c-0.7,0.2-1.2,1-0.9,1.8
            c0.2,0.7,1,1.2,1.8,0.9C657,582.4,657.4,581.6,657.2,580.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M650.9,585.6c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4
            c1.3,0,2.4-1.1,2.4-2.4C653.3,586.7,652.2,585.6,650.9,585.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M650.9,586.6c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
            c0.8,0,1.4-0.6,1.4-1.4C652.3,587.3,651.7,586.6,650.9,586.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M653.4,594.1c-0.4,1.3,0.3,2.7,1.5,3.1c1.3,0.4,2.7-0.3,3.1-1.5
            c0.4-1.3-0.3-2.6-1.5-3.1C655.2,592.1,653.9,592.8,653.4,594.1z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M654.4,594.4c-0.2,0.8,0.2,1.5,0.9,1.8c0.7,0.3,1.5-0.2,1.8-0.9
            c0.2-0.7-0.2-1.6-0.9-1.8C655.5,593.3,654.7,593.6,654.4,594.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M662.3,594.2c1.1,0.8,2.6,0.6,3.4-0.5c0.8-1.1,0.6-2.6-0.5-3.4
            c-1.1-0.8-2.6-0.6-3.4,0.5C661,591.9,661.2,593.5,662.3,594.2z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M662.9,593.4c0.6,0.5,1.5,0.3,2-0.3c0.5-0.6,0.3-1.5-0.3-2
            c-0.6-0.5-1.5-0.3-2,0.3C662.1,592.1,662.2,593,662.9,593.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M665.2,585.9c1.1-0.8,1.3-2.3,0.6-3.4c-0.8-1.1-2.3-1.3-3.4-0.6
            c-1.1,0.8-1.3,2.3-0.6,3.4C662.6,586.4,664.1,586.7,665.2,585.9"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M665.2,585.9c1.1-0.8,1.3-2.3,0.6-3.4c-0.8-1.1-2.3-1.3-3.4-0.6
            c-1.1,0.8-1.3,2.3-0.6,3.4C662.6,586.4,664.1,586.7,665.2,585.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M664.6,585.1c0.6-0.5,0.8-1.3,0.3-2c-0.5-0.6-1.3-0.8-2-0.3
            c-0.6,0.5-0.8,1.3-0.3,2C663.1,585.4,664,585.6,664.6,585.1"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M664.6,585.1c0.6-0.5,0.8-1.3,0.3-2c-0.5-0.6-1.3-0.8-2-0.3
            c-0.6,0.5-0.8,1.3-0.3,2C663.1,585.4,664,585.6,664.6,585.1z"/>
        </g>
      </g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="652.8" y1="517.9" x2="652.8" y2="523.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M634.8,665.8c0,0,0.1-0.5,0.2-1.1c0.1-0.6,0.3-1.3,0.7-2.1
        c3.4-6.6,12.2-22.3,12.2-22.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="644.2" y1="640.5" x2="646.2" y2="641"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="663.2" y1="635.4" x2="662.5" y2="638.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M665,646c0,0,0.2,0.1,0.4,0.1c0.2,0,0.5,0,0.5,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="641.4" y1="666.2" x2="641.4" y2="664"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="637.8" y1="660.6" x2="636.8" y2="660.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M651.4,664.3c0,0,2.6-4.3,4.9-9.4c2.1-4.7,4.2-10.2,4.2-10.2"/>
    </g>
  </g>
  <g id="Layer_3">
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.3" y1="270.4" x2="275.3" y2="405.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.3" y1="409.3" x2="275.3" y2="559.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="292.1" y1="432.9" x2="431.2" y2="432.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.3" y1="399.9" x2="447" y2="399.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="433.6" y1="432.9" x2="433.6" y2="433"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="276.8" y1="256.4" x2="276.8" y2="269.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.9" y1="256.4" x2="445.9" y2="269.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447" y1="269.7" x2="447" y2="407.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447" y1="409.3" x2="447" y2="578.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="446.1" y1="400" x2="439.1" y2="428.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.4" y1="425.2" x2="281.4" y2="569"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.8" y1="426.4" x2="440.9" y2="577.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.3" y1="399.9" x2="277.7" y2="407.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="278.1" y1="409.2" x2="283.8" y2="428.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="402" x2="275.3" y2="402"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447" y1="402" x2="452.3" y2="402"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M403.7,456.1c0-2.8-2.2-5-5-5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M398.4,490.3c2.9,0,5.3-2.4,5.3-5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="403.7" y1="456.1" x2="403.7" y2="485"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321,485.4c0,2.7,2.2,4.9,4.9,4.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="398.4" y1="490.3" x2="325.9" y2="490.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M325.9,451.1c-2.7,0-5,2.2-5,5v29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="398.7" y1="451.1" x2="325.9" y2="451.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="398.7" y1="448" x2="325.9" y2="448"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.9" y1="456" x2="317.9" y2="485.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M325.9,448c-2.2,0-4.2,0.9-5.7,2.4c-1.4,1.4-2.3,3.5-2.3,5.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317.9,485.4c0,2.2,0.9,4.2,2.3,5.6c1.4,1.4,3.4,2.6,5.6,2.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M398.4,493.7c2.3,0,4.7-1.2,6.2-2.7c1.5-1.5,2.4-3.6,2.4-5.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M406.8,456.1c0-2.2-0.9-4.2-2.4-5.7c-1.5-1.5-3.5-2.4-5.7-2.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="398.4" y1="493.7" x2="325.8" y2="493.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="407.1" y1="456.1" x2="407.1" y2="484.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M410.6,270c-0.6-2.7-2.9-4.5-5.6-4.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="410.6" y1="270" x2="438.3" y2="400"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317.7,265.4c-2.6,0-4.8,1.7-5.6,4.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.7" y1="265.4" x2="405" y2="265.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.1" y1="269.6" x2="283.8" y2="400.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="410.5" y1="269.5" x2="449.4" y2="269.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M452.3,272.2c0-1.5-1.2-2.7-2.7-2.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="399.9" x2="452.3" y2="404.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="372.7" x2="452.3" y2="399.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="272.2" x2="452.3" y2="372.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="494.7" x2="452.4" y2="561"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="478.1" x2="452.3" y2="494.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="409.3" x2="452.3" y2="478.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M273.3,269.5c-1.5,0-2.8,1.3-2.8,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="273.3" y1="269.5" x2="312.1" y2="269.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="399.9" x2="270.5" y2="404.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="372.5" x2="270.5" y2="399.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="272.3" x2="270.5" y2="372.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="494.4" x2="270.6" y2="561.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="478.4" x2="270.5" y2="494.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="409.3" x2="270.5" y2="478.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M430.3,432.9c4.4,0.2,8.3-1.9,8.8-4.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M430.3,432.9c4.4,0.2,8.3-1.9,8.8-4.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M430,429.9c2.4,0,4.4-1.3,4.4-2.9c0,0,0-0.1,0-0.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="292" y1="429.8" x2="430" y2="429.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.8,428.2c0.4,2.8,4.3,4.9,8.8,4.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M288.5,427c0,1.6,2,2.8,4.4,2.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M456.5,263.7c0-4-3.3-7.3-7.3-7.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="273.5" y1="256.3" x2="449.2" y2="256.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="456.5" y1="263.7" x2="456.5" y2="270.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M454.4,273.1c1.2,0,2.2-1,2.2-2.2l0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M452.2,271.1c0.1,1.1,1.1,1.9,2.2,1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M449.4,262c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8
        c-1,0-1.8,0.8-1.8,1.8C447.6,261.2,448.4,262,449.4,262"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="276.8" y1="256.4" x2="276.8" y2="269.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M273.1,269.4c-1.5,0-2.7,1.2-2.7,2.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M273.5,256.3c-4,0-7.3,3.3-7.3,7.3v7.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M268.3,273c1.1,0,2-0.8,2.2-1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M266.1,270.8C266.1,270.8,266.1,270.8,266.1,270.8c0,1.2,1,2.2,2.2,2.2"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M273.3,262c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8
        c-1,0-1.8,0.8-1.8,1.8C271.5,261.1,272.3,262,273.3,262"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M460.1,366.7c0.7-0.6,1.1-1.4,1.1-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="373.2" x2="460.1" y2="366.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M461.1,283.1c0-1.8-0.8-3.3-2.1-4.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.3" y1="273" x2="459.1" y2="278.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="461.1" y1="283.1" x2="461.1" y2="364.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="285.1" y1="533.5" x2="285.1" y2="618"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M289.4,533.8c0,0,0-0.1,0-0.1c0-2.4-1-4.3-2.2-4.3
        c-1.2,0-2.2,1.9-2.2,4.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M287.2,622.3c1.2,0,2.2-2,2.2-4.4c0,0,0-0.1,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M285.1,618c0,2.4,1,4.3,2.2,4.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="289.4" y1="533.5" x2="289.4" y2="617.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="437.6" y1="533.5" x2="437.6" y2="618"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M437.5,533.6c0-2.4-1-4.3-2.2-4.3c-1.2,0-2.2,1.9-2.2,4.3
        c0,0,0,0.1,0,0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M435.4,622.3c1.2,0,2.2-1.9,2.2-4.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M433.2,617.9C433.2,617.9,433.2,617.9,433.2,617.9c0,2.5,1,4.4,2.2,4.4"
        />
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="433.2" y1="533.5" x2="433.2" y2="617.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M261.9,364.4c0,0.9,0.4,1.7,1.1,2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.7" y1="373.2" x2="262.9" y2="366.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M263.9,278.7c-1.3,1.1-2,2.7-2,4.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.7" y1="273" x2="263.9" y2="278.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="261.9" y1="283.1" x2="261.9" y2="364.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M439.1,408.5c0-0.1,0-0.1,0-0.2c0-1.5-2.4-2.6-5.3-2.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="439.1" y1="408.5" x2="434.4" y2="427"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="433.9" y1="405.7" x2="393.6" y2="405.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="377.2" y1="405.7" x2="321.9" y2="405.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M402.8,262.4c0-1.5-1.2-2.8-2.8-2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="402.8" y1="262.4" x2="402.8" y2="265.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321.8,259.7c-1.6,0-2.9,1.3-2.9,2.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="400" y1="259.7" x2="321.8" y2="259.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="318.8" y1="262.6" x2="318.8" y2="265.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="444.1" y1="267.8" x2="412.4" y2="267.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M444.1,267.8c1.1,0,1.2,1,1.2,1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M412.4,267.8c-1.1,0-1.2,1-1.2,1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443.3,267.8v-0.3c0-0.6-1.7-2.2-3.4-2.2c-1.7,0-3.4,1.6-3.4,2.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="436.5" y1="267.8" x2="436.5" y2="267.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="278.4" y1="267.6" x2="310.1" y2="267.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M278.4,267.6c-1.1,0-1.2,1-1.2,1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M310.1,267.6c1.1,0,1.2,1,1.2,1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.2,267.6v-0.3c0-0.6,1.7-2.2,3.4-2.2c1.7,0,3.4,1.6,3.4,2.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="286" y1="267.7" x2="286" y2="267.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="370.6" y1="404.5" x2="367" y2="404.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M436.1,404.5c0.6,0,1.1-0.5,1.1-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="436.1" y1="401.1" x2="367.1" y2="401.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="431.6" y1="405.7" x2="431.6" y2="404.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428.2" y1="404.5" x2="428.2" y2="405.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M377.1,405.4c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M379.1,404.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.6,0.4-1.6,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="396.1" y1="406" x2="396.1" y2="407"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="396.1" y1="406.9" x2="406.3" y2="407.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="396.1" y1="406.1" x2="406.7" y2="406.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="378.5" y1="406.3" x2="396.1" y2="407"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="378.8" y1="404.5" x2="396.1" y2="406"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="425.2" y1="407.6" x2="408.4" y2="407.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="406.4" y1="407.6" x2="388.5" y2="407.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="425.2" y1="408.4" x2="410.2" y2="408.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="408" y1="408.4" x2="388.5" y2="408.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="406.4" y1="407.7" x2="406.7" y2="406.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="406.4" y1="407.7" x2="408.5" y2="408.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="406.7" y1="406.8" x2="408.7" y2="407.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="408.5" y1="408.6" x2="410.3" y2="408.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="408.7,407.7 410.2,407.7 410.2,408.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="425.2" y1="408.4" x2="425.2" y2="407.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="388.5" y1="408.4" x2="388.5" y2="407.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.8" y1="404.5" x2="427.8" y2="402.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="424.4" y1="402.8" x2="424.4" y2="404.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.8,402.7c0-0.7-0.5-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M425.8,401.5c-0.7,0-1.3,0.6-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.6" y1="401.5" x2="425.8" y2="401.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432" y1="405.4" x2="431.6" y2="405.4"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="428.2,405.4 424.1,405.4 424.1,404.5 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432" y1="405.4" x2="432" y2="404.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="370.2" y1="405.7" x2="370.2" y2="404.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.5" y1="404.5" x2="373.5" y2="405.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.7" y1="405.4" x2="370.2" y2="405.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.5" y1="405.4" x2="377" y2="405.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.7" y1="405.4" x2="369.7" y2="404.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="436.1" y1="404.5" x2="370.5" y2="404.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="437.2" y1="402.1" x2="437.2" y2="403.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M437.2,402.2C437.2,402.2,437.2,402.2,437.2,402.2
        c0-0.6-0.5-1.1-1.1-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="446.9" y1="401.8" x2="440.9" y2="426.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277" y1="409.4" x2="281.2" y2="423.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M365.9,403.4c0,0.6,0.5,1.1,1.1,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="365.9" y1="402.2" x2="365.9" y2="403.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M367,401.1c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="377.3" y1="404.5" x2="377.3" y2="402.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.9" y1="402.8" x2="373.9" y2="404.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M377.3,402.7c0-0.7-0.5-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M375.2,401.5c-0.7,0-1.3,0.6-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="376" y1="401.5" x2="375.2" y2="401.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="444.9" y1="404.9" x2="443.9" y2="404.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.5" y1="404.9" x2="437.1" y2="404.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443,408.7l1.5-6.2c0-0.5-0.5-0.9-1.6-0.9c-0.9,0-1.6-0.2-1.9,0.9
        l-1.2,5.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.6" y1="409.5" x2="442.2" y2="409.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M439.8,408c-0.2,1,0.1,1.4,0.8,1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443,408.7c-0.2,0.7-0.3,0.9-0.8,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="284.9" y1="404.9" x2="288.2" y2="404.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M461.3,538.5c0-1.6-1-3-2.4-3.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M458.7,640.8c1.6-0.5,2.6-2,2.6-3.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.4" y1="560.8" x2="452.4" y2="643.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.6" y1="561.1" x2="270.6" y2="644.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="461.3" y1="538.2" x2="461.3" y2="637.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="458.7" y1="640.8" x2="452.6" y2="644.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="458.9" y1="534.9" x2="452.4" y2="531.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M261.9,637.3c0,1.7,1.1,3.1,2.6,3.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="261.9" y1="538.4" x2="261.9" y2="637.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264.2,535.1c-1.5,0.7-2.3,2.1-2.4,3.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.5" y1="641" x2="270.6" y2="644.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.2" y1="535.1" x2="270.5" y2="532.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="443.6" y1="425.3" x2="443.6" y2="576.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="279.5" y1="424.9" x2="279.5" y2="564.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443.6,425.3c0-4.1-2.7-4.7-2.7,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="267.3" y1="494.2" x2="270.5" y2="494.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M377,406.3c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0l16.7,0.1l0.8-0.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="377" y1="405.4" x2="377" y2="406.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="379.4" y1="404.5" x2="379.4" y2="406.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M305.4,405.4c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.4,404.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.6,0.4-1.6,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="324.5" y1="406" x2="324.4" y2="407"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="324.4" y1="406.9" x2="334.6" y2="407.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="324.4" y1="406.1" x2="335" y2="406.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.9" y1="406.3" x2="324.4" y2="407"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="307.1" y1="404.5" x2="324.5" y2="406"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="353.5" y1="407.5" x2="336.7" y2="407.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.7" y1="407.5" x2="316.8" y2="407.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="353.5" y1="408.4" x2="338.5" y2="408.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="336.3" y1="408.4" x2="316.8" y2="408.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.7" y1="407.7" x2="335" y2="406.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.7" y1="407.7" x2="336.8" y2="408.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335" y1="406.8" x2="337" y2="407.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="336.8" y1="408.6" x2="338.6" y2="408.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="337,407.7 338.5,407.7 338.5,408.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="353.5" y1="408.4" x2="353.5" y2="407.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="316.8" y1="408.4" x2="316.8" y2="407.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M305.3,406.3c0,0.5,0.7,0.9,1.6,0.9c0,0,0.1,0,0.1,0l16.7,0.1l0.8-0.3"
        />
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="305.4" y1="405.4" x2="305.3" y2="406.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="307.7" y1="404.5" x2="307.7" y2="406.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="305.3" y1="405.7" x2="289.1" y2="405.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="290.7" y1="404.6" x2="287.2" y2="404.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M356.2,404.6c0.6,0,1.1-0.5,1.1-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="356.2" y1="401.2" x2="287.2" y2="401.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="351.7" y1="405.8" x2="351.7" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="348.3" y1="404.6" x2="348.3" y2="405.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="348" y1="404.6" x2="348" y2="402.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="344.6" y1="402.9" x2="344.6" y2="404.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M348,402.8c0-0.7-0.5-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M345.9,401.6c-0.7,0-1.3,0.6-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="346.7" y1="401.6" x2="345.9" y2="401.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.1" y1="405.5" x2="351.7" y2="405.5"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="348.3,405.5 344.2,405.5 344.2,404.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.1" y1="405.5" x2="352.1" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="290.3" y1="405.8" x2="290.3" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.7" y1="404.6" x2="293.7" y2="405.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="289.9" y1="405.5" x2="290.3" y2="405.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.7" y1="405.5" x2="297.6" y2="405.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="297.8" y1="405.6" x2="297.8" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="289.9" y1="405.5" x2="289.9" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="356.2" y1="404.6" x2="308.8" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.1" y1="404.6" x2="298.3" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="298.4" y1="404.6" x2="290.6" y2="404.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="357.3" y1="402.2" x2="357.3" y2="403.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M357.3,402.3C357.3,402.3,357.3,402.3,357.3,402.3
        c0-0.6-0.5-1.1-1.1-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M286,403.5c0,0.6,0.5,1.1,1.1,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="286" y1="402.3" x2="286" y2="403.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M287.1,401.2c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="297.4" y1="404.6" x2="297.4" y2="402.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="294" y1="402.9" x2="294" y2="404.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M297.4,402.8c0-0.7-0.5-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M295.4,401.6c-0.7,0-1.3,0.6-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.2" y1="401.6" x2="295.4" y2="401.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282" y1="519" x2="440.6" y2="518.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.6,378.4c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7
        c-1.5,0-2.7,1.2-2.7,2.7C276.9,377.2,278.1,378.4,279.6,378.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.6,377.2c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
        c-0.8,0-1.5,0.7-1.5,1.5C278.1,376.5,278.8,377.2,279.6,377.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.6,376.4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C279,376.1,279.2,376.4,279.6,376.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277.9" y1="409.3" x2="260.3" y2="409.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.9,407.6c1.2,0,1.2,1.6,0,1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M260.4,409.3c-1.2,0-1.2-1.6,0-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="269" y1="400.6" x2="269" y2="404.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="400" x2="269.6" y2="400"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M269.7,400c-0.5,0-0.7,0.3-0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277.8" y1="407.6" x2="260.4" y2="407.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.5,407.5c-1.6-3.1-2.2-2.8-8.9-2.8c-7.2-0.1-6.6-0.2-8.3,2.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.8" y1="409.3" x2="463.5" y2="409.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.5,407.6c-1.2,0-1.2,1.6,0,1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M463.3,409.3c1.2,0,1.2-1.6,0-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.7" y1="400.6" x2="454.7" y2="404.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="453.2" y1="400" x2="454.1" y2="400"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M454,400c0.5,0,0.7,0.3,0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.9" y1="407.6" x2="463.3" y2="407.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.2,407.5c1.6-3.1,2.2-2.8,8.9-2.8c7.2-0.1,6.6-0.2,8.3,2.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M288.5,426.9C288.5,426.9,288.5,427,288.5,426.9c0,1.7,2,2.9,4.4,3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M289,405.7c-2.9,0-5.2,1.2-5.2,2.6c0,0.1,0,0.1,0,0.2l4.7,18.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="276.9" y1="404.9" x2="279" y2="404.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="404.9" x2="285.8" y2="404.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.9,408.7l-1.5-6.2c0-0.5,0.5-0.9,1.6-0.9c0.9,0,1.6-0.2,1.9,0.9
        l1.2,5.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.2" y1="409.5" x2="280.7" y2="409.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.1,408c0.2,1-0.1,1.4-0.8,1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.9,408.7c0.2,0.7,0.3,0.9,0.8,0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.4,425.2c-0.2-3.5-1.9-2.3-1.9-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="504.8" x2="281.4" y2="504.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.8" y1="504.8" x2="452.3" y2="504.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M268.7,478.2c-0.8,0-1.4,0.6-1.4,1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="478.2" x2="268.7" y2="478.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="267.3" y1="479.5" x2="267.3" y2="494.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.6" y1="479.4" x2="268.6" y2="489.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.5" y1="479.4" x2="268.6" y2="479.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.6" y1="489.2" x2="270.5" y2="489.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="455.5" y1="494.2" x2="452.3" y2="494.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M455.5,479.5c0-0.8-0.6-1.4-1.4-1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.4" y1="478.2" x2="454.2" y2="478.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="455.5" y1="479.5" x2="455.5" y2="494.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.3" y1="479.4" x2="454.3" y2="489.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.4" y1="479.4" x2="454.3" y2="479.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.3" y1="489.2" x2="452.3" y2="489.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="436.1,432.6 430.7,518.9 430.7,592 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.3" y1="559.5" x2="275.3" y2="664.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447" y1="578.3" x2="447" y2="664.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.4" y1="568.8" x2="281.4" y2="661.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.9" y1="577" x2="441" y2="661.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.4" y1="643.8" x2="452.4" y2="664.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.6" y1="644" x2="270.7" y2="664.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="397.7" y1="698.4" x2="324.6" y2="698.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M324.6,670.8l-2.8,1.9c-2.5,5.1-2.5,19.3,0,23.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="324.6" y1="698.4" x2="321.8" y2="696.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M397.7,670.8l2.8,1.9c2.5,5.1,2.5,19.3,0,23.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="397.7" y1="698.4" x2="400.4" y2="696.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="341.8" y1="670.8" x2="324.6" y2="670.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="361.1" y1="670.8" x2="341.8" y2="670.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="381.8" y1="670.8" x2="361.1" y2="670.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="397.7" y1="670.8" x2="381.8" y2="670.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M282.3,664.5v0.9c0,0.7-1.8,2.3-3.6,2.3c-1.8,0-3.6-1.7-3.5-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.1" y1="665" x2="275.1" y2="665.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.1" y1="665" x2="275.1" y2="664.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M449.9,665.2v0.4c0,0.7-1.8,2.3-3.6,2.3c-1.8,0-3.6-1.7-3.5-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.8" y1="665.2" x2="442.8" y2="665.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="449.9" y1="665.2" x2="449.9" y2="664.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.8" y1="665.2" x2="442.8" y2="664.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.8" y1="664.3" x2="381.6" y2="671.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="339.1" y1="664.6" x2="342" y2="670.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.2" y1="661.5" x2="441.2" y2="661.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.9,664.5c1.5,0,2.7-1.2,2.7-2.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="443.6" y1="576.2" x2="443.6" y2="662"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.6" y1="664.5" x2="441" y2="664.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="339" y1="664.5" x2="384.6" y2="664.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="664.5" x2="339" y2="664.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.7" y1="664.5" x2="282.4" y2="664.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.4" y1="664.5" x2="274.7" y2="664.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="449.6" y1="664.5" x2="452.7" y2="664.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.6" y1="664.5" x2="449.6" y2="664.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="441" y1="664.5" x2="442.6" y2="664.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.5,661.7c0,1.6,1.3,2.8,3,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="279.5" y1="564.4" x2="279.5" y2="661.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="430.7" y1="591.9" x2="430.7" y2="661.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="292.3" y1="596.3" x2="292.3" y2="661.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="287.4,432.1 292.3,519.4 292.3,596.4 		"/>
    </g>
  </g>
  <g id="Layer_4">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M304.8,873.6l-0.3-53.9l0-3.4l0-8.4l0.2,0h108l1,0l0,8.5l0,3.3
        l-0.3,53.7l-2.4,0.1v15l-5,0.2v-9.4l0-5.6c-27.3,1.1-66.6,0.8-93.7,0.1l0,5v9.4l-5.1-0.2v-14.3L304.8,873.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,820c-0.7-0.4-1-0.5-2.3,0.2c-1.4,0.7-2,1-3.1,1.4
        c-1.1,0.4-2.7,0.1-2.5-1.8v-3.6c-0.2-1.9,1.5-2.1,2.5-1.8c1.1,0.4,1.7,0.7,3.1,1.4c1.4,0.7,1.7,0.6,2.3,0.2
        c0.5-0.5,1.1-0.8,1.9-0.8c1.5,0,2.6,1.2,2.6,2.8c0,1.5-1.2,2.8-2.6,2.8C415.8,820.8,415.1,820.5,414.6,820"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M407.9,816.5v2.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.9
        c0-0.4-0.3-0.7-0.7-0.7C408.2,815.7,407.9,816.1,407.9,816.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="304.4" y1="807.9" x2="304.4" y2="816.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M411.4,873.5c-0.1,0-0.3,0-0.4,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M405.9,873.7c-27.3,1.1-66.6,0.8-93.7,0.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.3" y1="883.8" x2="302.1" y2="895.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="304.5,819.7 304.8,873.6 307.2,873.7 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M296.6,880.6c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C296.1,880.7,296.4,880.8,296.6,880.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M417.2,894.9c-33.1,1.7-88.8,1.3-115.1,0.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.2" y1="877.8" x2="307.2" y2="877.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.2" y1="877.9" x2="405.9" y2="878.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="411" y1="878.2" x2="448" y2="878.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447.9" y1="884.3" x2="417.2" y2="894.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="413.7" y1="807.9" x2="413.7" y2="816.4"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="413.7,819.7 413.3,873.4 411,873.5 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M421.8,824.8c-0.1-0.7-1-1.6-3.6-1.6c-2.8,0-3.9,0.9-3.9,1.8
        c0,1.1,1.5,2.1,3.8,2.1c2.2,0,3.4-1,3.6-1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M421.8,825c0-0.6-0.8-1.8-3.6-1.8c-2.8,0-3.9,0.9-3.9,1.8
        c0,1.1,1.5,2.1,3.8,2.1C420.5,827.1,421.8,825.9,421.8,825"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M421,824c-0.5-0.2-1.1,0-1.4,0.6c-0.2,0.6,0,1.2,0.5,1.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M415.2,826.1c0.5,0.2,1.1,0,1.4-0.6c0.2-0.6,0-1.2-0.5-1.4
        c-0.5-0.2-1.1,0-1.4,0.6C414.5,825.2,414.7,825.9,415.2,826.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M419.7,823.3c-0.6-0.2-0.9,0.3-1,0.8c-0.1,0.5-0.1,1.4,0,2
        c0.1,0.6,0.3,0.9,1,0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M416.5,823.3c0.6-0.2,0.9,0.3,1,0.8c0.1,0.5,0.1,1.3,0,1.9
        c-0.1,0.6-0.1,1.1-0.8,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="378.8" y1="889.8" x2="390.6" y2="889.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M382.9,892.6c-3.4,0-4.8-3.5-4.8-5.8c0-1.6,0.8-5.2,5.1-5.2
        c1.6,0,1.6,0,3.1,0c4.2,0,5.1,3.5,5.1,5.2c0,2.3-1.4,5.8-4.8,5.8C383.1,892.6,386.3,892.6,382.9,892.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="382" y1="887.5" x2="386.6" y2="887.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="382" y1="884" x2="386.6" y2="884"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="382" y1="884" x2="382" y2="887.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="386.6" y1="884" x2="386.6" y2="887.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M416.9,890.8c0-0.9-0.7-1.7-1.6-1.7c-0.9,0-1.6,0.7-1.6,1.7
        c0,0.9,0.7,1.7,1.6,1.7C416.1,892.5,416.9,891.8,416.9,890.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M417.9,890.8c0-1.5-1.2-2.8-2.7-2.8c-1.5,0-2.7,1.2-2.7,2.8
        c0,1.5,1.2,2.8,2.7,2.8C416.7,893.6,417.9,892.4,417.9,890.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M376.2,892.5c0-0.9-0.7-1.7-1.6-1.7c-0.9,0-1.6,0.8-1.6,1.7
        c0,0.9,0.7,1.7,1.6,1.7C375.5,894.2,376.2,893.4,376.2,892.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M365.3,892.8c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C364.9,893.7,365.3,893.3,365.3,892.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M365.3,888.6c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C364.9,889.6,365.3,889.1,365.3,888.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M365.3,883.2c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C364.9,884.2,365.3,883.8,365.3,883.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M354.9,892.8c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C354.5,893.7,354.9,893.3,354.9,892.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M354.9,888.6c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C354.5,889.6,354.9,889.1,354.9,888.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M354.9,883.2c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C354.5,884.2,354.9,883.8,354.9,883.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M345.3,892.5c0-0.9-0.7-1.7-1.6-1.7c-0.9,0-1.6,0.8-1.6,1.7
        c0,0.9,0.7,1.7,1.6,1.7C344.6,894.2,345.3,893.4,345.3,892.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M304.5,890.8c0-0.9-0.7-1.7-1.6-1.7c-0.9,0-1.6,0.7-1.6,1.7
        c0,0.9,0.7,1.7,1.6,1.7C303.8,892.5,304.5,891.8,304.5,890.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M305.6,890.8c0-1.5-1.2-2.8-2.7-2.8c-1.5,0-2.7,1.2-2.7,2.8
        c0,1.5,1.2,2.8,2.7,2.8C304.4,893.6,305.6,892.4,305.6,890.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M301,880.7c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C300.5,880.8,300.8,880.9,301,880.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.5,882.6c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C303,882.7,303.3,882.7,303.5,882.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M296.3,891.8c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C295.7,891.9,296.1,892,296.3,891.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="405.9" y1="879.3" x2="411" y2="879.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="405.9" y1="879.3" x2="405.9" y2="888.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="411" y1="879.1" x2="411" y2="888.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="405.9" y1="888.7" x2="411" y2="888.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M419.1,818c0-1.5-1.2-2.8-2.6-2.8c-0.7,0-1.4,0.3-1.9,0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,820c0.5,0.5,1.2,0.8,1.9,0.8c1.5,0,2.6-1.2,2.6-2.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M417.8,818c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C417.2,819.4,417.8,818.8,417.8,818"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,816.1c-0.7,0.4-1,0.5-2.3-0.2c-1.4-0.7-2-1-3.1-1.4
        c-1.1-0.4-2.7-0.1-2.5,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,820c-0.7-0.4-1-0.5-2.3,0.2c-1.4,0.7-2,1-3.1,1.4
        c-1.1,0.4-2.7,0.1-2.5-1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="406.7" y1="816.2" x2="406.7" y2="819.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.2" y1="878.8" x2="307.2" y2="878.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.2" y1="878.8" x2="312.2" y2="888.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="307.2" y1="878.6" x2="307.2" y2="888"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.2" y1="888.2" x2="307.2" y2="888"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M398.2,875.9c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C397.6,877.3,398.2,876.7,398.2,875.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M393.5,876c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C392.9,877.4,393.5,876.8,393.5,876"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M362.6,876.2c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C362,877.5,362.6,876.9,362.6,876.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M358.2,876.2c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C357.6,877.5,358.2,876.9,358.2,876.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.3,876c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C326.7,877.3,327.3,876.7,327.3,876"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M323.2,876c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C322.6,877.3,323.2,876.7,323.2,876"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.6,867.8c0.9-0.8,0.9-2.2,0.1-3.2c-0.8-1-2.1-1.1-3-0.3
        c-0.9,0.8-0.9,2.2-0.1,3.2C268.4,868.4,269.7,868.6,270.6,867.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M271.8,869.2c1.6-1.4,1.7-4,0.2-5.7c-1.4-1.7-3.9-2-5.4-0.6
        c-1.6,1.4-1.7,4-0.2,5.7C267.8,870.3,270.2,870.6,271.8,869.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M276.9,897.7c1-1.6,0.5-3.8-1.2-5c-1.7-1.1-3.8-0.7-4.8,0.9
        c-1,1.6-0.5,3.8,1.2,5C273.8,899.7,275.9,899.3,276.9,897.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.9,898.4c1.3-2.2,0.6-5.1-1.6-6.6c-2.2-1.5-5.1-0.9-6.4,1.3
        c-1.3,2.2-0.6,5.1,1.6,6.6C273.7,901.1,276.6,900.5,277.9,898.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M273.1,891c1.5-1.7,4.6-3.7,5.8-4.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M290.2,890.9c-4,1.3-9.8,2.7-11.6,5.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M272.1,884.7c-1.6,2.8-2.9,5.9-4.6,7.1c-1.7,1.3-3.7,0.4-3.4,4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.2" y1="877.8" x2="270.3" y2="883.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.5" y1="883.4" x2="263.5" y2="828.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M284.8,871.4c2.6-1.8,3.2-5.5,1.4-8.4c-1.8-2.9-5.4-3.8-8-2
        c-2.6,1.8-3.2,5.5-1.4,8.4C278.6,872.3,282.2,873.2,284.8,871.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M286.1,870.4c2.5-1.7,3.2-5.4,1.4-8.2c-1.8-2.8-5.3-3.7-7.8-2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.1,830.5c2-0.9,2.8-3.4,1.9-5.5c-0.9-2.2-3.2-3.2-5.2-2.3
        c-1.9,0.9-2.8,3.4-1.9,5.5C265.8,830.3,268.2,831.4,270.1,830.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M271.3,830c2-0.9,2.8-3.4,1.9-5.6c-0.9-2.2-3.3-3.2-5.2-2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M263.5,828.7c-0.1-3.4,0.6-7.4,4.6-8.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.1" y1="820" x2="268.1" y2="813.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.1" y1="820.2" x2="268.1" y2="807.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M272.7,868.3c1.5-1.4,1.6-3.9,0.2-5.6c-1.4-1.7-3.8-2-5.3-0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="272.1" y1="868.9" x2="272.7" y2="868.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.9" y1="869.1" x2="272.8" y2="868.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="267.6" y1="862.1" x2="266.9" y2="862.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="266.7" y1="822.7" x2="268" y2="822.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270" y1="830.5" x2="271.3" y2="830"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M275.4,865.5c0-3-2.3-5.5-5.1-5.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.1,859.6c-1.5-0.3-1.8-1-1.9-2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.3,859.7c-1.7-0.2-2.1-1-2.1-2.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.2" y1="856.9" x2="267.9" y2="833.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.2" y1="857.1" x2="267.9" y2="833.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.5" y1="883.4" x2="268.9" y2="883.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.4" y1="865.5" x2="275.6" y2="877.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M289.7,824c-2.2-0.8-3.6-1.2-6.2-1.1c-2.4,0.1-7.4,1.4-7.8,3.4
        c-0.4,1.8-0.5,4.8,2.1,4.8c2.5,0.1,7.2,0,9-0.1c1.8-0.1,1.9,0,3.3-1c1.3-1,1.6-1.4,1.6-2.6C291.5,825.6,291.3,824.7,289.7,824"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M287.8,825.4c-1.7-0.6-3.3-0.8-5.3-0.7c-1.9,0-6.2,0.6-6.7,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.7,828.6c0.3-0.1,0.5-0.6,0.3-0.9c-0.2-0.4-0.5-0.5-0.9-0.4
        c-0.3,0.1-0.5,0.6-0.3,0.9C281,828.6,281.4,828.7,281.7,828.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M288.2,830.4c1-0.8,1.1-1.3,1-2.3c0-1.3-0.2-2.1-1.5-2.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275" y1="826.3" x2="275" y2="808.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M267.9,833.5c0-0.9,0.4-1.7,1.8-1.7c0.8,0,1.6-0.1,2.3-0.4
        c1.9-0.9,3.1-2.9,3.1-5.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M286,876.2c0.6-0.4,0.8-1.4,0.4-2.1c-0.5-0.7-1.3-0.9-2-0.5
        c-0.6,0.4-0.8,1.4-0.4,2.1C284.5,876.4,285.4,876.7,286,876.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M290.4,876.3c0.6-0.4,0.8-1.4,0.4-2.1c-0.4-0.7-1.3-0.9-2-0.5
        c-0.6,0.4-0.8,1.4-0.4,2.1C288.9,876.5,289.8,876.7,290.4,876.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.5" y1="872.2" x2="265.6" y2="872.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.5" y1="877" x2="265.6" y2="877"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.5" y1="872.2" x2="274.5" y2="877"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="265.6" y1="872.2" x2="265.6" y2="877"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.9" y1="883.4" x2="268.8" y2="877"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M290.1,880.4c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C289.5,880.5,289.9,880.6,290.1,880.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M296.6,880.6c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C296.1,880.7,296.4,880.8,296.6,880.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M276.9,880.3c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C276.4,880.4,276.7,880.4,276.9,880.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M274.1,880.2c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C273.6,880.3,273.9,880.4,274.1,880.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M271.9,882.2c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C271.3,882.3,271.6,882.4,271.9,882.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.8,885c0.2-0.2,0.3-0.5,0.1-0.8c-0.2-0.3-0.5-0.3-0.7-0.2
        c-0.2,0.2-0.3,0.5-0.1,0.8C277.2,885,277.5,885.1,277.8,885"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.9,919.9c2.3-7.1,3.1-15.6,3.4-20.7c1.5-1.9,2-1.9,2.3-3.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.5,885.1c-0.1,1.7-0.4,4.1-1.6,5.4c-1.2,1.3-6.9,2.5-11,2.6
        c-3.8,0.1-8.8,0.3-11.6,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M284.9,892.8c0.3,0,0.6,0,0.8,0c3.8,0.1,8.2,0.3,10.9,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M273,884.8c0.1,1.6,0.4,3.7,1.3,5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M378,899.5c-10.8,0.1-24,0.1-32.1-0.1c-4.8,0-11-0.9-16-3.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M378,899.5c4.1,0,7.2-1,11.2-3.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M265.9,924.7c0.1,1.2,1.1,2.1,2.2,2.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.8,926.1c0.2-0.4,0.3-0.8,0.3-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.1,927c0.7,0,1.3-0.4,1.7-0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="302.1" y1="927" x2="268.1" y2="926.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="265.9" y1="924.7" x2="264.1" y2="895.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="304.1" y1="924.9" x2="302.1" y2="895.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M266.8,924.6c0,0.7,0.6,1.2,1.3,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="266.8" y1="924.6" x2="265.1" y2="896.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M266.3,895.3c-0.5,0-0.8,0.2-1.1,0.5c-0.2,0.3-0.2,0.6-0.1,0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303,925.6c0.1-0.2,0.2-0.5,0.2-0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302,926.1c0.4,0,0.8-0.2,1-0.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="302" y1="926.2" x2="268.1" y2="925.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.1" y1="895.5" x2="279.6" y2="895.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="269.2" y1="895.3" x2="266.2" y2="895.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M301.3,896.8c0-0.7-0.6-1.2-1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="303.2" y1="924.9" x2="301.3" y2="896.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M447.5,868.3c0.9,0.8,2.2,0.6,3-0.3c0.8-1,0.7-2.4-0.1-3.2
        c-0.9-0.8-2.2-0.6-3,0.3C446.6,866.1,446.6,867.5,447.5,868.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.3,869.7c1.6,1.4,4,1.1,5.4-0.6c1.4-1.7,1.3-4.3-0.2-5.7
        c-1.6-1.4-4-1.1-5.4,0.6C444.7,865.7,444.8,868.3,446.3,869.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446,885.2c1.6,2.8,2.9,5.9,4.6,7.1c1.7,1.3,3.7,0.4,3.4,4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447.9" y1="878.3" x2="447.8" y2="884.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="453.6" y1="883.8" x2="454.6" y2="829.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M433.3,871.9c2.6,1.8,6.1,0.9,8-2c1.8-2.9,1.2-6.6-1.4-8.4
        c-2.6-1.8-6.2-0.9-8,2C430.1,866.4,430.7,870.1,433.3,871.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M438.4,860.7c-2.5-1.7-6-0.9-7.8,2c-1.8,2.8-1.1,6.5,1.4,8.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M448,831c2,0.9,4.3-0.1,5.2-2.3c0.9-2.2,0.1-4.6-1.9-5.5
        c-2-0.9-4.3,0.1-5.2,2.3C445.2,827.6,446,830.1,448,831"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M450.1,822.6c-2-0.9-4.3,0.1-5.2,2.3c-0.9,2.2-0.1,4.7,1.9,5.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M454.6,829.2c0.1-3.4-0.4-7.3-4.4-8.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="450" y1="820.5" x2="450" y2="815"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="450" y1="820.7" x2="450" y2="807.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M450.5,862.6c-1.5-1.4-3.9-1.1-5.3,0.6c-1.4,1.7-1.3,4.2,0.2,5.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="446.2" y1="869.5" x2="445.3" y2="868.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="450.5" y1="862.6" x2="451.2" y2="863.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="450.4" y1="862.5" x2="451.4" y2="863.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="451.2" y1="823.1" x2="450.4" y2="822.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="451.4" y1="823.2" x2="450.2" y2="822.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447.9" y1="830.9" x2="447.1" y2="830.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="448.1" y1="831" x2="446.9" y2="830.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M420.1,826.1c0.5,0.2,1.1,0,1.3-0.6c0.2-0.6,0-1.2-0.5-1.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M447.8,860.1c-2.8,0.3-5.1,2.9-5.1,5.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M448,860.1c1.5-0.3,1.8-1,1.9-2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M447.8,860.1c1.7-0.2,2.1-1,2.1-2.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="449.9" y1="857.3" x2="450.2" y2="834.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="449.9" y1="857.5" x2="450.2" y2="834"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="453.6" y1="883.8" x2="449.2" y2="883.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.7" y1="866" x2="442.5" y2="878.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.2,831.9c0.7,0.3,1.5,0.5,2.3,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443.1,826.8c0,2.3,1.2,4.2,3.1,5.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="443.1" y1="826.8" x2="443.1" y2="808.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M450.2,834c0-0.9-0.7-1.7-2.1-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.1,876.7c0.6,0.4,1.5,0.2,2-0.5c0.4-0.7,0.3-1.7-0.4-2.1
        c-0.6-0.4-1.5-0.2-2,0.5C431.3,875.3,431.4,876.3,432.1,876.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.7,876.8c0.6,0.4,1.5,0.2,2-0.5c0.4-0.7,0.3-1.7-0.4-2.1
        c-0.6-0.4-1.5-0.2-2,0.5C426.9,875.4,427,876.3,427.7,876.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="443.6" y1="872.6" x2="452.5" y2="872.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="443.6" y1="877.5" x2="452.5" y2="877.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="443.6" y1="872.6" x2="443.6" y2="877.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.5" y1="872.6" x2="452.5" y2="877.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="449.2" y1="883.9" x2="449.3" y2="877.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M428,880.9c0.2,0.2,0.6,0.1,0.7-0.2c0.2-0.3,0.1-0.6-0.1-0.8
        c-0.2-0.2-0.6-0.1-0.7,0.2C427.7,880.4,427.8,880.7,428,880.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.9,889.8c0.2,0.2,0.6,0.1,0.7-0.2c0.2-0.3,0.1-0.6-0.1-0.8
        c-0.2-0.2-0.6-0.1-0.7,0.2C427.6,889.3,427.7,889.6,427.9,889.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M421.5,881.1c0.2,0.2,0.6,0.1,0.7-0.2c0.2-0.3,0.1-0.6-0.1-0.8
        c-0.2-0.2-0.6-0.1-0.7,0.2C421.2,880.6,421.2,881,421.5,881.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M441.2,880.8c0.2,0.2,0.6,0.1,0.7-0.2c0.2-0.3,0.1-0.6-0.1-0.8
        c-0.2-0.2-0.6-0.1-0.7,0.2C440.9,880.3,440.9,880.6,441.2,880.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M444,880.7c0.2,0.2,0.6,0.1,0.7-0.2c0.2-0.3,0.1-0.6-0.1-0.8
        c-0.2-0.2-0.6-0.1-0.7,0.2C443.7,880.2,443.7,880.6,444,880.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.2,882.7c0.2,0.2,0.6,0.1,0.7-0.2c0.2-0.3,0.1-0.6-0.1-0.8
        c-0.2-0.2-0.6-0.1-0.7,0.2C445.9,882.2,446,882.5,446.2,882.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.3,885.4c0.2,0.2,0.6,0.1,0.7-0.2c0.2-0.3,0.1-0.6-0.1-0.8
        c-0.2-0.2-0.6-0.1-0.7,0.2C440,884.9,440.1,885.3,440.3,885.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.2,920.3c-2.3-7.1-3.1-15.6-3.4-20.7c-1.5-1.9-2.3-2.9-2.7-4.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.3,926.6c0.4,0.6,1,0.9,1.7,0.9l33.9-0.3c1.1,0,2.1-1,2.2-2.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414,925.4c0,0.5,0.1,0.9,0.3,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.2" y1="925.1" x2="454" y2="896.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="414" y1="925.4" x2="416" y2="895.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M450,926.4c0.7,0,1.3-0.6,1.3-1.2l1.8-27.9c0.1-0.3,0-0.7-0.1-0.9
        c-0.2-0.4-0.6-0.6-1.1-0.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M415.1,926.1c0.2,0.4,0.6,0.5,1,0.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.9,925.4c0,0.3,0,0.5,0.2,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="416.1" y1="926.6" x2="450" y2="926.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="418" y1="896" x2="451.9" y2="895.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M418,896c-0.7,0-1.2,0.6-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="414.9" y1="925.4" x2="416.8" y2="897.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M436.8,846.5c0-1.4-2.3-2.6-5-2.6c-2.8,0-5,1.2-5,2.6
        c0,1.4,2.3,2.6,5,2.6C434.5,849.1,436.8,847.9,436.8,846.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M435.8,846.5c0-1.2-1.8-2.1-4-2.1c-2.2,0-4,0.9-4,2.1
        c0,1.2,1.8,2.1,4,2.1C434,848.6,435.8,847.6,435.8,846.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="285.5" y1="816.5" x2="283.6" y2="816.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="286.6" y1="816.5" x2="288.7" y2="816.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M288.2,815.2c0-0.2-0.2-0.5-0.4-0.5c-0.2,0-0.4,0.2-0.4,0.5
        c0,0.2,0.2,0.5,0.4,0.5C288,815.7,288.2,815.5,288.2,815.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M285.5,816.5c0.1-1,0.2-1.9,0-2.7c0-0.2-0.2-0.3-0.3-0.3
        c-0.1,0-0.9,0-1.1,0c-0.2,0-0.3,0-0.3,0.2c-0.2,0.7-0.3,1.6-0.2,2.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M288.7,816.6c0.1-1.1,0.1-1.7-0.1-2.6c0-0.2-0.1-0.3-0.4-0.3
        c-0.3,0-1,0-1.2,0c-0.3,0-0.4,0.1-0.4,0.3c-0.1,0.6-0.1,1.5,0,2.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M285,815.1c0-0.3-0.2-0.5-0.4-0.5c-0.2,0-0.4,0.2-0.4,0.5
        c0,0.2,0.2,0.5,0.4,0.5C284.8,815.6,285,815.4,285,815.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.6,816.4c0,0.5-0.3,0.9-0.3,1.5c0,0.7,0.2,1.5,0.6,2
        c0.4,0.4,1,0.8,1.6,0.9c0.3,0,0.6,0,0.9-0.1c0.4-0.1,0.9-0.4,1.2-0.9c0.5-0.7,0.6-1.3,0.7-2c0-0.3-0.1-0.8,0.5-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M285.5,816.5c-0.7,0.2-1,1-1.1,1.4c0,0.4,0.1,0.8,0.3,1.1
        c0.2,0.2,0.5,0.4,0.9,0.5c0.1,0,0.3,0,0.4,0c0.2-0.1,0.4-0.2,0.6-0.5c0.3-0.4,0.4-0.9,0.4-1.4c0-0.4,0-0.9-0.5-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.8" y1="817" x2="434.3" y2="816.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.6" y1="817" x2="434.5" y2="816.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M429.9,815.7c0-0.2,0.2-0.5,0.4-0.5c0.2,0,0.4,0.2,0.4,0.5
        c0,0.2-0.2,0.5-0.4,0.5C430.1,816.2,429.9,816,429.9,815.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.6,817c-0.1-1-0.2-1.9,0-2.7c0-0.2,0.2-0.3,0.3-0.3
        c0.1,0,0.9,0,1.1,0c0.2,0,0.3,0,0.3,0.2c0.2,0.7,0.3,1.6,0.2,2.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M429.3,817.1c-0.1-1-0.1-1.7,0.1-2.6c0-0.2,0.1-0.3,0.4-0.3
        c0.3,0,1,0,1.2,0c0.3,0,0.4,0.1,0.4,0.3c0.1,0.6,0.1,1.5,0,2.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M433,815.6c0-0.2,0.2-0.5,0.4-0.5c0.2,0,0.4,0.2,0.4,0.5
        c0,0.2-0.2,0.4-0.4,0.4C433.2,816.1,433,815.9,433,815.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M434.5,817.1c0.1,0.4,0.3,0.8,0.3,1.3c0,0.7-0.2,1.5-0.6,2
        c-0.4,0.4-1,0.8-1.6,0.9c-0.3,0-0.6,0-0.9-0.1c-0.4-0.1-0.9-0.4-1.2-0.9c-0.5-0.7-0.6-1.3-0.7-2c0-0.3,0-0.7-0.3-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M434.5,816.9c0,0.5,0.3,0.9,0.3,1.5c0,0.7-0.2,1.5-0.6,2
        c-0.4,0.4-1,0.8-1.6,0.9c-0.3,0-0.6,0-0.9-0.1c-0.4-0.1-0.9-0.4-1.2-0.9c-0.5-0.7-0.6-1.3-0.7-2c0-0.3,0.1-0.8-0.5-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.7,817.1c0.6,0.3,0.9,1,0.9,1.3c0,0.4-0.1,0.8-0.3,1.1
        c-0.2,0.2-0.5,0.4-0.9,0.5c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.4-0.2-0.6-0.5c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.4,0-0.8,0.4-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.6,817c0.7,0.2,1,1,1.1,1.4c0,0.4-0.1,0.8-0.3,1.1
        c-0.2,0.2-0.5,0.4-0.9,0.5c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.4-0.2-0.6-0.5c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.4,0-0.9,0.5-1.2l-2.1,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M434.5,817.1c0.1,0.4,0.3,0.8,0.3,1.3c0,0.7-0.2,1.5-0.6,2
        c-0.4,0.4-1,0.8-1.6,0.9c-0.3,0-0.6,0-0.9-0.1c-0.4-0.1-0.9-0.4-1.2-0.9c-0.5-0.7-0.6-1.3-0.7-2c0-0.3,0-0.7-0.3-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.7,817.1c0.6,0.3,0.9,1,0.9,1.3c0,0.4-0.1,0.8-0.3,1.1
        c-0.2,0.2-0.5,0.4-0.9,0.5c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.4-0.2-0.6-0.5c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.4,0-0.8,0.4-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="267.8" y1="807.9" x2="449.9" y2="807.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M407.9,816.5v2.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.9
        c0-0.4-0.3-0.7-0.7-0.7C408.2,815.7,407.9,816.1,407.9,816.5z"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M269.5,926.8c-0.2,4.2,0.8,8.1,8.1,7.9h17.6c4,0.2,4.1-3.7,3.7-7.6"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M419,927.5c-0.4,3.7-0.4,7.3,3.7,7.1h17.6c7.3,0.2,8.3-3.4,8.1-7.4"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M444.5,796l0-0.3l-5.9-52.7l0.2-2.9l0,0h0.6c0-0.5,0-1.3-0.8-1.3h-1h0
        l-1.1-12.1l0,0.3c-0.3-4.4-3.9-7.7-8.3-7.8l0.7,0H289.3c-4.6,0-8.3,3.5-8.5,8l-0.9,11.5H279c-0.8,0-0.7,0.8-0.8,1.3h0.6v0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="444.4" y1="807.8" x2="444.5" y2="796"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="456.7,796.8 456.7,798.4 456.6,798.4 444.6,798.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.8" y1="796.7" x2="459.1" y2="796.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M450,808.1c0.1-0.2,0.1-0.3,0.1-0.5v-6.4c0,0,8.6-0.3,8.6-0.3
        c0.5,0,0.8-0.4,0.8-0.8v-0.9c0-0.4-0.4-0.8-0.8-0.8v-1.8v0.1h5.2c1.1,0,2-0.9,2-2v-18.9c0-1.1-0.9-1.9-1.9-1.9h-10.6
        c-1.1,0-2,0.9-2,2v18.8c0,1.1,0.9,2,2,2h1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M258.7,797.8h0.4v-0.1v1.8c-0.4,0-0.8,0.4-0.8,0.8v0.9
        c0,0.4,0.4,0.8,0.8,0.8l8.8,0.3l-0.2,0v6.4c0,0.4,0.2,0.8,0.4,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="256.9" y1="797.8" x2="261.5" y2="797.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M261.6,797.8h2.8c1.1,0,2-0.9,2-2V777c0-1.1-0.9-2-2-2h-10.6
        c-1.1,0-1.9,0.9-1.9,1.9v18.9c0,1.1,0.9,2,2,2h2.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="273.1,799.1 261.1,799.4 261.1,797.7 261.1,797.8 261.6,797.8 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="278.9,740.1 279.1,743.1 273.2,795.7 273.2,796.4 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="273.2,796.1 273.2,795.7 279.1,743.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="273.3" y1="807.8" x2="273.2" y2="796.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="256.7" y1="796.7" x2="261.4" y2="796.7"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M261.3,796.7h2.4c0.9,0,1.7-0.8,1.7-1.8v-17c0-1-0.8-1.8-1.7-1.8h-9
          c-0.9,0-1.6,0.8-1.6,1.7v17.2c0,1,0.8,1.8,1.7,1.8h2"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.6" y1="795.7" x2="460.8" y2="795.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M460.6,795.7h2.4c0.9,0,1.7-0.8,1.7-1.8v-17c0-1-0.8-1.8-1.7-1.8h-9
          c-0.9,0-1.6,0.8-1.6,1.7V794c0,1,0.8,1.8,1.7,1.8h0.5"/>
      </g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="304.4" y1="807.9" x2="304.4" y2="737.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="413.7" y1="807.9" x2="413.7" y2="737.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M304.4,737.1L304.4,737.1c0-3.6,3-6.6,6.6-6.6h96.1c3.7,0,6.6,3,6.6,6.6
        "/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.1,736.7L303.1,736.7c0-4,3-7.3,6.8-7.3h98.6c3.8,0,6.8,3.3,6.8,7.3
        "/>
      <g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="279.9" y1="738.8" x2="302.2" y2="738.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="278.7" y1="740.2" x2="304.5" y2="740.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="303.1" y1="736.7" x2="303.1" y2="738.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.1,738.1c0,0,0,0.4-0.2,0.5c-0.3,0.2-0.7,0.2-0.7,0.2"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="438.4" y1="738.8" x2="416.1" y2="738.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="439.6" y1="740.2" x2="413.8" y2="740.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="415.2" y1="736.7" x2="415.2" y2="738.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M415.2,738.1c0,0,0,0.4,0.2,0.5c0.3,0.2,0.7,0.2,0.7,0.2"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,750.2c-0.7-0.4-1-0.5-2.3,0.2c-1.4,0.7-2,1-3.1,1.4
        c-1.1,0.4-2.7,0.1-2.5-1.8v-3.6c-0.2-1.9,1.5-2.1,2.5-1.8c1.1,0.4,1.7,0.7,3.1,1.4c1.4,0.7,1.7,0.6,2.3,0.2
        c0.5-0.5,1.1-0.8,1.9-0.8c1.5,0,2.6,1.2,2.6,2.8c0,1.5-1.2,2.8-2.6,2.8C415.8,751.1,415.1,750.8,414.6,750.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M407.9,746.7v2.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.9
        c0-0.4-0.3-0.7-0.7-0.7C408.2,746,407.9,746.3,407.9,746.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M417.8,748.3c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C417.2,749.7,417.8,749.1,417.8,748.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,746.4c-0.7,0.4-1,0.5-2.3-0.2c-1.4-0.7-2-1-3.1-1.4
        c-1.1-0.4-2.7-0.1-2.5,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,750.2c-0.7-0.4-1-0.5-2.3,0.2c-1.4,0.7-2,1-3.1,1.4
        c-1.1,0.4-2.7,0.1-2.5-1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M407.9,746.7v2.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.9
        c0-0.4-0.3-0.7-0.7-0.7C408.2,746,407.9,746.3,407.9,746.7z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,870.1c-0.7-0.4-1-0.5-2.3,0.2c-1.4,0.7-2,1-3.1,1.4
        c-1.1,0.4-2.7,0.1-2.5-1.8v-3.6c-0.2-1.9,1.5-2.1,2.5-1.8c1.1,0.4,1.7,0.7,3.1,1.4c1.4,0.7,1.7,0.6,2.3,0.2
        c0.5-0.5,1.1-0.8,1.9-0.8c1.5,0,2.6,1.2,2.6,2.8c0,1.5-1.2,2.8-2.6,2.8C415.8,870.9,415.1,870.6,414.6,870.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M407.9,866.6v2.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.9
        c0-0.4-0.3-0.7-0.7-0.7C408.2,865.9,407.9,866.2,407.9,866.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M417.8,868.1c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4
        c0,0.8,0.6,1.4,1.3,1.4C417.2,869.5,417.8,868.9,417.8,868.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,866.2c-0.7,0.4-1,0.5-2.3-0.2c-1.4-0.7-2-1-3.1-1.4
        c-1.1-0.4-2.7-0.1-2.5,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M414.6,870.1c-0.7-0.4-1-0.5-2.3,0.2c-1.4,0.7-2,1-3.1,1.4
        c-1.1,0.4-2.7,0.1-2.5-1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M407.9,866.6v2.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.9
        c0-0.4-0.3-0.7-0.7-0.7C408.2,865.9,407.9,866.2,407.9,866.6z"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="307.2" y1="873.7" x2="312.1" y2="873.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="405.9" y1="873.7" x2="411" y2="873.5"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M316,801.5L316,801.5c-1.8,0-3.2-1.4-3.2-3.1v-57.6
          c0-1.7,1.4-3.1,3.2-3.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M402,737.6L402,737.6c1.8,0,3.2,1.4,3.2,3.1v57.6
          c0,1.7-1.4,3.1-3.2,3.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="316" y1="737.6" x2="402.1" y2="737.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="316" y1="801.5" x2="402.3" y2="801.5"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317,800.3L317,800.3c-1.7,0-3.1-1.3-3.1-3v-55.5c0-1.7,1.4-3,3.1-3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M401,738.8L401,738.8c1.7,0,3.1,1.3,3.1,3v55.5c0,1.7-1.4,3-3.1,3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317" y1="738.8" x2="401" y2="738.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317" y1="800.3" x2="401.3" y2="800.3"/>
      </g>
      <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M405.8,822c0,23.7-19.2,42.8-42.9,42.8c-23.7,0-42.9-19.2-42.9-42.8
        c0-23.7,19.2-42.9,42.9-42.9C386.6,779.2,405.8,798.3,405.8,822z"/>
      <g>
        <g>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M295.8,801.7L295.8,801.7c1.3,0,2.3-1,2.3-2.3v-51.1
            c0-1.3-1-2.3-2.3-2.3"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M279.4,798.9v0.4c0,1.3,1,2.3,2.3,2.3"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M286.8,746c-0.6,0.1-1.2,0.2-1.5,0.6c-0.1,0.2-0.2,0.4-0.3,0.7
            c0,0.1,0,0.3-0.1,0.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="279.5" y1="796.7" x2="284.9" y2="747.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295.9" y1="746" x2="286.8" y2="746"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295.9" y1="801.7" x2="281.6" y2="801.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.5,796.6c0,0-0.1,0.6-0.1,1.2c0,0.5,0,1.1,0,1.1"/>
        </g>
        <g>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M295.1,800.5L295.1,800.5c1.1,0,2-1,2-2.2v-49c0-1.2-0.9-2.2-2-2.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.1,797.9v0.4c0,1.2,0.9,2.2,2,2.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M287.5,747.1c-0.5,0.1-1,0.2-1.3,0.6c-0.1,0.2-0.2,0.4-0.3,0.6
            c0,0.1,0,0.3-0.1,0.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.2" y1="795.8" x2="285.9" y2="748.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295.1" y1="747" x2="287.4" y2="747.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295.2" y1="800.5" x2="283" y2="800.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.2,795.7c0,0-0.1,0.6-0.1,1.2c0,0.5,0,1,0,1"/>
        </g>
      </g>
      <g>
        <g>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M422.2,801.7L422.2,801.7c-1.3,0-2.3-1-2.3-2.3v-51.1
            c0-1.3,1-2.3,2.3-2.3"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M438.7,798.9v0.4c0,1.3-1,2.3-2.3,2.3"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M431.2,746c0.6,0.1,1.2,0.2,1.5,0.6c0.1,0.2,0.2,0.4,0.3,0.7
            c0,0.1,0,0.3,0.1,0.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="438.6" y1="796.7" x2="433.1" y2="747.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.2" y1="746" x2="431.3" y2="746"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.1" y1="801.7" x2="436.5" y2="801.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M438.6,796.6c0,0,0.1,0.6,0.1,1.2c0,0.5,0,1.1,0,1.1"/>
        </g>
        <g>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M422.9,800.5L422.9,800.5c-1.1,0-2-1-2-2.2v-49c0-1.2,0.9-2.2,2-2.2"
            />
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M436.9,797.9v0.4c0,1.2-0.9,2.2-2,2.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M430.6,747.1c0.5,0.1,1,0.2,1.3,0.6c0.1,0.2,0.2,0.4,0.3,0.6
            c0,0.1,0,0.3,0.1,0.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="436.8" y1="795.8" x2="432.2" y2="748.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.9" y1="747" x2="430.6" y2="747.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.9" y1="800.5" x2="435" y2="800.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M436.8,795.7c0,0,0.1,0.6,0.1,1.2c0,0.5,0,1,0,1"/>
        </g>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M400.5,822c0-20.7-16.8-37.6-37.6-37.6c-20.7,0-37.5,16.8-37.5,37.6
          c0,20.7,16.8,37.5,37.5,37.5C383.7,859.5,400.5,842.7,400.5,822"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M404.7,822c0-23-18.7-41.7-41.7-41.7c-23,0-41.7,18.7-41.7,41.7
          c0,23,18.7,41.7,41.7,41.7C386,863.7,404.7,845,404.7,822"/>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M357.4,822c0-3.1,2.5-5.6,5.5-5.6c3.1,0,5.6,2.5,5.6,5.5
            c0,3.1-2.5,5.6-5.5,5.6C359.9,827.6,357.4,825.1,357.4,822z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M337.4,822.1c-0.1-14.1,11.3-25.7,25.4-25.8
            c14.1-0.1,25.7,11.3,25.8,25.4c0.1,14.1-11.3,25.7-25.4,25.8C349,847.6,337.5,836.3,337.4,822.1"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M337.4,822.1c-0.1-14.1,11.3-25.7,25.4-25.8
            c14.1-0.1,25.7,11.3,25.8,25.4c0.1,14.1-11.3,25.7-25.4,25.8C349,847.6,337.5,836.3,337.4,822.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M338.4,822.1c-0.1-13.6,10.8-24.6,24.4-24.7
            c13.6-0.1,24.6,10.8,24.7,24.4c0.1,13.6-10.8,24.6-24.4,24.7C349.6,846.6,338.5,835.7,338.4,822.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M376.6,803c2.9,2.1,5.3,4.8,6.9,7.8l-0.3,0.5l-12.8,4.2l-0.6,0
            c-0.2-0.1-0.9-0.5-1.5-0.9c-0.5-0.4-1.2-1-1.4-1.1l-0.2-0.6l-0.1-13.4l0.4-0.5C370.4,799.6,373.7,800.9,376.6,803z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M355.9,822.1c0-3.9,3.1-7.1,7.1-7.2c3.9,0,7.1,3.1,7.2,7.1
            c0,3.9-3.1,7.1-7.1,7.2C359.1,829.1,355.9,826,355.9,822.1"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M355.9,822.1c0-3.9,3.1-7.1,7.1-7.2c3.9,0,7.1,3.1,7.2,7.1
            c0,3.9-3.1,7.1-7.1,7.2C359.1,829.1,355.9,826,355.9,822.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M360.5,814.9c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.5,2.4
            c0,1.3-1.1,2.4-2.4,2.5C361.6,817.3,360.5,816.3,360.5,814.9"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M361.6,814.9c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
            c0,0.8-0.6,1.4-1.4,1.4C362.2,816.3,361.6,815.7,361.6,814.9"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M349.1,803.2c2.9-2.1,6.2-3.5,9.5-4.1l0.4,0.5l0.1,13.4l-0.2,0.6
            c-0.1,0.1-0.8,0.7-1.3,1.1c-0.5,0.4-1.3,0.9-1.5,0.9l-0.6,0l-12.8-4l-0.3-0.5C343.9,808.1,346.2,805.4,349.1,803.2z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M355.5,822.2c-1.3-0.4-2-1.8-1.6-3.1c0.4-1.3,1.8-2,3.1-1.6
            c1.3,0.4,2,1.8,1.6,3.1C358.1,821.9,356.8,822.6,355.5,822.2"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M355.8,821.2c-0.7-0.2-1.2-1-0.9-1.8c0.2-0.8,1-1.2,1.8-0.9
            c0.7,0.2,1.2,1,0.9,1.8C357.3,821,356.5,821.5,355.8,821.2"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M340.8,829.4c-1.1-3.4-1.4-7-1-10.3l0.6-0.2l12.8,4.1l0.5,0.4
            c0.1,0.2,0.4,1,0.7,1.6c0.2,0.6,0.4,1.5,0.4,1.7l-0.2,0.6l-7.8,10.9l-0.6,0.2C343.9,835.8,342,832.8,340.8,829.4z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M363.2,845.4c-3.6,0-7.1-0.8-10.2-2.3l0-0.6l7.8-10.9l0.5-0.4
            c0.2,0,1.1-0.1,1.8-0.1c0.7,0,1.6,0.1,1.7,0.1l0.5,0.4l8,10.8l0,0.6C370.2,844.5,366.8,845.4,363.2,845.4z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M369.2,826.3c0.8,1.1,0.6,2.6-0.5,3.4c-1.1,0.8-2.6,0.6-3.4-0.5
            c-0.8-1.1-0.6-2.6,0.5-3.4C366.9,825,368.4,825.2,369.2,826.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M368.4,826.9c0.5,0.6,0.3,1.5-0.3,2c-0.6,0.5-1.5,0.3-2-0.3
            c-0.5-0.6-0.3-1.5,0.3-2C367,826.2,367.9,826.3,368.4,826.9"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M385.3,829.1c-1.1,3.5-2.9,6.5-5.3,9l-0.6-0.2l-8-10.8l-0.2-0.6
            c0-0.2,0.2-1.1,0.4-1.7c0.2-0.6,0.6-1.5,0.6-1.6l0.5-0.4l12.7-4.3l0.6,0.2C386.6,822.1,386.4,825.7,385.3,829.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M369,817.5c1.3-0.4,2.7,0.3,3.1,1.5c0.4,1.3-0.3,2.7-1.5,3.1
            c-1.3,0.4-2.6-0.3-3.1-1.5C367,819.3,367.7,817.9,369,817.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M369.3,818.4c0.8-0.2,1.5,0.2,1.8,0.9c0.3,0.7-0.2,1.6-0.9,1.8
            c-0.7,0.2-1.6-0.2-1.8-0.9C368.2,819.5,368.6,818.7,369.3,818.4"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M357.4,822c0-3.1,2.5-5.6,5.5-5.6c3.1,0,5.6,2.5,5.6,5.5
            c0,3.1-2.5,5.6-5.5,5.6C359.9,827.6,357.4,825.1,357.4,822"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M359.1,822c0-2.2,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9
            c0,2.1-1.7,3.9-3.9,3.9C360.9,825.9,359.1,824.2,359.1,822z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M337.3,822.2c-0.1-14.2,11.3-25.7,25.5-25.8
            c14.1-0.1,25.7,11.3,25.8,25.5c0.1,14.1-11.3,25.7-25.4,25.8C349,847.7,337.4,836.3,337.3,822.2z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M355.5,822.2c-1.3-0.4-2-1.8-1.6-3.1c0.4-1.3,1.8-2,3.1-1.6
            c1.3,0.4,2,1.8,1.6,3.1C358.1,821.9,356.8,822.6,355.5,822.2z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M355.8,821.2c-0.7-0.2-1.2-1-0.9-1.8c0.2-0.8,1-1.2,1.8-0.9
            c0.7,0.2,1.2,1,0.9,1.8C357.3,821,356.5,821.5,355.8,821.2z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M360.5,814.9c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.5,2.4
            c0,1.3-1.1,2.4-2.4,2.5C361.6,817.3,360.5,816.3,360.5,814.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M361.6,814.9c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
            c0,0.8-0.6,1.4-1.4,1.4C362.2,816.3,361.6,815.7,361.6,814.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M369,817.5c1.3-0.4,2.7,0.3,3.1,1.5c0.4,1.3-0.3,2.7-1.5,3.1
            c-1.3,0.4-2.6-0.3-3.1-1.5C367,819.3,367.7,817.9,369,817.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M369.3,818.4c0.8-0.2,1.5,0.2,1.8,0.9c0.3,0.7-0.2,1.6-0.9,1.8
            c-0.7,0.2-1.6-0.2-1.8-0.9C368.2,819.5,368.6,818.7,369.3,818.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M369.2,826.3c0.8,1.1,0.6,2.6-0.5,3.4c-1.1,0.8-2.6,0.6-3.4-0.5
            c-0.8-1.1-0.6-2.6,0.5-3.4C366.9,825,368.4,825.2,369.2,826.3z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M368.4,826.9c0.5,0.6,0.3,1.5-0.3,2c-0.6,0.5-1.5,0.3-2-0.3
            c-0.5-0.6-0.3-1.5,0.3-2C367,826.2,367.9,826.3,368.4,826.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M360.8,829.2c-0.8,1.1-2.3,1.3-3.4,0.6c-1.1-0.8-1.3-2.3-0.6-3.4
            c0.8-1.1,2.3-1.3,3.4-0.6C361.4,826.6,361.6,828.1,360.8,829.2"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M360.8,829.2c-0.8,1.1-2.3,1.3-3.4,0.6c-1.1-0.8-1.3-2.3-0.6-3.4
            c0.8-1.1,2.3-1.3,3.4-0.6C361.4,826.6,361.6,828.1,360.8,829.2z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M360,828.6c-0.5,0.6-1.3,0.8-2,0.3c-0.6-0.5-0.8-1.4-0.3-2
            c0.5-0.6,1.3-0.8,2-0.3C360.3,827.1,360.5,828,360,828.6"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M360,828.6c-0.5,0.6-1.3,0.8-2,0.3c-0.6-0.5-0.8-1.4-0.3-2
            c0.5-0.6,1.3-0.8,2-0.3C360.3,827.1,360.5,828,360,828.6z"/>
        </g>
      </g>
    </g>
  </g>
  <g id="Layer_5">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.2,35l0.2,2.9l-5.8,52.7l0.1,3.3l0,0.1l-12.1,0.3v-1.7v0h3.3
        c1.1,0,2-0.9,2-2V71.8c0-1.1-0.9-2-2-2h-10.5c-1.1,0-1.9,0.9-1.9,1.9v18.9c0,1.1,0.9,2,2,2h5.1v-0.1v1.8c-0.4,0-0.8,0.4-0.8,0.8
        v0.9c0,0.4,0.4,0.8,0.8,0.8l8.7,0.3l-0.2,0v6.4c0,0.8,0.6,1.4,1.4,1.4h4.5l0.2,0.5h-1.2c-2.6,0-4.8,2.1-4.8,4.8v13.3l-0.1-0.2
        c-2.9,1-6.1,4.4-6.8,5.4c-0.7,1-1.5,2.4-1.7,4.1c-0.2,1.7,0,4.9,0.5,11c0.3,3.7,1,10.2,1.8,16.6l0,0.4v10.8l0,0.1
        c0,0.4,0.3,0.8,0.6,1l-0.1-0.1v46c-0.6,5.2-1.2,11.2,7,11l-0.3,0H287l0.3,0c4.6,0.2,3.4-5.9,3-10.6v-24.3c1.3-0.5,2.3-1.4,3.1-2.5
        l0,0h2.6V192v4h3.4h-0.1v-3.9h15.7c3.9,0.3,10.2,0.7,12.6,5l-0.1-0.1c3.1,3.6,7.6,5.4,12,5.5c5.6,0,10.5-2.9,13.4-7.3
        c1.8-2.1,5-2.5,8.3-2.5l0.1,0h38.1l-0.2,0l0.6,0.9l5,0.3l0.8-0.8l0-0.3l0-0.1h16.7v3.2h3.4H426v-3.2h2.9l0,0
        c0.8,0.8,1.7,1.5,2.8,1.9v-0.1v24.3c-0.4,4.7-1.5,10.8,3,10.6h15.8c8.2,0.2,7.6-5.7,6.9-11v-46h0c0.3-0.2,0.4-0.5,0.4-0.9l0-0.1
        v-10.8c0-0.2-0.1-0.4-0.1-0.6l0,0c0.8-6.4,1.5-12.7,1.7-16.4c0.5-6.1,0.7-9.3,0.5-11c-0.2-1.7-1-3.2-1.7-4.1
        c-0.7-1-3.9-4.4-6.8-5.4l-0.1,0v-13.1c0-2.6-2.1-4.7-4.8-4.7h-1l0-0.2l0-1.3l0,0h4.5c0.8,0,1.4-0.7,1.4-1.4V96
        c0,0,8.6-0.3,8.6-0.3c0.5,0,0.8-0.4,0.8-0.8V94c0-0.4-0.3-0.8-0.8-0.8v-1.8v0.1h5.1c1.1,0,2-0.9,2-2V70.7c0-1.1-0.9-1.9-1.9-1.9
        h-10.5c-1.1,0-2,0.9-2,2v18.8c0,1.1,0.9,2,2,2h3.3l0,0v1.7l0,0l-12.1-0.3l0,0l0.1-2.3l-5.9-52.7l0.2-2.9v0h0.6
        c0-0.5,0-1.3-0.8-1.3h-1h0l-1.1-12.1l0,0.3c-0.3-4.4-3.9-7.7-8.3-7.8l0.7,0H291.6c-4.5,0-8.2,3.5-8.5,8l-0.9,11.5h-0.9
        c-0.8,0-0.7,0.8-0.8,1.3L281.2,35L281.2,35"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="368.4,154.8 392.7,154.8 392.7,156.2 402.9,156.2 402.9,116.4 
        319.2,116.4 319.4,156.2 329.2,156.2 329.2,154.8 353.4,154.8 353.4,156.2 368.4,156.2 368.4,154.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.7,103.8L445.7,103.8l4.6,0c0.8,0,1.4-0.7,1.4-1.4V96
        c0,0,8.6-0.3,8.6-0.3c0.5,0,0.8-0.4,0.8-0.8V94c0-0.4-0.3-0.8-0.8-0.8v-1.8v0.1h5.1c1.1,0,2-0.9,2-2V70.7c0-1.1-0.9-1.9-1.9-1.9
        h-10.5c-1.1,0-2,0.9-2,2v18.8c0,1.1,0.9,2,2,2h3.3l0,0v1.7l0,0l-12.1-0.3l-0.1,0L445.7,103.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M276.1,104.9L276.1,104.9l-4.5,0c-0.8,0-1.4-0.6-1.4-1.4V97
        c0,0-8.6-0.3-8.6-0.3c-0.5,0-0.8-0.4-0.8-0.8V95c0-0.4,0.3-0.8,0.8-0.8v-1.8v0.1h-5.1c-1.1,0-2-0.9-2-2V71.7
        c0-1.1,0.9-1.9,1.9-1.9h10.5c1.1,0,2,0.9,2,2v18.8c0,1.1-0.9,2-2,2h-3.3l0,0v1.7l0,0l12.1-0.3l0,0L276.1,104.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M438.2,69.7l-4.1-34.1c-0.4-3.1-2.9-4.4-4.4-4.4H292.9
        c-3.7,0-4.9,3-5,4.7l-4.1,33.2c-0.7,5.2,2.5,5.9,5.7,6.2h42l-4.5-2.2l0-0.1l-9.8-4.1l-0.1,0.1l-1.7-0.8l0,0h-16.9l0,0v-1.1h36.8
        v1.1l0,0h-17.7l0,0l9.6,4.1l0-0.1l8.7,3.1h70.4l-4.7-2.2l0-0.1l-9.8-4.1l-0.1,0.1l-1.7-0.8l0,0h-16.9l0,0V67h36.8v1.1l0,0h-17.7h0
        l9.6,4.1l0-0.1l8.9,3.2h21.3C437,75.3,438.5,73.6,438.2,69.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M457.5,172.4v46c0.6,5.2,1.2,11.2-6.9,11h-15.8c-4.6,0.2-3.4-5.9-3-10.6
        v-47.1l0-0.1h13.9l0,0c0.1,0.5,0.5,0.9,1.1,0.9h10.2l0,0c0,0,0,0,0,0C457.1,172.7,457.3,172.6,457.5,172.4L457.5,172.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M290.4,207.1v-35.3l0-0.1h-13.9l0,0c-0.1,0.5-0.5,0.9-1.1,0.9h-10.2l0,0
        c0,0,0,0,0,0c-0.3,0-0.5-0.1-0.7-0.2l0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264.5,210v8.5c-0.6,5.2-1.2,11.2,7,11h15.8c4.6,0.2,3.4-5.9,3-10.6
        v-11.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.5" y1="172.4" x2="264.5" y2="210"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M331.3,21.4l0.4-2.4c0.2-1,1.1-1.8,2.1-1.8h0h54.7h-0.1
        c1,0,1.9,0.7,2.1,1.7l0-0.1l0.5,2.4l0,0.4c0,1.2-0.9,2.1-2.1,2.1h0.2h-55.9h0.1c-1.2,0-2.1-0.9-2.1-2.1
        C331.3,21.6,331.3,21.5,331.3,21.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M418.6,78l-16.4-5.9l0,0.1l-9.6-4.1h17.7l0,0V67h-36.8v1.1l0,0h16.9
        l1.7,0.8l0.1-0.1l9.8,4.1l0,0.1l15.5,7.4l0,0c0.2,0.1,0.5,0.2,0.7,0.2c0.9,0,1.6-0.6,1.6-1.4C419.6,78.7,419.2,78.2,418.6,78"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M343.8,78.1l-16.4-5.9l0,0.1l-9.6-4.1h17.7h0v-1.1h-36.8v1.1l0,0h16.9
        l1.7,0.8l0.1-0.1L327,73l0,0.1l15.5,7.4l0,0c0.2,0.1,0.5,0.2,0.7,0.2c0.9,0,1.6-0.6,1.6-1.4C344.8,78.8,344.4,78.3,343.8,78.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M413.9,153.2h28.4c2.9,0,5.3-2.3,5.3-5.3l0-27.3c0-2.9-2.4-5.3-5.3-5.3
        h-28.4l0.1-0.1c-3,0-5.3,2.4-5.3,5.3v27.2C408.6,150.8,410.9,153.2,413.9,153.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M409.9,147.9c0,1.1,0.4,2.1,1.1,2.8c0.7,0.7,1.7,1.1,2.8,1.1h28.4
        c1.1,0,2-0.4,2.8-1.1c0.7-0.7,1.1-1.7,1.1-2.8v-27.3c0-1.1-0.4-2-1.1-2.8c-0.7-0.7-1.7-1.1-2.8-1.1h-28.4c-1.1,0-2.1,0.4-2.8,1.2
        c-0.7,0.7-1.2,1.7-1.2,2.8V147.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.2,120.7c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
        C444.4,124.7,446.2,122.9,446.2,120.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.2,140.5c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
        C444.4,144.5,446.2,142.7,446.2,140.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M436.7,133.2c0-6.6-5.3-11.9-11.9-11.9c-6.6,0-11.9,5.3-11.9,11.9
        c0,6.6,5.3,11.9,11.9,11.9C431.3,145.1,436.7,139.8,436.7,133.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M308.2,153.2h-28.4c-2.9,0-5.3-2.4-5.3-5.3l0.1-27.3
        c0-2.9,2.4-5.3,5.3-5.3h28.4l0,0c3,0,5.3,2.4,5.3,5.3v27.2C313.5,150.8,311.1,153.2,308.2,153.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M312.2,148c0,1.1-0.4,2.1-1.1,2.8c-0.7,0.7-1.7,1.1-2.8,1.1h-28.4
        c-1.1,0-2-0.4-2.8-1.1c-0.7-0.7-1.1-1.7-1.1-2.8v-27.3c0-1.1,0.4-2,1.1-2.7c0.7-0.7,1.7-1.1,2.7-1.1h28.4c1.1,0,2.1,0.4,2.8,1.2
        c0.7,0.7,1.2,1.7,1.2,2.8V148"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M275.9,120.8c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4
        C277.7,124.7,275.9,123,275.9,120.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M275.9,140.5c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4
        C277.7,144.5,275.9,142.7,275.9,140.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M285.4,133.2c0-6.6,5.3-11.9,11.9-11.9c6.6,0,11.9,5.3,11.9,11.9
        c0,6.6-5.3,11.9-11.9,11.9C290.8,145.2,285.4,139.8,285.4,133.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.6,150.2 322.1,152.5 400.7,152.5 403.1,150.2 319.6,150.2 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,145.8 322,148 400.6,148 403,145.8 319.5,145.8 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,141.1 322,143.3 400.6,143.3 403,141.1 319.5,141.1 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,136.4 322,138.6 400.6,138.6 403,136.4 319.5,136.4 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,131.7 322,133.9 400.6,133.9 403,131.7 319.5,131.7 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,127 322,129.2 400.6,129.2 403,127 319.5,127 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,122.3 322,124.5 400.6,124.5 403,122.3 319.5,122.3 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.3,117.6 321.8,119.8 400.4,119.8 402.8,117.6 319.3,117.6 
            "/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M388.3,141.7c0-1.1-1.9-2.1-4.1-2.1c-2.3,0-4.1,0.9-4.1,2.1
        c0,1.1,1.9,2.1,4.1,2.1C386.4,143.7,388.3,142.8,388.3,141.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M405.4,179.3h-13.2v0v-7.6l-61.4,0.1v7.6v0h-13.2v0v-7.6l-27.2,0v22.8
        c1.3-0.5,2.3-1.4,3.1-2.5l0,0h2.6V192v4h3.4h-0.1v-3.9h15.7c3.9,0.3,10.2,0.7,12.6,5l-0.1-0.1c3.1,3.6,7.6,5.4,12,5.5
        c5.6,0,10.5-2.9,13.4-7.3c1.8-2.1,5-2.5,8.3-2.5l0.1,0.1h38.1l-0.2,0l0.6,0.9l5,0.3l0.8-0.8l0-0.3l0-0.1h16.7v3.2h3.4H426v-3.2
        h2.9l0,0c0.8,0.8,1.7,1.5,2.8,1.9v-22.9l-26.3,0V179.3L405.4,179.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M435,133.2c0-5.7-4.6-10.3-10.3-10.3c-5.7,0-10.3,4.6-10.3,10.3
        c0,5.7,4.6,10.3,10.3,10.3C430.4,143.5,435,138.9,435,133.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M413.9,115.3c-3,0-5.3,2.4-5.3,5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="413.9" y1="115.4" x2="442.2" y2="115.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M408.6,147.9c0,2.9,2.4,5.3,5.3,5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="408.5" y1="120.7" x2="408.5" y2="147.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.3,153.2c2.9,0,5.3-2.3,5.3-5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="413.9" y1="153.2" x2="442.3" y2="153.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447.5" y1="147.9" x2="447.5" y2="120.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.2,120.7c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
        C444.4,124.7,446.2,122.9,446.2,120.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.2,140.5c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
        C444.4,144.5,446.2,142.7,446.2,140.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M275.1,105.4c-2.6,0-4.8,2.1-4.8,4.8v49.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M275.1,105.4h31.4c2.9,0,3.5,6.2,8.1,6.2c9.2,0,93.3,0,93.3,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M416,105.4c-2.9,0-3.5,6.2-8.1,6.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="276.4" y1="160.5" x2="369.2" y2="160.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264.5,164.5c-1-7.6-2-16.3-2.3-20.8c-0.5-6.1-0.7-9.3-0.5-11
        c0.2-1.7,1-3.2,1.7-4.1c0.7-1,3.9-4.4,6.8-5.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M261.7,132.7c0.5-2,1.6-3.5,2.1-0.5c0.5,2.9,2.3,15.4,2.7,19.1
        c0.3,2.8,0.8,6.5,1.5,8.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.3,157.4C270.3,157.3,270.3,157.3,270.3,157.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="229.4" x2="287" y2="229.4"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="276.1,105.2 275.6,90.5 281.5,37.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277" y1="79" x2="278.2" y2="79"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.8" y1="79" x2="339.2" y2="79"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="344.7" y1="79" x2="358.6" y2="79"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="406.7" y1="90.5" x2="446.1" y2="90.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.6" y1="90.5" x2="315.5" y2="90.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288" y1="35.9" x2="283.8" y2="69.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M288,35.9c0.1-1.7,1.3-4.7,5-4.7h136.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.8,69.2c-0.7,5.2,2.5,5.9,5.7,6.2h42"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.9" y1="75.3" x2="406.5" y2="75.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M411.1,75.3h21.3c4.6,0,6.1-1.7,5.8-5.6l-4.1-34.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M429.7,31.2c1.5,0,4,1.3,4.4,4.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.5,37.9l2.1-0.6l0-2.4h-3c0-0.5,0-1.3,0.8-1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="283.9" y1="33.7" x2="281.4" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.3" y1="35" x2="281.5" y2="37.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.6,35c0.2-1.9,2.9-7.7,10.1-7.7h134.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M352.6,186.5c0-1.1-0.1-2.2-0.4-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M332.6,175.7c4.8-3.1,11.2-2.8,15.7,1.1c0,0,0,0,0.1,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M328.9,179.3c-1.2,1.8-1.9,3.9-2.1,5.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M326.8,187.8c0.7,6.7,6.3,11.6,12.9,11.6c6.2,0,11.4-4.4,12.7-10.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M352,176.4c-1.8-2.2-4-3.8-6.4-4.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M333.7,171.7c-1.1,0.4-2.1,1-3,1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M325.4,179.3c-0.2,0.3-0.3,0.6-0.4,0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.7,197c3.1,3.6,7.5,5.4,12,5.5c5.6,0,10.5-2.9,13.4-7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M294.8,187.8c0-3-1.8-5.6-4.5-6.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M290.4,194.5c2.8-1.2,4.5-3.9,4.5-6.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M315.8,183.7c4.2-0.1,7.5,0.3,9.2-3.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M315.1,192.1c3.9,0.3,10.2,0.7,12.6,5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.5" y1="122.3" x2="403" y2="122.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,122.3 322,124.5 400.7,124.5 403,122.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.5" y1="127" x2="403" y2="127"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,127 322,129.2 400.7,129.2 403,127 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.5" y1="131.7" x2="403" y2="131.7"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,131.7 322,133.9 400.7,133.9 403,131.7 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.5" y1="136.4" x2="403" y2="136.4"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,136.4 322,138.6 400.7,138.6 403,136.4 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.5" y1="145.8" x2="403" y2="145.8"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,145.8 322,148 400.7,148 403,145.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M357.1,83.4c0-1.2-1-2.2-2.2-2.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="351.3" y1="81.2" x2="354.9" y2="81.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M354.8,88.2c1.3,0,2.3-1,2.3-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="357.1" y1="83.4" x2="357.1" y2="86"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M286.9,81.2c-1.3,0-2.3,1-2.3,2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M284.5,85.9c0,1.3,1,2.3,2.3,2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="284.5" y1="83.5" x2="284.5" y2="85.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="286.9" y1="88.2" x2="354.8" y2="88.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="290.2" y1="79" x2="290.2" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.5" y1="81.2" x2="293.5" y2="79"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M447.5,120.6c0-2.9-2.4-5.3-5.3-5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="408.6" y1="120.7" x2="408.6" y2="147.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M451.4,110.1c0-2.6-2.1-4.7-4.8-4.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="451.4" y1="110.1" x2="451.4" y2="159.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="416" y1="105.4" x2="446.7" y2="105.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.5" y1="160.5" x2="369.1" y2="160.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M311.8,95.1h-19.9c-5,0-8.6,6.7-8.6,10"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M311.8,95c0.7-2.6,1.9-4.5,4.9-4.6l44.4,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.5" y1="171.7" x2="276.4" y2="171.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M457.8,160.1c0.8-6.4,1.4-12.7,1.7-16.4c0.5-6.1,0.7-9.3,0.5-11
        c-0.2-1.7-1-3.2-1.7-4.1c-0.7-1-3.9-4.4-6.8-5.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M460,132.7c-0.6-2-1.6-3.5-2.1-0.6c-0.5,2.9-2.3,15.4-2.7,19.1
        c-0.3,2.8-0.8,6.5-1.5,8.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="457.5" y1="147.4" x2="457.5" y2="159.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="457.5" y1="172.4" x2="457.5" y2="218.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="431.7" y1="171.8" x2="431.7" y2="218.8"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="445.6,105.2 446.1,90.6 440.3,37.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="444.8" y1="79" x2="443.7" y2="79"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="439" y1="79" x2="419.6" y2="79"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="414.1" y1="79" x2="363.9" y2="79"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.3,37.9l-2.1-0.6l0-2.4l3,0c0-0.5,0-1.3-0.8-1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="437.8" y1="33.7" x2="440.4" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.5" y1="35" x2="440.3" y2="37.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M438.2,35c-0.2-1.9-2.9-7.7-10.1-7.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M431.7,180.9c-2.9,1.1-4.8,3.9-4.8,6.9c0,3.1,2,5.8,4.8,6.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M366.9,81.2c-1.2,0-2.2,1-2.2,2.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M364.7,86c0,1.3,1,2.3,2.3,2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="364.7" y1="83.4" x2="364.7" y2="86"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M437.2,83.5c0-1.3-1-2.3-2.3-2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M434.9,88.2c1.3,0,2.3-1,2.3-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="437.2" y1="83.5" x2="437.2" y2="85.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="434.9" y1="88.2" x2="366.9" y2="88.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M457.5,218.5c0.6,5.2,1.2,11.2-7,11"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M431.7,218.8c-0.4,4.7-1.5,10.8,3,10.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.2" y1="111.5" x2="317.2" y2="154.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="404.6" y1="111.5" x2="404.6" y2="160.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M361.4,192.7c-3.4,0-6.5,0.4-8.3,2.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.6" y1="150.2" x2="403.1" y2="150.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.6,150.2 322.2,152.5 400.8,152.5 403.1,150.2 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M268.9,71.8c0-1.1-0.9-2-2-2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M256.3,69.8c-1.1,0-1.9,0.9-1.9,1.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="266.9" y1="69.8" x2="256.3" y2="69.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M254.4,90.6c0,1.1,0.9,2,2,2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="254.4" y1="71.7" x2="254.4" y2="90.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M266.9,92.6c1.1,0,2-0.9,2-2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="256.4" y1="92.6" x2="266.9" y2="92.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268.9" y1="71.8" x2="268.9" y2="90.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M261.5,94.3c-0.4,0-0.8,0.4-0.8,0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="261.5" y1="94.3" x2="275.7" y2="93.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M260.7,95.9c0,0.4,0.4,0.8,0.8,0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="260.7" y1="95" x2="260.7" y2="95.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="261.5" y1="96.7" x2="275.8" y2="97.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="261.5" y1="94.3" x2="261.5" y2="92.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="263.6" y1="94.2" x2="263.6" y2="92.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M256.4,91.4h10.5c0.2,0,0.4-0.1,0.6-0.2c0.1-0.2,0.2-0.4,0.2-0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M255.6,71.7v18.9c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.6,0.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M266.9,70.9h-10.5c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M267.7,71.8c0-0.2-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.2-0.6-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="267.7" y1="71.8" x2="267.7" y2="90.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.1,103.4c0,0.8,0.6,1.5,1.4,1.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="270.1" y1="97" x2="270.1" y2="103.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.6" y1="104.9" x2="276.1" y2="104.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M454.9,68.8c-1.1,0-2,0.9-2,2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M467.4,70.7c0-1.1-0.9-1.9-1.9-1.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.9" y1="68.8" x2="465.5" y2="68.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M465.4,91.6c1.1,0,2-0.9,2-2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="467.4" y1="70.7" x2="467.4" y2="89.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M452.9,89.6c0,1.1,0.9,2,2,2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="465.4" y1="91.6" x2="454.9" y2="91.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="452.9" y1="70.8" x2="452.9" y2="89.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M461.1,94c0-0.4-0.3-0.8-0.8-0.8l-14.2-0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M460.2,95.7c0.5,0,0.8-0.4,0.8-0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="461.1" y1="94" x2="461.1" y2="94.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="460.2" y1="95.7" x2="446" y2="96.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="460.3" y1="93.3" x2="460.3" y2="91.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="458.2" y1="93.2" x2="458.2" y2="91.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M465.4,90.4h-10.5c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M466.2,70.7v18.9c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.6,0.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M454.9,69.9h10.5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M454.1,70.8c0-0.2,0.1-0.5,0.2-0.6c0.2-0.2,0.4-0.3,0.6-0.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.1" y1="70.8" x2="454.1" y2="89.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M450.2,103.9c0.8,0,1.4-0.6,1.4-1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="451.7" y1="96" x2="451.7" y2="102.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="450.2" y1="103.9" x2="445.7" y2="103.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M278.8,74.6c-0.4,1.7-1.1,8.7-1,10.3c0,1.3,1.2,1.1,2.3,1.1
        c1.6,0,2.3-0.1,2.4-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M278.8,74.6c0.2-0.8,1-0.9,1.9-0.9c1.3,0,2.6-0.1,2.4,1.1l-0.7,9.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443,74.4c0.4,1.7,1.1,8.7,1,10.3c-0.1,1.3-1.2,1.1-2.3,1.1
        c-1.6,0-2.3-0.1-2.4-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443,74.4c-0.2-0.8-1-0.9-1.9-0.9c-1.3,0-2.6-0.1-2.4,1.1l0.7,9.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M359.2,81.1c0,0.4,0.4,0.8,0.8,0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="358.6" y1="79" x2="359.2" y2="81.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M362.6,81.9c0.4,0,0.8-0.4,0.8-0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="360" y1="81.9" x2="362.6" y2="81.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="363.4" y1="81.1" x2="363.9" y2="79"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M359.6,78c-0.6,0-1,0.4-1,1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M363.9,79c0-0.6-0.5-1-1-1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="359.6" y1="78" x2="362.9" y2="78"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M344.8,79.4c0-0.8-0.7-1.4-1.6-1.4c-0.9,0-1.6,0.6-1.6,1.4
        c0,0.7,0.7,1.4,1.6,1.4C344.1,80.7,344.8,80.1,344.8,79.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327" y1="73.1" x2="327.4" y2="72.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.3" y1="72.3" x2="317.5" y2="68.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327" y1="73" x2="317.2" y2="68.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="343.8" y1="78.1" x2="327.4" y2="72.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="342.5" y1="80.5" x2="327" y2="73.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="298.6" y1="68.2" x2="315.5" y2="68.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.7" y1="68.2" x2="335.5" y2="68.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="298.6" y1="67.1" x2="335.4" y2="67.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.5" y1="68" x2="317.2" y2="69"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.5" y1="68" x2="315.4" y2="67.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.2" y1="69" x2="315.2" y2="68.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="315.4" y1="67.2" x2="313.6" y2="67.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="315.2,68.1 313.7,68.1 313.7,67.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="298.6" y1="67.1" x2="298.6" y2="68.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.4" y1="67.1" x2="335.4" y2="68.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M419.6,79.3c0-0.7-0.7-1.4-1.6-1.4c-0.9,0-1.6,0.6-1.6,1.4
        c0,0.7,0.7,1.4,1.6,1.4C418.9,80.6,419.6,80,419.6,79.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="401.8" y1="73.1" x2="402.2" y2="72.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="402.1" y1="72.2" x2="392.3" y2="68.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="401.9" y1="73" x2="392.1" y2="68.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="418.7" y1="78" x2="402.2" y2="72.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="417.3" y1="80.5" x2="401.8" y2="73.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.4" y1="68.2" x2="390.3" y2="68.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.5" y1="68.2" x2="410.3" y2="68.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.4" y1="67" x2="410.3" y2="67"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.3" y1="68" x2="392" y2="68.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.3" y1="68" x2="390.2" y2="67.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392" y1="68.9" x2="390" y2="68"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="390.2" y1="67.1" x2="388.4" y2="67.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="390,68 388.5,68 388.5,67.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.4" y1="67" x2="373.4" y2="68.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="410.3" y1="67" x2="410.3" y2="68.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="438.8" y1="128.6" x2="445.8" y2="128.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.2" y1="127" x2="444.5" y2="127"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.3,127c-0.8,0-1.4,0.7-1.4,1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.9,128.6c0-0.9-0.7-1.6-1.4-1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="438.8" y1="131.2" x2="445.8" y2="131.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.2" y1="129.6" x2="444.5" y2="129.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.3,129.6c-0.8,0-1.4,0.7-1.4,1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.9,131.2c0-0.9-0.7-1.6-1.4-1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="438.8" y1="134" x2="445.8" y2="134"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="440.2" y1="132.4" x2="444.5" y2="132.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.3,132.4c-0.8,0-1.4,0.7-1.4,1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.9,134c0-0.9-0.7-1.6-1.4-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M436.7,133.2c0-6.6-5.3-11.9-11.9-11.9c-6.6,0-11.9,5.3-11.9,11.9
        c0,6.6,5.3,11.9,11.9,11.9C431.3,145.1,436.7,139.8,436.7,133.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="409.9" y1="120.7" x2="409.9" y2="147.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.1,120.6c0-1.1-0.4-2-1.1-2.8c-0.7-0.7-1.7-1.1-2.7-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="446.1" y1="147.9" x2="446.1" y2="120.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M413.8,151.8h28.4c1.1,0,2-0.4,2.8-1.1c0.7-0.7,1.1-1.7,1.1-2.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M409.9,147.9c0,1.1,0.4,2.1,1.1,2.8c0.7,0.7,1.7,1.1,2.8,1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M413.9,116.7c-1.1,0-2.1,0.4-2.8,1.2c-0.7,0.7-1.2,1.7-1.2,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="413.9" y1="116.7" x2="442.2" y2="116.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.4" y1="156.3" x2="329.2" y2="156.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="353.4" y1="156.3" x2="368.4" y2="156.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.7" y1="156.3" x2="402.9" y2="156.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.2" y1="111.7" x2="319.2" y2="160.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="403.1" y1="111.7" x2="403.1" y2="160.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.6,133.2c0-5.7-4.6-10.3-10.3-10.3c-5.7,0-10.3,4.6-10.3,10.3
        c0,5.7,4.6,10.3,10.3,10.3C303,143.5,307.6,138.9,307.6,133.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M313.5,120.7c0-2.9-2.4-5.3-5.3-5.3h-28.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M308.2,153.2c2.9,0,5.3-2.4,5.3-5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="313.5" y1="120.7" x2="313.5" y2="147.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M274.5,147.9c0,2.9,2.4,5.3,5.3,5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="308.2" y1="153.2" x2="279.8" y2="153.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.5" y1="147.9" x2="274.5" y2="120.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.9,120.7c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
        C282.1,124.7,283.9,122.9,283.9,120.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.9,140.5c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
        C282.1,144.5,283.9,142.7,283.9,140.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.8,115.4c-2.9,0-5.3,2.4-5.3,5.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="313.5" y1="120.7" x2="313.5" y2="147.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="283.2" y1="128.6" x2="276.2" y2="128.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.8" y1="127" x2="277.6" y2="127"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.2,128.6c0-0.9-0.7-1.6-1.4-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.6,127c-0.8,0-1.4,0.7-1.4,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="283.2" y1="131.2" x2="276.2" y2="131.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.8" y1="129.6" x2="277.6" y2="129.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.2,131.2c0-0.9-0.7-1.6-1.4-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.6,129.6c-0.8,0-1.4,0.7-1.4,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="283.2" y1="134" x2="276.2" y2="134"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.8" y1="132.4" x2="277.6" y2="132.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M283.2,134c0-0.9-0.7-1.6-1.4-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.6,132.4c-0.8,0-1.4,0.7-1.4,1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M309.2,133.2c0-6.6-5.3-11.9-11.9-11.9c-6.6,0-11.9,5.3-11.9,11.9
        c0,6.6,5.3,11.9,11.9,11.9C303.9,145.1,309.2,139.8,309.2,133.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.1" y1="120.7" x2="312.1" y2="147.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M275.9,120.6c0-1.1,0.4-2,1.1-2.7c0.7-0.7,1.7-1.1,2.7-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.9" y1="147.9" x2="275.9" y2="120.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M308.2,151.8h-28.4c-1.1,0-2-0.4-2.8-1.1c-0.7-0.7-1.1-1.7-1.1-2.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M312.1,147.9c0,1.1-0.4,2.1-1.1,2.8c-0.7,0.7-1.7,1.1-2.8,1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M308.1,116.7c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="308.1" y1="116.7" x2="279.8" y2="116.7"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.3,117.6 321.8,119.8 400.4,119.8 402.8,117.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.3" y1="117.6" x2="402.8" y2="117.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="117.6" x2="338.1" y2="119.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="119.8" x2="338.1" y2="121.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="122.3" x2="338.1" y2="124"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="124.5" x2="338.1" y2="126.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="127" x2="338.1" y2="128.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="129.2" x2="338.1" y2="130.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="131.7" x2="338.1" y2="133.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="133.9" x2="338.1" y2="135.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="136.4" x2="338.1" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="138.6" x2="338.1" y2="140.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="141.1" x2="338.1" y2="142.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="143.4" x2="338.1" y2="145"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="145.8" x2="338.1" y2="147.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="148.1" x2="338.1" y2="149.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="150.3" x2="338.1" y2="152"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="117.6" x2="352.7" y2="119.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="119.8" x2="352.7" y2="121.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="122.3" x2="352.7" y2="124"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="124.5" x2="352.7" y2="126.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="127" x2="352.7" y2="128.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="129.2" x2="352.7" y2="130.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="131.7" x2="352.7" y2="133.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="133.9" x2="352.7" y2="135.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="136.4" x2="352.7" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="138.6" x2="352.7" y2="140.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="141.1" x2="352.7" y2="142.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="143.3" x2="352.7" y2="145"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="145.8" x2="352.7" y2="147.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="148.1" x2="352.7" y2="149.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.7" y1="150.3" x2="352.7" y2="152"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369" y1="117.6" x2="369" y2="119.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369" y1="119.8" x2="369" y2="121.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369" y1="122.3" x2="369" y2="123.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369" y1="124.5" x2="369" y2="126.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369" y1="127" x2="369" y2="128.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="129.2" x2="369.1" y2="130.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="131.7" x2="369.1" y2="133.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="133.9" x2="369.1" y2="135.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="136.4" x2="369.1" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="138.6" x2="369.1" y2="140.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="141.1" x2="369.1" y2="142.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="143.4" x2="369.1" y2="145"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="145.8" x2="369.1" y2="147.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="148.1" x2="369.1" y2="149.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="369.1" y1="150.3" x2="369.1" y2="152"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="117.6" x2="384.5" y2="119.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="119.8" x2="384.5" y2="121.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="122.3" x2="384.5" y2="123.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="124.5" x2="384.5" y2="126.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="127" x2="384.5" y2="128.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="129.2" x2="384.5" y2="130.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="131.7" x2="384.5" y2="133.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="133.9" x2="384.5" y2="135.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="136.4" x2="384.5" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="138.6" x2="384.5" y2="139.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="143.8" x2="384.5" y2="145"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="145.8" x2="384.5" y2="147.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="148.1" x2="384.5" y2="149.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="150.3" x2="384.5" y2="152"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.2" y1="116.4" x2="402.9" y2="116.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.1" y1="116.4" x2="338.1" y2="111.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.5" y1="116.3" x2="384.5" y2="111.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="353.4" y1="154.9" x2="329.2" y2="154.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="353.4,159.8 329.2,159.8 329.2,154.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="353.4" y1="159.8" x2="353.4" y2="154.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.7" y1="154.9" x2="368.4" y2="154.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="392.7,159.8 368.4,159.8 368.4,154.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.7" y1="159.8" x2="392.7" y2="154.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M457.5,147.4c0-1.3-0.9-2.2-1.6-2.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="450.6" y1="229.4" x2="434.8" y2="229.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.5" y1="201.2" x2="264.5" y2="218.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.5" y1="147.4" x2="264.5" y2="201.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="290.4" y1="171.8" x2="290.4" y2="218.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264.5,218.5c-0.6,5.2-1.2,11.2,7,11"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M290.4,218.8c0.4,4.7,1.5,10.8-3,10.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264.5,147.4c0-1.1,0.6-1.9,1.2-2.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428.2" y1="183.7" x2="426" y2="183.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.7" y1="183.7" x2="422.1" y2="183.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="318.6" y1="183.7" x2="300.7" y2="183.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M388.3,141.7c0-1.1-1.9-2.1-4.1-2.1c-2.3,0-4.1,0.9-4.1,2.1
        c0,1.1,1.9,2.1,4.1,2.1C386.4,143.7,388.3,142.8,388.3,141.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="388.1" y1="141.1" x2="403" y2="141.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.5" y1="141.1" x2="380.2" y2="141.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.7" y1="183" x2="296.4" y2="186.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.7" y1="183.2" x2="300.5" y2="187"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.7" y1="183.2" x2="296.7" y2="183"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.4" y1="186.7" x2="300.5" y2="187"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.3" y1="187.3" x2="296.1" y2="191.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.3" y1="187.6" x2="300.1" y2="191.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.3" y1="187.6" x2="296.3" y2="187.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.1" y1="191.1" x2="300.1" y2="191.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.6" y1="191.2" x2="296.4" y2="193.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.4" y1="191.3" x2="299.2" y2="194"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.4" y1="193.8" x2="299.2" y2="194"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.4" y1="186.5" x2="392" y2="191.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.6" y1="183.8" x2="393.8" y2="188.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M392,191.3c1,0.1,3.7-0.4,4.8-1.5c1.7-1.6,1.1-1.8,2.9-1.5l-0.3,4.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="406.2" y1="188.7" x2="405.9" y2="193.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M399.8,188.4l6.5,0.4c1.4,0.1,1.3-2.8,0.2-2.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="399.5,192.8 400.1,193.7 405.1,194 405.9,193.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="399.2" y1="185.4" x2="406.4" y2="185.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M399.2,185.4c0.2-2.4-3.4-2.6-3.6-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M398.6,185.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2
        c0,0.7,0.5,1.2,1.2,1.2C398.1,186.5,398.6,185.9,398.6,185.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="405.4" y1="171.8" x2="405.4" y2="179.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="405.4" y1="179.3" x2="392.2" y2="179.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.2" y1="179.3" x2="392.2" y2="171.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.8" y1="171.8" x2="330.8" y2="179.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.8" y1="179.3" x2="317.6" y2="179.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.6" y1="179.4" x2="317.6" y2="171.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.7" y1="182.5" x2="330.7" y2="175.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="391.8" y1="187.9" x2="329.6" y2="180.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="391.8" y1="187.9" x2="392.7" y2="182.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="329.7" y1="180.8" x2="329.9" y2="179.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="392.6,183.1 395.9,183.6 395.2,187.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392" y1="187.1" x2="395.2" y2="187.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M393.8,188.7c0.9-0.1,1-0.4,1.4-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M417.3,186.2c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.1" y1="186.2" x2="422.1" y2="182.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="417.3,186.2 417.3,182.2 422.1,182.2 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M421.2,186.2c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5
        c0,0.8,0.7,1.5,1.5,1.5C420.5,187.8,421.2,187.1,421.2,186.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="417.3" y1="182.9" x2="392.6" y2="182.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="417.3" y1="186.2" x2="406.9" y2="186.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="422.7,180.6 422.7,188.3 422.7,196 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="426,180.6 426,188.3 426,196 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428.9" y1="192.8" x2="426" y2="192.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.7" y1="192.8" x2="361.5" y2="192.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.1" y1="180.6" x2="422.7" y2="180.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.1" y1="196" x2="422.7" y2="196"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296" y1="180.6" x2="296" y2="188.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.3" y1="180.6" x2="299.3" y2="183.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.3" y1="192.1" x2="299.3" y2="196"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.4" y1="180.6" x2="296" y2="180.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.4" y1="196" x2="296" y2="196"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296" y1="188.3" x2="296" y2="196"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.5" y1="192.1" x2="296" y2="192.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.3" y1="192.1" x2="315.1" y2="192.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.6" y1="183.7" x2="296" y2="183.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.9" y1="186.8" x2="296.9" y2="187.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.8" y1="187" x2="299.8" y2="187.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.5" y1="159.5" x2="274.5" y2="105.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447.5" y1="159.5" x2="447.5" y2="105.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.8,159.5c-0.7,0-1.3,0.6-1.3,1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.5,171.6c0,0.6,0.5,1.1,1.1,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.5" y1="160.8" x2="445.5" y2="171.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="446.6" y1="172.7" x2="456.8" y2="172.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M457.9,160.7c0-0.6-0.5-1.2-1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="446.8" y1="159.5" x2="456.8" y2="159.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="457.9" y1="160.7" x2="457.9" y2="171.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M456.8,172.7C456.8,172.7,456.8,172.7,456.8,172.7
        c0.7,0,1.2-0.5,1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M276.4,160.8c0-0.7-0.6-1.3-1.3-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M275.3,172.7c0.6,0,1.1-0.5,1.1-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="276.4" y1="160.8" x2="276.4" y2="171.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.3" y1="172.7" x2="265.1" y2="172.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M265.1,159.5c-0.6,0-1.2,0.5-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.1" y1="159.5" x2="265.1" y2="159.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264" y1="160.7" x2="264" y2="171.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264,171.5c0,0.6,0.5,1.2,1.2,1.2c0,0,0,0,0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M458.9,90.4c-0.5,0-0.9-0.4-1.1-1.6c-0.9-5.2-1.5-10.2-3.7-17.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M461.4,90.4c0.5,0,0.9-0.4,1.1-1.6c0.9-5.2,1.5-10.2,3.7-17.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M260.4,91.1c-0.5,0-0.9-0.4-1.1-1.6c-0.9-5.2-1.5-10.2-3.7-17.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M262.9,91.1c0.5,0,0.9-0.4,1.1-1.6c0.9-5.2,1.5-10.2,3.7-17.7"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="319.5,141.1 322,143.3 381.6,143.3 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="386.7,143.3 400.6,143.3 403,141.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.9" y1="81.2" x2="293.9" y2="84.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="297.3" y1="84.8" x2="297.3" y2="81.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M293.9,84.9c0,0.7,0.6,1.2,1.2,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M295.9,86.2c0.7,0,1.3-0.6,1.3-1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295.1" y1="86.2" x2="295.9" y2="86.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="289.7" y1="80.3" x2="290.2" y2="80.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="293.5,80.3 297.7,80.3 297.7,81.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="289.7" y1="80.3" x2="289.7" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="351.7" y1="79" x2="351.7" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="348.3" y1="81.2" x2="348.3" y2="79"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="348" y1="81.2" x2="348" y2="84.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="344.6" y1="84.8" x2="344.6" y2="81.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M346.7,86.1c0.7,0,1.2-0.6,1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M344.6,84.8c0,0.7,0.6,1.3,1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="346.7" y1="86.1" x2="345.9" y2="86.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.2" y1="80.3" x2="351.7" y2="80.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="348.3,80.3 344.2,80.3 344.2,81.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352.2" y1="80.3" x2="352.2" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="286.9" y1="81.2" x2="351.4" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="370.4" y1="79" x2="370.4" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.8" y1="81.2" x2="373.8" y2="78.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="374.2" y1="81.2" x2="374.2" y2="84.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="377.6" y1="84.7" x2="377.6" y2="81.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M374.2,84.8c0,0.7,0.6,1.2,1.2,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M376.2,86.1c0.7,0,1.3-0.6,1.3-1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="375.4" y1="86.1" x2="376.2" y2="86.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="370" y1="80.2" x2="370.4" y2="80.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="373.8,80.2 377.9,80.2 377.9,81.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="370" y1="80.2" x2="370" y2="81.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.2" y1="79" x2="432.2" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428.8" y1="81.2" x2="428.8" y2="78.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428.5" y1="81.2" x2="428.5" y2="84.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="425.1" y1="84.7" x2="425.1" y2="81.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.2,86.1c0.7,0,1.2-0.6,1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M425.1,84.7c0,0.7,0.6,1.3,1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.2" y1="86.1" x2="426.4" y2="86.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.7" y1="80.2" x2="432.2" y2="80.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="428.8,80.2 424.7,80.2 424.7,81.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.7" y1="80.2" x2="432.7" y2="81.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="434.9" y1="81.2" x2="366.8" y2="81.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M438.4,21.9c-0.3-4.4-3.9-7.8-8.3-7.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="439.4" y1="33.9" x2="438.3" y2="21.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M291.6,14.1c-4.5,0-8.2,3.5-8.5,8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.2" y1="33.7" x2="283.1" y2="22.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="291.8" y1="14.1" x2="430.8" y2="14.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="287.1" y1="29.3" x2="283.1" y2="22.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="434.6" y1="29.3" x2="438.3" y2="21.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.6" y1="27.3" x2="293.6" y2="14.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428.1" y1="27.3" x2="428.1" y2="14.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M331.3,21.4l0.4-2.4c0.2-1,1.1-1.8,2.1-1.8h0h54.7h-0.1
        c1,0,1.9,0.7,2.1,1.7l0,0l0.5,2.4l0,0.4c0,1.2-0.9,2.1-2.1,2.1h0.2h-55.9h0.1c-1.2,0-2.1-0.9-2.1-2.1
        C331.3,21.7,331.3,21.5,331.3,21.4z"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="349" y1="177.5" x2="348.2" y2="176.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="352" y1="176.4" x2="353.3" y2="178"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M361.1,101l-44.8-0.1c-1.4,0-3.1-0.6-3.4-1.4c-0.3-0.8-1.2-4.1-1.2-4.1"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M315.2,91.5c0,0,0.4,2.5,0.5,3.7c0.1,1.3-0.1,3-0.2,4.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M361.1,104.2H319c-2.7,0-4.7,0.2-7.9-1.1c-3.1-1.3-17.1-6.7-17.1-6.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M410.4,95.1h19.9c5,0,8.6,6.7,8.6,10"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M410.4,95c-0.7-2.6-1.9-4.5-4.9-4.6l-44.4,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M361.1,101l44.9-0.1c1.4,0,3.1-0.6,3.4-1.4c0.3-0.8,1.2-4.1,1.2-4.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M407.1,91.5c0,0-0.4,2.5-0.5,3.7c-0.1,1.3,0.1,3,0.2,4.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M361.1,104.2h42.1c2.7,0,4.7,0.2,7.9-1.1c3.1-1.3,17.1-6.7,17.1-6.7"/>
    </g>
  </g></>)
  }
}