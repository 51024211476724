import React from 'react';
import styles from './index.module.css';
import { MapModule } from "./MapModule";

const Tracking = (props: any) => {
  return (
    <div className={styles.screen}>
      <MapModule />
    </div>
  );
}

export default Tracking

