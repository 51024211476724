import { IAuthState, IUser } from '../../reducers/auth/types';
import Evaporate from 'evaporate';

export const UPDATE_AUTH_STATE = "UPDATE_AUTH_STATE";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const SET_AUTH_EVAPORATE_CLIENT = "SET_AUTH_EVAPORATE_CLIENT";

interface IUpdateAuthAction {
  type: typeof UPDATE_AUTH_STATE;
  payload: IAuthState;
}

interface IAddUserData {
  type: typeof ADD_USER_DATA;
  payload: IUser;
}

export interface SetAuthEvaporateClientAction {
  type: typeof SET_AUTH_EVAPORATE_CLIENT,
  payload: { _e_: Evaporate }
}

export type AuthAction = IUpdateAuthAction | IAddUserData | SetAuthEvaporateClientAction
