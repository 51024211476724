import Evaporate from "evaporate";
import { IAddress, ICostCenter } from "../website/types";

export interface IAuthState {
  state: AuthStates;
  accountType?: AccountType;
  authUser?: IAuthUser;
  user?: IUser;
  token?: string;
  message?: string;
  _e_?: Evaporate;
}

export enum AccountType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}

export enum CustomerType {
  INDIVIDUAL = "individual",
  BUSINESS = "business",
  DRIVER = "driver",
}

export interface IAuthUser {
  _id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
}

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  customerId?: string;
  id: string;
  phoneNumber: IPhoneNumber;
  role: UserRole;
  customer?: ICustomer;
  businesscustomers?: IBusinessCustomer;
  active?: boolean;
}

export enum CustomerVerificationStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  IN_REVIEW = "IN_REVIEW",
}

export enum BusinessCustomerStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
}

export enum UserRole {
  ADMIN_USER = "ADMIN_USER",
  DRIVER = "DRIVER",
}

export interface IPhoneNumber {
  phone: string;
  country: string;
}

export interface ICustomer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  license: IDriverLicense;
  nationalInsuranceNumber: string;
  profession: string;
  profilePicture: string;
  tenantId: string;
  status: string;
  documents: ICustomerDocument[];
  notes: ICustomerNote[];
  showDeclinedNote?: boolean;
  taxExempted?: boolean;
  approvedDrivers?: ICustomer[] | string[];
  costCenters?: ICostCenter[];
  location?: IAddress;
  creditSafeData?: ICreditSafeData;
}

export interface ICustomerNote {
  id: string;
  description: string;
  createdBy: string;
  createdDate: string;
}

export interface ICustomerDocument {
  id: string;
  documentKey: string;
  title: string;
  documentName: string;
  documentType: string;
  reminderBefore?: number;
  expiryDate: string;
  createdAt?: string;
}

export interface IDriverLicense {
  authority: string;
  dateOfExpiry: string;
  dvlaVerificationStatus: string;
  endorsement: string;
  images: string[];
  licenseNumber: string;
  offences: number;
  points: number;
}

export interface IBusinessCustomer {
  id: string;
  businessName: string;
  registrationNumber: string;
  VAT: string;
  registeredAddress: ILocation;
  tradingAddressSame: boolean;
  tradingAddress: ILocation;
  approvedDrivers: IBusinessCustomerApprovedDriverInput[];
  authorizedSignatories: IBusinessCustomerCommonInput[];
  billing: IBusinessCustomerBillingInput;
  contact: IBusinessCustomerCommonInput;
  documents: IBusinessCustomerDocumentInput[];
  notes?: IBusinessCustomerNote[];
  overrideNetPaymentTerms?: boolean;
  netPaymentTerms?: number;
  overrideInvoiceDate?: boolean;
  invoicingDay?: number;
  taxExempted?: boolean;
  status: string;
  declinedReason: string;
  businessCreditData?: IBusinessCreditData;
}

interface IBusinessCreditData {
  creditScore?: number;
  creditDescription?: string;
  lastChecked?: string;
  companyId?: string;
}

interface ICreditSafeData {
  creditScore: number;
  lastChecked: string;
}

export interface ILocation {
  street: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  fullAddress: string;
}

export interface IBusinessCustomerApprovedDriverInput {
  id?: string;
  status?: string;
  nationalInsuranceNumber?: string;
  profession?: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
  license: IDriverLicense;
  tenantId: string;
  profilePicture?: string;
  documents: ICustomerDocument[];
  showDeclinedNote?: boolean;
  notes: ICustomerNote[];
  costCenters?: ICostCenter[];
  approvedDrivers?: ICustomer[];
  creditSafeData?: ICreditSafeData;
}

export interface IBusinessCustomerCommonInput {
  id?: string;
  status?: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
}

export interface IBusinessCustomerBillingInput {
  phoneNumber: IPhoneNumber;
  email: string;
  accountNumber?: string;
}

export interface IBusinessCustomerDocumentInput {
  id?: any;
  name: string;
  title: string;
  url: string;
  expiryDate: string | undefined;
  documentType: string;
  reminderBefore?: number;
  createdAt?: string;
}

export interface IBusinessCustomerNote {
  id?: string;
  description: string;
  createdBy: string;
  createdDate: string;
}

export enum AuthStates {
  SIGNED_IN = "SIGNED_IN",
  SET_PASSWORD = "SET_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  OTP_SENT = "OTP_SENT",
  SIGNED_OUT = "SIGNED_OUT",
  SELECT_ACCOUNT = "SELECT_ACCOUNT",
  SIGN_UP = "SIGN_UP",
  CUSTOMER_SELECTION = "CUSTOMER_SELECTION",
  BUSINESS_CUSTOMER = "BUSINESS_CUSTOMER",
}
