export default {
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "12px 14px 10px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)"
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #FFC700"
    }
  }
};
