import React from "react"

export const Hatchback5DoorSVG = {
    interior:{
        width:376,
        height:704,
        svg:(<><g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M179.1,12.3l48.4,2l1.7,1.2c0,0,44.1,5.2,74.2,33
                c0,0,10.4,11,11,23.8c0,0,12.8,40.6,13.3,88.7c0,0,0.6,37.1-1.2,53.3c0,0-1.2,1.7-1.7,9.3l0.6,79.4l1.2,76.5l-2.3,103.8l-1.7,41.7
                c0,0,7,51.6,0,99.1c0,0,1.7,15.7-8.1,31.9l-4.6,12.2c0,0-4.1,12.2-27.8,16.8c0,0-30.4,10.1-101.1,13.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M179.1,12.3l-47,2l-1.7,1.2c0,0-44.1,5.2-74.2,33
                c0,0-10.4,11-11,23.8c0,0-12.8,40.6-13.3,88.7c0,0-0.6,37.1,1.2,53.3c0,0,1.2,1.7,1.7,9.3L34.2,303l-1.2,76.5l2.3,103.8l1.7,41.7
                c0,0-7,51.6,0,99.1c0,0-1.7,15.7,8.1,31.9l4.6,12.2c0,0,4.1,12.2,27.8,16.8c0,0,32.5,10.1,103.2,13.6"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M179.1,170c0,0,71.9-4.6,118.8,26.1c0,0,11,7.5,9.3,15.1
                l-24.9,114.2c0,0-56.2-13.9-102-12.2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M181.6,170c0,0-71.9-4.6-118.8,26.1c0,0-11,7.5-9.3,15.1l24.9,114.2
                c0,0,56.2-13.9,102-12.2"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M178.2,182.1c0,0,68.2-4.1,112.8,23c0,0,10.5,6.7,8.8,13.3
                l-23.7,100.8c0,0-53.4-12.3-96.8-10.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M180.6,182.1c0,0-68.2-4.1-112.8,23c0,0-10.5,6.7-8.8,13.3
                l23.7,100.8c0,0,53.4-12.3,96.8-10.7"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M52.8,210.6l-0.6-12.8c0,0,4.6-104.3,7-114.2"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M52.3,199.6c0,0,33.5-35.4,128.5-34.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M64.9,163.6c0,0,18-81.1,32.5-113.6"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M307.2,210.6l0.6-12.8c0,0-4.6-104.3-7-114.2"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M307.6,199.6c0,0-33.5-35.4-128.5-34.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M295,163.6c0,0-18-81.1-32.5-113.6"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M103.6,32.8l-4.7,8.5c-0.2,0.4-0.6,0.8-0.9,1.1l-7,5.9
            c0,0-28.4,24.9-34.2,34.2c0,0-3,4-5.3,5.5c-0.4,0.2-0.8,0.4-1.2,0.4c-1.7,0-6.1-0.6-5.7-7.4c0-0.1,0-0.3,0-0.4
            c0.5-2.8,5.9-31.9,32.4-36.9c0,0,2.9-0.6,4.1-1.7l21.8-9.8C103.4,31.9,103.9,32.4,103.6,32.8z"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M256.2,32.8l4.7,8.5c0.2,0.4,0.6,0.8,0.9,1.1l7,5.9
            c0,0,28.4,24.9,34.2,34.2c0,0,3,4,5.3,5.5c0.4,0.2,0.8,0.4,1.2,0.4c1.7,0,6.1-0.6,5.7-7.4c0-0.1,0-0.3,0-0.4
            c-0.5-2.8-5.9-31.9-32.4-36.9c0,0-2.9-0.6-4.1-1.7l-21.8-9.8C256.4,31.9,256,32.4,256.2,32.8z"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M103.8,32c0,0,6.4-5.8,2.9-7.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M256.7,32c0,0-6.4-5.8-2.9-7.5"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M180.3,34.9c0,0,56.6,1.7,73.4,5
            c0.4,0.1,0.7-0.3,0.6-0.6c-1.2-3.2-6.1-15.2-16.5-21.2l-5.1-1.4c-0.9-0.2-1.7-0.4-2.6-0.4c-6.9-0.4-38.8-2.2-49.1-2.2"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M180.3,34.9c0,0-57.4,1.7-74.2,5
            c-0.4,0.1-0.7-0.3-0.6-0.6c1.2-3.2,6.1-15.2,16.5-21.2l5.1-1.4c0.9-0.2,1.7-0.4,2.6-0.4c6.9-0.4,40.8-2.2,51.1-2.2"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M178.5,32.6c0,0,15.7-0.6,66.7,4.1c0,0,2.3,0.6,2.9-0.6
                c0,0-0.4-7.7-11.5-13.9c-3.4-1.9-7.2-3-11.1-3.2c-9.6-0.6-32.7-2-46.9-2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M180.3,32.6c0,0-15.7-0.6-66.7,4.1c0,0-2.3,0.6-2.9-0.6
                c0,0,0.4-7.7,11.5-13.9c3.4-1.9,7.2-3,11.1-3.2c9.6-0.6,32.7-2,46.9-2"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M113.6,36.7c0,0,3.5-11.6,16.2-13.9c0,0,22-3.5,50.4-3.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M246.9,36.7c0,0-3.5-11.6-16.2-13.9c0,0-22-3.5-50.4-3.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M115.9,36.7c0,0,1.2-3.5,63.2-6.4"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M116.5,36.1c0,0,1.2-4.5,4.9-5.7c0.2-0.1,0.4-0.1,0.5-0.1
            c2.8-0.4,42-2.9,58.3-2.9"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M121.2,30.3c0,0,0-1.1,1-2.2c0.7-0.8,1.7-1.2,2.8-1.4
            c4.5-0.5,40.1-2.2,56.5-2.2"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M244.6,36.7c0,0-10.4-4.6-65.5-6.4"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M243.5,36.1c0,0-0.6-4-4.4-5.1c-0.2-0.1-0.4-0.1-0.5-0.1
            c-2.8-0.4-42.6-3.4-58.8-3.4"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M239.4,30.9c0,0,0-1.1-1-2.2c-0.7-0.8-1.7-1.2-2.8-1.4
            c-4.5-0.5-37.8-2.8-54.1-2.8"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M36.5,626.8c0,0,11,9.3,8.7,30.1"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M323.2,626.8c0,0-11,9.3-8.7,30.1"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M45.7,650.1c0,0,3.5,2.7,10.5-1.9l21.4-40c0,0,4.6-8.7,9.3-9.9
            l96.8-1.2"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M314,650.1c0,0-3.5,2.7-10.5-1.9l-21.4-40c0,0-4.6-8.7-9.3-9.9
            l-96.8-1.2"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M77.7,608.2c0,0-1.7,18.5,104.3,19.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M281.7,608.2c0,0,1.7,18.5-104.3,19.1"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M178.5,672H193c0,0,4.1,0.6,7.5,2.9c0,0,1.2,1.2,8.1,0.6
                c0,0,42.9-5.2,59.7-9.3l17.4-5.2c0,0,6.1-2.4,8.4-8.3c0.8-2,0.7-4.2-0.1-6.2l-10.6-24.9c0,0-3.5-5.8-10.4-2.9
                c0,0-24.9,9.9-91.6,10.4"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M181.7,672h-14.5c0,0-4.1,0.6-7.5,2.9c0,0-1.2,1.2-8.1,0.6
                c0,0-42.9-5.2-59.7-9.3L74.5,661c0,0-5.7-2.3-8.2-7.8c-1-2.2-1-4.7,0-6.9l10.5-24.7c0,0,3.5-5.8,10.4-2.9c0,0,27.5,9.9,94.2,10.4"
                />
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M56.2,648.2c0,0,3.5,9.9,12.2,13.9c0,0,27.8,15.7,111.3,17.4"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M303.2,648.2c0,0-3.5,9.9-12.2,13.9c0,0-27.8,15.7-111.3,17.4"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M53.9,649.4c0,0,0.6,8.7,7,12.7c0,0,0,0,0,0l24,11.4
            c0.1,0,0.1,0.2,0,0.1c-2.5-0.1-17-1.3-31.1-8.5c-0.3-0.1-0.6-0.4-0.8-0.6c-1.3-1.5-5.7-7.2-7.2-14.1"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M305.8,649.9c0,0-0.6,8.7-7,12.7c0,0,0,0,0,0l-24,11.4
            c-0.1,0-0.1,0.2,0,0.1c2.5-0.1,17-1.3,31.1-8.5c0.3-0.1,0.6-0.4,0.8-0.6c1.3-1.5,5.7-7.2,7.2-14.1"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M84.9,673.5c0,0,81,22.4,189.9,0.6"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M91.2,675.2c0,0,2.1,8.4,10.2,11.3c0,0,29,7,81.7,7.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M267.8,675.2c0,0-2.1,8.4-10.2,11.3c0,0-29,7-81.7,7.5"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M53.9,670.2c0,0,9.3,7,35.9,12.8"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M305.5,669.7c0,0-13.9,8.1-35.4,12.8"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M42.5,217.8l24.1,98.2c0,0,7,44.6,8.1,66.1l1.7,27.2l1.2,103.2
                l-0.6,45.8c0,0,1.2,10.4-2.9,16.8l-15.7,23.2c0,0-1.7,3.5-4.6,3.5c0,0-7,0.6-7.5-26.7L41.7,479L40,408.2l-0.6-100.9v-66.7
                c0,0,0-18.9,2.2-23C41.8,217.3,42.4,217.4,42.5,217.8z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M49.3,97.5c0,0-8.7,64.9-9.3,124.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M41.9,218.7l1,177.4l2.3,118.2l3.5,46.4l2.3,27.8
                c0,0,1.2,11,8.1,9.3"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M44.6,265.1l8.7,5.2l1.2,1.2l11.6,51l6.4,52.2l1.7,33.6
                c0,0-9.9-0.6-29-13.3L44.6,265.1z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M45.8,426.2c0,0,15.7,4.6,28.4,2.9l1.2,106.1l-13.9,11l-11-1.7
                L45.8,426.2z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M52.2,563l23.2-19.7v20.9c0,0-0.6,10.4-5.8,16.2l-9.3,12.8
                c0,0-3.5,1.7-5.2-2.3L52.2,563z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M75.9,539.2l-24.3,21.4c0,0-4.1-1.2-5.2,1.7l-5.8,4.1
                c0,0-1.7,1.2-2.9-1.2l-2.9-16.8"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M76.5,421c0,0-19.8,0.9-33.1-7.7l-3.1-0.3c0,0-5.5-1.9-6.7-3.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M52.8,211.2c0,0-8.7,3.5-7.5,15.7"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M316.9,217.8l-24.1,98.2c0,0-7,44.6-8.1,66.1l-1.7,27.2l-1.2,103.2
                l0.6,45.8c0,0-1.2,10.4,2.9,16.8l15.7,23.2c0,0,1.7,3.5,4.6,3.5c0,0,7,0.6,7.5-26.7l4.6-96.2l1.7-70.7l0.6-100.9v-66.7
                c0,0,0-18.9-2.2-23C317.6,217.3,317,217.4,316.9,217.8z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M310.1,97.5c0,0,8.7,64.9,9.3,124.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M317.5,218.7l-1,177.4l-2.3,118.2l-3.5,46.4l-2.3,27.8
                c0,0-1.2,11-8.1,9.3"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M314.8,265.1l-8.7,5.2l-1.2,1.2l-11.6,51l-6.4,52.2l-1.7,33.6
                c0,0,9.9-0.6,29-13.3L314.8,265.1z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M313.6,426.2c0,0-15.7,4.6-28.4,2.9L284,535.2l13.9,11l11-1.7
                L313.6,426.2z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M307.2,563L284,543.3v20.9c0,0,0.6,10.4,5.8,16.2l9.3,12.8
                c0,0,3.5,1.7,5.2-2.3L307.2,563z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M283.5,539.2l24.3,21.4c0,0,4.1-1.2,5.2,1.7l5.8,4.1
                c0,0,1.7,1.2,2.9-1.2l2.9-16.8"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M282.9,421c0,0,19.8,0.9,33.1-7.7l3.1-0.3c0,0,5.5-1.9,6.7-3.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.6,211.2c0,0,8.7,3.5,7.5,15.7"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M34.2,251.7l-19.7,15.4c-1.5,1.2-2.8,2.6-3.8,4.3
            c-1.8,3.1-3.9,8.2-2.1,13.3c0,0,0,1.2,1.7,1.3c0.6,0,1.3-0.1,1.9-0.4l22-11.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M326,251.7l19.7,15.4c1.5,1.2,2.8,2.6,3.8,4.3
            c1.8,3.1,3.9,8.2,2.1,13.3c0,0,0,1.2-1.7,1.3c-0.6,0-1.3-0.1-1.9-0.4l-22-11.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M347.6,285.6l-22-11.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M325.6,251.7l19.7,15.4"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M266.7,528.5H91.3c-5.4,0-9.8-4.4-9.8-9.8v-55.4
            c0-5.4,4.4-9.8,9.8-9.8h175.4c5.4,0,9.8,4.4,9.8,9.8v55.4C276.5,524.1,272.1,528.5,266.7,528.5z"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M126,419.5h31.3c0,0-4.1-16.8,8.7-21.4c0,0,5.8-6.4,6.4-19.7
                l-1.7-35.9c0,0-2.9-12.2-12.2-16.8c0,0-11.6-2.3-31.9-1.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M126,419.5H94.7c0,0,4.1-16.8-8.7-21.4c0,0-5.8-6.4-6.4-19.7
                l1.7-35.9c0,0,2.8-11.7,11.7-16.6c0.3-0.2,0.7-0.3,1.1-0.4c2.7-0.4,14.9-2.2,32.8-1.6"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M234,419.5h31.3c0,0-4.1-16.8,8.7-21.4c0,0,5.8-6.4,6.4-19.7
                l-1.7-35.9c0,0-2.2-9.3-9-14.7c-1.7-1.3-3.7-2.1-5.8-2.4c-4.7-0.5-15.8-1.4-30.8-1.4"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M234,419.5h-31.3c0,0,4.1-16.8-8.7-21.4c0,0-5.8-6.4-6.4-19.7
                l1.7-35.9c0,0,2.7-11.2,11.1-16.2c0.6-0.4,1.3-0.6,2.1-0.8c3.2-0.5,14.1-2,30.9-1.6"/>
        </g>
    </g></>)
    },

    exterior:{
        width:1002,
        height:1419,
        svg:(<><g id="Layer_1">
        <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M108.8,553c49.3-0.6,73.1-29.4,73.1-63.6s-26.1-63.6-73.1-61.2V553z
                "/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M109.9,999.8c39.2,1.2,71.9-25,71.3-63
                c-0.5-34.1-25.5-63.6-71.9-64.2L109.9,999.8z"/>
            <g>
                <ellipse fill="#F7F5F5" stroke="#000000" stroke-miterlimit="10" cx="117.4" cy="489.7" rx="53.8" ry="54.4"/>
                <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="118" cy="490" rx="37.1" ry="36.8"/>
            </g>
            <g>
                <ellipse fill="#F7F5F5" stroke="#000000" stroke-miterlimit="10" cx="116.8" cy="938.3" rx="53.8" ry="54.4"/>
                <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="117.4" cy="938.6" rx="37.1" ry="36.8"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M102.2,427.9v-9.2l-3-8.9v-25.5h4.8v-28.5c0,0,0.4-2.7,3-6.2
                c1.2-1.7,3.5-2.1,5.3-1.1l2.4,1.3l23.8-1.2l3.6-4.2l20.2,1.2l3.8,4.6c1,1.2,2.3,2,3.8,2.2c4.2,0.6,13.3,2.5,20.7,7.6
                c0.9,0.6,1.6,1.4,2,2.3l0.4,0.7c0.4,0.9,1.1,1.7,1.9,2.2c3.2,2.3,12.4,10,19.6,28.4c0,0,12.5,31.5,20.2,75.5
                c0,0,4.2,25.5,6.5,49.3l33.3,52.3l19.6,35.1l19.6,39.2c0,0,8.3,18.4,12.5,39.8c0,0,5.3,44.6,5.3,84.4l-1.2,63l-4.8,66.5l-5.3,44
                l-1.2,5.3l-2.4,25c0,0,0,4.8-4.8,3c0,0-2.4-1.2-4.8,0l-19,22c0,0-20.2,19.6-29.7,25.5c0,0-2.4,4.8-7.1,4.8c0,0-3-1.2-5.3,1.2
                c0,0-6.5,4.2-12.5,5.3c0,0-12.5,1.2-26.7,0l-22-0.6l-4.2,0.6c0,0-1.2,9.5-6.5,12.5c0,0-3,4.8-7.7,4.8c0,0-2.4-0.6-2.4-5.3h-15.4
                h-7.4l-0.3,1.8c0,0-18.4,0-32.1-22l-8.9,1.2v-8.3h3.6l3-3.6V1004c0,0,1.2-2.4,3-3.6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M109.3,1000.4c46.3-1.2,71.9-28.7,71.9-63.3s-26.1-66.8-79-64.5
                V557.1c0,0-0.6-4.2,5.3-4.2c48.8,0,74.3-28.1,74.3-62.7s-23.2-65.7-80.2-62.1"/>
            <path fill="none" d="M241,518.2l1.8,25c0,0,51.1,68.9,66.5,108.7c0,0,17.8,36.2,16.6,105.8c0,0,0.6,118.8-11.9,188.4l-14.3,14.3
                l-19.6,16.6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M241.3,519.1l1.5,24.1c0,0,50.5,71.9,66.8,109.6
                c0,0,1.8,2.4,3.6-8.9C313.1,643.9,279.3,572.6,241.3,519.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M310.2,653.4c0,0,8.3,19.6,13.1,59.4c0,0,2.4,14.3,3,54.7
                c0,0-1.2,126.6-12.5,179.4l5.9-1.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313.7,946.9L300.1,960l-1.8,0.6l-25.5,22c0,0-9.5,7.7-18.4,12.5
                c0,0-4.2,3-11.3,4.2c0,0-28.5,3-46.3,1.8c0,0-4.8,0.6-11.9-11.9l-11.9-20.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M302.2,958.2h3.7c1.2,0,2.4,0.6,3.1,1.5c2.2,2.9,6.8,9.3,7.7,13.3"
                />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M301.9,958.2c0,0,7.7,5.3,10.1,15.4c0,0,0.6,1.8,1.2,3"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M160,402.5c-0.9-0.8-1.4-2-1.5-3.2l-2.9-43.1
                c-0.1-1.9-0.5-2.7,0.5-3.2c0.4-0.2,0.8-0.3,1.2-0.3h3c2.4,0,4,3.1,4.2,5.3l3.6,44.6c0.1,1.8-0.4,2.5-1.9,2.8
                c-0.7,0.1-1.3,0.1-2-0.1l0,0c-0.8-0.3-1.5-0.6-2.2-1.1C161.4,403.7,160.6,403.1,160,402.5z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M303.6,959.4c0,0-32.1,30.9-52.3,40.4c0,0-1.2,20.8-5.3,29.7"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M196.7,1025.3c0,0-5.9-0.6-9.5,1.8c0,0-11.9,5.9-12.5,19.6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M146.8,552.4c0,0,3,10.7,3,14.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="133.7" y1="556.8" x2="133.7" y2="566.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M304.8,978.4v-6.5c0,0-0.6-2.4-3-1.2l-43.4,37.4
                c0,0-6.5,4.8-7.1,19.6c0,0,3,1.2,7.1-4.2l16.6-13.7l11.9-10.7l12.5-14.3L304.8,978.4z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M115.9,422.3c45.8,3,71.9,30.8,71.9,68
                s-29.7,68-68.9,68"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M108.2,865.5c54.7,3.6,79.6,33.2,79.6,70.4
                s-24.4,62.7-60,68.6"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="113.5" y1="552.7" x2="113.5" y2="866.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="109.9" y1="347.4" x2="112.9" y2="427.6"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M211,431.2c0,0,11.9,54.7,16,115.3"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M190.7,434.1c0,0,51.7,71.3-16,123.6"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="130.1" y1="567.5" x2="133.1" y2="869.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="133.7" y1="567.5" x2="136.7" y2="870.6"/>
            <polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="149.7,568.4 152.1,858.4 148,874.4 		
                "/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="127.2" y1="998.6" x2="128.4" y2="1045"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="137.9" y1="1047.9" x2="137.9" y2="1017"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M164,1047l-0.6-37c0-0.1,0-0.2,0-0.3
                c0-0.4-0.1-1.7,0.6-3.1c0.7-1.3,2-2.2,3.5-2.4c0.5-0.1,1-0.1,1.5-0.1c1.5,0,2.7,1.2,2.8,2.7l1.1,41.7"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M164,858.7L158,582.4c0-1.8,1.4-3.3,3.2-3.3l1.2-0.1
                c1.8,0,3.3,0,3.3,1.8l6.5,279.3c0,1.8-1.2,1.8-3,1.8l-2-0.1C165.5,861.9,164,860.5,164,858.7z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M245.4,998.6c0,0,7.1,26.1-26.7,26.7h-22V1001"
                />
            <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="225.8" cy="1012.3" rx="16.6" ry="10.1"/>
            <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="225.2" cy="1012.6" rx="8.9" ry="5.6"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="208" y1="1001.6" x2="208" y2="1025.3"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M116.5,349.8l1.2,25c0,0,0.6,8.3,10.7,13.7
                l5.8,3.1c1.3,0.7,2.7,1.1,4.2,1.1h1.9c0,0,1.1,0,1.9-1.2c0.3-0.5,0.5-1.2,0.4-1.8l-1.8-40.3L116.5,349.8z"/>
            <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="130.1" cy="375.9" rx="7.7" ry="5.9"/>
            
                <ellipse transform="matrix(0.6404 -0.7681 0.7681 0.6404 -237.062 291.5782)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="192.8" cy="398.9" rx="8.7" ry="17"/>
            
                <ellipse transform="matrix(0.5614 -0.8275 0.8275 0.5614 -228.7865 319.3896)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="186.9" cy="375.5" rx="4.3" ry="8.1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M167.6,449c14.3-16,14.3-21.4,14.3-21.4v-23.2
                c-8.9-11.3-8.9-21.4-8.9-21.4c0.6-7.1,5.3-7.1,5.3-7.1l-0.6-7.7c0,0-0.6-4.2,3.6-3c0,0,13.1,7.1,18.4,16.6c0,0,8.9,14.9,10.7,33.9
                c0,0,0.6,6.5-5.3,6.5c0,0-7.1,0-23.2-9.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M246,563.1l22,31.5l30.9,51.1c0,0,11.3,19,16,56.4
                c0,0,5.9,60.6,4.2,100.4c0,0-4.2,78.4-9.5,111.1c0,0-0.5,7.2-4.9,12c-0.6,0.7-1.3,1.2-2.1,1.7l-36.4,21.4c0,0-14.4,8.1-19.9-6.4
                c-0.2-0.5-0.3-1-0.4-1.4l-1.1-5.3c-0.3-1.5-0.5-3.1-0.6-4.7l-3.4-70.1l-9.5-215.1l-2.4-75.5v-10.4c0-1.1,0.2-2.2,0.7-3.2
                c0.7-1.4,2.1-2.8,4.6-2.5C234.1,554.2,240.1,554.2,246,563.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M245.4,570.8l20.8,29.7l28.5,48.7c0,0,12.6,18.3,16.6,54.8
                c0,0,5.1,59.2,3.6,98.1c0,0-3.6,76.6-8.2,108.6c0,0-0.4,7-4.2,11.7c-0.5,0.7-1.2,1.2-1.8,1.6L268,943.9c0,0-11.3,7.1-16-3
                c-0.2-0.4-0.4-2.5-0.6-3.3c0-0.2-0.1-0.4-0.1-0.6c-0.1-1.7-0.7-8.1-0.8-9.4l-3.4-67.5l-10.7-208l-1.8-68.3v-7.1
                c0-1.1-0.6-9.1-0.6-10.1c0-1.8,0-5.9,1.2-5.9C235.3,560.7,238.9,562.5,245.4,570.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M268.4,604.4l-33.5-7l5.9,139l73.5,14.5c0,0-1.5-41-5.2-59.8
                c0,0-3.1-20.6-14.4-42.6L268.4,604.4z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M315.1,770.5l-72.5-1.6l6.6,130.8l60.8-20
                C310,879.7,317,787.1,315.1,770.5z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M337.5,750.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M254.3,575c0,0-41.6-7.7-55.3-8.3l-82.6,0.6v-14.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M315.5,762.1l-72.5-7.7l-7.1-1.8c0,0-80.2-5.9-121.8,0"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309.6,888.7l-59.4,19.6l-21.4,5.3c0,0-26.7,9.5-36.8-8.3
                c0,0-18.4-32.7-54.7-39.8"/>
            <g>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="216" cy="723.5" rx="9.2" ry="11.9"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M213.6,739.9l-0.6-29.1
                    c0-1.8,0.3-3.3,2.1-3.3l1.1,0c1.8,0,2.1,1.4,2.1,3.2l1.8,29.1c0,1.8-1.4,3.3-3.2,3.3h0C215.2,743.1,213.7,741.7,213.6,739.9z"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="219.9" y1="735.4" x2="213.3" y2="735.4"/>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="216.9" cy="739.2" rx="1.8" ry="1.5"/>
            </g>
            <g>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="220.8" cy="886.3" rx="9.2" ry="11.9"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M218.4,902.7l-0.6-29.1
                    c0-1.8,0.3-3.3,2.1-3.3l1.1,0c1.8,0,2.1,1.4,2.1,3.2l1.8,29.1c0,1.8-1.4,3.3-3.2,3.3h0C219.9,905.9,218.4,904.5,218.4,902.7z"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="224.6" y1="898.2" x2="218.1" y2="898.2"/>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="221.6" cy="902.1" rx="1.8" ry="1.5"/>
            </g>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M194.3,365.2c0,0,3.6,5.9,3,12.5"/>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M197.3,375.7c0,0-10.1-12.9-20.2-18.2l-9.6-4.4
                c-2.4-1.1-5-1.9-7.6-2.3c-4.6-0.8-12.7-1.9-21.4-2.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="139.6" y1="347.4" x2="164" y2="348"/>
        </g>
        <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M927.8,557c-49.3-0.6-73.1-29.4-73.1-63.6s26.1-63.6,73.1-61.2V557z
                "/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M926.6,1003.8c-39.2,1.2-71.9-25-71.3-63
                c0.5-34.1,25.5-63.6,71.9-64.2L926.6,1003.8z"/>
            <g>
                <ellipse fill="#F7F5F5" stroke="#000000" stroke-miterlimit="10" cx="919.1" cy="493.7" rx="53.8" ry="54.4"/>
                <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="918.5" cy="494" rx="37.1" ry="36.8"/>
            </g>
            <g>
                <ellipse fill="#F7F5F5" stroke="#000000" stroke-miterlimit="10" cx="919.7" cy="942.3" rx="53.8" ry="54.4"/>
                <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="919.1" cy="942.6" rx="37.1" ry="36.8"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M934.3,431.9v-9.2l3-8.9v-25.5h-4.8v-28.5c0,0-0.4-2.7-3-6.2
                c-1.2-1.7-3.5-2.1-5.3-1.1l-2.4,1.3l-23.8-1.2l-3.6-4.2l-20.2,1.2l-3.8,4.6c-1,1.2-2.3,2-3.8,2.2c-4.2,0.6-13.3,2.5-20.7,7.6
                c-0.9,0.6-1.6,1.4-2,2.3l-0.4,0.7c-0.4,0.9-1.1,1.7-1.9,2.2c-3.2,2.3-12.4,10-19.6,28.4c0,0-12.5,31.5-20.2,75.5
                c0,0-4.2,25.5-6.5,49.3L762,574.8l-19.6,35.1l-19.6,39.2c0,0-8.3,18.4-12.5,39.8c0,0-5.3,44.6-5.3,84.4l1.2,63l4.8,66.5l5.3,44
                l1.2,5.3l2.4,25c0,0,0,4.8,4.8,3c0,0,2.4-1.2,4.8,0l19,22c0,0,20.2,19.6,29.7,25.5c0,0,2.4,4.8,7.1,4.8c0,0,3-1.2,5.3,1.2
                c0,0,6.5,4.2,12.5,5.3c0,0,12.5,1.2,26.7,0l22-0.6l4.2,0.6c0,0,1.2,9.5,6.5,12.5c0,0,3,4.8,7.7,4.8c0,0,2.4-0.6,2.4-5.3h15.4h7.4
                l0.3,1.8c0,0,18.4,0,32.1-22l8.9,1.2v-8.3h-3.6l-3-3.6V1008c0,0-1.2-2.4-3-3.6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M927.2,1004.4c-46.3-1.2-71.9-28.7-71.9-63.3s26.1-66.8,79-64.5
                V561.1c0,0,0.6-4.2-5.3-4.2c-48.8,0-74.3-28.1-74.3-62.7s23.2-65.7,80.2-62.1"/>
            <path fill="none" d="M795.6,522.2l-1.8,25c0,0-51.1,68.9-66.5,108.7c0,0-17.8,36.2-16.6,105.8c0,0-0.6,118.8,11.9,188.4l14.3,14.3
                l19.6,16.6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M795.3,523.1l-1.5,24.1c0,0-50.5,71.9-66.8,109.6
                c0,0-1.8,2.4-3.6-8.9C723.4,647.9,757.2,576.6,795.3,523.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M726.3,657.4c0,0-8.3,19.6-13.1,59.4c0,0-2.4,14.3-3,54.7
                c0,0,1.2,126.6,12.5,179.4l-5.9-1.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M722.8,950.9l13.7,13.1l1.8,0.6l25.5,22c0,0,9.5,7.7,18.4,12.5
                c0,0,4.2,3,11.3,4.2c0,0,28.5,3,46.3,1.8c0,0,4.8,0.6,11.9-11.9l11.9-20.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M734.4,962.2h-3.7c-1.2,0-2.4,0.6-3.1,1.5
                c-2.2,2.9-6.8,9.3-7.7,13.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M734.7,962.2c0,0-7.7,5.3-10.1,15.4c0,0-0.6,1.8-1.2,3"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M876.5,406.5c0.9-0.8,1.4-2,1.5-3.2l2.9-43.1
                c0.1-1.9,0.5-2.7-0.5-3.2c-0.4-0.2-0.8-0.3-1.2-0.3h-3c-2.4,0-4,3.1-4.2,5.3l-3.6,44.6c-0.1,1.8,0.4,2.5,1.9,2.8
                c0.7,0.1,1.3,0.1,2-0.1l0,0c0.8-0.3,1.5-0.6,2.2-1.1C875.1,407.7,875.9,407.1,876.5,406.5z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M732.9,963.4c0,0,32.1,30.9,52.3,40.4c0,0,1.2,20.8,5.3,29.7"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M839.8,1029.3c0,0,5.9-0.6,9.5,1.8c0,0,11.9,5.9,12.5,19.6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M889.7,556.4c0,0-3,10.7-3,14.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="902.8" y1="560.8" x2="902.8" y2="570.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M731.7,982.4v-6.5c0,0,0.6-2.4,3-1.2l43.4,37.4
                c0,0,6.5,4.8,7.1,19.6c0,0-3,1.2-7.1-4.2l-16.6-13.7l-11.9-10.7L737,988.9L731.7,982.4z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M920.6,426.3c-45.8,3-71.9,30.8-71.9,68
                s29.7,68,68.9,68"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M928.4,869.5c-54.7,3.6-79.6,33.2-79.6,70.4
                s24.4,62.7,60,68.6"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="923" y1="556.7" x2="923" y2="870.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="926.6" y1="351.4" x2="923.6" y2="431.6"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M825.6,435.2c0,0-11.9,54.7-16,115.3"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M845.8,438.1c0,0-51.7,71.3,16,123.6"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="906.4" y1="571.5" x2="903.4" y2="873.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="902.8" y1="571.5" x2="899.8" y2="874.6"/>
            <polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="886.8,572.4 884.4,862.4 888.5,878.4 
                        "/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="909.3" y1="1002.6" x2="908.2" y2="1049"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="898.6" y1="1051.9" x2="898.6" y2="1021"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M872.5,1051l0.6-37c0-0.1,0-0.2,0-0.3
                c0-0.4,0.1-1.7-0.6-3.1c-0.7-1.3-2-2.2-3.5-2.4c-0.5-0.1-1-0.1-1.5-0.1c-1.5,0-2.7,1.2-2.8,2.7l-1.1,41.7"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M872.5,862.7l5.9-276.3c0-1.8-1.4-3.3-3.2-3.3l-1.2-0.1
                c-1.8,0-3.3,0-3.3,1.8l-6.5,279.3c0,1.8,1.2,1.8,3,1.8l2-0.1C871,865.9,872.5,864.5,872.5,862.7z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M791.1,1002.6c0,0-7.1,26.1,26.7,26.7h22V1005"
                />
            <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="810.7" cy="1016.3" rx="16.6" ry="10.1"/>
            <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="811.3" cy="1016.6" rx="8.9" ry="5.6"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="828.5" y1="1005.6" x2="828.5" y2="1029.3"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M920,353.8l-1.2,25c0,0-0.6,8.3-10.7,13.7
                l-5.8,3.1c-1.3,0.7-2.7,1.1-4.2,1.1h-1.9c0,0-1.1,0-1.9-1.2c-0.3-0.5-0.5-1.2-0.4-1.8l1.8-40.3L920,353.8z"/>
            <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="906.4" cy="379.9" rx="7.7" ry="5.9"/>
            
                <ellipse transform="matrix(0.7681 -0.6404 0.6404 0.7681 -62.3534 633.7328)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="843.7" cy="402.9" rx="17" ry="8.7"/>
            
                <ellipse transform="matrix(0.8275 -0.5614 0.5614 0.8275 -66.5514 542.4128)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="849.6" cy="379.5" rx="8.1" ry="4.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M868.9,453c-14.3-16-14.3-21.4-14.3-21.4v-23.2
                c8.9-11.3,8.9-21.4,8.9-21.4c-0.6-7.1-5.3-7.1-5.3-7.1l0.6-7.7c0,0,0.6-4.2-3.6-3c0,0-13.1,7.1-18.4,16.6c0,0-8.9,14.9-10.7,33.9
                c0,0-0.6,6.5,5.3,6.5c0,0,7.1,0,23.2-9.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M790.5,567.1l-22,31.5l-30.9,51.1c0,0-11.3,19-16,56.4
                c0,0-5.9,60.6-4.2,100.4c0,0,4.2,78.4,9.5,111.1c0,0,0.5,7.2,4.9,12c0.6,0.7,1.3,1.2,2.1,1.7l36.4,21.4c0,0,14.4,8.1,19.9-6.4
                c0.2-0.5,0.3-1,0.4-1.4l1.1-5.3c0.3-1.5,0.5-3.1,0.6-4.7l3.4-70.1l9.5-215.1l2.4-75.5v-10.4c0-1.1-0.2-2.2-0.7-3.2
                c-0.7-1.4-2.1-2.8-4.6-2.5C802.4,558.2,796.4,558.2,790.5,567.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M791.1,574.8l-20.8,29.7l-28.5,48.7c0,0-12.6,18.3-16.6,54.8
                c0,0-5.1,59.2-3.6,98.1c0,0,3.6,76.6,8.2,108.6c0,0,0.4,7,4.2,11.7c0.5,0.7,1.2,1.2,1.8,1.6l32.8,19.8c0,0,11.3,7.1,16-3
                c0.2-0.4,0.4-2.5,0.6-3.3c0-0.2,0.1-0.4,0.1-0.6c0.1-1.7,0.7-8.1,0.8-9.4l3.4-67.5l10.7-208l1.8-68.3v-7.1c0-1.1,0.6-9.1,0.6-10.1
                c0-1.8,0-5.9-1.2-5.9C801.2,564.7,797.6,566.5,791.1,574.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M768.1,608.4l33.5-7l-5.9,139L722.1,755c0,0,1.5-41,5.2-59.8
                c0,0,3.1-20.6,14.4-42.6L768.1,608.4z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M721.4,774.5l72.5-1.6l-6.6,130.8l-60.8-20
                C726.5,883.7,719.5,791.1,721.4,774.5z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M699,754.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M782.2,579c0,0,41.6-7.7,55.3-8.3l82.6,0.6v-14.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M721,766.1l72.5-7.7l7.1-1.8c0,0,80.2-5.9,121.8,0"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M726.9,892.7l59.4,19.6l21.4,5.3c0,0,26.7,9.5,36.8-8.3
                c0,0,18.4-32.7,54.7-39.8"/>
            <g>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="820.5" cy="727.5" rx="9.2" ry="11.9"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M822.9,743.9l0.6-29.1
                    c0-1.8-0.3-3.3-2.1-3.3l-1.1,0c-1.8,0-2.1,1.4-2.1,3.2l-1.8,29.1c0,1.8,1.4,3.3,3.2,3.3l0,0C821.4,747.1,822.9,745.7,822.9,743.9
                    z"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="816.6" y1="739.4" x2="823.2" y2="739.4"/>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="819.6" cy="743.2" rx="1.8" ry="1.5"/>
            </g>
            <g>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="815.8" cy="890.3" rx="9.2" ry="11.9"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M818.1,906.7l0.6-29.1
                    c0-1.8-0.3-3.3-2.1-3.3l-1.1,0c-1.8,0-2.1,1.4-2.1,3.2l-1.8,29.1c0,1.8,1.4,3.3,3.2,3.3l0,0C816.6,909.9,818.1,908.5,818.1,906.7
                    z"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="811.9" y1="902.2" x2="818.4" y2="902.2"/>
                <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="814.9" cy="906.1" rx="1.8" ry="1.5"/>
            </g>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M842.2,369.2c0,0-3.6,5.9-3,12.5"/>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M839.2,379.7c0,0,10.1-12.9,20.2-18.2l9.6-4.4
                c2.4-1.1,5-1.9,7.6-2.3c4.6-0.8,12.7-1.9,21.4-2.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="896.9" y1="351.4" x2="872.5" y2="352"/>
        </g>
    </g>
    <g id="Layer_2">
        <g>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M375.8,280.4l-0.3,31.1c0,3.6-2.9,6.5-6.5,6.5
                h-30.5c-3.6,0-6.5-2.9-6.5-6.5v-91.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M631.1,280.4l0.3,31.1c0,3.6,2.9,6.5,6.5,6.5
                h30.5c3.6,0,6.5-2.9,6.5-6.5v-91.7"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.6,10c0,0,71.6,1.7,99.9,6c6.1,0.9,11.6,4.1,15.6,8.8
                c4.5,5.4,10.8,14.7,18.2,30.6l22.7,55.8l4.1,7.6l6.2,13.1c0,0,4.8,13.8,5.5,33.1c0,0,0.7,42.7-1.4,59.9l-6.9,35.8
                c0,0-5.5,20-15.8,20.7h-18.9c-0.2,0-0.4-0.1-0.5-0.2c-0.5-0.4-0.5-1.2-8.1-1.2H504"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.8,10c0,0-71.6,1.7-99.9,6c-6.1,0.9-11.6,4.1-15.6,8.8
                c-4.5,5.4-10.8,14.7-18.2,30.6l-22.7,55.8l-4.1,7.6L337,132c0,0-4.8,13.8-5.5,33.1c0,0-0.7,42.7,1.4,59.9l6.9,35.8
                c0,0,5.5,20,15.8,20.7h18.9c0.2,0,0.4-0.1,0.5-0.2c0.5-0.4,0.5-1.2,8.1-1.2H504"/>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M503.6,28.6c0,0,89.6,1.4,111.6,4.8
                    c0,0,2.1-0.7,4.1,3.4c0,0,24.1,46.2,28.9,74.4c0,0,0.7,4.1-2.1,4.1l-142.6,0.7"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M504,28.6c0,0-89.6,1.4-111.6,4.8
                    c0,0-2.1-0.7-4.1,3.4c0,0-24.1,46.2-28.9,74.4c0,0-0.7,4.1,2.1,4.1l142.6,0.7"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M503.5,32.7c0,0,86,1.3,107.2,4.4
                    c0,0,2-0.6,4,3.2c0,0,21.3,42,27.8,68.3c0,0,0.7,3.8-2,3.8L503.5,113"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M503.8,32.7c0,0-86,1.3-107.2,4.4
                    c0,0-2-0.6-4,3.2c0,0-20.5,39.1-26.8,67.3c0,0.1-0.1,0.2-0.1,0.3c-0.6,1.9-1,5,1.4,4.7l136.6,0.3"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M414.1,167.8l8.3,17.9c0,0,1.4,3.1,1,6.1
                    c-0.4,2.5-2.8,4.3-5.3,4.1l-41.2-3.3c0,0-17.9,2.1-24.8-11h-4.8c0,0-1.4-14.5,4.8-24.8c0,0,4.1-9,9.6-9c0,0,12.4-1.4,35.1,9.6
                    l12.2,5.5c1.5,0.7,2.9,1.7,4,3C413.4,166.5,413.9,167.1,414.1,167.8z"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="400.3" cy="173.3" r="9.6"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="370.7" cy="170.5" r="16.5"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M408.6,163l8.9,20.5c0.1,0.1,0.1,0.3,0.1,0.5
                    c0.1,0.5,0.1,1.4-0.2,2.2c-0.3,0.7-1,1-1.8,1l-26-1.3c-0.6,0-1.1,0.2-1.5,0.6c-1.2,1.3-4.3,4.1-10.5,6.2"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M593.5,167.8l-8.3,17.9c0,0-1.4,3.1-1,6.1
                    c0.4,2.5,2.8,4.3,5.3,4.1l41.2-3.3c0,0,17.9,2.1,24.8-11h4.8c0,0,1.4-14.5-4.8-24.8c0,0-4.1-9-9.6-9c0,0-12.4-1.4-35.1,9.6
                    l-12.2,5.5c-1.5,0.7-2.9,1.7-4,3C594.1,166.5,593.7,167.1,593.5,167.8z"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="607.2" cy="173.3" r="9.6"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="636.9" cy="170.5" r="16.5"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M599.4,162.3l-9.8,21.2
                    c-0.1,0.1-0.1,0.3-0.1,0.5c-0.1,0.5-0.2,1.4,0.2,2.2c0.3,0.7,1.1,1,1.8,1l27.7-1.3c0.6,0,1.1,0.2,1.5,0.6
                    c1.2,1.3,3.8,4.1,9.6,6.2"/>
            </g>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M347.2,181.6h-2.8c0,0-2.8,6.9-13.1,14.5"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M356.2,150.6c0,0-14.5-16.5-14.5-28.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M374.8,120.2c0,0,20,11.7,34.4,40.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M660.4,181.6h4.5c0,0,3.4,8.3,11,15.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M651.8,150.6c0,0,14.5-16.5,14.5-28.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M633.2,120.2c0,0-20,11.7-34.4,40.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M395.5,19c0,0-19.1,25.9-39,89.9
                c-0.2,0.6-0.7,1-1.3,1c-1.3,0-3.4,0.2-5,1.7c-0.6,0.5-0.9,1.2-1.1,2c-0.8,3.2-3.7,13.9-6.3,14.4"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M612.2,20.3c0,0,19.1,25.9,39,89.9
                c0.2,0.6,0.7,1,1.3,1c1.3,0,3.4,0.2,5,1.7c0.6,0.5,0.9,1.2,1.1,2c0.8,3.2,3.7,13.9,6.3,14.4"/>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M420.3,216.7l-65.5-1.4c0,0-1.1,0-2.4-0.4
                    c-1.8-0.6-3.1-2.3-3.1-4.2v-4.3c0,0,0-4.1,2.1-4.1l62.7,5.5C414.1,207.8,421,209.1,420.3,216.7z"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M351.4,205.7l59.8,4c2.1,0.1,4.2,0.7,6.1,1.7
                    c1,0.5,2.6,1.9,3,2.6"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M585.6,216.7l65.5-1.4c0,0,1.1,0,2.4-0.4
                    c1.8-0.6,3.1-2.3,3.1-4.2v-4.3c0,0,0-4.1-2.1-4.1l-62.7,5.5C591.8,207.8,584.9,209.1,585.6,216.7z"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M654.5,205.7l-59.8,4
                    c-2.1,0.1-4.2,0.7-6.1,1.7c-1,0.5-2.6,1.9-3,2.6"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M503.6,169.9h76.5l-8,21
                    c-0.9,2.2-2.3,4.2-4.2,5.7c-2.1,1.6-5.3,3.3-9.8,4.1c-0.8,0.1-1.5,0.2-2.3,0.2l-52.1,0.7"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M504.3,169.9h-76.5l8,21
                    c0.9,2.2,2.3,4.2,4.2,5.7c2.1,1.6,5.3,3.3,9.8,4.1c0.8,0.1,1.5,0.2,2.3,0.2l52.1,0.7"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M503.5,171.2h74.2l-7.8,18.9
                    c-0.8,2-2.2,3.8-4.1,5.1c-2.1,1.4-5.2,3-9.5,3.7c-0.7,0.1-1.5,0.2-2.2,0.2l-50.6,0.6"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M504.1,171.2h-74.2l7.8,18.9
                    c0.8,2,2.2,3.8,4.1,5.1c2.1,1.4,5.2,3,9.5,3.7c0.7,0.1,1.5,0.2,2.2,0.2l50.6,0.6"/>
            </g>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M550.9,231.2h-93.8c-1.3,0-2.3-1.1-2.3-2.3
                v-20.1c0-1.3,1.1-2.3,2.3-2.3h93.8c1.3,0,2.3,1.1,2.3,2.3v20.1C553.3,230.1,552.2,231.2,550.9,231.2z"/>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M504,165l83,0.7l-33.8,86.1c0,0-0.2,0.4-0.5,1
                    c-3.4,6.9-10.4,11.3-18.1,11.5l-30.4,1.2"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M504.7,165l-86.1,0.7l35,86.1
                    c0,0,0.2,0.4,0.5,1c3.6,6.9,10.8,11.3,18.7,11.5l31.5,1.2"/>
            </g>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="446.5" y1="234.6" x2="560.1" y2="234.6"/>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M503.6,236.7h53.1c0,0-4.8,24.8-22,25.5
                    l-31,0.7"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M504,236.7h-54.1c0,0,6.2,24.8,22.4,25.5
                    l31.3,0.7"/>
            </g>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="451.3" y1="241.5" x2="555.3" y2="241.5"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="452.3" y1="243.6" x2="554.6" y2="243.6"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="454.4" y1="248.4" x2="552.2" y2="248.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="455.8" y1="250.5" x2="551.5" y2="250.5"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="459.5" y1="255.3" x2="547.7" y2="255.3"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="461.3" y1="257.4" x2="546.4" y2="257.4"/>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M434.1,234.6l8.3,20.7c0,0,1.4,3.2-0.1,6
                    c-1,1.9-3.1,3-5.2,2.9l-64.6-2c-0.3,0-0.6,0-0.9-0.1c-1.4-0.3-5.5-1.3-8.9-3.5c-1.8-1.2-3.1-2.9-3.9-4.8l-3.8-9.4
                    c-0.6-1.4-0.8-2.9-0.8-4.3v-2.6c0,0,0-2.8,2.8-2.8H434.1z"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M428.5,236.7l7.4,17.6c0,0,1.3,2.7,0,5.1
                    c-0.9,1.6-2.8,2.5-4.7,2.5l-57.9-1.7c-0.3,0-0.5,0-0.8-0.1c-1.2-0.2-4.9-1.1-8-3c-1.6-1-2.8-2.4-3.5-4.1l-3.4-8
                    c-0.5-1.2-0.8-2.4-0.8-3.7V239c0,0,0-2.3,2.5-2.3H428.5z"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="356.5" y1="242.2" x2="431" y2="242.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="357.2" y1="243.6" x2="431.6" y2="243.6"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="362.1" y1="254.6" x2="435.8" y2="254.6"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="359" y1="248.4" x2="433.4" y2="248.4"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="359.6" y1="249.8" x2="434.1" y2="249.8"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="362.7" y1="256" x2="436.5" y2="256"/>
                <circle fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="376.5" cy="248.7" r="8.6"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M571.8,234.6l-8.3,20.7c0,0-1.4,3.2,0.1,6
                    c1,1.9,3.1,3,5.2,2.9l64.6-2c0.3,0,0.6,0,0.9-0.1c1.4-0.3,5.5-1.3,8.9-3.5c1.8-1.2,3.1-2.9,3.9-4.8l3.8-9.4
                    c0.6-1.4,0.8-2.9,0.8-4.3v-2.6c0,0,0-2.8-2.8-2.8H571.8z"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M577.3,236.7l-7.4,17.6c0,0-1.3,2.7,0,5.1
                    c0.9,1.6,2.8,2.5,4.7,2.5l57.9-1.7c0.3,0,0.5,0,0.8-0.1c1.2-0.2,4.9-1.1,8-3c1.6-1,2.8-2.4,3.5-4.1l3.4-8
                    c0.5-1.2,0.8-2.4,0.8-3.7V239c0,0,0-2.3-2.5-2.3H577.3z"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="649.3" y1="242.2" x2="574.9" y2="242.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="648.6" y1="243.6" x2="574.2" y2="243.6"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="643.8" y1="254.6" x2="570.1" y2="254.6"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="646.9" y1="248.4" x2="572.5" y2="248.4"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="646.2" y1="249.8" x2="571.8" y2="249.8"/>
                <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="643.1" y1="256" x2="569.4" y2="256"/>
                <circle fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="629.3" cy="248.7" r="8.6"/>
            </g>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M341.4,264.9c0,0,7.9,6.1,17.4,8
                c1.1,0.2,2.3,0.3,3.4,0.3H504"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M666.2,264.9c0,0-7.5,6.1-17,8
                c-1.1,0.2-2.3,0.3-3.4,0.3H504"/>
        </g>
    </g>
    <g id="Layer_3">
        <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M392.1,1355.6l-0.4,43.5c0,3.8-3.1,6.9-6.9,6.9H352
                c-3.8,0-6.9-3.1-6.9-6.9v-98.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M663.4,1355.6l0.4,43.5c0,3.8,3.1,6.9,6.9,6.9h32.7
                c3.8,0,6.9-3.1,6.9-6.9v-98.5"/>
            <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M526.5,1075c0,0,78.4,1.5,113.2,6.7c0,0,0.1,0,0.1,0
                    c0.6,0.1,7.5,1.6,10.3,5.2l13.3,18.4c0,0.1,0.1,0.1,0.1,0.2l12.5,22.8c0,0,0.1,0.1,0.1,0.2l21.4,51.7l2.1,5.7
                    c0.1,0.2,0.1,0.3,0.3,0.4c0.8,1,4.2,5.1,5,7.1c0,0.1,0.1,0.1,0.1,0.2c0.8,1.5,11,20.6,11,54.7v3.6c0,0.9-0.7,1.5-1.5,1.5l0,0
                    c-0.8,0-1.5,0.6-1.5,1.4l-4.3,49.7c0,0-5.2,45-25.1,48.1c-0.1,0-0.1,0-0.1,0c-0.9,0.3-10.5,3-16.9,3.7c-0.1,0-0.1,0-0.2,0H522.8"
                    />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="485.3" y1="1356.3" x2="532.4" y2="1356.3"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,1356.3"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M422.3,1356.3"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M528.7,1075c0,0-78.4,1.5-113.2,6.7c0,0-0.1,0-0.1,0
                    c-0.6,0.1-7.5,1.6-10.3,5.2l-13.3,18.4c0,0.1-0.1,0.1-0.1,0.2l-12.5,22.8c0,0-0.1,0.1-0.1,0.2l-21.4,51.7l-2.1,5.7
                    c-0.1,0.2-0.1,0.3-0.3,0.4c-0.8,1-4.2,5.1-5,7.1c0,0.1-0.1,0.1-0.1,0.2c-0.8,1.5-11,20.6-11,54.7v3.6c0,0.9,0.7,1.5,1.5,1.5h0
                    c0.8,0,1.5,0.6,1.5,1.4l4.3,49.7c0,0,5.2,45,25.1,48.1c0.1,0,0.1,0,0.1,0c0.9,0.3,10.5,3,16.9,3.7c0.1,0,0.1,0,0.2,0h33.4"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M422.1,1355.9c0-10.4,14.3-18.9,31.8-18.9s31.8,8.5,31.8,18.9"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="444.7" cy="1355.9" r="6.7"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="461" cy="1355.9" r="6.7"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="422.1" y1="1356.3" x2="438.4" y2="1356.3"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="468" y1="1356.3" x2="485.8" y2="1356.3"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="451" y1="1356.3" x2="454" y2="1356.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M415.4,1081.7l0,12.6c0,1.2,1,2.1,2.1,1.9
                c17-1.7,131.5-12.3,220.8,0.3c0.9,0.1,1.7-0.5,1.8-1.5l0.9-13.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M403.5,1089.3c0,0,2,7.6-1.7,15.7l-34.1,65.9
                c0,0-5.9,11.1-11.1,14.1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M653.3,1090.7c0,0-2,7.6,1.7,15.7l34.1,65.9c0,0,5.9,11.1,11.1,14.1
                "/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M526.5,1103.5l108.9,2.2c0,0,0.1,0,0.1,0c0.7,0,9.8-0.5,15.6,4.3
                c0.1,0.1,0.3,0.3,0.3,0.4l29.3,62.4c0.4,0.8-0.2,1.7-1.1,1.8c-14.4,0.6-106.6,4.4-153.9,5.1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M527.3,1103.5l-107.8,2.2c0,0-0.1,0-0.1,0c-0.7,0-9.7-0.5-15.5,4.3
                c-0.1,0.1-0.3,0.3-0.3,0.4l-29,62.4c-0.4,0.8,0.2,1.7,1.1,1.8c14.2,0.6,105.5,4.4,152.4,5.1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M529.1,1107.9l98.5,1.5c0,0,14.1,0.7,18.5,8.9l17.8,39.2
                c0,0,5.2,11.1-6.7,13.3l-20,1.5l-68.8,1.5h-13.1c-1.1,0-2.3-0.3-3.3-0.8l-7.3-3.7c0,0-3.7-1.5-15.5-1.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M531,1107.9l-102.5,1.5c0,0-14.6,0.7-19.3,8.9l-18.5,39.2
                c0,0-5.4,11.1,6.9,13.3l20.8,1.5l71.6,1.5h17.4c1,0,2-0.3,2.9-0.8l6.1-3.6c0,0,3-1.5,14.4-1.5"/>
            <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M404,1109.4c0,0,5.2-9.6,13.3-11.1c0,0,58.5-4.4,109.6-4.4"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M649.8,1109.4c0,0-5.2-9.6-13.3-11.1c0,0-58.5-4.4-109.6-4.4"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M481,1094.6c0,0,0.7,3.7,3.7,3.7h45.2"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M576.9,1094.6c0,0-0.7,3.7-3.7,3.7H528"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M344,1268.6c0,0,9.6-8.1,11.1-12.6
                    c0,0,1.5-3.7,2.2-12.6c0,0,3-35.5,6.7-46.6l3.7-10.4c0,0,1.5-3.7,4.4-3.7c0,0,18.5,2.2,25.9,8.1c0,0,8.9,4.4,11.8,32.6"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="382.2" cy="1206.8" r="18.1"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="382.2" cy="1207.1" r="8.9"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M711.2,1268.6c0,0-9.6-8.1-11.1-12.6
                    c0,0-1.5-3.7-2.2-12.6c0,0-3-35.5-6.7-46.6l-3.7-10.4c0,0-1.5-3.7-4.4-3.7c0,0-18.5,2.2-25.9,8.1c0,0-8.9,4.4-11.8,32.6"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="673.1" cy="1206.8" r="18.1"/>
                <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="673.1" cy="1207.1" r="8.9"/>
            </g>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="389.9" y1="1223.4" x2="553.5" y2="1223.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="553.2" y1="1223.4" x2="665.7" y2="1223.4"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M370,1183.4c0,0,1.5-6.7,4.4-9.6"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M685.3,1183.4c0,0-1.5-6.7-4.4-9.6"/>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M410.7,1226.4l4.4,31.1
                    c0,0,2.2,12.6,15.5,12.6h102.9"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M646.1,1226.4l-4.8,31.1
                    c0,0-2.2,12.6-15.5,12.6H522.8"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M415.1,1257.5c0,0,4.4,6.7,14.8,6.7h100.7"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M641.3,1257.5c0,0-4.4,6.7-14.8,6.7H525.8"/>
            </g>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="359.2" y1="1226.4" x2="411" y2="1226.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="357.4" y1="1243.4" x2="413.3" y2="1243.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="645.7" y1="1226.4" x2="696" y2="1226.4"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="643.5" y1="1243.4" x2="698.3" y2="1243.4"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M538.7,1249.3h-22.9c-2.5,0-5.9-3-7.6-6.3
                c-0.4-0.7-0.5-1.5-0.5-2.2v-3.6c0-2.7,2.2-5,5-5h30c2.7,0,5,2.2,5,5v3.9C547.6,1245.7,543.2,1249.3,538.7,1249.3z"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="507.6" y1="1240.4" x2="547.6" y2="1240.4"/>
            <circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="528" cy="1236.4" r="2.6"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M697.3,1285.6H359.5c-2.4,0-4.3-1.9-4.3-4.3
                v-3.9c0-2.4,1.9-4.3,4.3-4.3h337.8c2.4,0,4.3,1.9,4.3,4.3v3.9C701.6,1283.7,699.6,1285.6,697.3,1285.6z"/>
            <g>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M457,1285.5c0,0-0.2,12.6,2.8,20.8
                    c1.5,4,5.4,6.7,9.7,6.7h61.1"/>
                <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M598.7,1285.5c0,0,0.2,12.6-2.8,20.8
                    c-1.5,4-5.4,6.7-9.7,6.7H525"/>
            </g>
            
                <rect x="466.9" y="1288.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="121.4" height="21.5"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M352.9,1329.3c0,0,179.9-5.2,348.7,1.5"/>
            <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M357,1181.6h-5.8c-1,0-2-0.4-2.5-1.2c-0.3-0.4-0.6-1-0.6-1.8v-11.1
                    c0,0-2.2-4.4-8.1-4.4h-16.6c-2.4,0-4.8,0.4-6.9,1.4c-2.3,1-4.7,2.6-6.1,5.3c0,0-4.4,8.9-4.4,14.8c0,0,0,5.2,3.7,5.2h42.9"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.7,1166.8h22.9c0,0,3,0,1.5,4.4l-4.5,12.9
                    c-0.5,1.5-1.7,2.6-3.3,2.9c-1.1,0.2-2.7,0.4-4.8,0.4h-19.1c-2.4,0-4.5-1.9-4.5-4.4c0,0,0-0.1,0-0.1
                    C308.9,1183.1,313,1167.2,320.7,1166.8z"/>
            </g>
            <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M699,1181.6h5.8c1,0,2-0.4,2.5-1.2c0.3-0.4,0.6-1,0.6-1.8v-11.1
                    c0,0,2.2-4.4,8.1-4.4h16.6c2.4,0,4.8,0.4,6.9,1.4c2.3,1,4.7,2.6,6.1,5.3c0,0,4.4,8.9,4.4,14.8c0,0,0,5.2-3.7,5.2h-42.9"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M735.3,1166.8h-22.9c0,0-3,0-1.5,4.4l4.5,12.9
                    c0.5,1.5,1.7,2.6,3.3,2.9c1.1,0.2,2.7,0.4,4.8,0.4h19.1c2.4,0,4.5-1.9,4.5-4.4c0,0,0-0.1,0-0.1
                    C747.1,1183.1,743,1167.2,735.3,1166.8z"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M338.9,1252.3c0,0,0.7,42.9,5.9,82.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M716.4,1252.3c0,0-0.7,42.9-5.9,82.9"/>
        </g>
    </g>
    <g id="Layer_4">
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M517.3,347.8l49.3,2.1l1.8,1.2c0,0,44.9,5.3,75.6,33.7
                c0,0,10.6,11.2,11.2,24.2c0,0,13,41.3,13.6,90.4c0,0,0.6,37.8-1.2,54.3c0,0-1.2,1.8-1.8,9.4l0.6,80.9l1.2,77.9l-2.4,105.7
                l-1.8,42.5c0,0,7.1,52.6,0,101c0,0,1.8,15.9-8.3,32.5l-4.7,12.4c0,0-4.1,12.4-28.3,17.1c0,0-31,10.3-103,13.9"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M517.3,347.8l-47.8,2.1l-1.8,1.2c0,0-44.9,5.3-75.6,33.7
                c0,0-10.6,11.2-11.2,24.2c0,0-13,41.3-13.6,90.4c0,0-0.6,37.8,1.2,54.3c0,0,1.2,1.8,1.8,9.4l-0.6,80.9l-1.2,77.9l2.4,105.7
                l1.8,42.5c0,0-7.1,52.6,0,101c0,0-1.8,15.9,8.3,32.5l4.7,12.4c0,0,4.1,12.4,28.3,17.1c0,0,33.1,10.3,105.1,13.9"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M517.3,508.4c0,0,73.2-4.7,121.1,26.6c0,0,11.2,7.7,9.4,15.4
                l-25.4,116.3c0,0-57.3-14.2-103.9-12.4"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M519.9,508.4c0,0-73.2-4.7-121.1,26.6c0,0-11.2,7.7-9.4,15.4
                l25.4,116.3c0,0,57.3-14.2,103.9-12.4"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M516.4,520.8c0,0,69.5-4.2,114.9,23.5c0,0,10.6,6.8,9,13.6
                l-24.1,102.7c0,0-54.4-12.5-98.6-10.9"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M518.8,520.8c0,0-69.5-4.2-114.9,23.5c0,0-10.6,6.8-9,13.6
                l24.1,102.7c0,0,54.4-12.5,98.6-10.9"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M388.6,549.8l-0.6-13c0,0,4.7-106.3,7.1-116.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M388.2,538.6c0,0,34.1-36.1,130.9-34.9"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M401,501.9c0,0,18.3-82.7,33.1-115.7"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M647.8,549.8l0.6-13c0,0-4.7-106.3-7.1-116.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M648.3,538.6c0,0-34.1-36.1-130.9-34.9"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M635.4,501.9c0,0-18.3-82.7-33.1-115.7"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M440.4,368.6l-4.8,8.6c-0.2,0.4-0.6,0.8-1,1.2l-7.1,6
            c0,0-28.9,25.4-34.8,34.8c0,0-3,4-5.4,5.6c-0.4,0.2-0.8,0.4-1.3,0.4c-1.8,0-6.2-0.6-5.8-7.5c0-0.1,0-0.3,0-0.4
            c0.5-2.9,6.1-32.5,33-37.6c0,0,3-0.6,4.1-1.8l22.2-10C440.2,367.7,440.7,368.2,440.4,368.6z"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M595.9,368.6l4.8,8.6c0.2,0.4,0.6,0.8,1,1.2l7.1,6
            c0,0,28.9,25.4,34.8,34.8c0,0,3,4,5.4,5.6c0.4,0.2,0.8,0.4,1.3,0.4c1.8,0,6.2-0.6,5.8-7.5c0-0.1,0-0.3,0-0.4
            c-0.5-2.9-6.1-32.5-33-37.6c0,0-3-0.6-4.1-1.8l-22.2-10C596.1,367.7,595.7,368.2,595.9,368.6z"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M440.6,367.9c0,0,6.5-5.9,3-7.7"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M596.3,367.9c0,0-6.5-5.9-3-7.7"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M518.5,370.9c0,0,57.6,1.7,74.7,5.1
            c0.4,0.1,0.7-0.3,0.6-0.7c-1.2-3.3-6.2-15.5-16.8-21.6l-5.2-1.4c-0.9-0.2-1.8-0.4-2.7-0.4c-7.1-0.4-39.5-2.3-50-2.3"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M518.5,370.9c0,0-58.5,1.7-75.6,5.1
            c-0.4,0.1-0.7-0.3-0.6-0.7c1.2-3.3,6.2-15.5,16.8-21.6l5.2-1.4c0.9-0.2,1.8-0.4,2.7-0.4c7.1-0.4,41.5-2.3,52-2.3"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M516.7,368.5c0,0,15.9-0.6,67.9,4.1c0,0,2.4,0.6,3-0.6
                c0,0-0.4-7.8-11.8-14.2c-3.5-1.9-7.3-3-11.3-3.3c-9.8-0.6-33.3-2-47.8-2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M518.5,368.5c0,0-15.9-0.6-67.9,4.1c0,0-2.4,0.6-3-0.6
                c0,0,0.4-7.8,11.8-14.2c3.5-1.9,7.3-3,11.3-3.3c9.8-0.6,33.3-2,47.8-2"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M450.6,372.6c0,0,3.5-11.8,16.5-14.2c0,0,22.4-3.5,51.4-3.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M586.4,372.6c0,0-3.5-11.8-16.5-14.2c0,0-22.4-3.5-51.4-3.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M453,372.6c0,0,1.2-3.5,64.4-6.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M453.6,372c0,0,1.2-4.6,5-5.8c0.2-0.1,0.4-0.1,0.6-0.1
            c2.9-0.4,42.8-2.9,59.4-2.9"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M458.3,366.1c0,0,0-1.2,1-2.3c0.7-0.8,1.8-1.3,2.9-1.4
            c4.5-0.5,40.9-2.3,57.5-2.3"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M584,372.6c0,0-10.6-4.7-66.7-6.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M582.9,372c0,0-0.6-4-4.5-5.2c-0.2-0.1-0.4-0.1-0.6-0.1
            c-2.9-0.4-43.4-3.5-59.9-3.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M578.7,366.7c0,0,0-1.2-1-2.3c-0.7-0.8-1.8-1.3-2.9-1.4
            c-4.5-0.5-38.5-2.8-55.2-2.8"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M414.6,667.3c0,0,13,179.5,8.9,277"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M622.7,664.9c0,0-13,179.5-8.9,277"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M372.1,973.8c0,0,11.2,9.4,8.9,30.7"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M664.1,973.8c0,0-11.2,9.4-8.9,30.7"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M381.4,997.6c0,0,3.6,2.8,10.7-2l21.8-40.7c0,0,4.7-8.9,9.4-10
            l98.6-1.2"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M654.7,997.6c0,0-3.6,2.8-10.7-2l-21.8-40.7c0,0-4.7-8.9-9.4-10
            l-98.6-1.2"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M414,954.9c0,0-1.8,18.9,106.3,19.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M621.9,954.9c0,0,1.8,18.9-106.3,19.5"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M516.7,1019.8h14.8c0,0,4.1,0.6,7.7,3c0,0,1.2,1.2,8.3,0.6
                c0,0,43.7-5.3,60.8-9.4l17.7-5.3c0,0,6.2-2.5,8.6-8.5c0.8-2,0.7-4.3-0.1-6.3l-10.8-25.4c0,0-3.5-5.9-10.6-3c0,0-25.4,10-93.3,10.6
                "/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M520,1019.8h-14.8c0,0-4.1,0.6-7.7,3c0,0-1.2,1.2-8.3,0.6
                c0,0-43.7-5.3-60.8-9.4l-17.7-5.3c0,0-5.8-2.3-8.3-7.9c-1-2.2-1-4.8,0-7l10.7-25.2c0,0,3.5-5.9,10.6-3c0,0,28,10,96,10.6"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M392.1,995.6c0,0,3.5,10,12.4,14.2c0,0,28.3,15.9,113.4,17.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M643.7,995.6c0,0-3.5,10-12.4,14.2c0,0-28.3,15.9-113.4,17.7"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M389.8,996.8c0,0,0.6,8.9,7.1,13c0,0,0,0,0,0l24.4,11.6
            c0.1,0,0.1,0.2,0,0.1c-2.6-0.1-17.3-1.3-31.7-8.6c-0.3-0.2-0.6-0.4-0.8-0.6c-1.3-1.5-5.8-7.3-7.3-14.4"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M646.4,997.4c0,0-0.6,8.9-7.1,13c0,0,0,0,0,0l-24.4,11.6
            c-0.1,0-0.1,0.2,0,0.1c2.6-0.1,17.3-1.3,31.7-8.6c0.3-0.2,0.6-0.4,0.8-0.6c1.3-1.5,5.8-7.3,7.3-14.4"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M421.3,1021.4c0,0,82.5,22.9,193.5,0.6"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M427.8,1023.1c0,0,2.2,8.5,10.4,11.5c0,0,29.5,7.1,83.3,7.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M607.7,1023.1c0,0-2.2,8.5-10.4,11.5c0,0-29.5,7.1-83.3,7.7"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M389.8,1018.1c0,0,9.4,7.1,36.6,13"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M646.1,1017.5c0,0-14.2,8.3-36,13"/>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M378.2,557.2l24.6,100.1c0,0,7.1,45.5,8.3,67.3l1.8,27.8l1.2,105.1
                l-0.6,46.7c0,0,1.2,10.6-3,17.1l-15.9,23.6c0,0-1.8,3.5-4.7,3.5c0,0-7.1,0.6-7.7-27.2l-4.7-98l-1.8-72L375,648.4v-67.9
                c0,0,0-19.2,2.3-23.4C377.5,556.6,378.1,556.7,378.2,557.2z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M385.1,434.6c0,0-8.9,66.1-9.4,127"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M377.5,558.1l1.1,180.7l2.4,120.5l3.5,47.2l2.4,28.3
                c0,0,1.2,11.2,8.3,9.4"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M380.3,605.3l8.9,5.3l1.2,1.2l11.8,52l6.5,53.1l1.8,34.3
                c0,0-10-0.6-29.5-13.6L380.3,605.3z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M381.5,769.5c0,0,15.9,4.7,28.9,3l1.2,108.1l-14.2,11.2l-11.2-1.8
                L381.5,769.5z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M388,908.8l23.6-20.1V910c0,0-0.6,10.6-5.9,16.5l-9.4,13
                c0,0-3.5,1.8-5.3-2.4L388,908.8z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M412.2,884.6l-24.8,21.8c0,0-4.1-1.2-5.3,1.8l-5.9,4.1
                c0,0-1.8,1.2-3-1.2l-3-17.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M412.8,764.1c0,0-20.2,0.9-33.7-7.8l-3.2-0.3c0,0-5.6-1.9-6.8-3.7"
                />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M388.6,550.4c0,0-8.9,3.5-7.7,15.9"/>
        </g>
        <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M657.7,557.2l-24.6,100.1c0,0-7.1,45.5-8.3,67.3l-1.8,27.8
                l-1.2,105.1l0.6,46.7c0,0-1.2,10.6,3,17.1l15.9,23.6c0,0,1.8,3.5,4.7,3.5c0,0,7.1,0.6,7.7-27.2l4.7-98l1.8-72l0.6-102.8v-67.9
                c0,0,0-19.2-2.3-23.4C658.4,556.6,657.8,556.7,657.7,557.2z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M650.8,434.6c0,0,8.9,66.1,9.4,127"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M658.4,558.1l-1.1,180.7l-2.4,120.5l-3.5,47.2l-2.4,28.3
                c0,0-1.2,11.2-8.3,9.4"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M655.5,605.3l-8.9,5.3l-1.2,1.2l-11.8,52l-6.5,53.1l-1.8,34.3
                c0,0,10-0.6,29.5-13.6L655.5,605.3z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M654.3,769.5c0,0-15.9,4.7-28.9,3l-1.2,108.1l14.2,11.2l11.2-1.8
                L654.3,769.5z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M647.8,908.8l-23.6-20.1V910c0,0,0.6,10.6,5.9,16.5l9.4,13
                c0,0,3.5,1.8,5.3-2.4L647.8,908.8z"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M623.6,884.6l24.8,21.8c0,0,4.1-1.2,5.3,1.8l5.9,4.1
                c0,0,1.8,1.2,3-1.2l3-17.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M623,764.1c0,0,20.2,0.9,33.7-7.8l3.2-0.3c0,0,5.6-1.9,6.8-3.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M647.2,550.4c0,0,8.9,3.5,7.7,15.9"/>
        </g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M369.7,591.7l-20,15.7c-1.5,1.2-2.9,2.7-3.8,4.4
            c-1.8,3.1-4,8.4-2.1,13.6c0,0,0,1.2,1.7,1.3c0.7,0,1.3-0.2,1.9-0.5l22.4-11.5"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M666.6,591.7l20,15.7c1.5,1.2,2.9,2.7,3.8,4.4
            c1.8,3.1,4,8.4,2.1,13.6c0,0,0,1.2-1.7,1.3c-0.7,0-1.3-0.2-1.9-0.5l-22.4-11.5"/>
    </g></>)
    },

}