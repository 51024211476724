import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Theme, makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import React from "react";

export enum SnackBarVariant {
  ERROR = "error",
  INFORMATION = "information",
  SUCCESS = "success",
  WARNING = "warning"
}

const variantIcon = {
  [SnackBarVariant.ERROR]: ErrorIcon,
  [SnackBarVariant.INFORMATION]: InfoIcon,
  [SnackBarVariant.SUCCESS]: CheckCircleIcon,
  [SnackBarVariant.WARNING]: WarningIcon
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: theme.palette.success.main
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  information: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: theme.palette.warning.main
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    marginRight: theme.spacing(1),
    opacity: 0.9
  },
  message: {
    alignItems: "center",
    display: "flex"
  }
}));

export interface Props {
  className?: string;
  message?: string;
  variant: SnackBarVariant;
  onClose?: () => void;
}

const getClassName = (
  variant: SnackBarVariant
): "error" | "success" | "warning" | "information" => {
  switch (variant) {
    case SnackBarVariant.ERROR:
      return "error";
    case SnackBarVariant.INFORMATION:
      return "information";
    case SnackBarVariant.SUCCESS:
      return "success";
    case SnackBarVariant.WARNING:
      return "warning";
    default:
      return "information";
  }
};

export function SnackbarWrapper(props: Props) {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[getClassName(variant)], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}
