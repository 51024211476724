import React from "react"

export const SwbVanSVG = {
  interior: {
    width: 284.2,
    height: 576,
    svg: (
      <><g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M251.2,97.1c0.5-20.9-15.8-55.1-15.8-55.1c-18.4-22-93.4-25-93.4-25
		S66.8,20,48.4,42c0,0-16.3,34.2-15.8,55.1l0,212.6v182.7v49.5c0,0,0.7,17,20.9,17.9h88.3h88.3c20.2-0.8,20.9-17.9,20.9-17.9v-49.5
		V309.7L251.2,97.1z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M141.9,103.1c0,0-63.6,0.7-92.1,21.1v58.1c0,0,32.7-13.7,92.1-15.2
		c59.4,1.4,92.1,15.2,92.1,15.2v-58.1C205.4,103.8,141.9,103.1,141.9,103.1z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M234,170.7c0,0-42.4-16.9-92.1-15.7" />
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M49.8,170.7c0,0,42.4-16.9,92.1-15.7" />
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M32.6,101.1h3.7c0,0,1.2-26.1,11.5-48.5c1.9-4.1,4.6-7.7,8.1-10.5
		c4.6-3.6,11.8-8.3,21-10.8c0.5-0.1,1.1,0.3,1.1,0.8v4c0,0.6,0.5,1,1.1,0.9c5.6-1.4,31.4-7.1,62.7-6.7"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M251.2,101.1h-3.7c0,0-1.2-26.1-11.5-48.5c-1.9-4.1-4.6-7.7-8.1-10.5
		c-4.6-3.6-11.8-8.3-21-10.8c-0.5-0.1-1.1,0.3-1.1,0.8v4c0,0.6-0.5,1-1.1,0.9c-5.6-1.4-31.4-7.1-62.7-6.7"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M77.4,36.7" />
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M205.5,36.7c0,0,13.2,28.5,37.9,36.5" />
        <g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M78.7,36.7c0,0-13.2,28.5-37.9,36.5" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M37.6,93.6c0,0,8,69.6,8,92.1l2.6,73.5v193.3V540
			c0,0,0.8,4.3,5.4,4.1h90.9"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M34.4,101.1v151.8h9.4C43.8,252.9,43.6,115.2,34.4,101.1z" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="48.2" y1="528.4" x2="34.1" y2="536.8" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="48.2" y1="395.1" x2="32.6" y2="395.1" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="48.2" y1="259.3" x2="32.6" y2="259.3" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="61.7" y1="544.1" x2="46.4" y2="558.6" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="48.2" y1="266.7" x2="31.6" y2="266.7" />
        </g>
        <g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M246.4,93.6c0,0-8,69.6-8,92.1l-2.6,73.5v193.3V540
			c0,0-0.8,4.3-5.4,4.1h-90.9"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M249.6,101.1v151.8h-9.4C240.2,252.9,240.4,115.2,249.6,101.1z" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="235.8" y1="528.4" x2="249.9" y2="536.8" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="235.8" y1="395.1" x2="251.4" y2="395.1" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="235.8" y1="259.3" x2="251.4" y2="259.3" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="222.3" y1="544.1" x2="237.6" y2="558.6" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="235.8" y1="266.7" x2="252.4" y2="266.7" />
        </g>
        <g>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="49.8" y1="182.4" x2="57.6" y2="228.9" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="234.2" y1="182.4" x2="226.4" y2="228.9" />
        </g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M57.6,228.9v292.6c0,0-0.5,7.1,5.6,6.9h79.7" />
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M226.1,228.9v292.6c0,0,0.5,7.1-5.6,6.9h-79.7" />
        <g>
          <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="53.5,559.8 63,549.6 142.9,549.6 		" />
          <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="228.2,559.8 218.7,549.6 138.8,549.6 		" />
        </g>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="142.9" y1="549.6" x2="142.9" y2="559.8" />
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M34.2,127.3l-8.4,6.9v-2l-14-0.3c0,0,0,0,0,0
		c-2.9,0.5-5.1,3.1-5.1,6.1v4.7H24l1.8-3.7l8.4-6.6V127.3z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M249.6,127.3l8.4,6.9v-2l14-0.3c0,0,0,0,0,0c2.9,0.5,5.1,3.1,5.1,6.1
		v4.7h-17.4L258,139l-8.4-6.6V127.3z"/>
      </g>
        <g id="Layer_2_00000121240799882693998910000006517735838925078666_">
          <g>
            <path fill="#FFFFFF" stroke="#010101" stroke-miterlimit="10" d="M75.8,188.2h44.6c0,0,6.5,1.1,9.1,11.2l0.5,25.2
			c0,0-3.4,14.5-12.5,18.9H77.3c0,0-8.2-5.5-11.7-18.8V199C65.6,199.1,68.9,189.4,75.8,188.2z"/>
            <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M130.9,239.4l-0.9-1.5h-5.5l-3.7,3.7l-4.5,2l-18.6-0.2l0,0
			l-19.1,0.5l-4-2.8l-2.9-3.5l-6.3,0.3l-1.7,2.8l-0.9,7.4v22.5l0.8,5.8l2.3,4l2.9,2.5l4.5,2h6.3h2.8l0.2,1.7h15.4l0,0h15.4l0.2-1.7
			h2.8h6.3l4.5-2l2.9-2.5l2.3-4l0.8-5.8v-22.5l-0.8-5.8C131.8,241.1,131.5,240.2,130.9,239.4z"/>
            <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="97.5,269.6 78.4,269.6 78.4,284.4 82.1,287.8 97.5,287.8 
			113,287.8 116.7,284.4 116.7,269.6 		"/>
          </g>
          <g>
            <path fill="#FFFFFF" stroke="#010101" stroke-miterlimit="10" d="M164.1,188.8h44.6c0,0,6.5,1.1,9.1,11.2l0.5,25.2
			c0,0-3.4,14.5-12.5,18.9h-40.2c0,0-8.2-5.5-11.7-18.8v-25.9C153.8,199.6,157.2,190,164.1,188.8z"/>
            <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M219.1,240.1l-0.9-1.5h-5.5l-3.7,3.7l-4.5,2l-18.6-0.2l0,0
			l-19.1,0.5l-4-2.8l-2.9-3.5l-6.3,0.3l-1.7,2.8l-0.9,7.4v22.5l0.8,5.8l2.3,4l2.9,2.5l4.5,2h6.3h2.8l0.2,1.7H186l0,0h15.4l0.2-1.7
			h2.8h6.3l4.5-2l2.9-2.5l2.3-4l0.8-5.8v-22.5l-0.8-5.8C220,241.7,219.7,240.8,219.1,240.1z"/>
            <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="185.8,270.1 166.6,270.1 166.6,285 170.3,288.4 
			185.8,288.4 201.4,288.4 204.9,285 204.9,270.1 		"/>
          </g>
        </g>
      </>
    )
  },
  exterior: {
    width: 853.8,
    height: 1201,
    svg: (
      <><g id="Layer_1">
        <g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M529.9,395.4c0.5-20.9-15.8-55.1-15.8-55.1
          c-18.4-22-93.4-25-93.4-25s-75.1,3.1-93.4,25c0,0-16.3,34.2-15.8,55.1l0,212.6v182.7v49.5c0,0,0.7,17,20.9,17.9h88.3H509
          c20.2-0.8,20.9-17.9,20.9-17.9v-49.5V607.9L529.9,395.4z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M420.7,401.3c0,0-63.6,0.7-92.1,21.1v58.1c0,0,32.7-13.7,92.1-15.2
          c59.4,1.4,92.1,15.2,92.1,15.2v-58.1C484.2,402,420.7,401.3,420.7,401.3z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M512.8,469c0,0-42.4-16.9-92.1-15.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M328.6,469c0,0,42.4-16.9,92.1-15.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M311.4,399.3h3.7c0,0,1.2-26.1,11.5-48.5c1.9-4.1,4.6-7.7,8.1-10.5
          c4.6-3.6,11.8-8.3,21-10.8c0.5-0.1,1.1,0.3,1.1,0.8v4c0,0.6,0.5,1,1.1,0.9c5.6-1.4,31.4-7.1,62.7-6.7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M529.9,399.3h-3.7c0,0-1.2-26.1-11.5-48.5
          c-1.9-4.1-4.6-7.7-8.1-10.5c-4.6-3.6-11.8-8.3-21-10.8c-0.5-0.1-1.1,0.3-1.1,0.8v4c0,0.6-0.5,1-1.1,0.9
          c-5.6-1.4-31.4-7.1-62.7-6.7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M356.1,334.9" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.3,334.9c0,0,13.2,28.5,37.9,36.5" />
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M357.5,334.9c0,0-13.2,28.5-37.9,36.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M316.4,391.9c0,0,8,69.6,8,92.1l2.6,73.5v193.3v87.4
            c0,0,0.8,4.3,5.4,4.1h90.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313.2,399.3v151.8h9.4C322.6,551.1,322.4,413.4,313.2,399.3z" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327" y1="826.6" x2="312.9" y2="835" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327" y1="693.3" x2="311.4" y2="693.3" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327" y1="557.5" x2="311.4" y2="557.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="340.5" y1="842.3" x2="325.2" y2="856.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327" y1="564.9" x2="310.4" y2="564.9" />
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M525.2,391.9c0,0-8,69.6-8,92.1l-2.6,73.5v193.3v87.4
            c0,0-0.8,4.3-5.4,4.1h-90.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M528.4,399.3v151.8H519C519,551.1,519.2,413.4,528.4,399.3z" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="514.6" y1="826.6" x2="528.7" y2="835" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="514.6" y1="693.3" x2="530.2" y2="693.3" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="514.6" y1="557.5" x2="530.2" y2="557.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="501.1" y1="842.3" x2="516.4" y2="856.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="514.6" y1="564.9" x2="531.2" y2="564.9" />
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M328.6,480.6l7.8,46.5c0,0,34-12.3,86.8-11.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M513,480.6l-7.8,46.5c0,0-34-12.3-86.8-11.2" />
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M336.4,527.1v292.6c0,0-0.5,7.1,5.6,6.9h79.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M504.9,527.1v292.6c0,0,0.5,7.1-5.6,6.9h-79.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M355.3,820.2h-9.2c-1.8,0-3.3-1.5-3.3-3.3V558.8
          c0-1.8,1.5-3.3,3.3-3.3h9.2c1.8,0,3.3,1.5,3.3,3.3v258.1C358.6,818.7,357.1,820.2,355.3,820.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M378.1,820.2h-9.2c-1.8,0-3.3-1.5-3.3-3.3V558.8
          c0-1.8,1.5-3.3,3.3-3.3h9.2c1.8,0,3.3,1.5,3.3,3.3v258.1C381.4,818.7,379.9,820.2,378.1,820.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M400.9,820.2h-9.2c-1.8,0-3.3-1.5-3.3-3.3V558.8
          c0-1.8,1.5-3.3,3.3-3.3h9.2c1.8,0,3.3,1.5,3.3,3.3v258.1C404.2,818.7,402.7,820.2,400.9,820.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M423.7,820.2h-9.2c-1.8,0-3.3-1.5-3.3-3.3V558.8
          c0-1.8,1.5-3.3,3.3-3.3h9.2c1.8,0,3.3,1.5,3.3,3.3v258.1C427,818.7,425.5,820.2,423.7,820.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M446.5,820.2h-9.2c-1.8,0-3.3-1.5-3.3-3.3V558.8
          c0-1.8,1.5-3.3,3.3-3.3h9.2c1.8,0,3.3,1.5,3.3,3.3v258.1C449.9,818.7,448.4,820.2,446.5,820.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M469.3,820.2h-9.2c-1.8,0-3.3-1.5-3.3-3.3V558.8
          c0-1.8,1.5-3.3,3.3-3.3h9.2c1.8,0,3.3,1.5,3.3,3.3v258.1C472.7,818.7,471.2,820.2,469.3,820.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M492.1,820.2H483c-1.8,0-3.3-1.5-3.3-3.3V558.8
          c0-1.8,1.5-3.3,3.3-3.3h9.2c1.8,0,3.3,1.5,3.3,3.3v258.1C495.5,818.7,494,820.2,492.1,820.2z"/>
          <g>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="332.3,858 341.8,847.8 421.7,847.8 			" />
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="507,858 497.5,847.8 417.6,847.8 			" />
          </g>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="421.7" y1="847.8" x2="421.7" y2="858" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313,425.5l-8.4,6.9v-2l-14-0.3c0,0,0,0,0,0
          c-2.9,0.5-5.1,3.1-5.1,6.1v4.7h17.4l1.8-3.7l8.4-6.6V425.5z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M528.4,425.5l8.4,6.9v-2l14-0.3c0,0,0,0,0,0
          c2.9,0.5,5.1,3.1,5.1,6.1v4.7h-17.4l-1.8-3.7l-8.4-6.6V425.5z"/>
        </g>
      </g>
        <g id="Layer_2">
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="56.5" cy="750.8" r="38.1" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="56.5" cy="750.8" r="19.3" />
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="54.1" cy="413.6" r="38.1" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="54.1" cy="413.6" r="19.3" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M50.3,463.5c27.2,0,52.2-16.3,53-49.9c0.5-21.4-14-47.2-52.5-48.7
          h-3.4v-35.6c0,0,17.2-2,22-8h26.7l0.2,1.9c0,0,7.2,0.6,15.6,2.8c8.8,2.3,16.8,6.9,23.1,13.4c8.5,8.6,23.8,34.8,28.6,50l0.7,11.7
          L260,509c0,0,11.6,13.6,11.9,32.3l2,295.8c0,0,0.7,4.1-5.8,5.4l-29.3,5.8l-68.4,10.3c0,0-43.9,1-76.9,1v-77.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M93.5,859.7l-5.4,4.3H51.8l-3.6-69.1c37.6,1.2,54.3-11.7,55-41.7
          c0.5-21.8-13.6-46.4-52.6-46.4V463.5"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="96.8" y1="388.7" x2="96.8" y2="323.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="70.4" y1="368.3" x2="70.4" y2="321.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M96.8,340.7c0,0,22.6-2.2,42.4,18.9l25.2,41.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M228.8,483.2c0,0,1.9,2.9,3.9,6.7c2.3,4.4,3.5,9.4,3.5,14.4l0,344.5
          "/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M134.1,405.1c-0.1-3.9,4.3-6.3,7.5-4.1c0,0,0.1,0.1,0.1,0.1
          l31.1,28.4l55.9,53.6l-1.5,59.1c0,1.9-1,3.8-2.6,4.9c-1.7,1.2-4,2.4-6.7,2.1l-58.8-7.4c-1.9-0.2-3.7-1.1-5.1-2.5
          c-1.7-1.8-3.9-4.8-5.3-9.7l-13.5-55.1L134.1,405.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M179.5,435.9h-28c-2.7,0-5.4,0.7-7.8,2.1l-3,1.7v27.3l15.6,17.4V541
          "/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="228.5" y1="493.9" x2="156.3" y2="484.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M236.1,555H125.5l-71.5-11.9v-74.2c0,0,44.2-3.4,51.4-40.9h28.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M127.2,555v-8.8c0-2.1,1.5-3.9,3.4-3.9h14c1.9,0,3.4,1.7,3.4,3.9
          v8.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M227,563.5h-73.2v276.8c0,0,46.3,0,65.4-4.9
          c4.6-1.2,7.8-5.3,7.8-10.1V563.5z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="236.1" y1="699" x2="54.1" y2="699" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="236.1" y1="563.5" x2="54.1" y2="563.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="54.1" y1="699.5" x2="54.1" y2="563" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M236.1,839.1c0,0-78.9,12.2-142.6,9.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M137.5,859.3c0,0-1.2-5.9-7.3-6.2H93.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="108.5" y1="853.1" x2="108.5" y2="859.3" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="123.3" y1="849.2" x2="123.3" y2="428" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="113.3" y1="849.2" x2="113.3" y2="428" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M127.4,585.1V571c0-1.3,1.1-2.3,2.3-2.3h15.8c1.3,0,2.3,1.1,2.3,2.3
          v14.1c0,1.3-1.1,2.3-2.3,2.3h-15.8C128.5,587.4,127.4,586.4,127.4,585.1z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="147.5" y1="576.9" x2="127.4" y2="576.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M139.2,359.6c0,0-10.6-27.4-42.4-31.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="55.2" y1="463.3" x2="55.2" y2="451.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="55.2" y1="712.7" x2="55.2" y2="706.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="55.2" y1="794.7" x2="55.2" y2="788.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="55.2" y1="375.2" x2="55.2" y2="365.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="164.4" y1="421.8" x2="164.4" y2="401.1" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M151.4,433.8l0.7-5.6c0-2.4,1.9-4.4,4.2-4.4h20.8
          c2.3,0,4.2,2,4.2,4.4l-0.7,5.6c0,2.4-1.9,4.4-4.2,4.4h-20.8C153.3,438.2,151.4,436.2,151.4,433.8z"/>
          </g>
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="794.5" cy="750.8" r="38.1" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="794.5" cy="750.8" r="19.3" />
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="796.9" cy="413.6" r="38.1" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="796.9" cy="413.6" r="19.3" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M800.7,463.5c-27.2,0-52.2-16.3-53-49.9
          c-0.5-21.4,14-47.2,52.5-48.7h3.4v-35.6c0,0-17.2-2-22-8h-26.7l-0.2,1.9c0,0-7.2,0.6-15.6,2.8c-8.8,2.3-16.8,6.9-23.1,13.4
          c-8.5,8.6-23.8,34.8-28.6,50l-0.7,11.7L591,509c0,0-11.6,13.6-11.9,32.3l-2,295.8c0,0-0.7,4.1,5.8,5.4l29.3,5.8l68.4,10.3
          c0,0,43.9,1,76.9,1v-77.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M757.5,859.7l5.4,4.3h36.3l3.6-69.1c-37.6,1.2-54.3-11.7-55-41.7
          c-0.5-21.8,13.6-46.4,52.6-46.4V463.5"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="754.2" y1="388.7" x2="754.2" y2="323.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="780.6" y1="368.3" x2="780.6" y2="321.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M754.2,340.7c0,0-22.6-2.2-42.4,18.9l-25.2,41.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M622.2,483.2c0,0-1.9,2.9-3.9,6.7c-2.3,4.4-3.5,9.4-3.5,14.4
          l0,344.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M716.9,405.1c0.1-3.9-4.3-6.3-7.5-4.1c0,0-0.1,0.1-0.1,0.1
          l-31.1,28.4l-55.9,53.6l1.5,59.1c0,1.9,1,3.8,2.6,4.9c1.7,1.2,4,2.4,6.7,2.1l58.8-7.4c1.9-0.2,3.7-1.1,5.1-2.5
          c1.7-1.8,3.9-4.8,5.3-9.7l13.5-55.1L716.9,405.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M671.5,435.9h28c2.7,0,5.4,0.7,7.8,2.1l3,1.7v27.3l-15.6,17.4V541"
            />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="622.5" y1="493.9" x2="694.7" y2="484.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M614.9,555h110.6l71.5-11.9v-74.2c0,0-44.2-3.4-51.4-40.9h-28.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M723.9,555v-8.8c0-2.1-1.5-3.9-3.4-3.9h-14c-1.9,0-3.4,1.7-3.4,3.9
          v8.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M624,563.5h73.2v276.8c0,0-46.3,0-65.4-4.9
          c-4.6-1.2-7.8-5.3-7.8-10.1V563.5z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="614.9" y1="699" x2="796.9" y2="699" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="614.9" y1="563.5" x2="796.9" y2="563.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="796.9" y1="699.5" x2="796.9" y2="563" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M614.9,839.1c0,0,78.9,12.2,142.6,9.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M713.5,859.3c0,0,1.2-5.9,7.3-6.2h36.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="742.5" y1="853.1" x2="742.5" y2="859.3" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="727.7" y1="849.2" x2="727.7" y2="428" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="737.7" y1="849.2" x2="737.7" y2="428" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M723.6,585.1V571c0-1.3-1.1-2.3-2.3-2.3h-15.8
          c-1.3,0-2.3,1.1-2.3,2.3v14.1c0,1.3,1.1,2.3,2.3,2.3h15.8C722.5,587.4,723.6,586.4,723.6,585.1z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="703.5" y1="576.9" x2="723.6" y2="576.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M711.8,359.6c0,0,10.6-27.4,42.4-31.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="795.8" y1="463.3" x2="795.8" y2="451.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="795.8" y1="712.7" x2="795.8" y2="706.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="795.8" y1="794.7" x2="795.8" y2="788.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="795.8" y1="375.2" x2="795.8" y2="365.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="686.7" y1="421.8" x2="686.7" y2="401.1" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M699.6,433.8l-0.7-5.6c0-2.4-1.9-4.4-4.2-4.4h-20.8
          c-2.3,0-4.2,2-4.2,4.4l0.7,5.6c0,2.4,1.9,4.4,4.2,4.4h20.8C697.7,438.2,699.6,436.2,699.6,433.8z"/>
          </g>
        </g>
        <g id="Layer_3">
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M334.5,262.5v21c0,3.7-3,6.7-6.6,6.7h-11.9c-3.6,0-6.6-3-6.6-6.7
          v-46.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.7,262.5v21c0,3.7,3,6.7,6.6,6.7h11.9c3.6,0,6.6-3,6.6-6.7
          v-46.3"/>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M299.2,155.3h-5.8c-2.6,0-4.7-2.1-4.7-4.7v-20.2
            c0-2.6,2.1-4.7,4.7-4.7h5.8c2.6,0,4.7,2.1,4.7,4.7v20.2C303.9,153.2,301.8,155.3,299.2,155.3z"/>
              <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="313.9,151.6 303.9,151.5 303.9,145.3 314.2,145.3 			" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M539.1,155.3h5.8c2.6,0,4.7-2.1,4.7-4.7v-20.2
            c0-2.6-2.1-4.7-4.7-4.7h-5.8c-2.6,0-4.7,2.1-4.7,4.7v20.2C534.4,153.2,536.5,155.3,539.1,155.3z"/>
              <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="524.4,151.6 534.4,151.5 534.4,145.3 524.1,145.3 			" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M525.6,181.6l-1.8-39.6L502.6,31c-1.3-4-5.3-4-5.3-4l-78.3-1.8
          L340.8,27c0,0-4,0-5.3,4l-21.3,111l-1.8,39.6c0,0-4.3,9.7-5.5,14.8l2.8,45.6c0,0,0.5,20.5,14.5,20.5h94.8h94.8
          c14,0,14.5-20.5,14.5-20.5l2.8-45.6C529.9,191.4,525.6,181.6,525.6,181.6z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M515.4,136.8l-7.7-52.6c-0.2-1.2-1.2-2-2.3-2
          c-17.7-0.2-86.2-2-86.2-2s-68.6,1.8-86.2,2c-1.2,0-2.2,0.9-2.3,2l-7.7,52.6c0,0-0.4,4.9,3.4,5.4h92.9H512
          C515.7,141.8,515.4,136.8,515.4,136.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M420.4,179.4h-1.3h-72.5l3.4,28.9c0,0,2.5,8.2,9,13.4
          c1.4,1.1,3.1,1.7,4.8,1.7h55.3h1.3h55.3c1.8,0,3.5-0.6,4.8-1.7c6.5-5.3,9-13.4,9-13.4l3.4-28.9H420.4z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="491.9" y1="187.2" x2="347.3" y2="187.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="490.8" y1="191.9" x2="348.6" y2="191.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="490.8" y1="196.5" x2="348.6" y2="196.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="490.3" y1="201.2" x2="349.2" y2="201.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="489.7" y1="205.9" x2="349.7" y2="205.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="488.9" y1="210.6" x2="350.9" y2="210.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="486.1" y1="215.3" x2="353.3" y2="215.3" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="483.2" y1="219.3" x2="356.3" y2="219.3" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="491.9" y1="183.2" x2="347.3" y2="183.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="352.1" y1="213.1" x2="307.9" y2="213.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="530.3" y1="213.1" x2="487.4" y2="213.1" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M323.5,140.4l-2.6,2.6v22.6c0,0,18.9,3.7,25.7,13.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M515.2,139.7l3.3,3.3v22.6c0,0-18.9,3.7-25.7,13.8" />
            <rect x="371" y="149.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="97.8" height="2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="350.9" y1="179.4" x2="346.6" y2="149.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="487.6" y1="179.4" x2="491.9" y2="149.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="528.7" y1="237" x2="478.7" y2="237" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="359" y1="237.3" x2="309.9" y2="237.3" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="359" y1="221.8" x2="359" y2="261.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="478.7" y1="222.8" x2="478.7" y2="262.8" />
            <g>
              <rect x="341.6" y="241.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="13.3" height="7" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="332.1" cy="245.3" r="4" />
            </g>
            <g>

              <rect x="484.1" y="241.8" transform="matrix(-1 -4.480162e-11 4.480162e-11 -1 981.5165 490.6056)" fill="none" stroke="#000000" stroke-miterlimit="10" width="13.3" height="7" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="506.9" cy="245.3" r="4" />
            </g>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="359" y1="240.1" x2="478.7" y2="240.1" />
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="324.9" cy="183" r="8.1" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="340.3" cy="187.2" r="4.2" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="320.9" y1="165.3" x2="312.5" y2="181.6" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M344,206.2h-26.3c-0.6,0-1-0.5-1-1v-8.4c0-0.6,0.5-1,1-1H344
            c0.6,0,1,0.5,1,1v8.4C345.1,205.7,344.6,206.2,344,206.2z"/>
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="513.8" cy="183" r="8.1" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="498.4" cy="187.2" r="4.2" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="518.2" y1="165.3" x2="525.9" y2="182.2" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M494.6,206.2h26.3c0.6,0,1-0.5,1-1v-8.4c0-0.6-0.5-1-1-1h-26.3
            c-0.6,0-1,0.5-1,1v8.4C493.5,205.7,494,206.2,494.6,206.2z"/>
            </g>
          </g>
        </g>
        <g id="Layer_4">
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M303.5,1100.9c0,0,3.4,36.3,4.6,39.5c0,0,1.5,6.2,5.2,6.6h106.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M536.5,1100.9c0,0-3.4,36.3-4.6,39.5c0,0-1.5,6.2-5.2,6.6H419.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M329.1,1147.2v21c0,3.7-3,6.7-6.6,6.7h-11.9c-3.6,0-6.6-3-6.6-6.7
          V1105"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M511.3,1146.9v21c0,3.7,3,6.7,6.6,6.7h11.9c3.6,0,6.6-3,6.6-6.7v-66
          "/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M446.1,1157.2h-48.5c-2,0-3.7-1.6-3.7-3.7v-12.9
          c0-2,1.6-3.7,3.7-3.7h48.5c2,0,3.7,1.6,3.7,3.7v12.9C449.7,1155.6,448.1,1157.2,446.1,1157.2z"/>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M293.6,1045.2h-9.3c-2.3,0-4.3-1.9-4.3-4.3v-21.2
            c0-2.3,1.9-4.3,4.3-4.3h9.3c2.3,0,4.3,1.9,4.3,4.3v21.2C297.9,1043.3,296,1045.2,293.6,1045.2z"/>
              <rect x="298.2" y="1035.9" fill="none" stroke="#000000" stroke-miterlimit="10" width="7.2" height="5.5" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M547.7,1045.2h9.3c2.3,0,4.3-1.9,4.3-4.3v-21.2
            c0-2.3-1.9-4.3-4.3-4.3h-9.3c-2.3,0-4.3,1.9-4.3,4.3v21.2C543.4,1043.3,545.4,1045.2,547.7,1045.2z"/>

              <rect x="535.9" y="1035.9" transform="matrix(-1 -4.492063e-11 4.492063e-11 -1 1079.0812 2077.2766)" fill="none" stroke="#000000" stroke-miterlimit="10" width="7.2" height="5.5" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M535.5,1072.3l0-44.6c0.3-18.4-3.9-35.1-3.9-35.1l-11.7-66
          c-2-12.6-9.9-15-9.9-15c-5.3-0.9-76.3-3.3-88.6-3.7v-0.1c0,0-0.3,0-0.9,0c-0.6,0-0.9,0-0.9,0v0.1c-12.3,0.4-83.3,2.8-88.6,3.7
          c0,0-7.8,2.4-9.9,15l-11.7,66c0,0-4.3,16.7-3.9,35.1l0,44.6l-3.4,2v26.6h22.2l2.3,27.6h93h1.8h93l2.3-27.6h22.2v-26.6
          L535.5,1072.3z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M419.6,914H494c1.7,0,3.4,0.4,4.8,1.3c1.4,0.9,2.9,2.4,3.3,4.8
          l9.5,48.3l2.4,18l2.6,114.4l-2.6,27.6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M419.6,914h-74.5c-1.7,0-3.4,0.4-4.8,1.3c-1.4,0.9-2.9,2.4-3.3,4.8
          l-9.5,48.3l-2.4,18l-0.8,114.4l2.3,27.6"/>
            <g>
              <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="321.5,1097.9 305.2,1097.9 305.2,1076.1 309.3,1073.3 
            309.3,1061.2 321.5,1061.2 			"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="305" y1="1087" x2="321.4" y2="1087" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="309.3" y1="1073.1" x2="321.5" y2="1073.1" />
            </g>
            <g>
              <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="519,1097.9 535.4,1097.9 535.4,1076.1 531.3,1073.3 
            531.3,1061.2 519,1061.2 			"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="535.5" y1="1087" x2="519.2" y2="1087" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="531.3" y1="1073.1" x2="519" y2="1073.1" />
            </g>
            <path fill="#E5E5E5" stroke="#000000" stroke-miterlimit="10" d="M500.2,1038.6H338.9c-2.2,0-4-1.8-4-4v-66.3c0-2.2,1.8-4,4-4
          h161.3c2.2,0,4,1.8,4,4v66.3C504.1,1036.9,502.4,1038.6,500.2,1038.6z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M405.4,1033.5h-57c-4.5,0-8.3-3.7-8.3-8.3V974c0-2.2,1.8-4,4-4
          h65.6c2.2,0,4,1.8,4,4v51.3C413.7,1029.8,409.9,1033.5,405.4,1033.5z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M491,1033.5h-57c-4.5,0-8.3-3.7-8.3-8.3V974c0-2.2,1.8-4,4-4
          h65.6c2.2,0,4,1.8,4,4v51.3C499.3,1029.8,495.6,1033.5,491,1033.5z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="419.5" y1="913.1" x2="419.5" y2="1128.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M445.4,1092.4h-12.6c-2.2,0-4-1.8-4-4v-14c0-2.2,1.8-4,4-4h12.6
          c2.2,0,4,1.8,4,4v14C449.4,1090.6,447.6,1092.4,445.4,1092.4z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.5,1098.4h-54.4c-6.7,0-12.2-5.5-12.2-12.2v-15.8
          c0-2.2,1.8-4,4-4h70.7c2.2,0,4,1.8,4,4v15.8C413.7,1092.9,408.2,1098.4,401.5,1098.4z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="436.1" y1="1070.5" x2="436.1" y2="1092.4" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="334.9" y1="1073.1" x2="413.7" y2="1073.1" />
          </g>
        </g>
      </>
    )
  }
}