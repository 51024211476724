import {
  CircularProgress,
  Typography, Hidden, IconButton, Tooltip
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BILLING_CYCLE_NAME, IAddonRequirementInput, IBookingCreateInput, IBookingVehicleGroup, ILocationSurchargeRules, ILocationTaxSurcharge, IOneWayRentalFee, ITaxInput, PriceRuleOperator, ValueType } from "../../reducers/bookings/types";

import { IAppState } from "../../store";
import { getRetnalTaxAmount, getTaxDescription, isLongTermBooking, toCurrency } from "../utils";
import { getLocalizedTaxSyntex } from '../../utils/localized.syntex';
import { RATE_TYPES } from '../views/utils';
import { DateTime as d } from 'luxon';
import styles from "./index.module.css";
import InfoIcon from '@material-ui/icons/Info';
import { DepositCollection } from "../consts";

interface IProps {
  bookingInput: IBookingCreateInput;
  oneWayRentalFee: IOneWayRentalFee | undefined;
}
export const SummaryPanel = (props: IProps) => {
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);   //TODO from API
  const branches = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches);
  const authUser = useSelector((state: IAppState) => state.authReducer.user);

  const [bookingDurationInDays, setBookingDurationInDays] = useState<any>();

  const { locale, currency } = organisation;
  const { country } = organisation.address;
  const { bookingInput } = props;
  const branch = branches.find((branch) => branch.id === bookingInput.branchId);
  const [collectDeposit, setCollectDeposit] = useState<boolean>(false);
  const taxes = branch && branch.addTaxOptIn ? branch.taxes.map((tax) => {
    return {
      code: tax.title,
      type: tax.type,
      rate: tax.rate,
      amount: tax.value,
      subdivisionOptIn: tax.subdivisionOptIn,
      subdivisionRate: tax.subdivisionRate,
      subdivisionType: tax.subdivisionType,
      subdivisionValue: tax.subdivisionValue
    }
  }) : [];
  enum RentalTimeText {
    "hourly" = "hour",
    "daily" = "day",
    "weekly" = "week",
    "monthly" = "month"
  }
  let _totalRentalAmount: number = 0;

  useEffect(() => {
    if (branch && branch.depositCollection === DepositCollection.AT_BOOKING_CONFIRMATION) {
      setCollectDeposit(true);
    }
  }, [branch])

  useEffect(() => {
    if (bookingInput) {
      let bookingDuration;
      const longTermBooking = isLongTermBooking(bookingInput);
      if (longTermBooking) {
        switch (bookingInput.rateTypeName) {
          case BILLING_CYCLE_NAME.DAILY:
            if (bookingInput.isRecurringBilling && bookingInput.billingFrequency) {
              bookingDuration = bookingInput.billingFrequency;
              setBookingDurationInDays(bookingDuration);
            }
            break;
          case BILLING_CYCLE_NAME.WEEKLY:
            if (bookingInput.isRecurringBilling && bookingInput.billingFrequency) {
              bookingDuration = 7 * bookingInput.billingFrequency
            } else {
              bookingDuration = 7
            }
            setBookingDurationInDays(bookingDuration);
            break;
          case BILLING_CYCLE_NAME.MONTHLY:
            if (bookingInput.isRecurringBilling && bookingInput.billingFrequency) {
              let idealEndDate = d.fromISO(bookingInput.pickupDateTime || "").plus({ months: bookingInput.billingFrequency }).toUTC().toISO();
              bookingDuration = d.fromISO(idealEndDate).diff(d.fromISO(bookingInput.pickupDateTime), "day").days;
              setBookingDurationInDays(bookingDuration);
            }
            break;
          default:
            break;
        }
      } else {
        bookingDuration = Math.ceil(d.fromISO(bookingInput.dropoffDateTime || "" ).diff(d.fromISO(bookingInput.pickupDateTime || ""), ["days"]).days);
        setBookingDurationInDays(bookingDuration);
      }
    }
  }, [bookingInput]);

  const getApplicableRentalAmount = (baseRate: number, operator?: string, valueType?: ValueType, value?: number) => {
    if (operator && valueType && value) {
      switch (operator) {
        case PriceRuleOperator.SURGE:
          if (valueType === ValueType.VARIABLE) {
            return baseRate + Math.round((baseRate * value) / 100);
          }
          return baseRate + value;
        case PriceRuleOperator.DISCOUNT:
          let amount = 0;
          if (valueType === ValueType.VARIABLE) {
            amount = baseRate - Math.round((baseRate * value) / 100);
          } else {
            amount = baseRate - value;
          }
          return amount > 0 ? amount : 0;
      }
    }
    return baseRate;
  };

  const getAddonRate = (addon: IAddonRequirementInput, rentalTime: number) => {
    let rate = addon.rate * addon.quantity;
    if (!addon.hasFixedRate && rentalTime) {
      rate = rate * rentalTime;
    }
    return rate;
  };

  const getDepositAmount = () => {
    if (bookingInput && bookingInput.vehicleGroups) {
      return bookingInput.vehicleGroups.reduce((total, vg) => total + (vg.deposit * vg.count), 0)
    }
    return 0
  }

  if (!bookingInput) {
    return <CircularProgress />;
  }
  const flag = false
  const taxRate = taxes && taxes.length ? (taxes.reduce((prv: any, curr) => prv + curr.rate, 0)) / 100 : 0;
  const rentalTime = [RATE_TYPES.WEEKLY, RATE_TYPES.MONTHLY].includes(bookingInput.rateTypeName as RATE_TYPES) ? 1
    : bookingInput.rateTypeName === RATE_TYPES.DAILY ?
      Math.ceil(d.fromISO(bookingInput.dropoffDateTime || "").diff(d.fromISO(bookingInput.pickupDateTime || ""), "days").days)
      : Math.ceil(d.fromISO(bookingInput.dropoffDateTime || "").diff(d.fromISO(bookingInput.pickupDateTime || ""), "hours").hours);
  let totalVehicles = bookingInput.vehicleGroups.reduce(
    (accumulator, current) => accumulator + current.count, 0);
  let totalAmount = 0;
  if (bookingInput.isSubscription) {
    totalVehicles = 1
  }

  totalAmount += bookingInput.insuranceRate * totalVehicles * rentalTime;
  let totalSubscriptionAmount = 0;
  totalSubscriptionAmount += totalAmount + (bookingInput.activeSubscriptionExpanded?.subscriptionAmount || 0) + (bookingInput.activeSubscriptionExpanded?.enrollmentAmount || 0) + getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, bookingInput.activeSubscriptionExpanded?.subscriptionAmount || 0, bookingDurationInDays, 1)
  if (props.oneWayRentalFee && props.oneWayRentalFee.amount) {
    totalAmount += props.oneWayRentalFee.amount * totalVehicles;
  }
  return (
    <div className={`flex ${styles.sidebar}`}>
      <Hidden lgUp smDown>
        <span className='margin-left margin-right' />
      </Hidden>
      <div className='flex fill col-flex'>

      {!organisation.closeGroupSharingEnabled ?
          <Typography variant="h4" align="center" style={{ fontWeight: 700, marginBottom: 20 }} >Pricing Summary</Typography> :
          <Typography variant="h4" align="center" style={{ fontWeight: 700, marginBottom: 20 }} >Booking Summary</Typography>
        }
        <div style={{ background: "#fff", borderRadius: 10, padding: "30px 12px 10px 12px", marginBottom: 120 }}>
          <div style={{ overflowY:"auto", maxHeight:"300px" }}>
          <Typography variant='h4' style={{ margin: "0 6px 6px 6px" }}>{bookingInput.activeSubscriptionExpanded ? "Subscription Vehicle" : "Vehicles"}</Typography>
          {bookingInput?.vehicleGroups?.map((vg: IBookingVehicleGroup, idx: number) => {
            const rentalAmount = getApplicableRentalAmount(vg.baseRate, vg.priceRuleOperator, vg.priceRuleValueType, vg.priceRuleAmount)
            const totalRentalAmount = rentalAmount * vg.count * rentalTime;
            _totalRentalAmount += totalRentalAmount;
            const rentalTaxAmount = getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, totalRentalAmount, bookingDurationInDays, vg.count);
            const taxTitles = getTaxDescription(taxes, currency, locale);
            totalAmount += (rentalAmount * vg.count * rentalTime + rentalTaxAmount);
            return (
              <div className={styles.item}>
                <Typography variant='body1' className='flex '>
                  <span className='flex fill'>{vg.name}</span>
                  {!organisation.closeGroupSharingEnabled?
                    <span className='bold'>{toCurrency((rentalAmount * vg.count * rentalTime + rentalTaxAmount), currency, locale)}</span>:" "
                  }
                </Typography>
                <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                  <span className='flex fill'>Quantity: {vg.count}</span>
                  {!organisation.closeGroupSharingEnabled ?
                    <span>{vg.count} x ({rentalTime} {RentalTimeText[bookingInput.rateTypeName as RATE_TYPES]}{rentalTime > 1 ? "s" : ""} x {toCurrency(rentalAmount, currency, locale)})</span> : ""
                  }
                </Typography>
                {!organisation.closeGroupSharingEnabled ?
                <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                <span className='flex fill'></span>
                <span>
                  {getLocalizedTaxSyntex(country || "")} : {toCurrency(rentalTaxAmount, currency, locale)}
                  <IconButton style={{
                    color: "black",
                    padding: 0,
                    paddingRight: 5,
                    marginLeft: 10,
                    height: 12,
                    width: 12
                  }}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          {
                            taxTitles.length > 0 &&
                            taxTitles.map(title => {
                              return (
                                <>
                                  <span>({title})</span><br></br>
                                </>
                              )
                            })
                          }
                        </React.Fragment>
                      }
                      arrow
                      placement={"right"}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </IconButton>
                </span>
              </Typography> : ""}
                
              </div>
            )
          })}
          {
            bookingInput.activeSubscription && (
              <>
                <div className={styles.item}>
                  <Typography variant='body1' className='flex'>
                    <span className='flex fill'>{bookingInput?.activeSubscriptionExpanded?.subscription?.name}</span>
                    <span className='bold'>{toCurrency((bookingInput?.activeSubscriptionExpanded?.subscriptionAmount || 0) + (getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, bookingInput?.activeSubscriptionExpanded?.subscriptionAmount || 0, bookingDurationInDays, 1)), currency, locale)}</span>
                  </Typography>
                  <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                    <span className='flex fill'></span>
                    <span >{`Subscription Price(${bookingInput?.activeSubscriptionExpanded?.subscription.rateTypeName === RATE_TYPES.WEEKLY ? "Weekly" : "Monthly"}): ${toCurrency(bookingInput?.activeSubscriptionExpanded?.subscriptionAmount || 0, currency, locale)}`}</span>
                  </Typography>
                  <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                    <span className='flex fill'></span>
                    <span>
                      {getLocalizedTaxSyntex(country || "")} : {toCurrency(getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, bookingInput?.activeSubscriptionExpanded?.subscriptionAmount || 0, bookingDurationInDays, 1), currency, locale)}
                      <IconButton style={{
                        color: "black",
                        padding: 0,
                        paddingRight: 5,
                        marginLeft: 10,
                        height: 12,
                        width: 12
                      }}>
                        <Tooltip
                          title={
                            <React.Fragment>
                              {
                                getTaxDescription(taxes, currency, locale).length > 0 &&
                                getTaxDescription(taxes, currency, locale).map(title => {
                                  return (
                                    <>
                                      <span>({title})</span><br></br>
                                    </>
                                  )
                                })
                              }
                            </React.Fragment>
                          }
                          arrow
                          placement={"right"}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </IconButton>
                    </span>
                  </Typography>
                </div>
                <div className={styles.item} style={{ marginTop: 15 }}>
                  <Typography variant='body1' className='flex'>
                    <span className='flex fill'>Enrollment Fee</span>
                    <span className='bold'>{toCurrency(bookingInput?.activeSubscriptionExpanded?.enrollmentAmount || 0, currency, locale)}</span>
                  </Typography>
                </div>
              </>
            )
          }
          {bookingInput.insurancePolicy &&
            <>
              <Typography variant='h4' style={{ margin: "15px 6px 6px 6px" }}>Insurance</Typography>
              <div className={styles.item}>
                <Typography variant='body1' className='flex'>
                  <span className='flex fill' style={{ fontWeight: 'normal', whiteSpace: 'normal', wordBreak: 'break-word', wordWrap: 'break-word' }}>{bookingInput.insuranceName}</span>
                  <span className='bold' style={{ marginRight: 0 }}>{toCurrency(bookingInput.insuranceRate * totalVehicles * (rentalTime), currency, locale)}</span>
                </Typography>
                <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                  <span className='flex fill'>On {totalVehicles} Vehicles</span>
                  <span>{totalVehicles} x ({rentalTime} {RentalTimeText[bookingInput.rateTypeName as RATE_TYPES]}{rentalTime > 1 ? "s" : ""} x {toCurrency(bookingInput.insuranceRate, currency, locale)})</span>
                </Typography>
              </div>
            </>
          }
          {bookingInput.addonRequirements && bookingInput.addonRequirements.length > 0 &&
            <>
              <Typography variant='h4' style={{ margin: "15px 6px 6px 6px" }}>Add-ons</Typography>
              {bookingInput.addonRequirements.map((addon, index) => {
                let taxAmount = 0;
                if (!authUser?.customer?.taxExempted) {
                  if (addon.hasFixedRate) {
                    if (addon.tax && addon.tax.value) {
                      taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity);
                    }
                  } else {
                    if (addon.tax && addon.tax.value) {
                      taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity * rentalTime);
                    }
                  }
                }
                totalAmount += getAddonRate(addon, rentalTime) + taxAmount
                totalSubscriptionAmount += getAddonRate(addon, rentalTime) + taxAmount
                return (
                  <div className={styles.item}>
                    <Typography variant='body1' className='flex '>
                      <span className='flex fill'>{addon.displayName ? addon.displayName : addon.name}</span>
                      <span className='bold'>{toCurrency(getAddonRate(addon, rentalTime) + taxAmount, currency, locale)}</span>
                    </Typography>
                    <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                      <span className='flex fill'>Quantity: {addon.quantity}</span>
                      <span>{addon.quantity} x {addon.hasFixedRate ? toCurrency(addon.rate, currency, locale) : `(${rentalTime} ${RentalTimeText[bookingInput.rateTypeName as RATE_TYPES]}${rentalTime > 1 ? "s" : ""} x ${toCurrency(addon.rate, currency, locale)})`}</span>
                    </Typography>
                    <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                      <span className='flex fill'></span>
                      <span>
                        {getLocalizedTaxSyntex(country || "")}  : {toCurrency(taxAmount, currency, locale)}
                        {addon.tax && addon.tax.title && addon.tax.value && (
                          <IconButton style={{
                            color: "black",
                            padding: 0,
                            paddingRight: 5,
                            marginLeft: 10,
                            height: 12,
                            width: 12
                          }}>
                            <Tooltip
                              title={
                                <>
                                  <span>({`${addon.tax.title} ${addon.tax.value}%`})</span>
                                </>
                              }
                              arrow
                              placement={"right"}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      </span>
                    </Typography>
                  </div>
                )
              })}
            </>
          }
          
          {!organisation.closeGroupSharingEnabled && bookingInput.locationSurcharges && bookingInput.locationSurcharges.length > 0 &&
            <>
              <Typography variant='h4' style={{ margin: "15px 6px 6px 6px" }}>Location Surcharge</Typography>
              {bookingInput.locationSurcharges?.map((surcharge: ILocationTaxSurcharge, idx: number) => {
                let surchargeAmount = 0;
                let surchargeTaxAmount = 0;
                if (surcharge.type === "PERCENTAGE") {
                  surchargeAmount = Math.round(_totalRentalAmount * surcharge.value / 100);
                  surchargeTaxAmount = authUser?.customer?.taxExempted ? 0 : Math.round(surchargeAmount * surcharge.tax.value / 100);
                } else {
                  if (!authUser?.customer?.taxExempted) {
                    if (surcharge.tax && surcharge.tax.value) {
                      if (surcharge.rate === "PER_DAY") {
                        surchargeTaxAmount = (surcharge.value * surcharge.tax.value / 100) * totalVehicles * rentalTime;
                      } else if (surcharge.rate === "ONE_TIME") {
                        surchargeTaxAmount = (surcharge.value * surcharge.tax.value / 100) * totalVehicles;
                      }
                    }
                  }
                  if (surcharge.rate === "PER_DAY") {
                    surchargeAmount = surcharge.value * totalVehicles * rentalTime;
                  } else if (surcharge.rate === "ONE_TIME") {
                    surchargeAmount = surcharge.value * totalVehicles;
                  }
                }
                totalAmount += surchargeAmount + surchargeTaxAmount;
                totalSubscriptionAmount += surchargeAmount + surchargeTaxAmount;
                return (
                  <div className={styles.item}>
                    <Typography variant='body1' className='flex'>
                      <span className='flex fill'>{surcharge.name}</span>
                      <span className='bold'>{toCurrency(surchargeAmount + surchargeTaxAmount, currency, locale)}</span>
                    </Typography>
                    <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                      <span className='flex fill'>{surcharge.type !== "PERCENTAGE" ? `On ${totalVehicles} Vehicles` : `On Total Rental Amount : ${surcharge.value}%`}</span>
                      {surcharge.rate === "ONE_TIME" ? (
                        <span>{surcharge.type !== "PERCENTAGE" ? `${totalVehicles} x ${toCurrency(surcharge.value, currency, locale)}` : toCurrency(surchargeAmount, currency, locale)}</span>
                      ) : (
                        <span>{totalVehicles} x ({rentalTime} {RentalTimeText[bookingInput.rateTypeName as RATE_TYPES]}{rentalTime > 1 ? "s" : ""} x {toCurrency(surcharge.value, currency, locale)})</span>
                      )}
                    </Typography>
                    <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                      <span className='flex fill'></span>
                      <span>
                        {getLocalizedTaxSyntex(country || "")}  : {toCurrency(surchargeTaxAmount, currency, locale)}
                        {surcharge.tax && surcharge.tax.title && surcharge.tax.value && (
                          <IconButton style={{
                            color: "black",
                            padding: 0,
                            paddingRight: 5,
                            marginLeft: 10,
                            height: 12,
                            width: 12
                          }}>
                            <Tooltip
                              title={
                                <>
                                  <span>({`${surcharge.tax.title} ${surcharge.tax.value}%`})</span>
                                </>
                              }
                              arrow
                              placement={"right"}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      </span>
                    </Typography>
                  </div>
                )
              })}
            </>
          }
          </div>
          {!organisation.closeGroupSharingEnabled && props.oneWayRentalFee &&
            <>
              <Typography variant='h4' style={{ margin: "15px 6px 6px 6px" }}>One Way Fee</Typography>
              <div className={styles.item}>
                <Typography variant='body1' className='flex'>
                  <span className='flex fill' style={{ fontWeight: 'normal', whiteSpace: 'normal', wordBreak: 'break-word', wordWrap: 'break-word' }}>{'One Way Rental Charge'}</span>
                  <span className='bold' style={{ marginRight: 0 }}>{toCurrency(props.oneWayRentalFee.amount, currency, locale)}</span>
                </Typography>
                <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                  <span className='flex fill'>On {totalVehicles} Vehicles</span>
                  <span>{totalVehicles} x {toCurrency(props.oneWayRentalFee.amount * totalVehicles, currency, locale)}</span>
                </Typography>
                <Typography variant='body2' className='flex' style={{ opacity: 0.5 }}>
                  <span className='flex fill'></span>
                  <span>
                    {getLocalizedTaxSyntex(country || "")}  : {toCurrency((props.oneWayRentalFee.amount * totalVehicles * props.oneWayRentalFee.tax.value) / 100, currency, locale)}
                    {props.oneWayRentalFee.tax && props.oneWayRentalFee.tax.title && props.oneWayRentalFee.tax.value && (
                      <IconButton style={{
                        color: "black",
                        padding: 0,
                        paddingRight: 5,
                        marginLeft: 10,
                        height: 12,
                        width: 12
                      }}>
                        <Tooltip
                          title={
                            <>
                              <span>({`${props.oneWayRentalFee.tax.title} ${props.oneWayRentalFee.tax.value}%`})</span>
                            </>
                          }
                          arrow
                          placement={"right"}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </IconButton>
                    )}
                  </span>
                </Typography>
              </div>
            </>
          }
          {!organisation.closeGroupSharingEnabled && (
            <>
            {
              bookingInput.isSubscription ? (
                <div className={styles.item} style={{ marginTop: 30, padding: "18px 6px" }}>
                  <Typography variant='body1' className='flex bold'>
                    <span className='flex fill'>Total</span>
                    <span>{toCurrency(totalSubscriptionAmount, currency, locale)}</span>
                  </Typography>
                </div>
              ) : (
                <>
                  <div className={styles.item} style={{ marginTop: 30, padding: "18px 6px" }}>
                    <Typography variant='body1' className='flex bold'>
                      <span className='flex fill'>{`${bookingInput.rateTypeName === "weekly" ? "First Week Total" : bookingInput.rateTypeName === "monthly" ? "First Month Total" : "Total"}`}</span>
                      <span>{toCurrency(totalAmount, currency, locale)}</span>
                    </Typography>
                  </div>
                  <div className={styles.item} style={{ marginTop: 8, padding: "6px 6px" }}>
                    <Typography variant='body1' className='flex' style={{ fontWeight: "bolder" }}>
                      <span className='flex fill'>
                        {collectDeposit ? "Deposit" : "Deposit (Payable at the time of collection)"}
                      </span>
                      <span>{toCurrency(getDepositAmount(), currency, locale)}</span>
                    </Typography>
                  </div>
                </>
              )
            }
            </>
          )}
          
        </div>
      </div>
    </div>

  );
};