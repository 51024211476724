import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styles from "../index.module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Storage } from "aws-amplify";
import { useSelector } from 'react-redux';
import { DateTime as d } from "luxon";
import InfoIcon from '@material-ui/icons/Info';
import { IAppState } from '../../../../../store';
import { DATE_TYPE, getAddonIcon, getFeatureTypeIcon, getRetnalTaxAmount, getTaxDescription, getVehicleTypeIcon, isLongTermBooking, toCurrency } from '../../../../utils';
import { SimpleDateTimePicker } from '../../../../SimpleDateTimePicker/intex';
import { NuvvenDivider } from '../../../Summary/Components/Divider';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, Grid, IconButton, InputLabel, ListSubheader, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import { BILLING_CYCLE_NAME, IAddonRequirementInput, IAddons, IBookingCreateInput, ISubscription, ISubscriptionArgs, ISubscriptionInfo, ISubscriptionPricing, ISuggestedPricing, IVehicle } from '../../../../../reducers/bookings/types';
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_ADDON_TYPES } from '../../../../../graphql/addOns/getAvailableAddOnsQuery';
import { capitalizeFirstLetter, RATE_TYPES } from '../../../utils';
import FlatPickerBar from '../../../../FlatPicker';
import { getLocalizedDateFormat, getLocalizedTaxSyntex } from '../../../../../utils/localized.syntex';
import { AddonsSelection } from '../SelectAddOns/AddonsSelection';
import { DefaultAddons, IAddonRate, IAddonType } from '../SelectAddOns/types';

enum RentalTimeText {
  "hourly" = "hour",
  "daily" = "day",
  "weekly" = "week",
  "monthly" = "month"
}

interface IProps {
  bookingData: IBookingCreateInput;
  setBookingData: Dispatch<SetStateAction<IBookingCreateInput>>;
  onSubmit: (data: IBookingCreateInput) => void;
  selectedVehicle?: IVehicle;
  onSelectAvailabilityDate: (date: string) => void;
  onSelectSubscription: (selectedSubscription: ISubscriptionArgs, vehicle: IVehicle, startDate: string) => void
}

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 3000,
  cssEase: "linear"
};
const VehicleDetail: React.FC<IProps> = (props) => {

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const branches = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches);
  const authState = useSelector((state: IAppState) => state.authReducer);
  const authUser = useSelector((state: IAppState) => state.authReducer.user);
  const { locale, currency } = organisation;
  const { country } = website.organisation.address;
  const branch = branches.find((branch) => branch.id === props.bookingData.branchId);
  const [selectedPlan, setSelectedPlan] = useState<ISubscriptionInfo>()
  const [selectedPricing, setSelectedPricing] = useState<ISubscriptionPricing>()
  const [startDate, setStartDate] = useState<string>('')

  const [bookingDurationInDays, setBookingDurationInDays] = useState<any>();

  const taxes = branch && branch.addTaxOptIn ? branch.taxes.map((tax) => {
    return {
      code: tax.title,
      type: tax.type,
      rate: tax.rate,
      amount: tax.value,
      subdivisionOptIn: tax.subdivisionOptIn,
      subdivisionRate: tax.subdivisionRate,
      subdivisionType: tax.subdivisionType,
      subdivisionValue: tax.subdivisionValue
    }
  }) : [];

  let totalSubscriptionAmount = props.bookingData?.insuranceRate + 0;
  totalSubscriptionAmount += (props.bookingData.activeSubscription?.subscriptionAmount || 0) + (props.bookingData.activeSubscription?.enrollmentAmount || 0) + getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.bookingData.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1)

  const [loadAddons, { loading: addonsLoading, data: addonsData }] = useLazyQuery(GET_AVAILABLE_ADDON_TYPES, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (props.bookingData.branchId) {
      loadAddons({
        variables: {
          branchId: props.bookingData.branchId,
          startDate: props.bookingData.pickupDateTime,
          endDate: props.bookingData.dropoffDateTime,
          bookingId: props.bookingData.id || "",
          rateTypeName: props.bookingData.rateTypeName,
          businessCustomer: props.bookingData.businessCustomer ? props.bookingData.businessCustomer : authState.user?.businesscustomers?.id
        }
      });
    }
  }, []);

  useEffect(() => {
    if (props.bookingData) {
      let bookingDuration;
      const longTermBooking = isLongTermBooking(props.bookingData);
      if (longTermBooking) {
        switch (props.bookingData.rateTypeName) {
          case BILLING_CYCLE_NAME.WEEKLY:
            if (props.bookingData.isRecurringBilling && props.bookingData.billingFrequency) {
              bookingDuration = 7 * props.bookingData.billingFrequency
            } else {
              bookingDuration = 7
            }
            setBookingDurationInDays(bookingDuration);
            break;
          case BILLING_CYCLE_NAME.MONTHLY:
            if (props.bookingData.isRecurringBilling && props.bookingData.billingFrequency) {
              let idealEndDate = d.fromISO(props.bookingData.pickupDateTime || "").plus({ months: props.bookingData.billingFrequency }).toUTC().toISO();
              bookingDuration = d.fromISO(idealEndDate).diff(d.fromISO(props.bookingData.pickupDateTime), "day").days;
              setBookingDurationInDays(bookingDuration);
            }
            break;
          default:
            break;
        }
      } else {
        bookingDuration = Math.ceil(d.fromISO(props.bookingData.dropoffDateTime || "").diff(d.fromISO(props.bookingData.pickupDateTime || ""), ["days"]).days);
        setBookingDurationInDays(bookingDuration);
      }
    }
  }, [props.bookingData]);

  useEffect(() => {
    let monthlyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'monthly', subscription: "", duration: 0 };
    let weeklyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'weekly', subscription: "", duration: 0 };

    props.selectedVehicle?.subscriptions.forEach(subscription => {
      subscription.pricings.forEach(pricing => {
        if (subscription.subscription.rateTypeName === 'monthly') {
          if (pricing.subscriptionAmount < monthlyPricing.subscriptionAmount) {
            monthlyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'monthly',
              subscription: subscription.subscription.id || "",
              duration: pricing.duration
            };
          }
        } else if (subscription.subscription.rateTypeName === 'weekly') {
          if (pricing.subscriptionAmount < weeklyPricing.subscriptionAmount) {
            weeklyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'weekly',
              subscription: subscription.subscription.id || "",
              duration: pricing.duration
            };
          }
        }
      });
    });

    const pricing = monthlyPricing.subscriptionAmount < Infinity ? monthlyPricing : weeklyPricing;
    const subscription = props.selectedVehicle?.subscriptions.find(sub => sub.subscription.id === pricing.subscription)
    const selectedPricing = subscription?.pricings.find(p => p.duration === pricing.duration)
    setSelectedPlan(subscription)
    setSelectedPricing(selectedPricing)
    setStartDate(props.selectedVehicle?.availableFrom || "")
    if (subscription && pricing) {
      props.onSelectSubscription(
        {
          pricing: selectedPricing,
          subscription: subscription.subscription as any,
          deposit: subscription?.deposit
        },
        props.selectedVehicle as any,
        props.selectedVehicle?.availableFrom || "");
    }
  }, [props.selectedVehicle])

  const getAddonRate = (addon: IAddonRequirementInput, rentalTime: number) => {
    let rate = addon.rate * addon.quantity;
    if (!addon.hasFixedRate && rentalTime) {
      rate = rate * rentalTime;
    }
    return rate;
  };

  return (
    <Grid container item xs={12} spacing={2} >
      <Grid item xs={8}>
        <Grid item xs={12}>
          <div className='carauselContainer' >
            <Slider className="slickySlider" {...settings}>
              <div className='imageContainer' onClick={() => { }}>
                <img
                  className={"image"}
                  alt="loading.."
                  src={props.selectedVehicle?.imageUrl || `${getVehicleTypeIcon(capitalizeFirstLetter(props.selectedVehicle?.bodyType || ""))}`}
                />
              </div>
              <div className='imageContainer' onClick={() => { }}>
                <img
                  className={"image"}
                  alt="loading.."
                  src={props.selectedVehicle?.imageUrl || `${getVehicleTypeIcon(capitalizeFirstLetter(props.selectedVehicle?.bodyType || ""))}`}
                />
              </div>
            </Slider>
          </div>
        </Grid>
        <Box mt={8}></Box>
        <Grid item xs={12} container spacing={2} style={{ background: "#fff", padding: 10, borderRadius: 10 }}>
          <Grid item xs={4} style={{ textAlign: "center", borderRight: "1px solid lightgrey" }}>
            <div>
              <img
                style={{
                  height: "20px",
                  width: "20px",
                }}
                src={getFeatureTypeIcon("numberOfSeats")}
                alt={"numberOfSeats"}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 16 }}>{props.selectedVehicle?.numberOfSeats}</span>
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center", borderRight: "1px solid lightgrey" }}>
            <div>
              <img
                style={{
                  height: "20px",
                  width: "20px",
                }}
                src={getFeatureTypeIcon("numberOfDoors")}
                alt={"numberOfDoors"}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 16 }}>{props.selectedVehicle?.numberOfDoors}</span>
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <div >
              <img
                style={{
                  height: "20px",
                  width: "20px",
                }}
                src={getFeatureTypeIcon("transmission")}
                alt={"transmission"}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 16, textTransform: "uppercase" }}>{props.selectedVehicle?.transmission}</span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}><Box mt={5}></Box></Grid>
        <Grid item xs={12} container spacing={2}>
          <Accordion elevation={0} style={{ flexGrow: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content-vehicle-specs"
              id="panel-vehicle-specs"
            >
              <Grid item xs={12}><Typography variant={"h3"} style={{ fontSize: 16, color: "#000" }}>Vehicle Specification</Typography></Grid>
              <Box mt={2}></Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box style={{ flexGrow: 1 }}>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={6}>
                    <Grid item xs={12} container justify={"space-between"} alignItems={"flex-end"}>
                      <Typography variant='h4'>Fuel Type</Typography>
                      <Typography variant='h4'>{props.selectedVehicle?.fuelType}</Typography>
                    </Grid>
                    <Box mt={1.5} mb={1.5}><NuvvenDivider noMargin /></Box>
                    <Grid item xs={12} container justify={"space-between"}>
                      <Typography variant='h4'>Color</Typography>
                      <Typography variant='h4'>{props.selectedVehicle?.colour || "NA"}</Typography>
                    </Grid>
                    <Box mt={1.5} mb={1.5}><NuvvenDivider noMargin /></Box>
                    <Grid item xs={12} container justify={"space-between"}>
                      <Typography variant='h4'>Co2 Emission</Typography>
                      <Typography variant='h4'>{props.selectedVehicle?.co2Emissions || "NA"}</Typography>
                    </Grid>
                    <Box mt={1.5} mb={1.5}><NuvvenDivider noMargin /></Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} container justify={"space-between"} alignItems={"flex-end"}>
                      <Typography variant='h4'>Body Type</Typography>
                      <Typography variant='h4'>{props.selectedVehicle?.bodyType || "NA"}</Typography>
                    </Grid>
                    <Box mt={1.5} mb={1.5}><NuvvenDivider noMargin /></Box>
                    <Grid item xs={12} container justify={"space-between"}>
                      <Typography variant='h4'>Tank Capacity</Typography>
                      <Typography variant='h4'>{props.selectedVehicle?.cylinderCapacity || "NA"}</Typography>
                    </Grid>
                    <Box mt={1.5} mb={1.5}><NuvvenDivider noMargin /></Box>
                    <Grid item xs={12} container justify={"space-between"}>
                      <Typography variant='h4'>Interior Color</Typography>
                      <Typography variant='h4'>{props.selectedVehicle?.interiorColour || "NA"}</Typography>
                    </Grid>
                    <Box mt={1.5} mb={1.5}><NuvvenDivider noMargin /></Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}><Box mt={3}></Box></Grid>
        <Grid item xs={12}>
          {selectedPlan && (selectedPlan.subscription.addons?.length || selectedPlan.subscription.insurancePolicy) && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'h4'} style={{ fontSize: 16 }} color='primary' align='center'>Plan Inclusion</Typography>
              </Grid>
              {selectedPlan.subscription.addons?.map((addon: IAddons, index: number) => (
                <Grid key={index} item xs={4}>
                  <Box className={styles.included_plan_services}>
                    <img
                      src={getAddonIcon(
                        addon.name === DefaultAddons.VALET
                          ? DefaultAddons.VALET
                          : addon.name === DefaultAddons.ADDITIONAL_DRIVER
                            ? DefaultAddons.ADDITIONAL_DRIVER
                            : DefaultAddons.CUSTOM_ADDON
                      )}
                      alt={`${addon.name.toLowerCase()} addon`}
                      className={styles.included_service_icon}
                    />
                    <Typography variant='h3'>{addon.displayName}</Typography>
                    <Typography variant='caption'>{addon.description}</Typography>
                  </Box>
                </Grid>
              ))}

              {selectedPlan.subscription.insurancePolicy && (
                <Grid item xs={4}>
                  <Box className={styles.included_plan_services}>
                    <img
                      src={getAddonIcon("insurance")}
                      alt="insurance addon"
                      className={styles.included_service_icon}
                    />
                    <Typography variant='h3'>{selectedPlan.subscription.insurancePolicy.name}</Typography>
                    <Typography variant='caption'>{selectedPlan.subscription.insurancePolicy.description ? selectedPlan.subscription.insurancePolicy.description : "Description not available"}</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}><Box mt={3}></Box></Grid>
        {
          props.bookingData.pickupDateTime && props.bookingData.dropoffDateTime && (
            <Grid item xs={12}>
              <AddonsSelection
                onPrevious={() => { }}
                onSubmit={() => { }}
                oneWayRentalFee={undefined}
                setBookingData={props.setBookingData}
                bookingData={props.bookingData}
              />
            </Grid>
          )
        }
      </Grid>
      <Grid item xs={4} spacing={2}>
        <Grid item xs={12}>
          <Box style={{ background: "#fff", padding: 20, borderRadius: 10, boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px" }}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}><Typography style={{ fontWeight: 600, textAlign: "center", fontSize: 22 }} color='primary' variant='h4'>Customize Contract</Typography></Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 22, textTransform: "capitalize" }} variant='h2'>{`${props.selectedVehicle?.make} ${props.selectedVehicle?.model}`}</Typography>
              </Grid>

            </Grid>
            <Box mt={2.5}></Box>
            {
              selectedPricing && (
                <Grid item xs={12} container>
                  <Grid item xs={12} container alignItems='baseline'>
                    <Typography style={{ fontSize: 26 }} variant='h4'>{toCurrency(selectedPricing?.subscriptionAmount, currency, locale)} </Typography>
                    <Typography variant='caption' style={{ marginLeft: 5 }}> {selectedPlan?.subscription.rateTypeName === "monthly" ? "month" : "week"} + (taxes)</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}><Box mt={2.5}></Box></Grid>
                  </Grid>
                </Grid>
              )
            }
            {
              selectedPlan && (
                <Grid item xs={12}>
                  <InputLabel id="demo-simple-select-contract-plan-label">
                    <Typography style={{ fontSize: 16, color: "#000" }} variant='h3'>Select Plan</Typography>
                  </InputLabel>
                  <Box mt={0.5}></Box>
                  <FormControl fullWidth variant='outlined'>
                    <Select
                      labelId="demo-simple-select-contract-plan-label"
                      id="demo-simple-select-contract-plan-label-select"
                      variant='outlined'
                      value={selectedPlan?.subscription.id || ""}
                      onChange={(event: any) => {
                        const plan = props.selectedVehicle?.subscriptions.find(sub => sub.subscription.id === event.target.value)
                        if (plan) {
                          setSelectedPricing(undefined)
                          setSelectedPlan(plan)
                        }
                      }}
                    >
                      {
                        props.selectedVehicle?.subscriptions.map((subscription, index) => (
                          <MenuItem
                            value={subscription.subscription.id}
                            key={subscription.subscription.id}
                          >
                            {subscription.subscription.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Box mt={2}></Box>
                  <InputLabel id="demo-simple-select-contract-plan-label">
                    <Typography style={{ fontSize: 16, color: "#000" }} variant='h3'>Select Tenure</Typography>
                  </InputLabel>
                  <Box mt={0.5}></Box>
                  <FormControl fullWidth variant='outlined'>
                    <Select
                      labelId="demo-simple-select-contract-period-label"
                      id="demo-simple-select-contract-plan-period-select"
                      value={selectedPricing?.duration || ""}
                      onChange={(event: any) => {
                        const pricing = selectedPlan?.pricings.find(pricing => pricing.duration === event.target.value)
                        if (pricing && selectedPlan) {
                          setSelectedPricing(pricing)
                          props.onSelectSubscription(
                            {
                              pricing,
                              subscription: selectedPlan.subscription as any,
                              deposit: selectedPlan.deposit
                            },
                            props.selectedVehicle as any,
                            props.bookingData.pickupDateTime || startDate
                          );
                        }
                      }}

                    >
                      {
                        selectedPlan?.pricings.map((pricing, index) => (
                          <MenuItem
                            value={pricing.duration}
                            key={index}
                          >
                            {`${pricing.duration} ${selectedPlan.subscription.rateTypeName === "monthly" ? "Month's" : "Week's"} (${toCurrency(pricing.subscriptionAmount, currency, locale)}/${selectedPlan.subscription.rateTypeName === "monthly" ? "Month" : "Week"})`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )
            }
            <Box mt={2.5}></Box>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Typography variant={"h3"} style={{ fontSize: 16, color: "#000" }}>Available From</Typography>
                <Box mt={0.5}></Box>
                <Typography variant='h3' style={{ fontSize: 16 }} color={"primary"}>{getLocalizedDateFormat(country, props.selectedVehicle?.availableFrom || "", DATE_TYPE.CONDENSED)}</Typography>
              </Grid>
              {
                selectedPricing && (
                  <Grid item xs={6} >
                    <Typography variant={"h3"} style={{ fontSize: 16, color: "#000" }}>Rolling Contract</Typography>
                    <Box mt={0.5}></Box>
                    <Typography variant='h3' style={{ fontSize: 16 }} color={"primary"}>{selectedPricing?.duration} {selectedPlan?.subscription.rateTypeName === "monthly" ? "month's" : "week's"}</Typography>
                  </Grid>
                )
              }
            </Grid>
            <Box mt={2.5}></Box>
            <Grid item xs={12}><Typography variant='h3' style={{ fontSize: 16, color: "#000" }}>Tentative start date</Typography>
              <Box mt={0.5}></Box>
              <Grid item xs={12}>
                <FlatPickerBar
                  handleDateChange={(value: Date) => {
                    if (value) {
                      if (selectedPricing && selectedPlan) {
                        setStartDate(d.fromJSDate(value).toUTC().toISO())
                        props.onSelectSubscription(
                          {
                            pricing: selectedPricing,
                            subscription: selectedPlan.subscription as any,
                            deposit: selectedPlan.deposit
                          },
                          props.selectedVehicle as any,
                          d.fromJSDate(value).toUTC().toISO()
                        );
                      }
                    }
                  }}
                  identifier={"availabilityDate"}
                  name={"subscriptionDate"}
                  placeholderValue={"Select Date.."}
                  value={getLocalizedDateFormat(country, startDate, DATE_TYPE.CONDENSED)}
                  minDate={getLocalizedDateFormat(country, d.fromISO(props.selectedVehicle?.availableFrom || "").startOf('day').toUTC().toISO(), DATE_TYPE.CONDENSED)}
                  enableTime={false}
                  type={"availability-time-picker"}
                  country={country}
                />
              </Grid>
            </Grid>
            <Box mt={3}></Box>
            {
              props.bookingData.isSubscription && props.bookingData.activeSubscription && selectedPlan && selectedPricing && (
                <>
                  <Grid item xs={12}><Typography style={{ fontWeight: 400, textAlign: "center", fontSize: 20 }} color='primary' variant='h4'>SUMMARY OF YOUR CONTRACT</Typography></Grid>
                  <Box mt={3}></Box>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={5} container>
                      <Typography style={{ fontSize: 16 }} variant={"h5"}>{"Charges"}</Typography>
                    </Grid>
                    <Grid item xs={7} container justify={"flex-end"} spacing={1}>
                      <Grid item xs={12} container justify={"flex-end"}><Typography variant={"h4"}>{toCurrency(props.bookingData.activeSubscription.subscriptionAmount + (getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.bookingData?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1)), currency, locale)}</Typography></Grid>
                      <Grid item xs={12} container justify={"flex-end"}>
                        <Typography variant={"caption"}>{props.bookingData.activeSubscription.rateTypeName} {toCurrency(props.bookingData.activeSubscription.subscriptionAmount, currency, locale)}</Typography>
                      </Grid>
                      <Grid item xs={12} container justify={"flex-end"}>
                        <Typography variant={"caption"}>{"Enrollment Fee"} {toCurrency(props.bookingData.activeSubscription.enrollmentAmount, currency, locale)}</Typography>
                      </Grid>
                      <Grid item xs={12} container justify={"flex-end"}>
                        <Typography variant='caption'>
                          <IconButton
                            style={{
                              padding: 0,
                              paddingRight: 10
                            }}
                          >
                            <Tooltip
                              title={
                                <React.Fragment>
                                  {
                                    getTaxDescription(taxes, currency, locale).length > 0 &&
                                    getTaxDescription(taxes, currency, locale).map(title => {
                                      return (
                                        <>
                                          <span>({title})</span><br></br>
                                        </>
                                      )
                                    })
                                  }
                                </React.Fragment>
                              }
                              arrow
                              placement={"top"}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </IconButton>
                          {getLocalizedTaxSyntex(country || "")} : {toCurrency(getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.bookingData?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1), currency, locale)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {
                      props.bookingData.addonRequirements.length > 0 && (
                        <Grid item xs={12} container>
                          <Typography style={{ fontSize: 16 }} variant={"h5"}>Add-ons</Typography>
                        </Grid>
                      )
                    }
                    {
                      props.bookingData.addonRequirements.map((addon, index) => {
                        let taxAmount = 0;
                        if (!authUser?.customer?.taxExempted) {
                          if (addon.hasFixedRate) {
                            if (addon.tax && addon.tax.value) {
                              taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity);
                            }
                          } else {
                            if (addon.tax && addon.tax.value) {
                              taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity * 1);
                            }
                          }
                        }
                        totalSubscriptionAmount += getAddonRate(addon, 1) + taxAmount
                        return (
                          <Grid container item xs={12} spacing={1}>
                            <Grid container item xs={6}>
                              <Typography variant='h4'>{addon.displayName ? addon.displayName : addon.name}</Typography>
                            </Grid>
                            <Grid item xs={6} container justify={"flex-end"} spacing={1}>
                              <Typography variant="h4">{toCurrency(getAddonRate(addon, 1) + taxAmount, currency, locale)}</Typography>
                            </Grid>
                            <Grid item xs={6} container>
                              <Typography variant='caption'>Quantity: {addon.quantity}</Typography>
                            </Grid>
                            <Grid item xs={6} container justify='flex-end'>
                              <Typography variant="caption">{addon.quantity} x {addon.hasFixedRate ? toCurrency(addon.rate, currency, locale) : `(${1} ${RentalTimeText[props.bookingData.rateTypeName as RATE_TYPES]}${1 > 1 ? "s" : ""} x ${toCurrency(addon.rate, currency, locale)})`}</Typography>
                            </Grid>
                            <Grid item xs={12} container justify='flex-end' alignItems='center'>
                              {
                                addon.tax && addon.tax.title && addon.tax.value && (
                                  <IconButton
                                    style={{
                                      padding: 0,
                                      paddingRight: 10
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        <>
                                          <Typography>({`${addon.tax.title} ${addon.tax.value}%`})</Typography>
                                        </>
                                      }
                                      arrow
                                      placement={"right"}
                                    >
                                      <InfoIcon />
                                    </Tooltip>
                                  </IconButton>
                                )
                              }
                              {getLocalizedTaxSyntex(country || "")}  : {toCurrency(taxAmount, currency, locale)}
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                    {
                      props.bookingData.insurancePolicy && (
                        <>
                          <Grid item xs={12} container>
                            <Typography style={{ fontSize: 16 }} variant={"h5"}>Insurance</Typography>
                          </Grid>
                          <Grid container item xs={12} spacing={1}>
                            <Grid container item xs={6}>
                              <Typography variant='h4'>{props.bookingData.insuranceName}</Typography>
                            </Grid>
                            <Grid item xs={6} container justify={"flex-end"} spacing={1}>
                              <Typography variant="h4">{toCurrency(props.bookingData.insuranceRate * 1 * (1), currency, locale)}</Typography>
                            </Grid>
                          </Grid>
                        </>
                      )
                    }
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={6} container>
                        <Typography style={{ fontSize: 16 }} variant={"h5"}>Total</Typography>
                      </Grid>
                      <Grid item xs={6} container justify={"flex-end"}>
                        <Typography variant={"h4"}>{toCurrency(totalSubscriptionAmount, currency, locale)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box mt={2.5}></Box>
                  <Grid item xs={12}>
                    <Button
                      className={styles.next_step_btn}
                      onClick={() => props.onSubmit(props.bookingData)}
                      fullWidth
                    >
                      Continue To Personal Details
                    </Button>
                  </Grid>
                </>
              )
            }

          </Box>
        </Grid>
      </Grid >
    </Grid >
  )
}

export default VehicleDetail