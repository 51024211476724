import { gql } from "apollo-boost";

export const GET_AVAILABLE_VEHICLE_GROUPS = gql`
  query consumerAvailableVehicleGroups(
    $input: ConsumerAvailableVehicleGroupsInput!
  ) {
    consumerAvailableVehicleGroups(input: $input) {
      vehicleGroups {
        id
        name
        fleetType
        criteria {
          type
          values
        }
        vehicles {
          id
          bodyType
          make
          model
          year
          isGhostVehicle
          licencePlate
          transmission
          colour
          steering
          fuelType
          features
          wheelPlan
          cylinderCapacity
          numberOfSeats
          numberOfDoors
          imageUrl
          smartCarVehicleID
          isCarShare
          subscriptions {
            deposit
            subscription {
              isActive
              id
              name
              rateTypeName
              addons {
                id
                name
                displayName
              }
              insurancePolicy {
                id
                name
              }
            }
            pricings {
              duration
              enrollmentAmount
              mileage
              subscriptionAmount
              pricePerExtraMile
              unlimitedMileage
              swapsAllowed
            }
          }
        }
        deposit
        basePrices {
          rateTypeName
          rateTypeDuration
          rate
          longTerm
          mileageLimit
          pricePerExtraMile
          applicableAmount
          priceRuleAmount
          priceRuleName
          priceRuleOperator
          priceRuleValueType
          priceRuleTriggerType
          priceRule
          unlimitedMileage
        }
        isActivated
        imageUrl
        images{
          idx
          url
          default
        }
      }
      locationSurcharges {
        id
        title
        value
        rate
        type
        taxable
        tax {
          title
          value
        }
      }
      vehiclesDistance{
        vehicleId
        distance
      }
      oneWayRentalFee{
        amount
        taxable
        tax{
          title
          type
          value
        }
      }
    }
  }
`;

export const GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS = gql`
  query consumerExternalAvailableVehicleGroups(
    $input: ConsumerAvailableVehicleGroupsInput!
  ) {
    consumerExternalAvailableVehicleGroups(input: $input) {
      vehicleGroups {
        id
        name
        fleetType
        criteria {
          type
          values
        }
        vehicles {
          id
          bodyType
          make
          model
          year
          licencePlate
          transmission
          colour
          steering
          subscriptions {
            deposit
            subscription {
              isActive
              id
              name
              addons {
                id
                name
                displayName
              }
              insurancePolicy {
                id
                name
              }
            }
            pricings {
              duration
              enrollmentAmount
              mileage
              subscriptionAmount
              pricePerExtraMile
              unlimitedMileage
              swapsAllowed
            }
          }
          fuelType
          features
          wheelPlan
          isGhostVehicle
          cylinderCapacity
          numberOfSeats
          numberOfDoors
          imageUrl
          smartCarVehicleID
        }
        deposit
        basePrices {
          rateTypeName
          rateTypeDuration
          rate
          longTerm
          mileageLimit
          pricePerExtraMile
          applicableAmount
          priceRuleAmount
          priceRuleName
          priceRuleOperator
          priceRuleValueType
          priceRuleTriggerType
          priceRule
          unlimitedMileage
        }
        isActivated
        imageUrl
      }
      locationSurcharges {
        id
        title
        value
        rate
        type
        taxable
        tax {
          title
          value
        }
      }
      vehiclesDistance{
        vehicleId
        distance
      }
    }
  }
`;
