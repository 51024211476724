
import React from "react";
import { FilterSection, IFilter } from "../FilterSection/FilterSection";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface IProps {
  searchFilters?: { [key: string]: string[] }
  filters: IFilter[];
  onChange(filters: any): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    }
  })
);

export const FiltersView = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.filters.map((filter: IFilter, index: number) => {
        return (
          <FilterSection
            key={index}
            searchFilters={props.searchFilters}
            filter={filter}
            onChange={props.onChange}
          />
        );
      })}
    </div>
  );
};
