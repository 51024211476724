export const MuiStepper = {
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    flex: 1
  }
};
export const MuiStep = {
  root: {
    color: '#fff'
  }
};
export const MuiStepIcon = {
  root: {
    color: 'var(--stepper-color)',
    '&.MuiStepIcon-completed': {
      color: 'var(--primary-color)'
    },
    '&.MuiStepIcon-active': {
      color: 'var(--primary-color)'
    },
  },
  text: {
    color: '#fff',
    fill: '#fff',
    fontWeight: 700
  }
}
export const MuiStepLabel = {
  label: {
    color: 'var(--stepper-color)',
    fontSize: 'var(--font-medium)',
    fontWeight: 700,
    '&.MuiStepLabel-completed': {
      color: 'var(--primary-color)',
      fontWeight: '700 !important'
    },
    '&.MuiStepLabel-active': {
      color: 'var(--primary-color)',
      fontWeight: 700
    }
  },
}
