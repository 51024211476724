import {
  FormControl,
  MenuItem,
  withStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  Grid,
  RadioGroup,
  Radio
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import { Field, Form, Formik, setIn, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  IInsuranceAddon,
} from "../../../../reducers/bookings/types";
import { IAppState } from "../../../../store";
import { getAddonIcon, toCurrency } from "../../../utils";
import styles from './index.module.css';


const PlainInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: 0,
    },
    border: 0
  },
  input: {
    paddingLeft: 5,
    textAlign: 'center',
    borderRadius: 5,
    fontSize: 'var(--font-medium)',
    minHeight: 0,
    padding: '10px 20px',
    minWidth: 230,
    backgroundColor: '#fff',
    border: '1px solid var(--border-light)',
    '&:hover': {
      backgroundColor: 'var(--accent-shade)'
    },
    '&:focus': {
      borderRadius: 5,
      backgroundColor: '#fff'
    },
  },
}))(InputBase);

const ColoredCheckbox = withStyles({
  root: {
    color: 'var(--primary-color)',
    '&$checked': {
      color: 'var(--primary-color)',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface IInsuranceProps {
  insuranceAddOns: IInsuranceAddon[];
  selectedAddon?: string;
  onSelect(insuranceAddOn?: string): void;
  onCoiSelect(bool: boolean): void;
  isCoi: boolean | undefined;
}
export const InsuranceCard: React.FC<IInsuranceProps> = (props) => {

  const { insuranceAddOns } = props;
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const { locale, currency } = organisation;      //TODO from API

  const [selected, setSelected] = useState<boolean>(!!props.selectedAddon);
  const [values, setValues] = useState<Partial<IInsuranceAddon>>({ policyName: "", policyId: " " });
  const [insuranceType, setInsuranceType] = useState<string>("HOI");

  useEffect(() => {
    const insurance = props.insuranceAddOns.find((insuranceAddOn) => insuranceAddOn.policyId === props.selectedAddon);
    if (insurance) {
      setValues({ ...insurance });
    }
    setSelected(!!props.selectedAddon);
  }, [props.selectedAddon]);

  useEffect(() => {
    if (!selected && props.selectedAddon) {
      props.onSelect();
    }
  }, [selected]);
  

  useEffect(() => {
    if (props.isCoi) {
      setInsuranceType("COI")
      setSelected(false)
      setValues({
        policyName: "",
        policyId: ""
      })
    } else {
      setInsuranceType("HOI")
    }
  }, [props.isCoi])

  return (
    <>
      <div className={styles.addonIcon}>
        <img
          src={getAddonIcon("insurance")}
          alt="custom addon"
        />
      </div>
      <div className={styles.addonInfo}>
        <p>INSURANCE</p>
        <Formik className={styles.addonInfo} enableReinitialize initialValues={values} onSubmit={() => { }}>
          {(formikProps) => (
            <Form style={{ margin: '20px 0' }}>
              <FormControl variant="outlined" fullWidth>
                <Field
                  component={Select}
                  name={"insurance.policyId"}
                  fullWidth
                  type="text"
                  input={<PlainInput />}
                  label="Insurance"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={insuranceType === "COI"}
                  inputProps={{
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      props.onSelect(e.target.value);
                      setValues({
                        ...values,
                        policyId: e.target.value
                      });
                    },
                    value: values.policyId
                  }}
                >
                  <MenuItem value={" "} disabled>
                    Choose a policy
                  </MenuItem>
                  {insuranceAddOns.map(
                    (insuranceAddOn: any, index: number) => (
                      <MenuItem key={index} value={insuranceAddOn.policyId} style={{ whiteSpace: 'normal', wordBreak: 'break-word', wordWrap: 'break-word' }}>
                        <Typography variant="body1" style={{ fontWeight: 'normal', maxWidth: '300px', whiteSpace: 'normal', wordBreak: 'break-word', wordWrap: 'break-word', alignContent: "center" }}>
                          {insuranceAddOn.policyName} - Rate{" "}
                          {toCurrency(insuranceAddOn.rate, currency, locale)}
                        </Typography>
                      </MenuItem>
                    )
                  )}
                </Field>

              </FormControl>
              <Grid item xs={12} >
                <FormControl style={{ marginLeft: '20%' }}>
                  <RadioGroup
                    row
                    name="insuranceType"
                    value={insuranceType}
                    onChange={(event) => {
                      if (event.target.value === "COI") {
                        props.onCoiSelect(true)
                      } else {
                        props.onCoiSelect(false)
                      }
                    }}
                  >
                    <FormControlLabel
                      value="HOI"
                      control={<Radio />}
                      label={<Typography variant="body1">{"HOI"}</Typography>}
                    />
                    <FormControlLabel
                      value="COI"
                      control={<Radio />}
                      label={<Typography variant="body1">{"COI"}</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Form>
          )}
        </Formik>
        <div style={{ fontWeight: 'normal', maxWidth: '400px', whiteSpace: 'normal', wordBreak: 'break-word', wordWrap: 'break-word', marginBottom: "10px", alignContent: "center" }}>
          <span className="font-medium">{values?.description}</span>
        </div>
      </div>
      <div className="flex col-flex cross-center">
        {selected && <div className={styles.insuranceSelectButtonWrap}>
          <input
            className={styles.addonSelectCheck}
            type="checkbox"
            id='insurance-checkbox'
            onChange={() => {
              if (selected) {
                setSelected(!selected);
                setValues({
                  ...values,
                  policyId: ' ',
                  description: '',
                  policyName: '',
                  rateName: ''
                });
              }
            }}
            checked={selected}
          />
          <label htmlFor="insurance-checkbox" className={styles.buttonStyledCheck} />
        </div>}
      </div>
    </>

  );
};
