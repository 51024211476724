import React from "react"

export const ATV = {
  interior: {
    width: 612,
    height: 792,
    svg: (
      <>
        <g id="Layer_1">
          <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,271.2c0,0,22.3,4,35.6,25.7c0,0,4.6,7.9,4.5,16
			c0,0-0.8,12.5-9.4,27.2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,324.7h4.6c0,0,18.9,6.4,31.7,21.4l0.8,20.3
			c0,0,0.3,2.9-1.9,3.2h-35.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,271.2c0,0-22.3,4-35.6,25.7c0,0-4.6,7.9-4.5,16
			c0,0,0.8,12.5,9.4,27.2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,324.7h-4.6c0,0-18.9,6.4-31.7,21.4l-0.8,20.3
			c0,0-0.3,2.9,1.9,3.2h35.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,240.8c0,0,7.7-1.4,12.5,10.1l8.5,30.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,240.8c0,0-7.7-1.4-12.5,10.1l-8.5,30.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M288.5,268.8c0,0-1-3.5-4.5-3.5c0,0-3.1,0-3.3,2.4v16.8" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M323.7,268.8c0,0,1-3.5,4.5-3.5c0,0,3.1,0,3.3,2.4v16.8" />
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,283.5l15.6,11.7v20.4c0,0-9.2-5.9-16.2-5.2" />
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,283.5l-15.6,11.7v20.4c0,0,9.2-5.9,16.2-5.2" />
            </g>
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="306.1" y1="271.2" x2="306.1" y2="283.5" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="306.1" y1="309.4" x2="306.1" y2="324.7" />
            <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="284.6,282.1 284.6,285.3 271.4,299.5 269.7,299.5 		" />
            <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="327.7,282.1 327.7,285.3 340.9,299.5 342.6,299.5 		" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="267.6" y1="305.6" x2="272.3" y2="334.2" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="345" y1="305.6" x2="340.2" y2="334.2" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,347.2c0,0,11.5-0.9,24.8,8.9l0.5,2.8
			c0.2,1.1,0.1,2.2-0.5,3.1c-0.4,0.6-0.9,1.3-1.9,1.7h-23"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,347.2c0,0-11.5-0.9-24.8,8.9l-0.5,2.8
			c-0.2,1.1-0.1,2.2,0.5,3.1c0.4,0.6,0.9,1.3,1.9,1.7h23"/>
            <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="296.7" cy="356.3" rx="6" ry="3.4" />
            <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="315" cy="356.3" rx="6" ry="3.4" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,232.5c0,0,6.5,0.3,11.4,2.2v13.4" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,232.5c0,0-6.5,0.3-11.4,2.2v13.4" />
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M239,369.7l20.8-5.1c0,0,0.6-0.5,1.2-1.9c0.9-2.1,0.7-4.5-0.1-6.6
				c-0.5-1.1-1.1-2.2-1.7-2.4C259.1,353.6,246.1,352.2,239,369.7z"/>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M260.4,354.9l12.5-5.4c0,0,0.6-2.2,2.6-2.4c0,0,6.7-1.1,6.9,3.3
				c0,0,0.8,2.9-2.6,5.6c0,0-5.4,1-6.3-2.4l-11.9,4.8"/>
              <path fill="none" stroke="#020202" stroke-width="1.5" stroke-miterlimit="10" d="M269.8,349.9c0,0-10,3.6-18.2,1.7" />
              <circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="250.2" cy="351.5" r="1.5" />
            </g>
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M373.4,369.7l-20.8-5.1c0,0-0.6-0.5-1.2-1.9
				c-0.9-2.1-0.7-4.5,0.1-6.6c0.5-1.1,1.1-2.2,1.7-2.4C353.3,353.6,366.3,352.2,373.4,369.7z"/>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M352,354.9l-12.5-5.4c0,0-0.6-2.2-2.6-2.4c0,0-6.7-1.1-6.9,3.3
				c0,0-0.8,2.9,2.6,5.6c0,0,5.4,1,6.3-2.4l11.9,4.8"/>
              <path fill="none" stroke="#020202" stroke-width="1.5" stroke-miterlimit="10" d="M342.7,349.9c0,0,10,3.6,18.2,1.7" />
              <circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="362.2" cy="351.5" r="1.5" />
            </g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,326.8c0,0,18.8,3.9,20,26" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,326.8c0,0-18.8,3.9-20,26" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M269.1,367.5c0,0-11.3,46.3-4.3,73.5l0.3,3.3
			c0,0,1.3,48.9,15.2,73.9c0,0,8.3,16.6,25.8,17.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M343.2,367.5c0,0,11.3,46.3,4.3,73.5l-0.3,3.3
			c0,0-1.3,48.9-15.2,73.9c0,0-8.5,16.6-26,17.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M304.8,380.5h4.9c0,0,7.3,1.8,11.3,7.8c0,0,13,21.8,16.8,39.8v26.9
			c0,0-0.5,28.5-7.1,41.2c0,0-7.9,14.6-25.8,16.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306,380.5h-4.9c0,0-7.3,1.8-11.3,7.8c0,0-13,21.8-16.8,39.8v26.9
			c0,0,0.5,28.5,7.1,41.2c0,0,7.9,14.6,25.8,16.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M304.8,387.8h3.7c0,0,7.1,1.3,11,14.8c0,0,11.7,38.4,15.6,45.6v24.1
			c0,0-2.2,33.1-29.6,35.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306,387.8h-3.7c0,0-7.1,1.3-11,14.8c0,0-11.7,38.4-15.6,45.6v24.1
			c0,0,2.3,33.1,29.7,35.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M275.8,448.2c0,0,27.1,10.7,59.3,1.3" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="346.6" y1="442.2" x2="337.8" y2="428.1" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="264.7" y1="442.2" x2="273.5" y2="428.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M321.3,530.4c0,0-0.9,20.6-7.7,28.4h-14.6c0,0-6-8.7-8.1-28.4" />
          </g>
        </g>
        <g id="Layer_2">
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63.8" y1="261.5" x2="74.4" y2="254.5" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="245.8" x2="59.8" y2="257.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="40.7" y1="265.1" x2="29.4" y2="259.1" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="26.6" y1="272.1" x2="39.3" y2="270.4" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="52.8" y1="283.3" x2="51.7" y2="296" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.7" y1="293.5" x2="58.2" y2="282.5" />
              </g>
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63.8" y1="496.8" x2="74.4" y2="489.8" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="481.1" x2="59.8" y2="493" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="40.7" y1="500.4" x2="29.4" y2="494.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="26.6" y1="507.4" x2="39.3" y2="505.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="52.8" y1="518.6" x2="51.7" y2="531.3" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.7" y1="528.8" x2="58.2" y2="517.8" />
              </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M85.6,303.8l-6.9-10.3c-0.1-0.1-0.1-0.2-0.1-0.4
			c-1.7-9.1,0.6-13,0.6-13c2.4-4.6,9.2-8,11.1-8.9c0.3-0.1,0.5-0.4,0.5-0.8c0.3-5.5-1.6-12.3-1.8-13c0,0,0-0.1,0-0.1L87,244.2
			c-0.1-0.4,0.1-0.7,0.4-0.9h0c0.9-0.5,2-0.4,2.7,0.4L98,253c0,0,0.1,0.1,0.1,0.1c10.3,18,7.7,38.5,7.7,38.5L85.6,303.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M111,285.6c0,0,0.8-13.1-6.7-28.9c0,0-6.5-10.9-13.4-16.3
			c0,0-2.6-0.8-2.4,2.2"/>

            <rect x="71.3" y="261" transform="matrix(-0.4573 0.8893 -0.8893 -0.4573 357.3746 343.5254)" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="5.2" height="39.6" />

            <rect x="51" y="265.9" transform="matrix(-0.4573 0.8893 -0.8893 -0.4573 316.7334 346.4957)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.3" height="8" />
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="91,291.2 92.3,288.7 102.3,293.9 99.9,295.7 		" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M185.4,369h4.9l15.9-18.5c0.6-0.7,0.7-1.7,0.2-2.4
			c-2.5-4.1-10.9-17-20.9-24h-5.2c-1,0-1.9,0.7-2,1.7l-1.2,6c0,0-1.8,3.3-2.8,1.3l-1.2-4.7c-0.4-1.6-1-3.1-1.8-4.6L157,299
			l-10.8-13.1c-1.5-1.8-3.2-3.4-5.2-4.7l-13.9-9.3c-1.6-1.1-3.6-1.2-5.3-0.4c-1.9,0.9-4.4,2.5-6.6,5.3c0,0-4.3,11.2-8.3,14
			c0,0-10.9,8.2-22,13.2l-24.3,13.6c0,0-12.1,6.4-14.5,19c0,0-2.3,14.3-3,22v28.2c0,0,4.3,33.4,9.2,44.9l14,12l8.5,7.5l5.6-5.4
			c0,0-10.9-45.1-11.1-49.5L69.2,382l0.2-28.4c0,0,1.2-5.3,7.8-9.4l9-5.1c0,0,34.5-4.5,43.6-3.6l29.8,14.4c0,0,3.1,1.9,6.1-0.2
			l15.1,17C180.8,366.7,183.7,369,185.4,369z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M160,304.6v11.6c0,0.3-0.4,0.4-0.5,0.2
			c-2.1-3-10.1-14.1-14-20.8c-1.2-2.1-2.1-4.4-2.5-6.8c-0.4-2.2-0.8-4.9-0.8-6.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M185.5,345.6c0,0-2.9,3.5-3.9,6.9
			c-0.1,0.4-0.7,0.5-0.9,0.1l-8.3-15.1c-0.1-0.3-0.5-0.2-0.6,0l-6.1,12.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M172.9,328.2l-4.7,7.8c0,0-1.5,1.9-4.2,0.3
			c-0.3-0.2-0.5-0.4-0.7-0.7c-2.3-3.4-18.8-27.6-28.2-34.4c0,0-3.9-3.5-6.5-4.8c-0.6-0.3-1.4-0.3-2,0l-19.3,9.8c0,0-2.9,1.4-3.4-0.7
			l3.3-15.1"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M170.1,340.8c0,0,0,0-0.1,0.1
			c-1.6,1.8-4.5,1.9-6.2,0.2l-15.4-15c-0.1-0.1-0.2-0.1-0.2-0.2l-14.4-12.1c-0.5-0.4-1-0.7-1.6-0.9c-1.3-0.4-3.8-0.8-6.2-0.1
			l-24.3,7.4c-0.7,0.2-1.5,0.3-2.3,0.1c-1.1-0.3-2.8-0.8-4.6-2c-2.4-1.5-4-3.9-4.5-6.7c-0.7-3.8-1.7-9.3-1.7-9.3"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="124" y1="271.4" x2="128" y2="296.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M134.1,300.3l-3-18c-0.4-2.5-1.8-4.9-3.8-6.4
			c-0.8-0.6-1.7-1.2-2.9-1.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M157.1,334.4v-4.4l-22.5-22.2
			c0,0-0.1-0.1-0.2-0.2c-2.8-2.2-6.4-3-9.9-2.4c-3.5,0.6-8.4,1.6-11.1,2.7c0,0-9.6,3.3-14,12.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M99.4,319.8c0,0-2-5.9-2-11.7c0-2.1,0.6-4.2,1.7-6
			c1.4-2.4,3.3-6.1,3.2-8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.4,316c0,0,0.8,11.3-6.8,23.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.4,316l-21.4,14.9c-3,2.1-5.4,5.9-6,8.5
			c-0.1,0.3-0.2,0.6-0.2,0.9c0,0,1,42.6,5.4,60.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.8,308.4c0,0-9.3,1.8-16.8,6.9c0,0-10.1,5-18.1,20.1
			c-3,5.6-4.8,11.7-5.6,18c-0.9,7.4-1.9,18.9-0.8,29.6c0,0,5.6,40.1,11.4,55"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="186.4" cy="363.3" r="3.9" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="186.4" cy="363.3" r="2.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.8,352.6l3.5,0c0,0,9.9,3.9,10.8,13.2c0.2,1.6-0.8,3.3-2.4,3.7
			c-0.1,0-0.3,0.1-0.5,0.1h-7.4c-1.6,0-3.2-0.3-4.6-0.9c0,0,0,0,0,0c-1.6-0.7-2.1-2.3-2.1-4.1c0-0.6,0.1-1.1,0.5-1.3
			c0,0,1.3-2.6,1.3-3.9c0,0-0.5-3.3-0.4-5.7C215.6,353.1,216.2,352.6,216.8,352.6z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M214.7,355.9l-18.2-0.7c0,0-3.3-0.8-3.5-2.6
			c0,0-0.1-0.5-0.2-1.3c-0.3-3,4.3-3.8,4.9-0.9c0.1,0.4,0.1,0.8,0.1,1.3c0,0,0,0,0,0l18,0.8c0.1,0,0.2,0.1,0.1,0.2l-0.4,2.5
			C215.5,355.6,215.1,355.9,214.7,355.9z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M184.8,324.4c0,0,10,16.1,11.2,24.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M178.6,324.9c0,0,5.7,12.6,6.9,20.7
			c0,0,4.1,0.8,4.8,4.3c0,0,1.6,3.5,3.4,3.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M148.1,513.7c0,0,7.5-1.3,8.8-5.2l-3.4-54.3c0,0-4.3-21.7-4.5-29.7
			V396c0,0-2.9-5.9-10.5-7.9c0,0-5-0.6-9.7,0c0,0-2.9,1.1-5.9,0.4c0,0-2.6-1-4.1,0l-14.7,9.8c0,0-4.3,2.3-8.7-0.3l-22.9-13v-1.5
			h-3.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="119.4" y1="430.8" x2="124.9" y2="388.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128.7,388.8c0,0,3.6,31.1,9.8,49.4c0,0,5.1,14.4,15,17.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M72.6,385.1v6.4L94.1,404c0,0,0.1,0,0.3,0.1
			c6.2,1.9,12.9-0.1,17-5.1l9-10.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="69.3" y1="396.7" x2="85.6" y2="409.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M85.6,409.7c0,0,7.3-1,9.3-0.8l29,25.8c0,0,9.1,9.7,13.4,25.1
			c0,0,6.1,20.3,10.1,54.5c0,0-1.7,16.6-3.4,22c0,0-7-0.1-15.6-5.9l-7.2-6.3l-27.4-55.8c0,0-4-6.8-4.6-13.7L85.6,409.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M120.4,523c0,0-7.5-80-34.5-112.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.7,535.4l-4.2-23c0,0-4-41.5-13-59.9l-29.3-42.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M131.7,532.5v-6c0-3.7,1-7.3,3.1-10.3c1-1.4,2.2-2.7,3.7-3.7" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M61.3,439.3l1.4,13.5l11.4,22.5l7.5,49l-0.9,12L70,537.4
			l-4.6-11.2c0,0-5.3,2.2-8.3-0.5c0,0-5.1-2.7-7.8-17c0,0-2.4-17.6-3.3-28.2c-0.3-3.8-0.2-7.6,0.3-11.3c1.1-7.8,3.6-23.3,8.2-35.6
			L61.3,439.3z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="56.4,435.1 56.4,476.8 56.4,477.8 65.4,526.2 81.6,524.3 
					"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="73.8" y1="474.7" x2="56.4" y2="476.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="56.4" y1="458.1" x2="64.4" y2="456" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="77.4" y1="448.6" x2="88.7" y2="451.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="70.3" y1="467.7" x2="93.2" y2="467.7" />
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="78.1,501.6 77.2,495.1 101.4,484.2 104.1,489.9 		" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="81.6" cy="479" r="5.2" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="113" cy="520.4" r="2.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M110.1,502.1c0,0-0.3,9.9-1,12.8c0,0-2.7,8-16,19.7
			c0,0-14.6,12.1-20.1,21.1c-0.5,0.8-0.7,1.6-0.7,2.5l0,0c0,1.6,2.2,2.2,2.9,0.7c0,0,0,0,0-0.1c0,0,8-11.7,15.8-16.9l29.8-17.9
			L110.1,502.1z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="105.1,533.6 113.5,533.6 113.5,528.3 		" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="98.7" y1="485.5" x2="101.5" y2="491.4" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="95.8" y1="486.8" x2="98.7" y2="492.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.2" y1="488.1" x2="96" y2="494" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="90.6" y1="489.3" x2="93.5" y2="495.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.8" y1="490.6" x2="90.6" y2="496.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.7" y1="491.7" x2="87.5" y2="497.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="82.2" y1="492.9" x2="85" y2="498.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="79.5" y1="494.2" x2="82.4" y2="500.1" />
          </g>
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="550.2" y1="261.5" x2="539.5" y2="254.5" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.6" y1="245.8" x2="554.1" y2="257.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="573.3" y1="265.1" x2="584.6" y2="259.1" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="587.3" y1="272.1" x2="574.7" y2="270.4" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="561.2" y1="283.3" x2="562.3" y2="296" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.3" y1="293.5" x2="555.8" y2="282.5" />
              </g>
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="550.2" y1="496.8" x2="539.5" y2="489.8" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.6" y1="481.1" x2="554.1" y2="493" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="573.3" y1="500.4" x2="584.6" y2="494.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="587.3" y1="507.4" x2="574.7" y2="505.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="561.2" y1="518.6" x2="562.3" y2="531.3" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.3" y1="528.8" x2="555.8" y2="517.8" />
              </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M528.4,303.8l6.9-10.3c0.1-0.1,0.1-0.2,0.1-0.4
			c1.7-9.1-0.6-13-0.6-13c-2.4-4.6-9.2-8-11.1-8.9c-0.3-0.1-0.5-0.4-0.5-0.8c-0.3-5.5,1.6-12.3,1.8-13c0,0,0-0.1,0-0.1l1.9-13.1
			c0.1-0.4-0.1-0.7-0.4-0.9l0,0c-0.9-0.5-2-0.4-2.7,0.4L516,253c0,0-0.1,0.1-0.1,0.1c-10.3,18-7.7,38.5-7.7,38.5L528.4,303.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M502.9,285.6c0,0-0.8-13.1,6.7-28.9c0,0,6.5-10.9,13.4-16.3
			c0,0,2.6-0.8,2.4,2.2"/>

            <rect x="537.5" y="261" transform="matrix(0.4573 0.8893 -0.8893 0.4573 542.8383 -327.9317)" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="5.2" height="39.6" />

            <rect x="559.7" y="265.9" transform="matrix(0.4573 0.8893 -0.8893 0.4573 544.6509 -352.7328)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.3" height="8" />
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="522.9,291.2 521.7,288.7 511.7,293.9 514.1,295.7 		" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M428.6,369h-4.9l-15.9-18.5c-0.6-0.7-0.7-1.7-0.2-2.4
			c2.5-4.1,10.9-17,20.9-24h5.2c1,0,1.9,0.7,2,1.7l1.2,6c0,0,1.8,3.3,2.8,1.3l1.2-4.7c0.4-1.6,1-3.1,1.8-4.6L457,299l10.8-13.1
			c1.5-1.8,3.2-3.4,5.2-4.7l13.9-9.3c1.6-1.1,3.6-1.2,5.3-0.4c1.9,0.9,4.4,2.5,6.6,5.3c0,0,4.3,11.2,8.3,14c0,0,10.9,8.2,22,13.2
			l24.3,13.6c0,0,12.1,6.4,14.5,19c0,0,2.3,14.3,3,22v28.2c0,0-4.3,33.4-9.2,44.9l-14,12l-8.5,7.5l-5.6-5.4c0,0,10.9-45.1,11.1-49.5
			l0.2-14.1l-0.2-28.4c0,0-1.2-5.3-7.8-9.4l-9-5.1c0,0-34.5-4.5-43.6-3.6l-29.8,14.4c0,0-3.1,1.9-6.1-0.2l-15.1,17
			C433.1,366.7,430.2,369,428.6,369z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M454,304.6v11.6c0,0.3,0.4,0.4,0.5,0.2
			c2.1-3,10.1-14.1,14-20.8c1.2-2.1,2.1-4.4,2.5-6.8c0.4-2.2,0.8-4.9,0.8-6.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M428.5,345.6c0,0,2.9,3.5,3.9,6.9
			c0.1,0.4,0.7,0.5,0.9,0.1l8.3-15.1c0.1-0.3,0.5-0.2,0.6,0l6.1,12.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M441,328.2l4.7,7.8c0,0,1.5,1.9,4.2,0.3
			c0.3-0.2,0.5-0.4,0.7-0.7c2.3-3.4,18.8-27.6,28.2-34.4c0,0,3.9-3.5,6.5-4.8c0.6-0.3,1.4-0.3,2,0l19.3,9.8c0,0,2.9,1.4,3.4-0.7
			l-3.3-15.1"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M443.8,340.8c0,0,0,0,0.1,0.1
			c1.6,1.8,4.5,1.9,6.2,0.2l15.4-15c0.1-0.1,0.2-0.1,0.2-0.2l14.4-12.1c0.5-0.4,1-0.7,1.6-0.9c1.3-0.4,3.8-0.8,6.2-0.1l24.3,7.4
			c0.7,0.2,1.5,0.3,2.3,0.1c1.1-0.3,2.8-0.8,4.6-2c2.4-1.5,4-3.9,4.5-6.7c0.7-3.8,1.7-9.3,1.7-9.3"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="490" y1="271.4" x2="486" y2="296.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M479.9,300.3l3-18c0.4-2.5,1.8-4.9,3.8-6.4
			c0.8-0.6,1.7-1.2,2.9-1.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M456.9,334.4v-4.4l22.5-22.2
			c0,0,0.1-0.1,0.2-0.2c2.8-2.2,6.4-3,9.9-2.4c3.5,0.6,8.4,1.6,11.1,2.7c0,0,9.6,3.3,14,12.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M514.5,319.8c0,0,2-5.9,2-11.7c0-2.1-0.6-4.2-1.7-6
			c-1.4-2.4-3.3-6.1-3.2-8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.6,316c0,0-0.8,11.3,6.8,23.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.6,316l21.4,14.9c3,2.1,5.4,5.9,6,8.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0-1,42.6-5.4,60.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M524.2,308.4c0,0,9.3,1.8,16.8,6.9c0,0,10.1,5,18.1,20.1
			c3,5.6,4.8,11.7,5.6,18c0.9,7.4,1.9,18.9,0.8,29.6c0,0-5.6,40.1-11.4,55"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="427.6" cy="363.3" r="3.9" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="427.6" cy="363.3" r="2.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397.1,352.6l-3.5,0c0,0-9.9,3.9-10.8,13.2
			c-0.2,1.6,0.8,3.3,2.4,3.7c0.1,0,0.3,0.1,0.5,0.1h7.4c1.6,0,3.2-0.3,4.6-0.9c0,0,0,0,0,0c1.6-0.7,2.1-2.3,2.1-4.1
			c0-0.6-0.1-1.1-0.5-1.3c0,0-1.3-2.6-1.3-3.9c0,0,0.5-3.3,0.4-5.7C398.4,353.1,397.8,352.6,397.1,352.6z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M399.3,355.9l18.2-0.7c0,0,3.3-0.8,3.5-2.6
			c0,0,0.1-0.5,0.2-1.3c0.3-3-4.3-3.8-4.9-0.9c-0.1,0.4-0.1,0.8-0.1,1.3c0,0,0,0,0,0l-18,0.8c-0.1,0-0.2,0.1-0.1,0.2l0.4,2.5
			C398.5,355.6,398.9,355.9,399.3,355.9z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M429.1,324.4c0,0-10,16.1-11.2,24.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M435.4,324.9c0,0-5.7,12.6-6.9,20.7
			c0,0-4.1,0.8-4.8,4.3c0,0-1.6,3.5-3.4,3.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.9,513.7c0,0-7.5-1.3-8.8-5.2l3.4-54.3c0,0,4.3-21.7,4.5-29.7
			V396c0,0,2.9-5.9,10.5-7.9c0,0,5-0.6,9.7,0c0,0,2.9,1.1,5.9,0.4c0,0,2.6-1,4.1,0l14.7,9.8c0,0,4.3,2.3,8.7-0.3l22.9-13v-1.5h3.4"
            />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="494.5" y1="430.8" x2="489.1" y2="388.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,388.8c0,0-3.6,31.1-9.8,49.4c0,0-5.1,14.4-15,17.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M541.3,385.1v6.4L519.9,404c0,0-0.1,0-0.3,0.1
			c-6.2,1.9-12.9-0.1-17-5.1l-9-10.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="544.7" y1="396.7" x2="528.4" y2="409.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M528.4,409.7c0,0-7.3-1-9.3-0.8l-29,25.8c0,0-9.1,9.7-13.4,25.1
			c0,0-6.1,20.3-10.1,54.5c0,0,1.7,16.6,3.4,22c0,0,7-0.1,15.6-5.9l7.2-6.3l27.4-55.8c0,0,4-6.8,4.6-13.7L528.4,409.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M493.6,523c0,0,7.5-80,34.5-112.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M471.3,535.4l4.2-23c0,0,4-41.5,13-59.9l29.3-42.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M482.3,532.5v-6c0-3.7-1-7.3-3.1-10.3c-1-1.4-2.2-2.7-3.7-3.7" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M552.7,439.3l-1.4,13.5l-11.4,22.5l-7.5,49l0.9,12l10.7,1.1
			l4.6-11.2c0,0,5.3,2.2,8.3-0.5c0,0,5.1-2.7,7.8-17c0,0,2.4-17.6,3.3-28.2c0.3-3.8,0.2-7.6-0.3-11.3c-1.1-7.8-3.6-23.3-8.2-35.6
			L552.7,439.3z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="557.6,435.1 557.6,476.8 557.6,477.8 548.5,526.2 
			532.4,524.3 		"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="540.2" y1="474.7" x2="557.6" y2="476.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="557.6" y1="458.1" x2="549.6" y2="456" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="536.6" y1="448.6" x2="525.3" y2="451.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="543.7" y1="467.7" x2="520.8" y2="467.7" />
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="535.8,501.6 536.8,495.1 512.6,484.2 509.9,489.9 		" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="532.4" cy="479" r="5.2" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="500.9" cy="520.4" r="2.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.8,502.1c0,0,0.3,9.9,1,12.8c0,0,2.7,8,16,19.7
			c0,0,14.6,12.1,20.1,21.1c0.5,0.8,0.7,1.6,0.7,2.5l0,0c0,1.6-2.2,2.2-2.9,0.7c0,0,0,0,0-0.1c0,0-8-11.7-15.8-16.9L493.1,524
			L503.8,502.1z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="508.9,533.6 500.5,533.6 500.5,528.3 		" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="515.3" y1="485.5" x2="512.5" y2="491.4" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="518.1" y1="486.8" x2="515.3" y2="492.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="520.8" y1="488.1" x2="518" y2="494" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="523.3" y1="489.3" x2="520.5" y2="495.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="526.2" y1="490.6" x2="523.3" y2="496.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="529.3" y1="491.7" x2="526.4" y2="497.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="531.8" y1="492.9" x2="529" y2="498.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="534.4" y1="494.2" x2="531.6" y2="500.1" />
          </g>
        </g>
        <g id="Layer_3">
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M294.4,194.1h-11c0,0-1.3-0.1-1.7,1.2V206l-2.4,10.4h-4v3.2h4.9
				c0,0,1.5-0.4,1.9-1.9l2.6-11.8h9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="196.5" x2="294.7" y2="196.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="203.6" x2="294.7" y2="203.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="196" x2="284.6" y2="204.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M317.5,194.1h11c0,0,1.3-0.1,1.7,1.2V206l2.4,10.4h4v3.2h-4.9
				c0,0-1.5-0.4-1.9-1.9l-2.6-11.8h-9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="196.5" x2="317.3" y2="196.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="203.6" x2="317.3" y2="203.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="196" x2="327.3" y2="204.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M271.1,158.1c0,0-11.1,0.1-14.3,2.4v12.7c0,0,3.5,10.1,21.5,17.3"
              />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M256.7,162.9c0,0,7.5,6.3,15,9.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M256.5,168.3c0,0,8.3,6.7,15.8,9.5" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M339.6,158.1c0,0,11.1,0.1,14.3,2.4v12.7c0,0-3.5,10.1-21.5,17.3"
              />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M354,162.9c0,0-7.5,6.3-15,9.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M354.2,168.3c0,0-8.3,6.7-15.8,9.5" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M349.4,107.9c0.3-4.9-5.4-7.9-5.4-7.9c-0.8-14-8.2-28.9-8.2-28.9
			c-6.1-6.1-23.3-7.7-29.3-8.1l0-0.1c0,0-0.4,0-1.1,0c-0.7,0-1.1,0-1.1,0v0.1c-6,0.4-23.2,2-29.3,8.1c0,0-7.4,14.8-8.2,28.9
			c0,0-5.6,3.1-5.4,7.9l7.4,24.6c0,0-0.5,9.1,2,12.1l1.5,40.4c0,0,0.5,4.7,6.9,5.9l14,2.7h10.9h13l14-2.7c6.4-1.2,6.9-5.9,6.9-5.9
			l1.5-40.4c2.6-3,2-12.1,2-12.1L349.4,107.9z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M286.8,153.4v31c0,1.9-0.8,3.4-1.8,3.4h-1.8c-1,0-1.8-1.5-1.8-3.4
			v-50.2c0-0.4,0-0.7,0.1-1.1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.4,153.4v31c0,1.9,0.8,3.4,1.8,3.4h1.8c1,0,1.8-1.5,1.8-3.4
			v-50.2c0-0.4,0,2.2-0.1,1.9"/>
            <g>
              <rect x="290.3" y="177.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="4.2" height="9.8" />
              <rect x="287.4" y="180.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.9" height="3.7" />
            </g>
            <g>

              <rect x="317.4" y="177.5" transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 639.0573 364.6874)" fill="none" stroke="#000000" stroke-miterlimit="10" width="4.2" height="9.8" />

              <rect x="321.6" y="180.5" transform="matrix(-1 -4.518265e-11 4.518265e-11 -1 646.0372 364.6874)" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.9" height="3.7" />
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M317.8,147v65.9c0,5.4-4.2,9.8-9.4,9.8h-4.4
			c-5.2,0-9.4-4.4-9.4-9.8V147"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M274.1,73.4c0,0-4,43.6-2.8,56.2l10,4.6" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M336.9,73.4c0,0,4,43.6,2.8,56.2l-10.9,4.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M277.6,148c0-15.1,12.3-27.4,27.4-27.4s27.4,12.3,27.4,27.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M323.9,133c0,0-3.3-5.6-18.4-6.3c-15.1,0.7-18.4,6.3-18.4,6.3
			c-3.4,9.9-2.4,17.9-1.7,21c0.1,0.5,0.7,0.5,0.9,0.1l3.3-7.1h15.9h15.9l3.3,7.1c0.2,0.4,0.8,0.4,0.9-0.1
			C326.2,150.9,327.3,143,323.9,133z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M290.3,130.8c0,0,4.2,9.1,4.2,15.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321.3,130.8c0,0-4.2,9.1-4.2,15.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M271.1,144.7c0,0,5.7,3.2,6.9,3.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M339.8,144.7c0,0-6.7,3.2-7.9,3.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M266.6,101.1c0,0,7.8,3.1,17.9,5.2c0.5,0.1,0.9,0.6,0.9,1.1v3.3
			c0,0.6-0.6,1.1-1.2,0.9c-3.3-0.7-12.8-2.9-17.7-5.6L266.6,101.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M344.5,101.1c0,0-7.8,3.1-17.9,5.2c-0.5,0.1-0.9,0.6-0.9,1.1v3.3
			c0,0.6,0.6,1.1,1.2,0.9c3.3-0.7,12.8-2.9,17.7-5.6L344.5,101.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,75.4h15.4v12.4c0,0-5.1,5.9-15.5,8.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,75.4h-15.4v12.4c0,0,5.1,5.9,15.5,8.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="306.5" y1="63.1" x2="306.5" y2="75" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="306.3" y1="96.8" x2="306.3" y2="120.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,29.4c0,0,13.1-0.7,18.3,9.7l4.4,0c0,0,4.5,4.6,8.2,4.6
			c0,0,2.5,0,6,0v11.8c0,0-22.7,1.1-23.8,9.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,29.4c0,0-13.1-0.7-18.3,9.7l-4.4,0c0,0-4.5,4.6-8.2,4.6
			c0,0-2.5,0-6,0v11.8c0,0,22.7,1.1,23.8,9.2"/>
            <g>
              <rect x="251.2" y="47.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M250,55.1L250,55.1c-0.6,0-1.1-0.5-1.1-1.1v-7.5
				c0-0.6,0.5-1.1,1.1-1.1l0,0c0.6,0,1.1,0.5,1.1,1.1V54C251.2,54.6,250.7,55.1,250,55.1z"/>
            </g>
            <g>

              <rect x="342.9" y="47.3" transform="matrix(-1 -4.490864e-11 4.490864e-11 -1 703.872 99.7683)" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M362.1,55.1L362.1,55.1c0.6,0,1.1-0.5,1.1-1.1v-7.5
				c0-0.6-0.5-1.1-1.1-1.1l0,0c-0.6,0-1.1,0.5-1.1,1.1V54C361,54.6,361.5,55.1,362.1,55.1z"/>
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M287.8,39.1c0,0,6.7,14,18.8,13.4" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.6,39.1c0,0-6.7,14-18.8,13.4" />
            </g>
            <g>

              <ellipse transform="matrix(0.9143 -0.4051 0.4051 0.9143 25.3885 147.89)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="362.1" cy="14" rx="11.4" ry="8.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="351.4" y1="14.3" x2="331.3" y2="41.2" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M351.9,19c0,0-9.3,11.1-14.9,24.6" />
            </g>
            <g>

              <ellipse transform="matrix(0.4051 -0.9143 0.9143 0.4051 136.1323 237.1267)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="250.3" cy="14" rx="8.1" ry="11.4" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="261" y1="14.3" x2="281.1" y2="41.2" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M260.5,19c0,0,9.3,11.1,14.9,24.6" />
            </g>
          </g>
        </g>
        <g id="Layer_4">
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M297.4,744.4h-11c0,0-1.3-0.1-1.7,1.2v10.7l-2.4,10.4h-4v3.2h4.9
				c0,0,1.5-0.4,1.9-1.9l2.6-11.8h9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="746.9" x2="297.7" y2="746.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="753.9" x2="297.7" y2="753.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="746.4" x2="287.6" y2="754.4" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.5,744.4h11c0,0,1.3-0.1,1.7,1.2v10.7l2.4,10.4h4v3.2h-4.9
				c0,0-1.5-0.4-1.9-1.9l-2.6-11.8h-9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="746.9" x2="320.3" y2="746.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="753.9" x2="320.3" y2="753.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="746.4" x2="330.3" y2="754.4" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.8,708h-16.2c0,0-3.8,0.2-6.7,1.2c-1.6,0.6-2.7,2.2-2.7,4v10.5
			c0,0.6,0.1,1.1,0.3,1.6c1.2,2.9,6.1,12.6,19.2,15.9c0.4,0.1,0.7,0.1,1.1,0.1h16.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="275.2" y1="694" x2="275.2" y2="708" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="275.7" y1="695.7" x2="286.4" y2="695.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.1,708h16.2c0,0,3.8,0.2,6.7,1.2c1.6,0.6,2.7,2.2,2.7,4v10.5
			c0,0.6-0.1,1.1-0.3,1.6c-1.2,2.9-6.1,12.6-19.2,15.9c-0.4,0.1-0.7,0.1-1.1,0.1h-16.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="342.7" y1="694" x2="342.7" y2="708" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="342.2" y1="695.7" x2="331.5" y2="695.7" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M292.5,733.4c0,0-19-3.1-32-8.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.3,715.6h-14.8c0,0-8.3-0.8-9.9,9.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M325.3,733.4c0,0,19-3.1,32-8.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.6,715.6h14.8c0,0,8.3-0.8,9.9,9.1" />
            </g>
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="339.3" cy="713.6" r="7.5" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="338.2" cy="710.4" r="5.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.7,697.1v70.1c0,2.5-1.4,4.6-3.2,4.6H301c-1.7,0-3.2-2-3.2-4.6
			v-70.1"/>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M293.5,733.5c-4.6,0-8.3-5.5-8.3-12.4c0-6.8,3.7-12.4,8.3-12.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="293.5" y1="708.3" x2="293.5" y2="733.9" />
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="294" y1="713" x2="297.8" y2="713" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="293.8" y1="729.5" x2="297.6" y2="729.5" />
            </g>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.5,733.5c4.6,0,8.3-5.5,8.3-12.4c0-6.8-3.7-12.4-8.3-12.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324.5" y1="708.3" x2="324.5" y2="733.9" />
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324" y1="713" x2="320.1" y2="713" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324.2" y1="729.5" x2="320.4" y2="729.5" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M315.3,677.9h-11.5c-0.8,0-1.5-0.7-1.5-1.5v-0.5
			c0-0.8,0.7-1.5,1.5-1.5h11.5c0.8,0,1.5,0.7,1.5,1.5v0.5C316.8,677.2,316.2,677.9,315.3,677.9z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.9" y1="704.9" x2="298.1" y2="704.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.6" y1="708.7" x2="297.8" y2="708.7" />
            <g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287" y1="673.1" x2="286.4" y2="714.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.5" y1="673.8" x2="292.5" y2="673.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287" y1="676.7" x2="293" y2="676.7" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="680.1" x2="293.8" y2="680.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="683.5" x2="294.9" y2="683.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="687.5" x2="295.7" y2="687.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="692" x2="297" y2="692" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.9" y1="695.9" x2="297.4" y2="695.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.7" y1="700.6" x2="297.8" y2="700.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="288.3" y1="671.3" x2="291.9" y2="671.3" />
            </g>
            <g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="673.1" x2="331.8" y2="714.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.7" y1="673.8" x2="325.7" y2="673.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="676.7" x2="325.2" y2="676.7" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.1" y1="680.1" x2="324.4" y2="680.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.1" y1="683.5" x2="323.3" y2="683.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="687.5" x2="322.5" y2="687.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="692" x2="321.2" y2="692" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.3" y1="695.9" x2="320.8" y2="695.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.5" y1="700.6" x2="320.4" y2="700.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="329.9" y1="671.3" x2="326.3" y2="671.3" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M291,668.1c0,0,8.6,30.3,8.6,46.4h9.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M327.2,668.1c0,0-8.6,30.3-8.6,46.4H309" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321.3,616.5c0,0,15.5,2.5,19.8,8.1c0,0,4.7,4,6.2,25" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309,640.8c0,0,31.4,0.8,43.7,13.6l-7.4,39.3h-2.4
				c0,0-6.9-16.7-16.7-26.9h-16.8"/>
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M297,616.5c0,0-15.5,2.5-19.8,8.1c0,0-4.7,4-6.2,25" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309,640.8c0,0-31,0.8-43.3,13.6l7.4,39.3h2.4
				c0,0,6.9-16.7,16.7-26.9h17.2"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M273.4,648.8c0,0,0.9-19.4,35.9-18.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M344.5,648.8c0,0-0.5-19.4-35.6-18.9" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M266.1,655.8c0,0,16.8-9.7,33-10.9l3.2,21.9" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M300.3,655.8H289c0,0-6.9-0.3-8.5-6.2" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M352.7,655.8c0,0-16.8-9.7-33-10.9l-3.2,21.9" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.5,655.8h11.3c0,0,6.9-0.3,8.5-6.2" />
            </g>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="299.5" y1="644.9" x2="319.4" y2="644.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M348.4,674.9c0,0-10.2-6.3-21.4-7.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M269.6,675.5c0,0,10.2-6.3,21.4-7.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M308.7,579.9c0,0,11.7-1.4,19.4,10h3.6v0.9h2.8c0,0,0.4,3.5,5.7,3.4
			l6,0v11.1c0,0-1,0.9-5,1.9c0,0-3.4,0.9-8.1,1.1s-4.7,0.3-4.7,0.3l0,0c0,0-9.4,11.5-13.8,13.6c0,0-1.8,0.8-5.6,0.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309.2,579.9c0,0-11.7-1.4-19.4,10h-3.6v0.9h-2.8
			c0,0-0.4,3.5-5.7,3.4l-6,0v11.1c0,0,1,0.9,5,1.9c0,0,3.4,0.9,8.1,1.1c4.7,0.3,4.7,0.3,4.7,0.3l0,0c0,0,9.4,11.5,13.8,13.6
			c0,0,1.9,0.8,5.6,0.8"/>
            <g>
              <rect x="253.6" y="597.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M252.5,605.5L252.5,605.5c-0.6,0-1.1-0.5-1.1-1.1v-7.5
				c0-0.6,0.5-1.1,1.1-1.1l0,0c0.6,0,1.1,0.5,1.1,1.1v7.5C253.6,605,253.1,605.5,252.5,605.5z"/>
            </g>
            <g>

              <rect x="346.4" y="597.7" transform="matrix(-1 -4.485846e-11 4.485846e-11 -1 710.8186 1200.564)" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.6,605.5L365.6,605.5c0.6,0,1.1-0.5,1.1-1.1v-7.5
				c0-0.6-0.5-1.1-1.1-1.1l0,0c-0.6,0-1.1,0.5-1.1,1.1v7.5C364.5,605,365,605.5,365.6,605.5z"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M272.1,598.7c0,0,30.9-2,73.8,0" />
            <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="300.9" cy="590" rx="5.9" ry="4.1" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="290.6" cy="592.8" r="1.3" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="328" cy="592.8" r="1.3" />
            <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="316.1" cy="590" rx="5.9" ry="4.1" />
          </g>
        </g>
      </>
    )
  },
  exterior: {
    width: 859,
    height: 1032,
    svg: (
        <>
  <g id="Layer_6" data-name="Layer 6">
    <g>
      <path d="M425.8,331.8c13.7.9,22.5,4.8,22.5,4.8l35,16,2-5-28.1-13.6c-6.9-3.4-14.2-6.2-21.8-7.4-4.3-.7-7.5-.9-9.1-1h-1c-1.5,0-4.8.3-9.1,1-7.6,1.2-14.9,4-21.8,7.4l-28.1,13.6,2,5,35-16s8.8-3.9,22.5-4.8Z" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <path d="M505.2,342.6c0-11.6,9.2-21,20.5-21s20.5,9.4,20.5,21v65c0,11.6-9.2,21-20.5,21s-20.5-9.4-20.5-21v-65.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
        <rect x="538.2" y="350.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="509.2" y="350.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="538.2" y="333.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="509.2" y="334.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="538.2" y="367.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="509.2" y="368.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="538.2" y="385.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="509.2" y="386.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="538.2" y="403.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="523.2" y="349.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="523.2" y="332.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="523.2" y="366.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="523.2" y="384.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="523.2" y="402.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      </g>
      <g>
        <path d="M345.2,342.6c0-11.6-9.2-21-20.5-21s-20.5,9.4-20.5,21v65c0,11.6,9.2,21,20.5,21s20.5-9.4,20.5-21v-65.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
        <rect x="308.2" y="350.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="337.2" y="350.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="308.2" y="333.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="337.2" y="334.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="308.2" y="367.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="337.2" y="368.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="308.2" y="385.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="337.2" y="386.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="308.2" y="403.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="323.2" y="349.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="323.2" y="332.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="323.2" y="366.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="323.2" y="384.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="323.2" y="402.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      </g>
      <g>
        <path d="M495.8,613.7c0-11.6,12-21,26.8-21s26.8,9.4,26.8,21v65c0,11.6-12,21-26.8,21s-26.8-9.4-26.8-21v-65.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
        <rect x="534.2" y="621.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="506.2" y="637.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="534.2" y="604.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="506.2" y="621.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="534.2" y="638.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="506.2" y="655.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="534.2" y="656.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="506.2" y="673.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="534.2" y="674.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="519.2" y="620.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="519.2" y="638.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="519.2" y="603.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="519.2" y="655.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="519.2" y="673.7" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      </g>
      <g>
        <path d="M353.2,613.7c0-11.6-12-21-26.8-21s-26.8,9.4-26.8,21v65c0,11.6,12,21,26.8,21s26.8-9.4,26.8-21v-65.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
        <rect x="310.7" y="621.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="338.7" y="637.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="310.7" y="604.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="338.7" y="621.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="310.7" y="638.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="338.7" y="655.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="310.7" y="656.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="338.7" y="673.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="310.7" y="674.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="325.7" y="620.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="325.7" y="638.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="325.7" y="603.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="325.7" y="655.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <rect x="325.7" y="673.6" width="4" height="13" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      </g>
      <g>
        <polyline points="455.2 353.5 462.8 353.5 462.8 360.7" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <path d="M463.2,356.6s33,7,42,12v4s-8-6-42-13v-3Z" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      </g>
      <g>
        <polyline points="395.8 353.6 387.8 353.5 387.8 360.7" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
        <path d="M387.2,356.6s-33,7-42,12v4s8-6,42-13v-3Z" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      </g>
      <path d="M430.2,421.6c0,1.9-2,3.5-4.5,3.5s-4.5-1.6-4.5-3.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      <path d="M442.2,432.6s-8.7-5.6-15.5-7.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      <path d="M409.8,432.6s8.7-5.6,15.5-7.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".5"/>
      <path d="M438.2,331.5l-1,9.2s-12.1-3-22.6,0l-1.4-9s11-2.3,25-.2Z" fill="#fff" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M437.2,336.6s4.4,3.2,8.9,7.3c9.9,9,16.8,19.1,21.1,31.7s8.7,27.3,11,42c0,0,1.8,9.6,1.8,22.7s-2.1,20.7-6.1,30.3-2.2,4.7-3.6,7l-7.1,11" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M459.2,358.6s13,7,25,9c0,0,15,3,23,16,0,0,13,21,18,41l1,21s-2,11-11,13l-32,1-6.9,4.2s4.4-17,3.6-28.1c0,0-.8-28.1-10.1-51.8l-4.1-11.5c-.4-1.2-.9-2.3-1.5-3.4l-5.2-10.3h.2Z" fill="#fff" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M414.2,336.6s-4.4,3.2-8.9,7.3c-9.9,9-16.8,19.1-21.1,31.7s-8.7,27.3-11,42c0,0-1.8,9.6-1.8,22.7s2.1,20.7,6.1,30.3,2.2,4.7,3.6,7l5.1,11" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M392.2,358.6s-13,7-25,9c0,0-15,3-23,16,0,0-13,21-18,41l-1,21s2,11,11,13l32,1,6.9,4.2s-4.4-17-3.6-28.1c0,0,.8-28.1,10.1-51.8l4.1-11.5c.4-1.2.9-2.3,1.5-3.4l5.2-10.3h-.2Z" fill="#fcfafa" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M424.8,666.6h.5c23.9-1.7,38-12,38-12v-164l-10-51c-2-6-9-7-9-7l-18.5-1h0c0-.1-1,0-1,0h-1l-18.5,1s-7,1-9,7l-10,51v164s14.1,10.3,38,11.9h.5Z" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M437.2,340.6s29,68,26,150" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M414.2,340.6s-31,68-28,150" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M495.3,505.6h0c0,1.7-1.3,3-3,3h-29v-6h29c1.7,0,3,1.3,3,3Z" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M386.3,502.7v6h-29c-1.7,0-3-1.3-3-3h0c0-1.7,1.3-3,3-3h29Z" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M463.5,539.1h44.6c1.6,0,3.3.2,4.8.8s3.9,1.9,4.9,4,.5,1.8.5,2.7v107.6c0,1.2-.3,2.3-1,3.3-1.1,1.8-3.3,4.1-7,4.1h-24c-3.6,0-7.1-.6-10.5-1.9-3.9-1.4-9.1-3.4-12.5-5.1l.2-115.6h0Z" fill="#fff" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M386,539.1h-44.6c-1.6,0-3.3.2-4.8.8s-3.9,1.9-4.9,4-.5,1.8-.5,2.7v107.6c0,1.2.3,2.3,1,3.3,1.1,1.8,3.3,4.1,7,4.1h24c3.6,0,7.1-.6,10.5-1.9,3.9-1.4,9.1-3.4,12.5-5.1l-.2-115.6h0Z" fill="#fff" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <path d="M455.2,646.9c.6,0,1-.7,1-1.3v-123.6c0-2.5-.9-4.8-2.5-6.7-2.5-2.9-6.9-6.7-13-8.2h-31.8c-6.1,1.5-10.5,5.4-13,8.2-1.6,1.8-2.5,4.2-2.5,6.7v123.6c0,.6.4,1.2,1,1.3,7.4,1.8,30.5,2.7,30.5,2.7,0,0,23.1-.9,30.5-2.7h-.2Z" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      <g>
        <path d="M458.9,657.3v34.1c.1,2,0,9.5-7.1,11.9-.8.3-1.7.4-2.6.5-4,.4-18.1,1.9-24.9,1.9" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
        <path d="M391.3,657.3v34.1c-.1,2,0,9.5,7.1,11.9.8.3,1.7.4,2.6.5,4,.4,18.1,1.9,24.9,1.9" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      </g>
      <g>
        <path d="M453.7,660.2v27.8c0,1.6,0,7.8-6,9.7s-1.5.4-2.2.4c-3.3.3-15.2,1.5-21,1.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
        <path d="M396.8,660.2v27.8c0,1.6,0,7.8,6,9.7s1.5.4,2.2.4c3.3.3,15.2,1.5,21,1.5" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width=".8"/>
      </g>
      <path d="M466.2,421.6" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="425.8 416.8 442.2 416.8 466.2 425.7 466.2 421.6 442.9 412.6 425.8 412.6 408.4 412.6 384.8 421.6 384.8 425.7 409 416.8 425.8 416.8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <path d="M466.2,421.6s7.4-2.5,14.5-4.5,16.2-2.1,24,.6c3.3,1.1,6.7,2.5,9.7,4.4s.8.9.8,1.6v26.4s0,1.6-3,1.6-46-25-46-25v-5h0Z" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <path d="M515.2,441.6s-28.6-16-49-17" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M508.4,438.1s-.2.8-.7,1.6c-.8,1.4-2.6,2-4.2,1.4l-37.3-14.8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M384.2,421.6s-7.4-2.5-14.5-4.5-16.2-2.1-24,.6c-3.3,1.1-6.7,2.5-9.7,4.4s-.8.9-.8,1.6v26.4s0,1.6,3,1.6,46-25,46-25v-5h0Z" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <path d="M335.2,441.6s28.6-16,49-17" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M342.1,438.1s.2.8.7,1.6c.8,1.4,2.6,2,4.2,1.4l37.3-14.8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <line x1="455.2" y1="421.6" x2="396.2" y2="421.6" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M411.2,412.6c0-3.9,6.5-7,14.5-7s14.5,3.1,14.5,7" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="425.2" y1="339.6" x2="425.2" y2="405.6" fill="none" stroke="#000" stroke-miterlimit="10"/>
    </g>
  </g>
  <g id="Layer_10" data-name="Layer 10">
    <g>
      <g>
        <circle cx="104.6" cy="621.9" r="51" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <circle cx="104.6" cy="621.9" r="20" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="145.4" y="604.4" width="4" height="14" transform="translate(-116 40.3) rotate(-11.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="140.7" y="627.6" width="14" height="4" transform="translate(-496.8 682.6) rotate(-81.5)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="134.7" y="643.7" width="14" height="4" transform="translate(-461.1 374.5) rotate(-53.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="121.7" y="656.8" width="14" height="4" transform="translate(-341.4 179.7) rotate(-33.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="104.7" y="662.9" width="14" height="4" transform="translate(-79.1 18.2) rotate(-6.9)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="91" y="657" width="4" height="14" transform="translate(-570.2 565.8) rotate(-73.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="74.2" y="648.7" width="4" height="14" transform="translate(-463.3 275.5) rotate(-48.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="62.7" y="634" width="4" height="14" transform="translate(-342.2 141.5) rotate(-33.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="57.7" y="615.9" width="4" height="14" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="56.7" y="601.9" width="14" height="4" transform="translate(-491.2 357.1) rotate(-60)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="68.7" y="585.9" width="14" height="4" transform="translate(-393.6 225.7) rotate(-45)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="84.1" y="578.1" width="14" height="4" transform="translate(-110.9 28.7) rotate(-11.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="103.6" y="574.9" width="14" height="4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="126.5" y="575.9" width="4" height="14" transform="translate(-429.2 370.3) rotate(-56.7)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="138.7" y="589.1" width="4" height="14" transform="translate(-351.5 231.6) rotate(-40.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g>
        <circle cx="106.6" cy="350.9" r="51" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <circle cx="106.6" cy="350.9" r="20" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="147.4" y="333.6" width="4" height="14" transform="translate(-63.3 35.5) rotate(-11.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="142.6" y="356.7" width="14" height="4" transform="translate(-227.3 453.6) rotate(-81.5)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="136.7" y="372.8" width="14" height="4" transform="translate(-242.9 266.7) rotate(-53.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="123.7" y="385.8" width="14" height="4" transform="translate(-191.9 136) rotate(-33.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="106.7" y="391.9" width="14" height="4" transform="translate(-46.5 16.5) rotate(-6.9)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="92.9" y="385.9" width="4" height="14" transform="translate(-308.8 373) rotate(-73.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="76.4" y="377.7" width="4" height="14" transform="translate(-260.7 186.8) rotate(-48.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="64.7" y="363" width="4" height="14" transform="translate(-192.7 97.8) rotate(-33.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="59.7" y="344.9" width="4" height="14" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="58.6" y="331" width="14" height="4" transform="translate(-255.6 223.3) rotate(-60)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="70.7" y="315" width="14" height="4" transform="translate(-201.4 147.8) rotate(-45)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="86.4" y="307.1" width="14" height="4" transform="translate(-58.3 24) rotate(-11.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="105.6" y="303.9" width="14" height="4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="128.6" y="304.9" width="4" height="14" transform="translate(-201.8 249.8) rotate(-56.7)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="140.7" y="318" width="4" height="14" transform="translate(-176.1 168.9) rotate(-40.2)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <polyline points="183.2 558.4 148.7 584.1 141.6 586.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="174.6 550.9 140.6 574.9 136.6 581.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M182.6,560.9l-13-9s-4-1-3,2l13,9s4,0,3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M176.6,564.9l-13-9s-4-1-3,2l13,9s4,0,3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M172.6,568.9l-13-9s-4-1-3,2l13,9s4,0,3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M167.6,572.9l-13-9s-4-1-3,2l13,9s4,0,3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M162.6,576.9l-13-9s-4-1-3,2l13,9s4,0,3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M157.6,580.9l-13-9s-4-1-3,2l13,9s4,0,3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M131.6,521.1s-4-2.2-4-5.2v-81.6h-6.2v82.1s.2,8.5,10.2,12" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="122.6" y1="434.4" x2="122.6" y2="399.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="126.6" y1="434.4" x2="126.6" y2="397.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <circle cx="138.1" cy="501.4" r="6.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M146.1,512.6v-11.2c0-4.4-3.6-8-8-8s-8,3.6-8,8v6.5h-2.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="143.6 504.4 143.6 512.6 137.3 507.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="134.6 580.9 139.8 536.9 136.6 536.9 131.6 578.6 134.6 580.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="127.6" y1="525.9" x2="114.6" y2="571.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="126.6" y1="525.9" x2="112.6" y2="571.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="124.6" y1="523" x2="106.6" y2="570.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="123.6" y1="521.9" x2="103.6" y2="570.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="127.6" y1="434.9" x2="147.7" y2="428.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="153.2" y1="423.4" x2="126.6" y2="431.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="126.6" y1="424.8" x2="142.6" y2="387" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="129.1 431.1 150.6 378.9 181.8 406.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="184.6 402.5 154.6 376.9 184.6 361.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="193.1" y1="361.8" x2="156.5" y2="361.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="191.1" y1="350.8" x2="157.1" y2="350.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="171.9" y1="361.9" x2="152.7" y2="372.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="188.2" y1="396" x2="185.6" y2="361.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="192" y1="361.9" x2="193" y2="378.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="148.1" y1="383.5" x2="156.5" y2="384" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="163.1" y1="384.5" x2="187.1" y2="385" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="159.1" y1="380.5" x2="187.1" y2="381" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M182.6,328.9v-16.5l57,67.5,2,27-59,66.7-36-33.7s0-.3-.2-.7c-1.9-6.8,1.8-13.9,8.4-16.5,8.7-3.4,23.9-10.3,28.9-18.8,0,0,23-28-1-75h-.1Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M241.6,406.9s19,16,20,39c0,0,2,12-11,35,0,0-7.3,17.7-12.1,32.4-2.6,7.9-3.9,16.2-4.1,24.5l-1.8,89.1s0,2-2,2c0,0-3.8,5-4,4-.8-4.2-7.4-36.2-9.4-46.1-.5-2.3-.6-3.9-.9-5.8l-7.7-80.1-5.2-50.7,38.2-43.3h0Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M226.6,632.9s0,5-2,7c0,0-.4.5-1.1,1-6,3.9-13.3-3.5-9.3-9.5s.3-.4.4-.5c0,0,2-22-6-42,0,0-.6-1.7-1.9-4.3-4.9-10.1-12.3-18.7-21.4-25.2l-13.9-9.9c-2.7-1.9-5.5-3.5-8.5-4.8-4.9-2.1-13.1-5.4-21.3-7.9h-7v11.1h-3v-35.1h18l53.8-62.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="249.4" y1="483.8" x2="209.1" y2="506" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="253" y1="476.4" x2="208.1" y2="500.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M227.6,495.9s-10,21-7,55c0,0,9,55,8,80" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M224.3,621.1s-8.3-55.9-43.5-75.1c0,0-13.2-6.3-29.2-10.5-8.1-2.1-14-9.3-14.2-17.7,0-1.5,0-3.2.3-5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M220.6,603.9s-10-59-26-71c0,0-7-6-18-10l-27-10" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="200.5" y1="539.6" x2="188.2" y2="467.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M188.2,319s21.4,34.8,27.4,49.8c0,0,9,18,4,41,0,0-7.9,24.6-32,43.4s-9.9,6.3-15.6,7.7-1.5.4-2.3.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M186.2,336.6s19.4,24.3,25.4,51.3c0,0,4,20-15,33,0,0-20,16-50,18" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M218.7,642l2.9,35.9s1,4-5,3c0,0-2,0-2-4l-8.1-92.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="220.6" y1="665.9" x2="213.6" y2="665.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="221.6" y1="677.9" x2="214.6" y2="677.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M253.8,396.2s-4.2-2.4-7.2-3.4h-6l-.5-7.2s9.6,2.2,16.6,7.2" fill="#fff" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="185.1 334.1 169.7 334.1 169.7 350.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="186.9 338.3 185.1 338.3 169.7 338.3" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="185.1" y1="350.8" x2="185.1" y2="338.3" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="184.6" y1="347.8" x2="169.7" y2="347.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="169.6" y1="347.9" x2="155.2" y2="335.2" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="169.6" y1="342.9" x2="152.2" y2="327.2" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="183.9 331.4 167.3 331.4 167.3 340.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="182.6 328.9 168 328.9 167.3 331.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M170.6,328.9s-1-11-5-17c0,0-1.5-2.6-5.1-4.6s-5.4-2-8.3-2.1c-4.1,0-12.9,0-21.6.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M167.6,331.9s-2-13-6-18c0,0-4-5-13-5l-12.4.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M252.6,400.9v21.4s.6,2.1,1.7,3.9,1.9,1.4,3,1l24.2-8.6c1.4-.5,2.5-1.8,2.7-3.3s.4-4.1-.7-6.3l-17-17.1s-1.8-1.7-4.9-.9c0,0-1.5.2-3.4,1-3.5,1.5-5.8,5-5.8,8.9h.2Z" fill="#fff" stroke="#050505" stroke-miterlimit="10"/>
    </g>
    <g>
      <g>
        <circle cx="749.5" cy="621.9" r="51" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <circle cx="749.5" cy="621.9" r="20" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="700" y="610.5" width="14" height="4" transform="translate(-31.2 1187.1) rotate(-78.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="704.8" y="623.1" width="4" height="14" transform="translate(-85.4 111.4) rotate(-8.5)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="710.8" y="639" width="4" height="14" transform="translate(-244.6 552.4) rotate(-36.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="723.7" y="651.9" width="4" height="14" transform="translate(-223.9 902.1) rotate(-56.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="740.5" y="657.9" width="4" height="14" transform="translate(-6.7 1322.2) rotate(-83.1)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="754.7" y="661.5" width="14" height="4" transform="translate(-156.3 242.1) rotate(-16.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="770.5" y="654.8" width="14" height="4" transform="translate(-239.8 685.4) rotate(-41.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="782.5" y="638.7" width="14" height="4" transform="translate(-180 947.1) rotate(-56.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="792.5" y="615.9" width="4" height="14" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="788.5" y="597" width="4" height="14" transform="translate(-196.1 476.2) rotate(-30)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="776.5" y="580.9" width="4" height="14" transform="translate(-187.7 722.7) rotate(-45)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="760" y="573.4" width="4" height="14" transform="translate(44.6 1215.2) rotate(-78.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="736.5" y="574.9" width="14" height="4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="718.5" y="581" width="14" height="4" transform="translate(-200.9 494) rotate(-33.3)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="706.5" y="593.6" width="14" height="4" transform="translate(-201.9 756.1) rotate(-49.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g>
        <circle cx="747.5" cy="350.9" r="51" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <circle cx="747.5" cy="350.9" r="20" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="698" y="339.3" width="14" height="4" transform="translate(233.3 966.6) rotate(-78.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="702.8" y="352.1" width="4" height="14" transform="translate(-45.3 108.1) rotate(-8.5)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="708.8" y="367.9" width="4" height="14" transform="translate(-83.4 497.7) rotate(-36.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="721.6" y="380.9" width="4" height="14" transform="translate(1.5 778.5) rotate(-56.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="738.5" y="386.9" width="4" height="14" transform="translate(260.5 1081.7) rotate(-83.1)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="752.6" y="390.5" width="14" height="4" transform="translate(-79.9 230.4) rotate(-16.4)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="768.7" y="383.7" width="14" height="4" transform="translate(-59.6 615.2) rotate(-41.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="780.4" y="367.8" width="14" height="4" transform="translate(45.3 823.6) rotate(-56.6)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="790.5" y="344.9" width="4" height="14" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="786.5" y="325.9" width="4" height="14" transform="translate(-60.8 438.9) rotate(-30)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="774.5" y="310" width="4" height="14" transform="translate(3.3 641.9) rotate(-45)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="758.2" y="302.5" width="4" height="14" transform="translate(309 995.1) rotate(-78.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="734.5" y="303.9" width="14" height="4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
        <rect x="716.5" y="310" width="14" height="4" transform="translate(-52.5 448.5) rotate(-33.3)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
        <rect x="704.3" y="322.6" width="14" height="4" transform="translate(4.3 658.4) rotate(-49.8)" fill="none" stroke="#050505" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <polyline points="671 558.4 705.4 584.1 712.5 586.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="679.5 550.9 713.5 574.9 717.5 581.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M671.5,560.9l13-9s4-1,3,2l-13,9s-4,0-3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M677.5,564.9l13-9s4-1,3,2l-13,9s-4,0-3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M681.5,568.9l13-9s4-1,3,2l-13,9s-4,0-3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M686.5,572.9l13-9s4-1,3,2l-13,9s-4,0-3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M691.5,576.9l13-9s4-1,3,2l-13,9s-4,0-3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M696.5,580.9l13-9s4-1,3,2l-13,9s-4,0-3-2Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M722.5,521.1s4-2.2,4-5.2v-81.6h6.2v82.1s-.2,8.5-10.2,12" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="731.5" y1="434.4" x2="731.5" y2="399.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="727.5" y1="434.4" x2="727.5" y2="397.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <circle cx="716" cy="501.4" r="6.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M708,512.6v-11.2c0-4.4,3.6-8,8-8s8,3.6,8,8v6.5h2.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="710.5 504.4 710.5 512.6 716.8 507.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="719.5 580.9 714.3 536.9 717.5 536.9 722.5 578.6 719.5 580.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="726.5" y1="525.9" x2="739.5" y2="571.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="727.5" y1="525.9" x2="741.5" y2="571.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="729.6" y1="523" x2="747.5" y2="570.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="730.5" y1="521.9" x2="750.5" y2="570.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="726.5" y1="434.9" x2="706.5" y2="428.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="701" y1="423.4" x2="727.5" y2="431.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="727.5" y1="424.8" x2="711.6" y2="387" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="725 431.1 703.5 378.9 672.3 406.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="669.6 402.5 699.5 376.9 669.5 361.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="661.1" y1="361.8" x2="697.7" y2="361.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="663" y1="350.8" x2="697" y2="350.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="682.3" y1="361.9" x2="701.5" y2="372.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="665.9" y1="396" x2="668.5" y2="361.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="662.2" y1="361.9" x2="661.2" y2="378.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="706" y1="383.5" x2="697.7" y2="384" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="691" y1="384.5" x2="667" y2="385" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="695" y1="380.5" x2="667" y2="381" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M671.5,328.9v-16.5l-57,67.5-2,27,59,66.7,36-33.7s0-.3.2-.7c1.9-6.8-1.8-13.9-8.4-16.5-8.7-3.4-23.9-10.3-28.9-18.8,0,0-23-28,1-75h.1Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M612.5,406.9s-19,16-20,39c0,0-2,12,11,35,0,0,7.3,17.7,12.1,32.4s3.9,16.2,4.1,24.5l1.8,89.1s0,2,2,2c0,0,3.8,5,4,4,.8-4.2,7.4-36.2,9.4-46.1.5-2.3.6-3.9.9-5.8l7.7-80.1,5.2-50.7-38.2-43.3h0Z" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M627.5,632.9s0,5,2,7c0,0,.4.5,1.1,1,6,3.9,13.3-3.5,9.3-9.5s-.3-.4-.4-.5c0,0-2-22,6-42,0,0,.6-1.7,1.9-4.3,4.9-10.1,12.3-18.7,21.4-25.2l13.9-9.9c2.7-1.9,5.5-3.5,8.5-4.8,4.9-2.1,13.1-5.4,21.3-7.9h7v11.1h3v-35.1h-18l-53.8-62.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="604.7" y1="483.8" x2="645" y2="506" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="601" y1="476.4" x2="646" y2="500.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M626.5,495.9s10,21,7,55c0,0-9,55-8,80" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M629.9,621.1s8.3-55.9,43.5-75.1c0,0,13.2-6.3,29.2-10.5,8.1-2.1,14-9.3,14.2-17.7s0-3.2-.3-5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M633.5,603.9s10-59,26-71c0,0,7-6,18-10l27-10" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="653.6" y1="539.6" x2="665.9" y2="467.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M665.9,319s-21.4,34.8-27.4,49.8c0,0-9,18-4,41,0,0,7.9,24.6,32,43.4,4.6,3.6,9.9,6.3,15.6,7.7s1.5.4,2.3.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M667.9,336.6s-19.4,24.3-25.4,51.3c0,0-4,20,15,33,0,0,20,16,50,18" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M635.4,642l-2.9,35.9s-1,4,5,3c0,0,2,0,2-4l8.1-92.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="633.5" y1="665.9" x2="640.5" y2="665.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="632.5" y1="677.9" x2="639.5" y2="677.9" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M601.5,400.9v21.4s-.6,2.1-1.7,3.9-1.9,1.4-3,1l-24.2-8.6c-1.4-.5-2.5-1.8-2.7-3.3s-.4-4.1.7-6.3l17-17.1s1.8-1.7,4.9-.9c0,0,1.5.2,3.4,1,3.5,1.5,5.8,5,5.8,8.9h-.2Z" fill="#fff" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M600.4,396.2s4.2-2.4,7.2-3.4h6l.5-7.2s-9.6,2.2-16.6,7.2" fill="#fff" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="669 334.1 684.4 334.1 684.4 350.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="667.2 338.3 669 338.3 684.4 338.3" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="669" y1="350.8" x2="669" y2="338.3" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="669.5" y1="347.8" x2="684.4" y2="347.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="684.5" y1="347.9" x2="699" y2="335.2" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <line x1="684.5" y1="342.9" x2="702" y2="327.2" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="670.2 331.4 686.8 331.4 686.8 340.8" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <polyline points="671.5 328.9 686.2 328.9 686.8 331.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M683.5,328.9s1-11,5-17c0,0,1.5-2.6,5.1-4.6s5.4-2,8.3-2.1c4.1,0,12.9,0,21.6.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M686.5,331.9s2-13,6-18c0,0,4-5,13-5l12.4.5" fill="none" stroke="#050505" stroke-miterlimit="10"/>
    </g>
  </g>
  <g id="Layer_12" data-name="Layer 12">
    <g>
      <rect x="420.1" y="136.9" width="3.9" height="54" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="439.5 167.9 402.5 167.9 400.5 162.9 442.5 162.9 439.5 167.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="401.5 136.9 409.5 162.9 404.5 162.9 395.7 136.3 401.5 136.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="410.5 136.9 405.5 148.9 406.5 152.9 413.5 136.9 410.5 136.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="431.5 136.9 436.5 148.9 435.5 152.9 428.5 136.9 431.5 136.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="440.7 136.9 432.7 162.9 437.7 162.9 446.5 136.3 440.7 136.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <path d="M429,137.9c0,1.9-3.9,3.5-8.2,3.5s-7.8-1.6-7.8-3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M429,137.9c0,1.9,0,5-8,5s-8-3.1-8-5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M429,141.9c0,1.9-3.9,3.5-8.2,3.5s-7.8-1.6-7.8-3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M429,141.9c0,1.9,0,5-8,5s-8-3.1-8-5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M429,145.9c0,1.9-3.9,3.5-8.2,3.5s-7.8-1.6-7.8-3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M429,145.9c0,1.9,0,5-8,5s-8-3.1-8-5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M429,150.9c0,1.9-3.9,3.5-8.2,3.5s-7.8-1.6-7.8-3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M429,150.9c0,1.9,0,5-8,5s-8-3.1-8-5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M429,155.9c0,1.9-3.9,3.5-8.2,3.5s-7.8-1.6-7.8-3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M429,155.9c0,1.9,0,5-8,5s-8-3.1-8-5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <rect x="409.5" y="196.9" width="23" height="1" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="409.5" y="190.9" width="23" height="2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="404.5 192.9 340.5 223.5 340.5 227.1 405.4 195.9 404.5 192.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="437.5 192.9 501.5 223.5 501.5 227.1 436.6 195.9 437.5 192.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <polyline points="348.5 219.9 357.3 205.9 370.2 171.4 376.5 149.6 380.8 134" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <polyline points="363.5 212.4 369.5 199.9 385.5 168.9 391.5 149.9 395.8 136" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <polyline points="493.8 219.9 485 205.9 472.1 171.4 465.8 149.6 461.5 134" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <polyline points="478.8 212.4 472.8 199.9 456.8 168.9 450.8 149.9 446.5 136" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <circle cx="374.5" cy="142.9" r="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <circle cx="389.5" cy="145.9" r="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <line x1="340.5" y1="174.9" x2="368.5" y2="174.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="398" y1="205.9" x2="404.5" y2="205.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="417" y1="205.9" x2="424.5" y2="205.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="404.5" y1="173.9" x2="420.5" y2="173.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="423.5" y1="173.9" x2="438" y2="173.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="437.5" y1="205.9" x2="443.5" y2="205.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="473.5" y1="174.9" x2="502" y2="174.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <circle cx="467.5" cy="142.9" r="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <circle cx="452.5" cy="145.9" r="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <path d="M480.5,150.9v4.3s-19.6.6-28.3,4.2-3,1.8-3.9,3.3c-2.4,3.8-7.9,13.7-11.2,28.9-.4,2.1-.7,4.2-.7,6.3h-4c0-3.5.4-7.1,1.2-10.5,1.5-6.5,4.4-16.6,8.1-23.9s4.7-6.5,8.4-8.3c5.3-2.5,14.7-4.3,30.3-4.3h.1Z" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <path d="M361.5,150.9v4.3s19.6.6,28.3,4.2,3,1.8,3.9,3.3c2.4,3.8,7.9,13.7,11.2,28.9.4,2.1.7,4.2.7,6.3h4c0-3.5-.4-7.1-1.2-10.5-1.5-6.5-4.4-16.6-8.1-23.9s-4.7-6.5-8.4-8.3c-5.3-2.5-14.7-4.3-30.3-4.3h-.1Z" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="396.5 168.5 340.5 195.4 340.5 198.7 398.3 171.4 396.5 168.5" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="445.5 168.5 501.5 195.4 501.5 198.7 443.7 171.4 445.5 168.5" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <polygon points="398.5 235.9 396.5 235.9 396.5 200.9 398.5 199.9 398.5 235.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="394.5" y="205.9" width="2" height="19" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="388.5" y="208.9" width="6" height="13" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="383.5" y="211.9" width="5" height="8" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="378.5" y="212.9" width="5" height="6" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="378.5" y1="217.9" x2="359.5" y2="217.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="379" y1="213.9" x2="367.5" y2="213.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <polygon points="443.5 235.9 445.5 235.9 445.5 200.9 443.5 199.9 443.5 235.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="445.5" y="205.9" width="2" height="19" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="447.5" y="208.9" width="6" height="13" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="453.5" y="211.9" width="5" height="8" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="458.5" y="212.9" width="5" height="6" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="463.5" y1="217.9" x2="482.5" y2="217.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="463" y1="213.9" x2="474.5" y2="213.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <line x1="398.5" y1="201.9" x2="443.5" y2="201.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="404.5" y="201.9" width="13" height="19" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="424.5" y="201.9" width="13" height="19" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="399.5 175.9 380.8 192.9 373.2 192.9 370.5 197.9 381.5 197.9 401.3 179.9 399.5 175.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="442.3 175.9 461.1 192.9 468.6 192.9 471.3 197.9 460.3 197.9 440.5 179.9 442.3 175.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <rect x="298.5" y="159.9" width="42" height="108" rx="19" ry="19" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="318.5" y="162.9" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="326.5" y="167.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="308.5" y="167.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="326.5" y="251.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="308.5" y="251.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="318.5" y="253.9" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="334" y="179.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="318.5" y="180.9" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="318.5" y="196.4" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="318.5" y="210.8" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="318.5" y="224.1" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="318.5" y="239.2" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="301.5" y="179.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="334" y="195.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="301.5" y="195.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="334" y="209.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="301.5" y="209.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="334" y="224.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="301.5" y="224.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="334" y="239.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="301.5" y="239.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <rect x="501.5" y="159.9" width="42" height="108" rx="19" ry="19" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="521.5" y="162.9" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="529.5" y="167.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="511.5" y="167.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="529.5" y="251.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="511.5" y="251.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="521.5" y="253.9" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="537" y="179.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="521.5" y="180.9" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="521.5" y="196.4" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="521.5" y="210.8" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="521.5" y="224.1" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="521.5" y="239.2" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="504.5" y="179.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="537" y="195.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="504.5" y="195.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="537" y="209.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="504.5" y="209.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="537" y="224.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="504.5" y="224.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="537" y="239.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="504.5" y="239.9" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <path d="M421.5,80.9h-10.8s-4.5,0-9.1-1.1-9,1.4-10.1,5.9,0,.2,0,.2l-2.1,9.8c-.5,2.4-2.1,4.5-4.3,5.6s-6.9,3.3-9.9,3.5-.9,0-1.4.2c-1.4.3-4.3,1.1-5.8,2.4s-1.9,1.4-3,1.7l-29.6,7.6c-.7.2-1.4.4-2,.8-2.7,1.5-9.2,6.2-12.5,17.3-3.3,11.1-.3,1.5-.3,2.3v22.6" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M421.5,80.9h10.8s4.5,0,9.1-1.1,9,1.4,10.1,5.9,0,.2,0,.2l2.1,9.8c.5,2.4,2.1,4.5,4.3,5.6s6.9,3.3,9.9,3.5.9,0,1.4.2c1.4.3,4.3,1.1,5.8,2.4s1.9,1.4,3,1.7l29.6,7.6c.7.2,1.4.4,2,.8,2.7,1.5,9.2,6.2,12.5,17.3,3.3,11.1.3,1.5.3,2.3v22.6" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M320.5,139.9s1.5-10.9,8.7-12.4c0,0,6.5-1.8,19.2,4.2s5.5,1.9,8.4,1.9c7,0,20.2,0,26.9.8s2.5.5,3.7.9c2.5.9,10.1,1.7,34.1,1.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M522.5,139.9s-1.5-10.9-8.7-12.4c0,0-6.5-1.8-19.2,4.2s-5.5,1.9-8.4,1.9c-7,0-20.2,0-26.9.8s-2.5.5-3.7.9c-2.5.9-10.1,1.7-34.1,1.7" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M368,107.5s11.5,12.4,17.5,27.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M388,98.4s14.6,23.3,21.6,38.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M475.6,107.5s-11.5,12.4-17.5,27.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M455,98.4s-14.1,23.3-21,38.4" fill="none" stroke="#050505" stroke-miterlimit="10"/>
      <path d="M421.5,58.9s14,0,22.9,8.2.1,0,.2,0c.7.7,5.9,6.2,6.9,14.5s0,.2,0,.3l3.3,10.1c.1.4.3.7.6.9,1.2.9,4.3,3.7,5.1,7.8,0,0,0,2,5,1l38-7s6.9-1.5,10.6-.6,1.4,1,1.4,1.8v26.2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M421.5,58.9s-15,.4-23.9,8.5-.1,0-.2,0c-.7.7-5.9,6.2-6.9,14.5-1,8.3,0,.2,0,.3l-3.3,10.1c-.1.4-.3.7-.6.9-1.2.9-4.3,3.7-5.1,7.8,0,0,0,2-5,1l-38-7s-6.9-1.5-10.6-.6-1.4,1-1.4,1.8v26.8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M509.5,37.3c-.5-.3-1.1-.5-1.7-.5-1.7-.2-5.9-.6-11.3.1-6,.9-12.7,3.2-14.5,3.8-.3.1-.6.2-.9.4-5.5,3.3-13.8,10.4-17.1,13.4-1,.9-2.2,1.3-3.5,1.3s-2.5.5-3.5,1.3l-15.5,13.7c-2.4,2.4-6.8,3.6-8.4,3.9-.4,0-.7,0-1.1,0h-21c-.4,0-.7,0-1.1,0-1.6-.3-6-1.5-8.4-3.9l-15.5-13.7c-1-.8-2.2-1.3-3.5-1.3s-2.5-.5-3.5-1.3c-3.3-3-11.6-10.2-17.1-13.4-.3-.2-.6-.3-.9-.4-1.8-.7-8.5-3-14.5-3.8-5.4-.8-9.6-.4-11.3-.1-.6,0-1.2.3-1.7.5-.9.5-2,1.3-2,2.6v21s0,3.4,3.1,5.5c.5.4,1.1.6,1.8.7,1.3.3,4.3.8,9.1.8h15.8c.8,0,1.5-.2,2.2-.5l10.9-5c.7-.3,1.4-.5,2.2-.5h3.8v-1h1.1c1.2,0,2.4.4,3.4,1.2l16.5,13.8s3.6,3.3,10.3,3.7h19.4c6.7-.3,10.3-3.7,10.3-3.7l16.5-13.8c.9-.8,2.1-1.2,3.4-1.2h1.1v1h3.8c.8,0,1.5.2,2.2.5l10.9,5c.7.3,1.4.5,2.2.5h15.8c4.9,0,7.8-.5,9.1-.8.6,0,1.2-.4,1.8-.7,3.1-2.1,3.1-5.5,3.1-5.5v-21c0-1.3-1.2-2.1-2-2.6h.2Z" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <line x1="380.5" y1="60.9" x2="380.5" y2="55.4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="462.5" y1="60.9" x2="462.5" y2="55.4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M380.5,59.9s-34,1-49-2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M380.5,60.9s-34,1-49-2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M462.5,59.9s34,1,49-2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M462.5,60.9s34,1,49-2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="448.5 64.9 394.5 64.9 391.5 61.9 451.5 61.9 448.5 64.9" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
    </g>
  </g>
  <g id="Layer_18" data-name="Layer 18">
    <g>
      <rect x="400.5" y="925.9" width="41" height="21" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="407.5" y="946.9" width="2" height="9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="432.5" y="946.9" width="2" height="9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <rect x="393.5" y="932.9" width="7" height="8" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="386.5" y="934.9" width="7" height="5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="348.5" y="935.9" width="38" height="3" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="387.5" y1="920.9" x2="362.5" y2="935.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="398.5" y1="919.9" x2="371.5" y2="935.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <polygon points="356.5 920.9 356.7 928.8 351.7 935.7 362.5 935.9 370.3 930.7 368.7 929.4 368.8 925.3 370.5 923.9 370.5 920.9 356.5 920.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <rect x="441.5" y="932.9" width="7" height="8" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="448.5" y="934.9" width="7" height="5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="455.5" y="935.9" width="38" height="3" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="454.5" y1="920.9" x2="479.5" y2="935.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="443.5" y1="919.9" x2="470.5" y2="935.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <polygon points="485.5 920.9 485.3 928.8 490.3 935.7 479.5 935.9 471.7 930.7 473.3 929.4 473.2 925.3 471.5 923.9 471.5 920.9 485.5 920.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <path d="M385.3,821.4s-1.8,4.5-14.8,1.5c0,0-26.1-6.8-41.5-6.9h-.3c-2.4,0-4.2,2.2-4.1,4.6l.8,22c0,2.8-.4,5.6-1.5,8.1l-3,7.3c-.2.5-.3,1.1-.3,1.7v22.7" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="387.5" y="821.9" width="67" height="5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="396.5" y1="821.9" x2="396.5" y2="826.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="445.5" y1="821.4" x2="445.5" y2="826.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="297.5" y="882.9" width="51" height="108" rx="24.6" ry="24.6" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <rect x="322.5" y="886.9" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="332.5" y="892.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="311.5" y="892.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="330.3" y="976.7" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="312.3" y="976.7" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="322.3" y="978.7" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="339.8" y="904.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="322.3" y="905.7" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="322.3" y="921.2" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="322.3" y="935.7" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="322.3" y="949" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="322.3" y="964.1" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="302.3" y="904.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="339.8" y="920.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="302.3" y="920.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="339.8" y="934.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="302.3" y="934.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="339.8" y="949.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="302.3" y="949.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="339.8" y="964.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="302.3" y="964.7" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <path d="M387.5,824.4h0c-1.7,0-3.1,1.4-3.1,3.1v64.3c0,.8-.3,1.5-.8,2.1-1.2,1.3-3.7,3.9-6.7,6.4s-8.3,4.6-13.1,4.6h-15.4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M384.5,828.9s-12,2-32-5c0,0-13.7-3.9-28-4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="331.5" y1="819.9" x2="331.5" y2="883.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="389.5" y="836.9" width="4" height="63" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="389.5 836.9 387.5 826.9 393.5 826.9 393.5 836.9 389.5 836.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M393.5,899.9v14.8h0c-.2,3.4-1,6.1-6.2,6h-38.4v-6h40.6s0,.2,0-14.8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M456.7,821.4s1.8,4.5,14.8,1.5c0,0,26.1-6.8,41.5-6.9h.3c2.4,0,4.2,2.2,4.1,4.6l-.8,22c0,2.8.4,5.6,1.5,8.1l3,7.3c.2.5.3,1.1.3,1.7v22.7" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="493.5" y="882.9" width="51" height="108" rx="24.6" ry="24.6" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <rect x="516.5" y="886.9" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="506.5" y="892.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="527.5" y="892.9" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="508.7" y="976.8" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="526.7" y="976.8" width="3" height="7" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="516.7" y="978.8" width="3" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="498.7" y="904.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="517.2" y="905.8" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="517.2" y="921.2" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="517.2" y="935.6" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="517.2" y="948.9" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="517.2" y="964" width="2.5" height="8.2" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="536.2" y="904.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="498.7" y="920.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="536.2" y="920.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="498.7" y="934.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="536.2" y="934.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="498.7" y="949.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="536.2" y="949.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="498.7" y="964.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
        <rect x="536.2" y="964.8" width="3.5" height="10" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <path d="M454.5,824.4h0c1.7,0,3.1,1.4,3.1,3.1v64.3c0,.8.3,1.5.8,2.1,1.2,1.3,3.7,3.9,6.7,6.4s8.3,4.6,13.1,4.6h15.4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M457.5,828.9s12,2,32-5c0,0,13.7-3.9,28-4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="510.5" y1="819.9" x2="510.5" y2="883.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="448.5" y="836.9" width="4" height="63" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="452.5 836.9 454.5 826.9 448.5 826.9 448.5 836.9 452.5 836.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M448.5,899.9v14.8h0c.2,3.4,1,6.1,6.2,6h38.4v-6h-40.6s0,.2,0-14.8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <path d="M393.5,831.9s18,3.5,28,3.2" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M448.5,831.9s-18,3.5-28,3.2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <line x1="421.5" y1="834.9" x2="421.5" y2="826.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M393.5,840.8h55v-5.1s-15,2.9-27,2.6c0,0-17-.7-28-2.6v5.1Z" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="412.5 850.9 393.5 850.9 393.5 846.9 414.5 846.9 412.5 850.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M412.5,850.9l-19,37.2v7.7l21-38.9v-10h0l-2,4Z" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="393.5 850.9 399.9 875.7 403.3 868.7 398.5 850.9 393.5 850.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="401.5 880.9 406.2 899.9 411 899.9 404.5 875.9 401.5 880.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="405.5" y="899.9" width="12" height="28" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <rect x="393.5" y="912.9" width="12" height="3" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="405.5" y1="919.9" x2="391.5" y2="919.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="393.5" y="905.9" width="12" height="4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="429.5 850.9 448.5 850.9 448.5 846.9 427.5 846.9 429.5 850.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M429.5,850.9l19,37.2v7.7l-21-38.9v-10h0l2,4Z" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="448.5 850.9 442.1 875.7 438.7 868.7 443.5 850.9 448.5 850.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <polygon points="440.5 880.9 435.7 899.9 431 899.9 437.5 875.9 440.5 880.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="424.5" y="899.9" width="12" height="28" fill="#fff" stroke="#000" stroke-miterlimit="10"/>
      <rect x="436.5" y="912.9" width="12" height="3" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="436.5" y1="919.9" x2="450.5" y2="919.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="436.5" y="905.9" width="12" height="4" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <line x1="400.5" y1="846.9" x2="404.5" y2="840.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="404.5" y1="846.9" x2="408.5" y2="840.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <line x1="442.5" y1="846.9" x2="438.5" y2="840.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <line x1="438.5" y1="846.9" x2="434.5" y2="840.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <rect x="419.5" y="840.9" width="3" height="58" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <path d="M415,862.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,862.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,867.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,867.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,872.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,872.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,876.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,876.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,881.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,881.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,886.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,886.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,891.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,891.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,896.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,896.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,858.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,858.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,854.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,854.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,850.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,850.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M415,846.8c0-1.9,2.9-3.5,6.2-3.5s5.8,1.6,5.8,3.5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M415,846.8c0-1.9,0-5,6-5s6,3.1,6,5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <rect x="417.5" y="898.9" width="7" height="5" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <rect x="417.5" y="909.9" width="7" height="3" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M421,810.6h-.6c-27.3-.2-37.5,10.8-37.5,10.8h75.5s-10.2-11-37.5-10.8h0Z" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M383.5,820.9l4.6-7.3c.2-.4.5-.8.7-1.2,1.5-3,9.3-17.7,22.7-19.5h9.2" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M458.2,820.9l-4.6-7.3c-.2-.4-.5-.8-.7-1.2-1.5-3-9.3-17.7-22.7-19.5h-10.3" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M388.5,812.9s4-32,33-32" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <path d="M453,812.9s-3.8-32-31.5-32" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <g>
        <path d="M398.5,790.9s-7-9-18-13v4.3s9.5,4.2,15.7,11.5l2.3-2.7h0Z" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M380.5,777.9v-1.3c0-.5-.2-.9-.5-1.2h0c-.3-.3-.7-.5-1.1-.5h-2.7c-.4,0-.8,0-1.1-.4-2.3-2-12.7-10.9-23.3-14.5s-.4,0-.6,0h-17.4c-.3,0-.5,0-.7.2-.6.3-1.6,1-1.6,2.2v22.3s0,3.2,4.7,5.2.4,0,.7,0h25.5c.2,0,.4,0,.6,0,1.7-.7,9.7-4,10.6-4.9,0,0,2-2,3-2l4-.4" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="338.5" y="771.9" width="3" height="12" rx=".7" ry=".7" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="370.5" y="775.9" width="10" height="5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="341.5" y="773.9" width="29" height="8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <g>
        <path d="M443.5,790.9s7-9,18-13v4.3s-9.5,4.2-15.7,11.5l-2.3-2.7h0Z" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <path d="M461.5,777.9v-1.3c0-.5.2-.9.5-1.2h0c.3-.3.7-.5,1.1-.5h2.7c.4,0,.8,0,1.1-.4,2.3-2,12.7-10.9,23.3-14.5s.4,0,.6,0h17.4c.3,0,.5,0,.7.2.6.3,1.6,1,1.6,2.2v22.3s0,3.2-4.7,5.2-.4,0-.7,0h-25.5c-.2,0-.4,0-.6,0-1.7-.7-9.7-4-10.6-4.9,0,0-2-2-3-2l-4-.4" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="500.5" y="771.9" width="3" height="12" rx=".7" ry=".7" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="461.5" y="775.9" width="10" height="5" fill="none" stroke="#000" stroke-miterlimit="10"/>
        <rect x="471.5" y="773.9" width="29" height="8" fill="none" stroke="#000" stroke-miterlimit="10"/>
      </g>
      <line x1="391.5" y1="783.9" x2="407.5" y2="783.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
      <line x1="435" y1="783.9" x2="451" y2="783.9" fill="none" stroke="#000" stroke-miterlimit="10"/>
    </g>
  </g>
        </>
    )

}
  }