import {
  SET_WEBSITE,
  UPDATE_WEBSITE,
} from "../../actions/organisation/types";
import { initialState } from "./const";
import { IConsumerWebsiteState } from "./types";

const reducer = function (
  state: IConsumerWebsiteState = initialState,
  action: any
): IConsumerWebsiteState {
  switch (action.type) {
    case UPDATE_WEBSITE:
      return {
        ...state,
        consumerWebsite: { ...state.consumerWebsite, ...action.payload }
      };
    case SET_WEBSITE:
      return {
        ...state,
        consumerWebsite: { ...state.consumerWebsite, ...action.payload }
      };
    default:
      return state;
  }
}
export default reducer