import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_PAYMENT } from "../../../graphql/invoices/createPaymentMutation";
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackBar } from "../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../SnackbarWrapper/SnackbarWrapper";

interface IProps {
}

const PaymentStatus: React.FC<IProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackBar();
  const [isLoading, setLoading] = useState<boolean>(true)

  const [loadPayment, { data: paymentData }] = useLazyQuery(
    GET_PAYMENT,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const params = new URLSearchParams(location.search);
      const paymentId = params.get("id");
      if (paymentId) {
        loadPayment({
          variables: {
            id: paymentId
          }
        });
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const paymentStatus = paymentData?.consumerGetPayment?.status;
    const params = new URLSearchParams(location.search);
    const urlStatus = params.get("status");
    if (paymentStatus) {
      if (paymentStatus === "PAID" || paymentStatus === "AUTHORISED") {
        setLoading(false);
        history.push(`/booking?id=${paymentData.consumerGetPayment.booking.id}`);
      }
      if (urlStatus === "failure") {
        setLoading(false);
        snackbar({
          message: "Payment has been failed, Please try again",
          variant: SnackBarVariant.ERROR
        });
        history.push(`/quote?id=${paymentData.consumerGetPayment.booking.id}`);
      }
    }
  }, [paymentData]);

  return (
    <>
      {isLoading ?
        <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <CircularProgress thickness={5} />
          <h3>Payment Processing, Please Wait...</h3>
        </div> :
        <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h3>Payment Complete</h3>
        </div>
      }
    </>
  );
}

export default PaymentStatus;