import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const BusinessCustomerVerificationDialog: React.FC<IProps> = (props) => {
  const { open, handleClose } = props;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Account Verification
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your profile has not been verified. Kindly contact your operator to complete the account verification process and proceed further.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BusinessCustomerVerificationDialog;
