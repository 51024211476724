import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from "react";
import { IBookingQuestionInput, IBookingCreateInput, ICustomerInfoInput, IOneWayRentalFee, BookingType } from "../../../../reducers/bookings/types";
import { SummaryPanel } from '../../../SummaryPanel';
import SimpleCustomerView from "./CustomerView/SimpleCustomerView";
import { IDriverRow } from './CustomerView/AddNewDriver';


interface IProps {
  bookingData: IBookingCreateInput;
  customerInfo: ICustomerInfoInput;
  bookingCreationFailed?: boolean;
  onSubmit: (data: IBookingCreateInput, updateOnly?: boolean) => void;
  onPrevious(values: ICustomerInfoInput): void;
  updateBookingDrivers: (data: IDriverRow[]) => void;
  bookingDrivers?: IDriverRow[];
  oneWayRentalFee: IOneWayRentalFee | undefined
  bookingType?: BookingType;
}

const PersonalDetails: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const isTestDriveBooking = props.bookingType === BookingType.TEST_DRIVE;

  useEffect(() => {
    if (props.bookingCreationFailed) {
      setLoading(false);
    }
  }, [props.bookingCreationFailed]);

  const updateCustomer = ({
    customer, businessCustomer,
    approvedDrivers, preBookingQuestions, customerNote,
    costCenter, projectId
  }:
    {
      customer?: string,
      businessCustomer?: string,
      approvedDrivers?: string[],
      preBookingQuestions?: IBookingQuestionInput[],
      customerNote?: string,
      costCenter?: string,
      projectId?: string
    }
  ) => {
    approvedDrivers = approvedDrivers || [];
    if (customer || businessCustomer) {
      props.onSubmit({ ...props.bookingData, customer, businessCustomer, approvedDrivers, preBookingQuestions, customerNote, costCenter, projectId }, false);
    }
  };

  return (
    <div className="flex col-flex">
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={isTestDriveBooking ? 12 : 8} style={{ marginBottom: 20 }}>
            <Typography variant="h4" align="center" style={{ fontWeight: 700, marginBottom: 20 }} >Personal Details</Typography>
            <SimpleCustomerView
              updateCustomer={updateCustomer}
              customerInfo={props.customerInfo}
              onPrevious={props.onPrevious}
              branchId={props.bookingData.branchId}
              bookingData={props.bookingData}
              bookingDrivers={props.bookingDrivers}
              updateBookingDrivers={(drivers) => props.updateBookingDrivers(drivers)}
            />
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} lg={1}></Grid>
          </Hidden>
          {!isTestDriveBooking && 
            <Grid item xs={12} md={4} lg={3}>
              <SummaryPanel bookingInput={props.bookingData} oneWayRentalFee={props.oneWayRentalFee ? props.oneWayRentalFee : undefined} />
            </Grid>
          }
        </Grid>
      </Container>

    </div>
  );
};

export default PersonalDetails;
