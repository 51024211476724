import React, { useEffect, useState } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, ButtonGroup, CircularProgress, createStyles, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import styles from "./index.module.css";
import { CheckListStatus, IVehicle, IVehicleCheckInfo, IVehicleState, IVehicleStateCheck } from '../../../../reducers/bookings/types';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { UPDATE_VEHICLE_STATE } from '../../../../graphql/vehicles/updateVehicle';
import { useMutation } from '@apollo/client';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { useImageUpload } from '../../../../utils/axios-hooks';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';

const StyledInput = withStyles(() =>
  createStyles({
    root: {
      // marginBottom: 20,
      '& .MuiOutlinedInput-input': {
        padding: '14px 14px'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px 4px'
      },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: '#e2e2e2',
        borderRadius: 8
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      },
      '& .MuiSelect-select': {
        padding: '14px 14px',
      },
      '& .MuiSelect-select ~ fieldset': {
        borderColor: '#e2e2e2',
        borderRadius: 8
      },
    },
  }),
)(TextField);

enum CheckStatusLabel {
  "CHECKED" = "Checked",
  "MISSING" = "Missing",
  "N/A" = "N/A"
}

enum statusColorMap {
  "CHECKED" = "#395dd9",
  "MISSING" = "#cb4242",
  "N/A" = "#393939"
}

interface ICardProps {
  check: IVehicleStateCheck;
  status: CheckListStatus;
  setStatus: (status: CheckListStatus) => void;
  error: boolean;
}

const VehicleCheckCard: React.FC<ICardProps> = (props) => {
  const [stateStatus, setStateStatus] = useState(props.check.status)
  const handleStatusChange = (status: CheckListStatus) => {
    setStateStatus(status)
    props.setStatus(status);
  }
  return (
    <Grid item xs={12} sm={6}>
      <div className={`${styles.checkCard} ${props.error && styles.error}`}>
        <div className="padding-bottom flex cross-center">
          <Typography variant="body1" className={`flex fill ${styles.name}`}>{props.check.name}</Typography>
          {/* <IconButton edge="start" style={{ margin: -10 }}>
            <AddAPhotoIcon style={{ fontSize: 15 }} />
          </IconButton> */}
        </div>
        <ButtonGroup fullWidth size="small" color="default" aria-label="outlined primary button group">
          {[CheckListStatus["CHECKED"], CheckListStatus["MISSING"], CheckListStatus["N/A"]].map((s, i) =>
            <Button
              key={i}
              onClick={() => handleStatusChange(s)}
              style={{
                textTransform: "none",
                fontWeight: "initial",
                backgroundColor: stateStatus === s ? statusColorMap[s] : "",
                color: stateStatus === s ? "#fff" : "#848484"
              }}>{CheckStatusLabel[s]}</Button>
          )}
        </ButtonGroup>
      </div>
    </Grid >
  )
}

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  vehicle: IVehicle;
  setVehicleState: (vehicleState: IVehicleState) => void
}

const hasBattery = ["HYBRID ELECTRIC", "ELECTRICITY", "ELECTRIC DIESEL", "HYBRID ELECTRIC (CLEAN)", "ELECTRIC"];
const hasFuel = ["DIESEL", "PETROL", "HYBRID ELECTRIC", "ELECTRIC DIESEL", "GAS BI-FUEL", "HYBRID ELECTRIC (CLEAN)", "GASOLINE"];

const UpdateVehicleStateDialog: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const auth = useSelector((state: IAppState) => state.authReducer);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer);
  const { upload, loading, imageKey, error } = useImageUpload(website.consumerWebsite.tenantId, auth._e_);

  const handleDialogueClose = () => {
    props.setOpen(false)
  };

  const [updateVehicleMutation, { loading: vehicleStateLoading }] = useMutation(UPDATE_VEHICLE_STATE, {
    onCompleted: (data) => {
      props.setVehicleState(data.businessConsumerUpdateVehicleState);
      snackbar({
        message: 'Vehicle state updated',
        variant: SnackBarVariant.SUCCESS
      });
      props.setOpen(false)
    },
    onError: () => {
      snackbar({
        message: 'Error in saving data',
        variant: SnackBarVariant.ERROR
      });
    },
  });

  const validateAnswer = (value: CheckListStatus) => {
    let error;
    if (!value)
      error = "Error";
    return error
  }

  const validationSchema = Yup.object().shape({
    batteryStatus: Yup.number()
      .typeError("Battery Status must be a number")
      .min(0, "Battery Status can't be negative")
      .max(100, "Battery Status can't be more than 100%")
      .required("Battery Status is required"),
    odometerReading: Yup.number()
      .typeError("Odometer Reading must be a number")
      .min(
        props.vehicle.vehicleState.odometerReading,
        `Odometer Reading must be equal or higher than the last reported reading. Last reported "${props.vehicle.vehicleState.odometerReading}"`
      )
      .required("Odometer Reading is required"),
  });

  const fuelLevelValues = [
    { label: 'E', value: 'e' },
    { label: '1/8', value: '1/8' },
    { label: '1/4', value: '1/4' },
    { label: '3/8', value: '3/8' },
    { label: '1/2', value: '1/2' },
    { label: '5/8', value: '5/8' },
    { label: '3/4', value: '3/4' },
    { label: '7/8', value: '7/8' },
    { label: 'F', value: 'f' },
  ]
  return (
    <Dialog
      open={props.open}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Formik
        initialValues={{
          odometerReading: props.vehicle.vehicleState.odometerReading,
          odometerReadingImg: "fkshdfskdh",
          fuelStatus: props.vehicle.vehicleState.fuelStatus,
          batteryStatus: props.vehicle.vehicleState.batteryStatus || 0,
          generalChecks: props.vehicle.vehicleState.generalChecks.map(check => ({ ...check, status: check.status ? check.status : CheckListStatus['N/A'] })),
          defaultAccesories: props.vehicle.vehicleState.defaultAccesories.map(check => ({ ...check, status: check.status ? check.status : CheckListStatus['N/A'] }))
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          updateVehicleMutation({
            variables: {
              vehicleId: props.vehicle.id,
              businessConsumerVehicleState: values
            }
          })
        }}
      >
        {(formikProps) => (
          <Form style={{ width: 600 }}>
            <DialogTitle>
              <div className="flex cross-center space-between">
                <span className="font-regular">Update Vehicle State</span>
                <IconButton onClick={handleDialogueClose} edge="end" aria-label="options" size="small">
                  <CloseRoundedIcon style={{ fontSize: 20 }} />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={1} style={{ marginBottom: 20 }}>
                <Grid item xs={12} sm={6} style={{ display: "flex" }} >
                  {hasFuel.includes(props.vehicle.fuelType) && <>
                    <div className="flex fill col-flex">
                      <Typography variant="body1" className="padding-bottom semi-bold">Fuel Status</Typography>
                      <StyledInput
                        select
                        value={formikProps.values.fuelStatus}
                        onChange={(e) => formikProps.setFieldValue("fuelStatus", e.target.value)}
                        style={{ color: formikProps.values.fuelStatus === " " ? "#848484" : "initial" }}
                        fullWidth
                        placeholder="Fuel Level"
                      >
                        <MenuItem disabled value=" ">Choose fuel level</MenuItem>
                        {fuelLevelValues.map((level, index) => <MenuItem value={level.value}>{level.label}</MenuItem>)}
                      </StyledInput>
                    </div>
                    <span className="margin-right"></span>
                  </>}
                  {hasBattery.includes(props.vehicle.fuelType) && <div className="flex fill col-flex">
                    <Typography variant="body1" className="padding-bottom semi-bold">Battery Status</Typography>
                    <Field name="batteryStatus">
                      {({ field, meta: { touched, error } }: FieldProps<number>) => (
                        <div>
                          <StyledInput
                            {...field}
                            type="number"
                            fullWidth
                            name="batteryStatus"
                            placeholder="Battery Status"
                            value={formikProps.values.batteryStatus}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              formikProps.setFieldValue("batteryStatus", value);
                            }}
                            error={touched && !!error}
                          />
                          {touched && error && (
                            <div style={{color: 'red', fontSize: '14px', marginTop: '4px'}}>{error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="padding-bottom semi-bold">Odometer Reading</Typography>
                  <div className="flex">
                    <Field name="odometerReading">
                      {({ field, meta: { touched, error } }: FieldProps<number>) => (
                        <div>
                          <StyledInput
                            {...field}
                            type="number"
                            fullWidth
                            name="odometerReading"
                            placeholder="Odometer Reading"
                            value={formikProps.values.odometerReading}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              formikProps.setFieldValue("odometerReading", value);
                            }}
                            error={touched && !!error}
                          />
                          {touched && error && <div style={{color: 'red', fontSize: '14px', marginTop: '4px'}}>{error}</div>}
                        </div>
                      )}
                    </Field>
                    {/* <div className={`${styles.odometerImage} ${styles.empty}`}>
                      <img className="img-cover" src="https://images.unsplash.com/photo-1600033562504-1fcb422f63cb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80" />
                      <div className={styles.upload}>
                        <input
                          accept="image/*"
                          id="odometerReadingImg"
                          type="file"
                          onChange={(e: any) => {
                            var filesArr = Array.prototype.slice.call(e.target.files);
                            const file = filesArr[0];
                            upload(file)
                          }}
                          style={{ display: 'none' }}
                        />
                        {loading ? <CircularProgress /> :
                          <label htmlFor="odometerReadingImg">
                            <IconButton aria-label="options">
                              <AddAPhotoIcon style={{ fontSize: 18, color: "#000" }} />
                            </IconButton>
                          </label>
                        }
                      </div>
                    </div> */}
                  </div>
                </Grid>
              </Grid>
              {props.vehicle.vehicleState.generalChecks.length ? <>
                <Typography variant="body1" className="padding-top padding-bottom semi-bold">General Checks</Typography>
                <Grid container spacing={1} style={{ marginBottom: 20 }}>
                  {props.vehicle.vehicleState.generalChecks.map((check, index) => {
                    return (
                      <Field name={`generalChecks.[${index}].status`} validate={(value: any) => validateAnswer(value)}>
                        {({ field, meta: { touched, error }, }: FieldProps) => {
                          return (
                            <VehicleCheckCard
                              error={touched && Boolean(error)}
                              check={check}
                              status={field.value as CheckListStatus}
                              setStatus={(status: CheckListStatus) => {
                                formikProps.setFieldValue(`generalChecks.[${index}].status`, status);
                              }}
                            />
                          )
                        }}
                      </Field>
                    )
                  })}
                </Grid>
              </> : null}
              {props.vehicle.vehicleState.defaultAccesories.length ? <>
                <Typography variant="body1" className="margin-top padding-top padding-bottom semi-bold">Accessories checks</Typography>
                <Grid container spacing={1}>
                  {props.vehicle.vehicleState.defaultAccesories.map((check, index) => {
                    return (
                      <Field name={`defaultAccesories.[${index}].status`} validate={(value: any) => validateAnswer(value)}>
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <VehicleCheckCard
                            error={touched && Boolean(error)}
                            check={check}
                            status={field.value as CheckListStatus}
                            setStatus={(status: CheckListStatus) => {
                              formikProps.setFieldValue(`defaultAccesories.[${index}].status`, status);
                            }}
                          />
                        )}
                      </Field>
                    )
                  })}
                </Grid>
              </> : null}
            </DialogContent>
            <DialogActions>
              <div className="flex main-end cross-center">
                <Button type="submit" variant="contained" color="primary" size="large" style={{ background: "var(--primary-color)", textTransform: "initial", boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                  Save
                </Button>
              </div>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
};

export default UpdateVehicleStateDialog;
