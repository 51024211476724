import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from "react";
import SimpleCustomerView from "./CustomerView/SimpleCustomerView";
import { IDriverRow } from './CustomerView/AddNewDriver';
import { IAddress, IBookingCreateInput, IBookingQuestionInput, ICustomerInfoInput, IOneWayRentalFee } from '../../../../../reducers/bookings/types';


interface IProps {
  bookingData: IBookingCreateInput;
  customerInfo: ICustomerInfoInput;
  bookingCreationFailed?: boolean;
  onSubmit: (data: IBookingCreateInput) => void;
  onPrevious(values: ICustomerInfoInput): void;
  updateBookingDrivers: (data: IDriverRow[]) => void;
  bookingDrivers?: IDriverRow[];
  oneWayRentalFee: IOneWayRentalFee | undefined;
  isLastStepPersonalDetails: boolean
}

const PersonalDetails: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.bookingCreationFailed) {
      setLoading(false);
    }
  }, [props.bookingCreationFailed]);

  const updateCustomer = ({
    customer,
    businessCustomer,
    approvedDrivers,
    preBookingQuestions,
    customerNote,
    costCenter,
    projectId,
    deliveryAddress
  }:
    {
      customer?: string,
      businessCustomer?: string,
      approvedDrivers?: string[],
      preBookingQuestions?: IBookingQuestionInput[],
      customerNote?: string,
      costCenter?: string,
      projectId?: string,
      deliveryAddress?: IAddress
    }
  ) => {
    approvedDrivers = approvedDrivers || [];
    if (customer || businessCustomer) {
      props.onSubmit({ ...props.bookingData, customer, businessCustomer, approvedDrivers, preBookingQuestions, customerNote, costCenter, projectId, deliveryAddress });
    }
  };

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <SimpleCustomerView
            updateCustomer={updateCustomer}
            onSubmit={props.onSubmit}
            customerInfo={props.customerInfo}
            branchId={props.bookingData.branchId}
            bookingData={props.bookingData}
            bookingDrivers={props.bookingDrivers}
            updateBookingDrivers={(drivers) => props.updateBookingDrivers(drivers)}
            isLastStepPersonalDetails={props.isLastStepPersonalDetails}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PersonalDetails;
