import React from "react";

export const stepFrameTrailerSvg = {
  interior: {
    width: 174.6,
    height: 500.9,
    svg: (
      <>
        <rect
          x="16.3"
          y="11.5"
          width="142.5"
          height="332.9"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <rect
          x="16.3"
          y="365.2"
          width="142.5"
          height="121.8"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <rect
          x="32.3"
          y="376.8"
          width="110.3"
          height="73.9"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <text
          transform="translate(47.3 471.7)"
          fill="#231f20"
          font-family="MyriadPro-Regular, 'Myriad Pro'"
          font-size="12"
        >
          <tspan x="0" y="0">
            Refrigerator Unit
          </tspan>
        </text>
      </>
    ),
  },
  exterior: {
    width: 546.9,
    height: 923.7,
    svg: (
      <>
        <g id="Layer_2" data-name="Layer 2">
          <g>
            <rect
              x="-17.8"
              y="319.8"
              width="139.9"
              height="3.4"
              transform="translate(373.6 269.3) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="-107.5"
              y="538.4"
              width="299.2"
              height="3.4"
              transform="translate(582.2 498) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M39.9,390.5l-17.3,2v106.4s14.3.1,17.7,6.4v18.1s-2.3,7.1-17.7,6.8v12.9s13.2-1.1,17.2,5.3v19s-1.6,8-16.1,6.5v13.2s13.2-1.9,16.6,5.6v19.4s-5.5,6.1-16.1,5.5l8.9,69.4h7.2"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="46.7"
              y="387.9"
              width="1"
              height="6.1"
              transform="translate(438.1 343.8) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <g>
              <ellipse
                cx="26"
                cy="514.8"
                rx="13.2"
                ry="13.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="26"
                cy="514.8"
                r="6.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <ellipse
                cx="25.2"
                cy="558.7"
                rx="13.2"
                ry="13.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="25.2"
                cy="558.7"
                r="6.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <ellipse
                cx="26"
                cy="602.3"
                rx="13.2"
                ry="13.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="26"
                cy="602.3"
                r="6.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <rect
              x="39.3"
              y="333.4"
              width="6.1"
              height="15.8"
              transform="translate(383.6 299) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="29.2"
              y="337.9"
              width="3.5"
              height="7"
              transform="translate(372.3 310.5) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <polygon
              points="24.7 345.1 24.7 337.8 27.4 339.2 27.4 343.5 24.7 345.1"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <polygon
              points="44.1 689.7 44.1 390.5 53.9 390.5 53.9 252.7 168 252.7 168 689.7 44.1 689.7"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
          </g>
          <g>
            <rect
              x="425.8"
              y="318.4"
              width="139.9"
              height="3.4"
              transform="translate(815.9 -175.7) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="356.3"
              y="537"
              width="299.2"
              height="3.4"
              transform="translate(1044.6 32.9) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M508,389.1l17.3,2v106.4s-14.3.1-17.7,6.4v18.1s2.3,7.1,17.7,6.8v12.9s-13.2-1.1-17.2,5.3v19s1.6,8,16.1,6.5v13.2s-13.2-1.9-16.6,5.6v19.4s5.5,6.1,16.1,5.5l-8.9,69.4h-7.2"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="500.3"
              y="386.6"
              width="1"
              height="6.1"
              transform="translate(890.4 -111.2) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <g>
              <ellipse
                cx="521.9"
                cy="513.5"
                rx="13.2"
                ry="13.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="521.9"
                cy="513.5"
                r="6.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <ellipse
                cx="522.7"
                cy="557.3"
                rx="13.2"
                ry="13.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="522.7"
                cy="557.3"
                r="6.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <ellipse
                cx="521.9"
                cy="600.9"
                rx="13.2"
                ry="13.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="521.9"
                cy="600.9"
                r="6.2"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <rect
              x="502.6"
              y="332"
              width="6.1"
              height="15.8"
              transform="translate(845.5 -165.7) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="515.3"
              y="336.6"
              width="3.5"
              height="7"
              transform="translate(857.1 -177) rotate(90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <polygon
              points="523.2 343.7 523.2 336.4 520.5 337.8 520.5 342.2 523.2 343.7"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <polygon
              points="503.8 688.3 503.8 389.1 494.1 389.1 494.1 251.3 379.9 251.3 379.9 688.3 503.8 688.3"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
          </g>
          <rect
            x="187.4"
            y="251.5"
            width="163.4"
            height="438.1"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
          <g>
            <rect
              x="188.4"
              y="26.1"
              width="161.4"
              height="178.1"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="188.4"
              y="204.2"
              width="18.2"
              height="20.5"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="331.7"
              y="204.2"
              width="18.2"
              height="20.5"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
          </g>
          <rect
            x="187.4"
            y="708.8"
            width="163.4"
            height="180.4"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
          <line
            x1="269.1"
            y1="709.6"
            x2="269.1"
            y2="889.2"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
          <polygon
            points="247 889.2 247 897.5 187.9 897.5 187.9 907.2 350.3 907.2 350.3 897.5 292.1 897.5 292.1 889.2 247 889.2"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
        </g>
      </>
    ),
  },
};
