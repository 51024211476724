import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, Hidden, Typography, } from "@material-ui/core";
import { ApolloError } from "apollo-boost";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';

import { GET_BOOKING } from "../../../../graphql/bookings/getBookingQuery";
import styles from './index.module.css';
import { BookingStatus, IAddonRequirementInput, IBooking, ICreditNote, PaymentGateway } from "../../../../reducers/bookings/types";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { IAppState } from "../../../../store";
import { CREATE_PAYMENT } from "../../../../graphql/invoices/createPaymentMutation";
import { formatGraphQLErrorMessage, toCurrency } from "../../../utils";
import Container from "@material-ui/core/Container";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { TopBar } from '../../../TopBar/TopBar'
import DownloadPdf from '../../../Pdf/DownloadPdf';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { CONFIRM_BOOKING } from '../../../../graphql/bookings/confirmBookingMutation';
import StripePayment from "../PaymentView/StripePayment";
import { RATE_TYPES } from "../../utils";
import Quote from '../../CustomerPortal/BookingManagement/Quote';
import { DepositCollection, MinimumBookingAmountTypes } from "../../../consts";
import { UPDATE_BOOKING } from '../../../../graphql/bookings/updateBookingMutation';
import { getLocalizedBookingSyntex } from "../../../../utils/localized.syntex";
import { BusinessCustomerStatus, CustomerType, CustomerVerificationStatus } from "../../../../reducers/auth/types";
import { VerificationDialog } from "../../../VerificationDialog/VerificationDialog";
import { CHECK_BOOKING_CONFIRMATION_ELIGIBILITY } from "../../../../graphql/bookings/checkBookingConfirmationEligibility";
import { GET_ADJUSTABLE_CREDIT_NOTES_FOR_BOOKING } from "../../../../graphql/bookings/getAdjustableCreditNotesForBooking"
import BusinessCustomerVerificationDialog from "../../../WarningDialogBox/BusinessCustomerVerificationDialog";
import { GET_ALL_DOCUMENT_TYPES } from "../../../../graphql/customers/getAllDocumentTypesQuery";
import { IDocumentType } from "../../../Profile";
import { loadConvergeUS } from "../../../../utils/converge";

export interface ICreditNotesToAdjust {
  creditNoteId: string;
  amount: number;
}

const WhiteCircularProgress = withStyles(() =>
  createStyles({
    root: {
      color: '#fff !important',
      width: 30,
      height: 30
    },
  }),
)(CircularProgress);

interface IProps {
}

const QuoteView: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const location = useLocation();
  const history = useHistory();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { user } = useSelector((state: IAppState) => state.authReducer);
  const [booking, setBooking] = useState<IBooking>();
  const [loading, setLoading] = useState<boolean>(false);
  const { currency, stripeAccountId, locale, autoChargeEnabled, convergeEnabled } = website.organisation;
  const { country } = website.organisation.address;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [paymentCollection, setPaymentCollection] = useState<boolean>(false);
  const [stripePaymentIntentSecret, setStripePaymentIntentSecret] = useState<string>("");
  const [saveDetails, setSaveDetails] = useState<boolean>(false);
  const [amountToPay, setAmountToPay] = useState<number>(0);
  const [collectDeposit, setCollectDeposit] = useState<boolean>(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
  const [confirmDirectly, setConfirmDirectly] = useState(false);
  const [captureLater, setCaptureLater] = useState(false);
  const [adjustableCreditNotes, setAdjustableCreditNotes] = useState<ICreditNote[]>([]);
  const [creditNotesApplied, setCreditNotesApplied] = useState<boolean>(false);
  const [adjustableAmount, setAdjustableAmount] = useState<number>(0);
  const [creditNotesToAdjust, setCreditNotesToAdjust] = useState<ICreditNotesToAdjust[]>([]);
  const [bookingType, setBookingType] = useState<string>('PREPAID');
  const [paymentGatewayAvailable, setPaymentGatewayAvailable] = useState<boolean>(false);
  const [allRequiredDocument, setAllRequiredDocument] = useState<IDocumentType[]>([]);


  useEffect(() => {
    if (autoChargeEnabled) {
      setSaveDetails(true)
    }
  }, [autoChargeEnabled])

  const handleDialogOpen = () => {
    if (website.organisation.closeGroupSharingEnabled) {
      confirmBookingMutation({
        variables: {
          bookingId: booking?.id,
          branchId: booking?.branchId,
          bookingType: "PREPAID"
        }
      })
      
    } else {
      setDialogOpen(true);
    }
    
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setPaymentCollection(false);
  };
  const handleDialogBoxClose = () => {
    setDialogBoxOpen(false)
  }

  const [loadBooking, { loading: bookingLoading, data: bookingData }] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "network-only"
  });

  const [getAdjustableCreditNotesForBooking, { data: adjustableCreditNotesData }] = useLazyQuery(
    GET_ADJUSTABLE_CREDIT_NOTES_FOR_BOOKING, {
    fetchPolicy: "network-only"
  })

  const [checkBookingConfirmationEligibility] = useLazyQuery(
    CHECK_BOOKING_CONFIRMATION_ELIGIBILITY,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setLoading(false);
      },
      onCompleted: (data) => {
        if (data.bookingConfirmationEligibility) {
          if (booking) {
            if (confirmDirectly) {
              if (creditNotesToAdjust.length) {
                createPayment({ // signifies the case of pay on collection with some credit notes to adjust
                  variables: {
                    payment: {
                      bookingId: booking.id,
                      currency,
                      successUrl: `${window.location.protocol}//${window.location.host}/booking?id=${booking.id}`,
                      cancelUrl: `${window.location.protocol}//${window.location.host}/quote?id=${booking.id}`,
                    },
                    saveDetails,
                    collectDeposit: false, // because credit notes can't be adjusted against deposit
                    creditNotesToAdjust
                  }
                })
              } else {
                confirmBookingMutation({
                  variables: {
                    bookingId: booking.id,
                    branchId: booking.branchId,
                    bookingType
                  }
                })
              }
            } else {
              if (amountToPay) {
                createPayment({
                  variables: {
                    payment: {
                      bookingId: booking.id,
                      currency,
                      amount: amountToPay,
                      captureLater,
                      successUrl: `${window.location.protocol}//${window.location.host}/booking?id=${booking.id}`,
                      cancelUrl: `${window.location.protocol}//${window.location.host}/quote?id=${booking.id}`,
                    },
                    saveDetails,
                    collectDeposit,
                    creditNotesToAdjust
                  }
                });
              } else {
                createPayment({
                  variables: {
                    payment: {
                      bookingId: booking.id,
                      currency,
                      successUrl: `${window.location.protocol}//${window.location.host}/booking?id=${booking.id}`,
                      cancelUrl: `${window.location.protocol}//${window.location.host}/quote?id=${booking.id}`,
                    },
                    saveDetails,
                    collectDeposit: false, // because credit notes can't be adjusted against deposit
                    creditNotesToAdjust
                  }
                });
              }
            }
          }
        }
      }
    }
  );

  const [createPayment, { loading: sessionLoading }] = useMutation(CREATE_PAYMENT, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (data?.consumerCreatePayment?.success && booking) {
        const paymentResponse = data.consumerCreatePayment.payment;
        if (paymentResponse?.stripePaymentIntentSecret) {
          const stripePromise: any = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "", {
            stripeAccount: stripeAccountId
          });
          // Get Stripe.js instance
          const stripe = await stripePromise;
          // When the customer clicks on the button, redirect them to Checkout.
          if (stripe) {
            setLoading(false)
            setStripePaymentIntentSecret(data.consumerCreatePayment.payment.stripePaymentIntentSecret)
            setPaymentCollection(true);
          }
        } else if (paymentResponse?.convergePaymentSessionUrl) {
          window.location = data.consumerCreatePayment.payment.convergePaymentSessionUrl;
        } else if (paymentResponse?.convergeUSSessionToken) {
          loadConvergeUS({
            paymentSessionToken: paymentResponse.convergeUSSessionToken,
            onloadFunction() {
              setDialogOpen(false);
            },
            redirectFunction() {
              history.push(`/booking?id=${booking.id}`);
            }
          });
        } else {
          history.push(`/booking?id=${booking.id}`);
        }
      }
    }
  });

  const [confirmBookingMutation, { loading: confirmBookingLoading }] = useMutation(CONFIRM_BOOKING, {
    onCompleted: ({ consumerConfirmBooking }) => {
      history.push(`/booking?id=${consumerConfirmBooking.id}`);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
  });

  const [
    updateBookingMutation,
    { loading: updateBookingLoading }
  ] = useMutation(UPDATE_BOOKING, {
    onCompleted: ({ consumerUpdateBooking }) => {
      if (consumerUpdateBooking.status === BookingStatus.CONFIRMED) {
        history.push(`/account/bookings/${consumerUpdateBooking.id}`);
      }
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} updated`,
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [getAllDocumentTypes, { data: allDocumentTypesData, loading: documentTypesLoading }] = useLazyQuery(GET_ALL_DOCUMENT_TYPES);
  
  const updateBooking = (booking: IBooking) => {
    const vehicleGroups = booking.vehicleGroups.map((vg) => {
      const { activeVehicleSchedules, ..._vg } = vg;
      return {
        ..._vg,
        unlimitedMileage: _vg.unlimitedMileage || false,
        vehicleGroup: typeof _vg.vehicleGroup === "string" ? _vg.vehicleGroup : _vg.vehicleGroup.id
      }
    })
    const approvedDrivers: string[] = []
    if (booking.approvedDrivers?.length) {
      booking.approvedDrivers.forEach((driver: any) => {
        if (driver.id) {
          approvedDrivers.push(driver.id);
        } else if (typeof driver === "string") {
          approvedDrivers.push(driver);
        }
      })
    }
    const addonRequirements: IAddonRequirementInput[] = booking.addonRequirements.map(
      (addonReq) => {
        return {
          addon: addonReq.addon.id,
          hasFixedRate: addonReq.hasFixedRate,
          name: addonReq.name,
          quantity: addonReq.quantity,
          rate: addonReq.rate,
          displayName: addonReq.displayName,
          tax: {
            title: addonReq.tax && addonReq.tax.title ? addonReq.tax?.title : "",
            value: addonReq.tax && addonReq.tax.value ? addonReq.tax?.value : 0
          }
        };
      }
    );
    const _booking = {
      id: booking.id ? booking.id : "",
      ...(booking.bookingId && { bookingId: booking.bookingId }),
      pickupServiceLocation: booking.pickupServiceLocation.id,
      pickupDateTime: new Date(booking.pickupDateTime).toISOString(),
      pickupOtherLocation: booking.pickupOtherLocation,
      dropoffServiceLocation: booking.dropoffServiceLocation.id,
      dropoffDateTime: booking.dropoffDateTime
        ? new Date(booking.dropoffDateTime).toISOString()
        : booking.dropoffDateTime,
      dropoffOtherLocation: booking.dropoffOtherLocation,
      flightNumber: booking.flightNumber,
      vehicleGroups: vehicleGroups,
      longTermBooking: booking.longTermBooking,
      insuranceRate: booking.insuranceRate,
      excess: booking.excess,
      insurancePolicy: booking.insurancePolicy?.id,
      addonRequirements: addonRequirements,
      approvedDrivers: approvedDrivers,
      rateTypeName: booking.rateTypeName,
      rateTypeDuration: booking.rateTypeDuration,
      billingCycleName: booking.billingCycleName,
      billingCycleDuration: booking.billingCycleDuration,
      authorizedSignatory: booking.authorizedSignatory,
      pcoNumber: booking.pcoNumber,
      preBookingQuestions: booking.preBookingQuestions,
      locationSurcharges: booking.locationSurcharges,
      isRecurringBilling: booking.isRecurringBilling,
      billingFrequency: booking.billingFrequency,
    };
    updateBookingMutation({
      variables: {
        booking: _booking,
        branchId: booking.branchId
      }
    });
  };

  const handlePreAuthPayment = async () => {
    setCaptureLater(true)
    setConfirmDirectly(false)
    setLoading(true);
    setAmountToPay(getTotalPayableAmount())
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id
        }
      })
    }
  }

  const handlePaymentClick = async (amount?: number) => {
    setCaptureLater(false)
    setConfirmDirectly(false)
    if (amount) {
      setAmountToPay(amount)
    } else {
      setAmountToPay(getTotalPayableAmount())
    }
    setLoading(true);
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id
        }
      })
    }
  };

  const handleConfirmBooking = async () => {
    setLoading(true);
    setDialogOpen(false);
    setCaptureLater(false)
    setConfirmDirectly(true);
    setAmountToPay(getTotalPayableAmount())
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id
        }
      })
    }
  };

  const handlePayLater = async () => {
    setLoading(true);
    setCaptureLater(false)
    setConfirmDirectly(true);
    setBookingType('POSTPAID')
    setAmountToPay(getTotalPayableAmount())
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id,
          bookingType: 'POSTPAID'
        }
      })
    }
  }

  const calcAdjustableAmount = (creditNotes: ICreditNote[]) => {
    let adjustableAmount: number = 0;
    if (creditNotes.length) {
      creditNotes.forEach((note: ICreditNote) => {
        adjustableAmount += note.availableAmount
      })
    };
    return adjustableAmount;
  }

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const booking = params.get("id");
      if (booking) {
        loadBooking({
          variables: {
            bookingId: booking
          }
        });
        getAdjustableCreditNotesForBooking({
          variables: {
            bookingId: booking
          }
        })
      }
    }
  }, [location])


  const userType = user?.businesscustomers ? CustomerType.BUSINESS : CustomerType.INDIVIDUAL;
  useEffect(() => {
    if (allDocumentTypesData && allDocumentTypesData.getAllDocumentTypes) {
      const requiredDocuments = allDocumentTypesData.getAllDocumentTypes.filter((e: any) => {
        return e.isMandatory && e.applicableFor && e.applicableFor.length && e.applicableFor.includes(userType);
      });
      setAllRequiredDocument(requiredDocuments);
    }
  }, [allDocumentTypesData, userType]);

  useEffect(() => {
    if (bookingData && bookingData ) {
      setBooking(bookingData.consumerGetBooking);
    }
  }, [bookingData]);

  useEffect(() => {
    if (website && booking) {
      const selectedbranch = website.branches.find(branch => branch.id === booking.branchId);
      if (selectedbranch?.depositCollection === DepositCollection.AT_BOOKING_CONFIRMATION) {
        setCollectDeposit(true);
      }
      if (
        (website.paymentGateway === PaymentGateway.STRIPE && stripeAccountId) ||
        (website.paymentGateway === PaymentGateway.CONVERGE && convergeEnabled)
      ) {
        setPaymentGatewayAvailable(true);
      }
    }
  }, [website, booking])

  useEffect(() => {
    if (adjustableCreditNotesData && adjustableCreditNotesData.adjustableCreditNotesForBooking?.length) {
      setAdjustableCreditNotes(adjustableCreditNotesData.adjustableCreditNotesForBooking)
      const amount = calcAdjustableAmount(adjustableCreditNotesData.adjustableCreditNotesForBooking);
      setAdjustableAmount(amount)
    }
  }, [adjustableCreditNotesData])

  useEffect(() => {
    if (creditNotesApplied && adjustableCreditNotes.length && booking) {
      const adjustableCreditNotesArr = [...adjustableCreditNotes].sort((a, b) => (b.availableAmount - a.availableAmount) > 0 ? 1 : -1)
      const arr: ICreditNotesToAdjust[] = [];
      let payableAmount = booking.paymentDetails.totalPayableAmount;
      let amountPaid = 0;
      let amountRemaining = payableAmount
      for (let i = 0; i < adjustableCreditNotesArr.length; i++) {
        const creditNote: ICreditNote = adjustableCreditNotesArr[i];
        if (amountPaid >= payableAmount) {
          break;
        }
        if (creditNote.availableAmount >= amountRemaining) {
          amountPaid += amountRemaining
          arr.push({
            creditNoteId: creditNote.id,
            amount: amountRemaining
          })
          amountRemaining -= amountRemaining
        } else {
          amountPaid += creditNote.availableAmount
          arr.push({
            creditNoteId: creditNote.id,
            amount: creditNote.availableAmount
          })
          amountRemaining -= creditNote.availableAmount
        }
      }
      setCreditNotesToAdjust(arr);
    }
  }, [creditNotesApplied])

  const handleCheckboxChange = () => {
    if (creditNotesApplied) { // signifies unchecking of checkbox as previous state was true.
      setCreditNotesToAdjust([]) // if checkbox unchecked then creditNotesToAdjust should be empty
    }
    setCreditNotesApplied(!creditNotesApplied)
  }

  if (!booking) {
    return (
      <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  const getMinimumPayableAmount = () => {
    let amount: number = -1;
    const selectedbranch = website.branches.find(branch => branch.id === booking.branchId);
    if (selectedbranch?.minBookingAmountOptIn && selectedbranch.minimumBookingAmount.type) {
      const chargeType = selectedbranch.minimumBookingAmount.type;
      if (chargeType === MinimumBookingAmountTypes.FIXED) {
        amount = selectedbranch.minimumBookingAmount.value
      } else if (chargeType === MinimumBookingAmountTypes.VARIABLE) {
        amount = Math.round((selectedbranch.minimumBookingAmount.value * booking.paymentDetails.totalPayableAmount) / 100)
      }
      if (paymentGatewayAvailable && collectDeposit) {
        if (booking.depositDetails?.amount && booking.depositDetails.amount > amount) {
          amount = booking.depositDetails.amount
        }
        if (amount > (booking.paymentDetails.totalPayableAmount + (booking.depositDetails?.amount || 0))) {
          amount = -1
        }
      } else {
        if (amount > booking.paymentDetails.totalPayableAmount) {
          amount = -1
        }
      }
    }
    return amount
  }

  const getTotalPayableAmount = () => {
    let amount = booking.paymentDetails.totalPayableAmount;
    if (collectDeposit && booking.depositDetails?.amount) {
      amount += booking.depositDetails.amount
    }
    if (creditNotesApplied && creditNotesToAdjust.length) {
      creditNotesToAdjust.forEach((item) => {
        amount -= item.amount
      })
    }
    if (amount > 0) {
      return amount
    }
    return 0;
  }

  return (
    <>
    
      <>
      <TopBar
        height={70}
        logoSize={44}
      />
      <div className={styles.appBody}>
        <div className={styles.actionField}>
          <Container>
            <h4 className={styles.header}>QUOTE GENERATED</h4>
            <div
              className={styles.quoteBody}
            >
              <Quote booking={booking} />
            </div>
            <div className={styles.bottomBar}>
              <Hidden smDown>
                <div className="flex fill"></div>
              </Hidden>
              <DownloadPdf
                getSource={{ type: "QUOTE", id: booking.id }}
                pdfSource={booking.quoteUrl}
                fileName={`Quote-${booking?.referenceNumber}.pdf`}
                styledButton
              />
              <button
                onClick={() => {
                  if (user) {
                    getAllDocumentTypes();
                    if (user?.customer) {
                      if (website.organisation.requiresCustomerVerification &&
                        user?.customer?.status === CustomerVerificationStatus.IN_REVIEW) {
                        return snackbar({
                          message: "Verification status is in review",
                          variant: SnackBarVariant.ERROR
                        });
                      }
                      if ((website.organisation.requiresCustomerVerification && (
                        user?.customer?.status === CustomerVerificationStatus.PENDING ||
                        user?.customer?.status === CustomerVerificationStatus.DECLINED
                      ))) {
                        setOpenVerificationDialog(true);
                      } else if ((!website.organisation.requiresCustomerVerification) ||
                        (website.organisation.requiresCustomerVerification && user?.customer?.status === CustomerVerificationStatus.APPROVED)) {
                        if (booking.bookingId && booking.bookingId !== booking.id) {
                          updateBooking(booking)
                        } else {
                          handleDialogOpen();
                        }
                      }
                    }
                    if (user?.businesscustomers) {
                      if ((website.enableBusinessCustomerSignup && (
                        user.businesscustomers.status === BusinessCustomerStatus.PENDING ||
                        user.businesscustomers.status === BusinessCustomerStatus.DECLINED))) {
                        setDialogBoxOpen(true);
                        return snackbar({
                          message: "Verification status is pending",
                          variant: SnackBarVariant.ERROR
                        })
                      } else if ((!website.enableBusinessCustomerSignup) ||
                        (website.enableBusinessCustomerSignup))
                        if (booking.bookingId && booking.bookingId !== booking.id) {
                          updateBooking(booking)
                        } else {
                          handleDialogOpen();
                        }
                    }
                  } else {
                    handleDialogOpen();
                  }
                }}
                className={styles.nextStepButton}
                type="button"
              >
                {
                  (loading || bookingLoading || confirmBookingLoading || updateBookingLoading) ? (
                    <WhiteCircularProgress size="small" thickness={5} />
                  ) : (
                    (booking.bookingId && booking.id !== booking.bookingId) ? 
                    `Update ${getLocalizedBookingSyntex(country)}` : 
                    (website.organisation.closeGroupSharingEnabled ? "Confirm Booking" : "Pay & Confirm")
                  )
                }

              </button>
            </div>
            {openVerificationDialog && (
              <VerificationDialog
                open={openVerificationDialog}
                handleClose={() => setOpenVerificationDialog(false)}
                user={user}
                require= {allRequiredDocument}
              />
            )}
            <BusinessCustomerVerificationDialog
              open={dialogBoxOpen}
              handleClose={handleDialogBoxClose}
            />
            <Dialog
              disableAutoFocus
              disableEnforceFocus
              disableRestoreFocus
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <div className={`${styles.paymentDialog} ${styles.mobile}`}>
                <div className={styles.closeDialog}>
                  <Typography variant="h2" className="bold">Proceed to pay</Typography>
                  <IconButton onClick={() => handleDialogClose()} aria-label="delete">
                    <CloseIcon />
                  </IconButton>
                </div>
                {!paymentCollection && <>
                  <div style={{ marginBottom: 10 }} className="flex padding-left space-between">
                    <Grid container xs={12}>
                      <Grid container xs={5}>
                        <Typography variant="h4" style={{ fontWeight: 'initial', display: "inline" }}>Rental Charges:</Typography>
                        <Typography variant="h3" style={{ marginLeft: "0.5rem", marginTop: "-0.2rem", display: "inline" }} className="bold text-accent">{toCurrency(booking.paymentDetails.totalPayableAmount, currency, locale)}</Typography>
                      </Grid>
                      <Grid container xs={7}>
                        {adjustableAmount > 0 && <Grid xs={11}>
                          <Grid container xs={12} style={{ marginTop: "-0.9rem", marginLeft: "2rem" }}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={creditNotesApplied}
                                    onChange={handleCheckboxChange}
                                    value={creditNotesApplied}
                                    color="secondary"
                                    disabled={loading}
                                  />
                                }
                                label={
                                  <Typography variant="body1">
                                    {`Use Credit Note Balance: ${toCurrency(adjustableAmount, currency, locale)}`}
                                  </Typography>}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </div>
                  {collectDeposit && Boolean(booking.depositDetails?.amount) &&
                    <div style={{ marginBottom: 15 }} className="flex padding-left space-between">
                      <div>
                        <Typography variant="h4" style={{ fontWeight: 'initial', display: "inline" }}>Deposit:</Typography>
                        <Typography variant="h3" style={{ marginLeft: "1rem", display: "inline" }} className="bold text-accent">{toCurrency(booking.depositDetails?.amount || 0, currency, locale)}</Typography>
                      </div>
                    </div>
                  }
                  <hr></hr>
                </>}
                <div style={{ marginBottom: 35 }} className="flex padding-left space-between">
                  <div>
                    <Typography variant="h4" style={{ fontWeight: 'initial', display: "inline" }}>Total payable amount:</Typography>
                    <Typography variant="h2" style={{ marginLeft: "1rem", display: "inline" }} className="bold text-accent">{toCurrency(amountToPay || getTotalPayableAmount(), currency, locale)}</Typography>
                  </div>
                </div>
                {paymentCollection ?
                  <StripePayment stripePaymentIntentSecret={stripePaymentIntentSecret} bookingId={booking.id} /> :
                  loading ? <CircularProgress /> : getTotalPayableAmount() > 0 ? <>
                    {getMinimumPayableAmount() > 0 && !creditNotesApplied ? <div onClick={() => handlePaymentClick(getMinimumPayableAmount())} className={`${styles.payAmoutButton} bold padding-top`}>
                      <div>
                        <Typography variant="h4" className="bold flex space-between">
                          <span style={{ paddingRight: 40 }}>Pay minimum amount</span>
                          <span className="text-accent">{toCurrency(getMinimumPayableAmount(), currency, locale)}</span>
                        </Typography>
                        <Typography variant="body1" className="text-grey flex space-between cross-center">
                          <span style={{ paddingRight: 50 }}>Due amount to be paid at the time of pickup</span>
                          <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                        </Typography>
                      </div>
                      <span></span>
                    </div> : null}
                    {paymentGatewayAvailable && getMinimumPayableAmount() === 0 ? <div onClick={() => handlePreAuthPayment()} className={`${styles.payAmoutButton} bold padding-top`}>
                      <div>
                        <Typography variant="h4" className="bold flex space-between">
                          <span style={{ paddingRight: 40 }}>Pay on collection</span>
                          <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
                        </Typography>
                        <Typography variant="body1" className="text-grey flex space-between cross-center">
                          <span style={{ paddingRight: 50 }}>{`Card will be authorised now but amount will be deducted when ${getLocalizedBookingSyntex(country || "").toLowerCase()} starts`}</span>
                          <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                        </Typography>
                      </div>
                      <span></span>
                    </div> : null}
                    {!paymentGatewayAvailable && getMinimumPayableAmount() === 0 ? <div onClick={() => handleConfirmBooking()} className={`${styles.payAmoutButton} bold padding-top`}>
                      <div>
                        <Typography variant="h4" className="bold flex space-between">
                          <span style={{ paddingRight: 40 }}>Pay on collection</span>
                          <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
                        </Typography>
                        <Typography variant="body1" className="text-grey flex space-between cross-center">
                          <span style={{ paddingRight: 50 }}>{`Confirm ${getLocalizedBookingSyntex(country || "").toLowerCase()} now and pay at the time of pickup`}</span>
                          <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                        </Typography>
                      </div>
                      <span></span>
                    </div> : null}
                    {paymentGatewayAvailable && <div onClick={() => handlePaymentClick()} className={`${styles.payAmoutButton} bold ${styles.full}`}>
                      <div>
                        <Typography variant="h4" className="bold flex space-between">
                          <span style={{ paddingRight: 40 }}>Pay full amount now</span>
                          <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
                        </Typography>
                        <Typography variant="body1" className="text-grey flex space-between cross-center">
                          <span style={{ paddingRight: 50 }}>Pay full amount right away</span>
                          <ArrowForwardIcon fontSize="small" style={{ marginRight: -4, fontSize: 16 }} />
                        </Typography>
                        {
                          autoChargeEnabled ?
                            <Grid item xs={12}>
                              <Typography>
                                Future charges related to the ongoing booking will be charged to this payment method
                              </Typography>
                            </Grid> :
                            [RATE_TYPES.MONTHLY, RATE_TYPES.WEEKLY].includes(booking.rateTypeName) &&
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={saveDetails}
                                    onChange={(e: any) => {
                                      setSaveDetails(e.target.checked)
                                    }}
                                    onClick={(e: any) => {
                                      e.stopPropagation()
                                    }}
                                    value={saveDetails}
                                    color="primary"
                                    name={"agreed"}
                                    disabled={sessionLoading}
                                  />
                                }
                                label={
                                  <Typography variant="body1">
                                    {`Save this card to make payment for future invoices on this ${getLocalizedBookingSyntex(country).toLowerCase()}.`}
                                  </Typography>
                                }
                              />
                            </FormGroup>
                        }
                      </div>
                    </div>}
                    {(booking.businessCustomer && booking.businessCustomer.creditLimitEnabled) ? <div onClick={() => handlePayLater()} className={`${styles.payAmoutButton} bold padding-top`}>
                  <div>
                      <Typography variant="h4" className="bold flex space-between">
                          <span style={{ paddingRight: 40 }}>Confirm Booking, Pay Later</span>
                          <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
                      </Typography>
                      <Typography variant="body1" className="text-grey flex space-between cross-center">
                          <span style={{ paddingRight: 50 }}>{`Credit limit assigned for this business will be used for this payment`}</span>
                            <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                      </Typography>
                    </div>
                      <span></span>
                  </div> : null}
                  </> :
                    <>
                      <div onClick={() => handlePaymentClick(getTotalPayableAmount())} className={`${styles.payAmoutButton} bold padding-top`}>
                        <div>
                          <Typography variant="h4" className="bold flex space-between">
                            <span style={{ paddingRight: 40 }}>Confirm Booking</span>

                          </Typography>
                          <Typography variant="body1" className="text-grey flex space-between cross-center">
                            <span style={{ paddingRight: 50 }}>Credits will be used against this booking</span>
                            <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                          </Typography>
                        </div>
                        <span></span>
                      </div>
                    </>
                }
              </div>
            </Dialog>
          </Container>
        </div>
      </div>
    </>


    
    </>

    
  );
};

export default QuoteView;