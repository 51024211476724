import { createMuiTheme } from "@material-ui/core";

import overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const theme = (createMuiTheme as any)({
  palette,
  typography,
  overrides,
  spacing: 10,
  props: {
    MuiPaper: {},
    MuiTextField: {
      variant: "outlined",
      color: "primary"
    },
    MuiInputBase: {
      color: "primary"
    },
    MuiCheckbox: {
      color: "secondary"
    }
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
