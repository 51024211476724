import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  InputBase,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { DateTime as d } from "luxon";
import HttpHeader from '../../../../header';
import * as UUID from "uuid";
import { ApolloError, useMutation } from '@apollo/client';
import FlatPickerBar from '../../../FlatPicker';
import { IAppState } from '../../../../store';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { DATE_TYPE, formatGraphQLErrorMessage, getUploadedImageByKey, returnfileContentTypeAndExtention, getSpellingByCountry } from '../../../utils';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from "yup"
import { ADD_DRIVER_MUTATION } from '../../../../graphql/user/updateUser';
import { getDefaultCountryCode, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import AddressPicker from "../../../AddressPicker/AddressPicker";
import { IAddress } from "../../../../reducers/bookings/types";

const StyledInput = withStyles(() =>
  createStyles({
    root: {
      border: "1px solid #c0c0c0",
      borderRadius: 6,
      padding: "8px 10px",
      marginTop: 5,
      '&.Mui-focused': {
        borderColor: "var(--primary-color)"
      },
      '&.Mui-error': {
        borderColor: "#D13135",
        color: "#D13135"
      }
    },
  }),
)(InputBase);
interface ILicenseDocument {
  documentKey?: string;
  document?: string;
}
interface IProps {

}

const licenseImageInitial: ILicenseDocument[] = [{
  documentKey: "",
  document: ""
}, {
  documentKey: "",
  document: ""
}]

const AddDriver: React.FC<IProps> = () => {
  const snackbar = useSnackBar();
  const history = useHistory();
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const { country } = website.organisation.address;
  const user = useSelector(
    (state: IAppState) => state.authReducer.user
  );
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [licenseImages, setLicenseImages] = useState<ILicenseDocument[]>(licenseImageInitial);
  const [isLocation,setIsLocation] = useState<boolean>(false);
  const [addDriverMutation, { loading: addDriverLoading }] = useMutation(ADD_DRIVER_MUTATION, {
    fetchPolicy: "no-cache",
    onCompleted: ({ addBusinessCustomerDriver }) => {
      snackbar({
        message: "Driver added.",
        variant: SnackBarVariant.SUCCESS
      });
      window.location.replace("/account/drivers")
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const uploadLicenceImage = async (
    e: any,
    index: number
  ) => {
    try {
      const profilePicArr: any[] = [];
      const showUploadedImages: any[] = [];
      var filesArr = Array.prototype.slice.call(e.target.files);
      const file = filesArr[0];
      if (file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        const { fileExtension, contentType } = returnfileContentTypeAndExtention(file);
        const uniqueId = UUID.v4();
        const key = `${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                profilePicArr.push(key);
                snackbar({
                  message: `${getSpellingByCountry(country)} Image uploaded successfully`,
                  variant: SnackBarVariant.SUCCESS
                });
                const uploadedFile = await getUploadedImageByKey(key);
                if (uploadedFile) {
                  showUploadedImages.push(uploadedFile);
                }
                const _images = [...licenseImages];
                _images[index] = {
                  documentKey: key,
                  document: uploadedFile
                }
                setLicenseImages(_images)
              }
            });
        }
      } else {
        return snackbar({
          message: "Please only upload .jpeg/.jpg/.png image format!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err) {
      snackbar({
        message: err.message,
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Email is required."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    firstName: Yup.string()
      .required("First name is required."),
    lastName: Yup.string()
      .required("Last name is required.")
  });

  if (!user || !user.businesscustomers) {
    return <></>
  }

  return (
    <div className={styles.column}>
      <div className="flex cross-center margin-bottom padding-bottom">
        <div className="flex cross-center">
          <IconButton
            size="small"
            edge="start"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="semi-bold">Add new driver</Typography>
        </div>
        <span
          className="flex fill margin-left"
          style={{ borderBottom: "1px solid #e9e9e9", marginTop: 4 }}
        ></span>
      </div>
      <Formik
        initialValues={{
          nationalInsuranceNumber: "",
          profession: "",
          firstName: "",
          lastName: "",
          phoneNumber: {
            phone: "",
            country: ""
          },
          email: "",
          license: {
            authority: "",
            dateOfExpiry: "",
            licenseNumber: "",
          },
          location: {
            city: "",
            country: "",
            fullAddress: "",
            state: "",
            street: "",
            zipcode: ""
          },
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if(values.location && values.location.fullAddress){
            if(!(values.location.street || values.location.zipcode)){
              return snackbar({
                message: "Street / Zipcode is required",
                variant: SnackBarVariant.ERROR
              });
            }
          }
          addDriverMutation({
            variables: {
              driver: {
                ...values,
                license: {
                  ...values.license,
                  images: licenseImages.map(im => im.documentKey)
                }
              },
              businessCustomerId: user?.businesscustomers?.id
            }
          })
        }}
      >
        {(formikProps) => (
          <Form className={styles.profile}>
            <div className={styles.card}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">First Name*</Typography>
                  <Field name="firstName">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Enter First Name"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Last Name*</Typography>
                  <Field name="lastName">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Enter Last Name"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="margin-bottom">
                    <Typography variant="body1" className="opacity60 padding-bottom--small" > Email* </Typography>
                    <Field name="email">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="Enter Email"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body2" color="error" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </div>
                </Grid>
                <Grid xs={6} md={6} item>
                  <span className="font-medium opacity50">Phone No.*</span>
                  <Field
                    component={StyledInput}
                    name={"phoneNumber.phone"}
                    required
                  >
                    {({ field, meta: { touched, error } }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`} style={{ marginTop: 9 }}>
                          <div style={{ border: `1px solid ${(touched && Boolean(error)) ? '#D13135' : '#c0c0c0'}`, borderRadius: 6 }}>
                            <PhoneInput
                              {...field}
                              international={false}
                              defaultCountry={getDefaultCountryCode(website.organisation.address.country)}   //
                              placeholder="Enter phone number *"
                              className={(touched && Boolean(error)) ? "PhoneInputInputError" : ""}
                              name={"phoneNumber.phone"}
                              onChange={(val) => {
                                formikProps.setFieldValue('phoneNumber.phone', val)
                              }}
                              onCountryChange={(val) => {
                                formikProps.setFieldValue('phoneNumber.country', val)
                              }}
                            />
                          </div>
                          <Typography variant="body2" color="error" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid xs={6} md={6} item>
                  <AddressPicker
                    fieldName={"location"}
                    required={isLocation ? true : false}
                    onChange={(address: IAddress) => {
                    if (address && address.fullAddress) {
                      setIsLocation(true)
                      formikProps.setFieldValue('location', address)
                    }
                    else{
                      formikProps.setFieldValue("location",{
                        street:"",
                        city: "",
                        country: "",
                        fullAddress: "",
                        state: "",
                        zipcode: ""
                      })
                      setIsLocation(false)
                    }
                  }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={styles.card}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">National Insurance Number</Typography>
                  <Field name="nationalInsuranceNumber">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Enter National Insurance Number"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Profession</Typography>
                  <Field name="profession">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Enter Your Profession"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="margin-bottom">
                    <Typography variant="body1" className="opacity60 padding-bottom--small" > Driving Licence Number </Typography>
                    <Field name="license.licenseNumber">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="Enter Driving Licence Number"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </div>
                  <Typography variant="body1" className="opacity60 padding-bottom--small padding-top" > Driving Licence Image </Typography>
                  <div className="flex">
                    {licenseImages.map((image, index) => {
                      return (
                        <div key={index} className={styles.dlImage}>
                          {image.documentKey ? <div
                            style={{
                              border: "none",
                              boxShadow: "none",
                              margin: 0,
                              padding: 0,
                              height: 56,
                              width: "100%",
                            }}
                          >
                            <img src={image.document} alt="licance" />
                          </div> : <>
                            <label htmlFor={`LICENCE_IMG_${index}`} style={{ cursor: "pointer" }}>
                              <Typography variant="body2">Upload {index ? "Back" : "Front"}</Typography>
                            </label>
                            <input accept="image/*" style={{ display: 'none' }} onChange={(e: any) => { uploadLicenceImage(e, index); e.target.value = null }} id={`LICENCE_IMG_${index}`} type="file" />
                          </>
                          }
                        </div>
                      );
                    })
                    }
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="margin-bottom padding-bottom">
                    <Typography variant="body1" className="opacity60 padding-bottom--small" > Licence Issued By </Typography>
                    <Field name="license.authority">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="Licence Issued By"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Typography variant="body1" className="opacity60 padding-bottom--small" >  Licence Expiry Date </Typography>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        formikProps.setFieldValue("license.dateOfExpiry", d.fromJSDate(value).endOf("day").toUTC().toISO())
                      }}
                      label={""}
                      identifier={"dateOfExpiry"}
                      placeholderValue={" Driving Licence Expiry Date"}
                      value={formikProps.values.license.dateOfExpiry ? getLocalizedDateFormat(country, formikProps.values.license.dateOfExpiry, DATE_TYPE.CONDENSED) : ""}
                      minDate={d.now().startOf("day").toUTC().toISO()}
                      country={country}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 30 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disableRipple
                disabled={addDriverLoading}
              >{addDriverLoading && <CircularProgress size={16} style={{ color: "black", marginRight: "10px" }} />}
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddDriver;
