import { gql } from "apollo-boost";

export const GET_INVOICES = gql`
  query consumerInvoices($businessCustomerId: ID,$customerId:ID){
    consumerInvoices(businessCustomerId: $businessCustomerId,customerId:$customerId) {
      rentalAmount
      customerType
      insuranceAmount
      damageAmount
      addonsAmount
      taxAmount
      dueDate
      dueAmount
      booking {
        referenceNumber
        branch {
          timeZone
        }
      }
      tax {
        amount
        appliedBy
        appliedDate
        code
      }
      dateCreated
      dateUpdated
      createdBy
      updatedBy
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      invoiceRef
      status
      locked
      invoiceType
      startDate
      endDate
      totalPayableAmount
      confirmedPaidAmount
      confirmedRefundAmount
      otherRefunds
      otherCharges {
        amount
        name
      }
      id
      customer {
        id
        firstName
        lastName
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
      }
      sageAccountingInvoiceId
    }
  }
`;
