import axios from "axios";
import HttpHeader from '../header';

const httpHeader = HttpHeader.getInstance();

const instance = axios.create({
  baseURL: process.env.REACT_APP_APOLLO_SERVER,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(async function (config) {
  config.headers = {
    ...config.headers,
    ...httpHeader.headers,
    ...(httpHeader.headers.authorization && { authorization: `Bearer ${httpHeader.headers.authorization}` })
  };
  return config;
});

export const signedUrlAPI = (key: string) => instance.post("/", {
  query: `query signedUrl($key: String!) {
    consumerGetS3ObjectSignedUrl(key: $key)
  }`,
  variables: {
    key
  }
})

export const getSignedUrl = async (key: string) => {
  const { data } = await signedUrlAPI(key);
  return data.data.consumerGetS3ObjectSignedUrl;
}