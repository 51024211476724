import { gql } from "@apollo/client";

export const GET_CREDIT_SCORE = gql`
  mutation getConsumerCreditScore($customerId: String!) {
    getConsumerCreditScore(customerId: $customerId) {
      creditSafeData {
        creditScore
        lastChecked
      }
    }
  }
`;
