import {
  UPDATE_AUTH_STATE,
  AuthAction,
  SET_AUTH_EVAPORATE_CLIENT,
  ADD_USER_DATA
} from "../../actions/auth/types";
import { initialState } from "./const";
import { IAuthState } from "./types";

export default function (
  state: IAuthState = initialState,
  action: AuthAction
): IAuthState {
  switch (action.type) {
    case UPDATE_AUTH_STATE:
      return {
        ...action.payload,
        _e_: state._e_
      };
    case ADD_USER_DATA:
      return {
        ...state,
        user: action.payload,
        _e_: state._e_
      }
    case SET_AUTH_EVAPORATE_CLIENT:
      return {
        ...state,
        _e_: action.payload._e_
      }
    default:
      return state;
  }
}
