import React, { useState } from 'react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, createStyles, DialogContent, DialogTitle, Grid, InputBase, Theme, Typography, withStyles } from '@material-ui/core';
import styles from "./index.module.css";
import { IAssignedDriver, IVehicle } from '../../../../reducers/bookings/types';
import { useMutation } from '@apollo/client';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import CheckIcon from '@material-ui/icons/Check';
import { ASSIGN_DRIVER_TO_VEHICLE } from '../../../../graphql/vehicles/assignDriverMutation';
import { IBusinessCustomerApprovedDriverInput } from '../../../../reducers/auth/types';
import { getLocalizedBookingSyntex } from '../../../../utils/localized.syntex';
import { getSpellingByCountry } from '../../../utils';

interface IProps {
  open: boolean;
  onClose: () => void;
  vehicle?: IVehicle;
  onSucces: (driver: IAssignedDriver) => void;
  drivers: IBusinessCustomerApprovedDriverInput[];
}

const StyledInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
    input: {
      borderRadius: "4px !important",
      position: 'relative',
      backgroundColor: "rgba(0,0,0,0.1) !important",
      border: '1px solid rgba(0,0,0,0)',
      fontSize: 14,
      padding: '10px 5px',
      color: "#fff",
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      minHeight: 'initial'
    },
  }),
)(InputBase);

const AssignDriverDialog: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const [selection, setSelection] = useState<string | undefined>(props.drivers.find(driver => driver.id === props.vehicle?.activeBookingSchedule.driver?.id)?.id);
  const [noteInput, setNoteInput] = useState(props.vehicle?.activeBookingSchedule.driver?.note || "");
  const handleDialogueClose = () => {
    props.onClose()
  };

  const [assignDriverMutation, { loading: assigningDriver }] = useMutation(ASSIGN_DRIVER_TO_VEHICLE, {
    onCompleted: ({ assignDriverToVehicle }) => {
      props.onSucces(assignDriverToVehicle.driver);
      snackbar({
        message: 'Success',
        variant: SnackBarVariant.SUCCESS
      });
      props.onClose()
    },
    onError: () => {
      snackbar({
        message: 'Error in saving data',
        variant: SnackBarVariant.ERROR
      });
    },
  });


  return (
    <Dialog
      open={props.open}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      onClose={props.onClose}
    >
      <DialogTitle>
        <div style={{ width: 340 }}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <div className="flex cross-center space-between" style={{ padding: "0 6px" }}>
                <Typography variant="inherit" className="bold">Assign Driver</Typography>
                <IconButton onClick={handleDialogueClose} edge="end" aria-label="options" size="small">
                  <CloseRoundedIcon style={{ fontSize: 20 }} />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.shade}>
                <Typography variant="body2" className="bold">{getSpellingByCountry(country)} Plate: </Typography>
                <Typography variant="body1" >{props.vehicle?.licencePlate || "N/A"}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.shade}>
                <Typography variant="body2" className="bold">{getLocalizedBookingSyntex(country)} Ref</Typography>
                <Typography variant="body1" >{props.vehicle?.activeBookingSchedule.booking.referenceNumber || "N/A"}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div >
          {assigningDriver && <div
            className="flex cross-center main-center"
            style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, backgroundColor: "rgba(255,255,255,0.8)" }}
          >
            <CircularProgress size={22} thickness={5} />
          </div>}
          <div
            className={`${styles.shade} ${styles.item} ${styles.red} ${selection ? "" : styles.selected}`}
            onClick={() => {
              props.vehicle && props.vehicle.activeBookingSchedule.driver && assignDriverMutation({
                variables: {
                  bookingScheduleId: props.vehicle.activeBookingSchedule.id,
                  businessCustomerId: user?.businesscustomers?.id || "",
                  licencePlate: props.vehicle.licencePlate,
                }
              })
            }}
            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <Typography variant="body1">Unassigned</Typography>
            {!selection && <CheckIcon color="inherit" fontSize="inherit" />}
          </div>
          {user?.businesscustomers && user.businesscustomers.approvedDrivers.map((driver, index) => {
            const seleted = selection === driver.id;
            return <div
              className={`${styles.shade} ${styles.item} ${seleted ? styles.selected : ""}`}
              onClick={!seleted ? () => {
                setSelection(driver.id)
                setNoteInput("")
              } : undefined}
            >
              <div className="flex cross-center space-between">
                <Typography variant="body1" className={`${seleted ? "bold" : ""}`}>{driver.firstName} {driver.lastName}</Typography>
                {seleted && <CheckIcon color="inherit" fontSize="inherit" />}
              </div>
              {seleted && <div>
                <StyledInputBase
                  placeholder="Add note"
                  fullWidth
                  error={false}
                  multiline
                  value={noteInput}
                  onChange={(e) => setNoteInput(e.target.value)}
                />
                <div className="flex main-end">
                  <Typography
                    variant="h6"
                    className={styles.textButton}
                    onClick={() => {
                      seleted && props.vehicle && user.businesscustomers && assignDriverMutation({
                        variables: {
                          bookingScheduleId: props.vehicle.activeBookingSchedule.id,
                          businessCustomerId: user.businesscustomers.id,
                          licencePlate: props.vehicle.licencePlate,
                          driver: driver.id,
                          note: noteInput
                        }
                      })
                    }}
                  >
                    Save
                  </Typography>
                </div>
              </div>}
            </div>
          })}
        </div>

      </DialogContent>
    </Dialog >
  )
};

export default AssignDriverDialog;
