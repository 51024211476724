import React from "react"

export const Estate4x4_7SeaterSvg = {
  interior: {
    width:262,
    height:570,
    svg: (<><g>
    <path fill="none" d="M227.8,519l0.1-1.1c-1.2-1.9-2.9,0.2-5.1,6.3c-6.3,8.4-12,12.4-17.3,12c0.2,0.6,0.4,1.3,0.5,2l0.2,2.5
      c6-1.1,11.9-5.2,17.9-12.1c1-0.9,2.2-4,3.6-9.2L227.8,519L227.8,519z"/>
    <path fill="none" d="M225.5,502l-0.4,5.9c-1,4.7-2.1,6.7-3.4,5.7c-5.5-4.3-10.3-7.2-14.5-8.7c-0.4,4.7,0.5,8,3,9.8l12.6,9.5
      c2.2-6.1,3.9-8.2,5.1-6.3l2.5-27c1.9-22.4,3.4-45,4.5-67.9l-18.9-8.5c-1.9-0.9-3.1-2.9-3.6-6.1l-0.1-9.3l1-87.2l-0.1,2.2l-0.4-52
      c0.1-7,0.6-12,1.5-15l-0.9-7.3c-0.1-0.2-0.2-0.4-0.3-0.6c-1.1,4.1-1.6,9.2-1.5,15.3c0.8,43.6,0.7,87.3-0.4,130.9l-1,33.6l0.5,0.1
      l14.2,6.5c2.9,0.7,4.3,2.7,4.4,6.1l-0.1,1.4C228.4,456.3,227.3,479.2,225.5,502L225.5,502z"/>
    <path fill="none" d="M225.1,507.9l0.4-5.9c1.7-22.7,2.9-45.7,3.5-68.8l0.1-1.4c0-3.4-1.5-5.4-4.4-6.1l-14.2-6.5l-0.5-0.1l-2.8,85.9
      c4.2,1.5,9,4.5,14.5,8.7C223,514.6,224.1,512.6,225.1,507.9L225.1,507.9z M223.8,502c-1.8,2.3-3.7,3-5.8,2.2l-5.9-3.8
      c-1.9-1.3-2.7-3.3-2.5-5.9l2.1-60.8c0.2-4.1,1.4-5.7,3.4-4.8l9.4,4.1c1.8,0.9,2.7,2.6,2.7,5.2C226.5,459.6,225.3,480.9,223.8,502
      L223.8,502z"/>
    <path fill="none" d="M217.9,504.1c2.1,0.8,4,0.1,5.8-2.2c1.6-21.1,2.7-42.4,3.4-63.8c-0.1-2.6-1-4.3-2.7-5.2l-9.4-4.1
      c-2.1-0.9-3.2,0.7-3.4,4.8l-2.1,60.8c-0.2,2.6,0.7,4.6,2.5,5.9L217.9,504.1L217.9,504.1z"/>
    <path fill="none" d="M207.2,504.9c-0.8-0.3-1.6-0.6-2.4-0.8c-1.3-0.4-2.6-0.5-3.8-0.6c-1.4-0.1-2.6,0.1-3.8,0.5l-0.3,8.2l0.1,0
      l-0.1,0l-0.1,2.1c-0.4,3.4,0.3,6.7,2,10.1l6.7,11.9c5.2,0.3,11-3.7,17.3-12l-12.6-9.5C207.8,512.9,206.8,509.6,207.2,504.9
      L207.2,504.9z"/>
    <path fill="none" d="M204.8,504.1c0.8,0.2,1.6,0.5,2.4,0.8l2.8-85.9c-2.5-0.5-4.9-0.6-7.2-0.4l-1.8,84.9
      C202.2,503.6,203.5,503.8,204.8,504.1L204.8,504.1z"/>
    <path fill="none" d="M197.2,504.1c1.2-0.4,2.4-0.6,3.8-0.5l1.8-84.9c-0.9,0.1-1.9,0.2-2.9,0.4L197.2,504.1L197.2,504.1z"/>
    <path fill="none" d="M196.9,514.4l0.1-2.1l-4,0.2c-10.8,0.7-21.7,1.3-32.8,1.5v12.7l38.6-2.2C197.2,521.1,196.5,517.7,196.9,514.4
      L196.9,514.4z"/>
    <path fill="none" d="M197,512.2l0.3-8.2l2.7-85l5.2-162.8c0-8.5,0.4-15.6,1.3-21.4l-3.6-1.3c-1,6.6-1.5,14.2-1.5,22.7l-3.1,96.1
      l-4.8,150.5l-0.4,7.7l-0.1,1.8L197,512.2L197,512.2z"/>
    <path fill="none" d="M230.4,491c0.8-0.7,1.6-1.5,2.3-2.3c1.8-21.5,3.2-43.3,4.2-65.4l-2.1-0.2C233.8,446,232.3,468.7,230.4,491
      L230.4,491z"/>
    <path fill="none" d="M193,512.4l0.1-1.8c-10.9,0.7-21.8,1.3-32.8,1.5c-7.1,0.2-14.2,0.3-21.3,0.3h-14.6c-7.1,0-14.2-0.1-21.2-0.3
      c-11.1-0.3-22.1-0.8-33-1.6l0.1,1.9c10.8,0.7,21.8,1.3,32.9,1.5c7,0.2,14.1,0.3,21.2,0.3h14.6c7.2,0,14.3-0.1,21.3-0.3
      C171.3,513.7,182.2,513.2,193,512.4L193,512.4z"/>
    <path fill="none" d="M193.5,502.9L192,503c-6.1,0.5-12.3,0.9-18.7,1.2c-9.9,0.5-20.3,0.8-31.1,0.9l-21.4,0
      c-10.8-0.1-21.2-0.4-31.1-0.9c-6.4-0.3-12.6-0.7-18.7-1.2l-1.4-0.1l0.4,7.6c11,0.8,22,1.4,33,1.6c7,0.2,14.1,0.3,21.2,0.3h14.6
      c7.2,0,14.3-0.1,21.3-0.3c11-0.3,22-0.8,32.8-1.5L193.5,502.9L193.5,502.9z M130.1,506c0-0.1,0.1-0.1,0.5-0.2l1.1-0.1h0l1.1,0.1
      c0.3,0.1,0.4,0.1,0.4,0.2v2.6c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h0c-0.4,0-0.8-0.1-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1
      V506L130.1,506z"/>
    <path fill="none" d="M130.6,505.9c-0.3,0.1-0.5,0.1-0.5,0.2v2.6c0,0.4,0.1,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5h0
      c0.4,0,0.8-0.1,1.1-0.5c0.3-0.3,0.4-0.7,0.4-1.1V506c0-0.1-0.1-0.1-0.4-0.2l-1.1-0.1h0L130.6,505.9L130.6,505.9z"/>
    <path fill="none" d="M192,503l1.5-0.1l4.8-150.5l-1.4-0.4c-4.6-1.1-9.4-2.1-14.3-3c-10.2-1.8-21.1-3.1-32.7-3.8
      c-6-0.4-12.2-0.6-18.5-0.7c-6.3,0.1-12.4,0.3-18.3,0.7c-11.6,0.7-22.5,2-32.7,3.8c-4.9,0.9-9.7,1.8-14.2,3l-1.4,0.4l4.8,150.5
      l1.4,0.1l-4.8-148.9l-0.1-1.7l0.1,1.7c4.5-1.1,9.2-2.1,14.1-2.9c15.4-2.7,32.5-4.2,51.1-4.4c18.7,0.2,35.7,1.7,51.1,4.4
      c4.9,0.9,9.6,1.8,14.1,2.9l0.1-1.7l-0.1,1.7L192,503L192,503z"/>
    <path fill="none" d="M173.4,504.2c6.4-0.3,12.6-0.7,18.7-1.2l4.8-148.9c-4.5-1.1-9.2-2.1-14.1-2.9c-15.4-2.7-32.4-4.2-51.1-4.4
      c-18.7,0.2-35.7,1.7-51.1,4.4c-4.9,0.9-9.6,1.8-14.1,2.9L71.1,503c6.1,0.5,12.3,0.9,18.7,1.2c9.9,0.5,20.3,0.8,31.1,0.9l21.4,0
      C153.1,505,163.4,504.7,173.4,504.2L173.4,504.2z M175.9,359.9c4.6,0.8,7,4.5,7.4,11v24.8c-0.1,6.5-3.1,9.6-9,9.4H88.9
      c-5.9,0.2-8.9-2.9-9-9.4v-24.8c0.4-6.5,2.8-10.2,7.4-11c13.6-2,28.4-3.1,44.3-3.3C147.5,356.8,162.3,357.9,175.9,359.9L175.9,359.9
      z M182,474.5c-0.5,7-3.7,10.7-9.4,11.1H90.5c-5.7-0.5-8.9-4.2-9.4-11.1c-0.9-11.5-1.2-23.7-0.9-36.5c0.5-7.2,3.6-10.6,9.5-10.4
      h83.6c5.9-0.2,9.1,3.2,9.5,10.4C183.2,450.8,183,463,182,474.5L182,474.5z"/>
    <path fill="none" d="M183.2,370.9c-0.4-6.5-2.8-10.2-7.4-11c-13.6-2-28.3-3.1-44.3-3.3c-16,0.2-30.7,1.3-44.3,3.3
      c-4.6,0.8-7,4.5-7.4,11v24.8c0.1,6.5,3.1,9.6,9,9.4h85.4c5.9,0.2,8.9-2.9,9-9.4V370.9L183.2,370.9z"/>
    <path fill="none" d="M172.6,485.6c5.7-0.5,8.9-4.2,9.4-11.1c1-11.5,1.2-23.7,0.9-36.5c-0.5-7.2-3.6-10.6-9.5-10.4H89.8
      c-5.9-0.2-9.1,3.2-9.5,10.4c-0.4,12.8-0.1,25,0.9,36.5c0.5,7,3.7,10.7,9.4,11.1H172.6L172.6,485.6z"/>
    <path fill="none" d="M199.8,555.5l-0.1,0l-9.2,0.5c-13.7,0.7-28,1.2-43.2,1.4l-39.9-0.1c-12-0.2-23.6-0.7-34.7-1.2l-9.2-0.5l-0.1,0
      c-2.9-0.7-4.6-1.9-5.2-3.6l-0.9-7.9c-2.5-0.1-4.3-0.6-5.6-1.4c-6.5-3.8-11.1-8.3-13.8-13.5l-0.2-0.2c1.5,5,3.5,9.6,5.9,13.9
      c4.8,8.7,10.6,13.3,17.4,13.6l11.7,0.7c11.1,0.6,22.6,1,34.7,1.2l39.9,0.1c15.1-0.2,29.5-0.7,43.2-1.4l11.7-0.7
      c6.8-0.4,12.7-4.9,17.4-13.6c2.5-4.3,4.4-8.9,5.9-13.9l-0.2,0.2c-2.7,5.3-7.4,9.8-13.8,13.5c-1.2,0.8-3.1,1.3-5.6,1.4l-0.9,7.9
      C204.4,553.6,202.7,554.8,199.8,555.5L199.8,555.5z"/>
    <path fill="none" d="M199.7,555.5l0.1,0c2.9-0.7,4.6-1.9,5.2-3.6l0.9-7.9l0.2-1.2c-33.1,2.1-65.8,2.9-98,2.2l-1.5,2.4
      c-15.8,0-30.7-0.6-44.8-1.8l-1.3-2.7l-3.4-0.2l0.2,1.2l0.9,7.9c0.6,1.7,2.3,2.9,5.2,3.6l0.1,0l9.2,0.5c11.1,0.6,22.6,1,34.7,1.2
      l39.9,0.1c15.1-0.2,29.5-0.7,43.2-1.4L199.7,555.5L199.7,555.5z"/>
    <polygon fill="none" points="225.5,528.9 225.7,528.3 225.3,529 225.5,528.9 	"/>
    <path fill="none" d="M225.3,529l0.4-0.7c0.8-2.9,1.5-5.8,2.1-8.9c-1.4,5.3-2.6,8.3-3.6,9.2c-6,7-11.9,11-17.9,12.1l-0.1,2l0.1,0
      c1.8,0.1,3.2-0.1,4.1-0.5C214.4,540.8,219.4,536.4,225.3,529L225.3,529z"/>
    <path fill="none" d="M206,538.3c-0.1-0.7-0.3-1.4-0.5-2l-6.7-11.9l-38.6,2.2l-1.6,0.1c-3.9,0.3-8.1,0.5-12.5,0.7l-15,0.3l-14-0.3
      c-4.5-0.2-8.7-0.4-12.6-0.7l-1.5-0.1l-38.8-2.3l-6.7,11.9c-0.2,0.6-0.4,1.3-0.5,2l1.1,0.1l0.9,0.1c4.7,0.3,14.4,0.6,29.2,0.9
      c11.4,0.2,25.8,0.4,43.2,0.6C175.6,539.5,200.4,538.9,206,538.3L206,538.3z M190.6,529.4l2.5,5.8c1,1.5,0.4,2.3-1.8,2.3
      c-15.4,1-29.6,1.3-42.6,0.9c-2.6,0.1-5.7-0.4-9.4-1.4c-2-0.5-4.6-0.8-7.7-0.9c-3.1,0.2-5.7,0.5-7.8,0.9c-3.7,1-6.8,1.5-9.4,1.4
      c-13,0.4-27.2,0.1-42.6-0.9c-2.1,0-2.7-0.8-1.8-2.3l2.5-5.8c0.5-2,1.8-2.9,4-2.9l28.1,1.6c3.9,0.3,8.1,0.5,12.6,0.7l14.6,0.3
      l14.3-0.3c4.5-0.2,8.7-0.4,12.5-0.7l28.1-1.6C188.8,526.5,190.1,527.5,190.6,529.4L190.6,529.4z"/>
    <path fill="none" d="M193.1,535.2l-2.5-5.8c-0.5-2-1.8-2.9-4-2.9l-28.1,1.6c-3.9,0.3-8.1,0.5-12.5,0.7l-14.3,0.3l-14.6-0.3
      c-4.5-0.2-8.7-0.4-12.6-0.7l-28.1-1.6c-2.1-0.1-3.4,0.9-4,2.9l-2.5,5.8c-1,1.5-0.4,2.3,1.8,2.3c15.4,1,29.6,1.3,42.6,0.9
      c2.6,0.1,5.7-0.4,9.4-1.4c2-0.5,4.6-0.8,7.8-0.9c3.1,0.2,5.6,0.5,7.7,0.9c3.7,1,6.8,1.5,9.4,1.4c13,0.4,27.2,0.1,42.6-0.9
      C193.5,537.5,194.1,536.8,193.1,535.2L193.1,535.2z"/>
    <path fill="none" d="M210.3,542.2c-0.9,0.4-2.2,0.6-4.1,0.5l-0.1,0l-0.2,1.2c2.4-0.1,4.3-0.6,5.6-1.4c6.5-3.8,11.1-8.3,13.8-13.5
      C219.4,536.4,214.4,540.8,210.3,542.2L210.3,542.2z"/>
    <path fill="none" d="M206.1,542.7l0.1-2c-24.9,1.6-49.5,2.5-74,2.5l-6.2-0.5c-1.5,0-3-0.1-4.5-0.2c-2.2-0.2-4.3-0.4-6.5-0.7
      l-4.7-0.7L108,545C140.3,545.7,173,544.9,206.1,542.7L206.1,542.7L206.1,542.7z"/>
    <polygon fill="none" points="206.2,542.8 206.1,542.7 206.1,542.8 206.2,542.8 	"/>
    <path fill="none" d="M206.2,540.8l-0.2-2.5c-5.6,0.7-30.5,1.2-74.5,1.6c-17.4-0.2-31.8-0.4-43.2-0.6l17.9,0.7l3.4,0.9l0.7,0.2l0,0
      l4.7,0.7c2.1,0.3,4.3,0.5,6.5,0.7c1.5,0.1,3,0.2,4.5,0.2l6.2,0.5C156.7,543.2,181.3,542.4,206.2,540.8L206.2,540.8z"/>
    <path fill="none" d="M232.8,488.7c4-4.8,6.5-13.1,7.6-24.8c1.2-13.8,1.9-27.2,1.9-40.2l-5.3-0.4C236,445.3,234.6,467.1,232.8,488.7
      L232.8,488.7z"/>
    <path fill="none" d="M160.2,526.7V514c-7.1,0.2-14.2,0.3-21.3,0.3h-14.6c-7.1,0-14.2-0.1-21.2-0.3v12.7l1.5,0.1
      c3.9,0.3,8.1,0.5,12.6,0.7c-0.1-1.4,1.3-2.2,4.1-2.3h20.6c2.9,0.2,4.2,0.9,4.2,2.3c4.5-0.2,8.7-0.4,12.5-0.7L160.2,526.7
      L160.2,526.7z"/>
    <path fill="none" d="M223.1,38.2l0.5,1.7c4.5,8.5,8.1,31.8,11,69.9c1.4,15.8,1.5,31.4,0.2,47.1v0l0.1,1.4
      c3.3-6.7,5.1-20.7,5.3-42.2c-0.2-22.7-1.5-41.4-3.8-56.3c-1.7-9.3-4.1-15.2-7-17.4L223.1,38.2L223.1,38.2z"/>
    <path fill="none" d="M223.7,39.9l-0.5-1.7c-2.4-5-6.8-9.6-13.3-13.7l-0.5-0.3c-3.9-2.4-8.5-4.7-13.9-6.8l-1.8-0.7
      c-12.3-4-32.8-5.9-61.6-5.9h-0.9c-28.8,0-49.3,2-61.6,5.9l-1.8,0.7c-5.3,2.1-10,4.4-13.9,6.8l-0.5,0.3c-6.5,4.1-10.9,8.7-13.3,13.7
      l-0.5,1.7c-0.5,1.7-0.9,3.6-1.3,5.5c3.8-4.3,8.8-8.6,14.9-12.9c10-6.1,19.2-9.1,27.5-8.9l0.1,0l2.8-0.5c12.9-2,27.8-3.1,44.7-3.3
      h6.7c16.9,0.2,31.8,1.3,44.7,3.3l2.8,0.5l0.1,0c8.3-0.2,17.5,2.8,27.5,8.9c6.2,4.3,11.2,8.6,15,12.9
      C224.6,43.5,224.2,41.6,223.7,39.9L223.7,39.9z M197.6,20.4c0.1-0.2,0.2-0.3,0.4-0.2c1.1,0.1,2.1,0.6,3.1,1.2
      c0.2,0.1,0.3,0.2,0.2,0.5l-0.6,1.5c-0.1,0.2-0.2,0.3-0.4,0.2c-1.2-0.2-2.3-0.6-3.1-1.2c-0.2-0.1-0.3-0.3-0.2-0.5L197.6,20.4
      L197.6,20.4z M66.1,21.9c0.1,0.2,0,0.4-0.2,0.5c-0.8,0.7-1.9,1.1-3.1,1.2c-0.2,0.1-0.4,0-0.4-0.2l-0.6-1.5c-0.1-0.2,0-0.4,0.2-0.5
      c1-0.7,2-1.1,3.1-1.2c0.2-0.1,0.4,0,0.4,0.2L66.1,21.9L66.1,21.9z"/>
    <path fill="none" d="M198.1,20.2c-0.2-0.1-0.4,0-0.4,0.2l-0.6,1.5c-0.1,0.2,0,0.4,0.2,0.5c0.8,0.7,1.9,1.1,3.1,1.2
      c0.2,0.1,0.4,0,0.4-0.2l0.6-1.5c0.1-0.2,0-0.4-0.2-0.5C200.2,20.8,199.2,20.4,198.1,20.2L198.1,20.2z M198.4,21.1
      c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4C198.1,21.1,198.3,21.1,198.4,21.1L198.4,21.1z M200.4,21.8
      c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
      c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1C200.2,21.7,200.3,21.7,200.4,21.8L200.4,21.8z"/>
    <path fill="none" d="M198.8,21.2c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
      c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4C199,21.5,198.9,21.3,198.8,21.2L198.8,21.2z"/>
    <path fill="none" d="M200.6,22.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4
      c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.4-0.1C200.5,22.5,200.6,22.3,200.6,22.2L200.6,22.2z"/>
    <path fill="none" d="M65.9,22.4c0.2-0.1,0.3-0.3,0.2-0.5l-0.6-1.5c-0.1-0.2-0.2-0.3-0.4-0.2c-1.1,0.1-2.1,0.6-3.1,1.2
      c-0.2,0.1-0.3,0.2-0.2,0.5l0.6,1.5c0.1,0.2,0.2,0.3,0.4,0.2C64.1,23.5,65.1,23,65.9,22.4L65.9,22.4z M65.3,21.6
      c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
      c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.1C65.2,21.3,65.3,21.5,65.3,21.6L65.3,21.6z M63.1,21.7c0.1,0,0.3,0.1,0.4,0.1
      c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.1l-0.2-0.4c0-0.1,0.1-0.3,0.2-0.4
      C62.8,21.7,63,21.7,63.1,21.7L63.1,21.7z"/>
    <path fill="none" d="M65.1,22c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1
      c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2C64.9,22.2,65,22.1,65.1,22L65.1,22z"/>
    <path fill="none" d="M63.5,21.8c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4l0.2,0.4
      c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4C63.7,22.1,63.6,21.9,63.5,21.8L63.5,21.8z"/>
    <path fill="none" d="M209.8,24.5l-0.5-1c-3.9-2.4-8.5-4.7-13.9-6.8l-1.8,0l1.8,0.7c5.3,2.1,10,4.4,13.9,6.8L209.8,24.5L209.8,24.5z
      "/>
    <path fill="none" d="M202.1,34.6l-0.2-3.2c-4.9-2.3-10.2-4.4-16.1-6.2l3.3,1.6l0.3,3C194,31,198.2,32.6,202.1,34.6L202.1,34.6z"/>
    <path fill="none" d="M201.9,31.3l0.2,3.2c7.7,3.7,14.3,8.6,19.6,14.5l0.2,0.2c1.2-0.1,1.8-0.9,1.8-2.6
      C218,40.6,210.7,35.6,201.9,31.3L201.9,31.3z"/>
    <polygon fill="none" points="189.2,26.7 185.9,25.2 182.5,23.6 182.5,23.6 179.7,23.1 179.7,23.6 179.8,24.8 179.8,25.3 
      189.2,26.7 	"/>
    <path fill="none" d="M189.5,29.7l-0.3-3l-9.4-1.5l0.2,2.2l2.2,0.4C184.7,28.4,187.1,29,189.5,29.7L189.5,29.7z"/>
    <path fill="none" d="M180,27.5l-0.2-2.2l0-0.5l-1-0.1h-0.1l-0.7-0.1l-1-0.2c-1-0.2-2.1-0.6-3.2-1v-0.7l5.9,0.9l0-0.6
      c-12.9-2-27.8-3.1-44.7-3.3h-6.7c-16.9,0.2-31.8,1.3-44.7,3.3l-0.1,0.6l6.1-0.9v0.6c-1.1,0.4-2.2,0.8-3.2,1h-0.1l-2.9,0.4l0,0.5
      l-0.1,2.2c13.9-2.3,30-3.5,48.4-3.7C149.9,23.9,166.1,25.2,180,27.5L180,27.5z M171.9,22.5v0.7c-1.1,0.2-2.2,0.2-3.2,0.1h0
      l-1.8-0.2c-1-0.2-2.1-0.5-3.2-0.9v-0.6C166.4,21.9,169.1,22.2,171.9,22.5L171.9,22.5z M171.8,23.8l1.8,0.2l0.2,0.1
      c1.1,0.2,2.3,0.6,3.5,1.1v0.9c-1.2,0.2-2.3,0.2-3.5,0.1l-0.1,0c-0.6,0-1.2-0.1-1.9-0.2c-1.1-0.2-2.3-0.6-3.5-1.1v-0.8
      C169.5,23.8,170.7,23.8,171.8,23.8L171.8,23.8z M167.1,23.9v0.8c-1.2,0.2-2.3,0.3-3.5,0.2h-0.1c-0.6,0-1.2-0.1-1.9-0.2
      c-1.1-0.2-2.3-0.5-3.5-0.9v-0.8c1.2-0.2,2.4-0.4,3.5-0.3l2.4,0.3C165.1,23.2,166.1,23.5,167.1,23.9L167.1,23.9z M161.6,21.5v0.6
      c-1.1,0.2-2.1,0.3-3.2,0.3l-0.1,0l-1.8-0.1c-1-0.1-2.1-0.3-3.2-0.7V21C156.1,21.1,158.8,21.3,161.6,21.5L161.6,21.5z M156.8,23v0.8
      c-1.2,0.2-2.3,0.4-3.5,0.3h-0.1c-0.6,0-1.3,0-1.9-0.1c-1.1-0.1-2.3-0.4-3.5-0.8v-0.7c1.2-0.3,2.4-0.4,3.5-0.4l2,0.1
      C154.4,22.3,155.6,22.6,156.8,23L156.8,23z M151.3,20.9v0.6c-1.1,0.2-2.2,0.3-3.2,0.4l-1.8-0.1c-1-0.1-2.1-0.3-3.2-0.6v-0.5
      C145.8,20.7,148.6,20.8,151.3,20.9L151.3,20.9z M146.6,22.5v0.7c-1.2,0.3-2.4,0.4-3.5,0.4h-0.1c-0.6,0.1-1.2,0-1.9-0.1
      c-1.1-0.1-2.3-0.3-3.5-0.6v-0.7c1.2-0.3,2.4-0.4,3.5-0.5l2.5,0.1C144.6,21.9,145.6,22.2,146.6,22.5L146.6,22.5z M141.1,20.6v0.6
      c-1.1,0.2-2.2,0.4-3.2,0.4l-1.8,0c-1-0.1-2.1-0.2-3.2-0.5v-0.5L141.1,20.6L141.1,20.6z M132.6,23.4L132.6,23.4
      c-0.7,0.1-1.3,0.1-2,0c-1.1-0.1-2.3-0.2-3.5-0.5v-0.7c1.2-0.3,2.4-0.5,3.5-0.5h2c1.1,0.1,2.3,0.2,3.5,0.6v0.7
      C134.9,23.2,133.8,23.4,132.6,23.4L132.6,23.4z M130.6,20.5v0.5c-1.1,0.3-2.1,0.4-3.2,0.5l-1.8,0c-1-0.1-2.1-0.2-3.2-0.4v-0.5
      L130.6,20.5L130.6,20.5z M125.9,22.2v0.7c-1.2,0.3-2.3,0.5-3.5,0.6h-0.1c-0.6,0.1-1.2,0.1-1.9,0c-1.1,0-2.3-0.2-3.5-0.4v-0.7
      c1.2-0.3,2.4-0.5,3.5-0.6h2.4C123.9,21.8,124.9,22,125.9,22.2L125.9,22.2z M120.4,20.6v0.5c-1.1,0.3-2.2,0.5-3.2,0.5l-1.8,0.1
      c-1,0-2.1-0.1-3.2-0.3v-0.5L120.4,20.6L120.4,20.6z M115.6,22.4v0.7c-1.2,0.4-2.3,0.6-3.5,0.7H112c-0.6,0.1-1.3,0.1-1.9,0.1
      c-1.1,0-2.3-0.1-3.5-0.3v-0.7c1.2-0.4,2.4-0.6,3.5-0.7l2-0.1C113.2,22,114.4,22.2,115.6,22.4L115.6,22.4z M106.9,22.2l-1.8,0.1
      c-1,0-2.1-0.1-3.2-0.2v-0.6c2.8-0.2,5.6-0.4,8.3-0.5v0.5C109.1,21.9,108,22.1,106.9,22.2L106.9,22.2z M99.9,21.7v0.5
      c-1.1,0.4-2.1,0.7-3.2,0.8l-0.1,0l-1.8,0.2c-1,0.1-2.1,0-3.2-0.1v-0.6L99.9,21.7L99.9,21.7z M105.4,22.9v0.7
      c-1.2,0.4-2.3,0.7-3.5,0.8h-0.1l-1.9,0.2c-1.1,0-2.3,0-3.5-0.2v-0.8c1.2-0.4,2.4-0.7,3.5-0.9l2.4-0.2
      C103.4,22.7,104.4,22.8,105.4,22.9L105.4,22.9z M95.1,23.9v0.8c-1.2,0.5-2.3,0.8-3.5,1h-0.1c-0.6,0.2-1.2,0.3-1.9,0.3
      c-1.1,0.1-2.3,0.1-3.5,0v-0.8c1.2-0.6,2.4-1,3.5-1.2l2.4-0.3C93.1,23.7,94.1,23.8,95.1,23.9L95.1,23.9z"/>
    <path fill="none" d="M171.9,23.2v-0.7c-2.7-0.3-5.5-0.6-8.3-0.9v0.6c1.1,0.4,2.2,0.7,3.2,0.9l1.8,0.2h0
      C169.7,23.4,170.8,23.3,171.9,23.2L171.9,23.2z"/>
    <path fill="none" d="M173.6,24.1l-1.8-0.2c-1.1-0.1-2.3,0-3.5,0.2v0.8c1.2,0.5,2.4,0.9,3.5,1.1c0.7,0.1,1.3,0.2,1.9,0.2l0.1,0
      c1.1,0.1,2.3,0.1,3.5-0.1v-0.9c-1.2-0.5-2.3-0.9-3.5-1.1L173.6,24.1L173.6,24.1z"/>
    <path fill="none" d="M167.1,24.7v-0.8c-1-0.4-2-0.7-3-0.9l-2.4-0.3c-1.1,0-2.3,0.1-3.5,0.3v0.8c1.2,0.4,2.4,0.7,3.5,0.9
      c0.7,0.1,1.3,0.2,1.9,0.2h0.1C164.8,25.1,166,25,167.1,24.7L167.1,24.7z"/>
    <path fill="none" d="M161.6,22.1v-0.6c-2.7-0.2-5.5-0.4-8.3-0.5v0.6c1.1,0.4,2.2,0.6,3.2,0.7l1.8,0.1l0.1,0
      C159.4,22.4,160.5,22.3,161.6,22.1L161.6,22.1z"/>
    <path fill="none" d="M156.8,23.8V23c-1.2-0.4-2.3-0.7-3.5-0.8l-2-0.1c-1.1,0-2.3,0.1-3.5,0.4v0.7c1.2,0.4,2.4,0.7,3.5,0.8
      c0.7,0.1,1.3,0.1,1.9,0.1h0.1C154.4,24.1,155.6,24,156.8,23.8L156.8,23.8z"/>
    <path fill="none" d="M151.3,21.5v-0.6c-2.7-0.1-5.5-0.2-8.3-0.2v0.5c1.1,0.3,2.2,0.5,3.2,0.6l1.8,0.1
      C149.2,21.8,150.3,21.7,151.3,21.5L151.3,21.5z"/>
    <path fill="none" d="M146.6,23.2v-0.7c-1-0.3-2-0.5-3-0.6l-2.5-0.1c-1.1,0.1-2.3,0.2-3.5,0.5v0.7c1.2,0.4,2.4,0.6,3.5,0.6
      c0.7,0.1,1.3,0.1,1.9,0.1h0.1C144.3,23.6,145.4,23.5,146.6,23.2L146.6,23.2z"/>
    <path fill="none" d="M141.1,21.2v-0.6l-8.3-0.1v0.5c1.1,0.3,2.2,0.4,3.2,0.5l1.8,0C138.9,21.5,140,21.4,141.1,21.2L141.1,21.2z"/>
    <path fill="none" d="M132.5,23.4L132.5,23.4c1.2-0.1,2.4-0.2,3.6-0.5v-0.7c-1.2-0.3-2.3-0.5-3.5-0.6h-2c-1.1,0.1-2.3,0.2-3.5,0.5
      v0.7c1.2,0.3,2.4,0.5,3.5,0.5C131.3,23.5,131.9,23.5,132.5,23.4L132.5,23.4z"/>
    <path fill="none" d="M130.6,21.1v-0.5l-8.3,0.1v0.5c1.1,0.2,2.2,0.4,3.2,0.4l1.8,0C128.5,21.5,129.6,21.3,130.6,21.1L130.6,21.1z"
      />
    <path fill="none" d="M125.9,22.9v-0.7c-1-0.2-2-0.4-3-0.5h-2.4c-1.1,0.1-2.3,0.3-3.5,0.6v0.7c1.2,0.3,2.4,0.4,3.5,0.4
      c0.7,0,1.3,0,1.9,0h0.1C123.6,23.4,124.7,23.2,125.9,22.9L125.9,22.9z"/>
    <path fill="none" d="M120.4,21.2v-0.5l-8.3,0.3v0.5c1.1,0.2,2.2,0.3,3.2,0.3l1.8-0.1C118.2,21.6,119.3,21.5,120.4,21.2L120.4,21.2z
      "/>
    <path fill="none" d="M115.6,23.1v-0.7c-1.2-0.2-2.3-0.4-3.5-0.4l-2,0.1c-1.1,0.1-2.3,0.4-3.5,0.7v0.7c1.2,0.2,2.4,0.4,3.5,0.3
      c0.7,0,1.3,0,1.9-0.1h0.1C113.2,23.7,114.4,23.5,115.6,23.1L115.6,23.1z"/>
    <path fill="none" d="M105.1,22.3l1.8-0.1c1.1-0.1,2.2-0.4,3.2-0.7V21c-2.7,0.1-5.5,0.3-8.3,0.5v0.6C103,22.3,104.1,22.3,105.1,22.3
      L105.1,22.3z"/>
    <path fill="none" d="M99.9,22.2v-0.5l-8.3,0.8v0.6c1.1,0.1,2.2,0.2,3.2,0.1l1.8-0.2l0.1,0C97.7,22.9,98.8,22.6,99.9,22.2L99.9,22.2
      z"/>
    <path fill="none" d="M105.4,23.7v-0.7c-1-0.2-2-0.3-3-0.3l-2.4,0.2c-1.1,0.2-2.3,0.5-3.5,0.9v0.8c1.2,0.2,2.4,0.2,3.5,0.2l1.9-0.2
      h0.1C103.1,24.3,104.2,24.1,105.4,23.7L105.4,23.7z"/>
    <path fill="none" d="M95.1,24.7v-0.8c-1-0.1-2-0.2-3-0.2L89.6,24c-1.1,0.2-2.3,0.6-3.5,1.2V26c1.2,0.1,2.4,0.1,3.5,0
      c0.7,0,1.3-0.1,1.9-0.3h0.1C92.7,25.5,93.9,25.1,95.1,24.7L95.1,24.7z"/>
    <path fill="none" d="M182.2,27.8l-2.2-0.4c-13.9-2.3-30.1-3.5-48.4-3.7c-18.4,0.2-34.5,1.5-48.4,3.7L81,27.8
      c-2.5,0.6-4.9,1.2-7.3,1.9c-4.5,1.3-8.8,2.9-12.7,4.9c-7.7,3.7-14.3,8.6-19.6,14.5l-0.1,0.2c-1.8,2.1-3.4,6.2-4.9,12
      c-2.8,20.1-4.9,42.7-6.3,68l-1.8,27.7v0l-0.1,1.4l-0.7,14.4l2.9,3c1.1-35.5,3.2-69,6.3-100.5l0-0.1c1.1-9.6,2.9-15.9,5.2-19.1
      c2.5-3.4,6.2-6.5,10.9-9.4c5-3,11.1-5.7,18.5-8.2c14.6-4.5,34.7-7,60.1-7.6c25.4,0.6,45.5,3.2,60.1,7.6c7.4,2.5,13.6,5.2,18.6,8.2
      l0.7,0.4c4.4,2.8,7.8,5.7,10.2,8.9c2.4,3.2,4.1,9.5,5.2,19.1l0,0.1c3.1,31.5,5.2,65,6.3,100.5l2.9-3l-0.7-14.4l-0.1-1.4v0
      l-1.8-27.7c-1.4-25.3-3.4-47.9-6.3-68c-1.5-5.9-3.1-9.9-4.9-12l-0.2-0.2c-5.3-5.9-11.8-10.7-19.6-14.5c-3.9-1.9-8.1-3.5-12.7-4.9
      C187.1,29,184.7,28.4,182.2,27.8L182.2,27.8z M174.4,31.7v-2.6l-2.8-0.5v-0.9c1.4,0.3,2.7,0.6,4.1,0.9v3.4L174.4,31.7L174.4,31.7z
       M166.1,26.7l1.5,0.2l-2.2,3.4l-1.6-0.2l-2-4l1.5,0.2c0.2,0.2,0.3,0.5,0.4,0.7l1.9,0.3L166.1,26.7L166.1,26.7z M157.2,29.5
      l-1.4-0.1l-2.6-2.5h0l0,2.3l-1.4-0.1l0.1-3.7l1.4,0.1l2.6,2.5h0l0-2.3l1.4,0.1L157.2,29.5L157.2,29.5z M141.6,27.4
      c-0.2-0.5-0.2-1,0-1.4c0.4-0.7,1.1-1.1,2-1c1.1,0,2.2,0.1,3.3,0.1l0,3.6l-3.2-0.2C142.7,28.4,142.1,28,141.6,27.4L141.6,27.4z
       M127.6,24.8h1.8l1.3,1.3h0v-1.3h1.4v3.4H130c-1.2,0-1.8-0.3-1.9-1c0-0.5,0.4-0.8,1.2-1L127.6,24.8L127.6,24.8z M122.1,27.9
      c-0.6,0.3-1.3,0.5-2.1,0.6c-0.8,0-1.5-0.1-2.1-0.4c-0.4-0.2-0.7-0.5-0.8-0.9l-0.1-0.4l0.1-0.4c0.4-1,1.3-1.5,3-1.6
      c1.9,0,2.9,0.6,3,1.8C123,27.1,122.7,27.6,122.1,27.9L122.1,27.9z M113,28.8l-1.5,0.1l-1.3-2.1l-1.3,2.3l-1.6,0.1l2.4-3.8l1.1-0.1
      L113,28.8L113,28.8z M100.5,27.5v-0.6l-1.7,0.2v-0.8l3.1-0.4v3.7l-3.1,0.4v-0.8l1.7-0.3v-0.6l-1.6,0.2v-0.8L100.5,27.5L100.5,27.5z
       M91.2,29L91.2,29l0-1.3l1.4-0.3v3.5l-2.1,0.4c-1.2,0.2-1.8,0-1.9-0.7c0-0.5,0.4-1,1.2-1.2l-1.7-1l1.8-0.4L91.2,29L91.2,29z"/>
    <path fill="none" d="M174.4,29.1v2.6l1.3,0.2v-3.4c-1.3-0.3-2.7-0.6-4.1-0.9v0.9L174.4,29.1L174.4,29.1z"/>
    <path fill="none" d="M167.6,26.9l-1.5-0.2l-0.4,0.6l-1.9-0.3c-0.1-0.2-0.2-0.5-0.4-0.7l-1.5-0.2l2,4l1.6,0.2L167.6,26.9L167.6,26.9
      z M164.6,29L164.6,29c-0.2-0.4-0.4-0.8-0.6-1.3c0.4,0,0.8,0.1,1.2,0.2L164.6,29L164.6,29z"/>
    <path fill="none" d="M164.6,29L164.6,29l0.7-1.1c-0.4-0.1-0.8-0.1-1.2-0.2C164.3,28.2,164.5,28.6,164.6,29L164.6,29z"/>
    <polygon fill="none" points="155.8,29.3 157.2,29.5 157.3,25.8 155.9,25.6 155.8,27.9 155.8,27.9 153.2,25.4 151.8,25.3 151.8,29 
      153.2,29.1 153.2,26.8 153.2,26.8 155.8,29.3 	"/>
    <path fill="none" d="M141.6,25.9c-0.2,0.5-0.2,0.9,0,1.4c0.4,0.7,1.1,1,1.9,1.1l3.2,0.2l0-3.6c-1.1-0.1-2.2-0.1-3.3-0.1
      C142.7,24.9,142.1,25.2,141.6,25.9L141.6,25.9z M144.3,27.7l-0.1,0c-0.3,0-0.6-0.1-0.9-0.3c-0.1-0.1-0.2-0.4-0.3-0.7l0-0.3
      c0-0.3,0.1-0.5,0.3-0.7c0.2-0.1,0.4-0.2,0.8-0.2l1.3,0.1l0,2.1L144.3,27.7L144.3,27.7z"/>
    <path fill="none" d="M144.1,27.7l0.1,0l1.1,0l0-2.1l-1.3-0.1c-0.4,0-0.6,0-0.8,0.2c-0.2,0.2-0.4,0.4-0.3,0.7l0,0.3
      c0.1,0.3,0.2,0.5,0.3,0.7C143.5,27.6,143.8,27.7,144.1,27.7L144.1,27.7z"/>
    <path fill="none" d="M129.3,24.8h-1.8l1.7,1.4c-0.8,0.1-1.2,0.4-1.2,1c0.1,0.7,0.7,1.1,1.9,1h2.1v-3.4h-1.4v1.3h0L129.3,24.8
      L129.3,24.8z M130.6,27.5c-0.5,0-0.9-0.1-1-0.4c0.1-0.3,0.4-0.5,1-0.4h0.1v0.9H130.6L130.6,27.5z"/>
    <path fill="none" d="M129.6,27.1c0.1,0.3,0.4,0.4,1,0.4h0.1v-0.9h-0.1C130,26.6,129.7,26.7,129.6,27.1L129.6,27.1z"/>
    <path fill="none" d="M120,28.5c0.8-0.1,1.5-0.3,2.1-0.6c0.6-0.4,0.9-0.8,0.9-1.3c-0.1-1.2-1.1-1.8-3-1.8c-1.6,0.1-2.6,0.6-3,1.6
      l-0.1,0.4l0.1,0.4c0.2,0.3,0.4,0.6,0.8,0.9C118.4,28.4,119.1,28.5,120,28.5L120,28.5z M118.4,26.8c0.1-0.7,0.6-1.1,1.5-1.1
      c1,0,1.5,0.3,1.5,1c-0.1,0.5-0.6,0.9-1.5,1C119.1,27.6,118.5,27.3,118.4,26.8L118.4,26.8z"/>
    <path fill="none" d="M119.9,25.7c-0.9,0-1.4,0.4-1.5,1.1c0.1,0.6,0.7,0.9,1.5,0.9c1-0.1,1.5-0.4,1.5-1
      C121.4,26,120.9,25.7,119.9,25.7L119.9,25.7z"/>
    <polygon fill="none" points="111.5,28.9 113,28.8 110.7,25.4 109.6,25.5 107.3,29.3 108.8,29.1 110.2,26.8 111.5,28.9 	"/>
    <polygon fill="none" points="100.5,26.9 100.5,27.5 98.9,27.7 98.9,28.5 100.5,28.3 100.5,28.9 98.8,29.1 98.8,29.9 101.9,29.5 
      101.9,25.9 98.8,26.3 98.8,27.1 100.5,26.9 	"/>
    <path fill="none" d="M91.3,29L91.3,29l-1.4-1l-1.8,0.4l1.7,1c-0.8,0.3-1.2,0.7-1.2,1.2c0.1,0.7,0.7,0.9,1.9,0.7l2.1-0.4v-3.5
      l-1.4,0.3V29L91.3,29z M91.1,29.6l0.1,0v0.9l-0.1,0c-0.5,0.1-0.9,0.1-1-0.3C90.3,29.9,90.6,29.6,91.1,29.6L91.1,29.6z"/>
    <path fill="none" d="M91.2,29.6l-0.1,0c-0.5,0.1-0.9,0.3-1,0.6c0.1,0.3,0.4,0.4,1,0.3l0.1,0V29.6L91.2,29.6z"/>
    <path fill="none" d="M179.8,24.8l-0.1-1.1l-5.9-0.9v0.7c1.1,0.5,2.2,0.8,3.2,1l1,0.2l0.7,0.1h0.1L179.8,24.8L179.8,24.8z"/>
    <path fill="none" d="M182.5,23.6l3.3,1.6c5.8,1.8,11.2,3.9,16.1,6.2c8.8,4.2,16,9.3,21.8,15.3l0.3-0.4l-0.2-1
      C211.3,33.7,197.5,26.5,182.5,23.6L182.5,23.6z"/>
    <path fill="none" d="M210,32.5c-10-6.1-19.2-9.1-27.5-8.9c15,2.9,28.7,10.1,41.2,21.6l1.4,1.3l-0.2-1.1
      C221.2,41.1,216.2,36.8,210,32.5L210,32.5z"/>
    <path fill="none" d="M226.4,75.2L226.4,75.2c-1.2-9.6-2.9-16-5.3-19.2c-2.4-3.2-5.8-6.2-10.2-8.9c4.5,3.5,7.4,9,8.6,16.5l6.2,102.3
      c0.4,0.8,0.9,1.7,1.2,2.6c0.7,1.7,1.2,3.5,1.5,5.6l0.4,2.9l3.8-1.2C231.6,140.2,229.5,106.7,226.4,75.2L226.4,75.2z"/>
    <path fill="none" d="M225.8,51.7c0,0.4-0.1,0.8-0.4,1.1l1.3,8.4c2.8,20.1,4.9,42.7,6.3,68l1.8,27.7c1.3-15.6,1.2-31.3-0.2-47.1
      c-2.9-38.1-6.5-61.4-11-69.9c0.5,1.7,0.9,3.6,1.3,5.5l0.2,1.1L225.8,51.7L225.8,51.7z"/>
    <path fill="none" d="M225.5,52.8c0.2-0.3,0.4-0.7,0.4-1.1l-0.7-5.2l-1.4-1.3l0.2,1l0.2,1L225.5,52.8L225.5,52.8z"/>
    <polygon fill="none" points="223.7,46.6 224.2,47.2 224,46.2 223.7,46.6 	"/>
    <path fill="none" d="M224.2,47.2l-0.5-0.6c0,1.7-0.6,2.5-1.8,2.6c1.8,2.1,3.4,6.2,4.9,12l-1.3-8.4L224.2,47.2L224.2,47.2z"/>
    <path fill="none" d="M210.9,47.1l-0.7-0.4c-5-3-11.2-5.7-18.6-8.2c-14.6-4.5-34.7-7-60.1-7.6c-25.4,0.6-45.5,3.2-60.1,7.6
      c-7.4,2.5-13.6,5.2-18.5,8.2c-4.9,3.4-8,9.1-9.2,17l-6.3,102.2c1.2-2.1,2.6-3.8,4.3-5l0.6-0.4c6-3.8,11.9-7.1,17.5-9.9l9.7-80.2
      c1.8-13.9,3.1-18.1,4-12.6l-4.2,88.6l3.1-1.2c15.4-4.3,30-7,43.7-8.2l1.8-0.2c5-0.4,9.9-0.6,14.9-0.6l16.2,0.9
      c13.1,1.3,27.1,4,41.8,8.1c1,0.4,2.1,0.8,3.1,1.2l-4.2-88.6c0.9-5.5,2.2-1.3,4,12.6l9.7,80.2c5.6,2.7,11.4,6,17.5,9.9l0.6,0.4
      c1.7,1.2,3.2,2.9,4.3,5l-6.2-102.3C218.3,56.1,215.5,50.6,210.9,47.1L210.9,47.1z M162.3,108.8c0.3,0,0.5,0.1,0.7,0.3
      c0.2,0.2,0.3,0.4,0.3,0.7v1.8c0,0.1-0.1,0.2-0.3,0.3l-0.7,0.1l-0.7-0.1c-0.2-0.1-0.3-0.2-0.3-0.3v-1.8c0-0.3,0.1-0.5,0.3-0.7
      C161.8,108.9,162,108.8,162.3,108.8L162.3,108.8z M100.8,108.8c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v1.8
      c0,0.1-0.1,0.2-0.3,0.3l-0.7,0.1l-0.7-0.1c-0.2-0.1-0.3-0.2-0.3-0.3v-1.8c0-0.3,0.1-0.5,0.3-0.7
      C100.3,108.9,100.6,108.8,100.8,108.8L100.8,108.8z"/>
    <path fill="none" d="M163,109.1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.8
      c0,0.1,0.1,0.2,0.3,0.3l0.7,0.1l0.7-0.1c0.2-0.1,0.3-0.2,0.3-0.3v-1.8C163.3,109.5,163.2,109.3,163,109.1L163,109.1z"/>
    <path fill="none" d="M101.5,109.1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.8
      c0,0.1,0.1,0.2,0.3,0.3l0.7,0.1l0.7-0.1c0.2-0.1,0.3-0.2,0.3-0.3v-1.8C101.8,109.5,101.7,109.3,101.5,109.1L101.5,109.1z"/>
    <path fill="none" d="M193.7,70.4c-1.8-13.9-3.1-18.1-4-12.6l4.2,88.6c3.1,1.2,6.2,2.6,9.4,4.2L193.7,70.4L193.7,70.4z"/>
    <polygon fill="none" points="258.6,218.8 259.3,216.9 229.8,208.9 229.8,209.7 229.9,211.1 233.5,212.1 236.7,213 258.6,218.8 	"/>
    <path fill="none" d="M251.8,203c-4.4-2.2-9.5-4.1-15.5-5.8c-1-0.3-2.1-0.6-3.2-0.9l0,0c-0.5-0.2-1-0.2-1.4,0.1
      c-1.5,1-2.1,5.2-1.9,12.5l29.5,8C258.8,210.2,256.3,205.6,251.8,203L251.8,203z"/>
    <path fill="none" d="M236.7,213l-3.2-0.9c0.7,33.4,0.9,61.7,0.8,84.9l0,3.8l3.3-2.1v-6.9c-0.4,0.1-0.8,0.1-1.3,0V285v-3.2
      c-0.6-1-1-3.4-1-7.4c0-3,0.3-5.2,0.8-6.5v-6.5c0.5-0.1,1,0.1,1.4,0.3C237.3,245.4,237.1,229.2,236.7,213L236.7,213z"/>
    <path fill="none" d="M236.4,197.1l-0.7-24.4l-2.9,3l0.5,20.5C234.3,196.6,235.3,196.8,236.4,197.1L236.4,197.1z"/>
    <path fill="none" d="M221.4,160.9l-0.6-0.4c-6-3.8-11.9-7.1-17.5-9.9c-3.2-1.6-6.4-3-9.4-4.2c-1.1-0.4-2.1-0.8-3.1-1.2
      c-14.7-4.1-28.7-6.8-41.8-8.1l-16.2-0.9c-5,0-9.9,0.1-14.9,0.6l-1.8,0.2c-13.7,1.2-28.2,4-43.7,8.2l-3.1,1.2
      c-3.1,1.2-6.2,2.6-9.4,4.2c-5.6,2.7-11.4,6-17.5,9.9l-0.6,0.4l0.5,3.5c20.3-7.9,44-13.1,71.1-15.6l0.1,0c2.8-0.3,5.7-0.6,8.7-0.7
      c3.1-0.2,6.3-0.3,9.5-0.3c3.2,0,6.3,0.1,9.3,0.3c2.9,0.2,5.8,0.4,8.7,0.7l0.1,0c27.1,2.5,50.8,7.7,71.1,15.6L221.4,160.9
      L221.4,160.9z"/>
    <path fill="none" d="M229.9,211.1l-0.1-1.4l-0.7-0.1l0.7,92.2l4.5-1l0-3.8c0.1-23.3-0.1-51.6-0.8-84.9L229.9,211.1L229.9,211.1z"/>
    <path fill="none" d="M226.8,209.2L226.8,209.2L224,211c-1.2,0.9-2.1,2.3-2.7,4.2l-7.1,35.5c0.5,17,0.7,33.9,0.7,50.7
      c4.7-2.3,8.8-5.6,12.5-9.7L226.8,209.2L226.8,209.2z"/>
    <path fill="none" d="M226.7,209.2L226.7,209.2l2.4,0.4l0.7,0.1l0-0.9c-0.2-7.3,0.5-11.5,1.9-12.5l-2.7-0.7l-2.2-0.6l-0.6-0.2V207
      C226.1,208.3,226.3,209.1,226.7,209.2L226.7,209.2z"/>
    <path fill="none" d="M224,211l2.7-1.8c-0.5-0.1-0.7-0.8-0.5-2.2v-12.1l0.6,0.2v-7.6l-5.5,27.7C221.9,213.3,222.8,211.9,224,211
      L224,211z"/>
    <polygon fill="none" points="229.1,209.6 226.8,209.2 227.4,291.7 227.8,291.2 227.8,302.3 229.8,301.8 229.1,209.6 	"/>
    <path fill="none" d="M229,195.6l2.7,0.7c0.4-0.3,0.9-0.3,1.4-0.1l0,0l-0.5-20.5l-3.8,1.2L229,195.6L229,195.6z"/>
    <polygon fill="none" points="226.8,195 229,195.6 228.9,177 226.8,187.4 226.8,195 	"/>
    <polygon fill="none" points="226.8,187.4 228.9,177 228.5,174.1 214.2,247.1 214.2,250.7 221.3,215.2 226.8,187.4 	"/>
    <path fill="none" d="M226.9,168.5c-0.4-0.9-0.8-1.8-1.2-2.6c-1.2-2.1-2.6-3.8-4.3-5l-0.5,3.5c-3.3,21.8-8.1,45.3-14.5,70.5
      c3.4,1.4,5.6,2.8,6.5,4.2L226.9,168.5L226.9,168.5z"/>
    <path fill="none" d="M228.5,174.1c-0.4-2.1-0.9-4-1.5-5.6l-14,70.6c0.1,0.2,0.2,0.4,0.3,0.6l0.9,7.3L228.5,174.1L228.5,174.1z"/>
    <path fill="none" d="M240,285.7l0-0.8l-3.8,0.1v6.7c0.5,0.1,0.9,0.1,1.3,0C239.2,291.5,240,289.4,240,285.7L240,285.7z"/>
    <path fill="none" d="M236.3,281.8v3.2l3.8-0.1c0.3-7.7,0-14.3-0.9-20c-0.3-1.6-0.9-2.7-1.7-3.2c-0.4-0.3-0.9-0.4-1.4-0.3v6.5
      c0.8,0.3,1.3,1.4,1.5,3.1l0,8.5C237.5,281.2,237.1,281.9,236.3,281.8L236.3,281.8z"/>
    <path fill="none" d="M237.6,298.7l-3.3,2.1c-0.1,31.8-0.7,63.5-2,95.1c-0.4,4.9-2.1,6.8-5,5.6l-11.3-4.1c-1.3-0.4-2-1.1-2-2.3
      l0.1-13.8l0-2l0.7-60.4l0.1-8.4l-1.6,0.3l0,1.3l-1,87.2l0.1,9.3c0.5,3.2,1.7,5.2,3.6,6.1l18.9,8.5l0.5-12.7c-0.5,0.1-1,0.1-1.6,0
      l0.2-6.7l0.1-3.2c-0.6-1-0.8-3.4-0.7-7.4c0.1-3,0.5-5.2,1-6.4l0.2-6.5c0.7-0.1,1.3,0.1,1.7,0.6l0.1-4.7
      C237.1,350,237.5,324.3,237.6,298.7L237.6,298.7z"/>
    <path fill="none" d="M236.1,267.9c-0.5,1.3-0.8,3.5-0.8,6.5c0,4,0.4,6.4,1,7.4c0.8,0.1,1.2-0.6,1.3-2.2l0-8.5
      C237.4,269.3,236.9,268.2,236.1,267.9L236.1,267.9z"/>
    <path fill="none" d="M202.9,233.6l3.6,1.3c6.4-25.3,11.2-48.8,14.5-70.5c-20.3-7.9-44-13.1-71.1-15.6l-0.1,0
      c-2.8-0.3-5.7-0.6-8.7-0.7c-3-0.2-6.2-0.3-9.3-0.3c-3.3,0-6.4,0.1-9.5,0.3c-3,0.2-5.9,0.4-8.7,0.7l-0.1,0
      c-27.1,2.5-50.8,7.7-71.1,15.6c3.3,21.8,8.1,45.3,14.5,70.5l3.5-1.3c18.3-6.5,36.7-10.6,55.3-12.4l8.8-0.7c2.4-0.1,4.8-0.2,7.1-0.2
      c2.5,0,4.9,0.1,7.4,0.2l8.8,0.7C166.1,223,184.6,227.1,202.9,233.6L202.9,233.6z M213.6,191.2l-2,8.1l-6.6,29.8
      c-0.7,2-2.7,2.3-5.8,0.9c-14.3-5.2-30.9-9.2-49.8-11.9c-1.7-0.3-2.7-1.7-3.1-4.3c-1.8-7.5-5.1-11.3-10-11.6
      c-1.5-0.1-3.1-0.1-4.7-0.1c-1.8,0-3.4,0-5,0.1c-4.9,0.3-8.2,4.1-10,11.6c-0.3,2.6-1.3,4-3.1,4.3c-18.9,2.7-35.5,6.6-49.8,11.9
      c-3.1,1.4-5,1.1-5.8-0.9l-6.7-29.8l-2-8.1c-0.7-3.3-0.4-6,0.9-8.1c4.8-7.1,9.7-11.5,14.8-13.1c20.3-7,42.4-10.6,66.4-10.9
      c24,0.3,46.1,4,66.4,10.9c5.1,1.7,10,6.1,14.7,13.1C214,185.2,214.3,187.9,213.6,191.2L213.6,191.2z"/>
    <path fill="none" d="M211.6,199.3l2-8.1c0.7-3.3,0.4-6-0.9-8.1c-4.8-7.1-9.7-11.5-14.7-13.1c-20.3-7-42.5-10.6-66.4-10.9
      c-23.9,0.3-46,4-66.4,10.9c-5.1,1.7-10,6.1-14.8,13.1c-1.2,2.1-1.5,4.8-0.9,8.1l2,8.1l6.7,29.8c0.7,2,2.6,2.3,5.8,0.9
      c14.3-5.2,30.9-9.2,49.8-11.9c1.7-0.3,2.7-1.7,3.1-4.3c1.8-7.5,5.1-11.3,10-11.6c1.6-0.1,3.2-0.1,5-0.1c1.7,0,3.2,0,4.7,0.1
      c4.9,0.3,8.2,4.1,10,11.6c0.3,2.6,1.3,4,3.1,4.3c18.9,2.7,35.5,6.6,49.8,11.9c3.1,1.4,5,1.1,5.8-0.9L211.6,199.3L211.6,199.3z"/>
    <path fill="none" d="M214.2,250.7l-0.1-3.6c-0.9,3-1.4,8-1.5,15l0.4,52l0.1-2.2l0-1.3l1.6-0.3l0-9
      C214.9,284.6,214.7,267.7,214.2,250.7L214.2,250.7z"/>
    <path fill="none" d="M213,239.1c-0.9-1.4-3.1-2.8-6.5-4.2c-0.8,5.8-1.2,12.9-1.3,21.4L200,419.1c1-0.2,1.9-0.4,2.9-0.4l1.5-96.8
      l-1.5,96.8c2.3-0.2,4.8-0.1,7.2,0.4l1-33.6c1-43.6,1.1-87.3,0.4-130.9C211.4,248.4,211.9,243.2,213,239.1L213,239.1z"/>
    <path fill="none" d="M201.4,256.3c0-8.5,0.5-16,1.5-22.7c-18.3-6.5-36.7-10.6-55.3-12.4l-8.8-0.7c-2.5-0.1-4.9-0.2-7.4-0.2
      c-2.4,0-4.8,0.1-7.1,0.2l-8.8,0.7c-18.5,1.8-36.9,6-55.3,12.4c1,6.6,1.5,14.2,1.5,22.7l3.1,96.1l1.4-0.4c4.6-1.1,9.3-2.1,14.2-3
      c10.2-1.8,21.1-3.1,32.7-3.8c5.9-0.4,12-0.6,18.3-0.7c6.4,0.1,12.5,0.3,18.5,0.7c11.6,0.7,22.5,2,32.7,3.8c4.9,0.9,9.7,1.8,14.3,3
      l1.4,0.4L201.4,256.3L201.4,256.3z M179.3,257c3.8,1.8,5.9,5.5,6.5,11.1v27.5c-0.5,6.3-4.2,9-11,8.1c-12.4-2.3-24.7-3.6-36.9-4
      l-12.7,0c-12.2,0.4-24.5,1.7-36.9,4c-6.7,0.9-10.4-1.8-11-8.1v-27.5c0.5-5.7,2.7-9.4,6.5-11.1c11.9-4.1,24.4-6.6,37.6-7.4l2.6-0.1
      c2.6-0.1,5.2-0.2,7.9-0.2c2.5,0,4.9,0.1,7.3,0.2l2.5,0.1C154.9,250.4,167.4,252.8,179.3,257L179.3,257z"/>
    <path fill="none" d="M185.8,268.1c-0.5-5.7-2.7-9.4-6.5-11.1c-11.9-4.1-24.4-6.6-37.6-7.4l-2.5-0.1c-2.4-0.1-4.9-0.2-7.3-0.2
      c-2.6,0-5.2,0-7.9,0.2l-2.6,0.1c-13.2,0.8-25.7,3.3-37.6,7.4c-3.8,1.8-5.9,5.5-6.5,11.1v27.5c0.6,6.3,4.2,9,11,8.1
      c12.4-2.3,24.7-3.6,36.9-4c-2.1,0-4.2,0.1-6.3,0.2c2.1-0.1,4.2-0.2,6.3-0.2l12.7,0c2.1,0,4.2,0.1,6.3,0.2c-2.1-0.1-4.2-0.2-6.3-0.2
      c12.2,0.4,24.5,1.7,36.9,4c6.8,0.9,10.4-1.8,11-8.1V268.1L185.8,268.1z"/>
    <path fill="none" d="M227.4,313.7l0.4-0.3v-11.1l-0.4,0.4c-3.2,3.3-7.4,5.8-12.6,7.7l-0.1,8.4C219.6,317.8,223.8,316.1,227.4,313.7
      L227.4,313.7z"/>
    <path fill="none" d="M227.8,313.4l-0.4,0.3c-0.1,20.6-0.6,40.8-1.4,60.6l0.6-0.3v2.3l-0.7,0.4L225,395c-0.2,1.4-0.9,2-2.3,1.8
      l0.6,0.1c2.2,0.2,3.6-0.4,4-2c1.6-30.1,2.4-61.1,2.4-92.9v-0.3l-2,0.4V313.4L227.8,313.4z"/>
    <path fill="none" d="M227.4,313.7c-3.6,2.3-7.8,4-12.6,5.1l-0.7,60.4c4.7-1.4,8.7-3,11.9-4.9C226.8,354.5,227.3,334.3,227.4,313.7
      L227.4,313.7z"/>
    <path fill="none" d="M227.4,302.7v-0.6V302.7l0.4-0.4v-11.1l-0.4,0.5c-3.6,4.1-7.8,7.4-12.5,9.7l0,9
      C220,308.5,224.2,306,227.4,302.7L227.4,302.7z"/>
    <path fill="none" d="M229.8,301.8v0.3c0,31.8-0.8,62.8-2.4,92.9l-0.1,1.8c-0.5,2.3-2.4,2.7-5.6,1.4L214,395c0,1.2,0.7,2,2,2.3
      l11.3,4.1c3,1.2,4.6-0.7,5-5.6c1.2-31.6,1.9-63.2,2-95.1L229.8,301.8L229.8,301.8z"/>
    <path fill="none" d="M234,403.6l-0.2,6.7c0.6,0.2,1.1,0.2,1.6,0c1.4-0.4,2.1-2.1,2.3-5.1l0.1-1.2l0-0.5L234,403.6L234,403.6z"/>
    <path fill="none" d="M237.7,405.3c-0.2,3-1,4.6-2.3,5.1l-0.5,12.7l2.1,0.2L237.7,405.3L237.7,405.3z"/>
    <path fill="none" d="M234,403.6l3.8,0.1l0.1-2.1l0.3-8.9l-0.6-9c-0.2-1.4-0.6-2.4-1.2-3c-0.5-0.5-1-0.7-1.7-0.6l-0.2,6.5
      c0.9,0.4,1.4,1.8,1.4,4.1l-0.2,6.8c-0.1,2.1-0.5,3.1-1.4,2.9L234,403.6L234,403.6z"/>
    <path fill="none" d="M237.8,404.1l-0.1,1.2l-0.7,17.9l5.3,0.4c0-6.1-0.1-12.1-0.3-18c-0.4-10.4-1.5-18.6-3.5-24.7l-0.3,11.7
      l-0.3,8.9l0,2.1L237.8,404.1L237.8,404.1z"/>
    <polygon fill="none" points="237.8,404.1 237.8,403.7 237.8,403.7 237.8,404.1 	"/>
    <polygon fill="none" points="237.8,403.7 237.8,403.7 237.9,401.6 237.8,403.7 	"/>
    <path fill="none" d="M238.5,380.9c-0.6-1.9-1.3-3.5-2-5l-0.1,4.7c0.6,0.6,1,1.6,1.2,3l0.6,9L238.5,380.9L238.5,380.9z"/>
    <path fill="none" d="M235.6,397.5l0.2-6.8c0-2.3-0.4-3.7-1.4-4.1c-0.6,1.3-0.9,3.4-1,6.4c-0.1,4,0.2,6.4,0.7,7.4
      C235,400.6,235.5,399.6,235.6,397.5L235.6,397.5z"/>
    <path fill="none" d="M222.7,396.9c1.4,0.2,2.1-0.4,2.3-1.8l0.9-18.3c-3,1.9-7,3.3-11.8,4.4L214,395L222.7,396.9L222.7,396.9z"/>
    <path fill="none" d="M223.3,397l-0.6-0.1L214,395l7.7,3.3c3.2,1.3,5.1,0.8,5.6-1.4l0.1-1.8C226.9,396.5,225.5,397.2,223.3,397
      L223.3,397z"/>
    <path fill="none" d="M226.6,374l-0.6,0.3c-3.2,1.9-7.1,3.5-11.9,4.9l0,2c4.8-1.1,8.8-2.6,11.8-4.4l0.7-0.4V374L226.6,374z"/>
    <path fill="none" d="M49,379.2l0,2l0.1,13.8c0,1.2-0.7,2-2,2.3l-11.3,4.1c-3,1.2-4.6-0.7-5-5.6c-1.2-31.6-1.9-63.2-2-95.1l-3.3-2.1
      c0.1,25.6,0.4,51.3,1.1,77.3l0.1,4.7c0.5-0.5,1-0.7,1.7-0.6l0.2,6.5c0.5,1.3,0.9,3.4,1,6.4c0.1,4-0.2,6.4-0.7,7.4l0.1,3.2l0.2,6.7
      c-0.6,0.2-1.1,0.2-1.6,0l0.5,12.7l18.9-8.5c1.9-0.9,3.1-2.9,3.6-6.1l0.1-9.3l-1-87.2l0-1.3l-1.6-0.3l0.1,8.4L49,379.2L49,379.2z"/>
    <path fill="none" d="M49,381.2l0-2c-4.7-1.4-8.7-3-11.9-4.9l-0.6-0.3v2.3l0.7,0.4C40.3,378.6,44.2,380.1,49,381.2L49,381.2z"/>
    <path fill="none" d="M49.2,395L49,381.2c-4.8-1.1-8.7-2.6-11.8-4.4l0.9,18.3c0.2,1.4,0.9,2,2.3,1.8L49.2,395L49.2,395z"/>
    <path fill="none" d="M35.9,401.5l11.3-4.1c1.3-0.4,2-1.1,2-2.3l-7.7,3.3c-3.2,1.3-5.1,0.8-5.6-1.4l-0.1-1.8
      c-1.7-30.1-2.5-61.1-2.4-92.9v-0.3l-4.5-1c0.1,31.8,0.7,63.5,2,95.1C31.2,400.8,32.9,402.7,35.9,401.5L35.9,401.5z"/>
    <path fill="none" d="M35.9,396.8c0.5,2.3,2.4,2.7,5.6,1.4l7.7-3.3l-8.8,1.8l-0.6,0.1c-2.2,0.2-3.6-0.4-4-2L35.9,396.8L35.9,396.8z"
      />
    <path fill="none" d="M39.8,397l0.6-0.1c-1.4,0.2-2.1-0.4-2.3-1.8l-0.9-18.3l-0.7-0.4V374l0.6,0.3c-0.8-19.9-1.3-40.1-1.4-60.6
      l-0.4-0.3v-11.1l-2-0.4v0.3c0,31.8,0.8,62.8,2.4,92.9C36.3,396.5,37.6,397.2,39.8,397L39.8,397z"/>
    <path fill="none" d="M26.7,376c-0.8,1.4-1.4,3.1-2,5l0.3,11.7l0.6-9c0.2-1.4,0.6-2.4,1.2-3L26.7,376L26.7,376z"/>
    <path fill="none" d="M28.5,380.1c-0.7-0.1-1.2,0.1-1.7,0.6c-0.6,0.6-1,1.6-1.2,3l-0.6,9l0.3,10l0.1,1l3.8-0.1l-0.1-3.2
      c-0.9,0.1-1.4-0.8-1.4-2.9l-0.2-6.8c0-2.3,0.4-3.7,1.4-4.1L28.5,380.1L28.5,380.1z"/>
    <path fill="none" d="M29,400.4c0.6-1,0.8-3.4,0.7-7.4c-0.1-3-0.5-5.2-1-6.4c-0.9,0.4-1.4,1.8-1.4,4.1l0.2,6.8
      C27.6,399.6,28.1,400.6,29,400.4L29,400.4z"/>
    <path fill="none" d="M25,392.7l-0.3-11.7c-2,6.1-3.2,14.3-3.5,24.7c-0.2,5.9-0.3,11.9-0.3,18l5.3-0.4l-0.8-18.9v-0.2l0-0.5l0-1
      L25,392.7L25,392.7z"/>
    <path fill="none" d="M37.1,374.3c3.2,1.9,7.1,3.5,11.9,4.9l-0.7-60.4c-4.8-1.1-9.1-2.8-12.6-5.1C35.8,334.3,36.3,354.5,37.1,374.3
      L37.1,374.3z"/>
    <path fill="none" d="M53.1,419c2.5-0.5,4.9-0.6,7.3-0.4l-1.5-96.8l1.5,96.8c1,0.1,1.9,0.2,2.9,0.4L58,256.3
      c0-8.5-0.4-15.6-1.2-21.4c-3.4,1.4-5.6,2.8-6.5,4.2c1.1,4.1,1.5,9.2,1.5,15.3c-0.8,43.6-0.7,87.3,0.3,130.9L53.1,419L53.1,419z"/>
    <path fill="none" d="M60.3,418.7c-2.3-0.2-4.8-0.1-7.3,0.4l2.8,85.9c0.8-0.3,1.7-0.6,2.4-0.8c1.3-0.4,2.6-0.5,3.8-0.6L60.3,418.7
      L60.3,418.7z"/>
    <path fill="none" d="M53.1,419l-0.5,0.1l-14.2,6.5c-2.9,0.7-4.3,2.7-4.4,6.1l0.1,1.4c0.6,23.2,1.8,46.1,3.5,68.8l0.4,5.9
      c1,4.7,2.1,6.7,3.4,5.7c5.5-4.3,10.3-7.2,14.5-8.7L53.1,419L53.1,419z M36,438.1c0.1-2.6,1-4.3,2.7-5.2l9.3-4.1
      c2.1-0.9,3.2,0.7,3.4,4.8l2.2,60.8c0.2,2.6-0.7,4.6-2.5,5.9l-5.9,3.8c-2.1,0.8-4.1,0.1-5.8-2.2C37.8,480.9,36.7,459.6,36,438.1
      L36,438.1z"/>
    <path fill="none" d="M38.7,432.9c-1.8,0.9-2.7,2.6-2.7,5.2c0.7,21.5,1.8,42.7,3.4,63.8c1.8,2.3,3.7,3,5.8,2.2l5.9-3.8
      c1.8-1.3,2.7-3.3,2.5-5.9l-2.2-60.8c-0.2-4.1-1.3-5.7-3.4-4.8L38.7,432.9L38.7,432.9z"/>
    <path fill="none" d="M52.6,419.1l0.5-0.1l-1-33.6c-1-43.6-1.1-87.3-0.3-130.9c0.1-6.1-0.4-11.2-1.5-15.3l-0.3,0.6l-0.9,7.3
      c0.9,3,1.4,8,1.5,15l-0.4,52l-0.1-2.2l1,87.2l-0.1,9.3c-0.5,3.2-1.7,5.2-3.6,6.1l-18.9,8.5c1.1,22.9,2.6,45.6,4.5,67.9l2.5,27
      c1.2-1.9,2.9,0.2,5.1,6.3l12.6-9.5c2.4-1.8,3.4-5.1,3-9.8c-4.1,1.5-9,4.5-14.5,8.7c-1.3,0.9-2.4-1-3.4-5.7l-0.4-5.9
      c-1.7-22.7-2.9-45.7-3.5-68.8l-0.1-1.4c0-3.4,1.5-5.4,4.4-6.1L52.6,419.1L52.6,419.1z"/>
    <path fill="none" d="M63.2,419.1c-1-0.2-1.9-0.4-2.9-0.4l1.8,84.9c1.4-0.1,2.6,0.1,3.8,0.5L63.2,419.1L63.2,419.1z"/>
    <path fill="none" d="M27.8,410.4c0.4,0.1,1,0.1,1.6,0l-0.2-6.7l-3.8,0.1l0,0.5v0.2l0,0.1C25.5,408,26.3,409.9,27.8,410.4
      L27.8,410.4z"/>
    <path fill="none" d="M25.4,404.5L25.4,404.5l0.8,18.8l2.1-0.2l-0.5-12.7C26.3,409.9,25.5,408,25.4,404.5L25.4,404.5z"/>
    <polygon fill="none" points="25.3,404.1 25.3,403.7 25.3,403.7 25.3,404.1 	"/>
    <polygon fill="none" points="25.3,403.7 25.3,403.7 25.3,402.7 25.3,403.7 	"/>
    <path fill="none" d="M26.1,423.3l-5.3,0.4c0.1,13.1,0.7,26.5,1.9,40.2c1.1,11.7,3.7,20,7.6,24.8C28.6,467.1,27.2,445.3,26.1,423.3
      L26.1,423.3z"/>
    <path fill="none" d="M28.3,423.1l-2.1,0.2c1,22.1,2.4,43.9,4.2,65.4c0.7,0.9,1.5,1.7,2.4,2.3C30.8,468.7,29.4,446,28.3,423.1
      L28.3,423.1z"/>
    <path fill="none" d="M60.3,233.6l-3.5,1.3c0.8,5.8,1.2,12.9,1.2,21.4l5.2,162.8l2.7,85l0.3,8.2l4,0.2l-0.1-1.9l-0.4-7.6l-4.8-150.5
      l-3.1-96.1C61.7,247.8,61.2,240.2,60.3,233.6L60.3,233.6z"/>
    <path fill="none" d="M49.9,239.8l0.3-0.6l-14-70.6c-0.7,1.7-1.2,3.5-1.6,5.6l14.3,73L49.9,239.8L49.9,239.8z"/>
    <path fill="none" d="M50.2,239.1c0.9-1.4,3.1-2.8,6.5-4.2c-6.4-25.3-11.2-48.8-14.5-70.5l-0.5-3.5c-1.7,1.2-3.1,2.9-4.3,5
      c-0.4,0.8-0.8,1.7-1.2,2.6L50.2,239.1L50.2,239.1z"/>
    <path fill="none" d="M25.7,261.8c-0.8,0.5-1.3,1.5-1.7,3.2c-0.9,5.6-1.2,12.3-0.9,20l3.8,0.1v-3.2c-0.8,0.1-1.2-0.6-1.3-2.2l0-8.5
      c0.1-1.8,0.6-2.8,1.5-3.1v-6.5C26.5,261.4,26.1,261.5,25.7,261.8L25.7,261.8z"/>
    <path fill="none" d="M25.7,261.8c0.4-0.3,0.9-0.4,1.4-0.3v6.5c0.5,1.3,0.8,3.5,0.8,6.5c0,4-0.4,6.4-1,7.4v3.2v6.7
      c-0.5,0.1-0.9,0.1-1.3,0v6.9l3.3,2.1l0-3.8c-0.2-23.3,0.1-51.6,0.8-84.9l-3.2,0.9C26,229.2,25.8,245.4,25.7,261.8L25.7,261.8z"/>
    <path fill="none" d="M27.9,274.4c0-3-0.3-5.2-0.8-6.5c-0.8,0.3-1.3,1.4-1.5,3.1l0,8.5c0.1,1.6,0.5,2.3,1.3,2.2
      C27.5,280.8,27.8,278.4,27.9,274.4L27.9,274.4z"/>
    <path fill="none" d="M50.4,262.1c-0.1-7-0.6-12-1.5-15l-0.1,3.6c-0.5,17-0.7,33.9-0.6,50.7l0,9l1.6,0.3l0,1.3l0.1,2.2L50.4,262.1
      L50.4,262.1z"/>
    <polygon fill="none" points="48.9,250.7 49,247.1 34.6,174.1 34.3,177 36.3,187.4 41.8,215.2 48.9,250.7 	"/>
    <polygon fill="none" points="33.3,301.8 35.3,302.3 35.3,291.2 35.8,291.7 36.3,209.2 34,209.6 33.3,301.8 	"/>
    <path fill="none" d="M48.3,310.4l0-9c-4.7-2.3-8.8-5.6-12.5-9.7l-0.5-0.5v11.1l0.4,0.4v-0.6v0.6C38.9,306,43.1,308.5,48.3,310.4
      L48.3,310.4z"/>
    <path fill="none" d="M48.3,310.4c-5.2-1.9-9.3-4.4-12.6-7.7l-0.4-0.4v11.1l0.4,0.3c3.6,2.3,7.8,4,12.6,5.1L48.3,310.4L48.3,310.4z"
      />
    <path fill="none" d="M23.1,285.7c0,3.7,0.8,5.7,2.4,6.1c0.4,0.1,0.8,0.1,1.3,0V285l-3.8-0.1L23.1,285.7L23.1,285.7z"/>
    <path fill="none" d="M28.9,297.1l0,3.8l4.5,1l0.7-92.2l-0.7,0.1l0,1.4l-3.7,1C29,245.5,28.7,273.8,28.9,297.1L28.9,297.1z"/>
    <path fill="none" d="M35.8,291.7c3.6,4.1,7.8,7.4,12.5,9.7c0-16.8,0.2-33.7,0.6-50.7l-7.1-35.5c-0.6-1.9-1.5-3.3-2.7-4.2l-2.7-1.8
      l-0.1,0L35.8,291.7L35.8,291.7z"/>
    <path fill="none" d="M59.8,150.6c3.2-1.6,6.4-3,9.4-4.2l4.2-88.6c-0.9-5.5-2.2-1.3-4,12.6L59.8,150.6L59.8,150.6z"/>
    <path fill="none" d="M28.3,156.9L28.3,156.9c-1.3-15.7-1.2-31.3,0.2-47.1c2.9-38.1,6.5-61.4,11-69.9l0.5-1.7l-6.3,4.1
      c-2.9,2.3-5.3,8.1-7,17.4c-2.3,14.8-3.6,33.6-3.8,56.3c0.2,21.5,2,35.6,5.3,42.2L28.3,156.9L28.3,156.9z"/>
    <path fill="none" d="M28.3,156.8l1.8-27.7c1.4-25.3,3.4-47.9,6.3-68l1.3-8.4c-0.2-0.3-0.4-0.7-0.4-1.1l0.7-5.2l0.2-1.1
      c0.4-1.9,0.8-3.8,1.3-5.5c-4.5,8.5-8.1,31.8-11,69.9C27.1,125.5,27,141.2,28.3,156.8L28.3,156.8z"/>
    <polygon fill="none" points="36.3,187.4 34.3,177 34.1,195.6 36.3,195 36.3,187.4 	"/>
    <path fill="none" d="M34.6,174.1c0.4-2.1,0.9-4,1.6-5.6c0.4-1,0.8-1.8,1.2-2.6l6.3-102.2c1.2-7.9,4.3-13.5,9.2-17
      c-4.8,2.9-8.4,6-10.9,9.4c-2.4,3.2-4.1,9.5-5.2,19.1l0,0.1c-3.1,31.5-5.2,65-6.3,100.5l3.8,1.2L34.6,174.1L34.6,174.1z"/>
    <path fill="none" d="M30.5,175.7l-2.9-3c-0.3,8.1-0.6,16.3-0.7,24.4c1-0.3,2.1-0.6,3.2-0.9L30.5,175.7L30.5,175.7z"/>
    <path fill="none" d="M34.3,177l-3.8-1.2L30,196.3l0,0c0.5-0.2,1-0.2,1.4,0.1l2.7-0.7L34.3,177L34.3,177z"/>
    <path fill="none" d="M33.3,209.7l0.7-0.1l2.3-0.4l0.1,0c0.5-0.1,0.7-0.8,0.5-2.2v-12.1l-0.6,0.2l-2.2,0.6l-2.7,0.7
      c1.5,1,2.1,5.2,1.9,12.5L33.3,209.7L33.3,209.7z"/>
    <polygon fill="none" points="33.3,211.1 33.3,209.7 33.3,208.9 3.9,216.9 4.5,218.8 26.4,213 29.6,212.1 33.3,211.1 	"/>
    <path fill="none" d="M36.4,209.2l2.7,1.8c1.2,0.9,2.1,2.3,2.7,4.2l-5.5-27.7v7.6l0.6-0.2V207C37.1,208.3,36.9,209.1,36.4,209.2
      L36.4,209.2z"/>
    <path fill="none" d="M30,196.3c-1.1,0.3-2.1,0.6-3.2,0.9c-5.9,1.7-11.1,3.7-15.5,5.8c-4.5,2.6-7,7.3-7.5,13.9l29.5-8
      c0.2-7.3-0.5-11.5-1.9-12.5C31,196.1,30.5,196,30,196.3L30,196.3L30,196.3z"/>
    <path fill="none" d="M89.6,23.4v-0.6l-6.1,0.9l-0.1,1.1l2.9-0.4h0.1C87.4,24.2,88.5,23.8,89.6,23.4L89.6,23.4z"/>
    <path fill="none" d="M83.2,27.5l0.1-2.2L74,26.7l-0.3,3c2.4-0.7,4.8-1.3,7.3-1.9L83.2,27.5L83.2,27.5z"/>
    <polygon fill="none" points="74,26.7 83.3,25.3 83.4,24.8 83.4,23.7 83.5,23.1 80.7,23.6 80.6,23.6 77.3,25.2 74,26.7 	"/>
    <path fill="none" d="M74,26.7l3.3-1.6c-5.8,1.8-11.2,3.9-16,6.2L61,34.6c3.9-1.9,8.1-3.5,12.7-4.9L74,26.7L74,26.7z"/>
    <path fill="none" d="M67.7,17.4l1.8-0.7l-1.8,0c-5.3,2.1-10,4.4-13.9,6.8l-0.5,1l0.5-0.3C57.7,21.8,62.3,19.5,67.7,17.4L67.7,17.4z
      "/>
    <path fill="none" d="M61,34.6l0.2-3.2c-8.8,4.2-16.1,9.3-21.8,15.3c0,1.7,0.6,2.5,1.8,2.6l0.1-0.2C46.7,43.1,53.3,38.3,61,34.6
      L61,34.6z"/>
    <path fill="none" d="M80.6,23.6c-8.3-0.2-17.5,2.8-27.5,8.9C47,36.8,42,41.1,38.2,45.4L38,46.5l1.4-1.3
      C51.9,33.7,65.6,26.5,80.6,23.6L80.6,23.6z"/>
    <path fill="none" d="M77.3,25.2l3.3-1.6c-15,2.9-28.7,10.1-41.2,21.6l-0.2,1l0.3,0.4c5.7-6,13-11.1,21.8-15.3
      C66.1,29,71.4,26.9,77.3,25.2L77.3,25.2z"/>
    <path fill="none" d="M39.2,46.2l0.2-1L38,46.5l-0.7,5.2c0,0.4,0.1,0.8,0.4,1.1l1.3-5.6L39.2,46.2L39.2,46.2z"/>
    <path fill="none" d="M39.5,46.6l-0.5,0.6l-1.3,5.6l-1.3,8.4c1.5-5.9,3.1-9.9,4.9-12C40.1,49.1,39.5,48.3,39.5,46.6L39.5,46.6z"/>
    <polygon fill="none" points="38.9,47.2 39.5,46.6 39.2,46.2 38.9,47.2 	"/>
    <path fill="none" d="M146,527.5c0.1-1.4-1.3-2.2-4.2-2.3h-20.6c-2.9,0.2-4.2,0.9-4.1,2.3l14,0.3L146,527.5L146,527.5z"/>
    <polygon fill="none" points="106.5,547.4 108,545 110.4,541.2 109.7,541 106.3,540.1 103.3,546 106.5,547.4 	"/>
    <polygon fill="none" points="109.7,541 110.4,541.2 110.5,541.2 109.7,541 	"/>
    <path fill="none" d="M103.3,546l3.1-5.9l-17.9-0.7c-14.7-0.3-24.5-0.6-29.2-0.9l2.5,5.6C75.7,545.5,89.6,546.1,103.3,546L103.3,546
      z"/>
    <path fill="none" d="M106.5,547.4l-3.2-1.4c-13.7,0.1-27.5-0.5-41.5-2v1.6C75.8,546.8,90.8,547.4,106.5,547.4L106.5,547.4z"/>
    <path fill="none" d="M58.3,504.1c-0.8,0.2-1.6,0.5-2.4,0.8c0.4,4.7-0.5,8-3,9.8l-12.6,9.5c6.3,8.4,12,12.4,17.3,12l6.7-11.9
      c1.7-3.3,2.4-6.7,2-10.1l-0.1-2.1l-0.1,0l0.1,0l-0.3-8.2c-1.1-0.4-2.4-0.6-3.8-0.5C61,503.6,59.7,503.8,58.3,504.1L58.3,504.1z"/>
    <path fill="none" d="M66.2,512.2l0.1,2.1c0.4,3.4-0.3,6.7-2,10.1l38.8,2.3V514c-11.1-0.3-22.1-0.8-32.9-1.5L66.2,512.2L66.2,512.2z
      "/>
    <path fill="none" d="M35.3,519l0.1,0.4c1.4,5.3,2.6,8.3,3.6,9.2c6,7,11.9,11,17.9,12.1l0.2-2.5c0.1-0.7,0.3-1.4,0.5-2
      c-5.2,0.3-11-3.7-17.3-12c-2.2-6.1-3.9-8.2-5.1-6.3L35.3,519L35.3,519z"/>
    <polygon fill="none" points="58.3,538.4 57.1,538.3 56.9,540.8 59.5,541 58.3,538.4 	"/>
    <polygon fill="none" points="60.5,543 61.8,545.6 61.8,544 59.2,538.5 58.3,538.4 59.5,541 60.5,543 	"/>
    <polygon fill="none" points="60.5,543 59.5,541 56.9,540.8 57.1,542.7 57.1,542.8 60.5,543 	"/>
    <path fill="none" d="M57.2,544l-0.2-1.2l-0.1,0c-1.8,0.1-3.2-0.1-4.1-0.5c-4.1-1.4-9.1-5.8-15-13.2c2.7,5.3,7.4,9.8,13.8,13.5
      C52.9,543.4,54.8,543.9,57.2,544L57.2,544z"/>
    <polygon fill="none" points="57.1,542.7 56.9,542.8 57.1,542.8 57.1,542.7 	"/>
    <path fill="none" d="M56.9,542.8l0.1,0l-0.1-2c-6-1.1-11.9-5.2-17.9-12.1c-1-0.9-2.2-4-3.6-9.2c0.5,3.1,1.2,6,2.1,8.9l0.4,0.7
      c5.9,7.4,10.9,11.8,15,13.2C53.7,542.6,55.1,542.8,56.9,542.8L56.9,542.8z"/>
    <polygon fill="none" points="37.5,528.3 37.7,528.9 37.8,529 37.5,528.3 	"/>
    <path fill="none" stroke="#000000" stroke-width="0.5" d="M161.6,21.5c-2.7-0.2-5.5-0.4-8.3-0.5v0.6c1.1,0.4,2.2,0.6,3.2,0.7
      l1.8,0.1l0.1,0c1.1,0,2.2-0.1,3.2-0.3V21.5L161.6,21.5z M167.1,23.9c-1-0.4-2-0.7-3-0.9l-2.4-0.3c-1.1,0-2.3,0.1-3.5,0.3v0.8
      c1.2,0.4,2.4,0.7,3.5,0.9c0.7,0.1,1.3,0.2,1.9,0.2h0.1c1.1,0.1,2.3,0,3.5-0.2V23.9L167.1,23.9z M171.8,23.8c-1.1-0.1-2.3,0-3.5,0.2
      v0.8c1.2,0.5,2.4,0.9,3.5,1.1c0.7,0.1,1.3,0.2,1.9,0.2l0.1,0c1.1,0.1,2.3,0.1,3.5-0.1v-0.9c-1.2-0.5-2.3-0.9-3.5-1.1l-0.2-0.1
      L171.8,23.8L171.8,23.8z M171.9,22.5c-2.7-0.3-5.5-0.6-8.3-0.9v0.6c1.1,0.4,2.2,0.7,3.2,0.9l1.8,0.2h0c1.1,0.1,2.2,0,3.2-0.1V22.5
      L171.9,22.5z M179.7,23.6l-5.9-0.9v0.7c1.1,0.5,2.2,0.8,3.2,1l1,0.2l0.7,0.1h0.1l1,0.1 M37.7,528.9l0.2,0.2
      c5.9,7.4,10.9,11.8,15,13.2c0.9,0.4,2.2,0.6,4.1,0.5l0.1,0 M211.6,199.3l-6.6,29.8c-0.7,2-2.7,2.3-5.8,0.9
      c-14.3-5.2-30.9-9.2-49.8-11.9c-1.7-0.3-2.7-1.7-3.1-4.3c-1.8-7.5-5.1-11.3-10-11.6c-1.5-0.1-3.1-0.1-4.7-0.1c-1.8,0-3.4,0-5,0.1
      c-4.9,0.3-8.2,4.1-10,11.6c-0.3,2.6-1.3,4-3.1,4.3c-18.9,2.7-35.5,6.6-49.8,11.9c-3.1,1.4-5,1.1-5.8-0.9l-6.7-29.8l-2-8.1
      c-0.7-3.3-0.4-6,0.9-8.1c4.8-7.1,9.7-11.5,14.8-13.1c20.3-7,42.4-10.6,66.4-10.9c24,0.3,46.1,4,66.4,10.9c5.1,1.7,10,6.1,14.7,13.1
      c1.2,2.1,1.5,4.8,0.9,8.1L211.6,199.3L211.6,199.3z M42.3,164.4c20.3-7.9,44-13.1,71.1-15.6l0.1,0c2.8-0.3,5.7-0.6,8.7-0.7
      c3.1-0.2,6.3-0.3,9.5-0.3c3.2,0,6.3,0.1,9.3,0.3c2.9,0.2,5.8,0.4,8.7,0.7l0.1,0c27.1,2.5,50.8,7.7,71.1,15.6 M156.8,23
      c-1.2-0.4-2.3-0.7-3.5-0.8l-2-0.1c-1.1,0-2.3,0.1-3.5,0.4v0.7c1.2,0.4,2.4,0.7,3.5,0.8c0.7,0.1,1.3,0.1,1.9,0.1h0.1
      c1.1,0,2.3-0.1,3.5-0.3V23L156.8,23z M151.3,20.9c-2.7-0.1-5.5-0.2-8.3-0.2v0.5c1.1,0.3,2.2,0.5,3.2,0.6l1.8,0.1
      c1.1,0,2.1-0.1,3.2-0.4V20.9L151.3,20.9z M146.6,22.5c-1-0.3-2-0.5-3-0.6l-2.5-0.1c-1.1,0.1-2.3,0.2-3.5,0.5v0.7
      c1.2,0.4,2.4,0.6,3.5,0.6c0.7,0.1,1.3,0.1,1.9,0.1h0.1c1.1,0,2.3-0.1,3.5-0.4V22.5L146.6,22.5z M141.1,20.6l-8.3-0.1v0.5
      c1.1,0.3,2.2,0.4,3.2,0.5l1.8,0c1.1-0.1,2.2-0.2,3.2-0.4V20.6L141.1,20.6z M132.6,23.4c1.1-0.1,2.3-0.2,3.5-0.5v-0.7
      c-1.2-0.3-2.3-0.5-3.5-0.6h-2c-1.1,0.1-2.3,0.2-3.5,0.5v0.7c1.2,0.3,2.4,0.5,3.5,0.5C131.3,23.5,131.9,23.5,132.6,23.4L132.6,23.4
      L132.6,23.4z M130.6,20.5l-8.3,0.1v0.5c1.1,0.2,2.2,0.4,3.2,0.4l1.8,0c1.1-0.1,2.2-0.2,3.2-0.5V20.5L130.6,20.5z M125.9,22.2
      c-1-0.2-2-0.4-3-0.5h-2.4c-1.1,0.1-2.3,0.3-3.5,0.6v0.7c1.2,0.3,2.4,0.4,3.5,0.4c0.7,0,1.3,0,1.9,0h0.1c1.1-0.1,2.3-0.3,3.5-0.6
      V22.2L125.9,22.2z M120.4,20.6l-8.3,0.3v0.5c1.1,0.2,2.2,0.3,3.2,0.3l1.8-0.1c1.1-0.1,2.2-0.3,3.2-0.5V20.6L120.4,20.6z
       M115.6,22.4c-1.2-0.2-2.3-0.4-3.5-0.4l-2,0.1c-1.1,0.1-2.3,0.4-3.5,0.7v0.7c1.2,0.2,2.4,0.4,3.5,0.3c0.7,0,1.3,0,1.9-0.1h0.1
      c1.1-0.1,2.3-0.3,3.5-0.7V22.4L115.6,22.4z M106.9,22.2c1.1-0.1,2.2-0.4,3.2-0.7V21c-2.7,0.1-5.5,0.3-8.3,0.5v0.6
      c1.1,0.2,2.2,0.2,3.2,0.2L106.9,22.2L106.9,22.2z M105.4,22.9c-1-0.2-2-0.3-3-0.3l-2.4,0.2c-1.1,0.2-2.3,0.5-3.5,0.9v0.8
      c1.2,0.2,2.4,0.2,3.5,0.2l1.9-0.2h0.1c1.1-0.2,2.3-0.4,3.5-0.8V22.9L105.4,22.9z M99.9,21.7l-8.3,0.8v0.6c1.1,0.1,2.2,0.2,3.2,0.1
      l1.8-0.2l0.1,0c1.1-0.2,2.1-0.4,3.2-0.8V21.7L99.9,21.7z M95.1,23.9c-1-0.1-2-0.2-3-0.2L89.6,24c-1.1,0.2-2.3,0.6-3.5,1.2V26
      c1.2,0.1,2.4,0.1,3.5,0c0.7,0,1.3-0.1,1.9-0.3h0.1c1.1-0.2,2.3-0.6,3.5-1V23.9L95.1,23.9z M83.4,24.8l2.9-0.4h0.1
      c1.1-0.2,2.2-0.6,3.2-1v-0.6l-6.1,0.9"/>
    <path fill="none" stroke="#000000" stroke-width="0.5" d="M225.5,528.9l-0.2,0.2c-5.9,7.4-10.9,11.8-15,13.2
      c-0.9,0.4-2.2,0.6-4.1,0.5l-0.1,0 M35.4,519.4c1.4,5.3,2.6,8.3,3.6,9.2c6,7,11.9,11,17.9,12.1 M227.7,519.4
      c-1.4,5.3-2.6,8.3-3.6,9.2c-6,7-11.9,11-17.9,12.1"/>
    <path fill="none" stroke="#000000" stroke-width="0.5" d="M160.2,526.7V514 M222.8,524.2c2.2-6.1,3.9-8.2,5.1-6.3 M232.8,488.7
      c4-4.8,6.5-13.1,7.6-24.8c1.2-13.8,1.9-27.2,1.9-40.2 M198.9,524.4l-38.6,2.2 M190.6,529.4c-0.5-2-1.8-2.9-4-2.9l-28.1,1.6
      c-3.9,0.3-8.1,0.5-12.5,0.7l-14.3,0.3l-14.6-0.3c-4.5-0.2-8.7-0.4-12.6-0.7l-28.1-1.6c-2.1-0.1-3.4,0.9-4,2.9l-2.5,5.8
      c-1,1.5-0.4,2.3,1.8,2.3c15.4,1,29.6,1.3,42.6,0.9c2.6,0.1,5.7-0.4,9.4-1.4c2-0.5,4.6-0.8,7.8-0.9c3.1,0.2,5.6,0.5,7.7,0.9
      c3.7,1,6.8,1.5,9.4,1.4c13,0.4,27.2,0.1,42.6-0.9c2.1,0,2.7-0.8,1.8-2.3L190.6,529.4L190.6,529.4z M206.2,540.8l-0.2-2.5
       M205.5,536.3c5.2,0.3,11-3.7,17.3-12 M206.1,542.8l0.1,0 M206.1,542.8L206.1,542.8l0.1-2 M206.1,542.8l-0.2,1.2 M205.5,536.3
      l-6.7-11.9 M206,538.3c-0.1-0.7-0.3-1.4-0.5-2 M205.9,544c2.4-0.1,4.3-0.6,5.6-1.4c6.5-3.8,11.1-8.3,13.8-13.5l0.4-0.7 M37.5,528.3
      l0.2,0.6c1.5,5,3.5,9.6,5.9,13.9c4.8,8.7,10.6,13.3,17.4,13.6l11.7,0.7c11.1,0.6,22.6,1,34.7,1.2l39.9,0.1
      c15.1-0.2,29.5-0.7,43.2-1.4l11.7-0.7c6.8-0.4,12.7-4.9,17.4-13.6c2.5-4.3,4.4-8.9,5.9-13.9l0.2-0.6 M57.2,544l0.9,7.9
      c0.6,1.7,2.3,2.9,5.2,3.6l0.1,0l9.2,0.5c11.1,0.6,22.6,1,34.7,1.2l39.9,0.1c15.1-0.2,29.5-0.7,43.2-1.4l9.2-0.5l0.1,0
      c2.9-0.7,4.6-1.9,5.2-3.6l0.9-7.9 M197,512.2l-4,0.2 M227.9,518l2.5-27 M160.2,514c11.1-0.3,22-0.8,32.8-1.5 M193.1,510.6
      c-10.9,0.7-21.8,1.3-32.8,1.5c-7.1,0.2-14.2,0.3-21.3,0.3h-14.6c-7.1,0-14.2-0.1-21.2-0.3c-11.1-0.3-22.1-0.8-33-1.6 M71.1,503
      c6.1,0.5,12.3,0.9,18.7,1.2c9.9,0.5,20.3,0.8,31.1,0.9l21.4,0c10.8-0.1,21.2-0.4,31.1-0.9c6.4-0.3,12.6-0.7,18.7-1.2 M193.1,510.6
      l0.4-7.7 M192,503l1.5-0.1 M193,512.4l0.1-1.8 M230.4,491c0.8-0.7,1.6-1.5,2.3-2.3 M197,512.2l0.3-8.2 M222.8,524.2l-12.6-9.5
      c-2.4-1.8-3.4-5.1-3-9.8 M198.9,524.4c-1.7-3.3-2.4-6.7-2-10.1l0.1-2.1 M197.1,512.2L197.1,512.2 M197.2,504.1
      c1.2-0.4,2.4-0.6,3.8-0.5 M201,503.6c1.2,0,2.4,0.2,3.8,0.6c0.8,0.2,1.6,0.5,2.4,0.8 M207.2,504.9c4.2,1.5,9,4.5,14.5,8.7
      c1.3,0.9,2.4-1,3.4-5.7l0.4-5.9c1.7-22.7,2.9-45.7,3.5-68.8l0.1-1.4c0-3.4-1.5-5.4-4.4-6.1l-14.2-6.5l-0.5-0.1 M223.8,502
      c1.6-21.1,2.7-42.4,3.4-63.8c-0.1-2.6-1-4.3-2.7-5.2l-9.4-4.1c-2.1-0.9-3.2,0.7-3.4,4.8l-2.1,60.8c-0.2,2.6,0.7,4.6,2.5,5.9
      l5.9,3.8C220,505,222,504.2,223.8,502L223.8,502z M225.7,528.3c0.8-2.9,1.5-5.8,2.1-8.9l0.1-0.4l0.1-1.1"/>
    <path fill="none" stroke="#000000" stroke-width="0.5" d="M223.7,39.9l-0.5-1.7 M209.8,24.5l-0.5-1c-3.9-2.4-8.5-4.7-13.9-6.8
      l-1.8,0 M193.7,16.7l1.8,0.7c5.3,2.1,10,4.4,13.9,6.8l0.5,0.3 M198.4,21.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4
      c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1-0.1-0.3-0.2-0.4
      C198.7,21.1,198.6,21.1,198.4,21.1L198.4,21.1z M198.1,20.2c1.1,0.1,2.1,0.6,3.1,1.2c0.2,0.1,0.3,0.2,0.2,0.5l-0.6,1.5
      c-0.1,0.2-0.2,0.3-0.4,0.2c-1.2-0.2-2.3-0.6-3.1-1.2c-0.2-0.1-0.3-0.3-0.2-0.5l0.6-1.5C197.7,20.2,197.9,20.1,198.1,20.2
      L198.1,20.2z M200.4,21.8c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
      c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.4C200.6,22.1,200.5,21.9,200.4,21.8L200.4,21.8z
       M201.9,31.3l0.2,3.2 M223.1,38.2c-2.4-5-6.8-9.6-13.3-13.7 M189.2,26.7l-3.3-1.6 M189.5,29.7l-0.3-3 M189.2,26.7l-9.4-1.5
       M180,27.5l-0.2-2.2 M189.5,29.7c-2.4-0.7-4.8-1.3-7.3-1.9l-2.2-0.4 M179.8,25.3l0-0.5l-0.1-1.1l0-0.6 M182.5,23.6L182.5,23.6
      l-2.9-0.5 M185.9,25.2l-3.3-1.6 M185.9,25.2c5.8,1.8,11.2,3.9,16.1,6.2 M202.1,34.6c-3.9-1.9-8.1-3.5-12.7-4.9 M225,45.4
      c-3.8-4.3-8.8-8.6-15-12.9c-10-6.1-19.2-9.1-27.5-8.9 M210.9,47.1c4.4,2.8,7.8,5.7,10.2,8.9c2.4,3.2,4.1,9.5,5.2,19.1l0,0.1
      c3.1,31.5,5.2,65,6.3,100.5 M225.5,52.8c0.2-0.3,0.4-0.7,0.4-1.1l-0.7-5.2 M225.2,46.5l-0.2-1.1 M224.2,47.2l-0.5-0.6 M223.7,46.6
      l0.3-0.4 M224,46.2l-0.2-1 M224.2,47.2l-0.2-1 M221.9,49.2l-0.2-0.2c-5.3-5.9-11.8-10.7-19.6-14.5 M225.5,52.8l-1.3-5.6
       M223.8,45.2l1.4,1.3 M223.7,46.6c0,1.7-0.6,2.5-1.8,2.6 M52.9,46.6c5-3,11.1-5.7,18.5-8.2c14.6-4.5,34.7-7,60.1-7.6
      c25.4,0.6,45.5,3.2,60.1,7.6c7.4,2.5,13.6,5.2,18.6,8.2l0.7,0.4 M225.7,165.9l-6.2-102.3c-1.2-7.5-4-13-8.6-16.5 M226.8,61.2
      l-1.3-8.4 M226.8,61.2c-1.5-5.9-3.1-9.9-4.9-12 M203.3,150.6l-9.7-80.2c-1.8-13.9-3.1-18.1-4-12.6l4.2,88.6 M234.9,158.3
      c3.3-6.7,5.1-20.7,5.3-42.2c-0.2-22.7-1.5-41.4-3.8-56.3c-1.7-9.3-4.1-15.2-7-17.4l-6.3-4.1 M201.9,31.3c8.8,4.2,16,9.3,21.8,15.3
       M223.7,39.9c0.5,1.7,0.9,3.6,1.3,5.5 M182.5,23.6c15,2.9,28.7,10.1,41.2,21.6 M236.7,213l21.9,5.8l0.6-1.9 M259.3,216.9
      c-0.5-6.7-3-11.3-7.5-13.9c-4.4-2.2-9.5-4.1-15.5-5.8 M236.7,213l-3.2-0.9 M236.4,197.1l-0.7-24.4 M234.9,158.3l-0.1-1.4v0
       M234.9,156.8c1.3-15.6,1.2-31.3-0.2-47.1c-2.9-38.1-6.5-61.4-11-69.9 M221.4,160.9l-0.6-0.4c-6-3.8-11.9-7.1-17.5-9.9 M163,109.1
      c0.2,0.2,0.3,0.4,0.3,0.7v1.8c0,0.1-0.1,0.2-0.3,0.3l-0.7,0.1l-0.7-0.1c-0.2-0.1-0.3-0.2-0.3-0.3v-1.8c0-0.3,0.1-0.5,0.3-0.7
      c0.2-0.2,0.4-0.3,0.7-0.3C162.6,108.8,162.8,108.9,163,109.1L163,109.1z M193.9,146.4c-1.1-0.4-2.1-0.8-3.1-1.2
      c-14.7-4.1-28.7-6.8-41.8-8.1l-16.2-0.9c-5,0-9.9,0.1-14.9,0.6l-1.8,0.2c-13.7,1.2-28.2,4-43.7,8.2l-3.1,1.2 M193.9,146.4
      c3.1,1.2,6.2,2.6,9.4,4.2 M233.5,212.1l-3.6-1l-0.1-1.4 M226.7,209.2L226.7,209.2 M221.3,215.2c0.6-1.9,1.5-3.3,2.7-4.2l2.7-1.8
       M226.7,209.2c-0.5-0.1-0.7-0.8-0.5-2.2v-12.1l0.6,0.2 M229.1,209.6l-2.3-0.4 M229.8,209.7l-0.7-0.1 M229.8,208.9l0,0.9
       M231.7,196.4l-2.7-0.7 M233.2,196.3L233.2,196.3c-0.6-0.2-1-0.2-1.4,0.1 M226.8,195l2.2,0.6 M231.7,196.4c-1.5,1-2.1,5.2-1.9,12.5
       M226.8,187.4l2.1-10.4 M226.9,168.5c-0.4-0.9-0.8-1.8-1.2-2.6 M228.5,174.1c-0.4-2.1-0.9-4-1.5-5.6 M232.7,175.7l-3.8,1.2
       M228.9,177l-0.4-2.9 M229,195.6l-0.1-18.6 M226.8,195v-7.6 M233.2,196.3l-0.5-20.5 M221.3,215.2l5.5-27.7 M235.6,172.7l-0.7-14.4
       M236.4,197.1c-1-0.3-2.1-0.6-3.2-0.9 M234.9,156.8l-1.8-27.7c-1.4-25.3-3.4-47.9-6.3-68 M225.7,165.9c-1.2-2.1-2.6-3.8-4.3-5
       M232.7,175.7l2.9-3 M206.4,234.9c6.4-25.3,11.2-48.8,14.5-70.5l0.5-3.5 M229.8,208.9l29.5,8"/>
    <path fill="none" stroke="#000000" stroke-width="0.5" d="M237.6,291.8c1.7-0.4,2.5-2.4,2.4-6.1l0-0.8 M236.3,285v6.7
      c0.5,0.1,0.9,0.1,1.3,0 M236.3,281.8v3.2 M240.1,284.9l-3.8,0.1 M234.3,300.8l0-3.8c0.1-23.3-0.1-51.6-0.8-84.9 M237.6,298.7
      l-3.3,2.1 M237.6,298.7v-6.9 M240.1,284.9c0.3-7.7,0-14.3-0.9-20c-0.3-1.6-0.9-2.7-1.7-3.2 M237.5,261.8c-0.4-0.3-0.9-0.4-1.4-0.3
      v6.5 M236.1,267.9c-0.5,1.3-0.8,3.5-0.8,6.5c0,4,0.4,6.4,1,7.4 M236.3,281.8c0.8,0.1,1.2-0.6,1.3-2.2l0-8.5
      c-0.1-1.8-0.6-2.8-1.5-3.1 M202.9,233.6l3.6,1.3 M214.2,250.7l-0.1-3.6 M214.2,247.1l-0.9-7.3c-0.1-0.2-0.2-0.4-0.3-0.6 M213,239.1
      c-0.9-1.4-3.1-2.8-6.5-4.2 M198.3,352.3l3.1-96.1c0-8.5,0.5-16,1.5-22.7 M210.1,419l1-33.6c1-43.6,1.1-87.3,0.4-130.9
      c-0.1-6.1,0.4-11.2,1.5-15.3 M213.2,312l-0.1,2.2l-0.4-52c0.1-7,0.6-12,1.5-15 M206.4,234.9c-0.8,5.8-1.2,12.9-1.3,21.4L200,419.1
       M227.8,302.3v11.1l-0.4,0.3 M227.4,313.7c-3.6,2.3-7.8,4-12.6,5.1 M227.4,302.1v0.6 M227.4,302.7l0.4-0.4 M227.8,302.3l2-0.4
       M229.8,301.8v0.3c0,31.8-0.8,62.8-2.4,92.9 M227.4,291.7l0.4-0.5v11.1 M214.9,310.4l0-9 M214.9,310.4l-1.6,0.3l0,1.3 M227.4,291.7
      c-3.6,4.1-7.8,7.4-12.5,9.7 M227.4,302.7c-3.2,3.3-7.4,5.8-12.6,7.7 M214.8,318.8l0.1-8.4 M214.9,301.4c0-16.8-0.2-33.7-0.7-50.7
       M234.3,300.8l-4.5,1 M235.4,410.4c-0.5,0.1-1,0.1-1.6,0l0.2-6.7 M237.7,405.3c-0.2,3-1,4.6-2.3,5.1 M237.8,403.7l-3.8-0.1
       M237.7,405.3l0.1-1.2 M237.8,403.7l-0.1,0.5 M237.8,403.7L237.8,403.7 M237.8,404.1l0-0.5 M242.3,423.6l-5.3-0.4 M237,423.3
      l-2.1-0.2 M242.3,423.6c0-6.1-0.1-12.1-0.3-18c-0.4-10.4-1.5-18.6-3.5-24.7 M234.9,423.1l0.5-12.7 M237,423.3l0.7-17.9
       M238.5,380.9c-0.6-1.9-1.3-3.5-2-5 M236.4,380.7c0.6,0.6,1,1.6,1.2,3l0.6,9 M234.4,386.6l0.2-6.5c0.7-0.1,1.3,0.1,1.7,0.6
       M236.4,380.7l0.1-4.7 M234.1,400.4c0.9,0.1,1.4-0.8,1.4-2.9l0.2-6.8c0-2.3-0.4-3.7-1.4-4.1 M237.9,401.6l0.3-8.9 M238.5,380.9
      l-0.3,11.7 M214,395c0,1.2,0.7,2,2,2.3l11.3,4.1c3,1.2,4.6-0.7,5-5.6c1.2-31.6,1.9-63.2,2-95.1 M222.7,396.9
      c1.4,0.2,2.1-0.4,2.3-1.8l0.9-18.3 M227.4,395c-0.5,1.5-1.8,2.2-4,2l-0.6-0.1 M214,395l7.7,3.3c3.2,1.3,5.1,0.8,5.6-1.4l0.1-1.8
       M225.9,376.7l0.7-0.4V374l-0.6,0.3 M214.1,379.2l0,2 M214.1,381.2L214,395 M226,374.3c-3.2,1.9-7.1,3.5-11.9,4.9 M222.7,396.9
      L214,395 M214.1,381.2c4.8-1.1,8.8-2.6,11.8-4.4 M202.8,418.7c2.3-0.2,4.8-0.1,7.2,0.4 M200,419.1c1-0.2,1.9-0.4,2.9-0.4
       M213.2,312l-1,87.2l0.1,9.3c0.5,3.2,1.7,5.2,3.6,6.1l18.9,8.5 M234,403.6l0.1-3.2 M234.1,400.4c-0.6-1-0.8-3.4-0.7-7.4
      c0.1-3,0.5-5.2,1-6.4 M237.9,401.6l0,2.1 M237.9,401.6l-0.1,2.1 M236.5,376c0.7-26,1-51.7,1.1-77.3 M227.4,313.7
      c-0.1,20.6-0.6,40.8-1.4,60.6 M204.3,321.9l-1.5,96.8 M214.8,318.8l-0.7,60.4 M237.5,261.8c-0.1-16.3-0.4-32.6-0.7-48.8
       M226.8,209.2l0.6,82.5 M214.2,250.7l7.1-35.5 M226.9,168.5l-14,70.6 M228.5,174.1l-14.3,73 M229.8,301.8l-0.7-92.2 M49,381.2l0-2
       M49.2,395L49,381.2 M28.9,300.8c0.1,31.8,0.7,63.5,2,95.1c0.4,4.9,2.1,6.8,5,5.6l11.3-4.1c1.3-0.4,2-1.1,2-2.3 M35.8,395l0.1,1.8
      c0.5,2.3,2.4,2.7,5.6,1.4l7.7-3.3 M35.8,395c0.5,1.5,1.8,2.2,4,2l0.6-0.1 M40.4,396.9c-1.4,0.2-2.1-0.4-2.3-1.8l-0.9-18.3
       M37.3,376.7l-0.7-0.4V374l0.6,0.3 M26.7,376c-0.8,1.4-1.4,3.1-2,5 M28.7,386.6l-0.2-6.5c-0.7-0.1-1.2,0.1-1.7,0.6 M25,392.7l0.6-9
      c0.2-1.4,0.6-2.4,1.2-3 M26.8,380.7l-0.1-4.7 M29,400.4c0.6-1,0.8-3.4,0.7-7.4c-0.1-3-0.5-5.2-1-6.4 M29,400.4
      c-0.9,0.1-1.4-0.8-1.4-2.9l-0.2-6.8c0-2.3,0.4-3.7,1.4-4.1 M24.6,380.9l0.3,11.7 M37.3,376.7c3,1.9,7,3.3,11.8,4.4 M49.2,395
      l-8.8,1.8 M37.1,374.3c3.2,1.9,7.1,3.5,11.9,4.9 M60.3,418.7c-2.3-0.2-4.8-0.1-7.3,0.4 M55.9,504.9c-4.1,1.5-9,4.5-14.5,8.7
      c-1.3,0.9-2.4-1-3.4-5.7l-0.4-5.9c-1.7-22.7-2.9-45.7-3.5-68.8l-0.1-1.4c0-3.4,1.5-5.4,4.4-6.1l14.2-6.5l0.5-0.1 M63.2,419.1
      c-1-0.2-1.9-0.4-2.9-0.4 M27.8,410.4c0.4,0.1,1,0.1,1.6,0l-0.2-6.7 M25.3,404.4v-0.2 M27.8,410.4c-1.5-0.5-2.3-2.4-2.4-5.9l0-0.1
       M25.3,404.1l0-0.5 M25.3,403.7l0,0.5 M25.3,403.7L25.3,403.7 M25.3,402.7l0,1 M25.3,403.7l-0.1-1 M29.1,403.6l-3.8,0.1
       M20.8,423.6l5.3-0.4 M26.1,423.3l2.1-0.2 M24.6,380.9c-2,6.1-3.2,14.3-3.5,24.7c-0.2,5.9-0.3,11.9-0.3,18 M28.3,423.1l-0.5-12.7
       M49.9,312l1,87.2l-0.1,9.3c-0.5,3.2-1.7,5.2-3.6,6.1l-18.9,8.5 M25.3,404.4l0.8,18.9 M38.7,432.9l9.3-4.1c2.1-0.9,3.2,0.7,3.4,4.8
      l2.2,60.8c0.2,2.6-0.7,4.6-2.5,5.9l-5.9,3.8c-2.1,0.8-4.1,0.1-5.8-2.2c-1.6-21.1-2.7-42.4-3.4-63.8C36.1,435.6,37,433.8,38.7,432.9
      L38.7,432.9z M29.1,403.6l-0.1-3.2 M50.2,239.1c1.1,4.1,1.5,9.2,1.5,15.3c-0.8,43.6-0.7,87.3,0.3,130.9l1,33.6 M25.3,402.7l-0.3-10
      "/>
    <path fill="none" stroke="#000000" stroke-width="0.5" d="M60.3,233.6l-3.5,1.3 M49,247.1l0.9-7.3l0.3-0.6 M50.2,239.1
      c0.9-1.4,3.1-2.8,6.5-4.2 M25.7,261.8c-0.8,0.5-1.3,1.5-1.7,3.2c-0.9,5.6-1.2,12.3-0.9,20 M27.1,267.9v-6.5c-0.5-0.1-1,0.1-1.4,0.3
       M26.9,281.8c0.6-1,0.9-3.4,1-7.4c0-3-0.3-5.2-0.8-6.5 M26.9,281.8c-0.8,0.1-1.2-0.6-1.3-2.2l0-8.5c0.1-1.8,0.6-2.8,1.5-3.1
       M64.8,352.3l-3.1-96.1c0-8.5-0.5-16-1.5-22.7 M49.9,312l0.1,2.2l0.4-52c-0.1-7-0.6-12-1.5-15 M56.7,234.9
      c0.8,5.8,1.2,12.9,1.2,21.4l5.2,162.8 M48.9,250.7l0.1-3.6 M33.3,301.8v0.3c0,31.8,0.8,62.8,2.4,92.9 M33.3,301.8l2,0.4
       M48.3,310.4l0-9 M35.7,302.7c3.2,3.3,7.4,5.8,12.6,7.7 M35.3,302.3l0.4,0.4 M35.7,302.7v-0.6 M35.8,291.7l-0.5-0.5v11.1
       M23.1,284.9l0,0.8c0,3.7,0.8,5.7,2.4,6.1 M26.9,285v-3.2 M25.6,291.8c0.4,0.1,0.8,0.1,1.3,0V285 M23.1,284.9l3.8,0.1 M25.6,298.7
      l3.3,2.1 M29.6,212.1c-0.7,33.4-0.9,61.7-0.8,84.9l0,3.8 M25.6,291.8v6.9 M28.9,300.8l4.5,1 M35.8,291.7c3.6,4.1,7.8,7.4,12.5,9.7
       M35.3,302.3v11.1l0.4,0.3 M48.4,318.8c-4.8-1.1-9.1-2.8-12.6-5.1 M48.3,310.4l1.6,0.3l0,1.3 M48.4,318.8l-0.1-8.4 M48.3,301.4
      c0-16.8,0.2-33.7,0.6-50.7 M25.6,298.7c0.1,25.6,0.4,51.3,1.1,77.3 M35.7,313.7c0.1,20.6,0.6,40.8,1.4,60.6 M58.9,321.9l1.5,96.8
       M49,379.2l-0.7-60.4 M60.3,233.6c18.3-6.5,36.7-10.6,55.3-12.4l8.8-0.7c2.4-0.1,4.8-0.2,7.1-0.2c2.5,0,4.9,0.1,7.4,0.2l8.8,0.7
      c18.5,1.8,36.9,6,55.3,12.4 M100.8,108.8c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.8c0,0.1,0.1,0.2,0.3,0.3l0.7,0.1
      l0.7-0.1c0.2-0.1,0.3-0.2,0.3-0.3v-1.8c0-0.3-0.1-0.5-0.3-0.7C101.3,108.9,101.1,108.8,100.8,108.8L100.8,108.8z M69.2,146.4
      c-3.1,1.2-6.2,2.6-9.4,4.2 M59.8,150.6c-5.6,2.7-11.4,6-17.5,9.9l-0.6,0.4 M28.2,158.3l0.1-1.4v0 M40,38.2l-6.3,4.1
      c-2.9,2.3-5.3,8.1-7,17.4c-2.3,14.8-3.6,33.6-3.8,56.3c0.2,21.5,2,35.6,5.3,42.2 M28.3,156.8l1.8-27.7c1.4-25.3,3.4-47.9,6.3-68
       M28.3,156.8c-1.3-15.6-1.2-31.3,0.2-47.1c2.9-38.1,6.5-61.4,11-69.9 M36.3,187.4L34.3,177 M34.6,174.1c0.4-2.1,0.9-4,1.6-5.6
       M36.2,168.5c0.4-1,0.8-1.8,1.2-2.6 M30.5,175.7l-2.9-3 M34.3,177l0.4-2.9 M30.5,175.7l3.8,1.2 M33.3,209.7l0.7-0.1 M29.6,212.1
      l3.7-1l0-1.4 M34,209.6l2.3-0.4 M41.8,215.2c-0.6-1.9-1.5-3.3-2.7-4.2l-2.7-1.8 M33.3,208.9l0,0.9 M36.4,209.2
      c0.5-0.1,0.7-0.8,0.5-2.2v-12.1l-0.6,0.2 M36.3,209.2l0.1,0 M36.3,195l-2.2,0.6 M31.4,196.4l2.7-0.7 M26.8,197.1
      c1-0.3,2.1-0.6,3.2-0.9 M30,196.3L30,196.3c0.6-0.2,1-0.2,1.4,0.1 M26.4,213l3.2-0.9 M33.3,208.9c0.2-7.3-0.5-11.5-1.9-12.5
       M27.5,172.7c-0.3,8.1-0.6,16.3-0.7,24.4 M41.8,215.2l-5.5-27.7 M34.1,195.6l0.1-18.6 M36.3,187.4v7.6 M30,196.3l0.5-20.5
       M28.2,158.3l-0.7,14.4 M41.7,160.9c-1.7,1.2-3.1,2.9-4.3,5 M41.7,160.9l0.5,3.5c3.3,21.8,8.1,45.3,14.5,70.5 M193.7,16.7
      c-12.3-4-32.8-5.9-61.6-5.9h-0.9c-28.8,0-49.3,2-61.6,5.9 M83.5,23.1c12.9-2,27.8-3.1,44.7-3.3h6.7c16.9,0.2,31.8,1.3,44.7,3.3
       M83.2,27.5l0.1-2.2 M83.3,25.3L74,26.7 M73.7,29.7c2.4-0.7,4.8-1.3,7.3-1.9l2.2-0.4 M74,26.7l3.3-1.6 M73.7,29.7l0.3-3 M69.5,16.7
      l-1.8,0.7c-5.3,2.1-10,4.4-13.9,6.8l-0.5,0.3 M69.5,16.7l-1.8,0c-5.3,2.1-10,4.4-13.9,6.8l-0.5,1 M83.5,23.1l-2.8,0.5l-0.1,0
       M83.3,25.3l0-0.5l0.1-1.1l0.1-0.6 M66.1,21.9l-0.6-1.5c-0.1-0.2-0.2-0.3-0.4-0.2c-1.1,0.1-2.1,0.6-3.1,1.2
      c-0.2,0.1-0.3,0.2-0.2,0.5l0.6,1.5c0.1,0.2,0.2,0.3,0.4,0.2c1.2-0.2,2.3-0.6,3.1-1.2C66.1,22.3,66.2,22.1,66.1,21.9L66.1,21.9z
       M65.1,22c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
      c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.4C65.3,21.7,65.2,21.9,65.1,22L65.1,22z M63.5,21.8
      c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.1l-0.2-0.4c0-0.1,0.1-0.3,0.2-0.4
      c0.1-0.1,0.2-0.1,0.4-0.1C63.2,21.7,63.4,21.7,63.5,21.8L63.5,21.8z M61.2,31.3L61,34.6 M61,34.6c3.9-1.9,8.1-3.5,12.7-4.9
       M80.6,23.6c-8.3-0.2-17.5,2.8-27.5,8.9C47,36.8,42,41.1,38.2,45.4 M77.3,25.2l3.3-1.6 M61.2,31.3c4.9-2.3,10.2-4.4,16-6.2
       M39.5,39.9l0.5-1.7 M53.3,24.5c-6.5,4.1-10.9,8.7-13.3,13.7 M41.3,49.2l0.1-0.2c5.3-5.9,11.8-10.7,19.6-14.5 M39.2,46.2l0.2-1
       M39.4,45.2L38,46.5 M38.2,45.4L38,46.5 M38.9,47.2l0.5-0.6 M39.2,46.2l0.3,0.4 M38.9,47.2l0.2-1 M38,46.5l-0.7,5.2
      c0,0.4,0.1,0.8,0.4,1.1 M37.7,52.8l1.3-5.6 M39.5,46.6c0,1.7,0.6,2.5,1.8,2.6 M36.4,61.2c1.5-5.9,3.1-9.9,4.9-12 M37.7,52.8
      l-1.3,8.4 M52.9,46.6c-4.8,2.9-8.4,6-10.9,9.4c-2.4,3.2-4.1,9.5-5.2,19.1l0,0.1c-3.1,31.5-5.2,65-6.3,100.5 M37.4,165.8l6.3-102.2
      c1.2-7.9,4.3-13.5,9.2-17 M69.2,146.4l4.2-88.6c-0.9-5.5-2.2-1.3-4,12.6l-9.7,80.2 M180,27.5c-13.9-2.3-30.1-3.5-48.4-3.7
      c-18.4,0.2-34.5,1.5-48.4,3.7 M39.5,39.9c-0.5,1.7-0.9,3.6-1.3,5.5 M39.5,46.6c5.7-6,13-11.1,21.8-15.3 M80.6,23.6
      c-15,2.9-28.7,10.1-41.2,21.6 M3.9,216.9l0.6,1.9l21.9-5.8 M26.8,197.1c-5.9,1.7-11.1,3.7-15.5,5.8c-4.5,2.6-7,7.3-7.5,13.9
       M3.9,216.9l29.5-8 M26.4,213c-0.4,16.2-0.6,32.5-0.7,48.8 M34,209.6l-0.7,92.2 M36.2,168.5l14,70.6 M49,247.1l-14.3-73
       M36.3,209.2l-0.5,82.5 M48.9,250.7l-7.1-35.5"/>
    <path fill="none" stroke="#000000" stroke-width="0.5" d="M146,527.5c4.5-0.2,8.7-0.4,12.5-0.7l1.6-0.1 M117.1,527.5
      c-0.1-1.4,1.3-2.2,4.1-2.3h20.6c2.9,0.2,4.2,0.9,4.2,2.3 M110.4,541.2l4.7,0.7c2.1,0.3,4.3,0.5,6.5,0.7c1.5,0.1,3,0.2,4.5,0.2
      l6.2,0.5c24.4,0,49.1-0.9,74-2.5 M117.1,527.5l14,0.3l15-0.3 M106.5,547.4l1.5-2.4 M108,545l2.4-3.8 M109.7,541l0.7,0.2
       M106.3,540.1l3.4,0.9 M110.4,541.2L110.4,541.2l-0.7-0.2 M103.1,526.7l1.5,0.1c3.9,0.3,8.1,0.5,12.6,0.7 M103.3,546l3.1-5.9
       M106.5,547.4l-3.2-1.4 M106.3,540.1l-17.9-0.7 M206,538.3c-5.6,0.7-30.5,1.2-74.5,1.6c-17.4-0.2-31.8-0.4-43.2-0.6 M130.6,505.9
      l1.1-0.1h0l1.1,0.1c0.3,0.1,0.4,0.1,0.4,0.2v2.6c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h0c-0.4,0-0.8-0.1-1.1-0.5
      c-0.3-0.3-0.5-0.7-0.5-1.1V506C130.1,506,130.2,505.9,130.6,505.9L130.6,505.9z M103.1,514c7,0.2,14.1,0.3,21.2,0.3h14.6
      c7.2,0,14.3-0.1,21.3-0.3 M70.2,512.4l-0.1-1.9 M69.7,502.9l1.4,0.1 M62.1,503.6c-1.2,0-2.5,0.2-3.8,0.6c-0.8,0.2-1.6,0.5-2.4,0.8
       M65.9,504.1c-1.1-0.4-2.4-0.6-3.8-0.5 M66.2,512.2l-0.1,0 M64.3,524.4c1.7-3.3,2.4-6.7,2-10.1l-0.1-2.1 M70,510.5l-0.4-7.6
       M65.9,504.1l0.3,8.2 M40.4,524.2l12.6-9.5c2.4-1.8,3.4-5.1,3-9.8 M66.2,512.2l4,0.2 M30.4,488.7c0.7,0.9,1.5,1.7,2.4,2.3
       M20.8,423.6c0.1,13.1,0.7,26.5,1.9,40.2c1.1,11.7,3.7,20,7.6,24.8 M35.2,518l0.1,1.1l0.1,0.4c0.5,3.1,1.2,6,2.1,8.9 M32.7,491
      l2.5,27 M57.6,536.3l6.7-11.9 M57.1,538.3c0.1-0.7,0.3-1.4,0.5-2 M58.3,538.4l-1.1-0.1 M60.5,543l1.3,2.7 M61.8,544v1.6
       M59.2,538.5l2.5,5.6 M59.5,541l1,2 M57.2,544l-0.2-1.2 M37.5,528.3l0.4,0.7c2.7,5.3,7.4,9.8,13.8,13.5c1.2,0.8,3.1,1.3,5.6,1.4
       M56.9,542.8l0.1,0 M57.1,542.8L57.1,542.8l-0.1-2 M56.9,540.8l2.6,0.2 M57.1,542.8l3.4,0.2 M59.2,538.5l-0.9-0.1 M56.9,540.8
      l0.2-2.5 M58.3,538.4l1.2,2.6 M57.6,536.3c-5.2,0.3-11-3.7-17.3-12 M61.8,545.6c14.1,1.2,29,1.8,44.8,1.8 M64.3,524.4l38.8,2.3
       M88.4,539.3c-14.7-0.3-24.5-0.6-29.2-0.9 M103.1,526.7V514 M103.1,514c-11.1-0.3-22.1-0.8-32.9-1.5 M35.2,518
      c1.2-1.9,2.9,0.2,5.1,6.3 M103.3,546c-13.7,0.1-27.5-0.5-41.5-2 M108,545c32.3,0.7,65-0.1,98-2.2 M193.5,502.9l4.8-150.5
       M207.2,504.9l2.8-85.9 M200,419.1l-2.7,85 M232.8,488.7c1.8-21.5,3.2-43.3,4.2-65.4 M201,503.6l1.8-84.9 M234.9,423.1
      c-1.1,22.9-2.6,45.6-4.5,67.9 M53.1,419l2.8,85.9 M26.1,423.3c1,22.1,2.4,43.9,4.2,65.4 M28.3,423.1c1.1,22.9,2.6,45.6,4.5,67.9
       M60.3,418.7l1.8,84.9 M63.2,419.1l2.7,85 M69.7,502.9l-4.8-150.5"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M80.1,227.6h32c0,0,4.7,0.9,6.5,9.1l0.3,20.4c0,0-2.4,11.7-9,15.4
      H81.1c0,0-5.9-4.5-8.4-15.2v-20.9C72.7,236.4,75.1,228.6,80.1,227.6z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M119.6,269.1l-0.7-1.2h-4l-2.7,3l-3.2,1.7l-13.4-0.1v0L82,272.8
      l-2.9-2.3l-2.2-2.9l-4.6,0.2l-1.3,2.3l-0.7,5.9v18.2l0.6,4.7l1.7,3.2l2.1,2l3.2,1.6h4.5h2l0.1,1.3h11v0h11l0.1-1.3h2h4.5l3.2-1.6
      l2.1-2l1.7-3.2l0.6-4.7V276l-0.6-4.7C120.2,270.5,120,269.8,119.6,269.1z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="95.6,293.5 81.9,293.5 81.9,305.5 84.5,308.3 95.6,308.3 
      106.8,308.3 109.4,305.5 109.4,293.5 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M147.2,227.6h32c0,0,4.7,0.9,6.5,9.1l0.3,20.4c0,0-2.4,11.7-9,15.4
      h-28.8c0,0-5.9-4.5-8.4-15.2v-20.9C139.8,236.4,142.2,228.6,147.2,227.6z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M186.7,269.1l-0.7-1.2h-4l-2.7,3l-3.2,1.7l-13.4-0.1v0l-13.6,0.4
      l-2.9-2.3l-2.2-2.9l-4.6,0.2l-1.3,2.3l-0.7,5.9v18.2l0.6,4.7l1.7,3.2l2.1,2l3.2,1.6h4.5h2l0.1,1.3h11v0h11l0.1-1.3h2h4.5l3.2-1.6
      l2.1-2l1.7-3.2l0.6-4.7V276l-0.6-4.7C187.3,270.5,187.1,269.8,186.7,269.1z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="162.8,293.5 149,293.5 149,305.5 151.6,308.3 162.8,308.3 
      173.9,308.3 176.5,305.5 176.5,293.5 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.1,420.6h32c0,0,4.7,0.9,6.5,9.1l0.3,20.4c0,0-2.4,11.7-9,15.4
      H87.1c0,0-5.9-4.5-8.4-15.2v-20.9C78.7,429.4,81.1,421.6,86.1,420.6z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M125.6,462.1l-0.7-1.2h-4l-2.7,3l-3.2,1.7l-13.4-0.1v0L88,465.8
      l-2.9-2.3l-2.2-2.9l-4.6,0.2l-1.3,2.3l-0.7,5.9v18.2l0.6,4.7l1.7,3.2l2.1,2l3.2,1.6h4.5h2l0.1,1.3h11v0h11l0.1-1.3h2h4.5l3.2-1.6
      l2.1-2l1.7-3.2l0.6-4.7V469l-0.6-4.7C126.2,463.5,126,462.8,125.6,462.1z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="101.6,486.5 87.9,486.5 87.9,498.5 90.5,501.3 101.6,501.3 
      112.8,501.3 115.4,498.5 115.4,486.5 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M150.2,420.6h32c0,0,4.7,0.9,6.5,9.1l0.3,20.4c0,0-2.4,11.7-9,15.4
      h-28.8c0,0-5.9-4.5-8.4-15.2v-20.9C142.8,429.4,145.2,421.6,150.2,420.6z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M189.7,462.1l-0.7-1.2h-4l-2.7,3l-3.2,1.7l-13.4-0.1v0l-13.6,0.4
      l-2.9-2.3l-2.2-2.9l-4.6,0.2l-1.3,2.3l-0.7,5.9v18.2l0.6,4.7l1.7,3.2l2.1,2l3.2,1.6h4.5h2l0.1,1.3h11v0h11l0.1-1.3h2h4.5l3.2-1.6
      l2.1-2l1.7-3.2l0.6-4.7V469l-0.6-4.7C190.3,463.5,190.1,462.8,189.7,462.1z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="165.8,486.5 152,486.5 152,498.5 154.6,501.3 165.8,501.3 
      176.9,501.3 179.5,498.5 179.5,486.5 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M187.8,366.8H78.9c-1.3,0-2.3-1-2.3-2.3l-1.2-31.6
      c0-1.3,1-2.3,2.3-2.3h111.4c1.3,0,2.3,1,2.3,2.3l-1.2,31.6C190.2,365.8,189.1,366.8,187.8,366.8z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M189,368l-2.4-1.2h-7.3h-8.5h-8.5h-29h-1.2h-30.2h-7.3h-6H79h0
      c-1.7,2-3,4.3-3.8,6.8h0c-0.3,1-0.5,2-0.5,3.1V387c0,2.9,0.4,5.8,1.3,8.6l0,0l0.7,0.5c2.1,1.6,4.4,2.8,6.8,3.7l1.2,0.4l7.5,1.4
      h10.4h4.5l0.3,1.2h25.3v0H158l0.3-1.2h4.5h10.4l7.5-1.4l4.9-1.8l3.9-2.9l1.3-4.2v-16.2l-0.6-4.7C190,369.7,189.8,368.6,189,368z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M132.8,390.8h-31.6v10.7l2.5,1.1c2.2,1,4.7,1.4,7.1,1.4h21.9h21.5
      c2.7,0,5.5-0.6,8-1.6l2.1-0.9v-10.7H132.8z"/>
  </g></>)
  }, 
  exterior: {
    width:778,
    height:1082,
    svg: (<><g id="top">
    <g>
      <path fill="none" d="M479.8,769l0.1-1.1c-1.2-1.9-2.9,0.2-5.1,6.3c-6.3,8.4-12,12.4-17.3,12c0.2,0.6,0.4,1.3,0.5,2l0.2,2.5
        c6-1.1,11.9-5.2,17.9-12.1c1-0.9,2.2-4,3.6-9.2L479.8,769L479.8,769z"/>
      <path fill="none" d="M477.5,751.9l-0.4,5.9c-1,4.7-2.1,6.7-3.4,5.7c-5.5-4.3-10.3-7.2-14.5-8.7c-0.4,4.7,0.5,8,3,9.8l12.6,9.5
        c2.2-6.1,3.9-8.2,5.1-6.3l2.5-27c1.9-22.4,3.4-45,4.5-67.9l-18.9-8.5c-1.9-0.9-3.1-2.9-3.6-6.1l-0.1-9.3l1-87.2l-0.1,2.2l-0.4-52
        c0.1-7,0.6-12,1.5-15l-0.9-7.3c-0.1-0.2-0.2-0.4-0.3-0.6c-1.1,4.1-1.6,9.2-1.5,15.3c0.8,43.6,0.7,87.3-0.4,130.9l-1,33.6l0.5,0.1
        l14.2,6.5c2.9,0.7,4.3,2.7,4.4,6.1l-0.1,1.4C480.4,706.3,479.2,729.2,477.5,751.9L477.5,751.9z"/>
      <path fill="none" d="M477,757.9l0.4-5.9c1.7-22.7,2.9-45.7,3.5-68.8l0.1-1.4c0-3.4-1.5-5.4-4.4-6.1l-14.2-6.5L462,669l-2.8,85.9
        c4.2,1.5,9,4.5,14.5,8.7C474.9,764.5,476.1,762.6,477,757.9L477,757.9z M475.7,751.9c-1.8,2.3-3.7,3-5.8,2.2l-5.9-3.8
        c-1.9-1.3-2.7-3.3-2.5-5.9l2.1-60.8c0.2-4.1,1.4-5.7,3.4-4.8l9.4,4.1c1.8,0.9,2.7,2.6,2.7,5.2
        C478.4,709.6,477.3,730.8,475.7,751.9L475.7,751.9z"/>
      <path fill="none" d="M469.9,754.1c2.1,0.8,4,0.1,5.8-2.2c1.6-21.1,2.7-42.4,3.4-63.8c-0.1-2.6-1-4.3-2.7-5.2l-9.4-4.1
        c-2.1-0.9-3.2,0.7-3.4,4.8l-2.1,60.8c-0.2,2.6,0.7,4.6,2.5,5.9L469.9,754.1L469.9,754.1z"/>
      <path fill="none" d="M459.2,754.9c-0.8-0.3-1.6-0.6-2.4-0.8c-1.3-0.4-2.6-0.5-3.8-0.6c-1.4-0.1-2.6,0.1-3.8,0.5l-0.3,8.2l0.1,0
        l-0.1,0l-0.1,2.1c-0.4,3.4,0.3,6.7,2,10.1l6.7,11.9c5.2,0.3,11-3.7,17.3-12l-12.6-9.5C459.7,762.9,458.7,759.6,459.2,754.9
        L459.2,754.9z"/>
      <path fill="none" d="M456.7,754.1c0.8,0.2,1.6,0.5,2.4,0.8L462,669c-2.5-0.5-4.9-0.6-7.2-0.4l-1.8,84.9
        C454.1,753.5,455.4,753.7,456.7,754.1L456.7,754.1z"/>
      <path fill="none" d="M449.2,754c1.2-0.4,2.4-0.6,3.8-0.5l1.8-84.9c-0.9,0.1-1.9,0.2-2.9,0.4L449.2,754L449.2,754z"/>
      <path fill="none" d="M448.8,764.3l0.1-2.1l-4,0.2c-10.8,0.7-21.7,1.3-32.8,1.5v12.7l38.6-2.2C449.1,771.1,448.5,767.7,448.8,764.3
        L448.8,764.3z"/>
      <path fill="none" d="M448.9,762.2l0.3-8.2l2.7-85l5.2-162.8c0-8.5,0.4-15.6,1.3-21.4l-3.6-1.3c-1,6.6-1.5,14.2-1.5,22.7l-3.1,96.1
        l-4.8,150.5l-0.4,7.7l-0.1,1.8L448.9,762.2L448.9,762.2z"/>
      <path fill="none" d="M482.4,741c0.8-0.7,1.6-1.5,2.3-2.3c1.8-21.5,3.2-43.3,4.2-65.4l-2.1-0.2C485.7,696,484.2,718.6,482.4,741
        L482.4,741z"/>
      <path fill="none" d="M444.9,762.4l0.1-1.8c-10.9,0.7-21.8,1.3-32.8,1.5c-7.1,0.2-14.2,0.3-21.3,0.3h-14.6
        c-7.1,0-14.2-0.1-21.2-0.3c-11.1-0.3-22.1-0.8-33-1.6l0.1,1.9c10.8,0.7,21.8,1.3,32.9,1.5c7,0.2,14.1,0.3,21.2,0.3h14.6
        c7.2,0,14.3-0.1,21.3-0.3C423.2,763.6,434.2,763.1,444.9,762.4L444.9,762.4z"/>
      <path fill="none" d="M445.4,752.9L444,753c-6.1,0.5-12.3,0.9-18.7,1.2c-9.9,0.5-20.3,0.8-31.1,0.9l-21.4,0
        c-10.8-0.1-21.2-0.4-31.1-0.9c-6.4-0.3-12.6-0.7-18.7-1.2l-1.4-0.1l0.4,7.6c11,0.8,22,1.4,33,1.6c7,0.2,14.1,0.3,21.2,0.3h14.6
        c7.2,0,14.3-0.1,21.3-0.3c11-0.3,22-0.8,32.8-1.5L445.4,752.9L445.4,752.9z M382,756c0-0.1,0.1-0.1,0.5-0.2l1.1-0.1h0l1.1,0.1
        c0.3,0.1,0.4,0.1,0.4,0.2v2.6c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h0c-0.4,0-0.8-0.1-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1
        V756L382,756z"/>
      <path fill="none" d="M382.5,755.8c-0.3,0.1-0.5,0.1-0.5,0.2v2.6c0,0.4,0.1,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5h0
        c0.4,0,0.8-0.1,1.1-0.5c0.3-0.3,0.4-0.7,0.4-1.1V756c0-0.1-0.1-0.1-0.4-0.2l-1.1-0.1h0L382.5,755.8L382.5,755.8z"/>
      <path fill="none" d="M444,753l1.5-0.1l4.8-150.5l-1.4-0.4c-4.6-1.1-9.4-2.1-14.3-3c-10.2-1.8-21.1-3.1-32.7-3.8
        c-6-0.4-12.2-0.6-18.5-0.7c-6.3,0.1-12.4,0.3-18.3,0.7c-11.6,0.7-22.5,2-32.7,3.8c-4.9,0.9-9.7,1.8-14.2,3l-1.4,0.4l4.8,150.5
        l1.4,0.1L318.3,604l-0.1-1.7l0.1,1.7c4.5-1.1,9.2-2.1,14.1-2.9c15.4-2.7,32.5-4.2,51.1-4.4c18.7,0.2,35.7,1.7,51.1,4.4
        c4.9,0.9,9.6,1.8,14.1,2.9l0.1-1.7l-0.1,1.7L444,753L444,753z"/>
      <path fill="none" d="M425.3,754.1c6.4-0.3,12.6-0.7,18.7-1.2l4.8-148.9c-4.5-1.1-9.2-2.1-14.1-2.9c-15.4-2.7-32.4-4.2-51.1-4.4
        c-18.7,0.2-35.7,1.7-51.1,4.4c-4.9,0.9-9.6,1.8-14.1,2.9L323,753c6.1,0.5,12.3,0.9,18.7,1.2c9.9,0.5,20.3,0.8,31.1,0.9l21.4,0
        C405,754.9,415.4,754.6,425.3,754.1L425.3,754.1z M427.8,609.9c4.6,0.8,7,4.5,7.4,11v24.8c-0.1,6.5-3.1,9.6-9,9.4h-85.4
        c-5.9,0.2-8.9-2.9-9-9.4v-24.8c0.4-6.5,2.8-10.2,7.4-11c13.6-2,28.4-3.1,44.3-3.3C399.5,606.8,414.2,607.9,427.8,609.9
        L427.8,609.9z M433.9,724.4c-0.5,7-3.7,10.7-9.4,11.1h-82.1c-5.7-0.5-8.9-4.2-9.4-11.1c-0.9-11.5-1.2-23.7-0.9-36.5
        c0.5-7.2,3.6-10.6,9.5-10.4h83.6c5.9-0.2,9.1,3.2,9.5,10.4C435.2,700.8,434.9,712.9,433.9,724.4L433.9,724.4z"/>
      <path fill="none" d="M435.2,620.9c-0.4-6.5-2.8-10.2-7.4-11c-13.6-2-28.3-3.1-44.3-3.3c-16,0.2-30.7,1.3-44.3,3.3
        c-4.6,0.8-7,4.5-7.4,11v24.8c0.1,6.5,3.1,9.6,9,9.4h85.4c5.9,0.2,8.9-2.9,9-9.4V620.9L435.2,620.9z"/>
      <path fill="none" d="M424.6,735.6c5.7-0.5,8.9-4.2,9.4-11.1c1-11.5,1.2-23.7,0.9-36.5c-0.5-7.2-3.6-10.6-9.5-10.4h-83.6
        c-5.9-0.2-9.1,3.2-9.5,10.4c-0.4,12.8-0.1,25,0.9,36.5c0.5,7,3.7,10.7,9.4,11.1H424.6L424.6,735.6z"/>
      <path fill="none" d="M451.7,805.5l-0.1,0l-9.2,0.5c-13.7,0.7-28,1.2-43.2,1.4l-39.9-0.1c-12-0.2-23.6-0.7-34.7-1.2l-9.2-0.5
        l-0.1,0c-2.9-0.7-4.6-1.9-5.2-3.6l-0.9-7.9c-2.5-0.1-4.3-0.6-5.6-1.4c-6.5-3.8-11.1-8.3-13.8-13.5l-0.2-0.2
        c1.5,5,3.5,9.6,5.9,13.9c4.8,8.7,10.6,13.3,17.4,13.6l11.7,0.7c11.1,0.6,22.6,1,34.7,1.2l39.9,0.1c15.1-0.2,29.5-0.7,43.2-1.4
        l11.7-0.7c6.8-0.4,12.7-4.9,17.4-13.6c2.5-4.3,4.4-8.9,5.9-13.9l-0.2,0.2c-2.7,5.3-7.4,9.8-13.8,13.5c-1.2,0.8-3.1,1.3-5.6,1.4
        l-0.9,7.9C456.4,803.6,454.6,804.8,451.7,805.5L451.7,805.5z"/>
      <path fill="none" d="M451.6,805.5l0.1,0c2.9-0.7,4.6-1.9,5.2-3.6l0.9-7.9l0.2-1.2c-33.1,2.1-65.8,2.9-98,2.2l-1.5,2.4
        c-15.8,0-30.7-0.6-44.8-1.8l-1.3-2.7l-3.4-0.2l0.2,1.2l0.9,7.9c0.6,1.7,2.3,2.9,5.2,3.6l0.1,0l9.2,0.5c11.1,0.6,22.6,1,34.7,1.2
        l39.9,0.1c15.1-0.2,29.5-0.7,43.2-1.4L451.6,805.5L451.6,805.5z"/>
      <polygon fill="none" points="477.4,778.8 477.6,778.3 477.3,779 477.4,778.8 		"/>
      <path fill="none" d="M477.3,779l0.4-0.7c0.8-2.9,1.5-5.8,2.1-8.9c-1.4,5.3-2.6,8.3-3.6,9.2c-6,7-11.9,11-17.9,12.1l-0.1,2l0.1,0
        c1.8,0.1,3.2-0.1,4.1-0.5C466.4,790.8,471.4,786.4,477.3,779L477.3,779z"/>
      <path fill="none" d="M458,788.3c-0.1-0.7-0.3-1.4-0.5-2l-6.7-11.9l-38.6,2.2l-1.6,0.1c-3.9,0.3-8.1,0.5-12.5,0.7l-15,0.3l-14-0.3
        c-4.5-0.2-8.7-0.4-12.6-0.7l-1.5-0.1l-38.8-2.3l-6.7,11.9c-0.2,0.6-0.4,1.3-0.5,2l1.1,0.1l0.9,0.1c4.7,0.3,14.4,0.6,29.2,0.9
        c11.4,0.2,25.8,0.4,43.2,0.6C427.5,789.4,452.3,788.9,458,788.3L458,788.3z M442.6,779.4l2.5,5.8c1,1.5,0.4,2.3-1.8,2.3
        c-15.4,1-29.6,1.3-42.6,0.9c-2.6,0.1-5.7-0.4-9.4-1.4c-2-0.5-4.6-0.8-7.7-0.9c-3.1,0.2-5.7,0.5-7.8,0.9c-3.7,1-6.8,1.5-9.4,1.4
        c-13,0.4-27.2,0.1-42.6-0.9c-2.1,0-2.7-0.8-1.8-2.3l2.5-5.8c0.5-2,1.8-2.9,4-2.9l28.1,1.6c3.9,0.3,8.1,0.5,12.6,0.7l14.6,0.3
        l14.3-0.3c4.5-0.2,8.7-0.4,12.5-0.7l28.1-1.6C440.7,776.5,442.1,777.5,442.6,779.4L442.6,779.4z"/>
      <path fill="none" d="M445.1,785.2l-2.5-5.8c-0.5-2-1.8-2.9-4-2.9l-28.1,1.6c-3.9,0.3-8.1,0.5-12.5,0.7l-14.3,0.3l-14.6-0.3
        c-4.5-0.2-8.7-0.4-12.6-0.7l-28.1-1.6c-2.1-0.1-3.4,0.9-4,2.9l-2.5,5.8c-1,1.5-0.4,2.3,1.8,2.3c15.4,1,29.6,1.3,42.6,0.9
        c2.6,0.1,5.7-0.4,9.4-1.4c2-0.5,4.6-0.8,7.8-0.9c3.1,0.2,5.6,0.5,7.7,0.9c3.7,1,6.8,1.5,9.4,1.4c13,0.4,27.2,0.1,42.6-0.9
        C445.4,787.5,446,786.7,445.1,785.2L445.1,785.2z"/>
      <path fill="none" d="M462.2,792.2c-0.9,0.4-2.2,0.6-4.1,0.5l-0.1,0l-0.2,1.2c2.4-0.1,4.3-0.6,5.6-1.4c6.5-3.8,11.1-8.3,13.8-13.5
        C471.4,786.4,466.4,790.8,462.2,792.2L462.2,792.2z"/>
      <path fill="none" d="M458,792.7l0.1-2c-24.9,1.6-49.5,2.5-74,2.5l-6.2-0.5c-1.5,0-3-0.1-4.5-0.2c-2.2-0.2-4.3-0.4-6.5-0.7
        l-4.7-0.7L360,795C392.3,795.6,424.9,794.9,458,792.7L458,792.7L458,792.7z"/>
      <polygon fill="none" points="458.2,792.7 458,792.7 458,792.7 458.2,792.7 		"/>
      <path fill="none" d="M458.2,790.7l-0.2-2.5c-5.6,0.7-30.5,1.2-74.5,1.6c-17.4-0.2-31.8-0.4-43.2-0.6l17.9,0.7l3.4,0.9l0.7,0.2l0,0
        l4.7,0.7c2.1,0.3,4.3,0.5,6.5,0.7c1.5,0.1,3,0.2,4.5,0.2l6.2,0.5C408.6,793.2,433.3,792.4,458.2,790.7L458.2,790.7z"/>
      <path fill="none" d="M484.7,738.6c4-4.8,6.5-13.1,7.6-24.8c1.2-13.8,1.9-27.2,1.9-40.2l-5.3-0.4
        C487.9,695.3,486.5,717.1,484.7,738.6L484.7,738.6z"/>
      <path fill="none" d="M412.2,776.6v-12.7c-7.1,0.2-14.2,0.3-21.3,0.3h-14.6c-7.1,0-14.2-0.1-21.2-0.3v12.7l1.5,0.1
        c3.9,0.3,8.1,0.5,12.6,0.7c-0.1-1.4,1.3-2.2,4.1-2.3h20.6c2.9,0.2,4.2,0.9,4.2,2.3c4.5-0.2,8.7-0.4,12.5-0.7L412.2,776.6
        L412.2,776.6z"/>
      <path fill="none" d="M475.1,288.2l0.5,1.7c4.5,8.5,8.1,31.8,11,69.9c1.4,15.8,1.5,31.4,0.2,47.1v0l0.1,1.4
        c3.3-6.7,5.1-20.7,5.3-42.2c-0.2-22.7-1.5-41.4-3.8-56.3c-1.7-9.3-4.1-15.2-7-17.4L475.1,288.2L475.1,288.2z"/>
      <path fill="none" d="M475.6,289.9l-0.5-1.7c-2.4-5-6.8-9.6-13.3-13.7l-0.5-0.3c-3.9-2.4-8.5-4.7-13.9-6.8l-1.8-0.7
        c-12.3-4-32.8-5.9-61.6-5.9h-0.9c-28.8,0-49.3,2-61.6,5.9l-1.8,0.7c-5.3,2.1-10,4.4-13.9,6.8l-0.5,0.3
        c-6.5,4.1-10.9,8.7-13.3,13.7l-0.5,1.7c-0.5,1.7-0.9,3.6-1.3,5.5c3.8-4.3,8.8-8.6,14.9-12.9c10-6.1,19.2-9.1,27.5-8.9l0.1,0
        l2.8-0.5c12.9-2,27.8-3.1,44.7-3.3h6.7c16.9,0.2,31.8,1.3,44.7,3.3l2.8,0.5l0.1,0c8.3-0.2,17.5,2.8,27.5,8.9
        c6.2,4.3,11.2,8.6,15,12.9C476.6,293.4,476.1,291.6,475.6,289.9L475.6,289.9z M449.6,270.4c0.1-0.2,0.2-0.3,0.4-0.2
        c1.1,0.1,2.1,0.6,3.1,1.2c0.2,0.1,0.3,0.2,0.2,0.5l-0.6,1.5c-0.1,0.2-0.2,0.3-0.4,0.2c-1.2-0.2-2.3-0.6-3.1-1.2
        c-0.2-0.1-0.3-0.3-0.2-0.5L449.6,270.4L449.6,270.4z M318.1,271.9c0.1,0.2,0,0.4-0.2,0.5c-0.8,0.7-1.9,1.1-3.1,1.2
        c-0.2,0.1-0.4,0-0.4-0.2l-0.6-1.5c-0.1-0.2,0-0.4,0.2-0.5c1-0.7,2-1.1,3.1-1.2c0.2-0.1,0.4,0,0.4,0.2L318.1,271.9L318.1,271.9z"/>
      <path fill="none" d="M450,270.2c-0.2-0.1-0.4,0-0.4,0.2l-0.6,1.5c-0.1,0.2,0,0.4,0.2,0.5c0.8,0.7,1.9,1.1,3.1,1.2
        c0.2,0.1,0.4,0,0.4-0.2l0.6-1.5c0.1-0.2,0-0.4-0.2-0.5C452.2,270.7,451.1,270.3,450,270.2L450,270.2z M450.4,271
        c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4C450.1,271.1,450.2,271,450.4,271L450.4,271z M452.4,271.8
        c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
        c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1C452.1,271.6,452.3,271.7,452.4,271.8L452.4,271.8z"/>
      <path fill="none" d="M450.7,271.2c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4
        c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4
        C450.9,271.4,450.9,271.3,450.7,271.2L450.7,271.2z"/>
      <path fill="none" d="M452.5,272.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1
        c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.4-0.1
        C452.5,272.4,452.5,272.3,452.5,272.2L452.5,272.2z"/>
      <path fill="none" d="M317.9,272.4c0.2-0.1,0.3-0.3,0.2-0.5l-0.6-1.5c-0.1-0.2-0.2-0.3-0.4-0.2c-1.1,0.1-2.1,0.6-3.1,1.2
        c-0.2,0.1-0.3,0.2-0.2,0.5l0.6,1.5c0.1,0.2,0.2,0.3,0.4,0.2C316,273.4,317,273,317.9,272.4L317.9,272.4z M317.2,271.6
        c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
        c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.1C317.1,271.3,317.2,271.4,317.2,271.6L317.2,271.6z M315.1,271.6
        c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.1l-0.2-0.4
        c0-0.1,0.1-0.3,0.2-0.4C314.8,271.7,314.9,271.6,315.1,271.6L315.1,271.6z"/>
      <path fill="none" d="M317.1,272c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1
        c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2C316.8,272.1,316.9,272.1,317.1,272L317.1,272z"/>
      <path fill="none" d="M315.4,271.8c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4l0.2,0.4
        c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4C315.6,272,315.5,271.9,315.4,271.8L315.4,271.8z"/>
      <path fill="none" d="M461.8,274.5l-0.5-1c-3.9-2.4-8.5-4.7-13.9-6.8l-1.8,0l1.8,0.7c5.3,2.1,10,4.4,13.9,6.8L461.8,274.5
        L461.8,274.5z"/>
      <path fill="none" d="M454.1,284.5l-0.2-3.2c-4.9-2.3-10.2-4.4-16.1-6.2l3.3,1.6l0.3,3C446,281,450.2,282.6,454.1,284.5
        L454.1,284.5z"/>
      <path fill="none" d="M453.9,281.3l0.2,3.2c7.7,3.7,14.3,8.6,19.6,14.5l0.2,0.2c1.2-0.1,1.8-0.9,1.8-2.6
        C469.9,290.6,462.7,285.5,453.9,281.3L453.9,281.3z"/>
      <polygon fill="none" points="441.1,276.7 437.8,275.1 434.5,273.6 434.4,273.5 431.6,273.1 431.6,273.6 431.7,274.7 431.8,275.2 
        441.1,276.7 		"/>
      <path fill="none" d="M441.4,279.7l-0.3-3l-9.4-1.5l0.2,2.2l2.2,0.4C436.6,278.3,439.1,279,441.4,279.7L441.4,279.7z"/>
      <path fill="none" d="M431.9,277.4l-0.2-2.2l0-0.5l-1-0.1h-0.1l-0.7-0.1l-1-0.2c-1-0.2-2.1-0.6-3.2-1v-0.7l5.9,0.9l0-0.6
        c-12.9-2-27.8-3.1-44.7-3.3h-6.7c-16.9,0.2-31.8,1.3-44.7,3.3l-0.1,0.6l6.1-0.9v0.6c-1.1,0.4-2.2,0.8-3.2,1h-0.1l-2.9,0.4l0,0.5
        l-0.1,2.2c13.9-2.3,30-3.5,48.4-3.7C401.9,273.9,418,275.1,431.9,277.4L431.9,277.4z M423.8,272.5v0.7c-1.1,0.2-2.2,0.2-3.2,0.1h0
        l-1.8-0.2c-1-0.2-2.1-0.5-3.2-0.9v-0.6C418.3,271.9,421.1,272.2,423.8,272.5L423.8,272.5z M423.8,273.8l1.8,0.2l0.2,0.1
        c1.1,0.2,2.3,0.6,3.5,1.1v0.9c-1.2,0.2-2.3,0.2-3.5,0.1l-0.1,0c-0.6,0-1.2-0.1-1.9-0.2c-1.1-0.2-2.3-0.6-3.5-1.1V274
        C421.5,273.8,422.7,273.7,423.8,273.8L423.8,273.8z M419.1,273.9v0.8c-1.2,0.2-2.3,0.3-3.5,0.2h-0.1c-0.6,0-1.2-0.1-1.9-0.2
        c-1.1-0.2-2.3-0.5-3.5-0.9v-0.8c1.2-0.2,2.4-0.4,3.5-0.3l2.4,0.3C417,273.2,418,273.5,419.1,273.9L419.1,273.9z M413.5,271.5v0.6
        c-1.1,0.2-2.1,0.3-3.2,0.3l-0.1,0l-1.8-0.1c-1-0.1-2.1-0.3-3.2-0.7v-0.6C408,271.1,410.8,271.3,413.5,271.5L413.5,271.5z
         M408.7,272.9v0.8c-1.2,0.2-2.3,0.4-3.5,0.3h-0.1c-0.6,0-1.3,0-1.9-0.1c-1.1-0.1-2.3-0.4-3.5-0.8v-0.7c1.2-0.3,2.4-0.4,3.5-0.4
        l2,0.1C406.4,272.3,407.6,272.5,408.7,272.9L408.7,272.9z M403.3,270.9v0.6c-1.1,0.2-2.2,0.3-3.2,0.4l-1.8-0.1
        c-1-0.1-2.1-0.3-3.2-0.6v-0.5C397.8,270.7,400.5,270.7,403.3,270.9L403.3,270.9z M398.6,272.4v0.7c-1.2,0.3-2.4,0.4-3.5,0.4H395
        c-0.6,0.1-1.2,0-1.9-0.1c-1.1-0.1-2.3-0.3-3.5-0.6v-0.7c1.2-0.3,2.4-0.4,3.5-0.5l2.5,0.1C396.5,271.9,397.5,272.1,398.6,272.4
        L398.6,272.4z M393,270.6v0.6c-1.1,0.2-2.2,0.4-3.2,0.4l-1.8,0c-1-0.1-2.1-0.2-3.2-0.5v-0.5L393,270.6L393,270.6z M384.6,273.4
        L384.6,273.4c-0.7,0.1-1.3,0.1-2,0c-1.1-0.1-2.3-0.2-3.5-0.5v-0.7c1.2-0.3,2.4-0.5,3.5-0.5h2c1.1,0.1,2.3,0.2,3.5,0.6v0.7
        C386.9,273.2,385.7,273.3,384.6,273.4L384.6,273.4z M382.6,270.5v0.5c-1.1,0.3-2.1,0.4-3.2,0.5l-1.8,0c-1-0.1-2.1-0.2-3.2-0.4
        v-0.5L382.6,270.5L382.6,270.5z M377.9,272.2v0.7c-1.2,0.3-2.3,0.5-3.5,0.6h-0.1c-0.6,0.1-1.2,0.1-1.9,0c-1.1,0-2.3-0.2-3.5-0.4
        v-0.7c1.2-0.3,2.4-0.5,3.5-0.6h2.4C375.8,271.8,376.9,271.9,377.9,272.2L377.9,272.2z M372.3,270.6v0.5c-1.1,0.3-2.2,0.5-3.2,0.5
        l-1.8,0.1c-1,0-2.1-0.1-3.2-0.3v-0.5L372.3,270.6L372.3,270.6z M367.5,272.4v0.7c-1.2,0.4-2.3,0.6-3.5,0.7H364
        c-0.6,0.1-1.3,0.1-1.9,0.1c-1.1,0-2.3-0.1-3.5-0.3v-0.7c1.2-0.4,2.4-0.6,3.5-0.7l2-0.1C365.2,272,366.3,272.1,367.5,272.4
        L367.5,272.4z M358.8,272.2l-1.8,0.1c-1,0-2.1-0.1-3.2-0.2v-0.6c2.8-0.2,5.6-0.4,8.3-0.5v0.5C361,271.8,359.9,272,358.8,272.2
        L358.8,272.2z M351.8,271.7v0.5c-1.1,0.4-2.1,0.7-3.2,0.8l-0.1,0l-1.8,0.2c-1,0.1-2.1,0-3.2-0.1v-0.6L351.8,271.7L351.8,271.7z
         M357.4,272.9v0.7c-1.2,0.4-2.3,0.7-3.5,0.8h-0.1l-1.9,0.2c-1.1,0-2.3,0-3.5-0.2v-0.8c1.2-0.4,2.4-0.7,3.5-0.9l2.4-0.2
        C355.3,272.6,356.3,272.7,357.4,272.9L357.4,272.9z M347,273.9v0.8c-1.2,0.5-2.3,0.8-3.5,1h-0.1c-0.6,0.2-1.2,0.3-1.9,0.3
        c-1.1,0.1-2.3,0.1-3.5,0v-0.8c1.2-0.6,2.4-1,3.5-1.2l2.4-0.3C345,273.7,346,273.7,347,273.9L347,273.9z"/>
      <path fill="none" d="M423.8,273.1v-0.7c-2.7-0.3-5.5-0.6-8.3-0.9v0.6c1.1,0.4,2.2,0.7,3.2,0.9l1.8,0.2h0
        C421.6,273.3,422.7,273.3,423.8,273.1L423.8,273.1z"/>
      <path fill="none" d="M425.5,274l-1.8-0.2c-1.1-0.1-2.3,0-3.5,0.2v0.8c1.2,0.5,2.4,0.9,3.5,1.1c0.7,0.1,1.3,0.2,1.9,0.2l0.1,0
        c1.1,0.1,2.3,0.1,3.5-0.1v-0.9c-1.2-0.5-2.3-0.9-3.5-1.1L425.5,274L425.5,274z"/>
      <path fill="none" d="M419.1,274.7v-0.8c-1-0.4-2-0.7-3-0.9l-2.4-0.3c-1.1,0-2.3,0.1-3.5,0.3v0.8c1.2,0.4,2.4,0.7,3.5,0.9
        c0.7,0.1,1.3,0.2,1.9,0.2h0.1C416.7,275,417.9,275,419.1,274.7L419.1,274.7z"/>
      <path fill="none" d="M413.5,272.1v-0.6c-2.7-0.2-5.5-0.4-8.3-0.5v0.6c1.1,0.4,2.2,0.6,3.2,0.7l1.8,0.1l0.1,0
        C411.4,272.4,412.5,272.3,413.5,272.1L413.5,272.1z"/>
      <path fill="none" d="M408.7,273.7v-0.8c-1.2-0.4-2.3-0.7-3.5-0.8l-2-0.1c-1.1,0-2.3,0.1-3.5,0.4v0.7c1.2,0.4,2.4,0.7,3.5,0.8
        c0.7,0.1,1.3,0.1,1.9,0.1h0.1C406.4,274.1,407.6,274,408.7,273.7L408.7,273.7z"/>
      <path fill="none" d="M403.3,271.4v-0.6c-2.7-0.1-5.5-0.2-8.3-0.2v0.5c1.1,0.3,2.2,0.5,3.2,0.6l1.8,0.1
        C401.1,271.8,402.2,271.7,403.3,271.4L403.3,271.4z"/>
      <path fill="none" d="M398.6,273.2v-0.7c-1-0.3-2-0.5-3-0.6l-2.5-0.1c-1.1,0.1-2.3,0.2-3.5,0.5v0.7c1.2,0.4,2.4,0.6,3.5,0.6
        c0.7,0.1,1.3,0.1,1.9,0.1h0.1C396.2,273.6,397.4,273.4,398.6,273.2L398.6,273.2z"/>
      <path fill="none" d="M393,271.1v-0.6l-8.3-0.1v0.5c1.1,0.3,2.2,0.4,3.2,0.5l1.8,0C390.9,271.5,392,271.4,393,271.1L393,271.1z"/>
      <path fill="none" d="M384.5,273.4L384.5,273.4c1.2-0.1,2.4-0.2,3.6-0.5v-0.7c-1.2-0.3-2.3-0.5-3.5-0.6h-2
        c-1.1,0.1-2.3,0.2-3.5,0.5v0.7c1.2,0.3,2.4,0.5,3.5,0.5C383.2,273.4,383.9,273.4,384.5,273.4L384.5,273.4z"/>
      <path fill="none" d="M382.6,271v-0.5l-8.3,0.1v0.5c1.1,0.2,2.2,0.4,3.2,0.4l1.8,0C380.4,271.5,381.5,271.3,382.6,271L382.6,271z"
        />
      <path fill="none" d="M377.9,272.9v-0.7c-1-0.2-2-0.4-3-0.5h-2.4c-1.1,0.1-2.3,0.3-3.5,0.6v0.7c1.2,0.3,2.4,0.4,3.5,0.4
        c0.7,0,1.3,0,1.9,0h0.1C375.5,273.4,376.7,273.2,377.9,272.9L377.9,272.9z"/>
      <path fill="none" d="M372.3,271.1v-0.5l-8.3,0.3v0.5c1.1,0.2,2.2,0.3,3.2,0.3l1.8-0.1C370.2,271.6,371.3,271.4,372.3,271.1
        L372.3,271.1z"/>
      <path fill="none" d="M367.5,273.1v-0.7c-1.2-0.2-2.3-0.4-3.5-0.4l-2,0.1c-1.1,0.1-2.3,0.4-3.5,0.7v0.7c1.2,0.2,2.4,0.4,3.5,0.3
        c0.7,0,1.3,0,1.9-0.1h0.1C365.2,273.7,366.3,273.4,367.5,273.1L367.5,273.1z"/>
      <path fill="none" d="M357,272.3l1.8-0.1c1.1-0.1,2.2-0.4,3.2-0.7V271c-2.7,0.1-5.5,0.3-8.3,0.5v0.6
        C354.9,272.2,356,272.3,357,272.3L357,272.3z"/>
      <path fill="none" d="M351.8,272.2v-0.5l-8.3,0.8v0.6c1.1,0.1,2.2,0.2,3.2,0.1l1.8-0.2l0.1,0C349.7,272.9,350.7,272.6,351.8,272.2
        L351.8,272.2z"/>
      <path fill="none" d="M357.4,273.6v-0.7c-1-0.2-2-0.3-3-0.3l-2.4,0.2c-1.1,0.2-2.3,0.5-3.5,0.9v0.8c1.2,0.2,2.4,0.2,3.5,0.2
        l1.9-0.2h0.1C355,274.3,356.2,274,357.4,273.6L357.4,273.6z"/>
      <path fill="none" d="M347,274.6v-0.8c-1-0.1-2-0.2-3-0.2l-2.4,0.3c-1.1,0.2-2.3,0.6-3.5,1.2v0.8c1.2,0.1,2.4,0.1,3.5,0
        c0.7,0,1.3-0.1,1.9-0.3h0.1C344.7,275.4,345.9,275.1,347,274.6L347,274.6z"/>
      <path fill="none" d="M434.1,277.8l-2.2-0.4c-13.9-2.3-30.1-3.5-48.4-3.7c-18.4,0.2-34.5,1.5-48.4,3.7l-2.2,0.4
        c-2.5,0.6-4.9,1.2-7.3,1.9c-4.5,1.3-8.8,2.9-12.7,4.9c-7.7,3.7-14.3,8.6-19.6,14.5l-0.1,0.2c-1.8,2.1-3.4,6.2-4.9,12
        c-2.8,20.1-4.9,42.7-6.3,68l-1.8,27.7v0l-0.1,1.4l-0.7,14.4l2.9,3c1.1-35.5,3.2-69,6.3-100.5l0-0.1c1.1-9.6,2.9-15.9,5.2-19.1
        c2.5-3.4,6.2-6.5,10.9-9.4c5-3,11.1-5.7,18.5-8.2c14.6-4.5,34.7-7,60.1-7.6c25.4,0.6,45.5,3.2,60.1,7.6c7.4,2.5,13.6,5.2,18.6,8.2
        l0.7,0.4c4.4,2.8,7.8,5.7,10.2,8.9c2.4,3.2,4.1,9.5,5.2,19.1l0,0.1c3.1,31.5,5.2,65,6.3,100.5l2.9-3l-0.7-14.4l-0.1-1.4v0
        l-1.8-27.7c-1.4-25.3-3.4-47.9-6.3-68c-1.5-5.9-3.1-9.9-4.9-12l-0.2-0.2c-5.3-5.9-11.8-10.7-19.6-14.5c-3.9-1.9-8.1-3.5-12.7-4.9
        C439.1,279,436.6,278.3,434.1,277.8L434.1,277.8z M426.4,281.6V279l-2.8-0.5v-0.9c1.4,0.3,2.7,0.6,4.1,0.9v3.4L426.4,281.6
        L426.4,281.6z M418,276.6l1.5,0.2l-2.2,3.4l-1.6-0.2l-2-4l1.5,0.2c0.2,0.2,0.3,0.5,0.4,0.7l1.9,0.3L418,276.6L418,276.6z
         M409.2,279.4l-1.4-0.1l-2.6-2.5h0l0,2.3l-1.4-0.1l0.1-3.7l1.4,0.1l2.6,2.5h0l0-2.3l1.4,0.1L409.2,279.4L409.2,279.4z
         M393.6,277.3c-0.2-0.5-0.2-1,0-1.4c0.4-0.7,1.1-1.1,2-1c1.1,0,2.2,0.1,3.3,0.1l0,3.6l-3.2-0.2C394.6,278.3,394,278,393.6,277.3
        L393.6,277.3z M379.5,274.7h1.8l1.3,1.3h0v-1.3h1.4v3.4h-2.1c-1.2,0-1.8-0.3-1.9-1c0-0.5,0.4-0.8,1.2-1L379.5,274.7L379.5,274.7z
         M374,277.9c-0.6,0.3-1.3,0.5-2.1,0.6c-0.8,0-1.5-0.1-2.1-0.4c-0.4-0.2-0.7-0.5-0.8-0.9l-0.1-0.4l0.1-0.4c0.4-1,1.3-1.5,3-1.6
        c1.9,0,2.9,0.6,3,1.8C374.9,277.1,374.6,277.5,374,277.9L374,277.9z M365,278.8l-1.5,0.1l-1.3-2.1l-1.3,2.3l-1.6,0.1l2.4-3.8
        l1.1-0.1L365,278.8L365,278.8z M352.4,277.5v-0.6l-1.7,0.2v-0.8l3.1-0.4v3.7l-3.1,0.4v-0.8l1.7-0.3v-0.6l-1.6,0.2v-0.8
        L352.4,277.5L352.4,277.5z M343.2,278.9L343.2,278.9l0-1.3l1.4-0.3v3.5l-2.1,0.4c-1.2,0.2-1.8,0-1.9-0.7c0-0.5,0.4-1,1.2-1.2
        l-1.7-1l1.8-0.4L343.2,278.9L343.2,278.9z"/>
      <path fill="none" d="M426.4,279v2.6l1.3,0.2v-3.4c-1.3-0.3-2.7-0.6-4.1-0.9v0.9L426.4,279L426.4,279z"/>
      <path fill="none" d="M419.5,276.9l-1.5-0.2l-0.4,0.6l-1.9-0.3c-0.1-0.2-0.2-0.5-0.4-0.7l-1.5-0.2l2,4l1.6,0.2L419.5,276.9
        L419.5,276.9z M416.6,279L416.6,279c-0.2-0.4-0.4-0.8-0.6-1.3c0.4,0,0.8,0.1,1.2,0.2L416.6,279L416.6,279z"/>
      <path fill="none" d="M416.6,279L416.6,279l0.7-1.1c-0.4-0.1-0.8-0.1-1.2-0.2C416.2,278.2,416.4,278.6,416.6,279L416.6,279z"/>
      <polygon fill="none" points="407.8,279.3 409.2,279.4 409.3,275.7 407.8,275.6 407.8,277.9 407.8,277.9 405.2,275.4 403.8,275.3 
        403.7,279 405.1,279.1 405.1,276.8 405.2,276.8 407.8,279.3 		"/>
      <path fill="none" d="M393.6,275.9c-0.2,0.5-0.2,0.9,0,1.4c0.4,0.7,1.1,1,1.9,1.1l3.2,0.2l0-3.6c-1.1-0.1-2.2-0.1-3.3-0.1
        C394.7,274.8,394,275.2,393.6,275.9L393.6,275.9z M396.2,277.7l-0.1,0c-0.3,0-0.6-0.1-0.9-0.3c-0.1-0.1-0.2-0.4-0.3-0.7l0-0.3
        c0-0.3,0.1-0.5,0.3-0.7c0.2-0.1,0.4-0.2,0.8-0.2l1.3,0.1l0,2.1L396.2,277.7L396.2,277.7z"/>
      <path fill="none" d="M396.1,277.7l0.1,0l1.1,0l0-2.1l-1.3-0.1c-0.4,0-0.6,0-0.8,0.2c-0.2,0.2-0.4,0.4-0.3,0.7l0,0.3
        c0.1,0.3,0.2,0.5,0.3,0.7C395.4,277.6,395.7,277.7,396.1,277.7L396.1,277.7z"/>
      <path fill="none" d="M381.3,274.7h-1.8l1.7,1.4c-0.8,0.1-1.2,0.4-1.2,1c0.1,0.7,0.7,1.1,1.9,1h2.1v-3.4h-1.4v1.3h0L381.3,274.7
        L381.3,274.7z M382.5,277.5c-0.5,0-0.9-0.1-1-0.4c0.1-0.3,0.4-0.5,1-0.4h0.1v0.9H382.5L382.5,277.5z"/>
      <path fill="none" d="M381.5,277c0.1,0.3,0.4,0.4,1,0.4h0.1v-0.9h-0.1C381.9,276.6,381.6,276.7,381.5,277L381.5,277z"/>
      <path fill="none" d="M371.9,278.5c0.8-0.1,1.5-0.3,2.1-0.6c0.6-0.4,0.9-0.8,0.9-1.3c-0.1-1.2-1.1-1.8-3-1.8
        c-1.6,0.1-2.6,0.6-3,1.6l-0.1,0.4l0.1,0.4c0.2,0.3,0.4,0.6,0.8,0.9C370.4,278.4,371.1,278.5,371.9,278.5L371.9,278.5z
         M370.3,276.7c0.1-0.7,0.6-1.1,1.5-1.1c1,0,1.5,0.3,1.5,1c-0.1,0.5-0.6,0.9-1.5,1C371,277.6,370.5,277.3,370.3,276.7L370.3,276.7z
        "/>
      <path fill="none" d="M371.9,275.7c-0.9,0-1.4,0.4-1.5,1.1c0.1,0.6,0.7,0.9,1.5,0.9c1-0.1,1.5-0.4,1.5-1
        C373.3,276,372.8,275.6,371.9,275.7L371.9,275.7z"/>
      <polygon fill="none" points="363.4,278.9 365,278.8 362.6,275.3 361.6,275.4 359.2,279.2 360.8,279.1 362.1,276.8 363.4,278.9 		
        "/>
      <polygon fill="none" points="352.4,276.8 352.4,277.5 350.8,277.7 350.8,278.5 352.4,278.3 352.4,278.8 350.7,279.1 350.7,279.9 
        353.8,279.5 353.8,275.8 350.7,276.3 350.7,277.1 352.4,276.8 		"/>
      <path fill="none" d="M343.2,278.9L343.2,278.9l-1.4-1l-1.8,0.4l1.7,1c-0.8,0.3-1.2,0.7-1.2,1.2c0.1,0.7,0.7,0.9,1.9,0.7l2.1-0.4
        v-3.5l-1.4,0.3V278.9L343.2,278.9z M343.1,279.6l0.1,0v0.9l-0.1,0c-0.5,0.1-0.9,0.1-1-0.3C342.2,279.8,342.5,279.6,343.1,279.6
        L343.1,279.6z"/>
      <path fill="none" d="M343.2,279.5l-0.1,0c-0.5,0.1-0.9,0.3-1,0.6c0.1,0.3,0.4,0.4,1,0.3l0.1,0V279.5L343.2,279.5z"/>
      <path fill="none" d="M431.7,274.7l-0.1-1.1l-5.9-0.9v0.7c1.1,0.5,2.2,0.8,3.2,1l1,0.2l0.7,0.1h0.1L431.7,274.7L431.7,274.7z"/>
      <path fill="none" d="M434.5,273.6l3.3,1.6c5.8,1.8,11.2,3.9,16.1,6.2c8.8,4.2,16,9.3,21.8,15.3l0.3-0.4l-0.2-1
        C463.2,283.7,449.5,276.5,434.5,273.6L434.5,273.6z"/>
      <path fill="none" d="M462,282.5c-10-6.1-19.2-9.1-27.5-8.9c15,2.9,28.7,10.1,41.2,21.6l1.4,1.3l-0.2-1.1
        C473.1,291.1,468.1,286.8,462,282.5L462,282.5z"/>
      <path fill="none" d="M478.3,325.2L478.3,325.2c-1.2-9.6-2.9-16-5.3-19.2c-2.4-3.2-5.8-6.2-10.2-8.9c4.5,3.5,7.4,9,8.6,16.5
        l6.2,102.3c0.4,0.8,0.9,1.7,1.2,2.6c0.7,1.7,1.2,3.5,1.5,5.6l0.4,2.9l3.8-1.2C483.5,390.2,481.4,356.7,478.3,325.2L478.3,325.2z"
        />
      <path fill="none" d="M477.8,301.7c0,0.4-0.1,0.8-0.4,1.1l1.3,8.4c2.8,20.1,4.9,42.7,6.3,68l1.8,27.7c1.3-15.6,1.2-31.3-0.2-47.1
        c-2.9-38.1-6.5-61.4-11-69.9c0.5,1.7,0.9,3.6,1.3,5.5l0.2,1.1L477.8,301.7L477.8,301.7z"/>
      <path fill="none" d="M477.4,302.8c0.2-0.3,0.4-0.7,0.4-1.1l-0.7-5.2l-1.4-1.3l0.2,1l0.2,1L477.4,302.8L477.4,302.8z"/>
      <polygon fill="none" points="475.6,296.6 476.2,297.1 475.9,296.1 475.6,296.6 		"/>
      <path fill="none" d="M476.2,297.1l-0.5-0.6c0,1.7-0.6,2.5-1.8,2.6c1.8,2.1,3.4,6.2,4.9,12l-1.3-8.4L476.2,297.1L476.2,297.1z"/>
      <path fill="none" d="M462.9,297l-0.7-0.4c-5-3-11.2-5.7-18.6-8.2c-14.6-4.5-34.7-7-60.1-7.6c-25.4,0.6-45.5,3.2-60.1,7.6
        c-7.4,2.5-13.6,5.2-18.5,8.2c-4.9,3.4-8,9.1-9.2,17l-6.3,102.2c1.2-2.1,2.6-3.8,4.3-5l0.6-0.4c6-3.8,11.9-7.1,17.5-9.9l9.7-80.2
        c1.8-13.9,3.1-18.1,4-12.6l-4.2,88.6l3.1-1.2c15.4-4.3,30-7,43.7-8.2l1.8-0.2c5-0.4,9.9-0.6,14.9-0.6l16.2,0.9
        c13.1,1.3,27.1,4,41.8,8.1c1,0.4,2.1,0.8,3.1,1.2l-4.2-88.6c0.9-5.5,2.2-1.3,4,12.6l9.7,80.2c5.6,2.7,11.4,6,17.5,9.9l0.6,0.4
        c1.7,1.2,3.2,2.9,4.3,5l-6.2-102.3C470.3,306,467.4,300.5,462.9,297L462.9,297z M414.2,358.7c0.3,0,0.5,0.1,0.7,0.3
        c0.2,0.2,0.3,0.4,0.3,0.7v1.8c0,0.1-0.1,0.2-0.3,0.3l-0.7,0.1l-0.7-0.1c-0.2-0.1-0.3-0.2-0.3-0.3v-1.8c0-0.3,0.1-0.5,0.3-0.7
        C413.7,358.8,414,358.7,414.2,358.7L414.2,358.7z M352.8,358.7c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v1.8
        c0,0.1-0.1,0.2-0.3,0.3l-0.7,0.1l-0.7-0.1c-0.2-0.1-0.3-0.2-0.3-0.3v-1.8c0-0.3,0.1-0.5,0.3-0.7
        C352.3,358.8,352.5,358.7,352.8,358.7L352.8,358.7z"/>
      <path fill="none" d="M415,359c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.8
        c0,0.1,0.1,0.2,0.3,0.3l0.7,0.1l0.7-0.1c0.2-0.1,0.3-0.2,0.3-0.3v-1.8C415.2,359.4,415.1,359.2,415,359L415,359z"/>
      <path fill="none" d="M353.5,359c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.8
        c0,0.1,0.1,0.2,0.3,0.3l0.7,0.1l0.7-0.1c0.2-0.1,0.3-0.2,0.3-0.3v-1.8C353.8,359.4,353.7,359.2,353.5,359L353.5,359z"/>
      <path fill="none" d="M445.6,320.4c-1.8-13.9-3.1-18.1-4-12.6l4.2,88.6c3.1,1.2,6.2,2.6,9.4,4.2L445.6,320.4L445.6,320.4z"/>
      <polygon fill="none" points="510.6,468.8 511.2,466.8 481.7,458.8 481.8,459.7 481.8,461.1 485.5,462.1 488.7,462.9 510.6,468.8 
            "/>
      <path fill="none" d="M503.8,452.9c-4.4-2.2-9.5-4.1-15.5-5.8c-1-0.3-2.1-0.6-3.2-0.9l0,0c-0.5-0.2-1-0.2-1.4,0.1
        c-1.5,1-2.1,5.2-1.9,12.5l29.5,8C510.7,460.2,508.2,455.6,503.8,452.9L503.8,452.9z"/>
      <path fill="none" d="M488.7,462.9l-3.2-0.9c0.7,33.4,0.9,61.7,0.8,84.9l0,3.8l3.3-2.1v-6.9c-0.4,0.1-0.8,0.1-1.3,0V535v-3.2
        c-0.6-1-1-3.4-1-7.4c0-3,0.3-5.2,0.8-6.5v-6.5c0.5-0.1,1,0.1,1.4,0.3C489.3,495.4,489,479.1,488.7,462.9L488.7,462.9z"/>
      <path fill="none" d="M488.3,447.1l-0.7-24.4l-2.9,3l0.5,20.5C486.2,446.5,487.3,446.8,488.3,447.1L488.3,447.1z"/>
      <path fill="none" d="M473.4,410.8l-0.6-0.4c-6-3.8-11.9-7.1-17.5-9.9c-3.2-1.6-6.4-3-9.4-4.2c-1.1-0.4-2.1-0.8-3.1-1.2
        c-14.7-4.1-28.7-6.8-41.8-8.1l-16.2-0.9c-5,0-9.9,0.1-14.9,0.6l-1.8,0.2c-13.7,1.2-28.2,4-43.7,8.2l-3.1,1.2
        c-3.1,1.2-6.2,2.6-9.4,4.2c-5.6,2.7-11.4,6-17.5,9.9l-0.6,0.4l0.5,3.5c20.3-7.9,44-13.1,71.1-15.6l0.1,0c2.8-0.3,5.7-0.6,8.7-0.7
        c3.1-0.2,6.3-0.3,9.5-0.3c3.2,0,6.3,0.1,9.3,0.3c2.9,0.2,5.8,0.4,8.7,0.7l0.1,0c27.1,2.5,50.8,7.7,71.1,15.6L473.4,410.8
        L473.4,410.8z"/>
      <path fill="none" d="M481.8,461.1l-0.1-1.4l-0.7-0.1l0.7,92.2l4.5-1l0-3.8c0.1-23.3-0.1-51.6-0.8-84.9L481.8,461.1L481.8,461.1z"
        />
      <path fill="none" d="M478.7,459.2L478.7,459.2l-2.7,1.8c-1.2,0.9-2.1,2.3-2.7,4.2l-7.1,35.5c0.5,17,0.7,33.9,0.7,50.7
        c4.7-2.3,8.8-5.6,12.5-9.7L478.7,459.2L478.7,459.2z"/>
      <path fill="none" d="M478.7,459.1L478.7,459.1l2.4,0.4l0.7,0.1l0-0.9c-0.2-7.3,0.5-11.5,1.9-12.5l-2.7-0.7l-2.2-0.6l-0.6-0.2V457
        C478,458.3,478.2,459,478.7,459.1L478.7,459.1z"/>
      <path fill="none" d="M476,460.9l2.7-1.8c-0.5-0.1-0.7-0.8-0.5-2.2v-12.1l0.6,0.2v-7.6l-5.5,27.7
        C473.9,463.2,474.8,461.8,476,460.9L476,460.9z"/>
      <polygon fill="none" points="481.1,459.6 478.7,459.2 479.3,541.6 479.8,541.1 479.8,552.2 481.7,551.8 481.1,459.6 		"/>
      <path fill="none" d="M481,445.6l2.7,0.7c0.4-0.3,0.9-0.3,1.4-0.1l0,0l-0.5-20.5l-3.8,1.2L481,445.6L481,445.6z"/>
      <polygon fill="none" points="478.8,445 481,445.6 480.8,426.9 478.8,437.4 478.8,445 		"/>
      <polygon fill="none" points="478.8,437.4 480.8,426.9 480.4,424.1 466.1,497.1 466.2,500.7 473.3,465.1 478.8,437.4 		"/>
      <path fill="none" d="M478.9,418.4c-0.4-0.9-0.8-1.8-1.2-2.6c-1.2-2.1-2.6-3.8-4.3-5l-0.5,3.5c-3.3,21.8-8.1,45.3-14.5,70.5
        c3.4,1.4,5.6,2.8,6.5,4.2L478.9,418.4L478.9,418.4z"/>
      <path fill="none" d="M480.4,424.1c-0.4-2.1-0.9-4-1.5-5.6l-14,70.6c0.1,0.2,0.2,0.4,0.3,0.6l0.9,7.3L480.4,424.1L480.4,424.1z"/>
      <path fill="none" d="M492,535.7l0-0.8l-3.8,0.1v6.7c0.5,0.1,0.9,0.1,1.3,0C491.2,541.4,492,539.4,492,535.7L492,535.7z"/>
      <path fill="none" d="M488.2,531.8v3.2l3.8-0.1c0.3-7.7,0-14.3-0.9-20c-0.3-1.6-0.9-2.7-1.7-3.2c-0.4-0.3-0.9-0.4-1.4-0.3v6.5
        c0.8,0.3,1.3,1.4,1.5,3.1l0,8.5C489.4,531.1,489,531.9,488.2,531.8L488.2,531.8z"/>
      <path fill="none" d="M489.5,548.6l-3.3,2.1c-0.1,31.8-0.7,63.5-2,95.1c-0.4,4.9-2.1,6.8-5,5.6l-11.3-4.1c-1.3-0.4-2-1.1-2-2.3
        l0.1-13.8l0-2l0.7-60.4l0.1-8.4l-1.6,0.3l0,1.3l-1,87.2l0.1,9.3c0.5,3.2,1.7,5.2,3.6,6.1l18.9,8.5l0.5-12.7c-0.5,0.1-1,0.1-1.6,0
        l0.2-6.7l0.1-3.2c-0.6-1-0.8-3.4-0.7-7.4c0.1-3,0.5-5.2,1-6.4l0.2-6.5c0.7-0.1,1.3,0.1,1.7,0.6l0.1-4.7
        C489.1,600,489.5,574.2,489.5,548.6L489.5,548.6z"/>
      <path fill="none" d="M488,517.9c-0.5,1.3-0.8,3.5-0.8,6.5c0,4,0.4,6.4,1,7.4c0.8,0.1,1.2-0.6,1.3-2.2l0-8.5
        C489.3,519.3,488.9,518.2,488,517.9L488,517.9z"/>
      <path fill="none" d="M454.8,483.6l3.6,1.3c6.4-25.3,11.2-48.8,14.5-70.5c-20.3-7.9-44-13.1-71.1-15.6l-0.1,0
        c-2.8-0.3-5.7-0.6-8.7-0.7c-3-0.2-6.2-0.3-9.3-0.3c-3.3,0-6.4,0.1-9.5,0.3c-3,0.2-5.9,0.4-8.7,0.7l-0.1,0
        c-27.1,2.5-50.8,7.7-71.1,15.6c3.3,21.8,8.1,45.3,14.5,70.5l3.5-1.3c18.3-6.5,36.7-10.6,55.3-12.4l8.8-0.7
        c2.4-0.1,4.8-0.2,7.1-0.2c2.5,0,4.9,0.1,7.4,0.2l8.8,0.7C418.1,473,436.5,477.1,454.8,483.6L454.8,483.6z M465.6,441.2l-2,8.1
        l-6.6,29.8c-0.7,2-2.7,2.3-5.8,0.9c-14.3-5.2-30.9-9.2-49.8-11.9c-1.7-0.3-2.7-1.7-3.1-4.3c-1.8-7.5-5.1-11.3-10-11.6
        c-1.5-0.1-3.1-0.1-4.7-0.1c-1.8,0-3.4,0-5,0.1c-4.9,0.3-8.2,4.1-10,11.6c-0.3,2.6-1.3,4-3.1,4.3c-18.9,2.7-35.5,6.6-49.8,11.9
        c-3.1,1.4-5,1.1-5.8-0.9l-6.7-29.8l-2-8.1c-0.7-3.3-0.4-6,0.9-8.1c4.8-7.1,9.7-11.5,14.8-13.1c20.3-7,42.4-10.6,66.4-10.9
        c24,0.3,46.1,4,66.4,10.9c5.1,1.7,10,6.1,14.7,13.1C465.9,435.2,466.2,437.9,465.6,441.2L465.6,441.2z"/>
      <path fill="none" d="M463.6,449.3l2-8.1c0.7-3.3,0.4-6-0.9-8.1c-4.8-7.1-9.7-11.5-14.7-13.1c-20.3-7-42.5-10.6-66.4-10.9
        c-23.9,0.3-46,4-66.4,10.9c-5.1,1.7-10,6.1-14.8,13.1c-1.2,2.1-1.5,4.8-0.9,8.1l2,8.1l6.7,29.8c0.7,2,2.6,2.3,5.8,0.9
        c14.3-5.2,30.9-9.2,49.8-11.9c1.7-0.3,2.7-1.7,3.1-4.3c1.8-7.5,5.1-11.3,10-11.6c1.6-0.1,3.2-0.1,5-0.1c1.7,0,3.2,0,4.7,0.1
        c4.9,0.3,8.2,4.1,10,11.6c0.3,2.6,1.3,4,3.1,4.3c18.9,2.7,35.5,6.6,49.8,11.9c3.1,1.4,5,1.1,5.8-0.9L463.6,449.3L463.6,449.3z"/>
      <path fill="none" d="M466.2,500.7l-0.1-3.6c-0.9,3-1.4,8-1.5,15l0.4,52l0.1-2.2l0-1.3l1.6-0.3l0-9
        C466.9,534.5,466.7,517.6,466.2,500.7L466.2,500.7z"/>
      <path fill="none" d="M464.9,489.1c-0.9-1.4-3.1-2.8-6.5-4.2c-0.8,5.8-1.2,12.9-1.3,21.4l-5.2,162.8c1-0.2,1.9-0.4,2.9-0.4
        l1.5-96.8l-1.5,96.8c2.3-0.2,4.8-0.1,7.2,0.4l1-33.6c1-43.6,1.1-87.3,0.4-130.9C463.3,498.3,463.8,493.2,464.9,489.1L464.9,489.1z
        "/>
      <path fill="none" d="M453.4,506.2c0-8.5,0.5-16,1.5-22.7c-18.3-6.5-36.7-10.6-55.3-12.4l-8.8-0.7c-2.5-0.1-4.9-0.2-7.4-0.2
        c-2.4,0-4.8,0.1-7.1,0.2l-8.8,0.7c-18.5,1.8-36.9,6-55.3,12.4c1,6.6,1.5,14.2,1.5,22.7l3.1,96.1l1.4-0.4c4.6-1.1,9.3-2.1,14.2-3
        c10.2-1.8,21.1-3.1,32.7-3.8c5.9-0.4,12-0.6,18.3-0.7c6.4,0.1,12.5,0.3,18.5,0.7c11.6,0.7,22.5,2,32.7,3.8c4.9,0.9,9.7,1.8,14.3,3
        l1.4,0.4L453.4,506.2L453.4,506.2z M431.2,507c3.8,1.8,5.9,5.5,6.5,11.1v27.5c-0.5,6.3-4.2,9-11,8.1c-12.4-2.3-24.7-3.6-36.9-4
        l-12.7,0c-12.2,0.4-24.5,1.7-36.9,4c-6.7,0.9-10.4-1.8-11-8.1v-27.5c0.5-5.7,2.7-9.4,6.5-11.1c11.9-4.1,24.4-6.6,37.6-7.4l2.6-0.1
        c2.6-0.1,5.2-0.2,7.9-0.2c2.5,0,4.9,0.1,7.3,0.2l2.5,0.1C406.9,500.4,419.4,502.8,431.2,507L431.2,507z"/>
      <path fill="none" d="M437.7,518.1c-0.5-5.7-2.7-9.4-6.5-11.1c-11.9-4.1-24.4-6.6-37.6-7.4l-2.5-0.1c-2.4-0.1-4.9-0.2-7.3-0.2
        c-2.6,0-5.2,0-7.9,0.2l-2.6,0.1c-13.2,0.8-25.7,3.3-37.6,7.4c-3.8,1.8-5.9,5.5-6.5,11.1v27.5c0.6,6.3,4.2,9,11,8.1
        c12.4-2.3,24.7-3.6,36.9-4c-2.1,0-4.2,0.1-6.3,0.2c2.1-0.1,4.2-0.2,6.3-0.2l12.7,0c2.1,0,4.2,0.1,6.3,0.2
        c-2.1-0.1-4.2-0.2-6.3-0.2c12.2,0.4,24.5,1.7,36.9,4c6.8,0.9,10.4-1.8,11-8.1V518.1L437.7,518.1z"/>
      <path fill="none" d="M479.4,563.7l0.4-0.3v-11.1l-0.4,0.4c-3.2,3.3-7.4,5.8-12.6,7.7l-0.1,8.4C471.6,567.7,475.8,566,479.4,563.7
        L479.4,563.7z"/>
      <path fill="none" d="M479.8,563.4l-0.4,0.3c-0.1,20.6-0.6,40.8-1.4,60.6l0.6-0.3v2.3l-0.7,0.4L477,645c-0.2,1.4-0.9,2-2.3,1.8
        l0.6,0.1c2.2,0.2,3.6-0.4,4-2c1.6-30.1,2.4-61.1,2.4-92.9v-0.3l-2,0.4V563.4L479.8,563.4z"/>
      <path fill="none" d="M479.4,563.7c-3.6,2.3-7.8,4-12.6,5.1l-0.7,60.4c4.7-1.4,8.7-3,11.9-4.9C478.8,604.4,479.2,584.2,479.4,563.7
        L479.4,563.7z"/>
      <path fill="none" d="M479.4,552.7v-0.6V552.7l0.4-0.4v-11.1l-0.4,0.5c-3.6,4.1-7.8,7.4-12.5,9.7l0,9
        C472,558.5,476.2,555.9,479.4,552.7L479.4,552.7z"/>
      <path fill="none" d="M481.7,551.8v0.3c0,31.8-0.8,62.8-2.4,92.9l-0.1,1.8c-0.5,2.3-2.4,2.7-5.6,1.4l-7.7-3.3c0,1.2,0.7,2,2,2.3
        l11.3,4.1c3,1.2,4.6-0.7,5-5.6c1.2-31.6,1.9-63.2,2-95.1L481.7,551.8L481.7,551.8z"/>
      <path fill="none" d="M486,653.6l-0.2,6.7c0.6,0.2,1.1,0.2,1.6,0c1.4-0.4,2.1-2.1,2.3-5.1l0.1-1.2l0-0.5L486,653.6L486,653.6z"/>
      <path fill="none" d="M489.7,655.3c-0.2,3-1,4.6-2.3,5.1l-0.5,12.7l2.1,0.2L489.7,655.3L489.7,655.3z"/>
      <path fill="none" d="M486,653.6l3.8,0.1l0.1-2.1l0.3-8.9l-0.6-9c-0.2-1.4-0.6-2.4-1.2-3c-0.5-0.5-1-0.7-1.7-0.6l-0.2,6.5
        c0.9,0.4,1.4,1.8,1.4,4.1l-0.2,6.8c-0.1,2.1-0.5,3.1-1.4,2.9L486,653.6L486,653.6z"/>
      <path fill="none" d="M489.7,654.1l-0.1,1.2l-0.7,17.9l5.3,0.4c0-6.1-0.1-12.1-0.3-18c-0.4-10.4-1.5-18.6-3.5-24.7l-0.3,11.7
        l-0.3,8.9l0,2.1L489.7,654.1L489.7,654.1z"/>
      <polygon fill="none" points="489.7,654.1 489.8,653.6 489.8,653.6 489.7,654.1 		"/>
      <polygon fill="none" points="489.8,653.6 489.8,653.6 489.8,651.6 489.8,653.6 		"/>
      <path fill="none" d="M490.4,630.9c-0.6-1.9-1.3-3.5-2-5l-0.1,4.7c0.6,0.6,1,1.6,1.2,3l0.6,9L490.4,630.9L490.4,630.9z"/>
      <path fill="none" d="M487.5,647.5l0.2-6.8c0-2.3-0.4-3.7-1.4-4.1c-0.6,1.3-0.9,3.4-1,6.4c-0.1,4,0.2,6.4,0.7,7.4
        C487,650.5,487.5,649.5,487.5,647.5L487.5,647.5z"/>
      <path fill="none" d="M474.7,646.8c1.4,0.2,2.1-0.4,2.3-1.8l0.9-18.3c-3,1.9-7,3.3-11.8,4.4l-0.1,13.8L474.7,646.8L474.7,646.8z"/>
      <path fill="none" d="M475.3,647l-0.6-0.1l-8.8-1.8l7.7,3.3c3.2,1.3,5.1,0.8,5.6-1.4l0.1-1.8C478.9,646.5,477.5,647.2,475.3,647
        L475.3,647z"/>
      <path fill="none" d="M478.5,624l-0.6,0.3c-3.2,1.9-7.1,3.5-11.9,4.9l0,2c4.8-1.1,8.8-2.6,11.8-4.4l0.7-0.4V624L478.5,624z"/>
      <path fill="none" d="M301,629.2l0,2l0.1,13.8c0,1.2-0.7,2-2,2.3l-11.3,4.1c-3,1.2-4.6-0.7-5-5.6c-1.2-31.6-1.9-63.2-2-95.1
        l-3.3-2.1c0.1,25.6,0.4,51.3,1.1,77.3l0.1,4.7c0.5-0.5,1-0.7,1.7-0.6l0.2,6.5c0.5,1.3,0.9,3.4,1,6.4c0.1,4-0.2,6.4-0.7,7.4
        l0.1,3.2l0.2,6.7c-0.6,0.2-1.1,0.2-1.6,0l0.5,12.7l18.9-8.5c1.9-0.9,3.1-2.9,3.6-6.1l0.1-9.3l-1-87.2l0-1.3l-1.6-0.3l0.1,8.4
        L301,629.2L301,629.2z"/>
      <path fill="none" d="M301,631.1l0-2c-4.7-1.4-8.7-3-11.9-4.9l-0.6-0.3v2.3l0.7,0.4C292.2,628.6,296.2,630,301,631.1L301,631.1z"/>
      <path fill="none" d="M301.1,645l-0.1-13.8c-4.8-1.1-8.7-2.6-11.8-4.4l0.9,18.3c0.2,1.4,0.9,2,2.3,1.8L301.1,645L301.1,645z"/>
      <path fill="none" d="M287.8,651.5l11.3-4.1c1.3-0.4,2-1.1,2-2.3l-7.7,3.3c-3.2,1.3-5.1,0.8-5.6-1.4l-0.1-1.8
        c-1.7-30.1-2.5-61.1-2.4-92.9v-0.3l-4.5-1c0.1,31.8,0.7,63.5,2,95.1C283.2,650.8,284.8,652.6,287.8,651.5L287.8,651.5z"/>
      <path fill="none" d="M287.8,646.8c0.5,2.3,2.4,2.7,5.6,1.4l7.7-3.3l-8.8,1.8l-0.6,0.1c-2.2,0.2-3.6-0.4-4-2L287.8,646.8
        L287.8,646.8z"/>
      <path fill="none" d="M291.8,647l0.6-0.1c-1.4,0.2-2.1-0.4-2.3-1.8l-0.9-18.3l-0.7-0.4V624l0.6,0.3c-0.8-19.9-1.3-40.1-1.4-60.6
        l-0.4-0.3v-11.1l-2-0.4v0.3c0,31.8,0.8,62.8,2.4,92.9C288.2,646.5,289.5,647.2,291.8,647L291.8,647z"/>
      <path fill="none" d="M278.6,626c-0.8,1.4-1.4,3.1-2,5l0.3,11.7l0.6-9c0.2-1.4,0.6-2.4,1.2-3L278.6,626L278.6,626z"/>
      <path fill="none" d="M280.4,630.1c-0.7-0.1-1.2,0.1-1.7,0.6c-0.6,0.6-1,1.6-1.2,3l-0.6,9l0.3,10l0.1,1l3.8-0.1l-0.1-3.2
        c-0.9,0.1-1.4-0.8-1.4-2.9l-0.2-6.8c0-2.3,0.4-3.7,1.4-4.1L280.4,630.1L280.4,630.1z"/>
      <path fill="none" d="M280.9,650.4c0.6-1,0.8-3.4,0.7-7.4c-0.1-3-0.5-5.2-1-6.4c-0.9,0.4-1.4,1.8-1.4,4.1l0.2,6.8
        C279.6,649.5,280.1,650.5,280.9,650.4L280.9,650.4z"/>
      <path fill="none" d="M276.9,642.6l-0.3-11.7c-2,6.1-3.2,14.3-3.5,24.7c-0.2,5.9-0.3,11.9-0.3,18l5.3-0.4l-0.8-18.9v-0.2l0-0.5l0-1
        L276.9,642.6L276.9,642.6z"/>
      <path fill="none" d="M289.1,624.3c3.2,1.9,7.1,3.5,11.9,4.9l-0.7-60.4c-4.8-1.1-9.1-2.8-12.6-5.1
        C287.8,584.2,288.3,604.4,289.1,624.3L289.1,624.3z"/>
      <path fill="none" d="M305,669c2.5-0.5,4.9-0.6,7.3-0.4l-1.5-96.8l1.5,96.8c1,0.1,1.9,0.2,2.9,0.4l-5.2-162.8
        c0-8.5-0.4-15.6-1.2-21.4c-3.4,1.4-5.6,2.8-6.5,4.2c1.1,4.1,1.5,9.2,1.5,15.3c-0.8,43.6-0.7,87.3,0.3,130.9L305,669L305,669z"/>
      <path fill="none" d="M312.3,668.6c-2.3-0.2-4.8-0.1-7.3,0.4l2.8,85.9c0.8-0.3,1.7-0.6,2.4-0.8c1.3-0.4,2.6-0.5,3.8-0.6
        L312.3,668.6L312.3,668.6z"/>
      <path fill="none" d="M305,669l-0.5,0.1l-14.2,6.5c-2.9,0.7-4.3,2.7-4.4,6.1l0.1,1.4c0.6,23.2,1.8,46.1,3.5,68.8l0.4,5.9
        c1,4.7,2.1,6.7,3.4,5.7c5.5-4.3,10.3-7.2,14.5-8.7L305,669L305,669z M288,688.1c0.1-2.6,1-4.3,2.7-5.2l9.3-4.1
        c2.1-0.9,3.2,0.7,3.4,4.8l2.2,60.8c0.2,2.6-0.7,4.6-2.5,5.9l-5.9,3.8c-2.1,0.8-4.1,0.1-5.8-2.2C289.7,730.8,288.6,709.6,288,688.1
        L288,688.1z"/>
      <path fill="none" d="M290.7,682.9c-1.8,0.9-2.7,2.6-2.7,5.2c0.7,21.5,1.8,42.7,3.4,63.8c1.8,2.3,3.7,3,5.8,2.2l5.9-3.8
        c1.8-1.3,2.7-3.3,2.5-5.9l-2.2-60.8c-0.2-4.1-1.3-5.7-3.4-4.8L290.7,682.9L290.7,682.9z"/>
      <path fill="none" d="M304.5,669.1l0.5-0.1l-1-33.6c-1-43.6-1.1-87.3-0.3-130.9c0.1-6.1-0.4-11.2-1.5-15.3l-0.3,0.6l-0.9,7.3
        c0.9,3,1.4,8,1.5,15l-0.4,52l-0.1-2.2l1,87.2l-0.1,9.3c-0.5,3.2-1.7,5.2-3.6,6.1l-18.9,8.5c1.1,22.9,2.6,45.6,4.5,67.9l2.5,27
        c1.2-1.9,2.9,0.2,5.1,6.3l12.6-9.5c2.4-1.8,3.4-5.1,3-9.8c-4.1,1.5-9,4.5-14.5,8.7c-1.3,0.9-2.4-1-3.4-5.7l-0.4-5.9
        c-1.7-22.7-2.9-45.7-3.5-68.8l-0.1-1.4c0-3.4,1.5-5.4,4.4-6.1L304.5,669.1L304.5,669.1z"/>
      <path fill="none" d="M315.1,669.1c-1-0.2-1.9-0.4-2.9-0.4l1.8,84.9c1.4-0.1,2.6,0.1,3.8,0.5L315.1,669.1L315.1,669.1z"/>
      <path fill="none" d="M279.7,660.4c0.4,0.1,1,0.1,1.6,0l-0.2-6.7l-3.8,0.1l0,0.5v0.2l0,0.1C277.4,657.9,278.2,659.9,279.7,660.4
        L279.7,660.4z"/>
      <path fill="none" d="M277.3,654.5L277.3,654.5l0.8,18.8l2.1-0.2l-0.5-12.7C278.2,659.9,277.4,657.9,277.3,654.5L277.3,654.5z"/>
      <polygon fill="none" points="277.3,654.1 277.3,653.6 277.3,653.6 277.3,654.1 		"/>
      <polygon fill="none" points="277.3,653.6 277.3,653.6 277.2,652.6 277.3,653.6 		"/>
      <path fill="none" d="M278.1,673.2l-5.3,0.4c0.1,13.1,0.7,26.5,1.9,40.2c1.1,11.7,3.7,20,7.6,24.8
        C280.5,717.1,279.1,695.3,278.1,673.2L278.1,673.2z"/>
      <path fill="none" d="M280.2,673.1l-2.1,0.2c1,22.1,2.4,43.9,4.2,65.4c0.7,0.9,1.5,1.7,2.4,2.3C282.8,718.6,281.3,696,280.2,673.1
        L280.2,673.1z"/>
      <path fill="none" d="M312.2,483.6l-3.5,1.3c0.8,5.8,1.2,12.9,1.2,21.4l5.2,162.8l2.7,85l0.3,8.2l4,0.2l-0.1-1.9l-0.4-7.6
        l-4.8-150.5l-3.1-96.1C313.7,497.8,313.2,490.2,312.2,483.6L312.2,483.6z"/>
      <path fill="none" d="M301.8,489.7l0.3-0.6l-14-70.6c-0.7,1.7-1.2,3.5-1.6,5.6l14.3,73L301.8,489.7L301.8,489.7z"/>
      <path fill="none" d="M302.1,489.1c0.9-1.4,3.1-2.8,6.5-4.2c-6.4-25.3-11.2-48.8-14.5-70.5l-0.5-3.5c-1.7,1.2-3.1,2.9-4.3,5
        c-0.4,0.8-0.8,1.7-1.2,2.6L302.1,489.1L302.1,489.1z"/>
      <path fill="none" d="M277.6,511.8c-0.8,0.5-1.3,1.5-1.7,3.2c-0.9,5.6-1.2,12.3-0.9,20l3.8,0.1v-3.2c-0.8,0.1-1.2-0.6-1.3-2.2
        l0-8.5c0.1-1.8,0.6-2.8,1.5-3.1v-6.5C278.5,511.4,278,511.5,277.6,511.8L277.6,511.8z"/>
      <path fill="none" d="M277.6,511.8c0.4-0.3,0.9-0.4,1.4-0.3v6.5c0.5,1.3,0.8,3.5,0.8,6.5c0,4-0.4,6.4-1,7.4v3.2v6.7
        c-0.5,0.1-0.9,0.1-1.3,0v6.9l3.3,2.1l0-3.8c-0.2-23.3,0.1-51.6,0.8-84.9l-3.2,0.9C278,479.1,277.7,495.4,277.6,511.8L277.6,511.8z
        "/>
      <path fill="none" d="M279.8,524.4c0-3-0.3-5.2-0.8-6.5c-0.8,0.3-1.3,1.4-1.5,3.1l0,8.5c0.1,1.6,0.5,2.3,1.3,2.2
        C279.4,530.8,279.8,528.3,279.8,524.4L279.8,524.4z"/>
      <path fill="none" d="M302.4,512.1c-0.1-7-0.6-12-1.5-15l-0.1,3.6c-0.5,17-0.7,33.9-0.6,50.7l0,9l1.6,0.3l0,1.3l0.1,2.2
        L302.4,512.1L302.4,512.1z"/>
      <polygon fill="none" points="300.8,500.7 300.9,497.1 286.6,424.1 286.2,426.9 288.3,437.4 293.8,465.1 300.8,500.7 		"/>
      <polygon fill="none" points="285.3,551.8 287.3,552.2 287.3,541.1 287.7,541.6 288.2,459.2 286,459.6 285.3,551.8 		"/>
      <path fill="none" d="M300.2,560.4l0-9c-4.7-2.3-8.8-5.6-12.5-9.7l-0.5-0.5v11.1l0.4,0.4v-0.6v0.6
        C290.9,555.9,295.1,558.5,300.2,560.4L300.2,560.4z"/>
      <path fill="none" d="M300.2,560.4c-5.2-1.9-9.3-4.4-12.6-7.7l-0.4-0.4v11.1l0.4,0.3c3.6,2.3,7.8,4,12.6,5.1L300.2,560.4
        L300.2,560.4z"/>
      <path fill="none" d="M275.1,535.7c0,3.7,0.8,5.7,2.4,6.1c0.4,0.1,0.8,0.1,1.3,0V535l-3.8-0.1L275.1,535.7L275.1,535.7z"/>
      <path fill="none" d="M280.8,547l0,3.8l4.5,1l0.7-92.2l-0.7,0.1l0,1.4l-3.7,1C280.9,495.4,280.6,523.8,280.8,547L280.8,547z"/>
      <path fill="none" d="M287.7,541.6c3.6,4.1,7.8,7.4,12.5,9.7c0-16.8,0.2-33.7,0.6-50.7l-7.1-35.5c-0.6-1.9-1.5-3.3-2.7-4.2
        l-2.7-1.8l-0.1,0L287.7,541.6L287.7,541.6z"/>
      <path fill="none" d="M311.7,400.6c3.2-1.6,6.4-3,9.4-4.2l4.2-88.6c-0.9-5.5-2.2-1.3-4,12.6L311.7,400.6L311.7,400.6z"/>
      <path fill="none" d="M280.2,406.8L280.2,406.8c-1.3-15.7-1.2-31.3,0.2-47.1c2.9-38.1,6.5-61.4,11-69.9l0.5-1.7l-6.3,4.1
        c-2.9,2.3-5.3,8.1-7,17.4c-2.3,14.8-3.6,33.6-3.8,56.3c0.2,21.5,2,35.6,5.3,42.2L280.2,406.8L280.2,406.8z"/>
      <path fill="none" d="M280.2,406.8l1.8-27.7c1.4-25.3,3.4-47.9,6.3-68l1.3-8.4c-0.2-0.3-0.4-0.7-0.4-1.1l0.7-5.2l0.2-1.1
        c0.4-1.9,0.8-3.8,1.3-5.5c-4.5,8.5-8.1,31.8-11,69.9C279,375.5,278.9,391.2,280.2,406.8L280.2,406.8z"/>
      <polygon fill="none" points="288.3,437.4 286.2,426.9 286.1,445.6 288.3,445 288.3,437.4 		"/>
      <path fill="none" d="M286.6,424.1c0.4-2.1,0.9-4,1.6-5.6c0.4-1,0.8-1.8,1.2-2.6l6.3-102.2c1.2-7.9,4.3-13.5,9.2-17
        c-4.8,2.9-8.4,6-10.9,9.4c-2.4,3.2-4.1,9.5-5.2,19.1l0,0.1c-3.1,31.5-5.2,65-6.3,100.5l3.8,1.2L286.6,424.1L286.6,424.1z"/>
      <path fill="none" d="M282.4,425.7l-2.9-3c-0.3,8.1-0.6,16.3-0.7,24.4c1-0.3,2.1-0.6,3.2-0.9L282.4,425.7L282.4,425.7z"/>
      <path fill="none" d="M286.2,426.9l-3.8-1.2l-0.5,20.5l0,0c0.5-0.2,1-0.2,1.4,0.1l2.7-0.7L286.2,426.9L286.2,426.9z"/>
      <path fill="none" d="M285.3,459.7l0.7-0.1l2.3-0.4l0.1,0c0.5-0.1,0.7-0.8,0.5-2.2v-12.1l-0.6,0.2l-2.2,0.6l-2.7,0.7
        c1.5,1,2.1,5.2,1.9,12.5L285.3,459.7L285.3,459.7z"/>
      <polygon fill="none" points="285.2,461.1 285.3,459.7 285.3,458.8 255.8,466.8 256.5,468.8 278.4,462.9 281.6,462.1 285.2,461.1 
            "/>
      <path fill="none" d="M288.3,459.1l2.7,1.8c1.2,0.9,2.1,2.3,2.7,4.2l-5.5-27.7v7.6l0.6-0.2V457C289,458.3,288.8,459,288.3,459.1
        L288.3,459.1z"/>
      <path fill="none" d="M281.9,446.2c-1.1,0.3-2.1,0.6-3.2,0.9c-5.9,1.7-11.1,3.7-15.5,5.8c-4.5,2.6-7,7.3-7.5,13.9l29.5-8
        c0.2-7.3-0.5-11.5-1.9-12.5C282.9,446.1,282.5,446,281.9,446.2L281.9,446.2L281.9,446.2z"/>
      <path fill="none" d="M341.5,273.4v-0.6l-6.1,0.9l-0.1,1.1l2.9-0.4h0.1C339.3,274.2,340.4,273.8,341.5,273.4L341.5,273.4z"/>
      <path fill="none" d="M335.1,277.4l0.1-2.2l-9.4,1.5l-0.3,3c2.4-0.7,4.8-1.3,7.3-1.9L335.1,277.4L335.1,277.4z"/>
      <polygon fill="none" points="325.9,276.7 335.3,275.2 335.3,274.7 335.4,273.7 335.4,273.1 332.6,273.5 332.6,273.6 329.2,275.1 
        325.9,276.7 		"/>
      <path fill="none" d="M325.9,276.7l3.3-1.6c-5.8,1.8-11.2,3.9-16,6.2l-0.2,3.2c3.9-1.9,8.1-3.5,12.7-4.9L325.9,276.7L325.9,276.7z"
        />
      <path fill="none" d="M319.6,267.3l1.8-0.7l-1.8,0c-5.3,2.1-10,4.4-13.9,6.8l-0.5,1l0.5-0.3C309.7,271.8,314.3,269.5,319.6,267.3
        L319.6,267.3z"/>
      <path fill="none" d="M313,284.5l0.2-3.2c-8.8,4.2-16.1,9.3-21.8,15.3c0,1.7,0.6,2.5,1.8,2.6l0.1-0.2
        C298.7,293.1,305.2,288.3,313,284.5L313,284.5z"/>
      <path fill="none" d="M332.6,273.6c-8.3-0.2-17.5,2.8-27.5,8.9c-6.2,4.3-11.1,8.6-14.9,12.9l-0.2,1.1l1.4-1.3
        C303.8,283.7,317.6,276.5,332.6,273.6L332.6,273.6z"/>
      <path fill="none" d="M329.2,275.1l3.3-1.6c-15,2.9-28.7,10.1-41.2,21.6l-0.2,1l0.3,0.4c5.7-6,13-11.1,21.8-15.3
        C318,279,323.4,276.9,329.2,275.1L329.2,275.1z"/>
      <path fill="none" d="M291.1,296.1l0.2-1l-1.4,1.3l-0.7,5.2c0,0.4,0.1,0.8,0.4,1.1l1.3-5.6L291.1,296.1L291.1,296.1z"/>
      <path fill="none" d="M291.4,296.6l-0.5,0.6l-1.3,5.6l-1.3,8.4c1.5-5.9,3.1-9.9,4.9-12C292,299.1,291.4,298.2,291.4,296.6
        L291.4,296.6z"/>
      <polygon fill="none" points="290.9,297.1 291.4,296.6 291.1,296.1 290.9,297.1 		"/>
      <path fill="none" d="M398,777.4c0.1-1.4-1.3-2.2-4.2-2.3h-20.6c-2.9,0.2-4.2,0.9-4.1,2.3l14,0.3L398,777.4L398,777.4z"/>
      <polygon fill="none" points="358.5,797.4 360,795 362.4,791.2 361.7,790.9 358.3,790 355.2,796 358.5,797.4 		"/>
      <polygon fill="none" points="361.7,790.9 362.4,791.2 362.4,791.1 361.7,790.9 		"/>
      <path fill="none" d="M355.2,796l3.1-5.9l-17.9-0.7c-14.7-0.3-24.5-0.6-29.2-0.9l2.5,5.6C327.7,795.4,341.5,796.1,355.2,796
        L355.2,796z"/>
      <path fill="none" d="M358.5,797.4l-3.2-1.4c-13.7,0.1-27.5-0.5-41.5-2v1.6C327.8,796.8,342.7,797.4,358.5,797.4L358.5,797.4z"/>
      <path fill="none" d="M310.3,754.1c-0.8,0.2-1.6,0.5-2.4,0.8c0.4,4.7-0.5,8-3,9.8l-12.6,9.5c6.3,8.4,12,12.4,17.3,12l6.7-11.9
        c1.7-3.3,2.4-6.7,2-10.1l-0.1-2.1l-0.1,0l0.1,0l-0.3-8.2c-1.1-0.4-2.4-0.6-3.8-0.5C312.9,753.5,311.6,753.7,310.3,754.1
        L310.3,754.1z"/>
      <path fill="none" d="M318.1,762.2l0.1,2.1c0.4,3.4-0.3,6.7-2,10.1l38.8,2.3v-12.7c-11.1-0.3-22.1-0.8-32.9-1.5L318.1,762.2
        L318.1,762.2z"/>
      <path fill="none" d="M287.3,769l0.1,0.4c1.4,5.3,2.6,8.3,3.6,9.2c6,7,11.9,11,17.9,12.1l0.2-2.5c0.1-0.7,0.3-1.4,0.5-2
        c-5.2,0.3-11-3.7-17.3-12c-2.2-6.1-3.9-8.2-5.1-6.3L287.3,769L287.3,769z"/>
      <polygon fill="none" points="310.2,788.4 309.1,788.3 308.9,790.7 311.4,790.9 310.2,788.4 		"/>
      <polygon fill="none" points="312.4,792.9 313.7,795.6 313.7,794 311.2,788.4 310.2,788.4 311.4,790.9 312.4,792.9 		"/>
      <polygon fill="none" points="312.4,792.9 311.4,790.9 308.9,790.7 309,792.7 309,792.7 312.4,792.9 		"/>
      <path fill="none" d="M309.2,794l-0.2-1.2l-0.1,0c-1.8,0.1-3.2-0.1-4.1-0.5c-4.1-1.4-9.1-5.8-15-13.2c2.7,5.3,7.4,9.8,13.8,13.5
        C304.9,793.4,306.7,793.8,309.2,794L309.2,794z"/>
      <polygon fill="none" points="309,792.7 308.9,792.7 309,792.7 309,792.7 		"/>
      <path fill="none" d="M308.9,792.7l0.1,0l-0.1-2c-6-1.1-11.9-5.2-17.9-12.1c-1-0.9-2.2-4-3.6-9.2c0.5,3.1,1.2,6,2.1,8.9l0.4,0.7
        c5.9,7.4,10.9,11.8,15,13.2C305.7,792.6,307,792.8,308.9,792.7L308.9,792.7z"/>
      <polygon fill="none" points="289.4,778.3 289.6,778.8 289.8,779 289.4,778.3 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M413.5,271.5c-2.7-0.2-5.5-0.4-8.3-0.5v0.6c1.1,0.4,2.2,0.6,3.2,0.7
        l1.8,0.1l0.1,0c1.1,0,2.2-0.1,3.2-0.3V271.5L413.5,271.5z M419.1,273.9c-1-0.4-2-0.7-3-0.9l-2.4-0.3c-1.1,0-2.3,0.1-3.5,0.3v0.8
        c1.2,0.4,2.4,0.7,3.5,0.9c0.7,0.1,1.3,0.2,1.9,0.2h0.1c1.1,0.1,2.3,0,3.5-0.2V273.9L419.1,273.9z M423.8,273.8
        c-1.1-0.1-2.3,0-3.5,0.2v0.8c1.2,0.5,2.4,0.9,3.5,1.1c0.7,0.1,1.3,0.2,1.9,0.2l0.1,0c1.1,0.1,2.3,0.1,3.5-0.1v-0.9
        c-1.2-0.5-2.3-0.9-3.5-1.1l-0.2-0.1L423.8,273.8L423.8,273.8z M423.8,272.5c-2.7-0.3-5.5-0.6-8.3-0.9v0.6c1.1,0.4,2.2,0.7,3.2,0.9
        l1.8,0.2h0c1.1,0.1,2.2,0,3.2-0.1V272.5L423.8,272.5z M431.6,273.6l-5.9-0.9v0.7c1.1,0.5,2.2,0.8,3.2,1l1,0.2l0.7,0.1h0.1l1,0.1
         M289.6,778.8l0.2,0.2c5.9,7.4,10.9,11.8,15,13.2c0.9,0.4,2.2,0.6,4.1,0.5l0.1,0 M463.6,449.3l-6.6,29.8c-0.7,2-2.7,2.3-5.8,0.9
        c-14.3-5.2-30.9-9.2-49.8-11.9c-1.7-0.3-2.7-1.7-3.1-4.3c-1.8-7.5-5.1-11.3-10-11.6c-1.5-0.1-3.1-0.1-4.7-0.1c-1.8,0-3.4,0-5,0.1
        c-4.9,0.3-8.2,4.1-10,11.6c-0.3,2.6-1.3,4-3.1,4.3c-18.9,2.7-35.5,6.6-49.8,11.9c-3.1,1.4-5,1.1-5.8-0.9l-6.7-29.8l-2-8.1
        c-0.7-3.3-0.4-6,0.9-8.1c4.8-7.1,9.7-11.5,14.8-13.1c20.3-7,42.4-10.6,66.4-10.9c24,0.3,46.1,4,66.4,10.9
        c5.1,1.7,10,6.1,14.7,13.1c1.2,2.1,1.5,4.8,0.9,8.1L463.6,449.3L463.6,449.3z M294.2,414.3c20.3-7.9,44-13.1,71.1-15.6l0.1,0
        c2.8-0.3,5.7-0.6,8.7-0.7c3.1-0.2,6.3-0.3,9.5-0.3c3.2,0,6.3,0.1,9.3,0.3c2.9,0.2,5.8,0.4,8.7,0.7l0.1,0
        c27.1,2.5,50.8,7.7,71.1,15.6 M408.7,272.9c-1.2-0.4-2.3-0.7-3.5-0.8l-2-0.1c-1.1,0-2.3,0.1-3.5,0.4v0.7c1.2,0.4,2.4,0.7,3.5,0.8
        c0.7,0.1,1.3,0.1,1.9,0.1h0.1c1.1,0,2.3-0.1,3.5-0.3V272.9L408.7,272.9z M403.3,270.9c-2.7-0.1-5.5-0.2-8.3-0.2v0.5
        c1.1,0.3,2.2,0.5,3.2,0.6l1.8,0.1c1.1,0,2.1-0.1,3.2-0.4V270.9L403.3,270.9z M398.6,272.4c-1-0.3-2-0.5-3-0.6l-2.5-0.1
        c-1.1,0.1-2.3,0.2-3.5,0.5v0.7c1.2,0.4,2.4,0.6,3.5,0.6c0.7,0.1,1.3,0.1,1.9,0.1h0.1c1.1,0,2.3-0.1,3.5-0.4V272.4L398.6,272.4z
         M393,270.6l-8.3-0.1v0.5c1.1,0.3,2.2,0.4,3.2,0.5l1.8,0c1.1-0.1,2.2-0.2,3.2-0.4V270.6L393,270.6z M384.6,273.4
        c1.1-0.1,2.3-0.2,3.5-0.5v-0.7c-1.2-0.3-2.3-0.5-3.5-0.6h-2c-1.1,0.1-2.3,0.2-3.5,0.5v0.7c1.2,0.3,2.4,0.5,3.5,0.5
        C383.2,273.4,383.9,273.4,384.6,273.4L384.6,273.4L384.6,273.4z M382.6,270.5l-8.3,0.1v0.5c1.1,0.2,2.2,0.4,3.2,0.4l1.8,0
        c1.1-0.1,2.2-0.2,3.2-0.5V270.5L382.6,270.5z M377.9,272.2c-1-0.2-2-0.4-3-0.5h-2.4c-1.1,0.1-2.3,0.3-3.5,0.6v0.7
        c1.2,0.3,2.4,0.4,3.5,0.4c0.7,0,1.3,0,1.9,0h0.1c1.1-0.1,2.3-0.3,3.5-0.6V272.2L377.9,272.2z M372.3,270.6l-8.3,0.3v0.5
        c1.1,0.2,2.2,0.3,3.2,0.3l1.8-0.1c1.1-0.1,2.2-0.3,3.2-0.5V270.6L372.3,270.6z M367.5,272.4c-1.2-0.2-2.3-0.4-3.5-0.4l-2,0.1
        c-1.1,0.1-2.3,0.4-3.5,0.7v0.7c1.2,0.2,2.4,0.4,3.5,0.3c0.7,0,1.3,0,1.9-0.1h0.1c1.1-0.1,2.3-0.3,3.5-0.7V272.4L367.5,272.4z
         M358.8,272.2c1.1-0.1,2.2-0.4,3.2-0.7V271c-2.7,0.1-5.5,0.3-8.3,0.5v0.6c1.1,0.2,2.2,0.2,3.2,0.2L358.8,272.2L358.8,272.2z
         M357.4,272.9c-1-0.2-2-0.3-3-0.3l-2.4,0.2c-1.1,0.2-2.3,0.5-3.5,0.9v0.8c1.2,0.2,2.4,0.2,3.5,0.2l1.9-0.2h0.1
        c1.1-0.2,2.3-0.4,3.5-0.8V272.9L357.4,272.9z M351.8,271.7l-8.3,0.8v0.6c1.1,0.1,2.2,0.2,3.2,0.1l1.8-0.2l0.1,0
        c1.1-0.2,2.1-0.4,3.2-0.8V271.7L351.8,271.7z M347,273.9c-1-0.1-2-0.2-3-0.2l-2.4,0.3c-1.1,0.2-2.3,0.6-3.5,1.2v0.8
        c1.2,0.1,2.4,0.1,3.5,0c0.7,0,1.3-0.1,1.9-0.3h0.1c1.1-0.2,2.3-0.6,3.5-1V273.9L347,273.9z M335.3,274.7l2.9-0.4h0.1
        c1.1-0.2,2.2-0.6,3.2-1v-0.6l-6.1,0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M477.4,778.8l-0.2,0.2c-5.9,7.4-10.9,11.8-15,13.2
        c-0.9,0.4-2.2,0.6-4.1,0.5l-0.1,0 M287.3,769.4c1.4,5.3,2.6,8.3,3.6,9.2c6,7,11.9,11,17.9,12.1 M479.7,769.4
        c-1.4,5.3-2.6,8.3-3.6,9.2c-6,7-11.9,11-17.9,12.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M412.2,776.6v-12.7 M474.7,774.2c2.2-6.1,3.9-8.2,5.1-6.3 M484.7,738.6
        c4-4.8,6.5-13.1,7.6-24.8c1.2-13.8,1.9-27.2,1.9-40.2 M450.8,774.4l-38.6,2.2 M442.6,779.4c-0.5-2-1.8-2.9-4-2.9l-28.1,1.6
        c-3.9,0.3-8.1,0.5-12.5,0.7l-14.3,0.3l-14.6-0.3c-4.5-0.2-8.7-0.4-12.6-0.7l-28.1-1.6c-2.1-0.1-3.4,0.9-4,2.9l-2.5,5.8
        c-1,1.5-0.4,2.3,1.8,2.3c15.4,1,29.6,1.3,42.6,0.9c2.6,0.1,5.7-0.4,9.4-1.4c2-0.5,4.6-0.8,7.8-0.9c3.1,0.2,5.6,0.5,7.7,0.9
        c3.7,1,6.8,1.5,9.4,1.4c13,0.4,27.2,0.1,42.6-0.9c2.1,0,2.7-0.8,1.8-2.3L442.6,779.4L442.6,779.4z M458.2,790.7l-0.2-2.5
         M457.5,786.3c5.2,0.3,11-3.7,17.3-12 M458,792.7l0.1,0 M458,792.7L458,792.7l0.1-2 M458,792.7l-0.2,1.2 M457.5,786.3l-6.7-11.9
         M458,788.3c-0.1-0.7-0.3-1.4-0.5-2 M457.9,794c2.4-0.1,4.3-0.6,5.6-1.4c6.5-3.8,11.1-8.3,13.8-13.5l0.4-0.7 M289.4,778.3l0.2,0.6
        c1.5,5,3.5,9.6,5.9,13.9c4.8,8.7,10.6,13.3,17.4,13.6l11.7,0.7c11.1,0.6,22.6,1,34.7,1.2l39.9,0.1c15.1-0.2,29.5-0.7,43.2-1.4
        l11.7-0.7c6.8-0.4,12.7-4.9,17.4-13.6c2.5-4.3,4.4-8.9,5.9-13.9l0.2-0.6 M309.2,794l0.9,7.9c0.6,1.7,2.3,2.9,5.2,3.6l0.1,0
        l9.2,0.5c11.1,0.6,22.6,1,34.7,1.2l39.9,0.1c15.1-0.2,29.5-0.7,43.2-1.4l9.2-0.5l0.1,0c2.9-0.7,4.6-1.9,5.2-3.6l0.9-7.9
         M448.9,762.2l-4,0.2 M479.9,767.9l2.5-27 M412.2,763.9c11.1-0.3,22-0.8,32.8-1.5 M445,760.6c-10.9,0.7-21.8,1.3-32.8,1.5
        c-7.1,0.2-14.2,0.3-21.3,0.3h-14.6c-7.1,0-14.2-0.1-21.2-0.3c-11.1-0.3-22.1-0.8-33-1.6 M323,753c6.1,0.5,12.3,0.9,18.7,1.2
        c9.9,0.5,20.3,0.8,31.1,0.9l21.4,0c10.8-0.1,21.2-0.4,31.1-0.9c6.4-0.3,12.6-0.7,18.7-1.2 M445,760.6l0.4-7.7 M444,753l1.5-0.1
         M444.9,762.4l0.1-1.8 M424.6,735.6h-82.1c-5.7-0.5-8.9-4.2-9.4-11.1c-0.9-11.5-1.2-23.7-0.9-36.5c0.5-7.2,3.6-10.6,9.5-10.4h83.6
        c5.9-0.2,9.1,3.2,9.5,10.4c0.4,12.8,0.1,25-0.9,36.5C433.4,731.4,430.3,735.1,424.6,735.6L424.6,735.6z M482.4,741
        c0.8-0.7,1.6-1.5,2.3-2.3 M448.9,762.2l0.3-8.2 M474.7,774.2l-12.6-9.5c-2.4-1.8-3.4-5.1-3-9.8 M450.8,774.4
        c-1.7-3.3-2.4-6.7-2-10.1l0.1-2.1 M449,762.2L449,762.2 M449.2,754c1.2-0.4,2.4-0.6,3.8-0.5 M453,753.5c1.2,0,2.4,0.2,3.8,0.6
        c0.8,0.2,1.6,0.5,2.4,0.8 M459.2,754.9c4.2,1.5,9,4.5,14.5,8.7c1.3,0.9,2.4-1,3.4-5.7l0.4-5.9c1.7-22.7,2.9-45.7,3.5-68.8l0.1-1.4
        c0-3.4-1.5-5.4-4.4-6.1l-14.2-6.5L462,669 M475.7,751.9c1.6-21.1,2.7-42.4,3.4-63.8c-0.1-2.6-1-4.3-2.7-5.2l-9.4-4.1
        c-2.1-0.9-3.2,0.7-3.4,4.8l-2.1,60.8c-0.2,2.6,0.7,4.6,2.5,5.9l5.9,3.8C472,754.9,473.9,754.2,475.7,751.9L475.7,751.9z
         M477.6,778.3c0.8-2.9,1.5-5.8,2.1-8.9l0.1-0.4l0.1-1.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M475.6,289.9l-0.5-1.7 M461.8,274.5l-0.5-1c-3.9-2.4-8.5-4.7-13.9-6.8
        l-1.8,0 M445.6,266.7l1.8,0.7c5.3,2.1,10,4.4,13.9,6.8l0.5,0.3 M450.4,271c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4
        c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1-0.1-0.3-0.2-0.4
        C450.6,271.1,450.5,271,450.4,271L450.4,271z M450,270.2c1.1,0.1,2.1,0.6,3.1,1.2c0.2,0.1,0.3,0.2,0.2,0.5l-0.6,1.5
        c-0.1,0.2-0.2,0.3-0.4,0.2c-1.2-0.2-2.3-0.6-3.1-1.2c-0.2-0.1-0.3-0.3-0.2-0.5l0.6-1.5C449.7,270.2,449.8,270.1,450,270.2
        L450,270.2z M452.4,271.8c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
        c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.4C452.5,272,452.5,271.9,452.4,271.8L452.4,271.8z
         M453.9,281.3l0.2,3.2 M475.1,288.2c-2.4-5-6.8-9.6-13.3-13.7 M441.1,276.7l-3.3-1.6 M441.4,279.7l-0.3-3 M441.1,276.7l-9.4-1.5
         M431.9,277.4l-0.2-2.2 M441.4,279.7c-2.4-0.7-4.8-1.3-7.3-1.9l-2.2-0.4 M431.8,275.2l0-0.5l-0.1-1.1l0-0.6 M434.5,273.6
        L434.5,273.6l-2.9-0.5 M437.8,275.1l-3.3-1.6 M437.8,275.1c5.8,1.8,11.2,3.9,16.1,6.2 M454.1,284.5c-3.9-1.9-8.1-3.5-12.7-4.9
         M476.9,295.4c-3.8-4.3-8.8-8.6-15-12.9c-10-6.1-19.2-9.1-27.5-8.9 M462.9,297c4.4,2.8,7.8,5.7,10.2,8.9c2.4,3.2,4.1,9.5,5.2,19.1
        l0,0.1c3.1,31.5,5.2,65,6.3,100.5 M477.4,302.8c0.2-0.3,0.4-0.7,0.4-1.1l-0.7-5.2 M477.1,296.4l-0.2-1.1 M476.2,297.1l-0.5-0.6
         M475.6,296.6l0.3-0.4 M475.9,296.1l-0.2-1 M476.2,297.1l-0.2-1 M473.8,299.2l-0.2-0.2c-5.3-5.9-11.8-10.7-19.6-14.5 M477.4,302.8
        l-1.3-5.6 M475.7,295.1l1.4,1.3 M475.6,296.6c0,1.7-0.6,2.5-1.8,2.6 M304.9,296.6c5-3,11.1-5.7,18.5-8.2c14.6-4.5,34.7-7,60.1-7.6
        c25.4,0.6,45.5,3.2,60.1,7.6c7.4,2.5,13.6,5.2,18.6,8.2l0.7,0.4 M477.7,415.9l-6.2-102.3c-1.2-7.5-4-13-8.6-16.5 M478.7,311.2
        l-1.3-8.4 M478.7,311.2c-1.5-5.9-3.1-9.9-4.9-12 M455.3,400.6l-9.7-80.2c-1.8-13.9-3.1-18.1-4-12.6l4.2,88.6 M486.9,408.3
        c3.3-6.7,5.1-20.7,5.3-42.2c-0.2-22.7-1.5-41.4-3.8-56.3c-1.7-9.3-4.1-15.2-7-17.4l-6.3-4.1 M453.9,281.3
        c8.8,4.2,16,9.3,21.8,15.3 M475.6,289.9c0.5,1.7,0.9,3.6,1.3,5.5 M434.5,273.6c15,2.9,28.7,10.1,41.2,21.6 M488.7,462.9l21.9,5.8
        l0.6-1.9 M511.2,466.8c-0.5-6.7-3-11.3-7.5-13.9c-4.4-2.2-9.5-4.1-15.5-5.8 M488.7,462.9l-3.2-0.9 M488.3,447.1l-0.7-24.4
         M486.9,408.3l-0.1-1.4v0 M486.8,406.8c1.3-15.6,1.2-31.3-0.2-47.1c-2.9-38.1-6.5-61.4-11-69.9 M473.4,410.8l-0.6-0.4
        c-6-3.8-11.9-7.1-17.5-9.9 M415,359c0.2,0.2,0.3,0.4,0.3,0.7v1.8c0,0.1-0.1,0.2-0.3,0.3l-0.7,0.1l-0.7-0.1
        c-0.2-0.1-0.3-0.2-0.3-0.3v-1.8c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3C414.5,358.7,414.7,358.8,415,359L415,359z
         M445.9,396.4c-1.1-0.4-2.1-0.8-3.1-1.2c-14.7-4.1-28.7-6.8-41.8-8.1l-16.2-0.9c-5,0-9.9,0.1-14.9,0.6l-1.8,0.2
        c-13.7,1.2-28.2,4-43.7,8.2l-3.1,1.2 M445.9,396.4c3.1,1.2,6.2,2.6,9.4,4.2 M485.5,462.1l-3.6-1l-0.1-1.4 M478.7,459.1
        L478.7,459.1 M473.3,465.1c0.6-1.9,1.5-3.3,2.7-4.2l2.7-1.8 M478.7,459.1c-0.5-0.1-0.7-0.8-0.5-2.2v-12.1l0.6,0.2 M481.1,459.6
        l-2.3-0.4 M481.8,459.7l-0.7-0.1 M481.7,458.8l0,0.9 M483.7,446.3l-2.7-0.7 M485.1,446.2L485.1,446.2c-0.6-0.2-1-0.2-1.4,0.1
         M478.8,445l2.2,0.6 M483.7,446.3c-1.5,1-2.1,5.2-1.9,12.5 M478.8,437.4l2.1-10.4 M478.9,418.4c-0.4-0.9-0.8-1.8-1.2-2.6
         M480.4,424.1c-0.4-2.1-0.9-4-1.5-5.6 M484.6,425.7l-3.8,1.2 M480.8,426.9l-0.4-2.9 M481,445.6l-0.1-18.6 M478.8,445v-7.6
         M485.1,446.2l-0.5-20.5 M473.3,465.1l5.5-27.7 M487.6,422.7l-0.7-14.4 M488.3,447.1c-1-0.3-2.1-0.6-3.2-0.9 M486.8,406.8
        l-1.8-27.7c-1.4-25.3-3.4-47.9-6.3-68 M477.7,415.9c-1.2-2.1-2.6-3.8-4.3-5 M484.6,425.7l2.9-3 M458.4,484.9
        c6.4-25.3,11.2-48.8,14.5-70.5l0.5-3.5 M481.7,458.8l29.5,8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M489.5,541.8c1.7-0.4,2.5-2.4,2.4-6.1l0-0.8 M488.2,535v6.7
        c0.5,0.1,0.9,0.1,1.3,0 M488.2,531.8v3.2 M492,534.9l-3.8,0.1 M486.2,550.8l0-3.8c0.1-23.3-0.1-51.6-0.8-84.9 M489.5,548.6
        l-3.3,2.1 M489.5,548.6v-6.9 M492,534.9c0.3-7.7,0-14.3-0.9-20c-0.3-1.6-0.9-2.7-1.7-3.2 M489.4,511.8c-0.4-0.3-0.9-0.4-1.4-0.3
        v6.5 M488,517.9c-0.5,1.3-0.8,3.5-0.8,6.5c0,4,0.4,6.4,1,7.4 M488.2,531.8c0.8,0.1,1.2-0.6,1.3-2.2l0-8.5
        c-0.1-1.8-0.6-2.8-1.5-3.1 M454.8,483.6l3.6,1.3 M466.2,500.7l-0.1-3.6 M466.1,497.1l-0.9-7.3c-0.1-0.2-0.2-0.4-0.3-0.6
         M464.9,489.1c-0.9-1.4-3.1-2.8-6.5-4.2 M389.9,549.7c12.2,0.4,24.5,1.7,36.9,4c6.8,0.9,10.4-1.8,11-8.1v-27.5
        c-0.5-5.7-2.7-9.4-6.5-11.1c-11.9-4.1-24.4-6.6-37.6-7.4l-2.5-0.1c-2.4-0.1-4.9-0.2-7.3-0.2c-2.6,0-5.2,0-7.9,0.2l-2.6,0.1
        c-13.2,0.8-25.7,3.3-37.6,7.4c-3.8,1.8-5.9,5.5-6.5,11.1v27.5c0.6,6.3,4.2,9,11,8.1c12.4-2.3,24.7-3.6,36.9-4 M450.3,602.3
        l3.1-96.1c0-8.5,0.5-16,1.5-22.7 M462,669l1-33.6c1-43.6,1.1-87.3,0.4-130.9c-0.1-6.1,0.4-11.2,1.5-15.3 M465.2,562l-0.1,2.2
        l-0.4-52c0.1-7,0.6-12,1.5-15 M458.4,484.9c-0.8,5.8-1.2,12.9-1.3,21.4l-5.2,162.8 M479.8,552.2v11.1l-0.4,0.3 M479.4,563.7
        c-3.6,2.3-7.8,4-12.6,5.1 M479.4,552.1v0.6 M479.4,552.7l0.4-0.4 M479.8,552.2l2-0.4 M481.7,551.8v0.3c0,31.8-0.8,62.8-2.4,92.9
         M479.3,541.6l0.4-0.5v11.1 M466.8,560.4l0-9 M466.8,560.4l-1.6,0.3l0,1.3 M479.3,541.6c-3.6,4.1-7.8,7.4-12.5,9.7 M479.4,552.7
        c-3.2,3.3-7.4,5.8-12.6,7.7 M466.7,568.8l0.1-8.4 M466.8,551.4c0-16.8-0.2-33.7-0.7-50.7 M486.2,550.8l-4.5,1 M487.4,660.4
        c-0.5,0.1-1,0.1-1.6,0l0.2-6.7 M489.7,655.3c-0.2,3-1,4.6-2.3,5.1 M489.8,653.6l-3.8-0.1 M489.7,655.3l0.1-1.2 M489.8,653.6
        l-0.1,0.5 M489.8,653.6L489.8,653.6 M489.7,654.1l0-0.5 M494.3,673.6l-5.3-0.4 M488.9,673.2l-2.1-0.2 M494.3,673.6
        c0-6.1-0.1-12.1-0.3-18c-0.4-10.4-1.5-18.6-3.5-24.7 M486.8,673.1l0.5-12.7 M488.9,673.2l0.7-17.9 M490.4,630.9
        c-0.6-1.9-1.3-3.5-2-5 M488.3,630.7c0.6,0.6,1,1.6,1.2,3l0.6,9 M486.4,636.5l0.2-6.5c0.7-0.1,1.3,0.1,1.7,0.6 M488.3,630.7
        l0.1-4.7 M486.1,650.4c0.9,0.1,1.4-0.8,1.4-2.9l0.2-6.8c0-2.3-0.4-3.7-1.4-4.1 M489.8,651.6l0.3-8.9 M490.4,630.9l-0.3,11.7
         M465.9,645c0,1.2,0.7,2,2,2.3l11.3,4.1c3,1.2,4.6-0.7,5-5.6c1.2-31.6,1.9-63.2,2-95.1 M474.7,646.8c1.4,0.2,2.1-0.4,2.3-1.8
        l0.9-18.3 M479.3,645c-0.5,1.5-1.8,2.2-4,2l-0.6-0.1 M465.9,645l7.7,3.3c3.2,1.3,5.1,0.8,5.6-1.4l0.1-1.8 M477.8,626.7l0.7-0.4
        V624l-0.6,0.3 M466.1,629.2l0,2 M466,631.1l-0.1,13.8 M477.9,624.3c-3.2,1.9-7.1,3.5-11.9,4.9 M474.7,646.8l-8.8-1.8 M466,631.1
        c4.8-1.1,8.8-2.6,11.8-4.4 M450.3,602.3l-1.4-0.4c-4.6-1.1-9.4-2.1-14.3-3c-10.2-1.8-21.1-3.1-32.7-3.8c-6-0.4-12.2-0.6-18.5-0.7
        c-6.3,0.1-12.4,0.3-18.3,0.7c-11.6,0.7-22.5,2-32.7,3.8c-4.9,0.9-9.7,1.8-14.2,3l-1.4,0.4 M448.7,604l0.1-1.7 M427.8,609.9
        c-13.6-2-28.3-3.1-44.3-3.3c-16,0.2-30.7,1.3-44.3,3.3c-4.6,0.8-7,4.5-7.4,11v24.8c0.1,6.5,3.1,9.6,9,9.4h85.4
        c5.9,0.2,8.9-2.9,9-9.4v-24.8C434.8,614.4,432.4,610.7,427.8,609.9L427.8,609.9z M318.3,604c4.5-1.1,9.2-2.1,14.1-2.9
        c15.4-2.7,32.5-4.2,51.1-4.4c18.7,0.2,35.7,1.7,51.1,4.4c4.9,0.9,9.6,1.8,14.1,2.9 M454.8,668.6c2.3-0.2,4.8-0.1,7.2,0.4
         M451.9,669.1c1-0.2,1.9-0.4,2.9-0.4 M465.2,562l-1,87.2l0.1,9.3c0.5,3.2,1.7,5.2,3.6,6.1l18.9,8.5 M486,653.6l0.1-3.2
         M486.1,650.4c-0.6-1-0.8-3.4-0.7-7.4c0.1-3,0.5-5.2,1-6.4 M489.8,651.6l0,2.1 M489.8,651.6l-0.1,2.1 M488.4,626
        c0.7-26,1-51.7,1.1-77.3 M479.4,563.7c-0.1,20.6-0.6,40.8-1.4,60.6 M456.2,571.8l-1.5,96.8 M466.7,568.8l-0.7,60.4 M489.4,511.8
        c-0.1-16.3-0.4-32.6-0.7-48.8 M478.7,459.2l0.6,82.5 M466.2,500.7l7.1-35.5 M478.9,418.4l-14,70.6 M480.4,424.1l-14.3,73
         M481.7,551.8l-0.7-92.2 M301,631.1l0-2 M301.1,645l-0.1-13.8 M318.3,604l-0.1-1.7 M280.8,550.8c0.1,31.8,0.7,63.5,2,95.1
        c0.4,4.9,2.1,6.8,5,5.6l11.3-4.1c1.3-0.4,2-1.1,2-2.3 M287.7,645l0.1,1.8c0.5,2.3,2.4,2.7,5.6,1.4l7.7-3.3 M287.7,645
        c0.5,1.5,1.8,2.2,4,2l0.6-0.1 M292.4,646.8c-1.4,0.2-2.1-0.4-2.3-1.8l-0.9-18.3 M289.2,626.7l-0.7-0.4V624l0.6,0.3 M278.6,626
        c-0.8,1.4-1.4,3.1-2,5 M280.7,636.5l-0.2-6.5c-0.7-0.1-1.2,0.1-1.7,0.6 M276.9,642.6l0.6-9c0.2-1.4,0.6-2.4,1.2-3 M278.7,630.7
        l-0.1-4.7 M280.9,650.4c0.6-1,0.8-3.4,0.7-7.4c-0.1-3-0.5-5.2-1-6.4 M280.9,650.4c-0.9,0.1-1.4-0.8-1.4-2.9l-0.2-6.8
        c0-2.3,0.4-3.7,1.4-4.1 M276.6,630.9l0.3,11.7 M289.2,626.7c3,1.9,7,3.3,11.8,4.4 M301.1,645l-8.8,1.8 M289.1,624.3
        c3.2,1.9,7.1,3.5,11.9,4.9 M312.3,668.6c-2.3-0.2-4.8-0.1-7.3,0.4 M307.8,754.9c-4.1,1.5-9,4.5-14.5,8.7c-1.3,0.9-2.4-1-3.4-5.7
        l-0.4-5.9c-1.7-22.7-2.9-45.7-3.5-68.8l-0.1-1.4c0-3.4,1.5-5.4,4.4-6.1l14.2-6.5l0.5-0.1 M315.1,669.1c-1-0.2-1.9-0.4-2.9-0.4
         M279.7,660.4c0.4,0.1,1,0.1,1.6,0l-0.2-6.7 M277.3,654.4v-0.2 M279.7,660.4c-1.5-0.5-2.3-2.4-2.4-5.9l0-0.1 M277.3,654.1l0-0.5
         M277.3,653.6l0,0.5 M277.3,653.6L277.3,653.6 M277.2,652.6l0,1 M277.3,653.6l-0.1-1 M281.1,653.6l-3.8,0.1 M272.8,673.6l5.3-0.4
         M278.1,673.2l2.1-0.2 M276.6,630.9c-2,6.1-3.2,14.3-3.5,24.7c-0.2,5.9-0.3,11.9-0.3,18 M280.2,673.1l-0.5-12.7 M301.9,562l1,87.2
        l-0.1,9.3c-0.5,3.2-1.7,5.2-3.6,6.1l-18.9,8.5 M277.3,654.4l0.8,18.9 M290.7,682.9l9.3-4.1c2.1-0.9,3.2,0.7,3.4,4.8l2.2,60.8
        c0.2,2.6-0.7,4.6-2.5,5.9l-5.9,3.8c-2.1,0.8-4.1,0.1-5.8-2.2c-1.6-21.1-2.7-42.4-3.4-63.8C288,685.5,288.9,683.8,290.7,682.9
        L290.7,682.9z M281.1,653.6l-0.1-3.2 M302.1,489.1c1.1,4.1,1.5,9.2,1.5,15.3c-0.8,43.6-0.7,87.3,0.3,130.9l1,33.6 M277.2,652.6
        l-0.3-10 M377.2,549.7l12.7,0 M396.2,549.9c-2.1-0.1-4.2-0.2-6.3-0.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M370.8,549.9c2.1-0.1,4.2-0.2,6.3-0.2 M312.2,483.6l-3.5,1.3
         M300.9,497.1l0.9-7.3l0.3-0.6 M302.1,489.1c0.9-1.4,3.1-2.8,6.5-4.2 M277.6,511.8c-0.8,0.5-1.3,1.5-1.7,3.2
        c-0.9,5.6-1.2,12.3-0.9,20 M279,517.9v-6.5c-0.5-0.1-1,0.1-1.4,0.3 M278.8,531.8c0.6-1,0.9-3.4,1-7.4c0-3-0.3-5.2-0.8-6.5
         M278.8,531.8c-0.8,0.1-1.2-0.6-1.3-2.2l0-8.5c0.1-1.8,0.6-2.8,1.5-3.1 M316.8,602.3l-3.1-96.1c0-8.5-0.5-16-1.5-22.7 M301.9,562
        l0.1,2.2l0.4-52c-0.1-7-0.6-12-1.5-15 M308.7,484.9c0.8,5.8,1.2,12.9,1.2,21.4l5.2,162.8 M300.8,500.7l0.1-3.6 M285.3,551.8v0.3
        c0,31.8,0.8,62.8,2.4,92.9 M285.3,551.8l2,0.4 M300.2,560.4l0-9 M287.7,552.7c3.2,3.3,7.4,5.8,12.6,7.7 M287.3,552.2l0.4,0.4
         M287.7,552.7v-0.6 M287.7,541.6l-0.5-0.5v11.1 M275,534.9l0,0.8c0,3.7,0.8,5.7,2.4,6.1 M278.8,535v-3.2 M277.5,541.8
        c0.4,0.1,0.8,0.1,1.3,0V535 M275,534.9l3.8,0.1 M277.5,548.6l3.3,2.1 M281.6,462.1c-0.7,33.4-0.9,61.7-0.8,84.9l0,3.8
         M277.5,541.8v6.9 M280.8,550.8l4.5,1 M287.7,541.6c3.6,4.1,7.8,7.4,12.5,9.7 M287.3,552.2v11.1l0.4,0.3 M300.3,568.8
        c-4.8-1.1-9.1-2.8-12.6-5.1 M300.2,560.4l1.6,0.3l0,1.3 M300.3,568.8l-0.1-8.4 M300.2,551.4c0-16.8,0.2-33.7,0.6-50.7
         M277.5,548.6c0.1,25.6,0.4,51.3,1.1,77.3 M287.7,563.7c0.1,20.6,0.6,40.8,1.4,60.6 M310.8,571.8l1.5,96.8 M301,629.2l-0.7-60.4
         M312.2,483.6c18.3-6.5,36.7-10.6,55.3-12.4l8.8-0.7c2.4-0.1,4.8-0.2,7.1-0.2c2.5,0,4.9,0.1,7.4,0.2l8.8,0.7
        c18.5,1.8,36.9,6,55.3,12.4 M352.8,358.7c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.8c0,0.1,0.1,0.2,0.3,0.3l0.7,0.1
        l0.7-0.1c0.2-0.1,0.3-0.2,0.3-0.3v-1.8c0-0.3-0.1-0.5-0.3-0.7C353.3,358.8,353.1,358.7,352.8,358.7L352.8,358.7z M321.2,396.4
        c-3.1,1.2-6.2,2.6-9.4,4.2 M311.7,400.6c-5.6,2.7-11.4,6-17.5,9.9l-0.6,0.4 M280.2,408.3l0.1-1.4v0 M291.9,288.2l-6.3,4.1
        c-2.9,2.3-5.3,8.1-7,17.4c-2.3,14.8-3.6,33.6-3.8,56.3c0.2,21.5,2,35.6,5.3,42.2 M280.2,406.8l1.8-27.7c1.4-25.3,3.4-47.9,6.3-68
         M280.2,406.8c-1.3-15.6-1.2-31.3,0.2-47.1c2.9-38.1,6.5-61.4,11-69.9 M288.3,437.4l-2.1-10.4 M286.6,424.1c0.4-2.1,0.9-4,1.6-5.6
         M288.2,418.4c0.4-1,0.8-1.8,1.2-2.6 M282.4,425.7l-2.9-3 M286.2,426.9l0.4-2.9 M282.4,425.7l3.8,1.2 M285.3,459.7l0.7-0.1
         M281.6,462.1l3.7-1l0-1.4 M286,459.6l2.3-0.4 M293.8,465.1c-0.6-1.9-1.5-3.3-2.7-4.2l-2.7-1.8 M285.3,458.8l0,0.9 M288.3,459.1
        c0.5-0.1,0.7-0.8,0.5-2.2v-12.1l-0.6,0.2 M288.2,459.2l0.1,0 M288.3,445l-2.2,0.6 M283.4,446.3l2.7-0.7 M278.7,447.1
        c1-0.3,2.1-0.6,3.2-0.9 M281.9,446.2L281.9,446.2c0.6-0.2,1-0.2,1.4,0.1 M278.4,462.9l3.2-0.9 M285.3,458.8
        c0.2-7.3-0.5-11.5-1.9-12.5 M279.5,422.7c-0.3,8.1-0.6,16.3-0.7,24.4 M293.8,465.1l-5.5-27.7 M286.1,445.6l0.1-18.6 M288.3,437.4
        v7.6 M281.9,446.2l0.5-20.5 M280.2,408.3l-0.7,14.4 M293.7,410.8c-1.7,1.2-3.1,2.9-4.3,5 M293.7,410.8l0.5,3.5
        c3.3,21.8,8.1,45.3,14.5,70.5 M445.6,266.7c-12.3-4-32.8-5.9-61.6-5.9h-0.9c-28.8,0-49.3,2-61.6,5.9 M335.4,273.1
        c12.9-2,27.8-3.1,44.7-3.3h6.7c16.9,0.2,31.8,1.3,44.7,3.3 M335.1,277.4l0.1-2.2 M335.3,275.2l-9.4,1.5 M325.6,279.7
        c2.4-0.7,4.8-1.3,7.3-1.9l2.2-0.4 M325.9,276.7l3.3-1.6 M325.6,279.7l0.3-3 M321.4,266.7l-1.8,0.7c-5.3,2.1-10,4.4-13.9,6.8
        l-0.5,0.3 M321.4,266.7l-1.8,0c-5.3,2.1-10,4.4-13.9,6.8l-0.5,1 M335.4,273.1l-2.8,0.5l-0.1,0 M335.3,275.2l0-0.5l0.1-1.1l0.1-0.6
         M318.1,271.9l-0.6-1.5c-0.1-0.2-0.2-0.3-0.4-0.2c-1.1,0.1-2.1,0.6-3.1,1.2c-0.2,0.1-0.3,0.2-0.2,0.5l0.6,1.5
        c0.1,0.2,0.2,0.3,0.4,0.2c1.2-0.2,2.3-0.6,3.1-1.2C318.1,272.3,318.2,272.1,318.1,271.9L318.1,271.9z M317.1,272
        c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1
        c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.4C317.2,271.7,317.1,271.8,317.1,272L317.1,272z M315.4,271.8
        c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.1l-0.2-0.4c0-0.1,0.1-0.3,0.2-0.4
        c0.1-0.1,0.2-0.1,0.4-0.1C315.2,271.6,315.3,271.7,315.4,271.8L315.4,271.8z M313.2,281.3l-0.2,3.2 M313,284.5
        c3.9-1.9,8.1-3.5,12.7-4.9 M332.6,273.6c-8.3-0.2-17.5,2.8-27.5,8.9c-6.2,4.3-11.1,8.6-14.9,12.9 M329.2,275.1l3.3-1.6
         M313.2,281.3c4.9-2.3,10.2-4.4,16-6.2 M291.4,289.9l0.5-1.7 M305.3,274.5c-6.5,4.1-10.9,8.7-13.3,13.7 M293.2,299.2l0.1-0.2
        c5.3-5.9,11.8-10.7,19.6-14.5 M291.1,296.1l0.2-1 M291.3,295.1l-1.4,1.3 M290.1,295.4l-0.2,1.1 M290.9,297.1l0.5-0.6 M291.1,296.1
        l0.3,0.4 M290.9,297.1l0.2-1 M289.9,296.4l-0.7,5.2c0,0.4,0.1,0.8,0.4,1.1 M289.6,302.8l1.3-5.6 M291.4,296.6
        c0,1.7,0.6,2.5,1.8,2.6 M288.3,311.2c1.5-5.9,3.1-9.9,4.9-12 M289.6,302.8l-1.3,8.4 M304.9,296.6c-4.8,2.9-8.4,6-10.9,9.4
        c-2.4,3.2-4.1,9.5-5.2,19.1l0,0.1c-3.1,31.5-5.2,65-6.3,100.5 M289.4,415.8l6.3-102.2c1.2-7.9,4.3-13.5,9.2-17 M321.2,396.4
        l4.2-88.6c-0.9-5.5-2.2-1.3-4,12.6l-9.7,80.2 M431.9,277.4c-13.9-2.3-30.1-3.5-48.4-3.7c-18.4,0.2-34.5,1.5-48.4,3.7 M291.4,289.9
        c-0.5,1.7-0.9,3.6-1.3,5.5 M291.4,296.6c5.7-6,13-11.1,21.8-15.3 M332.6,273.6c-15,2.9-28.7,10.1-41.2,21.6 M255.8,466.8l0.6,1.9
        l21.9-5.8 M278.7,447.1c-5.9,1.7-11.1,3.7-15.5,5.8c-4.5,2.6-7,7.3-7.5,13.9 M255.8,466.8l29.5-8 M278.4,462.9
        c-0.4,16.2-0.6,32.5-0.7,48.8 M286,459.6l-0.7,92.2 M288.2,418.4l14,70.6 M300.9,497.1l-14.3-73 M288.2,459.2l-0.5,82.5
         M300.8,500.7l-7.1-35.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M398,777.4c4.5-0.2,8.7-0.4,12.5-0.7l1.6-0.1 M369.1,777.4
        c-0.1-1.4,1.3-2.2,4.1-2.3h20.6c2.9,0.2,4.2,0.9,4.2,2.3 M362.4,791.2l4.7,0.7c2.1,0.3,4.3,0.5,6.5,0.7c1.5,0.1,3,0.2,4.5,0.2
        l6.2,0.5c24.4,0,49.1-0.9,74-2.5 M369.1,777.4l14,0.3l15-0.3 M358.5,797.4l1.5-2.4 M360,795l2.4-3.8 M361.7,790.9l0.7,0.2
         M358.3,790l3.4,0.9 M362.4,791.2L362.4,791.2l-0.7-0.2 M355,776.7l1.5,0.1c3.9,0.3,8.1,0.5,12.6,0.7 M355.2,796l3.1-5.9
         M358.5,797.4l-3.2-1.4 M358.3,790l-17.9-0.7 M458,788.3c-5.6,0.7-30.5,1.2-74.5,1.6c-17.4-0.2-31.8-0.4-43.2-0.6 M382.5,755.8
        l1.1-0.1h0l1.1,0.1c0.3,0.1,0.4,0.1,0.4,0.2v2.6c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h0c-0.4,0-0.8-0.1-1.1-0.5
        c-0.3-0.3-0.5-0.7-0.5-1.1V756C382,755.9,382.2,755.9,382.5,755.8L382.5,755.8z M355,763.9c7,0.2,14.1,0.3,21.2,0.3h14.6
        c7.2,0,14.3-0.1,21.3-0.3 M322.1,762.4l-0.1-1.9 M321.6,752.9l1.4,0.1 M314.1,753.5c-1.2,0-2.5,0.2-3.8,0.6
        c-0.8,0.2-1.6,0.5-2.4,0.8 M317.9,754c-1.1-0.4-2.4-0.6-3.8-0.5 M318.1,762.2l-0.1,0 M316.2,774.4c1.7-3.3,2.4-6.7,2-10.1
        l-0.1-2.1 M322,760.5l-0.4-7.6 M317.9,754l0.3,8.2 M292.3,774.2l12.6-9.5c2.4-1.8,3.4-5.1,3-9.8 M318.1,762.2l4,0.2 M282.3,738.6
        c0.7,0.9,1.5,1.7,2.4,2.3 M272.8,673.6c0.1,13.1,0.7,26.5,1.9,40.2c1.1,11.7,3.7,20,7.6,24.8 M287.2,767.9l0.1,1.1l0.1,0.4
        c0.5,3.1,1.2,6,2.1,8.9 M284.7,741l2.5,27 M309.6,786.3l6.7-11.9 M309.1,788.3c0.1-0.7,0.3-1.4,0.5-2 M310.2,788.4l-1.1-0.1
         M312.4,792.9l1.3,2.7 M313.7,794v1.6 M311.2,788.4l2.5,5.6 M311.4,790.9l1,2 M309.2,794l-0.2-1.2 M289.4,778.3l0.4,0.7
        c2.7,5.3,7.4,9.8,13.8,13.5c1.2,0.8,3.1,1.3,5.6,1.4 M308.9,792.7l0.1,0 M309,792.7L309,792.7l-0.1-2 M308.9,790.7l2.6,0.2
         M309,792.7l3.4,0.2 M311.2,788.4l-0.9-0.1 M308.9,790.7l0.2-2.5 M310.2,788.4l1.2,2.6 M309.6,786.3c-5.2,0.3-11-3.7-17.3-12
         M313.7,795.6c14.1,1.2,29,1.8,44.8,1.8 M316.2,774.4l38.8,2.3 M340.3,789.3c-14.7-0.3-24.5-0.6-29.2-0.9 M355,776.7v-12.7
         M355,763.9c-11.1-0.3-22.1-0.8-32.9-1.5 M287.2,767.9c1.2-1.9,2.9,0.2,5.1,6.3 M355.2,796c-13.7,0.1-27.5-0.5-41.5-2 M360,795
        c32.3,0.7,65-0.1,98-2.2 M445.4,752.9l4.8-150.5 M459.2,754.9L462,669 M451.9,669.1l-2.7,85 M484.7,738.6
        c1.8-21.5,3.2-43.3,4.2-65.4 M444,753l4.8-148.9 M323,753L318.3,604 M453,753.5l1.8-84.9 M486.8,673.1
        c-1.1,22.9-2.6,45.6-4.5,67.9 M305,669l2.8,85.9 M278.1,673.2c1,22.1,2.4,43.9,4.2,65.4 M280.2,673.1c1.1,22.9,2.6,45.6,4.5,67.9
         M312.3,668.6l1.8,84.9 M315.1,669.1l2.7,85 M321.6,752.9l-4.8-150.5"/>
    </g>
  </g>
  <g id="left">
    <g>
      <path fill="none" d="M222.6,784.4c6.3,3.8,5.3-8.5,5.9-12.1l-0.2-0.3C227.1,777.9,228.7,783.5,222.6,784.4L222.6,784.4z"/>
      <path fill="none" d="M222.6,784.4c6.1-0.9,4.5-6.6,5.7-12.4l-1.3-1.8c-1,7-1.6,8.9-11.3,11.9c0.3,0.5,1,1.6,1.3,2
        C219,783.5,221.1,783.5,222.6,784.4L222.6,784.4z"/>
      <path fill="none" d="M215.7,782.1c5.7-1.7,9.9-4.1,10-6c1.4-4.3,1.9-10.6,2-15.2l-6.7,1.5c-0.4,5.4-4.3,9.1-11.6,11.2
        c0,0-46.7,8.6-46.7,8.6c0.1,0.1,0.2,0.4,0.2,0.5c0,0,0.5,11.4,0.5,11.4C181.3,790.9,198.9,786.9,215.7,782.1L215.7,782.1z"/>
      <polygon fill="none" points="215.7,782.1 209.5,783.8 210.5,785.1 216.5,783.5 215.7,782.1 		"/>
      <path fill="none" d="M216.5,783.5c-15.6,4.4-33.7,8.2-53.1,11.7l0,0.5c17.7-3.1,35.6-7,53.5-11.5L216.5,783.5L216.5,783.5z"/>
      <path fill="none" d="M210.5,785.1l-1-1.3c-12.5,3.4-25.6,6.4-39.3,9v1.1C185.9,791,199.4,788.1,210.5,785.1L210.5,785.1z"/>
      <polygon fill="none" points="228.3,772.1 228.5,772.3 228.5,770.6 227.2,768.5 227,770.3 228.3,772.1 		"/>
      <path fill="none" d="M228.5,770.6c0.1-3.3,0.1-7.1-0.1-10.4c-0.1,0-0.7,0.7-0.8,0.7c0.1,0-0.5,7.3-0.5,7.6L228.5,770.6
        L228.5,770.6z"/>
      <path fill="none" d="M229.5,763.5h-0.9c0,1.5,0,3.1,0,4.6C229.3,767.4,229.6,765.9,229.5,763.5L229.5,763.5z"/>
      <path fill="none" d="M228.5,760.2c0.9-1,1.3-2.6,1.3-4.5h-2.2l0.1,5.3C227.7,760.9,228.4,760.2,228.5,760.2L228.5,760.2z"/>
      <polygon fill="none" points="227.7,760.9 227.7,760.9 227.7,760.9 		"/>
      <path fill="none" d="M227.7,760.9c-0.1-13.3-1-72.2-0.7-84.8l-6.9,1l0.9,85.3L227.7,760.9L227.7,760.9z"/>
      <path fill="none" d="M221,762.7v-0.2c-81.9,18.4-54.5,10.6-60.4-70.8c-0.2-4.6,2.5-7.1,8.2-7.3l51.3-7.2
        c-0.7-42.5,0.2-128.1-4-167.3c-0.6-4.2-3.2-8.8-6.9-12.8l0,7l0.8,0.9v0c2,2.4,3.2,6.7,3.6,12.9c0.7,16.2,1.2,33.2,1.5,51
        c0.3,20.2,0.7,72.4,1,93.5c0,5.5-2.5,8.6-7.5,9.3c-19.8,3.4-42.2,6.4-67.1,8.8l1.5,95.3c7.2-0.9,16.9,0.5,19.9,6.5l46.7-8.6
        C216.6,771.6,220.5,767.9,221,762.7L221,762.7z"/>
      <path fill="none" d="M227.6,755.6h2.2c-0.7-47.1-0.1-114.6-1.5-160.5c-0.1-3.9-1.9-8.5-3.2-12.2
        C228.3,617.3,226.3,709.2,227.6,755.6z"/>
      <path fill="none" d="M156.1,776.7c-3.9-2-12.4-0.8-17.1-0.9c0,0-29.4,0.7-29.4,0.7c-1.6-0.8-0.5,21.3-0.9,21.6l1.4,0
        c0-6.1,0.1-14.1,0.2-20.2c12,0.4,33.5-2.3,45.1,0.1h0c3.1,1.6,5,3.1,5.8,4.4c0.1,0,1.4-0.1,1.5-0.2
        C162.1,780.5,159.9,778.7,156.1,776.7L156.1,776.7z"/>
      <path fill="none" d="M155.4,778L155.4,778c-3.5-2.1-24.1-0.2-28.5-0.5v7c12.4-0.3,23.8-1,34.3-2.1
        C160.4,781,158.5,779.6,155.4,778L155.4,778z"/>
      <polygon fill="none" points="138.9,775.8 142.9,775.7 141.4,680.4 137.5,680.6 		"/>
      <path fill="none" d="M161.6,761.4c-0.4,19.9,45.9,2,59.4,1l-0.9-85.3l-51.3,7.2c-5.7,0.2-8.5,2.7-8.2,7.3L161.6,761.4z
         M209.2,757.5c-7.1,0.6-41.6,11.2-41.5-1.1c0,0-1.1-57.2-1.1-57.2c-0.4-4.9,1.5-7.5,5.5-7.7c60.1-7.2,39.6-16.2,43.1,58
        C215.6,754.4,213.6,757,209.2,757.5L209.2,757.5z"/>
      <path fill="none" d="M183.6,762.3l25.6-4.8c4.4-0.4,6.4-3.1,6.1-8v-56.9c0.1-4.1-1.4-6.1-4.6-6.1l-38.5,5c-4,0.2-5.9,2.8-5.5,7.7
        l1.1,57.2C167.6,764,177.3,761.4,183.6,762.3L183.6,762.3z"/>
      <polygon fill="none" points="158.5,796.9 158.8,796.5 162.1,795.9 162,794.3 152.5,795.4 152,797.7 158.5,796.9 		"/>
      <path fill="none" d="M163.5,795.2l6.7-1.2v-1.1c-0.6,0.1-7.4,1.4-7.8,1.4l0.1,1l-0.1-1l-0.4,0.1l0.1,1.6l1.4-0.2L163.5,795.2
        l-0.9,0.2L163.5,795.2L163.5,795.2z"/>
      <path fill="none" d="M162.5,782.2l-1.3,0.1c0.5,0.6,0.6,11.5,0.8,12c0.3-0.1,1.1-0.2,1.4-0.2l-0.5-11.4c0-0.1-0.1-0.4-0.2-0.5
        L162.5,782.2L162.5,782.2z"/>
      <path fill="none" d="M156.5,785.7c5.2,3.3,2.4,7.8-3,8.8c2.9-0.3,5.7-0.7,8.4-1.1l-0.5-10.6c-0.1-0.2-0.1-0.3-0.2-0.5
        c-10.5,1.1-21.9,1.8-34.3,2.1c0,2.9,0,8.8,0,11.7l3.7-0.1c-5.9-2.1,0.4-10.2,4.7-10.2c6.2-1.3,11.6,8.2,3.5,9.8l9-0.6
        C139.2,793,150.5,780.8,156.5,785.7L156.5,785.7z"/>
      <path fill="none" d="M158.5,787.7c-3-4.8-10.6-3.5-12.9,1.4C150.4,790.3,154.7,789.8,158.5,787.7L158.5,787.7z"/>
      <path fill="none" d="M162,794.3l0-0.9c-7.2,1.2-15.8,1.9-23.1,2.3c-3.9,1.4-8.1,0.1-11.9,0.4v1.3C138,796.9,150.9,795.6,162,794.3
        L162,794.3z"/>
      <path fill="none" d="M157.7,792.6c2.3-1.5,1.9-3.2,0.8-4.9c-3.9,2.1-8.1,2.6-12.9,1.4C141.5,797.3,153.8,795.8,157.7,792.6
        L157.7,792.6z"/>
      <path fill="none" d="M141.1,794.8c5.4-3.2-1.2-10-5.7-8.9c-3.8,0.4-7.2,4.2-7,8.1C130,798,137.8,796.5,141.1,794.8L141.1,794.8z
         M141.8,788.8c-3.4,2.1-7.5,2.6-12.3,1.4C134.3,791.4,138.4,790.9,141.8,788.8L141.8,788.8z"/>
      <path fill="none" d="M150.9,802.4c0.4-1.6,1.3-5.5,1.6-7l-10,1c0,1.5,0,5.5,0,7.1L150.9,802.4L150.9,802.4z"/>
      <polygon fill="none" points="150.5,804 150.9,802.4 142.5,803.5 142.5,805.2 150.5,804 		"/>
      <path fill="none" d="M142.5,798.8v-2.5c-11.3,1-22.6,1.6-33.8,1.7l0,3.2C120.7,800.7,132,799.9,142.5,798.8L142.5,798.8z"/>
      <path fill="none" d="M126.9,796.1v-5.2l-16.8,0.4l-0.1,5.3C115.6,796.6,121.2,796.4,126.9,796.1L126.9,796.1z"/>
      <polygon fill="none" points="122.9,784.5 121.2,784.6 121.2,787.3 122.9,787.3 122.9,784.5 		"/>
      <path fill="none" d="M121.2,784.6c1.1,0,4.6-0.1,5.7-0.1v-7l-16.6,0.4l-0.1,6.8C113,784.7,118.4,784.6,121.2,784.6L121.2,784.6z"
        />
      <path fill="none" d="M121.2,787.3v-2.8l-6.5,0.1v1.5c2.1,3.6-3.1,4.7-1.8,0.4c0,0,0-1.9,0-1.9h-2.6l-0.1,6.7l16.8-0.4v-6.5
        l-4.1,0.1v2.8H121.2L121.2,787.3z"/>
      <path fill="none" d="M113.9,789.5c1.4-0.1,1.6-2.4,0.8-3.3v-1.5l-1.8,0C113.1,786.1,111.8,789,113.9,789.5L113.9,789.5z"/>
      <path fill="none" d="M110.1,796.7v1.3c5.5-0.1,11.1-0.3,16.8-0.6v-1.3C121.2,796.4,115.6,796.6,110.1,796.7L110.1,796.7z"/>
      <path fill="none" d="M106.8,800.3l1.9,1c0.4-1-0.8-24.8,0.9-24.8l-3.8-0.3l-0.7,26c0.8-0.9,2.5-2.8,3.4-3.8L106.8,800.3
        L106.8,800.3z"/>
      <polygon fill="none" points="63.4,809.3 65.3,809.3 65.1,809.1 64.7,805.8 		"/>
      <path fill="none" d="M63.6,796.4c1.2,2.6,0.9,8.8,1.6,12.8c0.3,0.3,6.2,6.7,6.5,7c-2.9-27.1-4.7-49.8-6-74.7
        c-2.3,0.3-4.7,0.4-7.1,0.4C59.8,759.8,61.5,778.5,63.6,796.4z"/>
      <path fill="none" d="M87.2,811.1c-0.4-16.1,0.6-24.2,2.9-24.3l-1,16.3l16.1-0.9l0.7-26c-24.6-0.4-20.2-4-23.3-25.6
        c-2.4,0.6-4.8,1.1-7.3,1.4c1.3,16.2,6.9,52.1,6.4,64.5C85.6,816.7,87.2,817.2,87.2,811.1L87.2,811.1z"/>
      <path fill="none" d="M90.1,786.8c-2.3,0.1-3.3,8.2-2.9,24.3c-0.1,3.4-0.6,5.2-1.7,5.4C90,818,89.2,790.4,90.1,786.8L90.1,786.8z"
        />
      <path fill="none" d="M71.6,816.1l10,0.3c0.5-12.3-5.1-48.4-6.4-64.5c-1.6,0.2-3.2,0.3-4.9,0.4c-0.1-2.9-0.4-8.7-0.5-11.6
        c-1.4,0.3-2.8,0.5-4.2,0.7C66.9,766.3,68.7,789,71.6,816.1z M80.4,814.1c-0.1,0.3,0.3,1.5-0.4,1.5c-0.3-0.2-3.5,0.4-3.4-0.3
        c0.1-0.3-0.3-1.6,0.4-1.5C77.3,813.9,80.5,813.4,80.4,814.1z"/>
      <path fill="none" d="M80.4,815.3c-0.1-0.3,0.3-1.6-0.4-1.5c0,0-3,0-3,0c-0.6-0.1-0.3,1.2-0.4,1.5c0,0.2,0.1,0.3,0.4,0.3
        C77.3,815.4,80.5,816,80.4,815.3L80.4,815.3z"/>
      <path fill="none" d="M105.8,776.1c0.7,0.6,31.2-0.4,33.1-0.4l-1.5-95.1l-11.3,0.6c4,30.1-14.1,61.3-43.6,69.3
        C85.7,772.2,81.2,775.7,105.8,776.1L105.8,776.1z"/>
      <path fill="none" d="M101,730.1c12.7-12.6,18.1-30.4,15.5-48.3l-4.8,0.2c4.4,25.4-13.2,50.9-37.5,57.7l0.5,5.5
        C84.6,743,93.7,737.6,101,730.1L101,730.1z"/>
      <path fill="none" d="M102.6,728.4c-7.4,8.3-17.2,14.4-27.9,16.8l0.6,6.7c33.2-3.9,55.5-38.2,50.9-70.7l-9.7,0.5
        C118.9,698.9,114.2,715.9,102.6,728.4L102.6,728.4z"/>
      <path fill="none" d="M96.5,726.6c20.9-19.2,21.1-54.2,0-73.7C85.2,641.3,70.3,638,55,635.7c-0.9,0-1.8-0.3-2.2-1.2v9.1
        c63.5-3.8,68.3,89.4,4.9,92.3l0.8,6.1C72.8,741.9,86.1,736.8,96.5,726.6L96.5,726.6z"/>
      <path fill="none" d="M70,734.1c59.7-19.8,31.9-106.8-28.3-88.2C-13.8,667.5,12.3,748.8,70,734.1L70,734.1z M57.5,721.9
        C32,723.3,15.2,691.3,30.6,671h0c11.2-16.8,37.5-18.5,50.5-3.2C100,687.4,84.8,722.4,57.5,721.9L57.5,721.9z"/>
      <path fill="none" d="M48.7,720.9c1.5,0.4,3.1,0.7,4.7,0.8l0.3-2.2c-1.5-0.2-2.9-0.4-4.4-0.8L48.7,720.9z"/>
      <path fill="none" d="M86.4,682.4l2.2-0.4c-1.2-5.3-3.7-10-7.6-14.2l-1.5,1.6C83,673.2,85.3,677.5,86.4,682.4z"/>
      <path fill="none" d="M88.7,682c-5.1,0.8-12.4,2.1-17.6,3c-0.9-0.2-3,1.4-3.7,1.8l0.1,2.4l4.2-0.1c0-0.2,0-0.8,0-1
        c5.1-0.6,12.5-1.5,17.6-2.1C89.1,684.6,88.9,683.3,88.7,682L88.7,682z"/>
      <path fill="none" d="M82.7,683l3.8-0.6c-1.1-4.8-3.4-9.2-6.9-13l-2.6,2.8C79.8,675.4,81.7,679,82.7,683z"/>
      <polygon fill="none" points="87.1,686.2 83.3,686.6 83.4,690.5 87.2,690.6 87.1,686.2 		"/>
      <polygon fill="none" points="89.3,685.9 87.1,686.2 87.2,690.6 89.5,690.7 89.3,685.9 		"/>
      <polygon fill="none" points="80.2,687 80.3,690.3 83.4,690.5 83.3,686.6 80.2,687 		"/>
      <path fill="none" d="M80.3,690.3l-0.1-3.3l-8.5,1c0,0.5,0,1.3,0.1,1.9L80.3,690.3L80.3,690.3z"/>
      <path fill="none" d="M83.4,690.5c-2.1,0-9.5-0.4-11.6-0.5c0-0.1,0-0.8,0-0.9l-4.2,0.1l0.1,2.4c0.7,0.3,2.9,1.7,3.8,1.5
        c5.2,0.5,12.6,1.1,17.7,1.6l0.4-3.9C88.1,690.7,84.8,690.5,83.4,690.5L83.4,690.5z"/>
      <path fill="none" d="M76.9,672.2c1-1,3.2-3.4,4.2-4.4l-2.8-2.8c-3.3,3.9-8.2,9.4-11.6,13.3l-0.7-0.5l-2.6,3.4l1.9,1.4
        c0.7-0.3,3.1-1.3,3.5-2C70.3,679,75.4,673.7,76.9,672.2L76.9,672.2z"/>
      <path fill="none" d="M76.9,672.2l-2.2,2.2c2.4,2.7,4,5.8,4.9,9.1l3.1-0.5C81.7,679,79.8,675.4,76.9,672.2L76.9,672.2z"/>
      <path fill="none" d="M72.2,671.9l2.1-2.3c-1-0.9-2-1.6-3.1-2.3l-1.6,2.6C70.5,670.5,71.4,671.2,72.2,671.9L72.2,671.9z"/>
      <path fill="none" d="M76.8,666.7c-1.1-1-2.3-1.9-3.6-2.7l-2,3.3c1.1,0.7,2.1,1.5,3.1,2.3L76.8,666.7L76.8,666.7z"/>
      <path fill="none" d="M73.2,664c1.2,0.8,2.4,1.7,3.6,2.7l1.5-1.7c-1.2-1.1-2.5-2.1-3.9-2.9L73.2,664L73.2,664z"/>
      <path fill="none" d="M71.2,667.3c0.7-1.2,2.4-4,3.2-5.2c-1.1-0.7-2.3-1.4-3.4-1.9c-2.5,4.5-6.2,11.1-8.6,15.6
        c-0.6,0.6-0.8,3.2-0.9,4l1.9,1.4l2.6-3.4l-0.8-0.6C66.3,675.3,70.1,669,71.2,667.3L71.2,667.3z"/>
      <path fill="none" d="M68,665.5l1.9-3.4c-4.3-2.1-9.4-3-14.5-2.7l0.5,3.8C60.2,663.1,64.4,663.8,68,665.5L68,665.5z"/>
      <path fill="none" d="M72.2,671.9c-0.8-0.7-1.7-1.4-2.6-2l-4.5,7.3c0.4,0.3,1.2,0.9,1.5,1.2L72.2,671.9L72.2,671.9z"/>
      <path fill="none" d="M66.5,668.2l1.5-2.7c-3.6-1.7-7.8-2.4-12.1-2.2l0.4,3.1C59.9,666.2,63.4,666.9,66.5,668.2L66.5,668.2z"/>
      <path fill="none" d="M71.7,689.2v-0.4C71.7,688.9,71.7,689.1,71.7,689.2z"/>
      <path fill="none" d="M71.1,685l8.5-1.5c-0.9-3.4-2.5-6.4-4.9-9.1l-6,6.2C69.8,682,70.6,683.4,71.1,685z"/>
      <path fill="none" d="M68.1,681.4l-2.8,1.3c-1-0.7-2.9-2.1-3.9-2.9c0.1-0.8,0.2-3.4,0.9-4c-1.5-0.6-3.1-0.9-4.8-0.9
        c0.4,0.7-0.4,3.2-0.6,4c-1.2,0.3-3.5,0.9-4.7,1.2c-0.5-0.6-2.5-2.3-2.6-3.1c-1.4,0.8-2.7,2-3.7,3.2c0.8,0.2,2.3,2.3,2.7,2.9
        c-0.5,1.1-1.5,3.3-1.9,4.4c-0.8,0.1-3.4,0.5-4.1,0c-0.2,1.2,0,3.7,0.1,4.9c0.6-0.5,3.3-0.3,4-0.3c0.6,1.1,1.7,3.2,2.3,4.3
        c-0.4,0.7-1.7,3-2.5,3.2c1.2,1.2,2.5,2.3,3.9,3c0-0.9,1.8-2.8,2.3-3.4c1.2,0.2,3.6,0.7,4.8,0.9c0.2,0.8,1.2,3.2,0.9,4
        c1.7-0.1,3.3-0.5,4.8-1.2c-0.7-0.5-1-3.1-1.2-3.9c0.9-0.8,2.7-2.4,3.7-3.2c0.7,0.3,3.3,1,3.7,1.8c0.9-1.4,1.6-2.9,2-4.5
        c-0.8,0.2-3.1-1.2-3.8-1.5c0-1.2-0.1-3.6-0.2-4.8c0.7-0.4,2.8-1.9,3.7-1.8c-0.5-1.6-1.3-3-2.3-4.3L68.1,681.4L68.1,681.4z
         M64.6,687.1c2.9-0.1,2.9,4.6,0,4.5C61.7,691.7,61.7,687,64.6,687.1L64.6,687.1z M60.3,698.8c-2.8,1-4.2-3.5-1.4-4.3
        C61.7,693.6,63.1,697.9,60.3,698.8L60.3,698.8z M60,692.4c-3.6,3.8-9.6-2.1-5.7-5.8C57.9,682.9,63.8,688.8,60,692.4L60,692.4z
         M53.4,694.4c-0.4,2.9-4.9,2.2-4.4-0.7C49.3,690.9,53.9,691.6,53.4,694.4z M58.5,684.5c-2.8-0.9-1.4-5.2,1.4-4.3
        C62.8,681.1,61.3,685.4,58.5,684.5L58.5,684.5z M53.2,684.9c0.5,2.9-4.1,3.6-4.5,0.7C48.2,682.7,52.8,682,53.2,684.9z"/>
      <path fill="none" d="M66.2,687.8c-2-2.1-5.3,1.1-3.2,3.2C65,693.1,68.3,689.8,66.2,687.8L66.2,687.8z M64.6,687.9l1.3,0.8v1.5
        l-1.3,0.8l-1.3-0.8v-1.5L64.6,687.9L64.6,687.9z"/>
      <path fill="none" d="M66,688.7l-1.3-0.8l-1.3,0.8v1.5l1.3,0.8l1.3-0.8V688.7L66,688.7z M64.2,689.8c-0.8-1.2,1.6-1.3,0.8,0
        l-0.4,0.1L64.2,689.8L64.2,689.8z"/>
      <path fill="none" d="M64,689.3c0,0.1,0.1,0.3,0.2,0.4l0.4,0.1l0.4-0.1C65.7,688.9,64.2,688.3,64,689.3z"/>
      <path fill="none" d="M58.6,698.6c2.6,1.4,4.7-2.8,2.1-4C58.1,693.2,56,697.3,58.6,698.6L58.6,698.6z M59.9,695.2l1.1,1l-0.3,1.5
        l-1.4,0.4l-1.1-1l0.3-1.5L59.9,695.2L59.9,695.2z"/>
      <path fill="none" d="M61,696.2l-1.1-1l-1.4,0.5l-0.3,1.5l1.1,1l1.4-0.4L61,696.2L61,696.2z M60.2,696.5l0,0.4
        c-0.3,0.4-0.8,0.4-1-0.1C58.9,696.1,59.9,695.7,60.2,696.5z"/>
      <path fill="none" d="M60.1,696.9c0.3-1-1.2-1.1-1-0.1C59.3,697.2,59.8,697.3,60.1,696.9L60.1,696.9z"/>
      <path fill="none" d="M57.1,693.6c5.2,0.1,5.3-8.2,0-8.1C51.8,685.4,51.8,693.7,57.1,693.6L57.1,693.6z M58.5,691.5
        c-2.5,4.6-4.4-5.3-0.9-4.9C59,687.6,58.9,690,58.5,691.5z"/>
      <path fill="none" d="M58,692.3c1-1.5,1.1-4.5-0.5-5.6C54.6,686.1,55.2,694.7,58,692.3L58,692.3z M58.4,691.5
        c-2.3,4.5-4.1-5.5-0.8-4.7C58.9,687.7,58.8,690.1,58.4,691.5z"/>
      <path fill="none" d="M58,692.2c0.6-0.8,0.7-2.2,0.6-3.2c-0.8-4.8-3.6-1.2-2.8,1.7C56,691.8,57,693.6,58,692.2L58,692.2z
         M57.7,691.1L57.7,691.1l0.2,0.1v0.5l0,0l-0.2-0.1l-0.2-0.6h0l0,0L57.7,691.1v0.2V691.1L57.7,691.1z M58.2,690.7l-0.1,0.1
        l-0.2,0.1c-0.4-0.1-0.6-0.5-0.5-0.8l0,0c0.2,0.1,0.6,0.3,0.8,0.3L58.2,690.7z M57.2,691.2L57.2,691.2l-0.2-0.1c0,0,0-0.2,0-0.3
        l-0.1-0.1v0.2l0,0l-0.1-0.1v-0.2l-0.1,0l0,0.2c-0.1,0-0.2,0-0.2-0.1v-0.5c0.1,0,0.7,0.3,0.8,0.3V691.2z M57.2,691.6l0,0.2
        l-0.2,0.1l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.2c0.1,0,0.2-0.1,0.3-0.1l-0.3-0.1V691c0.1,0,0.7,0.3,0.8,0.2V691.6z M58.1,688.4
        c0,0-0.6-0.2-0.6-0.2c0,0.1,0,0.2,0,0.2h0l0,0l-0.2-0.1l0-0.5l0,0v0c0.1,0,0.7,0.3,0.8,0.3L58.1,688.4L58.1,688.4L58.1,688.4
        L58.1,688.4L58.1,688.4z M57.3,688.7v-0.3l0.8,0.5h0v0.2l0,0c-0.1,0-0.7,0-0.8,0v-0.2c0,0,0.1,0,0.1,0l0-0.3L57.3,688.7
        L57.3,688.7z M57.3,689.5v-0.2l0.8,0.3l0,0.3l-0.4,0c0,0,0.4,0.1,0.4,0.1v0.2l0,0l-0.8-0.3l0-0.2l0,0l0.4,0
        C57.7,689.6,57.4,689.5,57.3,689.5L57.3,689.5z M57.2,690.1c0,0-0.4,0-0.5,0c0.1,0,0.4,0.3,0.5,0.3v0.3l-0.8-0.6h0v-0.2
        c0,0.1,0.8,0,0.8,0V690.1z M57.2,689.4c0,0.7-1,0.3-0.8-0.3l0.1-0.1C56.8,688.9,57.2,689.1,57.2,689.4z M57.2,688.4
        c0,0.2,0.1,0.6-0.2,0.6l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.3c0.1,0,0.2-0.1,0.3-0.1l-0.3-0.1v-0.2C56.5,688.2,57.1,688.4,57.2,688.4
        L57.2,688.4z M56.9,688.1c0,0-0.2-0.1-0.2-0.1v-0.5l0,0l0.2,0.1l0.2,0.6h0v0L56.9,688.1v-0.3V688.1z"/>
      <polygon fill="none" points="57.7,691.1 57.7,691.1 57.7,691.3 57.6,691 57.5,691 57.5,691 57.5,691 57.7,691.6 57.8,691.7 
        57.9,691.6 57.9,691.1 57.7,691.1 		"/>
      <path fill="none" d="M58,690.8l0.1-0.1l0-0.4c-0.2,0-0.6-0.2-0.8-0.3l0,0c0,0.4,0.1,0.8,0.5,0.8L58,690.8L58,690.8z M58,690.6
        c-0.2,0.1-0.4-0.1-0.5-0.3l0.5,0.1L58,690.6z"/>
      <path fill="none" d="M57.9,690.6L57.9,690.6l0.1-0.1l-0.5-0.1C57.6,690.5,57.7,690.7,57.9,690.6L57.9,690.6z"/>
      <path fill="none" d="M57.2,691.2L57.2,691.2l0-0.5c-0.1,0-0.7-0.3-0.8-0.3v0.5c0,0,0.2,0.1,0.2,0.1l0-0.2l0.1,0v0.2l0.1,0.1l0,0
        v-0.2l0.1,0.1c0,0,0,0.2,0,0.3L57.2,691.2L57.2,691.2z"/>
      <path fill="none" d="M57.1,691.8c0-0.1,0-0.4,0-0.5c-0.1,0-0.7-0.2-0.8-0.2v0.2l0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1v0.2l0.3-0.2
        l0.1,0.2l0.2,0.1L57.1,691.8L57.1,691.8z M57,691.6l-0.1,0l0-0.2l0.2,0.1L57,691.6z"/>
      <polygon fill="none" points="56.8,691.6 57,691.6 57,691.5 56.8,691.4 		"/>
      <path fill="none" d="M58.1,688.4C58.1,688.4,58.1,688.4,58.1,688.4L58.1,688.4L58.1,688.4l0.1-0.2c-0.1,0-0.7-0.3-0.8-0.3v0l0,0
        l0,0.5l0.2,0.1l0,0h0c0-0.1,0-0.2,0-0.2L58.1,688.4L58.1,688.4z"/>
      <path fill="none" d="M57.3,688.4v0.3l0.1,0l0,0.3c0,0-0.1,0-0.1,0v0.2c0.1,0,0.7,0,0.8,0l0,0v-0.2h0L57.3,688.4L57.3,688.4z
         M57.7,688.9v-0.1l0.2,0.1H57.7L57.7,688.9z"/>
      <polygon fill="none" points="57.7,688.8 57.7,688.9 57.9,688.9 57.7,688.8 		"/>
      <path fill="none" d="M57.3,689.2v0.2c0.1,0,0.3,0.1,0.4,0.1l-0.4,0l0,0l0,0.2l0.8,0.3l0,0v-0.2c0,0-0.4-0.1-0.4-0.1l0.4,0l0-0.3
        L57.3,689.2L57.3,689.2z"/>
      <path fill="none" d="M57.1,690L57.1,690l0.1-0.2c0,0-0.8,0.1-0.8,0v0.2h0l0.8,0.6v-0.3c-0.1,0-0.4-0.3-0.5-0.3H57.1L57.1,690z"/>
      <path fill="none" d="M57.1,689.7c0.2-0.4-0.2-0.8-0.7-0.6l-0.1,0.1C56.2,689.6,56.8,690,57.1,689.7L57.1,689.7z M57,689.4
        L57,689.4c-0.2,0.1-0.4,0.1-0.5-0.1C56.5,689.1,57,689.2,57,689.4z"/>
      <path fill="none" d="M57,689.5c0.1-0.2-0.5-0.4-0.5-0.1C56.6,689.5,56.8,689.6,57,689.5L57,689.5z"/>
      <path fill="none" d="M57.2,688.7v-0.4c-0.1,0-0.7-0.2-0.8-0.2v0.2l0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1v0.3l0.3-0.2l0.1,0.2L57,689
        C57.1,689,57.2,688.9,57.2,688.7L57.2,688.7z M57,688.7l-0.1,0l0-0.2l0.2,0.1C57,688.6,57,688.7,57,688.7z"/>
      <path fill="none" d="M56.8,688.7l0.1,0c0,0,0-0.1,0-0.1l-0.2-0.1L56.8,688.7z"/>
      <polygon fill="none" points="56.9,688.1 56.9,688.1 56.9,687.8 57,688.1 57.1,688.1 57.1,688.1 57.1,688.1 56.9,687.6 56.7,687.5 
        56.7,687.5 56.7,688 56.9,688.1 		"/>
      <path fill="none" d="M52.5,695.9c2.4-1.7-0.3-5.4-2.6-3.7C47.4,693.9,50.2,697.7,52.5,695.9L52.5,695.9z M52,695.3l-1.5,0.2
        l-0.9-1.2l0.6-1.4l1.5-0.2l0.9,1.2L52,695.3z"/>
      <path fill="none" d="M50.5,695.4l1.5-0.2l0.6-1.4l-0.9-1.2l-1.5,0.2l-0.6,1.4L50.5,695.4L50.5,695.4z M51.1,694.7
        c-0.5,0-0.6-0.8-0.3-1C51.7,693.1,52.1,694.7,51.1,694.7L51.1,694.7z"/>
      <path fill="none" d="M50.7,694.4c0.4,0.6,1.3-0.1,0.9-0.7C51.1,693.2,50.3,693.8,50.7,694.4z"/>
      <path fill="none" d="M57.2,683.3c1.2,2.6,5.4,0.6,4-2C60,678.7,55.9,680.8,57.2,683.3z M59.6,683.8l-1.4-0.4l-0.3-1.5l1.1-1
        l1.4,0.5l0.3,1.5L59.6,683.8L59.6,683.8z"/>
      <path fill="none" d="M58.2,683.3l1.4,0.4l1.1-1l-0.3-1.5l-1.4-0.5l-1.1,1L58.2,683.3z M59.8,682.5c-0.1,0.3-0.4,0.4-0.7,0.4
        l-0.3-0.3C58.4,681.6,60,681.5,59.8,682.5z"/>
      <path fill="none" d="M59.5,682.8c1.1-0.8-1.1-1.7-0.7-0.3l0.3,0.3C59.3,682.9,59.4,682.9,59.5,682.8L59.5,682.8z"/>
      <path fill="none" d="M52.8,686.6c1.8-2.3-2-5.1-3.6-2.6C47.3,686.3,51.1,689,52.8,686.6L52.8,686.6z M52.4,685.4l-0.9,1.2
        l-1.5-0.1l-0.6-1.4l0.9-1.2l1.5,0.2C52,684.5,52.2,684.9,52.4,685.4z"/>
      <path fill="none" d="M51.6,686.6l0.9-1.2c-0.2-0.4-0.4-0.9-0.6-1.4l-1.5-0.2l-0.9,1.2l0.6,1.4L51.6,686.6L51.6,686.6z M51.4,685.6
        c-0.5,0.6-1.3,0-0.9-0.7C50.9,684.3,51.9,685.1,51.4,685.6L51.4,685.6z"/>
      <path fill="none" d="M51,685.8c0.7-0.1,0.6-1.2-0.2-1.1C50.2,684.8,50.3,686,51,685.8L51,685.8z"/>
      <path fill="none" d="M62.3,675.8l4.2-7.6c-3.1-1.3-6.6-2-10.1-1.9l1.1,8.6C59.2,674.9,60.8,675.2,62.3,675.8L62.3,675.8z"/>
      <path fill="none" d="M81,707.9l1.7,1.5c3.5-4.4,5.6-9.3,6.4-14.8l-2.2-0.2C86.1,699.4,84.1,703.9,81,707.9L81,707.9z"/>
      <path fill="none" d="M82.7,709.4c-3.7-3.3-8.7-7.8-12.4-11.1c-0.4-0.4-1.3-1.1-1.7-1.5c0,0-2.9-1-2.9-1l-1.8,1.6l2.7,3.3l0.8-0.7
        c3.3,3.3,9.4,9.2,12.6,12.4C81,711.4,81.8,710.4,82.7,709.4L82.7,709.4z"/>
      <path fill="none" d="M86.9,694.5l-3.8-0.3c-0.7,4.2-2.3,7.9-4.9,11.3l2.8,2.6C84.1,703.9,86.1,699.4,86.9,694.5L86.9,694.5z"/>
      <path fill="none" d="M75.8,703.3l2.3,2.1c2.6-3.4,4.2-7.1,4.9-11.3l-3.1-0.3C79.4,697.3,78,700.5,75.8,703.3L75.8,703.3z"/>
      <path fill="none" d="M80,693.9l-8.6-0.8c-0.4,1.6-1,3.1-2,4.5l0.9,0.8c1.7-0.7,3.1-0.9,4.1-0.7l1.4,5.7
        C78,700.5,79.4,697.3,80,693.9L80,693.9z"/>
      <path fill="none" d="M67.4,700c-0.4,0.3-1.1,0.9-1.5,1.2l0,0L67.4,700L67.4,700z"/>
      <polygon fill="none" points="68.3,700.8 67.4,700 65.9,701.2 66.6,702.2 68.3,700.8 		"/>
      <path fill="none" d="M65.9,701.2L65.9,701.2l0.6-0.6l-2.7-3.3L62,699l0.6,3c0.3,0.5,0.9,1.4,1.2,1.9c2.2,3.4,7.1,10.7,9.2,14
        c1.1-0.7,2.2-1.4,3.3-2.2C73.7,711.9,68.6,704.9,65.9,701.2L65.9,701.2z"/>
      <path fill="none" d="M63.9,703.8l-0.6-1c-1.5,0.7-3.1,1.1-4.8,1.2l-0.1,1.1C60.4,705,62.2,704.6,63.9,703.8L63.9,703.8z"/>
      <path fill="none" d="M68.3,700.8l-1.6,1.3l6.2,8.5c1-0.8,2-1.6,2.9-2.5L68.3,700.8L68.3,700.8z"/>
      <path fill="none" d="M75.8,703.3l-1.4-5.7c-1-0.2-2.4,0.1-4.1,0.7L75.8,703.3L75.8,703.3z"/>
      <path fill="none" d="M73.1,717.8l-1.2-1.9c-4.3,2.4-9,3.7-14.2,3.8l-0.1,2.2C63.2,721.8,68.4,720.5,73.1,717.8L73.1,717.8z"/>
      <path fill="none" d="M75,713.8l1.3,1.8c1.3-1,2.5-2,3.6-3.2l-1.6-1.6C77.3,711.9,76.2,712.9,75,713.8L75,713.8z"/>
      <path fill="none" d="M78.4,710.8l-2.7-2.7c-0.9,0.9-1.9,1.8-2.9,2.5l2.2,3.1C76.2,712.9,77.3,711.9,78.4,710.8L78.4,710.8z"/>
      <path fill="none" d="M71.8,715.9l-2.1-3.2c-3.6,2-7.6,3-11.9,3.1l-0.2,3.8C62.8,719.6,67.6,718.3,71.8,715.9L71.8,715.9z"/>
      <path fill="none" d="M69.7,712.7l-5.9-8.9c-1.7,0.8-3.5,1.2-5.4,1.4l-0.6,10.6C62.2,715.7,66.1,714.7,69.7,712.7L69.7,712.7z
         M63.5,708.7l0.9,4.3l-3.2,0.8c-0.6-1.7-1.4-5.4-1.1-7.3l1.5-0.4L63.5,708.7L63.5,708.7z"/>
      <path fill="none" d="M64.4,713l-0.9-4.3l-1.9-2.6l-1.5,0.4c-0.3,1.8,0.5,5.5,1.1,7.3L64.4,713L64.4,713z"/>
      <path fill="none" d="M55.4,659.5c5.1-0.3,10.2,0.6,14.5,2.7l1.1-2c-4.7-2.3-10.3-3.3-15.9-2.9L55.4,659.5z"/>
      <path fill="none" d="M55.4,659.5l-0.3-2.2c-1.3,0.1-2.6,0.2-3.9,0.4c0.9,5,2.3,12.4,3.2,17.5l-0.9,0.2l1.1,4.1l2.3-0.6
        c0.2-0.8,1-3.2,0.6-4C56.9,670.7,55.9,663.6,55.4,659.5L55.4,659.5z"/>
      <path fill="none" d="M51.6,659.9l-0.4-2.2c-1.6,0.3-3.2,0.7-4.7,1.2l0.8,2.1C48.6,660.5,50.1,660.1,51.6,659.9L51.6,659.9z"/>
      <path fill="none" d="M53.7,719.5l0.4-3.8c-1.3-0.1-2.6-0.4-3.8-0.7l-1,3.7C50.8,719.1,52.2,719.3,53.7,719.5L53.7,719.5z"/>
      <polygon fill="none" points="55,707.7 55,707 55,707.6 55,707.7 		"/>
      <path fill="none" d="M55,707.6c0.1-0.4,0.2-2,0.3-2.4l-2.1-0.3c0,0.1-0.1,0.5-0.2,0.6C53.8,705.9,54.6,706.8,55,707.6z"/>
      <path fill="none" d="M46.9,713.9L46.9,713.9L46.9,713.9z"/>
      <path fill="none" d="M45,719.7l0.7-2.2c-4.4-1.7-8.7-5.1-11.9-8.9l-1.8,1.3C35.5,714.1,40.1,717.8,45,719.7L45,719.7z"/>
      <path fill="none" d="M45.7,717.5c0,0,1.2-3.7,1.2-3.7c-3.7-1.5-7.3-4.4-10-7.4l-3.2,2.2C37,712.4,41.3,715.8,45.7,717.5
        L45.7,717.5z"/>
      <polygon fill="none" points="55.2,705.2 55.3,704 53.4,703.7 53.1,705 55.2,705.2 		"/>
      <path fill="none" d="M55.3,704L55.3,704c-0.5-0.1-1.4-0.3-1.9-0.4l0,0.1L55.3,704L55.3,704z"/>
      <polygon fill="none" points="53,705.1 52.9,705.5 53,705.1 		"/>
      <path fill="none" d="M46.9,692.1c-0.8,0-3.4-0.2-4,0.3c-4.9,1.8-12,4.4-16.8,6.2c0.4,1.3,0.8,2.6,1.4,3.9c4.7-2.1,11.7-5,16.4-7.1
        l0.4,0.8l3.8-1.9L46.9,692.1L46.9,692.1z"/>
      <path fill="none" d="M44.7,697.1c-4.4,2.8-10.7,6.9-15,9.7c0.7,1.1,1.5,2.1,2.3,3.1c4.2-2.9,10.4-7.3,14.6-10.3
        c0.8-0.2,2.1-2.5,2.5-3.2l-1.1-2.1l-3.8,1.9L44.7,697.1z"/>
      <path fill="none" d="M44.6,697.1L44.6,697.1c-0.2-0.4-0.6-1.3-0.8-1.7l-0.1,0L44.6,697.1z"/>
      <polygon fill="none" points="44.6,697.1 43.7,695.4 35.8,698.9 37.4,701.8 44.6,697.1 		"/>
      <path fill="none" d="M34.7,695.5l8.1-3c-0.1-1.2-0.3-3.7-0.1-4.9l-8.3-2.4C33.9,688.3,33.8,692.4,34.7,695.5z"/>
      <path fill="none" d="M28.1,697.9l3.6-1.3c-1-3.8-1.1-8.4-0.4-12.3l-3.7-1.1C26.7,687.9,26.8,693.4,28.1,697.9z"/>
      <path fill="none" d="M33,700.1l-3.5,1.5c0.6,1.3,1.3,2.6,2.1,3.9l3.2-2.1C34.1,702.4,33.5,701.3,33,700.1L33,700.1z"/>
      <path fill="none" d="M29.4,701.6l-2.1,0.9c0.7,1.5,1.4,2.9,2.3,4.2l1.9-1.2C30.8,704.3,30,703,29.4,701.6L29.4,701.6z"/>
      <path fill="none" d="M26,698.7l2.1-0.8c-1.3-4.5-1.4-10-0.5-14.7c-0.4-0.1-1.8-0.5-2.2-0.6C24.4,687.7,24.6,693.7,26,698.7z"/>
      <path fill="none" d="M44.2,682.7L44.2,682.7l-0.3,0.8l3.9,1.8l1-2.2c-0.5-0.6-1.9-2.7-2.7-2.9c-4.5-2.7-10.9-6.5-15.4-9.2h0
        c-0.8,1.1-1.5,2.2-2.1,3.4C33,676.7,39.6,680.3,44.2,682.7L44.2,682.7z"/>
      <path fill="none" d="M44.2,682.7L44.2,682.7l-0.8,1.7h0C43.6,684,44,683.2,44.2,682.7L44.2,682.7z"/>
      <path fill="none" d="M50.1,677.9l2.1,2.2l2.3-0.6l-1.1-4.1l-1,0.2c-1.8-4.9-4.3-11.9-6-16.7c-1.2,0.4-2.5,0.9-3.7,1.5
        C45,665.6,47.9,672.7,50.1,677.9z"/>
      <path fill="none" d="M46.8,679.2c0.9-0.9,1.8-1.6,2.8-2.2l-3.3-8c-2.9,1.5-5.8,4-7.8,6.8l7.4,4.5L46.8,679.2L46.8,679.2z"/>
      <path fill="none" d="M47.7,685.3l-3.9-1.8l-0.4,0.9c-4.9-1.7-12-4.1-16.9-5.7c-0.4,1.2-0.8,2.5-1.1,3.8c5,1.4,12.2,3.5,17.2,5
        c0.7,0.5,3.3,0,4.1,0L47.7,685.3L47.7,685.3z"/>
      <path fill="none" d="M43.4,684.5l0.8-1.8l-7.6-4.1c-0.5,1-1,2-1.3,3L43.4,684.5L43.4,684.5z"/>
      <path fill="none" d="M53.5,675.4l0.9-0.2c-0.6,0.1-1.2,0.3-1.9,0.5L53.5,675.4L53.5,675.4z"/>
      <path fill="none" d="M47.2,661l1.3,3.6c1.2-0.4,2.4-0.7,3.7-1l-0.7-3.8C50.1,660.1,48.6,660.5,47.2,661L47.2,661z"/>
      <path fill="none" d="M45.1,666l-1.5-3.6c-4.2,2.1-8.3,5.7-11.2,9.6l3.3,2C38.2,670.8,41.6,667.8,45.1,666L45.1,666z"/>
      <path fill="none" d="M54.4,675.2l-1.5-8.4c-1.1,0.2-2.2,0.5-3.2,0.8l2.9,8.1C53.1,675.4,53.7,675.3,54.4,675.2L54.4,675.2z"/>
      <path fill="none" d="M42.8,660.4c-4.6,2.3-9.1,6.2-12.2,10.5l1.9,1.1c2.8-3.9,7-7.5,11.2-9.6L42.8,660.4L42.8,660.4z"/>
      <path fill="none" d="M30.5,675.4l-2-1c-0.8,1.4-1.4,2.9-1.9,4.4l2.1,0.7C29.1,678.1,29.7,676.7,30.5,675.4L30.5,675.4z"/>
      <path fill="none" d="M28.6,679.5l3.6,1.2c0.4-1.2,1-2.4,1.6-3.5l-3.4-1.8C29.7,676.7,29.1,678.1,28.6,679.5z"/>
      <polygon fill="none" points="25.5,682.6 26,682.7 25.5,682.6 		"/>
      <path fill="none" d="M57.1,721.9h0.4c0.2-3.9,0.7-12.7,1-16.7c0-0.5,0.1-1.7,0.1-2.2l-1-2.9l-2.4-0.4l-0.9,4.2l1,0.2
        c-0.6,5.7-1.2,12-1.9,17.7C54.7,721.8,55.9,721.9,57.1,721.9L57.1,721.9z"/>
      <polygon fill="none" points="55,707.6 54.9,708.3 55,707.7 55,707.6 		"/>
      <path fill="none" d="M54.9,708.3c0.3-1.2-1-2.3-2-2.8l-0.1,0.4l-2.1,7.9c1.5,0.2,2.9-0.5,3.8-1.7L54.9,708.3L54.9,708.3z"/>
      <polygon fill="none" points="54.5,712.1 54.2,712.5 54.4,712.6 54.5,712.1 		"/>
      <path fill="none" d="M54.4,712.6l-0.3,0c-0.9,1-2.2,1.4-3.5,1.3l-0.3,1.2c1.2,0.3,2.5,0.5,3.8,0.7L54.4,712.6L54.4,712.6z"/>
      <path fill="none" d="M47.6,711.8l1.9-5.9l0.1-0.5C47.2,706.3,46.1,709.6,47.6,711.8L47.6,711.8z"/>
      <path fill="none" d="M49.5,705.9c-1.3,4.2-3.1,9.7-4.4,13.8c1.2,0.5,2.4,0.9,3.7,1.2c1.4-5.1,3.3-12.1,4.7-17.2l0.9,0.2l0.9-4.2
        l-2.4-0.5l-2,2.3C50.4,702.8,49.9,704.6,49.5,705.9z"/>
      <path fill="none" d="M47.6,711.8c-1.4-2.2-0.4-5.5,2.1-6.4c0.1-0.3,0.7-2,0.8-2.3l-5.4,1.5c0.5,2.3,1.5,7,2,9.3L47.6,711.8
        L47.6,711.8z"/>
      <polygon fill="none" points="52.9,705.5 52.8,705.9 52.9,705.5 		"/>
      <polygon fill="none" points="49.8,705 49.6,705.4 49.6,705.4 49.8,705 		"/>
      <path fill="none" d="M50.4,703.1l0.1-0.5c-1.4-0.7-2.8-1.8-3.9-3l-7.1,5c1.8,1.8,4.3,4.3,6.6,5.4l-1.1-5.4L50.4,703.1L50.4,703.1z
        "/>
      <polygon fill="none" points="49.6,705.4 49.6,705.4 49.5,705.9 49.6,705.4 		"/>
      <path fill="none" d="M69.9,740.8l0.2,5.4l4.6-0.9l-0.5-5.5C72.8,740.1,71.4,740.5,69.9,740.8L69.9,740.8z"/>
      <path fill="none" d="M74.7,745.2l-4.6,0.9l0.3,6.2c1.6-0.1,3.3-0.2,4.9-0.4L74.7,745.2L74.7,745.2z"/>
      <path fill="none" d="M57.8,735.8c-4.3,0-8.5-0.4-12.5-1.4l11.6,58.5c0.7,2.9,2.9,4,6.6,3.5C61,776.5,59.9,755.7,57.8,735.8
        L57.8,735.8z"/>
      <path fill="none" d="M222.2,560.8l-0.2-4c-2.6,10.2,1.4,21.4,4.9,31.1c-0.1-2.1,0.3-8.6-0.4-10.5c-1.9-4.5-2.9-9.9-4.4-17
        L222.2,560.8z"/>
      <path fill="none" d="M210.3,559.3l0.1,8.8l4.7,0.6c-0.3-17.8-0.8-34.8-1.5-51c-0.4-6.3-1.6-10.6-3.6-12.9v0l-0.8-0.9
        C209,511.7,210.2,540.9,210.3,559.3z"/>
      <polygon fill="none" points="210.3,568.2 210.3,559.3 153,548.6 153.3,560.1 210.3,568.2 		"/>
      <path fill="none" d="M215,568.8l-4.7-0.6c0,5.1,0.4,63.9,0.5,70.6c-0.5,2,1.6,16-1.6,15.8c-17.9,2.7-34.4,4.5-49.5,5.3
        c-5.2-0.1-8.1-3.9-8.4-11.4l-2.3-90.1c-21.9-2.3-45.3-2.8-69.9-2.6l0.9,63.1c0.6,6.4-5.1,4.5-8.7,3.6l0,3.3
        c28,3.5,52.3,26.8,54.9,55.5l11.3-0.6l-0.2-11h3.9l0.2,10.7c24.9-2.4,47.3-5.3,67.1-8.8c5-0.7,7.5-3.8,7.5-9.3
        C215.6,641.1,215.3,589.3,215,568.8L215,568.8z M137.5,645.1c-0.3-0.6-0.2-5.9-0.2-6.7c-0.2-1.3,2.9-0.5,3.6-0.7
        c1.5-0.4,0.4,7.3,0.7,7.8c4.6,2.1,4.6,9.1,0,11.1v4.5c-0.3,0.6,0.7,7.2-0.7,6.9c-0.6-0.2-3.8,0.6-3.7-0.7
        c0.2-2.5-0.6-7.8,0.5-10.1C131.4,656.3,131.2,646.4,137.5,645.1L137.5,645.1z"/>
      <path fill="none" d="M137.3,644.1c0.8,1.6,0.5,11.1,0.5,13c-0.7,1-0.7,3-0.6,4.1h4.4c0-2.9,0-12.5,0-15.6
        c-0.3-0.6,0.8-8.2-0.7-7.8c-0.6,0.2-3.7-0.6-3.6,0.7C137.3,638.5,137.3,644.1,137.3,644.1z"/>
      <path fill="none" d="M143.3,646.8c-0.5-0.5-1.1-0.9-1.7-1.2v11.1C145.4,655,146.3,649.6,143.3,646.8L143.3,646.8z"/>
      <path fill="none" d="M137.8,657.1c0-0.9,0.2-11.4-0.3-11.9c-6.4,1.3-6.2,11.1,0.2,12.1L137.8,657.1L137.8,657.1z"/>
      <path fill="none" d="M141.6,667.4v-6.2h-4.4c0.3,0.6-0.7,7.2,0.7,6.9C138.6,667.9,141.8,668.7,141.6,667.4L141.6,667.4z"/>
      <path fill="none" d="M221,560.6v0.8c2.3-1.2-2.2-55.4-1.9-58.3c-0.2-8.7-3.7-17.3-9.4-24.2l-0.5,13.5
        C220.8,500.9,218.8,545.7,221,560.6z"/>
      <path fill="none" d="M221,561.4c-2.1-15.5-0.1-60.1-11.7-69l-0.1,4.6c13.3,14.6,7.7,55.8,10.1,81.3c0,0,0.9,98.8,0.9,98.8l6.9-1
        C227.5,620.2,227.6,598.6,221,561.4L221,561.4z"/>
      <polygon fill="none" points="210.4,576.5 210.3,568.2 153.3,560.1 153.6,571 210.4,576.5 		"/>
      <path fill="none" d="M209.1,504l0-7l-61.6-70.9c-0.1,2.6-0.2,5.3-0.2,8.2l0.2,0.1C162.2,450.9,194.1,487,209.1,504L209.1,504z"/>
      <path fill="none" d="M209.2,508.6l0-4.6l-38.4-43.4c1.4,3,1.8,6.2,1.8,9.8C178.9,470.5,204.5,504.6,209.2,508.6L209.2,508.6z"/>
      <path fill="none" d="M209.3,492.4c-10.9-13.1-50.5-60.7-61.1-73.4l-0.7,7.1l61.6,70.9L209.3,492.4L209.3,492.4z"/>
      <path fill="none" d="M207.7,490.5l1.5,1.9l0.5-13.5l0.1-0.8l-1.5-2.2C208.3,476.3,207.7,489.7,207.7,490.5z"/>
      <polygon fill="none" points="204.5,486.8 207.7,490.5 208.3,477.1 205.9,474.1 		"/>
      <path fill="none" d="M148.8,558.2l0.2,0.1l-3.9-124.6c-2.9-0.8-6.1-1.4-9.5-2c0.2,4.1,0.2,13.5,0.2,17.7h-3.4
        c0.1-4.5-0.1-13.6-0.3-18.2c-21.3-2.2-36.5-1.7-62.6,1.3l0.1,5.7c5.2-0.2,7.8,1,7.8,3.6L79,555.7
        C103.7,555.4,126.9,556.3,148.8,558.2L148.8,558.2z M138.2,519.2c1.5-0.4,0.4,7.3,0.7,7.8c4.6,2.1,4.6,9.1,0,11.1v4.5
        c-0.3,0.6,0.7,7.2-0.7,6.9c-0.6-0.2-3.8,0.6-3.7-0.7c0.2-2.5-0.6-7.8,0.6-10.1c-6.4-1-6.6-10.8-0.3-12.1c-0.3-0.6-0.2-5.9-0.2-6.7
        C134.5,518.6,137.6,519.4,138.2,519.2L138.2,519.2z"/>
      <path fill="none" d="M138.9,519.9c0.2-1.3-3-0.5-3.6-0.7c-1.4-0.3-0.4,5.7-0.7,6.3c0.1,0.6,0.4,1.9,0.5,2.5
        c-0.1,0.2,0.1,10.5-0.1,10.6c-0.6,0.9-0.6,3-0.6,4h4.4C138.9,536.3,138.9,526.2,138.9,519.9z"/>
      <path fill="none" d="M140.6,528.2c-0.5-0.5-1.1-0.9-1.7-1.2v11.1C142.7,536.4,143.6,531.1,140.6,528.2L140.6,528.2z"/>
      <path fill="none" d="M138.2,549.5c1.4,0.4,0.4-6.3,0.7-6.9h-4.4v6.2C134.4,550.1,137.6,549.3,138.2,549.5L138.2,549.5z
         M136.8,546.9c-1.6,0-1.6-2.5,0-2.5C138.4,544.4,138.4,546.9,136.8,546.9L136.8,546.9z"/>
      <path fill="none" d="M135.9,546.5c1.1,1.2,2.9-0.6,1.8-1.8C136.5,543.6,134.7,545.4,135.9,546.5L135.9,546.5z"/>
      <path fill="none" d="M131.9,537c0.9,0.9,2,1.5,3.2,1.7c0.1-1.1,0.2-11.3-0.3-12.1C130.3,527.5,128.4,533.8,131.9,537L131.9,537z"
        />
      <path fill="none" d="M153.3,560.1l-2.2-0.7c0,0,2.6,86.1,2.6,86.2c0.4,7.2,3.3,10.9,8.8,11.3c8.4-0.9,48.8,0,48.4-5.6
        c-0.3,1.1-1,1.5-2,1.5c-17.3,1.8-32.7,2.5-46,2c-4.6-0.2-6.9-3.4-7-9.7C155.3,628.4,153.8,576.9,153.3,560.1L153.3,560.1z"/>
      <path fill="none" d="M149,558.4c0.4,0.2,1.7,1,2.1,1.1l-3.7-125.1l-2.2-0.6L149,558.4z"/>
      <path fill="none" d="M150.5,559.2l-1.5-0.9l2.3,90.1c0.4,7.5,3.2,11.3,8.4,11.4c3.7-1.1,54.8-1.7,51.2-8.8
        c1.6,5.9-40.5,4.9-48.4,5.8c-5.4-0.4-8.4-4.1-8.8-11.3l-2.6-86.1L150.5,559.2L150.5,559.2z"/>
      <polygon fill="none" points="151.1,559.4 150.5,559.2 151.1,559.5 151.1,559.4 		"/>
      <polygon fill="none" points="210.4,576.5 153.6,571 155.4,631.9 210.8,637.1 210.4,576.5 		"/>
      <polygon fill="none" points="210.8,638.7 210.8,637.1 155.4,631.9 155.5,633.9 210.8,638.7 		"/>
      <path fill="none" d="M209.5,652.7c0.6-0.2,1.3-0.8,1.3-1.7c0,0-0.1-12.3-0.1-12.3l-55.3-4.8c0.5,5.8-1.4,20.2,6.3,20.7
        C175.8,655.4,191.6,654.5,209.5,652.7L209.5,652.7z"/>
      <polygon fill="none" points="141.3,669.7 137.3,669.7 137.5,680.6 141.4,680.4 141.3,669.7 		"/>
      <path fill="none" d="M143.3,300.2c-2.1-9.6-3.4-15.8-11.6-18.6c-0.1,8.1,0,20.2,0.6,28.3c4.2,0.9,6.6,4.4,7.3,10.5
        c3.1,33.9,4.7,74.5,5.6,113.3l2.2,0.6c-0.1-4.9,0.3-10.6,0.8-15.3c3.4-36.8-3.7-74.7-6.3-110.2c4,18.4,7.3,49.5,10,93.4l1.5-10.3
        C151.4,358.9,148.2,323,143.3,300.2L143.3,300.2z"/>
      <path fill="none" d="M151.7,403.2c-2.1-43.4-6.1-76.2-9.8-94.4c3.5,32,6.2,64.9,7.9,98.4C150.7,405.9,151.3,404.6,151.7,403.2
        L151.7,403.2z"/>
      <path fill="none" d="M151.2,404.7c-1.7,1.5-2.6,12.1-3,14.3l10.4,12.5c-1.2-1.9-1.4-3.5-0.7-5l2.3-10.3L151.2,404.7L151.2,404.7z"
        />
      <polygon fill="none" points="172.4,470.3 172.5,470.3 172.5,470 		"/>
      <path fill="none" d="M172.4,470.3c0.3-11.3-3.9-13.9-13.8-16.7l0,21.1C162.5,474.1,172.5,476.9,172.4,470.3L172.4,470.3z"/>
      <path fill="none" d="M157.9,426.5c-5.7,1.7,46.5,58.4,46.7,60.3l1.4-12.7l-45.8-58L157.9,426.5z"/>
      <path fill="none" d="M135.6,431.8c3.4,0.5,6.6,1.2,9.5,2c-0.9-38.9-2.5-79.4-5.6-113.3c-0.7-6.2-3.1-9.7-7.3-10.5
        C132.8,319.8,133.8,357.4,135.6,431.8z"/>
      <polygon fill="none" points="135.8,438.8 135.6,431.8 132.1,431.3 132.4,440.5 132.4,438.8 135.8,438.8 		"/>
      <path fill="none" d="M164.7,455.8c0.8,0.3,2.1,0.7,2.8,1.2l-17.8-20.1l1,33.6c0.3-3.3,0.4-9.6,0.2-12.9c-0.1-4.2,4.4-5.8,6.9-6.9
        C159.9,450.4,162.2,452,164.7,455.8L164.7,455.8z"/>
      <path fill="none" d="M164.7,455.8c-4.5-7.1-7.9-5.7-12.9-1.2c-1.4,1.1-0.7,6.6-0.7,8.3l0.6-5.2c-0.4-1.2,6.2-6.8,7-4L164.7,455.8
        L164.7,455.8z"/>
      <path fill="none" d="M158.6,453.6c-0.8-2.7-7.4,2.9-7,4c0,0-0.6,5.2-0.6,5.2c0,1.1-0.3,7.7-0.3,8.6c0,4.1,5,2.8,7.8,3.3
        L158.6,453.6L158.6,453.6z"/>
      <path fill="none" d="M150.7,471.9c0,0,0,0.1,0,0.1l0-0.5V471.9z"/>
      <path fill="none" d="M150.7,471.5l0-1.5C150.7,470.5,150.7,471.1,150.7,471.5z"/>
      <polygon fill="none" points="150.7,471.5 150.7,471 150.7,471.9 150.7,471.5 		"/>
      <path fill="none" d="M135.8,449.5v-10.7h-3.4c0,1,0,9.5,0,10.7H135.8L135.8,449.5z"/>
      <path fill="none" d="M112.8,430.1c7,0.1,13.5,0.5,19.3,1.2L129,310.8l-23.6-1.9l0.3,3.7c37.5,35.5,17.1,105.4-36.1,109.6v10.3
        C87.2,430.5,101.6,429.7,112.8,430.1L112.8,430.1z M130.3,420.6c0.5,5.2-2.1,4.8-6.4,4.7c-1.8,0-6,0-7.9,0
        c-4,0.5-1.6-13.1-2.2-15.3c-0.3-3.4,0.6-6.1,4.1-8.3l0,0c1.1-0.8,2.1-1.4,3.1-1.8v-0.2c3.6-1.1,8.7-1.6,8.9,3.4
        C129.8,403.2,130.3,420.6,130.3,420.6z"/>
      <path fill="none" d="M130.2,421.2l0-0.6l-0.4-17.4c-0.1-4.6-5-4.9-8.4-3.4h2.4v1.3c3-0.5,6,0.7,6,4.1
        C129.8,405.2,130.2,421.2,130.2,421.2z"/>
      <path fill="none" d="M128.8,422.6c-0.1,1.9-3.7,1.2-5,1.3v1.4c3.4,0.1,7.3,0.3,6.4-4.1c0,0-0.4-16-0.4-16c-0.1-3.5-3-4.6-6-4.1
        v1.5c2.1-0.4,4.5,0.1,4.6,2.7C128.4,405.2,128.8,422.6,128.8,422.6z"/>
      <path fill="none" d="M128.7,423.1l0.1-0.5l-0.4-17.3c-0.1-2.6-2.4-3.2-4.6-2.7v21.4C124.9,423.8,128.2,424.3,128.7,423.1
        L128.7,423.1z"/>
      <path fill="none" d="M120.9,425.3h2.9c0-3,0-22.8,0-25.6c-0.4,0-2.6,0-2.9,0C120.9,403.4,120.9,421.5,120.9,425.3z"/>
      <path fill="none" d="M120.9,425.3v-1.4h-4.8c-0.9-0.1-0.9-1.3-0.9-1.9c0.5-6.5-2.6-15.4,5.7-18.5v-1.6c-1,0.5-2,1.1-3.1,1.8l0,0
        c-6.3,2.9-3.5,13.1-4.1,18.2C113,426.6,118.3,425.1,120.9,425.3L120.9,425.3z"/>
      <path fill="none" d="M115.3,423.2c0.2,1.2,4.9,0.4,5.6,0.7v-20.4c-2.6,1.3-5.9,3.7-5.7,6.6C115.2,410.8,115,423.1,115.3,423.2z"/>
      <path fill="none" d="M117.8,403.7L117.8,403.7c1-0.7,2.1-1.3,3.1-1.8v-2c-1,0.4-2,1-3.1,1.8l0,0c-3.5,2.1-4.3,4.9-4.1,8.3
        C113.8,407.5,115.1,405.4,117.8,403.7L117.8,403.7z"/>
      <polygon fill="none" points="69.6,432.6 69.6,422.2 61.5,423.9 61.5,411.5 58.8,412.2 59.4,433.2 69.6,432.6 		"/>
      <path fill="none" d="M77.4,441.8c0.1-2.6-2.5-3.8-7.8-3.6l1,117.5H79L77.4,441.8L77.4,441.8z"/>
      <path fill="none" d="M68.6,443.3l1-5c-6.3-0.1-9.5,2.1-9.8,6.8c-0.1,25.8,0.2,84.4,0.5,110.7h2.4l-1-108.4
        C62.1,444.3,64.4,442.9,68.6,443.3L68.6,443.3z"/>
      <polygon fill="none" points="69.6,438.3 68.6,443.3 69.5,555.7 70.7,555.7 69.6,438.3 		"/>
      <path fill="none" d="M69.6,438.3l-0.1-5.7c-2.4,0.1-11.3,0.6-13.6,0.8l0.9,122.4h3.4c-0.2-26.5-0.6-84.7-0.5-110.7
        C60.1,440.4,63.4,438.1,69.6,438.3L69.6,438.3z"/>
      <path fill="none" d="M69.6,422.2c74-8.7,73.1-117.5-0.4-126.7l1.2,5.3c60.2,12.7,61.7,99.3,2.5,114.9
        c59.2-15.6,57.7-102.2-2.5-114.9l0.4,5.1c54,14,52.7,90-1.2,103.2V422.2z"/>
      <path fill="none" d="M95.5,394.4c28.4-26.6,15-78.1-22.4-87.9c-3-0.8-6.2-1.6-9.2-2.3c0,0-9.1-3.9-9.1-3.9l-0.6-0.3l-0.6,11.4
        c68-2.2,64.9,98.2-2.8,91.9v10.8C66.7,410,83.3,407.1,95.5,394.4L95.5,394.4z"/>
      <path fill="none" d="M70,402.1c59.7-19.8,31.9-106.8-28.3-88.2C-13.8,335.5,12.3,416.8,70,402.1L70,402.1z M89.5,358.8
        c-0.1,20.2-21.3,35.7-40.7,30.1C27.7,384,17.5,356.4,30.6,339h0C48.7,312.7,91,327.1,89.5,358.8z"/>
      <path fill="none" d="M81,376l1.7,1.5c3.5-4.4,5.6-9.3,6.4-14.8l-2.2-0.2C86.1,367.5,84.1,372,81,376L81,376z"/>
      <path fill="none" d="M82.7,377.5c-3.7-3.2-8.7-7.8-12.4-11.1c-0.4-0.4-1.2-1.1-1.7-1.5c0,0-2.9-1-2.9-1l-1.8,1.6l2.7,3.2l0.8-0.7
        c3.3,3.3,9.4,9.2,12.6,12.4C81,379.5,81.8,378.5,82.7,377.5L82.7,377.5z"/>
      <polygon fill="none" points="87.2,358.7 89.5,358.8 89.3,353.9 87.1,354.2 		"/>
      <polygon fill="none" points="83.4,358.5 87.2,358.7 87.1,354.2 83.3,354.7 		"/>
      <path fill="none" d="M86.9,362.5l-3.8-0.3c-0.7,4.2-2.3,7.9-4.9,11.3L81,376C84.1,372,86.1,367.5,86.9,362.5L86.9,362.5z"/>
      <path fill="none" d="M75.8,371.4l2.3,2.1c2.6-3.4,4.2-7.1,4.9-11.3l-3.1-0.3C79.4,365.4,78,368.5,75.8,371.4L75.8,371.4z"/>
      <path fill="none" d="M80,361.9l-8.6-0.8c-0.4,1.6-1,3.1-2,4.5l0.9,0.8c1.7-0.7,3.1-0.9,4.1-0.7l1.4,5.7
        C78,368.5,79.4,365.4,80,361.9L80,361.9z"/>
      <path fill="none" d="M68.6,364.9l0.8,0.7c0.9-1.4,1.6-2.9,2-4.5c-0.8,0.2-3.1-1.2-3.8-1.5c0-1.2-0.1-3.6-0.2-4.8
        c0.7-0.4,2.8-1.9,3.7-1.8c-0.5-1.5-1.3-3-2.3-4.3c-0.3,0.7-2.8,1.7-3.5,2c-1-0.7-2.9-2.2-3.9-2.9c0.1-0.8,0.2-3.4,0.9-4
        c-1.5-0.6-3.1-0.9-4.8-0.9c0.4,0.7-0.4,3.2-0.6,4c-1.2,0.3-3.5,0.9-4.7,1.2c-0.5-0.6-2.5-2.3-2.6-3.1c-1.4,0.8-2.7,2-3.7,3.2
        c0.8,0.2,2.3,2.4,2.7,2.9c-0.5,1.1-1.5,3.3-1.9,4.4c-0.8,0.1-3.4,0.5-4.1,0c-0.2,1.2,0,3.7,0.1,4.9c0.6-0.5,3.3-0.3,4-0.3
        c0.6,1.3,1.6,3,2.3,4.3c-0.4,0.7-1.7,3-2.5,3.2c1.2,1.2,2.5,2.3,3.9,3c0-0.9,1.8-2.8,2.3-3.4c1.2,0.2,3.6,0.7,4.8,0.9
        c0.3,0.8,1.2,3.2,0.9,4c1.7-0.1,3.3-0.6,4.8-1.2c-0.7-0.5-1-3.1-1.2-3.9c0.9-0.8,2.7-2.4,3.7-3.2L68.6,364.9L68.6,364.9z
         M66.2,359c-2,2.1-5.3-1.1-3.2-3.2C65,353.6,68.3,356.9,66.2,359L66.2,359z M60.3,366.8c-2.8,0.9-4.2-3.4-1.4-4.3
        C61.7,361.6,63.1,365.9,60.3,366.8L60.3,366.8z M60,360.4c-3.6,3.8-9.6-2.1-5.7-5.8C57.9,350.9,63.8,356.8,60,360.4L60,360.4z
         M53.4,362.5c-0.4,2.9-4.9,2.1-4.4-0.7C49.3,358.9,53.9,359.6,53.4,362.5z M58.5,352.5c-2.8-0.9-1.4-5.2,1.4-4.3
        C62.8,349.1,61.3,353.5,58.5,352.5L58.5,352.5z M53.2,353c0.5,2.9-4,3.6-4.5,0.7C48.2,350.8,52.7,350,53.2,353z"/>
      <path fill="none" d="M64.6,359.6c2.9,0.1,2.9-4.6,0-4.5C61.7,355.1,61.7,359.7,64.6,359.6L64.6,359.6z M66,356.7v1.5l-1.3,0.8
        l-1.3-0.8v-1.5l1.3-0.8L66,356.7L66,356.7z"/>
      <path fill="none" d="M66,358.2v-1.5l-1.3-0.8l-1.3,0.8v1.5l1.3,0.8L66,358.2L66,358.2z M64.6,356.8c0.5,0,0.7,0.6,0.4,1l-0.4,0.2
        l-0.4-0.2C63.9,357.4,64.1,356.8,64.6,356.8L64.6,356.8z"/>
      <path fill="none" d="M65,357c-0.6-0.5-1.3,0.2-0.8,0.8l0.4,0.2l0.4-0.2C65.2,357.6,65.2,357.2,65,357L65,357z"/>
      <path fill="none" d="M58.6,366.7c2.6,1.4,4.7-2.8,2.1-4C58.1,361.2,56,365.4,58.6,366.7L58.6,366.7z M61,364.2l-0.3,1.5l-1.4,0.4
        l-1.1-1l0.3-1.4l1.4-0.5L61,364.2L61,364.2z"/>
      <path fill="none" d="M60.7,365.7l0.3-1.5l-1.1-1l-1.4,0.5l-0.3,1.4l1.1,1L60.7,365.7L60.7,365.7z M60.2,364.5l0,0.4
        C58.6,366.2,59,362.9,60.2,364.5z"/>
      <path fill="none" d="M60.1,364.9c0.3-0.7-0.6-1.2-1-0.5C58.9,365.1,59.7,365.5,60.1,364.9L60.1,364.9z"/>
      <path fill="none" d="M57.1,361.6c5.2,0.1,5.3-8.2,0-8.1C51.8,353.4,51.8,361.8,57.1,361.6L57.1,361.6z M58.7,358.6
        c-1.8,6.7-5.3-3.9-1.1-4C58.7,355.4,58.8,357.3,58.7,358.6z"/>
      <path fill="none" d="M58.5,359.5c0.3-0.9,0.4-2.1,0.2-3.1c-0.8-3.6-3.3-1.1-3,1.4C55.4,359.8,57.5,362.2,58.5,359.5L58.5,359.5z
         M58.6,358.6c-0.3,2.5-2.3,2.9-2.7,0.2c-0.6-1.7,0.9-6.1,2.5-3.1C58.7,356.5,58.7,357.7,58.6,358.6z"/>
      <path fill="none" d="M58.4,359.5c0.5-1.3,0.5-5.4-1.7-4.7C54.7,356,56.3,363.6,58.4,359.5L58.4,359.5z M57.7,359.3v-0.3l0,0
        l0.2,0.1v0.5l0,0l-0.2-0.1l-0.2-0.6h0l0,0l0.1,0L57.7,359.3z M58.2,358.7c0,0.2-0.2,0.2-0.3,0.2c-0.4-0.1-0.6-0.5-0.5-0.8l0,0
        c0,0,0.8,0.3,0.8,0.3L58.2,358.7z M57.2,359.2L57.2,359.2l-0.2-0.1c0,0,0-0.2,0-0.2l-0.1-0.1v0.2c0,0-0.2,0-0.2,0v-0.2l-0.1,0
        l0,0.3c0,0-0.2,0-0.2-0.1v-0.5c0.1,0,0.7,0.3,0.8,0.3V359.2z M57.2,359.6l0,0.2l-0.2,0.1l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.3l0.3-0.1
        l-0.3-0.1V359c0.1,0,0.7,0.3,0.8,0.2V359.6z M58.2,356.1v0.3h0v0c0,0-0.6-0.2-0.6-0.2c0,0.1,0,0.2,0,0.2c0,0-0.2-0.1-0.2-0.1
        l0-0.5l0,0v0C57.6,355.9,57.9,356.1,58.2,356.1L58.2,356.1z M57.1,356.1L57.1,356.1L57.1,356.1l-0.1,0l-0.1-0.3v0.2
        c0,0-0.2-0.1-0.2-0.1v-0.5l0,0l0.2,0.1L57.1,356.1z M58.2,357.1L58.2,357.1c-0.1,0-0.8,0-0.8,0v-0.2c0,0,0.1,0,0.1,0l0-0.3l-0.1,0
        v-0.3l0.8,0.5h0V357.1z M58.2,357.9L58.2,357.9l0,0.2l0,0l-0.8-0.3l0-0.2l0,0l0.4,0c-0.1,0-0.3-0.1-0.4-0.1v-0.2l0.8,0.3l0,0.3
        l-0.4,0L58.2,357.9L58.2,357.9z M57.1,358.3L57.1,358.3l0.1,0.3l-0.8-0.6h0v-0.2l0,0c0,0,0.8,0,0.8,0v0.3c0,0-0.4,0-0.5,0
        L57.1,358.3L57.1,358.3z M57.2,357.4l-0.1,0.3c-0.4,0.2-0.9,0-0.8-0.5C56.6,356.8,57.2,357,57.2,357.4z M57.2,356.4
        c0,0.2,0.1,0.6-0.2,0.6l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.2l0.3-0.1l-0.3-0.1v-0.2l0,0C56.5,356.1,57.1,356.4,57.2,356.4L57.2,356.4z
        "/>
      <polygon fill="none" points="57.7,359.1 57.7,359.3 57.6,359 57.5,359 57.5,359 57.5,359 57.7,359.6 57.9,359.6 57.9,359.6 
        57.9,359.1 57.7,359 57.7,359.1 		"/>
      <path fill="none" d="M58.1,358.8c0.2-0.1,0.1-0.4,0.1-0.5c0,0-0.8-0.3-0.8-0.3l0,0c0,0.4,0.1,0.8,0.5,0.8L58.1,358.8L58.1,358.8z
         M58,358.6c-0.2,0.1-0.5,0-0.5-0.3l0.5,0.2L58,358.6z"/>
      <path fill="none" d="M57.9,358.6L57.9,358.6l0.1-0.1l-0.5-0.2C57.5,358.5,57.7,358.7,57.9,358.6L57.9,358.6z"/>
      <path fill="none" d="M57.2,359.2L57.2,359.2l0-0.5c-0.1,0-0.7-0.3-0.8-0.3v0.5c0,0,0.2,0.1,0.2,0.1l0-0.3l0.1,0v0.2
        c0,0,0.2,0,0.2,0v-0.2l0.1,0.1c0,0,0,0.2,0,0.2L57.2,359.2L57.2,359.2z"/>
      <path fill="none" d="M57.2,359.8c0-0.1,0-0.4,0-0.5c-0.1,0-0.7-0.2-0.8-0.2v0.2l0.3,0.1l-0.3,0.1v0.3l0.3-0.2l0.1,0.2l0.2,0.1
        L57.2,359.8L57.2,359.8z M57,359.6l-0.1,0l0-0.1l0.2,0.1L57,359.6z"/>
      <polygon fill="none" points="56.9,359.6 57,359.6 57,359.5 56.8,359.4 		"/>
      <path fill="none" d="M58.2,356.4v-0.3c-0.2,0-0.6-0.2-0.8-0.3v0l0,0l0,0.5c0,0,0.2,0.1,0.2,0.1c0-0.1,0-0.2,0-0.2
        C57.6,356.2,58.2,356.4,58.2,356.4L58.2,356.4L58.2,356.4L58.2,356.4z"/>
      <path fill="none" d="M57.1,356.1L57.1,356.1l-0.2-0.6l-0.2-0.1l0,0v0.5c0,0,0.2,0.1,0.2,0.1v-0.2L57.1,356.1L57.1,356.1
        L57.1,356.1L57.1,356.1z"/>
      <path fill="none" d="M58.2,357.1L58.2,357.1l0-0.3h0l-0.8-0.5v0.3l0.1,0l0,0.3c0,0-0.1,0-0.1,0v0.2
        C57.4,357.1,58.1,357.2,58.2,357.1L58.2,357.1z M57.7,356.8l0.3,0.1h-0.3V356.8L57.7,356.8z"/>
      <polygon fill="none" points="57.9,356.9 57.7,356.8 57.7,356.9 57.9,356.9 		"/>
      <path fill="none" d="M58.2,357.9c0,0-0.4-0.1-0.4-0.1l0.4,0l0-0.3l-0.8-0.3v0.2c0.1,0,0.3,0.1,0.4,0.1l-0.4,0l0,0l0,0.2l0.8,0.3
        l0,0V357.9L58.2,357.9z"/>
      <path fill="none" d="M57.2,358.3c-0.1,0-0.4-0.2-0.5-0.3c0,0,0.4,0,0.5,0v-0.3c0,0-0.8,0-0.8,0l0,0v0.2h0l0.8,0.6V358.3
        L57.2,358.3z"/>
      <path fill="none" d="M57.2,357.7c0.2-0.5-0.5-1-0.8-0.5C56.2,357.6,56.8,357.9,57.2,357.7L57.2,357.7z M57,357.5l-0.2,0
        c-0.1,0-0.2-0.1-0.3-0.2C56.5,357.1,57.1,357.2,57,357.5z"/>
      <path fill="none" d="M56.8,357.5l0.2,0c0.1-0.3-0.5-0.4-0.5-0.1C56.6,357.4,56.7,357.5,56.8,357.5L56.8,357.5z"/>
      <path fill="none" d="M57.2,356.7v-0.4c-0.1,0-0.7-0.2-0.8-0.3l0,0v0.2l0.3,0.1l-0.3,0.1v0.2l0.3-0.2l0.1,0.2L57,357
        C57.1,356.9,57.2,356.9,57.2,356.7L57.2,356.7z M57,356.6L57,356.6l-0.1,0l0-0.2L57,356.6L57,356.6z"/>
      <path fill="none" d="M57,356.7c0,0,0-0.1,0-0.1l-0.2-0.1l0,0.2L57,356.7L57,356.7z"/>
      <path fill="none" d="M52.5,364c2.4-1.7-0.3-5.4-2.6-3.6C47.4,362,50.2,365.7,52.5,364L52.5,364z M52,363.3l-1.5,0.1l-0.9-1.2
        l0.6-1.4l1.5-0.1l0.9,1.2L52,363.3z"/>
      <path fill="none" d="M50.5,363.5l1.5-0.1l0.6-1.4l-0.9-1.2l-1.5,0.1l-0.6,1.4L50.5,363.5L50.5,363.5z M51.7,362.2
        c-0.2,0.7-1.2,0.5-1.1-0.2C50.7,361.3,51.8,361.5,51.7,362.2z"/>
      <path fill="none" d="M51.5,362.6c0.6-0.4-0.1-1.3-0.7-0.9C50.3,362.1,50.9,363,51.5,362.6L51.5,362.6z"/>
      <path fill="none" d="M57.2,351.4c1.3,2.6,5.4,0.6,4-2C60,346.7,55.9,348.8,57.2,351.4z M59.6,351.8l-1.4-0.5l-0.3-1.5l1.1-1
        l1.4,0.5l0.3,1.5L59.6,351.8L59.6,351.8z"/>
      <path fill="none" d="M58.2,351.4l1.4,0.5l1.1-1l-0.3-1.5l-1.4-0.5l-1.1,1L58.2,351.4z M59.5,350.9c-0.1,0.1-0.3,0.1-0.4,0.1
        l-0.3-0.3c-0.3-1,1.2-1.1,1-0.1L59.5,350.9L59.5,350.9z"/>
      <path fill="none" d="M59.1,350.9c0.4,0.1,0.6-0.2,0.7-0.4c0.2-1-1.4-0.9-1,0.1L59.1,350.9L59.1,350.9z"/>
      <path fill="none" d="M52.8,354.6c1.8-2.3-2-5.1-3.6-2.6C47.3,354.3,51.1,357,52.8,354.6L52.8,354.6z M52.4,353.4l-0.9,1.2
        l-1.5-0.1l-0.6-1.4l0.9-1.2l1.5,0.1L52.4,353.4z"/>
      <path fill="none" d="M51.6,354.6l0.9-1.2l-0.6-1.4l-1.5-0.1l-0.9,1.2l0.6,1.4L51.6,354.6L51.6,354.6z M51.5,353.2
        c0.1,0.5-0.5,0.8-0.9,0.5C49.8,353.1,51.3,352.2,51.5,353.2z"/>
      <path fill="none" d="M51.4,353.6c0.5-0.6-0.5-1.3-0.9-0.7C50.1,353.6,50.9,354.2,51.4,353.6L51.4,353.6z"/>
      <polygon fill="none" points="68.3,368.9 67.4,368 65.9,369.3 66.6,370.2 68.3,368.9 		"/>
      <path fill="none" d="M67.4,368c-0.4,0.3-1.1,0.9-1.5,1.2l0,0L67.4,368L67.4,368z"/>
      <path fill="none" d="M65.9,369.3L65.9,369.3l0.6-0.6l-2.7-3.2L62,367l0.6,3c0.3,0.5,0.9,1.4,1.2,1.9c2.2,3.4,7,10.7,9.2,14
        c1.1-0.7,2.2-1.4,3.3-2.2C73.7,379.9,68.6,373,65.9,369.3L65.9,369.3z"/>
      <path fill="none" d="M63.9,371.8l-0.6-1c-1.5,0.7-3.1,1.1-4.8,1.2l-0.1,1.1C60.4,373.1,62.2,372.6,63.9,371.8L63.9,371.8z"/>
      <path fill="none" d="M68.3,368.9l-1.6,1.4l6.2,8.5c1-0.8,2-1.6,2.9-2.5L68.3,368.9L68.3,368.9z"/>
      <path fill="none" d="M74.4,365.6c-1-0.2-2.4,0.1-4.1,0.7l5.6,5L74.4,365.6L74.4,365.6z"/>
      <path fill="none" d="M75,381.8l1.3,1.8c1.3-0.9,2.5-2,3.6-3.2l-1.6-1.6C77.3,379.9,76.2,380.9,75,381.8L75,381.8z"/>
      <path fill="none" d="M73.1,385.8l-1.2-1.9c-4.3,2.4-9,3.7-14.2,3.7l-0.1,2.2C63.2,389.9,68.4,388.5,73.1,385.8L73.1,385.8z"/>
      <path fill="none" d="M78.4,378.8l-2.7-2.7c-0.9,0.9-1.9,1.8-2.9,2.5l2.2,3.1C76.2,380.9,77.3,379.9,78.4,378.8L78.4,378.8z"/>
      <path fill="none" d="M71.8,383.9l-2.1-3.2c-3.6,2-7.6,3-11.9,3.1l-0.2,3.8C62.8,387.6,67.6,386.4,71.8,383.9L71.8,383.9z"/>
      <path fill="none" d="M69.7,380.7l-5.9-8.9c-1.7,0.8-3.5,1.2-5.4,1.4l-0.6,10.6C62.2,383.7,66.1,382.7,69.7,380.7L69.7,380.7z
         M63.5,376.7l0.9,4.3l-3.2,0.8c-0.6-1.7-1.4-5.4-1.1-7.3l1.5-0.4L63.5,376.7L63.5,376.7z"/>
      <path fill="none" d="M64.4,381l-0.9-4.3l-1.9-2.6l-1.5,0.4c-0.3,1.8,0.5,5.5,1.1,7.3L64.4,381L64.4,381z"/>
      <path fill="none" d="M57.1,389.9h0.4c0.2-3.9,0.7-12.7,1-16.7c0-0.5,0.1-1.7,0.1-2.2c0,0-1-2.9-1-2.9l-2.4-0.4l-0.9,4.1l1,0.2
        c-0.6,5.7-1.2,12-1.9,17.7C54.7,389.9,55.9,389.9,57.1,389.9L57.1,389.9z"/>
      <path fill="none" d="M48.7,388.9c1.5,0.4,3.1,0.7,4.7,0.8l0.3-2.2c-1.5-0.1-2.9-0.4-4.4-0.8L48.7,388.9z"/>
      <path fill="none" d="M53.7,387.5l0.4-3.8c-1.3-0.1-2.6-0.4-3.8-0.7l-1,3.7C50.8,387.1,52.2,387.4,53.7,387.5L53.7,387.5z"/>
      <path fill="none" d="M54.1,374.4c0.4,0.4,0.7,0.8,0.9,1.3l0.2-2.4l-2.1-0.2l-0.2,0.6C53.3,373.8,53.8,374.1,54.1,374.4L54.1,374.4
        z"/>
      <polygon fill="none" points="54.5,380.1 54.2,380.6 54.4,380.6 54.5,380.1 		"/>
      <path fill="none" d="M45,387.7l0.7-2.1c-4.4-1.7-8.7-5.1-11.9-8.9l-1.8,1.3C35.5,382.2,40.1,385.8,45,387.7L45,387.7z"/>
      <polygon fill="none" points="55.2,373.3 55.3,372.1 53.4,371.7 53.1,373 55.2,373.3 		"/>
      <path fill="none" d="M55.3,372.1L55.3,372.1c-0.5-0.1-1.4-0.3-1.9-0.4l0,0L55.3,372.1L55.3,372.1z"/>
      <path fill="none" d="M49.1,364.4l-1.1-2.1l-3.8,1.9l0.5,0.9c-4.4,2.8-10.7,6.9-15,9.7c0.7,1.1,1.5,2.1,2.3,3.1
        c4.2-2.9,10.4-7.3,14.6-10.3C47.4,367.4,48.7,365.1,49.1,364.4L49.1,364.4z"/>
      <path fill="none" d="M46.9,360.2c-0.8,0-3.4-0.2-4,0.3c-4.9,1.8-12,4.5-16.8,6.2c0.4,1.3,0.8,2.6,1.4,3.8c4.7-2.1,11.7-5,16.4-7.1
        l0.4,0.8l3.8-1.9C47.6,361.6,47.2,360.9,46.9,360.2L46.9,360.2z"/>
      <path fill="none" d="M44.7,365.1c-0.2-0.4-0.7-1.3-0.9-1.7l-0.1,0C44,364,44.3,364.6,44.7,365.1L44.7,365.1L44.7,365.1z"/>
      <path fill="none" d="M44.6,365.2c-0.3-0.5-0.6-1.1-0.9-1.7l-7.9,3.4c0.5,1,1,2,1.6,2.9L44.6,365.2L44.6,365.2z"/>
      <path fill="none" d="M50.5,370.6c-1.4-0.7-2.8-1.8-3.9-3l-7.1,5c1.9,2.1,4.2,4.1,6.6,5.4l-1.1-5.4l5.4-1.5L50.5,370.6L50.5,370.6z
        "/>
      <path fill="none" d="M34.7,363.5l8.1-3c-0.1-1.2-0.3-3.7-0.1-4.9l-8.3-2.4C33.9,356.4,33.8,360.5,34.7,363.5z"/>
      <path fill="none" d="M26,366.7l2.1-0.8c-1.3-4.5-1.4-10.1-0.5-14.7c-0.4-0.1-1.8-0.5-2.2-0.6C24.4,355.7,24.6,361.8,26,366.7z"/>
      <path fill="none" d="M29.4,369.7l-2.1,0.9c0.7,1.5,1.4,2.9,2.3,4.2l1.9-1.2C30.8,372.3,30,371,29.4,369.7L29.4,369.7z"/>
      <path fill="none" d="M33,368.1l-3.5,1.5c0.6,1.4,1.3,2.7,2.1,3.9l3.2-2.1C34.1,370.4,33.5,369.3,33,368.1L33,368.1z"/>
      <path fill="none" d="M28.1,365.9l3.6-1.3c-1-3.8-1.1-8.4-0.4-12.3l-3.7-1.1C26.7,355.9,26.8,361.4,28.1,365.9z"/>
      <path fill="none" d="M82.7,351.1l3.8-0.7c-1.1-4.8-3.4-9.1-6.9-13l-2.6,2.8C79.8,343.4,81.7,347,82.7,351.1z"/>
      <polygon fill="none" points="83.3,354.7 80.2,355.1 80.3,358.4 83.4,358.5 83.3,354.7 		"/>
      <path fill="none" d="M86.4,350.4l2.2-0.4c-1.2-5.3-3.7-10-7.6-14.2l-1.5,1.6C83,341.3,85.3,345.6,86.4,350.4z"/>
      <path fill="none" d="M76.9,340.2l-2.2,2.2c2.4,2.7,4,5.8,4.9,9.1l3.1-0.5C81.7,347,79.8,343.4,76.9,340.2L76.9,340.2z"/>
      <path fill="none" d="M74.7,342.4c1.9-1.9,4.5-4.7,6.3-6.6l-2.8-2.8c-3.3,3.9-8.2,9.4-11.6,13.3l-0.7-0.5l-2.6,3.4l1.9,1.4l2.8-1.3
        C68.3,349.2,74.3,342.8,74.7,342.4L74.7,342.4z"/>
      <path fill="none" d="M72.2,339.9l2.1-2.3c-1-0.9-2-1.6-3.1-2.3l-1.6,2.6C70.5,338.5,71.4,339.2,72.2,339.9L72.2,339.9z"/>
      <path fill="none" d="M76.8,334.7l1.5-1.7c-1.2-1.1-2.5-2.1-3.9-2.9l-1.2,1.9C74.5,332.8,75.6,333.7,76.8,334.7L76.8,334.7z"/>
      <path fill="none" d="M73.2,332l1.2-1.9c-1.1-0.7-2.3-1.3-3.4-1.9c-2.5,4.5-6.2,11-8.6,15.6c-0.6,0.6-0.8,3.2-0.9,4l1.9,1.4
        l2.6-3.4l-0.8-0.6C67.3,341.7,71.1,335.6,73.2,332L73.2,332z"/>
      <path fill="none" d="M73.2,332l-2,3.3c1.1,0.7,2.1,1.4,3.1,2.3l2.5-2.9C75.6,333.7,74.5,332.8,73.2,332L73.2,332z"/>
      <path fill="none" d="M68,333.6l1.9-3.4c-4.3-2-9.4-2.9-14.5-2.7l0.5,3.8C60.2,331.1,64.4,331.9,68,333.6L68,333.6z"/>
      <path fill="none" d="M72.2,339.9c-0.8-0.7-1.7-1.4-2.6-2l-4.5,7.3c0.4,0.3,1.1,0.9,1.5,1.1L72.2,339.9L72.2,339.9z"/>
      <path fill="none" d="M69.9,330.2l1.1-1.9c-4.7-2.3-10.3-3.3-15.9-2.9l0.3,2.2C60.5,327.2,65.6,328.1,69.9,330.2L69.9,330.2z"/>
      <path fill="none" d="M66.5,336.3l1.5-2.7c-3.6-1.7-7.8-2.4-12.1-2.2l0.4,3.1C59.9,334.3,63.4,334.9,66.5,336.3L66.5,336.3z"/>
      <path fill="none" d="M71.1,353l8.5-1.5c-0.9-3.4-2.5-6.4-4.9-9.1l-6,6.2C69.8,350,70.6,351.5,71.1,353z"/>
      <path fill="none" d="M62.3,343.8l4.2-7.6c-3.1-1.3-6.6-2-10.1-1.8l1.1,8.5C59.2,343,60.8,343.3,62.3,343.8L62.3,343.8z"/>
      <path fill="none" d="M53.5,343.4l-1,0.3c-1.8-4.9-4.3-11.9-6-16.7c-1.2,0.4-2.5,0.9-3.7,1.5c2,4.8,4.8,11.7,6.8,16.6
        c0.1,0.8,2,2.6,2.6,3.1l2.3-0.6L53.5,343.4L53.5,343.4z"/>
      <path fill="none" d="M52.5,343.7c0.5-0.1,1.4-0.4,1.9-0.5C53.7,343.3,53.1,343.5,52.5,343.7L52.5,343.7z"/>
      <path fill="none" d="M56.9,346.9c0.2-0.8,1-3.2,0.6-4c-0.7-5.2-1.7-12.6-2.4-17.7c-1.3,0.1-2.6,0.2-3.9,0.4
        c0.9,5,2.3,12.4,3.2,17.5l-0.9,0.2l1.1,4.1L56.9,346.9L56.9,346.9z"/>
      <path fill="none" d="M46.8,348.7c-4.9-2.9-11.4-6.8-16.3-9.7h0c-0.8,1.1-1.5,2.2-2.1,3.4c4.6,2.4,11.2,6,15.8,8.4l-0.4,0.8
        l3.9,1.8l1-2.2L46.8,348.7L46.8,348.7z"/>
      <path fill="none" d="M45.9,348.2c1.1-1.2,2.4-2.4,3.7-3.2l-3.3-8c-2.9,1.5-5.8,4-7.8,6.7L45.9,348.2L45.9,348.2z"/>
      <path fill="none" d="M44.2,350.8L44.2,350.8l-0.8,1.7h0C43.6,352.1,44,351.2,44.2,350.8L44.2,350.8z"/>
      <path fill="none" d="M47.7,353.3l-3.9-1.8l-0.4,0.9c-5-1.7-12-4.1-16.9-5.7c-0.4,1.2-0.8,2.5-1.1,3.8c5,1.4,12.2,3.5,17.2,5
        c0.7,0.5,3.3,0,4.1,0L47.7,353.3L47.7,353.3z"/>
      <path fill="none" d="M43.4,352.5l0.8-1.8l-7.6-4c-0.5,1-1,2-1.3,3L43.4,352.5L43.4,352.5z"/>
      <path fill="none" d="M47.2,329l1.3,3.6c1.2-0.4,2.4-0.7,3.7-1l-0.7-3.8C50.1,328.2,48.6,328.6,47.2,329L47.2,329z"/>
      <path fill="none" d="M45.1,334.1l-1.5-3.5c-4.2,2.1-8.3,5.7-11.2,9.6l3.3,2C38.2,338.9,41.6,335.9,45.1,334.1L45.1,334.1z"/>
      <path fill="none" d="M54.4,343.2l-1.5-8.4c-1.1,0.2-2.2,0.5-3.2,0.8l2.9,8.1C53.1,343.5,53.7,343.3,54.4,343.2L54.4,343.2z"/>
      <path fill="none" d="M34.2,334.7c-1.4,1.4-2.6,2.8-3.7,4.3l1.9,1.2c2.8-4,7-7.5,11.2-9.6l-0.9-2.1C39.7,330,36.9,332.1,34.2,334.7
        L34.2,334.7z"/>
      <path fill="none" d="M33.8,345.2l-3.4-1.8c-0.7,1.3-1.3,2.7-1.8,4.1l3.6,1.2C32.7,347.5,33.2,346.4,33.8,345.2L33.8,345.2z"/>
      <polygon fill="none" points="26,350.7 25.5,350.6 25.5,350.6 26,350.7 		"/>
      <path fill="none" d="M26.5,346.8l2.1,0.7c0.5-1.4,1.1-2.8,1.8-4.1l-2-1C27.7,343.8,27,345.3,26.5,346.8z"/>
      <path fill="none" d="M51.1,325.7c-1.6,0.3-3.2,0.7-4.7,1.2l0.8,2.1c1.4-0.5,2.9-0.8,4.3-1.1L51.1,325.7L51.1,325.7z"/>
      <path fill="none" d="M86.9,362.5l2.2,0.2l0.4-3.9c-5.1-0.2-12.5-0.5-17.7-0.8c0-0.1,0-0.8,0-0.9l-4.2,0.1l0.1,2.4
        c0.7,0.3,2.9,1.7,3.8,1.5C75.5,361.5,82.7,362.1,86.9,362.5L86.9,362.5z"/>
      <path fill="none" d="M89.3,353.9c-0.1-1.3-0.3-2.6-0.6-3.9c-5.1,0.9-12.4,2.1-17.6,3c-0.9-0.1-3,1.4-3.7,1.8l0.1,2.4l4.2-0.1
        c0-0.2,0-0.8,0-1C76.9,355.5,84.2,354.5,89.3,353.9L89.3,353.9z"/>
      <path fill="none" d="M80.3,358.4l-0.1-3.3l-8.5,1c0,0.6,0,1.3,0.1,1.9L80.3,358.4L80.3,358.4z"/>
      <polygon fill="none" points="71.7,357.1 71.7,357.3 71.7,356.9 		"/>
      <path fill="none" d="M55,375.7c-0.4-0.9-1.2-1.7-2.1-2.1l-2.3,8.3c1.5,0.2,2.9-0.5,3.8-1.7L55,375.7L55,375.7z"/>
      <path fill="none" d="M54.2,380.6c-0.9,1-2.2,1.4-3.5,1.3l-0.3,1.2c1.2,0.3,2.5,0.5,3.8,0.7l0.3-3.1L54.2,380.6L54.2,380.6z"/>
      <path fill="none" d="M47.6,379.9c-1.4-2.2-0.4-5.5,2.1-6.4l0.7-2.3l-5.4,1.5c0.5,2.3,1.5,7,2,9.3L47.6,379.9L47.6,379.9z"/>
      <path fill="none" d="M47,378.5c0.1,0.5,0.3,1,0.6,1.4l2.1-6.4C47.6,374.2,46.6,376.4,47,378.5z"/>
      <polygon fill="none" points="46.9,381.9 46.9,381.9 46.9,381.9 		"/>
      <path fill="none" d="M46.9,381.9c-0.4,1.4-1.4,4.5-1.9,5.8c1.2,0.5,2.4,0.9,3.7,1.2c1.4-5,3.3-12.2,4.7-17.2l0.9,0.2l0.9-4.1
        l-2.4-0.5l-2,2.3c-0.3,1-0.9,2.9-1.2,3.9C49.2,374.9,47.3,380.5,46.9,381.9z"/>
      <path fill="none" d="M46.9,381.9L46.9,381.9c-3.7-1.5-7.2-4.3-10-7.5l-3.2,2.2c3.2,3.8,7.5,7.2,11.9,8.9L46.9,381.9L46.9,381.9z"
        />
      <polygon fill="none" points="58.8,412.2 56,412.9 56,433.4 59.4,433.2 58.8,412.2 		"/>
      <polygon fill="none" points="53.4,413.6 50.6,414.2 50.7,433.6 53.4,433.5 53.4,413.6 		"/>
      <polygon fill="none" points="56,412.9 53.4,413.6 53.4,433.5 56,433.4 56,412.9 		"/>
      <path fill="none" d="M56,433.4l-2.6,0.1L55,635.7c1.8,0.3,3.5,0.6,5.2,0.9c0-2.7,0-10.1,0-12.8c-2-0.5-3-2-2.9-4.7
        C57.1,579.1,56.3,474.6,56,433.4L56,433.4z"/>
      <path fill="none" d="M53.4,433.5l-2.6,0.1l1.2,195.6c0.3,3.4,0.1,6.2,3.1,6.5L53.4,433.5L53.4,433.5z"/>
      <path fill="none" d="M68.6,443.3c-4.2-0.4-6.5,1-7,4.1l1,108.4h6.8L68.6,443.3L68.6,443.3z"/>
      <path fill="none" d="M69.6,422.2v-13.2c-2.6,0.8-5.3,1.6-8.1,2.4v12.4L69.6,422.2L69.6,422.2z"/>
      <path fill="none" d="M132.3,311.1c-0.4-6.3-0.8-18-0.7-24.3l-3.7-2.3l-3-0.6c-4-3.4-13.6-4.4-18.1-1.6l0.1,7.5
        c0.8-1.2,6.4,0.6,5.2,1.4v4.4c0.1,0.1,0.2,0.2,0.2,0.4l1,0c1.4,0.9,0.9,3,3.3,3.1v2.2c3.4,3.7,8.2,4.5,14.3,2.7l0-1.7l-2.8-2.1
        c-9.2-1.8-16-0.3-15.5-11.3c-15.8-7.1,8.2-9.5,12.2-3.8c1.7,0.2,4.2,1.5,5.6,2.3c0.1,5.5,0.4,14.8,0.5,20.2
        c-0.9-0.7-1.9-1.3-2.9-1.7v3.7l-20.3-1.5c-0.5,0-0.7-0.4-0.8-1.3c-0.3-2-0.4-6.6-0.4-8.7c0.1-1.9-0.3-6.4,0.2-8.2l-0.1-7.5
        l-2.9-0.2l0.3,0.1c-0.1,10.1,0.2,18,1.2,26.8C107.5,309.1,131.2,311,132.3,311.1L132.3,311.1z"/>
      <path fill="none" d="M128.2,305.9c1,0.4,2,0.9,2.9,1.7l-0.1-3.7c-6.1,1.9-10.9,1-14.3-2.7v3.1C118.2,304.5,127,305.2,128.2,305.9
        L128.2,305.9z"/>
      <path fill="none" d="M128.2,309.6v-3.7c-6.2-1.4-15.2-1.7-21.4-2.6c0.3,0.8-0.1,4.8,1.2,4.8C108,308.1,128.2,309.6,128.2,309.6
        L128.2,309.6z"/>
      <path fill="none" d="M132.3,311.1l-3.3-0.3l3.1,120.4l3.5,0.5C133.9,359.1,132.8,318.9,132.3,311.1L132.3,311.1z"/>
      <path fill="none" d="M128.2,300.1l2.8,2.1l-0.1-2c-0.4-0.3-2.2-1.7-2.6-2c-5.5-1-13.6-0.2-12-8.7l-0.7-0.1
        c0.2,1.8-0.7,6.1,1.2,7.2c-3.9-0.2-3.1-3.9-3.1-7.6l-1-0.3C112.2,299.8,119,298.3,128.2,300.1L128.2,300.1z"/>
      <path fill="none" d="M128.4,298.2l0.5,0.4v-1.4c-1.4,0-5.3,0.1-6.7,0.1C124.2,297.7,126.2,298.1,128.4,298.2L128.4,298.2z"/>
      <polygon fill="none" points="128.9,298.6 130.9,300.2 130.7,288.9 127.7,287.1 125.2,286.8 125.3,296.4 126.8,296.3 126.8,297.3 
        128.9,297.2 		"/>
      <path fill="none" d="M114.5,300.9l0,3.2l0.3,0c-0.1-2.3,0.8-6.7-1.4-8.2l-1,0l0.1,2.3l-5.7-0.2v5.2l6.7,0.7l-0.1-7.3
        C114.1,297.3,114.5,298.8,114.5,300.9z"/>
      <path fill="none" d="M114.5,304.1c0-2,0.1-6.5-1.1-7.5l0.1,7.3L114.5,304.1L114.5,304.1z"/>
      <path fill="none" d="M115,299.9l-0.1,4.2l1.9,0.2c0-1.3,0-4.1,0-5.4c-1.1-0.2-1.8-0.5-2-1.1L115,299.9z"/>
      <path fill="none" d="M105.7,312.6c-0.1-2.1-0.5-6.6-0.8-8.7c-5.4-2.7-6.8-25.1-6.4-33.8c-1.3-2-7.8-0.9-9.9-1.2v2.3
        c-4.2,1.1-13.8,0.7-18.4,0.7l-1.1,23.6C83,298.3,95.4,302.1,105.7,312.6L105.7,312.6z M95.6,278c-0.9,5.3-13.2,5.3-14.1,0
        C82.4,272.8,94.7,272.8,95.6,278z"/>
      <path fill="none" d="M93.5,280.8c8.1-6.4-11.5-9.7-12.1-2.8C82.2,282.6,90.2,282.7,93.5,280.8L93.5,280.8z M94.9,277.6
        c-1,4.5-11.9,4.5-12.9,0C83,273.1,93.9,273.1,94.9,277.6z"/>
      <path fill="none" d="M93.1,279.9c7.3-5.4-10.4-8.1-11-2.3C82.9,281.5,90.1,281.4,93.1,279.9L93.1,279.9z"/>
      <path fill="none" d="M106.8,298l5.7,0.2c-0.1-0.3,0.1-2.6-0.4-2.7h-5.4V298z"/>
      <path fill="none" d="M70.7,305.9c0-2.6-0.9-7.8-1.6-10.3h-5.2l0.9,8.9C66.8,304.9,68.8,305.3,70.7,305.9L70.7,305.9z"/>
      <path fill="none" d="M63.8,304.2l1,0.2l-0.9-8.9h5.2l1.1-23.6c-7.3,6.6-22.5,15-16.6,26.6C53.5,301,62.5,303.3,63.8,304.2
        L63.8,304.2z M64.3,278.2c0,2.2,0,5.4,0,7.6c0.2,2-4.1,4.4-5.3,5.5c-2.6,1.1-1.4-5.5-1.8-6.7L64.3,278.2L64.3,278.2z"/>
      <path fill="none" d="M64.3,281.1v-2.9l-7.1,6.4c0.3,1.2-0.4,6.3,0.9,6.8c0-1,0-4.6,0-5.5L64.3,281.1L64.3,281.1z"/>
      <polygon fill="none" points="64.3,282.7 64.3,281.1 58,286 58,287.3 64.3,282.7 		"/>
      <path fill="none" d="M62.9,288.3c1.9-1.4,1.3-3.7,1.4-5.6l-6.2,4.6l0,4.1C58.7,292,62.3,288.5,62.9,288.3L62.9,288.3z"/>
      <path fill="none" d="M126.8,297.3v-0.9c-1,0-6.4,0-7.5,0.1C120.9,297.4,124.8,297.4,126.8,297.3L126.8,297.3z"/>
      <path fill="none" d="M128.4,283l2,1.4v-2.7c-1-1-3.9-2-6.2-1.9l0.1,2.3C125.7,282.3,127.1,282.6,128.4,283L128.4,283z"/>
      <path fill="none" d="M130.4,284.4c-3.8-2.8-8.9-2.6-13.4-3.5c3.4,0.5,6,1.5,8,3.1l3,0.6l3.7,2.3v-1.6L130.4,284.4L130.4,284.4z"/>
      <path fill="none" d="M127.8,285.9c-4.9-1.6-7.5-4.7-13.8-4c3.5,0.2,7.5,1.2,9.7,3.7L127.8,285.9L127.8,285.9z"/>
      <path fill="none" d="M127.7,287.1l3,1.8l0-0.8l-2.8-2.2l-4.1-0.4c0.3,0.4,0.5,0.8,0.6,1.2C124.9,286.8,127,287,127.7,287.1
        L127.7,287.1z"/>
      <polygon fill="none" points="130.6,288.1 130.6,287.3 127.8,285.9 130.6,288.1 		"/>
      <path fill="none" d="M125.2,286.8l-0.9-0.1c0.1,3.2-5.1,3.2-8,2.9c-0.5,3.5,0.1,5.6,1.8,6.3c1.8-1.2,5.7-1.4,7.1,0.5h0.1
        L125.2,286.8L125.2,286.8z"/>
      <path fill="none" d="M124.4,287.1c-0.6-4.9-15.5-7.8-16.9-2.6c0.3,2.9,5.5,4.5,8.3,4.9c0-0.5,0.2-0.7,0.7-0.7
        c-8.9-0.7-11.8-7.7-0.5-6.4c8.3,0.7,11,7.4,0.7,6.4c-0.5-0.1-1,0.1-0.9,0.7C118.6,289.9,123.7,290.1,124.4,287.1L124.4,287.1z"/>
      <path fill="none" d="M122.9,286c0,0,0,1.7,0,1.7c4.1-5.9-20.3-8.2-13.2-1.3C107.9,280.3,121.9,282.6,122.9,286z"/>
      <path fill="none" d="M122.9,286.1c-1.3-3.4-11.3-4.9-13.2-1.6C111.1,287.8,120.9,289.3,122.9,286.1L122.9,286.1z"/>
      <path fill="none" d="M121.3,288.5c0.7-0.2,1.3-0.4,1.6-0.7v-1.7c-2,3.2-11.7,1.7-13.2-1.6v2C112.6,288.5,117.8,289.4,121.3,288.5
        L121.3,288.5z"/>
      <path fill="none" d="M125.2,296.4c-1.4-1.9-5.3-1.7-7.1-0.5C118.8,296.7,124.3,296.3,125.2,296.4L125.2,296.4z"/>
      <path fill="none" d="M124.3,282l-0.1-2.3H123c0,0.3,0.1,1.7,0.1,2L124.3,282L124.3,282z"/>
      <path fill="none" d="M123.1,281.8l-0.1-1.6h-2.1v1.2C121.6,281.5,122.4,281.7,123.1,281.8L123.1,281.8z"/>
      <path fill="none" d="M120.9,279.9c0-0.3-0.1-0.5-0.4-0.7c-0.7,0.7-3.2,0.9-7.7,0.7l0,0.7c2.6-0.1,5.5,0.4,8.1,0.8
        C120.9,281.2,120.9,280.1,120.9,279.9L120.9,279.9z"/>
      <path fill="none" d="M120.5,279.2c-0.9-0.7-6.7-0.8-7.9-1l0.1,1.8C117.3,280.1,119.9,279.9,120.5,279.2L120.5,279.2z"/>
      <polygon fill="none" points="112.8,280.6 112.8,280 108.2,280.2 108.2,280.6 112.8,280.6 		"/>
      <path fill="none" d="M114.4,280.6c-1,0-5,0-6.1,0c0-0.2,0-2.2,0-2.5h-6.4c-0.5,3.9,2.4,4.2,5.1,4.2
        C108.7,281.3,111.2,280.7,114.4,280.6L114.4,280.6z"/>
      <path fill="none" d="M99.8,275c-0.2-1.1-0.3-4.2-1.2-4.9c-0.4,8.7,1,31.2,6.4,33.8c-0.5-6.4-0.7-13.7-0.6-21.7
        c-1.8-0.3-2.7-1.6-2.5-4C100.7,277.6,100,276.5,99.8,275L99.8,275z M99.3,277.9h1.6c0.4,1,0.9,3.5-0.6,3.4l-1-1.6V277.9
        L99.3,277.9z"/>
      <polygon fill="none" points="100.9,277.9 99.3,277.9 99.3,279.7 101.4,279.6 100.9,277.9 		"/>
      <path fill="none" d="M100.3,281.3c0.7,0,1.1-0.6,1.1-1.7l-2.1,0.1L100.3,281.3L100.3,281.3z"/>
      <path fill="none" d="M112.2,295.5v-4.4c1.2-0.8-4.4-2.6-5.2-1.4c-0.4,0.1-0.1,5.4-0.2,5.7H112.2L112.2,295.5z"/>
      <path fill="none" d="M114.2,295.1c0.4,1.1,1.3,1.6,2.6,1.5c-1.9-1.1-1-5.4-1.2-7.2l-2-0.4C113.5,291.7,113.7,293.7,114.2,295.1z"
        />
      <path fill="none" d="M81.3,272c-0.7-1.7-0.9-3.6-3.4-3.6c0,0-7.8,0-7.8,0l-5.2,4.2l0.1,3.6c1.8-1.5,3.5-3,5.2-4.3L81.3,272
        L81.3,272z"/>
      <path fill="none" d="M51.8,287.7c-8.9-0.9-5.9,2.8-5.3,8.6c0.5,2.7,5.1,2,7.1,2.3C52.8,296.6,52,291,51.8,287.7L51.8,287.7z"/>
      <path fill="none" d="M52.1,291c1.4-3.7,3.5-6.1,6.1-8.5V276c-0.6-0.1-1.3,0.9-2.3,2.9C53.8,282.3,50.9,286.6,52.1,291z"/>
      <path fill="none" d="M111.7,682l4.8-0.2c-2.7-22.9-22.6-43.1-45.1-47l0,4.3C91.4,643.3,109.3,661.7,111.7,682z"/>
      <path fill="none" d="M116.5,681.8l9.7-0.5c-2.6-28.8-26.9-52-54.9-55.5l0.1,9C93.9,638.6,113.8,658.9,116.5,681.8z"/>
      <path fill="none" d="M71.3,622.5c-4.4-1.1-11.4-1.2-10.5-7.9c0,0-0.4-58.9-0.4-58.9h-3.4l0.5,63.3c-0.9,6.7,9.6,5,13.9,6.7
        L71.3,622.5L71.3,622.5z"/>
      <polygon fill="none" points="71.2,622.5 71.3,622.5 70.7,555.7 69.5,555.7 70.1,620.1 		"/>
      <path fill="none" d="M66.3,619.3c-6.3,2.9-2.3-61.8-3.6-63.5h-2.4l0.4,58.9c-0.9,6.6,6.1,6.8,10.5,7.9l-1.2-2.3L66.3,619.3
        L66.3,619.3z"/>
      <path fill="none" d="M63.3,615.3c-0.2,4,4,4.1,6.8,4.8l-0.5-64.4h-6.8L63.3,615.3z"/>
      <path fill="none" d="M76.1,623.5c2.7,0.3,3.9-1.3,3.8-4.7L79,555.7h-8.4l0.6,66.7L76.1,623.5L76.1,623.5z"/>
      <path fill="none" d="M71.3,625.7c-3.5-0.7-7.5-1.4-11.1-2v8.7c3.8,0.7,7.5,1.4,11.1,2.3L71.3,625.7L71.3,625.7z"/>
      <path fill="none" d="M71.4,639l0-4.3c-3.6-0.9-7.3-1.7-11.1-2.3v4.1C64.1,637.2,67.8,638.1,71.4,639L71.4,639z"/>
      <path fill="none" d="M210.3,559.3c0-14.2-1.1-47.7-1.1-50.8c-4.9-4.2-30.1-38-36.7-38.2c0,2.5,0.1,5.7-3.2,5.9
        c-5.5-0.2-16.1,2.4-18.4-2.1l2.2,74.4L210.3,559.3L210.3,559.3z"/>
      <path fill="none" d="M151.1,559.4l2.2,0.7c-0.9-31-2.7-92.7-3.6-123.3l-2.2-2.5l-0.2-0.1L151.1,559.4z"/>
      <path fill="none" d="M172.5,470.3L172.5,470.3c0,2.1-0.8,3.8-3.2,4.1c-4.7-0.3-17,2.5-18.5-2.4c-0.2,1.9,0.2,4.4,2.7,4.2
        c4.7,0.5,10,0.5,15.8,0C172.5,476,172.5,472.8,172.5,470.3L172.5,470.3z"/>
      <path fill="none" d="M150.7,471.9l0,0.7C150.8,472.6,150.8,472,150.7,471.9L150.7,471.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M57.7,359.1L57.7,359.1l0.2,0.1v0.5l0,0l-0.2-0.1l-0.2-0.6h0l0,0
        L57.7,359.1v0.3V359.1L57.7,359.1z M58.1,358.8l-0.2,0.1c-0.4-0.1-0.6-0.5-0.5-0.8l0,0c0,0,0.8,0.3,0.8,0.3
        C58.2,358.4,58.2,358.8,58.1,358.8L58.1,358.8z M57.9,358.6c-0.2,0-0.4-0.1-0.4-0.3l0.5,0.2L57.9,358.6L57.9,358.6L57.9,358.6z
         M57.2,359.2l-0.2-0.1c0,0,0-0.2,0-0.2l-0.1-0.1v0.2c0,0-0.2,0-0.2,0v-0.2l-0.1,0l0,0.3c0,0-0.2,0-0.2-0.1v-0.5
        c0.1,0,0.7,0.3,0.8,0.3L57.2,359.2L57.2,359.2L57.2,359.2z M57.2,359.8l-0.2,0.1l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.3l0.3-0.1
        l-0.3-0.1V359c0.1,0,0.7,0.3,0.8,0.2C57.2,359.4,57.2,359.6,57.2,359.8z M58.7,358.6c0.1-1.3,0-3.2-1.1-4
        C53.4,354.7,56.9,365.3,58.7,358.6L58.7,358.6z M58.4,359.5c-1.4,3.2-3.2-1-2.6-2.7c0.1-1.6,1.7-3.1,2.5-1
        C58.8,356.8,58.8,358.3,58.4,359.5z M56.9,359.6l0-0.1l0.2,0.1l0,0.1L56.9,359.6L56.9,359.6z M58.2,356.4L58.2,356.4L58.2,356.4
        c0,0-0.6-0.2-0.6-0.2c0,0.1,0,0.2,0,0.2c0,0-0.2-0.1-0.2-0.1l0-0.5l0,0v0c0.2,0.1,0.6,0.3,0.8,0.3V356.4z M57.1,356.1L57.1,356.1
        L57.1,356.1l-0.2-0.3v0.2c0,0-0.2-0.1-0.2-0.1v-0.5l0,0l0.2,0.1L57.1,356.1L57.1,356.1L57.1,356.1z M58.2,357.1
        c-0.1,0-0.7,0-0.8,0v-0.2c0,0,0.1,0,0.1,0l0-0.3l-0.1,0v-0.3l0.8,0.5h0L58.2,357.1L58.2,357.1L58.2,357.1z M57.9,356.9h-0.3v-0.1
        L57.9,356.9L57.9,356.9z M58.2,357.9v0.2l0,0l-0.8-0.3l0-0.2l0,0l0.4,0c-0.1,0-0.3-0.1-0.4-0.1v-0.2l0.8,0.3l0,0.3l-0.4,0
        C57.8,357.8,58.2,357.9,58.2,357.9L58.2,357.9z M56.9,688.1l-0.2-0.1v-0.5l0,0l0.2,0.1l0.2,0.6h0v0l-0.1,0l-0.1-0.3L56.9,688.1
        L56.9,688.1L56.9,688.1z M56.8,688.7l0-0.2l0.2,0.1c0,0,0,0.1,0,0.1L56.8,688.7L56.8,688.7z M57,689.5c-0.2,0.1-0.4,0-0.5-0.1
        C56.5,689.1,57.1,689.3,57,689.5z M57.2,688.7c0,0.2-0.1,0.2-0.2,0.3l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.3c0.1,0,0.2-0.1,0.3-0.1
        l-0.3-0.1v-0.2c0.1,0,0.7,0.3,0.8,0.2V688.7z M57.1,689.7c-0.3,0.3-0.9-0.1-0.8-0.5l0.1-0.1C56.9,688.9,57.3,689.2,57.1,689.7z
         M57.1,690h-0.4c0.1,0,0.4,0.3,0.5,0.3v0.3l-0.8-0.6h0v-0.2c0,0.1,0.8,0,0.8,0L57.1,690L57.1,690L57.1,690z M57.3,689.2l0.8,0.3
        l0,0.3l-0.4,0c0,0,0.4,0.1,0.4,0.1v0.2l0,0l-0.8-0.3l0-0.2l0,0l0.4,0c-0.1,0-0.3-0.1-0.4-0.1V689.2L57.3,689.2z M57.7,688.8
        l0.2,0.1h-0.2V688.8L57.7,688.8z M57.2,358.3v0.3l-0.8-0.6h0v-0.2l0,0c0,0,0.8,0,0.8,0v0.3c0,0-0.4,0-0.5,0
        C56.8,358.1,57.1,358.3,57.2,358.3L57.2,358.3z M57.3,688.4l0.8,0.5h0v0.2l0,0c-0.1,0-0.7,0-0.8,0v-0.2c0,0,0.1,0,0.1,0l0-0.3
        l-0.1,0V688.4L57.3,688.4z M58.1,688.4l-0.6-0.2c0,0.1,0,0.2,0,0.2h0l0,0l-0.2-0.1l0-0.5l0,0v0c0.1,0,0.7,0.3,0.8,0.3L58.1,688.4
        L58.1,688.4L58.1,688.4C58.1,688.4,58.1,688.4,58.1,688.4L58.1,688.4z M56.8,691.6l0-0.2l0.2,0.1l0,0.1L56.8,691.6L56.8,691.6z
         M58,692.2c-2.5,2-2.9-4.5-1.3-5.4C59.1,686.2,59,690.9,58,692.2L58,692.2z M58.5,691.5c0.4-1.4,0.5-3.6-0.6-4.6
        C54.1,685,55.7,696.6,58.5,691.5L58.5,691.5z M57.1,691.8l-0.2,0.1l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.2c0.1,0,0.2-0.1,0.3-0.1
        l-0.3-0.1V691c0.1,0,0.7,0.3,0.8,0.2C57.2,691.4,57.2,691.6,57.1,691.8z M57.2,691.2l-0.2-0.1c0,0,0-0.2,0-0.3l-0.1-0.1v0.2l0,0
        l-0.1-0.1v-0.2l-0.1,0l0,0.2c-0.1,0-0.2,0-0.2-0.1v-0.5c0.1,0,0.7,0.3,0.8,0.3L57.2,691.2L57.2,691.2L57.2,691.2z M57.9,690.6
        c-0.2,0-0.3-0.1-0.4-0.3l0.5,0.1L57.9,690.6L57.9,690.6L57.9,690.6z M57.2,357.7c-0.4,0.2-0.9,0-0.8-0.5
        C56.7,356.7,57.4,357.2,57.2,357.7z M58,690.8l-0.2,0.1c-0.4-0.1-0.6-0.5-0.5-0.8l0,0c0.2,0.1,0.6,0.3,0.8,0.3l0,0.4L58,690.8
        L58,690.8z M57.2,356.7c0,0.1-0.1,0.2-0.2,0.2l-0.2-0.1l-0.1-0.2l-0.3,0.2v-0.2l0.3-0.1l-0.3-0.1v-0.2l0,0c0.1,0,0.7,0.3,0.8,0.3
        V356.7z M57.7,691.1l0.2,0.1v0.5l0,0l-0.2-0.1l-0.2-0.6h0l0,0l0.1,0l0.1,0.3L57.7,691.1L57.7,691.1L57.7,691.1z M56.8,357.5
        c-0.1,0-0.2-0.1-0.3-0.2c0-0.2,0.6-0.1,0.5,0.1L56.8,357.5L56.8,357.5z M57,356.7l-0.1,0l0-0.2l0.2,0.1
        C57,356.6,57,356.7,57,356.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M161.9,793.4l-0.5-10.6 M147.8,795.1c1.6,0.3,3.8,0,5.7-0.6
         M145.7,789.1c-1.1,2.4-1.6,5.8,2.2,6 M138.9,795.7c8.1-1.6,2.6-11.1-3.5-9.8c-4.2,0-10.6,8-4.7,10.2 M147.8,795.1l-9,0.6
         M106.8,295.5v2.5 M161.9,793.4c-2.7,0.4-5.5,0.8-8.4,1.1 M130.9,300.2l-0.3-11.3 M130.7,796c2.2,0.7,5.8,0.5,8.2-0.3
         M145.7,789.1c4.7,1.1,9,0.7,12.9-1.4 M153.5,794.5c3.4-0.9,7.7-3.4,5-6.8 M162,794.3l0-0.9 M158.5,787.7
        c-3-4.8-10.6-3.5-12.9,1.4 M155.4,778c-3.5-2.1-24.2-0.2-28.5-0.5 M126.9,784.4c12.4-0.3,23.8-1,34.3-2.1
        c-0.8-1.3-2.7-2.7-5.8-4.4 M204.5,486.8l1.4-12.7 M118.1,295.9c-1.7-0.7-2.3-2.9-1.8-6.3 M106.9,282.3l0.1,7.5 M106.8,295.5h5.4
         M107,289.8c-0.4,0-0.1,5.5-0.2,5.7 M109.7,284.5v2 M115.7,289.4c0-0.5,0.2-0.7,0.7-0.7 M112.7,288.8l1,0.3 M115.7,289.4l-2-0.4
         M116.3,289.5l-0.7-0.1 M109.7,286.5c1.9,1.3,4.1,1.9,6.7,2.2 M112.2,295.5c0.1,0.1,0.2,0.2,0.2,0.4 M114.7,297.8
        c-0.2-0.8-0.7-1.6-1.3-2l-1,0 M113.7,289c0.1,3.7-0.7,7.4,3.1,7.6c-1.9-1.1-1-5.4-1.2-7.2 M112.2,295.5v-4.4
        c1.2-0.8-4.4-2.6-5.2-1.4 M114.1,281.8c-7.8-0.7-8.9,4.7-1.3,6.9 M125.3,296.4l-0.1-9.6 M123.8,285.5l4.1,0.4 M122.1,297.3l4.6,0
         M119.3,296.4l6,0 M119.3,296.4c0.9,0.3,1.8,0.6,2.9,0.9 M118.1,295.9c0.1,0,1.1,0.5,1.1,0.5 M125.3,296.4L125.3,296.4
         M125.2,296.4c-1.4-1.9-5.3-1.7-7.1-0.5 M122.9,287.8v-1.7 M124.3,286.7c-0.1-0.4-0.3-0.8-0.6-1.2 M109.7,284.5
        c1.5,3.3,11.2,4.8,13.2,1.6 M116.4,288.7c2.4,0.2,4.8,0.2,6.6-1 M109.7,286.5c-7-6.9,17.3-4.6,13.2,1.3 M122.9,286.1
        c-1.3-3.4-11.4-4.9-13.2-1.6 M123.8,285.5c-2.2-2.5-6.2-3.4-9.7-3.7 M116.3,289.5c2.9,0.3,8.1,0.4,8-2.9 M125.2,286.8l-0.9-0.1
         M127.8,285.9l2.8,2.2 M117,280.8c3.4,0.5,6,1.5,8,3.1l3,0.6l3.7,2.3 M130.6,288.1l0-0.8l-2.8-1.4 M130.7,288.9l0-0.8
         M130.7,288.9l-3-1.8l-2.4-0.3 M127.8,285.9c-4.9-1.6-7.5-4.7-13.8-4 M126.8,297.3l2.1,0v1.4 M126.8,297.3v-0.9l-1.4,0 M131,303.9
        c-6.1,1.9-10.9,1-14.3-2.7 M112.4,295.9l0.1,2.3l-5.7-0.2 M106.8,303.2l6.7,0.7 M106.8,298v5.2 M113.5,304l1,0.1 M114.5,304.1
        l0.3,0 M114.9,304.1l1.9,0.2 M116.7,301.2v3.1 M114.7,297.8c0.2,0.6,0.9,0.9,2,1.1v2.2 M114.9,304.1c0.1-1.4,0.2-4.9-0.1-6.3
         M114.5,304.1c0-2,0.1-6.5-1.1-7.5l0.1,7.3 M116.7,304.3c1.5,0.2,10.3,0.9,11.5,1.5 M130.9,300.2l-2.1-1.6 M131,303.9l0-1.7
         M131,302.1l-0.1-2 M112.7,288.8c-0.6,11,6.2,9.6,15.5,11.3c0,0,2.8,2,2.8,2 M106.8,303.2c0.3,0.8-0.1,4.8,1.2,4.8
        c0,0,20.3,1.5,20.3,1.5v-3.7 M128.2,305.9c1,0.4,2,0.9,2.9,1.7l-0.1-3.7 M158.6,431.5c-1.2-1.9-1.4-3.5-0.7-5l2.3-10.3
         M129.4,790.2c4.9,1.2,9,0.7,12.3-1.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M126.9,796.1v-5.2 M130.7,796l-3.7,0.1 M121.2,784.6l1.7,0 M121.2,784.6
        v2.8h1.7v-2.8 M122.9,784.5l4.1-0.1 M126.9,790.9v-6.5 M112.9,784.6v1.9c-1.3,4.4,3.9,3.2,1.8-0.4v-1.5 M112.9,784.6l1.8,0
         M110.2,784.6h2.6 M110.2,784.6l-0.1,6.7 M110.1,796.7v1.3 M110.2,791.4l-0.1,5.3 M114.7,784.6l6.5-0.1 M110.2,791.4l16.8-0.4
         M110.1,796.7c5.5-0.1,11.1-0.3,16.8-0.6 M126.9,797.5v-1.3 M126.9,777.5l-16.6,0.4l-0.1,6.8 M126.9,784.4v-7 M128.9,298.6
        l-0.5-0.4c-2.1-0.1-4.2-0.5-6.2-0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M105.8,776.1l-0.7,26 M65.7,741.4c-2.3,0.3-4.7,0.4-7.1,0.4 M45.3,734.3
        l11.6,58.5c0.7,2.9,2.9,4,6.6,3.5 M58.5,741.8c1.2,17.9,2.9,36.7,5,54.6 M52.8,643.5c-51.1,4.8-57,77.6-7.5,90.8 M58.5,741.8
        l-0.8-6.1 M45.3,734.3c3.9,1,8.2,1.5,12.5,1.4 M48.7,720.9c1.5,0.4,3.1,0.7,4.7,0.8 M53.4,721.7c1.3,0.1,2.7,0.2,4,0.2
         M71.6,816.1c-2.9-27.1-4.7-49.8-6-74.7 M82.6,750.6c-2.4,0.6-4.8,1.1-7.3,1.4 M75.3,752l-0.6-6.7 M70.1,746.1l0.3,6.2
        c1.6-0.1,3.3-0.2,4.9-0.4 M105.8,776.1c-24.6-0.4-20.2-4-23.3-25.6 M69.9,740.8c-1.4,0.3-2.8,0.5-4.2,0.7 M74.7,745.2l-4.6,0.9
         M74.7,745.2l-0.5-5.5 M74.2,739.7c-1.4,0.4-2.9,0.7-4.3,1 M70.1,746.1l-0.2-5.4 M57.8,735.8c63.4-2.9,58.5-96-4.9-92.3
         M74.2,739.7c24.3-6.8,41.9-32.3,37.5-57.7 M82.6,750.6c29.5-8,47.7-39.2,43.6-69.3 M116.5,681.8c4.7,28.1-14.1,57.2-41.8,63.4
         M109.6,776.4l-3.8-0.3 M108.7,798.1c0.3-0.3-0.6-22.5,0.9-21.6 M81.6,816.4c0.5-12.3-5.1-48.4-6.4-64.5 M65.3,809.3l6.3,6.8
         M89.1,803.1l16.1-0.9 M71.6,816.1l10,0.3 M80.4,815.3c0.1,0.7-3.1,0.2-3.4,0.3c-0.6,0.1-0.3-1.2-0.4-1.5
        c-0.2-0.7,3.1-0.2,3.4-0.4C80.6,813.7,80.3,814.9,80.4,815.3z M81.6,816.4l3.9,0.1 M85.5,816.6c3.9,0,2.8-10.3,3.5-13.5
         M89.1,803.1l1-16.3c-2.3,0.1-3.3,8.2-2.9,24.3c-0.1,3.4-0.6,5.2-1.7,5.4 M64.7,805.8l0.4,3.4l0.2,0.2 M63.6,796.4
        c0.8,2.2,1.2,5.3,1.1,9.4 M64.7,805.8l-1.3,3.6h2 M108.7,801.3l0-3.2 M106.8,800.3l1.7-1.9 M105.1,802.2l1.7-1.9 M106.8,800.3
        l1.9,1 M142.5,796.4c-11.3,1-22.6,1.6-33.8,1.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M109.6,776.4l29.4-0.7 M108.7,801.3c12-0.5,23.2-1.4,33.8-2.4
         M222.2,560.8l-0.2-4 M210.3,568.2l-0.1-8.8 M215,568.8l-4.7-0.6 M221,561.4c-2.1-15.5-0.1-60.1-11.7-69 M222,556.8l-1,4.6
         M222.2,560.8l-0.1-0.4 M210.3,559.3c0-14.2-1.1-47.7-1.1-50.8 M229.8,755.6c-0.2-23.7-0.7-87.7-0.7-110.4
        c-0.8-20.4,1.3-38.9-2.3-57.3 M226.8,588c1-10.2-2.9-15.8-4.6-27.1 M225.1,582.9c-2.6-6.8-3.9-14.6-4.1-21.5 M225.1,582.9l1.8,5
         M141.4,680.4c24.9-2.4,47.3-5.3,67.1-8.8c5-0.7,7.5-3.8,7.5-9.3c-0.4-21.3-0.7-73.1-1-93.5 M210.4,576.5l-0.1-8.3 M222,556.8
        c-2.7-18.2,2.2-63.7-12.2-77.9 M215,568.8c-0.3-17.8-0.8-34.8-1.5-51c-0.4-6.3-1.6-10.6-3.6-12.9v0l-0.8-0.9 M209.2,497
        c13.3,14.6,7.7,55.8,10.1,81.3c0,0,0.9,98.8,0.9,98.8 M209.1,504l0-7 M209.2,508.6l0-4.6 M207.7,490.5l1.5,1.9 M148.2,419
        c9.3,11.1,49.7,59.8,59.5,71.5 M209.3,492.4l-0.1,4.6 M138.9,527c-0.3-0.6,0.8-8.2-0.7-7.8c-0.6,0.2-3.7-0.6-3.6,0.7
        c0,0.8-0.1,6.1,0.2,6.7 M138.9,538.2c4.6-2.1,4.6-9.1,0-11.1 M138.9,527v11.1 M136.8,546.9c1.6,0,1.6-2.6,0-2.5
        C135.2,544.4,135.2,546.9,136.8,546.9L136.8,546.9z M134.5,542.6c0.3,0.6-0.7,7.2,0.7,6.9c0.6-0.2,3.8,0.6,3.7-0.7
        c0,0,0-6.2,0-6.2 M79,555.7c24.7-0.2,48.1,0.4,69.9,2.6 M153.3,560.1l-2.2-0.7 M150.5,559.2l-1.5-0.9 M151.1,559.4l-0.6-0.2
         M151.1,559.4L151.1,559.4 M151.1,559.5l-0.6-0.3 M153,548.6l0.3,11.5 M153.3,560.1l0.3,10.8 M210.3,559.3L153,548.6 M138.9,542.6
        v-4.5 M210.3,568.2l-57-8 M210.4,576.5l-56.7-5.6 M226.9,676.1l-6.9,1 M227.6,755.6c-0.1-9.4-1-71.9-0.7-79.5 M210.8,638.7l0-1.6
         M210.8,651.3c0.5,5.6-40.1,4.6-48.4,5.6c-5.4-0.4-8.4-4.1-8.8-11.3l-2.6-86.1 M155.5,633.9c0.5,5.8-1.4,20.2,6.3,20.7
        c14,0.7,29.3-0.1,47.1-1.9c0.9,0,1.7-0.4,2-1.5 M210.8,651.3V651 M210.8,651l-0.1-12.3 M155.4,631.9l0.1,2 M141.6,645.6
        c-0.3-0.6,0.8-8.2-0.7-7.8c-0.6,0.2-3.7-0.6-3.6,0.7c0,0.8-0.1,6.1,0.2,6.7 M141.6,656.7c4.6-2.1,4.6-9.1,0-11.1 M137.8,657.2
        c-0.6,0.8-0.6,3-0.5,4 M137.8,657.2c0.1-1.1,0.2-11.3-0.2-12.1 M141.6,645.6v11.1 M149,558.4l2.3,90.1c0.4,7.5,3.2,11.3,8.4,11.4
        c3.7-1.1,54.8-1.7,51.2-8.8 M137.2,661.2c0.3,0.6-0.7,7.2,0.7,6.9c0.6-0.2,3.8,0.6,3.7-0.7c0,0,0-6.2,0-6.2 M141.4,680.4
        l-0.2-10.7h-3.9l0.2,11 M137.2,661.2h4.4 M137.5,680.6l3.9-0.2 M141.6,661.2v-4.5 M210.8,638.7l-55.3-4.8 M155.4,631.9l55.4,5.2
         M226.9,676.1c0.4-42.1-0.2-73.2-1.9-93.2 M210.8,637.1l-0.4-60.6 M153.6,571l1.8,61 M151.8,402.2l1.5-10.3l-0.7-10.2
        c-3.3-27.9-2.8-94.8-20.9-100.2v3.7 M209.8,478.9l0.1-0.8l-1.5-2.2l-0.1,1.2 M151.2,404.7c0.4-0.8,0.6-1.7,0.7-2.6 M149.8,407.2
        c0.6-0.8,1-1.6,1.4-2.5 M208.3,477.1c-8.4-10.7-49.4-62.6-57.2-72.4 M172.5,470.3c6.5,0.1,32,34.2,36.7,38.2 M172.4,470.3
        L172.4,470.3 M172.5,470.3V470 M172.4,470.3l0-0.3 M135.6,431.8c3.4,0.5,6.6,1.2,9.5,2 M145.1,433.7c-0.9-38.9-2.5-79.4-5.6-113.3
        c-0.7-6.2-3.1-9.7-7.3-10.5 M147.5,426.1l0.7-7.1 M149.7,436.9l-2.2-2.5l-0.2-0.1 M145.1,433.7l2.2,0.6 M135.8,438.8l-0.2-7
         M147.4,434.3c0-2.9,0-5.6,0.2-8.2 M150.8,474.2c2.3,4.5,12.9,1.8,18.4,2.1c3.3-0.2,3.2-3.5,3.2-5.9 M158.6,474.8
        c3.9-0.6,13.9,2.2,13.8-4.4 M170.7,460.6c-0.8-1.5-1.8-2.7-3.2-3.6 M172.5,470c0-3.5-0.4-6.5-1.8-9.5 M167.5,457
        c-0.7-0.5-2-0.9-2.8-1.2 M151.1,462.9c0-1.8-0.6-7.2,0.7-8.3c5-4.4,8.4-5.9,12.9,1.2 M158.6,453.6c-0.8-2.7-7.4,2.9-7,4
        c0,0-0.6,5.2-0.6,5.2 M164.7,455.8l-6.1-2.1 M150.8,472.7l0.1,1.5 M150.7,471.9l0,0.7 M150.8,472.7V472 M150.8,472
        c0.6,3.6,5.1,2.2,7.8,2.7 M150.7,471.9L150.7,471.9 M150.8,472l0-0.1 M150.8,470l0,1.5 M150.7,471.5l0-0.5 M150.7,471l0-0.5
         M150.8,470l-0.1,0.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M150.7,471.5l0,0.5 M150.7,471.9v-0.4 M150.7,471l0,0.9 M151.1,462.9
        l-0.3,7.2 M158.6,474.8l0-21.1 M167.5,457l-17.8-20.1 M149.7,436.9l1,33.6 M132.4,440.5v8.9h3.4v-10.7 M148.2,419l1.6-11.8
         M149.8,407.2c-1.7-33.6-4.3-66.4-7.9-98.4c4,18.4,7.3,49.5,10,93.4 M135.8,438.8h-3.4v1.7 M121.4,399.8c3.5-1.5,8.3-1.1,8.4,3.4
        c0,0,0.4,17.4,0.4,17.4l0,0.6 M123.8,423.9c1.3-0.2,4.9,0.6,5-1.3c0,0-0.4-17.3-0.4-17.3c-0.1-2.6-2.4-3.2-4.6-2.7 M130.2,421.2
        c0.9,4.4-3,4.2-6.4,4.1 M123.8,425.3h-2.9 M123.8,425.3v-1.4 M120.9,423.9v1.4 M120.9,403.5c-8.3,3-5.2,11.9-5.7,18.5
        c0.1,0.6,0.1,1.8,0.9,1.9c0,0,4.8,0,4.8,0 M120.9,425.3c-2.6-0.2-7.8,1.3-7.2-3.4c0,0,0-11.9,0-11.9 M132.1,431.3l0.3,9.2
         M69.6,432.6c26.1-3,41.3-3.5,62.6-1.3 M69.6,432.6v-10.3 M79,555.7l-1.6-113.9c0.1-2.6-2.5-3.8-7.8-3.6 M69.6,438.3l-1,5
         M69.6,438.3l-0.1-5.7 M105.7,312.6c37.5,35.5,17.1,105.4-36.1,109.6 M113.7,410c0.1-2.5,1.4-4.6,4.1-6.3l0,0
        c1.1-0.7,2.1-1.3,3.1-1.8 M130.2,421.2l-0.4-16c-0.1-3.5-3-4.6-6-4.1 M123.8,402.5V401 M120.9,400v-0.2h0.5 M120.9,401.9v1.6
         M120.9,401.9v-2 M113.7,410c-0.3-3.4,0.6-6.1,4.1-8.3l0,0c1.1-0.8,2.1-1.4,3.1-1.8 M123.8,401v-1.3h-2.4 M72.8,415.7
        c59.2-15.6,57.7-102.2-2.5-114.9 M82.7,377.5L81,376 M86.9,362.5l2.2,0.2 M89.5,358.8l-0.4,3.9 M87.2,358.7l2.2,0.1 M83.4,358.5
        l3.8,0.2 M86.9,362.5l-3.8-0.3 M78.1,373.4l-2.3-2.1 M80,361.9l-8.6-0.8 M66.2,359c2.1-2-1.2-5.3-3.2-3.2
        C61,357.9,64.2,361.1,66.2,359L66.2,359z M69.4,365.6c-0.4-0.7-3-1.5-3.7-1.8l-1.8,1.6 M71.4,361.1c-0.8,0.2-3.1-1.2-3.8-1.5
        l-0.1-2.4 M67.4,368l0.9,0.9 M66.6,368.7l0.8-0.7 M65.9,369.3L65.9,369.3l0.6-0.6 M63.2,370.9l0.6,1 M63.8,365.4L62,367
        c0.2,0.8,0.6,3.3,1.2,3.9 M65.9,369.3l1.4-1.3 M66.6,370.2l-0.7-1 M68.3,368.9l-1.6,1.4 M75.8,371.4c2.2-2.8,3.5-6,4.2-9.5
         M71.4,361.1c-0.4,1.6-1,3.1-2,4.5 M66.6,368.7l-2.7-3.2 M70.2,366.4l-0.9-0.8 M75.8,371.4l-1.4-5.7c-1-0.2-2.4,0.1-4.1,0.7
         M70.2,366.4l5.6,5 M76.4,383.6l-1.3-1.8 M71.8,383.9l1.2,1.9 M76.4,383.6c-1.1,0.8-2.2,1.5-3.3,2.2 M78.4,378.8l1.6,1.6
         M80,380.4c-1.2,1.2-2.4,2.2-3.6,3.2 M75.7,376.2l2.7,2.7 M72.8,378.7c1-0.8,2-1.6,2.9-2.5 M63.5,376.7l-1.9-2.6l-1.5,0.4
        c-0.3,1.8,0.5,5.5,1.1,7.3l3.2-0.8L63.5,376.7L63.5,376.7z M75,381.8c1.2-0.9,2.3-1.9,3.4-2.9 M75,381.8l-2.2-3.1 M69.7,380.7
        l2.1,3.2 M81,376c3.2-4,5.1-8.5,5.9-13.5 M89.1,362.7c-0.8,5.5-3,10.4-6.4,14.8 M83,362.2l-3.1-0.3 M83,362.2
        c-0.7,4.2-2.3,7.9-4.9,11.3 M82.7,377.5c-0.8,1-1.7,2-2.6,3 M68.3,368.9l7.4,7.3 M72.8,378.7l-6.2-8.5 M63.9,371.8l5.9,8.9
         M81,376l-2.8-2.6 M69.6,409.1c53.9-13.2,55.2-89.2,1.2-103.2 M50.6,403.4c67.8,6.3,70.8-94.1,2.8-91.9 M58.8,412.2l2.7-0.7
         M56,412.9l2.8-0.7 M50.6,414.2l2.7-0.7 M53.4,413.6l2.6-0.7 M53.4,389.7c1.3,0.1,2.7,0.2,4,0.2 M48.7,388.9
        c1.5,0.4,3.1,0.7,4.7,0.8 M50.6,403.4v10.8 M57.6,387.7l0.2-3.8 M53.7,387.5l0.4-3.8 M55,375.7c-0.4-0.9-1.2-1.7-2.1-2.1
         M54.2,380.6l0.4-0.4 M50.7,381.9c1.3,0.1,2.5-0.3,3.5-1.3 M54.2,380.6l0.3,0 M54.4,380.6l0.1-0.5 M54.5,380.1l0.5-4.4
         M49.6,373.4c-2.5,0.9-3.5,4.2-2.1,6.4 M47.6,379.9c0,0-0.7,2-0.7,2 M50.3,383l0.3-1.2 M46.9,381.9L46.9,381.9 M50.3,383l-1,3.7
         M45.7,385.6l1.2-3.7 M45,387.7l0.7-2.1 M54.1,383.7l0.3-3.1 M54.1,383.7c-1.3-0.1-2.6-0.4-3.8-0.7 M46.9,381.9l-0.8-3.9
         M52.9,373.6l-2.3,8.3 M49.3,386.7c1.4,0.4,2.9,0.6,4.4,0.8 M58.4,373.2l0.1-1.1 M58.5,372.1c0.3-0.8-0.7-3.2-0.9-4l-2.4-0.4
         M60.3,366.8c2.8-0.9,1.4-5.2-1.4-4.3C56.1,363.4,57.5,367.7,60.3,366.8L60.3,366.8z M55.2,367.7l-2.4-0.5
        c-0.5,0.6-2.3,2.5-2.3,3.4 M55.2,373.3l0.1-1.2 M55.3,372.1L55.3,372.1l-1-0.2 M53.1,373l2.1,0.2 M53.4,371.7l-0.4,1.3
         M54.3,371.8l-0.9-0.2l0,0 M53.4,371.7l1.9,0.4 M54.3,371.8l0.9-4.1 M60.2,364.5c-1.2-1.6-1.6,1.7,0,0.4L60.2,364.5L60.2,364.5z
         M61,364.2l-1.1-1l-1.4,0.5l-0.3,1.4l1.1,1l1.4-0.4L61,364.2L61,364.2z M60,360.4c3.8-3.7-2.1-9.5-5.7-5.8
        C50.4,358.3,56.3,364.3,60,360.4L60,360.4z M53.4,362.5c0.6-2.9-4.1-3.6-4.4-0.7C48.4,364.6,53,365.4,53.4,362.5L53.4,362.5z
         M52,363.3l0.6-1.4l-0.9-1.2l-1.5,0.1l-0.6,1.4l0.9,1.2L52,363.3L52,363.3z M51.7,362.2c0.1-0.7-1-0.9-1.1-0.2
        C50.6,362.8,51.5,362.9,51.7,362.2L51.7,362.2z M46.6,367.6c0.8-0.2,2.1-2.5,2.5-3.2l-1.1-2.1 M48,362.3c-0.4-0.7-0.8-1.4-1.1-2.1
        c-0.8,0-3.4-0.2-4,0.3 M44.6,365.2L44.6,365.2l-0.4-0.9 M44.2,364.3l-0.4-0.8l-0.1,0 M43.7,363.5c0.3,0.6,0.6,1.2,0.9,1.7
         M44.2,364.3l3.8-1.9 M50.5,370.6c-1.4-0.7-2.8-1.8-3.9-3 M50.4,371.1l-0.7,2.3 M46.1,378l-1.1-5.4l5.4-1.5 M50.4,371.1l0.1-0.5
         M34.8,371.5l2.6-1.7 M37.4,369.8c-0.6-1-1.1-1.9-1.6-2.9 M42.8,360.5l-8.1,3 M26,366.7l2.1-0.8 M26,366.7
        c0.4,1.3,0.8,2.6,1.4,3.8 M29.4,369.7l-2.1,0.9 M33,368.1l-3.5,1.5 M28.1,365.9l3.6-1.3 M31.7,364.6l2.9-1.1 M33,368.1
        c0.5,1.2,1.1,2.3,1.8,3.4 M35.8,366.9l-2.9,1.2 M31.9,377.9c3.5,4.2,8.2,7.9,13.1,9.8 M29.7,374.8c0.7,1.1,1.5,2.1,2.3,3.1
         M29.7,374.8l1.9-1.2 M36.9,374.4c2.7,3.2,6.3,5.9,10,7.4 M33.8,376.6l3.2-2.2 M45.7,385.6c-4.4-1.7-8.7-5.1-11.9-8.9 M33.8,376.6
        l-1.8,1.3 M27.4,370.6c0.7,1.5,1.4,2.9,2.3,4.2 M55,375.7l0.2-2.4 M52.9,373.6l0.2-0.6 M37.4,369.8l7.2-4.7 M31.6,373.6l3.2-2.1
         M43.7,363.5l-7.9,3.4 M58.4,373.2l-0.6,10.6 M29.4,369.7c0.6,1.4,1.3,2.7,2.1,3.9 M39.5,372.6l-2.6,1.8 M46.6,367.6l-7.1,5
         M47.6,379.9l2.1-6.4 M39.5,372.6c1.9,2.1,4.2,4.1,6.6,5.4 M53.5,311.5c-54.7,5-57,83.6-2.8,91.9 M57.5,389.9l0.1-2.2 M45,387.7
        c1.2,0.5,2.4,0.9,3.7,1.2 M53.4,389.7l0.3-2.2 M49.3,386.7l-0.6,2.2 M56,433.4l3.4-0.2 M53.4,433.5l2.6-0.1 M53.4,433.5l-2.6,0.1
         M50.6,414.2l0.1,19.4 M120.9,403.5v20.4 M59.4,433.2l10.2-0.6 M60.3,555.7c-0.2-26.5-0.6-84.7-0.5-110.7c0.2-4.7,3.5-6.9,9.8-6.8
         M68.6,443.3c-4.2-0.4-6.5,1-7,4.1l1,108.4 M63.2,370.9c-1.5,0.7-3.1,1.1-4.8,1.2 M63.9,371.8c-1.7,0.8-3.5,1.2-5.4,1.4
         M57.6,387.7c5.2-0.1,10-1.3,14.2-3.7 M73.1,385.8c-4.7,2.7-9.9,4.1-15.6,4.1 M57.8,383.9c4.4-0.1,8.3-1.2,11.9-3.1 M69.6,422.2
        v-13.2 M61.5,411.5v12.4l8.1-1.6 M59.4,433.2l-0.6-21 M56,433.4v-20.4 M53.4,413.6v19.9 M61.5,411.5c2.8-0.8,5.5-1.6,8.1-2.4
         M123.8,402.5v21.4 M132.3,311.1l-0.1-1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M132.3,311.1l-3.3-0.3 M105.7,312.6l-0.3-3.7 M105.4,309l-0.5-5.1
         M105.4,309l23.6,1.9 M70.7,305.9l-0.4-5.1 M70.7,305.9c-2-0.5-3.9-1-5.9-1.5 M64.8,304.4c-0.4,0.2-9.5-3.9-10.1-4.1 M89.3,353.9
        c-0.1-1.3-0.3-2.6-0.6-3.9 M86.4,350.4l-3.8,0.7 M87.1,354.2l2.2-0.3 M80.2,355.1l3.1-0.4 M83.3,354.7l3.8-0.5 M80.3,358.4
        l-0.1-3.3 M88.7,350l-2.2,0.4 M74.7,342.4l2.2-2.2 M76.9,340.2l2.6-2.8 M74.3,337.6l-2.1,2.3 M78.2,333.1l-1.5,1.7 M73.2,332
        l1.2-1.9 M71.2,335.3l2-3.3 M73.2,332c1.2,0.8,2.4,1.7,3.6,2.7 M74.4,330.1c-1.1-0.7-2.3-1.3-3.4-1.9 M78.2,333.1
        c-1.2-1.1-2.5-2.1-3.9-2.9 M69.9,330.2l-1.9,3.4 M76.8,334.7l-2.5,2.9 M72.2,339.9c-0.8-0.7-1.7-1.4-2.6-2 M69.6,338l1.6-2.6
         M71.2,335.3c1.1,0.7,2.1,1.4,3.1,2.3 M70.9,328.2l-1.1,1.9 M68,333.6l-1.5,2.7 M71.7,357.3l0,0.7l8.6,0.4 M71.7,357.1v0.2
         M71.7,357.1v-0.2 M71.7,356.9v0.4 M71.7,356.9l0-0.8l8.5-1 M79.6,351.6l-8.5,1.5 M65.9,345.9l-2.6,3.4 M63.4,349.2l1.9,1.4
        c0.7-0.3,3.1-1.3,3.5-2 M72.2,339.9l-5.6,6.4l-0.7-0.5 M62.3,343.8c-0.6,0.6-0.8,3.2-0.9,4l1.9,1.4 M65.9,345.9l-0.8-0.6l4.5-7.3
         M71.1,353c-0.9-0.1-3,1.4-3.7,1.8l0.1,2.4 M64.6,356.8c-0.5,0-0.7,0.6-0.4,1l0.4,0.2l0.4-0.2C65.3,357.4,65.1,356.8,64.6,356.8
        L64.6,356.8z M66,356.7l-1.3-0.8l-1.3,0.8v1.5l1.3,0.8l1.3-0.8V356.7L66,356.7z M68.8,348.7c1.1,1.3,1.8,2.8,2.3,4.3 M67.5,357.2
        l4.2-0.1 M88.7,350c-1.2-5.3-3.7-10-7.6-14.2 M79.5,337.4l1.5-1.6 M86.4,350.4c-1.1-4.8-3.4-9.1-6.9-13 M82.7,351.1
        c-1-4-2.9-7.6-5.8-10.8 M79.6,351.6c-0.9-3.4-2.5-6.4-4.9-9.1 M82.7,351.1l-3.1,0.5 M68.8,348.7l6-6.2 M66.5,336.3l-4.2,7.6
         M81.1,335.8l-2.8-2.8 M69.2,295.5c13.8,2.7,26.2,6.5,36.6,17.1 M130.4,284.4c-1.7-1.5-3.9-1.9-6-2.3 M132.3,309.9
        c-0.4-6.5-0.7-18.1-0.6-24.6 M131.7,285.2l-1.3-0.9 M124.2,279.8l0.1,2.3 M124.2,279.8H123l0,0.4 M130.4,284.4v-2.7
        c-1-1-3.9-2-6.2-1.9 M124.3,282l-1.3-0.2 M123,280.2l0.1,1.6 M120.9,280.2c0-0.4,0-0.8-0.4-1 M120.9,281.4v-1.2 M120.5,279.2
        c-0.9-0.7-6.7-0.8-7.9-1l0.1,1.8 M123,280.2h-2.1 M123.1,281.8c-0.7-0.1-1.5-0.3-2.2-0.4 M112.8,280l0,0.7 M114.4,280.6h-1.5
         M114.4,280.6c2.1,0,4.5,0.5,6.5,0.8 M112.8,280.6h-4.6v-0.4 M108.2,280.2v-2.1h-6.4 M112.8,280l-4.5,0.3 M101.8,278.2
        c-2.9-1.2-1.5-6-3.2-8.1 M104.9,303.9c-0.5-6.4-0.7-13.7-0.6-21.7l-0.3-0.1 M104,282.1l2.9,0.2c1.8-1,4.2-1.6,7.4-1.7
         M101.4,279.6l-0.4-1.7h-1.6v1.8 M99.3,279.7l1,1.6c0.7,0,1.1-0.6,1.1-1.7 M101.4,279.6l-2.1,0.1 M101.8,278.2
        c-0.2,2.3,0.6,3.6,2.2,4 M120.5,279.2c-0.7,0.7-3.2,0.9-7.7,0.7 M95.6,278c-0.9-5.3-13.2-5.3-14.1,0
        C82.3,283.4,94.7,283.4,95.6,278L95.6,278z M94.9,277.6c-1-4.5-11.9-4.5-12.9,0C83,282,94,282.1,94.9,277.6L94.9,277.6z
         M98.6,270.1c-1.3-2-7.8-0.9-9.9-1.2v2.3c-2.1,0.5-4.6,0.7-7.4,0.8 M81.3,272c-0.7-1.7-0.9-3.6-3.4-3.6c0,0-7.8,0-7.8,0l-5.2,4.2
        l0.1,3.6 M70.3,271.9c-1.7,1.3-3.4,2.7-5.2,4.3 M64.3,281.1v-2.9l-7.1,6.4c0.3,1.2-0.4,6.3,0.9,6.8 M64.3,282.7v-1.6 M64.8,304.4
        l-0.9-8.9h5.2 M58.1,291.4c0.7,0.5,4.3-2.9,4.8-3.1c1.9-1.4,1.3-3.7,1.4-5.6 M69.2,295.5l1.1-23.6 M81.3,272l-11-0.1 M98.6,270.1
        c-0.4,8.7,1,31.2,6.4,33.8 M64.3,281.1L58,286v1.3 M51.8,287.7c-8.9-0.9-5.9,2.8-5.3,8.6c0.5,2.7,5.1,2,7.1,2.3 M58,287.3l0,4.1
         M58.2,282.5c-2.6,2.4-4.7,4.8-6.1,8.5 M52.1,291l-0.3-3.3 M58.2,282.5V276c-1.3,0-6.6,9.9-6.4,11.7 M170.2,792.8
        c13.7-2.6,26.8-5.6,39.3-9 M217,784.1c-18,4.6-35.8,8.4-53.5,11.5 M170.2,793.9c15.7-2.9,29.2-5.9,40.3-8.8 M58.5,352.5
        c2.7,1,4.2-3.4,1.4-4.3C57.2,347.3,55.8,351.6,58.5,352.5L58.5,352.5z M59.5,350.9l0.3-0.3c0.2-1-1.4-0.9-1,0.1l0.3,0.3
        C59.3,351,59.4,350.9,59.5,350.9L59.5,350.9z M59.6,351.8l1.1-1l-0.3-1.5l-1.4-0.5l-1.1,1l0.3,1.5L59.6,351.8L59.6,351.8z
         M152.5,795.4l-0.5,2.3 M162,794.3l-9.5,1.1 M53.2,353c-0.4-2.9-4.9-2.1-4.5,0.7C49.1,356.6,53.7,355.8,53.2,353L53.2,353z
         M52.5,343.7l1-0.3 M54.6,347.5l2.3-0.6c0.2-0.7,1-3.2,0.6-4 M53.5,343.4l1.1,4.1 M47.7,353.3l1-2.2c-0.5-0.6-1.9-2.8-2.7-2.9
         M45.9,348.2c1.1-1.2,2.4-2.4,3.7-3.2 M49.7,345c0.1,0.8,2,2.6,2.6,3.1l2.3-0.6 M43.8,351.6l0.4-0.8l0,0 M51.5,353.2
        c-0.2-1-1.7-0.1-0.9,0.5C51,354,51.6,353.7,51.5,353.2L51.5,353.2z M52.4,353.4l-0.6-1.4l-1.5-0.1l-0.9,1.2l0.6,1.4l1.5,0.1
        L52.4,353.4L52.4,353.4z M47.7,353.3l-3.9-1.8 M43.4,352.5L43.4,352.5l0.4-0.9 M42.7,355.6c0.7,0.5,3.3,0,4.1,0l1-2.2 M44.2,350.8
        l-0.8,1.8 M68,333.6c-3.6-1.7-7.8-2.4-12.1-2.2 M56.3,334.4l-0.4-3.1 M66.5,336.3c-3.1-1.3-6.6-2-10.1-1.8 M48.5,332.6l1.1,2.9
         M47.2,329l1.3,3.6 M43.7,330.5l1.5,3.5 M52.2,331.7c-1.3,0.2-2.5,0.5-3.7,1 M54.4,343.2l-1.5-8.4 M52.8,334.8l-0.6-3.1
         M57.5,342.9l-1.1-8.5 M45.1,334.1l1.2,2.9 M49.6,335.6c1-0.4,2.1-0.6,3.2-0.8 M30.6,339L30.6,339c-0.8,1.1-1.5,2.2-2.1,3.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M42.8,328.4c-4.6,2.3-9.1,6.2-12.2,10.6 M32.5,340.1
        c2.8-4,7-7.5,11.2-9.6 M152.5,795.4l-10,1 M45.1,334.1c-3.5,1.8-6.9,4.8-9.3,8.1 M32.5,340.1l-1.9-1.2 M42.8,360.5
        c-0.1-1.2-0.3-3.7-0.1-4.9 M34.3,353.2l8.3,2.4 M36.6,346.7c-0.5,1-1,2-1.3,3 M33.8,345.2l-3.4-1.8 M25.5,350.6l0.5,0.1 M26,350.7
        l-0.5-0.1 M25.5,350.6L25.5,350.6 M26.5,346.8c-0.4,1.2-0.8,2.5-1.1,3.8 M28.6,347.5l3.6,1.2 M26.5,346.8l2.1,0.7 M33.8,345.2
        c-0.6,1.1-1.1,2.3-1.6,3.5 M30.5,343.4c-0.7,1.3-1.3,2.7-1.8,4.1 M31.3,352.3c-0.8,3.9-0.6,8.5,0.4,12.3 M27.6,351.2l3.7,1.1
         M28.1,365.9c-1.3-4.5-1.4-10.1-0.5-14.7 M32.3,348.7l3,1 M36.6,346.7l-2.8-1.5 M34.7,363.5c-0.9-3-0.7-7.2-0.3-10.3 M31.3,352.3
        l3,0.9 M26,350.7l1.7,0.5 M28.4,342.4c-0.8,1.4-1.4,2.9-1.9,4.4 M49.6,335.6l2.9,8.1 M30.5,343.4l-2-1 M44.2,350.8l-7.6-4
         M35.2,349.7l8.1,2.8 M42.8,328.4l0.9,2.1 M53.5,343.4l0.9-0.2 M38.5,343.7l-2.7-1.6 M46.3,337c-2.9,1.5-5.8,4-7.8,6.7 M46.3,337
        l3.3,8 M45.9,348.2l-7.4-4.5 M54.4,343.2c-0.6,0.1-1.2,0.3-1.9,0.5 M51.1,325.7c-1.6,0.3-3.2,0.7-4.7,1.2 M55.1,325.3
        c-1.3,0.1-2.6,0.2-3.9,0.4 M70.9,328.2c-4.7-2.3-10.3-3.3-15.9-2.9 M55.4,327.5c5.1-0.2,10.2,0.6,14.5,2.7 M55.4,327.5l-0.3-2.2
         M51.6,327.9l-0.4-2.2 M54.7,300.4l-0.6-0.3l-0.6,11.4 M53.7,298.6c0.3,0.8,0.7,1.4,1,1.8 M25.5,350.6c-1.1,5.1-0.9,11.2,0.5,16.1
         M46.5,326.9c-1.2,0.4-2.5,0.9-3.7,1.5 M55.9,331.3l-0.5-3.8 M46.5,326.9l0.8,2.1 M51.6,327.9l0.7,3.8 M47.2,329
        c1.4-0.5,2.9-0.8,4.3-1.1 M65,276.2c-2.2,1.9-4.5,4-6.8,6.3 M70.3,300.8l-1.2-5.3 M57.5,342.9c1.7,0,3.3,0.3,4.8,0.9 M152,797.7
        l6.5-0.9 M142.5,798.8v-2.5 M53.7,298.6c-0.6-1.6-1.1-4.2-1.6-7.6 M64.3,282.7l-6.2,4.6 M142.5,803.5l8.4-1.1 M142.5,798.8v4.7
         M142.5,803.5v1.7l8-1.2l0.4-1.6 M150.9,802.4l1.1-4.6 M162.6,795.4l0.9-0.2 M163.5,795.7l-1.4,0.2 M163.4,794.1l-1,0.2
         M162.4,794.2l0.1,1 M162.1,795.9l-0.1-1.6 M80.3,358.4l3.1,0.1 M129,310.8l3.1,120.4 M89.5,358.8l-0.2-4.8 M87.2,358.7l-0.2-4.5
         M83.3,354.7l0.1,3.8 M111.7,682l4.8-0.2 M116.5,681.8l9.7-0.5 M88.7,682l-2.2,0.4 M86.4,682.4l-3.8,0.6 M89.3,685.9
        c-0.1-1.3-0.3-2.7-0.6-3.9 M83.3,686.6l3.8-0.5 M87.1,686.2l2.2-0.3 M80.3,690.3l-0.1-3.3 M83.4,690.5l-3.1-0.1 M83.3,686.6
        l0.1,3.8 M80.2,687l3.1-0.4 M76.9,672.2l2.6-2.8 M74.7,674.4l2.2-2.2 M74.3,669.6l-2.1,2.3 M73.2,664c1.2,0.8,2.4,1.7,3.6,2.7
         M71.2,667.3l2-3.3 M73.2,664l1.2-1.9 M78.2,665l-1.5,1.7 M78.2,665c-1.2-1.1-2.5-2.1-3.9-2.9 M69.9,662.1l-1.9,3.4 M76.8,666.7
        l-2.5,2.9 M72.2,671.9c-0.8-0.7-1.7-1.4-2.6-2 M69.6,669.9l1.6-2.6 M71.2,667.3c1.1,0.7,2.1,1.5,3.1,2.3 M68,665.5l-1.5,2.7
         M80.2,687l-8.5,1l0,0.7 M71.7,688.8v0.4 M71.7,689v-0.2 M71.7,689.2V689 M71.7,689.2l0,0.7l8.6,0.4 M79.6,683.5l-8.5,1.5
         M72.2,671.9l-5.6,6.4l-0.7-0.5 M63.4,681.2l1.9,1.4c0.7-0.3,3.1-1.3,3.5-2 M65.9,677.8l-2.6,3.4 M62.3,675.8
        c-0.6,0.6-0.8,3.2-0.9,4l1.9,1.4 M65.9,677.8l-0.8-0.6l4.5-7.3 M71.1,685c-0.9-0.2-3,1.4-3.7,1.8l0.1,2.4 M64.2,689.8l0.4,0.1
        l0.4-0.1C65.8,688.5,63.4,688.5,64.2,689.8L64.2,689.8z M64.6,687.1c-2.9-0.1-2.9,4.6,0,4.5C67.5,691.7,67.5,687,64.6,687.1
        L64.6,687.1z M64.6,687.9l-1.3,0.8v1.5l1.3,0.8l1.3-0.8v-1.5L64.6,687.9L64.6,687.9z M71.1,685c-0.5-1.6-1.3-3-2.3-4.3 M71.7,689
        l-4.2,0.1 M79.5,669.4c3.5,3.8,5.8,8.1,6.9,13 M68.8,680.6l6-6.2 M66.5,668.2l-4.2,7.6 M79.5,669.4l1.5-1.6 M88.7,682
        c-1.2-5.3-3.7-10-7.6-14.2 M82.7,683c-1-4-2.9-7.6-5.8-10.8 M79.6,683.5c-0.9-3.4-2.5-6.4-4.9-9.1 M82.7,683l-3.1,0.5 M81.1,667.8
        l-2.8-2.8 M82.7,709.4l-1.7-1.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M89.5,690.7l-2.2-0.1 M86.9,694.5l2.2,0.2 M83,694.1l3.8,0.3
         M89.1,694.7l0.4-3.9 M78.1,705.4l-2.3-2.1 M71.4,693.1l8.6,0.8 M67.5,689.2l0.1,2.4c0.7,0.3,3,1.7,3.8,1.5 M69.4,697.6
        c-0.4-0.7-3-1.5-3.7-1.8l-1.8,1.6 M66.6,700.6l0.8-0.7 M67.4,700l0.9,0.9 M65.9,701.2L65.9,701.2l0.6-0.6 M63.8,697.4L62,699
        c0.2,0.8,0.6,3.3,1.2,3.9 M63.2,702.8l0.6,1 M67.4,700l-1.4,1.3 M66.6,702.2l-0.7-1 M68.3,700.8l-1.6,1.3 M71.4,693.1
        c-0.4,1.6-1,3.1-2,4.5 M63.8,697.4l2.7,3.3 M70.2,698.3l-0.9-0.8 M75.8,703.3c2.2-2.8,3.5-6,4.2-9.5 M70.2,698.3
        c1.7-0.7,3.1-0.9,4.1-0.7l1.4,5.7 M75.8,703.3l-5.6-5 M71.8,715.9l1.2,1.9 M76.4,715.6l-1.3-1.8 M73.1,717.8
        c1.1-0.7,2.2-1.4,3.3-2.2 M75.7,708.1l2.7,2.7 M78.4,710.8l1.6,1.6 M76.4,715.6c1.3-1,2.5-2,3.6-3.2 M72.8,710.7
        c1-0.8,2-1.6,2.9-2.5 M63.5,708.7l-1.9-2.6l-1.5,0.4c-0.3,1.8,0.5,5.5,1.1,7.3l3.2-0.8L63.5,708.7L63.5,708.7z M75,713.8
        c1.2-0.9,2.3-1.9,3.4-2.9 M75,713.8l-2.2-3.1 M69.7,712.7l2.1,3.2 M81,707.9c3.2-4,5.1-8.5,5.9-13.5 M81,707.9l-2.8-2.6
         M82.7,709.4c3.5-4.4,5.6-9.3,6.4-14.8 M80,712.4c1-1,1.8-1.9,2.6-2.9 M78.1,705.4c2.6-3.4,4.2-7.1,4.9-11.3 M80,693.9l3.1,0.3
         M68.3,700.8l7.4,7.3 M72.8,710.7l-6.2-8.5 M63.9,703.8l5.9,8.9 M87.2,690.6l-3.8-0.2 M87.2,690.6l-0.2-4.5 M89.5,690.7l-0.2-4.8
         M137.5,645.1c-6.4,1.3-6.2,11.1,0.2,12.1 M71.2,622.5L71.2,622.5 M62.7,555.7l0.6,59.6c-0.2,4,4,4.1,6.8,4.8 M71.3,622.5
        c3.6,0.8,9.3,2.9,8.7-3.6c0,0-0.9-63.1-0.9-63.1 M71.3,625.7l0-3.3 M60.3,555.7l0.4,58.9c-0.9,6.6,6.1,6.8,10.5,7.9 M71.3,625.7
        c-3.5-0.7-7.5-1.4-11.1-2 M71.2,622.5l-1.2-2.3 M126.2,681.2c-2.6-28.8-26.9-52-54.9-55.5 M71.4,634.7c22.6,3.9,42.4,24.1,45.1,47
         M71.4,639l0-4.3 M111.7,682c-2.4-20.3-20.4-38.7-40.3-43 M71.4,634.7l-0.1-9 M55.4,659.5c5.1-0.3,10.2,0.6,14.5,2.7 M55.4,659.5
        l-0.3-2.2 M51.6,659.9l-0.4-2.2 M51.1,657.7c-1.6,0.3-3.2,0.7-4.7,1.2 M55.1,657.2c-1.3,0.1-2.6,0.2-3.9,0.4 M70.9,660.2
        c-4.7-2.3-10.3-3.3-15.9-2.9 M52.8,634.4v9.1 M60.2,636.6v-4.1 M60.2,636.6c-1.7-0.3-3.4-0.6-5.2-0.9 M52.8,634.4
        c0.4,1,1.3,1.2,2.2,1.2 M60.2,623.7c-6.2,1.2-1.9-65.3-3.3-68 M50.7,433.6l1.2,195.6c0.1,2.4,0.4,4.2,0.9,5.3 M60.2,623.7v8.7
         M57.6,719.6l0.2-3.8 M53.7,719.5l0.4-3.8 M54.5,712.1c0-0.4,0.4-4.1,0.5-4.4 M55,707.7c0-0.4,0.2-2,0.2-2.4 M55,707.7
        c-0.4-0.9-1.2-1.7-2.1-2.1 M54.2,712.5l0.4-0.4 M54.4,712.6l-0.3,0 M50.7,713.8c1.3,0.1,2.5-0.3,3.5-1.3 M54.4,712.6l0.1-0.5
         M49.6,705.4c-0.2,0.5-1.9,5.9-2.1,6.4 M49.6,705.4c-2.5,0.9-3.5,4.2-2.1,6.4 M46.9,713.9c0,0,0.7-2,0.7-2 M45,719.7l0.7-2.2
         M45.7,717.5l1.2-3.7 M50.3,715l-1,3.7 M50.7,713.8l-0.3,1.2 M46.9,713.9L46.9,713.9 M52.9,705.5l-0.1,0.4l-2.1,7.9 M49.3,718.7
        c1.4,0.4,2.9,0.6,4.4,0.8 M54.1,715.7l0.3-3.1 M46.9,713.8l-0.8-3.9 M50.3,715c1.2,0.3,2.5,0.5,3.8,0.7 M58.5,704.1
        c0.3-0.8-0.7-3.2-0.9-4l-2.4-0.4 M58.4,705.2l0.1-1.1 M60.3,698.8c2.8-0.9,1.4-5.2-1.4-4.3C56.2,695.3,57.5,699.7,60.3,698.8
        L60.3,698.8z M50.5,702.6c0-0.9,1.8-2.8,2.3-3.4l2.4,0.5 M55.2,705.2l0.1-1.2 M55.3,704L55.3,704l-1-0.2 M54.3,703.8l-0.9-0.2
        l0,0.1 M52.9,705.5c0-0.1,0.1-0.5,0.2-0.6 M53.4,703.7l1.9,0.4 M53.4,703.7l-0.4,1.3 M55.2,705.2l-2.1-0.3 M55.2,699.6l-0.9,4.2
         M60.2,696.5c-0.2-0.7-1.2-0.4-1.1,0.3c0.3,0.4,0.7,0.5,1,0.1L60.2,696.5L60.2,696.5z M59.9,695.2l-1.4,0.5l-0.3,1.5l1.1,1
        l1.4-0.4l0.3-1.5L59.9,695.2L59.9,695.2z M162.4,794.2l-0.4,0.1 M163.4,794.1l-0.5-11.4c0-0.1-0.1-0.4-0.2-0.5 M161.5,782.8
        c-0.1-0.2-0.1-0.3-0.2-0.5c0.1,0,1.4-0.1,1.5-0.2 M170.2,792.8l-6.8,1.3 M170.2,793.9v-1.1 M163.5,795.7l0-0.5 M163.5,795.2
        l6.7-1.2 M60,692.4c3.8-3.7-2.1-9.5-5.7-5.8C50.4,690.3,56.3,696.2,60,692.4L60,692.4z M158.8,796.8l-0.3,0.1 M53.4,694.4
        c0.6-2.9-4.1-3.6-4.4-0.7C48.4,696.6,53,697.4,53.4,694.4L53.4,694.4z M52,695.3l0.6-1.4l-0.9-1.2l-1.5,0.2l-0.6,1.4l0.9,1.2
        L52,695.3L52,695.3z M51.1,694.7c1.1,0.1,0.6-1.6-0.3-1C50.5,693.9,50.6,694.6,51.1,694.7L51.1,694.7z M48,694.3l-1.1-2.2
        c-0.8,0-3.4-0.2-4,0.3 M44.6,697.1L44.6,697.1l-0.4-0.9 M43.7,695.4l0.9,1.7 M44.2,696.2l-0.4-0.8l-0.1,0 M48,694.3l-3.8,1.9
         M46.6,699.6c0.8-0.2,2.1-2.5,2.5-3.2l-1.1-2.1 M49.6,705.4c0.1-0.3,0.6-2,0.7-2.3 M50.4,703.1l0.1-0.5 M50.5,702.6
        c-1.4-0.7-2.8-1.8-3.9-3 M46.1,710l-1.1-5.4l5.4-1.5 M37.4,701.8l-1.6-2.9 M34.8,703.5l2.6-1.7 M42.8,692.5l-8.1,3 M31.7,696.6
        l-3.6,1.3 M33,700.1l-3.5,1.5 M29.4,701.6l-2.1,0.9 M29.4,701.6c0.6,1.3,1.3,2.6,2.1,3.9 M26,698.7c0.4,1.3,0.8,2.6,1.4,3.9
         M31.6,705.6l3.2-2.1 M26,698.7l2.1-0.8 M35.8,698.9l-2.9,1.2 M34.7,695.5l-2.9,1.1 M33,700.1c0.5,1.2,1.1,2.3,1.8,3.4
         M33.8,708.6l3.2-2.2 M33.8,708.6c3.2,3.8,7.5,7.1,11.9,8.9 M31.9,709.9l1.8-1.3 M31.9,709.9c3.5,4.2,8.2,7.9,13.1,9.8
         M29.7,706.8c0.7,1.1,1.5,2.1,2.3,3.1 M46.9,713.8c-3.7-1.5-7.3-4.4-10-7.4 M43.7,695.4l-7.9,3.4 M44.6,697.1l-7.2,4.7
         M29.7,706.8l1.9-1.2 M46.6,699.6l-7.1,5 M27.4,702.5c0.7,1.5,1.4,2.9,2.3,4.3 M36.9,706.4l2.6-1.8 M39.5,704.6
        c1.8,1.8,4.3,4.3,6.6,5.4 M58.4,705.2l-0.6,10.6 M158.5,796.9l0.3-0.4l3.3-0.6 M220.1,677.1l-51.3,7.2c-5.7,0.2-8.5,2.7-8.2,7.3
        c5.9,81.3-21.5,89.1,60.4,70.8 M138.9,775.8l3.9-0.1 M155.4,778L155.4,778 M162.7,782.1c-2.9-6-12.7-7.3-19.9-6.5 M209.2,757.5
        c4.4-0.4,6.4-3.1,6.1-8v-56.9c0.1-4.1-1.4-6.1-4.6-6.1l-38.5,5c-4,0.2-5.9,2.8-5.5,7.7l1.1,57.2
        C167.9,768.8,201.9,758.1,209.2,757.5L209.2,757.5z M228.6,768.2c0-1.5,0-3.1,0-4.6 M58.5,684.5c2.7,0.9,4.2-3.4,1.4-4.3
        C57.2,679.2,55.8,683.6,58.5,684.5L58.5,684.5z M59.6,683.8l1.1-1l-0.3-1.5l-1.4-0.5l-1.1,1l0.3,1.5L59.6,683.8L59.6,683.8z
         M53.2,684.9c-0.4-2.9-4.9-2.2-4.5,0.7C49.1,688.5,53.7,687.8,53.2,684.9L53.2,684.9z M227.6,755.6h2.2 M227.7,760.9l0.7-0.7
         M228.3,772.1l-1.3-1.8 M59.8,682.5c0.2-1-1.4-0.9-1,0.1l0.3,0.3C59.4,682.9,59.7,682.8,59.8,682.5L59.8,682.5z M54.6,679.5
        l2.3-0.6c0.2-0.7,1-3.2,0.6-4 M43.8,683.5l0.4-0.8l0,0 M49.7,676.9c0.1,0.8,2,2.6,2.6,3.1l2.3-0.6 M45.9,680.1
        c1.1-1.2,2.4-2.4,3.7-3.2 M47.7,685.3l1-2.2c-0.5-0.6-1.9-2.8-2.7-2.9 M51.4,685.6c0.5-0.5-0.5-1.3-0.9-0.7
        C50.1,685.6,50.9,686.2,51.4,685.6L51.4,685.6z M52.4,685.4c-0.2-0.4-0.4-0.9-0.6-1.4l-1.5-0.2l-0.9,1.2l0.6,1.4l1.5,0.1
        L52.4,685.4L52.4,685.4z M47.7,685.3l-3.9-1.8 M43.4,684.5L43.4,684.5l0.4-0.9 M42.7,687.6c0.7,0.5,3.3,0,4.1,0l1-2.2 M44.2,682.7
        l-0.8,1.8 M53.5,675.4l0.9-0.2 M68,665.5c-3.6-1.7-7.8-2.4-12.1-2.2 M56.3,666.4l-0.4-3.1 M66.5,668.2c-3.1-1.3-6.6-2-10.1-1.9
         M48.5,664.6l-1.3-3.6 M43.7,662.5l1.5,3.6 M48.5,664.6l1.1,3 M52.2,663.6c-1.3,0.2-2.5,0.6-3.7,1 M54.4,675.2l-1.5-8.4
         M57.5,674.9l-1.1-8.6 M52.8,666.7l-0.6-3.1 M49.6,667.5c1-0.4,2.1-0.6,3.2-0.8 M45.1,666l1.2,2.9 M45.1,666
        c-3.5,1.8-6.9,4.8-9.3,8.1 M42.8,660.4c-4.6,2.3-9.1,6.2-12.2,10.5 M32.5,672.1l-1.9-1.1 M30.5,675.4l-2-1 M30.6,671L30.6,671
        c-0.8,1.1-1.5,2.2-2.1,3.4 M35.8,674.1l-3.3-2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M32.5,672.1c2.8-3.9,7-7.5,11.2-9.6 M34.3,685.1l8.3,2.4 M42.8,692.5
        c-0.1-1.2-0.3-3.7-0.1-4.9 M36.6,678.7c-0.5,1-1,2-1.3,3 M28.6,679.5l3.6,1.2 M26.5,678.8l2.1,0.7 M26,682.7l-0.5-0.1 M25.5,682.6
        l0.5,0.1 M26.5,678.8c-0.4,1.2-0.8,2.5-1.1,3.8 M33.8,677.2c-0.6,1.1-1.1,2.3-1.6,3.5 M25.5,682.6L25.5,682.6 M28.1,697.9
        c-1.3-4.5-1.4-10-0.5-14.7 M27.6,683.2l3.7,1.1 M31.3,684.3c-0.8,3.9-0.6,8.5,0.4,12.3 M26,682.7l1.7,0.5 M36.6,678.7l-2.8-1.5
         M34.7,695.5c-0.9-3-0.7-7.2-0.3-10.3 M32.3,680.7l3,1 M31.3,684.3l3,0.9 M54.6,679.5l-1.1-4.1 M33.8,677.2l-3.4-1.8 M44.2,682.7
        l-7.6-4.1 M35.2,681.7l8.1,2.8 M52.5,675.6l1-0.2 M49.6,667.5l2.9,8.1 M42.8,660.4l0.9,2.1 M46.3,668.9l3.3,8 M30.5,675.4
        c-0.7,1.3-1.3,2.7-1.8,4.1 M45.9,680.1l-7.4-4.5 M54.4,675.2c-0.6,0.1-1.2,0.3-1.9,0.5 M28.4,674.4c-0.8,1.4-1.4,2.9-1.9,4.4
         M38.5,675.7l-2.7-1.6 M46.3,668.9c-2.9,1.5-5.8,4-7.8,6.8 M25.5,682.6c-1.1,5.1-0.9,11.1,0.5,16.1 M55.9,663.3l-0.5-3.8
         M70.9,660.2l-1.1,2 M57.5,674.9c1.7,0,3.3,0.3,4.8,0.9 M63.2,702.8c-1.5,0.7-3.1,1.1-4.8,1.2 M57.6,719.6
        c5.2-0.1,10-1.3,14.2-3.8 M52.2,663.6l-0.7-3.8 M47.2,661c1.4-0.5,2.9-0.9,4.3-1.1 M46.5,658.9l0.8,2.1 M46.5,658.9
        c-1.2,0.4-2.5,0.9-3.7,1.5 M74.4,662.1c-1.1-0.7-2.3-1.4-3.4-1.9 M60.2,632.4c3.8,0.7,7.5,1.4,11.1,2.3 M71.4,639
        c-3.6-1-7.3-1.8-11.2-2.5 M63.9,703.8c-1.7,0.8-3.5,1.2-5.4,1.4 M57.8,715.8c4.4-0.1,8.3-1.1,11.9-3.1 M135.1,538.7
        c-0.6,0.8-0.6,3-0.6,4 M70.7,555.7H79 M69.5,555.7h1.1 M69.5,555.7h-6.8 M134.8,526.6c-6.4,1.3-6.2,11.1,0.3,12.1 M135.1,538.7
        c0.1-1.1,0.2-11.3-0.3-12.1 M56.9,555.7h3.4 M62.7,555.7h-2.4 M71.3,622.5l-0.6-66.7 M70.1,620.1l-0.5-64.4 M126.2,681.2l11.3-0.6
         M153,548.6l-2.2-74.4 M209.2,497l-61.6-70.9 M149,558.4l-3.9-124.6 M209.1,504l-38.4-43.4 M56.9,555.7L56,433.4 M70.7,555.7
        l-1-117.5 M55,635.7l-1.7-202.2 M135.6,431.8c-1.8-72.7-2.9-112.9-3.3-120.7 M132.1,431.3l3.5,0.5 M68.6,443.3l0.9,112.5
         M134.5,542.6h4.4 M227.2,768.5l1.3,2.1 M207.7,490.5l0.6-13.4 M209.8,478.9l-0.5,13.5 M147.4,434.3l3.7,125.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M227,770.3l0.2-1.8 M162.7,782.1l46.7-8.6c7.3-2.1,11.3-5.8,11.6-11.2
         M221,762.4l6.7-1.5 M227.7,760.9L227.7,760.9 M227.7,760.9L227.7,760.9 M227.7,760.9l-0.1-5.3 M227.2,768.5
        c0-0.6,0.6-7.2,0.5-7.6 M227.7,760.9L227.7,760.9 M228.5,760.2c0.9-1,1.3-2.6,1.3-4.5 M228.6,768.2c0.7-0.7,1-2.3,0.9-4.6h-0.9
         M228.6,763.5l-0.1-3.4 M228.5,770.6l0.1-2.5 M228.5,770.6l-0.1,1.7 M228.3,772.1l0.2,0.3 M215.7,782.1l0.9,1.3 M209.5,783.8
        l1,1.3 M217,784.1c2-0.6,4.1-0.6,5.6,0.3 M216.5,783.5l0.4,0.7 M210.5,785.1l6.1-1.7 M215.7,782.1l-6.2,1.7 M227,770.3
        c-1,7-1.6,8.9-11.3,11.9 M222.6,784.4c6.1-0.9,4.5-6.6,5.7-12.4 M228.5,772.3c-0.5,3.6,0.4,16-5.9,12.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M35.8,342.1l-3.3-2 M49.3,718.7l-0.6,2.2 M57.5,721.9l0.1-2.2
         M53.4,721.7l0.3-2.2 M142.9,775.7l-1.5-95.3 M137.5,680.6l1.5,95.1 M45,719.7c1.2,0.5,2.4,0.9,3.7,1.2 M57.5,721.9
        c5.7-0.1,10.9-1.4,15.6-4.1 M220.1,677.1l0.9,85.3"/>
    </g>
  </g>
  <g id="right">
    <g>
      <path fill="none" d="M553.6,790.1c-6.3,3.8-5.3-8.5-5.9-12.1l0.2-0.3C549.1,783.5,547.5,789.2,553.6,790.1L553.6,790.1z"/>
      <path fill="none" d="M553.6,790.1c-6.1-0.9-4.5-6.6-5.7-12.4l1.3-1.8c1,7,1.6,8.9,11.3,11.9c-0.3,0.5-1,1.6-1.3,2
        C557.1,789.1,555,789.1,553.6,790.1L553.6,790.1z"/>
      <path fill="none" d="M560.5,787.7c-5.7-1.7-9.9-4.1-10-6c-1.4-4.3-1.9-10.6-2-15.2l6.7,1.5c0.4,5.4,4.3,9.1,11.6,11.2
        c0,0,46.7,8.6,46.7,8.6c-0.1,0.1-0.2,0.4-0.2,0.5c0,0-0.5,11.4-0.5,11.4C594.9,796.5,577.3,792.6,560.5,787.7L560.5,787.7z"/>
      <polygon fill="none" points="560.5,787.7 566.7,789.5 565.7,790.7 559.6,789.1 560.5,787.7 		"/>
      <path fill="none" d="M559.6,789.1c15.6,4.4,33.7,8.2,53.1,11.7l0,0.5c-17.7-3.1-35.6-7-53.5-11.5L559.6,789.1L559.6,789.1z"/>
      <path fill="none" d="M565.7,790.7l1-1.3c12.5,3.4,25.6,6.4,39.3,9v1.1C590.2,796.6,576.8,793.7,565.7,790.7L565.7,790.7z"/>
      <polygon fill="none" points="547.9,777.7 547.7,778 547.6,776.2 549,774.1 549.2,775.9 547.9,777.7 		"/>
      <path fill="none" d="M547.6,776.2c-0.1-3.3-0.1-7.1,0.1-10.4c0.1,0,0.7,0.7,0.8,0.7c-0.1,0,0.5,7.3,0.5,7.6L547.6,776.2
        L547.6,776.2z"/>
      <path fill="none" d="M546.6,769.2h0.9c0,1.5,0,3.1,0,4.6C546.9,773.1,546.6,771.5,546.6,769.2L546.6,769.2z"/>
      <path fill="none" d="M547.7,765.8c-0.9-1-1.3-2.6-1.3-4.5h2.2l-0.1,5.3C548.4,766.6,547.7,765.8,547.7,765.8L547.7,765.8z"/>
      <polygon fill="none" points="548.5,766.6 548.4,766.5 548.5,766.5 		"/>
      <path fill="none" d="M548.5,766.5c0.1-13.3,1-72.2,0.7-84.8l6.9,1l-0.9,85.3L548.5,766.5L548.5,766.5z"/>
      <path fill="none" d="M555.2,768.3V768c81.9,18.4,54.5,10.6,60.4-70.8c0.2-4.6-2.5-7.1-8.2-7.3l-51.3-7.2
        c0.7-42.5-0.2-128.1,4-167.3c0.6-4.2,3.2-8.8,6.9-12.8l0,7l-0.8,0.9v0c-2,2.4-3.2,6.7-3.6,12.9c-0.7,16.2-1.2,33.2-1.5,51
        c-0.3,20.2-0.7,72.4-1,93.5c0,5.5,2.5,8.6,7.5,9.3c19.8,3.4,42.2,6.4,67.1,8.8l-1.5,95.3c-7.2-0.9-16.9,0.5-19.9,6.5l-46.7-8.6
        C559.5,777.2,555.7,773.5,555.2,768.3L555.2,768.3z"/>
      <path fill="none" d="M548.6,761.3h-2.2c0.7-47.1,0.1-114.6,1.5-160.5c0.1-3.9,1.9-8.5,3.2-12.2
        C547.8,622.9,549.8,714.9,548.6,761.3z"/>
      <path fill="none" d="M620.1,782.3c3.9-2,12.4-0.8,17.1-0.9c0,0,29.4,0.7,29.4,0.7c1.6-0.8,0.5,21.3,0.9,21.6l-1.4,0
        c0-6.1-0.1-14.1-0.2-20.2c-12,0.4-33.5-2.3-45.1,0.1h0c-3.1,1.6-5,3.1-5.8,4.4c-0.1,0-1.4-0.1-1.5-0.2
        C614.1,786.2,616.3,784.3,620.1,782.3L620.1,782.3z"/>
      <path fill="none" d="M620.8,783.6L620.8,783.6c3.5-2.1,24.1-0.2,28.5-0.5v7c-12.4-0.3-23.8-1-34.3-2.1
        C615.7,786.7,617.7,785.2,620.8,783.6L620.8,783.6z"/>
      <polygon fill="none" points="637.2,781.4 633.3,781.3 634.7,686 638.7,686.3 		"/>
      <path fill="none" d="M615.6,697.3c0.2-4.6-2.5-7.1-8.2-7.3l-51.3-7.2l-0.9,85.3c13.4,1,59.8,18.9,59.4-1L615.6,697.3z M567,763.1
        c-4.4-0.4-6.4-3.1-6.1-8c3.5-74.1-17-65.2,43.1-58c4,0.2,5.9,2.8,5.5,7.7c0,0-1.1,57.2-1.1,57.2
        C608.6,774.3,574.1,763.7,567,763.1L567,763.1z"/>
      <path fill="none" d="M592.5,767.9l-25.6-4.8c-4.4-0.4-6.4-3.1-6.1-8v-56.9c-0.1-4.1,1.4-6.1,4.6-6.1l38.5,5c4,0.2,5.9,2.8,5.5,7.7
        l-1.1,57.2C608.6,769.6,598.9,767,592.5,767.9L592.5,767.9z"/>
      <polygon fill="none" points="617.7,802.5 617.4,802.1 614.1,801.5 614.2,800 623.6,801 624.2,803.4 617.7,802.5 		"/>
      <path fill="none" d="M612.7,800.8l-6.7-1.2v-1.1c0.6,0.1,7.4,1.4,7.8,1.4l-0.1,1l0.1-1l0.4,0.1l-0.1,1.6l-1.4-0.2L612.7,800.8
        l0.9,0.2L612.7,800.8L612.7,800.8z"/>
      <path fill="none" d="M613.6,787.8l1.3,0.1c-0.5,0.6-0.6,11.5-0.8,12c-0.3-0.1-1.1-0.2-1.4-0.2l0.5-11.4c0-0.1,0.1-0.4,0.2-0.5
        L613.6,787.8L613.6,787.8z"/>
      <path fill="none" d="M619.7,791.3c-5.2,3.3-2.4,7.8,3,8.8c-2.9-0.3-5.7-0.7-8.4-1.1l0.5-10.6c0.1-0.2,0.1-0.3,0.2-0.5
        c10.5,1.1,21.9,1.8,34.3,2.1c0,2.9,0,8.8,0,11.7l-3.7-0.1c5.9-2.1-0.4-10.2-4.7-10.2c-6.2-1.3-11.6,8.2-3.5,9.8l-9-0.6
        C636.9,798.6,625.7,786.4,619.7,791.3L619.7,791.3z"/>
      <path fill="none" d="M617.6,793.3c3-4.8,10.6-3.5,12.9,1.4C625.8,795.9,621.5,795.4,617.6,793.3L617.6,793.3z"/>
      <path fill="none" d="M614.2,800l0-0.9c7.2,1.2,15.8,1.9,23.1,2.3c3.9,1.4,8.1,0.1,11.9,0.4v1.3C638.2,802.5,625.3,801.3,614.2,800
        L614.2,800z"/>
      <path fill="none" d="M618.5,798.3c-2.3-1.5-1.9-3.2-0.8-4.9c3.9,2.1,8.1,2.6,12.9,1.4C634.7,802.9,622.4,801.4,618.5,798.3
        L618.5,798.3z"/>
      <path fill="none" d="M635.1,800.4c3.3,1.7,11.1,3.3,12.7-0.8c0.3-3.9-3.1-7.7-7-8.1C636.3,790.4,629.7,797.2,635.1,800.4
        L635.1,800.4z M634.4,794.4c3.4,2.1,7.5,2.6,12.3,1.4C641.9,797,637.7,796.5,634.4,794.4L634.4,794.4z"/>
      <path fill="none" d="M625.3,808c-0.4-1.6-1.3-5.5-1.6-7l10,1c0,1.5,0,5.5,0,7.1L625.3,808L625.3,808z"/>
      <polygon fill="none" points="625.6,809.6 625.3,808 633.7,809.1 633.7,810.9 625.6,809.6 		"/>
      <path fill="none" d="M633.7,804.4V802c11.3,1,22.6,1.6,33.8,1.7l0,3.2C655.5,806.3,644.2,805.5,633.7,804.4L633.7,804.4z"/>
      <path fill="none" d="M649.2,801.7v-5.2L666,797l0.1,5.3C660.5,802.2,654.9,802.1,649.2,801.7L649.2,801.7z"/>
      <polygon fill="none" points="653.3,790.2 654.9,790.2 654.9,793 653.3,793 653.3,790.2 		"/>
      <path fill="none" d="M654.9,790.2c-1.1,0-4.6-0.1-5.7-0.1v-7l16.6,0.4l0.1,6.8C663.2,790.3,657.8,790.2,654.9,790.2L654.9,790.2z"
        />
      <path fill="none" d="M654.9,793v-2.8l6.5,0.1v1.5c-2.1,3.6,3.1,4.7,1.8,0.4c0,0,0-1.9,0-1.9h2.6l0.1,6.7l-16.8-0.4v-6.5l4.1,0.1
        v2.8H654.9L654.9,793z"/>
      <path fill="none" d="M662.3,795.1c-1.4-0.1-1.6-2.4-0.8-3.3v-1.5l1.8,0C663,791.7,664.4,794.7,662.3,795.1L662.3,795.1z"/>
      <path fill="none" d="M666.1,802.3v1.3c-5.5-0.1-11.1-0.3-16.8-0.6v-1.3C654.9,802.1,660.5,802.2,666.1,802.3L666.1,802.3z"/>
      <path fill="none" d="M669.3,805.9l-1.9,1c-0.4-1,0.8-24.8-0.9-24.8l3.8-0.3l0.7,26c-0.8-0.9-2.5-2.8-3.4-3.8L669.3,805.9
        L669.3,805.9z"/>
      <polygon fill="none" points="712.8,815 710.8,815 711,814.8 711.4,811.4 		"/>
      <path fill="none" d="M712.6,802c-1.2,2.6-0.9,8.8-1.6,12.8c-0.3,0.3-6.2,6.7-6.5,7c2.9-27.1,4.7-49.8,6-74.7
        c2.3,0.3,4.7,0.4,7.1,0.4C716.4,765.4,714.7,784.1,712.6,802z"/>
      <path fill="none" d="M689,816.8c0.4-16.1-0.6-24.2-2.9-24.3l1,16.3l-16.1-0.9l-0.7-26c24.6-0.4,20.2-4,23.3-25.6
        c2.4,0.6,4.8,1.1,7.3,1.4c-1.3,16.2-6.9,52.1-6.4,64.5C690.6,822.3,689,822.8,689,816.8L689,816.8z"/>
      <path fill="none" d="M686.1,792.4c2.3,0.1,3.3,8.2,2.9,24.3c0.1,3.4,0.6,5.2,1.7,5.4C686.1,823.6,687,796,686.1,792.4L686.1,792.4
        z"/>
      <path fill="none" d="M710.5,747.1c-1.4-0.2-2.8-0.4-4.2-0.7c-0.1,2.9-0.3,8.7-0.5,11.6c-1.6-0.1-3.3-0.2-4.9-0.4
        c-1.3,16.1-6.9,52.1-6.4,64.5l10-0.3C707.4,794.7,709.3,771.9,710.5,747.1z M699.2,819.4c0.6-0.1,0.3,1.2,0.4,1.5
        c0.1,0.7-3.1,0.2-3.4,0.3c-0.6,0.1-0.3-1.2-0.4-1.5C695.7,819,698.9,819.6,699.2,819.4z"/>
      <path fill="none" d="M695.8,820.9c0.1-0.3-0.3-1.6,0.4-1.5c0,0,3,0,3,0c0.6-0.1,0.3,1.2,0.4,1.5c0,0.2-0.1,0.3-0.4,0.3
        C698.9,821.1,695.7,821.6,695.8,820.9L695.8,820.9z"/>
      <path fill="none" d="M670.3,781.8c-0.7,0.6-31.2-0.4-33.1-0.4l1.5-95.1l11.3,0.6c-4,30.1,14.1,61.3,43.6,69.3
        C690.5,777.9,694.9,781.4,670.3,781.8L670.3,781.8z"/>
      <path fill="none" d="M675.2,735.8c-12.7-12.6-18.1-30.4-15.5-48.3l4.8,0.2c-4.4,25.4,13.2,50.9,37.5,57.7l-0.5,5.5
        C691.5,748.6,682.4,743.2,675.2,735.8L675.2,735.8z"/>
      <path fill="none" d="M673.6,734c7.4,8.3,17.2,14.4,27.9,16.8l-0.6,6.7c-33.2-3.9-55.5-38.2-50.9-70.7l9.7,0.5
        C657.3,704.5,661.9,721.6,673.6,734L673.6,734z"/>
      <path fill="none" d="M679.6,732.2c-20.9-19.2-21.1-54.2,0-73.7c11.4-11.6,26.3-14.9,41.5-17.2c0.9,0,1.8-0.3,2.2-1.2v9.1
        c-63.5-3.8-68.3,89.4-4.9,92.3l-0.8,6.1C703.3,747.5,690.1,742.4,679.6,732.2L679.6,732.2z"/>
      <path fill="none" d="M706.2,739.7c57.7,14.7,83.8-66.6,28.3-88.2C674.3,632.9,646.5,719.9,706.2,739.7L706.2,739.7z M718.7,727.5
        c-27.3,0.5-42.5-34.5-23.6-54.1c13-15.3,39.3-13.7,50.5,3.2h0C761,696.9,744.1,728.9,718.7,727.5L718.7,727.5z"/>
      <path fill="none" d="M727.4,726.5c-1.5,0.4-3.1,0.7-4.7,0.8l-0.3-2.2c1.5-0.2,2.9-0.4,4.4-0.8L727.4,726.5z"/>
      <path fill="none" d="M689.7,688l-2.2-0.4c1.2-5.3,3.7-10,7.6-14.2l1.5,1.6C693.1,678.8,690.8,683.2,689.7,688z"/>
      <path fill="none" d="M687.5,687.6c5.1,0.8,12.4,2.1,17.6,3c0.9-0.2,3,1.4,3.7,1.8l-0.1,2.4l-4.2-0.1c0-0.2,0-0.8,0-1
        c-5.1-0.6-12.5-1.5-17.6-2.1C687,690.2,687.2,688.9,687.5,687.6L687.5,687.6z"/>
      <path fill="none" d="M693.5,688.6l-3.8-0.6c1.1-4.8,3.4-9.2,6.9-13l2.6,2.8C696.4,681,694.5,684.6,693.5,688.6z"/>
      <polygon fill="none" points="689.1,691.8 692.9,692.3 692.8,696.1 689,696.3 689.1,691.8 		"/>
      <polygon fill="none" points="686.9,691.5 689.1,691.8 689,696.3 686.7,696.4 686.9,691.5 		"/>
      <polygon fill="none" points="696,692.6 695.8,696 692.8,696.1 692.9,692.3 696,692.6 		"/>
      <path fill="none" d="M695.8,696l0.1-3.3l8.5,1c0,0.5,0,1.3-0.1,1.9L695.8,696L695.8,696z"/>
      <path fill="none" d="M692.8,696.1c2.1,0,9.5-0.4,11.6-0.5c0-0.1,0-0.8,0-0.9l4.2,0.1l-0.1,2.4c-0.7,0.3-2.9,1.7-3.8,1.5
        c-5.2,0.5-12.6,1.1-17.7,1.6l-0.4-3.9C688.1,696.3,691.4,696.2,692.8,696.1L692.8,696.1z"/>
      <path fill="none" d="M699.3,677.8c-1-1-3.2-3.4-4.2-4.4l2.8-2.8c3.3,3.9,8.2,9.4,11.6,13.3l0.7-0.5l2.6,3.4l-1.9,1.4
        c-0.7-0.3-3.1-1.3-3.5-2C705.9,684.7,700.7,679.3,699.3,677.8L699.3,677.8z"/>
      <path fill="none" d="M699.3,677.8l2.2,2.2c-2.4,2.7-4,5.8-4.9,9.1l-3.1-0.5C694.5,684.6,696.4,681,699.3,677.8L699.3,677.8z"/>
      <path fill="none" d="M703.9,677.5l-2.1-2.3c1-0.9,2-1.6,3.1-2.3l1.6,2.6C705.7,676.1,704.8,676.8,703.9,677.5L703.9,677.5z"/>
      <path fill="none" d="M699.4,672.3c1.1-1,2.3-1.9,3.6-2.7l2,3.3c-1.1,0.7-2.1,1.5-3.1,2.3L699.4,672.3L699.4,672.3z"/>
      <path fill="none" d="M702.9,669.6c-1.2,0.8-2.4,1.7-3.6,2.7l-1.5-1.7c1.2-1.1,2.5-2.1,3.9-2.9L702.9,669.6L702.9,669.6z"/>
      <path fill="none" d="M704.9,672.9c-0.7-1.2-2.4-4-3.2-5.2c1.1-0.7,2.3-1.4,3.4-1.9c2.5,4.5,6.2,11.1,8.6,15.6
        c0.6,0.6,0.8,3.2,0.9,4l-1.9,1.4l-2.6-3.4l0.8-0.6C709.9,680.9,706.1,674.7,704.9,672.9L704.9,672.9z"/>
      <path fill="none" d="M708.2,671.1l-1.9-3.4c4.3-2.1,9.4-3,14.5-2.7l-0.5,3.8C716,668.7,711.8,669.5,708.2,671.1L708.2,671.1z"/>
      <path fill="none" d="M703.9,677.5c0.8-0.7,1.7-1.4,2.6-2l4.5,7.3c-0.4,0.3-1.2,0.9-1.5,1.2L703.9,677.5L703.9,677.5z"/>
      <path fill="none" d="M709.7,673.9l-1.5-2.7c3.6-1.7,7.8-2.4,12.1-2.2l-0.4,3.1C716.3,671.9,712.8,672.5,709.7,673.9L709.7,673.9z"
        />
      <path fill="none" d="M704.4,694.8v-0.4C704.4,694.5,704.4,694.7,704.4,694.8z"/>
      <path fill="none" d="M705.1,690.6l-8.5-1.5c0.9-3.4,2.5-6.4,4.9-9.1l6,6.2C706.3,687.6,705.6,689,705.1,690.6z"/>
      <path fill="none" d="M708.1,687l-0.7-0.7c-1.1,1.3-1.8,2.8-2.3,4.3c0.9-0.2,3,1.3,3.7,1.8c0,1.2-0.1,3.6-0.2,4.8
        c-0.7,0.3-3,1.7-3.8,1.5c0.4,1.6,1,3.1,2,4.5c0.4-0.7,2.9-1.5,3.7-1.8c0.9,0.8,2.8,2.4,3.7,3.2c-0.2,0.8-0.6,3.4-1.2,3.9
        c1.5,0.7,3.1,1.1,4.8,1.2c-0.3-0.8,0.7-3.2,0.9-4c1.2-0.2,3.6-0.7,4.8-0.9c0.5,0.6,2.3,2.5,2.3,3.4c1.4-0.7,2.8-1.8,3.9-3
        c-0.8-0.2-2.1-2.5-2.5-3.2c0.6-1.1,1.7-3.2,2.3-4.3c0.8,0,3.4-0.2,4,0.3c0.1-1.2,0.3-3.7,0.1-4.9c-0.7,0.5-3.3,0-4.1,0
        c-0.5-1.1-1.5-3.3-1.9-4.4c0.5-0.6,1.9-2.8,2.7-2.9c-1.1-1.2-2.4-2.4-3.7-3.2c-0.1,0.8-2,2.6-2.6,3.1c-1.2-0.3-3.5-0.9-4.7-1.2
        c-0.2-0.7-1-3.3-0.6-4c-1.7,0-3.3,0.3-4.8,0.9c0.6,0.6,0.8,3.2,0.9,4c-1,0.7-2.9,2.1-3.9,2.9L708.1,687L708.1,687z M711.5,692.7
        c2.9-0.1,2.9,4.6,0,4.5C708.6,697.3,708.6,692.7,711.5,692.7L711.5,692.7z M715.9,704.4c-2.8-0.9-1.4-5.2,1.4-4.3
        C720,700.9,718.6,705.4,715.9,704.4L715.9,704.4z M716.2,698c-3.8-3.7,2.1-9.5,5.7-5.8C725.7,695.9,719.8,701.9,716.2,698
        L716.2,698z M727.2,699.4c0.5,2.9-4.1,3.6-4.4,0.7C722.2,697.2,726.9,696.5,727.2,699.4z M717.6,690.1c-2.7,0.9-4.2-3.4-1.4-4.3
        C719,684.9,720.4,689.2,717.6,690.1L717.6,690.1z M727.5,691.2c-0.4,2.9-4.9,2.2-4.5-0.7C723.4,687.6,728,688.4,727.5,691.2z"/>
      <path fill="none" d="M709.9,693.4c-2.1,2,1.2,5.3,3.2,3.2C715.2,694.5,712,691.3,709.9,693.4L709.9,693.4z M711.5,693.5l1.3,0.8
        v1.5l-1.3,0.8l-1.3-0.8v-1.5L711.5,693.5L711.5,693.5z"/>
      <path fill="none" d="M710.2,694.3v1.5l1.3,0.8l1.3-0.8v-1.5l-1.3-0.8L710.2,694.3L710.2,694.3z M712,695.4l-0.4,0.1l-0.4-0.1
        C710.4,694.1,712.8,694.2,712,695.4L712,695.4z"/>
      <path fill="none" d="M712.1,695c0,0.1-0.1,0.3-0.2,0.4l-0.4,0.1l-0.4-0.1C710.5,694.6,712,693.9,712.1,695z"/>
      <path fill="none" d="M717.6,704.3c2.6-1.3,0.5-5.4-2.1-4C712.9,701.5,715,705.6,717.6,704.3L717.6,704.3z M716.3,700.8l1.4,0.5
        l0.3,1.5l-1.1,1l-1.4-0.4l-0.3-1.5L716.3,700.8L716.3,700.8z"/>
      <path fill="none" d="M715.2,701.8l0.3,1.5l1.4,0.4l1.1-1l-0.3-1.5l-1.4-0.5L715.2,701.8L715.2,701.8z M717.1,702.4
        c-0.3,0.4-0.7,0.5-1,0.1l0-0.4C716.3,701.4,717.2,701.7,717.1,702.4z"/>
      <path fill="none" d="M716.1,702.5c-0.3-1,1.2-1.1,1-0.1C716.8,702.9,716.4,702.9,716.1,702.5L716.1,702.5z"/>
      <path fill="none" d="M719,699.2c5.3,0.1,5.3-8.3,0-8.1C713.8,691,713.8,699.3,719,699.2L719,699.2z M718.6,692.3
        c3.5-0.4,1.6,9.4-0.9,4.9C717.3,695.7,717.2,693.2,718.6,692.3z"/>
      <path fill="none" d="M718.1,697.9c2.8,2.4,3.4-6.2,0.5-5.6C717,693.4,717.2,696.4,718.1,697.9L718.1,697.9z M718.6,692.4
        c3.3-0.8,1.5,9.3-0.8,4.7C717.4,695.7,717.3,693.4,718.6,692.4z"/>
      <path fill="none" d="M718.2,697.9c1,1.3,2-0.5,2.1-1.5c0.9-3-2-6.5-2.8-1.7C717.5,695.7,717.6,697.1,718.2,697.9L718.2,697.9z
         M718.5,696.7v0.2V696.7l0.2-0.1l0,0h0l-0.2,0.6l-0.2,0.1l0,0v-0.5L718.5,696.7L718.5,696.7L718.5,696.7z M718,696
        c0.2,0,0.6-0.2,0.8-0.3l0,0c0,0.4-0.1,0.8-0.5,0.8l-0.2-0.1l-0.1-0.1L718,696z M719,696.3c0.1,0,0.7-0.3,0.8-0.3v0.5
        c0,0-0.2,0.1-0.2,0.1l0-0.2l-0.1,0v0.2l-0.1,0.1l0,0v-0.2l-0.1,0.1c0,0,0,0.2,0,0.3l-0.2,0.1l0,0V696.3z M719,696.9
        c0.1,0,0.7-0.2,0.8-0.2v0.2l-0.3,0.1c0.1,0,0.2,0.1,0.3,0.1v0.2l-0.3-0.2l-0.1,0.2l-0.2,0.1l-0.2-0.1l0-0.2V696.9z M718,694
        L718,694L718,694L718,694l0-0.3c0.1,0,0.7-0.3,0.8-0.3v0l0,0l0,0.5l-0.2,0.1l0,0h0c0-0.1,0-0.2,0-0.2
        C718.6,693.8,718.1,694,718,694L718,694z M718.8,694.3l-0.1,0l0,0.3c0,0,0.1,0,0.1,0v0.2c-0.1,0-0.7,0-0.8,0l0,0v-0.2h0l0.8-0.5
        V694.3L718.8,694.3z M718.8,695.1c-0.1,0-0.3,0.1-0.4,0.1l0.4,0l0,0l0,0.2l-0.8,0.3l0,0v-0.2c0,0,0.4-0.1,0.4-0.1l-0.4,0l0-0.3
        l0.8-0.3V695.1L718.8,695.1z M719,695.4c0,0,0.8,0.1,0.8,0v0.2h0l-0.8,0.6V696c0.1,0,0.4-0.3,0.5-0.3c0,0-0.4,0-0.5,0V695.4z
         M719.7,694.7l0.1,0.1c0.2,0.6-0.8,1-0.8,0.3C719,694.7,719.4,694.6,719.7,694.7z M719,694c0.1,0,0.7-0.2,0.8-0.2v0.2l-0.3,0.1
        c0.1,0,0.2,0.1,0.3,0.1v0.3l-0.3-0.2l-0.1,0.2l-0.2,0.1C718.9,694.6,719,694.2,719,694L719,694z M719.3,693.4l-0.1,0.3l-0.1,0v0h0
        l0.2-0.6l0.2-0.1l0,0v0.5c0,0-0.2,0.1-0.2,0.1V693.4z"/>
      <polygon fill="none" points="718.5,696.7 718.5,696.7 718.5,696.9 718.6,696.6 718.7,696.6 718.7,696.6 718.7,696.6 718.5,697.2 
        718.3,697.3 718.3,697.3 718.3,696.8 718.5,696.7 		"/>
      <path fill="none" d="M718.1,696.5l0.2,0.1c0.4-0.1,0.6-0.5,0.5-0.8l0,0c-0.2,0.1-0.6,0.3-0.8,0.3l0,0.4L718.1,696.5L718.1,696.5z
         M718.2,696.1l0.5-0.1c-0.1,0.2-0.2,0.4-0.5,0.3L718.2,696.1z"/>
      <path fill="none" d="M718.3,696.3L718.3,696.3l-0.1-0.1l0.5-0.1C718.6,696.1,718.5,696.3,718.3,696.3L718.3,696.3z"/>
      <path fill="none" d="M719,696.8L719,696.8l0-0.5c0.1,0,0.7-0.3,0.8-0.3v0.5c0,0-0.2,0.1-0.2,0.1l0-0.2l-0.1,0v0.2l-0.1,0.1l0,0
        v-0.2l-0.1,0.1c0,0,0,0.2,0,0.3L719,696.8L719,696.8z"/>
      <path fill="none" d="M719,697.4l0.2,0.1l0.2-0.1l0.1-0.2l0.3,0.2v-0.2c-0.1,0-0.2-0.1-0.3-0.1l0.3-0.1v-0.2
        c-0.1,0-0.7,0.3-0.8,0.2C719,697,719,697.3,719,697.4L719,697.4z M719.2,697.1l0.2-0.1l0,0.2l-0.1,0L719.2,697.1z"/>
      <polygon fill="none" points="719.3,697.2 719.2,697.2 719.2,697.1 719.4,697 		"/>
      <path fill="none" d="M718.1,694C718.1,694,718,694,718.1,694L718.1,694L718.1,694l-0.1-0.2c0.1,0,0.7-0.3,0.8-0.3v0l0,0l0,0.5
        l-0.2,0.1l0,0h0c0-0.1,0-0.2,0-0.2L718.1,694L718.1,694z"/>
      <path fill="none" d="M718.8,694l-0.8,0.5h0v0.2l0,0c0.1,0,0.7,0,0.8,0v-0.2c0,0-0.1,0-0.1,0l0-0.3l0.1,0V694L718.8,694z
         M718.5,694.6h-0.2l0.2-0.1V694.6L718.5,694.6z"/>
      <polygon fill="none" points="718.5,694.4 718.5,694.6 718.3,694.6 718.5,694.4 		"/>
      <path fill="none" d="M718.8,694.9v0.2c-0.1,0-0.3,0.1-0.4,0.1l0.4,0l0,0l0,0.2l-0.8,0.3l0,0v-0.2c0,0,0.4-0.1,0.4-0.1l-0.4,0
        l0-0.3L718.8,694.9L718.8,694.9z"/>
      <path fill="none" d="M719.1,695.7L719.1,695.7l-0.1-0.2c0,0,0.8,0.1,0.8,0v0.2h0l-0.8,0.6V696c0.1,0,0.4-0.3,0.5-0.3H719.1
        L719.1,695.7z"/>
      <path fill="none" d="M719,695.3c0.3,0.3,0.9-0.1,0.8-0.5l-0.1-0.1C719.3,694.5,718.8,694.9,719,695.3L719,695.3z M719.7,695
        c-0.1,0.1-0.3,0.2-0.5,0.1l0-0.1C719.2,694.9,719.7,694.7,719.7,695z"/>
      <path fill="none" d="M719.2,695.1c-0.1-0.2,0.5-0.4,0.5-0.1C719.6,695.1,719.3,695.2,719.2,695.1L719.2,695.1z"/>
      <path fill="none" d="M719,694.4c0,0.2,0.1,0.2,0.2,0.3l0.2-0.1l0.1-0.2l0.3,0.2v-0.3c-0.1,0-0.2-0.1-0.3-0.1l0.3-0.1v-0.2
        c-0.1,0-0.7,0.3-0.8,0.2V694.4L719,694.4z M719.2,694.2l0.2-0.1l0,0.2l-0.1,0C719.2,694.3,719.2,694.3,719.2,694.2z"/>
      <path fill="none" d="M719.3,694.3l-0.1,0c0,0,0-0.1,0-0.1l0.2-0.1L719.3,694.3z"/>
      <polygon fill="none" points="719.3,693.7 719.3,693.7 719.3,693.4 719.2,693.7 719.1,693.8 719.1,693.8 719.1,693.8 719.3,693.2 
        719.5,693.1 719.5,693.1 719.5,693.6 719.3,693.7 		"/>
      <path fill="none" d="M723.7,701.6c2.3,1.8,5-2,2.6-3.7C724,696.2,721.3,699.9,723.7,701.6L723.7,701.6z M723.6,699.5l0.9-1.2
        l1.5,0.2l0.6,1.4l-0.9,1.2l-1.5-0.2L723.6,699.5z"/>
      <path fill="none" d="M725.7,701.1l0.9-1.2l-0.6-1.4l-1.5-0.2l-0.9,1.2l0.6,1.4L725.7,701.1L725.7,701.1z M725.1,700.3
        c-1.1,0.1-0.6-1.6,0.3-1C725.7,699.5,725.6,700.3,725.1,700.3L725.1,700.3z"/>
      <path fill="none" d="M725.5,700.1c-0.4,0.6-1.3-0.1-0.9-0.7C725,698.8,725.8,699.4,725.5,700.1z"/>
      <path fill="none" d="M714.9,686.9c-1.4,2.6,2.8,4.7,4,2C720.3,686.4,716.2,684.3,714.9,686.9z M716.5,689.4l-1.1-1l0.3-1.5
        l1.4-0.5l1.1,1L718,689L716.5,689.4L716.5,689.4z"/>
      <path fill="none" d="M718.3,687.5l-1.1-1l-1.4,0.5l-0.3,1.5l1.1,1l1.4-0.4L718.3,687.5z M717.4,688.2l-0.3,0.3
        c-0.4,0-0.6-0.1-0.7-0.4C716.2,687.2,717.7,687.2,717.4,688.2z"/>
      <path fill="none" d="M716.6,688.5c-1.1-0.8,1.1-1.7,0.7-0.3l-0.3,0.3C716.9,688.5,716.8,688.5,716.6,688.5L716.6,688.5z"/>
      <path fill="none" d="M723.4,692.2c1.7,2.4,5.4-0.3,3.6-2.6C725.4,687.2,721.6,689.9,723.4,692.2L723.4,692.2z M724.3,689.6
        l1.5-0.2l0.9,1.2l-0.6,1.4l-1.5,0.1l-0.9-1.2C724,690.6,724.1,690.1,724.3,689.6z"/>
      <path fill="none" d="M724.6,692.2l1.5-0.1l0.6-1.4l-0.9-1.2l-1.5,0.2c-0.2,0.5-0.4,0.9-0.6,1.4L724.6,692.2L724.6,692.2z
         M724.8,691.2c-0.5-0.5,0.5-1.3,0.9-0.7C726,691.2,725.2,691.8,724.8,691.2L724.8,691.2z"/>
      <path fill="none" d="M725.1,691.4c-0.7-0.1-0.6-1.2,0.2-1.1C726,690.4,725.9,691.6,725.1,691.4L725.1,691.4z"/>
      <path fill="none" d="M713.9,681.4l-4.2-7.6c3.1-1.3,6.6-2,10.1-1.9l-1.1,8.6C717,680.6,715.4,680.9,713.9,681.4L713.9,681.4z"/>
      <path fill="none" d="M695.2,713.6l-1.7,1.5c-3.5-4.4-5.6-9.3-6.4-14.8l2.2-0.2C690.1,705.1,692,709.6,695.2,713.6L695.2,713.6z"/>
      <path fill="none" d="M693.5,715.1c3.7-3.3,8.7-7.8,12.4-11.1c0.4-0.4,1.3-1.1,1.7-1.5c0,0,2.9-1,2.9-1l1.8,1.6l-2.7,3.3l-0.8-0.7
        c-3.3,3.3-9.4,9.2-12.6,12.4C695.2,717.1,694.3,716.1,693.5,715.1L693.5,715.1z"/>
      <path fill="none" d="M689.3,700.1l3.8-0.3c0.7,4.2,2.3,7.9,4.9,11.3l-2.8,2.6C692,709.6,690.1,705.1,689.3,700.1L689.3,700.1z"/>
      <path fill="none" d="M700.4,709L698,711c-2.6-3.4-4.2-7.1-4.9-11.3l3.1-0.3C696.8,703,698.2,706.1,700.4,709L700.4,709z"/>
      <path fill="none" d="M696.2,699.5l8.6-0.8c0.4,1.6,1,3.1,2,4.5l-0.9,0.8c-1.7-0.7-3.1-0.9-4.1-0.7l-1.4,5.7
        C698.2,706.1,696.8,703,696.2,699.5L696.2,699.5z"/>
      <path fill="none" d="M708.8,705.6c0.4,0.3,1.1,0.9,1.5,1.2l0,0L708.8,705.6L708.8,705.6z"/>
      <polygon fill="none" points="707.9,706.5 708.8,705.6 710.2,706.9 709.5,707.8 707.9,706.5 		"/>
      <path fill="none" d="M710.2,706.9L710.2,706.9l-0.6-0.6l2.7-3.3l1.8,1.6l-0.6,3c-0.3,0.5-0.9,1.4-1.2,1.9
        c-2.2,3.4-7.1,10.7-9.2,14c-1.1-0.7-2.2-1.4-3.3-2.2C702.5,717.6,707.5,710.6,710.2,706.9L710.2,706.9z"/>
      <path fill="none" d="M712.3,709.4l0.6-1c1.5,0.7,3.1,1.1,4.8,1.2l0.1,1.1C715.8,710.6,714,710.2,712.3,709.4L712.3,709.4z"/>
      <path fill="none" d="M707.9,706.5l1.6,1.3l-6.2,8.5c-1-0.8-2-1.6-2.9-2.5L707.9,706.5L707.9,706.5z"/>
      <path fill="none" d="M700.4,709l1.4-5.7c1-0.2,2.4,0.1,4.1,0.7L700.4,709L700.4,709z"/>
      <path fill="none" d="M703.1,723.4l1.2-1.9c4.3,2.4,9,3.7,14.2,3.8l0.1,2.2C713,727.4,707.8,726.1,703.1,723.4L703.1,723.4z"/>
      <path fill="none" d="M701.1,719.4l-1.3,1.8c-1.3-1-2.5-2-3.6-3.2l1.6-1.6C698.8,717.5,699.9,718.5,701.1,719.4L701.1,719.4z"/>
      <path fill="none" d="M697.7,716.4l2.7-2.7c0.9,0.9,1.9,1.8,2.9,2.5l-2.2,3.1C699.9,718.5,698.8,717.5,697.7,716.4L697.7,716.4z"/>
      <path fill="none" d="M704.3,721.5l2.1-3.2c3.6,2,7.6,3,11.9,3.1l0.2,3.8C713.3,725.2,708.6,724,704.3,721.5L704.3,721.5z"/>
      <path fill="none" d="M706.4,718.3c3.6,2,7.6,3,11.9,3.1l-0.6-10.6c-2-0.2-3.8-0.7-5.4-1.4L706.4,718.3L706.4,718.3z M712.7,714.3
        l1.9-2.6l1.5,0.4c0.3,1.8-0.5,5.5-1.1,7.3l-3.2-0.8L712.7,714.3L712.7,714.3z"/>
      <path fill="none" d="M711.8,718.6l0.9-4.3l1.9-2.6l1.5,0.4c0.3,1.8-0.5,5.5-1.1,7.3L711.8,718.6L711.8,718.6z"/>
      <path fill="none" d="M720.8,665.1c-5.1-0.3-10.2,0.6-14.5,2.7l-1.1-2c4.7-2.3,10.3-3.3,15.9-2.9L720.8,665.1z"/>
      <path fill="none" d="M720.8,665.1l0.3-2.2c1.3,0.1,2.6,0.2,3.9,0.4c-0.9,5-2.3,12.4-3.2,17.5l0.9,0.2l-1.1,4.1l-2.3-0.6
        c-0.2-0.8-1-3.2-0.6-4C719.2,676.4,720.2,669.2,720.8,665.1L720.8,665.1z"/>
      <path fill="none" d="M724.6,665.5l0.4-2.2c1.6,0.3,3.2,0.7,4.7,1.2l-0.8,2.1C727.5,666.1,726.1,665.8,724.6,665.5L724.6,665.5z"/>
      <path fill="none" d="M722.4,725.1l-0.4-3.8c1.3-0.1,2.6-0.4,3.8-0.7l1,3.7C725.4,724.7,723.9,724.9,722.4,725.1L722.4,725.1z"/>
      <polygon fill="none" points="721.2,713.3 721.1,712.7 721.2,713.3 721.2,713.3 		"/>
      <path fill="none" d="M721.2,713.3c-0.1-0.4-0.2-2-0.3-2.4l2.1-0.3c0,0.1,0.1,0.5,0.2,0.6C722.4,711.6,721.6,712.4,721.2,713.3z"/>
      <path fill="none" d="M729.3,719.5L729.3,719.5L729.3,719.5z"/>
      <path fill="none" d="M731.1,725.3l-0.7-2.2c4.4-1.7,8.7-5.1,11.9-8.9l1.8,1.3C740.7,719.8,736,723.4,731.1,725.3L731.1,725.3z"/>
      <path fill="none" d="M730.4,723.1c0,0-1.2-3.7-1.2-3.7c3.7-1.5,7.3-4.4,10-7.4l3.2,2.2C739.1,718.1,734.9,721.4,730.4,723.1
        L730.4,723.1z"/>
      <polygon fill="none" points="721,710.9 720.8,709.7 722.7,709.3 723.1,710.6 721,710.9 		"/>
      <path fill="none" d="M720.8,709.7L720.8,709.7c0.5-0.1,1.4-0.3,1.9-0.4l0,0.1L720.8,709.7L720.8,709.7z"/>
      <polygon fill="none" points="723.1,710.7 723.2,711.2 723.1,710.7 		"/>
      <path fill="none" d="M729.3,697.8c0.8,0,3.4-0.2,4,0.3c4.9,1.8,12,4.4,16.8,6.2c-0.4,1.3-0.8,2.6-1.4,3.9
        c-4.7-2.1-11.7-5-16.4-7.1l-0.4,0.8l-3.8-1.9L729.3,697.8L729.3,697.8z"/>
      <path fill="none" d="M731.5,702.7c4.4,2.8,10.7,6.9,15,9.7c-0.7,1.1-1.5,2.1-2.3,3.1c-4.2-2.9-10.4-7.3-14.6-10.3
        c-0.8-0.2-2.1-2.5-2.5-3.2l1.1-2.1l3.8,1.9L731.5,702.7z"/>
      <path fill="none" d="M731.5,702.8L731.5,702.8c0.2-0.4,0.6-1.3,0.8-1.7l0.1,0L731.5,702.8z"/>
      <polygon fill="none" points="731.5,702.8 732.4,701.1 740.3,704.5 738.8,707.4 731.5,702.8 		"/>
      <path fill="none" d="M741.5,701.1l-8.1-3c0.1-1.2,0.3-3.7,0.1-4.9l8.3-2.4C742.2,693.9,742.4,698.1,741.5,701.1z"/>
      <path fill="none" d="M748.1,703.5l-3.6-1.3c1-3.8,1.1-8.4,0.4-12.3l3.7-1.1C749.5,693.5,749.3,699,748.1,703.5z"/>
      <path fill="none" d="M743.2,705.7l3.5,1.5c-0.6,1.3-1.3,2.6-2.1,3.9l-3.2-2.1C742.1,708,742.7,706.9,743.2,705.7L743.2,705.7z"/>
      <path fill="none" d="M746.7,707.3l2.1,0.9c-0.7,1.5-1.4,2.9-2.3,4.2l-1.9-1.2C745.4,709.9,746.1,708.6,746.7,707.3L746.7,707.3z"
        />
      <path fill="none" d="M750.2,704.3l-2.1-0.8c1.3-4.5,1.4-10,0.5-14.7c0.4-0.1,1.8-0.5,2.2-0.6C751.8,693.3,751.6,699.3,750.2,704.3
        z"/>
      <path fill="none" d="M732,688.4L732,688.4l0.3,0.8l-3.9,1.8l-1-2.2c0.5-0.6,1.9-2.7,2.7-2.9c4.5-2.7,10.9-6.5,15.4-9.2h0
        c0.8,1.1,1.5,2.2,2.1,3.4C743.2,682.3,736.6,685.9,732,688.4L732,688.4z"/>
      <path fill="none" d="M732,688.4L732,688.4l0.8,1.7h0C732.6,689.7,732.2,688.8,732,688.4L732,688.4z"/>
      <path fill="none" d="M726.1,683.6l-2.1,2.2l-2.3-0.6l1.1-4.1l1,0.2c1.8-4.9,4.3-11.9,6-16.7c1.2,0.4,2.5,0.9,3.7,1.5
        C731.2,671.2,728.3,678.4,726.1,683.6z"/>
      <path fill="none" d="M729.3,684.8c-0.9-0.9-1.8-1.6-2.8-2.2l3.3-8c2.9,1.5,5.8,4,7.8,6.8l-7.4,4.5L729.3,684.8L729.3,684.8z"/>
      <path fill="none" d="M728.5,690.9l3.9-1.8l0.4,0.9c4.9-1.7,12-4.1,16.9-5.7c0.4,1.2,0.8,2.5,1.1,3.8c-5,1.4-12.2,3.5-17.2,5
        c-0.7,0.5-3.3,0-4.1,0L728.5,690.9L728.5,690.9z"/>
      <path fill="none" d="M732.8,690.1l-0.8-1.8l7.6-4.1c0.5,1,1,2,1.3,3L732.8,690.1L732.8,690.1z"/>
      <path fill="none" d="M722.7,681l-0.9-0.2c0.6,0.1,1.2,0.3,1.9,0.5L722.7,681L722.7,681z"/>
      <path fill="none" d="M728.9,666.6l-1.3,3.6c-1.2-0.4-2.4-0.7-3.7-1l0.7-3.8C726.1,665.8,727.5,666.1,728.9,666.6L728.9,666.6z"/>
      <path fill="none" d="M731,671.7l1.5-3.6c4.2,2.1,8.3,5.7,11.2,9.6l-3.3,2C737.9,676.4,734.5,673.5,731,671.7L731,671.7z"/>
      <path fill="none" d="M721.8,680.8l1.5-8.4c1.1,0.2,2.2,0.5,3.2,0.8l-2.9,8.1C723,681,722.4,680.9,721.8,680.8L721.8,680.8z"/>
      <path fill="none" d="M733.4,666c4.6,2.3,9.1,6.2,12.2,10.5l-1.9,1.1c-2.8-3.9-7-7.5-11.2-9.6L733.4,666L733.4,666z"/>
      <path fill="none" d="M745.7,681l2-1c0.8,1.4,1.4,2.9,1.9,4.4l-2.1,0.7C747,683.7,746.4,682.3,745.7,681L745.7,681z"/>
      <path fill="none" d="M747.5,685.1l-3.6,1.2c-0.4-1.2-1-2.4-1.6-3.5l3.4-1.8C746.4,682.3,747,683.7,747.5,685.1z"/>
      <polygon fill="none" points="750.7,688.2 750.2,688.3 750.7,688.2 		"/>
      <path fill="none" d="M719.1,727.5h-0.4c-0.2-3.9-0.7-12.7-1-16.7c0-0.5-0.1-1.7-0.1-2.2c0,0,1-2.9,1-2.9l2.4-0.4l0.9,4.2l-1,0.2
        c0.6,5.7,1.2,12,1.9,17.7C721.5,727.4,720.3,727.5,719.1,727.5L719.1,727.5z"/>
      <polygon fill="none" points="721.2,713.3 721.3,713.9 721.2,713.3 721.2,713.3 		"/>
      <path fill="none" d="M721.3,713.9c-0.3-1.2,1-2.3,2-2.8l0.1,0.4l2.1,7.9c-1.5,0.2-2.9-0.5-3.8-1.7L721.3,713.9L721.3,713.9z"/>
      <polygon fill="none" points="721.7,717.7 722,718.2 721.7,718.2 721.7,717.7 		"/>
      <path fill="none" d="M721.7,718.2l0.3,0c0.9,1,2.2,1.4,3.5,1.3l0.3,1.2c-1.2,0.3-2.5,0.5-3.8,0.7L721.7,718.2L721.7,718.2z"/>
      <path fill="none" d="M728.6,717.5l-1.9-5.9l-0.1-0.5C729,711.9,730.1,715.3,728.6,717.5L728.6,717.5z"/>
      <path fill="none" d="M726.7,711.5c1.3,4.2,3.1,9.7,4.4,13.8c-1.2,0.5-2.4,0.9-3.7,1.2c-1.4-5.1-3.3-12.1-4.7-17.2l-0.9,0.2
        l-0.9-4.2l2.4-0.5l2,2.3C725.7,708.5,726.3,710.2,726.7,711.5z"/>
      <path fill="none" d="M728.6,717.5c1.4-2.2,0.4-5.5-2.1-6.4c-0.1-0.3-0.7-2-0.8-2.3l5.4,1.5c-0.5,2.3-1.5,7-2,9.3L728.6,717.5
        L728.6,717.5z"/>
      <polygon fill="none" points="723.2,711.2 723.4,711.6 723.2,711.2 		"/>
      <polygon fill="none" points="726.4,710.6 726.6,711 726.5,711 726.4,710.6 		"/>
      <path fill="none" d="M725.8,708.7l-0.1-0.5c1.4-0.7,2.8-1.8,3.9-3l7.1,5c-1.8,1.8-4.3,4.3-6.6,5.4l1.1-5.4L725.8,708.7
        L725.8,708.7z"/>
      <polygon fill="none" points="726.5,711 726.6,711 726.7,711.5 726.5,711 		"/>
      <path fill="none" d="M706.3,746.4l-0.2,5.4l-4.6-0.9l0.5-5.5C703.4,745.7,704.8,746.1,706.3,746.4L706.3,746.4z"/>
      <path fill="none" d="M701.5,750.8l4.6,0.9l-0.3,6.2c-1.6-0.1-3.3-0.2-4.9-0.4L701.5,750.8L701.5,750.8z"/>
      <path fill="none" d="M718.4,741.4c4.3,0,8.5-0.4,12.5-1.4l-11.6,58.5c-0.7,2.9-2.9,4-6.6,3.5C715.2,782.1,716.2,761.3,718.4,741.4
        L718.4,741.4z"/>
      <path fill="none" d="M554,566.5l0.2-4c2.6,10.2-1.4,21.4-4.9,31.1c0.1-2.1-0.3-8.6,0.4-10.5c1.9-4.5,2.9-9.9,4.4-17L554,566.5z"/>
      <path fill="none" d="M565.9,565l-0.1,8.8l-4.7,0.6c0.3-17.8,0.8-34.8,1.5-51c0.4-6.3,1.6-10.6,3.6-12.9v0l0.8-0.9
        C567.1,517.4,565.9,546.6,565.9,565z"/>
      <polygon fill="none" points="565.8,573.8 565.9,565 623.2,554.2 622.9,565.8 565.8,573.8 		"/>
      <path fill="none" d="M561.2,574.4c-0.3,20.4-0.6,72.2-1,93.5c0,5.5,2.5,8.6,7.5,9.3c19.8,3.4,42.2,6.4,67.1,8.8l0.2-10.7h3.9
        l-0.2,11l11.3,0.6c2.6-28.8,26.9-52,54.9-55.5l0-3.3c-3.6,0.9-9.3,2.8-8.7-3.6l0.9-63.1c-24.7-0.2-48.1,0.4-69.9,2.6l-2.3,90.1
        c-0.4,7.5-3.2,11.3-8.4,11.4c-15.1-0.8-31.6-2.6-49.5-5.3c-3.1,0.1-1.1-13.8-1.6-15.8c0-6.6,0.5-65.4,0.5-70.6L561.2,574.4
        L561.2,574.4z M638.6,650.8c6.4,1.3,6.2,11.1-0.2,12.1c1.1,2.3,0.3,7.6,0.5,10.1c0.2,1.3-3,0.5-3.7,0.7c-1.5,0.3-0.4-6.3-0.7-6.9
        v-4.5c-4.6-2.1-4.6-9.1,0-11.1c0.3-0.6-0.8-8.2,0.7-7.8c0.6,0.2,3.7-0.6,3.6,0.7C638.8,644.8,639,650.2,638.6,650.8L638.6,650.8z"
        />
      <path fill="none" d="M638.8,649.7c-0.8,1.6-0.5,11.1-0.5,13c0.7,1,0.7,3,0.6,4.1h-4.4c0-2.9,0-12.5,0-15.6
        c0.3-0.6-0.8-8.2,0.7-7.8c0.6,0.2,3.7-0.6,3.6,0.7C638.8,644.1,638.8,649.7,638.8,649.7z"/>
      <path fill="none" d="M632.9,652.4c0.5-0.5,1.1-0.9,1.7-1.2v11.1C630.8,660.7,629.8,655.3,632.9,652.4L632.9,652.4z"/>
      <path fill="none" d="M638.3,662.7c0-0.9-0.2-11.4,0.3-11.9c6.4,1.3,6.2,11.1-0.2,12.1L638.3,662.7L638.3,662.7z"/>
      <path fill="none" d="M634.6,673v-6.2h4.4c-0.3,0.6,0.7,7.2-0.7,6.9C637.6,673.5,634.4,674.3,634.6,673L634.6,673z"/>
      <path fill="none" d="M555.2,566.2v0.8c-2.3-1.2,2.2-55.4,1.9-58.3c0.2-8.7,3.7-17.3,9.4-24.2l0.5,13.5
        C555.3,506.5,557.3,551.3,555.2,566.2z"/>
      <path fill="none" d="M555.2,567.1c2.1-15.5,0.1-60.1,11.7-69l0.1,4.6c-13.3,14.6-7.7,55.8-10.1,81.3c0,0-0.9,98.8-0.9,98.8l-6.9-1
        C548.7,625.8,548.6,604.2,555.2,567.1L555.2,567.1z"/>
      <polygon fill="none" points="565.8,582.1 565.8,573.8 622.9,565.8 622.5,576.6 565.8,582.1 		"/>
      <path fill="none" d="M567,509.6l0-7l61.6-70.9c0.1,2.6,0.2,5.3,0.2,8.2l-0.2,0.1C614,456.6,582.1,492.6,567,509.6L567,509.6z"/>
      <path fill="none" d="M567,514.2l0-4.6l38.4-43.4c-1.4,3-1.8,6.2-1.8,9.8C597.2,476.1,571.7,510.2,567,514.2L567,514.2z"/>
      <path fill="none" d="M566.9,498.1c10.9-13.1,50.5-60.7,61.1-73.4l0.7,7.1L567,502.6L566.9,498.1L566.9,498.1z"/>
      <path fill="none" d="M568.5,496.2l-1.5,1.9l-0.5-13.5l-0.1-0.8l1.5-2.2C567.8,482,568.4,495.3,568.5,496.2z"/>
      <polygon fill="none" points="571.6,492.4 568.5,496.2 567.9,482.8 570.3,479.7 		"/>
      <path fill="none" d="M627.4,563.9c21.9-2,45.1-2.8,69.7-2.5l1.6-113.9c-0.1-2.6,2.5-3.8,7.8-3.6l0.1-5.7
        c-26.1-3-41.3-3.5-62.6-1.3c-0.2,4.5-0.3,13.6-0.3,18.2h-3.4c0-4.2,0-13.6,0.2-17.7c-3.4,0.5-6.6,1.2-9.5,2L627.2,564L627.4,563.9
        L627.4,563.9z M638,524.8c0.6,0.2,3.7-0.6,3.6,0.7c0,0.7,0.1,6.1-0.2,6.7c6.4,1.3,6.2,11.1-0.3,12.1c1.1,2.3,0.3,7.6,0.6,10.1
        c0.2,1.3-3,0.5-3.7,0.7c-1.5,0.3-0.4-6.3-0.7-6.9v-4.5c-4.6-2.1-4.6-9.1,0-11.1C637.5,532.1,636.5,524.4,638,524.8L638,524.8z"/>
      <path fill="none" d="M637.2,525.5c-0.2-1.3,3-0.5,3.6-0.7c1.4-0.3,0.4,5.7,0.7,6.3c-0.1,0.6-0.4,1.9-0.5,2.5
        c0.1,0.2-0.1,10.5,0.1,10.6c0.6,0.9,0.6,3,0.6,4h-4.4C637.2,541.9,637.2,531.9,637.2,525.5z"/>
      <path fill="none" d="M635.6,533.9c0.5-0.5,1.1-0.9,1.7-1.2v11.1C633.5,542.1,632.5,536.7,635.6,533.9L635.6,533.9z"/>
      <path fill="none" d="M638,555.1c0.6-0.2,3.8,0.6,3.7-0.7v-6.2h-4.4C637.5,548.8,636.5,555.5,638,555.1L638,555.1z M639.4,552.5
        c-1.6,0-1.6-2.6,0-2.5C641,550,641,552.6,639.4,552.5L639.4,552.5z"/>
      <path fill="none" d="M640.3,552.2c-1.1,1.2-2.9-0.6-1.8-1.8C639.6,549.2,641.4,551,640.3,552.2L640.3,552.2z"/>
      <path fill="none" d="M644.3,542.6c-0.9,0.9-2,1.5-3.2,1.7c-0.1-1.1-0.2-11.3,0.3-12.1C645.9,533.1,647.8,539.4,644.3,542.6
        L644.3,542.6z"/>
      <path fill="none" d="M622.9,565.8l2.2-0.7c0,0-2.6,86.1-2.6,86.2c-0.4,7.2-3.3,10.9-8.8,11.3c-8.4-0.9-48.8,0-48.4-5.6
        c0.3,1.1,1,1.5,2,1.5c17.3,1.8,32.7,2.5,46,2c4.6-0.2,6.9-3.4,7-9.7C620.9,634.1,622.3,582.5,622.9,565.8L622.9,565.8z"/>
      <path fill="none" d="M627.2,564c-0.4,0.2-1.7,1-2.1,1.1l3.7-125.1l2.2-0.6L627.2,564z"/>
      <path fill="none" d="M625.6,564.8l1.5-0.9l-2.3,90.1c-0.4,7.5-3.2,11.3-8.4,11.4c-3.7-1.1-54.8-1.7-51.2-8.8
        c-1.6,5.9,40.5,4.9,48.4,5.8c5.4-0.4,8.4-4.1,8.8-11.3l2.6-86.1L625.6,564.8L625.6,564.8z"/>
      <polygon fill="none" points="625.1,565.1 625.6,564.8 625.1,565.1 625.1,565.1 		"/>
      <polygon fill="none" points="565.8,582.1 622.5,576.6 620.8,637.6 565.4,642.8 565.8,582.1 		"/>
      <polygon fill="none" points="565.4,644.4 565.4,642.8 620.8,637.6 620.7,639.6 565.4,644.4 		"/>
      <path fill="none" d="M566.6,658.3c-0.6-0.2-1.3-0.8-1.3-1.7c0,0,0.1-12.3,0.1-12.3l55.3-4.8c-0.5,5.8,1.4,20.2-6.3,20.7
        C600.4,661,584.6,660.2,566.6,658.3L566.6,658.3z"/>
      <polygon fill="none" points="634.9,675.3 638.8,675.3 638.7,686.3 634.7,686 634.9,675.3 		"/>
      <path fill="none" d="M632.9,305.8c2.1-9.6,3.4-15.8,11.6-18.6c0.1,8.1,0,20.2-0.6,28.3c-4.2,0.9-6.6,4.4-7.3,10.5
        c-3.1,33.9-4.7,74.5-5.6,113.3l-2.2,0.6c0.1-4.9-0.3-10.6-0.8-15.3c-3.4-36.8,3.7-74.7,6.3-110.2c-4,18.4-7.3,49.5-10,93.4
        l-1.5-10.3C624.7,364.6,628,328.6,632.9,305.8L632.9,305.8z"/>
      <path fill="none" d="M624.5,408.8c2.1-43.4,6.1-76.2,9.8-94.4c-3.5,32-6.2,64.9-7.9,98.4C625.5,411.5,624.9,410.3,624.5,408.8
        L624.5,408.8z"/>
      <path fill="none" d="M625,410.4c1.7,1.5,2.6,12.1,3,14.3l-10.4,12.5c1.2-1.9,1.4-3.5,0.7-5l-2.3-10.3L625,410.4L625,410.4z"/>
      <polygon fill="none" points="603.7,475.9 603.7,476 603.7,475.7 		"/>
      <path fill="none" d="M603.7,475.9c-0.3-11.3,3.9-13.9,13.8-16.7l0,21.1C613.6,479.8,603.7,482.6,603.7,475.9L603.7,475.9z"/>
      <path fill="none" d="M618.3,432.1c5.7,1.7-46.5,58.4-46.7,60.3l-1.4-12.7l45.8-58L618.3,432.1z"/>
      <path fill="none" d="M640.6,437.4c-3.4,0.5-6.6,1.2-9.5,2c0.9-38.9,2.5-79.4,5.6-113.3c0.7-6.2,3.1-9.7,7.3-10.5
        C643.3,325.4,642.4,363.1,640.6,437.4z"/>
      <polygon fill="none" points="640.4,444.4 640.6,437.4 644,436.9 643.8,446.2 643.8,444.4 640.4,444.4 		"/>
      <path fill="none" d="M611.5,461.4c-0.8,0.3-2.1,0.7-2.8,1.2l17.8-20.1l-1,33.6c-0.3-3.3-0.4-9.6-0.2-12.9c0.1-4.2-4.4-5.8-6.9-6.9
        C616.3,456,614,457.7,611.5,461.4L611.5,461.4z"/>
      <path fill="none" d="M611.5,461.4c4.5-7.1,7.9-5.7,12.9-1.2c1.4,1.1,0.7,6.6,0.7,8.3l-0.6-5.2c0.4-1.2-6.2-6.8-7-4L611.5,461.4
        L611.5,461.4z"/>
      <path fill="none" d="M617.6,459.3c0.8-2.7,7.4,2.9,7,4c0,0,0.6,5.2,0.6,5.2c0,1.1,0.3,7.7,0.3,8.6c0,4.1-5,2.8-7.8,3.3
        L617.6,459.3L617.6,459.3z"/>
      <path fill="none" d="M625.4,477.5c0,0,0,0.1,0,0.1l0-0.5V477.5z"/>
      <path fill="none" d="M625.4,477.1l0-1.5C625.5,476.1,625.4,476.7,625.4,477.1z"/>
      <polygon fill="none" points="625.4,477.1 625.4,476.7 625.4,477.5 625.4,477.1 		"/>
      <path fill="none" d="M640.4,455.1v-10.7h3.4c0,1,0,9.5,0,10.7H640.4L640.4,455.1z"/>
      <path fill="none" d="M663.4,435.7c11.2-0.4,25.6,0.4,43.2,2.5v-10.3c-53.2-4.2-73.6-74.1-36.1-109.6l0.3-3.7l-23.6,1.9L644,436.9
        C649.9,436.2,656.3,435.8,663.4,435.7L663.4,435.7z M646.4,408.8c0.2-5,5.3-4.5,8.9-3.4v0.2c1,0.4,2,1,3.1,1.8l0,0
        c3.5,2.1,4.3,4.9,4.1,8.3c-0.6,2.3,1.8,15.8-2.2,15.3c-1.8,0-6.1,0-7.9,0c-4.4,0.1-7,0.4-6.4-4.7
        C645.9,426.2,646.4,408.8,646.4,408.8z"/>
      <path fill="none" d="M645.9,426.9l0-0.6l0.4-17.4c0.1-4.6,5-4.9,8.4-3.4h-2.4v1.3c-3-0.5-6,0.7-6,4.1
        C646.4,410.8,645.9,426.9,645.9,426.9z"/>
      <path fill="none" d="M647.3,428.2c0.1,1.9,3.7,1.2,5,1.3v1.4c-3.4,0.1-7.3,0.3-6.4-4.1c0,0,0.4-16,0.4-16c0.1-3.5,3-4.6,6-4.1v1.5
        c-2.1-0.4-4.5,0.1-4.6,2.7C647.8,410.9,647.3,428.2,647.3,428.2z"/>
      <path fill="none" d="M647.5,428.7l-0.1-0.5l0.4-17.3c0.1-2.6,2.4-3.2,4.6-2.7v21.4C651.2,429.4,647.9,430,647.5,428.7L647.5,428.7
        z"/>
      <path fill="none" d="M655.3,431h-2.9c0-3,0-22.8,0-25.6c0.4,0,2.6,0,2.9,0C655.3,409,655.3,427.1,655.3,431z"/>
      <path fill="none" d="M655.3,431v-1.4h4.8c0.9-0.1,0.9-1.3,0.9-1.9c-0.5-6.5,2.6-15.4-5.7-18.5v-1.6c1,0.5,2,1.1,3.1,1.8l0,0
        c6.3,2.9,3.5,13.1,4.1,18.2C663.2,432.2,657.9,430.7,655.3,431L655.3,431z"/>
      <path fill="none" d="M660.9,428.9c-0.2,1.2-4.9,0.4-5.6,0.7v-20.4c2.6,1.3,5.9,3.7,5.7,6.6C661,416.4,661.1,428.7,660.9,428.9z"/>
      <path fill="none" d="M658.4,409.3L658.4,409.3c-1-0.7-2.1-1.3-3.1-1.8v-2c1,0.4,2,1,3.1,1.8l0,0c3.5,2.1,4.3,4.9,4.1,8.3
        C662.4,413.1,661,411,658.4,409.3L658.4,409.3z"/>
      <polygon fill="none" points="706.6,438.2 706.6,427.9 714.7,429.5 714.7,417.1 717.4,417.8 716.8,438.8 706.6,438.2 		"/>
      <path fill="none" d="M698.8,447.5c-0.1-2.6,2.5-3.8,7.8-3.6l-1,117.5h-8.4L698.8,447.5L698.8,447.5z"/>
      <path fill="none" d="M707.5,448.9l-1-5c6.3-0.1,9.5,2.1,9.8,6.8c0.1,25.8-0.2,84.4-0.5,110.7h-2.4l1-108.4
        C714.1,449.9,711.7,448.5,707.5,448.9L707.5,448.9z"/>
      <polygon fill="none" points="706.5,443.9 707.5,448.9 706.6,561.4 705.5,561.4 706.5,443.9 		"/>
      <path fill="none" d="M706.5,443.9l0.1-5.7c2.4,0.1,11.3,0.6,13.6,0.8l-0.9,122.4h-3.4c0.2-26.5,0.6-84.7,0.5-110.7
        C716.1,446,712.8,443.8,706.5,443.9L706.5,443.9z"/>
      <path fill="none" d="M706.6,427.9c-74-8.7-73.1-117.5,0.4-126.7l-1.2,5.3c-60.2,12.7-61.7,99.3-2.5,114.9
        c-59.2-15.6-57.7-102.2,2.5-114.9l-0.4,5.1c-54,14-52.7,90,1.2,103.2V427.9z"/>
      <path fill="none" d="M680.7,400.1c-28.4-26.6-15-78.1,22.4-87.9c3-0.8,6.2-1.6,9.2-2.3c0,0,9.1-3.9,9.1-3.9l0.6-0.3l0.6,11.4
        c-68-2.2-64.9,98.2,2.8,91.9v10.8C709.5,415.7,692.8,412.8,680.7,400.1L680.7,400.1z"/>
      <path fill="none" d="M706.2,407.7c57.7,14.7,83.8-66.6,28.3-88.2C674.3,300.9,646.5,387.9,706.2,407.7L706.2,407.7z M745.6,344.6
        L745.6,344.6c13.1,17.4,2.9,45-18.2,49.9c-19.5,5.6-40.6-9.9-40.7-30.1C685.1,332.7,727.5,318.3,745.6,344.6z"/>
      <path fill="none" d="M695.2,381.6l-1.7,1.5c-3.5-4.4-5.6-9.3-6.4-14.8l2.2-0.2C690.1,373.1,692,377.6,695.2,381.6L695.2,381.6z"/>
      <path fill="none" d="M693.5,383.1c3.7-3.2,8.7-7.8,12.4-11.1c0.4-0.4,1.2-1.1,1.7-1.5c0,0,2.9-1,2.9-1l1.8,1.6l-2.7,3.2l-0.8-0.7
        c-3.3,3.3-9.4,9.2-12.6,12.4C695.2,385.1,694.3,384.1,693.5,383.1L693.5,383.1z"/>
      <polygon fill="none" points="689,364.3 686.7,364.4 686.9,359.6 689.1,359.8 		"/>
      <polygon fill="none" points="692.8,364.1 689,364.3 689.1,359.8 692.9,360.3 		"/>
      <path fill="none" d="M689.3,368.1l3.8-0.3c0.7,4.2,2.3,7.9,4.9,11.3l-2.8,2.6C692,377.6,690.1,373.1,689.3,368.1L689.3,368.1z"/>
      <path fill="none" d="M700.4,377l-2.3,2.1c-2.6-3.4-4.2-7.1-4.9-11.3l3.1-0.3C696.8,371,698.2,374.2,700.4,377L700.4,377z"/>
      <path fill="none" d="M696.2,367.5l8.6-0.8c0.4,1.6,1,3.1,2,4.5l-0.9,0.8c-1.7-0.7-3.1-0.9-4.1-0.7l-1.4,5.7
        C698.2,374.2,696.8,371,696.2,367.5L696.2,367.5z"/>
      <path fill="none" d="M707.6,370.5l2.9-1c0.9,0.8,2.7,2.4,3.7,3.2c-0.2,0.8-0.6,3.4-1.2,3.9c1.5,0.7,3.1,1.1,4.8,1.2
        c-0.3-0.8,0.7-3.2,0.9-4c1.2-0.2,3.6-0.7,4.8-0.9c0.5,0.6,2.3,2.5,2.3,3.4c1.4-0.7,2.8-1.8,3.9-3c-0.8-0.3-2.1-2.5-2.5-3.2
        c0.7-1.3,1.6-3,2.3-4.3c0.8,0,3.4-0.2,4,0.3c0.1-1.2,0.3-3.7,0.1-4.9c-0.7,0.5-3.3,0-4.1,0c-0.5-1.1-1.5-3.3-1.9-4.4
        c0.5-0.6,1.9-2.8,2.7-2.9c-1.1-1.2-2.4-2.4-3.7-3.2c-0.1,0.8-2,2.6-2.6,3.1c-1.2-0.3-3.5-0.9-4.7-1.2c-0.2-0.7-1-3.3-0.6-4
        c-1.7,0-3.3,0.3-4.8,0.9c0.6,0.6,0.8,3.2,0.9,4c-1,0.7-2.9,2.2-3.9,2.9c-0.7-0.3-3.1-1.3-3.5-2c-1.1,1.3-1.8,2.8-2.3,4.3
        c0.9-0.1,3,1.4,3.7,1.8c0,1.2-0.1,3.6-0.2,4.8c-0.7,0.3-3,1.7-3.8,1.5c0.4,1.6,1,3.1,2,4.5L707.6,370.5L707.6,370.5z M709.9,364.6
        c-2.1-2,1.2-5.3,3.2-3.2C715.2,363.5,712,366.7,709.9,364.6L709.9,364.6z M715.9,372.4c-2.8-0.9-1.4-5.2,1.4-4.3
        C720,369,718.6,373.4,715.9,372.4L715.9,372.4z M716.2,366.1c-3.8-3.7,2.1-9.5,5.7-5.8C725.7,364,719.8,369.9,716.2,366.1
        L716.2,366.1z M727.2,367.4c0.5,2.9-4,3.6-4.4,0.7C722.2,365.3,726.9,364.5,727.2,367.4z M717.6,358.1c-2.7,1-4.2-3.4-1.4-4.3
        C719,352.9,720.4,357.3,717.6,358.1L717.6,358.1z M727.5,359.3c-0.4,2.9-5,2.2-4.5-0.7C723.4,355.7,728,356.4,727.5,359.3z"/>
      <path fill="none" d="M711.5,365.3c2.9,0.1,2.9-4.6,0-4.5C708.6,360.7,708.6,365.3,711.5,365.3L711.5,365.3z M710.2,362.4l1.3-0.8
        l1.3,0.8v1.5l-1.3,0.8l-1.3-0.8V362.4L710.2,362.4z"/>
      <path fill="none" d="M710.2,363.8l1.3,0.8l1.3-0.8v-1.5l-1.3-0.8l-1.3,0.8V363.8L710.2,363.8z M711.5,362.4c0.5,0,0.7,0.6,0.4,1
        l-0.4,0.2l-0.4-0.2C710.8,363.1,711.1,362.4,711.5,362.4L711.5,362.4z"/>
      <path fill="none" d="M711.1,362.6c0.6-0.5,1.3,0.2,0.8,0.8l-0.4,0.2l-0.4-0.2C711,363.2,710.9,362.8,711.1,362.6L711.1,362.6z"/>
      <path fill="none" d="M717.6,372.3c2.6-1.2,0.5-5.4-2.1-4C712.9,369.5,715,373.7,717.6,372.3L717.6,372.3z M715.2,369.9l1.1-1
        l1.4,0.5l0.3,1.4l-1.1,1l-1.4-0.4L715.2,369.9L715.2,369.9z"/>
      <path fill="none" d="M715.5,371.3l1.4,0.4l1.1-1l-0.3-1.4l-1.4-0.5l-1.1,1L715.5,371.3L715.5,371.3z M716.1,370.5l0-0.4
        C717.2,368.5,717.6,371.9,716.1,370.5z"/>
      <path fill="none" d="M716.1,370.5c-0.3-0.7,0.6-1.2,1-0.5C717.3,370.7,716.5,371.1,716.1,370.5L716.1,370.5z"/>
      <path fill="none" d="M719,367.3c5.3,0.1,5.3-8.3,0-8.1C713.8,359,713.8,367.4,719,367.3L719,367.3z M718.6,360.3
        c4.1,0,0.6,10.6-1.1,4C717.3,362.9,717.4,361,718.6,360.3z"/>
      <path fill="none" d="M717.7,365.2c1,2.7,3,0.3,2.8-1.7c0.3-2.5-2.2-5-3-1.4C717.3,363,717.4,364.3,717.7,365.2L717.7,365.2z
         M717.8,361.3c1.6-3,3.1,1.4,2.5,3.1c-0.5,2.7-2.4,2.3-2.7-0.2C717.4,363.3,717.5,362.2,717.8,361.3z"/>
      <path fill="none" d="M717.8,365.1c2.1,4.1,3.7-3.5,1.7-4.7C717.3,359.7,717.3,363.8,717.8,365.1L717.8,365.1z M718.6,364.6
        L718.6,364.6L718.6,364.6L718.6,364.6l-0.1,0.6l-0.2,0.1l0,0v-0.5l0.2-0.1L718.6,364.6l-0.1,0.3L718.6,364.6z M718,363.9
        c0,0,0.8-0.3,0.8-0.3l0,0c0,0.4-0.1,0.8-0.5,0.8c-0.1,0-0.3-0.1-0.3-0.2L718,363.9z M719,364.3c0.1,0,0.7-0.2,0.8-0.3v0.5
        c0,0-0.2,0.1-0.2,0.1l0-0.3l-0.1,0v0.2c0,0-0.2,0-0.2,0v-0.2l-0.1,0.1c0,0,0,0.2,0,0.2l-0.2,0.1l0,0V364.3z M719,364.9
        c0.1,0,0.7-0.2,0.8-0.2v0.2l-0.3,0.1l0.3,0.1v0.3l-0.3-0.2l-0.1,0.2l-0.2,0.1l-0.2-0.1l0-0.2V364.9z M718,361.7
        c0.2,0,0.6-0.2,0.8-0.3v0l0,0l0,0.5c0,0-0.2,0.1-0.2,0.1c0-0.1,0-0.2,0-0.2c0,0-0.6,0.2-0.6,0.2v0h0V361.7L718,361.7z
         M719.3,361.2l0.2-0.1l0,0v0.5c0,0-0.2,0.1-0.2,0.1v-0.2l-0.1,0.3l-0.1,0v0h0L719.3,361.2z M718,362.5L718,362.5l0.8-0.5v0.3
        l-0.1,0l0,0.3c0,0,0.1,0,0.1,0v0.2c-0.1,0-0.7,0-0.8,0l0,0V362.5z M718,363.6l0.4-0.1l-0.4,0l0-0.3l0.8-0.3v0.2
        c-0.1,0-0.3,0.1-0.4,0.1l0.4,0l0,0l0,0.2l-0.8,0.3l0,0L718,363.6L718,363.6L718,363.6z M719.1,363.9l0.4-0.3c0,0-0.4,0-0.5,0v-0.3
        c0,0,0.8,0,0.8,0l0,0v0.2h0l-0.8,0.6L719.1,363.9L719.1,363.9L719.1,363.9z M719.8,362.8c0.1,0.5-0.4,0.8-0.8,0.5L719,363
        C719,362.7,719.6,362.4,719.8,362.8z M719,362c0.1,0,0.7-0.2,0.8-0.3l0,0v0.2l-0.3,0.1l0.3,0.1v0.2l-0.3-0.2l-0.1,0.2l-0.2,0.1
        C718.9,362.6,719,362.2,719,362L719,362z"/>
      <polygon fill="none" points="718.5,364.7 718.5,364.9 718.6,364.6 718.7,364.6 718.7,364.6 718.7,364.6 718.5,365.2 718.3,365.3 
        718.3,365.2 718.3,364.7 718.5,364.7 718.5,364.7 		"/>
      <path fill="none" d="M718.1,364.5l0.2,0.1c0.4-0.1,0.6-0.5,0.5-0.8l0,0c0,0-0.8,0.3-0.8,0.3C718,364.1,717.9,364.4,718.1,364.5
        L718.1,364.5z M718.2,364.1l0.5-0.2c0,0.2-0.3,0.4-0.5,0.3L718.2,364.1z"/>
      <path fill="none" d="M718.3,364.3L718.3,364.3l-0.1-0.1l0.5-0.2C718.6,364.1,718.5,364.3,718.3,364.3L718.3,364.3z"/>
      <path fill="none" d="M719,364.8L719,364.8l0-0.5c0.1,0,0.7-0.2,0.8-0.3v0.5c0,0-0.2,0.1-0.2,0.1l0-0.3l-0.1,0v0.2c0,0-0.2,0-0.2,0
        v-0.2l-0.1,0.1c0,0,0,0.2,0,0.2L719,364.8L719,364.8z"/>
      <path fill="none" d="M719,365.4l0.2,0.1l0.2-0.1l0.1-0.2l0.3,0.2v-0.3l-0.3-0.1l0.3-0.1v-0.2c-0.1,0-0.7,0.3-0.8,0.2
        C719,365,719,365.3,719,365.4L719,365.4z M719.2,365.1l0.2-0.1l0,0.1l-0.1,0L719.2,365.1z"/>
      <polygon fill="none" points="719.3,365.2 719.2,365.2 719.2,365.1 719.4,365 		"/>
      <path fill="none" d="M718,362v-0.3c0.2,0,0.6-0.2,0.8-0.3v0l0,0l0,0.5c0,0-0.2,0.1-0.2,0.1c0-0.1,0-0.2,0-0.2
        C718.6,361.8,718,362,718,362L718,362L718,362L718,362z"/>
      <path fill="none" d="M719.1,361.7L719.1,361.7l0.2-0.6l0.2-0.1l0,0v0.5c0,0-0.2,0.1-0.2,0.1v-0.2L719.1,361.7L719.1,361.7
        L719.1,361.7L719.1,361.7z"/>
      <path fill="none" d="M718,362.8c0.1,0,0.7,0,0.8,0v-0.2c0,0-0.1,0-0.1,0l0-0.3l0.1,0V362l-0.8,0.5h0L718,362.8L718,362.8
        L718,362.8z M718.5,362.4v0.1h-0.3L718.5,362.4L718.5,362.4z"/>
      <polygon fill="none" points="718.3,362.6 718.5,362.4 718.5,362.6 718.3,362.6 		"/>
      <path fill="none" d="M718,363.6c0,0,0.4-0.1,0.4-0.1l-0.4,0l0-0.3l0.8-0.3v0.2c-0.1,0-0.3,0.1-0.4,0.1l0.4,0l0,0l0,0.2l-0.8,0.3
        l0,0V363.6L718,363.6z"/>
      <path fill="none" d="M719,364c0.1,0,0.4-0.2,0.5-0.3c0,0-0.4,0-0.5,0v-0.3c0,0,0.8,0,0.8,0l0,0v0.2h0l-0.8,0.6V364L719,364z"/>
      <path fill="none" d="M719,363.3c0.4,0.2,0.9,0,0.8-0.5C719.5,362.3,718.8,362.8,719,363.3L719,363.3z M719.6,363
        c-0.1,0.1-0.2,0.2-0.3,0.2l-0.2,0C719,362.9,719.6,362.7,719.6,363z"/>
      <path fill="none" d="M719.3,363.2l-0.2,0c-0.1-0.3,0.5-0.4,0.5-0.1C719.6,363.1,719.4,363.1,719.3,363.2L719.3,363.2z"/>
      <path fill="none" d="M719,362.4c0,0.1,0.1,0.2,0.2,0.2l0.2-0.1l0.1-0.2l0.3,0.2v-0.2l-0.3-0.1l0.3-0.1v-0.2l0,0
        c-0.1,0-0.7,0.3-0.8,0.3V362.4L719,362.4z M719.2,362.2l0.2-0.1l0,0.2l-0.1,0L719.2,362.2L719.2,362.2z"/>
      <path fill="none" d="M719.2,362.3c0,0,0-0.1,0-0.1l0.2-0.1l0,0.2L719.2,362.3L719.2,362.3z"/>
      <path fill="none" d="M723.7,369.6c2.3,1.8,5-2,2.6-3.6C724,364.2,721.3,367.9,723.7,369.6L723.7,369.6z M723.6,367.6l0.9-1.2
        l1.5,0.1l0.6,1.4l-0.9,1.2l-1.5-0.1L723.6,367.6z"/>
      <path fill="none" d="M725.7,369.1l0.9-1.2l-0.6-1.4l-1.5-0.1l-0.9,1.2l0.6,1.4L725.7,369.1L725.7,369.1z M725.6,367.7
        c0.1,0.7-0.9,0.9-1.1,0.2C724.4,367.1,725.5,366.9,725.6,367.7z"/>
      <path fill="none" d="M724.7,368.2c-0.6-0.4,0.1-1.3,0.7-0.9C725.9,367.7,725.3,368.7,724.7,368.2L724.7,368.2z"/>
      <path fill="none" d="M714.9,355c-1.4,2.6,2.7,4.6,4,2C720.3,354.5,716.1,352.3,714.9,355z M716.5,357.5l-1.1-1l0.3-1.5l1.4-0.5
        l1.1,1L718,357L716.5,357.5L716.5,357.5z"/>
      <path fill="none" d="M718.3,355.6l-1.1-1l-1.4,0.5l-0.3,1.5l1.1,1l1.4-0.5L718.3,355.6z M716.6,356.5l-0.3-0.3
        c-0.2-1,1.4-0.9,1,0.1l-0.3,0.3C716.9,356.6,716.8,356.6,716.6,356.5L716.6,356.5z"/>
      <path fill="none" d="M717.1,356.6c-0.4,0.1-0.6-0.2-0.7-0.4c-0.2-1,1.4-0.9,1,0.1L717.1,356.6L717.1,356.6z"/>
      <path fill="none" d="M723.4,360.3c1.6,2.4,5.4-0.3,3.6-2.6C725.4,355.2,721.6,357.9,723.4,360.3L723.4,360.3z M724.3,357.7
        l1.5-0.1l0.9,1.2l-0.6,1.4l-1.5,0.1l-0.9-1.2L724.3,357.7z"/>
      <path fill="none" d="M724.6,360.2l1.5-0.1l0.6-1.4l-0.9-1.2l-1.5,0.1l-0.6,1.4L724.6,360.2L724.6,360.2z M725.6,359.4
        c-0.3,0.3-1-0.1-0.9-0.5C724.9,357.8,726.4,358.7,725.6,359.4z"/>
      <path fill="none" d="M724.8,359.3c-0.5-0.6,0.5-1.3,0.9-0.7C726.1,359.2,725.3,359.8,724.8,359.3L724.8,359.3z"/>
      <polygon fill="none" points="707.9,374.5 708.8,373.6 710.2,374.9 709.5,375.9 707.9,374.5 		"/>
      <path fill="none" d="M708.8,373.6c0.4,0.3,1.1,0.9,1.5,1.2l0,0L708.8,373.6L708.8,373.6z"/>
      <path fill="none" d="M710.2,374.9L710.2,374.9l-0.6-0.6l2.7-3.2l1.8,1.6l-0.6,3c-0.3,0.5-0.9,1.4-1.2,1.9c-2.2,3.4-7,10.7-9.2,14
        c-1.1-0.7-2.2-1.4-3.3-2.2C702.4,385.6,707.6,378.6,710.2,374.9L710.2,374.9z"/>
      <path fill="none" d="M712.3,377.5l0.6-1c1.5,0.7,3.1,1.1,4.8,1.2l0.1,1.1C715.8,378.7,714,378.2,712.3,377.5L712.3,377.5z"/>
      <path fill="none" d="M707.9,374.5l1.6,1.4l-6.2,8.5c-1-0.8-2-1.6-2.9-2.5L707.9,374.5L707.9,374.5z"/>
      <path fill="none" d="M701.8,371.3c1-0.2,2.4,0.1,4.1,0.7l-5.6,5L701.8,371.3L701.8,371.3z"/>
      <path fill="none" d="M701.1,387.4l-1.3,1.8c-1.3-0.9-2.5-2-3.6-3.2l1.6-1.6C698.8,385.6,699.9,386.5,701.1,387.4L701.1,387.4z"/>
      <path fill="none" d="M703.1,391.4l1.2-1.9c4.3,2.4,9,3.7,14.2,3.7l0.1,2.2C713,395.5,707.8,394.1,703.1,391.4L703.1,391.4z"/>
      <path fill="none" d="M697.7,384.5l2.7-2.7c0.9,0.9,1.9,1.8,2.9,2.5l-2.2,3.1C699.9,386.5,698.8,385.6,697.7,384.5L697.7,384.5z"/>
      <path fill="none" d="M704.3,389.6l2.1-3.2c3.6,2,7.6,3,11.9,3.1l0.2,3.8C713.3,393.2,708.6,392,704.3,389.6L704.3,389.6z"/>
      <path fill="none" d="M706.4,386.4c3.6,2,7.6,3,11.9,3.1l-0.6-10.6c-2-0.2-3.8-0.7-5.4-1.4L706.4,386.4L706.4,386.4z M712.7,382.4
        l1.9-2.6l1.5,0.4c0.3,1.8-0.5,5.5-1.1,7.3l-3.2-0.8L712.7,382.4L712.7,382.4z"/>
      <path fill="none" d="M711.8,386.6l0.9-4.3l1.9-2.6l1.5,0.4c0.3,1.8-0.5,5.5-1.1,7.3L711.8,386.6L711.8,386.6z"/>
      <path fill="none" d="M719.1,395.6h-0.4c-0.2-3.9-0.7-12.7-1-16.7c0-0.5-0.1-1.7-0.1-2.2l1-2.9l2.4-0.4l0.9,4.1l-1,0.2
        c0.6,5.7,1.2,12,1.9,17.7C721.5,395.5,720.3,395.6,719.1,395.6L719.1,395.6z"/>
      <path fill="none" d="M727.4,394.5c-1.5,0.4-3.1,0.7-4.7,0.8l-0.3-2.2c1.5-0.1,2.9-0.4,4.4-0.8L727.4,394.5z"/>
      <path fill="none" d="M722.4,393.1l-0.4-3.8c1.3-0.1,2.6-0.4,3.8-0.7l1,3.7C725.4,392.7,723.9,393,722.4,393.1L722.4,393.1z"/>
      <path fill="none" d="M722.1,380.1c-0.4,0.4-0.7,0.8-0.9,1.3l-0.2-2.4l2.1-0.2l0.2,0.6C722.8,379.4,722.4,379.7,722.1,380.1
        L722.1,380.1z"/>
      <polygon fill="none" points="721.7,385.8 722,386.2 721.7,386.2 721.7,385.8 		"/>
      <path fill="none" d="M731.1,393.3l-0.7-2.1c4.4-1.7,8.7-5.1,11.9-8.9l1.8,1.3C740.7,387.8,736,391.4,731.1,393.3L731.1,393.3z"/>
      <polygon fill="none" points="721,378.9 720.8,377.7 722.7,377.3 723.1,378.6 721,378.9 		"/>
      <path fill="none" d="M720.8,377.7L720.8,377.7c0.5-0.1,1.4-0.3,1.9-0.4l0,0L720.8,377.7L720.8,377.7z"/>
      <path fill="none" d="M727.1,370.1l1.1-2.1l3.8,1.9l-0.5,0.9c4.4,2.8,10.7,6.9,15,9.7c-0.7,1.1-1.5,2.1-2.3,3.1
        c-4.2-2.9-10.4-7.3-14.6-10.3C728.7,373,727.5,370.7,727.1,370.1L727.1,370.1z"/>
      <path fill="none" d="M729.3,365.8c0.8,0,3.4-0.2,4,0.3c4.9,1.8,12,4.5,16.8,6.2c-0.4,1.3-0.8,2.6-1.4,3.8
        c-4.7-2.1-11.7-5-16.4-7.1l-0.4,0.8l-3.8-1.9C728.6,367.2,728.9,366.5,729.3,365.8L729.3,365.8z"/>
      <path fill="none" d="M731.5,370.8c0.2-0.4,0.7-1.3,0.9-1.7l0.1,0C732.2,369.7,731.9,370.3,731.5,370.8L731.5,370.8L731.5,370.8z"
        />
      <path fill="none" d="M731.5,370.8c0.3-0.5,0.6-1.1,0.9-1.7l7.9,3.4c-0.5,1-1,2-1.6,2.9L731.5,370.8L731.5,370.8z"/>
      <path fill="none" d="M725.7,376.3c1.4-0.7,2.8-1.8,3.9-3l7.1,5c-1.9,2.1-4.2,4.1-6.6,5.4l1.1-5.4l-5.4-1.5L725.7,376.3
        L725.7,376.3z"/>
      <path fill="none" d="M741.5,369.1l-8.1-3c0.1-1.2,0.3-3.7,0.1-4.9l8.3-2.4C742.2,362,742.4,366.1,741.5,369.1z"/>
      <path fill="none" d="M750.2,372.4l-2.1-0.8c1.3-4.5,1.4-10.1,0.5-14.7c0.4-0.1,1.8-0.5,2.2-0.6
        C751.8,361.4,751.6,367.4,750.2,372.4z"/>
      <path fill="none" d="M746.7,375.3l2.1,0.9c-0.7,1.5-1.4,2.9-2.3,4.2l-1.9-1.2C745.4,378,746.1,376.7,746.7,375.3L746.7,375.3z"/>
      <path fill="none" d="M743.2,373.8l3.5,1.5c-0.6,1.4-1.3,2.7-2.1,3.9l-3.2-2.1C742.1,376.1,742.7,375,743.2,373.8L743.2,373.8z"/>
      <path fill="none" d="M748.1,371.6l-3.6-1.3c1-3.8,1.1-8.4,0.4-12.3l3.7-1.1C749.5,361.5,749.3,367,748.1,371.6z"/>
      <path fill="none" d="M693.5,356.7l-3.8-0.7c1.1-4.8,3.4-9.1,6.9-13l2.6,2.8C696.4,349.1,694.5,352.7,693.5,356.7z"/>
      <polygon fill="none" points="692.9,360.3 696,360.7 695.8,364 692.8,364.1 692.9,360.3 		"/>
      <path fill="none" d="M689.7,356l-2.2-0.4c1.2-5.3,3.7-10,7.6-14.2l1.5,1.6C693.1,346.9,690.8,351.2,689.7,356z"/>
      <path fill="none" d="M699.3,345.8l2.2,2.2c-2.4,2.7-4,5.8-4.9,9.1l-3.1-0.5C694.5,352.7,696.4,349.1,699.3,345.8L699.3,345.8z"/>
      <path fill="none" d="M701.4,348.1c-1.9-1.9-4.5-4.7-6.3-6.6l2.8-2.8c3.3,3.9,8.2,9.4,11.6,13.3l0.7-0.5l2.6,3.4l-1.9,1.4l-2.8-1.3
        C707.9,354.8,701.8,348.5,701.4,348.1L701.4,348.1z"/>
      <path fill="none" d="M703.9,345.6l-2.1-2.3c1-0.9,2-1.6,3.1-2.3l1.6,2.6C705.7,344.2,704.8,344.9,703.9,345.6L703.9,345.6z"/>
      <path fill="none" d="M699.4,340.4l-1.5-1.7c1.2-1.1,2.5-2.1,3.9-2.9l1.2,1.9C701.7,338.5,700.5,339.4,699.4,340.4L699.4,340.4z"/>
      <path fill="none" d="M702.9,337.7l-1.2-1.9c1.1-0.7,2.3-1.3,3.4-1.9c2.5,4.5,6.2,11,8.6,15.6c0.6,0.6,0.8,3.2,0.9,4l-1.9,1.4
        l-2.6-3.4l0.8-0.6C708.9,347.3,705.1,341.2,702.9,337.7L702.9,337.7z"/>
      <path fill="none" d="M702.9,337.7l2,3.3c-1.1,0.7-2.1,1.4-3.1,2.3l-2.5-2.9C700.5,339.4,701.7,338.5,702.9,337.7L702.9,337.7z"/>
      <path fill="none" d="M708.2,339.2l-1.9-3.4c4.3-2,9.4-2.9,14.5-2.7l-0.5,3.8C716,336.7,711.8,337.5,708.2,339.2L708.2,339.2z"/>
      <path fill="none" d="M703.9,345.6c0.8-0.7,1.7-1.4,2.6-2l4.5,7.3c-0.4,0.3-1.1,0.9-1.5,1.1L703.9,345.6L703.9,345.6z"/>
      <path fill="none" d="M706.3,335.8l-1.1-1.9c4.7-2.3,10.3-3.3,15.9-2.9l-0.3,2.2C715.7,332.9,710.6,333.8,706.3,335.8L706.3,335.8z
        "/>
      <path fill="none" d="M709.7,341.9l-1.5-2.7c3.6-1.7,7.8-2.4,12.1-2.2l-0.4,3.1C716.3,339.9,712.8,340.5,709.7,341.9L709.7,341.9z"
        />
      <path fill="none" d="M705.1,358.6l-8.5-1.5c0.9-3.4,2.5-6.4,4.9-9.1l6,6.2C706.3,355.6,705.6,357.1,705.1,358.6z"/>
      <path fill="none" d="M713.9,349.5l-4.2-7.6c3.1-1.3,6.6-2,10.1-1.8l-1.1,8.5C717,348.6,715.4,348.9,713.9,349.5L713.9,349.5z"/>
      <path fill="none" d="M722.7,349l1,0.3c1.8-4.9,4.3-11.9,6-16.7c1.2,0.4,2.5,0.9,3.7,1.5c-2,4.8-4.8,11.7-6.8,16.6
        c-0.1,0.8-2,2.6-2.6,3.1l-2.3-0.6L722.7,349L722.7,349z"/>
      <path fill="none" d="M723.7,349.3c-0.5-0.1-1.4-0.4-1.9-0.5C722.4,348.9,723,349.1,723.7,349.3L723.7,349.3z"/>
      <path fill="none" d="M719.3,352.5c-0.2-0.8-1-3.2-0.6-4c0.7-5.2,1.7-12.6,2.4-17.7c1.3,0.1,2.6,0.2,3.9,0.4
        c-0.9,5-2.3,12.4-3.2,17.5l0.9,0.2l-1.1,4.1L719.3,352.5L719.3,352.5z"/>
      <path fill="none" d="M729.3,354.3c4.9-2.9,11.4-6.8,16.3-9.7h0c0.8,1.1,1.5,2.2,2.1,3.4c-4.6,2.4-11.2,6-15.8,8.4l0.4,0.8
        l-3.9,1.8l-1-2.2L729.3,354.3L729.3,354.3z"/>
      <path fill="none" d="M730.2,353.8c-1.1-1.2-2.4-2.4-3.7-3.2l3.3-8c2.9,1.5,5.8,4,7.8,6.7L730.2,353.8L730.2,353.8z"/>
      <path fill="none" d="M732,356.4L732,356.4l0.8,1.7h0C732.6,357.7,732.2,356.8,732,356.4L732,356.4z"/>
      <path fill="none" d="M728.5,359l3.9-1.8l0.4,0.9c5-1.7,12-4.1,16.9-5.7c0.4,1.2,0.8,2.5,1.1,3.8c-5,1.4-12.2,3.5-17.2,5
        c-0.7,0.5-3.3,0-4.1,0L728.5,359L728.5,359z"/>
      <path fill="none" d="M732.8,358.1l-0.8-1.8l7.6-4c0.5,1,1,2,1.3,3L732.8,358.1L732.8,358.1z"/>
      <path fill="none" d="M728.9,334.7l-1.3,3.6c-1.2-0.4-2.4-0.7-3.7-1l0.7-3.8C726.1,333.8,727.5,334.2,728.9,334.7L728.9,334.7z"/>
      <path fill="none" d="M731,339.7l1.5-3.5c4.2,2.1,8.3,5.7,11.2,9.6l-3.3,2C737.9,344.5,734.5,341.5,731,339.7L731,339.7z"/>
      <path fill="none" d="M721.8,348.8l1.5-8.4c1.1,0.2,2.2,0.5,3.2,0.8l-2.9,8.1C723,349.1,722.4,348.9,721.8,348.8L721.8,348.8z"/>
      <path fill="none" d="M741.9,340.3c1.4,1.4,2.6,2.8,3.7,4.3l-1.9,1.2c-2.8-4-7-7.5-11.2-9.6l0.9-2.1
        C736.4,335.6,739.3,337.7,741.9,340.3L741.9,340.3z"/>
      <path fill="none" d="M742.3,350.9l3.4-1.8c0.7,1.3,1.3,2.7,1.8,4.1l-3.6,1.2C743.5,353.2,743,352,742.3,350.9L742.3,350.9z"/>
      <polygon fill="none" points="750.2,356.4 750.7,356.2 750.7,356.3 750.2,356.4 		"/>
      <path fill="none" d="M749.6,352.4l-2.1,0.7c-0.5-1.4-1.1-2.8-1.8-4.1l2-1C748.5,349.4,749.1,350.9,749.6,352.4z"/>
      <path fill="none" d="M725,331.4c1.6,0.3,3.2,0.7,4.7,1.2l-0.8,2.1c-1.4-0.5-2.9-0.8-4.3-1.1L725,331.4L725,331.4z"/>
      <path fill="none" d="M689.3,368.1l-2.2,0.2l-0.4-3.9c5.1-0.2,12.5-0.5,17.7-0.8c0-0.1,0-0.8,0-0.9l4.2,0.1l-0.1,2.4
        c-0.7,0.3-2.9,1.7-3.8,1.5C700.6,367.1,693.4,367.8,689.3,368.1L689.3,368.1z"/>
      <path fill="none" d="M686.9,359.6c0.1-1.3,0.3-2.6,0.6-3.9c5.1,0.9,12.4,2.1,17.6,3c0.9-0.1,3,1.4,3.7,1.8l-0.1,2.4l-4.2-0.1
        c0-0.2,0-0.8,0-1C699.3,361.1,692,360.2,686.9,359.6L686.9,359.6z"/>
      <path fill="none" d="M695.8,364l0.1-3.3l8.5,1c0,0.6,0,1.3-0.1,1.9L695.8,364L695.8,364z"/>
      <polygon fill="none" points="704.4,362.7 704.4,362.9 704.4,362.5 		"/>
      <path fill="none" d="M721.2,381.3c0.4-0.9,1.2-1.7,2.1-2.1l2.3,8.3c-1.5,0.2-2.9-0.5-3.8-1.7L721.2,381.3L721.2,381.3z"/>
      <path fill="none" d="M722,386.2c0.9,1,2.2,1.4,3.5,1.3l0.3,1.2c-1.2,0.3-2.5,0.5-3.8,0.7l-0.3-3.1L722,386.2L722,386.2z"/>
      <path fill="none" d="M728.6,385.5c1.4-2.2,0.4-5.5-2.1-6.4l-0.7-2.3l5.4,1.5c-0.5,2.3-1.5,7-2,9.3L728.6,385.5L728.6,385.5z"/>
      <path fill="none" d="M729.2,384.1c-0.1,0.5-0.3,1-0.6,1.4l-2.1-6.4C728.5,379.8,729.6,382.1,729.2,384.1z"/>
      <polygon fill="none" points="729.3,387.5 729.3,387.5 729.3,387.5 		"/>
      <path fill="none" d="M729.3,387.5c0.4,1.4,1.4,4.5,1.9,5.8c-1.2,0.5-2.4,0.9-3.7,1.2c-1.4-5-3.3-12.2-4.7-17.2l-0.9,0.2l-0.9-4.1
        l2.4-0.5l2,2.3c0.3,1,0.9,2.9,1.2,3.9C727,380.5,728.8,386.2,729.3,387.5z"/>
      <path fill="none" d="M729.3,387.5L729.3,387.5c3.7-1.5,7.2-4.3,10-7.5l3.2,2.2c-3.2,3.8-7.5,7.2-11.9,8.9L729.3,387.5L729.3,387.5
        z"/>
      <polygon fill="none" points="717.4,417.8 720.2,418.6 720.2,439 716.8,438.8 717.4,417.8 		"/>
      <polygon fill="none" points="722.8,419.2 725.5,419.9 725.4,439.3 722.8,439.1 722.8,419.2 		"/>
      <polygon fill="none" points="720.2,418.6 722.8,419.2 722.8,439.1 720.2,439 720.2,418.6 		"/>
      <path fill="none" d="M720.2,439l2.6,0.1l-1.7,202.2c-1.8,0.3-3.5,0.6-5.2,0.9c0-2.7,0-10.1,0-12.8c2-0.5,3-2,2.9-4.7
        C719.1,584.7,719.9,480.2,720.2,439L720.2,439z"/>
      <path fill="none" d="M722.8,439.1l2.6,0.1l-1.2,195.6c-0.3,3.4-0.1,6.2-3.1,6.5L722.8,439.1L722.8,439.1z"/>
      <path fill="none" d="M707.5,448.9c4.2-0.4,6.5,1,7,4.1l-1,108.4h-6.8L707.5,448.9L707.5,448.9z"/>
      <path fill="none" d="M706.6,427.9v-13.2c2.6,0.8,5.3,1.6,8.1,2.4v12.4L706.6,427.9L706.6,427.9z"/>
      <path fill="none" d="M643.8,316.7c0.4-6.3,0.8-18,0.7-24.3l3.7-2.3l3-0.6c4-3.4,13.6-4.4,18.1-1.6l-0.1,7.5
        c-0.8-1.2-6.4,0.6-5.2,1.4v4.4c-0.1,0.1-0.2,0.2-0.2,0.4l-1,0c-1.4,0.9-0.9,3-3.3,3.1v2.2c-3.4,3.7-8.2,4.5-14.3,2.7l0-1.7
        l2.8-2.1c9.2-1.8,16-0.3,15.5-11.3c15.8-7.1-8.2-9.5-12.2-3.8c-1.7,0.2-4.2,1.5-5.6,2.3c-0.1,5.5-0.4,14.8-0.5,20.2
        c0.9-0.7,1.9-1.3,2.9-1.7v3.7l20.3-1.5c0.5,0,0.7-0.4,0.8-1.3c0.3-2,0.4-6.6,0.4-8.7c-0.1-1.9,0.3-6.4-0.2-8.2l0.1-7.5l2.9-0.2
        l-0.3,0.1c0.1,10.1-0.2,18-1.2,26.8C668.7,314.8,645,316.7,643.8,316.7L643.8,316.7z"/>
      <path fill="none" d="M647.9,311.5c-1,0.4-2,0.9-2.9,1.7l0.1-3.7c6.1,1.9,10.9,1,14.3-2.7v3.1C657.9,310.2,649.1,310.9,647.9,311.5
        L647.9,311.5z"/>
      <path fill="none" d="M647.9,315.2v-3.7c6.2-1.4,15.2-1.7,21.4-2.6c-0.3,0.8,0.1,4.8-1.2,4.8C668.2,313.7,647.9,315.2,647.9,315.2
        L647.9,315.2z"/>
      <path fill="none" d="M643.8,316.7l3.3-0.3L644,436.9l-3.5,0.5C642.3,364.7,643.4,324.5,643.8,316.7L643.8,316.7z"/>
      <path fill="none" d="M648,305.7l-2.8,2.1l0.1-2c0.4-0.3,2.2-1.7,2.6-2c5.5-1,13.6-0.2,12-8.7l0.7-0.1c-0.2,1.8,0.7,6.1-1.2,7.2
        c3.9-0.2,3.1-3.9,3.1-7.6l1-0.3C664,305.4,657.2,304,648,305.7L648,305.7z"/>
      <path fill="none" d="M647.8,303.8l-0.5,0.4v-1.4c1.4,0,5.3,0.1,6.7,0.1C652,303.4,649.9,303.7,647.8,303.8L647.8,303.8z"/>
      <polygon fill="none" points="647.3,304.2 645.2,305.8 645.5,294.6 648.5,292.8 650.9,292.4 650.8,302 649.4,302 649.4,302.9 
        647.3,302.9 		"/>
      <path fill="none" d="M661.6,306.5l0,3.2l-0.3,0c0.1-2.3-0.8-6.7,1.4-8.2l1,0l-0.1,2.3l5.7-0.2v5.2l-6.7,0.7l0.1-7.3
        C662.1,303,661.7,304.4,661.6,306.5z"/>
      <path fill="none" d="M661.6,309.7c0-2-0.1-6.5,1.1-7.5l-0.1,7.3L661.6,309.7L661.6,309.7z"/>
      <path fill="none" d="M661.2,305.6l0.1,4.2l-1.9,0.2c0-1.3,0-4.1,0-5.4c1.1-0.2,1.8-0.5,2-1.1L661.2,305.6z"/>
      <path fill="none" d="M670.5,318.3c10.3-10.6,22.8-14.4,36.6-17.1l-1.1-23.6c-4.6,0-14.2,0.4-18.4-0.7v-2.3c-2,0.3-8.6-0.8-9.9,1.2
        c0.4,8.7-1,31.1-6.4,33.8C671,311.6,670.6,316.2,670.5,318.3L670.5,318.3z M694.7,283.7c-0.9,5.3-13.2,5.3-14.1,0
        C681.5,278.4,693.8,278.4,694.7,283.7z"/>
      <path fill="none" d="M682.6,286.4c3.3,1.9,11.3,1.8,12.1-2.8C694.1,276.7,674.5,280,682.6,286.4L682.6,286.4z M694.1,283.2
        c-1,4.5-11.9,4.5-12.9,0C682.2,278.7,693.1,278.7,694.1,283.2z"/>
      <path fill="none" d="M683.1,285.5c-7.3-5.4,10.4-8.1,11-2.3C693.3,287.1,686.1,287,683.1,285.5L683.1,285.5z"/>
      <path fill="none" d="M669.3,303.7l-5.7,0.2c0.1-0.3-0.1-2.6,0.4-2.7h5.4V303.7z"/>
      <path fill="none" d="M705.4,311.5c0-2.6,0.9-7.8,1.6-10.3h5.2l-0.9,8.9C709.4,310.5,707.4,311,705.4,311.5L705.4,311.5z"/>
      <path fill="none" d="M712.3,309.8c1.3-0.9,10.3-3.3,10.1-5.6c5.9-11.6-9.3-20-16.6-26.6l1.1,23.6h5.2l-0.9,8.9L712.3,309.8
        L712.3,309.8z M711.9,283.8l7.1,6.4c-0.4,1.3,0.8,7.8-1.8,6.7c-1.3-1.2-5.5-3.5-5.3-5.5C711.9,289.2,711.9,286,711.9,283.8
        L711.9,283.8z"/>
      <path fill="none" d="M711.9,286.7v-2.9l7.1,6.4c-0.3,1.2,0.4,6.3-0.9,6.8c0-1,0-4.6,0-5.5L711.9,286.7L711.9,286.7z"/>
      <polygon fill="none" points="711.9,288.3 711.9,286.7 718.1,291.6 718.1,292.9 711.9,288.3 		"/>
      <path fill="none" d="M713.3,293.9c-1.9-1.4-1.3-3.7-1.4-5.6l6.2,4.6l0,4.1C717.4,297.6,713.8,294.2,713.3,293.9L713.3,293.9z"/>
      <path fill="none" d="M649.4,302.9V302c1,0,6.4,0,7.5,0.1C655.2,303,651.3,303,649.4,302.9L649.4,302.9z"/>
      <path fill="none" d="M647.8,288.6l-2,1.4v-2.7c1-1,3.9-2,6.2-1.9l-0.1,2.3C650.4,288,649.1,288.3,647.8,288.6L647.8,288.6z"/>
      <path fill="none" d="M645.8,290c3.8-2.8,8.9-2.6,13.4-3.5c-3.4,0.5-6,1.5-8,3.1l-3,0.6l-3.7,2.3v-1.6L645.8,290L645.8,290z"/>
      <path fill="none" d="M648.3,291.5c4.9-1.6,7.5-4.7,13.8-4c-3.5,0.2-7.5,1.2-9.7,3.7L648.3,291.5L648.3,291.5z"/>
      <path fill="none" d="M648.5,292.8l-3,1.8l0-0.8l2.8-2.2l4.1-0.4c-0.3,0.4-0.5,0.8-0.6,1.2C651.2,292.4,649.1,292.7,648.5,292.8
        L648.5,292.8z"/>
      <polygon fill="none" points="645.5,293.7 645.6,292.9 648.3,291.5 645.5,293.7 		"/>
      <path fill="none" d="M650.9,292.4l0.9-0.1c-0.1,3.2,5.1,3.2,8,2.9c0.5,3.5-0.1,5.6-1.8,6.3c-1.8-1.2-5.7-1.4-7.1,0.5h-0.1
        L650.9,292.4L650.9,292.4z"/>
      <path fill="none" d="M651.8,292.7c0.6-4.9,15.5-7.8,16.9-2.6c-0.3,2.9-5.5,4.5-8.3,4.9c0-0.5-0.2-0.7-0.7-0.7
        c8.9-0.7,11.8-7.7,0.5-6.4c-8.3,0.7-11,7.4-0.7,6.4c0.5-0.1,1,0.1,0.9,0.7C657.6,295.5,652.5,295.7,651.8,292.7L651.8,292.7z"/>
      <path fill="none" d="M653.3,291.7c0,0,0,1.7,0,1.7c-4.1-5.9,20.3-8.2,13.2-1.3C668.3,286,654.3,288.2,653.3,291.7z"/>
      <path fill="none" d="M653.3,291.7c1.3-3.4,11.3-4.9,13.2-1.6C665,293.4,655.2,294.9,653.3,291.7L653.3,291.7z"/>
      <path fill="none" d="M654.9,294.1c-0.7-0.2-1.3-0.4-1.6-0.7v-1.7c2,3.2,11.7,1.7,13.2-1.6v2C663.5,294.1,658.3,295,654.9,294.1
        L654.9,294.1z"/>
      <path fill="none" d="M650.9,302c1.4-1.9,5.3-1.7,7.1-0.5C657.4,302.3,651.9,301.9,650.9,302L650.9,302z"/>
      <path fill="none" d="M651.8,287.7l0.1-2.3h1.2c0,0.3-0.1,1.7-0.1,2L651.8,287.7L651.8,287.7z"/>
      <path fill="none" d="M653.1,287.4l0.1-1.6h2.1v1.2C654.5,287.2,653.8,287.3,653.1,287.4L653.1,287.4z"/>
      <path fill="none" d="M655.3,285.5c0-0.3,0.1-0.5,0.4-0.7c0.7,0.7,3.2,0.9,7.7,0.7l0,0.7c-2.6-0.1-5.5,0.4-8.1,0.8
        C655.3,286.8,655.3,285.8,655.3,285.5L655.3,285.5z"/>
      <path fill="none" d="M655.7,284.9c0.9-0.7,6.7-0.8,7.9-1l-0.1,1.8C658.9,285.8,656.3,285.5,655.7,284.9L655.7,284.9z"/>
      <polygon fill="none" points="663.4,286.3 663.4,285.6 667.9,285.9 667.9,286.3 663.4,286.3 		"/>
      <path fill="none" d="M661.8,286.3c1,0,5,0,6.1,0c0-0.2,0-2.2,0-2.5h6.4c0.5,3.9-2.4,4.2-5.1,4.2
        C667.5,286.9,665,286.3,661.8,286.3L661.8,286.3z"/>
      <path fill="none" d="M676.4,280.6c-0.2,1.5-0.9,2.6-2,3.2c0.2,2.4-0.7,3.8-2.5,4c0.1,8-0.1,15.3-0.6,21.7
        c5.4-2.6,6.8-25.2,6.4-33.8C676.7,276.4,676.6,279.5,676.4,280.6L676.4,280.6z M676.9,283.6v1.8l-1,1.6c-1.5,0.1-1-2.4-0.6-3.4
        H676.9L676.9,283.6z"/>
      <polygon fill="none" points="675.2,283.6 676.9,283.6 676.9,285.4 674.8,285.3 675.2,283.6 		"/>
      <path fill="none" d="M675.9,287c-0.7,0-1.1-0.6-1.1-1.7l2.1,0.1L675.9,287L675.9,287z"/>
      <path fill="none" d="M664,301.2v-4.4c-1.2-0.8,4.4-2.6,5.2-1.4c0.4,0.1,0.1,5.4,0.2,5.7H664L664,301.2z"/>
      <path fill="none" d="M662,300.7c-0.4,1.1-1.3,1.6-2.6,1.5c1.9-1.1,1-5.4,1.2-7.2l2-0.4C662.6,297.3,662.5,299.3,662,300.7z"/>
      <path fill="none" d="M694.9,277.6c0.7-1.7,0.9-3.6,3.4-3.6c0,0,7.8,0,7.8,0l5.2,4.2l-0.1,3.6c-1.8-1.5-3.5-3-5.2-4.3L694.9,277.6
        L694.9,277.6z"/>
      <path fill="none" d="M724.3,293.3c8.9-0.9,5.9,2.8,5.3,8.6c-0.5,2.7-5.1,2-7.1,2.3C723.3,302.2,724.2,296.6,724.3,293.3
        L724.3,293.3z"/>
      <path fill="none" d="M724.1,296.6c-1.4-3.7-3.5-6.1-6.1-8.5v-6.5c0.6-0.1,1.3,0.9,2.3,2.9C722.3,287.9,725.3,292.3,724.1,296.6z"
        />
      <path fill="none" d="M664.4,687.7l-4.8-0.2c2.7-22.9,22.6-43.1,45.1-47l0,4.3C684.8,649,666.9,667.3,664.4,687.7z"/>
      <path fill="none" d="M659.7,687.4l-9.7-0.5c2.6-28.8,26.9-52,54.9-55.5l-0.1,9C682.2,644.3,662.4,664.5,659.7,687.4z"/>
      <path fill="none" d="M704.9,628.1c4.4-1.1,11.4-1.2,10.5-7.9c0,0,0.4-58.9,0.4-58.9h3.4l-0.5,63.3c0.9,6.7-9.6,5-13.9,6.7
        L704.9,628.1L704.9,628.1z"/>
      <polygon fill="none" points="704.9,628.1 704.9,628.1 705.5,561.4 706.6,561.4 706.1,625.8 		"/>
      <path fill="none" d="M709.8,624.9c6.3,2.9,2.3-61.8,3.6-63.5h2.4l-0.4,58.9c0.9,6.6-6.1,6.8-10.5,7.9l1.2-2.3L709.8,624.9
        L709.8,624.9z"/>
      <path fill="none" d="M712.9,620.9c0.2,4-4,4.1-6.8,4.8l0.5-64.4h6.8L712.9,620.9z"/>
      <path fill="none" d="M700,629.2c-2.7,0.3-3.9-1.3-3.8-4.7l0.9-63.1h8.4l-0.6,66.7L700,629.2L700,629.2z"/>
      <path fill="none" d="M704.9,631.4c3.5-0.7,7.5-1.4,11.1-2v8.7c-3.8,0.7-7.5,1.4-11.1,2.3L704.9,631.4L704.9,631.4z"/>
      <path fill="none" d="M704.8,644.6l0-4.3c3.6-0.9,7.3-1.7,11.1-2.3v4.1C712.1,642.9,708.4,643.7,704.8,644.6L704.8,644.6z"/>
      <path fill="none" d="M565.9,565c0-14.2,1.1-47.7,1.1-50.8c4.9-4.2,30.1-38,36.7-38.2c0,2.5-0.1,5.7,3.2,5.9
        c5.5-0.2,16.1,2.4,18.4-2.1l-2.2,74.4L565.9,565L565.9,565z"/>
      <path fill="none" d="M625.1,565.1l-2.2,0.7c0.9-31,2.7-92.7,3.6-123.3l2.2-2.5l0.2-0.1L625.1,565.1z"/>
      <path fill="none" d="M603.7,476L603.7,476c0,2.1,0.8,3.8,3.2,4.1c4.7-0.3,17,2.5,18.5-2.4c0.2,1.9-0.2,4.4-2.7,4.2
        c-4.7,0.5-10,0.5-15.8,0C603.6,481.7,603.7,478.5,603.7,476L603.7,476z"/>
      <path fill="none" d="M625.4,477.6l0,0.7C625.4,478.2,625.4,477.6,625.4,477.6L625.4,477.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M718.5,364.7v0.3V364.7l0.2-0.1l0,0h0l-0.2,0.6l-0.2,0.1l0,0v-0.5
        L718.5,364.7L718.5,364.7L718.5,364.7z M718.1,364.5c-0.2-0.1-0.1-0.4-0.1-0.5c0,0,0.8-0.3,0.8-0.3l0,0c0,0.4-0.1,0.8-0.5,0.8
        L718.1,364.5L718.1,364.5z M718.3,364.3L718.3,364.3l-0.1-0.1l0.5-0.2C718.6,364.1,718.5,364.3,718.3,364.3L718.3,364.3z
         M719,364.8L719,364.8l0-0.5c0.1,0,0.7-0.2,0.8-0.3v0.5c0,0-0.2,0.1-0.2,0.1l0-0.3l-0.1,0v0.2c0,0-0.2,0-0.2,0v-0.2l-0.1,0.1
        c0,0,0,0.2,0,0.2L719,364.8L719,364.8z M719,364.9c0.1,0,0.7-0.2,0.8-0.2v0.2l-0.3,0.1l0.3,0.1v0.3l-0.3-0.2l-0.1,0.2l-0.2,0.1
        l-0.2-0.1C719,365.3,719,365,719,364.9z M717.5,364.2c1.8,6.7,5.3-3.9,1.1-4C717.4,361,717.3,362.9,717.5,364.2L717.5,364.2z
         M717.8,361.3c0.8-2,2.4-0.6,2.5,1c0.6,1.7-1.2,6-2.6,2.7C717.4,364,717.4,362.5,717.8,361.3z M719.3,365.2l-0.1,0l0-0.1l0.2-0.1
        L719.3,365.2L719.3,365.2z M718,361.7c0.2,0,0.6-0.2,0.8-0.3v0l0,0l0,0.5c0,0-0.2,0.1-0.2,0.1c0-0.1,0-0.2,0-0.2
        c0,0-0.6,0.2-0.6,0.2v0h0V361.7z M719.1,361.7L719.1,361.7l0.2-0.6l0.2-0.1l0,0v0.5c0,0-0.2,0.1-0.2,0.1v-0.2L719.1,361.7
        L719.1,361.7L719.1,361.7L719.1,361.7z M718,362.8L718,362.8l0-0.3h0l0.8-0.5v0.3l-0.1,0l0,0.3c0,0,0.1,0,0.1,0v0.2
        C718.8,362.8,718.1,362.8,718,362.8L718,362.8z M718.3,362.6l0.3-0.1v0.1H718.3L718.3,362.6z M718,363.6c0,0,0.4-0.1,0.4-0.1
        l-0.4,0l0-0.3l0.8-0.3v0.2c-0.1,0-0.3,0.1-0.4,0.1l0.4,0l0,0l0,0.2l-0.8,0.3l0,0V363.6L718,363.6z M719.3,693.7L719.3,693.7l0-0.3
        l-0.1,0.3l-0.1,0v0h0l0.2-0.6l0.2-0.1l0,0v0.5L719.3,693.7L719.3,693.7z M719.3,694.3l-0.1,0c0,0,0-0.1,0-0.1l0.2-0.1L719.3,694.3
        L719.3,694.3z M719.7,695c-0.1,0.1-0.3,0.2-0.5,0.1C719.1,694.9,719.7,694.7,719.7,695z M719,694c0.1,0,0.7-0.2,0.8-0.2v0.2
        l-0.3,0.1c0.1,0,0.2,0.1,0.3,0.1v0.3l-0.3-0.2l-0.1,0.2l-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.3V694z M719.7,694.7l0.1,0.1
        c0.1,0.4-0.4,0.9-0.8,0.5C718.8,694.9,719.3,694.5,719.7,694.7z M719.1,695.7L719.1,695.7l-0.1-0.2c0,0,0.8,0.1,0.8,0v0.2h0
        l-0.8,0.6V696c0.1,0,0.4-0.3,0.5-0.3H719.1L719.1,695.7z M718.8,694.9v0.2c-0.1,0-0.3,0.1-0.4,0.1l0.4,0l0,0l0,0.2l-0.8,0.3l0,0
        v-0.2c0,0,0.4-0.1,0.4-0.1l-0.4,0l0-0.3L718.8,694.9L718.8,694.9z M718.5,694.4v0.1h-0.2L718.5,694.4L718.5,694.4z M719,364
        c0.1,0,0.4-0.2,0.5-0.3c0,0-0.4,0-0.5,0v-0.3c0,0,0.8,0,0.8,0l0,0v0.2h0l-0.8,0.6V364L719,364z M718.8,694v0.3l-0.1,0l0,0.3
        c0,0,0.1,0,0.1,0v0.2c-0.1,0-0.7,0-0.8,0l0,0v-0.2h0L718.8,694L718.8,694z M718.1,694C718.1,694,718,694,718.1,694L718.1,694
        L718.1,694l-0.1-0.2c0.1,0,0.7-0.3,0.8-0.3v0l0,0l0,0.5l-0.2,0.1l0,0h0c0-0.1,0-0.2,0-0.2L718.1,694L718.1,694z M719.3,697.2
        l-0.1,0l0-0.1l0.2-0.1L719.3,697.2L719.3,697.2z M718.2,697.9c-1-1.3-1.1-6,1.3-5.4C721,693.4,720.7,699.9,718.2,697.9
        L718.2,697.9z M717.7,697.2c2.7,5,4.3-6.5,0.6-4.6C717.3,693.6,717.3,695.8,717.7,697.2L717.7,697.2z M719,696.9
        c0.1,0,0.7-0.2,0.8-0.2v0.2l-0.3,0.1c0.1,0,0.2,0.1,0.3,0.1v0.2l-0.3-0.2l-0.1,0.2l-0.2,0.1l-0.2-0.1C719,697.3,719,697,719,696.9
        z M719,696.8L719,696.8l0-0.5c0.1,0,0.7-0.3,0.8-0.3v0.5c0,0-0.2,0.1-0.2,0.1l0-0.2l-0.1,0v0.2l-0.1,0.1l0,0v-0.2l-0.1,0.1
        c0,0,0,0.2,0,0.3L719,696.8L719,696.8z M718.3,696.3L718.3,696.3l-0.1-0.1l0.5-0.1C718.6,696.1,718.5,696.3,718.3,696.3
        L718.3,696.3z M719.8,362.8c0.1,0.5-0.4,0.8-0.8,0.5C718.8,362.8,719.5,362.3,719.8,362.8z M718.1,696.5l-0.1-0.1l0-0.4
        c0.2,0,0.6-0.2,0.8-0.3l0,0c0,0.4-0.1,0.8-0.5,0.8L718.1,696.5L718.1,696.5z M719,362c0.1,0,0.7-0.2,0.8-0.3l0,0v0.2l-0.3,0.1
        l0.3,0.1v0.2l-0.3-0.2l-0.1,0.2l-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2V362z M718.5,696.7L718.5,696.7l0,0.3l0.1-0.3l0.1,0l0,0h0
        l-0.2,0.6l-0.2,0.1l0,0v-0.5L718.5,696.7L718.5,696.7z M719.3,363.2l-0.2,0c-0.1-0.3,0.5-0.4,0.5-0.1
        C719.6,363.1,719.4,363.1,719.3,363.2L719.3,363.2z M719.2,362.2l0.2-0.1l0,0.2l-0.1,0C719.2,362.3,719.2,362.2,719.2,362.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M614.7,788.5l-0.5,10.6 M622.6,800.2c1.9,0.5,4.1,0.8,5.7,0.6
         M628.3,800.7c3.7-0.2,3.3-3.6,2.2-6 M645.5,801.7c5.9-2.1-0.4-10.2-4.7-10.2c-6.2-1.3-11.6,8.2-3.5,9.8 M637.3,801.4l-9-0.6
         M669.3,303.7v-2.5 M622.6,800.2c-2.9-0.3-5.7-0.7-8.4-1.1 M645.5,294.6l-0.3,11.3 M637.3,801.4c2.4,0.8,6,1,8.2,0.3 M617.6,793.3
        c3.9,2.1,8.1,2.6,12.9,1.4 M617.6,793.3c-2.7,3.5,1.6,6,5,6.8 M614.2,799.1l0,0.9 M630.5,794.8c-2.2-4.9-9.9-6.2-12.9-1.4
         M649.2,783.1c-4.3,0.3-25-1.6-28.5,0.5 M620.8,783.6c-3.1,1.6-5,3.1-5.8,4.4c10.5,1.1,21.9,1.8,34.3,2.1 M570.3,479.7l1.4,12.7
         M659.8,295.2c0.5,3.5-0.1,5.6-1.8,6.3 M669.2,295.4l0.1-7.5 M664,301.2h5.4 M669.3,301.2c-0.1-0.3,0.2-5.7-0.2-5.7 M666.5,292.1
        v-2 M659.8,294.4c0.5,0,0.7,0.2,0.7,0.7 M662.5,294.7l1-0.3 M662.5,294.7l-2,0.4 M660.5,295.1l-0.7,0.1 M659.8,294.4
        c2.6-0.3,4.8-1,6.7-2.2 M663.7,301.5c0.1-0.2,0.1-0.3,0.2-0.4 M663.7,301.5l-1,0c-0.6,0.4-1.1,1.1-1.3,2 M660.5,295.1
        c-0.2,1.8,0.7,6.1-1.2,7.2c3.9-0.2,3.1-3.9,3.1-7.6 M669.2,295.4c-0.8-1.2-6.4,0.6-5.2,1.4v4.4 M663.4,294.4
        c7.5-2.2,6.5-7.6-1.3-6.9 M650.9,292.4l-0.1,9.6 M648.3,291.5l4.1-0.4 M649.4,302.9l4.6,0 M650.9,302l6,0 M654,302.9
        c1.1-0.3,2-0.6,2.9-0.9 M656.9,302c0.1,0,1.1-0.5,1.1-0.5 M650.9,302L650.9,302 M658,301.5c-1.8-1.2-5.7-1.4-7.1,0.5 M653.3,291.7
        v1.7 M652.4,291.1c-0.3,0.4-0.5,0.8-0.6,1.2 M653.3,291.7c2,3.2,11.7,1.7,13.2-1.6 M653.3,293.4c1.8,1.2,4.1,1.2,6.6,1
         M653.3,293.4c-4.1-5.9,20.3-8.2,13.2-1.3 M666.5,290.1c-1.8-3.4-11.9-1.8-13.2,1.6 M662.1,287.5c-3.5,0.2-7.5,1.2-9.7,3.7
         M651.8,292.3c-0.1,3.2,5.1,3.2,8,2.9 M651.8,292.3l-0.9,0.1 M645.5,293.7l2.8-2.2 M644.5,292.5l3.7-2.3l3-0.6
        c2-1.5,4.6-2.6,8-3.1 M648.3,291.5l-2.8,1.4l0,0.8 M645.5,293.7l0,0.8 M650.9,292.4l-2.4,0.3l-3,1.8 M662.1,287.5
        c-6.3-0.6-8.9,2.4-13.8,4 M647.3,304.2v-1.4l2.1,0 M650.8,302l-1.4,0v0.9 M659.4,306.8c-3.4,3.7-8.2,4.5-14.3,2.7 M669.3,303.7
        l-5.7,0.2l0.1-2.3 M662.6,309.6l6.7-0.7 M669.3,308.9v-5.2 M661.6,309.7l1-0.1 M661.3,309.8l0.3,0 M659.4,309.9l1.9-0.2
         M659.4,309.9v-3.1 M659.4,306.8v-2.2c1.1-0.2,1.8-0.5,2-1.1 M661.4,303.5c-0.3,1.3-0.2,4.9-0.1,6.3 M662.6,309.6l0.1-7.3
        c-1.2,1-1.1,5.5-1.1,7.5 M647.9,311.5c1.2-0.6,10-1.3,11.5-1.5 M647.3,304.2l-2.1,1.6 M645.2,307.8l0,1.7 M645.2,305.8l-0.1,2
         M645.2,307.8c0,0,2.8-2.1,2.8-2c9.2-1.8,16-0.3,15.5-11.3 M647.9,311.5v3.7c0,0,20.3-1.5,20.3-1.5c1.2,0,0.9-4,1.2-4.8
         M645.1,309.5l-0.1,3.7c0.9-0.7,1.9-1.3,2.9-1.7 M616,421.7l2.3,10.3c0.8,1.5,0.5,3.1-0.7,5 M634.4,794.4
        c3.4,2.1,7.5,2.6,12.3,1.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M649.2,796.6v5.2 M649.2,801.7l-3.7-0.1 M653.3,790.2l1.7,0
         M653.3,790.2v2.8h1.7v-2.8 M649.2,790.1l4.1,0.1 M649.2,790.1v6.5 M661.5,790.3v1.5c-2.1,3.6,3.1,4.7,1.8,0.4v-1.9 M661.5,790.3
        l1.8,0 M663.3,790.3h2.6 M666,797l-0.1-6.7 M666.1,803.7v-1.3 M666.1,802.3L666,797 M654.9,790.2l6.5,0.1 M649.2,796.6L666,797
         M649.2,801.7c5.7,0.3,11.3,0.5,16.8,0.6 M649.2,801.7v1.3 M665.9,790.3l-0.1-6.8l-16.6-0.4 M649.2,783.1v7 M654,302.9
        c-2,0.4-4.1,0.8-6.2,0.9l-0.5,0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M671,807.8l-0.7-26 M717.6,747.5c-2.4,0-4.8-0.1-7.1-0.4 M712.6,802
        c3.7,0.5,5.9-0.7,6.6-3.5l11.6-58.5 M712.6,802c2.1-17.9,3.8-36.6,5-54.6 M730.8,740c49.5-13.2,43.6-86.1-7.5-90.8 M718.4,741.4
        l-0.8,6.1 M718.4,741.4c4.3,0,8.5-0.4,12.5-1.4 M722.7,727.3c1.6-0.2,3.2-0.4,4.7-0.8 M718.7,727.5c1.4,0,2.7,0,4-0.2
         M710.5,747.1c-1.2,24.8-3.1,47.6-6,74.7 M700.9,757.6c-2.5-0.3-4.9-0.8-7.3-1.4 M701.5,750.8l-0.6,6.7 M700.9,757.6
        c1.6,0.2,3.2,0.3,4.9,0.4l0.3-6.2 M693.6,756.2c-3.1,21.6,1.4,25.2-23.3,25.6 M710.5,747.1c-1.4-0.2-2.8-0.4-4.2-0.7 M706.1,751.8
        l-4.6-0.9 M701.9,745.4l-0.5,5.5 M706.3,746.4c-1.5-0.3-2.9-0.6-4.3-1 M706.3,746.4l-0.2,5.4 M723.3,649.1
        c-63.4-3.8-68.4,89.4-4.9,92.3 M664.4,687.7c-4.4,25.4,13.2,50.9,37.5,57.7 M650,686.9c-4,30.1,14.1,61.3,43.6,69.3 M701.5,750.8
        c-27.7-6.3-46.5-35.3-41.8-63.4 M670.3,781.8l-3.8,0.3 M666.6,782.1c1.5-0.9,0.6,21.3,0.9,21.6 M700.9,757.6
        c-1.3,16.1-6.9,52.1-6.4,64.5 M704.5,821.7l6.3-6.8 M671,807.8l16.1,0.9 M694.5,822.1l10-0.3 M696.2,819.4
        c0.3,0.2,3.6-0.4,3.4,0.4c-0.1,0.3,0.3,1.5-0.4,1.5c-0.3-0.1-3.5,0.4-3.4-0.3C695.9,820.6,695.5,819.3,696.2,819.4z M690.6,822.2
        l3.9-0.1 M687.1,808.7c0.8,3.2-0.4,13.5,3.5,13.5 M690.6,822.2c-1-0.3-1.6-2.1-1.7-5.4c0.4-16.1-0.6-24.2-2.9-24.3l1,16.3
         M710.8,815l0.2-0.2l0.4-3.4 M711.4,811.4c-0.1-4,0.3-7.2,1.1-9.4 M710.8,815h2l-1.3-3.6 M667.5,803.7l0,3.2 M667.6,804.1l1.7,1.9
         M669.3,805.9l1.7,1.9 M667.5,806.9l1.9-1 M667.5,803.7c-11.2-0.1-22.5-0.7-33.8-1.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M637.2,781.4l29.4,0.7 M633.7,804.4c10.6,1.1,21.8,1.9,33.8,2.4
         M554.2,562.5l-0.2,4 M565.9,565l-0.1,8.8 M565.8,573.8l-4.7,0.6 M566.9,498.1c-11.7,9-9.6,53.6-11.7,69 M555.2,567.1l-1-4.6
         M554.1,566.1l-0.1,0.4 M567,514.2c0,3.1-1.1,36.5-1.1,50.8 M549.3,593.6c-3.6,18.4-1.5,36.9-2.3,57.3
        c0.1,22.7-0.5,86.7-0.7,110.4 M554,566.5c-1.7,11.4-5.7,16.9-4.6,27.1 M555.2,567.1c-0.2,6.9-1.4,14.7-4.1,21.5 M549.3,593.6
        l1.8-5 M561.2,574.4c-0.3,20.4-0.6,72.2-1,93.5c0,5.5,2.5,8.6,7.5,9.3c19.8,3.4,42.2,6.4,67.1,8.8 M565.8,573.8l-0.1,8.3
         M566.4,484.6c-14.4,14.2-9.5,59.8-12.2,77.9 M567,509.6l-0.8,0.9v0c-2,2.4-3.2,6.7-3.6,12.9c-0.7,16.2-1.2,33.2-1.5,51
         M556.1,682.7c0,0,0.9-98.8,0.9-98.8c2.3-25.5-3.3-66.7,10.1-81.3 M567,502.6l0,7 M567,509.6l0,4.6 M566.9,498.1l1.5-1.9
         M568.5,496.2c9.8-11.8,50.3-60.4,59.5-71.5 M567,502.6l-0.1-4.6 M641.3,532.2c0.3-0.6,0.2-5.9,0.2-6.7c0.1-1.3-2.9-0.5-3.6-0.7
        c-1.5-0.4-0.4,7.2-0.7,7.8 M637.2,532.7c-4.6,2.1-4.6,9.1,0,11.1 M637.2,543.8v-11.1 M639.4,552.5c1.6,0,1.6-2.5,0-2.5
        C637.7,550,637.8,552.6,639.4,552.5L639.4,552.5z M637.2,548.3c0,0,0,6.2,0,6.2c-0.2,1.3,3,0.5,3.7,0.7c1.4,0.3,0.4-6.3,0.7-6.9
         M627.2,564c21.9-2.3,45.3-2.8,69.9-2.6 M625.1,565.1l-2.2,0.7 M627.2,564l-1.5,0.9 M625.6,564.8l-0.6,0.2 M625.1,565.1
        L625.1,565.1 M625.6,564.8l-0.6,0.3 M622.9,565.8l0.3-11.5 M622.5,576.6l0.3-10.8 M623.2,554.2L565.9,565 M637.2,543.8v4.5
         M622.9,565.8l-57,8 M622.5,576.6l-56.7,5.6 M556.1,682.7l-6.9-1 M549.2,681.8c0.3,7.7-0.6,70.2-0.7,79.5 M565.4,642.8l0,1.6
         M625.1,565.1l-2.6,86.1c-0.4,7.2-3.3,10.9-8.8,11.3c-8.3-1-48.9,0-48.4-5.6 M565.3,656.9c0.3,1.1,1.1,1.5,2,1.5
        c17.9,1.8,33.1,2.6,47.1,1.9c7.7-0.5,5.7-14.9,6.3-20.7 M565.3,656.6v0.2 M565.4,644.4l-0.1,12.3 M620.7,639.6l0.1-2 M638.6,650.8
        c0.3-0.6,0.2-5.9,0.2-6.7c0.1-1.3-2.9-0.5-3.6-0.7c-1.5-0.4-0.4,7.2-0.7,7.8 M634.6,651.2c-4.6,2.1-4.6,9.1,0,11.1 M638.9,666.8
        c0.1-1,0.1-3.1-0.5-4 M638.6,650.8c-0.5,0.8-0.4,11-0.2,12.1 M634.6,662.4v-11.1 M565.3,656.6c-3.6,7.1,47.5,7.7,51.2,8.8
        c5.2-0.1,8.1-3.9,8.4-11.4l2.3-90.1 M634.6,666.8c0,0,0,6.2,0,6.2c-0.2,1.3,3,0.5,3.7,0.7c1.4,0.3,0.4-6.3,0.7-6.9 M638.7,686.3
        l0.2-11h-3.9l-0.2,10.7 M634.6,666.8h4.4 M634.7,686l3.9,0.2 M634.6,662.4v4.5 M620.7,639.6l-55.3,4.8 M565.4,642.8l55.4-5.2
         M551.1,588.6c-1.7,20-2.3,51.1-1.9,93.2 M565.8,582.1l-0.4,60.6 M620.8,637.6l1.8-61 M644.5,290.9v-3.7
        c-18.1,5.3-17.6,72.2-20.9,100.2l-0.7,10.2l1.5,10.3 M567.9,482.8l-0.1-1.2l-1.5,2.2l0.1,0.8 M624.3,407.8
        c0.1,0.9,0.3,1.8,0.7,2.6 M625,410.4c0.4,0.8,0.8,1.7,1.4,2.5 M625,410.4c-7.8,9.8-48.7,61.7-57.2,72.4 M567,514.2
        c4.7-4,30.2-38.1,36.7-38.2 M603.7,476L603.7,476 M603.7,475.7v0.3 M603.7,475.7l0,0.3 M631.1,439.4c2.9-0.8,6.1-1.4,9.5-2
         M643.9,315.5c-4.2,0.9-6.6,4.4-7.3,10.5c-3.1,33.9-4.7,74.4-5.6,113.3 M628,424.6l0.7,7.1 M628.8,440l-0.2,0.1l-2.2,2.5
         M628.8,440l2.2-0.6 M640.6,437.4l-0.2,7 M628.6,431.8c0.1,2.6,0.2,5.3,0.2,8.2 M603.7,476c0,2.5-0.1,5.7,3.2,5.9
        c5.5-0.2,16.1,2.4,18.4-2.1 M603.7,475.9c-0.1,6.6,9.9,3.8,13.8,4.4 M608.6,462.6c-1.3,0.8-2.4,2-3.2,3.6 M605.5,466.2
        c-1.3,2.9-1.7,6-1.8,9.5 M611.5,461.4c-0.8,0.3-2.1,0.7-2.8,1.2 M611.5,461.4c4.5-7.1,7.9-5.7,12.9-1.2c1.4,1.1,0.7,6.5,0.7,8.3
         M625.1,468.5c0,0-0.6-5.2-0.6-5.2c0.4-1.2-6.2-6.8-7-4 M617.6,459.3l-6.1,2.1 M625.3,479.8l0.1-1.5 M625.4,478.3l0-0.7
         M625.4,477.7v0.6 M617.6,480.4c2.7-0.5,7.2,0.8,7.8-2.7 M625.4,477.6L625.4,477.6 M625.4,477.6l0,0.1 M625.4,477.1l0-1.5
         M625.4,476.7l0,0.5 M625.5,476.2l0,0.5 M625.5,476.2l-0.1-0.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M625.4,477.7l0-0.5 M625.4,477.1v0.4 M625.4,477.5l0-0.9 M625.4,475.7
        l-0.3-7.2 M617.6,459.3l0,21.1 M626.4,442.5l-17.8,20.1 M625.5,476.2l1-33.6 M640.4,444.4v10.7h3.4v-8.9 M626.4,412.8l1.6,11.8
         M624.3,407.8c2.7-43.9,6-75,10-93.4c-3.5,32-6.2,64.9-7.9,98.4 M643.8,446.2v-1.7h-3.4 M645.9,426.9l0-0.6c0,0,0.4-17.4,0.4-17.4
        c0.1-4.6,5-4.9,8.4-3.4 M652.4,408.1c-2.1-0.4-4.5,0.2-4.6,2.7c0,0-0.4,17.3-0.4,17.3c0.1,2,3.7,1.2,5,1.3 M652.4,431
        c-3.4,0.1-7.3,0.3-6.4-4.1 M655.3,431h-2.9 M652.4,429.5v1.4 M655.3,431v-1.4 M655.3,429.5c0,0,4.8,0,4.8,0
        c0.9-0.1,0.9-1.3,0.9-1.9c-0.5-6.5,2.6-15.4-5.7-18.5 M662.5,415.6c0,0,0,11.9,0,11.9c0.7,4.7-4.5,3.2-7.2,3.4 M643.8,446.2
        l0.3-9.2 M644,436.9c21.3-2.2,36.5-1.7,62.6,1.3 M706.6,427.9v10.3 M706.5,443.9c-5.2-0.2-7.8,1-7.8,3.6l-1.6,113.9 M707.5,448.9
        l-1-5 M706.6,438.2l-0.1,5.7 M706.6,427.9c-53.2-4.2-73.6-74.1-36.1-109.6 M655.3,407.6c1,0.5,2,1.1,3.1,1.8l0,0
        c2.7,1.7,4,3.8,4.1,6.3 M652.4,406.7c-3-0.5-6,0.7-6,4.1l-0.4,16 M652.4,406.7v1.5 M654.8,405.4h0.5v0.2 M655.3,409.2v-1.6
         M655.3,405.6v2 M655.3,405.6c1,0.4,2,1,3.1,1.8l0,0c3.5,2.1,4.3,4.9,4.1,8.3 M654.8,405.4h-2.4v1.3 M705.8,306.4
        c-60.2,12.7-61.7,99.3-2.5,114.9 M695.2,381.6l-1.7,1.5 M687.1,368.3l2.2-0.2 M687.1,368.3l-0.4-3.9 M686.7,364.4l2.2-0.1
         M689,364.3l3.8-0.2 M693.1,367.8l-3.8,0.3 M700.4,377l-2.3,2.1 M704.8,366.8l-8.6,0.8 M709.9,364.6c2,2.1,5.3-1.1,3.2-3.2
        C711.1,359.3,707.9,362.6,709.9,364.6L709.9,364.6z M712.3,371.1l-1.8-1.6c-0.7,0.3-3.3,1-3.7,1.8 M708.7,362.8l-0.1,2.4
        c-0.7,0.3-3,1.7-3.8,1.5 M707.9,374.5l0.9-0.9 M708.8,373.6l0.8,0.7 M709.6,374.3l0.7,0.6l0,0 M712.3,377.5l0.6-1 M712.9,376.5
        c0.7-0.5,1-3.1,1.2-3.9l-1.8-1.6 M708.8,373.6l1.4,1.3 M710.2,374.9l-0.7,1 M709.5,375.9l-1.6-1.4 M696.2,367.5
        c0.6,3.5,2,6.6,4.2,9.5 M706.8,371.2c-0.9-1.4-1.6-2.9-2-4.5 M712.3,371.1l-2.7,3.2 M706.8,371.2l-0.9,0.8 M705.9,372
        c-1.7-0.7-3.1-0.9-4.1-0.7l-1.4,5.7 M700.4,377l5.6-5 M701.1,387.4l-1.3,1.8 M703.1,391.4l1.2-1.9 M703.1,391.4
        c-1.1-0.7-2.2-1.4-3.3-2.2 M696.2,386.1l1.6-1.6 M699.8,389.2c-1.3-0.9-2.5-2-3.6-3.2 M697.7,384.5l2.7-2.7 M700.5,381.8
        c0.9,0.9,1.9,1.8,2.9,2.5 M712.7,382.4l-0.9,4.3l3.2,0.8c0.6-1.7,1.4-5.4,1.1-7.3l-1.5-0.4L712.7,382.4L712.7,382.4z M697.7,384.5
        c1.1,1.1,2.2,2.1,3.4,2.9 M703.4,384.3l-2.2,3.1 M704.3,389.6l2.1-3.2 M689.3,368.1c0.8,5,2.7,9.5,5.9,13.5 M693.5,383.1
        c-3.5-4.4-5.6-9.3-6.4-14.8 M696.2,367.5l-3.1,0.3 M698,379.1c-2.6-3.4-4.2-7.1-4.9-11.3 M696.2,386.1c-1-1-1.8-1.9-2.6-3
         M700.5,381.8l7.4-7.3 M709.5,375.9l-6.2,8.5 M706.4,386.4l5.9-8.9 M698,379.1l-2.8,2.6 M705.4,311.5c-54,14-52.7,90,1.2,103.2
         M722.7,317.1c-67.9-2.2-64.9,98.2,2.8,91.9 M714.7,417.1l2.7,0.7 M717.4,417.8l2.8,0.7 M722.8,419.2l2.7,0.7 M720.2,418.6
        l2.6,0.7 M718.7,395.6c1.4,0,2.7,0,4-0.2 M722.7,395.4c1.6-0.2,3.2-0.4,4.7-0.8 M725.5,419.9V409 M718.4,389.5l0.2,3.8 M722,389.3
        l0.4,3.8 M723.2,379.2c-0.9,0.4-1.7,1.2-2.1,2.1 M721.7,385.8l0.4,0.4 M722,386.2c0.9,1,2.2,1.4,3.5,1.3 M721.7,386.2l0.3,0
         M721.7,385.8l0.1,0.5 M721.2,381.3l0.5,4.4 M728.6,385.5c1.4-2.2,0.4-5.5-2.1-6.4 M729.3,387.5c0,0-0.6-2-0.7-2 M725.5,387.5
        l0.3,1.2 M729.3,387.5L729.3,387.5 M726.8,392.4l-1-3.7 M729.3,387.5l1.2,3.7 M730.4,391.2l0.7,2.1 M721.7,386.2l0.3,3.1
         M725.8,388.7c-1.2,0.3-2.5,0.5-3.8,0.7 M730.1,383.6l-0.8,3.9 M725.5,387.5l-2.3-8.3 M722.4,393.1c1.5-0.1,2.9-0.4,4.4-0.8
         M717.7,377.8l0.1,1.1 M721,373.3l-2.4,0.4c-0.2,0.8-1.2,3.2-0.9,4 M715.9,372.4c2.8,0.9,4.2-3.4,1.4-4.3
        C714.5,367.2,713.1,371.5,715.9,372.4L715.9,372.4z M725.7,376.3c0-0.9-1.8-2.8-2.3-3.4l-2.4,0.5 M720.8,377.7l0.1,1.2
         M721.9,377.5l-1,0.2v0 M721,378.9l2.1-0.2 M723.1,378.6l-0.4-1.3 M722.7,377.3L722.7,377.3l-0.9,0.1 M720.8,377.7l1.9-0.4
         M721,373.3l0.9,4.1 M716,370.1l0,0.4C717.6,371.9,717.2,368.5,716,370.1L716,370.1z M715.2,369.9l0.3,1.5l1.4,0.4l1.1-1l-0.3-1.4
        l-1.4-0.5L715.2,369.9L715.2,369.9z M716.2,366.1c3.6,3.8,9.6-2.1,5.7-5.8C718.3,356.5,712.4,362.4,716.2,366.1L716.2,366.1z
         M722.8,368.1c0.4,2.9,4.9,2.1,4.4-0.7C726.9,364.5,722.2,365.3,722.8,368.1L722.8,368.1z M724.2,369l1.5,0.1l0.9-1.2l-0.6-1.4
        l-1.5-0.1l-0.9,1.2L724.2,369L724.2,369z M724.5,367.9c0.2,0.7,1.2,0.5,1.1-0.2C725.5,366.9,724.4,367.1,724.5,367.9L724.5,367.9z
         M728.2,367.9l-1.1,2.1c0.4,0.7,1.7,3,2.5,3.2 M733.4,366.1c-0.6-0.5-3.3-0.3-4-0.3c-0.4,0.7-0.7,1.4-1.1,2.1 M732,369.9l-0.5,0.9
        l0,0 M732.4,369.1L732.4,369.1l-0.5,0.8 M731.5,370.8c0.3-0.5,0.6-1.1,0.9-1.7 M728.2,367.9l3.8,1.9 M729.6,373.3
        c-1.2,1.2-2.5,2.3-3.9,3 M726.5,379.1l-0.7-2.3 M725.8,376.7l5.4,1.5l-1.1,5.4 M725.7,376.3l0.1,0.5 M738.8,375.5l2.6,1.7
         M740.3,372.5c-0.5,1-1,2-1.6,2.9 M741.5,369.1l-8.1-3 M748.1,371.6l2.1,0.8 M748.8,376.2c0.5-1.2,1-2.5,1.4-3.8 M748.8,376.2
        l-2.1-0.9 M746.7,375.3l-3.5-1.5 M744.4,370.2l3.6,1.3 M741.5,369.1l2.9,1.1 M741.4,377.2c0.7-1.1,1.3-2.2,1.8-3.4 M743.2,373.8
        l-2.9-1.2 M731.1,393.3c4.9-1.9,9.6-5.5,13.1-9.8 M744.2,383.6c0.8-1,1.6-2,2.3-3.1 M744.6,379.2l1.9,1.2 M729.3,387.5
        c3.7-1.5,7.2-4.2,10-7.4 M739.2,380.1l3.2,2.2 M742.4,382.3c-3.2,3.8-7.5,7.2-11.9,8.9 M744.2,383.6l-1.8-1.3 M746.5,380.5
        c0.9-1.4,1.6-2.8,2.3-4.2 M721,378.9l0.2,2.4 M723.1,378.6l0.2,0.6 M731.5,370.8l7.2,4.7 M741.4,377.2l3.2,2.1 M740.3,372.5
        l-7.9-3.4 M718.4,389.5l-0.6-10.6 M744.6,379.2c0.8-1.3,1.5-2.6,2.1-3.9 M739.2,380.1l-2.6-1.8 M736.7,378.3l-7.1-5 M726.5,379.1
        l2.1,6.4 M730.1,383.6c2.4-1.3,4.7-3.3,6.6-5.4 M725.5,409c54.2-8.3,51.9-86.9-2.8-91.9 M718.6,393.3l0.1,2.2 M727.4,394.5
        c1.3-0.3,2.5-0.7,3.7-1.2 M722.4,393.1l0.3,2.2 M727.4,394.5l-0.6-2.2 M716.8,438.8l3.4,0.2 M720.2,439l2.6,0.1 M725.4,439.3
        l-2.6-0.1 M725.4,439.3l0.1-19.4 M655.3,429.5v-20.4 M706.6,438.2l10.2,0.6 M706.5,443.9c6.3-0.1,9.5,2.1,9.8,6.8
        c0.1,26-0.2,84.2-0.5,110.7 M713.5,561.4l1-108.4c-0.4-3.1-2.8-4.5-7-4.1 M717.7,377.8c-1.7-0.1-3.3-0.6-4.8-1.2 M717.7,378.9
        c-2-0.2-3.8-0.7-5.4-1.4 M704.3,389.6c4.3,2.4,9,3.7,14.2,3.7 M718.7,395.6c-5.7-0.1-10.9-1.4-15.6-4.1 M706.4,386.4
        c3.6,2,7.6,3,11.9,3.1 M706.6,414.7v13.2 M706.6,427.9l8.1,1.6v-12.4 M717.4,417.8l-0.6,21 M720.2,418.6V439 M722.8,439.1v-19.9
         M706.6,414.7c2.6,0.8,5.3,1.6,8.1,2.4 M652.4,429.5v-21.4 M643.9,315.5l-0.1,1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M647.2,316.5l-3.3,0.3 M670.7,314.6l-0.3,3.7 M671.2,309.5l-0.5,5.1
         M647.2,316.5l23.6-1.9 M705.8,306.4l-0.4,5.1 M711.4,310c-2,0.4-4,0.9-5.9,1.5 M721.5,306c-0.6,0.2-9.7,4.3-10.1,4.1
         M687.5,355.7c-0.3,1.3-0.5,2.6-0.6,3.9 M693.5,356.7l-3.8-0.7 M686.9,359.6l2.2,0.3 M692.9,360.3l3.1,0.4 M689.1,359.8l3.8,0.5
         M696,360.7l-0.1,3.3 M689.7,356l-2.2-0.4 M699.3,345.8l2.2,2.2 M696.6,343.1l2.6,2.8 M703.9,345.6l-2.1-2.3 M699.4,340.4
        l-1.5-1.7 M701.8,335.8l1.2,1.9 M702.9,337.7l2,3.3 M699.4,340.4c1.1-1,2.3-1.9,3.6-2.7 M705.2,333.9c-1.2,0.6-2.3,1.2-3.4,1.9
         M701.8,335.8c-1.3,0.9-2.6,1.8-3.9,2.9 M708.2,339.2l-1.9-3.4 M701.9,343.2l-2.5-2.9 M706.6,343.6c-0.9,0.6-1.8,1.3-2.6,2
         M704.9,341l1.6,2.6 M701.9,343.2c1-0.9,2-1.6,3.1-2.3 M706.3,335.8l-1.1-1.9 M709.7,341.9l-1.5-2.7 M695.8,364l8.6-0.4l0-0.7
         M704.4,362.9v-0.2 M704.4,362.5v0.2 M704.4,362.9v-0.4 M696,360.7l8.5,1l0,0.8 M705.1,358.6l-8.5-1.5 M712.8,354.9l-2.6-3.4
         M707.4,354.3c0.3,0.7,2.8,1.7,3.5,2l1.9-1.4 M710.3,351.5l-0.7,0.5l-5.6-6.4 M712.8,354.9l1.9-1.4c-0.1-0.8-0.2-3.4-0.9-4
         M706.6,343.6l4.5,7.3l-0.8,0.6 M708.7,362.8l0.1-2.4c-0.7-0.4-2.8-1.9-3.7-1.8 M711.5,362.4c-0.5,0-0.7,0.6-0.4,1l0.4,0.2
        l0.4-0.2C712.2,363,712.1,362.5,711.5,362.4L711.5,362.4z M710.2,362.4v1.5l1.3,0.8l1.3-0.8v-1.5l-1.3-0.8L710.2,362.4
        L710.2,362.4z M705.1,358.6c0.5-1.5,1.3-3,2.3-4.3 M704.4,362.7l4.2,0.1 M695.1,341.5c-3.9,4.2-6.4,8.9-7.6,14.2 M695.1,341.5
        l1.5,1.6 M696.6,343.1c-3.5,3.8-5.8,8.1-6.9,13 M699.3,345.8c-2.9,3.2-4.8,6.8-5.8,10.8 M701.4,348.1c-2.4,2.7-4,5.8-4.9,9.1
         M696.6,357.2l-3.1-0.5 M701.4,348.1l6,6.2 M713.9,349.5l-4.2-7.6 M697.9,338.7l-2.8,2.8 M670.5,318.3
        c10.3-10.6,22.8-14.4,36.6-17.1 M651.8,287.7c-2.2,0.4-4.4,0.9-6,2.3 M644.5,290.9c0.1,6.5-0.2,18.1-0.6,24.6 M645.8,290l-1.3,0.9
         M651.8,287.7l0.1-2.3 M653.1,285.9l0-0.4h-1.2 M651.9,285.4c-2.3-0.1-5.2,0.9-6.2,1.9v2.7 M653.1,287.4l-1.3,0.2 M653.1,287.4
        l0.1-1.6 M655.7,284.9c-0.4,0.2-0.4,0.6-0.4,1 M655.3,285.9v1.2 M663.4,285.6l0.1-1.8c-1.2,0.2-7,0.3-7.9,1 M655.3,285.9h-2.1
         M655.3,287c-0.7,0.1-1.5,0.2-2.2,0.4 M663.4,286.3l0-0.7 M663.4,286.3h-1.5 M655.3,287c2-0.3,4.4-0.8,6.5-0.8 M667.9,285.9v0.4
        h-4.6 M674.4,283.8h-6.4v2.1 M667.9,285.9l-4.5-0.3 M677.6,275.7c-1.7,2.1-0.3,6.9-3.2,8.1 M672.2,287.8l-0.3,0.1
        c0.1,8-0.1,15.3-0.6,21.7 M661.8,286.3c3.2,0.1,5.7,0.6,7.4,1.7l2.9-0.2 M676.9,285.4v-1.8h-1.6l-0.4,1.7 M674.8,285.3
        c0,1.1,0.4,1.7,1.1,1.7l1-1.6 M676.9,285.4l-2.1-0.1 M672.2,287.8c1.6-0.4,2.4-1.7,2.2-4 M663.4,285.6c-4.5,0.2-7.1-0.1-7.7-0.7
         M680.6,283.7c0.9,5.3,13.2,5.3,14.1,0C693.8,278.4,681.5,278.4,680.6,283.7L680.6,283.7z M681.3,283.2c1,4.5,11.9,4.5,12.9,0
        C693.1,278.7,682.2,278.7,681.3,283.2L681.3,283.2z M694.9,277.6c-2.8-0.1-5.3-0.3-7.4-0.8v-2.3c-2,0.3-8.6-0.8-9.9,1.2
         M711.1,281.8l0.1-3.6l-5.2-4.2c0,0-7.8,0-7.8,0c-2.5,0-2.8,1.9-3.4,3.6 M711.1,281.8c-1.8-1.5-3.5-3-5.2-4.3 M718.1,297.1
        c1.3-0.5,0.7-5.6,0.9-6.8l-7.1-6.4v2.9 M711.9,286.7v1.6 M707,301.2h5.2l-0.9,8.9 M711.9,288.3c0.1,1.9-0.5,4.2,1.4,5.6
        c0.5,0.2,4.1,3.7,4.8,3.1 M705.9,277.6l1.1,23.6 M705.9,277.6l-11,0.1 M671.2,309.5c5.4-2.6,6.8-25.2,6.4-33.8 M718.1,292.9v-1.3
        l-6.2-4.9 M722.5,304.2c2-0.3,6.6,0.4,7.1-2.3c0.7-5.7,3.6-9.5-5.3-8.6 M718.1,297.1l0-4.1 M724.1,296.6c-1.4-3.7-3.5-6.1-6.1-8.5
         M724.3,293.3l-0.3,3.3 M724.3,293.3c0.3-1.9-5.1-11.7-6.4-11.7v6.5 M566.7,789.5c12.5,3.4,25.6,6.4,39.3,9 M612.7,801.3
        c-17.7-3.1-35.6-7-53.5-11.5 M565.7,790.7c11.1,2.9,24.5,5.9,40.3,8.8 M717.6,358.1c2.8-0.9,1.4-5.2-1.4-4.3
        C713.4,354.7,714.9,359.1,717.6,358.1L717.6,358.1z M716.6,356.5c0.1,0.1,0.3,0.1,0.4,0.1l0.3-0.3c0.3-1-1.2-1.1-1-0.1
        L716.6,356.5L716.6,356.5z M716.5,357.5l1.4-0.5l0.3-1.5l-1.1-1l-1.4,0.5l-0.3,1.5L716.5,357.5L716.5,357.5z M624.2,803.4
        l-0.5-2.3 M623.6,801l-9.5-1.1 M723,358.6c-0.5,2.9,4,3.6,4.5,0.7C728,356.4,723.4,355.7,723,358.6L723,358.6z M722.7,349l1,0.3
         M718.7,348.6c-0.4,0.7,0.4,3.2,0.6,4l2.3,0.6 M721.6,353.2l1.1-4.1 M730.2,353.8c-0.8,0.2-2.3,2.3-2.7,2.9l1,2.2 M726.5,350.6
        c1.4,0.8,2.7,2,3.7,3.2 M721.6,353.2l2.3,0.6c0.5-0.6,2.5-2.3,2.6-3.1 M732,356.4L732,356.4l0.3,0.8 M724.7,358.9
        c-0.1,0.5,0.5,0.8,0.9,0.5C726.4,358.7,724.9,357.8,724.7,358.9L724.7,358.9z M723.8,359l0.9,1.2l1.5-0.1l0.6-1.4l-0.9-1.2
        l-1.5,0.1L723.8,359L723.8,359z M732.3,357.2l-3.9,1.8 M732.3,357.2l0.4,0.9h0 M728.5,359l1,2.2c0.8,0.1,3.4,0.5,4.1,0
         M732.8,358.1l-0.8-1.8 M720.3,336.9c-4.2-0.2-8.5,0.6-12.1,2.2 M720.3,336.9l-0.4,3.1 M719.8,340c-3.6-0.1-7.1,0.5-10.1,1.8
         M726.6,341.2l1.1-2.9 M727.6,338.3l1.3-3.6 M731,339.7l1.5-3.5 M727.6,338.3c-1.2-0.4-2.4-0.7-3.7-1 M723.3,340.4l-1.5,8.4
         M723.9,337.3l-0.6,3.1 M719.8,340l-1.1,8.5 M729.8,342.6l1.2-2.9 M723.3,340.4c1.1,0.2,2.2,0.5,3.2,0.8 M747.7,348
        c-0.6-1.2-1.3-2.3-2.1-3.4h0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M745.6,344.6c-3.1-4.3-7.6-8.3-12.2-10.6 M732.5,336.2
        c4.2,2.1,8.3,5.7,11.2,9.6 M633.7,802l-10-1 M740.4,347.8c-2.4-3.3-5.8-6.2-9.3-8.1 M745.6,344.6l-1.9,1.2 M733.5,361.2
        c0.2,1.2,0,3.7-0.1,4.9 M733.5,361.2l8.3-2.4 M740.9,355.4c-0.4-1.1-0.8-2.1-1.3-3 M745.7,349l-3.4,1.8 M750.2,356.4l0.5-0.1
         M750.7,356.3l-0.5,0.1 M750.7,356.3L750.7,356.3 M750.7,356.2c-0.3-1.3-0.6-2.6-1.1-3.8 M743.9,354.4l3.6-1.2 M747.5,353.1
        l2.1-0.7 M743.9,354.4c-0.4-1.2-1-2.4-1.6-3.5 M747.5,353.1c-0.5-1.4-1.1-2.8-1.8-4.1 M744.4,370.2c1-3.8,1.1-8.4,0.4-12.3
         M744.8,357.9l3.7-1.1 M748.5,356.9c1,4.7,0.8,10.2-0.5,14.7 M740.9,355.4l3-1 M742.3,350.9l-2.8,1.5 M741.8,358.8
        c0.4,3.2,0.6,7.3-0.3,10.3 M741.8,358.8l3-0.9 M748.5,356.9l1.7-0.5 M749.6,352.4c-0.5-1.5-1.2-3-1.9-4.4 M723.7,349.3l2.9-8.1
         M747.7,348l-2,1 M739.6,352.3l-7.6,4 M732.8,358.1l8.1-2.8 M732.5,336.2l0.9-2.1 M721.8,348.8l0.9,0.2 M740.4,347.8l-2.7,1.6
         M737.7,349.4c-2.1-2.7-4.9-5.2-7.8-6.7 M726.5,350.6l3.3-8 M737.7,349.4l-7.4,4.5 M723.7,349.3c-0.6-0.2-1.2-0.4-1.9-0.5
         M729.7,332.6c-1.5-0.5-3.1-0.9-4.7-1.2 M725,331.4c-1.3-0.2-2.6-0.4-3.9-0.4 M721.1,330.9c-5.6-0.3-11.2,0.7-15.9,2.9
         M706.3,335.8c4.3-2,9.4-2.9,14.5-2.7 M721.1,330.9l-0.3,2.2 M725,331.4l-0.4,2.2 M722.7,317.1l-0.6-11.4l-0.6,0.3 M721.5,306
        c0.4-0.4,0.7-1,1-1.8 M750.2,372.4c1.4-5,1.6-11,0.5-16.1 M733.4,334.1c-1.2-0.6-2.4-1.1-3.7-1.5 M720.8,333.1l-0.5,3.8
         M728.9,334.7l0.8-2.1 M723.9,337.3l0.7-3.8 M724.6,333.6c1.5,0.3,2.9,0.6,4.3,1.1 M717.9,288.1c-2.3-2.3-4.6-4.4-6.8-6.3
         M707,301.2l-1.2,5.3 M713.9,349.5c1.5-0.6,3.1-0.9,4.8-0.9 M617.7,802.5l6.5,0.9 M633.7,802v2.5 M724.1,296.6
        c-0.4,3.4-1,5.9-1.6,7.6 M718.1,292.9l-6.2-4.6 M625.3,808l8.4,1.1 M633.7,809.1v-4.7 M625.3,808l0.4,1.6l8,1.2v-1.7 M624.2,803.4
        l1.1,4.6 M612.7,800.8l0.9,0.2 M614.1,801.5l-1.4-0.2 M613.8,799.9l-1-0.2 M613.7,800.9l0.1-1 M614.2,800l-0.1,1.6 M692.8,364.1
        l3.1-0.1 M644,436.9l3.1-120.4 M686.9,359.6l-0.2,4.8 M689.1,359.8l-0.2,4.5 M692.8,364.1l0.1-3.8 M659.7,687.4l4.8,0.2
         M650,686.9l9.7,0.5 M689.7,688l-2.2-0.4 M693.5,688.6l-3.8-0.6 M687.5,687.6c-0.3,1.3-0.5,2.6-0.6,3.9 M689.1,691.8l3.8,0.5
         M686.9,691.5l2.2,0.3 M696,692.6l-0.1,3.3 M695.8,696l-3.1,0.1 M692.8,696.1l0.1-3.8 M692.9,692.3l3.1,0.4 M696.6,675l2.6,2.8
         M699.3,677.8l2.2,2.2 M703.9,677.5l-2.1-2.3 M699.4,672.3c1.1-1,2.3-1.9,3.6-2.7 M702.9,669.6l2,3.3 M701.8,667.7l1.2,1.9
         M699.4,672.3l-1.5-1.7 M701.8,667.7c-1.3,0.9-2.6,1.8-3.9,2.9 M708.2,671.1l-1.9-3.4 M701.9,675.2l-2.5-2.9 M706.6,675.5
        c-0.9,0.6-1.8,1.3-2.6,2 M704.9,672.9l1.6,2.6 M701.9,675.2c1-0.9,2-1.6,3.1-2.3 M709.7,673.9l-1.5-2.7 M704.4,694.4l0-0.7l-8.5-1
         M704.4,694.8v-0.4 M704.4,694.4v0.2 M704.4,694.7v0.2 M695.8,696l8.6-0.4l0-0.7 M705.1,690.6l-8.5-1.5 M710.3,683.5l-0.7,0.5
        l-5.6-6.4 M707.4,686.3c0.3,0.7,2.8,1.7,3.5,2l1.9-1.4 M712.8,686.8l-2.6-3.4 M712.8,686.8l1.9-1.4c-0.1-0.8-0.2-3.4-0.9-4
         M706.6,675.5l4.5,7.3l-0.8,0.6 M708.7,694.8l0.1-2.4c-0.7-0.4-2.8-1.9-3.7-1.8 M712,695.4c0.8-1.2-1.6-1.3-0.8,0l0.4,0.1
        L712,695.4L712,695.4z M711.5,692.7c-2.9-0.1-2.9,4.6,0,4.5C714.4,697.3,714.5,692.7,711.5,692.7L711.5,692.7z M711.5,693.5
        l-1.3,0.8v1.5l1.3,0.8l1.3-0.8v-1.5L711.5,693.5L711.5,693.5z M707.4,686.3c-1.1,1.3-1.8,2.8-2.3,4.3 M708.7,694.8l-4.2-0.1
         M689.7,688c1.1-4.8,3.4-9.2,6.9-13 M701.4,680l6,6.2 M713.9,681.4l-4.2-7.6 M695.1,673.4l1.5,1.6 M695.1,673.4
        c-3.9,4.2-6.4,8.9-7.6,14.2 M699.3,677.8c-2.9,3.2-4.8,6.8-5.8,10.8 M701.4,680c-2.4,2.7-4,5.8-4.9,9.1 M696.6,689.2l-3.1-0.5
         M697.9,670.6l-2.8,2.8 M695.2,713.6l-1.7,1.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M689,696.3l-2.2,0.1 M687.1,700.3l2.2-0.2 M689.3,700.1l3.8-0.3
         M686.7,696.4l0.4,3.9 M700.4,709L698,711 M696.2,699.5l8.6-0.8 M704.8,698.7c0.8,0.2,3.1-1.1,3.8-1.5l0.1-2.4 M712.3,703
        l-1.8-1.6c-0.7,0.3-3.3,1-3.7,1.8 M708.8,705.6l0.8,0.7 M707.9,706.5l0.9-0.9 M709.6,706.3l0.7,0.6l0,0 M712.9,708.5
        c0.7-0.5,1-3.1,1.2-3.9l-1.8-1.6 M712.3,709.4l0.6-1 M710.2,706.9l-1.4-1.3 M710.2,706.9l-0.7,1 M709.5,707.8l-1.6-1.3
         M706.8,703.2c-0.9-1.4-1.6-2.9-2-4.5 M709.6,706.3l2.7-3.3 M706.8,703.2l-0.9,0.8 M696.2,699.5c0.6,3.5,2,6.6,4.2,9.5 M700.4,709
        l1.4-5.7c1-0.2,2.4,0.1,4.1,0.7 M705.9,704l-5.6,5 M703.1,723.4l1.2-1.9 M701.1,719.4l-1.3,1.8 M699.8,721.2
        c1.1,0.8,2.2,1.5,3.3,2.2 M697.7,716.4l2.7-2.7 M696.2,718l1.6-1.6 M696.2,718c1.2,1.2,2.4,2.2,3.6,3.2 M700.5,713.8
        c0.9,0.9,1.9,1.8,2.9,2.5 M712.7,714.3l-0.9,4.3l3.2,0.8c0.6-1.7,1.4-5.4,1.1-7.3l-1.5-0.4L712.7,714.3L712.7,714.3z M697.7,716.4
        c1.1,1.1,2.2,2.1,3.4,2.9 M703.4,716.3l-2.2,3.1 M704.3,721.5l2.1-3.2 M689.3,700.1c0.8,5,2.7,9.5,5.9,13.5 M698,711l-2.8,2.6
         M687.1,700.3c0.8,5.5,3,10.4,6.4,14.8 M693.5,715.1c0.8,1,1.7,2,2.6,2.9 M693.1,699.8c0.7,4.2,2.3,7.9,4.9,11.3 M693.1,699.8
        l3.1-0.3 M700.5,713.8l7.4-7.3 M709.5,707.8l-6.2,8.5 M706.4,718.3l5.9-8.9 M692.8,696.1l-3.8,0.2 M689.1,691.8l-0.2,4.5
         M686.9,691.5l-0.2,4.8 M638.4,662.9c6.4-1,6.6-10.8,0.2-12.1 M704.9,628.1L704.9,628.1 M706.1,625.8c2.8-0.7,7-0.8,6.8-4.8
        l0.6-59.6 M697.1,561.4c0,0-0.9,63.1-0.9,63.1c-0.6,6.5,5,4.4,8.7,3.6 M704.9,628.1l0,3.3 M704.9,628.1c4.4-1.1,11.4-1.2,10.5-7.9
        l0.4-58.9 M715.9,629.3c-3.5,0.6-7.6,1.3-11.1,2 M706.1,625.8l-1.2,2.3 M704.9,631.4c-28,3.5-52.3,26.8-54.9,55.5 M659.7,687.4
        c2.7-22.9,22.6-43.1,45.1-47 M704.8,640.4l0,4.3 M704.8,644.6c-20,4.3-37.9,22.7-40.3,43 M704.9,631.4l-0.1,9 M706.3,667.8
        c4.3-2.1,9.4-3,14.5-2.7 M721.1,662.9l-0.3,2.2 M725,663.3l-0.4,2.2 M729.7,664.5c-1.5-0.5-3.1-0.9-4.7-1.2 M725,663.3
        c-1.3-0.2-2.6-0.4-3.9-0.4 M721.1,662.9c-5.6-0.3-11.2,0.7-15.9,2.9 M723.3,649.1v-9.1 M715.9,638v4.1 M721.1,641.3
        c-1.8,0.3-3.5,0.6-5.2,0.9 M721.1,641.3c0.9-0.1,1.7-0.3,2.2-1.2 M719.3,561.4c-1.4,2.7,2.8,69.2-3.3,68 M723.3,640.1
        c0.6-1.1,0.9-2.8,0.9-5.3l1.2-195.6 M715.9,638v-8.7 M718.4,721.4l0.2,3.8 M722,721.3l0.4,3.8 M721.2,713.3c0,0.3,0.4,4,0.5,4.4
         M721,710.9c0,0.4,0.2,2,0.2,2.4 M723.2,711.2c-0.9,0.4-1.6,1.3-2.1,2.1 M721.7,717.7l0.4,0.4 M722,718.2l-0.3,0 M722,718.2
        c0.9,1,2.2,1.4,3.5,1.3 M721.7,717.7l0.1,0.5 M728.6,717.5c-0.2-0.5-1.9-5.9-2.1-6.4 M728.6,717.5c1.4-2.2,0.4-5.5-2.1-6.4
         M728.6,717.5c0,0,0.7,2,0.7,2 M730.4,723.1l0.7,2.2 M729.3,719.5l1.2,3.7 M726.8,724.3l-1-3.7 M725.8,720.6l-0.3-1.2
         M729.3,719.5L729.3,719.5 M725.5,719.5l-2.1-7.9l-0.1-0.4 M722.4,725.1c1.5-0.2,2.9-0.4,4.4-0.8 M721.7,718.2l0.3,3.1
         M730.1,715.6l-0.8,3.9 M722,721.3c1.3-0.1,2.6-0.4,3.8-0.7 M721,705.3l-2.4,0.4c-0.2,0.7-1.2,3.2-0.9,4 M717.7,709.7l0.1,1.1
         M715.9,704.4c2.8,1,4.2-3.5,1.4-4.3C714.5,699.2,713.1,703.5,715.9,704.4L715.9,704.4z M721,705.3l2.4-0.5
        c0.5,0.6,2.3,2.5,2.3,3.4 M720.8,709.7l0.1,1.2 M721.9,709.4l-1,0.2v0 M722.7,709.3L722.7,709.3l-0.9,0.1 M723.1,710.6
        c0,0.1,0.1,0.5,0.2,0.6 M720.8,709.7l1.9-0.4 M723.1,710.6l-0.4-1.3 M723.1,710.6l-2.1,0.3 M721.9,709.4l-0.9-4.2 M716,702.1
        l0,0.4c0.3,0.4,0.8,0.4,1-0.1C717.2,701.7,716.3,701.4,716,702.1L716,702.1z M716.3,700.8l-1.1,1l0.3,1.5l1.4,0.4l1.1-1l-0.3-1.5
        L716.3,700.8L716.3,700.8z M614.2,800l-0.4-0.1 M613.4,787.8c0,0.1-0.2,0.4-0.2,0.5l-0.5,11.4 M613.4,787.8c0.1,0,1.4,0.2,1.5,0.2
        c-0.1,0.2-0.2,0.3-0.2,0.5 M612.8,799.7l-6.8-1.3 M606,798.4v1.1 M612.7,800.8l0,0.5 M606,799.6l6.7,1.2 M716.2,698
        c3.6,3.8,9.6-2.1,5.7-5.8C718.2,688.5,712.4,694.4,716.2,698L716.2,698z M617.7,802.5l-0.3-0.1 M722.8,700.1
        c0.4,2.9,4.9,2.2,4.4-0.7C726.9,696.5,722.2,697.2,722.8,700.1L722.8,700.1z M724.2,700.9l1.5,0.2l0.9-1.2l-0.6-1.4l-1.5-0.2
        l-0.9,1.2L724.2,700.9L724.2,700.9z M725.1,700.3c0.5,0,0.6-0.8,0.3-1C724.5,698.7,724,700.4,725.1,700.3L725.1,700.3z
         M733.4,698.1c-0.6-0.5-3.3-0.3-4-0.3l-1.1,2.2 M732,701.8l-0.5,0.9l0,0 M731.5,702.8l0.9-1.7 M732.4,701.1L732.4,701.1l-0.5,0.8
         M732,701.8l-3.8-1.9 M728.2,699.9l-1.1,2.1c0.4,0.7,1.7,3,2.5,3.2 M725.8,708.7c0.1,0.3,0.7,2.1,0.7,2.3 M725.7,708.2l0.1,0.5
         M729.6,705.2c-1.2,1.2-2.5,2.3-3.9,3 M725.8,708.7l5.4,1.5l-1.1,5.4 M740.3,704.5l-1.6,2.9 M738.8,707.4l2.6,1.7 M741.5,701.1
        l-8.1-3 M748.1,703.5l-3.6-1.3 M746.7,707.3l-3.5-1.5 M748.8,708.2l-2.1-0.9 M744.6,711.2c0.8-1.3,1.5-2.6,2.1-3.9 M748.8,708.2
        c0.5-1.2,1-2.5,1.4-3.9 M741.4,709.1l3.2,2.1 M748.1,703.5l2.1,0.8 M743.2,705.7l-2.9-1.2 M744.4,702.2l-2.9-1.1 M741.4,709.1
        c0.7-1.1,1.3-2.2,1.8-3.4 M739.2,712l3.2,2.2 M730.4,723.1c4.4-1.8,8.7-5.1,11.9-8.9 M742.4,714.2l1.8,1.3 M731.1,725.3
        c4.9-1.9,9.5-5.5,13.1-9.8 M744.2,715.5c0.8-1,1.6-2,2.3-3.1 M739.2,712c-2.7,3.1-6.2,5.9-10,7.4 M740.3,704.5l-7.9-3.4
         M738.8,707.4l-7.2-4.7 M744.6,711.2l1.9,1.2 M736.7,710.2l-7.1-5 M746.5,712.4c0.9-1.4,1.6-2.8,2.3-4.3 M736.7,710.2l2.6,1.8
         M730.1,715.6c2.3-1.1,4.8-3.6,6.6-5.4 M718.4,721.4l-0.6-10.6 M614.1,801.5l3.3,0.6l0.3,0.4 M555.2,768
        c81.9,18.4,54.5,10.6,60.4-70.8c0.2-4.6-2.5-7.1-8.2-7.3l-51.3-7.2 M633.3,781.3l3.9,0.1 M620.7,783.6L620.7,783.6 M633.3,781.3
        c-7.2-0.9-16.9,0.5-19.9,6.5 M567,763.1c7.3,0.6,41.3,11.3,41.5-1.1l1.1-57.2c0.4-4.9-1.5-7.5-5.5-7.7l-38.5-5
        c-3.2,0-4.8,2-4.6,6.1v56.9C560.5,760,562.5,762.7,567,763.1L567,763.1z M547.6,769.2c0,1.5,0,3.1,0,4.6 M717.6,690.1
        c2.8-0.9,1.4-5.2-1.4-4.3C713.4,686.7,714.9,691.1,717.6,690.1L717.6,690.1z M716.5,689.4l1.4-0.4l0.3-1.5l-1.1-1l-1.4,0.5
        l-0.3,1.5L716.5,689.4L716.5,689.4z M723,690.6c-0.5,2.9,4.1,3.6,4.5,0.7C728,688.4,723.4,687.6,723,690.6L723,690.6z
         M546.4,761.3h2.2 M547.7,765.8l0.7,0.7 M549.2,775.9l-1.3,1.8 M716.4,688.1c0.1,0.3,0.4,0.4,0.7,0.4l0.3-0.3
        C717.7,687.2,716.2,687.2,716.4,688.1L716.4,688.1z M718.7,680.5c-0.4,0.7,0.4,3.2,0.6,4l2.3,0.6 M732,688.4L732,688.4l0.3,0.8
         M721.6,685.1l2.3,0.6c0.5-0.6,2.5-2.3,2.6-3.1 M726.5,682.6c1.4,0.8,2.7,2,3.7,3.2 M730.2,685.8c-0.8,0.2-2.3,2.3-2.7,2.9l1,2.2
         M724.8,691.2c0.5,0.6,1.3,0,0.9-0.7C725.3,689.9,724.3,690.7,724.8,691.2L724.8,691.2z M723.8,691l0.9,1.2l1.5-0.1l0.6-1.4
        l-0.9-1.2l-1.5,0.2C724.1,690.1,724,690.6,723.8,691L723.8,691z M732.3,689.2l-3.9,1.8 M732.3,689.2l0.4,0.9h0 M728.5,690.9l1,2.2
        c0.8,0.1,3.4,0.5,4.1,0 M732.8,690.1l-0.8-1.8 M721.8,680.8l0.9,0.2 M720.3,668.9c-4.2-0.2-8.5,0.6-12.1,2.2 M720.3,668.9
        l-0.4,3.1 M719.8,672c-3.6-0.1-7.1,0.5-10.1,1.9 M728.9,666.6l-1.3,3.6 M731,671.7l1.5-3.6 M726.6,673.2l1.1-3 M727.6,670.2
        c-1.2-0.4-2.4-0.7-3.7-1 M723.3,672.3l-1.5,8.4 M719.8,672l-1.1,8.6 M723.9,669.3l-0.6,3.1 M723.3,672.3c1.1,0.2,2.2,0.5,3.2,0.8
         M729.8,674.6l1.2-2.9 M740.4,679.7c-2.4-3.3-5.8-6.2-9.3-8.1 M745.6,676.6c-3.1-4.3-7.6-8.3-12.2-10.5 M745.6,676.6l-1.9,1.1
         M747.7,680l-2,1 M747.7,680c-0.6-1.2-1.3-2.3-2.1-3.4h0 M743.7,677.7l-3.3,2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M732.5,668.1c4.2,2.1,8.3,5.7,11.2,9.6 M733.5,693.2l8.3-2.4
         M733.5,693.2c0.2,1.2,0,3.7-0.1,4.9 M740.9,687.3c-0.4-1-0.8-2-1.3-3 M743.9,686.3l3.6-1.2 M747.5,685.1l2.1-0.7 M750.7,688.2
        l-0.5,0.1 M750.2,688.3l0.5-0.1 M750.7,688.2c-0.3-1.3-0.6-2.6-1.1-3.8 M743.9,686.3c-0.4-1.2-1-2.4-1.6-3.5 M750.7,688.2
        L750.7,688.2 M748.5,688.8c1,4.7,0.8,10.2-0.5,14.7 M744.8,689.9l3.7-1.1 M744.4,702.2c1-3.8,1.1-8.4,0.4-12.3 M748.5,688.8
        l1.7-0.5 M742.3,682.8l-2.8,1.5 M741.8,690.8c0.4,3.2,0.6,7.3-0.3,10.3 M740.9,687.3l3-1 M741.8,690.8l3-0.9 M722.7,681l-1.1,4.1
         M745.7,681l-3.4,1.8 M739.6,684.3l-7.6,4.1 M732.8,690.1l8.1-2.8 M722.7,681l1,0.2 M723.7,681.3l2.9-8.1 M732.5,668.1l0.9-2.1
         M726.5,682.6l3.3-8 M747.5,685.1c-0.5-1.4-1.1-2.8-1.8-4.1 M737.7,681.3l-7.4,4.5 M723.7,681.3c-0.6-0.2-1.2-0.4-1.9-0.5
         M749.6,684.4c-0.5-1.5-1.2-3-1.9-4.4 M740.4,679.7l-2.7,1.6 M737.7,681.3c-2.1-2.7-4.9-5.2-7.8-6.8 M750.2,704.3
        c1.4-5,1.6-11,0.5-16.1 M720.8,665.1l-0.5,3.8 M706.3,667.8l-1.1-2 M713.9,681.4c1.5-0.6,3.1-0.9,4.8-0.9 M717.7,709.7
        c-1.7-0.1-3.3-0.5-4.8-1.2 M704.3,721.5c4.3,2.4,9,3.7,14.2,3.8 M724.6,665.5l-0.7,3.8 M724.6,665.5c1.5,0.3,2.9,0.6,4.3,1.1
         M728.9,666.6l0.8-2.1 M733.4,666c-1.2-0.6-2.4-1.1-3.7-1.5 M705.2,665.8c-1.2,0.6-2.3,1.2-3.4,1.9 M704.8,640.4
        c3.6-0.9,7.3-1.7,11.1-2.3 M715.9,642.2c-3.8,0.7-7.6,1.5-11.2,2.5 M717.7,710.8c-2-0.2-3.8-0.7-5.4-1.4 M706.4,718.3
        c3.6,2,7.6,3,11.9,3.1 M641.6,548.3c0.1-1,0.1-3.1-0.6-4 M697.1,561.4h8.4 M705.5,561.4h1.1 M713.5,561.4h-6.8 M641.1,544.3
        c6.4-1,6.6-10.8,0.3-12.1 M641.3,532.2c-0.5,0.8-0.3,11-0.3,12.1 M715.8,561.4h3.4 M715.8,561.4h-2.4 M705.5,561.4l-0.6,66.7
         M706.6,561.4l-0.5,64.4 M638.7,686.3l11.3,0.6 M625.3,479.8l-2.2,74.4 M628.6,431.8L567,502.6 M631.1,439.4L627.2,564
         M605.5,466.2L567,509.6 M720.2,439l-0.9,122.4 M706.5,443.9l-1,117.5 M722.8,439.1l-1.7,202.2 M643.8,316.7
        c-0.4,7.8-1.5,48-3.3,120.7 M640.6,437.4l3.5-0.5 M706.6,561.4l0.9-112.5 M637.2,548.3h4.4 M547.6,776.2l1.3-2.1 M567.9,482.8
        l0.6,13.4 M566.9,498.1l-0.5-13.5 M625.1,565.1l3.7-125.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M549,774.1l0.2,1.8 M555.2,768c0.4,5.4,4.3,9.1,11.6,11.2l46.7,8.6
         M548.5,766.5l6.7,1.5 M548.5,766.6L548.5,766.6 M548.5,766.5L548.5,766.5 M548.6,761.3l-0.1,5.3 M548.5,766.6
        c-0.1,0.4,0.5,7,0.5,7.6 M548.4,766.5L548.4,766.5 M546.4,761.3c0,2,0.4,3.5,1.3,4.5 M547.6,769.2h-0.9c-0.1,2.3,0.2,3.9,0.9,4.6
         M547.7,765.8l-0.1,3.4 M547.6,773.8l0.1,2.5 M547.7,778l-0.1-1.7 M547.7,778l0.2-0.3 M559.6,789.1l0.9-1.3 M565.7,790.7l1-1.3
         M553.6,790.1c1.5-0.9,3.5-0.9,5.6-0.3 M559.2,789.8l0.4-0.7 M559.6,789.1l6.1,1.7 M566.7,789.5l-6.2-1.7 M560.5,787.7
        c-9.7-3-10.3-4.8-11.3-11.9 M547.9,777.7c1.2,5.8-0.4,11.5,5.7,12.4 M553.6,790.1c-6.3,3.8-5.3-8.5-5.9-12.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M743.7,345.8l-3.3,2 M727.4,726.5l-0.6-2.2 M718.6,725.3l0.1,2.2
         M722.4,725.1l0.3,2.2 M634.7,686l-1.5,95.3 M637.2,781.4l1.5-95.1 M727.4,726.5c1.3-0.3,2.5-0.7,3.7-1.2 M703.1,723.4
        c4.7,2.7,9.9,4,15.6,4.1 M555.2,768l0.9-85.3"/>
    </g>
  </g>
  <g id="front">
    <g>
      <path fill="none" d="M446.7,17.5c-0.6-0.1-0.8,0.1-0.8,0.8v1.2c0.7,0.4,1.9,0.6,3.5,0.7h1.1c0.4,0,0.7,0.3,1,0.7l0.8,0.3
        c0.2-1.1,0.1-2-0.2-2.6l-1.5-1l-1.2,0c-0.2,0.1-0.3,0.4-0.3,0.7l-1.8-0.5C447.1,17.6,446.8,17.6,446.7,17.5L446.7,17.5z"/>
      <path fill="none" d="M449.4,17.6l-2.8-0.1c0.2,0.1,0.4,0.1,0.7,0.3l1.8,0.5C449.2,17.9,449.3,17.7,449.4,17.6L449.4,17.6z"/>
      <path fill="none" d="M449.4,20.2c-1.7-0.1-2.9-0.3-3.5-0.7l-60.2-0.7h-3.1l-60.2,0.7c-0.7,0.4-1.9,0.6-3.5,0.7l64.2-0.3
        L449.4,20.2L449.4,20.2z"/>
      <polygon fill="none" points="382.6,17.7 382.6,18.7 385.6,18.7 385.6,17.7 382.6,17.7 		"/>
      <path fill="none" d="M388.4,94l-8.5,0c-8.7-0.1-17.5,0-26.4,0.3l-31.7,1.3l-0.7,0.1l5.6,8.3c0.7,1.5-0.1,1.9-2.2,1.1
        c-4.7-2.2-8.4-4.8-11.1-7.9l-19.4,2l-3.9,0.4c2.5,4.8,6,7.3,10.4,7.4h167.1c4.4-0.2,7.9-2.7,10.4-7.4l-3.9-0.4l-19.4-2
        c-2.7,3.1-6.4,5.8-11.1,7.9c-2.1,0.8-2.9,0.4-2.2-1.1l5.6-8.3l-0.7-0.1l-31.7-1.3C405.8,94.1,397.1,94,388.4,94L388.4,94z
         M354.3,95.9v0.4l-0.3,0.2l-0.5,0.1h-0.1l-0.6-0.1l-0.3-0.2v-0.4c0-0.2,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.6-0.3l0.1,0
        c0.2,0,0.4,0.1,0.5,0.2C354.2,95.5,354.3,95.7,354.3,95.9L354.3,95.9z M414.7,95l0.1,0c0.2,0,0.5,0.1,0.6,0.3
        c0.2,0.2,0.3,0.4,0.3,0.7v0.4l-0.3,0.2l-0.6,0.1h-0.1l-0.5-0.1c-0.2-0.1-0.3-0.1-0.3-0.2v-0.4c0-0.2,0.1-0.5,0.3-0.7
        C414.4,95.2,414.5,95.1,414.7,95L414.7,95z"/>
      <path fill="none" d="M354.3,96.4v-0.4c0-0.2-0.1-0.5-0.3-0.7c-0.1-0.1-0.3-0.2-0.5-0.2l-0.1,0c-0.2,0-0.5,0.1-0.6,0.3
        c-0.2,0.2-0.3,0.4-0.3,0.7v0.4l0.3,0.2l0.6,0.1h0.1l0.5-0.1L354.3,96.4L354.3,96.4z"/>
      <path fill="none" d="M414.9,95l-0.1,0c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.2-0.3,0.4-0.3,0.7v0.4c0,0.1,0.1,0.1,0.3,0.2l0.5,0.1h0.1
        l0.6-0.1l0.3-0.2v-0.4c0-0.2-0.1-0.5-0.3-0.7C415.3,95.1,415.1,95,414.9,95L414.9,95z"/>
      <path fill="none" d="M447.1,95.8l-5.6,8.3c-0.7,1.5,0.1,1.9,2.2,1.1c4.7-2.2,8.4-4.8,11.1-7.9L447.1,95.8L447.1,95.8z"/>
      <path fill="none" d="M464.4,27.6l-2.3-1.9l-4.9-4.6c-0.4-0.3-1-0.6-1.9-0.9v0.1l0,0.5l0.4,1.1l0.5,2.8c0.1,0.4,0.3,0.8,0.5,1.2
        l0,0c0.9,1.4,1.7,5.2,2.6,11.6l0.1,0.6l7-0.2l-0.3-1.2c-0.7-2.1-0.9-3.6-0.6-4.6c0.1-0.2,0.1-0.3,0.2-0.5
        C465.8,29.9,465.3,28.6,464.4,27.6L464.4,27.6z"/>
      <path fill="none" d="M455.6,21.9l-0.4-1.1l0,0.4h-2.9l-0.8-0.3c0.2,0.3,0.4,0.8,0.6,1.4l0.5,2.8c0.1,0.4,0.3,0.8,0.5,1.2l0,0
        c0.9,1.4,1.5,5.2,1.9,11.3h4.3c-0.9-6.4-1.8-10.3-2.6-11.6l0,0c-0.3-0.4-0.4-0.8-0.5-1.2L455.6,21.9L455.6,21.9z"/>
      <path fill="none" d="M455.2,20.8l0-0.5v-0.1c0-1.5-0.6-2.2-1.7-2.2c-0.9,0-1.9-0.1-2.9-0.4l1.5,1c0.3,0.7,0.4,1.5,0.2,2.6h2.9
        L455.2,20.8L455.2,20.8z"/>
      <polygon fill="none" points="467.8,37.6 467.5,37 466.5,38 466.8,39.2 467.8,37.6 		"/>
      <path fill="none" d="M467.5,37c-0.8-2.3-1.5-3.9-1.9-4.7c-0.3,1-0.1,2.5,0.6,4.6l0.3,1.2L467.5,37L467.5,37z"/>
      <path fill="none" d="M466.8,39.2l-0.3-1.2l-7,0.2c5.9,21.5,10.8,41.9,14.8,61.1l3.9,0.4l3.9,0.4l-0.1-0.5l-1-4.1l-4.4-17.6
        l-9.6-38.5L466.8,39.2L466.8,39.2z"/>
      <path fill="none" d="M466.8,39.2l0.1,0.2c4.2,13.8,7.4,26.6,9.6,38.5l4.4,17.6h1.5h1.5h11.5l-0.7-6.2h-15.2h15.2l-0.1-0.8h-11.1
        l-0.7-0.4h-3h-0.7h0.7c-2.6-15.5-5.8-30-9.7-43.4c-0.9-2.8-1.7-5.2-2.4-7.1L466.8,39.2L466.8,39.2z"/>
      <path fill="none" d="M475.4,61.6c-2-6.7-3.8-12.4-5.3-16.8c3.9,13.4,7.1,27.8,9.7,43.4h3v-1.2L475.4,61.6L475.4,61.6z"/>
      <path fill="none" d="M478.1,99.7c-2.5,4.8-6,7.3-10.4,7.4H300.6c-4.4-0.2-7.9-2.7-10.4-7.4l-3.9,0.4l-3.5,2.6
        c1.2,1.3,3.1,2.9,5.5,4.5c1.7,1.5,2.4,4.1,2.1,8l2.6,0.3l8.1,0.3l22.5,0.2c0.2-0.1,0.5-0.1,0.7,0l2.2,0h9.7l95.9,0l9.7,0l2.2,0
        c0.2-0.1,0.5-0.1,0.7,0l22.7-0.2l8-0.3l2.6-0.3c-0.3-3.9,0.4-6.5,2.1-8c2.4-1.7,4.2-3.2,5.5-4.5l-3.5-2.6L478.1,99.7L478.1,99.7z
         M350.2,109.9h1.6l2.1,3.5h-1.5l-0.4-0.6h-2l-0.4,0.6H348L350.2,109.9L350.2,109.9z M359.8,111.3L359.8,111.3l0,2.2h-1.4v-3.5h1.4
        l2.6,2.2h0v-2.2h1.4v3.5h-1.4L359.8,111.3L359.8,111.3z M368.8,109.9h3.3c0.9,0,1.5,0.3,2,1c0.2,0.5,0.2,1,0,1.4
        c-0.4,0.7-1.1,1.1-2,1.1h-3.3V109.9L368.8,109.9z M385.6,109.9c1.2,0,1.8,0.4,1.9,1.1c0,0.5-0.4,0.9-1.2,1l1.7,1.4h-1.8l-1.3-1.3
        h0v1.3h-1.4v-3.5H385.6L385.6,109.9z M398.7,111.2l0.1,0.4l-0.1,0.4c-0.3,1-1.3,1.5-3,1.5c-1.9-0.1-2.9-0.7-3-1.9
        c0-0.5,0.3-1,0.9-1.3c0.6-0.3,1.3-0.5,2.1-0.5c0.8,0,1.5,0.2,2.1,0.5C398.3,110.5,398.5,110.8,398.7,111.2L398.7,111.2z
         M402.7,109.9h1.5l1.3,2.1l1.3-2.1h1.6l-2.3,3.5H405L402.7,109.9L402.7,109.9z M415.3,110.7v0.6h1.6v0.8h-1.6v0.6h1.7v0.8h-3.1
        v-3.5h3.1v0.8H415.3L415.3,110.7z"/>
      <polygon fill="none" points="350.9,111 350.9,111 350.3,112.1 351.5,112.1 350.9,111 		"/>
      <polygon fill="none" points="359.8,111.3 359.8,111.3 362.4,113.4 363.8,113.4 363.8,109.9 362.4,109.9 362.4,112.1 362.4,112.1 
        359.8,109.9 358.3,109.9 358.3,113.4 359.8,113.4 359.8,111.3 		"/>
      <path fill="none" d="M372,109.9h-3.3v3.5h3.3c0.9,0,1.5-0.4,2-1.1c0.2-0.4,0.2-0.9,0-1.4C373.6,110.3,372.9,109.9,372,109.9
        L372,109.9z M372.4,110.9c0.1,0.1,0.2,0.3,0.3,0.7l0,0.3c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.1-0.4,0.2-0.8,0.2h-1.3v-2.1h1.2
        C371.8,110.6,372.1,110.7,372.4,110.9L372.4,110.9z"/>
      <path fill="none" d="M372.6,111.5c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.9-0.2h-1.2v2.1h1.3c0.4,0,0.6-0.1,0.8-0.2
        c0.2-0.2,0.3-0.4,0.3-0.7L372.6,111.5L372.6,111.5z"/>
      <path fill="none" d="M387.6,111c-0.1-0.7-0.7-1.1-1.9-1.1h-2.1v3.5h1.4v-1.3h0l1.3,1.3h1.8l-1.7-1.4
        C387.1,111.9,387.5,111.5,387.6,111L387.6,111z M386.1,111.1c-0.1,0.3-0.4,0.5-1,0.4h-0.1v-0.9h0.1
        C385.6,110.6,386,110.7,386.1,111.1L386.1,111.1z"/>
      <path fill="none" d="M385.1,111.5c0.6,0,0.9-0.1,1-0.4c-0.1-0.3-0.4-0.5-1-0.4h-0.1v0.9H385.1L385.1,111.5z"/>
      <path fill="none" d="M398.8,111.6l-0.1-0.4c-0.1-0.4-0.4-0.7-0.8-0.9c-0.6-0.3-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5
        c-0.6,0.3-0.9,0.8-0.9,1.3c0.1,1.2,1.1,1.9,3,1.9c1.7-0.1,2.6-0.6,3-1.5L398.8,111.6L398.8,111.6z M395.7,110.7
        c0.9,0.1,1.5,0.4,1.5,0.9c-0.1,0.7-0.6,1-1.5,1c-1,0-1.5-0.4-1.5-1C394.3,111.1,394.8,110.7,395.7,110.7L395.7,110.7z"/>
      <path fill="none" d="M397.3,111.6c-0.1-0.6-0.6-0.9-1.5-0.9c-1,0.1-1.5,0.4-1.5,0.9c0.1,0.7,0.6,1,1.5,1
        C396.6,112.6,397.2,112.3,397.3,111.6L397.3,111.6z"/>
      <polygon fill="none" points="404.2,109.9 402.7,109.9 405,113.4 406.1,113.4 408.4,109.9 406.9,109.9 405.5,112.1 404.2,109.9 		
        "/>
      <polygon fill="none" points="415.3,111.3 415.3,110.7 416.9,110.7 416.9,109.9 413.8,109.9 413.8,113.4 416.9,113.4 416.9,112.7 
        415.3,112.7 415.3,112.1 416.9,112.1 416.9,111.3 415.3,111.3 		"/>
      <path fill="none" d="M424.6,110.6h-0.1v0.9h0.1c0.6,0,0.9-0.1,1-0.4C425.5,110.7,425.2,110.6,424.6,110.6L424.6,110.6z"/>
      <path fill="none" d="M482.8,80.3v6.6v1.2l0.7,0.4h11.1h17.6l-0.1-8.1c-0.2-2.9-2.1-4.5-5.9-5l-17.5-0.5
        C485,75,483,76.8,482.8,80.3L482.8,80.3z"/>
      <path fill="none" d="M494.7,88.5l0.1,0.8l0.7,6.2h-11.5c0.4,0.6,1.1,1,2.2,1.1l19.6,0.1c4.3-0.1,6.5-2.2,6.7-6.3l0-1.9H494.7
        L494.7,88.5z"/>
      <path fill="none" d="M482.3,95.5c0.7,0.7,0.5,2.1-0.5,4.1l0.1,0.5l3.5,2.6c1.5-1.6,2.1-2.9,2-4c-0.4-1-0.8-1.6-1.4-2
        c-1.1-0.2-1.8-0.5-2.2-1.1H482.3L482.3,95.5z"/>
      <path fill="none" d="M481.9,99.5c1-2,1.2-3.4,0.5-4.1h-1.5L481.9,99.5L481.9,99.5z"/>
      <path fill="none" d="M488.1,102.2c-0.1-1.5-0.3-2.6-0.6-3.6c0.2,1.1-0.5,2.4-2,4c-1.2,1.3-3.1,2.9-5.5,4.5c-1.7,1.5-2.4,4.1-2.1,8
        c2.7-0.2,5.6-1.3,8.9-3.4c0.8-0.3,1.2,0.8,1.2,3.3l1.4-1l-0.1-1.2l-0.5-4.2l-0.3-2.8L488.1,102.2L488.1,102.2z"/>
      <path fill="none" d="M492.3,109c-0.1-0.5-0.4-0.7-0.9-0.7l-0.4,0l-2.3,0.3l0.5,4.2l2.9-0.3c0.5-0.1,0.7-0.4,0.6-0.9L492.3,109
        L492.3,109z"/>
      <path fill="none" d="M491.1,108.3l-0.4-2c-0.1-0.5-0.4-0.7-0.9-0.7l-1.4,0.2l0.3,2.8L491.1,108.3L491.1,108.3z"/>
      <path fill="none" d="M476.4,77.9c-2.2-11.8-5.4-24.7-9.6-38.5L476.4,77.9L476.4,77.9z"/>
      <path fill="none" d="M318.8,17.6l-1.2,0l-1.5,1c-0.3,0.7-0.4,1.5-0.2,2.6l0.8-0.3c0.3-0.4,0.6-0.7,1-0.7h1.1
        c1.7-0.1,2.9-0.3,3.5-0.7v-1.2c0-0.7-0.3-0.9-0.8-0.8l-0.7,0.3l-1.8,0.5C319,17.9,318.9,17.7,318.8,17.6L318.8,17.6z"/>
      <path fill="none" d="M321.5,17.5l-2.8,0.1c0.2,0.1,0.3,0.4,0.3,0.7l1.8-0.5L321.5,17.5L321.5,17.5z"/>
      <path fill="none" d="M316.1,18.6l1.5-1c-1.1,0.3-2,0.4-2.9,0.4c-1.1,0-1.7,0.7-1.7,2.2v0.1l0,0.5l0,0.4h2.9
        C315.7,20.1,315.8,19.3,316.1,18.6L316.1,18.6z"/>
      <path fill="none" d="M313,20.8l0-0.5v-0.1c-0.9,0.3-1.5,0.6-1.9,0.9l-4.9,4.6l-2.3,1.9c-0.9,0.9-1.4,2.3-1.4,4.2l0.2,0.5
        c0.3,1,0.1,2.5-0.6,4.6l-0.3,1.2l7,0.2l0.1-0.6c0.9-6.4,1.8-10.3,2.6-11.6l0,0c0.3-0.4,0.4-0.8,0.5-1.2l0.5-2.8
        C312.7,21.5,312.8,21.1,313,20.8L313,20.8z"/>
      <path fill="none" d="M313,20.8c-0.1,0.3-0.3,0.7-0.4,1.1l-0.5,2.8c-0.1,0.4-0.3,0.8-0.5,1.2l0,0c-0.9,1.4-1.7,5.2-2.6,11.6h4.3
        c0.3-6.1,1-9.8,1.9-11.3l0,0c0.3-0.4,0.4-0.8,0.5-1.2l0.5-2.8c0.2-0.6,0.4-1.1,0.6-1.4l-0.8,0.3H313L313,20.8L313,20.8z"/>
      <path fill="none" d="M302.6,32.3c-0.5,0.8-1.1,2.4-1.9,4.7l1,1l0.3-1.2C302.7,34.7,302.9,33.2,302.6,32.3L302.6,32.3z"/>
      <polygon fill="none" points="300.7,37 300.4,37.6 301.4,39.2 301.7,38 300.7,37 		"/>
      <path fill="none" d="M255.9,88.5l0,1.9c0.2,4.2,2.4,6.3,6.7,6.3l19.6-0.1c1.1-0.2,1.8-0.5,2.2-1.1h-11.5l0.7-6.2l0.1-0.8H255.9
        L255.9,88.5z"/>
      <path fill="none" d="M256,80.4l-0.1,8.1h17.6h11.1l0.7-0.4v-1.2v-6.6c-0.2-3.5-2.2-5.3-6-5.4l-17.5,0.5
        C258.2,75.8,256.2,77.5,256,80.4L256,80.4z"/>
      <path fill="none" d="M308.8,38.2l-7-0.2l-0.3,1.2l-0.1,0.2l-9.6,38.5l-4.4,17.6l-1,4.1l-0.1,0.5l3.9-0.4l3.9-0.4
        C297.9,80.1,302.8,59.7,308.8,38.2L308.8,38.2z"/>
      <path fill="none" d="M301.4,39.4l0.1-0.2l-1-1.5c-0.7,1.9-1.5,4.3-2.4,7.1c-3.9,13.4-7.1,27.8-9.7,43.4h0.7h-0.7h-3l-0.7,0.4
        h-11.1l-0.1,0.8h15.2h-15.2l-0.7,6.2h11.5h1.5h1.5l4.4-17.6C294,66,297.2,53.2,301.4,39.4L301.4,39.4z"/>
      <path fill="none" d="M298,44.7c-1.5,4.5-3.2,10.1-5.3,16.8l-7.4,25.3v1.2h3C290.9,72.6,294.1,58.1,298,44.7L298,44.7z"/>
      <path fill="none" d="M284.3,95.5c-0.4,0.6-1.1,1-2.2,1.1c-0.6,0.4-1,1.1-1.4,2c-0.1,1.1,0.5,2.4,2,4l3.5-2.6l0.1-0.5
        c-1-2-1.2-3.4-0.5-4.1H284.3L284.3,95.5z"/>
      <path fill="none" d="M280.8,98.6c-0.3,0.9-0.5,2.1-0.6,3.6l-0.4,3.7l-0.3,2.8l-0.5,4.2l-0.1,1.2l1.4,1c0-2.5,0.4-3.6,1.2-3.3
        c3.3,2.1,6.3,3.2,8.9,3.4c0.3-3.9-0.4-6.5-2.1-8c-2.4-1.7-4.2-3.2-5.5-4.5C281.3,101,280.6,99.7,280.8,98.6L280.8,98.6z"/>
      <path fill="none" d="M275.9,109l-0.5,2.6c-0.1,0.5,0.1,0.8,0.6,0.9l2.9,0.3l0.5-4.2l-2.3-0.3l-0.4,0
        C276.2,108.2,276,108.4,275.9,109L275.9,109z"/>
      <path fill="none" d="M279.4,108.6l0.3-2.8l-1.4-0.2c-0.5-0.1-0.8,0.2-0.9,0.7l-0.4,2L279.4,108.6L279.4,108.6z"/>
      <path fill="none" d="M286.4,99.5l1-4.1h-1.5C285.2,96.1,285.3,97.5,286.4,99.5L286.4,99.5z"/>
      <path fill="none" d="M326.7,104.1l-5.6-8.3l-7.7,1.5c2.7,3.1,6.4,5.8,11.1,7.9C326.6,106,327.3,105.7,326.7,104.1L326.7,104.1z"/>
      <path fill="none" d="M291.8,77.9l9.6-38.5C297.2,53.2,294,66,291.8,77.9L291.8,77.9z"/>
      <path fill="none" d="M324.3,116c-0.2-0.1-0.5-0.1-0.7,0l-22.5-0.2l-8.1-0.3c-1.5,1-2.5,2.1-2.7,3.4l1.4,0.3l1.4,0.3
        c0.4-1.5,1-2.3,1.8-2.6h5.1l2.6,0l20.4,0.1l2.6,0l0,0.6c-0.3,1.2-0.5,2.8-0.5,4.7l1.8,0.4l0-6.7L324.3,116L324.3,116z"/>
      <path fill="none" d="M325.2,117.6l0-0.6l-2.6,0c-0.4,1.4-0.6,3.2-0.6,5.3c-1.5,0.2-2.8,0.7-4,1.6c-0.2,0.1-0.4,0.3-0.6,0.4
        l-0.9,0.8c-0.5,0.6-1,1.2-1.4,1.9c-0.1,0.6-0.4,1.1-0.7,1.6h2.8c0.2-0.4,0.4-0.8,0.7-1.2l0.6-0.6c1.3-1.3,2.8-1.9,4.6-1.9h0.2
        c0.9,0,1.7,0.2,2.4,0.5l0.7,0.3c0.5,0.3,1,0.7,1.4,1.1c0.6,0.6,1,1.2,1.3,1.9h2.8c-0.4-1.4-1.2-2.6-2.3-3.7c-1-1-2-1.7-3.2-2.1
        l-1.8-0.4C324.7,120.4,324.9,118.9,325.2,117.6L325.2,117.6z M323.8,122.9l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
        C323.7,122.6,323.8,122.7,323.8,122.9L323.8,122.9z M328.8,126.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.4,0.2l0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2C328.8,127,328.8,126.8,328.8,126.6
        L328.8,126.6z"/>
      <path fill="none" d="M299.7,116.8l0,0.2c-0.2,4.4,0,8,0.4,10.9c0,0.2,0.1,0.5,0.1,0.7h2.7c-0.1-0.3-0.2-0.7-0.3-1.1
        c-0.1-1-0.3-2.1-0.3-3.3c-0.1-2.1-0.1-4.4,0-7.1l0-0.3L299.7,116.8L299.7,116.8z M301.5,120.6c0,0.2-0.1,0.3-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.4,0.2C301.4,120.3,301.5,120.4,301.5,120.6L301.5,120.6z M301.3,126.7c0.2,0,0.3,0.1,0.4,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c0-0.2,0.1-0.3,0.2-0.4C301,126.7,301.2,126.7,301.3,126.7L301.3,126.7z"/>
      <path fill="none" d="M314.6,128.6C314.6,128.7,314.6,128.7,314.6,128.6c-0.2,0.7-0.4,1.5-0.4,2.3v0.9l-1.6,0l-5.4-0.3l-0.5-0.1
        l-0.1,0c-0.7-0.1-1.4-0.4-1.9-0.7c-0.8-0.5-1.3-1.2-1.7-2.1h-2.7c0.2,0.7,0.4,1.4,0.7,2c1,1.8,2.7,2.9,5.2,3.4h0.1
        c2.6,0.4,5.4,0.5,8.5,0.4c0.4,1.2,1.2,2.4,2.2,3.4l0.8,0.7c1.6,1.3,3.5,1.9,5.6,1.9c1.1,0,2.2-0.2,3.2-0.5
        c1.2-0.4,2.3-1.1,3.2-2.1c1.3-1.3,2.1-2.7,2.5-4.3l0.2-1.1l0-1c0-1-0.1-1.9-0.4-2.7h-2.8c0.4,0.8,0.6,1.7,0.6,2.7
        c0,1.8-0.6,3.3-1.9,4.6c-0.4,0.4-0.9,0.8-1.4,1.1l-0.7,0.3c-0.8,0.3-1.6,0.5-2.5,0.5c-1.8,0-3.3-0.6-4.6-1.9
        c-0.9-0.9-1.5-1.9-1.8-3.1l0,0v0l0-0.1v0c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.3,0-0.6,0.1-0.9c0.1-0.7,0.3-1.2,0.5-1.8H314.6
        L314.6,128.6z M313.1,132.5c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4C312.8,132.5,312.9,132.5,313.1,132.5L313.1,132.5z
         M305.2,131.7c0.2,0,0.3,0.1,0.4,0.2l0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c0-0.2,0.1-0.3,0.2-0.4C304.8,131.8,305,131.7,305.2,131.7L305.2,131.7z M331.4,131.2c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        c0.1-0.1,0.3-0.2,0.4-0.2C331.1,131.1,331.3,131.1,331.4,131.2L331.4,131.2z M323.3,138.5c0.2,0,0.3,0.1,0.4,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c0-0.2,0.1-0.3,0.2-0.4C323,138.6,323.2,138.5,323.3,138.5L323.3,138.5z M317.1,136.9c0-0.2,0.1-0.3,0.2-0.4
        c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        C317.2,137.2,317.1,137.1,317.1,136.9L317.1,136.9z M328.6,137.1c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        C328.9,137.3,328.7,137.3,328.6,137.1L328.6,137.1z"/>
      <path fill="none" d="M318.7,126.8l-0.6,0.6c-0.7,0.9-1.1,1.9-1.3,3.1c0,0.3-0.1,0.6-0.1,0.9c0,0.5,0,0.9,0.1,1.3v0l0,0.1v0l0,0
        c0.3,1.2,0.9,2.2,1.8,3.1c1.3,1.2,2.8,1.9,4.6,1.9c0.9,0,1.8-0.2,2.5-0.5l0.7-0.3c0.5-0.3,1-0.7,1.4-1.1c1.3-1.3,1.9-2.8,1.9-4.6
        c0-1.8-0.6-3.3-1.9-4.6c-0.4-0.4-0.9-0.8-1.4-1.1l-0.7-0.3c-0.7-0.3-1.5-0.5-2.4-0.5h-0.2C321.5,124.9,320,125.5,318.7,126.8
        L318.7,126.8z M323.4,125.8c0.7,0,1.4,0.1,2,0.4l0.6,0.3c0.4,0.2,0.8,0.6,1.2,0.9c1.1,1.1,1.6,2.4,1.6,3.9c0,1.5-0.5,2.8-1.6,3.9
        c-0.4,0.4-0.8,0.7-1.2,0.9l-0.6,0.3c-0.7,0.3-1.4,0.4-2.2,0.4c-1.5,0-2.8-0.5-3.9-1.6c-0.8-0.8-1.3-1.7-1.5-2.6l0,0v0l0,0v0
        l-0.1-1.2c0-1.5,0.5-2.8,1.6-3.9c1.1-1.1,2.4-1.6,3.9-1.6H323.4L323.4,125.8z"/>
      <path fill="none" d="M325.4,126.2c-0.6-0.3-1.3-0.4-2-0.4h-0.1c-1.5,0-2.8,0.5-3.9,1.6c-1.1,1.1-1.6,2.4-1.6,3.9l0.1,1.2v0l0,0v0
        l0,0c0.2,1,0.7,1.9,1.5,2.6c1.1,1.1,2.4,1.6,3.9,1.6c0.8,0,1.5-0.1,2.2-0.4l0.6-0.3c0.4-0.2,0.8-0.6,1.2-0.9
        c1.1-1.1,1.6-2.4,1.6-3.9c0-1.5-0.5-2.8-1.6-3.9c-0.4-0.4-0.8-0.7-1.2-0.9L325.4,126.2L325.4,126.2z M323.8,130.4l0.2,0.2
        c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8l-0.2,0.2l-0.1,0.1l-0.4,0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.1-0.2-0.2-0.3-0.3-0.5v0
        l0-0.2c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h0l0.4,0.1L323.8,130.4L323.8,130.4z"/>
      <path fill="none" d="M324.1,130.6l-0.2-0.2l-0.1-0.1l-0.4-0.1h0c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8l0,0.2v0
        c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.2,0.5,0.3,0.8,0.3l0.4-0.1l0.1-0.1l0.2-0.2c0.2-0.2,0.3-0.5,0.3-0.8
        C324.4,131,324.3,130.8,324.1,130.6L324.1,130.6z"/>
      <path fill="none" d="M323.8,122.9c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2L323.8,122.9L323.8,122.9z"/>
      <path fill="none" d="M329.8,126.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l0.2-0.4L329.8,126.2L329.8,126.2z"/>
      <path fill="none" d="M300.9,120c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2
        c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C301.2,120,301,120,300.9,120L300.9,120z"/>
      <path fill="none" d="M301.3,126.7c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C301.6,126.7,301.5,126.7,301.3,126.7L301.3,126.7z
        "/>
      <path fill="none" d="M312.5,133.1c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l0.2-0.4
        c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2C312.5,132.8,312.5,132.9,312.5,133.1L312.5,133.1z"/>
      <path fill="none" d="M305.2,131.7c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l0.2-0.4l-0.2-0.4C305.5,131.8,305.3,131.7,305.2,131.7L305.2,131.7z"/>
      <path fill="none" d="M323.3,138.5c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C323.7,138.6,323.5,138.5,323.3,138.5L323.3,138.5z
        "/>
      <path fill="none" d="M329,137.3c0.2,0,0.3-0.1,0.4-0.2l0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4C328.7,137.3,328.9,137.3,329,137.3L329,137.3z"/>
      <path fill="none" d="M318.2,136.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l0.2-0.4L318.2,136.4L318.2,136.4z"/>
      <path fill="none" d="M331.4,131.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        C331.6,131.5,331.5,131.4,331.4,131.2L331.4,131.2z"/>
      <path fill="none" d="M316.6,125.2l0.9-0.8c-0.3-1.4-0.9-2.6-2-3.7c-1.4-1.4-3.2-2.2-5.2-2.2c-2,0-3.8,0.7-5.2,2.2
        c-1.4,1.4-2.2,3.2-2.2,5.2c0,1.9,0.6,3.5,1.8,4.8c0.5,0.4,1.2,0.6,1.9,0.7l0.1,0l0.5,0.1c-0.5-0.3-1-0.7-1.5-1.1
        c-1.2-1.2-1.9-2.8-1.9-4.5c0-1.8,0.6-3.3,1.9-4.5c1.2-1.2,2.8-1.9,4.5-1.9c1.8,0,3.3,0.6,4.5,1.9
        C315.9,122.4,316.5,123.7,316.6,125.2L316.6,125.2z"/>
      <path fill="none" d="M315.2,127l0.1-1.2c0-1.4-0.5-2.6-1.5-3.6c-1-1-2.2-1.5-3.6-1.5c-1.4,0-2.6,0.5-3.6,1.5c-1,1-1.5,2.2-1.5,3.6
        c0,1.4,0.5,2.6,1.5,3.6c1,1,2.2,1.5,3.6,1.5c1.4,0,2.6-0.5,3.6-1.5l0.7-0.9C314.7,128.1,315,127.5,315.2,127L315.2,127z
         M307,129.2c-0.9-0.9-1.4-2-1.4-3.3c0-1.3,0.4-2.4,1.4-3.3c0.9-0.9,2-1.4,3.3-1.4c1.3,0,2.4,0.4,3.3,1.4c0.9,0.9,1.4,2,1.4,3.3
        c0,1.3-0.4,2.4-1.4,3.3c-0.9,0.9-2,1.4-3.3,1.4C308.9,130.5,307.9,130.1,307,129.2L307,129.2z"/>
      <path fill="none" d="M305.6,125.9c0,1.3,0.4,2.4,1.4,3.3c0.9,0.9,2,1.4,3.3,1.4c1.3,0,2.4-0.4,3.3-1.4c0.9-0.9,1.4-2,1.4-3.3
        c0-1.3-0.4-2.4-1.4-3.3c-0.9-0.9-2-1.4-3.3-1.4c-1.3,0-2.4,0.4-3.3,1.4C306.1,123.5,305.6,124.6,305.6,125.9L305.6,125.9z
         M309.1,124.7c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2
        c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2C308.6,125.4,308.8,125,309.1,124.7L309.1,124.7z"/>
      <path fill="none" d="M310.3,124.2c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2
        c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2
        C311.1,124.4,310.7,124.2,310.3,124.2L310.3,124.2z"/>
      <path fill="none" d="M315.4,125.9l-0.1,1.2c0.4-0.7,0.8-1.3,1.4-1.9c-0.1-1.5-0.8-2.8-1.8-3.8c-1.2-1.2-2.8-1.9-4.5-1.9
        c-1.8,0-3.3,0.6-4.5,1.9c-1.2,1.2-1.9,2.8-1.9,4.5c0,1.8,0.6,3.3,1.9,4.5c0.5,0.5,1,0.8,1.5,1.1l5.4,0.3c0.6-0.2,1.1-0.5,1.6-0.9
        c0-0.8,0.2-1.6,0.4-2.3l-0.7,0.9c-1,1-2.2,1.5-3.6,1.5c-1.4,0-2.6-0.5-3.6-1.5c-1-1-1.5-2.2-1.5-3.6c0-1.4,0.5-2.6,1.5-3.6
        c1-1,2.2-1.5,3.6-1.5c1.4,0,2.6,0.5,3.6,1.5C314.9,123.2,315.4,124.4,315.4,125.9L315.4,125.9z"/>
      <path fill="none" d="M317.5,124.3c0.2-0.2,0.4-0.3,0.6-0.4c-0.3-1.4-1-2.6-2.1-3.7c-1.6-1.6-3.5-2.3-5.7-2.3
        c-2.2,0-4.1,0.8-5.7,2.3c-1.2,1.2-1.9,2.5-2.2,4.1c0.1,1.2,0.2,2.3,0.3,3.3c0.2,1.4,0.9,2.5,2,3.2c-1.2-1.4-1.8-3-1.8-4.8
        c0-2.1,0.7-3.8,2.2-5.2c1.4-1.4,3.2-2.2,5.2-2.2c2,0,3.8,0.7,5.2,2.2C316.5,121.7,317.2,122.9,317.5,124.3L317.5,124.3z"/>
      <path fill="none" d="M318,123.9c1.2-0.9,2.5-1.4,4-1.6c0-2.1,0.2-3.9,0.6-5.3l-20.4-0.1l0,0.3c-0.1,2.7-0.1,5,0,7.1
        c0.3-1.5,1-2.9,2.2-4.1c1.6-1.6,3.5-2.3,5.7-2.3c2.2,0,4.1,0.8,5.7,2.3C317,121.3,317.7,122.5,318,123.9L318,123.9z"/>
      <path fill="none" d="M326.5,122.8c1.2,0.4,2.3,1.2,3.2,2.1c1.8,1.8,2.7,3.9,2.7,6.4l0,1l-0.2,1.1c-0.4,1.6-1.2,3.1-2.5,4.3
        c-1,1-2,1.7-3.2,2.1v3.2c1.2,1.5,4.1,2.4,8.8,2.6H433c4.6-0.2,7.5-1.1,8.8-2.6v-3.2c-1.2-0.4-2.3-1.1-3.2-2.1
        c-1.3-1.3-2.1-2.7-2.4-4.3l-0.2-1.1c0-0.3-0.1-0.7-0.1-1c0-2.5,0.9-4.7,2.7-6.4c1-1,2-1.7,3.2-2.1l0-6.7l-9.7,0v5.2v1.8v0.9v3.5
        v4.2v1.5l0,0.2c-0.1,0.4-0.2,0.8-0.5,1.2c-0.4,0.7-1,1.4-1.9,2.1c-1.8,1.5-3.8,2.2-6,2.1h-7.1h-0.9h-20.1h-0.9h-21h-0.9h-20.1
        h-0.9h-7.1c-2.2,0.1-4.2-0.5-6-2.1c-0.9-0.7-1.5-1.4-1.9-2.1c-0.2-0.4-0.4-0.8-0.5-1.2l0-0.2v-1.5v-4.2v-3.5V123v-1.8v-5.2h-9.7
        L326.5,122.8L326.5,122.8z"/>
      <path fill="none" d="M314.2,131.8V131c-0.5,0.4-1,0.7-1.6,0.9L314.2,131.8L314.2,131.8z"/>
      <path fill="none" d="M313.1,137c-0.5-1.3-1.6-2.1-3.4-2.1h-4.7c-1,0.1-1.8-0.2-2.4-0.6c-2.5,1.7-2.9,3.9-1.3,6.6l10.7,0.1
        C314.1,141,314.4,139.7,313.1,137L313.1,137z M306.6,137.9l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
        C306.6,137.6,306.6,137.7,306.6,137.9L306.6,137.9z"/>
      <path fill="none" d="M306.4,138.3l0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2C306.2,138.5,306.3,138.5,306.4,138.3L306.4,138.3z"/>
      <path fill="none" d="M309.8,134.9c1.7,0.1,2.8,0.8,3.4,2.1c1.3,2.6,0.9,3.9-1.1,4l12.5,0.1c1.1-0.1,1.8-0.5,2-1.2
        c-1,0.4-2,0.5-3.2,0.5c-2.1,0-4-0.6-5.6-1.9l-0.8-0.7c-1-1-1.7-2.1-2.2-3.4c-3.1,0.1-5.9,0-8.5-0.4h-0.1c-2.5-0.5-4.2-1.6-5.2-3.4
        h-8.6l-0.1,3.5c0-0.7,0.3-1.1,0.7-1.3c5.3-0.1,8.4,0.1,9.1,0.8l0.6,0.6c0.7,0.5,1.5,0.7,2.4,0.6H309.8L309.8,134.9z"/>
      <path fill="none" d="M302.7,134.3l-0.6-0.6c-0.7-0.7-3.8-1-9.1-0.8c-0.4,0.1-0.7,0.5-0.7,1.3l0,5.4c-0.1,0.4,0,0.7,0.3,1
        c0.3,0.2,0.7,0.3,1.3,0.3l7.5,0.1C299.8,138.1,300.2,135.9,302.7,134.3L302.7,134.3z M296.7,137.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l0.2,0.4
        C296.9,137.2,296.8,137.3,296.7,137.4L296.7,137.4z"/>
      <path fill="none" d="M296.3,137.6c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4l-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4C296,137.5,296.1,137.6,296.3,137.6L296.3,137.6z"/>
      <path fill="none" d="M312.1,141l-10.7-0.1l-7.5-0.1c-0.6,0-1-0.1-1.3-0.3c-0.9,0.2-1.5,0-1.8-0.7c0-7.6,0.2-14.5,0.8-20.6
        l-1.4-0.3l-0.1,0.6l-0.4,22.5c0.3,0.6,1.2,0.9,2.4,0.8l34.3,0.4v-3.2c-0.2,0.8-0.8,1.2-2,1.2L312.1,141L312.1,141z"/>
      <path fill="none" d="M314.6,128.7c0.3-0.5,0.6-1,0.7-1.6C315,127.5,314.7,128.1,314.6,128.7L314.6,128.7z"/>
      <path fill="none" d="M301,130.6c-0.4-0.8-0.7-1.7-0.9-2.7c-0.4-2.9-0.6-6.5-0.4-10.9l0-0.2h-5.1c-0.8,0.3-1.4,1.1-1.8,2.6
        c-0.3,3.4-0.4,7.1-0.6,11.2H301L301,130.6z M295.5,124.6c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        C295.7,124.7,295.6,124.7,295.5,124.6L295.5,124.6z"/>
      <path fill="none" d="M295.3,124.1c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2C295.4,123.8,295.3,124,295.3,124.1L295.3,124.1z"/>
      <path fill="none" d="M290.1,119.4l0.1-0.6c-3.2-0.6-6.5-1.9-10-3.8l-1.4-1l-1,9.3l-0.6,5.2c1.7-2.7,3.5-4.7,5.3-6.1h2.4
        c1.8,5.6,3.6,13.2,5.3,23c3.3,1.9,10.4,2.9,21.3,3.2h145.1c11-0.2,18.1-1.2,21.3-3.2c1.7-9.7,3.5-17.4,5.3-23h2.4
        c1.9,1.4,3.6,3.4,5.3,6.1l-0.6-5.2l-1-9.3l-1.4,1c-3.5,1.9-6.8,3.2-10,3.8l0.1,0.6l0.4,22.5c-0.3,0.6-1.2,0.9-2.4,0.8l-34.3,0.4
        c-1.2,1.5-4.1,2.4-8.8,2.6h-97.8c-4.6-0.2-7.5-1.1-8.8-2.6l-34.3-0.4c-1.3,0-2.1-0.3-2.4-0.8L290.1,119.4L290.1,119.4z
         M314.4,148.1c-0.2,0-0.4-0.1-0.4-0.4v-1.1c0-0.2,0.1-0.4,0.4-0.4h0.1v-0.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.3
        c0.1,0,0.2,0,0.3,0.1l0.1,0.3v0.1h0.9v-0.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.3c0.1,0,0.2,0,0.3,0.1l0.1,0.3v0.1
        c0.2,0,0.4,0.1,0.4,0.4v1.1c0,0.2-0.1,0.4-0.4,0.4H314.4L314.4,148.1z M453.7,146.1v0.1h0.1c0.2,0,0.4,0.1,0.4,0.4v1.1
        c0,0.2-0.1,0.4-0.4,0.4h-3.1c-0.2,0-0.4-0.1-0.4-0.4v-1.1c0-0.2,0.1-0.4,0.4-0.4v-0.1c0-0.1,0-0.2,0.1-0.3
        c0.1-0.1,0.2-0.1,0.3-0.1h0.3c0.1,0,0.2,0,0.3,0.1l0.1,0.3v0.1h0.9v-0.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.3
        c0.1,0,0.2,0,0.3,0.1L453.7,146.1L453.7,146.1z"/>
      <path fill="none" d="M314.1,147.7c0,0.2,0.1,0.4,0.4,0.4h3.1c0.2,0,0.4-0.1,0.4-0.4v-1.1c0-0.2-0.1-0.4-0.4-0.4h-1h-0.9h-1.1h-0.1
        c-0.2,0-0.4,0.1-0.4,0.4V147.7L314.1,147.7z"/>
      <path fill="none" d="M314.5,146.1v0.1h1.1v-0.1l-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-0.3c-0.1,0-0.2,0-0.3,0.1
        C314.6,146,314.5,146,314.5,146.1L314.5,146.1z"/>
      <path fill="none" d="M317.4,146c-0.1-0.1-0.2-0.1-0.3-0.1h-0.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1h1v-0.1
        L317.4,146L317.4,146z"/>
      <path fill="none" d="M453.7,146.3v-0.1l-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1H453c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1
        H453.7L453.7,146.3z"/>
      <path fill="none" d="M453.8,146.3L453.8,146.3h-1.2h-0.9h-1c-0.2,0-0.4,0.1-0.4,0.4v1.1c0,0.2,0.1,0.4,0.4,0.4h3.1
        c0.2,0,0.4-0.1,0.4-0.4v-1.1C454.1,146.4,454,146.3,453.8,146.3L453.8,146.3z"/>
      <path fill="none" d="M451.3,145.9h-0.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1h1v-0.1l-0.1-0.3
        C451.5,145.9,451.4,145.9,451.3,145.9L451.3,145.9z"/>
      <path fill="none" d="M292.9,115.5l-2.6-0.3c-2.7-0.2-5.6-1.3-8.9-3.4c-0.8-0.3-1.2,0.8-1.2,3.3c3.5,1.9,6.8,3.2,10,3.8
        C290.5,117.5,291.4,116.4,292.9,115.5L292.9,115.5z"/>
      <path fill="none" d="M292.9,119.4l-1.4-0.3c-0.6,6.1-0.8,13-0.8,20.6c0.3,0.7,0.9,0.9,1.8,0.7c-0.3-0.2-0.4-0.5-0.3-1l0-5.4
        l0.1-3.5C292.4,126.5,292.6,122.8,292.9,119.4L292.9,119.4z"/>
      <path fill="none" d="M277.2,128.5l-0.1,0.2c-0.4,0.7-0.9,1.5-1.3,2.3c3.2,13.3,6.2,28.6,9.1,45.9c-2.9-17.3-5.9-32.6-9.1-45.9
        c-0.5,1.7-0.7,4-0.4,6.8c-0.1-1.5,0.2-1.7,0.7-0.5l2.9,14.3c1.6,8.1,3.1,16.5,4.6,25.3l11.3,1.5c-0.8-9.1-1.9-17.3-3.3-24.6
        l-1.4-8.4c-1.7-9.7-3.5-17.4-5.3-23h-2.4C280.6,123.8,278.8,125.9,277.2,128.5L277.2,128.5z"/>
      <path fill="none" d="M277.1,128.7l0.1-0.2l0.6-5.2l-4.1,6.4c-0.7,5.2-0.3,12.9,1.1,23c1.1,9.1,2.6,16.9,4.3,23.1v-5.4
        c-1.6-8.3-2.7-17.8-3.3-28.6c-0.2-1.9-0.4-3.2-0.4-4.1c-0.2-2.8-0.1-5.1,0.4-6.8C276.2,130.2,276.6,129.4,277.1,128.7L277.1,128.7
        z"/>
      <path fill="none" d="M276.1,137.3c-0.5-1.2-0.8-1.1-0.7,0.5c0,0.9,0.1,2.3,0.4,4.1c0.6,10.8,1.7,20.3,3.3,28.6v-18.9L276.1,137.3
        L276.1,137.3z"/>
      <path fill="none" d="M279,170.5v5.4v56c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5v-25.8v-4.8l-4.5-0.7
        c-1.7-0.9-2.2-3.2-1.5-6.9l-2.3-0.6c-3.2-1.2-6.2-3.9-9-8.2c-0.3-0.7-0.5-1.4-0.6-2.2c-0.1-0.6,0-1.2,0-1.8
        c-2.3-0.8-4.5-2.2-6.4-4.2c-1.5-8.7-3-17.2-4.6-25.3V170.5L279,170.5z"/>
      <path fill="none" d="M290,181c1.6,0.5,3.3,0.7,5.1,0.7l0-0.1v-0.1l-0.3-3.1l-11.3-1.5C285.5,178.9,287.7,180.2,290,181L290,181z"
        />
      <path fill="none" d="M295.2,181.7c-1.8,0.1-3.5-0.2-5.1-0.7c-0.1,0.7-0.1,1.2,0,1.8l5.4,0.8L295.2,181.7L295.2,181.7z"/>
      <path fill="none" d="M295.4,183.6l-5.4-0.8c0.1,0.8,0.3,1.5,0.6,2.2c2.9,4.3,5.9,7.1,9,8.2l2.3,0.6c6.4,1.4,15.7,1.9,27.8,1.5
        l-1.7-5.6v-7.3v-4.9c-11.3-0.3-22.3,0-33.1,0.9l0.3,3.1v0.1l0,0.1L295.4,183.6L295.4,183.6z M303.9,184.9l0.2-0.8
        c0.2-0.6,0.6-1,1.2-1.2l0.7-0.1h16.9l0.9,0.1c0.2,0.1,0.4,0.1,0.5,0.3l0.1,0.1c0.2,0.2,0.3,0.5,0.3,0.8v0.3l0.2,2v0l0.2,2.8
        c0,0.3-0.1,0.6-0.3,0.8l-0.4,0.3c-0.5,0.2-1.3,0.3-2.3,0.3l-9-0.1l-8-0.6l-0.5-0.2c-0.4-0.2-0.7-0.5-0.8-0.8
        c-0.1-0.2-0.1-0.4-0.1-0.7v0L303.9,184.9L303.9,184.9z"/>
      <path fill="none" d="M304.1,184.1l-0.2,0.8l-0.5,3.4v0c0,0.2,0.1,0.4,0.1,0.7c0.1,0.3,0.4,0.6,0.8,0.8l0.5,0.2l8,0.6l9,0.1
        c1,0,1.8-0.1,2.3-0.3l0.4-0.3c0.2-0.2,0.4-0.5,0.3-0.8l-0.2-2.8v0l-0.2-2v-0.3c0-0.3-0.1-0.6-0.3-0.8l-0.1-0.1
        c-0.1-0.1-0.3-0.2-0.5-0.3l-0.9-0.1H306l-0.7,0.1C304.7,183.1,304.3,183.5,304.1,184.1L304.1,184.1z M306.4,183.5l0.7,0h2.7h0.8
        h1.5h0.8h2.7h0.8h1.5h0.8h2.7h0.8c0.9,0,1.3,0.4,1.3,1.2l0,0.4l0,0.4l0.1,1.7l0.1,0.7l0.1,0.6c0,0.5-0.4,0.8-1.4,1l-0.9,0.1
        l-3.2,0l-2.6,0l-3.1-0.1l-0.8,0l-1.4-0.1l-0.9-0.1l-2.4-0.1l-0.7-0.1l-0.2,0c-1.2-0.1-1.8-0.4-2-1l-0.1-0.4l0.1-0.7l0.2-1.3
        l0.1-0.6C305,184.2,305.6,183.6,306.4,183.5L306.4,183.5z"/>
      <path fill="none" d="M305,185.1L305,185.1l-0.2,0.6l0.2-0.1h1.3l1.1,0.9l-1.1,0.9H305l-0.4-0.3l-0.1,0.7l0.1,0.4l0.4-0.3h1.3
        l1.1,0.9l-0.7,0.5l0.7,0.1l0.4-0.3h1.3l0.6,0.5l0.9,0.1l-1-0.8l1.1-0.9h1.3l1.1,0.9l-1.1,0.8l0.8,0l0.8-0.6h1.3l0.9,0.7l2.6,0
        l1-0.7h1.3l0.9,0.7l0.9-0.1h-0.1l-1.1-0.8l1.1-0.9h1.3l0.1,0.1l-0.1-0.7l-0.1,0.1h-1.3l-1.1-0.8l1.1-0.9h1.2l0-0.4h-1.2l-1.1-0.9
        l1-0.8h-0.8l-0.7,0.5h-1.3l-0.7-0.5H318l1,0.8l-1.1,0.9h-1.3l-1.1-0.9l1-0.8h-0.8L315,184h-1.3l-0.7-0.5h-0.8l1,0.8l-1.1,0.9h-1.3
        l-1.1-0.9l1-0.8h-0.8l-0.7,0.5h-1.3l-0.7-0.5l-0.7,0l1,0.7l-1.1,0.9H305L305,185.1z M307.9,184.5h1.3l1.1,0.9l-1.1,0.9h-1.3
        l-1.1-0.9L307.9,184.5L307.9,184.5z M312.6,185.3l1.1-0.9h1.3l1.1,0.9l-1.1,0.9h-1.3L312.6,185.3L312.6,185.3z M319.6,184.5h1.3
        l1.1,0.9l-1.1,0.9h-1.3l-1.1-0.9L319.6,184.5L319.6,184.5z M320.9,186.7l1.1,0.9l-1.1,0.8h-1.3l-1.1-0.8l1.1-0.9L320.9,186.7
        L320.9,186.7z M316.6,187.3l-1.1-0.8l1.1-0.9h1.3l1.1,0.9l-1.1,0.9H316.6L316.6,187.3z M316.6,189.5l-1.1-0.8l1.1-0.9h1.3l1.1,0.9
        l-1.1,0.8H316.6L316.6,189.5z M313.7,186.6l1.3,0l1.1,0.9l-1.1,0.8h-1.3l-1.1-0.8L313.7,186.6L313.7,186.6z M313.3,186.4l-1.1,0.9
        h-1.3l-1.1-0.8l1.1-0.9h1.3L313.3,186.4L313.3,186.4z M307.9,186.6l1.3,0l1.1,0.9l-1.1,0.8h-1.3l-1.1-0.8L307.9,186.6L307.9,186.6
        z"/>
      <polygon fill="none" points="309.2,184.5 307.9,184.5 306.8,185.3 307.9,186.2 309.2,186.2 310.3,185.3 309.2,184.5 		"/>
      <polygon fill="none" points="313.7,184.5 312.6,185.3 313.7,186.2 315,186.2 316.2,185.3 315,184.5 313.7,184.5 		"/>
      <polygon fill="none" points="320.9,184.5 319.6,184.5 318.4,185.3 319.6,186.2 320.9,186.2 322,185.3 320.9,184.5 		"/>
      <polygon fill="none" points="322,187.5 320.9,186.7 319.6,186.6 318.4,187.5 319.6,188.4 320.9,188.4 322,187.5 		"/>
      <polygon fill="none" points="315.5,186.5 316.6,187.3 317.9,187.3 319.1,186.4 317.9,185.6 316.7,185.6 315.5,186.5 		"/>
      <polygon fill="none" points="315.5,188.6 316.6,189.5 317.9,189.5 319,188.6 317.9,187.8 316.6,187.8 315.5,188.6 		"/>
      <polygon fill="none" points="315,186.7 313.7,186.6 312.6,187.5 313.7,188.4 315,188.4 316.1,187.5 315,186.7 		"/>
      <polygon fill="none" points="312.1,187.3 313.3,186.4 312.1,185.6 310.8,185.6 309.7,186.5 310.8,187.3 312.1,187.3 		"/>
      <polygon fill="none" points="309.2,186.7 307.9,186.6 306.8,187.5 307.9,188.4 309.2,188.4 310.3,187.5 309.2,186.7 		"/>
      <path fill="none" d="M304.9,185.1L304.9,185.1l1.4,0.1l1.1-0.9l-1-0.7C305.6,183.6,305,184.2,304.9,185.1L304.9,185.1z"/>
      <polygon fill="none" points="307.9,184 309.2,184 309.9,183.5 307.2,183.5 307.9,184 		"/>
      <polygon fill="none" points="309.7,184.3 310.8,185.1 312.1,185.1 313.2,184.3 312.2,183.5 310.7,183.5 309.7,184.3 		"/>
      <polygon fill="none" points="313,183.5 313.7,184 315,184 315.7,183.5 313,183.5 		"/>
      <polygon fill="none" points="316.6,185.1 317.9,185.1 319,184.3 318,183.5 316.5,183.5 315.5,184.3 316.6,185.1 		"/>
      <polygon fill="none" points="319.6,184 320.9,184 321.6,183.5 318.8,183.5 319.6,184 		"/>
      <path fill="none" d="M321.4,184.3l1.1,0.9h1.2l0-0.4c0-0.8-0.4-1.2-1.3-1.2L321.4,184.3L321.4,184.3z"/>
      <path fill="none" d="M324,188.5l-0.1-0.6l-0.1-0.1h-1.3l-1.1,0.9l1.1,0.8h0.1C323.5,189.3,324,189,324,188.5L324,188.5z"/>
      <polygon fill="none" points="323.9,187.2 323.7,185.6 322.5,185.6 321.4,186.5 322.5,187.3 323.8,187.3 323.9,187.2 		"/>
      <polygon fill="none" points="321.8,189.6 320.9,188.8 319.6,188.8 318.6,189.6 321.8,189.6 		"/>
      <polygon fill="none" points="312.2,189.4 313.2,188.6 312.1,187.8 310.8,187.8 309.7,188.6 310.7,189.4 312.2,189.4 		"/>
      <polygon fill="none" points="315,188.8 313.7,188.8 312.9,189.5 316,189.6 315,188.8 		"/>
      <polygon fill="none" points="309.2,188.8 307.9,188.8 307.5,189.2 309.9,189.3 309.2,188.8 		"/>
      <polygon fill="none" points="306.3,187.3 307.4,186.4 306.3,185.6 305,185.6 304.8,185.7 304.6,187 305,187.3 306.3,187.3 		"/>
      <path fill="none" d="M306.7,189.1l0.7-0.5l-1.1-0.9H305l-0.4,0.3c0.2,0.6,0.9,0.9,2,1L306.7,189.1L306.7,189.1z"/>
      <path fill="none" d="M291.6,153.7c1.4,7.3,2.5,15.5,3.3,24.6c10.8-0.9,21.8-1.2,33.1-0.9l0.9-9.5c0-0.5,0.1-1.1,0.2-1.6
        c0.4-1.3,1.1-2.5,2.2-3.6c2-2.4,3.6-3.7,4.8-3.8h9.2h77.6h9.2c1.2,0.1,2.9,1.4,4.8,3.8c1.1,1.1,1.8,2.3,2.2,3.6
        c0.1,0.5,0.2,1.1,0.2,1.6l0.9,9.5c11.3-0.3,22.3,0,33.1,0.9c0.8-9.1,1.9-17.3,3.3-24.6c0.5-2.9,0.9-5.7,1.4-8.4
        c-3.3,1.9-10.4,2.9-21.3,3.2H311.6c-11-0.2-18.1-1.2-21.3-3.2L291.6,153.7L291.6,153.7z M320.7,159.1c0,2-0.7,3.7-2.1,5.2
        c-1.4,1.4-3.1,2.1-5.2,2.1c-2,0-3.7-0.7-5.2-2.1c-1.4-1.4-2.1-3.2-2.1-5.2c0-2,0.7-3.7,2.1-5.2c1.4-1.4,3.1-2.1,5.2-2.1
        c2,0,3.7,0.7,5.2,2.1C320,155.3,320.7,157,320.7,159.1L320.7,159.1z M462.2,159.1c0,2-0.7,3.7-2.1,5.2c-1.4,1.4-3.2,2.1-5.2,2.1
        c-2,0-3.7-0.7-5.2-2.1c-1.4-1.4-2.1-3.2-2.1-5.2c0-2,0.7-3.7,2.1-5.2c1.4-1.4,3.2-2.1,5.2-2.1c2,0,3.7,0.7,5.2,2.1
        C461.5,155.3,462.2,157,462.2,159.1L462.2,159.1z"/>
      <path fill="none" d="M318.5,164.3c1.4-1.4,2.1-3.2,2.1-5.2c0-2-0.7-3.7-2.1-5.2c-1.4-1.4-3.1-2.1-5.2-2.1c-2,0-3.7,0.7-5.2,2.1
        c-1.4,1.4-2.1,3.2-2.1,5.2c0,2,0.7,3.7,2.1,5.2c1.4,1.4,3.1,2.1,5.2,2.1C315.4,166.4,317.1,165.7,318.5,164.3L318.5,164.3z
         M319.2,159.1c0,1.6-0.6,3-1.7,4.1c-1.1,1.1-2.5,1.7-4.2,1.7c-1.6,0-3-0.6-4.1-1.7c-1.1-1.1-1.7-2.5-1.7-4.1c0-1.6,0.6-3,1.7-4.2
        c1.1-1.1,2.5-1.7,4.1-1.7c1.6,0,3,0.6,4.2,1.7C318.7,156.1,319.2,157.5,319.2,159.1L319.2,159.1z"/>
      <path fill="none" d="M317.5,163.2c1.1-1.1,1.7-2.5,1.7-4.1c0-1.6-0.6-3-1.7-4.2c-1.1-1.1-2.5-1.7-4.2-1.7c-1.6,0-3,0.6-4.1,1.7
        c-1.1,1.1-1.7,2.5-1.7,4.2c0,1.6,0.6,3,1.7,4.1c1.1,1.1,2.5,1.7,4.1,1.7C315,164.9,316.4,164.4,317.5,163.2L317.5,163.2z
         M317.5,159.1c0,1.1-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2c-1.1,0-2.1-0.4-2.9-1.2c-0.8-0.8-1.2-1.8-1.2-2.9
        c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.1,0.4,2.9,1.2C317.1,157,317.5,157.9,317.5,159.1L317.5,159.1z"/>
      <path fill="none" d="M316.3,162c0.8-0.8,1.2-1.8,1.2-2.9c0-1.1-0.4-2.1-1.2-2.9c-0.8-0.8-1.8-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.9,1.2
        c-0.8,0.8-1.2,1.8-1.2,2.9c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2C314.5,163.2,315.5,162.8,316.3,162L316.3,162z
         M316.7,159.1c0,0.9-0.3,1.7-1,2.3c-0.7,0.7-1.4,1-2.3,1c-0.9,0-1.7-0.3-2.3-1c-0.7-0.6-1-1.4-1-2.3c0-0.9,0.3-1.7,1-2.3
        c0.6-0.6,1.4-1,2.3-1c0.9,0,1.7,0.3,2.3,1C316.3,157.4,316.7,158.1,316.7,159.1L316.7,159.1z"/>
      <path fill="none" d="M315.7,161.4c0.6-0.6,1-1.4,1-2.3c0-0.9-0.3-1.7-1-2.3c-0.7-0.6-1.4-1-2.3-1c-0.9,0-1.7,0.3-2.3,1
        c-0.7,0.7-1,1.4-1,2.3c0,0.9,0.3,1.7,1,2.3c0.6,0.7,1.4,1,2.3,1C314.3,162.3,315.1,162,315.7,161.4L315.7,161.4z M313.4,156.8
        c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.2-0.2-1.6-0.7
        c-0.4-0.4-0.7-1-0.7-1.6c0-0.6,0.2-1.2,0.7-1.6C312.2,157,312.7,156.8,313.4,156.8L313.4,156.8z"/>
      <path fill="none" d="M315,157.4c-0.4-0.4-1-0.7-1.6-0.7c-0.6,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.6
        c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6C315.7,158.4,315.4,157.9,315,157.4L315,157.4z M313.9,158.6
        c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
        c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2C313.6,158.3,313.7,158.4,313.9,158.6L313.9,158.6z"/>
      <path fill="none" d="M314.1,159c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
        c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2C314,159.4,314.1,159.2,314.1,159L314.1,159z"/>
      <path fill="none" d="M460,164.3c1.4-1.4,2.1-3.2,2.1-5.2c0-2-0.7-3.7-2.1-5.2c-1.4-1.4-3.2-2.1-5.2-2.1c-2,0-3.7,0.7-5.2,2.1
        c-1.4,1.4-2.1,3.2-2.1,5.2c0,2,0.7,3.7,2.1,5.2c1.4,1.4,3.2,2.1,5.2,2.1C456.9,166.4,458.6,165.7,460,164.3L460,164.3z
         M460.7,159.1c0,1.6-0.6,3-1.7,4.1c-1.1,1.1-2.5,1.7-4.1,1.7c-1.6,0-3-0.6-4.2-1.7c-1.1-1.1-1.7-2.5-1.7-4.1c0-1.6,0.6-3,1.7-4.2
        c1.1-1.1,2.5-1.7,4.2-1.7c1.6,0,3,0.6,4.1,1.7C460.1,156.1,460.7,157.5,460.7,159.1L460.7,159.1z"/>
      <path fill="none" d="M459,163.2c1.1-1.1,1.7-2.5,1.7-4.1c0-1.6-0.6-3-1.7-4.2c-1.1-1.1-2.5-1.7-4.1-1.7c-1.6,0-3,0.6-4.2,1.7
        c-1.1,1.1-1.7,2.5-1.7,4.2c0,1.6,0.6,3,1.7,4.1c1.1,1.1,2.5,1.7,4.2,1.7C456.5,164.9,457.8,164.4,459,163.2L459,163.2z M454.8,155
        c1.1,0,2.1,0.4,2.9,1.2c0.8,0.8,1.2,1.8,1.2,2.9c0,1.1-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2c-1.1,0-2.1-0.4-2.9-1.2
        c-0.8-0.8-1.2-1.8-1.2-2.9c0-1.1,0.4-2.1,1.2-2.9C452.7,155.4,453.7,155,454.8,155L454.8,155z"/>
      <path fill="none" d="M457.7,156.2c-0.8-0.8-1.8-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,2.9
        c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2c1.1,0,2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9
        C458.9,157.9,458.5,157,457.7,156.2L457.7,156.2z M454.8,155.8c0.9,0,1.7,0.3,2.3,1c0.7,0.7,1,1.4,1,2.3c0,0.9-0.3,1.7-1,2.3
        c-0.6,0.7-1.4,1-2.3,1c-0.9,0-1.7-0.3-2.3-1c-0.6-0.6-1-1.4-1-2.3c0-0.9,0.3-1.7,1-2.3C453.1,156.1,453.9,155.8,454.8,155.8
        L454.8,155.8z"/>
      <path fill="none" d="M457.1,156.7c-0.6-0.6-1.4-1-2.3-1c-0.9,0-1.7,0.3-2.3,1c-0.6,0.7-1,1.4-1,2.3c0,0.9,0.3,1.7,1,2.3
        c0.7,0.7,1.4,1,2.3,1c0.9,0,1.7-0.3,2.3-1c0.7-0.6,1-1.4,1-2.3C458.1,158.1,457.8,157.4,457.1,156.7L457.1,156.7z M453.2,157.4
        c0.4-0.4,1-0.7,1.6-0.7c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7
        c-0.6,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6C452.5,158.4,452.8,157.9,453.2,157.4L453.2,157.4z"/>
      <path fill="none" d="M454.8,156.8c-0.6,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7
        c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-0.6-0.2-1.2-0.7-1.6C456,157,455.5,156.8,454.8,156.8L454.8,156.8z M454.8,158.3
        c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2
        c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5C454.5,158.4,454.6,158.3,454.8,158.3L454.8,158.3z"/>
      <path fill="none" d="M455.3,158.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
        c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
        C455.5,158.9,455.5,158.7,455.3,158.6L455.3,158.6z"/>
      <polygon fill="none" points="328,177.5 328,182.4 440.2,182.4 440.2,177.5 328,177.5 		"/>
      <polygon fill="none" points="328.8,168 328,177.5 440.2,177.5 439.4,168 328.8,168 		"/>
      <path fill="none" d="M329.1,166.4c-0.1,0.5-0.2,1.1-0.2,1.6h110.6c0-0.5-0.1-1.1-0.2-1.6h-16.2v-0.6h-77.6v0.6H329.1L329.1,166.4z
        "/>
      <polygon fill="none" points="328,189.7 329.6,195.3 357.1,195.3 411.1,195.3 438.6,195.3 440.2,189.7 328,189.7 		"/>
      <path fill="none" d="M301.9,193.8c-0.7,3.7-0.2,6,1.5,6.9l4.5,0.7l18.1,0.7H349c1.4-0.1,2.9-1,4.5-2.5c1.1-1.1,2.3-2.5,3.6-4.3
        h-27.4C317.6,195.7,308.3,195.2,301.9,193.8L301.9,193.8z"/>
      <polygon fill="none" points="307.8,201.4 307.8,206.1 340.5,206.1 363.8,206.1 404.4,206.1 427.7,206.1 460.4,206.1 460.4,201.4 
        442.3,202.1 419.2,202.1 349,202.1 325.9,202.1 307.8,201.4 		"/>
      <polygon fill="none" points="328,182.4 328,189.7 440.2,189.7 440.2,182.4 328,182.4 		"/>
      <path fill="none" d="M488.1,115c0-2.5-0.4-3.6-1.2-3.3c-3.3,2.1-6.3,3.2-8.9,3.4l-2.6,0.3c1.5,1,2.5,2.1,2.7,3.4
        C481.2,118.2,484.6,117,488.1,115L488.1,115z"/>
      <path fill="none" d="M485.7,122.4h-2.4c-1.8,5.6-3.6,13.2-5.3,23c-0.5,2.6-0.9,5.4-1.4,8.4c-1.4,7.3-2.5,15.5-3.3,24.6l11.3-1.5
        c1.5-8.7,3-17.2,4.6-25.3c0.8-4.2,1.7-8.3,2.5-12.4l0.4-1.9c0.5-1.2,0.8-1.1,0.7,0.5c0.2-2.8,0.1-5.1-0.4-6.8
        c-3.2,13.3-6.2,28.6-9.1,45.9c2.9-17.3,5.9-32.6,9.1-45.9l-1.4-2.4C489.4,125.9,487.6,123.8,485.7,122.4L485.7,122.4z"/>
      <path fill="none" d="M494.6,129.8l-4.1-6.4l0.6,5.2l1.4,2.4c0.5,1.7,0.7,4,0.4,6.8c0,0.9-0.1,2.3-0.4,4.1
        c-0.6,10.8-1.7,20.3-3.3,28.6v5.4c1.7-6.3,3.2-14,4.3-23.1C494.9,142.7,495.3,135,494.6,129.8L494.6,129.8z"/>
      <path fill="none" d="M492.1,137.3l-0.4,1.9c-0.9,4-1.7,8.2-2.5,12.4v18.9c1.6-8.3,2.7-17.8,3.3-28.6c0.2-1.9,0.4-3.2,0.4-4.1
        C492.9,136.2,492.7,136,492.1,137.3L492.1,137.3z"/>
      <path fill="none" d="M478,118.9c-0.3-1.3-1.2-2.5-2.7-3.4l-8,0.3l-22.7,0.2c-0.2-0.1-0.5-0.1-0.7,0l-2.2,0l0,6.7l1.8-0.4
        c0-1.9-0.2-3.5-0.5-4.7c-0.1-0.2-0.1-0.4,0-0.6l2.6,0l20.4-0.1l2.6,0h5.1c0.8,0.3,1.4,1.1,1.8,2.6l1.4-0.3L478,118.9L478,118.9z"
        />
      <path fill="none" d="M478.1,119.4l-0.1-0.6l-1.4,0.3c0.6,6.1,0.8,13,0.8,20.6c-0.3,0.7-0.9,0.9-1.8,0.7c-0.3,0.2-0.7,0.3-1.3,0.3
        l-7.6,0.1l-10.7,0.1l-12.5,0.1c-1.1-0.1-1.8-0.5-2-1.2v3.2l34.3-0.4c1.3,0,2.1-0.3,2.4-0.8L478.1,119.4L478.1,119.4z"/>
      <path fill="none" d="M476.7,119.1l-1.4,0.3c0.3,3.4,0.4,7.1,0.6,11.2l0.1,3.5l0,5.4c0.1,0.4,0,0.7-0.3,1c0.9,0.2,1.5,0,1.8-0.7
        C477.5,132.1,477.2,125.3,476.7,119.1L476.7,119.1z"/>
      <path fill="none" d="M473.5,116.8h-5.1l0,0.2c0.2,4.4,0,8-0.4,10.9c-0.1,1-0.4,1.9-0.9,2.7h8.6c-0.1-4.1-0.3-7.8-0.6-11.2
        C474.9,118,474.3,117.1,473.5,116.8L473.5,116.8z M472.3,123.5c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        C472,123.6,472.1,123.5,472.3,123.5L472.3,123.5z"/>
      <path fill="none" d="M472.7,123.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        C472.9,124,472.8,123.8,472.7,123.7L472.7,123.7z"/>
      <path fill="none" d="M465.9,116.8l0,0.3c0.1,2.7,0.1,5,0,7.1l-0.3,3.3c-0.1,0.4-0.1,0.8-0.3,1.1h2.7c0.1-0.2,0.1-0.5,0.1-0.7
        c0.4-2.9,0.6-6.5,0.4-10.9l0-0.2L465.9,116.8L465.9,116.8z M466.9,121c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        c0.1-0.1,0.3-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        S467,121.1,466.9,121L466.9,121z M466.9,126.7c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        C466.6,126.7,466.7,126.7,466.9,126.7L466.9,126.7z"/>
      <path fill="none" d="M443,117c-0.1,0.2,0,0.4,0,0.6c0.3,1.2,0.5,2.8,0.5,4.7l-1.8,0.4c-1.2,0.4-2.3,1.2-3.2,2.1
        c-1.1,1.1-1.9,2.3-2.3,3.7h2.8c0.3-0.7,0.7-1.3,1.3-1.9c0.4-0.4,0.9-0.8,1.4-1.1c0.2-0.1,0.4-0.2,0.7-0.3c0.7-0.3,1.5-0.5,2.4-0.5
        h0.1c1.8,0,3.3,0.6,4.6,1.9l0.6,0.6c0.3,0.4,0.6,0.8,0.8,1.2h2.8c-0.3-0.5-0.6-1-0.7-1.6c-0.4-0.7-0.8-1.3-1.4-1.9l-0.9-0.8
        l-0.6-0.4c-1.2-0.9-2.5-1.4-4-1.6c0-2.1-0.2-3.9-0.6-5.4L443,117L443,117z M438.2,126.6c0-0.2,0.1-0.3,0.2-0.4
        c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0-0.3-0.1-0.4-0.2C438.3,127,438.2,126.8,438.2,126.6L438.2,126.6z M445.7,122.9c0,0.2-0.1,0.3-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.4,0.2C445.6,122.6,445.7,122.7,445.7,122.9L445.7,122.9z"/>
      <path fill="none" d="M451.4,130.5l0.1,0.9l-0.1,1.3v0l0,0.1v0l0,0c-0.3,1.2-0.9,2.2-1.8,3.1c-1.3,1.2-2.8,1.9-4.6,1.9
        c-0.9,0-1.8-0.2-2.5-0.5l-0.7-0.3c-0.5-0.3-1-0.7-1.4-1.1c-1.3-1.3-1.9-2.8-1.9-4.6c0-1,0.2-1.9,0.6-2.7h-2.8
        c-0.3,0.9-0.4,1.8-0.4,2.7c0,0.4,0,0.7,0.1,1l0.2,1.1c0.4,1.6,1.2,3.1,2.4,4.3c1,1,2.1,1.7,3.2,2.1c1,0.4,2,0.5,3.2,0.5
        c2.1,0,4-0.6,5.6-1.9l0.8-0.7c1-1,1.7-2.1,2.2-3.4c3.1,0.1,5.9,0,8.5-0.4h0.1c2.5-0.5,4.2-1.6,5.2-3.4c0.3-0.6,0.6-1.2,0.7-2h-2.7
        c-0.4,0.9-0.9,1.6-1.7,2.1c-0.5,0.4-1.2,0.6-1.9,0.7l-0.1,0l-0.5,0.1l-5.4,0.3l-1.6,0V131c0-0.8-0.2-1.6-0.4-2.3c0,0,0,0,0,0h-2.8
        C451.1,129.2,451.3,129.8,451.4,130.5L451.4,130.5z M436.6,131.7c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        C436.7,132,436.6,131.8,436.6,131.7L436.6,131.7z M439.8,136.7c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
        C439.7,136.4,439.8,136.5,439.8,136.7L439.8,136.7z M450.9,136.4c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        C450.6,136.3,450.8,136.3,450.9,136.4L450.9,136.4z M444.4,138.7c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        C444.3,139,444.3,138.8,444.4,138.7L444.4,138.7z M463,131.7c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        C462.7,131.8,462.9,131.7,463,131.7L463,131.7z M454.7,132.6c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        C454.5,132.9,454.6,132.8,454.7,132.6L454.7,132.6z"/>
      <path fill="none" d="M467.4,120c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2
        s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C467.7,120,467.5,120,467.4,120L467.4,120z"/>
      <path fill="none" d="M466.3,127.3c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2C466.3,127,466.3,127.1,466.3,127.3L466.3,127.3z"/>
      <path fill="none" d="M462.6,131.9c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
        c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2C462.9,131.7,462.7,131.8,462.6,131.9L462.6,131.9z"/>
      <path fill="none" d="M455.7,133.1c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
        C455.7,133.4,455.7,133.2,455.7,133.1L455.7,133.1z"/>
      <path fill="none" d="M451.4,131.4l-0.1-0.9c-0.1-1.1-0.6-2.1-1.3-3.1l-0.6-0.6c-1.3-1.3-2.8-1.9-4.6-1.9h-0.1
        c-0.9,0-1.7,0.2-2.4,0.5c-0.2,0.1-0.4,0.2-0.7,0.3c-0.5,0.3-1,0.7-1.4,1.1c-1.3,1.3-1.9,2.8-1.9,4.6c0,1.8,0.6,3.3,1.9,4.6
        c0.4,0.4,0.9,0.8,1.4,1.1l0.7,0.3c0.8,0.3,1.6,0.5,2.5,0.5c1.8,0,3.3-0.6,4.6-1.9c0.9-0.9,1.5-1.9,1.8-3.1l0,0v0l0-0.1v0
        L451.4,131.4L451.4,131.4z M441,127.4c0.4-0.4,0.8-0.7,1.2-0.9l0.6-0.3c0.6-0.3,1.3-0.4,2-0.4h0.1c1.5,0,2.8,0.5,3.9,1.6
        c1.1,1.1,1.6,2.4,1.6,3.9l-0.1,1.2v0l0,0v0l0,0c-0.2,1-0.7,1.9-1.5,2.6c-1.1,1.1-2.4,1.6-3.9,1.6c-0.8,0-1.5-0.1-2.2-0.4l-0.6-0.3
        l-1.2-0.9c-1.1-1.1-1.6-2.4-1.6-3.9C439.4,129.8,439.9,128.5,441,127.4L441,127.4z"/>
      <path fill="none" d="M442.2,126.5c-0.4,0.2-0.8,0.6-1.2,0.9c-1.1,1.1-1.6,2.4-1.6,3.9c0,1.5,0.5,2.8,1.6,3.9l1.2,0.9l0.6,0.3
        c0.7,0.3,1.4,0.4,2.2,0.4c1.5,0,2.8-0.5,3.9-1.6c0.8-0.8,1.3-1.7,1.5-2.6l0,0v0l0,0v0l0.1-1.2c0-1.5-0.5-2.8-1.6-3.9
        c-1.1-1.1-2.4-1.6-3.9-1.6h-0.1c-0.7,0-1.4,0.1-2,0.4L442.2,126.5L442.2,126.5z M446,131.6c-0.1,0.2-0.1,0.4-0.3,0.5
        c-0.2,0.2-0.5,0.3-0.8,0.3l-0.4-0.1l-0.1-0.1l-0.2-0.2c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8l0.2-0.2l0.1-0.1l0.4-0.1h0
        c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8L446,131.6L446,131.6L446,131.6z"/>
      <path fill="none" d="M445.7,132.1c0.1-0.2,0.2-0.3,0.3-0.5v0l0-0.2c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3h0l-0.4,0.1
        l-0.1,0.1l-0.2,0.2c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8l0.2,0.2l0.1,0.1l0.4,0.1C445.2,132.5,445.5,132.3,445.7,132.1
        L445.7,132.1z"/>
      <path fill="none" d="M438.8,126c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2
        c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C439.1,126.1,439,126,438.8,126L438.8,126z"/>
      <path fill="none" d="M445.7,122.9c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
        C445.6,123.2,445.7,123.1,445.7,122.9L445.7,122.9z"/>
      <path fill="none" d="M445.3,138.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        C445.5,139,445.4,138.8,445.3,138.7L445.3,138.7z"/>
      <path fill="none" d="M450.5,136.3c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        C450.8,136.3,450.6,136.3,450.5,136.3L450.5,136.3z"/>
      <path fill="none" d="M437.2,132.3c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4C436.9,132.2,437.1,132.3,437.2,132.3L437.2,132.3z"/>
      <path fill="none" d="M439.2,136.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        C439.5,136.1,439.4,136.1,439.2,136.1L439.2,136.1z"/>
      <path fill="none" d="M465.9,117.2l0-0.3l-20.4,0.1c0.4,1.4,0.6,3.2,0.6,5.3c1.5,0.2,2.8,0.7,4,1.6c0.3-1.4,1-2.6,2.1-3.7
        c1.6-1.6,3.5-2.3,5.7-2.3c2.2,0,4.1,0.8,5.7,2.3c1.2,1.2,1.9,2.5,2.2,4.1C466,122.2,466,119.8,465.9,117.2L465.9,117.2z"/>
      <path fill="none" d="M452.7,120.6c1.4-1.4,3.2-2.2,5.2-2.2c2,0,3.8,0.7,5.2,2.2c1.4,1.4,2.2,3.2,2.2,5.2c0,1.9-0.6,3.5-1.8,4.8
        c1.1-0.7,1.7-1.8,2-3.2l0.3-3.3c-0.3-1.5-1-2.9-2.2-4.1c-1.6-1.6-3.5-2.3-5.7-2.3c-2.2,0-4.1,0.8-5.7,2.3
        c-1.1,1.1-1.8,2.3-2.1,3.7l0.6,0.4C451,122.9,451.7,121.7,452.7,120.6L452.7,120.6z"/>
      <path fill="none" d="M458,118.5c-2,0-3.8,0.7-5.2,2.2c-1.1,1.1-1.7,2.3-2,3.7l0.9,0.8c0.1-1.5,0.8-2.8,1.8-3.8
        c1.2-1.2,2.8-1.9,4.5-1.9c1.8,0,3.3,0.6,4.5,1.9c1.2,1.2,1.9,2.8,1.9,4.5c0,1.8-0.6,3.3-1.9,4.5l-1.5,1.1l0.5-0.1l0.1,0
        c0.7-0.1,1.4-0.4,1.9-0.7c1.2-1.4,1.8-3,1.8-4.8c0-2.1-0.7-3.8-2.2-5.2C461.8,119.2,460,118.5,458,118.5L458,118.5z"/>
      <path fill="none" d="M453.4,121.3c-1.1,1.1-1.7,2.4-1.8,3.8c0.5,0.6,1,1.2,1.4,1.9c-0.1-0.4-0.1-0.8-0.1-1.2
        c0-1.4,0.5-2.6,1.5-3.6c1-1,2.2-1.5,3.6-1.5c1.4,0,2.6,0.5,3.6,1.5c1,1,1.5,2.2,1.5,3.6c0,1.4-0.5,2.6-1.5,3.6
        c-1,1-2.2,1.5-3.6,1.5c-1.4,0-2.6-0.5-3.6-1.5l-0.7-0.9c0.2,0.7,0.3,1.5,0.4,2.3c0.5,0.4,1,0.7,1.6,0.9l5.4-0.3l1.5-1.1
        c1.2-1.2,1.9-2.8,1.9-4.5c0-1.8-0.6-3.3-1.9-4.5c-1.2-1.2-2.8-1.9-4.5-1.9C456.2,119.4,454.7,120.1,453.4,121.3L453.4,121.3z"/>
      <path fill="none" d="M453,127c0.1,0.6,0.4,1.1,0.7,1.6C453.5,128.1,453.2,127.5,453,127L453,127z"/>
      <path fill="none" d="M453.6,128.7l0.7,0.9c1,1,2.2,1.5,3.6,1.5c1.4,0,2.6-0.5,3.6-1.5c1-1,1.5-2.2,1.5-3.6c0-1.4-0.5-2.6-1.5-3.6
        c-1-1-2.2-1.5-3.6-1.5c-1.4,0-2.6,0.5-3.6,1.5c-1,1-1.5,2.2-1.5,3.6c0,0.4,0,0.8,0.1,1.2C453.2,127.5,453.5,128.1,453.6,128.7
        L453.6,128.7z M454.7,129.2c-0.9-0.9-1.4-2-1.4-3.3c0-1.3,0.4-2.4,1.4-3.3c0.9-0.9,2-1.4,3.3-1.4c1.3,0,2.4,0.4,3.3,1.4
        c0.9,0.9,1.4,2,1.4,3.3c0,1.3-0.4,2.4-1.4,3.3c-0.9,0.9-2,1.4-3.3,1.4C456.7,130.5,455.6,130.1,454.7,129.2L454.7,129.2z"/>
      <path fill="none" d="M453.3,125.9c0,1.3,0.4,2.4,1.4,3.3c0.9,0.9,2,1.4,3.3,1.4c1.3,0,2.4-0.4,3.3-1.4c0.9-0.9,1.4-2,1.4-3.3
        c0-1.3-0.4-2.4-1.4-3.3c-0.9-0.9-2-1.4-3.3-1.4c-1.3,0-2.4,0.4-3.3,1.4C453.8,123.5,453.3,124.6,453.3,125.9L453.3,125.9z
         M456.8,124.7c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2
        c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2C456.3,125.4,456.4,125,456.8,124.7L456.8,124.7z"/>
      <path fill="none" d="M458,124.2c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
        c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2C458.8,124.4,458.4,124.2,458,124.2L458,124.2z"/>
      <path fill="none" d="M465.5,134.3l0.6-0.6c0.7-0.7,3.8-1,9.1-0.8c0.4,0.1,0.7,0.5,0.7,1.3l-0.1-3.5h-8.6c-1,1.8-2.7,2.9-5.2,3.4
        H462c-2.6,0.4-5.4,0.5-8.5,0.4c-0.4,1.2-1.2,2.4-2.2,3.4l-0.8,0.7c-1.6,1.3-3.5,1.9-5.6,1.9c-1.1,0-2.2-0.2-3.2-0.5
        c0.2,0.8,0.8,1.2,2,1.2l12.5-0.1c-2,0-2.4-1.3-1.1-4c0.5-1.3,1.6-2.1,3.4-2.1h4.7C464,135,464.8,134.7,465.5,134.3L465.5,134.3z"
        />
      <path fill="none" d="M466.1,133.7l-0.6,0.6c2.5,1.7,2.9,3.9,1.3,6.6l7.6-0.1c0.6,0,1-0.1,1.3-0.3c0.3-0.2,0.4-0.5,0.3-1l0-5.4
        c0-0.7-0.3-1.1-0.7-1.3C469.9,132.7,466.8,133,466.1,133.7L466.1,133.7z M471.9,137.6c-0.2,0-0.3-0.1-0.4-0.2
        c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.2-0.1,0.3-0.2,0.4C472.2,137.5,472.1,137.6,471.9,137.6L471.9,137.6z"/>
      <path fill="none" d="M471.5,137.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4C471.3,137.2,471.4,137.3,471.5,137.4L471.5,137.4z"/>
      <path fill="none" d="M465.5,134.3c-0.7,0.5-1.5,0.7-2.4,0.6h-4.7c-1.7,0.1-2.8,0.8-3.4,2.1c-1.3,2.6-1,3.9,1.1,4l10.7-0.1
        C468.4,138.1,468,135.9,465.5,134.3L465.5,134.3z M461.8,137.5c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
        c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        C461.6,137.7,461.6,137.6,461.8,137.5L461.8,137.5z"/>
      <path fill="none" d="M462.2,137.3c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        C462.5,137.4,462.4,137.3,462.2,137.3L462.2,137.3z"/>
      <path fill="none" d="M454,131v0.9l1.6,0C455,131.7,454.5,131.4,454,131L454,131z"/>
      <path fill="none" d="M478.2,181c-1.6,0.5-3.3,0.7-5.1,0.7l-0.2,1.9l5.4-0.8L478.2,181L478.2,181z"/>
      <path fill="none" d="M473.1,181.7c1.8,0.1,3.5-0.2,5.1-0.7c2.3-0.8,4.5-2.2,6.4-4.2l-11.3,1.5L473.1,181.7L473.1,181.7
        L473.1,181.7z"/>
      <path fill="none" d="M478.2,182.8l-5.4,0.8l0.2-1.9l0-0.1l0.3-3.3c-10.8-0.9-21.8-1.2-33.1-0.9v4.9v7.3l-1.7,5.6
        c12.1,0.4,21.3-0.1,27.8-1.5l2.3-0.6c3.2-1.2,6.2-3.9,9-8.2C477.9,184.4,478.1,183.6,478.2,182.8L478.2,182.8z M462.9,182.9
        c0.8,0.2,1.3,0.8,1.4,1.8l0.5,3.6v0.1l-0.1,0.5c-0.2,0.4-0.5,0.8-1,1l-0.5,0.2l-5.3,0.4l-11.6,0.2c-1.1,0-1.9-0.1-2.4-0.4
        l-0.4-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v0l0.4-5.3c0.1-0.5,0.4-0.8,0.8-1l1-0.1h16.9L462.9,182.9L462.9,182.9z"/>
      <path fill="none" d="M464.3,184.7c-0.1-1-0.6-1.5-1.4-1.8l-0.7-0.1h-16.9l-1,0.1c-0.4,0.2-0.7,0.5-0.8,1l-0.4,5.3v0
        c0,0.3,0.1,0.5,0.3,0.7l0.4,0.3c0.5,0.3,1.3,0.4,2.4,0.4l11.6-0.2l5.3-0.4l0.5-0.2c0.5-0.2,0.9-0.6,1-1l0.1-0.5v-0.1L464.3,184.7
        L464.3,184.7z M451.5,183.5h0.8h2.7h0.8h1.5h0.8h2.7l0.8,0c1,0.1,1.5,0.6,1.7,1.4l0.2,0.9l0.1,1l0.1,0.6l0,0.3v0.1l-0.1,0.5
        c-0.2,0.5-0.9,0.8-2,0.9l-0.3,0l-0.7,0.1l-2.4,0.1l-0.9,0l-1.4,0.1l-0.7,0l-3.1,0.1l-2.6,0l-3.2,0l-0.7-0.1h0
        c-0.9-0.2-1.3-0.5-1.3-1l0.1-0.7l0-0.5l0.2-1.7l0-0.4l0-0.4c0-0.7,0.4-1.1,1.1-1.2l0.8,0h2.7h0.8H451.5L451.5,183.5z"/>
      <path fill="none" d="M451.5,183.5l1,0.8l-1.1,0.9h-1.3l-1.1-0.9l1-0.8h-0.8l-0.7,0.5h-1.3l-0.7-0.5l-0.8,0l1,0.8l-1.1,0.9h-1
        l0,0.4h1.1l1.1,0.9l-1.1,0.9h-1.2l0,0.5h1.2l1.1,0.9l-1.1,0.8l0.7,0.1l0.9-0.7h1.3l1,0.7l2.6,0l0.9-0.7h1.3l0.8,0.6l0.7,0
        l-1.1-0.8l1.1-0.9h1.3l1.1,0.9l-1,0.8l0.9,0l0.7-0.5h1.3l0.4,0.4l0.7-0.1l-0.7-0.5l1.1-0.9h1.3l0.6,0.4l0.1-0.5v-0.1l-0.2-0.1
        l0.2-0.1l-0.1-0.6l-0.6,0.4h-1.3l-1.1-0.8l1.1-0.9h1.3l0.4,0.3l-0.2-0.9l-0.2,0.2h-1.3l-1.1-0.9l1-0.8l-0.8,0l-0.7,0.5h-1.3
        l-0.7-0.5h-0.8l1,0.8l-1.1,0.9h-1.3l-1.1-0.9l1-0.8H455l-0.7,0.5H453l-0.7-0.5H451.5L451.5,183.5z M453,184.5h1.3l1.1,0.9
        l-1.1,0.9H453l-1.1-0.9L453,184.5L453,184.5z M458.8,184.5h1.3l1.1,0.9l-1.1,0.9h-1.3l-1.1-0.9L458.8,184.5L458.8,184.5z
         M458.8,186.6l1.3,0l1.1,0.9l-1.1,0.8h-1.3l-1.1-0.8L458.8,186.6L458.8,186.6z M457.2,185.6l1.1,0.9l-1.1,0.9h-1.3l-1.1-0.8
        l1.1-0.9H457.2L457.2,185.6z M453,186.6l1.3,0l1.1,0.9l-1.1,0.8H453l-1.1-0.8L453,186.6L453,186.6z M451.4,185.6l1.1,0.9l-1.1,0.9
        h-1.3l-1.1-0.8l1.1-0.9H451.4L451.4,185.6z M452.5,188.6l-1.1,0.8h-1.3l-1.1-0.8l1.1-0.9h1.3L452.5,188.6L452.5,188.6z M446,185.3
        l1.1-0.9h1.3l1.1,0.9l-1.1,0.9h-1.3L446,185.3L446,185.3z M448.4,186.7l1.1,0.9l-1.1,0.8h-1.3l-1.1-0.8l1.1-0.9L448.4,186.7
        L448.4,186.7z"/>
      <polygon fill="none" points="454.3,184.5 453,184.5 451.9,185.3 453,186.2 454.3,186.2 455.4,185.3 454.3,184.5 		"/>
      <polygon fill="none" points="460.1,184.5 458.8,184.5 457.7,185.3 458.8,186.2 460.1,186.2 461.2,185.3 460.1,184.5 		"/>
      <polygon fill="none" points="460.1,186.7 458.8,186.6 457.7,187.5 458.8,188.4 460.1,188.4 461.2,187.5 460.1,186.7 		"/>
      <polygon fill="none" points="458.3,186.4 457.2,185.6 455.9,185.6 454.8,186.5 455.9,187.3 457.2,187.3 458.3,186.4 		"/>
      <polygon fill="none" points="454.3,186.7 453,186.6 451.9,187.5 453,188.4 454.3,188.4 455.4,187.5 454.3,186.7 		"/>
      <polygon fill="none" points="452.5,186.4 451.4,185.6 450.1,185.6 449,186.5 450.1,187.3 451.4,187.3 452.5,186.4 		"/>
      <polygon fill="none" points="451.4,189.5 452.5,188.6 451.4,187.8 450.1,187.8 449,188.6 450.1,189.5 451.4,189.5 		"/>
      <polygon fill="none" points="447.1,184.5 446,185.3 447.2,186.2 448.5,186.2 449.6,185.3 448.5,184.5 447.1,184.5 		"/>
      <polygon fill="none" points="449.6,187.5 448.4,186.7 447.2,186.6 446,187.5 447.1,188.4 448.4,188.4 449.6,187.5 		"/>
      <polygon fill="none" points="452.5,184.3 451.5,183.5 450,183.5 449,184.3 450.1,185.1 451.4,185.1 452.5,184.3 		"/>
      <polygon fill="none" points="453,184 454.3,184 455,183.5 452.3,183.5 453,184 		"/>
      <polygon fill="none" points="455.9,185.1 457.2,185.1 458.3,184.3 457.3,183.5 455.8,183.5 454.8,184.3 455.9,185.1 		"/>
      <path fill="none" d="M461.6,183.5l-1,0.8l1.1,0.9h1.3l0.2-0.2C463.1,184.1,462.6,183.6,461.6,183.5L461.6,183.5z"/>
      <polygon fill="none" points="460.1,184 460.8,183.5 458.1,183.5 458.8,184 460.1,184 		"/>
      <polygon fill="none" points="463.7,187.7 463.7,187.4 463.5,187.5 463.7,187.7 		"/>
      <polygon fill="none" points="463.6,186.9 463.4,185.9 463,185.6 461.7,185.6 460.6,186.5 461.7,187.3 463,187.3 463.6,186.9 		"/>
      <path fill="none" d="M463.6,188.2l-0.6-0.4h-1.3l-1.1,0.9l0.7,0.5l0.3,0C462.7,189,463.4,188.7,463.6,188.2L463.6,188.2z"/>
      <polygon fill="none" points="460.1,188.8 458.8,188.8 458.1,189.3 460.6,189.2 460.1,188.8 		"/>
      <polygon fill="none" points="457.3,189.4 458.3,188.6 457.2,187.8 455.9,187.8 454.8,188.6 455.9,189.5 457.3,189.4 		"/>
      <polygon fill="none" points="454.3,188.8 453,188.8 452.1,189.6 455.1,189.5 454.3,188.8 		"/>
      <path fill="none" d="M444.6,184.7l0,0.4h1l1.1-0.9l-1-0.8C444.9,183.6,444.5,184,444.6,184.7L444.6,184.7z"/>
      <polygon fill="none" points="446.4,183.5 447.1,184 448.4,184 449.2,183.5 446.4,183.5 		"/>
      <polygon fill="none" points="449.4,189.6 448.5,188.8 447.1,188.8 446.3,189.5 449.4,189.6 		"/>
      <polygon fill="none" points="446.7,186.4 445.5,185.6 444.5,185.6 444.3,187.3 445.5,187.3 446.7,186.4 		"/>
      <path fill="none" d="M445.5,189.5L445.5,189.5l1.1-0.8l-1.1-0.9h-1.2l-0.1,0.7C444.2,189,444.6,189.3,445.5,189.5L445.5,189.5z"/>
      <path fill="none" d="M478.2,181l0,1.8c-0.1,0.8-0.3,1.5-0.6,2.2c-2.9,4.3-5.9,7.1-9,8.2l-2.3,0.6c0.7,3.7,0.2,6-1.5,6.9l-4.5,0.7
        v4.8V232c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5v-56v-5.4v-18.9c-1.6,8.1-3.1,16.5-4.6,25.3
        C482.7,178.9,480.5,180.2,478.2,181L478.2,181z"/>
      <path fill="none" d="M460.4,201.4l4.5-0.7c1.7-0.9,2.2-3.2,1.5-6.9c-6.4,1.4-15.7,1.9-27.8,1.5h-27.4c1.2,1.8,2.4,3.2,3.6,4.3
        c1.6,1.5,3.1,2.3,4.5,2.5h23.1L460.4,201.4L460.4,201.4z"/>
      <path fill="none" d="M427.8,123v-0.9c1.2-0.4,2.3-0.7,3.4-0.8l0.9,0v-5.2l-95.9,0v5.2l0.9,0c1.1,0.1,2.2,0.4,3.3,0.8v0.9h-4.3v0.9
        h15.5h0.9h20.1h0.9h21h0.9h20.1h0.9H432V123H427.8L427.8,123z M431.3,118.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1
        c-1-0.1-2.1-0.4-3.1-0.9v-1.2c1.2-0.5,2.4-0.8,3.6-0.9h0.1c0.7-0.1,1.3-0.1,2,0C428.9,118.1,430.1,118.4,431.3,118.9L431.3,118.9z
         M422.3,121.3c1.1,0.1,2.2,0.4,3.3,0.8v0.9h-8.6v-0.9c1.2-0.4,2.3-0.7,3.4-0.8H422.3L422.3,121.3z M415,118c0.7-0.1,1.3-0.1,2,0
        c1.2,0.1,2.4,0.4,3.6,0.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.1-0.9v-1.2
        C412.6,118.4,413.8,118.1,415,118L415,118L415,118z M410.1,118.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.8,0l-0.3,0
        c-1.2-0.1-2.4-0.4-3.6-0.9v-1.2c1.2-0.5,2.4-0.8,3.6-0.9h0.1c0.7-0.1,1.3-0.1,2,0C407.6,118.1,408.8,118.4,410.1,118.9
        L410.1,118.9z M409.8,121.3h1.9c1.1,0.1,2.2,0.4,3.3,0.8v0.9h-8.6v-0.9C407.6,121.6,408.7,121.4,409.8,121.3L409.8,121.3z
         M399.3,118.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.2-0.9v-1.2c1.2-0.5,2.4-0.8,3.6-0.9h0.1
        c0.7-0.1,1.3-0.1,2,0C396.9,118.1,398.1,118.4,399.3,118.9L399.3,118.9z M395.8,123v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        c1.1,0.1,2.2,0.4,3.3,0.8v0.9H395.8L395.8,123z M393.7,122.1v0.9h-8.6v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        C391.5,121.4,392.6,121.6,393.7,122.1L393.7,122.1z M383.1,118L383.1,118c0.7-0.1,1.4-0.1,2.1,0c1.2,0.1,2.4,0.4,3.6,0.9v1.2
        c-1.2,0.5-2.4,0.8-3.6,0.9l-1.8,0l-0.3,0c-1.2-0.1-2.4-0.4-3.6-0.9v-1.2C380.7,118.4,381.9,118.1,383.1,118L383.1,118z
         M377.9,118.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.1-0.9v-1.2c1.2-0.5,2.4-0.8,3.6-0.9h0.1
        c0.7-0.1,1.3-0.1,2,0C375.4,118.1,376.6,118.4,377.9,118.9L377.9,118.9z M382.9,122.1v0.9h-8.6v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        C380.7,121.4,381.8,121.6,382.9,122.1L382.9,122.1z M361.7,118c0.7-0.1,1.3-0.1,2,0c1.2,0.1,2.4,0.4,3.6,0.9v1.2
        c-1.2,0.5-2.4,0.8-3.6,0.9l-1.8,0l-0.3,0c-1.2-0.1-2.4-0.4-3.6-0.9v-1.2C359.3,118.4,360.5,118.1,361.7,118L361.7,118L361.7,118z
         M363.7,122.1c1.2-0.4,2.3-0.7,3.4-0.8h1.9c1.1,0.1,2.2,0.4,3.3,0.8v0.9h-8.6V122.1L363.7,122.1z M361.6,122.1v0.9H353v-0.9
        c1.2-0.4,2.3-0.7,3.4-0.8h1.9C359.4,121.4,360.5,121.6,361.6,122.1L361.6,122.1z M356.6,118.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9
        l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.1-0.9v-1.2c1.2-0.5,2.4-0.8,3.6-0.9h0.1c0.7-0.1,1.3-0.1,2,0
        C354.1,118.1,355.3,118.4,356.6,118.9L356.6,118.9z M346,118.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.8,0l-0.3,0
        c-1.2-0.1-2.4-0.4-3.6-0.9v-1.2c1.2-0.5,2.4-0.8,3.6-0.9h0.1c0.7-0.1,1.3-0.1,2,0C343.6,118.1,344.8,118.4,346,118.9L346,118.9z
         M351,122.1v0.9h-8.6v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9C348.7,121.4,349.8,121.6,351,122.1L351,122.1z"/>
      <path fill="none" d="M431.3,120.2v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1C428.9,121,430.1,120.7,431.3,120.2L431.3,120.2z"/>
      <path fill="none" d="M425.7,122.1c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6V122.1L425.7,122.1z"/>
      <path fill="none" d="M417,118c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2C419.4,118.4,418.2,118.1,417,118L417,118z"/>
      <path fill="none" d="M410.1,120.2v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0C407.6,121,408.8,120.7,410.1,120.2L410.1,120.2z"/>
      <path fill="none" d="M411.7,121.3h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9C413.9,121.6,412.8,121.4,411.7,121.3L411.7,121.3z"
        />
      <path fill="none" d="M399.3,120.2v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.1,0.4,2.1,0.7,3.2,0.9l1.3,0.1l1.3-0.1C396.9,121,398.1,120.7,399.3,120.2L399.3,120.2z"/>
      <path fill="none" d="M395.8,122.1v0.9h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9C398,121.4,396.9,121.6,395.8,122.1L395.8,122.1z"/>
      <path fill="none" d="M393.7,123v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9H393.7L393.7,123z"/>
      <path fill="none" d="M383.2,118L383.2,118c-1.2,0.1-2.4,0.4-3.7,0.9v1.2c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C384.5,118,383.8,118,383.2,118L383.2,118z"/>
      <path fill="none" d="M377.9,120.2v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1C375.4,121,376.6,120.7,377.9,120.2L377.9,120.2z"/>
      <path fill="none" d="M382.9,123v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9H382.9L382.9,123z"/>
      <path fill="none" d="M363.7,118c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2C366.1,118.4,364.9,118.1,363.7,118L363.7,118z"/>
      <path fill="none" d="M367,121.3c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8H367L367,121.3z"/>
      <path fill="none" d="M361.6,123v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9H361.6L361.6,123z"/>
      <path fill="none" d="M356.6,120.2v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1C354.1,121,355.3,120.7,356.6,120.2L356.6,120.2z"/>
      <path fill="none" d="M346,120.2v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0C343.6,121,344.8,120.7,346,120.2L346,120.2z"/>
      <path fill="none" d="M351,123v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9H351L351,123z"/>
      <path fill="none" d="M427.8,122.1v0.9h4.3v-1.8l-0.9,0C430,121.4,428.9,121.6,427.8,122.1L427.8,122.1z"/>
      <path fill="none" d="M432,127.4v-3.5h-15.5v2.8c0.7,0.1,1.3,0.3,1.9,0.5l0.4,0.2H432L432,127.4z"/>
      <path fill="none" d="M422,129.4c1.2-0.5,2.4-0.8,3.6-0.9h0.1c0.7-0.1,1.3-0.1,2,0c1.2,0.1,2.4,0.4,3.6,0.9v1.2
        c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.1-0.9V129.4L422,129.4z M393.6,128.5L393.6,128.5
        c0.7-0.1,1.4-0.1,2.1,0c1.2,0.1,2.4,0.4,3.6,0.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.2-0.9v-1.2
        C391.3,128.9,392.5,128.6,393.6,128.5L393.6,128.5z M399.1,131.7h1.9c1.1,0.1,2.2,0.4,3.3,0.8v0.9h-8.6v-0.9
        C396.9,132.1,398,131.8,399.1,131.7L399.1,131.7z M393.7,132.5v0.9h-8.6v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        C391.5,131.8,392.6,132.1,393.7,132.5L393.7,132.5z M385.2,128.5c1.2,0.1,2.4,0.4,3.6,0.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.8,0
        l-0.3,0c-1.2-0.1-2.4-0.4-3.6-0.9v-1.2c1.2-0.5,2.4-0.8,3.6-0.9h0.1C383.8,128.4,384.5,128.4,385.2,128.5L385.2,128.5z
         M363.7,128.5c1.2,0.1,2.4,0.4,3.6,0.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.8,0l-0.3,0c-1.2-0.1-2.4-0.4-3.6-0.9v-1.2
        c1.2-0.5,2.4-0.8,3.6-0.9h0.1C362.4,128.4,363.1,128.4,363.7,128.5L363.7,128.5z M372.3,128.5c0.7-0.1,1.3-0.1,2,0
        c1.2,0.1,2.4,0.4,3.6,0.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.1-0.9v-1.2
        C369.8,128.9,371,128.6,372.3,128.5L372.3,128.5L372.3,128.5z M374.3,133.5v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        c1.1,0.1,2.2,0.4,3.3,0.8v0.9H374.3L374.3,133.5z M372.3,132.5v0.9h-8.6v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        C370,131.8,371.1,132.1,372.3,132.5L372.3,132.5z M361.6,132.5v0.9H353v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        C359.4,131.8,360.5,132.1,361.6,132.5L361.6,132.5z M350.9,128.5L350.9,128.5c0.7-0.1,1.4-0.1,2.1,0c1.2,0.1,2.4,0.4,3.6,0.9v1.2
        c-1.2,0.5-2.4,0.8-3.6,0.9l-1.3,0.1l-1.3-0.1c-1-0.1-2.1-0.4-3.1-0.9v-1.2C348.5,128.9,349.7,128.6,350.9,128.5L350.9,128.5z
         M340.4,128.5L340.4,128.5c0.7-0.1,1.4-0.1,2.1,0c1.2,0.1,2.4,0.4,3.6,0.9v1.2c-1.2,0.5-2.4,0.8-3.6,0.9l-1.8,0l-0.3,0
        c-1.2-0.1-2.4-0.4-3.6-0.9v-1.2C338,128.9,339.2,128.6,340.4,128.5L340.4,128.5z M342.4,133.5v-0.9c1.2-0.4,2.3-0.7,3.4-0.8h1.9
        c1.1,0.1,2.2,0.4,3.3,0.8v0.9H342.4L342.4,133.5z"/>
      <path fill="none" d="M425.6,128.5c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9
        v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C427,128.4,426.4,128.4,425.6,128.5L425.6,128.5L425.6,128.5z"/>
      <path fill="none" d="M393.7,128.5L393.7,128.5c-1.2,0.1-2.4,0.4-3.7,0.9v1.2c1.1,0.4,2.1,0.7,3.2,0.9l1.3,0.1l1.3-0.1
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C395.1,128.4,394.4,128.4,393.7,128.5L393.7,128.5z"/>
      <path fill="none" d="M401,131.7h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9C403.2,132.1,402.1,131.8,401,131.7L401,131.7z"/>
      <path fill="none" d="M393.7,133.5v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9H393.7L393.7,133.5z"/>
      <path fill="none" d="M388.8,129.4c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9V129.4L388.8,129.4z"/>
      <path fill="none" d="M367.3,129.4c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9V129.4L367.3,129.4z"/>
      <path fill="none" d="M374.3,128.5c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1
        l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9v-1.2C376.6,128.9,375.4,128.6,374.3,128.5L374.3,128.5z"/>
      <path fill="none" d="M374.3,132.5v0.9h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9C376.6,131.8,375.5,132.1,374.3,132.5L374.3,132.5z"
        />
      <path fill="none" d="M372.3,133.5v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8H367c-1.1,0.1-2.2,0.4-3.4,0.8v0.9H372.3L372.3,133.5z"/>
      <path fill="none" d="M361.6,133.5v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9H361.6L361.6,133.5z"/>
      <path fill="none" d="M351,128.5L351,128.5c-1.2,0.1-2.4,0.4-3.7,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C352.3,128.4,351.7,128.4,351,128.5L351,128.5z"/>
      <path fill="none" d="M340.4,128.5L340.4,128.5c-1.2,0.1-2.4,0.4-3.7,0.9v1.2c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C341.8,128.4,341.1,128.4,340.4,128.5L340.4,128.5z"/>
      <path fill="none" d="M342.4,132.5v0.9h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9C344.7,131.8,343.5,132.1,342.4,132.5L342.4,132.5z"
        />
      <path fill="none" d="M432,131.7l-0.9,0c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h4.3l0-0.2V131.7L432,131.7z"/>
      <path fill="none" d="M429.6,136.8c0.9-0.7,1.5-1.4,1.9-2.1h-15v4.2h7.1C425.9,139,427.9,138.3,429.6,136.8L429.6,136.8z"/>
      <path fill="none" d="M425.7,132.5c-1.1-0.4-2.3-0.7-3.3-0.8h-1.7l-0.1,0.1c-0.4,0.4-0.8,0.8-1.5,1.1l-1.5,0.6h8.1V132.5
        L425.7,132.5z"/>
      <path fill="none" d="M408.3,129.4l0.3-0.5c-0.7-0.2-1.5-0.4-2.2-0.4c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0l2-0.4l-0.2-0.6C408.1,130.2,408.2,129.8,408.3,129.4L408.3,129.4z"/>
      <polygon fill="none" points="418.7,128.7 418.7,128.7 417.7,128.7 417.5,129.1 417.5,129.1 418.1,129.1 417.4,129.4 417.4,129.6 
        417.4,129.5 417.4,129.6 418.6,129.2 418.7,128.7 		"/>
      <polygon fill="none" points="411.2,131 411.2,131 410.7,131 411.4,130.8 411.4,130.6 411.4,130.6 411.4,130.5 410.2,130.9 
        410,131.4 410.1,131.4 411.1,131.4 411.2,131 		"/>
      <path fill="none" d="M410.4,127.4c0.5-0.2,1.1-0.4,1.7-0.6l2.2-0.3l1.3,0v-2.7h-20.1v3.5H410.4L410.4,127.4z"/>
      <polygon fill="none" points="416.6,123.9 415.6,123.9 415.6,126.6 416.6,126.7 416.6,123.9 		"/>
      <polygon fill="none" points="395.5,123.9 394.6,123.9 394.6,127.4 395.5,127.4 395.5,123.9 		"/>
      <polygon fill="none" points="394.6,127.4 394.6,123.9 373.6,123.9 373.6,127.4 394.6,127.4 		"/>
      <polygon fill="none" points="394.6,134.7 394.6,138.8 395.5,138.8 395.5,134.7 394.6,134.7 		"/>
      <polygon fill="none" points="394.6,138.8 394.6,134.7 373.6,134.7 373.6,138.8 394.6,138.8 		"/>
      <polygon fill="none" points="395.5,134.7 395.5,138.8 415.6,138.8 415.6,134.7 395.5,134.7 		"/>
      <polygon fill="none" points="416.6,134.7 415.6,134.7 415.6,138.8 416.6,138.8 416.6,134.7 		"/>
      <path fill="none" d="M411.8,133.5l-1-0.3c-0.7-0.2-1.2-0.6-1.6-1l-0.3-0.3c-0.8,0.1-1.7,0.4-2.5,0.7v0.9H411.8L411.8,133.5z"/>
      <path fill="none" d="M439.1,166.4c-0.4-1.3-1.1-2.5-2.2-3.6c-2-2.4-3.6-3.7-4.8-3.8h-9.2v6.9v0.6H439.1L439.1,166.4z"/>
      <polygon fill="none" points="422.9,165.8 422.9,158.9 345.3,158.9 345.3,165.8 422.9,165.8 		"/>
      <polygon fill="none" points="373.6,123.9 372.7,123.9 372.7,127.4 373.6,127.4 373.6,123.9 		"/>
      <polygon fill="none" points="372.7,127.4 372.7,123.9 352.6,123.9 352.6,127.4 372.7,127.4 		"/>
      <polygon fill="none" points="373.6,134.7 372.7,134.7 372.7,138.8 373.6,138.8 373.6,134.7 		"/>
      <polygon fill="none" points="372.7,138.8 372.7,134.7 352.6,134.7 352.6,138.8 372.7,138.8 		"/>
      <polygon fill="none" points="352.6,123.9 351.6,123.9 351.6,127.4 352.6,127.4 352.6,123.9 		"/>
      <polygon fill="none" points="351.6,127.4 351.6,123.9 336.2,123.9 336.2,127.4 351.6,127.4 		"/>
      <path fill="none" d="M336.2,121.2v1.8h4.3v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8L336.2,121.2L336.2,121.2z"/>
      <path fill="none" d="M340.4,133.5v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8l-0.9,0v1.5l0,0.2H340.4L340.4,133.5z"/>
      <path fill="none" d="M338.6,136.8c1.8,1.5,3.8,2.2,6,2.1h7.1v-4.2h-15C337.1,135.4,337.7,136.1,338.6,136.8L338.6,136.8z"/>
      <polygon fill="none" points="352.6,134.7 351.6,134.7 351.6,138.8 352.6,138.8 352.6,134.7 		"/>
      <path fill="none" d="M336.1,158.9c-1.2,0.1-2.9,1.4-4.8,3.8c-1.1,1.1-1.8,2.3-2.2,3.6h16.2v-0.6v-6.9H336.1L336.1,158.9z"/>
      <polygon fill="none" points="363.8,214.3 363.8,206.1 340.5,206.1 345.3,214.3 363.8,214.3 		"/>
      <path fill="none" d="M363.8,206.1c6,1,13,1.5,20.9,1.3c7.2,0.2,13.8-0.3,19.8-1.3H363.8L363.8,206.1z"/>
      <path fill="none" d="M357.1,195.3c-1.2,1.8-2.4,3.2-3.6,4.3c-1.6,1.5-3.1,2.3-4.5,2.5h70.1c-1.4-0.1-2.9-1-4.5-2.5
        c-1.1-1.1-2.3-2.5-3.6-4.3H357.1L357.1,195.3z"/>
      <polygon fill="none" points="422.9,214.3 427.7,206.1 404.4,206.1 404.4,214.3 422.9,214.3 		"/>
      <path fill="none" d="M450.5,20.2h-1.1L383,19.9l-64.2,0.3h-1.1c-0.4,0-0.7,0.3-1,0.7c-0.2,0.3-0.4,0.8-0.6,1.4l-0.5,2.8
        c-0.1,0.4-0.3,0.8-0.5,1.2l0,0c-0.9,1.4-1.5,5.2-1.9,11.3h-4.3l-0.1,0.6h6.2l12.4-0.3c14.1-0.3,28.1-0.5,42-0.6l28.1,0
        c14.4,0.1,28.8,0.3,43.3,0.7l18.6,0.3l-0.1-0.6H455c-0.3-6.1-1-9.8-1.9-11.3l0,0c-0.3-0.4-0.4-0.8-0.5-1.2l-0.5-2.8
        c-0.2-0.6-0.4-1.1-0.6-1.4C451.2,20.5,450.9,20.2,450.5,20.2L450.5,20.2z M437.3,26.1c1,0.1,1.4,0.5,1,1.2c-0.4,0.7-1.7,1.1-4,1.1
        c-12.3-0.3-24.5-0.4-36.7-0.5l-28.1,0c-11.8,0.1-23.6,0.2-35.4,0.5c-2.3,0-3.7-0.4-4-1.1c-0.4-0.7-0.1-1.2,1-1.2
        c12.9-0.3,25.7-0.5,38.5-0.6l28.1,0C410.8,25.6,424,25.8,437.3,26.1L437.3,26.1z"/>
      <path fill="none" d="M438.3,27.3c0.4-0.7,0.1-1.2-1-1.2c-13.3-0.3-26.6-0.5-39.8-0.6l-28.1,0c-12.8,0.1-25.6,0.3-38.5,0.6
        c-1,0.1-1.4,0.5-1,1.2c0.4,0.7,1.7,1.1,4,1.1c11.9-0.2,23.7-0.4,35.4-0.5l28.1,0c12.2,0.1,24.4,0.2,36.7,0.5
        C436.6,28.4,437.9,28.1,438.3,27.3L438.3,27.3z"/>
      <path fill="none" d="M379.9,94l8.5,0c8.7,0,17.5,0.1,26.4,0.3l31.7,1.3l0.7,0.1l7.7,1.5l19.4,2c-3.9-19.2-8.8-39.5-14.8-61.1
        l-18.6-0.3c-14.5-0.4-28.9-0.6-43.3-0.7l-28.1,0c-13.9,0.1-27.9,0.3-42,0.6L315,38.2h-6.2c-5.9,21.5-10.8,41.9-14.8,61.1l19.4-2
        l7.7-1.5l0.7-0.1l31.7-1.3C362.4,94.1,371.1,94,379.9,94L379.9,94z M379.6,53.1l9.5,0c3.5-0.4,6.1-1.6,7.8-3.7
        c0.4-0.5,0.7-1,1-1.5c0.6-1.2,1-2.5,1.2-4.1v0c0.1-0.4,0.1-0.7,0.3-0.9l0.2-0.4c0.5-0.8,1.4-1.1,2.6-0.9l52.4,1.1
        c1.8,0.1,2.9,1.3,3.2,3.7l9,33.6c0.5,1.4-0.3,3-2.4,4.8c-4.2,3.9-9.1,5.7-14.5,5.4c-11.9-0.9-23.5-1.5-35-1.9
        c-8.1-0.3-16-0.4-23.9-0.4l-15.4,0c-7.2,0-14.5,0.2-21.9,0.4c-11.5,0.4-23.2,1-35,1.9c-5.5,0.3-10.3-1.5-14.5-5.4
        c-2.1-1.8-2.9-3.4-2.4-4.8l9-33.6c0.3-2.4,1.4-3.6,3.2-3.7l52.4-1.1c1.2-0.1,2.1,0.2,2.6,0.9l0.2,0.4l0.1,0.2l0.2,0.7
        c0.2,1.6,0.6,2.9,1.2,4.1c0.3,0.5,0.6,1,1,1.5C373.1,51.5,375.8,52.7,379.6,53.1L379.6,53.1z M440.8,39.4
        c-14.5-0.4-28.9-0.6-43.3-0.7l-28.1,0c-13.9,0.1-27.9,0.3-42,0.6L315,39.7h-6.2h6.2l12.4-0.3c14.1-0.3,28.1-0.5,42-0.6l28.1,0
        C411.9,38.8,426.3,39,440.8,39.4l18.6,0.3L440.8,39.4L440.8,39.4z"/>
      <path fill="none" d="M389.1,53l-9.5,0c-3.7-0.4-6.4-1.6-8.2-3.7c-0.4-0.5-0.7-1-1-1.5c-0.6-1.2-1-2.5-1.2-4.1l-0.2-0.7l-0.1-0.2
        l-0.2-0.4c-0.5-0.8-1.4-1.1-2.6-0.9l-52.4,1.1c-1.8,0.1-2.9,1.3-3.2,3.7l-9,33.6c-0.5,1.4,0.3,3,2.4,4.8c4.2,3.9,9.1,5.7,14.5,5.4
        c11.9-0.9,23.5-1.5,35-1.9c7.4-0.2,14.7-0.4,21.9-0.4l15.4,0c7.9,0,15.9,0.2,23.9,0.4c11.5,0.4,23.2,1,35,1.9
        c5.5,0.3,10.3-1.5,14.5-5.4c2.1-1.8,2.9-3.4,2.4-4.8l-9-33.6c-0.3-2.4-1.4-3.6-3.2-3.7l-52.4-1.1c-1.2-0.1-2.1,0.2-2.6,0.9
        l-0.2,0.4c-0.1,0.3-0.2,0.6-0.3,0.9v0c-0.2,1.5-0.6,2.9-1.2,4.1c-0.3,0.5-0.6,1-1,1.5C395.2,51.4,392.6,52.6,389.1,53L389.1,53z"
        />
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="450,183.5 449,184.3 450.1,185.1 451.4,185.1 452.5,184.3 
        451.5,183.5 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="453,184.5 451.9,185.3 453,186.2 454.3,186.2 455.4,185.3 
        454.3,184.5 453,184.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="455.8,183.5 454.8,184.3 455.9,185.1 457.2,185.1 458.3,184.3 
        457.3,183.5 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="460.1,184.5 461.2,185.3 460.1,186.2 458.8,186.2 457.7,185.3 
        458.8,184.5 460.1,184.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="461.6,183.5 460.6,184.3 461.7,185.1 463,185.1 463.3,184.9 		
        "/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="458.1,183.5 458.8,184 460.1,184 460.8,183.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="463.7,187.4 463.5,187.5 463.7,187.7 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="463.4,185.9 463,185.6 461.7,185.6 460.6,186.5 461.7,187.3 
        463,187.3 463.6,186.9 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="463.6,188.2 463,187.8 461.7,187.8 460.6,188.6 461.3,189.1 		
        "/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="458.8,186.6 457.7,187.5 458.8,188.4 460.1,188.4 461.2,187.5 
        460.1,186.7 458.8,186.6 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="460.6,189.2 460.1,188.8 458.8,188.8 458.1,189.3 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="457.2,185.6 455.9,185.6 454.8,186.5 455.9,187.3 457.2,187.3 
        458.3,186.4 457.2,185.6 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="457.3,189.4 458.3,188.6 457.2,187.8 455.9,187.8 454.8,188.6 
        455.9,189.5 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="453,186.6 451.9,187.5 453,188.4 454.3,188.4 455.4,187.5 
        454.3,186.7 453,186.6 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="451.4,185.6 450.1,185.6 449,186.5 450.1,187.3 451.4,187.3 
        452.5,186.4 451.4,185.6 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="452.5,188.6 451.4,187.8 450.1,187.8 449,188.6 450.1,189.5 
        451.4,189.5 452.5,188.6 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="455.1,189.5 454.3,188.8 453,188.8 452.1,189.6 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="446,185.3 447.2,186.2 448.5,186.2 449.6,185.3 448.5,184.5 
        447.1,184.5 446,185.3 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="444.5,185.1 445.5,185.1 446.6,184.3 445.7,183.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="446.4,183.5 447.1,184 448.4,184 449.2,183.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="306.7,189.1 307.4,188.6 306.3,187.8 305,187.8 304.6,188.1 		
        "/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="304.6,187 305,187.3 306.3,187.3 307.4,186.4 306.3,185.6 
        305,185.6 304.8,185.7 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="309.9,189.3 309.2,188.8 307.9,188.8 307.5,189.2 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="307.9,186.6 306.8,187.5 307.9,188.4 309.2,188.4 310.3,187.5 
        309.2,186.7 307.9,186.6 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="316,189.6 315,188.8 313.7,188.8 312.9,189.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="312.2,189.4 313.2,188.6 312.1,187.8 310.8,187.8 309.7,188.6 
        310.7,189.4 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="313.3,186.4 312.1,185.6 310.8,185.6 309.7,186.5 310.8,187.3 
        312.1,187.3 313.3,186.4 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="313.7,186.6 312.6,187.5 313.7,188.4 315,188.4 316.1,187.5 
        315,186.7 313.7,186.6 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="316.6,189.5 317.9,189.5 319,188.6 317.9,187.8 316.6,187.8 
        315.5,188.6 316.6,189.5 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="316.6,187.3 317.9,187.3 319.1,186.4 317.9,185.6 316.7,185.6 
        315.5,186.5 316.6,187.3 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="321.8,189.6 320.9,188.8 319.6,188.8 318.6,189.6 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="320.9,186.7 319.6,186.6 318.4,187.5 319.6,188.4 320.9,188.4 
        322,187.5 320.9,186.7 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="323.9,187.9 323.8,187.8 322.5,187.8 321.4,188.6 322.5,189.5 
        322.6,189.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="323.7,185.6 322.5,185.6 321.4,186.5 322.5,187.3 323.8,187.3 
        323.9,187.2 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="320.9,184.5 322,185.3 320.9,186.2 319.6,186.2 318.4,185.3 
        319.6,184.5 320.9,184.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="322.4,183.5 321.4,184.3 322.5,185.1 323.7,185.1 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="318.8,183.5 319.6,184 320.9,184 321.6,183.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="316.5,183.5 315.5,184.3 316.6,185.1 317.9,185.1 319,184.3 
        318,183.5 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="312.6,185.3 313.7,186.2 315,186.2 316.2,185.3 315,184.5 
        313.7,184.5 312.6,185.3 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="313,183.5 313.7,184 315,184 315.7,183.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="310.7,183.5 309.7,184.3 310.8,185.1 312.1,185.1 313.2,184.3 
        312.2,183.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="307.2,183.5 307.9,184 309.2,184 309.9,183.5 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="307.9,184.5 306.8,185.3 307.9,186.2 309.2,186.2 310.3,185.3 
        309.2,184.5 307.9,184.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="304.9,185.1 305,185.1 306.3,185.1 307.4,184.3 306.4,183.5 		
        "/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="449.4,189.6 448.5,188.8 447.1,188.8 446.3,189.5 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="448.4,186.7 447.2,186.6 446,187.5 447.1,188.4 448.4,188.4 
        449.6,187.5 448.4,186.7 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="444.3,187.3 445.5,187.3 446.7,186.4 445.5,185.6 444.5,185.6 
            "/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="445.6,189.5 446.6,188.6 445.5,187.8 444.3,187.8 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="452.3,183.5 453,184 454.3,184 455,183.5 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M322.7,117l2.6,0l0,0.6c-0.3,1.2-0.5,2.8-0.5,4.7l1.8,0.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="316.6" y1="125.2" x2="317.5" y2="124.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.6,128.7l-0.7,0.9c-1,1-2.2,1.5-3.6,1.5c-1.4,0-2.6-0.5-3.6-1.5
        c-1-1-1.5-2.2-1.5-3.6c0-1.4,0.5-2.6,1.5-3.6c1-1,2.2-1.5,3.6-1.5c1.4,0,2.6,0.5,3.6,1.5c1,1,1.5,2.2,1.5,3.6l-0.1,1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M318.7,126.8c1.3-1.3,2.8-1.9,4.6-1.9h0.2c0.9,0,1.7,0.2,2.4,0.5
        l0.7,0.3c0.5,0.3,1,0.7,1.4,1.1c1.3,1.3,1.9,2.8,1.9,4.6c0,1.8-0.6,3.3-1.9,4.6c-0.4,0.4-0.9,0.8-1.4,1.1l-0.7,0.3
        c-0.8,0.3-1.6,0.5-2.5,0.5c-1.8,0-3.3-0.6-4.6-1.9c-0.9-0.9-1.5-1.9-1.8-3.1l0,0v0l0-0.1v0c-0.1-0.4-0.1-0.9-0.1-1.3
        c0-0.3,0-0.6,0.1-0.9c0.1-1.1,0.6-2.1,1.3-3.1L318.7,126.8L318.7,126.8z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M315.2,127c0.4-0.7,0.8-1.3,1.4-1.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M317.5,124.3c0.2-0.2,0.4-0.3,0.6-0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M318,123.9c1.2-0.9,2.5-1.4,4-1.6c0-2.1,0.2-3.9,0.6-5.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M323.8,122.9l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
        C323.7,122.6,323.8,122.7,323.8,122.9L323.8,122.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M325.4,126.2l0.6,0.3c0.4,0.2,0.8,0.6,1.2,0.9c1.1,1.1,1.6,2.4,1.6,3.9
        c0,1.5-0.5,2.8-1.6,3.9c-0.4,0.4-0.8,0.7-1.2,0.9l-0.6,0.3c-0.7,0.3-1.4,0.4-2.2,0.4c-1.5,0-2.8-0.5-3.9-1.6
        c-0.8-0.8-1.3-1.7-1.5-2.6l0,0v0l0,0v0l-0.1-1.2c0-1.5,0.5-2.8,1.6-3.9c1.1-1.1,2.4-1.6,3.9-1.6h0.1
        C324.1,125.8,324.8,126,325.4,126.2L325.4,126.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M329.8,126.2l0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        C329.6,126,329.7,126.1,329.8,126.2L329.8,126.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M304.7,130.7c-1.2-1.4-1.8-3-1.8-4.8c0-2.1,0.7-3.8,2.2-5.2
        c1.4-1.4,3.2-2.2,5.2-2.2c2,0,3.8,0.7,5.2,2.2c1.1,1.1,1.7,2.3,2,3.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M302.3,124.3c0.3-1.5,1-2.9,2.2-4.1c1.6-1.6,3.5-2.3,5.7-2.3
        c2.2,0,4.1,0.8,5.7,2.3c1.1,1.1,1.8,2.3,2.1,3.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M300.9,120c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
        c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        C300.5,120,300.7,120,300.9,120L300.9,120z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M302.3,116.8l0,0.3c-0.1,2.7-0.1,5,0,7.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301.3,126.7c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        C301,126.7,301.2,126.7,301.3,126.7L301.3,126.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M305.6,125.9c0-1.3,0.4-2.4,1.4-3.3c0.9-0.9,2-1.4,3.3-1.4
        c1.3,0,2.4,0.4,3.3,1.4c0.9,0.9,1.4,2,1.4,3.3c0,1.3-0.4,2.4-1.4,3.3c-0.9,0.9-2,1.4-3.3,1.4c-1.3,0-2.4-0.4-3.3-1.4
        C306.1,128.2,305.6,127.2,305.6,125.9L305.6,125.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M302.3,124.3c0.1,1.2,0.2,2.3,0.3,3.3c0.2,1.4,0.9,2.5,2,3.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M307.2,131.6c-0.5-0.3-1-0.7-1.5-1.1c-1.2-1.2-1.9-2.8-1.9-4.5
        c0-1.8,0.6-3.3,1.9-4.5c1.2-1.2,2.8-1.9,4.5-1.9c1.8,0,3.3,0.6,4.5,1.9c1.1,1.1,1.7,2.4,1.8,3.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M310.3,124.2c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
        c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2
        C309.4,124.4,309.8,124.2,310.3,124.2L310.3,124.2z"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="312.6,131.9 314.2,131.8 314.2,131 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M312.5,133.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        C312.5,133.4,312.5,133.2,312.5,133.1L312.5,133.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.2,131c-0.5,0.4-1,0.7-1.6,0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M302.7,134.3c0.7,0.5,1.5,0.7,2.4,0.6h4.7c1.7,0.1,2.8,0.8,3.4,2.1
        c1.3,2.6,0.9,3.9-1.1,4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M304.7,130.7c0.5,0.4,1.2,0.6,1.9,0.7l0.1,0l0.5,0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M305.2,131.7c0.2,0,0.3,0.1,0.4,0.2l0.2,0.4l-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        C304.8,131.8,305,131.7,305.2,131.7L305.2,131.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M312.1,141l12.5,0.1c1.1-0.1,1.8-0.5,2-1.2c-1,0.4-2,0.5-3.2,0.5
        c-2.1,0-4-0.6-5.6-1.9l-0.8-0.7c-1-1-1.7-2.1-2.2-3.4c-3.1,0.1-5.9,0-8.5-0.4h-0.1c-2.5-0.5-4.2-1.6-5.2-3.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M292.3,134.1c0-0.7,0.3-1.1,0.7-1.3c5.3-0.1,8.4,0.1,9.1,0.8l0.6,0.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M306.4,138.3c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
        L306.4,138.3L306.4,138.3z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="307.2" y1="131.6" x2="312.6" y2="131.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301.4,140.9c-1.6-2.7-1.1-4.9,1.3-6.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="312.1" y1="141" x2="301.4" y2="140.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M324.1,130.6c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8l-0.2,0.2
        l-0.1,0.1l-0.4,0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.1-0.2-0.2-0.3-0.3-0.5v0l0-0.2c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h0
        l0.4,0.1l0.1,0.1L324.1,130.6L324.1,130.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M323.3,138.5c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
        C323,138.6,323.2,138.5,323.3,138.5L323.3,138.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M329,137.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4l-0.2,0.4
        C329.3,137.3,329.2,137.3,329,137.3L329,137.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M318.2,136.4l0.2,0.4l-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
        c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        C317.9,136.3,318.1,136.3,318.2,136.4L318.2,136.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.6,128.7c0.3-0.5,0.6-1,0.7-1.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.2,131c0-0.8,0.2-1.6,0.4-2.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301,130.6c-0.4-0.8-0.7-1.7-0.9-2.7c-0.4-2.9-0.6-6.5-0.4-10.9l0-0.2"
        />
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M315.2,127c-0.3,0.5-0.5,1-0.7,1.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="302.3" y1="116.8" x2="322.7" y2="117"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M292.9,119.4c0.4-1.5,1-2.3,1.8-2.6h5.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="290.2" y1="118.9" x2="291.5" y2="119.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M295.3,124.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
        C295.4,124.4,295.3,124.3,295.3,124.1L295.3,124.1z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="291.5" y1="119.1" x2="292.9" y2="119.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="450.7" y1="124.3" x2="451.6" y2="125.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="465.9" y1="116.8" x2="445.5" y2="117"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M296.3,137.6c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
        c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l0.2,0.4c0,0.2-0.1,0.3-0.2,0.4
        C296.6,137.5,296.5,137.6,296.3,137.6L296.3,137.6z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="292.3" y1="130.6" x2="292.3" y2="134.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301.4,140.9l-7.5-0.1c-0.6,0-1-0.1-1.3-0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M291.5,119.1c-0.6,6.1-0.8,13-0.8,20.6c0.3,0.7,0.9,0.9,1.8,0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M292.5,140.4c-0.3-0.2-0.4-0.5-0.3-1l0-5.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M292.3,130.6c0.1-4.1,0.3-7.8,0.6-11.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M331.4,131.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4
        c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2
        C331.1,131.1,331.3,131.1,331.4,131.2L331.4,131.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M336.2,133.5h4.2v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8l-0.9,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M342.4,133.5h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9
        c-1.1,0.1-2.2,0.4-3.4,0.8V133.5L342.4,133.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M340.4,128.5c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.2,0.5,2.4,0.8,3.6,0.9
        l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C341.8,128.4,341.1,128.4,340.4,128.5L340.4,128.5
        L340.4,128.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M336.2,123h4.3v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8l-0.9,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M316.3,162c-0.8,0.8-1.8,1.2-2.9,1.2c-1.1,0-2.1-0.4-2.9-1.2
        c-0.8-0.8-1.2-1.8-1.2-2.9c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.1,0.4,2.9,1.2c0.8,0.8,1.2,1.8,1.2,2.9
        C317.5,160.2,317.1,161.2,316.3,162L316.3,162z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M350.9,128.5c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9
        l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C352.3,128.4,351.7,128.4,350.9,128.5L350.9,128.5
        L350.9,128.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M317.5,163.2c-1.1,1.1-2.5,1.7-4.2,1.7c-1.6,0-3-0.6-4.1-1.7
        c-1.1-1.1-1.7-2.5-1.7-4.1c0-1.6,0.6-3,1.7-4.2c1.1-1.1,2.5-1.7,4.1-1.7c1.6,0,3,0.6,4.2,1.7c1.1,1.1,1.7,2.5,1.7,4.2
        C319.2,160.7,318.7,162.1,317.5,163.2L317.5,163.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M315.7,161.4c-0.7,0.7-1.4,1-2.3,1c-0.9,0-1.7-0.3-2.3-1
        c-0.7-0.6-1-1.4-1-2.3c0-0.9,0.3-1.7,1-2.3c0.6-0.6,1.4-1,2.3-1c0.9,0,1.7,0.3,2.3,1c0.6,0.7,1,1.4,1,2.3
        C316.7,160,316.3,160.7,315.7,161.4L315.7,161.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M315,157.4c0.4,0.4,0.7,1,0.7,1.6c0,0.6-0.2,1.2-0.7,1.6
        c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6c0-0.6,0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7
        C314,156.8,314.6,157,315,157.4L315,157.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.1,159c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
        c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2
        C314,158.7,314.1,158.9,314.1,159L314.1,159z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M351,122.1c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9
        h8.6V122.1L351,122.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M346,118.9c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1
        c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9V118.9L346,118.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M356.6,118.9c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1
        c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9V118.9L356.6,118.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M361.6,132.5c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8
        v0.9h8.6V132.5L361.6,132.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M372.3,132.5c-1.1-0.4-2.3-0.7-3.3-0.8H367c-1.1,0.1-2.2,0.4-3.4,0.8
        v0.9h8.6V132.5L372.3,132.5z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="299.7" y1="116.8" x2="302.3" y2="116.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="301" y1="130.6" x2="292.3" y2="130.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M374.3,133.5h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9
        c-1.1,0.1-2.2,0.4-3.4,0.8V133.5L374.3,133.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M372.3,128.5L372.3,128.5c-1.2,0.1-2.4,0.4-3.7,0.9v1.2
        c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9
        C373.6,128.4,372.9,128.4,372.3,128.5L372.3,128.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M361.6,122.1c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8
        v0.9h8.6V122.1L361.6,122.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M363.7,122.1v0.9h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8H367
        C365.9,121.4,364.8,121.6,363.7,122.1L363.7,122.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M363.7,128.5c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9v-1.2C366.1,128.9,364.9,128.6,363.7,128.5L363.7,128.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M361.7,118L361.7,118c-1.2,0.1-2.4,0.4-3.7,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C363.1,118,362.4,118,361.7,118
        L361.7,118z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M385.2,128.5c-0.7-0.1-1.3-0.1-2,0h-0.1c-1.2,0.1-2.4,0.4-3.6,0.9v1.2
        c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9v-1.2C387.5,128.9,386.3,128.6,385.2,128.5L385.2,128.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M382.9,122.1c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8
        v0.9h8.6V122.1L382.9,122.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M377.9,118.9c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1
        c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9V118.9L377.9,118.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M383.1,118c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0
        l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C384.5,118,383.8,118,383.1,118L383.1,118L383.1,118z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M393.7,132.5c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8
        v0.9h8.6V132.5L393.7,132.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M399.1,131.7c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9
        c-1.1-0.4-2.3-0.7-3.3-0.8H399.1L399.1,131.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M409.1,131.7c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9
        c-1.1-0.4-2.3-0.7-3.3-0.8H409.1L409.1,131.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M420.1,131.7c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9
        c-1.1-0.4-2.3-0.7-3.3-0.8H420.1L420.1,131.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M393.6,128.5c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.2,0.9
        l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C395.1,128.4,394.4,128.4,393.6,128.5L393.6,128.5
        L393.6,128.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M393.7,122.1c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8
        v0.9h8.6V122.1L393.7,122.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M395.8,123h8.6v-0.9c-1.1-0.4-2.3-0.7-3.3-0.8h-1.9
        c-1.1,0.1-2.2,0.4-3.4,0.8V123L395.8,123z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M399.3,118.9c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1
        c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.2,0.9l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9V118.9L399.3,118.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M409.8,121.3c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9
        c-1.1-0.4-2.3-0.7-3.3-0.8H409.8L409.8,121.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M410.1,118.9c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1
        c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.2,0.5,2.4,0.8,3.6,0.9l0.3,0l1.8,0c1.2-0.1,2.4-0.4,3.6-0.9V118.9L410.1,118.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M415,118L415,118c-1.2,0.1-2.4,0.4-3.7,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9
        l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9C416.4,118,415.7,118,415,118L415,118z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M438.7,136.3c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        C439,136.1,438.9,136.1,438.7,136.3L438.7,136.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M437.7,132.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2
        C437.4,132.3,437.5,132.2,437.7,132.1L437.7,132.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M450,136.4c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        C450.3,136.3,450.1,136.3,450,136.4L450,136.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M444.9,138.5c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        C445.2,138.6,445,138.5,444.9,138.5L444.9,138.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M446,131.6L446,131.6l0-0.2c0-0.3-0.1-0.6-0.3-0.8
        c-0.2-0.2-0.5-0.3-0.8-0.3h0l-0.4,0.1l-0.1,0.1l-0.2,0.2c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8l0.2,0.2l0.1,0.1l0.4,0.1
        c0.3,0,0.6-0.1,0.8-0.3C445.9,132,446,131.8,446,131.6L446,131.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M432,131.7l-0.9,0c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h4.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M432,121.2l-0.9,0c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h4.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M422,129.4v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1C424.5,128.6,423.3,128.9,422,129.4L422,129.4z
        "/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M411,129.4v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1C413.5,128.6,412.3,128.9,411,129.4L411,129.4z
        "/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M401,129.4v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1
        c1.2-0.1,2.4-0.4,3.6-0.9v-1.2c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1C403.5,128.6,402.3,128.9,401,129.4L401,129.4z
        "/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M422.3,121.3h-1.9c-1.1,0.1-2.2,0.4-3.4,0.8v0.9h8.6v-0.9
        C424.5,121.6,423.4,121.4,422.3,121.3L422.3,121.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M431.3,118.9c-1.2-0.5-2.4-0.8-3.6-0.9c-0.7-0.1-1.3-0.1-2,0h-0.1
        c-1.2,0.1-2.4,0.4-3.6,0.9v1.2c1.1,0.4,2.1,0.7,3.1,0.9l1.3,0.1l1.3-0.1c1.2-0.1,2.4-0.4,3.6-0.9V118.9L431.3,118.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M445.5,117c0.4,1.4,0.6,3.2,0.6,5.3c1.5,0.2,2.8,0.7,4,1.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M445.5,122.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        C445.7,122.7,445.6,122.6,445.5,122.5L445.5,122.5z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="450.2" y1="123.9" x2="450.7" y2="124.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M441,127.4c-1.1,1.1-1.6,2.4-1.6,3.9c0,1.5,0.5,2.8,1.6,3.9l1.2,0.9
        l0.6,0.3c0.7,0.3,1.4,0.4,2.2,0.4c1.5,0,2.8-0.5,3.9-1.6c0.8-0.8,1.3-1.7,1.5-2.6l0,0v0l0,0v0l0.1-1.2c0-1.5-0.5-2.8-1.6-3.9
        c-1.1-1.1-2.4-1.6-3.9-1.6h-0.1c-0.7,0-1.4,0.1-2,0.4l-0.6,0.3C441.8,126.8,441.4,127.1,441,127.4L441,127.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M438.4,126.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        C438.6,126,438.5,126.1,438.4,126.2L438.4,126.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M445.5,117l-2.6,0c-0.1,0.2,0,0.4,0,0.6c0.3,1.2,0.5,2.8,0.5,4.7
        l-1.8,0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M379.6,53.1c-3.7-0.4-6.4-1.6-8.2-3.7c-0.4-0.5-0.7-1-1-1.5
        c-0.6-1.2-1-2.5-1.2-4.1l-0.2-0.7l-0.1-0.2l-0.2-0.4c-0.5-0.8-1.4-1.1-2.6-0.9l-52.4,1.1c-1.8,0.1-2.9,1.3-3.2,3.7l-9,33.6
        c-0.5,1.4,0.3,3,2.4,4.8c4.2,3.9,9.1,5.7,14.5,5.4c11.9-0.9,23.5-1.5,35-1.9c7.4-0.2,14.7-0.4,21.9-0.4l15.4,0
        c7.9,0,15.9,0.2,23.9,0.4c11.5,0.4,23.2,1,35,1.9c5.5,0.3,10.3-1.5,14.5-5.4c2.1-1.8,2.9-3.4,2.4-4.8l-9-33.6
        c-0.3-2.4-1.4-3.6-3.2-3.7l-52.4-1.1c-1.2-0.1-2.1,0.2-2.6,0.9l-0.2,0.4c-0.1,0.3-0.2,0.6-0.3,0.9v0c-0.2,1.5-0.6,2.9-1.2,4.1
        c-0.3,0.5-0.6,1-1,1.5c-1.7,2.1-4.3,3.3-7.8,3.7L379.6,53.1L379.6,53.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M460.7,159.1c0-1.6-0.6-3-1.7-4.2c-1.1-1.1-2.5-1.7-4.1-1.7
        c-1.6,0-3,0.6-4.2,1.7c-1.1,1.1-1.7,2.5-1.7,4.2c0,1.6,0.6,3,1.7,4.1c1.1,1.1,2.5,1.7,4.2,1.7c1.6,0,3-0.6,4.1-1.7
        C460.1,162.1,460.7,160.7,460.7,159.1L460.7,159.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M454.8,158.3c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
        c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5
        C455.2,158.4,455,158.3,454.8,158.3L454.8,158.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M453.2,157.4c-0.4,0.4-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.6
        c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6c0-0.6-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7
        C454.2,156.8,453.6,157,453.2,157.4L453.2,157.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M454.8,155.8c-0.9,0-1.7,0.3-2.3,1c-0.6,0.7-1,1.4-1,2.3
        c0,0.9,0.3,1.7,1,2.3c0.7,0.7,1.4,1,2.3,1c0.9,0,1.7-0.3,2.3-1c0.7-0.6,1-1.4,1-2.3c0-0.9-0.3-1.7-1-2.3
        C456.5,156.1,455.7,155.8,454.8,155.8L454.8,155.8z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M454.8,155c-1.1,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,2.9
        c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2c1.1,0,2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9c0-1.1-0.4-2.1-1.2-2.9
        C456.9,155.4,456,155,454.8,155L454.8,155z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M466.8,140.9c1.6-2.7,1.1-4.9-1.3-6.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M475.9,130.6c-0.1-4.1-0.3-7.8-0.6-11.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="456.1" y1="141" x2="466.8" y2="140.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M453.6,128.7c0.2,0.7,0.3,1.5,0.4,2.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="468.5" y1="116.8" x2="465.9" y2="116.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="467.2" y1="130.6" x2="475.9" y2="130.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M466.8,140.9l7.6-0.1c0.6,0,1-0.1,1.3-0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M475.7,140.4c0.3-0.2,0.4-0.5,0.3-1l0-5.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M476.7,119.1c0.6,6.1,0.8,13,0.8,20.6c-0.3,0.7-0.9,0.9-1.8,0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M471.9,137.6c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        C471.6,137.5,471.8,137.6,471.9,137.6L471.9,137.6z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="475.9" y1="130.6" x2="475.9" y2="134.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="455.6" y1="131.9" x2="461" y2="131.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M454,131c0.5,0.4,1,0.7,1.6,0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M451.4,130.5c-0.1-1.1-0.6-2.1-1.3-3.1l-0.6-0.6
        c-1.3-1.3-2.8-1.9-4.6-1.9h-0.1c-0.9,0-1.7,0.2-2.4,0.5c-0.2,0.1-0.4,0.2-0.7,0.3c-0.5,0.3-1,0.7-1.4,1.1
        c-1.3,1.3-1.9,2.8-1.9,4.6c0,1.8,0.6,3.3,1.9,4.6c0.4,0.4,0.9,0.8,1.4,1.1l0.7,0.3c0.8,0.3,1.6,0.5,2.5,0.5c1.8,0,3.3-0.6,4.6-1.9
        c0.9-0.9,1.5-1.9,1.8-3.1l0,0v0l0-0.1v0l0.1-1.3L451.4,130.5L451.4,130.5z"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="454,131 454,131.8 455.6,131.9 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M455.6,132.6c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4
        C455.7,132.9,455.7,132.8,455.6,132.6L455.6,132.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M465.5,134.3c-0.7,0.5-1.5,0.7-2.4,0.6h-4.7c-1.7,0.1-2.8,0.8-3.4,2.1
        c-1.3,2.6-1,3.9,1.1,4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M461.8,137.5c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        C462,137.3,461.9,137.4,461.8,137.5L461.8,137.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M461,131.6l0.5-0.1l0.1,0c0.7-0.1,1.4-0.4,1.9-0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M467.2,130.6c-1,1.8-2.7,2.9-5.2,3.4H462c-2.6,0.4-5.4,0.5-8.5,0.4
        c-0.4,1.2-1.2,2.4-2.2,3.4l-0.8,0.7c-1.6,1.3-3.5,1.9-5.6,1.9c-1.1,0-2.2-0.2-3.2-0.5c0.2,0.8,0.8,1.2,2,1.2l12.5-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M475.9,134.1c0-0.7-0.3-1.1-0.7-1.3c-5.4-0.1-8.4,0.1-9.1,0.8l-0.6,0.6"
        />
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M462.4,132.3c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2
        c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
        C462.5,132,462.4,132.2,462.4,132.3L462.4,132.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M463.5,130.7c1.1-0.7,1.7-1.8,2-3.2l0.3-3.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M451.6,125.2c0.5,0.6,1,1.2,1.4,1.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M454.7,129.2c0.9,0.9,2,1.4,3.3,1.4c1.3,0,2.4-0.4,3.3-1.4
        c0.9-0.9,1.4-2,1.4-3.3c0-1.3-0.4-2.4-1.4-3.3c-0.9-0.9-2-1.4-3.3-1.4c-1.3,0-2.4,0.4-3.3,1.4c-0.9,0.9-1.4,2-1.4,3.3
        C453.3,127.2,453.8,128.2,454.7,129.2L454.7,129.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M453,127c0.3,0.5,0.5,1,0.7,1.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M453.6,128.7l0.7,0.9c1,1,2.2,1.5,3.6,1.5c1.4,0,2.6-0.5,3.6-1.5
        c1-1,1.5-2.2,1.5-3.6c0-1.4-0.5-2.6-1.5-3.6c-1-1-2.2-1.5-3.6-1.5c-1.4,0-2.6,0.5-3.6,1.5c-1,1-1.5,2.2-1.5,3.6
        c0,0.4,0,0.8,0.1,1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M461,131.6l1.5-1.1c1.2-1.2,1.9-2.8,1.9-4.5c0-1.8-0.6-3.3-1.9-4.5
        c-1.2-1.2-2.8-1.9-4.5-1.9c-1.8,0-3.3,0.6-4.5,1.9c-1.1,1.1-1.7,2.4-1.8,3.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M456.8,124.7c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2
        c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5
        C457.5,124.2,457.1,124.4,456.8,124.7L456.8,124.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M465.9,124.3c-0.3-1.5-1-2.9-2.2-4.1c-1.6-1.6-3.5-2.3-5.7-2.3
        c-2.2,0-4.1,0.8-5.7,2.3c-1.1,1.1-1.8,2.3-2.1,3.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M463.5,130.7c1.2-1.4,1.8-3,1.8-4.8c0-2.1-0.7-3.8-2.2-5.2
        c-1.4-1.4-3.2-2.2-5.2-2.2c-2,0-3.8,0.7-5.2,2.2c-1.1,1.1-1.7,2.3-2,3.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M465.9,124.3c0.1-2.1,0.1-4.4,0-7.1l0-0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M466.4,127.7c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
        c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        C466.3,127.5,466.3,127.6,466.4,127.7L466.4,127.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M472.3,123.5c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        C472.6,123.6,472.5,123.5,472.3,123.5L472.3,123.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M466.9,120.1c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4
        c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
        S467,120,466.9,120.1L466.9,120.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M468.5,116.8l0,0.2c0.2,4.4,0,8-0.4,10.9c-0.1,1-0.4,1.9-0.9,2.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="476.7" y1="119.1" x2="475.3" y2="119.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="478" y1="118.9" x2="476.7" y2="119.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M475.3,119.4c-0.4-1.5-1-2.3-1.8-2.6h-5.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M441.7,143.1l34.3-0.4c1.3,0,2.1-0.3,2.4-0.8l-0.4-22.5l-0.1-0.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M478,118.9c-0.3-1.3-1.2-2.5-2.7-3.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="477.9" y1="115.1" x2="475.3" y2="115.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="492.4" y1="131" x2="491" y2="128.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M475.3,115.5l-8,0.3l-22.7,0.2c-0.2-0.1-0.5-0.1-0.7,0l-2.2,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M489.2,170.5c1.6-8.3,2.7-17.8,3.3-28.6c0.2-1.9,0.4-3.2,0.4-4.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.4,131c0.5,1.7,0.7,4,0.4,6.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.9,137.7c0.1-1.5-0.2-1.7-0.7-0.5l-0.4,1.9c-0.9,4-1.7,8.2-2.5,12.4
        "/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="490.5" y1="123.3" x2="489.4" y2="114"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.4" y1="114" x2="488.1" y2="115"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="490.5" y1="123.3" x2="491" y2="128.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M489.2,175.9c1.7-6.3,3.2-14,4.3-23.1c1.4-10.1,1.8-17.8,1.1-23
        l-4.1-6.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M491,128.5c-1.7-2.7-3.4-4.7-5.3-6.1h-2.4c-1.8,5.6-3.6,13.2-5.3,23"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M477.9,115.1c2.7-0.2,5.6-1.3,8.9-3.4c0.8-0.3,1.2,0.8,1.2,3.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.4" y1="114" x2="489.3" y2="112.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M452.6,146.3v-0.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.3
        c0.1,0,0.2,0,0.3,0.1l0.1,0.3v0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M450.7,146.3c-0.2,0-0.4,0.1-0.4,0.4v1.1c0,0.2,0.1,0.4,0.4,0.4h3.1
        c0.2,0,0.4-0.1,0.4-0.4v-1.1c0-0.2-0.1-0.4-0.4-0.4h-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M451.7,146.3L451.7,146.3l-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-0.3
        c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="452.6" y1="146.3" x2="451.7" y2="146.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="453.7" y1="146.3" x2="452.6" y2="146.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M462.2,159.1c0-2-0.7-3.7-2.1-5.2c-1.4-1.4-3.2-2.1-5.2-2.1
        c-2,0-3.7,0.7-5.2,2.1c-1.4,1.4-2.1,3.2-2.1,5.2c0,2,0.7,3.7,2.1,5.2c1.4,1.4,3.2,2.1,5.2,2.1c2,0,3.7-0.7,5.2-2.1
        C461.5,162.8,462.2,161.1,462.2,159.1L462.2,159.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M452.3,183.5h2.7h0.8h1.5h0.8h2.7l0.8,0c1,0.1,1.5,0.6,1.7,1.4l0.1,0.9
        l0.1,1l0.1,0.6l0,0.3v0.1l-0.1,0.5c-0.2,0.5-0.9,0.8-2,0.9l-0.3,0l-0.7,0.1l-2.4,0.1l-0.9,0l-1.4,0.1l-0.7,0l-3.1,0.1l-2.6,0
        l-3.2,0l-0.7-0.1h0c-0.9-0.2-1.3-0.5-1.3-1l0.1-0.7l0-0.5l0.1-1.7l0-0.4l0-0.4c0-0.7,0.4-1.1,1.1-1.2l0.8,0h2.7h0.8h1.5H452.3
        L452.3,183.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M462.9,182.9l-0.7-0.1h-16.9l-1,0.1c-0.4,0.2-0.7,0.5-0.8,1l-0.4,5.3v0
        c0,0.3,0.1,0.5,0.3,0.7l0.4,0.3c0.5,0.3,1.3,0.4,2.4,0.4l11.6-0.2l5.3-0.4l0.5-0.2c0.5-0.2,0.9-0.6,1-1l0.1-0.5v-0.1l-0.5-3.6
        C464.2,183.8,463.7,183.2,462.9,182.9L462.9,182.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M473.1,181.7c1.8,0.1,3.5-0.2,5.1-0.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="473.1,181.7 472.8,183.6 478.2,182.8 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="478.2" y1="181" x2="478.2" y2="182.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M478.2,182.8c-0.1,0.8-0.3,1.5-0.6,2.2c-2.9,4.3-5.9,7.1-9,8.2l-2.3,0.6
        "/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="473.3,178.4 473.1,181.6 473.1,181.7 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M478,145.4c-0.5,2.6-0.9,5.4-1.4,8.4c-1.4,7.3-2.5,15.5-3.3,24.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.2" y1="175.9" x2="489.2" y2="170.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M489.2,151.6c-1.6,8.1-3.1,16.5-4.6,25.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.2" y1="170.5" x2="489.2" y2="151.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M488.1,115c-3.5,1.9-6.8,3.2-10,3.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="484.6" y1="176.9" x2="473.3" y2="178.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M483.3,176.9c2.9-17.3,5.9-32.6,9.1-45.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M484.6,176.9c-2,2-4.1,3.4-6.4,4.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M460.4,201.4l4.5-0.7c1.7-0.9,2.2-3.2,1.5-6.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="460.4" y1="201.4" x2="460.4" y2="206.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M460.4,206.1V232c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5v-56
        "/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M441.7,116.1l0,6.7c-1.2,0.4-2.3,1.2-3.2,2.1c-1.8,1.8-2.7,3.9-2.7,6.4
        c0,0.4,0,0.7,0.1,1l0.2,1.1c0.4,1.6,1.2,3.1,2.4,4.3c1,1,2.1,1.7,3.2,2.1v3.2"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="432,123.9 432,123 432,121.2 432,116.1 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="432" y1="127.4" x2="432" y2="123.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M416.6,138.8h7.1c2.2,0.1,4.2-0.5,6-2.1c0.9-0.7,1.5-1.4,1.9-2.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M431.5,134.7c0.2-0.4,0.4-0.8,0.5-1.2l0-0.2v-1.5v-4.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="441.7" y1="116.1" x2="432" y2="116.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M441.7,143.1c-1.2,1.5-4.1,2.4-8.8,2.6h-97.8c-4.6-0.2-7.5-1.1-8.8-2.6"
        />
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="415.6" y1="123.9" x2="416.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.6" y1="126.7" x2="415.6" y2="126.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="415.6" y1="123.9" x2="415.6" y2="126.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.6" y1="123.9" x2="416.6" y2="126.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="123.9" x2="395.5" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="127.4" x2="395.5" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="127.4" x2="394.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="395.5" y1="127.4" x2="395.5" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="138.8" x2="394.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="395.5" y1="134.7" x2="395.5" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="138.8" x2="395.5" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="134.7" x2="395.5" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.6" y1="134.7" x2="415.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="415.6" y1="138.8" x2="416.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="415.6" y1="138.8" x2="415.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.6" y1="134.7" x2="416.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="395.5" y1="138.8" x2="415.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="395.5" y1="127.4" x2="410.4" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="395.5" y1="123.9" x2="415.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="415.6" y1="134.7" x2="395.5" y2="134.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M439.1,166.4c-0.4-1.3-1.1-2.5-2.2-3.6c-2-2.4-3.6-3.7-4.8-3.8h-9.2"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="422.9,165.8 422.9,166.4 439.1,166.4 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="422.9" y1="158.9" x2="422.9" y2="165.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.2" y1="182.4" x2="440.2" y2="177.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.2" y1="177.5" x2="439.4" y2="168"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M439.4,168c0-0.5-0.1-1.1-0.2-1.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="432" y1="123.9" x2="416.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="431.5" y1="134.7" x2="416.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="410" y1="127.4" x2="432" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="328" y1="182.4" x2="328" y2="189.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M279,175.9v56c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5v-25.8"
        />
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="307.8,201.4 325.9,202.1 349,202.1 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M329.6,195.3c-12.1,0.4-21.3-0.1-27.8-1.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="307.8" y1="201.4" x2="307.8" y2="206.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301.9,193.8c-0.7,3.7-0.2,6,1.5,6.9l4.5,0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="328" y1="189.7" x2="329.6" y2="195.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290.2,145.4c3.3,1.9,10.4,2.9,21.3,3.2h145.1c11-0.2,18.1-1.2,21.3-3.2
        "/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="123.9" x2="373.6" y2="123.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M328,177.5c-11.3-0.3-22.3,0-33.1,0.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="127.4" x2="373.6" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="127.4" x2="372.7" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.6" y1="123.9" x2="373.6" y2="127.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M284.9,176.9c-2.9-17.3-5.9-32.6-9.1-45.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M329.1,166.4c-0.1,0.5-0.2,1.1-0.2,1.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="134.7" x2="373.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="138.8" x2="373.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="138.8" x2="372.7" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.6" y1="134.7" x2="373.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="328.8" y1="168" x2="328" y2="177.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="328" y1="177.5" x2="328" y2="182.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M307.2,183.5h2.7h0.8h1.5h0.8h2.7h0.8h1.5h0.8h2.7h0.8
        c0.9,0,1.3,0.4,1.3,1.2l0,0.4l0,0.4l0.1,1.7l0.1,0.7l0.1,0.6c0,0.5-0.4,0.8-1.4,1l-0.9,0.1l-3.2,0l-2.6,0l-3.1-0.1l-0.8,0
        l-1.4-0.1l-0.9-0.1l-2.4-0.1l-0.7-0.1l-0.2,0c-1.2-0.1-1.8-0.4-2-1l-0.1-0.4l0.1-0.7l0.2-1.3l0.1-0.6c0.1-0.9,0.6-1.4,1.5-1.5
        L307.2,183.5L307.2,183.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M304.1,184.1c0.2-0.6,0.6-1,1.2-1.2l0.7-0.1h16.9l0.9,0.1
        c0.2,0.1,0.4,0.1,0.5,0.3l0.1,0.1c0.2,0.2,0.3,0.5,0.3,0.8v0.3l0.2,2v0l0.2,2.8c0,0.3-0.1,0.6-0.3,0.8l-0.4,0.3
        c-0.5,0.2-1.3,0.3-2.3,0.3l-9-0.1l-8-0.6l-0.5-0.2c-0.4-0.2-0.7-0.5-0.8-0.8c-0.1-0.2-0.1-0.4-0.1-0.7v0l0.5-3.4L304.1,184.1
        L304.1,184.1z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="352.6" y1="123.9" x2="351.6" y2="123.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M318.5,164.3c-1.4,1.4-3.1,2.1-5.2,2.1c-2,0-3.7-0.7-5.2-2.1
        c-1.4-1.4-2.1-3.2-2.1-5.2c0-2,0.7-3.7,2.1-5.2c1.4-1.4,3.1-2.1,5.2-2.1c2,0,3.7,0.7,5.2,2.1c1.4,1.4,2.1,3.2,2.1,5.2
        C320.7,161.1,320,162.8,318.5,164.3L318.5,164.3z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351.6" y1="127.4" x2="352.6" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="352.6" y1="127.4" x2="352.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351.6" y1="123.9" x2="351.6" y2="127.4"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="336.2,116.1 336.2,121.2 336.2,123 336.2,123.9 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M283.6,176.9c2,2,4.1,3.4,6.4,4.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="336.2" y1="123.9" x2="336.2" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="294.9" y1="178.4" x2="283.6" y2="176.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M283.6,176.9c-1.5-8.7-3-17.2-4.6-25.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="279" y1="151.6" x2="279" y2="170.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M336.7,134.7c0.4,0.7,1,1.4,1.9,2.1c1.8,1.5,3.8,2.2,6,2.1h7.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M336.2,127.4v4.2v1.5l0,0.2c0.1,0.4,0.2,0.8,0.5,1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290.2,145.4l1.4,8.4c1.4,7.3,2.5,15.5,3.3,24.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351.6" y1="134.7" x2="352.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351.6" y1="138.8" x2="352.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="352.6" y1="134.7" x2="352.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351.6" y1="138.8" x2="351.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="336.2" y1="127.4" x2="351.6" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="336.2" y1="123.9" x2="351.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351.6" y1="134.7" x2="336.7" y2="134.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M345.3,158.9h-9.2c-1.2,0.1-2.9,1.4-4.8,3.8c-1.1,1.1-1.8,2.3-2.2,3.6"
        />
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="329.1,166.4 345.3,166.4 345.3,165.8 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="345.3" y1="165.8" x2="345.3" y2="158.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="352.6" y1="138.8" x2="372.7" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="352.6" y1="127.4" x2="372.7" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="123.9" x2="352.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="134.7" x2="352.6" y2="134.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="340.5,206.1 345.3,214.3 363.8,214.3 363.8,206.1 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M363.8,206.1c6,1,13,1.5,20.9,1.3c7.2,0.2,13.8-0.3,19.8-1.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M349,202.1c1.4-0.1,2.9-1,4.5-2.5c1.1-1.1,2.3-2.5,3.6-4.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="340.5" y1="206.1" x2="363.8" y2="206.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290,182.8c0.1,0.8,0.3,1.5,0.6,2.2c2.9,4.3,5.9,7.1,9,8.2l2.3,0.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290,181c-0.1,0.7-0.1,1.2,0,1.8"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="295.2,181.7 295.1,181.6 295.1,181.5 294.9,178.4 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="290,182.8 295.4,183.6 295.2,181.7 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="438.6" y1="195.3" x2="440.2" y2="189.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="404.4,206.1 404.4,214.3 422.9,214.3 427.7,206.1 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M411.1,195.3c1.2,1.8,2.4,3.2,3.6,4.3c1.6,1.5,3.1,2.3,4.5,2.5"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="419.2,202.1 442.3,202.1 460.4,201.4 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="427.7" y1="206.1" x2="404.4" y2="206.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.1" y1="195.3" x2="438.6" y2="195.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="422.9" y1="158.9" x2="345.3" y2="158.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.2" y1="189.7" x2="440.2" y2="182.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="349" y1="202.1" x2="419.2" y2="202.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.6" y1="138.8" x2="394.6" y2="138.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.6" y1="127.4" x2="394.6" y2="127.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="345.3" y1="165.8" x2="422.9" y2="165.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="123.9" x2="373.6" y2="123.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.6" y1="134.7" x2="373.6" y2="134.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="357.1" y1="195.3" x2="411.1" y2="195.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="404.4" y1="206.1" x2="363.8" y2="206.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="432" y1="116.1" x2="336.2" y2="116.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M295.2,181.7c-1.8,0.1-3.5-0.2-5.1-0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="279" y1="175.9" x2="279" y2="170.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M280.1,115c3.5,1.9,6.8,3.2,10,3.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M275.3,137.7c-0.2-2.8-0.1-5.1,0.4-6.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M279,170.5c-1.6-8.3-2.7-17.8-3.3-28.6c-0.2-1.9-0.4-3.2-0.4-4.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M275.3,137.7c-0.1-1.5,0.2-1.7,0.7-0.5l2.9,14.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="278.8" y1="114" x2="277.7" y2="123.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M277.7,123.3l-4.1,6.4c-0.7,5.2-0.3,12.9,1.1,23
        c1.1,9.1,2.6,16.9,4.3,23.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="277.7" y1="123.3" x2="277.2" y2="128.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="427.7" y1="206.1" x2="460.4" y2="206.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M473.3,178.4c-10.8-0.9-21.8-1.2-33.1-0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M466.3,193.8c-6.4,1.4-15.7,1.9-27.8,1.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M277.2,128.5c1.7-2.7,3.5-4.7,5.3-6.1h2.4c1.8,5.6,3.6,13.2,5.3,23"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="450.7" y1="146.3" x2="451.7" y2="146.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M275.8,131c0.4-0.8,0.9-1.6,1.3-2.3l0.1-0.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="307.8" y1="206.1" x2="340.5" y2="206.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="382.6" y1="18.7" x2="322.4" y2="19.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.3" y1="112.8" x2="488.8" y2="108.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="278.9" y1="112.8" x2="279.4" y2="108.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="336.2" y1="116.1" x2="326.5" y2="116.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.2" y1="177.5" x2="328" y2="177.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M280.1,115c0-2.5,0.4-3.6,1.2-3.3c3.3,2.1,6.3,3.2,8.9,3.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.2" y1="189.7" x2="328" y2="189.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.2" y1="182.4" x2="328" y2="182.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="328.8" y1="168" x2="439.4" y2="168"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="357.1" y1="195.3" x2="329.6" y2="195.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="278.8" y1="114" x2="280.1" y2="115"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="278.9" y1="112.8" x2="278.8" y2="114"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290.2,118.9c0.3-1.3,1.2-2.5,2.7-3.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="292.9" y1="115.5" x2="290.3" y2="115.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M326.5,143.1l-34.3-0.4c-1.3,0-2.1-0.3-2.4-0.8l0.4-22.5l0.1-0.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="314.5" y1="146.3" x2="315.6" y2="146.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="316.5" y1="146.3" x2="317.5" y2="146.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="316.5" y1="146.3" x2="315.6" y2="146.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M317.5,146.3L317.5,146.3l-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-0.3
        c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M315.6,146.3v-0.1l-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-0.3
        c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.5,146.3L314.5,146.3c-0.3,0-0.5,0.1-0.5,0.4v1.1
        c0,0.2,0.1,0.4,0.4,0.4h3.1c0.2,0,0.4-0.1,0.4-0.4v-1.1c0-0.2-0.1-0.4-0.4-0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M326.5,116.1l0,6.7c1.2,0.4,2.3,1.2,3.2,2.1c1.8,1.8,2.7,3.9,2.7,6.4
        l0,1l-0.2,1.1c-0.4,1.6-1.2,3.1-2.5,4.3c-1,1-2,1.7-3.2,2.1v3.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M326.5,116.1l-2.2,0c-0.2-0.1-0.5-0.1-0.7,0l-22.5-0.2l-8.1-0.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="300.4" y1="37.6" x2="301.4" y2="39.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="273.5" y1="88.5" x2="255.9" y2="88.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="300.7" y1="37" x2="301.7" y2="38"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M300.4,37.6c-0.7,1.9-1.5,4.3-2.4,7.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="308.8" y1="38.2" x2="308.9" y2="37.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M291.8,77.9c2.2-11.8,5.4-24.7,9.6-38.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M298,44.7c-3.9,13.4-7.1,27.8-9.7,43.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="291.8" y1="77.9" x2="301.4" y2="39.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="313.4" y1="97.3" x2="294" y2="99.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M294,99.3c3.9-19.2,8.8-39.5,14.8-61.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="321.1" y1="95.8" x2="313.4" y2="97.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M313.4,97.3c2.7,3.1,6.4,5.8,11.1,7.9c2.1,0.8,2.9,0.4,2.2-1.1l-5.6-8.3
        "/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="288.7" y1="89.3" x2="273.5" y2="89.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290.3,115.1c0.3-3.9-0.4-6.5-2.1-8c-2.4-1.7-4.2-3.2-5.5-4.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="286.2" y1="100.1" x2="282.7" y2="102.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="285.9" y1="95.5" x2="284.3" y2="95.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="287.4" y1="95.5" x2="291.8" y2="77.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M286.4,99.5c-1-2-1.2-3.4-0.5-4.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="287.4" y1="95.5" x2="285.9" y2="95.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="294" y1="99.3" x2="290.1" y2="99.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="290.1" y1="99.7" x2="286.2" y2="100.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="286.2" y1="100.1" x2="286.4" y2="99.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="286.4" y1="99.5" x2="287.4" y2="95.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M282.7,102.6c-1.5-1.6-2.1-2.9-2-4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="279.4" y1="108.6" x2="277.1" y2="108.3"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="273.5,89.3 272.8,95.5 284.3,95.5 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M279.7,105.8l-1.4-0.2c-0.5-0.1-0.8,0.2-0.9,0.7l-0.4,2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="279.4" y1="108.6" x2="279.7" y2="105.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M277.1,108.3l-0.4,0c-0.5-0.1-0.8,0.2-0.9,0.7l-0.5,2.6
        c-0.1,0.5,0.1,0.8,0.6,0.9l2.9,0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M282.2,96.6c-0.6,0.4-1,1.1-1.4,2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M280.8,98.6c-0.3,0.9-0.5,2.1-0.6,3.6l-0.4,3.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M284.3,95.5c-0.4,0.6-1.1,1-2.2,1.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="273.5" y1="88.5" x2="273.5" y2="89.3"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="285.4,88.1 284.7,88.5 273.5,88.5 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="289" y1="88.1" x2="288.4" y2="88.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="288.4" y1="88.1" x2="285.4" y2="88.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="285.4" y1="88.1" x2="285.4" y2="86.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M298,44.7c-1.5,4.5-3.2,10.1-5.3,16.8l-7.4,25.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="301.4" y1="39.4" x2="301.4" y2="39.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="301.4" y1="39.2" x2="301.7" y2="38"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="301.7" y1="38" x2="308.8" y2="38.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M285.4,86.9v-6.6c-0.2-3.5-2.2-5.3-6-5.4l-17.5,0.5
        c-3.7,0.5-5.7,2.2-5.9,5l-0.1,8.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M255.9,88.5l0,1.9c0.2,4.2,2.4,6.3,6.7,6.3l19.6-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M317.6,17.6c-1.1,0.3-2,0.4-2.9,0.4c-1.1,0-1.7,0.7-1.7,2.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M318.8,20.2c1.7-0.1,2.9-0.3,3.5-0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="315.9" y1="21.2" x2="316.7" y2="20.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M316.7,20.9c0.3-0.4,0.6-0.7,1-0.7h1.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301.7,38l0.3-1.2c0.7-2.1,0.9-3.6,0.6-4.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="300.7" y1="37" x2="300.4" y2="37.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M302.6,32.3c-0.5,0.8-1.1,2.4-1.9,4.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M313,20.2c-0.9,0.3-1.5,0.6-1.9,0.9l-4.9,4.6l-2.3,1.9
        c-0.9,0.9-1.4,2.3-1.4,4.2l0.2,0.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M308.9,37.6h4.3c0.3-6.1,1-9.8,1.9-11.3l0,0c0.3-0.4,0.4-0.8,0.5-1.2
        l0.5-2.8c0.2-0.6,0.4-1.1,0.6-1.4"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="315.9,21.2 313,21.2 313,20.8 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M308.9,37.6c0.9-6.4,1.8-10.3,2.6-11.6l0,0c0.3-0.4,0.4-0.8,0.5-1.2
        l0.5-2.8c0.1-0.4,0.3-0.8,0.4-1.1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="313,20.8 313,20.4 313,20.2 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M317.6,17.6l-1.5,1c-0.3,0.7-0.4,1.5-0.2,2.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M318.8,17.6c0.2,0.1,0.3,0.4,0.3,0.7l1.8-0.5l0.7-0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M322.4,19.5v-1.2c0-0.7-0.3-0.9-0.8-0.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="321.5" y1="17.5" x2="318.8" y2="17.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="318.8" y1="17.6" x2="317.6" y2="17.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="454.8" y1="97.3" x2="447.1" y2="95.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="512.3" y1="88.5" x2="494.7" y2="88.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="467.8" y1="37.6" x2="466.8" y2="39.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="467.5" y1="37" x2="466.5" y2="38"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M470.2,44.7c-0.9-2.8-1.7-5.2-2.4-7.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="459.3" y1="37.6" x2="459.4" y2="38.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="476.4" y1="77.9" x2="466.8" y2="39.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="478.1" y1="99.7" x2="482" y2="100.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M485.5,102.6c-1.2,1.3-3.1,2.9-5.5,4.5c-1.7,1.5-2.4,4.1-2.1,8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="480.8" y1="95.5" x2="481.9" y2="99.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M470.2,44.7c3.9,13.4,7.1,27.8,9.7,43.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="479.8" y1="88.1" x2="482.8" y2="88.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="479.5" y1="89.3" x2="494.8" y2="89.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="482.3" y1="95.5" x2="480.8" y2="95.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M476.4,77.9c-2.2-11.8-5.4-24.7-9.6-38.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M459.4,38.2c5.9,21.5,10.8,41.9,14.8,61.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M486,96.6l19.6,0.1c4.3-0.1,6.5-2.2,6.7-6.3l0-1.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="488.8" y1="108.6" x2="488.5" y2="105.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M487.4,98.6c0.2,1.1-0.5,2.4-2,4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="485.5" y1="102.6" x2="482" y2="100.1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="494.8,89.3 495.4,95.5 483.9,95.5 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="488.8" y1="108.6" x2="491.1" y2="108.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M491.1,108.3l-0.4-2c-0.1-0.5-0.4-0.7-0.9-0.7l-1.4,0.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M489.3,112.8l2.9-0.3c0.5-0.1,0.7-0.4,0.6-0.9l-0.5-2.6
        c-0.1-0.5-0.4-0.7-0.9-0.7l-0.4,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M488.5,105.8l-0.4-3.7c-0.1-1.5-0.3-2.6-0.6-3.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M487.4,98.6c-0.4-1-0.8-1.6-1.4-2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="483.9" y1="95.5" x2="482.3" y2="95.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M483.9,95.5c0.4,0.6,1.1,1,2.2,1.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="482" y1="100.1" x2="481.9" y2="99.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M481.9,99.5c1-2,1.2-3.4,0.5-4.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="482.8" y1="88.1" x2="482.8" y2="86.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="494.7" y1="88.5" x2="494.8" y2="89.3"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="494.7,88.5 483.5,88.5 482.8,88.1 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M512.3,88.5l-0.1-8.1c-0.2-2.9-2.1-4.5-5.9-5l-17.5-0.5
        c-3.7,0.1-5.7,1.9-6,5.4v6.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="480.8" y1="95.5" x2="476.4" y2="77.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="474.2" y1="99.3" x2="454.8" y2="97.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="474.2" y1="99.3" x2="478.1" y2="99.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290.1,99.7c2.5,4.8,6,7.3,10.4,7.4h167.1c4.4-0.2,7.9-2.7,10.4-7.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="479.2" y1="88.1" x2="479.8" y2="88.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="466.5" y1="38" x2="459.4" y2="38.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M482.8,86.9l-7.4-25.3c-2-6.7-3.8-12.4-5.3-16.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="466.8" y1="39.4" x2="466.8" y2="39.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="466.8" y1="39.2" x2="466.5" y2="38"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M465.6,32.3c-0.3,1-0.1,2.5,0.6,4.6l0.3,1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M450.6,17.6l1.5,1c0.3,0.7,0.4,1.5,0.2,2.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M467.5,37c-0.8-2.3-1.5-3.9-1.9-4.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M455.3,20.2c0-1.5-0.6-2.2-1.7-2.2c-0.9,0-1.9-0.1-2.9-0.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="467.8" y1="37.6" x2="467.5" y2="37"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="451.5" y1="20.9" x2="452.3" y2="21.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M451.5,20.9c0.2,0.3,0.4,0.8,0.6,1.4l0.5,2.8c0.1,0.4,0.3,0.8,0.5,1.2
        l0,0c0.9,1.4,1.5,5.2,1.9,11.3h4.3"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="452.3,21.2 455.2,21.2 455.2,20.8 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="455.2,20.8 455.3,20.4 455.3,20.2 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M459.3,37.6c-0.9-6.4-1.8-10.3-2.6-11.6l0,0c-0.3-0.4-0.4-0.8-0.5-1.2
        l-0.5-2.8l-0.4-1.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M465.6,32.3c0.1-0.2,0.1-0.3,0.2-0.5c-0.1-1.9-0.5-3.2-1.4-4.2l-2.3-1.9
        l-4.9-4.6c-0.4-0.3-1-0.6-1.9-0.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="445.8" y1="19.5" x2="385.6" y2="18.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="318.8,20.2 383,19.9 449.4,20.2 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M308.8,38.2h6.2l12.4-0.3c14.1-0.3,28.1-0.5,42-0.6l28.1,0
        c14.4,0.1,28.8,0.3,43.3,0.7l18.6,0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M308.8,39.7h6.2l12.4-0.3c14.1-0.3,28.1-0.5,42-0.6l28.1,0
        c14.4,0.1,28.8,0.3,43.3,0.7l18.6,0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M454.8,97.3c-2.7,3.1-6.4,5.8-11.1,7.9c-2.1,0.8-2.9,0.4-2.2-1.1
        l5.6-8.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M414.9,95c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.7v0.4l-0.3,0.2
        l-0.6,0.1h-0.1l-0.5-0.1c-0.2-0.1-0.3-0.1-0.3-0.2v-0.4c0-0.2,0.1-0.5,0.3-0.7c0.2-0.1,0.3-0.2,0.5-0.2L414.9,95L414.9,95z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M321.1,95.8l0.7-0.1l31.7-1.3c8.9-0.2,17.7-0.3,26.4-0.3l8.5,0
        c8.7,0,17.5,0.1,26.4,0.3l31.7,1.3l0.7,0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M354.3,95.9c0-0.2-0.1-0.5-0.3-0.7c-0.1-0.1-0.3-0.2-0.5-0.2l-0.1,0
        c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v0.4l0.3,0.2l0.6,0.1h0.1l0.5-0.1l0.3-0.2V95.9L354.3,95.9z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="382.6" y1="18.7" x2="385.6" y2="18.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="385.6,18.7 385.6,17.7 382.6,17.7 382.6,18.7 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M449.4,20.2h1.1c0.4,0,0.7,0.3,1,0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M449.4,20.2c-1.7-0.1-2.9-0.3-3.5-0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M437.3,26.1c-13.3-0.3-26.6-0.5-39.8-0.6l-28.1,0
        c-12.8,0.1-25.6,0.3-38.5,0.6c-1,0.1-1.4,0.5-1,1.2c0.4,0.7,1.7,1.1,4,1.1c11.9-0.2,23.7-0.4,35.4-0.5l28.1,0
        c12.2,0.1,24.4,0.2,36.7,0.5c2.3,0,3.7-0.4,4-1.1C438.7,26.6,438.4,26.2,437.3,26.1L437.3,26.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M449.4,17.6c-0.2,0.1-0.3,0.4-0.3,0.7l-1.8-0.5
        c-0.3-0.1-0.5-0.2-0.7-0.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="450.6" y1="17.6" x2="449.4" y2="17.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="449.4" y1="17.6" x2="446.7" y2="17.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M446.7,17.5c-0.6-0.1-0.8,0.1-0.8,0.8v1.2"/>
    </g>
  </g>
  <g id="Layer_5">
    <g>
      <path fill="none" d="M274.2,908.6c-3.7,0.5-5.7,2.2-5.9,5.1l-0.1,8.1l0,0h0l0,1.9c0.2,4.2,2.4,6.3,6.7,6.3l19.3,0l0.5-0.4
        c0.5-0.6,1-1.5,1.5-2.7c-0.6,1.5-1.5,2.2-2.9,2.1l-17.6,0c-3.8-0.1-5.9-2-6-5.7l0-1.7h0l0,0l0.1-7.3c0.2-2.6,1.9-4.1,5.3-4.6
        l15.8-0.4c3.4,0.1,5.2,1.8,5.4,4.9l0.3,11.4l0.7-2.9l0.4-2.8v-6.3c-0.2-3.5-2.2-5.3-6-5.4L274.2,908.6L274.2,908.6z"/>
      <path fill="none" d="M269.7,921.6L269.7,921.6v1.7c0.2,3.7,2.2,5.6,6,5.7l17.6,0c1.4,0.1,2.3-0.6,2.9-2.1l0.4-1.2l-0.3-11.4
        c-0.2-3.2-2-4.8-5.4-4.9l-15.8,0.4c-3.4,0.4-5.1,2-5.3,4.6L269.7,921.6L269.7,921.6L269.7,921.6z"/>
      <path fill="none" d="M334.7,851.6c0-0.7-0.3-0.9-0.8-0.8l-3.9,0.1c-1.1,0.3-2,0.4-2.9,0.4c-1.1,0-1.7,0.7-1.7,2.2
        c1.5-0.5,3.3-0.8,5.3-0.8c1.3-0.1,2.7,0,4.1,0.1V851.6L334.7,851.6z"/>
      <path fill="none" d="M328.9,864.2c1.7-6.8,2.2-10.6,1.7-11.5c-2,0.1-3.8,0.4-5.3,0.8c-1.4,1.3-2.5,2.6-3.5,3.9
        c-0.9,1.2-1.6,2.4-2.1,3.6l-0.8,2.5c-5,16.3-9.9,34.4-14.7,54.2c6.2-0.9,12.4-0.9,18.6,0C324.2,901.1,326.2,883.3,328.9,864.2
        L328.9,864.2z"/>
      <path fill="none" d="M330.6,852.7c0.6,0.9,0,4.7-1.7,11.5c17.4-0.7,35.5-1,54.1-1.1c4.3,0,8.6,0,12.9,0h2.2c4,0,7.9,0,11.8,0
        c18.6,0.1,36.6,0.4,54.1,1.1c-1.7-6.8-2.2-10.6-1.7-11.5c-1.3-0.1-2.6,0-4.1,0.1l-33.4-0.4v4.1c-0.2,2.6-3.1,4.1-8.8,4.3h-39.2
        c-5.6-0.2-8.6-1.6-8.8-4.3v-4.1l-33.4,0.4C333.2,852.7,331.9,852.7,330.6,852.7L330.6,852.7z"/>
      <path fill="none" d="M325.3,853.6c-0.9,0.3-1.5,0.6-1.9,0.9l-4.9,4.6l-2.3,1.9c-0.9,0.9-1.4,2.3-1.4,4.2l0.2,0.4l0,0
        c-0.5,0.8-1.1,2.4-1.9,4.7l0,0h0.1l0,0l-0.2,0.7c-0.7,1.9-1.5,4.2-2.4,7.1h0l0,0c-1.5,4.5-3.2,10.1-5.3,16.8l-7.3,25l-0.4,2.8
        l-2.6,15.8l0.9-0.1l4.8-0.8c0.5-4.9,1.6-10.5,3.2-16.6l0.8-3.2c4.8-19.8,9.7-37.9,14.7-54.2l-0.5,1.2
        c-4.4,10.4-9.3,26.5-14.6,48.2l-1.5,6.1l-3.2,0.4l1.4-5.5c4.7-17.4,9.8-33.6,15.4-48.7l1.5-3.9l4.3-4
        C322.8,856.2,323.9,854.9,325.3,853.6L325.3,853.6z"/>
      <path fill="none" d="M316.1,865.3l2.4-0.6l0.5-1.2l0.8-2.5c0.5-1.2,1.2-2.4,2.1-3.6l-4.3,4L316.1,865.3L316.1,865.3z"/>
      <path fill="none" d="M318.5,864.7l-2.4,0.6c-5.6,15-10.7,31.2-15.4,48.7l3.2-1C309.2,891.2,314.1,875.1,318.5,864.7L318.5,864.7z"
        />
      <path fill="none" d="M304.6,920.2l-0.3,1.1c-2.3,8.9-3.5,16.5-3.4,22.8l-0.1,9.4l19-0.2l0-15.6c0.2-5.8,0.5-11.7,0.9-17.8
        c0-0.8-0.4-1.3-1-1.4l-0.4,0c-4.5-0.4-8.9-0.3-13.4,0.2l-0.1,0C305.2,918.7,304.8,919.2,304.6,920.2L304.6,920.2L304.6,920.2z
         M317.1,923.1c1.4,1.4,2.1,3.1,2.1,5.1c0,2-0.7,3.7-2.1,5.1c-1.4,1.4-3.1,2.1-5.1,2.1c-2,0-3.7-0.7-5.1-2.1
        c-1.4-1.4-2.1-3.1-2.1-5.1c0-2,0.7-3.7,2.1-5.1c1.4-1.4,3.1-2.1,5.1-2.1C314,921,315.7,921.7,317.1,923.1L317.1,923.1z
         M318.3,944.6c0,2-0.7,3.7-2.1,5.1c-1.4,1.4-3.1,2.1-5.1,2.1c-2,0-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3.1-2.1-5.1c0-2,0.7-3.7,2.1-5.1
        c1.4-1.4,3.1-2.1,5.1-2.1c2,0,3.7,0.7,5.1,2.1C317.6,940.9,318.3,942.6,318.3,944.6L318.3,944.6z"/>
      <path fill="none" d="M319.2,928.2c0-2-0.7-3.7-2.1-5.1c-1.4-1.4-3.1-2.1-5.1-2.1c-2,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3.1-2.1,5.1
        c0,2,0.7,3.7,2.1,5.1c1.4,1.4,3.1,2.1,5.1,2.1c2,0,3.7-0.7,5.1-2.1C318.5,931.9,319.2,930.2,319.2,928.2L319.2,928.2z
         M315.7,924.5c1,1,1.5,2.3,1.5,3.7c0,1.4-0.5,2.7-1.5,3.7c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.3-1.5-3.7
        c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5C313.4,923,314.7,923.5,315.7,924.5L315.7,924.5z"/>
      <path fill="none" d="M317.2,928.2c0-1.4-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.7-1.5c-1.4,0-2.7,0.5-3.7,1.5c-1,1-1.5,2.3-1.5,3.7
        c0,1.4,0.5,2.7,1.5,3.7c1,1,2.2,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5C316.7,930.9,317.2,929.6,317.2,928.2L317.2,928.2z
         M315.7,928.2c0,1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6
        c0.7-0.7,1.6-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1C315.3,926.3,315.7,927.2,315.7,928.2L315.7,928.2z"/>
      <path fill="none" d="M314.6,930.8c0.7-0.7,1.1-1.6,1.1-2.6c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1
        c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1C313,931.8,313.9,931.5,314.6,930.8L314.6,930.8z
         M313.8,928.2c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.4-0.5-0.8-0.5-1.3
        c0-0.5,0.2-0.9,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5C313.6,927.3,313.8,927.7,313.8,928.2L313.8,928.2z"/>
      <path fill="none" d="M313.3,929.5c0.4-0.4,0.5-0.8,0.5-1.3c0-0.5-0.2-0.9-0.5-1.3c-0.4-0.4-0.8-0.5-1.3-0.5
        c-0.5,0-0.9,0.2-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3c0.4,0.4,0.8,0.5,1.3,0.5
        C312.5,930,312.9,929.8,313.3,929.5L313.3,929.5z"/>
      <path fill="none" d="M316.2,949.7c1.4-1.4,2.1-3.1,2.1-5.1c0-2-0.7-3.7-2.1-5.1c-1.4-1.4-3.1-2.1-5.1-2.1c-2,0-3.7,0.7-5.1,2.1
        c-1.4,1.4-2.1,3.1-2.1,5.1c0,2,0.7,3.7,2.1,5.1c1.4,1.4,3.1,2.1,5.1,2.1C313.1,951.9,314.8,951.2,316.2,949.7L316.2,949.7z
         M314.8,940.9c1,1,1.5,2.3,1.5,3.7c0,1.4-0.5,2.7-1.5,3.7c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.3-1.5-3.7
        c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5C312.5,939.4,313.7,939.9,314.8,940.9L314.8,940.9z"/>
      <path fill="none" d="M316.3,944.6c0-1.4-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.7-1.5c-1.4,0-2.7,0.5-3.7,1.5c-1,1-1.5,2.3-1.5,3.7
        c0,1.4,0.5,2.7,1.5,3.7c1,1,2.2,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5C315.8,947.3,316.3,946.1,316.3,944.6L316.3,944.6z M313.7,942
        c0.7,0.7,1.1,1.6,1.1,2.6c0,1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6
        c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1C312.1,941,312.9,941.3,313.7,942L313.7,942z"/>
      <path fill="none" d="M314.7,944.6c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
        c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1C314.4,946.5,314.7,945.6,314.7,944.6L314.7,944.6z
         M311.1,942.8c0.5,0,0.9,0.2,1.3,0.5c0.4,0.4,0.5,0.8,0.5,1.3c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.5-1.3,0.5s-0.9-0.2-1.3-0.5
        c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3C310.1,943,310.6,942.8,311.1,942.8L311.1,942.8z"/>
      <path fill="none" d="M312.4,943.3c-0.4-0.4-0.8-0.5-1.3-0.5s-0.9,0.2-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3
        c0.4,0.4,0.8,0.5,1.3,0.5s0.9-0.2,1.3-0.5c0.4-0.4,0.5-0.8,0.5-1.3C312.9,944.1,312.7,943.7,312.4,943.3L312.4,943.3z"/>
      <path fill="none" d="M304.3,921.3l0.3-1.1l0,0c0.2-0.9,0.7-1.4,1.2-1.5l0.1,0c4.5-0.5,8.9-0.6,13.4-0.2l0.4,0c0.7,0.1,1,0.6,1,1.4
        c-0.4,6.1-0.7,12-0.9,17.8l0,15.6v16.7c-0.1,0.6-0.6,0.9-1.8,0.8h-16l-1.1-0.3l-0.8-0.2l0.1,1.8l3,0.5h18.6v-1.3v-30.8l0-2.8
        c0.1-3.2,0.3-6.4,0.4-9.7l0.6-10.3c-6.2-0.9-12.4-0.9-18.6,0l-0.8,3.2c-1.6,6.2-2.7,11.7-3.2,16.6c-0.1,1.4-0.2,2.8-0.3,4.2
        l-0.1,11.6l0.8,0.2l0.1-9.4C300.8,937.8,302,930.2,304.3,921.3L304.3,921.3z"/>
      <polygon fill="none" points="300.7,914 299.3,919.4 302.4,919 303.9,912.9 300.7,914 		"/>
      <path fill="none" d="M328.9,864.2c-2.8,19.1-4.8,36.9-6,53.5H375c4.8-0.1,8.5,2,11.2,6.1l8.4,12.4c1.1,1.5,4.3,2.3,9.5,2.2H471
        c-0.2-6.3-0.6-12.8-1-19.6l-0.1-1.1c-1.2-16.6-3.2-34.4-6-53.5c-17.4-0.7-35.5-1-54.1-1.1c-3.9,0-7.8,0-11.8,0h-2.2
        c-4.3,0-8.6,0-12.9,0C364.4,863.2,346.4,863.5,328.9,864.2L328.9,864.2z M342.6,909.9c-4.3-0.4-6.5-2.3-6.7-5.7l2.4-27.3
        c0.4-4.5,2.9-6.7,7.6-6.5l51-0.7l50.1,0.7c4.7-0.2,7.3,2,7.6,6.5l4.1,47.4c0.3,2.7-1.2,4.2-4.5,4.5l-30.5,0.5
        c-4.5,0-7.6-0.9-9.4-2.7l-13.2-14.4c-1.3-1.7-3.4-2.4-6.5-2.2H342.6L342.6,909.9z"/>
      <path fill="none" d="M335.9,904.2c0.2,3.5,2.4,5.4,6.7,5.7h52c3.1-0.2,5.3,0.6,6.5,2.2l13.2,14.4c1.8,1.8,4.9,2.7,9.4,2.7
        l30.5-0.5c3.3-0.3,4.8-1.8,4.5-4.5l-4.1-47.4c-0.4-4.5-2.9-6.7-7.6-6.5l-50.1-0.7l-51,0.7c-4.7-0.2-7.2,2-7.6,6.5L335.9,904.2
        L335.9,904.2z"/>
      <polygon fill="none" points="398,852.1 398,851 394.9,851 394.9,852.1 398,852.1 		"/>
      <path fill="none" d="M398,852.1h-3.1l-26.8,0.3v4.1c0.2,2.6,3.1,4.1,8.8,4.3H416c5.6-0.2,8.6-1.6,8.8-4.3v-4.1L398,852.1
        L398,852.1z M382.3,853.9c0-0.5,0.2-0.7,0.7-0.7h26.7c0.5,0,0.7,0.2,0.7,0.7v2.7c0,0.5-0.2,0.7-0.7,0.7H383
        c-0.4,0-0.7-0.3-0.7-0.7V853.9L382.3,853.9z"/>
      <path fill="none" d="M383.1,853.1c-0.5,0-0.7,0.2-0.7,0.7v2.7c0,0.5,0.2,0.7,0.7,0.7h26.9c0.4,0,0.7-0.3,0.7-0.7v-2.7
        c0-0.5-0.2-0.7-0.7-0.7H383.1L383.1,853.1z M398.8,854.9c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
        c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
        C398.9,855.4,398.8,855.2,398.8,854.9L398.8,854.9z M395.5,854.9c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3
        c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
        C395.6,855.4,395.5,855.2,395.5,854.9L395.5,854.9z M402.2,854.9c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3
        c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
        C402.3,855.4,402.2,855.2,402.2,854.9L402.2,854.9z M389.8,855.8c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7
        c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
        C390.3,855.7,390.1,855.8,389.8,855.8L389.8,855.8z M392.5,854.2c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
        c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7
        C392.2,854.6,392.3,854.4,392.5,854.2L392.5,854.2z M386.9,854.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
        c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3
        C386.5,854,386.7,854.1,386.9,854.2L386.9,854.2z M406.6,855.8c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7
        c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
        C407.1,855.7,406.9,855.8,406.6,855.8L406.6,855.8z"/>
      <path fill="none" d="M399.1,854.2c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3C399.5,854,399.2,854.1,399.1,854.2L399.1,854.2z"/>
      <path fill="none" d="M395.8,854.2c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3C396.2,854,396,854.1,395.8,854.2L395.8,854.2z"/>
      <path fill="none" d="M402.4,854.2c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
        c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3C402.8,854,402.6,854.1,402.4,854.2L402.4,854.2z"/>
      <path fill="none" d="M389.1,855.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
        c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7C388.9,855.2,389,855.4,389.1,855.6L389.1,855.6z"/>
      <path fill="none" d="M393.2,854c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
        c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C393.6,854.1,393.4,854,393.2,854L393.2,854z"/>
      <path fill="none" d="M387.2,854.9c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3
        c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
        C387.1,855.4,387.2,855.2,387.2,854.9L387.2,854.9z"/>
      <path fill="none" d="M406,855.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
        c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7C405.7,855.2,405.8,855.4,406,855.6L406,855.6z"/>
      <path fill="none" d="M458.2,851.6v1.2c1.5-0.1,2.9-0.1,4.1-0.1c2,0.1,3.8,0.4,5.3,0.8c0-1.5-0.6-2.2-1.7-2.2
        c-0.9,0-1.9-0.1-2.9-0.4l-3.9-0.1C458.4,850.7,458.2,850.9,458.2,851.6L458.2,851.6z"/>
      <path fill="none" d="M469.5,854.5c-0.4-0.3-1-0.6-1.9-0.9c1.4,1.3,2.5,2.6,3.4,3.9l4.3,4l1.5,3.9c5.6,15,10.7,31.2,15.4,48.7
        l1.4,5.5l-3.2-0.4l-1.5-6.1c-5.3-21.8-10.2-37.8-14.6-48.2l-0.5-1.2c5,16.3,9.9,34.4,14.7,54.2l0.8,3.2c1.6,6.2,2.7,11.7,3.2,16.6
        l4.8,0.8l0.9,0.1l-2.6-15.8l-0.4-2.8l-7.3-25c-2.1-6.8-3.8-12.4-5.3-16.8l0,0h0c-0.9-2.8-1.7-5.2-2.4-7.1l-0.2-0.7l0,0h0.1l0,0
        c-0.8-2.3-1.5-3.8-1.9-4.7l0,0l0.2-0.4c-0.1-1.9-0.5-3.2-1.4-4.2l-2.3-1.9L469.5,854.5L469.5,854.5z"/>
      <path fill="none" d="M471,857.5c0.9,1.2,1.6,2.4,2.1,3.6l0.8,2.5l0.5,1.2l2.4,0.6l-1.5-3.9L471,857.5L471,857.5z"/>
      <path fill="none" d="M473.1,861.1c-0.5-1.2-1.2-2.4-2.1-3.6c-1-1.3-2.1-2.6-3.4-3.9c-1.5-0.5-3.3-0.8-5.3-0.8
        c-0.6,0.9,0,4.7,1.7,11.5c2.8,19.1,4.8,36.9,6,53.5c6.2-0.9,12.4-0.9,18.6,0c-4.8-19.8-9.7-37.9-14.7-54.2L473.1,861.1
        L473.1,861.1z"/>
      <path fill="none" d="M476.8,865.3l-2.4-0.6c4.4,10.4,9.3,26.5,14.6,48.2l3.2,1C487.5,896.5,482.4,880.3,476.8,865.3L476.8,865.3z"
        />
      <path fill="none" d="M473.6,918.4l-0.4,0c-0.7,0.1-1,0.6-1,1.4c0.4,6.4,0.8,12.5,1,18.5v14.8l19,0.2L492,944
        c0-6.2-1.1-13.8-3.4-22.8l-0.3-1.1l0,0c-0.2-0.9-0.7-1.4-1.2-1.5l-0.1,0C482.5,918.1,478,918,473.6,918.4L473.6,918.4z M486,933.3
        c-1.4,1.4-3.1,2.1-5.1,2.1c-2,0-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3.1-2.1-5.1c0-2,0.7-3.7,2.1-5.1c1.4-1.4,3.1-2.1,5.1-2.1
        c2,0,3.7,0.7,5.1,2.1c1.4,1.4,2.1,3.1,2.1,5.1C488.1,930.2,487.4,931.9,486,933.3L486,933.3z M481.8,937.4c2,0,3.7,0.7,5.1,2.1
        c1.4,1.4,2.1,3.1,2.1,5.1c0,2-0.7,3.7-2.1,5.1c-1.4,1.4-3.1,2.1-5.1,2.1s-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3.1-2.1-5.1
        c0-2,0.7-3.7,2.1-5.1C478.1,938.1,479.8,937.4,481.8,937.4L481.8,937.4z"/>
      <path fill="none" d="M480.9,935.4c2,0,3.7-0.7,5.1-2.1c1.4-1.4,2.1-3.1,2.1-5.1c0-2-0.7-3.7-2.1-5.1c-1.4-1.4-3.1-2.1-5.1-2.1
        c-2,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3.1-2.1,5.1c0,2,0.7,3.7,2.1,5.1C477.2,934.7,478.9,935.4,480.9,935.4L480.9,935.4z
         M480.9,923c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7c0,1.4-0.5,2.7-1.5,3.7c-1,1-2.2,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5
        c-1-1-1.5-2.3-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7C478.2,923.5,479.5,923,480.9,923L480.9,923z"/>
      <path fill="none" d="M484.6,924.5c-1-1-2.2-1.5-3.7-1.5c-1.4,0-2.7,0.5-3.7,1.5c-1,1-1.5,2.3-1.5,3.7c0,1.4,0.5,2.7,1.5,3.7
        c1,1,2.3,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7C486.1,926.8,485.6,925.5,484.6,924.5L484.6,924.5z M484.5,928.2
        c0,1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6
        c0.7-0.7,1.6-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1C484.2,926.3,484.5,927.2,484.5,928.2L484.5,928.2z"/>
      <path fill="none" d="M483.5,930.8c0.7-0.7,1.1-1.6,1.1-2.6c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1
        c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1C481.9,931.8,482.8,931.5,483.5,930.8L483.5,930.8z
         M480.9,930c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5
        c0.5,0,0.9,0.2,1.3,0.5c0.4,0.4,0.5,0.8,0.5,1.3c0,0.5-0.2,0.9-0.5,1.3C481.8,929.8,481.4,930,480.9,930L480.9,930z"/>
      <path fill="none" d="M479.6,929.5c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5c0.4-0.4,0.5-0.8,0.5-1.3c0-0.5-0.2-0.9-0.5-1.3
        c-0.4-0.4-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3C479.1,928.7,479.2,929.1,479.6,929.5L479.6,929.5z"/>
      <path fill="none" d="M486.9,939.5c-1.4-1.4-3.1-2.1-5.1-2.1s-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3.1-2.1,5.1c0,2,0.7,3.7,2.1,5.1
        c1.4,1.4,3.1,2.1,5.1,2.1s3.7-0.7,5.1-2.1c1.4-1.4,2.1-3.1,2.1-5.1C489.1,942.6,488.3,940.9,486.9,939.5L486.9,939.5z
         M476.6,944.6c0-1.4,0.5-2.7,1.5-3.7c1-1,2.3-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5c1,1,1.5,2.3,1.5,3.7c0,1.4-0.5,2.7-1.5,3.7
        c-1,1-2.2,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5C477.1,947.3,476.6,946.1,476.6,944.6L476.6,944.6z"/>
      <path fill="none" d="M478.1,940.9c-1,1-1.5,2.3-1.5,3.7c0,1.4,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5
        c1-1,1.5-2.3,1.5-3.7c0-1.4-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5C480.4,939.4,479.2,939.9,478.1,940.9L478.1,940.9z M484.4,942
        c0.7,0.7,1.1,1.6,1.1,2.6c0,1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6
        c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1C482.8,941,483.7,941.3,484.4,942L484.4,942z"/>
      <path fill="none" d="M485.5,944.6c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
        c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1C485.1,946.5,485.5,945.6,485.5,944.6L485.5,944.6z
         M483.1,943.3c0.4,0.4,0.5,0.8,0.5,1.3c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5
        c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5C482.3,942.8,482.8,943,483.1,943.3L483.1,943.3z"/>
      <path fill="none" d="M483.6,944.6c0-0.5-0.2-0.9-0.5-1.3c-0.4-0.4-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5
        c-0.4,0.4-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5
        C483.5,945.5,483.6,945.1,483.6,944.6L483.6,944.6z"/>
      <polygon fill="none" points="489,912.9 490.4,919 493.6,919.4 492.2,914 489,912.9 		"/>
      <path fill="none" d="M495.1,913.6v6.3l0.4,2.8l0.7,2.9l0.3-11.4c0.2-3.2,2-4.8,5.4-4.9l15.8,0.4c3.4,0.4,5.1,2,5.3,4.6l0.1,7.3
        l0,0h0l0,1.7c-0.2,3.7-2.2,5.6-6,5.7l-17.6,0c-1.4,0.1-2.3-0.6-2.9-2.1c0.5,1.3,1,2.2,1.5,2.7l0.5,0.4l19.3,0
        c4.3-0.1,6.5-2.2,6.7-6.3l0-1.9h0l0,0l-0.1-8.1c-0.2-2.9-2.2-4.6-5.9-5.1l-17.6-0.5C497.3,908.3,495.4,910.1,495.1,913.6
        L495.1,913.6z"/>
      <path fill="none" d="M502,909.3c-3.4,0.1-5.2,1.8-5.4,4.9l-0.3,11.4l0.4,1.2c0.6,1.5,1.5,2.2,2.9,2.1l17.6,0c3.8-0.1,5.9-2,6-5.7
        l0-1.7h0l0,0l-0.1-7.3c-0.2-2.6-1.9-4.1-5.3-4.6L502,909.3L502,909.3z"/>
      <path fill="none" d="M375,917.7H323l-0.6,10.3h45.1c2.5,0,4.5,1.3,6,3.9c1.1,2,2.1,4,3.2,5.8c3.1,5.3,6.2,9.9,9.3,13.6
        c1.8,2.1,5.8,3.1,11.7,2.8l73.5-0.8v-14.9h-66.9c-5.2,0-8.4-0.7-9.5-2.2l-8.4-12.4C383.6,919.7,379.9,917.7,375,917.7L375,917.7z"
        />
      <path fill="none" d="M496.3,925.6l-0.7-2.9l2.6,15.8l1.9,0.8l0.8-0.1l-0.4-3.7c-0.1-1.5-0.3-2.6-0.6-3.6l-0.2-0.5
        c-0.3-0.7-0.7-1.1-1.2-1.5l0,0l0.2,0l-0.5-0.4c-0.5-0.6-1-1.5-1.5-2.7L496.3,925.6L496.3,925.6z"/>
      <path fill="none" d="M500.8,939.2l-0.8,0.1l-1.1,0.1l0.7,3.8l0.5-0.1l1.1-0.2l1.2-0.2c1,0,1.3-0.4,1.1-1.2l-0.4-2
        c-0.1-0.5-0.4-0.7-0.9-0.7L500.8,939.2L500.8,939.2z"/>
      <polygon fill="none" points="498.9,939.4 500.1,939.3 498.2,938.5 497.3,938.4 497.8,939.6 498.9,939.4 		"/>
      <path fill="none" d="M498.9,939.4l-1.1,0.1l1.4,3.2c-2.1-0.8-4.3-1.2-6.4-1.1l0.1,11.6c0,5.8-0.1,11.4-0.2,17l-0.1,1.8l-0.1,2.7
        c-0.3,6.6-0.7,13.1-1.2,19.4c0,1.9,0.8,3.1,2.4,3.7c1.8-10.6,3.1-22.1,3.9-34.5l0.1-0.9c0.7-5.8,1.3-8.7,2-8.7l2.8,0.8l-1-8.5
        l-0.4-3.1l-1.1,0.2l0.2,4.3l-0.7-4.3L498.9,939.4L498.9,939.4z"/>
      <path fill="none" d="M497.8,939.6l-0.5-1.2l-4.8-0.8c0.1,1.4,0.2,2.8,0.3,4.2c2.1-0.1,4.3,0.3,6.4,1.1L497.8,939.6L497.8,939.6z"
        />
      <path fill="none" d="M501.2,943l0.4,3.1l2.9-0.3c0.5-0.1,0.7-0.4,0.6-0.9l-0.5-2.6c-0.1-0.5-0.4-0.7-0.9-0.7l-0.4,0
        c0.3,0.8-0.1,1.1-1.1,1.2L501.2,943L501.2,943z"/>
      <polygon fill="none" points="500.1,943.1 499.6,943.2 500.3,947.5 500.1,943.1 		"/>
      <path fill="none" d="M471,955.2v-1.9l-73.5,0.8c-6,0.3-9.9-0.7-11.7-2.8c-3.1-3.7-6.2-8.3-9.3-13.6h-5.6H326h-4.1l0,2.8h50.8
        c1.6-0.2,3,0.9,4.3,3.4l5.8,8.8c1.7,2,4.9,3.2,9.5,3.5L471,955.2L471,955.2z"/>
      <path fill="none" d="M493,953.3l-0.8,0.2c0,5.8-0.1,11.5-0.2,17l0.8-0.2C492.9,964.7,493,959.1,493,953.3L493,953.3z"/>
      <path fill="none" d="M492.1,953.5l-19-0.2v16.7c0.1,0.6,0.6,0.9,1.8,0.8h16l1.1-0.3C492.1,964.9,492.1,959.2,492.1,953.5
        L492.1,953.5z M474,955.9c0-1.2,0.6-1.9,1.9-1.9h11.7c1.2,0,1.9,0.6,1.9,1.9v12.6c0,1.2-0.6,1.9-1.9,1.9h-11.7
        c-1.2,0-1.9-0.6-1.9-1.9V955.9L474,955.9z"/>
      <path fill="none" d="M475.9,954c-1.2,0-1.9,0.6-1.9,1.9v12.6c0,1.2,0.6,1.9,1.9,1.9h11.7c1.2,0,1.9-0.6,1.9-1.9v-12.6
        c0-1.2-0.6-1.9-1.9-1.9H475.9L475.9,954z M479.8,957.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
        s-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2S479.4,957.5,479.8,957.2
        L479.8,957.2z M479.2,967c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
        s-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5C479.4,967.8,479.2,967.4,479.2,967L479.2,967z"/>
      <path fill="none" d="M481,956.7c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
        c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2C481.8,956.8,481.4,956.7,481,956.7L481,956.7z M480.3,957.8
        c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
        c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.2-0.4-0.2-0.6S480.2,957.9,480.3,957.8L480.3,957.8z"/>
      <path fill="none" d="M481,957.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6s0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.3
        c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.4-0.3-0.6C481.4,957.6,481.2,957.5,481,957.5L481,957.5z"/>
      <path fill="none" d="M479.8,965.8c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5
        c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5C480.5,965.3,480.1,965.4,479.8,965.8L479.8,965.8z
         M480.1,967c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6
        c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3C480.2,967.4,480.1,967.2,480.1,967L480.1,967z"/>
      <path fill="none" d="M480.3,966.4c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3
        c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.4-0.2-0.6-0.2C480.7,966.1,480.5,966.2,480.3,966.4L480.3,966.4z"/>
      <polygon fill="none" points="492.6,974.8 492.7,972.1 489.7,972.6 491.4,974.8 492.6,974.8 		"/>
      <path fill="none" d="M492.6,974.8h-1.2l-1.5,15.5c-0.4,2.5-2.2,3.9-5.6,4.1H308.6c-3.4-0.2-5.2-1.6-5.6-4.1l-1.5-15.5h-1.2
        c0.3,6.6,0.7,13.1,1.2,19.4c0,1.9-0.8,3.1-2.4,3.7c0.4,2.7,0.7,5.2,1.2,7.5c2.1-4.6,8.4-6.9,18.8-6.9l57.4,0.7
        c-0.4-1.9,0.1-2.9,1.3-2.8h37.4c1.2-0.1,1.7,0.9,1.3,2.8l57.4-0.7c10.4,0.1,16.7,2.4,18.8,6.9c0.4-2.3,0.8-4.8,1.2-7.5
        c-1.6-0.6-2.4-1.9-2.4-3.7C491.9,987.9,492.3,981.4,492.6,974.8L492.6,974.8z"/>
      <path fill="none" d="M476.3,974.8l-5.2,0.7v14.9c0,0.4,0,0.7-0.1,0.9h0.1c8,0,14.2-0.3,18.8-1.1l1.5-15.5H476.3L476.3,974.8z"/>
      <path fill="none" d="M470.9,991.4c0.1-0.3,0.2-0.6,0.1-0.9v-14.9H321.8v14.9c0,0.4,0,0.7,0.1,0.9H470.9L470.9,991.4z"/>
      <path fill="none" d="M471,991.4h-0.1c-0.5,1.1-1.9,1.5-4.3,1.5H326.3c-2.4,0.1-3.9-0.4-4.3-1.5h-0.1c-8,0-14.2-0.3-18.8-1.1
        c0.4,2.5,2.2,3.9,5.6,4.1h175.7c3.4-0.2,5.2-1.6,5.6-4.1C485.3,991,479,991.4,471,991.4L471,991.4z"/>
      <path fill="none" d="M466.6,992.9c2.4,0.1,3.9-0.4,4.3-1.5H322c0.5,1.1,1.9,1.5,4.3,1.5H466.6L466.6,992.9z"/>
      <path fill="none" d="M505.8,986.1c0.9-6.4,1.4-11.8,1.4-16.2c-0.6,8.4-2.5,19.2-5.7,32.4v7C503.3,1003,504.7,995.3,505.8,986.1
        L505.8,986.1z"/>
      <path fill="none" d="M491.6,1010c0.4-1.5,0.7-3,1-4.6c-2.1-4.6-8.4-6.9-18.8-6.9l-57.4,0.7v9.5h53.1c10.6,0.6,17.5,2.5,20.7,5.8
        C490.7,1013.1,491.2,1011.6,491.6,1010L491.6,1010z M467.7,1001.1v2.4c0,0.5-0.2,0.7-0.7,0.7h-22.7c-0.5,0-0.7-0.2-0.7-0.7v-2.4
        c0-0.5,0.2-0.7,0.7-0.7h22.7C467.4,1000.3,467.7,1000.6,467.7,1001.1L467.7,1001.1z"/>
      <path fill="none" d="M467.7,1003.5v-2.4c0-0.5-0.2-0.7-0.7-0.7h-22.7c-0.5,0-0.7,0.2-0.7,0.7v2.4c0,0.5,0.2,0.7,0.7,0.7h22.7
        C467.4,1004.2,467.7,1004,467.7,1003.5L467.7,1003.5z"/>
      <polygon fill="none" points="472.7,1028.1 472.7,1021.2 466.6,1021.4 466.6,1028.1 472.7,1028.1 		"/>
      <path fill="none" d="M466.6,1021.4l6.2-0.2l9.9-0.4c-0.8-2.6-4.8-4.4-11.9-5.4h-54.2h-13.6v1c10.1,0.2,18.9,0.9,26.4,2.1
        c9.1,1.5,13.6,3.2,13.7,5.3v1.3h6.5l-1.7,1.1h4.3v-4.3L466.6,1021.4L466.6,1021.4z"/>
      <polygon fill="none" points="466.6,1028.1 466.6,1021.4 452,1022 452,1026.2 447.7,1026.2 443,1034.5 466.6,1034.5 466.6,1030.2 
        466.6,1028.1 		"/>
      <polygon fill="none" points="472.7,1028.1 466.6,1028.1 466.6,1030.2 472.7,1030.2 472.7,1028.1 		"/>
      <polygon fill="none" points="472.7,1030.2 466.6,1030.2 466.6,1034.5 443,1034.5 441.9,1036.3 472.7,1036.3 472.7,1030.2 		"/>
      <path fill="none" d="M485,1022l5.2-7.5c-3.2-3.3-10-5.2-20.7-5.8h-53.1v6.7h54.2c7.1,1,11.1,2.8,11.9,5.4c0.1,0.4,0.2,0.7,0.2,1.1
        H485L485,1022z"/>
      <polygon fill="none" points="472.7,1039.5 472.7,1036.3 441.9,1036.3 440.1,1039.5 472.7,1039.5 		"/>
      <path fill="none" d="M376.6,937.7c-1.1-1.8-2.1-3.8-3.2-5.8c-1.4-2.6-3.4-3.9-6-3.9h-45.1c-0.2,3.3-0.3,6.5-0.4,9.7h4.1l0.3-6.2
        c0.1-1.3,0.9-1.9,2.3-1.9h34.1c2.8,0.1,4.8,1.3,5.8,3.6l2.4,4.5H376.6L376.6,937.7z"/>
      <path fill="none" d="M368.5,933.2c-1-2.3-2.9-3.5-5.8-3.6h-34.1c-1.4,0-2.2,0.6-2.3,1.9l-0.3,6.2h44.9L368.5,933.2L368.5,933.2z
         M332.4,931.1l1.8-0.2l1.9,0.1c0.6,0.1,1.1,0.2,1.6,0.4c0.5,0.2,1,0.4,1.3,0.7c0.5,0.4,0.8,0.8,0.9,1.2c0.1,0.5-0.1,1-0.5,1.5
        c-0.3,0.3-0.7,0.6-1.2,0.8c-1,0.4-2.1,0.7-3.4,0.7c-1.3,0-2.5-0.2-3.5-0.6c-0.6-0.2-1-0.4-1.4-0.7c-0.5-0.4-0.7-0.8-0.8-1.2
        c-0.1-0.3-0.1-0.6,0.1-0.9c0.1-0.2,0.3-0.4,0.4-0.6C330.3,931.8,331.2,931.3,332.4,931.1L332.4,931.1z"/>
      <path fill="none" d="M334.2,930.9l-1.8,0.2c-1.2,0.2-2.1,0.7-2.8,1.3c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.3-0.1,0.6-0.1,0.9
        c0.1,0.4,0.4,0.8,0.8,1.2c0.4,0.3,0.8,0.5,1.4,0.7c1,0.4,2.2,0.6,3.5,0.6c1.3-0.1,2.4-0.3,3.4-0.7c0.5-0.2,1-0.5,1.2-0.8
        c0.5-0.5,0.6-1,0.5-1.5c-0.1-0.4-0.4-0.8-0.9-1.2c-0.4-0.3-0.8-0.5-1.3-0.7c-0.5-0.2-1.1-0.3-1.6-0.4L334.2,930.9L334.2,930.9z
         M333.5,931.2l0.9-0.1l1.7,0.1c0.6,0.1,1.1,0.2,1.5,0.4c0.5,0.2,0.9,0.4,1.2,0.6c0.5,0.3,0.7,0.7,0.8,1.1c0.1,0.5-0.1,0.9-0.6,1.3
        c-0.3,0.3-0.7,0.5-1.2,0.7l-1.4,0.4c-1.1,0.2-2.3,0.2-3.4,0.1c-1.1-0.2-2.1-0.5-2.7-1c-0.2-0.1-0.4-0.3-0.5-0.5
        c-0.1-0.2-0.2-0.4-0.3-0.7c0-0.3,0-0.5,0.1-0.8c0.3-0.5,0.8-0.9,1.6-1.3C331.9,931.5,332.6,931.3,333.5,931.2L333.5,931.2z"/>
      <path fill="none" d="M334.4,931.1l-0.9,0.1c-0.9,0.1-1.6,0.3-2.2,0.5c-0.8,0.3-1.3,0.8-1.6,1.3c-0.1,0.2-0.2,0.5-0.1,0.8
        c0,0.2,0.1,0.4,0.3,0.7c0.2,0.2,0.3,0.4,0.5,0.5c0.7,0.5,1.6,0.8,2.7,1c1.1,0.2,2.3,0.1,3.4-0.1l1.4-0.4c0.5-0.2,0.9-0.4,1.2-0.7
        c0.4-0.4,0.6-0.9,0.6-1.3c0-0.4-0.3-0.8-0.8-1.1c-0.3-0.2-0.7-0.4-1.2-0.6c-0.4-0.2-0.9-0.3-1.5-0.4L334.4,931.1L334.4,931.1z
         M334.7,931.9L334.7,931.9l0.1,0.7l0.2-0.5l0.1-0.2v0h0.4l0,0l-0.5,1.4l-0.4,0l0,0l-0.1-0.7l-0.2,0.5l0,0.2h-0.4l0.5-1.5
        L334.7,931.9L334.7,931.9z M333.6,931.9L333.6,931.9l0,1.3l0,0.2h-0.4c0,0,0,0,0,0l0-0.1v-0.1l-0.5,0c-0.1,0.1-0.1,0.2-0.1,0.2
        h-0.5l0.9-1.4v-0.1H333.6L333.6,931.9z M331.7,932.1l0-0.3h0.5v0h0l0,0l0,0.1l-0.3,1l0.4,0v0.1l0,0l-0.1,0.3l-0.9,0l0,0h-0.1
        L331.7,932.1L331.7,932.1z M331.7,934.2L331.7,934.2l-0.1,0.4h-0.9l0,0l0.1-0.3l1-0.3v0.1h0l-0.1,0.2l-0.5,0.2H331.7L331.7,934.2z
         M332.2,933.9l0-0.3h0.6c0.3,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.4,0.2l0.3,0.5h-0.4l-0.3-0.5
        c-0.1,0.2-0.1,0.4-0.1,0.5h-0.4l0,0L332.2,933.9L332.2,933.9z M334.5,933.7c0.1,0.1,0.2,0.2,0.2,0.4c0,0.3-0.1,0.5-0.3,0.8
        c-0.1,0.1-0.2,0.2-0.4,0.2l-0.4,0l-0.2-0.2c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.4c0.2-0.2,0.4-0.4,0.6-0.4
        C334.2,933.6,334.4,933.6,334.5,933.7L334.5,933.7z M334.7,935.1L334.7,935.1v-1.4h0.5l0,0.1v0.7l0.5-0.7l0-0.2h0.5
        c-0.1,0.2-0.3,0.4-0.5,0.7l-0.5,0.7v0.1L334.7,935.1L334.7,935.1L334.7,935.1z M337.4,932.8h-0.4l0,0l0.1-0.3h0.9l0,0l-0.1,0.4
        l-1,0.3v0l0,0l0-0.1L337.4,932.8L337.4,932.8z M336.3,931.9l0.3,0.2l0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c-0.2,0.3-0.4,0.4-0.7,0.5
        l-0.7,0l0,0l0-0.1l0-0.1l0.4-1.3L336.3,931.9L336.3,931.9z M336.9,934.2L336.9,934.2l-0.1,0.3h-0.4l-0.1,0.2l0.4,0v0.1l-0.1,0.2
        l0,0.1h-0.9l0-0.1l0.4-1.1l0-0.2h0.9l0,0L337,934l-0.1,0h-0.4l-0.1,0.3H336.9L336.9,934.2z M336.9,935.1L336.9,935.1l0.4-1.2
        l0-0.3h0.5l0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2l0.3,0.5h-0.4l-0.2-0.2l-0.1-0.2
        l-0.1,0.5H336.9L336.9,935.1z"/>
      <polygon fill="none" points="334.7,931.9 334.7,931.9 334.2,931.9 333.7,933.4 334.2,933.4 334.2,933.1 334.4,932.6 334.4,933.4 
        334.5,933.4 334.8,933.4 335.4,931.9 335.3,931.9 335,931.9 335,931.9 334.9,932.1 334.7,932.6 334.7,931.9 		"/>
      <path fill="none" d="M333.6,931.9L333.6,931.9l-0.4,0v0.1l-0.9,1.4h0.5c0,0,0-0.1,0.1-0.2l0.5,0v0.1l0,0.1c0,0,0,0,0,0h0.4l0-0.2
        V931.9L333.6,931.9z M333.2,932.4v0.4H333L333.2,932.4L333.2,932.4z"/>
      <polygon fill="none" points="333.2,932.8 333.2,932.4 333,932.8 333.2,932.8 		"/>
      <polygon fill="none" points="331.7,931.9 331.7,932.1 331.2,933.4 331.3,933.4 331.3,933.4 332.2,933.4 332.3,933 332.2,933.1 
        332.2,933 331.8,933 332.2,932 332.2,931.9 332.2,931.9 332.2,931.9 332.2,931.9 331.7,931.9 		"/>
      <polygon fill="none" points="331.7,934.2 331.7,934.2 331.2,934.2 331.7,934 331.8,933.9 331.8,933.9 331.8,933.8 330.8,934.1 
        330.6,934.5 330.7,934.5 331.6,934.5 331.7,934.2 		"/>
      <path fill="none" d="M332.2,933.7l0,0.3l-0.4,1.1l0,0h0.4c0-0.1,0.1-0.3,0.1-0.5l0.3,0.5h0.4l-0.3-0.5c0.2,0,0.3-0.1,0.4-0.2
        c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2-0.1-0.3-0.4-0.4H332.2L332.2,933.7z M332.6,934h0.1l0.1,0c0,0,0,0.1,0,0.2l-0.3,0.1L332.6,934
        L332.6,934z"/>
      <path fill="none" d="M332.7,934h-0.1l-0.1,0.3l0.3-0.1C332.8,934.1,332.8,934,332.7,934L332.7,934L332.7,934z"/>
      <path fill="none" d="M334.7,934.1c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.3,0-0.5,0.1-0.6,0.4l-0.1,0.4
        c0,0.2,0,0.3,0.1,0.4l0.2,0.2l0.4,0c0.1,0,0.3-0.1,0.4-0.2C334.6,934.7,334.7,934.4,334.7,934.1L334.7,934.1z M334.2,934l0.1,0.3
        l-0.1,0.4l-0.2,0.2c-0.1,0-0.2,0-0.2-0.2c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.1-0.1,0.2-0.1L334.2,934L334.2,934z"/>
      <path fill="none" d="M334.2,934.3l-0.1-0.3l-0.1,0c-0.1,0-0.2,0-0.2,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.1,0.1,0.2,0.2,0.2l0.2-0.2
        L334.2,934.3L334.2,934.3z"/>
      <path fill="none" d="M334.7,935L334.7,935L334.7,935l0.4,0.1v-0.1l0.5-0.7c0.2-0.3,0.4-0.6,0.5-0.7h-0.5l0,0.2l-0.5,0.7v-0.7
        l0-0.1h-0.5L334.7,935L334.7,935z"/>
      <polygon fill="none" points="336.9,932.8 337.4,932.8 336.8,933 336.8,933.1 336.8,933.1 336.8,933.1 337.8,932.8 338,932.4 
        337.9,932.4 337,932.4 336.9,932.7 336.9,932.8 		"/>
      <path fill="none" d="M336.5,932.1l-0.3-0.2l-0.6,0l-0.4,1.3l0,0.1l0,0.1l0,0l0.7,0c0.3,0,0.5-0.2,0.7-0.5c0.1-0.1,0.1-0.3,0.1-0.4
        L336.5,932.1L336.5,932.1z M336,932.9c-0.1,0.1-0.2,0.1-0.3,0.1l0.3-0.9l0.2,0l0,0.2C336.2,932.6,336.1,932.8,336,932.9L336,932.9
        z"/>
      <path fill="none" d="M335.7,933.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.3,0.2-0.5l0-0.2l-0.2,0L335.7,933.1L335.7,933.1z"/>
      <polygon fill="none" points="336.9,934.3 336.9,934.2 336.5,934.2 336.6,934 337,934 337,934 337.2,933.7 337.1,933.7 
        336.2,933.7 336.2,933.9 335.8,935 335.7,935.1 336.7,935.1 336.7,935 336.8,934.8 336.8,934.7 336.3,934.8 336.4,934.5 
        336.8,934.5 336.9,934.3 		"/>
      <path fill="none" d="M336.9,935.1L336.9,935.1l0.4,0l0.1-0.5l0.1,0.2l0.2,0.2h0.4l-0.3-0.5c0.2,0,0.3-0.1,0.4-0.2
        c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1-0.1-0.2-0.1-0.3l-0.4-0.1h-0.5l0,0.3L336.9,935.1L336.9,935.1z M337.8,934.2l-0.3,0.1l0.1-0.3
        l0.2,0L337.8,934.2L337.8,934.2z"/>
      <polygon fill="none" points="337.5,934.3 337.8,934.2 337.9,934 337.7,934 337.5,934.3 		"/>
      <path fill="none" d="M390.5,1012.8c-0.2,0-0.4,0.1-0.4,0.4v2.2v1v0.7c0,0.2,0.1,0.4,0.4,0.4h12c0.2,0,0.4-0.1,0.4-0.4v-0.7v-1
        v-2.2c0-0.2-0.1-0.4-0.4-0.4H390.5L390.5,1012.8z"/>
      <path fill="none" d="M390.1,1013.2c0-0.2,0.1-0.4,0.4-0.4h12c0.2,0,0.4,0.1,0.4,0.4v2.2h13.6v-6.7h-40v6.7h13.6V1013.2
        L390.1,1013.2z"/>
      <path fill="none" d="M377.8,996.4c-1.2-0.1-1.7,0.9-1.3,2.8v9.5h40v-9.5c0.4-1.9-0.1-2.9-1.3-2.8H377.8L377.8,996.4z"/>
      <path fill="none" d="M376.5,1008.7v-9.5l-57.4-0.7c-10.4,0.1-16.7,2.4-18.8,6.9c0.3,1.6,0.7,3.2,1,4.6c0.4,1.6,0.9,3.1,1.3,4.5
        c3.1-3.3,10-5.2,20.7-5.8H376.5L376.5,1008.7z M349.4,1001.1v2.4c0,0.5-0.2,0.7-0.7,0.7h-22.7c-0.5,0-0.7-0.2-0.7-0.7v-2.4
        c0-0.5,0.2-0.7,0.7-0.7h22.7C349.2,1000.3,349.4,1000.6,349.4,1001.1L349.4,1001.1z"/>
      <path fill="none" d="M349.4,1003.5v-2.4c0-0.5-0.2-0.7-0.7-0.7h-22.7c-0.5,0-0.7,0.2-0.7,0.7v2.4c0,0.5,0.2,0.7,0.7,0.7h22.7
        C349.2,1004.2,349.4,1004,349.4,1003.5L349.4,1003.5z"/>
      <path fill="none" d="M390.1,1016.4v-1h-13.6h-54.2c-7.1,1-11.1,2.8-11.9,5.4l9.9,0.4l6.2,0.2l14.5,0.5v4.3h4.3l-1.7-1.1h6.4
        l-0.2-1.3c0-2.1,4.5-3.8,13.6-5.3C370.9,1017.3,379.9,1016.6,390.1,1016.4L390.1,1016.4z"/>
      <path fill="none" d="M390.1,1017.1v-0.7c-10.2,0.2-19.1,0.9-26.8,2.1c-9.1,1.5-13.6,3.2-13.6,5.3l0.2,1.3c0.3,2.1,1.3,4.6,2.9,7.6
        c-0.2-0.3-0.2-0.7-0.2-1c0-1.2,1-2.3,3-3.3c2.1-1.1,5.5-2.1,10-3c8.6-1.7,19-2.6,31.2-2.6c12.2,0,22.6,0.9,31.2,2.6
        c4.5,0.9,7.8,1.9,10,3c2,1,3,2.1,3,3.3l-0.1,0.7c1.4-2.8,2.2-5.2,2.4-7.4v-1.3c0-2.1-4.6-3.8-13.7-5.3c-7.5-1.2-16.3-1.9-26.4-2.1
        v0.7c0,0.2-0.1,0.4-0.4,0.4h-12C390.2,1017.5,390.1,1017.4,390.1,1017.1L390.1,1017.1z"/>
      <path fill="none" d="M376.5,1015.4v-6.7h-53.1c-10.6,0.6-17.5,2.5-20.7,5.8l5.2,7.5h2.2c0-0.4,0.1-0.8,0.2-1.1
        c0.8-2.6,4.8-4.4,11.9-5.4H376.5L376.5,1015.4z"/>
      <polygon fill="none" points="351,1036.3 349.9,1034.5 326.3,1034.5 326.3,1030.2 320.2,1030.2 320.2,1036.3 351,1036.3 		"/>
      <polygon fill="none" points="352.8,1039.5 351,1036.3 320.2,1036.3 320.2,1039.5 352.8,1039.5 		"/>
      <polygon fill="none" points="349.9,1034.5 345.1,1026.2 340.9,1026.2 340.9,1022 326.3,1021.4 326.3,1028.1 326.3,1030.2 
        326.3,1034.5 349.9,1034.5 		"/>
      <path fill="none" d="M296.2,926.8c-0.5,1.3-1,2.2-1.5,2.7l-0.5,0.4l0.2,0l0,0c-0.5,0.3-0.8,0.8-1.2,1.5l-0.2,0.5
        c-0.3,0.9-0.5,2.1-0.6,3.6l-0.4,3.7l0.8,0.1l1.9-0.8l2.6-15.8l-0.7,2.9L296.2,926.8L296.2,926.8z"/>
      <path fill="none" d="M292.1,939.2l-1.4-0.2c-0.5-0.1-0.8,0.2-0.9,0.7l-0.4,2c-0.3,0.8,0.1,1.1,1.1,1.2l1.2,0.2l1.1,0.2l0.5,0.1
        l0.7-3.8l-1.1-0.1L292.1,939.2L292.1,939.2z"/>
      <polygon fill="none" points="295.6,938.4 294.7,938.5 292.8,939.3 294,939.4 295.1,939.6 295.6,938.4 		"/>
      <path fill="none" d="M295.1,939.6l-1.1-0.1l-0.7,3.8l-0.7,4.3l0.2-4.3l-1.1-0.2l-0.4,3.1l-1,8.5l2.8-0.8c0.7,0,1.3,2.9,2,8.7
        l0.1,0.9c0.8,12.4,2.1,23.9,3.9,34.5c1.6-0.6,2.4-1.9,2.4-3.7c-0.5-6.3-0.9-12.8-1.2-19.4l-0.1-2.7l-0.1-1.8
        c-0.2-5.5-0.2-11.2-0.2-17l0.1-11.6c-2.1-0.1-4.2,0.3-6.4,1.1L295.1,939.6L295.1,939.6z"/>
      <path fill="none" d="M295.6,938.4l-0.5,1.2l-1.4,3.2c2.1-0.8,4.2-1.2,6.4-1.1c0.1-1.3,0.2-2.7,0.3-4.2L295.6,938.4L295.6,938.4z"
        />
      <polygon fill="none" points="292.8,943.1 292.6,947.5 293.3,943.2 292.8,943.1 		"/>
      <path fill="none" d="M291.7,943l-1.2-0.2c-1,0-1.3-0.4-1.1-1.2l-0.4,0c-0.5-0.1-0.8,0.2-0.9,0.7l-0.5,2.6
        c-0.1,0.5,0.1,0.8,0.6,0.9l2.9,0.3L291.7,943L291.7,943z"/>
      <path fill="none" d="M300.8,953.5l-0.8-0.2c0,5.8,0.1,11.4,0.2,17l0.8,0.2C300.8,964.9,300.7,959.2,300.8,953.5L300.8,953.5z"/>
      <polygon fill="none" points="301.5,974.8 303.2,972.6 300.2,972.1 300.3,974.8 301.5,974.8 		"/>
      <polygon fill="none" points="303.2,972.6 301.5,974.8 316.6,974.8 321.8,975.5 321.8,972.6 303.2,972.6 		"/>
      <path fill="none" d="M301,970.5l1.1,0.3h16c1.1,0,1.7-0.2,1.8-0.8v-16.7l-19,0.2C300.7,959.2,300.8,964.9,301,970.5L301,970.5z
         M317,954c1.2,0,1.9,0.6,1.9,1.9v12.6c0,1.2-0.6,1.9-1.9,1.9h-11.7c-1.2,0-1.9-0.6-1.9-1.9v-12.6c0-1.2,0.6-1.9,1.9-1.9H317
        L317,954z"/>
      <path fill="none" d="M318.9,955.9c0-1.2-0.6-1.9-1.9-1.9h-11.7c-1.2,0-1.9,0.6-1.9,1.9v12.6c0,1.2,0.6,1.9,1.9,1.9H317
        c1.2,0,1.9-0.6,1.9-1.9V955.9L318.9,955.9z M313.1,957.2c0.3,0.3,0.5,0.7,0.5,1.2s-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5
        c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5C312.4,956.7,312.8,956.8,313.1,957.2
        L313.1,957.2z M310.2,967c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
        s-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5C310.4,967.8,310.2,967.4,310.2,967L310.2,967z"/>
      <path fill="none" d="M313.6,958.4c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5
        c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5C313.5,959.3,313.6,958.9,313.6,958.4
        L313.6,958.4z M312.5,957.8c0.2,0.2,0.2,0.4,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
        c-0.2-0.2-0.3-0.4-0.3-0.6s0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.2,0.6-0.2C312.2,957.5,312.4,957.6,312.5,957.8L312.5,957.8z"/>
      <path fill="none" d="M312.8,958.4c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
        c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3C312.7,958.8,312.8,958.6,312.8,958.4
        L312.8,958.4z"/>
      <path fill="none" d="M310.7,965.8c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5
        c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5C311.5,965.3,311.1,965.4,310.7,965.8L310.7,965.8z
         M311.1,967c0-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6
        c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3C311.2,967.4,311.1,967.2,311.1,967L311.1,967z"/>
      <path fill="none" d="M311.3,966.4c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3
        c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.4-0.2-0.6-0.2C311.7,966.1,311.5,966.2,311.3,966.4L311.3,966.4z"/>
      <path fill="none" d="M285.6,969.9c0.1,4.4,0.5,9.8,1.4,16.2c1.1,9.1,2.6,16.8,4.3,23.1v-7C288.1,989.1,286.2,978.3,285.6,969.9
        L285.6,969.9z"/>
      <path fill="none" d="M301.5,974.8l1.5,15.5c4.6,0.8,10.9,1.2,18.8,1.1h0.1c-0.1-0.3-0.2-0.6-0.1-0.9v-14.9l-5.2-0.7H301.5
        L301.5,974.8z"/>
      <polygon fill="none" points="326.3,1021.4 320.2,1021.2 320.2,1028.1 326.3,1028.1 326.3,1021.4 		"/>
      <polygon fill="none" points="326.3,1030.2 326.3,1028.1 320.2,1028.1 320.2,1030.2 326.3,1030.2 		"/>
      <path fill="none" d="M502.8,956.7l-0.2-2c-1.4,1.9-2.6,8.6-3.5,20.1l-0.1,0.9c-0.9,10.9-2.6,20.8-5.1,29.7l4.8,0.2
        c1.1-3.3,2-6.9,2.9-10.8c0.8-3.7,1.6-7.8,2.3-12.2c1.4-11.7,2.4-18.2,3.1-19.5L502.8,956.7L502.8,956.7z"/>
      <path fill="none" d="M502.6,954.7L502.6,954.7l-2.8-0.9c-0.7,0-1.3,2.9-2,8.7l-0.1,0.9c-0.8,12.4-2.1,23.9-3.9,34.5
        c-0.4,2.7-0.7,5.2-1.2,7.5l1.3,0.1c2.5-9,4.2-18.9,5.1-29.7l0.1-0.9C500,963.3,501.2,956.6,502.6,954.7L502.6,954.7z"/>
      <path fill="none" d="M507.3,969.9c0-2.6-0.1-4.9-0.3-6.8c-0.7,1.3-1.8,7.8-3.1,19.5c-0.7,4.4-1.4,8.5-2.3,12.2
        c-0.9,4-1.8,7.6-2.9,10.8l2,0.1l0.9-3.4C504.8,989.1,506.7,978.3,507.3,969.9L507.3,969.9z"/>
      <path fill="none" d="M500.7,1005.7l-2-0.1l-4.8-0.2l-1.3-0.1c-0.3,1.6-0.7,3.2-1,4.6h6.1C499.3,1010.2,500.3,1008.8,500.7,1005.7
        L500.7,1005.7z"/>
      <path fill="none" d="M501.5,1002.3l-0.9,3.4c-0.4,3.1-1.4,4.5-3,4.3h-6.1c-0.4,1.6-0.9,3.1-1.3,4.5l-5.2,7.5h-2.2
        c0-0.4-0.1-0.8-0.2-1.1l-9.9,0.4v6.9v2.1v6.2v3.1v25.8c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5v-56V1002.3L501.5,1002.3
        z"/>
      <path fill="none" d="M355.4,1028.5c-2,1-3,2.1-3,3.3c0,0.3,0.1,0.7,0.2,1c1,2,5.2,3.8,12.7,5.3c3.3,0.7,6.9,1.2,10.7,1.6
        c0,0,0,0,0,0c6.1,0.7,13,1,20.4,1c7.4,0,14.1-0.3,20.2-1c0,0,0,0,0,0c3.9-0.4,7.6-0.9,10.9-1.6c7.7-1.5,11.9-3.4,12.8-5.5l0,0
        l0.1-0.7c0-1.2-1-2.3-3-3.3c-2.1-1.1-5.5-2.1-10-3c-8.6-1.7-19-2.6-31.2-2.6c-12.2,0-22.6,0.9-31.2,2.6
        C360.9,1026.4,357.6,1027.4,355.4,1028.5L355.4,1028.5z M388.3,1028L388.3,1028c1.3-0.1,2.6-0.1,3.9-0.1l4.8,0l3.9,0.1
        c5.5,0.1,10.4,0.5,14.8,1.2c1,0.1,2,0.3,2.9,0.5h0c1.2,0.2,2.2,0.4,3.2,0.7c0.8,0.2,1.5,0.4,2.2,0.6c2.7,0.8,4,1.8,4.1,2.8v0.1
        c0,0.2-0.1,0.5-0.2,0.7c-0.2,0.3-0.4,0.6-0.8,0.9c-0.3,0.2-0.7,0.4-1.2,0.7c-1.6,0.7-4,1.4-7.3,2l-2.5,0.4c-1,0.2-2,0.3-3.1,0.4
        c-1.4,0.2-2.8,0.3-4.2,0.4c-1.2,0.1-2.5,0.2-3.9,0.2c-2.9,0.1-5.9,0.2-9.1,0.2c-2.4,0-4.7,0-7-0.1h0c-1.3-0.1-2.6-0.1-3.8-0.2
        c-1.5-0.1-3-0.2-4.4-0.4c-1.2-0.1-2.3-0.2-3.4-0.4c-1.5-0.2-2.9-0.4-4.3-0.7c-2.6-0.5-4.7-1-6.3-1.5c-0.6-0.2-1.1-0.4-1.5-0.7
        c-0.5-0.3-0.9-0.6-1.2-0.8c-0.2-0.2-0.4-0.5-0.4-0.7l-0.1-0.4c0-0.9,1-1.7,3-2.5c0.6-0.2,1.2-0.4,1.9-0.6c0.9-0.2,1.8-0.5,2.9-0.7
        l2.8-0.5C378.3,1028.7,383.1,1028.2,388.3,1028L388.3,1028z"/>
      <path fill="none" d="M373.6,1031.2l2.6,0.3c0.6-0.2,1.3-0.3,2-0.5l-2.3-0.4C375.1,1030.8,374.3,1031,373.6,1031.2L373.6,1031.2z"
        />
      <path fill="none" d="M376.2,1031.5l-2.6-0.3l-3.3-0.4l-1.9-0.2c-0.7,0.2-1.4,0.4-1.9,0.6l2,0.2l3.4,0.4l2.7,0.3l7.6,0.8l4,0.2
        l1.4-0.4l-3.4-0.5l-0.6,0.1L376.2,1031.5L376.2,1031.5z"/>
      <path fill="none" d="M373.6,1031.2c0.7-0.2,1.5-0.4,2.3-0.6l-2.9-0.4c-1,0.2-1.9,0.4-2.7,0.7L373.6,1031.2L373.6,1031.2z"/>
      <polygon fill="none" points="373.1,1030.2 375.9,1030.6 378.3,1031 384.7,1032 384.2,1032.2 387.5,1032.6 389,1032.3 
        387.7,1031.8 387,1031.6 380.8,1030.5 378.5,1030.2 375.7,1029.7 374.1,1029.4 371.4,1029.9 373.1,1030.2 		"/>
      <path fill="none" d="M378.3,1031c-0.7,0.2-1.4,0.3-2,0.5l7.3,0.8l0.6-0.1l0.5-0.1L378.3,1031L378.3,1031z"/>
      <path fill="none" d="M375.7,1029.7l2.8,0.5c3.2-0.5,6.8-0.9,10.8-1l-0.6-0.7C383.9,1028.6,379.6,1029,375.7,1029.7L375.7,1029.7z"
        />
      <path fill="none" d="M374.1,1029.4l1.6,0.3c3.8-0.6,8.1-1,13-1.2l-0.4-0.4C383.1,1028.2,378.3,1028.7,374.1,1029.4L374.1,1029.4z"
        />
      <path fill="none" d="M380.8,1030.5l6.2,1.1c1.3-0.2,2.8-0.3,4.3-0.4l-1.5-1.5C386.5,1029.8,383.5,1030.1,380.8,1030.5
        L380.8,1030.5z"/>
      <path fill="none" d="M387,1031.6l0.7,0.1l1.3,0.5l-1.4,0.4l-1.4,0.4l-4-0.2c-0.6,0.3-0.9,0.6-0.9,0.9l4,0.1l0.6,0.4l0.6,0.4
        l-2.2,0.4l-1,0.1c0.6,0.2,1.4,0.4,2.2,0.5l1,0.1l0.5-0.1l2.4-0.3l2.2,0.2l2.2,0.2l0.3,0.5l-0.3,0.2l4.9,0l-0.4-0.2l0-0.6l2.2-0.2
        l2.1-0.2l2.6,0.3l0.6,0.2l0.3,0c1.1-0.2,2-0.4,2.7-0.7l-3.4-0.4l0.5-0.4l0.4-0.4l4-0.2c-0.1-0.3-0.5-0.6-1.2-0.9l-0.9,0.1l-3,0.1
        l-1.6-0.3l-1.6-0.3l1-0.5l0.7-0.1c-1.4-0.2-2.9-0.3-4.5-0.4l-0.1,0.2l-1.4,0.5h-2.4l-2.4,0l-1.6-0.5l-0.2-0.2
        C389.8,1031.3,388.3,1031.4,387,1031.6L387,1031.6z"/>
      <path fill="none" d="M378.5,1030.2l2.2,0.4c2.7-0.4,5.8-0.7,9.1-0.9l-0.5-0.6C385.3,1029.3,381.7,1029.6,378.5,1030.2
        L378.5,1030.2z"/>
      <path fill="none" d="M388.3,1028l0.4,0.4l0.6,0.7l0.5,0.6l1.5,1.5l0.2,0.2l1.6,0.5l2.4,0l-0.1-0.8h-1l-1-1.5l-0.4-0.6l-0.5-0.7
        l-0.3-0.4C390.9,1027.9,389.6,1028,388.3,1028L388.3,1028L388.3,1028z"/>
      <polygon fill="none" points="393,1029 393.4,1029.6 396.7,1029.5 396.8,1029 393,1029 		"/>
      <polygon fill="none" points="392.5,1028.3 393,1029 396.8,1029 397,1028.3 392.5,1028.3 		"/>
      <polygon fill="none" points="393.4,1029.6 394.4,1031.1 395.4,1031.1 396.3,1031.1 396.7,1029.5 393.4,1029.6 		"/>
      <polygon fill="none" points="392.3,1027.9 392.5,1028.3 397,1028.3 397.1,1027.9 392.3,1027.9 		"/>
      <polygon fill="none" points="395.5,1031.9 397.9,1031.9 399.3,1031.4 399.4,1031.2 400.2,1029.6 400.5,1029 400.8,1028.3 
        401,1027.9 397.1,1027.9 397,1028.3 396.8,1029 396.7,1029.5 396.3,1031.1 395.4,1031.1 395.5,1031.9 		"/>
      <path fill="none" d="M400.8,1028.3l-0.3,0.7c4.2,0.1,7.9,0.4,11.3,0.9l2.6-0.5C410.3,1028.8,405.8,1028.5,400.8,1028.3
        L400.8,1028.3z"/>
      <path fill="none" d="M400.2,1029.6l-0.8,1.6c1.6,0.1,3.1,0.2,4.5,0.4l0.8-0.1c-0.7-0.3-0.9-0.6-0.7-0.7l5.7-0.3
        C406.8,1030,403.7,1029.7,400.2,1029.6L400.2,1029.6z"/>
      <path fill="none" d="M400.5,1029l-0.3,0.6c3.5,0.1,6.6,0.4,9.5,0.8l2.1-0.4C408.4,1029.5,404.6,1029.2,400.5,1029L400.5,1029z"/>
      <path fill="none" d="M401,1027.9l-0.2,0.4c5,0.1,9.5,0.5,13.5,1.1l1.5-0.3C411.4,1028.5,406.5,1028.1,401,1027.9L401,1027.9z"/>
      <path fill="none" d="M371.4,1029.9c-1.1,0.2-2,0.5-2.9,0.7l1.9,0.2c0.8-0.2,1.7-0.4,2.7-0.7L371.4,1029.9L371.4,1029.9z"/>
      <path fill="none" d="M368.5,1031.4l-2-0.2c-2,0.8-3,1.6-3,2.5l0.1,0.4l2.2-0.1l-0.1-0.3C365.7,1032.9,366.7,1032.1,368.5,1031.4
        L368.5,1031.4z"/>
      <path fill="none" d="M371.8,1031.8l-3.4-0.4c-1.8,0.7-2.7,1.5-2.7,2.3l0.1,0.3l3.8-0.1l0-0.2
        C369.6,1033,370.3,1032.4,371.8,1031.8L371.8,1031.8z"/>
      <path fill="none" d="M372.7,1033.9l0-0.2c0-0.6,0.6-1.2,1.9-1.7l-2.7-0.3c-1.5,0.6-2.3,1.2-2.3,2l0,0.2L372.7,1033.9L372.7,1033.9
        z"/>
      <path fill="none" d="M372.7,1033.7l0,0.2l8.6-0.2c0-0.3,0.3-0.6,0.9-0.9l-7.6-0.8C373.3,1032.6,372.7,1033.1,372.7,1033.7
        L372.7,1033.7z"/>
      <path fill="none" d="M370.9,1035.3c-0.4-0.2-0.7-0.4-1-0.7l-3.8,0.1c0.3,0.3,0.6,0.5,1.1,0.8L370.9,1035.3L370.9,1035.3z"/>
      <path fill="none" d="M370,1034.6c0.2,0.2,0.6,0.5,1,0.7l3-0.2c-0.4-0.2-0.6-0.4-0.8-0.6L370,1034.6L370,1034.6z"/>
      <path fill="none" d="M372.7,1033.9l-3.1,0.1l-3.8,0.1l-2.2,0.1c0.1,0.2,0.2,0.5,0.4,0.7l2.2-0.1l3.8-0.1l3.1-0.1l8.4-0.3l0.2,0.2
        l4.1-0.2l-0.6-0.4l-4-0.1L372.7,1033.9L372.7,1033.9z"/>
      <path fill="none" d="M367.3,1035.5c-0.5-0.3-0.9-0.5-1.1-0.8l-2.2,0.1c0.3,0.3,0.7,0.6,1.2,0.8L367.3,1035.5L367.3,1035.5z"/>
      <path fill="none" d="M368.8,1036.2l-2.1,0.2c1.5,0.6,3.6,1.1,6.3,1.5c1.4,0.2,2.8,0.5,4.3,0.7l1.1-0.4c-1.3-0.2-2.6-0.4-3.9-0.6
        C372.2,1037.2,370.2,1036.7,368.8,1036.2L368.8,1036.2z"/>
      <path fill="none" d="M366.8,1036.3l2.1-0.2l3.6-0.3l2.9-0.2l8-0.6l1-0.1l2.2-0.4l-0.6-0.4l-4.1,0.2l0.2,0.2l-8.1,0.5l-3,0.2
        l-3.6,0.2l-2.1,0.1C365.7,1035.9,366.2,1036.1,366.8,1036.3L366.8,1036.3z"/>
      <path fill="none" d="M372.4,1035.9l-3.6,0.3c1.4,0.5,3.3,1,5.7,1.4c1.2,0.2,2.5,0.4,3.9,0.6l2-0.6c-1.1-0.1-2.2-0.3-3.1-0.5
        C375.3,1036.8,373.6,1036.3,372.4,1035.9L372.4,1035.9z"/>
      <path fill="none" d="M401.7,1036.2l0.8-0.1l-1.9-0.7l-2.2,0.2l0,0.6l0.4,0.2l3,1.5l1.1,0.5l1.3,0.7l0.8,0.4
        c1.3-0.1,2.6-0.1,3.9-0.2l-0.9-0.4l-1.5-0.7l-1.2-0.5L401.7,1036.2L401.7,1036.2L401.7,1036.2z"/>
      <polygon fill="none" points="402.5,1036.1 401.7,1036.2 401.8,1036.2 403.5,1036 403.5,1036 402.5,1036.1 		"/>
      <path fill="none" d="M402.7,1035.2l-2.1,0.2l1.9,0.7l0.9-0.1l0,0l4.7,1.3l1.7,0.5l2.1,0.6l1.2,0.4c1.1-0.1,2.1-0.3,3.1-0.4
        l-1.3-0.3l-2.2-0.6l-1.8-0.5l-5-1.3l-0.6-0.2L402.7,1035.2L402.7,1035.2z"/>
      <path fill="none" d="M402.9,1038.4l-1.1-0.5c-1.9,0.1-3.9,0.1-5.9,0.1l-4.4-0.1l-0.9,0.5c1.7,0.1,3.5,0.1,5.3,0.1
        C398.3,1038.5,400.7,1038.5,402.9,1038.4L402.9,1038.4z"/>
      <path fill="none" d="M401.8,1037.8l-3-1.5l-4.9,0l-2.4,1.5l4.4,0.1C397.9,1037.9,399.9,1037.9,401.8,1037.8L401.8,1037.8z"/>
      <path fill="none" d="M402.9,1038.4c-2.2,0.1-4.5,0.1-7,0.1c-1.8,0-3.6,0-5.3-0.1l-1.1,0.7c2,0.1,4.1,0.1,6.3,0.1
        c2.9,0,5.7-0.1,8.4-0.2L402.9,1038.4L402.9,1038.4z"/>
      <path fill="none" d="M393.9,1036.4l0.3-0.2l-0.3-0.5l-2.2-0.2l-1.7,0.7l0.9,0.1l-2.8,1.5l-1,0.5l-1.2,0.7l-0.7,0.4
        c1.2,0.1,2.5,0.1,3.8,0.2l0.1-0.1l0.5-0.3l1.1-0.7l0.9-0.5L393.9,1036.4L393.9,1036.4z"/>
      <path fill="none" d="M389.9,1036.2l-0.8-0.1h0l-4.1,1.4c1,0.1,2,0.2,3,0.2l2.8-1.5L389.9,1036.2L389.9,1036.2L389.9,1036.2z"/>
      <path fill="none" d="M389.1,1036.1l0.8,0.1h0l1.7-0.7l-2.2-0.2l-2.4,0.3l-0.5,0.1l-4.5,1.4l-1.6,0.5l-2,0.6l-1.1,0.4
        c1.1,0.1,2.2,0.3,3.4,0.4l1-0.4l1.8-0.6l1.5-0.5L389.1,1036.1L389.1,1036.1L389.1,1036.1z"/>
      <polygon fill="none" points="389,1039.4 388.9,1039.5 388.9,1039.5 389,1039.4 		"/>
      <path fill="none" d="M389.5,1039.1l-0.5,0.3l-0.1,0.1c2.2,0.1,4.6,0.1,7,0.1c3.2,0,6.3-0.1,9.1-0.2l-0.8-0.4
        c-2.6,0.1-5.4,0.2-8.4,0.2C393.7,1039.2,391.6,1039.2,389.5,1039.1L389.5,1039.1z"/>
      <path fill="none" d="M386.5,1035.8l-1-0.1c-0.9-0.2-1.6-0.3-2.2-0.5l-8,0.6c1.1,0.4,2.5,0.7,4.2,1c0.8,0.1,1.7,0.3,2.6,0.4
        L386.5,1035.8L386.5,1035.8z"/>
      <path fill="none" d="M382,1034.6l-0.2-0.2l-0.2-0.2C381.6,1034.4,381.8,1034.5,382,1034.6L382,1034.6z"/>
      <path fill="none" d="M382,1034.6c-0.2-0.1-0.4-0.2-0.5-0.3l-8.4,0.3c0.2,0.2,0.5,0.4,0.8,0.6L382,1034.6L382,1034.6z"/>
      <path fill="none" d="M383.5,1038l-1.8,0.6c1.3,0.1,2.7,0.2,4.1,0.3l1.2-0.7C385.8,1038.2,384.7,1038.1,383.5,1038L383.5,1038z"/>
      <path fill="none" d="M381.7,1038.6l-1,0.4c1.4,0.2,2.9,0.3,4.4,0.4l0.7-0.4C384.4,1038.8,383,1038.7,381.7,1038.6L381.7,1038.6z"
        />
      <path fill="none" d="M380.4,1037.6l1.6-0.5c-0.9-0.1-1.8-0.3-2.6-0.4c-1.7-0.3-3.1-0.7-4.2-1l-2.9,0.2c1.2,0.4,2.9,0.8,4.9,1.2
        C378.3,1037.3,379.3,1037.5,380.4,1037.6L380.4,1037.6z"/>
      <path fill="none" d="M385,1037.5l-1.5,0.5c1.1,0.1,2.3,0.2,3.5,0.3l1-0.5C387,1037.7,386,1037.6,385,1037.5L385,1037.5z"/>
      <polygon fill="none" points="406.3,1031.9 406.6,1032 407.6,1032.2 408.5,1032 407.2,1031.7 406.3,1031.9 		"/>
      <path fill="none" d="M406.6,1032l-0.3-0.1l0.9-0.2l7.3-1.3l2.7-0.5l1.6-0.3c-1-0.2-1.9-0.3-2.9-0.5l-1.5,0.3l-2.6,0.5l-2.1,0.4
        l-5,1l-0.8,0.1l-0.7,0.1l-1,0.5l1.6,0.3l3.3-0.5L406.6,1032L406.6,1032z"/>
      <path fill="none" d="M403.9,1030.6c-0.2,0.2,0.1,0.4,0.7,0.7l5-1L403.9,1030.6L403.9,1030.6z"/>
      <polygon fill="none" points="407.5,1032.2 407.6,1032.2 406.6,1032 407,1032 407.5,1032.2 		"/>
      <path fill="none" d="M407.6,1032.2L407.6,1032.2l-0.6-0.1l-3.3,0.5l1.6,0.3l3-0.1l0.9-0.1l1-0.1l8.9-1.1l3.2-0.4l1.9-0.2
        c-0.7-0.2-1.4-0.4-2.2-0.6l-1.8,0.2l-3.1,0.4l-8.5,1.1L407.6,1032.2L407.6,1032.2z"/>
      <path fill="none" d="M407.2,1031.7l1.3,0.3l8.5-1.1c-0.8-0.2-1.6-0.4-2.5-0.5L407.2,1031.7L407.2,1031.7z"/>
      <path fill="none" d="M417.2,1029.9l-2.7,0.5c0.9,0.2,1.8,0.3,2.5,0.5l3.1-0.4C419.2,1030.3,418.2,1030.1,417.2,1029.9
        L417.2,1029.9z"/>
      <path fill="none" d="M421.9,1030.3c-1-0.2-2-0.4-3.2-0.7h0l-1.6,0.3c1.1,0.2,2.1,0.4,2.9,0.6L421.9,1030.3L421.9,1030.3z"/>
      <path fill="none" d="M422.2,1031.1l-3.2,0.4c2,0.7,3,1.4,3.1,2.2l3.8,0C425.9,1032.7,424.7,1031.8,422.2,1031.1L422.2,1031.1z"/>
      <path fill="none" d="M424.1,1030.8l-1.9,0.2c2.4,0.8,3.7,1.6,3.8,2.6l2.2,0C428.2,1032.6,426.8,1031.7,424.1,1030.8L424.1,1030.8z
        "/>
      <path fill="none" d="M419,1031.5l-8.9,1.1c0.8,0.3,1.2,0.6,1.4,1l10.6,0.1C422.1,1032.8,421,1032.1,419,1031.5L419,1031.5z"/>
      <path fill="none" d="M428.2,1033.7l-2.2,0l-3.8,0l-10.6-0.1l-1.1,0l-4,0.2l-0.4,0.4l4.2,0.2l0.2-0.2h0l1.2,0l2.4,0l4.4,0.1h0.5
        l3.1,0.1l3.8,0.1l2.2,0C428.2,1034.2,428.2,1034,428.2,1033.7L428.2,1033.7L428.2,1033.7z"/>
      <path fill="none" d="M428,1034.4l-2.2,0c-0.2,0.3-0.4,0.5-0.8,0.8l2.2,0.1C427.6,1035,427.9,1034.7,428,1034.4L428,1034.4z"/>
      <path fill="none" d="M426,1035.9c0.5-0.2,0.9-0.4,1.2-0.7l-2.2-0.1l-3.7-0.2l-1.2-0.1l-8.3-0.4l-0.6,0l-1.3-0.1H410l0.2-0.1
        l-4.2-0.2l-0.5,0.4l3.4,0.4l0.5,0l2.3,0.1l6.4,0.4l2,0.1h0l3.7,0.2L426,1035.9L426,1035.9z"/>
      <polygon fill="none" points="418.9,1034.2 418.4,1034.2 418.9,1034.3 418.9,1034.2 		"/>
      <path fill="none" d="M418.9,1034.3l-0.4-0.1l-4.4-0.1c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.7,0.1-0.9,0.2l8.3,0.4l0-0.1
        C420.2,1034.6,419.8,1034.4,418.9,1034.3L418.9,1034.3L418.9,1034.3z"/>
      <path fill="none" d="M418.9,1034.2L418.9,1034.2L418.9,1034.2c0.9,0.2,1.3,0.4,1.3,0.6l0,0.1l1.2,0.1c0.3-0.2,0.5-0.4,0.7-0.7
        L418.9,1034.2L418.9,1034.2z"/>
      <path fill="none" d="M420.2,1035.6L420.2,1035.6c-0.9,0.4-2.2,0.8-3.7,1.1l-3.7,0.7l2.2,0.6l2.2-0.4c3-0.5,5.2-1.2,6.7-1.8
        L420.2,1035.6L420.2,1035.6z"/>
      <path fill="none" d="M420.2,1035.6l-2-0.1c-0.4,0.1-0.9,0.1-1.4,0.1l-1,0c-1.2,0-2.2-0.1-3.1-0.2c-0.5-0.1-0.8-0.2-1-0.3l-2.3-0.1
        l1.5,1l5.4-0.2L420.2,1035.6L420.2,1035.6z"/>
      <path fill="none" d="M422,1034.3c-0.1,0.2-0.4,0.4-0.7,0.7l3.7,0.2c0.4-0.3,0.6-0.5,0.8-0.8L422,1034.3L422,1034.3z"/>
      <path fill="none" d="M426,1035.9l-2.2-0.1c-1.5,0.7-3.7,1.3-6.7,1.8l-2.2,0.4l1.3,0.3l2.5-0.4C422,1037.3,424.4,1036.7,426,1035.9
        L426,1035.9z"/>
      <path fill="none" d="M415.8,1035.6l1,0c0.5,0,0.9,0,1.4-0.1l-6.4-0.4c0.2,0.1,0.5,0.2,1,0.3
        C413.6,1035.5,414.6,1035.6,415.8,1035.6L415.8,1035.6z"/>
      <path fill="none" d="M413.8,1036.4l-2.9,0.5l1.8,0.5l3.7-0.7c1.6-0.4,2.8-0.7,3.7-1.1l-3.9,0.1
        C415.6,1036,414.8,1036.2,413.8,1036.4L413.8,1036.4z"/>
      <path fill="none" d="M410.9,1036.9l2.9-0.5c1-0.2,1.8-0.4,2.5-0.7l-5.4,0.2l-1.5-1l-0.5,0c-0.7,0.2-1.6,0.5-2.7,0.7l-0.3,0
        L410.9,1036.9L410.9,1036.9z"/>
      <path fill="none" d="M414,1034.1l-2.4,0l-0.3,0.4l0.6,0c0.2-0.1,0.5-0.2,0.9-0.2C413.1,1034.2,413.5,1034.2,414,1034.1L414,1034.1
        z"/>
      <polygon fill="none" points="411.3,1034.5 411.6,1034.1 410.4,1034.1 410,1034.4 411.3,1034.5 		"/>
      <path fill="none" d="M413.1,1038.7l-1.2-0.4c-1.3,0.2-2.6,0.3-3.9,0.4l0.9,0.4C410.4,1039,411.8,1038.9,413.1,1038.7L413.1,1038.7
        z"/>
      <polygon fill="none" points="410,1034.4 410,1034.4 410.4,1034.1 410.3,1034.1 410.1,1034.3 410,1034.4 		"/>
      <path fill="none" d="M409.2,1032.6c0.7,0.3,1.1,0.5,1.2,0.9l1.1,0c-0.2-0.4-0.7-0.7-1.4-1L409.2,1032.6L409.2,1032.6z"/>
      <path fill="none" d="M405.2,1037.6l1.2,0.5c1.2-0.1,2.3-0.2,3.4-0.3l-1.7-0.5L405.2,1037.6L405.2,1037.6z"/>
      <path fill="none" d="M409.8,1037.8c-1.1,0.1-2.2,0.2-3.4,0.3l1.5,0.7c1.3-0.1,2.6-0.2,3.9-0.4L409.8,1037.8L409.8,1037.8z"/>
      <polygon fill="none" points="408.1,1037.3 403.5,1036 401.8,1036.2 405.2,1037.6 408.1,1037.3 		"/>
      <path fill="none" d="M343.5,1025.1l1.7,1.1l4.8,8.2l1.1,1.9l1.8,3.1l4.8,8.2h18.5v-8.1c0,0,0,0,0,0c-3.8-0.4-7.4-1-10.7-1.6
        c-7.4-1.5-11.7-3.2-12.7-5.3c-1.6-3-2.5-5.6-2.9-7.6H343.5L343.5,1025.1z"/>
      <path fill="none" d="M442.9,1025.1c-0.1,2.1-0.9,4.6-2.4,7.4l0,0c-0.8,2.1-5.1,3.9-12.8,5.5c-3.4,0.7-7,1.2-10.9,1.6c0,0,0,0,0,0
        v8h18.5l4.8-8.2l1.8-3.1l1.1-1.9l4.8-8.2l1.7-1.1H442.9L442.9,1025.1z"/>
      <path fill="none" d="M290.3,954.6L290.3,954.6c1.4,2,2.6,8.7,3.5,20.2l0.1,0.9c0.9,10.9,2.6,20.8,5.1,29.7l1.3-0.1
        c-0.4-2.3-0.8-4.8-1.2-7.5c-1.8-10.6-3.1-22.1-3.9-34.5l-0.1-0.9c-0.7-5.8-1.3-8.7-2-8.7L290.3,954.6L290.3,954.6z"/>
      <path fill="none" d="M290.3,954.7l-0.2,2l-4.1,6.4c0.7,1.3,1.8,7.8,3.1,19.5c0.7,4.4,1.4,8.5,2.3,12.2c0.9,4,1.8,7.6,2.9,10.8
        l4.8-0.2c-2.5-9-4.2-18.9-5.1-29.7l-0.1-0.9C292.9,963.3,291.7,956.6,290.3,954.7L290.3,954.7z"/>
      <path fill="none" d="M285.9,963.1c-0.2,1.9-0.4,4.2-0.3,6.8c0.6,8.4,2.5,19.2,5.7,32.4l0.9,3.4l2.1-0.1c-1.1-3.3-2-6.9-2.9-10.8
        c-0.8-3.7-1.6-7.8-2.3-12.2C287.7,970.9,286.7,964.4,285.9,963.1L285.9,963.1z"/>
      <path fill="none" d="M320.2,1030.2v-2.1v-6.9l-9.9-0.4c-0.1,0.4-0.2,0.7-0.2,1.1h-2.2l-5.2-7.5c-0.5-1.4-0.9-2.9-1.3-4.5h-6.1
        c-1.6,0.2-2.6-1.2-3-4.3l-0.9-3.4v7v56c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5v-25.8v-3.1V1030.2L320.2,1030.2z"/>
      <path fill="none" d="M301.3,1010c-0.4-1.5-0.7-3-1-4.6l-1.3,0.1l-4.8,0.2l-2.1,0.1c0.4,3.1,1.4,4.5,3,4.3H301.3L301.3,1010z"/>
      <path fill="none" d="M469.9,917.7l0.1,1.1c0.5,6.7,0.8,13.3,1,19.6v14.9v1.9v16v1.3h18.6l3-0.5l0.1-1.8l-0.8,0.2l-1.1,0.3h-16
        c-1.1,0-1.7-0.2-1.8-0.8v-16.7v-14.8c-0.2-6-0.5-12.2-1-18.5c0-0.8,0.4-1.3,1-1.4l0.4,0c4.5-0.4,8.9-0.3,13.4,0.2l0.1,0
        c0.6,0.1,1,0.6,1.2,1.5l0,0l0.3,1.1c2.3,8.9,3.5,16.5,3.4,22.8l0.1,9.4l0.8-0.2l-0.1-11.6c-0.1-1.3-0.1-2.7-0.3-4.2
        c-0.5-4.9-1.6-10.5-3.2-16.6l-0.8-3.2C482.4,916.8,476.1,916.8,469.9,917.7L469.9,917.7z"/>
      <polygon fill="none" points="491.4,974.8 489.7,972.6 471,972.6 471,975.5 476.3,974.8 491.4,974.8 		"/>
      <polygon fill="none" points="471,972.6 471,971.3 396.3,972.1 321.8,971.3 321.8,972.6 396.3,973.4 471,972.6 		"/>
      <polygon fill="none" points="471,975.5 471,972.6 396.3,973.4 321.8,972.6 321.8,975.5 471,975.5 		"/>
      <path fill="none" d="M471,971.3v-16l-78.9,0.9c-4.6-0.4-7.8-1.6-9.5-3.5l-5.8-8.8c-1.2-2.4-2.7-3.6-4.3-3.4h-50.8v30.8l74.4,0.9
        L471,971.3L471,971.3z M366.2,965.3h1.5l-1.8,2.3v1.6h-1.4v-1.6l-1.8-2.3h1.5l0.9,1.3L366.2,965.3L366.2,965.3z M369.9,967.6
        l2.3-2.4h1.1v2.4h0.6v0.8h-0.6v0.7h-1.1v-0.7h-2.3V967.6L369.9,967.6z M360.5,967.7c-0.1-0.1-0.2-0.1-0.3-0.1H360v1.6h-1.4v-3.9
        h2.3l1,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.2,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.3-0.4,0.4l-0.5,0.1l0.4,0.2
        l0.4,0.4l0.7,1.1h-1.6l-0.7-1.2C360.7,967.8,360.6,967.7,360.5,967.7L360.5,967.7z M357.8,965.3v0.8h-2.3v0.6h2.1v0.8h-2.1v0.8
        h2.4v0.9h-3.8v-3.9H357.8L357.8,965.3z M352.2,965.3h1.4l-1.7,3.9h-1.5l-1.7-3.9h1.4l1,2.8L352.2,965.3L352.2,965.3z M344.1,967.2
        c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,1-0.5,1.7-0.5c0.8,0,1.3,0.2,1.7,0.5c0.4,0.4,0.6,0.8,0.6,1.5c0,0.5-0.1,0.8-0.3,1.1
        c-0.2,0.3-0.4,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.2,0.2l-1.2-0.2c-0.3-0.2-0.6-0.4-0.8-0.7C344.2,968.1,344.1,967.7,344.1,967.2
        L344.1,967.2z M342.8,965.5c0.3,0.2,0.6,0.5,0.7,0.9l-1.2,0.2l-0.1-0.2l-0.3-0.2l-0.4-0.1c-0.3,0-0.5,0.1-0.7,0.3
        c-0.1,0.2-0.2,0.4-0.2,0.8c0,0.4,0.1,0.7,0.2,0.9c0.2,0.1,0.4,0.2,0.7,0.2c0.3,0,0.5-0.1,0.6-0.2l0.3-0.6l1.2,0.3
        c-0.1,0.3-0.2,0.5-0.4,0.7l-0.7,0.4l-1,0.2c-0.5,0-0.9-0.1-1.2-0.2c-0.3-0.1-0.6-0.3-0.8-0.7c-0.2-0.3-0.3-0.7-0.3-1.2
        c0-0.7,0.2-1.2,0.6-1.5c0.4-0.4,1-0.5,1.7-0.5C342.1,965.2,342.5,965.3,342.8,965.5L342.8,965.5z M335.6,965.4l1.1-0.2
        c0.6,0,1,0.1,1.3,0.3c0.3,0.2,0.5,0.5,0.6,0.9l-1.3,0.1c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.4-0.1l-0.4,0.1
        c-0.1,0.1-0.1,0.1-0.1,0.2l0.1,0.2l0.5,0.1c0.6,0.1,1,0.2,1.3,0.4c0.3,0.1,0.4,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.2,0.6
        c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.4-0.7,0.5l-1.1,0.2c-0.8,0-1.3-0.1-1.6-0.4c-0.3-0.3-0.4-0.6-0.5-1l1.3-0.1
        c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.6,0.2l0.5-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.2l-0.7-0.2
        c-0.6-0.1-1-0.3-1.3-0.5c-0.3-0.2-0.4-0.4-0.4-0.8c0-0.2,0.1-0.4,0.2-0.6C335.1,965.6,335.3,965.5,335.6,965.4L335.6,965.4z
         M329.6,965.3l1,0.2c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.6l0.1,0.8c0,0.4-0.1,0.7-0.1,1l-0.4,0.6
        c-0.2,0.2-0.4,0.3-0.6,0.3l-0.8,0.1h-2v-3.9H329.6L329.6,965.3z M332.5,965.3h1.4v3.9h-1.4V965.3L332.5,965.3z"/>
      <polygon fill="none" points="367.7,965.3 366.2,965.3 365.2,966.6 364.3,965.3 362.8,965.3 364.5,967.5 364.5,969.2 365.9,969.2 
        365.9,967.5 367.7,965.3 		"/>
      <path fill="none" d="M372.2,965.2l-2.3,2.4v0.9h2.3v0.7h1.1v-0.7h0.6v-0.8h-0.6v-2.4H372.2L372.2,965.2z M371,967.6l1.2-1.2v1.2
        H371L371,967.6z"/>
      <polygon fill="none" points="372.2,966.4 371,967.6 372.2,967.6 372.2,966.4 		"/>
      <path fill="none" d="M360.2,967.6c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.2,0.3,0.3l0.7,1.2h1.6l-0.7-1.1l-0.4-0.4l-0.4-0.2l0.5-0.1
        c0.2-0.1,0.4-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4l-1-0.1h-2.3v3.9h1.4v-1.6
        H360.2L360.2,967.6z M361.2,966.7l-0.2,0.1l-0.4,0.1H360v-0.8h0.6l0.5,0.1c0.1,0.1,0.1,0.2,0.1,0.3L361.2,966.7L361.2,966.7z"/>
      <path fill="none" d="M361,966.8l0.2-0.1l0.1-0.2c0-0.1,0-0.2-0.1-0.3l-0.5-0.1H360v0.8h0.6L361,966.8L361,966.8z"/>
      <polygon fill="none" points="357.8,966.1 357.8,965.3 354.1,965.3 354.1,969.2 357.9,969.2 357.9,968.3 355.5,968.3 355.5,967.5 
        357.6,967.5 357.6,966.7 355.5,966.7 355.5,966.1 357.8,966.1 		"/>
      <polygon fill="none" points="353.6,965.3 352.2,965.3 351.2,968.1 350.2,965.3 348.8,965.3 350.5,969.2 352,969.2 353.6,965.3 		
        "/>
      <path fill="none" d="M344.7,965.7c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.5,0.1,0.8,0.3,1.1c0.2,0.3,0.5,0.5,0.8,0.7l1.2,0.2
        c0.5,0,0.9-0.1,1.2-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.7,0.3-1.1c0-0.7-0.2-1.1-0.6-1.5c-0.4-0.4-1-0.5-1.7-0.5
        C345.7,965.2,345.1,965.4,344.7,965.7L344.7,965.7z M345.5,967.2c0-0.4,0.1-0.7,0.3-0.8c0.2-0.2,0.4-0.3,0.7-0.3
        c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.8c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.4,0.2-0.7,0.2c-0.3,0-0.5-0.1-0.7-0.2
        C345.6,967.9,345.5,967.6,345.5,967.2L345.5,967.2z"/>
      <path fill="none" d="M345.7,966.4c-0.2,0.2-0.3,0.4-0.3,0.8c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.2,0.7,0.2c0.3,0,0.5-0.1,0.7-0.2
        c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.7-0.3C346.1,966.1,345.9,966.2,345.7,966.4L345.7,966.4z"/>
      <path fill="none" d="M343.5,966.4c-0.1-0.4-0.4-0.7-0.7-0.9c-0.3-0.2-0.7-0.3-1.3-0.3c-0.7,0-1.3,0.2-1.7,0.5
        c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.5,0.1,0.9,0.3,1.2c0.2,0.3,0.5,0.5,0.8,0.7c0.3,0.1,0.7,0.2,1.2,0.2l1-0.2l0.7-0.4
        c0.2-0.2,0.3-0.4,0.4-0.7l-1.2-0.3l-0.3,0.6c-0.1,0.1-0.3,0.2-0.6,0.2c-0.3,0-0.5-0.1-0.7-0.2c-0.1-0.2-0.2-0.5-0.2-0.9
        c0-0.4,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.7-0.3l0.4,0.1l0.3,0.2l0.1,0.2L343.5,966.4L343.5,966.4z"/>
      <path fill="none" d="M336.7,965.2l-1.1,0.2c-0.3,0.1-0.5,0.2-0.6,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.3,0.1,0.6,0.4,0.8
        c0.3,0.2,0.7,0.4,1.3,0.5l0.7,0.2c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3l-0.5,0.1c-0.3,0-0.5-0.1-0.6-0.2
        c-0.1-0.1-0.2-0.2-0.2-0.4l-1.3,0.1c0.1,0.4,0.2,0.7,0.5,1c0.3,0.3,0.8,0.4,1.6,0.4l1.1-0.2c0.3-0.1,0.5-0.3,0.7-0.5
        c0.2-0.2,0.2-0.4,0.2-0.7c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.6-0.4c-0.3-0.1-0.7-0.2-1.3-0.4l-0.5-0.1l-0.1-0.2
        c0-0.1,0-0.2,0.1-0.2l0.4-0.1c0.2,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4l1.3-0.1c-0.1-0.4-0.3-0.7-0.6-0.9
        C337.7,965.3,337.3,965.2,336.7,965.2L336.7,965.2z"/>
      <path fill="none" d="M330.6,965.4l-1-0.2h-2v3.9h2l0.8-0.1c0.2,0,0.4-0.1,0.6-0.3l0.4-0.6c0.1-0.2,0.1-0.5,0.1-1l-0.1-0.8
        c-0.1-0.2-0.2-0.4-0.3-0.6C331.1,965.7,330.8,965.5,330.6,965.4L330.6,965.4z M330.1,966.4c0.2,0.1,0.2,0.4,0.2,0.9l-0.1,0.7
        l-0.3,0.3l-0.6,0.1h-0.3v-2.2h0.4C329.6,966.1,329.9,966.2,330.1,966.4L330.1,966.4z"/>
      <path fill="none" d="M330.3,967.2c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.2-0.4-0.2-0.8-0.2h-0.4v2.2h0.3l0.6-0.1l0.3-0.3L330.3,967.2
        L330.3,967.2z"/>
      <polygon fill="none" points="333.9,965.3 332.5,965.3 332.5,969.2 333.9,969.2 333.9,965.3 		"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="337.5,934.3 337.7,934 337.9,934 337.8,934.2 337.5,934.3 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M336.9,935.1l0.4-1.1l0-0.3h0.5l0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.3
        c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2l0.3,0.5h-0.4l-0.2-0.2l-0.1-0.2l-0.1,0.5L336.9,935.1L336.9,935.1L336.9,935.1z"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="336.9,934.3 336.8,934.5 336.4,934.5 336.3,934.8 336.8,934.7 
        336.8,934.8 336.7,935 336.7,935.1 335.7,935.1 335.8,935 336.2,933.9 336.2,933.7 337.1,933.7 337.2,933.7 337,934 337,934 
        336.6,934 336.5,934.2 336.9,934.2 336.9,934.3 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M335.7,933.1l0.3-0.9l0.2,0l0,0.2c0,0.2-0.1,0.4-0.2,0.5
        C335.9,933,335.8,933.1,335.7,933.1L335.7,933.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M336.5,932.1l0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c-0.2,0.3-0.4,0.4-0.7,0.5
        l-0.7,0l0,0l0-0.1l0-0.1l0.4-1.3l0.6,0L336.5,932.1L336.5,932.1z"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="336.9,932.8 336.9,932.7 337,932.4 337.9,932.4 338,932.4 
        337.8,932.8 336.8,933.1 336.8,933.1 336.8,933.1 336.8,933 337.4,932.8 336.9,932.8 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M334.7,935l0-1.4h0.5l0,0.1v0.7l0.5-0.7l0-0.2h0.5
        c-0.1,0.2-0.3,0.4-0.5,0.7l-0.5,0.7v0.1L334.7,935L334.7,935L334.7,935L334.7,935z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M334.7,934.1c0,0.3-0.1,0.5-0.3,0.8c-0.1,0.1-0.2,0.2-0.4,0.2l-0.4,0
        l-0.2-0.2c-0.1-0.1-0.1-0.3-0.1-0.4l0.1-0.4c0.2-0.2,0.4-0.4,0.6-0.4c0.2,0,0.3,0,0.4,0.1C334.6,933.8,334.7,934,334.7,934.1
        L334.7,934.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M334.2,934.3l-0.1,0.4l-0.2,0.2c-0.1,0-0.2,0-0.2-0.2
        c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.1-0.1,0.2-0.1l0.1,0L334.2,934.3L334.2,934.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M332.2,933.7h0.6c0.3,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.1,0.4
        c-0.1,0.1-0.2,0.1-0.4,0.2l0.3,0.5h-0.4l-0.3-0.5c-0.1,0.2-0.1,0.4-0.1,0.5h-0.4l0,0l0.4-1.1L332.2,933.7L332.2,933.7z"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="331.7,934.2 331.6,934.5 330.7,934.5 330.6,934.5 330.8,934.1 
        331.8,933.8 331.8,933.9 331.8,933.9 331.7,934 331.2,934.2 331.7,934.2 331.7,934.2 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M332.7,934L332.7,934c0.1,0.1,0.1,0.1,0.1,0.3l-0.3,0.1l0.1-0.3H332.7
        L332.7,934z"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="331.7,931.9 332.2,931.9 332.2,931.9 332.2,931.9 332.2,931.9 
        332.2,932 331.8,933 332.2,933 332.2,933.1 332.3,933 332.2,933.4 331.3,933.4 331.3,933.4 331.2,933.4 331.7,932.1 331.7,931.9 
            "/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M334.4,931.1l1.7,0.1c0.6,0.1,1.1,0.2,1.5,0.4c0.5,0.2,0.9,0.4,1.2,0.6
        c0.5,0.3,0.7,0.7,0.8,1.1c0.1,0.5-0.1,0.9-0.6,1.3c-0.3,0.3-0.7,0.5-1.2,0.7l-1.4,0.4c-1.1,0.2-2.3,0.2-3.4,0.1
        c-1.1-0.2-2.1-0.5-2.7-1c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.3-0.7c0-0.3,0-0.5,0.1-0.8c0.3-0.5,0.8-0.9,1.6-1.3
        c0.6-0.2,1.4-0.4,2.2-0.5L334.4,931.1L334.4,931.1z"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="333.2,932.8 333,932.8 333.2,932.4 333.2,932.8 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M333.6,931.9v1.2l0,0.2h-0.4c0,0,0,0,0,0l0-0.1v-0.1l-0.5,0
        c-0.1,0.1-0.1,0.2-0.1,0.2h-0.5l0.9-1.4v-0.1L333.6,931.9L333.6,931.9L333.6,931.9z"/>
      <polygon fill="none" stroke="#000000" stroke-width="0.5" points="334.7,931.9 334.7,932.6 334.9,932.1 335,931.9 335,931.9 
        335.3,931.9 335.4,931.9 334.8,933.4 334.5,933.4 334.4,933.4 334.4,932.6 334.2,933.1 334.2,933.4 333.7,933.4 334.2,931.9 
        334.7,931.9 334.7,931.9 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489" y1="912.9" x2="492.2" y2="914"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M473.1,953.2v-14.8c-0.2-6-0.5-12.2-1-18.5c0-0.8,0.4-1.3,1-1.4l0.4,0
        c4.5-0.4,8.9-0.3,13.4,0.2l0.1,0c0.6,0.1,1,0.6,1.2,1.5l0,0l0.3,1.1c2.3,8.9,3.5,16.5,3.4,22.8l0.1,9.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M406.6,855.8c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
        c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
        C406.2,855.7,406.4,855.8,406.6,855.8L406.6,855.8z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M387.2,854.9c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3
        c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
        C387.1,854.4,387.2,854.6,387.2,854.9L387.2,854.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M393.2,854c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7
        c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
        C392.7,854.1,392.9,854,393.2,854L393.2,854z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M389.1,855.6c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
        c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3
        C389.5,855.8,389.3,855.7,389.1,855.6L389.1,855.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M402.2,854.9c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
        c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3
        C402.3,854.4,402.2,854.6,402.2,854.9L402.2,854.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M395.5,854.9c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
        c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3
        C395.6,854.4,395.5,854.6,395.5,854.9L395.5,854.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M398.8,854.9c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
        c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3
        C398.9,854.4,398.8,854.6,398.8,854.9L398.8,854.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M480.9,930c0.5,0,0.9-0.2,1.3-0.5c0.4-0.4,0.5-0.8,0.5-1.3
        c0-0.5-0.2-0.9-0.5-1.3c-0.4-0.4-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3
        C480,929.8,480.4,930,480.9,930L480.9,930z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M484.5,928.2c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1
        c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1
        C484.2,930.1,484.5,929.2,484.5,928.2L484.5,928.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M480.9,923c-1.4,0-2.7,0.5-3.7,1.5c-1,1-1.5,2.3-1.5,3.7
        c0,1.4,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5c1.4,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.4-0.5-2.7-1.5-3.7
        C483.6,923.5,482.3,923,480.9,923L480.9,923z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M486,933.3c1.4-1.4,2.1-3.1,2.1-5.1c0-2-0.7-3.7-2.1-5.1
        c-1.4-1.4-3.1-2.1-5.1-2.1c-2,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3.1-2.1,5.1c0,2,0.7,3.7,2.1,5.1c1.4,1.4,3.1,2.1,5.1,2.1
        C482.9,935.4,484.6,934.7,486,933.3L486,933.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M481.8,937.4c-2,0-3.7,0.7-5.1,2.1c-1.4,1.4-2.1,3.1-2.1,5.1
        c0,2,0.7,3.7,2.1,5.1c1.4,1.4,3.1,2.1,5.1,2.1s3.7-0.7,5.1-2.1c1.4-1.4,2.1-3.1,2.1-5.1c0-2-0.7-3.7-2.1-5.1
        C485.5,938.1,483.8,937.4,481.8,937.4L481.8,937.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M475.9,954h11.7c1.2,0,1.9,0.6,1.9,1.9v12.6c0,1.2-0.6,1.9-1.9,1.9
        h-11.7c-1.2,0-1.9-0.6-1.9-1.9v-12.6C474,954.6,474.6,954,475.9,954L475.9,954z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M476.6,944.6c0,1.4,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5
        c1.4,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.4-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5c-1.4,0-2.7,0.5-3.7,1.5
        C477.1,942,476.6,943.2,476.6,944.6L476.6,944.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M483.1,943.3c-0.4-0.4-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5
        c-0.4,0.4-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5c0.4-0.4,0.5-0.8,0.5-1.3
        C483.6,944.1,483.5,943.7,483.1,943.3L483.1,943.3z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M484.4,942c-0.7-0.7-1.6-1.1-2.6-1.1c-1,0-1.9,0.4-2.6,1.1
        c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1c0.7-0.7,1.1-1.6,1.1-2.6
        C485.5,943.6,485.1,942.8,484.4,942L484.4,942z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M479.8,957.2c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2
        c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5
        C480.5,956.7,480.1,956.8,479.8,957.2L479.8,957.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M480.3,957.8c-0.2,0.2-0.2,0.4-0.2,0.6s0.1,0.4,0.2,0.6
        c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.4-0.3-0.6c-0.2-0.2-0.4-0.2-0.6-0.2
        C480.7,957.5,480.5,957.6,480.3,957.8L480.3,957.8z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="493" y1="953.3" x2="492.1" y2="953.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="492.1" y1="953.5" x2="473.1" y2="953.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M479.2,967c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
        c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5
        C479.4,966.1,479.2,966.5,479.2,967L479.2,967z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M480.1,967c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.3
        c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
        C480.2,966.5,480.1,966.7,480.1,967L480.1,967z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="491.9" y1="970.5" x2="492.7" y2="970.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M473.1,953.2v16.7c0.1,0.6,0.6,0.9,1.8,0.8h16l1.1-0.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M491.9,970.5c0.2-5.6,0.2-11.2,0.2-17"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M319.2,928.2c0,2-0.7,3.7-2.1,5.1c-1.4,1.4-3.1,2.1-5.1,2.1
        c-2,0-3.7-0.7-5.1-2.1c-1.4-1.4-2.1-3.1-2.1-5.1c0-2,0.7-3.7,2.1-5.1c1.4-1.4,3.1-2.1,5.1-2.1c2,0,3.7,0.7,5.1,2.1
        C318.5,924.5,319.2,926.2,319.2,928.2L319.2,928.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M317.2,928.2c0,1.4-0.5,2.7-1.5,3.7c-1,1-2.3,1.5-3.7,1.5
        c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.3-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5
        C316.7,925.5,317.2,926.8,317.2,928.2L317.2,928.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.6,930.8c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1
        c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6
        C315.7,929.2,315.3,930.1,314.6,930.8L314.6,930.8z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M313.3,929.5c-0.4,0.4-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5
        c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5c0.4,0.4,0.5,0.8,0.5,1.3
        C313.8,928.7,313.6,929.1,313.3,929.5L313.3,929.5z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M316.2,949.7c-1.4,1.4-3.1,2.1-5.1,2.1c-2,0-3.7-0.7-5.1-2.1
        c-1.4-1.4-2.1-3.1-2.1-5.1c0-2,0.7-3.7,2.1-5.1c1.4-1.4,3.1-2.1,5.1-2.1c2,0,3.7,0.7,5.1,2.1c1.4,1.4,2.1,3.1,2.1,5.1
        C318.3,946.6,317.6,948.3,316.2,949.7L316.2,949.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M317,954h-11.7c-1.2,0-1.9,0.6-1.9,1.9v12.6c0,1.2,0.6,1.9,1.9,1.9H317
        c1.2,0,1.9-0.6,1.9-1.9v-12.6C318.9,954.6,318.3,954,317,954L317,954z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M312.8,958.4c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
        c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6s0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
        C312.7,957.9,312.8,958.1,312.8,958.4L312.8,958.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M313.6,958.4c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5
        c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5
        C313.5,957.5,313.6,957.9,313.6,958.4L313.6,958.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M316.3,944.6c0,1.4-0.5,2.7-1.5,3.7c-1,1-2.3,1.5-3.7,1.5
        c-1.4,0-2.7-0.5-3.7-1.5c-1-1-1.5-2.3-1.5-3.7c0-1.4,0.5-2.7,1.5-3.7c1-1,2.2-1.5,3.7-1.5c1.4,0,2.7,0.5,3.7,1.5
        C315.8,942,316.3,943.2,316.3,944.6L316.3,944.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M314.7,944.6c0,1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.6,1.1
        c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1
        C314.4,942.8,314.7,943.6,314.7,944.6L314.7,944.6z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M312.4,943.3c0.4,0.4,0.5,0.8,0.5,1.3c0,0.5-0.2,0.9-0.5,1.3
        c-0.4,0.4-0.8,0.5-1.3,0.5s-0.9-0.2-1.3-0.5c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5
        S312,943,312.4,943.3L312.4,943.3z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="299.9" y1="953.3" x2="300.8" y2="953.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M310.7,965.8c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5
        c0.3,0.3,0.5,0.7,0.5,1.2s-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2
        S310.4,966.1,310.7,965.8L310.7,965.8z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M311.3,966.4c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
        c0.2,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6
        C311.1,966.7,311.2,966.5,311.3,966.4L311.3,966.4z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301,970.5l1.1,0.3h16c1.1,0,1.7-0.2,1.8-0.8v-16.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="300.2" y1="970.2" x2="301" y2="970.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="319.8" y1="953.2" x2="300.8" y2="953.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301,970.5c-0.2-5.6-0.2-11.2-0.2-17"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M300.8,953.5l0.1-9.4c0-6.2,1.1-13.8,3.4-22.8l0.3-1.1l0,0
        c0.2-0.9,0.7-1.4,1.2-1.5l0.1,0c4.5-0.5,8.9-0.6,13.4-0.2l0.4,0c0.7,0.1,1,0.6,1,1.4c-0.4,6.1-0.7,12-0.9,17.8l0,15.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="303.9" y1="912.9" x2="300.7" y2="914"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M328.9,864.2c-2.8,19.1-4.8,36.9-6,53.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M323,917.7c-6.2-0.9-12.4-0.9-18.6,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M304.3,917.7c4.8-19.8,9.7-37.9,14.7-54.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M342.6,909.9h52c3.1-0.2,5.3,0.6,6.5,2.2l13.2,14.4
        c1.8,1.8,4.9,2.7,9.4,2.7l30.5-0.5c3.3-0.3,4.8-1.8,4.5-4.5l-4.1-47.4c-0.4-4.5-2.9-6.7-7.6-6.5l-50.1-0.7l-51,0.7
        c-4.7-0.2-7.2,2-7.6,6.5l-2.4,27.3C336.1,907.6,338.3,909.6,342.6,909.9L342.6,909.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M316.1,865.3c-5.6,15-10.7,31.2-15.4,48.7l-1.4,5.5l3.2-0.4l1.5-6.1
        c5.3-21.8,10.2-37.8,14.6-48.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M304.3,917.7l-0.8,3.2c-1.6,6.2-2.7,11.7-3.2,16.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M325.3,853.6c1.5-0.5,3.3-0.8,5.3-0.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M321.8,857.5c1-1.3,2.1-2.6,3.5-3.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M319,863.5l0.8-2.5c0.5-1.2,1.2-2.4,2.1-3.6"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="321.8,857.5 317.5,861.4 316.1,865.3 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="318.5" y1="864.7" x2="319" y2="863.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="318.5" y1="864.7" x2="316.1" y2="865.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M325.3,853.6c-0.9,0.3-1.5,0.6-1.9,0.9l-4.9,4.6l-2.3,1.9
        c-0.9,0.9-1.4,2.3-1.4,4.2l0.2,0.4l0,0c-0.5,0.8-1.1,2.4-1.9,4.7l0,0h0.1l0,0l-0.2,0.7c-0.7,1.9-1.5,4.2-2.4,7.1h0l0,0
        c-1.5,4.5-3.2,10.1-5.3,16.8l-7.3,25"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M334.7,852.8c-1.5-0.1-2.9-0.1-4.1-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M330.6,852.7c0.6,0.9,0,4.7-1.7,11.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M334.7,852.8v-1.2c0-0.7-0.3-0.9-0.8-0.8l-3.9,0.1
        c-1.1,0.3-2,0.4-2.9,0.4c-1.1,0-1.7,0.7-1.7,2.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M296.6,925.6l-0.3-11.4c-0.2-3.2-2-4.8-5.4-4.9l-15.8,0.4
        c-3.4,0.4-5.1,2-5.3,4.6l-0.1,7.3l0,0h0l0,1.7c0.2,3.7,2.2,5.6,6,5.7l17.6,0c1.4,0.1,2.3-0.6,2.9-2.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M294.3,930l-19.3,0c-4.3-0.1-6.5-2.2-6.7-6.3l0-1.9h0l0,0l0.1-8.1
        c0.2-2.9,2.2-4.6,5.9-5.1l17.6-0.5c3.8,0.1,5.8,1.9,6,5.4v6.3"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="398,852.1 398,851 394.9,851 394.9,852.1 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M464,864.2c-17.4-0.7-35.5-1-54.1-1.1c-3.9,0-7.8,0-11.8,0h-2.2
        c-4.3,0-8.6,0-12.9,0c-18.6,0.1-36.6,0.4-54.1,1.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.9" y1="852.1" x2="398" y2="852.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M382.3,853.9v2.7c0,0.5,0.2,0.7,0.7,0.7h26.9c0.4,0,0.7-0.3,0.7-0.7
        v-2.7c0-0.5-0.2-0.7-0.7-0.7h-26.7C382.6,853.1,382.3,853.4,382.3,853.9L382.3,853.9z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M368.1,852.4v4.1c0.2,2.6,3.1,4.1,8.8,4.3H416c5.6-0.2,8.6-1.6,8.8-4.3
        v-4.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="394.9" y1="852.1" x2="368.1" y2="852.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M467.6,853.6c0-1.5-0.6-2.2-1.7-2.2c-0.9,0-1.9-0.1-2.9-0.4l-3.9-0.1
        c-0.6-0.1-0.8,0.1-0.8,0.8v1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M458.2,852.8c1.5-0.1,2.9-0.1,4.1-0.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="458.2" y1="852.8" x2="424.8" y2="852.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="424.8" y1="852.4" x2="398" y2="852.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.1,919.9l-7.3-25c-2.1-6.8-3.8-12.4-5.3-16.8l0,0h0
        c-0.9-2.8-1.7-5.2-2.4-7.1l-0.2-0.7l0,0h0.1l0,0c-0.8-2.3-1.5-3.8-1.9-4.7l0,0l0.2-0.4c-0.1-1.9-0.5-3.2-1.4-4.2l-2.3-1.9
        l-4.9-4.6c-0.4-0.3-1-0.6-1.9-0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M473.9,863.5l-0.8-2.5c-0.5-1.2-1.2-2.4-2.1-3.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="474.4" y1="864.7" x2="473.9" y2="863.5"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="471,857.5 475.4,861.4 476.8,865.3 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="476.8" y1="865.3" x2="474.4" y2="864.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M471,857.5c-1-1.3-2.1-2.6-3.4-3.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M469.9,917.7l0.1,1.1c0.5,6.7,0.8,13.3,1,19.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M474.4,864.7c4.4,10.4,9.3,26.5,14.6,48.2l1.5,6.1l3.2,0.4l-1.4-5.5
        c-4.7-17.4-9.8-33.6-15.4-48.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M488.6,917.7l0.8,3.2c1.6,6.2,2.7,11.7,3.2,16.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M469.9,917.7c6.2-0.9,12.4-0.9,18.6,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M498.6,930l19.3,0c4.3-0.1,6.5-2.2,6.7-6.3l0-1.9h0l0,0l-0.1-8.1
        c-0.2-2.9-2.2-4.6-5.9-5.1l-17.6-0.5c-3.8,0.1-5.8,1.9-6,5.4v6.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M496.3,925.6l0.3-11.4c0.2-3.2,2-4.8,5.4-4.9l15.8,0.4
        c3.4,0.4,5.1,2,5.3,4.6l0.1,7.3l0,0h0l0,1.7c-0.2,3.7-2.2,5.6-6,5.7l-17.6,0c-1.4,0.1-2.3-0.6-2.9-2.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M464,864.2c2.8,19.1,4.8,36.9,6,53.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M488.6,917.7c-4.8-19.8-9.7-37.9-14.7-54.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M462.3,852.7c-0.6,0.9,0,4.7,1.7,11.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M467.6,853.6c-1.5-0.5-3.3-0.8-5.3-0.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="368.1" y1="852.4" x2="334.7" y2="852.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M471,938.4h-66.9c-5.2,0-8.4-0.7-9.5-2.2l-8.4-12.4
        c-2.6-4.1-6.3-6.1-11.2-6.1H323"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="496.3" y1="925.6" x2="495.6" y2="922.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M498.6,930l-0.5-0.4c-0.5-0.6-1-1.5-1.5-2.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M500.8,939.2l-0.4-3.7c-0.1-1.5-0.3-2.6-0.6-3.6l-0.2-0.5
        c-0.3-0.7-0.7-1.1-1.2-1.5l0,0l0.2,0"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="496.7" y1="926.8" x2="496.3" y2="925.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="500.1" y1="939.3" x2="500.8" y2="939.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="498.9" y1="939.4" x2="500.1" y2="939.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="497.8" y1="939.6" x2="498.9" y2="939.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="500.1" y1="939.3" x2="498.2" y2="938.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="498.2" y1="938.5" x2="497.3" y2="938.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="497.8" y1="939.6" x2="497.3" y2="938.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M503.5,941.7l-0.4-2c-0.1-0.5-0.4-0.7-0.9-0.7l-1.4,0.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="498.2" y1="938.5" x2="495.6" y2="922.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="501.2" y1="943" x2="501.6" y2="946.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M503.5,941.7c0.3,0.8-0.1,1.1-1.1,1.2l-1.2,0.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M501.6,946.1l2.9-0.3c0.5-0.1,0.7-0.4,0.6-0.9l-0.5-2.6
        c-0.1-0.5-0.4-0.7-0.9-0.7l-0.4,0"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="500.1" y1="943.1" x2="499.6" y2="943.2"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="500.1,943.1 500.3,947.5 499.6,943.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="506.9,963.1 502.8,956.7 502.6,954.7 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="502.6" y1="954.7" x2="502.6" y2="954.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="502.6" y1="954.6" x2="501.6" y2="946.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="501.2" y1="943" x2="500.1" y2="943.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M502.6,954.6l-2.8-0.8c-0.7,0-1.3,2.9-2,8.7l-0.1,0.9
        c-0.8,12.4-2.1,23.9-3.9,34.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="499.6" y1="943.2" x2="498.9" y2="939.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.8,941.7c2.1-0.1,4.3,0.3,6.4,1.1l-1.4-3.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="471" y1="955.2" x2="471" y2="953.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.8,941.7l0.1,11.6c0,5.8-0.1,11.4-0.2,17l-0.1,1.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.5,937.5c0.1,1.4,0.2,2.8,0.3,4.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="471" y1="953.3" x2="471" y2="938.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="492.6" y1="974.8" x2="492.7" y2="972.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="492.7" y1="972.1" x2="489.7" y2="972.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.7" y1="972.6" x2="491.4" y2="974.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="491.4" y1="974.8" x2="492.6" y2="974.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="471" y1="972.6" x2="471" y2="971.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="471" y1="975.5" x2="471" y2="972.6"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="491.4,974.8 476.3,974.8 471,975.5 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M470.9,991.4c0.1-0.3,0.2-0.6,0.1-0.9v-14.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M489.9,990.3c-4.6,0.8-10.9,1.2-18.8,1.1h-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M322,991.4c0.5,1.1,1.9,1.5,4.3,1.5h140.3c2.4,0.1,3.9-0.4,4.3-1.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M303,990.3c0.4,2.5,2.2,3.9,5.6,4.1h175.7c3.4-0.2,5.2-1.6,5.6-4.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M493.8,997.9c-1.6-0.6-2.4-1.9-2.4-3.7c0.5-6.3,0.9-12.8,1.2-19.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.7" y1="972.6" x2="471" y2="972.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="489.9" y1="990.3" x2="491.4" y2="974.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M507.3,969.9c0-2.6-0.1-4.9-0.3-6.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M493.9,1005.5c2.5-9,4.2-18.9,5.1-29.7l0.1-0.9
        c1-11.5,2.1-18.2,3.5-20.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M498.6,1005.7c1.1-3.3,2-6.9,2.9-10.8c0.8-3.7,1.6-7.8,2.3-12.2
        c1.4-11.7,2.4-18.2,3.1-19.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M501.5,1009.3c1.7-6.3,3.2-14,4.3-23.1c0.9-6.4,1.4-11.8,1.4-16.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="497.3" y1="938.4" x2="492.5" y2="937.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="471" y1="971.3" x2="471" y2="955.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="500.7" y1="1005.7" x2="498.6" y2="1005.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="498.6" y1="1005.7" x2="493.9" y2="1005.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="501.5" y1="1002.3" x2="500.7" y2="1005.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="501.5" y1="1002.3" x2="501.5" y2="1009.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M500.7,1005.7c-0.4,3.1-1.4,4.5-3,4.3h-6.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M491.6,1010c0.4-1.5,0.7-3,1-4.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M467.7,1003.5c0,0.5-0.2,0.7-0.7,0.7h-22.7c-0.5,0-0.7-0.2-0.7-0.7v-2.4
        c0-0.5,0.2-0.7,0.7-0.7h22.7c0.5,0,0.7,0.2,0.7,0.7V1003.5L467.7,1003.5z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="472.7" y1="1021.2" x2="472.7" y2="1028.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="466.6" y1="1021.4" x2="472.7" y2="1021.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="466.6" y1="1028.1" x2="466.6" y2="1021.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="472.7" y1="1028.1" x2="466.6" y2="1028.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="472.7" y1="1030.2" x2="472.7" y2="1028.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="466.6" y1="1030.2" x2="466.6" y2="1028.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="472.7" y1="1030.2" x2="466.6" y2="1030.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M482.6,1020.8c0.1,0.4,0.2,0.7,0.2,1.1h2.2l5.2-7.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.6,1005.4c-2.1-4.6-8.4-6.9-18.8-6.9l-57.4,0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M490.2,1014.5c0.5-1.4,0.9-2.9,1.3-4.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="472.7" y1="1021.2" x2="482.6" y2="1020.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M416.4,1008.7h53.1c10.6,0.6,17.5,2.5,20.7,5.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M482.6,1020.8c-0.8-2.6-4.8-4.4-11.9-5.4h-54.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="472.7" y1="1039.5" x2="472.7" y2="1036.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M472.7,1039.5v25.8c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5
        v-56"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M493.8,997.9c-0.4,2.7-0.7,5.2-1.2,7.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="472.7" y1="1036.3" x2="472.7" y2="1030.2"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="466.6,1030.2 466.6,1034.5 443,1034.5 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="492.6" y1="1005.4" x2="493.9" y2="1005.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M507.3,969.9c-0.6,8.4-2.5,19.2-5.7,32.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M376.6,937.7c-1.1-1.8-2.1-3.8-3.2-5.8c-1.4-2.6-3.4-3.9-6-3.9h-45.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M471,955.2l-78.9,0.9c-4.6-0.4-7.8-1.6-9.5-3.5l-5.8-8.8
        c-1.2-2.4-2.7-3.6-4.3-3.4h-50.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M471,953.3l-73.5,0.8c-6,0.3-9.9-0.7-11.7-2.8
        c-3.1-3.7-6.2-8.3-9.3-13.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M370.9,937.7l-2.4-4.5c-1-2.3-2.9-3.5-5.8-3.6h-34.1
        c-1.4,0-2.2,0.6-2.3,1.9l-0.3,6.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="376.6" y1="937.7" x2="370.9" y2="937.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M390.1,1015.4v-2.2c0-0.2,0.1-0.4,0.4-0.4h12c0.2,0,0.4,0.1,0.4,0.4v2.2
        "/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M416.4,999.2c0.4-1.9-0.1-2.9-1.3-2.8h-37.4c-1.2-0.1-1.7,0.9-1.3,2.8"
        />
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="376.5" y1="1008.7" x2="376.5" y2="999.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="376.2" y1="1031.5" x2="373.6" y2="1031.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M373.6,1031.2c0.7-0.2,1.5-0.4,2.3-0.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.1" y1="1030.2" x2="375.9" y2="1030.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M378.3,1031c-0.7,0.2-1.4,0.3-2,0.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="375.9" y1="1030.6" x2="378.3" y2="1031"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="378.5" y1="1030.2" x2="375.7" y2="1029.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="375.7" y1="1029.7" x2="374.1" y2="1029.4"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="378.3,1031 384.7,1032 384.2,1032.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="384.2,1032.2 383.5,1032.3 376.2,1031.5 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="387" y1="1031.6" x2="380.8" y2="1030.5"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="387.5,1032.6 389,1032.3 387.7,1031.8 387,1031.6 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="380.8" y1="1030.5" x2="378.5" y2="1030.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="402.8" y1="1015.4" x2="402.8" y2="1016.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M402.8,1016.4v0.7c0,0.2-0.1,0.4-0.4,0.4h-12c-0.2,0-0.4-0.1-0.4-0.4
        v-0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="390.1" y1="1016.4" x2="390.1" y2="1015.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="388.3" y1="1028" x2="388.7" y2="1028.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M392.3,1027.9c-1.3,0-2.6,0.1-3.9,0.1h0"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="389.9" y1="1029.7" x2="391.3" y2="1031.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="388.7" y1="1028.4" x2="389.3" y2="1029.1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="391.3,1031.2 391.5,1031.4 393.1,1031.9 395.5,1031.9 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="389.3" y1="1029.1" x2="389.9" y2="1029.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="393.4" y1="1029.6" x2="393" y2="1029"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="393" y1="1029" x2="392.5" y2="1028.3"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="393.4,1029.6 394.4,1031.1 395.4,1031.1 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="392.5" y1="1028.3" x2="392.3" y2="1027.9"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="399.4,1031.2 399.3,1031.4 397.9,1031.9 395.5,1031.9 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="395.4,1031.1 396.3,1031.1 396.7,1029.5 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="397" y1="1028.3" x2="396.8" y2="1029"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="396.8" y1="1029" x2="396.7" y2="1029.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="400.8" y1="1028.3" x2="400.5" y2="1029"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="400.2" y1="1029.6" x2="399.4" y2="1031.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="400.5" y1="1029" x2="400.2" y2="1029.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="401" y1="1027.9" x2="400.8" y2="1028.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="397.1" y1="1027.9" x2="397" y2="1028.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="401" y1="1027.9" x2="397.1" y2="1027.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="393" y1="1029" x2="396.8" y2="1029"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="396.7" y1="1029.5" x2="393.4" y2="1029.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="395.5" y1="1031.9" x2="395.4" y2="1031.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="397" y1="1028.3" x2="392.5" y2="1028.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="397.1" y1="1027.9" x2="392.3" y2="1027.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M388.3,1028c-5.3,0.2-10,0.7-14.2,1.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M375.7,1029.7c3.8-0.6,8.1-1,13-1.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M389.9,1029.7c-3.4,0.1-6.4,0.4-9.1,0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M389.3,1029.1c-4,0.2-7.6,0.5-10.8,1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M391.3,1031.2c-1.6,0.1-3,0.2-4.3,0.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="376.5" y1="1015.4" x2="376.5" y2="1008.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="390.1" y1="1015.4" x2="376.5" y2="1015.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M349.4,1001.1c0-0.5-0.2-0.7-0.7-0.7h-22.7c-0.5,0-0.7,0.2-0.7,0.7v2.4
        c0,0.5,0.2,0.7,0.7,0.7h22.7c0.5,0,0.7-0.2,0.7-0.7V1001.1L349.4,1001.1z"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M440.5,1032.5l0.1-0.7c0-1.2-1-2.3-3-3.3c-2.1-1.1-5.5-2.1-10-3
        c-8.6-1.7-19-2.6-31.2-2.6c-12.2,0-22.6,0.9-31.2,2.6c-4.5,0.9-7.8,1.9-10,3c-2,1-3,2.1-3,3.3c0,0.3,0.1,0.7,0.2,1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="349.8,1025.1 343.5,1025.1 345.1,1026.2 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M349.8,1025.1l-0.2-1.3c0-2.1,4.5-3.8,13.6-5.3
        c7.6-1.2,16.5-1.9,26.8-2.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M371.4,1029.9c-1.1,0.2-2,0.5-2.9,0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="370.4" y1="1030.8" x2="368.4" y2="1030.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M368.4,1030.6c-0.7,0.2-1.4,0.4-1.9,0.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="366.5" y1="1031.2" x2="368.5" y2="1031.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="368.5" y1="1031.4" x2="371.8" y2="1031.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M374.6,1032c-1.2,0.5-1.9,1.1-1.9,1.7l0,0.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M370,1034.6c0.2,0.2,0.6,0.5,1,0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.7" y1="1033.9" x2="369.6" y2="1034"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M371.8,1031.8c-1.5,0.6-2.3,1.2-2.3,2l0,0.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M365.8,1034.1l-0.1-0.3c0-0.8,0.9-1.6,2.7-2.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M367.3,1035.5c-0.5-0.3-0.9-0.5-1.1-0.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="366.8" y1="1036.3" x2="368.8" y2="1036.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="367.3" y1="1035.5" x2="370.9" y2="1035.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="369.6" y1="1034" x2="365.8" y2="1034.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="370" y1="1034.6" x2="366.2" y2="1034.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="368.8" y1="1036.2" x2="372.4" y2="1035.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M364,1034.8c0.3,0.3,0.7,0.6,1.2,0.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M363.6,1034.1c0.1,0.2,0.2,0.5,0.4,0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M366.5,1031.2c-2,0.8-3,1.6-3,2.5l0.1,0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M365.2,1035.7c0.4,0.2,0.9,0.4,1.5,0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="365.2" y1="1035.7" x2="367.3" y2="1035.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="365.8" y1="1034.1" x2="363.6" y2="1034.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="366.2" y1="1034.8" x2="364" y2="1034.8"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="352.8,1039.5 357.7,1047.7 376.1,1047.7 376.1,1039.6 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351" y1="1036.3" x2="349.9" y2="1034.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="352.8" y1="1039.5" x2="351" y2="1036.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M352.7,1032.7c1,2,5.2,3.8,12.7,5.3c3.3,0.7,6.9,1.2,10.7,1.6
        c0,0,0,0,0,0"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="402.5,1036.1 401.7,1036.2 401.8,1036.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="405.9,1035.7 405.3,1035.5 402.7,1035.2 400.6,1035.4 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="400.6" y1="1035.4" x2="402.5" y2="1036.1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="400.6,1035.4 398.5,1035.6 398.4,1036.2 398.8,1036.3 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="401.8" y1="1037.8" x2="402.9" y2="1038.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="398.8" y1="1036.3" x2="401.8" y2="1037.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M401.8,1037.8c-1.9,0.1-3.9,0.1-5.9,0.1l-4.4-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M390.6,1038.4c1.7,0.1,3.5,0.1,5.3,0.1c2.5,0,4.8-0.1,7-0.1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="393.9,1036.4 394.2,1036.2 393.9,1035.6 391.6,1035.5 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="385,1037.5 389.1,1036.1 389.1,1036.1 389.9,1036.2 
        389.9,1036.2 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="391.6" y1="1035.5" x2="389.9" y2="1036.2"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="388,1037.7 390.8,1036.2 389.9,1036.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="391.6,1035.5 389.4,1035.3 387,1035.6 386.5,1035.8 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="389.5" y1="1039.1" x2="390.6" y2="1038.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="388.9" y1="1039.5" x2="389" y2="1039.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="388.9" y1="1039.5" x2="388.9" y2="1039.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="389" y1="1039.4" x2="389.5" y2="1039.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="389" y1="1039.4" x2="388.9" y2="1039.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="390.6" y1="1038.4" x2="391.5" y2="1037.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="391.5" y1="1037.9" x2="393.9" y2="1036.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M404.2,1039c-2.6,0.1-5.4,0.2-8.4,0.2c-2.2,0-4.3,0-6.3-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M388.9,1039.5c2.2,0.1,4.6,0.1,7,0.1c3.2,0,6.3-0.1,9.1-0.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="398.8" y1="1036.3" x2="393.9" y2="1036.4"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="382.1,1032.8 386.1,1033 387.5,1032.6 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="385.8,1034.2 385.2,1033.8 381.3,1033.7 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="383.3,1035.1 384.3,1035 386.4,1034.6 385.8,1034.2 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M386.5,1035.8l-1-0.1c-0.9-0.2-1.6-0.3-2.2-0.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M381.3,1033.7c0-0.3,0.3-0.6,0.9-0.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="382" y1="1034.6" x2="381.7" y2="1034.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M381.5,1034.2c0.1,0.1,0.3,0.2,0.5,0.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="381.7" y1="1034.4" x2="381.5" y2="1034.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="383.5" y1="1038" x2="381.7" y2="1038.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="381.7" y1="1038.6" x2="380.7" y2="1038.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="385.1" y1="1039.3" x2="385.8" y2="1038.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="385.8" y1="1038.9" x2="387.1" y2="1038.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="386.5" y1="1035.8" x2="382" y2="1037.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M380.7,1038.9c1.4,0.2,2.9,0.3,4.4,0.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="382" y1="1037.1" x2="380.4" y2="1037.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M381.7,1038.6c1.3,0.1,2.7,0.2,4.1,0.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="385" y1="1037.5" x2="383.5" y2="1038"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M383.5,1038c1.1,0.1,2.3,0.2,3.5,0.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="385.8" y1="1034.2" x2="381.7" y2="1034.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M366.8,1036.3c1.5,0.6,3.6,1.1,6.3,1.5c1.4,0.2,2.8,0.5,4.3,0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="378.4" y1="1038.2" x2="377.3" y2="1038.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M378.4,1038.2c-1.3-0.2-2.6-0.4-3.9-0.6c-2.4-0.4-4.3-0.9-5.7-1.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M375.3,1035.7c1.1,0.4,2.5,0.7,4.2,1c0.8,0.1,1.7,0.3,2.6,0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M373.9,1035.1c-0.4-0.2-0.6-0.4-0.8-0.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M377.3,1038.6c1.1,0.1,2.2,0.3,3.4,0.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="375.3" y1="1035.7" x2="383.3" y2="1035.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M372.4,1035.9c1.2,0.4,2.9,0.8,4.9,1.2c1,0.2,2,0.4,3.1,0.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="380.4" y1="1037.6" x2="378.4" y2="1038.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.9" y1="1035.1" x2="382" y2="1034.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="381.5" y1="1034.2" x2="373.1" y2="1034.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M385.1,1039.3c1.2,0.1,2.5,0.1,3.8,0.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M385,1037.5c1,0.1,2,0.2,3,0.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="387.1" y1="1038.2" x2="388" y2="1037.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M376.1,1039.6c6.1,0.7,13,1,20.4,1c7.4,0,14.1-0.3,20.2-1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="372.4" y1="1035.9" x2="375.3" y2="1035.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="371.8" y1="1031.8" x2="374.6" y2="1032"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="374.6" y1="1032" x2="382.1" y2="1032.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="384.2" y1="1032.2" x2="387.5" y2="1032.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="381.3" y1="1033.7" x2="372.7" y2="1033.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.1" y1="1034.5" x2="370" y2="1034.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="370.9" y1="1035.3" x2="373.9" y2="1035.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M373.1,1030.2c-1,0.2-1.9,0.4-2.7,0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="374.1" y1="1029.4" x2="371.4" y2="1029.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="373.6" y1="1031.2" x2="370.4" y2="1030.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="371.4" y1="1029.9" x2="373.1" y2="1030.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M352.7,1032.7c-1.6-3-2.5-5.6-2.9-7.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="349.9" y1="1034.5" x2="345.1" y2="1026.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M442.9,1025.1c-0.1,2.1-0.9,4.6-2.4,7.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M402.8,1016.4c10.1,0.2,18.9,0.9,26.4,2.1c9.1,1.5,13.6,3.2,13.7,5.3
        v1.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M416.8,1039.6C416.8,1039.6,416.8,1039.6,416.8,1039.6
        c3.9-0.4,7.6-0.9,11-1.6c7.7-1.5,11.9-3.4,12.8-5.5l0,0"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="447.7,1026.2 452,1026.2 452,1022 466.6,1021.4 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="442.9,1025.1 449.4,1025.1 447.7,1026.2 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.4" y1="999.2" x2="416.4" y2="1008.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="406.6,1032 406.3,1031.9 407.2,1031.7 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="403.9,1031.5 403.2,1031.7 402.1,1032.2 403.7,1032.5 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="404.7" y1="1031.4" x2="403.9" y2="1031.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M409.7,1030.4l-5.7,0.3c-0.2,0.2,0.1,0.4,0.7,0.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="407.6,1032.2 407.5,1032.2 407,1032 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="408.5" y1="1032" x2="407.6" y2="1032.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="407.2" y1="1031.7" x2="408.5" y2="1032"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="403.7" y1="1032.5" x2="407" y2="1032"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="409.7" y1="1030.4" x2="404.7" y2="1031.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="407" y1="1032" x2="406.6" y2="1032"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="407.6" y1="1032.2" x2="406.6" y2="1032"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="414.5" y1="1030.4" x2="417.2" y2="1029.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M417,1030.9c-0.8-0.2-1.6-0.4-2.5-0.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="414.3" y1="1029.4" x2="411.7" y2="1029.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="415.8" y1="1029.1" x2="414.3" y2="1029.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="417" y1="1030.9" x2="408.5" y2="1032"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="407.2" y1="1031.7" x2="414.5" y2="1030.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.7" y1="1029.9" x2="409.7" y2="1030.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M424.1,1030.8c-0.7-0.2-1.4-0.4-2.2-0.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M421.9,1030.3c-1-0.2-2-0.4-3.2-0.7h0"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="419" y1="1031.5" x2="422.2" y2="1031.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="421.9" y1="1030.3" x2="420.1" y2="1030.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="422.2" y1="1031.1" x2="424.1" y2="1030.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="410.1" y1="1032.5" x2="419" y2="1031.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="420.1" y1="1030.5" x2="417" y2="1030.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M420.1,1030.5c-0.9-0.2-1.9-0.4-2.9-0.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M418.7,1029.6c-1-0.2-1.9-0.3-2.9-0.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="417.2" y1="1029.9" x2="418.7" y2="1029.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.4" y1="1015.4" x2="416.4" y2="1008.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="428.2" y1="1033.7" x2="426" y2="1033.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="428" y1="1034.4" x2="425.8" y2="1034.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M426,1035.9c0.5-0.2,0.9-0.4,1.2-0.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M427.2,1035.3c0.4-0.3,0.7-0.6,0.8-0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M428,1034.4c0.1-0.2,0.2-0.4,0.2-0.7v-0.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="418.9" y1="1034.2" x2="418.4" y2="1034.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="418.9" y1="1034.3" x2="418.4" y2="1034.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="418.9" y1="1034.3" x2="418.9" y2="1034.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M420.2,1034.9L420.2,1034.9c0-0.3-0.4-0.5-1.3-0.6l0,0"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="423.8,1035.8 420.2,1035.6 420.2,1035.6 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="421.3" y1="1035" x2="420.2" y2="1034.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="418.2" y1="1035.5" x2="420.2" y2="1035.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M422,1034.3c-0.1,0.2-0.4,0.4-0.7,0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="421.3" y1="1035" x2="425" y2="1035.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="422" y1="1034.3" x2="418.9" y2="1034.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="426" y1="1035.9" x2="423.8" y2="1035.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M416.2,1038.3l2.5-0.4c3.3-0.6,5.7-1.2,7.3-2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="425" y1="1035.2" x2="427.2" y2="1035.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="426" y1="1033.7" x2="422.2" y2="1033.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M425,1035.2c0.4-0.3,0.6-0.5,0.8-0.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="425.8" y1="1034.4" x2="422" y2="1034.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M411.8,1035.1c0.2,0.1,0.5,0.2,1,0.3c0.9,0.2,1.9,0.2,3.1,0.2l1,0
        c0.5,0,0.9,0,1.4-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M410.9,1036.9l2.9-0.5c1-0.2,1.8-0.4,2.5-0.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="414" y1="1034.1" x2="411.6" y2="1034.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.9" y1="1034.5" x2="411.3" y2="1034.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M414,1034.1c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.7,0.1-0.9,0.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.3" y1="1034.5" x2="411.6" y2="1034.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="413.1" y1="1038.7" x2="411.9" y2="1038.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M415,1038l2.2-0.4c3-0.5,5.2-1.2,6.7-1.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.2" y1="1038.3" x2="415" y2="1038"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="415" y1="1038" x2="412.7" y2="1037.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="412.7" y1="1037.4" x2="410.9" y2="1036.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M413.1,1038.7c1.1-0.1,2.1-0.3,3.1-0.4"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="416.3,1035.7 410.9,1036 409.4,1035 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M420.2,1035.6c-0.9,0.4-2.2,0.8-3.7,1.1l-3.7,0.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="410.1,1034.3 410,1034.4 410,1034.4 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="410" y1="1034.4" x2="410.4" y2="1034.1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="410.4,1034.1 410.3,1034.1 410.1,1034.3 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M409.2,1032.6c0.7,0.3,1.1,0.5,1.2,0.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="409.4" y1="1035" x2="408.9" y2="1034.9"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="403.7,1032.5 405.3,1032.9 408.3,1032.7 409.2,1032.6 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="403.5,1036 403.5,1036 402.5,1036.1 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M408.9,1034.9c-0.7,0.2-1.6,0.5-2.7,0.7l-0.3,0"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="406,1034.1 405.5,1034.5 408.9,1034.9 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="410.4,1033.5 406.4,1033.7 406,1034.1 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="405.2" y1="1037.6" x2="406.4" y2="1038.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="405" y1="1039.4" x2="404.2" y2="1039"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="409.8" y1="1037.8" x2="408.1" y2="1037.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="408" y1="1038.8" x2="408.9" y2="1039.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M406.4,1038.1c1.2-0.1,2.3-0.2,3.4-0.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="403.5" y1="1036" x2="408.1" y2="1037.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M405,1039.4c1.3-0.1,2.6-0.1,3.9-0.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="406.4" y1="1038.1" x2="408" y2="1038.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="408.1" y1="1037.3" x2="405.2" y2="1037.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="410.1" y1="1034.3" x2="406" y2="1034.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.9" y1="1038.4" x2="409.8" y2="1037.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M408.9,1039.1c1.5-0.1,2.9-0.2,4.2-0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M408,1038.8c1.3-0.1,2.6-0.2,3.9-0.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="405.9" y1="1035.7" x2="410.9" y2="1036.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="409.4" y1="1035" x2="411.8" y2="1035.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.6" y1="1034.1" x2="410.4" y2="1034.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="410" y1="1034.4" x2="411.3" y2="1034.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.5" y1="1033.5" x2="410.4" y2="1033.5"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="416.8,1039.6 416.8,1047.7 435.2,1047.7 440.1,1039.5 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="418.2" y1="1035.5" x2="411.8" y2="1035.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="420.2" y1="1035.6" x2="416.3" y2="1035.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.5" y1="1033.5" x2="422.2" y2="1033.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="411.9" y1="1034.5" x2="420.2" y2="1034.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="418.4" y1="1034.2" x2="414" y2="1034.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.1" y1="1039.5" x2="441.9" y2="1036.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="443" y1="1034.5" x2="441.9" y2="1036.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M428.2,1033.7c-0.1-1-1.4-2-4.1-2.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M422.2,1033.6c-0.1-0.8-1.1-1.5-3.1-2.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M426,1033.7c-0.1-0.9-1.3-1.8-3.8-2.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M410.1,1032.5c0.8,0.3,1.2,0.6,1.4,1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="409.2" y1="1032.6" x2="410.1" y2="1032.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="443" y1="1034.5" x2="447.7" y2="1026.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M414.3,1029.4c-4-0.6-8.5-0.9-13.5-1.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M415.8,1029.1c-4.4-0.6-9.3-1-14.8-1.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="402.9" y1="1038.4" x2="404.2" y2="1039"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="401.8" y1="1036.2" x2="405.2" y2="1037.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="401.8" y1="1036.2" x2="403.5" y2="1036"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M400.5,1029c4.2,0.1,7.9,0.4,11.3,0.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M409.7,1030.4c-2.8-0.4-6-0.7-9.5-0.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M399.4,1031.2c1.6,0.1,3.1,0.2,4.5,0.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.4" y1="1008.7" x2="376.5" y2="1008.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="416.4" y1="1015.4" x2="402.8" y2="1015.4"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="321.8,971.3 396.3,972.1 471,971.3 		"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="321.8,972.6 396.3,973.4 471,972.6 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="440.1" y1="1039.5" x2="472.7" y2="1039.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="441.9" y1="1036.3" x2="472.7" y2="1036.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M332.4,931.1c-1.2,0.2-2.1,0.7-2.8,1.3c-0.2,0.2-0.3,0.4-0.4,0.6
        c-0.1,0.3-0.1,0.6-0.1,0.9c0.1,0.4,0.4,0.8,0.8,1.2c0.4,0.3,0.8,0.5,1.4,0.7c1,0.4,2.2,0.6,3.5,0.6c1.3-0.1,2.4-0.3,3.4-0.7
        c0.5-0.2,1-0.5,1.2-0.8c0.5-0.5,0.6-1,0.5-1.5c-0.1-0.4-0.4-0.8-0.9-1.2c-0.4-0.3-0.8-0.5-1.3-0.7c-0.5-0.2-1.1-0.3-1.6-0.4
        l-1.9-0.1L332.4,931.1L332.4,931.1z"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="326" y1="937.7" x2="321.9" y2="937.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M322.3,928c-0.2,3.3-0.3,6.5-0.4,9.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="321.9" y1="937.7" x2="321.8" y2="940.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M296.2,926.8c-0.5,1.3-1,2.2-1.5,2.7l-0.5,0.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M294.3,930l0.2,0l0,0c-0.5,0.3-0.8,0.8-1.2,1.5l-0.2,0.5
        c-0.3,0.9-0.5,2.1-0.6,3.6l-0.4,3.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="296.2" y1="926.8" x2="296.6" y2="925.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M292.1,939.2l-1.4-0.2c-0.5-0.1-0.8,0.2-0.9,0.7l-0.4,2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="292.8" y1="939.3" x2="292.1" y2="939.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="294.7" y1="938.5" x2="295.6" y2="938.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="295.1" y1="939.6" x2="294" y2="939.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="295.6" y1="938.4" x2="295.1" y2="939.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="294.7" y1="938.5" x2="292.8" y2="939.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="292.8" y1="939.3" x2="294" y2="939.4"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="292.8,943.1 292.6,947.5 293.3,943.2 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M289.4,941.7c-0.3,0.8,0.1,1.1,1.1,1.2l1.2,0.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="291.7" y1="943" x2="291.3" y2="946.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="293.3" y1="943.2" x2="292.8" y2="943.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="292.8" y1="943.1" x2="291.7" y2="943"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M289.4,941.7l-0.4,0c-0.5-0.1-0.8,0.2-0.9,0.7l-0.5,2.6
        c-0.1,0.5,0.1,0.8,0.6,0.9l2.9,0.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="290.3" y1="954.6" x2="290.3" y2="954.7"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="290.3,954.7 290.1,956.7 285.9,963.1 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M299.1,997.9c-1.8-10.6-3.1-22.1-3.9-34.5l-0.1-0.9
        c-0.7-5.8-1.3-8.7-2-8.7l-2.8,0.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="291.3" y1="946.1" x2="290.3" y2="954.6"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M300.1,941.7l-0.1,11.6c0,5.8,0.1,11.4,0.2,17l0.1,1.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="297.3" y1="922.7" x2="296.6" y2="925.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="297.3" y1="922.7" x2="294.7" y2="938.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="294" y1="939.4" x2="293.3" y2="943.2"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M295.1,939.6l-1.4,3.2c2.1-0.8,4.2-1.2,6.4-1.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="295.6" y1="938.4" x2="300.4" y2="937.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M300.4,937.5c-0.1,1.4-0.2,2.8-0.3,4.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="303.2" y1="972.6" x2="301.5" y2="974.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="300.2" y1="972.1" x2="300.3" y2="974.8"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="303.2" y1="972.6" x2="300.2" y2="972.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="300.3" y1="974.8" x2="301.5" y2="974.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M285.9,963.1c-0.2,1.9-0.4,4.2-0.3,6.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M290.3,954.7c1.4,1.9,2.6,8.6,3.5,20.1l0.1,0.9
        c0.9,10.9,2.6,20.8,5.1,29.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M285.9,963.1c0.7,1.3,1.8,7.8,3.1,19.5c0.7,4.4,1.4,8.5,2.3,12.2
        c0.9,4,1.8,7.6,2.9,10.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M285.6,969.9c0.1,4.4,0.5,9.8,1.4,16.2c1.1,9.1,2.6,16.8,4.3,23.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="301.5" y1="974.8" x2="303" y2="990.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M299.1,997.9c1.6-0.6,2.4-1.9,2.4-3.7c-0.5-6.3-0.9-12.8-1.2-19.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="321.8" y1="971.3" x2="321.8" y2="972.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="321.8" y1="975.5" x2="321.8" y2="972.6"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="301.5,974.8 316.6,974.8 321.8,975.5 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M303,990.3c4.6,0.8,10.9,1.2,18.8,1.1h0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M321.8,975.5v14.9c0,0.4,0,0.7,0.1,0.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="321.8" y1="972.6" x2="303.2" y2="972.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="321.8" y1="940.5" x2="321.8" y2="971.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="326.3" y1="1021.4" x2="320.2" y2="1021.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="326.3" y1="1028.1" x2="326.3" y2="1021.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="326.3" y1="1030.2" x2="326.3" y2="1028.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="320.2" y1="1028.1" x2="320.2" y2="1030.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="320.2" y1="1021.2" x2="320.2" y2="1028.1"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="320.2" y1="1030.2" x2="326.3" y2="1030.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="326.3" y1="1028.1" x2="320.2" y2="1028.1"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="345.1,1026.2 340.9,1026.2 340.9,1022 326.3,1021.4 		"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M300.3,1005.4c-0.4-2.3-0.8-4.8-1.2-7.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M300.3,1005.4c0.3,1.6,0.7,3.2,1,4.6"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="299" y1="1005.5" x2="300.3" y2="1005.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="292.2" y1="1005.7" x2="294.3" y2="1005.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M301.3,1010h-6.1c-1.6,0.2-2.6-1.2-3-4.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="291.4" y1="1002.3" x2="292.2" y2="1005.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="291.4" y1="1009.3" x2="291.4" y2="1002.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M310.3,1020.8c-0.1,0.4-0.2,0.7-0.2,1.1h-2.2l-5.2-7.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="294.3" y1="1005.7" x2="299" y2="1005.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M302.6,1014.5c-0.5-1.4-0.9-2.9-1.3-4.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="320.2" y1="1036.3" x2="320.2" y2="1039.5"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M291.4,1009.3v56c0,3,2.3,4.5,7.1,4.5h14.6c4.8-0.1,7.1-1.6,7.1-4.5
        v-25.8"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M376.5,999.2l-57.4-0.7c-10.4,0.1-16.7,2.4-18.8,6.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M310.3,1020.8c0.8-2.6,4.8-4.4,11.9-5.4h54.2"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="320.2" y1="1021.2" x2="310.3" y2="1020.8"/>
      <polyline fill="none" stroke="#000000" stroke-width="0.5" points="349.9,1034.5 326.3,1034.5 326.3,1030.2 		"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="320.2" y1="1030.2" x2="320.2" y2="1036.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M302.6,1014.5c3.2-3.3,10-5.2,20.7-5.8h53.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" d="M291.4,1002.3c-3.2-13.2-5.1-24-5.7-32.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="320.2" y1="1039.5" x2="352.8" y2="1039.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="471" y1="975.5" x2="321.8" y2="975.5"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="351" y1="1036.3" x2="320.2" y2="1036.3"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="326" y1="937.7" x2="370.9" y2="937.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="322" y1="991.4" x2="470.9" y2="991.4"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="297.8" y1="919.9" x2="297.3" y2="922.7"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="495.6" y1="922.7" x2="495.1" y2="919.9"/>
      <line fill="none" stroke="#000000" stroke-width="0.5" x1="323" y1="917.7" x2="322.3" y2="928"/>
    </g>
  </g></>)
  }
}