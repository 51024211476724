
import React from "react"

export const LightUtility4x4Svg = {
  interior: {
    width:162,
    height:398,
    svg: (<><g id="front">
    </g>
    <g id="back">
    </g>
    <g id="left">
    </g>
    <g id="top">
      <g>
        <g>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="16.5" y1="18.4" x2="16.5" y2="119.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="16.5" y1="122.4" x2="16.5" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="29.1" y1="140" x2="133.1" y2="140"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="16.5" y1="115.3" x2="145" y2="115.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="134.9" y1="140" x2="134.9" y2="140.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="17.6" y1="7.9" x2="17.6" y2="17.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="144.2" y1="7.9" x2="144.2" y2="17.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="145" y1="17.9" x2="145" y2="120.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="145" y1="122.3" x2="145" y2="365.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="144.3" y1="115.4" x2="139" y2="136.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="21.1" y1="134.3" x2="21.1" y2="363.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="140.4" y1="135.2" x2="140.5" y2="363.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="16.5" y1="115.3" x2="18.3" y2="121.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="18.6" y1="122.3" x2="22.9" y2="136.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="116.9" x2="16.5" y2="116.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="145" y1="116.9" x2="149" y2="116.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M117.7,18.1c-0.4-2-2.2-3.4-4.2-3.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="117.7" y1="18.1" x2="138.5" y2="115.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M48.2,14.7c-1.9,0-3.6,1.3-4.2,3.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="48.2" y1="14.7" x2="113.6" y2="14.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="44.1" y1="17.8" x2="22.9" y2="115.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="117.7" y1="17.7" x2="146.8" y2="17.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M149,19.8c0-1.1-0.9-2-2-2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="115.3" x2="149" y2="118.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="95" x2="149" y2="115.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="19.8" x2="149" y2="95"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="327.8" x2="149.1" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="235.8" x2="149" y2="327.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="186.2" x2="149" y2="235.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="173.9" x2="149" y2="186.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="122.3" x2="149" y2="173.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M15,17.7c-1.2,0-2.1,0.9-2.1,2.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="15" y1="17.7" x2="44.1" y2="17.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="115.3" x2="12.9" y2="118.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="94.8" x2="12.9" y2="115.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="19.8" x2="12.9" y2="94.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="13" y1="328" x2="13" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="13" y1="236" x2="13" y2="328"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="13" y1="186" x2="13" y2="236"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="174.1" x2="13" y2="186"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="122.3" x2="12.9" y2="174.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M132.5,140c3.3,0.2,6.2-1.4,6.6-3.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M132.5,140c3.3,0.2,6.2-1.4,6.6-3.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M132.3,137.7c1.8,0,3.3-1,3.3-2.1c0,0,0-0.1,0-0.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="29" y1="137.7" x2="132.3" y2="137.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M22.9,136.5c0.3,2.1,3.3,3.7,6.6,3.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M26.4,135.6c0,1.2,1.5,2.1,3.3,2.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M152.1,13.4c0-3-2.5-5.5-5.5-5.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="15.1" y1="7.8" x2="146.6" y2="7.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="152.1" y1="13.4" x2="152.1" y2="18.8"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M150.5,20.4c0.9,0,1.6-0.7,1.6-1.6l0,0"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M148.9,19c0.1,0.8,0.8,1.4,1.6,1.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M146.8,12.1c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
            c-0.8,0-1.4,0.6-1.4,1.4C145.4,11.5,146,12.1,146.8,12.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="17.6" y1="7.9" x2="17.6" y2="17.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M14.9,17.7c-1.1,0-2,0.9-2,2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M15.1,7.8c-3,0-5.5,2.5-5.5,5.5v5.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M11.3,20.3c0.8,0,1.5-0.6,1.6-1.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M9.6,18.7C9.6,18.7,9.6,18.7,9.6,18.7c0,0.9,0.7,1.6,1.6,1.6"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M15,12.1c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
            c-0.8,0-1.4,0.6-1.4,1.4C13.6,11.5,14.3,12.1,15,12.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M154.8,90.5c0.5-0.4,0.8-1.1,0.8-1.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="95.3" x2="154.8" y2="90.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M155.6,27.9c0-1.3-0.6-2.5-1.5-3.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="20.3" x2="154" y2="24.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="155.6" y1="27.9" x2="155.6" y2="88.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.1" y1="391.1" x2="53.4" y2="391.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M53.4,370.4l-2.1,1.4c-1.9,3.8-1.9,14.5,0,17.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="53.4" y1="391.1" x2="51.3" y2="389.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M108.1,370.4l2.1,1.4c1.9,3.8,1.9,14.5,0,17.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.1" y1="391.1" x2="110.2" y2="389.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="66.3" y1="370.4" x2="53.4" y2="370.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="80.7" y1="370.4" x2="66.3" y2="370.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="96.2" y1="370.4" x2="80.7" y2="370.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.1" y1="370.4" x2="96.2" y2="370.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="23.8" y1="215.3" x2="23.8" y2="278.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M27.1,215.5C27.1,215.5,27.1,215.4,27.1,215.5c0-1.9-0.7-3.3-1.6-3.3
            c-0.9,0-1.6,1.4-1.6,3.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M25.4,281.7c0.9,0,1.6-1.5,1.6-3.3c0,0,0,0,0-0.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M23.8,278.5c0,1.8,0.7,3.2,1.6,3.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="27.1" y1="215.2" x2="27.1" y2="278.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="137.9" y1="215.3" x2="137.9" y2="278.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M137.9,215.4c0-1.8-0.7-3.2-1.6-3.2c-0.9,0-1.6,1.5-1.6,3.2
            c0,0,0,0.1,0,0.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M136.3,281.7c0.9,0,1.6-1.4,1.6-3.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M134.7,278.4C134.7,278.4,134.7,278.4,134.7,278.4
            c0,1.9,0.7,3.3,1.6,3.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="134.7" y1="215.2" x2="134.7" y2="278.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M6.4,88.7c0,0.7,0.3,1.3,0.8,1.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="13.1" y1="95.3" x2="7.2" y2="90.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M8,24.6c-1,0.8-1.5,2.1-1.5,3.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="13.1" y1="20.3" x2="8" y2="24.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="6.4" y1="27.9" x2="6.4" y2="88.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M139.1,121.8c0-0.1,0-0.1,0-0.1c0-1.1-1.8-2-3.9-2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="139.1" y1="121.8" x2="135.6" y2="135.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="135.2" y1="119.7" x2="105" y2="119.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="92.8" y1="119.7" x2="51.4" y2="119.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M111.9,12.4c0-1.1-0.9-2.1-2.1-2.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="111.9" y1="12.4" x2="111.9" y2="14.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M51.3,10.4c-1.2,0-2.2,1-2.2,2.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="109.8" y1="10.4" x2="51.3" y2="10.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="49.1" y1="12.5" x2="49.1" y2="14.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="142.8" y1="16.4" x2="119.1" y2="16.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M142.8,16.4c0.8,0,0.9,0.7,0.9,1.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M119.1,16.4c-0.8,0-0.9,0.7-0.9,1.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M142.2,16.4v-0.2c0-0.5-1.3-1.7-2.6-1.7c-1.3,0-2.6,1.2-2.5,1.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="137.1" y1="16.5" x2="137.1" y2="16.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="18.9" y1="16.3" x2="42.6" y2="16.3"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M18.9,16.3C18,16.3,18,17,18,17.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M42.6,16.3c0.8,0,0.9,0.7,0.9,1.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M19.4,16.3v-0.2c0-0.5,1.3-1.7,2.6-1.7c1.3,0,2.6,1.2,2.5,1.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="24.5" y1="16.4" x2="24.5" y2="16.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="87.8" y1="118.7" x2="85.2" y2="118.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M136.8,118.8c0.4,0,0.8-0.4,0.8-0.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="136.8" y1="116.2" x2="85.2" y2="116.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="133.4" y1="119.7" x2="133.4" y2="118.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="130.9" y1="118.7" x2="130.9" y2="119.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M92.7,119.4c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M94.2,118.7c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0.3-1.2,0.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="106.9" y1="119.8" x2="106.9" y2="120.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="106.9" y1="120.5" x2="114.5" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="106.9" y1="119.9" x2="114.8" y2="120.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="93.8" y1="120.1" x2="106.9" y2="120.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="94" y1="118.7" x2="106.9" y2="119.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="128.7" y1="121" x2="116.1" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="114.6" y1="121" x2="101.2" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="128.7" y1="121.7" x2="117.5" y2="121.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="115.8" y1="121.7" x2="101.2" y2="121.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="114.6" y1="121.2" x2="114.8" y2="120.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="114.6" y1="121.2" x2="116.2" y2="121.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="114.8" y1="120.5" x2="116.3" y2="121.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="116.2" y1="121.8" x2="117.5" y2="121.8"/>
          <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="116.3,121.1 117.5,121.1 117.5,121.8 			"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="128.7" y1="121.7" x2="128.7" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="101.2" y1="121.7" x2="101.2" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="130.6" y1="118.7" x2="130.6" y2="117.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="128.1" y1="117.4" x2="128.1" y2="118.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M130.6,117.4c0-0.5-0.4-0.9-0.9-0.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M129.1,116.5c-0.6,0-1,0.4-1,1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="129.7" y1="116.5" x2="129.1" y2="116.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="133.8" y1="119.4" x2="133.4" y2="119.4"/>
          <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="130.9,119.4 127.8,119.4 127.8,118.7 			"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="133.8" y1="119.4" x2="133.8" y2="118.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="87.5" y1="119.7" x2="87.5" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="90" y1="118.8" x2="90" y2="119.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="87.2" y1="119.4" x2="87.5" y2="119.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="90" y1="119.4" x2="92.6" y2="119.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="87.2" y1="119.4" x2="87.2" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="136.8" y1="118.7" x2="87.8" y2="118.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="137.6" y1="116.9" x2="137.6" y2="118"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M137.6,117C137.6,117,137.6,117,137.6,117c0-0.5-0.4-0.8-0.8-0.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="144.9" y1="116.7" x2="140.4" y2="135"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="17.8" y1="122.4" x2="20.9" y2="133.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M84.3,117.9c0,0.4,0.4,0.8,0.8,0.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="84.3" y1="117" x2="84.3" y2="117.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M85.1,116.2c-0.4,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="92.8" y1="118.7" x2="92.8" y2="117.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="90.3" y1="117.5" x2="90.3" y2="118.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M92.8,117.4c0-0.5-0.4-0.9-0.9-0.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M91.3,116.5c-0.6,0-1,0.4-1,1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="91.9" y1="116.5" x2="91.3" y2="116.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="143.4" y1="119" x2="142.6" y2="119"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="140.1" y1="119" x2="137.6" y2="119"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M142,121.9l1.1-4.6c0-0.4-0.4-0.7-1.2-0.6c-0.7,0-1.2-0.1-1.4,0.7
            l-0.9,4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="140.2" y1="122.5" x2="141.4" y2="122.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M139.6,121.4c-0.2,0.7,0,1,0.6,1.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M142,121.9c-0.2,0.5-0.2,0.7-0.6,0.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="23.7" y1="119" x2="26.2" y2="119"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M153.7,325.5c1.2-0.4,2-1.5,2-2.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="155.7" y1="248.7" x2="155.7" y2="322.8"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M155.7,249c0-1.2-0.7-2.2-1.8-2.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="153.7" y1="325.5" x2="149.2" y2="328"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="153.9" y1="246.2" x2="151.4" y2="245.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M6.5,322.9c0,1.3,0.8,2.3,2,2.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="6.5" y1="248.9" x2="6.5" y2="322.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M8.2,246.4c-1.1,0.5-1.7,1.6-1.8,2.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="8.4" y1="325.6" x2="13" y2="328.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="8.2" y1="246.4" x2="10.5" y2="245.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.8" y1="244.3" x2="13.1" y2="244.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M21.7,365.7v0.7c0,0.5-1.3,1.8-2.7,1.7c-1.3,0-2.7-1.3-2.7-1.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="16.4" y1="366.1" x2="16.4" y2="366.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="16.4" y1="366.1" x2="16.4" y2="365.6"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M147.2,366.2v0.3c0,0.5-1.3,1.7-2.7,1.7c-1.3,0-2.7-1.3-2.6-1.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="141.8" y1="366.2" x2="141.8" y2="366.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.2" y1="366.2" x2="147.2" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="141.8" y1="366.2" x2="141.8" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="98.4" y1="365.6" x2="96.1" y2="370.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="64.2" y1="365.8" x2="66.4" y2="370.4"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="21.2" y1="363.4" x2="140.5" y2="363.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M140.4,365.7c1.1,0,2-0.9,2-2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="142.4" y1="134.3" x2="142.4" y2="363.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="98.3" y1="365.7" x2="140.5" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="64.2" y1="365.7" x2="98.3" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="21.8" y1="365.7" x2="64.2" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="16" y1="365.7" x2="21.8" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="13" y1="365.7" x2="16" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147" y1="365.7" x2="149.1" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="141.7" y1="365.7" x2="147" y2="365.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="140.5" y1="365.7" x2="141.7" y2="365.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M19.7,363.6c0,1.2,0.9,2.1,2.1,2.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="19.6" y1="134" x2="19.6" y2="363.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M142.4,134.3c0-3.1-2-3.5-2,0.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="10.5" y1="185.9" x2="12.9" y2="185.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M92.6,120.1c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0l12.5,0.1l0.6-0.2"
            />
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="92.6" y1="119.4" x2="92.6" y2="120.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="94.4" y1="118.7" x2="94.4" y2="120.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M39,119.4c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M40.5,118.7c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0.3-1.2,0.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="53.3" y1="119.8" x2="53.3" y2="120.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="53.3" y1="120.5" x2="60.9" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="53.3" y1="119.9" x2="61.2" y2="120.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.1" y1="120.1" x2="53.3" y2="120.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.3" y1="118.7" x2="53.3" y2="119.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="75" y1="121" x2="62.4" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="61" y1="121" x2="47.6" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="75" y1="121.7" x2="63.8" y2="121.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62.2" y1="121.7" x2="47.6" y2="121.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="60.9" y1="121.2" x2="61.2" y2="120.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="60.9" y1="121.2" x2="62.5" y2="121.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="61.2" y1="120.5" x2="62.7" y2="121.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62.5" y1="121.8" x2="63.8" y2="121.8"/>
          <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="62.7,121.1 63.8,121.1 63.8,121.8 			"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="75" y1="121.7" x2="75" y2="121"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="47.6" y1="121.7" x2="47.6" y2="121"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M39,120.1c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0l12.5,0.1l0.6-0.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39" y1="119.4" x2="39" y2="120.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.8" y1="118.7" x2="40.8" y2="120.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39" y1="119.7" x2="26.8" y2="119.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="28" y1="118.8" x2="25.4" y2="118.8"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M77.1,118.8c0.4,0,0.8-0.4,0.8-0.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="77.1" y1="116.2" x2="25.4" y2="116.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="73.7" y1="119.7" x2="73.7" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="71.1" y1="118.8" x2="71.1" y2="119.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70.9" y1="118.8" x2="70.9" y2="117.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="68.4" y1="117.5" x2="68.4" y2="118.8"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M70.9,117.5c0-0.5-0.4-0.9-0.9-0.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M69.4,116.6c-0.6,0-1,0.5-1,1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="70" y1="116.6" x2="69.4" y2="116.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="74" y1="119.5" x2="73.7" y2="119.5"/>
          <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="71.1,119.5 68.1,119.5 68.1,118.8 			"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="74" y1="119.5" x2="74" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="27.7" y1="119.7" x2="27.7" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="30.3" y1="118.8" x2="30.3" y2="119.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="27.4" y1="119.5" x2="27.7" y2="119.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="30.3" y1="119.5" x2="33.2" y2="119.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="33.3" y1="119.5" x2="33.3" y2="118.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="27.4" y1="119.5" x2="27.4" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="77.1" y1="118.8" x2="41.6" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39.5" y1="118.8" x2="33.7" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="33.8" y1="118.8" x2="28" y2="118.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="77.9" y1="117" x2="77.9" y2="118.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M77.9,117.1C77.9,117.1,77.9,117.1,77.9,117.1c0-0.5-0.4-0.8-0.8-0.8"
            />
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M24.5,118c0,0.4,0.4,0.8,0.8,0.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="24.5" y1="117.1" x2="24.5" y2="118"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M25.4,116.3c-0.4,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="33.1" y1="118.8" x2="33.1" y2="117.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="30.5" y1="117.6" x2="30.5" y2="118.8"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M33.1,117.5c0-0.5-0.4-0.9-0.9-0.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M31.5,116.6c-0.6,0-1,0.4-1,1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="32.1" y1="116.6" x2="31.5" y2="116.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149.2" y1="255.2" x2="142.6" y2="255.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="13.1" y1="255.2" x2="21.2" y2="255.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M19.7,99.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2
            C17.7,98.3,18.6,99.2,19.7,99.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M19.7,98.3c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1
            c-0.6,0-1.1,0.5-1.1,1.1C18.6,97.8,19.1,98.3,19.7,98.3"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M19.7,97.7c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5
            c-0.3,0-0.5,0.2-0.5,0.5C19.2,97.5,19.5,97.7,19.7,97.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="18.5" y1="122.3" x2="5.2" y2="122.3"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M18.4,121.1c0.9,0,0.9,1.2,0,1.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M5.4,122.3c-0.9,0-0.9-1.2,0-1.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="11.8" y1="115.8" x2="11.8" y2="118.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="115.4" x2="12.2" y2="115.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M12.3,115.4c-0.4,0-0.5,0.2-0.5,0.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="18.4" y1="121.1" x2="5.3" y2="121.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M18.2,121c-1.2-2.3-1.7-2.1-6.6-2.1c-5.4,0-4.9-0.1-6.2,2.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="144.1" y1="122.3" x2="157.3" y2="122.3"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M144.6,121.1c-0.9,0-0.9,1.2,0,1.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M157.2,122.3c0.9,0,0.9-1.2,0-1.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="150.7" y1="115.8" x2="150.7" y2="118.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149.6" y1="115.4" x2="150.3" y2="115.4"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M150.2,115.4c0.4,0,0.5,0.2,0.5,0.5"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="144.2" y1="121.1" x2="157.2" y2="121.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M144.4,121c1.2-2.3,1.7-2.1,6.6-2.1c5.4,0,4.9-0.1,6.2,2.2"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M26.4,135.5C26.4,135.5,26.4,135.6,26.4,135.5c0,1.2,1.5,2.2,3.3,2.2"
            />
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M26.8,119.7c-2.2,0-3.9,0.9-3.9,2c0,0.1,0,0.1,0,0.1l3.5,13.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="17.7" y1="119" x2="19.3" y2="119"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="21.8" y1="119" x2="24.3" y2="119"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M20,121.9l-1.1-4.6c0-0.4,0.4-0.7,1.2-0.6c0.7,0,1.2-0.1,1.4,0.7l0.9,4
            "/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="21.7" y1="122.5" x2="20.6" y2="122.5"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M22.3,121.4c0.2,0.7,0,1-0.6,1.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M20,121.9c0.2,0.5,0.2,0.7,0.6,0.7"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M21.1,134.3c-0.2-2.6-1.4-1.7-1.4-0.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="193.8" x2="21.1" y2="193.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="140.4" y1="193.8" x2="149" y2="193.8"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M11.6,173.9c-0.6,0-1,0.5-1,1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="173.9" x2="11.6" y2="173.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="10.5" y1="174.9" x2="10.5" y2="185.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="11.5" y1="174.8" x2="11.5" y2="182.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="174.8" x2="11.5" y2="174.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="11.5" y1="182.2" x2="12.9" y2="182.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="10.5" y1="248" x2="12.9" y2="248"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M11.6,236c-0.6,0-1,0.5-1,1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="236" x2="11.6" y2="236"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="10.5" y1="237" x2="10.5" y2="248"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="11.5" y1="236.9" x2="11.5" y2="244.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="12.9" y1="236.9" x2="11.5" y2="236.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="11.5" y1="244.3" x2="12.9" y2="244.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149.1" y1="244.3" x2="148.8" y2="244.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="151.4" y1="185.9" x2="149" y2="185.9"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M151.4,174.9c0-0.6-0.5-1-1-1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="173.9" x2="150.3" y2="173.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="151.4" y1="174.9" x2="151.4" y2="185.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="150.4" y1="174.8" x2="150.4" y2="182.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="174.8" x2="150.4" y2="174.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="150.4" y1="182.2" x2="149" y2="182.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="151.4" y1="248" x2="149" y2="248"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M151.4,237c0-0.6-0.5-1-1-1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="236" x2="150.3" y2="236"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="151.4" y1="237" x2="151.4" y2="248"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="150.4" y1="236.9" x2="150.4" y2="244.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149" y1="236.9" x2="150.4" y2="236.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="150.4" y1="244.3" x2="149" y2="244.3"/>
          <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="136.8,139.8 132.8,204.3 132.8,363.1 			"/>
          <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="25.5,139.4 29.2,204.7 29.2,363.1 			"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M41.6,159.6H68c0,0,3.9,0.7,5.4,7.5l0.2,16.8c0,0-2,9.6-7.4,12.7
              H42.5c0,0-4.9-3.7-7-12.5v-17.2C35.5,166.8,37.5,160.4,41.6,159.6z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M74.2,193.7l-0.6-1h-3.3l-2.2,2.5l-2.6,1.4l-11-0.1v0l-11.2,0.3
              l-2.4-1.9l-1.8-2.4l-3.8,0.2l-1,1.9l-0.6,4.9v14.9l0.5,3.9l1.4,2.7l1.7,1.7l2.7,1.3h3.7h1.6l0.1,1.1h9.1v0h9.1l0.1-1.1h1.6h3.7
              l2.7-1.3l1.7-1.7l1.4-2.7l0.5-3.9v-14.9l-0.5-3.9C74.7,194.9,74.5,194.3,74.2,193.7z"/>
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="54.4,213.8 43.1,213.8 43.1,223.7 45.3,226 54.4,226 
              63.6,226 65.7,223.7 65.7,213.8 				"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M94.4,159.6h26.4c0,0,3.9,0.7,5.4,7.5l0.2,16.8
              c0,0-2,9.6-7.4,12.7H95.3c0,0-4.9-3.7-7-12.5v-17.2C88.3,166.8,90.3,160.4,94.4,159.6z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M126.9,193.7l-0.6-1h-3.3l-2.2,2.5l-2.6,1.4l-11-0.1v0L96,196.7
              l-2.4-1.9l-1.8-2.4l-3.8,0.2l-1,1.9l-0.6,4.9v14.9l0.5,3.9l1.4,2.7l1.7,1.7l2.7,1.3h3.7h1.6l0.1,1.1h9.1v0h9.1l0.1-1.1h1.6h3.7
              l2.7-1.3l1.7-1.7l1.4-2.7l0.5-3.9v-14.9l-0.5-3.9C127.5,194.9,127.3,194.3,126.9,193.7z"/>
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="107.2,213.8 95.9,213.8 95.9,223.7 98,226 107.2,226 
              116.4,226 118.5,223.7 118.5,213.8 				"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M125.6,287.5H35.4c-1.1,0-1.9-0.9-1.9-1.9l-1-26.1
              c0-1.1,0.9-1.9,1.9-1.9h92.1c1.1,0,1.9,0.9,1.9,1.9l-1,26.1C127.5,286.6,126.6,287.5,125.6,287.5z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M126.5,288.5l-2-1h-6h-7h-7h-24h-1h-25h-6h-5h-8h0
              c-1.4,1.6-2.5,3.6-3.1,5.6l0,0c-0.3,0.8-0.4,1.7-0.4,2.5v8.5c0,2.4,0.4,4.8,1.1,7.1l0,0l0.6,0.4c1.7,1.3,3.6,2.3,5.7,3.1l1,0.4
              l6.2,1.2h8.6h3.7l0.3,1H80v0h20.9l0.3-1h3.7h8.6l6.2-1.2l4-1.5l3.2-2.4l1.1-3.5v-13.4l-0.5-3.9
              C127.3,289.9,127.2,289,126.5,288.5z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M80,307.3H53.9v8.9l2.1,0.9c1.9,0.8,3.8,1.2,5.9,1.2H80h17.8
              c2.3,0,4.5-0.5,6.6-1.3l1.7-0.7v-8.9H80z"/>
          </g>
        </g>
      </g>
    </g>
    <g id="right">
    </g></>)
  }, 
  exterior: {
    width:538,
    height:746,
    svg: (<><g id="front">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M207.5,30.2l0.2,2.1l-4.2,38.2l0.1,2.4l0,0.1l-8.8,0.2v-1.2v0h2.4
        c0.8,0,1.5-0.7,1.5-1.5V56.9c0-0.8-0.7-1.5-1.5-1.5h-7.7c-0.8,0-1.4,0.6-1.4,1.4v13.7c0,0.8,0.6,1.4,1.4,1.4h3.7v0v1.3
        c-0.3,0-0.6,0.3-0.6,0.6v0.6c0,0.3,0.3,0.6,0.6,0.6l6.3,0.2l-0.1,0v4.7c0,0.6,0.5,1.1,1,1.1h3.3l0.1,0.3H203
        c-1.9,0-3.5,1.5-3.5,3.4v9.6l0-0.1c-2.1,0.7-4.4,3.2-5,3.9c-0.5,0.7-1.1,1.8-1.2,3c-0.2,1.2,0,3.5,0.4,8c0.2,2.7,0.7,7.4,1.3,12.1
        l0,0.3v7.8l0,0c0,0.3,0.2,0.6,0.5,0.7l-0.1,0v33.4c-0.4,3.8-0.9,8.1,5,8l-0.2,0h11.5l0.2,0c3.3,0.1,2.5-4.3,2.2-7.7V146
        c0.9-0.4,1.7-1,2.3-1.8l0,0h1.9v-0.1v2.9h2.5h0v-2.8H232c2.9,0.2,7.4,0.5,9.2,3.7l0-0.1c2.3,2.6,5.5,4,8.7,4c4,0,7.6-2.1,9.7-5.3
        c1.3-1.5,3.6-1.8,6-1.8l0.1,0h27.7l-0.1,0l0.5,0.7l3.6,0.2l0.6-0.6l0-0.2l0,0h12.1v2.3h2.5h0v-2.3h2.1l0,0
        c0.6,0.6,1.3,1.1,2.1,1.4V146v17.6c-0.3,3.4-1.1,7.8,2.2,7.7h11.5c5.9,0.2,5.5-4.2,5-8v-33.4h0c0.2-0.2,0.3-0.4,0.3-0.7l0,0v-7.8
        c0-0.1,0-0.3-0.1-0.4l0,0c0.6-4.6,1.1-9.2,1.3-11.9c0.4-4.4,0.5-6.7,0.4-8c-0.2-1.2-0.7-2.3-1.2-3c-0.5-0.7-2.8-3.2-5-3.9l0,0
        v-9.5c0-1.9-1.5-3.4-3.5-3.4h-0.7l0-0.1l0-1l0,0h3.3c0.6,0,1-0.5,1-1v-4.7c0,0,6.2-0.2,6.2-0.2c0.3,0,0.6-0.3,0.6-0.6V73
        c0-0.3-0.3-0.6-0.6-0.6v-1.3v0h3.7c0.8,0,1.4-0.6,1.4-1.4V56.1c0-0.8-0.6-1.4-1.4-1.4h-7.7c-0.8,0-1.5,0.7-1.5,1.5v13.6
        c0,0.8,0.7,1.5,1.5,1.5h2.4l0,0v1.2l0,0l-8.8-0.2l0,0l0.1-1.7l-4.2-38.2l0.2-2.1l0,0h0.5c0-0.3,0-1-0.6-1h-0.7h0l-0.8-8.8l0,0.2
        c-0.2-3.2-2.8-5.6-6-5.6l0.5,0H215c-3.3,0-6,2.6-6.2,5.8l-0.6,8.3h-0.6c-0.6,0-0.5,0.6-0.5,1L207.5,30.2L207.5,30.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="270.7,117.1 288.3,117.1 288.3,118.2 295.8,118.2 295.8,89.2 
        235,89.2 235.1,118.2 242.2,118.2 242.2,117.1 259.8,117.1 259.8,118.2 270.7,118.2 270.7,117.1 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M326.8,80.1L326.8,80.1l3.3,0c0.6,0,1-0.5,1-1v-4.7c0,0,6.2-0.2,6.2-0.2
        c0.3,0,0.6-0.3,0.6-0.6V73c0-0.3-0.3-0.6-0.6-0.6v-1.3v0h3.7c0.8,0,1.4-0.6,1.4-1.4V56.1c0-0.8-0.6-1.4-1.4-1.4h-7.7
        c-0.8,0-1.5,0.7-1.5,1.5v13.6c0,0.8,0.7,1.5,1.5,1.5h2.4l0,0v1.2l0,0l-8.8-0.2l0,0L326.8,80.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.7,80.9L203.7,80.9l-3.3,0c-0.6,0-1-0.5-1-1v-4.7
        c0,0-6.2-0.2-6.2-0.2c-0.3,0-0.6-0.3-0.6-0.6v-0.6c0-0.3,0.3-0.6,0.6-0.6v-1.3v0h-3.7c-0.8,0-1.4-0.6-1.4-1.4V56.8
        c0-0.8,0.6-1.4,1.4-1.4h7.7c0.8,0,1.5,0.7,1.5,1.5v13.6c0,0.8-0.7,1.5-1.5,1.5h-2.4l0,0v1.2l0,0l8.8-0.2l0,0L203.7,80.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321.4,55.4l-3-24.8c-0.3-2.2-2.1-3.2-3.2-3.2h-99.2
        c-2.7,0-3.5,2.2-3.6,3.4l-3,24.1c-0.5,3.8,1.8,4.3,4.1,4.5H244l-3.3-1.6l0-0.1l-7.1-3l0,0.1l-1.2-0.6l0,0h-12.2l0,0v-0.8h26.7v0.8
        l0,0h-12.9l0,0l7,2.9l0-0.1l6.3,2.3h51.1l-3.4-1.6l0-0.1l-7.1-3l-0.1,0.1l-1.2-0.6l0,0h-12.2l0,0v-0.8h26.7v0.8l0,0h-12.9h0l7,2.9
        l0-0.1l6.4,2.3h15.5C320.5,59.4,321.5,58.2,321.4,55.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.4,129.9v33.4c0.4,3.8,0.9,8.1-5,8h-11.5c-3.3,0.1-2.5-4.3-2.2-7.7
        v-34.2l0,0h10.1l0,0c0.1,0.4,0.4,0.7,0.8,0.7h7.4l0,0c0,0,0,0,0,0C335,130.1,335.2,130,335.4,129.9L335.4,129.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M214.1,155.1v-25.6l0,0h-10.1l0,0c-0.1,0.4-0.4,0.7-0.8,0.7h-7.4l0,0
        c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.5-0.2l0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M195.3,157.2v6.2c-0.4,3.8-0.9,8.1,5,8h11.5c3.3,0.1,2.5-4.3,2.2-7.7
        v-8.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M243.8,20.3l0.3-1.7c0.1-0.7,0.8-1.3,1.5-1.3h0h39.7h-0.1
        c0.7,0,1.4,0.5,1.5,1.3l0,0l0.4,1.7l0,0.3c0,0.8-0.7,1.5-1.5,1.5h0.2h-40.5h0.1c-0.8,0-1.5-0.7-1.5-1.5
        C243.8,20.5,243.8,20.4,243.8,20.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.2,61.4l-11.9-4.3l0,0.1l-7-3h12.9l0,0v-0.8h-26.7v0.8l0,0h12.2
        l1.2,0.6l0.1-0.1l7.1,3l0,0.1l11.2,5.4l0,0c0.2,0.1,0.3,0.1,0.5,0.1c0.6,0,1.1-0.4,1.1-1C307.9,61.9,307.6,61.6,307.2,61.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M252.9,61.5l-11.9-4.3l0,0.1l-7-3h12.9h0v-0.8h-26.7v0.8l0,0h12.2
        l1.2,0.6l0.1-0.1l7.1,3l0,0.1l11.2,5.4l0,0c0.2,0.1,0.3,0.1,0.5,0.1c0.6,0,1.1-0.4,1.1-1C253.6,62,253.3,61.6,252.9,61.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.7,115.9h20.6c2.1,0,3.8-1.7,3.8-3.8l0-19.8c0-2.1-1.7-3.8-3.8-3.8
        h-20.6l0,0c-2.1,0-3.9,1.7-3.9,3.9v19.8C299.9,114.2,301.6,115.9,303.7,115.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M300.8,112.1c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.2,0.8,2,0.8h20.6
        c0.8,0,1.5-0.3,2-0.8c0.5-0.5,0.8-1.2,0.8-2V92.3c0-0.8-0.3-1.5-0.8-2c-0.5-0.5-1.2-0.8-2-0.8h-20.6c-0.8,0-1.5,0.3-2,0.8
        c-0.5,0.5-0.8,1.2-0.8,2V112.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.1,92.4c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9
        c0,1.6,1.3,2.9,2.9,2.9C325.8,95.3,327.1,94,327.1,92.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.1,106.7c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9
        c0,1.6,1.3,2.9,2.9,2.9C325.8,109.6,327.1,108.3,327.1,106.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M320.2,101.4c0-4.8-3.9-8.7-8.7-8.7c-4.8,0-8.7,3.9-8.7,8.7
        c0,4.8,3.9,8.7,8.7,8.7C316.4,110.1,320.2,106.2,320.2,101.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227,116h-20.6c-2.1,0-3.8-1.7-3.8-3.8l0-19.8c0-2.1,1.7-3.8,3.8-3.8
        H227l0,0c2.1,0,3.9,1.7,3.9,3.9v19.8C230.9,114.2,229.2,116,227,116"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M229.9,112.2c0,0.8-0.3,1.5-0.8,2c-0.5,0.5-1.2,0.8-2,0.8h-20.6
        c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-2V92.4c0-0.8,0.3-1.5,0.8-2c0.5-0.5,1.2-0.8,2-0.8H227c0.8,0,1.5,0.3,2,0.8
        c0.5,0.5,0.8,1.2,0.8,2V112.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.6,92.4c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
        c0,1.6-1.3,2.9-2.9,2.9C204.9,95.3,203.6,94,203.6,92.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.6,106.8c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
        c0,1.6-1.3,2.9-2.9,2.9C204.9,109.7,203.6,108.4,203.6,106.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M210.5,101.5c0-4.8,3.9-8.7,8.7-8.7c4.8,0,8.7,3.9,8.7,8.7
        c0,4.8-3.9,8.7-8.7,8.7C214.4,110.1,210.5,106.3,210.5,101.5"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.3,113.8 237.1,115.5 294.2,115.5 295.9,113.8 235.3,113.8 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,110.6 237.1,112.2 294.1,112.2 295.8,110.6 235.2,110.6 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,107.1 237.1,108.8 294.1,108.8 295.8,107.1 235.2,107.1 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,103.7 237.1,105.4 294.1,105.4 295.8,103.7 235.2,103.7 
            "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,100.3 237.1,102 294.1,102 295.8,100.3 235.2,100.3 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,96.9 237.1,98.6 294.1,98.6 295.8,96.9 235.2,96.9 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,93.5 237.1,95.1 294.1,95.1 295.8,93.5 235.2,93.5 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.1,90.1 236.9,91.7 294,91.7 295.7,90.1 235.1,90.1 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M297.5,134.9H288v0v-5.5l-44.6,0.1v5.5v0h-9.6v0v-5.5l-19.8,0V146
        c0.9-0.4,1.7-1,2.3-1.8l0,0h1.9v-0.1v2.9h2.5h0v-2.8H232c2.9,0.2,7.4,0.5,9.2,3.6l0-0.1c2.3,2.6,5.5,4,8.7,4c4,0,7.6-2.1,9.7-5.3
        c1.3-1.5,3.6-1.8,6-1.8l0.1,0h27.7l-0.1,0l0.5,0.7l3.6,0.2l0.6-0.6l0-0.2l0,0h12.1v2.3h2.5h0v-2.3h2.1l0,0
        c0.6,0.6,1.3,1.1,2.1,1.4v-16.6l-19.1,0V134.9L297.5,134.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319,101.4c0-4.1-3.3-7.4-7.4-7.4c-4.1,0-7.4,3.3-7.4,7.4
        c0,4.1,3.3,7.5,7.4,7.5C315.7,108.9,319,105.6,319,101.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.7,88.5c-2.1,0-3.9,1.7-3.9,3.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="303.7" y1="88.5" x2="324.3" y2="88.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M299.9,112.1c0,2.1,1.7,3.8,3.8,3.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.8" y1="92.4" x2="299.8" y2="112.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M324.3,115.9c2.1,0,3.8-1.7,3.8-3.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="303.7" y1="115.9" x2="324.3" y2="115.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="328.1" y1="112.1" x2="328.1" y2="92.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.1,92.4c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9
        c0,1.6,1.3,2.9,2.9,2.9C325.8,95.3,327.1,94,327.1,92.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.1,106.7c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9
        c0,1.6,1.3,2.9,2.9,2.9C325.8,109.6,327.1,108.3,327.1,106.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203,81.2c-1.9,0-3.5,1.5-3.5,3.4v35.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203,81.2h22.8c2.1,0,2.6,4.5,5.9,4.5c6.6,0,67.7,0,67.7,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M305.3,81.2c-2.1,0-2.6,4.5-5.9,4.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.9" y1="121.2" x2="271.3" y2="121.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M195.3,124.2c-0.8-5.5-1.4-11.8-1.7-15.1c-0.4-4.4-0.5-6.7-0.4-8
        c0.2-1.2,0.7-2.3,1.2-3c0.5-0.7,2.8-3.2,5-3.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M193.3,101.1c0.4-1.5,1.2-2.5,1.5-0.4c0.4,2.1,1.7,11.1,1.9,13.9
        c0.2,2,0.6,4.7,1.1,6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M199.5,119C199.5,119,199.5,118.9,199.5,119"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="200.2" y1="171.3" x2="211.7" y2="171.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="203.7,81.1 203.4,70.5 207.6,32.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204.4" y1="62.1" x2="205.2" y2="62.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.6" y1="62.1" x2="249.5" y2="62.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="253.5" y1="62.1" x2="263.6" y2="62.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="298.5" y1="70.5" x2="327.1" y2="70.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.4" y1="70.5" x2="232.4" y2="70.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="212.3" y1="30.9" x2="209.3" y2="55"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M212.3,30.9c0.1-1.2,0.9-3.4,3.6-3.4h99.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.3,55c-0.5,3.8,1.8,4.3,4.1,4.5H244"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="247.1" y1="59.4" x2="298.4" y2="59.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M301.7,59.4h15.5c3.4,0,4.4-1.2,4.2-4.1l-3-24.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M315.2,27.4c1.1,0,2.9,0.9,3.2,3.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M207.6,32.3l1.5-0.4l0-1.7H207c0-0.3,0-1,0.5-1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="209.4" y1="29.2" x2="207.6" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="207.5" y1="30.2" x2="207.6" y2="32.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.2,30.2c0.1-1.4,2.1-5.6,7.3-5.6h97.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M259.3,140.1c0-0.8-0.1-1.6-0.3-2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M244.7,132.3c3.5-2.3,8.1-2,11.4,0.8c0,0,0,0,0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M242,134.9c-0.9,1.3-1.4,2.8-1.5,4.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M240.5,141.1c0.5,4.8,4.6,8.4,9.4,8.4c4.5,0,8.3-3.2,9.2-7.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M258.8,132.8c-1.3-1.6-2.9-2.7-4.7-3.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M245.5,129.4c-0.8,0.3-1.5,0.7-2.1,1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M239.5,134.9c-0.1,0.2-0.2,0.5-0.3,0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M241.1,147.7c2.3,2.6,5.5,4,8.7,4c4,0,7.6-2.1,9.7-5.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M217.3,141.1c0-2.2-1.3-4.1-3.3-4.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M214.1,146c2-0.8,3.2-2.8,3.3-4.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M232.5,138.1c3-0.1,5.5,0.2,6.7-2.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M232,144.2c2.9,0.2,7.4,0.5,9.2,3.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.2" y1="93.5" x2="295.8" y2="93.5"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,93.5 237.1,95.1 294.1,95.1 295.8,93.5 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.2" y1="96.9" x2="295.8" y2="96.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,96.9 237.1,98.6 294.1,98.6 295.8,96.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.2" y1="100.3" x2="295.8" y2="100.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,100.3 237.1,102 294.1,102 295.8,100.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.2" y1="103.7" x2="295.8" y2="103.7"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,103.7 237.1,105.4 294.1,105.4 295.8,103.7 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.2" y1="110.6" x2="295.8" y2="110.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,110.6 237.1,112.2 294.1,112.2 295.8,110.6 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M262.5,65.3c0-0.9-0.7-1.6-1.6-1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="258.3" y1="63.7" x2="260.9" y2="63.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M260.9,68.8c0.9,0,1.6-0.7,1.6-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="262.5" y1="65.3" x2="262.5" y2="67.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M211.5,63.7c-0.9,0-1.7,0.8-1.7,1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.9,67.1c0,0.9,0.8,1.7,1.7,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="209.9" y1="65.4" x2="209.9" y2="67.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="211.5" y1="68.8" x2="260.9" y2="68.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="213.9" y1="62.1" x2="213.9" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="216.4" y1="63.7" x2="216.4" y2="62.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M328.1,92.3c0-2.1-1.7-3.8-3.8-3.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.9" y1="92.4" x2="299.9" y2="112.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M331,84.7c0-1.9-1.5-3.4-3.5-3.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="331" y1="84.7" x2="331" y2="120.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="305.3" y1="81.2" x2="327.5" y2="81.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.7" y1="121.2" x2="271.2" y2="121.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M229.7,73.8h-14.4c-3.6,0-6.2,4.8-6.2,7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M229.7,73.7c0.5-1.9,1.4-3.3,3.5-3.3l32.2,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.7" y1="129.4" x2="204" y2="129.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.6,121c0.6-4.6,1.1-9.2,1.3-11.9c0.4-4.4,0.5-6.7,0.4-8
        c-0.2-1.2-0.7-2.3-1.2-3c-0.5-0.7-2.8-3.2-5-3.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M337.2,101.1c-0.4-1.5-1.2-2.5-1.5-0.4c-0.4,2.1-1.7,11.1-1.9,13.9
        c-0.2,2-0.6,4.7-1.1,6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.4" y1="111.7" x2="335.4" y2="120.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.4" y1="129.9" x2="335.4" y2="163.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="316.6" y1="129.4" x2="316.6" y2="163.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="326.8,81.1 327.1,70.5 322.9,32.3 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.1" y1="62.1" x2="325.3" y2="62.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="321.9" y1="62.1" x2="307.8" y2="62.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="303.9" y1="62.1" x2="267.4" y2="62.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.9,32.3l-1.5-0.4l0-1.7l2.1,0c0-0.3,0-1-0.5-1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="321.1" y1="29.2" x2="322.9" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="323" y1="30.2" x2="322.9" y2="32.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321.3,30.2c-0.1-1.4-2.1-5.6-7.3-5.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M316.6,136.1c-2.1,0.8-3.5,2.8-3.5,5c0,2.3,1.4,4.2,3.5,5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M269.6,63.7c-0.9,0-1.6,0.7-1.6,1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M268,67.2c0,0.9,0.7,1.6,1.6,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="268" y1="65.3" x2="268" y2="67.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M320.6,65.4c0-0.9-0.8-1.7-1.7-1.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319,68.8c0.9,0,1.7-0.8,1.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="320.6" y1="65.4" x2="320.6" y2="67.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319" y1="68.8" x2="269.6" y2="68.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.4,163.3c0.4,3.8,0.9,8.1-5,8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M316.6,163.6c-0.3,3.4-1.1,7.8,2.2,7.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.5" y1="85.7" x2="233.5" y2="117.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="297" y1="85.7" x2="297" y2="121.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M265.6,144.6c-2.4,0-4.7,0.3-6,1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.3" y1="113.8" x2="295.9" y2="113.8"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.3,113.8 237.2,115.4 294.2,115.4 295.9,113.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M198.5,56.9c0-0.8-0.7-1.5-1.5-1.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197" y1="55.4" x2="189.4" y2="55.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M197.1,72c0.8,0,1.5-0.7,1.5-1.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="189.4" y1="72" x2="197.1" y2="72"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198.5" y1="56.9" x2="198.5" y2="70.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="193.1" y1="73.2" x2="203.4" y2="72.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="193.2" y1="75" x2="203.5" y2="75.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M189.4,71.1h7.6c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M197,71.1h-7.6c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M197.1,56.3h-7.8c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M188.7,56.8v12.9c0,0.2,0,0.5,0,0.6c0,0.2,0,0.3,0,0.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M197.7,56.9c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.6" y1="56.9" x2="197.6" y2="70.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M199.4,79.8c0,0.6,0.5,1.1,1,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="199.4" y1="75.2" x2="199.4" y2="79.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="200.4" y1="80.9" x2="203.7" y2="80.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M333.5,54.7c-0.8,0-1.5,0.7-1.5,1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M342.5,56.1c0-0.8-0.6-1.4-1.4-1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="333.5" y1="54.7" x2="341.1" y2="54.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M341.1,71.2c0.8,0,1.4-0.6,1.4-1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="342.5" y1="56.1" x2="342.5" y2="69.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M332,69.8c0,0.8,0.7,1.5,1.5,1.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="341.1" y1="71.2" x2="333.5" y2="71.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332" y1="56.2" x2="332" y2="69.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M338,73c0-0.3-0.3-0.6-0.6-0.6l-10.3-0.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M337.4,74.2c0.3,0,0.6-0.3,0.6-0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338" y1="73" x2="338" y2="73.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="337.4" y1="74.2" x2="327" y2="74.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="337.4" y1="72.5" x2="337.4" y2="71.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.9" y1="72.4" x2="335.9" y2="71.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M341.1,70.4h-7.6c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M341.7,56.1v13.7c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M333.5,55.5h7.7c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M332.9,56.2c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332.9" y1="56.2" x2="332.9" y2="69.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M330.1,80.2c0.6,0,1-0.5,1-1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="331.1" y1="74.4" x2="331.1" y2="79.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.1" y1="80.2" x2="326.8" y2="80.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205.7,58.9c-0.3,1.2-0.8,6.3-0.8,7.5c0,0.9,0.9,0.8,1.6,0.8
        c1.1,0,1.7-0.1,1.8-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205.7,58.9c0.1-0.6,0.8-0.7,1.4-0.7c1,0,1.9,0,1.7,0.8l-0.5,6.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M324.9,58.8c0.3,1.2,0.8,6.3,0.8,7.5c0,0.9-0.9,0.8-1.6,0.8
        c-1.1,0-1.7-0.1-1.8-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M324.9,58.8c-0.1-0.6-0.8-0.7-1.4-0.7c-1,0-1.9,0-1.7,0.8l0.5,6.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264,63.7c0,0.3,0.3,0.6,0.6,0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="263.6" y1="62.1" x2="264" y2="63.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M266.5,64.3c0.3,0,0.6-0.3,0.6-0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.6" y1="64.3" x2="266.5" y2="64.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="267.1" y1="63.7" x2="267.4" y2="62.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M264.3,61.4c-0.4,0-0.7,0.3-0.7,0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M267.4,62.1c0-0.4-0.3-0.7-0.7-0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="264.3" y1="61.4" x2="266.7" y2="61.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M253.6,62.4c0-0.5-0.5-1-1.1-1c-0.6,0-1.1,0.4-1.1,1c0,0.5,0.5,1,1.1,1
        C253.1,63.4,253.6,62.9,253.6,62.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="240.7" y1="57.9" x2="240.9" y2="57.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="240.9" y1="57.2" x2="233.8" y2="54.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="240.7" y1="57.8" x2="233.6" y2="54.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="252.9" y1="61.5" x2="240.9" y2="57.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="251.9" y1="63.2" x2="240.7" y2="57.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.1" y1="54.3" x2="232.3" y2="54.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.9" y1="54.3" x2="246.8" y2="54.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.1" y1="53.5" x2="246.8" y2="53.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.8" y1="54.2" x2="233.5" y2="54.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.8" y1="54.2" x2="232.2" y2="53.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.5" y1="54.9" x2="232.1" y2="54.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="232.2" y1="53.5" x2="231" y2="53.5"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="232.1,54.2 231,54.2 231,53.5 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.1" y1="53.5" x2="220.1" y2="54.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="246.8" y1="53.5" x2="246.8" y2="54.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.9,62.3c0-0.5-0.5-1-1.1-1c-0.6,0-1.1,0.4-1.1,1s0.5,1,1.1,1
        C307.4,63.3,307.9,62.9,307.9,62.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295" y1="57.8" x2="295.2" y2="57.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295.2" y1="57.2" x2="288.1" y2="54.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295" y1="57.7" x2="287.9" y2="54.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="307.2" y1="61.4" x2="295.2" y2="57.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.2" y1="63.2" x2="295" y2="57.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.4" y1="54.2" x2="286.6" y2="54.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288.2" y1="54.2" x2="301.1" y2="54.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.4" y1="53.4" x2="301.1" y2="53.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288.1" y1="54.1" x2="287.8" y2="54.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288.1" y1="54.1" x2="286.5" y2="53.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="287.8" y1="54.8" x2="286.4" y2="54.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="286.5" y1="53.5" x2="285.2" y2="53.5"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="286.4,54.1 285.3,54.1 285.3,53.5 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.4" y1="53.4" x2="274.4" y2="54.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="301.1" y1="53.4" x2="301.1" y2="54.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="321.8" y1="98.1" x2="326.9" y2="98.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.8" y1="97" x2="325.9" y2="97"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.9,97c-0.6,0-1,0.5-1,1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M326.9,98.1c0-0.6-0.5-1.1-1-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="321.8" y1="100" x2="326.9" y2="100"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.8" y1="98.8" x2="325.9" y2="98.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.9,98.8c-0.6,0-1,0.5-1,1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M326.9,100c0-0.6-0.5-1.1-1-1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="321.8" y1="102" x2="326.9" y2="102"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.8" y1="100.9" x2="325.9" y2="100.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.9,100.9c-0.6,0-1,0.5-1,1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M326.9,102c0-0.6-0.5-1.1-1-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M320.2,101.4c0-4.8-3.9-8.7-8.7-8.7c-4.8,0-8.7,3.9-8.7,8.7
        c0,4.8,3.9,8.7,8.7,8.7C316.4,110.1,320.2,106.2,320.2,101.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.8" y1="92.4" x2="300.8" y2="112.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.1,92.3c0-0.8-0.3-1.5-0.8-2c-0.5-0.5-1.2-0.8-2-0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.1" y1="112.1" x2="327.1" y2="92.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.7,115h20.6c0.8,0,1.5-0.3,2-0.8c0.5-0.5,0.8-1.2,0.8-2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M300.8,112.1c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.2,0.8,2,0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M303.7,89.5c-0.8,0-1.5,0.3-2,0.8c-0.5,0.5-0.8,1.2-0.8,2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="303.7" y1="89.5" x2="324.3" y2="89.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.1" y1="118.2" x2="242.2" y2="118.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.8" y1="118.2" x2="270.7" y2="118.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288.3" y1="118.2" x2="295.8" y2="118.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235" y1="85.9" x2="235" y2="121.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="295.9" y1="85.9" x2="295.9" y2="121.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M226.6,101.4c0-4.1-3.3-7.4-7.4-7.4c-4.1,0-7.4,3.3-7.4,7.4
        c0,4.1,3.3,7.5,7.4,7.5C223.2,108.9,226.6,105.6,226.6,101.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M230.9,92.4c0-2.1-1.7-3.9-3.9-3.9h-20.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227,116c2.1,0,3.8-1.7,3.8-3.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="230.9" y1="92.4" x2="230.9" y2="112.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M202.6,112.1c0,2.1,1.7,3.8,3.8,3.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="227" y1="116" x2="206.4" y2="116"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.6" y1="112.1" x2="202.6" y2="92.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.4,92.4c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9
        c0,1.6,1.3,2.9,2.9,2.9C208.1,95.3,209.4,94,209.4,92.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.4,106.7c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9
        c0,1.6,1.3,2.9,2.9,2.9C208.1,109.6,209.4,108.3,209.4,106.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M206.4,88.5c-2.1,0-3.8,1.7-3.8,3.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="230.9" y1="92.4" x2="230.9" y2="112.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.9" y1="98.1" x2="203.8" y2="98.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="207.9" y1="97" x2="204.8" y2="97"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M208.9,98.1c0-0.6-0.5-1.1-1-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.8,97c-0.6,0-1,0.5-1,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.9" y1="100" x2="203.8" y2="100"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="207.9" y1="98.8" x2="204.8" y2="98.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M208.9,100c0-0.6-0.5-1.1-1-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.8,98.8c-0.6,0-1,0.5-1,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.9" y1="102" x2="203.8" y2="102"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="207.9" y1="100.9" x2="204.8" y2="100.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M208.9,102c0-0.6-0.5-1.1-1-1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.8,100.9c-0.6,0-1,0.5-1,1.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227.8,101.4c0-4.8-3.9-8.7-8.7-8.7c-4.8,0-8.7,3.9-8.7,8.7
        c0,4.8,3.9,8.7,8.7,8.7C223.9,110.1,227.8,106.2,227.8,101.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="229.9" y1="92.4" x2="229.9" y2="112.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.6,92.3c0-0.8,0.3-1.5,0.8-2c0.5-0.5,1.2-0.8,2-0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.6" y1="112.1" x2="203.6" y2="92.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227,115h-20.6c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.8-1.2-0.8-2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M229.9,112.1c0,0.8-0.3,1.5-0.8,2c-0.5,0.5-1.2,0.8-2,0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227,89.5c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.8,1.2,0.8,2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="227" y1="89.5" x2="206.4" y2="89.5"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.1,90.1 236.9,91.7 294,91.7 295.7,90.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.1" y1="90.1" x2="295.7" y2="90.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="90.1" x2="248.7" y2="91.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="91.7" x2="248.7" y2="93"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="93.5" x2="248.7" y2="94.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="95.1" x2="248.7" y2="96.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="96.9" x2="248.7" y2="98.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="98.6" x2="248.7" y2="99.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="100.4" x2="248.7" y2="101.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="102" x2="248.7" y2="103.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="103.8" x2="248.7" y2="105"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="105.4" x2="248.7" y2="106.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="107.2" x2="248.7" y2="108.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="108.8" x2="248.7" y2="110"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="110.6" x2="248.7" y2="111.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="112.2" x2="248.7" y2="113.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="113.9" x2="248.7" y2="115.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="90.1" x2="259.3" y2="91.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="91.7" x2="259.3" y2="92.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="93.5" x2="259.3" y2="94.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="95.1" x2="259.3" y2="96.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="96.9" x2="259.3" y2="98.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="98.6" x2="259.3" y2="99.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="100.4" x2="259.3" y2="101.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="102" x2="259.3" y2="103.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="103.8" x2="259.3" y2="105"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="105.4" x2="259.3" y2="106.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.3" y1="107.2" x2="259.3" y2="108.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.4" y1="108.8" x2="259.4" y2="110"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.4" y1="110.6" x2="259.4" y2="111.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.4" y1="112.2" x2="259.4" y2="113.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.4" y1="113.9" x2="259.4" y2="115.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="90.1" x2="271.2" y2="91.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="91.7" x2="271.2" y2="92.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="93.5" x2="271.2" y2="94.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="95.1" x2="271.2" y2="96.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="96.9" x2="271.2" y2="98.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="98.6" x2="271.2" y2="99.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="100.4" x2="271.2" y2="101.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="102" x2="271.2" y2="103.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="103.8" x2="271.2" y2="105"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="105.4" x2="271.2" y2="106.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="107.2" x2="271.2" y2="108.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="108.8" x2="271.2" y2="110"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="110.6" x2="271.2" y2="111.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="112.2" x2="271.2" y2="113.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.2" y1="113.9" x2="271.2" y2="115.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="90.1" x2="282.4" y2="91.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="91.7" x2="282.4" y2="92.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="93.5" x2="282.4" y2="94.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="95.1" x2="282.4" y2="96.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="96.9" x2="282.4" y2="98.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="98.6" x2="282.4" y2="99.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="100.4" x2="282.4" y2="101.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="102" x2="282.4" y2="103.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="103.8" x2="282.4" y2="105"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="105.4" x2="282.4" y2="106.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="109.1" x2="282.4" y2="110"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="107" x2="282.4" y2="107.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="110.6" x2="282.4" y2="111.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="112.2" x2="282.4" y2="113.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="113.9" x2="282.4" y2="115.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235" y1="89.2" x2="295.8" y2="89.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="248.7" y1="89.2" x2="248.7" y2="85.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="282.4" y1="89.2" x2="282.4" y2="85.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.8" y1="117.2" x2="242.2" y2="117.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="259.8,120.8 242.2,120.8 242.2,117.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259.8" y1="120.8" x2="259.8" y2="117.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288.3" y1="117.2" x2="270.7" y2="117.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="288.3,120.8 270.7,120.8 270.7,117.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288.3" y1="120.8" x2="288.3" y2="117.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="256.9,86.5 273.9,86.5 273.9,88.9 256.9,88.9 256.9,86.5 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="256.9,86.5 273.9,86.5 273.9,88.9 256.9,88.9 256.9,86.5 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.4,111.7c0-1-0.7-1.6-1.1-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.3" y1="171.3" x2="318.9" y2="171.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="214.1" y1="129.4" x2="214.1" y2="163.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M195.3,163.3c-0.4,3.8-0.9,8.1,5,8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M214.1,163.6c0.3,3.4,1.1,7.8-2.2,7.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M195.3,111.7c0-0.8,0.5-1.4,0.9-1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="314.1" y1="138.1" x2="312.5" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="310.1" y1="138.1" x2="309.7" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="234.6" y1="138.1" x2="221.6" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="285" y1="107.1" x2="295.8" y2="107.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="235.2" y1="107.1" x2="279.2" y2="107.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.7" y1="137.5" x2="218.5" y2="140.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="221.6" y1="137.7" x2="221.4" y2="140.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="221.6" y1="137.7" x2="218.7" y2="137.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.5" y1="140.3" x2="221.4" y2="140.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.4" y1="140.7" x2="218.2" y2="143.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="221.3" y1="140.9" x2="221.1" y2="143.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="221.3" y1="140.9" x2="218.4" y2="140.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.2" y1="143.5" x2="221.1" y2="143.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.6" y1="143.5" x2="218.5" y2="145.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.7" y1="143.6" x2="220.5" y2="145.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.5" y1="145.4" x2="220.5" y2="145.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="221.4" y1="140.1" x2="287.8" y2="143.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="221.5" y1="138.2" x2="289.1" y2="141.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M287.8,143.6c0.7,0,2.7-0.3,3.5-1.1c1.2-1.2,0.8-1.3,2.1-1.1l-0.2,3.2"
        />
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="298.2" y1="141.7" x2="297.9" y2="144.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M293.5,141.5l4.7,0.3c1,0.1,1-2,0.2-2.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="293.3,144.7 293.7,145.3 297.3,145.6 297.9,144.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293" y1="139.3" x2="298.3" y2="139.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M293,139.3c0.1-1.8-2.5-1.9-2.6-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M292.6,139.2c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9
        c0,0.5,0.4,0.9,0.9,0.9C292.2,140.1,292.6,139.7,292.6,139.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="297.5" y1="129.4" x2="297.5" y2="134.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="297.5" y1="134.9" x2="288" y2="134.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288" y1="134.9" x2="288" y2="129.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="243.4" y1="129.5" x2="243.4" y2="134.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="243.4" y1="134.9" x2="233.8" y2="134.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.8" y1="134.9" x2="233.8" y2="129.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="288.3" y1="137.2" x2="243.3" y2="132.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="287.7" y1="141.1" x2="242.5" y2="136"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="287.7" y1="141.1" x2="288.3" y2="137.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="242.6" y1="136" x2="242.8" y2="134.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="288.3,137.6 290.7,138 290.2,140.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="287.8" y1="140.5" x2="290.2" y2="140.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M289.1,141.7c0.6-0.1,0.7-0.3,1-0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M306.2,139.9c0,1,0.8,1.7,1.7,1.7c1,0,1.7-0.8,1.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="309.7" y1="139.9" x2="309.6" y2="137"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="306.2,139.9 306.2,137 309.6,137 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M309,139.9c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1
        c0,0.6,0.5,1.1,1.1,1.1C308.5,141,309,140.5,309,139.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.2" y1="137.5" x2="288.3" y2="137.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.2" y1="139.9" x2="298.6" y2="139.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="310.1,135.9 310.1,141.4 310.1,147 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="312.5,135.9 312.5,141.4 312.5,147 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="314.6" y1="144.7" x2="312.5" y2="144.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="310.1" y1="144.7" x2="265.7" y2="144.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.6" y1="135.9" x2="310.1" y2="135.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.6" y1="147" x2="310.1" y2="147"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.2" y1="135.9" x2="218.2" y2="141.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.6" y1="135.9" x2="220.6" y2="137.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.6" y1="144.2" x2="220.6" y2="147"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.7" y1="135.9" x2="218.2" y2="135.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.7" y1="147" x2="218.2" y2="147"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.2" y1="141.4" x2="218.2" y2="147"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="216.3" y1="144.2" x2="218.2" y2="144.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.6" y1="144.2" x2="232" y2="144.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="216.4" y1="138.1" x2="218.2" y2="138.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.8" y1="140.3" x2="218.8" y2="140.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="220.9" y1="140.5" x2="220.9" y2="140.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.6" y1="120.6" x2="202.6" y2="81.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="328.1" y1="120.5" x2="328.1" y2="81.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.6,120.6c-0.5,0-0.9,0.4-0.9,0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M326.7,129.3c0,0.4,0.4,0.8,0.8,0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.7" y1="121.5" x2="326.7" y2="129.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.5" y1="130.1" x2="334.9" y2="130.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.7,121.4c0-0.5-0.4-0.8-0.9-0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.6" y1="120.6" x2="334.8" y2="120.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.7" y1="121.4" x2="335.7" y2="129.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M334.8,130.1C334.8,130.1,334.8,130.1,334.8,130.1
        c0.5,0,0.9-0.4,0.9-0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204,121.5c0-0.5-0.4-0.9-0.9-0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.1,130.1c0.4,0,0.8-0.4,0.8-0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204" y1="121.5" x2="204" y2="129.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.1" y1="130.1" x2="195.8" y2="130.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M195.8,120.6c-0.5,0-0.9,0.4-0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203" y1="120.6" x2="195.8" y2="120.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M194.9,129.3c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="235.2,107.2 237.1,108.8 280.3,108.8 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="284,108.8 294.1,108.8 295.8,107.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="216.7" y1="63.7" x2="216.7" y2="66.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="219.1" y1="66.3" x2="219.1" y2="63.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M216.7,66.4c0,0.5,0.4,0.9,0.9,0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M218.1,67.3c0.5,0,1-0.4,1-1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="217.5" y1="67.3" x2="218.1" y2="67.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="213.6" y1="63.1" x2="213.9" y2="63.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="216.4,63.1 219.4,63.1 219.4,63.7 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="213.6" y1="63.1" x2="213.6" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="258.6" y1="62.1" x2="258.6" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="256.1" y1="63.7" x2="256.1" y2="62.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="255.9" y1="63.7" x2="255.9" y2="66.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="253.4" y1="66.3" x2="253.4" y2="63.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M255,67.3c0.5,0,0.9-0.4,0.9-0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M253.4,66.3c0,0.5,0.4,1,1,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="255" y1="67.3" x2="254.4" y2="67.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="258.9" y1="63" x2="258.6" y2="63"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="256.1,63 253.2,63 253.2,63.7 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="258.9" y1="63" x2="258.9" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="211.5" y1="63.7" x2="258.3" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="272.2" y1="62.1" x2="272.2" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.6" y1="63.7" x2="274.6" y2="62.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="274.9" y1="63.7" x2="274.9" y2="66.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277.4" y1="66.3" x2="277.4" y2="63.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M274.9,66.4c0,0.5,0.4,0.9,0.9,0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M276.4,67.3c0.5,0,1-0.4,1-1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.8" y1="67.3" x2="276.4" y2="67.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.9" y1="63" x2="272.2" y2="63"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="274.6,63 277.6,63 277.6,63.7 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="271.9" y1="63" x2="271.9" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317" y1="62.1" x2="317" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="314.6" y1="63.7" x2="314.6" y2="62"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="314.3" y1="63.7" x2="314.3" y2="66.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="311.8" y1="66.3" x2="311.8" y2="63.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M313.4,67.2c0.5,0,0.9-0.4,0.9-0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M311.8,66.3c0,0.5,0.4,1,1,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="313.4" y1="67.2" x2="312.8" y2="67.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.3" y1="63" x2="317" y2="63"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="314.6,63 311.6,63 311.6,63.7 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="317.3" y1="63" x2="317.3" y2="63.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="318.9" y1="63.7" x2="269.6" y2="63.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321.5,20.7c-0.2-3.2-2.8-5.6-6-5.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.2" y1="29.4" x2="321.5" y2="20.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M215,15.1c-3.3,0-6,2.6-6.2,5.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.2" y1="29.2" x2="208.8" y2="20.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="215.1" y1="15.1" x2="316" y2="15.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="211.7" y1="26" x2="208.8" y2="20.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="318.7" y1="26" x2="321.5" y2="20.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="216.5" y1="24.6" x2="216.5" y2="15.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="314" y1="24.6" x2="314" y2="15.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M243.8,20.3l0.3-1.7c0.1-0.7,0.8-1.3,1.5-1.3h0h39.7h-0.1
        c0.7,0,1.4,0.5,1.5,1.3l0,0l0.4,1.7l0,0.3c0,0.8-0.7,1.5-1.5,1.5h0.1h-40.5h0.1c-0.8,0-1.5-0.7-1.5-1.5
        C243.8,20.5,243.8,20.4,243.8,20.3z"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="256.7" y1="133.6" x2="256.1" y2="133.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="258.8" y1="132.8" x2="259.8" y2="133.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M265.4,78.1L232.9,78c-1,0-2.3-0.4-2.5-1c-0.2-0.6-0.8-3-0.8-3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M232.1,71.2c0,0,0.3,1.8,0.3,2.7c0.1,0.9-0.1,2.2-0.2,3.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M265.4,80.4h-30.5c-2,0-3.4,0.2-5.7-0.8c-2.3-1-12.4-4.9-12.4-4.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M301.2,73.8h14.4c3.6,0,6.2,4.8,6.2,7.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M301.2,73.7c-0.5-1.9-1.4-3.3-3.5-3.3l-32.2,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M265.4,78.1L298,78c1,0,2.3-0.4,2.5-1c0.2-0.6,0.8-3,0.8-3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M298.8,71.2c0,0-0.3,1.8-0.3,2.7c-0.1,0.9,0.1,2.2,0.2,3.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M265.4,80.4H296c2,0,3.4,0.2,5.7-0.8c2.3-1,12.4-4.9,12.4-4.9"/>
    </g>
  </g>
  <g id="back">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M229.4,695.9l-0.2-38l0-2.4l0-6l0.2,0h76.2l0.7,0l0,6l0,2.3l-0.2,37.9
        l-1.7,0v10.6l-3.6,0.2v-6.6l0-3.9c-19.3,0.8-47,0.6-66.1,0.1l0,3.5v6.6l-3.6-0.2v-10.1L229.4,695.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,658c-0.5-0.3-0.7-0.3-1.6,0.1c-1,0.5-1.4,0.7-2.2,1
        c-0.7,0.3-1.9,0.1-1.8-1.3v-2.5c-0.1-1.3,1-1.5,1.8-1.2c0.7,0.3,1.2,0.5,2.2,1c1,0.5,1.2,0.4,1.6,0.1c0.3-0.4,0.8-0.6,1.3-0.6
        c1,0,1.9,0.9,1.9,1.9c0,1.1-0.8,1.9-1.9,1.9C307.8,658.6,307.3,658.4,307,658"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.2,655.6v2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2
        c0-0.3-0.2-0.5-0.5-0.5C302.4,655.1,302.2,655.3,302.2,655.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="229.2" y1="649.5" x2="229.2" y2="655.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M304.7,695.8c-0.1,0-0.2,0-0.3,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M300.8,696c-19.3,0.8-47,0.6-66.1,0.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="205.1" y1="703.1" x2="227.5" y2="711"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="229.2,657.9 229.4,695.9 231.1,695.9 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M308.8,710.9c-23.4,1.2-62.6,0.9-81.2,0.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="205" y1="698.9" x2="231.1" y2="698.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="234.7" y1="698.9" x2="300.8" y2="699.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="304.4" y1="699.1" x2="330.5" y2="699.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.4" y1="703.5" x2="308.8" y2="710.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.3" y1="649.5" x2="306.3" y2="655.5"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="306.3,657.8 306.1,695.8 304.4,695.8 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M312,661.5c-0.1-0.5-0.7-1.1-2.5-1.1c-2,0-2.7,0.6-2.7,1.3
        c0,0.8,1.1,1.5,2.7,1.5c1.5,0,2.4-0.7,2.6-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M312,661.6c0-0.5-0.6-1.3-2.6-1.3c-2,0-2.7,0.6-2.7,1.3
        c0,0.8,1.1,1.5,2.7,1.5C311.1,663.1,312,662.2,312,661.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M311.5,660.9c-0.4-0.2-0.8,0-1,0.4c-0.2,0.4,0,0.8,0.3,1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.4,662.4c0.4,0.2,0.8,0,1-0.4c0.2-0.4,0-0.9-0.3-1
        c-0.4-0.2-0.8,0-1,0.4C306.9,661.8,307,662.2,307.4,662.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M310.5,660.4c-0.4-0.1-0.6,0.2-0.7,0.5c-0.1,0.4-0.1,1,0,1.4
        c0.1,0.4,0.2,0.7,0.7,0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M308.3,660.4c0.4-0.1,0.6,0.2,0.7,0.5c0.1,0.4,0.1,0.9,0,1.3
        c-0.1,0.4-0.1,0.8-0.6,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.7" y1="707.3" x2="290" y2="707.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M284.6,709.3c-2.4,0-3.4-2.5-3.4-4.1c0-1.2,0.6-3.7,3.6-3.7
        c1.1,0,1.1,0,2.2,0c3,0,3.6,2.5,3.6,3.7c0,1.6-1,4.1-3.4,4.1C284.7,709.3,287,709.3,284.6,709.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="284" y1="705.7" x2="287.2" y2="705.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="284" y1="703.3" x2="287.2" y2="703.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="284" y1="703.3" x2="284" y2="705.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="287.2" y1="703.3" x2="287.2" y2="705.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M308.5,708.1c0-0.7-0.5-1.2-1.1-1.2c-0.6,0-1.1,0.5-1.1,1.2
        c0,0.7,0.5,1.2,1.1,1.2C308,709.2,308.5,708.7,308.5,708.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M309.3,708.1c0-1.1-0.8-2-1.9-2c-1,0-1.9,0.9-1.9,2c0,1.1,0.8,2,1.9,2
        C308.4,710,309.3,709.1,309.3,708.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.9,709.2c0-0.6-0.5-1.2-1.1-1.2c-0.6,0-1.1,0.5-1.1,1.2
        c0,0.7,0.5,1.2,1.1,1.2C279.4,710.4,279.9,709.9,279.9,709.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M258.1,709.2c0-0.6-0.5-1.2-1.1-1.2c-0.6,0-1.1,0.5-1.1,1.2
        c0,0.7,0.5,1.2,1.1,1.2C257.5,710.4,258.1,709.9,258.1,709.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M229.3,708.1c0-0.7-0.5-1.2-1.1-1.2c-0.6,0-1.1,0.5-1.1,1.2
        c0,0.7,0.5,1.2,1.1,1.2C228.8,709.2,229.3,708.7,229.3,708.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M230,708.1c0-1.1-0.8-2-1.9-2c-1,0-1.9,0.9-1.9,2c0,1.1,0.8,2,1.9,2
        C229.2,710,230,709.1,230,708.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.8" y1="699.9" x2="304.4" y2="699.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.8" y1="699.9" x2="300.8" y2="706.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="304.4" y1="699.8" x2="304.4" y2="706.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.8" y1="706.6" x2="304.4" y2="706.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M310.1,656.7c0-1.1-0.8-1.9-1.9-1.9c-0.5,0-1,0.2-1.3,0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,658c0.4,0.4,0.8,0.6,1.3,0.6c1,0,1.9-0.9,1.9-1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M309.2,656.7c0-0.5-0.4-1-0.9-1s-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        S309.2,657.2,309.2,656.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,655.3c-0.5,0.3-0.7,0.3-1.6-0.1c-1-0.5-1.4-0.7-2.2-1
        c-0.7-0.3-1.9-0.1-1.8,1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,658c-0.5-0.3-0.7-0.3-1.6,0.1c-1,0.5-1.4,0.7-2.2,1
        c-0.7,0.3-1.9,0.1-1.8-1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="301.4" y1="655.4" x2="301.4" y2="657.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="234.7" y1="699.6" x2="231.1" y2="699.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="234.7" y1="699.6" x2="234.7" y2="706.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="231.1" y1="699.4" x2="231.1" y2="706.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="234.7" y1="706.2" x2="231.1" y2="706.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M295.4,697.5c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C294.9,698.5,295.4,698.1,295.4,697.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M292.1,697.6c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C291.6,698.6,292.1,698.2,292.1,697.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.3,697.7c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1s0.4,1,0.9,1
        C269.8,698.7,270.3,698.2,270.3,697.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M267.2,697.7c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1s0.4,1,0.9,1
        C266.7,698.7,267.2,698.2,267.2,697.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M245.3,697.6c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C244.9,698.5,245.3,698.1,245.3,697.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M242.4,697.6c0-0.5-0.4-1-0.9-1c-0.5,0-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        C242,698.5,242.4,698.1,242.4,697.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205.3,691.8c0.6-0.6,0.7-1.5,0.1-2.2c-0.6-0.7-1.5-0.8-2.1-0.2
        c-0.6,0.5-0.7,1.5-0.1,2.2C203.7,692.2,204.7,692.4,205.3,691.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M206.1,692.8c1.1-1,1.2-2.8,0.2-4c-1-1.2-2.7-1.4-3.8-0.4
        c-1.1,1-1.2,2.8-0.2,4C203.3,693.6,205,693.8,206.1,692.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.8,712.9c0.7-1.1,0.3-2.7-0.8-3.5c-1.2-0.8-2.7-0.5-3.4,0.7
        c-0.7,1.1-0.3,2.7,0.8,3.5C207.6,714.4,209.1,714.1,209.8,712.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M210.5,713.4c0.9-1.5,0.4-3.6-1.1-4.7c-1.6-1-3.6-0.6-4.5,0.9
        c-0.9,1.5-0.4,3.6,1.1,4.7C207.5,715.3,209.5,714.9,210.5,713.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M207.1,708.2c1.1-1.2,3.3-2.6,4.1-2.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M219.2,708.1c-2.8,0.9-6.9,1.9-8.2,3.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M206.4,703.7c-1.1,1.9-2,4.2-3.2,5c-1.2,0.9-2.6,0.3-2.4,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="205" y1="698.9" x2="205.1" y2="703.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201" y1="702.8" x2="200.3" y2="664.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M215.3,694.3c1.8-1.2,2.3-3.9,1-5.9c-1.3-2-3.8-2.7-5.6-1.4
        c-1.8,1.2-2.3,3.9-1,5.9C211,695,213.5,695.6,215.3,694.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M216.2,693.6c1.8-1.2,2.2-3.8,1-5.8c-1.2-2-3.7-2.6-5.5-1.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205,665.5c1.4-0.6,2-2.4,1.3-3.9c-0.6-1.5-2.3-2.3-3.7-1.6
        c-1.4,0.6-2,2.4-1.3,3.9C201.9,665.4,203.6,666.1,205,665.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205.8,665.1c1.4-0.6,2-2.4,1.3-3.9c-0.6-1.5-2.3-2.3-3.7-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M200.3,664.2c-0.1-2.4,0.4-5.2,3.2-6.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.6" y1="658" x2="203.6" y2="653.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.6" y1="658.2" x2="203.6" y2="649.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M206.8,692.2c1.1-1,1.2-2.7,0.2-3.9c-1-1.2-2.7-1.4-3.8-0.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.3" y1="692.6" x2="206.8" y2="692.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.2" y1="692.7" x2="206.9" y2="692.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.2" y1="687.8" x2="202.7" y2="688.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.6" y1="660" x2="203.5" y2="659.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204.9" y1="665.5" x2="205.8" y2="665.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M208.7,690.2c0-2.1-1.6-3.9-3.6-4.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205,686c-1-0.2-1.3-0.7-1.3-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205.1,686.1c-1.2-0.2-1.5-0.7-1.5-1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.6" y1="684.1" x2="203.4" y2="667.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.6" y1="684.2" x2="203.4" y2="667.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201" y1="702.8" x2="204.1" y2="702.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.7" y1="690.2" x2="208.8" y2="698.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M218.8,660.9c-1.5-0.5-2.6-0.8-4.4-0.8c-1.7,0-5.2,1-5.5,2.4
        c-0.3,1.2-0.4,3.4,1.4,3.4c1.8,0,5.1,0,6.3-0.1c1.3-0.1,1.4,0,2.3-0.7c0.9-0.7,1.1-1,1.1-1.9C220.1,662,219.9,661.4,218.8,660.9"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M217.5,661.9c-1.2-0.4-2.3-0.6-3.7-0.5c-1.3,0-4.4,0.4-4.7,1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M213.2,664.1c0.2-0.1,0.3-0.4,0.2-0.7c-0.1-0.3-0.4-0.4-0.6-0.3
        c-0.2,0.1-0.3,0.4-0.2,0.7C212.7,664.1,212.9,664.2,213.2,664.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M217.8,665.4c0.7-0.5,0.8-0.9,0.7-1.6c0-0.9-0.2-1.5-1-1.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.4" y1="662.5" x2="208.4" y2="649.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.4,667.6c0-0.6,0.3-1.2,1.2-1.2c0.6,0,1.1-0.1,1.6-0.3
        c1.4-0.6,2.2-2,2.2-3.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M216.2,697.7c0.5-0.3,0.6-1,0.3-1.5c-0.3-0.5-0.9-0.7-1.4-0.4
        c-0.5,0.3-0.6,1-0.3,1.5C215.1,697.9,215.7,698.1,216.2,697.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M219.3,697.8c0.5-0.3,0.6-1,0.3-1.5c-0.3-0.5-0.9-0.7-1.4-0.4
        c-0.5,0.3-0.6,1-0.2,1.5C218.2,698,218.8,698.1,219.3,697.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208" y1="694.9" x2="201.8" y2="694.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208" y1="698.3" x2="201.8" y2="698.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208" y1="694.9" x2="208" y2="698.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201.8" y1="694.9" x2="201.8" y2="698.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204.1" y1="702.8" x2="204" y2="698.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M228.8,728.5c1.6-5,2.2-11,2.4-14.6c1-1.3,1.4-1.3,1.6-2.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M328.8,704c-0.1,1.2-0.3,2.9-1.1,3.8c-0.8,0.9-4.8,1.7-7.8,1.8
        c-2.7,0.1-6.2,0.2-8.2,0.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M215.4,709.4c0.2,0,0.4,0,0.6,0c2.7,0.1,5.8,0.2,7.7,0.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M207,703.8c0.1,1.1,0.3,2.6,0.9,3.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.1,714.2c-7.6,0.1-16.9,0.1-22.7-0.1c-3.4,0-7.8-0.6-11.3-2.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M281.1,714.2c2.9,0,5.1-0.7,7.9-2.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M202,731.9c0.1,0.8,0.7,1.5,1.6,1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M228.7,733c0.2-0.3,0.2-0.6,0.2-0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227.5,733.6c0.5,0,1-0.3,1.2-0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="227.5" y1="733.6" x2="203.6" y2="733.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202" y1="731.9" x2="200.7" y2="711.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="228.9" y1="732.1" x2="227.5" y2="711"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M202.6,731.9c0,0.5,0.4,0.9,0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.6" y1="731.9" x2="201.4" y2="712.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M202.2,711.2c-0.3,0-0.6,0.1-0.8,0.4c-0.1,0.2-0.2,0.4-0.1,0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M228.2,732.6c0.1-0.2,0.1-0.3,0.1-0.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227.5,733c0.3,0,0.5-0.1,0.7-0.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="227.5" y1="733" x2="203.5" y2="732.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="226.1" y1="711.4" x2="211.7" y2="711.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204.3" y1="711.2" x2="202.2" y2="711.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227,712.2c0-0.5-0.4-0.8-0.9-0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="228.3" y1="732.1" x2="227" y2="712.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M330.2,692.1c0.6,0.6,1.6,0.4,2.1-0.2c0.6-0.7,0.5-1.7-0.1-2.2
        c-0.6-0.6-1.6-0.4-2.1,0.2C329.5,690.6,329.5,691.6,330.2,692.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M329.3,693.1c1.1,1,2.8,0.8,3.8-0.4c1-1.2,0.9-3-0.2-4
        c-1.1-1-2.8-0.8-3.8,0.4C328.2,690.3,328.2,692.1,329.3,693.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M329.1,704.1c1.1,1.9,2,4.2,3.2,5c1.2,0.9,2.6,0.3,2.4,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.5" y1="699.2" x2="330.4" y2="703.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.5" y1="703.1" x2="335.1" y2="664.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M320.2,694.7c1.8,1.2,4.3,0.6,5.6-1.4c1.3-2,0.8-4.7-1-5.9
        c-1.8-1.2-4.3-0.6-5.6,1.4C317.9,690.8,318.3,693.4,320.2,694.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M323.8,686.8c-1.8-1.2-4.3-0.6-5.5,1.4c-1.2,2-0.8,4.6,1,5.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M330.5,665.8c1.4,0.6,3-0.1,3.7-1.6c0.6-1.5,0-3.3-1.3-3.9
        c-1.4-0.6-3,0.1-3.7,1.6C328.5,663.4,329.1,665.2,330.5,665.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M332,659.9c-1.4-0.6-3,0.1-3.7,1.6c-0.6,1.5,0,3.3,1.3,3.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.1,664.6c0.1-2.4-0.3-5.2-3.1-6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="331.9" y1="658.4" x2="331.9" y2="654.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="331.9" y1="658.5" x2="331.9" y2="649.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M332.3,688.1c-1.1-1-2.8-0.8-3.8,0.4c-1,1.2-0.9,3,0.2,3.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="329.2" y1="693" x2="328.6" y2="692.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332.3" y1="688.1" x2="332.8" y2="688.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332.2" y1="688.1" x2="332.9" y2="688.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332.8" y1="660.3" x2="332.2" y2="660"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332.9" y1="660.3" x2="332.1" y2="659.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.5" y1="665.8" x2="329.9" y2="665.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330.6" y1="665.8" x2="329.7" y2="665.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M310.9,662.4c0.4,0.2,0.8,0,1-0.4c0.2-0.4,0-0.9-0.3-1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M330.4,686.4c-2,0.2-3.6,2-3.6,4.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M330.5,686.4c1-0.2,1.3-0.7,1.3-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M330.4,686.4c1.2-0.2,1.5-0.7,1.5-1.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="331.9" y1="684.4" x2="332.1" y2="668.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="331.9" y1="684.6" x2="332.1" y2="667.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.5" y1="703.1" x2="331.4" y2="703.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.8" y1="690.5" x2="326.7" y2="699.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M329.2,666.4c0.5,0.2,1.1,0.3,1.6,0.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.1,662.8c0,1.6,0.9,3,2.2,3.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.1" y1="662.8" x2="327.1" y2="649.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M332.1,667.9c0-0.6-0.5-1.2-1.5-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319.3,698.1c0.5,0.3,1.1,0.2,1.4-0.4c0.3-0.5,0.2-1.2-0.2-1.5
        c-0.5-0.3-1.1-0.2-1.4,0.3C318.7,697.1,318.8,697.8,319.3,698.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M316.2,698.1c0.5,0.3,1.1,0.2,1.4-0.4c0.3-0.5,0.2-1.2-0.2-1.5
        c-0.5-0.3-1.1-0.2-1.4,0.4C315.6,697.2,315.7,697.8,316.2,698.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.4" y1="695.2" x2="333.7" y2="695.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.4" y1="698.6" x2="333.7" y2="698.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.4" y1="695.2" x2="327.4" y2="698.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="333.7" y1="695.2" x2="333.7" y2="698.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="331.4" y1="703.1" x2="331.5" y2="698.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M306.7,728.9c-1.6-5-2.2-11-2.4-14.6c-1-1.3-1.7-2-1.9-3.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M306.8,733.3c0.3,0.4,0.7,0.7,1.2,0.7l23.9-0.2c0.8,0,1.5-0.7,1.6-1.5"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M306.6,732.4c0,0.3,0.1,0.6,0.2,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="333.5" y1="732.3" x2="334.7" y2="711.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.6" y1="732.4" x2="307.9" y2="711.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M331.9,733.1c0.5,0,0.9-0.4,0.9-0.9l1.2-19.7c0.1-0.2,0-0.5-0.1-0.7
        c-0.2-0.3-0.5-0.4-0.8-0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.3,732.9c0.2,0.3,0.4,0.4,0.7,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.2,732.4c0,0.2,0,0.4,0.1,0.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="308" y1="733.3" x2="331.9" y2="733.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="309.3" y1="711.7" x2="333.3" y2="711.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M309.3,711.7c-0.5,0-0.8,0.4-0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="307.2" y1="732.4" x2="308.5" y2="712.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.6,676.7c0-1-1.6-1.8-3.6-1.8c-2,0-3.6,0.8-3.6,1.8
        c0,1,1.6,1.8,3.6,1.8C321,678.6,322.6,677.8,322.6,676.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321.9,676.7c0-0.8-1.3-1.5-2.9-1.5c-1.6,0-2.9,0.7-2.9,1.5
        c0,0.8,1.3,1.5,2.9,1.5C320.6,678.2,321.9,677.6,321.9,676.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="215.8" y1="655.6" x2="214.5" y2="655.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="216.6" y1="655.6" x2="218.1" y2="655.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M217.7,654.7c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3
        c0,0.2,0.1,0.3,0.3,0.3C217.6,655,217.7,654.9,217.7,654.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M215.8,655.6c0.1-0.7,0.1-1.3,0-1.9c0-0.1-0.2-0.2-0.2-0.2
        c-0.1,0-0.6,0-0.8,0c-0.1,0-0.2,0-0.2,0.2c-0.1,0.5-0.2,1.1-0.1,1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M218.1,655.6c0.1-0.7,0.1-1.2-0.1-1.9c0-0.1-0.1-0.2-0.3-0.2
        c-0.2,0-0.7,0-0.9,0c-0.2,0-0.3,0-0.3,0.2c-0.1,0.4-0.1,1.1,0,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M215.5,654.6c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3
        c0,0.2,0.1,0.3,0.3,0.3C215.4,655,215.5,654.8,215.5,654.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M214.5,655.5c0,0.4-0.2,0.7-0.2,1c0,0.5,0.1,1,0.4,1.4
        c0.3,0.3,0.7,0.6,1.1,0.6c0.2,0,0.4,0,0.6,0c0.3-0.1,0.6-0.3,0.9-0.6c0.4-0.5,0.4-0.9,0.5-1.4c0-0.2,0-0.6,0.3-0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M215.8,655.6c-0.5,0.2-0.7,0.7-0.8,1c0,0.3,0.1,0.6,0.2,0.8
        c0.1,0.2,0.4,0.3,0.6,0.4c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.4-0.3c0.2-0.3,0.3-0.6,0.3-1c0-0.3,0-0.6-0.4-0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.8" y1="655.9" x2="320.8" y2="655.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.6" y1="656" x2="321" y2="655.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317.7,655c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
        c0,0.2-0.1,0.3-0.3,0.3C317.9,655.4,317.7,655.2,317.7,655"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319.6,656c-0.1-0.7-0.1-1.3,0-1.9c0-0.1,0.2-0.2,0.2-0.2
        c0.1,0,0.6,0,0.8,0c0.1,0,0.2,0,0.2,0.2c0.1,0.5,0.2,1.1,0.1,1.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317.3,656c-0.1-0.7-0.1-1.2,0.1-1.9c0-0.1,0.1-0.2,0.3-0.2
        c0.2,0,0.7,0,0.9,0c0.2,0,0.3,0,0.3,0.2c0.1,0.4,0.1,1.1,0,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319.9,655c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
        c0,0.2-0.1,0.3-0.3,0.3C320.1,655.3,319.9,655.2,319.9,655"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321,656c0,0.3,0.2,0.6,0.2,0.9c0,0.5-0.1,1-0.4,1.4
        c-0.3,0.3-0.7,0.6-1.1,0.6c-0.2,0-0.4,0-0.6,0c-0.3-0.1-0.6-0.3-0.9-0.6c-0.4-0.5-0.4-0.9-0.5-1.4c0-0.2,0-0.5-0.2-0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321,655.9c0,0.4,0.2,0.7,0.2,1c0,0.5-0.1,1-0.4,1.4
        c-0.3,0.3-0.7,0.6-1.1,0.6c-0.2,0-0.4,0-0.6,0c-0.3-0.1-0.6-0.3-0.9-0.6c-0.4-0.5-0.4-0.9-0.5-1.4c0-0.2,0-0.6-0.3-0.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319.8,656c0.4,0.2,0.6,0.7,0.6,0.9c0,0.3-0.1,0.6-0.2,0.8
        c-0.1,0.2-0.4,0.3-0.6,0.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.3-0.3-0.6-0.3-1c0-0.3,0-0.6,0.2-0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319.6,656c0.5,0.2,0.7,0.7,0.8,1c0,0.3-0.1,0.6-0.2,0.8
        c-0.1,0.2-0.4,0.3-0.6,0.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.3-0.3-0.6-0.3-1c0-0.3,0-0.6,0.4-0.9l-1.5,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321,656c0,0.3,0.2,0.6,0.2,0.9c0,0.5-0.1,1-0.4,1.4
        c-0.2,0.3-0.7,0.6-1.1,0.6c-0.2,0-0.4,0-0.6,0c-0.3-0.1-0.6-0.3-0.9-0.6c-0.4-0.5-0.4-0.9-0.5-1.4c0-0.2,0-0.5-0.2-0.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319.8,656c0.4,0.2,0.6,0.7,0.6,0.9c0,0.3-0.1,0.6-0.2,0.8
        c-0.1,0.2-0.4,0.3-0.6,0.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.3-0.3-0.6-0.3-1c0-0.3,0-0.6,0.2-0.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.4" y1="649.5" x2="331.9" y2="649.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.2,655.6v2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2
        c0-0.3-0.2-0.5-0.5-0.5C302.4,655.1,302.2,655.3,302.2,655.6z"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.5,733.4c-0.2,3,0.5,5.7,5.7,5.6h12.4c2.9,0.1,2.9-2.6,2.6-5.4"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M310.1,734c-0.3,2.6-0.2,5.1,2.6,5h12.4c5.1,0.1,5.9-2.4,5.7-5.2"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M328.1,641.2l0-0.2l-4.1-37.2l0.1-2.1l0,0h0.4c0-0.3,0-0.9-0.5-0.9h-0.7
        h0l-0.7-8.5l0,0.2c-0.2-3.1-2.8-5.5-5.9-5.5l0.5,0h-98.6c-3.2,0-5.8,2.5-6,5.7l-0.6,8.1h-0.6c-0.5,0-0.5,0.6-0.5,0.9h0.4v0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="328" y1="649.4" x2="328.1" y2="641.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="336.6,641.7 336.6,642.8 336.6,642.8 328.1,642.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.3" y1="641.6" x2="338.4" y2="641.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M331.9,649.7c0-0.1,0.1-0.2,0.1-0.4v-4.5c0,0,6.1-0.2,6.1-0.2
        c0.3,0,0.6-0.3,0.6-0.6v-0.6c0-0.3-0.2-0.5-0.6-0.5v-1.2v0h3.6c0.8,0,1.4-0.6,1.4-1.4v-13.4c0-0.7-0.6-1.3-1.4-1.3h-7.5
        c-0.8,0-1.4,0.6-1.4,1.4v13.2c0,0.8,0.6,1.4,1.4,1.4h1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M196.9,642.4h0.3v0v1.2c-0.3,0-0.6,0.3-0.6,0.6v0.6
        c0,0.3,0.3,0.6,0.6,0.6l6.2,0.2l-0.1,0v4.5c0,0.3,0.1,0.6,0.3,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.6" y1="642.4" x2="198.9" y2="642.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M199,642.4h2c0.8,0,1.4-0.6,1.4-1.4v-13.2c0-0.8-0.6-1.4-1.4-1.4h-7.5
        c-0.7,0-1.3,0.6-1.3,1.3V641c0,0.8,0.6,1.4,1.4,1.4h2.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="207.1,643.3 198.6,643.5 198.6,642.3 198.6,642.4 199,642.4 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="211.2,601.7 211.3,603.8 207.2,640.9 207.2,641.4 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="207.2,641.2 207.2,640.9 211.3,603.8 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="207.2" y1="649.4" x2="207.2" y2="641.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.5" y1="641.6" x2="198.8" y2="641.6"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M198.7,641.6h1.7c0.7,0,1.2-0.6,1.2-1.3v-12c0-0.7-0.5-1.3-1.2-1.3H194
          c-0.6,0-1.1,0.5-1.1,1.2v12.1c0,0.7,0.5,1.3,1.2,1.3h1.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.2" y1="640.9" x2="339.5" y2="640.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M339.4,640.9h1.7c0.7,0,1.2-0.6,1.2-1.3v-12c0-0.7-0.5-1.3-1.2-1.3
          h-6.4c-0.6,0-1.1,0.5-1.1,1.2v12.1c0,0.7,0.5,1.3,1.2,1.3h0.4"/>
      </g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="229.2" y1="649.5" x2="229.2" y2="599.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="306.3" y1="649.5" x2="306.3" y2="599.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M229.2,599.6L229.2,599.6c0-2.6,2.1-4.7,4.7-4.7h67.8
        c2.6,0,4.7,2.1,4.7,4.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M228.2,599.3L228.2,599.3c0-2.8,2.1-5.1,4.8-5.1h69.6
        c2.6,0,4.8,2.3,4.8,5.1"/>
      <g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="211.9" y1="600.8" x2="227.6" y2="600.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="211" y1="601.7" x2="229.2" y2="601.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="228.2" y1="599.3" x2="228.2" y2="600.3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M228.2,600.3c0,0,0,0.3-0.2,0.4c-0.2,0.1-0.5,0.1-0.5,0.1"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="323.7" y1="600.8" x2="308" y2="600.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="324.6" y1="601.7" x2="306.4" y2="601.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="307.4" y1="599.3" x2="307.4" y2="600.3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307.4,600.3c0,0,0,0.3,0.2,0.4c0.2,0.1,0.5,0.1,0.5,0.1"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,608.8c-0.5-0.3-0.7-0.3-1.6,0.1c-1,0.5-1.4,0.7-2.2,1
        c-0.7,0.3-1.9,0.1-1.8-1.3v-2.5c-0.1-1.3,1-1.5,1.8-1.2c0.7,0.3,1.2,0.5,2.2,1c1,0.5,1.2,0.4,1.6,0.1c0.3-0.4,0.8-0.6,1.3-0.6
        c1,0,1.9,0.9,1.9,1.9c0,1.1-0.8,1.9-1.9,1.9C307.8,609.4,307.3,609.2,307,608.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.2,606.3v2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2
        c0-0.3-0.2-0.5-0.5-0.5C302.4,605.8,302.2,606.1,302.2,606.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M309.2,607.5c0-0.5-0.4-1-0.9-1s-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        S309.2,608,309.2,607.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,606.1c-0.5,0.3-0.7,0.3-1.6-0.1c-1-0.5-1.4-0.7-2.2-1
        c-0.7-0.3-1.9-0.1-1.8,1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,608.8c-0.5-0.3-0.7-0.3-1.6,0.1c-1,0.5-1.4,0.7-2.2,1
        c-0.7,0.3-1.9,0.1-1.8-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.2,606.3v2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2
        c0-0.3-0.2-0.5-0.5-0.5C302.4,605.8,302.2,606.1,302.2,606.3z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,693.4c-0.5-0.3-0.7-0.3-1.6,0.1c-1,0.5-1.4,0.7-2.2,1
        c-0.7,0.3-1.9,0.1-1.8-1.3v-2.5c-0.1-1.3,1-1.5,1.8-1.2c0.7,0.3,1.2,0.5,2.2,1c1,0.5,1.2,0.4,1.6,0.1c0.3-0.4,0.8-0.6,1.3-0.6
        c1,0,1.9,0.9,1.9,1.9c0,1.1-0.8,1.9-1.9,1.9C307.8,694,307.3,693.8,307,693.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.2,690.9v2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2
        c0-0.3-0.2-0.5-0.5-0.5C302.4,690.4,302.2,690.7,302.2,690.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M309.2,692c0-0.5-0.4-1-0.9-1s-0.9,0.4-0.9,1c0,0.5,0.4,1,0.9,1
        S309.2,692.6,309.2,692"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,690.7c-0.5,0.3-0.7,0.3-1.6-0.1c-1-0.5-1.4-0.7-2.2-1
        c-0.7-0.3-1.9-0.1-1.8,1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M307,693.4c-0.5-0.3-0.7-0.3-1.6,0.1c-1,0.5-1.4,0.7-2.2,1
        c-0.7,0.3-1.9,0.1-1.8-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.2,690.9v2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2
        c0-0.3-0.2-0.5-0.5-0.5C302.4,690.4,302.2,690.7,302.2,690.9z"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="231.1" y1="695.9" x2="234.6" y2="696.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.8" y1="696" x2="304.4" y2="695.8"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M237.3,645L237.3,645c-1.2,0-2.2-1-2.2-2.2v-40.7c0-1.2,1-2.2,2.2-2.2"
          />
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M298.1,599.9L298.1,599.9c1.2,0,2.2,1,2.2,2.2v40.7
          c0,1.2-1,2.2-2.2,2.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="237.3" y1="599.9" x2="298.1" y2="599.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="237.3" y1="645" x2="298.3" y2="645"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M238,644.1L238,644.1c-1.2,0-2.2-0.9-2.2-2.1v-39.2
          c0-1.2,1-2.1,2.2-2.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M297.3,600.7L297.3,600.7c1.2,0,2.2,0.9,2.2,2.1V642
          c0,1.2-1,2.1-2.2,2.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="238" y1="600.7" x2="297.3" y2="600.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="238" y1="644.1" x2="297.5" y2="644.1"/>
      </g>
      <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M300.7,659.5c0,16.7-13.5,30.2-30.2,30.2
        c-16.7,0-30.2-13.5-30.2-30.2c0-16.7,13.5-30.2,30.2-30.2C287.2,629.2,300.7,642.8,300.7,659.5z"/>
      <g>
        <g>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M223.1,645.1L223.1,645.1c0.9,0,1.6-0.7,1.6-1.6v-36
            c0-0.9-0.7-1.6-1.6-1.6"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M211.5,643.2v0.3c0,0.9,0.7,1.6,1.6,1.6"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M216.8,605.8c-0.4,0-0.8,0.1-1.1,0.5c-0.1,0.1-0.2,0.3-0.2,0.5
            c0,0.1,0,0.2,0,0.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="211.6" y1="641.6" x2="215.4" y2="606.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="223.2" y1="605.8" x2="216.7" y2="605.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="223.2" y1="645.1" x2="213" y2="645.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M211.6,641.6c0,0,0,0.4-0.1,0.9c0,0.4,0,0.7,0,0.7"/>
        </g>
        <g>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M222.6,644.3L222.6,644.3c0.8,0,1.4-0.7,1.4-1.6v-34.6
            c0-0.9-0.6-1.6-1.4-1.6"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M212.7,642.5v0.3c0,0.9,0.6,1.6,1.4,1.6"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M217.2,606.6c-0.3,0-0.7,0.1-0.9,0.4c-0.1,0.1-0.1,0.3-0.2,0.4
            c0,0.1,0,0.2,0,0.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="212.8" y1="641" x2="216.1" y2="607.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="222.6" y1="606.6" x2="217.2" y2="606.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="222.6" y1="644.3" x2="214.1" y2="644.3"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M212.8,640.9c0,0,0,0.4-0.1,0.8c0,0.3,0,0.7,0,0.7"/>
        </g>
      </g>
      <g>
        <g>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M312.3,645.1L312.3,645.1c-0.9,0-1.6-0.7-1.6-1.6v-36
            c0-0.9,0.7-1.6,1.6-1.6"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M323.9,643.2v0.3c0,0.9-0.7,1.6-1.6,1.6"/>
          <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" d="M318.7,605.8c0.4,0,0.8,0.1,1.1,0.5c0.1,0.1,0.2,0.3,0.2,0.5
            c0,0.1,0,0.2,0,0.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="323.9" y1="641.6" x2="320" y2="606.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.3" y1="605.8" x2="318.7" y2="605.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.3" y1="645.1" x2="322.4" y2="645.1"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M323.9,641.6c0,0,0,0.4,0.1,0.9c0,0.4,0,0.7,0,0.7"/>
        </g>
        <g>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M312.8,644.3L312.8,644.3c-0.8,0-1.4-0.7-1.4-1.6v-34.6
            c0-0.9,0.6-1.6,1.4-1.6"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.7,642.5v0.3c0,0.9-0.6,1.6-1.4,1.6"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M318.2,606.6c0.3,0,0.7,0.1,0.9,0.4c0.1,0.1,0.1,0.3,0.2,0.4
            c0,0.1,0,0.2,0,0.2"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.6" y1="641" x2="319.3" y2="607.7"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.8" y1="606.6" x2="318.2" y2="606.6"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" x1="312.8" y1="644.3" x2="321.4" y2="644.3"/>
          <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.6,640.9c0,0,0,0.4,0.1,0.8c0,0.3,0,0.7,0,0.7"/>
        </g>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M297,659.5c0-14.6-11.9-26.5-26.5-26.5c-14.6,0-26.5,11.9-26.5,26.5
          c0,14.6,11.9,26.5,26.5,26.5C285.1,686,297,674.1,297,659.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M299.9,659.5c0-16.3-13.2-29.4-29.4-29.4c-16.3,0-29.4,13.2-29.4,29.4
          c0,16.3,13.2,29.4,29.4,29.4C286.8,688.9,299.9,675.7,299.9,659.5"/>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M266.6,659.5c0-2.2,1.7-4,3.9-4c2.2,0,4,1.7,4,3.9
            c0,2.2-1.7,3.9-3.9,4C268.4,663.4,266.6,661.7,266.6,659.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M252.5,659.6c-0.1-10,8-18.1,17.9-18.2c10-0.1,18.1,8,18.2,17.9
            c0.1,10-8,18.1-17.9,18.2C260.7,677.6,252.5,669.5,252.5,659.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M252.5,659.6c-0.1-10,8-18.1,17.9-18.2c10-0.1,18.1,8,18.2,17.9
            c0.1,10-8,18.1-17.9,18.2C260.7,677.6,252.5,669.5,252.5,659.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M253.2,659.6c-0.1-9.6,7.6-17.4,17.2-17.4c9.6-0.1,17.4,7.6,17.5,17.2
            c0.1,9.6-7.6,17.4-17.2,17.5C261.1,676.8,253.3,669.1,253.2,659.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M280.1,646.1c2.1,1.5,3.7,3.4,4.9,5.5l-0.2,0.4l-9,3l-0.4,0
            c-0.1-0.1-0.7-0.4-1.1-0.7c-0.4-0.3-0.9-0.7-1-0.8l-0.1-0.4l-0.1-9.5l0.3-0.3C275.7,643.7,278.1,644.6,280.1,646.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M265.5,659.5c0-2.8,2.2-5,5-5.1c2.8,0,5,2.2,5.1,5c0,2.8-2.2,5-5,5.1
            C267.8,664.5,265.5,662.3,265.5,659.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M265.5,659.5c0-2.8,2.2-5,5-5.1c2.8,0,5,2.2,5.1,5c0,2.8-2.2,5-5,5.1
            C267.8,664.5,265.5,662.3,265.5,659.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M268.8,654.5c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
            c0,1-0.8,1.7-1.7,1.7C269.6,656.2,268.8,655.4,268.8,654.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M269.5,654.5c0-0.6,0.4-1,1-1c0.5,0,1,0.4,1,1c0,0.6-0.4,1-1,1
            C270,655.5,269.5,655,269.5,654.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M260.7,646.2c2.1-1.5,4.4-2.5,6.7-2.9l0.3,0.3l0.1,9.5l-0.1,0.4
            c-0.1,0.1-0.6,0.5-0.9,0.8c-0.4,0.3-0.9,0.6-1,0.7l-0.4,0l-9-2.9l-0.2-0.4C257.1,649.7,258.7,647.7,260.7,646.2z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M265.2,659.6c-0.9-0.3-1.4-1.3-1.1-2.2c0.3-0.9,1.3-1.4,2.2-1.1
            c0.9,0.3,1.4,1.3,1.1,2.2C267.1,659.4,266.1,659.9,265.2,659.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M265.4,658.9c-0.5-0.2-0.8-0.7-0.6-1.3c0.2-0.5,0.7-0.8,1.3-0.6
            c0.5,0.2,0.8,0.7,0.7,1.3C266.5,658.8,266,659.1,265.4,658.9"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M254.9,664.7c-0.8-2.4-1-4.9-0.7-7.3l0.4-0.1l9,2.9l0.4,0.2
            c0.1,0.1,0.3,0.7,0.5,1.1c0.1,0.5,0.3,1.1,0.3,1.2l-0.1,0.4l-5.5,7.7l-0.4,0.1C257,669.3,255.7,667.1,254.9,664.7z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M270.6,676c-2.6,0-5-0.6-7.2-1.6l0-0.4l5.5-7.7l0.3-0.3
            c0.1,0,0.8-0.1,1.2-0.1c0.5,0,1.1,0.1,1.2,0.1l0.4,0.3l5.6,7.6l0,0.4C275.6,675.4,273.2,676,270.6,676z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M274.9,662.5c0.6,0.8,0.4,1.8-0.4,2.4c-0.8,0.6-1.8,0.4-2.4-0.4
            c-0.6-0.8-0.4-1.8,0.4-2.4C273.3,661.6,274.3,661.8,274.9,662.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M274.3,663c0.3,0.4,0.2,1.1-0.2,1.4c-0.4,0.3-1.1,0.2-1.4-0.2
            c-0.3-0.4-0.2-1.1,0.2-1.4C273.4,662.4,274,662.5,274.3,663"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M286.3,664.5c-0.8,2.4-2.1,4.6-3.7,6.3l-0.4-0.1l-5.6-7.6l-0.2-0.4
            c0-0.1,0.2-0.8,0.3-1.2c0.1-0.4,0.4-1,0.5-1.2l0.4-0.3l9-3l0.4,0.1C287.2,659.6,287,662.1,286.3,664.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M274.8,656.3c0.9-0.3,1.9,0.2,2.2,1.1c0.3,0.9-0.2,1.9-1.1,2.2
            c-0.9,0.3-1.9-0.2-2.2-1.1C273.4,657.6,273.9,656.6,274.8,656.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M275,657c0.5-0.2,1.1,0.1,1.3,0.6c0.2,0.5-0.1,1.1-0.6,1.3
            c-0.5,0.2-1.1-0.1-1.3-0.6C274.2,657.7,274.5,657.1,275,657"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M266.6,659.5c0-2.2,1.7-4,3.9-4c2.2,0,4,1.7,4,3.9
            c0,2.2-1.7,3.9-3.9,4C268.4,663.4,266.6,661.7,266.6,659.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M267.8,659.5c0-1.5,1.2-2.8,2.7-2.8c1.5,0,2.8,1.2,2.8,2.7
            c0,1.5-1.2,2.8-2.7,2.8C269,662.2,267.8,661,267.8,659.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M252.4,659.6c-0.1-10,8-18.1,18-18.2c10-0.1,18.1,8,18.2,18
            c0.1,10-8,18.1-18,18.2C260.6,677.6,252.5,669.6,252.4,659.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M265.2,659.6c-0.9-0.3-1.4-1.3-1.1-2.2c0.3-0.9,1.3-1.4,2.2-1.1
            c0.9,0.3,1.4,1.3,1.1,2.2C267.1,659.4,266.1,659.9,265.2,659.6z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M265.4,658.9c-0.5-0.2-0.8-0.7-0.6-1.3c0.2-0.5,0.7-0.8,1.3-0.6
            c0.5,0.2,0.8,0.7,0.7,1.3C266.5,658.8,266,659.1,265.4,658.9z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M268.8,654.5c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
            c0,1-0.8,1.7-1.7,1.7C269.6,656.2,268.8,655.4,268.8,654.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M269.5,654.5c0-0.6,0.4-1,1-1c0.5,0,1,0.4,1,1c0,0.6-0.4,1-1,1
            C270,655.5,269.5,655,269.5,654.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M274.8,656.3c0.9-0.3,1.9,0.2,2.2,1.1c0.3,0.9-0.2,1.9-1.1,2.2
            c-0.9,0.3-1.9-0.2-2.2-1.1C273.4,657.6,273.9,656.6,274.8,656.3z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M275,657c0.5-0.2,1.1,0.1,1.3,0.6c0.2,0.5-0.1,1.1-0.6,1.3
            c-0.5,0.2-1.1-0.1-1.3-0.6C274.2,657.7,274.5,657.1,275,657z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M274.9,662.5c0.6,0.8,0.4,1.8-0.4,2.4c-0.8,0.6-1.8,0.4-2.4-0.4
            c-0.6-0.8-0.4-1.8,0.4-2.4C273.3,661.6,274.3,661.8,274.9,662.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M274.3,663c0.3,0.4,0.2,1.1-0.2,1.4c-0.4,0.3-1.1,0.2-1.4-0.2
            c-0.3-0.4-0.2-1.1,0.2-1.4C273.4,662.4,274,662.5,274.3,663z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M269,664.6c-0.6,0.8-1.6,0.9-2.4,0.4c-0.8-0.6-1-1.6-0.4-2.4
            c0.6-0.8,1.6-0.9,2.4-0.4C269.4,662.7,269.5,663.8,269,664.6"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M269,664.6c-0.6,0.8-1.6,0.9-2.4,0.4c-0.8-0.6-1-1.6-0.4-2.4
            c0.6-0.8,1.6-0.9,2.4-0.4C269.4,662.7,269.5,663.8,269,664.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M268.4,664.2c-0.3,0.5-0.9,0.6-1.4,0.2c-0.4-0.3-0.5-1-0.2-1.4
            c0.3-0.5,0.9-0.6,1.4-0.2C268.6,663.1,268.7,663.7,268.4,664.2"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M268.4,664.2c-0.3,0.5-0.9,0.6-1.4,0.2c-0.4-0.3-0.5-1-0.2-1.4
            c0.3-0.5,0.9-0.6,1.4-0.2C268.6,663.1,268.7,663.7,268.4,664.2z"/>
        </g>
      </g>
    </g>
  </g>
  <g id="left">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M85.2,187.8l-0.3-0.1v1.3l-1.7,0l-0.9-1.2l-0.3-0.1v1.3l-1.7,0l-0.9-1.2
        l-0.3-0.1v1.3l-1.7,0l-0.9-1.2l-0.3-0.1v1.3l-1.7,0l-0.9-1.2l-0.3-0.1v1.3l-1.7,0l-0.9-1.2l-0.3-0.1v1.3l-1.7,0l-0.9-1.2l-0.3-0.1
        v1.3l-1.7,0l-0.9-1.2l-0.3-0.1v1.3h-2.9l-3.2,1.4v3.7h-1.5v-8.5h-9.1v1.5l0,0h-2.8v5.7l0,0l-3.4-0.5c-0.1,0-0.1,0-0.2,0
        c-1.5,0-2.8,1.2-2.8,2.8c0,1.5,1.2,2.8,2.8,2.8l0.1,0l3.7-0.4h0c0.3,0,0.7-0.1,1-0.3l0,0l3,10.4l0.1,0c-3.9-1.8-8.2-2.7-12.8-2.7
        c-17.2,0-31.2,14-31.2,31.2c0,16.4,12.7,29.9,28.8,31.1l0,0.2l0.7,17.5h0H15.2v1h19.4l0.6,15.6l-0.1,0.3v3.2h5.3l-0.1-0.9v0.9h-6
        l-1.1,102.2l1,0.3l-1,12.2l-0.2,0l-11.9,1.6c-0.5,0.1-0.9,0.5-0.9,1v2.5c0,0.5,0.4,0.9,0.9,0.9l11.3,0.6l0.2,0l-0.8,10.2l-0.1,0
        C16.7,439,5.2,452,5.2,467.7c0,17,13.6,30.8,30.5,31.2l0.3,0l-4.9,1.3c-0.8,0.2-0.5,1.6,0.4,1.4l0.6-0.2l0,0l0.5,2.2l0,0.3
        c-0.6,4.2,0,10.2,1.3,16l0,0l-1.9,0.1c-1.4,0.2-2.5,1.4-2.5,2.8c0,1.5,1.2,2.8,2.8,2.8c0.2,0,0.4,0,0.6-0.1l0,0l2.2-0.7l0,0
        c0.2,0.6,0.3,1.2,0.5,1.8H14.9v1.2h21.1c1.8,5.4,4.3,10.8,6.2,12.3l0.1,0c-0.1,0.8-0.2,1.7-0.2,2.6v1.4l-0.3,0
        c-0.9,0-0.9,1.2,0,1.2h0h7.8c0.9,0,0.9-1.2,0-1.2h-0.3v-1.3c-0.1-2.3,0.5-4.3,1.1-4.9c0.4-0.4,1-1,1.4-1.6l2.4,9.3l0,0h0.9v0v0.5
        h0.5l0,0v4.7c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0,0,0,0,0,0l0-4.7h0.4v-0.5v0h5.4l0,0v0.8c0,0.5,1.3,1.7,2.7,1.7
        c1.4,0,2.7-1.2,2.7-1.7l0-0.9h8.4v0.1l0.6,2.7H62l-2.1,1.4c-1.9,3.8-1.9,14.5,0,17.9L62,569h54.9l2.1-1.4c1.9-3.4,1.9-14.1,0-17.9
        l-2.1-1.4h-14.1v0l0.4-2.7l0.1,0h2.2v0c0,0.9,3.1,0.6,3.1,0v0c0,0,57.2,0,57.2,0V382.3v0l-8-67.1l-30.3-15.4h2.5
        c0.9,0,0.9-1.3,0-1.2h-0.8l0-0.1c-1.2-0.8-4.9-1.6-8.6-2.2l0.1,0L118,295l0,0c0.3-0.5,0.1-1.2-0.5-1.6l-3.6-1.9
        c-0.6-0.3-1.4,0.1-1.7,0.5l0,0l-2.2-1.1l-0.8,0l-0.3-5.8c-0.1-0.8-0.9-1.5-1.7-1.4l0,0l-4.6-82.3v0c0-4.9-3.8-8.9-8.6-9.4l-3.1-3
        l-1.7,0l-0.9-1.2l-0.3-0.1v1.3l-1.7,0L85.2,187.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M109.1,304.2v54.5c0,2,1.6,3.6,3.6,3.6h0h27.4c1.9,0,3.4-1.5,3.4-3.4
        v-41.7c0-1.3-0.8-2.5-1.9-3.1L115,301v0c-0.7-0.4-1.4-0.6-2.1-0.6C110.8,300.3,109.1,302.1,109.1,304.2c0,0.1,0,0.3,0,0.4V304.2"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M111.1,361.9V301l-0.1,0c-1.1,0.7-1.9,1.9-1.9,3.3c0,0.2,0,0.3,0,0.4
        l0-0.4v54.5C109.1,360.1,109.9,361.3,111.1,361.9L111.1,361.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M109.1,379.5v40.7c0,2.3,1.8,4.1,4.1,4.1h26.1c2.3,0,4.2-1.9,4.2-4.2
        v-40.7c0-2.2-1.8-4-4-4h-26.3C110.9,375.4,109.1,377.3,109.1,379.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M111.1,414.4h32.4v-1.9h-32.4v-36.5c-1.2,0.7-1.9,2-1.9,3.5v40.7
        c0,1.5,0.8,2.8,1.9,3.5V414.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M111.1,414.4h32.4v-1.9h-32.4v-36.5c-1.2,0.7-1.9,2-1.9,3.5v40.7
        c0,1.5,0.8,2.8,1.9,3.5V414.4z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M158.1,461.1c3,0,5.5-2.5,5.5-5.5c0,0,0,0,0-0.1l0-63c0-0.1,0-0.1,0-0.2
        c0-3-2.5-5.5-5.5-5.5c-3,0-5.5,2.4-5.5,5.5v63.2l0-0.3c0,0.1,0,0.3,0,0.4C152.6,458.6,155.1,461.1,158.1,461.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M161.4,392.5C161.4,392.4,161.4,392.4,161.4,392.5
        c0-1.9-1.5-3.3-3.3-3.3c-1.8,0-3.2,1.4-3.3,3.2l0,63.3c0,1.8,1.5,3.2,3.3,3.2c1.8,0,3.3-1.5,3.3-3.3c0,0,0,0,0-0.1L161.4,392.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M36.4,264.4c15.5,0,28.1-12.6,28.1-28.1c0-15.5-12.6-28.1-28.1-28.1
        c-15.5,0-28.1,12.6-28.1,28.1C8.3,251.8,20.9,264.4,36.4,264.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M36.4,498.9c17.2,0,31.2-14,31.2-31.2c0-17.2-14-31.2-31.2-31.2
        c-17.2,0-31.2,14-31.2,31.2C5.2,484.9,19.2,498.9,36.4,498.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M118.9,549.7l-2.1-1.4H62l-2.1,1.4c-1.9,3.8-1.9,14.5,0,17.9L62,569
        h54.9l2.1-1.4C120.9,564.2,120.9,553.5,118.9,549.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M130.1,298.6l-0.8-0.1c-2.5-1.6-15-3.2-16.8-3.1
        c-0.7,0.1-1.8,0.9-2.6,1.8h-1.4v-0.3c0-0.4-0.1-0.5-0.3-0.5h-2.8l0,0c-0.1-0.2-0.3-0.3-0.5-0.3h-5.1c-0.4,0-0.7,0.3-0.7,0.7v1.8
        c0,0.4,0.3,0.6,0.6,0.6h5.1c0.3,0,0.6-0.2,0.7-0.5l0,0h2.8c0.3,0,0.4-0.1,0.3-0.5V298h0.7c-0.1,0.2-0.3,0.4-0.4,0.5
        c-0.9,0-0.9,1.3,0,1.2h21.2C131,299.8,130.9,298.5,130.1,298.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M82.9,193.5H88v-0.3c0-0.5-1.3-1.7-2.6-1.7c-1.3,0-2.6,1.2-2.5,1.7
        V193.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M65.3,193.5h5.1v-0.3c0-0.5-1.3-1.7-2.6-1.7c-1.3,0-2.6,1.2-2.5,1.7
        V193.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M108.7,278.9l-0.5,0.4c0,0-0.4,0.3-1.1,0.3h-0.1l-3.3-58.3l0.2,0
        c2.1,2.7,3.5,6.6,3.5,9.6l2.5,44.2l0,1l-0.3,1.2l-0.4,0.9L108.7,278.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M49.4,544.1c0.9,0,0.9,1.2,0,1.2h-7.8h0c-0.9,0-0.9-1.2,0-1.2l0.3,0
        v-1.4c0-0.9,0-1.8,0.2-2.6l-0.1,0c-1.9-1.5-4.4-6.9-6.2-12.3H14.9v-1.2h20.6c-0.2-0.6-0.4-1.2-0.5-1.8l0,0l-2.2,0.7l0,0
        c-0.2,0-0.4,0.1-0.6,0.1c-1.5,0-2.8-1.2-2.8-2.8c0-1.4,1.1-2.6,2.5-2.8l1.9-0.1l0,0c-1.2-5.8-1.9-11.7-1.3-16l0-0.3l-0.5-2.2
        l-0.6,0.2c-0.9,0.2-1.2-1.1-0.4-1.4l4.9-1.3l0.4,0c17.2,0,31.2-14,31.2-31.2c0-17.2-14-31.2-31.2-31.2c-1.6,0-3.1,0.1-4.6,0.3l0,0
        l0.8-10.2l-11.5-0.6c-0.5,0-0.9-0.4-0.9-0.9v-2.5c0-0.5,0.4-0.9,0.9-1l12-1.6l0.1,0l1-12.4l0,0l-1,0l-0.1-0.9l-0.7,0.1H32v-104
        h2.3v2.7h6l0,0v117.5c0,4.9-0.9,8,5.6,9.8l1.1,0.1c0.8,0.5,1.8,0.8,3.3,1.2c3,0.8,8.1,2.5,11,3.7c2.9,1.2,10.7,4.5,11.9,12.6
        c1.2,8.1,0.9,26.2,0.2,31.6c-0.7,5.4-3.8,12-11.5,14.5c-7.6,2.5-10.6,3.2-13.3,3.6c-2.4,0.4-5.9,6.6-4.4,7.5l-0.1,0.3l7.3,28.5
        l0,0.1c-0.4,0.6-1,1.2-1.4,1.6c-0.6,0.6-1.1,2.6-1.1,4.9v1.3H49.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M40.3,304.2V279c0.2-3.4,2.2-4.6,4.8-5.2l0-0.1c-0.1-0.5,0-1,0.1-1.6
        c0.1-0.6,0.5-1.4,1.3-2.3c1.8-2.1,3.9-2.8,5.8-3.4l1.2-0.3c2.1-0.5,5.7-1.4,9.5-3.2c5.6-2.8,8.1-4,9.7-6c0.6-0.8,1.1-1.8,1.5-3
        c0.8-2.4,2.1-6.3,2.4-17.1c0.4-10.8-0.9-15.4-1.5-19c-0.3-1.9-0.7-2.9-1.9-4.4c-0.8-0.9-1.8-1.9-2.7-2.4c-2.4-1.3-7.1-4.3-8.8-5.2
        c-1.3-0.7-3.3-1.8-4.1-2.3c-0.5-0.4-0.9-0.8-1.1-1.2c-0.2-0.4-0.3-0.8-0.3-1.3l0.1-4.7l0.5-0.2v-10.3h-9.1l0,1.6h-2.8v5.7
        l-3.4-0.5c-0.1,0-0.1,0-0.2,0c-1.5,0-2.8,1.2-2.8,2.8c0,1.5,1.2,2.8,2.8,2.8c0,0,0.1,0,0.1,0l3.7-0.4h0c0.3,0,0.6-0.1,0.9-0.2v0
        l3,10.4l0.2,0.1c10.8,4.9,18.3,15.8,18.3,28.4c0,17.2-14,31.2-31.2,31.2c-0.9,0-1.7,0-2.6-0.1l0.1,0.2l0.7,17.5H15.2v1h19.4
        l0.6,15.6l-0.1,0.3v3.2h5.3L40.3,304.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.8,351.3c0-0.4-0.3-0.7-0.7-0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7v12.2
        c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.6-0.3,0.6-0.6V351.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.8,414.1c0-0.4-0.3-0.7-0.7-0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7v12.2
        c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.6-0.3,0.6-0.6V414.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M91.4,215.2c0.1-0.2,0.3-0.2,0.8-0.2c0.5,0,0.7,0,0.8,0.3
        c0.6,1.5,0.6,4.4,0,6.2c-0.1,0.2-0.3,0.3-0.8,0.3c-0.5,0-0.7,0-0.8-0.2C90.9,219.7,90.9,216.8,91.4,215.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M21,425.9l19.1,1.1l0.1-0.1c-0.1-1.2,0-2.6,0-4.1v-3.9l0,0L21,421.4
        c-0.5,0.1-0.9,0.5-0.9,1v2.5C20.2,425.4,20.6,425.8,21,425.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M25.5,415c0,2.1,1.7,3.9,3.9,3.9c2.1,0,3.9-1.7,3.9-3.9V291
        c0-2.1-1.7-3.9-3.9-3.9c-2.1,0-3.9,1.7-3.9,3.9V415z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M25.5,415c0,2.1,1.7,3.9,3.9,3.9c2.1,0,3.9-1.7,3.9-3.9V291
        c0-2.1-1.7-3.9-3.9-3.9c-2.1,0-3.9,1.7-3.9,3.9V415z"/>
      <rect x="32" y="300.8" fill="none" stroke="#231F20" stroke-width="0.5" width="2.3" height="104"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="81.5" y1="432.4" x2="73.6" y2="436.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="107.5" y1="290.7" x2="102.5" y2="201.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="96.5" y1="295.2" x2="96.5" y2="545.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="110" y1="290.8" x2="47.3" y2="290.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="165.8" y1="545.6" x2="165.8" y2="382.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="157.8" y1="315.2" x2="165.8" y2="382.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.6" y1="366.8" x2="48.8" y2="366.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="165.8" y1="545.6" x2="108.6" y2="545.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.5" y1="545.6" x2="53.9" y2="545.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="147.7,432.4 81.5,432.4 48.8,397.1 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M98.3,203.5c0-5.2-4.3-9.4-9.5-9.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="56.8" y1="194.1" x2="88.8" y2="194.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="99" y1="290.8" x2="98.3" y2="203.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="93.8" y1="195.6" x2="93.8" y2="192"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M102.5,201.3c0-4.9-3.8-8.9-8.6-9.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="149.5" y1="545.6" x2="149.5" y2="313.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.6" y1="545.6" x2="147.6" y2="312.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.3" y1="279" x2="40.3" y2="422.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="48.8" y1="295.3" x2="48.8" y2="397.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="157.8" y1="315.2" x2="127.6" y2="299.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="120.7" y1="296.3" x2="110" y2="290.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="158" y1="318.1" x2="122.2" y2="299.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="113.4,295.4 113.2,295.3 48.8,295.3 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M140.1,362.3c1.9,0,3.4-1.5,3.4-3.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M143.5,317.2c0-1.3-0.8-2.5-1.9-3.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="143.5" y1="317.2" x2="143.5" y2="358.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M115,300.9c-0.7-0.4-1.4-0.6-2.1-0.6c-2.1,0-3.9,1.7-3.9,3.9
        c0,0.1,0,0.3,0,0.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="141.6" y1="314.1" x2="115" y2="301"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M109.1,358.7c0,2,1.6,3.6,3.6,3.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="109.1" y1="304.2" x2="109.1" y2="358.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="140.1" y1="362.3" x2="112.7" y2="362.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="56.8,195.9 56.8,185.7 47.7,185.7 47.7,195.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="47.7" y1="196" x2="56.8" y2="196"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="47.6,187.2 44.9,187.2 44.9,193 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="163.6" y1="392.3" x2="163.6" y2="455.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="152.6" y1="392.3" x2="152.6" y2="455.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="161.4" y1="392.4" x2="161.4" y2="455.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="154.8" y1="392.3" x2="154.8" y2="455.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M158.1,461.1c3,0,5.5-2.5,5.5-5.5c0,0,0,0,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M152.6,455.2c0,0.1,0,0.3,0,0.4c0,3,2.5,5.5,5.5,5.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M163.6,392.6c0-0.1,0-0.1,0-0.2c0-3-2.5-5.5-5.5-5.5
        c-3,0-5.5,2.4-5.5,5.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M161.4,392.5C161.4,392.4,161.4,392.4,161.4,392.5
        c0-1.9-1.5-3.3-3.3-3.3c-1.8,0-3.2,1.4-3.3,3.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M158.1,458.9c1.8,0,3.3-1.5,3.3-3.3c0,0,0,0,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M154.9,455.6c0,1.8,1.5,3.2,3.3,3.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M143.5,379.5c0-2.2-1.8-4-4-4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M139.3,424.3c2.3,0,4.2-1.9,4.2-4.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="143.5" y1="379.5" x2="143.5" y2="420.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M109.1,420.2c0,2.3,1.8,4.1,4.1,4.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="139.3" y1="424.3" x2="113.2" y2="424.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M113.2,375.4c-2.3,0-4.1,1.8-4.1,4.1v40.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="139.5" y1="375.4" x2="113.2" y2="375.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M45.1,197.6c1.2-0.1,2.2-1.1,2.2-2.3c0-1.2-1-2.2-2.2-2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M41.3,198.1c0,0,0.1,0,0.1,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M41.5,192.5c-0.1,0-0.1,0-0.2,0c-1.5,0-2.8,1.2-2.8,2.8
        c0,1.5,1.2,2.8,2.8,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="45.1" y1="197.6" x2="41.4" y2="198.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="45.2" y1="193" x2="41.5" y2="192.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M45,196.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1
        c-0.6,0-1.1,0.5-1.1,1.1C43.9,195.9,44.4,196.4,45,196.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M41.3,196.6c0.8,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
        c-0.7,0-1.4,0.6-1.4,1.4C39.9,196,40.6,196.6,41.3,196.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="46.1" y1="197.4" x2="49.1" y2="207.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="33.9" y1="267.6" x2="34.6" y2="285.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="34.6" y1="286.2" x2="35.2" y2="301.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.5" y1="370.4" x2="48.9" y2="370.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.6" y1="432.3" x2="108.6" y2="545.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.5" y1="432.3" x2="105.5" y2="545.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="143.4" y1="412.5" x2="111" y2="412.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="109.1" y1="412.5" x2="109.1" y2="412.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="143.4" y1="414.4" x2="111" y2="414.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="109.1" y1="414.4" x2="109.1" y2="414.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M45.9,426.1c5.8,1.8,18.1,5.3,22.3,7.2c4.5,2,11.2,5.6,12.6,13.9
        c1.4,8.3,2.5,28.8,0.7,36.2c-1.8,7.4-4,13.9-11.5,16.9c-7.4,2.9-19.3,5.8-23.2,6.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M40.3,422.8c0,4.9-0.9,8,5.6,9.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M45.9,426.1c-1.3-0.4-2.8,2-1.1,4.3c1.7,2.4,2.5,2.7,5.5,3.5
        c3,0.8,8.1,2.5,11,3.7c2.9,1.2,10.7,4.5,11.9,12.6c1.2,8.1,0.9,26.2,0.2,31.6c-0.7,5.4-3.8,12-11.5,14.5
        c-7.6,2.5-10.6,3.1-13.3,3.6c-2.7,0.4-7,8.4-3.5,7.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M48.3,273.3c-4.1,0.5-7.7,1-8,5.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="56.8" y1="203.4" x2="58.5" y2="214.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="56.1" y1="260.4" x2="54.5" y2="265.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="93.8" y1="192" x2="90.8" y2="189"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M105.4,293.4c0-0.7-0.6-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M104.2,300c0.2,0,0.4-0.1,0.6-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.4" y1="293.4" x2="105.4" y2="296.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="104.2" y1="300" x2="100.4" y2="300"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M100.3,292.2c-0.7,0-1.2,0.5-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="104.2" y1="292.2" x2="100.3" y2="292.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="99.1" y1="293.4" x2="99.1" y2="296.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M104,294.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C103.3,294.3,103.6,294.6,104,294.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M100.6,294.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C99.9,294.3,100.2,294.6,100.6,294.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M60,293.3c0-0.7-0.6-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.8,299.9c0.7,0,1.2-0.6,1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="60" y1="293.3" x2="60" y2="298.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M53.7,298.6c0,0.7,0.6,1.3,1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.8" y1="299.9" x2="55" y2="299.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M54.9,292c-0.6,0-1.2,0.5-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.8" y1="292" x2="54.9" y2="292"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.6,294.4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C57.9,294.1,58.2,294.4,58.6,294.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M55.2,294.4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C54.5,294.1,54.8,294.4,55.2,294.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.6,298.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C57.9,298.4,58.2,298.7,58.6,298.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M55.2,298.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C54.5,298.4,54.8,298.7,55.2,298.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M60,368.8c0-0.7-0.6-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.8,375.4c0.7,0,1.2-0.6,1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="60" y1="368.8" x2="60" y2="374.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M53.7,374.2c0,0.7,0.6,1.3,1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.8" y1="375.4" x2="55" y2="375.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M54.9,367.6c-0.7,0-1.2,0.5-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.8" y1="367.6" x2="54.9" y2="367.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="53.7" y1="368.7" x2="53.7" y2="374.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.6,369.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C57.9,369.6,58.2,369.9,58.6,369.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M55.2,369.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C54.5,369.6,54.8,369.9,55.2,369.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M58.6,374.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C57.9,373.9,58.2,374.3,58.6,374.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M55.2,374.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C54.5,373.9,54.8,374.3,55.2,374.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="109.1" y1="311.5" x2="109.1" y2="311.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.8,360.5c1.5-0.9,2.5-2.8,2.5-5c0-3-1.8-5.4-4-5.4
        c-2.2,0-4,2.4-4,5.4c0,2.3,1.1,4.2,2.6,5.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.8,351.3c0-0.4-0.3-0.7-0.7-0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M87.5,350.6c-0.4,0-0.7,0.3-0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89" y1="350.6" x2="87.5" y2="350.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.1,364.2c0.4,0,0.6-0.3,0.6-0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89.8" y1="351.3" x2="89.8" y2="363.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M86.8,363.5c0,0.4,0.3,0.7,0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="86.8" y1="351.3" x2="86.8" y2="363.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89.1" y1="364.2" x2="87.5" y2="364.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89.7" y1="360.5" x2="86.8" y2="360.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M88.2,363.1c0.5,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8
        c-0.4,0-0.8,0.4-0.8,0.8C87.4,362.8,87.8,363.1,88.2,363.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.8,423.3c1.5-0.9,2.5-2.9,2.5-5c0-3-1.8-5.4-4-5.4
        c-2.2,0-4,2.4-4,5.4c0,2.3,1.1,4.3,2.6,5.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.8,414.1c0-0.4-0.3-0.7-0.7-0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M87.5,413.3c-0.4,0-0.7,0.3-0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89" y1="413.3" x2="87.5" y2="413.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M89.1,427c0.4,0,0.6-0.3,0.6-0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89.8" y1="414.1" x2="89.8" y2="426.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M86.8,426.2c0,0.4,0.3,0.7,0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="86.8" y1="414.1" x2="86.8" y2="426.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89.1" y1="427" x2="87.5" y2="427"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89.7" y1="423.2" x2="86.8" y2="423.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M88.2,425.9c0.5,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8
        c-0.5,0-0.8,0.4-0.8,0.8C87.4,425.5,87.8,425.9,88.2,425.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.2" y1="404.7" x2="34.3" y2="404.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="36.6" y1="402.5" x2="40.2" y2="399.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="36.6" y1="402.5" x2="36.6" y2="404.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="36.4" y1="426.8" x2="40.9" y2="436.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="37.3" y1="407.8" x2="36.3" y2="419.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="35.8" y1="426.7" x2="35" y2="436.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="34.1" y1="407.7" x2="33.1" y2="419.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="32.6" y1="426.5" x2="31.8" y2="436.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39.1" y1="407.7" x2="33.1" y2="407.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="39.1" y1="407.7" x2="39.2" y2="404.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="44.2" y1="507.7" x2="53.9" y2="545.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="147.7" y1="542.9" x2="108.6" y2="542.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.5" y1="542.9" x2="53.3" y2="542.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M108.6,545.6c0,0.6-3.1,0.9-3.1,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="47.4" y1="273.4" x2="47.4" y2="426.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M91.4,215.2c0.1-0.2,0.3-0.2,0.8-0.2c0.5,0,0.7,0,0.8,0.3
        c0.6,1.5,0.6,4.4,0,6.2c-0.1,0.2-0.3,0.3-0.8,0.3c-0.5,0-0.7,0-0.8-0.2C90.9,219.7,90.9,216.9,91.4,215.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M107.1,283.7c0.8-0.1,1.6,0.6,1.7,1.4l0.3,5.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="113.2" y1="295.3" x2="114.4" y2="293.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="38.8" y1="267.4" x2="38.1" y2="285.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="38.1" y1="286.2" x2="37.6" y2="298.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="40.3,294.2 35.1,302.1 35.1,305.2 40.3,305.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="34.3" y1="305.2" x2="40.3" y2="305.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="116.9" y1="548.3" x2="62" y2="548.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="116.9" y1="569" x2="62" y2="569"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M62,548.3l-2.1,1.4c-1.9,3.8-1.9,14.5,0,17.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="62" y1="569" x2="59.9" y2="567.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M116.9,548.3l2.1,1.4c1.9,3.8,1.9,14.5,0,17.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="116.9" y1="569" x2="118.9" y2="567.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="103.1" y1="545.6" x2="102.7" y2="548.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="77.5" y1="545.4" x2="78.2" y2="548.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="55.4" y1="546.1" x2="55.4" y2="550.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M56.7,552.1c0.7,0,1.3-0.6,1.3-1.3c0,0,0,0,0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M55.4,550.8c0,0.7,0.6,1.3,1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58" y1="550.8" x2="58" y2="546.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58" y1="545.6" x2="58" y2="545.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="58.4,546.1 54.9,546.1 54.9,545.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.4" y1="546.1" x2="58.4" y2="545.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="130.1" y1="299.8" x2="108.9" y2="299.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="130.1" y1="298.6" x2="108.9" y2="298.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M130.1,298.6c0.9,0,0.9,1.2,0,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M108.9,299.8c-0.9,0-0.9-1.2,0-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M129.2,298.5c-2.5-1.6-15-3.2-16.8-3.1c-1.1,0.1-3,2.1-3.6,3.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.5" y1="298" x2="109.2" y2="298"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.5" y1="297.2" x2="109.9" y2="297.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M108.5,298.3c0,0.4-0.1,0.5-0.3,0.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M105.4,296.7c0-0.3-0.3-0.6-0.6-0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M104.7,299.3c0.4,0,0.7-0.3,0.7-0.7v-1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M99.7,296.1c-0.4,0-0.7,0.3-0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="104.8" y1="296.1" x2="99.7" y2="296.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M99,298.6c0,0.4,0.3,0.6,0.6,0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="99" y1="296.8" x2="99" y2="298.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="104.7" y1="299.3" x2="99.6" y2="299.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="104" y1="296.1" x2="104" y2="299.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="100.5" y1="296.2" x2="100.5" y2="299.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.5" y1="298.3" x2="108.5" y2="296.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M108.5,296.9c0-0.4-0.1-0.5-0.3-0.5h-2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="108.2" y1="298.8" x2="105.4" y2="298.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="106.8" y1="296.5" x2="106.8" y2="298.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="106.8" y1="296.9" x2="105.4" y2="296.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="106.8" y1="298.3" x2="105.4" y2="298.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M45.1,273.8c-0.1-0.5,0-1,0.1-1.6c0.1-0.6,0.5-1.4,1.3-2.3
        c1.8-2.1,3.9-2.8,5.8-3.4l1.2-0.3c2.1-0.5,5.7-1.4,9.5-3.2c5.6-2.8,8.1-4,9.7-6c0.6-0.8,1.1-1.8,1.5-3c0.8-2.4,2.1-6.3,2.4-17.1
        c0.3-10.8-0.9-15.4-1.5-19c-0.3-1.9-0.7-2.9-1.9-4.4c-0.8-0.9-1.8-1.9-2.7-2.4c-2.4-1.3-7.1-4.3-8.8-5.2c-1.3-0.7-3.3-1.8-4.1-2.4
        c-0.5-0.4-0.9-0.8-1.1-1.2c-0.2-0.4-0.3-0.8-0.3-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M56.3,197.4c4.7,0.2,9.4,2.7,13.7,4.7c4.4,2,11.3,5.1,13,11.5
        c1.6,6.4,2.4,15.1,2.4,22.4c0,7.3-0.9,16.4-2.2,21.3c-1.3,4.9-4.7,7.3-8.4,9.1c-3.6,1.8-11.9,4.9-16.1,5.7
        c-4.2,0.7-6.4,0.9-10.4,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="56.3" y1="196.2" x2="56.2" y2="200.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="66.9" y1="229.7" x2="76.8" y2="230.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="66.6" y1="244.2" x2="76.1" y2="243.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M112.2,292c0.3-0.4,1.1-0.8,1.7-0.5l3.6,1.9c0.6,0.4,0.8,1.1,0.5,1.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="61.5" y1="189" x2="58.3" y2="190.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="58.3" y1="190.4" x2="58.3" y2="194.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="88.2,187.8 87.8,187.7 87.8,189 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="90.8" y1="189" x2="61.5" y2="189"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="87.8" y1="189" x2="84.9" y2="189"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="86.1,189 85.2,187.8 84.9,187.7 84.9,189 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="89.1" y1="189" x2="88.2" y2="187.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="84.9" y1="189" x2="82" y2="189"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="83.2,189 82.3,187.8 82,187.7 82,189 79,189 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="80.3,189 79.4,187.8 79,187.7 79,189 76.1,189 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="77.3,189 76.4,187.8 76.1,187.7 76.1,189 73.2,189 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="74.4,189 73.5,187.8 73.2,187.7 73.2,189 70.3,189 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="71.5,189 70.6,187.8 70.3,187.7 70.3,189 67.3,189 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="68.5,189 67.6,187.8 67.3,187.7 67.3,189 64.4,189 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="65.6,189 64.7,187.8 64.4,187.7 64.4,189 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M88,193.5v-0.3c0-0.5-1.3-1.7-2.6-1.7c-1.3,0-2.6,1.2-2.5,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="82.9" y1="193.5" x2="82.9" y2="193.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="61.7,193.5 88.8,193.5 88.8,194.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="61.7" y1="193.5" x2="61.7" y2="194.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M70.4,193.5v-0.3c0-0.5-1.3-1.7-2.6-1.7c-1.3,0-2.6,1.2-2.5,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="65.3" y1="193.5" x2="65.3" y2="193.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M41.1,267.3c-1.1,2-2.6,5.5-2.7,10.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M104.3,300c0.7,0,1.2-0.6,1.2-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M99.1,299c0.1,0.6,0.6,1.1,1.2,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="53.7" y1="293.2" x2="53.7" y2="298.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M105.2,368.4c0-0.7-0.6-1.2-1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M104,375c0.7,0,1.2-0.6,1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="105.2" y1="368.4" x2="105.2" y2="373.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M98.9,373.8c0,0.7,0.6,1.3,1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="104" y1="375" x2="100.2" y2="375"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M100.1,367.2c-0.7,0-1.2,0.5-1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="104" y1="367.2" x2="100.1" y2="367.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="98.9" y1="368.4" x2="98.9" y2="373.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M103.8,369.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C103,369.2,103.4,369.6,103.8,369.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M100.4,369.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        s-0.7,0.3-0.7,0.7C99.6,369.2,100,369.6,100.4,369.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M103.8,373.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        c-0.4,0-0.7,0.3-0.7,0.7C103,373.6,103.4,373.9,103.8,373.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M100.4,373.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7
        s-0.7,0.3-0.7,0.7C99.6,373.6,100,373.9,100.4,373.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.3" y1="285.1" x2="15.2" y2="285.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.3" y1="286.2" x2="15.2" y2="286.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="15.2" y1="285.1" x2="15.2" y2="286.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M21.1,421.4c-0.5,0.1-0.9,0.5-0.9,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="21.1" y1="421.4" x2="40.3" y2="418.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M20.2,425c0,0.5,0.4,0.9,0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="425" x2="20.2" y2="422.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.1" y1="426.9" x2="21.1" y2="425.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="111.1" y1="300.9" x2="111.1" y2="361.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="111.1" y1="376.1" x2="111.1" y2="423.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="32" y1="501.4" x2="36.4" y2="519.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="31.4" y1="501.6" x2="45.7" y2="497.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="31.1" y1="500.2" x2="36" y2="498.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M31.1,500.2c-0.8,0.2-0.5,1.6,0.4,1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="36.2" y1="520.9" x2="46.8" y2="518"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="35.8" y1="519.6" x2="46.5" y2="516.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M35.8,519.6c-0.8,0.2-0.5,1.6,0.4,1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="49" y1="527.8" x2="14.9" y2="527.8"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="48.9,526.6 14.9,526.6 14.9,527.8 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.6" y1="519.7" x2="42.5" y2="526.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="43.1" y1="528.8" x2="44.3" y2="533.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="45.6" y1="497.5" x2="46.6" y2="501.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M49.4,544.1c0.9,0,0.9,1.2,0,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M41.6,544.1c-0.9,0-0.9,1.2,0,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="49.1" y1="544.1" x2="49.1" y2="542.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="42" y1="544.1" x2="42" y2="542.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="49.4" y1="544.1" x2="41.6" y2="544.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="49.4" y1="545.4" x2="41.6" y2="545.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M42,542.7c0-2.9,0.5-5.8,2.3-7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M49.1,542.8c-0.1-2.3,0.5-4.3,1.1-4.9c0.4-0.4,1-1,1.4-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M51.1,534.9h-5.6c-0.5,0-0.8,0.1-1.3,0.5c-0.5,0.5-1,1.1-1.1,1.3
        c-0.1,0.2-1.2-0.1-0.9-0.7c0.6-1,1.8-2.5,2.6-2.5h6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M32.5,503.8c-0.6,4.2,0,10.2,1.3,16"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M34.9,524.7c0.2,0.6,0.3,1.2,0.5,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M35.9,527.8c1.8,5.4,4.3,10.8,6.2,12.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M40.6,532.2c1.4,0,2.6-1.2,2.6-2.6c0-0.7-0.3-1.3-0.7-1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M38.7,527.8c-0.5,0.5-0.7,1.1-0.7,1.8c0,1.4,1.2,2.6,2.6,2.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="64.7" y1="439.4" x2="64.7" y2="454.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="64.7" y1="480.9" x2="64.7" y2="495"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M95.8,546.1v0.3c0,0.5-1.3,1.8-2.7,1.7c-1.3,0-2.7-1.3-2.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="90.4" y1="546.1" x2="90.4" y2="546.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="95.8" y1="546.1" x2="95.8" y2="545.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="90.4" y1="546.1" x2="90.4" y2="545.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M69.1,546.1v0.3c0,0.5-1.3,1.8-2.7,1.7c-1.3,0-2.7-1.3-2.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="63.8" y1="546.1" x2="63.8" y2="546.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="69.1" y1="546.1" x2="69.1" y2="545.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="63.8" y1="546.1" x2="63.8" y2="545.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M36.3,524.3c1.1-0.2,1.8-1.2,1.8-2.3c0-0.6-0.2-1.1-0.6-1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M32.1,525.5c0.2,0,0.4,0,0.6-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M31.8,519.9c-1.4,0.2-2.5,1.4-2.5,2.8c0,1.5,1.2,2.8,2.8,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="36.3" y1="524.3" x2="32.7" y2="525.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="35.5" y1="519.8" x2="31.8" y2="519.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M35.8,523.2c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1
        c-0.6,0-1.1,0.5-1.1,1.1C34.7,522.7,35.2,523.2,35.8,523.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M32.1,524c0.8,0,1.4-0.6,1.4-1.4c0-0.7-0.6-1.4-1.4-1.4
        c-0.7,0-1.4,0.6-1.4,1.4C30.7,523.4,31.3,524,32.1,524"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M103.8,221.3c2.1,2.7,3.5,6.6,3.5,9.6l2.5,44.2c0.1,2.9-1.1,3.9-2.8,4.5
        "/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M21,421.4c-0.5,0.1-0.9,0.5-0.9,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="21" y1="421.4" x2="40.3" y2="418.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M20.2,424.9c0,0.5,0.4,0.9,0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="20.2" y1="424.9" x2="20.2" y2="422.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="40.1" y1="426.9" x2="21" y2="425.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M30.8,417.4c0.9-0.7,1.5-1.7,1.5-3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M30.9,288.4c0.9,0.7,1.5,1.7,1.5,3"/>
      <rect x="32" y="300.8" fill="none" stroke="#231F20" stroke-width="0.5" width="2.3" height="104"/>
      <rect x="34.3" y="310.5" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <rect x="34.3" y="310.5" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <rect x="34.3" y="390.6" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <rect x="34.3" y="390.6" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,232.1c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.8,4.2-4.2,4.2
          c-2.3,0-4.2-1.8-4.2-4.2C32.2,234,34.1,232.1,36.4,232.1z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,217.2c10.6-0.1,19.2,8.4,19.3,19c0.1,10.6-8.5,19.2-19,19.3
          c-10.6,0.1-19.2-8.5-19.3-19C17.2,225.9,25.7,217.2,36.3,217.2"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,217.2c10.6-0.1,19.2,8.4,19.3,19c0.1,10.6-8.5,19.2-19,19.3
          c-10.6,0.1-19.2-8.5-19.3-19C17.2,225.9,25.7,217.2,36.3,217.2z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,217.9c10.1-0.1,18.4,8.1,18.5,18.3c0.1,10.2-8.1,18.4-18.3,18.5
          c-10.2,0.1-18.4-8.1-18.5-18.3C18,226.3,26.1,218,36.3,217.9z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M50.6,246.5c-1.6,2.2-3.6,3.9-5.8,5.2l-0.4-0.2l-3.2-9.5l0-0.5
          c0.1-0.1,0.4-0.7,0.7-1.1c0.3-0.4,0.7-0.9,0.8-1l0.5-0.1l10-0.1l0.4,0.3C53.2,241.8,52.2,244.3,50.6,246.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,231c2.9,0,5.3,2.4,5.4,5.3c0,2.9-2.4,5.3-5.3,5.4
          c-2.9,0-5.3-2.3-5.4-5.3C31,233.4,33.4,231,36.4,231"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,231c2.9,0,5.3,2.4,5.4,5.3c0,2.9-2.4,5.3-5.3,5.4
          c-2.9,0-5.3-2.3-5.4-5.3C31,233.4,33.4,231,36.4,231z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M41.7,234.5c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8
          c-1,0-1.8-0.8-1.8-1.8C39.9,235.3,40.7,234.5,41.7,234.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M41.7,235.2c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
          c-0.6,0-1.1-0.5-1.1-1.1C40.6,235.7,41.1,235.2,41.7,235.2"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M50.5,226c1.6,2.2,2.6,4.6,3.1,7.1l-0.3,0.3l-10,0.1l-0.5-0.1
          c-0.1-0.1-0.5-0.6-0.8-1c-0.3-0.4-0.6-1-0.7-1.1l0-0.5l3-9.6l0.4-0.2C46.8,222.1,48.8,223.8,50.5,226z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,230.7c0.3-1,1.3-1.5,2.3-1.2c1,0.3,1.5,1.3,1.2,2.3
          c-0.3,1-1.3,1.5-2.3,1.2C36.5,232.7,36,231.7,36.3,230.7"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M37,230.9c0.2-0.6,0.8-0.9,1.3-0.7c0.6,0.2,0.9,0.8,0.7,1.3
          c-0.2,0.6-0.8,0.9-1.3,0.7C37.1,232.1,36.8,231.5,37,230.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M30.9,219.7c2.6-0.9,5.2-1.1,7.7-0.7l0.2,0.4l-3.1,9.6l-0.3,0.4
          c-0.1,0.1-0.7,0.3-1.2,0.5c-0.5,0.1-1.1,0.3-1.3,0.3l-0.4-0.2l-8.2-5.8l-0.1-0.4C26,222,28.3,220.6,30.9,219.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M18.9,236.4c0-2.7,0.6-5.3,1.7-7.6l0.4,0l8.2,5.9l0.3,0.4
          c0,0.1,0.1,0.8,0.1,1.3c0,0.5-0.1,1.2-0.1,1.3l-0.3,0.4l-8.1,6l-0.4,0C19.5,241.7,18.9,239.2,18.9,236.4z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M33.2,241c-0.8,0.6-2,0.4-2.6-0.4c-0.6-0.8-0.4-2,0.4-2.6
          c0.8-0.6,2-0.4,2.5,0.4C34.1,239.2,34,240.4,33.2,241"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M32.7,240.3c-0.5,0.3-1.1,0.2-1.5-0.2c-0.3-0.5-0.3-1.1,0.2-1.5
          c0.5-0.3,1.1-0.2,1.5,0.2C33.3,239.3,33.2,240,32.7,240.3"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M31.1,253c-2.6-0.8-4.9-2.2-6.7-3.9l0.1-0.4l8.1-6l0.4-0.2
          c0.1,0,0.8,0.2,1.3,0.3c0.5,0.2,1.1,0.4,1.2,0.5l0.3,0.4l3.2,9.5l-0.2,0.4C36.3,254,33.7,253.8,31.1,253z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M39.8,240.8c0.3,1-0.2,2-1.2,2.3c-1,0.3-2-0.2-2.3-1.2
          c-0.3-1,0.2-2,1.2-2.3C38.4,239.3,39.5,239.9,39.8,240.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M39.1,241.1c0.2,0.6-0.1,1.2-0.7,1.3c-0.6,0.2-1.2-0.1-1.3-0.7
          c-0.2-0.6,0.1-1.2,0.7-1.3C38.3,240.2,38.9,240.5,39.1,241.1"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,232.1c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.8,4.2-4.2,4.2
          c-2.3,0-4.2-1.8-4.2-4.2C32.2,234,34.1,232.1,36.4,232.1"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,233.4c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9
          c-1.6,0-2.9-1.3-2.9-2.9C33.5,234.7,34.8,233.4,36.4,233.4z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.2,217.1c10.6-0.1,19.2,8.5,19.3,19c0.1,10.6-8.5,19.2-19,19.3
          c-10.6,0.1-19.2-8.5-19.3-19C17.1,225.8,25.7,217.2,36.2,217.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M36.3,230.7c0.3-1,1.3-1.5,2.3-1.2c1,0.3,1.5,1.3,1.2,2.3
          c-0.3,1-1.3,1.5-2.3,1.2C36.5,232.7,36,231.7,36.3,230.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M37,230.9c0.2-0.6,0.8-0.9,1.3-0.7c0.6,0.2,0.9,0.8,0.7,1.3
          c-0.2,0.6-0.8,0.9-1.3,0.7C37.1,232.1,36.8,231.5,37,230.9z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M41.7,234.5c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8
          c-1,0-1.8-0.8-1.8-1.8C39.9,235.3,40.7,234.5,41.7,234.5z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M41.7,235.2c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
          c-0.6,0-1.1-0.5-1.1-1.1C40.6,235.7,41.1,235.2,41.7,235.2z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M39.8,240.8c0.3,1-0.2,2-1.2,2.3c-1,0.3-2-0.2-2.3-1.2
          c-0.3-1,0.2-2,1.2-2.3C38.4,239.3,39.5,239.9,39.8,240.8z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M39.1,241.1c0.2,0.6-0.1,1.2-0.7,1.3c-0.6,0.2-1.2-0.1-1.3-0.7
          c-0.2-0.6,0.1-1.2,0.7-1.3C38.3,240.2,38.9,240.5,39.1,241.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M33.2,241c-0.8,0.6-2,0.4-2.6-0.4c-0.6-0.8-0.4-2,0.4-2.6
          c0.8-0.6,2-0.4,2.5,0.4C34.1,239.2,34,240.4,33.2,241z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M32.7,240.3c-0.5,0.3-1.1,0.2-1.5-0.2c-0.3-0.5-0.3-1.1,0.2-1.5
          c0.5-0.3,1.1-0.2,1.5,0.2C33.3,239.3,33.2,240,32.7,240.3z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31,234.7c-0.8-0.6-1-1.7-0.4-2.6c0.6-0.8,1.7-1,2.5-0.4
          c0.8,0.6,1,1.7,0.4,2.5C32.9,235.1,31.8,235.3,31,234.7"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31,234.7c-0.8-0.6-1-1.7-0.4-2.6c0.6-0.8,1.7-1,2.5-0.4
          c0.8,0.6,1,1.7,0.4,2.5C32.9,235.1,31.8,235.3,31,234.7z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31.4,234.1c-0.5-0.3-0.6-1-0.2-1.5c0.3-0.5,1-0.6,1.5-0.2
          c0.5,0.3,0.6,1,0.2,1.5C32.6,234.3,31.9,234.4,31.4,234.1"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31.4,234.1c-0.5-0.3-0.6-1-0.2-1.5c0.3-0.5,1-0.6,1.5-0.2
          c0.5,0.3,0.6,1,0.2,1.5C32.6,234.3,31.9,234.4,31.4,234.1z"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M36.4,495.8c15.5,0,28.1-12.6,28.1-28.1c0-15.5-12.6-28.1-28.1-28.1
          c-15.5,0-28.1,12.6-28.1,28.1C8.3,483.2,20.9,495.8,36.4,495.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M36.4,498.9c17.2,0,31.2-14,31.2-31.2c0-17.2-14-31.2-31.2-31.2
          c-17.2,0-31.2,14-31.2,31.2C5.2,484.9,19.1,498.9,36.4,498.9"/>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,463.5c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.8,4.2-4.2,4.2
            c-2.3,0-4.2-1.8-4.2-4.2C32.2,465.4,34.1,463.5,36.4,463.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,448.5c10.6-0.1,19.2,8.4,19.3,19c0.1,10.6-8.5,19.2-19,19.3
            c-10.6,0.1-19.2-8.5-19.3-19C17.2,457.2,25.7,448.6,36.3,448.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,448.5c10.6-0.1,19.2,8.4,19.3,19c0.1,10.6-8.5,19.2-19,19.3
            c-10.6,0.1-19.2-8.5-19.3-19C17.2,457.2,25.7,448.6,36.3,448.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,449.3c10.1-0.1,18.4,8.1,18.5,18.3c0.1,10.2-8.1,18.4-18.3,18.5
            c-10.2,0.1-18.4-8.1-18.5-18.3C18,457.7,26.1,449.4,36.3,449.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M50.6,477.9c-1.6,2.2-3.6,3.9-5.8,5.2l-0.4-0.2l-3.2-9.5l0-0.5
            c0.1-0.1,0.4-0.7,0.7-1.1c0.3-0.4,0.7-0.9,0.8-1l0.5-0.1l10-0.1l0.4,0.3C53.2,473.2,52.2,475.7,50.6,477.9z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,462.3c2.9,0,5.3,2.4,5.4,5.3c0,2.9-2.4,5.3-5.3,5.4
            c-2.9,0-5.3-2.3-5.4-5.3C31,464.8,33.4,462.4,36.4,462.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,462.3c2.9,0,5.3,2.4,5.4,5.3c0,2.9-2.4,5.3-5.3,5.4
            c-2.9,0-5.3-2.3-5.4-5.3C31,464.8,33.4,462.4,36.4,462.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M41.7,465.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8
            c-1,0-1.8-0.8-1.8-1.8C39.9,466.7,40.7,465.8,41.7,465.8"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M41.7,466.6c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
            c-0.6,0-1.1-0.5-1.1-1.1C40.6,467.1,41.1,466.6,41.7,466.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M50.5,457.3c1.6,2.2,2.6,4.6,3.1,7.1l-0.3,0.3l-10,0.1l-0.5-0.1
            c-0.1-0.1-0.5-0.6-0.8-1c-0.3-0.4-0.6-1-0.7-1.1l0-0.5l3-9.6l0.4-0.2C46.8,453.4,48.8,455.1,50.5,457.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.3,462.1c0.3-1,1.3-1.5,2.3-1.2c1,0.3,1.5,1.3,1.2,2.3
            c-0.3,1-1.3,1.5-2.3,1.2C36.5,464,36,463,36.3,462.1"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M37,462.3c0.2-0.6,0.8-0.9,1.3-0.7c0.6,0.2,0.9,0.8,0.7,1.3
            c-0.2,0.6-0.8,0.9-1.3,0.7C37.1,463.4,36.8,462.8,37,462.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M30.9,451.1c2.6-0.9,5.2-1.1,7.7-0.7l0.2,0.4l-3.1,9.6l-0.3,0.4
            c-0.1,0.1-0.7,0.3-1.2,0.5c-0.5,0.1-1.1,0.3-1.3,0.3l-0.4-0.2l-8.2-5.8l-0.1-0.4C26,453.4,28.3,451.9,30.9,451.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M18.9,467.8c0-2.7,0.6-5.3,1.7-7.6l0.4,0l8.2,5.9l0.3,0.4
            c0,0.1,0.1,0.8,0.1,1.3c0,0.5-0.1,1.2-0.1,1.3l-0.3,0.4l-8.1,6l-0.4,0C19.5,473.1,18.9,470.5,18.9,467.8z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M33.2,472.3c-0.8,0.6-2,0.4-2.6-0.4c-0.6-0.8-0.4-1.9,0.4-2.6
            c0.8-0.6,2-0.4,2.5,0.4C34.1,470.6,34,471.7,33.2,472.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M32.7,471.7c-0.5,0.3-1.1,0.2-1.5-0.2c-0.3-0.5-0.3-1.1,0.2-1.5
            c0.5-0.3,1.1-0.2,1.5,0.2C33.3,470.7,33.2,471.4,32.7,471.7"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M31.1,484.4c-2.6-0.8-4.9-2.2-6.7-3.9l0.1-0.4l8.1-6l0.4-0.2
            c0.1,0,0.8,0.2,1.3,0.3c0.5,0.2,1.1,0.4,1.2,0.5l0.3,0.4l3.2,9.5l-0.2,0.4C36.3,485.4,33.7,485.2,31.1,484.4z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M39.8,472.2c0.3,1-0.2,2-1.2,2.3c-1,0.3-2-0.2-2.3-1.2
            c-0.3-1,0.2-2,1.2-2.3C38.4,470.7,39.5,471.2,39.8,472.2"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M39.1,472.4c0.2,0.6-0.1,1.2-0.7,1.3c-0.6,0.2-1.2-0.1-1.3-0.7
            c-0.2-0.6,0.1-1.2,0.7-1.3C38.3,471.6,38.9,471.9,39.1,472.4"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,463.5c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.8,4.2-4.2,4.2
            c-2.3,0-4.2-1.8-4.2-4.2C32.2,465.4,34.1,463.5,36.4,463.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.4,464.8c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9
            c-1.6,0-2.9-1.3-2.9-2.9C33.5,466.1,34.8,464.8,36.4,464.8z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M36.2,448.5c10.6-0.1,19.2,8.5,19.3,19c0.1,10.6-8.5,19.2-19,19.3
            c-10.6,0.1-19.2-8.5-19.3-19C17.1,457.2,25.7,448.5,36.2,448.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M36.3,462.1c0.3-1,1.3-1.5,2.3-1.2c1,0.3,1.5,1.3,1.2,2.3
            c-0.3,1-1.3,1.5-2.3,1.2C36.5,464,36,463,36.3,462.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M37,462.3c0.2-0.6,0.8-0.9,1.3-0.7c0.6,0.2,0.9,0.8,0.7,1.3
            c-0.2,0.6-0.8,0.9-1.3,0.7C37.1,463.4,36.8,462.8,37,462.3z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M41.7,465.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8
            c-1,0-1.8-0.8-1.8-1.8C39.9,466.7,40.7,465.8,41.7,465.8z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M41.7,466.6c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
            c-0.6,0-1.1-0.5-1.1-1.1C40.6,467.1,41.1,466.6,41.7,466.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M39.8,472.2c0.3,1-0.2,2-1.2,2.3c-1,0.3-2-0.2-2.3-1.2
            c-0.3-1,0.2-2,1.2-2.3C38.4,470.7,39.5,471.2,39.8,472.2z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M39.1,472.4c0.2,0.6-0.1,1.2-0.7,1.3c-0.6,0.2-1.2-0.1-1.3-0.7
            c-0.2-0.6,0.1-1.2,0.7-1.3C38.3,471.6,38.9,471.9,39.1,472.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M33.2,472.3c-0.8,0.6-2,0.4-2.6-0.4c-0.6-0.8-0.4-1.9,0.4-2.6
            c0.8-0.6,2-0.4,2.5,0.4C34.1,470.6,34,471.7,33.2,472.3z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M32.7,471.7c-0.5,0.3-1.1,0.2-1.5-0.2c-0.3-0.5-0.3-1.1,0.2-1.5
            c0.5-0.3,1.1-0.2,1.5,0.2C33.3,470.7,33.2,471.4,32.7,471.7z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31,466.1c-0.8-0.6-1-1.7-0.4-2.6c0.6-0.8,1.7-1,2.5-0.4
            c0.8,0.6,1,1.7,0.4,2.5C32.9,466.5,31.8,466.6,31,466.1"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31,466.1c-0.8-0.6-1-1.7-0.4-2.6c0.6-0.8,1.7-1,2.5-0.4
            c0.8,0.6,1,1.7,0.4,2.5C32.9,466.5,31.8,466.6,31,466.1z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31.4,465.4c-0.5-0.3-0.6-1-0.2-1.5c0.3-0.5,1-0.6,1.5-0.2
            c0.5,0.3,0.6,1,0.2,1.5C32.6,465.7,31.9,465.8,31.4,465.4"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M31.4,465.4c-0.5-0.3-0.6-1-0.2-1.5c0.3-0.5,1-0.6,1.5-0.2
            c0.5,0.3,0.6,1,0.2,1.5C32.6,465.7,31.9,465.8,31.4,465.4z"/>
        </g>
      </g>
    </g>
  </g>
  <g id="top">
    <g>
      <g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201.5" y1="199.4" x2="201.5" y2="300.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201.5" y1="303.4" x2="201.5" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="214.1" y1="321" x2="318.1" y2="321"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201.5" y1="296.3" x2="330" y2="296.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.9" y1="321" x2="319.9" y2="321.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.6" y1="188.9" x2="202.6" y2="198.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="329.2" y1="188.9" x2="329.2" y2="198.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330" y1="198.9" x2="330" y2="301.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330" y1="303.3" x2="330" y2="546.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="329.3" y1="296.4" x2="324" y2="317.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.1" y1="315.3" x2="206.1" y2="544.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="325.4" y1="316.2" x2="325.5" y2="544.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201.5" y1="296.3" x2="203.3" y2="302.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.6" y1="303.3" x2="207.9" y2="317.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="297.9" x2="201.5" y2="297.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="330" y1="297.9" x2="334" y2="297.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M297.6,338.3c0-2.1-1.7-3.7-3.7-3.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M293.6,364c2.2,0,3.9-1.8,3.9-4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="297.6" y1="338.3" x2="297.6" y2="360"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M235.7,360.3c0,2,1.6,3.7,3.7,3.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.6" y1="364" x2="239.3" y2="364"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M239.4,334.6c-2,0-3.7,1.7-3.7,3.7v22.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.9" y1="334.6" x2="239.4" y2="334.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.9" y1="332.3" x2="239.4" y2="332.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.4" y1="338.3" x2="233.4" y2="360.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M239.4,332.3c-1.7,0-3.2,0.7-4.2,1.8c-1.1,1.1-1.8,2.6-1.8,4.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M233.4,360.3c0,1.6,0.7,3.1,1.7,4.2c1.1,1.1,2.6,2,4.2,2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M293.6,366.5c1.7,0,3.5-0.9,4.6-2c1.1-1.1,1.8-2.7,1.8-4.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M299.9,338.3c0-1.7-0.7-3.2-1.8-4.3c-1.1-1.1-2.6-1.8-4.3-1.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.6" y1="366.5" x2="239.3" y2="366.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.1" y1="338.3" x2="300.1" y2="359.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M302.7,199.1c-0.4-2-2.2-3.4-4.2-3.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="302.7" y1="199.1" x2="323.5" y2="296.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M233.2,195.7c-1.9,0-3.6,1.3-4.2,3.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="233.2" y1="195.7" x2="298.6" y2="195.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="229.1" y1="198.8" x2="207.9" y2="296.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="302.7" y1="198.7" x2="331.8" y2="198.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M334,200.8c0-1.1-0.9-2-2-2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="296.3" x2="334" y2="299.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="276" x2="334" y2="296.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="200.8" x2="334" y2="276"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="508.8" x2="334.1" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="416.8" x2="334" y2="508.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="367.2" x2="334" y2="416.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="354.9" x2="334" y2="367.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="303.3" x2="334" y2="354.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M200,198.7c-1.2,0-2.1,0.9-2.1,2.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="200" y1="198.7" x2="229.1" y2="198.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="296.3" x2="197.9" y2="299.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="275.8" x2="197.9" y2="296.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="200.8" x2="197.9" y2="275.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198" y1="509" x2="198" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198" y1="417" x2="198" y2="509"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198" y1="367" x2="198" y2="417"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="355.1" x2="198" y2="367"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="303.3" x2="197.9" y2="355.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317.5,321c3.3,0.2,6.2-1.4,6.6-3.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317.5,321c3.3,0.2,6.2-1.4,6.6-3.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M317.3,318.7c1.8,0,3.3-1,3.3-2.1c0,0,0-0.1,0-0.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="214" y1="318.7" x2="317.3" y2="318.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M207.9,317.5c0.3,2.1,3.3,3.7,6.6,3.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M211.4,316.6c0,1.2,1.5,2.1,3.3,2.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M337.1,194.4c0-3-2.5-5.5-5.5-5.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="200.1" y1="188.8" x2="331.6" y2="188.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="337.1" y1="194.4" x2="337.1" y2="199.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.5,201.4c0.9,0,1.6-0.7,1.6-1.6l0,0"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M333.9,200c0.1,0.8,0.8,1.4,1.6,1.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M331.8,193.1c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
          c-0.8,0-1.4,0.6-1.4,1.4C330.4,192.5,331,193.1,331.8,193.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.6" y1="188.9" x2="202.6" y2="198.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M199.9,198.7c-1.1,0-2,0.9-2,2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M200.1,188.8c-3,0-5.5,2.5-5.5,5.5v5.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M196.3,201.3c0.8,0,1.5-0.6,1.6-1.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M194.6,199.7C194.6,199.7,194.6,199.7,194.6,199.7
          c0,0.9,0.7,1.6,1.6,1.6"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M200,193.1c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
          c-0.8,0-1.4,0.6-1.4,1.4C198.6,192.5,199.3,193.1,200,193.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M339.8,271.5c0.5-0.4,0.8-1.1,0.8-1.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="276.3" x2="339.8" y2="271.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M340.6,208.9c0-1.3-0.6-2.5-1.5-3.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="201.3" x2="339" y2="205.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="340.6" y1="208.9" x2="340.6" y2="269.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.1" y1="572.1" x2="238.4" y2="572.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M238.4,551.4l-2.1,1.4c-1.9,3.8-1.9,14.5,0,17.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="238.4" y1="572.1" x2="236.3" y2="570.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M293.1,551.4l2.1,1.4c1.9,3.8,1.9,14.5,0,17.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.1" y1="572.1" x2="295.2" y2="570.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="251.3" y1="551.4" x2="238.4" y2="551.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="265.7" y1="551.4" x2="251.3" y2="551.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="281.2" y1="551.4" x2="265.7" y2="551.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="293.1" y1="551.4" x2="281.2" y2="551.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.8" y1="396.3" x2="208.8" y2="459.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M212.1,396.5C212.1,396.5,212.1,396.4,212.1,396.5
          c0-1.9-0.7-3.3-1.6-3.3c-0.9,0-1.6,1.4-1.6,3.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M210.4,462.7c0.9,0,1.6-1.5,1.6-3.3c0,0,0,0,0-0.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M208.8,459.5c0,1.8,0.7,3.2,1.6,3.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="212.1" y1="396.2" x2="212.1" y2="459.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.9" y1="396.3" x2="322.9" y2="459.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.9,396.4c0-1.8-0.7-3.2-1.6-3.2c-0.9,0-1.6,1.5-1.6,3.2
          c0,0,0,0.1,0,0.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321.3,462.7c0.9,0,1.6-1.4,1.6-3.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M319.7,459.4C319.7,459.4,319.7,459.4,319.7,459.4
          c0,1.9,0.7,3.3,1.6,3.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="319.7" y1="396.2" x2="319.7" y2="459.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M191.4,269.7c0,0.7,0.3,1.3,0.8,1.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198.1" y1="276.3" x2="192.2" y2="271.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M193,205.6c-1,0.8-1.5,2.1-1.5,3.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198.1" y1="201.3" x2="193" y2="205.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="191.4" y1="208.9" x2="191.4" y2="269.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M324.1,302.8c0-0.1,0-0.1,0-0.1c0-1.1-1.8-2-3.9-2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="324.1" y1="302.8" x2="320.6" y2="316.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="320.2" y1="300.7" x2="290" y2="300.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277.8" y1="300.7" x2="236.4" y2="300.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M296.9,193.4c0-1.1-0.9-2.1-2.1-2.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="296.9" y1="193.4" x2="296.9" y2="195.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M236.3,191.4c-1.2,0-2.2,1-2.2,2.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="294.8" y1="191.4" x2="236.3" y2="191.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="234.1" y1="193.5" x2="234.1" y2="195.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.8" y1="197.4" x2="304.1" y2="197.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.8,197.4c0.8,0,0.9,0.7,0.9,1.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M304.1,197.4c-0.8,0-0.9,0.7-0.9,1.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.2,197.4v-0.2c0-0.5-1.3-1.7-2.6-1.7c-1.3,0-2.6,1.2-2.5,1.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.1" y1="197.5" x2="322.1" y2="197.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.9" y1="197.3" x2="227.6" y2="197.3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.9,197.3c-0.8,0-0.9,0.7-0.9,1.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M227.6,197.3c0.8,0,0.9,0.7,0.9,1.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.4,197.3v-0.2c0-0.5,1.3-1.7,2.6-1.7c1.3,0,2.6,1.2,2.5,1.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="209.5" y1="197.4" x2="209.5" y2="197.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="272.8" y1="299.7" x2="270.2" y2="299.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M321.8,299.8c0.4,0,0.8-0.4,0.8-0.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="321.8" y1="297.2" x2="270.2" y2="297.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="318.4" y1="300.7" x2="318.4" y2="299.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="315.9" y1="299.7" x2="315.9" y2="300.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.7,300.4c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M279.2,299.7c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0.3-1.2,0.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="291.9" y1="300.8" x2="291.9" y2="301.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="291.9" y1="301.5" x2="299.5" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="291.9" y1="300.9" x2="299.8" y2="301.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="278.8" y1="301.1" x2="291.9" y2="301.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="279" y1="299.7" x2="291.9" y2="300.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="313.7" y1="302" x2="301.1" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.6" y1="302" x2="286.2" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="313.7" y1="302.7" x2="302.5" y2="302.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="300.8" y1="302.7" x2="286.2" y2="302.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.6" y1="302.2" x2="299.8" y2="301.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.6" y1="302.2" x2="301.2" y2="302.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="299.8" y1="301.5" x2="301.3" y2="302.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="301.2" y1="302.8" x2="302.5" y2="302.8"/>
        <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="301.3,302.1 302.5,302.1 302.5,302.8 			"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="313.7" y1="302.7" x2="313.7" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="286.2" y1="302.7" x2="286.2" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="315.6" y1="299.7" x2="315.6" y2="298.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="313.1" y1="298.4" x2="313.1" y2="299.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M315.6,298.4c0-0.5-0.4-0.9-0.9-0.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M314.1,297.5c-0.6,0-1,0.4-1,1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="314.7" y1="297.5" x2="314.1" y2="297.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="318.8" y1="300.4" x2="318.4" y2="300.4"/>
        <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="315.9,300.4 312.8,300.4 312.8,299.7 			"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="318.8" y1="300.4" x2="318.8" y2="299.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="272.5" y1="300.7" x2="272.5" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275" y1="299.8" x2="275" y2="300.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="272.2" y1="300.4" x2="272.5" y2="300.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275" y1="300.4" x2="277.6" y2="300.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="272.2" y1="300.4" x2="272.2" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="321.8" y1="299.7" x2="272.8" y2="299.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="322.6" y1="297.9" x2="322.6" y2="299"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M322.6,298C322.6,298,322.6,298,322.6,298c0-0.5-0.4-0.8-0.8-0.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="329.9" y1="297.7" x2="325.4" y2="316"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.8" y1="303.4" x2="205.9" y2="314.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M269.3,298.9c0,0.4,0.4,0.8,0.8,0.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="269.3" y1="298" x2="269.3" y2="298.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M270.1,297.2c-0.4,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277.8" y1="299.7" x2="277.8" y2="298.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="275.3" y1="298.5" x2="275.3" y2="299.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.8,298.4c0-0.5-0.4-0.9-0.9-0.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M276.3,297.5c-0.6,0-1,0.4-1,1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="276.9" y1="297.5" x2="276.3" y2="297.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="328.4" y1="300" x2="327.6" y2="300"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="325.1" y1="300" x2="322.6" y2="300"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327,302.9l1.1-4.6c0-0.4-0.4-0.7-1.2-0.6c-0.7,0-1.2-0.1-1.4,0.7
          l-0.9,4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="325.2" y1="303.5" x2="326.4" y2="303.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M324.6,302.4c-0.2,0.7,0,1,0.6,1.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327,302.9c-0.2,0.5-0.2,0.7-0.6,0.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="208.7" y1="300" x2="211.2" y2="300"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M338.7,506.5c1.2-0.4,2-1.5,2-2.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="340.7" y1="429.7" x2="340.7" y2="503.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M340.7,430c0-1.2-0.7-2.2-1.8-2.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.7" y1="506.5" x2="334.2" y2="509"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="338.9" y1="427.2" x2="336.4" y2="426.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M191.5,503.9c0,1.3,0.8,2.3,2,2.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="191.5" y1="429.9" x2="191.5" y2="503.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M193.2,427.4c-1.1,0.5-1.7,1.6-1.8,2.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="193.4" y1="506.6" x2="198" y2="509.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="193.2" y1="427.4" x2="195.5" y2="426.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.8" y1="425.3" x2="198.1" y2="425.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M206.7,546.7v0.7c0,0.5-1.3,1.8-2.7,1.7c-1.3,0-2.7-1.3-2.7-1.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201.4" y1="547.1" x2="201.4" y2="547.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201.4" y1="547.1" x2="201.4" y2="546.6"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M332.2,547.2v0.3c0,0.5-1.3,1.7-2.7,1.7c-1.3,0-2.7-1.3-2.6-1.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.8" y1="547.2" x2="326.8" y2="547.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332.2" y1="547.2" x2="332.2" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.8" y1="547.2" x2="326.8" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="283.4" y1="546.6" x2="281.1" y2="551.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="249.2" y1="546.8" x2="251.4" y2="551.4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.2" y1="544.4" x2="325.5" y2="544.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M325.4,546.7c1.1,0,2-0.9,2-2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="327.4" y1="315.3" x2="327.4" y2="544.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="283.3" y1="546.7" x2="325.5" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="249.2" y1="546.7" x2="283.3" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.8" y1="546.7" x2="249.2" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="201" y1="546.7" x2="206.8" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198" y1="546.7" x2="201" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="332" y1="546.7" x2="334.1" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="326.7" y1="546.7" x2="332" y2="546.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="325.5" y1="546.7" x2="326.7" y2="546.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.7,544.6c0,1.2,0.9,2.1,2.1,2.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="204.6" y1="315" x2="204.6" y2="544.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M327.4,315.3c0-3.1-2-3.5-2,0.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.5" y1="366.9" x2="197.9" y2="366.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M277.6,301.1c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0l12.5,0.1l0.6-0.2"
          />
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="277.6" y1="300.4" x2="277.6" y2="301.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="279.4" y1="299.7" x2="279.4" y2="301.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M224,300.4c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M225.5,299.7c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0.3-1.2,0.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="238.3" y1="300.8" x2="238.3" y2="301.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="238.3" y1="301.5" x2="245.9" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="238.3" y1="300.9" x2="246.2" y2="301.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="225.1" y1="301.1" x2="238.3" y2="301.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="225.3" y1="299.7" x2="238.3" y2="300.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="260" y1="302" x2="247.4" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="246" y1="302" x2="232.6" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="260" y1="302.7" x2="248.8" y2="302.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="247.2" y1="302.7" x2="232.6" y2="302.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="245.9" y1="302.2" x2="246.2" y2="301.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="245.9" y1="302.2" x2="247.5" y2="302.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="246.2" y1="301.5" x2="247.7" y2="302.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="247.5" y1="302.8" x2="248.8" y2="302.8"/>
        <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="247.7,302.1 248.8,302.1 248.8,302.8 			"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="260" y1="302.7" x2="260" y2="302"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="232.6" y1="302.7" x2="232.6" y2="302"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M224,301.1c0,0.4,0.5,0.7,1.2,0.7c0,0,0.1,0,0.1,0l12.5,0.1l0.6-0.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="224" y1="300.4" x2="224" y2="301.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="225.8" y1="299.7" x2="225.8" y2="301.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="224" y1="300.7" x2="211.8" y2="300.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="213" y1="299.8" x2="210.4" y2="299.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M262.1,299.8c0.4,0,0.8-0.4,0.8-0.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="262.1" y1="297.2" x2="210.4" y2="297.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="258.7" y1="300.7" x2="258.7" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="256.1" y1="299.8" x2="256.1" y2="300.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="255.9" y1="299.8" x2="255.9" y2="298.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="253.4" y1="298.5" x2="253.4" y2="299.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M255.9,298.5c0-0.5-0.4-0.9-0.9-0.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M254.4,297.6c-0.6,0-1,0.5-1,1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="255" y1="297.6" x2="254.4" y2="297.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259" y1="300.5" x2="258.7" y2="300.5"/>
        <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="256.1,300.5 253.1,300.5 253.1,299.8 			"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="259" y1="300.5" x2="259" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="212.7" y1="300.7" x2="212.7" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="215.3" y1="299.8" x2="215.3" y2="300.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="212.4" y1="300.5" x2="212.7" y2="300.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="215.3" y1="300.5" x2="218.2" y2="300.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.3" y1="300.5" x2="218.3" y2="299.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="212.4" y1="300.5" x2="212.4" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="262.1" y1="299.8" x2="226.6" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="224.5" y1="299.8" x2="218.7" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.8" y1="299.8" x2="213" y2="299.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="262.9" y1="298" x2="262.9" y2="299.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M262.9,298.1C262.9,298.1,262.9,298.1,262.9,298.1
          c0-0.5-0.4-0.8-0.8-0.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M209.5,299c0,0.4,0.4,0.8,0.8,0.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="209.5" y1="298.1" x2="209.5" y2="299"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M210.4,297.3c-0.4,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="218.1" y1="299.8" x2="218.1" y2="298.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="215.5" y1="298.6" x2="215.5" y2="299.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M218.1,298.5c0-0.5-0.4-0.9-0.9-0.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M216.5,297.6c-0.6,0-1,0.4-1,1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="217.1" y1="297.6" x2="216.5" y2="297.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.2" y1="436.2" x2="327.6" y2="436.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="198.1" y1="436.2" x2="206.2" y2="436.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.5" y1="385.4" x2="325.2" y2="385.3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.7,280.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2
          C202.7,279.3,203.6,280.2,204.7,280.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.7,279.3c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1
          c-0.6,0-1.1,0.5-1.1,1.1C203.6,278.8,204.1,279.3,204.7,279.3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M204.7,278.7c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5
          c-0.3,0-0.5,0.2-0.5,0.5C204.2,278.5,204.5,278.7,204.7,278.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.5" y1="303.3" x2="190.2" y2="303.3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.4,302.1c0.9,0,0.9,1.2,0,1.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M190.4,303.3c-0.9,0-0.9-1.2,0-1.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.8" y1="296.8" x2="196.8" y2="299.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="296.4" x2="197.2" y2="296.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M197.3,296.4c-0.4,0-0.5,0.2-0.5,0.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="203.4" y1="302.1" x2="190.3" y2="302.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M203.2,302c-1.2-2.3-1.7-2.1-6.6-2.1c-5.4,0-4.9-0.1-6.2,2.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="329.1" y1="303.3" x2="342.3" y2="303.3"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M329.6,302.1c-0.9,0-0.9,1.2,0,1.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M342.2,303.3c0.9,0,0.9-1.2,0-1.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.7" y1="296.8" x2="335.7" y2="299.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.6" y1="296.4" x2="335.3" y2="296.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M335.2,296.4c0.4,0,0.5,0.2,0.5,0.5"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="329.2" y1="302.1" x2="342.2" y2="302.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M329.4,302c1.2-2.3,1.7-2.1,6.6-2.1c5.4,0,4.9-0.1,6.2,2.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M211.4,316.5C211.4,316.5,211.4,316.6,211.4,316.5
          c0,1.2,1.5,2.2,3.3,2.2"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M211.8,300.7c-2.2,0-3.9,0.9-3.9,2c0,0.1,0,0.1,0,0.1l3.5,13.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="202.7" y1="300" x2="204.3" y2="300"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.8" y1="300" x2="209.3" y2="300"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205,302.9l-1.1-4.6c0-0.4,0.4-0.7,1.2-0.6c0.7,0,1.2-0.1,1.4,0.7
          l0.9,4"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="206.7" y1="303.5" x2="205.6" y2="303.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M207.3,302.4c0.2,0.7,0,1-0.6,1.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M205,302.9c0.2,0.5,0.2,0.7,0.6,0.7"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M206.1,315.3c-0.2-2.6-1.4-1.7-1.4-0.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="374.8" x2="206.1" y2="374.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="325.4" y1="374.8" x2="334" y2="374.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M196.6,354.9c-0.6,0-1,0.5-1,1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="354.9" x2="196.6" y2="354.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.5" y1="355.9" x2="195.5" y2="366.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.5" y1="355.8" x2="196.5" y2="363.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="355.8" x2="196.5" y2="355.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.5" y1="363.2" x2="197.9" y2="363.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.5" y1="429" x2="197.9" y2="429"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M196.6,417c-0.6,0-1,0.5-1,1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="417" x2="196.6" y2="417"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="195.5" y1="418" x2="195.5" y2="429"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.5" y1="417.9" x2="196.5" y2="425.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="197.9" y1="417.9" x2="196.5" y2="417.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="196.5" y1="425.3" x2="197.9" y2="425.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334.1" y1="425.3" x2="333.8" y2="425.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="336.4" y1="366.9" x2="334" y2="366.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M336.4,355.9c0-0.6-0.5-1-1-1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="354.9" x2="335.3" y2="354.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="336.4" y1="355.9" x2="336.4" y2="366.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.4" y1="355.8" x2="335.4" y2="363.1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="355.8" x2="335.4" y2="355.8"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.4" y1="363.2" x2="334" y2="363.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="336.4" y1="429" x2="334" y2="429"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M336.4,418c0-0.6-0.5-1-1-1"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="417" x2="335.3" y2="417"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="336.4" y1="418" x2="336.4" y2="429"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.4" y1="417.9" x2="335.4" y2="425.2"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="334" y1="417.9" x2="335.4" y2="417.9"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" x1="335.4" y1="425.3" x2="334" y2="425.3"/>
        <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="321.8,320.8 317.8,385.3 317.8,544.1 			"/>
        <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="210.5,320.4 214.2,385.7 214.2,544.1 			"/>
      </g>
    </g>
  </g>
  <g id="right">
    <g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M446.7,188.8l0.3-0.1v1.3l1.7,0l0.9-1.2l0.3-0.1v1.3l1.7,0l0.9-1.2
        l0.3-0.1v1.3l1.7,0l0.9-1.2l0.3-0.1v1.3l1.7,0l0.9-1.2l0.3-0.1v1.3l1.7,0l0.9-1.2l0.3-0.1v1.3l1.7,0l0.9-1.2l0.3-0.1v1.3l1.7,0
        l0.9-1.2l0.3-0.1v1.3h2.9l3.2,1.4v3.7h1.5v-8.5h9.1v1.5l0,0h2.8v5.7l0,0l3.4-0.5c0.1,0,0.1,0,0.2,0c1.5,0,2.8,1.2,2.8,2.8
        c0,1.5-1.2,2.8-2.8,2.8l-0.1,0l-3.7-0.4h0c-0.3,0-0.7-0.1-1-0.3l0,0l-3,10.4l-0.1,0c3.9-1.8,8.2-2.7,12.8-2.7
        c17.2,0,31.2,14,31.2,31.2c0,16.4-12.7,29.9-28.8,31.1l0,0.2l-0.7,17.5h0h19.4v1h-19.4l-0.6,15.6l0.1,0.3v3.2h-5.3l0.1-0.9v0.9h6
        l1.1,102.2l-1,0.3l1,12.2l0.2,0l11.9,1.6c0.5,0.1,0.9,0.5,0.9,1v2.5c0,0.5-0.4,0.9-0.9,0.9l-11.3,0.6l-0.2,0l0.8,10.2l0.1,0
        c15,2.3,26.6,15.2,26.6,30.9c0,17-13.6,30.8-30.5,31.2l-0.3,0l4.9,1.3c0.8,0.2,0.5,1.6-0.4,1.4l-0.6-0.2l0,0l-0.5,2.2l0,0.3
        c0.6,4.2,0,10.2-1.3,16l0,0l1.9,0.1c1.4,0.2,2.5,1.4,2.5,2.8c0,1.5-1.2,2.8-2.8,2.8c-0.2,0-0.4,0-0.6-0.1l0,0l-2.2-0.7l0,0
        c-0.2,0.6-0.3,1.2-0.5,1.8h20.6v1.2h-21c-1.8,5.4-4.3,10.8-6.2,12.3l-0.1,0c0.1,0.8,0.2,1.7,0.2,2.6v1.4l0.3,0
        c0.9,0,0.9,1.2,0,1.2h0h-7.8c-0.9,0-0.9-1.2,0-1.2h0.3v-1.3c0.1-2.3-0.5-4.3-1.1-4.9c-0.4-0.4-1-1-1.4-1.6l-2.4,9.3l0,0h-0.9v0
        v0.5h-0.5l0,0v4.7c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0,0,0,0,0,0l0-4.7h-0.4v-0.5v0h-5.4l0,0v0.8
        c0,0.5-1.3,1.7-2.7,1.7c-1.4,0-2.7-1.2-2.7-1.7l0-0.9h-8.4v0.1l-0.6,2.7h16.2l2.1,1.4c1.9,3.8,1.9,14.5,0,17.9l-2.1,1.4h-54.9
        l-2.1-1.4c-1.9-3.4-1.9-14.1,0-17.9l2.1-1.4h14.1v0l-0.4-2.7l-0.1,0h-2.2v0c0,0.9-3.1,0.6-3.1,0v0c0,0-57.2,0-57.2,0V383.3v0
        l8-67.1l30.3-15.4h-2.5c-0.9,0-0.9-1.3,0-1.2h0.8l0-0.1c1.2-0.8,4.9-1.6,8.6-2.2l-0.1,0l2.7-1.4l0,0c-0.3-0.5-0.1-1.2,0.5-1.6
        l3.6-1.9c0.6-0.3,1.4,0.1,1.7,0.5l0,0l2.2-1.1l0.8,0l0.3-5.8c0.1-0.8,0.9-1.5,1.7-1.4l0,0l4.6-82.3v0c0-4.9,3.8-8.9,8.6-9.4l3.1-3
        l1.7,0l0.9-1.2l0.3-0.1v1.3l1.7,0L446.7,188.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M422.8,305.2v54.5c0,2-1.6,3.6-3.6,3.6h0h-27.4c-1.9,0-3.4-1.5-3.4-3.4
        v-41.7c0-1.3,0.8-2.5,1.9-3.1l26.6-13.1v0c0.7-0.4,1.4-0.6,2.1-0.6C421.1,301.3,422.8,303.1,422.8,305.2c0,0.1,0,0.3,0,0.4V305.2"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M420.8,362.9V302l0.1,0c1.1,0.7,1.9,1.9,1.9,3.3c0,0.2,0,0.3,0,0.4
        l0-0.4v54.5C422.8,361.1,422,362.3,420.8,362.9L420.8,362.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M422.8,380.5v40.7c0,2.3-1.8,4.1-4.1,4.1h-26.1c-2.3,0-4.2-1.9-4.2-4.2
        v-40.7c0-2.2,1.8-4,4-4h26.3C421,376.4,422.8,378.3,422.8,380.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M420.9,415.4h-32.4v-1.9h32.4v-36.5c1.2,0.7,1.9,2,1.9,3.5v40.7
        c0,1.5-0.8,2.8-1.9,3.5V415.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M420.9,415.4h-32.4v-1.9h32.4v-36.5c1.2,0.7,1.9,2,1.9,3.5v40.7
        c0,1.5-0.8,2.8-1.9,3.5V415.4z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M373.8,462.1c-3,0-5.5-2.5-5.5-5.5c0,0,0,0,0-0.1l0-63
        c0-0.1,0-0.1,0-0.2c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.4,5.5,5.5v63.2l0-0.3c0,0.1,0,0.3,0,0.4C379.3,459.6,376.8,462.1,373.8,462.1"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M370.5,393.5C370.5,393.4,370.5,393.4,370.5,393.5
        c0-1.9,1.5-3.3,3.3-3.3c1.8,0,3.2,1.4,3.3,3.2l0,63.3c0,1.8-1.5,3.2-3.3,3.2c-1.8,0-3.3-1.5-3.3-3.3c0,0,0,0,0-0.1L370.5,393.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.5,265.4c-15.5,0-28.1-12.6-28.1-28.1c0-15.5,12.6-28.1,28.1-28.1
        c15.5,0,28.1,12.6,28.1,28.1C523.6,252.8,511,265.4,495.5,265.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.5,499.9c-17.2,0-31.2-14-31.2-31.2c0-17.2,14-31.2,31.2-31.2
        c17.2,0,31.2,14,31.2,31.2C526.7,485.9,512.8,499.9,495.5,499.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M413,550.7l2.1-1.4h54.9l2.1,1.4c1.9,3.8,1.9,14.5,0,17.9l-2.1,1.4
        h-54.9l-2.1-1.4C411.1,565.2,411.1,554.5,413,550.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M401.8,299.6l0.8-0.1c2.5-1.6,15-3.2,16.8-3.1c0.7,0.1,1.8,0.9,2.6,1.8
        h1.4v-0.3c0-0.4,0.1-0.5,0.3-0.5h2.8l0,0c0.1-0.2,0.3-0.3,0.5-0.3h5.1c0.4,0,0.7,0.3,0.7,0.7v1.8c0,0.4-0.3,0.6-0.6,0.6h-5.1
        c-0.3,0-0.6-0.2-0.7-0.5l0,0h-2.8c-0.3,0-0.4-0.1-0.3-0.5V299h-0.7c0.1,0.2,0.3,0.4,0.4,0.5c0.9,0,0.9,1.3,0,1.2h-21.2
        C400.9,300.8,401,299.5,401.8,299.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M449,194.5h-5.1v-0.3c0-0.5,1.3-1.7,2.6-1.7c1.3,0,2.6,1.2,2.5,1.7
        V194.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M466.6,194.5h-5.1v-0.3c0-0.5,1.3-1.7,2.6-1.7c1.3,0,2.6,1.2,2.5,1.7
        V194.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M423.2,279.9l0.5,0.4c0,0,0.4,0.3,1.1,0.3h0.1l3.3-58.3l-0.2,0
        c-2.1,2.7-3.5,6.6-3.5,9.6l-2.5,44.2l0,1l0.3,1.2l0.4,0.9L423.2,279.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M482.5,545.1c-0.9,0-0.9,1.2,0,1.2h7.8h0c0.9,0,0.9-1.2,0-1.2l-0.3,0
        v-1.4c0-0.9,0-1.8-0.2-2.6l0.1,0c1.9-1.5,4.4-6.9,6.2-12.3h21v-1.2h-20.6c0.2-0.6,0.4-1.2,0.5-1.8l0,0l2.2,0.7l0,0
        c0.2,0,0.4,0.1,0.6,0.1c1.5,0,2.8-1.2,2.8-2.8c0-1.4-1.1-2.6-2.5-2.8l-1.9-0.1l0,0c1.2-5.8,1.9-11.7,1.3-16l0-0.3l0.5-2.2l0.6,0.2
        c0.9,0.2,1.2-1.1,0.4-1.4l-4.9-1.3l-0.4,0c-17.2,0-31.2-14-31.2-31.2c0-17.2,14-31.2,31.2-31.2c1.6,0,3.1,0.1,4.6,0.3l0,0
        l-0.8-10.2l11.5-0.6c0.5,0,0.9-0.4,0.9-0.9v-2.5c0-0.5-0.4-0.9-0.9-1l-12-1.6l-0.1,0l-1-12.4l0,0l1,0l0.1-0.9l0.7,0.1h0.4v-104
        h-2.3v2.7h-6l0,0v117.5c0,4.9,0.9,8-5.6,9.8l-1.1,0.1c-0.8,0.5-1.8,0.8-3.3,1.2c-3,0.8-8.1,2.5-11,3.7
        c-2.9,1.2-10.7,4.5-11.9,12.6c-1.2,8.1-0.9,26.2-0.2,31.6c0.7,5.4,3.8,12,11.5,14.5c7.6,2.5,10.6,3.2,13.3,3.6
        c2.4,0.4,5.9,6.6,4.4,7.5l0.1,0.3l-7.3,28.5l0,0.1c0.4,0.6,1,1.2,1.4,1.6c0.6,0.6,1.1,2.6,1.1,4.9v1.3H482.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M491.6,305.2V280c-0.2-3.4-2.2-4.6-4.8-5.2l0-0.1c0.1-0.5,0-1-0.1-1.6
        c-0.1-0.6-0.5-1.4-1.3-2.3c-1.8-2.1-3.9-2.8-5.8-3.4l-1.2-0.3c-2.1-0.5-5.7-1.4-9.5-3.2c-5.6-2.8-8.1-4-9.7-6
        c-0.6-0.8-1.1-1.8-1.5-3c-0.8-2.4-2.1-6.3-2.4-17.1c-0.4-10.8,0.9-15.4,1.5-19c0.3-1.9,0.7-2.9,1.9-4.4c0.8-0.9,1.8-1.9,2.7-2.4
        c2.4-1.3,7.1-4.3,8.8-5.2c1.3-0.7,3.3-1.8,4.1-2.3c0.5-0.4,0.9-0.8,1.1-1.2c0.2-0.4,0.3-0.8,0.3-1.3l-0.1-4.7l-0.5-0.2v-10.3h9.1
        l0,1.6h2.8v5.7l3.4-0.5c0.1,0,0.1,0,0.2,0c1.5,0,2.8,1.2,2.8,2.8c0,1.5-1.2,2.8-2.8,2.8c0,0-0.1,0-0.1,0l-3.7-0.4h0
        c-0.3,0-0.6-0.1-0.9-0.2v0l-3,10.4l-0.2,0.1c-10.8,4.9-18.3,15.8-18.3,28.4c0,17.2,14,31.2,31.2,31.2c0.9,0,1.7,0,2.6-0.1
        l-0.1,0.2l-0.7,17.5h19.3v1h-19.4l-0.6,15.6l0.1,0.3v3.2h-5.3L491.6,305.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.1,352.3c0-0.4,0.3-0.7,0.7-0.7h1.6c0.4,0,0.7,0.3,0.7,0.7v12.2
        c0,0.4-0.3,0.7-0.7,0.7h-1.6c-0.4,0-0.6-0.3-0.6-0.6V352.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.1,415.1c0-0.4,0.3-0.7,0.7-0.7h1.6c0.4,0,0.7,0.3,0.7,0.7v12.2
        c0,0.4-0.3,0.7-0.7,0.7h-1.6c-0.4,0-0.6-0.3-0.6-0.6V415.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.5,216.2c-0.1-0.2-0.3-0.2-0.8-0.2c-0.5,0-0.7,0-0.8,0.3
        c-0.6,1.5-0.6,4.4,0,6.2c0.1,0.2,0.3,0.3,0.8,0.3c0.5,0,0.7,0,0.8-0.2C441,220.7,441,217.8,440.5,216.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M510.9,426.9l-19.1,1.1l-0.1-0.1c0.1-1.2,0-2.6,0-4.1v-3.9l0,0l19.2,2.5
        c0.5,0.1,0.9,0.5,0.9,1v2.5C511.7,426.4,511.4,426.8,510.9,426.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M506.4,416c0,2.1-1.7,3.9-3.9,3.9c-2.1,0-3.9-1.7-3.9-3.9V292
        c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9V416z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M506.4,416c0,2.1-1.7,3.9-3.9,3.9c-2.1,0-3.9-1.7-3.9-3.9V292
        c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9V416z"/>
      <rect x="497.6" y="301.8" fill="none" stroke="#231F20" stroke-width="0.5" width="2.3" height="104"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="450.4" y1="433.4" x2="458.3" y2="437.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="424.4" y1="291.7" x2="429.4" y2="202.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="435.4" y1="296.2" x2="435.4" y2="546.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="421.9" y1="291.8" x2="484.6" y2="291.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="366.1" y1="546.6" x2="366.1" y2="383.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="374.1" y1="316.2" x2="366.1" y2="383.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.3" y1="367.8" x2="483.1" y2="367.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="366.1" y1="546.6" x2="423.3" y2="546.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.4" y1="546.6" x2="478" y2="546.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="384.3,433.4 450.4,433.4 483.1,398.1 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M433.6,204.5c0-5.2,4.3-9.4,9.5-9.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="475.1" y1="195.1" x2="443.1" y2="195.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.9" y1="291.8" x2="433.6" y2="204.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="438.1" y1="196.6" x2="438.1" y2="193"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M429.4,202.3c0-4.9,3.8-8.9,8.6-9.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="382.4" y1="546.6" x2="382.4" y2="314.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.3" y1="546.6" x2="384.3" y2="313.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.6" y1="280" x2="491.6" y2="423.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="483.1" y1="296.3" x2="483.1" y2="398.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="374.1" y1="316.2" x2="404.3" y2="300.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="411.2" y1="297.3" x2="421.9" y2="291.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="373.9" y1="319.1" x2="409.7" y2="300.9"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="418.5,296.4 418.8,296.3 483.1,296.3 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M391.9,363.3c-1.9,0-3.4-1.5-3.4-3.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M388.4,318.2c0-1.3,0.8-2.5,1.9-3.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="388.4" y1="318.2" x2="388.4" y2="359.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M416.9,301.9c0.7-0.4,1.4-0.6,2.1-0.6c2.1,0,3.9,1.7,3.9,3.9
        c0,0.1,0,0.3,0,0.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="390.3" y1="315.1" x2="416.9" y2="302"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M422.8,359.7c0,2-1.6,3.6-3.6,3.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.8" y1="305.2" x2="422.8" y2="359.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="391.9" y1="363.3" x2="419.3" y2="363.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="475.1,196.9 475.1,186.7 484.3,186.7 484.3,196.9 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="484.3" y1="197" x2="475.1" y2="197"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="484.3,188.2 487,188.2 487,194 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="368.3" y1="393.3" x2="368.3" y2="456.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="379.3" y1="393.3" x2="379.3" y2="456.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="370.5" y1="393.4" x2="370.5" y2="456.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="377.1" y1="393.3" x2="377.1" y2="456.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M373.8,462.1c-3,0-5.5-2.5-5.5-5.5c0,0,0,0,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M379.3,456.2c0,0.1,0,0.3,0,0.4c0,3-2.5,5.5-5.5,5.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M368.3,393.6c0-0.1,0-0.1,0-0.2c0-3,2.5-5.5,5.5-5.5
        c3,0,5.5,2.4,5.5,5.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M370.5,393.5C370.5,393.4,370.5,393.4,370.5,393.5
        c0-1.9,1.5-3.3,3.3-3.3c1.8,0,3.2,1.4,3.3,3.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M373.8,459.9c-1.8,0-3.3-1.5-3.3-3.3c0,0,0,0,0-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M377.1,456.6c0,1.8-1.5,3.2-3.3,3.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M388.4,380.5c0-2.2,1.8-4,4-4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M392.6,425.3c-2.3,0-4.2-1.9-4.2-4.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="388.4" y1="380.5" x2="388.4" y2="421.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M422.8,421.2c0,2.3-1.8,4.1-4.1,4.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.6" y1="425.3" x2="418.7" y2="425.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M418.7,376.4c2.3,0,4.1,1.8,4.1,4.1v40.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="392.4" y1="376.4" x2="418.7" y2="376.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M486.8,198.6c-1.2-0.1-2.2-1.1-2.2-2.3c0-1.2,1-2.2,2.2-2.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M490.6,199.1c0,0-0.1,0-0.1,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M490.4,193.5c0.1,0,0.1,0,0.2,0c1.5,0,2.8,1.2,2.8,2.8
        c0,1.5-1.2,2.8-2.8,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="486.8" y1="198.6" x2="490.5" y2="199.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="486.7" y1="194" x2="490.4" y2="193.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M486.9,197.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
        c0.6,0,1.1,0.5,1.1,1.1C488,196.9,487.5,197.4,486.9,197.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M490.6,197.6c-0.8,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
        c0.7,0,1.4,0.6,1.4,1.4C492,197,491.4,197.6,490.6,197.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="485.8" y1="198.4" x2="482.8" y2="208.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="498" y1="268.6" x2="497.3" y2="286.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="497.3" y1="287.2" x2="496.7" y2="302.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.4" y1="371.4" x2="483.1" y2="371.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="423.3" y1="433.3" x2="423.3" y2="546.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.4" y1="433.3" x2="426.4" y2="546.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="388.5" y1="413.5" x2="420.9" y2="413.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.8" y1="413.5" x2="422.8" y2="413.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="388.5" y1="415.4" x2="420.9" y2="415.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.8" y1="415.4" x2="422.8" y2="415.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M486.1,427.1c-5.8,1.8-18.1,5.3-22.3,7.2c-4.5,2-11.2,5.6-12.6,13.9
        c-1.4,8.3-2.5,28.8-0.7,36.2c1.8,7.4,4,13.9,11.5,16.9c7.4,2.9,19.3,5.8,23.2,6.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M491.6,423.8c0,4.9,0.9,8-5.6,9.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M486.1,427.1c1.3-0.4,2.8,2,1.1,4.3c-1.7,2.4-2.5,2.7-5.5,3.5
        c-3,0.8-8.1,2.5-11,3.7c-2.9,1.2-10.7,4.5-11.9,12.6c-1.2,8.1-0.9,26.2-0.2,31.6c0.7,5.4,3.8,12,11.5,14.5
        c7.6,2.5,10.6,3.1,13.3,3.6c2.7,0.4,7,8.4,3.5,7.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M483.6,274.3c4.1,0.5,7.7,1,8,5.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="475.1" y1="204.4" x2="473.4" y2="215.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="475.8" y1="261.4" x2="477.4" y2="266.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="438.1" y1="193" x2="441.1" y2="190"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M426.5,294.4c0-0.7,0.6-1.2,1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.7,301c-0.2,0-0.4-0.1-0.6-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.5" y1="294.4" x2="426.5" y2="297.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.7" y1="301" x2="431.5" y2="301"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M431.6,293.2c0.7,0,1.2,0.5,1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.7" y1="293.2" x2="431.6" y2="293.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.8" y1="294.4" x2="432.8" y2="297.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.9,295.6c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C428.6,295.3,428.3,295.6,427.9,295.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M431.3,295.6c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C432.1,295.3,431.7,295.6,431.3,295.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M471.9,294.3c0-0.7,0.6-1.2,1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M473.1,300.9c-0.7,0-1.2-0.6-1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="471.9" y1="294.3" x2="471.9" y2="299.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M478.2,299.6c0,0.7-0.6,1.3-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.1" y1="300.9" x2="476.9" y2="300.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M477,293c0.6,0,1.2,0.5,1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.1" y1="293" x2="477" y2="293"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M473.3,295.4c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C474,295.1,473.7,295.4,473.3,295.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M476.7,295.4c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C477.4,295.1,477.1,295.4,476.7,295.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M473.3,299.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C474,299.4,473.7,299.7,473.3,299.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M476.7,299.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C477.4,299.4,477.1,299.7,476.7,299.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M471.9,369.8c0-0.7,0.6-1.2,1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M473.1,376.4c-0.7,0-1.2-0.6-1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="471.9" y1="369.8" x2="471.9" y2="375.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M478.2,375.2c0,0.7-0.6,1.3-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.1" y1="376.4" x2="476.9" y2="376.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M477,368.6c0.7,0,1.2,0.5,1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.1" y1="368.6" x2="477" y2="368.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="478.2" y1="369.7" x2="478.2" y2="375.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M473.3,370.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C474,370.6,473.7,370.9,473.3,370.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M476.7,370.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C477.4,370.6,477.1,370.9,476.7,370.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M473.3,375.3c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C474,374.9,473.7,375.3,473.3,375.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M476.7,375.3c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C477.4,374.9,477.1,375.3,476.7,375.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="422.8" y1="312.5" x2="422.8" y2="312.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.2,361.5c-1.5-0.9-2.5-2.8-2.5-5c0-3,1.8-5.4,4-5.4
        c2.2,0,4,2.4,4,5.4c0,2.3-1.1,4.2-2.6,5.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.1,352.3c0-0.4,0.3-0.7,0.7-0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M444.4,351.6c0.4,0,0.7,0.3,0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.9" y1="351.6" x2="444.4" y2="351.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.8,365.2c-0.4,0-0.6-0.3-0.6-0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.1" y1="352.3" x2="442.1" y2="364.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.2,364.5c0,0.4-0.3,0.7-0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.2" y1="352.3" x2="445.2" y2="364.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.8" y1="365.2" x2="444.4" y2="365.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.2" y1="361.5" x2="445.1" y2="361.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443.7,364.1c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8
        c0.4,0,0.8,0.4,0.8,0.8C444.5,363.8,444.1,364.1,443.7,364.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.2,424.3c-1.5-0.9-2.5-2.9-2.5-5c0-3,1.8-5.4,4-5.4
        c2.2,0,4,2.4,4,5.4c0,2.3-1.1,4.3-2.6,5.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.2,415.1c0-0.4,0.3-0.7,0.7-0.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M444.4,414.3c0.4,0,0.7,0.3,0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.9" y1="414.3" x2="444.4" y2="414.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M442.8,428c-0.4,0-0.6-0.3-0.6-0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.2" y1="415.1" x2="442.2" y2="427.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M445.2,427.2c0,0.4-0.3,0.7-0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="445.2" y1="415.1" x2="445.2" y2="427.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.8" y1="428" x2="444.4" y2="428"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.2" y1="424.2" x2="445.1" y2="424.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443.7,426.9c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8
        c0.5,0,0.8,0.4,0.8,0.8C444.5,426.5,444.1,426.9,443.7,426.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.8" y1="405.7" x2="497.7" y2="405.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="495.3" y1="403.5" x2="491.7" y2="400.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="495.3" y1="403.5" x2="495.3" y2="405.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="495.6" y1="427.8" x2="491" y2="437.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="494.7" y1="408.8" x2="495.6" y2="420.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="496.2" y1="427.7" x2="496.9" y2="437.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="497.8" y1="408.7" x2="498.8" y2="420.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="499.3" y1="427.5" x2="500.1" y2="437.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="492.8" y1="408.7" x2="498.8" y2="408.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="492.8" y1="408.7" x2="492.7" y2="405.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="487.7" y1="508.7" x2="478" y2="546.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="384.2" y1="543.9" x2="423.3" y2="543.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.4" y1="543.9" x2="478.6" y2="543.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M423.3,546.6c0,0.6,3.1,0.9,3.1,0"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="484.6" y1="274.4" x2="484.6" y2="427.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M440.5,216.2c-0.1-0.2-0.3-0.2-0.8-0.2c-0.5,0-0.7,0-0.8,0.3
        c-0.6,1.5-0.6,4.4,0,6.2c0.1,0.2,0.3,0.3,0.8,0.3c0.5,0,0.7,0,0.8-0.2C441,220.7,441,217.9,440.5,216.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M424.8,284.7c-0.8-0.1-1.6,0.6-1.7,1.4l-0.3,5.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="418.8" y1="296.3" x2="417.6" y2="294.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="493.1" y1="268.4" x2="493.8" y2="286.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="493.9" y1="287.2" x2="494.3" y2="299.3"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="491.7,295.2 496.8,303.1 496.8,306.2 491.6,306.2 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="497.7" y1="306.2" x2="491.7" y2="306.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="415.1" y1="549.3" x2="469.9" y2="549.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="415.1" y1="570" x2="469.9" y2="570"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M469.9,549.3l2.1,1.4c1.9,3.8,1.9,14.5,0,17.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="469.9" y1="570" x2="472" y2="568.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M415.1,549.3l-2.1,1.4c-1.9,3.8-1.9,14.5,0,17.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="415.1" y1="570" x2="413" y2="568.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428.8" y1="546.6" x2="429.2" y2="549.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="454.4" y1="546.4" x2="453.7" y2="549.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="476.5" y1="547.1" x2="476.5" y2="551.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M475.2,553.1c-0.7,0-1.3-0.6-1.3-1.3c0,0,0,0,0,0"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M476.5,551.8c0,0.7-0.6,1.3-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.9" y1="551.8" x2="473.9" y2="547.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.9" y1="546.6" x2="473.9" y2="546.6"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="473.5,547.1 477.1,547.1 477.1,546.6 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.5" y1="547.1" x2="473.5" y2="546.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="401.9" y1="300.8" x2="423.1" y2="300.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="401.9" y1="299.6" x2="423.1" y2="299.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M401.9,299.6c-0.9,0-0.9,1.2,0,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M423.1,300.8c0.9,0,0.9-1.2,0-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M402.7,299.5c2.5-1.6,15-3.2,16.8-3.1c1.1,0.1,3,2.1,3.6,3.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="423.4" y1="299" x2="422.7" y2="299"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="423.4" y1="298.2" x2="422" y2="298.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M423.4,299.3c0,0.4,0.1,0.5,0.3,0.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M426.5,297.7c0-0.3,0.3-0.6,0.6-0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.2,300.3c-0.4,0-0.7-0.3-0.7-0.7v-1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.2,297.1c0.4,0,0.7,0.3,0.7,0.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.1" y1="297.1" x2="432.2" y2="297.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.9,299.6c0,0.4-0.3,0.6-0.6,0.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="432.9" y1="297.8" x2="432.9" y2="299.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.2" y1="300.3" x2="432.3" y2="300.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.9" y1="297.1" x2="427.9" y2="300.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="431.4" y1="297.2" x2="431.4" y2="300.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="423.4" y1="299.3" x2="423.4" y2="297.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M423.4,297.9c0-0.4,0.1-0.5,0.3-0.5h2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="423.7" y1="299.8" x2="426.5" y2="299.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="425.2" y1="297.5" x2="425.2" y2="299.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="425.2" y1="297.9" x2="426.5" y2="297.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="425.2" y1="299.3" x2="426.5" y2="299.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M486.8,274.8c0.1-0.5,0-1-0.1-1.6c-0.1-0.6-0.5-1.4-1.3-2.3
        c-1.8-2.1-3.9-2.8-5.8-3.4l-1.2-0.3c-2.1-0.5-5.7-1.4-9.5-3.2c-5.6-2.8-8.1-4-9.7-6c-0.6-0.8-1.1-1.8-1.5-3
        c-0.8-2.4-2.1-6.3-2.4-17.1c-0.3-10.8,0.9-15.4,1.5-19c0.3-1.9,0.7-2.9,1.9-4.4c0.8-0.9,1.8-1.9,2.7-2.4c2.4-1.3,7.1-4.3,8.8-5.2
        c1.3-0.7,3.3-1.8,4.1-2.4c0.5-0.4,0.9-0.8,1.1-1.2c0.2-0.4,0.3-0.8,0.3-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M475.6,198.4c-4.7,0.2-9.4,2.7-13.7,4.7c-4.4,2-11.3,5.1-13,11.5
        c-1.6,6.4-2.4,15.1-2.4,22.4c0,7.3,0.9,16.4,2.2,21.3c1.3,4.9,4.7,7.3,8.4,9.1c3.6,1.8,11.9,4.9,16.1,5.7
        c4.2,0.7,6.4,0.9,10.4,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="475.6" y1="197.2" x2="475.7" y2="201.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="465" y1="230.7" x2="455.1" y2="231.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="465.3" y1="245.2" x2="455.8" y2="244.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M419.7,293c-0.3-0.4-1.1-0.8-1.7-0.5l-3.6,1.9c-0.6,0.4-0.8,1.1-0.5,1.6
        "/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="470.5" y1="190" x2="473.6" y2="191.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="473.6" y1="191.4" x2="473.6" y2="195.1"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="443.8,188.8 444.1,188.7 444.1,190 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="441.1" y1="190" x2="470.5" y2="190"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="444.1" y1="190" x2="447" y2="190"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="445.8,190 446.7,188.8 447,188.7 447,190 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="442.8" y1="190" x2="443.8" y2="188.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="447" y1="190" x2="449.9" y2="190"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="448.7,190 449.6,188.8 449.9,188.7 449.9,190 452.9,190 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="451.6,190 452.6,188.8 452.9,188.7 452.9,190 455.8,190 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="454.6,190 455.5,188.8 455.8,188.7 455.8,190 458.7,190 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="457.5,190 458.4,188.8 458.7,188.7 458.7,190 461.7,190 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="460.4,190 461.4,188.8 461.7,188.7 461.7,190 464.6,190 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="463.4,190 464.3,188.8 464.6,188.7 464.6,190 467.5,190 		"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="466.3,190 467.2,188.8 467.5,188.7 467.5,190 		"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M443.9,194.5v-0.3c0-0.5,1.3-1.7,2.6-1.7c1.3,0,2.6,1.2,2.5,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="449" y1="194.5" x2="449" y2="194.2"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="470.2,194.5 443.1,194.5 443.1,195.1 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="470.2" y1="194.5" x2="470.2" y2="195.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M461.5,194.5v-0.3c0-0.5,1.3-1.7,2.6-1.7c1.3,0,2.6,1.2,2.5,1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="466.6" y1="194.5" x2="466.6" y2="194.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M490.8,268.3c1.1,2,2.6,5.5,2.7,10.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M427.6,301c-0.7,0-1.2-0.6-1.2-1.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M432.8,300c-0.1,0.6-0.6,1.1-1.2,1.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="478.2" y1="294.2" x2="478.2" y2="299.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M426.7,369.4c0-0.7,0.6-1.2,1.2-1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M428,376c-0.7,0-1.2-0.6-1.2-1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="426.7" y1="369.4" x2="426.7" y2="374.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M433,374.8c0,0.7-0.6,1.3-1.3,1.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="428" y1="376" x2="431.8" y2="376"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M431.8,368.2c0.7,0,1.2,0.5,1.2,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="427.9" y1="368.2" x2="431.8" y2="368.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="433" y1="369.4" x2="433" y2="374.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M428.2,370.6c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C428.9,370.2,428.6,370.6,428.2,370.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M431.6,370.6c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C432.3,370.2,432,370.6,431.6,370.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M428.2,374.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C428.9,374.6,428.6,374.9,428.2,374.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M431.6,374.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
        c0.4,0,0.7,0.3,0.7,0.7C432.3,374.6,432,374.9,431.6,374.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.7" y1="286.1" x2="516.7" y2="286.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.6" y1="287.2" x2="516.7" y2="287.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="516.7" y1="286.1" x2="516.7" y2="287.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M510.8,422.4c0.5,0.1,0.9,0.5,0.9,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="510.8" y1="422.4" x2="491.6" y2="419.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M511.7,426c0,0.5-0.4,0.9-0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="511.7" y1="426" x2="511.7" y2="423.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.8" y1="427.9" x2="510.8" y2="426.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="420.9" y1="301.9" x2="420.9" y2="362.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="420.9" y1="377.1" x2="420.9" y2="424.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="499.9" y1="502.4" x2="495.5" y2="520.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="500.5" y1="502.6" x2="486.2" y2="498.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="500.9" y1="501.2" x2="496" y2="499.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M500.9,501.2c0.8,0.2,0.5,1.6-0.4,1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="495.7" y1="521.9" x2="485.1" y2="519"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="496.1" y1="520.6" x2="485.4" y2="517.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M496.1,520.6c0.8,0.2,0.5,1.6-0.4,1.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="482.9" y1="528.8" x2="517.1" y2="528.8"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.5" points="483,527.6 517.1,527.6 517.1,528.8 		"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.3" y1="520.7" x2="489.5" y2="527.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="488.9" y1="529.8" x2="487.6" y2="534.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="486.3" y1="498.5" x2="485.4" y2="502.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M482.5,545.1c-0.9,0-0.9,1.2,0,1.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M490.3,545.1c0.9,0,0.9,1.2,0,1.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="482.8" y1="545.1" x2="482.8" y2="543.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="489.9" y1="545.1" x2="489.9" y2="543.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="482.5" y1="545.1" x2="490.3" y2="545.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="482.5" y1="546.4" x2="490.3" y2="546.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M489.9,543.7c0-2.9-0.5-5.8-2.3-7.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M482.8,543.8c0.1-2.3-0.5-4.3-1.1-4.9c-0.4-0.4-1-1-1.4-1.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M480.8,535.9h5.6c0.5,0,0.8,0.1,1.3,0.5c0.5,0.5,1,1.1,1.1,1.3
        c0.1,0.2,1.2-0.1,0.9-0.7c-0.6-1-1.8-2.5-2.6-2.5h-6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M499.4,504.8c0.6,4.2,0,10.2-1.3,16"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M497,525.7c-0.2,0.6-0.3,1.2-0.5,1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M496,528.8c-1.8,5.4-4.3,10.8-6.2,12.3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M491.4,533.2c-1.4,0-2.6-1.2-2.6-2.6c0-0.7,0.3-1.3,0.7-1.8"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M493.2,528.8c0.5,0.5,0.7,1.1,0.7,1.8c0,1.4-1.2,2.6-2.6,2.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="467.2" y1="440.4" x2="467.2" y2="455.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="467.2" y1="481.9" x2="467.2" y2="496"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M436.1,547.1v0.3c0,0.5,1.3,1.8,2.7,1.7c1.3,0,2.7-1.3,2.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="441.5" y1="547.1" x2="441.5" y2="547.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="436.1" y1="547.1" x2="436.1" y2="546.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="441.5" y1="547.1" x2="441.5" y2="546.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M462.8,547.1v0.3c0,0.5,1.3,1.8,2.7,1.7c1.3,0,2.7-1.3,2.7-1.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="468.2" y1="547.1" x2="468.2" y2="547.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="462.8" y1="547.1" x2="462.8" y2="546.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="468.2" y1="547.1" x2="468.2" y2="546.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.6,525.3c-1.1-0.2-1.8-1.2-1.8-2.3c0-0.6,0.2-1.1,0.6-1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M499.8,526.5c-0.2,0-0.4,0-0.6-0.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M500.1,520.9c1.4,0.2,2.5,1.4,2.5,2.8c0,1.5-1.2,2.8-2.8,2.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="495.6" y1="525.3" x2="499.2" y2="526.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="496.4" y1="520.8" x2="500.1" y2="520.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M496.1,524.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
        c0.6,0,1.1,0.5,1.1,1.1C497.3,523.7,496.8,524.2,496.1,524.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M499.8,525c-0.8,0-1.4-0.6-1.4-1.4c0-0.7,0.6-1.4,1.4-1.4
        c0.7,0,1.4,0.6,1.4,1.4C501.2,524.4,500.6,525,499.8,525"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M428.2,222.3c-2.1,2.7-3.5,6.6-3.5,9.6l-2.5,44.2
        c-0.1,2.9,1.1,3.9,2.8,4.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M510.9,422.4c0.5,0.1,0.9,0.5,0.9,1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="510.9" y1="422.4" x2="491.6" y2="419.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M511.7,425.9c0,0.5-0.4,0.9-0.9,0.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="511.7" y1="425.9" x2="511.7" y2="423.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" x1="491.8" y1="427.9" x2="510.9" y2="426.9"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M501.2,418.4c-0.9-0.7-1.5-1.7-1.5-3"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" d="M501,289.4c-0.9,0.7-1.5,1.7-1.5,3"/>
      <rect x="497.6" y="301.8" fill="none" stroke="#231F20" stroke-width="0.5" width="2.3" height="104"/>
      <rect x="491.6" y="311.5" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <rect x="491.6" y="311.5" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <rect x="491.6" y="391.6" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <rect x="491.6" y="391.6" fill="none" stroke="#231F20" stroke-width="0.5" width="6" height="2.4"/>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,233.1c-2.3,0-4.2,1.8-4.2,4.1c0,2.3,1.8,4.2,4.2,4.2
          c2.3,0,4.2-1.8,4.2-4.2C499.7,235,497.9,233.1,495.6,233.1z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,218.2c-10.6-0.1-19.2,8.4-19.3,19c-0.1,10.6,8.5,19.2,19,19.3
          c10.6,0.1,19.2-8.5,19.3-19C514.7,226.9,506.2,218.2,495.6,218.2"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,218.2c-10.6-0.1-19.2,8.4-19.3,19c-0.1,10.6,8.5,19.2,19,19.3
          c10.6,0.1,19.2-8.5,19.3-19C514.7,226.9,506.2,218.2,495.6,218.2z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,218.9c-10.1-0.1-18.4,8.1-18.5,18.3
          c-0.1,10.2,8.1,18.4,18.3,18.5c10.2,0.1,18.4-8.1,18.5-18.3C513.9,227.3,505.8,219,495.6,218.9z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M481.3,247.5c1.6,2.2,3.6,3.9,5.8,5.2l0.4-0.2l3.2-9.5l0-0.5
          c-0.1-0.1-0.4-0.7-0.7-1.1c-0.3-0.4-0.7-0.9-0.8-1l-0.5-0.1l-10-0.1l-0.4,0.3C478.8,242.8,479.7,245.3,481.3,247.5z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,232c-2.9,0-5.3,2.4-5.4,5.3c0,2.9,2.4,5.3,5.3,5.4
          c2.9,0,5.3-2.3,5.4-5.3C500.9,234.4,498.5,232,495.6,232"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,232c-2.9,0-5.3,2.4-5.4,5.3c0,2.9,2.4,5.3,5.3,5.4
          c2.9,0,5.3-2.3,5.4-5.3C500.9,234.4,498.5,232,495.6,232z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M490.2,235.5c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
          c1,0,1.8-0.8,1.8-1.8C492,236.3,491.2,235.5,490.2,235.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M490.2,236.2c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1
          c0.6,0,1.1-0.5,1.1-1.1C491.3,236.7,490.8,236.2,490.2,236.2"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M481.5,227c-1.6,2.2-2.6,4.6-3.1,7.1l0.3,0.3l10,0.1l0.5-0.1
          c0.1-0.1,0.5-0.6,0.8-1c0.3-0.4,0.6-1,0.7-1.1l0-0.5l-3-9.6l-0.4-0.2C485.1,223.1,483.1,224.8,481.5,227z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.7,231.7c-0.3-1-1.3-1.5-2.3-1.2c-1,0.3-1.5,1.3-1.2,2.3
          c0.3,1,1.3,1.5,2.3,1.2C495.4,233.7,496,232.7,495.7,231.7"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M494.9,231.9c-0.2-0.6-0.8-0.9-1.3-0.7c-0.6,0.2-0.9,0.8-0.7,1.3
          c0.2,0.6,0.8,0.9,1.3,0.7C494.8,233.1,495.1,232.5,494.9,231.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M501.1,220.7c-2.6-0.9-5.2-1.1-7.7-0.7l-0.2,0.4l3.1,9.6l0.3,0.4
          c0.1,0.1,0.7,0.3,1.2,0.5c0.5,0.1,1.1,0.3,1.3,0.3l0.4-0.2l8.2-5.8l0.1-0.4C505.9,223,503.6,221.6,501.1,220.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M513,237.4c0-2.7-0.6-5.3-1.7-7.6l-0.4,0l-8.2,5.9l-0.3,0.4
          c0,0.1-0.1,0.8-0.1,1.3c0,0.5,0.1,1.2,0.1,1.3l0.3,0.4l8.1,6l0.4,0C512.4,242.7,513,240.2,513,237.4z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M498.8,242c0.8,0.6,2,0.4,2.6-0.4c0.6-0.8,0.4-2-0.4-2.6
          c-0.8-0.6-2-0.4-2.5,0.4C497.8,240.2,498,241.4,498.8,242"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M499.2,241.3c0.5,0.3,1.1,0.2,1.5-0.2c0.3-0.5,0.3-1.1-0.2-1.5
          c-0.5-0.3-1.1-0.2-1.5,0.2C498.6,240.3,498.7,241,499.2,241.3"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M500.8,254c2.6-0.8,4.9-2.2,6.7-3.9l-0.1-0.4l-8.1-6l-0.4-0.2
          c-0.1,0-0.8,0.2-1.3,0.3c-0.5,0.2-1.1,0.4-1.2,0.5l-0.3,0.4l-3.2,9.5l0.2,0.4C495.6,255,498.3,254.8,500.8,254z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.1,241.8c-0.3,1,0.2,2,1.2,2.3c1,0.3,2-0.2,2.3-1.2
          c0.3-1-0.2-2-1.2-2.3C493.5,240.3,492.5,240.9,492.1,241.8"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.9,242.1c-0.2,0.6,0.1,1.2,0.7,1.3c0.6,0.2,1.2-0.1,1.3-0.7
          c0.2-0.6-0.1-1.2-0.7-1.3C493.7,241.2,493,241.5,492.9,242.1"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,233.1c-2.3,0-4.2,1.8-4.2,4.1c0,2.3,1.8,4.2,4.2,4.2
          c2.3,0,4.2-1.8,4.2-4.2C499.7,235,497.9,233.1,495.6,233.1"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.5,234.4c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9
          c1.6,0,2.9-1.3,2.9-2.9C498.5,235.7,497.2,234.4,495.5,234.4z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.7,218.1c-10.6-0.1-19.2,8.5-19.3,19c-0.1,10.6,8.5,19.2,19,19.3
          c10.6,0.1,19.2-8.5,19.3-19C514.8,226.8,506.3,218.2,495.7,218.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M495.7,231.7c-0.3-1-1.3-1.5-2.3-1.2c-1,0.3-1.5,1.3-1.2,2.3
          c0.3,1,1.3,1.5,2.3,1.2C495.4,233.7,496,232.7,495.7,231.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" d="M494.9,231.9c-0.2-0.6-0.8-0.9-1.3-0.7c-0.6,0.2-0.9,0.8-0.7,1.3
          c0.2,0.6,0.8,0.9,1.3,0.7C494.8,233.1,495.1,232.5,494.9,231.9z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M490.2,235.5c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
          c1,0,1.8-0.8,1.8-1.8C492,236.3,491.2,235.5,490.2,235.5z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M490.2,236.2c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1
          c0.6,0,1.1-0.5,1.1-1.1C491.3,236.7,490.8,236.2,490.2,236.2z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M492.1,241.8c-0.3,1,0.2,2,1.2,2.3c1,0.3,2-0.2,2.3-1.2
          c0.3-1-0.2-2-1.2-2.3C493.5,240.3,492.5,240.9,492.1,241.8z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M492.9,242.1c-0.2,0.6,0.1,1.2,0.7,1.3c0.6,0.2,1.2-0.1,1.3-0.7
          c0.2-0.6-0.1-1.2-0.7-1.3C493.7,241.2,493,241.5,492.9,242.1z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M498.8,242c0.8,0.6,2,0.4,2.6-0.4c0.6-0.8,0.4-2-0.4-2.6
          c-0.8-0.6-2-0.4-2.5,0.4C497.8,240.2,498,241.4,498.8,242z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M499.2,241.3c0.5,0.3,1.1,0.2,1.5-0.2c0.3-0.5,0.3-1.1-0.2-1.5
          c-0.5-0.3-1.1-0.2-1.5,0.2C498.6,240.3,498.7,241,499.2,241.3z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.9,235.7c0.8-0.6,1-1.7,0.4-2.6c-0.6-0.8-1.7-1-2.5-0.4
          c-0.8,0.6-1,1.7-0.4,2.5C499,236.1,500.1,236.3,500.9,235.7"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.9,235.7c0.8-0.6,1-1.7,0.4-2.6c-0.6-0.8-1.7-1-2.5-0.4
          c-0.8,0.6-1,1.7-0.4,2.5C499,236.1,500.1,236.3,500.9,235.7z"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.5,235.1c0.5-0.3,0.6-1,0.2-1.5c-0.3-0.5-1-0.6-1.5-0.2
          c-0.5,0.3-0.6,1-0.2,1.5C499.4,235.3,500,235.4,500.5,235.1"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.5,235.1c0.5-0.3,0.6-1,0.2-1.5c-0.3-0.5-1-0.6-1.5-0.2
          c-0.5,0.3-0.6,1-0.2,1.5C499.4,235.3,500,235.4,500.5,235.1z"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.5,496.8c-15.5,0-28.1-12.6-28.1-28.1c0-15.5,12.6-28.1,28.1-28.1
          c15.5,0,28.1,12.6,28.1,28.1C523.6,484.2,511,496.8,495.5,496.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" d="M495.5,499.9c-17.2,0-31.2-14-31.2-31.2c0-17.2,14-31.2,31.2-31.2
          c17.2,0,31.2,14,31.2,31.2C526.8,485.9,512.8,499.9,495.5,499.9"/>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,464.5c-2.3,0-4.2,1.8-4.2,4.1c0,2.3,1.8,4.2,4.2,4.2
            c2.3,0,4.2-1.8,4.2-4.2C499.7,466.4,497.9,464.5,495.6,464.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,449.5c-10.6-0.1-19.2,8.4-19.3,19c-0.1,10.6,8.5,19.2,19,19.3
            c10.6,0.1,19.2-8.5,19.3-19C514.7,458.2,506.2,449.6,495.6,449.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,449.5c-10.6-0.1-19.2,8.4-19.3,19c-0.1,10.6,8.5,19.2,19,19.3
            c10.6,0.1,19.2-8.5,19.3-19C514.7,458.2,506.2,449.6,495.6,449.5z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,450.3c-10.1-0.1-18.4,8.1-18.5,18.3
            c-0.1,10.2,8.1,18.4,18.3,18.5c10.2,0.1,18.4-8.1,18.5-18.3C513.9,458.7,505.8,450.4,495.6,450.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M481.3,478.9c1.6,2.2,3.6,3.9,5.8,5.2l0.4-0.2l3.2-9.5l0-0.5
            c-0.1-0.1-0.4-0.7-0.7-1.1c-0.3-0.4-0.7-0.9-0.8-1l-0.5-0.1l-10-0.1l-0.4,0.3C478.8,474.2,479.7,476.7,481.3,478.9z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,463.3c-2.9,0-5.3,2.4-5.4,5.3c0,2.9,2.4,5.3,5.3,5.4
            c2.9,0,5.3-2.3,5.4-5.3C500.9,465.8,498.5,463.4,495.6,463.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,463.3c-2.9,0-5.3,2.4-5.4,5.3c0,2.9,2.4,5.3,5.3,5.4
            c2.9,0,5.3-2.3,5.4-5.3C500.9,465.8,498.5,463.4,495.6,463.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M490.2,466.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
            c1,0,1.8-0.8,1.8-1.8C492,467.7,491.2,466.8,490.2,466.8"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M490.2,467.6c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1
            c0.6,0,1.1-0.5,1.1-1.1C491.3,468.1,490.8,467.6,490.2,467.6"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M481.5,458.3c-1.6,2.2-2.6,4.6-3.1,7.1l0.3,0.3l10,0.1l0.5-0.1
            c0.1-0.1,0.5-0.6,0.8-1c0.3-0.4,0.6-1,0.7-1.1l0-0.5l-3-9.6l-0.4-0.2C485.1,454.4,483.1,456.1,481.5,458.3z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.7,463.1c-0.3-1-1.3-1.5-2.3-1.2c-1,0.3-1.5,1.3-1.2,2.3
            c0.3,1,1.3,1.5,2.3,1.2C495.4,465,496,464,495.7,463.1"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M494.9,463.3c-0.2-0.6-0.8-0.9-1.3-0.7c-0.6,0.2-0.9,0.8-0.7,1.3
            c0.2,0.6,0.8,0.9,1.3,0.7C494.8,464.4,495.1,463.8,494.9,463.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M501.1,452.1c-2.6-0.9-5.2-1.1-7.7-0.7l-0.2,0.4l3.1,9.6l0.3,0.4
            c0.1,0.1,0.7,0.3,1.2,0.5c0.5,0.1,1.1,0.3,1.3,0.3l0.4-0.2l8.2-5.8l0.1-0.4C505.9,454.4,503.6,452.9,501.1,452.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M513,468.8c0-2.7-0.6-5.3-1.7-7.6l-0.4,0l-8.2,5.9l-0.3,0.4
            c0,0.1-0.1,0.8-0.1,1.3c0,0.5,0.1,1.2,0.1,1.3l0.3,0.4l8.1,6l0.4,0C512.4,474.1,513,471.5,513,468.8z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M498.8,473.3c0.8,0.6,2,0.4,2.6-0.4c0.6-0.8,0.4-1.9-0.4-2.6
            c-0.8-0.6-2-0.4-2.5,0.4C497.8,471.6,498,472.7,498.8,473.3"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M499.2,472.7c0.5,0.3,1.1,0.2,1.5-0.2c0.3-0.5,0.3-1.1-0.2-1.5
            c-0.5-0.3-1.1-0.2-1.5,0.2C498.6,471.7,498.7,472.4,499.2,472.7"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M500.8,485.4c2.6-0.8,4.9-2.2,6.7-3.9l-0.1-0.4l-8.1-6l-0.4-0.2
            c-0.1,0-0.8,0.2-1.3,0.3c-0.5,0.2-1.1,0.4-1.2,0.5l-0.3,0.4l-3.2,9.5l0.2,0.4C495.6,486.4,498.3,486.2,500.8,485.4z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.1,473.2c-0.3,1,0.2,2,1.2,2.3c1,0.3,2-0.2,2.3-1.2
            c0.3-1-0.2-2-1.2-2.3C493.5,471.7,492.5,472.2,492.1,473.2"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M492.9,473.4c-0.2,0.6,0.1,1.2,0.7,1.3c0.6,0.2,1.2-0.1,1.3-0.7
            c0.2-0.6-0.1-1.2-0.7-1.3C493.7,472.6,493,472.9,492.9,473.4"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.6,464.5c-2.3,0-4.2,1.8-4.2,4.1c0,2.3,1.8,4.2,4.2,4.2
            c2.3,0,4.2-1.8,4.2-4.2C499.7,466.4,497.9,464.5,495.6,464.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.5,465.8c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9
            c1.6,0,2.9-1.3,2.9-2.9C498.5,467.1,497.2,465.8,495.5,465.8z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M495.7,449.5c-10.6-0.1-19.2,8.5-19.3,19c-0.1,10.6,8.5,19.2,19,19.3
            c10.6,0.1,19.2-8.5,19.3-19C514.8,458.2,506.3,449.5,495.7,449.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M495.7,463.1c-0.3-1-1.3-1.5-2.3-1.2c-1,0.3-1.5,1.3-1.2,2.3
            c0.3,1,1.3,1.5,2.3,1.2C495.4,465,496,464,495.7,463.1z"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" d="M494.9,463.3c-0.2-0.6-0.8-0.9-1.3-0.7c-0.6,0.2-0.9,0.8-0.7,1.3
            c0.2,0.6,0.8,0.9,1.3,0.7C494.8,464.4,495.1,463.8,494.9,463.3z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M490.2,466.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
            c1,0,1.8-0.8,1.8-1.8C492,467.7,491.2,466.8,490.2,466.8z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M490.2,467.6c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1
            c0.6,0,1.1-0.5,1.1-1.1C491.3,468.1,490.8,467.6,490.2,467.6z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M492.1,473.2c-0.3,1,0.2,2,1.2,2.3c1,0.3,2-0.2,2.3-1.2
            c0.3-1-0.2-2-1.2-2.3C493.5,471.7,492.5,472.2,492.1,473.2z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M492.9,473.4c-0.2,0.6,0.1,1.2,0.7,1.3c0.6,0.2,1.2-0.1,1.3-0.7
            c0.2-0.6-0.1-1.2-0.7-1.3C493.7,472.6,493,472.9,492.9,473.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M498.8,473.3c0.8,0.6,2,0.4,2.6-0.4c0.6-0.8,0.4-1.9-0.4-2.6
            c-0.8-0.6-2-0.4-2.5,0.4C497.8,471.6,498,472.7,498.8,473.3z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M499.2,472.7c0.5,0.3,1.1,0.2,1.5-0.2c0.3-0.5,0.3-1.1-0.2-1.5
            c-0.5-0.3-1.1-0.2-1.5,0.2C498.6,471.7,498.7,472.4,499.2,472.7z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.9,467.1c0.8-0.6,1-1.7,0.4-2.6c-0.6-0.8-1.7-1-2.5-0.4
            c-0.8,0.6-1,1.7-0.4,2.5C499,467.5,500.1,467.6,500.9,467.1"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.9,467.1c0.8-0.6,1-1.7,0.4-2.6c-0.6-0.8-1.7-1-2.5-0.4
            c-0.8,0.6-1,1.7-0.4,2.5C499,467.5,500.1,467.6,500.9,467.1z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.5,466.4c0.5-0.3,0.6-1,0.2-1.5c-0.3-0.5-1-0.6-1.5-0.2
            c-0.5,0.3-0.6,1-0.2,1.5C499.4,466.7,500,466.8,500.5,466.4"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M500.5,466.4c0.5-0.3,0.6-1,0.2-1.5c-0.3-0.5-1-0.6-1.5-0.2
            c-0.5,0.3-0.6,1-0.2,1.5C499.4,466.7,500,466.8,500.5,466.4z"/>
        </g>
      </g>
    </g>
  </g></>)
  }
}