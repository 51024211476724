import { gql } from "apollo-boost";

export const GET_INSURANCE_POLICIES = gql`
  query consumerInsurancePolicies {
    consumerInsurancePolicies {
      id
      name
      description
      excess
      organisationId
      insuranceRate {
        rateName
        rateDuration
        rate
        longTerm
      }
    }
  }
`;
