import React from "react";
import { IVehiclePriceGroup } from "../../../reducers/bookings/types";
import MinusIcon from "@material-ui/icons/Remove";
import PlusIcon from "@material-ui/icons/Add";

interface IProps {
  count: number;
  maxCount: number;
  vehicleGroup: IVehiclePriceGroup;
  updateCounter: (data: any, isIncrease: boolean, vehicleId?: string) => void;
}

const VehicleCounter: React.FC<IProps> = (props) => {
  const { count, maxCount, vehicleGroup, updateCounter } = props

  const increase = () => {
    if (count < props.maxCount) {
      updateCounter(vehicleGroup, true);
    }
  };

  const decrease = () => {
    if (count > 0) {
      updateCounter(vehicleGroup, false);
    }
  };

  return (
    <div
      style={{
        width: 170,
        height: 38,
        borderRadius: 8,
        background: 'var(--background-secondary)',
        position: 'relative'
      }}
      className="flex cross-center"
    >
      {false ? <div onClick={increase} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 8, backgroundColor: 'var(--primary-color)', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', cursor: 'pointer' }}>
        Select
      </div>
        : <><div
          style={{
            width: 36,
            height: 36,
            borderRadius: 8,
            backgroundColor: count > 0 ? '#ffcfcb' : '#fff',
            color: count > 0 ? '#e00101' : '#e2e2e2',
            cursor: 'pointer',
            border: '2px solid var(--background-secondary)'
          }}
          className="flex cross-center main-center"
          onClick={decrease}
        >
          <MinusIcon style={{ fontSize: 18 }} />
        </div>
          <div
            className={`flex fill main-center text-center font-medium ${count > 0 && 'bold'}`}
          >
            Selected: {count}
          </div>
          <div
            style={{
              width: 36,
              height: 36,
              borderRadius: 8,
              backgroundColor: count === maxCount ? "#e2e2e2" : 'var(--primary-color)',
              color: '#fff',
              cursor: 'pointer',
              border: '2px solid var(--background-secondary)'
            }}
            className="flex cross-center main-center"
            onClick={increase}
          >
            <PlusIcon style={{ fontSize: 18 }} />
          </div>
        </>}
    </div>
  );
};

export default VehicleCounter;
