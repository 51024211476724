import Evaporate from 'evaporate';
import { IAuthState, IUser } from '../../reducers/auth/types';
import {
  UPDATE_AUTH_STATE,
  AuthAction,
  SET_AUTH_EVAPORATE_CLIENT,
  ADD_USER_DATA
} from "./types";

export function updateAuthStateAction(payload: IAuthState): AuthAction {
  return {
    payload,
    type: UPDATE_AUTH_STATE
  };
}

export function addUserDataAction(payload: IUser): AuthAction {
  return {
    payload,
    type: ADD_USER_DATA
  };
}


export function setAuthEvaporateClientAction(payload: { _e_: Evaporate }): AuthAction {
  return {
    type: SET_AUTH_EVAPORATE_CLIENT,
    payload
  }
}
