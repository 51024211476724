import { gql } from "apollo-boost";

export const CONSUMER_CREATE_VEHICLE_DAMAGE_INFO = gql`
  mutation consumerCreateVehicleDamage(
    $vehicleId: String!
    $vehicledamage: VehicleDamageInput!
  ) {
    consumerCreateVehicleDamage(vehicleId: $vehicleId, vehicledamage: $vehicledamage) {
      id
      vehicleId
      circumstance
      bookingRef
      reportDate
      incidentDate
      reportedBy
      frogDiagram
      approvalStatus
      estimatedCost
      status
      damageSeverity
      estimatedRepairDate
      referenceNumber
      damages {
        title
        descriptions
        damageType
        damageKind
        damageArea
        images
        location {
          x
          y
        }
      }
    }
  }
`;
