// LightBox loader

interface LightBoxLoader {
  paymentSessionToken: string;
  onloadFunction: () => void;
  redirectFunction: () => void;
}

export function loadConvergeUS(args: LightBoxLoader) {
  const { paymentSessionToken, onloadFunction, redirectFunction } = args;
  const payload = {
    ssl_txn_auth_token: paymentSessionToken,
  };
  const script = document.createElement("script");
  script.src =
    process.env.REACT_APP_CONVERGE_US_LIGHTBOX ||
    "https://api.demo.convergepay.com/hosted-payments/PayWithConverge.js";
  script.async = true;
  script.onload = () => {
    onloadFunction();
    window.PayWithConverge.open(payload, {
      onApproval: function (resp: any) {
        redirectFunction();
      },
    });
  };
  document.body.appendChild(script);
}
