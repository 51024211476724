
import React from "react"

export const Van41TonneSVG = {
    interior:{
        width:387,
        height:1020,
        svg:(<><g>
        <g>
          <rect x="41.2" y="254" fill="none" stroke="#000000" stroke-miterlimit="10" width="305" height="737"/>
          <rect x="46" y="260" fill="none" stroke="#000000" stroke-miterlimit="10" width="294.2" height="726"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M191.2,14h56c0,0,45,1,72,12c0,0,18,6,16,32l-1,163c0,0,2,15-8,16
              c0,0-18,4-131,5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M196.4,14h-56c0,0-45,1-72,12c0,0-18,6-16,32l1,163c0,0-2,15,8,16
              c0,0,20.8,4,133.8,5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M194.7,22.8h53.8c0,0,37.2,0,63.4,10.1c0,0,17.1,6,15.1,24.1
              l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M194.7,22.8H139c0,0-37.2,0-63.4,10.1c0,0-17.1,6-15.1,24.1l6,26.2
              c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M195.1,26.5h52.7c0,0,36.4,0,62,8.9c0,0,16.7,5.3,14.8,21.4
              l-6,23.2c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M195.1,26.5h-54.5c0,0-36.4,0-62,8.9c0,0-16.7,5.3-14.8,21.4
              l6,23.2c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M69.2,89v140c0,0-2,7-11,7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.2,89v140c0,0,2,7,11,7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M69.5,230c0,0,119.7,14,249.7,1"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M56.2,77c0,0,5,11,7,22v111.7c0,0.2,0,0.4,0,0.6
            c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C57.2,213,56.7,76.6,56.2,77z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M331.2,77c0,0-5,11-7,22v111.7c0,0.2,0,0.4,0,0.6
            c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C330.2,213,330.7,76.6,331.2,77z"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="74.2" y1="239" x2="74.2" y2="254"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="314.2" y1="238" x2="314.2" y2="254"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="105.2" y1="17" x2="280.2" y2="17"/>
          <rect x="42.2" y="59" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
          <rect x="335.2" y="60" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
          <g>
            <polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="8.2,80 20.2,71 32.2,71 44.2,80 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="43.2,83 9.2,83 8.2,80 44.2,80 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="27.2,83 58.2,133 61.2,133 61.2,132 30.2,83 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="33.3,83.1 52.2,100 52.2,96 37.4,83 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="62.2,129 62.2,133 58.2,133 58.2,138 62.2,138 
              62.2,142 65.2,142 65.2,129 			"/>
          </g>
          <g>
            <polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="379.2,80 367.2,71 355.2,71 343.2,80 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="344.2,83 378.2,83 379.2,80 343.2,80 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="360.2,83 329.2,133 326.2,133 326.2,132 357.2,83 			
              "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="354.1,83.1 335.2,100 335.2,96 350,83 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="325.2,129 325.2,133 329.2,133 329.2,138 325.2,138 
              325.2,142 322.2,142 322.2,129 			"/>
          </g>
        </g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M74,91"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M72.5,91c0,0,10.8,122.9,10,126c-1,4-13,13-13,13"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M314.5,91c0,0-10.8,122.9-10,126c1,4,13,13,13,13"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M82.5,217c0,0,104.1,13,222,0"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M123.7,97.7h44.2c0,0,6.5,1.2,9,12.6l0.4,28.2
          c0,0-3.3,16.1-12.4,21.2h-39.7c0,0-8.1-6.3-11.6-21v-28.9C113.5,109.8,116.8,99.1,123.7,97.7z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M178.2,154.9l-1-1.7h-5.5l-3.7,4.2l-4.4,2.3l-18.4-0.2v-0.1
          l-18.8,0.6l-4-3.1l-3-4l-6.3,0.3l-1.7,3.1l-1,8.2v25l0.8,6.5l2.3,4.4l2.9,2.8l4.5,2.2h6.2h2.7l0.2,1.8h15.2v0.1h15.2l0.2-1.8h2.7
          h6.2l4.5-2.2l2.9-2.8l2.3-4.4l0.8-6.5v-25l-0.8-6.5C179.1,156.9,178.7,155.9,178.2,154.9z"/>
        <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="145.2,188.6 126.2,188.6 126.2,205.1 129.8,209 145.2,209 
          160.5,209 164.1,205.1 164.1,188.6 	"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M221.7,98.7h44.2c0,0,6.5,1.2,9,12.6l0.4,28.2
          c0,0-3.3,16.1-12.4,21.2h-39.7c0,0-8.1-6.3-11.6-21v-28.9C211.5,110.8,214.8,100.1,221.7,98.7z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M276.2,155.9l-1-1.7h-5.5l-3.7,4.2l-4.4,2.3l-18.4-0.2v-0.1
          l-18.8,0.6l-4-3.1l-3-4l-6.3,0.3l-1.7,3.1l-1,8.2v25l0.8,6.5l2.3,4.4l2.9,2.8l4.5,2.2h6.2h2.7l0.2,1.8h15.2v0.1h15.2l0.2-1.8h2.7
          h6.2l4.5-2.2l2.9-2.8l2.3-4.4l0.8-6.5v-25l-0.8-6.5C277.1,157.9,276.7,156.9,276.2,155.9z"/>
        <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="243.2,189.6 224.2,189.6 224.2,206.1 227.8,210 243.2,210 
          258.5,210 262.1,206.1 262.1,189.6 	"/>
      </g></>)

    },

    exterior:{
        width:1346,
        height:2451,
        svg:(<><g>
        <g>
          <g>
            <g>
              <rect x="63.4" y="736.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M158.2,672.1c-2-24-18-43-38-51.8V565h-15h-4l-5-32c0-2,3-2,3-2
                h29v6h3l3-3h72l30,9c12,5,52,24,52,24l25,14l19,12c13,9,13,27,13,27v116.1c0,11-12,12.6-12,12.6c-1.4,0.3-13.7,0.4-15.2,0.4
                H210.2c-2-1-3-4-3-4h-20c-2,0-29-4-29-4V672.1"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M97.2,741.2c17,0,51-8,61-46"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M97.2,736.2c12.2-0.8,23.6-5.5,32.5-13.9
                c16.2-15.3,23.4-38.3,17.8-60.1c-5.4-20.7-21.3-38.2-42.3-43.1"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.2" y1="748.8" x2="97.2" y2="735.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="105.2" y1="565" x2="105.2" y2="619.6"/>
              <rect x="84.2" y="573" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="91.2" y1="617.1" x2="91.2" y2="573"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.1,565.1c0,0-5.9,0-6.9,0c-0.9,0-6-9.2-6.9-10.8
                c-0.1-0.2-0.1-0.3-0.1-0.5v-19.7c0-0.6,0.5-1.1,1.1-1.1h3.9"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.2,616.1c8,1,15,4,15,4"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M276.7,585l-10-3l-51-16.7c-0.6-0.2-1.3-0.3-2-0.3h-94"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.3,622.2L318.3,622.2c0-4.3-1.8-8.5-5-11.3
                c-4.8-4.1-11-7.7-11-7.7l-40-16l-29.7-8.7c-3.5-1.1-7.2-1.9-10.9-2.4c-4.9-0.7-5.8-0.3-8,0.1c-2.3,0.4-4,2.4-4.1,4.7
                c-0.1,3.9,1.1,7.6,1.5,8.8c0.1,0.3,0.3,0.7,0.4,1.1l2.4,7.2c0.3,0.7,0.5,1.4,0.7,2.2l13.7,50l16.5,46.7c0.3,0.8,0.7,1.7,1.1,2.5
                l6.6,13.3c0.7,1.3,1.5,2.5,2.5,3.6c1.4,1.5,2.9,2.8,4.4,3.8c3.6,2.6,7.9,4.1,12.3,4.7l10.5,1.4h25c10,0,12-10,12-10v-54.1
                L318.3,622.2z"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M315,624c-0.3-6.3-4.1-12-9.7-14.9L290,601l-39-14l-29-8
                c-3.1-0.6-5.2-0.6-6.7-0.3c-2.1,0.5-3.3,2.6-2.8,4.7c1.1,4.1,3.6,11.7,3.6,11.7l12,43l15,46l10.5,23.3
                c2.7,5.9,7.5,10.4,13.6,12.5c8.1,2.8,17,3.2,17,3.2h22.4c2.1,0,4.1-0.7,5.7-2.1c0.7-0.7,1.3-1.3,1.7-1.9c0.8-1,1.2-2.4,1.2-3.7
                v-90.4C315.2,624.7,315.2,624.4,315,624z"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M232.2,542l2,11c2,9,14,14,14,14l21,9l54,26c4,2,6-1,6-1l5-7"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M235.3,543l0.4,4c0.5,4,2.1,7.8,4.8,10.8c4.7,5.2,8.2,6.3,8.2,6.3
                l20.3,8.7l52.1,25.1c3.9,1.9,5.8-1,5.8-1l3.3-4.9"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M158.2,713.3l27.2,18.5c0.5,0.3,1.1,0.5,1.7,0.5h124.1
                c15,0,13.7-12.9,14-14.8c0-0.2,0-0.4,0-0.6v-91.7c0-1.5-0.1-2.8-0.2-4.1c-0.8-6.7-4.5-12.8-10-16.7l-8.8-6.3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M214.2,565c2-12-9-31-9-31"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M202.2,565c0-13-10-31-10-31"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M166.2,565c1-18-9-31-9-31"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124.2,565c-2-4-2.6-10.2-2.9-12.4c-0.1-0.4-0.1-0.8-0.1-1.2V531"
                />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M136.2,564.9c-4-6-5.3-13.4-5.8-16.5c-0.1-0.8-0.2-1.6-0.2-2.5
                V537"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321.2,601.1c0,0,16,9,17,34l1,95.1c0,0,0,12-11,12
                c-10,0-140,0-140,0v-10c0-39-5-62.1-5-62.1c-6-40-45-105.1-45-105.1"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="188.2" y1="742.2" x2="188.2" y2="745.2"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M135.2,565c31,52.1,41,85.1,41,85.1c11,41,9,81.6,9,81.6v13"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M132.2,565c20,35,29,57.1,29,57.1c19,42,19,76.1,19,76.1v30v14
                l-22-3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M192.8,696.1c2.2-3.8,8.2,1.4,11.4,4.7c1.3,1.4,2.1,3.2,2.1,5.2
                v16.8c0,1.8-1.5,3.3-3.3,3.3h-7.5c-1.8,0-3.3-1.5-3.3-3.3v-24.7C192.2,697.5,192.3,696.7,192.8,696.1z"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="199.2" y1="726.2" x2="199.2" y2="696.6"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M103.2,536h12c0.5,0,1-0.5,1-1v-1c0-0.6-0.5-1-1-1h-12
                c-0.6,0-1,0.4-1,1v1C102.2,535.5,102.6,536,103.2,536z"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M267.2,586l-10.9,8.1c-0.4,0.3-0.2,0.9,0.3,0.9h53.3
                c0.7,0,1-0.9,0.4-1.3l-11.1-7.7H267.2z"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M256.3,595l1.7,2.6c0.1,0.2,0.5,0.4,0.8,0.4h49
                c0.3,0,0.5-0.2,0.7-0.4l1.7-2.6H256.3z"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M260.2,591h-9.3c-0.5,0-1,0.2-1.3,0.5l-20,18.1
                c-0.4,0.2-0.8,0.4-1.2,0.4h-27.3v-3h26.2c0.6,0,1.1-0.2,1.5-0.6l19.4-18c0.3-0.2,0.7-0.4,1.1-0.4h14.5h0.4L260.2,591z"/>
              <rect x="196.2" y="604.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M305.2,590h16l5.3,3.6c0.5,0.3,0.7,0.7,0.7,1.2v48.2h2v-47.9
                c0-0.8-0.3-1.7-1-2.3c-2.3-2.1-7-4.8-7-4.8h-19L305.2,590z"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M327.2,595"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.2" y1="996.8" x2="134.2" y2="1266.8"/>
              <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="134.2,1673.8 134.2,1858.8 153.2,1858.8 153.2,730.8 
                134.2,730.8 134.2,900.8 				"/>
              <rect x="127.2" y="1858.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="157.7" y1="730.2" x2="152.7" y2="730.2"/>
              <rect x="88.2" y="1021.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="220.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="105.2" y1="1242.5" x2="105.2" y2="1021.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.2" y1="1242" x2="93.2" y2="1021.8"/>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="133.8" y1="1086.3" x2="88.6" y2="1086.3"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.6" y1="1089.1" x2="88.4" y2="1089.1"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.6" y1="1169.2" x2="88.6" y2="1169.4"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.6" y1="1172.2" x2="88.6" y2="1172.2"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="133.6" y1="1230.3" x2="87.6" y2="1230.5"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="133.6" y1="1233.3" x2="87.6" y2="1233.3"/>
              </g>
              <rect x="88.2" y="1697.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="143.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="105.2" y1="1841.5" x2="105.2" y2="1697.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.2" y1="1841.2" x2="93.2" y2="1697.8"/>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="133.8" y1="1739.8" x2="88.6" y2="1739.8"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.6" y1="1741.6" x2="88.4" y2="1741.6"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.6" y1="1793.7" x2="88.6" y2="1793.9"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.6" y1="1795.7" x2="88.6" y2="1795.7"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="133.6" y1="1833.5" x2="87.6" y2="1833.7"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="133.6" y1="1835.5" x2="87.6" y2="1835.5"/>
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.2" y1="1868.7" x2="97.2" y2="1840.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.2" y1="1247.3" x2="97.2" y2="1242.5"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.2" y1="1698.3" x2="97.2" y2="1693.5"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="95.2" y1="1022.3" x2="95.2" y2="1017.5"/>
              <rect x="93.2" y="1868.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.2" y1="1858.7" x2="134.2" y2="1858.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="104.2" y1="1868.7" x2="104.2" y2="1858.7"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.7,1384.6c15.7,0,30.9-5.6,42.9-15.7
                c21.9-18.4,30.1-49.6,20.7-76.6c-9.3-26.7-34.7-45.6-63.1-45.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M87.2,1376.6c14-0.8,27-5.3,38-14.2
                c19.2-15.6,26.1-42.7,18.5-66.1c-7.9-24.5-31.1-40.7-56.5-41.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.2" y1="1255" x2="87.2" y2="1246"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.2" y1="1018" x2="86.2" y2="1009"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.2" y1="888" x2="86.2" y2="879"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.2" y1="1376.1" x2="87.2" y2="1384.6"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M87.2,1531.6c14-0.8,27-5.3,38-14.2
                c19.2-15.6,26.1-42.7,18.5-66.1c-7.9-24.5-31.1-40.7-56.5-41.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.2" y1="1410" x2="87.2" y2="1401"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.2" y1="1531.1" x2="87.2" y2="1539.6"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M87.2,1685.6c14-0.8,27-5.3,38-14.2
                c19.2-15.6,26.1-42.7,18.5-66.1c-7.9-24.5-31.1-40.7-56.5-41.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.2" y1="1564" x2="87.2" y2="1555"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.2" y1="1685.1" x2="87.2" y2="1693.6"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="86.2" cy="1314.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="86.2" cy="1315.4" rx="31" ry="30.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M85.7,1017.6c15.7,0,30.9-5.6,42.9-15.7
                c21.9-18.4,30.1-49.6,20.7-76.6c-9.3-26.7-34.7-45.6-63.1-45.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.2,1009.6c14-0.8,27-5.3,38-14.2
                c19.2-15.6,26.1-42.7,18.5-66.1c-7.9-24.5-31.1-40.7-56.5-41.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="85.2" cy="947.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="85.2" cy="948.4" rx="31" ry="30.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="87.2" cy="676.1" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="87.2" cy="676.9" rx="31" ry="30.8"/>
              <rect x="64.2" y="1378.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.7,1539.6c15.7,0,30.9-5.6,42.9-15.7
                c21.9-18.4,30.1-49.6,20.7-76.6c-9.3-26.7-34.7-45.6-63.1-45.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="86.2" cy="1469.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="86.2" cy="1470.4" rx="31" ry="30.8"/>
              <rect x="64.2" y="1533.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.7,1693.6c15.7,0,30.9-5.6,42.9-15.7
                c21.9-18.4,30.1-49.6,20.7-76.6c-9.3-26.7-34.7-45.6-63.1-45.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="86.2" cy="1623.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="86.2" cy="1624.4" rx="31" ry="30.8"/>
              <rect x="64.2" y="1687.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <rect x="63.2" y="1011.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <rect x="153.2" y="756.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="285.7" height="1146.5"/>
              <rect x="160" y="762.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="273" height="1132.5"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.5,879.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.5,887.8"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M329.6,749h105.6c1.7,0,3-1.6,2.6-3.3
              c-4.3-17.6-25.5-100.4-29.2-111.4c-4.5-13.4-67.5-30.4-67.5-30.4"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.5,868.7V752.8c0-1.9,1.6-3.5,3.5-3.5H124c1.9,0,3.5,1.6,3.5,3.5
            v115.9c0,1.9-1.6,3.5-3.5,3.5H90C88.1,872.3,86.5,870.7,86.5,868.7z"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="127.5" y1="767.3" x2="86.5" y2="767.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="128" y1="788.3" x2="87" y2="788.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="128" y1="809.3" x2="87" y2="809.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="128" y1="829.3" x2="87" y2="829.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="128" y1="851.3" x2="87" y2="851.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.5" y1="872.8" x2="97.5" y2="880.8"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="127.5" y1="781.3" x2="134.5" y2="781.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="127" y1="839.3" x2="134" y2="839.3"/>
        </g>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.5" y1="1576.3" x2="134.5" y2="1519.3"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="134.5" y1="1421.8" x2="134.5" y2="1364.8"/>
      </g>
      <g>
        <g>
          <g>
            <g>
              <rect x="1233.8" y="736.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1173,672.1c2-24,18-43,38-51.8V565h15h4l5-32c0-2-3-2-3-2h-29v6
                h-3l-3-3h-72l-30,9c-12,5-52,24-52,24l-25,14l-19,12c-13,9-13,27-13,27v116.1c0,11,12,12.6,12,12.6c1.4,0.3,13.7,0.4,15.2,0.4
                H1121c2-1,3-4,3-4h20c2,0,29-4,29-4V672.1"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1234,741.2c-17,0-51-8-61-46"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1234,736.2c-12.2-0.8-23.6-5.5-32.5-13.9
                c-16.2-15.3-23.4-38.3-17.8-60.1c5.4-20.7,21.3-38.2,42.3-43.1"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1234" y1="748.8" x2="1234" y2="735.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1226" y1="565" x2="1226" y2="619.6"/>
              <rect x="1226" y="573" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1240" y1="617.1" x2="1240" y2="573"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1226.1,565.1c0,0,5.9,0,6.9,0c0.9,0,6-9.2,6.9-10.8
                c0.1-0.2,0.1-0.3,0.1-0.5v-19.7c0-0.6-0.5-1.1-1.1-1.1h-3.9"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1226,616.1c-8,1-15,4-15,4"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1054.5,585l10-3l51-16.7c0.6-0.2,1.3-0.3,2-0.3h94"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1012.9,622.2L1012.9,622.2c0-4.3,1.8-8.5,5-11.3
                c4.8-4.1,11-7.7,11-7.7l40-16l29.7-8.7c3.5-1.1,7.2-1.9,10.9-2.4c4.9-0.7,5.8-0.3,8,0.1c2.3,0.4,4,2.4,4.1,4.7
                c0.1,3.9-1.1,7.6-1.5,8.8c-0.1,0.3-0.3,0.7-0.4,1.1l-2.4,7.2c-0.3,0.7-0.5,1.4-0.7,2.2l-13.7,50l-16.5,46.7
                c-0.3,0.8-0.7,1.7-1.1,2.5l-6.6,13.3c-0.7,1.3-1.5,2.5-2.5,3.6c-1.4,1.5-2.9,2.8-4.4,3.8c-3.6,2.6-7.9,4.1-12.3,4.7l-10.5,1.4
                h-25c-10,0-12-10-12-10v-54.1L1012.9,622.2z"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1016.2,624c0.3-6.3,4.1-12,9.7-14.9l15.3-8.1l39-14l29-8
                c3.1-0.6,5.2-0.6,6.7-0.3c2.1,0.5,3.3,2.6,2.8,4.7c-1.1,4.1-3.6,11.7-3.6,11.7l-12,43l-15,46l-10.5,23.3
                c-2.7,5.9-7.5,10.4-13.6,12.5c-8.1,2.8-17,3.2-17,3.2h-22.4c-2.1,0-4.1-0.7-5.7-2.1c-0.7-0.7-1.3-1.3-1.7-1.9
                c-0.8-1-1.2-2.4-1.2-3.7v-90.4C1016,624.7,1016,624.4,1016.2,624z"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1099,542l-2,11c-2,9-14,14-14,14l-21,9l-54,26c-4,2-6-1-6-1l-5-7
                "/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1095.9,543l-0.4,4c-0.5,4-2.1,7.8-4.8,10.8
                c-4.7,5.2-8.2,6.3-8.2,6.3l-20.3,8.7l-52.1,25.1c-3.9,1.9-5.8-1-5.8-1l-3.3-4.9"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1173,713.3l-27.2,18.5c-0.5,0.3-1.1,0.5-1.7,0.5H1020
                c-15,0-13.7-12.9-14-14.8c0-0.2,0-0.4,0-0.6v-91.7c0-1.5,0.1-2.8,0.2-4.1c0.8-6.7,4.5-12.8,10-16.7l8.8-6.3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1117,565c-2-12,9-31,9-31"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1129,565c0-13,10-31,10-31"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1165,565c-1-18,9-31,9-31"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1207,565c2-4,2.6-10.2,2.9-12.4c0.1-0.4,0.1-0.8,0.1-1.2V531"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1195,564.9c4-6,5.3-13.4,5.8-16.5c0.1-0.8,0.2-1.6,0.2-2.5V537"
                />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1010,601.1c0,0-16,9-17,34l-1,95.1c0,0,0,12,11,12
                c10,0,140,0,140,0v-10c0-39,5-62.1,5-62.1c6-40,45-105.1,45-105.1"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1143" y1="742.2" x2="1143" y2="745.2"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1196,565c-31,52.1-41,85.1-41,85.1c-11,41-9,81.6-9,81.6v13"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1199,565c-20,35-29,57.1-29,57.1c-19,42-19,76.1-19,76.1v30v14
                l22-3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1138.4,696.1c-2.2-3.8-8.2,1.4-11.4,4.7
                c-1.3,1.4-2.1,3.2-2.1,5.2v16.8c0,1.8,1.5,3.3,3.3,3.3h7.5c1.8,0,3.3-1.5,3.3-3.3v-24.7C1139,697.5,1138.9,696.7,1138.4,696.1z"
                />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1132" y1="726.2" x2="1132" y2="696.6"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1228,536h-12c-0.5,0-1-0.5-1-1v-1c0-0.6,0.5-1,1-1h12
                c0.6,0,1,0.4,1,1v1C1229,535.5,1228.6,536,1228,536z"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M1064,586l10.9,8.1c0.4,0.3,0.2,0.9-0.3,0.9h-53.3
                c-0.7,0-1-0.9-0.4-1.3l11.1-7.7H1064z"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M1074.9,595l-1.7,2.6c-0.1,0.2-0.5,0.4-0.8,0.4h-49
                c-0.3,0-0.5-0.2-0.7-0.4l-1.7-2.6H1074.9z"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M1071,591h9.3c0.5,0,1,0.2,1.3,0.5l20,18.1
                c0.4,0.2,0.8,0.4,1.2,0.4h27.3v-3h-26.2c-0.6,0-1.1-0.2-1.5-0.6l-19.4-18c-0.3-0.2-0.7-0.4-1.1-0.4h-14.5h-0.4L1071,591z"/>
              <rect x="1120" y="604.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9"/>
              <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M1026,590h-16l-5.3,3.6c-0.5,0.3-0.7,0.7-0.7,1.2v48.2h-2
                v-47.9c0-0.8,0.3-1.7,1-2.3c2.3-2.1,7-4.8,7-4.8h19L1026,590z"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1004,595"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1197" y1="996.8" x2="1197" y2="1266.8"/>
              <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="1197,1673.8 1197,1858.8 1178,1858.8 1178,730.8 
                1197,730.8 1197,900.8 				"/>
              <rect x="1178" y="1858.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1173.5" y1="730.2" x2="1178.5" y2="730.2"/>
              <rect x="1221" y="1021.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="220.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1226" y1="1242.5" x2="1226" y2="1021.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1238" y1="1242" x2="1238" y2="1021.8"/>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1197.4" y1="1086.3" x2="1242.6" y2="1086.3"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.6" y1="1089.1" x2="1242.8" y2="1089.1"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.6" y1="1169.2" x2="1242.6" y2="1169.4"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.6" y1="1172.2" x2="1242.6" y2="1172.2"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1197.6" y1="1230.3" x2="1243.6" y2="1230.5"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1197.6" y1="1233.3" x2="1243.6" y2="1233.3"/>
              </g>
              <rect x="1221" y="1697.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="143.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1226" y1="1841.5" x2="1226" y2="1697.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1238" y1="1841.2" x2="1238" y2="1697.8"/>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1197.4" y1="1739.8" x2="1242.6" y2="1739.8"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.6" y1="1741.6" x2="1242.8" y2="1741.6"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.6" y1="1793.7" x2="1242.6" y2="1793.9"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.6" y1="1795.7" x2="1242.6" y2="1795.7"/>
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1197.6" y1="1833.5" x2="1243.6" y2="1833.7"/>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1197.6" y1="1835.5" x2="1243.6" y2="1835.5"/>
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1234" y1="1868.7" x2="1234" y2="1840.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1234" y1="1247.3" x2="1234" y2="1242.5"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1234" y1="1698.3" x2="1234" y2="1693.5"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1236" y1="1022.3" x2="1236" y2="1017.5"/>
              <rect x="1222" y="1868.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1234" y1="1858.7" x2="1197" y2="1858.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1227" y1="1868.7" x2="1227" y2="1858.7"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244.5,1384.6c-15.7,0-30.9-5.6-42.9-15.7
                c-21.9-18.4-30.1-49.6-20.7-76.6c9.3-26.7,34.7-45.6,63.1-45.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244,1376.6c-14-0.8-27-5.3-38-14.2
                c-19.2-15.6-26.1-42.7-18.5-66.1c7.9-24.5,31.1-40.7,56.5-41.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1244" y1="1255" x2="1244" y2="1246"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1245" y1="1018" x2="1245" y2="1009"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1245" y1="888" x2="1245" y2="879"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1244" y1="1376.1" x2="1244" y2="1384.6"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244,1531.6c-14-0.8-27-5.3-38-14.2
                c-19.2-15.6-26.1-42.7-18.5-66.1c7.9-24.5,31.1-40.7,56.5-41.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1244" y1="1410" x2="1244" y2="1401"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1244" y1="1531.1" x2="1244" y2="1539.6"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244,1685.6c-14-0.8-27-5.3-38-14.2
                c-19.2-15.6-26.1-42.7-18.5-66.1c7.9-24.5,31.1-40.7,56.5-41.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1244" y1="1564" x2="1244" y2="1555"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1244" y1="1685.1" x2="1244" y2="1693.6"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1245" cy="1314.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1245" cy="1315.4" rx="31" ry="30.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1245.5,1017.6c-15.7,0-30.9-5.6-42.9-15.7
                c-21.9-18.4-30.1-49.6-20.7-76.6c9.3-26.7,34.7-45.6,63.1-45.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1245,1009.6c-14-0.8-27-5.3-38-14.2
                c-19.2-15.6-26.1-42.7-18.5-66.1c7.9-24.5,31.1-40.7,56.5-41.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1246" cy="947.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1246" cy="948.4" rx="31" ry="30.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1244" cy="676.1" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1244" cy="676.9" rx="31" ry="30.8"/>
              <rect x="1244" y="1378.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244.5,1539.6c-15.7,0-30.9-5.6-42.9-15.7
                c-21.9-18.4-30.1-49.6-20.7-76.6c9.3-26.7,34.7-45.6,63.1-45.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1245" cy="1469.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1245" cy="1470.4" rx="31" ry="30.8"/>
              <rect x="1244" y="1533.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244.5,1693.6c-15.7,0-30.9-5.6-42.9-15.7
                c-21.9-18.4-30.1-49.6-20.7-76.6c9.3-26.7,34.7-45.6,63.1-45.8"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1245" cy="1623.6" rx="54" ry="55.1"/>
              <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1245" cy="1624.4" rx="31" ry="30.8"/>
              <rect x="1244" y="1687.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <rect x="1245" y="1011.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
              <rect x="892.2" y="756.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="285.7" height="1146.5"/>
              <rect x="898.2" y="762.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="273" height="1132.5"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244.7,879.8"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244.7,887.8"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1001.6,749H896c-1.7,0-3-1.6-2.6-3.3
              c4.3-17.6,25.5-100.4,29.2-111.4c4.5-13.4,67.5-30.4,67.5-30.4"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1244.7,868.7V752.8c0-1.9-1.6-3.5-3.5-3.5h-33.9
            c-1.9,0-3.5,1.6-3.5,3.5v115.9c0,1.9,1.6,3.5,3.5,3.5h33.9C1243.2,872.3,1244.7,870.7,1244.7,868.7z"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1203.7" y1="767.3" x2="1244.7" y2="767.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1203.2" y1="788.3" x2="1244.2" y2="788.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1203.2" y1="809.3" x2="1244.2" y2="809.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1203.2" y1="829.3" x2="1244.2" y2="829.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1203.2" y1="851.3" x2="1244.2" y2="851.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1233.7" y1="872.8" x2="1233.7" y2="880.8"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1203.7" y1="781.3" x2="1196.7" y2="781.3"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1204.2" y1="839.3" x2="1197.2" y2="839.3"/>
        </g>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.7" y1="1576.3" x2="1196.7" y2="1519.3"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1196.7" y1="1421.8" x2="1196.7" y2="1364.8"/>
      </g>
      <g id="Layer_4">
        <g>
          <rect x="511.3" y="763.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="305" height="1136.4"/>
          <rect x="522" y="775" fill="none" stroke="#000000" stroke-miterlimit="10" width="282.6" height="1114"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M661.3,523.6h56c0,0,45,1,72,12c0,0,18,6,16,32l-1,163
              c0,0,2,15-8,16c0,0-18,4-131,5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M666.5,523.6h-56c0,0-45,1-72,12c0,0-18,6-16,32l1,163
              c0,0-2,15,8,16c0,0,20.8,4,133.8,5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.8,532.4h53.8c0,0,37.2,0,63.4,10.1c0,0,17.1,6,15.1,24.1
              l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.8,532.4h-55.7c0,0-37.2,0-63.4,10.1c0,0-17.1,6-15.1,24.1
              l6,26.2c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M665.2,536.1h52.7c0,0,36.4,0,62,8.9c0,0,16.7,5.3,14.8,21.4
              l-5.9,23.2c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M665.2,536.1h-54.5c0,0-36.4,0-62,8.9c0,0-16.7,5.3-14.8,21.4
              l5.9,23.2c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M539.3,598.6v140c0,0-2,7-11,7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M788.3,598.6v140c0,0,2,7,11,7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M539.6,739.6c0,0,119.7,14,249.7,1"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M526.3,586.6c0,0,5,11,7,22v111.7c0,0.2,0,0.4,0,0.6
            c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C527.3,722.6,526.8,586.1,526.3,586.6z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M801.3,586.6c0,0-5,11-7,22v111.7c0,0.2,0,0.4,0,0.6
            c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C800.3,722.6,800.9,586.1,801.3,586.6z"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="544.3" y1="748.6" x2="544.3" y2="763.6"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="784.3" y1="747.6" x2="784.3" y2="763.6"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="575.3" y1="526.6" x2="750.3" y2="526.6"/>
          <rect x="512.3" y="568.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
          <rect x="805.3" y="569.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
          <g>
            <polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="478.3,589.6 490.3,580.6 502.3,580.6 514.3,589.6 			
              "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="513.3,592.6 479.3,592.6 478.3,589.6 514.3,589.6 			
              "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="497.3,592.6 528.3,642.6 531.3,642.6 531.3,641.6 
              500.3,592.6 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="503.4,592.7 522.3,609.6 522.3,605.6 507.5,592.6 			
              "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="532.3,638.6 532.3,642.6 528.3,642.6 528.3,647.6 
              532.3,647.6 532.3,651.6 535.3,651.6 535.3,638.6 			"/>
          </g>
          <g>
            <polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="849.3,589.6 837.3,580.6 825.3,580.6 813.3,589.6 			
              "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="814.3,592.6 848.3,592.6 849.3,589.6 813.3,589.6 			
              "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="830.3,592.6 799.3,642.6 796.3,642.6 796.3,641.6 
              827.3,592.6 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="824.2,592.7 805.3,609.6 805.3,605.6 820.1,592.6 			
              "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="795.3,638.6 795.3,642.6 799.3,642.6 799.3,647.6 
              795.3,647.6 795.3,651.6 792.3,651.6 792.3,638.6 			"/>
          </g>
        </g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M544.2,600.6"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M542.6,600.5c0,0,10.8,122.9,10,126c-1,4-13,13-13,13"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M784.6,600.5c0,0-10.8,122.9-10,126c1,4,13,13,13,13"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M552.6,726.5c0,0,104.1,13,222,0"/>
      </g>
      <g id="Layer_2_00000128465917794039431330000007439254303631224460_">
        <g>
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M520.9,2343.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
              c1.5,0,2.7-1.4,2.7-3.1v-34.4H520.9z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M557.9,2343.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
              c1.5,0,2.7-1.4,2.7-3.1v-34.4H557.9z"/>
          </g>
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M737.9,2343.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
              c1.5,0,2.7-1.4,2.7-3.1v-34.4H737.9z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M774.9,2343.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
              c1.5,0,2.7-1.4,2.7-3.1v-34.4H774.9z"/>
          </g>
          <rect x="510.9" y="1951.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="308" height="331"/>
          <rect x="515.9" y="1962.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="298" height="286"/>
          <rect x="519.9" y="1965.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279"/>
          <rect x="666.9" y="1965.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="510.9" y1="2255.8" x2="817.9" y2="2255.8"/>
          <rect x="521.9" y="2258.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20"/>
          <rect x="755.9" y="2258.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20"/>
          <rect x="623.9" y="2259.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="73" height="17"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="510.9" y1="1954.8" x2="818.9" y2="1954.8"/>
          <rect x="519.9" y="1954.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4"/>
          <rect x="794.9" y="1954.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4"/>
          <g>
            <rect x="510.9" y="1991.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
            <rect x="512" y="2001.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
          </g>
          <g>
            <rect x="510.9" y="2097.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
            <rect x="512" y="2107.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
          </g>
          <g>
            <rect x="510.9" y="2204.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
            <rect x="512" y="2214.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
          </g>
          <g>
            <rect x="815" y="1991.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
            <rect x="795.9" y="2001.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
          </g>
          <g>
            <rect x="815" y="2097.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
            <rect x="795.9" y="2107.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
          </g>
          <g>
            <rect x="815" y="2204.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
            <rect x="795.9" y="2214.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M652.3,2179.8h-21.9c-0.9,0-1.6-0.7-1.6-1.6v-48.9
              c0-0.9,0.7-1.6,1.6-1.6h21.9c0.9,0,1.6,0.7,1.6,1.6v48.9C653.9,2179,653.2,2179.8,652.3,2179.8z"/>
            <g>
              <rect x="642.4" y="2133.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="648.1" y1="2134.4" x2="648.1" y2="2173.6"/>
            </g>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M676.5,2179.8h21.9c0.9,0,1.6-0.7,1.6-1.6v-48.9
              c0-0.9-0.7-1.6-1.6-1.6h-21.9c-0.9,0-1.6,0.7-1.6,1.6v48.9C674.9,2179,675.6,2179.8,676.5,2179.8z"/>
            <g>
              <rect x="679.9" y="2133.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="680.7" y1="2134.4" x2="680.7" y2="2173.6"/>
            </g>
          </g>
          <rect x="517.9" y="2282.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61"/>
          <rect x="734.9" y="2282.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="595.9" y1="2338.8" x2="734.9" y2="2338.8"/>
          <rect x="520.9" y="2304.8" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="290" height="15"/>
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M510.9,2153.8v-56h-11c0,0-4,0-7.4,1c-2.7,0.8-4.6,3.3-4.6,6.1
                v24.9"/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M510.9,2097.8v56h-11c0,0-4,0-7.4-1c-2.7-0.8-4.6-3.3-4.6-6.1
                v-24.9"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M499.9,2153.8v6.7c0,0.9,0.3,1.7,0.9,2.3l10.1,10.1v-5l-6.3-6.3
              c-0.4-0.4-0.7-1-0.7-1.6v-6L499.9,2153.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.9,2097.8v-11.2c0-0.5,0.3-0.9,0.7-1.1l6.3-2.7v-3l-8.6,3.4
              c-0.9,0.3-1.4,1.2-1.4,2.1v12.5H503.9L503.9,2097.8z"/>
          </g>
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M818.9,2153.8v-56h11c0,0,4,0,7.4,1c2.7,0.8,4.6,3.3,4.6,6.1v24.9
                "/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M818.9,2097.8v56h11c0,0,4,0,7.4-1c2.7-0.8,4.6-3.3,4.6-6.1v-24.9
                "/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M829.9,2153.8v6.7c0,0.9-0.3,1.7-0.9,2.3l-10.1,10.1v-5l6.3-6.3
              c0.4-0.4,0.7-1,0.7-1.6v-6L829.9,2153.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M825.9,2097.8v-11.2c0-0.5-0.3-0.9-0.7-1.1l-6.3-2.7v-3l8.6,3.4
              c0.9,0.3,1.4,1.2,1.4,2.1v12.5H825.9L825.9,2097.8z"/>
          </g>
        </g>
      </g>
      <g id="Layer_3">
        <g>
          <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="529.3,334.1 527.8,334.1 527.8,48.9 801.8,48.9 
            801.8,334.1 800.3,334.1 		"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.6,140.1l99.5,1c0,0,22-1,25,17l4,24l4,35l2,36l1,73v8
              c0,0-1,16-2,20l1,9c0,0,1,13-2,22l-1,8c0,0-3,12-11,12h-32c0,0-8,1-12-7h-76.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.6,140.1l-99.5,1c0,0-22-1-25,17l-4,24l-4,35l-2,36l-1,73v8
              c0,0,1,16,2,20l-1,9c0,0-1,13,2,22l1,8c0,0,3,12,11,12h32c0,0,8,1,12-7h76.5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.1,155.1l104.3,2c10.3,0,17.7,8,18.7,18.7l6,62.3
              c0,10.3,1,19-21,19l-108,2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.1,155.1l-104.3,2c-10.3,0-17.7,8-18.7,18.7l-6,62.3
              c0,10.3-1,19,21,19l108,2"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.4,158.1l102.9,1.9c10.1,0,17.4,7.6,18.4,17.7l5.9,59
              c0,9.7,1,18-20.7,18l-106.5,1.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.4,158.1L561.5,160c-10.1,0-17.4,7.6-18.4,17.7l-5.9,59
              c0,9.7-1,18,20.7,18l106.5,1.9"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.6,330.1h59.5c6,0,8,3,8,3c-18,10-18,27-18,27h-49.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M664.6,330.1h-59.5c-6,0-8,3-8,3c18,10,18,27,18,27h50.5"/>
          </g>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="598.1" y1="332.1" x2="731.1" y2="332.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="598.6" y1="334.1" x2="730.1" y2="334.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="601.6" y1="336.1" x2="728.1" y2="336.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="604.6" y1="338.1" x2="725.1" y2="338.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="606.6" y1="340.1" x2="723.1" y2="340.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="607.6" y1="342.1" x2="721.1" y2="342.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="609.6" y1="344.1" x2="720.1" y2="344.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="610.6" y1="346.1" x2="718.1" y2="346.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="611.6" y1="348.1" x2="717.1" y2="348.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="612.6" y1="350.1" x2="716.1" y2="350.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="613.6" y1="352.1" x2="716.1" y2="352.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="613.6" y1="354.1" x2="715.1" y2="354.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="614.6" y1="356.1" x2="715.1" y2="356.1"/>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="614.6" y1="358.1" x2="714.1" y2="358.1"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M661.1,374.1h74c0,0-1,12-7,15h-67"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M668.6,374.1h-74c0,0,1,12,7,15h67"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M530.1,273.1c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M799.1,273.1c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M530.1,286.1c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M799.1,286.1c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3"/>
          </g>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="575.1" y1="281.1" x2="583.1" y2="294.1"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="754.4" y1="281.3" x2="746.4" y2="294.3"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M531.1,356.1c0,0,4,6,13,6h123"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M530.1,364.1c0,0,4.5,6,13.5,6h123"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M798.1,356.1c0,0-4,6-13,6h-123"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M799.1,364.1c0,0-4.5,6-13.5,6h-123"/>
          </g>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="533.1" y1="393.1" x2="796.1" y2="393.1"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="554.1" y1="326.1" x2="554.1" y2="292.1"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="775.1" y1="326.1" x2="775.1" y2="292.1"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M612.1,357.1c0,0-2-23-34-28l-46-6v22.5c0,3.9,1.9,7.8,5.2,9.9
            c1.5,1,3.4,1.6,5.8,1.6C543.1,357.1,612.4,357.3,612.1,357.1z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M717.1,357.1c0,0,2-23,34-28l46-6v22.5c0,3.9-1.9,7.8-5.2,9.9
            c-1.5,1-3.4,1.6-5.8,1.6C786.1,357.1,716.8,357.3,717.1,357.1z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M582.8,388.1h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
            c0-1.3,1.1-2.4,2.4-2.4h16.3c1.3,0,2.4,1.1,2.4,2.4v8.3C585.1,387,584,388.1,582.8,388.1z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M763.8,387.1h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
            c0-1.3,1.1-2.4,2.4-2.4h16.3c1.3,0,2.4,1.1,2.4,2.4v8.3C766.1,386,765,387.1,763.8,387.1z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M531.1,379.1v75c0,0,0,0,0,0.1c0.1,2.2,1.9,3.9,4.1,3.9h27.9
            c0,0,4,0,4-3v-50"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M798.1,379.1v75c0,0,0,0,0,0.1c-0.1,2.2-1.9,3.9-4.1,3.9h-27.9
            c0,0-4,0-4-3v-50"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="567.1" y1="424.1" x2="762.1" y2="424.1"/>
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M511.4,233.1h-19.5c-3.7,0-6.7-3-6.7-6.7v-42.5
              c0-3.7,3-6.7,6.7-6.7h19.5c3.7,0,6.7,3,6.7,6.7v42.4C518.1,230,515.1,233.1,511.4,233.1z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M501.1,216.1L501.1,216.1c-1.1,0-2-0.9-2-2v-19c0-1.1,0.9-2,2-2
              l0,0c1.1,0,2,0.9,2,2v19C503.1,215.2,502.2,216.1,501.1,216.1z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M503.1,177.1v-11.2c0-0.5,0.2-1,0.6-1.3l2.9-2.2
              c0.3-0.2,0.7-0.4,1.1-0.4h31.4l1-4H508c-0.6,0-1.1,0.2-1.5,0.5l-5.6,4c-0.5,0.3-0.7,0.8-0.7,1.4v13.2H503.1z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M500.6,233.2v6.7c0,0.8,0.3,1.5,0.9,2.1l19.7,19.2
              c0.6,0.6,0.9,1.4,0.9,2.3v16.7h3v-19.7c0-0.8-0.3-1.6-0.9-2.2l-18.1-17.2c-0.6-0.6-1-1.4-1-2.3v-5.7L500.6,233.2z"/>
            <rect x="520.1" y="280.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11"/>
          </g>
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M817.9,233.1h19.5c3.7,0,6.7-3,6.7-6.7v-42.5
              c0-3.7-3-6.7-6.7-6.7h-19.5c-3.7,0-6.7,3-6.7,6.7v42.4C811.1,230,814.1,233.1,817.9,233.1z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M828.1,216.1L828.1,216.1c1.1,0,2-0.9,2-2v-19c0-1.1-0.9-2-2-2
              l0,0c-1.1,0-2,0.9-2,2v19C826.1,215.2,827,216.1,828.1,216.1z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M826.1,177.1v-11.2c0-0.5-0.2-1-0.6-1.3l-2.9-2.2
              c-0.3-0.2-0.7-0.4-1.1-0.4h-31.4l-1-4h32.1c0.6,0,1.1,0.2,1.5,0.5l5.6,4c0.5,0.3,0.7,0.8,0.7,1.4v13.2H826.1z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M828.7,233.2v6.7c0,0.8-0.3,1.5-0.9,2.1l-19.7,19.2
              c-0.6,0.6-0.9,1.4-0.9,2.3v16.7h-3v-19.7c0-0.8,0.3-1.6,0.9-2.2l18.1-17.2c0.6-0.6,1-1.4,1-2.3v-5.7L828.7,233.2z"/>
            <rect x="800.1" y="280.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11"/>
          </g>
        </g>
        <g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M545.8,146.8l5.3-87c0,0,0-5,6.4-5h105"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M780.8,145.8l-5.3-86c0,0,0-5-6.4-5H661.4"/>
        </g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M741.5,132.7l9.8-26.1c0.4-1.1,1.5-1.8,2.6-1.8h16
          c1.2,0,2.2,1,2.1,2.3l-0.9,17c-0.1,2-1.5,3.6-3.5,4l-25.8,5.2C741.6,133.2,741.4,133,741.5,132.7z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M585,132.7l-9.8-26.1c-0.4-1.1-1.5-1.8-2.6-1.8h-16
          c-1.2,0-2.2,1-2.1,2.3l0.9,17c0.1,2,1.5,3.6,3.5,4l25.8,5.2C584.9,133.2,585.1,133,585,132.7z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M584.3,132.8c0,0,74.2,4.9,158,0"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M554.3,107.8c0,0,0-40-0.2-52"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M772.1,107.8c0,0,2.2-31,2.2-51"/>
      </g></>)
    },

}