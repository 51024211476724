import { Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { ICustomerDetails } from "../../../reducers/bookings/types";


interface IProps {
  dataOptions: any;
  isDriver?: boolean;
  values?: ICustomerDetails;
  disabled?: boolean;
  updateData: (data: any) => void;
}

export const SearchForm = (props: IProps) => {

  return (
    <Grid xs={12} md={12}sm={12}>
      <Autocomplete
        id="combo-box-demo"
        fullWidth
        onChange={(event: any, newValue: any) => {
          if (newValue) {
            props.updateData(newValue);
          }
        }}
        options={props.dataOptions}
        disabled={props.disabled}
        getOptionLabel={(option: any) =>
          `${option.firstName} ${option.lastName} ${option.email}`
        }
        value={props.values}
        style={{ width: "100%" }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label="Search for existing driver"
            variant="outlined"
            value={props.values ?
              `${props.values.firstName} ${props.values.lastName} ${props.values.email}` :
              ""
            }
          />
        )}
      />
      <Box mt={2}></Box>
    </Grid>
  );
};
