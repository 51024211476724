import {
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import { BookingStatus, IBooking } from "../../reducers/bookings/types";
import { getSignedUrl } from "../../utils/getSignedUrl";

import { checkDeleteOrDownloadFileType } from "../utils";

interface IProps {
  open: boolean;
  document: string | undefined;
  fileName: string;
  title: string;
  raLoading: boolean;
  handleClose: () => void;
  handleMail: () => void;
  regeneratePdf?: () => void;
  booking?: IBooking;
  sendEmail: boolean;
  renderMode?: "canvas" | "svg";
}

const PdfViewer = (props: IProps) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState<string>("");
  const documentId = props.document;

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  useEffect(() => {
    if (documentId) {
      getPdf(documentId);
    }
  }, [documentId]);

  async function getPdf(conditionsUrl: string) {
    const { contentType } = checkDeleteOrDownloadFileType(conditionsUrl);
    const file = await getSignedUrl(conditionsUrl);
    setPdf(file);
  }
  const [open, setOpen] = useState(props?.open ? true : false);
  async function download(url: any) {
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = props.fileName ? props.fileName : `Rental_agreement.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container xs={12} justify="flex-end">
              <Fab
                size={"medium"}
                variant={"extended"}
                onClick={() => download(pdf)}
              >
                {"DOWNLOAD"}
              </Fab>
            </Grid>
          </Grid>

          {pdf ? (
            <div>
              <Document
                file={pdf}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<CircularProgress size={26} thickness={5} />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    width={900}
                    scale={1.0}
                    pageNumber={index + 1}
                    renderAnnotationLayer={true}
                    renderMode={props.renderMode || "canvas"}
                  />
                ))}
              </Document>
            </div>
          ) : (
            <CircularProgress size={26} thickness={5} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default PdfViewer;
