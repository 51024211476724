import axios, { AxiosError, AxiosResponse } from 'axios';
import HttpHeader from '../../../../header';

const httpHeader = HttpHeader.getInstance();

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(async function (config) {
  config.headers = {
    ...config.headers,
    ...httpHeader.headers
  };
  return config;
});

instance.interceptors.response.use(
  (value: AxiosResponse) => Promise.resolve(value),
  (error: AxiosError) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        httpHeader.resetHeaders();
        localStorage.clear();
      }
    };
    return Promise.reject(error);
  });

export default instance;