import { gql } from '@apollo/client';

export const GET_CONSUMER_AVAILABLE_VEHICLES_FOR_SWAP = gql`
  query consumerAvailableVehiclesForSwap(
    $bookingId: String!
    $startDate: String!
    $endDate: String!
  ) {
    consumerAvailableVehiclesForSwap(
      bookingId: $bookingId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      licencePlate
      make
      model
      year
      imageUrl
      bodyType
      numberOfSeats
      numberOfDoors
      transmission
      fuelType
      subscriptions {
        pricings {
          subscriptionAmount
          duration
        }
      }
    }
  }
`;