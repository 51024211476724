import { gql } from "apollo-boost";

export const GET_AVAILABLE_SUBSCRIPTION_VEHICLES = gql`
  query getConsumerSubscriptionCatalogue {
    getConsumerSubscriptionCatalogue {
      id
      bodyType
      make
      model
      year
      licencePlate
      numberOfDoors
      numberOfSeats
      transmission
      fuelType
      imageUrl
      subscriptions {
        deposit
        subscription {
          isActive
          id
          name
          rateTypeName
          addons {
            id
            name
            displayName
            description
            category
          }
          insurancePolicy {
            id
            name
            description
          }
        } 
        pricings {
          duration
          enrollmentAmount
          mileage
          subscriptionAmount
          pricePerExtraMile
          unlimitedMileage
          swapsAllowed
        }
      }
      availableFrom
      branchId
      currentServiceLocation {
        id
        isDisabled
      }
    }
  }
`;
