import * as React from "react";

export const Van18TonneSVG = {
  interior: {
    width: 387,
    height: 802,
    svg: (
      <>
        <g>
          <g>
            <rect
              x="41.2"
              y="254"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="305"
              height="532.9"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M191.2,14h56c0,0,45,1,72,12c0,0,18,6,16,32l-1,163c0,0,2,15-8,16
				c0,0-18,4-131,5"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M196.4,14h-56c0,0-45,1-72,12c0,0-18,6-16,32l1,163c0,0-2,15,8,16
				c0,0,20.8,4,133.8,5"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M194.7,22.8h53.8c0,0,37.2,0,63.4,10.1c0,0,17.1,6,15.1,24.1
				l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M194.7,22.8H139c0,0-37.2,0-63.4,10.1c0,0-17.1,6-15.1,24.1l6,26.2
				c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M195.1,26.5h52.7c0,0,36.4,0,62,8.9c0,0,16.7,5.3,14.8,21.4
				L318.6,80c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M195.1,26.5h-54.5c0,0-36.4,0-62,8.9c0,0-16.7,5.3-14.8,21.4
				L69.8,80c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M69.2,89v140c0,0-2,7-11,7"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M318.2,89v140c0,0,2,7,11,7"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M69.5,230c0,0,119.7,14,249.7,1"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M56.2,77c0,0,5,11,7,22l0,111.7c0,0.2,0,0.4,0,0.6
			c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C57.2,213,56.7,76.6,56.2,77z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M331.2,77c0,0-5,11-7,22l0,111.7c0,0.2,0,0.4,0,0.6
			c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C330.2,213,330.7,76.6,331.2,77z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="74.2"
              y1="239"
              x2="74.2"
              y2="254"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="314.2"
              y1="238"
              x2="314.2"
              y2="254"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="105.2"
              y1="17"
              x2="280.2"
              y2="17"
            />
            <rect
              x="42.2"
              y="59"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="10"
              height="44"
            />
            <rect
              x="335.2"
              y="60"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="10"
              height="44"
            />
            <g>
              <polyline
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="8.2,80 20.2,71 32.2,71 44.2,80 			"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="43.2,83 9.2,83 8.2,80 44.2,80 			"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="27.2,83 58.2,133 61.2,133 61.2,132 30.2,83 			"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="33.3,83.1 52.2,100 52.2,96 37.4,83 			"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="62.2,129 62.2,133 58.2,133 58.2,138 62.2,138 
				62.2,142 65.2,142 65.2,129 			"
              />
            </g>
            <g>
              <polyline
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="379.2,80 367.2,71 355.2,71 343.2,80 			"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="344.2,83 378.2,83 379.2,80 343.2,80 			"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="360.2,83 329.2,133 326.2,133 326.2,132 357.2,83 			
				"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="354.1,83.1 335.2,100 335.2,96 350,83 			"
              />
              <polygon
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                points="325.2,129 325.2,133 329.2,133 329.2,138 325.2,138 
				325.2,142 322.2,142 322.2,129 			"
              />
            </g>
          </g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M74,91"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M72.5,91c0,0,10.8,122.9,10,126c-1,4-13,13-13,13"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M314.5,91c0,0-10.8,122.9-10,126c1,4,13,13,13,13"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M82.5,217c0,0,104.1,13,222,0"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M123.7,97.7h44.2c0,0,6.5,1.2,9,12.6l0.4,28.2
		c0,0-3.3,16.1-12.4,21.2h-39.7c0,0-8.1-6.3-11.6-21v-28.9C113.5,109.8,116.8,99.1,123.7,97.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M178.2,154.9l-1-1.7h-5.5l-3.7,4.2l-4.4,2.3l-18.4-0.2v-0.1
		l-18.8,0.6l-4-3.1l-3-4l-6.3,0.3l-1.7,3.1l-1,8.2v25l0.8,6.5l2.3,4.4l2.9,2.8l4.5,2.2h6.2h2.7l0.2,1.8h15.2v0.1h15.2l0.2-1.8h2.7
		h6.2l4.5-2.2l2.9-2.8l2.3-4.4l0.8-6.5v-25l-0.8-6.5C179.1,156.9,178.7,155.9,178.2,154.9z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="145.2,188.6 126.2,188.6 126.2,205.1 129.8,209 145.2,209 
		160.5,209 164.1,205.1 164.1,188.6 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M221.7,98.7h44.2c0,0,6.5,1.2,9,12.6l0.4,28.2
		c0,0-3.3,16.1-12.4,21.2h-39.7c0,0-8.1-6.3-11.6-21v-28.9C211.5,110.8,214.8,100.1,221.7,98.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M276.2,155.9l-1-1.7h-5.5l-3.7,4.2l-4.4,2.3l-18.4-0.2v-0.1
		l-18.8,0.6l-4-3.1l-3-4l-6.3,0.3l-1.7,3.1l-1,8.2v25l0.8,6.5l2.3,4.4l2.9,2.8l4.5,2.2h6.2h2.7l0.2,1.8h15.2v0.1h15.2l0.2-1.8h2.7
		h6.2l4.5-2.2l2.9-2.8l2.3-4.4l0.8-6.5v-25l-0.8-6.5C277.1,157.9,276.7,156.9,276.2,155.9z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="243.2,189.6 224.2,189.6 224.2,206.1 227.8,210 243.2,210 
		258.5,210 262.1,206.1 262.1,189.6 	"
          />
        </g>
      </>
    )
  },

  exterior: {
    width: 1240,
    height: 1706,
    svg: (
      <>
<g id="Layer_1_00000160151331818565984460000000603837332544609957_">
	<g>
		<g>
			<rect x="47.6" y="673" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.4,608.7c-2-24-18-43-38-51.7v-55.3h-15h-4l-5-32c0-2,3-2,3-2
				h29v6h3l3-3h72l30,9c12,5,52,24,52,24l25,14l19,12c13,9,13,27,13,27v116c0,11-12,12.6-12,12.6c-1.4,0.3-13.7,0.4-15.2,0.4H194.4
				c-2-1-3-4-3-4h-20c-2,0-29-4-29-4V608.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M81.4,677.7c17,0,51-8,61-46"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M81.4,672.7c12.2-0.8,23.6-5.5,32.5-13.9
				c16.2-15.3,23.4-38.3,17.8-60c-5.4-20.7-21.3-38.2-42.3-43.1"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="81.4" y1="679.5" x2="81.4" y2="672.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="89.4" y1="501.7" x2="89.4" y2="556.2"/>
			<rect x="68.4" y="509.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="75.4" y1="553.7" x2="75.4" y2="509.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.3,501.8c0,0-5.9,0-6.9,0c-0.9,0-6-9.2-6.9-10.8
				c-0.1-0.2-0.1-0.3-0.1-0.5v-19.7c0-0.6,0.5-1.1,1.1-1.1h3.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.4,552.7c8,1,15,4,15,4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M260.9,521.7l-10-3l-51-16.7c-0.6-0.2-1.3-0.3-2-0.3h-94"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M302.5,558.8L302.5,558.8c0-4.3-1.8-8.5-5-11.3
				c-4.8-4.1-11-7.7-11-7.7l-40-16l-29.7-8.7c-3.5-1.1-7.2-1.9-10.9-2.4c-4.9-0.7-5.8-0.3-8,0.1c-2.3,0.4-4,2.4-4.1,4.7
				c-0.1,3.9,1.1,7.6,1.5,8.8c0.1,0.3,0.3,0.7,0.4,1.1l2.4,7.2c0.3,0.7,0.5,1.4,0.7,2.2l13.7,49.9l16.5,46.7
				c0.3,0.8,0.7,1.7,1.1,2.5l6.6,13.3c0.7,1.3,1.5,2.5,2.5,3.6c1.4,1.5,2.9,2.8,4.4,3.8c3.6,2.6,7.9,4.1,12.3,4.7l10.5,1.4h25
				c10,0,12-10,12-10v-54L302.5,558.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M299.2,560.6c-0.3-6.3-4.1-12-9.7-14.9l-15.3-8.1l-39-14l-29-8
				c-3.1-0.6-5.2-0.6-6.7-0.3c-2.1,0.5-3.3,2.6-2.8,4.7c1.1,4.1,3.6,11.7,3.6,11.7l12,43l15,46l10.5,23.3
				c2.7,5.9,7.5,10.4,13.6,12.5c8.1,2.8,17,3.2,17,3.2h22.4c2.1,0,4.1-0.7,5.7-2.1c0.7-0.7,1.3-1.3,1.7-1.9c0.8-1,1.2-2.4,1.2-3.7
				v-90.3C299.4,561.3,299.4,561,299.2,560.6z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.4,478.7l2,11c2,9,14,14,14,14l21,9l54,26c4,2,6-1,6-1l5-7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M219.5,479.7l0.4,4c0.5,4,2.1,7.8,4.8,10.8
				c4.7,5.2,8.2,6.3,8.2,6.3l20.3,8.7l52.1,25.1c3.9,1.9,5.8-1,5.8-1l3.3-4.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.4,649.8l27.2,18.5c0.5,0.3,1.1,0.5,1.7,0.5h124.1
				c15,0,13.7-12.9,14-14.8c0-0.2,0-0.4,0-0.6v-91.6c0-1.5-0.1-2.8-0.2-4.1c-0.8-6.7-4.5-12.8-10-16.7l-8.8-6.3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M198.4,501.7c2-12-9-31-9-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M186.4,501.7c0-13-10-31-10-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M150.4,501.7c1-18-9-31-9-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M108.4,501.7c-2-4-2.6-10.2-2.9-12.4c-0.1-0.4-0.1-0.8-0.1-1.2
				v-20.4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M120.4,501.6c-4-6-5.3-13.4-5.8-16.5c-0.1-0.8-0.2-1.6-0.2-2.5
				v-8.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M305.4,537.7c0,0,16,9,17,34l1,95c0,0,0,12-11,12
				c-10,0-140,0-140,0v-10c0-39-5-62-5-62c-6-40-45-105-45-105"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="172.4" y1="678.7" x2="172.4" y2="681.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M119.4,501.7c31,52,41,85,41,85c11,41,9,81.5,9,81.5v13"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M116.4,501.7c20,35,29,57,29,57c19,42,19,76,19,76v30v14l-22-3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M177,632.7c2.2-3.8,8.2,1.4,11.4,4.7c1.3,1.4,2.1,3.2,2.1,5.2v16.8
				c0,1.8-1.5,3.3-3.3,3.3h-7.5c-1.8,0-3.3-1.5-3.3-3.3v-24.7C176.4,634,176.5,633.3,177,632.7z"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="183.4" y1="662.7" x2="183.4" y2="633.2"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M87.4,472.7h12c0.5,0,1-0.5,1-1v-1c0-0.6-0.5-1-1-1h-12
				c-0.6,0-1,0.4-1,1v1C86.4,472.2,86.8,472.7,87.4,472.7z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M251.4,522.7l-10.9,8.1c-0.4,0.3-0.2,0.9,0.3,0.9h53.3
				c0.7,0,1-0.9,0.4-1.3l-11.1-7.7H251.4z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M240.5,531.7l1.7,2.6c0.1,0.2,0.5,0.4,0.8,0.4h49
				c0.3,0,0.5-0.2,0.7-0.4l1.7-2.6H240.5z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M244.4,527.7h-9.3c-0.5,0-1,0.2-1.3,0.5l-20,18.1
				c-0.4,0.2-0.8,0.4-1.2,0.4h-27.3v-3h26.2c0.6,0,1.1-0.2,1.5-0.6l19.4-18c0.3-0.2,0.7-0.4,1.1-0.4H248h0.4L244.4,527.7z"/>
			<rect x="180.4" y="540.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M289.4,526.7h16l5.3,3.6c0.5,0.3,0.7,0.7,0.7,1.2v48.2h2v-47.9
				c0-0.8-0.3-1.7-1-2.3c-2.3-2.1-7-4.8-7-4.8h-19L289.4,526.7z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M311.4,531.7"/>
			<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="118.4,1086.2 118.4,1190.7 137.4,1190.7 137.4,666.7 
				118.4,666.7 118.4,988.5 			"/>
			<rect x="111.4" y="1190.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="141.9" y1="666.7" x2="136.9" y2="666.7"/>
			<rect x="72.4" y="679.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="285.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="89.4" y1="964.7" x2="89.4" y2="680"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="77.4" y1="964.2" x2="77.4" y2="680"/>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="118" y1="693.7" x2="72.8" y2="693.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="118.8" y1="696.5" x2="72.6" y2="696.5"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="118" y1="772.7" x2="72.8" y2="772.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="118.8" y1="775.5" x2="72.6" y2="775.5"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="118.8" y1="855.5" x2="72.8" y2="855.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="118.8" y1="858.5" x2="72.8" y2="858.5"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="117.8" y1="952.5" x2="71.8" y2="952.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="117.8" y1="955.5" x2="71.8" y2="955.5"/>
			</g>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="81.4" y1="1190.7" x2="81.4" y2="1107.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="81.4" y1="969.5" x2="81.4" y2="964.7"/>
			<rect x="77.4" y="1190.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="81.4" y1="1180.7" x2="118.4" y2="1180.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="88.4" y1="1190.7" x2="88.4" y2="1180.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M70.9,1106.7c15.7,0,30.9-5.6,42.9-15.7
				c21.9-18.4,30.1-49.5,20.7-76.5c-9.3-26.7-34.7-45.6-63.1-45.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M71.4,1098.7c14-0.8,27-5.3,38-14.2c19.2-15.6,26.1-42.7,18.5-66
				c-7.9-24.5-31.1-40.7-56.5-41.8"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="71.4" y1="977.2" x2="71.4" y2="968.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="71.4" y1="1098.2" x2="71.4" y2="1106.7"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="70.4" cy="1036.7" rx="54" ry="55"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="70.4" cy="1037.5" rx="31" ry="30.8"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="71.4" cy="612.7" rx="54" ry="55"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="71.4" cy="613.5" rx="31" ry="30.8"/>
			<rect x="48.4" y="1100.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
			<rect x="137.4" y="692.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="304" height="543"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313.8,685.5h105.6c1.7,0,3-1.6,2.6-3.3
			c-4.3-17.6-25.5-100.3-29.2-111.3c-4.5-13.4-67.5-30.4-67.5-30.4"/>
	</g>
	<g>
		<g>
			<rect x="1148.2" y="673.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1087.4,608.7c2-24,18-43,38-51.7v-55.3h15h4l5-32c0-2-3-2-3-2h-29
				v6h-3l-3-3h-72l-30,9c-12,5-52,24-52,24l-25,14l-19,12c-13,9-13,27-13,27v116c0,11,12,12.6,12,12.6c1.4,0.3,13.7,0.4,15.2,0.4
				h107.8c2-1,3-4,3-4h20c2,0,29-4,29-4V608.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1148.4,677.7c-17,0-51-8-61-46"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1148.4,672.7c-12.2-0.8-23.6-5.5-32.5-13.9
				c-16.2-15.3-23.4-38.3-17.8-60c5.4-20.7,21.3-38.2,42.3-43.1"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1148.4" y1="679.5" x2="1148.4" y2="672.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1140.4" y1="501.7" x2="1140.4" y2="556.2"/>
			<rect x="1140.4" y="509.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1154.4" y1="553.7" x2="1154.4" y2="509.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1140.5,501.8c0,0,5.9,0,6.9,0c0.9,0,6-9.2,6.9-10.8
				c0.1-0.2,0.1-0.3,0.1-0.5v-19.7c0-0.6-0.5-1.1-1.1-1.1h-3.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1140.4,552.7c-8,1-15,4-15,4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M968.9,521.7l10-3l51-16.7c0.6-0.2,1.3-0.3,2-0.3h94"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M927.3,558.8L927.3,558.8c0-4.3,1.8-8.5,5-11.3
				c4.8-4.1,11-7.7,11-7.7l40-16l29.7-8.7c3.5-1.1,7.2-1.9,10.9-2.4c4.9-0.7,5.8-0.3,8,0.1c2.3,0.4,4,2.4,4.1,4.7
				c0.1,3.9-1.1,7.6-1.5,8.8c-0.1,0.3-0.3,0.7-0.4,1.1l-2.4,7.2c-0.3,0.7-0.5,1.4-0.7,2.2l-13.7,49.9l-16.5,46.7
				c-0.3,0.8-0.7,1.7-1.1,2.5l-6.6,13.3c-0.7,1.3-1.5,2.5-2.5,3.6c-1.4,1.5-2.9,2.8-4.4,3.8c-3.6,2.6-7.9,4.1-12.3,4.7l-10.5,1.4
				h-25c-10,0-12-10-12-10v-54L927.3,558.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M930.6,560.6c0.3-6.3,4.1-12,9.7-14.9l15.3-8.1l39-14l29-8
				c3.1-0.6,5.2-0.6,6.7-0.3c2.1,0.5,3.3,2.6,2.8,4.7c-1.1,4.1-3.6,11.7-3.6,11.7l-12,43l-15,46L992,644
				c-2.7,5.9-7.5,10.4-13.6,12.5c-8.1,2.8-17,3.2-17,3.2H939c-2.1,0-4.1-0.7-5.7-2.1c-0.7-0.7-1.3-1.3-1.7-1.9
				c-0.8-1-1.2-2.4-1.2-3.7v-90.3C930.4,561.3,930.4,561,930.6,560.6z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1013.4,478.7l-2,11c-2,9-14,14-14,14l-21,9l-54,26c-4,2-6-1-6-1
				l-5-7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1010.3,479.7l-0.4,4c-0.5,4-2.1,7.8-4.8,10.8
				c-4.7,5.2-8.2,6.3-8.2,6.3l-20.3,8.7l-52.1,25.1c-3.9,1.9-5.8-1-5.8-1l-3.3-4.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1087.4,649.8l-27.2,18.5c-0.5,0.3-1.1,0.5-1.7,0.5H934.4
				c-15,0-13.7-12.9-14-14.8c0-0.2,0-0.4,0-0.6v-91.6c0-1.5,0.1-2.8,0.2-4.1c0.8-6.7,4.5-12.8,10-16.7l8.8-6.3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1031.4,501.7c-2-12,9-31,9-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1043.4,501.7c0-13,10-31,10-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1079.4,501.7c-1-18,9-31,9-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1121.4,501.7c2-4,2.6-10.2,2.9-12.4c0.1-0.4,0.1-0.8,0.1-1.2
				v-20.4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1109.4,501.6c4-6,5.3-13.4,5.8-16.5c0.1-0.8,0.2-1.6,0.2-2.5v-8.9
				"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M924.4,537.7c0,0-16,9-17,34l-1,95c0,0,0,12,11,12
				c10,0,140,0,140,0v-10c0-39,5-62,5-62c6-40,45-105,45-105"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1057.4" y1="678.7" x2="1057.4" y2="681.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1110.4,501.7c-31,52-41,85-41,85c-11,41-9,81.5-9,81.5v13"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1113.4,501.7c-20,35-29,57-29,57c-19,42-19,76-19,76v30v14l22-3"
				/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1052.8,632.7c-2.2-3.8-8.2,1.4-11.4,4.7c-1.3,1.4-2.1,3.2-2.1,5.2
				v16.8c0,1.8,1.5,3.3,3.3,3.3h7.5c1.8,0,3.3-1.5,3.3-3.3v-24.7C1053.4,634,1053.3,633.3,1052.8,632.7z"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1046.4" y1="662.7" x2="1046.4" y2="633.2"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1142.4,472.7h-12c-0.5,0-1-0.5-1-1v-1c0-0.6,0.5-1,1-1h12
				c0.6,0,1,0.4,1,1v1C1143.4,472.2,1142.9,472.7,1142.4,472.7z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M978.4,522.7l10.9,8.1c0.4,0.3,0.2,0.9-0.3,0.9h-53.3
				c-0.7,0-1-0.9-0.4-1.3l11.1-7.7H978.4z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M989.3,531.7l-1.7,2.6c-0.1,0.2-0.5,0.4-0.8,0.4h-49
				c-0.3,0-0.5-0.2-0.7-0.4l-1.7-2.6H989.3z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M985.4,527.7h9.3c0.5,0,1,0.2,1.3,0.5l20,18.1
				c0.4,0.2,0.8,0.4,1.2,0.4h27.3v-3h-26.2c-0.6,0-1.1-0.2-1.5-0.6l-19.4-18c-0.3-0.2-0.7-0.4-1.1-0.4h-14.5h-0.4L985.4,527.7z"/>
			<rect x="1034.4" y="540.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M940.4,526.7h-16l-5.3,3.6c-0.5,0.3-0.7,0.7-0.7,1.2v48.2h-2
				v-47.9c0-0.8,0.3-1.7,1-2.3c2.3-2.1,7-4.8,7-4.8h19L940.4,526.7z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M918.4,531.7"/>
			<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="1111.4,1086.2 1111.4,1190.7 1092.4,1190.7 1092.4,666.7 
				1111.4,666.7 1111.4,988.5 			"/>
			<rect x="1092.4" y="1190.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1087.9" y1="666.7" x2="1092.9" y2="666.7"/>
			<rect x="1135.4" y="679.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="285.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1140.4" y1="964.7" x2="1140.4" y2="680"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1152.4" y1="964.2" x2="1152.4" y2="680"/>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111.8" y1="693.7" x2="1157" y2="693.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111" y1="696.5" x2="1157.2" y2="696.5"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111.8" y1="772.7" x2="1157" y2="772.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111" y1="775.5" x2="1157.2" y2="775.5"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111" y1="855.5" x2="1157" y2="855.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111" y1="858.5" x2="1157" y2="858.5"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1112" y1="952.5" x2="1158" y2="952.7"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1112" y1="955.5" x2="1158" y2="955.5"/>
			</g>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1148.4" y1="1190.7" x2="1148.4" y2="1107.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1148.4" y1="969.5" x2="1148.4" y2="964.7"/>
			<rect x="1136.4" y="1190.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1148.4" y1="1180.7" x2="1111.4" y2="1180.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1141.4" y1="1190.7" x2="1141.4" y2="1180.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1158.9,1106.7c-15.7,0-30.9-5.6-42.9-15.7
				c-21.9-18.4-30.1-49.5-20.7-76.5c9.3-26.7,34.7-45.6,63.1-45.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1158.4,1098.7c-14-0.8-27-5.3-38-14.2
				c-19.2-15.6-26.1-42.7-18.5-66c7.9-24.5,31.1-40.7,56.5-41.8"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1158.4" y1="977.2" x2="1158.4" y2="968.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1158.4" y1="1098.2" x2="1158.4" y2="1106.7"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1159.4" cy="1036.7" rx="54" ry="55"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1159.4" cy="1037.5" rx="31" ry="30.8"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1158.4" cy="612.7" rx="54" ry="55"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1158.4" cy="613.5" rx="31" ry="30.8"/>
			<rect x="1158.4" y="1100.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
			<rect x="788.4" y="692.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="304" height="543"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M916,685.5H810.4c-1.7,0-3-1.6-2.6-3.3
			c4.3-17.6,25.5-100.3,29.2-111.3c4.5-13.4,67.5-30.4,67.5-30.4"/>
	</g>
</g>
<g id="Layer_2_00000128465917794039431330000007439254303631224460_">
	<g>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M474,1661.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H474z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M511,1661.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H511z"/>
		</g>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M691,1661.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H691z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M728,1661.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H728z"/>
		</g>
		<rect x="464" y="1269.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="308" height="331"/>
		<rect x="469" y="1280.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="298" height="286"/>
		<rect x="473" y="1283.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279"/>
		<rect x="620" y="1283.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="464" y1="1573.8" x2="771" y2="1573.8"/>
		<rect x="475" y="1576.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20"/>
		<rect x="709" y="1576.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20"/>
		<rect x="577" y="1577.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="73" height="17"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="464" y1="1272.8" x2="772" y2="1272.8"/>
		<rect x="473" y="1272.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4"/>
		<rect x="748" y="1272.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4"/>
		<g>
			<rect x="464" y="1309.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="465.1" y="1319.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="464" y="1415.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="465.1" y="1425.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="464" y="1522.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="465.1" y="1532.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="768.1" y="1309.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="749" y="1319.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="768.1" y="1415.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="749" y="1425.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="768.1" y="1522.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="749" y="1532.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M605.4,1497.8h-21.9c-0.9,0-1.6-0.7-1.6-1.6v-48.9
				c0-0.9,0.7-1.6,1.6-1.6h21.9c0.9,0,1.6,0.7,1.6,1.6v48.9C607,1497,606.3,1497.8,605.4,1497.8z"/>
			<g>
				<rect x="595.5" y="1451.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="601.2" y1="1452.4" x2="601.2" y2="1491.6"/>
			</g>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M629.6,1497.8h21.9c0.9,0,1.6-0.7,1.6-1.6v-48.9
				c0-0.9-0.7-1.6-1.6-1.6h-21.9c-0.9,0-1.6,0.7-1.6,1.6v48.9C628,1497,628.7,1497.8,629.6,1497.8z"/>
			<g>
				<rect x="633" y="1451.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="633.8" y1="1452.4" x2="633.8" y2="1491.6"/>
			</g>
		</g>
		<rect x="471" y="1600.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61"/>
		<rect x="688" y="1600.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549" y1="1656.8" x2="688" y2="1656.8"/>
		<rect x="474" y="1622.8" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="290" height="15"/>
		<g>
			<g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M464,1471.8v-56h-11c0,0-4,0-7.4,1c-2.7,0.8-4.6,3.3-4.6,6.1v24.9
					"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M464,1415.8v56h-11c0,0-4,0-7.4-1c-2.7-0.8-4.6-3.3-4.6-6.1v-24.9
					"/>
			</g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M453,1471.8v6.7c0,0.9,0.3,1.7,0.9,2.3l10.1,10.1v-5l-6.3-6.3
				c-0.4-0.4-0.7-1-0.7-1.6v-6L453,1471.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M457,1415.8v-11.2c0-0.5,0.3-0.9,0.7-1.1l6.3-2.7v-3l-8.6,3.4
				c-0.9,0.3-1.4,1.2-1.4,2.1v12.5L457,1415.8L457,1415.8z"/>
		</g>
		<g>
			<g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M772,1471.8v-56h11c0,0,4,0,7.4,1c2.7,0.8,4.6,3.3,4.6,6.1v24.9"
					/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M772,1415.8v56h11c0,0,4,0,7.4-1c2.7-0.8,4.6-3.3,4.6-6.1v-24.9"
					/>
			</g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M783,1471.8v6.7c0,0.9-0.3,1.7-0.9,2.3l-10.1,10.1v-5l6.3-6.3
				c0.4-0.4,0.7-1,0.7-1.6v-6L783,1471.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M779,1415.8v-11.2c0-0.5-0.3-0.9-0.7-1.1l-6.3-2.7v-3l8.6,3.4
				c0.9,0.3,1.4,1.2,1.4,2.1v12.5L779,1415.8L779,1415.8z"/>
		</g>
	</g>
</g>
<g id="Layer_3">
	<g>
		<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="483.5,317.8 482,317.8 482,13.8 756,13.8 756,317.8 
			754.5,317.8 		"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,123.8l99.5,1c0,0,22-1,25,17l4,24l4,35l2,36l1,73v8
				c0,0-1,16-2,20l1,9c0,0,1,13-2,22l-1,8c0,0-3,12-11,12h-32c0,0-8,1-12-7h-76.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,123.8l-99.5,1c0,0-22-1-25,17l-4,24l-4,35l-2,36l-1,73v8
				c0,0,1,16,2,20l-1,9c0,0-1,13,2,22l1,8c0,0,3,12,11,12h32c0,0,8,1,12-7h76.5"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.3,138.8l104.3,2c10.3,0,17.7,8,18.7,18.7l6,62.3
				c0,10.3,1,19-21,19l-108,2"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.3,138.8l-104.3,2c-10.3,0-17.7,8-18.7,18.7l-6,62.3
				c0,10.3-1,19,21,19l108,2"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.6,141.8l102.9,1.9c10.1,0,17.4,7.6,18.4,17.7l5.9,59
				c0,9.7,1,18-20.7,18l-106.5,1.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.6,141.8l-102.9,1.9c-10.1,0-17.4,7.6-18.4,17.7l-5.9,59
				c0,9.7-1,18,20.7,18l106.5,1.9"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,313.8h59.5c6,0,8,3,8,3c-18,10-18,27-18,27h-49.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,313.8h-59.5c-6,0-8,3-8,3c18,10,18,27,18,27h50.5"/>
		</g>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="552.3" y1="315.8" x2="685.3" y2="315.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="552.8" y1="317.8" x2="684.3" y2="317.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="555.8" y1="319.8" x2="682.3" y2="319.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="558.8" y1="321.8" x2="679.3" y2="321.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="560.8" y1="323.8" x2="677.3" y2="323.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="561.8" y1="325.8" x2="675.3" y2="325.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="563.8" y1="327.8" x2="674.3" y2="327.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="564.8" y1="329.8" x2="672.3" y2="329.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="565.8" y1="331.8" x2="671.3" y2="331.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="566.8" y1="333.8" x2="670.3" y2="333.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="567.8" y1="335.8" x2="670.3" y2="335.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="567.8" y1="337.8" x2="669.3" y2="337.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="568.8" y1="339.8" x2="669.3" y2="339.8"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="568.8" y1="341.8" x2="668.3" y2="341.8"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M615.3,357.8h74c0,0-1,12-7,15h-67"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M622.8,357.8h-74c0,0,1,12,7,15h67"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.3,256.8c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M753.3,256.8c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.3,269.8c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M753.3,269.8c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3"/>
		</g>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="529.3" y1="264.8" x2="537.3" y2="277.8"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="708.6" y1="265" x2="700.6" y2="278"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,339.8c0,0,4,6,13,6h123"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.3,347.8c0,0,4.5,6,13.5,6h123"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M752.3,339.8c0,0-4,6-13,6h-123"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M753.3,347.8c0,0-4.5,6-13.5,6h-123"/>
		</g>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="487.3" y1="376.8" x2="750.3" y2="376.8"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="508.3" y1="309.8" x2="508.3" y2="275.8"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="729.3" y1="309.8" x2="729.3" y2="275.8"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M566.3,340.8c0,0-2-23-34-28l-46-6v22.5c0,3.9,1.9,7.8,5.2,9.9
			c1.5,1,3.4,1.6,5.8,1.6C497.3,340.8,566.6,341,566.3,340.8z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M671.3,340.8c0,0,2-23,34-28l46-6v22.5c0,3.9-1.9,7.8-5.2,9.9
			c-1.5,1-3.4,1.6-5.8,1.6C740.3,340.8,671,341,671.3,340.8z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M537,371.8h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
			c0-1.3,1.1-2.4,2.4-2.4H537c1.3,0,2.4,1.1,2.4,2.4v8.3C539.3,370.7,538.2,371.8,537,371.8z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M718,370.8h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
			c0-1.3,1.1-2.4,2.4-2.4H718c1.3,0,2.4,1.1,2.4,2.4v8.3C720.3,369.7,719.2,370.8,718,370.8z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,362.8v75c0,0,0,0,0,0.1c0.1,2.2,1.9,3.9,4.1,3.9h27.9
			c0,0,4,0,4-3v-50"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M752.3,362.8v75c0,0,0,0,0,0.1c-0.1,2.2-1.9,3.9-4.1,3.9h-27.9
			c0,0-4,0-4-3v-50"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="521.3" y1="407.8" x2="716.3" y2="407.8"/>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M465.6,216.8h-19.5c-3.7,0-6.7-3-6.7-6.7v-42.5
				c0-3.7,3-6.7,6.7-6.7h19.5c3.7,0,6.7,3,6.7,6.7V210C472.3,213.7,469.3,216.8,465.6,216.8z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M455.3,199.8L455.3,199.8c-1.1,0-2-0.9-2-2v-19c0-1.1,0.9-2,2-2
				l0,0c1.1,0,2,0.9,2,2v19C457.3,198.9,456.4,199.8,455.3,199.8z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M457.3,160.8v-11.2c0-0.5,0.2-1,0.6-1.3l2.9-2.2
				c0.3-0.2,0.7-0.4,1.1-0.4h31.4l1-4h-32.1c-0.6,0-1.1,0.2-1.5,0.5l-5.6,4c-0.5,0.3-0.7,0.8-0.7,1.4v13.2H457.3z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M454.8,216.9v6.7c0,0.8,0.3,1.5,0.9,2.1l19.7,19.2
				c0.6,0.6,0.9,1.4,0.9,2.3v16.7h3v-19.7c0-0.8-0.3-1.6-0.9-2.2l-18.1-17.2c-0.6-0.6-1-1.4-1-2.3v-5.7L454.8,216.9z"/>
			<rect x="474.3" y="263.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11"/>
		</g>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M772.1,216.8h19.5c3.7,0,6.7-3,6.7-6.7v-42.5
				c0-3.7-3-6.7-6.7-6.7h-19.5c-3.7,0-6.7,3-6.7,6.7V210C765.3,213.7,768.3,216.8,772.1,216.8z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M782.3,199.8L782.3,199.8c1.1,0,2-0.9,2-2v-19c0-1.1-0.9-2-2-2
				l0,0c-1.1,0-2,0.9-2,2v19C780.3,198.9,781.2,199.8,782.3,199.8z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M780.3,160.8v-11.2c0-0.5-0.2-1-0.6-1.3l-2.9-2.2
				c-0.3-0.2-0.7-0.4-1.1-0.4h-31.4l-1-4h32.1c0.6,0,1.1,0.2,1.5,0.5l5.6,4c0.5,0.3,0.7,0.8,0.7,1.4v13.2H780.3z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M782.9,216.9v6.7c0,0.8-0.3,1.5-0.9,2.1l-19.7,19.2
				c-0.6,0.6-0.9,1.4-0.9,2.3v16.7h-3v-19.7c0-0.8,0.3-1.6,0.9-2.2l18.1-17.2c0.6-0.6,1-1.4,1-2.3v-5.7L782.9,216.9z"/>
			<rect x="754.3" y="263.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11"/>
		</g>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M500,130.5l5.3-87c0,0,0-5,6.4-5h105"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M735,129.5l-5.3-86c0,0,0-5-6.4-5H615.6"/>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M695.7,116.4l9.8-26.1c0.4-1.1,1.5-1.8,2.6-1.8h16
		c1.2,0,2.2,1,2.1,2.3l-0.9,17c-0.1,2-1.5,3.6-3.5,4L696,117C695.8,116.9,695.6,116.7,695.7,116.4z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M539.2,116.4l-9.8-26.1c-0.4-1.1-1.5-1.8-2.6-1.8h-16
		c-1.2,0-2.2,1-2.1,2.3l0.9,17c0.1,2,1.5,3.6,3.5,4l25.8,5.2C539.1,116.9,539.3,116.7,539.2,116.4z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M538.5,116.5c0,0,74.2,4.9,158,0"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M508.5,91.5c0,0,0-40-0.2-52"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M726.3,91.5c0,0,2.2-31,2.2-51"/>
</g>
<g id="Layer_4">
	<g>
		<rect x="466.2" y="704.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="305" height="532.9"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M616.2,464.6h56c0,0,45,1,72,12c0,0,18,6,16,32l-1,163
				c0,0,2,15-8,16c0,0-18,4-131,5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M621.4,464.6h-56c0,0-45,1-72,12c0,0-18,6-16,32l1,163
				c0,0-2,15,8,16c0,0,20.8,4,133.8,5"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M619.7,473.4h53.8c0,0,37.2,0,63.4,10.1c0,0,17.1,6,15.1,24.1
				l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M619.7,473.4H564c0,0-37.2,0-63.4,10.1c0,0-17.1,6-15.1,24.1
				l6,26.2c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M620.1,477.1h52.7c0,0,36.4,0,62,8.9c0,0,16.7,5.3,14.8,21.4
				l-5.9,23.2c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M620.1,477.1h-54.5c0,0-36.4,0-62,8.9c0,0-16.7,5.3-14.8,21.4
				l5.9,23.2c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M494.2,539.6v140c0,0-2,7-11,7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M743.2,539.6v140c0,0,2,7,11,7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M494.5,680.6c0,0,119.7,14,249.7,1"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M481.2,527.6c0,0,5,11,7,22v111.7c0,0.2,0,0.4,0,0.6
			c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C482.2,663.6,481.7,527.1,481.2,527.6z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M756.2,527.6c0,0-5,11-7,22v111.7c0,0.2,0,0.4,0,0.6
			c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C755.2,663.6,755.8,527.1,756.2,527.6z"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="499.2" y1="689.6" x2="499.2" y2="704.6"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="739.2" y1="688.6" x2="739.2" y2="704.6"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="530.2" y1="467.6" x2="705.2" y2="467.6"/>
		<rect x="467.2" y="509.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
		<rect x="760.2" y="510.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
		<g>
			<polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="433.2,530.6 445.2,521.6 457.2,521.6 469.2,530.6 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="468.2,533.6 434.2,533.6 433.2,530.6 469.2,530.6 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="452.2,533.6 483.2,583.6 486.2,583.6 486.2,582.6 
				455.2,533.6 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="458.3,533.7 477.2,550.6 477.2,546.6 462.4,533.6 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="487.2,579.6 487.2,583.6 483.2,583.6 483.2,588.6 
				487.2,588.6 487.2,592.6 490.2,592.6 490.2,579.6 			"/>
		</g>
		<g>
			<polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="804.2,530.6 792.2,521.6 780.2,521.6 768.2,530.6 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="769.2,533.6 803.2,533.6 804.2,530.6 768.2,530.6 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="785.2,533.6 754.2,583.6 751.2,583.6 751.2,582.6 
				782.2,533.6 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="779.1,533.7 760.2,550.6 760.2,546.6 775,533.6 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="750.2,579.6 750.2,583.6 754.2,583.6 754.2,588.6 
				750.2,588.6 750.2,592.6 747.2,592.6 747.2,579.6 			"/>
		</g>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M499.1,541.6"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M497.5,541.5c0,0,10.8,122.9,10,126c-1,4-13,13-13,13"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M739.5,541.5c0,0-10.8,122.9-10,126c1,4,13,13,13,13"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M507.5,667.5c0,0,104.1,13,222,0"/>
</g>
      </>
    )
  }
};
