import { gql } from "@apollo/client";

export const GET_BUSINESS_CREDIT_SCORE = gql`
  mutation getConsumerBusinessCreditScore($businessCustomerId: String!) {
    getConsumerBusinessCreditScore(businessCustomerId: $businessCustomerId) {
      businessCreditData {
        creditScore
        creditDescription
        lastChecked
      }
    }
  }
`;
