import { gql } from '@apollo/client';

export const GET_BOOKING_CUSTOMER_ID = gql`
  query getBookingsByCustomerId($id: String!) {
    bookingsByCustomerId(id: $id) {
      id
      branchId
      branch {
        id
        timeZone
        name
      }
      status
      referenceNumber
      smartCarVehicle
      pickupDateTime
      organisation {
        logoUrl
      }
      currentBookingSchedules {
        id
        vehicle {
          id
          licencePlate
          isGhostVehicle
        }
      }
      vehicleGroups {
        vehicleIds
      }
      pickupServiceLocation {
        name
      }
      pickupOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      dropoffDateTime
      dropoffServiceLocation {
        name
      }
      dropoffOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
    }
  }
`;

export const GET_BOOKING_BY_BUSINESS_CUSTOMER_ID = gql`
  query getBookingsByBusinessCustomerId($id: String!) {
    bookingsByBusinessCustomerId(id: $id) {
      id
      branchId
      branch {
        id
        timeZone
        name
      }
      status
      referenceNumber
      smartCarVehicle
      pickupDateTime
      organisation {
        logoUrl
      }
      currentBookingSchedules {
        id
        vehicle {
          id
          licencePlate
          isGhostVehicle
        }
      }
      vehicleGroups {
        vehicleIds
      }
      pickupServiceLocation {
        name
      }
      pickupOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      dropoffDateTime
      dropoffServiceLocation {
        name
      }
      dropoffOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
    }
  }
`;

export const GET_BOOKING_DASHBOARD = gql`
  query getConsumerBookingsDashboard($customerId: String!) {
    getConsumerBookingsDashboard(customerId: $customerId) {
      currentBookings {
        id
      branchId
      branch {
        id
        timeZone
        name
      }
      status
      referenceNumber
      smartCarVehicle
      pickupDateTime
      organisation {
        logoUrl
      }
      currentBookingSchedules {
        id
        vehicle {
          id
          licencePlate
          isGhostVehicle
        }
      }
      vehicleGroups {
        vehicleIds
      }
      pickupServiceLocation {
        name
      }
      pickupOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      dropoffDateTime
      dropoffServiceLocation {
        name
      }
      dropoffOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      }
      upcomingBookings {
        id
      branchId
      branch {
        id
        timeZone
        name
      }
      status
      referenceNumber
      smartCarVehicle
      pickupDateTime
      organisation {
        logoUrl
      }
      currentBookingSchedules {
        id
        vehicle {
          id
          licencePlate
          isGhostVehicle
        }
      }
      vehicleGroups {
        vehicleIds
      }
      pickupServiceLocation {
        name
      }
      pickupOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      dropoffDateTime
      dropoffServiceLocation {
        name
      }
      dropoffOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      }
      pastBookings {
        id
      branchId
      branch {
        id
        timeZone
        name
      }
      status
      referenceNumber
      smartCarVehicle
      pickupDateTime
      organisation {
        logoUrl
      }
      currentBookingSchedules {
        id
        vehicle {
          id
          licencePlate
          isGhostVehicle
        }
      }
      vehicleGroups {
        vehicleIds
      }
      pickupServiceLocation {
        name
      }
      pickupOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      dropoffDateTime
      dropoffServiceLocation {
        name
      }
      dropoffOtherLocation {
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      }
    }
  }
`;

