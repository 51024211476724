import React, { useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import styles from "./index.module.css";
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { IVehicle } from '../../../../reducers/bookings/types';
import { GET_VEHICLES_FOR_TRACKING, GET_VEHICLE_GROUPS_TRACKING } from '../../../../graphql/vehiclePriceGroup/getVehicleGroupTrackingQuery';
import { useLocation } from 'react-router-dom';
import { ISmartcarData } from "./TelematicsView";
import { getSpellingByCountry } from "../../../utils";

const StyledInput = withStyles({
  root: {
    padding: "0px !important",
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'transparent',
      fontSize: 14,
      padding: "28px 14px 8px 14px !important",
      border: 0,
      fontWeight: 700,
      borderRadius: 0,
      width: "auto"
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: "0px !important",
    },
    '& fieldset': {
      border: 0
    },
    '& .MuiAutocomplete-endAdornment': {
      top: "initial",
      bottom: 5,
      right: "4px !important"
    }
  },
  focus: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: "#f6f6f6"
    },
    '& fieldset': {
      border: "0 !important",
    }
  }
})(TextField);

interface IProps {
  setVehicles: (vehicles: IVehicleTelematics[]) => void;
  active: boolean;
  setMarkedVehicle: (vehicle?: IVehicleTelematics) => void;
  setSmartcarData: (data: ISmartcarData[]) => void;
}

export interface IVehicleGroupData {
  id: string
  name: string;
  vehicles: IVehicleId[]
}

export interface IVehicleTelematics {
  lat?: number;
  lng?: number;
  status?: string;
  esn?: string;
  id: string;
  licencePlate?: string;
  lastLocation?: string;
  lastLocationTime?: string;
}

interface IVehicleId {
  id: string
}

export const QueryBox: React.FC<IProps> = (props) => {
  const location = useLocation();
  const snackbar = useSnackBar();
  const [allVehicles, setAllVehicles] = useState<IVehicleTelematics[]>([]);
  const [vehicleInputText, setVehicleInputText] = useState<string>("");
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const [loadVehicles,
    { loading: vehiclesLoading, data: vehiclesFetchedData }
  ] = useLazyQuery(
    GET_VEHICLES_FOR_TRACKING, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    user?.businesscustomers && loadVehicles({
      variables: {
        businessCustomerId: user.businesscustomers.id,
      }
    });
  }, []);

  useEffect(() => {
    if (vehiclesFetchedData && vehiclesFetchedData.getTelematicsVehiclesForBusinessCustomer && vehiclesFetchedData.getTelematicsVehiclesForBusinessCustomer.length) {
      let _vehicles: IVehicleTelematics[] = [];
      let _smartcardata: ISmartcarData[] = [];
      for (let i = 0; i < vehiclesFetchedData.getTelematicsVehiclesForBusinessCustomer.length; i++) {
        const _vehicle: IVehicle = vehiclesFetchedData.getTelematicsVehiclesForBusinessCustomer[i];
        if (!_vehicle.smartCarVehicleData && _vehicle.telematicCoreData && _vehicle.telematicCoreData.latitude && _vehicle.telematicCoreData.longitude) {
          const alreadyPushed = _vehicles.findIndex(v => v.id === _vehicle.id);
          alreadyPushed < 0 && _vehicles.push({
            lat: _vehicle.telematicCoreData.latitude,
            lng: _vehicle.telematicCoreData.longitude,
            esn: _vehicle.telemetryDeviceId,
            id: _vehicle.id || "",
            licencePlate: _vehicle.licencePlate,
            lastLocation: `${_vehicle.telematicCoreData?.address?.street || ""} ${_vehicle.telematicCoreData?.address?.city || ""}`,
            lastLocationTime: _vehicle.telematicCoreData?.eventTime || ""
          })
        } else if (_vehicle.smartCarVehicleData && _vehicle.smartCarVehicleData?.vehicleLocation?.latitude && _vehicle.smartCarVehicleData?.vehicleLocation?.longitude) {
          const alreadyPushed = _vehicles.findIndex(v => v.id === _vehicle.id);
          alreadyPushed < 0 && _vehicles.push({
            lat: _vehicle.smartCarVehicleData?.vehicleLocation?.latitude,
            lng:  _vehicle.smartCarVehicleData?.vehicleLocation?.longitude,
            id: _vehicle.id || "",
            licencePlate: _vehicle.licencePlate,
          })

          _smartcardata.push({
            id: _vehicle.id || "",
            latitude:  _vehicle.smartCarVehicleData?.vehicleLocation?.latitude,
            longitude:  _vehicle.smartCarVehicleData?.vehicleLocation?.longitude,
            engineOilLife: _vehicle.smartCarVehicleData.engineOilLife?.lifeRemaining,
            evBatteryLevel: _vehicle.smartCarVehicleData.eVBatteryLevel?.percentRemaining,
            evChargeStatus: _vehicle.smartCarVehicleData.eVChargeStatus?.state,
            fuelLevel: _vehicle.smartCarVehicleData.fuelLevel?.amountRemaining,
            odometer: _vehicle.smartCarVehicleData.odometer?.distance,
          })
        }
      }
      setAllVehicles(_vehicles);
      props.setVehicles(_vehicles);
      props.setSmartcarData(_smartcardata);
      if (location && location.search) {
        const params = new URLSearchParams(location.search);
        const vehicleFromParams = params.get("vehicle");
        if (vehicleFromParams) {
          const _vehicle = _vehicles.find(v => v.id === vehicleFromParams);
          if (_vehicle) {
            props.setMarkedVehicle(_vehicle);
            setVehicleInputText(_vehicle.licencePlate || "")
          }
        }
      }
    }
  }, [vehiclesFetchedData])

  const getVehiclesForGroup = (vehicleIds: IVehicleId[]) => {
    const _vehicles: IVehicleTelematics[] = []
    for (let i = 0; i < vehicleIds.length; i++) {
      const _vehicle = allVehicles.find((item) => item.id === vehicleIds[i].id);
      if (_vehicle) {
        _vehicles.push(_vehicle);
      }
    }
    return _vehicles
  }

  const handleMarkedVehicle = (vehicle?: IVehicleTelematics) => {
    props.setMarkedVehicle(vehicle)
  }
  
  return (
    <Fade in={props.active} >
      <div className={styles.queryCard}>
        <h3>Vehicle <span >Tracking</span></h3>
        <div className={styles.searchBox}>
          <div className={styles.block}>
            <div className={styles.inputLabel}>
              Search Vehicle
            </div>
            {vehiclesLoading ? <div style={{ padding: "30px 30px 4px 14px" }}>
              <CircularProgress size={20} thickness={5} />
            </div> : <Autocomplete
              freeSolo
              noOptionsText="No Groups"
              id="FLEET_DASHBOARD_VEHICLE_SEARCH"
              options={allVehicles}
              getOptionLabel={(option) => option.licencePlate || ""}
              inputValue={vehicleInputText}
              onInputChange={(_, value, reason) => {
                setVehicleInputText(value);
                if (!value.length) {
                  handleMarkedVehicle()
                }
              }}
              onChange={(_: any, newVal: any, reason) => {
                if (newVal && newVal.id) {
                  handleMarkedVehicle(newVal)
                }
              }}
              renderInput={(params) => (
                <StyledInput
                  {...params}
                  variant="outlined"
                  placeholder={`${getSpellingByCountry(country)} Plate`}
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
            />}
          </div>
        </div>
      </div>
    </Fade>
  );
};


