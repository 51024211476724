import { combineReducers } from "redux";

import bookingReducer from "./bookings/reducer";
import consumerWebsiteReducer from "./website/reducer";
import branchReducer from "./branch/reducer";
import authReducer from "./auth/reducer";
import driverReducer from './drivers/reducer'

export default combineReducers({
  bookingReducer,
  consumerWebsiteReducer,
  branchReducer,
  authReducer,
  driverReducer
});
