export default {
  rounded:{
    borderRadius:5,
    marginBottom:2,
    boxShadow:'none',
    '&:before':{
      display:'none'
    }
  }
};
