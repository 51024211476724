import React from "react";

export const MPV7SeaterSVG = {
  interior: {
    width: 298,
    height: 658,
    svg: (
      <>
<g>
	<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M255.7,57.9c-13-19-40-33-40-33c-16-10-44-12-44-12h-27h-4h-27
		c0,0-28,2-44,12c0,0-27,14-40,33l-7,15v147v261v70c0,0,1,26,4,45c0,0-1,36,36,46c0,0,51,5,78,6c27-1,82-6,82-6c37-10,36-46,36-46
		c3-19,4-45,4-45v-70v-261v-147L255.7,57.9z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M225.5,266.5c1-3,0.5-0.9,0.9-4.6l7.7-80.3c0.4-4.4-0.6-9-3.2-12.7
		c-11-15.9-36.9-23.2-50.8-26c-5.1-1-10.3-1.6-15.5-1.6h-18.3H140h-18.3c-5.2,0-10.4,0.5-15.5,1.6c-13.9,2.8-39.8,10.1-50.8,26
		c-2.6,3.7-3.6,8.2-3.2,12.7l7.7,80.3c0.4,3.7,0.5,3.6,3.5,5.6"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M52.5,178.5c0,0,79-23,181-1"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M209.1,38.3c-21-24.8-61.3-23.7-66.1-23.5
		c-4.8-0.2-45.1-1.3-66.1,23.5c-0.3,0.3,0.1,0.8,0.5,0.6c6.2-3.2,30.3-13.7,65.2-13.1l0,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0l0,0
		c34.9-0.6,58.9,9.8,65.2,13.1C209,39.1,209.4,38.7,209.1,38.3z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M69.4,36.8c0.6,0,1,0.7,0.7,1.2c-3.6,6.2-20.5,36.6-21.3,59.9v3.6
		c0,1.4-1.2,2.6-2.6,2.5c-2.9-0.2-7.5-1.2-11-5.9c-1.8-2.3-2.7-5.1-3.1-8.1c-0.5-4-0.6-10.8,1.6-19.8c1.5-6.1,4.6-11.7,8.8-16.4
		C48.9,46.7,59.8,36.5,69.4,36.8z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M219,36.8c-0.6,0-1,0.7-0.7,1.2c3.6,6.2,20.5,36.6,21.3,59.9v3.6
		c0,1.4,1.2,2.6,2.6,2.5c2.9-0.2,7.5-1.2,11-5.9c1.8-2.3,2.7-5.1,3.1-8.1c0.5-4,0.6-10.8-1.6-19.8c-1.5-6.1-4.6-11.7-8.8-16.4
		C239.5,46.7,228.6,36.5,219,36.8z"/>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M145.7,31.9c0,0-33.6-0.8-61.5,13.9c-6.9,3.6-12.6,9-16.6,15.7
			c-5.9,10-13.5,27.9-16.9,57.4l-4.4,33.6c-0.6,4.8-0.5,9.7,0.6,14.4c0.2,1,5.3,11.7,5.6,12.7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.9,31.9c0,0,32.8-0.8,59.9,13.9c6.7,3.6,12.3,9,16.2,15.7
			c5.8,10,13.2,27.9,16.5,57.4l4.3,33.6c0.6,4.8,0.5,9.7-0.6,14.4c-0.2,1-4.5,9.7-4.8,10.7"/>
	</g>
	<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="63.5,267.5 62.5,568.5 225.5,568.5 225.5,266 	"/>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.5,568.5c0,0,12.2,33.4,83.2,31.4"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M225.5,568.5c0,0-10.8,33.4-82.8,31.4"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M40,174.5c2.1,11.8,14.8,82.5,15.7,95.3l2,277.8
			c0,2.1-0.5,4.2-1.4,6.1c-2.7,5.4-6.6,16.1-10.6,17.1c-3.5,0.9-7-3-8-7l-4-264v-132c0-0.4,0.5-0.6,0.7-0.3l4.1,4.1
			C39.3,172.4,39.8,173.4,40,174.5z"/>
		<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="56.7,349.9 34.3,334.1 34.7,358.9 56.7,365.9 		"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="56.7" y1="356.9" x2="34.7" y2="347.9"/>
		<path fill="#939393" stroke="#000000" stroke-miterlimit="10" d="M33.7,167.9l0.1,17l7.4,0.2c0.4,0,0.6-0.3,0.6-0.7l-1.9-9.8
			c-0.2-0.9-0.6-1.7-1.2-2.3l-4-4.4C34.4,167.7,33.7,167.5,33.7,167.9z"/>
		<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="56.7,481.9 36.4,484.8 36.6,497.9 56.7,493.9 		"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="56.7" y1="459.9" x2="35.7" y2="451.9"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M247.4,174.5c-2.1,11.8-14.8,82.5-15.7,95.3l-2,277.8
			c0,2.1,0.5,4.2,1.4,6.1c2.7,5.4,6.6,16.1,10.6,17.1c3.5,0.9,7-3,8-7l4-264v-132c0-0.4-0.5-0.6-0.7-0.3l-4.1,4.1
			C248.1,172.4,247.6,173.4,247.4,174.5z"/>
		<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="230.7,349.9 253.1,334.1 252.7,358.9 230.7,365.9 		"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="230.7" y1="356.9" x2="252.7" y2="347.9"/>
		<path fill="#939393" stroke="#000000" stroke-miterlimit="10" d="M253.7,167.9l-0.1,17l-7.4,0.2c-0.4,0-0.6-0.3-0.6-0.7l1.9-9.8
			c0.2-0.9,0.6-1.7,1.2-2.3l4-4.4C253,167.7,253.7,167.5,253.7,167.9z"/>
		<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="230.7,481.9 251,484.8 250.8,497.9 230.7,493.9 		"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="230.7" y1="459.9" x2="251.7" y2="451.9"/>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M232.5,616l-7.7-19.3c-0.6-1.4-2.3-2-3.6-1.2
		c-6.9,4.1-38.8,14.8-81.4,16.4c-42.6-1.6-70.6-12.3-77.5-16.4c-1.3-0.8-3.1-0.2-3.6,1.2L50.9,616c-0.7,1.6,0.1,3.4,1.7,4.2
		c41.1,18.7,88.1,16.7,88.1,16.7s48.9,2,90-16.7C232.4,619.4,233.1,617.6,232.5,616z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M34.9,583.6l2.5,25.7c0.2,2.5,1.3,4.8,3.1,6.6
		c5.3,5.3,18,16,37.2,18.9l0,0c1,3-1.5,3.1-3.3,2.9c-4.6-0.6-14.7-0.9-32-13c-4-2.8-6.7-6.9-8-11.4c-2.6-9.3-3.7-25-1.1-30
		C33.7,582.6,34.8,582.8,34.9,583.6z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M251.9,582.6l-2.5,25.7c-0.2,2.5-1.3,4.8-3.1,6.6
		c-5.3,5.3-18,16-37.2,18.9l0,0c-1,3,1.5,3.1,3.3,2.9c4.6-0.6,14.7-0.9,32-13c4-2.8,6.7-6.9,8-11.4c2.6-9.3,3.7-25,1.1-30
		C253.1,581.6,252,581.8,251.9,582.6z"/>
	<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M37.7,216.9c0,0-15.1,2.4-26.1,12.1c-3,2.6-5.2,6.2-5.8,10.2
		C5.5,240.6,5.5,242,6,243c0.3,0.6,1,0.7,1.5,0.5l30.2-12.7V216.9z"/>
	<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M258.7,216.9c0,0,15.1,2.4,26.1,12.1c3,2.6,5.2,6.2,5.8,10.2
		c0.2,1.5,0.3,2.9-0.2,3.9c-0.3,0.6-1,0.7-1.5,0.5l-30.2-12.7L258.7,216.9L258.7,216.9z"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="33.7" y1="346.9" x2="21.7" y2="342.9"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="251.7" y1="347.9" x2="263.7" y2="343.9"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="36.7" y1="497.9" x2="22.7" y2="495.9"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="249.7" y1="497.9" x2="263.7" y2="495.9"/>
</g>
<g id="Layer_3">
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M173,212.2h36.6c0,0,5.3,1,7.5,10l0.4,22.2c0,0-2.7,12.7-10.2,16.7
			h-33c0,0-6.7-4.9-9.6-16.5v-22.7C164.5,221.7,167.3,213.3,173,212.2z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M218,257.2l-0.7-1.3h-4.6l-3,3.2l-3.7,1.8l-15.3-0.1l0,0l-15.5,0.5
			l-3.3-2.5l-2.4-3.2l-5.1,0.2l-1.7,2.4l-0.7,6.5v19.7l0.7,5.1l1.9,3.5l2.4,2.2l3.7,1.8h5.1h2.2l0.2,1.4h12.4l0,0H203l0.2-1.4h2.2
			h5.1l3.7-1.8l2.4-2.2l1.9-3.5l0.7-5.1v-19.7l-0.7-5.1C218.8,258.7,218.5,257.9,218,257.2z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="190.8,283.7 175.1,283.7 175.1,296.6 178,299.8 
			190.8,299.8 203.5,299.8 206.4,296.6 206.4,283.7 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M83.5,213.6H120c0,0,5.3,1,7.4,10l0.4,22.2c0,0-2.7,12.7-10.2,16.7
			H84.8c0,0-6.6-4.9-9.5-16.5v-22.7C75.2,223.1,77.9,214.7,83.5,213.6z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128.5,258.6l-0.7-1.3h-4.6l-3,3.2l-3.7,1.8l-15.2-0.1l0,0L86,262.8
			l-3.2-2.5l-2.4-3.2l-5.1,0.2l-1.4,2.5l-0.7,6.5v19.6l0.7,5.1l1.9,3.5l2.4,2.2l3.7,1.8H87h2l0.2,1.4h12.4l0,0H114l0.2-1.4h2.2h5.1
			l3.7-1.8l2.4-2.2l1.9-3.5l0.7-5.1v-19.7l-0.7-5.1C129.3,260.2,128.9,259.3,128.5,258.6z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="101.2,285.1 85.6,285.1 85.6,298.2 88.5,301.2 101.2,301.2 
			113.9,301.2 116.8,298.2 116.8,285.1 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M76.1,348.4h30.5c0,0,4.4,1,6.2,9.5l0.3,21.2c0,0-2.3,12.2-8.5,16
			H77.2c0,0-5.5-4.7-8-15.8v-21.7C69,357.7,71.3,349.6,76.1,348.4z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M113.7,391.6l-0.7-1.3h-3.8l-2.5,3.1l-3,1.7l-12.7,0l0,0l-12.9,0.5
			l-2.7-2.4l-2.2-3.1l-4.3,0.2l-1.1,2.4l-0.7,6.2v18.8l0.5,4.8l1.6,3.3l1.9,2.1l3,1.7h4.3h1.7l0.1,1.4h10.4l0,0H101l0.1-1.4h1.9h4.3
			l3-1.7l2-2.1l1.6-3.3l0.5-4.8v-18.8l-0.5-4.9C114.2,393.1,114,392.3,113.7,391.6z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="90.9,416.9 77.9,416.9 77.9,429.4 80.3,432.3 90.9,432.3 
			101.5,432.3 103.9,429.4 103.9,416.9 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128.3,347.5h30.5c0,0,4.4,1,6.2,9.5l0.3,21.2c0,0-2.3,12.2-8.5,16
			h-27.4c0,0-5.5-4.7-8-15.8v-21.7C121.2,356.7,123.5,348.6,128.3,347.5z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M165.9,390.7l-0.7-1.3h-3.8l-2.5,3.1l-3,1.7l-12.7-0.1l0,0
			l-12.9,0.5l-2.7-2.4l-2.2-3.1l-4.3,0.2l-1.1,2.4l-0.7,6.2v18.8l0.5,4.8l1.6,3.3l1.9,2.1l3,1.7h4.3h1.7l0.1,1.4H143l0,0h10.4
			l0.1-1.4h1.9h4.3l3-1.7l2-2.1l1.6-3.3l0.5-4.8v-18.8l-0.5-4.9C166.4,392.2,166.1,391.4,165.9,390.7z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="143.1,415.9 130.1,415.9 130.1,428.5 132.5,431.4 
			143.1,431.4 153.7,431.4 156.1,428.5 156.1,415.9 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M182.1,348.4h30.5c0,0,4.4,1,6.2,9.5l0.3,21.2c0,0-2.3,12.2-8.5,16
			h-27.4c0,0-5.5-4.7-8-15.8v-21.7C175.1,357.7,177.4,349.6,182.1,348.4z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M219.7,391.6l-0.7-1.3h-3.8l-2.5,3.1l-3,1.7L197,395l0,0l-12.9,0.5
			l-2.7-2.4l-2.2-3.1l-4.3,0.2l-1.1,2.4l-0.7,6.2v18.8l0.5,4.8l1.6,3.3l1.9,2.1l3,1.7h4.3h1.7l0.1,1.4h10.4l0,0H207l0.1-1.4h1.9h4.3
			l3-1.7l2-2.1l1.6-3.3l0.5-4.8v-18.8l-0.5-4.9C220.3,393.1,220,392.3,219.7,391.6z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="196.9,416.9 183.9,416.9 183.9,429.4 186.3,432.3 
			196.9,432.3 207.5,432.3 210,429.4 210,416.9 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M79,473.2h37.1c0,0,5.4,1,7.5,9.5l0.4,21.2c0,0-2.8,12.2-10.3,16
			H80.4c0,0-6.7-4.7-9.7-15.8v-21.7C70.4,482.5,73.3,474.4,79,473.2z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124.8,516.4l-0.9-1.3h-4.6l-3,3.1l-3.7,1.7l-15.5-0.1l0,0
			l-15.7,0.5l-3.3-2.4l-2.7-3.1l-5.2,0.2l-1.3,2.4l-0.9,6.2v18.8l0.6,4.8l1.9,3.3l2.3,2.1l3.7,1.7h5.2h2.1l0.1,1.4h12.7l0,0h12.7
			l0.1-1.4h2.3h5.2l3.7-1.7l2.4-2.1l1.9-3.3l0.6-4.8v-18.8l-0.6-4.9C125.4,517.9,125.1,517.1,124.8,516.4z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="97,541.7 81.2,541.7 81.2,554.2 84.1,557.1 97,557.1 
			109.9,557.1 112.9,554.2 112.9,541.7 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M166.3,472.8h37.1c0,0,5.4,1,7.5,9.5l0.4,21.2c0,0-2.8,12.2-10.3,16
			h-33.3c0,0-6.7-4.7-9.7-15.8V482C157.6,482.1,160.6,474,166.3,472.8z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M212,516l-0.9-1.3h-4.6l-3,3.1l-3.7,1.7l-15.5-0.1l0,0l-15.7,0.5
			l-3.3-2.4l-2.7-3.1l-5.2,0.2l-1.3,2.4l-0.9,6.2V542l0.6,4.8l1.9,3.3l2.3,2.1l3.7,1.7h5.2h2.1l0.1,1.4h12.7l0,0h12.7l0.1-1.4h2.3
			h5.2l3.7-1.7l2.4-2.1l1.9-3.3l0.6-4.8v-18.8l-0.6-4.9C212.6,517.5,212.4,516.7,212,516z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="184.3,541.3 168.5,541.3 168.5,553.8 171.4,556.7 
			184.3,556.7 197.2,556.7 200.1,553.8 200.1,541.3 		"/>
	</g>
</g>
      </>
    ),
  },

  exterior: {
    width: 793,
    height: 1175,
    svg: (
      <>
<g id="Layer_1">
	<g>
		<path fill="#A09F9F" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M94.5,266.7c-3.1-0.7,21.5,24.8,25.7,27.3
			c0.6,0.4,1.3-0.1,1.3-0.8l1-9.5C122.5,283.7,111.5,270.7,94.5,266.7z"/>
		<path fill="#C6C6C6" d="M36.5,827.7l-3-15l2-93h18c0,0,15,0,27,10c0,0,21,9,24,42c0,0,2,33-29,50c0,0-10,5-20,6H36.5z"/>
		<path fill="#C6C6C6" d="M35.1,442.2l0.5-15l-1.5-93h18.1c0,0,14.6-1,28.7,8c0,0,23.2,11,26.2,44c0,0,3,35-32.2,51
			c0,0-10.6,4-20.6,5H35.1z"/>
		<polygon fill="#C6C6C6" points="34,334.2 31.5,737.2 35.5,737.2 35.5,334.2 		"/>
		<g>
			<circle fill="#FFFFFF" stroke="#020202" stroke-miterlimit="10" cx="51" cy="388.2" r="41.5"/>
			<circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="51" cy="388.2" r="23.5"/>
		</g>
		<g>
			<circle fill="#FFFFFF" stroke="#020202" stroke-miterlimit="10" cx="50" cy="774.2" r="41.5"/>
			<circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="50" cy="774.2" r="23.5"/>
		</g>
		<g>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M53.5,333.7c29.8,0,54,24.2,54,54s-24.2,54-54,54"/>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M53.5,333.7h-19l3-60h4v-6h9v4h17v-5h26c0,0,17,2,32,20
				c0,0,26,31,31,120c0,0,50,82,61,134c0,0,6,25,8,49c0,0,8,71-4,245l-3,33h-3.3c-0.4,0-0.7-0.3-0.7-0.7v-8.2c0-0.6-0.5-1.1-1.1-1.1
				h-15.2c-0.4,0-0.9,0.2-1.2,0.5l-38.5,34.5v0c-5.3,2.6-11.1,4-16.9,4h-22.1v3.3c0,0.4-0.3,0.7-0.7,0.7H57.6
				c-3.4,0-6.7-1.2-9.1-3.6c-1.7-1.7-3.3-4.1-4-7.4v-26l-8-36"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="54" y1="441.7" x2="35" y2="441.7"/>
		</g>
		<g>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M50.5,719.7c29.8,0,54,24.2,54,54c0,29.8-24.2,54-54,54"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="50.5" y1="719.7" x2="36" y2="719.7"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="51" y1="827.7" x2="36" y2="827.7"/>
		</g>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="35.5" y1="441.7" x2="35.5" y2="720.2"/>
		<path fill="#939393" d="M164.5,458.7h-19l-1-26c0,0,0-3,3,0L164.5,458.7z"/>
		<polygon fill="#939393" points="216.5,604.7 216.2,604.6 151.5,588.7 152.5,616.7 215.5,620.7 		"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="216" y1="613.2" x2="152" y2="603.2"/>
		<polygon fill="#939393" points="212.5,749.7 158,753.2 157.5,738.7 212.5,740.7 		"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="214" y1="714.2" x2="156.5" y2="709.7"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="179" y1="486.7" x2="147.5" y2="486.7"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="213" y1="749.2" x2="158.5" y2="752.7"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="212.5" y1="740.7" x2="158" y2="738.2"/>
		<line fill="none" stroke="#050505" stroke-miterlimit="10" x1="216.5" y1="604.7" x2="151.5" y2="588.7"/>
		<line fill="none" stroke="#050505" stroke-miterlimit="10" x1="216.5" y1="620.7" x2="152.5" y2="616.7"/>
		<line fill="none" stroke="#050505" stroke-miterlimit="10" x1="164.5" y1="458.7" x2="145.5" y2="458.7"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M144.6,432.6l16.8,382.7c0.1,2.2,1.1,4.2,2.8,5.6
			c3.5,3,9.6,7.7,12.4,7.7c0,0,7,1,13-5l16-13.6c2.5-2.2,4.1-5.3,4.2-8.6l2.7-51.8l3.8-138.2c0.1-4.1,0-8.2-0.4-12.3
			c-0.8-8.1-2.4-22.6-5-36.8c-2.6-14.6-7.4-28.8-14.1-42.1c-12.9-25.6-40.7-79.7-50.6-88.4C145.6,431.2,144.6,431.7,144.6,432.6z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M156.5,405.7c0,0-1.9,4.7-0.2,18.7c0.1,0.8,0.4,1.6,0.7,2.4
			l52.7,104.4c0.2,0.5,0.9,0.3,1-0.2c0.4-3.2,1-10.6-1.3-17.4"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M205.5,857.7c0,0-16,28-46,34"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M100.4,287.3c-0.4,4-1.1,14.8,0.6,25.3c0.3,2,1.3,3.8,2.7,5.2
			c3.2,3.3,9.6,10.3,13.9,18.9c0,0.1,0.1,0.1,0.1,0.2c0.3,1.2,4.1,14.8,6.9,17.8c0,0,0.1,0.1,0.1,0.1c0.5,0.4,3.4,2.3,7.8,2.8
			c1.1,0.1,2.1-0.8,2-1.9c-0.8-7.5-3.8-33.4-9.8-42c0,0-10.3-17.7-22.7-27.1C101.3,286.2,100.4,286.6,100.4,287.3z"/>
		<ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="68" cy="296.2" rx="9.5" ry="7.5"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="158.5" y1="752.7" x2="98.5" y2="749.7"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M144.5,454.7c0,0-70-5-97,7"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="58.5" y1="441.2" x2="59.5" y2="720.2"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="47.5" y1="442.2" x2="47.5" y2="718.7"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M151.5,602.7c0,0-86-14-104-13"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="145.5" y1="836.2" x2="145.5" y2="752.2"/>
		<polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="69.5,901.2 69.5,890.7 62.5,890.7 62.5,900.7 		"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M131.5,584.7v-28c0-1.6,1.4-3,3-3h0c1.6,0,3,1.4,3,3v28
			c0,1.6-1.3,3-3,3h0C132.9,587.7,131.5,586.4,131.5,584.7z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M136.5,736.7v-28c0-1.6,1.4-3,3-3h0c1.6,0,3,1.4,3,3v28
			c0,1.6-1.3,3-3,3h0C137.9,739.7,136.5,738.4,136.5,736.7z"/>
		<circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="123.5" cy="818.7" r="10"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M146,837.5v40.2c0,0-0.6,5.7-5.3,10c-2.1,2-4.9,3-7.7,3l-10.1,0
			c-0.8,0-1.4-0.6-1.4-1.4v-23.8c0-1.8,0.6-3.5,1.7-4.9l19.6-24.3c0.7-0.9,1.9-0.9,2.7-0.1v0C145.9,836.5,146,837,146,837.5z"/>
		<line fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" x1="121.5" y1="889.7" x2="121.5" y2="896.7"/>
		<path fill="#FFFFFF" stroke="#050505" stroke-miterlimit="10" d="M149,490.8c0-8.6,4.7-15.6,10.5-15.6c5.8,0,10.5,7,10.5,15.6
			C170,499.3,149,499.3,149,490.8z"/>
	</g>
</g>
<g id="Layer_2">
	<g>
		<path fill="#A09F9F" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M700.2,270.6c3.1-0.7-21.5,24.8-25.7,27.3
			c-0.6,0.4-1.3-0.1-1.3-0.8l-1-9.5C672.2,287.6,683.2,274.6,700.2,270.6z"/>
		<path fill="#C6C6C6" d="M758.2,831.6l3-15l-2-93h-18c0,0-15,0-27,10c0,0-21,9-24,42c0,0-2,33,29,50c0,0,10,5,20,6H758.2z"/>
		<path fill="#C6C6C6" d="M759.7,446.1l-0.5-15l1.5-93h-18.1c0,0-14.6-1-28.7,8c0,0-23.2,11-26.2,44c0,0-3,35,32.2,51
			c0,0,10.6,4,20.6,5H759.7z"/>
		<polygon fill="#C6C6C6" points="760.8,338.1 763.2,741.1 759.2,741.1 759.2,338.1 		"/>
		<g>
			<circle fill="#FFFFFF" stroke="#020202" stroke-miterlimit="10" cx="743.8" cy="392.1" r="41.5"/>
			<circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="743.8" cy="392.1" r="23.5"/>
		</g>
		<g>
			<circle fill="#FFFFFF" stroke="#020202" stroke-miterlimit="10" cx="744.8" cy="778.1" r="41.5"/>
			<circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="744.8" cy="778.1" r="23.5"/>
		</g>
		<g>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M741.2,337.6c-29.8,0-54,24.2-54,54c0,29.8,24.2,54,54,54"/>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M741.2,337.6h19l-3-60h-4v-6h-9v4h-17v-5h-26c0,0-17,2-32,20
				c0,0-26,31-31,120c0,0-50,82-61,134c0,0-6,25-8,49c0,0-8,71,4,245l3,33h3.3c0.4,0,0.7-0.3,0.7-0.7v-8.2c0-0.6,0.5-1.1,1.1-1.1
				h15.2c0.4,0,0.9,0.2,1.2,0.5l38.5,34.5l0,0c5.3,2.6,11.1,4,16.9,4h22.1v3.3c0,0.4,0.3,0.7,0.7,0.7h61.2c3.4,0,6.7-1.2,9.1-3.6
				c1.7-1.7,3.3-4.1,4-7.4v-26l8-36"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="740.8" y1="445.6" x2="759.8" y2="445.6"/>
		</g>
		<g>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M744.2,723.6c-29.8,0-54,24.2-54,54c0,29.8,24.2,54,54,54"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="744.2" y1="723.6" x2="758.8" y2="723.6"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="743.8" y1="831.6" x2="758.8" y2="831.6"/>
		</g>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="759.2" y1="445.6" x2="759.2" y2="724.1"/>
		<path fill="#939393" d="M630.2,462.6h19l1-26c0,0,0-3-3,0L630.2,462.6z"/>
		<polygon fill="#939393" points="578.2,608.6 578.6,608.5 643.2,592.6 642.2,620.6 579.2,624.6 		"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="578.8" y1="617.1" x2="642.8" y2="607.1"/>
		<polygon fill="#939393" points="582.2,753.6 636.8,757.1 637.2,742.6 582.2,744.6 		"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="580.8" y1="718.1" x2="638.2" y2="713.6"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="615.8" y1="490.6" x2="647.2" y2="490.6"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="581.8" y1="753.1" x2="636.2" y2="756.6"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="582.2" y1="744.6" x2="636.8" y2="742.1"/>
		<line fill="none" stroke="#050505" stroke-miterlimit="10" x1="578.2" y1="608.6" x2="643.2" y2="592.6"/>
		<line fill="none" stroke="#050505" stroke-miterlimit="10" x1="578.2" y1="624.6" x2="642.2" y2="620.6"/>
		<line fill="none" stroke="#050505" stroke-miterlimit="10" x1="630.2" y1="462.6" x2="649.2" y2="462.6"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M650.2,436.5l-16.8,382.7c-0.1,2.2-1.1,4.2-2.8,5.6
			c-3.5,3-9.6,7.7-12.4,7.7c0,0-7,1-13-5l-16-13.6c-2.5-2.2-4.1-5.3-4.2-8.6l-2.7-51.8l-3.8-138.2c-0.1-4.1,0-8.2,0.4-12.3
			c0.8-8.1,2.4-22.6,5-36.8c2.6-14.6,7.4-28.8,14.1-42.1c12.9-25.6,40.7-79.7,50.6-88.4C649.2,435.1,650.2,435.7,650.2,436.5z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M638.2,409.6c0,0,1.9,4.7,0.2,18.7c-0.1,0.8-0.4,1.6-0.7,2.4
			L585,535.2c-0.2,0.5-0.9,0.3-1-0.2c-0.4-3.2-1-10.6,1.3-17.4"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M589.2,861.6c0,0,16,28,46,34"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M694.4,291.2c0.4,4,1.1,14.8-0.6,25.3c-0.3,2-1.3,3.8-2.7,5.2
			c-3.2,3.3-9.6,10.3-13.9,18.9c0,0.1-0.1,0.1-0.1,0.2c-0.3,1.2-4.1,14.8-6.9,17.8c0,0-0.1,0.1-0.1,0.1c-0.5,0.4-3.4,2.3-7.8,2.8
			c-1.1,0.1-2.1-0.8-2-1.9c0.8-7.5,3.8-33.4,9.8-42c0,0,10.3-17.7,22.7-27.1C693.5,290.1,694.3,290.5,694.4,291.2z"/>
		<ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="726.8" cy="300.1" rx="9.5" ry="7.5"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="636.2" y1="756.6" x2="696.2" y2="753.6"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M650.2,458.6c0,0,70-5,97,7"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="736.2" y1="445.1" x2="735.2" y2="724.1"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="747.2" y1="446.1" x2="747.2" y2="722.6"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M643.2,606.6c0,0,86-14,104-13"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="649.2" y1="840.1" x2="649.2" y2="756.1"/>
		<polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="725.2,905.1 725.2,894.6 732.2,894.6 732.2,904.6 		"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M663.2,588.6v-28c0-1.6-1.3-3-3-3l0,0c-1.7,0-3,1.4-3,3v28
			c0,1.7,1.3,3,3,3l0,0C661.9,591.6,663.2,590.3,663.2,588.6z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M658.2,740.6v-28c0-1.6-1.3-3-3-3l0,0c-1.7,0-3,1.4-3,3v28
			c0,1.7,1.3,3,3,3l0,0C656.9,743.6,658.2,742.3,658.2,740.6z"/>
		<circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="671.2" cy="822.6" r="10"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M648.8,841.4v40.2c0,0,0.6,5.7,5.3,10c2.1,2,4.9,3,7.7,3h10.1
			c0.8,0,1.4-0.6,1.4-1.4v-23.8c0-1.8-0.6-3.5-1.7-4.9L652,840.2c-0.7-0.9-1.9-0.9-2.7-0.1l0,0C648.9,840.4,648.8,840.9,648.8,841.4
			z"/>
		<line fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" x1="673.2" y1="893.6" x2="673.2" y2="900.6"/>
		<path fill="#FFFFFF" stroke="#050505" stroke-miterlimit="10" d="M645.8,494.7c0-8.6-4.7-15.6-10.5-15.6s-10.5,7-10.5,15.6
			C624.8,503.3,645.8,503.3,645.8,494.7z"/>
	</g>
</g>
<g id="Layer_3">
	<g>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M312.5,205"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M312.5,205v33.6c0,2.2-1.8,3.9-3.9,3.9h-22.2
			c-2.2,0-3.9-1.8-3.9-3.9V192"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M489.5,205v33.6c0,2.2,1.8,3.9,3.9,3.9h22.2c2.2,0,3.9-1.8,3.9-3.9
			V192"/>
		<rect x="313" y="205.5" fill="#BFBFBF" width="176" height="7"/>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M294,87h-3v-9.8c0-1.3-0.6-2.6-1.6-3.4
				c-0.6-0.4-1.4-0.8-2.4-0.8h-16c0,0-5,0-5,6v13c0,0,0,4,4,4h20C290,96,294,90,294,87z"/>
			<rect x="266.5" y="80.5" fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="11" height="3"/>
		</g>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M508,87h3v-9.8c0-1.3,0.6-2.6,1.6-3.4c0.6-0.4,1.4-0.8,2.4-0.8
				h16c0,0,5,0,5,6v13c0,0,0,4-4,4h-20C512,96,508,90,508,87z"/>
			
				<rect x="524.5" y="80.5" transform="matrix(-1 -4.489621e-11 4.489621e-11 -1 1060 164)" fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="11" height="3"/>
		</g>
		<g>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M399.5,16.5c0,0,64.8,0,80,3c5.3,1.1,9.5,6.9,12.3,12.2
				c2,3.9,3.6,8.1,4.6,12.3l10.1,40.5c0,0,3.8,14.1,13.1,18.6c0.5,0.3,0.9,0.8,0.9,1.4l-1,87c0,0,0,11-7,14H401"/>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M402.5,16.5c0,0-64.8,0-80,3c-5.3,1.1-9.5,6.9-12.3,12.2
				c-2,3.9-3.6,8.1-4.6,12.3l-10.1,40.5c0,0-3.8,14.1-13.1,18.6c-0.5,0.3-0.9,0.8-0.9,1.4l1,87c0,0,0,11,7,14H401"/>
		</g>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M492.9,87.5H313.1c-2,0-3.6-1.6-3.6-3.6l8-43.8c0-2,1.6-3.6,3.6-3.6
			h161.8c2,0,3.6,1.6,3.6,3.6l10,43.8C496.5,85.9,494.9,87.5,492.9,87.5z"/>
		<g>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M310.5,86.5c0,0-1.3,0.1-1.8,0.4c-2.7,1.7-3.4,5.2-1.8,7.9
				c2.5,4,9.3,11.3,27.8,20c2.4,1.1,4.9,1.5,7.5,1.9c12.2,1.8,39.2,1.8,64.2,1.8"/>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M495,86.5c0,0,1.3,0.1,1.8,0.4c2.7,1.7,3.4,5.2,1.8,7.9
				c-2.5,4-9.3,11.3-27.8,20c-2.4,1.1-4.9,1.5-7.5,1.9c-12.2,1.8-39.2,1.8-64.2,1.8"/>
		</g>
		<path fill="#F4F4F4" stroke="#020202" stroke-miterlimit="10" d="M464.3,123.5h-61.8H401h-61.8c-2.5,0-4.1,2.8-2.8,4.9
			c0,0,0,0.1,0.1,0.1c0,0,7.1,11.8,21.2,17.9c3.2,1.4,6.6,2.1,10.1,2.1H401h1.5h33.2c3.5,0,6.9-0.7,10.1-2.1
			c14.1-6.2,21.2-17.9,21.2-17.9c0,0,0-0.1,0.1-0.1C468.4,126.3,466.8,123.5,464.3,123.5z"/>
		<g>
			<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M467.1,159.9c0-0.8-0.7-1.4-1.5-1.4h-64.1H400h-61.1
				c-0.8,0-1.5,0.6-1.5,1.4c-0.2,4.7-0.1,20,8.5,32c1,1.3,2.6,2.1,4.2,2.1H404h1h49.4c1.7,0,3.3-0.8,4.2-2.1
				C467.2,179.9,467.3,164.6,467.1,159.9z"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="337.5" y1="162.5" x2="467.5" y2="162.5"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="337" y1="166.5" x2="467" y2="166.5"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="337.5" y1="170.5" x2="467" y2="170.5"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="338.5" y1="174.5" x2="465.5" y2="174.5"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="339.5" y1="178.5" x2="464.5" y2="178.5"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="340.5" y1="182.5" x2="463.5" y2="182.5"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="342.5" y1="186.5" x2="461.5" y2="186.5"/>
			<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="344.5" y1="190.5" x2="459.5" y2="190.5"/>
		</g>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M305,107.6c-2.7,2.3-8.6,6-11.9,14.8c-0.5,1.4-0.6,3-0.2,4.4
			c0.7,2.4,2.9,7.8,6.5,11.6c2.8,2.9,6,5.1,10,5.4l21,1.6c0,0-5.6-29.1-22.3-38.2C307.2,106.8,305.9,106.9,305,107.6z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M324.2,187.5c1.1,0,1.9-1.2,1.4-2.2c-2.2-4.7-8.8-15.5-25.1-17.8
			c0,0-2.3-0.5-4-0.1c-1.1,0.2-2,1.2-2,2.4l0,13.8c0,0,0,4,6,4H324.2z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M499,107.6c2.7,2.3,8.6,6,11.9,14.8c0.5,1.4,0.6,3,0.2,4.4
			c-0.7,2.4-2.9,7.8-6.5,11.6c-2.8,2.9-6,5.1-10,5.4l-21,1.6c0,0,5.6-29.1,22.3-38.2C496.8,106.8,498.1,106.9,499,107.6z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M479.8,187.5c-1.1,0-1.9-1.2-1.4-2.2c2.2-4.7,8.8-15.5,25.1-17.8
			c0,0,2.3-0.5,4-0.1c1.1,0.2,2,1.2,2,2.4l0,13.8c0,0,0,4-6,4H479.8z"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M338.5,158.5c0,0-34-3-57-20"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M463.5,158.5c0,0,34-3,57-20"/>
	</g>
</g>
<g id="Layer_4">
	<g>
		<g>
			<rect x="312.8" y="1123.7" fill="#CECECE" width="86.5" height="13"/>
			<path fill="#CECECE" d="M313.2,1124.2l0-9.5c0,0,4-3,9-2c0,0,10-0.5,12,11.5"/>
		</g>
		<g>
			
				<rect x="397.8" y="1123.7" transform="matrix(-1 -4.153534e-11 4.153534e-11 -1 889 2260.4861)" fill="#CECECE" width="93.5" height="13"/>
			<path fill="#CECECE" d="M490.8,1124.2l0-9.5c0,0-4-3-9-2c0,0-10-0.5-12,11.5"/>
		</g>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M312.8,1115.2v40.8c0,2.3-1.8,4.2-3.9,4.2h-22.2
			c-2.2,0-3.9-1.9-3.9-4.2v-51.8"/>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M490.8,1115.2v40.8c0,2.3,1.8,4.2,3.9,4.2h22.2
			c2.2,0,3.9-1.9,3.9-4.2v-53.8"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M519.8,1035.4c0-2.4-0.5-4.8-1.5-7l-10.6-24.7
			c-10.6-26.4-18.3-46-21.4-54c-1-2.6-3.3-4.4-6-4.7c-17.9-2.5-61.5-2.4-78.5-2.3c-17.1-0.1-60.7-0.2-78.5,2.3c-2.7,0.4-5,2.2-6,4.7
			c-3.1,8-10.9,27.6-21.4,54l-10.6,24.7c-0.9,2.2-1.4,4.6-1.5,7l-1,69.4c0,0,0.6,5.5,2.3,9.9c1,2.4,1.4,4.9,1.5,7.5
			c0.1,2.5,0.6,5.6,2.1,7.6c3.9,0,7.8-1,11.2-3c3.6-2.1,6.7-4.9,9.3-8.1c2.2-2.8,5.9-5.9,11.5-5.9c0,0,12-1,13,11h61.5h13h61.5
			c1-12,13-11,13-11c5.6,0,9.3,3.1,11.5,5.9c2.6,3.3,5.7,6,9.3,8.1c3.4,2,7.3,3,11.2,3c1.5-2,2-5.1,2.1-7.6c0.1-2.6,0.6-5.1,1.5-7.5
			c1.7-4.3,2.3-9.9,2.3-9.9L519.8,1035.4z"/>
		<path fill="#D1D1D1" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M491.8,994.2c-1.5-13.9-9.1-26.1-12.3-31.3
			c-1-1.7-2.8-2.7-4.7-2.9c-17.4-1.1-70.8-0.8-72.9-0.8c-2.2,0-56.6-0.2-73.9,0.8c-1.9,0.1-2.7,1.2-3.7,2.9
			c-3.2,5.2-10.8,17.3-12.3,31.3c0,0-3,14.4,18,16.4c0,0,60.5,3.1,71.9,3.1v0c0,0,0.1,0,0.1,0s0.1,0,0.1,0v0
			c11.4,0,71.9-3.1,71.9-3.1C494.8,1008.6,491.8,994.2,491.8,994.2z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M483.1,992.4c-1.4-10.6-7.3-19.9-10.1-23.8
			c-0.9-1.3-2.5-2.1-4.2-2.2c-15.6-0.8-64.5-0.6-66.5-0.6c-1.9,0-50.9-0.2-66.5,0.6c-1.7,0.1-3.3,0.9-4.2,2.2
			c-2.9,3.9-8.8,13.2-10.1,23.8c0,0-2.7,11,16.2,12.5c0,0,54.4,2.3,64.6,2.3v0c0,0,0,0,0.1,0s0,0,0.1,0v0c10.2,0,64.6-2.3,64.6-2.3
			C485.8,1003.3,483.1,992.4,483.1,992.4z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M424.9,961.7h-44.4c-0.4,0-0.8-0.4-0.8-0.8
			v-4.4c0-0.4,0.4-0.8,0.8-0.8h44.4c0.4,0,0.8,0.4,0.8,0.8v4.4C425.8,961.4,425.4,961.7,424.9,961.7z"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M339.6,1048.7h-44.7c-1.2,0-2.1-1-2.1-2.1
			v-20.7c0-1.2,1-2.1,2.1-2.1h34.7c1.2,0,2.1,2,2.1,2.1l9.6,19.9c0.6,1.2-0.1,2.7-1.4,3C339.8,1048.7,339.7,1048.7,339.6,1048.7z"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M463.9,1048.7h44.7c1.2,0,2.1-1,2.1-2.1v-20.7
			c0-1.2-1-2.1-2.1-2.1h-34.7c-1.2,0-2.1,1-2.1,2.1l-10,20.7C461.8,1047.8,462.7,1048.7,463.9,1048.7z"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="341.8" y1="1045.7" x2="462.8" y2="1045.7"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M436.5,1083.7H365c-1.8,0-3.3-1.5-3.3-3.3
			l-3-20.4c0-1.8,1.5-3.3,3.3-3.3h77.4c1.8,0,3.3,1.5,3.3,3.3l-3,20.4C439.8,1082.3,438.3,1083.7,436.5,1083.7z"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="358.8" y1="1060.7" x2="442.8" y2="1060.7"/>
		<rect x="378.8" y="1062.7" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="47" height="18"/>
		<rect x="319.8" y="1099.7" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="31" height="6"/>
		<rect x="453.8" y="1099.7" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="30" height="6"/>
		<line fill="none" stroke="#020202" stroke-miterlimit="10" x1="350.2" y1="1100.7" x2="454.2" y2="1100.7"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M319.8,1100.7h-7c-1.8,0-3.6,0.8-4.7,2.2
			c-3.6,4.4-11.3,13.4-21.3,18.8"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M484.2,1100.7h7c1.8,0,3.6,0.8,4.7,2.2
			c3.6,4.4,11.3,13.4,21.3,18.8"/>
		<g>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M282.8,1098.7c0,0,9-7,18-8h102"/>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M520.2,1098.7c0,0-9-7-18-8h-102"/>
		</g>
		<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="402.2" cy="1031.2" r="8.5"/>
		<path fill="#FFFFFF" stroke="#020202" stroke-miterlimit="10" d="M294.2,1006.2h-4v-5.9c0-2.8-2.2-5.1-4.9-5.1c0,0-0.1,0-0.1,0
			h-13.8c-2,0-3.9,0.8-5.2,2.4c-0.6,0.7-1,1.6-1,2.6v12.8c0,1.2,0.4,2.4,1.2,3.3c0.5,0.5,1.1,0.9,1.8,0.9h21L294.2,1006.2z"/>
		<path fill="#FFFFFF" stroke="#020202" stroke-miterlimit="10" d="M509.8,1006.7h4v-5.9c0-2.8,2.2-5.1,4.9-5.1c0,0,0.1,0,0.1,0
			h13.8c2,0,3.9,0.8,5.2,2.4c0.6,0.7,1,1.6,1,2.6v12.8c0,1.2-0.4,2.4-1.2,3.3c-0.5,0.5-1.1,0.9-1.8,0.9h-21L509.8,1006.7z"/>
	</g>
</g>
<g id="Layer_5">
	<g>
		<path fill="none" stroke="#020202" stroke-miterlimit="10" d="M511.7,312.9c-13-19-40-33-40-33c-16-10-44-12-44-12h-27h-4h-27
			c0,0-28,2-44,12c0,0-27,14-40,33l-7,15v147v261v70c0,0,1,26,4,45c0,0-1,36,36,46c0,0,51,5,78,6c27-1,82-6,82-6c37-10,36-46,36-46
			c3-19,4-45,4-45v-70v-261v-147L511.7,312.9z"/>
		<path fill="#C1C1C1" stroke="#000000" stroke-miterlimit="10" d="M489.8,417.9c-11.4-17.3-38.3-25.2-52.6-28.3
			c-5.3-1.1-10.7-1.7-16.1-1.7h-18.9h-6.5h-18.9c-5.4,0-10.8,0.6-16.1,1.7c-14.4,3.1-41.2,11-52.6,28.3c-2.7,4.1-3.8,9-3.3,13.8
			l8,87.4c0.4,4,3.4,7.3,7.4,7.9c2.9,0.4,6.9,0.3,12-1.1c0,0,36.8-8.1,64.5-7.2l0,0.2c0.7-0.1,1.5-0.1,2.2-0.1
			c0.8,0,1.5,0.1,2.2,0.1l0-0.2c27.7-0.9,64.5,7.2,64.5,7.2c5.2,1.5,9.1,1.6,12,1.1c4-0.6,7.1-3.9,7.4-7.9l8-87.4
			C493.6,426.8,492.5,421.9,489.8,417.9z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M486.9,423.9c-11-15.9-36.9-23.2-50.8-26
			c-5.1-1-10.3-1.6-15.5-1.6h-18.3h-6.3h-18.3c-5.2,0-10.4,0.5-15.5,1.6c-13.9,2.8-39.8,10.1-50.8,26c-2.6,3.7-3.6,8.2-3.2,12.7
			l7.7,80.3c0.4,3.7,3.3,6.7,7.2,7.3c2.8,0.4,6.6,0.3,11.6-1.1c0,0,35.5-7.4,62.2-6.6l0,0.2c0.7,0,1.4-0.1,2.2-0.1
			c0.7,0,1.5,0.1,2.2,0.1l0-0.2c26.7-0.8,62.2,6.6,62.2,6.6c5,1.4,8.8,1.5,11.6,1.1c3.9-0.6,6.8-3.6,7.2-7.3l7.7-80.3
			C490.5,432.2,489.5,427.7,486.9,423.9z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.1,293.3c-21-24.8-61.3-23.7-66.1-23.5
			c-4.8-0.2-45.1-1.3-66.1,23.5c-0.3,0.3,0.1,0.8,0.5,0.6c6.2-3.2,30.3-13.7,65.2-13.1v0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0v0
			c34.9-0.6,58.9,9.8,65.2,13.1C465,294.1,465.4,293.7,465.1,293.3z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M325.4,291.8c0.6,0,1,0.7,0.7,1.2c-3.6,6.2-20.5,36.6-21.3,59.9v3.6
			c0,1.4-1.2,2.6-2.6,2.5c-2.9-0.2-7.5-1.2-11-5.9c-1.8-2.3-2.7-5.1-3.1-8.1c-0.5-4-0.6-10.8,1.6-19.8c1.5-6.1,4.6-11.7,8.8-16.4
			C304.9,301.7,315.8,291.5,325.4,291.8z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M475,291.8c-0.6,0-1,0.7-0.7,1.2c3.6,6.2,20.5,36.6,21.3,59.9v3.6
			c0,1.4,1.2,2.6,2.6,2.5c2.9-0.2,7.5-1.2,11-5.9c1.8-2.3,2.7-5.1,3.1-8.1c0.5-4,0.6-10.8-1.6-19.8c-1.5-6.1-4.6-11.7-8.8-16.4
			C495.5,301.7,484.6,291.5,475,291.8z"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.7,286.9c0,0-33.6-0.8-61.5,13.9c-6.9,3.6-12.6,9-16.6,15.7
				c-5.9,10-13.5,27.9-16.9,57.4l-4.4,33.6c-0.6,4.8-0.5,9.7,0.6,14.4c0.2,1,1.5,4,1.8,5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M398.9,286.9c0,0,32.8-0.8,59.9,13.9c6.7,3.6,12.3,9,16.2,15.7
				c5.8,10,13.2,27.9,16.5,57.4l4.3,33.6c0.6,4.8,0.5,9.7-0.6,14.4c-0.2,1-1.5,4-1.8,5"/>
		</g>
		<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="319.7,526.9 318.7,827.9 481.7,827.9 481.7,525.4 		"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.7,827.9c0,0,12,29,83,27"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M481.7,827.9c0,0-11,29-83,27"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M296,429.5c2.1,11.8,14.8,82.5,15.7,95.3l2,277.8
				c0,2.1-0.5,4.2-1.4,6.1c-2.7,5.4-6.6,16.1-10.6,17.1c-3.5,0.9-7-3-8-7l-4-264v-132c0-0.4,0.5-0.6,0.7-0.3l4.1,4.1
				C295.3,427.4,295.8,428.4,296,429.5z"/>
			<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="312.7,604.9 290.3,589.1 290.7,613.9 312.7,620.9 			
				"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="312.7" y1="611.9" x2="290.7" y2="602.9"/>
			<path fill="#939393" stroke="#000000" stroke-miterlimit="10" d="M289.7,422.9l0.1,17l7.4,0.2c0.4,0,0.6-0.3,0.6-0.7l-1.9-9.8
				c-0.2-0.9-0.6-1.7-1.2-2.3l-4-4.4C290.4,422.7,289.7,422.5,289.7,422.9z"/>
			<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="312.7,736.9 292.4,739.8 292.6,752.9 312.7,748.9 			
				"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="312.7" y1="714.9" x2="291.7" y2="706.9"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.4,429.5c-2.1,11.8-14.8,82.5-15.7,95.3l-2,277.8
				c0,2.1,0.5,4.2,1.4,6.1c2.7,5.4,6.6,16.1,10.6,17.1c3.5,0.9,7-3,8-7l4-264v-132c0-0.4-0.5-0.6-0.7-0.3l-4.1,4.1
				C504.1,427.4,503.6,428.4,503.4,429.5z"/>
			<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="486.7,604.9 509.1,589.1 508.7,613.9 486.7,620.9 			
				"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="486.7" y1="611.9" x2="508.7" y2="602.9"/>
			<path fill="#939393" stroke="#000000" stroke-miterlimit="10" d="M509.7,422.9l-0.1,17l-7.4,0.2c-0.4,0-0.6-0.3-0.6-0.7l1.9-9.8
				c0.2-0.9,0.6-1.7,1.2-2.3l4-4.4C509,422.7,509.7,422.5,509.7,422.9z"/>
			<polygon fill="#939393" stroke="#000000" stroke-miterlimit="10" points="486.7,736.9 507,739.8 506.8,752.9 486.7,748.9 			"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="486.7" y1="714.9" x2="507.7" y2="706.9"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M488.5,871l-7.7-19.3c-0.6-1.4-2.3-2-3.6-1.2
			c-6.9,4.1-38.8,14.8-81.4,16.4c-42.6-1.6-70.6-12.3-77.5-16.4c-1.3-0.8-3.1-0.2-3.6,1.2l-7.7,19.3c-0.7,1.6,0.1,3.4,1.7,4.2
			c41.1,18.7,88.1,16.7,88.1,16.7s48.9,2,90-16.7C488.4,874.4,489.1,872.6,488.5,871z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M290.9,838.6l2.5,25.7c0.2,2.5,1.3,4.8,3.1,6.6
			c5.3,5.3,18,16,37.2,18.9l0,0c1,3-1.5,3.1-3.3,2.9c-4.6-0.6-14.7-0.9-32-13c-4-2.8-6.7-6.9-8-11.4c-2.6-9.3-3.7-25-1.1-30
			C289.7,837.6,290.8,837.8,290.9,838.6z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M507.9,837.6l-2.5,25.7c-0.2,2.5-1.3,4.8-3.1,6.6
			c-5.3,5.3-18,16-37.2,18.9l0,0c-1,3,1.5,3.1,3.3,2.9c4.6-0.6,14.7-0.9,32-13c4-2.8,6.7-6.9,8-11.4c2.6-9.3,3.7-25,1.1-30
			C509.1,836.6,508,836.8,507.9,837.6z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M293.7,471.9c0,0-15.1,2.4-26.1,12.1c-3,2.6-5.2,6.2-5.8,10.2
			c-0.2,1.5-0.3,2.9,0.2,3.9c0.3,0.6,1,0.7,1.5,0.5l30.2-12.7V471.9z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M514.7,471.9c0,0,15.1,2.4,26.1,12.1c3,2.6,5.2,6.2,5.8,10.2
			c0.2,1.5,0.3,2.9-0.2,3.9c-0.3,0.6-1,0.7-1.5,0.5l-30.2-12.7V471.9z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M323.7,527.9c0,0,10,180,8,300"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M478,527.9c0,0-10,180-8,300"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="289.7" y1="601.9" x2="277.7" y2="597.9"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="507.7" y1="602.9" x2="519.7" y2="598.9"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="292.7" y1="752.9" x2="278.7" y2="750.9"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="505.7" y1="752.9" x2="519.7" y2="750.9"/>
	</g>
</g>
      </>
    ),
  },
};
