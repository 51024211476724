import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import { CircularProgress } from "@material-ui/core";
import { IAppState } from "../../../../store";

interface IProps {
  stripePaymentIntentSecret: string;
  bookingId: string;
}

const StripePayment: React.FC<IProps> = (props) => {
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const { stripeAccountId } = organisation;

  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  const options: any = {
    clientSecret: props.stripePaymentIntentSecret
  }

  if (!props.stripePaymentIntentSecret) {
    return <CircularProgress />
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm bookingId={props.bookingId} />
    </Elements>
  )
}

export default StripePayment;