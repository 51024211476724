import { Dialog, DialogContent, DialogTitle, Divider, Fab, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
interface IModelProps {
  title: string;
  open: boolean;
  handleClose(): void;
  images: any[];
}
export default function ImageGallery(props: IModelProps) {
  const { open, images, title, handleClose } = props;
  const [previewImage, setPreviewImage] = useState(images[0]);

  const handlePreviewImage = (image: string) => {
    setPreviewImage(image);
  };
  return (
    <Dialog
      open={open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      maxWidth="lg"
    >
      <DialogTitle>
        <div className="flex cross-center space-between">
          <Typography variant="h4">Image Preview</Typography>
          <IconButton onClick={props.handleClose} size="small" edge="end">
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 600, background: "#fff" }}>
          <div style={{ width: 600, height: 340, background: "#000", borderRadius: 8, overflow: "hidden" }}>
            <img style={{ height: "100%", width: "100%", objectFit: "contain" }} src={previewImage} />
          </div>
        </div>
        <div style={{ margin: "10px 0 10px 0", display: "flex", justifyContent: "center", backgroundColor: "#e9e9e9", padding: 10, borderRadius: 8 }}>
          {images &&
            images.map((image: string, index: number) => {
              return (
                <div
                  onClick={() => handlePreviewImage(image)}
                  key={index}
                  style={{ width: 100, height: 70, borderRadius: 8, overflow: "hidden", margin: 5, cursor: "pointer", border: "1px solid" }}>
                  <img src={image} className="img-cover" />
                </div>
              );
            })}
        </div>
      </DialogContent>
    </Dialog>
  );
}
