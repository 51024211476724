import { Grid, IconButton, TextField, Button, CircularProgress, withStyles, createStyles, InputBase, Typography } from "@material-ui/core"
import { Field, FieldProps, Form, Formik } from "formik"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as Yup from 'yup';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthStateAction } from "../../../actions/auth/actions";
import { AuthStates } from "../../../reducers/auth/types";
import { IAppState } from "../../../store";
import { CREATE_CUSTOMER } from "../../../graphql/customers/createCustomerMutation";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { ApolloError, useMutation } from "@apollo/client";
import { getDefaultCountryCode } from "../../../utils/localized.syntex";
import AddressPicker from "../../AddressPicker/AddressPicker";
import { IAddress } from "../../../reducers/bookings/types";
import { useSnackBar } from "../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../utils";
const StyledInput = withStyles(() =>
  createStyles({
    root: {
      border: "1px solid #c0c0c0",
      borderRadius: 6,
      padding: "8px 10px",
      marginTop: 5,
      '&.Mui-focused': {
        borderColor: "var(--primary-color)"
      },
      '&.Mui-error': {
        borderColor: "#D13135",
        color: "#D13135"
      }
    },
  }),
)(InputBase);

const initialRegistartionValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: {
    country: "",
    phone: ""
  },
  location: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
}

export const AuthRegister: React.FC = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const dispatch = useDispatch();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer);
  const [values, setValues] = useState(initialRegistartionValues);
  const { country } = website.consumerWebsite.organisation.address;
  const [phoneError, setPhoneError] = useState<string>("");
  const [countryCode, setCountry] = useState<any>(
    getDefaultCountryCode(country)
  );
  const snackbar = useSnackBar();

  const [createCustomer, { loading: creatingCustomer }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: ({ consumerCreateCustomer }) => {
      setIsRegistered(true);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const schema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" })
          }
          return true;
        })
        .required("Phone number is required")
    }),
  });

  const getCountryFromLocaleString = (localeString: string) => {
    const alpha2 = localeString.slice(-2);
    if (alpha2) {
      return alpha2;
    }
    return "GB"
  }

  return (
    <>
      {!isRegistered ? (
        <div style={{ maxWidth: 400, margin: "auto" }}>
          <Grid container style={{ padding: "0 48px", marginTop: 16 }}>
            <IconButton aria-label="close" style={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: "gray"
            }} onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))}>
              <ArrowBackIcon />
            </IconButton>
            <Grid item xs={12}>
              <h2 style={{ textAlign: "center", padding: 0, margin: 0 }}>Register Now</h2>
            </Grid>
          </Grid>
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={values}
            onSubmit={formValues => {
              const updatedValues = {
                ...formValues,
                phoneNumber: {
                  ...formValues.phoneNumber,
                  country: countryCode
                }
              }
              if (formValues.location.fullAddress && (!formValues.location.street || !formValues.location.zipcode)) {
                return snackbar({
                  message: "Street/zipcode is required",
                  variant: SnackBarVariant.ERROR,
                });
              }
              createCustomer({
                variables: {
                  customer: updatedValues
                }
              });
            }}
          >
            {(props) => (
              <Form>
                <Grid container style={{ padding: "0 48px" }}>
                  <Grid item xs={12} style={{ marginTop: 24 }}>
                    <Field name="firstName">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="First Name"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Field name="lastName">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="Last Name"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Field name="email">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="Email"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                    <Field
                      component={StyledInput}
                      name={"phoneNumber.phone"}
                      required
                    >
                      {({ field, meta: { touched, error } }: FieldProps) => {
                        return (
                          <div className={`phone-input-container`} style={{ marginTop: 5 }}>
                            <div style={{ border: `1px solid ${(touched && Boolean(error)) ? '#D13135' : '#c0c0c0'}`, borderRadius: 6 }}>
                              <PhoneInput
                                {...field}
                                international={false}
                                defaultCountry={getCountryFromLocaleString(website.consumerWebsite.organisation.locale)}   //
                                placeholder="Enter phone number *"
                                className={(touched && Boolean(error)) ? "PhoneInputInputError" : ""}
                                name={"phoneNumber.phone"}
                                onChange={(val) => {
                                  if (val && isValidPhoneNumber(val)) {
                                    setPhoneError("");
                                  }
                                  setValues({
                                    ...props.values,
                                    phoneNumber: {
                                      ...props.values.phoneNumber,
                                      phone: val
                                    }
                                  });
                                }}
                                onCountryChange={(val) => {
                                  if (val) {
                                    setCountry(val)
                                  }
                                }}
                              />
                            </div>
                            <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                    <AddressPicker
                      fieldName={"location"}
                      onChange={(address: IAddress) => {
                        if (address) {
                          setValues({ ...props.values, location: address })
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    size="large"
                    style={{
                      background: "#3A3A3A",
                      marginTop: 20,
                      marginBottom: 16,
                      boxShadow: "none",
                      fontSize: 12,
                    }}>
                    {creatingCustomer ? <CircularProgress /> : " Register Now"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      ) : (
        <div style={{ maxWidth: 400, margin: "auto", padding: 16 }}>
          <Grid container style={{ padding: "0 48px", marginTop: 16 }}>
            <Grid item xs={12}>
              <h2 style={{ textAlign: "center", padding: 0, margin: 0 }}>Thank you</h2>
            </Grid>
          </Grid>
          <div style={{ padding: "1 10%" }}>
            <p style={{}}>We have emailed you on how to get started.</p>
            <p style={{}}>If you do not see the email in a few minutes, check your "Junk mail" or "spam folder".</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#3A3A3A",
                marginTop: 20,
                boxShadow: "none",
                fontSize: 12,
                marginBottom: 16
              }}
              onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))}
            >
              Sign In
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
