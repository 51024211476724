
import React from "react"

export const ScooterSvg = {
  interior: {
    width: 612,
    height: 792,
    svg: (
      <>
        <g id="Layer_1">
          <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,271.2c0,0,22.3,4,35.6,25.7c0,0,4.6,7.9,4.5,16
			c0,0-0.8,12.5-9.4,27.2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,324.7h4.6c0,0,18.9,6.4,31.7,21.4l0.8,20.3
			c0,0,0.3,2.9-1.9,3.2h-35.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,271.2c0,0-22.3,4-35.6,25.7c0,0-4.6,7.9-4.5,16
			c0,0,0.8,12.5,9.4,27.2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,324.7h-4.6c0,0-18.9,6.4-31.7,21.4l-0.8,20.3
			c0,0-0.3,2.9,1.9,3.2h35.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,240.8c0,0,7.7-1.4,12.5,10.1l8.5,30.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,240.8c0,0-7.7-1.4-12.5,10.1l-8.5,30.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M288.5,268.8c0,0-1-3.5-4.5-3.5c0,0-3.1,0-3.3,2.4v16.8" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M323.7,268.8c0,0,1-3.5,4.5-3.5c0,0,3.1,0,3.3,2.4v16.8" />
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,283.5l15.6,11.7v20.4c0,0-9.2-5.9-16.2-5.2" />
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,283.5l-15.6,11.7v20.4c0,0,9.2-5.9,16.2-5.2" />
            </g>
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="306.1" y1="271.2" x2="306.1" y2="283.5" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="306.1" y1="309.4" x2="306.1" y2="324.7" />
            <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="284.6,282.1 284.6,285.3 271.4,299.5 269.7,299.5 		" />
            <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="327.7,282.1 327.7,285.3 340.9,299.5 342.6,299.5 		" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="267.6" y1="305.6" x2="272.3" y2="334.2" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="345" y1="305.6" x2="340.2" y2="334.2" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,347.2c0,0,11.5-0.9,24.8,8.9l0.5,2.8
			c0.2,1.1,0.1,2.2-0.5,3.1c-0.4,0.6-0.9,1.3-1.9,1.7h-23"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,347.2c0,0-11.5-0.9-24.8,8.9l-0.5,2.8
			c-0.2,1.1-0.1,2.2,0.5,3.1c0.4,0.6,0.9,1.3,1.9,1.7h23"/>
            <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="296.7" cy="356.3" rx="6" ry="3.4" />
            <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="315" cy="356.3" rx="6" ry="3.4" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,232.5c0,0,6.5,0.3,11.4,2.2v13.4" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,232.5c0,0-6.5,0.3-11.4,2.2v13.4" />
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M239,369.7l20.8-5.1c0,0,0.6-0.5,1.2-1.9c0.9-2.1,0.7-4.5-0.1-6.6
				c-0.5-1.1-1.1-2.2-1.7-2.4C259.1,353.6,246.1,352.2,239,369.7z"/>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M260.4,354.9l12.5-5.4c0,0,0.6-2.2,2.6-2.4c0,0,6.7-1.1,6.9,3.3
				c0,0,0.8,2.9-2.6,5.6c0,0-5.4,1-6.3-2.4l-11.9,4.8"/>
              <path fill="none" stroke="#020202" stroke-width="1.5" stroke-miterlimit="10" d="M269.8,349.9c0,0-10,3.6-18.2,1.7" />
              <circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="250.2" cy="351.5" r="1.5" />
            </g>
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M373.4,369.7l-20.8-5.1c0,0-0.6-0.5-1.2-1.9
				c-0.9-2.1-0.7-4.5,0.1-6.6c0.5-1.1,1.1-2.2,1.7-2.4C353.3,353.6,366.3,352.2,373.4,369.7z"/>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M352,354.9l-12.5-5.4c0,0-0.6-2.2-2.6-2.4c0,0-6.7-1.1-6.9,3.3
				c0,0-0.8,2.9,2.6,5.6c0,0,5.4,1,6.3-2.4l11.9,4.8"/>
              <path fill="none" stroke="#020202" stroke-width="1.5" stroke-miterlimit="10" d="M342.7,349.9c0,0,10,3.6,18.2,1.7" />
              <circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="362.2" cy="351.5" r="1.5" />
            </g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,326.8c0,0,18.8,3.9,20,26" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,326.8c0,0-18.8,3.9-20,26" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M269.1,367.5c0,0-11.3,46.3-4.3,73.5l0.3,3.3
			c0,0,1.3,48.9,15.2,73.9c0,0,8.3,16.6,25.8,17.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M343.2,367.5c0,0,11.3,46.3,4.3,73.5l-0.3,3.3
			c0,0-1.3,48.9-15.2,73.9c0,0-8.5,16.6-26,17.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M304.8,380.5h4.9c0,0,7.3,1.8,11.3,7.8c0,0,13,21.8,16.8,39.8v26.9
			c0,0-0.5,28.5-7.1,41.2c0,0-7.9,14.6-25.8,16.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306,380.5h-4.9c0,0-7.3,1.8-11.3,7.8c0,0-13,21.8-16.8,39.8v26.9
			c0,0,0.5,28.5,7.1,41.2c0,0,7.9,14.6,25.8,16.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M304.8,387.8h3.7c0,0,7.1,1.3,11,14.8c0,0,11.7,38.4,15.6,45.6v24.1
			c0,0-2.2,33.1-29.6,35.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306,387.8h-3.7c0,0-7.1,1.3-11,14.8c0,0-11.7,38.4-15.6,45.6v24.1
			c0,0,2.3,33.1,29.7,35.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M275.8,448.2c0,0,27.1,10.7,59.3,1.3" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="346.6" y1="442.2" x2="337.8" y2="428.1" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="264.7" y1="442.2" x2="273.5" y2="428.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M321.3,530.4c0,0-0.9,20.6-7.7,28.4h-14.6c0,0-6-8.7-8.1-28.4" />
          </g>
        </g>
        <g id="Layer_2">
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63.8" y1="261.5" x2="74.4" y2="254.5" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="245.8" x2="59.8" y2="257.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="40.7" y1="265.1" x2="29.4" y2="259.1" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="26.6" y1="272.1" x2="39.3" y2="270.4" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="52.8" y1="283.3" x2="51.7" y2="296" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.7" y1="293.5" x2="58.2" y2="282.5" />
              </g>
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63.8" y1="496.8" x2="74.4" y2="489.8" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="481.1" x2="59.8" y2="493" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="40.7" y1="500.4" x2="29.4" y2="494.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="26.6" y1="507.4" x2="39.3" y2="505.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="52.8" y1="518.6" x2="51.7" y2="531.3" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.7" y1="528.8" x2="58.2" y2="517.8" />
              </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M85.6,303.8l-6.9-10.3c-0.1-0.1-0.1-0.2-0.1-0.4
			c-1.7-9.1,0.6-13,0.6-13c2.4-4.6,9.2-8,11.1-8.9c0.3-0.1,0.5-0.4,0.5-0.8c0.3-5.5-1.6-12.3-1.8-13c0,0,0-0.1,0-0.1L87,244.2
			c-0.1-0.4,0.1-0.7,0.4-0.9h0c0.9-0.5,2-0.4,2.7,0.4L98,253c0,0,0.1,0.1,0.1,0.1c10.3,18,7.7,38.5,7.7,38.5L85.6,303.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M111,285.6c0,0,0.8-13.1-6.7-28.9c0,0-6.5-10.9-13.4-16.3
			c0,0-2.6-0.8-2.4,2.2"/>

            <rect x="71.3" y="261" transform="matrix(-0.4573 0.8893 -0.8893 -0.4573 357.3746 343.5254)" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="5.2" height="39.6" />

            <rect x="51" y="265.9" transform="matrix(-0.4573 0.8893 -0.8893 -0.4573 316.7334 346.4957)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.3" height="8" />
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="91,291.2 92.3,288.7 102.3,293.9 99.9,295.7 		" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M185.4,369h4.9l15.9-18.5c0.6-0.7,0.7-1.7,0.2-2.4
			c-2.5-4.1-10.9-17-20.9-24h-5.2c-1,0-1.9,0.7-2,1.7l-1.2,6c0,0-1.8,3.3-2.8,1.3l-1.2-4.7c-0.4-1.6-1-3.1-1.8-4.6L157,299
			l-10.8-13.1c-1.5-1.8-3.2-3.4-5.2-4.7l-13.9-9.3c-1.6-1.1-3.6-1.2-5.3-0.4c-1.9,0.9-4.4,2.5-6.6,5.3c0,0-4.3,11.2-8.3,14
			c0,0-10.9,8.2-22,13.2l-24.3,13.6c0,0-12.1,6.4-14.5,19c0,0-2.3,14.3-3,22v28.2c0,0,4.3,33.4,9.2,44.9l14,12l8.5,7.5l5.6-5.4
			c0,0-10.9-45.1-11.1-49.5L69.2,382l0.2-28.4c0,0,1.2-5.3,7.8-9.4l9-5.1c0,0,34.5-4.5,43.6-3.6l29.8,14.4c0,0,3.1,1.9,6.1-0.2
			l15.1,17C180.8,366.7,183.7,369,185.4,369z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M160,304.6v11.6c0,0.3-0.4,0.4-0.5,0.2
			c-2.1-3-10.1-14.1-14-20.8c-1.2-2.1-2.1-4.4-2.5-6.8c-0.4-2.2-0.8-4.9-0.8-6.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M185.5,345.6c0,0-2.9,3.5-3.9,6.9
			c-0.1,0.4-0.7,0.5-0.9,0.1l-8.3-15.1c-0.1-0.3-0.5-0.2-0.6,0l-6.1,12.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M172.9,328.2l-4.7,7.8c0,0-1.5,1.9-4.2,0.3
			c-0.3-0.2-0.5-0.4-0.7-0.7c-2.3-3.4-18.8-27.6-28.2-34.4c0,0-3.9-3.5-6.5-4.8c-0.6-0.3-1.4-0.3-2,0l-19.3,9.8c0,0-2.9,1.4-3.4-0.7
			l3.3-15.1"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M170.1,340.8c0,0,0,0-0.1,0.1
			c-1.6,1.8-4.5,1.9-6.2,0.2l-15.4-15c-0.1-0.1-0.2-0.1-0.2-0.2l-14.4-12.1c-0.5-0.4-1-0.7-1.6-0.9c-1.3-0.4-3.8-0.8-6.2-0.1
			l-24.3,7.4c-0.7,0.2-1.5,0.3-2.3,0.1c-1.1-0.3-2.8-0.8-4.6-2c-2.4-1.5-4-3.9-4.5-6.7c-0.7-3.8-1.7-9.3-1.7-9.3"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="124" y1="271.4" x2="128" y2="296.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M134.1,300.3l-3-18c-0.4-2.5-1.8-4.9-3.8-6.4
			c-0.8-0.6-1.7-1.2-2.9-1.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M157.1,334.4v-4.4l-22.5-22.2
			c0,0-0.1-0.1-0.2-0.2c-2.8-2.2-6.4-3-9.9-2.4c-3.5,0.6-8.4,1.6-11.1,2.7c0,0-9.6,3.3-14,12.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M99.4,319.8c0,0-2-5.9-2-11.7c0-2.1,0.6-4.2,1.7-6
			c1.4-2.4,3.3-6.1,3.2-8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.4,316c0,0,0.8,11.3-6.8,23.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.4,316l-21.4,14.9c-3,2.1-5.4,5.9-6,8.5
			c-0.1,0.3-0.2,0.6-0.2,0.9c0,0,1,42.6,5.4,60.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.8,308.4c0,0-9.3,1.8-16.8,6.9c0,0-10.1,5-18.1,20.1
			c-3,5.6-4.8,11.7-5.6,18c-0.9,7.4-1.9,18.9-0.8,29.6c0,0,5.6,40.1,11.4,55"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="186.4" cy="363.3" r="3.9" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="186.4" cy="363.3" r="2.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.8,352.6l3.5,0c0,0,9.9,3.9,10.8,13.2c0.2,1.6-0.8,3.3-2.4,3.7
			c-0.1,0-0.3,0.1-0.5,0.1h-7.4c-1.6,0-3.2-0.3-4.6-0.9c0,0,0,0,0,0c-1.6-0.7-2.1-2.3-2.1-4.1c0-0.6,0.1-1.1,0.5-1.3
			c0,0,1.3-2.6,1.3-3.9c0,0-0.5-3.3-0.4-5.7C215.6,353.1,216.2,352.6,216.8,352.6z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M214.7,355.9l-18.2-0.7c0,0-3.3-0.8-3.5-2.6
			c0,0-0.1-0.5-0.2-1.3c-0.3-3,4.3-3.8,4.9-0.9c0.1,0.4,0.1,0.8,0.1,1.3c0,0,0,0,0,0l18,0.8c0.1,0,0.2,0.1,0.1,0.2l-0.4,2.5
			C215.5,355.6,215.1,355.9,214.7,355.9z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M184.8,324.4c0,0,10,16.1,11.2,24.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M178.6,324.9c0,0,5.7,12.6,6.9,20.7
			c0,0,4.1,0.8,4.8,4.3c0,0,1.6,3.5,3.4,3.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M148.1,513.7c0,0,7.5-1.3,8.8-5.2l-3.4-54.3c0,0-4.3-21.7-4.5-29.7
			V396c0,0-2.9-5.9-10.5-7.9c0,0-5-0.6-9.7,0c0,0-2.9,1.1-5.9,0.4c0,0-2.6-1-4.1,0l-14.7,9.8c0,0-4.3,2.3-8.7-0.3l-22.9-13v-1.5
			h-3.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="119.4" y1="430.8" x2="124.9" y2="388.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128.7,388.8c0,0,3.6,31.1,9.8,49.4c0,0,5.1,14.4,15,17.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M72.6,385.1v6.4L94.1,404c0,0,0.1,0,0.3,0.1
			c6.2,1.9,12.9-0.1,17-5.1l9-10.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="69.3" y1="396.7" x2="85.6" y2="409.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M85.6,409.7c0,0,7.3-1,9.3-0.8l29,25.8c0,0,9.1,9.7,13.4,25.1
			c0,0,6.1,20.3,10.1,54.5c0,0-1.7,16.6-3.4,22c0,0-7-0.1-15.6-5.9l-7.2-6.3l-27.4-55.8c0,0-4-6.8-4.6-13.7L85.6,409.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M120.4,523c0,0-7.5-80-34.5-112.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.7,535.4l-4.2-23c0,0-4-41.5-13-59.9l-29.3-42.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M131.7,532.5v-6c0-3.7,1-7.3,3.1-10.3c1-1.4,2.2-2.7,3.7-3.7" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M61.3,439.3l1.4,13.5l11.4,22.5l7.5,49l-0.9,12L70,537.4
			l-4.6-11.2c0,0-5.3,2.2-8.3-0.5c0,0-5.1-2.7-7.8-17c0,0-2.4-17.6-3.3-28.2c-0.3-3.8-0.2-7.6,0.3-11.3c1.1-7.8,3.6-23.3,8.2-35.6
			L61.3,439.3z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="56.4,435.1 56.4,476.8 56.4,477.8 65.4,526.2 81.6,524.3 
					"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="73.8" y1="474.7" x2="56.4" y2="476.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="56.4" y1="458.1" x2="64.4" y2="456" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="77.4" y1="448.6" x2="88.7" y2="451.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="70.3" y1="467.7" x2="93.2" y2="467.7" />
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="78.1,501.6 77.2,495.1 101.4,484.2 104.1,489.9 		" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="81.6" cy="479" r="5.2" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="113" cy="520.4" r="2.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M110.1,502.1c0,0-0.3,9.9-1,12.8c0,0-2.7,8-16,19.7
			c0,0-14.6,12.1-20.1,21.1c-0.5,0.8-0.7,1.6-0.7,2.5l0,0c0,1.6,2.2,2.2,2.9,0.7c0,0,0,0,0-0.1c0,0,8-11.7,15.8-16.9l29.8-17.9
			L110.1,502.1z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="105.1,533.6 113.5,533.6 113.5,528.3 		" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="98.7" y1="485.5" x2="101.5" y2="491.4" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="95.8" y1="486.8" x2="98.7" y2="492.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.2" y1="488.1" x2="96" y2="494" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="90.6" y1="489.3" x2="93.5" y2="495.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.8" y1="490.6" x2="90.6" y2="496.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.7" y1="491.7" x2="87.5" y2="497.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="82.2" y1="492.9" x2="85" y2="498.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="79.5" y1="494.2" x2="82.4" y2="500.1" />
          </g>
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="550.2" y1="261.5" x2="539.5" y2="254.5" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.6" y1="245.8" x2="554.1" y2="257.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="573.3" y1="265.1" x2="584.6" y2="259.1" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="587.3" y1="272.1" x2="574.7" y2="270.4" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="561.2" y1="283.3" x2="562.3" y2="296" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.3" y1="293.5" x2="555.8" y2="282.5" />
              </g>
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="550.2" y1="496.8" x2="539.5" y2="489.8" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.6" y1="481.1" x2="554.1" y2="493" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="573.3" y1="500.4" x2="584.6" y2="494.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="587.3" y1="507.4" x2="574.7" y2="505.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="561.2" y1="518.6" x2="562.3" y2="531.3" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.3" y1="528.8" x2="555.8" y2="517.8" />
              </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M528.4,303.8l6.9-10.3c0.1-0.1,0.1-0.2,0.1-0.4
			c1.7-9.1-0.6-13-0.6-13c-2.4-4.6-9.2-8-11.1-8.9c-0.3-0.1-0.5-0.4-0.5-0.8c-0.3-5.5,1.6-12.3,1.8-13c0,0,0-0.1,0-0.1l1.9-13.1
			c0.1-0.4-0.1-0.7-0.4-0.9l0,0c-0.9-0.5-2-0.4-2.7,0.4L516,253c0,0-0.1,0.1-0.1,0.1c-10.3,18-7.7,38.5-7.7,38.5L528.4,303.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M502.9,285.6c0,0-0.8-13.1,6.7-28.9c0,0,6.5-10.9,13.4-16.3
			c0,0,2.6-0.8,2.4,2.2"/>

            <rect x="537.5" y="261" transform="matrix(0.4573 0.8893 -0.8893 0.4573 542.8383 -327.9317)" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="5.2" height="39.6" />

            <rect x="559.7" y="265.9" transform="matrix(0.4573 0.8893 -0.8893 0.4573 544.6509 -352.7328)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.3" height="8" />
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="522.9,291.2 521.7,288.7 511.7,293.9 514.1,295.7 		" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M428.6,369h-4.9l-15.9-18.5c-0.6-0.7-0.7-1.7-0.2-2.4
			c2.5-4.1,10.9-17,20.9-24h5.2c1,0,1.9,0.7,2,1.7l1.2,6c0,0,1.8,3.3,2.8,1.3l1.2-4.7c0.4-1.6,1-3.1,1.8-4.6L457,299l10.8-13.1
			c1.5-1.8,3.2-3.4,5.2-4.7l13.9-9.3c1.6-1.1,3.6-1.2,5.3-0.4c1.9,0.9,4.4,2.5,6.6,5.3c0,0,4.3,11.2,8.3,14c0,0,10.9,8.2,22,13.2
			l24.3,13.6c0,0,12.1,6.4,14.5,19c0,0,2.3,14.3,3,22v28.2c0,0-4.3,33.4-9.2,44.9l-14,12l-8.5,7.5l-5.6-5.4c0,0,10.9-45.1,11.1-49.5
			l0.2-14.1l-0.2-28.4c0,0-1.2-5.3-7.8-9.4l-9-5.1c0,0-34.5-4.5-43.6-3.6l-29.8,14.4c0,0-3.1,1.9-6.1-0.2l-15.1,17
			C433.1,366.7,430.2,369,428.6,369z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M454,304.6v11.6c0,0.3,0.4,0.4,0.5,0.2
			c2.1-3,10.1-14.1,14-20.8c1.2-2.1,2.1-4.4,2.5-6.8c0.4-2.2,0.8-4.9,0.8-6.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M428.5,345.6c0,0,2.9,3.5,3.9,6.9
			c0.1,0.4,0.7,0.5,0.9,0.1l8.3-15.1c0.1-0.3,0.5-0.2,0.6,0l6.1,12.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M441,328.2l4.7,7.8c0,0,1.5,1.9,4.2,0.3
			c0.3-0.2,0.5-0.4,0.7-0.7c2.3-3.4,18.8-27.6,28.2-34.4c0,0,3.9-3.5,6.5-4.8c0.6-0.3,1.4-0.3,2,0l19.3,9.8c0,0,2.9,1.4,3.4-0.7
			l-3.3-15.1"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M443.8,340.8c0,0,0,0,0.1,0.1
			c1.6,1.8,4.5,1.9,6.2,0.2l15.4-15c0.1-0.1,0.2-0.1,0.2-0.2l14.4-12.1c0.5-0.4,1-0.7,1.6-0.9c1.3-0.4,3.8-0.8,6.2-0.1l24.3,7.4
			c0.7,0.2,1.5,0.3,2.3,0.1c1.1-0.3,2.8-0.8,4.6-2c2.4-1.5,4-3.9,4.5-6.7c0.7-3.8,1.7-9.3,1.7-9.3"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="490" y1="271.4" x2="486" y2="296.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M479.9,300.3l3-18c0.4-2.5,1.8-4.9,3.8-6.4
			c0.8-0.6,1.7-1.2,2.9-1.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M456.9,334.4v-4.4l22.5-22.2
			c0,0,0.1-0.1,0.2-0.2c2.8-2.2,6.4-3,9.9-2.4c3.5,0.6,8.4,1.6,11.1,2.7c0,0,9.6,3.3,14,12.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M514.5,319.8c0,0,2-5.9,2-11.7c0-2.1-0.6-4.2-1.7-6
			c-1.4-2.4-3.3-6.1-3.2-8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.6,316c0,0-0.8,11.3,6.8,23.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.6,316l21.4,14.9c3,2.1,5.4,5.9,6,8.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0-1,42.6-5.4,60.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M524.2,308.4c0,0,9.3,1.8,16.8,6.9c0,0,10.1,5,18.1,20.1
			c3,5.6,4.8,11.7,5.6,18c0.9,7.4,1.9,18.9,0.8,29.6c0,0-5.6,40.1-11.4,55"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="427.6" cy="363.3" r="3.9" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="427.6" cy="363.3" r="2.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397.1,352.6l-3.5,0c0,0-9.9,3.9-10.8,13.2
			c-0.2,1.6,0.8,3.3,2.4,3.7c0.1,0,0.3,0.1,0.5,0.1h7.4c1.6,0,3.2-0.3,4.6-0.9c0,0,0,0,0,0c1.6-0.7,2.1-2.3,2.1-4.1
			c0-0.6-0.1-1.1-0.5-1.3c0,0-1.3-2.6-1.3-3.9c0,0,0.5-3.3,0.4-5.7C398.4,353.1,397.8,352.6,397.1,352.6z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M399.3,355.9l18.2-0.7c0,0,3.3-0.8,3.5-2.6
			c0,0,0.1-0.5,0.2-1.3c0.3-3-4.3-3.8-4.9-0.9c-0.1,0.4-0.1,0.8-0.1,1.3c0,0,0,0,0,0l-18,0.8c-0.1,0-0.2,0.1-0.1,0.2l0.4,2.5
			C398.5,355.6,398.9,355.9,399.3,355.9z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M429.1,324.4c0,0-10,16.1-11.2,24.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M435.4,324.9c0,0-5.7,12.6-6.9,20.7
			c0,0-4.1,0.8-4.8,4.3c0,0-1.6,3.5-3.4,3.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.9,513.7c0,0-7.5-1.3-8.8-5.2l3.4-54.3c0,0,4.3-21.7,4.5-29.7
			V396c0,0,2.9-5.9,10.5-7.9c0,0,5-0.6,9.7,0c0,0,2.9,1.1,5.9,0.4c0,0,2.6-1,4.1,0l14.7,9.8c0,0,4.3,2.3,8.7-0.3l22.9-13v-1.5h3.4"
            />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="494.5" y1="430.8" x2="489.1" y2="388.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,388.8c0,0-3.6,31.1-9.8,49.4c0,0-5.1,14.4-15,17.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M541.3,385.1v6.4L519.9,404c0,0-0.1,0-0.3,0.1
			c-6.2,1.9-12.9-0.1-17-5.1l-9-10.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="544.7" y1="396.7" x2="528.4" y2="409.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M528.4,409.7c0,0-7.3-1-9.3-0.8l-29,25.8c0,0-9.1,9.7-13.4,25.1
			c0,0-6.1,20.3-10.1,54.5c0,0,1.7,16.6,3.4,22c0,0,7-0.1,15.6-5.9l7.2-6.3l27.4-55.8c0,0,4-6.8,4.6-13.7L528.4,409.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M493.6,523c0,0,7.5-80,34.5-112.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M471.3,535.4l4.2-23c0,0,4-41.5,13-59.9l29.3-42.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M482.3,532.5v-6c0-3.7-1-7.3-3.1-10.3c-1-1.4-2.2-2.7-3.7-3.7" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M552.7,439.3l-1.4,13.5l-11.4,22.5l-7.5,49l0.9,12l10.7,1.1
			l4.6-11.2c0,0,5.3,2.2,8.3-0.5c0,0,5.1-2.7,7.8-17c0,0,2.4-17.6,3.3-28.2c0.3-3.8,0.2-7.6-0.3-11.3c-1.1-7.8-3.6-23.3-8.2-35.6
			L552.7,439.3z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="557.6,435.1 557.6,476.8 557.6,477.8 548.5,526.2 
			532.4,524.3 		"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="540.2" y1="474.7" x2="557.6" y2="476.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="557.6" y1="458.1" x2="549.6" y2="456" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="536.6" y1="448.6" x2="525.3" y2="451.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="543.7" y1="467.7" x2="520.8" y2="467.7" />
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="535.8,501.6 536.8,495.1 512.6,484.2 509.9,489.9 		" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="532.4" cy="479" r="5.2" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="500.9" cy="520.4" r="2.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.8,502.1c0,0,0.3,9.9,1,12.8c0,0,2.7,8,16,19.7
			c0,0,14.6,12.1,20.1,21.1c0.5,0.8,0.7,1.6,0.7,2.5l0,0c0,1.6-2.2,2.2-2.9,0.7c0,0,0,0,0-0.1c0,0-8-11.7-15.8-16.9L493.1,524
			L503.8,502.1z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="508.9,533.6 500.5,533.6 500.5,528.3 		" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="515.3" y1="485.5" x2="512.5" y2="491.4" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="518.1" y1="486.8" x2="515.3" y2="492.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="520.8" y1="488.1" x2="518" y2="494" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="523.3" y1="489.3" x2="520.5" y2="495.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="526.2" y1="490.6" x2="523.3" y2="496.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="529.3" y1="491.7" x2="526.4" y2="497.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="531.8" y1="492.9" x2="529" y2="498.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="534.4" y1="494.2" x2="531.6" y2="500.1" />
          </g>
        </g>
        <g id="Layer_3">
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M294.4,194.1h-11c0,0-1.3-0.1-1.7,1.2V206l-2.4,10.4h-4v3.2h4.9
				c0,0,1.5-0.4,1.9-1.9l2.6-11.8h9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="196.5" x2="294.7" y2="196.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="203.6" x2="294.7" y2="203.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="196" x2="284.6" y2="204.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M317.5,194.1h11c0,0,1.3-0.1,1.7,1.2V206l2.4,10.4h4v3.2h-4.9
				c0,0-1.5-0.4-1.9-1.9l-2.6-11.8h-9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="196.5" x2="317.3" y2="196.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="203.6" x2="317.3" y2="203.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="196" x2="327.3" y2="204.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M271.1,158.1c0,0-11.1,0.1-14.3,2.4v12.7c0,0,3.5,10.1,21.5,17.3"
              />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M256.7,162.9c0,0,7.5,6.3,15,9.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M256.5,168.3c0,0,8.3,6.7,15.8,9.5" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M339.6,158.1c0,0,11.1,0.1,14.3,2.4v12.7c0,0-3.5,10.1-21.5,17.3"
              />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M354,162.9c0,0-7.5,6.3-15,9.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M354.2,168.3c0,0-8.3,6.7-15.8,9.5" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M349.4,107.9c0.3-4.9-5.4-7.9-5.4-7.9c-0.8-14-8.2-28.9-8.2-28.9
			c-6.1-6.1-23.3-7.7-29.3-8.1l0-0.1c0,0-0.4,0-1.1,0c-0.7,0-1.1,0-1.1,0v0.1c-6,0.4-23.2,2-29.3,8.1c0,0-7.4,14.8-8.2,28.9
			c0,0-5.6,3.1-5.4,7.9l7.4,24.6c0,0-0.5,9.1,2,12.1l1.5,40.4c0,0,0.5,4.7,6.9,5.9l14,2.7h10.9h13l14-2.7c6.4-1.2,6.9-5.9,6.9-5.9
			l1.5-40.4c2.6-3,2-12.1,2-12.1L349.4,107.9z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M286.8,153.4v31c0,1.9-0.8,3.4-1.8,3.4h-1.8c-1,0-1.8-1.5-1.8-3.4
			v-50.2c0-0.4,0-0.7,0.1-1.1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.4,153.4v31c0,1.9,0.8,3.4,1.8,3.4h1.8c1,0,1.8-1.5,1.8-3.4
			v-50.2c0-0.4,0,2.2-0.1,1.9"/>
            <g>
              <rect x="290.3" y="177.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="4.2" height="9.8" />
              <rect x="287.4" y="180.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.9" height="3.7" />
            </g>
            <g>

              <rect x="317.4" y="177.5" transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 639.0573 364.6874)" fill="none" stroke="#000000" stroke-miterlimit="10" width="4.2" height="9.8" />

              <rect x="321.6" y="180.5" transform="matrix(-1 -4.518265e-11 4.518265e-11 -1 646.0372 364.6874)" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.9" height="3.7" />
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M317.8,147v65.9c0,5.4-4.2,9.8-9.4,9.8h-4.4
			c-5.2,0-9.4-4.4-9.4-9.8V147"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M274.1,73.4c0,0-4,43.6-2.8,56.2l10,4.6" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M336.9,73.4c0,0,4,43.6,2.8,56.2l-10.9,4.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M277.6,148c0-15.1,12.3-27.4,27.4-27.4s27.4,12.3,27.4,27.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M323.9,133c0,0-3.3-5.6-18.4-6.3c-15.1,0.7-18.4,6.3-18.4,6.3
			c-3.4,9.9-2.4,17.9-1.7,21c0.1,0.5,0.7,0.5,0.9,0.1l3.3-7.1h15.9h15.9l3.3,7.1c0.2,0.4,0.8,0.4,0.9-0.1
			C326.2,150.9,327.3,143,323.9,133z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M290.3,130.8c0,0,4.2,9.1,4.2,15.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321.3,130.8c0,0-4.2,9.1-4.2,15.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M271.1,144.7c0,0,5.7,3.2,6.9,3.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M339.8,144.7c0,0-6.7,3.2-7.9,3.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M266.6,101.1c0,0,7.8,3.1,17.9,5.2c0.5,0.1,0.9,0.6,0.9,1.1v3.3
			c0,0.6-0.6,1.1-1.2,0.9c-3.3-0.7-12.8-2.9-17.7-5.6L266.6,101.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M344.5,101.1c0,0-7.8,3.1-17.9,5.2c-0.5,0.1-0.9,0.6-0.9,1.1v3.3
			c0,0.6,0.6,1.1,1.2,0.9c3.3-0.7,12.8-2.9,17.7-5.6L344.5,101.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,75.4h15.4v12.4c0,0-5.1,5.9-15.5,8.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,75.4h-15.4v12.4c0,0,5.1,5.9,15.5,8.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="306.5" y1="63.1" x2="306.5" y2="75" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="306.3" y1="96.8" x2="306.3" y2="120.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,29.4c0,0,13.1-0.7,18.3,9.7l4.4,0c0,0,4.5,4.6,8.2,4.6
			c0,0,2.5,0,6,0v11.8c0,0-22.7,1.1-23.8,9.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,29.4c0,0-13.1-0.7-18.3,9.7l-4.4,0c0,0-4.5,4.6-8.2,4.6
			c0,0-2.5,0-6,0v11.8c0,0,22.7,1.1,23.8,9.2"/>
            <g>
              <rect x="251.2" y="47.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M250,55.1L250,55.1c-0.6,0-1.1-0.5-1.1-1.1v-7.5
				c0-0.6,0.5-1.1,1.1-1.1l0,0c0.6,0,1.1,0.5,1.1,1.1V54C251.2,54.6,250.7,55.1,250,55.1z"/>
            </g>
            <g>

              <rect x="342.9" y="47.3" transform="matrix(-1 -4.490864e-11 4.490864e-11 -1 703.872 99.7683)" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M362.1,55.1L362.1,55.1c0.6,0,1.1-0.5,1.1-1.1v-7.5
				c0-0.6-0.5-1.1-1.1-1.1l0,0c-0.6,0-1.1,0.5-1.1,1.1V54C361,54.6,361.5,55.1,362.1,55.1z"/>
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M287.8,39.1c0,0,6.7,14,18.8,13.4" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.6,39.1c0,0-6.7,14-18.8,13.4" />
            </g>
            <g>

              <ellipse transform="matrix(0.9143 -0.4051 0.4051 0.9143 25.3885 147.89)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="362.1" cy="14" rx="11.4" ry="8.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="351.4" y1="14.3" x2="331.3" y2="41.2" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M351.9,19c0,0-9.3,11.1-14.9,24.6" />
            </g>
            <g>

              <ellipse transform="matrix(0.4051 -0.9143 0.9143 0.4051 136.1323 237.1267)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="250.3" cy="14" rx="8.1" ry="11.4" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="261" y1="14.3" x2="281.1" y2="41.2" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M260.5,19c0,0,9.3,11.1,14.9,24.6" />
            </g>
          </g>
        </g>
        <g id="Layer_4">
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M297.4,744.4h-11c0,0-1.3-0.1-1.7,1.2v10.7l-2.4,10.4h-4v3.2h4.9
				c0,0,1.5-0.4,1.9-1.9l2.6-11.8h9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="746.9" x2="297.7" y2="746.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="753.9" x2="297.7" y2="753.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="746.4" x2="287.6" y2="754.4" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.5,744.4h11c0,0,1.3-0.1,1.7,1.2v10.7l2.4,10.4h4v3.2h-4.9
				c0,0-1.5-0.4-1.9-1.9l-2.6-11.8h-9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="746.9" x2="320.3" y2="746.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="753.9" x2="320.3" y2="753.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="746.4" x2="330.3" y2="754.4" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.8,708h-16.2c0,0-3.8,0.2-6.7,1.2c-1.6,0.6-2.7,2.2-2.7,4v10.5
			c0,0.6,0.1,1.1,0.3,1.6c1.2,2.9,6.1,12.6,19.2,15.9c0.4,0.1,0.7,0.1,1.1,0.1h16.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="275.2" y1="694" x2="275.2" y2="708" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="275.7" y1="695.7" x2="286.4" y2="695.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.1,708h16.2c0,0,3.8,0.2,6.7,1.2c1.6,0.6,2.7,2.2,2.7,4v10.5
			c0,0.6-0.1,1.1-0.3,1.6c-1.2,2.9-6.1,12.6-19.2,15.9c-0.4,0.1-0.7,0.1-1.1,0.1h-16.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="342.7" y1="694" x2="342.7" y2="708" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="342.2" y1="695.7" x2="331.5" y2="695.7" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M292.5,733.4c0,0-19-3.1-32-8.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.3,715.6h-14.8c0,0-8.3-0.8-9.9,9.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M325.3,733.4c0,0,19-3.1,32-8.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.6,715.6h14.8c0,0,8.3-0.8,9.9,9.1" />
            </g>
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="339.3" cy="713.6" r="7.5" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="338.2" cy="710.4" r="5.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.7,697.1v70.1c0,2.5-1.4,4.6-3.2,4.6H301c-1.7,0-3.2-2-3.2-4.6
			v-70.1"/>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M293.5,733.5c-4.6,0-8.3-5.5-8.3-12.4c0-6.8,3.7-12.4,8.3-12.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="293.5" y1="708.3" x2="293.5" y2="733.9" />
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="294" y1="713" x2="297.8" y2="713" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="293.8" y1="729.5" x2="297.6" y2="729.5" />
            </g>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.5,733.5c4.6,0,8.3-5.5,8.3-12.4c0-6.8-3.7-12.4-8.3-12.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324.5" y1="708.3" x2="324.5" y2="733.9" />
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324" y1="713" x2="320.1" y2="713" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324.2" y1="729.5" x2="320.4" y2="729.5" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M315.3,677.9h-11.5c-0.8,0-1.5-0.7-1.5-1.5v-0.5
			c0-0.8,0.7-1.5,1.5-1.5h11.5c0.8,0,1.5,0.7,1.5,1.5v0.5C316.8,677.2,316.2,677.9,315.3,677.9z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.9" y1="704.9" x2="298.1" y2="704.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.6" y1="708.7" x2="297.8" y2="708.7" />
            <g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287" y1="673.1" x2="286.4" y2="714.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.5" y1="673.8" x2="292.5" y2="673.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287" y1="676.7" x2="293" y2="676.7" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="680.1" x2="293.8" y2="680.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="683.5" x2="294.9" y2="683.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="687.5" x2="295.7" y2="687.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="692" x2="297" y2="692" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.9" y1="695.9" x2="297.4" y2="695.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.7" y1="700.6" x2="297.8" y2="700.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="288.3" y1="671.3" x2="291.9" y2="671.3" />
            </g>
            <g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="673.1" x2="331.8" y2="714.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.7" y1="673.8" x2="325.7" y2="673.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="676.7" x2="325.2" y2="676.7" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.1" y1="680.1" x2="324.4" y2="680.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.1" y1="683.5" x2="323.3" y2="683.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="687.5" x2="322.5" y2="687.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="692" x2="321.2" y2="692" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.3" y1="695.9" x2="320.8" y2="695.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.5" y1="700.6" x2="320.4" y2="700.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="329.9" y1="671.3" x2="326.3" y2="671.3" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M291,668.1c0,0,8.6,30.3,8.6,46.4h9.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M327.2,668.1c0,0-8.6,30.3-8.6,46.4H309" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321.3,616.5c0,0,15.5,2.5,19.8,8.1c0,0,4.7,4,6.2,25" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309,640.8c0,0,31.4,0.8,43.7,13.6l-7.4,39.3h-2.4
				c0,0-6.9-16.7-16.7-26.9h-16.8"/>
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M297,616.5c0,0-15.5,2.5-19.8,8.1c0,0-4.7,4-6.2,25" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309,640.8c0,0-31,0.8-43.3,13.6l7.4,39.3h2.4
				c0,0,6.9-16.7,16.7-26.9h17.2"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M273.4,648.8c0,0,0.9-19.4,35.9-18.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M344.5,648.8c0,0-0.5-19.4-35.6-18.9" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M266.1,655.8c0,0,16.8-9.7,33-10.9l3.2,21.9" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M300.3,655.8H289c0,0-6.9-0.3-8.5-6.2" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M352.7,655.8c0,0-16.8-9.7-33-10.9l-3.2,21.9" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.5,655.8h11.3c0,0,6.9-0.3,8.5-6.2" />
            </g>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="299.5" y1="644.9" x2="319.4" y2="644.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M348.4,674.9c0,0-10.2-6.3-21.4-7.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M269.6,675.5c0,0,10.2-6.3,21.4-7.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M308.7,579.9c0,0,11.7-1.4,19.4,10h3.6v0.9h2.8c0,0,0.4,3.5,5.7,3.4
			l6,0v11.1c0,0-1,0.9-5,1.9c0,0-3.4,0.9-8.1,1.1s-4.7,0.3-4.7,0.3l0,0c0,0-9.4,11.5-13.8,13.6c0,0-1.8,0.8-5.6,0.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309.2,579.9c0,0-11.7-1.4-19.4,10h-3.6v0.9h-2.8
			c0,0-0.4,3.5-5.7,3.4l-6,0v11.1c0,0,1,0.9,5,1.9c0,0,3.4,0.9,8.1,1.1c4.7,0.3,4.7,0.3,4.7,0.3l0,0c0,0,9.4,11.5,13.8,13.6
			c0,0,1.9,0.8,5.6,0.8"/>
            <g>
              <rect x="253.6" y="597.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M252.5,605.5L252.5,605.5c-0.6,0-1.1-0.5-1.1-1.1v-7.5
				c0-0.6,0.5-1.1,1.1-1.1l0,0c0.6,0,1.1,0.5,1.1,1.1v7.5C253.6,605,253.1,605.5,252.5,605.5z"/>
            </g>
            <g>

              <rect x="346.4" y="597.7" transform="matrix(-1 -4.485846e-11 4.485846e-11 -1 710.8186 1200.564)" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.6,605.5L365.6,605.5c0.6,0,1.1-0.5,1.1-1.1v-7.5
				c0-0.6-0.5-1.1-1.1-1.1l0,0c-0.6,0-1.1,0.5-1.1,1.1v7.5C364.5,605,365,605.5,365.6,605.5z"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M272.1,598.7c0,0,30.9-2,73.8,0" />
            <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="300.9" cy="590" rx="5.9" ry="4.1" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="290.6" cy="592.8" r="1.3" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="328" cy="592.8" r="1.3" />
            <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="316.1" cy="590" rx="5.9" ry="4.1" />
          </g>
        </g>
      </>
    )
  },
  exterior: {
    width: 612,
    height: 792,
    svg: (
      <>
        <g id="Layer_1">
          <g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,271.2c0,0,22.3,4,35.6,25.7c0,0,4.6,7.9,4.5,16
			c0,0-0.8,12.5-9.4,27.2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,324.7h4.6c0,0,18.9,6.4,31.7,21.4l0.8,20.3
			c0,0,0.3,2.9-1.9,3.2h-35.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,271.2c0,0-22.3,4-35.6,25.7c0,0-4.6,7.9-4.5,16
			c0,0,0.8,12.5,9.4,27.2"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,324.7h-4.6c0,0-18.9,6.4-31.7,21.4l-0.8,20.3
			c0,0-0.3,2.9,1.9,3.2h35.1"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,240.8c0,0,7.7-1.4,12.5,10.1l8.5,30.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,240.8c0,0-7.7-1.4-12.5,10.1l-8.5,30.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M288.5,268.8c0,0-1-3.5-4.5-3.5c0,0-3.1,0-3.3,2.4v16.8" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M323.7,268.8c0,0,1-3.5,4.5-3.5c0,0,3.1,0,3.3,2.4v16.8" />
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,283.5l15.6,11.7v20.4c0,0-9.2-5.9-16.2-5.2" />
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,283.5l-15.6,11.7v20.4c0,0,9.2-5.9,16.2-5.2" />
            </g>
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="306.1" y1="271.2" x2="306.1" y2="283.5" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="306.1" y1="309.4" x2="306.1" y2="324.7" />
            <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="284.6,282.1 284.6,285.3 271.4,299.5 269.7,299.5 		" />
            <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="327.7,282.1 327.7,285.3 340.9,299.5 342.6,299.5 		" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="267.6" y1="305.6" x2="272.3" y2="334.2" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="345" y1="305.6" x2="340.2" y2="334.2" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,347.2c0,0,11.5-0.9,24.8,8.9l0.5,2.8
			c0.2,1.1,0.1,2.2-0.5,3.1c-0.4,0.6-0.9,1.3-1.9,1.7h-23"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,347.2c0,0-11.5-0.9-24.8,8.9l-0.5,2.8
			c-0.2,1.1-0.1,2.2,0.5,3.1c0.4,0.6,0.9,1.3,1.9,1.7h23"/>
            <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="296.7" cy="356.3" rx="6" ry="3.4" />
            <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="315" cy="356.3" rx="6" ry="3.4" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,232.5c0,0,6.5,0.3,11.4,2.2v13.4" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,232.5c0,0-6.5,0.3-11.4,2.2v13.4" />
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M239,369.7l20.8-5.1c0,0,0.6-0.5,1.2-1.9c0.9-2.1,0.7-4.5-0.1-6.6
				c-0.5-1.1-1.1-2.2-1.7-2.4C259.1,353.6,246.1,352.2,239,369.7z"/>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M260.4,354.9l12.5-5.4c0,0,0.6-2.2,2.6-2.4c0,0,6.7-1.1,6.9,3.3
				c0,0,0.8,2.9-2.6,5.6c0,0-5.4,1-6.3-2.4l-11.9,4.8"/>
              <path fill="none" stroke="#020202" stroke-width="1.5" stroke-miterlimit="10" d="M269.8,349.9c0,0-10,3.6-18.2,1.7" />
              <circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="250.2" cy="351.5" r="1.5" />
            </g>
            <g>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M373.4,369.7l-20.8-5.1c0,0-0.6-0.5-1.2-1.9
				c-0.9-2.1-0.7-4.5,0.1-6.6c0.5-1.1,1.1-2.2,1.7-2.4C353.3,353.6,366.3,352.2,373.4,369.7z"/>
              <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M352,354.9l-12.5-5.4c0,0-0.6-2.2-2.6-2.4c0,0-6.7-1.1-6.9,3.3
				c0,0-0.8,2.9,2.6,5.6c0,0,5.4,1,6.3-2.4l11.9,4.8"/>
              <path fill="none" stroke="#020202" stroke-width="1.5" stroke-miterlimit="10" d="M342.7,349.9c0,0,10,3.6,18.2,1.7" />
              <circle fill="none" stroke="#020202" stroke-miterlimit="10" cx="362.2" cy="351.5" r="1.5" />
            </g>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,326.8c0,0,18.8,3.9,20,26" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306.1,326.8c0,0-18.8,3.9-20,26" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M269.1,367.5c0,0-11.3,46.3-4.3,73.5l0.3,3.3
			c0,0,1.3,48.9,15.2,73.9c0,0,8.3,16.6,25.8,17.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M343.2,367.5c0,0,11.3,46.3,4.3,73.5l-0.3,3.3
			c0,0-1.3,48.9-15.2,73.9c0,0-8.5,16.6-26,17.7"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M304.8,380.5h4.9c0,0,7.3,1.8,11.3,7.8c0,0,13,21.8,16.8,39.8v26.9
			c0,0-0.5,28.5-7.1,41.2c0,0-7.9,14.6-25.8,16.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306,380.5h-4.9c0,0-7.3,1.8-11.3,7.8c0,0-13,21.8-16.8,39.8v26.9
			c0,0,0.5,28.5,7.1,41.2c0,0,7.9,14.6,25.8,16.6"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M304.8,387.8h3.7c0,0,7.1,1.3,11,14.8c0,0,11.7,38.4,15.6,45.6v24.1
			c0,0-2.2,33.1-29.6,35.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M306,387.8h-3.7c0,0-7.1,1.3-11,14.8c0,0-11.7,38.4-15.6,45.6v24.1
			c0,0,2.3,33.1,29.7,35.5"/>
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M275.8,448.2c0,0,27.1,10.7,59.3,1.3" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="346.6" y1="442.2" x2="337.8" y2="428.1" />
            <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="264.7" y1="442.2" x2="273.5" y2="428.1" />
            <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M321.3,530.4c0,0-0.9,20.6-7.7,28.4h-14.6c0,0-6-8.7-8.1-28.4" />
          </g>
        </g>
        <g id="Layer_2">
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="269.7" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63.8" y1="261.5" x2="74.4" y2="254.5" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="245.8" x2="59.8" y2="257.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="40.7" y1="265.1" x2="29.4" y2="259.1" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="26.6" y1="272.1" x2="39.3" y2="270.4" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="52.8" y1="283.3" x2="51.7" y2="296" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.7" y1="293.5" x2="58.2" y2="282.5" />
              </g>
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="53" cy="505" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63.8" y1="496.8" x2="74.4" y2="489.8" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="481.1" x2="59.8" y2="493" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="40.7" y1="500.4" x2="29.4" y2="494.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="26.6" y1="507.4" x2="39.3" y2="505.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="52.8" y1="518.6" x2="51.7" y2="531.3" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.7" y1="528.8" x2="58.2" y2="517.8" />
              </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M85.6,303.8l-6.9-10.3c-0.1-0.1-0.1-0.2-0.1-0.4
			c-1.7-9.1,0.6-13,0.6-13c2.4-4.6,9.2-8,11.1-8.9c0.3-0.1,0.5-0.4,0.5-0.8c0.3-5.5-1.6-12.3-1.8-13c0,0,0-0.1,0-0.1L87,244.2
			c-0.1-0.4,0.1-0.7,0.4-0.9h0c0.9-0.5,2-0.4,2.7,0.4L98,253c0,0,0.1,0.1,0.1,0.1c10.3,18,7.7,38.5,7.7,38.5L85.6,303.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M111,285.6c0,0,0.8-13.1-6.7-28.9c0,0-6.5-10.9-13.4-16.3
			c0,0-2.6-0.8-2.4,2.2"/>

            <rect x="71.3" y="261" transform="matrix(-0.4573 0.8893 -0.8893 -0.4573 357.3746 343.5254)" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="5.2" height="39.6" />

            <rect x="51" y="265.9" transform="matrix(-0.4573 0.8893 -0.8893 -0.4573 316.7334 346.4957)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.3" height="8" />
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="91,291.2 92.3,288.7 102.3,293.9 99.9,295.7 		" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M185.4,369h4.9l15.9-18.5c0.6-0.7,0.7-1.7,0.2-2.4
			c-2.5-4.1-10.9-17-20.9-24h-5.2c-1,0-1.9,0.7-2,1.7l-1.2,6c0,0-1.8,3.3-2.8,1.3l-1.2-4.7c-0.4-1.6-1-3.1-1.8-4.6L157,299
			l-10.8-13.1c-1.5-1.8-3.2-3.4-5.2-4.7l-13.9-9.3c-1.6-1.1-3.6-1.2-5.3-0.4c-1.9,0.9-4.4,2.5-6.6,5.3c0,0-4.3,11.2-8.3,14
			c0,0-10.9,8.2-22,13.2l-24.3,13.6c0,0-12.1,6.4-14.5,19c0,0-2.3,14.3-3,22v28.2c0,0,4.3,33.4,9.2,44.9l14,12l8.5,7.5l5.6-5.4
			c0,0-10.9-45.1-11.1-49.5L69.2,382l0.2-28.4c0,0,1.2-5.3,7.8-9.4l9-5.1c0,0,34.5-4.5,43.6-3.6l29.8,14.4c0,0,3.1,1.9,6.1-0.2
			l15.1,17C180.8,366.7,183.7,369,185.4,369z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M160,304.6v11.6c0,0.3-0.4,0.4-0.5,0.2
			c-2.1-3-10.1-14.1-14-20.8c-1.2-2.1-2.1-4.4-2.5-6.8c-0.4-2.2-0.8-4.9-0.8-6.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M185.5,345.6c0,0-2.9,3.5-3.9,6.9
			c-0.1,0.4-0.7,0.5-0.9,0.1l-8.3-15.1c-0.1-0.3-0.5-0.2-0.6,0l-6.1,12.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M172.9,328.2l-4.7,7.8c0,0-1.5,1.9-4.2,0.3
			c-0.3-0.2-0.5-0.4-0.7-0.7c-2.3-3.4-18.8-27.6-28.2-34.4c0,0-3.9-3.5-6.5-4.8c-0.6-0.3-1.4-0.3-2,0l-19.3,9.8c0,0-2.9,1.4-3.4-0.7
			l3.3-15.1"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M170.1,340.8c0,0,0,0-0.1,0.1
			c-1.6,1.8-4.5,1.9-6.2,0.2l-15.4-15c-0.1-0.1-0.2-0.1-0.2-0.2l-14.4-12.1c-0.5-0.4-1-0.7-1.6-0.9c-1.3-0.4-3.8-0.8-6.2-0.1
			l-24.3,7.4c-0.7,0.2-1.5,0.3-2.3,0.1c-1.1-0.3-2.8-0.8-4.6-2c-2.4-1.5-4-3.9-4.5-6.7c-0.7-3.8-1.7-9.3-1.7-9.3"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="124" y1="271.4" x2="128" y2="296.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M134.1,300.3l-3-18c-0.4-2.5-1.8-4.9-3.8-6.4
			c-0.8-0.6-1.7-1.2-2.9-1.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M157.1,334.4v-4.4l-22.5-22.2
			c0,0-0.1-0.1-0.2-0.2c-2.8-2.2-6.4-3-9.9-2.4c-3.5,0.6-8.4,1.6-11.1,2.7c0,0-9.6,3.3-14,12.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M99.4,319.8c0,0-2-5.9-2-11.7c0-2.1,0.6-4.2,1.7-6
			c1.4-2.4,3.3-6.1,3.2-8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.4,316c0,0,0.8,11.3-6.8,23.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.4,316l-21.4,14.9c-3,2.1-5.4,5.9-6,8.5
			c-0.1,0.3-0.2,0.6-0.2,0.9c0,0,1,42.6,5.4,60.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.8,308.4c0,0-9.3,1.8-16.8,6.9c0,0-10.1,5-18.1,20.1
			c-3,5.6-4.8,11.7-5.6,18c-0.9,7.4-1.9,18.9-0.8,29.6c0,0,5.6,40.1,11.4,55"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="186.4" cy="363.3" r="3.9" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="186.4" cy="363.3" r="2.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.8,352.6l3.5,0c0,0,9.9,3.9,10.8,13.2c0.2,1.6-0.8,3.3-2.4,3.7
			c-0.1,0-0.3,0.1-0.5,0.1h-7.4c-1.6,0-3.2-0.3-4.6-0.9c0,0,0,0,0,0c-1.6-0.7-2.1-2.3-2.1-4.1c0-0.6,0.1-1.1,0.5-1.3
			c0,0,1.3-2.6,1.3-3.9c0,0-0.5-3.3-0.4-5.7C215.6,353.1,216.2,352.6,216.8,352.6z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M214.7,355.9l-18.2-0.7c0,0-3.3-0.8-3.5-2.6
			c0,0-0.1-0.5-0.2-1.3c-0.3-3,4.3-3.8,4.9-0.9c0.1,0.4,0.1,0.8,0.1,1.3c0,0,0,0,0,0l18,0.8c0.1,0,0.2,0.1,0.1,0.2l-0.4,2.5
			C215.5,355.6,215.1,355.9,214.7,355.9z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M184.8,324.4c0,0,10,16.1,11.2,24.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M178.6,324.9c0,0,5.7,12.6,6.9,20.7
			c0,0,4.1,0.8,4.8,4.3c0,0,1.6,3.5,3.4,3.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M148.1,513.7c0,0,7.5-1.3,8.8-5.2l-3.4-54.3c0,0-4.3-21.7-4.5-29.7
			V396c0,0-2.9-5.9-10.5-7.9c0,0-5-0.6-9.7,0c0,0-2.9,1.1-5.9,0.4c0,0-2.6-1-4.1,0l-14.7,9.8c0,0-4.3,2.3-8.7-0.3l-22.9-13v-1.5
			h-3.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="119.4" y1="430.8" x2="124.9" y2="388.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128.7,388.8c0,0,3.6,31.1,9.8,49.4c0,0,5.1,14.4,15,17.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M72.6,385.1v6.4L94.1,404c0,0,0.1,0,0.3,0.1
			c6.2,1.9,12.9-0.1,17-5.1l9-10.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="69.3" y1="396.7" x2="85.6" y2="409.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M85.6,409.7c0,0,7.3-1,9.3-0.8l29,25.8c0,0,9.1,9.7,13.4,25.1
			c0,0,6.1,20.3,10.1,54.5c0,0-1.7,16.6-3.4,22c0,0-7-0.1-15.6-5.9l-7.2-6.3l-27.4-55.8c0,0-4-6.8-4.6-13.7L85.6,409.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M120.4,523c0,0-7.5-80-34.5-112.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.7,535.4l-4.2-23c0,0-4-41.5-13-59.9l-29.3-42.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M131.7,532.5v-6c0-3.7,1-7.3,3.1-10.3c1-1.4,2.2-2.7,3.7-3.7" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M61.3,439.3l1.4,13.5l11.4,22.5l7.5,49l-0.9,12L70,537.4
			l-4.6-11.2c0,0-5.3,2.2-8.3-0.5c0,0-5.1-2.7-7.8-17c0,0-2.4-17.6-3.3-28.2c-0.3-3.8-0.2-7.6,0.3-11.3c1.1-7.8,3.6-23.3,8.2-35.6
			L61.3,439.3z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="56.4,435.1 56.4,476.8 56.4,477.8 65.4,526.2 81.6,524.3 
					"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="73.8" y1="474.7" x2="56.4" y2="476.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="56.4" y1="458.1" x2="64.4" y2="456" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="77.4" y1="448.6" x2="88.7" y2="451.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="70.3" y1="467.7" x2="93.2" y2="467.7" />
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="78.1,501.6 77.2,495.1 101.4,484.2 104.1,489.9 		" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="81.6" cy="479" r="5.2" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="113" cy="520.4" r="2.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M110.1,502.1c0,0-0.3,9.9-1,12.8c0,0-2.7,8-16,19.7
			c0,0-14.6,12.1-20.1,21.1c-0.5,0.8-0.7,1.6-0.7,2.5l0,0c0,1.6,2.2,2.2,2.9,0.7c0,0,0,0,0-0.1c0,0,8-11.7,15.8-16.9l29.8-17.9
			L110.1,502.1z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="105.1,533.6 113.5,533.6 113.5,528.3 		" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="98.7" y1="485.5" x2="101.5" y2="491.4" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="95.8" y1="486.8" x2="98.7" y2="492.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.2" y1="488.1" x2="96" y2="494" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="90.6" y1="489.3" x2="93.5" y2="495.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="87.8" y1="490.6" x2="90.6" y2="496.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.7" y1="491.7" x2="87.5" y2="497.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="82.2" y1="492.9" x2="85" y2="498.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="79.5" y1="494.2" x2="82.4" y2="500.1" />
          </g>
          <g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="269.7" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="550.2" y1="261.5" x2="539.5" y2="254.5" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.6" y1="245.8" x2="554.1" y2="257.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="573.3" y1="265.1" x2="584.6" y2="259.1" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="587.3" y1="272.1" x2="574.7" y2="270.4" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="561.2" y1="283.3" x2="562.3" y2="296" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.3" y1="293.5" x2="555.8" y2="282.5" />
              </g>
            </g>
            <g>
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="38.2" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="26.3" />
              <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="561" cy="505" r="13.6" />
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="550.2" y1="496.8" x2="539.5" y2="489.8" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.6" y1="481.1" x2="554.1" y2="493" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="573.3" y1="500.4" x2="584.6" y2="494.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="587.3" y1="507.4" x2="574.7" y2="505.7" />
              </g>
              <g>
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="561.2" y1="518.6" x2="562.3" y2="531.3" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.3" y1="528.8" x2="555.8" y2="517.8" />
              </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M528.4,303.8l6.9-10.3c0.1-0.1,0.1-0.2,0.1-0.4
			c1.7-9.1-0.6-13-0.6-13c-2.4-4.6-9.2-8-11.1-8.9c-0.3-0.1-0.5-0.4-0.5-0.8c-0.3-5.5,1.6-12.3,1.8-13c0,0,0-0.1,0-0.1l1.9-13.1
			c0.1-0.4-0.1-0.7-0.4-0.9l0,0c-0.9-0.5-2-0.4-2.7,0.4L516,253c0,0-0.1,0.1-0.1,0.1c-10.3,18-7.7,38.5-7.7,38.5L528.4,303.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M502.9,285.6c0,0-0.8-13.1,6.7-28.9c0,0,6.5-10.9,13.4-16.3
			c0,0,2.6-0.8,2.4,2.2"/>

            <rect x="537.5" y="261" transform="matrix(0.4573 0.8893 -0.8893 0.4573 542.8383 -327.9317)" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="5.2" height="39.6" />

            <rect x="559.7" y="265.9" transform="matrix(0.4573 0.8893 -0.8893 0.4573 544.6509 -352.7328)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.3" height="8" />
            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="522.9,291.2 521.7,288.7 511.7,293.9 514.1,295.7 		" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M428.6,369h-4.9l-15.9-18.5c-0.6-0.7-0.7-1.7-0.2-2.4
			c2.5-4.1,10.9-17,20.9-24h5.2c1,0,1.9,0.7,2,1.7l1.2,6c0,0,1.8,3.3,2.8,1.3l1.2-4.7c0.4-1.6,1-3.1,1.8-4.6L457,299l10.8-13.1
			c1.5-1.8,3.2-3.4,5.2-4.7l13.9-9.3c1.6-1.1,3.6-1.2,5.3-0.4c1.9,0.9,4.4,2.5,6.6,5.3c0,0,4.3,11.2,8.3,14c0,0,10.9,8.2,22,13.2
			l24.3,13.6c0,0,12.1,6.4,14.5,19c0,0,2.3,14.3,3,22v28.2c0,0-4.3,33.4-9.2,44.9l-14,12l-8.5,7.5l-5.6-5.4c0,0,10.9-45.1,11.1-49.5
			l0.2-14.1l-0.2-28.4c0,0-1.2-5.3-7.8-9.4l-9-5.1c0,0-34.5-4.5-43.6-3.6l-29.8,14.4c0,0-3.1,1.9-6.1-0.2l-15.1,17
			C433.1,366.7,430.2,369,428.6,369z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M454,304.6v11.6c0,0.3,0.4,0.4,0.5,0.2
			c2.1-3,10.1-14.1,14-20.8c1.2-2.1,2.1-4.4,2.5-6.8c0.4-2.2,0.8-4.9,0.8-6.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M428.5,345.6c0,0,2.9,3.5,3.9,6.9
			c0.1,0.4,0.7,0.5,0.9,0.1l8.3-15.1c0.1-0.3,0.5-0.2,0.6,0l6.1,12.2"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M441,328.2l4.7,7.8c0,0,1.5,1.9,4.2,0.3
			c0.3-0.2,0.5-0.4,0.7-0.7c2.3-3.4,18.8-27.6,28.2-34.4c0,0,3.9-3.5,6.5-4.8c0.6-0.3,1.4-0.3,2,0l19.3,9.8c0,0,2.9,1.4,3.4-0.7
			l-3.3-15.1"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M443.8,340.8c0,0,0,0,0.1,0.1
			c1.6,1.8,4.5,1.9,6.2,0.2l15.4-15c0.1-0.1,0.2-0.1,0.2-0.2l14.4-12.1c0.5-0.4,1-0.7,1.6-0.9c1.3-0.4,3.8-0.8,6.2-0.1l24.3,7.4
			c0.7,0.2,1.5,0.3,2.3,0.1c1.1-0.3,2.8-0.8,4.6-2c2.4-1.5,4-3.9,4.5-6.7c0.7-3.8,1.7-9.3,1.7-9.3"/>
            <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="490" y1="271.4" x2="486" y2="296.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M479.9,300.3l3-18c0.4-2.5,1.8-4.9,3.8-6.4
			c0.8-0.6,1.7-1.2,2.9-1.7"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M456.9,334.4v-4.4l22.5-22.2
			c0,0,0.1-0.1,0.2-0.2c2.8-2.2,6.4-3,9.9-2.4c3.5,0.6,8.4,1.6,11.1,2.7c0,0,9.6,3.3,14,12.3"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M514.5,319.8c0,0,2-5.9,2-11.7c0-2.1-0.6-4.2-1.7-6
			c-1.4-2.4-3.3-6.1-3.2-8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.6,316c0,0-0.8,11.3,6.8,23.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.6,316l21.4,14.9c3,2.1,5.4,5.9,6,8.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0-1,42.6-5.4,60.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M524.2,308.4c0,0,9.3,1.8,16.8,6.9c0,0,10.1,5,18.1,20.1
			c3,5.6,4.8,11.7,5.6,18c0.9,7.4,1.9,18.9,0.8,29.6c0,0-5.6,40.1-11.4,55"/>
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="427.6" cy="363.3" r="3.9" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="427.6" cy="363.3" r="2.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397.1,352.6l-3.5,0c0,0-9.9,3.9-10.8,13.2
			c-0.2,1.6,0.8,3.3,2.4,3.7c0.1,0,0.3,0.1,0.5,0.1h7.4c1.6,0,3.2-0.3,4.6-0.9c0,0,0,0,0,0c1.6-0.7,2.1-2.3,2.1-4.1
			c0-0.6-0.1-1.1-0.5-1.3c0,0-1.3-2.6-1.3-3.9c0,0,0.5-3.3,0.4-5.7C398.4,353.1,397.8,352.6,397.1,352.6z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M399.3,355.9l18.2-0.7c0,0,3.3-0.8,3.5-2.6
			c0,0,0.1-0.5,0.2-1.3c0.3-3-4.3-3.8-4.9-0.9c-0.1,0.4-0.1,0.8-0.1,1.3c0,0,0,0,0,0l-18,0.8c-0.1,0-0.2,0.1-0.1,0.2l0.4,2.5
			C398.5,355.6,398.9,355.9,399.3,355.9z"/>
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M429.1,324.4c0,0-10,16.1-11.2,24.2" />
            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M435.4,324.9c0,0-5.7,12.6-6.9,20.7
			c0,0-4.1,0.8-4.8,4.3c0,0-1.6,3.5-3.4,3.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.9,513.7c0,0-7.5-1.3-8.8-5.2l3.4-54.3c0,0,4.3-21.7,4.5-29.7
			V396c0,0,2.9-5.9,10.5-7.9c0,0,5-0.6,9.7,0c0,0,2.9,1.1,5.9,0.4c0,0,2.6-1,4.1,0l14.7,9.8c0,0,4.3,2.3,8.7-0.3l22.9-13v-1.5h3.4"
            />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="494.5" y1="430.8" x2="489.1" y2="388.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,388.8c0,0-3.6,31.1-9.8,49.4c0,0-5.1,14.4-15,17.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M541.3,385.1v6.4L519.9,404c0,0-0.1,0-0.3,0.1
			c-6.2,1.9-12.9-0.1-17-5.1l-9-10.9"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="544.7" y1="396.7" x2="528.4" y2="409.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M528.4,409.7c0,0-7.3-1-9.3-0.8l-29,25.8c0,0-9.1,9.7-13.4,25.1
			c0,0-6.1,20.3-10.1,54.5c0,0,1.7,16.6,3.4,22c0,0,7-0.1,15.6-5.9l7.2-6.3l27.4-55.8c0,0,4-6.8,4.6-13.7L528.4,409.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M493.6,523c0,0,7.5-80,34.5-112.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M471.3,535.4l4.2-23c0,0,4-41.5,13-59.9l29.3-42.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M482.3,532.5v-6c0-3.7-1-7.3-3.1-10.3c-1-1.4-2.2-2.7-3.7-3.7" />
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M552.7,439.3l-1.4,13.5l-11.4,22.5l-7.5,49l0.9,12l10.7,1.1
			l4.6-11.2c0,0,5.3,2.2,8.3-0.5c0,0,5.1-2.7,7.8-17c0,0,2.4-17.6,3.3-28.2c0.3-3.8,0.2-7.6-0.3-11.3c-1.1-7.8-3.6-23.3-8.2-35.6
			L552.7,439.3z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="557.6,435.1 557.6,476.8 557.6,477.8 548.5,526.2 
			532.4,524.3 		"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="540.2" y1="474.7" x2="557.6" y2="476.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="557.6" y1="458.1" x2="549.6" y2="456" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="536.6" y1="448.6" x2="525.3" y2="451.1" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="543.7" y1="467.7" x2="520.8" y2="467.7" />
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="535.8,501.6 536.8,495.1 512.6,484.2 509.9,489.9 		" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="532.4" cy="479" r="5.2" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="500.9" cy="520.4" r="2.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M503.8,502.1c0,0,0.3,9.9,1,12.8c0,0,2.7,8,16,19.7
			c0,0,14.6,12.1,20.1,21.1c0.5,0.8,0.7,1.6,0.7,2.5l0,0c0,1.6-2.2,2.2-2.9,0.7c0,0,0,0,0-0.1c0,0-8-11.7-15.8-16.9L493.1,524
			L503.8,502.1z"/>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="508.9,533.6 500.5,533.6 500.5,528.3 		" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="515.3" y1="485.5" x2="512.5" y2="491.4" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="518.1" y1="486.8" x2="515.3" y2="492.8" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="520.8" y1="488.1" x2="518" y2="494" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="523.3" y1="489.3" x2="520.5" y2="495.2" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="526.2" y1="490.6" x2="523.3" y2="496.6" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="529.3" y1="491.7" x2="526.4" y2="497.7" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="531.8" y1="492.9" x2="529" y2="498.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="534.4" y1="494.2" x2="531.6" y2="500.1" />
          </g>
        </g>
        <g id="Layer_3">
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M294.4,194.1h-11c0,0-1.3-0.1-1.7,1.2V206l-2.4,10.4h-4v3.2h4.9
				c0,0,1.5-0.4,1.9-1.9l2.6-11.8h9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="196.5" x2="294.7" y2="196.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="203.6" x2="294.7" y2="203.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="284.6" y1="196" x2="284.6" y2="204.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M317.5,194.1h11c0,0,1.3-0.1,1.7,1.2V206l2.4,10.4h4v3.2h-4.9
				c0,0-1.5-0.4-1.9-1.9l-2.6-11.8h-9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="196.5" x2="317.3" y2="196.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="203.6" x2="317.3" y2="203.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="327.3" y1="196" x2="327.3" y2="204.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M271.1,158.1c0,0-11.1,0.1-14.3,2.4v12.7c0,0,3.5,10.1,21.5,17.3"
              />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M256.7,162.9c0,0,7.5,6.3,15,9.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M256.5,168.3c0,0,8.3,6.7,15.8,9.5" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M339.6,158.1c0,0,11.1,0.1,14.3,2.4v12.7c0,0-3.5,10.1-21.5,17.3"
              />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M354,162.9c0,0-7.5,6.3-15,9.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M354.2,168.3c0,0-8.3,6.7-15.8,9.5" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M349.4,107.9c0.3-4.9-5.4-7.9-5.4-7.9c-0.8-14-8.2-28.9-8.2-28.9
			c-6.1-6.1-23.3-7.7-29.3-8.1l0-0.1c0,0-0.4,0-1.1,0c-0.7,0-1.1,0-1.1,0v0.1c-6,0.4-23.2,2-29.3,8.1c0,0-7.4,14.8-8.2,28.9
			c0,0-5.6,3.1-5.4,7.9l7.4,24.6c0,0-0.5,9.1,2,12.1l1.5,40.4c0,0,0.5,4.7,6.9,5.9l14,2.7h10.9h13l14-2.7c6.4-1.2,6.9-5.9,6.9-5.9
			l1.5-40.4c2.6-3,2-12.1,2-12.1L349.4,107.9z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M286.8,153.4v31c0,1.9-0.8,3.4-1.8,3.4h-1.8c-1,0-1.8-1.5-1.8-3.4
			v-50.2c0-0.4,0-0.7,0.1-1.1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.4,153.4v31c0,1.9,0.8,3.4,1.8,3.4h1.8c1,0,1.8-1.5,1.8-3.4
			v-50.2c0-0.4,0,2.2-0.1,1.9"/>
            <g>
              <rect x="290.3" y="177.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="4.2" height="9.8" />
              <rect x="287.4" y="180.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.9" height="3.7" />
            </g>
            <g>

              <rect x="317.4" y="177.5" transform="matrix(-1 -4.490509e-11 4.490509e-11 -1 639.0573 364.6874)" fill="none" stroke="#000000" stroke-miterlimit="10" width="4.2" height="9.8" />

              <rect x="321.6" y="180.5" transform="matrix(-1 -4.518265e-11 4.518265e-11 -1 646.0372 364.6874)" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.9" height="3.7" />
            </g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M317.8,147v65.9c0,5.4-4.2,9.8-9.4,9.8h-4.4
			c-5.2,0-9.4-4.4-9.4-9.8V147"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M274.1,73.4c0,0-4,43.6-2.8,56.2l10,4.6" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M336.9,73.4c0,0,4,43.6,2.8,56.2l-10.9,4.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M277.6,148c0-15.1,12.3-27.4,27.4-27.4s27.4,12.3,27.4,27.4" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M323.9,133c0,0-3.3-5.6-18.4-6.3c-15.1,0.7-18.4,6.3-18.4,6.3
			c-3.4,9.9-2.4,17.9-1.7,21c0.1,0.5,0.7,0.5,0.9,0.1l3.3-7.1h15.9h15.9l3.3,7.1c0.2,0.4,0.8,0.4,0.9-0.1
			C326.2,150.9,327.3,143,323.9,133z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M290.3,130.8c0,0,4.2,9.1,4.2,15.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321.3,130.8c0,0-4.2,9.1-4.2,15.8" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M271.1,144.7c0,0,5.7,3.2,6.9,3.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M339.8,144.7c0,0-6.7,3.2-7.9,3.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M266.6,101.1c0,0,7.8,3.1,17.9,5.2c0.5,0.1,0.9,0.6,0.9,1.1v3.3
			c0,0.6-0.6,1.1-1.2,0.9c-3.3-0.7-12.8-2.9-17.7-5.6L266.6,101.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M344.5,101.1c0,0-7.8,3.1-17.9,5.2c-0.5,0.1-0.9,0.6-0.9,1.1v3.3
			c0,0.6,0.6,1.1,1.2,0.9c3.3-0.7,12.8-2.9,17.7-5.6L344.5,101.1z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,75.4h15.4v12.4c0,0-5.1,5.9-15.5,8.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,75.4h-15.4v12.4c0,0,5.1,5.9,15.5,8.5" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="306.5" y1="63.1" x2="306.5" y2="75" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="306.3" y1="96.8" x2="306.3" y2="120.2" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,29.4c0,0,13.1-0.7,18.3,9.7l4.4,0c0,0,4.5,4.6,8.2,4.6
			c0,0,2.5,0,6,0v11.8c0,0-22.7,1.1-23.8,9.2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.1,29.4c0,0-13.1-0.7-18.3,9.7l-4.4,0c0,0-4.5,4.6-8.2,4.6
			c0,0-2.5,0-6,0v11.8c0,0,22.7,1.1,23.8,9.2"/>
            <g>
              <rect x="251.2" y="47.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M250,55.1L250,55.1c-0.6,0-1.1-0.5-1.1-1.1v-7.5
				c0-0.6,0.5-1.1,1.1-1.1l0,0c0.6,0,1.1,0.5,1.1,1.1V54C251.2,54.6,250.7,55.1,250,55.1z"/>
            </g>
            <g>

              <rect x="342.9" y="47.3" transform="matrix(-1 -4.490864e-11 4.490864e-11 -1 703.872 99.7683)" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M362.1,55.1L362.1,55.1c0.6,0,1.1-0.5,1.1-1.1v-7.5
				c0-0.6-0.5-1.1-1.1-1.1l0,0c-0.6,0-1.1,0.5-1.1,1.1V54C361,54.6,361.5,55.1,362.1,55.1z"/>
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M287.8,39.1c0,0,6.7,14,18.8,13.4" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.6,39.1c0,0-6.7,14-18.8,13.4" />
            </g>
            <g>

              <ellipse transform="matrix(0.9143 -0.4051 0.4051 0.9143 25.3885 147.89)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="362.1" cy="14" rx="11.4" ry="8.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="351.4" y1="14.3" x2="331.3" y2="41.2" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M351.9,19c0,0-9.3,11.1-14.9,24.6" />
            </g>
            <g>

              <ellipse transform="matrix(0.4051 -0.9143 0.9143 0.4051 136.1323 237.1267)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="250.3" cy="14" rx="8.1" ry="11.4" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="261" y1="14.3" x2="281.1" y2="41.2" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M260.5,19c0,0,9.3,11.1,14.9,24.6" />
            </g>
          </g>
        </g>
        <g id="Layer_4">
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M297.4,744.4h-11c0,0-1.3-0.1-1.7,1.2v10.7l-2.4,10.4h-4v3.2h4.9
				c0,0,1.5-0.4,1.9-1.9l2.6-11.8h9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="746.9" x2="297.7" y2="746.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="753.9" x2="297.7" y2="753.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.6" y1="746.4" x2="287.6" y2="754.4" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.5,744.4h11c0,0,1.3-0.1,1.7,1.2v10.7l2.4,10.4h4v3.2h-4.9
				c0,0-1.5-0.4-1.9-1.9l-2.6-11.8h-9.8"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="746.9" x2="320.3" y2="746.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="753.9" x2="320.3" y2="753.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.3" y1="746.4" x2="330.3" y2="754.4" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.8,708h-16.2c0,0-3.8,0.2-6.7,1.2c-1.6,0.6-2.7,2.2-2.7,4v10.5
			c0,0.6,0.1,1.1,0.3,1.6c1.2,2.9,6.1,12.6,19.2,15.9c0.4,0.1,0.7,0.1,1.1,0.1h16.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="275.2" y1="694" x2="275.2" y2="708" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="275.7" y1="695.7" x2="286.4" y2="695.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.1,708h16.2c0,0,3.8,0.2,6.7,1.2c1.6,0.6,2.7,2.2,2.7,4v10.5
			c0,0.6-0.1,1.1-0.3,1.6c-1.2,2.9-6.1,12.6-19.2,15.9c-0.4,0.1-0.7,0.1-1.1,0.1h-16.4"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="342.7" y1="694" x2="342.7" y2="708" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="342.2" y1="695.7" x2="331.5" y2="695.7" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M292.5,733.4c0,0-19-3.1-32-8.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.3,715.6h-14.8c0,0-8.3-0.8-9.9,9.1" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M325.3,733.4c0,0,19-3.1,32-8.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.6,715.6h14.8c0,0,8.3-0.8,9.9,9.1" />
            </g>
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="339.3" cy="713.6" r="7.5" />
            <circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="338.2" cy="710.4" r="5.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.7,697.1v70.1c0,2.5-1.4,4.6-3.2,4.6H301c-1.7,0-3.2-2-3.2-4.6
			v-70.1"/>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M293.5,733.5c-4.6,0-8.3-5.5-8.3-12.4c0-6.8,3.7-12.4,8.3-12.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="293.5" y1="708.3" x2="293.5" y2="733.9" />
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="294" y1="713" x2="297.8" y2="713" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="293.8" y1="729.5" x2="297.6" y2="729.5" />
            </g>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.5,733.5c4.6,0,8.3-5.5,8.3-12.4c0-6.8-3.7-12.4-8.3-12.4" />
                <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324.5" y1="708.3" x2="324.5" y2="733.9" />
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324" y1="713" x2="320.1" y2="713" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324.2" y1="729.5" x2="320.4" y2="729.5" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M315.3,677.9h-11.5c-0.8,0-1.5-0.7-1.5-1.5v-0.5
			c0-0.8,0.7-1.5,1.5-1.5h11.5c0.8,0,1.5,0.7,1.5,1.5v0.5C316.8,677.2,316.2,677.9,315.3,677.9z"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.9" y1="704.9" x2="298.1" y2="704.9" />
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.6" y1="708.7" x2="297.8" y2="708.7" />
            <g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287" y1="673.1" x2="286.4" y2="714.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.5" y1="673.8" x2="292.5" y2="673.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287" y1="676.7" x2="293" y2="676.7" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="680.1" x2="293.8" y2="680.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="683.5" x2="294.9" y2="683.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="687.5" x2="295.7" y2="687.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.1" y1="692" x2="297" y2="692" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.9" y1="695.9" x2="297.4" y2="695.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="286.7" y1="700.6" x2="297.8" y2="700.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="288.3" y1="671.3" x2="291.9" y2="671.3" />
            </g>
            <g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="673.1" x2="331.8" y2="714.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="330.7" y1="673.8" x2="325.7" y2="673.8" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="676.7" x2="325.2" y2="676.7" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.1" y1="680.1" x2="324.4" y2="680.1" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.1" y1="683.5" x2="323.3" y2="683.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="687.5" x2="322.5" y2="687.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.2" y1="692" x2="321.2" y2="692" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.3" y1="695.9" x2="320.8" y2="695.9" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.5" y1="700.6" x2="320.4" y2="700.6" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="329.9" y1="671.3" x2="326.3" y2="671.3" />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M291,668.1c0,0,8.6,30.3,8.6,46.4h9.7" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M327.2,668.1c0,0-8.6,30.3-8.6,46.4H309" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321.3,616.5c0,0,15.5,2.5,19.8,8.1c0,0,4.7,4,6.2,25" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309,640.8c0,0,31.4,0.8,43.7,13.6l-7.4,39.3h-2.4
				c0,0-6.9-16.7-16.7-26.9h-16.8"/>
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M297,616.5c0,0-15.5,2.5-19.8,8.1c0,0-4.7,4-6.2,25" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309,640.8c0,0-31,0.8-43.3,13.6l7.4,39.3h2.4
				c0,0,6.9-16.7,16.7-26.9h17.2"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M273.4,648.8c0,0,0.9-19.4,35.9-18.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M344.5,648.8c0,0-0.5-19.4-35.6-18.9" />
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M266.1,655.8c0,0,16.8-9.7,33-10.9l3.2,21.9" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M300.3,655.8H289c0,0-6.9-0.3-8.5-6.2" />
            </g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M352.7,655.8c0,0-16.8-9.7-33-10.9l-3.2,21.9" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.5,655.8h11.3c0,0,6.9-0.3,8.5-6.2" />
            </g>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="299.5" y1="644.9" x2="319.4" y2="644.9" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M348.4,674.9c0,0-10.2-6.3-21.4-7.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M269.6,675.5c0,0,10.2-6.3,21.4-7.3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M308.7,579.9c0,0,11.7-1.4,19.4,10h3.6v0.9h2.8c0,0,0.4,3.5,5.7,3.4
			l6,0v11.1c0,0-1,0.9-5,1.9c0,0-3.4,0.9-8.1,1.1s-4.7,0.3-4.7,0.3l0,0c0,0-9.4,11.5-13.8,13.6c0,0-1.8,0.8-5.6,0.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309.2,579.9c0,0-11.7-1.4-19.4,10h-3.6v0.9h-2.8
			c0,0-0.4,3.5-5.7,3.4l-6,0v11.1c0,0,1,0.9,5,1.9c0,0,3.4,0.9,8.1,1.1c4.7,0.3,4.7,0.3,4.7,0.3l0,0c0,0,9.4,11.5,13.8,13.6
			c0,0,1.9,0.8,5.6,0.8"/>
            <g>
              <rect x="253.6" y="597.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M252.5,605.5L252.5,605.5c-0.6,0-1.1-0.5-1.1-1.1v-7.5
				c0-0.6,0.5-1.1,1.1-1.1l0,0c0.6,0,1.1,0.5,1.1,1.1v7.5C253.6,605,253.1,605.5,252.5,605.5z"/>
            </g>
            <g>

              <rect x="346.4" y="597.7" transform="matrix(-1 -4.485846e-11 4.485846e-11 -1 710.8186 1200.564)" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.1" height="5.1" />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.6,605.5L365.6,605.5c0.6,0,1.1-0.5,1.1-1.1v-7.5
				c0-0.6-0.5-1.1-1.1-1.1l0,0c-0.6,0-1.1,0.5-1.1,1.1v7.5C364.5,605,365,605.5,365.6,605.5z"/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M272.1,598.7c0,0,30.9-2,73.8,0" />
            <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="300.9" cy="590" rx="5.9" ry="4.1" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="290.6" cy="592.8" r="1.3" />
            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="328" cy="592.8" r="1.3" />
            <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="316.1" cy="590" rx="5.9" ry="4.1" />
          </g>
        </g>
      </>
    )
  }
}