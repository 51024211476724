import { gql } from "@apollo/client";

export const GET_CONSUMER_CUSTOMER = gql`
  query Customer($customerId: String!) {
    customer(customerId: $customerId) {
      license {
        dvlaVerificationStatus
      }
      creditSafeData {
        creditScore
        lastChecked
      }
    }
  }
`;
