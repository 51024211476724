import { gql } from "apollo-boost";

export const GET_CREDIT_NOTE = gql`
  query consumerCreditNote($id: String!){
    consumerCreditNote(id: $id) {
      id
      creditNoteRef
      creditNoteType
      totalAmount
      availableAmount
      refundedAmount
      createdAt
      versionNumber
      allocations {
        amount
        allocatedAt
        invoice {
          invoiceRef
          id
        }
      }
      creditNoteItems {
        type
        description
        unitPrice
        totalAmount
        quantity
      }
      paymentsLinked {
        amount
        id
        status
      }
      invoice {
        invoiceRef
        id
      }
      booking {
        referenceNumber
        id
        branch {
          timeZone
        }
      }
      customer {
        firstName
        lastName
        email
        id
        phoneNumber {
          phone
        }
      }
      businessCustomer {
        businessName
        id
        contact {
          firstName
          lastName
        }
        billing {
          email
          phoneNumber {
            phone
          }
        }
      }
    }
  }
`