import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import HistoryIcon from "@material-ui/icons/History";
import SpeedIcon from "@material-ui/icons/Speed";
import {
  FrogDiagram,
} from "./VehicleDamage/FrogDiagrams";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import UpdateVehicleStateDialog from "./UpdateCondition";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GET_BOOKING_DATA_FOR_VEHICLE, GET_VEHICLE } from "../../../../graphql/vehicles/getVehicleQuery";
import { useLazyQuery } from "@apollo/client";
import {
  IVehicle,
  DamageTypes,
  IVehicleState,
  IBooking,
  BookingStatus,
  IDamageList,
  IVehicleType,
  IVehicleService,
  VehicleServiceStatus,
} from "../../../../reducers/bookings/types";
import { StyledTableCell, StyledTableRow } from "../../../StyledTable";
import { getSignedUrl } from "../../../../utils/getSignedUrl";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { DamgeImageView } from "./DamageImagesView";
import DEFAULT_VEHICLE_ICON from "../../../../assets/icons/hatchback.svg";
import { DateTime as d, Settings } from "luxon";
import ImageGallery from '../../../ImageGallery/ImageGallery';
import { AddDamage, coordinateOffset, svgHeight } from "./AddDamage";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { DATE_TYPE, getVehicleTypeIcon, mapFuelTypesAndDisplayType } from "../../../utils";
import { BookingStatusText, capitalizeFirstLetter } from "../../utils";

const Vehicle = () => {
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const queryParams = new URLSearchParams(window.location.search);
  const bookingId: string | any = queryParams.get("booking");
  const interiorSvgRef = useRef<any>(null);
  const exteriorSvgRef = useRef<any>(null);
  const history = useHistory();
  const snackbar = useSnackBar();
  const location = useLocation();
  const { id: vehicleId } = useParams<{ id: string }>();
  const [vehicle, setVehicle] = useState<IVehicle>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [previewDialog, setPreviewDialog] = useState<{ open: boolean, images: string[] }>({ open: false, images: [] });
  const [booking, setBooking] = useState<IBooking>();
  const [note, setNote] = useState("")
  const [addDamage, setAddDamage] = useState<boolean>(false);
  const [damageValues, setDamageValues] = useState<any>(null);
  const [nextService, setNextService] = useState<IVehicleService>();


  const [getVehicle, { data: vehicleData }] =
    useLazyQuery(GET_VEHICLE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.vehicle) {
          history.push("/account/vehicles");
        }
      }
    });

  const [loadBooking, { data: bookingData }] = useLazyQuery(GET_BOOKING_DATA_FOR_VEHICLE, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (vehicleId) {
      getVehicle({
        variables: {
          id: vehicleId,
        },
      });
    }
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const booking = params.get("booking");
      const _note = params.get("note");
      if (booking) {
        loadBooking({
          variables: {
            bookingId: booking
          }
        });
      }
      if (_note) {
        const decodedNote = decodeURIComponent(_note);
        setNote(decodedNote)
      }
    }
  }, [location]);

  useEffect(() => {
    if (vehicleData && vehicleData.vehicle) {
      const vehicleNextService = vehicleData.vehicle.services.find((service: IVehicleService) =>
        service.status !== VehicleServiceStatus.COMPLETED && service.startDate >= d.now().toUTC().toISO());
      if (vehicleNextService) {
        setNextService(vehicleNextService);
      }
      (async () => {
        const vehicle = vehicleData.vehicle;
        const file = await getVehicleImage(vehicle.imageUrl);
        const resShapeVehicle = { ...vehicle, imageUrl: file };
        setVehicle(resShapeVehicle);
      })();
    }
  }, [vehicleData]);

  useEffect(() => {
    if (bookingData && bookingData) {
      const _booking: IBooking = bookingData.consumerGetBooking;
      Settings.defaultZone = _booking.branch?.timeZone || "Europe/London"
      setBooking(_booking);
      return () => {
        Settings.defaultZone = "Europe/London"
      }
    }
  }, [bookingData]);

  const getVehicleImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url);
        return file;
      } catch (error) {
        snackbar({
          message: error as string,
          variant: SnackBarVariant.ERROR,
        });
      }
    }
  };
  const addMark = (damage: any, markerRadius: number, markerFont: number, type: string,) => {

    const xVal = parseInt(damage.x);
    const yVal = parseInt(damage.y);
    setDamageValues({
      title: "",
      descriptions: "",
      damageType: type,
      damageKind: "",
      damageArea: "",
      images: [],
      location: {
        x: xVal - coordinateOffset,
        y: yVal - coordinateOffset
      }
    });
  }

  if (!vehicle) {
    return <CircularProgress size={20} thickness={5} />;
  }

  const exteriorMarkers: JSX.Element[] = [];
  const exteriorDamageList: IDamageList[] = [];
  const interiorMarkers: JSX.Element[] = [];
  const interiorDamageList: IDamageList[] = [];

  let interiorCount = 0;
  let exteriorCount = 0;
  for (let i = 0; i < vehicle.activeVehicleDamages.length; i++) {
    const damage = vehicle.activeVehicleDamages[i];
    for (let j = 0; j < damage.damages.length; j++) {
      const d = damage.damages[j];
      let x = 50 + d.location.x;
      let y = 50 + d.location.y;
      if (d.damageType === DamageTypes.EXTERIOR) {
        exteriorCount++;
        exteriorMarkers.push(
          <g id={`damage ${exteriorCount}`} style={{ cursor: "pointer" }}>
            <circle
              cx={`${x}`}
              cy={`${y}`}
              r={`${FrogDiagram[vehicle.frogDiagram].exterior.markerRadius}`}
              fill="red"
              stroke-width="0.5"
            />
            <text
              text-anchor="middle"
              x={`${x}`}
              y={`${y}`}
              dy="0.30em"
              font-size={`${FrogDiagram[vehicle.frogDiagram].exterior.markerFont
                }em`}
              stroke-width="0.8"
              stroke="white"
              fill="white"
            >
              {exteriorCount}
            </text>
          </g>
        );

        exteriorDamageList.push({
          number: exteriorCount,
          damageKind: d.damageKind,
          images: d.images || [],
        });
      }
      if (d.damageType === DamageTypes.INTERIOR) {
        interiorCount++;
        interiorMarkers.push(
          <g id={`damage ${interiorCount}`} style={{ cursor: "pointer" }}>
            <circle
              cx={`${x}`}
              cy={`${y}`}
              r={`${FrogDiagram[vehicle.frogDiagram].interior.markerRadius}`}
              fill="red"
              stroke-width="0.5"
            />
            <text
              text-anchor="middle"
              x={`${x}`}
              y={`${y}`}
              dy="0.30em"
              font-size={`${FrogDiagram[vehicle.frogDiagram].interior.markerFont
                }em`}
              stroke-width="0.8"
              stroke="white"
              fill="white"
            >
              {interiorCount}
            </text>
          </g>
        );
        interiorDamageList.push({
          number: interiorCount,
          damageKind: d.damageKind,
          images: d.images || [],
        });
      }
    }
  }

  const getLevel = (fuelLevel: string) => {
    if (fuelLevel === "e") return "0%";
    else if (fuelLevel === "E") return "100%";
    else if (fuelLevel === "f") return "100%";
    else if (fuelLevel === "F") return "100%";
    else return `${eval(fuelLevel) * 100}%`;
  };

  const getFuelLabel = (fuelLevel: string) => {
    if (fuelLevel === "e") return "E";
    else if (fuelLevel === "E") return "E";
    else if (fuelLevel === "f") return "F";
    else if (fuelLevel === "F") return "F";
    else return fuelLevel;
  };

  const isBusinessCustomerBooking = booking ?
    booking.customerType === "business" &&
      booking.businessCustomer &&
      booking.businessCustomer.id !== ""
      ? true
      : false : false;

  const { fuelAndBattery, fuelOnly, batteryOnly } = mapFuelTypesAndDisplayType(vehicle?.fuelType);
  
  return (
    <div className={styles.column}>
      <UpdateVehicleStateDialog
        open={dialogOpen}
        setOpen={(open: boolean) => {
          setDialogOpen(open);
        }}
        vehicle={vehicle}
        setVehicleState={(vehicleState: IVehicleState) =>
          setVehicle({ ...vehicle, vehicleState })
        }
      />
      <div className="flex cross-center">
        <Typography
          variant="h4"
          className="semi-bold flex cross-center space-between"
        >
          <IconButton
            size="small"
            edge="start"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="margin-left">Vehicle Details</span>
        </Typography>
        <div className="flex fill main-end">
          {booking?.status === BookingStatus.IN_PROGRESS && <Button
            onClick={() => setDialogOpen(true)}
            variant="contained"
            color="primary"
            size="large"
            style={{
              background: "var(--primary-color)",
              textTransform: "initial",
              boxShadow: "none",
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            Update Vehicle state
          </Button>}
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} md={5} style={{ display: "flex" }}>
          <div className={styles.card}>
            <div className="flex">
              <div className={styles.vehicleImage}>
                {vehicle.imageUrl ? (
                  <img
                    className="img-cover"
                    src={vehicle.imageUrl}
                    alt="vehicleImg"
                  />
                ) : (
                  <img
                    className="img-cover"
                    src={getVehicleTypeIcon(capitalizeFirstLetter(vehicle.bodyType))}
                    alt="vehicleImg"
                    style={{ objectFit: "contain" }}
                  />
                )}
              </div>
              <div className="flex col-flex space-between"> 
              {booking?.status &&  [BookingStatus.IN_PROGRESS, BookingStatus.COMPLETED].includes(booking.status) && (
                <Typography className="bold">{vehicle.licencePlate}</Typography>
              )}
              <div>
                  <Typography>
                    {vehicle.make} | {vehicle.model} | {vehicle.colour}
                  </Typography>
                  <span className="font-small">VIN: {vehicle.vin}</span>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: "flex" }}>
          {vehicle.vehicleState.fuelStatus && (fuelAndBattery || fuelOnly) && <div className={styles.card}>
            <Typography className="semi-bold">Fuel Status</Typography>
            <div>
              <Typography className="flex space-between cross-center">
                <span>{getFuelLabel(vehicle.vehicleState.fuelStatus)}</span>
                <span className="opacity40 font-small flex cross-center">
                  <HistoryIcon fontSize="inherit" />
                  <span style={{ paddingLeft: 3 }}>{vehicle.vehicleState.updatedAt && getLocalizedDateFormat(country, vehicle.vehicleState.updatedAt, DATE_TYPE.CONDENSED)}</span>
                </span>
              </Typography>
              <div className={styles.fuelIndicator}>
                <div
                  style={{
                    width: getLevel(vehicle.vehicleState.fuelStatus)
                  }}
                ></div>
              </div>
            </div>
          </div>}
          {(typeof vehicle.vehicleState.batteryStatus === 'number' && (fuelAndBattery || batteryOnly)) && <div className={styles.card}>
            <Typography className="semi-bold">Battery Status</Typography>
            <div>
              <Typography className="flex space-between cross-center">
                <span>{vehicle.vehicleState.batteryStatus}%</span>
                <span className="opacity40 font-small flex cross-center">
                  <HistoryIcon fontSize="inherit" />
                  <span style={{ paddingLeft: 3 }}>1 day</span>
                </span>
              </Typography>
              <div className={styles.fuelIndicator}>
                <div
                  style={{
                    width: `${vehicle.vehicleState.batteryStatus || 0}%`
                  }}
                ></div>
              </div>
            </div>
          </div>}
        </Grid>
        <Grid item xs={12} md={3} style={{ display: "flex" }}>
          <div className={styles.card} style={{ marginRight: 0 }}>
            <div className="flex">
              <div className="flex fill col-flex space-between">
                <Typography className="semi-bold">Odometer Reading</Typography>
                <div>
                  <Typography style={{ marginTop: 5 }}>
                    <span className="flex cross-center">
                      <SpeedIcon fontSize="inherit" />
                      <span style={{ paddingLeft: 2 }}>
                        {vehicle.vehicleState.odometerReading}
                      </span>
                    </span>
                  </Typography>
                  <Typography>
                    <span className="opacity40 font-small flex cross-center">
                      <HistoryIcon fontSize="inherit" />
                      <span style={{ paddingLeft: 3 }}>{vehicle.vehicleState.updatedAt && getLocalizedDateFormat(country, vehicle.vehicleState.updatedAt, DATE_TYPE.CONDENSED)}</span>
                    </span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        {booking ? <Grid item xs={12} style={{ backgroundColor: "rgba(0,0,0,0.03)", borderRadius: 10, marginTop: 20 }}>
          <Grid container >
            <Grid item xs={12} md={3} style={{ display: "flex" }}>
              <div className={`${styles.card} ${styles.noBg}`}>
                <div className="margin-bottom">
                  <Typography variant="body1" className="padding-bottom--small bold">{getLocalizedBookingSyntex(country)} Details</Typography>
                  <Typography variant="body1" className="" style={{ opacity: 0 }}>.</Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">{getLocalizedBookingSyntex(country)} Reference:</Typography>
                  <Typography variant="body1" className="">{booking.referenceNumber}</Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">{getLocalizedBookingSyntex(country)} Status:</Typography>
                  <Typography variant="body1" className="">{BookingStatusText[booking.status]}</Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5} style={{ display: "flex" }}>
              <div className={`${styles.card} ${styles.noBg}`}>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">
                    {booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                      ? "Delivery Address, Date & Time:"
                      : "Pick-up Location, Date & Time:"}
                  </Typography>
                  <Typography variant="body1" className="">
                    {`${booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                      ? booking.pickupOtherLocation.fullAddress
                      : booking.pickupServiceLocation?.name || "N/A"
                      }`}
                  </Typography>
                  <Typography variant="body1" className="">
                    {booking.pickupDateTime && getLocalizedDateFormat(country, booking.pickupDateTime, DATE_TYPE.EXPANDED, booking.branch?.timeZone)}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">
                    {booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                      ? "Collection Address, Date & Time:"
                      : "Drop-off Location, Date & Time:"}
                  </Typography>
                  <Typography variant="body1" className="">
                    {`${booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                      ? booking.dropoffOtherLocation.fullAddress
                      : booking.dropoffServiceLocation?.name || "N/A"
                      }`}
                  </Typography>
                  <Typography variant="body1" className="">
                    {booking.dropoffDateTime && getLocalizedDateFormat(country, booking.dropoffDateTime, DATE_TYPE.EXPANDED, booking.branch?.timeZone)}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} style={{ display: "flex" }}>
              <div className={`${styles.card} ${styles.noBg}`} style={{ marginRight: 0 }}>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Customer:</Typography>
                  <Typography variant="body1" className="">
                    {`${isBusinessCustomerBooking
                      ? booking.businessCustomer?.businessName || ""
                      : `${booking.customer?.firstName} ${booking.customer?.lastName}`
                      }`}
                  </Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Email Address:</Typography>
                  <Typography variant="body1" className="">
                    {`${isBusinessCustomerBooking
                      ? booking.businessCustomer?.billing.email
                      : booking.customer
                        ? booking.customer.email
                        : ""
                      }`}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Phone Number:</Typography>
                  <Typography variant="body1" className="">
                    {`${isBusinessCustomerBooking
                      ? booking.businessCustomer?.billing.phoneNumber.phone || ""
                      : booking.customer
                        ? booking.customer.phoneNumber.phone
                        : ""
                      }`}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid> : null}
        {nextService ? <Grid item xs={12} style={{ backgroundColor: "rgba(0,0,0,0.03)", borderRadius: 10, marginTop: 20 }}>
          <Grid container >
            <Grid item xs={12} md={3} style={{ display: "flex" }}>
              <div className={`${styles.card} ${styles.noBg}`}>
                <div className="margin-bottom">
                  <Typography variant="body1" className="padding-bottom--small bold">Next Service Details</Typography>
                  <Typography variant="body1" className="" style={{ opacity: 0 }}>.</Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small"> Service Type:</Typography>
                  <Typography variant="body1" className="">{nextService.serviceType}</Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Service Status:</Typography>
                  <Typography variant="body1" className="">{nextService.status}</Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5} style={{ display: "flex" }}>
              <div className={`${styles.card} ${styles.noBg}`}>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">
                    {"Appointment Date"}
                  </Typography>
                  <Typography variant="body1" className="">
                    {getLocalizedDateFormat(country, nextService.appointmentDate, DATE_TYPE.EXPANDED)}
                  </Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">
                    {"Estd. Completion Date"}
                  </Typography>
                  <Typography variant="body1" className="">
                    {getLocalizedDateFormat(country, nextService.completionDate, DATE_TYPE.EXPANDED)}
                  </Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">
                    {"Odometer Reading"}
                  </Typography>
                  <Typography variant="body1" className="">
                    {nextService.odometerReading ? nextService.odometerReading : "NA"}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} style={{ display: "flex" }}>
              <div className={`${styles.card} ${styles.noBg}`} style={{ marginRight: 0 }}>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Service Provider Name:</Typography>
                  <Typography variant="body1" className="">
                    {nextService.serviceProviderName ? nextService.serviceProviderName : "NA"}
                  </Typography>
                </div>
                <div className="margin-bottom">
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Service Provider Address:</Typography>
                  <Typography variant="body1" className="">
                    {nextService.serviceProviderAddress && nextService.serviceProviderAddress.fullAddress ?
                      nextService.serviceProviderAddress.fullAddress : "NA"}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid> : null}
        {note && <Grid item xs={12} style={{ display: "flex" }}>
          <div className={`${styles.card}`} style={{ marginRight: 0 }}>
            <div className="margin-bottom">
              <Typography variant="body1" className="padding-bottom--small bold">Notes for driver:</Typography>
              <Typography variant="body1" className="">
                {note}
              </Typography>
            </div>
          </div>
        </Grid>}
      </Grid>
      {addDamage && (
        <AddDamage
          title={"Mark Damage"}
          open={addDamage}
          handleClose={() => {
            setAddDamage(false)
            getVehicle({
              variables: {
                id: vehicleId,
              },
            });
          }}
          svg={FrogDiagram[vehicle.frogDiagram]}
          values={damageValues}
          licencePlate={vehicle.licencePlate}
          bookingReferenceNumber={booking?.referenceNumber || ""}
          frogDiagram={vehicle?.frogDiagram}
          vehicleId={vehicle.id}
          bookingId={bookingId}
          pickupDateTime={booking?.pickupDateTime || ""}
          dropoffDateTime={booking?.dropoffDateTime || ""}
        />)}
      <Grid container spacing={1} style={{ marginTop: 30 }}>
        <Grid item xs={12} md={6}>
          <div className={`${styles.box} flex col-flex cross-center`}>
            <div className="font-medium semi-bold margin-bottom">
              Exterior damages
            </div>
            <Paper >
              <svg xmlns="http://www.w3.org/2000/svg"
                style={{ height: 400 }}
                ref={exteriorSvgRef}
                onClick={event => {
                  if (booking?.status === BookingStatus.IN_PROGRESS) {
                    const rect = exteriorSvgRef.current.getBoundingClientRect();
                    const location = {
                      x: (event.clientX - rect.left) * FrogDiagram[vehicle.frogDiagram]?.exterior.width / rect.width,
                      y: (event.clientY - rect.top) * FrogDiagram[vehicle.frogDiagram]?.exterior.height / svgHeight
                    };
                    addMark(location, FrogDiagram[vehicle.frogDiagram]?.exterior.markerRadius, FrogDiagram[vehicle.frogDiagram]?.exterior.markerFont, DamageTypes.EXTERIOR)
                    setAddDamage(true)
                  }
                }}
                viewBox={`0 0 ${FrogDiagram[vehicle.frogDiagram].exterior.width} ${FrogDiagram[vehicle.frogDiagram].exterior.height}`}>
                {FrogDiagram[vehicle.frogDiagram].exterior.svg}
                {exteriorMarkers}
              </svg>
            </Paper>
            <div className={styles.damageImages}>
              {exteriorDamageList.map((d, i) => (
                <div className={styles.image}>
                  <div className={styles.tag}>
                    <p>{d.number}</p>
                    <span>{d.damageKind || "--"}</span>
                  </div>
                  {d.images && d.images.length > 0 && (
                    <Grid item xs={12}>
                      <DamgeImageView damageImages={d.images} openPreview={() => setPreviewDialog({ open: true, images: d.images })} />
                    </Grid>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {vehicle.vehicleType !== IVehicleType.TWO_WHEELER && vehicle.vehicleType !== IVehicleType.ATV && <div className={`${styles.box} flex col-flex cross-center`}>
            <div className="font-medium semi-bold margin-bottom">
              Interior damages
            </div>
            <Paper >
              <svg xmlns="http://www.w3.org/2000/svg"
                style={{ height: 400 }}
                ref={interiorSvgRef}
                onClick={(event) => {
                  if (booking?.status === BookingStatus.IN_PROGRESS) {
                    const rect = interiorSvgRef.current.getBoundingClientRect();
                    const location = {
                      x: (event.clientX - rect.left) * FrogDiagram[vehicle.frogDiagram]?.interior.width / rect.width,
                      y: (event.clientY - rect.top) * FrogDiagram[vehicle.frogDiagram]?.interior.height / svgHeight
                    };
                    addMark(location, FrogDiagram[vehicle.frogDiagram]?.interior.markerRadius, FrogDiagram[vehicle.frogDiagram]?.interior.markerFont, DamageTypes.INTERIOR)
                    setAddDamage(true)
                  }
                }}
                viewBox={`0 0 ${FrogDiagram[vehicle.frogDiagram].interior.width} ${FrogDiagram[vehicle.frogDiagram].interior.height}`}>
                {FrogDiagram[vehicle.frogDiagram].interior.svg}
                {interiorMarkers}
              </svg>
            </Paper>
            <div className={styles.damageImages}>
              {interiorDamageList.map((d, i) => {
                return (
                  <div className={styles.image}>
                    <div className={styles.tag}>
                      <p>{d.number}</p>
                      <span>{d.damageKind || "--"}</span>
                    </div>
                    {d.images && d.images.length > 0 && (
                      <Grid item xs={12}>
                        <DamgeImageView damageImages={d.images} openPreview={() => setPreviewDialog({ open: true, images: d.images })} />
                      </Grid>
                    )}
                  </div>
                );
              })}
            </div>
          </div>}
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={`${styles.box} flex col-flex cross-center`}>
            <div className="font-medium semi-bold margin-bottom padding-bottom">
              General checks
            </div>
            <TableContainer style={{ maxHeight: "100%" }} className="px-10">
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Images</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicle.vehicleState.generalChecks.map((check, index) => (
                    <StyledTableRow
                      key={index}
                    >
                      <StyledTableCell>{check.name}</StyledTableCell>
                      <StyledTableCell>{check.status}</StyledTableCell>
                      <StyledTableCell>
                        {check.images && check.images.length > 0 ? (
                          <Grid item xs={12}>
                            <DamgeImageView damageImages={check.images} openPreview={() => setPreviewDialog({ open: true, images: check.images })} />
                          </Grid>
                        ) : (
                          "NA"
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={`${styles.box} flex col-flex cross-center`}>
            <div className="font-medium semi-bold margin-bottom padding-bottom">
              Accessories checks
            </div>
            <TableContainer style={{ maxHeight: "100%" }} className="px-10">
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Images</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicle.vehicleState.defaultAccesories.map(
                    (check, index) => (
                      <StyledTableRow
                        key={index}
                      >
                        <StyledTableCell>{check.name}</StyledTableCell>
                        <StyledTableCell>{check.status}</StyledTableCell>
                        <StyledTableCell>
                          {check.images && check.images.length > 0 ? (
                            <Grid item xs={12}>
                              <DamgeImageView damageImages={check.images} openPreview={() => setPreviewDialog({ open: true, images: check.images })} />
                            </Grid>
                          ) : (
                            "NA"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      <ImageGallery
        title={"Vehicle Damage Images"}
        open={previewDialog.open}
        handleClose={() => setPreviewDialog({ open: false, images: [] })}
        images={previewDialog.images}
      />
      <Grid container></Grid>
    </div>
  );
};

export default Vehicle;
