import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";

import theme from "../../../Theme";
import { DropzoneDialog } from "material-ui-dropzone";
interface IDropzoneDialogProps {
  open: boolean;
  handleSave: (files: File[]) => void;
  deleteSelectedImage?: (file: File) => void;
  handleCloseDragDrop: () => void;
  filesLimit: number;
  title?: string;
  initialFiles?: any[];
  acceptedFiles?: string[];
}

export const DragDropImages = (props: IDropzoneDialogProps) => {
  const getFileLimitExceedCustomMessage = (filesLimit: number) => {
    return `Maximum ${filesLimit} images are allowed to upload.`
  }

  const appTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: "#002F6E"
      },
      secondary: {
        ...theme.palette.secondary,
        main: "#002F6E"
      }
    }
  }

  return (
    <MuiThemeProvider theme={appTheme}>
      <div className="dragDropDialouge">
        <DropzoneDialog
          open={props.open}
          onSave={props.handleSave}
          onDelete={props.deleteSelectedImage}
          acceptedFiles={props.acceptedFiles ? props.acceptedFiles : ["image/jpeg", "image/png", "image/jpg"]}
          initialFiles={props.initialFiles ? props.initialFiles : []}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={props.handleCloseDragDrop}
          filesLimit={props.filesLimit || 10}
          dropzoneText={`Drag and drop a file or click (Maximum ${props.filesLimit} 
            ${props.filesLimit === 1 ? "image" : "images"} are allowed)`}
          maxWidth="md"
          dialogTitle={props.title ? props.title : `Upload Images`}
          showAlerts={false}
          getFileLimitExceedMessage={() => getFileLimitExceedCustomMessage(props.filesLimit)}
          previewGridProps={{
            container: { spacing: 0 }
          }}
          alertSnackbarProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }}
        />
      </div>
    </MuiThemeProvider>
  );
};
