import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateAuthStateAction } from '../../../actions/auth/actions';
import { AuthStates } from '../../../reducers/auth/types';

import imageIndividual from "../../../../src/assets/icons/customer/individual.png";
import imageBusiness from "../../../../src/assets/icons/customer/business.png";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 20
  },
  dialog: {
    borderRadius: '10px',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '160px',
    height: '180px',
    margin: theme.spacing(1),
    transition: 'transform 0.2s, box-shadow 0.2s, opacity 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    },
  },
}));


const CustomerSelection: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }));
  };

  const handleSelection = (val: string) => {
    if (val === "Individual") {
      dispatch(updateAuthStateAction({ state: AuthStates.SIGN_UP }));
    }
    if (val === "Business") {
      dispatch(updateAuthStateAction({ state: AuthStates.BUSINESS_CUSTOMER }));
    }
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={'sm'} open={open} onClose={handleClose} classes={{ paper: classes.dialog }}>
        <DialogTitle className={classes.title}>
          Register As
          <IconButton className={classes.closeButton} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Paper elevation={3} className={classes.button} onClick={() => handleSelection('Individual')}>
                <img src={imageIndividual} alt="Individual" />
                <Typography variant='h2' style={{color: 'var(--secondary-color)'}}>Individual</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Paper elevation={3} className={classes.button} onClick={() => handleSelection('Business')}>
                <img src={imageBusiness} alt="Business" />
                <Typography variant='h2' style={{color: 'var(--secondary-color)'}}>Business</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{padding: 10, color: 'grey'}}>
          <Typography>Please Note: The Operator has mandated a customer check before you can have a confirmed booking. Please wait for approval, and you will be notified of the same.</Typography>
          </Grid>
        </DialogContent>
        <Box style={{ height: 30 }}></Box>
      </Dialog>
    </div>
  );
};

export default CustomerSelection;
