import React, { useEffect, useState } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import Evaporate from 'evaporate';
import { v4 as UUID } from "uuid";

export const useMutation = <D, V extends any = {}>(queryFunc: (variables?: V) => Promise<AxiosResponse<D>>, options?: { onSuccess?: (data: D, variables?: V) => void, onError?: (err: AxiosError) => void }) => {
  const [response, setResponse] = useState<D>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setloading] = useState(false);

  const mutate = (variables: V) => {
    setloading(true);
    queryFunc(variables)
      .then((res) => {
        setResponse(res.data);
        if (options && options.onSuccess) {
          options.onSuccess(res.data, variables)
        }
      })
      .catch((err) => {
        setError(err);
        if (options && options.onError) {
          options.onError(err)
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  return { mutate, response, error, loading };
};

export const useQuery = <D, V = {}>(queryFunc: (variables?: V) => Promise<AxiosResponse<D>>, options?: { onSuccess?: (data: D, variables?: V) => void, onError?: (err: AxiosError) => void }) => {
  const [response, setResponse] = useState<D>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setloading] = useState(false);

  const query = (variables?: V) => {
    setloading(true);
    queryFunc(variables)
      .then((res) => {
        setResponse(res.data);
        if (options && options.onSuccess) {
          options.onSuccess(res.data, variables)
        }
      })
      .catch((err) => {
        setError(err);
        if (options && options.onError) {
          options.onError(err)
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  return { query, response, error, loading };
};

export const useImageUpload = (tenancy: string, _e_?: Evaporate, options?: { onSuccess?: (key: string) => void, onError?: (err: AxiosError) => void }) => {
  const [imageKey, setImageKey] = useState<string>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);

  const upload = async (file: any) => {
    try {
      let key: string = "";
      const uniqueId = UUID();
      if (file && file.type === "image/jpeg") {
        key = `${tenancy}-${uniqueId}.jpeg`;
      } else if (file && file.type === "image/png") {
        key = `${tenancy}-${uniqueId}.png`;
      } else if (file && file.type === "image/bmp") {
        key = `${tenancy}-${uniqueId}.bmp`;
      }
      key = `${tenancy}/${key}`;
      setLoading(true);
      if (_e_) {
        await _e_
          .add({
            name: key,
            file: file,
            complete: async () => {
              setLoading(false);
              setImageKey(key);
              if (options && options.onSuccess) {
                options.onSuccess(key)
              }
            }
          });
      }
    } catch (error) {
      setError(error);
      if (options && options.onError) {
        options.onError(error)
      }
    }
  }

  return { upload, imageKey, error, loading };
};

