import { gql } from "apollo-boost";

export const CREATE_CUSTOMER = gql`
  mutation consumerCreateCustomer($customer: ConsumerCustomerInput!) {
    consumerCreateCustomer(customer: $customer) {
      id
      firstName
      lastName
      phoneNumber{
        phone
        country
      }
      email
      location {
        fullAddress
        city
        country
        state
        zipcode
        street
      }
    }
  }
`;
export const ADD_NEW_DRIVERS = gql`
  mutation consumerAddDrivers($customerId: String!, $drivers: [NewDriverCustomerInput]!) {
    consumerAddDrivers(customerId: $customerId, drivers: $drivers) {
      id
      firstName
      lastName
      email
      phoneNumber {
        phone
        country
      }
      license {
        licenseNumber
        dateOfExpiry
        authority
        images
      }
    }
  }
`;

