import React from "react";

export const TipperTruckSVG = {
  interior: {
    width: 220,
    height: 583,
    svg: (
      <>
        <g id="Layer_1"></g>
        <g id="Layer_2"></g>
        <g id="Layer_3"></g>
        <g id="Layer_4"></g>
        <g id="Layer_5">
          <g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M110,7.1H40.8c0,0-0.1,0-0.1,0
				C39.8,7,28.7,6.2,23.9,14c-0.2,0.3-0.3,0.6-0.3,1v14.8c0,1,0.8,1.8,1.8,1.8h4.9c1,0,1.8,0.8,1.8,1.8v16.9c0,0.6-0.3,1.1-0.7,1.5
				L23.1,58c-0.5,0.3-0.7,0.9-0.7,1.5v79.4c0,1,0.8,1.8,1.8,1.8h0c1,0,1.8,0.8,1.8,1.8v11c0,1,0.8,1.8,1.8,1.8h38
				c1,0,1.8-0.8,1.8-1.8v0c0-1,0.8-1.8,1.8-1.8h15.9c1,0,1.8,0.8,1.8,1.8v15.9c0,1,0.8,1.8,1.8,1.8h22.1"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M110,7.1h69.2c0,0,0.1,0,0.1,0
				c0.9-0.1,12-0.8,16.8,6.9c0.2,0.3,0.3,0.6,0.3,1v14.8c0,1-0.8,1.8-1.8,1.8h-4.9c-1,0-1.8,0.8-1.8,1.8v16.9c0,0.6,0.3,1.1,0.7,1.5
				L197,58c0.5,0.3,0.7,0.9,0.7,1.5v79.4c0,1-0.8,1.8-1.8,1.8l0,0c-1,0-1.8,0.8-1.8,1.8v11c0,1-0.8,1.8-1.8,1.8h-38
				c-1,0-1.8-0.8-1.8-1.8v0c0-1-0.8-1.8-1.8-1.8h-15.9c-1,0-1.8,0.8-1.8,1.8v15.9c0,1-0.8,1.8-1.8,1.8H110"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M176.1,48.8H44c-3.1,0-5.7-2.6-5.7-5.7
			l-3.7-25.4c0-3.1,2.6-5.7,5.7-5.7h139.4c3.1,0,5.7,2.6,5.7,5.7l-3.7,25.4C181.8,46.2,179.2,48.8,176.1,48.8z"
            />
            <rect
              x="42"
              y="48.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="136.1"
              height="78.5"
            />
            <rect
              x="32.2"
              y="102.7"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="24.5"
            />
            <rect
              x="178.1"
              y="102.7"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="24.5"
            />
            <polyline
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              points="72.6,151.1 72.6,172 60.4,172 
			67.7,160.5 67.7,153.3 		"
            />
            <polyline
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              points="147.4,151.1 147.4,172 159.7,172 
			152.3,160.5 152.3,153.3 		"
            />

            <rect
              x="24.8"
              y="227.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="170.4"
              height="328.6"
            />
            <rect
              x="24.8"
              y="569.9"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="170.4"
              height="6.1"
            />
            <rect
              x="33.4"
              y="556.4"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="13.5"
            />
            <rect
              x="175.6"
              y="556.4"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="13.5"
            />

            <rect
              x="30.9"
              y="227.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="156.9"
              height="328.6"
            />

            <rect
              x="40.8"
              y="238.8"
              fill="#E8E8E8"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="137.3"
              height="307.8"
            />
            <rect
              x="24.8"
              y="171.4"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="169.2"
              height="56.4"
            />

            <rect
              x="24.8"
              y="171.4"
              fill="#F4F4F4"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="169.2"
              height="13.5"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M17.5,52.5l-7.9,7.9c-0.7,0.7-1.8,0.7-2.5,0
				l-1.9-1.9c-0.7-0.7-0.7-1.8,0-2.5l7.9-7.9c0.7-0.7,1.8-0.7,2.5,0l1.9,1.9C18.1,50.7,18.1,51.8,17.5,52.5z"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="30.9"
                y1="52.4"
                x2="17.5"
                y2="52.4"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M203.2,52.5l7.9,7.9c0.7,0.7,1.8,0.7,2.5,0
				l1.9-1.9c0.7-0.7,0.7-1.8,0-2.5l-7.9-7.9c-0.7-0.7-1.8-0.7-2.5,0l-1.9,1.9C202.5,50.7,202.5,51.8,203.2,52.5z"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="189.7"
                y1="52.4"
                x2="203.2"
                y2="52.4"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M168.1,95.5h-49.8c-2.1,0-3.8-1.7-3.8-3.8V65.4
			c0-2.1,1.7-3.8,3.8-3.8h49.8c2.1,0,3.8,1.7,3.8,3.8v26.3C171.9,93.8,170.2,95.5,168.1,95.5z"
            />
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M169.9,117.5h-52.5c-2.2,0-3.9-2-3.9-4.4l1-15.7
			c0-2.4,1.8-4.4,3.9-4.4h49.5c2.2,0,3.9,2,3.9,4.4l2,15.7C173.9,115.5,172.1,117.5,169.9,117.5z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M102.1,95.5H52.3c-2.1,0-3.8-1.7-3.8-3.8V65.4
			c0-2.1,1.7-3.8,3.8-3.8h49.8c2.1,0,3.8,1.7,3.8,3.8v26.3C105.9,93.8,104.2,95.5,102.1,95.5z"
            />
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M103.9,117.5H51.4c-2.2,0-3.9-2-3.9-4.4l1-15.7
			c0-2.4,1.8-4.4,3.9-4.4h49.5c2.2,0,3.9,2,3.9,4.4l2,15.7C107.9,115.5,106.1,117.5,103.9,117.5z"
            />
          </g>
        </g>
      </>
    )
  },

  exterior: {
    width: 730,
    height: 1154,
    svg: (
      <>
        <g id="Layer_1">
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M88.1,346.1v-55.2c0-1.4,1.1-2.5,2.5-2.5h31.8
			c0,0,4.9,9.8,24.6,13.5l55.3,12.3v-13.5c0,0,17.2,2.5,19.6,23.3h-6.1v78.6H106.6v-31.9C106.6,370.6,104.1,354.7,88.1,346.1z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M202.3,330V376c0,2.5-2,4.5-4.5,4.5h-33.6c-1.5,0-2.9-0.8-3.8-2.1
			l-24.1-37.8c-0.3-0.4-0.5-0.9-0.6-1.4c-0.6-2.4-2.3-9.9-2.3-14v-7.7c0-0.7,0.2-1.4,0.5-2c0,0,0,0,0-0.1c0.9-1.7,2.9-2.6,4.8-2.2
			l59.9,12.4C200.9,326.1,202.3,327.9,202.3,330z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M215.8,341.2c0,0,10.1,6.7,13.1,23.4c0.2,1.4,0.4,2.7,0.4,4.1v17
			c0,4.9-1.9,9.8-5.6,13.1c-2,1.8-4.6,3.4-7.9,3.8"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M122.5,288.4c0,0,27-2.5,27,13.5"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M126.2,385.4h-11.1v-17.2c0,0,7.4,1.2,11.1,4.9V385.4z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="203.6"
              y1="403.2"
              x2="203.6"
              y2="385.4"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="204.2"
              y1="385.4"
              x2="106.6"
              y2="385.4"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M59.9,338.7c24.4,0,44.2,19.8,44.2,44.2s-19.8,44.2-44.2,44.2"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M59.9,341.2c23.3,1.2,41.8,18.4,41.8,41.8s-17.2,41.8-41.8,41.8"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="59.9"
                y1="341.8"
                x2="59.9"
                y2="338.1"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="59.9"
                y1="427.7"
                x2="59.9"
                y2="424"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M88.1,305.5c0,0-2.5-11.1-16-20.9H48.8v16h3.7v6.1h-1.2v16
			c0,0,8.6,6.1,8.6,16"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="69.7"
              y1="339.3"
              x2="69.7"
              y2="305.5"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="88.1"
              y1="305.5"
              x2="69.1"
              y2="305.5"
            />

            <rect
              x="150.1"
              y="359"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 562.528 252.4043)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="9.8"
              height="97"
            />
            <polyline
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="142.2,412.4 137.3,418.5 113.9,418.5 94.3,432 78.3,432 
			78.3,425.9 90.6,425.9 107.2,415.4 107.2,412.4 		"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="78.3,432 57.4,432 57.4,449.2 42.7,449.2 42.7,579.4 
			68.5,579.4 68.5,699.7 68.5,817.6 83.2,817.6 83.2,799.2 90.6,799.2 99.2,783.2 99.2,440.6 93,432 		"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="68.5,805.3 42.7,821.3 42.7,827.4 47.6,827.4 47.6,831.1 
			35.3,831.1 35.3,816.4 68.5,793.1 		"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M99.2,457.8h44.2v9.8H179v13.5l-36.8,7.4h-43
			C99.2,488.5,99.4,458,99.2,457.8z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="2"
              stroke-miterlimit="10"
              d="M93.7,433.3h9.9c2.4,0,4.8,0.4,7,1.3
			c0.8,0.3,1.5,0.7,1.9,1c0.1,0.1,0.1,0.1,0.1,0.2l8.4,15.9c0,0,1.2,2.5,6.1,2.5h18.2v13.5"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M99.2,514.3h18.4l95.8-45.4c0,0,0.5-0.3,1.5-0.9
			c6.4-3.8,11.7-9.3,15.3-15.8c2.1-3.7,5.3-7.3,10.3-7.9c0,0,17.2-3.7,13.5,14.7c0,0,0,2.5-4.9,7.4l-63.9,41.8v60.2v266.5h11.1
			c0,0,4.9,0,4.9,4.9c0,0,1.2,4.9-4.9,6.1H93v-11.1h6.1V514.3z"
            />

            <rect
              x="141.6"
              y="806"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 993.554 704.3061)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="6.1"
              height="86"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="109,836 110.2,543.8 175.3,510.6 175.3,836 		"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="548.7"
              x2="110.2"
              y2="580.6"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="584.3"
              x2="110.2"
              y2="616.2"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="617.5"
              x2="110.2"
              y2="649.4"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="648.2"
              x2="109"
              y2="681.3"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="681.3"
              x2="109"
              y2="713.2"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="716.9"
              x2="109"
              y2="750.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="748.8"
              x2="109"
              y2="782"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="785.7"
              x2="109"
              y2="818.8"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="175.3"
              y1="816.4"
              x2="136"
              y2="836"
            />

            <rect
              x="8.9"
              y="528.4"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 603.0518 483.2671)"
              fill="#FFFFFF"
              stroke="#000000"
              stroke-miterlimit="10"
              width="101.9"
              height="29.5"
            />

            <rect
              x="16.3"
              y="538.2"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 603.0518 483.2671)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="87.2"
              height="9.8"
            />

            <rect
              x="-6.4"
              y="650.2"
              transform="matrix(-3.919103e-02 0.9992 -0.9992 -3.919103e-02 702.2668 627.2122)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="111.9"
              height="2"
            />
            <path
              fill="#D1D1D1"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M59.9,341.2c23.1,0,41.8,18.7,41.8,41.8
			c0,23.1-18.7,41.8-41.8,41.8"
            />
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="47"
                cy="383.5"
                r="36.2"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="47"
                cy="383.5"
                r="21.5"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="47"
                cy="383.5"
                r="8"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="44.5"
                cy="651.2"
                r="36.2"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="44.5"
                cy="651.2"
                r="21.5"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="44.5"
                cy="651.2"
                r="8"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="44.5"
                cy="743.3"
                r="36.2"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="44.5"
                cy="743.3"
                r="21.5"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="44.5"
                cy="743.3"
                r="8"
              />
            </g>
          </g>
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M636.5,340.5v-55.6c0-1.4-1.1-2.6-2.6-2.6h-32.1
			c0,0-4.9,9.9-24.7,13.6l-55.7,12.4v-13.6c0,0-17.3,2.5-19.8,23.5h6.2v79.2h110.1v-32.2C617.9,365.2,620.4,349.1,636.5,340.5z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M521.4,324.3v46.3c0,2.5,2,4.5,4.5,4.5h33.9c1.5,0,3-0.8,3.8-2.1
			l24.2-38.1c0.3-0.4,0.5-0.9,0.6-1.4c0.6-2.4,2.3-10,2.3-14.1v-7.7c0-0.7-0.2-1.4-0.5-2c0,0,0,0,0-0.1c-0.9-1.7-2.9-2.6-4.8-2.2
			L525,319.9C522.9,320.4,521.4,322.2,521.4,324.3z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M507.8,335.5c0,0-10.2,6.8-13.2,23.6c-0.2,1.4-0.4,2.8-0.4,4.1v17.1
			c0,5,1.9,9.8,5.6,13.2c2,1.9,4.7,3.4,8,3.8"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M601.8,282.3c0,0-27.2-2.5-27.2,13.6"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M598.1,380h11.1v-17.3c0,0-7.4,1.2-11.1,4.9V380z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="520.2"
              y1="398"
              x2="520.2"
              y2="380"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="519.6"
              y1="380"
              x2="617.9"
              y2="380"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M664.9,333c-24.6,0-44.5,19.9-44.5,44.5
				c0,24.6,19.9,44.5,44.5,44.5"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M664.9,335.5c-23.5,1.2-42,18.5-42,42c0,23.6,17.3,42,42,42"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="664.9"
                y1="336.1"
                x2="664.9"
                y2="332.4"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="664.9"
                y1="422.7"
                x2="664.9"
                y2="419"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M636.5,299.7c0,0,2.5-11.1,16.1-21H676v16.1h-3.7v6.2h1.2V317
			c0,0-8.7,6.2-8.7,16.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="655"
              y1="333.7"
              x2="655"
              y2="299.7"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="636.5"
              y1="299.7"
              x2="655.6"
              y2="299.7"
            />

            <rect
              x="564.1"
              y="353.5"
              transform="matrix(-4.485941e-11 1 -1 -4.485941e-11 971.3519 -166.7496)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="9.9"
              height="97.7"
            />
            <polyline
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="582,407.2 587,413.4 610.5,413.4 630.3,427 646.3,427 
			646.3,420.9 634,420.9 617.3,410.3 617.3,407.2 		"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="646.3,427 667.4,427 667.4,444.4 682.2,444.4 682.2,575.4 
			656.2,575.4 656.2,696.6 656.2,815.4 641.4,815.4 641.4,796.8 634,796.8 625.3,780.7 625.3,435.7 631.5,427 		"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="656.2,803 682.2,819.1 682.2,825.3 677.3,825.3 677.3,829 
			689.6,829 689.6,814.1 656.2,790.6 		"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M625.3,453h-44.5v9.9h-35.9v13.6l37.1,7.4h43.3
			C625.3,483.9,625.1,453.2,625.3,453z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="2"
              stroke-miterlimit="10"
              d="M630.9,428.3h-10c-2.4,0-4.8,0.4-7,1.4
			c-0.8,0.3-1.5,0.7-1.9,1c-0.1,0.1-0.1,0.1-0.1,0.2l-8.5,16c0,0-1.2,2.5-6.1,2.5h-18.3v13.6"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M625.3,509.9h-18.6l-96.5-45.8c0,0-0.5-0.3-1.5-0.9
			c-6.4-3.8-11.8-9.3-15.4-15.9c-2.1-3.8-5.3-7.4-10.4-7.9c0,0-17.3-3.7-13.6,14.8c0,0,0,2.5,4.9,7.4l64.3,42v60.6v268.4h-11.1
			c0,0-4.9,0-4.9,4.9c0,0-1.2,4.9,4.9,6.2h103.9v-11.1h-6.2V509.9z"
            />

            <rect
              x="576.5"
              y="803.6"
              transform="matrix(-4.500596e-11 1 -1 -4.500596e-11 1426.4712 267.3451)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="6.2"
              height="86.6"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="615.4,833.9 614.2,539.6 548.6,506.2 548.6,833.9 		"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="544.5"
              x2="614.2"
              y2="576.7"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="580.4"
              x2="614.2"
              y2="612.5"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="613.8"
              x2="614.2"
              y2="645.9"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="644.7"
              x2="615.4"
              y2="678.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="678.1"
              x2="615.4"
              y2="710.2"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="714"
              x2="615.4"
              y2="747.4"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="746.1"
              x2="615.4"
              y2="779.5"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="783.2"
              x2="615.4"
              y2="816.6"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="548.6"
              y1="814.1"
              x2="588.2"
              y2="833.9"
            />

            <rect
              x="613.6"
              y="524.1"
              transform="matrix(-4.488606e-11 1 -1 -4.488606e-11 1203.8585 -125.9373)"
              fill="#FFFFFF"
              stroke="#000000"
              stroke-miterlimit="10"
              width="102.6"
              height="29.7"
            />

            <rect
              x="621"
              y="534"
              transform="matrix(-4.489050e-11 1 -1 -4.489050e-11 1203.8585 -125.9373)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="87.8"
              height="9.9"
            />

            <rect
              x="618.9"
              y="646.8"
              transform="matrix(3.919103e-02 0.9992 -0.9992 3.919103e-02 1296.123 -52.3387)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="112.7"
              height="2"
            />
            <path
              fill="#D1D1D1"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M664.9,335.5c-23.2,0-42,18.8-42,42c0,23.2,18.8,42,42,42"
            />
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="677.9"
                cy="378.2"
                r="36.5"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="677.9"
                cy="378.2"
                r="21.6"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="677.9"
                cy="378.2"
                r="8"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="680.4"
                cy="647.8"
                r="36.5"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="680.4"
                cy="647.8"
                r="21.6"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="680.4"
                cy="647.8"
                r="8"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="680.4"
                cy="740.5"
                r="36.5"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="680.4"
                cy="740.5"
                r="21.6"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                cx="680.4"
                cy="740.5"
                r="8"
              />
            </g>
          </g>
        </g>
        <g id="Layer_2">
          <g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M292.6,232.2v28.2c0,2-1.8,3.6-3.9,3.6h-12.9
				c-2.2,0-3.9-1.6-3.9-3.6v-28.2"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M315.2,232.2v28.2c0,2-1.8,3.6-3.9,3.6h-12.9
				c-2.2,0-3.9-1.6-3.9-3.6v-28.2"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M422,232.2v28.2c0,2-1.8,3.6-3.9,3.6h-12.9
				c-2.2,0-3.9-1.6-3.9-3.6v-28.2"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M444.6,232.2v28.2c0,2-1.8,3.6-3.9,3.6h-12.9
				c-2.2,0-3.9-1.6-3.9-3.6v-28.2"
              />
            </g>
            <rect
              x="315.8"
              y="233.2"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="5"
              height="8.8"
            />
            <rect
              x="396.2"
              y="233.2"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="5"
              height="8.8"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="320.8"
              y1="237"
              x2="396.2"
              y2="237"
            />

            <rect
              x="269.3"
              y="13.3"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="178.5"
              height="187.3"
            />
            <rect
              x="274.3"
              y="13.3"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="167.2"
              height="8.8"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M325.9,45.6v-6.8c0-2.3,2.2-4.1,4.8-4.1h55.1
			c2.7,0,4.8,1.9,4.8,4.1v6.8"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M358.3,182.9h-76.5c-2.3,0-5-1.3-5-6.3l6.3-126.5
				c0-2.3,1.9-4.2,4.2-4.2h74.4"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M358.2,182.9h76.5c2.3,0,5-1.3,5-6.3l-6.3-126.5
				c0-2.3-1.9-4.2-4.2-4.2h-74.4"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M435.8,139.4"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M359,146.5h-60.5c-5.1,0-12.8-1.3-15.4-12.6l5.1-77.1
				c0-3.9,3.3-7.1,7.2-7.1h63.4"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M357.6,146.5h60.5c5.1,0,12.8-1.3,15.4-12.6l-5.1-77.1
				c0-3.9-3.3-7.1-7.2-7.1h-63.4"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M410.6,176.6H306.4c-1.1,0-2-0.9-2-2V156c0-1.1,0.9-2,2-2h104.2
			c1.1,0,2,0.9,2,2v18.7C412.6,175.8,411.7,176.6,410.6,176.6z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="156.5"
              x2="412"
              y2="156.5"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="159.1"
              x2="412"
              y2="159.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="161.6"
              x2="412"
              y2="161.6"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="164.1"
              x2="412"
              y2="164.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="166.6"
              x2="412"
              y2="166.6"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="169.1"
              x2="412"
              y2="169.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="171.6"
              x2="412"
              y2="171.6"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="305.1"
              y1="174.1"
              x2="412"
              y2="174.1"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M270.6,218.1v12.3c0,1.2,1.2,2.1,2.6,2.1h170.8
			c1.4,0,2.6-1,2.6-2.1v-12.3"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M359.5,219.4h-0.4h-85.7c-2.3,0-4.1-1.9-4.1-4.1l2.5-45.8
				c0-2.3,2.8-4.1,5-4.1"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M357.3,219.4h0.4h85.7c2.3,0,4.1-1.9,4.1-4.1l-2.5-45.8
				c0-2.3-1.9-4.1-4.1-4.1h-1.3"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M301.7,198h-23.3c-0.8,0-1.5-0.7-1.5-1.5v-7
			c0-0.8,0.7-1.5,1.5-1.5h23.3c0.8,0,1.5,0.7,1.5,1.5v7C303.2,197.3,302.5,198,301.7,198z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M287,214.4h-9c-0.6,0-1.1-0.5-1.1-1.1v-7.8
			c0-0.6,0.5-1.1,1.1-1.1h9c0.6,0,1.1,0.5,1.1,1.1v7.8C288.2,213.8,287.6,214.4,287,214.4z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M302.1,214.4h-9c-0.6,0-1.1-0.5-1.1-1.1v-7.8
			c0-0.6,0.5-1.1,1.1-1.1h9c0.6,0,1.1,0.5,1.1,1.1v7.8C303.2,213.8,302.7,214.4,302.1,214.4z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M439.9,198h-23.3c-0.8,0-1.5-0.7-1.5-1.5v-7
			c0-0.8,0.7-1.5,1.5-1.5h23.3c0.8,0,1.5,0.7,1.5,1.5v7C441.5,197.3,440.8,198,439.9,198z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M425.3,215.6h-9c-0.6,0-1.1-0.5-1.1-1.1v-7.8
			c0-0.6,0.5-1.1,1.1-1.1h9c0.6,0,1.1,0.5,1.1,1.1v7.8C426.4,215.1,425.9,215.6,425.3,215.6z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M440.4,215.6h-9c-0.6,0-1.1-0.5-1.1-1.1v-7.8
			c0-0.6,0.5-1.1,1.1-1.1h9c0.6,0,1.1,0.5,1.1,1.1v7.8C441.5,215.1,441,215.6,440.4,215.6z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="270.6"
              y1="200.5"
              x2="446.5"
              y2="200.5"
            />
            <path
              fill="#D1D1D1"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M377.6,211.8h-38.2c-0.6,0-1-0.5-1-1v-14.3
			c0-0.6,0.5-1,1-1h38.2c0.6,0,1,0.5,1,1v14.3C378.6,211.4,378.2,211.8,377.6,211.8z"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M282.5,66H263c0,0-5,0-5,5v49
				c0,0,0,3.8,3.8,3.8h17.6"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M264.5,86.2h-12.9c-0.6,0-1.1-0.5-1.1-1.1
				V72.1c0-0.6,0.5-1.1,1.1-1.1h12.9c0.6,0,1.1,0.5,1.1,1.1v12.9C265.5,85.7,265,86.2,264.5,86.2z"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M264.5,118.8h-12.9c-0.6,0-1.1-0.5-1.1-1.1
				V93.5c0-0.6,0.5-1.1,1.1-1.1h12.9c0.6,0,1.1,0.5,1.1,1.1v24.3C265.5,118.4,265,118.8,264.5,118.8z"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M433.9,66h19.5c0,0,5,0,5,5v49
				c0,0,0,3.8-3.8,3.8h-17.6"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M452,86.2h12.9c0.6,0,1.1-0.5,1.1-1.1V72.1
				c0-0.6-0.5-1.1-1.1-1.1H452c-0.6,0-1.1,0.5-1.1,1.1v12.9C450.9,85.7,451.4,86.2,452,86.2z"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M452,118.8h12.9c0.6,0,1.1-0.5,1.1-1.1
				V93.5c0-0.6-0.5-1.1-1.1-1.1H452c-0.6,0-1.1,0.5-1.1,1.1v24.3C450.9,118.4,451.4,118.8,452,118.8z"
              />
            </g>
          </g>
        </g>
        <g id="Layer_3"></g>
        <g id="Layer_4">
          <g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M296.8,1113.5v30c0,2.1-1.9,3.8-4.2,3.8h-13.7
				c-2.3,0-4.2-1.7-4.2-3.8v-30"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M320.8,1113.5v30c0,2.1-1.9,3.8-4.2,3.8h-13.7
				c-2.3,0-4.2-1.7-4.2-3.8v-30"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M435.6,1113.5v30c0,2.1-1.9,3.8-4.2,3.8h-13.7
				c-2.3,0-4.2-1.7-4.2-3.8v-30"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M459.7,1113.5v30c0,2.1-1.9,3.8-4.2,3.8h-13.7
				c-2.3,0-4.2-1.7-4.2-3.8v-30"
              />
            </g>
            <rect
              x="321.9"
              y="1113.5"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="5.3"
              height="9.3"
            />
            <rect
              x="408.3"
              y="1114.5"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="5.3"
              height="9.3"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="327.2"
              y1="1118.5"
              x2="408.3"
              y2="1118.5"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M275,907.2h-18c0,0-5.3,0-5.3,5v49.1
				c0,0,0,3.8,4,3.8h18.7"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M258.6,927.4h-13.9c-0.6,0-1.1-0.5-1.1-1.1
				v-13c0-0.6,0.5-1.1,1.1-1.1h13.9c0.6,0,1.1,0.5,1.1,1.1v13C259.7,926.9,259.2,927.4,258.6,927.4z"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M258.6,960.1h-13.9c-0.6,0-1.1-0.5-1.1-1.1
				v-24.3c0-0.6,0.5-1.1,1.1-1.1h13.9c0.6,0,1.1,0.5,1.1,1.1V959C259.7,959.6,259.2,960.1,258.6,960.1z"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M460,907.2h16.7c0,0,5.3,0,5.3,5v49.1
				c0,0,0,3.8-4,3.8h-18.7"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M475,927.4h13.9c0.6,0,1.1-0.5,1.1-1.1v-13
				c0-0.6-0.5-1.1-1.1-1.1H475c-0.6,0-1.1,0.5-1.1,1.1v13C474,926.9,474.5,927.4,475,927.4z"
              />
              <path
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M475,960.1h13.9c0.6,0,1.1-0.5,1.1-1.1
				v-24.3c0-0.6-0.5-1.1-1.1-1.1H475c-0.6,0-1.1,0.5-1.1,1.1V959C474,959.6,474.5,960.1,475,960.1z"
              />
            </g>

            <rect
              x="275.1"
              y="879.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="184.2"
              height="162.9"
            />

            <rect
              x="275.1"
              y="1042.7"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="184.2"
              height="21.4"
            />

            <rect
              x="333.9"
              y="1064.1"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="69.4"
              height="13.4"
            />

            <rect
              x="275.1"
              y="1064.1"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="58.7"
              height="37.4"
            />

            <rect
              x="403.3"
              y="1064.1"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="56.1"
              height="37.4"
            />
            <rect
              x="275.1"
              y="1101.5"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="184.2"
              height="12"
            />
            <g>
              <rect
                x="275.1"
                y="949.3"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="184.2"
                height="93.5"
              />

              <rect
                x="280.5"
                y="954.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="173.6"
                height="82.8"
              />
              <rect
                x="307.2"
                y="954.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="6.7"
                height="82.8"
              />
              <rect
                x="344.6"
                y="954.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="6.7"
                height="82.8"
              />
              <rect
                x="384.6"
                y="954.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="6.7"
                height="82.8"
              />
              <rect
                x="422"
                y="954.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="6.7"
                height="82.8"
              />
              <rect
                x="275.1"
                y="1042.7"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="18.7"
                height="8"
              />
              <rect
                x="440.7"
                y="1042.7"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="18.7"
                height="8"
              />
            </g>
            <g>
              <rect
                x="269.8"
                y="949.3"
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="10.7"
                height="5.3"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.5"
                stroke-miterlimit="10"
                d="M270.5,949.3c0-1.1,0.7-2,1.7-2s1.7,0.9,1.7,2"
              />
            </g>
            <g>
              <rect
                x="454"
                y="949.3"
                transform="matrix(-1 -4.496016e-11 4.496016e-11 -1 918.7698 1903.8625)"
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="10.7"
                height="5.3"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.5"
                stroke-miterlimit="10"
                d="M464.1,949.3c0-1.1-0.7-2-1.7-2
				c-0.9,0-1.7,0.9-1.7,2"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M449,1080.1h-32.7c-0.6,0-1-0.5-1-1v-8.7
			c0-0.6,0.5-1,1-1H449c0.6,0,1,0.5,1,1v8.7C450,1079.6,449.6,1080.1,449,1080.1z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M319.5,1080.1h-32.7c-0.6,0-1-0.5-1-1v-8.7
			c0-0.6,0.5-1,1-1h32.7c0.6,0,1,0.5,1,1v8.7C320.5,1079.6,320.1,1080.1,319.5,1080.1z"
            />
            <g>
              <line
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                x1="333.2"
                y1="1086.8"
                x2="360.6"
                y2="1086.8"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                x1="333.2"
                y1="1089.4"
                x2="360.6"
                y2="1089.4"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                x1="375.3"
                y1="1086.8"
                x2="402.6"
                y2="1086.8"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                x1="375.3"
                y1="1089.4"
                x2="402.6"
                y2="1089.4"
              />
            </g>

            <ellipse
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              cx="367.9"
              cy="1087.5"
              rx="11.3"
              ry="7.3"
            />
          </g>
        </g>
        <g id="Layer_5">
          <g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M360,287.1h-69.2c0,0-0.1,0-0.1,0
				c-0.9-0.1-12-0.8-16.8,6.9c-0.2,0.3-0.3,0.6-0.3,1v14.8c0,1,0.8,1.8,1.8,1.8h4.9c1,0,1.8,0.8,1.8,1.8v16.9c0,0.6-0.3,1.1-0.7,1.5
				l-8.3,6.3c-0.5,0.3-0.7,0.9-0.7,1.5v79.4c0,1,0.8,1.8,1.8,1.8l0,0c1,0,1.8,0.8,1.8,1.8v11c0,1,0.8,1.8,1.8,1.8h38
				c1,0,1.8-0.8,1.8-1.8l0,0c0-1,0.8-1.8,1.8-1.8h15.9c1,0,1.8,0.8,1.8,1.8v15.9c0,1,0.8,1.8,1.8,1.8h22.1"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M360,287.1h69.2c0,0,0.1,0,0.1,0
				c0.9-0.1,12-0.8,16.8,6.9c0.2,0.3,0.3,0.6,0.3,1v14.8c0,1-0.8,1.8-1.8,1.8h-4.9c-1,0-1.8,0.8-1.8,1.8v16.9c0,0.6,0.3,1.1,0.7,1.5
				l8.3,6.3c0.5,0.3,0.7,0.9,0.7,1.5v79.4c0,1-0.8,1.8-1.8,1.8l0,0c-1,0-1.8,0.8-1.8,1.8v11c0,1-0.8,1.8-1.8,1.8h-38
				c-1,0-1.8-0.8-1.8-1.8l0,0c0-1-0.8-1.8-1.8-1.8h-15.9c-1,0-1.8,0.8-1.8,1.8v15.9c0,1-0.8,1.8-1.8,1.8H360"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M426.1,328.8H294c-3.1,0-5.7-2.6-5.7-5.7
			l-3.7-25.4c0-3.1,2.6-5.7,5.7-5.7h139.4c3.1,0,5.7,2.6,5.7,5.7l-3.7,25.4C431.8,326.2,429.2,328.8,426.1,328.8z"
            />
            <rect
              x="292"
              y="328.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="136.1"
              height="78.5"
            />
            <rect
              x="282.2"
              y="382.7"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="24.5"
            />
            <rect
              x="428.1"
              y="382.7"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="24.5"
            />
            <polyline
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              points="322.6,431.1 322.6,452 310.4,452 
			317.7,440.5 317.7,433.3 		"
            />
            <polyline
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              points="397.4,431.1 397.4,452 409.7,452 
			402.3,440.5 402.3,433.3 		"
            />

            <rect
              x="274.8"
              y="507.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="170.4"
              height="328.6"
            />
            <rect
              x="274.8"
              y="849.9"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="170.4"
              height="6.1"
            />
            <rect
              x="283.4"
              y="836.4"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="13.5"
            />
            <rect
              x="425.6"
              y="836.4"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="9.8"
              height="13.5"
            />

            <rect
              x="280.9"
              y="507.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="156.9"
              height="328.6"
            />

            <rect
              x="290.8"
              y="518.8"
              fill="#E8E8E8"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="137.3"
              height="307.8"
            />

            <rect
              x="274.8"
              y="451.4"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="169.2"
              height="56.4"
            />

            <rect
              x="274.8"
              y="451.4"
              fill="#F4F4F4"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="169.2"
              height="13.5"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M267.5,332.5l-7.9,7.9c-0.7,0.7-1.8,0.7-2.5,0
				l-1.9-1.9c-0.7-0.7-0.7-1.8,0-2.5l7.9-7.9c0.7-0.7,1.8-0.7,2.5,0l1.9,1.9C268.1,330.7,268.1,331.8,267.5,332.5z"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="280.9"
                y1="332.4"
                x2="267.5"
                y2="332.4"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M453.2,332.5l7.9,7.9c0.7,0.7,1.8,0.7,2.5,0
				l1.9-1.9c0.7-0.7,0.7-1.8,0-2.5l-7.9-7.9c-0.7-0.7-1.8-0.7-2.5,0l-1.9,1.9C452.5,330.7,452.5,331.8,453.2,332.5z"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                x1="439.7"
                y1="332.4"
                x2="453.2"
                y2="332.4"
              />
            </g>
            <rect
              x="328.8"
              y="349.6"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="60.1"
              height="44.1"
            />
            <rect
              x="294.4"
              y="331.2"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.7"
              height="8.6"
            />
            <rect
              x="422"
              y="331.2"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.7"
              height="8.6"
            />
          </g>
        </g>
      </>
    )
  }
};
