import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_CUSTOMER = gql`
  mutation updateBusinessCustomer(
    $businessCustomerId: ID!
    $businessCustomer: BusinessCustomerUpdateInput!
  ) {
    updateBusinessCustomer(
      businessCustomerId: $businessCustomerId
      businessCustomer: $businessCustomer
    ) {
      id
        businessName
        registrationNumber
        VAT
        registeredAddress{
          street
          city
          fullAddress
          state
          zipcode
          country
        }
        tradingAddressSame
        tradingAddress{
          street
          city
          fullAddress
          state
          zipcode
          country
        }
        approvedDrivers{
          id
          firstName
          lastName
          email
          phoneNumber{
            phone
            country
          }
          nationalInsuranceNumber
          profession
          license{
            licenseNumber
            dateOfExpiry
            authority
            endorsement
            images
            offences
            points
            dvlaVerificationStatus
          }
        }
        billing{
          phoneNumber{
            phone
            country
          }
          email
          accountNumber
        }
        contact{
          firstName
          lastName
          email
          phoneNumber{
            phone
            country
          }
        }
        overrideNetPaymentTerms
        netPaymentTerms
        overrideInvoiceDate
        invoicingDay
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $customerId: String!
    $customer: CustomerUpdateInput!
  ) {
    updateCustomer(customerId: $customerId, customer: $customer) {
      id
      firstName
      lastName
      dateOfBirth
      gender
      phoneNumber {
        country
        phone
      }
      profilePicture
      profession
      nationalInsuranceNumber
      email
      license {
        licenseNumber
        dateOfExpiry
        authority
        images
      }
      location {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      documents {
        id
        documentKey
        documentName
        documentType
        expiryDate
        title
        createdAt
      }
    }
  }
`;

export const ADD_DRIVER_MUTATION = gql`
  mutation addBusinessCustomerDriver(
    $businessCustomerId: ID!
    $driver: BusinessCustomerApprovedDriverInput!
  ) {
    addBusinessCustomerDriver(
      businessCustomerId: $businessCustomerId
      driver: $driver
    ) {
        id
          firstName
          lastName
          email
          phoneNumber{
            phone
            country
          }
          nationalInsuranceNumber
          profession
          license{
            licenseNumber
            dateOfExpiry
            authority
            endorsement
            images
            offences
            points
            dvlaVerificationStatus
          }
          location{
            city
            country
            state
            fullAddress
            zipcode
            street
          }
    }
  }
`;

export const DELETE_BUSINESS_CUSTOMER_DRIVER = gql`
  mutation deleteBusinessCustomerDriver(
    $businessCustomerId: ID!
    $driverId: ID!
  ) {
    deleteBusinessCustomerDriver(
      businessCustomerId: $businessCustomerId
      driverId: $driverId
    ) {
      id
      businessName
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
    }
  }
`;