import { gql } from "apollo-boost";

// The data returned should be same as GET_BOOKING query
export const CREATE_BOOKING = gql`
  mutation consumerCreateBooking($branchId:String! , $booking: ConsumerCreateBookingInput!) {
    consumerCreateBooking(branchId: $branchId , booking: $booking) {
      id
      dateStarted
      dateReturned
      organisationId
      branchId
      taxExempted
      isRecurringBilling
      branch {
        id
        timeZone
      }
      firstInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
          rate
          type
          subdivisionRate
          subdivisionType
          subdivisionValue
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        approvedDrivers {
          id
          status
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        authorizedSignatories {
          id
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
        contact {
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        documents {
          id
          name
          title
          documentType
          expiryDate
          url
        }
      }
      currentInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      finalInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      referenceNumber
      dateCreated
      dateUpdated
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      pickupDateTime
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      dropoffDateTime
      longTermBooking
      flightNumber
      insuranceRate
      excess
      customerType
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      tax {
        appliedBy
        appliedDate
        code
        amount
        payableAmount
        rate
        type
        subdivisionOptIn
        subdivisionRate
        subdivisionType
        subdivisionValue
      }
      invoices {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
      }
      rateTypeName
      rateTypeDuration
      billingCycleName
      billingCycleDuration
      status
      addonRequirements {
        addon {
          id
          name
          category
          imageUrl
          hasFixedRate
          hasQuantity
          fixedRate
          description
          recurringRate {
            rateTypeName
            rateTypeDuration
            rate
            longTerm
          }
          totalQuantity
          availableQuantity
          isActivated
        }
        rate
        quantity
        name
        hasFixedRate
        payableAmount
        displayName
        tax {
            title
            value
        }
      }
      customer {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        dateOfBirth
        location {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        license {
          dateOfExpiry
          licenseNumber
          authority
        }
      }
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicle {
          id
          licencePlate
        }
      }
      authorizedSignatory
      insurancePolicy {
        id
        name
        description
      }
      paymentDetails {
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
      }
      depositDetails {
        amount
        amountAdjusted
        payment {
          id
          status
        }
        adjustedAgainst {
          id
          invoiceRef
        }
        refund {
          amount
          refundRef
          status
          mode
          currency
          id
          paymentIntent
          paymentId
        }
      }
      vehicleGroups {
        activeVehicleSchedules {
          vehicle {
            isGhostVehicle
            id
            licencePlate
            make
            model
            features
          }
        }
        vehicleGroup {
          name
        }
        vehicleIds
        baseRate
        deposit
        mileageLimit
        pricePerExtraMile
        priceRuleOperator
        priceRuleAmount
        priceRuleName
        priceRuleValueType
        priceRuleTriggerType
        priceRule
        count
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber{
          phone
          country
        }
        email
      }
      quoteUrl
      locationSurcharges{
        id
        name
        value
        rate
        type
        tax {
          title
          value
        }
      }
      oneWayRentalFee{
        amount
        taxable
        tax{
          title
          type
          value
        }
      }
      isCoi
    }
  }
`;
