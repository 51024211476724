import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useRef, useState } from "react";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import Slider, { Settings } from "react-slick";

import { IAppState } from '../../store';
import Image from './Image'
import { Storage } from "aws-amplify";
import { Skeleton } from '@material-ui/lab';


const CarouselModule: React.FC = () => {

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const [images, setImages] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  let settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    swipeToSlide: true
  };

  useEffect(() => {
    if (website.carousel.length > 0) {
      getCarouselImages(website.carousel)
    }
  }, website.carousel)

  const getCarouselImages = async (images: string[]) => {
    const config = { contentType: "image/*", level: "public" };
    const imageUrls = await Promise.all(images.map(async (image: string) => {
      const url = await Storage.get(image, config).then((result) => {
        setLoading(true)
        return result;
      })
      return url
    }))
    if (imageUrls.length > 0) {
      setLoading(false)
      setImages(imageUrls as string[]);
    }
  }

  return (
    <>
      <Container>
        <div className={"carauselContainer"}>
          {!loading ? (
            <Slider className={"slickySlider"} {...settings} >
            {
              images.map((image, index) => <Image key={index} imageKey={image} />)
            }
          </Slider>
          ) : <Skeleton variant="rect" height={400} />
        }
        </div>
      </Container>
    </>
  )
}

export default CarouselModule