import React from "react";

export const TipperVanSVG = {
  interior: {
    width: 155,
    height: 353,
    svg: (
      <>
        <g>
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M75.9,10.7l27.7,2.3c0,0,26.6,3.5,31.2,31.2v153.6H75.9"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M77.1,10.7L49.4,13c0,0-26.6,3.5-31.2,31.2v153.6h58.9"
            />
          </g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M42.4,21.1c0,0,2.3,1.2,0,3.5c0,0-11.6,6.3-16.7,15
		c-0.1,0.3-0.5,0.1-0.5-0.2C25.8,34.7,28.8,20.1,42.4,21.1z"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M110.6,21.1c0,0-2.3,1.2,0,3.5c0,0,11.6,6.3,16.7,15
		c0.1,0.3,0.5,0.1,0.5-0.2C127.2,34.7,124.2,20.1,110.6,21.1z"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            d="M101.4,18.8H51.6c-0.6,0-1.1-0.5-1.1-1.1v-1.4
		c0-0.6,0.5-1.1,1.1-1.1h49.9c0.6,0,1.1,0.5,1.1,1.1v1.4C102.5,18.3,102,18.8,101.4,18.8z"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            d="M69,45.7v-6.6c0-0.4-0.3-0.8-0.8-0.8H55.1
		c0,0-6.3,0-9.3,1.6c-0.7,0.3-1.1,1-1.1,1.8l0,4.1c0,0.3,0.3,0.6,0.6,0.6h22.9C68.6,46.5,69,46.1,69,45.7z"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            d="M84,45.7v-6.6c0-0.4,0.3-0.8,0.8-0.8h13.1
		c0,0,6.3,0,9.3,1.6c0.7,0.3,1.1,1,1.1,1.8l0,4.1c0,0.3-0.3,0.6-0.6,0.6H84.8C84.3,46.5,84,46.1,84,45.7z"
          />
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M75.9,49.9h18.5c0,0,6.9,0,15.1,1.3
			c5.3,0.8,10.2,3.4,13.7,7.4c1.5,1.8,2.8,3.9,3.5,6.3v37c0,0,0,2.3-2.3,2.3c0,0-19.6-2.3-26.6-2.3H74.8"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M78.2,49.9H59.7c0,0-6.9,0-15.1,1.3
			c-5.3,0.8-10.2,3.4-13.7,7.4c-1.5,1.8-2.8,3.9-3.5,6.3v37c0,0,0,2.3,2.3,2.3c0,0,19.6-2.3,26.6-2.3h23.1"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M22.8,193.7l0-148.3c0,0,0-5.3,2.9-6.4"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="22.8"
              y1="193.1"
              x2="18.2"
              y2="197.7"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M129.6,193.7l0-148.3c0,0,0-5.3-2.9-6.4"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="129.6"
              y1="193.1"
              x2="134.2"
              y2="197.7"
            />
          </g>
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            x1="22.8"
            y1="193.1"
            x2="129.6"
            y2="193.1"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            d="M21.4,83.7L6.1,85.4
		c-0.8,0.1-1.6-0.5-1.7-1.3l-0.1-0.5c-0.1-0.8,0.5-1.6,1.3-1.7l15.4-1.8c0.8-0.1,1.6,0.5,1.7,1.3l0.1,0.5
		C22.9,82.8,22.3,83.6,21.4,83.7z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            d="M135,83.7l15.4,1.8c0.8,0.1,1.6-0.5,1.7-1.3
		l0.1-0.5c0.1-0.8-0.5-1.6-1.3-1.7l-15.4-1.8c-0.8-0.1-1.6,0.5-1.7,1.3l-0.1,0.5C133.6,82.8,134.2,83.6,135,83.7z"
          />

          <rect
            x="18.2"
            y="202.4"
            fill="#9E9E9E"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="116.6"
            height="3.5"
          />
          <rect
            x="47"
            y="197.7"
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="8.1"
            height="4.6"
          />
          <rect
            x="58.6"
            y="197.7"
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="11.5"
            height="4.6"
          />

          <rect
            x="95.5"
            y="197.7"
            transform="matrix(-1 -4.455737e-11 4.455737e-11 -1 199.1636 400.1032)"
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="8.1"
            height="4.6"
          />

          <rect
            x="80.5"
            y="197.7"
            transform="matrix(-1 -4.473722e-11 4.473722e-11 -1 172.6048 400.1032)"
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="11.5"
            height="4.6"
          />
          <rect
            x="20.5"
            y="205.8"
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="112"
            height="138.6"
          />
          <rect
            x="22.8"
            y="208.1"
            fill="none"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="107.4"
            height="133.9"
          />
          <rect
            x="18.2"
            y="205.8"
            fill="#9E9E9E"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="2.3"
            height="4.6"
          />
          <rect
            x="132.5"
            y="205.8"
            fill="#9E9E9E"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="2.3"
            height="4.6"
          />
          <rect
            x="18.2"
            y="342.1"
            fill="#9E9E9E"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="2.3"
            height="4.6"
          />
          <rect
            x="132.5"
            y="342.1"
            fill="#9E9E9E"
            stroke="#000000"
            stroke-width="0.75"
            stroke-miterlimit="10"
            width="2.3"
            height="4.6"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M121.2,111.9h-37c-2.1,0-3.8-1.7-3.8-3.8V89.3
		c0-2.1,1.7-3.8,3.8-3.8h37c2.1,0,3.8,1.7,3.8,3.8v18.8C125,110.2,123.3,111.9,121.2,111.9z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M123.5,129H82.6c-1.7,0-3.1-1.5-3.1-3.4l0.8-12.2
		c0-1.9,1.4-3.4,3.1-3.4h38.5c1.7,0,3.1,1.5,3.1,3.4l1.6,12.2C126.5,127.5,125.2,129,123.5,129z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M69.8,111.9h-37c-2.1,0-3.8-1.7-3.8-3.8V89.3
		c0-2.1,1.7-3.8,3.8-3.8h37c2.1,0,3.8,1.7,3.8,3.8v18.8C73.6,110.2,71.9,111.9,69.8,111.9z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M72.1,129H31.3c-1.7,0-3.1-1.5-3.1-3.4l0.8-12.2
		c0-1.9,1.4-3.4,3.1-3.4h38.5c1.7,0,3.1,1.5,3.1,3.4l1.6,12.2C75.2,127.5,73.8,129,72.1,129z"
          />
        </g>
      </>
    )
  },

  exterior: {
    width: 433,
    height: 662,
    svg: (
      <>
        <g id="Layer_1">
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M22.9,337.1h108.9v-76.1c0,0,1.2-10.4-11.5-25.4l-34.6-39.2
        l-13.8-36.9c0,0-1.2-3.5-8.1-3.5H44.2c0,0-1.2-3.5-3.5-3.5h-9.2c-5.6,0-10.3,4.5-10.4,10.1c0,0.1,0,0.2,0,0.2v24.8"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M21.2,187.3c14.3,0,28.8,9.2,28.8,25.9c0,15.6-13.3,24.8-27.7,24.8"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="22.3"
              y1="237.4"
              x2="22.3"
              y2="337.7"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M121.5,278.3v-13.8c0,0,0-9.2-4.6-15l-42.7-48.4c0,0-1.2,0-1.2,2.3
        l5.8,49.6c0,0,1.4,9.3,7.1,17.8c2.7,4,7.3,6.5,12.2,6.7L121.5,278.3z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="22.9"
              y1="288.7"
              x2="131.3"
              y2="288.7"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M85.7,328.2v-30.6c0-0.5,0.4-0.8,0.8-0.8h34.1
        c0.5,0,0.8,0.4,0.8,0.8v30.6c0,0.5-0.4,0.8-0.8,0.8H86.6C86.1,329.1,85.7,328.7,85.7,328.2z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M73,282v-11.8c0-0.5,0.5-1,1-1h0.3c0.6,0,1,0.5,1,1V282
        c0,0.5-0.4,1-1,1H74C73.5,283,73,282.5,73,282z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M74.2,326.9v-11.8c0-0.5,0.4-1,1-1h0.3c0.6,0,1,0.4,1,1v11.8
        c0,0.6-0.4,1-1,1h-0.3C74.6,327.9,74.2,327.5,74.2,326.9z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="48.8"
              y1="337.7"
              x2="48.8"
              y2="221.9"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="48.8"
              y1="205.1"
              x2="48.8"
              y2="156.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="36.2"
              y1="337.7"
              x2="36.2"
              y2="235.1"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M75.2,191.1l-11.4-6.7c-0.7-0.4-1.2-1.2-1.2-2v-17.5
        c0-0.5,0.3-0.9,0.8-1.1c2.3-0.8,11.9,15.4,12.9,26.6C76.4,191,75.7,191.4,75.2,191.1z"
            />

            <rect
              x="0.4"
              y="359"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 387.1236 336.7202)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="49.6"
              height="5.8"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="40.8,478.9 40.8,341.7 48.8,341.7 48.8,481.2 		"
            />

            <rect
              x="32.7"
              y="366"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 406.7211 337.873)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.5"
              height="12.7"
            />

            <rect
              x="32.7"
              y="377.5"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 418.2491 349.401)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.5"
              height="12.7"
            />

            <rect
              x="-7.1"
              y="421.9"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 453.4092 392.6307)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="74.9"
              height="2.3"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="33.8"
              y1="477.8"
              x2="33.8"
              y2="385.6"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="40.2"
              y1="477.8"
              x2="33.3"
              y2="477.8"
            />

            <rect
              x="32.1"
              y="458.8"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 492.6042 426.0617)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="2.3"
              height="1.2"
            />

            <rect
              x="32.1"
              y="444.9"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 478.7707 412.2282)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="2.3"
              height="1.2"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="25.8,386.7 22.3,444.3 20,444.3 22.3,386.7 		"
            />

            <rect
              x="93.8"
              y="296.8"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 438.9993 247.9552)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.5"
              height="93.4"
            />

            <rect
              x="-8.2"
              y="402.3"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 478.7707 353.4357)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="141.8"
              height="27.7"
            />

            <rect
              x="-4.8"
              y="405.7"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 478.7707 353.4357)"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="134.9"
              height="20.8"
            />
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="25.2"
                cy="212.1"
                r="21.3"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="25.2"
                cy="212.1"
                r="11"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="24"
                cy="420.7"
                r="21.3"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="24"
                cy="420.7"
                r="11"
              />
            </g>
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M75.9,218.6L75.9,218.6
        c0-0.8,2.7-3.5,4.2-4.8c0.5-0.5,1.3-0.5,1.9-0.1c1.8,1.3,5.5,4,5.5,4.9l0,0c0,1.1-1.1,2.1-2.5,2.1h-6.5
        C77.1,220.7,75.9,219.8,75.9,218.6z"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M406,337.1H297.1v-76.1c0,0-1.2-10.4,11.5-25.4l34.6-39.2l13.8-36.9
        c0,0,1.2-3.5,8.1-3.5h19.6c0,0,1.2-3.5,3.5-3.5h9.2c5.6,0,10.3,4.5,10.4,10.1c0,0.1,0,0.2,0,0.2v24.8"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M407.8,187.3c-14.3,0-28.8,9.2-28.8,25.9
        c0,15.6,13.3,24.8,27.7,24.8"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="406.6"
              y1="237.4"
              x2="406.6"
              y2="337.7"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M307.5,278.3v-13.8c0,0,0-9.2,4.6-15l42.7-48.4c0,0,1.2,0,1.2,2.3
        l-5.8,49.6c0,0-1.4,9.3-7.1,17.8c-2.7,4-7.3,6.5-12.2,6.7L307.5,278.3z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="406"
              y1="288.7"
              x2="297.7"
              y2="288.7"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M343.2,328.2v-30.6c0-0.5-0.4-0.8-0.8-0.8h-34.1
        c-0.5,0-0.8,0.4-0.8,0.8v30.6c0,0.5,0.4,0.8,0.8,0.8h34.1C342.8,329.1,343.2,328.7,343.2,328.2z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M355.9,282v-11.8c0-0.5-0.5-1-1-1h-0.3c-0.5,0-1,0.5-1,1V282
        c0,0.5,0.5,1,1,1h0.3C355.4,283,355.9,282.5,355.9,282z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M354.7,326.9v-11.8c0-0.5-0.5-1-1-1h-0.3c-0.5,0-1,0.4-1,1v11.8
        c0,0.6,0.5,1,1,1h0.3C354.3,327.9,354.7,327.5,354.7,326.9z"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="380.1"
              y1="337.7"
              x2="380.1"
              y2="221.9"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="380.1"
              y1="205.1"
              x2="380.1"
              y2="156.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="392.8"
              y1="337.7"
              x2="392.8"
              y2="235.1"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M353.7,191.1l11.4-6.7c0.7-0.4,1.2-1.2,1.2-2v-17.5
        c0-0.5-0.3-0.9-0.8-1.1c-2.3-0.8-11.9,15.4-12.9,26.6C352.6,191,353.2,191.4,353.7,191.1z"
            />

            <rect
              x="378.9"
              y="359"
              transform="matrix(-4.487806e-11 1 -1 -4.487806e-11 765.6526 -41.8088)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="49.6"
              height="5.8"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="388.2,478.9 388.2,341.7 380.1,341.7 380.1,481.2 		"
            />

            <rect
              x="392.8"
              y="366"
              transform="matrix(-4.418173e-11 1 -1 -4.418173e-11 766.8055 -22.2113)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.5"
              height="12.7"
            />

            <rect
              x="392.8"
              y="377.5"
              transform="matrix(-4.470753e-11 1 -1 -4.470753e-11 778.3334 -10.6834)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.5"
              height="12.7"
            />

            <rect
              x="361.1"
              y="421.9"
              transform="matrix(-4.488517e-11 1 -1 -4.488517e-11 821.5632 24.4768)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="74.9"
              height="2.3"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="395.1"
              y1="477.8"
              x2="395.1"
              y2="385.6"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              x1="388.7"
              y1="477.8"
              x2="395.7"
              y2="477.8"
            />

            <rect
              x="394.5"
              y="458.8"
              transform="matrix(-4.418173e-11 1 -1 -4.418173e-11 854.9941 63.6718)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="2.3"
              height="1.2"
            />

            <rect
              x="394.5"
              y="444.9"
              transform="matrix(-4.497043e-11 1 -1 -4.497043e-11 841.1606 49.8383)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="2.3"
              height="1.2"
            />
            <polygon
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              points="403.2,386.7 406.6,444.3 408.9,444.3 406.6,386.7 		"
            />

            <rect
              x="331.7"
              y="296.8"
              transform="matrix(-4.470753e-11 1 -1 -4.470753e-11 676.8876 10.0669)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="3.5"
              height="93.4"
            />

            <rect
              x="295.4"
              y="402.3"
              transform="matrix(-4.489938e-11 1 -1 -4.489938e-11 782.3682 49.8383)"
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              width="141.8"
              height="27.7"
            />

            <rect
              x="298.8"
              y="405.7"
              transform="matrix(-4.491004e-11 1 -1 -4.491004e-11 782.3682 49.8383)"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="134.9"
              height="20.8"
            />
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="403.7"
                cy="212.1"
                r="21.3"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="403.7"
                cy="212.1"
                r="11"
              />
            </g>
            <g>
              <circle
                fill="#FFFFFF"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="404.9"
                cy="420.7"
                r="21.3"
              />
              <circle
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="404.9"
                cy="420.7"
                r="11"
              />
            </g>
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M353,218.6L353,218.6c0-0.8-2.7-3.5-4.2-4.8
        c-0.5-0.5-1.3-0.5-1.9-0.1c-1.8,1.3-5.5,4-5.5,4.9l0,0c0,1.1,1.1,2.1,2.5,2.1h6.5C351.9,220.7,353,219.8,353,218.6z"
            />
          </g>
        </g>
        <g id="Layer_2">
          <g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M212.8,152.2l27.7,2.3c0,0,26.6,3.5,31.2,31.2v153.6h-58.9"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                d="M214,152.2l-27.7,2.3c0,0-26.6,3.5-31.2,31.2v153.6H214"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M179.3,162.6c0,0,2.3,1.2,0,3.5c0,0-11.6,6.3-16.7,15
        c-0.1,0.3-0.5,0.1-0.5-0.2C162.7,176.3,165.7,161.6,179.3,162.6z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-miterlimit="10"
              d="M247.5,162.6c0,0-2.3,1.2,0,3.5c0,0,11.6,6.3,16.7,15
        c0.1,0.3,0.5,0.1,0.5-0.2C264,176.3,261.1,161.6,247.5,162.6z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M238.3,160.3h-49.9c-0.6,0-1.1-0.5-1.1-1.1
        v-1.4c0-0.6,0.5-1.1,1.1-1.1h49.9c0.6,0,1.1,0.5,1.1,1.1v1.4C239.4,159.8,238.9,160.3,238.3,160.3z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M205.9,187.2v-6.6c0-0.4-0.3-0.8-0.8-0.8H192
        c0,0-6.3,0-9.3,1.6c-0.7,0.3-1.1,1-1.1,1.8v4.1c0,0.3,0.3,0.6,0.6,0.6h22.9C205.5,188,205.9,187.6,205.9,187.2z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M220.9,187.2v-6.6c0-0.4,0.3-0.8,0.8-0.8h13.1
        c0,0,6.3,0,9.3,1.6c0.7,0.3,1.1,1,1.1,1.8l0,4.1c0,0.3-0.3,0.6-0.6,0.6h-22.9C221.2,188,220.9,187.6,220.9,187.2z"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M212.8,191.4h18.5c0,0,6.9,0,15.1,1.3
          c5.3,0.8,10.2,3.4,13.7,7.4c1.5,1.8,2.8,3.9,3.5,6.3v37c0,0,0,2.3-2.3,2.3c0,0-19.6-2.3-26.6-2.3h-23.1"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M215.1,191.4h-18.5c0,0-6.9,0-15.1,1.3
          c-5.3,0.8-10.2,3.4-13.7,7.4c-1.5,1.8-2.8,3.9-3.5,6.3v37c0,0,0,2.3,2.3,2.3c0,0,19.6-2.3,26.6-2.3h23.1"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M159.7,335.2l0-148.3c0,0,0-5.3,2.9-6.4"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                x1="159.7"
                y1="334.6"
                x2="155.1"
                y2="339.3"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M266.5,335.2l0-148.3c0,0,0-5.3-2.9-6.4"
              />
              <line
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                x1="266.5"
                y1="334.6"
                x2="271.1"
                y2="339.3"
              />
            </g>
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="159.7"
              y1="334.6"
              x2="266.5"
              y2="334.6"
            />
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M158.3,225.2L143,227
        c-0.8,0.1-1.6-0.5-1.7-1.3l-0.1-0.5c-0.1-0.8,0.5-1.6,1.3-1.7l15.4-1.8c0.8-0.1,1.6,0.5,1.7,1.3l0.1,0.5
        C159.8,224.4,159.2,225.1,158.3,225.2z"
            />
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M271.9,225.2l15.4,1.8
        c0.8,0.1,1.6-0.5,1.7-1.3l0.1-0.5c0.1-0.8-0.5-1.6-1.3-1.7l-15.4-1.8c-0.8-0.1-1.6,0.5-1.7,1.3l-0.1,0.5
        C270.5,224.4,271.1,225.1,271.9,225.2z"
            />

            <rect
              x="155.1"
              y="343.9"
              fill="#9E9E9E"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="116.6"
              height="3.5"
            />
            <rect
              x="183.9"
              y="339.3"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="8.1"
              height="4.6"
            />
            <rect
              x="195.5"
              y="339.3"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="11.5"
              height="4.6"
            />

            <rect
              x="232.4"
              y="339.3"
              transform="matrix(-1 -4.478208e-11 4.478208e-11 -1 472.9572 683.1296)"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="8.1"
              height="4.6"
            />

            <rect
              x="217.4"
              y="339.3"
              transform="matrix(-1 -4.489488e-11 4.489488e-11 -1 446.3984 683.1296)"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="11.5"
              height="4.6"
            />
            <rect
              x="157.4"
              y="347.3"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="112"
              height="138.6"
            />

            <rect
              x="159.7"
              y="349.6"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="107.4"
              height="133.9"
            />

            <rect
              x="155.1"
              y="347.3"
              fill="#9E9E9E"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.3"
              height="4.6"
            />

            <rect
              x="269.4"
              y="347.3"
              fill="#9E9E9E"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.3"
              height="4.6"
            />

            <rect
              x="155.1"
              y="483.6"
              fill="#9E9E9E"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.3"
              height="4.6"
            />

            <rect
              x="269.4"
              y="483.6"
              fill="#9E9E9E"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.3"
              height="4.6"
            />
          </g>
        </g>
        <g id="Layer_3"></g>
        <g id="Layer_4">
          <g>
            <polyline
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              points="162.4,24.4 162.4,15.6 262.4,15.6 
        262.4,25 		"
            />
            <rect
              x="163.5"
              y="10.1"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="1.1"
              height="5.5"
            />
            <rect
              x="169"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="181"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="192.9"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="204.8"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="216.7"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="228.6"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="240.6"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="252.5"
              y="17.8"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="3.3"
            />
            <rect
              x="260.2"
              y="10.1"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="1.1"
              height="5.5"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M159.1,118.8v22c0,0,0,2.2,2.2,2.2h7.7
        c0,0,2.2,0,2.2-2.2V130"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M265.7,118.8v22c0,0,0,2.2-2.2,2.2h-7.7
        c0,0-2.2,0-2.2-2.2V130"
            />
            <rect
              x="188.8"
              y="124.3"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="7.7"
            />
            <rect
              x="233.8"
              y="124.3"
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="2.2"
              height="7.7"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M206.9,21.1h47.8c0,0,0.6,0,1.4,0.1
          c4.2,0.3,7.4,3.8,7.5,8l1,52.2c0,1.3,0.6,2.6,1.6,3.4c1.3,1.1,2.8,2.9,2.8,5.5c0,0,0,7.1-4,10.2c-0.7,0.6-1.7,0.8-2.6,0.8
          l-16.6,0c-1,0-2,0.3-2.8,1c-0.4,0.3-0.9,0.6-1.7,0.9c-0.5,0.2-1.1,0.3-1.7,0.3H213"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M217.4,21.1h-47.8c0,0-0.6,0-1.4,0.1
          c-4.2,0.3-7.4,3.8-7.5,8l-1,52.2c0,1.3-0.6,2.6-1.6,3.4c-1.3,1.1-2.8,2.9-2.8,5.5c0,0,0,7.1,4,10.2c0.7,0.6,1.7,0.8,2.6,0.8
          l16.6,0c1,0,2,0.3,2.8,1c0.4,0.3,0.9,0.6,1.7,0.9c0.5,0.2,1.1,0.3,1.7,0.3h28.9"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M211.9,27.7h29.6h12.1c0,0,3.6,0,5.1,2.5
          c0.2,0.4,0.4,0.9,0.4,1.4V58c0,0.7-0.1,1.5-0.5,2.1c-0.2,0.4-0.4,0.8-0.7,1.2c-1.3,2-3.6,3.1-6,3.1h-40.1"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M213,27.7h-29.6h-12.1c0,0-3.6,0-5.1,2.5
          c-0.2,0.4-0.4,0.9-0.4,1.4V58c0,0.7,0.1,1.5,0.5,2.1c0.2,0.4,0.4,0.8,0.7,1.2c1.3,2,3.6,3.1,6,3.1H213"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M181.3,67.2h24.6c0.3,0,0.5,0.2,0.5,0.5v2.8
        h-23.1c0,0-2.1,0-2.2-3.1C181.1,67.3,181.2,67.2,181.3,67.2z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M244.7,67.2H220c-0.3,0-0.5,0.2-0.5,0.5v2.8
        h23.1c0,0,2.1,0,2.2-3.1C244.8,67.3,244.7,67.2,244.7,67.2z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M162.8,76.1l16.4,4.1c0.5,0.1,0.8,0.6,0.8,1.1
        l0,5.7c0,0.8-0.5,1.6-1.3,1.9c-1,0.4-1.8,0.4-4.5,0.3c-1.3,0-2.6-0.2-3.8-0.5l-5.8-1.5c-0.6-0.2-1.1-0.7-1.1-1.3l-1-9.6
        C162.5,76.2,162.6,76.1,162.8,76.1z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M262.1,76.1l-16.4,4.1
        c-0.5,0.1-0.8,0.6-0.8,1.1l0,5.7c0,0.8,0.5,1.6,1.3,1.9c1,0.4,1.8,0.4,4.5,0.3c1.3,0,2.6-0.2,3.8-0.5l5.8-1.5
        c0.6-0.2,1.1-0.7,1.1-1.3l1-9.6C262.4,76.2,262.2,76.1,262.1,76.1z"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M211.9,81.5h23.1c0,0,2.2,0,3.3,3.3l2.9,10.5
          c0.5,1.9-0.8,3.7-2.8,3.8c0,0-0.1,0-0.1,0h-26.4"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M212.4,81.5h-23.1c0,0-2.2,0-3.3,3.3
          l-2.9,10.5c-0.5,1.9,0.8,3.7,2.8,3.8c0,0,0.1,0,0.1,0h26.4"
              />
            </g>
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="186.1"
              y1="84.8"
              x2="238.2"
              y2="84.8"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="185"
              y1="88.1"
              x2="239.3"
              y2="88.1"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="183.9"
              y1="91.4"
              x2="240.4"
              y2="91.4"
            />
            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="183.3"
              y1="94.7"
              x2="241.5"
              y2="94.7"
            />
            <g>
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M155.9,94.7c0,0,1.1,15.4,3.3,24.2
          c0,0,2.2,9.9,8.8,11h7.9c0.5,0,0.9-0.4,0.9-0.9v-4.6H205c0.2,0,0.3,0.1,0.3,0.3c-0.1,1.3,0,6.3,7.7,6.3"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M269,94.7c0,0-1.1,15.4-3.3,24.2
          c0,0-2.2,9.9-8.8,11H249c-0.5,0-0.9-0.4-0.9-0.9v-4.6H219c0,0,1.6,6.6-7.1,6.6"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M232.4,119.9h-38.9c-1.4,0-2.5-1.1-2.5-2.5
        v-0.5c0-1.4,1.1-2.5,2.5-2.5h38.9c1.4,0,2.5,1.1,2.5,2.5v0.5C234.9,118.8,233.8,119.9,232.4,119.9z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M168.3,115.5h-0.8c-2.2,0-4-1.8-4-4v-0.8
        c0-2.2,1.8-4,4-4h0.8c2.2,0,4,1.8,4,4v0.8C172.3,113.7,170.5,115.5,168.3,115.5z"
            />
            <path
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M257.3,116.6h-0.8c-2.2,0-4-1.8-4-4v-0.8
        c0-2.2,1.8-4,4-4h0.8c2.2,0,4,1.8,4,4v0.8C261.3,114.8,259.5,116.6,257.3,116.6z"
            />
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M159.7,66.1h-3.2v-7.5
        c0-1.2-0.5-2.4-1.3-3.2c-0.8-0.7-2.1-1.4-3.9-1.4c0,0-2,0-3.6,0.7c-1,0.5-1.7,1.5-1.7,2.6l0,13.1c0,0,0,2.2,2.1,2.2h6
        c0.2,0,0.5-0.1,0.7-0.2c0.4-0.3,1.2-0.8,1.5-1.3c0.3-0.4,0.7-0.7,1.2-0.7h0.8c0.8,0,1.4-0.6,1.4-1.4V66.1z"
            />
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M264.6,66.1h3.2v-7.5c0-1.2,0.5-2.4,1.3-3.2
        c0.8-0.7,2.1-1.4,3.9-1.4c0,0,2,0,3.6,0.7c1,0.5,1.7,1.5,1.7,2.6l0,13.1c0,0,0,2.2-2.1,2.2h-6c-0.2,0-0.5-0.1-0.7-0.2
        c-0.4-0.3-1.2-0.8-1.5-1.3c-0.3-0.4-0.7-0.7-1.2-0.7H266c-0.8,0-1.4-0.6-1.4-1.4V66.1z"
            />

            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-linecap="round"
              stroke-miterlimit="10"
              x1="208.6"
              y1="63.9"
              x2="183.3"
              y2="58.4"
            />

            <line
              fill="none"
              stroke="#000000"
              stroke-width="0.75"
              stroke-linecap="round"
              stroke-miterlimit="10"
              x1="231.6"
              y1="63.9"
              x2="206.4"
              y2="58.4"
            />
          </g>
        </g>
        <g id="Layer_5">
          <g>
            <g>
              <polygon
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="273.6,574.3 
          157,574.3 158.3,511.7 272.3,511.7 			"
              />
              <polygon
                fill="#EAEAEA"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="
          271.1,572.4 160.1,572.4 161.3,514.2 269.9,514.2 			"
              />

              <rect
                x="159.5"
                y="504.4"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="1.2"
                height="7.4"
              />

              <rect
                x="269.9"
                y="504.4"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="1.2"
                height="7.4"
              />

              <rect
                x="153.4"
                y="574.3"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="122.6"
                height="23.3"
              />

              <rect
                x="155.8"
                y="576.7"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="117.7"
                height="18.4"
              />

              <rect
                x="152.1"
                y="597.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="125.1"
                height="6.1"
              />

              <rect
                x="155.8"
                y="580.4"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="2.5"
                height="6.1"
              />

              <rect
                x="271"
                y="580.1"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="2.5"
                height="6.1"
              />
              <polygon
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="274.8,620.3 
          155.8,620.3 154.6,603.7 276,603.7 			"
              />

              <rect
                x="256.4"
                y="620.9"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="18.4"
                height="18.4"
              />

              <rect
                x="155.8"
                y="620.9"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="19.6"
                height="18.4"
              />

              <ellipse
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                cx="216.5"
                cy="633.2"
                rx="8.9"
                ry="7.4"
              />

              <rect
                x="175.4"
                y="630.7"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="31.9"
                height="3.7"
              />

              <rect
                x="225.7"
                y="630.7"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="30.7"
                height="3.7"
              />

              <rect
                x="188.9"
                y="634.4"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="2.5"
                height="8.6"
              />

              <rect
                x="239.2"
                y="634.4"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-linecap="round"
                stroke-miterlimit="10"
                width="2.5"
                height="8.6"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M155.8,619v32.5c0,0,0,2.5,3.7,2.5h7.4
          c0,0,2.5,0,2.5-2.5v-12.3"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M274.8,618.4v33.1c0,0,0,2.5-3.7,2.5h-7.4
          c0,0-2.5,0-2.5-2.5v-12.3"
              />
              <rect
                x="162"
                y="608.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="14.7"
                height="4.9"
              />
              <rect
                x="253.9"
                y="608.6"
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="14.7"
                height="4.9"
              />
              <path
                fill="none"
                stroke="#000000"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M229.8,617.2h-28.9c-0.5,0-0.9-0.4-0.9-0.9
          v-8.1c0-0.5,0.4-0.9,0.9-0.9h28.9c0.5,0,0.9,0.4,0.9,0.9v8.1C230.6,616.8,230.2,617.2,229.8,617.2z"
              />
            </g>
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M157.3,560.3h-3.2v-7.5
        c0-1.2-0.5-2.4-1.3-3.2c-0.8-0.7-2.1-1.4-3.9-1.4c0,0-2,0-3.6,0.7c-1,0.5-1.7,1.5-1.7,2.6l0,13.1c0,0,0,2.2,2.1,2.2h6
        c0.2,0,0.5-0.1,0.7-0.2c0.4-0.3,1.2-0.8,1.5-1.3c0.3-0.4,0.7-0.7,1.2-0.7h0.8c0.8,0,1.4-0.6,1.4-1.4V560.3z"
            />
            <path
              fill="#FFFFFF"
              stroke="#000000"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M273.1,560.3h3.2v-7.5
        c0-1.2,0.5-2.4,1.3-3.2c0.8-0.7,2.1-1.4,3.9-1.4c0,0,2,0,3.6,0.7c1,0.5,1.7,1.5,1.7,2.6l0,13.1c0,0,0,2.2-2.1,2.2h-6
        c-0.2,0-0.5-0.1-0.7-0.2c-0.4-0.3-1.2-0.8-1.5-1.3c-0.3-0.4-0.7-0.7-1.2-0.7h-0.8c-0.8,0-1.4-0.6-1.4-1.4V560.3z"
            />
          </g>
        </g>
      </>
    )
  }
};
