import { gql } from "apollo-boost";

export const GET_VEHICLE_GROUPS_TRACKING = gql`
  query vehicleGroups {
    vehicleGroups {
      id
      name
      vehicles {
        id
        licencePlate
        telemetryDeviceId
        telematicCoreData {
          latitude
          longitude
          address {
            street
            city
          }
          odometer
          speed
          eventTime
        }
        smartCarVehicleData {
          vehicleLocation {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export const GET_VEHICLES_FOR_TRACKING = gql`
   query getTelematicsVehiclesForBusinessCustomer($businessCustomerId: ID!) {
    getTelematicsVehiclesForBusinessCustomer(businessCustomerId: $businessCustomerId) {
      id
      licencePlate
      telemetryDeviceId
      telematicCoreData {
        latitude
        longitude
        eventTime
        address {
          street
          city
        }
      }
      smartCarVehicleData {
        vehicleLocation {
          latitude
          longitude
        }
        odometer {
          distance
        }
        eVChargeStatus {
          isPluggedIn
          state
        }
        eVBatteryLevel {
          percentRemaining
          range
        }
        fuelLevel {
          amountRemaining
          range
          percentRemaining
        }
        engineOilLife {
          lifeRemaining
        }
        tirePressure {
            backLeft
            backRight
            frontLeft
            frontRight
          }
        eVBatteryCapacity {
          capacity
        }
      }
    }
  }
`;

export const GET_VEHICLES_FOR_TRACKING_HISTORY = gql`
   {
    vehicles {
      id
      licencePlate
      telemetryDeviceId
    }
  }
`;

export const GET_VEHICLE_DATA = gql`
  query Vehicle($id: String!) {
    vehicle(id: $id) {
      cylinderCapacity
      fuelType
      licencePlate
      telemetryDeviceId
      imageUrl
      id
      model
      make
      branch
      vehicleStarter {
          status
          latestActionTime
          lastestActionBy
        }
    }
  }
`;