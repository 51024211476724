import React from "react"
export const CampervanSVG = {
	interior: {
		width: 201,
		height: 475,
		svg: (
			<><g>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M99.4,20.6c0,0,25.7,1.5,38.7,3.1
            c3.4,0.4,6.6,1.6,9.5,3.3c5.4,3.3,14.9,9.4,22.3,16.2c1.6,1.5,3,3.1,4.1,5l3.4,5.4l2,17V194v199.7v11.2c0,0,1,4.8-3,11.8v17
            l-1.1,1.1c-1.1,1.1-1.9,2.4-2.4,3.9c-1.1,3-4.4,8.6-14.5,10.1c0,0-25,2-59,2"/>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M100.6,20.6c0,0-25.7,1.5-38.7,3.1
            c-3.4,0.4-6.6,1.6-9.5,3.3c-5.4,3.3-14.9,9.4-22.3,16.2c-1.6,1.5-3,3.1-4.1,5l-3.4,5.4l-2,17V194v199.7v11.2c0,0-1,4.8,3,11.8v17
            l1.1,1.1c1.1,1.1,1.9,2.4,2.4,3.9c1.1,3,4.4,8.6,14.5,10.1c0,0,23.9,2,57.9,2"/>
			</g>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M31.4,97.6c0,0,70-36,132-3l5-8c0,0-14-18-67-18
        c0,0-45-3-73,22L31.4,97.6z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M22.6,53.6c0,0,2.9-21,26.9-34
            c1.7-0.9,4-1.9,5.9-2.5c8.1-2.5,22.1-3.5,45.1-3.5"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M177.4,53.6c0,0-2.9-21-26.9-34
            c-1.7-0.9-4-1.9-5.9-2.5c-8.1-2.5-22.1-3.5-45.1-3.5"/>
				</g>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M58.4,25.6c0,0-8,17-13,52
        C45.4,77.6,55.4,32.6,58.4,25.6z"/>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M144.4,25.6c0,0,8,17,13,52
        C157.4,77.6,147.4,32.6,144.4,25.6z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M26.1,436.6c0,0,9.3-8.9,13.3-9.9h61.1" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M173.4,436.6c0,0-9.3-8.9-13.3-9.9H98.9" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M39.9,448.1c0,0,7.7-10.3,11-11.5h50.2" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M160.9,448.1c0,0-7.7-10.3-11-11.5H99.8" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M26.1,436.6c0,0,0.3,15.2,13.8,19.1
            s61.2,3.6,61.2,3.6"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M174.1,436.6c0,0-0.3,15.2-13.8,19.1
            s-61.2,3.6-61.2,3.6"/>
				</g>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M31.4,97.6c0,0,14,46,11,141v188" />
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M163.4,94.6c0,0-8,53-8,142.5v190" />
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M20.6,95.2c0,0,2.4,2.3,6,9.6
            c0.1,0.2,0.2,0.5,0.3,0.7c0.9,3.8,8.6,37.2,9.6,46c0,0.1,0,0.2,0,0.4V197v4.2c0,1.8-1.4,3.2-3.2,3.2H20.4"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M36.4,202.7v212c0,1.5-0.8,2.6-2,3.4
            c-1.9,1.1-5.3,1.5-11,1.6"/>
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="36.4" y1="303.6" x2="20.4" y2="303.6" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M31.9,297.6H27c-1.9,0-3.5-1.6-3.5-3.5v-81.9
            c0-1.9,1.6-3.5,3.5-3.5h4.9c1.9,0,3.5,1.6,3.5,3.5v81.9C35.4,296,33.9,297.6,31.9,297.6z"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M36.4,198.6c0,0-16-7-16-10" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M179.3,95.2c0,0-2.4,2.3-6,9.6
            c-0.1,0.2-0.2,0.5-0.3,0.7c-0.9,3.8-8.6,37.2-9.6,46c0,0.1,0,0.2,0,0.4V197v4.2c0,1.8,1.4,3.2,3.2,3.2h12.8"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M163.4,202.7v212c0,1.5,0.8,2.6,2,3.4
            c1.9,1.1,5.3,1.5,11,1.6"/>
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="163.4" y1="303.6" x2="179.4" y2="303.6" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M168,297.6h4.9c1.9,0,3.5-1.6,3.5-3.5v-81.9
            c0-1.9-1.6-3.5-3.5-3.5H168c-1.9,0-3.5,1.6-3.5,3.5v81.9C164.4,296,166,297.6,168,297.6z"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M163.4,198.6c0,0,16-7,16-10" />
				</g>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M20.6,110.6c0,0-11.1,4-16.1,11v12.1l16.1-8.5
        V110.6z"/>
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M179.4,110.6c0,0,11.1,4,16.1,11v12.1l-16.1-8.5
        V110.6z"/>
				<path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M105.4,21.6h-11c-1.1,0-2-0.9-2-2v0
        c0-1.1,0.9-2,2-2h11c1.1,0,2,0.9,2,2v0C107.4,20.7,106.5,21.6,105.4,21.6z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M118.5,96.4H144c0,0,3.7,0.6,5.2,6.5l0.2,14.6c0,0-1.9,8.4-7.1,11
            h-22.9c0,0-4.7-3.2-6.7-10.9v-15C112.6,102.7,114.6,97.1,118.5,96.4z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M150,126.1l-0.6-0.9h-3.2l-2.1,2.2l-2.5,1.2l-10.6-0.1v0l-10.9,0.3
            l-2.3-1.6L116,125l-3.6,0.2l-1,1.6l-0.6,4.2v13l0.4,3.4l1.3,2.3l1.7,1.5l2.6,1.2h3.6h1.6l0.1,0.9h8.7v0h8.7l0.1-0.9h1.6h3.6
            l2.6-1.2l1.7-1.5l1.3-2.3l0.4-3.4v-13l-0.4-3.4C150.5,127.1,150.3,126.6,150,126.1z"/>
					<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="130.9,143.5 120,143.5 120,152.1 122,154.1 130.9,154.1 
            139.8,154.1 141.8,152.1 141.8,143.5 	"/>
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M60.8,96.1h25.5c0,0,3.7,0.6,5.2,6.5l0.2,14.6c0,0-1.9,8.4-7.1,11
            H61.6c0,0-4.7-3.2-6.7-10.9v-15C54.9,102.4,56.8,96.9,60.8,96.1z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.2,125.8l-0.6-0.9h-3.2l-2.1,2.2l-2.5,1.2l-10.6-0.1v0l-10.9,0.3
            l-2.3-1.6l-1.7-2.1l-3.6,0.2l-1,1.6l-0.6,4.2v13l0.4,3.4l1.3,2.3l1.7,1.5l2.6,1.2h3.6h1.6l0.1,0.9h8.7v0h8.7L82,152h1.6h3.6
            l2.6-1.2l1.7-1.5l1.3-2.3l0.4-3.4v-13l-0.4-3.4C92.7,126.8,92.5,126.3,92.2,125.8z"/>
					<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="73.2,143.3 62.2,143.3 62.2,151.9 64.3,153.9 73.2,153.9 
            82,153.9 84.1,151.9 84.1,143.3 	"/>
				</g>
			</>),
	},

	exterior: {
		width: 677,
		height: 911,
		svg: (
			<><g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M99.5,248c0,0,10-1,13,3h6.3c0,0,17.7,20,23.7,46l2,3v8l46,63l13,18
            l30,44c0,0,5,6,6,16c0,0,4,132-1,187c0,0,1,5-14,12l-31,14c0,0-30,8-47,10l-6,4h-43v2h-20h-4.2l0.2-36c0,0,24-4,24-34
            c0,0,1-22-26-34h-17V350h13c4,0,8.8-1.4,12.3-4c7.6-5.5,19.4-16.8,18.7-33c0,0,1-26-24-37h-21v-16.1c0,0,2.2-7,4.9-12.3
            c1.5-2.8,4.4-4.6,7.6-4.6h25.5c2.2,0,4.3,0.9,5.7,2.5L99.5,248z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M45.5,366" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M51.5,649" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M72.5,642l-16,1v19.1l2,0.9v17c0,0,0,0.2,0,0.5
            c0.3,2,2.2,3.5,4.2,3.5h4.7c3.2,0,5.9-2.6,5.8-5.9V678v-36H72.5z"/>
				<g>
					<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="60.5" cy="313" r="30" />
					<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="60.5" cy="313" r="18" />
				</g>
				<g>
					<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="59.5" cy="609" r="30" />
					<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="59.5" cy="609" r="18" />
				</g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M79.3,664h50.2c42.8-3,58.9-8.1,64.4-10.6c1.6-0.7,2.6-2.3,2.6-4V642
            c0,0-1-227-1-246c0-13-4-18-4-18l-54-66l-9-18c-2.2-5-5.4-10.6-7-13.3c-0.7-1.1-1.1-2.4-1.2-3.7l-0.8-12l-1-14"/>
				<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="86.6,243 86.6,265.8 73,265.8 73,243 	" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M187.5,430.6l-52,0.4l-1-102.3c0-0.4,0-4.7,0-6.7s3,2,5,4l45,54
            c0,0,3,3,3,9V430.6z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M187.5,435.5V637c0,0,1,4-6,5l-39,5c0,0-6,1-7-4V435.5H187.5z" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="187.5" y1="533" x2="135.5" y2="533" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.5,287l13-14c0,0,6,8,17,8v51.4v97.9V636v28" />
				<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="111.9,279.6 111.9,609 111.9,664 	" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="135" y1="431" x2="53.5" y2="431" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="73.5" y1="349.5" x2="73.5" y2="575" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="79.5" y1="678" x2="79.5" y2="640" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M54.5,418.3h50.8c0,0,6.9,0.6,6.5,12.6" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M192.1,378.8l18.6,28.3c2.7,4.1,4.6,12,4.9,17
            c7,104,0.6,191.3-0.7,216.7c-0.2,3.2-2.2,6-5.2,7l-15.6,5.6"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M110.5,676c0,0,1-9-8-12h-5v12H110.5z" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M118,251h-4.7c0,0-6.8,11-8.8,18c0,0,0,8,16,9" />
				<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="65" cy="257" rx="4.5" ry="7" />
				<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="128.5" cy="413" rx="5" ry="11" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M129.3,402.1c0,0-3.2,9.6,0,21.7" />
			</g>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M569.3,248.4c0,0-10-1-13,3H550c0,0-17.7,20-23.7,46l-2,3v8l-46,63
            l-13,18l-30,44c0,0-5,6-6,16c0,0-4,132,1,187c0,0-1,5,14,12l31,14c0,0,30,8,47,10l6,4h43v2h20h4.2l-0.2-36c0,0-24-4-24-34
            c0,0-1-22,26-34h17v-224h-13c-4,0-8.8-1.4-12.3-4c-7.6-5.5-19.4-16.8-18.7-33c0,0-1-26,24-37h21v-16.1c0,0-2.2-7-4.9-12.3
            c-1.5-2.8-4.4-4.6-7.6-4.6h-25.5c-2.2,0-4.3,0.9-5.7,2.5L569.3,248.4z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M623.3,366.4" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M617.3,649.4" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M596.3,642.4l16,1v19.1l-2,0.9v17c0,0,0,0.2,0,0.5
            c-0.3,2-2.2,3.5-4.2,3.5h-4.7c-3.2,0-5.9-2.6-5.8-5.9v-0.1v-36H596.3z"/>
					<g>
						<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="608.3" cy="313.4" r="30" />
						<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="608.3" cy="313.4" r="18" />
					</g>
					<g>
						<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="609.3" cy="609.4" r="30" />
						<circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="609.3" cy="609.4" r="18" />
					</g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M589.5,664.4h-50.2c-42.8-3-58.9-8.1-64.4-10.6
            c-1.6-0.7-2.6-2.3-2.6-4v-7.4c0,0,1-227,1-246c0-13,4-18,4-18l54-66l9-18c2.2-5,5.4-10.6,7-13.3c0.7-1.1,1.1-2.4,1.2-3.7l0.8-12
            l1-14"/>
					<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="582.2,243.4 582.2,266.2 595.8,266.2 595.8,243.4 	" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M481.3,431l52,0.4l1-102.3c0-0.4,0-4.7,0-6.7s-3,2-5,4l-45,54
            c0,0-3,3-3,9V431z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M481.3,435.9v201.5c0,0-1,4,6,5l39,5c0,0,6,1,7-4V435.9H481.3z" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="481.3" y1="533.4" x2="533.3" y2="533.4" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M579.3,287.4l-13-14c0,0-6,8-17,8v51.4v97.9v205.7v28" />
					<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="556.9,280 556.9,609.4 556.9,664.4 	" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="533.8" y1="431.4" x2="615.3" y2="431.4" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="595.3" y1="349.9" x2="595.3" y2="575.4" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="589.3" y1="678.4" x2="589.3" y2="640.4" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M614.3,418.7h-50.8c0,0-6.9,0.6-6.5,12.6" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M476.7,379.2l-18.5,28.2c-2.7,4.1-4.6,12-4.9,17
            c-7,104-0.6,191.3,0.7,216.7c0.2,3.2,2.2,6,5.2,7l15.6,5.6"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M558.3,676.4c0,0-1-9,8-12h5v12H558.3z" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M550.8,251.4h4.7c0,0,6.8,11,8.8,18c0,0,0,8-16,9" />
					<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="603.8" cy="257.4" rx="4.5" ry="7" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="540.3" cy="413.4" rx="5" ry="11" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M539.5,402.5c0,0,3.2,9.6,0,21.7" />
				</g>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M257.5,194.3v25.6c0,0-0.4,2.2,1.6,2.2h19
        c0,0,1,0,1-1.1v-24.8C279.1,196.2,267.9,211.5,257.5,194.3z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M419.1,194.3v25.6c0,0,0.4,2.2-1.6,2.2h-19
        c0,0-1,0-1-1.1v-24.8C397.5,196.2,408.7,211.5,419.1,194.3z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M338.1,194.6h58.3c0,0,2.7,8,11.7,8
            c0,0,6,1,11-8v-44.8c0,0-8-41.2-9-46.2l-13-46l-7-20l-7-20c0,0-4-7-17-6l-28-0.1"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M338.1,194.6h-58.3c0,0-2.7,8-11.7,8
            c0,0-6,1-11-8v-44.8c0,0,8-41.2,9-46.2l13-46l7-20l7-20c0,0,4-7,17-6l28-0.1"/>
				</g>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M269.1,103.6h137.7l-12.7-43c0,0-0.1-0.2-0.2-0.6
        c-1.1-2.7-3.7-4.4-6.6-4.4h-99c0,0-0.1,0-0.3,0c-3.1,0.2-5.8,2.4-6.7,5.4L269.1,103.6z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M286.1,38.6c0,0,53-6,104,0" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M413.1,60.6" />
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M287.1,136.1v13.1c0,0.8-0.6,1.4-1.4,1.4h-23.6
            c-0.8,0-1.5-0.7-1.4-1.5c0.1-2.2,0.5-6.3,1.2-9.6c0.6-2.9,3.2-4.9,6.2-4.9h17.7C286.5,134.6,287.1,135.3,287.1,136.1z"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="267.1" y1="134.6" x2="267.1" y2="150.6" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M387.7,136.1v13.1c0,0.8,0.6,1.4,1.4,1.4h23.6
            c0.8,0,1.5-0.7,1.4-1.5c-0.1-2.2-0.5-6.3-1.2-9.6c-0.6-2.9-3.2-4.9-6.2-4.9H389C388.3,134.6,387.7,135.3,387.7,136.1z"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="407.7" y1="134.6" x2="407.7" y2="150.6" />
				</g>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M382.2,154.6H292c-1.6,0-2.9-1.3-2.9-2.9v-14.3
        c0-1.6,1.3-2.9,2.9-2.9h90.3c1.6,0,2.9,1.3,2.9,2.9v14.3C385.1,153.3,383.8,154.6,382.2,154.6z"/>
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="289.1" y1="137.6" x2="385.1" y2="137.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="289.1" y1="140.6" x2="385.1" y2="140.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="289.1" y1="143.6" x2="385.1" y2="143.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="289.1" y1="146.6" x2="385.1" y2="146.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="289.1" y1="149.6" x2="385.1" y2="149.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="289.1" y1="152.6" x2="385.1" y2="152.6" />
				<rect x="257.1" y="165.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="162" height="15" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.1" y1="167.6" x2="419.1" y2="167.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.1" y1="169.6" x2="419.1" y2="169.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.1" y1="171.6" x2="419.1" y2="171.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.1" y1="173.6" x2="419.1" y2="173.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.1" y1="176.6" x2="419.1" y2="176.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.1" y1="178.6" x2="419.1" y2="178.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="260.1" y1="165.6" x2="260.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="264.1" y1="165.6" x2="264.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="268.1" y1="165.6" x2="268.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="272.1" y1="165.6" x2="272.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="276.1" y1="165.6" x2="276.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="280.1" y1="165.6" x2="280.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="284.1" y1="165.6" x2="284.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="288.1" y1="165.6" x2="288.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="292.1" y1="165.6" x2="292.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="296.1" y1="165.6" x2="296.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="300.1" y1="165.6" x2="300.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="304.1" y1="165.6" x2="304.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="308.1" y1="165.6" x2="308.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="312.1" y1="165.6" x2="312.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="316.1" y1="165.6" x2="316.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="320.1" y1="165.6" x2="320.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="324.1" y1="165.6" x2="324.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="328.1" y1="165.6" x2="328.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="332.1" y1="165.6" x2="332.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="336.1" y1="165.6" x2="336.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="340.1" y1="165.6" x2="340.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="344.1" y1="165.6" x2="344.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="348.1" y1="165.6" x2="348.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="352.1" y1="165.6" x2="352.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="356.1" y1="165.6" x2="356.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="360.1" y1="165.6" x2="360.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="364.1" y1="165.6" x2="364.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="368.1" y1="165.6" x2="368.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="372.1" y1="165.6" x2="372.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="376.1" y1="165.6" x2="376.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="380.1" y1="165.6" x2="380.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="384.1" y1="165.6" x2="384.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="388.1" y1="165.6" x2="388.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="392.1" y1="165.6" x2="392.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="396.1" y1="165.6" x2="396.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="400.1" y1="165.6" x2="400.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="404.1" y1="165.6" x2="404.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="408.1" y1="165.6" x2="408.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="412.1" y1="165.6" x2="412.1" y2="180.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="416.1" y1="165.6" x2="416.1" y2="180.6" />
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M261.8,125.8c0,0,7.4,5.8,28.4,6.8H338" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M414.8,125.8c0,0-7.4,5.8-28.4,6.8h-48.8" />
				</g>
				<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="268.6" cy="192.1" r="7.5" />
				<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="406.6" cy="192.1" r="7.5" />
				<rect x="313.1" y="180.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="50" height="13" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M283.1,107.6c0,0,7,8,8,24
        C291.1,131.6,287.1,112.6,283.1,107.6z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M393.1,107.6c0,0-7,8-8,24
        C385.1,131.6,389.1,112.6,393.1,107.6z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M265.9,104.9h-3.7v-9.3c0,0-4-2-7-2
        c0,0-5-0.7-8,5.6v8.4c0,0,0.5,1.5,1.8,2.7c0.9,0.9,2.2,1.3,3.4,1.3h11.8L265.9,104.9z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M410.1,104.9h3.7v-9.3c0,0,4-2,7-2
        c0,0,5-0.7,8,5.6v8.4c0,0-0.5,1.5-1.8,2.7c-0.9,0.9-2.2,1.3-3.4,1.3h-11.8L410.1,104.9z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M254.3,873.2l-0.2,25c0,0,0,1,1,1h20c0,0,1,0,1-1v-25H254.3z" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M390.3,873.2l-0.2,25c0,0,0,1,1,1h20c0,0,1,0,1-1v-25H390.3z" />
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M333.1,873.2h-78.8v-16.1h-2.6v-28.9h2.6c0,0-0.2-13.9,0.9-27.2
            c0.4-5,3.7-27,4.9-31.8l8.3-31.5c0.4-1.6,1.7-2.8,3.3-3.1c6.6-1.3,20.4-4.4,61.4-4.4"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.7,873.2h78.8v-16.1h2.6v-28.9h-2.6c0,0,0.2-13.9-0.9-27.2
            c-0.4-5-3.7-27-4.9-31.8l-8.3-31.5c-0.4-1.6-1.7-2.8-3.3-3.1c-6.6-1.3-20.1-4.4-61.1-4.4"/>
				</g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M254.1,828.2h15.2l0.3-17c0,0-3.1-8-14.5-8L254.1,828.2z" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M411.8,828.2h-13.9l-0.3-17c0,0,2.8-8,13.3-8L411.8,828.2z" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="269.6" y1="831.2" x2="397.6" y2="831.2" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="269.6" y1="811.2" x2="397.6" y2="811.2" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="269.6" y1="854.2" x2="397.6" y2="854.2" />
				<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="269.4,828.2 269.6,857.2 254.1,857.2 " />
				<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="397.7,828.2 397.6,857.2 413.6,857.2 " />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M345.1,844.2c0,3.9-5.6,7-12.5,7s-12.5-3.1-12.5-7s7.1-3,14-3
        S345.1,840.3,345.1,844.2z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M280.2,811.2c0,0,8.9,16,30.9,20" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M387.1,811.2c0,0-8.9,16-30.9,20" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M356.1,829.2H310c-0.5,0-0.9-0.4-0.9-0.9v-14.1c0-0.5,0.4-0.9,0.9-0.9
        h46.1c0.5,0,0.9,0.4,0.9,0.9v14.1C357.1,828.8,356.6,829.2,356.1,829.2z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M333.6,789.2h64.3c1,0,2-0.3,2.7-1l0,0c1-0.9,1.4-2.4,1.2-3.7
            l-7.7-39.1c-0.6-3-3-5.3-6-5.7c-12.4-1.6-36.5-2.5-56-2.5"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M333.6,789.2h-66.8c-1,0-2-0.3-2.7-1l0,0c-1-0.9-1.4-2.4-1.2-3.7
            l7.7-39.1c0.6-3,3-5.3,6-5.7c12.4-1.6,36-2.5,55.5-2.5"/>
				</g>
				<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M347.9,740.2h-28.7c-0.6,0-1.1-0.5-1.1-1.1v-3.7
        c0-0.6,0.5-1.1,1.1-1.1h28.7c0.6,0,1.1,0.5,1.1,1.1v3.7C349.1,739.7,348.5,740.2,347.9,740.2z"/>
				<circle fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" cx="333.1" cy="800.2" r="5" />
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M269.3,736l16.8-41.8c0,0,2.3-5.7,12.1-6.3H334" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397,736l-16.8-41.8c0,0-2.3-5.7-12.1-6.3h-35.8" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M273.3,735l6.8-16.1c1.3-3.1,4.1-5.3,7.5-5.7
            c3.1-0.4,7.5-0.7,12.7-0.5h33.5"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M392.6,735l-7.9-17.7c-1-2-3-3.3-5.2-3.5c-2.3-0.2-6.8-0.5-11.4-0.6
            l-36-0.5"/>
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M269.6,857.2c0,0,0.4,1.3,1.2,2.7c1.1,2,3.4,3.3,5.7,3.3h57.3" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397.6,857.2c0,0-0.4,1.3-1.2,2.7c-1.1,2-3.4,3.3-5.7,3.3h-57.3" />
				</g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M258.1,780.2h-2.3v-8h-9.7c0,0-4,3-5,8c0,0-0.2,0.8-0.1,2.1
        c0.4,3.2,2.8,5.9,6,6.7h0.2c0.6,0.1,1.2,0.2,1.8,0.2h7.7L258.1,780.2z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M407.9,780.2h2.3v-8h9.7c0,0,4,3,5,8c0,0,0.2,0.8,0.1,2.1
        c-0.4,3.2-2.8,5.9-6,6.7h-0.2c-0.6,0.1-1.2,0.2-1.8,0.2h-7.7L407.9,780.2z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M333.4,235.6c0,0,25.7,1.5,38.7,3.1c3.4,0.4,6.6,1.6,9.5,3.3
            c5.4,3.3,14.9,9.4,22.3,16.2c1.6,1.5,3,3.1,4.1,5l3.4,5.4l2,17V409v199.7v11.2c0,0,1,4.8-3,11.8v17l-1.1,1.1
            c-1.1,1.1-1.9,2.4-2.4,3.9c-1.1,3-4.4,8.6-14.5,10.1c0,0-25,2-59,2"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M334.6,235.6c0,0-25.7,1.5-38.7,3.1c-3.4,0.4-6.6,1.6-9.5,3.3
            c-5.4,3.3-14.9,9.4-22.3,16.2c-1.6,1.5-3,3.1-4.1,5l-3.4,5.4l-2,17V409v199.7v11.2c0,0-1,4.8,3,11.8v17l1.1,1.1
            c1.1,1.1,1.9,2.4,2.4,3.9c1.1,3,4.4,8.6,14.5,10.1c0,0,23.9,2,57.9,2"/>
				</g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M273.4,356.6c0,0,62-12,119-1l10-55c0,0-14-17-67-17c0,0-45-3-73,22
        L273.4,356.6z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M256.6,268.6c0,0,2.9-21,26.9-34c1.7-0.9,4-1.9,5.9-2.5
            c8.1-2.5,22.1-3.5,45.1-3.5"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M411.4,268.6c0,0-2.9-21-26.9-34c-1.7-0.9-4-1.9-5.9-2.5
            c-8.1-2.5-22.1-3.5-45.1-3.5"/>
				</g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M292.4,240.6c0,0-8,17-13,52C279.4,292.6,289.4,247.6,292.4,240.6z" />
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M378.4,240.6c0,0,8,17,13,52
        C391.4,292.6,381.4,247.6,378.4,240.6z"/>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M260.1,651.6c0,0,9.3-8.9,13.3-9.9h61.1" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M407.4,651.6c0,0-9.3-8.9-13.3-9.9H333" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M273.9,663.1c0,0,7.7-10.3,11-11.5h50.2" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M394.9,663.1c0,0-7.7-10.3-11-11.5h-50.2" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M260.1,651.6c0,0,0.3,15.2,13.8,19.1
            c13.5,3.9,61.2,3.6,61.2,3.6"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M408.1,651.6c0,0-0.3,15.2-13.8,19.1
            c-13.5,3.9-61.2,3.6-61.2,3.6"/>
				</g>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M273.4,356.6c0,0,4,74,3,97v188" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M392.4,355.1c0,0-4,74-3,97v190" />
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M254.6,310.2c0,0,2.4,2.3,6,9.6c0.1,0.2,0.2,0.5,0.3,0.7
            c0.9,3.8,8.6,37.2,9.6,46c0,0.1,0,0.2,0,0.4V412v4.2c0,1.8-1.4,3.2-3.2,3.2h-12.8"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M270.4,417.7v212c0,1.5-0.8,2.6-2,3.4c-1.9,1.1-5.3,1.5-11,1.6" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="270.4" y1="518.6" x2="254.4" y2="518.6" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M265.9,512.6H261c-1.9,0-3.5-1.6-3.5-3.5v-81.9
            c0-1.9,1.6-3.5,3.5-3.5h4.9c1.9,0,3.5,1.6,3.5,3.5v81.9C269.4,511,267.9,512.6,265.9,512.6z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M270.4,413.6c0,0-16-7-16-10" />
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M413.3,310.2c0,0-2.4,2.3-6,9.6
            c-0.1,0.2-0.2,0.5-0.3,0.7c-0.9,3.8-8.6,37.2-9.6,46c0,0.1,0,0.2,0,0.4V412v4.2c0,1.8,1.4,3.2,3.2,3.2h12.8"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M397.4,417.7v212c0,1.5,0.8,2.6,2,3.4
            c1.9,1.1,5.3,1.5,11,1.6"/>
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="397.4" y1="518.6" x2="413.4" y2="518.6" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M402,512.6h4.9c1.9,0,3.5-1.6,3.5-3.5v-81.9
            c0-1.9-1.6-3.5-3.5-3.5H402c-1.9,0-3.5,1.6-3.5,3.5v81.9C398.4,511,400,512.6,402,512.6z"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M397.4,413.6c0,0,16-7,16-10" />
				</g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M254.6,325.6c0,0-11.1,4-16.1,11v12.1l16.1-8.5V325.6z" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M413.4,325.6c0,0,11.1,4,16.1,11v12.1l-16.1-8.5
        V325.6z"/>
				<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M339.4,236.6h-11c-1.1,0-2-0.9-2-2l0,0
        c0-1.1,0.9-2,2-2h11c1.1,0,2,0.9,2,2l0,0C341.4,235.7,340.5,236.6,339.4,236.6z"/></>)
	}
}