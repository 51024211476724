
import { SET_PREBOOKING_QUESTIONS } from "../../actions/branch/types";
import { initialState } from "./const";
import { IBranchState } from "./types";

export default function branchReducer(
    state: IBranchState = initialState,
    action: any
): IBranchState {
    switch (action.type) {
        case SET_PREBOOKING_QUESTIONS:
            return {
                ...state,
                preBookingQuestions: action.payload && action.payload.length ? [...action.payload] : []
            }
        default:
            return state;
    }
}
