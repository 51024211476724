import { io } from "socket.io-client";

const URL =
  process.env.REACT_APP_NOTIFICATION_API_URL ||
  "https://dev-notification-api.coastr.com:5005";

export const socket = io(URL, {
  transports: ["websocket"],
  autoConnect: false
});
