import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useParams } from "react-router-dom";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { GET_CREDIT_NOTE } from "../../../../graphql/creditNotes/getCreditNoteQuery";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from "../../../utils";
import { ICreditNote, ICreditNoteItem } from "../../../../reducers/bookings/types";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { StyledTableCell, StyledTableRow } from "../../../StyledTable";

const columns = [
  "#",
  "Particulars",
  "Quantity",
  "Amount"
];

const adjustmentColumns = [
  "#",
  "Amount",
  "Adjusted To Invoice",
  "Adjusted At"
]

const CreditNote: React.FC = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const { id: creditNoteId } = useParams<{ id: string }>();
  const [creditNote, setCreditNote] = useState<ICreditNote>();
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const { currency, locale } = website.organisation;
  const { country } = website.organisation.address;
  
  const [getCreditNote, { loading: creditNoteLoading, data: creditNoteData }] =
  useLazyQuery(GET_CREDIT_NOTE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.consumerCreditNote) {
        history.push("/account/credit-notes");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    console.log(creditNoteId)
    if (creditNoteId) {
      getCreditNote({
        variables: {
          id: creditNoteId,
        },
      });
    }
  }, []);

  useEffect(() => {
    if(creditNoteData?.consumerCreditNote) {
      setCreditNote(creditNoteData.consumerCreditNote)
    }
  }, [creditNoteData])

  if (!creditNote) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress size={30} thickness={5} />
      </div>
    );
  }
  
  return (
    <div className={styles.column}>
      <div className="flex cross-center">
        <Typography
          variant="h4"
          className="semi-bold flex cross-center space-between"
        >
          <IconButton
            size="small"
            edge="start"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="margin-left">Credit Note Summary</span>
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={12} md={4} style={{ display: "flex" }}>
          <div className={styles.card}>
            {creditNote.businessCustomer && <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Business Name:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {creditNote.businessCustomer.businessName}
              </Typography>
            </div>}
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Customer Name:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {creditNote.businessCustomer ? creditNote.businessCustomer.contact.firstName : creditNote.customer?.firstName}{" "}
                {creditNote.businessCustomer ? creditNote.businessCustomer.contact.lastName : creditNote.customer?.lastName}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Customer Email:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {creditNote.businessCustomer ? creditNote.businessCustomer.billing.email : creditNote.customer?.email}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Date Created:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {getLocalizedDateFormat(country, creditNote.createdAt, DATE_TYPE.EXPANDED, creditNote.booking?.branch?.timeZone)}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: "flex" }}>
          <div className={styles.card}>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Credit Note Ref:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {creditNote.creditNoteRef}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Invoice Ref:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {creditNote.invoice.invoiceRef}
              </Typography>
            </div>
            {creditNote.booking &&
              <div className="margin-bottom">
                <Typography
                  variant="body1"
                  className="opacity60 padding-bottom--small"
                >
                  {getLocalizedBookingSyntex(country)} Ref:
                </Typography>
                <Typography variant="body1" className="semi-bold">
                  {creditNote.booking.referenceNumber}
                </Typography>
              </div>
            }
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: "flex" }}>
          <div className={styles.card}>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Total Amount:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {toCurrency(creditNote.totalAmount, currency, locale)}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Amount Adjusted/Refunded:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {toCurrency(creditNote.refundedAmount, currency, locale)}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Amount Available:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {toCurrency(creditNote.availableAmount, currency, locale)}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <div className={`${styles.box}`}>
          <div style={{ margin: "0 -10px -10px -10px" }}>
            <TableContainer style={{ maxHeight: "100%" }}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    {columns.map((column: string) => {
                      return <StyledTableCell>{column}</StyledTableCell>;
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {creditNote.creditNoteItems?.map(
                    (item: ICreditNoteItem, idx: number) => {
                      
                      return (
                        <StyledTableRow>
                          <StyledTableCell>{idx + 1}</StyledTableCell>
                          <StyledTableCell>
                            {item.description}{" "}
                          </StyledTableCell>
                          <StyledTableCell>{item.quantity}</StyledTableCell>
                          <StyledTableCell>
                            {toCurrency(item.totalAmount, currency, locale)}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>
      {creditNote.allocations && creditNote.allocations.length >0 &&
        creditNote.allocations.some((item)=>item.amount>0) && ( 
        <Grid item xs={12}>
          <div className={`${styles.box}`}>
            <div className="font-medium semi-bold margin-bottom padding-bottom">
              Adjustments
            </div>
            <div style={{ margin: "0 -10px -10px -10px" }}>
              <TableContainer style={{ maxHeight: "100%" }}>
                <Table stickyHeader aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      {adjustmentColumns.map((column: string) => {
                        return <StyledTableCell>{column}</StyledTableCell>;
                      })}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {creditNote.allocations && 
                    creditNote.allocations
                    .filter((item) => item.amount > 0 )
                    .map((item, index) => {  
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                           <StyledTableCell>
                            {toCurrency(item.amount, currency, locale)}
                          </StyledTableCell>
                          <StyledTableCell>{item.invoice.invoiceRef}</StyledTableCell>
                          <StyledTableCell>
                            {getLocalizedDateFormat(country, item.allocatedAt, DATE_TYPE.CONDENSED, creditNote.booking?.branch?.timeZone)}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Grid> )}
    </div>
  )
}

export default CreditNote;
