import _ from "lodash";

import { IBooking } from "../../../../reducers/bookings/types";
import { IInvoice } from "../../../../reducers/bookings/types";
import { toCurrency } from "../../../utils";


export enum InvoiceStatus {
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY PAID",
  UNPAID = "UNPAID"
}

export enum InvoiceType {
  FIRST = "FIRST",
  FINAL = "FINAL",
  ACCUMULATIVE = "ACCUMULATIVE", // for extend booking
  INTERMEDIATE = "INTERMEDIATE",
  SUNDRY = "SUNDRY",
  BOOKING_EXTENSION = "BOOKING_EXTENSION",
  CONSOLIDATED = "CONSOLIDATED"
}

// export function reshapeInvoicesIntoRows(
//   invoice: IInvoice,

//   locale: any,
//   currency: any,
//   bookingRef: string,
//   customer: string
// ) {
//   return {
//     referenceNumber: bookingRef,
//     invoiceId: invoice.invoiceRef,
//     startDate: new Date(invoice.startDate).toLocaleDateString(),
//     customer,
//     rentalAmount: toCurrency(invoice.rentalAmount, currency, locale),
//     insurance: toCurrency(invoice.insuranceAmount, currency, locale),
//     deposit: toCurrency(invoice.deposit, currency, locale),
//     damageAmount: toCurrency(invoice.damageAmount, currency, locale),
//     taxAmount: toCurrency(invoice.taxAmount, currency, locale),
//     sumTotal: toCurrency(invoice.totalPayableAmount, currency, locale),
//     status: invoice.status,
//     valetAmount: invoice.valetAmount
//   };
// }

// export const getTotalsFromInvoices = (invoices: IInvoice[]) => {
//   let rentalAmount: number = 0;
//   let insurance: number = 0;
//   let deposit: number = 0;
//   let damageAmount: number = 0;
//   let taxAmount: number = 0;
//   let sumTotal: number = 0;
//   const statuses: string[] = [];

//   invoices.forEach((invoice: IInvoice) => {
//     rentalAmount += invoice.rentalAmount;
//     insurance += invoice.insuranceAmount;
//     deposit += invoice.deposit;
//     damageAmount += invoice.damageAmount;
//     taxAmount += invoice.taxAmount;
//     sumTotal += invoice.totalPayableAmount;
//     statuses.push(invoice.status);
//   });

//   const unique = _.uniq(statuses);
//   const status = unique.length === 1 ? unique[0] : InvoiceStatus.PARTIALLY_PAID;
//   return {
//     damageAmount,
//     deposit,
//     insurance,
//     status,
//     rentalAmount,
//     sumTotal,
//     taxAmount
//   };
// };

export function getSubTotal(booking: IBooking) {
  return (
    booking.paymentDetails.rentalAmount +
    booking.paymentDetails.insuranceAmount
  );
}
