export default {
  root: {
    "&.MuiFab-extended.MuiFab-sizeMedium": {
      backgroundColor: "#FFC700",
      boxShadow: "none",
      color: "#fff",
      fontWeight: 500,
      letterSpacing: 1.25,
      padding: "10px 16px",
      fontSize: 14,
      "&:hover": {
        backgroundColor: "#F78500",
      },
      "&.blackBackButton": {
        background: "#404040",
        "&:hover": {
          background: "#292929",
        },
      },
      "&.interiorImageButton": {
        background: "#FFFFFF",
        border: "2px solid #FFC700",
        color: "#FFC700",
        opacity: "100%",
        fontWeight: "bold",
      },
      "&.imageUploadButton": {
        background: "#FFFFFF",
        border: "2px solid #FFC700",
        color: "#FFC700",
        opacity: "100%",
        fontWeight: "bold",
      },
      "&.documentUploadButton": {
        background: "#FFFFFF",
        border: "2px solid #FFC700",
        color: "#FFC700",
        opacity: "100%",
        fontWeight: "bold",
      },
      "&.greyButton": {
        color: "#000",
        background: "#D5D5D5",
        "&:hover": {
          background: "#C1C1C1",
          color: "#000",
        },
      },
      "&.Mui-disabled.Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.18)",
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
    "&.MuiFab-extended.MuiFab-sizeSmall": {
      "&.Mui-disabled.Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.18) !important",
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
    borderRadius: "4px !important",
  },
};
