import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_STATE = gql`
  mutation businessConsumerUpdateVehicleState($vehicleId: String!, $businessConsumerVehicleState: BusinessConsumerVehicleStateInput!) {
    businessConsumerUpdateVehicleState(vehicleId: $vehicleId, businessConsumerVehicleState: $businessConsumerVehicleState) {
      generalChecks {
          name
          status
          images
        }
        defaultAccesories {
          name
          status
          images
        }
        bookedAccesories {
          name
          type
          quantity
          description
        }
        id
        odometerReading
        fuelStatus
        batteryStatus
        updatedAt
        createdAt
        createdBy {
          firstName
          lastName
        }
        updatedBy {
          firstName
          lastName
        }
    }
  }
`;