import React, { useEffect, useState } from "react"
import styles from "./index.module.css";
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { Button, Typography } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell, StyledTableRow } from '../../../StyledTable';
import TableBody from '@material-ui/core/TableBody';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import ProfileScreen from '../../../Profile';
import DeleteIcon from '@material-ui/icons/Delete';
import { DELETE_DRIVER, DELETE_DRIVER_DESCRIPTION, IConfirmationDialogData, defaultConfirmationDialogData } from "../../Summary/const";
import { DELETE_BUSINESS_CUSTOMER_DRIVER } from "../../../../graphql/user/updateUser";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { formatGraphQLErrorMessage } from "../../../utils";
import { ApolloError } from "apollo-boost";
import { ConfirmationDialog } from "../../../ConfirmationDialog/ConfirmationDialog";
import { useMutation } from "@apollo/client";

const Drivers = () => {
  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackBar();
  const { id: idFromURL } = useParams<{ id: string }>();
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const [driverId, setDriverId] = useState<string | undefined>(idFromURL);
  const [confirmationDialogData, setConfirmationDialogData] = useState<
    IConfirmationDialogData
  >(defaultConfirmationDialogData);

  const [deleteBusinessCustomerDriver] = useMutation(
    DELETE_BUSINESS_CUSTOMER_DRIVER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Business customer driver deleted.",
          variant: SnackBarVariant.SUCCESS
        });
        setConfirmationDialogData({
          ...confirmationDialogData,
          isOpen: false,
          isLoading: false
        })
        window.location.reload();
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  useEffect(() => {
    if(idFromURL){
      setDriverId(idFromURL)
    }
    else{
      const pathname = window.location.pathname;
      const idFromUrl = pathname.split('/').pop();
      setDriverId(idFromUrl)
    }
    }, [location])


  if (!user?.businesscustomers) {
    return <></>
  }

  const selectedDriver = user.businesscustomers.approvedDrivers.find(driver => driver?.id === driverId);

  return (
    <div className={styles.column}>
      <Switch>
        <Route path="/account/drivers" exact>
          <div className="flex fill cross-center">
            <Typography variant="h2" className="semi-bold">Drivers</Typography>
            <div className={styles.filters}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  background: "var(--primary-color)",
                  textTransform: "initial",
                  boxShadow: "none",
                  fontSize: 14,
                  fontWeight: 500,
                }}
                onClick={() => {
                  history.push(`/account/drivers/new`);
                }}
              >
                Add+
                </Button>
            </div>
          </div>
          <div className="margin-top padding-top">
            <TableContainer style={{ maxHeight: "100%" }} className="row-hover">
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell> </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.businesscustomers.approvedDrivers.length ? user.businesscustomers.approvedDrivers.map((driver, index) =>
                    driver && <StyledTableRow
                      key={index} onClick={() => {
                        setDriverId(driver.id);
                        history.push(`/account/drivers/${driver.id}`);
                      }}
                    >
                      <StyledTableCell>{driver.firstName}</StyledTableCell>
                      <StyledTableCell>{driver.lastName}</StyledTableCell>
                      <StyledTableCell>{driver.email}</StyledTableCell>
                      <StyledTableCell>
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmationDialogData({
                              description: DELETE_DRIVER_DESCRIPTION,
                              isOpen: true,
                              onCancel: () =>
                                setConfirmationDialogData({
                                  ...confirmationDialogData,
                                  isOpen: false
                                }),
                              onConfirm: () => {
                                setConfirmationDialogData({
                                  ...confirmationDialogData,
                                  description: DELETE_DRIVER_DESCRIPTION,
                                  title: DELETE_DRIVER,
                                  isLoading: true,
                                  isOpen: true
                                })
                                deleteBusinessCustomerDriver({
                                  variables: {
                                    businessCustomerId: user.businesscustomers?.id,
                                    driverId: driver.id
                                  }
                                });  
                              },
                              title: DELETE_DRIVER,
                            })
                          }}
                        />
                    </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                      <StyledTableCell colSpan={20}>
                        <Typography style={{ textAlign: "center", opacity: 0.5 }}> "Sorry, no drivers found!"</Typography>
                      </StyledTableCell>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            <ConfirmationDialog {...confirmationDialogData} />
          </div>
        </Route>
        <Route path="/account/drivers/:id" >
          {selectedDriver && <ProfileScreen customer={selectedDriver} />}
        </Route>
      </Switch>

    </div >
  )
}

export default Drivers