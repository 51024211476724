import * as React from 'react';

export const TruckmanTop4DoorSVG = {
  interior: {
    width: 388.1,
    height: 948.8,
    svg: (
      <>
<g id="Layer_1">
	<g>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M345.8,252.3c1-2.3,1.5-4.7,1.5-7.1V124.5
			c-1.2-48.3-25.5-68.1-25.5-68.1c-34.6-34.8-69.5-45.9-69.5-45.9h-57.8h-6.7h-57.8c0,0-34.8,11.1-69.5,45.9
			c0,0-24.3,19.8-25.5,68.1v120.7c0,2.5,0.5,4.9,1.5,7.1c1.8,4.1,4.5,11.1,4.5,14.6v10.4l-2.1,134.8v222.6h2.9l-6.2,33.6
			c-0.3,1.9-0.5,3.7-0.5,5.6V796c0,0,8.3,104.5,10.7,119.6c0,0,2.4,15.8,17.4,17.8h76h48.7h6.7h48.7h76c15-2,17.4-17.8,17.4-17.8
			c2.4-15,10.7-119.6,10.7-119.6V674.1c0-1.9-0.2-3.8-0.5-5.6l-6.2-33.6h2.9V412.2l-2.1-134.8V267
			C341.3,263.4,344.1,256.4,345.8,252.3z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M312.4,242.1c-0.1-1.8-1-3.6-2.5-4.6
			c-19.9-14.4-45.5-19-45.5-19c-19.8-3.1-58-4.9-71.6-5.5v-0.2c0,0-0.9,0-2.5,0.1c-1.6-0.1-2.5-0.1-2.5-0.1v0.2
			c-13.6,0.6-51.8,2.3-71.6,5.5c0,0-25.6,4.7-45.5,19c-1.5,1.1-2.4,2.8-2.5,4.6"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M190.3,32.3h52.4c0,0,17.8,1.2,34,13.9
			L310.8,75c0,0,10.4,9.3,13,27.6c0.4,2.8,0.5,5.7,0.5,8.5v86.7c0,10.8-1.5,21.7-4.8,32c-1.6,5-3.6,9.6-5.8,11.8"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M191.1,32.3h-52.4c0,0-17.8,1.2-34,13.9
			L70.7,75c0,0-10.4,9.3-13,27.6c-0.4,2.8-0.5,5.7-0.5,8.5v86.7c0,10.8,1.5,21.7,4.8,32c1.6,5,3.6,9.6,5.8,11.8"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M85.9,228.6c0,0,32.9-130.8,34.5-191.6"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M296,228.6c0,0-32.9-130.8-34.5-191.6"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M127,33.9l-23.5-3.3c-1.4-0.2-1.6-2.2-0.2-2.6
				l29.7-10.8c0.1-0.1,0.3-0.1,0.5-0.1h57"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M251.6,33.9l23.5-3.3c1.4-0.2,1.6-2.2,0.2-2.6
				l-29.7-10.8c-0.1-0.1-0.3-0.1-0.5-0.1h-57"/>
		</g>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M82.2,42.4L62,60.5c0,0-10.5,12.2-15.2,25.3
			c0,0-6.7,15.1,6.6,14.7s10.9-0.3,13.4-4.5c2.4-4.1,5.3-20.9,11.3-25.1c4.2-3,13.8-11.1,19.2-15.7c3.3-2.8,5.4-6.7,5.5-11
			c0.1-4.7-2-9.3-12.3-6.4C87.4,38.7,84.6,40.3,82.2,42.4z"/>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M300.5,42.4l20.2,18
			c0,0,10.5,12.2,15.2,25.3c0,0,6.7,15.1-6.6,14.7c-13.4-0.3-10.9-0.3-13.4-4.5c-2.4-4.1-5.3-20.9-11.3-25.1
			c-4.2-3-13.8-11.1-19.2-15.7c-3.3-2.8-5.4-6.7-5.5-11c-0.1-4.7,2-9.3,12.3-6.4C295.2,38.7,298.1,40.3,300.5,42.4z"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M52.7,274.6c0-0.5,0.7-0.6,0.9-0.1
				c4.3,14,22.9,76.3,22.9,92.7v231.1c0,2.6-1,5.1-3.1,6.7c-1.5,1.1-3.5,2-6.4,1.6c0,0-13.1,1.2-14.3-38.6V274.6z"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="75.6" y1="577.1" x2="53.6" y2="572.8"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="76.5" y1="479.6" x2="51.4" y2="471"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="76.5" y1="455.2" x2="53.8" y2="441.7"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="74.6" y1="465.9" x2="52.7" y2="458.1"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M328.7,274.6c0-0.5-0.7-0.6-0.9-0.1
				c-4.3,14-22.9,76.3-22.9,92.7v231.1c0,2.6,1,5.1,3.1,6.7c1.5,1.1,3.5,2,6.4,1.6c0,0,13.1,1.2,14.3-38.6V274.6z"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="305.8" y1="577.1" x2="327.8" y2="572.8"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="304.9" y1="479.6" x2="330" y2="471"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="304.9" y1="455.2" x2="327.6" y2="441.7"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="306.8" y1="465.9" x2="328.7" y2="458.1"/>
		</g>
		<rect x="161.8" y="922.7" fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" width="56" height="9.7"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M58.1,897.6v10.6c0,0,0,0.1,0,0.1
			c0,0.8,0.8,9.7,10,10.9c1.5,0.2,2.6,1.4,2.5,2.9l0,0c-0.1,1.4-1.3,2.6-2.7,2.6h-9.6c-0.4,0-0.8-0.1-1.2-0.3
			c-2.1-1.1-8.5-6-8.7-26.8c0-1.5,1.2-2.8,2.8-2.8l4.2,0C56.9,894.9,58.1,896.1,58.1,897.6z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M323.8,897.6v10.6c0,0,0,0.1,0,0.1
			c0,0.8-0.8,9.7-10,10.9c-1.5,0.2-2.6,1.4-2.5,2.9l0,0c0.1,1.4,1.3,2.6,2.7,2.6h9.6c0.4,0,0.8-0.1,1.2-0.3c2.1-1.1,8.5-6,8.7-26.8
			c0-1.5-1.2-2.8-2.8-2.8l-4.2,0C325,894.9,323.8,896.1,323.8,897.6z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M52.7,277.9c0-1-1.1-1.7-2-1.3
			c-7.5,3.2-33.5,14.9-36.6,21.7c0,0-4.8,6.2-3,11.6c0,0,0,0.1,0,0.3c0.3,4.7,7.7,6,9.5,5.4l32-15.8V277.9z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M328.7,277.9c0-1,1.1-1.7,2-1.3
			c7.5,3.2,33.5,14.9,36.6,21.7c0,0,4.8,6.2,3,11.6c0,0,0,0.1,0,0.3c-0.3,4.7-7.7,6-9.5,5.4l-32-15.8V277.9z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M84.5,633.1L55,625.4c-1.2-0.3-2.3,0.6-2.3,1.8
			v252c0,4,4,6.8,7.7,5.3c9.1-3.6,23.2-12.1,24.3-30.5C86.2,829.1,84.5,633.1,84.5,633.1z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M299.5,633.1l29.4-7.7c1.2-0.3,2.3,0.6,2.3,1.8
			v252c0,4-4,6.8-7.7,5.3c-9.1-3.6-23.2-12.1-24.3-30.5C297.7,829.1,299.5,633.1,299.5,633.1z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M296.5,913.1H84.1c-0.3,0-0.6,0-0.9-0.1
			c-5.8-0.8-8.8-10-5.9-16.9L91,863h198l14.6,31c3.6,7.6,0.2,18.1-6.3,18.9C297.1,913,296.8,913.1,296.5,913.1z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M289.1,863v9.6c0,0.7-2,1.4-4.4,1.4H95.4
			c-2.4,0-4.4-0.6-4.4-1.4V863"/>
	</g>
</g>
<g id="Layer_2">
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M107.7,281h53.9c0,0,7.8,1.5,11,15.4l0.6,34.4c0,0-4.1,19.7-15,25.8
			h-48.7c0,0-9.8-7.6-14.1-25.6v-35.1C95.3,295.6,99.3,282.6,107.7,281z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M174,350.6l-1.1-2h-6.7l-4.5,5l-5.4,2.8l-22.5-0.2l0,0l-22.9,0.7
			l-4.8-3.9l-3.5-4.8l-7.6,0.4l-2.4,3.7l-1.1,10v30.5l0.9,7.8l2.8,5.4l3.5,3.5l5.4,2.8h7.6h3.3l0.2,2.2h18.4l0,0h18.4l0.2-2.2h3.3
			h7.6l5.4-2.8l3.5-3.5l2.8-5.4l0.9-7.8v-30.5l-0.9-8C175.1,353,174.8,351.7,174,350.6z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="133.9,391.7 110.9,391.7 110.9,411.7 115.1,416.6 
			133.9,416.6 152.7,416.6 156.9,411.7 156.9,391.7 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M220.6,277.3H276c0,0,8,1.5,11.3,15.8l0.6,35.3
			c0,0-4.2,20.2-15.5,26.5h-50c0,0-10.1-7.8-14.5-26.3v-36.1C207.8,292.3,212,279,220.6,277.3z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M288.7,348.8l-1.1-2.1h-6.9l-4.6,5.2l-5.5,2.9l-23.1-0.2l0,0
			l-23.5,0.8l-5-4l-3.6-5l-7.8,0.4l-2.5,3.8l-1.1,10.3v31.3l1,8l2.9,5.5l3.6,3.6l5.5,2.9h7.8h3.4l0.2,2.3h18.9l0,0h18.9l0.2-2.3h3.4
			h7.8l5.5-2.9l3.6-3.6l2.9-5.5l1-8v-31.3l-1-8.2C289.9,351.3,289.5,350,288.7,348.8z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="247.5,391 223.9,391 223.9,411.6 228.3,416.6 247.5,416.6 
			266.8,416.6 271.2,411.6 271.2,391 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M293.2,540.2H87.3c-3.1,0-5.6-2.5-5.6-5.6v-48.4
			c0-3.1,2.5-5.6,5.6-5.6h205.9c3.1,0,5.6,2.5,5.6,5.6v48.4C298.8,537.7,296.3,540.2,293.2,540.2z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M299.3,570.3H82.9c-2,0-3.6-1.6-3.6-3.5l3.5-23
			c0-1.9,1.6-3.5,3.6-3.5H294c2,0,2.6,1.6,3.6,3.5l5.3,23C302.9,568.7,301.3,570.3,299.3,570.3z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M302.9,567c0,1.9,0,5.5-3.6,5.5H82.9c-3.6-0.3-3.6-3.8-3.6-5.7"/>
	</g>
</g>
      </>
    ),
  },

  exterior: {
    width: 819.1,
    height: 1243.9,
    svg: (
      <>
  <g id="Layer_1">
	<g>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M510.6,433.3c0.7-1.6,1-3.3,1-5v-84.4
			c-0.8-33.8-17.9-47.6-17.9-47.6c-24.2-24.4-48.6-32.1-48.6-32.1h-40.4H400h-40.4c0,0-24.4,7.8-48.6,32.1c0,0-17,13.8-17.9,47.6
			v84.4c0,1.7,0.3,3.4,1,5c1.2,2.9,3.1,7.8,3.1,10.2v7.3l-1.5,94.3v155.7h2l-4.3,23.5c-0.2,1.3-0.4,2.6-0.4,3.9v85.2
			c0,0,5.8,73.1,7.5,83.6c0,0,1.7,11.1,12.2,12.5H366H400h4.7h34.1h53.2c10.5-1.4,12.2-12.5,12.2-12.5c1.7-10.5,7.5-83.6,7.5-83.6
			v-85.2c0-1.3-0.1-2.6-0.4-3.9l-4.3-23.5h2V545.1l-1.5-94.3v-7.3C507.4,441,509.4,436.1,510.6,433.3z"/>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M488,439.2l-0.8-13.1
			c-0.1-1.3-0.7-2.5-1.7-3.2c-13.9-10-31.8-13.3-31.8-13.3c-13.8-2.2-40.6-3.4-50.1-3.8v-0.1c0,0-0.6,0-1.7,0.1
			c-1.1,0-1.7-0.1-1.7-0.1v0.1c-9.5,0.4-36.3,1.6-50.1,3.8c0,0-17.9,3.3-31.8,13.3c-1,0.8-1.7,2-1.7,3.2l-0.8,13.1
			c-0.2,3.9,0,7.8,0.7,11.7l9.2,49.3c0.9,4.6,5,8,9.7,7.5c0.1,0,0.1,0,0.2,0h64.6h3.5h64.6c0.1,0,0.1,0,0.2,0
			c4.7,0.4,8.9-2.9,9.7-7.5l9.2-49.3C488,447,488.2,443.1,488,439.2z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M477.5,432.5c-0.3,0-0.4-0.1-0.4-0.1h-75.3
			h-75.3c0,0-0.1,0-0.4,0.1c-3.8,0.6-6.3,4.4-5.5,8.1l11.3,59.1c0,0,0.4,3.3,6.3,3.3h63.7h63.7c5.8,0,6.3-3.3,6.3-3.3l11.3-59.1
			C483.8,436.9,481.3,433.2,477.5,432.5z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M401.8,279.4h36.7c0,0,12.5,0.8,23.8,9.7
			l23.7,20.2c0,0,7.3,6.5,9.1,19.3c0.3,2,0.4,4,0.4,5.9v60.6c0,7.6-1.1,15.2-3.4,22.4c-1.1,3.5-2.5,6.7-4.1,8.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M402.4,279.4h-36.7c0,0-12.5,0.8-23.8,9.7
			l-23.7,20.2c0,0-7.3,6.5-9.1,19.3c-0.3,2-0.4,4-0.4,5.9v60.6c0,7.6,1.1,15.2,3.4,22.4c1.1,3.5,2.5,6.7,4.1,8.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M328.8,416.7c0,0,23-91.5,24.1-134"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M475.7,416.7c0,0-23-91.5-24.1-134"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M357.5,280.5l-16.4-2.3
				c-1-0.2-1.1-1.5-0.2-1.8l20.8-7.5c0.1,0,0.2-0.1,0.3-0.1h39.9"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M444.7,280.5l16.4-2.3c1-0.2,1.1-1.5,0.2-1.8
				l-20.8-7.5c-0.1,0-0.2-0.1-0.3-0.1h-39.9"/>
		</g>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M326.2,286.5l-14.1,12.6
			c0,0-7.4,8.6-10.6,17.7c0,0-4.7,10.5,4.6,10.3c9.3-0.2,7.7-0.2,9.3-3.1c1.6-2.9,3.7-14.6,7.9-17.5c2.9-2.1,9.7-7.8,13.4-10.9
			c2.3-1.9,3.8-4.7,3.9-7.7c0.1-3.3-1.4-6.5-8.6-4.5C329.8,283.9,327.9,285,326.2,286.5z"/>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M478.8,286.5l14.1,12.6
			c0,0,7.4,8.6,10.6,17.7c0,0,4.7,10.5-4.6,10.3c-9.3-0.2-7.7-0.2-9.3-3.1c-1.6-2.9-3.7-14.6-7.9-17.5c-2.9-2.1-9.7-7.8-13.4-10.9
			c-2.3-1.9-3.8-4.7-3.9-7.7c-0.1-3.3,1.4-6.5,8.6-4.5C475.2,283.9,477.2,285,478.8,286.5z"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="332.4" y1="507.7" x2="332.4" y2="694.3"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="470.9" y1="506.6" x2="470.9" y2="693.3"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="331.6" y1="693.5" x2="471.7" y2="693.5"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M305.6,448.8c0-0.4,0.5-0.4,0.6-0.1
				c3,9.8,16,53.4,16,64.8v161.7c0,1.8-0.7,3.6-2.1,4.7c-1,0.8-2.5,1.4-4.5,1.1c0,0-9.1,0.8-10-27V448.8z"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="321.6" y1="660.5" x2="306.2" y2="657.4"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="322.2" y1="592.2" x2="304.7" y2="586.2"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="322.2" y1="575.2" x2="306.3" y2="565.8"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="320.9" y1="582.7" x2="305.6" y2="577.2"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M498.6,448.8c0-0.4-0.5-0.4-0.6-0.1
				c-3,9.8-16,53.4-16,64.8v161.7c0,1.8,0.7,3.6,2.1,4.7c1,0.8,2.5,1.4,4.5,1.1c0,0,9.1,0.8,10-27V448.8z"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="482.6" y1="660.5" x2="498" y2="657.4"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="482" y1="592.2" x2="499.5" y2="586.2"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="482" y1="575.2" x2="497.8" y2="565.8"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="483.3" y1="582.7" x2="498.6" y2="577.2"/>
		</g>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M470.4,860.4H332.9c-0.3,0-0.5-0.2-0.5-0.5
			V698.1c0-0.3,0.2-0.5,0.5-0.5h137.5c0.3,0,0.5,0.2,0.5,0.5v161.8C470.9,860.2,470.7,860.4,470.4,860.4z"/>
		<rect x="381.9" y="902.1" fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" width="39.2" height="6.8"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M309.4,884.6v7.4c0,0,0,0.1,0,0.1
			c0,0.6,0.6,6.8,7,7.6c1,0.1,1.8,1,1.7,2l0,0c-0.1,1-0.9,1.8-1.9,1.8h-6.7c-0.3,0-0.6-0.1-0.8-0.2c-1.5-0.7-6-4.2-6.1-18.8
			c0-1.1,0.9-2,1.9-2l2.9,0C308.5,882.7,309.4,883.6,309.4,884.6z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M495.2,884.6v7.4c0,0,0,0.1,0,0.1
			c0,0.6-0.6,6.8-7,7.6c-1,0.1-1.8,1-1.7,2l0,0c0.1,1,0.9,1.8,1.9,1.8h6.7c0.3,0,0.6-0.1,0.8-0.2c1.5-0.7,6-4.2,6.1-18.8
			c0-1.1-0.9-2-1.9-2l-2.9,0C496,882.7,495.2,883.6,495.2,884.6z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M305.6,451.2c0-0.7-0.7-1.2-1.4-0.9
			c-5.2,2.3-23.5,10.4-25.6,15.2c0,0-3.3,4.4-2.1,8.1c0,0,0,0.1,0,0.2c0.2,3.3,5.4,4.2,6.7,3.8l22.4-11V451.2z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M498.6,451.2c0-0.7,0.7-1.2,1.4-0.9
			c5.2,2.3,23.5,10.4,25.6,15.2c0,0,3.3,4.4,2.1,8.1c0,0,0,0.1,0,0.2c-0.2,3.3-5.4,4.2-6.7,3.8l-22.4-11V451.2z"/>
		<rect x="398.9" y="513.6" fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" width="6.1" height="13.4"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="402.4" y1="527" x2="402.4" y2="541.5"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M327.8,699.6l-20.6-5.4
			c-0.8-0.2-1.6,0.4-1.6,1.2v176.2c0,2.8,2.8,4.7,5.4,3.7c6.4-2.5,16.2-8.5,17-21.3C329,836.7,327.8,699.6,327.8,699.6z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M478.2,699.6l20.6-5.4c0.8-0.2,1.6,0.4,1.6,1.2
			v176.2c0,2.8-2.8,4.7-5.4,3.7c-6.4-2.5-16.2-8.5-17-21.3C477,836.7,478.2,699.6,478.2,699.6z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M476.1,895.4H327.5c-0.2,0-0.4,0-0.6,0
			c-4-0.6-6.1-7-4.1-11.8l9.6-23.1h138.5l10.2,21.7c2.5,5.3,0.2,12.7-4.4,13.2C476.5,895.4,476.3,895.4,476.1,895.4z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M470.9,860.4v6.7c0,0.5-1.4,1-3.1,1H335.4
			c-1.7,0-3.1-0.4-3.1-1v-6.7"/>
	</g>
</g>
<g id="Layer_2">
	<g>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M331,188.7V226c0,3.3-2.7,6-6,6h-18.8c-3.3,0-6-2.7-6-6v-50.7"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M478.6,188.7V226c0,3.3,2.7,6,6,6h18.8c3.3,0,6-2.7,6-6v-50.7"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M331,205.5c0,0,2.5-7.4,10.2-7.7h20.4c0,0,4.5,0.3,6.8,4.7h24.2
				c0,0,4.8,4.8,12.6,4.7"/>
			<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M478.6,205.5c0,0-2.5-7.4-10.2-7.7H448c0,0-4.5,0.3-6.8,4.7H417
				c0,0-4.8,4.8-12.6,4.7"/>
		</g>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="378.2" y1="184.5" x2="378.2" y2="202.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432" y1="184.5" x2="432" y2="202.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="412.8" y1="184.5" x2="412.8" y2="205.3"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="396.9" y1="184.5" x2="396.9" y2="205.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M513.2,110.4c-0.1-1.2-0.7-3.8-1.4-6.6
			c-1.9-7.3-6.7-13.6-13.4-17.2c0,0,0,0,0,0L496.8,70l-10.8-38.6c-1.3-2.5-3-4.3-4.8-5.5c-2.5-1.7-5.6-2.6-8.6-2.6l-67.9-1l-67.9,1
			c-3.1,0-6.1,0.9-8.6,2.6c-1.7,1.2-3.5,2.9-4.8,5.5L312.6,70L311,86.6c0,0,0,0,0,0c-6.6,3.7-11.5,9.9-13.4,17.2
			c-0.7,2.8-1.3,5.4-1.4,6.6c0,0-2.2,19.8,0,36.3l3.4,27c0,0,1.8,6.3,3.5,10.7c1,2.6,3.5,4.3,6.2,4.3l43.4,0c0,0,3.7-0.4,6.3-2.5
			c1.5-1.2,3.4-1.7,5.2-1.7l40.5,0l40.5,0c1.9,0,3.8,0.5,5.2,1.7c2.6,2.1,6.3,2.5,6.3,2.5l43.4,0c2.8,0,5.3-1.7,6.2-4.3
			c1.7-4.5,3.5-10.7,3.5-10.7l3.4-27C515.4,130.2,513.2,110.4,513.2,110.4z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M486.8,82.8H322.6c-1.7,0-3-1.6-2.5-3.3
			l11.3-39.3c0.5-1.6,1.9-2.7,3.6-2.7h138.8c1.9,0,3.6,1.2,4.2,3L490,78.5C490.7,80.6,489.1,82.8,486.8,82.8z"/>
		<rect x="403.5" y="22.4" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="3.7" height="15.2"/>
		<rect x="335.3" y="23.3" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="1.9" height="14.3"/>
		<rect x="472.7" y="23.3" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="1.9" height="14.3"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M308.5,88.1c0,0,1.1,5.8,2.4,9.8
				c0.9,2.9,3.3,5.1,6.2,5.9c5.6,1.5,17.6,4.2,39.7,4.2h51.4"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M500.3,88.1c0,0-1.1,5.8-2.4,9.8
				c-0.9,2.9-3.3,5.1-6.2,5.9c-5.6,1.5-17.6,4.2-39.7,4.2h-51.4"/>
		</g>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M336.2,83.6c0,0,18.5,10.7,20.2,23.7"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M473.6,83.6c0,0-18.5,10.7-20.2,23.7"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M465.6,129.9c-2.4-5.2-9.2-13.1-12.8-17.1
			c-1.5-1.6-3.6-2.6-5.8-2.6h-41.6h-0.8h-41.6c-2.2,0-4.3,0.9-5.8,2.6c-3.6,4-10.4,12-12.8,17.1c-0.5,1-0.7,2.1-0.7,3.1
			c0,2.6,0.3,7.6,2.6,11.6c1.3,2.3,3.9,3.7,6.6,3.7h51.7h0.8h51.7c2.7,0,5.3-1.3,6.6-3.7c2.3-4,2.6-9,2.6-11.6
			C466.3,132,466.1,130.9,465.6,129.9z"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M341.8,188.7c0,0,10.5-17.7,15.8-23.3
				c0.9-0.9,2-1.4,3.3-1.4h44.3"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M467.1,188.7c0,0-10.5-17.7-15.8-23.3
				c-0.9-0.9-2-1.4-3.3-1.4h-44.3"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M357.6,187.1c0,0,1.9-4.4,6.5-9.9
				c0.7-0.9,1.8-1.4,2.9-1.4h39"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M454.6,188.3c0,0-3.3-5.5-7.9-11.1
				c-0.7-0.9-1.8-1.4-2.9-1.4h-39"/>
		</g>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M307.1,135.1v27.1l0,0c0.4,3.1,3,5.3,6.1,5.3
			h8.6c0,0,3.1-0.1,4-3.5c0,0,1.6-4.9-3.7-6.9c0,0-6.3-1.2-7.6-6.4v-13.8c0-1.7-1.3-3-3-3h-3.1C307.6,133.9,307.1,134.4,307.1,135.1
			z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M504.1,135.1v27.1l0,0c-0.4,3.1-3,5.3-6.1,5.3
			h-8.6c0,0-3.1-0.1-4-3.5c0,0-1.6-4.9,3.7-6.9c0,0,6.3-1.2,7.6-6.4v-13.8c0-1.7,1.3-3,3-3h3.1C503.6,133.9,504.1,134.4,504.1,135.1
			z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M356.4,108c0,0-13.4,15.9-17.3,18
			c-0.3,0.2-0.7,0.2-1,0.2l-25.7,0c-3.1,0-5.7-2.1-6.3-5.1c-0.2-1-0.4-2.2-0.6-3.7c0,0-0.5-10.9,6.7-16.9"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M453.2,108c0,0,13.4,15.9,17.3,18
			c0.3,0.2,0.7,0.2,1,0.2l25.7,0c3.1,0,5.7-2.1,6.3-5.1c0.2-1,0.4-2.2,0.6-3.7c0,0,0.5-10.9-6.7-16.9"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="357.1" y1="113.4" x2="453.2" y2="113.4"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="353.8" y1="116.4" x2="456.3" y2="116.4"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="351.7" y1="119.5" x2="458.5" y2="119.5"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="349" y1="122.5" x2="461.1" y2="122.5"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="346.3" y1="125.5" x2="463" y2="125.5"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="128.7" x2="465" y2="128.7"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="132" x2="466.3" y2="132"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="135.2" x2="466.3" y2="135.2"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.2" y1="138.4" x2="466.1" y2="138.4"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="141.6" x2="465" y2="141.6"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="346.3" y1="144.4" x2="463.7" y2="144.4"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M312,78.9c0,5.8-1.8,10.5-13.1,10.5
				c-10.7,0-19.8-4.2-20.8-9.6c-0.1-0.3-0.1-0.6-0.1-0.9c0-5.8,9.5-10.5,20.8-10.5S312,73,312,78.9z"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M498,78.9c0,5.8,1.8,10.5,13.1,10.5
				c10.7,0,19.8-4.2,20.8-9.6c0.1-0.3,0.1-0.6,0.1-0.9c0-5.8-9.5-10.5-20.8-10.5C499.9,68.3,498,73,498,78.9z"/>
		</g>
	</g>
</g>
<g id="Layer_3">
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M266.6,1113.9v64.3c0,0.8,0.7,1.5,1.5,1.5h41.2
		c0,0,0.2,0,0.4,0c3.2-0.1,5.6-0.9,5.6-4.2v-40.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M309,1180.1v35.2c0,2.3-2.6,4.2-5.8,4.2h-27.5
		c-3.2,0-5.8-1.9-5.8-4.2v-35.6"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M538.6,1113.9v64.3c0,0.8-0.7,1.5-1.5,1.5H496
		c0,0-0.2,0-0.4,0c-3.2-0.1-5.6-0.9-5.6-4.2v-40.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M496.3,1179.7v35.6c0,2.3,2.6,4.2,5.8,4.2h27.5
		c3.2,0,5.8-1.9,5.8-4.2v-35.6"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M315.3,1176.9c0,0,3.6,0.2,6.1-2.5
		c0.5-0.5,1.2-0.8,1.9-0.8h24.2c0,0,2,5.1,5.3,5.3h37.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M489.8,1176.9c0,0-3.6,0.2-6.1-2.5
		c-0.5-0.5-1.2-0.8-1.9-0.8h-24.2c0,0-2,5.1-5.3,5.3h-37.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M416,1178.4c-7.8,7.5-20.8,6-26.7,0.1"/>
	<line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="315.3" y1="1158" x2="490.1" y2="1158"/>
	<rect x="363.3" y="1146.8" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="77.3" height="11.2"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M525.1,1030.7l-3.6-16.8l-14.7-50.1
		c-5.1-13.7-17.8-13.7-17.8-13.7l-85.9-2v0l-0.5,0l-0.5,0l0,0l-85.9,2c0,0-12.7,0-17.8,13.7l-14.7,50.1l-3.6,16.8
		c0,0-13,11.9-15.8,33.8v31.1c0,5.8,0.7,11.6,2,17.3c1.8,7.5,4.4,18.1,6.9,24.3c0.7,1.9,2.6,3.1,4.6,3.1l16,0v-2.4
		c0-1.4,1.1-2.5,2.5-2.5h35.1c1.4,0,2.8,0.6,3.8,1.6l3.4,3.4h17.6h46.2h0.2h46h17.6l3.4-3.4c1-1,2.4-1.6,3.8-1.6h35.1
		c1.4,0,2.5,1.1,2.5,2.5v2.4l16,0c2,0,3.9-1.2,4.6-3.1c2.4-6.3,5.1-16.8,6.9-24.3c1.3-5.7,2-11.5,2-17.3v-31.1
		C538.1,1042.6,525.1,1030.7,525.1,1030.7z"/>
	<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="282.3" y1="1020.6" x2="523.1" y2="1020.6"/>
	<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="264.4" y1="1101.8" x2="540.5" y2="1101.8"/>
	<rect x="302.7" y="1020.6" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="199.6" height="81.2"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M285.6,1030.8c-1,0-2,0.5-2.7,1.3
		c-1.7,2.1-5,7.2-7.7,17.5c-0.3,1-0.4,2-0.4,2.9l0,33.3c0,0,0.3,2.4,2.2,2.7h22.8c0.6,0,1.1-0.5,1.1-1.1v-9.4c0-0.4-0.3-0.8-0.8-0.8
		h-14c-0.4,0-0.7-0.3-0.7-0.7v-13.8c0-0.5,0.4-0.9,0.9-0.9h13.6c0.5,0,0.9-0.4,0.9-0.9v-12c0-0.5-0.4-0.9-0.9-0.9h-12.1
		c-0.6,0-1-0.5-1-1v-6.5c0-0.5,0.4-1,1-1h12.3c0.5,0,0.9-0.4,0.9-0.9v-6.8c0-0.5-0.4-1-1-1H285.6z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M519.5,1030.8c1,0,2,0.5,2.7,1.3
		c1.7,2.1,5,7.2,7.7,17.5c0.3,1,0.4,2,0.4,2.9v33.3c0,0-0.3,2.4-2.2,2.7h-22.8c-0.6,0-1.1-0.5-1.1-1.1v-9.4c0-0.4,0.3-0.8,0.8-0.8
		h14c0.4,0,0.7-0.3,0.7-0.7v-13.8c0-0.5-0.4-0.9-0.9-0.9h-13.6c-0.5,0-0.9-0.4-0.9-0.9v-12c0-0.5,0.4-0.9,0.9-0.9h12.1
		c0.6,0,1-0.5,1-1v-6.5c0-0.5-0.4-1-1-1h-12.3c-0.5,0-0.9-0.4-0.9-0.9v-6.8c0-0.5,0.4-1,1-1H519.5z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M428.1,1037.1h-52.4c-0.2,0-0.3-0.1-0.3-0.3v-7
		c0-0.2,0.1-0.3,0.3-0.3h52.4c0.2,0,0.3,0.1,0.3,0.3v7C428.4,1037,428.3,1037.1,428.1,1037.1z"/>
	<rect x="306.8" y="1038" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="21.5" height="5.3"/>
	<rect x="476.4" y="1038" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="21.5" height="5.3"/>
	<rect x="278.2" y="1101.8" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="73.5" height="7.5"/>
	<rect x="453.2" y="1101.8" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="73.5" height="7.5"/>
	<rect x="351.9" y="1101.8" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="101.3" height="38.6"/>
	<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="302.7" y1="1087" x2="502.3" y2="1087"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M401.9,964.9"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M401.9,1025h-36.7c-0.8,0-1.5,0.7-1.5,1.5v9.2
		c0,0.4,0.1,0.8,0.4,1l17.4,18.7c0.3,0.3,0.7,0.5,1.1,0.5h19.2h19.2c0.4,0,0.8-0.2,1.1-0.5l17.4-18.7c0.3-0.3,0.4-0.7,0.4-1v-9.2
		c0-0.8-0.7-1.5-1.5-1.5H401.9z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M281.2,1022.5c0,6.6-3.6,12-14.6,12
		c-10.9,0-19.8-5.4-19.8-12s8.9-12,19.8-12C277.6,1010.6,281.2,1015.9,281.2,1022.5z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M523.5,1022.5c0,6.6,3.6,12,14.6,12
		s19.8-5.4,19.8-12s-8.9-12-19.8-12S523.5,1015.9,523.5,1022.5z"/>
	<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M498.2,966.9c-3.6-6.9-12.1-6.9-12.1-6.9h-83.5h-0.6h-83.5
		c0,0-8.6,0-12.1,6.9c0,0-4.1,10.6-7.7,21.3l-5.5,20c-0.6,2.2,0.9,4.4,3.2,4.6c0,0,0.1,0,0.1,0h105.6h0.6h106.6c0,0,0.1,0,0.1,0
		c2.3-0.2,3.7-2.5,3-4.7l-6.4-20C503.7,978.8,498.2,966.9,498.2,966.9z"/>
	<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M486.3,954.5H319c-1.1,0-1.9-1-1.4-1.8l1.1-2.3l84.5-2.3l83,2.3
		l1.5,1.8C488.5,953.2,487.7,954.5,486.3,954.5z"/>
</g>
<g id="Layer_4">
	<g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M55.7,332.6v-13.1c0,0,11.2-14.4,15.4-16.6
			V319h25.6c0,0,26.1,6.6,34.3,36.2v39.9c0,0,1,30-40.4,40.4l-15.8,3l4.9,242.1l0.4,21.7l4.5,30.3c0,0,21.5,0,40.2,13.7
			c0,0,12.6,9.4,15.5,22.2v48.9c0,0-1.6,14.4-22.4,28.3c0,0-15.3,6.3-27.8,6.3l7.3,51.6l-12.9,15.4l-6.8-3.9l6.8-13.5l-8.8-64.1
			l-8.5-96.6L55.7,423L55.7,332.6z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M71.2,319v-28.8c0-5.7,0.8-11.3,2.5-16.8c2.8-8.8,8.3-21,19-28.6
			l2.2,3.6h8.5v-3.6h4c0.3,0,0.7,0.1,0.9,0.3l8.1,6.8v12.1H156v-10.3c0,0,9.9,1.3,13.5,20.7c0,0,13.9,63.3,18.9,144.6
			c0,0-0.9,8.1,2.7,11.2c0,0,46.2,53.4,59.3,106c0,0,6.7,35.9,6.3,65.1l-0.3,96.1c0,0.6,0.1,4,0.1,4.7c-0.3,3.2,2.6,14.2-18.6,14.6
			l-44,1.8l1.8,215l-9,4.5H125v-16.6l-26.5-9.4L90,851.9c0,0,14.8-0.4,27.8-6.3c0,0,19.3-13,22.4-28.3v-48.9c0,0-7.2-32.8-55.7-35.9
			l-4.5-28.7l-5.4-265.3c0,0,56.1-4,56.6-47.6v-34.1c0,0-4.5-29.2-34.6-36.8L71.2,319z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M190.1,667.5l-8.8-205.6c0,0,4.2-10.2,13.8-1.2
			c0,0,24.7,17.9,39.3,68.6c2.8,9.7,4.6,19.6,5.5,29.6l1.9,20.3l0,104.5c0,0,0.3,6.9-7.5,8.7c0,0-21.2,6.9-41.4-17.6
			C191.2,672.7,190.2,670.1,190.1,667.5z"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="241" y1="673.5" x2="192.5" y2="673.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="241" y1="601.2" x2="187.2" y2="599"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="241" y1="579.8" x2="186.1" y2="573.6"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="241" y1="590.2" x2="186.7" y2="587"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M188.3,417.9c0,0-15.8-20.2-20-73.1c0,0-19-12.4-22.3-22
			c0,0-2.7-13,0.7-20.8c0,0,3.9-15.1-0.5-28.9c-0.3-0.9,0.3-1.8,1.3-1.9c2.2-0.1,5.9,0.3,9.3,3.6c1.8,1.7,2.9,4,3.6,6.3
			c2.4,8.4,9.6,36.9,8,63.7"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M175.3,387.2v83.7c0,0-1.5,12-6.3,16.8"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M186.7,587c0,0-36.3-3.7-108.2,1.6"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M175.3,442.2H125c0,0-20.7,0.4-33.1,9.9c-5.5,4.2-8.5,10.9-8.5,17.8
			l1,117.3"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M193.7,718.6c0,0-69.7-0.4-102-14.3c-2.1-0.9-3.6-2.8-4-5
			c-1.1-5.8-2.7-20.5-3-57.4c-0.4-56.6-0.2-53.6-0.2-53.6"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M132.6,287.8v6.7H99.4c0,0-5.8-0.4-7-4.9
			l0-7.4c0-0.9,0.5-1.7,1.2-2c1.6-0.8,4.2-1.2,8.2-1c1.8,0.1,3.4,1.1,4.1,2.7c0.5,1.1,0.9,2.6,1.1,4.8c0.1,0.8,0.7,1.4,1.5,1.4
			L132.6,287.8z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M156.3,254.6l-1.5-1.7
			c-0.5-0.5-1.1-0.8-1.8-0.8h-24.2c-0.7,0-1.3,0.4-1.5,1l-0.9,2.2h-9.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M164.5,575.9V546c0-1.2,1-2.2,2.2-2.2h3.3
			c1.2,0,2.2,1,2.2,2.2v29.9c0,1.2-1,2.2-2.2,2.2h-3.3C165.5,578.1,164.5,577.1,164.5,575.9z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M168.3,695.4v-29.9c0-1.2,1-2.2,2.2-2.2h3.3
			c1.2,0,2.2,1,2.2,2.2v29.9c0,1.2-1,2.2-2.2,2.2h-3.3C169.3,697.6,168.3,696.6,168.3,695.4z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M185.6,938.1v-3.9c0-1.3-0.6-2.5-1.7-3.2
			l-33-22.3c-1.4-0.9-2.9-1.4-4.6-1.4h-5.9c0,0-3.6,0.6-4.6,3.7v27.1H185.6z"/>
		<polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="118.4,942.8 118.2,918.9 124.8,921.3 
			124.8,942.8 		"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M117.8,942.8H102c0,0-0.1,0-0.2,0
			c-5.6-0.5-9.9-5.3-9.9-10.9l0-19.8h6.7l19.2,7.4V942.8z"/>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="69.6" cy="789.6" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="69.6" cy="789.6" r="25.7"/>
		</g>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="71.2" cy="377.8" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="71.2" cy="377.8" r="25.7"/>
		</g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M191.1,487.7c-5.9,0-10.7-10.2-10.7-22.8
			c0-12.6,4.8-10.1,10.7-10.1c5.9,0,10.7-2.5,10.7,10.1C201.8,477.5,197,487.7,191.1,487.7z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M256.3,705.8c0,0-0.9,3.8-0.9,3.8c-3,3.7-5.7,5.4-11.9,6.7
			l-49.7,2.2l1.8,215c0,0,61.1-9.4,61.1-48.2C256.6,842.2,256.3,705.8,256.3,705.8z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M256.6,882.5V902c0,0-0.4,2.3-1.6,3L256.6,882.5z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M248.1,729.7l-47.7,0.9l1.3,179.8c0,3.7,3.3,6.4,6.7,5.4
			c12.9-3.5,39.9-13.8,39.9-38.2C248.3,842.1,248.1,729.7,248.1,729.7"/>
	</g>
	<g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M762.7,332.6v-13.1c0,0-11.2-14.4-15.4-16.6
			V319h-25.6c0,0-26.1,6.6-34.3,36.2v39.9c0,0-1,30,40.4,40.4l15.8,3l-4.9,242.1l-0.4,21.7l-4.5,30.3c0,0-21.5,0-40.2,13.7
			c0,0-12.6,9.4-15.5,22.2v48.9c0,0,1.6,14.4,22.4,28.3c0,0,15.3,6.3,27.8,6.3l-7.3,51.6l12.9,15.4l6.8-3.9l-6.8-13.5l8.8-64.1
			l8.5-96.6L762.7,423V332.6z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M747.3,319v-28.8c0-5.7-0.8-11.3-2.5-16.8c-2.8-8.8-8.3-21-19-28.6
			l-2.2,3.6H715v-3.6h-4c-0.3,0-0.7,0.1-0.9,0.3l-8.1,6.8v12.1h-39.5v-10.3c0,0-9.9,1.3-13.5,20.7c0,0-13.9,63.3-18.9,144.6
			c0,0,0.9,8.1-2.7,11.2c0,0-46.2,53.4-59.3,106c0,0-6.7,35.9-6.3,65.1l0.3,96.1c0,0.6-0.1,4-0.1,4.7c0.3,3.2-2.6,14.2,18.6,14.6
			l44,1.8l-1.8,215l9,4.5h61.5v-16.6l26.5-9.4l8.5-60.2c0,0-14.8-0.4-27.8-6.3c0,0-19.3-13-22.4-28.3v-48.9c0,0,7.2-32.8,55.7-35.9
			l4.5-28.7l5.4-265.3c0,0-56.1-4-56.6-47.6v-34.1c0,0,4.5-29.2,34.6-36.8L747.3,319z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M628.3,667.5l8.8-205.6c0,0-4.2-10.2-13.8-1.2
			c0,0-24.7,17.9-39.3,68.6c-2.8,9.7-4.6,19.6-5.5,29.6l-1.9,20.3l0,104.5c0,0-0.3,6.9,7.5,8.7c0,0,21.2,6.9,41.4-17.6
			C627.3,672.7,628.2,670.1,628.3,667.5z"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="577.4" y1="673.5" x2="625.9" y2="673.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="577.4" y1="601.2" x2="631.3" y2="599"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="577.4" y1="579.8" x2="632.4" y2="573.6"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="577.4" y1="590.2" x2="631.8" y2="587"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M630.2,417.9c0,0,15.8-20.2,20-73.1c0,0,19-12.4,22.3-22
			c0,0,2.7-13-0.7-20.8c0,0-3.9-15.1,0.5-28.9c0.3-0.9-0.3-1.8-1.3-1.9c-2.2-0.1-5.9,0.3-9.3,3.6c-1.8,1.7-2.9,4-3.6,6.3
			c-2.4,8.4-9.6,36.9-8,63.7"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M643.1,387.2v83.7c0,0,1.5,12,6.3,16.8"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M631.8,587c0,0,36.3-3.7,108.2,1.6"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M643.1,442.2h50.3c0,0,20.7,0.4,33.1,9.9c5.5,4.2,8.5,10.9,8.5,17.8
			l-1,117.3"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M624.7,718.6c0,0,69.7-0.4,102-14.3c2.1-0.9,3.6-2.8,4-5
			c1.1-5.8,2.7-20.5,3-57.4c0.4-56.6,0.2-53.6,0.2-53.6"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M685.9,287.8v6.7H719c0,0,5.8-0.4,7-4.9v-7.4
			c0-0.9-0.5-1.7-1.2-2c-1.6-0.8-4.2-1.2-8.2-1c-1.8,0.1-3.4,1.1-4.1,2.7c-0.5,1.1-0.9,2.6-1.1,4.8c-0.1,0.8-0.7,1.4-1.5,1.4
			L685.9,287.8z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M662.2,254.6l1.5-1.7c0.5-0.5,1.1-0.8,1.8-0.8
			h24.2c0.7,0,1.3,0.4,1.5,1l0.9,2.2h9.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M653.9,575.9V546c0-1.2-1-2.2-2.2-2.2h-3.3
			c-1.2,0-2.2,1-2.2,2.2v29.9c0,1.2,1,2.2,2.2,2.2h3.3C652.9,578.1,653.9,577.1,653.9,575.9z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M650.1,695.4v-29.9c0-1.2-1-2.2-2.2-2.2h-3.3
			c-1.2,0-2.2,1-2.2,2.2v29.9c0,1.2,1,2.2,2.2,2.2h3.3C649.1,697.6,650.1,696.6,650.1,695.4z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M632.8,938.1v-3.9c0-1.3,0.6-2.5,1.7-3.2
			l33-22.3c1.4-0.9,2.9-1.4,4.6-1.4h5.9c0,0,3.6,0.6,4.6,3.7v27.1H632.8z"/>
		<polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="700.1,942.8 700.2,918.9 693.7,921.3 
			693.7,942.8 		"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M700.7,942.8h15.8c0,0,0.1,0,0.2,0
			c5.6-0.5,9.9-5.3,9.9-10.9l0-19.8h-6.7l-19.2,7.4V942.8z"/>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="748.8" cy="789.6" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="748.8" cy="789.6" r="25.7"/>
		</g>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="747.3" cy="377.8" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="747.3" cy="377.8" r="25.7"/>
		</g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M627.4,487.7c5.9,0,10.7-10.2,10.7-22.8
			c0-12.6-4.8-10.1-10.7-10.1s-10.7-2.5-10.7,10.1C616.6,477.5,621.4,487.7,627.4,487.7z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M562.1,705.8c0,0,0.9,3.8,0.9,3.8c3,3.7,5.7,5.4,11.9,6.7l49.7,2.2
			l-1.8,215c0,0-61.1-9.4-61.1-48.2C561.9,842.2,562.1,705.8,562.1,705.8z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M561.9,882.5V902c0,0,0.4,2.3,1.6,3L561.9,882.5z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M570.4,729.7l47.7,0.9l-1.3,179.8c0,3.7-3.3,6.4-6.7,5.4
			c-12.9-3.5-39.9-13.8-39.9-38.2C570.2,842.1,570.4,729.7,570.4,729.7"/>
	</g>
	<path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M166.7,746.6h-12c-1.3,0-2.3-1-2.3-2.3v-15.8c0-1.3,1-2.3,2.3-2.3
		h12c1.3,0,2.3,1,2.3,2.3v15.8C169,745.5,168,746.6,166.7,746.6z"/>
</g>
      </>
    ),
  },
};
