import "./index.scss";

import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  Grid,
  Theme,
  createStyles,
  makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GET_BOOKING } from "../../../graphql/bookings/getBookingQuery";
import {
  BookingStatus,
  IBooking,
} from "../../../reducers/bookings/types";
import { IAppState } from "../../../store";
import {
  BOOKING_STATUS,
  CHECK_TYPE,
  VEHICLE_STATUS,
} from "./const";
import styles from './index.module.css'
import Container from "@material-ui/core/Container/Container";
import { TopBar } from '../../TopBar/TopBar'
import Hidden from '@material-ui/core/Hidden';
import { IBranch } from "../../../reducers/website/types";
import BookingSummary from '../CustomerPortal/BookingManagement/BookingManagement';
import { getLocalizedBookingSyntex } from "../../../utils/localized.syntex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldtext: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    button: {
      width: "100%"
    },
    buttonEnabled: {
      backgroundColor: "#faaf40",
      color: "white",
      width: "100px"
    },
    content: {
      padding: "50px"
    },
    details: {
      color: "#000000",
      fontSize: 16
    },
    header: {},
    para: {
      fontSize: "16px"
    },
    pdf: {},
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    space: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    subtitle: {
      color: "#2a9cb7",
      fontSize: "16px",
      marginBottom: "0px"
    },
    tags: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    title: {
      color: "#faaf40",
      fontSize: "16px",
      fontWeight: "bold",
      width: "100%"
    },
    tableCellBorder: {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    image: {
      width: "76px",
      height: "47px"
    }
  })
);

const ViewBooking: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [booking, setBooking] = useState<IBooking>();
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);       //TODO from API
  const country = organisation!.address.country
  const branchData = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches);
  const [currentTimeZone, setCurrentTimeZone] = useState<string>("Europe/london");
  const [currentBranch, setCurrentBranch] = useState<IBranch>();
  const [
    loadBooking,
    { data: bookingData, loading: bookingDataLoading }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const bookingId = params.get("id");
    if (bookingId) {
      return loadBooking({
        variables: {
          bookingId: bookingId
        }
      });
    }
  }, [location]);

  useEffect(() => {
    if (bookingData && bookingData.consumerGetBooking && branchData) {
      if (bookingData.consumerGetBooking?.status === BookingStatus.CONFIRMATION_IN_PROCESS || bookingData.consumerGetBooking?.status === BookingStatus.QUOTE) {
        setTimeout(() => {
          return loadBooking({
            variables: {
              bookingId: bookingData.consumerGetBooking.id
            }
          });
        }, 30000);
      } else {
        setBooking(bookingData.consumerGetBooking);
      }
      setTimeZone(bookingData.consumerGetBooking.branchId);
      const selectedBranch = branchData.find((branch) => branch.id === bookingData.consumerGetBooking.branchId);
      selectedBranch && setCurrentBranch(selectedBranch)
    }
  }, [bookingData, branchData]);
  const setTimeZone = (branchId: string) => {
    const branch: any = branchData.find(singlebranch => singlebranch.id === branchId);
    setCurrentTimeZone(branch?.timeZone);
  };
  const ifPCONumberExists = () => {
    let flag = false;
    if (booking && booking.vehicleGroups && booking.vehicleGroups.length) {
      booking.vehicleGroups.forEach((vg: any) => {
        if (vg.activeVehicleSchedules && vg.activeVehicleSchedules.length) {
          vg.activeVehicleSchedules.forEach((schedule: any) => {
            if (schedule.vehicle && schedule.vehicle.pcoNumber) {
              flag = true;
            }
          });
        }
      });
    }
    return flag;
  };

  const isVehicleChecked = (vehicleStatus: string) => {
    let flag: boolean = false;
    let checkType = CHECK_TYPE.DELIVERY;
    if (booking?.status === BOOKING_STATUS.CONFIRMED) {
      if (vehicleStatus === VEHICLE_STATUS.DROPOFF_CHECK_COMPLETE) {
        flag = true;
      }
    } else if (booking?.status === BOOKING_STATUS.IN_PROGRESS) {
      checkType = CHECK_TYPE.COLLECTION;
      if (vehicleStatus === VEHICLE_STATUS.PICKUP_CHECK_COMPLETE) {
        flag = true;
      }
    }
    const resObj = {
      checkType,
      checked: flag
    };
    return resObj;
  };
  if (!organisation) {
    return (
      <Grid item xs={12}>
        Unable to load company details
      </Grid>
    );
  }

  if (!booking || bookingDataLoading) {
    return (
      <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (booking?.status === BookingStatus.CONFIRMATION_IN_PROCESS || booking?.status === BookingStatus.QUOTE) {
    return (
      <div className="flex col-flex cross-center loading-message">
        <h1>Booking Confirmation in progress...</h1>
        <h3 className="text-grey">Please do not hit back, cancel or close the browser. This process may take upto 2 mins..</h3>
        <span><CircularProgress /></span>
      </div>
    );
  }

  return (
    <div className={styles.appBody}>
      <TopBar
        height={70}
        logoSize={44}
      />
      <Container>
        <div className={styles.actionField}>
          <div className={styles.container}>
            <h4 className={styles.header}>{getLocalizedBookingSyntex(country)} Summary</h4>
            <div style={{ background: "#fff", padding: 30 }}>
              <BookingSummary booking={booking} />
            </div>
          </div>
          <div className={styles.bottomBar}>
            <Hidden smDown>
              <div className="flex fill"></div>
            </Hidden>
            <button
              onClick={() => {
                history.push(`/`);
              }}
              className={styles.prevStepButton}
              type="button"
            >
              {`New ${getLocalizedBookingSyntex(country)}`}
            </button>
          </div>
        </div >
      </Container >
    </div >
  );
};

export default ViewBooking;