import React from "react"
export const SUVSvg = {
    interior: {
      width:305,
      height:691,
      svg: (<>
      <g>
      <g>
        <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M153.2,10.8c0,0,43,4,65,8c0,0,27,5,45,20c0,0,18,20,22,34l5,22
          l3,3.8l-2,137.2v189l1,97l1,54.1c0,0-2,19.9-4,36.9c0,0-9,32-24,46c0,0-43,21-108,22"/>
        <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M153.2,10.8c0,0-41.9,4-63.4,8c0,0-26.3,5-43.9,20
          c0,0-17.5,20-21.4,34l-4.9,22l-2.9,3.8l1.9,137.2v189l-1,97l-1,54.1c0,0,1.9,19.9,3.9,36.9c0,0,8.8,32,23.4,46
          c0,0,29.2,19,113.2,22"/>
      </g>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M77.2,25.8c0,0-23,5-37,27c0,0-10,12-10,55l-6,72
        v102.5"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M222.2,23.8c0,0,26,3.8,44.9,25.7
        c0.5,0.6,1.1,1.2,1.6,1.8c2.3,2.5,11.4,14.9,10.5,52.5l5,74v102.5"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M115.2,29.8l-7,1.9l-12,3.2c0,0-4,0.8-14,8.8
        c0,0-10,11-18,29l-14,39.7l-8,32.3c0,0-5,22-5,36V209l17,68.8l5,31l5,38l3,68l2,134v24"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M193.2,29.8l8,2.5l12,3.2c0,0,6,2.3,14,8.8
        c0,0,10,11,18,29l14,39.7l8,32.3c0,0,3,22.5,4,36.5l-1,19c-0.2,5.8-1,12-1.9,17.5l-13.1,60l-5,31l-5,38l-3,68l-2,134v24"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M112.2,34.8c0,0-21,89-24,121
        C88.2,155.8,92.2,87.8,112.2,34.8z"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M189.2,34.8c0,0,21,89,24,121
        C213.2,155.8,209.2,87.8,189.2,34.8z"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M42.2,195.8"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M266.2,195.8c0,0,0-7-2-12c0,0-7-12-20-17
        s-25-8-38-11c0,0-27-4.5-53-4.7l-31.1,1c0,0-30.9,4.8-40.9,8.8l-23,9c0,0-9,5-13,13c0,0-3,6.8-3,13.9c0,0,10-11.9,27-16.9
        c0,0,20-7,42-10c0,0,28-3.5,42-3.2c0,0,28,0.2,50,3.2C203.2,169.8,244.2,174.8,266.2,195.8z"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M185.6,195.6H233c0,0,6.9,1.2,9.7,12.1l0.4,27.2
          c0,0-3.6,15.6-13.3,20.5h-42.7c0,0-8.7-6-12.5-20.3v-27.9C174.7,207.3,178.2,197,185.6,195.6z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M244.1,250.8l-1-1.7h-6l-4,4l-4.7,2.2l-19.8-0.2v-0.1l-20.2,0.6
          l-4.3-3l-3.2-3.9l-6.7,0.3l-1.9,3l-1,7.9v24.2l0.8,6.2l2.5,4.3l3.1,2.7l4.8,2.1h6.7h2.9l0.2,1.8h16.3v0.1h16.3l0.2-1.8h2.9h6.7
          l4.8-2.1l3.1-2.7l2.5-4.3l0.8-6.2v-24.2l-0.8-6.3C245,252.7,244.7,251.7,244.1,250.8z"/>
        <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="208.6,283.3 188.3,283.3 188.3,299.3 192.1,303 208.6,303 
          225.2,303 229,299.3 229,283.3 		"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M74.6,197.6H122c0,0,6.9,1.2,9.7,12.1l0.4,27.2
          c0,0-3.6,15.6-13.3,20.5H76.2c0,0-8.7-6-12.5-20.3v-27.9C63.7,209.3,67.2,199,74.6,197.6z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M133.1,252.8l-1-1.7h-6l-4,4l-4.7,2.2l-19.8-0.2v-0.1l-20.2,0.6
          l-4.3-3l-3.2-3.9l-6.7,0.3l-1.9,3l-1,7.9v24.2l0.8,6.2l2.5,4.3l3.1,2.7l4.8,2.1h6.7h2.9l0.2,1.8h16.3v0.1h16.3l0.2-1.8h2.9h6.7
          l4.8-2.1l3.1-2.7l2.5-4.3l0.8-6.2v-24.2l-0.8-6.3C134,254.7,133.7,253.7,133.1,252.8z"/>
        <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="97.6,285.3 77.3,285.3 77.3,301.3 81.1,305 97.6,305 
          114.2,305 118,301.3 118,285.3 		"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M235.7,469.8l-2.4-1.7h-13.6l-9.1,4l-10.8,2.2l-45.2-0.2v-0.1
          l-46.2,0.6l-9.8-3l-7.3-3.9L76,468.1l-4.3,3l-2.4,7.9v24.2l1.9,6.2l5.7,4.3l7.2,2.7l11,2.1h15.3h6.6l0.5,1.8h37.2v0.1h37.2
          l0.5-1.8h6.6h15.3l11-2.1l7.2-2.7l5.7-4.3l1.9-6.2v-24.2l-1.9-6.3C237.8,471.7,237,470.7,235.7,469.8z"/>
        <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="104.5,518.5 91.5,518.5 91,532.8 93.5,536 104.5,536 
          115.4,536 118,532.8 117.5,518.5 		"/>
        <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="205.5,518.5 192.5,518.5 192,532.8 194.5,536 205.5,536 
          216.4,536 219,532.8 218.5,518.5 		"/>
        <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M232.5,475.5H75.5c-2.8,0-5-2.3-5-5v-60.9c0-2.8,2.3-5,5-5h156.9
          c2.8,0,5,2.3,5,5v60.9C237.5,473.2,235.2,475.5,232.5,475.5z"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M143.5,291.9c0,3.4,1.8,6.1,3.9,6.1h13.2c2.1,0,3.9-2.7,3.9-6.1
        V166.5h-21V291.9z"/>
      <g>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M154.2,573.8h86c0,0,6,1,7,5c0,0,2,3-1,8
          c0,0-9,15-46,21c0,0-27,4.7-43,4.8h-3"/>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M154.9,573.8h-86c0,0-6,1-7,5c0,0-2,3,1,8
          c0,0,9,15,46,21c0,0,27,4.7,43,4.8h3"/>
      </g>
      <g>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M34.2,208.8l16,89c0,0,7,34,9,83v104.6l-3,65.4
          c0,0-0.4,6.3-1.1,11.7c-0.3,2.3-3.6,2.6-4.2,0.4c-2-6.5-4.9-22.4-6.6-36.1l-5-50l-3-79l-2-108.5l-1-44.5v-30
          C33.2,214.8,32.2,207.8,34.2,208.8z"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="37.2" y1="398" x2="59.2" y2="400.5"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="59.2" y1="382.5" x2="35.8" y2="371.3"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="42.2" y1="254.7" x2="33" y2="248.7"/>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M59.2,486.8c0,0-2.3,8.7-4.7,12.3
          c-1.1,1.7-3,2.8-5.1,3c-2.6,0.2-6.3,0.2-7.8-1.1"/>
      </g>
      <g>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M273.2,208.8l-16,89c0,0-7,34-9,83v104.6
          l3,65.4c0,0,0.4,6.3,1.1,11.7c0.3,2.3,3.6,2.6,4.2,0.4c2-6.5,4.9-22.4,6.6-36.1l5-50l3-79l2-108.5l1-44.5v-30
          C274.2,214.8,275.2,207.8,273.2,208.8z"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="270.2" y1="398" x2="248.2" y2="400.5"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="248.2" y1="382.5" x2="271.7" y2="371.3"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="265.2" y1="254.7" x2="274.5" y2="248.7"/>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M248.2,486.8c0,0,2.3,8.7,4.7,12.3
          c1.1,1.7,3,2.8,5.1,3c2.6,0.2,6.3,0.2,7.8-1.1"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M61.2,582.4c0,0-5,38.3-3,49.3c0,0,0,8.3,3,11.2
        c0,0,24,18.8,65,24.8c0,0,48,3,65-2c0,0,34-7.3,53-21.6c0,0,6-4.4,7-11.4v-28.1l-3.3-23.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.9,586.8c0,0-1.7,41.9-1.7,44c0,0,2,7,20,15c0,0,28,11,55,14
        c0,0,24,1,48-2c0,0,31-6,42-11c0,0,13-6,19-13c0,0,3.3-7.8,2.7-10.4l-1.7-36.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M81.2,35.8c0,0-36,30.2-44,81.1l-7.2-6.9c0,0,0.2-33.1,9.2-51.1
        c0,0,11-18,28.9-24l9.1-1.1C77.2,33.7,81.2,33.8,81.2,35.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M228.1,35.8c0,0,36,30.2,44,81.1l7.2-6.9c0,0-0.2-33.1-9.2-51.1
        c0,0-11-18-28.9-24l-9.1-1.1C232.1,33.7,228.1,33.8,228.1,35.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M102.6,29.8c0,0-19.3,3.9-21.3,5.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M76.2,28.8c0,0-5,2-10,7"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M206.2,29.8c0,0,20,3.9,22,5.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M223.2,25.8c0,0,9,1,18,9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M278.6,581.2c0.6,2.8,1.7,10.1,1.7,25.6c0,0,0.8,10.6-4,25.9
        c-1.1,3.4-2.9,6.6-5.2,9.3c-3.8,4.5-10.4,11.2-16.8,13.8v-11c0,0,12.1-5.8,15.1-21.4c0,0,4.4-15.8,5.2-23.2c0,0,1.8-14.4,3-19
        C277.6,580.5,278.5,580.6,278.6,581.2z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M29.9,581.2c-0.6,2.8-1.7,10.1-1.7,25.6c0,0-0.8,10.6,4,25.9
        c1.1,3.4,2.9,6.6,5.2,9.3c3.8,4.5,10.4,11.2,16.8,13.8v-11c0,0-12.1-5.8-15.1-21.4c0,0-4.4-15.8-5.2-23.2c0,0-1.8-14.4-3-19
        C30.9,580.5,30,580.6,29.9,581.2z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M54.2,650.8c0,0,93,46,200-1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="30.2" y1="580.8" x2="25.2" y2="450.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M17.7,521.8c0,0,3.3,11.2,7.8,16.6c1.6,1.9,4.3,2.5,6.5,1.4
        c2.6-1.3,6.6-4.5,12.1-11.8c0.2-0.2,0.3-0.5,0.4-0.7l14.7-26.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="35.8" y1="383.7" x2="18.7" y2="380.6"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="23.2" cy="500.3" rx="2" ry="9.5"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="23.2" cy="354.3" rx="2" ry="9.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M20.2,252.8v84.1l3,7.1c0,0,5,9.9,0,19.9c0,0-1,4-2,7.5v51.9v61.1
        l2,6.5c0,0,5,6,0,19v8.3c0,0.8-0.3,1.6-0.9,2.1c-0.8,0.8-2.4,1.2-5.1-0.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="278.2" y1="580.8" x2="283.2" y2="447.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M290.8,520.8c0,0-3.3,11.2-7.8,16.6c-1.6,1.9-4.3,2.5-6.5,1.4
        c-2.6-1.3-6.6-4.5-12.1-11.8c-0.2-0.2-0.3-0.5-0.4-0.7l-14.7-26.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="272.7" y1="382.7" x2="289.8" y2="379.6"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="285.2" cy="499.3" rx="2" ry="9.5"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="285.2" cy="353.3" rx="2" ry="9.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M288.2,251.8v84.1l-3,7.1c0,0-5,9.9,0,19.9c0,0,1,4,2,7.5v51.9v61.1
        l-2,6.5c0,0-5,6,0,19v8.3c0,0.8,0.3,1.6,0.9,2.1c0.8,0.8,2.4,1.2,5.1-0.4"/>
      <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M219,18.9l6.3,8.9c1,2-1.5,2.2-1.9,2c-0.1,0-0.2,0-0.3,0H77.2
        c-1,0-1-2-1-2l4.6-6.7c0,0,16.1-4.2,31.8-5.8l40.7-4.6l36,3.9C189.3,14.6,211.7,17.1,219,18.9z"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="115.2" y1="14.8" x2="187.2" y2="14.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="96.2" y1="17.8" x2="213.2" y2="17.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="85.7" y1="20.8" x2="219.7" y2="20.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="79.2" y1="23.8" x2="222.2" y2="23.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="77.2" y1="26.8" x2="224.2" y2="26.8"/>
    </g>
     </>)
    },
    exterior: {
      width:854,
      height:1253,
      svg: (<>
      <g>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M795.9,309.4c0-0.9-2.2-2.9-2.8-3.6c-1.2-1.5-2.4-3.2-2.9-5
        c-0.7-2.5,0.4-5.3-0.4-7.9c-1.3-4.1-6.4-7.5-10.5-7.5H759l-2,4c-5.2,1.6-10.3,3.2-15.5,4.8c-6.2,1.9-12.4,3.8-18.6,5.7
        c-1.6,0.4-3.1,1-4.7,1.4c-1.2,0.3-2.4,0.3-3.6,0.3c-2.3-0.1-4.7-0.7-7-1c-3.5-0.5-3.8,0.9-5.6,3.7c-1.1,1.7-2.2,3.5-3.2,5.2
        c-2.1,3.6-4,7.3-5.7,11.1c-3.4,7.4-6.2,15.2-8.5,23.1c-6.2,21.3-9.4,43.3-12.4,65.2c-1.8,12.8-3.5,25.6-5.3,38.4l-1,13
        c0,6-8,19-8,19c-0.8,1.5-1.6,2.9-2.4,4.3c-6.6,11.8-13.2,23.5-19.8,35.3c-10.3,18.4-18.5,38-26.3,57.6c-0.5,1-5,10.3-4.5,10.8
        c-1.1-1.1-2.3-2.9-3.6-3.6c-2.3-1.2-2.9,1.4-3.2,3.3c-1,5.7-1.4,11.5-2,17.2c-1.2,11.4-2.2,22.9-3,34.4
        c-1.8,27.4-2.4,54.8-1.7,82.2s2.6,54.8,5.8,82c1.6,13.6,3.5,27.2,5.7,40.7c0.5,3.4,7.4,40,7.5,40c5.2-1.3,9-6,10.8-11
        c1-2.8,1.5-5.8,1.7-8.8c0.1-1.4-1.5-8.7,0.9-8.3c1.7,0.3,5,7.3,6,8.7c4.2,5.7,8.2,11.5,12.1,17.4c3.6,5.3,7.1,10.6,10.6,16
        c3.1,4.7,7.3,9.4,8.7,14.8c1.3,5,1.5,10.3,2.2,15.4c0.3,2.5,0.7,5.1,1.5,7.5c0.2,0.8,2,6.3,2.9,6.2c7.4-0.6,14.9,0.3,21.9,2.6
        c0-1.8,0-3.7,0-5.5c4.6-0.2,9.6,1,14.2,1.5c4.3,0.4,8.6,0.9,12.9,1.4c3,0,10,4,10,4h18l2.8,6c2.9-0.6,5.9-0.8,8.8-1.4
        c3-0.6,6.1-1.1,9,0.1c0.8,0.3,1.5,0.7,2.3,1s1.7,0.4,2.6,0.4c4.3,0.2,8.7-0.7,12.6-2.4c3.3-1.5,5.2-3.7,7.9-5.7
        c2.1-1.5,3.2-3.9,3.7-6.3c0.2-0.7,0-2,0.3-2.6c0.2-0.6,3-2.2,3-2.1c0-6.3,0-12.6,0-18.9c0-11.3,0-22.7,0-34.1c-70-3-67-60-67-60
        c-1-65,69-64,69-64v-277c-71,3-71-60-71-60c0-64,67-63,67-63S795.8,309.4,795.9,309.4z"/>
      <g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M783.8,490.4c-42-2-69-35-69-72c0-40.5,31-71,66-75"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M799.8,743.7l-22,0.7l-3.2,1.1c-2,0.6-3.9,1.4-5.7,2.3
          c-37.8,18.4-51.1,36.6-51.1,70.6c0,36,30,76,79,75"/>
        <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="795.8" y1="343.4" x2="780.8" y2="343.4"/>
        <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="799.8" y1="490.4" x2="783.8" y2="490.4"/>
      </g>
      <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="780.8" y1="357.4" x2="780.8" y2="285.4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M650.7,799.4l16.2,11.1c3.2,2.2,7.4,2.2,10.6,0.1
        c0.2-0.1,0.3-0.2,0.3-0.2l22-13l26-19l40.8-29.5c9.8-7.5,13.2-14.5,13.2-14.5c3-6,3-23,3-23l1-233.5"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M689.9,381.4c-1,8.1-2,110-2,110l2,51c0,10-1,94-1,94l-3,53
        c-1,17-6,50-6,50l-5,43.7c0,0.2-0.1,0.4-0.1,0.6c-2,11.5-3,61.7-3,61.7c0,12,8,96,8,96"/>
      <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="706.8" y1="354.4" x2="731.8" y2="397.4"/>
      <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="695.8" y1="904.8" x2="737.8" y2="848"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M719.9,936.4c-2.5-9.2-12.5-27.5-15.9-33.2c-0.3-0.4,0.2-0.8,0.6-0.5
        c3.7,3.3,8.6,10,10.9,13.4c1,1.4,1.9,2.9,2.7,4.4c5.2,9.5,8.6,19.7,9.6,23.9c0.1,0.6-1.7,0.5-2,0c-12-18.9-25-17-25-17"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M747.8,951.4c0.8-16.8-3.3-23.7-4.6-25.5c-0.1-0.1-0.2,0-0.2,0.1
        c1.8,5.5,1.8,19.4,1.8,19.4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M763.8,949.5c-1-4,2-8.1,2-8.1v-6.7c0-2.5-1-4.9-2.9-6.5
        c-2.1-1.8-4.6-3.1-5.9-3.5c-0.9-0.3-1.7-0.6-2.5-1.1l-0.4-0.2v-57"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M765.7,941.4c-2.1-1.1-3.8-2.1-5.1-3.1c-3.3-2.4-5.5-6.1-6-10.1
        c-0.3-2.5-0.5-4.8-0.5-4.8"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M781,292.3c4,1,6,5.4,6,5.4v19.6c0,1.1-0.3,2.2-0.6,3.1
        c-0.6,1.5-1.4,2.9-2.4,4.1l-3.2,3.8c-0.5,0.7-1.4,0.9-2.2,0.6l-25.7-9.5c-0.3-0.1-0.5-0.2-0.8-0.3c-5.8-2.5-8.2-9.3-5.6-15
        c1.6-3.6,4.5-7.1,7.9-9.5l4.4-3.2c13,5,13,11.6,13,11.6v21.5l-7-3l-9-3.8c-3.5-1.4-5.9-4.7-6.1-8.5c-0.1-1.6,0.1-2.7,0.1-2.7
        c0.8-4.8,6.1-11.7,8.2-14.2c0.5-0.7,0.8-1.5,0.8-2.4v-4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M760.2,765.5l3.6-116l6-99c0-12,4-17,4-17l9.8-18.3"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M760.7,748c4.9-2.5,15.1-12.6,15.1-12.6c3-3,4-19.8,4-19.8v-63.2
        l0.8-131.7"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M783.8,494.8l-47.7-4.4c-21.6-1-48.3,1-48.3,1l-12,5"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M783.6,651.9l-96.8,1.5c-6,0-19,2.1-19,2.1"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M604.8,587.4c-11.8,119-0.2,216.5-0.2,216.5
        c1.8,12.5,9.2,61.5,9.2,61.5l9-7"/>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="791.8" cy="416.9" rx="53" ry="53.5"/>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="792.2" cy="417.4" rx="36.6" ry="37"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="791.8" cy="818.9" rx="53" ry="53.5"/>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="792.2" cy="819.4" rx="36.6" ry="37"/>
      </g>
      <path fill="#F9F7F7" stroke="#020202" stroke-miterlimit="10" d="M718,306.2c2.7,1.2,2.7,5.2,2.7,5.2c0,6-1,12-1,12
        c-0.7,5.5-3.5,12.9-5.1,17c-0.8,1.8-1.7,3.6-2.7,5.3l-13.2,21.7l-15,24c1.9-39.6,10-57.8,10.9-59.8c0.1-0.1,0.2-0.3,0.2-0.4
        c3.3-9.8,6.8-16,9.6-19.8c2.5-3.5,6.7-5.5,11-5.5h1.2C717.1,305.9,717.6,306,718,306.2z"/>
      <path fill="#F9F7F7" stroke="#020202" stroke-miterlimit="10" d="M695.8,935.4c2.2-1,3.5-3.8,4.2-6c0.6-2.1,0.8-4.3,0.4-6.4
        c-0.8-5.2-3-13.3-3.5-15.2c-0.1-0.3-0.2-0.5-0.3-0.8l-11.9-33.5l-7.5-18.8c-0.5-1.1-1.1-2.3-2-3.2c-0.2-0.2-0.4-0.4-0.6-0.6
        c-2-1.9-5.3-0.7-5.8,2c-0.1,0.9-0.1,1.6-0.1,1.6l-0.9,38.8c0,1.6,0,3.2,0.2,4.8c1,10.1,3.4,18.8,4.4,22c0.3,0.8,0.5,1.6,0.9,2.4
        c5,11.1,13.5,13.9,13.5,13.9C691.8,938.4,695.8,935.5,695.8,935.4z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M771.7,316.2V313c0-0.4-0.4-0.9-0.9-1.3c-0.6-0.5-1.3-0.8-2.1-0.8
        h-15c-0.2,0-0.5,0.2-0.7,0.5c-1,1.3-0.9,3.1,0.1,4.4l0.1,0.1l8.6,4.3l4,1.3c0.6,0,2.7-1.2,4.2-2.1
        C771.1,318.7,771.8,317.5,771.7,316.2z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M771.8,313.4c-11,4-19,0-19,0"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M673.5,480c-2.4-0.9-4.9,1.7-6.2,3.2c-4.6,5.3-7.9,12.1-11.4,18.1
        c-2.4,4-4.7,8.1-7.1,12.1c-0.1,0.1-0.1,0.3-0.2,0.4c-4.7,8.5-9.3,16.9-14,25.4c-8.3,15-15.4,30.9-20.4,47.4
        c-5.5,18.2-8.7,37.1-9.4,56.1c-0.3,8.3-0.2,16.7,0,25c0.3,11.5,0.3,23,0.5,34.5c0.3,13.3,1.5,26.6,3,39.9
        c1.1,10.1,2.1,20.3,3.7,30.3c1.6,10.3,3.4,20.6,5.3,30.9c1.4,7.4,2.5,14.8,4.8,22c1.2,4,3.5,11.1,8.7,11.1c4.7,0,8-8.4,9.6-11.7
        c2.9-5.8,5.3-11.7,7.4-17.8c3.2-9.2,5.7-18.8,8-28.3c1.1-4.6,2.4-9.2,3.2-13.9c0.8-4.9,0.9-9.9,1.4-14.8c0.9-9.2,2-18.4,2.5-27.6
        c0.5-8.9,1.1-17.7,1.7-26.6c0.7-11.3,1.4-22.6,2.1-33.9c0.4-5.6,0.7-11.2,1.1-16.8l8-158C675.8,485,675.9,481,673.5,480z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M666.8,641.4l1.2-0.7l4.7-106.2c-1-28-10-21-10-21
        c-0.8,0-3.4,1.9-6.5,4.3c-7.3,5.7-13.3,13-17.6,21.3l-7.9,15.3c-12,20-20,59-20,59c-2,7-3.3,38.5-3.3,38.5c0.3,3.5,3.3,2.5,3.3,2.5
        l51.8-11.5C664,642.6,665.5,642.1,666.8,641.4z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M666.4,668.9l-3,48.3l-2.5,29.2l-1.7,17.7c-0.2,2.2-0.9,4.2-2.1,6
        l-0.8,1.2c-1.3,1.3-3.1,2.2-5,2.5c-7.6,1.1-13.3,1-17.1,0.7c-3.6-0.3-7.1-1.7-10-4l-7.6-5.9c-1.8-1.4-2.9-3.4-3.2-5.7l-1.6-12.5
        l-3-36l-1.3-38.5L666.4,668.9z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M639.1,791.8c-0.2-0.2-0.5-0.4-0.7-0.6l-17.5-13.1
        c-1.5-1.2-3.7,0.2-3.3,2l0,0c0,0.3,0.1,0.5,0.1,0.7l1,7.6l8,40c0.2,0.9,0.5,1.6,0.8,2.1c1.3,2.1,4.2,2.3,5.8,0.5
        c2.2-2.4,3.6-5.9,3.6-5.9l10.9-25.7L639.1,791.8z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M619.6,878.5c5.9,14.1,44.2,53.9,44.2,53.9"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M663.8,932.4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M599.8,582.4c2-6,13-34,13-34c3-7,13-28,13-28l17-31l23.9-40.8"/>
      <path fill="#F9F7F7" stroke="#020202" stroke-miterlimit="10" d="M666.3,502.5c-4.5,1.1-4.5,4.9-4.5,4.9c0,0.8-1.1,0.9-1.7,1
        c-0.2,0.1-0.5,0.1-0.7,0.2c-2.9,1.3-6.6,5.9-6.6,5.9c-3.6,4.5-4,10.6-4,11.8c0,0.1,0,0.3,0,0.4c0.4,2.5,1,4.6,1.6,6.3
        c1.3,3.3,2.8,5.5,6.4,5.5h7.6c0.3,0,0.6,0,0.8-0.1c5.3-1.6,6.5-4.9,7.2-6.2c0.2-0.3,0.3-0.7,0.3-1c1-12.4-0.5-22.2-0.8-24.3
        c-0.1-0.3-0.2-0.6-0.3-0.8c-1.4-2.5-3.1-3.2-4.1-3.5C667.1,502.5,666.7,502.4,666.3,502.5z"/>
      <path fill="#F9F7F7" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M655.3,534.4L655.3,534.4
        c-0.8,0-1.5-0.7-1.5-1.5v-12c0-0.8,0.7-1.5,1.5-1.5l0,0c0.8,0,1.5,0.7,1.5,1.5v12C656.8,533.8,656.1,534.4,655.3,534.4z"/>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" cx="692.3" cy="772.9" rx="7.5" ry="11.5"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M691.5,773.3c0-10-6.1-18.4,0.3-18.4
          c7.1,0,1,8.4,1,18.4s6,20.6-0.7,20.6C684.7,793.9,691.5,783.3,691.5,773.3z"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" cx="698.3" cy="625.9" rx="7.5" ry="11.5"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M697.5,626.3c0-10-6.1-18.4,0.3-18.4
          c7.1,0,1,8.4,1,18.4s6,20.6-0.7,20.6C690.7,646.9,697.5,636.3,697.5,626.3z"/>
      </g>
      <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M756.8,289.4c-50.8,4.2-52,11-52,11"/>
    </g>
    <g>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M56.1,305.4c0-0.9,2.2-2.9,2.8-3.6c1.2-1.5,2.4-3.2,2.9-5
        c0.7-2.5-0.4-5.3,0.4-7.9c1.3-4.1,6.4-7.5,10.5-7.5H93l2,4c5.2,1.6,10.3,3.2,15.5,4.8c6.2,1.9,12.4,3.8,18.6,5.7
        c1.6,0.4,3.1,1,4.7,1.4c1.2,0.3,2.4,0.3,3.6,0.3c2.3-0.1,4.7-0.7,7-1c3.5-0.5,3.8,0.9,5.6,3.7c1.1,1.7,2.2,3.5,3.2,5.2
        c2.1,3.6,4,7.3,5.7,11.1c3.4,7.4,6.2,15.2,8.5,23.1c6.2,21.3,9.4,43.3,12.4,65.2c1.8,12.8,3.5,25.6,5.3,38.4l1,13c0,6,8,19,8,19
        c0.8,1.5,1.6,2.9,2.4,4.3c6.6,11.8,13.2,23.5,19.8,35.3c10.3,18.4,18.5,38,26.3,57.6c0.5,1,5,10.3,4.5,10.8
        c1.1-1.1,2.3-2.9,3.6-3.6c2.3-1.2,2.9,1.4,3.2,3.3c1,5.7,1.4,11.5,2,17.2c1.2,11.4,2.2,22.9,3,34.4c1.8,27.4,2.4,54.8,1.7,82.2
        c-0.7,27.4-2.6,54.8-5.8,82c-1.6,13.6-3.5,27.2-5.7,40.7c-0.5,3.4-7.4,40-7.5,40c-5.2-1.3-9-6-10.8-11c-1-2.8-1.5-5.8-1.7-8.8
        c-0.1-1.4,1.5-8.7-0.9-8.3c-1.7,0.3-5,7.3-6,8.7c-4.2,5.7-8.2,11.5-12.1,17.4c-3.6,5.3-7.1,10.6-10.6,16c-3.1,4.7-7.3,9.4-8.7,14.8
        c-1.3,5-1.5,10.3-2.2,15.4c-0.3,2.5-0.7,5.1-1.5,7.5c-0.2,0.8-2,6.3-2.9,6.2c-7.4-0.6-14.9,0.3-21.9,2.6c0-1.8,0-3.7,0-5.5
        c-4.6-0.2-9.6,1-14.2,1.5c-4.3,0.4-8.6,0.9-12.9,1.4c-3,0-10,4-10,4h-18l-2.8,6c-2.9-0.6-5.9-0.8-8.8-1.4c-3-0.6-6.1-1.1-9,0.1
        c-0.8,0.3-1.5,0.7-2.3,1s-1.7,0.4-2.6,0.4c-4.3,0.2-8.7-0.7-12.6-2.4c-3.3-1.5-5.2-3.7-7.9-5.7c-2.1-1.5-3.2-3.9-3.7-6.3
        c-0.2-0.7,0-2-0.3-2.6c-0.2-0.6-3-2.2-3-2.1c0-6.3,0-12.6,0-18.9c0-11.3,0-22.7,0-34.1c70-3,67-60,67-60c1-65-69-64-69-64v-277
        c71,3,71-60,71-60c0-64-67-63-67-63S56.2,305.4,56.1,305.4z"/>
      <g>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M68.2,486.4c42-2,69-35,69-72c0-40.5-31-71-66-75"/>
        <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M52.2,739.7l22,0.7l3.2,1.1c2,0.6,3.9,1.4,5.7,2.3
          c37.8,18.4,51.1,36.6,51.1,70.6c0,36-30,76-79,75"/>
        <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="56.2" y1="339.4" x2="71.2" y2="339.4"/>
        <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="52.2" y1="486.4" x2="68.2" y2="486.4"/>
      </g>
      <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="71.2" y1="353.4" x2="71.2" y2="281.4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M201.3,795.4l-16.2,11.1c-3.2,2.2-7.4,2.2-10.6,0.1
        c-0.2-0.1-0.3-0.2-0.3-0.2l-22-13l-26-19l-40.8-29.5c-9.8-7.5-13.2-14.5-13.2-14.5c-3-6-3-23-3-23l-1-233.5"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M162.1,377.4c1,8.1,2,110,2,110l-2,51c0,10,1,94,1,94l3,53
        c1,17,6,50,6,50l5,43.7c0,0.2,0.1,0.4,0.1,0.6c2,11.5,3,61.7,3,61.7c0,12-8,96-8,96"/>
      <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="145.2" y1="350.4" x2="120.2" y2="393.4"/>
      <line fill="none" stroke="#020202" stroke-miterlimit="10" x1="156.2" y1="900.8" x2="114.2" y2="844"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M132.1,932.4c2.5-9.2,12.5-27.5,15.9-33.2c0.3-0.4-0.2-0.8-0.6-0.5
        c-3.7,3.3-8.6,10-10.9,13.4c-1,1.4-1.9,2.9-2.7,4.4c-5.2,9.5-8.6,19.7-9.6,23.9c-0.1,0.6,1.7,0.5,2,0c12-18.9,25-17,25-17"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M104.2,947.4c-0.8-16.8,3.3-23.7,4.6-25.5c0.1-0.1,0.2,0,0.2,0.1
        c-1.8,5.5-1.8,19.4-1.8,19.4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M88.2,945.5c1-4-2-8.1-2-8.1v-6.7c0-2.5,1-4.9,2.9-6.5
        c2.1-1.8,4.6-3.1,5.9-3.5c0.9-0.3,1.7-0.6,2.5-1.1l0.4-0.2v-57"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M86.3,937.4c2.1-1.1,3.8-2.1,5.1-3.1c3.3-2.4,5.5-6.1,6-10.1
        c0.3-2.5,0.5-4.8,0.5-4.8"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M71,288.3c-4,1-6,5.4-6,5.4v19.6c0,1.1,0.3,2.2,0.6,3.1
        c0.6,1.5,1.4,2.9,2.4,4.1l3.2,3.8c0.5,0.7,1.4,0.9,2.2,0.6l25.7-9.5c0.3-0.1,0.5-0.2,0.8-0.3c5.8-2.5,8.2-9.3,5.6-15
        c-1.6-3.6-4.5-7.1-7.9-9.5l-4.4-3.2c-13,5-13,11.6-13,11.6v21.5l7-3l9-3.8c3.5-1.4,5.9-4.7,6.1-8.5c0.1-1.6-0.1-2.7-0.1-2.7
        c-0.8-4.8-6.1-11.7-8.2-14.2c-0.5-0.7-0.8-1.5-0.8-2.4v-4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M91.8,761.5l-3.6-116l-6-99c0-12-4-17-4-17l-9.8-18.3"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M91.3,744c-4.9-2.5-15.1-12.6-15.1-12.6c-3-3-4-19.8-4-19.8v-63.2
        l-0.8-131.7"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M68.2,490.8l47.7-4.4c21.6-1,48.3,1,48.3,1l12,5"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M68.4,647.9l96.8,1.5c6,0,19,2.1,19,2.1"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M247.2,583.4c11.8,119,0.2,216.5,0.2,216.5
        c-1.8,12.5-9.2,61.5-9.2,61.5l-9-7"/>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="60.2" cy="412.9" rx="53" ry="53.5"/>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="59.8" cy="413.4" rx="36.6" ry="37"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="60.2" cy="814.9" rx="53" ry="53.5"/>
        <ellipse fill="none" stroke="#020202" stroke-miterlimit="10" cx="59.8" cy="815.4" rx="36.6" ry="37"/>
      </g>
      <path fill="#F9F7F7" stroke="#020202" stroke-miterlimit="10" d="M134,302.2c-2.7,1.2-2.7,5.2-2.7,5.2c0,6,1,12,1,12
        c0.7,5.5,3.5,12.9,5.1,17c0.8,1.8,1.7,3.6,2.7,5.3l13.2,21.7l15,24c-1.9-39.6-10-57.8-10.9-59.8c-0.1-0.1-0.2-0.3-0.2-0.4
        c-3.3-9.8-6.8-16-9.6-19.8c-2.5-3.5-6.7-5.5-11-5.5h-1.2C134.9,301.9,134.4,302,134,302.2z"/>
      <path fill="#F9F7F7" stroke="#020202" stroke-miterlimit="10" d="M156.2,931.5c-2.2-1-3.5-3.8-4.2-6c-0.6-2.1-0.8-4.3-0.4-6.4
        c0.8-5.2,3-13.3,3.5-15.2c0.1-0.3,0.2-0.5,0.3-0.8l11.9-33.6l7.5-18.8c0.5-1.1,1.1-2.3,2-3.2c0.2-0.2,0.4-0.4,0.6-0.6
        c2-1.9,5.3-0.7,5.8,2c0.1,0.9,0.1,1.6,0.1,1.6l0.9,38.8c0,1.6,0,3.2-0.2,4.8c-1,10.1-3.4,18.8-4.4,22c-0.3,0.8-0.5,1.6-0.9,2.4
        c-5,11.1-13.5,13.9-13.5,13.9C160.2,934.4,156.2,931.5,156.2,931.5z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M80.3,312.2V309c0-0.4,0.4-0.9,0.9-1.3c0.6-0.5,1.3-0.8,2.1-0.8h15
        c0.2,0,0.5,0.2,0.7,0.5c1,1.3,0.9,3.1-0.1,4.4l-0.1,0.1l-8.6,4.3l-4,1.3c-0.6,0-2.7-1.2-4.2-2.1C80.9,314.7,80.2,313.5,80.3,312.2z
        "/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M80.2,309.4c11,4,19,0,19,0"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M178.5,476c2.4-0.9,4.9,1.7,6.2,3.2c4.6,5.3,7.9,12.1,11.4,18.1
        c2.4,4,4.7,8.1,7.1,12.1c0.1,0.1,0.1,0.3,0.2,0.4c4.7,8.5,9.3,16.9,14,25.4c8.3,15,15.4,30.9,20.4,47.4c5.5,18.2,8.7,37.1,9.4,56.1
        c0.3,8.3,0.2,16.7,0,25c-0.3,11.5-0.3,23-0.5,34.5c-0.3,13.3-1.5,26.6-3,39.9c-1.1,10.1-2.1,20.3-3.7,30.3
        c-1.6,10.3-3.4,20.6-5.3,30.9c-1.4,7.4-2.5,14.8-4.8,22c-1.2,4-3.5,11.1-8.7,11.1c-4.7,0-8-8.4-9.6-11.7
        c-2.9-5.8-5.3-11.7-7.4-17.8c-3.2-9.2-5.7-18.8-8-28.3c-1.1-4.6-2.4-9.2-3.2-13.9c-0.8-4.9-0.9-9.9-1.4-14.8
        c-0.9-9.2-2-18.4-2.5-27.6c-0.5-8.9-1.1-17.7-1.7-26.6c-0.7-11.3-1.4-22.6-2.1-33.9c-0.4-5.6-0.7-11.2-1.1-16.8l-8-158
        C176.2,481,176.1,477,178.5,476z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M185.2,637.4l-1.2-0.7l-4.7-106.2c1-28,10-21,10-21
        c0.8,0,3.4,1.9,6.5,4.3c7.3,5.7,13.3,13,17.6,21.3l7.9,15.3c12,20,20,59,20,59c2,7,3.3,38.5,3.3,38.5c-0.3,3.5-3.3,2.5-3.3,2.5
        l-51.8-11.5C188,638.6,186.5,638.1,185.2,637.4z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M185.6,664.9l3,48.3l2.5,29.2l1.7,17.7c0.2,2.2,0.9,4.2,2.1,6
        l0.8,1.2c1.3,1.3,3.1,2.2,5,2.5c7.6,1.1,13.3,1,17.1,0.7c3.6-0.3,7.1-1.7,10-4l7.6-5.9c1.8-1.4,2.9-3.4,3.2-5.7l1.6-12.5l3-36
        l1.3-38.5L185.6,664.9z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M212.9,787.9c0.2-0.2,0.5-0.4,0.7-0.6l17.5-13.1
        c1.5-1.2,3.7,0.2,3.3,2l0,0c0,0.3-0.1,0.5-0.1,0.7l-1,7.6l-8,40c-0.2,0.9-0.5,1.6-0.8,2.1c-1.3,2.1-4.2,2.3-5.8,0.5
        c-2.2-2.4-3.6-5.9-3.6-5.9l-10.9-25.8L212.9,787.9z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M232.4,874.5c-5.9,14.1-44.2,53.9-44.2,53.9"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M188.2,928.4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M252.2,578.4c-2-6-13-34-13-34c-3-7-13-28-13-28l-17-31l-23.9-40.8"
        />
      <path fill="#F9F7F7" stroke="#020202" stroke-miterlimit="10" d="M185.7,498.5c4.5,1.1,4.5,4.9,4.5,4.9c0,0.8,1.1,0.9,1.7,1
        c0.2,0.1,0.5,0.1,0.7,0.2c2.9,1.3,6.6,5.9,6.6,5.9c3.6,4.5,4,10.6,4,11.8c0,0.1,0,0.3,0,0.4c-0.4,2.5-1,4.6-1.6,6.3
        c-1.3,3.3-2.8,5.5-6.4,5.5h-7.6c-0.3,0-0.6,0-0.8-0.1c-5.3-1.6-6.5-4.9-7.2-6.2c-0.2-0.3-0.3-0.7-0.3-1c-1-12.4,0.5-22.2,0.8-24.3
        c0.1-0.3,0.2-0.6,0.3-0.8c1.4-2.5,3.1-3.2,4.1-3.5C184.9,498.5,185.3,498.4,185.7,498.5z"/>
      <path fill="#F9F7F7" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M196.7,530.4L196.7,530.4
        c0.8,0,1.5-0.7,1.5-1.5v-12c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v12C195.2,529.8,195.9,530.4,196.7,530.4z"/>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" cx="159.7" cy="768.9" rx="7.5" ry="11.5"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M160.5,769.3c0-10,6.1-18.4-0.3-18.4
          c-7.1,0-1,8.4-1,18.4s-6,20.6,0.7,20.6C167.3,789.9,160.5,779.3,160.5,769.3z"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" cx="153.7" cy="621.9" rx="7.5" ry="11.5"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M154.5,622.3c0-10,6.1-18.4-0.3-18.4
          c-7.1,0-1,8.4-1,18.4s-6,20.6,0.7,20.6C161.3,642.9,154.5,632.3,154.5,622.3z"/>
      </g>
      <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M95.2,285.4c50.8,4.2,52,11,52,11"/>
    </g>
    <g>
      <g>
        <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M424.5,275.8c0,0,43,4,65,8c0,0,27,5,45,20c0,0,18,20,22,34l5,22
          l3,3.8l-2,137.2v189l1,97l1,54.1c0,0-2,19.9-4,36.9c0,0-9,32-24,46c0,0-43,21-108,22"/>
        <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M424.5,275.8c0,0-41.9,4-63.4,8c0,0-26.3,5-43.9,20
          c0,0-17.5,20-21.4,34l-4.9,22l-2.9,3.8l1.9,137.2v189l-1,97l-1,54.1c0,0,1.9,19.9,3.9,36.9c0,0,8.8,32,23.4,46
          c0,0,29.2,19,113.2,22"/>
      </g>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M348.5,290.8c0,0-23,5-37,27c0,0-10,12-10,55
        l-6,72v102.5"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M493.5,288.8c0,0,26,3.8,44.9,25.7
        c0.5,0.6,1.1,1.2,1.6,1.8c2.3,2.5,11.4,14.9,10.5,52.5l5,74v102.5"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M386.5,294.8l-7,1.9l-12,3.2c0,0-4,0.8-14,8.8
        c0,0-10,11-18,29l-14,39.7l-8,32.3c0,0-5,22-5,36V474l17,68.8l5,31l5,38l3,68l2,134v24"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M464.5,294.8l8,2.5l12,3.2c0,0,6,2.3,14,8.8
        c0,0,10,11,18,29l14,39.7l8,32.3c0,0,3,22.5,4,36.5l-1,19c-0.2,5.8-1,12-1.9,17.5l-13.1,60l-5,31l-5,38l-3,68l-2,134v24"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M383.5,299.8c0,0-21,89-24,121
        C359.5,420.8,363.5,352.8,383.5,299.8z"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M460.5,299.8c0,0,21,89,24,121
        C484.5,420.8,480.5,352.8,460.5,299.8z"/>
      <path fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" d="M313.5,460.8"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M537.5,460.8c0,0,0-7-2-12c0,0-7-12-20-17
        s-25-8-38-11c0,0-27-4.5-53-4.7l-31.1,1c0,0-30.9,4.8-40.9,8.8l-23,9c0,0-9,5-13,13c0,0-3,6.8-3,13.9c0,0,10-11.9,27-16.9
        c0,0,20-7,42-10c0,0,28-3.5,42-3.2c0,0,28,0.2,50,3.2C474.5,434.8,515.5,439.8,537.5,460.8z"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M313.5,461.3v12l6,29.5l12,61c0,0,43-9,96-10
        c0,0,36,0,92.1,10l14.9-75c0,0,4-16,3-28"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M316.5,463.8c0,0,2-6,18-14c0,0,13-5,21-7
        s27-7,58-8c0,0,34-1,60,3c0,0,24,5,30,7c0,0,7,2,23,10c0,0,7,5,8,8v9.4l-7,38.6l-10,50c0,0-52-10-89-9.5c0,0-46,0.5-95,9.5l-17-87
        V463.8z"/>
      <g>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M425.5,838.8h86c0,0,6,1,7,5c0,0,2,3-1,8
          c0,0-9,15-46,21c0,0-27,4.7-43,4.8h-3"/>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M426.1,838.8h-86c0,0-6,1-7,5c0,0-2,3,1,8
          c0,0,9,15,46,21c0,0,27,4.7,43,4.8h3"/>
      </g>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M508.5,580.8l7,1c0,0-5,51-5,60l-3,127v63.9
        h-7.9l0.9-97.9l1-75.2c0-1.2,0.1-2.4,0.2-3.6C502.6,644.8,507.6,585.3,508.5,580.8z"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M342.6,580.8l-7,1c0,0,5,51,5,60l3,127v63.9h7.9
        l-0.9-97.9l-1-75.2c0-1.2-0.1-2.4-0.2-3.6C348.5,644.8,343.5,585.3,342.6,580.8z"/>
      <g>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M305.5,473.8l16,89c0,0,7,34,9,83v104.6
          l-3,65.4c0,0-0.4,6.3-1.1,11.7c-0.3,2.3-3.6,2.6-4.2,0.4c-2-6.5-4.9-22.4-6.6-36.1l-5-50l-3-79l-2-108.5l-1-44.5v-30
          C304.5,479.8,303.5,472.8,305.5,473.8z"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="308.5" y1="663" x2="330.5" y2="665.5"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="330.5" y1="647.5" x2="307" y2="636.3"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="313.5" y1="519.7" x2="304.2" y2="513.7"/>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M330.5,751.8c0,0-2.3,8.7-4.7,12.3
          c-1.1,1.7-3,2.8-5.1,3c-2.6,0.2-6.3,0.2-7.8-1.1"/>
      </g>
      <g>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M544.5,473.8l-16,89c0,0-7,34-9,83v104.6
          l3,65.4c0,0,0.4,6.3,1.1,11.7c0.3,2.3,3.6,2.6,4.2,0.4c2-6.5,4.9-22.4,6.6-36.1l5-50l3-79l2-108.5l1-44.5v-30
          C545.5,479.8,546.5,472.8,544.5,473.8z"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="541.5" y1="663" x2="519.5" y2="665.5"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="519.5" y1="647.5" x2="543" y2="636.3"/>
        <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="536.5" y1="519.7" x2="545.7" y2="513.7"/>
        <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M519.5,751.8c0,0,2.3,8.7,4.7,12.3
          c1.1,1.7,3,2.8,5.1,3c2.6,0.2,6.3,0.2,7.8-1.1"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M332.5,847.4c0,0-5,38.3-3,49.3
        c0,0,0,8.3,3,11.2c0,0,24,18.8,65,24.8c0,0,48,3,65-2c0,0,34-7.3,53-21.6c0,0,6-4.4,7-11.4v-28.1l-3.3-23.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M334.1,851.8c0,0-1.7,41.9-1.7,44c0,0,2,7,20,15c0,0,28,11,55,14
        c0,0,24,1,48-2c0,0,31-6,42-11c0,0,13-6,19-13c0,0,3.3-7.8,2.7-10.4l-1.7-36.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M352.5,300.8c0,0-36,30.2-44,81.1l-7.2-6.9c0,0,0.2-33.1,9.2-51.1
        c0,0,11-18,28.9-24l9.1-1.1C348.5,298.7,352.5,298.8,352.5,300.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M499.3,300.8c0,0,36,30.2,44,81.1l7.2-6.9c0,0-0.2-33.1-9.2-51.1
        c0,0-11-18-28.9-24l-9.1-1.1C503.3,298.7,499.3,298.8,499.3,300.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M373.8,294.8c0,0-19.3,3.9-21.3,5.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M347.5,293.8c0,0-5,2-10,7"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M477.5,294.8c0,0,20,3.9,22,5.9"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M494.5,290.8c0,0,9,1,18,9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M549.8,846.2c0.6,2.8,1.7,10.1,1.7,25.6c0,0,0.8,10.6-4,25.9
        c-1.1,3.4-2.9,6.6-5.2,9.3c-3.8,4.5-10.4,11.2-16.8,13.8v-11c0,0,12.1-5.8,15.1-21.4c0,0,4.4-15.8,5.2-23.2c0,0,1.8-14.4,3-19
        C548.8,845.5,549.7,845.6,549.8,846.2z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M301.1,846.2c-0.6,2.8-1.7,10.1-1.7,25.6c0,0-0.8,10.6,4,25.9
        c1.1,3.4,2.9,6.6,5.2,9.3c3.8,4.5,10.4,11.2,16.8,13.8v-11c0,0-12.1-5.8-15.1-21.4c0,0-4.4-15.8-5.2-23.2c0,0-1.8-14.4-3-19
        C302.1,845.5,301.3,845.6,301.1,846.2z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M325.5,915.8c0,0,93,46,200-1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="301.5" y1="845.8" x2="296.5" y2="715.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M289,786.8c0,0,3.3,11.2,7.8,16.6c1.6,1.9,4.3,2.5,6.5,1.4
        c2.6-1.3,6.6-4.5,12.1-11.8c0.2-0.2,0.3-0.5,0.4-0.7l14.7-26.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="307" y1="648.7" x2="289.9" y2="645.6"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="294.5" cy="765.3" rx="2" ry="9.5"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="294.5" cy="619.3" rx="2" ry="9.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M291.5,517.8v84.1l3,7.1c0,0,5,9.9,0,19.9c0,0-1,4-2,7.5v51.9v61.1
        l2,6.5c0,0,5,6,0,19v8.3c0,0.8-0.3,1.6-0.9,2.1c-0.8,0.8-2.4,1.2-5.1-0.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549.5" y1="845.8" x2="554.5" y2="712.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M562,785.8c0,0-3.3,11.2-7.8,16.6c-1.6,1.9-4.3,2.5-6.5,1.4
        c-2.6-1.3-6.6-4.5-12.1-11.8c-0.2-0.2-0.3-0.5-0.4-0.7l-14.7-26.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="544" y1="647.7" x2="561" y2="644.6"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="556.5" cy="764.3" rx="2" ry="9.5"/>
      <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="556.5" cy="618.3" rx="2" ry="9.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M559.5,516.8v84.1l-3,7.1c0,0-5,9.9,0,19.9c0,0,1,4,2,7.5v51.9v61.1
        l-2,6.5c0,0-5,6,0,19v8.3c0,0.8,0.3,1.6,0.9,2.1c0.8,0.8,2.4,1.2,5.1-0.4"/>
      <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M490.2,283.9l6.3,8.9c1,2-1.5,2.2-1.9,2c-0.1,0-0.2,0-0.3,0H348.5
        c-1,0-1-2-1-2l4.6-6.7c0,0,16.1-4.2,31.8-5.8l40.7-4.6l36,3.9C460.5,279.6,483,282.1,490.2,283.9z"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="386.5" y1="279.8" x2="458.5" y2="279.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="367.5" y1="282.8" x2="484.5" y2="282.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="357" y1="285.8" x2="491" y2="285.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="350.5" y1="288.8" x2="493.5" y2="288.8"/>
      <line fill="none" stroke="#050505" stroke-width="0.5" stroke-miterlimit="10" x1="348.5" y1="291.8" x2="495.5" y2="291.8"/>
    </g>
    <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M531.5,91.1l-104,1l-109-1h-0.1
      c-4-0.9-6.2-5.1-4.8-9l17-46.8c0-1.2,1-2.2,2.2-2.2l81.8-3c2.5-0.1,28.2-0.1,23,0l78.8,3c1.2,0,2.2,1,2.2,2.2l17.7,44.4
      c1.3,3.2,0.7,6.9-1.6,9.4C533.7,90,532.7,90.8,531.5,91.1z"/>
    <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M529.8,89.6l-102.2,0.9l-107.1-0.9h-0.1
      c-3.9-0.8-6.1-4.8-4.8-8.4l16.7-43.7c0-1.1,1-2.1,2.2-2.1l80.4-2.8c2.5-0.1,27.7-0.1,22.6,0l77.4,2.8c1.2,0,2.2,0.9,2.2,2.1
      L534.3,79c1.2,3,0.7,6.5-1.6,8.8C531.9,88.7,530.9,89.3,529.8,89.6z"/>
    <g>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M424.8,15.1c0,0,61.8,2,82.4,5c0,0,5.9,0,11.8,8
        l20.8,52.1c0.5,1.2,0.7,2.5,0.7,3.9v3.3c0,3.5-1.6,6.7-4.3,8.8c-0.7,0.6-24.1,18.2-25.1,18.9l-12.8,9c0,0-11.8,4-14.7,4h-53.5h-5.3
        "/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M424.8,15.1c0,0-61.8,2-82.4,5c0,0-5.9,0-11.8,8
        l-20.8,52.1c-0.5,1.2-0.7,2.5-0.7,3.9v3.3c0,3.5,1.6,6.7,4.3,8.8c0.7,0.6,24.1,18.2,25.1,18.9l12.8,9c0,0,11.8,4,14.7,4h53.5h5.3"
        />
    </g>
    <path fill="#050505" d="M490.5,95.1c0,0-11,9-21,28C469.5,123.1,486.5,98.1,490.5,95.1z"/>
    <path fill="#050505" d="M359.5,95.1c0,0,11,9,21,28C380.5,123.1,363.5,98.1,359.5,95.1z"/>
    <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M540.5,84.1c0,0,3.2,3.8,6,8c0.7,1.1,4.1,5.2,4.5,6.5
      c1.6,4.7,6.5,19.2,7.4,25.2c0,0.2,0.1,0.4,0.1,0.5c0.7,2.2,5.7,18.7,2.9,43.7l-7,35v6c0,0-2,7-10,10h-43c0,0-3,0-5-1c0,0-4-5-11-5
      H426"/>
    <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M309.5,86.1c0,0-2,2.1-5.2,6c-0.9,1.2-2.8,3-4.5,6.5
      c-2.2,4.5-6.5,19.2-7.4,25.2c0,0.2-0.1,0.4-0.1,0.5c-0.7,2.2-5.7,18.7-2.9,43.7l7,35v6c0,0,2,7,10,10h43c0,0,3,0,5-1c0,0,4-5,11-5
      H427"/>
    <polyline fill="none" stroke="#050505" stroke-miterlimit="10" points="347.9,219.1 349.5,222.1 500.7,222.1 501.5,219.1 "/>
    <g>
      <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M424.7,131.1h43.8c0,0,10-0.1,15,1.9c0.7,0.3,1.4,1,1.9,1.4
        c1.1,0.8,2.2,1.6,3.3,2.4c2.3,1.6,4.5,3.2,6.5,5.1c1.1,1,2.1,2.2,2.6,3.6c0.4,1.1,0.3,2.4-0.1,3.5s-1.1,2.1-1.8,3.1
        c-5.1,7.2-10.4,14.3-16.4,20.7c-2.5,2.6-5.2,5-8.6,6.3c-1.6,0.6-3.9,1.4-6.5,1.9H424"/>
      <path fill="none" stroke="#050505" stroke-miterlimit="10" d="M425.1,131.1c-4,0-8.1,0-12.1,0c-7.3,0-14.5,0-21.8,0
        s-14.7-0.4-21.9,1.1c-0.9,0.2-1.8,0.4-2.7,0.8c-0.7,0.3-1.4,0.8-2.1,1.3c-3.1,2.2-6.2,4.5-9.1,6.8c-2.4,1.9-4.2,4.6-3.5,7.7
        c0.5,2.2,2.7,4.5,4,6.3c1.6,2.2,3.2,4.3,4.9,6.4c3.2,4.1,6.7,8,10.4,11.7c2.2,2.2,4.5,4.2,7.3,5.6c4.6,2.3,10,2.5,15,2.2
        c4.6-0.2,9.2,0,13.9,0c5.8,0,11.6,0,17.4,0"/>
    </g>
    <g>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M424.7,133.6h42c0,0,9.6,0,14.3,1.8
        c0,0,9.6,6.3,11.5,8.1c0,0,3.8,2.7,1.9,7.2l-10.5,13.5l-8.6,9c-1.1,1.1-4.8,3.1-6.3,3.7c-1.6,0.6-3.8,1.3-6.2,1.7H424"/>
      <path fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" d="M425,133.6h-42c-2,0-3.9,0.1-5.9,0.2
        c-3.4,0.3-7,0.5-9.9,2.5c-3.4,2.2-7.1,4.4-10.1,7.2c0,0-3.8,2.7-1.9,7.2c2.3,2.9,4.8,5.7,6.8,8.8c2.8,4.1,6.6,7.4,10.2,10.8
        c2.2,2.1,4.4,4.4,7.1,5.9c4.4,2.5,9.7,2.6,14.7,2.4c4.2-0.1,8.4,0,12.5,0c6,0,12,0,18,0h0.1"/>
    </g>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="367.5" y1="136.1" x2="482.5" y2="136.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="362.5" y1="139.6" x2="487.5" y2="139.6"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="357.5" y1="143.1" x2="492.5" y2="143.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="354.5" y1="147.1" x2="494.5" y2="147.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="355.5" y1="151.1" x2="494.5" y2="151.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="359" y1="155.1" x2="491.5" y2="155.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="362.5" y1="160.1" x2="487.5" y2="160.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="367" y1="165.1" x2="483.5" y2="165.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="372" y1="170.1" x2="478.5" y2="170.1"/>
    <line fill="none" stroke="#050505" stroke-width="0.75" stroke-miterlimit="10" x1="377" y1="175.1" x2="472.5" y2="175.1"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M362.5,136.1l-45-24l-11-6c0,0-5-4-6-9"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M307.1,106.4l-5.6,5.7c0,0-1.5,1-1.3,4.5l0.3,10.3c0,0,0,8.2,6,13.2
      c0,0,5,2,14,3c0,0,13,1,19,0c0,0,8-2,14.5-11.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M487.5,136.1l47-25l9.7-6c0,0,5-4,6-9"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M542.5,106.4l5.6,5.7c0,0,1.5,1,1.3,4.5l-0.3,10.3c0,0,0,8.2-6,13.2
      c0,0-5,2-14,3c0,0-13,1-19,0c0,0-8-2-14.5-11.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M558.5,124.1c0,0-0.2,2.4-1,5.4c-2,7.9-8.4,14-16.5,15.3
      c-0.1,0-0.3,0-0.4,0.1c-0.7,0.1-1.5,0.2-2.2,0.4c-3.8,0.9-16.7,3.9-20.9,4.7c0,0-3.9,0.7-7.3,1.6c-5.6,1.5-10.7,4.4-14.8,8.5
      c-7.3,7.1-19,19.2-21.8,19.9c-4,1-6.7,1.1-6.7,1.1"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M292.8,124.1c0,0,0.2,2.4,1,5.4c2,7.9,8.4,14,16.5,15.3
      c0.1,0,0.3,0,0.4,0.1c0.7,0.1,1.5,0.2,2.2,0.4c3.8,0.9,16.7,3.9,20.9,4.7c0,0,3.9,0.7,7.3,1.6c5.6,1.5,10.7,4.4,14.8,8.5
      c7.3,7.1,19,19.2,21.8,19.9c4,1,6.7,1.1,6.7,1.1"/>
    <g>
      <path fill="#F9F9F9" stroke="#020202" stroke-miterlimit="10" d="M306.5,85.1v8c0,0,0,1-3.2,2l-4.1,2h-17.7c0,0-7.6-1-11.7-8.6
        c-0.2-0.3-0.3-0.7-0.4-1c-0.3-1.1-0.9-3.4-0.6-6c0.2-2.5,1.6-4.7,3.7-6s5.6-2.8,11.2-3.3h12.2c0,0,1.8,0,1.8,2v11L306.5,85.1
        L306.5,85.1z"/>
      <path fill="#F9F9F9" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M289.2,81.1h-17.4c-0.2,0-0.3-0.1-0.3-0.3
        v-2.4c0-0.2,0.1-0.3,0.3-0.3h17.4c0.2,0,0.3,0.1,0.3,0.3v2.4C289.5,80.9,289.4,81.1,289.2,81.1z"/>
    </g>
    <g>
      <path fill="#F9F9F9" stroke="#020202" stroke-miterlimit="10" d="M543.5,85.1v8c0,0,0,1,3.2,2l4.1,2h17.7c0,0,7.6-1,11.7-8.6
        c0.2-0.3,0.3-0.7,0.4-1c0.3-1.1,0.9-3.4,0.6-6c-0.2-2.5-1.6-4.7-3.7-6c-2.1-1.3-5.6-2.8-11.2-3.3h-12.2c0,0-1.8,0-1.8,2v11
        L543.5,85.1L543.5,85.1z"/>
      <path fill="#F9F9F9" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M560.8,81.1h17.4c0.2,0,0.3-0.1,0.3-0.3v-2.4
        c0-0.2-0.1-0.3-0.3-0.3h-17.4c-0.2,0-0.3,0.1-0.3,0.3v2.4C560.5,80.9,560.6,81.1,560.8,81.1z"/>
    </g>
    <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M424.5,182.7H480l19.5-5.7l23.1-7
      c0,0,6.6-2,14.3,0c0,0,4.7,3,6.7,11c0,0,3,12,3,21c0,0,0,5.3-6,8.2h-39c0,0-9-10.2-19-13.2H424"/>
    <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M425,182.7h-55.5L350,177l-23.1-7
      c0,0-6.6-2-14.3,0c0,0-4.7,3-6.7,11c0,0-3,12-3,21c0,0,0,5.3,6,8.2h39c0,0,9-10.2,19-13.2h58.5"/>
    <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M308.5,197.1h27.8c0,0,5.2,0,7.2-2l9.2-8.8
      c1-1,1.9-2.2,2.5-3.5c0.2-0.4,0.3-0.8,0.3-1l-26-7.8l-8.3-2.5c-1.8-0.5-3.6-0.7-5.5-0.5c-2,0.2-4.2,1-5.2,3c0,0-4,8-5,14
      C305.5,188.1,304.5,196.1,308.5,197.1z"/>
    <circle fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" cx="322.5" cy="185.1" r="7"/>
    <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M540.4,197.1h-27.8c0,0-5.2,0-7.2-2l-9.2-8.8
      c-1-1-1.9-2.2-2.5-3.5c-0.2-0.4-0.3-0.8-0.3-1l26-7.8l8.3-2.5c1.8-0.5,3.6-0.7,5.5-0.5c2,0.2,4.2,1,5.2,3c0,0,4,8,5,14
      C543.4,188.1,544.4,196.1,540.4,197.1z"/>
    <circle fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" cx="526.4" cy="185.1" r="7"/>
    <line fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" x1="296.5" y1="204.1" x2="552.5" y2="204.1"/>
    <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M296.5,209.5V203l-8-36.6V265c0,0,0,2,2,3h34.7
      c0,0,1.3,0,1.9-2.1V219h-19.9c0,0-3.7-0.6-5.7-2.8"/>
    <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M554.5,208.6v-5.5l7-36l-0.4,98c0,0,0,2-2,3h-34.7
      c0,0-1.3,0-1.9-2.1v-46.9h19.9c0,0,4.1,0.2,6.1-2"/>
    <path fill="#F7F2F2" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M334.5,29.1h5.8c0,0,3.5-7.9,5.4-9.4l3.2-0.4
      v-2.6h-9l-3.4,4.4C336.5,21.1,334.5,26.1,334.5,29.1z"/>
    <path fill="#F7F2F2" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M515.9,29.1h-5.8c0,0-3.5-7.9-5.4-9.4l-3.2-0.4
      v-2.6h9l3.4,4.4C513.9,21.1,515.9,26.1,515.9,29.1z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M291.4,1143.3v94.9c0,0,0.2,2.7,2.2,2.7h35c0,0,2,0,2-2v-46"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M564.6,1143.3v94.9c0,0-0.2,2.7-2.2,2.7h-35c0,0-2,0-2-2v-46"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M431.6,1017c-3,0.1-6,0.1-9.1,0.2c-7.1,0.2-14.1,0.3-21.2,0.5
        c-8.2,0.2-16.4,0.3-24.6,0.6c-6.1,0.2-12.3,0.4-18.4,0.4c-3.3,0-6.6,0-10,0.1c-2.1,0.1-4.2,0.2-6.1,0.9c-3.5,1.2-5.1,4.4-6.2,7.6
        c-1.5,4.1-2.6,8.1-2.9,12.5c-0.1,1.7-0.1,3.4,0,5.2c0.1,1.7,0.3,3.5,1,5.1c1.6,3.7,5.4,6.2,9.5,7.3c4.1,1.1,8.4,1.1,12.6,1.1
        c5.1,0,10.3,0,15.4-0.1c5,0,10.1-0.2,15.1,0c9.3,0.3,18.7,0,28,0c5.7,0,11.4,0,17.1,0c0.4,0,0.8,0,1.2,0"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M431.4,1017c2.8,0.1,5.6,0.1,8.4,0.2c6.6,0.2,13.1,0.3,19.7,0.5
        c7.6,0.2,15.2,0.3,22.9,0.6c5.7,0.2,11.4,0.4,17.2,0.4c3.1,0,6.2,0,9.3,0.1c1.9,0.1,3.9,0.2,5.7,0.9c3.3,1.2,4.7,4.4,5.8,7.6
        c1.4,4.1,2.4,8.1,2.7,12.5c0.1,1.7,0.1,3.4,0,5.2c-0.1,1.7-0.3,3.5-1,5.1c-1.5,3.7-5,6.2-8.8,7.3c-3.8,1.1-7.8,1.1-11.7,1.1
        c-4.8,0-9.5,0-14.3-0.1c-4.7,0-9.4-0.2-14.1,0c-8.7,0.3-17.4,0-26,0c-5.3,0-10.6,0-15.9,0c-0.4,0-0.7,0-1.1,0"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M428.6,1010l65,1c0,0,9.5,0,17.5,2.3c4,1.2,7.5,3.8,9.7,7.3
        c0.9,1.4,1.7,3,2.3,4.4c0,0,2.5,5,3.5,10c0,0,2,11.6,0,18.8c0,0-0.7,4.2-6.5,6.8c-2.2,1-4.6,1.4-7,1.4h-84.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M429.5,1010l-65,1c0,0-9.5,0-17.5,2.3c-4,1.2-7.5,3.8-9.7,7.3
        c-0.9,1.4-1.7,3-2.3,4.4c0,0-2.5,5-3.5,10c0,0-2,11.6,0,18.8c0,0,0.7,4.2,6.5,6.8c2.2,1,4.6,1.4,7,1.4h84.5"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M426.6,990h45.7c0,0,26.3,1,35.3,2c0,0,9,2,13,7l5,10c0,0,0,13,1,14
        l14,25l7.1,14.1l5.9,6.9l3,11l4,11l3,17c0,0,0,2,1,4l1,13c0,0-1,12-2,20l-4,23l-2,12c0,0,0,10-10,13h-42.3c0,0-3.7-5-11.7-5h-66.5"
        />
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M428.6,990h-45.7c0,0-26.3,1-35.3,2c0,0-9,2-13,7l-3,8c0,0-1,17-2,18
        l-16,26l-5,12l-6,7l-4,10l-4,11l-2,13.7c0,0.2-0.1,0.5-0.1,0.7c-0.3,1.2-1.1,5-1.9,6.6l-1,13c0,0,1,12,2,20l4,23l2,12
        c0,0,0,10,10,13h42.3c0,0,3.7-5,11.7-5h66.5"/>
    </g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M349.6,1192c0,0,2-11,11-14h136c0,0,9.6,3,9.3,14"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M349.6,1193c0,0,0.5,1,1.5,1.9c0.7,0.7,1.8,1.1,2.8,1.1h79.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M505.7,1193c0,0-0.5,1-1.5,1.9c-0.7,0.7-1.8,1.1-2.8,1.1H422"/>
    </g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M292.4,1149.4h18.9c0,0,4.4,13.6,18.4,15.6h105.7"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M563.3,1149.4h-18.9c0,0-4.4,13.6-18.4,15.6H420.4"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M311.6,1149c0,0,24-4,40,16"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M544.6,1149c0,0-24-4-40,16"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M307.1,1064.7l39.5,9.3l12.1,3.5c1.6,0.5,2.5,2.1,2,3.7v0.1l-3.4,5.3
      c-1.3,2-2.8,3.7-4.7,5.1c-2.7,2-6.8,4.4-10.9,4.4h-21.6c-1.2,0-2.5-0.2-3.7-0.5c-3-0.8-8.1-2.8-10.8-7.5c0,0-3.5-7.8-3.2-17.4
      L307.1,1064.7z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M301.3,1073.3l1.1,25.3c0,0,0.3,15.4,18.3,21.4c0,0,5,2,19,2l89.9,3.1
      "/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M304.3,1084.2c0,0,1.3,12.8,5.3,18.8c0,0,4,9,23,11c0,0,13,2,25,2
      h71.9"/>
    <path d="M361.1,1080.5c0,0,17,4,22.5,35.5c0,0-0.5-31.5-23.5-37.5L361.1,1080.5z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M548.7,1063.7l-39.5,9.3l-12.1,3.5c-1.6,0.5-2.5,2.1-2,3.7v0.1
      l3.4,5.3c1.3,2,2.8,3.7,4.7,5.1c2.7,2,6.8,4.4,10.9,4.4h21.6c1.2,0,2.5-0.2,3.7-0.5c3-0.8,8.1-2.8,10.8-7.5c0,0,3.5-7.8,3.2-17.4
      L548.7,1063.7z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M554.6,1072l-1.2,26.6c0,0-0.3,15.4-18.3,21.4c0,0-5,2-19,2l-89.9,3.1
      "/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M551.6,1083c0,0-1.4,14-5.4,20c0,0-4,9-23,11c0,0-13,2-25,2h-71.9"/>
    <path d="M496.1,1081.5c0,0-18.4,3-23.9,34.5c0,0-0.1-31.5,22.9-37.5L496.1,1081.5z"/>
    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="373.4" y1="1088.2" x2="482.1" y2="1087.5"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M337.9,1122c0,0,2.7,14.3,11.7,17.6c0,0,5,2.4,12,2.4c0,0,45,2,68,2"
        />
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M341.2,1131.2c0,0,7.7,7.2,17.2,8.7c0.8,0.1,1.7,0.2,2.5,0.2
        c6.8,0.3,39.3,1.5,68.7,1.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M519.6,1122c0,0-2.7,14.3-11.7,17.6c0,0-5,2.4-12,2.4c0,0-45,2-68,2"
        />
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M516.3,1131.2c0,0-7.7,7.2-17.2,8.7c-0.8,0.1-1.7,0.2-2.5,0.2
        c-6.8,0.3-39.3,1.5-68.7,1.3"/>
    </g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M331.6,1007c0,0-11,16.4-18.5,45.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M525.1,1009c0,0,11,16.4,18.5,45.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M403.6,1017c0,0,0.7-4.3,4.9-6.7h40.1c0,0,2.3,0.7,4.6,6.7l0.4,0.6
      l-18.9-0.5l-4-0.1l-17.1,0.4l-10.3,0.2L403.6,1017z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M346.6,1005h7c0,0,0-11-1-13l-1-2h-8l-4,5c0,0,4.5-2,6.2-2.5
      L346.6,1005z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M509.6,1005h-7c0,0,0-11,1-13l1-2h8l4,5c0,0-4.5-2-6.2-2.5L509.6,1005
      z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M338.6,995.6c0,0,4,2.4,5,19.4"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M517.6,996c0,0-3,3-4,18"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M299.6,1070v-22c0,0,0-2-2-2h-12.2c0,0-4.8,1-8.8,3c0,0-4,2-5,4v5.5
        c0,0,0,4.5,7,9.5c0,0,3,2.4,7,2.7h14V1070z"/>
      <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="299.6,1058.5 310.4,1058.5 309,1062 308.2,1063.4 
        305.6,1066.6 302.6,1070.3 299.6,1070.3 	"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M556.4,1070v-22c0,0,0-2,2-2h12.2c0,0,4.8,1,8.8,3c0,0,4,2,5,4v5.5
        c0,0,0,4.5-7,9.5c0,0-3,2.4-7,2.7h-14V1070z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M556.4,1058.5h-10.3c-0.2,0-0.4,0.2-0.3,0.4l1.7,3.1l1,1l3,4l2,3
        l2.8,0.3L556.4,1058.5L556.4,1058.5z"/>
    </g></>)
    }
  }