/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:1878c38c-8a96-4fd8-a012-4f369f658f90",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_aEKbADVO9",
    "aws_user_pools_web_client_id": "58nlhghuel3p2acauipc6uphhe",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "nuvven-b2b-app0921014c5695490da952f0b646a8cba8app-master",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
