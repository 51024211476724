import { gql } from "apollo-boost";

export const GET_VEHICLES_FOR_BUSINESS_CUSTOMER = gql`
  query getVehiclesForBusinessCustomer($businessCustomerId: ID!,$driver:ID) {
    getVehiclesForBusinessCustomer(businessCustomerId: $businessCustomerId, driver:$driver) {
      insurerCode
      isGhostVehicle
      assetTag
      bodyType
      colour
      fuelType
      licencePlate
      vin
      make
      model
      year
      imageUrl
      numberOfDoors
      status
      id
      telematicCoreData {
        latitude
        longitude
      }
      smartCarVehicleData {
          vehicleLocation {
            latitude
            longitude
          }
        }
      activeBookingSchedule{
        booking{
          id
          referenceNumber
          pickupDateTime
          dropoffDateTime
          branch{
            id
            timeZone
          }
        }
        driver {
          id
          note
        }
        id
      }
    }
  }
`;