import * as React from 'react';

export const TruckmanWithoutTop4DoorSVG = {
  interior: {
    width: 448.9,
    height: 1129,
    svg: (
      <>
<g id="Layer_1_00000021828353072565977660000004100298413923226781_">
	<g>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M400.3,306.5c1.1-2.6,1.7-5.4,1.7-8.3v-140
			c-1.4-56.1-29.6-79-29.6-79c-40.2-40.4-80.6-53.3-80.6-53.3h-67.1h-7.8h-67.1c0,0-40.4,12.9-80.6,53.3c0,0-28.3,23-29.6,79v140
			c0,2.8,0.6,5.7,1.7,8.3c2,4.8,5.2,12.9,5.2,17v12.1L43.9,492v258.4h3.4l-7.2,39c-0.4,2.2-0.6,4.3-0.6,6.5v141.4
			c0,0,9.6,121.3,12.4,138.8c0,0,2.8,18.4,20.2,20.7h88.2h56.5h7.8h56.5h88.2c17.5-2.3,20.2-20.7,20.2-20.7
			c2.8-17.5,12.4-138.8,12.4-138.8V796c0-2.2-0.2-4.4-0.6-6.5l-7.2-39h3.4V492L395,335.6v-12.1C395.1,319.3,398.3,311.2,400.3,306.5
			z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M334.5,429.7c8.2-0.4,10.5-4.6,11.9-12.3
			l15.2-81.7c1.2-6.4,1.6-12.9,1.2-19.4l-1.3-21.7c-0.1-2.1-1.2-4.1-2.9-5.4c-23.1-16.7-52.8-22.1-52.8-22.1
			c-23-3.6-67.3-5.7-83.1-6.3v-0.2c0,0-1,0-2.9,0.1c-1.8-0.1-2.9-0.1-2.9-0.1v0.2c-15.8,0.6-60.2,2.7-83.1,6.3
			c0,0-29.7,5.4-52.8,22.1c-1.7,1.3-2.8,3.3-2.9,5.4l-1.3,21.7c-0.4,6.5,0,13,1.2,19.4l15.2,81.7c1.4,7.7,3.7,11.7,11.3,13"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M219.9,51.1h60.9c0,0,20.7,1.4,39.5,16.1
			l39.4,33.5c0,0,12.1,10.8,15.1,32.1c0.5,3.3,0.6,6.6,0.6,9.9v100.6c0,12.6-1.8,25.2-5.6,37.1c-1.9,5.8-4.2,11.2-6.8,13.7"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M220.8,51.1h-60.9c0,0-20.7,1.4-39.5,16.1
			L81,100.7c0,0-12.1,10.8-15.1,32.1c-0.5,3.3-0.6,6.6-0.6,9.9v100.6c0,12.6,1.8,25.2,5.6,37.1c1.9,5.8,4.2,11.2,6.8,13.7"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M98.7,278.9c0,0,38.2-151.8,40-222.4"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M342.5,278.9c0,0-38.2-151.8-40-222.4"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M146.3,52.9L119,49.1
				c-1.6-0.3-1.8-2.5-0.3-3.1l34.4-12.5c0.2-0.1,0.4-0.1,0.5-0.1h66.1"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M290.9,52.9l27.3-3.9c1.6-0.3,1.8-2.5,0.3-3.1
				l-34.4-12.5c-0.2-0.1-0.4-0.1-0.5-0.1h-66.1"/>
		</g>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M94.3,62.9L70.9,83.8
			c0,0-12.2,14.2-17.6,29.4c0,0-7.8,17.5,7.7,17.1c15.5-0.4,12.7-0.3,15.5-5.2c2.7-4.8,6.2-24.2,13.1-29.1
			c4.9-3.4,16-12.9,22.2-18.2c3.8-3.2,6.3-7.8,6.4-12.8c0.1-5.4-2.4-10.7-14.3-7.5C100.4,58.6,97.1,60.4,94.3,62.9z"/>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M347.7,62.9l23.4,20.9
			c0,0,12.2,14.2,17.6,29.4c0,0,7.8,17.5-7.7,17.1c-15.5-0.4-12.7-0.3-15.5-5.2c-2.7-4.8-6.2-24.2-13.1-29.1
			c-4.9-3.4-16-12.9-22.2-18.2c-3.8-3.2-6.3-7.8-6.4-12.8c-0.1-5.4,2.4-10.7,14.3-7.5C341.6,58.6,344.9,60.4,347.7,62.9z"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="104.6" y1="429.9" x2="104.6" y2="739.6"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="334.5" y1="429.7" x2="334.5" y2="737.9"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="103.4" y1="738.3" x2="335.8" y2="738.3"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M60.2,332.3c0-0.6,0.8-0.7,1-0.2
				c5,16.3,26.6,88.6,26.6,107.6V708c0,3-1.2,5.9-3.6,7.7c-1.7,1.3-4.1,2.3-7.5,1.9c0,0-15.2,1.4-16.6-44.8L60.2,332.3L60.2,332.3z"
				/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="86.7" y1="683.4" x2="61.2" y2="678.4"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="87.7" y1="570.2" x2="58.6" y2="560.2"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="87.7" y1="542" x2="61.4" y2="526.3"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="85.5" y1="554.4" x2="60.2" y2="545.3"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M380.4,332.3c0-0.6-0.8-0.7-1-0.2
				c-5,16.3-26.6,88.6-26.6,107.6V708c0,3,1.2,5.9,3.6,7.7c1.7,1.3,4.1,2.3,7.5,1.9c0,0,15.2,1.4,16.6-44.8V332.3H380.4z"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="353.9" y1="683.4" x2="379.4" y2="678.4"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="352.9" y1="570.2" x2="382" y2="560.2"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="352.9" y1="542" x2="379.2" y2="526.3"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="355.1" y1="554.4" x2="380.4" y2="545.3"/>
		</g>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M366.9,1068.4H74.5c-0.3,0-0.5-0.2-0.5-0.5v-305
			c0-0.3,0.2-0.5,0.5-0.5h292.4c0.3,0,0.5,0.2,0.5,0.5v305C367.4,1068.1,367.2,1068.4,366.9,1068.4z"/>
		<rect x="186.8" y="1084.4" fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" width="65" height="11.3"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M66.4,1055.4v12.3c0,0.1,0,0.1,0,0.2
			c0.1,1,0.9,11.3,11.6,12.7c1.7,0.2,3,1.7,2.9,3.4l0,0c-0.1,1.7-1.5,3-3.2,3H66.6c-0.5,0-0.9-0.1-1.4-0.3
			c-2.4-1.2-9.9-6.9-10.1-31.1c0-1.8,1.4-3.3,3.2-3.3h4.9C65,1052.2,66.4,1053.7,66.4,1055.4z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M374.7,1055.4v12.3c0,0.1,0,0.1,0,0.2
			c-0.1,1-0.9,11.3-11.6,12.7c-1.7,0.2-3,1.7-2.9,3.4l0,0c0.1,1.7,1.5,3,3.2,3h11.1c0.5,0,0.9-0.1,1.4-0.3
			c2.4-1.2,9.9-6.9,10.1-31.1c0-1.8-1.4-3.3-3.2-3.3h-4.9C376.2,1052.2,374.7,1053.7,374.7,1055.4z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M60.2,336.2c0-1.2-1.2-2-2.3-1.5
			c-8.7,3.7-38.9,17.2-42.5,25.2c0,0-5.5,7.2-3.4,13.4c0,0,0,0.1,0,0.3c0.3,5.4,9,7,11.1,6.3l37.2-18.3v-25.4H60.2z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M380.4,336.2c0-1.2,1.2-2,2.3-1.5
			c8.7,3.7,38.9,17.2,42.5,25.2c0,0,5.5,7.2,3.4,13.4c0,0,0,0.1,0,0.3c-0.3,5.4-9,7-11.1,6.3l-37.2-18.3v-25.4H380.4z"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="74.8" y1="957.1" x2="367.5" y2="957.1"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="74.8" y1="974.1" x2="367.5" y2="974.1"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="74.8" y1="991.6" x2="366.6" y2="991.6"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="1008.9" x2="366.6" y2="1008.9"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="1027.6" x2="368.5" y2="1027.6"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="1047" x2="368.5" y2="1047"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="941" x2="368.4" y2="941"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="921.3" x2="368.4" y2="921.3"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="902" x2="368.4" y2="902"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="882.7" x2="368.4" y2="882.7"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="860.8" x2="368.4" y2="860.8"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="838.9" x2="368.4" y2="838.9"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="818.8" x2="368.4" y2="818.8"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73" y1="799.1" x2="367.5" y2="799.1"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="778.4" x2="368.4" y2="778.4"/>
	</g>
</g>
<g id="Layer_2_00000025409314644289510640000013372325667582107023_">
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M135.3,338.2h53.9c0,0,7.8,1.5,11,15.4l0.6,34.4
			c0,0-4.1,19.7-15,25.8h-48.7c0,0-9.8-7.6-14.1-25.6V353C122.8,352.9,126.9,339.9,135.3,338.2z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M201.6,407.8l-1.1-2h-6.7l-4.5,5l-5.4,2.8l-22.5-0.2l0,0l-22.9,0.7
			l-4.8-3.9l-3.5-4.8l-7.6,0.4l-2.4,3.7l-1.1,10V450l0.9,7.8l2.8,5.4l3.5,3.5l5.4,2.8h7.6h3.3l0.2,2.2h18.4l0,0h18.4l0.2-2.2h3.3
			h7.6l5.4-2.8l3.5-3.5l2.8-5.4l0.9-7.8v-30.5l-0.9-8C202.7,410.3,202.3,409,201.6,407.8z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="161.4,448.9 138.4,448.9 138.4,469 142.7,473.8 
			161.4,473.8 180.2,473.8 184.5,469 184.5,448.9 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M248.2,334.5h55.3c0,0,8,1.5,11.3,15.8l0.6,35.3
			c0,0-4.2,20.2-15.5,26.5h-50c0,0-10.1-7.8-14.5-26.3v-36.1C235.4,349.6,239.6,336.2,248.2,334.5z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M316.3,406.1l-1.1-2.1h-6.9l-4.6,5.2l-5.5,2.9l-23.1-0.2l0,0
			l-23.5,0.8l-5-4l-3.6-5l-7.8,0.4l-2.5,3.8l-1.1,10.3v31.3l1,8l2.9,5.5l3.6,3.6l5.5,2.9h7.8h3.4l0.2,2.3h18.9l0,0h18.9l0.2-2.3h3.4
			h7.8l5.5-2.9l3.6-3.6l2.9-5.5l1-8v-31.3l-1-8.2C317.4,408.5,317.1,407.2,316.3,406.1z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="275.1,448.2 251.4,448.2 251.4,468.8 255.8,473.8 
			275.1,473.8 294.3,473.8 298.7,468.8 298.7,448.2 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M322.9,640.7h-206c-3.1,0-5.6-2.5-5.6-5.6v-48.4
			c0-3.1,2.5-5.6,5.6-5.6h205.9c3.1,0,5.6,2.5,5.6,5.6v48.4C328.5,638.2,326,640.7,322.9,640.7z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M328.9,670.7H112.6c-2,0-3.6-1.6-3.6-3.5l3.5-23
			c0-1.9,1.6-3.5,3.6-3.5h207.5c2,0,2.6,1.6,3.6,3.5l5.3,23C332.6,669.1,330.9,670.7,328.9,670.7z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.6,667.4c0,1.9,0,5.5-3.6,5.5H112.6c-3.6-0.3-3.6-3.8-3.6-5.7"
			/>
	</g>
</g>
      </>
    ),
  },

  exterior: {
    width: 819.1,
    height: 1243.9,
    svg: (
      <>
<g id="Layer_1">
	<g>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M510.6,433.3c0.7-1.6,1-3.3,1-5v-84.4
			c-0.8-33.8-17.9-47.6-17.9-47.6c-24.2-24.4-48.6-32.1-48.6-32.1h-40.4H400h-40.4c0,0-24.4,7.8-48.6,32.1c0,0-17,13.8-17.9,47.6
			v84.4c0,1.7,0.3,3.4,1,5c1.2,2.9,3.1,7.8,3.1,10.2v7.3l-1.5,94.3v155.7h2l-4.3,23.5c-0.2,1.3-0.4,2.6-0.4,3.9v85.2
			c0,0,5.8,73.1,7.5,83.6c0,0,1.7,11.1,12.2,12.5H366H400h4.7h34.1h53.2c10.5-1.4,12.2-12.5,12.2-12.5c1.7-10.5,7.5-83.6,7.5-83.6
			v-85.2c0-1.3-0.1-2.6-0.4-3.9l-4.3-23.5h2V545.1l-1.5-94.3v-7.3C507.4,441,509.4,436.1,510.6,433.3z"/>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M488,439.2l-0.8-13.1
			c-0.1-1.3-0.7-2.5-1.7-3.2c-13.9-10-31.8-13.3-31.8-13.3c-13.8-2.2-40.6-3.4-50.1-3.8v-0.1c0,0-0.6,0-1.7,0.1
			c-1.1,0-1.7-0.1-1.7-0.1v0.1c-9.5,0.4-36.3,1.6-50.1,3.8c0,0-17.9,3.3-31.8,13.3c-1,0.8-1.7,2-1.7,3.2l-0.8,13.1
			c-0.2,3.9,0,7.8,0.7,11.7l9.2,49.3c0.9,4.6,5,8,9.7,7.5c0.1,0,0.1,0,0.2,0h64.6h3.5h64.6c0.1,0,0.1,0,0.2,0
			c4.7,0.4,8.9-2.9,9.7-7.5l9.2-49.3C488,447,488.2,443.1,488,439.2z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M477.5,432.5c-0.3,0-0.4-0.1-0.4-0.1h-75.3
			h-75.3c0,0-0.1,0-0.4,0.1c-3.8,0.6-6.3,4.4-5.5,8.1l11.3,59.1c0,0,0.4,3.3,6.3,3.3h63.7h63.7c5.8,0,6.3-3.3,6.3-3.3l11.3-59.1
			C483.8,436.9,481.3,433.2,477.5,432.5z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M401.8,279.4h36.7c0,0,12.5,0.8,23.8,9.7
			l23.7,20.2c0,0,7.3,6.5,9.1,19.3c0.3,2,0.4,4,0.4,5.9v60.6c0,7.6-1.1,15.2-3.4,22.4c-1.1,3.5-2.5,6.7-4.1,8.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M402.4,279.4h-36.7c0,0-12.5,0.8-23.8,9.7
			l-23.7,20.2c0,0-7.3,6.5-9.1,19.3c-0.3,2-0.4,4-0.4,5.9v60.6c0,7.6,1.1,15.2,3.4,22.4c1.1,3.5,2.5,6.7,4.1,8.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M328.8,416.7c0,0,23-91.5,24.1-134"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M475.7,416.7c0,0-23-91.5-24.1-134"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M357.5,280.5l-16.4-2.3
				c-1-0.2-1.1-1.5-0.2-1.8l20.8-7.5c0.1,0,0.2-0.1,0.3-0.1h39.9"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M444.7,280.5l16.4-2.3c1-0.2,1.1-1.5,0.2-1.8
				l-20.8-7.5c-0.1,0-0.2-0.1-0.3-0.1h-39.9"/>
		</g>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M326.2,286.5l-14.1,12.6
			c0,0-7.4,8.6-10.6,17.7c0,0-4.7,10.5,4.6,10.3c9.3-0.2,7.7-0.2,9.3-3.1c1.6-2.9,3.7-14.6,7.9-17.5c2.9-2.1,9.7-7.8,13.4-10.9
			c2.3-1.9,3.8-4.7,3.9-7.7c0.1-3.3-1.4-6.5-8.6-4.5C329.8,283.9,327.9,285,326.2,286.5z"/>
		<path fill="#E5E5E4" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M478.8,286.5l14.1,12.6
			c0,0,7.4,8.6,10.6,17.7c0,0,4.7,10.5-4.6,10.3c-9.3-0.2-7.7-0.2-9.3-3.1c-1.6-2.9-3.7-14.6-7.9-17.5c-2.9-2.1-9.7-7.8-13.4-10.9
			c-2.3-1.9-3.8-4.7-3.9-7.7c-0.1-3.3,1.4-6.5,8.6-4.5C475.2,283.9,477.2,285,478.8,286.5z"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="332.4" y1="507.7" x2="332.4" y2="694.3"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="470.9" y1="506.6" x2="470.9" y2="693.3"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="331.6" y1="693.5" x2="471.7" y2="693.5"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M305.6,448.8c0-0.4,0.5-0.4,0.6-0.1
				c3,9.8,16,53.4,16,64.8v161.7c0,1.8-0.7,3.6-2.1,4.7c-1,0.8-2.5,1.4-4.5,1.1c0,0-9.1,0.8-10-27V448.8z"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="321.6" y1="660.5" x2="306.2" y2="657.4"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="322.2" y1="592.2" x2="304.7" y2="586.2"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="322.2" y1="575.2" x2="306.3" y2="565.8"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="320.9" y1="582.7" x2="305.6" y2="577.2"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M498.6,448.8c0-0.4-0.5-0.4-0.6-0.1
				c-3,9.8-16,53.4-16,64.8v161.7c0,1.8,0.7,3.6,2.1,4.7c1,0.8,2.5,1.4,4.5,1.1c0,0,9.1,0.8,10-27V448.8z"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="482.6" y1="660.5" x2="498" y2="657.4"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="482" y1="592.2" x2="499.5" y2="586.2"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="482" y1="575.2" x2="497.8" y2="565.8"/>
			<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="483.3" y1="582.7" x2="498.6" y2="577.2"/>
		</g>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M490.2,892.4H314.4c-0.3,0-0.5-0.2-0.5-0.5
			V708.5c0-0.3,0.2-0.5,0.5-0.5h175.8c0.3,0,0.5,0.2,0.5,0.5v183.4C490.7,892.2,490.5,892.4,490.2,892.4z"/>
		<rect x="381.9" y="902.1" fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" width="39.2" height="6.8"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M309.4,884.6v7.4c0,0,0,0.1,0,0.1
			c0,0.6,0.6,6.8,7,7.6c1,0.1,1.8,1,1.7,2l0,0c-0.1,1-0.9,1.8-1.9,1.8h-6.7c-0.3,0-0.6-0.1-0.8-0.2c-1.5-0.7-6-4.2-6.1-18.8
			c0-1.1,0.9-2,1.9-2l2.9,0C308.5,882.7,309.4,883.6,309.4,884.6z"/>
		<path fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M495.2,884.6v7.4c0,0,0,0.1,0,0.1
			c0,0.6-0.6,6.8-7,7.6c-1,0.1-1.8,1-1.7,2l0,0c0.1,1,0.9,1.8,1.9,1.8h6.7c0.3,0,0.6-0.1,0.8-0.2c1.5-0.7,6-4.2,6.1-18.8
			c0-1.1-0.9-2-1.9-2l-2.9,0C496,882.7,495.2,883.6,495.2,884.6z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M305.6,451.2c0-0.7-0.7-1.2-1.4-0.9
			c-5.2,2.3-23.5,10.4-25.6,15.2c0,0-3.3,4.4-2.1,8.1c0,0,0,0.1,0,0.2c0.2,3.3,5.4,4.2,6.7,3.8l22.4-11V451.2z"/>
		<path fill="#FFFFFF" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" d="M498.6,451.2c0-0.7,0.7-1.2,1.4-0.9
			c5.2,2.3,23.5,10.4,25.6,15.2c0,0,3.3,4.4,2.1,8.1c0,0,0,0.1,0,0.2c-0.2,3.3-5.4,4.2-6.7,3.8l-22.4-11V451.2z"/>
		<rect x="398.9" y="513.6" fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" width="6.1" height="13.4"/>
		<line fill="none" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" x1="402.4" y1="527" x2="402.4" y2="541.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="314.4" y1="826" x2="492" y2="826"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="314.4" y1="836.2" x2="492" y2="836.2"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="314.4" y1="846.7" x2="492" y2="846.7"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="312.7" y1="858.6" x2="490.2" y2="858.6"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="312.9" y1="870.5" x2="490.4" y2="870.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.4" y1="880.3" x2="490.9" y2="880.3"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="816.3" x2="491.4" y2="816.3"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="804.4" x2="491.4" y2="804.4"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="792.7" x2="491.4" y2="792.7"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="781.1" x2="491.4" y2="781.1"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="767.9" x2="491.4" y2="767.9"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="754.7" x2="491.4" y2="754.7"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="742.6" x2="491.4" y2="742.6"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.4" y1="730.7" x2="490.9" y2="730.7"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="313.9" y1="718.3" x2="491.4" y2="718.3"/>
	</g>
</g>
<g id="Layer_2">
	<g>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M331,188.7V226c0,3.3-2.7,6-6,6h-18.8c-3.3,0-6-2.7-6-6v-50.7"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M478.6,188.7V226c0,3.3,2.7,6,6,6h18.8c3.3,0,6-2.7,6-6v-50.7"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M331,205.5c0,0,2.5-7.4,10.2-7.7h20.4c0,0,4.5,0.3,6.8,4.7h24.2
				c0,0,4.8,4.8,12.6,4.7"/>
			<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M478.6,205.5c0,0-2.5-7.4-10.2-7.7H448c0,0-4.5,0.3-6.8,4.7H417
				c0,0-4.8,4.8-12.6,4.7"/>
		</g>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="378.2" y1="184.5" x2="378.2" y2="202.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432" y1="184.5" x2="432" y2="202.5"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="412.8" y1="184.5" x2="412.8" y2="205.3"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="396.9" y1="184.5" x2="396.9" y2="205.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M513.2,110.4c-0.1-1.2-0.7-3.8-1.4-6.6
			c-1.9-7.3-6.7-13.6-13.4-17.2c0,0,0,0,0,0L496.8,70l-10.8-38.6c-1.3-2.5-3-4.3-4.8-5.5c-2.5-1.7-5.6-2.6-8.6-2.6l-67.9-1l-67.9,1
			c-3.1,0-6.1,0.9-8.6,2.6c-1.7,1.2-3.5,2.9-4.8,5.5L312.6,70L311,86.6c0,0,0,0,0,0c-6.6,3.7-11.5,9.9-13.4,17.2
			c-0.7,2.8-1.3,5.4-1.4,6.6c0,0-2.2,19.8,0,36.3l3.4,27c0,0,1.8,6.3,3.5,10.7c1,2.6,3.5,4.3,6.2,4.3l43.4,0c0,0,3.7-0.4,6.3-2.5
			c1.5-1.2,3.4-1.7,5.2-1.7l40.5,0l40.5,0c1.9,0,3.8,0.5,5.2,1.7c2.6,2.1,6.3,2.5,6.3,2.5l43.4,0c2.8,0,5.3-1.7,6.2-4.3
			c1.7-4.5,3.5-10.7,3.5-10.7l3.4-27C515.4,130.2,513.2,110.4,513.2,110.4z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M486.8,82.8H322.6c-1.7,0-3-1.6-2.5-3.3
			l11.3-39.3c0.5-1.6,1.9-2.7,3.6-2.7h138.8c1.9,0,3.6,1.2,4.2,3L490,78.5C490.7,80.6,489.1,82.8,486.8,82.8z"/>
		<rect x="403.5" y="22.4" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="3.7" height="15.2"/>
		<rect x="335.3" y="23.3" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="1.9" height="14.3"/>
		<rect x="472.7" y="23.3" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="1.9" height="14.3"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M308.5,88.1c0,0,1.1,5.8,2.4,9.8
				c0.9,2.9,3.3,5.1,6.2,5.9c5.6,1.5,17.6,4.2,39.7,4.2h51.4"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M500.3,88.1c0,0-1.1,5.8-2.4,9.8
				c-0.9,2.9-3.3,5.1-6.2,5.9c-5.6,1.5-17.6,4.2-39.7,4.2h-51.4"/>
		</g>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M336.2,83.6c0,0,18.5,10.7,20.2,23.7"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M473.6,83.6c0,0-18.5,10.7-20.2,23.7"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M465.6,129.9c-2.4-5.2-9.2-13.1-12.8-17.1
			c-1.5-1.6-3.6-2.6-5.8-2.6h-41.6h-0.8h-41.6c-2.2,0-4.3,0.9-5.8,2.6c-3.6,4-10.4,12-12.8,17.1c-0.5,1-0.7,2.1-0.7,3.1
			c0,2.6,0.3,7.6,2.6,11.6c1.3,2.3,3.9,3.7,6.6,3.7h51.7h0.8h51.7c2.7,0,5.3-1.3,6.6-3.7c2.3-4,2.6-9,2.6-11.6
			C466.3,132,466.1,130.9,465.6,129.9z"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M341.8,188.7c0,0,10.5-17.7,15.8-23.3
				c0.9-0.9,2-1.4,3.3-1.4h44.3"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M467.1,188.7c0,0-10.5-17.7-15.8-23.3
				c-0.9-0.9-2-1.4-3.3-1.4h-44.3"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M357.6,187.1c0,0,1.9-4.4,6.5-9.9
				c0.7-0.9,1.8-1.4,2.9-1.4h39"/>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M454.6,188.3c0,0-3.3-5.5-7.9-11.1
				c-0.7-0.9-1.8-1.4-2.9-1.4h-39"/>
		</g>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M307.1,135.1v27.1l0,0c0.4,3.1,3,5.3,6.1,5.3
			h8.6c0,0,3.1-0.1,4-3.5c0,0,1.6-4.9-3.7-6.9c0,0-6.3-1.2-7.6-6.4v-13.8c0-1.7-1.3-3-3-3h-3.1C307.6,133.9,307.1,134.4,307.1,135.1
			z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M504.1,135.1v27.1l0,0c-0.4,3.1-3,5.3-6.1,5.3
			h-8.6c0,0-3.1-0.1-4-3.5c0,0-1.6-4.9,3.7-6.9c0,0,6.3-1.2,7.6-6.4v-13.8c0-1.7,1.3-3,3-3h3.1C503.6,133.9,504.1,134.4,504.1,135.1
			z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M356.4,108c0,0-13.4,15.9-17.3,18
			c-0.3,0.2-0.7,0.2-1,0.2l-25.7,0c-3.1,0-5.7-2.1-6.3-5.1c-0.2-1-0.4-2.2-0.6-3.7c0,0-0.5-10.9,6.7-16.9"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M453.2,108c0,0,13.4,15.9,17.3,18
			c0.3,0.2,0.7,0.2,1,0.2l25.7,0c3.1,0,5.7-2.1,6.3-5.1c0.2-1,0.4-2.2,0.6-3.7c0,0,0.5-10.9-6.7-16.9"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="357.1" y1="113.4" x2="453.2" y2="113.4"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="353.8" y1="116.4" x2="456.3" y2="116.4"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="351.7" y1="119.5" x2="458.5" y2="119.5"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="349" y1="122.5" x2="461.1" y2="122.5"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="346.3" y1="125.5" x2="463" y2="125.5"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="128.7" x2="465" y2="128.7"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="132" x2="466.3" y2="132"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="135.2" x2="466.3" y2="135.2"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.2" y1="138.4" x2="466.1" y2="138.4"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="344.4" y1="141.6" x2="465" y2="141.6"/>
		<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="346.3" y1="144.4" x2="463.7" y2="144.4"/>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M312,78.9c0,5.8-1.8,10.5-13.1,10.5
				c-10.7,0-19.8-4.2-20.8-9.6c-0.1-0.3-0.1-0.6-0.1-0.9c0-5.8,9.5-10.5,20.8-10.5S312,73,312,78.9z"/>
		</g>
		<g>
			<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M498,78.9c0,5.8,1.8,10.5,13.1,10.5
				c10.7,0,19.8-4.2,20.8-9.6c0.1-0.3,0.1-0.6,0.1-0.9c0-5.8-9.5-10.5-20.8-10.5C499.9,68.3,498,73,498,78.9z"/>
		</g>
	</g>
</g>
<g id="Layer_3">
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M266.6,1113.9v64.3c0,0.8,0.7,1.5,1.5,1.5h41.2
		c0,0,0.2,0,0.4,0c3.2-0.1,5.6-0.9,5.6-4.2v-40.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M309,1180.1v35.2c0,2.3-2.6,4.2-5.8,4.2h-27.5
		c-3.2,0-5.8-1.9-5.8-4.2v-35.6"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M538.6,1113.9v64.3c0,0.8-0.7,1.5-1.5,1.5H496
		c0,0-0.2,0-0.4,0c-3.2-0.1-5.6-0.9-5.6-4.2v-40.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M496.3,1179.7v35.6c0,2.3,2.6,4.2,5.8,4.2h27.5
		c3.2,0,5.8-1.9,5.8-4.2v-35.6"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M315.3,1176.9c0,0,3.6,0.2,6.1-2.5
		c0.5-0.5,1.2-0.8,1.9-0.8h24.2c0,0,2,5.1,5.3,5.3h37.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M489.8,1176.9c0,0-3.6,0.2-6.1-2.5
		c-0.5-0.5-1.2-0.8-1.9-0.8h-24.2c0,0-2,5.1-5.3,5.3h-37.1"/>
	<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M416,1178.4c-7.8,7.5-20.8,6-26.7,0.1"/>
	<line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="315.3" y1="1158" x2="490.1" y2="1158"/>
	<rect x="363.3" y="1146.8" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="77.3" height="11.2"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M525.1,1030.7l-3.6-16.8l-14.7-50.1
		c-5.1-13.7-17.8-13.7-17.8-13.7l-85.9-2v0l-0.5,0l-0.5,0l0,0l-85.9,2c0,0-12.7,0-17.8,13.7l-14.7,50.1l-3.6,16.8
		c0,0-13,11.9-15.8,33.8v31.1c0,5.8,0.7,11.6,2,17.3c1.8,7.5,4.4,18.1,6.9,24.3c0.7,1.9,2.6,3.1,4.6,3.1l16,0v-2.4
		c0-1.4,1.1-2.5,2.5-2.5h35.1c1.4,0,2.8,0.6,3.8,1.6l3.4,3.4h17.6h46.2h0.2h46h17.6l3.4-3.4c1-1,2.4-1.6,3.8-1.6h35.1
		c1.4,0,2.5,1.1,2.5,2.5v2.4l16,0c2,0,3.9-1.2,4.6-3.1c2.4-6.3,5.1-16.8,6.9-24.3c1.3-5.7,2-11.5,2-17.3v-31.1
		C538.1,1042.6,525.1,1030.7,525.1,1030.7z"/>
	<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="282.3" y1="1020.6" x2="523.1" y2="1020.6"/>
	<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="264.4" y1="1101.8" x2="540.5" y2="1101.8"/>
	<rect x="302.7" y="1020.6" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="199.6" height="81.2"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M285.6,1030.8c-1,0-2,0.5-2.7,1.3
		c-1.7,2.1-5,7.2-7.7,17.5c-0.3,1-0.4,2-0.4,2.9l0,33.3c0,0,0.3,2.4,2.2,2.7h22.8c0.6,0,1.1-0.5,1.1-1.1v-9.4c0-0.4-0.3-0.8-0.8-0.8
		h-14c-0.4,0-0.7-0.3-0.7-0.7v-13.8c0-0.5,0.4-0.9,0.9-0.9h13.6c0.5,0,0.9-0.4,0.9-0.9v-12c0-0.5-0.4-0.9-0.9-0.9h-12.1
		c-0.6,0-1-0.5-1-1v-6.5c0-0.5,0.4-1,1-1h12.3c0.5,0,0.9-0.4,0.9-0.9v-6.8c0-0.5-0.4-1-1-1H285.6z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M519.5,1030.8c1,0,2,0.5,2.7,1.3
		c1.7,2.1,5,7.2,7.7,17.5c0.3,1,0.4,2,0.4,2.9v33.3c0,0-0.3,2.4-2.2,2.7h-22.8c-0.6,0-1.1-0.5-1.1-1.1v-9.4c0-0.4,0.3-0.8,0.8-0.8
		h14c0.4,0,0.7-0.3,0.7-0.7v-13.8c0-0.5-0.4-0.9-0.9-0.9h-13.6c-0.5,0-0.9-0.4-0.9-0.9v-12c0-0.5,0.4-0.9,0.9-0.9h12.1
		c0.6,0,1-0.5,1-1v-6.5c0-0.5-0.4-1-1-1h-12.3c-0.5,0-0.9-0.4-0.9-0.9v-6.8c0-0.5,0.4-1,1-1H519.5z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M428.1,1037.1h-52.4c-0.2,0-0.3-0.1-0.3-0.3v-7
		c0-0.2,0.1-0.3,0.3-0.3h52.4c0.2,0,0.3,0.1,0.3,0.3v7C428.4,1037,428.3,1037.1,428.1,1037.1z"/>
	<rect x="306.8" y="1038" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="21.5" height="5.3"/>
	<rect x="476.4" y="1038" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="21.5" height="5.3"/>
	<rect x="278.2" y="1101.8" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="73.5" height="7.5"/>
	<rect x="453.2" y="1101.8" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="73.5" height="7.5"/>
	<rect x="351.9" y="1101.8" fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" width="101.3" height="38.6"/>
	<line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="302.7" y1="1087" x2="502.3" y2="1087"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M401.9,964.9"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M401.9,1025h-36.7c-0.8,0-1.5,0.7-1.5,1.5v9.2
		c0,0.4,0.1,0.8,0.4,1l17.4,18.7c0.3,0.3,0.7,0.5,1.1,0.5h19.2h19.2c0.4,0,0.8-0.2,1.1-0.5l17.4-18.7c0.3-0.3,0.4-0.7,0.4-1v-9.2
		c0-0.8-0.7-1.5-1.5-1.5H401.9z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M281.2,1022.5c0,6.6-3.6,12-14.6,12
		c-10.9,0-19.8-5.4-19.8-12s8.9-12,19.8-12C277.6,1010.6,281.2,1015.9,281.2,1022.5z"/>
	<path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M523.5,1022.5c0,6.6,3.6,12,14.6,12
		s19.8-5.4,19.8-12s-8.9-12-19.8-12S523.5,1015.9,523.5,1022.5z"/>
	<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M494.2,971.1c-3.4-6.3-11.5-6.3-11.5-6.3h-79.3h-0.6h-79.3
		c0,0-8.1,0-11.5,6.3c0,0-6.6,10.8-7.3,19.3v19.8c0,0,0.5,2.4,3.6,2.5h94.6h0.6h94.6c3-0.2,3.6-2.5,3.6-2.5v-19.8
		C500.8,982,494.2,971.1,494.2,971.1z"/>
	<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="314.8" y1="967.4" x2="314.8" y2="952.2"/>
	<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="489.9" y1="967.4" x2="489.9" y2="952.2"/>
</g>
<g id="Layer_4">
	<g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M51.9,342v-13.1c0,0,11.2-14.4,15.4-16.6
			v16.2h25.6c0,0,26.1,6.6,34.3,36.2v39.9c0,0,1,30-40.4,40.4l-15.8,3l4.9,242.1l0.4,21.7l4.5,30.3c0,0,21.5,0,40.2,13.7
			c0,0,12.6,9.4,15.5,22.2v48.9c0,0-1.6,14.4-22.4,28.3c0,0-15.3,6.3-27.8,6.3l7.3,51.6l-12.9,15.4l-6.8-3.9l6.8-13.5l-8.8-64.1
			l-8.5-96.6L51.9,432.4V342z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M67.3,328.4V302c0-10.5,2.3-21,7.1-30.4c3-5.8,7.1-11.6,12.9-16.2
			c0.8-0.6,2-0.4,2.5,0.5l1.2,2h8.5v-3.6h0c2.9,0,5.7,1,7.9,2.9l5.1,4.3v9.1c0,1.7,1.4,3.1,3.1,3.1h33.7c1.5,0,2.8-1.2,2.8-2.8v-7.6
			c0,0,9.9,1.3,13.5,20.7c0,0,13.9,63.3,18.9,144.6c0,0-0.9,8.1,2.7,11.2c0,0,46.2,53.4,59.3,106c0,0,6.7,35.9,6.3,65.1v96.4
			c0,4.6-1.5,9.2-4.6,12.6c-2.8,3.1-7.2,5.9-14.3,6.4l-44,1.8l1.8,212.6c0,1.5-0.8,2.8-2.1,3.5l-6.8,3.4h-61.5v-16.6l-26.5-9.4
			l-8.5-60.2c0,0,14.8-0.4,27.8-6.3c0,0,19.3-13,22.4-28.3v-48.9c0,0-7.2-32.8-55.7-35.9l-4.5-28.7l-5.4-265.3c0,0,56.1-4,56.6-47.6
			v-34.1c0,0-4.5-29.2-34.6-36.8L67.3,328.4z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M159.8,763h-12.6c-2,0-3.6-1.6-3.6-3.6v-18.8c0-2,1.6-3.6,3.6-3.6
			h12.6c2,0,3.6,1.6,3.6,3.6v18.8C163.4,761.4,161.8,763,159.8,763z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M186.3,676.8l-8.8-205.6c0,0,4.2-10.2,13.8-1.2
			c0,0,24.7,17.9,39.3,68.6c2.8,9.7,4.6,19.6,5.5,29.6l1.9,20.3v104.5c0,0,0.3,6.9-7.5,8.7c0,0-21.2,6.9-41.4-17.6
			C187.3,682.1,186.4,679.5,186.3,676.8z"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="237.2" y1="682.9" x2="188.7" y2="682.9"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="237.2" y1="610.6" x2="183.3" y2="608.4"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="237.2" y1="589.2" x2="182.2" y2="582.9"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="237.2" y1="599.5" x2="182.8" y2="596.4"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M184.4,427.2c0,0-15.8-20.2-20-73.1c0,0-19-12.4-22.3-22
			c0,0-2.7-13,0.7-20.8c0,0,3.9-15.1-0.5-28.9c-0.3-0.9,0.3-1.8,1.3-1.9c2.2-0.1,5.9,0.3,9.3,3.6c1.8,1.7,2.9,4,3.6,6.3
			c2.4,8.4,9.6,36.9,8,63.7"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M171.5,396.6v83.7c0,0-1.5,12-6.3,16.8"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M182.8,596.4c0,0-36.3-3.7-108.2,1.6"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M171.5,451.5h-50.3c0,0-20.7,0.4-33.1,9.9
			c-5.5,4.2-8.5,10.9-8.5,17.8l1,117.3"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M189.9,727.9c0,0-69.7-0.4-102-14.3c-2.1-0.9-3.6-2.8-4-5
			c-1.1-5.8-2.7-20.5-3-57.4c-0.4-56.6-0.2-53.6-0.2-53.6"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M128.7,297.2v6.7H95.6c0,0-5.8-0.4-7-4.9
			l0-7.4c0-0.9,0.5-1.7,1.2-2c1.6-0.8,4.2-1.2,8.2-1c1.8,0.1,3.4,1.1,4.1,2.7c0.5,1.1,0.9,2.6,1.1,4.8c0.1,0.8,0.7,1.4,1.5,1.4
			L128.7,297.2z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M152.4,263.9l-1.5-1.7
			c-0.5-0.5-1.1-0.8-1.8-0.8H125c-0.7,0-1.3,0.4-1.5,1l-0.9,2.2h-9.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M160.7,585.2v-29.9c0-1.2,1-2.2,2.2-2.2h3.3
			c1.2,0,2.2,1,2.2,2.2v29.9c0,1.2-1,2.2-2.2,2.2h-3.3C161.7,587.4,160.7,586.4,160.7,585.2z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M164.5,704.8v-29.9c0-1.2,1-2.2,2.2-2.2h3.3
			c1.2,0,2.2,1,2.2,2.2v29.9c0,1.2-1,2.2-2.2,2.2h-3.3C165.5,707,164.5,706,164.5,704.8z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M181.8,947.5v-3.9c0-1.3-0.6-2.5-1.7-3.2
			L147,918c-1.4-0.9-2.9-1.4-4.6-1.4h-5.9c0,0-3.6,0.6-4.6,3.7v27.1H181.8z"/>
		<polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="114.5,952.2 114.4,928.2 120.9,930.6 
			120.9,952.2 		"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M113.9,952.2H98.1c0,0-0.1,0-0.2,0
			c-5.6-0.5-9.9-5.3-9.9-10.9l0-19.8h6.7l19.2,7.4V952.2z"/>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="65.7" cy="799" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="65.7" cy="799" r="25.7"/>
		</g>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="67.3" cy="387.2" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="67.3" cy="387.2" r="25.7"/>
		</g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M187.2,497c-5.9,0-10.7-10.2-10.7-22.8
			c0-12.6,4.8-10.1,10.7-10.1c5.9,0,10.7-2.5,10.7,10.1C197.9,486.8,193.1,497,187.2,497z"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M207.7,737.3v-1.9c0-0.7,0.6-1.3,1.3-1.3
			h36.9c0.7,0,1.3,0.6,1.3,1.3v1.9c0,0.7-0.6,1.3-1.3,1.3H209C208.2,738.6,207.7,738,207.7,737.3z"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M190,752.7L190,752.7
			c-0.8-1-0.6-2.4,0.3-3.2l18-14.7c1-0.8,2.4-0.6,3.2,0.3l0,0c0.8,1,0.6,2.4-0.3,3.2l-18.3,14.9C191.8,754,190.7,753.7,190,752.7z"
			/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M189.5,731.9l0.5-1.9
			c0.2-0.7,0.9-1.1,1.6-0.9l19.7,5.6c0.7,0.2,1.1,0.9,0.9,1.6l-0.5,1.9c-0.2,0.7-0.9,1.1-1.6,0.9l-19.7-5.6
			C189.7,733.3,189.3,732.5,189.5,731.9z"/>
	</g>
	<g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M741.9,342v-13.1c0,0-11.2-14.4-15.4-16.6
			v16.2h-25.6c0,0-26.1,6.6-34.3,36.2v39.9c0,0-1,30,40.4,40.4l15.8,3l-4.9,242.1l-0.4,21.7l-4.5,30.3c0,0-21.5,0-40.2,13.7
			c0,0-12.6,9.4-15.5,22.2v48.9c0,0,1.6,14.4,22.4,28.3c0,0,15.3,6.3,27.8,6.3l-7.3,51.6l12.9,15.4l6.8-3.9l-6.8-13.5l8.8-64.1
			l8.5-96.6l11.4-317.8V342z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M726.4,328.4V302c0-10.5-2.3-21-7.1-30.4c-3-5.8-7.1-11.6-12.9-16.2
			c-0.8-0.6-2-0.4-2.5,0.5l-1.2,2h-8.5v-3.6l0,0c-2.9,0-5.7,1-7.9,2.9l-5.1,4.3v9.1c0,1.7-1.4,3.1-3.1,3.1h-33.7
			c-1.5,0-2.8-1.2-2.8-2.8v-7.6c0,0-9.9,1.3-13.5,20.7c0,0-13.9,63.3-18.9,144.6c0,0,0.9,8.1-2.7,11.2c0,0-46.2,53.4-59.3,106
			c0,0-6.7,35.9-6.3,65.1v96.4c0,4.6,1.5,9.2,4.6,12.6c2.8,3.1,7.2,5.9,14.3,6.4l44,1.8l-1.8,212.6c0,1.5,0.8,2.8,2.1,3.5l6.8,3.4
			h61.5v-16.6l26.5-9.4l8.5-60.2c0,0-14.8-0.4-27.8-6.3c0,0-19.3-13-22.4-28.3v-48.9c0,0,7.2-32.8,55.7-35.9l4.5-28.7l5.4-265.3
			c0,0-56.1-4-56.6-47.6v-34.1c0,0,4.5-29.2,34.6-36.8L726.4,328.4z"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M607.5,676.8l8.8-205.6c0,0-4.2-10.2-13.8-1.2
			c0,0-24.7,17.9-39.3,68.6c-2.8,9.7-4.6,19.6-5.5,29.6l-1.9,20.3v104.5c0,0-0.3,6.9,7.5,8.7c0,0,21.2,6.9,41.4-17.6
			C606.4,682.1,607.4,679.5,607.5,676.8z"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="556.6" y1="682.9" x2="605.1" y2="682.9"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="556.6" y1="610.6" x2="610.4" y2="608.4"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="556.6" y1="589.2" x2="611.5" y2="582.9"/>
		<line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="556.6" y1="599.5" x2="610.9" y2="596.4"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M609.3,427.2c0,0,15.8-20.2,20-73.1c0,0,19-12.4,22.3-22
			c0,0,2.7-13-0.7-20.8c0,0-3.9-15.1,0.5-28.9c0.3-0.9-0.3-1.8-1.3-1.9c-2.2-0.1-5.9,0.3-9.3,3.6c-1.8,1.7-2.9,4-3.6,6.3
			c-2.4,8.4-9.6,36.9-8,63.7"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M622.3,396.6v83.7c0,0,1.5,12,6.3,16.8"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M610.9,596.4c0,0,36.3-3.7,108.2,1.6"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M622.3,451.5h50.3c0,0,20.7,0.4,33.1,9.9c5.5,4.2,8.5,10.9,8.5,17.8
			l-1,117.3"/>
		<path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M603.9,727.9c0,0,69.7-0.4,102-14.3c2.1-0.9,3.6-2.8,4-5
			c1.1-5.8,2.7-20.5,3-57.4c0.4-56.6,0.2-53.6,0.2-53.6"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M665,297.2v6.7h33.1c0,0,5.8-0.4,7-4.9v-7.4
			c0-0.9-0.5-1.7-1.2-2c-1.6-0.8-4.2-1.2-8.2-1c-1.8,0.1-3.4,1.1-4.1,2.7c-0.5,1.1-0.9,2.6-1.1,4.8c-0.1,0.8-0.7,1.4-1.5,1.4
			L665,297.2z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M641.3,263.9l1.5-1.7c0.5-0.5,1.1-0.8,1.8-0.8
			h24.2c0.7,0,1.3,0.4,1.5,1l0.9,2.2h9.3"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M633,585.2v-29.9c0-1.2-1-2.2-2.2-2.2h-3.3
			c-1.2,0-2.2,1-2.2,2.2v29.9c0,1.2,1,2.2,2.2,2.2h3.3C632.1,587.4,633,586.4,633,585.2z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M629.3,704.8v-29.9c0-1.2-1-2.2-2.2-2.2h-3.3
			c-1.2,0-2.2,1-2.2,2.2v29.9c0,1.2,1,2.2,2.2,2.2h3.3C628.3,707,629.3,706,629.3,704.8z"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M611.9,947.5v-3.9c0-1.3,0.6-2.5,1.7-3.2
			l33-22.3c1.4-0.9,2.9-1.4,4.6-1.4h5.9c0,0,3.6,0.6,4.6,3.7v27.1H611.9z"/>
		<polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="679.2,952.2 679.4,928.2 672.8,930.6 
			672.8,952.2 		"/>
		<path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M679.8,952.2h15.8c0,0,0.1,0,0.2,0
			c5.6-0.5,9.9-5.3,9.9-10.9l0-19.8H699l-19.2,7.4V952.2z"/>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="728" cy="799" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="728" cy="799" r="25.7"/>
		</g>
		<g>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="726.4" cy="387.2" r="47.8"/>
			<circle fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="726.4" cy="387.2" r="25.7"/>
		</g>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M606.5,497c5.9,0,10.7-10.2,10.7-22.8
			c0-12.6-4.8-10.1-10.7-10.1s-10.7-2.5-10.7,10.1C595.8,486.8,600.6,497,606.5,497z"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M586.1,737.3v-1.9c0-0.7-0.6-1.3-1.3-1.3
			h-36.9c-0.7,0-1.3,0.6-1.3,1.3v1.9c0,0.7,0.6,1.3,1.3,1.3h36.9C585.5,738.6,586.1,738,586.1,737.3z"/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M603.8,752.7L603.8,752.7
			c0.8-1,0.6-2.4-0.3-3.2l-18-14.7c-1-0.8-2.4-0.6-3.2,0.3l0,0c-0.8,1-0.6,2.4,0.3,3.2l18.3,14.9C601.9,754,603,753.7,603.8,752.7z"
			/>
		<path fill="#E8E7E7" stroke="#231F20" stroke-width="0.25" stroke-miterlimit="10" d="M604.2,731.9l-0.5-1.9
			c-0.2-0.7-0.9-1.1-1.6-0.9l-19.7,5.6c-0.7,0.2-1.1,0.9-0.9,1.6l0.5,1.9c0.2,0.7,0.9,1.1,1.6,0.9l19.7-5.6
			C604,733.3,604.4,732.5,604.2,731.9z"/>
	</g>
</g>
      </>
    ),
  },
};
