import React from "react"
export const Hatchback3DoorSVG = {
	interior: {
		width: 355,
		height: 680,
		svg: (<>
			<g id="Layer_2_00000114041336711337076660000014473655554808374443_">
				<g>
					<g>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M172.7,5.8c0,0,21.4,0,26.4,4.9l26.6,0.7c3.2,0.1,6.3,0.5,9.4,1.2
				c7.7,1.7,21.6,5.8,30.8,13.8c0,0,21.7,15.9,39.3,43.6c2.3,3.6,4.1,7.6,5.3,11.7l3.2,10.6l3.3,117v130.2v123.6v136.8
				c0,0,0,26.4-14.8,41.2c-14,14-26.4,24.7-64.3,29.7h-58.5"/>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M180.9,5.8c0,0-21.4,0-26.4,4.9L128,11.5c-3.2,0.1-6.3,0.5-9.4,1.2
				c-7.7,1.7-21.6,5.8-30.8,13.8c0,0-21.7,15.9-39.3,43.6c-2.3,3.6-4.1,7.6-5.3,11.7L40,92.4l-3.3,117v130.2v123.6V600
				c0,0,0,26.4,14.8,41.2c14,14,26.4,24.7,64.3,29.7h65.1"/>
					</g>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M107.2,26.1c-4.7,10-19,39.5-37,63.5c-2.1,2.8-5,4.8-8.4,5.6
			c-2,0.4-4.1,0.4-5.7-1c-0.9-0.8-1.3-1.9-1.4-3c0-0.1,0-0.2,0-0.4c-1-10.5,2.3-21,8.9-29.3c8.3-10.5,23.5-27.3,41.8-37.1
			C106.5,23.7,107.8,24.9,107.2,26.1z"/>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M244.1,26.1c4.7,10,19,39.5,37,63.5c2.1,2.8,5,4.8,8.4,5.6
			c2,0.4,4.1,0.4,5.7-1c0.9-0.8,1.3-1.9,1.4-3c0-0.1,0-0.2,0-0.4c1-10.5-2.3-21-8.9-29.3c-8.3-10.5-23.5-27.3-41.8-37.1
			C244.9,23.7,243.6,24.9,244.1,26.1z"/>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M71.3,585.3c0,0-11.5,6.6-16.5,16.5c0,0-3.3,9.2,0,17
			c0,0,5.6,12.2,19.5,24.5c1.4,1.2,3.6,0.2,3.6-1.6V587C77.9,586.9,76.2,583.6,71.3,585.3z"/>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M282.5,585.3c0,0,11.5,6.6,16.5,16.5c0,0,3.3,9.2,0,17
			c0,0-5.6,12.2-19.5,24.5c-1.4,1.2-3.6,0.2-3.6-1.6V587C275.9,586.9,277.6,583.6,282.5,585.3z"/>
					<g>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M53.4,198.8l19.6,131c0,0,7.6,49,7.9,91.2l-3,152.6
				c0,0-18.1-14.7-19.8-34.5l-4.8-140.6L53.4,198.8L53.4,198.8z"/>
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="80.9" y1="394.9" x2="53.4" y2="387.6" />
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="80.8" y1="423.3" x2="54" y2="416.5" />
						<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="80.6" y1="408.1" x2="53.4" y2="402.8" />
					</g>
					<g>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M298.5,198.8l-19.6,131c0,0-7.6,49-7.9,91.2l3,152.6
				c0,0,18.1-14.7,19.8-34.5l4.8-140.6L298.5,198.8L298.5,198.8z"/>
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="271" y1="394.9" x2="298.5" y2="387.6" />
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="271" y1="423.3" x2="297.9" y2="416.5" />
						<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="271.3" y1="408.1" x2="298.5" y2="402.8" />
					</g>
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M53.4,198.8c0,0-2.8-87.8,8.4-103.7" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M298.8,198.8c0,0,2.8-87.8-8.4-103.7" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M105.8,29.1c0,0,71-20.8,140.3,0.6
			C246,29.7,181.8,16.5,105.8,29.1z"/>
					<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="53" y1="216.6" x2="36.7" y2="207.1" />
					<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="298.8" y1="219.3" x2="316.8" y2="208.8" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M51.5,397.3c0,0-11.5,0.8-14.8-4.5" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M298.8,397.3c0,0,13.2,1.6,18.1-4.5" />
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M173.5,153.4l24.4,0.9c0,0,49.6-0.9,79.5,24.1
				c1.1,1,2.1,2.1,2.8,3.4c0.7,1.3,1.7,3,2.5,4.9c1.4,3.1,1.9,6.5,1.5,9.9l-13.5,111.2c-0.2,1.3-1.3,2.2-2.6,2.1
				c-10.9-0.8-59-4.5-93-4.8"/>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M173.5,153.4l-21.6,0.9
				c0,0-49.6-0.9-79.5,24.1c-1.1,1-2.1,2.1-2.8,3.4c-0.7,1.3-1.7,3-2.5,4.9c-1.4,3.1-1.9,6.5-1.5,9.9l13.5,111.2
				c0.2,1.3,1.3,2.2,2.6,2.1c10.9-0.8,59.5-4.5,93.5-4.8"/>
					</g>
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M66.4,188c0,0,18.1-44.5,108.8-44.5" />
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M284,189.6c0,0-8.2-42.9-110.4-46.2" />
					</g>
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M79.5,308.3c0,0,14.8,72.5,16.5,262.1h84.9" />
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M270.8,308.3c0,0-14.8,72.5-16.5,262.1h-84.9"
						/>
					</g>
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M76.2,585.3c0,0,9.9-14.8,19.8-14.8h3.5v9.3
			c0,2,0.2,4,0.9,5.8c1.7,5.2,4.9,6.6,10.2,8c0.1,0,0.2,0,0.2,0s37.9,3.3,64.3,3.3"/>
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M275.7,585.3c0,0-9.9-14.8-19.8-14.8h-3.5v9.3
			c0,2-0.2,4-0.9,5.8c-1.7,5.2-4.9,6.6-10.2,8c-0.1,0-0.2,0-0.2,0s-39.6,3.3-65.9,3.3"/>
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M175.2,617.7c0,0,52.8,2.2,85.7-9.3
				c0,0,2-0.7,3.8-0.7c1.5-0.1,2.6,1.2,2.6,2.6v12.5c0,3.1-0.9,6.1-2.7,8.7c-3.7,5.3-12.4,14-32.2,22.8c-2.9,1.3-6,1.9-9.1,2
				c-12.4,0.3-47.1,1.3-49.8,1.5"/>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M176.7,617.7c0,0-52.8,2.2-85.7-9.3
				c0,0-2-0.7-3.8-0.7c-1.5-0.1-2.6,1.2-2.6,2.6v12.5c0,3.1,0.9,6.1,2.7,8.7c3.7,5.3,12.4,14,32.2,22.8c2.9,1.3,6,1.9,9.1,2
				c12.4,0.3,47.1,1.3,49.8,1.5"/>
					</g>
					<path fill="#FFFFFF" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M36.7,246.3v-12.6c0-1.6-1.3-2.8-2.9-2.7
			l-8.9,0.6c0,0-5.7,2.9-10.7,7.5c-2.9,2.6-5.2,5.8-6.9,9.3l-3.6,7.2c0,0-1.6,4.9,1.6,6.6c0,0,7.7,3.1,29.1-11.6
			C35.8,249.6,36.7,248,36.7,246.3z"/>
					<path fill="#FFFFFF" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M316.9,246.3v-12.6c0-1.6,1.3-2.8,2.9-2.7
			l8.9,0.6c0,0,5.7,2.9,10.7,7.5c2.9,2.6,5.2,5.8,6.9,9.3l3.6,7.2c0,0,1.6,4.9-1.6,6.6c0,0-7.7,3.1-29.1-11.6
			C317.8,249.6,316.9,248,316.9,246.3z"/>
				</g>
			</g>
			<g id="Layer_3">
				<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M236.7,537.2l-118.4,1.3c-5,0-9.2-4.1-9.2-9.2
		l-8.4-49.7c0-5,4.1-9.2,9.2-9.2h132.9c5,0,9.2,4.1,9.2,9.2l-6.1,48.4C245.9,533.1,241.7,537.2,236.7,537.2z"/>
				<g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M218.7,339.3c0,0,23.3-1.6,33.6,16.1
				c0,0,4.9,11.8,0,28.8c0,0-3.2,12-1.8,17.8c0,0,4.3,12,2.9,18.4c0,0-2.5,7.2-10.3,8.5c0,0-8.1-0.4-12.8,4.1l-11.5-0.4"/>
						<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M219.7,339.3c0,0-23.3-1.6-33.6,16.1
				c0,0-4.9,11.8,0,28.8c0,0,3.2,12,1.8,17.8c0,0-4.3,12-2.9,18.4c0,0,2.5,7.2,10.3,8.5c0,0,8.1-0.4,12.8,4.1l11.5-0.4"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M188.2,400.5c0,0,33.6,6.6,62.2-0.1" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M184.8,414.4c0,0,35.5,10.7,68.7,0" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M203.1,430c0,0-0.2-5.7,4.3-7.3h13" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M235.5,430c0,0,0.2-5.7-4.3-7.3h-13" />
				</g>
				<g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M135.2,339.3c0,0,23.3-1.6,33.6,16.1
				c0,0,4.9,11.8,0,28.8c0,0-3.2,12-1.8,17.8c0,0,4.3,12,2.9,18.4c0,0-2.5,7.2-10.3,8.5c0,0-8.1-0.4-12.8,4.1l-11.5-0.4"/>
						<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M136.2,339.3c0,0-23.3-1.6-33.6,16.1
				c0,0-4.9,11.8,0,28.8c0,0,3.2,12,1.8,17.8c0,0-4.3,12-2.9,18.4c0,0,2.5,7.2,10.3,8.5c0,0,8.1-0.4,12.8,4.1l11.5-0.4"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M104.7,400.5c0,0,33.6,6.6,62.2-0.1" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M101.3,414.4c0,0,35.5,10.7,68.7,0" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M119.6,430c0,0-0.2-5.7,4.3-7.3h13" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M152,430c0,0,0.2-5.7-4.3-7.3h-13" />
				</g>
				<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="164.5" y1="470.5" x2="169.8" y2="537.7" />
				<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="190.5" y1="470.5" x2="185.1" y2="537.7" />
			</g>
		</>),
	},
	exterior: {
		width: 503,
		height: 738,
		svg: (<>
			<g id="side">
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M464.7,277.9c-17.9,0-32.5-16-32.5-35.7s14.6-35.7,32.5-35.7h14.1
				v-20.8l-3.6-1v-5.9c0,0-1-5.9-4-6.9H466c0,0-2.8,1-3.8,3h-6.7v-6.3h-11.9v2.3h-8.2l-1.5,1.5c0,0-10.5,4.4-19.5,17.3
				c0,0-14,19.8-23,77.3l-25,51.5c0,0-13,25.8-17,58.5c0,0-4,80.3,4,130.8l4,27.7h8.8l27.2,26.8h26.4l8.3,5.8
				c0.1,0.1,0.3,0.2,0.4,0.3l0,0c1.2,1.2,2.8,1.8,4.4,1.8h11.5c1.2,0,2.3-0.1,3.5-0.2l5-1.6c0.9-0.1,1.9-0.4,2.8-0.8
				c2.4-1.2,7.7-4,12.6-7.7c1.2-1,2.3-2.2,3.1-3.5l0,0c1.2-2,1.8-4.3,1.8-6.6v-2.9h-8.5c-17.9,0-32.5-16.4-32.5-36.7
				s14.6-36.7,32.5-36.7l11.5-1.5l2.6-0.3v-192L464.7,277.9z"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M402.2,270.8c1-13.9,8.5-38.5,8.5-38.5" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M455.7,286.1c-21.3,0-38.5-20.2-38.5-45.1
				c0-26.8,15-37.7,29-44.6"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M429.2,569c-8-21.8-35.5-36.1-35.5-36.1" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M438.2,531.2c-14,11-28,13-28,13" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="394.6" y1="532.9" x2="410.2" y2="402.5" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="410.6" y1="402.1" x2="413.2" y2="385.7" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="413.2" y1="371.8" x2="425.2" y2="268.8" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M427.9,477.1c-22.1,28.2-6.4,56.8-2.5,63
				c0.2,0.3-0.1,0.6-0.4,0.4c-7.6-5.4-11.6-12.9-13.6-19.1c-2.6-8.1-2.1-17,1.1-24.9c4.3-10.7,11.8-17.3,15.1-19.8
				C427.8,476.5,428.1,476.8,427.9,477.1z"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M438.2,568.4c-8-12.5,0.9-24.2,0.9-24.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="469.2" y1="472.4" x2="469.2" y2="288.6" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M435.2,195.4c0-17.8,6-20.8,6-20.8" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M403,271.3l-3.8,46.9l-7,92.2c0,14.2-6.4,71.5-8.4,89
				c-0.4,3.6-2.3,6.9-5.2,9.1c-3.4,2.6-6.5,4.1-8.6,5c-1.4,0.6-3.1-0.2-3.5-1.7l-6.6-23.3c-1.3-4.5-2.1-9.1-2.6-13.8
				c-2.2-22.8-3-64.3-3-64.3c1-23.7,5-49.5,5-49.5c11.2-46.9,35.7-81.3,42.3-90C402,270.3,403.1,270.6,403,271.3z"/>
					<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="354.5,404.5 393.4,394.7 392.7,404.9 354.3,409.4 		" />
					<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="392.7,404.9 392.1,415.3 354.2,416.8 354.3,409.4 		" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="401.6" y1="289.3" x2="387.1" y2="292.8" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M478.8,289.4l-16.5-2.4c-10-1.6-42.1-2.4-42.1-2.4c-7,0-19,5-19,5"
					/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M478.8,401.4c-21.4-3-84.5,1.9-84.5,1.9" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="410.7" cy="378.8" rx="3.5" ry="10.9" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M410.2,389.7c-2-10.9,0-21.8,0-21.8" />
					<path id="rear_glass" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M393.2,562.1
				c-27-39.7-27-26.8-27-26.8"/>
					<path id="front_glass" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M350.9,366.2
				c4.7-23.4,38.4-84.5,38.4-84.5c1-2,1.2-13.3,1.2-13.3"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M371.3,524.9c0.4,1.3,1,2.6,1.8,3.8l19.1,28.8
				c0.7,0.9,1.7,1.5,2.8,1.5h10c1.2,0,2.1-1,1.9-2.2c-2.6-14.4-11.7-22.5-11.7-22.5c-8.9-8.8-19-12.9-22.7-12.6
				c-0.8,0-1.6,0.5-1.5,1.3C371.1,523.7,371.2,524.4,371.3,524.9z"/>
					<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="449.2" cy="560.1" rx="3" ry="6.4" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="455.7" cy="185" rx="6.5" ry="6.4" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M428.7,176.7c-11.1,9-15.6,16.8-17.4,21.4
				c-0.8,2.1-1.2,4.2-1.2,6.4v27.2c0,0.6,0.9,0.9,1.2,0.3c19.9-37.1,20.6-50.2,19.7-54.5C430.8,176.5,429.6,176.1,428.7,176.7z"/>
					<g>
						<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="467.7" cy="241.5" rx="30.5" ry="30.2" />
						<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="467.7" cy="241.5" rx="20.5" ry="20.3" />
					</g>
					<g>
						<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="467.7" cy="510.1" rx="30.5" ry="30.2" />
						<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="467.7" cy="510.1" rx="20.5" ry="20.3" />
					</g>
				</g>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M39.9,277.8c17.9,0,32.5-16,32.5-35.7s-14.6-35.7-32.5-35.7H25.8
				v-20.8l3.6-1v-5.9c0,0,1-5.9,4-6.9h5.2c0,0,2.8,1,3.8,3h6.7v-6.3H61v2.3h8.4l1.5,1.5c0,0,10.5,4.4,19.5,17.3c0,0,14,19.8,23,77.3
				l25,51.5c0,0,13,25.8,17,58.5c0,0,4,80.3-4,130.8l-4,27.7h-8.8l-27.2,26.8H85l-8.3,5.8c-0.1,0.1-0.3,0.2-0.4,0.3l0,0
				c-1.2,1.2-2.8,1.8-4.4,1.8H60.2c-1.2,0-2.3-0.1-3.5-0.2l-5-1.6c-0.9-0.1-1.9-0.4-2.8-0.8c-2.4-1.2-7.7-4-12.6-7.7
				c-1.2-1-2.3-2.2-3.1-3.5l0,0c-1.2-2-1.8-4.3-1.8-6.6v-2.9h8.5c17.9,0,32.5-16.4,32.5-36.7s-14.6-36.7-32.5-36.7l-11.5-1.5
				l-2.6-0.3v-192L39.9,277.8z"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M102.4,270.8c-1-13.9-8.5-38.5-8.5-38.5" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M48.9,286.1c21.3,0,38.5-20.2,38.5-45.1
				c0-26.8-15-37.7-29-44.6"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M75.4,569c8-21.8,35.5-36.1,35.5-36.1" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M66.4,531.2c14,11,28,13,28,13" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="110.1" y1="532.9" x2="94.4" y2="402.5" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="94.1" y1="402.1" x2="91.4" y2="385.7" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="91.4" y1="371.8" x2="79.4" y2="268.7" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M76.8,477.1c22.1,28.2,6.4,56.8,2.5,63
				c-0.2,0.3,0.1,0.6,0.4,0.4c7.6-5.4,11.6-12.9,13.6-19.1c2.6-8.1,2.1-17-1.1-24.9c-4.3-10.7-11.8-17.3-15.1-19.8
				C76.9,476.5,76.5,476.8,76.8,477.1z"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M66.5,568.4c8-12.5-0.9-24.2-0.9-24.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="35.4" y1="472.4" x2="35.4" y2="288.6" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M69.4,195.4c0-17.8-6-20.8-6-20.8" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M101.7,271.3l3.8,46.9l7,92.2c0,14.2,6.4,71.5,8.4,89
				c0.4,3.6,2.3,6.9,5.2,9.1c3.4,2.6,6.5,4.1,8.6,5c1.4,0.6,3.1-0.2,3.5-1.7l6.6-23.3c1.3-4.5,2.1-9.1,2.6-13.8
				c2.2-22.8,3-64.3,3-64.3c-1-23.7-5-49.5-5-49.5c-11.2-46.9-35.7-81.3-42.3-90C102.6,270.2,101.6,270.6,101.7,271.3z"/>
					<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="150.1,404.5 111.2,394.7 112,404.9 150.4,409.3 		" />
					<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="112,404.9 112.6,415.3 150.4,416.8 150.4,409.3 		" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="103.1" y1="289.3" x2="117.6" y2="292.8" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M25.8,289.4l16.5-2.4c10-1.6,42.1-2.4,42.1-2.4c7,0,19,5,19,5" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M25.9,401.4c21.4-3,84.5,1.9,84.5,1.9" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="93.9" cy="378.7" rx="3.5" ry="10.9" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M94.4,389.6c2-10.9,0-21.8,0-21.8" />
					<path id="rear_glass_1_" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M111.4,562.1
				c27-39.7,27-26.8,27-26.8"/>
					<path id="front_glass_1_" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M153.8,366.2
				c-4.7-23.4-38.4-84.5-38.4-84.5c-1-2-1.2-13.3-1.2-13.3"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M133.4,524.9c-0.4,1.3-1,2.6-1.8,3.8l-19.1,28.8
				c-0.7,0.9-1.7,1.5-2.8,1.5h-10c-1.2,0-2.1-1-1.9-2.2c2.6-14.4,11.7-22.5,11.7-22.5c8.9-8.8,19-12.9,22.7-12.6
				c0.8,0,1.6,0.5,1.5,1.3C133.6,523.7,133.4,524.4,133.4,524.9z"/>
					<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="55.4" cy="560.1" rx="3" ry="6.4" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="48.9" cy="185" rx="6.5" ry="6.4" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M75.9,176.7c11.1,9,15.6,16.8,17.4,21.4
				c0.8,2.1,1.2,4.2,1.2,6.4v27.2c0,0.6-0.9,0.9-1.2,0.3c-19.9-37.1-20.6-50.2-19.7-54.5C73.8,176.5,75.1,176.1,75.9,176.7z"/>
					<g>
						<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="36.9" cy="241.5" rx="30.5" ry="30.2" />
						<ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="36.9" cy="241.5" rx="20.5" ry="20.3" />
					</g>
					<g>
						<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="36.9" cy="510" rx="30.5" ry="30.2" />
						<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="36.9" cy="510" rx="20.5" ry="20.3" />
					</g>
				</g>
			</g>
			<g id="Layer_3">
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M153.3,690.1l-0.4,39.8c0,0,0,3,3,3h17
				c0,0,2,0,2-3l-0.2-15.9"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M340.4,691.1l0.4,39.8c0,0,0,3-3,3h-17
				c0,0-2,0-2-3V714"/>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M245.7,580.5h28.5c0,0,16.5,0,25.5,2
					c0,0,9,2,15,13l12,26l3.9,9.7c0.1,0.2,0.1,0.4,0.2,0.6l1.6,7.2c0.2,0.9,0.7,1.8,1.5,2.3l2.7,2.1c0.8,0.6,1.3,1.5,1.5,2.4l1.5,7.5
					c0.1,0.6,0.6,1,1.2,1l0,0c0.7,0,1.2,0.5,1.2,1.2V688c0,1-0.4,1.9-1.1,2.5l0,0c-0.7,0.6-1.1,1.5-1.1,2.4c-0.2,3.5-1,12.8-5,17.6
					l-1.1,1.7c-1.8,2.7-4.8,4.3-8,4.3l0,0c-2.6,0-5.1-1.1-6.9-2.9l-3.9-4.1l-9-11c0,0-4-6-9-6h-50.5"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M248.9,580.5h-28.5c0,0-16.5,0-25.5,2
					c0,0-9,2-15,13l-12,26l-3.9,9.7c-0.1,0.2-0.1,0.4-0.2,0.6l-1.6,7.2c-0.2,0.9-0.7,1.8-1.5,2.3l-2.7,2.1c-0.8,0.6-1.3,1.5-1.5,2.4
					l-1.5,7.5c-0.1,0.6-0.6,1-1.2,1l0,0c-0.7,0-1.2,0.5-1.2,1.2V688c0,1,0.4,1.9,1.1,2.5l0,0c0.7,0.6,1.1,1.5,1.1,2.4
					c0.2,3.5,1,12.8,5,17.6l1.1,1.7c1.8,2.7,4.8,4.3,8,4.3l0,0c2.6,0,5.1-1.1,6.9-2.9l3.9-4.1l9-11c0,0,4-6,9-6h50.5"/>
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M185.7,647.5c0,0,3,11,8.8,16.6
					c1.6,1.6,3.8,2.4,6,2.4h47"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M309.2,647.5c0,0-3,11-8.8,16.6
					c-1.6,1.6-3.8,2.4-6,2.4h-47"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M196.7,625.5l12,28c0,0,0.4,0.6,1,1.5
				c2.6,3.5,6.8,5.6,11.2,5.6h27.3"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M298.7,625.5l-12,28c0,0-0.4,0.6-1,1.5
				c-2.6,3.5-6.8,5.6-11.2,5.6h-27.3"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="183.2" y1="632.5" x2="311.2" y2="632.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="201.2" y1="636" x2="294.2" y2="636" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="203.2" y1="640" x2="292.2" y2="640" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="204.2" y1="644" x2="291.2" y2="644" />
					<circle fill="#FCFCFC" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="247.7" cy="638.5" r="6.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="200.2" y1="633" x2="183.2" y2="633" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="311.2" y1="633" x2="295.2" y2="633" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="157.2" y1="680" x2="185.2" y2="680" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="311.2" y1="680.5" x2="339.2" y2="680.5" />
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M156.6,646c0,0,1.9,14.7,13,21.4
					c1.1,0.7,2.3,1.2,3.5,1.6l2.4,0.9c2.8,1,5.8,1.6,8.7,1.6h64.7"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M337.9,646c0,0-1.9,14.7-13,21.4
					c-1.1,0.7-2.3,1.2-3.5,1.6l-2.4,0.9c-2.8,1-5.8,1.6-8.7,1.6h-64.7"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M170.7,623.5h8.7c0,0,2.3,0,3.3,4
				c0,0,1.8,12.3,2.4,15.6c0.6,3.4,0.7,4.7,0,4.7c-7.4,0.6-17.4-2.4-20.4-7.4C162.8,637.4,168.7,623.5,170.7,623.5z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M324.2,623.5h-8.7c0,0-2.3,0-3.3,4
				c0,0-1.8,12.3-2.4,15.6c-0.6,3.4-0.7,4.7,0,4.7c7.4,0.6,17.4-2.4,20.4-7.4C332,637.4,326.2,623.5,324.2,623.5z"/>
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="169.7" cy="692.5" rx="7" ry="3" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="325.7" cy="692.5" rx="7" ry="3" />
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M247.2,593.5h36.5c0,0,17,0,24,7
					c0,0,2,1.2,3.2,4.5c2.4,6.5-0.1,13.9-5.8,17.8c-0.8,0.6-1.8,1.1-2.8,1.6c-1.5,0.7-3.1,1.1-4.7,1.1h-49.3"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M248.2,593.5h-35c0,0-17,0-24,7
					c0,0-2,1.2-3.2,4.5c-2.4,6.5,0.1,13.9,5.8,17.8c0.8,0.6,1.8,1.1,2.8,1.6c1.5,0.7,3.1,1.1,4.7,1.1h48.8"/>
					</g>
					<path fill="#F7F5F5" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M264.3,592.5H232c-0.8,0-1.4-0.6-1.4-1.4
				v-1.2c0-0.8,0.6-1.4,1.4-1.4h32.2c0.8,0,1.4,0.6,1.4,1.4v1.2C265.7,591.9,265,592.5,264.3,592.5z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M160.7,641.5l-1,1l-2,1l1.4-4.5
				c0-2.4-1.9-4.3-4.3-4.3h-2.3c-0.6,0-1.2-0.1-1.7-0.4c-0.9-0.4-2.2-1.1-3.3-2c-0.8-0.7-1.3-1.7-1.3-2.8v-2.8c0,0,0.8-3.6,6.2-3.6
				c0.2,0,0.4,0,0.6,0h8.1c1.4,0,2.8,0.7,3.6,1.9c0.2,0.3,0.3,0.6,0.3,0.9v2.8"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M334.2,641.5l1,1l2,1l-1.4-4.5
				c0-2.4,1.9-4.3,4.3-4.3h2.3c0.6,0,1.2-0.1,1.7-0.4c0.9-0.4,2.2-1.1,3.3-2c0.8-0.7,1.3-1.7,1.3-2.8v-2.8c0,0-0.8-3.6-6.2-3.6
				c-0.2,0-0.4,0-0.6,0h-8.1c-1.4,0-2.8,0.7-3.6,1.9c-0.2,0.3-0.3,0.6-0.3,0.9v2.8"/>
				</g>
			</g>
			<g id="Layer_2_1_">
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M156.7,116.8l-0.4,39.8c0,0,0,3,3,3h17
				c0,0,2,0,2-3v-16"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M340.8,116.8l0.4,39.8c0,0,0,3-3,3h-17
				c0,0-2,0-2-3v-16"/>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M247.3,4.6l53,2c0,0,4.6,0,8.6,3.6
					c0.3,0.3,0.5,0.6,0.7,1l22.7,47.4c0,0.1,0.1,0.1,0.1,0.2l10,17.9l0.9,1.8c0.7,1.4,1.1,3,1.1,4.6v22.4c0,2-0.3,4-0.8,5.9l-1.3,4.4
					c-0.6,1.9-0.8,3.9-0.8,5.9v16c0,0,0,0.2,0,0.4c-0.3,1.5-1.6,2.6-3.2,2.6h-42.7c-0.6,0-1.1-0.1-1.6-0.4c-1.7-1-5.9-3.5-8.5-5.8
					c-0.6-0.5-1.4-0.8-2.1-0.8h-35.2"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M251.3,4.6l-53,2c0,0-4.6,0-8.6,3.6
					c-0.3,0.3-0.5,0.6-0.7,1l-22.7,47.4c0,0.1-0.1,0.1-0.1,0.2l-10,17.9l-0.9,1.8c-0.7,1.4-1.1,3-1.1,4.6v22.4c0,2,0.3,4,0.8,5.9
					l1.3,4.4c0.6,1.9,0.8,3.9,0.8,5.9v16c0,0,0,0.2,0,0.4c0.3,1.5,1.6,2.6,3.2,2.6H203c0.6,0,1.1-0.1,1.6-0.4c1.7-1,5.9-3.5,8.5-5.8
					c0.6-0.5,1.4-0.8,2.1-0.8h35.2"/>
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M246.8,11.6l57.1,1c-0.6,0,1.4,0,2.4,3
					L318.9,44c0.4,0.8,0.4,1.7,0.1,2.5c-0.3,0.7-0.7,1.1-1.1,1.1h-71.1"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M251.8,11.6l-57.1,1c0.6,0-1.4,0-2.4,3
					L179.7,44c-0.4,0.8-0.4,1.7-0.1,2.5c0.3,0.7,0.7,1.1,1.1,1.1h71.1"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M172.3,63.6h6.6c2.8,0,5.5,0.8,7.8,2.3
				c3.1,2,7.7,5.7,12.6,11.7l6.1,7.9c0.4,0.5,0,1.1-0.6,1.1H188c-1.2,0-2.4-0.1-3.5-0.4c-3.6-1-12-3.5-18.2-8c-1.5-1-2.4-2.7-2.6-4.5
				c-0.4-3.4,0-8.6,6.1-9.9C170.6,63.7,171.4,63.6,172.3,63.6z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M325.8,63.6h-6.6c-2.8,0-5.5,0.8-7.8,2.3
				c-3.1,2-7.7,5.7-12.6,11.7l-6.1,7.9c-0.4,0.5,0,1.1,0.6,1.1h16.9c1.2,0,2.4-0.1,3.5-0.4c3.6-1,12-3.5,18.2-8
				c1.5-1,2.4-2.7,2.6-4.5c0.4-3.4,0-8.6-6.1-9.9C327.5,63.7,326.7,63.6,325.8,63.6z"/>
					<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="171.8" cy="113.1" r="5.5" />
					<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="326.8" cy="113.1" r="5.5" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M299.3,77c-54.1,11.6-100.1-0.4-100.1-0.4
				s10.3,6.1,48.5,9c0.9,0.1,1.9,0.1,2.8,0.1c5.9-0.2,28.1-1.2,48.3-8.1"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M162.3,88.6c0,0,12,8,28,10" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M337.3,88.6c0,0-12,8-28,10" />
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M157.8,126.6h19.5c0,0,3.5,4.6,8.3,4.8h70.7"
						/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M341.3,126.6h-19.5c0,0-3.5,4.6-8.3,4.8h-70.7
					"/>
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M247.3,92.8l29-0.1c0,0,0.1,0,0.3,0
					c7.3,0.2,14.2,3.4,19,8.9l8.7,9.8c1.6,1.8,2.6,4.2,2.7,6.6c0,2.1-0.5,4.5-2.4,6.7c-1.1,1.2-2.4,2-3.9,2.6l-3,1.1
					c-1.2,0.5-2.5,0.7-3.8,0.7h-46.5"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M249.9,92.8l-29-0.1c0,0-0.1,0-0.3,0
					c-7.3,0.2-14.2,3.4-19,8.9l-8.7,9.8c-1.6,1.8-2.6,4.2-2.7,6.6c0,2.1,0.5,4.5,2.4,6.7c1.1,1.2,2.4,2,3.9,2.6l3,1.1
					c1.2,0.5,2.5,0.7,3.8,0.7h46.5"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M280.7,120.6h-61.8c-0.9,0-1.6-0.7-1.6-1.6
				v-14.8c0-0.9,0.7-1.6,1.6-1.6h61.8c0.9,0,1.6,0.7,1.6,1.6V119C282.3,119.9,281.5,120.6,280.7,120.6z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M158.9,72v-7c0-3-2.5-5.5-5.5-5.5h-3
				c-0.8,0-1.5-0.2-2.2-0.5c-1.1-0.5-2.8-1.4-4.2-2.5c-1.1-0.9-1.7-2.2-1.7-3.6v-3.6c0,0,1-4.6,7.9-4.6c0.3,0,0.5-0.1,0.8-0.1h10.3
				c1.8,0,3.6,0.9,4.6,2.4c0.3,0.4,0.4,0.8,0.4,1.2v3.6c0,1.3-0.4,2.5-1.2,3.5l-0.3,0.3c-0.8,1-1.2,2.2-1.2,3.4v4.6L158.9,72z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M339.7,72v-7c0-3,2.5-5.5,5.5-5.5h3
				c0.8,0,1.5-0.2,2.2-0.5c1.1-0.5,2.8-1.4,4.2-2.5c1.1-0.9,1.7-2.2,1.7-3.6v-3.6c0,0-1-4.6-7.9-4.6c-0.3,0-0.5-0.1-0.8-0.1h-10.3
				c-1.8,0-3.6,0.9-4.6,2.4c-0.3,0.4-0.4,0.8-0.4,1.2v3.6c0,1.3,0.4,2.5,1.2,3.5l0.3,0.3c0.8,1,1.2,2.2,1.2,3.4v4.6L339.7,72z"/>
				</g>
			</g>
			<g id="Layer_4">
				<g>
					<g>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M248.9,168.2c0,0,13,0,16,3l16.1,0.4c1.9,0.1,3.8,0.3,5.7,0.7
					c4.6,1,13.1,3.5,18.7,8.4c0,0,13.1,9.6,23.9,26.5c1.4,2.2,2.5,4.6,3.2,7.1l1.9,6.4l2,71v79v75v83c0,0,0,16-9,25
					c-8.5,8.5-16,15-39,18h-35.5"/>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M253.9,168.2c0,0-13,0-16,3l-16.1,0.4c-1.9,0.1-3.8,0.3-5.7,0.7
					c-4.6,1-13.1,3.5-18.7,8.4c0,0-13.1,9.6-23.9,26.5c-1.4,2.2-2.5,4.6-3.2,7.1l-1.9,6.4l-2,71v79v75v83c0,0,0,16,9,25
					c8.5,8.5,16,15,39,18h39.5"/>
					</g>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M209.2,180.5c-2.8,6.1-11.5,24-22.5,38.5c-1.3,1.7-3.1,2.9-5.1,3.4
				c-1.2,0.3-2.5,0.2-3.5-0.6c-0.5-0.5-0.8-1.1-0.9-1.8c0-0.1,0-0.1,0-0.2c-0.6-6.4,1.4-12.7,5.4-17.8c5.1-6.4,14.2-16.5,25.4-22.5
				C208.8,179,209.5,179.7,209.2,180.5z"/>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M292.3,180.5c2.8,6.1,11.5,24,22.5,38.5c1.3,1.7,3.1,2.9,5.1,3.4
				c1.2,0.3,2.5,0.2,3.5-0.6c0.5-0.5,0.8-1.1,0.9-1.8c0-0.1,0-0.1,0-0.2c0.6-6.4-1.4-12.7-5.4-17.8c-5.1-6.4-14.2-16.5-25.4-22.5
				C292.7,179,291.9,179.7,292.3,180.5z"/>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M187.4,519.7c0,0-7,4-10,10c0,0-2,5.6,0,10.3c0,0,3.4,7.4,11.8,14.9
				c0.8,0.7,2.2,0.1,2.2-1v-33.2C191.4,520.7,190.4,518.7,187.4,519.7z"/>
					<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M315.5,519.7c0,0,7,4,10,10c0,0,2,5.6,0,10.3c0,0-3.4,7.4-11.8,14.9
				c-0.8,0.7-2.2,0.1-2.2-1v-33.2C311.5,520.7,312.5,518.7,315.5,519.7z"/>
					<g>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M176.5,285.2l11.9,79.5c0,0,4.6,29.7,4.8,55.4l-1.8,92.6
					c0,0-11-8.9-12-20.9l-2.9-85.3V285.2z"/>
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="193.2" y1="404.2" x2="176.5" y2="399.8" />
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="193.2" y1="421.4" x2="176.9" y2="417.3" />
						<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="193.1" y1="412.2" x2="176.5" y2="409" />
					</g>
					<g>
						<path fill="none" stroke="#070707" stroke-miterlimit="10" d="M325.2,285.2l-11.9,79.5c0,0-4.6,29.7-4.8,55.4l1.8,92.6
					c0,0,11-8.9,12-20.9l2.9-85.3V285.2z"/>
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="308.5" y1="404.2" x2="325.2" y2="399.8" />
						<line fill="none" stroke="#070707" stroke-miterlimit="10" x1="308.6" y1="421.4" x2="324.9" y2="417.3" />
						<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="308.7" y1="412.2" x2="325.2" y2="409" />
					</g>
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M176.5,285.2c0,0-1.7-53.3,5.1-62.9" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M325.4,285.2c0,0,1.7-53.3-5.1-62.9" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M208.3,182.3c0,0,43.1-12.6,85.1,0.4
				C293.4,182.7,254.4,174.7,208.3,182.3z"/>
					<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="176.3" y1="296.1" x2="166.4" y2="290.3" />
					<line fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" x1="325.4" y1="297.7" x2="336.3" y2="291.3" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M175.4,405.7c0,0-7,0.5-9-2.7" />
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M325.4,405.7c0,0,8,1,11-2.7" />
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M249.4,257.7l14.8,0.6c0,0,30.1-0.5,48.2,14.6
					c0.7,0.6,1.3,1.3,1.7,2.1c0.4,0.8,1,1.8,1.5,3c0.8,1.9,1.2,3.9,0.9,6l-8.2,67.4c-0.1,0.8-0.8,1.4-1.6,1.3
					c-6.6-0.5-35.8-2.7-56.4-2.9"/>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M249.4,257.7l-13.1,0.6
					c0,0-30.1-0.5-48.2,14.6c-0.7,0.6-1.3,1.3-1.7,2.1c-0.4,0.8-1,1.8-1.5,3c-0.8,1.9-1.2,3.9-0.9,6l8.2,67.4
					c0.1,0.8,0.8,1.4,1.6,1.3c6.6-0.5,36.1-2.7,56.7-2.9"/>
					</g>
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M184.4,278.7c0,0,11-27,66-27" />
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M316.4,279.7c0,0-5-26-67-28" />
					</g>
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M192.4,351.7c0,0,9,44,10,159h51.5" />
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M308.4,351.7c0,0-9,44-10,159h-51.5" />
					</g>
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M190.4,519.7c0,0,6-9,12-9h2.1v5.6
				c0,1.2,0.1,2.4,0.5,3.5c1,3.2,3,4,6.2,4.8h0.1c0,0,23,2,39,2"/>
					<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M311.4,519.7c0,0-6-9-12-9h-2.1v5.6
				c0,1.2-0.1,2.4-0.5,3.5c-1,3.2-3,4-6.2,4.8h-0.1c0,0-24,2-40,2"/>
					<g>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M250.4,539.4c0,0,32,1.3,52-5.7
					c0,0,1.2-0.4,2.3-0.4c0.9,0,1.6,0.7,1.6,1.6v7.6c0,1.9-0.6,3.7-1.6,5.3c-2.3,3.2-7.5,8.5-19.6,13.9c-1.7,0.8-3.6,1.2-5.5,1.2
					c-7.5,0.2-28.6,0.8-30.2,0.9"/>
						<path fill="none" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M251.4,539.4c0,0-32,1.3-52-5.7
					c0,0-1.2-0.4-2.3-0.4c-0.9,0-1.6,0.7-1.6,1.6v7.6c0,1.9,0.6,3.7,1.6,5.3c2.3,3.2,7.5,8.5,19.6,13.9c1.7,0.8,3.6,1.2,5.5,1.2
					c7.5,0.2,28.6,0.8,30.2,0.9"/>
					</g>
					<path fill="#FFFFFF" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M166.4,314.1v-7.6c0-1-0.8-1.7-1.8-1.6
				l-5.4,0.4c0,0-3.4,1.8-6.5,4.6c-1.7,1.6-3.1,3.5-4.2,5.6l-2.2,4.3c0,0-1,3,1,4c0,0,4.7,1.9,17.6-7
				C165.9,316.1,166.4,315.1,166.4,314.1z"/>
					<path fill="#FFFFFF" stroke="#070707" stroke-width="0.75" stroke-miterlimit="10" d="M336.4,314.1v-7.6c0-1,0.8-1.7,1.8-1.6
				l5.4,0.4c0,0,3.4,1.8,6.5,4.6c1.7,1.6,3.1,3.5,4.2,5.6l2.2,4.3c0,0,1,3-1,4c0,0-4.7,1.9-17.6-7
				C336.9,316.1,336.4,315.1,336.4,314.1z"/>
				</g>
			</g></>),
	},
}