import React from "react"

export const Estate4x4Svg = {
  interior: {
    width:372,
    height:834,
    svg: (<><g id="Layer_1">
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M359.1,330.6l2.6,5.4
      c0.5,0.9,0.7,1.8,0.6,2.9v7.8v2.5l-16.4-6.8c0,2.5,0,5,0,7.5c0.3,17,0.4,33.8,0.4,50.2c0,3,0,6,0,9c1.5,7.6,1.8,18.1,1,31.5
      c-0.2,2.5-0.3,5-0.6,7.7h-0.5c0,1,0,1.9,0,2.8c-0.1,3.8-0.2,7.2-0.2,10.3c-0.2,11.9-0.4,17.8-0.4,17.9c-0.1,6.2-0.3,12.4-0.4,18.6
      c-0.4,25.2-1,49.8-1.9,73.8c0,0.1,0,0.1,0,0.1c0,0.7,0,1.5-0.1,2.2h0.2c0.1,0.4,0.2,0.8,0.3,1.2c0,0.2,0.1,0.4,0.1,0.5
      c0.2,0.9,0.4,1.8,0.6,2.8l0.2,0.5c-0.1-0.2-0.1-0.4-0.2-0.6c2.5,6.2,4.5,11.9,5.9,17.2c0.3,0.8,0.5,1.6,0.7,2.4
      c0.6,2.6,1.2,5.1,1.7,7.6c0.8,4.4,1.3,8.6,1.5,12.8c0.1,2.8,0.1,5.6,0.1,8.4c0.4,26.8,0.4,54.2-0.1,82.4c-0.1,3-0.1,6.1-0.1,9.1
      l-0.1,2.4c-0.3,7-1,13.3-1.9,18.8c-0.8,4.6-1.8,8.6-3,12c-7,15.9-13.5,28.5-19.7,37.7c-0.6,0.8-1.2,1.7-1.8,2.6
      c-1.1,0.4-2.1,0.7-3.2,1.1c-4.2,1.3-8.5,2.6-12.9,3.7c-0.6,0.1-1.1,0.3-1.7,0.4c-0.6,0.1-1.2,0.3-1.7,0.4c-1.2,0.3-2.3,0.5-3.5,0.8
      c-6.6,1.4-13.4,2.5-20.5,3.4c-0.4,0-0.9,0.1-1.3,0.1l-5,8.2c-1.9,2.8-5.1,4.6-9.8,5.4c-2,0.1-4.1,0.2-6.2,0.3c-0.5,0-1.1,0-1.6,0.1
      l-8.7,0.3c-20.3,0.7-40.6,1-61,1.1h-6.1c-0.1,0-0.3,0-0.4,0H178c-20.3-0.1-40.7-0.5-61-1.1l-8.6-0.3c-0.5,0-1.1-0.1-1.7-0.1
      c-2.1-0.1-4.1-0.2-6.2-0.3c-4.7-0.7-7.9-2.5-9.8-5.4l-5-8.2c-0.5-0.1-1.1-0.1-1.6-0.1c-6.7-0.8-13.3-1.9-19.6-3.2
      c-1.9-0.4-3.9-0.8-5.7-1.3c-0.6-0.1-1.1-0.3-1.7-0.4c-4.4-1.1-8.7-2.3-12.9-3.7c-1-0.4-2.1-0.7-3.2-1.1c-0.6-0.9-1.2-1.7-1.8-2.6
      c-6.1-9.2-12.7-21.7-19.7-37.7c-1.2-3.4-2.2-7.4-3-12c-0.9-5.5-1.5-11.7-1.9-18.8l-0.1-2.4c0-3.1-0.1-6.1-0.1-9.1
      c-0.5-28.1-0.5-55.6-0.1-82.4c0-2.8,0.1-5.6,0.1-8.4c0.2-4.2,0.8-8.5,1.6-12.8c0.5-2.5,1.1-5.1,1.7-7.6c0.2-0.7,0.4-1.5,0.7-2.4
      c1.4-5.3,3.3-10.5,5.7-15.8c0.2-1.5,0.5-2.8,0.8-4.1c0-0.2,0-0.4,0.1-0.5c0.1-0.4,0.2-0.8,0.3-1.2h0.2c0-0.7,0-1.5-0.1-2.2
      c0,0,0-0.1,0-0.1c-0.9-24.1-1.6-48.7-1.9-73.8c-0.2-6.2-0.4-12.4-0.5-18.6c0-0.1-0.1-6.1-0.4-17.9c-0.1-3-0.2-6.5-0.2-10.3
      c0-0.9,0-1.8-0.1-2.8h-0.5c-0.2-2.7-0.4-5.2-0.6-7.7c-0.8-13.4-0.4-23.9,1-31.5c0-3,0-6,0-9c0-16.4,0.2-33.2,0.5-50.2
      c0-2.5,0-5,0-7.5l-16.4,6.8v-2.5v-7.8c0-1.1,0.2-2.1,0.6-2.9l2.6-5.4c1-2.2,2.4-3.8,4-4.6l6.7-2.8l2.7-1.1
      c0.1-16.8,0.1-33.9,0.2-51.5c-0.5-0.4-0.9-0.7-1.4-1.2c-1.5-1.2-3.3-2.2-5.5-3.1c-0.5-0.2-0.8-0.6-0.9-1.2v-21
      c-0.1-1,0.4-1.7,1.6-2l1.7-0.4l3.3-0.9c-0.7-1.5-1.3-3-1.9-4.5c-0.4-0.9-0.7-1.9-1-2.9c-3.1-9.5-4.9-20.5-5.2-33l0-3
      c0-0.6,0-1.2,0-1.7c-0.1-30-0.1-59.6,0.1-88.8l0-2.7c0-0.7,0-1.3,0.1-2C14.3,90,16.2,80,19.4,72.2c0-0.1,0-0.1,0.1-0.2
      c0.2-0.5,0.4-0.9,0.6-1.2c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.2-0.3,0.2-0.5c0.2-0.4,0.4-0.7,0.5-1.1c0.3-0.4,0.5-0.7,0.8-1.1
      c1-1.4,2.2-2.6,3.7-3.7l0.1-0.1c3.3-4.2,8-8.2,14-11.8c4.8-2.9,9.7-5.4,14.6-7.7c4.1-1.9,8.3-3.6,12.5-5.1
      c11.6-7.1,23.8-13,36.7-17.7v-1.3v-3.2l8-3c2.4-0.7,5.5-1.4,9.4-1.8c0.1,0,0.2,0,0.4,0c1.5-0.1,2.9-0.2,4.3-0.3
      c17.6-1.3,35-2,52.3-2.2c1.4,0,2.8,0,4.2,0h0.1c0.2,0,0.4,0,0.6,0c1,0,1.9,0,2.9,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0.1,0
      c1.4,0,2.8,0,4.2,0c17.2,0.2,34.6,0.9,52.2,2.2c1.4,0.1,2.9,0.2,4.3,0.3c0.1,0,0.3,0,0.4,0c3.9,0.5,7,1.1,9.4,1.8l8,3v3.2V21
      c12.9,4.7,25.1,10.6,36.7,17.7c4.2,1.5,8.3,3.2,12.5,5.1c4.9,2.3,9.8,4.9,14.6,7.7c6,3.7,10.7,7.6,14,11.8l0.1,0.1
      c1.5,1,2.7,2.2,3.7,3.7c0.3,0.4,0.5,0.7,0.8,1.1c0.2,0.4,0.3,0.7,0.6,1.1c0.1,0.2,0.1,0.4,0.2,0.5c0.2,0.3,0.3,0.6,0.4,0.9
      c0.2,0.4,0.3,0.8,0.5,1.2c0,0.1,0,0.1,0.1,0.2c3.2,7.8,5.1,17.8,5.6,29.9c0,0.6,0,1.3,0.1,2l0,2.7c0.2,29.2,0.2,58.8,0,88.8
      c0,0.6,0,1.1,0,1.7l0,3c-0.4,12.4-2.1,23.4-5.2,33c-0.4,1-0.7,1.9-1,2.9c-0.6,1.7-1.4,3.4-2.1,5c-0.3,0.6-0.6,1.2-0.9,1.9
      c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.9,0,1.8,0,2.7c0,0.2,0,0.4,0,0.5c0,1,0,2.1,0,3.1c0,0.2,0,0.4,0,0.6v1.7c0,0.5,0,0.9,0,1.4
      c0,0.2,0,0.3,0,0.5c0,1.1,0,2.1,0,3.2c0,0.2,0,0.3,0,0.5c0,1,0,2.1,0,3.1c0,0.2,0,0.3,0,0.5c0,1.1,0,2.1,0,3.2c0,0.2,0,0.4,0,0.5
      c0,0.8,0,1.7,0,2.5c0.1,18.7,0.2,36.8,0.3,54.6l2.7,1.1l6.7,2.8C356.8,326.8,358.1,328.4,359.1,330.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M345.9,342.4l-4-1.7
      c0,3.1,0,6.2,0.1,9.2c0.3,17,0.4,33.8,0.4,50.2c0,3.8,0,20-0.2,48.6c-0.1,5.1-0.1,10.2-0.2,15.2c1.3-0.6,2.6-1.5,4-2.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M341.9,340.7l-6.9-2.9V336
      l-4.2-5.5c0,6.8,0.1,13.7,0.1,20.5c0.1,6.8,0.1,13.5,0.1,20.2l0.3,59c0,0.2,0,0.4,0,0.7c-0.1,12.4-0.3,24.7-0.5,36.7
      c3.8-0.7,7.5-2,11.1-3.7c-0.2,11.5-0.3,22.6-0.5,33.2c-0.5,26-1.2,50.8-2.1,74.6c0,0.5,0,0.9,0,1.3c-0.5,27.1-1.4,53.2-2.7,78.4
      c1.4-0.3,2.8-1.3,4.2-3.1c0.5-11.2,0.9-22.3,1.3-33.3h-1.6l0.3-7.7v-5.7c-0.6-0.2-0.9-0.6-0.9-1.2v-0.2v-16.6
      c0-0.7,0.1-1.3,0.2-1.8c0.2-1,0.6-1.5,1.2-1.5v-6.3h1.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M308.5,359.4
      c0,0.5,0.1,1.3,0.1,2.5c0,1.5,0,2.9,0.1,4.3c0,0.4,0,0.8,0,1.2c3.7-12.7,7.4-26.5,11-41.6l-0.4-9.7c-3.7,15-7.3,28.8-11,41.4
      C308.5,358.1,308.5,358.7,308.5,359.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M294.3,346.4
      c-2-0.6-4.2-1.2-6.5-1.8c-32.3-8.9-64.7-13.7-97.2-14.2c-0.1,0-0.3,0-0.4,0l-0.7,0c-0.1,0-0.3,0-0.4,0c-0.9,0-1.7,0-2.6,0
      c0,0,0,0-0.1,0h-0.9c-0.8,0-1.6,0-2.4,0h-0.9c-1.4,0-2.8,0-4.2,0.1c-32.5,0.5-64.9,5.2-97.2,14.2c-2.4,0.6-4.5,1.2-6.5,1.8
      c-1.6,0.5-3.2,1-4.6,1.6c0.4,1.6,0.7,3.1,1.1,4.7c1.1-0.5,2.3-0.9,3.5-1.2c2-0.6,4.2-1.2,6.5-1.8c32.3-8.9,64.7-13.6,97.2-14.1
      c1.4,0,2.8-0.1,4.2-0.1h4.2c0,0,0,0,0.1,0c0.8,0,1.6,0,2.4,0h0.9c0.3,0,0.6,0,0.9,0c32.5,0.5,64.9,5.2,97.2,14.1
      c2.4,0.6,4.5,1.3,6.5,1.8c1.2,0.4,2.4,0.8,3.5,1.2c0.4-1.6,0.8-3.1,1.1-4.7C297.4,347.5,295.9,346.9,294.3,346.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M308.4,357.5
      c-0.8-9-2.8-14.4-6-16.3c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.1-0.5-0.2-0.8-0.3c-0.6,2.6-1.2,5.1-1.8,7.7c0.4,0.2,0.8,0.4,1.2,0.5
      c0.3,0.2,0.6,0.3,0.9,0.4c3.5,2,5.5,8,6.1,17.8c0,0.5,0.1,1.3,0.1,2.4c0,1.4,0,2.8,0.1,4.2c0.7,35.5,0.9,69.2,0.7,101.3l1.4-0.4
      c0.1-4.9,0.1-9.8,0.1-14.7c0.1-29.4-0.1-60.1-0.7-92.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M292.9,592.2h0.3
      c3.4,0.1,5.2,2.8,5.3,8.2c0,0.4,0,0.9,0,1.3c-0.2,32.5-1,63-2.3,91.5c-0.2,4.5-0.4,8.9-0.6,13.3c0,0.1,0,0.2,0,0.2l-1,12.6
      c-0.1,1.5-0.3,3-0.4,4.6c-0.6,5.6-2.6,8.3-5.9,8.3h-11.6c-1.3,17.4-2.7,33.3-4.2,47.7l3.7-0.5l16.1-0.4c1.1-8.1,2.1-16.3,3.1-24.6
      c1-9.1,2-18.3,2.9-27.6c0.1-1.3,0.3-2.6,0.4-3.9l0.8-9.6c0-0.1,0-0.2,0-0.3c0.1-0.7,0.1-1.4,0.1-2.1c0.3-3.9,0.5-7.9,0.7-11.9
      c2.4-38.9,3.8-81.3,4.4-127.3l1.6-73.8c0.1-7.5,0.2-15.1,0.3-22.9c0.2-33.4,0-68.7-0.7-105.9c-0.4-6.2-1.6-10.8-3.8-13.6
      c-0.8-1.1-2.1-2-3.8-2.7l0,0c-0.1,0-0.2-0.1-0.3-0.1c-5.5,19.5-9.2,43.7-11.3,72.7c-0.4,5.7-0.7,11.7-1,17.8l-4.2,149h11.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M345.7,322l-2.5-1.1l0,0h-0.1
      c0,0-0.1,0-0.1,0c-0.4-0.1-0.9-0.2-1.3-0.2c-1.3,0-2.5,0.5-3.8,1.6l-2,2.3c-0.1,0.1-0.1,0.1-0.1,0.2c-0.4,0.4-0.6,0.9-0.6,1.6
      c0,0.2-0.1,0.5-0.1,0.8v8.2l13.4,5.6v-17.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.1,320.9
      C343.1,320.9,343.1,320.9,343.1,320.9c-0.6-0.3-1-0.5-1.4-0.7c-0.6-0.4-1.1-0.8-1.6-1.2l-9.5-10.6l-1-1l-2.3-2.5l-2.1-2.4
      c0.2,7,0.3,14.1,0.4,21.2l1.6,2.2l0.5,0.6l2.4,3.2l0.7,0.9"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="335" y1="336" x2="335" y2="335.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M323.4,325.8
      c0.3,14.9,0.4,29.9,0.6,45.1l0.9,59.3c0,0.2,0,0.4,0,0.7c-0.1,7-0.1,13.9-0.2,20.8l2.3-1.2c0.1-6.5,0.2-13,0.2-19.6
      c0-0.3,0-0.5,0-0.7l-0.9-59.3c-0.1-15.2-0.3-30.2-0.6-45.1H323.4h-3.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="327.3" y1="325.8" x2="325.7" y2="325.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M319.4,316.1
      c1.2-4.8,2.3-9.6,3.5-14.6c0.7-2.9,1.4-5.9,2-8.9c0.2-0.8,0.4-1.7,0.6-2.6c-0.2-1.7-0.5-3.2-0.8-4.7c-0.1-0.3-0.1-0.5-0.1-0.8
      c-1.8-7.7-4.8-12.3-9-13.8c-0.2,0.8-0.3,1.6-0.5,2.4c0,0.3-0.1,0.5-0.1,0.7c-4.2,21.4-9,43.5-14.3,66.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M331.1,371.2
      c-0.2-14-0.5-27.8-0.9-41.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M326.9,450.4l2.1-1.1
      c0.1-6.1,0.2-12.3,0.2-18.5c0-0.3,0-0.5,0-0.7l-0.9-59.3c-0.1-14.9-0.3-29.7-0.5-44.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M325,600.3l-0.2,6.8
      c-0.2,4-0.4,8-0.6,12l-0.3,5.7c0,0.3,0,0.5,0,0.8c0,0.1,0,0.1,0,0.1c0,0.5,0,1-0.1,1.5c-0.4,8.2-0.9,16.3-1.4,24.3l2,0.1
      c0.5-8.5,1-17.1,1.4-25.9c0.9-17,1.6-34.5,2.1-52.4c0-0.5,0-1,0-1.6c0-0.8,0.1-1.6,0.1-2.4l2.1-71.4c0.3-10,0.6-20.1,0.7-30.4
      c-0.7,0.5-1.5,0.8-2.2,0.8c0,0.9,0,1.7,0,2.7c-0.1,5.7-0.3,11.4-0.4,17.1c-0.1,3.3-0.2,6.6-0.3,9.9l-2,73.9c0,0.5,0,1.1,0,1.6
      c-0.3,9-0.6,17.8-0.9,26.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M346.3,448.3h-0.1h-2.4l0.2-7.7
      l0.1-4.2c-0.5-0.1-0.8-0.7-0.9-1.8v-16.9c0-0.1,0-0.2,0-0.4c0-0.4,0-0.8,0.1-1.1c0-0.1,0-0.2,0-0.3c0.1-0.7,0.3-0.9,0.5-0.8v-7.8
      h2.4c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.3,0.1,0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M346.2,448.3
      c0.1-1.1,0.1-2.2,0.2-3.2c0-0.2,0-0.4,0.1-0.6c0.1-1.3,0.2-2.6,0.3-3.9h-2.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M346.1,407.3
      c-0.3,0.5-0.3,1.7,0,3.5c0.1,0.5,0.1,1.1,0.2,1.7c0,0.3,0.1,0.7,0.1,1.1c0.8,7,0.9,16,0.3,27h0.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.2,416h1.1
      c0.3,0,0.6,0.1,0.8,0.4c0.3,0.3,0.5,0.8,0.6,1.4c0.3,4.3,0.4,9.2,0.3,14.6c-0.1,1.3-0.4,2.1-0.9,2.2c-0.1,0.1-0.3,0.1-0.5,0h-1.4"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M304.6,651.1l1.2,0
      c0.3-8.1,0.6-16.3,0.9-24.6c0.5-17,0.9-34.6,1.2-52.8c0-0.7,0-1.3,0-2l1.4-73.8c0.1-3.1,0.1-6.3,0.1-9.4c0.1-3.2,0.1-6.4,0.1-9.6
      c0-1.5,0-3.1,0-4.6c6.9-1.8,13.3-3.8,19.3-6c0.1-6.3,0.2-12.6,0.3-19"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="309.5" y1="459.6" x2="324.6" y2="451.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M345.1,416.4c0,0.3,0,0.6-0.1,1
      c0,0.1,0,0.2,0,0.4v16.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.4,425.4H280
      c-0.4,5.7-0.7,11.7-1.1,17.8l-5.8,211.1l-0.7,26.2c0,1.1-0.1,2.2-0.1,3.4c-0.5,15.5-1.2,30.1-1.9,43.8h0.1
      c-1.3,19.2-2.8,36.7-4.5,52.4l6.5-0.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="362.3" y1="346.7" x2="348.4" y2="340.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M344.6,579
      c1.8,5.3,2.9,9.6,3.1,13c0.4,7.4,0.5,16.2,0.5,26.5c0.1,6.5,0.2,13.1,0.2,19.7l3.2-4.4c-0.1-5.1-0.1-10.2-0.2-15.3
      c0-10.2-0.3-17.8-1-22.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M354.4,627l-2.9,6.8
      c0.3,24.6,0.3,49.8-0.1,75.6c-0.1,3-0.1,6.1-0.1,9.1c-0.2,13-0.9,24-2.1,33.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.4,574.1
      c-0.1,0.2-0.2,0.5-0.2,0.9c0,0.2,0,0.4,0,0.6c0,0.5,0.1,1.2,0.2,2c0.1,0.5,0.2,1.1,0.3,1.7c0,0.4,0,0.7,0.1,1.1
      c1,7.1,1.1,16.1,0.4,27h0.9c0.8-11,0.6-20-0.4-27.1c-0.1-0.6-0.1-1.1-0.3-1.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M328.2,488.1l-2.2,0.1
      L322.6,600c-0.3,8.6-0.7,17.7-1.1,27.2l2.4,0.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="326.1" y1="488.1" x2="309.2" y2="488.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M292.2,779h0.2l1.4,0.3
      c1.1-8.2,2.1-16.5,3.1-24.9c1-9.1,2-18.3,2.9-27.6c0.1-1.3,0.3-2.6,0.4-3.9l0.8-9.6c0-0.1,0-0.2,0-0.3c0.1-0.7,0.1-1.4,0.1-2.1
      c0.3-3.9,0.5-7.9,0.8-11.9c2.3-38.9,3.8-81.3,4.4-127.3l1.6-73.8c0.1-7.7,0.2-15.4,0.2-23.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M324.4,651.6
      c-1,15.5-2.1,30.6-3.3,45.3c-1.1,12.1-2.2,23.9-3.4,35.4c-0.6,5.9-1.3,11.6-2,17.4c-1.3,11.7-2.7,23.2-4.1,34.5l1.7,0.3l12.7-3.2
      c1.3-11.1,2.7-22.3,4-33.7v0c2.8-30.3,5.1-62.3,6.8-96L324.4,651.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.8,648.4
      c-0.6,13.6-1.3,27.3-2.1,41.2c0.3-0.1,0.6-0.1,1.1-0.1c0.3,0,0.7,0,1.1,0.1c3.1,0.8,4.8,4.1,5.2,10c-0.4,10.1-3.2,19.8-8.4,29.1
      l-1.5,2.4c-1,14.6-2.1,29.4-3.3,44.4c1.2-3.4,2.7-6.5,4.4-9.3c2-2.7,4-5.4,6.1-8c0.1-6.7,0.6-14.1,1.5-22.3l0.3-2.3
      c0.1-0.4,0.1-0.7,0.1-1.1c0-0.1,0-0.2,0-0.3c1-8.3,1.7-17.2,2.2-26.7c0.8-23.2,1.1-45.6,1-67.3
      C345.8,640.8,343.3,644.1,340.8,648.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M305.7,651.1
      c-0.6,15.7-1.4,30.8-2.2,45.5l15.4,1.1v0c0-0.2,0-0.3,0.1-0.4c0.1-0.4,0.1-0.6,0.1-0.6c1.3-14.7,2.4-29.7,3.3-45.2L305.7,651.1z"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="321.4" y1="627.1" x2="306.6" y2="626.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M345,607.3
      c-0.2,2.5-0.4,5-0.7,7.7h-0.8h-1.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.5,615
      c0.1-1.1,0.2-2.2,0.2-3.2c0-0.2,0-0.4,0.1-0.6c0.1-1.3,0.2-2.6,0.3-3.9h-3.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340,581.9h1.4
      c0.4,0,0.7,0.1,1,0.4c0.4,0.3,0.6,0.7,0.7,1.4c0,0.2,0,0.4,0,0.5c0.4,4.1,0.5,8.7,0.3,13.8c-0.1,1-0.3,1.6-0.7,1.9
      c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.6,0h-1.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M342.4,582.3
      c0,0.3-0.1,0.6-0.1,1c0,0.1,0,0.2,0,0.3v16.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.2,758.2
      c1.9-2.3,3.9-4.5,5.9-6.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M336.1,731.1l-0.8,1.3
      c-2,3.7-2.9,2.4-2.6-3.9l2.8-27.6c0.5-7.3,1.6-11,3.2-11.2C337.9,703.3,337.1,717.1,336.1,731.1z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.5,696.6
      c0,0.8-0.1,1.6-0.1,2.4c-0.1,1.5-0.2,3-0.3,4.5c-0.2,2.5-0.3,5-0.5,7.4c0,0.7-0.1,1.4-0.1,2.1c0,0.1,0,0.2,0,0.3l-0.8,9.6
      c-0.1,1.3-0.2,2.6-0.4,3.9c-0.5,6-1.1,12-1.8,18c0,0.4-0.1,0.7-0.1,1.1l0,0.4c-0.3,2.7-0.6,5.5-0.9,8.2c-1,8.6-2.1,17.1-3.2,25.4
      c4.2,1.6,8.2,2.9,12.2,4c0.7,0.2,1.3,0.3,1.9,0.3c0.1,0,0.2,0,0.2,0c0,0,0,0,0.1,0c4-29.2,7.1-58,9.3-86.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="319" y1="697.8" x2="318.9" y2="697.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M318.9,697.8l0.1-0.7
      c0,0,0,0,0,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M281.3,592.2l-2.4,88.3
      c-0.1,4.6-0.3,9-0.4,13.5c-0.5,12.2-1,23.8-1.6,34.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,1-0.1,2.1-0.2,3.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M325.9,781.2l5.5-1.4
      c0.4-1.5,0.8-2.9,1.3-4.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M329.5,789.3
      c0.5-3.4,1.1-6.5,2-9.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M309.6,784.2
      c0.7,0.1,1.3,0,1.9,0c-0.5,3.6-0.9,7.2-1.4,10.8c0,0.3-0.1,0.6-0.1,0.9h0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M293.7,779.2h0.2
      c0.5,0.2,0.9,0.4,1.3,0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M292.1,779.2c0-0.1,0-0.2,0-0.3
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M292.3,779
      c-0.6,3.9-1.2,7.8-1.9,11.7c0.5,0.1,1,0.1,1.6,0.2c0.1-0.6,0.2-1.2,0.3-1.9c0.5-3.2,0.9-6.5,1.3-9.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M308.3,796.3
      c0.6-0.1,1.1-0.3,1.7-0.4c-0.5-0.1-1.1-0.2-1.6-0.4L308.3,796.3c-2.2,0.5-4.3,1-6.5,1.4l2.9,0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M324.5,792.9
      c0.5-3.9,0.9-7.8,1.4-11.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M308.4,795.5
      c-5.5-1.2-10.9-2.8-16.3-4.6c-0.6,0.2-1.2,0.3-1.7,0.4c-8.2,2-16.9,3.4-26.1,4.1c0.3,0.1,0.5,0.1,0.7,0.2l12.9,5.4
      c1.2-0.1,2.4-0.2,3.7-0.4c7-0.7,13.7-1.7,20.3-3"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="284.2" y1="801.5" x2="281.5" y2="800.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M282.9,801.7
      c-6.2,0.7-12.6,1.2-19.2,1.4c-24.4,0.9-48.7,1.4-73.1,1.5h-4.2c0,0,0,0-0.1,0h-4.1c0,0,0,0-0.1,0H178c-24.4-0.1-48.8-0.6-73.1-1.5
      c-6.5-0.2-12.9-0.7-19.2-1.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M277.8,801.1
      c-4.6,0.4-9.3,0.7-14.1,0.9c-24.4,0.9-48.7,1.3-73.1,1.4h-4.2c0,0,0,0-0.1,0h-4.1c0,0,0,0-0.1,0H178c-24.4-0.1-48.8-0.6-73.1-1.4
      c-4.8-0.2-9.5-0.5-14.1-0.9c-1.4-0.1-2.9-0.3-4.3-0.4l-2.4,0.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265.9,780l-8,0.1
      c-1.5-0.2-2.7-0.7-3.6-1.4l-2.3-2.1c-0.1-0.1-0.3-0.3-0.5-0.4c-0.7-0.5-1.6-0.9-2.6-1l0.2,17.7l26.9-2.1c0.8-0.1,1.6-0.1,2.4-0.2
      c4.3-0.3,8.3-0.3,12,0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M249,775.2
      c-1.5,5.8-3.5,11.9-6.1,18.2l6.4-0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M249,775.2H119.6
      c1.5,5.8,3.5,11.9,6.1,18.2l8.4,0.6c0.1,0,0.1,0,0.2,0c2.2,0,4.4,0.1,6.6,0.1v-1.9c0-0.3,0.1-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0
      c12.2,0.2,24.3,0.4,36.5,0.4h4.2c0,0,0,0,0.1,0h4.1c0,0,0,0,0.1,0h4.2c12.2-0.1,24.3-0.2,36.5-0.4c0.1,0,0.1,0,0.2,0
      c0.2,0,0.3,0.2,0.4,0.4v1.9c2.2-0.1,4.4-0.1,6.6-0.1c0.1,0,0.1,0,0.2,0l8.4-0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M264.2,795.4
      c-2.1-0.7-6.8-0.9-14-0.7c-19.9,0.6-39.8,1-59.7,1h-4.2c0,0,0,0-0.1,0c-1.4,0-2.7,0-4.1,0h-0.1c-1.4,0-2.8,0-4.2,0
      c-19.9-0.1-39.8-0.4-59.7-1c-7.2-0.2-11.9,0-14,0.7c-0.3,0.1-0.5,0.1-0.7,0.2l-12.9,5.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M314.2,43.8l0.4,1.6
      c8,4.8,15.9,9.7,23.7,15c1.4,0.9,2.8,1.8,4.2,2.8c0.1,0.1,0.2,0.1,0.3,0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M320.7,56.3
      c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.3-0.3-0.6-0.6-0.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M320,55.2c-0.5-0.5-1-1-1.5-1.5
      c-1.7-1.7-3.6-3.3-5.8-5c-14.1-10.6-29.3-19-45.5-25.2c-0.7-0.3-1.5-0.6-2.2-0.9v2.3v4c0,1,0.9,1.7,2.7,2.2
      c2.4,0.9,4.6,1.8,6.9,2.7c16.3,6.9,29.2,15.2,38.6,24.9c0,0,0,0,0,0c0.8,0.8,1.6,1.7,2.4,2.6c1.6,1.8,3.1,3.6,4.5,5.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M274.5,33.8l-0.4,1.4
      c16.6,6.6,29.3,14.8,38.3,24.5c2.9,3.1,5.3,6.4,7.4,9.8c10.7,19.2,17,54.5,18.9,105.9c0.6,22.4,1.2,44.5,1.6,66.5l4.7-1.8
      c-0.2-2-0.3-3.9-0.4-5.9c-0.2-3.2-0.4-6.4-0.7-9.6c-1.1-16.4-2-32.8-2.7-49.3c-1.8-55.8-8.7-95.5-20.7-119.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M301.7,38.7
      c3.8,2.4,7.6,4.8,11.3,7.4c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.1,0.1l0,0c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0.1c1.1,0.6,1.6,0.3,1.2-1.1"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M263.1,31.2l-0.1,0
      c-1.2-0.4-2.4-0.7-3.7-1c-1.3-0.3-2.5-0.6-3.8-0.8l-1.5-0.3c-1.5-0.2-3.2-0.5-5.1-0.7c-0.1,0-0.2,0-0.3,0c-2.2-0.3-4.6-0.5-7.3-0.8
      c-17.1-1.3-34-2-50.8-2.2c-0.2,0-0.4,0-0.6,0h-12c-16.7,0.2-33.7,0.9-50.8,2.2c-2.7,0.3-5.1,0.5-7.3,0.8c-0.1,0-0.2,0-0.3,0
      c-1.8,0.3-3.5,0.5-5.1,0.7l-1.5,0.3c-1.3,0.2-2.6,0.5-3.8,0.8c-1.2,0.3-2.4,0.6-3.7,1l-0.1,0c-3.8,1.3-7.5,2.6-11.1,4l-0.7,18.3
      c4.6-2.1,9.6-4,15-5.8l0.2,0c1.1-0.3,2.3-0.6,3.4-0.9c1.2-0.3,2.4-0.6,3.6-0.8l1.3-0.2c1.5-0.2,3.1-0.5,4.8-0.6
      c0.1,0,0.2-0.1,0.3-0.1c2.1-0.3,4.3-0.5,6.8-0.8c16.5-1.2,32.7-1.9,48.8-2.1l3.6,0c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.4,0,0.7,0
      c0.9,0,1.8,0,2.7,0h0.7c0,0,0,0,0.1,0c0.8,0,1.6,0,2.4,0c0.6,0,1.2,0,1.7,0c16.1,0.1,32.4,0.8,48.8,2.1c2.5,0.3,4.7,0.5,6.8,0.8
      c0.1,0,0.2,0,0.3,0.1c1.7,0.2,3.3,0.4,4.8,0.6l1.3,0.2c1.2,0.2,2.4,0.5,3.6,0.8c1.2,0.3,2.3,0.6,3.4,0.9l0.2,0
      c5.3,1.8,10.3,3.7,15,5.8l-0.7-18.3C270.6,33.8,266.9,32.5,263.1,31.2z"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="265" y1="22.6" x2="265" y2="21"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265,28.8
      c-3.6-1.6-9.2-2.9-17-3.9c-0.9-0.1-1.9-0.2-2.9-0.4c-0.7-0.1-1.5-0.1-2.3-0.2c-17.6-1.3-35-2.1-52.2-2.2c-1.4,0-2.8,0-4.2,0
      c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.6,0h-2.9c-0.2,0-0.4,0-0.6,0c0,0,0,0-0.1,0c-1.4,0-2.8,0-4.2,0c-17.2,0.2-34.6,0.9-52.3,2.2
      c-0.8,0.1-1.5,0.1-2.3,0.2c-1,0.1-2,0.2-2.9,0.4c-7.7,0.9-13.4,2.2-16.9,3.9c0,1-0.9,1.7-2.7,2.2c-2.4,0.9-4.6,1.8-6.9,2.7l0.4,1.4
      C77.9,41.8,65.1,50,56.1,59.7c-2.9,3.1-5.4,6.4-7.5,9.8c-10.7,19.2-17,54.5-18.9,105.9c-0.6,22-1.1,43.8-1.6,65.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M248,23.2v-2.1
      c-1-0.1-2-0.3-3-0.4c-0.7-0.1-1.5-0.2-2.2-0.2c-17.6-1.3-35-2-52.2-2.2c-0.2,0-0.4,0-0.6,0l-3.6,0c0,0,0,0-0.1,0
      c-0.2,0-0.4,0-0.6,0h-2.9c-0.2,0-0.4,0-0.6,0c0,0,0,0-0.1,0l-4.2,0c-17.2,0.2-34.6,0.9-52.3,2.2c-0.7,0.1-1.5,0.2-2.2,0.2
      c-1,0.1-2,0.3-3,0.4v2.1c1-0.1,1.9-0.2,2.9-0.3V22c0.7-0.1,1.5-0.2,2.3-0.2c17.6-1.3,35-2,52.3-2.2c1.4,0,2.8,0,4.2,0
      c0,0,0,0,0.1,0h0.6c1,0,1.9,0,2.9,0h0.6c0,0,0,0,0.1,0c1.4,0,2.8,0,4.2,0c17.2,0.2,34.6,0.9,52.2,2.2c0.8,0.1,1.6,0.2,2.3,0.2v0.9
      C246.1,22.9,247.1,23.1,248,23.2V25"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="245.1,22.8 
      245.1,23.1 245.1,24.6 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M245.1,23.1
      c-0.7-0.1-1.5-0.2-2.3-0.2c-17.6-1.3-35-2-52.2-2.2c-0.2,0-0.4,0-0.6,0l-3.6,0c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.6,0h-2.9
      c-0.2,0-0.4,0-0.6,0c0,0,0,0-0.1,0l-4.2,0c-17.2,0.2-34.6,0.9-52.3,2.2c-0.8,0.1-1.5,0.2-2.3,0.2v1.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265,19.7
      c-6.5-1.1-12.9-2-19.3-2.7c-0.2,0-0.4,0-0.6-0.1c-0.7-0.1-1.5-0.1-2.2-0.2c-17.6-1.3-35-2-52.2-2.2c-0.2,0-0.4,0-0.6,0l-3.6,0
      c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.6,0h-2.9c-0.2,0-0.4,0-0.6,0c0,0,0,0-0.1,0l-4.2,0c-17.2,0.2-34.6,0.9-52.3,2.2
      c-0.7,0.1-1.5,0.1-2.2,0.2c-0.2,0-0.5,0.1-0.6,0.1c-6.4,0.7-12.8,1.7-19.3,2.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265,24.8
      c-4.5-1.5-10.2-2.8-17-3.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M254.5,78
      c-0.7-0.1-1.3-0.2-1.9-0.3c-2.7-0.5-4.7-0.8-6.1-0.9c-0.1,0-0.2,0-0.3,0c-2.1-0.3-4.3-0.5-6.8-0.8c-16.5-1.2-32.7-1.9-48.8-2.1
      c-0.2,0-0.4,0-0.6,0c-1.2,0-2.4,0-3.6,0c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.7,0c-0.9,0-1.8,0-2.7,0h-0.7c0,0,0,0-0.1,0
      c-1.4,0-2.8,0-4.2,0c-16.1,0.2-32.4,0.9-48.8,2.1c-2.5,0.2-4.7,0.5-6.8,0.8c-0.1,0-0.2,0-0.3,0c-1.4,0.2-3.5,0.5-6.1,0.9
      c-0.6,0.1-1.2,0.2-1.9,0.3c0.5,3.5,0.7,6.8,0.8,9.9l-1.8,110.8c0.1,1.7,0.5,3,1.3,3.7c0.7,0.7,1.8,0.9,3.3,0.7
      c20.1-3.4,40.2-5,60.4-5.1c1.4,0,2.8,0,4.2,0c0,0,0,0,0.1,0c0.6,0,1.3,0,2,0c0,0,0.1,0,0.1,0c0.7,0,1.3,0,2,0c0,0,0,0,0.1,0
      c0.7,0,1.4,0,2.2,0h1.2c0.3,0,0.5,0,0.8,0c20.2,0,40.3,1.7,60.4,5.1c1.4,0.2,2.5,0,3.2-0.7c0.8-0.7,1.3-2,1.3-3.7l-1.8-110.8
      C253.8,84.7,254.1,81.4,254.5,78c1.1-9,3.4-18.9,6.8-29.7l2.3,161.8c0.1,2-0.3,3.3-1.3,4.1c-0.9,0.6-2.2,0.7-4,0.3
      c-22.5-4.9-45.1-7.5-67.8-7.8c-0.2,0-0.4,0-0.6,0l-3.6,0c0,0,0,0-0.1,0h-3.1c-0.3,0-0.7,0-1,0c0,0,0,0-0.1,0l-4.2,0
      c-22.7,0.3-45.3,2.9-67.8,7.8c-1.7,0.5-3.1,0.4-3.9-0.3c-1-0.7-1.4-2.1-1.3-4.1l2.3-161.8c3.4,10.9,5.7,20.8,6.8,29.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M275.7,75.5
      c16.3,4.7,26.9,21.7,32,51c4.1,29.3,7,60.5,8.7,93.6c0.2,3,0.4,6,0.5,9.1c0.3,4.5,0.6,9.1,0.8,13.6c0.1,2.3,0.3,4.5,0.4,6.8
      c0,1.1,0.1,2.2,0.1,3.2c0,0.5,0,1,0.1,1.5c0,0.8,0,1.5-0.1,2.2c5.7,2.2,9.1,9.1,10.4,20.5l1.5-0.4c-0.5-30.2-1.2-60.7-2-91.6
      c-0.7-21.6-2.3-40.1-4.7-55.7c-2.9-27.2-10.2-47-22.1-59.5c-7-6.3-15.8-11.8-26.5-16.5C275.1,60.9,275.4,68.2,275.7,75.5
      c1.4,35.2,3,69.7,4.8,103.7c0,0.3,0,0.5,0.1,0.7l0,0.5c0.4,5.2,2.1,9.3,5.1,12.4c0.1,0.1,0.2,0.2,0.3,0.3l30.4,26.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M348.6,236.1
      c2.3-8.7,3.5-21.1,3.7-37.2c0-0.6,0-1.2,0-1.8c0.1-31,0.1-61.6-0.1-91.8c-0.1-15.1-1.1-26.2-3.1-33.2"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="340.6,269.6 
      341,269.4 345.5,267.5 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.1,242.2
      c-2.6,1.9-4,4.3-4.3,7.1c0,0.9,0,1.9,0.1,2.8c0.1,6.8,0.2,13.7,0.4,20.5l4.4-3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M329.7,307.3
      c-0.2-8.1-0.5-16.1-0.6-24.1l-0.1-1.5c-0.1-1.6-0.2-3.1-0.4-4.6l-1.8,7.3c0,0,0,0,0,0.1c0,0.6,0.1,1.2,0.1,1.8
      c0.2,6.1,0.4,12.3,0.5,18.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="330.1" y1="276.8" x2="336.2" y2="272.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M345.4,243.5c0-0.1,0-0.2,0-0.4
      c-0.1-1-0.2-1.9-0.2-2.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M318.1,256.7
      c-0.1,1-0.2,2-0.4,3c-0.3,1.5-0.5,3-0.8,4.5c5.6,2.5,8.9,9.3,9.9,20.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M316.9,264.2
      c-3.3-1.6-7-3.1-11.1-4.6c-37.8-13.2-76.2-20-115.2-20.5c-1.4,0-2.8,0-4.2,0c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.5,0h-0.2
      c-0.1,0-0.2,0-0.3,0h-2.2c-0.1,0-0.2,0-0.3,0h-0.2c-0.2,0-0.3,0-0.5,0c0,0,0,0-0.1,0c-1.4,0-2.8,0-4.2,0
      c-39,0.5-77.4,7.3-115.2,20.5c-4.1,1.5-7.8,3-11.1,4.6c0.1,0.2,0.1,0.5,0.1,0.7l0.1,0.7c3.2-1.5,6.8-3,10.8-4.4
      c37.8-13.2,76.2-20,115.2-20.5c1.4,0,2.8,0,4.2,0c0.1,0,0.2,0,0.3,0h0.5c0.1,0,0.2,0,0.4,0h2c0.1,0,0.2,0,0.3,0h0.5
      c0.1,0,0.2,0,0.3,0c1.4,0,2.8,0,4.2,0c39,0.5,77.4,7.3,115.2,20.5c4,1.4,7.6,2.9,10.8,4.4C316.7,265.1,316.8,264.6,316.9,264.2z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M318.1,249.6
      c-11-8.5-26.1-15.2-45.4-20.2l-4.4-1.1c-2.2-0.5-4.4-1.1-6.6-1.6c-23.6-5.3-47.3-8.1-71.1-8.5c-0.2,0-0.4,0-0.6,0l-3.6,0
      c0,0,0,0-0.1,0h-4.1c0,0,0,0-0.1,0l-4.2,0c-23.8,0.4-47.5,3.2-71.1,8.5c-2.2,0.5-4.4,1-6.6,1.6l-4.4,1.1
      c-19.2,4.9-34.3,11.7-45.4,20.2c-0.1,1.1-0.1,2.2-0.1,3.2c0,0.5,0,1-0.1,1.5c0,0.2,0,0.4,0,0.6c0,0.6,0,1.1,0.1,1.7
      c0.5-0.2,0.9-0.4,1.4-0.5c3.7-1.5,7.3-2.8,11-4.1c37.8-13.2,76.2-20,115.2-20.5c1.4,0,2.8,0,4.2,0c0.1,0,0.2,0,0.3,0h0.5
      c0.1,0,0.2,0,0.4,0h2c0.1,0,0.2,0,0.3,0h0.5c0.1,0,0.2,0,0.3,0c1.4,0,2.8,0,4.2,0c39,0.5,77.4,7.3,115.2,20.5
      c4.1,1.5,8.2,3,12.4,4.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M315.6,270.8
      c0.4-1.7,0.7-3.4,1-5.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M346.4,241.1
      c1.9-6.8,2.9-14,2.9-21.7l-2.2-102.7c-1.2-28.7-4.1-47.5-8.8-56.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.2,242.1
      c0-0.1,0.1-0.1,0.2-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M93.8,53.5
      c-0.3,7.4-0.6,14.7-0.9,22c-1.4,35.2-3,69.7-4.8,103.7c0,0.3,0,0.5-0.1,0.7l0,0.5c-0.4,5.2-2.1,9.3-5.1,12.4
      c-0.1,0.1-0.2,0.2-0.3,0.3l-30.4,26.9c-0.2,3-0.3,6-0.5,9.1c-0.3,4.5-0.6,9.1-0.8,13.6c10.4-5,21-9.2,31.9-12.5
      c31.6-9.4,63.4-14.3,95.2-14.9c1.4,0,2.8,0,4.2,0h0.1c0.3,0,0.7,0,1,0h3.1c0,0,0,0,0.1,0c0.8,0,1.5,0,2.3,0h0.9c0.3,0,0.6,0,1,0
      c31.9,0.5,63.6,5.5,95.2,14.9c10.9,3.4,21.6,7.6,31.9,12.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M186.4,240.6
      C186.4,240.6,186.4,240.6,186.4,240.6c-0.1,0-0.2,0-0.3,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M185.6,240.6
      c-0.1,0-0.1,0-0.2,0h-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M183.3,240.6h-0.1
      c-0.1,0-0.2,0-0.2,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M186.4,231.5
      C186.4,231.5,186.4,231.5,186.4,231.5c-0.1,0-0.2,0-0.3,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M183.3,231.5h-0.1
      c-0.1,0-0.2,0-0.2,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M185.6,231.4
      c-0.1,0-0.1,0-0.2,0h-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M182.5,231.4
      c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M182.5,240.6
      c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M28.5,242.2
      c0.3,0.3,0.7,0.5,1,0.8c0.4,0.4,0.7,0.7,1.1,1.1c0.2,0.3,0.4,0.5,0.6,0.7l7.1,9c0.2,0.2,0.4,0.4,0.7,0.6c0.5-22.9,1-45.9,1.6-69.2
      c0.7-21.6,2.3-40.1,4.7-55.7c2.9-27.2,10.3-47,22.1-59.5c7-6.3,15.8-11.8,26.5-16.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M20,236.1
      c-2.3-8.7-3.5-21.1-3.7-37.2c0-0.6,0-1.2,0-1.8c-0.1-31-0.1-61.6,0.1-91.8c0.1-15.1,1.1-26.2,3-33.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M28.4,242.1
      c-0.1-0.1-0.1-0.1-0.2-0.1l-4.7-1.8l-1.5,0.4l-0.1,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M23.5,240.2
      c0.2-2,0.3-3.9,0.4-5.9c0.2-3.2,0.5-6.4,0.7-9.6c1.1-16.4,2-32.8,2.7-49.3C29.1,119.7,36,80,47.9,56.3C48,56.2,48,56.1,48,56
      c0.2-0.3,0.3-0.6,0.5-0.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M48.6,55.2c0.5-0.5,1-1,1.5-1.5
      c1.7-1.7,3.6-3.3,5.8-5C70,38,85.2,29.6,101.4,23.4c0.7-0.3,1.5-0.6,2.2-0.9V21"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M66.9,38.7
      c-3.8,2.4-7.6,4.8-11.3,7.4c0,0,0,0-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1l0,0c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0.1
      c-1.1,0.6-1.6,0.3-1.2-1.1c-8,4.8-15.9,9.7-23.7,15c-4.7,8.8-7.6,27.6-8.8,56.3l-2.2,102.7c0,7.5,0.9,14.5,2.7,21.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M18.6,241.5l3.7,8
      c0.3,0.7,0.6,1.5,0.8,2.2c0.2,0.7,0.4,1.3,0.5,2c0,0.2,0.1,0.3,0.1,0.5c0.1,0.6,0.2,1.2,0.2,1.8v15.1c0.4,0.2,0.7,0.4,1.1,0.6
      c0.9,0.6,1.8,1.2,2.5,1.8c2.2,1.7,3.6,3.7,4.2,5.8c0.4,1.6,0.8,3.2,1.1,4.9c0.3-4,0.7-7.5,1.2-10.4c1-5.6,2.5-9.1,4.4-10.5
      c0.1,0,0.2-0.1,0.3-0.1c1-0.5,1.7-0.2,2.1,1.1l15.3,73.5c0.1,0.8-0.1,1.7-0.7,2.6c-0.4,0.6-0.9,1.2-1.6,1.8
      c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0.2-0.3,0.4-0.5,0.6c-2.1,2.9-2.9,6.4-2.5,10.7c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.1,0.2,0.2
      c0.4,0.5,0.9,0.8,1.6,0.9l-0.3-1.6c-0.4-3.4,0.1-6.3,1.5-8.7c0.3-0.5,0.6-1,1-1.5c0.4-0.4,0.9-0.7,1.3-1c2.7-1.7,6.6-2.4,12-2
      c-5.3-22.9-10.1-45-14.3-66.4c-0.1-0.2-0.1-0.5-0.1-0.7c-0.2-0.8-0.3-1.6-0.5-2.4c-0.3-1.7-0.7-3.4-1-5.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M50.4,256.7
      c0.1,0.9,0.2,1.9,0.4,2.8c0,0.1,0,0.1,0.1,0.2c0.2,1.2,0.5,2.7,0.8,4.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M38.9,254.4
      c0.5,0.3,1,0.4,1.7,0.6l8.1,1c0.9,0.1,1.5,0.3,1.8,0.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M50.9,242.8
      c-0.1,2.3-0.3,4.5-0.4,6.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M23.9,271.1
      c-0.3-0.2-0.5-0.4-0.8-0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M92.9,75.5
      c-16.3,4.7-27,21.7-32,51c-4.1,29.3-7,60.5-8.7,93.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="123.5" y1="22.8" x2="123.5" y2="23.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="120.6" y1="23.2" x2="120.6" y2="25"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M103.6,22.6v2.3
      c4.5-1.5,10.2-2.8,16.9-3.8"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="103.6" y1="24.8" x2="103.6" y2="28.8"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="54.4" y1="43.8" x2="54" y2="45.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M94,33.8
      c-16.3,6.9-29.2,15.2-38.6,24.9c0,0,0,0,0,0c-0.8,0.8-1.6,1.7-2.4,2.6c-1.6,1.8-3.1,3.6-4.5,5.5c-0.6,0.7-0.9,0.6-1.1-0.3
      c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4c-0.3-2.6-0.2-5.2,0.1-7.7c0.1-0.7,0.3-1.3,0.5-1.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M30.3,60.4
      c-1.4,0.9-2.8,1.8-4.2,2.8c-0.1,0.1-0.2,0.1-0.3,0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M141,794.2
      c12.4,0.3,24.7,0.4,37.1,0.5h4.2c1.4,0,2.7,0,4.1,0c0,0,0,0,0.1,0h4.2c12.3-0.1,24.7-0.2,37-0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M95.5,654.3l0.7,26.2
      c0,1.1,0.1,2.2,0.1,3.4c0.5,15.5,1.1,30.1,1.9,43.8h-0.1c1.4,19.2,2.9,36.7,4.5,52.4l8,0.1c1.5-0.2,2.7-0.7,3.6-1.4l2.3-2.1
      c0.1-0.1,0.3-0.3,0.4-0.4c0.8-0.5,1.6-0.9,2.6-1l-0.2,17.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M125.7,793.4l-6.3-0.5l-27-2.1
      c-0.8-0.1-1.5-0.1-2.3-0.2c-4.3-0.3-8.3-0.3-12,0.1c-0.5,0.1-1,0.1-1.6,0.2c0.6,0.2,1.2,0.3,1.7,0.4c8.2,2,16.9,3.4,26.1,4.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M75.7,592.2h-0.3
      c-3.5,0.1-5.2,2.8-5.3,8.2c0,0.4,0,0.9,0,1.3c0.2,32.5,1,63,2.3,91.5c0.2,4.5,0.4,8.9,0.6,13.3c0,0.1,0,0.2,0,0.2l1,12.6
      c0.1,1.5,0.3,3,0.4,4.6c0.6,5.6,2.6,8.3,5.9,8.3H92c-0.1-1-0.1-2.1-0.2-3.1c0-0.1,0-0.2-0.1-0.3c-0.6-11-1.1-22.7-1.6-34.8
      c-0.2-4.4-0.3-8.9-0.5-13.5l-2.5-88.3H75.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M64,651.1l-1.1,0
      c0.6,15.7,1.4,30.8,2.2,45.5c0,0.8,0.1,1.6,0.1,2.4c0.1,1.5,0.2,3,0.3,4.5c0.1,2.5,0.3,5,0.5,7.4c0,0.7,0.1,1.4,0.1,2.1
      c0,0.1,0,0.2,0,0.3l0.8,9.6c0.1,1.3,0.2,2.6,0.4,3.9c0.5,6,1.1,12,1.7,18c0,0.4,0.1,0.7,0.1,1.1l0,0.4c0.3,2.7,0.6,5.5,0.9,8.2
      c1,8.6,2.1,17.1,3.2,25.4c0.5-0.2,0.9-0.4,1.4-0.5h0.2c-1.1-8.2-2.1-16.5-3.1-24.9c-1-9.1-2-18.3-2.8-27.6
      c-0.1-1.3-0.3-2.6-0.4-3.9l-0.8-9.6c0-0.1,0-0.2,0-0.3c-0.1-0.7-0.1-1.4-0.1-2.1c-0.3-3.9-0.5-7.9-0.7-11.9
      c-2.4-38.9-3.8-81.3-4.4-127.3l-1.6-73.8c-0.1-7.7-0.2-15.4-0.2-23.3l-1.4-0.4c0,1.6,0,3.1,0,4.6c0,3.2,0.1,6.4,0.1,9.6
      c0,3.2,0.1,6.3,0.1,9.4l1.3,73.8c0,0.7,0,1.4,0,2c0.3,18.1,0.6,35.7,1.2,52.8c0.2,8.3,0.5,16.5,0.9,24.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M74.9,779.2l1.4-0.3l0.3,0
      c-1.1-8.1-2.1-16.3-3.1-24.7c-1-9.1-2-18.3-2.8-27.6c-0.1-1.3-0.3-2.6-0.4-3.9l-0.8-9.6c0-0.1,0-0.2,0-0.3
      c-0.1-0.7-0.1-1.4-0.1-2.1c-0.3-3.9-0.5-7.9-0.7-11.9c-2.4-38.9-3.8-81.3-4.4-127.3l-1.7-73.8c-0.1-7.5-0.2-15.1-0.2-22.9
      c-0.2-33.4,0-68.7,0.7-105.9c0.4-6.2,1.6-10.8,3.7-13.6c0.8-1.1,2.1-2,3.8-2.7l0,0c0.1,0,0.2-0.1,0.2-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M52.9,732l-3.3-34.2
      c0-0.2,0-0.5-0.1-0.7v0c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c-1.2-14.7-2.3-29.7-3.3-45.2l-1.9,0.1c1,15.5,2.1,30.6,3.3,45.3
      c1.1,12.1,2.2,23.9,3.4,35.4c0.6,5.9,1.3,11.6,1.9,17.4c1.4,11.7,2.7,23.2,4.2,34.5c0.6,0.1,1.2,0.1,1.9,0
      c-1.2-9.3-2.3-18.7-3.4-28.4c-0.2-2-0.5-4-0.7-6.1c0-0.2,0-0.3-0.1-0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M54.8,748.8l-0.1-0.4
      c-0.6-5.3-1.2-10.7-1.8-16.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M27.8,689.6
      c-3.1,0.8-4.8,4.1-5.2,10c0.4,10.1,3.2,19.8,8.4,29.1l1.5,2.4c-1-14-1.8-27.8-2.6-41.4c-0.2-0.1-0.6-0.1-1-0.1
      C28.5,689.5,28.2,689.5,27.8,689.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M17.4,718.5
      c0.2,13,0.9,24,2.1,33.1l5.9,6.6c-0.1-6.7-0.6-14.1-1.5-22.3l-0.2-2.3c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.1,0-0.2,0-0.3
      c-1-8.3-1.7-17.2-2.2-26.7c-0.8-23.2-1.1-45.6-1-67.3l-3.2-4.4c-0.3,24.6-0.3,49.8,0.2,75.6C17.3,712.4,17.3,715.4,17.4,718.5z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M33.1,700.8l2.8,27.6
      c0.3,6.2-0.5,7.5-2.6,3.9l-0.8-1.3c1,14.6,2.1,29.4,3.3,44.4c0.5,1.4,0.9,2.8,1.4,4.3l5.5,1.4c-1.3-11.1-2.6-22.3-3.9-33.7v0
      c-2.8-30.3-5.1-62.3-6.8-96c-1.4-0.3-2.8-1.3-4.2-3.1c0.6,13.6,1.3,27.3,2.1,41.2C31.5,689.8,32.6,693.5,33.1,700.8z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M35.8,775.4
      c-1.2-3.4-2.7-6.5-4.4-9.3c-1.9-2.7-4-5.4-6.1-8"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="49.6" y1="697.8" x2="65.1" y2="696.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M92,732.2
      c1.3,17.4,2.7,33.3,4.2,47.7l6.5,0.1"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="76.6,779 92.6,779.4 
      96.2,779.9 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M76.6,779.2c0-0.1,0-0.2,0-0.2"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M74.9,779.2
      c0.4,3.3,0.9,6.5,1.3,9.8c0.1,0.6,0.2,1.2,0.3,1.9c-6,2-12,3.7-17.9,5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M76.3,779
      c0.6,3.9,1.2,7.8,1.9,11.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M73.3,779.8
      c-4.1,1.6-8.2,2.9-12.1,4c-0.7,0.2-1.3,0.3-1.9,0.3c-0.1,0-0.2,0-0.3,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M86.5,800.7
      c-6.6-0.7-13.1-1.7-19.4-2.9l-2.7,0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M67.1,797.7
      c-2.9-0.6-5.7-1.2-8.5-1.9h0c0-0.3-0.1-0.6-0.1-0.9c-0.5-3.6-0.9-7.2-1.4-10.8l-1.7,0.3l-12.7-3.2c0.5,3.9,1,7.8,1.4,11.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M37.2,779.8
      c0.8,3,1.5,6.2,1.9,9.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M43.6,600.3l0.3,6.8
      c0.2,4,0.4,8,0.5,12l0.4,6.5l0.1,1.6l2.4-0.1c-0.5-9.5-0.8-18.5-1.2-27.2l-3.5-111.9l-2.2-0.1c0.1,3.3,0.2,6.6,0.3,9.9l1.9,73.9
      c0,0.5,0,1.1,0,1.6c0.3,9,0.6,17.8,0.9,26.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M40.7,571.7
      c0-0.8-0.1-1.6-0.1-2.4l-2.2-71.4c-0.3-10-0.5-20.1-0.7-30.4c-3.7-0.7-7.4-2-11.1-3.7c0.2,11.5,0.4,22.6,0.6,33.2
      c0.5,26,1.2,50.8,2.1,74.6c0,0.5,0,0.9,0,1.3c0.5,27.1,1.4,53.2,2.7,78.4l12.3,0.1c-0.5-8.5-1-17.1-1.5-25.9
      c-0.9-17-1.6-34.5-2.1-52.4C40.7,572.8,40.7,572.3,40.7,571.7z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M24,579.9c0,0.1,0,0.2,0,0.3
      c-1,7.1-1.2,16.1-0.4,27.1h0.9c-0.7-10.9-0.6-19.9,0.4-27c0-0.4,0.1-0.7,0.1-1.1c0.1-0.6,0.2-1.2,0.3-1.7c0.1-0.8,0.2-1.5,0.2-2
      c0-0.2,0-0.4,0-0.6c0-0.4-0.1-0.7-0.2-0.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M25.4,574.1h1.9v6.3
      c0.6,0,1,0.5,1.3,1.5c0.1,0.5,0.2,1.1,0.2,1.8v16.6v0.2c-0.1,0.6-0.4,1.1-0.9,1.2v5.7L28,615h-1.6c0.4,11,0.8,22.1,1.3,33.3"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="42.5" y1="488.1" x2="59.4" y2="488.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M25.8,600
      c0.1,0.1,0.3,0.2,0.4,0.2v-16.6c0-0.1,0-0.2,0-0.3c0-0.4,0-0.8-0.1-1c-0.4,0.3-0.6,0.7-0.7,1.4c0,0.2,0,0.4,0,0.5
      c-0.4,4.1-0.4,8.7-0.3,13.8C25.3,599,25.5,599.6,25.8,600z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M28.6,581.9h-1.4
      c-0.4,0-0.7,0.1-0.9,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M24,579.9
      c-1.8,4.6-2.8,8.6-3,12.1c-0.4,7.4-0.5,16.2-0.5,26.5c-0.1,6.5-0.2,13.1-0.2,19.7c2.6,2.5,5.1,5.9,7.5,10.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M26.5,615h-1.4h-0.8
      c-0.3-2.7-0.5-5.2-0.7-7.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M27.8,607.3h-3.4
      c0.1,1.3,0.2,2.5,0.3,3.9c0,0.2,0.1,0.4,0.1,0.6c0.1,1.1,0.1,2.1,0.3,3.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M26.3,600.2
      c0.1,0.1,0.3,0.1,0.6,0h1.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M14.2,627l2.8,6.8
      c0.1-5.1,0.1-10.2,0.2-15.3c0-10.2,0.3-17.8,1-22.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M44.7,625.6c0,0.1,0,0.1,0,0.1
      c0,0.5,0,1,0.1,1.5c0.4,8.2,0.9,16.3,1.4,24.3l16.7-0.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="47.2" y1="627.1" x2="62" y2="626.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M67.9,340.3
      c0.3,1.4,0.6,2.7,1,4.1c0,0.1,0,0.1,0,0.2c0.3,1.1,0.5,2.3,0.8,3.4c-0.4,0.2-0.8,0.4-1.2,0.5c-0.3,0.2-0.6,0.3-0.9,0.4
      c-3.5,2-5.5,8-6.1,17.8c0,0.5,0,1.3-0.1,2.4c0,1.4,0,2.8-0.1,4.2c-0.7,35.5-0.9,69.2-0.7,101.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M52.4,355.1l0.3,1.6
      c0.1,0.8,0.4,1.4,0.6,2c-0.6-3.2,0-6,1.8-8.4c0.1-0.1,0.2-0.3,0.3-0.4c0.6-0.6,1.4-1.2,2.2-1.6c0.7-0.4,1.5-0.8,2.3-1.1
      c0.8-0.1,1.6-0.3,2.4-0.4c2-0.2,4-0.3,6-0.1c0.6-0.3,0.9-1,0.7-2.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M68.2,346.7
      c-4,1.5-6.4,6.2-7.4,14.2H60c0,0.3,0,0.6-0.1,1c0,1.5,0,2.9-0.1,4.3c0,0.4,0,0.8,0,1.2c-0.6,32.1-0.8,62.9-0.7,92.3
      c0,4.9,0,9.8,0.1,14.7c-6.9-1.8-13.3-3.8-19.3-6c0,0.9,0,1.7,0,2.7c0.1,5.7,0.3,11.4,0.4,17.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M20.2,323.2v17.8l13.4-5.6v-8.2
      c0-0.3,0-0.5-0.1-0.8c-0.1-0.6-0.3-1.1-0.6-1.6c0-0.1-0.1-0.1-0.1-0.2l-2.1-2.3c-1.2-1.1-2.5-1.7-3.7-1.6c-0.5,0-0.9,0.1-1.4,0.2
      c0,0-0.1,0-0.1,0h0l0,0l-2.5,1.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M37.7,330.5
      c0,6.8-0.1,13.7-0.1,20.5c-0.1,6.8-0.1,13.5-0.1,20.2c0.3-14,0.5-27.8,0.9-41.6L37.7,330.5l-4.2,5.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M37.5,371.2l-0.3,59
      c0,0.2,0,0.4,0,0.7c0.1,12.4,0.3,24.7,0.5,36.7c0.7,0.5,1.5,0.8,2.2,0.8c-0.1-6.3-0.2-12.6-0.3-19c-0.1-6.1-0.2-12.3-0.2-18.5
      c0-0.3,0-0.5,0-0.7l0.9-59.3c0.1-14.9,0.3-29.7,0.6-44.4l-2.4,3.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M39.6,449.3l2.1,1.1
      c-0.1-6.5-0.1-13-0.2-19.6c0-0.3,0-0.5,0-0.7l0.9-59.3c0.1-15.2,0.3-30.2,0.6-45.1h-1.6l-0.5,0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M42.9,325.8h0.2
      c-0.2-0.6-0.3-1.2-0.5-1.8l-1.4,1.8"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="22.7,342.4 
      26.7,340.7 33.6,337.8 33.6,336 33.6,335.3 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M41.7,450.4l2.3,1.2
      c-0.1-6.9-0.2-13.8-0.2-20.8c0-0.3,0-0.5,0-0.7l0.9-59.3c0.1-12.6,0.2-25.2,0.4-37.6c-0.6-2.5-1.3-5-1.9-7.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M38,308.3l0.6-0.6
      c-0.2-0.8-0.4-1.5-0.6-2.3C38,306.4,38,307.4,38,308.3z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M25.5,320.9
      C25.5,320.9,25.5,320.9,25.5,320.9c0.6-0.3,1-0.5,1.4-0.7c0.2-15.5,0.4-31,0.6-46.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M26.9,320.2
      c0.6-0.4,1.1-0.8,1.6-1.2l9.5-10.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M42.7,324
      c-1.4-5.4-2.7-10.8-4.1-16.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M59.8,367.3
      c-0.6-2.1-1.2-4.3-1.9-6.5h-1.2c-0.1,0-0.3,0-0.4,0c-1.4-0.2-2.4-0.9-3-2.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M50.6,353.9c0,0,0-0.1-0.1-0.1
      c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.5-0.2-0.9-0.3-1.3c-1.6-6.1-3.3-12.4-4.9-18.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M26.7,340.7
      c0,3.1,0,6.2-0.1,9.2c-0.3,17-0.4,33.8-0.4,50.2c0,3.7,0,19.9,0.2,48.6c0,0.2,0,0.4,0,0.7l0,1.9c0,0.4,0,0.8,0,1.2
      c0.1,3.8,0.1,7.7,0.2,11.5"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="20.2" y1="340.9" x2="6.3" y2="346.7"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="60" y1="360.9" x2="57.9" y2="360.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M95.5,654.3l-5.9-211.1
      c-0.3-6.1-0.6-12-1-17.8h-6.5c0.4,5.7,0.8,11.7,1,17.8l4.1,149"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M22.2,409.1
      c0-0.2,0-0.4,0.1-0.5c0.1-0.4,0.2-0.8,0.2-1.2h2.3v7.8c0.3-0.1,0.5,0.2,0.6,0.8c0,0.1,0,0.2,0,0.3c0,0.3,0,0.7,0,1.1
      c0,0.1,0,0.2,0,0.4v16.9c-0.1,1.1-0.3,1.7-0.9,1.8l0.1,4.2l0.2,7.7h-2.4h-0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M25.4,416h-1.1
      c-0.3,0-0.6,0.1-0.8,0.4c0,0.3,0,0.6,0.1,1c0,0.1,0,0.2,0,0.4v16.9c0.1,0.1,0.3,0.1,0.5,0h1.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M23.6,434.7
      c-0.5-0.2-0.8-0.9-0.9-2.2c-0.1-5.5-0.1-10.3,0.2-14.6c0.1-0.7,0.3-1.2,0.6-1.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M21.2,440.6h0.7
      c-0.6-10.9-0.5-19.9,0.3-27c0-0.4,0.1-0.7,0.1-1.1c0.1-0.6,0.2-1.2,0.2-1.7c0.2-1.9,0.2-3,0-3.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M24.7,440.6h-2.8
      c0.1,1.3,0.1,2.5,0.2,3.9c0,0.2,0,0.4,0.1,0.6c0.1,1.1,0.1,2.1,0.2,3.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M22.6,461.4
      c1.4,1,2.7,1.9,4,2.5"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="44" y1="451.6" x2="59.1" y2="459.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M82.1,425.4
      c-2.1-28.9-5.9-53.1-11.3-72.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M38,305.4
      c-1.7-7-3.4-14-5.1-21.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M325.6,290
      c0.4-1.8,0.8-3.6,1.2-5.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M330.1,276.8
      c0.2,10.5,0.4,21.1,0.5,31.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M341,269.4
      c0.3,17,0.5,34,0.7,50.8"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="292.8" y1="592.2" x2="292.9" y2="592.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.7,339
      c-20.8-5.8-41.7-9.8-62.6-12c-1.7-0.5-2.6-1.4-2.8-2.6l-1.1-5.9c-0.5-2-1.9-3.2-4.2-3.6c-0.5-0.1-1-0.1-1.5-0.1
      c-8.3-0.7-16.6-1.1-24.9-1.2c-0.1,0-0.3,0-0.4,0l-1.7,0c-0.7,0-1.4,0-2.1,0c0,0,0,0-0.1,0c-1.4,0-2.7,0-4.1,0c0,0,0,0-0.1,0
      c-1.4,0-2.8,0-4.2,0.1c-8.3,0.1-16.6,0.5-24.9,1.2c-0.5,0-1,0.1-1.5,0.1c-2.3,0.4-3.7,1.6-4.2,3.6l-1.2,5.9c-0.1,1.2-1,2.1-2.7,2.6
      c-21,2.2-41.8,6.2-62.6,12c-1.1,0.3-2.1,0.6-3.1,0.9c-5.1,1.3-8.6-1.3-10.5-8c-3.9-17.1-7.5-33.7-10.7-49.8
      c-0.8-6.3,0.9-10.3,5.2-12c38.1-13.1,76.9-19.9,116.3-20.4l3.8,0c0.1,0,0.3,0,0.4,0c0,0,0,0,0.1,0h3.1c0.3,0,0.7,0,1,0
      c0,0,0,0,0.1,0l3.6,0c0.2,0,0.4,0,0.6,0c39.4,0.5,78.2,7.2,116.3,20.4c4.2,1.7,6,5.7,5.2,12c-3.3,16.2-6.9,32.8-10.7,49.8
      c-1.8,6.7-5.3,9.4-10.5,8C289.9,339.6,288.8,339.3,287.7,339z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M325,600c0,0.1,0,0.2,0,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M296.3,641.1
      c0.2-11.5,0.4-23.3,0.6-35.4c0-0.2,0-0.4,0-0.6c-0.1-2.8-1.3-4.2-3.8-4h-6c-1.8-0.1-2.8,0.5-2.9,2l-0.7,38.5c0,0.6,0.2,0.8,0.8,0.8
      h11.1C296,642.4,296.3,642,296.3,641.1L296.3,641.1z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M292.2,722.6
      c-0.2,1.8-1.1,2.7-2.6,2.8h-8.3c-0.7-0.1-1.1-0.5-1.1-1.2c0-0.2,0-0.3,0-0.5c0.6-9.1,1.1-18.5,1.6-28.3c0.2-4.7,0.5-9.5,0.7-14.3
      l0.3-8c0.1-0.5,0.4-0.7,0.9-0.8c0.1,0,0.2-0.1,0.3-0.1h10.3c0.6,0.1,0.9,0.5,0.9,1.1c0,0.1,0,0.1,0,0.2c-0.4,7.2-0.8,14.2-1.2,21.1
      c-0.3,4.8-0.6,9.5-0.9,14.1c0,0.1,0,0.2,0,0.3l-0.8,9.7c-0.1,1.2-0.2,2.4-0.3,3.6C292.2,722.3,292.2,722.5,292.2,722.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M319.1,696.7L319.1,696.7
      c0,0.1,0,0.3-0.1,0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M319,697.1c0,0.2,0,0.5-0.1,0.7
      "/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="286.3" y1="728.8" x2="286.1" y2="728.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M264,797.4l3.4,1.7
      c2,0.9,1.2,1.6-2.6,1.8c-0.4,0-0.8,0-1.2,0c-24.4,0.9-48.7,1.4-73.1,1.5h-4.2c0,0,0,0-0.1,0h-4.1c0,0,0,0-0.1,0H178
      c-24.4-0.1-48.8-0.6-73.1-1.5c-0.4,0-0.8,0-1.2,0c-3.8-0.3-4.6-0.9-2.6-1.8l3.4-1.7c0.3-0.2,0.7-0.3,1.1-0.4
      c2.7-1,6.7-1.6,11.9-1.5l0.8,0c19.9,0.6,39.8,1,59.7,1c1.4,0,2.8,0,4.2,0c0,0,0,0,0.1,0h4.1c0,0,0,0,0.1,0h4.2
      c19.9-0.1,39.8-0.4,59.7-1l0.8,0c5.2,0,9.2,0.5,11.9,1.5C263.3,797.2,263.7,797.3,264,797.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M320,66.8
      c0.6,0.7,1,0.6,1.1-0.3c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4c0.2-2.6,0.2-5.2-0.1-7.7c-0.1-0.7-0.3-1.3-0.5-1.8"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="320" y1="55.2" x2="320" y2="55.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M318.5,53.7
      c1.1,1.8,1.6,3.8,1.7,6.2c0,0.3,0,0.6,0.1,0.9c0,0.5,0,1-0.1,1.6c0,0.5,0,0.9,0,1.3c0,0.6-0.1,1.3-0.1,1.9
      c-0.8-1.1-1.6-2.2-2.5-3.3c-0.7-0.8-1.4-1.6-2-2.4v0c-0.9-1-1.9-2-2.9-3c-10.6-10.7-25.3-19.6-44.3-26.8c-0.1-0.1-0.3-0.1-0.5-0.2
      c-0.5-0.2-0.7-0.6-0.7-1.1v-5.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M317.5,62.3l1.3,0.7
      c0.8,0.5,1.2,1.4,1.2,2.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="315.5" y1="59.9" x2="320.2" y2="62.3"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="312.6" y1="56.9" x2="320.2" y2="60.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.6,267.1c0-0.1,0-0.2,0-0.4
      c0,0,0-0.1,0-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.6,269.6c0-0.1,0-0.1,0-0.2
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.5,263.5c0-0.1,0-0.3,0-0.4
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.3,252.4c0-0.1,0-0.1,0-0.2
      c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.3,250.6c0-0.1,0-0.2,0-0.3
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.2,248.4
      C340.2,248.3,340.2,248.3,340.2,248.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.2,245.4c0-0.1,0-0.2,0-0.4
      c0-0.2,0-0.4,0-0.5"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.4" y1="257.7" x2="340.4" y2="259.5"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.4" y1="261.3" x2="340.5" y2="263.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.4" y1="260.8" x2="340.5" y2="262.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="340.4" y1="259" x2="345.4" y2="257.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.4" y1="243.1" x2="340.2" y2="245"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.2,248.4c0,0,0,0.1,0,0.2
      l5.2-1.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M340.3,248.6c0,0.3,0,0.5,0,0.9
      "/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="340.3" y1="251.7" x2="345.4" y2="249.9"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.4" y1="246.2" x2="340.2" y2="248.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="340.3" y1="252.2" x2="345.4" y2="250.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.4" y1="253.5" x2="340.3" y2="255.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.4" y1="254" x2="340.3" y2="255.9"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="340.6" y1="266.7" x2="345.5" y2="265"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.5" y1="264.4" x2="340.6" y2="266.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="48.6" y1="55.2" x2="48.6" y2="55.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M50.1,53.7
      c-1.1,1.8-1.6,3.8-1.7,6.2c0,0.3,0,0.6-0.1,0.9l7.6-3.9c10.6-10.7,25.3-19.6,44.3-26.8c0.1-0.1,0.3-0.1,0.5-0.2
      c0.5-0.2,0.7-0.6,0.7-1.1v-5.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M48.5,65.6
      c0-1.2,0.4-2.1,1.2-2.6l1.3-0.7c0.7-0.8,1.3-1.6,2-2.4v0l-4.7,2.4c0,0.5,0,0.9,0,1.3C48.5,64.3,48.5,64.9,48.5,65.6
      c0.8-1.1,1.6-2.2,2.5-3.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M48.4,60.8c0,0.5,0,1,0.1,1.6"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M53.1,59.9c0.9-1,1.9-2,2.9-3"
      />
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="75.8" y1="592.2" x2="75.7" y2="592.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M88.4,723.6
      c-0.6-9.1-1.1-18.5-1.6-28.3c-0.2-4.7-0.5-9.5-0.7-14.3l-0.3-8c-0.1-0.5-0.4-0.7-0.9-0.8c-0.1,0-0.2-0.1-0.3-0.1H74.2
      c-0.6,0.1-0.9,0.5-0.9,1.1c0,0.1,0,0.1,0,0.2c0.4,7.2,0.8,14.2,1.1,21.1c0.3,4.8,0.6,9.5,0.9,14.1c0,0.1,0,0.2,0,0.3l0.8,9.7
      c0.1,1.2,0.2,2.4,0.3,3.6c0,0.1,0,0.2,0,0.3c0.2,1.8,1.1,2.7,2.6,2.8h8.3c0.7-0.1,1.1-0.5,1.1-1.2C88.4,724,88.4,723.8,88.4,723.6z
      "/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="82.3" y1="728.8" x2="82.5" y2="728.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M49.5,696.9c0-0.1,0-0.2,0-0.2
      h0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M54.8,749.3c0-0.2,0-0.3,0-0.4"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M52.9,732.3c0-0.1,0-0.1,0-0.2
      c0,0,0-0.1,0-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M43.6,600c0,0.1,0,0.2,0,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M72.3,641.1L72.3,641.1
      c-0.3-11.6-0.5-23.4-0.6-35.4c0-0.2,0-0.4,0-0.6c0.1-2.8,1.3-4.2,3.9-4h6c1.8-0.1,2.8,0.5,2.9,2l0.7,38.5c0,0.6-0.2,0.8-0.8,0.8
      H73.3C72.6,642.4,72.3,642,72.3,641.1z"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M108.4,364.7h51.2c0,0,7.5,1.4,10.5,14.6l0.5,32.7
        c0,0-3.9,18.7-14.3,24.5h-46c0,0-9.4-7.2-13.5-24.3v-33.4C96.6,378.7,100.5,366.3,108.4,364.7z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M171.6,430.9l-1.1-2H164l-4.3,4.8l-5.1,2.7l-21.3-0.2v-0.1
        l-21.8,0.7l-4.6-3.6l-3.4-4.7l-7.3,0.4l-2,3.6L93,442v29l0.9,7.5l2.7,5.1l3.4,3.3l5.2,2.6h7.2h3.1l0.2,2.1h17.6v0.1h17.6l0.2-2.1
        h3.1h7.2l5.2-2.6l3.4-3.3l2.7-5.1l0.9-7.5v-29l-0.9-7.6C172.5,433.2,172.2,432,171.6,430.9z"/>
      <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="133.3,469.9 111.4,469.9 111.4,489.1 115.5,493.6 
        133.3,493.6 151.1,493.6 155.2,489.1 155.2,469.9 		"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M210.8,364.7H262c0,0,7.5,1.4,10.5,14.6l0.5,32.7
        c0,0-3.9,18.7-14.3,24.5h-46c0,0-9.4-7.2-13.5-24.3v-33.4C199,378.7,202.9,366.3,210.8,364.7z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M274,430.9l-1.1-2h-6.4l-4.3,4.8l-5.1,2.7l-21.3-0.2v-0.1l-21.8,0.7
        l-4.6-3.6l-3.4-4.7l-7.3,0.4l-2,3.6l-1.1,9.5v29l0.9,7.5l2.7,5.1l3.4,3.3l5.2,2.6h7.2h3.1l0.2,2.1h17.6v0.1h17.6l0.2-2.1h3.1h7.2
        l5.2-2.6l3.4-3.3l2.7-5.1l0.9-7.5v-29l-0.9-7.6C275,433.2,274.6,432,274,430.9z"/>
      <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="235.7,469.9 213.8,469.9 213.8,489.1 217.9,493.6 
        235.7,493.6 253.5,493.6 257.6,489.1 257.6,469.9 		"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M262,601.9H108c-1.8,0-3.3-1.5-3.3-3.3l-1.7-44.6
        c0-1.8,1.5-3.3,3.3-3.3h157.4c1.8,0,3.3,1.5,3.3,3.3l-1.7,44.6C265.3,600.4,263.8,601.9,262,601.9z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M263.6,603.6l-3.4-1.7h-10.3h-12h-12h-41h-1.7h-42.7h-10.3h-8.5
        h-13.7l0,0c-2.4,2.8-4.2,6.1-5.3,9.6l0,0c-0.4,1.4-0.6,2.9-0.6,4.3v14.5c0,4.1,0.6,8.2,1.8,12.1l0,0l1,0.8c3,2.2,6.2,4,9.7,5.3
        l1.7,0.6l10.5,2h14.7h6.4l0.5,1.7h35.7v0.1h35.7l0.5-1.7h6.4h14.7l10.5-2l6.9-2.6l5.5-4.1l1.8-5.9v-22.9l-0.9-6.7
        C265,606,264.8,604.4,263.6,603.6z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M184.1,635.7h-44.6v15.2l3.6,1.5c3.2,1.3,6.6,2,10,2h31h30.4
        c3.9,0,7.7-0.8,11.3-2.3l2.9-1.3v-15.2H184.1z"/>
    </g>
  </g>
  <g id="Layer_2">
  </g>
  <g id="Layer_3">
  </g>
  <g id="Layer_4">
  </g></>)
  }, 
  exterior: {
    width:748,
    height:1050,
    svg: (<><g id="Layer_1">
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M490.4,453.3l1.7,3.6
      c0.3,0.6,0.4,1.2,0.4,2v5.2v1.6l-10.9-4.5c0,1.7,0,3.3,0,5c0.2,11.3,0.3,22.4,0.3,33.3c0,2,0,4,0,5.9c1,5.1,1.2,12,0.7,20.9
      c-0.1,1.6-0.2,3.3-0.4,5.1h-0.4c0,0.7,0,1.3,0,1.9c-0.1,2.5-0.1,4.8-0.2,6.8c-0.2,7.9-0.2,11.8-0.3,11.9c-0.1,4.1-0.2,8.3-0.3,12.4
      c-0.3,16.7-0.7,33-1.3,49c0,0,0,0.1,0,0.1c0,0.5,0,1,0,1.5h0.2c0.1,0.3,0.1,0.5,0.2,0.8c0,0.1,0,0.2,0.1,0.4
      c0.1,0.6,0.3,1.2,0.4,1.8l0.1,0.3c0-0.1-0.1-0.3-0.1-0.4c1.7,4.1,3,7.9,3.9,11.4c0.2,0.6,0.3,1.1,0.5,1.6c0.4,1.7,0.8,3.4,1.1,5.1
      c0.5,2.9,0.9,5.7,1,8.5c0,1.9,0.1,3.7,0.1,5.6c0.3,17.8,0.3,36-0.1,54.7c0,2-0.1,4-0.1,6.1l-0.1,1.6c-0.2,4.7-0.6,8.8-1.2,12.5
      c-0.5,3.1-1.2,5.7-2,8c-4.6,10.6-9,18.9-13.1,25c-0.4,0.6-0.8,1.1-1.2,1.7c-0.7,0.2-1.4,0.5-2.1,0.7c-2.8,0.9-5.7,1.7-8.5,2.4
      c-0.4,0.1-0.8,0.2-1.1,0.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.8,0.2-1.5,0.4-2.3,0.5c-4.4,0.9-8.9,1.7-13.6,2.2c-0.3,0-0.6,0.1-0.9,0.1
      l-3.3,5.5c-1.2,1.9-3.4,3.1-6.5,3.6c-1.4,0.1-2.7,0.1-4.1,0.2c-0.4,0-0.7,0-1.1,0.1l-5.7,0.2c-13.5,0.4-27,0.7-40.5,0.7h-4
      c-0.1,0-0.2,0-0.3,0h-4c-13.5-0.1-27-0.3-40.5-0.7l-5.7-0.2c-0.4,0-0.7,0-1.1-0.1c-1.4-0.1-2.8-0.1-4.1-0.2
      c-3.1-0.5-5.3-1.7-6.5-3.6l-3.3-5.5c-0.4,0-0.7-0.1-1.1-0.1c-4.5-0.5-8.8-1.3-13-2.2c-1.3-0.3-2.6-0.6-3.8-0.9
      c-0.4-0.1-0.8-0.2-1.1-0.3c-2.9-0.7-5.8-1.5-8.5-2.4c-0.7-0.2-1.4-0.5-2.1-0.7c-0.4-0.6-0.8-1.1-1.2-1.7c-4.1-6.1-8.4-14.4-13.1-25
      c-0.8-2.3-1.5-4.9-2-8c-0.6-3.6-1-7.8-1.3-12.5l-0.1-1.6c0-2-0.1-4.1-0.1-6.1c-0.3-18.7-0.4-36.9-0.1-54.7c0-1.9,0.1-3.7,0.1-5.6
      c0.2-2.8,0.5-5.6,1-8.5c0.3-1.7,0.7-3.4,1.1-5.1c0.1-0.5,0.3-1,0.5-1.6c0.9-3.5,2.2-7,3.8-10.5c0.2-1,0.3-1.9,0.5-2.7
      c0-0.1,0-0.2,0.1-0.4c0.1-0.3,0.1-0.5,0.2-0.8h0.2c0-0.5,0-1,0-1.5c0,0,0-0.1,0-0.1c-0.6-16-1-32.3-1.3-49
      c-0.1-4.1-0.2-8.2-0.3-12.4c0-0.1-0.1-4-0.2-11.9c-0.1-2-0.1-4.3-0.2-6.8c0-0.6,0-1.2,0-1.9h-0.3c-0.2-1.8-0.3-3.5-0.4-5.1
      c-0.5-8.9-0.3-15.9,0.7-20.9c0-2,0-4,0-5.9c0-10.9,0.1-22,0.3-33.3c0-1.7,0-3.3,0-5l-10.9,4.5V464v-5.2c0-0.7,0.1-1.4,0.4-2
      l1.7-3.6c0.7-1.5,1.6-2.5,2.7-3.1l4.5-1.9l1.8-0.7c0.1-11.1,0.1-22.5,0.1-34.2c-0.3-0.2-0.6-0.5-0.9-0.8c-1-0.8-2.2-1.5-3.7-2.1
      c-0.4-0.2-0.5-0.4-0.6-0.8v-14c-0.1-0.7,0.3-1.1,1-1.3l1.1-0.3l2.2-0.6c-0.4-1-0.9-2-1.2-3c-0.2-0.6-0.5-1.3-0.7-1.9
      c-2.1-6.3-3.2-13.6-3.5-21.9l0-2c0-0.4,0-0.8,0-1.2c-0.1-19.9-0.1-39.6,0-58.9l0-1.8c0-0.4,0-0.9,0-1.3c0.3-8,1.5-14.6,3.7-19.8
      c0,0,0-0.1,0-0.1c0.1-0.3,0.3-0.6,0.4-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.3-0.5,0.4-0.7
      c0.2-0.2,0.3-0.5,0.5-0.7c0.7-0.9,1.5-1.7,2.4-2.4l0.1-0.1c2.2-2.8,5.3-5.4,9.3-7.8c3.2-1.9,6.4-3.6,9.7-5.1
      c2.7-1.3,5.5-2.4,8.3-3.4c7.7-4.7,15.8-8.6,24.4-11.7V247v-2.1l5.3-2c1.6-0.5,3.7-0.9,6.3-1.2c0.1,0,0.2,0,0.3,0
      c1-0.1,1.9-0.1,2.9-0.2c11.7-0.9,23.3-1.3,34.7-1.4c0.9,0,1.8,0,2.8,0h0c0.1,0,0.3,0,0.4,0c0.6,0,1.3,0,1.9,0c0.1,0,0.3,0,0.4,0
      c0,0,0,0,0,0c0.9,0,1.9,0,2.8,0c11.4,0.1,23,0.6,34.7,1.4c1,0.1,1.9,0.1,2.9,0.2c0.1,0,0.2,0,0.3,0c2.6,0.3,4.7,0.7,6.3,1.2l5.3,2
      v2.1v0.9c8.6,3.1,16.7,7,24.4,11.7c2.8,1,5.5,2.2,8.3,3.4c3.3,1.5,6.5,3.2,9.7,5.1c4,2.4,7.1,5,9.3,7.8l0.1,0.1
      c1,0.7,1.8,1.5,2.4,2.4c0.2,0.2,0.4,0.5,0.5,0.7c0.1,0.2,0.2,0.5,0.4,0.7c0,0.1,0.1,0.2,0.1,0.4c0.1,0.2,0.2,0.4,0.3,0.6
      c0.1,0.2,0.2,0.5,0.4,0.8c0,0,0,0.1,0,0.1c2.2,5.2,3.4,11.8,3.7,19.8c0,0.4,0,0.9,0,1.3l0,1.8c0.1,19.4,0.1,39,0,58.9
      c0,0.4,0,0.8,0,1.2l0,2c-0.2,8.3-1.4,15.5-3.5,21.9c-0.2,0.7-0.5,1.3-0.7,1.9c-0.4,1.1-0.9,2.3-1.4,3.3c-0.2,0.4-0.4,0.8-0.6,1.2
      c0,0.1-0.1,0.2-0.1,0.3c0,0.6,0,1.2,0,1.8c0,0.1,0,0.2,0,0.4c0,0.7,0,1.4,0,2.1c0,0.1,0,0.2,0,0.4v1.1c0,0.3,0,0.6,0,0.9
      c0,0.1,0,0.2,0,0.3c0,0.7,0,1.4,0,2.1c0,0.1,0,0.2,0,0.3c0,0.7,0,1.4,0,2.1c0,0.1,0,0.2,0,0.3c0,0.7,0,1.4,0,2.1c0,0.1,0,0.2,0,0.4
      c0,0.5,0,1.1,0,1.7c0.1,12.4,0.1,24.5,0.2,36.2l1.8,0.7l4.5,1.9C488.8,450.8,489.7,451.9,490.4,453.3z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.6,461.1l-2.6-1.1
      c0,2.1,0,4.1,0,6.1c0.2,11.3,0.3,22.4,0.3,33.3c0,2.6,0,13.3-0.1,32.2c0,3.4-0.1,6.8-0.2,10.1c0.8-0.4,1.7-1,2.7-1.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478.9,460l-4.6-1.9v-1.2
      l-2.8-3.7c0,4.5,0,9.1,0.1,13.6c0.1,4.5,0.1,9,0,13.4l0.2,39.1c0,0.1,0,0.3,0,0.4c-0.1,8.3-0.2,16.4-0.3,24.4
      c2.5-0.5,4.9-1.3,7.4-2.4c-0.1,7.6-0.2,15-0.4,22.1c-0.3,17.2-0.8,33.8-1.4,49.5c0,0.3,0,0.6,0,0.9c-0.4,18-0.9,35.3-1.8,52
      c0.9-0.2,1.8-0.8,2.8-2c0.3-7.5,0.6-14.8,0.9-22.1H478l0.2-5.1v-3.8c-0.4-0.1-0.6-0.4-0.6-0.8v-0.1v-11c0-0.5,0.1-0.9,0.1-1.2
      c0.1-0.7,0.4-1,0.8-1V615h1.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M456.8,472.4
      c0,0.3,0,0.9,0.1,1.7c0,1,0,1.9,0.1,2.9c0,0.3,0,0.5,0,0.8c2.4-8.4,4.9-17.6,7.3-27.6l-0.3-6.5c-2.4,10-4.9,19.1-7.3,27.5
      C456.7,471.6,456.7,472,456.8,472.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.3,463.8
      c-1.3-0.4-2.8-0.8-4.3-1.2c-21.4-5.9-42.9-9.1-64.5-9.4c-0.1,0-0.2,0-0.3,0l-0.5,0c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0-1.7,0
      c0,0,0,0,0,0h-0.6c-0.5,0-1.1,0-1.6,0h-0.6c-0.9,0-1.8,0-2.8,0c-21.6,0.3-43.1,3.5-64.5,9.4c-1.6,0.4-3,0.8-4.3,1.2
      c-1.1,0.3-2.1,0.7-3.1,1.1c0.2,1,0.5,2.1,0.7,3.1c0.8-0.3,1.5-0.6,2.3-0.8c1.3-0.4,2.8-0.8,4.3-1.2c21.4-5.9,42.9-9.1,64.5-9.4
      c0.9,0,1.8,0,2.8,0h2.8c0,0,0,0,0,0c0.5,0,1,0,1.6,0h0.6c0.2,0,0.4,0,0.6,0c21.6,0.3,43.1,3.5,64.5,9.4c1.6,0.4,3,0.8,4.3,1.2
      c0.8,0.2,1.6,0.5,2.3,0.8c0.3-1,0.5-2.1,0.7-3.1C449.4,464.5,448.4,464.2,447.3,463.8z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M456.7,471.2
      c-0.5-6-1.8-9.6-4-10.9c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.5-0.2c-0.4,1.7-0.8,3.4-1.2,5.1c0.3,0.1,0.5,0.2,0.8,0.4
      c0.2,0.1,0.4,0.2,0.6,0.3c2.3,1.3,3.6,5.3,4.1,11.8c0,0.3,0,0.9,0.1,1.6c0,0.9,0,1.8,0.1,2.8c0.5,23.5,0.6,45.9,0.4,67.2l0.9-0.2
      c0-3.2,0.1-6.5,0.1-9.8c0.1-19.5-0.1-39.9-0.5-61.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M446.4,627h0.2
      c2.3,0.1,3.4,1.9,3.5,5.4c0,0.3,0,0.6,0,0.9c-0.1,21.6-0.6,41.8-1.5,60.8c-0.1,3-0.3,5.9-0.4,8.8c0,0.1,0,0.1,0,0.2l-0.7,8.4
      c-0.1,1-0.2,2-0.3,3c-0.4,3.7-1.7,5.5-3.9,5.5h-7.7c-0.8,11.6-1.8,22.1-2.8,31.7l2.4-0.4l10.7-0.3c0.7-5.4,1.4-10.8,2-16.3
      c0.7-6,1.3-12.1,1.9-18.3c0.1-0.9,0.2-1.7,0.2-2.6l0.5-6.4c0-0.1,0-0.1,0-0.2c0-0.5,0.1-0.9,0.1-1.4c0.2-2.6,0.3-5.2,0.5-7.9
      c1.6-25.8,2.5-54,3-84.5l1-49c0.1-5,0.1-10,0.2-15.2c0.2-22.2,0-45.6-0.5-70.3c-0.3-4.1-1.1-7.2-2.5-9.1c-0.5-0.7-1.4-1.3-2.5-1.8
      l0,0c-0.1,0-0.1,0-0.2-0.1c-3.6,13-6.1,29-7.5,48.2c-0.3,3.8-0.5,7.7-0.7,11.8l-2.8,98.9H446.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.5,447.7l-1.7-0.7l0,0h0
      c0,0,0,0-0.1,0c-0.3-0.1-0.6-0.1-0.9-0.1c-0.8,0-1.7,0.4-2.5,1.1l-1.3,1.5c0,0-0.1,0.1-0.1,0.1c-0.2,0.3-0.4,0.6-0.4,1
      c0,0.1,0,0.3,0,0.5v5.4l8.9,3.7v-11.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M479.7,446.9
      C479.7,446.9,479.7,446.9,479.7,446.9c-0.4-0.2-0.7-0.3-0.9-0.5c-0.4-0.2-0.7-0.5-1-0.8l-6.3-7l-0.6-0.7l-1.5-1.7l-1.4-1.6
      c0.1,4.7,0.2,9.4,0.3,14.1l1.1,1.5l0.3,0.4l1.6,2.1l0.5,0.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="474.4" y1="456.9" x2="474.4" y2="456.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M466.6,450.1
      c0.2,9.9,0.3,19.9,0.4,29.9l0.6,39.3c0,0.1,0,0.3,0,0.4c0,4.6-0.1,9.2-0.2,13.8l1.5-0.8c0.1-4.3,0.1-8.6,0.1-13c0-0.2,0-0.3,0-0.4
      l-0.6-39.3c-0.1-10.1-0.2-20-0.4-29.9H466.6h-2.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="469.2" y1="450.1" x2="468.1" y2="450.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M464,443.7
      c0.8-3.2,1.6-6.4,2.3-9.7c0.5-1.9,0.9-3.9,1.3-5.9c0.1-0.5,0.3-1.1,0.4-1.7c-0.2-1.1-0.3-2.1-0.5-3.1c0-0.2-0.1-0.4-0.1-0.5
      c-1.2-5.1-3.2-8.2-6-9.2c-0.1,0.5-0.2,1.1-0.3,1.6c0,0.2-0.1,0.3-0.1,0.5c-2.8,14.2-5.9,28.9-9.5,44.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M471.7,480.3
      c-0.2-9.3-0.4-18.5-0.6-27.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M469,532.9l1.4-0.7
      c0.1-4.1,0.1-8.1,0.1-12.3c0-0.2,0-0.3,0-0.4l-0.6-39.3c-0.1-9.9-0.2-19.7-0.4-29.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M467.7,632.4l-0.2,4.5
      c-0.1,2.7-0.2,5.3-0.4,7.9l-0.2,3.8c0,0.2,0,0.4,0,0.5c0,0,0,0.1,0,0.1c0,0.3,0,0.7-0.1,1c-0.3,5.4-0.6,10.8-0.9,16.1l1.3,0
      c0.4-5.6,0.7-11.4,1-17.2c0.6-11.3,1.1-22.9,1.4-34.8c0-0.3,0-0.7,0-1c0-0.5,0-1.1,0.1-1.6l1.4-47.4c0.2-6.6,0.4-13.3,0.5-20.2
      c-0.5,0.4-1,0.5-1.5,0.5c0,0.6,0,1.2,0,1.8c-0.1,3.8-0.2,7.6-0.3,11.3c-0.1,2.2-0.1,4.4-0.2,6.5l-1.3,49c0,0.4,0,0.7,0,1
      c-0.2,6-0.4,11.8-0.6,17.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.8,531.5L481.8,531.5h-1.7
      l0.1-5.1l0.1-2.8c-0.3-0.1-0.5-0.5-0.6-1.2v-11.2c0-0.1,0-0.2,0-0.2c0-0.3,0-0.5,0-0.7c0-0.1,0-0.1,0-0.2c0.1-0.4,0.2-0.6,0.4-0.5
      v-5.2h1.6c0.1,0.2,0.1,0.5,0.1,0.8c0,0.1,0,0.2,0.1,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.7,531.5
      c0.1-0.7,0.1-1.4,0.1-2.2c0-0.1,0-0.3,0-0.4c0.1-0.9,0.1-1.7,0.2-2.6h-1.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.7,504.3
      c-0.2,0.3-0.2,1.1,0,2.3c0.1,0.4,0.1,0.7,0.1,1.1c0,0.2,0.1,0.5,0.1,0.7c0.5,4.7,0.6,10.6,0.2,17.9h0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M479.8,510h0.7
      c0.2,0,0.4,0.1,0.5,0.3c0.2,0.2,0.3,0.5,0.4,1c0.2,2.8,0.3,6.1,0.2,9.7c-0.1,0.9-0.3,1.4-0.6,1.5c-0.1,0-0.2,0-0.3,0h-1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M454.1,666.1l0.8,0
      c0.2-5.4,0.4-10.8,0.6-16.3c0.4-11.3,0.6-23,0.8-35c0-0.4,0-0.9,0-1.3l0.9-49c0-2.1,0.1-4.2,0.1-6.3c0-2.1,0.1-4.2,0.1-6.4
      c0-1,0-2,0-3.1c4.6-1.2,8.9-2.5,12.8-4c0.1-4.2,0.2-8.4,0.2-12.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="457.4" y1="539" x2="467.4" y2="533.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481,510.3c0,0.2,0,0.4,0,0.7
      c0,0.1,0,0.2,0,0.2v11.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M442.1,516.3h-4.3
      c-0.3,3.8-0.5,7.7-0.7,11.8l-3.9,140.1l-0.5,17.4c0,0.7-0.1,1.5-0.1,2.2c-0.4,10.3-0.8,20-1.3,29.1h0.1c-0.9,12.8-1.9,24.4-3,34.8
      l4.3,0"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="492.5" y1="464" x2="483.2" y2="460.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M480.7,618.2
      c1.2,3.5,1.9,6.4,2.1,8.6c0.2,4.9,0.3,10.8,0.3,17.6c0.1,4.3,0.1,8.7,0.2,13.1l2.1-3c0-3.4-0.1-6.8-0.1-10.1
      c0-6.8-0.2-11.8-0.7-15.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M487.2,650.1l-1.9,4.5
      c0.2,16.3,0.2,33-0.1,50.2c0,2-0.1,4-0.1,6.1c-0.1,8.6-0.6,16-1.4,22"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M479.9,615
      c-0.1,0.1-0.1,0.3-0.2,0.6c0,0.1,0,0.3,0,0.4c0,0.4,0.1,0.8,0.2,1.3c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.5,0.1,0.7
      c0.7,4.7,0.7,10.7,0.3,17.9h0.6c0.5-7.3,0.4-13.3-0.3-18c0-0.4-0.1-0.7-0.2-1.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M469.8,557.8l-1.4,0l-2.3,74.3
      c-0.2,5.7-0.5,11.7-0.8,18l1.6,0.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="468.4" y1="557.9" x2="457.2" y2="558.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M445.9,751h0.1l0.9,0.2
      c0.7-5.4,1.4-10.9,2.1-16.5c0.7-6,1.3-12.1,1.9-18.3c0.1-0.9,0.2-1.7,0.3-2.6l0.5-6.4c0-0.1,0-0.1,0-0.2c0-0.5,0.1-0.9,0.1-1.4
      c0.2-2.6,0.4-5.2,0.5-7.9c1.6-25.8,2.5-54,2.9-84.5l1.1-49c0.1-5.1,0.1-10.2,0.2-15.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M467.3,666.4
      c-0.6,10.3-1.4,20.3-2.2,30.1c-0.7,8-1.5,15.9-2.3,23.5c-0.4,3.9-0.9,7.7-1.3,11.5c-0.9,7.8-1.8,15.4-2.7,22.9l1.2,0.2l8.4-2.1
      c0.9-7.3,1.8-14.8,2.6-22.4v0c1.9-20.1,3.4-41.4,4.5-63.7L467.3,666.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478.2,664.3
      c-0.4,9-0.9,18.1-1.4,27.4c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0,0.4,0,0.7,0.1c2,0.5,3.2,2.7,3.4,6.6c-0.3,6.7-2.1,13.1-5.6,19.3l-1,1.6
      c-0.7,9.7-1.4,19.5-2.2,29.5c0.8-2.3,1.8-4.3,2.9-6.2c1.3-1.8,2.7-3.6,4.1-5.3c0.1-4.4,0.4-9.3,1-14.8l0.2-1.5
      c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.2,0-0.2c0.6-5.5,1.1-11.4,1.4-17.7c0.5-15.4,0.8-30.3,0.7-44.7
      C481.5,659.2,479.8,661.5,478.2,664.3z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M454.9,666.1
      c-0.4,10.4-0.9,20.5-1.5,30.2l10.2,0.8v0c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.4,0.1-0.4c0.8-9.7,1.6-19.7,2.2-30L454.9,666.1z"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="465.3" y1="650.2" x2="455.5" y2="649.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481,637
      c-0.1,1.6-0.3,3.3-0.4,5.1H480h-0.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M480,642.1
      c0.1-0.7,0.1-1.4,0.2-2.2c0-0.1,0-0.3,0.1-0.4c0.1-0.9,0.2-1.7,0.2-2.6h-2.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.7,620.1h0.9
      c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.4,0.5,0.5,0.9c0,0.1,0,0.2,0,0.4c0.2,2.7,0.3,5.8,0.2,9.2c-0.1,0.6-0.2,1.1-0.4,1.3
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0-0.4,0h-1.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M479.3,620.4
      c0,0.2,0,0.4-0.1,0.7c0,0.1,0,0.1,0,0.2v11"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M416,673.2v70.8
      c0,1.2-0.4,2-1.3,2.3c-0.3,0.1-0.7,0.2-1.2,0.2H406c-1.7,0-2.5-0.8-2.5-2.5v-70.8c0-1.7,0.8-2.5,2.5-2.5h7.5c0.5,0,0.8,0.1,1.2,0.2
      C415.6,671.2,416,672,416,673.2z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M414.9,675.4V742
      c0,1.4-0.7,2.1-2.1,2.1h-6.2c-1.4,0-2.1-0.7-2.1-2.1v-66.6c0-1.4,0.7-2.1,2.1-2.1h6.2C414.2,673.3,414.9,674,414.9,675.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M479.8,737.2
      c1.3-1.5,2.6-3,3.9-4.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M475.1,719.2l-0.5,0.8
      c-1.3,2.4-1.9,1.6-1.7-2.6l1.9-18.3c0.4-4.8,1.1-7.3,2.1-7.4C476.3,700.7,475.7,709.9,475.1,719.2z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M453.4,696.3
      c0,0.5-0.1,1.1-0.1,1.6c-0.1,1-0.1,2-0.2,3c-0.1,1.7-0.2,3.3-0.3,4.9c0,0.5-0.1,0.9-0.1,1.4c0,0.1,0,0.1,0,0.2l-0.5,6.4
      c-0.1,0.9-0.2,1.7-0.3,2.6c-0.4,4-0.8,8-1.2,11.9c0,0.2-0.1,0.5-0.1,0.7l0,0.2c-0.2,1.8-0.4,3.6-0.6,5.4
      c-0.7,5.7-1.4,11.3-2.1,16.9c2.8,1,5.5,1.9,8.1,2.7c0.4,0.1,0.9,0.2,1.3,0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
      c2.7-19.4,4.7-38.5,6.2-57.3"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="463.7" y1="697.1" x2="463.7" y2="697.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M463.7,697.1l0.1-0.5
      c0,0,0,0,0,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M438.7,627l-1.6,58.6
      c-0.1,3-0.2,6-0.3,8.9c-0.3,8.1-0.6,15.8-1,23.1c0,0.1,0,0.1,0,0.2c0,0.7-0.1,1.4-0.1,2.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M468.3,752.4l3.7-0.9
      c0.3-1,0.6-1.9,0.9-2.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M470.7,757.8
      c0.3-2.2,0.7-4.3,1.3-6.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.5,754.4c0.5,0,0.9,0,1.3,0
      c-0.3,2.4-0.6,4.8-0.9,7.2c0,0.2-0.1,0.4-0.1,0.6h0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M446.9,751.1h0.1
      c0.3,0.1,0.6,0.2,0.9,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M445.9,751.1c0-0.1,0-0.1,0-0.2
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M446,751
      c-0.4,2.6-0.8,5.2-1.2,7.8c0.3,0,0.7,0.1,1.1,0.1c0.1-0.4,0.1-0.8,0.2-1.2c0.3-2.2,0.6-4.3,0.9-6.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M456.6,762.4
      c0.4-0.1,0.8-0.2,1.1-0.3c-0.4-0.1-0.7-0.2-1.1-0.2L456.6,762.4c-1.4,0.3-2.9,0.6-4.3,0.9l1.9,0.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M467.4,760.2
      c0.3-2.6,0.6-5.2,0.9-7.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M456.7,761.9
      c-3.6-0.8-7.2-1.8-10.8-3.1c-0.4,0.1-0.8,0.2-1.1,0.3c-5.4,1.4-11.2,2.3-17.3,2.7c0.2,0,0.4,0.1,0.5,0.2l8.5,3.6
      c0.8-0.1,1.6-0.2,2.4-0.2c4.6-0.5,9.1-1.2,13.5-2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="440.6" y1="765.9" x2="438.8" y2="765.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M439.7,766
      c-4.1,0.5-8.4,0.8-12.7,0.9c-16.2,0.6-32.4,0.9-48.5,1h-2.8c0,0,0,0,0,0h-2.7c0,0,0,0,0,0h-2.8c-16.2-0.1-32.4-0.4-48.5-1
      c-4.3-0.2-8.6-0.5-12.7-0.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M436.4,765.6
      c-3.1,0.3-6.2,0.5-9.4,0.6c-16.2,0.6-32.4,0.9-48.5,1h-2.8c0,0,0,0,0,0h-2.7c0,0,0,0,0,0h-2.8c-16.2-0.1-32.4-0.4-48.5-1
      c-3.2-0.1-6.3-0.3-9.4-0.6c-1-0.1-1.9-0.2-2.9-0.3l-1.6,0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M428.5,751.6l-5.3,0.1
      c-1-0.2-1.8-0.5-2.4-0.9l-1.5-1.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.4-1.1-0.6-1.7-0.7l0.2,11.8l17.9-1.4c0.5-0.1,1-0.1,1.6-0.1
      c2.9-0.2,5.5-0.2,8,0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M417.2,748.4
      c-1,3.8-2.3,7.9-4.1,12.1l4.2-0.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M417.2,748.4h-85.9
      c1,3.8,2.3,7.9,4.1,12.1l5.6,0.4c0,0,0.1,0,0.1,0c1.5,0,2.9,0.1,4.4,0.1v-1.3c0-0.2,0.1-0.3,0.3-0.3c0,0,0.1,0,0.1,0
      c8.1,0.2,16.1,0.3,24.2,0.3h2.8c0,0,0,0,0,0h2.7c0,0,0,0,0,0h2.8c8.1,0,16.1-0.1,24.2-0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.2,0.3
      v1.3c1.5,0,2.9-0.1,4.4-0.1c0,0,0.1,0,0.1,0l5.6-0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M427.4,761.9
      c-1.4-0.5-4.5-0.6-9.3-0.5c-13.2,0.4-26.4,0.6-39.6,0.7h-2.8c0,0,0,0,0,0c-0.9,0-1.8,0-2.7,0h0c-0.9,0-1.8,0-2.8,0
      c-13.2-0.1-26.4-0.3-39.6-0.7c-4.8-0.1-7.9,0-9.3,0.5c-0.2,0-0.3,0.1-0.4,0.2l-8.5,3.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M460.5,263l0.3,1.1
      c5.3,3.2,10.5,6.5,15.7,9.9c0.9,0.6,1.9,1.2,2.8,1.8c0.1,0,0.1,0.1,0.2,0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M464.8,271.3
      c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.4-0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M464.4,270.5
      c-0.3-0.3-0.6-0.7-1-1c-1.1-1.1-2.4-2.2-3.8-3.3c-9.4-7-19.5-12.6-30.2-16.7c-0.5-0.2-1-0.4-1.5-0.6v1.5v2.6c0,0.6,0.6,1.1,1.8,1.5
      c1.6,0.6,3.1,1.2,4.6,1.8c10.8,4.5,19.4,10.1,25.6,16.5c0,0,0,0,0,0c0.5,0.5,1.1,1.1,1.6,1.7c1.1,1.2,2.1,2.4,3,3.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M434.2,256.3l-0.3,1
      c11,4.4,19.5,9.8,25.4,16.3c1.9,2.1,3.5,4.2,4.9,6.5c7.1,12.8,11.3,36.2,12.6,70.3c0.4,14.8,0.8,29.6,1.1,44.2l3.1-1.2
      c-0.1-1.3-0.2-2.6-0.3-3.9c-0.1-2.1-0.3-4.2-0.4-6.4c-0.7-10.9-1.3-21.8-1.8-32.7c-1.2-37-5.8-63.4-13.7-79.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M452.2,259.6
      c2.5,1.6,5,3.2,7.5,4.9c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0.1l0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.8,0.4,1,0.2,0.8-0.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M426.6,254.6L426.6,254.6
      c-0.9-0.3-1.7-0.5-2.5-0.7c-0.8-0.2-1.7-0.4-2.5-0.5l-1-0.2c-1-0.1-2.1-0.3-3.4-0.5c-0.1,0-0.1,0-0.2,0c-1.5-0.2-3.1-0.3-4.8-0.5
      c-11.4-0.9-22.6-1.3-33.7-1.5c-0.1,0-0.2,0-0.4,0h-8c-11.1,0.1-22.4,0.6-33.7,1.5c-1.8,0.2-3.4,0.3-4.8,0.5c-0.1,0-0.1,0-0.2,0
      c-1.2,0.2-2.3,0.3-3.4,0.5l-1,0.2c-0.9,0.1-1.7,0.3-2.5,0.5c-0.8,0.2-1.6,0.4-2.4,0.7l-0.1,0c-2.6,0.8-5,1.7-7.4,2.6l-0.4,12.1
      c3.1-1.4,6.4-2.7,10-3.8l0.1,0c0.7-0.2,1.5-0.4,2.3-0.6c0.8-0.2,1.6-0.4,2.4-0.5l0.9-0.1c1-0.2,2-0.3,3.2-0.4c0.1,0,0.1,0,0.2,0
      c1.4-0.2,2.9-0.3,4.5-0.5c10.9-0.8,21.7-1.3,32.4-1.4l2.4,0c0.1,0,0.3,0,0.4,0h0c0.1,0,0.3,0,0.4,0c0.6,0,1.2,0,1.8,0h0.4
      c0,0,0,0,0,0c0.5,0,1.1,0,1.6,0c0.4,0,0.8,0,1.2,0c10.7,0.1,21.5,0.5,32.4,1.4c1.6,0.2,3.1,0.3,4.5,0.5c0.1,0,0.1,0,0.2,0
      c1.2,0.1,2.2,0.3,3.2,0.4l0.9,0.1c0.8,0.2,1.6,0.3,2.4,0.5c0.8,0.2,1.5,0.4,2.3,0.6l0.1,0c3.5,1.2,6.9,2.5,10,3.8l-0.4-12.1
      C431.6,256.3,429.2,255.5,426.6,254.6z"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="427.9" y1="248.9" x2="427.9" y2="247.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M427.9,253
      c-2.4-1.1-6.1-1.9-11.3-2.6c-0.6-0.1-1.3-0.2-1.9-0.2c-0.5,0-1-0.1-1.5-0.1c-11.7-0.9-23.2-1.4-34.7-1.5c-0.9,0-1.9,0-2.8,0
      c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0h-1.9c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0c-0.9,0-1.8,0-2.8,0c-11.4,0.1-23,0.6-34.7,1.5
      c-0.5,0.1-1,0.1-1.5,0.1c-0.7,0.1-1.3,0.2-2,0.2c-5.1,0.6-8.9,1.5-11.2,2.6c0,0.6-0.6,1.1-1.8,1.5c-1.6,0.6-3.1,1.2-4.6,1.8l0.3,1
      c-11,4.4-19.5,9.8-25.4,16.3c-1.9,2.1-3.6,4.2-4.9,6.5c-7.1,12.8-11.3,36.2-12.6,70.3c-0.4,14.6-0.8,29.1-1.1,43.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M416.6,249.3v-1.4
      c-0.6-0.1-1.3-0.2-2-0.3c-0.5-0.1-1-0.1-1.5-0.2c-11.7-0.8-23.2-1.3-34.7-1.5c-0.1,0-0.2,0-0.4,0l-2.4,0c0,0,0,0,0,0
      c-0.1,0-0.3,0-0.4,0h-1.9c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0l-2.8,0c-11.4,0.1-23,0.6-34.7,1.5c-0.5,0.1-1,0.1-1.5,0.2
      c-0.7,0.1-1.3,0.2-2,0.3v1.4c0.6-0.1,1.3-0.1,2-0.2v-0.6c0.5-0.1,1-0.1,1.5-0.2c11.7-0.9,23.3-1.3,34.7-1.5c0.9,0,1.8,0,2.8,0
      c0,0,0,0,0,0h0.4c0.6,0,1.3,0,1.9,0h0.4c0,0,0,0,0,0c0.9,0,1.9,0,2.8,0c11.4,0.1,23,0.6,34.7,1.5c0.5,0.1,1,0.1,1.5,0.2v0.6
      C415.3,249.1,416,249.2,416.6,249.3v1.2"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="414.7,249 
      414.7,249.2 414.7,250.2 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M414.7,249.2
      c-0.5-0.1-1-0.1-1.5-0.2c-11.7-0.8-23.2-1.3-34.7-1.5c-0.1,0-0.2,0-0.4,0l-2.4,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0h-1.9
      c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0l-2.8,0c-11.4,0.1-23,0.6-34.7,1.5c-0.5,0.1-1,0.1-1.5,0.2v1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M427.9,247
      c-4.3-0.7-8.6-1.3-12.8-1.8c-0.1,0-0.3,0-0.4-0.1c-0.5,0-1-0.1-1.5-0.1c-11.7-0.9-23.2-1.4-34.7-1.5c-0.1,0-0.2,0-0.4,0l-2.4,0
      c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0h-1.9c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0l-2.8,0c-11.4,0.1-23,0.6-34.7,1.5c-0.5,0.1-1,0.1-1.5,0.1
      c-0.2,0-0.3,0-0.4,0.1c-4.2,0.5-8.5,1.1-12.8,1.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M427.9,250.4
      c-3-1-6.8-1.8-11.3-2.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M420.9,285.6
      c-0.4-0.1-0.9-0.1-1.3-0.2c-1.8-0.3-3.1-0.5-4.1-0.6c-0.1,0-0.1,0-0.2,0c-1.4-0.2-2.9-0.4-4.5-0.5c-10.9-0.8-21.7-1.3-32.4-1.4
      c-0.1,0-0.3,0-0.4,0c-0.8,0-1.6,0-2.4,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0c-0.6,0-1.2,0-1.8,0h-0.4c0,0,0,0,0,0c-0.9,0-1.8,0-2.8,0
      c-10.7,0.1-21.5,0.6-32.4,1.4c-1.6,0.1-3.1,0.3-4.5,0.5c-0.1,0-0.1,0-0.2,0c-0.9,0.1-2.3,0.3-4.1,0.6c-0.4,0.1-0.8,0.1-1.3,0.2
      c0.3,2.3,0.5,4.5,0.5,6.6l-1.2,73.5c0,1.2,0.3,2,0.9,2.5c0.5,0.4,1.2,0.6,2.2,0.4c13.3-2.2,26.7-3.3,40.1-3.4c0.9,0,1.8,0,2.8,0
      c0,0,0,0,0,0c0.4,0,0.9,0,1.3,0c0,0,0.1,0,0.1,0c0.5,0,0.9,0,1.3,0c0,0,0,0,0,0c0.5,0,0.9,0,1.4,0h0.8c0.2,0,0.4,0,0.5,0
      c13.4,0,26.7,1.1,40.1,3.4c0.9,0.1,1.7,0,2.2-0.4c0.5-0.5,0.8-1.3,0.9-2.5l-1.2-73.5C420.4,290.1,420.6,287.9,420.9,285.6
      c0.7-5.9,2.2-12.5,4.5-19.7l1.5,107.4c0.1,1.3-0.2,2.2-0.9,2.7c-0.6,0.4-1.5,0.5-2.6,0.2c-14.9-3.2-29.9-5-45-5.2
      c-0.1,0-0.2,0-0.4,0l-2.4,0c0,0,0,0,0,0h-2.1c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0l-2.8,0c-15.1,0.2-30.1,1.9-45,5.2
      c-1.2,0.3-2,0.2-2.6-0.2c-0.6-0.5-0.9-1.4-0.9-2.7l1.5-107.4c2.3,7.2,3.8,13.8,4.5,19.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M435,284
      c10.8,3.1,17.9,14.4,21.2,33.9c2.7,19.4,4.6,40.1,5.8,62.1c0.1,2,0.2,4,0.3,6c0.2,3,0.4,6,0.5,9c0.1,1.5,0.2,3,0.2,4.5
      c0,0.7,0.1,1.4,0.1,2.2c0,0.3,0,0.7,0,1c0,0.5,0,1-0.1,1.5c3.8,1.5,6.1,6,6.9,13.6l1-0.2c-0.3-20-0.8-40.3-1.3-60.8
      c-0.5-14.3-1.5-26.6-3.1-37c-1.9-18.1-6.8-31.2-14.6-39.5c-4.7-4.2-10.5-7.8-17.6-11C434.6,274.3,434.8,279.2,435,284
      c0.9,23.4,2,46.3,3.2,68.8c0,0.2,0,0.3,0,0.5l0,0.4c0.3,3.5,1.4,6.2,3.4,8.3c0.1,0.1,0.1,0.1,0.2,0.2L462,380"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M483.3,390.6
      c1.5-5.8,2.3-14,2.4-24.7c0-0.4,0-0.8,0-1.2c0.1-20.6,0.1-40.9,0-60.9c0-10-0.7-17.4-2-22"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="478.1,412.9 
      478.3,412.7 481.3,411.4 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.7,394.7
      c-1.7,1.3-2.7,2.9-2.9,4.7c0,0.6,0,1.2,0,1.9c0.1,4.5,0.2,9.1,0.2,13.6l2.9-2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M470.8,437.9
      c-0.2-5.3-0.3-10.7-0.4-16l0-1c-0.1-1.1-0.1-2.1-0.3-3l-1.2,4.9c0,0,0,0,0,0.1c0,0.4,0.1,0.8,0.1,1.2c0.1,4.1,0.2,8.1,0.3,12.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="471.1" y1="417.6" x2="475.1" y2="414.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.2,395.5c0-0.1,0-0.2,0-0.2
      c-0.1-0.7-0.1-1.3-0.2-1.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M463.1,404.3
      c0,0.7-0.1,1.3-0.3,2c-0.2,1-0.4,2-0.5,3c3.7,1.6,5.9,6.1,6.5,13.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462.3,409.2
      c-2.2-1.1-4.6-2.1-7.4-3.1c-25.1-8.8-50.6-13.3-76.5-13.6c-0.9,0-1.9,0-2.8,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0h-0.1
      c-0.1,0-0.1,0-0.2,0h-1.5c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.9,0-1.8,0-2.8,0
      c-25.9,0.3-51.4,4.9-76.5,13.6c-2.8,1-5.2,2-7.4,3.1c0,0.1,0.1,0.3,0.1,0.5l0.1,0.5c2.1-1,4.5-2,7.2-3
      c25.1-8.7,50.6-13.3,76.5-13.6c0.9,0,1.8,0,2.8,0c0.1,0,0.1,0,0.2,0h0.3c0.1,0,0.2,0,0.2,0h1.3c0.1,0,0.1,0,0.2,0h0.3
      c0.1,0,0.1,0,0.2,0c0.9,0,1.9,0,2.8,0c25.9,0.3,51.4,4.9,76.5,13.6c2.7,1,5.1,1.9,7.2,3C462.2,409.9,462.2,409.5,462.3,409.2z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M463.1,399.6
      c-7.3-5.7-17.3-10.1-30.1-13.4l-2.9-0.8c-1.5-0.4-2.9-0.7-4.4-1c-15.7-3.5-31.4-5.4-47.2-5.6c-0.1,0-0.2,0-0.4,0l-2.4,0
      c0,0,0,0,0,0h-2.7c0,0,0,0,0,0l-2.8,0c-15.8,0.2-31.5,2.1-47.2,5.7c-1.5,0.3-2.9,0.7-4.4,1l-2.9,0.8c-12.8,3.3-22.8,7.7-30.1,13.4
      c0,0.7-0.1,1.4-0.1,2.2c0,0.3,0,0.7,0,1c0,0.1,0,0.3,0,0.4c0,0.4,0,0.7,0.1,1.1c0.3-0.1,0.6-0.2,0.9-0.4c2.4-1,4.9-1.9,7.3-2.7
      c25.1-8.7,50.6-13.3,76.5-13.6c0.9,0,1.8,0,2.8,0c0.1,0,0.1,0,0.2,0h0.3c0.1,0,0.2,0,0.2,0h1.3c0.1,0,0.1,0,0.2,0h0.3
      c0.1,0,0.1,0,0.2,0c0.9,0,1.9,0,2.8,0c25.9,0.3,51.4,4.9,76.5,13.6c2.7,1,5.5,2,8.2,3.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M461.4,413.6
      c0.2-1.1,0.5-2.3,0.7-3.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.9,393.9
      c1.3-4.5,1.9-9.3,1.9-14.4l-1.5-68.2c-0.8-19.1-2.8-31.5-5.9-37.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.8,394.6
      c0,0,0.1-0.1,0.1-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M314.2,269.4
      c-0.2,4.9-0.4,9.8-0.6,14.6c-0.9,23.4-2,46.3-3.2,68.8c0,0.2,0,0.3,0,0.5l0,0.4c-0.3,3.5-1.4,6.2-3.4,8.3c-0.1,0.1-0.1,0.1-0.2,0.2
      L286.6,380c-0.1,2-0.2,4-0.3,6c-0.2,3-0.4,6-0.5,9c6.9-3.3,13.9-6.1,21.2-8.3c21-6.2,42.1-9.5,63.2-9.9c0.9,0,1.8,0,2.8,0h0
      c0.2,0,0.4,0,0.7,0h2.1c0,0,0,0,0,0c0.5,0,1,0,1.5,0h0.6c0.2,0,0.4,0,0.6,0c21.1,0.4,42.2,3.6,63.2,9.9c7.2,2.2,14.3,5,21.2,8.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M375.7,393.6
      C375.7,393.6,375.7,393.6,375.7,393.6c-0.1,0-0.1,0-0.2,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M375.2,393.6c0,0-0.1,0-0.1,0
      H375"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M373.6,393.6L373.6,393.6
      c-0.1,0-0.2,0-0.2,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M375.7,387.5
      C375.7,387.5,375.7,387.5,375.7,387.5c-0.1,0-0.1,0-0.2,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M373.6,387.5L373.6,387.5
      c-0.1,0-0.2,0-0.2,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M375.2,387.5c0,0-0.1,0-0.1,0
      H375"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M373.1,387.5c0,0-0.1,0-0.1,0
      c0,0,0,0,0,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M373.1,393.6c0,0-0.1,0-0.1,0
      c0,0,0,0,0,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M270.9,394.7
      c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.2,0.5,0.5,0.7,0.7c0.1,0.2,0.3,0.3,0.4,0.5l4.7,6c0.1,0.1,0.3,0.3,0.4,0.4
      c0.3-15.2,0.7-30.5,1.1-45.9c0.5-14.3,1.5-26.6,3.1-37c1.9-18.1,6.8-31.2,14.7-39.5c4.6-4.2,10.5-7.8,17.6-11"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265.3,390.6
      c-1.5-5.8-2.3-14-2.4-24.7c0-0.4,0-0.8,0-1.2c-0.1-20.6-0.1-40.9,0-60.9c0-10,0.7-17.4,2-22"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M270.8,394.6
      c0,0-0.1-0.1-0.2-0.1l-3.1-1.2l-1,0.3l-0.1,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M267.5,393.3
      c0.1-1.3,0.2-2.6,0.3-3.9c0.2-2.1,0.3-4.2,0.4-6.4c0.7-10.9,1.3-21.8,1.8-32.7c1.2-37,5.8-63.4,13.7-79.1c0-0.1,0.1-0.1,0.1-0.2
      c0.1-0.2,0.2-0.4,0.4-0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.2,270.5
      c0.3-0.3,0.7-0.7,1-1c1.1-1.1,2.4-2.2,3.8-3.3c9.4-7,19.4-12.6,30.2-16.7c0.5-0.2,1-0.4,1.5-0.6v-1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M296.4,259.6
      c-2.5,1.6-5,3.2-7.5,4.9c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0.1l0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.8,0.4-1,0.2-0.8-0.7
      c-5.3,3.2-10.5,6.5-15.7,9.9c-3.1,5.9-5.1,18.3-5.9,37.4l-1.5,68.2c0,5,0.6,9.6,1.8,14"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M264.3,394.2l2.5,5.3
      c0.2,0.5,0.4,1,0.5,1.5c0.1,0.4,0.3,0.9,0.3,1.3c0,0.1,0,0.2,0,0.3c0.1,0.4,0.1,0.8,0.1,1.2v10c0.2,0.1,0.5,0.3,0.7,0.4
      c0.6,0.4,1.2,0.8,1.7,1.2c1.5,1.2,2.4,2.5,2.8,3.9c0.2,1.1,0.5,2.2,0.8,3.2c0.2-2.7,0.4-5,0.8-6.9c0.7-3.7,1.6-6,2.9-7
      c0.1,0,0.1-0.1,0.2-0.1c0.7-0.4,1.1-0.1,1.4,0.7l10.2,48.8c0.1,0.5-0.1,1.1-0.5,1.7c-0.2,0.4-0.6,0.8-1,1.2
      c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c-1.4,1.9-1.9,4.3-1.7,7.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.2
      c0.3,0.3,0.6,0.5,1,0.6l-0.2-1c-0.2-2.3,0.1-4.2,1-5.8c0.2-0.3,0.4-0.7,0.7-1c0.3-0.2,0.6-0.5,0.9-0.7c1.8-1.1,4.4-1.6,7.9-1.3
      c-3.5-15.2-6.7-29.9-9.5-44.1c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.5-0.2-1.1-0.3-1.6c-0.2-1.1-0.4-2.3-0.7-3.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M285.4,404.3
      c0.1,0.6,0.1,1.2,0.3,1.8c0,0.1,0,0.1,0,0.1c0.1,0.8,0.3,1.8,0.5,3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M277.8,402.7
      c0.3,0.2,0.7,0.3,1.1,0.4l5.4,0.7c0.6,0.1,1,0.2,1.2,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M285.8,395.1
      c-0.1,1.5-0.2,3-0.2,4.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M267.8,413.9
      c-0.2-0.1-0.4-0.2-0.5-0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.6,284
      c-10.8,3.1-17.9,14.4-21.2,33.9c-2.7,19.4-4.6,40.1-5.8,62.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="333.9" y1="249" x2="333.9" y2="249.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="332" y1="249.3" x2="332" y2="250.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M320.7,248.9v1.5
      c3-1,6.7-1.8,11.2-2.5"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="320.7" y1="250.4" x2="320.7" y2="253"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="288.1" y1="263" x2="287.8" y2="264.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M314.4,256.3
      c-10.8,4.5-19.4,10.1-25.6,16.5c0,0,0,0,0,0c-0.5,0.5-1,1.1-1.6,1.7c-1.1,1.2-2,2.4-3,3.7c-0.4,0.5-0.6,0.4-0.7-0.2
      c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3c-0.2-1.7-0.1-3.4,0.1-5.1c0.1-0.4,0.2-0.8,0.3-1.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M272.1,274
      c-0.9,0.6-1.9,1.2-2.8,1.8c-0.1,0-0.1,0.1-0.2,0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M390.8,746.4
      c-0.3,0.1-0.7,0.2-1.2,0.2h-7.5c-1.7,0-2.5-0.8-2.5-2.5v-70.8c0-1.7,0.8-2.5,2.5-2.5h7.5c0.5,0,0.8,0.1,1.2,0.2
      c0.9,0.3,1.3,1.1,1.3,2.3v70.8C392.1,745.3,391.7,746.1,390.8,746.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M345.5,761.1
      c8.2,0.2,16.4,0.3,24.6,0.3h2.8c0.9,0,1.8,0,2.7,0c0,0,0,0,0,0h2.8c8.2,0,16.4-0.1,24.6-0.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M382.7,744
      c-1.4,0-2.1-0.7-2.1-2.1v-66.6c0-1.4,0.7-2.1,2.1-2.1h6.2c1.4,0,2.1,0.7,2.1,2.1V742c0,1.4-0.7,2.1-2.1,2.1H382.7z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M359,746.6h7.5
      c1.7,0,2.5-0.8,2.5-2.5v-70.8c0-1.7-0.8-2.5-2.5-2.5H359c-0.5,0-0.8,0.1-1.2,0.2c-0.9,0.3-1.3,1.1-1.3,2.3v70.8
      c0,1.2,0.4,2,1.3,2.3C358.1,746.5,358.5,746.6,359,746.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M365.9,744
      c1.4,0,2.1-0.7,2.1-2.1v-66.6c0-1.4-0.7-2.1-2.1-2.1h-6.2c-1.4,0-2.1,0.7-2.1,2.1V742c0,1.4,0.7,2.1,2.1,2.1H365.9z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M342.6,746.6
      c1.7,0,2.5-0.8,2.5-2.5v-70.8c0-1.7-0.8-2.5-2.5-2.5h-7.5c-0.5,0-0.8,0.1-1.2,0.2c-0.9,0.3-1.3,1.1-1.3,2.3v70.8
      c0,1.2,0.4,2,1.3,2.3c0.3,0.1,0.7,0.2,1.2,0.2H342.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M342,744c1.4,0,2.1-0.7,2.1-2.1
      v-66.6c0-1.4-0.7-2.1-2.1-2.1h-6.2c-1.4,0-2.1,0.7-2.1,2.1V742c0,1.4,0.7,2.1,2.1,2.1H342z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M433.2,668.2H315.4l0.5,17.4
      c0,0.7,0.1,1.5,0.1,2.2c0.4,10.3,0.8,20,1.2,29.1h-0.1c0.9,12.8,1.9,24.4,3,34.8l5.3,0.1c1-0.2,1.8-0.5,2.4-0.9l1.5-1.4
      c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.4,1.1-0.6,1.7-0.7l-0.1,11.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M335.4,760.5l-4.2-0.3
      l-17.9-1.4c-0.5-0.1-1-0.1-1.5-0.1c-2.9-0.2-5.5-0.2-8,0.1c-0.3,0-0.7,0.1-1.1,0.1c0.4,0.1,0.8,0.2,1.1,0.3
      c5.4,1.4,11.2,2.3,17.3,2.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M302.2,627H302
      c-2.3,0.1-3.5,1.9-3.5,5.4c0,0.3,0,0.6,0,0.9c0.1,21.6,0.7,41.8,1.5,60.8c0.1,3,0.3,5.9,0.4,8.8c0,0.1,0,0.1,0,0.2l0.7,8.4
      c0.1,1,0.2,2,0.3,3c0.4,3.7,1.7,5.5,3.9,5.5h7.7c0-0.7-0.1-1.4-0.1-2.1c0-0.1,0-0.1,0-0.2c-0.4-7.3-0.7-15-1-23.1
      c-0.1-2.9-0.2-5.9-0.3-8.9l-1.6-58.6H302.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M294.5,666.1l-0.8,0
      c0.4,10.4,0.9,20.5,1.5,30.2c0,0.5,0.1,1.1,0.1,1.6c0.1,1,0.1,2,0.2,3c0.1,1.7,0.2,3.3,0.3,4.9c0,0.5,0.1,0.9,0.1,1.4
      c0,0.1,0,0.1,0,0.2l0.5,6.4c0.1,0.9,0.2,1.7,0.3,2.6c0.4,4,0.7,8,1.2,11.9c0,0.2,0.1,0.5,0.1,0.7l0,0.2c0.2,1.8,0.4,3.6,0.6,5.4
      c0.7,5.7,1.4,11.3,2.1,16.9c0.3-0.1,0.6-0.2,0.9-0.4h0.1c-0.7-5.4-1.4-10.9-2.1-16.5c-0.7-6-1.3-12.1-1.9-18.3
      c-0.1-0.9-0.2-1.7-0.3-2.6l-0.5-6.4c0-0.1,0-0.1,0-0.2c0-0.5-0.1-0.9-0.1-1.4c-0.2-2.6-0.3-5.2-0.5-7.9c-1.6-25.8-2.5-54-3-84.5
      l-1.1-49c-0.1-5.1-0.1-10.2-0.2-15.4l-0.9-0.2c0,1,0,2.1,0,3.1c0,2.1,0,4.3,0.1,6.4c0,2.1,0,4.2,0.1,6.3l0.9,49c0,0.5,0,0.9,0,1.3
      c0.2,12,0.4,23.7,0.8,35c0.2,5.5,0.3,11,0.6,16.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M301.7,751.1l0.9-0.2l0.2,0
      c-0.7-5.4-1.4-10.8-2.1-16.4c-0.7-6-1.3-12.1-1.9-18.3c-0.1-0.9-0.2-1.7-0.3-2.6l-0.5-6.4c0-0.1,0-0.1,0-0.2c0-0.5-0.1-0.9-0.1-1.4
      c-0.2-2.6-0.3-5.2-0.5-7.9c-1.6-25.8-2.5-54-3-84.5l-1.1-49c-0.1-5-0.1-10-0.2-15.2c-0.2-22.2,0-45.6,0.5-70.3
      c0.3-4.1,1.1-7.2,2.5-9.1c0.5-0.7,1.4-1.3,2.5-1.8l0,0c0.1,0,0.1,0,0.2-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.1,719.8l-2.2-22.7
      c0-0.2,0-0.3-0.1-0.5v0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.8-9.7-1.6-19.7-2.2-30l-1.3,0c0.6,10.3,1.4,20.3,2.2,30.1
      c0.7,8,1.5,15.9,2.3,23.5c0.4,3.9,0.9,7.7,1.3,11.5c0.9,7.8,1.8,15.4,2.8,22.9c0.4,0.1,0.8,0.1,1.3,0c-0.8-6.2-1.5-12.4-2.3-18.8
      c-0.2-1.3-0.3-2.7-0.5-4c0-0.1,0-0.2,0-0.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M288.4,731l0-0.3
      c-0.4-3.5-0.8-7.1-1.2-10.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M270.4,691.6
      c-2.1,0.5-3.2,2.7-3.4,6.6c0.3,6.7,2.1,13.1,5.6,19.3l1,1.6c-0.6-9.3-1.2-18.5-1.7-27.5c-0.2-0.1-0.4-0.1-0.7-0.1
      C270.9,691.6,270.7,691.6,270.4,691.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M263.5,710.8
      c0.1,8.6,0.6,16,1.4,22l3.9,4.4c-0.1-4.4-0.4-9.3-1-14.8l-0.2-1.5c0-0.2-0.1-0.5-0.1-0.7c0-0.1,0-0.2,0-0.2
      c-0.6-5.5-1.1-11.4-1.5-17.7c-0.5-15.4-0.7-30.3-0.6-44.7l-2.1-3c-0.2,16.3-0.2,33,0.1,50.2C263.4,706.7,263.5,708.8,263.5,710.8z"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M273.9,699.1l1.8,18.3
      c0.2,4.1-0.4,5-1.7,2.6l-0.5-0.8c0.7,9.7,1.4,19.5,2.2,29.5c0.3,0.9,0.6,1.9,0.9,2.9l3.6,0.9c-0.9-7.3-1.7-14.8-2.6-22.4v0
      c-1.9-20.1-3.4-41.4-4.5-63.7c-0.9-0.2-1.8-0.8-2.8-2c0.4,9,0.9,18.1,1.4,27.4C272.9,691.8,273.6,694.2,273.9,699.1z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M275.7,748.6
      c-0.8-2.3-1.8-4.3-2.9-6.2c-1.3-1.8-2.6-3.6-4-5.3"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="284.9" y1="697.1" x2="295.2" y2="696.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313,719.9
      c0.8,11.6,1.8,22.1,2.8,31.7l4.3,0"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="302.8,751 
      313.4,751.3 315.8,751.6 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M302.8,751.1c0-0.1,0-0.1,0-0.2
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M301.7,751.1
      c0.3,2.2,0.6,4.3,0.9,6.5c0.1,0.4,0.1,0.8,0.2,1.2c-4,1.3-7.9,2.4-11.9,3.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M302.6,751
      c0.4,2.6,0.8,5.2,1.2,7.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M300.6,751.5
      c-2.8,1-5.4,1.9-8.1,2.7c-0.4,0.1-0.9,0.2-1.3,0.2c-0.1,0-0.1,0-0.2,0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M309.4,765.4
      c-4.4-0.5-8.7-1.1-12.9-1.9l-1.8,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M296.5,763.4
      c-1.9-0.4-3.8-0.8-5.7-1.3h0c0-0.2-0.1-0.4-0.1-0.6c-0.3-2.4-0.6-4.8-0.9-7.2l-1.2,0.2l-8.4-2.1c0.3,2.6,0.6,5.2,1,7.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276.6,751.5
      c0.5,2,1,4.1,1.3,6.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M280.9,632.4l0.2,4.5
      c0.1,2.7,0.2,5.3,0.4,7.9l0.2,4.3l0.1,1.1l1.6-0.1c-0.3-6.3-0.6-12.3-0.8-18l-2.3-74.3l-1.4,0c0.1,2.2,0.1,4.4,0.2,6.5l1.3,49
      c0,0.4,0,0.7,0,1c0.2,6,0.4,11.8,0.6,17.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M278.9,613.4
      c0-0.5,0-1.1-0.1-1.6l-1.4-47.4c-0.2-6.6-0.4-13.3-0.5-20.2c-2.5-0.5-4.9-1.3-7.4-2.4c0.1,7.6,0.2,15,0.4,22.1
      c0.3,17.2,0.8,33.8,1.4,49.5c0,0.3,0,0.6,0,0.9c0.4,18,0.9,35.3,1.8,52l8.2,0.1c-0.4-5.6-0.7-11.4-1-17.2
      c-0.6-11.3-1-22.9-1.4-34.8C279,614.1,278.9,613.7,278.9,613.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M267.9,618.8c0,0.1,0,0.1,0,0.2
      c-0.7,4.7-0.8,10.7-0.3,18h0.6c-0.5-7.2-0.4-13.2,0.2-17.9c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.1-0.8,0.2-1.1c0.1-0.5,0.1-1,0.2-1.3
      c0-0.1,0-0.3,0-0.4c0-0.3-0.1-0.5-0.2-0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M268.8,615h1.3v4.2
      c0.4,0,0.7,0.3,0.8,1c0.1,0.3,0.1,0.7,0.1,1.2v11v0.1c0,0.4-0.2,0.7-0.6,0.8v3.8l0.2,5.1h-1.1c0.2,7.3,0.5,14.7,0.9,22.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="280.2" y1="557.9" x2="291.4" y2="558.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M269.1,632.1
      c0.1,0.1,0.2,0.1,0.3,0.2v-11c0-0.1,0-0.1,0-0.2c0-0.3,0-0.5-0.1-0.7c-0.2,0.2-0.4,0.5-0.5,0.9c0,0.1,0,0.2,0,0.4
      c-0.2,2.7-0.3,5.8-0.2,9.2C268.7,631.5,268.9,631.9,269.1,632.1z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M270.9,620.1h-1
      c-0.2,0-0.5,0.1-0.6,0.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M267.9,618.8
      c-1.2,3-1.9,5.7-2,8.1c-0.2,4.9-0.4,10.8-0.3,17.6c-0.1,4.3-0.1,8.7-0.1,13.1c1.7,1.7,3.4,3.9,5,6.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M269.5,642.1h-0.9h-0.5
      c-0.2-1.8-0.3-3.5-0.4-5.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M270.4,637h-2.2
      c0.1,0.8,0.1,1.7,0.2,2.6c0,0.1,0,0.3,0.1,0.4c0,0.7,0.1,1.4,0.2,2.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M269.4,632.3c0.1,0,0.2,0,0.4,0
      h1.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M261.4,650.1l1.9,4.5
      c0-3.4,0.1-6.8,0.2-10.1c0-6.8,0.2-11.8,0.7-15.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M281.7,649.2
      C281.7,649.2,281.7,649.2,281.7,649.2c0,0.4,0,0.7,0.1,1.1c0.3,5.4,0.6,10.8,0.9,16.1l11.1-0.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="283.3" y1="650.2" x2="293.1" y2="649.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M297,459.8
      c0.2,0.9,0.4,1.8,0.6,2.7c0,0.1,0,0.1,0,0.1c0.2,0.8,0.3,1.5,0.5,2.3c-0.3,0.1-0.5,0.2-0.8,0.4c-0.2,0.1-0.4,0.2-0.6,0.3
      c-2.3,1.3-3.6,5.3-4.1,11.8c0,0.3,0,0.9-0.1,1.6c0,0.9,0,1.8-0.1,2.8c-0.5,23.5-0.6,45.9-0.5,67.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.7,469.6l0.2,1.1
      c0.1,0.5,0.2,0.9,0.4,1.3c-0.4-2.2,0-4,1.2-5.6c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.4,0.9-0.8,1.4-1.1c0.5-0.3,1-0.5,1.5-0.7
      c0.5-0.1,1-0.2,1.6-0.2c1.3-0.2,2.6-0.2,4-0.1c0.4-0.2,0.6-0.7,0.5-1.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M297.2,464
      c-2.6,1-4.3,4.1-4.9,9.4h-0.6c0,0.2,0,0.4,0,0.6c0,1,0,1.9-0.1,2.9c0,0.3,0,0.5,0,0.8c-0.4,21.3-0.5,41.7-0.4,61.2
      c0,3.3,0,6.5,0.1,9.8c-4.6-1.2-8.9-2.5-12.8-4c0,0.6,0,1.2,0,1.8c0.1,3.8,0.2,7.6,0.3,11.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265.4,448.4v11.8l8.9-3.7V451
      c0-0.2,0-0.4,0-0.5c-0.1-0.4-0.2-0.8-0.4-1c0-0.1-0.1-0.1-0.1-0.1l-1.4-1.5c-0.8-0.7-1.6-1.1-2.5-1.1c-0.3,0-0.6,0-0.9,0.1
      c0,0,0,0-0.1,0h0l0,0l-1.7,0.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M277,453.2
      c0,4.5-0.1,9.1-0.1,13.6c-0.1,4.5-0.1,9,0,13.4c0.2-9.3,0.4-18.5,0.6-27.6L277,453.2l-2.8,3.7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276.9,480.3l-0.2,39.1
      c0,0.1,0,0.3,0,0.4c0.1,8.3,0.2,16.4,0.3,24.4c0.5,0.4,1,0.5,1.5,0.5c-0.1-4.2-0.2-8.4-0.2-12.6c-0.1-4.1-0.1-8.1-0.1-12.3
      c0-0.2,0-0.3,0-0.4l0.6-39.3c0.1-9.9,0.2-19.7,0.4-29.5l-1.6,2.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M278.3,532.1l1.4,0.7
      c-0.1-4.3-0.1-8.6-0.1-13c0-0.2,0-0.3,0-0.4l0.6-39.3c0.1-10.1,0.2-20,0.4-29.9h-1.1l-0.3,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M280.5,450.1h0.1
      c-0.1-0.4-0.2-0.8-0.3-1.2l-0.9,1.2"/>
    <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="267,461.1 269.7,460 
      274.2,458.1 274.2,456.9 274.2,456.5 	"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M279.6,532.9l1.5,0.8
      c-0.1-4.6-0.1-9.2-0.2-13.8c0-0.2,0-0.3,0-0.4l0.6-39.3c0.1-8.4,0.2-16.7,0.3-25c-0.4-1.7-0.9-3.3-1.3-5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M277.2,438.6l0.4-0.4
      c-0.1-0.5-0.2-1-0.4-1.5C277.2,437.3,277.2,437.9,277.2,438.6z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M268.9,446.9
      C268.9,446.9,268.9,446.9,268.9,446.9c0.4-0.2,0.7-0.3,1-0.5c0.1-10.3,0.3-20.6,0.4-31"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M269.8,446.4
      c0.4-0.2,0.7-0.5,1-0.8l6.3-7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M280.3,448.9
      c-0.9-3.6-1.8-7.2-2.7-10.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M291.7,477.7
      c-0.4-1.4-0.8-2.8-1.2-4.3h-0.8c-0.1,0-0.2,0-0.3,0c-0.9-0.1-1.6-0.6-2-1.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M285.6,468.8
      C285.6,468.8,285.5,468.8,285.6,468.8c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.3-0.1-0.6-0.2-0.9c-1.1-4.1-2.2-8.2-3.2-12.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M269.7,460c0,2.1,0,4.1,0,6.1
      c-0.2,11.3-0.3,22.4-0.3,33.3c0,2.5,0,13.2,0.1,32.2c0,0.1,0,0.3,0,0.4l0,1.2c0,0.3,0,0.5,0,0.8c0,2.6,0.1,5.1,0.1,7.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="265.4" y1="460.2" x2="256.1" y2="464"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="291.8" y1="473.4" x2="290.4" y2="473.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M315.4,668.2l-3.9-140.1
      c-0.2-4.1-0.4-8-0.7-11.8h-4.3c0.3,3.8,0.5,7.7,0.7,11.8l2.7,98.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M266.7,505.4
      c0-0.1,0-0.2,0.1-0.4c0.1-0.3,0.1-0.5,0.2-0.8h1.5v5.2c0.2-0.1,0.3,0.1,0.4,0.5c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0,0.7
      c0,0.1,0,0.2,0,0.2v11.2c0,0.7-0.2,1.1-0.6,1.2l0.1,2.8l0.1,5.1h-1.6h-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M268.8,510h-0.7
      c-0.2,0-0.4,0.1-0.5,0.3c0,0.2,0,0.4,0,0.7c0,0.1,0,0.2,0,0.2v11.2c0.1,0,0.2,0,0.3,0h0.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M267.6,522.4
      c-0.3-0.1-0.5-0.6-0.6-1.5c-0.1-3.6,0-6.9,0.2-9.7c0.1-0.5,0.2-0.8,0.4-1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M266,526.4h0.5
      c-0.4-7.2-0.3-13.2,0.2-17.9c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.1-0.8,0.2-1.1c0.2-1.2,0.1-2,0-2.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M268.3,526.4h-1.8
      c0,0.8,0.1,1.7,0.2,2.6c0,0.1,0,0.2,0,0.4c0,0.7,0.1,1.4,0.2,2.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M266.9,540.1
      c0.9,0.7,1.8,1.3,2.7,1.7"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="281.2" y1="533.7" x2="291.2" y2="539"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M306.5,516.3
      c-1.4-19.2-3.9-35.3-7.5-48.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="310.8" y1="516.3" x2="437.8" y2="516.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M277.2,436.6
      c-1.1-4.6-2.3-9.3-3.4-14.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M468.1,426.4
      c0.3-1.2,0.5-2.4,0.8-3.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M471.1,417.6
      c0.1,7,0.2,14,0.4,20.9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478.3,412.7
      c0.2,11.3,0.3,22.5,0.5,33.7"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="446.3" y1="627" x2="446.4" y2="627"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M443,458.9
      c-13.8-3.8-27.7-6.5-41.6-8c-1.1-0.4-1.7-0.9-1.8-1.7l-0.8-3.9c-0.4-1.3-1.3-2.1-2.8-2.4c-0.3,0-0.7-0.1-1-0.1
      c-5.5-0.4-11-0.7-16.5-0.8c-0.1,0-0.2,0-0.3,0l-1.1,0c-0.5,0-0.9,0-1.4,0c0,0,0,0,0,0c-0.9,0-1.8,0-2.7,0c0,0,0,0,0,0
      c-0.9,0-1.8,0-2.8,0c-5.5,0.1-11,0.3-16.6,0.8c-0.3,0-0.6,0.1-1,0.1c-1.5,0.3-2.4,1.1-2.8,2.4l-0.8,3.9c-0.1,0.8-0.7,1.4-1.8,1.7
      c-13.9,1.5-27.8,4.1-41.6,8c-0.7,0.2-1.4,0.4-2.1,0.6c-3.4,0.9-5.7-0.9-6.9-5.3c-2.6-11.3-5-22.4-7.1-33.1
      c-0.5-4.2,0.6-6.8,3.4-7.9c25.3-8.7,51-13.2,77.2-13.5l2.5,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0h2.1c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0
      l2.4,0c0.1,0,0.3,0,0.4,0c26.1,0.3,51.9,4.8,77.2,13.5c2.8,1.1,4,3.8,3.4,7.9c-2.2,10.7-4.5,21.8-7.1,33.1c-1.2,4.4-3.5,6.2-7,5.3
      C444.4,459.3,443.7,459.1,443,458.9z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M467.7,632.1c0,0.1,0,0.2,0,0.2
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M448.7,659.4
      c0.2-7.6,0.3-15.5,0.4-23.5c0-0.1,0-0.3,0-0.4c0-1.9-0.9-2.8-2.5-2.7h-4c-1.2-0.1-1.8,0.4-1.9,1.3l-0.4,25.6c0,0.4,0.2,0.6,0.5,0.5
      h7.4C448.4,660.3,448.6,660,448.7,659.4L448.7,659.4z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M445.9,713.5
      c-0.1,1.2-0.7,1.8-1.7,1.8h-5.5c-0.5,0-0.7-0.3-0.7-0.8c0-0.1,0-0.2,0-0.3c0.4-6,0.7-12.3,1.1-18.8c0.1-3.1,0.3-6.3,0.5-9.5
      l0.2-5.3c0-0.3,0.2-0.5,0.6-0.5c0,0,0.1,0,0.2,0h6.9c0.4,0.1,0.6,0.3,0.6,0.7c0,0,0,0.1,0,0.1c-0.2,4.8-0.5,9.4-0.8,14
      c-0.2,3.2-0.4,6.3-0.6,9.4c0,0.1,0,0.1,0,0.2l-0.5,6.4c-0.1,0.8-0.1,1.6-0.2,2.4C445.9,713.4,445.9,713.4,445.9,713.5z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M463.7,696.3L463.7,696.3
      c0,0,0,0.2-0.1,0.4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M463.7,696.6c0,0.2,0,0.3,0,0.5
      "/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="442" y1="717.6" x2="441.9" y2="717.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M427.2,763.2l2.3,1.2
      c1.3,0.6,0.8,1-1.7,1.2c-0.3,0-0.5,0-0.8,0c-16.2,0.6-32.4,0.9-48.5,1h-2.8c0,0,0,0,0,0h-2.7c0,0,0,0,0,0h-2.8
      c-16.2-0.1-32.4-0.4-48.5-1c-0.3,0-0.5,0-0.8,0c-2.5-0.2-3.1-0.6-1.7-1.2l2.3-1.2c0.2-0.1,0.5-0.2,0.7-0.3c1.8-0.7,4.4-1,7.9-1
      l0.5,0c13.2,0.4,26.4,0.6,39.6,0.7c0.9,0,1.8,0,2.8,0c0,0,0,0,0,0h2.7c0,0,0,0,0,0h2.8c13.2-0.1,26.4-0.3,39.6-0.7l0.5,0
      c3.5,0,6.1,0.3,7.9,1C426.8,763,427,763.1,427.2,763.2z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M464.4,278.2
      c0.4,0.5,0.6,0.4,0.8-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3c0.2-1.7,0.1-3.4-0.1-5.1c-0.1-0.4-0.2-0.8-0.3-1.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="464.4" y1="270.5" x2="464.4" y2="270.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M463.4,269.5
      c0.7,1.2,1.1,2.5,1.1,4.1c0,0.2,0,0.4,0,0.6c0,0.3,0,0.7,0,1c0,0.3,0,0.6,0,0.9c0,0.4-0.1,0.9-0.1,1.3c-0.5-0.7-1.1-1.5-1.7-2.2
      c-0.5-0.5-0.9-1.1-1.3-1.6v0c-0.6-0.7-1.2-1.3-1.9-2c-7-7.1-16.8-13-29.4-17.8c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.2-0.5-0.4-0.5-0.8
      v-3.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462.7,275.2l0.9,0.4
      c0.5,0.3,0.8,0.9,0.8,1.7"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="461.4" y1="273.6" x2="464.5" y2="275.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="459.5" y1="271.6" x2="464.5" y2="274.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478,411.2c0-0.1,0-0.2,0-0.3
      c0,0,0-0.1,0-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478.1,412.9c0-0.1,0-0.1,0-0.1
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478,408.8c0-0.1,0-0.2,0-0.3"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.8,401.5c0-0.1,0-0.1,0-0.1
      c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.8,400.2c0-0.1,0-0.1,0-0.2
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.8,398.8
      C477.8,398.7,477.8,398.7,477.8,398.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.8,396.8c0-0.1,0-0.2,0-0.3
      c0-0.1,0-0.2,0-0.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.3" y1="404.9" x2="477.9" y2="406.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.3" y1="407.3" x2="478" y2="408.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.3" y1="407" x2="478" y2="408.2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="477.9" y1="405.8" x2="481.3" y2="404.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.2" y1="395.3" x2="477.8" y2="396.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.8,398.8c0,0,0,0.1,0,0.1
      l3.4-1.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M477.8,398.9c0,0.2,0,0.4,0,0.6
      "/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="477.8" y1="401" x2="481.2" y2="399.7"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.2" y1="397.3" x2="477.8" y2="398.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="477.8" y1="401.3" x2="481.2" y2="400.1"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.2" y1="402.2" x2="477.9" y2="403.4"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.2" y1="402.5" x2="477.9" y2="403.7"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="478" y1="410.9" x2="481.3" y2="409.8"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="481.3" y1="409.4" x2="478" y2="410.6"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="284.2" y1="270.5" x2="284.2" y2="270.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M285.2,269.5
      c-0.7,1.2-1.1,2.5-1.1,4.1c0,0.2,0,0.4,0,0.6l5-2.6c7-7.1,16.8-13,29.4-17.8c0.1,0,0.2-0.1,0.3-0.1c0.3-0.2,0.5-0.4,0.5-0.8v-3.5"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.2,277.4
      c0-0.8,0.3-1.4,0.8-1.7l0.9-0.4c0.4-0.5,0.9-1.1,1.3-1.6v0l-3.1,1.6c0,0.3,0,0.6,0,0.9C284.2,276.6,284.2,277,284.2,277.4
      c0.5-0.7,1.1-1.5,1.7-2.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.1,274.2c0,0.3,0,0.7,0,1"
      />
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.2,273.6
      c0.6-0.7,1.2-1.3,1.9-2"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="302.3" y1="627" x2="302.2" y2="627"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M310.6,714.2
      c-0.4-6-0.7-12.3-1.1-18.8c-0.2-3.1-0.3-6.3-0.5-9.5l-0.2-5.3c0-0.3-0.2-0.5-0.6-0.5c-0.1,0-0.1,0-0.2,0h-6.8
      c-0.4,0.1-0.6,0.3-0.6,0.7c0,0,0,0.1,0,0.1c0.2,4.8,0.5,9.4,0.8,14c0.2,3.2,0.4,6.3,0.6,9.4c0,0.1,0,0.1,0,0.2l0.5,6.4
      c0.1,0.8,0.1,1.6,0.2,2.4c0,0.1,0,0.1,0,0.2c0.1,1.2,0.7,1.8,1.7,1.8h5.5c0.5,0,0.7-0.3,0.7-0.8
      C310.6,714.4,310.6,714.3,310.6,714.2z"/>
    
      <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="306.6" y1="717.6" x2="306.7" y2="717.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.8,696.5c0-0.1,0-0.1,0-0.2
      h0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M288.4,731.3c0-0.1,0-0.2,0-0.3
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.1,720c0-0.1,0-0.1,0-0.1
      c0,0,0-0.1,0-0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M280.9,632.1c0,0.1,0,0.2,0,0.2
      "/>
    <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M299.9,659.5L299.9,659.5
      c-0.2-7.7-0.3-15.5-0.4-23.5c0-0.1,0-0.3,0-0.4c0-1.9,0.9-2.8,2.6-2.7h4c1.2-0.1,1.8,0.4,1.9,1.3l0.4,25.6c0,0.4-0.2,0.6-0.5,0.5
      h-7.4C300.2,660.3,300,660,299.9,659.5z"/>
  </g>
  <g id="Layer_2">
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M57.2,597.3c0.1,0,0.2,0,0.2,0
        c-0.1-3.5-0.1-23.9-0.2-61.2c0-1.4-0.1-2.8-0.1-4.2c-0.7-42.1-1.2-83.9-1.5-125.4c0-0.2,0-0.4,0-0.7c-0.1-9.4-0.1-18.8-0.2-28.2
        l-0.5-6c-12.2-0.4-22.7-4.9-31.5-13.7c-9.1-9.1-13.7-20.2-13.7-33.1c0-12.9,4.6-23.9,13.7-33.1c6.9-6.9,14.8-11.2,23.8-12.9
        l-0.5-6.7c-0.9-0.4-1.6-0.9-2-1.5l-0.4-0.6l-2.2-3c0.3-0.9,0.7-1.7,1-2.6c0.6-1.3,1.2-2.6,1.9-3.9c-0.2-0.4-0.4-0.8-0.7-1.2
        c-0.6-0.9-1.3-1.6-2-2.2v-1.2c1.4-2.5,2.9-5,4.8-7.2v-2.5c0-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
        c0.2,0.2,0.2,0.4,0.2,0.6v0.4c0.5-0.6,1.2-1.3,2-1.9c0.5-0.4,0.9-0.9,1.2-1.3c0.8-1.2,1.6-2.2,2.6-3l0.5,0.4v0.7
        c0.2-0.2,0.4-0.4,0.6-0.6c3.5-2.8,7.9-5.4,13.2-8v-1c2.2-0.6,4.6-1.1,7.1-1.3l2.7-0.1l1.4,1.4h18.1l0.5-1.7c1.3,0,2.5-0.1,3.7-0.1
        l1.2,1.7h8.2l0.5-1.7c1.4,0,2.7,0.1,3.8,0.2c1.7,1.1,3.2,2.4,4.4,3.8v2l1,0.2c0.2,0,0.4,0.1,0.7,0.1v1.7c0.2,0,0.4,0.1,0.5,0.1
        v-0.9c0.5-0.4,1.2-0.5,2.3-0.3l0.1,0c1.4,0.4,2.8,0.7,4.1,1.1c0.7,0.2,1.2,0.6,1.3,1.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0.1,0,0.2,0,0.3,0.1l0.3-0.6l3.6,1.5c0.5,0.2,0.9,0.5,1.3,0.8c0.1,0.1,0.2,0.1,0.2,0.2c0.2,0.2,0.4,0.4,0.6,0.6l0,0.1
        c0.1,0.1,0.1,0.1,0.2,0.2c2.8,3.2,5.1,6.2,7,9.1c0.3,0.4,0.5,0.9,0.7,1.3c0.2,0.4,0.3,0.9,0.4,1.4c0.4,1.6,0.7,3.2,1,4.8
        c1.3,4.5,2.8,8.8,4.5,13.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3l0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.9,2.5,1.5,4.8,1.8,7
        c0.1,0.4,0.1,0.9,0.2,1.3c2.8,23.3,4.7,47.9,5.7,73.7l-1.2,3.1l-2.2,5.6l0.2,5.9l0.3,6.9l5.4,5.7l-0.1,1.1c1.7,1.7,3.3,3.3,4.9,5
        c13.9,14.3,26.6,28.4,38.1,42.5c3.2,3.9,6.3,7.8,9.3,11.8c0.3,0.3,0.5,0.5,0.8,0.8l2.5,3c1.6,2.1,2.9,5,3.9,8.7
        c2.1,8,3.9,19.3,5.4,33.8c0.6,4.8,1.1,10.5,1.5,17c0.6,8.4,1,18.3,1.3,29.5c0.9,41.5,1.1,82.2,0.5,122.1c0,0.3,0,0.6,0,0.9
        l1.4,5.1c-0.2,23.4-0.5,46.5-1,69.4l-1.8,4.8v1l-1.1,2.9c-0.1,2-0.1,4.3-0.2,6.8c-0.1,0.5-0.4,0.7-0.8,0.8l-3,0.5v0.4
        c0,0.2-0.2,0.4-0.6,0.4l-0.7,0.1l-2.4,0.5c-0.8,0.1-1.6,0.2-2.4,0.4c-0.9,0.1-1.8,0.3-2.7,0.4c-0.4,0.1-0.9,0.1-1.3,0.2
        c-0.2,0-0.5,0.1-0.7,0.1c-0.3,0-0.5,0.1-0.8,0.1c-13.9,1.9-28.4,3.2-43.3,3.8c-1.8,0.1-3.6,0.2-5.5,0.2c0,0,0,0.1,0,0.1
        c0,0.1,0,0.2,0,0.3c-1.2,0-2.4,0-3.6,0c-3.3,0-6.5,0-9.8,0.1c-0.9,0-1.7,0-2.6,0v0.2c-1.7,0.4-3.7,0.6-5.8,0.8
        c-3.6,0.3-7.8,0.5-12.6,0.4c-1.8-0.1-3.3-0.2-4.5-0.4c-1.6-0.2-2.7-0.4-3.3-0.7v-0.3c-6.1,0-12.3-0.1-18.4-0.1v-1v-0.5v-1.7
        l-1.1-2.6v4.3v1.6l-4.9,8.5c-1.5-0.1-2.9-0.1-4.2-0.2v-0.5c-9-0.7-16-2.6-20.8-5.6l-0.3,0.4c-0.4-0.2-0.7-0.5-1.1-0.7
        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1h-3.3l-1.2-6.4c-0.1-0.1-0.2-0.3-0.2-0.4c0,0,0-0.1-0.1-0.1v-1.6l-0.7,0.9
        l-4-4.7l3.4-4.4c0.1-0.1,0.2-0.2,0.2-0.4c0.5-0.9,0.8-2.1,0.9-3.5l-2.2-4.6c-2.9-0.9-4.7-2.8-5.6-5.5l-3-11.9
        c-11.7-0.7-21.7-5.2-30.1-13.6C14.4,694,9.8,683,9.8,670c0-12.9,4.6-23.9,13.7-33.1c9.2-9.2,20.2-13.7,33.1-13.7
        c0.6,0,1.2,0,1.8,0l-0.3-6.5c-0.2,0-0.3-0.1-0.5-0.1c0-2-0.1-4-0.1-5.8c0-1.2,0-2.3-0.1-3.4c0-0.2,0-0.4,0-0.5
        c-0.1,0-0.2,0-0.3-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M228.5,738.6h-0.7l1.8-4.8
        c0.5-22.9,0.8-46.1,1-69.4l-1.6-5.7c-0.2,27-0.6,53.8-1.2,80.2l-1,3.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M227.4,742.5
        c0-0.1,0-0.3,0-0.4l-1.6,0.1c-0.8-0.4-1.3-0.6-1.4-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.1,0-0.2,0-0.3,0h-7.6v0c0,2.5-0.1,4.9-0.1,7.4
        c2-0.2,3.7-0.2,5.3-0.1c1.6,0.1,2.9,0.4,4,0.8c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.5,0.2,0.7,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M226,705.9h-2.2
        c-0.7,0-1.2-0.5-1.4-1.5c0-0.1,0-0.2-0.1-0.4c-0.1-12.2-0.1-23.2-0.1-32.9c0,0,0,0,0,0V671c0-0.6,0.3-0.8,0.7-0.7h3.4
        c0-4,0.1-8,0.1-12c0-2.5,0-5.1,0-7.6h-3.2c-0.6,0-0.9-0.2-1-0.5c0-0.1,0-0.3,0-0.4c0-8.1,0.1-16.3,0-24.4c0.2-1.5,0.7-2.3,1.7-2.3
        h2.5c0-1.9,0-3.9,0-5.8h-2.8c-1.5,0.1-2.3,2.1-2.4,6.2c0,0.5,0,1.1,0,1.6c0,11.1,0,22.2-0.1,33.2c-0.1,12.6-0.1,28,0,46.1
        c0.1,4.2,1.1,6.2,2.9,6h1.8C225.9,708.9,226,707.4,226,705.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M225.9,742.1
        c0.3-10.5,0.5-21.1,0.6-31.7h-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M226.4,670.3
        c-0.1,11.9-0.2,23.8-0.4,35.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M209.3,686.4
        c-0.1-9.9-0.1-20,0-30.2l-41.3,0.4v30.5L209.3,686.4c-0.2,17.8-0.4,36.3-0.4,55.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="223.5,750.6 
        222.2,750.8 222.2,751.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M215.6,748.7
        c-0.3,0-0.5,0-0.8,0.1c-0.7,0.1-1.3,0.1-2,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1l0.5,2.8c0.5-0.1,1-0.1,1.4-0.2c0,0,0.1,0,0.1,0
        c0.1,0,0.1,0,0.1,0h0c0.1,0,0.2,0,0.3,0c1.2-0.1,1.9,0.2,2.2,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M214.9,751.6
        c-0.1,0-0.1,0-0.2,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M212,749.1L212,749.1
        C212,749,212,749,212,749.1L212,749.1c0.2,0,0.5-0.1,0.7-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M213.2,751.8
        c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.4,0.1-0.6,0.1c-13.7,1.9-27.9,3.2-42.5,3.8c-0.5,0-0.8,0.3-0.8,0.7v0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M215.6,741.2h-0.8l-2,0.2
        c0,0,0,0.1,0,0.1c0,2.5-0.1,5-0.1,7.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M212.8,741.4l-3.9,0.4
        l-14.8,1.5c-10.1,0.8-19.9,1.3-29.3,1.4c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9,0-1.4,0c0,2.2,0.1,4.6,0.1,7.4c16.2-0.4,32.5-1.4,48.9-3"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M159,744.7c0-0.1,0-0.1,0-0.2
        l-9.4-2.1c0,2.5,0,5,0.1,7.5h0l9.7,2.2h0c0-2.5-0.1-5-0.1-7.5l-0.4-0.1c0-29.2-0.1-58.5-0.3-87.7l-5.8,0.1
        c-7.3-0.4-12.9-1.4-16.7-3.2c1.7,14.3,1.5,28.2-0.8,41.8c0,0,0,0,0,0.1c-3.6,17.3-16.9,30.6-39.9,40c0.3,2,0.5,4,0.8,6l53.3,1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="163" y1="744.7" x2="159.4" y2="744.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M163.1,752.1
        c0,0.1,0,0.1,0,0.1c0,0.5,0,1,0,1.6c0,0.1,0,0.3,0,0.4c0,0.5,0,1,0,1.6c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.1
        c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M163.1,752.2l-3.6-0.1
        c0,0.5,0,1.1,0,1.7c1.2,0,2.4,0,3.6,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M149.9,756.3
        c3.2,0,6.4,0,9.7-0.1c1.2,0,2.4,0,3.6,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M163.2,756.7
        c-1.2,0-2.4,0-3.6,0c-3.3,0-6.5,0-9.8,0.1c-0.9,0-1.7,0-2.6,0v1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M129,753.9L129,753.9
        c3.2,0,6.4,0,9.5,0c3.6,0,7.2,0,10.9-0.1h0.1c0.1,0,0.2,0,0.3,0c3.2,0,6.5,0,9.8-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M149.9,756.3c0,0-0.1,0-0.1,0
        c-3.8,0-7.6,0-11.4,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M138.1,756.4c-3,0-5.9,0-8.9,0
        h0c-3.9,0-7.8,0-11.7,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M117.4,756.3
        c-4.9,0-9.9-0.1-14.8-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M217.2,447.1l-0.8,8.1
        c0,0.2-0.1,0.5-0.1,0.8c0,0.4,0,0.8,0,1.3c0,0,0,0.1,0,0.1c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0,0.4c0.2,0.2,0.3,0.5,0.4,0.7
        c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.5,0.3,0.7c0,0.1,0,0.1,0.1,0.2c1.5,4.1,2.8,9.1,3.8,14.9l1.1,7.4
        c0.4,3.3,0.8,6.8,1.3,10.6c0.5,3.8,0.9,8.1,1.3,13h3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M216.3,458.5
        C216.3,458.5,216.3,458.5,216.3,458.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,2.4,0,4.8,0,7.2c0,23.8,0.1,64,0,120.6v0.9
        c0,6.2,0,12.4,0,18.5c0,43.3-0.3,88.2-0.7,134.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M204.6,431.5
        c-0.3,0.6,0,1.6,0.9,2.8c1.8,2.2,3.5,4.4,5.2,6.5l-0.8,7.6v0c-0.2,1.2-0.9,1.2-2.2-0.2c0.4,0.4,0.7,0.9,1.1,1.3
        c0.3,0.3,0.5,0.6,0.8,0.9c0,0,0.1,0.1,0.1,0.1c0.7,0.8,1.4,1.6,2.1,2.5c0.3,0.4,0.6,0.7,1,1.1c0,0,0.1,0.1,0.1,0.1v0l0.8,0.8
        c0-0.3,0-0.5,0.1-0.8l1.1-10.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M208.8,449.6
        c-0.1,4.3-0.1,6.7-0.1,7.2c0,0,0,0.1,0,0.1c0,0.5,0,1.1,0,1.7c-2.6-2.9-5.2-5.8-8-8.7c-0.1-0.1-0.3-0.2-0.4-0.4
        c-5.7-6-11.7-11.9-18-17.9c-5.8-5.5-11.8-11-18.1-16.5c-0.8-0.7-1.7-1.5-2.5-2.2l-0.1-0.1c-0.2-0.1-0.3-0.3-0.5-0.4
        c-0.9-1-1.4-2.2-1.5-3.6l-0.9-11.1c-0.2-0.2-0.4-0.4-0.6-0.6c-1.2-1.2-2.4-2.4-3.6-3.6l-0.7-0.7l-5.8-5.6c-1-0.8-2.1-1.5-3.3-2.1
        c-0.8-0.3-1.7-0.5-2.6-0.6h-0.2c0,0-0.1,0-0.1,0c-8.7-0.6-17.4-0.8-26.2-0.6c-0.2,0-0.5,0-0.8,0c-0.2,0-0.3,0-0.5,0
        c-2.3,0.2-4,1.3-5.2,3.1l-6.8,15.1c0,0,0.1,0,0.1,0c1.4-0.1,2.8-0.1,4.2-0.1c0.1,0,0.2,0,0.3,0c12.6-0.2,25.4,0.2,38.4,1.3
        c0.1,0,0.1,0,0.2,0l9,4.7c0.2,0.1,0.3,0.3,0.5,0.4c1.5,1.3,3,2.6,4.5,3.8c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.5,0.4,0.8,0.6
        c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.1,0.1,0.2,0.1c1,0.9,2,1.7,3,2.6c6.3,5.5,12.4,11.1,18.1,16.5
        c0.2,0.2,0.3,0.3,0.5,0.5c6.2,5.8,12,11.7,17.5,17.5c0.1,0.1,0.3,0.3,0.4,0.4c2.8,2.9,5.4,5.8,8,8.7c0.2,0.3,0.5,0.5,0.7,0.8
        c0-0.1,0-0.1,0-0.2v-0.1c0-0.9,0-1.8,0-2.6c0,0,0-0.1,0-0.1c0-0.4,0-0.8,0-1.1c0,0,0-0.1,0-0.1c0-0.4,0-0.9,0.1-1.3
        c0-1.2,0.1-2.7,0.1-4.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M216.3,458.5l-2.7-3.3
        c0,0.4,0,0.8,0,1.3c0,0,0,0.1,0,0.1c0,0.4,0,0.7,0,1.1c0,0,0,0.1,0,0.1c0,2.6,0,5.1,0,7.6v73.7l0,49.9c0,5.5,0,11,0,16.5
        c0,16.6-0.1,33.5-0.2,50.7c-0.1,27.8-0.3,56.2-0.6,85.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M209.5,460.4
        c0-0.3,0-0.6,0-0.9c0-0.6,0-1.1,0-1.7c0-0.1,0-0.1,0-0.1c0-0.3,0-1.2,0-2.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M209.5,460.6
        c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1c1.1,1.2,2.5,2.7,4,4.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="209.5" y1="460.8" x2="209.4" y2="460.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M182.8,433.5v6
        c0,0,0,0.1,0.1,0.1c0.4,0.7,0.7,1.4,0.9,2.1c0.2,0.2,0.5,0.4,0.7,0.7c8.7,8.4,17,17,25,25.8v60.4l-46.6-5.7c0.1,4,0.2,8,0.3,12.1
        l50.4,4.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M182.8,439.5
        c-0.1-0.2-0.3-0.4-0.4-0.6c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.4-0.8-0.7-1.3-1v1.6c0.1,0,0.2,0.1,0.3,0.2
        c0.4,0.2,0.7,0.4,0.9,0.7c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.8,1.4,1.3,3.1,1.4,5.1v-2.2c0-0.6-0.1-1.2-0.2-1.8
        c0-0.1-0.1-0.1-0.1-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M180.7,438.9
        c-0.5-0.2-1.2-0.3-2-0.3h-11.5c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-0.8,0.1v0.3c0,0.7,0.3,1.2,0.8,1.5c0.4,0.2,0.8,0.3,1.3,0.3h10.2
        C180,440.7,180.7,440.1,180.7,438.9C180.7,439,180.7,439,180.7,438.9C180.7,438.9,180.7,439,180.7,438.9L180.7,438.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M161.2,413.9
        c0,1.9,0.1,3.9,0.1,5.8c0,1.3,0,2.6,0,3.9c0.1,4.9,0.1,9.8,0.2,14.8h0.3v-5.8c0.4-2.4,1.7-4.9,3.9-7.3c0,0,0.1-0.1,0.1-0.1
        c0.1-0.1,0.3-0.3,0.4-0.4V437c0.2,0,0.4-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0h11.5c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0
        c0.6,0.1,1.1,0.2,1.6,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M102.2,402.2
        c-6.6,0.2-13,0.6-19.5,1.2l1.6,127.6c13.8-0.9,28-1,42.8-0.5c5.9,0.2,11.8,0.5,17.8,1l12.9,2.9c-0.9-26.5-1.6-52.8-2.1-78.8
        c-0.2-10.6-0.4-21.3-0.5-31.9c0-1.3,0-2.6-0.1-3.9c0-2-0.1-4-0.1-5.9c0-0.4-0.1-2.2-0.2-5.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M159.8,412.7
        c0.1,2.9,0.1,5.2,0.1,7c0,1.3,0,2.6,0,3.9c0.1,4.9,0.1,9.8,0.2,14.8h1.4c0.1,5.7,0.1,11.4,0.2,17.1c0.2,22.3,0.6,44.7,1.2,67.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M166.2,438.7
        c-1.2,0.9-2.1,1.9-2.9,3.1c-0.6,1-1.1,2-1.5,3.2v6.2c0,1,0.5,1.7,1.5,2.3c0.6,0.3,1.3,0.5,2.2,0.7c0.1,0,0.1,0,0.2,0
        c0.4,0.1,0.9,0.1,1.4,0.1c0.3,0,0.5,0,0.8,0h12.4c0.1,0,0.1,0,0.2,0c2.3-0.1,3.6-0.9,3.7-2.4v-6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="166.2" y1="437" x2="166.2" y2="438.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="161.8" y1="445" x2="161.8" y2="438.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M184.1,451.9v1.4
        c-0.1,1.5-1.3,2.3-3.7,2.4c-0.1,0-0.1,0-0.2,0h-12.6c-0.2,0-0.4,0-0.6,0c-1.6-0.1-2.9-0.4-3.7-0.9c-1-0.5-1.5-1.3-1.5-2.4v-1.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M160.1,438.3
        c0.1,5.7,0.1,11.4,0.2,17.1c0.3,26.2,0.8,52.6,1.5,79.3l1.4,0.2c0,0.6,0,1.2,0.1,1.9c0,3.7,0.1,7.5,0.1,11.2l46.1,0.4l0,40.6
        c0,5.5,0,11,0,16.5c0,11.1,0,22.4-0.1,33.8c0,0.3,0,0.5,0,0.8c0,5.4,0,10.8-0.1,16.2l4.1-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M161.8,534.8
        C161.8,534.8,161.8,534.8,161.8,534.8l-4-0.5h0c0,0.8,0.1,1.7,0.1,2.5c0.4,40.1,0.7,80.1,0.9,120l4-0.1
        c-0.2-39.9-0.5-79.9-0.9-120C161.9,536.1,161.8,535.5,161.8,534.8l1.4,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M226.6,617.2h0.6
        c0-0.1,0-0.2,0-0.2c-0.1-26.7-0.3-53.6-0.8-80.8c-0.4-11.4-0.9-21.3-1.5-29.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M229.8,658.3h-0.2
        c0,0.1,0,0.2,0,0.3l0.2,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M227.2,617.2
        c0,13.8,0,27.4-0.1,41h1.8h0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M228.9,658.3
        C228.9,658.3,228.9,658.3,228.9,658.3v0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M226.6,623
        c0,9.3,0,18.5,0,27.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M164.4,744.7
        c-0.1-29.3-0.1-58.6-0.3-88l-1.4,0c0.2,29.4,0.3,58.7,0.3,88"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="168" y1="656.7" x2="164.1" y2="656.7"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="209.4,640 168,640 
        168,656.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M163.4,548
        c0.3,36.3,0.6,72.5,0.8,108.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M84.3,530.9l0.9,72.7
        c5.6,1.5,11.1,3.2,16.5,5.1l0,0c11.1,4,19.6,9.9,25.4,17.8c3.8,5.2,6.4,11.3,7.9,18.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
        c0,0.1,0,0.1,0,0.2c0.3,1.8,0.6,3.5,0.8,5.3c0.1,1,0.3,2.1,0.4,3.1c-0.8-0.4-1.4-0.7-2.1-1.2c-2.5-1.8-4.9-3.4-7.2-4.8
        c0.2,1.3,0.4,2.7,0.6,4c2,14.2,1.9,28-0.4,41.3c0,0,0,0,0,0.1c-3.2,15.6-14.1,27.2-32.7,34.7c0.4,2.6,0.7,5.1,1,7.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M126.8,646.5
        c-3.3-16.1-13.6-27.2-30.7-33.3c-3.3-1.2-6.8-2.3-10.2-3.3c-0.2-0.1-0.4-0.1-0.6-0.2l0.1,3.9c2.9,0.9,5.7,1.8,8.6,2.8
        c15,5.3,24.3,14.8,28.1,28.3h0c1.6,0.8,3.3,1.8,5,2.9c0-0.3-0.1-0.6-0.1-0.8C126.9,646.7,126.8,646.6,126.8,646.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M122.7,647.6
        c-0.2-1-0.4-2-0.7-2.9l-12.5-5.5c2.2,3.4,3.8,7.4,4.8,11.8c0,0,0,0.1,0,0.1c0.2,1.4,0.4,2.7,0.6,4.1c1.6,11.7,1.5,23.1-0.3,34.1
        l0,0c-2.4,11.3-9.6,19.7-21.6,25.1c-0.1,0-0.2,0.1-0.3,0.1c0.2,1.7,0.5,3.3,0.7,5c0.2,1.1,0.3,2.3,0.5,3.4c0,0.2,0.1,0.4,0.1,0.5
        c16.3-6.7,26-17.4,29.1-32c0,0,0-0.1,0-0.1c2-12.5,2.1-25.4,0.3-38.8c-0.2-1.5-0.5-3-0.7-4.6C122.7,647.9,122.7,647.8,122.7,647.6
        z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M142.2,632.6
        c0.7,0,1.1-0.4,1.1-1.1v-4.1h-4.2v4.1c0,0.7,0.4,1.1,1.1,1.1H142.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M143.7,623.3
        c2.3,0,3.5-1.2,3.5-3.4v-7c0-2.3-1.2-3.4-3.5-3.5h-0.4v13.9H143.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M143.3,609.4v-2.9
        c0-0.7-0.4-1.1-1.1-1.1h-1.9c-0.7,0-1.1,0.4-1.1,1.1v2.9v13.9v4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="143.3" y1="627.4" x2="143.3" y2="623.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M139.1,609.4
        c-0.4,0-0.7,0-0.9,0.1c-0.1,0-0.2,0.1-0.3,0.1c-1.4,0.4-2.1,1.5-2.1,3.3v7c0,1.8,0.7,2.9,2.1,3.3c0.1,0,0.2,0,0.3,0.1
        c0.3,0.1,0.6,0.1,0.9,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M84.3,530.9
        c-2.2,0.1-4.4,0.3-6.5,0.5l0.8,70.3l-2.2-3c-0.5-0.7-0.8-1.7-0.9-3.2l-0.8-63.9l-4.2,0.1l1.1,74.3c0.2,0,0.4,0.1,0.6,0.1
        c2.3,0.5,4.5,1.1,6.7,1.7c2.1,0.6,4.2,1.2,6.3,1.8l-0.1-6.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M71.6,606
        c-0.3-0.1-0.5-0.1-0.8-0.2c-4.4-1-8.8-1.9-13.3-2.8c0,1.3,0,2.6,0,3.9c4.3,0.8,8.5,1.6,12.6,2.6c0.4,0.1,0.9,0.2,1.3,0.3
        c0.1,0,0.1,0,0.2,0.1c2.1,0.5,4.1,1,6.1,1.5c2.2,0.6,4.4,1.2,6.5,1.8c0.3,0.1,0.7,0.2,1,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M58.2,616.8
        c4.1,0.6,8.2,1.4,12.2,2.4c2.6,0.6,5.1,1.3,7.6,1.9c2.2,0.6,4.3,1.3,6.5,1.9c0.3,0.1,0.6,0.2,0.9,0.3v-9.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M58.5,623.3
        c10.2,0.3,19.1,3.6,26.9,9.7c1.5,1.2,3,2.5,4.4,3.9c9.2,9.1,13.7,20.2,13.7,33.1c0,12.9-4.6,24-13.7,33.1
        c-8,8-17.5,12.5-28.4,13.5l0.5,13.2l5.3-6.5c0.2-0.2,0.4-0.4,0.6-0.7c2.1-2.1,5.4-3.6,10-4.8c1.2-0.2,2.3-0.5,3.5-0.7
        c3.8-0.8,7.7-1.8,11.5-2.9l0.3-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M85.3,623.3
        c1.8,0.6,3.5,1.1,5.2,1.7c8.5,3.1,14.9,7.8,19,14.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M84,650.3
        c4.3,5.7,6.4,12.3,6.4,19.8c0,9.3-3.3,17.3-9.9,23.9c-6.6,6.6-14.5,9.9-23.8,9.9c-9.3,0-17.3-3.3-23.8-9.9
        c-2.7-2.6-4.8-5.5-6.4-8.6c-2.3-4.6-3.5-9.6-3.5-15.2c0-5.4,1.1-10.4,3.3-14.8c1.6-3.2,3.8-6.2,6.5-9c6.6-6.6,14.5-9.9,23.8-9.9
        c8.6,0,16,2.8,22.3,8.4c0.5,0.5,1,1,1.6,1.5C81.7,647.5,82.9,648.9,84,650.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M76,644.7
        c1.1,0.8,2.2,1.8,3.2,2.8c1.9,1.9,3.5,3.9,4.8,6c2.1,3.3,3.4,6.9,4.1,10.8c0,0,0,0,0,0c0.3,1.8,0.4,3.7,0.4,5.6
        c0,1.9-0.1,3.8-0.4,5.6c0,0,0,0,0,0c-1.1,6.4-4,12-8.9,16.9c-2.3,2.3-4.8,4.2-7.4,5.6c-3.3,1.8-6.9,3-10.7,3.5h0
        c-1.4,0.2-2.9,0.3-4.4,0.3c-1.2,0-2.3,0-3.4-0.1c-7.1-0.7-13.3-3.6-18.6-8.7l0,0c-0.2-0.2-0.3-0.3-0.5-0.5
        c-2.6-2.6-4.7-5.5-6.2-8.5c0,0,0,0,0,0c-2.1-4.2-3.1-8.9-3.1-14c0-5.1,1-9.7,3.1-13.9c1.5-3.1,3.6-5.9,6.2-8.6
        c0.2-0.2,0.3-0.3,0.5-0.5v0c6.1-5.9,13.5-8.8,22-8.8c1.5,0,2.9,0.1,4.3,0.3h0c3.8,0.5,7.4,1.6,10.7,3.4l0,0
        C73.2,642.7,74.6,643.7,76,644.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M88,664.4l-4.1-1.3l0,0h0
        l-6.2,0.6l-0.9,0.1l-7.3,0.7c-1,0.1-1.9-0.3-2.6-1.1c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2l-0.2-0.3
        c-0.1-0.1-0.1-0.2-0.2-0.3c-0.5-0.9-0.6-1.8-0.2-2.8l0.6-1.5l0.6-1.5l1.2-2.7l0.4-1.1l0.4-1l2.4-5.6l0,0l0-4.3"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="70.9,651.7 
        70.1,652.3 69.2,652.9 70.6,653 70.6,653 73.3,653.2 71.8,651.8 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M71.7,646.3
        c1.7,1.1,3.3,2.4,4.8,3.9c3.7,3.7,6.2,8,7.4,12.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M70.6,653l-0.4,3.9l-0.1,1.5
        l2-1.3c0.3-0.2,0.5-0.4,0.6-0.6c0.2-0.3,0.3-0.6,0.4-1c0,0,0,0,0,0l0.2-2.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M72.7,656.4
        c1.9,2.2,3.3,4.6,4,7.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M73.1,655.4
        c2.2,2.5,3.8,5.2,4.6,8.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M88.1,638.6
        c8.7,8.7,13,19.2,13,31.4c0,12.3-4.3,22.8-13,31.5c-7.6,7.6-16.5,11.8-26.8,12.8c-1.5,0.1-3.1,0.2-4.7,0.2c-1.2,0-2.4,0-3.6-0.1
        c-10.8-0.8-20.1-5.1-27.9-12.9c-8.7-8.7-13-19.2-13-31.5c0-12.3,4.3-22.7,13-31.4c8.7-8.7,19.1-13,31.4-13c0.6,0,1.3,0,1.9,0
        c10.2,0.4,19.1,3.9,26.8,10.4C86.3,636.8,87.2,637.7,88.1,638.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M57.4,597.4c0,1,0,2,0.1,3.1
        c0,0.9,0,1.8,0,2.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M88,664.4L70.4,666
        c-0.5,0-0.9,0.1-1.3,0.1c0,0,0,0-0.1,0.1c-0.5,0.6-0.7,1.5-0.8,2.7c0,0.1,0,0.1,0,0.2v2.1c0,0.1,0,0.2,0,0.2
        c0.1,1.2,0.4,2.1,0.8,2.7c0,0,0,0,0,0c0.4,0,0.9,0,1.4,0.1l17.6,1.6h0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M69.1,666.1c0,0-0.1,0-0.1,0
        c-2,0.1-3.6,0-5-0.3c-0.8-0.1-1.1-0.6-1-1.4c0.2-1.4,0.6-3,1.3-4.8c0,0,0-0.1,0-0.1c0,0,0,0,0,0c-0.7,0.2-1.6,0.2-2.8,0
        c-0.1,0-0.1,0-0.2-0.1l-2-0.6c-0.1,0-0.1,0-0.2-0.1c-1.1-0.5-1.9-1-2.3-1.6c0,0,0-0.1,0-0.1c0,0.1,0,0.1,0,0.1
        c-0.5,1.9-1.1,3.4-1.8,4.7c-0.4,0.7-0.9,0.9-1.6,0.5c-1.3-0.6-2.7-1.5-4.2-2.7c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
        c0,0.8-0.2,1.6-0.8,2.6c0,0.1-0.1,0.2-0.1,0.2l-1.2,1.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.8,0.9-1.6,1.4-2.3,1.6c0,0,0,0-0.1,0
        c0,0,0.1,0.1,0.1,0.1c1.6,1.1,2.9,2.1,3.9,3.1c0.5,0.5,0.5,1.1,0,1.7c-1,1-2.2,2.1-3.9,3.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0.1,0
        c0.7,0.2,1.5,0.7,2.3,1.7c0.1,0.1,0.1,0.1,0.2,0.2l1.2,1.7c0.1,0.1,0.1,0.1,0.1,0.2c0.6,1,0.9,1.9,0.8,2.7c0,0,0,0,0,0.1
        c0,0,0.1,0,0.1-0.1c1.6-1.2,2.9-2.1,4.2-2.7c0.7-0.4,1.2-0.2,1.6,0.5c0.7,1.2,1.3,2.8,1.8,4.7c0,0,0,0.1,0,0.1c0,0,0,0,0-0.1
        c0.4-0.7,1.2-1.2,2.2-1.6c0.1,0,0.1-0.1,0.2-0.1l2-0.6c0.1,0,0.1,0,0.2-0.1c1.1-0.3,2.1-0.3,2.8,0c0,0,0.1,0,0.1,0
        c0,0,0-0.1,0-0.1c-0.7-1.8-1.1-3.4-1.3-4.8c-0.1-0.8,0.3-1.2,1-1.4c1.4-0.3,3-0.4,5-0.3c0,0,0.1,0,0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M62.4,668.4
        c-0.4,0.4-0.7,1-0.7,1.6c0,0.7,0.2,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7c0.6,0,1.2-0.2,1.7-0.7c0.4-0.5,0.7-1,0.7-1.7
        c0-0.6-0.2-1.2-0.7-1.6c-0.5-0.5-1-0.7-1.7-0.7C63.4,667.8,62.9,668,62.4,668.4z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="66.7" y1="658.1" x2="70.1" y2="658.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="67.3,656.6 
        68.9,656.8 69.2,654 68.5,654 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="70.2" y1="656.9" x2="68.9" y2="656.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M71.7,641.9l-6.9,16.3
        c-0.2,0.4-0.4,0.9-0.5,1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M57.9,661
        c-0.6,0.3-1,0.7-1.1,1.3c-0.2,0.6-0.2,1.2,0.1,1.8c0.3,0.6,0.7,0.9,1.3,1.1c0.6,0.2,1.2,0.2,1.8-0.1c0.6-0.3,1-0.8,1.2-1.4
        c0.2-0.6,0.2-1.2-0.1-1.8c-0.3-0.5-0.8-0.9-1.4-1.1C59.1,660.6,58.5,660.6,57.9,661z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M56.7,657.1
        c0.1-0.4,0.2-0.8,0.3-1.2c0,0,0-0.1,0-0.1l3.9-17.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M55.3,654.2l-0.9-0.1
        c-0.1,0-0.2,0-0.3,0.1l-2.6,1.1l-0.2,0l-4-0.3l-1-0.1l1.9,1.6l3.5,0.3c0.1,0,0.2,0,0.3-0.1l0.7-0.2l1.9-0.8l0.9,0.1l0.3-1.4
        L55.3,654.2V653l1.1-2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M55.3,655.7L55.3,655.7
        l-0.1,0.4c-0.2,1-0.8,1.7-1.8,2.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1l-0.3,0.1c-0.1,0-0.2,0-0.3,0.1
        c-1,0.2-1.9,0-2.7-0.6l-1.6-1.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="52.5,656.5 54,656.5 
        55.3,655.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M59.3,667.4
        c0.7,0.7,1.1,1.6,1.1,2.6c0,1.1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.7,1.1c-1,0-1.9-0.4-2.6-1.1c-0.7-0.7-1.1-1.6-1.1-2.6
        c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1C57.7,666.3,58.6,666.7,59.3,667.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M52.1,663.8
        c-0.5-0.4-1.1-0.5-1.7-0.4c-0.6,0.1-1.1,0.4-1.5,1c-0.4,0.5-0.5,1.1-0.4,1.7c0.1,0.7,0.4,1.2,0.9,1.5c0.5,0.4,1.1,0.5,1.7,0.4
        c0.6-0.1,1.1-0.4,1.5-0.9c0.4-0.5,0.5-1.1,0.4-1.7C52.9,664.7,52.6,664.2,52.1,663.8z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="68.9" y1="652.9" x2="69.2" y2="652.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="70.1" y1="652.3" x2="69.4" y2="651.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M34.6,647.1l4.1,1.3h0l0,0
        c1.7-1.5,3.6-2.7,5.5-3.7c3.7-1.8,7.8-2.8,12.3-2.8c0.6,0,1.2,0,1.8,0.1l0-0.1l2.5-3.5"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="58.4,642 57.4,646.3 
        56.3,651 55.6,654.3 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M57.4,646.3
        c-4-0.1-7,0.4-8.9,1.6c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.1,0,0.3,0,0.4v0.5v0.3l1.1,0.5
        l0.2,0.1l1.9,4.7"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="47.9,651.1 
        47.4,651.1 47.3,652.2 48.4,652.3 48.4,651.1 47.9,651.1 49.1,650.5 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="47.4,651.1 
        47.4,651.1 46,651 45.8,654.5 46.2,654.8 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="48.1,649.7 
        46.6,650.3 47.4,651.1 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="48.1,649.2 45,649 
        44.1,649.6 44.1,650.1 44.9,649.5 45.8,649.6 46.6,650.3 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="38.8,648.4 
        43.2,652.2 43.3,650.7 44.1,650.1 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="46" y1="651" x2="43.3" y2="650.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M44.3,666
        c-0.4-0.2-0.7-0.5-1.2-0.7L28,656.2l2.5,3.5l0.1,0.1l5.3,3.1l0.8,0.5l6.4,3.8c0.9,0.5,1.4,1.3,1.4,2.3c0,0.1,0,0.2,0,0.3
        c0,0,0,0.1,0,0.2v0.4c0,0.1,0,0.2,0,0.3c-0.1,1-0.6,1.8-1.4,2.4l-6.4,3.8l-0.8,0.5l-5.3,3.2l-0.1,0.1L28,684l15.2-9.1
        c0.4-0.2,0.8-0.5,1.2-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M34.6,647.1l13.3,11.6
        c0.4,0.3,0.7,0.6,1.1,0.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="47.2" y1="654.9" x2="47.3" y2="652.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="45.8" y1="654.5" x2="43.2" y2="652.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="28" y1="656.2" x2="27.9" y2="656.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M141.9,520.7v-4.1h-4.2v4.1
        c0,0.7,0.4,1.1,1.1,1.1h2C141.6,521.8,141.9,521.4,141.9,520.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M141.9,516.6v-2.9v-13.9v-4.1
        c0-0.7-0.4-1.1-1.1-1.1h-2c-0.7,0-1.1,0.4-1.1,1.1v4.1v13.9v2.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M141.9,513.7h0.4
        c2.3,0,3.5-1.1,3.5-3.4v-7c0-2.3-1.2-3.5-3.5-3.5h-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M137.7,499.8
        c-0.3,0-0.6,0.1-0.9,0.1c-1.6,0.3-2.4,1.4-2.4,3.3v7c0,1.9,0.8,3,2.4,3.4c0.3,0,0.6,0.1,0.9,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M74.8,531.6c1-0.1,2-0.2,3-0.2
        l-1.5-127.4c-1,0.1-2,0.2-3,0.4L74.8,531.6L74.8,531.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M127.8,756.8
        c-2.3,0-4.5,0-6.8,0v1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M147.2,756.8c-2.3,0-4.7,0-7,0
        c-4.2,0-8.3,0-12.4,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M130.3,750.7h-0.5h-4.2H125
        h-0.3c-0.2,0-0.4,0.1-0.4,0.4v1c0,0.2,0.1,0.4,0.4,0.4h5.7c0.2,0,0.4-0.1,0.4-0.4v-1c0-0.2-0.1-0.4-0.4-0.4H130.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M128.9,753.9
        c-3.8,0-7.5,0-11.3,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M117.6,750.7h-0.2
        c-0.2,0-0.3,0.1-0.3,0.4v1c0,0.2,0.1,0.4,0.3,0.4h5.4c0.2,0,0.4-0.1,0.4-0.4v-1c0-0.2-0.1-0.4-0.4-0.4h-0.2h-0.5h-3.9H117.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M139.8,757.8
        c-3.9,0-7.8,0-11.8,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M101.5,751.9l-0.5-1.3
        c-0.1,0-0.3,0-0.4,0h-1.9v3.8c-0.1,1-0.6,1.5-1.7,1.5h-2.7h-2.7c-2.9-0.2-5.5-0.5-7.8-0.9c-1.3-0.2-2.5-0.5-3.7-0.8
        c-3.9-1-6.9-2.5-8.8-4.3c-1.5-1.5-2.8-3.3-3.9-5.3l-2.7-5.4v11.8c0.9,2.1,2.2,4.1,3.9,5.9c5.3,5,14.6,7.7,28.1,8.3l4.9-8.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M102.6,754.5v-0.7v-1.1
        c0-1-0.4-1.7-1.1-1.9c-0.2-0.1-0.3-0.1-0.5-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M98.6,750.6h-1.2h-1.2
        c-1.4,0-2.1,0.7-2.1,2.1v3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M96.5,749l0.7,0
        c-0.3-2.5-0.6-5-0.9-7.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M67.8,722.7
        c2.7,8.9,5.7,15.6,9,20.2c2.6,3.8,8.9,5.8,18.9,6.1l0.8,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M83.7,755V754
        c-1.3-0.2-2.5-0.5-3.7-0.8v0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M83.7,754v-4
        c0-0.5-0.3-0.8-0.8-0.9l-2.1-0.2c-0.5-0.1-0.8,0.2-0.8,0.7v3.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M71.6,760
        c-0.4-0.2-0.7-0.5-1.1-0.7l-0.3,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M149.7,750L97.2,749
        c0.1,0.5,0.1,1,0.2,1.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="96.6" y1="766.4" x2="96.6" y2="765"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M117.3,753.9
        c-4.9,0-9.8-0.1-14.7-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M121,756.8
        c-6.1,0-12.3,0-18.4-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M64.3,680.6
        c0.2,0.4,0.3,0.9,0.5,1.3l7,16.3l0-4.3l0,0l-2.4-5.7l-0.4-0.9l-2.9-6.7c-0.4-1-0.3-1.9,0.2-2.8c0.1-0.1,0.1-0.2,0.2-0.3
        c0-0.1,0.1-0.1,0.1-0.2l0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.7-0.8,1.5-1.1,2.6-1.1l7.3,0.7l0.9,0.1l6.2,0.5H84l4.1-1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M77.7,676.5
        c-0.7,2.5-1.9,4.8-3.6,6.9c-0.6,0.8-1.2,1.5-1.9,2.2c-0.9,0.9-1.9,1.7-2.9,2.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M76.8,676.4
        c-0.8,2.6-2.1,4.9-3.8,7c-0.4,0.5-0.9,1-1.4,1.5c-0.8,0.8-1.7,1.6-2.6,2.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M83.9,677
        c-0.5,2.3-1.4,4.4-2.5,6.4c-1.3,2.3-2.9,4.5-4.9,6.5c-1.5,1.5-3.1,2.8-4.8,3.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M94.6,727.8
        c-0.2-1.5-0.4-3-0.6-4.4c-0.6,0.3-1.2,0.5-1.9,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M92.7,714.5
        C92.7,714.4,92.7,714.4,92.7,714.5"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="61.9,729.9 
        60.8,731.3 62.2,734.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M61.4,716.7
        c-1.6,0.1-3.1,0.2-4.8,0.2c-1,0-2,0-3-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M56.8,683.1
        c0,0.1,0.1,0.2,0.1,0.4c0.1,0.3,0.2,0.7,0.2,1l3.9,17.2v0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M57.5,694l-0.5-2
        c-0.1,0-0.3,0-0.4,0c-5,0-9.4-1.5-13.2-4.4l-4.7,4.1c4.4,3.7,9.4,5.8,15.2,6.3c0.9,0.1,1.8,0.1,2.7,0.1c0.6,0,1.2,0,1.8-0.1
        L57.5,694z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M57.1,692l-0.2-0.9
        c-0.1,0-0.2,0-0.2,0c-4.7,0-8.9-1.4-12.5-4.1l-0.7,0.6"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="58.5,698.1 
        58.5,698.1 61,701.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M58.2,674.9
        c-0.6,0.2-1.1,0.6-1.4,1.1c-0.3,0.6-0.3,1.2-0.1,1.8c0.2,0.6,0.6,1.1,1.2,1.4c0.6,0.3,1.2,0.3,1.8,0.1c0.6-0.2,1.1-0.6,1.4-1.1
        c0.3-0.6,0.3-1.2,0.1-1.8c-0.2-0.6-0.6-1.1-1.2-1.3C59.4,674.7,58.8,674.7,58.2,674.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M53,674.7
        c0.1-0.6,0-1.2-0.4-1.7c-0.4-0.5-0.9-0.9-1.5-1c-0.6-0.1-1.2,0-1.7,0.4c-0.5,0.4-0.9,0.9-1,1.5c-0.1,0.7,0.1,1.2,0.5,1.7
        c0.4,0.5,0.9,0.8,1.5,0.9c0.7,0.1,1.2,0,1.8-0.4C52.6,675.9,52.9,675.4,53,674.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M44.2,687.1l5.5-4.8
        c0.8-0.7,1.7-0.9,2.7-0.6c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.8,0.3,1.3,0.8,1.6,1.5
        c0.1,0.2,0.2,0.4,0.2,0.6l1.6,7.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M49,680.5
        c-0.3,0.3-0.7,0.6-1,0.9L34.6,693"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M36.6,676.8
        c-0.7-2.1-1-4.4-1-6.7c0-2.4,0.3-4.6,1-6.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M35.8,677.2
        c-0.8-2.2-1.1-4.6-1.1-7.2c0-2.5,0.4-4.9,1.1-7.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="34.7,693.1 
        38.8,691.8 38.8,691.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M30.5,659.6
        c-1.3,3.2-1.9,6.7-1.9,10.4c0,3.8,0.6,7.2,1.9,10.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="27.9" y1="684" x2="28" y2="684"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M70.5,759.2
        c-0.7-0.5-1.4-1.1-2-1.7c-1.5-1.4-2.7-3-3.6-4.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="64.6" y1="739.1" x2="64.4" y2="738.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M227.1,658.3
        c-0.1,17.5-0.3,34.9-0.6,52.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M158,358.7
        c-1.1-27.9-3-53.5-5.8-76.8c-0.1-0.5-0.1-0.9-0.2-1.3c-0.3-2.2-0.9-4.5-1.8-7c-0.1-0.2-0.1-0.4-0.2-0.6l-0.2-0.6
        c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.8-1.9-1.5-3.9-2.2-5.8c0.7,5.4,1.3,11.1,1.9,16.9c2.2,26.7,4.2,54.9,6,84.5
        c0-1.1,0.1-2.1,0.5-3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M154.4,393.5
        c0-0.7,0-1.4,0-2.2v0c0-3.3,0-6.6-0.1-9.9c0-2.7-0.1-5.4-0.1-8.1l-0.3-7.3c-1.4-28.6-3-56.2-4.9-83c-0.2-1.9-0.3-3.8-0.5-5.6
        l-3.3-17.8c0,0.8,0.1,1.6,0.2,2.4l0.2,1.6c0.7,6.2,1.3,12.7,1.9,19.5c2.4,35.1,4.5,71.7,6.2,109.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="156.1" y1="370.2" x2="154.3" y2="381.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="154.4,391.3 
        156.4,378.9 156.4,377.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M207.7,448.3
        c-12-13.9-25.9-28.3-41.6-43.2c0,0-0.1-0.1-0.1-0.1l0,0c-0.4-0.4-0.8-0.8-1.2-1.2c-0.2-0.3-0.5-0.5-0.7-0.8
        c-1.1-1.1-2.1-2.1-3.2-3.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.4-0.4-0.5-0.5c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6
        c-0.2-0.2-0.3-0.4-0.5-0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M151.7,395
        c-0.1-1.8-0.7-3.2-1.7-4.2l-3-2.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.6-0.4c-1.2-0.8-2.5-1.2-3.9-1.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0
        c-0.2,0-0.5,0-0.7,0c-7.5-0.5-15-0.7-22.5-0.6c-2.8,0.2-4.9,1.5-6.3,4l-4.4,9.8l-0.7,1.8c12.5-0.2,25.2,0.2,38,1.3
        c0.1,0,0.1,0,0.2,0l5,1.8c1,0.3,1.5-0.1,1.5-1v-7C151.8,395.4,151.8,395.2,151.7,395z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M166.5,389l-1.5,12.7
        c-0.2,1.4,0.1,2.5,0.9,3.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="159.8" y1="398.8" x2="161.6" y2="384"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M140.5,395.2
        c-0.1-2-0.2-4.1-0.3-6.1c-0.2-1-1-1.6-2.1-1.8c-5.9-0.3-11.9-0.5-17.9-0.5c-0.2,0-0.3,0-0.4,0c-1.8,0.1-3,0.8-3.8,2.1
        c-0.1,0.1-0.1,0.2-0.2,0.3c-1.4,2.9-2.6,6.1-3.6,9.5c8.6-0.1,17.4,0.2,26.6,0.8h0c0.9,0,1.4-0.3,1.6-1.1c0-0.1,0.1-0.3,0.1-0.5
        c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2C140.6,396.8,140.6,396,140.5,395.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M118.8,373.1
        c11-7.7,17.2-17.6,18.8-29.6c0.1-1.1,0.2-2.2,0.3-3.2l0-1.2c0-1.3,0.1-2.6,0.1-3.9c0.2-11.3-0.2-22.8-1.2-34.5c0,0,0-0.1,0-0.1
        c-1.3-8.6-4.3-16.3-9-23.1c-0.8-1.2-1.7-2.3-2.6-3.4c0,0,0,0,0,0c-5.6-6.4-19-10.9-40.4-13.7l0.2,3.6c14.7,1.5,24.9,5.5,30.6,12.1
        c0,0,0,0,0,0c0.9,1.1,1.7,2.2,2.5,3.3c4.5,6.6,7.4,14,8.7,22.3c0,0,0,0.1,0,0.1c1,11.8,1.3,23.4,1.1,34.7c0,0.9,0,1.7-0.1,2.5
        l0,1.2c0,0.5-0.1,1-0.1,1.5c-1.1,12.4-7.2,22.5-18.3,30.3c0,0,0,0-0.1,0c-6.7,4.3-15.7,8.3-26.9,12l0,2.8l0.8-0.3
        C98.6,382.9,110.4,378.5,118.8,373.1C118.8,373.1,118.8,373.1,118.8,373.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M85.1,263.8l0.2,3.1h0
        c0.1,0,0.1,0,0.1,0c12.6,1.3,21.6,5,27,11.2c0,0,0,0,0,0c0.9,1,1.7,2.1,2.4,3.1c4.3,6.2,7.1,13.3,8.3,21.2c0,0,0,0.1,0,0.1
        c0.9,11.2,1.3,22.1,1,32.8c0,0.8,0,1.6-0.1,2.4l0,1.1c0,0.5-0.1,0.9-0.1,1.4c-1,11.7-6.8,21.3-17.4,28.7c0,0,0,0-0.1,0
        c-6.5,4.2-14.6,7.8-24,10.8l0,4.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M101.9,361.9
        c-5.4,3.5-11.9,6.5-19.5,9.1l0.1,5.3c8.7-2.8,16.1-6.2,22.2-10.1c10.2-7.1,15.6-16.2,16.3-27.5l0-1.1c0.1-2.3,0.1-4.7,0.1-7.1
        l-0.7-0.7c-0.4-0.4-0.6-1-0.7-1.7c0-4.4-0.1-8.8-0.3-13.2c0-0.7,0.2-1.2,0.5-1.4l0.8-0.7c-0.2-3.3-0.4-6.7-0.7-10
        c-1.1-7.3-3.7-13.9-7.8-19.7c-0.7-1-1.5-1.9-2.3-2.9c-4.9-5.5-13.1-9-24.7-10.6l-0.3,3.8c1.8,0.2,3.4,0.5,5,0.9
        c7.5,1.6,13.1,4.5,16.7,8.5c0.7,0.8,1.3,1.7,1.9,2.6c3.6,5.3,6,11.4,7,18.1c0.9,10.9,1.2,21.5,0.9,31.9l0,1
        C115.8,347,111,355.4,101.9,361.9C101.9,361.9,101.9,361.9,101.9,361.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M55.7,406.6
        c3.5-0.6,7.8-1.2,13-1.8l-0.2-20.7l9.1-2.9c1.7-0.5,3.3-1,4.9-1.5l0-3.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M82.3,371
        c-1.6,0.5-3.2,1.1-4.9,1.6c-7.4,2.3-14.7,4-21.9,5.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M109,387.1
        c-0.2,0.3-0.4,0.6-0.5,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M107.8,398.8c0,0-0.1,0-0.1,0"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M68.7,404.8L68.7,404.8
        c1.5-0.2,3-0.4,4.6-0.5l-0.1-8.6l1-3l1.9-3.8l6.4-2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M76.1,388.8l0.2,15.2
        c2.1-0.2,4.3-0.4,6.5-0.6l-0.2-16.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M145.2,259.5
        c-2.4-3.9-5.3-8-8.8-12.1c0.1,1.8,0.2,3.7,0.4,5.8c0,0.4,0.1,0.8,0.1,1.3l0.3,3.8c3.2,43.8,5.6,84.9,7.3,123.4
        c0,1,0.1,2.1,0.1,3.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M71.8,296.7L71.8,296.7
        l-7,16.3c-0.2,0.4-0.4,0.9-0.5,1.3c0,0,0,0.1,0,0.1c-0.7,1.8-1.1,3.4-1.3,4.8c-0.1,0.8,0.3,1.2,1,1.4c1.3,0.3,3,0.3,4.9,0.3
        c0.1,0,0.1,0,0.1,0c0.4,0,0.9,0,1.3-0.1l17.6-1.6c0,0,0,0,0,0l-4.1-1.4h0l-6.2,0.6l-0.9,0.1l-7.3,0.6c-1,0.1-1.9-0.3-2.6-1.1
        c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2l-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.5-0.9-0.6-1.8-0.2-2.8l0.6-1.5l0.6-1.5
        l1.2-2.7l0.4-1.1l0.4-1l2.4-5.6l0,0L71.8,296.7c2.6,1.4,5.1,3.3,7.4,5.6c4.8,4.8,7.8,10.5,8.9,16.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M71.8,301.1
        c1.7,1.1,3.3,2.4,4.8,3.9c3.8,3.8,6.2,8,7.4,12.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M73.1,310.2l0.2-2.2l-2.7-0.2
        h0l-0.4,3.8l-0.1,1.5l2-1.3c0.3-0.2,0.5-0.4,0.6-0.6C72.9,310.9,73,310.6,73.1,310.2C73.1,310.2,73.1,310.2,73.1,310.2z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="73.3,308 71.8,306.5 
        70.9,306.5 70.1,307.1 69.2,307.7 70.6,307.8 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M73.1,310.2
        c2.2,2.5,3.8,5.2,4.6,8.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M72.7,311.2
        c1.9,2.2,3.3,4.6,4,7.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M88,319.2
        c0.3,1.8,0.4,3.7,0.4,5.6c0,1.9-0.1,3.8-0.4,5.6c0,0,0,0,0,0c-1.1,6.4-4,12-8.9,16.9c-2.3,2.3-4.8,4.2-7.4,5.6
        c-3.3,1.8-6.9,3-10.7,3.5c0,0,0,0,0,0c-1.4,0.2-2.9,0.3-4.4,0.3c-1,0-1.9,0-2.9-0.1c-7.3-0.6-13.7-3.5-19.1-8.7l0,0
        c-0.2-0.2-0.3-0.3-0.5-0.5c-2.6-2.6-4.7-5.5-6.2-8.5c0,0,0,0,0,0c-2.1-4.2-3.1-8.9-3.1-14c0-5.1,1-9.7,3.1-13.9
        c1.5-3.1,3.6-5.9,6.2-8.6c0.2-0.2,0.3-0.3,0.5-0.5v0c4.1-3.9,8.8-6.6,14-7.9c2.6-0.6,5.2-1,8.1-1c1.5,0,2.9,0.1,4.3,0.3h0
        c3.8,0.5,7.4,1.6,10.7,3.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M88,330.4l-17.6-1.6
        c-0.5-0.1-0.9-0.1-1.4-0.1c0,0-0.1,0-0.1,0c-2-0.1-3.6,0-5,0.3c-0.8,0.2-1.1,0.6-1,1.4c0.2,1.4,0.6,3,1.3,4.8c0,0.1,0,0.1,0.1,0.1
        c0.2,0.4,0.3,0.8,0.5,1.3l7,16.3l0-4.3l0,0l-2.4-5.7L69,342l-2.9-6.7c-0.4-1-0.3-1.9,0.2-2.8c0.1-0.1,0.1-0.2,0.2-0.3
        c0-0.1,0.1-0.1,0.1-0.2l0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.7-0.8,1.5-1.1,2.6-1.1l7.3,0.7l0.9,0.1l6.2,0.5H84L88,330.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M83.9,331.8
        c-1.2,4.8-3.6,9.1-7.4,12.9c-1.5,1.5-3.1,2.8-4.7,3.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M80.5,348.7
        c-6.6,6.6-14.5,9.9-23.8,9.9c-0.9,0-1.8,0-2.7-0.1c-8.1-0.6-15.2-3.8-21.1-9.8c-2.7-2.6-4.8-5.5-6.4-8.6
        c-2.3-4.6-3.5-9.7-3.5-15.2c0-5.4,1.1-10.3,3.3-14.8c1.6-3.2,3.8-6.2,6.5-9c4.5-4.5,9.8-7.5,15.6-8.9c2.6-0.6,5.3-0.9,8.2-0.9
        c9.3,0,17.2,3.3,23.8,9.9c6.6,6.6,9.9,14.5,9.9,23.8C90.4,334.1,87.1,342.1,80.5,348.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M76.8,331.2
        c-1,3.2-2.7,6-5.2,8.6c-0.8,0.8-1.7,1.6-2.6,2.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M77.7,331.3
        c-1,3.4-2.8,6.4-5.5,9.1c-0.9,0.9-1.9,1.7-2.9,2.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M101.1,324.8
        c0,12.3-4.3,22.8-13,31.5c-8.7,8.7-19.2,13-31.4,13c-0.6,0-1.2,0-1.8,0c-11.5-0.4-21.4-4.8-29.6-13c-8.7-8.7-13-19.2-13-31.5
        c0-12.3,4.3-22.7,13-31.4c6.4-6.4,13.9-10.5,22.3-12.2c2.9-0.6,6-0.9,9.1-0.9c12.3,0,22.8,4.3,31.4,13
        C96.8,302.1,101.1,312.5,101.1,324.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M47.4,278.9
        c3-0.6,6.1-0.9,9.3-0.9c12.9,0,24,4.6,33.1,13.7c9.2,9.1,13.7,20.2,13.7,33.1c0,12.9-4.6,24-13.7,33.1
        c-9.1,9.2-20.2,13.7-33.1,13.7c-0.5,0-1.1,0-1.6,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M64.2,314.3L64.2,314.3
        c-0.7,0.3-1.6,0.3-2.8,0c-0.1,0-0.1,0-0.2-0.1l-2-0.6c-0.1,0-0.1,0-0.2-0.1c-1.1-0.5-1.9-1-2.3-1.6c0,0,0-0.1,0-0.1
        c0,0.1,0,0.1,0,0.2c-0.5,1.9-1.1,3.4-1.8,4.7c-0.4,0.7-0.9,0.8-1.6,0.5c-1.3-0.6-2.7-1.5-4.2-2.7c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
        c0,0.8-0.2,1.6-0.8,2.6c0,0.1-0.1,0.1-0.1,0.2l-1.2,1.6c0,0.1-0.1,0.1-0.1,0.2c-0.8,0.9-1.6,1.4-2.3,1.6c0,0,0,0-0.1,0
        c0,0,0.1,0.1,0.1,0.1c1.6,1.1,2.9,2.1,3.9,3.1c0.5,0.5,0.5,1.1,0,1.7c-1,1-2.2,2.1-3.9,3.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0.1,0
        c0.7,0.2,1.5,0.7,2.3,1.7c0.1,0.1,0.1,0.1,0.1,0.2l1.2,1.7c0,0.1,0.1,0.1,0.1,0.2c0.6,1,0.9,1.9,0.8,2.7c0,0,0,0.1,0,0.1
        c0,0,0.1-0.1,0.1-0.1c1.6-1.2,2.9-2.1,4.2-2.7c0.7-0.4,1.2-0.2,1.6,0.5c0.7,1.2,1.3,2.8,1.8,4.7c0,0,0,0.1,0,0.1c0,0,0,0,0-0.1
        c0.4-0.7,1.2-1.2,2.2-1.6c0.1,0,0.1-0.1,0.2-0.1l2-0.7c0.1,0,0.1,0,0.2,0c1.1-0.3,2.1-0.3,2.8,0c0,0,0.1,0,0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M56.8,337.8
        c0.1,0.4,0.2,0.9,0.4,1.3l3.9,17.2v0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M58.5,352.8l-1.4-6
        c-0.1,0-0.3,0-0.4,0c-1.3,0-2.5-0.1-3.7-0.3l0.5,6.2c1,0.1,2.1,0.1,3.2,0.1C57.3,352.9,57.9,352.9,58.5,352.8L58.5,352.8l2.5,3.6"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M57.1,346.8l-0.2-0.9
        c-0.1,0-0.2,0-0.2,0c-1.3,0-2.6-0.1-3.8-0.3c-3.2-0.5-6.1-1.8-8.7-3.8l-0.7,0.6c2.8,2.2,6,3.5,9.5,4.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M44.2,341.8l5.6-4.8
        c0.8-0.7,1.7-0.9,2.7-0.6c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c1,0.4,1.6,1.1,1.8,2.1l1.6,7.1"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M61.2,331.2
        c-0.2-0.6-0.6-1.1-1.2-1.3c-0.6-0.3-1.2-0.3-1.8-0.1c-0.6,0.2-1,0.6-1.3,1.1c-0.3,0.6-0.3,1.2-0.1,1.8c0.2,0.6,0.6,1.1,1.1,1.4
        c0.6,0.3,1.2,0.3,1.8,0.1c0.6-0.2,1.1-0.6,1.4-1.1C61.3,332.4,61.4,331.8,61.2,331.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M69.1,328.8
        C69,328.7,69,328.7,69.1,328.8c-0.5-0.6-0.8-1.5-0.9-2.7c0-0.1,0-0.1,0-0.2v-2c0-0.1,0-0.2,0-0.2c0.1-1.2,0.4-2.1,0.8-2.7
        c0,0,0,0,0.1-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M65.8,323.2
        c-0.5-0.5-1-0.7-1.7-0.7c-0.6,0-1.2,0.2-1.7,0.7c-0.4,0.4-0.7,1-0.7,1.6c0,0.7,0.2,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7
        c0.6,0,1.2-0.2,1.7-0.7c0.4-0.5,0.7-1,0.7-1.7C66.4,324.2,66.2,323.7,65.8,323.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M54,327.4
        c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1c1,0,1.9,0.4,2.7,1.1c0.7,0.7,1.1,1.6,1.1,2.6
        c0,1.1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.7,1.1C55.6,328.6,54.7,328.2,54,327.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M53,329.5
        c0.1-0.6,0-1.2-0.4-1.7c-0.4-0.5-0.9-0.9-1.5-1c-0.6-0.1-1.2,0-1.7,0.4c-0.5,0.4-0.9,0.9-1,1.5c-0.1,0.7,0.1,1.2,0.5,1.7
        c0.4,0.5,0.9,0.8,1.5,0.9c0.7,0.1,1.2,0,1.8-0.4C52.6,330.6,52.9,330.1,53,329.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M49,335.3
        c-0.3,0.3-0.7,0.5-1,0.9l-13.3,11.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M51.1,322.8
        c0.6-0.1,1.1-0.4,1.5-0.9c0.4-0.5,0.5-1.1,0.4-1.7c-0.1-0.6-0.4-1.1-0.9-1.5c-0.5-0.4-1.1-0.5-1.7-0.4c-0.6,0.1-1.1,0.4-1.5,1
        c-0.4,0.5-0.5,1.1-0.4,1.7c0.1,0.7,0.4,1.2,0.9,1.5C49.9,322.7,50.5,322.9,51.1,322.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M44.4,320.8
        c-0.4-0.2-0.7-0.5-1.2-0.7L28,310.9l2.5,3.5l0.1,0.1l5.3,3.1l0.8,0.5l6.4,3.8c0.9,0.5,1.4,1.3,1.4,2.3c0,0.1,0,0.2,0,0.3
        c0,0.1,0,0.1,0,0.2v0.4c0,0.1,0,0.2,0,0.3c-0.1,1-0.6,1.8-1.4,2.4l-6.4,3.8l-0.8,0.5l-5.3,3.1l-0.1,0.1l-2.5,3.5l15.2-9.1
        c0.4-0.2,0.8-0.5,1.2-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M36.6,331.6
        c-0.7-2.1-1-4.4-1-6.7c0-2.4,0.3-4.6,1-6.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M35.8,332
        c-0.8-2.2-1.1-4.7-1.1-7.2c0-2.5,0.4-4.9,1.1-7.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="34.7,347.8 
        38.8,346.5 38.8,346.5 43.5,342.4 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M38.8,346.5
        c4.3,3.6,9.1,5.7,14.6,6.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M61.1,316.7
        c-0.3-0.5-0.8-0.9-1.4-1.1c-0.6-0.2-1.2-0.2-1.8,0.1c-0.6,0.3-1,0.7-1.1,1.3c-0.2,0.6-0.2,1.2,0.1,1.8c0.3,0.6,0.7,0.9,1.3,1.2
        c0.6,0.2,1.2,0.1,1.8-0.1c0.6-0.3,1-0.8,1.2-1.4C61.5,317.9,61.4,317.3,61.1,316.7z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="66.7" y1="312.9" x2="70.1" y2="313.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="67.3,311.4 
        68.9,311.6 69.2,308.8 68.5,308.8 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="68.9" y1="311.6" x2="70.2" y2="311.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="69.2" y1="307.7" x2="68.9" y2="307.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="70.1" y1="307.1" x2="69.4" y2="306.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M56.8,311.8
        c0.1-0.4,0.2-0.8,0.3-1.2c0,0,0-0.1,0-0.1l3.9-17.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M55.3,309l-0.9-0.1
        c-0.1,0-0.2,0-0.3,0.1l-2.6,1.1l-0.2,0l-4-0.3l-1-0.1l1.9,1.7l3.5,0.3c0.1,0,0.2,0,0.3-0.1l0.7-0.2l1.9-0.8l0.9,0.1l0.3-1.5
        L55.3,309v-1.2l1.1-2l1.1-4.7c-3.6-0.1-6.3,0.3-8.2,1.3c-0.2,0.1-0.5,0.2-0.7,0.4c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
        c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.1,0,0.3,0,0.4v0.5v0.3l1.1,0.5l0.2,0.1l1.9,4.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M52.5,311.2l1.5,0.1l1.3-0.8
        l-0.1,0.4c-0.2,1-0.8,1.7-1.8,2.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1l-0.3,0.1c-0.1,0-0.2,0-0.3,0.1
        c-1,0.2-1.9,0-2.7-0.6l-1.6-1.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="56.4" y1="305.8" x2="55.6" y2="309"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M34.6,301.8l4.1,1.3l0,0
        c3.1-2.6,6.4-4.4,10.1-5.4c2.4-0.7,5-1,7.7-1c0.6,0,1.2,0,1.8,0.1l0-0.1l2.5-3.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="58.4" y1="296.8" x2="57.5" y2="301.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="48.1,304.5 
        46.6,305.1 47.4,305.8 47.4,305.8 47.9,305.9 49.1,305.3 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="38.8,303.2 43.2,307 
        43.3,305.5 44.1,304.9 44.2,304.4 45,303.8 48.1,304 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="44.1,304.9 45,304.3 
        45.9,304.4 46.6,305.1 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="49.3" y1="302.3" x2="48.9" y2="297.8"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="47.4,305.8 47.4,307 
        48.4,307 48.5,305.9 47.9,305.9 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="46.2,309.6 
        45.8,309.3 43.2,307 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="47.4,305.8 46,305.7 
        45.8,309.3 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="47.2" y1="309.7" x2="47.4" y2="307"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M49,314.4
        c-0.3-0.3-0.7-0.6-1.1-0.9l-13.3-11.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="46" y1="305.7" x2="43.3" y2="305.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="28" y1="310.9" x2="27.9" y2="310.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="28" y1="338.7" x2="27.9" y2="338.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M30.5,314.4
        c-1.3,3.2-1.9,6.7-1.9,10.4c0,3.8,0.6,7.2,1.9,10.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M148.5,277.3
        c-0.4-3.9-0.7-7.7-1.1-11.4c0-0.2,0-0.3-0.1-0.5l0-0.2c0-0.1,0-0.2,0-0.3l-0.7-5.7c0-0.2-0.1-0.4-0.1-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M134.3,267.6
        c0.1-0.1,0.1-0.4,0.1-0.7l-1.5-21.3c0-0.9-0.3-1.4-0.9-1.6h0c0,0-0.1,0-0.1,0c-6.2-1.9-12.7-3.2-19.6-4.1l1,13.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M132.9,268.4
        c0.9,0.4,1.5,0.5,2,0.2c0.5-0.2,0.8-0.8,0.7-1.8l-1.4-20.5l-0.1-0.8c-0.1-1-0.7-1.7-1.9-2.1c-0.2-0.1-0.4-0.1-0.6-0.2
        c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-3.4-1-6.9-1.8-10.5-2.5c-0.2,0-0.4-0.1-0.5-0.1c-2.5-0.5-5-0.8-7.5-1.1
        c0,0,0,0,0,0c-0.4-0.1-0.7-0.1-1.2-0.2l1.1,15.2l0.3,3.9c0.1,1.6,0.8,2.6,1.9,3c0,0,0,0,0,0c2.5,0.8,5.1,1.7,7.8,2.7
        C125.7,265.2,129.3,266.7,132.9,268.4L132.9,268.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M134.3,267.6
        c-0.2,0.1-0.5,0-0.9-0.3c0,0,0,0,0,0c-0.1-0.1-0.3-0.1-0.4-0.2c-1.5-0.7-2.9-1.3-4.3-1.9c-0.5-0.2-1-0.4-1.5-0.7
        c-2.3-0.9-4.5-1.8-6.7-2.6c-1.9-0.7-3.7-1.3-5.5-1.8c-0.7-0.3-1.1-1-1.2-2l-0.2-2l-0.1-0.9l-0.1-1.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M147.4,265.9c0,0,0,0.1,0,0.1
        c0-0.2-0.1-0.4-0.1-0.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="119.2" y1="255" x2="119" y2="255"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="136.4" y1="247.4" x2="134.1" y2="246.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M122.8,238.3
        c2.7,0.6,5.4,1.2,8,2h0l0-0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M130.8,240.4
        c0.1,0,0.2,0,0.3,0l0-0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M122.2,238.2L122.2,238.2
        c-0.5-0.1-0.7-0.4-0.7-0.9v-0.9"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="120.6,236.2 
        120.6,238.7 119.8,240.5 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M44.5,259.5
        c4-7.4,9.1-13.5,15.4-18.4c4.4-3.4,8.5-5.3,12.3-5.5c6.6-0.6,12.8-0.8,18.6-0.7c11.6,0.1,21.3,0.9,29.1,2.1l0.1,3.5"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="116.2,230.4 
        116.4,232 111.9,232 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M130.7,239.4
        c-2.6-0.8-5.2-1.4-7.9-2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M136.3,241.2
        c0,1.8,0,3.8,0.2,6.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="130.9" y1="243.1" x2="130.8" y2="240.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="131.2" y1="243.2" x2="131.1" y2="240.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="131.5" y1="243.3" x2="131.3" y2="238.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M112.2,254.4
        c-4.9-0.6-9.8-1-14.8-1.2l0,0.5c0,0.9-0.4,1.4-1.2,1.5c-0.1,0-0.3,0-0.4,0l-2-0.1l-0.7,0l-8.6-0.5l0.3,4.1l0.1,1.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M93.8,255.1v-13.6
        c-0.1-1.4-0.3-2.5-0.7-3.2v13.4v3.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="102.5,230.3 
        102.5,232 103.7,232 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M93.8,241.5H97l-0.1-3.2
        c4.9,0.1,9.7,0.4,14.2,0.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="98.3" y1="232" x2="102.5" y2="232"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M81.7,239.5
        c-0.3,0-0.5,0.2-0.5,0.5v8.8c0,0.3,0.2,0.5,0.5,0.5h5.6c0.3,0,0.5-0.2,0.5-0.5V240c0-0.3-0.2-0.5-0.5-0.5H81.7z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="80.2,232 78.9,232 
        78.8,230.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M93.1,238.3H79.7
        c-2.1,0-4.1,0.2-6,0.5l0.9,6.7c0.5,4,3.8,6,9.8,6.2h8.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M84.7,258.7c0,0.5,0,1.1,0,1.6
        c0,0.2,0,0.4,0,0.6c-0.3,3-1.5,4.9-3.8,5.7L80,253.2c-2.9-0.5-5.3-1.3-7.1-2.4c-1.3-0.8-2.1-2.2-2.5-4.2l-0.5-3l-0.7-3.9
        c-0.9,0.2-1.8,0.5-2.6,0.8l1,5.7c0.1,1-0.3,1.9-1.2,2.4c-0.1,0.1-0.2,0.1-0.3,0.2l-7.4,3.5c-1.8,0.7-2.9,0.3-3.2-1.1l-0.6-3
        c-4,3.8-7.3,8-9.8,12.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="84.5" y1="254.6" x2="80" y2="253.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="97" y1="241.5" x2="97.4" y2="253.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="85.4" y1="266.9" x2="85.2" y2="269.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M44.4,270.1
        c1.1,1.2,2.5,1.8,4.2,1.9c11.4,0,23.1,0.5,35.2,1.4c0.4,0.1,0.8,0.1,1.2,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M90,274.4l-5-0.1
        c-0.4-0.1-0.8-0.1-1.2-0.2c-11.4-0.8-22.6-1.3-33.5-1.4h-0.3c-1.2-0.1-2.2-0.2-3.1-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M145.1,252.6
        c-0.1,1.9-0.1,4.2,0.1,6.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M54.9,248.2
        c1.2-1.1,2.4-2.2,3.7-3.2c0.5-0.4,1-0.8,1.5-1.2c1.9-1.4,4.1-2.5,6.5-3.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M55.2,241.6
        c-0.2,0.1-0.3,0.3-0.5,0.5v-1.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M48.9,246.8
        c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.6-1,1.2-1.4,1.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M69.9,243.6l3.8-4.8
        c-1.6,0.2-3,0.5-4.5,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M85.3,266.9
        c-14.4-1.3-28.4-2.1-42-2.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="70.5" y1="531.7" x2="68.7" y2="404.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="213.2" y1="751.8" x2="213.5" y2="753"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M149.5,753.9
        c0.6,0,1,0.1,1.1,0.3c0,0,0,0,0,0.1c1.3,0,2.6,0,3.8,0c0.5,0.1,0.8,0.2,0.9,0.5v0.4c0,0.4-0.3,0.6-0.8,0.6c-1.3,0-2.6,0-3.9,0v0
        c0,0.1,0,0.1,0,0.1v0c-0.1,0.1-0.3,0.2-0.7,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M138.4,756.4
        c-0.1,0-0.2,0-0.3,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M117.5,756.3
        C117.4,756.3,117.4,756.3,117.5,756.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="150.6" y1="754.2" x2="150.6" y2="755.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M63.1,669.1
        c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1
        c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4C63.7,668.7,63.4,668.8,63.1,669.1z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="62.9,668.9 
        62.4,670.5 63.6,671.8 65.3,671.3 65.8,669.7 64.6,668.4 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M58.4,661.8
        c-0.3,0.2-0.5,0.4-0.7,0.8c-0.1,0.4-0.1,0.7,0.1,1.1c0.2,0.4,0.5,0.6,0.8,0.7c0.4,0.1,0.7,0.1,1-0.1c0.4-0.2,0.6-0.4,0.7-0.8
        c0.1-0.4,0.1-0.7-0.1-1.1c-0.2-0.3-0.4-0.6-0.8-0.7C59.1,661.6,58.7,661.6,58.4,661.8z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="60.5,664 60.5,662.2 
        59.1,661.3 57.6,662.1 57.5,663.8 58.9,664.8 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M51.6,664.5
        c-0.3-0.2-0.7-0.3-1.1-0.2c-0.4,0.1-0.7,0.3-0.9,0.6c-0.2,0.3-0.3,0.7-0.3,1c0.1,0.4,0.3,0.7,0.6,0.9c0.3,0.2,0.7,0.3,1,0.3
        c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.3-1.1S51.9,664.8,51.6,664.5z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="52.1,664.5 
        50.5,663.9 49.1,665 49.4,666.7 51,667.3 52.4,666.3 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M140.2,756.8v0.4
        c0,0.2-0.1,0.4-0.4,0.6c-0.3,0.2-0.7,0.3-1.3,0.4c-2.7,0.2-5.7,0.2-9,0.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3-0.1
        c-0.3-0.1-0.6-0.2-0.8-0.5c-0.1-0.2-0.2-0.4-0.3-0.6v-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M138.5,753.9
        c-0.5,0-0.7,0.1-0.7,0.4h0v1.6c0,0.2,0.1,0.4,0.4,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M129.7,750.7v0.3
        c0,0.1,0,0.2-0.1,0.2h-3.9c-0.1,0-0.2-0.1-0.2-0.2v-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M125,750.7v0.8
        c0,0.1,0.1,0.2,0.2,0.2h4.8c0.1,0,0.2-0.1,0.2-0.2v-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M129.2,756.4
        c0.3,0,0.5-0.1,0.6-0.2c0-0.1,0-0.1,0-0.2v0v-1.6c0-0.2-0.3-0.4-0.8-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M129,753.9c0,0-0.1,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M117.6,753.9
        c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0.2-0.3,0.4v1.6c0,0.3,0.1,0.4,0.4,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M137.8,754.3
        c-2.6,0-5.3,0-7.9,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M137.8,755.9
        c-2.6,0-5.3,0-7.9,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M122.6,750.7v0.8
        c0,0.1-0.1,0.2-0.2,0.2h-4.6c-0.1,0-0.2-0.1-0.2-0.2v-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M122.1,750.7v0.3
        c0,0.1-0.1,0.2-0.2,0.2h-3.7c-0.1,0-0.1-0.1-0.1-0.2v-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M117,755.9c-1.6,0-3.2,0-4.8,0
        h0c-0.3,0-0.5-0.2-0.5-0.4v-0.6c0-0.4,0.3-0.6,0.8-0.6c1.5,0,3.1,0,4.6,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M92.1,724.2
        c-1.9,0.8-2.8,2.4-2.7,4.5c0,0.2,0,0.3,0,0.5l1.9,13.2c0.6,4,2.3,6.2,5.1,6.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M54,698c0.7-2,1.9-3.3,3.6-4"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M59.6,675.9
        c-0.4-0.2-0.7-0.2-1.1-0.1c-0.4,0.1-0.6,0.3-0.8,0.7c-0.2,0.3-0.2,0.7-0.1,1.1c0.1,0.4,0.4,0.6,0.7,0.8c0.3,0.2,0.7,0.2,1.1,0.1
        c0.4-0.1,0.6-0.4,0.8-0.7c0.1-0.4,0.2-0.7,0-1.1C60.1,676.3,59.9,676,59.6,675.9z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="58,675.6 57.2,677.2 
        58.1,678.6 59.9,678.6 60.6,677 59.7,675.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M52.1,674.6
        c0.1-0.4,0-0.7-0.3-1.1c-0.2-0.3-0.5-0.5-0.9-0.5c-0.4-0.1-0.7,0-1,0.2c-0.3,0.2-0.5,0.5-0.6,0.9c-0.1,0.4,0,0.7,0.3,1
        c0.2,0.3,0.5,0.5,0.9,0.6c0.4,0.1,0.7,0,1.1-0.3C51.8,675.3,52,675,52.1,674.6z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="52.4,674.6 51.7,673 
        50.1,672.7 49,674.1 49.6,675.7 51.3,676 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M62.7,324.8
        c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1
        c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4C62.9,324.1,62.7,324.5,62.7,324.8z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="62.4,325.3 
        63.7,326.5 65.3,326.1 65.8,324.4 64.6,323.2 62.9,323.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M51.8,328.3
        c-0.2-0.3-0.5-0.5-0.9-0.5c-0.4-0.1-0.7,0-1,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c-0.1,0.4,0,0.7,0.3,1c0.2,0.3,0.5,0.5,0.9,0.6
        c0.4,0.1,0.7,0,1.1-0.3c0.3-0.2,0.5-0.5,0.6-0.9C52.2,329,52.1,328.6,51.8,328.3z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="59.9,333.4 
        60.7,331.8 59.7,330.3 58,330.4 57.2,332 58.2,333.4 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M60.2,332.5
        c0.1-0.4,0.2-0.7,0-1.1c-0.1-0.4-0.3-0.6-0.7-0.8c-0.4-0.2-0.7-0.2-1.1-0.1c-0.4,0.1-0.6,0.3-0.8,0.7c-0.2,0.4-0.2,0.7-0.1,1.1
        c0.1,0.4,0.4,0.6,0.7,0.8c0.3,0.2,0.7,0.2,1.1,0.1C59.8,333.1,60,332.9,60.2,332.5z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="52.4,329.4 
        51.8,327.8 50.1,327.5 49,328.9 49.6,330.5 51.3,330.8 		"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="51,322.1 52.4,321.1 
        52.1,319.4 50.5,318.7 49.1,319.8 49.4,321.5 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M50.9,321.8
        c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.3-1c-0.1-0.4-0.2-0.7-0.6-0.9c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0.1-0.7,0.3-0.9,0.6
        c-0.2,0.3-0.3,0.7-0.3,1.1c0.1,0.4,0.3,0.7,0.6,0.9C50.2,321.8,50.6,321.9,50.9,321.8z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="60.5,318.7 60.6,317 
        59.1,316 57.6,316.8 57.5,318.6 58.9,319.5 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M60.4,318.2
        c0.1-0.4,0.1-0.7-0.1-1.1c-0.2-0.3-0.4-0.5-0.8-0.7c-0.4-0.1-0.8-0.1-1.1,0.1c-0.3,0.2-0.5,0.4-0.7,0.8c-0.1,0.4-0.1,0.7,0.1,1.1
        c0.2,0.3,0.5,0.6,0.8,0.7c0.4,0.1,0.7,0.1,1-0.1C60,318.9,60.3,318.6,60.4,318.2z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="132,244 132.1,253.4 
        132.2,254.4 132.4,255.3 132.5,256.3 133.4,262 133.6,263 133.8,264.3 134.3,267.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M113.3,253.7
        c0,0.1-0.1,0.3-0.1,0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M132.9,267.1l-0.5-1.5
        c-1.3-0.1-2.5-0.2-3.8-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M133.6,263
        c-2.4-0.2-4.6-0.4-6.5-0.7v1.4c2.3,0.4,4.6,0.6,6.8,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M127,264.6v-0.9
        c-2.2-0.4-4.4-0.9-6.7-1.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M133.4,262
        c-2.3-0.2-4.4-0.4-6.2-0.7c-3.3-0.6-5.9-1.3-7.9-2.4c-3.1-1.8-3-3.1,0.3-3.9c-0.1,0-0.3,0-0.4,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M119,255
        c-1,0.1-1.8,0.3-2.2,0.8c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.4,0.2,0.8,0.6,1.3
        c0.2,0.2,0.4,0.3,0.6,0.5c2.1,1.4,5.3,2.5,9.6,3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M113.3,253.7
        c0.4-1.2,1.9-1.9,4.6-2c4.7-0.2,9.2,0.3,13.5,1.5c0.2,0.1,0.4,0.1,0.6,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M119.6,255
        c4.1-0.2,8,0.1,11.9,1.1c0.4,0.1,0.7,0.2,1.1,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M132.2,254.4
        c-0.3-0.1-0.5-0.1-0.8-0.2c-4.3-1.2-8.8-1.6-13.3-1.4c-3.1,0.1-4.6,0.9-4.7,2.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M113.5,256.1
        c0.2-1.6,1.8-2.4,4.8-2.5c4.5-0.2,8.9,0.2,13.2,1.3c0.3,0.1,0.6,0.1,0.9,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M82.1,240.3
        c-0.3,0-0.4,0.1-0.4,0.4v7.8c0,0.3,0.1,0.4,0.4,0.4H87c0.3,0,0.4-0.1,0.4-0.4v-7.8c0-0.3-0.1-0.4-0.4-0.4H82.1z"/>
    </g>
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M686.9,606.9
        c-0.1,0-0.2,0-0.3,0.1c0,0.2,0,0.3,0,0.5c0,1.1,0,2.3-0.1,3.4c0,1.9-0.1,3.8-0.1,5.8c-0.2,0-0.3,0.1-0.5,0.1l-0.3,6.5
        c0.6,0,1.2,0,1.8,0c12.9,0,24,4.6,33.1,13.7c9.1,9.1,13.7,20.2,13.7,33.1c0,12.9-4.6,24-13.7,33.1c-8.4,8.4-18.5,13-30.1,13.6
        l-3,11.9c-0.8,2.7-2.7,4.5-5.6,5.5l-2.2,4.6c0.1,1.4,0.4,2.6,0.9,3.5c0.1,0.1,0.1,0.3,0.2,0.4l3.4,4.4l-4,4.7l-0.7-0.9v1.6
        c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.4l-1.2,6.4h-3.3c-0.2,0-0.3,0-0.4,0.1c-0.2,0-0.3,0.1-0.4,0.2
        c-0.3,0.3-0.7,0.5-1.1,0.7l-0.3-0.4c-4.8,3-11.8,4.9-20.8,5.6v0.5c-1.3,0.1-2.7,0.2-4.2,0.2l-4.9-8.5v-1.6v-4.3l-1.1,2.6v1.7v0.5
        v1c-6.1,0.1-12.2,0.1-18.4,0.1v0.3c-0.6,0.3-1.7,0.5-3.3,0.7c-1.2,0.1-2.7,0.3-4.5,0.4c-4.8,0.1-8.9,0-12.6-0.4
        c-2.1-0.2-4.1-0.5-5.8-0.8v-0.2c-0.9,0-1.7,0-2.6,0c-3.2,0-6.5,0-9.8-0.1c-1.2,0-2.4,0-3.6,0c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
        c-1.8-0.1-3.6-0.1-5.5-0.2c-14.9-0.7-29.4-1.9-43.3-3.8c-0.2,0-0.5-0.1-0.8-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.4-0.1-0.8-0.1-1.3-0.2
        c-0.9-0.1-1.8-0.3-2.7-0.4c-0.8-0.1-1.6-0.2-2.4-0.4l-2.4-0.5l-0.7-0.1c-0.4-0.1-0.5-0.2-0.6-0.4v-0.4l-3-0.5
        c-0.5-0.1-0.7-0.3-0.8-0.8c-0.1-2.6-0.1-4.8-0.2-6.8l-1.1-2.9v-1l-1.8-4.8c-0.5-22.9-0.8-46.1-1-69.4l1.4-5.1c0-0.3,0-0.6,0-0.9
        c-0.6-39.9-0.4-80.6,0.5-122.1c0.3-11.3,0.8-21.1,1.3-29.5c0.4-6.5,1-12.2,1.5-17c1.5-14.5,3.3-25.8,5.4-33.8
        c1.1-3.6,2.4-6.5,3.9-8.7l2.5-3c0.3-0.3,0.5-0.6,0.8-0.8c3-3.9,6.1-7.8,9.3-11.8c11.5-14.1,24.2-28.3,38.1-42.5
        c1.6-1.7,3.3-3.3,4.9-5l-0.1-1.1l5.4-5.7l0.3-6.9l0.2-5.9l-2.2-5.6l-1.2-3.1c1-25.8,2.9-50.3,5.7-73.7c0.1-0.5,0.1-0.9,0.2-1.3
        c0.3-2.2,0.9-4.5,1.8-7c0.1-0.2,0.2-0.4,0.3-0.6l0.2-0.6c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c1.7-4.2,3.2-8.6,4.5-13.1
        c0.3-1.6,0.6-3.2,1-4.8c0.1-0.5,0.2-1,0.4-1.4c0.2-0.5,0.4-0.9,0.7-1.3c1.9-2.8,4.2-5.8,7-9.1c0.1-0.1,0.1-0.1,0.2-0.2l0-0.1
        c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.2-0.1,0.2-0.2c0.4-0.3,0.8-0.6,1.3-0.8l3.6-1.5l0.3,0.6c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.6,0.6-1,1.3-1.2c1.4-0.4,2.8-0.7,4.1-1.1l0.1,0c1.1-0.2,1.9-0.1,2.3,0.3v0.9
        c0.2,0,0.4-0.1,0.5-0.1v-1.7c0.2,0,0.5-0.1,0.7-0.1l1-0.2v-2c1.2-1.4,2.6-2.7,4.4-3.8c1.1-0.1,2.4-0.1,3.8-0.2l0.5,1.7h8.2
        l1.2-1.7c1.2,0,2.4,0,3.7,0.1l0.5,1.7h18.1l1.4-1.4l2.7,0.1c2.5,0.3,4.9,0.7,7.1,1.3v1c5.3,2.5,9.7,5.2,13.2,8
        c0.2,0.2,0.4,0.4,0.6,0.6v-0.7l0.5-0.4c1,0.8,1.9,1.8,2.6,3c0.3,0.5,0.7,0.9,1.2,1.3c0.8,0.7,1.5,1.3,2,1.9v-0.4
        c0-0.2,0.1-0.4,0.2-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.6v2.5c1.8,2.3,3.4,4.7,4.8,7.2v1.2
        c-0.8,0.6-1.5,1.4-2,2.2c-0.2,0.4-0.5,0.7-0.7,1.2c0.7,1.3,1.3,2.5,1.9,3.9c0.4,0.9,0.7,1.7,1,2.6l-2.2,3l-0.4,0.6
        c-0.5,0.7-1.2,1.2-2,1.5l-0.5,6.7c9,1.7,16.9,6,23.8,12.9c9.1,9.1,13.7,20.2,13.7,33.1c0,12.9-4.6,24-13.7,33.1
        c-8.8,8.8-19.3,13.3-31.5,13.7l-0.5,6c0,9.4-0.1,18.8-0.2,28.2c0,0.2,0,0.5,0,0.7c-0.3,41.5-0.8,83.3-1.5,125.4
        c0,1.4-0.1,2.8-0.1,4.2c-0.1,37.3-0.1,57.7-0.2,61.2c0.1,0,0.2,0,0.2,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M517.3,741.9l-1-3.1
        c-0.6-26.5-1-53.2-1.2-80.2l-1.6,5.7c0.2,23.4,0.5,46.5,1,69.4l1.8,4.8h-0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M517.7,750.1
        c0.2-0.1,0.5-0.3,0.7-0.4c0.3-0.1,0.6-0.2,0.9-0.3c1.1-0.4,2.4-0.6,4-0.8c1.6-0.1,3.3-0.1,5.3,0.1c0-2.5-0.1-5-0.1-7.4v0h-7.6
        c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.1-1,0.2c-0.1,0-0.6,0.2-1.4,0.6l-1.6-0.1c0,0.1,0,0.3,0,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M518.2,710.4h1.8
        c1.8,0.1,2.7-1.9,2.9-6c0.1-18.1,0.1-33.5,0-46.1c-0.1-11-0.1-22.1-0.1-33.2c0-0.5,0-1.1,0-1.6c-0.1-4-0.9-6.1-2.4-6.2h-2.8
        c0,1.9,0,3.9,0,5.8h2.5c0.9,0,1.5,0.8,1.7,2.3c0,8.2,0,16.3,0,24.4c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.4,0.5-1,0.5h-3.2
        c0,2.5,0,5.1,0,7.6c0,4,0.1,8,0.1,12h3.4c0.5-0.1,0.7,0.2,0.7,0.7v0.1c0,0,0,0,0,0c0,9.7,0,20.7-0.1,32.9c0,0.1,0,0.2-0.1,0.4
        c-0.2,1-0.7,1.5-1.4,1.5h-2.2C518.1,707.4,518.2,708.9,518.2,710.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M518.2,710.4h-0.6
        c0.2,10.6,0.4,21.2,0.6,31.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M518.1,705.9
        c-0.2-11.8-0.3-23.7-0.4-35.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M535.2,741.8
        c-0.1-19.1-0.2-37.6-0.4-55.4l41.3,0.8v-30.5l-41.3-0.4c0,10.2,0,20.3,0,30.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="521.9,751.6 
        521.9,750.8 520.6,750.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M526.7,752.4
        c0.3-0.7,1-1,2.2-0.9c0.1,0,0.2,0,0.3,0h0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.5,0.1,0.9,0.1,1.4,0.2l0.5-2.8c0,0,0,0,0-0.1
        c0,0,0,0,0,0c-0.7-0.1-1.4-0.2-2-0.2c-0.2,0-0.5,0-0.8-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M529.4,751.6
        c-0.1,0-0.1,0-0.2,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M531.3,748.9
        c0.2,0,0.5,0.1,0.7,0.1h0c0,0,0,0,0,0l0,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M575.5,757v-0.5
        c0-0.4-0.2-0.6-0.8-0.7c-14.7-0.7-28.8-1.9-42.5-3.8c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5-0.1-0.7-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M531.3,748.9
        c0-2.5-0.1-4.9-0.1-7.4c0,0,0-0.1,0-0.1l-2-0.2h-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M532.1,749.1
        c16.4,1.6,32.7,2.6,48.9,3c0-2.7,0.1-5.2,0.1-7.4c-0.5,0-0.9,0-1.4,0c-0.1,0-0.2,0-0.3,0c-9.5-0.1-19.3-0.6-29.3-1.4l-14.8-1.5
        l-3.9-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M594.4,742.5l53.3-1
        c0.2-2,0.5-4,0.8-6c-23-9.4-36.3-22.7-39.9-40c0-0.1,0-0.1,0-0.1c-2.2-13.5-2.5-27.4-0.8-41.8c-3.8,1.8-9.4,2.9-16.7,3.2l-5.8-0.1
        c-0.2,29.3-0.3,58.5-0.3,87.7l-0.4,0.1c-0.1,2.5-0.1,5-0.1,7.5h0l9.7-2.2h0C594.4,747.4,594.4,744.9,594.4,742.5l-9.4,2.1
        c0,0.1,0,0.1,0,0.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="584.7" y1="744.6" x2="581.1" y2="744.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M580.9,757.3
        c0-0.2,0-0.4,0-0.6c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.5,0-1.1,0-1.6
        c0-0.1,0-0.3,0-0.4c0-0.5,0-1.1,0-1.6c0,0,0-0.1,0-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M580.9,753.8
        c1.2,0,2.4,0,3.6,0c0-0.6,0-1.1,0-1.7l-3.6,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M580.9,756.2
        c1.2,0,2.4,0,3.6,0c3.2,0,6.5,0,9.7,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M596.9,757.8v-1
        c-0.9,0-1.7,0-2.6,0c-3.2,0-6.5,0-9.8-0.1c-1.2,0-2.4,0-3.6,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M584.5,753.8
        c3.3,0,6.5,0,9.8,0.1c0.1,0,0.2,0,0.3,0h0.1c3.6,0,7.2,0,10.9,0.1c3.2,0,6.3,0,9.5,0h0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M605.7,756.4
        c-3.8,0-7.6,0-11.4,0c0,0-0.1,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M626.6,756.3
        c-3.9,0-7.8,0-11.7,0h0c-3,0-6,0-8.9,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M641.5,756.2
        c-4.9,0-9.9,0.1-14.8,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M516.1,506.6h3.2
        c0.4-4.9,0.8-9.2,1.3-13c0.4-3.8,0.8-7.3,1.3-10.6l1.1-7.4c1.1-5.8,2.3-10.8,3.8-14.9c0-0.1,0-0.1,0.1-0.2
        c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.5,0.4-0.7c0-0.1,0-0.2,0-0.4c0-0.2,0-0.5,0-0.7c0,0,0-0.1,0-0.1
        c0-0.5,0-0.9,0-1.3c0-0.3-0.1-0.6-0.1-0.8l-0.8-8.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M528.5,741.2
        c-0.4-46.7-0.7-91.7-0.7-134.9c0-6.1,0-12.3,0-18.5v-0.9c0-56.6,0-96.8,0-120.6c0-2.4,0-4.8,0-7.2c0-0.1,0-0.3,0-0.4
        c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M529.4,444.1l1.1,10.3
        c0,0.2,0,0.5,0.1,0.8l0.8-0.8v0c0,0,0.1-0.1,0.1-0.1c0.3-0.4,0.6-0.8,1-1.1c0.7-0.8,1.4-1.7,2.1-2.5c0,0,0.1-0.1,0.1-0.1
        c0.2-0.3,0.5-0.6,0.8-0.9c0.4-0.4,0.7-0.9,1.1-1.3c-1.3,1.3-2.1,1.4-2.2,0.2v0l-0.8-7.6c1.7-2.2,3.4-4.4,5.2-6.5
        c0.9-1.3,1.2-2.2,0.9-2.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M534.5,450.5
        c0,1.9,0.1,3.5,0.1,4.7c0,0.4,0.1,0.8,0.1,1.3c0,0,0,0.1,0,0.1c0,0.4,0,0.7,0,1.1c0,0,0,0.1,0,0.1c0,0.9,0,1.8,0,2.6v0.1
        c0,0.1,0,0.1,0,0.2c0.2-0.3,0.5-0.5,0.7-0.8c2.6-2.9,5.2-5.8,8-8.7c0.1-0.1,0.3-0.3,0.4-0.4c5.5-5.8,11.4-11.6,17.5-17.5
        c0.2-0.2,0.3-0.3,0.5-0.5c5.8-5.5,11.8-11,18.1-16.5c1-0.8,2-1.7,3-2.6c0.1-0.1,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
        c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.1,0.3-0.3,0.5-0.4c1.5-1.3,3-2.6,4.5-3.8c0.2-0.2,0.4-0.3,0.5-0.4l9-4.7
        c0.1,0,0.1,0,0.2,0c13-1.1,25.8-1.5,38.4-1.3c0.1,0,0.2,0,0.3,0c1.4,0,2.8,0.1,4.2,0.1c0,0,0.1,0,0.1,0l-6.8-15.1
        c-1.2-1.9-3-2.9-5.2-3.1c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5,0-0.8,0c-8.7-0.2-17.4,0-26.2,0.6c0,0-0.1,0-0.1,0h-0.2
        c-1,0.1-1.8,0.3-2.6,0.6c-1.2,0.5-2.3,1.2-3.3,2.1l-5.8,5.6l-0.7,0.7c-1.2,1.2-2.4,2.4-3.6,3.6c-0.2,0.2-0.4,0.4-0.6,0.6
        l-0.9,11.1c-0.1,1.4-0.6,2.6-1.5,3.6c-0.2,0.2-0.3,0.3-0.5,0.4l-0.1,0.1c-0.8,0.7-1.7,1.5-2.5,2.2c-6.3,5.5-12.4,11-18.1,16.5
        c-6.3,6-12.3,12-18,17.9c-0.1,0.1-0.3,0.3-0.4,0.4c-2.8,2.9-5.4,5.8-8,8.7c0-0.6,0-1.1,0-1.7c0,0,0-0.1,0-0.1c0-0.5,0-3-0.1-7.2"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M531.3,741.4
        c-0.3-29.1-0.5-57.5-0.6-85.3c-0.1-17.2-0.1-34.1-0.2-50.7c0-5.5,0-11,0-16.5l0-49.9v-73.7c0-2.5,0-5.1,0-7.6c0,0,0-0.1,0-0.1
        c0-0.4,0-0.8,0-1.1c0,0,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3l-2.7,3.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M534.6,455.2
        c0,1.4,0,2.3,0,2.6c0,0,0,0.1,0,0.1c0,0.5,0,1.1,0,1.7c0,0.3,0,0.6,0,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M530.5,465.4
        c1.6-1.8,2.9-3.3,4-4.5c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="534.7" y1="460.8" x2="534.7" y2="460.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M530.5,539.1l50.4-4.2
        c0.1-4,0.2-8,0.3-12.1l-46.6,5.7v-60.4c7.9-8.8,16.3-17.4,25-25.8c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.7,0.5-1.4,0.9-2.1
        c0,0,0-0.1,0.1-0.1v-6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M560.3,441.6
        c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.6-0.2,1.2-0.2,1.8v2.2c0.1-2,0.6-3.8,1.4-5.1c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.2,0.3-0.4,0.4-0.5
        c0.2-0.3,0.6-0.5,0.9-0.7c0.1-0.1,0.2-0.1,0.3-0.2v-1.6c-0.5,0.2-0.9,0.6-1.3,1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0.1-0.1,0.1
        c-0.2,0.2-0.3,0.4-0.4,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M563.3,439L563.3,439
        C563.4,439,563.4,438.9,563.3,439C563.4,439,563.4,439,563.3,439c0.1,1.1,0.8,1.7,2.2,1.8h10.2c0.5,0,1-0.1,1.3-0.3
        c0.5-0.3,0.8-0.8,0.8-1.5v-0.3c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.1,0-0.2,0h-11.5C564.6,438.6,563.9,438.7,563.3,439"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M563.3,437.4
        c0.5-0.2,1-0.4,1.6-0.4c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0h11.5c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.5,0.1v-12.3
        c0.1,0.2,0.3,0.3,0.4,0.4c0,0,0.1,0.1,0.1,0.1c2.1,2.4,3.4,4.8,3.9,7.3v5.8h0.3c0.1-4.9,0.1-9.8,0.2-14.8c0-1.3,0-2.6,0-3.9
        c0-1.9,0-3.9,0.1-5.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M589.3,408.5
        c-0.1,3.1-0.2,4.9-0.2,5.3c0,2-0.1,3.9-0.1,5.9c0,1.3,0,2.6-0.1,3.9c-0.2,10.6-0.4,21.3-0.5,31.9c-0.5,26-1.2,52.3-2.1,78.8
        l12.9-2.9c6-0.5,11.9-0.8,17.8-1c14.7-0.5,29-0.4,42.8,0.5l1.6-127.6c-6.4-0.6-12.9-1-19.5-1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M581.2,522.9
        c0.6-22.7,1-45.1,1.2-67.4c0.1-5.7,0.1-11.4,0.2-17.1h1.4c0.1-4.9,0.1-9.8,0.2-14.8c0-1.3,0-2.6,0-3.9c0-1.7,0-4.1,0.1-7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M560,445.9v6
        c0.1,1.5,1.3,2.3,3.7,2.4c0.1,0,0.1,0,0.2,0h12.4c0.3,0,0.5,0,0.8,0c0.5,0,1-0.1,1.4-0.1c0.1,0,0.1,0,0.2,0
        c0.9-0.2,1.6-0.4,2.2-0.7c1-0.5,1.5-1.3,1.5-2.3V445c-0.4-1.2-0.9-2.3-1.5-3.2c-0.8-1.2-1.7-2.2-2.9-3.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="577.9" y1="438.7" x2="577.9" y2="437"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="582.3" y1="438.3" x2="582.3" y2="445"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M582.3,451.2v1.4
        c0,1-0.5,1.8-1.5,2.4c-0.9,0.5-2.1,0.8-3.7,0.9c-0.2,0-0.4,0-0.6,0h-12.6c-0.1,0-0.1,0-0.2,0c-2.3-0.1-3.6-0.9-3.7-2.4v-1.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M530.7,656.2l4.1,0.1
        c0-5.4-0.1-10.8-0.1-16.2c0-0.2,0-0.5,0-0.8c0-11.4-0.1-22.6-0.1-33.8c0-5.5,0-11,0-16.5l0-40.6l46.1-0.4c0-3.7,0.1-7.5,0.1-11.2
        c0-0.6,0-1.2,0.1-1.9l1.4-0.2c0.7-26.7,1.2-53.1,1.5-79.3c0.1-5.7,0.1-11.4,0.2-17.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M580.9,534.9l1.4-0.1
        c0,0.7,0,1.3-0.1,2c-0.4,40.1-0.7,80-0.9,120l4,0.1c0.2-39.9,0.5-79.9,0.9-120c0-0.8,0.1-1.7,0.1-2.5h0l-4,0.5c0,0,0,0,0,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M519.2,506.6
        c-0.6,8.3-1.1,18.1-1.5,29.5c-0.5,27.2-0.8,54.2-0.8,80.8c0,0.1,0,0.2,0,0.2h0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M514.3,659.2l0.2-0.7
        c0-0.1,0-0.2,0-0.3h-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M514.5,658.3h0.7h1.8
        c-0.1-13.6-0.1-27.3-0.1-41"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M515.2,658.6v-0.2
        c0,0,0-0.1,0-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M517.6,650.7
        c0-9.2-0.1-18.4,0-27.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M581.1,744.7
        c0.1-29.3,0.2-58.6,0.3-88l-1.4,0c-0.1,29.4-0.2,58.7-0.3,88"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="580" y1="656.7" x2="576.1" y2="656.7"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="576.1,656.7 
        576.1,640 534.7,640 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M580,656.7
        c0.2-36.2,0.4-72.5,0.8-108.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M648.5,735.5
        c0.3-2.6,0.6-5.1,1-7.7c-18.5-7.5-29.4-19.1-32.7-34.7c0-0.1,0-0.1,0-0.1c-2.2-13.4-2.3-27.1-0.4-41.3c0.2-1.3,0.4-2.7,0.6-4
        c-2.3,1.4-4.7,3-7.2,4.8c-0.6,0.4-1.3,0.8-2.1,1.2c0.1-1,0.3-2.1,0.4-3.1c0.3-1.8,0.5-3.5,0.8-5.3c0-0.1,0-0.1,0-0.2
        c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1c1.5-6.9,4.1-13,7.9-18.2c5.8-7.9,14.2-13.8,25.4-17.8l0,0c5.4-1.9,10.9-3.5,16.5-5.1l0.9-72.7"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M617.2,646.8
        c-0.1,0.3-0.1,0.6-0.1,0.8c1.7-1.1,3.4-2.1,5-2.9h0c3.7-13.5,13.1-22.9,28.1-28.3c2.8-1,5.7-1.9,8.6-2.8l0.1-3.9
        c-0.2,0.1-0.4,0.1-0.6,0.2c-3.5,1-6.9,2.1-10.2,3.3c-17.1,6.1-27.3,17.2-30.7,33.3C617.3,646.6,617.3,646.7,617.2,646.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M621.4,648
        c-0.3,1.5-0.5,3.1-0.7,4.6c-1.8,13.3-1.7,26.2,0.3,38.8c0,0,0,0,0,0.1c3.1,14.7,12.8,25.3,29.1,32c0-0.2,0.1-0.4,0.1-0.5
        c0.1-1.1,0.3-2.2,0.5-3.4c0.2-1.7,0.5-3.3,0.7-5c-0.1,0-0.2-0.1-0.3-0.1c-12-5.4-19.2-13.8-21.6-25.1l0,0
        c-1.8-11-1.9-22.4-0.3-34.1c0.2-1.4,0.4-2.8,0.6-4.1c0-0.1,0-0.1,0-0.1c0.9-4.4,2.5-8.3,4.8-11.8l-12.5,5.5
        c-0.3,1-0.5,1.9-0.7,2.9C621.4,647.8,621.4,647.9,621.4,648z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M603.9,632.6
        c0.7,0,1.1-0.4,1.1-1.1v-4.1h-4.2v4.1c0,0.7,0.4,1.1,1.1,1.1H603.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M600.8,623.3v-13.9h-0.4
        c-2.3,0-3.5,1.2-3.5,3.5v7c0,2.3,1.2,3.4,3.5,3.4H600.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M605,627.4v-4v-13.9v-2.9
        c0-0.7-0.4-1.1-1.1-1.1h-1.9c-0.7,0-1.1,0.4-1.1,1.1v2.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="600.8" y1="623.3" x2="600.8" y2="627.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M605,623.3
        c0.4,0,0.7,0,0.9-0.1c0.1,0,0.2,0,0.3-0.1c1.4-0.4,2.1-1.5,2.1-3.3v-7c0-1.8-0.7-2.9-2.1-3.3c-0.1,0-0.2-0.1-0.3-0.1
        c-0.3-0.1-0.6-0.1-0.9-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M658.9,603.6l-0.1,6.1
        c2.1-0.6,4.2-1.2,6.3-1.8c2.2-0.6,4.5-1.2,6.7-1.7c0.2-0.1,0.4-0.1,0.6-0.1l1.1-74.3l-4.2-0.1l-0.8,63.9c-0.1,1.4-0.4,2.5-0.9,3.2
        l-2.2,3l0.8-70.3c-2.2-0.2-4.3-0.3-6.5-0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M658.8,613.6
        c0.3-0.1,0.7-0.2,1-0.3c2.2-0.7,4.3-1.3,6.5-1.8c2-0.5,4.1-1.1,6.1-1.5c0.1,0,0.1,0,0.2-0.1c0.4-0.1,0.9-0.2,1.3-0.3
        c4.1-1,8.3-1.8,12.6-2.6c0-1.3,0-2.6,0-3.9c-4.5,0.8-8.9,1.7-13.3,2.8c-0.2,0.1-0.5,0.1-0.8,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M658.8,613.6v9.7
        c0.3-0.1,0.6-0.2,0.9-0.3c2.1-0.7,4.3-1.3,6.5-1.9c2.5-0.7,5-1.3,7.6-1.9c4-1,8.1-1.8,12.2-2.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M651.1,714.4l0.3,0.1
        c3.8,1.1,7.6,2,11.5,2.9c1.2,0.2,2.3,0.5,3.5,0.7c4.5,1.1,7.9,2.7,10,4.8c0.2,0.2,0.4,0.4,0.6,0.7l5.3,6.5l0.5-13.2
        c-10.9-1-20.3-5.5-28.4-13.5c-9.2-9.1-13.7-20.2-13.7-33.1c0-12.9,4.6-23.9,13.7-33.1c1.4-1.4,2.9-2.8,4.4-3.9
        c7.8-6.2,16.7-9.4,26.9-9.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M634.6,639.2
        c4.1-6.3,10.4-11,19-14.1c1.7-0.6,3.4-1.2,5.2-1.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M663.7,646.2
        c0.5-0.5,1-1,1.6-1.5c6.3-5.6,13.7-8.4,22.3-8.4c9.3,0,17.3,3.3,23.8,9.9c2.8,2.8,4.9,5.7,6.5,9c2.2,4.5,3.3,9.4,3.3,14.8
        c0,5.6-1.2,10.6-3.5,15.2c-1.6,3.1-3.7,6-6.4,8.6c-6.6,6.6-14.5,9.9-23.8,9.9c-9.3,0-17.2-3.3-23.8-9.9
        c-6.6-6.6-9.9-14.5-9.9-23.9c0-7.5,2.1-14.1,6.4-19.8C661.2,648.9,662.4,647.5,663.7,646.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M672.4,641.9L672.4,641.9
        c3.3-1.8,6.9-3,10.7-3.4h0c1.4-0.2,2.9-0.3,4.3-0.3c8.6,0,15.9,2.9,22,8.8v0c0.2,0.1,0.3,0.3,0.5,0.5c2.6,2.6,4.7,5.5,6.2,8.6
        c2.1,4.2,3.1,8.9,3.1,13.9c0,5.1-1,9.7-3.1,14c0,0,0,0,0,0c-1.5,3.1-3.6,5.9-6.2,8.5c-0.2,0.2-0.3,0.3-0.5,0.5l0,0
        c-5.3,5.1-11.5,8-18.6,8.7c-1.1,0.1-2.2,0.1-3.4,0.1c-1.5,0-3-0.1-4.4-0.3h0c-3.8-0.5-7.4-1.6-10.7-3.5c-2.6-1.5-5.1-3.3-7.4-5.6
        c-4.8-4.8-7.8-10.5-8.9-16.9c0,0,0,0,0,0c-0.3-1.8-0.4-3.7-0.4-5.6c0-1.9,0.2-3.8,0.4-5.6c0,0,0,0,0,0c0.7-3.9,2-7.5,4.1-10.8
        c1.3-2.1,2.9-4.1,4.8-6c1-1,2.1-2,3.2-2.8C669.5,643.7,670.9,642.7,672.4,641.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M672.4,641.9l0,4.3l0,0
        l2.4,5.6l0.4,1l0.4,1.1l1.2,2.7l0.6,1.5l0.6,1.5c0.4,1,0.3,1.9-0.2,2.8c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2
        c-0.1,0.1-0.1,0.2-0.2,0.2c-0.7,0.8-1.5,1.2-2.6,1.1l-7.3-0.7l-0.9-0.1l-6.2-0.6h0l0,0l-4.1,1.3"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="672.3,651.8 
        670.8,653.2 673.5,653 673.5,653 674.9,652.9 674.1,652.3 673.2,651.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M660.2,663
        c1.2-4.8,3.6-9.1,7.4-12.8c1.5-1.5,3.1-2.8,4.8-3.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M670.8,653.2l0.2,2.2
        c0,0,0,0,0,0c0.1,0.4,0.2,0.7,0.4,1c0.2,0.2,0.4,0.4,0.6,0.6l2,1.3l-0.1-1.5l-0.4-3.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M667.3,663.7
        c0.8-2.6,2.1-5,4-7.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M666.4,663.6
        c0.9-3,2.4-5.7,4.6-8.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M658.8,636.1
        c7.7-6.6,16.6-10,26.8-10.4c0.6,0,1.3,0,1.9,0c12.3,0,22.8,4.3,31.4,13c8.7,8.7,13,19.2,13,31.4c0,12.3-4.3,22.8-13,31.5
        c-7.8,7.8-17.1,12.1-27.9,12.9c-1.2,0.1-2.4,0.1-3.6,0.1c-1.6,0-3.1-0.1-4.7-0.2c-10.3-1-19.2-5.2-26.8-12.8
        c-8.7-8.7-13-19.2-13-31.5c0-12.3,4.3-22.7,13-31.4C656.9,637.7,657.8,636.8,658.8,636.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M686.6,603.1
        c0-0.9,0-1.8,0-2.7c0-1,0-2,0.1-3.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M656.1,675.7L656.1,675.7
        l17.6-1.6c0.5-0.1,0.9-0.1,1.4-0.1c0,0,0,0,0,0c0.5-0.6,0.7-1.5,0.8-2.7c0-0.1,0-0.1,0-0.2V669c0-0.1,0-0.1,0-0.2
        c-0.1-1.2-0.4-2.1-0.8-2.7c0,0,0,0-0.1-0.1c-0.4,0-0.9,0-1.3-0.1l-17.6-1.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M675.1,674
        C675.1,674,675.1,674,675.1,674c2.1-0.1,3.7,0,5.1,0.3c0.8,0.2,1.1,0.6,1,1.4c-0.2,1.4-0.6,3-1.3,4.8c0,0,0,0.1,0,0.1
        c0,0,0.1,0,0.1,0c0.7-0.3,1.6-0.3,2.8,0c0.1,0,0.1,0,0.2,0.1l2,0.6c0.1,0,0.1,0.1,0.2,0.1c1.1,0.4,1.8,1,2.2,1.6c0,0,0,0,0,0.1
        c0,0,0-0.1,0-0.1c0.5-1.9,1.1-3.4,1.8-4.7c0.4-0.7,1-0.9,1.6-0.5c1.2,0.6,2.6,1.5,4.2,2.7c0,0,0.1,0,0.1,0.1c0,0,0,0,0-0.1
        c-0.1-0.8,0.2-1.7,0.8-2.7c0-0.1,0.1-0.1,0.1-0.2l1.2-1.7c0.1-0.1,0.1-0.1,0.2-0.2c0.8-0.9,1.5-1.5,2.3-1.7c0,0,0,0,0.1,0
        c0,0-0.1,0-0.1-0.1c-1.6-1.1-2.9-2.1-3.9-3.1c-0.5-0.6-0.5-1.2,0-1.7c1-1,2.2-2,3.9-3.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0-0.1,0
        c-0.7-0.2-1.5-0.7-2.3-1.6c-0.1-0.1-0.1-0.1-0.2-0.2l-1.2-1.6c-0.1-0.1-0.1-0.1-0.1-0.2c-0.6-1-0.9-1.9-0.8-2.6c0,0,0,0,0,0
        c0,0-0.1,0-0.1,0.1c-1.6,1.2-3,2.1-4.2,2.7c-0.7,0.3-1.3,0.2-1.6-0.5c-0.7-1.2-1.3-2.8-1.8-4.7c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.1
        c-0.4,0.6-1.2,1.2-2.3,1.6c-0.1,0-0.1,0.1-0.2,0.1l-2,0.6c-0.1,0-0.1,0.1-0.2,0.1c-1.2,0.3-2.1,0.3-2.8,0c0,0,0,0,0,0
        c0,0,0,0.1,0,0.1c0.7,1.8,1.1,3.4,1.3,4.8c0.1,0.8-0.3,1.2-1,1.4c-1.3,0.3-3,0.3-5,0.3c0,0-0.1,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M680,667.8
        c-0.6,0-1.2,0.2-1.7,0.7c-0.4,0.4-0.7,1-0.7,1.6c0,0.7,0.2,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7s1.2-0.2,1.7-0.7
        c0.4-0.5,0.7-1,0.7-1.7c0-0.6-0.2-1.2-0.7-1.6C681.2,668,680.7,667.8,680,667.8z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="674" y1="658.4" x2="677.4" y2="658.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="675.6,654 675,654 
        675.2,656.8 676.8,656.6 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="675.2" y1="656.8" x2="673.9" y2="656.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M679.9,659.5
        c-0.2-0.4-0.3-0.9-0.5-1.3l-6.9-16.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M684.4,660.8
        c-0.6,0.2-1.1,0.6-1.4,1.1c-0.3,0.6-0.3,1.2-0.1,1.8c0.2,0.6,0.6,1.1,1.2,1.4c0.6,0.3,1.2,0.3,1.8,0.1c0.6-0.2,1.1-0.6,1.3-1.1
        c0.3-0.6,0.3-1.2,0.1-1.8c-0.2-0.6-0.6-1.1-1.1-1.3C685.6,660.6,685,660.6,684.4,660.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.1,638.5l3.9,17.2
        c0,0,0,0.1,0,0.1c0.1,0.4,0.2,0.8,0.3,1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M687.8,651l1.1,2v1.2l-0.3,0
        l0.3,1.4l0.9-0.1l1.9,0.8l0.7,0.2c0.1,0.1,0.2,0.1,0.3,0.1l3.5-0.3l1.9-1.6l-1,0.1l-4,0.3l-0.2,0l-2.6-1.1
        c-0.1-0.1-0.2-0.1-0.3-0.1l-0.9,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M696.1,656.5l-1.6,1.4
        c-0.8,0.7-1.7,0.9-2.7,0.6c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.3-0.1c-1-0.4-1.6-1.1-1.8-2.1
        l-0.1-0.4v0"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="688.8,655.7 
        690.1,656.5 691.6,656.5 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M687.5,666.3
        c1,0,1.9,0.4,2.6,1.1c0.7,0.7,1.1,1.6,1.1,2.6c0,1.1-0.4,1.9-1.1,2.6c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.7-1.1
        c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6C685.5,666.7,686.4,666.3,687.5,666.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M691.1,665.3
        c-0.1,0.7,0.1,1.2,0.4,1.7c0.4,0.5,0.9,0.8,1.5,0.9c0.6,0.1,1.2-0.1,1.7-0.4c0.5-0.4,0.8-0.9,0.9-1.5c0.1-0.6,0-1.2-0.4-1.7
        c-0.4-0.5-0.9-0.9-1.5-1c-0.7-0.1-1.2,0-1.7,0.4C691.5,664.2,691.2,664.7,691.1,665.3z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="674.9" y1="652.9" x2="675.2" y2="652.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="674.7" y1="651.9" x2="674.1" y2="652.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.1,638.5l2.5,3.5l0,0.1
        c0.6,0,1.2-0.1,1.8-0.1c4.5,0,8.6,0.9,12.3,2.8c1.9,1,3.8,2.2,5.5,3.7l0,0h0l4.1-1.3"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="688.5,654.3 
        687.8,651 686.7,646.3 685.7,642 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M692.9,655.3l1.9-4.7l0.2-0.1
        l1.1-0.5v-0.3v-0.5c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0-0.1-0.1
        c-1.9-1.2-4.8-1.8-8.9-1.6"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="695,650.5 
        696.2,651.1 695.7,651.1 695.7,652.3 696.8,652.2 696.7,651.1 696.2,651.1 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="697.9,654.8 
        698.3,654.5 698.1,651 696.7,651.1 696.7,651.1 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="696.7,651.1 
        697.5,650.3 696.1,649.7 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="697.5,650.3 
        698.3,649.6 699.2,649.5 700,650.1 700,649.6 699.1,649 696.1,649.2 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="700,650.1 
        700.8,650.7 700.9,652.2 705.3,648.4 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="700.8" y1="650.7" x2="698.1" y2="651"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M699.7,674.1
        c0.4,0.3,0.8,0.5,1.2,0.8l15.2,9.1l-2.5-3.5l-0.1-0.1l-5.3-3.2l-0.8-0.5l-6.4-3.8c-0.9-0.5-1.4-1.3-1.4-2.4c0-0.1,0-0.2,0-0.3V670
        c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.1-1,0.6-1.8,1.4-2.3l6.4-3.8l0.8-0.5l5.3-3.1l0.1-0.1l2.5-3.5l-15.2,9.1
        c-0.4,0.2-0.8,0.5-1.2,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M695.1,659.6
        c0.3-0.3,0.7-0.6,1.1-0.9l13.3-11.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="696.8" y1="652.2" x2="696.9" y2="654.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="700.9" y1="652.2" x2="698.3" y2="654.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="716.2" y1="656.1" x2="716.1" y2="656.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M603.3,521.8h2
        c0.7,0,1.1-0.4,1.1-1.1v-4.1h-4.2v4.1C602.2,521.4,602.6,521.8,603.3,521.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M606.4,516.6v-2.9v-13.9v-4.1
        c0-0.7-0.4-1.1-1.1-1.1h-2c-0.7,0-1.1,0.4-1.1,1.1v4.1v13.9v2.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M602.2,499.8h-0.4
        c-2.3,0-3.5,1.2-3.5,3.5v7c0,2.3,1.2,3.4,3.5,3.4h0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M606.4,513.7
        c0.3,0,0.6,0,0.9-0.1c1.6-0.3,2.4-1.5,2.4-3.4v-7c0-1.9-0.8-3-2.4-3.3c-0.3-0.1-0.6-0.1-0.9-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M669.4,531.6l1.5-127.3
        c-1-0.1-2-0.2-3-0.4l-1.5,127.4C667.4,531.4,668.4,531.5,669.4,531.6L669.4,531.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M623.1,757.8v-1
        c-2.3,0-4.5,0-6.8,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M616.3,756.8
        c-4.1,0-8.3,0-12.4,0c-2.3,0-4.7,0-7,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M613.6,750.7
        c-0.2,0-0.4,0.1-0.4,0.4v1c0,0.2,0.1,0.4,0.4,0.4h5.7c0.2,0,0.4-0.1,0.4-0.4v-1c0-0.2-0.1-0.4-0.4-0.4h-0.3h-0.5h-4.2h-0.5H613.6z
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M626.5,753.9
        c-3.8,0-7.5,0-11.3,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M626,750.7H622h-0.5h-0.2
        c-0.2,0-0.4,0.1-0.4,0.4v1c0,0.2,0.1,0.4,0.4,0.4h5.4c0.2,0,0.3-0.1,0.3-0.4v-1c0-0.2-0.1-0.4-0.3-0.4h-0.2H626z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M616.1,757.8
        c-3.9,0-7.8,0-11.8,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M642.6,756.3l4.9,8.8
        c13.5-0.5,22.8-3.3,28.1-8.3c1.7-1.8,3-3.8,3.9-5.9v-11.8l-2.7,5.4c-1.1,2-2.4,3.8-3.9,5.3c-1.9,1.9-4.9,3.3-8.8,4.3
        c-1.1,0.3-2.4,0.6-3.7,0.8c-2.3,0.4-4.9,0.7-7.8,0.9h-2.7h-2.7c-1.1,0-1.6-0.6-1.7-1.5v-3.8h-1.9c-0.2,0-0.3,0-0.4,0l-0.5,1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M643.1,750.6
        c-0.2,0-0.4,0.1-0.5,0.1c-0.7,0.3-1.1,0.9-1.1,1.9v1.1v0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M649.9,755.9v-3.2
        c0-1.4-0.7-2.1-2.1-2.1h-1.2h-1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M647.8,741.5
        c-0.3,2.5-0.6,5-0.9,7.5l0.7,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M647.6,749l0.8,0
        c10-0.3,16.3-2.3,18.9-6.1c3.3-4.6,6.3-11.3,9-20.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M664.1,754.2v-0.9
        c-1.1,0.3-2.4,0.6-3.7,0.8v0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M664.1,753.2v-3.7
        c0-0.5-0.3-0.8-0.8-0.7l-2.1,0.2c-0.5,0.1-0.8,0.4-0.8,0.9v4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M673.8,759.7l-0.3-0.4
        c-0.4,0.2-0.7,0.5-1.1,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M646.7,750.6
        c0.1-0.5,0.1-1,0.2-1.6l-52.5,0.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="647.5" y1="765" x2="647.5" y2="766.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M641.5,753.8
        c-4.9,0-9.8,0.1-14.7,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M641.5,756.7
        c-6.1,0.1-12.2,0.1-18.4,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M656.1,675.7l4.1,1.3h0.1
        l6.2-0.5l0.9-0.1l7.3-0.7c1-0.1,1.9,0.3,2.6,1.1c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3
        c0.5,0.9,0.6,1.8,0.2,2.8l-2.9,6.7l-0.4,0.9l-2.4,5.7l0,0l0,4.3l7-16.3c0.2-0.4,0.4-0.9,0.5-1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M674.8,688
        c-1-0.7-1.9-1.5-2.9-2.4c-0.7-0.7-1.3-1.4-1.9-2.2c-1.7-2.1-2.9-4.4-3.6-6.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M675.2,687.2
        c-0.9-0.7-1.8-1.4-2.6-2.2c-0.5-0.5-1-1-1.4-1.5c-1.8-2.1-3-4.5-3.8-7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M672.4,693.8
        c-1.7-1.1-3.3-2.4-4.8-3.9c-2-2-3.6-4.2-4.9-6.5c-1.1-2-1.9-4.1-2.5-6.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M652,724.2
        c-0.7-0.2-1.3-0.5-1.9-0.7c-0.2,1.5-0.4,3-0.6,4.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M651.4,714.4
        C651.4,714.4,651.4,714.4,651.4,714.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="681.9,734.2 
        683.3,731.3 682.2,729.9 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M690.5,716.8
        c-1,0.1-2,0.1-3,0.1c-1.6,0-3.2-0.1-4.8-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.1,701.6L683.1,701.6
        l3.9-17.2c0.1-0.3,0.2-0.7,0.2-1c0-0.1,0.1-0.2,0.1-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M685.6,698.1
        c0.6,0,1.2,0.1,1.8,0.1c0.9,0,1.8,0,2.7-0.1c5.7-0.5,10.8-2.6,15.2-6.3l-4.7-4.1c-3.8,2.9-8.2,4.4-13.2,4.4c-0.2,0-0.3,0-0.4,0
        l-0.5,2L685.6,698.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M700.6,687.7l-0.7-0.6
        c-3.6,2.7-7.7,4.1-12.5,4.1c-0.1,0-0.2,0-0.2,0L687,692"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="683.1,701.6 
        685.6,698.1 685.6,698.1 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M684.1,675
        c-0.6,0.3-1,0.7-1.2,1.3c-0.2,0.6-0.2,1.2,0.1,1.8c0.3,0.6,0.7,0.9,1.4,1.1c0.6,0.2,1.2,0.2,1.8-0.1c0.6-0.3,1-0.8,1.2-1.4
        c0.2-0.6,0.2-1.2-0.1-1.8c-0.3-0.6-0.8-0.9-1.4-1.1C685.3,674.7,684.7,674.7,684.1,675z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M692.1,676.2
        c0.5,0.4,1.1,0.5,1.8,0.4c0.6-0.1,1.1-0.4,1.5-0.9c0.4-0.5,0.6-1.1,0.5-1.7c-0.1-0.6-0.4-1.1-1-1.5c-0.5-0.4-1.1-0.5-1.7-0.4
        c-0.6,0.1-1.1,0.4-1.5,1c-0.4,0.5-0.5,1.1-0.4,1.7C691.2,675.4,691.5,675.9,692.1,676.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M687.2,691.1l1.6-7.1
        c0-0.2,0.1-0.4,0.2-0.6c0.3-0.7,0.8-1.2,1.6-1.5c0.1,0,0.2-0.1,0.3-0.1l0.3-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
        c1-0.2,1.9,0,2.7,0.6l5.5,4.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M709.5,693l-13.3-11.7
        c-0.4-0.3-0.7-0.6-1-0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M707.5,663.3
        c0.7,2.1,1,4.3,1,6.7c0,2.4-0.3,4.6-1,6.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M708.3,662.9
        c0.7,2.2,1.1,4.6,1.1,7.2c0,2.6-0.4,5-1.1,7.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="705.3,691.7 
        705.3,691.8 709.4,693.1 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M713.6,680.5
        c1.3-3.2,1.9-6.7,1.9-10.5c0-3.7-0.6-7.2-1.9-10.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="716.1" y1="684" x2="716.2" y2="684"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M679.2,753
        c-0.9,1.6-2.1,3.1-3.6,4.6c-0.6,0.6-1.3,1.1-2,1.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="679.7" y1="738.8" x2="679.5" y2="739.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M517.6,710.4
        c-0.3-17.3-0.5-34.7-0.6-52.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M588.3,364.3
        c0.4,1.1,0.5,2.1,0.5,3.2c1.8-29.6,3.8-57.8,6-84.5c0.6-5.9,1.2-11.5,1.9-16.9c-0.7,2-1.4,3.9-2.2,5.8c0,0.1-0.1,0.1-0.1,0.2
        c0,0.1-0.1,0.2-0.1,0.3l-0.2,0.6c-0.1,0.2-0.2,0.4-0.2,0.6c-0.9,2.5-1.5,4.8-1.8,7c-0.1,0.4-0.1,0.9-0.2,1.3
        c-2.8,23.3-4.7,48.9-5.8,76.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M590.4,392.7
        c1.7-38.1,3.8-74.7,6.2-109.8c0.6-6.8,1.2-13.3,1.9-19.5l0.2-1.6c0.1-0.8,0.1-1.6,0.2-2.4l-3.3,17.8c-0.2,1.9-0.3,3.7-0.5,5.6
        c-1.8,26.8-3.5,54.4-4.9,83l-0.3,7.3c-0.1,2.7-0.1,5.4-0.1,8.1c-0.1,3.3-0.1,6.7-0.1,9.9v0c0,0.7,0,1.4,0,2.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="589.8" y1="381.4" x2="588" y2="370.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="587.8,377.2 
        587.7,378.9 589.7,391.3 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M585.5,397.6
        c-0.2,0.2-0.4,0.4-0.5,0.5c-0.2,0.2-0.4,0.4-0.6,0.6c0,0,0,0-0.1,0.1c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.3,0.3-0.4,0.4
        c-1.1,1.1-2.1,2.2-3.2,3.2c-0.2,0.3-0.5,0.5-0.7,0.8c-0.4,0.4-0.8,0.8-1.2,1.2l0,0c0,0-0.1,0.1-0.1,0.1
        c-15.7,14.8-29.6,29.2-41.6,43.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M592.3,395.6v7
        c0,1,0.5,1.3,1.5,1l5-1.8c0.1,0,0.1,0,0.2,0c12.9-1.1,25.6-1.5,38-1.3l-0.7-1.8L632,389c-1.3-2.5-3.4-3.8-6.3-4
        c-7.5-0.1-15,0.1-22.5,0.6c-0.2,0-0.5,0-0.7,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-1.3,0.1-2.6,0.5-3.9,1.2l-0.6,0.4
        c-0.3,0.2-0.5,0.4-0.8,0.6l-3,2.9c-1,1-1.6,2.4-1.7,4.2C592.3,395.2,592.3,395.4,592.3,395.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M578.2,405
        c0.8-0.9,1.1-2,0.9-3.4l-1.5-12.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="582.5" y1="384" x2="584.3" y2="398.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M603.5,397.6
        c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0.1,0.5c0.2,0.7,0.7,1.1,1.6,1.1h0c9.2-0.6,18-0.9,26.6-0.8
        c-1-3.4-2.2-6.6-3.6-9.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.7-1.3-2-2.1-3.8-2.1c-0.1,0-0.3,0-0.4,0c-6,0-11.9,0.1-17.9,0.5
        c-1.2,0.2-1.9,0.8-2.1,1.8c-0.1,2-0.2,4-0.3,6.1C603.6,396,603.5,396.8,603.5,397.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M625.4,373.1
        c8.3,5.3,20.2,9.8,35.4,13.4l0.8,0.3l0-2.8c-11.3-3.7-20.3-7.7-26.9-12c0,0,0,0-0.1,0c-11.1-7.8-17.2-17.9-18.3-30.3
        c-0.1-0.5-0.1-1-0.1-1.5l0-1.2c0-0.8,0-1.7-0.1-2.5c-0.3-11.3,0.1-22.9,1.1-34.7c0-0.1,0-0.1,0-0.1c1.3-8.3,4.2-15.7,8.7-22.3
        c0.8-1.1,1.6-2.2,2.5-3.3c0,0,0,0,0,0c5.7-6.6,15.9-10.6,30.6-12.1l0.2-3.6c-21.4,2.7-34.8,7.3-40.4,13.7c0,0,0,0,0,0
        c-0.9,1.1-1.8,2.3-2.6,3.4c-4.7,6.8-7.7,14.5-9,23.1c0,0,0,0.1,0,0.1c-1,11.7-1.4,23.3-1.2,34.5c0,1.3,0.1,2.6,0.1,3.9l0,1.2
        c0.1,1.1,0.2,2.2,0.3,3.2C608.1,355.5,614.3,365.4,625.4,373.1C625.3,373.1,625.3,373.1,625.4,373.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M661.6,384l0-4.1
        c-9.5-3-17.5-6.6-24-10.8c0,0,0,0-0.1,0c-10.6-7.4-16.3-17-17.4-28.7c-0.1-0.5-0.1-0.9-0.1-1.4l0-1.1c0-0.8,0-1.6-0.1-2.4
        c-0.2-10.7,0.1-21.7,1-32.8c0,0,0-0.1,0-0.1c1.2-7.9,3.9-14.9,8.3-21.2c0.7-1.1,1.5-2.1,2.4-3.1c0,0,0,0,0,0
        c5.4-6.2,14.4-10,27-11.2c0,0,0.1,0,0.1,0h0l0.2-3.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M642.2,361.9
        c-9-6.5-13.8-14.9-14.4-25.2l0-1c-0.3-10.4,0-21,0.9-31.9c1-6.7,3.3-12.8,7-18.1c0.6-0.9,1.2-1.8,1.9-2.6
        c3.6-4.1,9.1-6.9,16.7-8.5c1.6-0.4,3.2-0.6,5-0.9l-0.3-3.8c-11.5,1.6-19.8,5.1-24.7,10.6c-0.8,0.9-1.6,1.9-2.3,2.9
        c-4.1,5.8-6.7,12.4-7.8,19.7c-0.3,3.3-0.5,6.7-0.7,10l0.8,0.7c0.3,0.2,0.4,0.7,0.5,1.4c-0.2,4.4-0.3,8.8-0.3,13.2
        c-0.1,0.8-0.3,1.3-0.7,1.7l-0.7,0.7c0,2.4,0.1,4.7,0.1,7.1l0,1.1c0.7,11.3,6.1,20.5,16.3,27.5c6.1,3.9,13.5,7.2,22.2,10.1l0.1-5.3
        C654.1,368.4,647.6,365.4,642.2,361.9C642.2,361.9,642.2,361.9,642.2,361.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M661.7,376.3l0,3.5
        c1.6,0.5,3.2,1,4.9,1.5l9.1,2.9l-0.2,20.7c5.2,0.6,9.5,1.2,13,1.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M688.6,377.7
        c-7.2-1.1-14.5-2.8-21.9-5.1c-1.7-0.5-3.3-1-4.9-1.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M635.7,388
        c-0.2-0.3-0.4-0.6-0.5-0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M636.4,398.8c0,0-0.1,0-0.1,0"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M661.6,386.8l6.4,2l1.9,3.8
        l1,3l-0.1,8.6c1.5,0.2,3,0.4,4.5,0.5h0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M661.6,386.8l-0.2,16.6
        c2.2,0.2,4.3,0.4,6.5,0.6l0.2-15.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M599.5,385.1
        c0-1.2,0-2.3,0.1-3.3c1.7-38.5,4.1-79.6,7.3-123.4l0.3-3.8c0-0.4,0.1-0.9,0.1-1.3c0.2-2.1,0.3-4.1,0.4-5.8
        c-3.4,4.1-6.4,8.2-8.8,12.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M656.1,319.2
        c1.1-6.4,4-12,8.9-16.9c2.3-2.3,4.8-4.2,7.4-5.6l0,4.3l0,0l2.4,5.6l0.4,1l0.4,1.1l1.2,2.7l0.6,1.5l0.6,1.5c0.4,1,0.3,1.9-0.2,2.8
        c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.7,0.8-1.5,1.2-2.6,1.1l-7.3-0.6l-0.9-0.1
        l-6.2-0.6h0L656.1,319.2C656.1,319.2,656.1,319.2,656.1,319.2l17.6,1.6c0.5,0,0.9,0.1,1.3,0.1c0,0,0.1,0,0.1,0
        c2,0.1,3.6,0,4.9-0.3c0.8-0.1,1.1-0.6,1-1.4c-0.2-1.4-0.6-3-1.3-4.8c0,0,0-0.1,0-0.1c-0.2-0.4-0.4-0.9-0.5-1.3l-6.9-16.3l0,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M660.2,317.8
        c1.2-4.8,3.7-9.1,7.4-12.8c1.5-1.5,3.1-2.8,4.8-3.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M671,310.2
        c0.1,0.4,0.2,0.7,0.4,1c0.2,0.2,0.4,0.4,0.6,0.6l2,1.3l-0.1-1.5l-0.4-3.8h0l-2.7,0.2L671,310.2C671,310.2,671,310.2,671,310.2z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="673.5,307.8 
        674.9,307.7 674,307.1 673.2,306.5 672.3,306.5 670.8,308 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M666.4,318.4
        c0.9-3,2.4-5.7,4.6-8.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M667.3,318.5
        c0.8-2.6,2.1-5.1,4-7.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M672.4,296.7
        c3.3-1.8,6.9-2.9,10.7-3.4h0c1.4-0.2,2.9-0.3,4.3-0.3c2.8,0,5.5,0.3,8.1,1c5.2,1.3,9.8,3.9,14,7.9v0c0.2,0.1,0.3,0.3,0.5,0.5
        c2.6,2.6,4.7,5.5,6.2,8.6c2.1,4.2,3.1,8.9,3.1,13.9c0,5.1-1,9.7-3.1,14c0,0,0,0,0,0c-1.5,3.1-3.6,5.9-6.2,8.5
        c-0.2,0.2-0.4,0.3-0.5,0.5l0,0c-5.4,5.2-11.8,8.1-19.1,8.7c-1,0.1-1.9,0.1-2.9,0.1c-1.5,0-2.9-0.1-4.4-0.3c0,0,0,0,0,0
        c-3.8-0.5-7.4-1.6-10.7-3.5c-2.6-1.4-5.1-3.3-7.4-5.6c-4.8-4.8-7.8-10.5-8.9-16.9c0,0,0,0,0,0c-0.3-1.8-0.4-3.7-0.4-5.6
        c0-1.9,0.1-3.8,0.4-5.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M656.1,330.5l4.1,1.3h0.1
        l6.2-0.5l0.9-0.1l7.3-0.7c1-0.1,1.9,0.3,2.6,1.1c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3
        c0.5,0.9,0.6,1.8,0.2,2.8l-2.9,6.7l-0.4,0.9l-2.4,5.7l0,0l0,4.3l7-16.3c0.2-0.4,0.4-0.9,0.5-1.3c0,0,0-0.1,0.1-0.1
        c0.7-1.8,1.1-3.4,1.3-4.8c0.1-0.8-0.3-1.2-1-1.4c-1.4-0.3-3-0.4-5-0.3c0,0-0.1,0-0.1,0c-0.4,0-0.9,0-1.4,0.1L656.1,330.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M672.3,348.6
        c-1.7-1.1-3.2-2.4-4.7-3.9c-3.8-3.8-6.2-8-7.4-12.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M653.7,324.8
        c0-9.3,3.3-17.2,9.9-23.8c6.6-6.6,14.5-9.9,23.8-9.9c2.9,0,5.6,0.3,8.2,0.9c5.9,1.4,11.1,4.4,15.6,8.9c2.8,2.8,4.9,5.8,6.5,9
        c2.2,4.5,3.3,9.4,3.3,14.8c0,5.6-1.2,10.6-3.5,15.2c-1.6,3.1-3.7,6-6.4,8.6c-5.9,5.9-13,9.2-21.1,9.8c-0.9,0.1-1.8,0.1-2.7,0.1
        c-9.3,0-17.2-3.3-23.8-9.9C657,342.1,653.7,334.1,653.7,324.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M675.1,342
        c-0.9-0.7-1.8-1.4-2.6-2.2c-2.5-2.5-4.3-5.4-5.2-8.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M674.8,342.8
        c-1-0.7-1.9-1.5-2.9-2.4c-2.7-2.7-4.5-5.7-5.5-9.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M656,293.4
        c8.7-8.7,19.2-13,31.4-13c3.2,0,6.2,0.3,9.1,0.9c8.4,1.7,15.9,5.7,22.3,12.2c8.7,8.7,13,19.2,13,31.4c0,12.3-4.3,22.8-13,31.5
        c-8.2,8.3-18.1,12.6-29.6,13c-0.6,0-1.2,0-1.8,0c-12.3,0-22.8-4.3-31.4-13c-8.7-8.7-13-19.2-13-31.5
        C643,312.5,647.3,302.1,656,293.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M689.1,371.6
        c-0.5,0-1.1,0-1.6,0c-12.9,0-24-4.6-33.1-13.7c-9.2-9.1-13.7-20.2-13.7-33.1c0-12.9,4.6-23.9,13.7-33.1
        c9.1-9.2,20.2-13.7,33.1-13.7c3.2,0,6.3,0.3,9.3,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M679.8,335.4
        C679.8,335.4,679.9,335.3,679.8,335.4c0.8-0.3,1.7-0.3,2.9,0c0.1,0,0.1,0,0.2,0l2,0.7c0.1,0,0.1,0,0.2,0.1c1.1,0.4,1.8,1,2.2,1.6
        c0,0,0,0,0,0.1c0,0,0-0.1,0-0.1c0.5-1.9,1.1-3.4,1.8-4.7c0.4-0.7,1-0.9,1.6-0.5c1.2,0.6,2.6,1.5,4.2,2.7c0,0,0.1,0.1,0.1,0.1
        c0,0,0-0.1,0-0.1c-0.1-0.8,0.2-1.7,0.8-2.7c0-0.1,0.1-0.1,0.1-0.2l1.2-1.7c0-0.1,0.1-0.1,0.1-0.2c0.8-0.9,1.5-1.5,2.3-1.7
        c0,0,0,0,0.1,0c0,0-0.1,0-0.1-0.1c-1.6-1.1-2.9-2.1-3.9-3.1c-0.5-0.6-0.5-1.2,0-1.7c1-1,2.2-2,3.9-3.1c0,0,0.1-0.1,0.1-0.1
        c0,0,0,0-0.1,0c-0.7-0.2-1.5-0.7-2.3-1.6c-0.1-0.1-0.1-0.1-0.1-0.2l-1.2-1.6c0-0.1-0.1-0.1-0.1-0.2c-0.6-1-0.9-1.9-0.8-2.6
        c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-1.6,1.2-3,2.1-4.2,2.7c-0.7,0.3-1.3,0.2-1.6-0.5c-0.7-1.2-1.3-2.8-1.8-4.7c0-0.1,0-0.1,0-0.2
        c0,0,0,0.1,0,0.1c-0.4,0.6-1.2,1.2-2.3,1.6c-0.1,0-0.1,0.1-0.2,0.1l-2,0.6c-0.1,0-0.1,0.1-0.2,0.1c-1.2,0.3-2.1,0.3-2.8,0l0,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683,356.4L683,356.4l3.9-17.2
        c0.1-0.5,0.2-0.9,0.4-1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.1,356.4l2.5-3.5l0-0.1
        c0.6,0,1.2,0.1,1.8,0.1c1.1,0,2.2,0,3.2-0.1l0.5-6.2c-1.2,0.2-2.4,0.3-3.7,0.3c-0.2,0-0.3,0-0.4,0l-1.4,6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M691.2,346.5
        c3.5-0.6,6.6-1.9,9.5-4.1l-0.7-0.6c-2.6,2-5.5,3.2-8.7,3.8c-1.2,0.2-2.5,0.3-3.8,0.3c-0.1,0-0.2,0-0.2,0l-0.2,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M687.2,345.9l1.6-7.1
        c0.2-1,0.8-1.7,1.8-2.1c0.1,0,0.2-0.1,0.3-0.1l0.3-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c1-0.2,1.9,0,2.7,0.6l5.6,4.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.1,332.9
        c0.3,0.6,0.7,0.9,1.4,1.1c0.6,0.2,1.2,0.2,1.8-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.6,0.2-1.2-0.1-1.8c-0.3-0.5-0.7-0.9-1.3-1.1
        c-0.6-0.2-1.2-0.2-1.8,0.1c-0.6,0.3-1,0.7-1.2,1.3C682.7,331.8,682.8,332.4,683.1,332.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M675,320.9
        C675,320.9,675.1,320.9,675,320.9c0.5,0.7,0.8,1.5,0.9,2.7c0,0.1,0,0.2,0,0.2v2c0,0.1,0,0.1,0,0.2c-0.1,1.2-0.4,2.1-0.8,2.7
        c0,0,0,0,0,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M677.7,324.8
        c0,0.7,0.2,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7c0.6,0,1.2-0.2,1.7-0.7c0.4-0.5,0.7-1,0.7-1.7c0-0.6-0.2-1.2-0.7-1.6
        c-0.5-0.5-1-0.7-1.7-0.7c-0.6,0-1.2,0.2-1.7,0.7C677.9,323.7,677.7,324.2,677.7,324.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M687.4,328.6
        c-1,0-1.9-0.4-2.7-1.1c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.7-1.1c1,0,1.9,0.4,2.6,1.1
        c0.7,0.7,1.1,1.6,1.1,2.6c0,1.1-0.4,1.9-1.1,2.6C689.4,328.2,688.5,328.6,687.4,328.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M692,331
        c0.5,0.4,1.1,0.5,1.8,0.4c0.6-0.1,1.1-0.4,1.5-0.9c0.4-0.5,0.6-1.1,0.5-1.7c-0.1-0.6-0.4-1.1-1-1.5c-0.5-0.4-1.1-0.5-1.7-0.4
        c-0.6,0.1-1.1,0.4-1.5,1c-0.4,0.5-0.5,1.1-0.4,1.7C691.2,330.1,691.5,330.6,692,331z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M709.4,347.8l-13.3-11.6
        c-0.4-0.3-0.7-0.6-1-0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M694.7,322.3
        c0.5-0.4,0.8-0.9,0.9-1.5c0.1-0.6,0-1.2-0.4-1.7c-0.4-0.5-0.9-0.9-1.5-1c-0.7-0.1-1.2,0-1.7,0.4c-0.5,0.4-0.8,0.9-0.9,1.5
        c-0.1,0.7,0.1,1.2,0.4,1.7c0.4,0.5,0.9,0.8,1.5,0.9C693.6,322.9,694.2,322.7,694.7,322.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M699.7,328.9
        c0.4,0.3,0.8,0.5,1.2,0.8l15.2,9.1l-2.5-3.5l-0.1-0.1l-5.3-3.1l-0.8-0.5l-6.4-3.8c-0.9-0.5-1.4-1.3-1.4-2.4c0-0.1,0-0.2,0-0.3
        v-0.4c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.1-1,0.6-1.8,1.4-2.3l6.4-3.8l0.8-0.5l5.3-3.1l0.1-0.1l2.5-3.5l-15.2,9.1
        c-0.4,0.2-0.8,0.5-1.2,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M707.5,318.1
        c0.7,2.1,1,4.3,1,6.7c0,2.4-0.3,4.6-1,6.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M708.3,317.6
        c0.7,2.2,1.1,4.6,1.1,7.2c0,2.6-0.4,5-1.1,7.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="700.6,342.4 
        705.3,346.5 705.3,346.5 709.4,347.8 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M690.7,352.8
        c5.5-0.6,10.4-2.7,14.6-6.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M682.9,318.5
        c0.2,0.6,0.6,1.1,1.2,1.4c0.6,0.3,1.2,0.3,1.8,0.1c0.6-0.2,1.1-0.6,1.3-1.2c0.3-0.6,0.3-1.2,0.1-1.8c-0.2-0.6-0.6-1.1-1.1-1.3
        c-0.6-0.3-1.2-0.3-1.8-0.1c-0.6,0.2-1.1,0.6-1.4,1.1C682.7,317.3,682.6,317.9,682.9,318.5z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="674" y1="313.2" x2="677.4" y2="312.9"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="675.6,308.8 
        674.9,308.8 675.2,311.6 676.8,311.4 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="673.9" y1="311.7" x2="675.2" y2="311.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="675.2" y1="307.7" x2="674.9" y2="307.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="674.7" y1="306.7" x2="674" y2="307.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.1,293.3l3.9,17.2
        c0,0,0,0.1,0,0.1c0.1,0.4,0.2,0.8,0.3,1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M692.9,310l1.9-4.7l0.2-0.1
        l1.1-0.5v-0.3V304c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.3-0.7-0.4
        c-1.9-0.9-4.6-1.4-8.2-1.3l1.1,4.7l1.1,2v1.2l-0.3,0l0.3,1.5l0.9-0.1l1.9,0.8l0.7,0.2c0.1,0.1,0.2,0.1,0.3,0.1l3.5-0.3l1.9-1.7
        l-1,0.1L692.9,310l-0.2,0l-2.6-1.1c-0.1-0.1-0.2-0.1-0.3-0.1l-0.9,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M696.1,311.3l-1.6,1.4
        c-0.8,0.7-1.7,0.9-2.7,0.6c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-1-0.4-1.5-1.1-1.8-2.1
        l-0.1-0.4l1.3,0.8l1.5-0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="688.5" y1="309" x2="687.7" y2="305.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.1,293.3l2.5,3.5l0,0.1
        c0.6,0,1.2-0.1,1.8-0.1c2.7,0,5.3,0.3,7.7,1c3.7,1,7.1,2.8,10.1,5.4l0,0l4.1-1.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="686.6" y1="301.1" x2="685.7" y2="296.8"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="695,305.3 
        696.2,305.9 696.7,305.8 696.7,305.8 697.5,305.1 696,304.5 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="696,304 699.1,303.8 
        699.9,304.4 700,304.9 700.8,305.5 700.9,307 705.3,303.2 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="697.5,305.1 
        698.2,304.4 699.1,304.3 700,304.9 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="695.2" y1="297.8" x2="694.8" y2="302.3"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="696.2,305.9 
        695.6,305.9 695.7,307 696.8,307 696.7,305.8 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="700.9,307 
        698.3,309.3 697.9,309.6 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="698.3,309.3 
        698.1,305.7 696.7,305.8 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="696.8" y1="307" x2="696.9" y2="309.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M709.5,301.9l-13.3,11.6
        c-0.4,0.3-0.7,0.6-1.1,0.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="700.8" y1="305.5" x2="698.1" y2="305.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="716.2" y1="310.9" x2="716.1" y2="310.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="716.2" y1="338.8" x2="716.1" y2="338.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M713.6,335.3
        c1.3-3.2,1.9-6.7,1.9-10.5c0-3.7-0.6-7.2-1.9-10.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M597.6,258.8
        c0,0.2-0.1,0.4-0.1,0.6l-0.7,5.7c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2,0,0.3-0.1,0.5c-0.4,3.7-0.8,7.5-1.1,11.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M630.8,253.7l1-13.9
        c-6.9,0.9-13.5,2.2-19.6,4.1c0,0-0.1,0-0.1,0h0c-0.5,0.2-0.8,0.7-0.9,1.6l-1.5,21.3c0,0.4,0,0.6,0.1,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M611.2,268.4
        c3.6-1.7,7.2-3.2,10.6-4.5c2.6-1,5.2-1.9,7.8-2.7c0,0,0,0,0,0c1.1-0.4,1.8-1.4,1.9-3l0.3-3.9l1.1-15.2c-0.4,0.1-0.8,0.1-1.2,0.2
        c0,0,0,0,0,0c-2.6,0.3-5.1,0.7-7.5,1.1c-0.2,0-0.3,0.1-0.5,0.1c-3.6,0.7-7.1,1.5-10.5,2.5c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c-1.2,0.4-1.8,1.1-1.9,2.1l-0.1,0.8l-1.4,20.5c-0.1,1,0.2,1.6,0.7,1.8
        C609.7,268.9,610.4,268.8,611.2,268.4L611.2,268.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M630.8,253.7l-0.1,1.5
        l-0.1,0.9l-0.2,2c-0.1,1.1-0.5,1.7-1.2,2c-1.8,0.6-3.6,1.2-5.5,1.8c-2.2,0.8-4.4,1.7-6.7,2.6c-0.5,0.2-1,0.4-1.5,0.7
        c-1.4,0.6-2.9,1.2-4.3,1.9c-0.2,0.1-0.3,0.1-0.4,0.2c0,0,0,0,0,0c-0.4,0.2-0.8,0.3-0.9,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M596.8,265.4
        c0,0.2-0.1,0.4-0.1,0.6c0,0,0-0.1,0-0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="625.1" y1="255" x2="624.9" y2="255"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="610" y1="246.4" x2="607.7" y2="247.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M613.4,239.4l0,0.9h0
        c2.6-0.8,5.2-1.4,8-2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M613.1,239.5l0,0.9
        c0.1,0,0.2,0,0.3,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M622.5,236.4v0.9
        c0,0.5-0.2,0.8-0.6,0.9h0"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="624.3,240.5 
        623.5,238.7 623.5,236.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M624.3,240.5l0.1-3.5
        c7.7-1.3,17.4-2,29.1-2.1c5.8-0.1,12,0.2,18.6,0.7c3.8,0.2,7.9,2.1,12.3,5.5c6.2,4.9,11.4,11,15.4,18.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="632.2,232 627.7,232 
        627.9,230.4 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M621.3,237.4
        c-2.7,0.6-5.4,1.2-7.9,2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M607.7,247.4
        c0.1-2.4,0.2-4.5,0.2-6.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="613.3" y1="240.4" x2="613.2" y2="243.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="613" y1="240.4" x2="612.9" y2="243.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="612.8" y1="238.9" x2="612.6" y2="243.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M659.3,260.2l0.1-1.6l0.3-4.1
        l-8.6,0.5l-0.7,0l-2,0.1c-0.1,0-0.3,0-0.4,0c-0.8-0.1-1.2-0.6-1.2-1.5l0-0.5c-5,0.2-9.9,0.6-14.8,1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M651,255.1v-3.4v-13.4
        c-0.4,0.7-0.7,1.8-0.7,3.2v13.6"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="640.4,232 641.6,232 
        641.6,230.3 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M633,239.2
        c4.5-0.5,9.3-0.8,14.2-0.9l-0.1,3.2h3.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="641.6" y1="232" x2="645.8" y2="232"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M656.8,239.5
        c-0.3,0-0.5,0.2-0.5,0.5v8.8c0,0.3,0.2,0.5,0.5,0.5h5.6c0.3,0,0.5-0.2,0.5-0.5V240c0-0.3-0.2-0.5-0.5-0.5H656.8z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="665.3,230.6 
        665.2,232 663.9,232 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M651,251.7h8.8
        c6-0.1,9.2-2.2,9.8-6.2l0.9-6.7c-1.9-0.3-3.9-0.4-6-0.5H651"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M699,260.7
        c-2.5-4.5-5.7-8.7-9.8-12.5l-0.6,3c-0.4,1.4-1.4,1.8-3.2,1.1l-7.4-3.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.9-0.6-1.3-1.4-1.2-2.4l1-5.7
        c-0.8-0.3-1.7-0.5-2.6-0.8l-0.7,3.9l-0.5,3c-0.4,2-1.3,3.4-2.5,4.2c-1.8,1.1-4.2,1.9-7.1,2.4l-0.9,13.2c-2.3-0.8-3.5-2.7-3.8-5.7
        c0-0.2,0-0.4,0-0.6c0-0.5,0-1,0-1.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="664.1" y1="253.2" x2="659.6" y2="254.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="646.7" y1="253.2" x2="647.1" y2="241.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="658.9" y1="269.8" x2="658.7" y2="266.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M659.1,273.6
        c0.4-0.1,0.8-0.1,1.2-0.2c12.1-0.9,23.8-1.3,35.2-1.4c1.7-0.1,3-0.7,4.2-1.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M697.3,272.2
        c-0.9,0.3-1.9,0.5-3.1,0.6h-0.3c-10.9,0.1-22,0.5-33.5,1.4c-0.4,0.1-0.8,0.1-1.2,0.2l-5,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M598.9,259.5
        c0.2-2.7,0.2-5,0.1-6.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M677.5,240.5
        c2.4,0.9,4.6,2,6.5,3.3c0.5,0.4,1,0.7,1.5,1.2c1.3,1,2.5,2.1,3.7,3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M689.4,240.5v1.6
        c-0.1-0.2-0.3-0.3-0.5-0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M696.9,248.9
        c-0.5-0.6-0.9-1.2-1.4-1.8c-0.1-0.1-0.2-0.2-0.3-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M674.9,239.7
        c-1.4-0.4-2.9-0.7-4.5-0.9l3.8,4.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M700.9,264.5
        c-13.6,0.2-27.6,1-42,2.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="675.4" y1="404.8" x2="673.6" y2="531.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="530.6" y1="753" x2="530.9" y2="751.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M594.2,756.3
        c-0.4,0-0.6-0.1-0.7-0.2v0c0,0,0-0.1,0-0.1v0c-1.3,0-2.6,0-3.9,0c-0.5-0.1-0.8-0.3-0.8-0.6v-0.4c0.1-0.3,0.4-0.5,0.9-0.5
        c1.3,0,2.6,0,3.8,0c0,0,0,0,0-0.1c0.2-0.2,0.6-0.3,1.1-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M606,756.4c-0.1,0-0.2,0-0.3,0
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M626.7,756.3
        C626.7,756.3,626.7,756.3,626.7,756.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="593.5" y1="755.9" x2="593.5" y2="754.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M680,668.7
        c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4
        c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1C680.7,668.8,680.4,668.7,680,668.7z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="679.5,668.4 
        678.3,669.7 678.8,671.3 680.5,671.8 681.7,670.5 681.2,668.9 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M684.7,661.7
        c-0.4,0.1-0.6,0.3-0.8,0.7c-0.2,0.4-0.2,0.7-0.1,1.1c0.1,0.4,0.4,0.6,0.7,0.8c0.3,0.2,0.7,0.2,1,0.1c0.4-0.1,0.7-0.4,0.8-0.7
        c0.2-0.3,0.2-0.7,0.1-1.1c-0.1-0.4-0.3-0.7-0.7-0.8C685.4,661.6,685,661.6,684.7,661.7z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="685.2,664.8 
        686.6,663.8 686.5,662.1 685,661.3 683.6,662.2 683.6,664 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M692,665.4
        c-0.1,0.4,0,0.7,0.3,1.1c0.2,0.3,0.5,0.5,0.9,0.6c0.4,0.1,0.7,0,1-0.3c0.3-0.2,0.5-0.5,0.6-0.9c0-0.4-0.1-0.7-0.3-1
        c-0.2-0.3-0.5-0.5-0.9-0.6c-0.4-0.1-0.7,0-1.1,0.2C692.2,664.8,692,665.1,692,665.4z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="691.7,666.3 
        693.1,667.3 694.7,666.7 695,665 693.6,663.9 692,664.5 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M616.3,756.8v0.4
        c-0.1,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.5,0.4-0.8,0.5c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.4,0c-3.3,0.1-6.3,0-9-0.2
        c-0.6-0.1-1-0.2-1.3-0.4c-0.3-0.2-0.4-0.4-0.4-0.6v-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M606,756.4
        c0.3,0,0.4-0.2,0.4-0.4v-1.6h0c0-0.2-0.2-0.3-0.7-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M618.5,750.7v0.3
        c0,0.1-0.1,0.2-0.2,0.2h-3.9c-0.1,0-0.1-0.1-0.1-0.2v-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M613.8,750.7v0.8
        c0,0.1,0.1,0.2,0.2,0.2h4.8c0.1,0,0.2-0.1,0.2-0.2v-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M615,753.9
        c-0.5,0-0.8,0.1-0.8,0.4v1.6v0c0,0.1,0,0.1,0,0.2c0.1,0.1,0.3,0.2,0.6,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M615.2,753.9c0,0-0.1,0-0.1,0"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M626.7,756.3
        c0.3,0,0.4-0.2,0.4-0.4v-1.6c0-0.2-0.1-0.3-0.3-0.4c-0.1,0-0.2,0-0.3,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M614.2,754.3
        c-2.6,0-5.3,0-7.9,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M614.2,755.9
        c-2.6,0-5.3,0-7.9,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M626.5,750.7v0.8
        c0,0.1-0.1,0.2-0.2,0.2h-4.6c-0.1,0-0.2-0.1-0.2-0.2v-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M626,750.7v0.3
        c0,0.1,0,0.2-0.1,0.2h-3.7c-0.1,0-0.2-0.1-0.2-0.2v-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M627.1,754.3
        c1.6,0,3.1,0,4.6,0c0.5,0,0.7,0.2,0.8,0.6v0.6c-0.1,0.3-0.2,0.4-0.5,0.4h0c-1.6,0-3.2,0-4.8,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M647.6,749
        c2.8-0.4,4.5-2.6,5.1-6.6l1.9-13.2c0-0.2,0-0.3,0-0.5c0.1-2.2-0.8-3.7-2.7-4.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M686.6,694
        c1.6,0.7,2.8,2,3.6,4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M683.9,676.7
        c-0.1,0.4-0.1,0.7,0,1.1c0.2,0.3,0.5,0.6,0.8,0.7c0.4,0.1,0.7,0.1,1.1-0.1c0.4-0.2,0.6-0.5,0.7-0.8c0.1-0.4,0.1-0.7-0.1-1.1
        c-0.2-0.3-0.4-0.6-0.8-0.7c-0.4-0.1-0.7-0.1-1.1,0.1C684.2,676,684,676.3,683.9,676.7z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="684.4,675.6 
        683.5,677 684.2,678.6 686,678.6 686.9,677.2 686.1,675.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M692.6,675.5
        c0.3,0.2,0.7,0.3,1.1,0.3c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.3-1c-0.1-0.4-0.2-0.7-0.6-0.9c-0.3-0.2-0.7-0.3-1-0.2
        c-0.4,0.1-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.7-0.3,1.1S692.3,675.3,692.6,675.5z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="692.8,676 
        694.5,675.7 695.1,674.1 694,672.7 692.4,673 691.7,674.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M681,323.8
        c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4
        c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1C681.4,324.5,681.2,324.1,681,323.8z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="681.2,323.7 
        679.5,323.2 678.3,324.4 678.8,326.1 680.4,326.5 681.7,325.3 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M692,329.4
        c0.1,0.4,0.3,0.7,0.6,0.9c0.3,0.2,0.7,0.3,1.1,0.3c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.3-1c-0.1-0.4-0.2-0.7-0.5-0.9
        c-0.3-0.2-0.7-0.3-1-0.3c-0.4,0.1-0.7,0.2-0.9,0.5C692,328.6,691.9,329,692,329.4z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="685.9,333.4 
        686.9,332 686.1,330.4 684.4,330.3 683.4,331.8 684.2,333.4 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M684.7,333.2
        c0.4,0.1,0.7,0.1,1.1-0.1c0.4-0.2,0.6-0.5,0.7-0.8c0.1-0.4,0.1-0.7-0.1-1.1c-0.2-0.3-0.4-0.6-0.8-0.7c-0.4-0.1-0.7-0.1-1.1,0.1
        c-0.3,0.2-0.5,0.4-0.7,0.8c-0.1,0.4-0.1,0.7,0,1.1C684.1,332.9,684.3,333.1,684.7,333.2z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="692.8,330.8 
        694.5,330.5 695.1,328.9 694,327.5 692.3,327.8 691.7,329.4 		"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="694.7,321.5 
        695,319.8 693.6,318.7 692,319.4 691.7,321.1 693.1,322.1 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M694.2,321.6
        c0.3-0.2,0.5-0.5,0.6-0.9c0-0.4-0.1-0.7-0.3-1.1c-0.2-0.3-0.5-0.5-0.9-0.6c-0.4-0.1-0.7,0-1.1,0.3c-0.3,0.2-0.5,0.5-0.6,0.9
        c-0.1,0.4,0,0.7,0.3,1c0.2,0.3,0.5,0.5,0.9,0.6C693.5,321.9,693.9,321.8,694.2,321.6z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="685.2,319.5 
        686.6,318.6 686.5,316.8 685,316 683.5,317 683.6,318.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M684.5,319
        c0.3,0.2,0.7,0.2,1,0.1c0.4-0.1,0.7-0.4,0.8-0.7c0.2-0.3,0.2-0.7,0.1-1.1c-0.1-0.4-0.3-0.7-0.7-0.8c-0.3-0.2-0.7-0.2-1.1-0.1
        c-0.4,0.1-0.6,0.4-0.8,0.7c-0.2,0.4-0.2,0.7-0.1,1.1C683.9,318.6,684.1,318.9,684.5,319z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="609.8,267.6 
        610.3,264.3 610.5,263 610.7,262 611.6,256.3 611.7,255.3 611.9,254.4 612,253.4 612.1,244 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M630.9,254.2
        c0-0.2,0-0.4-0.1-0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M615.5,265.3
        c-1.3,0.2-2.6,0.3-3.8,0.4l-0.5,1.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M610.3,264.3
        c2.2,0,4.4-0.2,6.8-0.6v-1.4c-1.9,0.3-4.1,0.5-6.5,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M623.8,262
        c-2.3,0.7-4.5,1.3-6.7,1.7v0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M624.9,255c-0.1,0-0.3,0-0.4,0
        c3.3,0.8,3.3,2.1,0.3,3.9c-2,1-4.6,1.8-7.9,2.4c-1.9,0.3-4,0.5-6.2,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M617.1,262.3
        c4.3-0.7,7.5-1.8,9.6-3.2c0.2-0.2,0.4-0.4,0.6-0.5c0.4-0.4,0.6-0.8,0.6-1.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3
        c-0.1-0.3-0.2-0.6-0.4-0.8c-0.5-0.5-1.2-0.8-2.2-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M612,253.4
        c0.2-0.1,0.4-0.1,0.6-0.2c4.4-1.2,8.9-1.7,13.5-1.5c2.7,0.1,4.2,0.8,4.6,2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M611.6,256.3
        c0.4-0.1,0.7-0.2,1.1-0.3c3.9-0.9,7.8-1.3,11.9-1.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M630.7,255.2
        c-0.1-1.5-1.6-2.4-4.7-2.5c-4.6-0.2-9,0.2-13.3,1.4c-0.3,0.1-0.5,0.1-0.8,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M611.7,255.3
        c0.3-0.1,0.6-0.2,0.9-0.3c4.2-1.1,8.6-1.6,13.2-1.3c3.1,0.1,4.7,0.9,4.8,2.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M657.1,240.3
        c-0.3,0-0.4,0.1-0.4,0.4v7.8c0,0.3,0.1,0.4,0.4,0.4h4.8c0.3,0,0.4-0.1,0.4-0.4v-7.8c0-0.3-0.1-0.4-0.4-0.4H657.1z"/>
    </g>
  </g>
  <g id="Layer_3">
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M310.8,14.7
        c-1,0.1-2,0.2-3,0.3c-0.4,0-0.7,0.1-1,0.1c-0.1,0-0.2,0-0.4,0c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0.4-0.9,0.8-1.3,1.2
        c0.6-0.1,1.2-0.1,1.8-0.2c0.1,0,0.2,0,0.4,0c0.6-0.1,1.2-0.1,1.8-0.2c0.1,0,0.3,0,0.4,0c0.3-0.3,0.6-0.6,0.9-0.9
        C310.3,15.2,310.5,15,310.8,14.7c0.4-0.1,0.9-0.1,1.3-0.1c0.5-0.1,1-0.1,1.5-0.2c0.7-0.1,1.4-0.1,2.2-0.2c3.1-0.3,6.3-0.6,9.5-0.9
        l4.9-0.3c0.5-0.2,0.9-0.5,1.3-0.8c0.2-0.1,0.3-0.2,0.4-0.4l2.9-0.2c2.6-0.1,5.1-0.3,7.7-0.4c0.4,0.4,0.9,0.7,1.5,1
        c0,0,0.1,0,0.1,0c3.5-0.1,6.9-0.3,10.4-0.3c0,0,0,0,0,0c0.2-0.1,0.3-0.2,0.5-0.3l1.3-0.8c3.3-0.1,6.6-0.1,9.9-0.2c0,0,0.1,0,0.1,0
        l1.4,0.7c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0,0,0,0c0.3,0,0.7,0,1,0h6.5c0.4,0,0.7,0,1,0c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.5-0.3l1.4-0.7
        c0,0,0.1,0,0.1,0c3.3,0,6.6,0.1,9.9,0.2l1.3,0.8c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0,0,0,0c3.5,0.1,6.9,0.2,10.4,0.3c0,0,0.1,0,0.1,0
        c0.6-0.3,1.1-0.6,1.5-1c2.6,0.1,5.1,0.2,7.7,0.4l2.9,0.2c0.1,0.1,0.3,0.2,0.4,0.4c0.4,0.3,0.8,0.5,1.3,0.8l4.9,0.3
        c3.2,0.3,6.4,0.5,9.5,0.9c0.7,0.1,1.4,0.1,2.2,0.2c0.5,0.1,1,0.1,1.5,0.2c0.4,0,0.9,0.1,1.3,0.1c1,0.1,2,0.2,3,0.3
        c0.4,0,0.7,0.1,1,0.1c0.1,0,0.2,0,0.4,0c0.8,0.1,1.6,0.2,2.4,0.3c0.3,0.1,0.7,0.1,1,0.2c1.6,0.4,3.1,1.1,4.5,2
        c1.3,0.8,2.5,1.8,3.6,3c0.9,0.9,1.7,1.9,2.4,3c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.1,0.1,0.2,0.1,0.3
        c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0.4,0.3,0.8,0.4,1.2l0.4,1.3l0.3,0.8h3.8c0.8,0,1.3,0.3,1.5,1l0.6,2.4H460c1,0,1.6,0.6,2,1.8
        c0,0.1,0.1,0.2,0.1,0.3c0.4,1.3,0.8,2.7,1.2,4.1c3.1,11.2,6.1,23.6,9,37.5h0.5V62.4c0-0.1,0-0.2,0-0.2c0.2-2.9,1.8-4.4,4.8-4.5
        h4.2h3.3c3.8,0,5.7,2.1,5.7,6.4c0,0.1,0,0.2,0,0.4v6.6c0,3.1-0.7,5.5-2,7c-1.3,1.5-3.2,2.3-5.8,2.2h-0.3H473
        c0.1,0.5,0.2,0.9,0.3,1.4c0.1,0.5,0.2,0.9,0.3,1.4c0.6,2.7,1.7,4.6,3.4,5.7c0.3,0.2,0.5,0.3,0.8,0.4l0.5,0.3
        c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.4l2.2,5c0.1,0.2,0.2,0.4,0.2,0.7
        c0.1,0.3,0.2,0.6,0.3,0.9c0,0.2,0.1,0.4,0.1,0.6v0c0.1,0.6,0.2,1.2,0.3,1.8c1.1,6.2,1.7,12.5,1.8,18.7c0.1,0.2,0.2,0.5,0.3,0.7
        l1.4,5.8c0,0.4,0,0.7,0,1.1c0,0.5,0,1.1,0,1.6c0,0.7,0,1.4,0,2c0,0.4,0,0.9,0,1.3c-0.2,7.3-0.7,15.4-1.4,24.4v71
        c0,3.8-1.9,5.7-5.7,5.7h-17.8c-3.8,0-5.7-1.9-5.7-5.7v-31.5c-8.5,2.2-16.6,3.7-24.4,4.5c-1.7,0.2-3.5,0.3-5.3,0.3
        c-0.1,0-0.2,0-0.3,0h-8.5h-4.4H409h-1.1h-13.6h-1.2H352h-1.2h-13.6h-1.1h-3.7H328h-8.5c-0.1,0-0.2,0-0.3,0c-1.8,0-3.5-0.1-5.3-0.3
        c-7.7-0.8-15.9-2.4-24.4-4.5v31.5c0,3.8-1.9,5.7-5.7,5.7h-17.8c-3.8,0-5.7-1.9-5.7-5.7v-71c-0.8-9-1.3-17.1-1.4-24.4
        c0-0.4,0-0.9,0-1.3c0-0.7,0-1.4,0-2c0-0.5,0-1.1,0-1.6c0-0.4,0-0.7,0-1.1l1.4-5.8c0.1-0.2,0.2-0.5,0.3-0.7c0-0.1,0-0.1,0.1-0.2
        c0.1-0.3,0.3-0.6,0.5-0.9c0.1-0.2,0.3-0.4,0.4-0.6v0l0.1-0.1c0-0.1,0.1-0.1,0.1-0.2l0.3-0.4c0.7-1,1.3-2,1.9-3.1l0.2,0.5
        c0.4-0.7,0.9-1.5,1.3-2.4c0.1-0.2,0.2-0.4,0.3-0.7l0.1-1.6c0-0.5,0.1-1.1,0.1-1.6c0-0.1,0-0.1,0-0.2c0.1-0.8,0.2-1.5,0.3-2.3
        c0-0.1,0-0.2,0.1-0.3v-0.1l-1.5-0.2l-0.5-0.1c-0.2,0-0.3-0.1-0.3-0.4l0.4-3.4c0-0.2,0.1-0.3,0.4-0.3l0.5,0.1h0l0.6,0.1l0.1-1.2
        c0-0.2,0.1-0.3,0.4-0.3l0.5,0.1h0l0.2,0c0.1-0.6,0.2-1.3,0.3-1.9c0,0,0-0.1,0-0.1v0c0-0.1,0-0.2,0.1-0.3l0-0.3c0,0,0,0,0-0.1v0
        c0,0,0-0.1,0-0.1v0c0-0.1,0-0.1,0.1-0.2v-0.1c0-0.1,0-0.1,0-0.2c0.1-0.3,0.2-0.6,0.3-0.8c0,0,0,0,0-0.1c0.1-0.1,0.1-0.3,0.2-0.4
        c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1v0l0-0.1v0
        c0-0.1,0.1-0.1,0.1-0.2c0.5-1,1.1-2,1.7-2.9l0.3-0.4l0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.5,0.5-0.8,0.6-0.9
        c1-1.3,2.2-2.5,3.3-3.4c0.2-0.2,0.4-0.3,0.5-0.4l0.1-0.6l0.3-3.2c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0h-1.6h-12h-0.3
        c-2.6,0.1-4.5-0.7-5.8-2.2c-1.3-1.5-2-3.9-2-7v-6.6c0-0.1,0-0.3,0-0.4c0-4.2,1.9-6.4,5.7-6.4h3.3h4.2c3,0.1,4.6,1.6,4.8,4.5
        c0,0.1,0,0.2,0,0.2v13.4h4.9c0.1-0.6,0.2-1.2,0.4-1.7c3.2-14.6,7-28.3,11.4-41.3l0.2-0.6l1.3-4.2l0.4-1.3c0.1-0.4,0.2-0.8,0.4-1.2
        c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.8-1.1,1.6-2.2,2.4-3
        c1.1-1.2,2.3-2.2,3.6-3c1.4-0.9,2.9-1.5,4.5-2c0.3-0.1,0.7-0.2,1-0.2c0.8-0.1,1.6-0.2,2.4-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.6,16c0.2,0,0.3,0,0.5,0
        c2.8-0.2,5.6-0.5,8.5-0.7l9.7-0.6c1.7-0.1,3.4-0.2,5.1-0.2c9.5-0.4,19-0.7,28.6-0.7c1,0,1.9,0,2.9,0h7.4c1,0,1.9,0,2.9,0
        c9.6,0.1,19.1,0.3,28.6,0.7c1.7,0.1,3.4,0.2,5.1,0.2l9.7,0.6c2.8,0.2,5.7,0.5,8.5,0.7c0.2,0,0.3,0,0.5,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M330.2,13l4.3-0.3
        c2.6-0.1,5.3-0.3,7.9-0.4l0,0c0.5,0,1,0,1.6-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M263.4,57.6v3.5h2.8
        c1.1,0,1.6,0.5,1.6,1.6v11.3c0,1.1-0.5,1.6-1.5,1.6h6.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M262.4,80.1l1-4.4h-3.9
        c-1,0-1.5-0.6-1.5-1.6V62.8c0-1.1,0.5-1.6,1.6-1.6h3.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M270.4,89.7
        c0,0.1-0.1,0.2-0.1,0.2C270.3,89.9,270.3,89.8,270.4,89.7l0.3-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M267.4,95.5c0,0,0-0.1,0-0.1
        c0-0.1,0.1-0.3,0.1-0.4l1.1,1.5c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3,0.4-0.6,0.6-0.9c0.4-0.6,0.8-1.1,1.2-1.7
        c0.3-0.4,0.6-0.8,0.9-1.2c1.5-2,3.1-3.6,4.7-4.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.5-1-0.8-1.9-0.7-2.8v0"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="266.3,75.8 
        263.4,75.8 263.4,61.1 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M304.6,16.9
        c-0.3,0.2-0.5,0.5-0.8,0.8c-2,1.9-4,4.2-6,6.7l-0.7,0.9c24.6-0.6,49.3-0.8,73.9-0.8c0.1,0,0.3,0,0.4,0h0c0.7,0,1.4,0,2.1,0h0.4
        c24.6,0,49.2,0.3,73.9,0.8l-0.7-0.9c-2-2.5-4-4.8-6-6.7c-0.3-0.3-0.5-0.5-0.8-0.8c-0.6-0.1-1.2-0.1-1.8-0.2c-0.1,0-0.2,0-0.4,0
        c-0.6-0.1-1.2-0.1-1.8-0.2c-0.1,0-0.3,0-0.4,0c-1.5-0.2-3-0.3-4.6-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M291.7,25.1
        c0.2,0.1,0.4,0.2,0.7,0.2c0.3,0,0.6,0,0.9,0.1c0.2,0,0.4,0,0.6,0c1.1,0,2.1-0.1,3.2-0.1l-0.9,2.9c-0.3,0-0.5,0-0.8,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M289.3,32.2h0.3h4.9
        c0.4-1.3,0.8-2.7,1.1-4c-0.4,0-0.8,0-1.2,0c-1.4,0-2.7-0.1-3.7-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M281.7,62.9
        c2.1-9.4,4.6-19.4,7.7-30.1l0.2-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M295.2,32.3
        c-4,14.4-7.2,29.3-9.8,44.8c0,0.1,0,0.2-0.1,0.3c-0.2,1.4-0.5,2.9-0.7,4.3c0.4,0,0.7,0,1.1-0.1c0.1,0,0.1,0,0.2,0
        c28.4-1.4,56.8-2.1,85.2-2.1c0.1,0,0.3,0,0.4,0h1c0,0,0.1,0,0.1,0h1c0.1,0,0.3,0,0.4,0c28.4,0,56.8,0.7,85.2,2.1
        c0.1,0,0.1,0,0.2,0c0.4,0,0.7,0,1.1,0.1c-0.2-1.5-0.4-2.9-0.7-4.3c0-0.1,0-0.2-0.1-0.3c-2.5-15.5-5.8-30.4-9.8-44.8
        c0,0,0-0.1,0-0.1c-0.4-1.3-0.8-2.7-1.1-4c-24.9-0.6-49.8-0.8-74.7-0.8h-3c-24.9,0-49.8,0.3-74.7,0.8c-0.4,1.3-0.8,2.7-1.1,4
        C295.2,32.2,295.2,32.3,295.2,32.3z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="294.4" y1="32.2" x2="295.2" y2="32.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M285.4,77.1h-1
        c-0.2,1.4-0.5,2.8-0.7,4.2c-0.1,0-0.1,0-0.2,0l-5.4,0.1c0,0.1-0.1,0.3-0.1,0.4l6.4-0.1c0.1,0,0.1,0,0.2,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M279,76.1l-0.9,4.1h-0.6
        c0.2,0.2,0.4,0.4,0.7,0.6c0.1-0.8,0.3-1.7,0.4-2.6c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0-0.2,0.1-0.3C278.9,76.7,279,76.4,279,76.1
        l0.1-0.3h-0.4h-1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M278.3,80.7
        c0,0.2-0.1,0.4-0.1,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276,80.2
        C276.1,80.2,276.1,80.1,276,80.2L276,80.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276.2,80.1
        C276.2,80.1,276.2,80.1,276.2,80.1L276.2,80.1C276.3,80.1,276.2,80.1,276.2,80.1z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="276.3,80.1 
        276.5,80.1 277.6,80.1 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276,80.2
        c0,0.8,0.5,1.4,1.5,1.9l0.5-0.3c-0.3-0.1-0.5-0.3-0.7-0.4c-0.5-0.4-0.8-0.8-0.9-1.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="274.4" y1="80.1" x2="276.2" y2="80.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="276,80.2 275.6,83.4 
        275.6,84 275.5,84 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276.4,87.1
        c0.2-0.1,0.4-0.4,0.4-0.7l0.7-4.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276.8,87.6
        c1.1,1.5,2.5,2.7,4.1,3.8c0.9,0.6,1.9,1.1,3.1,1.5c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0.1,0.3,0.1c0.6,0.2,1.2,0.3,1.8,0.5
        c0.1,0,0.2,0,0.3,0.1c5.3,1.1,13,1.8,23.2,2.1h1.9l-0.4-2.5c-0.5,0-1,0-1.5,0c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0-1.8-0.1
        c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2-0.1-1.8-0.1c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2-0.1-1.8-0.1c-0.1,0-0.2,0-0.3,0
        c-0.6,0-1.2-0.1-1.8-0.1c-0.1,0-0.2,0-0.3,0c-0.6-0.1-1.2-0.1-1.8-0.2c-0.1,0-0.2,0-0.3,0c-0.6-0.1-1.2-0.1-1.8-0.2
        c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.2,0-0.3-0.1c-0.6-0.1-1.2-0.3-1.8-0.4
        c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1.2-0.4-1.8-0.6c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.2-1.2-0.5-1.8-0.8c0,0-0.1,0-0.1-0.1l-0.2-0.1
        l-1.8-0.9l-0.3-0.1l-1.8-1l-0.3-0.1l-0.1-0.1l-1.6-0.8l-6.9,0.2C276.6,87.2,276.7,87.4,276.8,87.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M285,87.7h0.1h0.3l1.8,0h0.3
        l1.8,0h0.3l1.8,0h0.3l1.8,0h0.3l1.8,0h0.3l1.8,0h0.3l1.8,0h0.3l1.8,0h0.3l1.8,0h0.3l1.8,0h0.3h0.5c0.2,0,0.4,0,0.6,0.1
        c0.3,0,0.5,0.1,0.7,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2l0,0c0.5,0.5,0.9,1.1,1.3,1.6
        c0.1,0.1,0.2,0.3,0.3,0.4c0.6,0.8,1.1,1.7,1.5,2.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.5,95.9h3.8
        c5.3-0.9,8.6-2.8,9.9-5.7l0.1-0.2c0-0.1,0.1-0.2,0.1-0.3l0,0c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.2,0-0.4l-1.4-5.2
        c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.8,0.8-2.1,1.3-3.6,1.4c-0.2,0-0.3,0-0.5,0l-36.6,1.1
        l-0.4,0"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="284.5" y1="81.7" x2="283.7" y2="86.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="278.9" y1="77.1" x2="284.4" y2="77.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M281.7,62.9
        c-1,4.4-1.8,8.7-2.6,12.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M325,83.7
        c0.3-0.2,0.7-0.3,1.1-0.3c15-0.8,30-1.2,45-1.2h0.4c0.4,0,0.8,0,1.2,0c0.3,0,0.6,0,1,0h0c0.1,0,0.3,0,0.4,0c15,0,30,0.4,45,1.2
        c0.4,0,0.7,0.1,1.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.1,0.2,0.2c0.8,0.8,2.1,1.3,3.6,1.4
        c0.2,0,0.3,0,0.5,0l36.6,1.1l-0.7-5.1c-0.1,0-0.1,0-0.2,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.6,16
        c-1.5,0.1-3.1,0.3-4.6,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M271.8,92l0.2,0.3
        c0.1,0.1,0.1,0.4-0.1,0.7c-0.5,0.6-0.9,1.2-1.4,1.9c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.5,0.7-0.7,1c0,0.1-0.1,0.1-0.1,0.2
        l0.7,0.9c0.8,1,1.9,1.9,3.1,2.7c1.3,0.8,2.8,1.3,4.5,1.8c5.5,1.5,14.3,2.3,26.4,2.6h9l-0.3-9.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M329.9,110.2
        c0.1,0,0.2,0,0.3,0c0.9,0,1.9,0,2.8,0c0.2,0,0.5,0,0.7,0c5.8,0,11.5,0.1,17.3,0.1c0.2,0,0.4,0,0.7,0c4.7,0,9.3,0,14,0
        c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0,0,0.1,0,0.1,0c0.4,0,0.9,0,1.3,0c1.1,0,2.2,0,3.3,0h3c1.1,0,2.2,0,3.3,0c0.5,0,0.9,0,1.3,0
        c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0,0,0.1,0,0.1,0c4.6,0,9.3,0,14,0c0.2,0,0.5,0,0.7,0c5.8,0,11.5-0.1,17.3-0.1
        c0.2,0,0.4,0,0.7,0c0.9,0,1.9,0,2.8,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c3.5,0,7.1-0.1,10.6-0.1c0.1-1.7,1.2-2.7,3.4-2.8l2.9-0.1
        l0.1-2.3H312.8l0.1,2.3l2.9,0.1c2.2,0.1,3.3,1,3.4,2.8c3.6,0,7.1,0.1,10.6,0.1C329.9,110.2,329.9,110.2,329.9,110.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M319.2,110.2
        C319.2,110.2,319.2,110.2,319.2,110.2c0,0.2,0,0.3,0,0.4c0,0,0,0,0,0v10.8c0.2,1.9,2.5,3.2,6.7,3.9c1.2,0.2,2.6,0.3,4.2,0.4h85
        c1.6-0.1,3-0.3,4.2-0.4c4.2-0.7,6.5-2,6.7-3.9v-10.8c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4c0,0,0,0,0-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M318.4,130.6l-28.7-1.4v14.6
        c0,0,0.1,0,0.1,0l29.3,1.1v-15c0,0.1,0,0.1-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3C318.7,130.6,318.5,130.6,318.4,130.6z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="319.2" y1="121.4" x2="319.2" y2="130"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M330.3,110.2v7.4
        c0,0.8,0.5,1.3,1.4,1.4c0.4,0,0.9,0,1.3,0c0.2,0,0.5,0,0.7,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M415.3,110.2v7.4
        c0,1.1-0.6,1.7-1.9,1.9c-0.5,0-0.9,0-1.4,0.1c-0.2,0-0.5,0-0.7,0c-1.1,0-2.2,0-3.4,0h-70.8c-1.2,0-2.3,0-3.4,0c-0.2,0-0.4,0-0.7,0
        c-0.5,0-0.9,0-1.4-0.1c-1.3-0.1-1.9-0.7-1.9-1.9v-7.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M330.2,131.6
        c0-0.1,0-0.2,0.1-0.3c0.1-0.5,0.5-0.8,1.1-0.8h4.1h0.7h9.7h0.7h8.8h0.7h9.6h0.7h12.5h0.7h9.6h0.7h8.8h0.7h9.7h0.7h4.1
        c0.6,0,0.9,0.3,1.1,0.8c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2v2.2c0,0.2,0,0.4-0.1,0.5c-0.1,0.5-0.5,0.8-1.1,0.8h-4.1H409h-9.7
        h-0.7h-8.8h-0.7h-9.6h-0.7h-12.5h-0.7H356h-0.7h-8.8h-0.7h-9.7h-0.7h-4.1c-0.6,0-0.9-0.3-1.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.5v-2.2
        C330.2,131.7,330.2,131.6,330.2,131.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M328.8,131.3v5.7
        c0,1.4,0.7,2.2,2.2,2.2h83.1c1.4,0,2.2-0.7,2.2-2.2v-5.7c0-1.4-0.7-2.2-2.2-2.2H331C329.6,129.1,328.8,129.8,328.8,131.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M289.7,129.2L285,129
        c-1.9-0.1-3-0.9-3.1-2.4v0c-0.5-6.1-0.6-12.3-0.2-18.6c0.1-1.4,0.9-2.2,2.3-2.2c0,0,0,0,0.1,0c3.9,0.3,8,0.6,12.4,0.8l16.5,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M292.2,148.3h27.1l0-0.6h-29.5
        L292.2,148.3v8.7c0.2,4.5,2,7.6,5.3,9.1c1.6,0.6,3.7,0.9,6.3,1l15.5,0.9v-18l0-1.7h0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.3,159.7l0.1-2.6
        l-15.7-0.5l-4.7-0.1c0.1,1,0.3,2,0.4,3c0,0.1,0,0.1,0,0.2c0.2,0.8,0.9,1.2,2.1,1.2l17.9,0.5C286.3,160.8,286.3,160.3,286.3,159.7z
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.4,157.1l0-0.8l0.3-9.3
        c0.1-2,1.1-3.1,3-3.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M295.6,154.8c0-0.7,0.3-1,1-1
        h17.6c0.7,0,1,0.3,1,1v4.7c0,0.7-0.3,1-1,1h-17.6c-0.7,0-1-0.3-1-1V154.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.4,161.4
        c0.5,2.7,2.3,5.1,5.3,7.3c2.6,1.6,5.4,2.6,8.5,2.8l19,1.1v-0.4l-20-2.2c-0.9-0.1-1.7-0.3-2.6-0.6c-0.5-0.2-1-0.3-1.5-0.6l-0.2-0.1
        c-0.2-0.1-0.4-0.2-0.5-0.3c-2.1-1.1-4-2.7-5.7-4.8c-1.4-1.9-2.2-4.3-2.3-7.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M295,168.6
        c-1-0.5-1.9-1.2-2.5-2c-1.8-2.1-2.7-4.7-2.8-7.9v-11"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M331,142.7
        c-1.5,0.3-2.3,1.6-2.4,3.9v13.6c0.1,1.9,1.2,2.9,3.2,3h13.4c0-0.2,0.1-0.3,0.2-0.5c0,0,0,0,0,0c1.2-1.9,2.7-3.7,4.3-5.2v0
        c0.7-0.7,1.7-1,2.9-1h40c1.2,0,2.2,0.4,2.9,1v0c1.6,1.5,3.1,3.3,4.3,5.2c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.2,0.5h13.4
        c2-0.1,3.1-1.1,3.2-3v-13.6c-0.1-2.3-0.9-3.6-2.4-3.9c-0.2-0.1-0.4-0.1-0.7-0.1h-81.7C331.4,142.7,331.2,142.7,331,142.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M330,147.3v6.1
        c0,1.3,0.9,2.1,2.5,2.3c0.3,0,0.7,0.1,1.1,0.1h78c0.4,0,0.7,0,1.1-0.1c1.6-0.3,2.4-1,2.5-2.3v-6.1c0-0.2,0-0.4-0.1-0.6
        c-0.2-1.5-1-2.2-2.4-2.3h-80.2c-1.4,0.1-2.2,0.9-2.4,2.3C330,146.8,330,147,330,147.3z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="319.2,144.9 
        319.2,147.7 319.3,147.7 319.3,148.3 319.3,150 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="269.9" y1="95.8" x2="269.1" y2="95.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="268.8" y1="96.2" x2="269.2" y2="96.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="269.7" y1="94.8" x2="270.5" y2="94.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M266.9,98.5L266.9,98.5
        c0.1-0.6,0.2-1.3,0.3-1.9c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M267.3,95.9c0,0,0-0.1,0-0.1"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265.7,100l0.9,0.1
        c0.1-0.5,0.2-1,0.2-1.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M268.7,96.4
        c-1,4.5-1.7,9.1-2.2,13.8c0.1-0.2,0.3-0.4,0.4-0.6l0,0c0.1-0.9,0.2-1.8,0.3-2.7c0,0,0-0.1,0-0.1c0.4-2.9,0.8-5.7,1.4-8.4l0.4-1.4"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M266.5,110.2
        c-0.1,0.2-0.3,0.4-0.4,0.6l-0.7,0.9l-1.3,1.8v0c0.1,0.2,0.1,0.4,0.2,0.5l0.2,0.8c0.7,2.6,1.3,5.7,1.9,9.4c1,5.2,1.8,10.3,2.5,15.3
        c0.8,5.8,1.4,11.5,1.9,17.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="265.3" y1="111.8" x2="265.5" y2="110.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265.7,108.7
        c0.1-1.4,0.3-2.9,0.5-4.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="266.1" y1="104.2" x2="266.7" y2="100.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="264" y1="113.5" x2="263.9" y2="113.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M260.4,155.2v-10
        c0-1.2,0.2-2.2,0.5-3c-0.7-4.6-1.4-9.6-2.1-14.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M319.3,199.5v-2.8
        c-1.7,0-3.3-0.1-5-0.3c-7.4-0.8-15.1-2.2-23.2-4.3c-8.7-2.4-14.3-5.7-16.8-9.9c-3.6-5.4-6.1-12.6-7.6-21.5
        c-0.1-0.4-0.1-0.8-0.2-1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M266,156.5l-1,0l-1.8,0.4
        c1.4,11.7,4.3,20.9,8.8,27.5c2.7,4.4,8.6,7.8,17.7,10.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M261,142.2
        c0.7,5.1,1.5,9.8,2.2,14.2c0,0.1,0,0.3,0,0.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="260.5" y1="118.2" x2="266" y2="156.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M319.3,172.5v9.2l5.4,2.2
        c0.4,0.2,0.8,0.3,1.2,0.4c0.5,0.1,1,0.2,1.6,0.3c0.3,0,0.7,0,1,0.1h4.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M333.9,187h77.2
        c0.5,0,0.8-0.2,0.8-0.7c0,0,0-0.1,0-0.1v-0.5c0,0.5-0.3,0.8-0.8,0.8h-77.2c-0.5,0-0.8-0.3-0.8-0.8v0.5c0,0,0,0.1,0,0.1
        C333.1,186.8,333.4,187,333.9,187z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="412,185.7 412,184.7 
        412,184.2 333.1,184.2 333.1,184.7 333.1,185.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M333.1,184.2v-9.7
        c0.1-1.1,1.2-1.6,3.1-1.7h8.9v-0.3V164c0-0.3,0-0.6,0.1-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M318.4,175.4v11
        c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c-3,0-5.9-0.2-8.7-0.5c-2.4-0.4-4.5-1-6.2-1.9c-2.3-1.4-3.5-3.8-3.6-7.3v0
        c0-0.2,0-0.4,0-0.6c0.1-1.3,0.8-2,2-2l15.6,0.4C318,174.6,318.4,174.9,318.4,175.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M317.2,175.3l-15.3-0.3
        c-0.9,0-1.5,0.6-1.6,1.6c0,0.1,0,0.3,0,0.5c0.2,3.1,1.3,5.3,3.4,6.5h0c1.7,0.8,3.6,1.4,5.9,1.8c2.6,0.3,5.4,0.5,8.3,0.5v-10.1
        C317.9,175.5,317.6,175.3,317.2,175.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M328,199.5c-0.2,0-0.4,0-0.6,0
        c-0.5-0.1-1.1-0.1-1.6-0.3c-0.4-0.1-0.8-0.2-1.2-0.4l-5.4-2.2v0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M332.4,199.5v-8.6
        c0-1.6,0.8-2.4,2.4-2.4h75.4c1.6,0,2.4,0.8,2.4,2.4v8.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="319.3" y1="181.8" x2="319.3" y2="196.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="319.3" y1="168" x2="319.3" y2="172.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="272.9" y1="100.6" x2="266.9" y2="109.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M401.1,12.3c0.5,0,1,0,1.6,0.1
        l0,0c2.6,0.1,5.2,0.2,7.9,0.4l4.3,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M436,16.4
        c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.2-0.5-0.5-0.8-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M438.7,15.2
        c0.2,0.2,0.3,0.3,0.5,0.5c0.4,0.4,0.9,0.8,1.3,1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M354.5,12c0.6,0,1.3,0,1.9-0.1
        c3.4-0.1,6.7-0.1,10.1-0.2c0.1,0,0.1,0,0.2,0c0.5,0,1,0,1.5,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M376.9,11.8c0.5,0,1,0,1.5,0
        c0.1,0,0.1,0,0.2,0c3.4,0,6.8,0.1,10.1,0.2c0.7,0,1.3,0,1.9,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M316.3,95.9h112.5
        c-5.3-0.9-8.6-2.8-9.9-5.7l-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3l0,0c0-0.1,0-0.3-0.1-0.4c-14.9-0.8-29.7-1.2-44.6-1.2
        c-0.1,0-0.3,0-0.4,0h0c-0.4,0-0.7,0-1.1,0c-0.4,0-0.7,0-1.1,0h-0.4c-14.9,0-29.7,0.4-44.6,1.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.9,25.3
        c1.1,0,2.1,0.1,3.2,0.1c0.2,0,0.4,0,0.6,0c0.4,0,0.7,0,0.9-0.1c0.3-0.1,0.6-0.1,0.7-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.9,25.3l0.9,2.9
        c0.3,0,0.5,0,0.8,0c0.4,0,0.8,0,1.2,0c1.4,0,2.7-0.1,3.7-0.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="460,32.2 450.7,32.2 
        449.9,32.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M454.8,28.8h-5
        c0.3,1.1,0.6,2.2,1,3.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M473.3,81.5
        c-0.7,0.8-1.7,1.3-3.1,1.4c-0.3,0-0.6,0-0.8,0h0c-0.7-0.1-1.1-0.5-1.4-1.1c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.1
        c-0.1-0.4-0.1-0.7-0.2-1.1c-0.2-1.5-0.5-2.9-0.7-4.4c-2.5-14.2-5.2-26.6-8.1-37.5c-0.5-1.8-1-3.5-1.5-5.2h3c0.7,0,1.3,0.4,1.6,1.1
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M420.1,83.7l-1.4,5.2
        c0,0.1,0,0.2,0,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M438.7,87.9h-0.5
        c-0.2,0-0.4,0-0.6,0.1c-0.3,0-0.5,0.1-0.7,0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.1-0.2,0.2l0,0
        c-0.5,0.5-0.9,1.1-1.3,1.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.6,0.8-1.1,1.7-1.5,2.5c0.5,0,1,0,1.5,0c0.1,0,0.2,0,0.3,0
        c0.6,0,1.2,0,1.8-0.1c0.1,0,0.2,0,0.3,0c0.6,0,1.2-0.1,1.8-0.1c0.1,0,0.2,0,0.3,0c0.6,0,1.2-0.1,1.8-0.1c0.1,0,0.2,0,0.3,0
        c0.6,0,1.2-0.1,1.8-0.1c0.1,0,0.2,0,0.3,0c0.6-0.1,1.2-0.1,1.8-0.2c0.1,0,0.2,0,0.3,0c0.6-0.1,1.2-0.1,1.8-0.2c0.1,0,0.2,0,0.3,0
        c0.1,0,0.2,0,0.3,0c0.5-0.1,1-0.2,1.5-0.3c0.1,0,0.2,0,0.3-0.1c0.6-0.1,1.2-0.3,1.8-0.4c0.1,0,0.2-0.1,0.3-0.1
        c0.6-0.2,1.2-0.4,1.8-0.6c0.1,0,0.2-0.1,0.3-0.1c0.6-0.2,1.2-0.5,1.8-0.8c0,0,0.1,0,0.1-0.1l0.2-0.1l1.8-0.9l0.3-0.1l1.8-1
        l0.3-0.1l0.1-0.1H460h-0.3l-1.8,0h-0.3l-1.8,0h-0.3l-1.8,0h-0.3l-1.8,0h-0.3l-1.8,0h-0.3l-1.8,0h-0.3l-1.8,0H445l-1.8,0h-0.3
        l-1.8,0h-0.3l-1.8,0H438.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M461.4,86.8l0.4,0l6.9,0.2
        l4.4,5.6c0,0.1,0.1,0.1,0.1,0.2c0.5,0.6,0.9,1.2,1.4,1.9c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.5,0.7,0.7,1c0,0.1,0.1,0.1,0.1,0.2
        l0.4,1.4c0.6,2.8,1,5.6,1.4,8.4c0,0,0,0.1,0,0.1c0.1,0.9,0.2,1.8,0.3,2.7l0,0c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.6
        l2.1,2.7l0.2-0.7c0.5,1.1,1.1,2.1,1.9,3.1l0.3,0.4c0.1,0.1,0.1,0.1,0.1,0.2l0.1,0.1v0c0.1,0.2,0.3,0.4,0.4,0.6
        c0.2,0.3,0.3,0.6,0.5,0.9c0,0.1,0,0.1,0.1,0.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="461.8" y1="86.9" x2="460.1" y2="87.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M468.7,87.1
        c-0.1,0.2-0.3,0.4-0.4,0.6c-1.1,1.5-2.5,2.7-4.1,3.8c-0.9,0.6-1.9,1.1-3.1,1.5c-0.3,0.1-0.7,0.2-1,0.3c-0.1,0-0.2,0.1-0.3,0.1
        c-0.6,0.2-1.2,0.3-1.8,0.5c-0.1,0-0.2,0-0.3,0.1c-5.3,1.1-13,1.8-23.2,2.1h-1.9l-0.3,9.1h9c12.1-0.3,20.9-1.1,26.4-2.6
        c1.7-0.4,3.2-1,4.5-1.8c1.3-0.7,2.3-1.6,3.1-2.7L476,97"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.7,57.6v3.5h3.8
        c1.1,0,1.6,0.5,1.6,1.6v11.3c0,1.1-0.5,1.6-1.5,1.6h-3.9l1,4.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478.9,61.1
        c-1.1,0-1.6,0.5-1.6,1.6v11.3c0,1.1,0.5,1.6,1.5,1.6h2.9V61.1H478.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M472.1,75.8
        c0.3,1.4,0.6,2.9,0.9,4.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="478.8" y1="75.8" x2="472.6" y2="75.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M425.9,121.4v8.6
        c0,0.1,0,0.1,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.1l28.7-1.4l4.7-0.2c1.9-0.1,3-0.9,3.1-2.4v0
        c0.5-6.1,0.6-12.3,0.2-18.6c-0.1-1.4-0.9-2.2-2.3-2.2c0,0,0,0-0.1,0c-3.9,0.3-8,0.6-12.4,0.8l-16.5,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.1,113.5L481.1,113.5
        c-0.1,0.2-0.1,0.4-0.2,0.6l-0.2,0.8c-0.7,2.6-1.3,5.7-1.9,9.4c-1,5.2-1.8,10.3-2.5,15.3c-0.8,5.8-1.4,11.5-1.9,17.1l4.7-0.1
        l5.5-38.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="472.2" y1="100.6" x2="478.2" y2="109.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M484.7,155.2v-10
        c0-1.2-0.2-2.2-0.5-3c-0.7,5.1-1.5,9.8-2.2,14.2c0,0.1,0,0.3,0,0.4c-1.4,11.7-4.3,20.9-8.8,27.5c-2.7,4.4-8.6,7.8-17.7,10.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="479.1,156.5 
        480.1,156.4 481.9,156.8 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478.7,159.7
        c-0.2,0.8-0.9,1.2-2.1,1.2l-17.9,0.5c-0.5,2.7-2.3,5.1-5.3,7.3c-2.6,1.6-5.4,2.6-8.5,2.8l-19,1.1v9.2v14.9v0.1
        c1.7,0,3.3-0.1,5-0.3c7.4-0.8,15.1-2.2,23.2-4.3c8.7-2.4,14.3-5.7,16.8-9.9c3.6-5.4,6.1-12.6,7.6-21.5
        C478.6,160.4,478.6,160,478.7,159.7c0-0.1,0-0.1,0-0.2c0.1-1,0.3-2,0.4-3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M484.1,142.2
        c0.7-4.6,1.4-9.6,2.1-14.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M394.1,119.2H408
        c1.2,0,2.3,0,3.4,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M414.8,110.2v7.4
        c0,0.8-0.5,1.3-1.4,1.4c-0.4,0-0.9,0-1.3,0c-0.2,0-0.5,0-0.7,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M425.9,147.7h29.5v11
        c-0.1,3.2-1,5.8-2.8,7.9c-0.6,0.8-1.5,1.5-2.5,2c0.2-0.1,0.4-0.2,0.5-0.3c2.1-1.1,4-2.7,5.7-4.8c1.4-1.9,2.2-4.3,2.3-7.2l-0.3-9.3
        c-0.1-2-1.1-3.1-3-3.1c0,0-0.1,0-0.1,0l-29.3,1.1L425.9,147.7z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="425.9,148.3 
        425.9,148.3 425.9,150 425.9,148.3 425.9,147.7 425.9,147.7 425.9,148.3 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M345.1,172.5h0.5V164
        c0-0.3,0-0.6,0.1-0.8c0-0.1,0.1-0.2,0.1-0.3c1.2-1.9,2.6-3.6,4.2-5.1c0.6-0.6,1.5-0.9,2.6-0.9h39.9c1.1,0,1.9,0.3,2.6,0.9
        c1.6,1.5,3,3.2,4.2,5.1c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.1,0.5,0.1,0.8v8.5h0.5V164c0-0.3,0-0.6-0.1-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M429.9,159.5c0,0.7,0.3,1,1,1
        h17.6c0.7,0,1-0.3,1-1v-4.7c0-0.7-0.3-1-1-1h-17.6c-0.7,0-1,0.3-1,1V159.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M425.9,150l0,18l15.5-0.9
        c2.6-0.1,4.7-0.5,6.3-1c3.3-1.5,5.1-4.5,5.3-9.1v-8.7h-27.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="455.4" y1="147.7" x2="453" y2="148.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M458.7,161.4
        c0.1-0.5,0.1-1.1,0.1-1.7l-0.1-2.6l0-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M425.9,168v4.1l20-2.2
        c0.9-0.1,1.7-0.3,2.6-0.6c0.5-0.2,1-0.3,1.5-0.6l0.2-0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="425.9" y1="144.9" x2="425.9" y2="130"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="455.4" y1="143.9" x2="455.4" y2="129.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="432.6" y1="95.9" x2="428.8" y2="95.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="378.7" y1="119.2" x2="379.4" y2="119.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="394.1,119.2 
        393.4,119.2 379.4,119.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M378.7,119.2h-12.4h-0.7h-14
        H351h-13.9c-1.2,0-2.3,0-3.4,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M368,189.7
        c-0.4,0-0.8,0-1.1,0.1c-1.2,0.1-2.4,0.2-3.4,0.4c-0.4,0.1-0.8,0.1-1.2,0.2l-0.7,0.2c-0.2,0.1-0.4,0.1-0.7,0.2
        c-1.1,0.3-2.1,0.6-3.1,1c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-0.5,0.4-0.8,0.9-0.8,1.6v3.7
        c0.1,0.9,0.6,1.4,1.6,1.4h29.7c1-0.1,1.5-0.6,1.6-1.4v-3.7c0-0.7-0.3-1.3-0.8-1.6c-0.1-0.1-0.3-0.2-0.5-0.3
        c-0.2-0.1-0.4-0.1-0.5-0.2c-1-0.4-2-0.7-3.1-1c-0.2-0.1-0.5-0.1-0.7-0.2l-0.7-0.2c-0.4-0.1-0.8-0.1-1.2-0.2
        c-1-0.2-2.2-0.3-3.4-0.4c-0.4,0-0.7-0.1-1.1-0.1H368z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M381.4,191.8
        c0.7,0.1,1,0.5,1.2,1.3c0,0.1,0,0.3,0,0.4v1.4c0,0.4-0.1,0.7-0.4,0.8c-0.1,0-0.2,0.1-0.3,0.1h-18.8c-0.1,0-0.2,0-0.3-0.1
        c-0.3-0.1-0.4-0.4-0.4-0.8v-1.4c0-0.2,0-0.3,0-0.4c0.1-0.7,0.5-1.1,1.2-1.3c0,0,0.1,0,0.1,0c1-0.1,2-0.2,3.1-0.3
        c0.4,0,0.7-0.1,1.1-0.1h9.1c0.4,0,0.8,0,1.1,0.1c1.1,0.1,2.1,0.2,3.1,0.3C381.3,191.8,381.4,191.8,381.4,191.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M379.2,194.1h2.4
        c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2h-3.1c0.2,0,0.3,0.1,0.5,0.3
        c0.2,0.2,0.3,0.4,0.3,0.6v0C379.3,193.8,379.3,193.9,379.2,194.1c0,0.1-0.1,0.2-0.2,0.2c-0.2,0.2-0.4,0.3-0.6,0.3h-11.7
        c-0.2,0-0.5-0.1-0.6-0.3c-0.1-0.1-0.1-0.2-0.2-0.2h-2.4c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v0c0-0.2,0.1-0.4,0.2-0.5
        c0.1-0.1,0.3-0.2,0.5-0.2h3.1h12"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M407.9,199.5V192
        c0-0.3-0.1-0.4-0.4-0.4h-12.7c-0.3,0-0.4,0.1-0.4,0.4v7.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M409,199.5v-8.2
        c0-0.6-0.3-0.9-0.9-0.9h-14c-0.6,0-0.9,0.3-0.9,0.9v8.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="345.6,172.5 
        345.6,172.8 399.5,172.8 399.5,172.5 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M337.2,199.5V192
        c0-0.3,0.2-0.4,0.4-0.4h12.7c0.3,0,0.4,0.1,0.4,0.4v7.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M336.1,199.5v-8.2
        c0-0.6,0.3-0.9,0.9-0.9h14c0.6,0,0.9,0.3,0.9,0.9v8.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M366.6,192.7
        c-0.2,0-0.3,0.1-0.5,0.3c-0.2,0.2-0.3,0.4-0.3,0.6v0c0,0.1,0,0.3,0.1,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M445.4,176.8
        c0-0.2,0-0.4,0-0.6c-0.1-1.3-0.8-2-2-2l-15.6,0.4c-0.7,0.1-1.1,0.4-1.2,0.8v11c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1
        c3,0,5.9-0.2,8.7-0.5c2.4-0.4,4.5-1,6.2-1.9C444,182.7,445.3,180.3,445.4,176.8L445.4,176.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M444.8,177
        c-0.2,3.1-1.3,5.3-3.4,6.5h0c-1.7,0.8-3.6,1.4-5.9,1.8c-2.6,0.3-5.4,0.5-8.3,0.5v-10.1c0.1-0.2,0.3-0.4,0.7-0.4l15.3-0.3
        c0.9,0,1.5,0.6,1.6,1.6C444.8,176.6,444.8,176.8,444.8,177z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="425.9" y1="172.1" x2="425.9" y2="172.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M419.2,184.3
        c-0.5,0.1-1,0.2-1.6,0.3c-0.3,0-0.7,0-1,0.1H412"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M425.9,181.8l-5.4,2.2
        c-0.4,0.2-0.8,0.3-1.2,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M419.2,199.2
        c0.4-0.1,0.8-0.2,1.2-0.4l5.4-2.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M412,184.2v-9.7
        c-0.1-1.1-1.2-1.6-3.1-1.7H400v-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M419.2,199.2
        c-0.5,0.1-1,0.2-1.6,0.3c-0.2,0-0.4,0-0.6,0"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="425.9" y1="199.5" x2="425.9" y2="196.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.7" y1="163.2" x2="399.4" y2="163.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="474.5" y1="156.6" x2="458.7" y2="157.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="432.9" y1="93.4" x2="432.6" y2="95.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M431.5,16v-0.8
        c-0.7-0.1-1.4-0.1-2.2-0.2c-3.1-0.3-6.3-0.6-9.5-0.9l-9.2-0.6c-2.6-0.1-5.3-0.3-7.9-0.4l0,0c-0.6,0-1.1,0-1.7-0.1
        c-3.5-0.1-6.9-0.3-10.4-0.3c-0.6,0-1.3,0-2-0.1c-3.4-0.1-6.7-0.1-10.1-0.2c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0-1.5,0c-0.3,0-0.7,0-1,0
        h-6.5c-0.4,0-0.7,0-1,0c-0.5,0-1,0-1.5,0c-0.1,0-0.1,0-0.2,0c-3.4,0-6.8,0.1-10.1,0.2c-0.7,0-1.3,0-2,0.1
        c-3.5,0.1-6.9,0.2-10.4,0.3c-0.5,0-1.1,0-1.7,0.1l0,0c-2.6,0.1-5.2,0.2-7.9,0.4l-9.2,0.6c-3.2,0.3-6.4,0.5-9.5,0.9
        c-0.7,0.1-1.4,0.1-2.2,0.2V16"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="313.6" y1="14.4" x2="313.6" y2="15.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M301.7,31.2
        c-3.2,0.2-5.3,1.9-6.5,5.2c-2.9,10.8-5.4,22-7.4,33.4c0,0.1,0,0.2,0,0.3l0,0.2c0,0.2-0.1,0.4-0.1,0.6c-0.5,3.7,1.2,5.4,5.3,5.2
        c26-1.2,52.1-1.7,78.1-1.7c0.1,0,0.3,0,0.4,0h0c0.4,0,0.8,0,1.2,0c0.3,0,0.6,0,1,0h0c0.1,0,0.3,0,0.4,0c26.1,0,52.1,0.5,78.1,1.7
        c4.1,0.2,5.8-1.6,5.3-5.2c0-0.2-0.1-0.4-0.1-0.6l0-0.2c0-0.1,0-0.2,0-0.3c-2.1-11.4-4.5-22.5-7.4-33.4c-1.2-3.3-3.4-5-6.5-5.2
        c-0.3,0-0.6,0-0.8,0c-14.2-0.3-28.5-0.5-42.7-0.6c-0.2,0-0.4,0-0.6,0c-0.7,0.1-1.2,0.5-1.3,1.2l-0.7,3.1c-0.3,1.1-1.8,1.7-4.6,1.8
        H392c0,0-0.1,0-0.1,0c-5.9,0-11.9-0.1-17.9-0.1h-0.4c-0.7,0-1.4,0-2.1,0h-0.4c-5.9,0-11.9,0-17.9,0.1c0,0-0.1,0-0.1,0h-0.6
        c-2.8-0.1-4.3-0.7-4.6-1.8l-0.7-3.1c-0.2-0.7-0.6-1.1-1.3-1.2c-0.2,0-0.4,0-0.6,0c-14.2,0.1-28.5,0.3-42.7,0.6
        C302.3,31.2,302,31.2,301.7,31.2z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="285.4" y1="87.7" x2="285.4" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="285.1" y1="87.7" x2="285.1" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="287.5" y1="87.7" x2="287.5" y2="89"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="287.2" y1="88.9" x2="287.2" y2="87.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="295.6" y1="87.8" x2="295.6" y2="92.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="291.7" y1="87.7" x2="291.7" y2="91"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="293.5" y1="91.6" x2="293.5" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="293.8" y1="91.7" x2="293.8" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="289.3" y1="89.9" x2="289.3" y2="87.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="289.6" y1="87.7" x2="289.6" y2="90.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="291.4" y1="90.9" x2="291.4" y2="87.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="295.9" y1="92.2" x2="295.9" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="301.9" y1="87.8" x2="301.9" y2="92.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="300.1" y1="87.8" x2="300.1" y2="92.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="299.8" y1="92.7" x2="299.8" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="302.2" y1="87.8" x2="302.2" y2="92.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="298" y1="87.8" x2="298" y2="92.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="297.7" y1="87.8" x2="297.7" y2="92.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M281.7,62.9
        c-1,4.2-2,8.5-3,12.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="310.3" y1="93.3" x2="310.3" y2="90.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="310.6" y1="90.8" x2="310.6" y2="93.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="308.2" y1="88.2" x2="308.2" y2="93.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="304" y1="93" x2="304" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="304.3" y1="93" x2="304.3" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="306.2" y1="87.9" x2="306.2" y2="93.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="306.4" y1="93.2" x2="306.4" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="308.5" y1="88.3" x2="308.5" y2="93.3"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="333.7,110.3 
        333.7,112.7 351,112.7 351,110.4 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="333.7,112.7 
        333.1,112.7 332.2,112.7 332.2,116.2 333.1,116.2 333.7,116.2 351,116.2 351.7,116.2 365.7,116.2 366.4,116.2 378.7,116.2 
        379.4,116.2 393.4,116.2 394.1,116.2 394.1,119.2 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="408,112.7 
        407.6,112.7 406.7,112.7 388.4,112.7 387.5,112.7 371.2,112.7 370.3,112.7 351.9,112.7 351,112.7 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="333.1" y1="112.7" x2="333.1" y2="110.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M306.8,110.8v5.4
        c0,0.6-0.3,0.9-0.9,0.8l-5.9-0.3c-0.6,0-0.9-0.3-0.9-0.9v-5.4c0-0.6,0.3-0.9,0.9-0.8l5.9,0.3C306.5,109.9,306.8,110.2,306.8,110.8
        z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312,109.5l-16.1-0.7
        c-1,0-1.6,0.5-1.8,1.5c-0.2,1-0.3,1.9-0.3,2.9v0.9c0,2.6,0.9,4.8,2.8,6.7c1.9,1.8,4.1,2.7,6.8,2.7c2.6,0,4.9-1,6.8-2.9
        c1.9-1.9,2.8-4.2,2.8-6.8v-3.4C312.9,109.8,312.5,109.5,312,109.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M315.7,108.9l-1.2,0v5
        c0,3.1-1.1,5.7-3.3,7.9c-2.2,2.2-4.8,3.3-7.9,3.4c-3.1,0.1-5.7-1-7.9-3.1c-2.2-2.1-3.3-4.7-3.3-7.8v-1.1c0-1.7,0.3-3.5,0.9-5.2
        c-1-0.1-1.9-0.1-2.9-0.2c-0.6,1.6-0.9,3.5-0.9,5.8v0.9v0.4c0,2.7,0.6,5,1.9,7.1c0.6,1,1.3,1.8,2.2,2.7c1.8,1.8,3.8,2.9,6,3.5
        l5.9,0.3c3-0.5,5.7-1.8,7.9-4.1c2.7-2.8,4.1-6.1,4.1-10v-4.7C317.1,109.2,316.6,109,315.7,108.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M305.4,128.5l12.3,0.6v-18.5
        c0-0.3-0.1-0.6-0.2-0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M314.5,108.9l-18.6-0.8
        c-0.4,0-0.8,0-1.2-0.1c-0.2,0-0.5,0-0.7,0c-0.6,1.8-0.9,3.5-0.9,5.2v1c0,2.8,1,5.2,3,7.2c2,2,4.4,2.9,7.3,2.9
        c2.8-0.1,5.2-1.1,7.3-3.1c2-2,3-4.4,3-7.3V109"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="333.1" y1="119.1" x2="333.1" y2="116.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="333.7" y1="119.2" x2="333.7" y2="116.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M290.2,107.7
        c-0.3,0-0.6,0-0.8-0.1c-1.6-0.1-3.1-0.2-4.7-0.3c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8-0.1-1.2-0.1c-0.5,0-0.7,0.3-0.8,0.7
        c-0.1,1.7-0.1,3.3-0.2,5c0,0.4,0,0.7,0,1.1l6.9,0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M294,108c-0.3,0-0.6,0-0.9-0.1
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284,120.5l5.9,0.4
        c-0.8-1.6-1.2-3.4-1.3-5.3l-4.7-0.3C283.9,117.1,283.9,118.8,284,120.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M282.4,114.1
        c0,0.4,0,0.8,0,1.2c0,1.8,0.1,3.5,0.2,5.3c0,0.3,0,0.7,0.1,1l8.6,0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M282.6,121.5
        c0.1,1.2,0.2,2.4,0.3,3.6c0,0.4,0.1,0.9,0.1,1.3c0.2,0.7,0.8,1,1.8,1.1l14.7,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M288.6,113.4
        c0-1.7,0.2-3.2,0.5-4.5l-5.1-0.3c-0.1,1.5-0.1,3-0.1,4.6L288.6,113.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M297.3,154.3h5.8h10.3
        c0.6,0,0.8,0.3,0.8,0.8v3.8c0,0.6-0.3,0.8-0.8,0.8h-10.3h-5.8c-0.6,0-0.8-0.3-0.8-0.8v-3.8C296.4,154.6,296.7,154.3,297.3,154.3z"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M296.7,155.3v3.4
        c0,0.5,0.3,0.7,0.8,0.7h5.4c0.5,0,0.7-0.3,0.7-0.7v-3.4c0-0.5-0.2-0.7-0.7-0.8h-5.4C297,154.6,296.7,154.8,296.7,155.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M301,156.5
        c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.2c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5c0.2,0.2,0.4,0.2,0.6,0.2
        c0.2,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.3-0.5C301.3,156.8,301.2,156.7,301,156.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.1,159.7
        c0.5,0,0.8-0.3,0.8-0.8v-3.8c0-0.5-0.3-0.8-0.8-0.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="264.6" y1="99.8" x2="264.2" y2="103.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="265.1" y1="99.9" x2="264.6" y2="103.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M265.1,162.4
        c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0.1,0.5,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.3c0.1,0,0.2-0.1,0.3-0.3c0.1-0.2,0.1-0.4,0.1-0.6
        c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.4-0.3C265.2,162.1,265.1,162.2,265.1,162.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M264.9,162.1
        c-0.1,0.2-0.1,0.5-0.1,0.9c0,0.4,0.2,0.7,0.3,0.9c0.1,0.2,0.3,0.4,0.5,0.4c0.2,0,0.3-0.1,0.4-0.4c0.1-0.2,0.1-0.5,0.1-0.9
        c-0.1-0.4-0.2-0.7-0.3-0.9c-0.2-0.2-0.3-0.4-0.5-0.4C265.1,161.7,265,161.9,264.9,162.1z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="325.9" y1="184.3" x2="325.9" y2="125.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="325.9" y1="199.2" x2="325.9" y2="184.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M288.6,172.5
        c0.1-0.2,0.2-0.4,0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.2,0.4-0.1,0.6
        c0,0.2,0.2,0.5,0.4,0.6c0.2,0.2,0.4,0.3,0.6,0.3C288.3,172.7,288.4,172.6,288.6,172.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M288.9,172.7
        c0.2-0.2,0.2-0.5,0.2-0.9c-0.1-0.4-0.3-0.7-0.5-0.9c-0.3-0.2-0.6-0.4-0.9-0.4c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.2,0.5-0.2,0.9
        c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.3,0.6,0.4,0.9,0.4C288.4,173.1,288.7,173,288.9,172.7z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="431.5" y1="14.4" x2="431.5" y2="15.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="436.9" y1="88.2" x2="436.9" y2="93.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="438.7" y1="93.2" x2="438.7" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="436.6" y1="88.3" x2="436.6" y2="93.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="434.8" y1="90.4" x2="434.8" y2="93.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="434.5" y1="90.8" x2="434.5" y2="93.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="445" y1="87.8" x2="445" y2="92.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="445.3" y1="92.7" x2="445.3" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="443.2" y1="87.8" x2="443.2" y2="92.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="442.9" y1="87.8" x2="442.9" y2="92.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="439" y1="87.9" x2="439" y2="93.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="440.8" y1="93" x2="440.8" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="441.1" y1="93" x2="441.1" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="451.6" y1="87.8" x2="451.6" y2="91.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="453.4" y1="91" x2="453.4" y2="87.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="451.3" y1="87.8" x2="451.3" y2="91.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="449.2" y1="87.8" x2="449.2" y2="92.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="449.5" y1="92.1" x2="449.5" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="447.4" y1="87.8" x2="447.4" y2="92.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="447.1" y1="87.8" x2="447.1" y2="92.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="453.7" y1="90.9" x2="453.7" y2="87.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="460" y1="87.7" x2="460" y2="87.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="459.7" y1="87.7" x2="459.7" y2="87.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="457.6" y1="87.7" x2="457.6" y2="89"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="457.9" y1="88.9" x2="457.9" y2="87.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="455.5" y1="87.7" x2="455.5" y2="90.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="455.8" y1="89.9" x2="455.8" y2="87.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462.2,125.1
        c0,0.4-0.1,0.9-0.1,1.3c-0.2,0.7-0.8,1-1.8,1.1l-14.7,0.7l-5.9,0.3l-12.3,0.6v-18.5c0-0.3,0.1-0.6,0.2-0.9
        c0.3-0.5,0.9-0.7,1.8-0.8l1.2,0l18.6-0.8c0.4,0,0.8,0,1.2-0.1c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0,0.9-0.1c1-0.1,1.9-0.1,2.9-0.2
        c0.3,0,0.6,0,0.8-0.1c1.6-0.1,3.1-0.2,4.7-0.3c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.1,1.2-0.1c0.5,0,0.7,0.3,0.8,0.7
        c0.1,1.7,0.1,3.3,0.2,5c0,0.4,0,0.7,0,1.1c0,0.4,0,0.8,0,1.2c0,1.8-0.1,3.5-0.2,5.3c0,0.3,0,0.7-0.1,1
        C462.4,122.7,462.3,123.9,462.2,125.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M480.2,162.1
        c0.1,0.2,0.1,0.5,0.1,0.9c0,0.4-0.2,0.7-0.3,0.9c-0.1,0.2-0.3,0.4-0.5,0.4c-0.2,0-0.3-0.1-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.9
        c0.1-0.4,0.2-0.7,0.3-0.9c0.2-0.2,0.3-0.4,0.5-0.4C480,161.7,480.1,161.9,480.2,162.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M480.1,163
        c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2-0.1-0.3-0.3c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0.1-0.4,0.2-0.6
        c0.1-0.2,0.3-0.3,0.4-0.3c0.1,0,0.2,0.1,0.3,0.3C480.1,162.6,480.1,162.8,480.1,163z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M451,110.3
        c0.2,1,0.3,1.9,0.3,2.9v0.9c0,2.6-0.9,4.8-2.8,6.7c-1.9,1.8-4.1,2.7-6.8,2.7c-2.6,0-4.9-1-6.8-2.9c-1.9-1.9-2.8-4.2-2.8-6.8v-3.4
        c0.1-0.5,0.4-0.8,1-0.8l16.1-0.7C450.2,108.7,450.8,109.2,451,110.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M461.1,108.5
        c0.1,1.5,0.1,3,0.1,4.6l-4.7,0.3c0-1.7-0.2-3.2-0.5-4.5L461.1,108.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M445.1,109.6l-5.9,0.3
        c-0.6,0-0.9,0.4-0.9,0.9v5.4c0,0.6,0.3,0.9,0.9,0.8l5.9-0.3c0.6,0,0.9-0.3,0.9-0.9v-5.4C446,109.8,445.7,109.5,445.1,109.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M451.1,108
        c0.6,1.8,0.9,3.5,0.9,5.2v1c0,2.8-1,5.2-3,7.2c-2,2-4.4,2.9-7.3,2.9c-2.8-0.1-5.2-1.1-7.3-3.1c-2-2-3-4.4-3-7.3V109"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M430.6,108.9v5
        c0,3.1,1.1,5.7,3.3,7.9c2.2,2.2,4.8,3.3,7.9,3.4c3.1,0.1,5.7-1,7.9-3.1c2.2-2.1,3.3-4.7,3.3-7.8v-1.1c0-1.7-0.3-3.5-0.9-5.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M439.7,128.5
        c-3-0.5-5.7-1.8-7.9-4.1c-2.7-2.8-4.1-6.1-4.1-10v-4.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M461.3,115.3
        c0,1.7-0.1,3.5-0.2,5.2l-5.9,0.4c0.8-1.6,1.2-3.4,1.3-5.3L461.3,115.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462.7,114.1l-6.9,0.4v0.4
        c0,2.7-0.6,5-1.9,7.1l8.6-0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M454.9,107.7
        c0.6,1.6,0.9,3.5,0.9,5.8v0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M453.9,122
        c-0.6,1-1.3,1.8-2.2,2.7c-1.8,1.8-3.8,2.9-6,3.5"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="407.4,112.7 
        411.4,112.7 411.4,110.3 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="411.4,112.7 
        412,112.7 412,110.3 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="394,116.2 
        407.3,116.2 409.7,116.2 412.9,116.2 412.9,112.7 409.7,112.7 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="411.4" y1="119.2" x2="411.4" y2="116.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="412" y1="116.2" x2="412" y2="119.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M405.4,166.5
        c0-0.4,0.1-0.7,0.4-0.9c0.2-0.2,0.6-0.4,0.9-0.4c0.4,0,0.6,0.1,0.9,0.4c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9
        c-0.2,0.2-0.5,0.4-0.9,0.4c-0.4,0-0.7-0.1-0.9-0.4C405.5,167.2,405.4,166.9,405.4,166.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M405.8,166.5
        c0-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6
        c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.6-0.3C405.9,167,405.8,166.8,405.8,166.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M442,154.3h-10.3
        c-0.6,0-0.8,0.3-0.8,0.8v3.8c0,0.6,0.3,0.8,0.8,0.8H442c-0.5,0-0.8-0.3-0.8-0.8v-3.8C441.2,154.6,441.4,154.3,442,154.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M445.6,157
        c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5
        c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.5,0.1,0.6,0.2C445.5,156.7,445.6,156.8,445.6,157z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M442.2,154.6h5.4
        c0.5,0,0.8,0.3,0.8,0.8v3.4c0,0.5-0.3,0.7-0.8,0.7h-5.4c-0.5,0-0.7-0.3-0.7-0.7v-3.4C441.5,154.8,441.7,154.6,442.2,154.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M442,159.7h5.8
        c0.6,0,0.8-0.3,0.8-0.8v-3.8c0-0.6-0.3-0.8-0.8-0.8H442"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="398.6" y1="135.3" x2="398.6" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="399.3" y1="130.5" x2="399.3" y2="135.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="409" y1="135.3" x2="409" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="409.7" y1="135.3" x2="409.7" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="393.4" y1="110.4" x2="393.4" y2="112.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="394.1" y1="112.7" x2="394.1" y2="110.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="379.4" y1="112.7" x2="379.4" y2="110.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="378.7" y1="110.4" x2="378.7" y2="112.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="379.4" y1="119.2" x2="379.4" y2="116.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="378.7" y1="116.2" x2="378.7" y2="119.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="393.4" y1="119.2" x2="393.4" y2="116.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="351.7" y1="110.4" x2="351.7" y2="112.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="366.4" y1="110.4" x2="366.4" y2="112.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="365.7" y1="112.7" x2="365.7" y2="110.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="351" y1="119.2" x2="351" y2="116.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="365.7" y1="119.2" x2="365.7" y2="116.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="366.4" y1="116.2" x2="366.4" y2="119.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="351.7" y1="119.2" x2="351.7" y2="116.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M337.8,165.9
        c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6
        c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.3C338.2,165.6,338,165.7,337.8,165.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M337.5,165.6
        c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9
        c0-0.4-0.1-0.7-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.4C338,165.3,337.8,165.4,337.5,165.6z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="356" y1="135.3" x2="356" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="355.3" y1="130.5" x2="355.3" y2="135.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="365.6" y1="135.3" x2="365.6" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="366.3" y1="135.3" x2="366.3" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="336.1" y1="135.3" x2="336.1" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="335.4" y1="130.5" x2="335.4" y2="135.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="345.8" y1="130.5" x2="345.8" y2="135.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="346.5" y1="135.3" x2="346.5" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="389.1" y1="135.3" x2="389.1" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="389.8" y1="130.5" x2="389.8" y2="135.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="379.5" y1="135.3" x2="379.5" y2="130.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="378.8" y1="135.3" x2="378.8" y2="130.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M458.5,171.8
        c-0.1,0.4-0.3,0.7-0.5,0.9c-0.3,0.3-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.2-0.2-0.5-0.2-0.9c0.1-0.4,0.3-0.7,0.5-0.9
        c0.3-0.2,0.6-0.4,0.9-0.4c0.3,0,0.6,0.1,0.8,0.4C458.5,171.2,458.5,171.5,458.5,171.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M458.1,171.8
        c0,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4-0.1-0.6c0.1-0.2,0.2-0.4,0.4-0.6
        c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.4,0.1,0.5,0.3C458.1,171.4,458.1,171.6,458.1,171.8z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="419.2" y1="184.3" x2="419.2" y2="199.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="419.2" y1="184.3" x2="419.2" y2="125.3"/>
    </g>
  </g>
  <g id="Layer_4">
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M275.7,853.6
        c3,0,4.6,1.5,4.8,4.5c0,0.1,0,0.2,0,0.3v13.4h0.5c2.8-13.8,5.8-26.3,8.9-37.4c0.4-1.4,0.8-2.7,1.2-4.1c0-0.1,0.1-0.2,0.1-0.3
        c0.3-1.1,1-1.7,2-1.8h-0.7l0.6-2.4c0.2-0.6,0.7-0.9,1.5-1l3.8,0l0.2-0.7l0.4-1.5c0.1-0.4,0.2-0.8,0.4-1.2c0.1-0.2,0.2-0.5,0.4-0.7
        c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.8-1.1,1.6-2.2,2.4-3c1.1-1.2,2.3-2.2,3.6-3c1.4-0.9,2.9-1.5,4.5-2
        c0.3-0.1,0.7-0.2,1-0.2c0.9-0.1,1.8-0.2,2.7-0.3c0.3,0,0.7-0.1,1-0.1c1.4-0.2,2.8-0.3,4.3-0.5c1.2-0.1,2.5-0.3,3.7-0.4
        c3.2-0.3,6.3-0.6,9.6-0.9l4.8-0.3c0.5-0.2,0.9-0.5,1.3-0.8c0.2-0.1,0.3-0.2,0.4-0.4l2.9-0.2c2.6-0.1,5.1-0.3,7.7-0.4
        c0.4,0.4,0.9,0.7,1.5,0.9c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.3,0,0.7,0,1,0c0.2,0,0.4,0,0.5,0
        c2.8-0.1,5.6-0.2,8.3-0.3c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.5-0.2l1.3-0.8c3.3-0.1,6.6-0.1,9.9-0.2
        c0,0,0.1,0,0.1,0l1.4,0.7c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0,0.6,0,1.1,0h6.5c0.5,0,0.9,0,1.1,0c0.2-0.1,0.4-0.1,0.5-0.3l1.4-0.7
        c0,0,0.1,0,0.1,0c0.6,0,1.2,0,1.8,0c2.7,0,5.4,0.1,8.1,0.1l1.3,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0
        c3.3,0.1,6.6,0.2,9.9,0.3c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.6-0.2,1.1-0.6,1.5-0.9c2.6,0.1,5.1,0.2,7.7,0.4l2.9,0.2
        c0.4,0.4,1,0.8,1.7,1.1l4.8,0.3c4.5,0.4,8.9,0.8,13.2,1.2c1.4,0.1,2.9,0.3,4.3,0.5c1.3,0.1,2.5,0.3,3.8,0.4
        c3.4,0.7,6.5,2.4,9.1,5.1c0.9,1,1.8,2.1,2.6,3.3c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.2,0.3,0.5,0.4,0.7c0.2,0.4,0.3,0.8,0.4,1.2
        l0.4,1.5l1.3,4.1l0.1,0.5c4.4,13,8.1,26.7,11.4,41.3c0.1,0.5,0.2,1.1,0.4,1.7h4.9v-13.4c0-0.1,0-0.2,0-0.3
        c0.2-2.9,1.8-4.4,4.8-4.5h7.6c3.8,0,5.7,2.1,5.7,6.3c0,0.1,0,0.2,0,0.4v6.6c0,3.1-0.7,5.5-2,7c-1.3,1.6-3.2,2.3-5.8,2.2h-9.3h-4.8
        c0.1,0.1,0.2,0.1,0.3,0.1l0.4,3.8c1.4,1.1,2.7,2.4,3.9,3.9c0.2,0.2,0.3,0.4,0.5,0.6c0,0,0,0.1,0.1,0.1l0.7,1.1
        c0.7,1,1.3,2,1.9,3.1l0,0.1c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.3,0.2,0.4c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.3,0.6
        c0.1,0.1,0.1,0.2,0.2,0.4c0,0,0,0,0,0.1c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0.1,0,0.1
        c0.1,0.6,0.2,1.3,0.3,1.9l0.2,0h0l0.5-0.1c0.2,0,0.3,0.1,0.4,0.3l0.1,1.2l0.6-0.1h0l0.5-0.1c0.2,0,0.3,0.1,0.4,0.3l0.4,3.4
        c0,0.2-0.1,0.3-0.3,0.4l-0.5,0.1l-1.5,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.8,0.2,1.7,0.3,2.5c0.1,1.1,0.2,2.1,0.3,3.2
        c0.5,1.1,1.1,2.1,1.6,3.1l0.2-0.5c0.6,1.3,1.4,2.5,2.3,3.6l0.1,0.1v0c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.3,0.3,0.6,0.5,0.9
        c0.1,0.3,0.2,0.6,0.3,0.9l1.4,5.8c0,0.4,0,0.7,0,1.1c0,1.2-0.1,2.5-0.1,3.7c-0.2,7.6-0.7,16.2-1.5,25.7v71c0,3.8-1.9,5.7-5.7,5.7
        h-17.8c-3.8,0-5.7-1.9-5.7-5.7v-31.5c-8.5,2.2-16.7,3.7-24.4,4.5c-1.8,0.2-3.7,0.3-5.5,0.3h-106c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0
        l-4.7-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5-0.1l-15.2-2.5c-2.8-0.6-5.7-1.3-8.7-2v31.5
        c0,3.8-1.9,5.7-5.7,5.7h-17.8c-3.8,0-5.7-1.9-5.7-5.7v-71c-0.8-9-1.3-17.1-1.4-24.4c0-0.4,0-0.9,0-1.3c0-1.2-0.1-2.5-0.1-3.7
        c0-0.4,0-0.7,0-1.1l1.4-5.8c0.1-0.2,0.2-0.5,0.3-0.7c0.1-6.2,0.7-12.5,1.8-18.7c0.1-0.6,0.2-1.2,0.3-1.8v0
        c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.2,0.2-0.5,0.2-0.7l2.2-5c0.1-0.1,0.1-0.3,0.3-0.4
        c0.2-0.2,0.3-0.3,0.6-0.5c0.2-0.1,0.3-0.2,0.5-0.3l0.5-0.3c0.3-0.1,0.6-0.3,0.8-0.4c1.7-1.1,2.8-2.9,3.4-5.7
        c0.1-0.5,0.2-1,0.3-1.4c0.1-0.5,0.2-0.9,0.3-1.4c0,0,0,0,0,0h-0.6h-9.3c-2.6,0.1-4.5-0.6-5.8-2.2c-1.3-1.5-2-3.9-2-7v-6.6
        c0-0.1,0-0.3,0-0.4c0-4.2,1.9-6.3,5.7-6.3H275.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M338.2,809l-0.5,0.4l-1.4,1.3
        c0.7-0.3,1.4-0.6,1.9-0.9c0.2-0.1,0.5-0.3,0.7-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.3l0.3-1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M321.5,810.8l4.7,0.9
        c0.3,0,0.6-0.1,0.9-0.1c0.4-0.1,0.9-0.1,1.3-0.1c2.5-0.2,5-0.5,7.6-0.7l1.3-0.1l7.6-0.5c2.9-0.2,5.9-0.3,8.9-0.4
        c-0.7-0.1-1.3-0.3-1.9-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.3-0.2-0.5-0.4c-2.6,0.1-5.2,0.2-7.9,0.4
        l-3,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M337.3,810.7l3.5,2.8
        c0.5,0.4,1.1,0.7,2,0.7h9v-0.8h57.6v0.8h9c0.9,0,1.5-0.3,2-0.7l3.5-2.8l-7.6-0.5c-2.9-0.2-5.9-0.3-8.9-0.4
        c-2.3-0.1-4.6-0.2-6.9-0.3l0,0c0,0-0.1,0-0.1,0h0c-5.6-0.2-11.2-0.3-16.9-0.3c-0.4,0-0.7,0-1,0h-1c-0.2,0-0.3,0-0.5,0h-0.1
        c-0.2,0-0.5,0-0.7,0h-0.1c-0.8,0-1.7,0-2.5,0c-5.6,0-11.2,0.1-16.9,0.3h0c0,0-0.1,0-0.1,0l0,0c-2.3,0.1-4.6,0.2-6.9,0.3l-1.7-1.5"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M308.6,815.7
        c1-0.8,2.1-1.4,3.3-2c0.9-0.4,1.9-0.8,2.9-1.1c1.1-0.4,2.4-0.6,3.7-0.9c0.1,0,0.2,0,0.4-0.1l-1.7-0.3l-1.3-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M318.8,811.6l3.1,0.6
        c0.7-0.1,1.4-0.3,2.1-0.3c0.2,0,0.5,0,0.7-0.1l0.6-0.1c0.3,0,0.6-0.1,0.9-0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="321.5" y1="810.8" x2="320.1" y2="810.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M321.9,812.3
        c-0.6,0.1-1.2,0.3-1.8,0.4c-3.7,1.1-6.5,2.9-8.4,5.6l-3.3,4l1.2,0l0-0.1l3.3-4c1.9-2.7,4.8-4.6,8.4-5.6c1-0.3,2.2-0.6,3.3-0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M326.8,825.9
        c-3.9,15.6-7,31.8-9.2,48.5h125.9c-2.2-16.7-5.3-32.9-9.2-48.5c-0.1-0.4-0.2-0.7-0.3-1c-0.5-1.2-1.3-2.2-2.5-2.8
        c-0.7-0.4-1.5-0.7-2.5-0.9c-1.9-0.3-3.9-0.5-6.1-0.6c-0.7,0-1.3,0-2-0.1c-12.4-0.5-24.9-0.7-37.5-0.8c-0.4,0-0.7,0-1.1,0h-1.5
        c-0.2,0-0.5,0-0.7,0h-0.1c-0.1,0-0.2,0-0.3,0l-2.2,0c-12.6,0-25.1,0.3-37.5,0.8c-0.7,0-1.3,0-2,0.1c-2.1,0.1-4.2,0.3-6.1,0.6
        c-1,0.2-1.8,0.5-2.5,0.9c-1.1,0.6-2,1.6-2.5,2.8C327,825.2,326.9,825.5,326.8,825.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.7,816.6h3.9
        c0.3-0.3,0.7-0.6,1-0.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="303" y1="816.6" x2="303.7" y2="816.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M281,871.7h4.4
        c0.1-0.6,0.2-1.1,0.4-1.7c3.2-14.4,7-28.1,11.3-41h-4.3c-0.7,0-1.3,0.4-1.6,1.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M298.6,824.1l0.2,0.1l0.3,0.2
        c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0,0.2,0,0.3,0.1l0.7,0l7,0.3l0.6-2.8l-7.7-0.3c-0.1,0-0.1,0-0.2,0
        c-0.3-0.1-0.6-0.2-0.9-0.3l-0.2-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M307.8,825.1l1.2,0
        c0,0,0-0.1,0-0.1l0.6-2.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M309,825.1
        c-4.8,15.2-8.6,31.6-11.4,49.3h0.8c2.8-17.4,6.5-33.8,11.4-49.2c0,0,0-0.1,0-0.1H309z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M297.2,828.3l-0.1,0.5
        c0,0.1-0.1,0.2-0.1,0.3"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="298.4,824.8 
        297.2,828.3 293.1,828.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M280.5,871.7v3.5
        c0,0.3-0.1,0.6-0.4,0.7c-0.2,0.1-0.4,0.2-0.6,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.4,875.1
        c-0.1,0-0.3,0-0.4,0.1c-0.3,0.1-0.5,0.1-0.7,0.2c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.1
        c-0.2,0.1-0.5,0.2-0.7,0.3c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0v0c0,0-0.1,0-0.1,0
        c-0.1,0.1-0.2,0.1-0.3,0.1l-0.4,3.8c0.1-0.1,0.3-0.2,0.4-0.3v0c0,0,0,0,0,0c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0.2-0.1
        c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.1,0.6-0.1"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.3,876
        C284.3,876,284.3,875.9,284.3,876c0-0.2,0-0.3,0-0.4c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.1-0.5c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
        c0,0,0,0,0-0.1c0.2-1.1,0.4-2.1,0.7-3.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="280.1" y1="876.1" x2="284.2" y2="876.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.8,875
        C287.8,875,287.9,875,287.8,875c0.7-0.1,1.4-0.2,2.1-0.3c0.2,0,0.4,0,0.6-0.1c0.7-0.1,1.5-0.1,2.3-0.2c0.2,0,0.4,0,0.6,0
        c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M295.2,877.9
        c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.2,0.1c-2.5,1.9-5,4.6-7.4,8c-0.1,0.2-0.3,0.4-0.4,0.5
        c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.7l0,0.2
        c-1.8,16.8-2.9,34.1-3.2,51.9c0.6-0.3,1-0.5,1.4-0.8h0.2c0.1-17.4,1-34.4,2.5-50.9l0-0.2c0-0.2,0.1-0.4,0.1-0.7
        c0.1-0.4,0.2-0.8,0.4-1.1c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.3-0.4,0.4-0.5c2.5-3.5,5-6.2,7.6-8.1c0.4-0.2,0.6-0.5,0.6-0.9
        c0.2-1,0.4-2.1,0.6-3.2h-0.6h-0.4v0c0,0,0,0.1,0,0.1c-0.2,1-0.4,2-0.5,2.9C295.3,877.6,295.3,877.7,295.2,877.9
        C295.2,877.9,295.2,877.9,295.2,877.9c-0.6,0-1.1,0-1.6,0.1c-2.8,0.1-5,0.4-6.7,0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.8,875
        c-0.2,0-0.3,0.1-0.4,0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="297.6" y1="874.4" x2="297" y2="874.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.5,940.3h5.2v-0.2v-2.1
        c0.1-2,1.9-3,5.4-3.1h1.3h3.3c0.6-20.4,1.7-40.6,3.4-60.6h-4.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M295.9,874.4
        c-0.6,0-1.1,0-1.6,0"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="327.1" y1="824.9" x2="309.8" y2="825.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M299.8,935h14.2h1
        c0.2-9.1,0.4-18.1,0.8-27.1c0.3-7.5,0.7-15,1.2-22.5c0.2-3.7,0.5-7.4,0.7-11.1h-14.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M307.8,825.1
        c-4.9,15.4-8.7,31.8-11.4,49.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="329.6" y1="822.1" x2="309.7" y2="822.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M327.3,896.5v14.4h1
        c1.3,0,2.4-0.5,3.3-1.4c0.9-0.9,1.4-2,1.4-3.3v-5c0-1.3-0.5-2.4-1.4-3.3c-0.9-0.9-2-1.4-3.3-1.4H327.3v-5.4c0-0.4-0.2-0.6-0.7-0.6
        h-2.4c-0.5,0-0.7,0.2-0.7,0.6v5.4v14.4v4.9c0,0.4,0.2,0.6,0.7,0.6h2.4c0.5,0,0.7-0.2,0.7-0.6v-4.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M323.4,896.5
        c-1.1,0.1-2.1,0.5-2.9,1.3c-0.9,0.9-1.4,2-1.4,3.3v5c0,1.3,0.5,2.4,1.4,3.3c0.8,0.8,1.8,1.3,2.9,1.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="284" y1="879.7" x2="283.9" y2="879.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M283.6,880
        c-0.2,0.1-0.4,0.3-0.5,0.4c-1.2,1-2.3,2.1-3.3,3.4c-0.1,0.2-0.3,0.3-0.4,0.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1l-0.7,1.1
        c-0.5,0.7-0.9,1.6-1.3,2.5l2-0.1c0.1-0.1,0.1-0.2,0.2-0.3c1.8-3.2,4.1-6.2,7-8.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.5,887.7l-7.4,0.4
        l-0.1,0.1c-0.1,0.3-0.1,0.6-0.2,0.9c-0.8,3.3-1.4,6.5-1.8,9.5c0.7-1.3,1.3-2.1,1.8-2.3c0.9-0.4,1.3,1.1,1.4,4.4c0,2,0,4-0.1,6
        c0,11.7,0.4,23.3,1.2,35c0.6-0.2,1.2-0.4,1.6-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M289.8,940.2
        c0-0.8,0.4-1.3,1.1-1.3h0.7v-1.1c0-1.8,1.6-2.8,5-2.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.5,940.3
        c0,1.5,0,3.1,0,4.6c0,0.2,0,0.4,0,0.6c-0.1,9.4,0.7,17.4,2.2,24c3.1,1.4,6.8,2.7,10.9,3.9c2.7,0.7,5.6,1.5,8.7,2.1l0.8,0.2
        c0.3,0.1,0.5,0.1,0.8,0.2c0.2,0,0.4,0.1,0.7,0.1l0.4,0.1c1.1,0.2,2.1,0.4,3.2,0.6c0.5,0.1,1.1,0.2,1.6,0.3v-3.9l0.1-1.1v-14.4
        h-22.2c-0.4,0-0.6-0.2-0.7-0.6l-0.4-2.7c-0.1-0.4,0.1-0.6,0.5-0.6h22.8v-10.6h-20.4c-2.6,0-3.9-0.8-3.7-2.2v-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.9,972.1v1.1h7.7h0h5h0.6
        l0-1.1v-16.9h6.4v16.9v1.1h3.4h1.8l0-1.1v-3.3c0-0.1,0-0.3,0-0.4c0.2-0.8,0.7-1.2,1.6-1.3h79.9c0.9,0.1,1.5,0.5,1.6,1.3
        c0,0.1,0,0.2,0,0.4v4.4h5.2v-17.9h6.4v17.9h5.6h7.8v-15.4v-3.8v-10.6v-4.4h1.2v-2.4c-0.1-0.2-0.2-0.4-0.6-0.4h-0.7h-0.9
        c-1.4,1.3-3,2.5-4.8,3.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.3-0.8,0.4c-1,0.4-2,0.5-3.1,0.6h-113c-1.1,0-2.2-0.2-3.1-0.6
        c-0.3-0.1-0.5-0.2-0.8-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-1.7-1-3.3-2.2-4.8-3.5h-0.9h-0.7c-0.3,0-0.5,0.1-0.6,0.4v2.4h1.2v4.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M325.3,945.9h10.2
        c0.7,0,1,0.3,1,1v3.3c0,0.7-0.3,1-1,1h-10.2c-0.7,0-1-0.3-1-1v-3.3C324.3,946.3,324.6,945.9,325.3,945.9z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="313.9" y1="935" x2="313.9" y2="936.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="312.6" y1="938.9" x2="291.5" y2="938.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276.2,889.4
        C276.2,889.4,276.2,889.4,276.2,889.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.1,0.2-0.2,0.4c0,0,0,0,0,0.1c-0.1,0.3-0.2,0.6-0.3,0.8
        c0,0.2-0.1,0.4-0.1,0.5c0,0,0,0.1,0,0.1c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1c-0.1,0.6-0.2,1.3-0.3,1.9l0.5,0.1
        c0.1-0.7,0.2-1.3,0.3-2c0,0,0-0.1,0-0.1c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.3,0.1-0.5c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
        c0.1-0.2,0.1-0.3,0.1-0.4l0-0.1c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.3,0.3-0.6,0.4-0.9
        c-0.2,0.1-0.4,0.3-0.6,0.6l0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1C276.3,889.2,276.3,889.3,276.2,889.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M275.4,894.6l0.9,0.1
        c0.2-1.3,0.5-2.5,0.8-3.5c0.1-0.3,0.2-0.5,0.3-0.6c0.2-0.4,0.2,0.2-0.1,1.6c-0.2,0.8-0.4,1.6-0.5,2.5c-0.2,1.3-0.4,2.6-0.5,4.1
        c-0.1,0.7-0.1,1.5-0.2,2.3c0.3-0.8,0.6-1.7,0.9-2.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M274.7,894.5L274.7,894.5
        l-0.5-0.1c-0.2,0-0.3,0.1-0.4,0.3l-0.1,1.2l-0.2,2.2c0,0.2,0.1,0.3,0.3,0.4l0.5,0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="274.9" y1="894.5" x2="274.7" y2="894.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M272.6,895.8
        c-0.2,0-0.3,0.1-0.4,0.3l-0.4,3.4c0,0.2,0.1,0.3,0.3,0.4l0.5,0.1l1.5,0.2c0.1-0.5,0.1-1,0.2-1.6l-0.1,0"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="273.1,895.9 
        273.1,895.9 272.6,895.8 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="273.7" y1="896" x2="273.1" y2="895.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="276.7" y1="894.8" x2="276.3" y2="894.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M278.4,885.7
        c-0.7,1-1.3,2-1.9,3.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="274.3,898.6 
        274.8,898.7 275.8,898.8 276.2,898.9 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M274.1,900.2
        c0,0.1,0,0.3-0.1,0.4c-0.1,0.8-0.2,1.5-0.3,2.3c0,0.1,0,0.1,0,0.2c-0.1,1.1-0.2,2.1-0.3,3.2c0.2-0.4,0.4-0.9,0.6-1.3
        c0.1-0.7,0.1-1.4,0.2-2c0.1-0.9,0.2-1.7,0.3-2.6c0.1-0.5,0.1-1,0.2-1.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M276,901.1
        c-0.1,0.4-0.3,0.7-0.5,1.1c-0.1,0.2-0.2,0.4-0.3,0.5c0,0,0,0.1,0,0.1c-0.4,0.7-0.7,1.4-1.1,2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M275.8,898.8
        c-0.1,1.1-0.2,2.2-0.2,3.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M266.9,921.8
        c0.5-0.2,0.8,0.5,0.9,2.1c0.1,0.4,0.1,1,0.1,1.6c0,0.1,0,0.1,0,0.2c0,0.4,0,0.8,0.1,1.2c0.2,4.4,0.5,8.7,0.8,13
        c0.1,1.1,0.2,2.2,0.3,3.3c0.1,0.6,0.2,1.2,0.3,1.8l1.9-1.4c-0.5-5.5-0.8-11.1-1.1-16.6c0-0.4,0-0.9-0.1-1.3
        c-0.1-2.7-0.2-5.4-0.3-8c0-0.6,0-1.1-0.1-1.7l-0.3-3.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.3,0.6-0.5,0.9c0,0.1,0,0.1-0.1,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M271.1,943.5
        c1.4-0.2,2.7-0.3,3.9-0.5l-0.4-15.5c0-0.5-0.1-1.1-0.1-1.6c-0.1-2.2-0.3-4.2-0.5-6.2c-0.5-3.9-1.2-7.3-2.1-10.3l-0.2-0.5
        c-0.6,1.3-1.4,2.5-2.3,3.6l-0.1,0.1v0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M273.5,906.3
        c-0.1,0.2-0.2,0.5-0.3,0.7c-0.4,0.8-0.8,1.6-1.3,2.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M268.7,939.8
        c-0.2,0.7-0.3,1.4-0.3,2.2v9.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M271.1,943.5
        C271.1,943.5,271.1,943.5,271.1,943.5c0.3,1.3,0.6,2.3,0.9,3.2c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0c0.1,0.2,0.3,0.4,0.5,0.5
        c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.5,0.1,0.8,0c5.7-0.7,9.2-1.7,10.7-2.8"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="327.7,957.7 
        327.7,972.1 327.7,972.8 332.7,972.8 333.2,972.8 333.2,972.1 333.2,957.7 327.7,957.7 327.3,955.2 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="327.7,972.1 
        327.6,972.8 327.7,972.8 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M326.6,973.1v2.8
        c0-0.5,0.3-1,0.9-1.3c0.6-0.4,1.3-0.6,2.2-0.6c0.8,0,1.6,0.2,2.2,0.6c0.6,0.4,0.9,0.8,0.9,1.3v-3.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="327.2,973.1 
        327.3,973.1 327.3,972.1 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="327.6,972.8 
        327.3,973.1 327.3,973.1 327.7,972.8 		"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="333.7,973.1 
        333.6,973.1 333.6,973.1 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="333.2,972.1 
        333.2,972.8 333.6,973.1 333.7,972.1 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="333.6,973.1 
        333.2,972.8 333.2,972.8 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M338.9,972.1v1.1h4.3h4v-2.7
        c0-0.2,0-0.4,0-0.6c0.2-1.3,0.9-2,2.2-2h62.3c1.3,0,2,0.7,2.2,2c0,0.2,0,0.4,0,0.6v2.7h8.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="338.9" y1="973.1" x2="338.9" y2="973.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M337.1,973.1v2.8
        c0-0.5,0.3-1,0.9-1.3c0.6-0.4,1.3-0.6,2.2-0.6c0.8,0,1.6,0.2,2.2,0.6c0.6,0.4,0.9,0.8,0.9,1.3l0-2.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="313.8" y1="973.1" x2="313.9" y2="973.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M321.8,977
        c0.1,0.6,0.3,1.1,0.6,1.6c0.5,0.7,1.1,1.3,1.9,1.7c-0.8-0.4-1.5-0.9-1.9-1.7C322.2,978.2,322,977.6,321.8,977
        c-0.1-0.5-0.1-1.1-0.2-1.7v0.7C321.7,976.4,321.7,976.7,321.8,977z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M321.6,976c0-0.2,0-0.3,0-0.5
        v1.5h0V976z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M321.6,978.6
        c0.1,2.5,1,4.1,2.9,4.9h0C322.7,982.8,321.7,981.1,321.6,978.6L321.6,978.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M308.5,976
        c-0.2,0-0.4-0.1-0.7-0.1v2.7h13.7c0-0.1,0-0.2,0-0.3V977h-7.7c0,0,0,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M321.6,977v1.3
        c0,0.1,0,0.2,0,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M324.5,983.5h-16.6v7.7h145.3
        v-7.7h-16.6c-0.8,0.3-1.8,0.5-2.9,0.5h-24.2v0.5h-1.3V984H404v0.5h-1.3V984h-4.2v0.5H397V984h-6.7c-1.1,0-2.1-0.2-2.9-0.5h-13.8
        c-0.8,0.3-1.7,0.5-2.9,0.5h-6.7v0.5h-1.3V984h-4.2v0.5h-1.3V984h-4.2v0.5h-1.3V984h-24.2l-1.9-0.2
        C325.1,983.8,324.8,983.7,324.5,983.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M324.5,983.5
        c0.3,0.1,0.6,0.2,1,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M332.7,975.9
        c0,0.6-0.3,1-0.9,1.4c-0.6,0.4-1.3,0.6-2.2,0.6c-0.8,0-1.6-0.2-2.2-0.6c-0.6-0.4-0.9-0.8-0.9-1.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.2,975.9
        c0,0.6-0.3,1-0.9,1.4c-0.6,0.4-1.3,0.6-2.2,0.6c-0.8,0-1.6-0.2-2.2-0.6c-0.6-0.4-0.9-0.8-0.9-1.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="321.6" y1="973.1" x2="321.6" y2="975.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="327.3" y1="973.1" x2="327.2" y2="973.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="351.6,984 351.6,981 
        351.6,980.8 327.4,980.8 324.4,980.3 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="321.6" y1="975.3" x2="321.6" y2="973.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="313.9" y1="977" x2="313.9" y2="973.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M307.1,975.7
        c0.2,0.1,0.5,0.1,0.8,0.2c0,0,0,0,0.1,0v0"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="307.8,975.9 
        307.8,978.6 307.9,978.6 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="307.9,983.5 
        307.8,983.5 307.8,991.3 307.9,991.3 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M297.6,973.4v2.9
        c0.5,0,1-0.2,1.5-0.5c0.1,0.9,0.5,1.4,1,1.6c0.1,0,0.3,0.1,0.5,0.1c0.8,0,1.5-0.1,2.1-0.3v1.4h5.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="302.7,978.6 
        302.7,983.5 307.8,983.5 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M302.7,983.5v1.4
        c-0.5-0.2-1.2-0.3-2.1-0.3c-0.2,0-0.4,0-0.5,0.1c-0.5,0.2-0.9,0.8-1,1.6c-0.5-0.4-1-0.5-1.5-0.5v4.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="297.6" y1="985.8" x2="297.6" y2="976.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="299.1" y1="986.4" x2="299.1" y2="975.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M321.5,995.2
        c0.2,0,0.3,0,0.5,0.1c0.1,0,0.2,0,0.3,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M275,943
        c2.6-0.4,4.7-0.8,6.3-1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.7,969.6
        c-5.9-2.6-9.8-5.6-11.7-9c-2.8-4.4-4.8-9.6-5.8-15.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="333.7" y1="955.2" x2="333.2" y2="957.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="313.9" y1="953.9" x2="313.9" y2="957.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M400.4,809.5
        C400.4,809.5,400.4,809.5,400.4,809.5l-1.8-1.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M396.8,806.9l-0.2,1l1.3,0.8
        c0,0,0,0,0,0c0.5,0.3,1.3,0.6,2.3,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M396.7,807.9
        c-2.6-0.1-5.3-0.1-8-0.1c-0.7,0-1.5,0-2.2,0c0,0-0.1,0-0.1,0l-1.4,0.8c0,0-0.1,0-0.1,0.1c-0.5,0.3-1.3,0.5-2.4,0.6l2.3-1.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="386.8" y1="806.7" x2="386.4" y2="807.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M374.3,806.7l0.4,1l1.4,0.8
        c0,0,0.1,0,0.1,0.1c0.5,0.3,1.3,0.5,2.4,0.6l-2.3-1.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M364.3,806.9l0.2,1
        c3.4-0.1,6.8-0.1,10.1-0.2c0,0,0.1,0,0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M362.5,808l-1.9,1.5
        c0,0,0.1,0,0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M364.4,807.9l-1.3,0.8
        c0,0,0,0,0,0c-0.5,0.3-1.3,0.6-2.3,0.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="350.5" y1="807.3" x2="350.4" y2="808.4"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="351.8,814.2 
        351.8,815 409.4,815 409.4,814.2 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="457.4" y1="816.6" x2="458.1" y2="816.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="439.6" y1="810.8" x2="441" y2="810.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.4,816.6h-3.9
        c-0.3-0.3-0.7-0.6-1-0.9c-2.4-2-5.9-3.3-10.3-4l-3.1,0.6c0.6,0.1,1.2,0.3,1.8,0.4c3.7,1.1,6.5,2.9,8.4,5.6l3.3,4l7.7-0.3
        c0.4-0.1,0.7-0.2,1.1-0.4l0.2-0.2"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="442.3,811.6 
        444,811.3 445.3,811 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M431.5,822.1l19.9,0.2l0-0.1
        l-3.3-4c-1.9-2.7-4.8-4.6-8.4-5.6c-1-0.3-2.2-0.6-3.3-0.7c-0.1,0-0.2,0-0.2,0l-0.3,0c-0.3,0-0.6-0.1-0.9-0.1
        c-0.3,0-0.6-0.1-0.9-0.1c-0.4-0.1-0.9-0.1-1.3-0.1c-2.5-0.2-5-0.5-7.6-0.7l-1.3-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M452.7,822.3l-1.2,0l0.6,2.7
        c0,0,0,0.1,0,0.1l1.2,0L452.7,822.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M434,824.9l17.3,0.2h0.8
        c4.8,15.2,8.6,31.6,11.4,49.3h0.6h0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M443.5,874.4h14.4h4.8
        c-2.8-17.4-6.5-33.8-11.4-49.2c0,0,0-0.1,0-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462.5,824.1l-0.2,0.1
        l-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0-0.2,0-0.3,0.1l-0.7,0l-7,0.3c4.9,15.4,8.7,31.8,11.4,49.3h0.4
        v0c0.6,0,1.1,0,1.6,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M422.9,809l0.5,0.4l1.4,1.3
        c-0.7-0.3-1.4-0.6-1.9-0.9c-0.3-0.2-0.5-0.3-0.7-0.5c-0.2-0.1-0.4-0.3-0.5-0.4l-3-0.2c-2.6-0.1-5.2-0.3-7.9-0.4
        c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.3-0.7,0.4c-0.6,0.3-1.3,0.5-2.2,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M436.4,811.9
        c0.2,0,0.5,0,0.7,0.1c0.7,0.1,1.4,0.2,2.1,0.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="421.5" y1="808.9" x2="421.2" y2="807.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="410.6" y1="807.3" x2="410.7" y2="808.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="409.1" y1="808.3" x2="407.4" y2="809.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M465.2,874.4c0,0,0,0.1,0,0.1
        c0.2,1,0.4,2,0.5,2.9c0,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0,0.1,0c0.5,0,1,0,1.5,0.1c2.8,0.1,5,0.4,6.7,0.8c0.2,0,0.4,0.1,0.6,0.1
        c0.1,0,0.1,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.5,0.2,0.7,0.3c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3
        c0,0,0,0,0,0v0c0.1,0.1,0.2,0.2,0.4,0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M473.8,875.1
        c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0c-0.6-0.1-1.3-0.2-2-0.3c-0.2,0-0.4,0-0.6-0.1c-0.7-0.1-1.5-0.1-2.3-0.2
        c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M464.1,874.4
        c0.2,1.1,0.4,2.2,0.6,3.2c0.1,0.4,0.3,0.7,0.6,0.9c2.6,2,5.1,4.7,7.6,8.1c0.1,0.2,0.2,0.4,0.4,0.5c0,0.1,0.1,0.2,0.1,0.3
        c0.2,0.3,0.3,0.7,0.4,1.1c0.1,0.2,0.1,0.4,0.1,0.7l0,0.2c1.5,16.5,2.4,33.4,2.5,50.9h0.2c0.3,0.3,0.8,0.5,1.4,0.8
        c-0.4-17.8-1.4-35.1-3.2-51.9l0-0.2c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.4-0.2-0.6
        c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.3-0.4-0.4-0.5c-2.4-3.4-4.9-6.1-7.4-8c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.3
        c0,0-0.1-0.1-0.1-0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="462.7" y1="874.4" x2="463.5" y2="874.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="439.6" y1="810.8" x2="434.9" y2="811.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M473.8,875.1
        c0.1,0,0.3,0,0.4,0.1c0.3,0.1,0.5,0.1,0.7,0.2c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1
        c0.2,0.1,0.5,0.2,0.7,0.3c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0v0c0,0,0-0.1,0-0.1
        c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.4-0.1-0.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c-0.2-1.1-0.4-2.1-0.7-3.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M476.8,876.1
        C476.8,876.1,476.9,876.1,476.8,876.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M480.6,871.7v3.5
        c0,0.6,0.4,0.9,1.1,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M484.6,888.8
        c-0.2-0.3-0.4-0.5-0.6-0.6c0.3,0.6,0.5,1.2,0.7,1.9c0.1,0.2,0.1,0.4,0.2,0.7l0,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1
        c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0.1,0,0.1c0.1,0.7,0.2,1.3,0.3,2l0.5-0.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="477.1" y1="879.7" x2="477.2" y2="879.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M478.2,941.1
        c0.5,0.2,1,0.4,1.6,0.6c0.8-11.7,1.2-23.3,1.2-35c-1.3-2.6-1.9-7.4-1.9-14.3c0.1-1,0.3-1.6,0.6-1.7h0.6c0.4,0,0.7,0.4,0.9,1.2
        c0.3,1.5,0.6,3,1,4.5c0.5,0.2,1.1,1,1.8,2.3c-0.4-3.3-1.1-6.8-2.1-10.4l-0.1-0.1l-7.4-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M484.1,898.6
        c0.3,0.9,0.6,1.7,0.9,2.5c-0.1-0.8-0.1-1.5-0.2-2.3c-0.1-1.5-0.3-2.8-0.5-4.1c-0.2-0.9-0.3-1.8-0.5-2.5c-0.3-1.5-0.3-2-0.1-1.6
        c0.1,0.1,0.2,0.3,0.3,0.6c0.3,1,0.6,2.2,0.8,3.5l0.9-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M484,888.2l-2-0.1
        c-1.8-3.4-4.2-6.5-7.2-9.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="484.4" y1="894.8" x2="484.8" y2="894.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M482.7,885.7
        c0.5,0.7,0.9,1.6,1.3,2.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M485.1,901.1
        c0.1,0.4,0.3,0.7,0.5,1.1c-0.1-1.2-0.1-2.4-0.2-3.4l-0.4,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481,906.7
        c0.5,0.9,1,1.5,1.6,1.9c0.7,0.4,1.6,0.7,2.6,0.9c0.1,0,0.3,0,0.4,0.1h1c1.2-0.1,1.5-0.6,1-1.6c-0.1-0.1-0.1-0.2-0.2-0.4
        c-2.3-3.5-3.9-7.3-5.1-11.3c-0.9-0.4-1.3,1.1-1.4,4.4C481,902.7,481,904.7,481,906.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M487.4,896l0.2,2.2
        c0,0.2-0.1,0.3-0.3,0.4l-0.5,0.1l-0.1,0c0.1,0.5,0.1,1,0.2,1.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M486.8,898.6l-0.5,0.1
        c0.1,0.5,0.1,1.1,0.2,1.6c0.2,1.6,0.3,3.1,0.5,4.7c0.2,0.5,0.4,0.9,0.6,1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M491.7,912.6l-0.3,3.2
        c0,0.6,0,1.1-0.1,1.7c-0.1,2.7-0.1,5.4-0.3,8c-0.2,6-0.6,12-1.1,18l1.9,1.4c0.1-0.6,0.2-1.2,0.3-1.8c0.1-1.1,0.2-2.2,0.3-3.3
        c0.4-4.7,0.7-9.4,0.9-14.2c0-0.1,0-0.1,0-0.2c0-0.5,0.1-1.1,0.1-1.6c0.1-1.6,0.4-2.3,0.9-2.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M485.5,902.3
        c0.4,0.9,0.9,1.8,1.4,2.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="486.3" y1="898.7" x2="485.3" y2="898.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M489.2,909.3
        c-1.4,4.5-2.2,10-2.6,16.5c0,0.5-0.1,1.1-0.1,1.6l-0.4,15.5c1.2,0.2,2.5,0.3,3.9,0.5c0,0,0,0,0,0.1c-0.3,1.2-0.6,2.3-0.9,3.2
        c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c-0.1,0.2-0.3,0.4-0.5,0.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0.1-0.5,0.1-0.8,0
        c-5.7-0.7-9.2-1.7-10.7-2.8c0,0.2,0,0.4,0,0.6c0.1,9.4-0.7,17.4-2.2,24c5.9-2.6,9.8-5.6,11.7-9c2.8-4.4,4.8-9.6,5.8-15.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M476.6,940.3
        c0,1.5,0,3.1,0,4.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.9,874.4
        c1.7,20,2.8,40.2,3.4,60.6h3.3h1.3c3.6,0.1,5.4,1.1,5.4,3.1v2.1v0.2h5.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M471.3,940.2
        c0-0.8-0.4-1.3-1.1-1.3h-0.7h-21.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.2,943.3h20.4
        c2.6,0,3.9-0.8,3.7-2.2v-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.2,953.9h22.8
        c0.4,0,0.6,0.2,0.5,0.6l-0.4,2.7c-0.1,0.4-0.3,0.6-0.7,0.6h-22.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M492.4,939.8
        c0.2,0.7,0.3,1.4,0.3,2.2v9.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M479.8,941.7
        c1.6,0.5,3.7,0.9,6.3,1.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M409.9,945.3h-25.8v1.1
        c0,0.7-0.4,1.1-1.2,1.2c-0.1,0-0.3,0-0.4,0.1h-3.9c-0.2,0-0.3,0-0.4-0.1c-0.8-0.2-1.2-0.6-1.2-1.2v-1.1h-25.8
        c-2.7,0-4.1,1.4-4.1,4.1v14.8c0,1.3,0.6,1.9,1.9,1.9h63.1c1.3,0,1.9-0.6,1.9-1.9v-14.8C414,946.7,412.6,945.3,409.9,945.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M425.5,951.3h10.2
        c0.7,0,1-0.3,1-1v-3.3c0-0.7-0.3-1-1-1h-10.2c-0.7,0-1,0.3-1,1v3.3C424.5,950.9,424.9,951.3,425.5,951.3z"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="447.2,936.1 
        447.2,935 446.2,935 314.9,935 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M469.6,938.9v-1.1
        c0-1.8-1.6-2.8-5-2.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="461.3" y1="935" x2="447.2" y2="935"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="384.2" y1="945.3" x2="377" y2="945.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M402.6,973.1h1.3h4.2h1.3h4.2
        v-0.8c-0.1-0.4-0.3-0.6-0.7-0.7c-0.1,0-0.2,0-0.4,0h-64.2c-0.1,0-0.3,0-0.4,0c-0.4,0.1-0.7,0.3-0.7,0.7v0.8h4.2h1.3h4.2h1.3h4.2
        h1.3h12.5h8H397h1.3H402.6v7.7v0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M384.6,973.1v2.2
        c0,0.6,0.1,1.2,0.2,1.7c0.1,0.6,0.3,1.1,0.6,1.6c0.9,1.5,2.5,2.2,4.9,2.2h6.7v-7.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M384.6,977h-8v1.3
        c0,0.1,0,0.2,0,0.3h8c0-0.1,0-0.2,0-0.3V977v-1.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M387.4,983.5
        c-1.8-0.8-2.8-2.4-2.9-4.9"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="376.5,973.1 
        376.5,975.3 376.5,977 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M376.5,975.3
        c0,0.6-0.1,1.2-0.2,1.7c-0.1,0.6-0.3,1.1-0.6,1.6c-0.9,1.5-2.5,2.2-4.9,2.2h-6.7v0.2v3"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="404,984 404,981 
        402.6,981 402.6,984 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="404,981 404,980.8 
        404,973.1 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="398.4,984 398.4,981 
        397,981 397,984 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="398.4,981 
        398.4,980.8 398.4,973.1 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="397" y1="980.8" x2="397" y2="981"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="398.4" y1="980.8" x2="402.6" y2="980.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="347.2" y1="973.1" x2="347.4" y2="973.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="351.6,981 352.9,981 
        352.9,980.8 352.9,973.1 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="352.9" y1="981" x2="352.9" y2="984"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="357.1,984 357.1,981 
        357.1,980.8 352.9,980.8 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="358.5,984 358.5,981 
        357.1,981 		"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="362.7,984 362.7,981 
        362.7,980.8 358.5,980.8 358.5,981 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="362.7" y1="981" x2="364.1" y2="981"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="351.6" y1="973.1" x2="351.6" y2="980.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="362.7" y1="980.8" x2="362.7" y2="973.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="357.1" y1="980.8" x2="357.1" y2="973.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="358.5" y1="973.1" x2="358.5" y2="980.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="364.1" y1="980.8" x2="364.1" y2="973.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M376.5,978.6
        c-0.1,2.5-1,4.1-2.9,4.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M453.2,975.9
        c-1.9,0.4-3.9,0.8-6,1.1h-7.8v1.3c0,0.1,0,0.2,0,0.3h13.8V975.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M409.5,973.1v7.7h24.2
        c2.4,0,4-0.7,4.9-2.2c0.3-0.5,0.5-1,0.6-1.6c0.1-0.5,0.2-1.1,0.2-1.7v-2.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="439.5" y1="975.3" x2="439.5" y2="977"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M453.2,978.6h5.2v-1.4
        c0.5,0.2,1.2,0.3,2.1,0.3c0.2,0,0.4,0,0.5-0.1c0.5-0.2,0.9-0.8,1-1.6c0.5,0.4,1,0.5,1.5,0.5v-2.9c-3.1,0.9-6.5,1.7-10.3,2.5"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="453.2,983.5 
        458.4,983.5 458.4,978.6 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M458.4,983.5v1.4
        c0.5-0.2,1.2-0.3,2.1-0.3c0.2,0,0.4,0,0.5,0.1c0.5,0.2,0.9,0.8,1,1.6v-10.6"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="463.5,976.3 
        463.5,985.8 463.5,990.7 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462,986.4
        c0.5-0.4,1-0.5,1.5-0.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="447.2" y1="973.1" x2="447.2" y2="977"/>
      
        <rect x="427.9" y="957.7" fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" width="5.5" height="15.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="433.8" y1="973.1" x2="433.4" y2="972.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="427.9" y1="972.8" x2="427.4" y2="973.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="413.9" y1="973.1" x2="413.8" y2="973.1"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="409.5,984 409.5,981 
        408.2,981 408.2,984 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="409.5" y1="981" x2="409.5" y2="980.8"/>
      <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" points="408.2,973.1 
        408.2,980.8 408.2,981 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M439.5,978.6
        c-0.1,2.5-1,4.1-2.9,4.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="404" y1="980.8" x2="408.2" y2="980.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="433.4" y1="957.7" x2="433.8" y2="955.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="427.4" y1="955.2" x2="427.9" y2="957.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M463.5,973.4
        c4.2-1.2,7.8-2.5,10.9-3.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M446.2,935
        c-0.4-20.4-1.3-40.6-2.7-60.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="314.9" y1="936.1" x2="446.2" y2="936.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M268.1,854.1
        c-3.5,0-5.3,2-5.3,5.9c0,0.1,0,0.2,0,0.4v6.6c0,3,0.7,5.2,1.9,6.7c1.2,1.4,3,2.1,5.4,2h9.3c0.4,0,0.6-0.2,0.6-0.5v-16.8
        c0-0.1,0-0.1,0-0.2v0c-0.1-2.7-1.6-4-4.3-4.1H268.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M337.7,809.5l-4.1,0.3
        c-3.3,0.3-6.6,0.6-9.8,0.9c-0.8,0.1-1.5,0.1-2.2,0.2h0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M317.1,811.3
        c-0.8,0.1-1.6,0.2-2.3,0.3c-0.4,0.1-0.8,0.1-1.1,0.1c-0.4,0.1-0.9,0.1-1.3,0.2c-1.7,0.3-3.3,0.9-4.8,1.8c-1,0.6-2,1.2-2.9,2l3.9,0
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M342.7,824.7h75.7
        c7.2,0.2,12.1,3.9,14.5,11.1c1.3,5.8,2.5,11.7,3.6,17.7c0,0.2,0.1,0.4,0.1,0.5c0.4,2.6-0.2,4.9-1.8,6.9c-0.1,0.2-0.3,0.4-0.5,0.5
        l-5.4,5.2c-1.3,1.1-3,1.7-5.2,1.9h-86.2c-2.2-0.1-3.9-0.8-5.2-1.9l-5.4-5.2c-0.2-0.2-0.3-0.4-0.5-0.5c-1.6-2-2.2-4.3-1.8-6.9
        c0-0.2,0.1-0.4,0.1-0.5c1.1-6,2.3-11.9,3.6-17.7C330.7,828.7,335.5,825,342.7,824.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.7,816.6
        c0.3-0.3,0.7-0.7,1-0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M285.7,870.1
        c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.4-0.1,0.8-0.2,1.2-0.3c3.1-14.4,6.8-28,11-40.9c0,0,0,0-0.1,0
        c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.2-0.1-0.2-0.1l-0.3-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M287.4,869.6
        c0.2-0.1,0.5-0.1,0.7-0.1c3.1-14.3,6.7-27.9,10.9-40.6l-0.4,0c-0.1,0-0.2,0-0.2,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M288.1,869.5
        c0.3-0.1,0.6-0.1,0.9-0.1c3-14.3,6.5-27.8,10.5-40.5l-0.5,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M302.5,829.1
        c-3.6,12.6-6.5,25.9-8.9,39.9c0.2,0,0.4,0,0.6,0v0c2.4-14.1,5.3-27.3,8.9-39.9L302.5,829.1l-0.9-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M294.3,874.4
        c2.4-15.5,5.7-30.1,9.8-43.9c0-0.1,0-0.1,0.1-0.2c0.2-0.7,0-1.1-0.6-1.2l-0.4,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M301,829
        c-3.9,12.6-7.3,26-10.1,40.2c0.2,0,0.4,0,0.6-0.1c2.8-14.1,6.1-27.5,10.1-40.1L301,829l-1.5-0.1l1.3-4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M284.7,874.8
        c0.3-0.1,0.6-0.2,0.8-0.2c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.9,0.4-1.8,0.5-2.7
        c0.1-0.7,0.3-1.4,0.4-2.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M286.4,874.4
        c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.9,0.4-1.8,0.5-2.6c0.1-0.7,0.3-1.4,0.4-2.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="286.8" y1="878.7" x2="287.4" y2="875.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M293.6,869L293.6,869
        c-0.7,0-1.4,0.1-2.1,0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M289,869.4
        c0.6-0.1,1.2-0.1,1.9-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M289,869.4
        c-0.1,0.7-0.3,1.4-0.4,2.1c-0.1,0.5-0.2,1-0.3,1.6c-0.1,0.3-0.2,0.7-0.2,1c-0.1,0.3-0.1,0.7-0.2,1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M288,874
        c-0.3,0.1-0.6,0.1-0.9,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.3,882.4
        c0.2,0.2,0.3,0.5,0.3,0.8c0,0.1,0,0.1,0,0.2c0,0.4,0,0.7-0.1,1.1c0,0.4,0,0.8-0.1,1.1c0,0.4,0,0.8,0,1.3c0.5,0.1,0.9,0.3,1.2,0.6
        c0.5,0.4,0.7,1.1,0.6,2v0.1c0,0,0,0,0,0c-0.2,2.4-0.4,4.9-0.5,7.3v0.1c0,0,0,0,0,0.1c-0.1,1.3-0.7,2.1-1.9,2.4c0,0,0,0,0,0
        c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.5c0,0.7-0.1,1.4-0.1,2.1
        c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0,0.5c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6
        c0,0,0,0.1,0,0.1c0.4,0.1,0.7,0.3,1,0.6c0.5,0.5,0.7,1.1,0.7,2v0.1c0,0,0,0,0,0c-0.2,2.5-0.4,5-0.5,7.5v0.1c0,0,0,0,0,0
        c-0.1,1.1-0.6,1.9-1.4,2.3c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c0,0.4,0,0.9,0,1.3c0,0.4,0,0.9,0,1.3c0,0,0,0,0,0
        c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.3-0.1,0.5c-0.2,0.6-0.8,0.9-1.7,1h-5.7c-1,0.1-1.6-0.3-1.8-1c-0.1-0.3-0.1-0.7,0-1.1
        c0-0.4,0-0.9,0-1.3c0-0.4,0-0.9,0.1-1.3c0-0.2,0-0.4,0-0.6v0c-0.9-0.3-1.3-0.8-1.3-1.7c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5v0
        c0.2-2.8,0.4-5.6,0.6-8.3c0,0,0,0,0,0c0.1-1,0.5-1.6,1.3-2c0,0,0,0,0,0c0-0.3,0-0.6,0-0.8c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.7
        c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0.1-0.7,0.1-1.4,0.1-2.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6
        c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0,0,0,0,0,0c-0.7-0.3-1.1-0.8-1.1-1.6c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5
        c0.2-2.7,0.4-5.5,0.6-8.2c0,0,0,0,0,0c0.1-1,0.5-1.6,1.3-2c0,0,0,0,0,0c0-0.5,0.1-1,0.1-1.5c0-0.4,0.1-0.8,0.1-1.1
        c0-0.4,0.1-0.7,0.1-1.1c0-0.1,0-0.2,0-0.4v0c0-0.2,0.1-0.4,0.3-0.6c0.3-0.3,0.8-0.4,1.5-0.4h5.3c0.1,0,0.1,0,0.2,0
        C312.7,882.1,313,882.2,313.3,882.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.3,886.9
        C313.3,886.9,313.3,886.9,313.3,886.9c-0.3-0.1-0.6-0.1-0.9-0.1h-6.1c-0.7,0-1.2,0.1-1.6,0.3c0,0-0.1,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.7,888.4
        c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.5-0.1-0.8-0.1h-6.1c-0.9,0-1.5,0.4-1.8,1c0,0.1-0.1,0.2-0.1,0.4c-0.2,2.7-0.4,5.4-0.6,8.1
        c0,0,0,0,0,0c0,0.1,0,0.3,0,0.5c0,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.7,0.5,1.4,0.5h5.9c0.6,0,1.1-0.1,1.4-0.3
        c0.5-0.3,0.7-0.7,0.8-1.3V897c0.2-2.5,0.3-4.9,0.5-7.4v-0.1C314,889,313.9,888.6,313.7,888.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M313.2,889
        C313.1,889,313.1,888.9,313.2,889c-0.3-0.3-0.7-0.4-1.2-0.4h-5.4c-1,0-1.5,0.5-1.6,1.3c-0.2,2.4-0.3,4.7-0.5,7.1v0
        c0,0.1,0,0.3,0,0.4c0.1,0.4,0.5,0.6,1.3,0.6h5.2c0.9,0,1.5-0.2,1.8-0.7c0.1-0.2,0.2-0.4,0.2-0.7v-0.1c0.2-2.2,0.3-4.3,0.4-6.5V890
        C313.4,889.5,313.3,889.2,313.2,889z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.7,893.2
        c0-1.1-0.4-2-1.1-2.7c-0.7-0.8-1.6-1.1-2.7-1.1c-1.1,0-2,0.4-2.7,1.1c-0.7,0.7-1.1,1.6-1.1,2.7c0,1,0.4,1.9,1.1,2.7
        c0.7,0.7,1.6,1.1,2.7,1.1c1.1,0,2-0.4,2.7-1.1C312.3,895.1,312.7,894.2,312.7,893.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.7,899.6
        c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.9,0.2-1.4,0.2h-5.9c-0.6,0-1.1-0.1-1.4-0.2c0,0-0.1,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.5,907.6
        c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.5-0.1-0.9-0.1h-6.3c-0.7,0-1.2,0.1-1.7,0.4c0,0-0.1,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.2,908.7
        c-0.2-0.1-0.5-0.1-0.8-0.2h-6.2c-0.9,0-1.5,0.4-1.8,1c0,0.1-0.1,0.2-0.1,0.4c-0.2,2.7-0.4,5.5-0.6,8.3c0,0,0,0,0,0
        c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.3,0.3,0.5c0.2,0.2,0.6,0.3,1.2,0.3h6c0.8,0,1.3-0.1,1.7-0.4c0.3-0.3,0.5-0.6,0.6-1.2v-0.1
        c0.2-2.5,0.3-5,0.5-7.5v-0.1c0-0.5-0.1-0.9-0.3-1.2C312.5,908.9,312.3,908.8,312.2,908.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.1,909.7
        c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.6-0.4-1.1-0.4h-5.5c-1,0-1.5,0.5-1.6,1.3c-0.2,2.4-0.4,4.8-0.5,7.3v0c0,0.1,0,0.3,0,0.4
        c0.1,0.4,0.5,0.6,1.3,0.6h5.3c1.2,0,1.9-0.5,2.1-1.4v-0.1c0-0.1,0-0.2,0-0.4c0.1-2.1,0.3-4.2,0.4-6.3v-0.1
        C312.3,910.2,312.3,909.9,312.1,909.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M293.1,906.5
        c1,0,1.5,0.5,1.5,1.5v3.7c0,1-0.5,1.5-1.5,1.5h-3.4c-1,0-1.5-0.5-1.5-1.5V908c0-1,0.5-1.5,1.5-1.5H293.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M293.3,908.7v2.4
        c0,0.5-0.2,0.7-0.7,0.7h-2.3c-0.5,0-0.7-0.2-0.7-0.7v-2.4c0-0.5,0.2-0.7,0.7-0.7h2.3C293,908,293.3,908.2,293.3,908.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M293.5,911.1c0,0.7-0.3,1-1,1
        h-2.3c-0.7,0-1-0.3-1-1v-2.4c0-0.7,0.3-1,1-1h2.3c0.7,0,1,0.3,1,1V911.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M290.1,915.6h2.3
        c0.5,0,0.7,0.2,0.7,0.7v2.4c0,0.5-0.2,0.7-0.7,0.7h-2.3c-0.5,0-0.7-0.2-0.7-0.7v-2.4C289.4,915.8,289.7,915.6,290.1,915.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M290.2,915.3h2.3
        c0.7,0,1,0.3,1,1v2.4c0,0.7-0.3,1-1,1h-2.3c-0.7,0-1-0.3-1-1v-2.4C289.2,915.6,289.5,915.3,290.2,915.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M289.6,914.1h3.4
        c1,0,1.5,0.5,1.5,1.5v3.7c0,1-0.5,1.5-1.5,1.5h-3.4c-1,0-1.5-0.5-1.5-1.5v-3.7C288.1,914.6,288.6,914.1,289.6,914.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.7,900.2h-3.2
        c0,0.2,0,0.4,0,0.6h3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.7,901.4h-3.2
        c0,0.2,0,0.4,0,0.6h3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.7,901.9h3.2
        c0-0.2,0-0.4,0-0.6h-3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.7,900.8h3.2
        c0-0.2,0-0.4,0-0.6h-3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.4,905.7h3.3
        c0-0.2,0-0.4,0-0.6h-3.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M303.4,906.9h3.3
        c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5h-3.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.5,906.4h-3.2
        c0,0.2,0,0.3,0,0.5c0,0,0,0.1,0,0.1h3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.6,905.1h-3.2
        c0,0.2,0,0.4,0,0.6h3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M304.9,911.1
        c-0.8,0.8-1.2,1.7-1.2,2.8c0,1.1,0.4,2,1.2,2.8c0.8,0.8,1.7,1.2,2.8,1.2c1.1,0,2-0.4,2.8-1.2c0.8-0.8,1.1-1.7,1.1-2.8
        c0-1.1-0.4-2-1.1-2.8c-0.8-0.8-1.7-1.1-2.8-1.1C306.6,910,305.7,910.4,304.9,911.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M312.1,920.3
        c-0.1,0.1-0.2,0.1-0.4,0.2c-0.5,0.2-1,0.2-1.7,0.2h-6c-0.5,0-0.9,0-1.3-0.1c0,0-0.1,0-0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M294.4,948.4
        c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4c0.4,0,0.6-0.1,0.9-0.4c0.2-0.3,0.4-0.6,0.4-0.9c0-0.4-0.1-0.7-0.4-0.9
        c-0.2-0.2-0.5-0.4-0.9-0.4c-0.4,0-0.6,0.1-0.9,0.4C294.5,947.7,294.4,948.1,294.4,948.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M295,949.1
        c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.6-0.3
        c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7C294.7,948.7,294.8,948.9,295,949.1z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="304.8" y1="884.5" x2="313.4" y2="884.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="304.7" y1="885.7" x2="313.4" y2="885.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="305.2" y1="882.4" x2="313.3" y2="882.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="313.5" y1="883.4" x2="304.9" y2="883.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="312.6" y1="902.5" x2="303.6" y2="902.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="303.5" y1="904.5" x2="312.6" y2="904.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="312.1" y1="921.1" x2="302.7" y2="921.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="312.1" y1="922.5" x2="302.6" y2="922.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="312" y1="923.8" x2="302.6" y2="923.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="311.9" y1="924.9" x2="302.6" y2="924.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="274.2" y1="894.4" x2="273.8" y2="898.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="274.3" y1="898.6" x2="274.7" y2="894.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="272.2" y1="899.9" x2="272.6" y2="895.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="273.1" y1="895.9" x2="272.6" y2="899.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M271.4,947.4
        c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2,0c-0.1,0-0.1,0.1-0.2,0.3c0,0.2,0,0.4,0.1,0.7c0.1,0.3,0.2,0.5,0.3,0.7
        c0.1,0.2,0.2,0.2,0.3,0.2c0.1,0,0.1-0.1,0.2-0.4c0-0.2,0-0.4-0.1-0.7c0-0.1-0.1-0.2-0.1-0.4C271.5,947.6,271.5,947.5,271.4,947.4z
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M271.4,947.1
        c-0.1-0.2-0.2-0.3-0.3-0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.2-0.2,0.5c0,0.3,0,0.6,0.1,0.9c0.1,0.4,0.2,0.6,0.4,0.9
        c0.1,0.2,0.3,0.3,0.4,0.3c0.1,0,0.2-0.2,0.2-0.5c0-0.2,0-0.5-0.1-0.9c0-0.1-0.1-0.2-0.1-0.2C271.6,947.5,271.5,947.3,271.4,947.1z
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M444,811.3
        c1.6,0.2,3.2,0.4,4.8,0.5c2.8,0.6,5.4,1.8,7.7,3.8c0.3,0.3,0.7,0.6,1,0.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M423.4,809.5l4.1,0.3
        c4.1,0.3,8.1,0.7,12.1,1.1h0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M460.3,824.8l1.3,4l0.5,0
        l0.4,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0.1,0c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.2-0.1,0.2-0.1l0.3-0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M466.8,874.4
        c-2.4-15.5-5.7-30.1-9.8-43.9c0-0.1,0-0.1-0.1-0.2c-0.2-0.7,0-1.1,0.6-1.2l0.4,0l0.6,0l0.9-0.1l0.6,0l1.5-0.1
        c4.1,12.7,7.6,26.2,10.5,40.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="452.5" y1="815.7" x2="456.4" y2="815.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M473.3,875
        c-0.1-0.3-0.2-0.7-0.2-1c-0.1-0.3-0.2-0.7-0.2-1c-0.1-0.5-0.2-1-0.3-1.6c-0.1-0.7-0.3-1.4-0.4-2.1c-0.6-0.1-1.2-0.1-1.9-0.2
        c-0.2,0-0.4,0-0.6-0.1c-0.7-0.1-1.3-0.1-2.1-0.1h0c-0.2,0-0.4,0-0.6,0v0c-2.4-14.1-5.3-27.3-8.9-39.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="474.3" y1="878.7" x2="473.8" y2="875.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M467.5,869
        c-2.4-14.1-5.3-27.4-8.9-39.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M459.5,829
        c3.9,12.6,7.3,26,10.1,40.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M481.1,858.2L481.1,858.2
        c0.1-2.7,1.6-4.1,4.3-4.1h7.6c3.5,0,5.3,2,5.3,5.9c0,0.1,0,0.2,0,0.4v6.6c0,3-0.7,5.2-1.9,6.7c-1.2,1.4-3,2.1-5.4,2h-9.3
        c-0.4,0-0.6-0.2-0.6-0.5v-16.8C481.1,858.3,481.1,858.2,481.1,858.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462.7,828.8
        c4.2,12.8,7.9,26.4,11,40.9c0.4,0.1,0.9,0.2,1.2,0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M473.1,874
        c0.3,0.1,0.6,0.1,0.9,0.2c-0.2-0.9-0.4-1.8-0.5-2.6c-0.1-0.7-0.3-1.4-0.4-2.1c-0.3-0.1-0.6-0.1-0.9-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M474,874.2
        c0.3,0.1,0.5,0.1,0.7,0.2c-0.2-0.9-0.4-1.8-0.5-2.7c-0.1-0.7-0.3-1.4-0.4-2.1c-0.2-0.1-0.5-0.1-0.7-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M476.4,874.8
        c-0.3-0.1-0.6-0.2-0.8-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M470.2,869.2
        c-2.8-14.2-6.2-27.6-10.1-40.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M462,828.9
        c4.2,12.8,7.9,26.3,10.9,40.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M472.9,908
        c0-1-0.5-1.5-1.5-1.5H468c-1,0-1.5,0.5-1.5,1.5v3.7c0,1,0.5,1.5,1.5,1.5h3.4c1,0,1.5-0.5,1.5-1.5V908z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M470.8,907.7h-2.3
        c-0.7,0-1,0.3-1,1v2.4c0,0.7,0.3,1,1,1h2.3c0.7,0,1-0.3,1-1v-2.4C471.8,908,471.5,907.7,470.8,907.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M471.5,908.7
        c0-0.5-0.2-0.7-0.7-0.7h-2.3c-0.5,0-0.7,0.2-0.7,0.7v2.4c0,0.5,0.2,0.7,0.7,0.7h2.3c0.5,0,0.7-0.2,0.7-0.7V908.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M471.5,914.1h-3.4
        c-1,0-1.5,0.5-1.5,1.5v3.7c0,1,0.5,1.5,1.5,1.5h3.4c1,0,1.5-0.5,1.5-1.5v-3.7C473,914.6,472.5,914.1,471.5,914.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M471,915.6h-2.3
        c-0.5,0-0.7,0.2-0.7,0.7v2.4c0,0.5,0.2,0.7,0.7,0.7h2.3c0.5,0,0.7-0.2,0.7-0.7v-2.4C471.7,915.8,471.4,915.6,471,915.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M470.9,915.3h-2.3
        c-0.7,0-1,0.3-1,1v2.4c0,0.7,0.3,1,1,1h2.3c0.7,0,1-0.3,1-1v-2.4C471.9,915.6,471.6,915.3,470.9,915.3z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="488.5" y1="899.9" x2="488" y2="895.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="488.9" y1="899.9" x2="488.5" y2="895.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="487.3" y1="898.5" x2="486.9" y2="894.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="486.9" y1="898.6" x2="486.4" y2="894.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M490.1,947.5
        c0,0.2,0,0.4-0.1,0.7c-0.1,0.3-0.2,0.5-0.3,0.7s-0.2,0.2-0.3,0.2c-0.1,0-0.1-0.1-0.2-0.4c0-0.2,0-0.4,0.1-0.7
        c0-0.1,0.1-0.2,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2,0
        C490.1,947.2,490.1,947.3,490.1,947.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M490.3,947.3
        c0,0.3,0,0.6-0.1,0.9c-0.1,0.4-0.2,0.6-0.4,0.9c-0.1,0.2-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.2-0.2-0.5c0-0.2,0-0.5,0.1-0.9
        c0-0.1,0.1-0.2,0.1-0.2c0.1-0.3,0.2-0.5,0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.3c0,0,0.1,0,0.1,0C490.2,946.9,490.3,947,490.3,947.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M454.4,882h-5.3
        c-0.1,0-0.1,0-0.2,0c-0.5,0-0.8,0.2-1.1,0.4h8.1C455.6,882.2,455.1,882,454.4,882z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M456.2,883.4h-8.6
        c0,0.4,0,0.7,0.1,1.1h8.6C456.3,884.2,456.2,883.8,456.2,883.4c0-0.1,0-0.2,0-0.4v0c0-0.2-0.1-0.4-0.3-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.7,884.5
        c0,0.4,0,0.8,0.1,1.1h8.7c0-0.4-0.1-0.8-0.1-1.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.7,885.7
        c0,0.4,0,0.8,0,1.3c0,0,0,0,0.1,0c0.2-0.1,0.5-0.1,0.8-0.1h6.1c0.7,0,1.2,0.1,1.6,0.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0
        c0-0.5-0.1-1-0.1-1.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M456.6,889
        c0,0.1,0.1,0.2,0.1,0.4c0.2,2.7,0.4,5.4,0.6,8.1c0,0,0,0,0,0c0,0.1,0,0.3,0,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.3-0.7,0.5-1.4,0.5
        h-5.9c-0.6,0-1.1-0.1-1.4-0.3c-0.5-0.3-0.7-0.7-0.8-1.3V897c-0.2-2.5-0.3-4.9-0.5-7.4v-0.1c0-0.5,0.1-0.9,0.3-1.2
        c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.5-0.1,0.8-0.1h6.1C455.7,888,456.3,888.3,456.6,889z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.8,886.9
        c-0.5,0.1-0.9,0.3-1.2,0.6c-0.5,0.4-0.7,1.1-0.6,2v0.1c0,0,0,0,0,0c0.2,2.4,0.4,4.9,0.5,7.3v0.1c0,0,0,0,0,0.1
        c0.1,1.3,0.7,2.1,1.9,2.4c0.1,0,0.1,0,0.2,0c0.4,0.1,0.9,0.2,1.4,0.2h5.9c0.6,0,1.1-0.1,1.4-0.2c0,0,0.1,0,0.1,0
        c0.7-0.3,1.1-0.8,1.1-1.6c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c-0.2-2.7-0.4-5.5-0.6-8.2c0,0,0,0,0,0c-0.1-1-0.5-1.6-1.3-2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M456.1,889.8
        c0.2,2.4,0.3,4.7,0.5,7.1v0c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.5,0.6-1.3,0.6h-5.2c-0.9,0-1.5-0.2-1.8-0.7c-0.1-0.2-0.2-0.4-0.2-0.7
        v-0.1c-0.2-2.2-0.3-4.3-0.4-6.5V890c0-0.4,0-0.7,0.2-1c0,0,0-0.1,0.1-0.1c0.2-0.2,0.6-0.4,1.1-0.4h5.4
        C455.5,888.6,456,889,456.1,889.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M454.9,895.9
        c-0.7,0.7-1.6,1.1-2.7,1.1c-1.1,0-2-0.4-2.7-1.1c-0.7-0.8-1.1-1.7-1.1-2.7c0-1.1,0.4-2,1.1-2.7c0.7-0.8,1.6-1.1,2.7-1.1
        c1.1,0,2,0.4,2.7,1.1c0.7,0.7,1.1,1.6,1.1,2.7C456.1,894.2,455.7,895.1,454.9,895.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M447.9,882.4
        c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.1,0,0.1,0,0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M448.4,899.6
        C448.4,899.6,448.4,899.6,448.4,899.6c0,0.2,0,0.4,0,0.6h3.2c0,0.2,0,0.4,0,0.6h-3.2c0,0.2,0,0.4,0,0.6h3.2c0,0.2,0,0.4,0,0.6
        h-3.2c0,0.2,0,0.4,0,0.5h9c0-0.2,0-0.3,0-0.5h-3.2c0-0.2,0-0.4,0-0.6h3.2c0-0.2,0-0.4,0-0.6h-3.2c0-0.2,0-0.4,0-0.6h3.2
        c0-0.2,0-0.4,0-0.6c0,0,0,0,0,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M448.4,900.8
        c0-0.2,0-0.4,0-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M448.4,901.9
        c0-0.2,0-0.4,0-0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.5,902.5
        c0,0.7,0.1,1.4,0.1,2.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.5,0,0.7c0,0.2,0,0.4,0,0.6c0,0.3,0,0.6,0,0.8c0,0,0,0,0,0
        c0.7,0.4,1.2,1.1,1.3,2c0,0,0,0,0,0c0.2,2.8,0.4,5.5,0.6,8.3v0c0,0.2,0,0.3,0,0.5c0,0,0,0.1,0,0.1c0,0.9-0.4,1.4-1.3,1.7v0
        c0,0.2,0,0.4,0,0.6c0,0.4,0,0.9,0.1,1.3c0,0.4,0,0.9,0,1.3c0.1,0.4,0,0.8,0,1.1c-0.2,0.7-0.8,1-1.8,1h-5.7c-0.9,0-1.5-0.4-1.7-1
        c0-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0-0.6c0,0,0,0,0,0c0-0.4,0-0.9,0-1.3c0-0.4,0-0.9,0-1.3c0-0.2,0-0.5,0-0.7c0-0.1,0-0.1,0-0.2
        c-0.9-0.4-1.4-1.2-1.4-2.3c0,0,0,0,0,0v-0.1c-0.2-2.5-0.3-5-0.5-7.5c0,0,0,0,0,0v-0.1c0-0.9,0.2-1.6,0.7-2c0.2-0.3,0.6-0.5,1-0.6
        c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.6c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6
        c0-0.2,0-0.4,0-0.6c0-0.7-0.1-1.4-0.1-2.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M448.6,905.1h3.2
        c0,0.2,0,0.4,0,0.6h-3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M448.6,906.4h3.2
        c0,0.2,0,0.3,0,0.5c0,0,0,0.1,0,0.1h-3.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M453.4,910
        c1.1,0,2,0.4,2.8,1.1c0.8,0.8,1.2,1.7,1.2,2.8c0,1.1-0.4,2-1.2,2.8c-0.8,0.8-1.7,1.2-2.8,1.2c-1.1,0-2-0.4-2.8-1.2
        c-0.8-0.8-1.1-1.7-1.1-2.8c0-1.1,0.4-2,1.1-2.8C451.4,910.4,452.3,910,453.4,910z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M448.6,907.6
        c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.5-0.1,0.9-0.1h6.3c0.7,0,1.2,0.1,1.7,0.4c0,0,0.1,0,0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M449.8,908.6h6.2
        c0.9,0,1.5,0.4,1.8,1c0,0.1,0.1,0.2,0.1,0.4c0.2,2.7,0.4,5.5,0.6,8.3c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c0,0.2-0.1,0.3-0.3,0.5
        c-0.2,0.2-0.6,0.3-1.2,0.3h-6c-0.8,0-1.3-0.1-1.7-0.4c-0.3-0.3-0.5-0.6-0.6-1.2v-0.1c-0.2-2.5-0.3-5-0.5-7.5v-0.1
        c0-0.5,0.1-0.9,0.3-1.2c0.1-0.1,0.3-0.2,0.4-0.3C449.2,908.7,449.4,908.6,449.8,908.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M449.1,909.6
        c0.2-0.2,0.6-0.4,1.1-0.4h5.5c1,0,1.5,0.5,1.6,1.3c0.2,2.4,0.4,4.8,0.5,7.3v0c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.5,0.6-1.3,0.6h-5.3
        c-1.2,0-1.9-0.5-2.1-1.4v-0.1c0-0.1,0-0.2,0-0.4c-0.1-2.1-0.3-4.2-0.4-6.3v-0.1c0-0.4,0-0.7,0.2-0.9
        C449,909.7,449,909.6,449.1,909.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.4,901.4
        c0,0.2,0,0.4,0,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.3,900.2
        c0,0.2,0,0.4,0,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.7,906.9h-3.3
        c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5h3.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M457.7,905.7h-3.3
        c0-0.2,0-0.4,0-0.6h3.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="457.6" y1="904.5" x2="448.5" y2="904.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M417.4,948
        c0.2,0.2,0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-0.9,0.4c-0.4,0-0.7-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-0.9
        c0-0.4,0.1-0.7,0.4-0.9c0.2-0.3,0.6-0.4,0.9-0.4C416.9,947.6,417.2,947.7,417.4,948z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M415.5,948.9
        c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
        c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3C415.6,949.4,415.5,949.2,415.5,948.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M449,920.3
        c0.1,0.1,0.2,0.1,0.4,0.2c0.5,0.2,1,0.2,1.7,0.2h6c0.5,0,0.9,0,1.3-0.1c0,0,0.1,0,0.1,0"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M466.1,949.1
        c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.6-0.3
        c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7C466.4,948.7,466.3,948.9,466.1,949.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M466.3,949.4
        c-0.2,0.2-0.5,0.4-0.9,0.4c-0.4,0-0.6-0.1-0.9-0.4c-0.2-0.3-0.4-0.6-0.4-0.9c0-0.4,0.1-0.7,0.4-0.9c0.2-0.2,0.5-0.4,0.9-0.4
        c0.4,0,0.6,0.1,0.9,0.4c0.2,0.3,0.4,0.6,0.4,0.9C466.7,948.8,466.6,949.1,466.3,949.4z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="449.2" y1="924.9" x2="458.5" y2="924.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="449.1" y1="923.8" x2="458.5" y2="923.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="449" y1="922.5" x2="458.5" y2="922.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" x1="449" y1="921.1" x2="458.4" y2="921.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.7,948
        c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.6,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4c0.3-0.3,0.4-0.6,0.4-0.9
        c0-0.4-0.1-0.7-0.4-0.9c-0.2-0.3-0.6-0.4-0.9-0.4C344.2,947.6,343.9,947.7,343.7,948z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="0.5" d="M343.6,948.9
        c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
        c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3C343.7,948.4,343.6,948.6,343.6,948.9z"/>
    </g>
  </g></>)
  }
}