import React from "react"
export const SaloonSvg = {
  interior: {
    width: 254.27,
    height: 506.55,
    svg: (<>
      <g id="Layer_2" data-name="Layer 2">
        <path d="M255.74,318.31s-1.7-145.94,0-174.86-1-63.75-2-67.88-5.79-29.51-12.26-35.41S212.17,26,197.19,24.52c-12.87-1.27-36.39-2.32-42.77-2.59v-.07l-.82,0-.82,0v.07c-6.38.27-29.9,1.32-42.76,2.59C95,26,72.23,34.26,65.76,40.16S54.53,71.44,53.51,75.57s-3.75,39-2,67.88,0,174.86,0,174.86l-.54,94.87v33.65l5.31,41.9,3.06,17.12,3.41,10.92s.68,10.62,90.89,10.62v0c90.22,0,90.9-10.62,90.9-10.62l3.4-10.92L251,488.73l5.32-41.9V413.18Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#bcb8b9",strokeMiterlimit: 10}} />
      </g>
      <g id="Layer_3" data-name="Layer 3">
        <path d="M241.19,181.69c-94.22-41.36-178,0-178,0l7.15,55.15,55.4-4.94,59.49.6,49,4.34Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
      </g>
      <g id="Layer_4" data-name="Layer 4">
        <path d="M65.34,198s24.56-14.74,87.44-15,86.58,13.33,86.58,13.33" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
      </g>
      <g id="Layer_5" data-name="Layer 5">
        <polygon points="127.26 156.18 127.26 147.75 116.79 147.75 116.79 149.63 106.58 149.63 106.58 151.97 105.04 151.97 105.04 156.18 127.26 156.18" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
        <polygon points="132.04 156.18 132.04 147.75 142.51 147.75 142.51 149.63 152.72 149.63 152.72 151.97 154.26 151.97 154.26 156.18 132.04 156.18" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
      </g>
      <g id="seat_front_right" data-name="seat front right">
        <g>
          <path d="M177.06,246.78h40.68s6,1,8.32,10.61l.36,23.77S223.36,294.78,215,299h-36.6s-7.49-5.27-10.72-17.7V257S170.76,248,177.06,246.78Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <path d="M227.27,295l-.88-1.45h-5.12l-3.42,3.5-4,1.94-17-.17v0l-17.33.49-3.69-2.65-2.73-3.4-5.79.28-1.61,2.64-.89,6.89v21.11l.71,5.45,2.15,3.74,2.69,2.38,4.11,1.88h8.24l.18,1.53h14v0h14l.18-1.53h8.23l4.12-1.87,2.69-2.38,2.15-3.75.71-5.44V303.12l-.71-5.51A6.55,6.55,0,0,0,227.27,295Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <polygon points="170.49 302.08 153.07 302.08 153.07 316.03 156.34 319.3 170.49 319.3 184.65 319.3 187.92 316.03 187.92 302.08 170.49 302.08" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
        </g>
      </g>
      <g id="seat_front_left" data-name="seat front left">
        <g>
          <path d="M88.59,247.22h40.68s6,1,8.31,10.61l.37,23.78s-3.06,13.61-11.4,17.87H90s-7.49-5.28-10.72-17.7V257.44S82.29,248.41,88.59,247.22Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <path d="M138.8,295.48l-.89-1.45H132.8l-3.42,3.51-4,1.94-17-.17v-.05l-17.33.49-3.69-2.65-2.73-3.39-5.79.28-1.61,2.63-.89,6.9v21.1l.71,5.45,2.15,3.75,2.68,2.38L86,338.07h8.24l.18,1.53h14v0h14l.18-1.53h8.23l4.12-1.88,2.69-2.38,2.14-3.74.72-5.45V303.56l-.71-5.51A6.55,6.55,0,0,0,138.8,295.48Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <polygon points="82.02 302.52 64.6 302.52 64.6 316.47 67.86 319.74 82.02 319.74 96.18 319.74 99.45 316.47 99.45 302.52 82.02 302.52" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
        </g>
      </g>
      <g id="Layer_8" data-name="Layer 8">
        <g>
          <path d="M142.51,232.07l1.14,81.05a2.37,2.37,0,0,0,.18.82c.51,1.25.85,4.6,9.21,4,0,0,6.87,1.1,9.05-2.4a2.44,2.44,0,0,0,.47-1.16l1-82.32Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <line x1="116.79" y1="256.35" x2="136.67" y2="256.35" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
        </g>
      </g>
      <g id="Layer_11" data-name="Layer 11">
        <path d="M135.5,442.18l3.54,3.26H167.5L170,443V354s.19-2.62-1.85-2.62l-29.73-.31A2.71,2.71,0,0,0,135.5,354v88.73" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
      </g>
      <g id="left_rear_seat" data-name="left rear seat">
        <g>
          <path d="M135.36,356.2s-2.43-5.49-8.43-5.74H88.76s-6.13.38-7.66,4.47a46.57,46.57,0,0,0-4.76,13.78V388S78.8,398.07,81,402.41c0,0,.26,7.54-3.57,9.07H75.74v20.81s1.66,11.1,7.4,12.38h8.69l2.1,2.11h25.72l2.46-2.46h9.54s3.45-1.18,4-2.33Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <polyline points="109.14 382.86 101.85 382.86 100.19 384.52 63.3 384.52 59.73 382.86 54.62 381.07" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <polygon points="64.6 422.82 64.6 408.01 96.49 408.01 96.49 422.82 93.59 425.72 67.58 425.72 64.6 422.82" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
        </g>
      </g>
      <g id="right_rear_seat" data-name="right rear seat">
        <g>
          <path d="M170.14,356.2s2.43-5.49,8.43-5.74h38.17s6.13.38,7.66,4.47a46.41,46.41,0,0,1,4.77,13.78V388s-2.47,10.08-4.64,14.42c0,0-.26,7.54,3.57,9.07h1.66v20.81s-1.66,11.1-7.4,12.38h-8.68l-2.11,2.11H185.85l-2.46-2.46h-9.54s-3.45-1.18-4-2.33Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <polyline points="143.65 382.86 150.94 382.86 152.6 384.52 189.49 384.52 193.06 382.86 198.17 381.07" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <polygon points="188.19 422.82 188.19 408.01 156.3 408.01 156.3 422.82 159.2 425.72 185.21 425.72 188.19 422.82" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
        </g>
      </g>
      <g id="Layer_12" data-name="Layer 12">
        <path d="M235.72,221.64s-9.89-9-28-4.2l-4.85,1.39H182.94l-7.4-2.82-71.24,1.17S79,219.13,68.41,225.43" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
      </g>
      <g id="steering">
        <g>
          <ellipse cx="170.49" cy="210.73" rx="23.62" ry="11.11" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
          <ellipse cx="170.81" cy="210.9" rx="21.51" ry="8.96" style={{fill: "none",stroke: "#231f20",strokeMiterlimit: 10}} />
        </g>
      </g>
      <g id="Layer_14" data-name="Layer 14">
        <path d="M255.06,199.44l23,12.63a4,4,0,0,1,2.09,3.54v6a2.37,2.37,0,0,1-1.6,2.24,8.7,8.7,0,0,1-4,.24l-19.45-9.44Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#bcb8b9",strokeMiterlimit: 10}} />
        <path d="M51.92,198,29,210.59a4,4,0,0,0-2.09,3.54v6a2.37,2.37,0,0,0,1.6,2.24,8.7,8.7,0,0,0,4,.24l19.45-9.44Z" transform="translate(-26.36 -21.34)" style={{fill: "none",stroke: "#bcb8b9",strokeMiterlimit: 10}} />
      </g></>)
  },
  exterior: {
    width: 181.3,
    height: 282.16,
    svg: (<>
      <g id="bonnet">
        <path id="path4141" d="M136.54,73.76l-2.8-2,1.1-3.3h3.5l1.1,3.3Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4143" d="M148.34,68.36l19.3,54.7" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4145" d="M123.44,68.16l-20,54.7" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4135-9" d="M167.84,122.86V71.76L150,69.36l-2-1.2h-24.7l-2.4,1.2-17.6,2.7v50.1" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4243" d="M167.84,122.16s-13.7-7.3-19.3-7.3h-25.4c-7.1,0-19.8,7.3-19.8,7.3" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <path id="path4245" d="M148.94,46.92l15.9.2s4.2,1,4.4-4.4-.5-4.6-.5-4.6l-72.1.2v4.9c0,4.9,3.9,3.9,3.9,3.9h16.6c0-2.2,1.7-2,2.9-2h25.9C149.24,45.32,148.94,46.92,148.94,46.92Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4247" d="M165.34,38.42V47" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4247-2" d="M100.44,38.52v8.6" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4247-1" d="M155,38.22v8.6" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4247-0" d="M110,38.72v8.6" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4281-1" d="M155.34,39.92h-45.2" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101",strokeWidth: "0.5px"}} />
        <path id="path4281-8" d="M155,41.62h-45.2" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101",strokeWidth: "0.5px"}} />
        <path id="path4281-8-0" d="M155,43.52h-45.2" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101",strokeWidth: "0.5px"}} />
        <path id="Front_lamp_passanger_side" data-name="Front lamp passanger side" d="M105.24,38.72h4.5v7.8h-9v-7.8Z" transform="translate(-44.81 -25.72)" style={{fill: "#faf7c1"}} />
        <path id="frontlamp_driver_side" data-name="frontlamp driver side" d="M160.24,38.72H165v7.8h-9.6v-7.8Z" transform="translate(-44.81 -25.72)" style={{fill: "#faf7c1"}} />
        <path id="path4377" d="M169,38.12a3.34,3.34,0,0,0,2.2-3.2c0-2.4-1.5-2.4-1.5-2.4H96.24a2.14,2.14,0,0,0-1.7,2.4,3.82,3.82,0,0,0,1.7,3.2" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4381" d="M169,32.32s.2-6.1-3.7-6.1h-64.5c-3.9,0-4.4,6.4-4.4,6.4" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="rect4467" d="M165,29.22v.2a1.23,1.23,0,0,1-1.1,1.3H160a1.23,1.23,0,0,1-1.1-1.3v-.2a1.23,1.23,0,0,1,1.1-1.3h3.9A1.23,1.23,0,0,1,165,29.22Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="rect4467-6" d="M106.44,29.22v.2a1.23,1.23,0,0,1-1.1,1.3h-3.9a1.23,1.23,0,0,1-1.1-1.3v-.2a1.23,1.23,0,0,1,1.1-1.3h3.9A1.16,1.16,0,0,1,106.44,29.22Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <rect id="rect4487" x="55.23" y="28.8" width="65.5" height="5.4" style={{fill: "none",stroke: "#010101"}} />
        <g id="g3952">
          <path id="path3857" d="M164.06,35.84a.7.7,0,1,1,.7-.7A.68.68,0,0,1,164.06,35.84Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
          <path id="path3857-2" d="M143.16,35.84a.7.7,0,1,1,.7-.7A.68.68,0,0,1,143.16,35.84Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
          <path id="path3857-3" d="M122.36,35.84a.7.7,0,1,1,.7-.7A.68.68,0,0,1,122.36,35.84Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
          <path id="path3857-7" d="M101.46,35.84a.7.7,0,1,1,.7-.7A.68.68,0,0,1,101.46,35.84Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
        </g>
      </g>
      <g id="windshield">
        <path id="path4165" d="M167.81,122.24l-6.6,24.4s-8.5-1.5-12.5-1.5h-25.6a98.66,98.66,0,0,0-13.7,1.5l-5.9-24.4s10.7-3,16.1-3c5.9.1,26.9.3,32.2.4C157.21,119.34,167.81,122.24,167.81,122.24Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
        <path id="path4209" d="M148.51,119.24l5.6,5.6h-12.4" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101",strokeWidth: "0.75px"}} />
        <path id="path4209-7" d="M127.41,119.14l5.6,5.6h-12.4" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101",strokeWidth: "0.75px"}} />
      </g>
      <g id="Roof">
        <path id="path4175" d="M161.49,146.46s-1,16.4-1,26.4c0,18.6,1,34.9,1,34.9m-51.6-.2s1-13.4,1-34.7c0-12-1.2-26.4-1.2-26.4" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="rect4177" d="M151.29,160v7.7c0,3-2.7,5.4-6,5.4h-19.6c-3.3,0-6-2.4-6-5.4V160c0-3,2.7-5.4,6-5.4h19.6C148.59,154.56,151.29,157,151.29,160Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <line x1="95.74" y1="167.79" x2="88.32" y2="175.21" style={{fill: "none",stroke: "#010101"}} />
      </g>
      <g id="boot_area" data-name="boot area">
        <path id="path4203" d="M161.34,207.66s-8.3-2.2-12.7-2.2h-25.4c-4.4,0-13.4,2.2-13.4,2.2l-6.6,35.7v18.8s14.2,2.4,20,2.4h25.7c6.6,0,19.1-2.4,19.1-2.4v-18.8Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4207" d="M167.94,243.36s-7.1,5.4-19.3,5.4h-25.4a44.18,44.18,0,0,1-20.3-5.4" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
      </g>
      <g id="rear_window_glass" data-name="rear window glass">
        <path id="path4205" d="M158.09,209.77s-7.1-1.5-9.8-1.5h-25.2a78.28,78.28,0,0,0-10,1.2s-4.6,16.4-4.6,21.5v7.3h54.2V230C162.69,225.57,158.09,209.77,158.09,209.77Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
        <path id="path4209-76" d="M141,238.77l5.6-5.6h-12.4" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101",strokeWidth: "0.75px"}} />
      </g>
      <g id="rear_bumper" data-name="rear bumper">
        <path id="path4499" d="M171,275.18h-12.7v11.7h14.4l2.4-4.4Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4501" d="M113.79,275.18v11.7h-13.7l-2.4-4.2,3.2-7.6,12.9.1Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4503" d="M160.69,275.18h-49.3" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4505" d="M160.59,286.88h-49.3" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <rect id="rect4534" x="78.68" y="252.16" width="25.7" height="2.4" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4536" d="M149.39,279.18h9.3" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4536-0" d="M114,279.18h9.3" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4556" d="M158.19,284.48h-44.5" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4377-2" d="M172.49,286.88a3.34,3.34,0,0,1,2.2,3.2c0,2.4-1.5,2.4-1.5,2.4H99.69a2.14,2.14,0,0,1-1.7-2.4,3.82,3.82,0,0,1,1.7-3.2" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4381-0" d="M172.49,292.78s.2,6.1-3.7,6.1h-64.5c-3.9,0-4.4-6.4-4.4-6.4" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="rect4467-5" d="M168.39,296.18V296a1.23,1.23,0,0,0-1.1-1.3h-3.9a1.23,1.23,0,0,0-1.1,1.3v.2a1.23,1.23,0,0,0,1.1,1.3h3.9A1.23,1.23,0,0,0,168.39,296.18Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="rect4467-6-5" d="M109.79,296.18V296a1.23,1.23,0,0,0-1.1-1.3h-3.9a1.23,1.23,0,0,0-1.1,1.3v.2a1.23,1.23,0,0,0,1.1,1.3h3.9A1.23,1.23,0,0,0,109.79,296.18Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4585" d="M171.19,274.88l-12.9,11.7" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4587" d="M159.49,275.18l12.7,11.7" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4585-9" d="M113.89,275,101,286.68" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path4587-0" d="M100.79,275l12.7,11.7" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path3083" d="M137.09,299v7.1" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path3853" d="M137,307.38a.7.7,0,1,1,.7-.7A.75.75,0,0,1,137,307.38Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
        <g id="g3946">
          <path id="path3857-97" d="M167.41,290.7a.7.7,0,1,1,.7-.7A.75.75,0,0,1,167.41,290.7Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
          <path id="path3857-2-3" d="M146.51,290.7a.7.7,0,1,1,.7-.7C147.31,290.3,146.91,290.7,146.51,290.7Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
          <path id="path3857-3-6" d="M125.71,290.7a.7.7,0,1,1,.7-.7A.75.75,0,0,1,125.71,290.7Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
          <path id="path3857-7-1" d="M104.81,290.7a.7.7,0,1,1,.7-.7C105.61,290.3,105.21,290.7,104.81,290.7Z" transform="translate(-44.81 -25.72)" style={{fill: "#3d3d3d",stroke: "#010101"}} />
        </g>
      </g>
      <g id="left_front_door" data-name="left front door">
        <g>
          <path d="M107,172.86l-.15-9-.8-10.82a44.72,44.72,0,0,0-3.69-9.13L95.57,131.4s-4.71-6.94-5.13-7.64a30.89,30.89,0,0,0-9.86-3.29s-10.17-2-15.9,0a23.06,23.06,0,0,0-9.7,3.29v49.6L59.84,172l11.9-1.29L81,171l7.11.69,12.08.75Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <line x1="16.74" y1="145.99" x2="16.74" y2="145.99" style={{fill: "#01010",stroke: "#010101",strokeMiterlimit: 10}} />
          <line x1="16.64" y1="95.28" x2="16.64" y2="95.28" style={{fill: "#01010",stroke: "#010101",strokeMiterlimit: 10}} />
          <path id="path3850" d="M61.55,121.23v50.41" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3946" d="M71.74,119.57v51" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="left_front_window" data-name="left front window" d="M88.6,126.85c3.23,2.7,14.23,21.4,15.55,26.3,1.31,5.1,1.21,17.9,1.21,17.9l-16.76-1.2v-43Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
          <path id="path3888" d="M94,133.15h-5" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="rect3950" d="M81,160.45v6.1c0,.3-.68.5-1.46.5h0c-.79,0-1.47-.2-1.47-.5v-6.1c0-.3.68-.5,1.47-.5h0C80.35,160,81,160.15,81,160.45Z" transform="translate(-44.81 -25.72)" style={{fill: "#e6e6e5",stroke: "#010101"}} />
        </g>
      </g>
      <g id="right_front_door" data-name="right front door">
        <g>
          <path d="M163.8,173.2l.14-9,.81-10.82a44.63,44.63,0,0,1,3.68-9.13l6.82-12.49s4.71-6.93,5.13-7.63a30.79,30.79,0,0,1,9.86-3.3s10.17-2,15.9,0a23.09,23.09,0,0,1,9.69,3.3v49.6L211,172.29,199.08,171l-9.27.33-7.1.7-12.08.74Z" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <line x1="164.46" y1="146.32" x2="164.46" y2="146.32" style={{fill: "#01010",stroke: "#010101",strokeMiterlimit: 10}} />
          <line x1="164.56" y1="95.61" x2="164.56" y2="95.61" style={{fill: "#01010",stroke: "#010101",strokeMiterlimit: 10}} />
          <path id="path3850-2" data-name="path3850" d="M209.27,172V121.56" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3946-2" data-name="path3946" d="M199.08,170.9v-51" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="left_front_window-2" data-name="left front window" d="M182.22,127.19v43l-16.76,1.2s-.1-12.8,1.21-17.9c1.31-4.9,12.32-23.6,15.55-26.3Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
          <path id="path3888-2" data-name="path3888" d="M181.91,133.49h-5" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="rect3950-2" data-name="rect3950" d="M191.25,160.29h0c.79,0,1.47.2,1.47.5v6.1c0,.3-.68.5-1.47.5h0c-.79,0-1.46-.2-1.46-.5v-6.1C189.79,160.49,190.46,160.29,191.25,160.29Z" transform="translate(-44.81 -25.72)" style={{fill: "#e6e6e5",stroke: "#010101"}} />
        </g>
      </g>
      <g id="left_rear_door" data-name="left rear door">
        <g>
          <polyline points="61.11 173.19 61.98 164.63 62.21 158.66 62.15 151.51 62.15 147.49 47.37 146.57 40.44 145.96 28.2 145.29 23.44 145.56 13.11 146.85 9.99 147.42" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3942" d="M106,198.66c-2.22,3.2-8.9,9.8-18.31,16.1s-10.42,1.5-13-3.2-7.39-8.6-19.73-11.7" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3070" d="M54.9,200.28V172.7" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3850-3" data-name="path3850" d="M61.68,172.1v30.09" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3946-3" data-name="path3946" d="M71.9,171.07V208" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3892" d="M104.78,175.31l-16.59-.7v36c3.64-2,13.15-8.1,15.58-12.6C105.59,195.41,104.58,183.51,104.78,175.31Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
          <path id="path3948" d="M98.58,203.53H88.49" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="rect3950-4" d="M80.91,205.33v6.1c0,.3-.8.5-1.74.5h0c-.93,0-1.74-.2-1.74-.5v-6.1c0-.3.81-.5,1.74-.5h0C80.11,204.83,80.91,205,80.91,205.33Z" transform="translate(-44.81 -25.72)" style={{fill: "#e6e6e5",stroke: "#010101"}} />
        </g>
      </g>
      <g id="right_rear_door" data-name="right rear door">
        <g>
          <polyline points="120 173.67 119.13 165.11 118.89 159.15 118.96 152 118.96 147.97 133.74 147.06 140.66 146.44 152.9 145.77 157.67 146.04 168 147.33 171.12 147.9" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3942-2" data-name="path3942" d="M215.82,200.34c-12.35,3.1-17,7.1-19.73,11.7s-3.64,9.5-13.05,3.2-16.09-12.9-18.32-16.1" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3070-2" data-name="path3070" d="M215.82,173.18v27.58" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3850-4" data-name="path3850" d="M209,202.67V172.58" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3946-4" data-name="path3946" d="M198.83,208.46V171.55" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3892-2" data-name="path3892" d="M167,198.49c2.42,4.5,11.93,10.6,15.58,12.6v-36l-16.6.7C166.15,184,165.13,195.89,167,198.49Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
          <path id="path3948-2" data-name="path3948" d="M182.23,204H172.15" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="rect3950-4-2" data-name="rect3950-4" d="M191.55,205.32h0c.94,0,1.74.2,1.74.5v6.1c0,.3-.8.5-1.74.5h0c-.93,0-1.74-.2-1.74-.5v-6.1C189.81,205.52,190.62,205.32,191.55,205.32Z" transform="translate(-44.81 -25.72)" style={{fill: "#e6e6e5",stroke: "#010101"}} />
        </g>
      </g>
      <g id="rear_left_tyre" data-name="rear left tyre">
        <g>
          <path id="path4610" d="M58.51,233.54a13.2,13.2,0,1,1,0-26.4h0a13.2,13.2,0,0,1,0,26.4Z" transform="translate(-44.81 -25.72)" style={{fill: "#c8c8c8",stroke: "#010101"}} />
          <path id="path4612" d="M58.51,228a7.7,7.7,0,1,1,7.7-7.7A7.64,7.64,0,0,1,58.51,228Z" transform="translate(-44.81 -25.72)" style={{fill: "#fff",stroke: "#010101"}} />
        </g>
      </g>
      <g id="front_left_tyre" data-name="front left tyre">
        <g>
          <path id="path4610-2" data-name="path4610" d="M58.51,112.94a13.2,13.2,0,1,1,0-26.4h0a13.2,13.2,0,0,1,0,26.4Z" transform="translate(-44.81 -25.72)" style={{fill: "#c8c8c8",stroke: "#010101"}} />
          <path id="path4612-2" data-name="path4612" d="M58.51,107.44a7.7,7.7,0,1,1,7.7-7.7A7.64,7.64,0,0,1,58.51,107.44Z" transform="translate(-44.81 -25.72)" style={{fill: "#fff",stroke: "#010101"}} />
        </g>
        <g>
          <path id="path4610-3" data-name="path4610" d="M212.41,112.94a13.2,13.2,0,1,1,0-26.4h0a13.2,13.2,0,1,1,0,26.4Z" transform="translate(-44.81 -25.72)" style={{fill: "#c8c8c8",stroke: "#010101"}} />
          <path id="path4612-3" data-name="path4612" d="M212.41,107.44a7.7,7.7,0,1,1,7.7-7.7A7.64,7.64,0,0,1,212.41,107.44Z" transform="translate(-44.81 -25.72)" style={{fill: "#fff",stroke: "#010101"}} />
        </g>
        <g>
          <path id="path4610-4" data-name="path4610" d="M212.33,233.54a13.2,13.2,0,0,1,0-26.4h0a13.2,13.2,0,0,1,0,26.4Z" transform="translate(-44.81 -25.72)" style={{fill: "#c8c8c8",stroke: "#010101"}} />
          <path id="path4612-4" data-name="path4612" d="M212.33,228a7.7,7.7,0,1,1,7.7-7.7A7.64,7.64,0,0,1,212.33,228Z" transform="translate(-44.81 -25.72)" style={{fill: "#fff",stroke: "#010101"}} />
        </g>
      </g>
      <g id="front_right_tyre" data-name="front right tyre">
        <g>
          <path id="path4610-5" data-name="path4610" d="M212.41,112.94a13.2,13.2,0,1,1,0-26.4h0a13.2,13.2,0,1,1,0,26.4Z" transform="translate(-44.81 -25.72)" style={{fill: "#c8c8c8",stroke: "#010101"}} />
          <path id="path4612-5" data-name="path4612" d="M212.41,107.44a7.7,7.7,0,1,1,7.7-7.7A7.64,7.64,0,0,1,212.41,107.44Z" transform="translate(-44.81 -25.72)" style={{fill: "#fff",stroke: "#010101"}} />
        </g>
      </g>
      <g id="rear_right_tyre" data-name="rear right tyre">
        <g>
          <path id="path4610-6" data-name="path4610" d="M212.33,233.54a13.2,13.2,0,0,1,0-26.4h0a13.2,13.2,0,0,1,0,26.4Z" transform="translate(-44.81 -25.72)" style={{fill: "#c8c8c8",stroke: "#010101"}} />
          <path id="path4612-6" data-name="path4612" d="M212.33,228a7.7,7.7,0,1,1,7.7-7.7A7.64,7.64,0,0,1,212.33,228Z" transform="translate(-44.81 -25.72)" style={{fill: "#fff",stroke: "#010101"}} />
        </g>
      </g>
      <g id="left_front_panel" data-name="left front panel">
        <path id="path3920" d="M62.21,81.09s14.9,2.7,14.9,18.6S62,118.79,62,118.79" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="path3920-2" data-name="path3920" d="M54.62,82.7s17.93.59,18.89,16.18c1,16.61-18.89,17.91-18.89,17.91" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <line x1="10.38" y1="98.47" x2="10.38" y2="91.06" style={{fill: "none",stroke: "#010101"}} />
        <line x1="17.1" y1="95.28" x2="17.1" y2="89.77" style={{fill: "none",stroke: "#010101"}} />
        <line x1="27.2" y1="94.2" x2="27.2" y2="87.57" style={{fill: "none",stroke: "#010101"}} />
        <polyline points="10.38 57.02 10.38 45.57 16.96 42.44" style={{fill: "none",stroke: "#010101"}} />
        <path d="M61.91,83.72V68.32a4.33,4.33,0,0,1,1.34-3.58s.83-.87,2.94-.12a5.28,5.28,0,0,0,3.7.24s1.45-.51,2.22,2.53l-.42,18.72" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path d="M71.92,75.6l7.69,3,.5-8.55,3.49,8.55s1.71,8.6,2,11.52,1,11,1.17,12.87,1,10.43,1.61,13.53a71.51,71.51,0,0,0,2,7.29s-5.17-2.5-7.86-3c0,0-7.14-1.4-10.84-1.23,0,0-12.65.6-16.76,4.19" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
        <path id="rect4014" d="M80.61,108.39v2.7c0,.2-.5.3-1,.3h0c-.6,0-1-.1-1-.3v-2.7c0-.2.5-.3,1-.3h0C80.21,108.19,80.61,108.29,80.61,108.39Z" transform="translate(-44.81 -25.72)" style={{fill: "#ffcc04",stroke: "#010101"}} />
      </g>
      <g id="right_front_panel" data-name="right front panel">
        <g>
          <path id="path3920-3" data-name="path3920" d="M208.68,118.76s-15.1-3.2-15.1-19.1,14.9-18.6,14.9-18.6" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3920-4" data-name="path3920" d="M216.07,116.75s-19.92-1.29-18.89-17.91c1-15.59,18.89-16.18,18.89-16.18" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <line x1="170.69" y1="98.43" x2="170.69" y2="91.03" style={{fill: "none",stroke: "#010101"}} />
          <line x1="163.97" y1="95.24" x2="163.97" y2="89.74" style={{fill: "none",stroke: "#010101"}} />
          <line x1="153.87" y1="94.17" x2="153.87" y2="87.53" style={{fill: "none",stroke: "#010101"}} />
          <polyline points="170.69 56.98 170.69 45.54 164.11 42.4" style={{fill: "none",stroke: "#010101"}} />
          <path d="M208.78,83.68V68.28a4.33,4.33,0,0,0-1.35-3.58s-.83-.87-2.93-.12a5.28,5.28,0,0,1-3.7.24s-1.46-.51-2.22,2.54L199,86.07" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path d="M198.76,75.56l-7.68,3-.5-8.55-3.49,8.55s-1.72,8.6-2,11.52-1,11-1.17,12.87-1,10.43-1.61,13.53a70.67,70.67,0,0,1-2,7.29s5.17-2.5,7.86-3c0,0,7.14-1.4,10.83-1.23,0,0,12.66.6,16.76,4.19" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="rect4014-2" data-name="rect4014" d="M191.08,108.06h0c.5,0,1,.1,1,.3v2.7c0,.2-.4.3-1,.3h0c-.5,0-1-.1-1-.3v-2.7C190.08,108.26,190.48,108.16,191.08,108.06Z" transform="translate(-44.81 -25.72)" style={{fill: "#ffcc04",stroke: "#010101"}} />
        </g>
      </g>
      <g id="left_rear_panel" data-name="left rear panel">
        <g>
          <path id="path3920-5" d="M62,202s14.9,2.7,14.9,18.6-15.1,19.1-15.1,19.1" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3920-5-2" data-name="path3920-5" d="M54.9,203.53S73.24,206,73.24,220.34,54.9,237.59,54.9,237.59" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <line x1="10.38" y1="178.09" x2="10.38" y2="174.04" style={{fill: "none",stroke: "#010101"}} />
          <line x1="16.87" y1="179.8" x2="16.87" y2="175.75" style={{fill: "none",stroke: "#010101"}} />
          <line x1="17" y1="209.8" x2="17" y2="236.48" style={{fill: "none",stroke: "#010101"}} />
          <path d="M55.21,237.39V254s-.65,3.22,1.76,4.38l2.94.79s1.37.11,1.54,2a4.24,4.24,0,0,0,1.53,2s2.23,1,6.11,0a3.89,3.89,0,0,0,2.36-3.49l.66-3.93V233.54" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path d="M106,199.08s-1.31,9.24-2.39,14.3c0,0-1.42,4.24-2.61,6.79L96.74,229l-4.8,9.14s-2.32,5.57-2.57,6.62-1.75,8-1.75,8l-1.41,4.67s-1.34,3.48-3.44,4.69l-2-.06.45-5.33" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <line x1="27.68" y1="230.03" x2="36.92" y2="231.15" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3896" d="M103.35,204s-14.6,13.9-15.1,14.9-.2,20.1-.2,20.1,8.2-13.4,10.2-17.9S103.55,205.49,103.35,204Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
        </g>
      </g>
      <g id="right_rear_panel" data-name="right rear panel">
        <g>
          <path id="path3920-5-3" data-name="path3920-5" d="M208.91,239.63s-15.1-3.2-15.1-19.1,14.9-18.6,14.9-18.6" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3920-5-4" data-name="path3920-5" d="M215.81,237.53s-18.34-2.89-18.34-17.25,18.34-16.81,18.34-16.81" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <line x1="170.72" y1="178.02" x2="170.72" y2="173.98" style={{fill: "none",stroke: "#010101"}} />
          <line x1="164.22" y1="179.73" x2="164.22" y2="175.69" style={{fill: "none",stroke: "#010101"}} />
          <line x1="164.1" y1="209.73" x2="164.1" y2="236.41" style={{fill: "none",stroke: "#010101"}} />
          <path d="M215.51,237.33V254s.64,3.23-1.77,4.39l-2.93.79s-1.37.1-1.54,2a4.28,4.28,0,0,1-1.54,1.95s-2.23,1-6.1,0a3.88,3.88,0,0,1-2.37-3.48l-.65-3.94V233.48" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <path d="M164.71,199s1.3,9.23,2.39,14.3c0,0,1.41,4.24,2.61,6.79l4.26,8.78,4.81,9.14s2.31,5.57,2.56,6.62,1.75,8,1.75,8l1.42,4.68s1.33,3.48,3.44,4.69l2-.07-.45-5.32" transform="translate(-44.81 -25.72)" style={{fill: "none",stroke: "#010101"}} />
          <line x1="153.41" y1="229.96" x2="144.18" y2="231.09" style={{fill: "none",stroke: "#010101"}} />
          <path id="path3896-2" data-name="path3896" d="M172.46,221c2,4.5,10.2,17.9,10.2,17.9s.3-19.1-.2-20.1-15.1-14.9-15.1-14.9C167.16,205.43,170.56,216.53,172.46,221Z" transform="translate(-44.81 -25.72)" style={{fill: "#f1f7e9",stroke: "#010101"}} />
        </g>
        <circle cx="145.15" cy="217.01" r="2.68" style={{fill: "none",stroke: "#010101"}} />
      </g>
    </>)
  }
}