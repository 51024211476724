import { IBookingCreateInput, VehicleSelectionView } from "../../reducers/bookings/types";
import {
  BookingAction,
  CLEAR_BOOKING,
  SET_CREATE_BOOKING_INPUT,
  SET_VEHICLE_SELECTION_VIEW,
  UPDATE_BOOKING,
  UPDATE_BOOKING_INVOICE,
  UPDATE_VEHICLE_CHECKS
} from "./types";

export function updateBooking(payload: any): BookingAction {
  return {
    payload,
    type: UPDATE_BOOKING
  };
}

export function clearBooking(): BookingAction {
  return {
    type: CLEAR_BOOKING
  };
}

export function updateInvoice(payload: string): BookingAction {
  return {
    payload,
    type: UPDATE_BOOKING_INVOICE
  };
}
export function updateVehicleChecks(payload: any): BookingAction {
  return {
    payload,
    type: UPDATE_VEHICLE_CHECKS
  };
}
export function setVehicleSelectionView(
  payload: VehicleSelectionView
): BookingAction {
  return {
    payload,
    type: SET_VEHICLE_SELECTION_VIEW
  };
}

export function setCreateBookingInput(payload: IBookingCreateInput): BookingAction {
  return {
    payload,
    type: SET_CREATE_BOOKING_INPUT
  }
}