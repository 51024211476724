import { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  IBooking,
  BookingStatus,
  PDFViewMode,
  ICreditNote,
  IConfirmationDialogData,
  BookingType,
} from "../../../../reducers/bookings/types";
import {
  formatGraphQLErrorMessage,
} from "../../../utils";
import { GET_BOOKING } from "../../../../graphql/bookings/getBookingQuery";
import { DateTime as d, Settings } from "luxon";
import Quote from "./Quote";
import PaymentDialog from "../../../BookingConfirmation";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import PdfViewer from "../../../Pdf/ShowPdf";
import BookingSummary from "./BookingManagement";
import DownloadPdf from "../../../Pdf/DownloadPdf";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { getLocalizedBookingSyntex, getLocalizedCancelBookingSyntex, getLocalizedRASyntex } from "../../../../utils/localized.syntex";
import { BusinessCustomerStatus, CustomerType, CustomerVerificationStatus, IUser } from "../../../../reducers/auth/types";
import { GET_USER } from "../../../../graphql/user/getUser";
import { VerificationDialog } from "../../../VerificationDialog/VerificationDialog";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { CANCEL_BOOKING } from "../../../../graphql/bookings/cancelBookingMutation";
import { GET_ADJUSTABLE_CREDIT_NOTES_FOR_BOOKING } from "../../../../graphql/bookings/getAdjustableCreditNotesForBooking";
import BusinessCustomerVerificationDialog from "../../../WarningDialogBox/BusinessCustomerVerificationDialog";
import { defaultConfirmationDialogData } from "../../Summary/const";
import { CONFIRM_BOOKING } from "../../../../graphql/bookings/confirmBookingMutation";
import { Alert } from "@material-ui/lab";
import { socket } from "../../../../utils/socket";
import { SOCKET_EVENTS } from "../../utils";
import { GET_ALL_DOCUMENT_TYPES } from "../../../../graphql/customers/getAllDocumentTypesQuery";
import { IDocumentType } from "../../../Profile";
import { bookingCategories } from "../../NewBooking/utils";

export interface IDamageList {
  number: number;
  damageKind: string;
  image: string;
}

const BookingManagement = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const authUser = useSelector((state: IAppState) => state.authReducer.authUser);
  const { country } = website.organisation.address;
  const { id: bookingId } = useParams<{ id: string }>();
  const searchParams = useLocation();
  const [booking, setBooking] = useState<IBooking>();
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);
  const [pdfId, setPdfId] = useState<string | undefined>("");
  const [pdfViewMode, setPdfViewMode] = useState<string>();
  const [pdfFileName, setPdfFileName] = useState<string>("");
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [pdfTitle, setPdfTitle] = useState<string>("");
  const [pdfOpen, setPdfOpen] = useState<boolean>(false);
  const [raLoading, setRALoading] = useState<boolean>(false);
  const [adjustableCreditNotes, setAdjustableCreditNotes] = useState<ICreditNote[]>([]);
  const [adjustableAmount, setAdjustableAmount] = useState<number>(0);
  const [confirmationPrompt, setConfirmationPropmt] = useState<boolean>(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [user, setUser] = useState<IUser>();
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
  const [cancellationDialogOpen, setCancellationDialogOpen] =
    useState<boolean>(false);
  const [rentalAgreementUrl, setRentalAgreementUrl] = useState<string>();
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [confirmationDialogData, setConfirmationDialogData] = useState<
    IConfirmationDialogData
  >(defaultConfirmationDialogData);
  let CANCELLATION_REASONS = [
    'Requirement changed',
    'Other'
  ];
  const [showCancelError, setCancelError] = useState({display: false, message: ""});
  const [allRequiredDocument, setAllRequiredDocument] = useState<IDocumentType[]>([]);

  if (!website.organisation.closeGroupSharingEnabled) {
    CANCELLATION_REASONS = [...CANCELLATION_REASONS, "Price"]
  }
  else {
    CANCELLATION_REASONS = [...CANCELLATION_REASONS]
  }

  const [getBooking, { loading: bookingLoadingData, data: bookingData }] =
    useLazyQuery(GET_BOOKING, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.consumerGetBooking) {
          history.push("/account/bookings");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    });

    const [getAllDocumentTypes, { data: allDocumentTypesData, loading: documentTypesLoading }] = useLazyQuery(GET_ALL_DOCUMENT_TYPES);

  const [confirmBookingMutation] = useMutation(CONFIRM_BOOKING, {
    onCompleted: ({ consumerConfirmBooking }) => {
      history.push(`/booking?id=${consumerConfirmBooking.id}`);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
  });

  const [cancelBookingMutation, { loading: cancelBookingLoading }] = useMutation(CANCEL_BOOKING, {
    onCompleted: ({ consumerCancelBooking }) => {
      if (showCancelError.display) {
        clearCancelError();
      }
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} cancelled successfully`,
        variant: SnackBarVariant.SUCCESS
      });
      setCancellationDialogOpen(false)
      setConfirmationDialogData({
        ...confirmationDialogData,
        isOpen: false
      });
      setCancellationDialogOpen(false)
      getBooking({
        variables: {
          bookingId: consumerCancelBooking.id
        }
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      console.log(error)
      history.push("/account/bookings");
    }
  });

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: ({ user }: { user: IUser }) => {
      if (user) {
        setUser(user)
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [getAdjustableCreditNotesForBooking, { data: adjustableCreditNotesData }] = useLazyQuery(
    GET_ADJUSTABLE_CREDIT_NOTES_FOR_BOOKING, {
    fetchPolicy: "network-only"
  })

  const handleReasonChange = (reason: string) => {
    clearCancelError();
    setSelectedReason(reason);
    if (reason !== 'Other') {
      setOtherReason('');
    }
  };

  const isReasonValid = () => {
    if (selectedReason === 'Other') {
      return otherReason.trim() !== '';
    }
    return selectedReason !== '';
  };

  const handleSubmit = () => {
    if (isReasonValid() && booking) {
      cancelBookingMutation({
        variables: {
          bookingId: booking.id,
          branchId: booking.branchId,
          cancellationReason: selectedReason === "Other" ? otherReason : selectedReason
        }
      })
    } else {
      if (selectedReason === 'Other') {
        setCancelError({
          message: "Please enter the reason to cancel",
          display: true
        });
      }
      else {
        setCancelError({
          message: "Please select a reason to cancel",
          display: true
        });
      }
    }
  };

  useEffect(() => {
    if (authUser) {
      getUser({
        variables: {
          id: authUser._id,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (bookingId) {
      getBooking({
        variables: {
          bookingId,
        },
      });
      getAdjustableCreditNotesForBooking({
        variables: {
          bookingId
        }
      })
    }
  }, []);

  useEffect(() => {
    socket.auth = {
      userId: authUser?._id,
    };
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_BOOKING, (data) => {   
      getBooking({
        variables: {
          bookingId: data.bookingId
        }
      })
    });

    socket.on(SOCKET_EVENTS.RENTAL_AGGREMENT_GENERATED, (data) => {
      setRentalAgreementUrl(data.rentalAgreementUrl);
    });

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_BOOKING);
      socket.off(SOCKET_EVENTS.RENTAL_AGGREMENT_GENERATED);
    }
  }, []);

  const calcAdjustableAmount = (creditNotes: ICreditNote[]) => {
    let adjustableAmount: number = 0;
    if (creditNotes.length) {
      creditNotes.forEach((note: ICreditNote) => {
        adjustableAmount += note.availableAmount
      })
    };
    return adjustableAmount;
  }

  useEffect(() => {
    if (adjustableCreditNotesData && adjustableCreditNotesData.adjustableCreditNotesForBooking?.length) {
      setAdjustableCreditNotes(adjustableCreditNotesData.adjustableCreditNotesForBooking)
      const amount = calcAdjustableAmount(adjustableCreditNotesData.adjustableCreditNotesForBooking);
      setAdjustableAmount(amount)
    }
  }, [adjustableCreditNotesData])

  useEffect(() => {
    if (bookingData && bookingData.consumerGetBooking) {
      const _booking = bookingData.consumerGetBooking;
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_BOOKING, { bookingId: _booking.id });
      setConfirmationPropmt(false);
      if (_booking.rentalAgreementUrl) {
        setRentalAgreementUrl(
          _booking.rentalAgreementUrl
        );
      }
      setBooking(_booking);
      Settings.defaultZone = _booking.branch?.timeZone || "Europe/London"
    }
    return () => {
      Settings.defaultZone = "Europe/London"
    }
  }, [bookingData]);

  const userType = user?.businesscustomers ? CustomerType.BUSINESS : CustomerType.INDIVIDUAL;
  useEffect(() => {
    if (allDocumentTypesData && allDocumentTypesData.getAllDocumentTypes) {
      const requiredDocuments = allDocumentTypesData.getAllDocumentTypes.filter((e: any) => {
        return e.isMandatory && e.applicableFor && e.applicableFor.length && e.applicableFor.includes(userType);
      });
      setAllRequiredDocument(requiredDocuments);
    }
  }, [allDocumentTypesData, userType]);

  if (bookingLoadingData && (!booking || booking.status === BookingStatus.QUOTE)) {
    return <CircularProgress size={20} thickness={5} />;
  }

  if (
    (!booking && confirmationPrompt) ||
    (booking && booking.status === BookingStatus.CONFIRMATION_IN_PROCESS) ||
    (booking &&
      booking.status === BookingStatus.QUOTE &&
      searchParams &&
      searchParams.search.includes("redirect_status=succeeded")
    )
  ) {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <CircularProgress size={20} thickness={5} />
        </Grid>
        <Grid item>
        <Typography variant="h4" className="padding-right">
          Booking confirmation in progress
        </Typography>
        </Grid>
      </Grid>
    );
  }

  if (!booking) {
    return <></>
  }

  const canCancel = () => {
    if (booking) {
      return (
        booking.status === BookingStatus.QUOTE ||
        booking.status === BookingStatus.CONFIRMED
      );
    }
    return false;
  };

  const clearCancelError = () => {
    setCancelError({message: "", display: false});
  }

  return (
    <div className={styles.column}>
      {paymentDialogOpen && (
        <PaymentDialog
          open={paymentDialogOpen}
          setOpen={(open: boolean) => setPaymentDialogOpen(open)}
          booking={booking}
          onConfirmation={(bookingId) => {
              setPaymentDialogOpen(false);
              getBooking({
                variables: {
                  bookingId
                },
              });
            }
          }
          returnUrl={`account/bookings/${booking.id}`}
          adjustableCreditNotes={adjustableCreditNotes}
        />
      )}
      {pdfOpen ? (
        <div>
          <PdfViewer
            open={pdfOpen}
            document={pdfId}
            fileName={pdfFileName}
            title={pdfTitle}
            raLoading={raLoading}
            handleClose={() => {
              setPdfOpen(false);
            }}
            handleMail={() => {}}
            booking={booking}
            sendEmail={sendEmail}
            {...(pdfViewMode === PDFViewMode.VC_REPORT && {
              renderMode: "svg",
            })}
          />
        </div>
      ) : (
        ""
      )}

      {cancellationDialogOpen && (
        <Dialog
          open={cancellationDialogOpen}
          onClose={() => {
            setSelectedReason("");
            clearCancelError();
            setCancellationDialogOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{getLocalizedCancelBookingSyntex(country)}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"> Please select a reason to cancel the {getLocalizedBookingSyntex(country).toLowerCase()}: </DialogContentText>
            <RadioGroup value={selectedReason} onChange={(e) => handleReasonChange(e.target.value)}>
              {CANCELLATION_REASONS.map((reason) => (
                <FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />
              ))}
            </RadioGroup>

            {selectedReason === 'Other' && (
              <div>
                <h5 style={{ marginBottom: '0.25rem' }}>Please provide the reason:</h5>
                <TextField
                  type="text"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      padding: '10px 14px',
                    },
                  }}
                />
              </div>
            )}
            {showCancelError.display && 
              <Alert severity="error">{showCancelError.message}</Alert>
            }
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleSubmit} disabled={cancelBookingLoading}  >
              {(cancelBookingLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
              Confirm
            </Button>

            <Button variant="outlined" color="secondary" onClick={() => (setCancellationDialogOpen(false))} disabled={cancelBookingLoading} >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className="flex cross-center">
        <Typography
          variant="h4"
          className="semi-bold flex cross-center space-between"
        >
          <IconButton
            size="small"
            edge="start"
            onClick={() => history.push(`/account/bookings`)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="margin-left">
            {booking.status === BookingStatus.QUOTE
              ? "Quote"
              : `${booking.isSubscription ? "Subscription" : getLocalizedBookingSyntex(country)} Management`}
          </span>
        </Typography>

        {bookingLoadingData && 
          <div className={styles.updatesLoader}>
            <CircularProgress size={15}/>
          </div>
        }

        <div className="flex fill main-end">
          {booking.status === BookingStatus.REQUIRES_APPROVAL && (
            <>
              <Button variant="outlined" color="secondary" onClick={() => (setCancellationDialogOpen(true))}>
                Cancel {getLocalizedBookingSyntex(country)}
              </Button>
            </>
          )}
          {[
            BookingStatus.CONFIRMED,
            BookingStatus.IN_PROGRESS,
            BookingStatus.COMPLETED,
          ].includes(booking.status) && (
              <>
                {(booking.status === BookingStatus.QUOTE ||
                  booking.status === BookingStatus.CONFIRMED) && (
                    <Tooltip
                      title={
                        d.fromISO(booking.pickupDateTime) < d.now() || booking.zeroCostBooking
                          ? `Either pickup date and time has passed! or it is a zero charge ${getLocalizedBookingSyntex(country).toLowerCase()}`
                          : ""
                      }
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="large"
                          style={{
                            color: d.fromISO(booking.pickupDateTime) < d.now() || booking.zeroCostBooking
                              ? "rgba(0,0,0,0.3)"
                              : "#D13135",
                            textTransform: "initial",
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            d.fromISO(booking.pickupDateTime).toISO() > d.now().toISO() && setCancellationDialogOpen(true)
                          }}
                          disabled={!canCancel() || booking.zeroCostBooking || d.fromISO(booking.pickupDateTime) < d.now()}
                        >
                          Cancel {getLocalizedBookingSyntex(country)}
                        </Button>
                      </Grid>
                    </Tooltip>
                  )}
                <span className="padding-right"></span>
                {booking.status === BookingStatus.CONFIRMED && <Tooltip
                  title={
                    d.fromISO(booking.pickupDateTime) < d.now() || booking.zeroCostBooking
                      ? `Either pickup date and time has passed! or it is a zero charge ${getLocalizedBookingSyntex(country).toLowerCase()}`
                      : user?.customer && website.organisation.requiresCustomerVerification && (user?.customer?.status === CustomerVerificationStatus.PENDING ||
                        user?.customer?.status === CustomerVerificationStatus.DECLINED) ? "Your profile has either been rejected or in prending status" : ""
                  }
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                      booking.bookingCategory ? 
                      !bookingCategories[booking.bookingCategory].allowUpdate : false
                    }
                    style={{
                      background: "var(--primary-color)",
                      textTransform: "initial",
                      boxShadow: "none",
                      fontSize: 14,
                      fontWeight: 500,
                      color:
                        d.fromISO(booking.pickupDateTime) < d.now() || booking.zeroCostBooking ||
                          (user?.customer && website.organisation.requiresCustomerVerification && (user?.customer?.status === CustomerVerificationStatus.PENDING ||
                            user?.customer?.status === CustomerVerificationStatus.DECLINED))
                          ? "rgba(255,255,255,0.3)"
                          : "",
                    }}
                    onClick={() => {
                      let bookingType = booking.isSubscription ? BookingType.SUBSCRIPTION : BookingType.RENTAL;
                      if (booking.bookingCategory) {
                        bookingType = booking.bookingCategory;
                      }
                      d.fromISO(booking.pickupDateTime) > d.now() &&
                        history.push(`/update-booking?booking=${booking.id}&bookingType=${bookingType}`);
                    }}
                  >
                    Update
                  </Button>
                </Tooltip>}
                <span className="padding-right"></span>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    background: "var(--primary-color)",
                    textTransform: "initial",
                    boxShadow: "none",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    if (rentalAgreementUrl) {
                      setPdfId(rentalAgreementUrl);
                      setPdfViewMode(PDFViewMode.RENTAL_AGGREMENT);
                      setPdfFileName(
                        `${getLocalizedRASyntex(country)}-${booking.referenceNumber}.pdf`
                      );
                      setSendEmail(true);
                      setPdfTitle(`Rental Agreement`);
                      setPdfOpen(true);
                    } else {
                      history.push(`./rental-agreement?booking=${booking.id}`);
                    }
                  }}
                  disabled={!rentalAgreementUrl}
                >
                  {booking.isSubscription ? "Subscription Agreement" : getLocalizedRASyntex(country)}
                </Button>
              </>
            )}
          {booking.status === BookingStatus.QUOTE && (
            <>
              <DownloadPdf
                getSource={{ type: "QUOTE", id: booking.id }}
                pdfSource={booking.quoteUrl}
                fileName={`Quote-${booking?.referenceNumber}.pdf`}
              />
              <span className="padding-right"></span>
              {booking.zeroCostBooking ? (
                <Tooltip
                  title="Zero charge booking can only be confirmed by rental operator"
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={booking.zeroCostBooking}
                      style={{
                        background: "var(--primary-color)",
                        textTransform: "initial",
                        boxShadow: "none",
                        fontSize: 14,
                        fontWeight: 500,
                        color: booking.zeroCostBooking
                          ? "rgba(255,255,255,0.3)"
                          : ""
                      }}
                    >
                      Pay and confirm
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    d.fromISO(booking.pickupDateTime) < d.now()
                      ? "Pickup date and time is in past"
                      : ""
                  }
                >
                  {website.organisation.closeGroupSharingEnabled ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        confirmBookingMutation({
                          variables: {
                            bookingId: booking?.id,
                            branchId: booking?.branchId,
                            bookingType: "PREPAID"
                          }
                        });
                      }}
                      style={{
                        background: "var(--primary-color)",
                        textTransform: "initial",
                        boxShadow: "none",
                        fontSize: 14,
                        fontWeight: 500,
                        color: booking.zeroCostBooking
                          ? "rgba(255,255,255,0.3)"
                          : ""
                      }}
                    >
                      Confirm Booking
                    </Button>) : (
                    <Button
                      onClick={() => {
                        getAllDocumentTypes()
                        if (user?.customer) {
                          if (website.organisation.requiresCustomerVerification &&
                            user?.customer?.status === CustomerVerificationStatus.IN_REVIEW) {
                            return snackbar({
                              message: "Verification status is in review",
                              variant: SnackBarVariant.ERROR
                            });
                          }
                          if (website.organisation.requiresCustomerVerification && (
                            user?.customer?.status === CustomerVerificationStatus.PENDING ||
                            user?.customer?.status === CustomerVerificationStatus.DECLINED
                          )) {
                            setOpenVerificationDialog(true);
                          } else if ((!website.organisation.requiresCustomerVerification && d.fromISO(booking.pickupDateTime) > d.now()) ||
                            (website.organisation.requiresCustomerVerification && user?.customer?.status === CustomerVerificationStatus.APPROVED
                              && d.fromISO(booking.pickupDateTime) > d.now())) {
                            setPaymentDialogOpen(true)
                          }
                        }
                        if (user?.businesscustomers && d.fromISO(booking.pickupDateTime) > d.now()) {
                          if ((website.enableBusinessCustomerSignup && (
                            user.businesscustomers.status === BusinessCustomerStatus.PENDING ||
                            user.businesscustomers.status === BusinessCustomerStatus.DECLINED))) {
                            setDialogBoxOpen(true);
                            return snackbar({
                              message: "Verification status is pending",
                              variant: SnackBarVariant.ERROR
                            })
                          } else {
                            setPaymentDialogOpen(true)
                          }
                        }
                      }}
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{
                        background: "var(--primary-color)",
                        textTransform: "initial",
                        boxShadow: "none",
                        fontSize: 14,
                        fontWeight: 500,
                        color:
                          d.fromISO(booking.pickupDateTime) < d.now()
                            ? "rgba(255,255,255,0.3)"
                            : "",
                      }}
                    >
                      Pay and confirm
                    </Button>)
                  }

                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
      {booking.status === BookingStatus.QUOTE ? (
        <Quote booking={booking} />
      ) : (
        <BookingSummary booking={booking} />
      )}
      {openVerificationDialog && (
        <VerificationDialog
          open={openVerificationDialog}
          handleClose={() => setOpenVerificationDialog(false)}
          user={user}
          require= {allRequiredDocument}
        />
      )}
      <BusinessCustomerVerificationDialog
        open={dialogBoxOpen}
        handleClose={() => setDialogBoxOpen(false)}
      />
    </div>
  );
};

export default BookingManagement;