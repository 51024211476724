import React from "react"
export const Mini3DoorSVG = {
    interior: {
      width:378,
      height:704,
      svg:(<>
      <g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M185.8,12.7c0,0,77,1,122,20c0,0,12.1,4.8,16.7,13.8
          c1.2,2.3,2.8,4.5,4.7,6.3c2.3,2.3,5.4,5.6,7.6,9.2c0,0,8,13.7,9,80.7V171c0,0.7,0,1.3-0.1,1.9c-0.3,3.2-1.3,13.5-3.5,23.8
          c-0.3,1.3-0.5,2.6-0.5,4l-0.9,24l2,147l-1,138.6c0,1.6,0.1,3.2,0.5,4.7c1,5.1,3.5,17.2,5.1,24c0.3,1.2,0.5,2.3,0.5,3.5
          c0.7,9.2,3.6,50,1.9,72.1l-2,28c0,0-0.9,14.1-10.1,20.6c-0.6,0.4-1.2,0.8-1.9,1.1l-16,8.6c-5.1,2.7-10.5,4.7-16,6
          c-15.8,3.6-67.5,13.5-117,12.8"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M185.8,12.7c0,0-61,0-113,21c0,0-14.3,3.3-24.4,16.8
          c-1.2,1.6-2.4,3.1-3.8,4.5c-4.8,4.8-6.6,7.2-8.8,10.8c0,0-7,6-8,78v28.3c0,0.6,0,1.2,0.1,1.9c0.3,3.2-0.3,9.4,1.9,19.8
          c0.3,1.3,2.9,6.7,3,8l2,25v145l4,141c0,1.6,0.3,6.4,0,8c-1,5.1-2,7.5-3.6,14.4c-0.3,1.2-1.3,11.4-1.4,12.6c-0.7,9.2,0,50,0,72
          l2,23c0,0,1,13,5.1,19.6c0.4,0.6,3.3,3.1,3.9,3.4l9.4,6.3c1.5,1,3,1.9,4.6,2.8c3.4,2,9.7,4.8,14,5.9c15.7,3.9,66,11,114,11"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M27.8,153.5l10,22.1c0,0,0,0,0.1,0.1c6.9,16.9,22.9,21.9,44.3,9.1
        c0.6-0.3,1.1-0.8,1.6-1.2c0,0,29-21,77-22c0,0,25-2,53,0c48,0,76,21,76,21c0.5,0.4,1.1,0.9,1.6,1.2c21.4,12.8,37.4,7.8,44.3-9.1
        c0-0.1,0.1-0.1,0.1-0.1l10-22.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M158.8,161.7c0,0,1-35,2-42"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M214.8,161.7c0,0-1-35-2-42"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M172.8,160.7c0,0,0-50,4-100"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M200.8,160.7c0,0,0-50-4-100"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.9" y1="182.9" x2="93.8" y2="138.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="289.8" y1="182.9" x2="280.9" y2="138.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M166.8,59.7c0-10.5,8.7-19,19.5-19s19.5,8.5,19.5,19"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M31.3,197.4l2.5-65.8l3-45c0,0,1.7-16,6.3-26c0,0,4.7-12,28.7-20
          c0,0,41-13,71-16c0,0,22-3.1,44-3.5"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M341.8,197.4l-2.5-65.8l-3-45c0,0-1.6-15.4-6.1-25.5
          c-0.1-0.3-0.3-0.6-0.4-1c-0.9-2.6-6.4-16.3-28-23.6c0,0-28-9-67-13c0,0-26-3-48.5-2.5"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M80.4,38.1c0,0,17.4-0.4,20.4,7.6c0,0,6,6.3,0,44.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M292.5,34.1c0,0-17.4-0.4-20.4,7.6c0,0-6,6.3,0,44.6"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M88.8,35.7c0,0,24,1,30.9,0c0,0,43.1-7,65.1-7"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M282.8,31.7c0,0-25.1,2-32,1c0,0-45-4-67-4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M104.8,31.7c0,0,18,0,24.9-0.9c7.4-0.9,40.1-5.1,56.1-6"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M269.8,28.7c0,0-20.1,0.9-27,0c-7.4-0.9-38-4-57-3.9"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M88.8,58.7c-2,10-11,17-22,18
          c-9,0.8-16-6-15-16c0.8-8,8-15.9,20.5-17C83.8,42.7,90.5,50.3,88.8,58.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M93,60.1c-2.2,11.6-11.3,18.5-23.2,21.5
          c-12,3-26-5.5-21-22c3-10,12.1-16.7,26-18C87.6,40.5,94.9,50.4,93,60.1z"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M283.3,53.7c2,10,11,17,22,18c9,0.8,16-6,15-16
          c-0.8-8-8-15.9-20.5-17C288.3,37.7,281.6,45.3,283.3,53.7z"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M279.1,55.1c2.2,11.6,11.3,18.5,23.2,21.5
          c12,3,26-5.5,21-22c-3-10-12.1-16.7-26-18C284.5,35.5,277.2,45.4,279.1,55.1z"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M186.8,181.5c0,0,51-1.8,101,19.2
          c0,0,14,5,24,13c0,0,0.3,0.1,0.7,0.5c6.3,5.5,11.7,11.5,12.3,18.9c0,0.4,0,0.8,0,1.2v30.4l1,100V466l-1,58.6l-4,76
          c0,0-1.6,23.6-10.7,34.8c-1.4,1.8-3.2,3.2-5.2,4.3c-5.9,3.2-24.1,12.4-43,14.9c0,0-49,6-71,7"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M193.8,181.5c0,0-51-1.8-101,19.2
          c0,0-14,5-24,13c0,0-0.3,0.1-0.7,0.5c-6.3,5.5-15.3,11.5-16.3,19.5c-0.5,4,0,3.6-0.5,18.6l-0.5,34.4v80l4,99.4l2,58.6l5,76
          c0,0,0.7,4.1,1.6,9.9c4.4,28.1,17.3,34.5,35.5,40.9c6.1,2.1,13.4,4,21.9,5.3c0,0,43,6,71,5"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M186.8,185.5c0,0,50.1-1.8,99.2,19.2
          c0,0,13.7,5,23.6,13c0,0,0.3,0.1,0.7,0.5c6.2,5.5,11.5,11.5,12,18.9c0,0.4,0,0.8,0,1.2v30.4l1,100V470l-1,58.6l-4.5,76
          c0,0-2,29-17,35c0,0-20.4,10-43,13c0,0-42,6-67,6"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M193.7,185.5c0,0-50.1-1.8-99.2,19.2
          c0,0-13.2,4.8-23,12.5c-0.3,0.2-0.6,0.5-0.9,0.7c-0.1,0.1-0.2,0.1-0.3,0.2c-7.5,5.5-14.5,9.5-15.5,17.5c-0.5,4-0.5,5.6-1,20.6
          l-0.5,34.4l0.5,80l3.4,99.4l2.6,58.6l6,76c0,0,0.1,11.4,5,22c7,15,10,13,18,18s23.1,8.4,40,11c0,0,34.5,4,62,3"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M186.8,263.5c0,0,56-1.8,101,25.2l17.9-42.1
          c2.1-4.9,3-10.3,2.4-15.7c-0.5-4.2-1.9-8.6-5.3-11.2c0,0-37-27-116-27"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M188.3,263.5c0,0-49.5-1.8-99.5,28.2
          l-19.4-45.1c-2.1-4.9-2.2-9.5-1.6-14.9c0.5-4.2,3.6-7.4,7-10c0,0,34.5-29,113.5-29"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M188.3,268.3c0,0,63.6-0.6,97,23.4
          c0,0,8.4,4,9.4,25v242.2c0,0,2.2,27.9-13.9,42.4c0,0-30.1,15.5-92.1,17.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M189.9,268.3c0,0-61.7,0.4-95.1,24.4
          c0,0-10,6-11.4,24l2.4,229c0,0,0,38,13,54c0,0,22.4,18.1,90,19"/>
      </g>
      <g>
        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="83.9" y1="312.6" x2="53.9" y2="253.3"/>
        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="59.2" y1="226.8" x2="90.4" y2="296.9"/>
      </g>
      <g>
        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="294.8" y1="309.7" x2="322.8" y2="246.7"/>
        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="316.8" y1="224.7" x2="287.9" y2="293.9"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M188.3,594.3c0,0,23.5,0.4,58.5-2.6
          c0,0,25-3,31,6l17.1,42.6l1.9,6.4c0,0,4,18-12,24c0,0-49,8-96,8"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M195.2,594.3c0,0-23.5,0.4-58.5-2.6
          c0,0-25-3-31,6l-17.1,42.6l-1.9,6.4c0,0-4,18,12,24c0,0,49,8,96,8"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M114.8,610.7c0,0,64,26,154-1L278,629
        c1,2.2,1.7,4.5,1.8,6.9c0,0.1,0,0.1,0,0.2c0.1,3.5-2.2,6.6-5.6,7.6c-9.9,2.9-39,10.8-63.5,9.9c0,0-10-1-17-1c0,0-12,0-18.4,1.1
        c0,0-41.6-0.1-63.6-6.1c0,0-0.5-0.1-1.2-0.5c-5.3-2.3-7.6-8.5-5.4-13.8L114.8,610.7z"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M347.2,691.7"/>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M187.3,612.7h33.1c0.8,0,1.3-0.9,0.9-1.6
          c-0.7-1.6-2.8-3.8-8.6-4.5h-24.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M192.5,612.7h-33.1c-0.8,0-1.3-0.9-0.9-1.6
          c0.7-1.6,2.8-3.8,8.6-4.5H192"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M130.6,674.8c0,0,58.7-7.4,124.4-0.8"/>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M38,524.3c0,0,0.8,100.4,7.8,126.4
          c0,0,14,22,54,21c0,0,0,7,12,11c0,0,9,3,81,5"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M343.8,524.3c0,0-0.8,100.4-7.8,126.4
          c0,0-13.2,16-51.2,21c0,0-2,7-14,9c0,0-1,2-78,7"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M336.8,648.3c0,0,5.1,0.9,11.1-6.4"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M45.8,649.7c0,0-6,0-10-6.7"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M59.2,659.2l2.3-8.1c0.4-1.6,1.4-3,2.8-3.8
        c0,0,0,0,0.1,0c1.2-0.7,2.6-0.6,3.7,0.2c2.8,1.8,9.3,6.6,15.2,14.5c0.1,0.1,0.2,0.2,0.3,0.3l4.7,5.6c0.6,0.7,0.1,1.7-0.8,1.7
        c-4.9-0.3-16.8-1.8-27.4-8.1C59.3,661,59,660.1,59.2,659.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M326.5,655.2l-2.3-8.1c-0.4-1.6-1.4-3-2.8-3.8
        c0,0,0,0-0.1,0c-1.2-0.7-2.6-0.6-3.7,0.2c-2.8,1.8-9.3,6.6-15.2,14.5c-0.1,0.1-0.2,0.2-0.3,0.3l-4.7,5.6c-0.6,0.7-0.1,1.7,0.8,1.7
        c4.9-0.3,16.8-1.8,27.4-8.1C326.4,657,326.7,656.1,326.5,655.2z"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M50.8,669.7c0,0,15-2,26,12"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M332.8,665.7c0,0-15-2-26,12"/>
      <g>
        <polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="84.8,449 56.4,446.1 38,465.7 		"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M88.8,573.7l-13.4,24.9
          c-0.6,1.1-1.6,1.9-2.8,2.1c-1.5,0.2-3.2-0.6-3.8-5c0,0-6.8-106-6.4-124.5l23.4-0.5"/>
      </g>
      <g>
        <polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="294.8,447.7 327.4,444.1 341.8,460.7 
              "/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M293.8,572.7l14.6,21.9
          c0.6,1.1,1.6,1.9,2.8,2.1c1.5,0.2,3.2-0.6,3.8-5c0,0,5.2-104.5,4.8-123h-24.5"/>
      </g>
      <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="45.3" cy="547.2" rx="4.5" ry="14.5"/>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M36.8,447.7c-3,0-6-8.3-6-18.5
          s2.5-18.5,5.5-18.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M36.8,443.2c3,0,5-5.7,5-12.5s-3-12-6-12"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M341.8,445.7c3,0,6-8.3,6-18.5
          s-2.5-18.5-5.5-18.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M341.8,441.2c-3,0-5-5.7-5-12.5s3-12,6-12"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M44,285.8c-1.7-3.6-6.1-11.4-12.2-12.1
          c0,0-14.6,0-23.6,18.2c-0.3,0.7-0.6,1.4-0.8,2.1c-0.5,2.3-1.3,7.3,1.2,10.5c0.6,0.8,1.5,1.3,2.5,1.3c2.4,0,7.7-0.5,15.6-4.1
          c0,0,12.3-6.2,16.7-11.7C44.4,288.7,44.6,287.1,44,285.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M10.1,305.5c0,0,11.7-12.9,33.7-18.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M34.8,274.7l7.9-7.7c0.6-0.6,1.6-0.5,2,0.2
          c1.9,3.5,4.7,11.6-0.9,19.5"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M336,285.8c1.7-3.6,6.1-11.4,12.2-12.1
          c0,0,14.6,0,23.6,18.2c0.3,0.7,0.6,1.4,0.8,2.1c0.5,2.3,1.3,7.3-1.2,10.5c-0.6,0.8-1.5,1.3-2.5,1.3c-2.4,0-7.7-0.5-15.6-4.1
          c0,0-12.3-6.2-16.7-11.7C335.6,288.7,335.4,287.1,336,285.8z"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M369.9,305.5c0,0-11.7-12.9-33.7-18.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M345.2,274.7l-7.9-7.7c-0.6-0.6-1.6-0.5-2,0.2
          c-1.9,3.5-4.7,11.6,0.9,19.5"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M51.4,246.7c0,0-4.6-14.5-16.6-18.7"/>
        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="40.9" y1="231.4" x2="40.9" y2="181.6"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M324.8,241.7c0,0,4.6-14.5,16.6-18.7"/>
        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="335.3" y1="226.4" x2="334.8" y2="178.2"/>
      </g>
      <g>
        <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="113.8" y1="177.7" x2="113.8" y2="189.7"/>
        <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="115.8" y1="177.2" x2="115.8" y2="189.2"/>
        <g>
          <g>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M153.5,178.4L95.2,197c-3,1-6.3-0.7-7.3-3.8
              l-0.7-2.3c-1-3,0.7-6.3,3.8-7.3l58.3-18.6c3-1,6.3,0.7,7.3,3.8l0.7,2.3C158.2,174.1,156.5,177.4,153.5,178.4z"/>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M151.9,177.6L96,195.4c-3,1-6.3-0.7-7.3-3.8
              l0,0c-1-3,0.7-6.3,3.8-7.3l55.9-17.8c3-1,6.3,0.7,7.3,3.8l0,0C156.6,173.3,155,176.6,151.9,177.6z"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="91.8" y1="184.7" x2="91.8" y2="195.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="93.8" y1="184.2" x2="93.8" y2="195.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="95.8" y1="183.2" x2="95.8" y2="195.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="97.8" y1="182.7" x2="97.8" y2="194.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="99.8" y1="182.2" x2="99.8" y2="194.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="101.8" y1="181.7" x2="101.8" y2="193.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="103.8" y1="180.7" x2="103.8" y2="192.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="105.8" y1="180.2" x2="105.8" y2="192.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="107.8" y1="179.7" x2="107.8" y2="191.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="109.8" y1="178.7" x2="109.8" y2="191.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="111.8" y1="178.2" x2="111.8" y2="190.2"/>
          </g>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="117.8" y1="176.2" x2="117.8" y2="188.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="119.8" y1="175.7" x2="119.8" y2="187.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="121.8" y1="175.2" x2="121.8" y2="187.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="123.8" y1="174.2" x2="123.8" y2="186.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="125.8" y1="173.7" x2="125.8" y2="185.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="127.8" y1="173.2" x2="127.8" y2="185.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="129.8" y1="172.2" x2="129.8" y2="184.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="131.8" y1="171.7" x2="131.8" y2="183.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="133.8" y1="171.2" x2="133.8" y2="183.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="135.8" y1="170.7" x2="135.8" y2="182.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="137.8" y1="170.2" x2="137.8" y2="182.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="139.8" y1="169.2" x2="139.8" y2="181.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="141.8" y1="168.7" x2="141.8" y2="180.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="143.8" y1="168.2" x2="143.8" y2="180.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="145.8" y1="167.2" x2="145.8" y2="179.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="147.8" y1="166.7" x2="147.8" y2="179.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="149.8" y1="166.7" x2="149.8" y2="178.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="153.8" y1="167.7" x2="153.8" y2="176.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="151.8" y1="166.7" x2="151.8" y2="177.7"/>
        </g>
      </g>
      <g>
        <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="260.7" y1="176.7" x2="260.7" y2="188.7"/>
        <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="258.7" y1="176.2" x2="258.7" y2="188.2"/>
        <g>
          <g>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M221,177.4l58.3,18.6c3,1,6.3-0.7,7.3-3.8
              l0.7-2.3c1-3-0.7-6.3-3.8-7.3L225.2,164c-3-1-6.3,0.7-7.3,3.8l-0.7,2.3C216.3,173.1,218,176.4,221,177.4z"/>
            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M222.6,176.6l55.9,17.8c3,1,6.3-0.7,7.3-3.8
              l0,0c1-3-0.7-6.3-3.8-7.3l-55.9-17.8c-3-1-6.3,0.7-7.3,3.8l0,0C217.8,172.3,219.5,175.6,222.6,176.6z"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="282.7" y1="183.7" x2="282.7" y2="194.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="280.7" y1="183.2" x2="280.7" y2="194.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="278.7" y1="182.2" x2="278.7" y2="194.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="276.7" y1="181.7" x2="276.7" y2="193.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="274.7" y1="181.2" x2="274.7" y2="193.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="272.7" y1="180.7" x2="272.7" y2="192.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="270.7" y1="179.7" x2="270.7" y2="191.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="268.7" y1="179.2" x2="268.7" y2="191.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="266.7" y1="178.7" x2="266.7" y2="190.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="264.7" y1="177.7" x2="264.7" y2="190.2"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="262.7" y1="177.2" x2="262.7" y2="189.2"/>
          </g>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="256.7" y1="175.2" x2="256.7" y2="187.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="254.7" y1="174.7" x2="254.7" y2="186.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="252.7" y1="174.2" x2="252.7" y2="186.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="250.7" y1="173.2" x2="250.7" y2="185.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="248.7" y1="172.7" x2="248.7" y2="184.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="246.7" y1="172.2" x2="246.7" y2="184.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="244.7" y1="171.2" x2="244.7" y2="183.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="242.7" y1="170.7" x2="242.7" y2="182.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="240.7" y1="170.2" x2="240.7" y2="182.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="238.7" y1="169.7" x2="238.7" y2="181.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="236.7" y1="169.2" x2="236.7" y2="181.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="234.7" y1="168.2" x2="234.7" y2="180.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="232.7" y1="167.7" x2="232.7" y2="179.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="230.7" y1="167.2" x2="230.7" y2="179.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="228.7" y1="166.2" x2="228.7" y2="178.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="226.7" y1="165.7" x2="226.7" y2="178.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="224.7" y1="165.7" x2="224.7" y2="177.2"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="220.7" y1="166.7" x2="220.7" y2="175.7"/>
          <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="222.7" y1="165.7" x2="222.7" y2="176.7"/>
        </g>
      </g>
    </g>
    <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M270,554.5l-155,3c-6.6,0-12-5.4-12-12l-11-65
      c0-6.6,5.4-12,12-12h174c6.6,0,12,5.4,12,12l-8,62C282,549.1,276.6,554.5,270,554.5z"/>
    <g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M241,323.4c0,0,30.9-2,44.4,19.5
          c0,0,6.4,14.3,0,34.9c0,0-4.2,14.6-2.3,21.6c0,0,5.7,14.5,3.8,22.2c0,0-3.3,8.8-13.6,10.2c0,0-10.7-0.5-17,5l-15.3-0.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M242.3,323.4c0,0-30.9-2-44.4,19.5
          c0,0-6.4,14.3,0,34.9c0,0,4.2,14.6,2.3,21.6c0,0-5.7,14.5-3.8,22.2c0,0,3.3,8.8,13.6,10.2c0,0,10.7-0.5,17,5l15.3-0.5"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M200.5,397.5c0,0,44.4,8,82.4-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M196,414.4c0,0,47,13,91,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M220.3,433.2c0,0-0.3-6.9,5.7-8.9h17.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M263.3,433.2c0,0,0.3-6.9-5.7-8.9h-17.3"/>
    </g>
    <g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M137,323.4c0,0,30.9-2,44.4,19.5
          c0,0,6.4,14.3,0,34.9c0,0-4.2,14.6-2.3,21.6c0,0,5.7,14.5,3.8,22.2c0,0-3.3,8.8-13.6,10.2c0,0-10.7-0.5-17,5l-15.3-0.5"/>
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M138.3,323.4c0,0-30.9-2-44.4,19.5
          c0,0-6.4,14.3,0,34.9c0,0,4.2,14.6,2.3,21.6c0,0-5.7,14.5-3.8,22.2c0,0,3.3,8.8,13.6,10.2c0,0,10.7-0.5,17,5l15.3-0.5"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M96.5,397.5c0,0,44.4,8,82.4-0.1"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M92,414.4c0,0,47,13,91,0"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M116.3,433.2c0,0-0.3-6.9,5.7-8.9h17.3"/>
      <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M159.3,433.2c0,0,0.3-6.9-5.7-8.9h-17.3"/>
    </g>
    <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="175.5" y1="468.5" x2="182.5" y2="556.5"/>
    <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="209.5" y1="468.5" x2="202.5" y2="556.5"/>
    </>),
    },
    exterior:{
        width:950,
        height:1246,
        svg:(<>
        <g id="Layer_1_1_">
        <g>
          <path fill="#FCFAFA" stroke="#070707" stroke-miterlimit="10" d="M54.9,908.5c5.6,7.2,10.7,30.7,10.7,30.7h3.7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="57.5" y1="786.1" x2="57.5" y2="798.1"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="54.9" y1="445.3" x2="52.4" y2="455.3"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M78.3,787.8l-27-2l-7-330l33-1.5c34.2,0,62-29.5,62-66
            s-27.8-66-62-66c-0.6,0-1.1,0-1.7,0l-14.3-1l-19-1.5v-16.5c0,0,5,0,5-1s2-24,2-24s2,0,4,0s1,3.5,1,3.5v3.5c1,1,4,2,4,2h5
            c3-1,9-5,9-5l1-6H95v-4h13.3c2,0,2,1,2,1l3,9h3l23,4.5c7,4.5,14,13.5,14,13.5c9,15,14,30,14,30l5,18l6,24l6,33l5,35l2,2l42,43
            l39,48c7,11,8,31,8,31l3,75l1,109l-2,82c-1,32-12,40.1-12,40.1l-1,2.9l-6,3l-22,18l-39,24c-6,9-13,12-13,12l-8,7c-2,1-3-1-3-1v-5
            c0,0-19,2-24,4c-4.6,1.9-9,3-9,3l-17,1l-7,10l-8,1c-2,3-7.2,2.8-7.2,2.8c-3.3-0.3-4.8-3.8-4.8-3.8l-7-1l-11-3c-5,0-8,3-8,3
            c-4,3-5,1.3-5,1.3v-6.3c0-4,2-9,2-9c2-4,2-9,2-9l0.3-6h1.8c36.1,0,65.4-29.5,65.4-65.8c0-36.4-29.3-65.8-65.4-65.8
            c-0.6,0-1.2,0-1.8,0"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M266.3,875.2c-1-19.3-1.7-115.6-1.7-115.6l-1.3-163.5
            c0-27,2.6-70.8,2.6-70.8"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M49.3,281.2l2,13c0.5,3.5,1.3,6.3,2.1,8.6c2.1,5.9,7,10.3,13,11.8
            c1.7,0.4,2.9,0.6,2.9,0.6l8,1c40,3,67,32,67,72c0,38-29,72-72,72"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="78.3" y1="788.2" x2="72.3" y2="454.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M62.3,287.2c0.5,4.9,1.2,8.9,1.8,11.6c1.1,4.7,5.2,7.4,10.2,8.4
            c0.2,0,0,0,0,0l7,1c45,5,73,35,73,80c0,44.5-30,80-82,82"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M73.3,931.2c2-2,9.7-6.6,13.9-7.4c35.1-7,59.1-34.7,59.1-70
            c0-39.5-27-66.6-68-71.6"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M82.3,945.2c-2-17,10.3-16.6,15.2-17.6
            c34.1-7.3,59.8-38.7,59.8-76.4c0-43.1-37-75-79-78"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="114.3" y1="335.7" x2="114.3" y2="281.7"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M113.8,293.2h20.5c3-1,4-7,4-7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="132.3" y1="292.7" x2="132.3" y2="285.7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="129.3" y1="293.7" x2="129.3" y2="284.7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="126.3" y1="293.7" x2="126.3" y2="283.7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="123.3" y1="293.7" x2="123.3" y2="283.7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="120.3" y1="293.7" x2="120.3" y2="282.7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="117.3" y1="292.7" x2="117.3" y2="282.7"/>
          
            <ellipse transform="matrix(0.5876 -0.8092 0.8092 0.5876 -196.1789 243.7201)" fill="none" stroke="#070707" stroke-width="1" stroke-miterlimit="10.002" cx="141" cy="314.3" rx="4.2" ry="25.9"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M188.4,904.2c-2.2,0.3-4.1,0.9-4.1,0.9l-10,4l-25,8
            c-9,3-9,6.2-9,6.2v2.1c0,0.6,0.1,1.2,0.5,1.7c0.1,0.2,0.2,0.3,0.3,0.4c0.5,0.5,1.1,0.8,1.8,0.7l12.4-1c8-1,21-7,21-7
            c7-3.9,11.6-7.8,13.3-9.3c0.4-0.4,0.7-1,0.7-1.6v-3.4C190.3,905,189.4,904.2,188.4,904.2z"/>
          
            <ellipse transform="matrix(0.9485 -0.3167 0.3167 0.9485 -282.2326 100.0304)" fill="none" stroke="#070707" stroke-width="1" stroke-miterlimit="9.999" cx="166.6" cy="918.2" rx="10.2" ry="4.1"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M79.3,280.8v15.4c3,4,5.4,4,5.4,4h3.6c1,0,3-3,3-3v-19
            C80.3,277.2,79.3,280.8,79.3,280.8z"/>
          
            <ellipse transform="matrix(0.9203 -0.3913 0.3913 0.9203 -109.5709 51.2522)" fill="none" stroke="#070707" stroke-width="1" stroke-miterlimit="10.0015" cx="71" cy="294.5" rx="5.4" ry="2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M101.3,949.2v-13c1-2,3.3-2,3.3-2h10V950"/>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="72.3" cy="853.7" rx="57" ry="57.5"/>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="71.3" cy="853.7" rx="41.1" ry="41.5"/>
          </g>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="69.3" cy="389.7" rx="57" ry="57.5"/>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="68.3" cy="389.7" rx="41.1" ry="41.5"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M200.9,920.1l-4.8-137.9l-6.8-163l-5-120l-1-31l-6-73
            c-4-39-14-73.8-14-73.8"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M184.3,500.5c0,0-0.1-8.4-9.1-13.4c-3.5-1.9-9.4-3-15.5-4
            c-18.5-2.9-29.7-3-44.6-1.8c-18.7,1.5-42.3,4-42.3,4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M76.9,708.5l71,12c4.7,0.7,9.4,1.2,14.2,1.1
            c14.3-0.2,19.2-4.4,19.2-4.4c10-7,11.6-11.9,11.6-11.9"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M120.3,932.9v14.3l1,2l7-10l6.4-8V907
            C128.1,914.8,120.3,932.9,120.3,932.9z"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M134.8,931.2h8.6c21-1.5,34.9-8.1,40.5-11.3c1.6-0.9,3.1-2,4.5-3.2
            l11.9-10.5"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="181.8" y1="450" x2="135.9" y2="409.6"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="171.4" y1="485.6" x2="169.2" y2="439"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="154.8" y1="462.3" x2="165.3" y2="462.3"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M158,475.1h3.5c0.7,0,1.5-0.2,2.1-0.6c1.1-0.7,1.7-1.4,1.7-2.3v-28
            c0-0.4-0.1-0.8-0.3-1.2c-0.6-1.5-1.8-2.8-3.3-3.6l-4.3-2.4c-0.9-0.5-2.1,0.1-2.3,1.1c-0.3,1.1-0.3,5.4-0.3,6.1v28
            C154.8,473.3,155.8,475.2,158,475.1z"/>
          <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="176.8" cy="805.7" r="12.5"/>
          <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="171.3" cy="686.7" rx="11" ry="12.5"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M171.2,706.2L171.2,706.2c1.7,0,3.1-1.4,3.1-3.1v-32.8
            c0-1.7-1.4-3.1-3.1-3.1l0,0c-1.7,0-3.1,1.4-3.1,3.1v32.8C168.1,704.8,169.5,706.2,171.2,706.2z"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="108.3" y1="315.2" x2="108.3" y2="272.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M95.3,276.2c0,7,13,6,13,6"/>
          <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="100.8" cy="290.2" rx="6.5" ry="2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M264.3,559.1l-75.6-69.7c-1.6-1.4-2.4-3.5-2.4-5.6
            c0.3-19.5,3-43.6,3-43.6"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M262.2,520.8c-1.1,9.5-1.9,30.4-1.9,30.4l-62-60c-9-8-9-20-9-20
            c0-10,2-29,2-29"/>
          <polyline fill="none" stroke="#070707" stroke-miterlimit="10" points="202.3,918.2 196.3,704.2 186.3,486.4 		"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="263.8" y1="569.4" x2="187.2" y2="505.4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M196,699.1l54.5,2.8c5.1,0.3,9.5-3.6,9.7-8.7c0-0.1,0-0.1,0-0.1
            l-1-112c0-0.8,0-1.5-0.1-2.3c-0.5-5.5-3-10.7-6.7-14.8l-5.9-6.5c-0.8-0.9-1.7-1.8-2.6-2.5l-56.5-44.8"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="263.8" y1="705.3" x2="196.2" y2="702"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M251.5,727.3h-42.3c-4.4,0-8.2,3.2-8.7,7.6c-0.3,2-0.1,3.6-0.1,3.6
            l2.9,111.9c0.1,4.5,3.6,8.1,8.1,8.2c2.3,0,4-0.4,4-0.4l8-4l32.5-21.1c1.1-0.7,1.9-1.8,2.1-3.1c0.3-1.7,0.3-3.4,0.3-3.4v-93.4
            C257.3,727.2,251.5,727.2,251.5,727.3z"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M262.6,876.8v-10.7c-1.8-2.1-4.3,0-4.3,0
            c-18,14-53.6,35.1-53.6,35.1v15.5"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M201.7,899.3c0,0,50.5-29.1,64-42.6"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M280.7,846.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M266.3,858.6c2,0.4,9.7-11.5,9.7-11.5c1.7-2.1,4.6,0,4.6,0"/>
          <path fill="#FCFAFA" stroke="#070707" stroke-miterlimit="10" d="M209.3,550.2c0-8-5.7-19.2-12-20c-8-1-11,11-11,19s4.6,10,11,10
            C203.6,559.2,209.3,558.2,209.3,550.2z"/>
        </g>
      </g>
      <g id="Layer_4">
        <g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M347.5,251.4"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M311.6,178.4v89.2c0,1.5,0.3,3.1,0.8,4.5c0.8,2.2,2.5,4.9,5.7,5.9
              c0.8,0.2,1.6,0.3,2.4,0.3h19.1c2.4,0,7-1,8-6v-21"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M305.5,150.4c0,0-1,17.2,6,64.1"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M583.5,249.4"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M616.5,174.9l-1,90.5c0,1.5,0.1,3.2,0,4.7c0,2.3,0.5,4.9-2.7,5.9
              c-0.8,0.2-1.6,0.3-2.4,0.3h-19.1c-2.4,0-5.9,1-7.9-5v-21"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M620.5,150.4c0,0,3,19-4,64"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M356,31.4v4.1c0,0-18.5,31.9-26.5,72.9l-1,3c0,0-10,4-11,11l-2,7
            c0,0-1,6-3,8c0,0-4,3-7,13l7,32l8,35l5,18c0,0,4,12,17,15l3.8,0.8c0.8,0.2,1.6,0.3,2.4,0.3l22.8-0.1c0,0,0-2,2-3H465"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M569.5,31.4v4c0,0,22,32,30,73l2,3c0,0,8,4,9,11l1,7c0,0,1,3,3,6
            c0,0,2.3,4.1,4.9,11.7c0.7,2.1,0.8,4.3,0.4,6.5l-5.3,28.8l-7,37l-4.6,16c-0.3,0.8-0.7,1.6-1.2,2.3c-2,3-8.9,10.4-19.2,12.8l-4,1
            c-0.8,0.2-3,0-2.2,0.1l-16.8-0.1c0,0,0-2-2-3H464"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M457,29.1c34-0.3,73.3,0.1,112.5,2.3c0,0-5.9-12.8-29.6-15.9
              c-0.3,0-0.6-0.1-0.8-0.1c-4.8-0.4-48.6-3-81.6-3"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M468.5,29.1c-34-0.3-73.3,0.1-112.5,2.3c0,0,5.9-12.8,29.6-15.9
              c0.3,0,0.6-0.1,0.8-0.1c4.8-0.4,48.6-3,81.6-3"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M465,101.4l-117.5,1c-3,0-4.5-2.3-5-5.3c-0.4-2.7-0.1-5.5,0.9-8.1
              l18.1-48.6c2-4,3-6,7-7l98.5-1"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M459,101.4l117.5,1c3,0,4.5-2.3,5-5.3c0.4-2.7,0.1-5.5-0.9-8.1
              l-18.1-48.6c-2-4-3-6-7-7l-98.5-1"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" d="M466.2,192.4h-57c-12.1,0-18.5-3.4-21.2-8.1
              c-1.6-2.7-1.3-6.1,0.4-8.8l1.4-2.1c0.5-0.7,1-1.4,1.6-2.1c0.1-0.1,0.1-0.2,0.2-0.2c6.6-7.4,16-11.7,26-11.9l49.4-0.9"/>
            <path fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" d="M466,192.4h57c12.1,0,18.5-3.4,21.2-8.1
              c1.6-2.7,1.3-6.1-0.4-8.8l-1.4-2.1c-0.5-0.7-1-1.4-1.6-2.1c-0.1-0.1-0.1-0.2-0.2-0.2c-6.6-7.4-16-11.7-26-11.9l-48.1-0.9"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M466.1,196.9h-61.2c-12.9,0-19.9-4.4-22.8-10.5
              c-1.7-3.5-1.4-8,0.4-11.3l1.5-2.7c0.5-0.9,1.1-1.9,1.7-2.7c0.1-0.1,0.1-0.2,0.2-0.3c7.1-9.6,17.2-15.2,27.9-15.4l53.1-1.1"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M465.9,196.9h61.2c12.9,0,19.9-4.4,22.8-10.5
              c1.7-3.5,1.4-8-0.4-11.3l-1.5-2.7c-0.5-0.9-1.1-1.9-1.7-2.7c-0.1-0.1-0.1-0.2-0.2-0.3c-7.1-9.6-17.2-15.2-27.9-15.4l-51.7-1.1"/>
          </g>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="409" y1="160.4" x2="523" y2="160.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="403.5" y1="162.4" x2="529" y2="162.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="400" y1="164.4" x2="532.5" y2="164.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="397" y1="166.4" x2="535.5" y2="166.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="394.5" y1="168.4" x2="538" y2="168.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="392.5" y1="170.4" x2="540" y2="170.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="390.5" y1="172.4" x2="541.5" y2="172.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="389" y1="174.4" x2="543" y2="174.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="388" y1="176.4" x2="544.5" y2="176.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="387" y1="178.4" x2="545" y2="178.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="387" y1="180.4" x2="545.5" y2="180.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="387" y1="182.4" x2="545" y2="182.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="388" y1="184.4" x2="544" y2="184.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="389.5" y1="186.4" x2="542.5" y2="186.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="392" y1="188.4" x2="540.5" y2="188.4"/>
          <line fill="none" stroke="#070707" stroke-width="0.5" stroke-miterlimit="10" x1="396" y1="190.4" x2="536.5" y2="190.4"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="311.5" y1="177.4" x2="381.5" y2="177.4"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="549.5" y1="175.4" x2="615.5" y2="175.4"/>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="349.5" cy="150.9" rx="19.5" ry="21"/>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="349.5" cy="150.9" rx="22.5" ry="24"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M339,115.9c0,0,10,2,24,16"/>
          </g>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="579" cy="148.4" rx="19.5" ry="21"/>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="579" cy="148.4" rx="22.5" ry="24"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M589.5,113.4c0,0-10,2-24,16"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M369,119.3c0,0,23.5,17.2,5.7,47.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M555.7,120.5c0,0-19.7,21.4,3.6,47.4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M440.5,114.4c0,0,5.9,5,6.9,13"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M488.4,114.4c0,0-5.9,5-6.9,13"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M465,107c0,0,8.5-0.6,17.5,1.4c0,0,7,2,30,2l51,2c0,0,12,1,21-1
            c0,0,20-3,27,17"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M465.5,107c0,0-8.7-0.6-17.9,1.4c0,0-7.1,2-30.6,2l-52,2
            c0,0-12.2,1-21.4-1c0,0-20.4-3-27.6,17"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M614,134.7L602.5,197l-4,16.3c0,0-3.6,12-16.8,13.1l-46.2,2.4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M312.5,137.4l14.3,63.1l3.7,15.2c0.2,0.9,0.5,1.8,1,2.6l0,0
            c3.3,6.1,9.7,10,16.7,10.2l48.3,1.9"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M468.5,219.4h-57c-4.2,0-14,3-15,9v4c0,5,4,6,8,6h62.9"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M463.5,219.4h57c4.2,0,14,3,15,9v4c0,5-4,6-8,6h-62.9"/>
          </g>
          <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="354.5" cy="192.4" r="7"/>
          <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="575.5" cy="190.4" r="7"/>
          <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="573.5" cy="219.4" r="4"/>
          <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="358.5" cy="221.4" r="4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M338.5,201.4h63.9c0,0,0,0,0.1,0c2.2,0.1,3.9,2,3.9,4.2v4.6
            c0,1.1-0.3,2.2-0.9,3.1c-0.5,0.7-1.2,1.3-2.1,1.2H350c0,0-9.6-0.1-13.6-6.1v-4.7c0-1,0.7-2,1.7-2.2
            C338.3,201.4,338.4,201.4,338.5,201.4z"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M592.5,199.4h-63.9c0,0,0,0-0.1,0c-2.2,0.1-3.9,2-3.9,4.2v4.6
            c0,1.1,0.3,2.2,0.9,3.1c0.5,0.7,1.2,1.3,2.1,1.2H581c0,0,9.6-0.1,13.6-6.1v-4.7c0-1-0.7-2-1.7-2.2
            C592.7,199.4,592.6,199.4,592.5,199.4z"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M322.7,225.4c0,0,5.8,13.2,26.8,14.6l117.5,1.3"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M322.5,225.4c0,0,6,17.4,36,18.2l108.5,0.8"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M605.8,225.4c0,0-5.8,13.2-26.8,14.6l-117.5,1.3"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M606,225.4c0,0-6,17.4-36,18.2l-108.5,0.8"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M581.5,226.3c0,0,0,11.2-4,25.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M349.5,226.3c0,0,0,11.2,4,25.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M317,124.4"/>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="301.5" cy="98.9" rx="19" ry="12.5"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M317,124.4c-3.5-4-8.5-15.5-8.5-18.5c0-2.5,1-5.5,6-5.5
              c2,0,8,12,8,15"/>
          </g>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="624.5" cy="94.9" rx="19" ry="12.5"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M609.5,119.4c3.5-4,8-14.5,8-17.5c0-2.5-1-5.5-6-5.5
              c-2,0-9,13-9,16"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M514.6,217.4h-97.2c-1.6,0-2.9-1.3-2.9-2.9v-13.2
            c0-1.6,1.3-2.9,2.9-2.9h97.2c1.6,0,2.9,1.3,2.9,2.9v13.2C517.5,216.1,516.2,217.4,514.6,217.4z"/>
        </g>
      </g>
      <g id="Layer_3">
        <g>
          <path fill="#FCFAFA" stroke="#070707" stroke-miterlimit="10" d="M887.3,914.7c-5.6,7.2-10.7,30.7-10.7,30.7h-3.7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="884.7" y1="792.4" x2="884.7" y2="804.4"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="887.2" y1="451.6" x2="889.8" y2="461.6"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M863.9,794.1l27-2l7-330l-33-1.5c-34.2,0-62-29.5-62-66
            s27.8-66,62-66c0.6,0,1.1,0,1.7,0l14.3-1l19-1.5v-16.5c0,0-5,0-5-1s-2-24-2-24s-2,0-4,0s-1,3.5-1,3.5v3.5c-1,1-4,2-4,2h-5
            c-3-1-9-5-9-5l-1-6h-21.7v-4h-13.3c-2,0-2,1-2,1l-3,9h-3l-23,4.5c-7,4.5-14,13.5-14,13.5c-9,15-14,30-14,30l-5,18l-6,24l-6,33
            l-5,35l-2,2l-42,43l-39,48c-7,11-8,31-8,31l-3,75l-1,109l2,82c1,32,12,40.1,12,40.1l1,2.9l6,3l22,18l39,24c6,9,13,12,13,12l8,7
            c2,1,3-1,3-1v-5c0,0,19,2,24,4c4.6,1.9,9,3,9,3l17,1l7,10l8,1c2,3,7.2,2.8,7.2,2.8c3.3-0.3,4.8-3.8,4.8-3.8l7-1l11-3c5,0,8,3,8,3
            c4,3,5,1.3,5,1.3v-6.3c0-4-2-9-2-9c-2-4-2-9-2-9l-0.3-6h-1.8c-36.1,0-65.4-29.5-65.4-65.8c0-36.4,29.3-65.8,65.4-65.8
            c0.6,0,1.2,0,1.8,0"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M675.8,881.4c1-19.3,1.7-115.6,1.7-115.6l1.3-163.4
            c0-27-2.6-70.8-2.6-70.8"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M892.9,287.4l-2,13c-0.5,3.5-1.3,6.3-2.1,8.6
            c-2.1,5.9-7,10.3-13,11.8c-1.7,0.4-2.9,0.6-2.9,0.6l-8,1c-40,3-67,32-67,72c0,38,29,72,72,72"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="863.9" y1="794.4" x2="869.9" y2="460.4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M879.9,293.4c-0.5,4.9-1.2,8.9-1.8,11.6c-1.1,4.7-5.2,7.4-10.2,8.4
            c-0.2,0,0,0,0,0l-7,1c-45,5-73,35-73,80c0,44.5,30,80,82,82"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M868.9,937.4c-2-2-9.7-6.6-13.9-7.4c-35.1-7-59.1-34.7-59.1-70
            c0-39.5,27-66.6,68-71.6"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M859.9,951.4c2-17-10.3-16.6-15.2-17.6
            c-34.1-7.3-59.8-38.7-59.8-76.4c0-43.1,37-75,79-78"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="827.9" y1="341.9" x2="827.9" y2="287.9"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M828.4,299.4h-20.5c-3-1-4-7-4-7"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="809.9" y1="298.9" x2="809.9" y2="291.9"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="812.9" y1="299.9" x2="812.9" y2="290.9"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="815.9" y1="299.9" x2="815.9" y2="289.9"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="818.9" y1="299.9" x2="818.9" y2="289.9"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="821.9" y1="299.9" x2="821.9" y2="288.9"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="824.9" y1="298.9" x2="824.9" y2="288.9"/>
          
            <ellipse transform="matrix(0.8092 -0.5876 0.5876 0.8092 -35.4118 531.7941)" fill="none" stroke="#070707" stroke-width="1" stroke-miterlimit="10.002" cx="801" cy="320.4" rx="25.9" ry="4.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M753.8,910.5c2.2,0.3,4.1,0.9,4.1,0.9l10,4l25,8c9,3,9,6.2,9,6.2
            v2.1c0,0.6-0.1,1.2-0.5,1.7c-0.1,0.2-0.2,0.3-0.3,0.4c-0.5,0.5-1.1,0.8-1.8,0.7l-12.4-1c-8-1-21-7-21-7c-7-3.9-11.6-7.8-13.3-9.3
            c-0.4-0.4-0.7-1-0.7-1.6v-3.4C751.9,911.2,752.7,910.4,753.8,910.5z"/>
          
            <ellipse transform="matrix(0.3167 -0.9485 0.9485 0.3167 -346.588 1367.434)" fill="none" stroke="#070707" stroke-width="1" stroke-miterlimit="9.999" cx="775.8" cy="924.3" rx="4.1" ry="10.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M862.9,287v15.4c-3,4-5.4,4-5.4,4h-3.6c-1,0-3-3-3-3v-19
            C861.9,283.4,862.9,287.1,862.9,287z"/>
          
            <ellipse transform="matrix(0.3913 -0.9203 0.9203 0.3913 253.4707 984.8232)" fill="none" stroke="#070707" stroke-width="1" stroke-miterlimit="10.0015" cx="871.2" cy="300.8" rx="2" ry="5.4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M840.9,955.5v-13c-1-2-3.3-2-3.3-2h-10v15.8"/>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="869.9" cy="859.9" rx="57" ry="57.5"/>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="870.9" cy="859.9" rx="41.1" ry="41.5"/>
          </g>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="872.9" cy="395.9" rx="57" ry="57.5"/>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="873.9" cy="395.9" rx="41.1" ry="41.5"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M741.3,926.4l4.8-137.9l6.8-163l5-120l1-31l6-73
            c4-39,14-73.8,14-73.8"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M757.8,506.8c0,0,0.1-8.4,9.1-13.4c3.5-1.9,9.4-3,15.5-4
            c18.5-2.9,29.7-3,44.6-1.8c18.7,1.5,42.3,4,42.3,4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M865.3,714.8l-71,12c-4.7,0.7-9.4,1.2-14.2,1.1
            c-14.3-0.2-19.2-4.4-19.2-4.4c-10-7-11.6-11.9-11.6-11.9"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M821.8,939.1v14.3l-1,2l-7-10l-6.4-8v-24.2
            C814,921,821.9,939.1,821.8,939.1z"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M807.4,937.4h-8.6c-21-1.5-34.9-8.1-40.5-11.3
            c-1.6-0.9-3.1-2-4.5-3.2l-11.9-10.5"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="760.4" y1="456.3" x2="806.2" y2="415.9"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="770.7" y1="491.8" x2="772.9" y2="445.2"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="787.4" y1="468.5" x2="776.9" y2="468.5"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M784.2,481.3h-3.5c-0.7,0-1.5-0.2-2.1-0.6c-1.1-0.7-1.7-1.4-1.7-2.3
            v-28c0-0.4,0.1-0.8,0.3-1.2c0.6-1.5,1.8-2.8,3.3-3.6l4.3-2.4c0.9-0.5,2.1,0.1,2.3,1.1c0.3,1.1,0.3,5.4,0.3,6.1v28
            C787.4,479.5,786.3,481.4,784.2,481.3z"/>
          <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="765.4" cy="811.9" r="12.5"/>
          <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="770.9" cy="692.9" rx="11" ry="12.5"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M770.9,712.4L770.9,712.4c-1.7,0-3.1-1.4-3.1-3.1v-32.8
            c0-1.7,1.4-3.1,3.1-3.1l0,0c1.7,0,3.1,1.4,3.1,3.1v32.8C774,711,772.7,712.4,770.9,712.4z"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="833.9" y1="321.4" x2="833.9" y2="278.4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M846.9,282.4c0,7-13,6-13,6"/>
          <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="841.4" cy="296.4" rx="6.5" ry="2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M677.9,565.3l75.6-69.7c1.6-1.4,2.4-3.5,2.4-5.6
            c-0.3-19.5-3-43.6-3-43.6"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M680,527c1.1,9.5,1.9,30.4,1.9,30.4l62-60c9-8,9-20,9-20
            c0-10-2-29-2-29"/>
          <polyline fill="none" stroke="#070707" stroke-miterlimit="10" points="739.9,924.4 745.9,710.4 755.8,492.6 		"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="678.3" y1="575.7" x2="755" y2="511.7"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M746.1,705.4l-54.5,2.8c-5.1,0.3-9.5-3.6-9.7-8.7c0-0.1,0-0.1,0-0.1
            l1-112c0-0.8,0-1.5,0.1-2.3c0.5-5.5,3-10.7,6.7-14.8l5.9-6.5c0.8-0.9,1.7-1.8,2.6-2.5l56.5-44.8"/>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="678.3" y1="711.6" x2="746" y2="708.3"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M690.6,733.5H733c4.4,0,8.2,3.2,8.7,7.6c0.3,2,0.1,3.6,0.1,3.6
            l-2.9,111.9c-0.1,4.5-3.6,8.1-8.1,8.2c-2.3,0-4-0.4-4-0.4l-8-4l-32.5-21.1c-1.1-0.7-1.9-1.8-2.1-3.1c-0.3-1.7-0.3-3.4-0.3-3.4
            v-93.4C684.9,733.4,690.7,733.4,690.6,733.5z"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M679.5,883v-10.7c1.8-2.1,4.3,0,4.3,0c18,14,53.6,35.1,53.6,35.1
            v15.5"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M740.4,905.5c0,0-50.5-29.1-64-42.6"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M661.4,852.4"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M675.9,864.8c-2,0.4-9.7-11.5-9.7-11.5c-1.7-2.1-4.6,0-4.6,0"/>
          <path fill="#FCFAFA" stroke="#070707" stroke-miterlimit="10" d="M732.9,556.4c0-8,5.7-19.2,12-20c8-1,11,11,11,19s-4.6,10-11,10
            C738.5,565.4,732.9,564.4,732.9,556.4z"/>
        </g>
      </g>
      <g id="Layer_2_1_">
        <g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M355.3,984.2c0,0-13.4,26.2-26.2,67c-0.4,1.2-1.2,2.3-2.2,3.1
              c-2.7,2-8.2,6.2-10.4,9.9c0,0-5,8-6,28l-2.1,2.5c-3.2,3.8-5,8.6-5,13.5c0,3.9,0.6,9.1,2.1,16l7.4,35.3c0.8,3.9,2.4,7.6,4.9,10.8
              c2.1,2.7,5.1,5.3,9.3,6.9c2,0.7,4.1,1.1,6.3,1.1l27,0.9h103.5"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M566.6,984.2c0,0,13.4,26.2,26.2,67c0.4,1.2,1.2,2.3,2.2,3.1
              c2.7,2,8.2,6.2,10.4,9.9c0,0,5,8,6,28l2.1,2.5c3.2,3.8,5,8.6,5,13.5c0,3.9-0.6,9.1-2.1,16l-7.4,35.3c-0.8,3.9-2.4,7.6-4.9,10.8
              c-2.1,2.7-5.1,5.3-9.3,6.9c-2,0.7-4.1,1.1-6.3,1.1l-27,0.9H457.9"/>
            <circle fill="#F9F9F9" stroke="#070707" stroke-miterlimit="10" cx="538.4" cy="1177.2" r="7"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M458.4,984.2H567c0,0-0.6-3-4.6-8c0,0-7-10-36-10l-68-1.4"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M463.9,984.2H355.3c0,0,0.6-3,4.6-8c0,0,7-10,36-10l68-1.4"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M329.4,1050.2c0,0,129-7,262-3"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M357.4,1049.2c0,0,2.4-24,16.7-65"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M359.7,1048.8c0,0,5.7-38.6,16.4-64.6"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M564.4,1046.2c0,0-1.7-21-16-62"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M562.4,1046.2c0,0-5.2-36-16-62"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M357,1049.2c0,0-1.4,10.1,0,15.6l13.4,50.4c0,0,0,0,0,0.1
            c1.5,3.6,5.1,5.9,9,5.9h92.5"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M564.4,1046.2c0,0,2.6,4.4,1,19l-12,50c0,0,0,0,0,0.1
            c-1.5,3.6-5.1,5.9-9,5.9h-92.5"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M305.4,1124.2c0,0,1.4-1.7,3.4-3.4c2.8-2.3,6.3-3.6,9.9-3.6h53.1"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M616.4,1124.2c0,0-1.4-1.7-3.4-3.4c-2.8-2.3-6.3-3.6-9.9-3.6H552"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M309.8,1092.9l8.1,31.3l4.5,19.5c1.2,5.3,3.7,10.3,7.4,14.4
            c2.6,2.9,6.2,5.6,10.7,7.1c0,0,6,3,27.5,3.4h96"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M611.4,1092.2l-4.6,32l-4.5,19.5c-1.2,5.3-3.7,10.3-7.4,14.4
            c-2.6,2.9-6.2,5.6-10.7,7.1c0,0-6,3-27.5,3.4h-96"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M318.4,1125.2c0,0,9,5.1,38,5.5c35,0.5,115,0.5,115,0.5"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M606.4,1125.2c0,0-9,5.1-38,5.5c-35,0.5-115,0.5-115,0.5"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M461.9,1150.2H345.3c-3,0-6-0.9-8.3-2.8c-1.5-1.2-2.5-2.4-2.5-3.2
              v-3.5c0-2.5,2-4.5,4.5-4.5h123"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M461.9,1150.2h117.8c3,0,6-0.9,8.3-2.8c1.5-1.2,2.5-2.4,2.5-3.2
              v-3.5c0-2.5-2-4.5-4.5-4.5H460.9"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M472.8,1164.2H457c-2,0-3.6-1.6-3.6-3.6v-2.8c0-2,1.6-3.6,3.6-3.6
            h15.8c2,0,3.6,1.6,3.6,3.6v2.8C476.4,1162.6,474.8,1164.2,472.8,1164.2z"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M342.4,1061.2c0,0,3-0.1,4.9,0.9c2.1,1.1,3,2.8,3.5,4.8l7.6,29.3
              c0,0,1.6,5.8,0.1,10.2c-0.8,2.3-2.9,3.8-5.4,3.8h-17.8c0,0-0.2,0-0.5-0.1c-3.8-1.6-5.1-3.3-5.4-8c-0.1-1.5-0.1-3.1,0.1-4.6
              l1.9-24.2C331.4,1073.2,332.4,1061.2,342.4,1061.2z"/>
            <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="331.4" y1="1077.2" x2="353.4" y2="1077.2"/>
            <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="329.4" y1="1096.2" x2="358.4" y2="1096.2"/>
            <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="342.9" cy="1086.7" r="7.5"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M580.2,1061.2c0,0-3-0.1-4.9,0.9c-2.1,1.1-3,2.8-3.5,4.8l-7.6,29.3
              c0,0-1.6,5.8-0.1,10.2c0.8,2.3,2.9,3.8,5.4,3.8h17.8c0,0,0.2,0,0.5-0.1c3.8-1.6,5.1-3.3,5.4-8c0.1-1.5,0.1-3.1-0.1-4.6l-1.9-24.2
              C591.2,1073.2,590.2,1061.2,580.2,1061.2z"/>
            <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="591.2" y1="1077.2" x2="569.2" y2="1077.2"/>
            <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="593.2" y1="1096.2" x2="564.2" y2="1096.2"/>
            <circle fill="none" stroke="#070707" stroke-miterlimit="10" cx="579.7" cy="1086.7" r="7.5"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M461.9,1063.4l34.8,0.7c4.7,0.1,9.4,0.8,13.8,2.2
              c3.3,1.1,6.8,2.6,9.8,4.9c0,0,5.3,5,3.2,15.5c0,0-0.1,0.8-0.5,2.1c-2.9,9.7-12,16.2-22,16.2h-38.9"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M462.1,1063.4l-34.8,0.7c-4.7,0.1-9.4,0.8-13.8,2.2
              c-3.3,1.1-6.8,2.6-9.8,4.9c0,0-5.3,5-3.2,15.5c0,0,0.1,0.8,0.5,2.1c2.9,9.7,12,16.2,22,16.2h39.9"/>
          </g>
          <line fill="none" stroke="#070707" stroke-miterlimit="10" x1="401.4" y1="1074.2" x2="522.4" y2="1074.2"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M510,1101.2h-94.2c-1.9,0-3.4-1.5-3.4-3.4v-18.2
            c0-1.9,1.5-3.4,3.4-3.4H510c1.9,0,3.4,1.5,3.4,3.4v18.2C513.4,1099.7,511.9,1101.2,510,1101.2z"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M548.1,1042.2l-173.3,1c-2.4,0-4.3-2-4.3-4.3l11-45.3
            c0-2.3,2-4.3,4.3-4.3h151.3c2.4,0,4.3,2,4.3,4.3l11,44.3C552.4,1040.2,550.5,1042.2,548.1,1042.2z"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M463.9,970.7c0,0-58.5,0.5-74.4,3.8c0,0-11.1,1.7-15.1,9.7"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M459.4,970.7c0,0,58.5,0.5,74.4,3.8c0,0,11.1,1.7,15.1,9.7"/>
          </g>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M459.4,981.3h34c0,0,0-2.3-6.8-4.8c-2.4-0.9-5-1.3-7.5-1.3h-19.7"
              />
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M463.4,981.3h-34c0,0,0-2.3,6.8-4.8c2.4-0.9,5-1.3,7.5-1.3h19.7"/>
          </g>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="298.4" cy="1050.2" rx="19" ry="12"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M313.5,1071.8c0,0-3.5-8.6-6.3-10.6c0,0,7.9-3.3,8.6-6.1
              c0.6-2.9,3.7,5.4,3.7,5.4S315.5,1062.4,313.5,1071.8z"/>
          </g>
          <g>
            <ellipse fill="none" stroke="#070707" stroke-miterlimit="10" cx="623.4" cy="1050.2" rx="19" ry="12"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M608.4,1071.8c0,0,3.5-8.6,6.3-10.6c0,0-7.9-3.3-8.6-6.1
              c-0.6-2.9-3.7,5.4-3.7,5.4S606.3,1062.4,608.4,1071.8z"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M310.1,1146.5v74.7c0,0.2,0,0.3,0,0.5c0.1,0.7,0.3,2.4,0.9,4.2
            c0.9,2.6,3.4,4.3,6.2,4.3h21.5c0,0,4.5,0.2,5.6-5.4v-46.1"/>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M612.3,1146.5v74.7c0,0.2,0,0.3,0,0.5c-0.1,0.7-0.3,2.4-0.9,4.2
            c-0.9,2.6-3.4,4.3-6.2,4.3h-21.5c0,0-4.5,0.2-5.6-5.4v-46.1"/>
          <g>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M344.3,1192.8h4.2c0,0,3.9,4.4,7.9,4.4h44.7v-4.4h26.3
              c0,0,0.4,4.4,4.2,4.4h33.3"/>
            <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M578.9,1192.8h-4.2c0,0-3.9,4.4-7.9,4.4h-44.7v-4.4h-26.3
              c0,0-0.4,4.4-4.2,4.4h-33.3"/>
          </g>
          <path fill="none" stroke="#070707" stroke-miterlimit="10" d="M610.3,1080.3l-0.8-5.5c0.3-0.4,1-0.6,2.3-0.6c2.5,0,7,1.6,7,3.5
            s-2.5,3.5-5,3.5C611.8,1081.2,610.8,1080.9,610.3,1080.3z"/>
        </g>
      </g>
      <g id="Layer_5">
        <g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M462.8,275.7c0,0,77,1,122,20c0,0,12.1,4.8,16.7,13.8
              c1.2,2.3,2.8,4.5,4.7,6.3c2.3,2.3,5.4,5.6,7.6,9.2c0,0,8,13.7,9,80.7V434c0,0.7,0,1.3-0.1,1.9c-0.3,3.2-1.3,13.5-3.5,23.8
              c-0.3,1.3-0.5,2.6-0.5,4l-0.9,24l2,147l-1,138.6c0,1.6,0.1,3.2,0.5,4.7c1,5.1,3.5,17.2,5.1,24c0.3,1.2,0.5,2.3,0.5,3.5
              c0.7,9.2,3.6,50,1.9,72.1l-2,28c0,0-0.9,14.1-10.1,20.6c-0.6,0.4-1.2,0.8-1.9,1.1l-16,8.6c-5.1,2.7-10.5,4.7-16,6
              c-15.8,3.6-67.5,13.5-117,12.8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M462.8,275.7c0,0-61,0-113,21c0,0-14.3,3.3-24.4,16.8
              c-1.2,1.6-2.4,3.1-3.8,4.5c-4.8,4.8-6.6,7.2-8.8,10.8c0,0-7,6-8,78v28.3c0,0.6,0,1.2,0.1,1.9c0.3,3.2-0.3,9.4,1.9,19.8
              c0.3,1.3,2.9,6.7,3,8l2,25v145l4,141c0,1.6,0.3,6.4,0,8c-1,5.1-2,7.5-3.6,14.4c-0.3,1.2-1.3,11.4-1.4,12.6c-0.7,9.2,0,50,0,72
              l2,23c0,0,1,13,5.1,19.6c0.4,0.6,3.3,3.1,3.9,3.4l9.4,6.3c1.5,1,3,1.9,4.6,2.8c3.4,2,9.7,4.8,14,5.9c15.7,3.9,66,11,114,11"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M304.8,416.5l10,22.1c0,0,0,0,0.1,0.1c6.9,16.9,22.9,21.9,44.3,9.1
            c0.6-0.3,1.1-0.8,1.6-1.2c0,0,29-21,77-22c0,0,25-2,53,0c48,0,76,21,76,21c0.5,0.4,1.1,0.9,1.6,1.2c21.4,12.8,37.4,7.8,44.3-9.1
            c0-0.1,0.1-0.1,0.1-0.1l10-22.1"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M435.8,424.7c0,0,1-35,2-42"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M491.8,424.7c0,0-1-35-2-42"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M449.8,423.7c0,0,0-50,4-100"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M477.8,423.7c0,0,0-50-4-100"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="361.9" y1="445.9" x2="370.8" y2="401.7"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="566.8" y1="445.9" x2="557.9" y2="401.7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M443.8,322.7c0-10.5,8.7-19,19.5-19s19.5,8.5,19.5,19"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M308.3,460.4l2.5-65.8l3-45c0,0,1.7-16,6.3-26c0,0,4.7-12,28.7-20
              c0,0,41-13,71-16c0,0,22-3.1,44-3.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,460.4l-2.5-65.8l-3-45c0,0-1.6-15.4-6.1-25.5
              c-0.1-0.3-0.3-0.6-0.4-1c-0.9-2.6-6.4-16.3-28-23.6c0,0-28-9-67-13c0,0-26-3-48.5-2.5"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M357.4,301.1c0,0,17.4-0.4,20.4,7.6c0,0,6,6.3,0,44.6"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M569.5,297.1c0,0-17.4-0.4-20.4,7.6c0,0-6,6.3,0,44.6"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.8,298.7c0,0,24,1,30.9,0c0,0,43.1-7,65.1-7"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M559.8,294.7c0,0-25.1,2-32,1c0,0-45-4-67-4"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M381.8,294.7c0,0,18,0,24.9-0.9c7.4-0.9,40.1-5.1,56.1-6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M546.8,291.7c0,0-20.1,0.9-27,0c-7.4-0.9-38-4-57-3.9"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.8,321.7c-2,10-11,17-22,18c-9,0.8-16-6-15-16
              c0.8-8,8-15.9,20.5-17C360.8,305.7,367.5,313.3,365.8,321.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M370,323.1c-2.2,11.6-11.3,18.5-23.2,21.5c-12,3-26-5.5-21-22
              c3-10,12.1-16.7,26-18C364.6,303.5,371.9,313.4,370,323.1z"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M560.3,316.7c2,10,11,17,22,18c9,0.8,16-6,15-16
              c-0.8-8-8-15.9-20.5-17C565.3,300.7,558.6,308.3,560.3,316.7z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M556.1,318.1c2.2,11.6,11.3,18.5,23.2,21.5c12,3,26-5.5,21-22
              c-3-10-12.1-16.7-26-18C561.5,298.5,554.2,308.4,556.1,318.1z"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M463.8,444.5c0,0,51-1.8,101,19.2c0,0,14,5,24,13
              c0,0,0.3,0.1,0.7,0.5c6.3,5.5,11.7,11.5,12.3,18.9c0,0.4,0,0.8,0,1.2v30.4l1,100V729l-1,58.6l-4,76c0,0-1.6,23.6-10.7,34.8
              c-1.4,1.8-3.2,3.2-5.2,4.3c-5.9,3.2-24.1,12.4-43,14.9c0,0-49,6-71,7"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M470.8,444.5c0,0-51-1.8-101,19.2c0,0-14,5-24,13
              c0,0-0.3,0.1-0.7,0.5c-6.3,5.5-15.3,11.5-16.3,19.5c-0.5,4,0,3.6-0.5,18.6l-0.5,34.4v80l4,99.4l2,58.6l5,76c0,0,0.7,4.1,1.6,9.9
              c4.4,28.1,17.3,34.5,35.5,40.9c6.1,2.1,13.4,4,21.9,5.3c0,0,43,6,71,5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M463.8,448.5c0,0,50.1-1.8,99.2,19.2c0,0,13.7,5,23.6,13
              c0,0,0.3,0.1,0.7,0.5c6.2,5.5,11.5,11.5,12,18.9c0,0.4,0,0.8,0,1.2v30.4l1,100V733l-1,58.6l-4.5,76c0,0-2,29-17,35
              c0,0-20.4,10-43,13c0,0-42,6-67,6"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M470.7,448.5c0,0-50.1-1.8-99.2,19.2c0,0-13.2,4.8-23,12.5
              c-0.3,0.2-0.6,0.5-0.9,0.7c-0.1,0.1-0.2,0.1-0.3,0.2c-7.5,5.5-14.5,9.5-15.5,17.5c-0.5,4-0.5,5.6-1,20.6l-0.5,34.4l0.5,80
              l3.4,99.4l2.6,58.6l6,76c0,0,0.1,11.4,5,22c7,15,10,13,18,18s23.1,8.4,40,11c0,0,34.5,4,62,3"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M463.8,526.5c0,0,56-1.8,101,25.2l17.9-42.1
              c2.1-4.9,3-10.3,2.4-15.7c-0.5-4.2-1.9-8.6-5.3-11.2c0,0-37-27-116-27"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.3,526.5c0,0-49.5-1.8-99.5,28.2l-19.4-45.1
              c-2.1-4.9-2.2-9.5-1.6-14.9c0.5-4.2,3.6-7.4,7-10c0,0,34.5-29,113.5-29"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.3,531.3c0,0,63.6-0.6,97,23.4c0,0,8.4,4,9.4,25v242.2
              c0,0,2.2,27.9-13.9,42.4c0,0-30.1,15.5-92.1,17.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M466.9,531.3c0,0-61.7,0.4-95.1,24.4c0,0-10,6-11.4,24l2.4,229
              c0,0,0,38,13,54c0,0,22.4,18.1,90,19"/>
          </g>
          <g>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="360.9" y1="575.6" x2="330.9" y2="516.3"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="336.2" y1="489.8" x2="367.4" y2="559.9"/>
          </g>
          <g>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="571.8" y1="572.7" x2="599.8" y2="509.7"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="593.8" y1="487.7" x2="564.9" y2="556.9"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.3,857.3c0,0,23.5,0.4,58.5-2.6c0,0,25-3,31,6l17.1,42.6
              l1.9,6.4c0,0,4,18-12,24c0,0-49,8-96,8"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M472.2,857.3c0,0-23.5,0.4-58.5-2.6c0,0-25-3-31,6l-17.1,42.6
              l-1.9,6.4c0,0-4,18,12,24c0,0,49,8,96,8"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M391.8,873.7c0,0,64,26,154-1L555,892c1,2.2,1.7,4.5,1.8,6.9
            c0,0.1,0,0.1,0,0.2c0.1,3.5-2.2,6.6-5.6,7.6c-9.9,2.9-39,10.8-63.5,9.9c0,0-10-1-17-1c0,0-12,0-18.4,1.1c0,0-41.6-0.1-63.6-6.1
            c0,0-0.5-0.1-1.2-0.5c-5.3-2.3-7.6-8.5-5.4-13.8L391.8,873.7z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M624.2,954.7"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M464.3,875.7h33.1c0.8,0,1.3-0.9,0.9-1.6c-0.7-1.6-2.8-3.8-8.6-4.5
              h-24.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M469.5,875.7h-33.1c-0.8,0-1.3-0.9-0.9-1.6
              c0.7-1.6,2.8-3.8,8.6-4.5H469"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M407.6,937.8c0,0,58.7-7.4,124.4-0.8"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M315,787.3c0,0,0.8,100.4,7.8,126.4c0,0,14,22,54,21c0,0,0,7,12,11
              c0,0,9,3,81,5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M620.8,787.3c0,0-0.8,100.4-7.8,126.4c0,0-13.2,16-51.2,21
              c0,0-2,7-14,9c0,0-1,2-78,7"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M613.8,911.3c0,0,5.1,0.9,11.1-6.4"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M322.8,912.7c0,0-6,0-10-6.7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M336.2,922.2l2.3-8.1c0.4-1.6,1.4-3,2.8-3.8c0,0,0,0,0.1,0
            c1.2-0.7,2.6-0.6,3.7,0.2c2.8,1.8,9.3,6.6,15.2,14.5c0.1,0.1,0.2,0.2,0.3,0.3l4.7,5.6c0.6,0.7,0.1,1.7-0.8,1.7
            c-4.9-0.3-16.8-1.8-27.4-8.1C336.3,924,336,923.1,336.2,922.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M603.5,918.2l-2.3-8.1c-0.4-1.6-1.4-3-2.8-3.8c0,0,0,0-0.1,0
            c-1.2-0.7-2.6-0.6-3.7,0.2c-2.8,1.8-9.3,6.6-15.2,14.5c-0.1,0.1-0.2,0.2-0.3,0.3l-4.7,5.6c-0.6,0.7-0.1,1.7,0.8,1.7
            c4.9-0.3,16.8-1.8,27.4-8.1C603.4,920,603.7,919.1,603.5,918.2z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M327.8,932.7c0,0,15-2,26,12"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609.8,928.7c0,0-15-2-26,12"/>
          <g>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="361.8,712 333.4,709.1 315,728.7 			"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.8,836.7l-13.4,24.9c-0.6,1.1-1.6,1.9-2.8,2.1
              c-1.5,0.2-3.2-0.6-3.8-5c0,0-6.8-106-6.4-124.5l23.4-0.5"/>
          </g>
          <g>
            <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="571.8,710.7 604.4,707.1 618.8,723.7 			"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M570.8,835.7l14.6,21.9c0.6,1.1,1.6,1.9,2.8,2.1
              c1.5,0.2,3.2-0.6,3.8-5c0,0,5.2-104.5,4.8-123h-24.5"/>
          </g>
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="322.3" cy="810.2" rx="4.5" ry="14.5"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313.8,710.7c-3,0-6-8.3-6-18.5s2.5-18.5,5.5-18.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313.8,706.2c3,0,5-5.7,5-12.5s-3-12-6-12"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,708.7c3,0,6-8.3,6-18.5s-2.5-18.5-5.5-18.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,704.2c-3,0-5-5.7-5-12.5s3-12,6-12"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M321,548.8c-1.7-3.6-6.1-11.4-12.2-12.1c0,0-14.6,0-23.6,18.2
              c-0.3,0.7-0.6,1.4-0.8,2.1c-0.5,2.3-1.3,7.3,1.2,10.5c0.6,0.8,1.5,1.3,2.5,1.3c2.4,0,7.7-0.5,15.6-4.1c0,0,12.3-6.2,16.7-11.7
              C321.4,551.7,321.6,550.1,321,548.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M287.1,568.5c0,0,11.7-12.9,33.7-18.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M311.8,537.7l7.9-7.7c0.6-0.6,1.6-0.5,2,0.2
              c1.9,3.5,4.7,11.6-0.9,19.5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M610.8,548.3c1.7-3.6,6.1-11.4,12.2-12.1c0,0,14.6,0,23.6,18.2
              c0.3,0.7,0.6,1.4,0.8,2.1c0.5,2.3,1.3,7.3-1.2,10.5c-0.6,0.8-1.5,1.3-2.5,1.3c-2.4,0-7.7-0.5-15.6-4.1c0,0-12.3-6.2-16.7-11.7
              C610.4,551.2,610.2,549.6,610.8,548.3z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M644.7,568c0,0-11.7-12.9-33.7-18.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M620,537.2l-7.9-7.7c-0.6-0.6-1.6-0.5-2,0.2
              c-1.9,3.5-4.7,11.6,0.9,19.5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M328.4,509.7c0,0-4.6-14.5-16.6-18.7"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="317.9" y1="494.4" x2="317.9" y2="444.6"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M601.8,504.7c0,0,4.6-14.5,16.6-18.7"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="612.3" y1="489.4" x2="611.8" y2="441.2"/>
          </g>
          <g>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="390.8" y1="440.7" x2="390.8" y2="452.7"/>
            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="392.8" y1="440.2" x2="392.8" y2="452.2"/>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M430.5,441.4L372.2,460c-3,1-6.3-0.7-7.3-3.8
                  l-0.7-2.3c-1-3,0.7-6.3,3.8-7.3l58.3-18.6c3-1,6.3,0.7,7.3,3.8l0.7,2.3C435.2,437.1,433.5,440.4,430.5,441.4z"/>
                <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M428.9,440.6L373,458.4c-3,1-6.3-0.7-7.3-3.8
                  l0,0c-1-3,0.7-6.3,3.8-7.3l55.9-17.8c3-1,6.3,0.7,7.3,3.8l0,0C433.6,436.3,432,439.6,428.9,440.6z"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="368.8" y1="447.7" x2="368.8" y2="458.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="370.8" y1="447.2" x2="370.8" y2="458.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="372.8" y1="446.2" x2="372.8" y2="458.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="374.8" y1="445.7" x2="374.8" y2="457.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="376.8" y1="445.2" x2="376.8" y2="457.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="378.8" y1="444.7" x2="378.8" y2="456.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="380.8" y1="443.7" x2="380.8" y2="455.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="382.8" y1="443.2" x2="382.8" y2="455.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="384.8" y1="442.7" x2="384.8" y2="454.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="386.8" y1="441.7" x2="386.8" y2="454.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="388.8" y1="441.2" x2="388.8" y2="453.2"/>
              </g>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="394.8" y1="439.2" x2="394.8" y2="451.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="396.8" y1="438.7" x2="396.8" y2="450.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="398.8" y1="438.2" x2="398.8" y2="450.2"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="400.8" y1="437.2" x2="400.8" y2="449.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="402.8" y1="436.7" x2="402.8" y2="448.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="404.8" y1="436.2" x2="404.8" y2="448.2"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="406.8" y1="435.2" x2="406.8" y2="447.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="408.8" y1="434.7" x2="408.8" y2="446.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="410.8" y1="434.2" x2="410.8" y2="446.2"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="412.8" y1="433.7" x2="412.8" y2="445.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="414.8" y1="433.2" x2="414.8" y2="445.2"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="416.8" y1="432.2" x2="416.8" y2="444.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="418.8" y1="431.7" x2="418.8" y2="443.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="420.8" y1="431.2" x2="420.8" y2="443.2"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="422.8" y1="430.2" x2="422.8" y2="442.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="424.8" y1="429.7" x2="424.8" y2="442.2"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="426.8" y1="429.7" x2="426.8" y2="441.2"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="430.8" y1="430.7" x2="430.8" y2="439.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="428.8" y1="429.7" x2="428.8" y2="440.7"/>
            </g>
          </g>
          <g>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="537.7" y1="439.7" x2="537.7" y2="451.7"/>
            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="535.7" y1="439.2" x2="535.7" y2="451.2"/>
            <g>
              <g>
                <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M498,440.4l58.3,18.6c3,1,6.3-0.7,7.3-3.8
                  l0.7-2.3c1-3-0.7-6.3-3.8-7.3L502.2,427c-3-1-6.3,0.7-7.3,3.8l-0.7,2.3C493.3,436.1,495,439.4,498,440.4z"/>
                <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M499.6,439.6l55.9,17.8c3,1,6.3-0.7,7.3-3.8
                  l0,0c1-3-0.7-6.3-3.8-7.3l-55.9-17.8c-3-1-6.3,0.7-7.3,3.8l0,0C494.8,435.3,496.5,438.6,499.6,439.6z"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="559.7" y1="446.7" x2="559.7" y2="457.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="557.7" y1="446.2" x2="557.7" y2="457.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="555.7" y1="445.2" x2="555.7" y2="457.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="553.7" y1="444.7" x2="553.7" y2="456.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="551.7" y1="444.2" x2="551.7" y2="456.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="549.7" y1="443.7" x2="549.7" y2="455.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="547.7" y1="442.7" x2="547.7" y2="454.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="545.7" y1="442.2" x2="545.7" y2="454.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="543.7" y1="441.7" x2="543.7" y2="453.7"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="541.7" y1="440.7" x2="541.7" y2="453.2"/>
                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="539.7" y1="440.2" x2="539.7" y2="452.2"/>
              </g>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="533.7" y1="438.2" x2="533.7" y2="450.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="531.7" y1="437.7" x2="531.7" y2="449.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="529.7" y1="437.2" x2="529.7" y2="449.2"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="527.7" y1="436.2" x2="527.7" y2="448.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="525.7" y1="435.7" x2="525.7" y2="447.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="523.7" y1="435.2" x2="523.7" y2="447.2"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="521.7" y1="434.2" x2="521.7" y2="446.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="519.7" y1="433.7" x2="519.7" y2="445.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="517.7" y1="433.2" x2="517.7" y2="445.2"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="515.7" y1="432.7" x2="515.7" y2="444.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="513.7" y1="432.2" x2="513.7" y2="444.2"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="511.7" y1="431.2" x2="511.7" y2="443.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="509.7" y1="430.7" x2="509.7" y2="442.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="507.7" y1="430.2" x2="507.7" y2="442.2"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="505.7" y1="429.2" x2="505.7" y2="441.7"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="503.7" y1="428.7" x2="503.7" y2="441.2"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="501.7" y1="428.7" x2="501.7" y2="440.2"/>
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="497.7" y1="429.7" x2="497.7" y2="438.7"/>
              <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="499.7" y1="428.7" x2="499.7" y2="439.7"/>
            </g>
          </g>
        </g>
      </g></>),
    },
}