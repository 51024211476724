import React, { useEffect, useState } from "react";
import {
  IAddonRequirementInput,
  IAddressLocation,
  IBookingCreateInput,
} from "../../../../reducers/bookings/types";
import { formatGraphQLErrorMessage, getAddonIcon } from "../../../utils";
import styles from "./index.module.css";
import { FormControl, Grid, MenuItem, TextField } from "@material-ui/core";
import { GET_VALET_ADDON_PRICE } from "../../../../graphql/addOns/getValetAddOn";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { useLazyQuery } from "@apollo/client";
import { ValetType, IAddonType, LocationType, valetTypes } from "./types";
import { ApolloError } from "apollo-boost";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";

interface IProps {
  addon: IAddonType;
  bookingData: IBookingCreateInput;
  selectedAddon?: IAddonRequirementInput;
  onSelect(args: {
    addonType: any;
    quantity: number;
    rate?: number;
    pickupOtherLocation?: IAddressLocation;
    dropoffOtherLocation?: IAddressLocation;
    valetType?: String;
  }): void;
}

const addressLocation: IAddressLocation = {
  city: "",
  country: "",
  fullAddress: "",
  state: "",
  street: "",
  zipcode: "",
  location: {
    type: "Point",
    latitude: 0,
    longitude: 0,
  },
};

export const ValetCard: React.FC<IProps> = (props) => {
  const { addon, selectedAddon, onSelect, bookingData } = props;
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const snackbar = useSnackBar();
  const [selected, setSelected] = useState<boolean>(!!selectedAddon?.addon);
  const [pickupOtherLocation, setPickupOtherLocation] = useState<IAddressLocation>(_.cloneDeep(addressLocation));
  const [dropoffOtherLocation, setDropoffOtherLocation] = useState<IAddressLocation>(_.cloneDeep(addressLocation));
  const [selectedAddressType, setSelectedAddressType] = useState<string>(ValetType.DELIVERY_AND_COLLECTION);
  const [deliveryAddonRate, setDeliveryAddonRate] = useState<number>(0);
  const [collectionAddonRate, setCollectionAddonRate] = useState<number>(0);
  const [addonRate, setAddonRate] = useState<number>(0);
  const [selectedAddressTypeKey, setSelectedAddressTypeKey] = useState<LocationType>();
  const [valetPickupLocation, setValetPickupLocation] = useState<any>();
  const [valetDropOffLocation, setValetDropOffLocation] = useState<any>();
  const [pickupBranchId, setPickupBranchId] = useState<string | undefined>();
  const [dropOffBranchId, setDropOffBranchId] = useState<string | undefined>();

  const [getValetAddonPrice, { data: valetAddonPriceData }] = useLazyQuery(
    GET_VALET_ADDON_PRICE,
    {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {},
      onError: (error: ApolloError) => {
        setPickupOtherLocation(_.cloneDeep(addressLocation));
        setDropoffOtherLocation(_.cloneDeep(addressLocation));
        setSelected(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    }
  ); 

  useEffect(() => {
    if (!selected) {
      props.onSelect({ addonType: addon, quantity: 0 });
    }
  }, [selected]);

  useEffect(() => {
    if (selectedAddon && bookingData) {
      if (bookingData.pickupOtherLocation?.fullAddress && !bookingData.dropoffOtherLocation?.fullAddress) {
        setPickupOtherLocation(bookingData.pickupOtherLocation)
        setDeliveryAddonRate(selectedAddon.rate)
        setAddonRate(selectedAddon.rate)
        setSelectedAddressType(ValetType.DELIVERY)
      }
      if (bookingData.dropoffOtherLocation?.fullAddress && !bookingData.pickupOtherLocation?.fullAddress) {
        setDropoffOtherLocation(bookingData.dropoffOtherLocation)
        setCollectionAddonRate(selectedAddon.rate)
        setAddonRate(selectedAddon.rate)
        setSelectedAddressType(ValetType.COLLECTION)
      }
      if(bookingData.pickupOtherLocation?.fullAddress && bookingData.dropoffOtherLocation?.fullAddress) {
        setPickupOtherLocation(bookingData.pickupOtherLocation)
        setDropoffOtherLocation(bookingData.dropoffOtherLocation)
        setAddonRate(selectedAddon.rate)
        setSelectedAddressType(ValetType.DELIVERY_AND_COLLECTION)
      }
    }
  }, [bookingData, selectedAddon]);

  useEffect(() => {
    if (
      valetAddonPriceData &&
      valetAddonPriceData.consumerValetAddonPrice >= 0
    ) {
      if (
        pickupOtherLocation.fullAddress &&
        selectedAddressTypeKey === LocationType.PICKUP_LOCATION
      ) {        
        setDeliveryAddonRate(valetAddonPriceData.consumerValetAddonPrice);
      }
      if (
        dropoffOtherLocation.fullAddress &&
        selectedAddressTypeKey === LocationType.DROP_OFF_LOCATION
      ) {        
        setCollectionAddonRate(valetAddonPriceData.consumerValetAddonPrice);
      }
      if (
        selectedAddressType === ValetType.DELIVERY &&
        pickupOtherLocation.fullAddress
      ) {        
        setSelected(true);
        setAddonRate(valetAddonPriceData.consumerValetAddonPrice);
        onSelect({
          addonType: addon,
          quantity: 1,
          rate: valetAddonPriceData.consumerValetAddonPrice,
          pickupOtherLocation,
          dropoffOtherLocation,
        });
      }
      if (
        selectedAddressType === ValetType.COLLECTION &&
        dropoffOtherLocation.fullAddress
      ) {
        setSelected(true);
        setAddonRate(valetAddonPriceData.consumerValetAddonPrice);
        onSelect({
          addonType: addon,
          quantity: 1,
          rate: valetAddonPriceData.consumerValetAddonPrice,
          pickupOtherLocation,
          dropoffOtherLocation
        });
      }
    }
  }, [valetAddonPriceData, selectedAddressType]);

  useEffect(() => {
    if (
      pickupOtherLocation.fullAddress && pickupOtherLocation.city &&
      dropoffOtherLocation.fullAddress && dropoffOtherLocation.city    
    ) {
      if (selectedAddressType === ValetType.DELIVERY_AND_COLLECTION) {
        setSelected(true);
        setAddonRate(deliveryAddonRate + collectionAddonRate);
        onSelect({
          addonType: addon,
          quantity: 1,
          rate: deliveryAddonRate + collectionAddonRate || addonRate,
          pickupOtherLocation,
          dropoffOtherLocation,
        });
      }
    }
  }, [
    pickupOtherLocation,
    dropoffOtherLocation,
    deliveryAddonRate,
    collectionAddonRate,
  ]);

  useEffect(() => {
    if (
      bookingData &&
      (bookingData.pickupServiceLocation || bookingData.dropoffServiceLocation)
    ) {
      if (bookingData.pickupServiceLocation) {
        for (let i = 0; i < website.branches.length; i++) {
          const pickup = website.branches[i].locations.find(
            (location) => location.id === bookingData.pickupServiceLocation
          );
          if (pickup) {            
            if (typeof pickup?.branch !== "string") {              
              setPickupBranchId(pickup?.branch?.id);
            }            
            setValetPickupLocation(pickup.address);
          }
        }
      }

      if (bookingData.dropoffServiceLocation) {
        for (let i = 0; i < website.branches.length; i++) {
          const dropOff = website.branches[i].locations.find(
            (location) => location.id === bookingData.dropoffServiceLocation
          );
          if (dropOff) {
            if(typeof dropOff?.branch !== "string") {
              setDropOffBranchId(dropOff?.branch?.id);
            }
            setValetDropOffLocation(dropOff.address);
          }
        }
      }
    }
  }, [
    website.branches,
    bookingData.pickupServiceLocation,
    bookingData.dropoffServiceLocation,
  ]);

  const handleSelectAddress = (address: string, type: LocationType) => {
    geocodeByAddress(address)
      .then((results) => {
        if (results.length) {
          const loc = _.cloneDeep(addressLocation);
          const place = results[0];
          const streetName: string[] = [];
          place.address_components.forEach((item) => {
            if (item.types.includes("country")) {
              loc.country = item.long_name;
            } else if (item.types.includes("postal_code")) {
              loc.zipcode = item.long_name;
            } else if (item.types.includes("administrative_area_level_2")) {
              loc.state = item.short_name;
            } else if (
              item.types.includes("postal_town") ||
              item.types.includes("locality")
            ) {
              loc.city = item.long_name;
            } else if (
              item.types.includes("street_number") ||
              item.types.includes("route")
            ) {
              streetName.push(item.long_name);
            }
          });
          loc.street = streetName.join(", ");
          loc.fullAddress = address;
          const lng = place.geometry.location.lng();
          const lat = place.geometry.location.lat();
          loc.location = {
            type: "Point",
            latitude: lat,
            longitude: lng,
          };
          if (
            loc &&
            loc.location &&
            loc.location.latitude &&
            loc.location.longitude
          ) {
            if (type === LocationType.PICKUP_LOCATION && valetPickupLocation) {              
              setSelectedAddressTypeKey(LocationType.PICKUP_LOCATION);                                  
              getValetAddonPrice({
                variables: {
                  branchId: pickupBranchId ? pickupBranchId : "",
                  startLat: String(valetPickupLocation?.geometry?.latitude),
                  startLong: String(valetPickupLocation?.geometry?.longitude),
                  endLat: String(loc.location.latitude),
                  endLong: String(loc.location.longitude),
                },
              });
              setPickupOtherLocation({
                ...pickupOtherLocation,
                ...loc,
              });
            }

            if (type === LocationType.DROP_OFF_LOCATION) {
              setSelectedAddressTypeKey(LocationType.DROP_OFF_LOCATION)
              getValetAddonPrice({
                variables: {
                  branchId: dropOffBranchId ? dropOffBranchId : pickupBranchId,
                  startLat: String(valetDropOffLocation?.geometry?.latitude),
                  startLong: String(valetDropOffLocation?.geometry?.longitude),
                  endLat: String(loc.location.latitude),
                  endLong: String(loc.location.longitude),
                },
              });
              setDropoffOtherLocation({
                ...dropoffOtherLocation,
                ...loc,
              });
            }
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  const handleOnChange = (value: string, type: LocationType) => {
    if (type === LocationType.PICKUP_LOCATION) {
      if (!value) {
        setPickupOtherLocation(_.cloneDeep(addressLocation));
        setDeliveryAddonRate(0)
        setAddonRate(0)
        setSelected(false)
      } else {
        setPickupOtherLocation({
          ...pickupOtherLocation,
          fullAddress: value
        });
      }
    }
    if (type === LocationType.DROP_OFF_LOCATION) {
      if (!value) {
        setDropoffOtherLocation(_.cloneDeep(addressLocation));
        setCollectionAddonRate(0)
        setAddonRate(0)
        setSelected(false)
      } else {
        setDropoffOtherLocation({
          ...dropoffOtherLocation,
          fullAddress: value
        });
      }
    }
  }

  const active = {
    backgroundColor: "#fafafa",
    cursor: "pointer",
  } as React.CSSProperties;

  const inactive = {
    backgroundColor: "#ffffff",
    cursor: "pointer",
  } as React.CSSProperties;

  const removeValetAddon = () => {    
    setPickupOtherLocation(_.cloneDeep(addressLocation))
    setDropoffOtherLocation(_.cloneDeep(addressLocation))
    setSelected(false);
    setDeliveryAddonRate(0)
    setCollectionAddonRate(0)
    setAddonRate(0)
  };

  const divStyle = {
    border: '1px dashed rgba(255, 199, 0, 1)',
    backgroundColor: '#f2f1e8',    
  }

  const valetIconStyle = {    
    marginBottom: 20,    
    display: 'flex',
    justifyContent: 'center'
  }

  const valetIcon = {
    width: 30,
    height: 30,
  }
  return (
    <div className={styles.customAddonCard}    
      style={selected ?  divStyle : { width: '100%' }}
    >
      <div style={valetIconStyle}>
        <img src={getAddonIcon("VALET")} alt="custom addon" style={valetIcon} />
      </div>
      <div className={styles.addonInfo}>
        <p>{addon.displayName ? addon.displayName : addon.name}</p>
      </div>
      <div></div>
      <div style={{ marginTop: 20, padding: 10, width: '100%' }}>
        <Grid container item xs={8} md={12} xl={12} sm={12}>
          <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
            {(props) => (
              <Form style={{ width: "100%" }}>
                <Grid container item md={8} style={{ margin: "auto" }}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"typeOfValet"}
                      fullWidth
                      select
                      label="Type Of Valet"
                      value={selectedAddressType}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        onChange: (
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setPickupOtherLocation(_.cloneDeep(addressLocation));
                          setDropoffOtherLocation(_.cloneDeep(addressLocation));
                          setSelected(false);
                          setDeliveryAddonRate(0);
                          setCollectionAddonRate(0);
                          setAddonRate(0);
                          setSelectedAddressType(event.target.value as string);
                        },
                      }}
                      placeholder="Select Valet"
                    >
                      {valetTypes.map((item: string, index: number) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  spacing={2}
                  style={{ margin: 0 }}
                >
                  {(selectedAddressType === ValetType.DELIVERY ||
                    selectedAddressType ===
                      ValetType.DELIVERY_AND_COLLECTION) && (
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      style={{ marginTop: 20 }}
                    >
                      <PlacesAutocomplete
                        value={
                          pickupOtherLocation
                            ? pickupOtherLocation.fullAddress
                            : ""
                        }
                        onChange={(value: string) => {
                          handleOnChange(value, LocationType.PICKUP_LOCATION);
                        }}
                        onSelect={(value) => {
                          handleSelectAddress(
                            value,
                            LocationType.PICKUP_LOCATION
                          );
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                        }) => (
                          <div className="location-search">
                            <Field
                              component={TextField}
                              fullWidth
                              style={{
                                backgroundColor: selectedAddon ? "#FFFFFF" : "",
                              }}
                              label={"Delivery Address"}
                              {...getInputProps({
                                className: "location-search-input",
                                name: "state",
                                placeholder: "Delivery Address",
                              })}
                            ></Field>
                            <div className="autocomplete-dropdown-container">
                              {suggestions.map((suggestion, idx) => {
                                const style = suggestion.active
                                  ? active
                                  : inactive;
                                suggestion.id = suggestion.id || idx.toString();
                                return (
                                  <div
                                    className="suggestions"
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Grid>
                  )}
                  {(selectedAddressType === ValetType.COLLECTION ||
                    selectedAddressType ===
                      ValetType.DELIVERY_AND_COLLECTION) && (
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      style={{ marginTop: 20 }}
                    >
                      <PlacesAutocomplete
                        value={
                          dropoffOtherLocation
                            ? dropoffOtherLocation.fullAddress
                            : ""
                        }
                        onChange={(value: string) => {
                          handleOnChange(value, LocationType.DROP_OFF_LOCATION);
                        }}
                        onSelect={(value) => {
                          handleSelectAddress(
                            value,
                            LocationType.DROP_OFF_LOCATION
                          );
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                        }) => (
                          <Grid className="location-search">
                            <Field
                              component={TextField}
                              fullWidth
                              style={{
                                backgroundColor: selectedAddon ? "#FFFFFF" : "",
                              }}
                              label={"Collection Address"}
                              {...getInputProps({
                                className: "location-search-input",
                                name: "state",
                                placeholder: "Collection Address",
                              })}
                            ></Field>
                            <Grid className="autocomplete-dropdown-container">
                              {suggestions.map((suggestion, idx) => {
                                const style = suggestion.active
                                  ? active
                                  : inactive;
                                suggestion.id = suggestion.id || idx.toString();
                                return (
                                  <Grid
                                    className="suggestions"
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        )}
                      </PlacesAutocomplete>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        {selected ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p
              className="valetCard-remove-btn"
              style={{ backgroundColor: "#fddfdc", color: "#e00101" }}
              onClick={() => removeValetAddon()}
            >
              Remove
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
