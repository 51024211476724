import { gql } from "apollo-boost";

export const GET_AVAILABLE_ADDON_TYPES = gql`
    query consumerAddonTypes($branchId : String!, $startDate: String!, $endDate: String!, $bookingId: String, $rateTypeName: String, $businessCustomer: String) {
      consumerAddonTypes (branchId : $branchId, startDate: $startDate, endDate: $endDate, bookingId: $bookingId, rateTypeName: $rateTypeName, businessCustomer: $businessCustomer) {
        id
        name
        category
        displayName
        imageUrl
        hasFixedRate
        hasQuantity
        fixedRate
        description
        recurringRate {
          rateTypeName
          rateTypeDuration
          rate
          longTerm
        }
        totalQuantity
        availableQuantity
        isActivated
        taxable
        tax {
          title
          value
        }
      }
    }
`;
