import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import NewBooking from '../components/views/NewBooking/NewBooking';
import QuoteView from '../components/views/NewBooking/Quote/QuoteView';
import ViewBooking from '../components/views/Summary/ViewBooking';
import PaymentStatus from '../components/views/PaymentStatus/PaymentStatus';
import { IAppState } from "../store";
import Authenticator from "../components/views/Auth"
import CustomerPortal from '../components/views/CustomerPortal';
import NewSubscription from "../components/views/NewBooking/Subscription/NewSubscription";


export const history = createBrowserHistory();
// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");

const CustomRoutes = () => {
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  document.title = website.websiteTitle || website.organisation.name;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // tracking user timing
    ReactGA.timing({
      category: "JS Dependencies",
      variable: "load",
      value: 20,
      label: "Google CDN"
    });
  });

  useEffect(() => {
    if (website) {
      const favIconURL = website.websiteIcon || ''
      getFavIconURL(favIconURL);
    }
  }, [website]);

  const changeFavicon = (src: any) => {
    var link = document.createElement('link'),
      oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
      document.getElementsByTagName('head')[0].removeChild(oldLink);
    }
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  const getFavIconURL = async (logoUrl: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await Storage.get(logoUrl, config).then((result) => {
      return result;
    }).catch((err) => {
    });
    const response = await fetch(file as string).then((res) => {
      return res;
    });
    changeFavicon(file)
    if (!response.ok) {
      return;
    }
  };

  return (
    <Switch>
      <Route path="/quote" component={QuoteView} />
      <Route path="/booking" component={ViewBooking} />
      <Route path="/payment" component={PaymentStatus} />
      <Route path="/signin" component={Authenticator} />
      <Route path="/account" component={CustomerPortal} />
      <Route exact path="/update-booking" component={NewBooking} />
      <Route exact path="/" component={NewBooking} />
      <Route exact path="/car-subscription" component={NewSubscription} />
      
    </Switch>
  );
};
export default withRouter(CustomRoutes);
