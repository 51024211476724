import { gql } from "apollo-boost";

export const GET_CREDIT_NOTES = gql`
  query consumerCreditNotes($businessCustomerId: ID,$customerId:ID){
    consumerCreditNotes(businessCustomerId: $businessCustomerId,customerId:$customerId) {
      id
      creditNoteRef
      creditNoteType
      totalAmount
      availableAmount
      refundedAmount
      createdAt
      versionNumber
      paymentsLinked {
        amount
        id
        status
      }
      invoice {
        invoiceRef
        id
      }
      booking {
        referenceNumber
        id
      }
      customer {
        firstName
        lastName
        id
      }
      businessCustomer {
        businessName
        id
      }
    }
  }
`