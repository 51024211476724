import { gql } from "@apollo/client";

export const CONSUMER_VERIFY_CUSTOMER_LICENCE_DVLA = gql`
  query consumerVerifyLicenseWithDvla(
    $customerId: String!
    $licenseNumber: String!
    $insuranceNumber: String!
    $postCode: String!
  ) {
    consumerVerifyLicenseWithDvla(
      customerId: $customerId
      licenseNumber: $licenseNumber
      insuranceNumber: $insuranceNumber
      postCode: $postCode
    ) {
      success
      message
    }
  }
`;
