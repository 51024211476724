import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import { Field, Form, Formik, FieldProps } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  BookingType,
  IBookingCreateInput
} from "../../../../reducers/bookings/types";
import { IBranch, IServiceLocation } from "../../../../reducers/website/types";
import { IAppState } from "../../../../store";
import FlatPickerBar from "../../../FlatPicker";
import styles from './index.module.css';
import SearchIcon from "@material-ui/icons/SearchRounded";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from "@material-ui/core/Container";
import cx from 'classnames';
import Checkbox from "@material-ui/core/Checkbox";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { getEndDateForBilling, RATE_TYPES } from '../../utils';
import { DateTime as d, Settings } from "luxon"
import dayjs from "dayjs";
import { getLocalizedDateFormat, getLocalizedDateFormatString } from "../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../utils";
import { Box, Collapse, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const PlainInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: 0,
    },
    border: 0,
    opacity: 0
  },
  input: {
    border: 0,
    paddingLeft: 5,
    borderRadius: 5,
    fontSize: 'var(--font-medium)',
    minHeight: 0,
    padding: '5px 5px 8px 5px',
    '&:hover': {
      backgroundColor: 'none'
    },
    '&:focus': {
      borderRadius: 5,
      ackgroundColor: 'none'
    },
  },
}))(InputBase);

interface IProps {
  bookingData: IBookingCreateInput;
  requiredFieldUpdated: boolean;
  setRequiredFieldUpdated: Dispatch<SetStateAction<boolean>>
  searchVehicles: (data: IBookingCreateInput) => void;
  position?: number;
  height?: number;
  vehiclesSearched?: boolean;
  resetSearch: () => void;
  isBookingUpdate?: boolean
  setValues: Dispatch<SetStateAction<IBookingCreateInput>>
  values: IBookingCreateInput;
  handleSelectionBack: () => void
  bookingType?: string;
}
const isSelectOpenInitial: boolean[] = [false, false, false, false, false, false, false];

interface IOperatingHours {
  pickUp: number[],
  dropOff: number[],
}

const BaseDetails: React.FC<IProps> = (props) => {
  const [differentDropOffLocation, setDifferentDropOffLocation] = useState<boolean>(false);
  const { values, setValues } = props
  const [isSelectOpen, setSelectOpen] = useState<boolean[]>(isSelectOpenInitial);
  const [vehicleSearched, setVehicleSearched] = useState<boolean>(false);
  const [currentBranch, setCurrentBranch] = useState<IBranch>();
  const [searchDisabled, setSearchDisabled] = useState<boolean>(false)
  const { setRequiredFieldUpdated, position, height } = props;
  const [operatingHours, setOperatingHours] = useState<IOperatingHours>({
    pickUp: [0, 23.45],
    dropOff: [0, 23.45]
  })
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { allowSubscriptionBookings } = website.organisation
  const { country } = website.organisation.address;
  const branchData = website.branches;
  let locationsArray: IServiceLocation[] = [];
  const arr = branchData.map((branch) => {
    locationsArray = [...branch.activeLocations, ...locationsArray]
    locationsArray.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  });

  const [showSearchError, setSearchError] = useState({
    show: false,
    message: ""
  });

  const clearSearchError = () => {
    setSearchError({
      show: false,
      message: ""
    });
  }

  useEffect(() => {
    const selectedBranch = branchData.find((branch) => branch.id === values.branchId);
    if (selectedBranch) {
      setCurrentBranch(selectedBranch);
      Settings.defaultZone = selectedBranch.timeZone || "Europe/London"
    }
  }, [values.branchId])

  useEffect(() => {
    if (props.bookingData) {
      if (props.bookingData.isSubscription) {
        props.bookingData.billingFrequency = 1
        props.bookingData.isRecurringBilling = true
        props.bookingData.rateTypeDuration = props.bookingData.rateTypeName === RATE_TYPES.WEEKLY ? 10080 : 43200
      }
      setValues(props.bookingData);
      if (props.bookingData.dropoffServiceLocation && props.bookingData.dropoffServiceLocation.length) {
        setDifferentDropOffLocation(
          props.bookingData.pickupServiceLocation !==
          props.bookingData.dropoffServiceLocation
        );
      }
      else {
        setDifferentDropOffLocation(false);
      }
    }
  }, [props.bookingData]);

  useEffect(() => {
    if (props.isBookingUpdate && props.bookingData.dropoffDateTime) {
      const pickupOperatingHours = getOperatingHours("PICKUP", d.fromISO(props.bookingData.pickupDateTime));
      const dropoffOperatingHours = getOperatingHours("DROPOFF", d.fromISO(props.bookingData.dropoffDateTime));
      setOperatingHours({
        pickUp: pickupOperatingHours,
        dropOff: dropoffOperatingHours,
      });
    }
  }, [props.isBookingUpdate, currentBranch])

  useEffect(() => {
    if (props.isBookingUpdate && values.dropoffDateTime && values.rateTypeName) {
      const endDate = getEndDateForBilling(values.pickupDateTime, values.rateTypeName)
      setSearchDisabled(d.fromISO(values.dropoffDateTime) < endDate)
    }
  }, [props.isBookingUpdate, values.dropoffDateTime, values.rateTypeName, values.pickupDateTime]);

  useEffect(() => {
    if (website.allowedRateTypes?.length && !values.isSubscription && !props.isBookingUpdate) {
      let hourlyAllowed = false;
      let dailyAllowed = false;
      let weeklyAllowed = false;
      let monthlyAllowed = false;
      website.allowedRateTypes.forEach((rateType) => {
        switch (rateType) {
          case RATE_TYPES.HOURLY:
            hourlyAllowed = true;
            break;
          case RATE_TYPES.DAILY:
            dailyAllowed = true;
            break;
          case RATE_TYPES.WEEKLY:
            weeklyAllowed = true;
            break;
          case RATE_TYPES.MONTHLY:
            monthlyAllowed = true;
            break;
          default:
            dailyAllowed = true;
            break;
        }
      })
      if (values.dropoffDateTime) {
        // find booking duration in days, 0 means less than 1 day
        const bookingDurationInDays = Math.floor(d.fromISO(values.dropoffDateTime).diff(d.fromISO(values.pickupDateTime), "days").toObject().days || 0)
        if (!bookingDurationInDays) {
          if (hourlyAllowed) {
            setValues({
              ...values,
              rateTypeDuration: 60,
              billingCycleDuration: 60,
              rateTypeName: RATE_TYPES.HOURLY,
              billingCycleName: RATE_TYPES.HOURLY,
              isRecurringBilling: false,
              billingFrequency: 0
            })
          } else {
            setValues({
              ...values,
              rateTypeDuration: 1440,
              billingCycleDuration: 1440,
              rateTypeName: RATE_TYPES.DAILY,
              billingCycleName: RATE_TYPES.DAILY,
              isRecurringBilling: false,
              billingFrequency: 0
            })
          }
        } else {
          let now = new Date();
          const daysInCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

          if (bookingDurationInDays >= daysInCurrentMonth) { // more than a month booking
            if (monthlyAllowed) { // check if monthly allowed
              setValues({
                ...values,
                rateTypeDuration: 43200,
                billingCycleDuration: 43200,
                rateTypeName: RATE_TYPES.MONTHLY,
                billingCycleName: RATE_TYPES.MONTHLY,
                isRecurringBilling: true,
                billingFrequency: 1
              });
            } else if (weeklyAllowed) { // check if weekly allowed
              setValues({
                ...values,
                rateTypeDuration: 10080,
                billingCycleDuration: 10080,
                rateTypeName: RATE_TYPES.WEEKLY,
                billingCycleName: RATE_TYPES.WEEKLY,
                isRecurringBilling: true,
                billingFrequency: 1
              });
            } else if (dailyAllowed) { // if not monthly or weekly allowed put it as a daily booking
              setValues({
                ...values,
                rateTypeDuration: 1440,
                billingCycleDuration: 1440,
                rateTypeName: RATE_TYPES.DAILY,
                billingCycleName: RATE_TYPES.DAILY,
                isRecurringBilling: false,
                billingFrequency: 0
              })
            }
          } else if (bookingDurationInDays >= 7) { // less than a month booking but more than a week
            if (weeklyAllowed) {
              setValues({
                ...values,
                rateTypeDuration: 10080,
                billingCycleDuration: 10080,
                rateTypeName: RATE_TYPES.WEEKLY,
                isRecurringBilling: true,
                billingCycleName: RATE_TYPES.WEEKLY,
                billingFrequency: 1
              });
            } else { // if not monthly or weekly allowed put it as a daily booking
              setValues({
                ...values,
                rateTypeDuration: 1440,
                billingCycleDuration: 1440,
                rateTypeName: RATE_TYPES.DAILY,
                billingCycleName: RATE_TYPES.DAILY,
                isRecurringBilling: false,
                billingFrequency: 0
              })
            }
          } else { // less than a week booking
            setValues({
              ...values,
              rateTypeDuration: 1440,
              billingCycleDuration: 1440,
              rateTypeName: RATE_TYPES.DAILY,
              billingCycleName: RATE_TYPES.DAILY,
              isRecurringBilling: false,
              billingFrequency: 0
            })
          }
        }
      }
    }
    // if (website?.organisation?.autoRateType) {
    //   if (values.dropoffDateTime) {
    //     const monthlyEndate = getEndDateForBilling(values.pickupDateTime, RATE_TYPES.MONTHLY)
    //     if (values.dropoffDateTime && values.dropoffDateTime >= monthlyEndate) {
    //       setValues({
    //         ...values,
    //         rateTypeDuration: 43200,
    //         rateTypeName: RATE_TYPES.MONTHLY,
    //         isRecurringBilling: true,
    //         billingFrequency: 1
    //       });
    //     } else {
    //       const weeklyEndDate = getEndDateForBilling(values.pickupDateTime, RATE_TYPES.WEEKLY);
    //       if (values.dropoffDateTime && values.dropoffDateTime >= weeklyEndDate) {
    //         setValues({
    //           ...values,
    //           rateTypeDuration: 10080,
    //           rateTypeName: RATE_TYPES.WEEKLY,
    //           isRecurringBilling: true,
    //           billingFrequency: 1
    //         });
    //       } else {
    //         setValues({
    //           ...values,
    //           rateTypeDuration: 1440,
    //           rateTypeName: RATE_TYPES.DAILY,
    //           isRecurringBilling: false,
    //           billingFrequency: 0
    //         })
    //       }
    //     }
    //   }
    // }
  }, [values.dropoffDateTime, values.pickupDateTime, website, values.defaultBillingCycle]);

  useEffect(() => {
    if (vehicleSearched) {
      setVehicleSearched(false);
    }
  }, [values.pickupServiceLocation, values.dropoffServiceLocation, values.pickupDateTime, values.dropoffDateTime, values.rateTypeName, props.vehiclesSearched])

  useEffect(() => {
    if (props.requiredFieldUpdated) {
      props.resetSearch()
    }
  }, [props.requiredFieldUpdated])

  const baseDetailsSchema = Yup.object().shape({
    dropoffDateTime: Yup.string(),
    dropoffServiceLocation: Yup.string(),
    pickupDateTime: Yup.string()
      .required()
      .min(1),
    pickupServiceLocation: Yup.string()
      .required()
      .min(1)
  });

  const getBranchId = (id: string) => {
    const LN = locationsArray.find((location: any) => location.id === id);
    return (LN?.branch as any).id;
  }

  const handleSelectToggle = (i: number) => {
    const selectOpenState = [...isSelectOpen];
    selectOpenState[i] = !selectOpenState[i];
    setSelectOpen(selectOpenState);
  }

  const getLocationName = (id: string) => {
    if (id === "") {
      return 'Choose a location';
    }
    const LN = locationsArray.find((location: any) => location.id === id);
    const ln = LN ? `${LN?.name} - ${(LN?.branch as any).name}` : "";
    return ln;
  }

  const setTimeOnDate = (date: d, hour: number) => {
    const formattedDate = date.toFormat("dd/MM/yyyy");
    return d.fromFormat(`${formattedDate} ${numberToHour(hour)}`, "dd/MM/yyyy h:m")
  }

  const numberToHour = (opHr: number) => {
    const minute = parseInt((Math.round(opHr * 100)).toString().substr(-2));
    const hour = Math.floor(opHr);
    return `${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}`
  }

  const hourToNumber = (hour: string) => {
    const splitted = hour.split(":");
    return parseInt(splitted[0]) + (parseInt(splitted[1]) / 100)
  }

  const roundOffToNextQuarter = (time: string) => {
    const nextQuarter = Math.floor(parseInt(time.split(":")[1]) / 15 + 1) * 15;
    const hour = parseInt(time.split(":")[0])
    return nextQuarter < 60 ? Math.floor(hour) + nextQuarter / 100 : Math.floor(hour) + 1
  }

  const getTimesArray = (opHr: number[]) => {
    let arr = [];
    for (let i = opHr[0]; i <= opHr[1]; i) {
      arr.push(numberToHour(i));
      const minute = parseInt((Math.round(i * 100)).toString().substr(-2));
      if (minute >= 45)
        i = Math.floor(i) + 1;
      else
        i += 0.15
    }
    return arr
  }

  const getOperatingHours = (hoursFor: string, date: d) => {
    const isToday = d.now().hasSame(date, "day");
    let nextAvailableHour = roundOffToNextQuarter(d.now().toFormat("HH:mm"));
    let _hours: number[] = [0, 12];
    if (currentBranch && currentBranch.operatingHours && currentBranch.operatingHours.length) {
      const operatingHours = currentBranch.operatingHours.find(opHr => opHr.dayIndex === date.weekday);
      if (operatingHours) {
        const dropOffOnSameDay = (hoursFor === "DROPOFF" && values.pickupDateTime) ? d.fromISO(values.pickupDateTime).hasSame(date, "day") ? true : false : false;
        const time0 = d.fromFormat("00:00", "HH:mm");
        const startHour = hoursFor === "PICKUP" ? hourToNumber(operatingHours.pickupFromTime || "00:00") : hourToNumber(operatingHours.returnFromTime || "00:00");
        const endHour = hoursFor === "PICKUP" ? hourToNumber(operatingHours.pickupToTime || "00:00") : hourToNumber(operatingHours.returnToTime || "00:00");
        _hours = [startHour, endHour];
        if (isToday && startHour < nextAvailableHour) {
          _hours = [nextAvailableHour, endHour];
        }
        const pickupHour = values.pickupDateTime ? hourToNumber(d.fromISO(values.pickupDateTime).toFormat("HH:mm")) : startHour;
        if (dropOffOnSameDay && endHour <= pickupHour) {
          _hours = [startHour, pickupHour]
        }
        if (isToday && endHour < nextAvailableHour) {
          _hours = [0, 0];
        }
      }
    }
    return _hours
  }

  const handlePickUpDate = (value: Date) => {
    clearSearchError();
    let defaultDropOffDate = d.fromISO(values.dropoffDateTime || "");
    const fromDate = d.fromJSDate(value);
    const _pickUpHours = getOperatingHours("PICKUP", fromDate);
    const pickupDateTime = setTimeOnDate(fromDate, _pickUpHours[0]).toUTC().toISO();
    const minBookingDuration = currentBranch && currentBranch.minBookingDurationOptIn && currentBranch.minBookingDuration ? currentBranch?.minBookingDuration : 1;
    let nextAvailableDay = d.fromISO(pickupDateTime).plus({ hours: minBookingDuration });
    if (!values.dropoffDateTime || defaultDropOffDate < nextAvailableDay) {
      const disabledDates = getDisabledDates();
      while (disabledDates.includes(nextAvailableDay.weekday) || disabledDates.includes(getLocalizedDateFormat(country, nextAvailableDay.toUTC().toISO(), DATE_TYPE.CONDENSED))) {
        nextAvailableDay = nextAvailableDay.plus({ days: 1 });
      }
      defaultDropOffDate = nextAvailableDay;
    }
    const _dropOffHours = getOperatingHours("DROPOFF", defaultDropOffDate);
    let dropoffDateTime = defaultDropOffDate.toUTC().toISO();
    if (defaultDropOffDate.hour < _dropOffHours[0]) {
      dropoffDateTime = setTimeOnDate(defaultDropOffDate, _dropOffHours[0]).toUTC().toISO();
    }
    setValues({
      ...values,
      pickupDateTime,
      dropoffDateTime,
    });
    setOperatingHours({
      pickUp: _pickUpHours,
      dropOff: _dropOffHours
    })
    setRequiredFieldUpdated(true)
  }

  const handleDropoffDate = (value: Date) => {
    clearSearchError();
    const toDate = d.fromJSDate(value).startOf("day");
    const _dropOffHours = getOperatingHours("DROPOFF", toDate);
    const dropoffDateTime = setTimeOnDate(toDate, _dropOffHours[0]).toUTC().toISO();
    setValues({
      ...values,
      dropoffDateTime
    });
    setOperatingHours({
      ...operatingHours,
      dropOff: _dropOffHours
    })
    setRequiredFieldUpdated(true)
  }

  const getNextAvailableDay = (dateTime: d) => {
    let nextAvailableDay = dateTime.plus({ days: 1 });
    const disabledDates = getDisabledDates();
    while (disabledDates.includes(nextAvailableDay.weekday) || disabledDates.includes(getLocalizedDateFormat(country, nextAvailableDay.toUTC().toISO(), DATE_TYPE.CONDENSED))) {
      nextAvailableDay = nextAvailableDay.plus({ days: 1 });
    }
    let nextDropoffOperatingHours = getOperatingHours("DROPOFF", nextAvailableDay);
    nextAvailableDay = setTimeOnDate(nextAvailableDay, getOperatingHours("DROPOFF", nextAvailableDay)[0]);
    setOperatingHours({
      ...operatingHours,
      dropOff: nextDropoffOperatingHours,
    });
    return nextAvailableDay;
  }

  const handlePickUpTime = (event: any) => {
    clearSearchError();
    const pickupDateTime = setTimeOnDate(d.fromISO(values.pickupDateTime), hourToNumber(event.target.value));
    const minBookingDuration = currentBranch && currentBranch.minBookingDurationOptIn && currentBranch.minBookingDuration ? currentBranch?.minBookingDuration : 0;
    let dropoffDateTime = d.fromISO(values.dropoffDateTime || "");
    let dropoffOperatingHours = parseInt(event.target.value.split(":")[1]) === 45 ? hourToNumber(event.target.value) + 0.55 : hourToNumber(event.target.value) + 0.15;
    const isSameDay = values.dropoffDateTime ? pickupDateTime.hasSame(dropoffDateTime, "day") ? true : false : false;
    if (isSameDay) {
      if (operatingHours.dropOff[0] <= hourToNumber(event.target.value) && operatingHours.dropOff[1] >= dropoffOperatingHours) {
        dropoffDateTime = setTimeOnDate(dropoffDateTime, dropoffOperatingHours);
      } else if (operatingHours.dropOff[1] < dropoffOperatingHours) {
        dropoffDateTime = getNextAvailableDay(pickupDateTime);
      }
    }
    const timeDiff = dropoffDateTime.diff(pickupDateTime, 'hours').toObject().hours || 0;
    if (timeDiff < minBookingDuration) {
      dropoffDateTime = dropoffDateTime.plus({ hours: minBookingDuration - timeDiff });
      if (hourToNumber(dropoffDateTime.toLocaleString(d.TIME_24_SIMPLE)) > operatingHours.dropOff[1]) {
        dropoffDateTime = getNextAvailableDay(dropoffDateTime);
      }
    }
    setValues({
      ...values,
      pickupDateTime: pickupDateTime.toUTC().toISO(),
      dropoffDateTime: dropoffDateTime.toUTC().toISO(),
    });
    setRequiredFieldUpdated(true);
  }

  function getHolidays(startDate: d, endDate: d) {
    const datesArray = [];
    let dateIterator = startDate;
    while (dateIterator < endDate) {
      datesArray.push(getLocalizedDateFormat(country, dateIterator.toUTC().toISO(), DATE_TYPE.CONDENSED));
      dateIterator = dateIterator.plus({ days: 1 });
    }
    datesArray.push(getLocalizedDateFormat(country, endDate.toUTC().toISO(), DATE_TYPE.CONDENSED));
    return datesArray;
  }

  const getDisabledDates = () => {
    let disabledDatesArray: (number | string)[] = []
    if (currentBranch?.operatingHours && currentBranch.operatingHours.length) {
      for (const opHr of currentBranch.operatingHours) {
        if (opHr.closed) {
          disabledDatesArray.push(opHr.dayIndex)
        }
      }
    }
    if (currentBranch && currentBranch.holidays && currentBranch.holidays.length) {
      for (const holiday of currentBranch.holidays) {
        const dates = getHolidays(d.fromFormat(holiday.startDate, getLocalizedDateFormatString(country, DATE_TYPE.CONDENSED)), d.fromFormat(holiday.endDate, getLocalizedDateFormatString(country, DATE_TYPE.CONDENSED)));
        disabledDatesArray = [...disabledDatesArray, ...dates];
      }
    }
    return disabledDatesArray
  }
  if (props.vehiclesSearched) {
    return (
      <>
        <div style={{ height: 50 }}></div>
        <div className={styles.searchInfo}>
          <p className="font-medium">
            <span>{getLocationName(values.pickupServiceLocation)}</span>
            <div className={styles.rangeLine}></div>
            <span>{getLocationName(values.dropoffServiceLocation)}</span>
          </p>
          <p className="font-medium space-between">
            <span className="flex cross-center">
              <span>{d.fromISO(values.pickupDateTime).toFormat("dd MMM HH:mm")}</span>
              <div className={styles.rangeLine}></div>
              <span>{values.dropoffDateTime && d.fromISO(values.dropoffDateTime).toFormat("dd MMM HH:mm")}</span>
            </span>
            {props.resetSearch && <span onClick={props.resetSearch} className="bold underline flex cross-center">
              <EditIcon fontSize="inherit" />
              <span className="hover-opacity">Edit Search</span>
            </span>}
          </p>
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={baseDetailsSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          clearSearchError();
          if (currentBranch && currentBranch.minBookingDurationOptIn && currentBranch.minBookingDuration) {
            const bookingDurationInHours = Math.abs(dayjs(values.dropoffDateTime).diff(dayjs(values.pickupDateTime), "hour"))
            if (currentBranch.minBookingDuration > bookingDurationInHours) {
              props.resetSearch()
              setSearchError({
                show: true,
                message: `Minimum booking duration is ${currentBranch.minBookingDuration} hours. Please adjust your pick-up or drop-off date and time accordingly.`,
              });
              return;
            }
          }
          if (!differentDropOffLocation) {
            values.dropoffServiceLocation = values.pickupServiceLocation;
          }
          const { isSubscription, activeSubscription, activeSubscriptionExpanded, ...rest } = values
          props.searchVehicles(rest);
          setSubmitting(false);
          setVehicleSearched(true);
        }}
      >
        {(formikProps) => (
          <Form className={styles.searchBox} style={{ marginTop: position || 70 }}>
            <Container style={{ padding: 0 }}>
              <Grid container className={styles.searcContainer}>
                <Grid container item xs={12} md={5} style={{ padding: '0 3px', position: "relative" }}>
                  {
                    (!props.isBookingUpdate && allowSubscriptionBookings && website?.bookingTypes?.length > 0) &&
                    (website.bookingTypes.includes(BookingType.RENTAL) && website.bookingTypes.includes(BookingType.SUBSCRIPTION)) && (
                      <div className={styles.backToSelectionScreen}>
                        <Tooltip title="Go back to booking categories selection.">
                          <IconButton
                            onClick={props.handleSelectionBack}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )
                  }
                  <Grid item xs={12} sm={6}>
                    <div className={styles.searchParam}>
                      <div className={styles.searchParamDisplay} role="button" onClick={() => handleSelectToggle(0)}>
                        <p className="font-small bold">Pick-Up Location *</p>
                        <div className="flex">
                          <div className="flex fill" style={{ overflow: 'hidden' }}>{getLocationName(values.pickupServiceLocation)}</div>
                          <ExpandMoreIcon style={{ fontSize: 20 }} />
                        </div>
                      </div>
                      <Field
                        required
                        name={"pickupServiceLocation"}
                        fullWidth
                      >
                        {({ field }: FieldProps) => (
                          <Select
                            {...field}
                            style={{ width: 0 }}
                            input={<PlainInput />}
                            onChange={(event: any) => {
                              clearSearchError();
                              setRequiredFieldUpdated(true);
                              const branchId = getBranchId(event.target.value);
                              let shouldResetDateTime = false;
                              if (branchId !== values.branchId) {
                                shouldResetDateTime = true;
                              }
                              setValues({
                                ...values,
                                pickupServiceLocation: event.target.value,
                                dropoffServiceLocation: '',
                                branchId: getBranchId(event.target.value),
                                pickupDateTime: shouldResetDateTime ? "" : values.pickupDateTime,
                                dropoffDateTime: shouldResetDateTime ? "" : values.dropoffDateTime
                              });
                            }}
                            value={values.pickupServiceLocation}
                            onClose={() => handleSelectToggle(0)}
                            open={isSelectOpen[0]}
                          >
                            {branchData &&
                              locationsArray.map(
                                (location: IServiceLocation, key: number) => {
                                  if (website.organisation.crossLocationBookingEnabled && !values.id) {
                                    return (
                                      <MenuItem value={location.id} key={location.id} data-locationName={location.name}>
                                        {location.name} - {(location.branch as any).name}
                                      </MenuItem>
                                    );
                                  } else if (!values.branchId || !props.isBookingUpdate || (location?.branch as any).id === values.branchId) {
                                    return (
                                      <MenuItem value={location.id} key={location.id} data-locationName={location.name}>
                                        {location.name} - {(location.branch as any).name}
                                      </MenuItem>
                                    );
                                  }
                                })
                            }
                          </Select>
                        )}
                      </Field>
                    </div>

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={styles.searchParam}>
                      <div className={cx(styles.searchParamDisplay, !differentDropOffLocation && styles.disabled)} style={{ paddingLeft: 40 }} role="button" onClick={() => { differentDropOffLocation && handleSelectToggle(1) }}>
                        <p className="font-small bold" style={{ opacity: differentDropOffLocation ? 1 : 0.3 }}>Return Location *</p>
                        <div className="flex">
                          <div className="flex fill" style={{ overflow: 'hidden' }}>{getLocationName(values.dropoffServiceLocation)}</div>
                          <ExpandMoreIcon style={{ fontSize: 20 }} />
                        </div>
                      </div>
                      <div className={styles.dropInputCheck}>
                        <Checkbox
                          color="secondary"
                          onChange={() => {
                            clearSearchError();
                            differentDropOffLocation && setValues({
                              ...values,
                              dropoffServiceLocation: ""
                            });
                            setDifferentDropOffLocation(
                              !differentDropOffLocation
                            )
                            setRequiredFieldUpdated(true)
                            setSelectOpen(isSelectOpenInitial)
                          }}
                          checked={differentDropOffLocation}
                          checkedIcon={<CheckBoxIcon fontSize="small" className={styles.dropInputCheckBox} />}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" className={styles.dropInputCheckBox} />}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </div>
                      <Field
                        required
                        inputProps={{
                          onChange: (event: any) => {
                            clearSearchError();
                            setRequiredFieldUpdated(true);
                            setValues({
                              ...values,
                              dropoffServiceLocation: event.target.value
                            });
                          },
                          value: "l"
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        name={"dropoffServiceLocation"}
                        fullWidth
                      >
                        {({ field }: FieldProps) => (
                          <Select
                            {...field}
                            style={{ width: 0 }}
                            onChange={(event: any) => {
                              clearSearchError();
                              setRequiredFieldUpdated(true);
                              setValues({
                                ...values,
                                dropoffServiceLocation: event.target.value
                              });
                            }}
                            input={<PlainInput />}
                            value={values.dropoffServiceLocation}
                            onClose={() => handleSelectToggle(1)}
                            open={isSelectOpen[1]}
                            MenuProps={{
                              style: {
                                maxHeight: 500
                              }
                            }}
                          >
                            {branchData &&
                              locationsArray.map(
                                (location: IServiceLocation, key: number) => {
                                  if (website.organisation.crossLocationBookingEnabled) {
                                    return (
                                      <MenuItem value={location.id} key={location.id} data-locationName={location.name}>
                                        {location.name} - {(location.branch as any).name}
                                      </MenuItem>
                                    );
                                  } else if (!values.branchId || (location?.branch as any).id === values.branchId) {
                                    return (
                                      <MenuItem value={location.id} key={location.id} data-locationName={location.name}>
                                        {location.name} - {(location.branch as any).name}
                                      </MenuItem>
                                    );
                                  }
                                })
                            }
                          </Select>
                        )}
                      </Field>
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={3} style={{ padding: '0 3px' }}>
                  <Grid item xs={6}>
                    <div className={styles.searchParam}>
                      <label htmlFor="pickUpDateInput" className={styles.searchParamDisplay} role="button">
                        <p className="font-small bold">Pick-Up Date *</p>
                        <div className="flex space-between">
                          <span>{values.pickupDateTime ? d.fromISO(values.pickupDateTime).toFormat('dd MMM yy') : 'Select'}</span>
                          <ExpandMoreIcon style={{ fontSize: 20 }} />
                        </div>
                      </label>
                      <FlatPickerBar
                        handleDateChange={(value: Date) => handlePickUpDate(value)}
                        identifier={"pickupdateTime"}
                        name={"pickupdateTime"}
                        value={getLocalizedDateFormat(country, values.pickupDateTime, DATE_TYPE.CONDENSED)}
                        minDate={getOperatingHours("PICKUP", d.now())[1] ? getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED) : getLocalizedDateFormat(country, d.now().plus({ days: 1 }).toUTC().toISO(), DATE_TYPE.CONDENSED)}
                        disabledDates={getDisabledDates()}
                        timeZone={currentBranch?.timeZone}
                        render={
                          ({ defaultValue, value, ...props }: any, ref: any) => (
                            <input id="pickUpDateInput" {...props} defaultValue={defaultValue} ref={ref} className={styles.transparentInput} />
                          )
                        }
                        country={country}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.searchParam}>
                      <div className={styles.searchParamDisplay} role="button" onClick={() => handleSelectToggle(3)}>
                        <p className="font-small bold">Pick-Up Time *</p>
                        <div className="flex space-between">
                          <span>{values.pickupDateTime ? d.fromISO(values.pickupDateTime).toFormat("hh:mm a") : 'Select'}</span>
                          <ExpandMoreIcon style={{ fontSize: 20 }} />
                        </div>
                      </div>
                      <Field
                        id="pickup-select-outlined"
                        required
                        name={"pickupTime"}
                        fullWidth
                      >
                        {({ field }: FieldProps) => (
                          <Select
                            {...field}
                            input={<PlainInput />}
                            value={values.pickupDateTime ? d.fromISO(values.pickupDateTime).toFormat("HH:mm") : "00:00"}
                            disabled={!values.pickupDateTime}
                            onChange={handlePickUpTime}
                            onClose={() => handleSelectToggle(3)}
                            open={isSelectOpen[3]}
                            MenuProps={{
                              style: {
                                maxHeight: 500
                              }
                            }}
                          >
                            {getTimesArray(operatingHours.pickUp).map((hour, index) => {
                              return (
                                <MenuItem value={hour} key={index}>
                                  {d.fromFormat(hour, "HH:mm").toFormat("hh:mm a")}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      </Field>
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={3} style={{ padding: '0 3px' }}>
                  <Grid item xs={6}>
                    <div className={styles.searchParam}>
                      <label htmlFor="dropOffDateInput" className={cx(styles.searchParamDisplay, 's-p-d')} role="button">
                        <p className="font-small bold">Drop-off date *</p>
                        <div className="flex space-between">
                          <span>{values.dropoffDateTime ? d.fromISO(values.dropoffDateTime).toFormat('dd MMM yy') : 'Select'}</span>
                          <ExpandMoreIcon style={{ fontSize: 20 }} />
                        </div>
                      </label>
                      <FlatPickerBar
                        handleDateChange={(value: Date) => handleDropoffDate(value)}
                        identifier={"dropoffDateTime"}
                        name={"dropoffDateTime"}
                        value={getLocalizedDateFormat(country, values.dropoffDateTime || "", DATE_TYPE.CONDENSED)}
                        timeZone={currentBranch?.timeZone}
                        minDate={
                          values.pickupDateTime
                            ? getLocalizedDateFormat(country, d.fromISO(values.pickupDateTime).plus({ hours: currentBranch?.minBookingDurationOptIn && currentBranch.minBookingDuration ? currentBranch?.minBookingDuration : 0 }).toUTC().toISO(), DATE_TYPE.CONDENSED)
                            : getLocalizedDateFormat(country, d.now().plus({ days: 1 }).toUTC().toISO(), DATE_TYPE.CONDENSED)
                        }
                        disabledDates={getDisabledDates()}
                        render={
                          ({ defaultValue, value, ...props }: any, ref: any) => (
                            <input id="dropOffDateInput" {...props} defaultValue={defaultValue} ref={ref} className={styles.transparentInput} />
                          )
                        }
                        country={country}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={styles.searchParam}>
                      <div className={styles.searchParamDisplay} role="button" onClick={() => handleSelectToggle(5)}>
                        <p className="font-small bold">Drop-off Time *</p>
                        <div className="flex space-between">
                          <span>{values.dropoffDateTime ? d.fromISO(values.dropoffDateTime).toFormat("hh:mm a") : 'Select'}</span>
                          <ExpandMoreIcon style={{ fontSize: 20 }} />
                        </div>
                      </div>
                      <Field
                        required
                        select
                        name={"dropOffTime"}
                        fullWidth
                      >
                        {({ field }: FieldProps) => (
                          <Select
                            {...field}
                            input={<PlainInput />}
                            value={values.dropoffDateTime ? d.fromISO(values.dropoffDateTime).toFormat("HH:mm") : "00:00"}
                            disabled={!values.dropoffDateTime}
                            onChange={(event: any) => {
                              clearSearchError();
                              const dropoffDateTime = setTimeOnDate(d.fromISO(values.dropoffDateTime || ""), hourToNumber(event.target.value))
                                .toUTC()
                                .toISO();
                              setValues({
                                ...values,
                                dropoffDateTime: dropoffDateTime
                              });
                              setRequiredFieldUpdated(true)
                            }}
                            onClose={() => handleSelectToggle(5)}
                            open={isSelectOpen[5]}
                            MenuProps={{
                              style: {
                                maxHeight: 500
                              }
                            }}
                          >
                            {getTimesArray(operatingHours.dropOff).map((hour, index) => {
                              return (
                                <MenuItem value={hour}>
                                  {d.fromFormat(hour, "HH:mm").toFormat("hh:mm a")}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      </Field>
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={1} style={{ padding: '0 3px' }}>
                  <Grid item xs={12}>
                    <div className={styles.searchButtonWrap}>
                      <button
                        className={styles.searchButton}
                        aria-label="search"
                        type="submit"
                        disabled={
                          !values.pickupServiceLocation ||
                          !values.pickupDateTime ||
                          !values.billingCycleDuration || !values.dropoffDateTime ||
                          (differentDropOffLocation && !values.dropoffServiceLocation) ||
                          vehicleSearched ||
                          d.fromISO(values.dropoffDateTime) <= d.fromISO(values.pickupDateTime) ||
                          searchDisabled
                        }
                      >
                        <Hidden smDown>
                          <SearchIcon fontSize="inherit" color="inherit" />
                        </Hidden>
                        <Hidden mdUp>
                          <span className="font-regular bold">Search Vehicles</span>
                        </Hidden>
                      </button>
                    </div>
                  </Grid>
                </Grid>
                <Box style={{ width: "100%", padding: '0 3px', borderRadius: 2 }}>
                  <Collapse in={showSearchError.show}>
                    <Alert severity="error">
                      <Typography variant="body1">
                        {showSearchError.message}
                      </Typography>
                    </Alert>
                  </Collapse>
                </Box>
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </React.Fragment >
  );
};

export default BaseDetails;