import { gql } from "@apollo/client";

export const CREATE_CONSUMER_BUSINESS_CUSTOMER = gql`
  mutation createConsumerBusinessCustomer(
    $businessCustomer: BusinessCustomerCreateInput!
  ) {
    createConsumerBusinessCustomer(businessCustomer: $businessCustomer) {
      id
      businessName
      registrationNumber
      VAT
      overrideNetPaymentTerms
      requiresBookingApproval
      netPaymentTerms
      dailyBillingCycle
      weeklyBillingCycle
      monthlyBillingCycle
      overrideInvoiceDate
      invoicingDay
      credentialSent
      credentialSentOn
      isClientExists
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
        accountNumber
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
      notes{
        id
        description
        createdBy
        createdDate
      }
    }
  }
`;