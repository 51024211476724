import React from "react"

export const EstateSVG = {
  interior: {
    width: 386,
    height: 846,
    svg:(<><g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M352,181.9v-73.7c-0.4-32.5-11.3-42.5-11.3-42.5
      c-21-19.2-51.7-33.4-51.7-33.4c-13-5.9-28.3-9.2-28.3-9.2c-18.8-0.9-67-2.1-67-2.1l0,0c-2.6,0.1-48.5,1.2-66.8,2.1
      c0,0-15.2,3.3-28.3,9.2c0,0-30.7,14.2-51.7,33.4c0,0-10.9,10-11.3,42.5v73.7l-1.1,202.4v228.8l2,94.5c0,1.7,0.2,3.4,0.4,5.1
      L48,781.9c0,0,3.3,21.6,37.8,39.5c4.1,2.1,8.4,3.7,12.9,4.8l14.8,3.7c1.9,0.5,3.9,0.8,5.9,0.8l72.3,3.2v0l0.1,0l0.1,0v0l76.4-3.2
      c2-0.1,4-0.4,5.9-0.8l14.8-3.7c4.5-1.1,8.8-2.7,12.9-4.8c34.5-17.9,37.8-39.5,37.8-39.5l10.8-69.3c0.3-1.7,0.4-3.4,0.4-5.1l2-94.5
      V384.3L352,181.9z"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M323.6,287.4c0.3-1.2,0.1-2.4-0.6-3.5l-2.7-4.2
      C292.8,240.6,195,245.2,195,245.2l0,0c-3-0.1-98.5-4-125.7,34.6l-2.7,4.2c-0.7,1-0.9,2.3-0.6,3.5"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M252.7,26.2h-58.9h0h-58.9c-3.8,0-6.8,3.1-6.8,6.8
      c0,0.5,0.4,0.8,0.8,0.8h64.9h0.1h64.9c0.5,0,0.8-0.4,0.8-0.8C259.5,29.2,256.5,26.2,252.7,26.2z"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="130.5" y1="27.8" x2="257.7" y2="27.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="129.3" y1="29.2" x2="258.3" y2="29.2"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="128.4" y1="30.8" x2="259.3" y2="30.8"/>
    <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="128.2" y1="32.2" x2="259.2" y2="32.2"/>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="136.9" y1="27.8" x2="138.5" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="138.2" y1="29.2" x2="136.7" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="136.9" y1="30.8" x2="138.5" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="138.2" y1="32.3" x2="136.7" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="136.6" y1="27.7" x2="135" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="135.2" y1="29.2" x2="136.7" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="136.4" y1="30.8" x2="134.8" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="135" y1="32.2" x2="136.5" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="135.1" y1="32.1" x2="136.7" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="138.1" y1="32.1" x2="136.4" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="140.8" y1="27.8" x2="142.4" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="142.2" y1="29.2" x2="140.6" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="140.8" y1="30.9" x2="142.4" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="142.2" y1="32.3" x2="140.6" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="140.5" y1="27.7" x2="138.9" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="139.1" y1="29.2" x2="140.7" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="140.3" y1="30.8" x2="138.7" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="138.9" y1="32.3" x2="140.5" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="139" y1="32.2" x2="140.6" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="142" y1="32.1" x2="140.4" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="144.5" y1="27.8" x2="146.1" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="145.9" y1="29.2" x2="144.4" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="144.5" y1="30.8" x2="146.1" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="145.9" y1="32.3" x2="144.4" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="144.2" y1="27.7" x2="142.6" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="142.9" y1="29.2" x2="144.4" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="144" y1="30.8" x2="142.4" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="142.7" y1="32.2" x2="144.2" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="142.7" y1="32.1" x2="144.3" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="145.8" y1="32.1" x2="144.1" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="148" y1="27.8" x2="149.6" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="149.4" y1="29.2" x2="147.9" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="148" y1="30.8" x2="149.6" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="149.4" y1="32.3" x2="147.9" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="147.7" y1="27.7" x2="146.1" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="146.3" y1="29.2" x2="147.9" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="147.5" y1="30.8" x2="145.9" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="146.2" y1="32.2" x2="147.7" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="146.2" y1="32.1" x2="147.8" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="149.2" y1="32.1" x2="147.6" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="151.5" y1="27.7" x2="153.1" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="152.8" y1="29.2" x2="151.3" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="151.5" y1="30.8" x2="153.1" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="152.8" y1="32.3" x2="151.3" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="151.2" y1="27.7" x2="149.5" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="149.8" y1="29.2" x2="151.3" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="151" y1="30.8" x2="149.4" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="149.6" y1="32.2" x2="151.1" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="149.7" y1="32.1" x2="151.3" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="152.7" y1="32" x2="151" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="154.9" y1="28" x2="156.5" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="156.3" y1="29.4" x2="154.8" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="154.9" y1="31.1" x2="156.5" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="156.3" y1="32.5" x2="154.8" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="154.6" y1="27.9" x2="153" y2="26.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="153.2" y1="29.4" x2="154.8" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="154.4" y1="31" x2="152.8" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="153.1" y1="32.4" x2="154.6" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="153.1" y1="32.4" x2="154.7" y2="33.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="156.2" y1="32.3" x2="154.5" y2="33.9"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="158.2" y1="28" x2="159.8" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="159.5" y1="29.5" x2="158" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="158.2" y1="31.1" x2="159.8" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="159.5" y1="32.6" x2="158" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="157.9" y1="28" x2="156.2" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="156.5" y1="29.4" x2="158" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="157.7" y1="31" x2="156.1" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="156.3" y1="32.5" x2="157.8" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="156.4" y1="32.4" x2="158" y2="34"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="159.4" y1="32.3" x2="157.7" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="161.3" y1="28" x2="162.9" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="162.7" y1="29.5" x2="161.1" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="161.3" y1="31.1" x2="162.9" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="162.7" y1="32.6" x2="161.1" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="161" y1="28" x2="159.4" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="159.6" y1="29.4" x2="161.2" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="160.8" y1="31" x2="159.2" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="159.5" y1="32.5" x2="161" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="159.5" y1="32.4" x2="161.1" y2="34"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="162.5" y1="32.3" x2="160.9" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="164.5" y1="28.3" x2="166.1" y2="26.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="165.8" y1="29.7" x2="164.3" y2="28.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="164.5" y1="31.4" x2="166.1" y2="29.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="165.8" y1="32.8" x2="164.3" y2="31.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="164.2" y1="28.2" x2="162.6" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="162.8" y1="29.7" x2="164.3" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="164" y1="31.3" x2="162.4" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="162.6" y1="32.8" x2="164.1" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="162.7" y1="32.7" x2="164.3" y2="34.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="165.7" y1="32.6" x2="164" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="167.8" y1="28.1" x2="169.4" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="169.1" y1="29.5" x2="167.6" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="167.8" y1="31.2" x2="169.4" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="169.1" y1="32.6" x2="167.6" y2="31.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="167.4" y1="28" x2="165.8" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="166.1" y1="29.5" x2="167.6" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="167.3" y1="31.1" x2="165.7" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="165.9" y1="32.6" x2="167.4" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="166" y1="32.5" x2="167.6" y2="34.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="169" y1="32.4" x2="167.3" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="171" y1="28.1" x2="172.6" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="172.4" y1="29.6" x2="170.9" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="171" y1="31.2" x2="172.6" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="172.4" y1="32.7" x2="170.9" y2="31.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="170.7" y1="28.1" x2="169.1" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="169.4" y1="29.5" x2="170.9" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="170.6" y1="31.1" x2="169" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="169.2" y1="32.6" x2="170.7" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="169.2" y1="32.5" x2="170.8" y2="34.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="172.3" y1="32.4" x2="170.6" y2="34.1"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="174.4" y1="28.1" x2="176" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="175.7" y1="29.6" x2="174.2" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="174.4" y1="31.2" x2="176" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="175.7" y1="32.7" x2="174.2" y2="31.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="174.1" y1="28.1" x2="172.5" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="172.7" y1="29.5" x2="174.2" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="173.9" y1="31.1" x2="172.3" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="172.5" y1="32.6" x2="174.1" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="172.6" y1="32.5" x2="174.2" y2="34.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="175.6" y1="32.4" x2="173.9" y2="34.1"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="177.8" y1="28.1" x2="179.4" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="179.2" y1="29.6" x2="177.7" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="177.8" y1="31.2" x2="179.4" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="179.2" y1="32.7" x2="177.7" y2="31.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="177.5" y1="28.1" x2="175.9" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="176.2" y1="29.5" x2="177.7" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="177.4" y1="31.1" x2="175.7" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="176" y1="32.6" x2="177.5" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="176" y1="32.5" x2="177.6" y2="34.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="179.1" y1="32.4" x2="177.4" y2="34.1"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="181.1" y1="28.1" x2="182.7" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="182.4" y1="29.5" x2="180.9" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="181.1" y1="31.2" x2="182.7" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="182.4" y1="32.6" x2="180.9" y2="31.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="180.7" y1="28" x2="179.1" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="179.4" y1="29.5" x2="180.9" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="180.6" y1="31.1" x2="179" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="179.2" y1="32.6" x2="180.7" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="179.3" y1="32.5" x2="180.9" y2="34.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="182.3" y1="32.4" x2="180.6" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="184.3" y1="28" x2="185.9" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="185.7" y1="29.5" x2="184.1" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="184.3" y1="31.1" x2="185.9" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="185.7" y1="32.6" x2="184.1" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="184" y1="28" x2="182.4" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="182.6" y1="29.5" x2="184.2" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="183.8" y1="31.1" x2="182.2" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="182.4" y1="32.5" x2="184" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="182.5" y1="32.4" x2="184.1" y2="34"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="185.5" y1="32.3" x2="183.9" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="187.6" y1="27.9" x2="189.2" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="189" y1="29.3" x2="187.5" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="187.6" y1="30.9" x2="189.2" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="189" y1="32.4" x2="187.5" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="187.3" y1="27.8" x2="185.7" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="186" y1="29.3" x2="187.5" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="187.1" y1="30.9" x2="185.5" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="185.8" y1="32.3" x2="187.3" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="185.8" y1="32.2" x2="187.4" y2="33.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="188.9" y1="32.2" x2="187.2" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="191" y1="27.7" x2="192.6" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="192.3" y1="29.2" x2="190.8" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="191" y1="30.8" x2="192.6" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="192.3" y1="32.3" x2="190.8" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="190.7" y1="27.7" x2="189.1" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="189.3" y1="29.1" x2="190.8" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="190.5" y1="30.7" x2="188.9" y2="29.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="189.1" y1="32.2" x2="190.6" y2="30.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="189.2" y1="32.1" x2="190.8" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="192.2" y1="32" x2="190.5" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="194" y1="27.7" x2="195.6" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="195.4" y1="29.2" x2="193.9" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="194" y1="30.8" x2="195.6" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="195.4" y1="32.3" x2="193.9" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="193.7" y1="27.7" x2="192.1" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="192.3" y1="29.1" x2="193.9" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="193.5" y1="30.7" x2="191.9" y2="29.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="192.2" y1="32.2" x2="193.7" y2="30.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="192.2" y1="32.1" x2="193.8" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="195.3" y1="32" x2="193.6" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="196.9" y1="27.7" x2="198.5" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="198.3" y1="29.2" x2="196.7" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="196.9" y1="30.8" x2="198.5" y2="29.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="198.3" y1="32.3" x2="196.7" y2="30.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="196.6" y1="27.7" x2="195" y2="26.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="195.2" y1="29.1" x2="196.8" y2="27.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="196.4" y1="30.7" x2="194.8" y2="29.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="195.1" y1="32.2" x2="196.6" y2="30.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="195.1" y1="32.1" x2="196.7" y2="33.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="198.1" y1="32" x2="196.5" y2="33.7"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="199.6" y1="28.3" x2="201.2" y2="26.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="201" y1="29.8" x2="199.5" y2="28.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="199.6" y1="31.4" x2="201.2" y2="29.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="201" y1="32.8" x2="199.5" y2="31.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="199.3" y1="28.2" x2="197.7" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="197.9" y1="29.7" x2="199.5" y2="28.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="199.1" y1="31.3" x2="197.5" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="197.8" y1="32.8" x2="199.3" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="197.8" y1="32.7" x2="199.4" y2="34.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="200.8" y1="32.6" x2="199.2" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="202.8" y1="28.3" x2="204.4" y2="26.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="204.2" y1="29.7" x2="202.7" y2="28.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="202.8" y1="31.3" x2="204.4" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="204.2" y1="32.8" x2="202.7" y2="31.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="202.5" y1="28.2" x2="200.9" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="201.2" y1="29.7" x2="202.7" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="202.3" y1="31.3" x2="200.7" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="201" y1="32.7" x2="202.5" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="201" y1="32.6" x2="202.6" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="204.1" y1="32.6" x2="202.4" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="206.1" y1="28.2" x2="207.7" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="207.5" y1="29.7" x2="205.9" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="206.1" y1="31.3" x2="207.7" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="207.5" y1="32.8" x2="205.9" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="205.8" y1="28.2" x2="204.2" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="204.4" y1="29.6" x2="206" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="205.6" y1="31.2" x2="204" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="204.2" y1="32.7" x2="205.8" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="204.3" y1="32.6" x2="205.9" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="207.3" y1="32.5" x2="205.7" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="209.4" y1="28" x2="211" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="210.8" y1="29.5" x2="209.2" y2="28"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="209.4" y1="31.1" x2="211" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="210.8" y1="32.6" x2="209.2" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="209.1" y1="28" x2="207.5" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="207.7" y1="29.5" x2="209.3" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="208.9" y1="31.1" x2="207.3" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="207.6" y1="32.5" x2="209.1" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="207.6" y1="32.4" x2="209.2" y2="34"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="210.6" y1="32.3" x2="209" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="212.7" y1="27.9" x2="214.3" y2="26.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="214.1" y1="29.4" x2="212.6" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="212.7" y1="31" x2="214.3" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="214.1" y1="32.4" x2="212.6" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="212.4" y1="27.8" x2="210.8" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="211.1" y1="29.3" x2="212.6" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="212.2" y1="30.9" x2="210.6" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="210.9" y1="32.4" x2="212.4" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="210.9" y1="32.3" x2="212.5" y2="33.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="214" y1="32.2" x2="212.3" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="215.8" y1="27.9" x2="217.4" y2="26.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="217.2" y1="29.4" x2="215.6" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="215.8" y1="31" x2="217.4" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="217.2" y1="32.4" x2="215.6" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="215.5" y1="27.8" x2="213.9" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="214.1" y1="29.3" x2="215.7" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="215.3" y1="30.9" x2="213.7" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="213.9" y1="32.4" x2="215.5" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="214" y1="32.3" x2="215.6" y2="33.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="217" y1="32.2" x2="215.4" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="218.7" y1="27.9" x2="220.3" y2="26.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="220.1" y1="29.4" x2="218.5" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="218.7" y1="31" x2="220.3" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="220.1" y1="32.4" x2="218.5" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="218.4" y1="27.8" x2="216.8" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="217" y1="29.3" x2="218.6" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="218.2" y1="30.9" x2="216.6" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="216.8" y1="32.4" x2="218.4" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="216.9" y1="32.3" x2="218.5" y2="33.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="219.9" y1="32.2" x2="218.3" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="221.7" y1="28.3" x2="223.3" y2="26.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="223.1" y1="29.7" x2="221.6" y2="28.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="221.7" y1="31.3" x2="223.3" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="223.1" y1="32.8" x2="221.6" y2="31.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="221.4" y1="28.2" x2="219.8" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="220" y1="29.7" x2="221.6" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="221.2" y1="31.3" x2="219.6" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="219.9" y1="32.7" x2="221.4" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="219.9" y1="32.6" x2="221.5" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="223" y1="32.6" x2="221.3" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="224.9" y1="28.2" x2="226.5" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="226.3" y1="29.7" x2="224.8" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="224.9" y1="31.3" x2="226.5" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="226.3" y1="32.8" x2="224.8" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="224.6" y1="28.2" x2="223" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="223.3" y1="29.6" x2="224.8" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="224.5" y1="31.2" x2="222.9" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="223.1" y1="32.7" x2="224.6" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="223.1" y1="32.6" x2="224.7" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="226.2" y1="32.5" x2="224.5" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="228.2" y1="28.2" x2="229.8" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="229.6" y1="29.6" x2="228" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="228.2" y1="31.3" x2="229.8" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="229.6" y1="32.7" x2="228" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="227.9" y1="28.1" x2="226.3" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="226.5" y1="29.6" x2="228.1" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="227.7" y1="31.2" x2="226.1" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="226.3" y1="32.7" x2="227.9" y2="31.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="226.4" y1="32.6" x2="228" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="229.4" y1="32.5" x2="227.8" y2="34.1"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="231.5" y1="28" x2="233.1" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="232.9" y1="29.5" x2="231.4" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="231.5" y1="31.1" x2="233.1" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="232.9" y1="32.6" x2="231.4" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="231.2" y1="28" x2="229.6" y2="26.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="229.8" y1="29.4" x2="231.4" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="231" y1="31" x2="229.4" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="229.7" y1="32.5" x2="231.2" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="229.7" y1="32.4" x2="231.3" y2="34"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="232.7" y1="32.3" x2="231.1" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="234.9" y1="27.9" x2="236.5" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.2" y1="29.3" x2="234.7" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="234.9" y1="30.9" x2="236.5" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.2" y1="32.4" x2="234.7" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="234.5" y1="27.8" x2="232.9" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="233.2" y1="29.3" x2="234.7" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="234.4" y1="30.9" x2="232.8" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="233" y1="32.3" x2="234.5" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="233.1" y1="32.2" x2="234.6" y2="33.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.1" y1="32.2" x2="234.4" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="237.9" y1="27.9" x2="239.5" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.3" y1="29.3" x2="237.7" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="237.9" y1="30.9" x2="239.5" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.3" y1="32.4" x2="237.7" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="237.6" y1="27.8" x2="236" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.2" y1="29.3" x2="237.8" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="237.4" y1="30.9" x2="235.8" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.1" y1="32.3" x2="237.6" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.1" y1="32.2" x2="237.7" y2="33.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.1" y1="32.2" x2="237.5" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="240.8" y1="27.9" x2="242.4" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="242.2" y1="29.3" x2="240.6" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="240.8" y1="30.9" x2="242.4" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="242.2" y1="32.4" x2="240.6" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="240.5" y1="27.8" x2="238.9" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.1" y1="29.3" x2="240.7" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="240.3" y1="30.9" x2="238.7" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="238.9" y1="32.3" x2="240.5" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239" y1="32.2" x2="240.6" y2="33.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="242" y1="32.2" x2="240.4" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="235" y1="28.3" x2="236.6" y2="26.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.3" y1="29.7" x2="234.8" y2="28.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="235" y1="31.3" x2="236.6" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.3" y1="32.8" x2="234.8" y2="31.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="234.7" y1="28.2" x2="233" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="233.3" y1="29.7" x2="234.8" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="234.5" y1="31.3" x2="232.9" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="233.1" y1="32.7" x2="234.6" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="233.2" y1="32.6" x2="234.8" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.2" y1="32.6" x2="234.5" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="238.2" y1="28.2" x2="239.8" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.5" y1="29.7" x2="238" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="238.2" y1="31.3" x2="239.8" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.5" y1="32.8" x2="238" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="237.9" y1="28.2" x2="236.3" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.5" y1="29.6" x2="238" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="237.7" y1="31.2" x2="236.1" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.3" y1="32.7" x2="237.9" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.4" y1="32.6" x2="238" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.4" y1="32.5" x2="237.7" y2="34.2"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="241.4" y1="28.2" x2="243" y2="26.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="242.8" y1="29.6" x2="241.3" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="241.4" y1="31.3" x2="243" y2="29.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="242.8" y1="32.7" x2="241.3" y2="31.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="241.1" y1="28.1" x2="239.5" y2="26.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.7" y1="29.6" x2="241.3" y2="28.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="240.9" y1="31.2" x2="239.3" y2="29.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.6" y1="32.7" x2="241.1" y2="31.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="239.6" y1="32.6" x2="241.2" y2="34.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="242.7" y1="32.5" x2="241" y2="34.1"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="244.8" y1="28" x2="246.4" y2="26.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="246.1" y1="29.5" x2="244.6" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="244.8" y1="31.1" x2="246.4" y2="29.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="246.1" y1="32.6" x2="244.6" y2="31"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="244.4" y1="28" x2="242.8" y2="26.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="243.1" y1="29.4" x2="244.6" y2="27.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="244.3" y1="31" x2="242.7" y2="29.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="242.9" y1="32.5" x2="244.4" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="243" y1="32.4" x2="244.5" y2="34"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="246" y1="32.3" x2="244.3" y2="34"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="248.1" y1="27.9" x2="249.7" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="249.5" y1="29.3" x2="247.9" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="248.1" y1="30.9" x2="249.7" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="249.5" y1="32.4" x2="247.9" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="247.8" y1="27.8" x2="246.2" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="246.4" y1="29.3" x2="247.9" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="247.6" y1="30.9" x2="246" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="246.2" y1="32.3" x2="247.8" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="246.3" y1="32.2" x2="247.9" y2="33.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="249.3" y1="32.2" x2="247.7" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="251.1" y1="27.9" x2="252.8" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="252.5" y1="29.3" x2="251" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="251.1" y1="30.9" x2="252.8" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="252.5" y1="32.4" x2="251" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="250.8" y1="27.8" x2="249.2" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="249.5" y1="29.3" x2="251" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="250.7" y1="30.9" x2="249.1" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="249.3" y1="32.3" x2="250.8" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="249.4" y1="32.2" x2="250.9" y2="33.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="252.4" y1="32.2" x2="250.7" y2="33.8"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="254" y1="27.9" x2="255.6" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="255.4" y1="29.3" x2="253.9" y2="27.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="254" y1="30.9" x2="255.6" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="255.4" y1="32.4" x2="253.9" y2="30.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="253.7" y1="27.8" x2="252.1" y2="26.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="252.4" y1="29.3" x2="253.9" y2="27.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="253.5" y1="30.9" x2="251.9" y2="29.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="252.2" y1="32.3" x2="253.7" y2="30.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="252.2" y1="32.2" x2="253.8" y2="33.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="255.3" y1="32.2" x2="253.6" y2="33.8"/>
    </g>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M117.2,38.3c-5.6,3.6-34.3,22.4-51.6,39.4c-1.4,1.3-2.6,2.8-3.7,4.4
      c-2.3,3.3-6.5,9.8-10.1,18.7c0,0-1.2,4.6-3.3,8.8c-0.7,1.4-3,0.8-3.4-0.7c-0.5-1.7-0.8-4.7,0.3-9.7c0,0,4.1-18.4,10.7-26
      c0,0,4.3-7.1,30.1-19.9l30.9-15.1C117.2,38.1,117.3,38.2,117.2,38.3z"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="127.5" y1="33.5" x2="117.1" y2="38.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M46.6,110.3c0,0-3,9-10.8,13.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M68.6,280.2c0,0-1.9-5.7-1.7-25c0-2.9,0.2-5.9,0.5-8.8l8.1-87.3
      c1-9.2,1.5-11.5,2.9-17.1c4.3-16.7,13.9-53.1,21.1-67.4c0,0,14.3-32.4,29.9-40.9"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M271.6,38.3c5.6,3.6,34.3,22.4,51.6,39.4c1.4,1.3,2.6,2.8,3.7,4.4
      c2.3,3.3,6.5,9.8,10.1,18.7c0,0,1.2,4.6,3.3,8.8c0.7,1.4,3,0.8,3.4-0.7c0.5-1.7,0.8-4.7-0.3-9.7c0,0-4.1-18.4-10.7-26
      c0,0-4.3-7.1-30.1-19.9l-30.9-15.1C271.6,38.1,271.5,38.2,271.6,38.3z"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="261.3" y1="33.5" x2="271.7" y2="38.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M342.2,110.3c0,0,3,9,10.8,13.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M320.2,280.2c0,0,1.9-5.7,1.7-25c0-2.9-0.2-5.9-0.5-8.8l-8.1-87.3
      c-1-9.2-1.5-11.5-2.9-17.1c-4.3-16.7-13.9-53.1-21.1-67.4c0,0-14.3-32.4-29.9-40.9"/>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M62.2,290.5c0,0,25.9,92.4,28.3,113.4c0,0,5.4,41.2,5.1,75.8
        l2.1,176.6l0.6,72.5c0,0-13.5,47.5-13.2,74c0,0-13.8-12.3-20.8-37.6l-7.8-79.1l-3.3-47.8l8.1,5.7c0,0-6.9-95.7-8.1-146.3
        l3.3-159.5l2.1-37.6C58.5,300.7,59.4,295.3,62.2,290.5z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M43.5,178.8l0.3,64.7l0.3,97.8l2.4,129.4l6.6,166.7
        c0,0-5.8-2.9-6.5-7c-0.7-4.1-6.1-12.9-11.2-15.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M58.5,300.5c0,0-2.5-184.5,5-220.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M61,335.6l8.2,4.1c0,0,15.3,55.5,17,75.9l3.7,60.3l-29.3-19.7
        L61,335.6z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M61.6,491.2l28.3,4.1v118.1l-22.1,22.1
        C67.8,635.5,61,556.2,61.6,491.2z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M68.1,648.5c0,0-0.7-2.7,1.4-6.1l13.3-11.9c0,0,4.1-1.4,4.1,4.1
        l2.4,68.4c0,0-1.4,11.9-7.1,31.3c0,0-3.1,10.6-5.4,10.6c0,0-5.1-2-5.1-13.3L68.1,648.5z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="64.4" y1="585.1" x2="90.2" y2="593.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="64.4" y1="590.6" x2="89.6" y2="598.8"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="89.9,485.7 52.8,470.1 46.7,468.7 33.7,457.8 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M58.5,291.8c0,0-4.3-16.7-23.7-20.1"/>
    </g>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M325.8,290.5c0,0-25.9,92.4-28.3,113.4c0,0-5.4,41.2-5.1,75.8
        l-2.1,176.6l-0.6,72.5c0,0,13.5,47.5,13.2,74c0,0,13.8-12.3,20.8-37.6l7.8-79.1l3.3-47.8l-8.1,5.7c0,0,6.9-95.7,8.1-146.3
        l-3.3-159.5l-2.1-37.6C329.5,300.7,328.6,295.3,325.8,290.5z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M344.5,178.8l-0.3,64.7l-0.3,97.8l-2.4,129.4l-6.6,166.7
        c0,0,5.8-2.9,6.5-7c0.7-4.1,6.1-12.9,11.2-15.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M329.5,300.5c0,0,2.5-184.5-5-220.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M327,335.6l-8.2,4.1c0,0-15.3,55.5-17,75.9l-3.7,60.3l29.3-19.7
        L327,335.6z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M326.4,491.2l-28.3,4.1v118.1l22.1,22.1
        C320.2,635.5,327,556.2,326.4,491.2z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M319.9,648.5c0,0,0.7-2.7-1.4-6.1l-13.3-11.9c0,0-4.1-1.4-4.1,4.1
        l-2.4,68.4c0,0,1.4,11.9,7.1,31.3c0,0,3.1,10.6,5.4,10.6c0,0,5.1-2,5.1-13.3L319.9,648.5z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="323.6" y1="585.1" x2="297.8" y2="593.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="323.6" y1="590.6" x2="298.4" y2="598.8"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="298.1,485.7 335.2,470.1 341.3,468.7 354.3,457.8 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M329.5,291.8c0,0,4.3-16.7,23.7-20.1"/>
    </g>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="89.9" y1="495.3" x2="89.9" y2="475.5"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="297.9" y1="495.1" x2="297.9" y2="476.1"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M98.7,728.1c0,0,0.9-2.7,2-5.4c1.3-3,4.2-4.9,7.5-4.8
      c2.5,0.1,6.2,0.4,11.3,1.4c0,0,37,5.8,74.4,5.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M289.7,728.1c0,0-0.9-2.7-2-5.4c-1.3-3-4.2-4.9-7.5-4.8
      c-2.5,0.1-6.2,0.4-11.3,1.4c0,0-37.5,5.8-74.9,5.2"/>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M95.5,739c0,0,2.4,8.3,17.1,15.1c5.4,2.5,11.2,4,17.1,4.8
        c12,1.6,40,4.8,66.5,4.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M292.5,739c0,0-2.4,8.3-17.1,15.1c-5.4,2.5-11.2,4-17.1,4.8
        c-12,1.6-40,4.8-66.5,4.8"/>
    </g>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M85.1,794.7c0,0,11.1,15.8,70,23.7c0,0,3.9,1.2,42.7,1.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M302.8,794.7c0,0-11.1,15.8-70,23.7c0,0-3.9,1.2-42.7,1.4"/>
    </g>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M105.1,750.4c0,0-5.1,25.9-6.1,35.7c0,0-1.5,6.6,0.3,9.2
      c0,0,3.9,6.3,35.4,11.4l13.8,1.9c0,0,0.2,0,0.4,0.1c5.4,0.9,11,0,15.9-2.4c3.3-1.6,7.6-3.4,10.8-3.4h18.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M282.8,750.4c0,0,5.1,25.9,6.1,35.7c0,0,1.5,6.6-0.3,9.2
      c0,0-3.9,6.3-35.4,11.4l-13.8,1.9c0,0-0.2,0-0.4,0.1c-5.4,0.9-11,0-15.9-2.4c-3.3-1.6-7.6-3.4-10.8-3.4h-18.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M96.3,384.9"/>
    <g>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M50.8,336.9l1.5-10.1c0,0,1.2-7-1.5-9.2c-2.3-1.9-6.5-3.4-12,1.4
        l-18.2,13.6c0,0-3.4,3.9-4.2,16.8c0,0,0.4,6.6,2.7,7c0,0,1.8,1.2,6.6-2.8L50.8,336.9z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M19.1,356c0,0,11.8-11.7,31.8-21.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="50.9" y1="317.3" x2="57.9" y2="313.1"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="52.7" y1="327.1" x2="57.3" y2="327"/>
    </g>
    <g>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M337.3,336.9l-1.5-10.1c0,0-1.2-7,1.5-9.2
        c2.3-1.9,6.5-3.4,12,1.4l18.2,13.6c0,0,3.4,3.9,4.2,16.8c0,0-0.4,6.6-2.7,7c0,0-1.8,1.2-6.6-2.8L337.3,336.9z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M369,356c0,0-11.8-11.7-31.8-21.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="337.2" y1="317.3" x2="330.1" y2="313.1"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="335.4" y1="327.1" x2="330.8" y2="327"/>
    </g>
    <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="40.8" cy="432.5" rx="1.3" ry="19.6"/>
    <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="45.6" cy="598.3" rx="1.3" ry="19.6"/>
    <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="348.6" cy="432.5" rx="1.3" ry="19.6"/>
    <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="343.8" cy="598.3" rx="1.3" ry="19.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M100.4,811.8c0,0-9.1-3.8-15.4-8.3c0,0-16.1-17.6-21.2-38.5l-5.3-15
      c0,0-3.6,28.6,2.8,43.2C61.4,793.2,71.8,808,100.4,811.8z"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M288.1,811.8c0,0,9.1-3.8,15.4-8.3c0,0,16.1-17.6,21.2-38.5l5.3-15
      c0,0,3.6,28.6-2.8,43.2C327.1,793.2,316.7,808,288.1,811.8z"/>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M99.5,811.5c0,0,40.1,13.5,100.4,13.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M290.2,811.5c0,0-40.1,13.5-100.4,13.5"/>
    </g>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M74.7,814.7L96,814c0,0,10.3,11.1,25.1,16.8"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M315.1,814.7l-21.3-0.8c0,0-10.3,11.1-25.1,16.8"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M225,304.5h47.4c0,0,6.9,1.3,9.7,13.5l0.4,30.3
      c0,0-3.6,17.3-13.3,22.8h-42.7c0,0-8.7-6.7-12.5-22.5v-31C214.1,317.5,217.6,306,225,304.5z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M283.5,365.9l-1-1.8h-6l-4,4.5l-4.7,2.5l-19.8-0.2v-0.1l-20.2,0.6
      l-4.3-3.4l-3.2-4.3l-6.7,0.4l-1.9,3.4l-1,8.8V403l0.8,6.9l2.5,4.8l3.1,3l4.8,2.4h6.7h2.9l0.2,2H248v0.1h16.3l0.2-2h2.9h6.7l4.8-2.4
      l3.1-3l2.5-4.8l0.8-6.9v-26.9l-0.8-7C284.4,368.1,284.1,366.9,283.5,365.9z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="248,402.1 227.7,402.1 227.7,419.8 231.5,424 248,424 
      264.6,424 268.4,419.8 268.4,402.1 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M122,306.5h47.4c0,0,6.9,1.3,9.7,13.5l0.4,30.3
      c0,0-3.6,17.3-13.3,22.8h-42.7c0,0-8.7-6.7-12.5-22.5v-31C111.1,319.5,114.6,308,122,306.5z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M180.5,367.9l-1-1.8h-6l-4,4.5l-4.7,2.5l-19.8-0.2v-0.1l-20.2,0.6
      l-4.3-3.4l-3.2-4.3l-6.7,0.4l-1.9,3.4l-1,8.8V405l0.8,6.9l2.5,4.8l3.1,3l4.8,2.4h6.7h2.9l0.2,2H145v0.1h16.3l0.2-2h2.9h6.7l4.8-2.4
      l3.1-3l2.5-4.8l0.8-6.9v-26.9l-0.8-7C181.4,370.1,181.1,368.9,180.5,367.9z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="145,404.1 124.7,404.1 124.7,421.8 128.5,426 145,426 
      161.6,426 165.4,421.8 165.4,404.1 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128,509.5h47.4c0,0,6.9,1.3,9.7,13.5l0.4,30.3
      c0,0-3.6,17.3-13.3,22.8h-42.7c0,0-8.7-6.7-12.5-22.5v-31C117.1,522.5,120.6,511,128,509.5z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M186.5,570.9l-1-1.8h-6l-4,4.5l-4.7,2.5l-19.8-0.2v-0.1l-20.2,0.6
      l-4.3-3.4l-3.2-4.3l-6.7,0.4l-1.9,3.4l-1,8.8V608l0.8,6.9l2.5,4.8l3.1,3l4.8,2.4h6.7h2.9l0.2,2H151v0.1h16.3l0.2-2h2.9h6.7l4.8-2.4
      l3.1-3l2.5-4.8l0.8-6.9v-26.9l-0.8-7C187.4,573.1,187.1,571.9,186.5,570.9z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="151,607.1 130.7,607.1 130.7,624.8 134.5,629 151,629 
      167.6,629 171.4,624.8 171.4,607.1 	"/>
  </g>
  <g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M218,508.5h47.4c0,0,6.9,1.3,9.7,13.5l0.4,30.3
      c0,0-3.6,17.3-13.3,22.8h-42.7c0,0-8.7-6.7-12.5-22.5v-31C207.1,521.5,210.6,510,218,508.5z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M276.5,569.9l-1-1.8h-6l-4,4.5l-4.7,2.5l-19.8-0.2v-0.1l-20.2,0.6
      l-4.3-3.4l-3.2-4.3l-6.7,0.4l-1.9,3.4l-1,8.8V607l0.8,6.9l2.5,4.8l3.1,3l4.8,2.4h6.7h2.9l0.2,2H241v0.1h16.3l0.2-2h2.9h6.7l4.8-2.4
      l3.1-3l2.5-4.8l0.8-6.9v-26.9l-0.8-7C277.4,572.1,277.1,570.9,276.5,569.9z"/>
    <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="241,606.1 220.7,606.1 220.7,623.8 224.5,628 241,628 
      257.6,628 261.4,623.8 261.4,606.1 	"/>
  </g></>)
  },

  exterior: {
    width: 915,
    height: 1441,
    svg: (<><g id="front">
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M295.2,182.9v82.3
        c0,3.5,2.8,6.3,6.1,6.3h30.3c3.4,0,6.1-2.8,6.1-6.3v-25.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M609.6,182.1v82.3
        c0,3.5-2.8,6.3-6.1,6.3h-30.3c-3.4,0-6.1-2.8-6.1-6.3V239"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M323.4,249.7h20c6,0,10.9-4.5,10.9-10h-41.7
        C312.6,245.2,317.5,249.7,323.4,249.7z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M562.4,249.5h20c6,0,10.9-4.5,10.9-10h-41.7
        C551.6,245,556.4,249.5,562.4,249.5z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="352.7" y1="244.9" x2="553.4" y2="244.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M609.5,140.5l-5.8-24.1
        c-0.4-3.9-5-6.8-5-6.8c-0.2-5.8-6.2-7.4-6.2-7.4c-0.8-3.1-5.4-4.1-5.4-4.1l-27.7-58.2c-2.8-5.9-7.9-10.4-14.1-12.3
        c-0.1,0-0.1,0-0.2-0.1c-1.4-0.4-2.8-0.7-4.3-0.8c-33.1-3.3-84.6-4.9-88.2-5v0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1,0v0
        c-3.7,0.1-56.7,1.8-89.8,5.2c0,0-0.3,0-0.7,0.1c-7.2,1.6-13.3,6.6-16.5,13.3L318,97.9c0,0-4.5,1-5.4,4.1c0,0-6,1.7-6.2,7.4
        c0,0-4.5,2.9-5,6.8l-5.8,24.1l-0.6,7.8l0.8,37.1c0,0,0.4,2.3,2.9,5c0,0,2,34,4.6,46c0.4,1.9,2.1,3.3,4.1,3.3l70.5,0h74.5h0.2h74.5
        l70,0c2.3,0,4.4-1.6,4.8-3.9c2.6-12.5,4.5-45.4,4.5-45.4c2.5-2.7,2.9-5,2.9-5l0.8-37.1L609.5,140.5z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M450.4,21.6v-2.8c0-1.8,1.1-3.3,2.4-3.3l0,0c1.3,0,2.4,1.5,2.4,3.3
        v2.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M352.4,42.8c0,0,2.5-9.7,6.8-15"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M553.1,42.8c0,0-2.5-9.7-6.8-15"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M580.4,101l-20.3-50.6
        c-1.5-3.7-4.5-6.7-8.3-7.9c-0.2-0.1-0.3-0.1-0.3-0.1c-17.9-1.5-73.1-2.8-94.6-3.3l0-0.2c0,0-1.6,0-4.4,0.1
        c-2.8-0.1-4.4-0.1-4.4-0.1l0,0.2c-21.5,0.5-76.7,1.8-94.6,3.3c0,0-0.1,0-0.3,0.1c-3.8,1.3-6.8,4.2-8.3,7.9L324.6,101
        c-0.4,1,0.3,2.1,1.4,2.1h122.5h7.9H579C580,103.1,580.8,102,580.4,101z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M576.4,101l-19.7-48.5
        c-1.5-3.6-4.4-6.4-8.1-7.6c-0.2-0.1-0.3-0.1-0.3-0.1c-17.4-1.4-71-2.7-91.9-3.2l0-0.2c0,0-1.5,0-4.3,0.1c-2.7-0.1-4.3-0.1-4.3-0.1
        l0,0.2c-20.9,0.5-74.5,1.8-91.9,3.2c0,0-0.1,0-0.3,0.1c-3.7,1.2-6.6,4-8.1,7.6L327.9,101c-1.3,2.7-0.7,2,1.4,2.1l119-0.1h7.7
        l119,0.1C576.1,103,577.5,103.6,576.4,101z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M529.1,156.7
        c0-1.5-0.7-3.8-1.5-5.1c-3.1-4.6-7.9-6.4-13.4-6.4h-61h-1.5h-61c-5.5,0-10.3,1.8-13.4,6.4c-0.9,1.2-1.5,3.6-1.5,5.1l-0.1,4.3
        c0,0,1.7,8.6,10.4,13.8c0,0,11.5,7.4,66.5,6.6l0,0c55.1,0.9,66.5-6.6,66.5-6.6c8.6-5.2,10.4-13.8,10.4-13.8L529.1,156.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M476.7,179.8l1.2-1.5h2.5
        l1.2,1.5l-1,1.2c0.8,0,1.6-0.1,2.4-0.1l-0.9-1l1.2-1.5h2.5l1.2,1.5l-0.7,0.8c0.5,0,1.1-0.1,1.6-0.1l-0.5-0.6l1.2-1.5h2.5l1.2,1.5
        l-0.3,0.3c0.4,0,0.7-0.1,1.1-0.1l-0.2-0.2l1.2-1.5h2.5l1,1.2c0.3,0,0.6-0.1,0.9-0.1l0.9-1h2.5l0.4,0.5c0.7-0.1,1.4-0.2,2.1-0.3
        l0.2-0.2h1.2c0.1,0,0.3,0,0.4-0.1h-1.6l-1.2-1.5l1.2-1.5h2.5l1.2,1.5l-1.1,1.3c0.7-0.1,1.4-0.3,2.1-0.4l-0.7-0.8l1.2-1.5h2.5
        l1.1,1.3c0.4-0.1,0.7-0.2,1-0.3l0.9-1h2.1c0,0,0,0,0.1,0h-2l-1.2-1.5l1.2-1.5h2.5l1.2,1.5l-0.9,1.1c0.4-0.2,0.5-0.3,0.5-0.3
        c0.3-0.2,0.6-0.4,0.9-0.6l-0.2-0.2l1.2-1.5h1.1c0,0,0,0,0,0h-1.1l-1.2-1.5l1.2-1.5h2.5l0.8,0.9c0.4-0.5,0.8-1,1.2-1.4l-0.8-0.9
        l1.2-1.5h1.2c0,0,0,0,0-0.1h-1.1l-1.2-1.5l1.2-1.5h2.5l0.1,0.1c0,0,0-0.1,0-0.1h-2.4l-1.2-1.5l1.2-1.5h2.5l0.7,0.8
        c0.1-0.4,0.1-0.6,0.1-0.6l0-1.2l-0.8,1h-2.5l-1.2-1.5l1.2-1.5h2.5l0.8,0.9l0-1.9l-0.8,0.9h-2.5l-1.2-1.5l1.2-1.5h2.5l0.7,0.8
        c-0.1-0.4-0.2-0.8-0.3-1.2l-0.3,0.4H526l-1.2-1.5l1.2-1.5h1.8c0,0,0,0,0,0h-1.6l-1.2-1.5l0.8-1c-0.5-0.5-1-0.9-1.6-1.3l-0.6,0.7
        H521l-1.2-1.5l0.9-1c-2-0.7-4.2-1.1-6.6-1.1h-61h-1.5h-50.8l1.2,1.4l-1.2,1.5h-2.5l-1.2-1.5l1.2-1.4h-7.7c-0.1,0-0.3,0-0.4,0
        l1.2,1.4l-1.2,1.5h-2.5l-1.2-1.5l1-1.2c-0.7,0.1-1.5,0.3-2.2,0.5l0.6,0.7l-1.2,1.5h-2.5l-0.6-0.7c-0.6,0.4-1.2,0.8-1.8,1.2
        l0.7,0.9l-1.2,1.5h-1.8c0,0,0,0,0,0h1.6l1.2,1.5l-1.2,1.5h-2.5l-0.5-0.5c-0.2,0.5-0.3,1.1-0.5,1.6l0.8-1h2.5l1.2,1.5l-1.2,1.5
        h-2.5l-1-1.2c0,0.3-0.1,0.6-0.1,0.9l0,1.6l1.1-1.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.1-1.3l0,2.4c0,0,0,0.1,0.1,0.2l1.1-1.3h2.5
        l1.2,1.5l-1.2,1.5h-2.5l-1-1.1c0.1,0.4,0.3,0.9,0.5,1.5l0.3-0.4h2.5l1.2,1.5l-1.2,1.5h-1.6c0,0,0,0,0,0.1h1.5l1.2,1.5l-1,1.2
        c0.6,0.9,1.4,1.8,2.3,2.7l-0.7-0.9l1.2-1.5h2.5l1.2,1.5l-1.2,1.5H382c0,0,0,0,0,0h2.3l1.2,1.5l-0.7,0.8c0.3,0.2,0.6,0.4,1,0.6
        c0,0,0.3,0.2,1,0.5l0.4-0.4h2.5l1.2,1.5l-0.3,0.3c0.3,0.1,0.7,0.2,1.1,0.3l-0.5-0.6l1.2-1.5h2.5l1.2,1.5l-1.1,1.4
        c0.8,0.2,1.6,0.3,2.5,0.5l0.3-0.4h2.5l0.8,1c0.3,0.1,0.7,0.1,1,0.2l0.9-1.1h2.5l1.2,1.5l-0.2,0.2c0.3,0,0.6,0.1,0.9,0.1l-0.2-0.2
        l1.2-1.5h2.5l1.2,1.5l-0.6,0.7c0.5,0,1,0.1,1.5,0.1l-0.6-0.7l1.2-1.5h2.5l1.2,1.5l-0.9,1c0.8,0.1,1.6,0.1,2.5,0.2l-1-1.2l1.2-1.5
        h2.5l1.2,1.5l-1.1,1.3c0.9,0,1.7,0.1,2.7,0.1l-1.2-1.4l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-1.4c7.5,0.3,16.3,0.5,26.7,0.3l0,0
        c9.8,0.2,18.2,0,25.5-0.2L476.7,179.8z M496.7,178.5h-2.5L493,177l1.2-1.5h2.5l1.2,1.5L496.7,178.5z M496.9,175.5h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L496.9,175.5z M496.9,172.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L496.9,172.6z M496.9,163.6h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L496.9,163.6z M496.9,160.7h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L496.9,160.7z M499.5,166.7h2.5l1.2,1.5
        l-1.2,1.5h-2.5l-1.2-1.5L499.5,166.7z M498.3,165.2l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L498.3,165.2z M502,178.5h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L502,178.5z M502.1,175.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L502.1,175.6z M502.1,172.6h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L502.1,172.6z M502.2,163.7h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L502.2,163.7z M502.2,160.7h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L502.2,160.7z M503.4,168.1l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L503.4,168.1z M503.5,165.1l1.2-1.5h2.5
        l1.2,1.5l-1.2,1.5h-2.5L503.5,165.1z M507.3,175.5h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L507.3,175.5z M507.3,172.6h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L507.3,172.6z M507.3,163.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L507.3,163.6z M507.3,160.7h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L507.3,160.7z M508.7,168.2l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L508.7,168.2z M508.8,165.2l1.2-1.5h2.5
        l1.2,1.5l-1.2,1.5H510L508.8,165.2z M512.6,175.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L512.6,175.6z M512.6,172.6h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L512.6,172.6z M512.6,163.7h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L512.6,163.7z M512.6,160.7h-2.5l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L512.6,160.7z M518,172.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L518,172.6z M518,163.6h-2.5l-1.2-1.5l1.2-1.5
        h2.5l1.2,1.5L518,163.6z M518,160.7h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L518,160.7z M523.1,169.6h-2.5l-1.2-1.5l1.2-1.5h2.5
        l1.2,1.5L523.1,169.6z M523.1,166.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L523.1,166.6z M523.3,163.7h-2.5l-1.2-1.5l1.2-1.5h2.5
        l1.2,1.5L523.3,163.7z M523.3,160.7h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L523.3,160.7z M521,148.9h2.5l1.2,1.5l-1.2,1.5H521
        l-1.2-1.5L521,148.9z M520.9,151.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L520.9,151.8z M520.8,154.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L520.8,154.8z M515.8,145.9h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L515.8,145.9z M515.8,148.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L515.8,148.8z M515.6,151.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L515.6,151.8z M515.5,154.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L515.5,154.8z M515.4,163.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L515.4,163.6z M515.3,166.6h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L515.3,166.6z M510.4,145.9h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L510.4,145.9z M510.4,148.9h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L510.4,148.9z M510.2,151.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L510.2,151.8z M510.1,154.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L510.1,154.8z M505.1,145.9h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L505.1,145.9z M505.1,148.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L505.1,148.8z M504.9,151.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L504.9,151.8z M504.9,154.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L504.9,154.8z M499.9,145.9h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L499.9,145.9z M499.9,148.9h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L499.9,148.9z M499.8,151.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L499.8,151.8z M499.7,154.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L499.7,154.8z M494.7,145.9h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L494.7,145.9z M494.7,148.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L494.7,148.8z M494.5,151.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L494.5,151.8z M494.4,154.8h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L494.4,154.8z M494.3,163.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L494.3,163.6z M494.2,166.6h2.5l1.2,1.5l-1.2,1.5h-2.5
        l-1.2-1.5L494.2,166.6z M384.1,168.9h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L384.1,168.9z M384.2,165.9h-2.5l-1.2-1.5l1.2-1.5h2.5
        l1.2,1.5L384.2,165.9z M384.4,163h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L384.4,163z M380.7,155.6l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5
        L380.7,155.6z M384.4,160h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L384.4,160z M384.4,154H382l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L384.4,154z
         M384.6,151.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L384.6,151.1z M409,145.4h2.5l1.2,1.5l-1.2,1.5H409l-1.2-1.5L409,145.4z
         M413.9,163.2h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L413.9,163.2z M409,148.3h2.5l1.2,1.5l-1.2,1.5H409l-1.2-1.5L409,148.3z
         M408.6,163.2h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L408.6,163.2z M403.2,163.1h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L403.2,163.1z
         M398.3,148.2h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L398.3,148.2z M397.9,163h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L397.9,163z
         M392.5,163.1h2.5l1.2,1.5L395,166h-2.5l-1.2-1.5L392.5,163.1z M387.6,148.2h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L387.6,148.2z
         M387.2,163h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L387.2,163z M387.2,166h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L387.2,166z
         M389.8,174.9h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L389.8,174.9z M389.8,171.9h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L389.8,171.9z
         M389.9,163h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L389.9,163z M386.2,155.6l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L386.2,155.6z
         M389.9,160.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L389.9,160.1z M389.9,154.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L389.9,154.1z
         M391.2,167.5l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L391.2,167.5z M395.1,174.9h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L395.1,174.9z
         M395.1,172h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L395.1,172z M395.1,163.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L395.1,163.1z
         M391.4,155.7l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L391.4,155.7z M395.1,160.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L395.1,160.1z
         M395.2,154.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L395.2,154.1z M395.4,151.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L395.4,151.2z
         M395.4,148.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L395.4,148.2z M397.9,166h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L397.9,166z
         M400.4,177.8h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L400.4,177.8z M400.5,174.9H398l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L400.5,174.9z
         M400.5,171.9H398l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L400.5,171.9z M400.5,163h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L400.5,163z
         M396.8,155.6l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L396.8,155.6z M400.5,160.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L400.5,160.1z
         M400.6,154.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L400.6,154.1z M403.1,166.1h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L403.1,166.1z
         M405.6,177.9h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L405.6,177.9z M405.8,174.9h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L405.8,174.9z
         M405.8,172h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L405.8,172z M405.8,163.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L405.8,163.1z
         M402.1,155.7l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L402.1,155.7z M405.8,160.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L405.8,160.1z
         M405.9,154.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L405.9,154.1z M406,151.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L406,151.2z
         M406,148.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L406,148.2z M408.5,166.2h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L408.5,166.2z
         M411,178h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L411,178z M411.2,175h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L411.2,175z M411.2,172.1
        h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L411.2,172.1z M411.2,163.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L411.2,163.2z M407.5,155.8
        l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L407.5,155.8z M411.2,160.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L411.2,160.2z M411.3,154.2h-2.5
        l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L411.3,154.2z M413.8,166.2h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L413.8,166.2z M416.3,178h-2.5
        l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L416.3,178z M416.5,175.1H414l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L416.5,175.1z M416.5,172.1H414l-1.2-1.5
        l1.2-1.5h2.5l1.2,1.5L416.5,172.1z M416.5,163.2H414l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L416.5,163.2z M412.8,155.8l1.2-1.5h2.5l1.2,1.5
        l-1.2,1.5H414L412.8,155.8z M416.5,160.3H414l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L416.5,160.3z M416.6,154.3h-2.5l-1.2-1.5l1.2-1.5h2.5
        l1.2,1.5L416.6,154.3z M416.7,151.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L416.7,151.3z M416.7,148.4h-2.5l-1.2-1.5l1.2-1.5h2.5
        l1.2,1.5L416.7,148.4z M478,166.5h2.5l1.2,1.5l-1.2,1.5H478l-1.2-1.5L478,166.5z M476.8,164.9l1.2-1.5h2.5l1.2,1.5l-1.2,1.5H478
        L476.8,164.9z M480.5,178.3H478l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L480.5,178.3z M480.6,175.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5
        L480.6,175.3z M480.6,172.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L480.6,172.4z M480.7,163.4h-2.5L477,162l1.2-1.5h2.5l1.2,1.5
        L480.7,163.4z M480.7,160.5h-2.5L477,159l1.2-1.5h2.5l1.2,1.5L480.7,160.5z M485.9,178.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5
        L485.9,178.4z M486,175.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L486,175.4z M486,172.5h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L486,172.5z
         M486.1,163.5h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L486.1,163.5z M486.1,160.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L486.1,160.6z
         M488.6,166.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L488.6,166.6z M487.5,165.1l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L487.5,165.1z
         M491.1,178.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L491.1,178.4z M491.3,175.5h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L491.3,175.5z
         M491.3,172.5h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L491.3,172.5z M491.3,163.6h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L491.3,163.6z
         M491.3,160.7h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L491.3,160.7z M489.1,145.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L489.1,145.8z
         M489.1,148.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L489.1,148.8z M488.9,151.7h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L488.9,151.7z
         M488.9,154.7h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L488.9,154.7z M483.8,145.8h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L483.8,145.8z
         M483.8,148.7h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L483.8,148.7z M483.7,151.7h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L483.7,151.7z
         M483.6,154.7h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L483.6,154.7z M483.4,163.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L483.4,163.6z
         M483.4,166.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L483.4,166.6z M478.4,145.7h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L478.4,145.7z
         M478.4,148.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L478.4,148.6z M478.2,151.6h2.5l1.2,1.5l-1.2,1.5h-2.5L477,153L478.2,151.6z
         M478.2,154.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L478.2,154.6z M473.1,145.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L473.1,145.6z
         M473.1,148.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L473.1,148.6z M473,151.5h2.5l1.2,1.5l-1.2,1.5H473l-1.2-1.5L473,151.5z
         M472.9,154.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L472.9,154.5z M472.8,163.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L472.8,163.4z
         M472.7,166.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L472.7,166.4z M467.7,145.7h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L467.7,145.7z
         M467.7,148.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L467.7,148.6z M469.8,166.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L469.8,166.4z
         M471,167.9l-1.2,1.5h-2.5l-1.2-1.5l1.2-1.5h2.5L471,167.9z M462.5,145.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L462.5,145.6z
         M462.5,148.6h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L462.5,148.6z M462.3,151.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L462.3,151.5z
         M462.2,154.5h2.5l1.2,1.5l-1.2,1.5h-2.5L461,156L462.2,154.5z M462.1,163.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L462.1,163.4z
         M462,166.4h2.5l1.2,1.5l-1.2,1.5H462l-1.2-1.5L462,166.4z M459.1,166.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.1,166.3z
         M460.2,167.9l-1.2,1.5h-2.5l-1.2-1.5l1.2-1.5h2.5L460.2,167.9z M451.7,145.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L451.7,145.5z
         M451.7,148.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L451.7,148.5z M451.5,151.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L451.5,151.5z
         M451.5,154.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L451.5,154.4z M451.3,163.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L451.3,163.3z
         M451.3,166.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L451.3,166.3z M448.3,166.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.3,166.2z
         M449.5,167.8l-1.2,1.5h-2.5l-1.2-1.5l1.2-1.5h2.5L449.5,167.8z M440.9,145.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L440.9,145.5z
         M440.9,148.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L440.9,148.4z M440.8,151.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L440.8,151.4z
         M440.7,154.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L440.7,154.4z M440.6,163.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L440.6,163.3z
         M440.5,166.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L440.5,166.3z M435.5,145.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L435.5,145.5z
         M435.5,148.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L435.5,148.5z M437.6,166.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.6,166.2z
         M438.8,167.8l-1.2,1.5h-2.5l-1.2-1.5l1.2-1.5h2.5L438.8,167.8z M430.3,145.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L430.3,145.5z
         M430.3,148.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L430.3,148.4z M430.1,151.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L430.1,151.4z
         M430,154.4h2.5l1.2,1.5l-1.2,1.5H430l-1.2-1.5L430,154.4z M432.4,166.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L432.4,166.2z
         M433.5,167.7l-1.2,1.5h-2.5l-1.2-1.5l1.2-1.5h2.5L433.5,167.7z M425.1,145.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L425.1,145.5z
         M425.1,148.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L425.1,148.5z M424.9,151.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L424.9,151.4z
         M427.3,163.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L427.3,163.3z M428.4,164.8l-1.2,1.5h-2.5l-1.2-1.5l1.2-1.5h2.5L428.4,164.8z
         M427.3,160.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L427.3,160.3z M424.9,154.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L424.9,154.4z
         M419.8,145.5h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L419.8,145.5z M419.8,148.4h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L419.8,148.4z
         M419.4,163.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L419.4,163.3z M419.4,166.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L419.4,166.3z
         M421.9,178.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L421.9,178.1z M422,175.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L422,175.1z
         M422,172.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L422,172.2z M422.1,163.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L422.1,163.2z
         M418.4,155.8l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L418.4,155.8z M422.1,160.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L422.1,160.3z
         M422.1,154.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L422.1,154.3z M424.6,166.3h2.5l1.2,1.5l-1.2,1.5h-2.5l-1.2-1.5L424.6,166.3z
         M427.1,178.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L427.1,178.1z M427.3,175.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L427.3,175.2z
         M427.3,172.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L427.3,172.2z M432.2,181.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L432.2,181.1z
         M432.3,178.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L432.3,178.1z M432.5,175.1H430l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L432.5,175.1z
         M432.5,172.2H430l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L432.5,172.2z M432.5,163.2H430l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L432.5,163.2z
         M432.5,160.3H430l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L432.5,160.3z M437.5,181.1H435l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.5,181.1z
         M437.6,178.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.6,178.1z M437.7,175.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.7,175.2z
         M437.7,172.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.7,172.2z M437.8,163.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.8,163.3z
         M434.1,155.9l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L434.1,155.9z M437.8,160.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.8,160.3z
         M437.8,154.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L437.8,154.4z M442.9,181.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L442.9,181.1z
         M443,178.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L443,178.1z M443.1,175.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L443.1,175.1z
         M443.1,172.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L443.1,172.2z M443.2,163.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L443.2,163.2z
         M443.2,160.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L443.2,160.3z M448.2,181.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.2,181.1z
         M448.3,178.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.3,178.1z M448.4,175.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.4,175.2z
         M448.4,172.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.4,172.2z M448.5,163.3H446l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.5,163.3z
         M444.7,155.9l1.2-1.5h2.5l1.2,1.5l-1.2,1.5H446L444.7,155.9z M448.5,160.3H446l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.5,160.3z
         M448.5,154.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.5,154.4z M448.7,151.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L448.7,151.4z
         M448.7,148.5h-2.5L445,147l1.2-1.5h2.5l1.2,1.5L448.7,148.5z M453.7,181.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L453.7,181.1z
         M453.7,178.1h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L453.7,178.1z M453.9,175.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L453.9,175.2z
         M453.9,172.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L453.9,172.2z M453.9,163.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L453.9,163.3z
         M453.9,160.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L453.9,160.4z M458.9,181.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L458.9,181.2z
         M459,178.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459,178.2z M459.2,175.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.2,175.2z
         M459.2,172.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.2,172.3z M459.2,163.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.2,163.4z
         M455.5,156l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L455.5,156z M459.2,160.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.2,160.4z
         M459.3,154.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.3,154.4z M459.4,151.5H457l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.4,151.5z
         M459.4,148.5H457l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L459.4,148.5z M464.4,181.2H462l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L464.4,181.2z
         M464.5,178.2H462l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L464.5,178.2z M464.7,175.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L464.7,175.3z
         M464.7,172.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L464.7,172.3z M464.7,163.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L464.7,163.4z
         M464.7,160.4h-2.5L461,159l1.2-1.5h2.5l1.2,1.5L464.7,160.4z M469.7,181.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L469.7,181.3z
         M469.8,178.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L469.8,178.3z M469.9,175.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L469.9,175.3z
         M469.9,172.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L469.9,172.4z M470,163.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L470,163.4z
         M466.3,156l1.2-1.5h2.5l1.2,1.5l-1.2,1.5h-2.5L466.3,156z M470,160.5h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L470,160.5z M470,154.5
        h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L470,154.5z M475.1,181.2h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L475.1,181.2z M475.2,178.2h-2.5
        l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L475.2,178.2z M475.3,175.3h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L475.3,175.3z M475.3,172.3h-2.5
        l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L475.3,172.3z M475.4,163.4h-2.5l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L475.4,163.4z M475.4,160.4h-2.5
        l-1.2-1.5l1.2-1.5h2.5l1.2,1.5L475.4,160.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M295.6,141.6
        c11-6.2,11.9-15.5,11.9-15.5c0.7-6.3,3.7-8.5,3.7-8.5l13-15.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M327.1,103l-8,8.4
        c-1.1,1.1-1.7,2.6-1.8,4.1c-0.3,4-0.3,13.2,3.1,26.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M324.8,105.5l35.7,30.8
        c0,0,11.9,9.4,32.3,9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M608.8,141.6
        c-11-6.2-11.9-15.5-11.9-15.5c-0.7-6.3-3.7-8.5-3.7-8.5l-13-15.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M577.4,103l8,8.4
        c1.1,1.1,1.7,2.6,1.8,4.1c0.3,4,0.2,13.4-3.1,26.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M579.6,105.5L544,136.3
        c0,0-11.9,9.4-32.3,9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M303.3,157.3
        c0,0,3.8,22.2,3.3,33.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M546.3,214.7
        c-12.4-9.3-22.1-8.1-22.1-8.1h-70.7h-2.1h-70.7c0,0-9.7-1.1-22.1,8.1c-3.7,2.8-5.8,7.3-5.4,11.9c0,0.1,0,0.1,0,0.2h97.3h3.7h97.3
        c0-0.1,0-0.1,0-0.2C552.1,222,550,217.5,546.3,214.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M306.7,221.8
        c0,0,0.2,8.7,8,9.1h64.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M306.6,183.6
        c0,0,15.9-2.3,41.1,3.5c6.6,1.5,12.8,4,18.6,7.5c5,3,10.7,7.1,14.5,11.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M373.6,239.8
        c0,0,2.2-12.5,16.2-13"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M602,157.1
        c0,0-3.3,22.4-2.8,33.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M599.4,221.8
        c0,0-0.2,8.7-8,9.1h-64.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M599.5,183.6
        c0,0-15.9-2.3-41.1,3.5c-6.6,1.5-12.8,4-18.6,7.5c-5,3-10.7,7.1-14.5,11.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M532.4,239.8
        c0,0-2.2-12.5-16.2-13"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="392" y1="206.3" x2="403.7" y2="226.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="514.5" y1="206.4" x2="502.1" y2="227.1"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="447.3" y1="206.4" x2="447.3" y2="226.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="459.1" y1="206.7" x2="459.1" y2="226.8"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="395.2" y1="211.7" x2="447.2" y2="211.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="397.7" y1="216.3" x2="447.2" y2="216.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="401.6" y1="222.8" x2="447.3" y2="222.8"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="511.3" y1="211.7" x2="459.3" y2="211.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="508.7" y1="216.3" x2="459.2" y2="216.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504.8" y1="222.8" x2="459.2" y2="222.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M380.8,206.4c0,0,7.4,7.4,10,20.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M369.8,208.5c0,0,7,6.8,9.5,18.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M525.5,206.4c0,0-7.4,7.4-10,20.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M536.4,208.5c0,0-7,6.8-9.5,18.4"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M366,171.7c0,0,12.3,12.3,51.7,13.4l35.4,0.4"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M537.4,171.7c0,0-12.3,12.3-51.7,13.4l-35.4,0.4"/>
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M376.1,153.7l-56.4-11.8
        c0,0-12.7-1.5-16.8,8.5c0,0-3.3,10.6,10.8,17.8c2.2,1.1,4.7,1.8,7.1,1.9l61.5,2.4C382.4,172.4,372.8,164.5,376.1,153.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M529,153.7l56.4-11.8
        c0,0,12.7-1.5,16.8,8.5c0,0,3.3,10.6-10.8,17.8c-2.2,1.1-4.7,1.8-7.1,1.9l-61.5,2.4C522.7,172.4,532.3,164.5,529,153.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M306.5,190.3v30
        c0,0-0.1,4.3,2.3,4.7c0,0,2.4,0.3,4-0.8l6.5-8.9l19.4-0.2c0.9,0,1.6-0.5,2.1-1.2l5.8-9.9c0.3-0.6-0.1-1.3-0.7-1.3l-24.2-0.2
        l-7.4-11.4c0,0-0.1-0.8-2.7-1L306.5,190.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M599.3,190.3v30
        c0,0,0.1,4.3-2.3,4.7c0,0-2.4,0.3-4-0.8l-6.5-8.9l-19.4-0.2c-0.9,0-1.6-0.5-2.1-1.2l-5.8-9.9c-0.3-0.6,0.1-1.3,0.7-1.3l24.2-0.2
        l7.4-11.4c0,0,0.1-0.8,2.7-1L599.3,190.3z"/>
      
        <circle fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" cx="322.1" cy="156.4" r="9.2"/>
      
        <circle fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" cx="343.1" cy="159.3" r="6.6"/>
      
        <circle fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" cx="360.8" cy="160.5" r="5"/>
      
        <circle fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" cx="581.4" cy="156.4" r="9.2"/>
      
        <circle fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" cx="560.4" cy="159.3" r="6.6"/>
      
        <circle fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" cx="542.7" cy="160.5" r="5"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M323.5,102.6h-14.9V87.2c0,0-0.6-3.9-4.1-4.4h-11
        c0,0-14.3,0.6-17.4,1.9c0,0-3.3,2.2-3.1,5.8V94c0,1.9,0.4,3.8,1.2,5.6c0.8,1.8,2.3,3.8,4.8,5c0,0,2.3,2.2,26.1,1.7h2.1l11,2.9
        L323.5,102.6z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M308.7,99.5h-21c-0.9,0-1.7-0.6-1.7-1.4l0,0c0-0.7,0.8-1.4,1.7-1.4
        h21"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M580.5,102.1h14.9V86.7c0,0,0.6-3.9,4.1-4.4h11
        c0,0,14.3,0.6,17.4,1.9c0,0,3.3,2.2,3.1,5.8v3.6c0,1.9-0.4,3.8-1.2,5.6c-0.8,1.8-2.3,3.8-4.8,5c0,0-2.3,2.2-26.1,1.7h-2.1l-11,2.9
        L580.5,102.1z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M595.3,99h21c0.9,0,1.7-0.6,1.7-1.4v0c0-0.7-0.8-1.4-1.7-1.4h-21"/>
    </g>
  </g>
  <g id="Layer_2">
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M43.2,540.6
        c0.9-0.9,2.8-0.8,4.8-0.7c5.4-0.1,10.9-0.4,16.8-0.9c6-0.5,12.6-1.1,18.9-2.8c6.4-1.8,12.5-4.6,18.1-8.5
        c5.5-3.9,10.5-8.9,14.5-14.4c4-5.6,7.1-11.7,9.2-18.4c2-6.6,2.9-13.7,2.9-20.5c0-6.9-1-13.6-3-20c-2-6.4-5.1-12.6-9.1-18.1
        c-4-5.6-9-10.6-14.6-14.6c-5.5-4-11.7-7.1-18-9.1c-6.4-1.9-12.9-2.7-18.6-3.1c-5.8-0.4-10.7-0.5-15.6-0.1
        c-2.3-0.2-4.4-0.3-5.7-1.3c-1.2-1-1.4-2.8-1.6-4.7l3.3-66.9c2.3-2.4,4.4-4.7,5.5-6.5c1.2-1.8,1.1-2.8,1.1-4v-10.3
        c1.6,0,3.3,0,4.4,0.4c1.2,0.3,2.4,0.8,2.4,1.8v1.9l23.6-2.4c0.2-0.7,0.3-1.3,1-1.6c0.6-0.2,1.7-0.2,2.7-0.2h14.8
        c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.6,1.3,2.3,2.1l2.8,1.3c2.9,0.1,5.9,0.3,8.6,0.8c2.6,0.5,4.9,1.5,7.2,2.4c0.7-0.6,1.3-1.2,2.2-1.1
        c0.9,0.1,2.1,0.8,3.2,1.5c6.8,5.3,12.2,12.2,17.3,23.3c5,11.2,9.8,26.8,14,43.8c4.2,17,7.8,35.5,10.7,54.8c2.9,19.4,5,39.6,6,50.8
        c1,11.3,0.9,13.6,0.6,17.6c-0.3,4-0.8,9.7-1.4,15.4c-0.8,4.9-1.1,10-0.9,15.8c0.2,5.9,0.8,12.6,1.8,17.4c1,4.9,2.4,8.1,4.5,10.8
        c5.4,8.5,10.8,16.9,19,31.2c8.2,14.3,19.3,34.5,30.3,54.7c0.3,0.6,0.7,1.2,1.1,1.3c0.4,0.1,1.1-0.2,1.4-0.7l4.2-6.8
        c3.8,9.3,6.5,18.9,9,31.4c2.4,12.6,4.4,27.9,5.3,47.5c0.9,19.5,0.8,43.2,0.7,63.2c0,19.9,0.2,36.3-0.3,58.1
        c-0.5,21.8-1.8,49.1-2.8,71.6c-1.1,22.4-2.1,39.9-3.3,57.4c0.3,0.9,0.6,1.9,1,3.8c0.5,1.8,1.2,4.5,1.8,7.2
        c0.8,2.7,1.6,5.4,2.2,7.2c0.7,1.8,1.1,2.8,1.3,3.6c0.2,0.8,0.4,1.4,0.4,2c-0.1,0.7-0.3,1.3-0.6,1.8c-0.3,0.5-0.8,0.8-1.5,0.8
        c-0.7-0.2-1.5-0.8-2.3-1.1c-0.8-0.4-1.7-0.4-2.4-0.3c-0.8,0.1-1.4,0.3-2,0.7l-0.8,9.7c-0.7,8.1-1.6,16.3-2.6,24.6
        c-1.1,8.3-2.3,16.7-3.4,22c-1.1,5.3-2,7.4-3.3,9.3c-2-9.9-4-19.8-6.5-28.7c-2.4-8.9-5.3-16.9-8.1-24.8c-4.9,5.9-9.8,11.7-13.8,17
        c-3.9,5.3-7,10-10,14.7c-2.6,4.8-4.7,9.9-6.6,16.5c-1.9,6.6-3.8,14.8-4.6,22.4c-0.8,7.6-0.7,14.8,0.4,21.8
        c-1.7,0.9-3.4,1.3-6.1,1.3c-2.6-0.1-6.1-0.7-8.6-1c-2.5-0.4-3.9-0.6-5.9-0.4c-2,0.2-4.5,0.8-6.9,1.5c-2.4,0.7-4.7,1.3-7,1.8
        c-2.3,0.5-4.7,0.8-6.5,1c-1.8,0.2-3.3,0.3-4.4-0.1c-1-0.4-1.7-1.3-1.8-2.2l-0.2-3.9c-0.1-1-0.9-1.9-2.9-2.1
        c-2-0.2-5.1,0.4-8.2,0.7c-3.1,0.3-6.2,0.2-8.1,0.3c-2,0.1-2.8,0.4-3.3,1c-0.5,0.5-0.6,1.3-1.1,1.8c-0.5,0.5-1.3,0.3-2.1,0.4
        l-9.1,0.2c-1.4,0-2.9,0.2-4.4,0.9c-1.5,0.8-3.2,1.9-4.6,2.6c-1.5,0.7-2.7,0.8-3.8,0.8H89l-0.4-5c0-1.3-0.2-2.8-0.6-4.7
        c-0.5-1.9-1.2-4.4-2.3-6.5c-1.1-2.2-2.6-3.9-4.4-5.4c-4.4-3.1-8.7-6.2-12.9-9.6c-4.2-3.3-8.3-6.9-12.4-10.5l-7.7-68.4
        c-0.2-1.8-0.1-3.8,0.5-4.7c0.7-0.9,2-0.8,3.4-0.8c3.7,0.7,7.5,0.9,12.7,0.7c5.2-0.2,11.7-1,18.1-2.8c6.4-1.8,12.5-4.6,18.1-8.6
        c5.5-3.9,10.5-8.9,14.5-14.5c4-5.5,7.1-11.7,9.2-18.3c2-6.6,3-13.7,2.9-20.6c0-6.9-1-13.5-3-20c-2-6.4-5.1-12.5-9.1-18.1
        c-4-5.5-9-10.5-14.5-14.6c-5.6-4-11.7-7.1-18-9c-6.4-1.8-12.8-2.5-18.4-2.6c-5.5-0.2-10.1,0.3-14.6,1.3c-2.2-0.1-4.4-0.3-5.6-1.3
        c-1.2-0.9-1.7-2.8-1.7-4.7l-0.5-322.9C42.3,543.5,42.2,541.5,43.2,540.6L43.2,540.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M193.6,1082.6
        c2.4-8.8,5.7-17.3,9-23.9c3.4-6.6,6.8-11.4,9.6-14.1c2.8-2.8,5.1-3.5,7.3-3c2.1,0.5,4.4,1.9,5,4.1l4.2,17.2l2.6,6.8
        c0.5,1.3,1.3,2.6,2,3.2c0.7,0.6,1.3,0.5,1.9,0.4c-2-9.9-4-19.8-6.5-28.7c-2.4-8.9-5.3-16.9-8.1-24.8c-4.9,5.9-9.8,11.7-13.8,17
        c-3.9,5.3-7,10-10,14.7c-2.6,4.8-4.7,9.9-6.6,16.5c-1.9,6.6-3.8,14.8-4.6,22.4c-0.8,7.6-0.7,14.8,0.4,21.8l3.1-2.4
        C189.8,1100.7,191.3,1091.5,193.6,1082.6L193.6,1082.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M193.6,1082.6
        c2.4-8.8,5.7-17.3,9-23.9c3.4-6.6,6.8-11.4,9.6-14.1c2.8-2.8,5.1-3.5,7.3-3c2.1,0.5,4.4,1.9,5,4.1l4.2,17.2
        c-5.8,8.4-11.7,16.8-18.3,24.6c-6.5,7.8-13.9,15-21.3,22.2C189.8,1100.7,191.3,1091.5,193.6,1082.6L193.6,1082.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M129.3,328.3
        c-1.3-2.8-2.7-4.1-4.1-5.4c0.7-0.6,1.3-1.2,2.2-1.1c0.9,0.1,2.1,0.8,3.2,1.5c1.7,2.4,2.8,5.2,4.4,12.9c1.6,7.7,3.7,20.3,5.9,36.2
        c2.3,15.8,4.8,35,7,54.2l-2.9-2.7c-2.4-18.1-4.8-36.3-6.9-50.3c-2.1-14-3.9-24-5.6-33.9C131.4,335.5,130.4,331.1,129.3,328.3
        L129.3,328.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M122.5,334.1
        c-3-1.5-5.6-2.4-8.2-3.3l-1.4-0.3c0.8,1.7,1.4,3.4,1.8,5.1c0.4,1.7,0.8,3.3-0.1,5c-0.8,1.8-2.8,3.7-3.9,5.3
        c-1.2,1.5-1.6,2.5-1.2,4.5c0.4,2,1.8,5,3.7,10.6c2,5.5,4.7,13.7,7,20.5c2.4,6.8,4.5,12.3,6.7,17.1c2.1,4.8,4.2,8.8,6,11.9
        c1.8,3.1,3.4,5.3,5.4,7.4c1.8,2.2,4.1,4.2,6.5,6c-2.4-18.1-4.8-36.3-6.9-50.3c-2.1-14-3.9-24-5.6-33.9
        C128.9,337.7,125.5,335.6,122.5,334.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.7,420.1
        c-1.3-1.3-2.6-2.5-3.8-3.9c-1.2-1.4-2.2-2.9-3.3-4.4c-0.7-1.2-1.4-2.4-2.2-3.6c-0.7-1.3-1.3-2.5-1.9-3.8c-1.2-2.3-2.3-4.6-3.4-7
        c-1.1-2.4-2-4.8-2.9-7.2c-0.6-1.5-1.2-3.1-1.8-4.6c-0.6-1.6-1.2-3.1-1.7-4.7c-1.8-5.3-3.7-10.6-5.4-15.9c-0.7-1.8-1.3-3.7-1.9-5.6
        c-0.7-1.8-1.4-3.7-2.1-5.5c-0.4-1-0.8-2-1.1-3.1c-0.1-0.4-0.3-0.8-0.3-1.3c0-0.3,0-0.7,0-0.9c0.1-0.3,0.3-0.5,0.3-0.8
        c0.3-0.3,0.5-0.6,0.7-0.9c0.5-0.7,1.1-1.3,1.6-1.9c0.3-0.3,0.5-0.6,0.8-0.9c0.2-0.3,0.5-0.6,0.7-0.9c0.2-0.3,0.4-0.6,0.6-0.9
        c0.3-0.3,0.4-0.7,0.6-1.1c0.2-0.4,0.2-0.8,0.4-1.2c0.1-0.6,0.2-1.2,0.2-1.8c0-0.5-0.1-1.1-0.2-1.7c-0.2-0.6-0.3-1.2-0.4-1.8
        c-0.2-0.6-0.4-1.2-0.6-1.7c1.1,0.3,2.1,0.7,3.1,1.1c1,0.4,2,0.9,3,1.3c0.6,0.3,1.2,0.7,1.8,0.9c0.6,0.3,1.2,0.7,1.7,1
        c1.9,1.1,3.9,2.3,5.7,3.4c1,5.4,2,11,2.9,16.5c0.9,5.5,1.8,11.1,2.6,16.6c1.2,7.9,2.3,15.8,3.4,23.6
        C140.7,405.1,141.7,412.5,142.7,420.1L142.7,420.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M117.9,320.5
        c2.6,0.5,4.9,1.5,7.2,2.4c1.4,1.3,2.8,2.6,4.1,5.4c1.2,2.8,2.2,7.2,3.1,11.6c-3.4-2.2-6.9-4.3-9.9-5.8c-3-1.5-5.6-2.4-8.2-3.3
        l-4.9-11.1C112.2,319.8,115.3,320,117.9,320.5L117.9,320.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M106.5,318.5
        c1,1.3,1.9,2.8,3,4.8c1.1,2,2.2,4.6,3.4,7.3l1.4,0.3l-4.9-11.1L106.5,318.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M46.1,320.9l-0.5,15.7
        c2.3-2.4,4.4-4.7,5.5-6.5c1.2-1.8,1.1-2.8,1.1-4v-10.3c-1.9-0.1-3.8-0.1-4.8,0.8C46.4,317.4,46.2,319.1,46.1,320.9L46.1,320.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M228.9,995
        c-1.2,8.1-2,11.2-3.3,14.5c-1.3,3.4-3,7-5,10.3l3.9-42.1c1.3-2.3,2.3-4.8,3-7.5c0.7-2.8,1-5.8,2.3-19.9c1.3-14.1,3.4-39.2,4.8-66
        c1.5-26.8,2.4-55.2,3-79.7c0.7-24.4,1.1-44.9,0.4-63.3c-0.8-18.4-2.6-34.8-5.7-48.9c-3-14.1-7.3-25.8-11.7-36.1
        c-4.4-10.2-8.9-19-14.3-28.2c-5.4-9.1-11.6-18.6-18-27.2c-6.5-8.6-13.3-16.4-20.5-23.6l-0.3-4.9c8.3,7.9,16.1,16.3,23.1,25.6
        c7,9.2,13.3,19.1,18.7,28.4c5.4,9.2,9.9,17.9,14.4,28.2c4.5,10.4,9.1,22.6,12.1,37c3.1,14.4,4.8,31.1,5.7,49.4
        c0.9,18.4,1.1,38.2,0.6,62.5c-0.6,24.1-1.9,52.6-3.5,79.6c-1.5,27-3.4,52.6-5,71.8C231.8,974.1,230.1,987,228.9,995L228.9,995z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M189.4,930.1
        c2.3,15.5,5.6,33.3,7.6,43.7c1.9,10.4,2.4,13.4,3.3,15.6c0.8,2.2,2,3.4,4.2,3c2.2-0.3,5.4-2.4,9.1-5.1c3.5-2.8,7.5-6,11.1-9.6
        c1.3-2.3,2.3-4.8,3-7.5c0.7-2.8,1-5.8,2.3-19.9c1.3-14.1,3.4-39.2,4.8-66c1.5-26.8,2.4-55.2,3-79.7c0.7-24.4,1.2-44.9,0.4-63.3
        c-0.8-18.4-2.6-34.8-5.7-48.9c-3-14.1-7.3-25.8-11.7-36.1c-4.4-10.2-8.9-19-14.3-28.2c-5.4-9.1-11.6-18.6-18-27.2
        c-6.5-8.6-13.3-16.4-20.5-23.6c2.8,58.6,5.7,117.3,7.7,162.5c2,45.1,3.3,76.8,4.5,99.3c1.3,22.5,2.5,35.6,3.9,48.9
        C185.4,901.3,186.9,914.6,189.4,930.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M235.6,654.4
        c-0.4,0.7-0.9,1.3-1.3,1.3c-0.4,0.1-0.8-0.3-1.2-0.8c-10.4-19.1-20.9-38.3-28.8-52.4c-8-14.2-13.5-23.2-18.9-32.4
        c-1.5-2.3-2.6-4.8-3.4-9.1c-0.8-4.4-1.5-10.5-1.8-15.3c-0.2-4.8-0.1-8.3,0.4-11.7l-2.8-4.9c-0.8,4.9-1.1,10-0.9,15.8
        c0.2,5.9,0.8,12.6,1.8,17.4c1,4.9,2.4,8.1,4.5,10.8c5.4,8.5,10.8,16.9,19,31.2c8.2,14.3,19.3,34.5,30.3,54.7
        c0.3,0.6,0.7,1.2,1.1,1.3c0.4,0.1,1.1-0.2,1.4-0.7l4.2-6.8l-0.9-2.8L235.6,654.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M235.6,654.4
        c-0.4,0.7-0.9,1.3-1.3,1.3c-0.4,0.1-0.8-0.3-1.2-0.8c-10.4-19.1-20.9-38.3-28.8-52.4c-8-14.2-13.5-23.2-18.9-32.4
        c-1.5-2.3-2.6-4.8-3.4-9.1c-0.8-4.4-1.5-10.5-1.8-15.3c-0.2-4.8-0.1-8.3,0.4-11.7c11,18.2,21.9,36.4,31.5,55.8
        c9.6,19.3,17.9,39.7,26.2,60.2L235.6,654.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M60.9,1112.6
        c0.8,1.2,1.7,1.6,2.4,1.8c0.8,0.2,1.6,0.1,2.4-0.3c0.8-0.4,1.7-1.1,2.5-1.3c0.8-0.3,1.5,0,2.5,0.6c0.9,0.6,2.3,1.5,4.2,2.3
        c1.8,0.7,4.4,1.3,6.9,1.3H89l-0.4-5c0-1.3-0.2-2.8-0.6-4.7c-0.5-1.9-1.2-4.4-2.3-6.5c-1.1-2.2-2.6-3.9-4.4-5.4
        c-4.4-3.1-8.7-6.2-12.9-9.6c-4.2-3.3-8.3-6.9-12.4-10.5l3.6,32.2C59.8,1109.4,60.3,1111.4,60.9,1112.6L60.9,1112.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M84.5,1110.1l4.1,1.8
        c0-1.3-0.2-2.8-0.6-4.7c-0.5-1.9-1.2-4.4-2.3-6.5c-1.1-2.2-2.6-3.9-4.4-5.4l0.7,9.4c0,1,0,2.1,0.4,2.9
        C82.9,1108.6,83.5,1109.6,84.5,1110.1L84.5,1110.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M83.3,1099.9
        c0.2,0.3,0.3,0.5,0.5,0.8c0.2,0.2,0.3,0.6,0.4,0.8c0.3,0.3,0.4,0.8,0.6,1.1c0.2,0.4,0.2,0.8,0.4,1.2c0.4,1,0.7,2.1,0.9,3.1
        c0.3,0.8,0.4,1.6,0.5,2.3l-1.5-0.7c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.2-0.4-0.4-0.6
        c-0.1-0.3-0.2-0.6-0.3-0.8c-0.2-0.8-0.2-1.4-0.2-2.1L83.3,1099.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M64.9,539
        c6-0.5,12.6-1.1,18.9-2.8c6.4-1.8,12.5-4.6,18.1-8.5c5.5-3.9,10.5-8.9,14.5-14.4c4-5.6,7.1-11.7,9.2-18.4c2-6.6,2.9-13.7,2.9-20.5
        c0-6.9-1-13.6-3-20c-2-6.4-5.1-12.6-9.1-18.1c-4-5.6-9-10.6-14.6-14.6c-5.5-4-11.7-7.1-18-9.1c-6.4-1.9-12.9-2.7-18.6-3.1
        c-5.8-0.4-10.7-0.5-15.6-0.1v12.7c5.5-1.8,11.5-2.9,17.4-2.9c5.9-0.1,11.7,0.8,17.3,2.5c5.5,1.8,10.9,4.4,15.7,8
        c4.9,3.4,9.1,7.8,12.7,12.6c3.4,4.8,6.1,10.1,7.9,15.7c1.8,5.4,2.6,11.2,2.6,16.9c0,5.7-0.8,11.3-2.6,16.9
        c-1.8,5.5-4.4,10.8-7.9,15.7c-3.5,4.8-7.8,9.1-12.7,12.6c-4.8,3.4-10.1,6.1-15.9,7.9c-5.9,1.8-12.3,2.7-18.4,2.4
        c-6.2-0.2-12.1-1.4-17.6-3.6v13.2C53.5,539.8,58.9,539.5,64.9,539L64.9,539z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M64.9,1002
        c5.2-0.2,11.7-1,18.1-2.8c6.4-1.8,12.5-4.6,18.1-8.6c5.5-3.9,10.5-8.9,14.5-14.5c4-5.5,7.1-11.7,9.2-18.3c2-6.6,3-13.7,2.9-20.6
        c0-6.9-1-13.5-3-20c-2-6.4-5.1-12.5-9.1-18.1c-4-5.5-9-10.5-14.5-14.6c-5.6-4-11.7-7.1-18-9c-6.4-1.8-12.8-2.5-18.4-2.6
        c-5.5-0.2-10.1,0.3-14.6,1.3v10.8c5.6-1.8,11.5-2.8,17.3-2.9c5.8,0,11.5,0.8,17,2.5c5.4,1.8,10.8,4.4,15.6,8
        c4.9,3.4,9.1,7.7,12.7,12.6c3.4,4.8,6.1,10.1,7.9,15.7c1.8,5.4,2.6,11.1,2.6,16.9c0,5.6-0.8,11.3-2.6,16.9
        c-1.8,5.4-4.4,10.8-7.9,15.6c-3.5,4.9-7.8,9.1-12.7,12.7c-4.8,3.4-10.1,6.1-15.6,7.9c-5.5,1.8-11.2,2.6-16.7,2.6
        c-5.4,0.1-10.6-0.8-15.5-2.3v10.2C55.9,1002,59.7,1002.3,64.9,1002L64.9,1002z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M112.7,507.4
        c-3.5,4.8-7.8,9.1-12.7,12.6c-4.8,3.4-10.1,6-15.6,7.8c-5.5,1.8-11.2,2.7-16.9,2.7c-5.7,0-11.4-0.9-16.9-2.7
        c-5.4-1.8-10.8-4.4-15.6-7.8c-4.9-3.5-9.1-7.8-12.7-12.6c-3.4-4.9-6.1-10.1-7.9-15.7c-1.8-5.5-2.6-11.2-2.6-16.9
        c0-5.7,0.8-11.4,2.6-16.9c1.8-5.5,4.4-10.9,7.9-15.7c3.5-4.8,7.8-9.1,12.7-12.6c4.8-3.5,10.2-6.2,15.6-8
        c5.5-1.8,11.2-2.6,16.9-2.6c5.6,0,11.3,0.8,16.9,2.6c5.4,1.8,10.8,4.4,15.6,8c4.9,3.4,9.1,7.8,12.7,12.6
        c3.4,4.8,6.1,10.1,7.9,15.7c1.8,5.4,2.6,11.2,2.6,16.9c0,5.7-0.8,11.3-2.6,16.9C118.8,497.2,116.1,502.5,112.7,507.4L112.7,507.4z
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M152.5,551.7
        c0,0.8,0,1.5-0.3,2.2c-0.2,0.5-0.4,0.9-0.9,1c-0.7,0.3-1.6,0.3-2.3,0c-1.3-0.3-2.3-0.9-3.4-1.7c-0.8-0.4-1.4-0.9-1.8-1.6
        c-0.4-0.8-0.6-1.8-0.6-2.9l-0.6-19.7c0-1.3,0-2.8,0.3-4.1c0.3-1.7,0.8-3.4,1.5-5c0.5-1.5,1.3-2.8,2-4.3c0.3-0.7,0.7-1.3,1.2-1.7
        c0.4-0.3,1-0.4,1.3-0.2c0.5,0.3,0.8,1,1,1.8c0.3,0.9,0.3,1.9,0.4,2.9C151.1,529.5,151.8,540.6,152.5,551.7L152.5,551.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M56.1,539.6l4.5,299.6c0,4.3,0.8,8.5,3,11.7c2.9,4.1,8,7,12.7,10.2c9.5,6.7,19.6,12.5,29.3,19c15,10.3,29.7,21,44.5,31.4
        c4.8,3.4,9.4,7.2,14.3,10.1c2.3,1.3,4.5,2.2,7,2.6c2.4,0.3,4.9,0.3,7.3-0.4c4.8-1.4,9.1-4.5,9.1-4.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M56.5,565.4c0,0,14.7-3.1,29.5-4.9c9-1.1,17.9-1.6,26.9-1.8c7.9-0.2,15.8-0.5,23.4,0.6c7.4,1.1,14.5,3.1,21.3,6
        c5.5,2.3,9.9,7.2,9.9,7.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M147.8,426.7c0,0,4.4,6.3,6.3,13.3c2.4,9.4,3.4,19.3,4.6,29.1c1.9,15.9,3.4,31.9,4.7,48c2.3,27.6,4.1,55.3,4.1,55.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="127.1" y1="399.1" x2="114.8" y2="434"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="176" y1="750.4" x2="238.7" y2="766.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M180.1,766.3
        c0,0,1,26.3,2.2,52.6c0.7,15.4,1.1,30.8,2.2,46.1c1,14.3,3,28.5,3,28.5l22.6,4.4l23-10.5c0,0,1.3-25.1,2.3-50.1
        c0.3-9.6,0.6-19,0.8-28.6c0.5-16.7,1-33.4,1-33.4L180.1,766.3z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="234" y1="896.5" x2="187.8" y2="919.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="108.4" y1="984.6" x2="154.4" y2="1047.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M59.3,748.8c0,0,23.6-4,47.4-4.9c15.3-0.7,30.7-0.3,45.9,1.2c12,1.1,23.5,5.4,23.5,5.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M172.8,612.2l5.8,123.2
        l58.5,20.6c0,0,0-24.1-3.5-47.7c-1.8-12.8-4.9-25.5-9-37.9c-3.2-9.8-7.8-19.1-12.4-28.4c-4.9-10.2-11.2-19.9-11.2-19.9
        L172.8,612.2z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="178.6" y1="735.5" x2="180.1" y2="766.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M59.1,327.6
        c0,2.3,0.3,4.5,1.4,6.4c1,1.8,2.7,3.4,4.7,4.2c6.8,2.8,14.5,3.9,14.5,3.9l-0.3-8.9l1.8-1.8c0,0,0.8-1,1.2-2.2
        c0.2-0.8,0.2-1.7,0.2-2.5v-9.1L59.1,320V327.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M79.5,333.2l1.8-1.8
        c0,0,1.7,6.5,2.1,13c1.3,16.2,1.3,32.4,1.3,32.4l-2.2-0.1c0,0-0.3-13.2-1.3-26.5c-0.3-4.1-1.4-8.1-1.4-8.1L79.5,333.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M94,327.9c0,0,2.2,9.1,3.5,18.2c0.9,6,1.3,12.1,1.8,18.2c0.3,3.1,0.5,6.2,0.3,9.2c0,1.5-0.1,3-0.8,3.6c-0.9,0.8-2.6,0.6-4.3,0.5
        l-9.8-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M53.8,349.3l7,16.2c1.2,2.8,2.5,5.6,4.8,7.2c2.6,1.8,6.1,2.5,9.6,3l7.3,1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M85.9,337.2
        c0,0.2,0.1,0.4,0.2,0.7c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.4,0.3,0.9,0.4,1.4,0.2c0.1,0,0.2-0.1,0.3-0.1
        c0.1,0,0.2-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.2-0.2c0.3-0.5,0.4-1,0.3-1.5c0-0.1-0.1-0.2-0.1-0.3
        c-0.1,0-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.5-0.3-1-0.3-1.5-0.2c-0.1,0-0.2,0-0.2,0.1
        c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0-0.1,0.1-0.2,0.2C85.9,336.4,85.8,336.8,85.9,337.2 M86.6,337.1
        c0,0.2,0.1,0.3,0.3,0.5c0.3,0.3,0.7,0.3,1,0.3c0.1,0,0.2-0.1,0.3-0.3c0.3-0.3,0.3-0.6,0.3-0.9c-0.1-0.2-0.1-0.3-0.3-0.4
        c-0.3-0.3-0.6-0.3-0.9-0.2c-0.2,0-0.3,0.1-0.4,0.2C86.6,336.5,86.6,336.8,86.6,337.1z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="70.2" y1="857" x2="70.5" y2="873.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M61.7,545.9c8.2,0.2,16.5-0.8,24-2.8c7.5-2.1,14.3-5.3,20.4-9.6c6.1-4.3,11.6-9.8,16-15.8c4.4-6.1,7.8-12.8,10.1-20.1
        c2.2-7.2,3.3-15,3.3-22.6c0-7.5-1.1-14.8-3.4-21.8c-2.3-7-5.6-13.8-10.1-19.9c-4.4-6.1-9.8-11.6-15.9-16
        c-6.1-4.4-12.8-7.8-19.8-9.9c-7-2.1-14.2-3-20.4-3.4c-6.3-0.5-11.7-0.5-17.1-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M62.5,1010.5c7.2-0.3,14.3-1.7,21.4-3.9c7-2.3,14-5.4,20.3-9.7c6.2-4.4,11.7-10,16.3-16.2c4.5-6.3,8-13.2,10.2-20.5
        c2.3-7.4,3.4-15.3,3.4-23.1c-0.1-7.7-1.2-15.2-3.4-22.3c-2.3-7.1-5.9-14-9.6-19.5c-3.7-5.4-7.7-9.6-12.3-13"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M166.7,990.3
        c-0.8,1.1-1.8,2.1-2.9,2.9c-1.2,0.8-2.4,1.5-3.7,1.9c-1.3,0.4-2.7,0.6-4,0.6c-1.3,0-2.7-0.2-3.9-0.6c-1.3-0.4-2.6-1.1-3.7-1.9
        c-1.2-0.8-2.2-1.8-3-2.9c-0.8-1.2-1.4-2.4-1.8-3.7c-0.4-1.3-0.6-2.7-0.6-4c0-1.3,0.2-2.7,0.6-3.9c0.4-1.3,1-2.6,1.8-3.7
        c0.8-1.2,1.8-2.2,3-3c1.1-0.8,2.3-1.4,3.7-1.8c1.3-0.4,2.6-0.6,3.9-0.6c1.3,0,2.7,0.2,4,0.6c1.3,0.4,2.5,1.1,3.7,1.8
        c1.1,0.8,2.1,1.8,2.9,3c0.8,1.1,1.3,2.3,1.8,3.7c0.4,1.3,0.7,2.6,0.7,3.9c0,1.3-0.3,2.7-0.7,4C168,987.9,167.5,989.2,166.7,990.3
        L166.7,990.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M142.8,337.3l-1.9,23.2c0,0.7-0.2,1.3-0.6,1.8c-0.3,0.4-0.8,0.5-0.8,0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M164.8,534.9c0,0,2.8,1.3,4.9,3.4c4,4.2,7.2,9.2,7.2,9.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M141.5,353.8c0,0,3.4,7.3,5.4,14.9c3.9,14.8,7.5,29.8,10.5,44.9c4,21.2,7.3,42.6,10.2,64.1c4.5,33.8,7.6,67.7,7.6,67.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M63.7,607.5c0,0,6,42.4,8.6,85c1.8,29.8,2.6,59.6,2.3,89.5c-0.2,10.6-2.1,21-3.6,31.4C69,826.8,66,839.9,66,839.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M179.7,628.7l2.6-0.2
        c0,0,3.3-0.3,5.9-1.8c2.1-1.3,4.1-2.6,5-4.5c1.3-2.8,1.6-6,1.3-9.2c-0.3-3.2-1.3-6.4-2.8-9.3c-1-2.1-2.5-3.9-4.2-5.6
        c-1.6-1.5-3.4-2.8-5.4-3.7c-1.8-0.9-4.2-0.9-4.2-0.9l0.3,6.6L179.7,628.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M179.7,628.7l2.6-0.2
        l-1-23.3c-0.1-1.2-0.5-2.3-1.1-3.3c-0.7-1.1-1.9-1.8-1.9-1.8L179.7,628.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M179.7,628.7
        c0,0-4.6,0.5-6.8-1.6c-1.6-1.6-2.2-4.4-2.3-7.2c-0.4-5.6-0.2-11.2,0.7-16.8c0.4-2.9,1.5-5.7,3.1-8c1-1.5,3.6-1.7,3.6-1.7l0.3,6.6
        L179.7,628.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M156.8,707.6
        c-0.2,2.2,0.3,4.4,0.4,6.8c0.2,2.4,0.3,4.9,0.5,7.3c0.1,0.8,0.2,1.5,0.5,2.2c0.3,0.8,1.1,1.6,1.1,1.6s0,4.9-0.4,9.6
        c-0.1,1.2,0,2.3-0.6,2.9c-0.5,0.7-1.6,0.9-2.7,0.9c-0.6,0.1-1.1-0.3-1.4-0.8c-0.5-0.5-0.7-1.3-0.8-2.1c-0.5-3.4-0.8-6.7-1.1-10.1
        c-0.4-5.1-0.7-10.2-0.9-15.3c-0.2-2.3-0.2-4.6-0.2-7c0-1.4-0.3-2.9,0.1-4.2c0.2-0.8,0.8-1.4,1.4-1.6c1.2-0.3,2.4-0.3,3.6,0.1
        c0.5,0.3,0.8,0.8,1,1.5c0.8,2.9,0.8,5.8,0.8,5.8C158.1,705.3,157,706.4,156.8,707.6L156.8,707.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M163,880.8
        c-0.2,2.2,0.3,4.4,0.4,6.8c0.2,2.4,0.3,4.9,0.5,7.3c0.1,0.8,0.2,1.5,0.4,2.1c0.4,0.9,1.2,1.7,1.2,1.7s-0.1,4.8-0.5,9.6
        c-0.1,1.2,0,2.3-0.5,2.9c-0.6,0.7-1.7,0.8-2.7,0.9c-0.6,0-1.2-0.3-1.5-0.8c-0.4-0.6-0.7-1.3-0.8-2.1c-0.5-3.4-0.8-6.8-1.1-10.1
        c-0.4-5.1-0.8-10.2-1-15.3c-0.1-2.3-0.2-4.6-0.2-7c0-1.4-0.3-2.9,0-4.2c0.2-0.8,0.8-1.4,1.5-1.7c1.1-0.3,2.4-0.3,3.5,0.2
        c0.6,0.3,0.9,0.8,1.1,1.5c0.7,2.8,0.8,5.8,0.8,5.8S163.1,879.4,163,880.8L163,880.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M152.4,726.3c-0.5-0.2-0.9-0.4-1.3-0.7c-0.3-0.3-0.7-0.5-1-0.8c-0.3-0.3-0.7-0.6-1-0.9c-0.2-0.3-0.6-0.8-0.8-1.2
        c-0.3-0.3-0.5-0.8-0.8-1.3c-0.3-0.4-0.5-0.9-0.7-1.3c-0.2-0.5-0.3-1-0.5-1.7c-0.1-0.6-0.3-1.4-0.3-2.2c-0.1-0.7,0-1.6,0-2.3
        c0.1-0.7,0.2-1.2,0.3-1.7c0.1-0.5,0.3-1,0.4-1.4c0.2-0.5,0.3-1,0.6-1.4c0.2-0.4,0.4-0.8,0.7-1.3c0.3-0.3,0.5-0.8,0.8-1.1
        c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.3,0.7-0.5,0.9-0.8c0.3-0.2,0.5-0.3,0.8-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M158.5,899.5c-0.4-0.3-0.8-0.4-1.3-0.7c-0.4-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.6-0.7-0.9-1c-0.3-0.3-0.6-0.7-0.9-1.1
        c-0.3-0.4-0.5-0.8-0.8-1.3c-0.3-0.4-0.4-0.9-0.6-1.4c-0.2-0.4-0.3-0.9-0.5-1.6c-0.2-0.7-0.3-1.4-0.3-2.2c-0.1-0.8-0.1-1.6,0-2.3
        c0-0.7,0.1-1.2,0.2-1.7c0.2-0.5,0.2-1,0.4-1.5c0.2-0.4,0.3-0.9,0.6-1.3c0.3-0.4,0.4-0.8,0.7-1.3c0.3-0.4,0.6-0.8,0.8-1.1
        c0.3-0.3,0.6-0.6,0.9-0.9c0.2-0.3,0.6-0.5,0.8-0.7c0.2-0.2,0.5-0.3,0.8-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M158.1,705.2c0.2,0.2,0.4,0.2,0.7,0.4c0.3,0.2,0.6,0.4,0.9,0.8c0.3,0.3,0.7,0.6,1,0.9c0.3,0.3,0.6,0.7,0.8,1.1
        c0.3,0.4,0.6,0.8,0.8,1.3c0.3,0.5,0.5,0.9,0.7,1.4c0.2,0.5,0.3,1,0.4,1.5c0.4,1.5,0.6,3.1,0.4,4.7c-0.1,0.5-0.2,1-0.2,1.5
        c-0.1,0.5-0.3,1-0.4,1.5c-0.2,0.5-0.3,0.9-0.5,1.3c-0.2,0.5-0.5,0.9-0.8,1.3c-0.2,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.6,0.7-0.9,0.9
        c-0.3,0.2-0.5,0.4-0.8,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M164.2,878.3c0.3,0.1,0.4,0.3,0.7,0.4c0.3,0.2,0.7,0.4,1,0.7c0.3,0.3,0.6,0.7,0.9,1c0.3,0.3,0.6,0.7,0.8,1.1
        c0.3,0.4,0.5,0.8,0.8,1.3c0.3,0.4,0.4,0.9,0.6,1.4c0.2,0.4,0.3,0.9,0.5,1.4c0.4,1.6,0.5,3.2,0.3,4.8c0,0.5-0.1,1-0.2,1.5
        c-0.2,0.5-0.2,1-0.4,1.5c-0.2,0.4-0.3,0.9-0.6,1.3c-0.3,0.4-0.4,0.8-0.7,1.3c-0.3,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.6,0.6-0.8,0.8
        c-0.3,0.2-0.6,0.5-0.8,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M152.5,728.1c0,0,1.8,0.1,3.5,0c1.7-0.1,3.4-0.3,3.4-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M158.7,901.2c0,0,1.8,0.1,3.5,0c1.7-0.1,3.3-0.3,3.3-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M154.2,732.3
        c0-0.3,0-0.4,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.3-0.3,0.6-0.4c0.3-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0,0.8,0.1
        c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.2,0.3,0.3,0.3,0.6c0.1,0.3,0.2,0.4,0.2,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.3,0.6
        c-0.2,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.2c-0.2,0-0.4-0.1-0.7-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.2-0.3-0.3-0.4-0.6
        C154.2,732.8,154.2,732.5,154.2,732.3L154.2,732.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M61,1018.3c0,0,2.2,28.8,6.3,56.9c0.4,2.7,1.5,5,3,7.1c1.5,1.9,4.1,3.1,6.2,4.9c3.4,2.8,6.8,5.4,9.6,8.6c1.8,1.8,3.3,4,4.4,6.3
        c1.1,2.4,1.5,5,2,7.6c0.7,3.4,0.8,7,0.8,7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M63.8,319.5v4.7c0,2.6,0.4,5.2,1.2,7.6c0.5,1.5,1.2,2.9,2.3,3.7c2.9,1.8,6.4,3,9.8,4.2c0.5,0.2,0.9-0.2,0.9-0.7V318"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="74.1" y1="318.4" x2="74.1" y2="338.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="72.2" y1="318.6" x2="72.2" y2="337.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="69.5" y1="318.9" x2="69.5" y2="336.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="67.7" y1="319.1" x2="67.7" y2="335.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="49.2" y1="336.2" x2="50.2" y2="399"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M58.1,1093.6c0,0,0.9,1.8,2.4,2.8c3.6,2.3,7.9,3.6,11.5,5.9c1.5,0.9,2.8,2.2,3.5,3.7c0.4,0.8,0.2,1.8-0.1,2.9
        c-0.3,0.7-0.9,1.3-1.6,1.8c-0.7,0.4-1.3,0.7-2.1,0.6c-1.8-0.2-3.8-0.6-5.4-1.4c-3.8-2-7-5-7-5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M71,1105.9
        c0,1.8-2.4,2.3-5.4,1.3c-2.9-1-5.4-3.3-5.4-5c0-1.8,2.4-2.4,5.4-1.3C68.6,1101.9,71,1104.2,71,1105.9L71,1105.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M42.3,558.3c0,0,3.8,0.9,4.9,3.5c1.8,4.2,2.5,9.1,2.6,14l3.5,260.9c0.1,5.5-0.9,11-2.9,16c-2.3,6.2-7.6,11.2-7.6,11.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="114.5" y1="322.4" x2="114.5" y2="327.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="117.4" y1="323.5" x2="117.4" y2="328.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="120.3" y1="324.6" x2="120.3" y2="329.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="123.1" y1="325.8" x2="123.1" y2="330.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="126" y1="326.8" x2="126" y2="331.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="128.8" y1="327.9" x2="128.8" y2="333.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="110.2" y1="319.8" x2="110.2" y2="319.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="113.1" y1="319.9" x2="113.1" y2="321.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="115.9" y1="320.1" x2="115.9" y2="322.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="118.8" y1="320.6" x2="118.8" y2="323.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="121.7" y1="321.6" x2="121.7" y2="324.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="124.6" y1="322.7" x2="124.6" y2="325.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="127.4" y1="325.2" x2="127.4" y2="326.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="111.7" y1="321.2" x2="111.7" y2="325"/>
      
        <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" points="
        109.4,320.1 110.2,319.9 111.7,321.2 113.1,321.1 114.5,322.4 115.9,322.2 117.4,323.5 118.8,323.3 120.3,324.6 121.7,324.4 
        123.1,325.8 124.6,325.5 126,326.8 127.4,326.7 128.8,327.9 129.1,327.9 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="113.1" y1="327.6" x2="113.1" y2="328.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="115.9" y1="328.7" x2="115.9" y2="331.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="118.8" y1="329.9" x2="118.8" y2="332.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="121.7" y1="331" x2="121.7" y2="333.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="124.6" y1="332.1" x2="124.6" y2="335.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="127.4" y1="333.2" x2="127.4" y2="336.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="130.3" y1="334.3" x2="130.3" y2="338.5"/>
      
        <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" points="
        112.7,327.2 113.1,327.6 114.5,327.4 115.9,328.7 117.4,328.5 118.8,329.9 120.3,329.6 121.7,331 123.1,330.8 124.6,332.1 
        126,331.9 127.4,333.2 128.8,333.1 130.3,334.3 131.1,334.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M93.1,493.2
        c-2,2.7-4.4,5.2-7.1,7.1c-2.8,2-5.7,3.5-8.9,4.5c-3.1,1-6.3,1.5-9.5,1.5s-6.4-0.5-9.6-1.5c-3.1-1-6.1-2.5-8.9-4.5
        c-2.7-2-5.1-4.4-7.1-7.1c-2-2.7-3.5-5.7-4.5-8.9c-1-3.1-1.4-6.3-1.4-9.5s0.4-6.4,1.4-9.6c1-3.1,2.5-6.1,4.5-8.8
        c2-2.8,4.4-5.2,7.1-7.2c2.8-2,5.8-3.5,8.9-4.5c3.2-1,6.4-1.4,9.6-1.4s6.4,0.4,9.5,1.4c3.2,1,6.1,2.5,8.9,4.5
        c2.7,2,5.2,4.4,7.1,7.2c1.9,2.7,3.5,5.7,4.5,8.8c1,3.1,1.5,6.4,1.5,9.6s-0.5,6.4-1.5,9.5C96.5,487.5,95,490.5,93.1,493.2
        L93.1,493.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M112.3,969.8
        c-3.5,4.8-7.8,9.1-12.7,12.6c-4.8,3.4-10.1,6-15.6,7.8c-5.5,1.8-11.2,2.7-16.9,2.7c-5.7,0-11.4-0.9-16.9-2.7
        c-5.4-1.8-10.8-4.4-15.6-7.8c-4.9-3.5-9.1-7.8-12.7-12.6c-3.4-4.9-6.1-10.1-7.9-15.7c-1.8-5.5-2.6-11.2-2.6-16.9
        c0-5.7,0.8-11.4,2.6-16.9c1.8-5.5,4.4-10.9,7.9-15.7c3.5-4.8,7.8-9.1,12.7-12.6c4.8-3.5,10.2-6.2,15.6-8
        c5.5-1.8,11.2-2.6,16.9-2.6c5.6,0,11.3,0.8,16.9,2.6c5.4,1.8,10.8,4.4,15.6,8c4.9,3.4,9.1,7.8,12.7,12.6
        c3.4,4.8,6.1,10.1,7.9,15.7c1.8,5.4,2.6,11.2,2.6,16.9c0,5.7-0.8,11.3-2.6,16.9C118.4,959.7,115.7,965,112.3,969.8L112.3,969.8z"
        />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M92.7,955.7
        c-2,2.7-4.4,5.2-7.1,7.1c-2.8,2-5.7,3.5-8.9,4.5c-3.1,1-6.3,1.5-9.5,1.5c-3.2,0-6.4-0.5-9.6-1.5c-3.1-1-6.1-2.5-8.9-4.5
        c-2.7-2-5.1-4.4-7.1-7.1c-2-2.7-3.5-5.7-4.5-8.9c-1-3.1-1.4-6.3-1.4-9.5c0-3.2,0.4-6.4,1.4-9.6c1-3.1,2.5-6.1,4.5-8.8
        c2-2.8,4.4-5.2,7.1-7.2c2.8-2,5.8-3.5,8.9-4.5c3.2-1,6.4-1.4,9.6-1.4c3.2,0,6.4,0.4,9.5,1.4c3.2,1,6.1,2.5,8.9,4.5
        c2.7,2,5.2,4.4,7.1,7.2c1.9,2.7,3.5,5.7,4.5,8.8c1,3.1,1.5,6.4,1.5,9.6c0,3.2-0.5,6.4-1.5,9.5C96.2,949.9,94.7,953,92.7,955.7
        L92.7,955.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M106.4,374.9
        c0.2,0.3,0.3,0.6,0.6,0.8c0.3,0.3,0.7,0.4,1,0.4c1.4,0.1,2.8,0,4.2-0.1c0.3,0,0.6,0,0.8-0.3c0.2-0.2,0.2-0.5,0.1-0.8
        c-0.3-1.1-0.8-2.2-1.3-3.2c-0.3-0.3-0.6-0.7-0.9-0.9c-0.3-0.2-0.8-0.3-1.2-0.4c-1.3-0.3-2.5-0.5-3.7-0.5c-0.4,0-0.8,0.2-0.8,0.5
        c-0.2,0.5,0,1.1,0.2,1.7C105.5,373,106,374,106.4,374.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M247.6,982.1c0,0-0.6,7.6-1.2,15.2c-0.4,5.3-0.9,10.5-0.9,10.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M171.3,1087.1
        c-0.2,4-0.9,8-1.8,12c-0.5,2.3-1.1,4.6-2.3,6.2c-0.8,1-2.2,1.3-3.6,1.5c-2.3,0.3-4.8,0.5-7.1,0.2c-1.2-0.2-2.3-0.7-3-1.5
        c-1.3-1.3-2.2-3-2.7-4.9c-1.6-6.1-2.9-12.4-3.7-18.7c-0.3-2.7-0.3-5.4,0.1-8c0.5-3.8,1.5-7.5,2.5-11.1c1.4-5.3,3.1-10.6,4.8-15.8
        c2-6.3,4.1-12.6,6.5-18.6c0.7-1.7,1.5-3.2,2.7-4.4c1.7-1.8,3.8-3.1,5.9-4.4c0.6-0.3,1.2-0.5,1.7-0.2c0.6,0.3,0.9,1.1,1.2,1.8
        c0.3,1.3,0.3,2.5,0.3,3.8C172.4,1045.8,172.1,1066.5,171.3,1087.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M170.4,1021
        c0.1,0.1,0.2,0.3,0.2,0.3c0.1,0.3,0.2,0.6,0.2,0.8c0.1,0.3,0.1,0.6,0.1,0.8c0.1,0.7,0.1,1.3,0.1,2c-0.2,10.3-0.3,20.7-0.5,31
        c-0.1,5.2-0.3,10.3-0.3,15.5c-0.2,5.1-0.4,10.3-0.6,15.4c-0.1,1.3-0.2,2.4-0.3,3.7c-0.1,1.2-0.3,2.4-0.5,3.6c-0.2,1-0.4,2-0.6,3
        c-0.2,1-0.5,2-0.7,3c-0.2,0.7-0.3,1.3-0.6,2c-0.2,0.3-0.3,0.7-0.5,1c-0.2,0.3-0.3,0.7-0.6,0.9c0,0.1-0.1,0.1-0.2,0.2
        c0,0.1-0.1,0.1-0.2,0.1c-0.3,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5,0.1-0.8,0.2c-0.8,0.1-1.7,0.3-2.4,0.3c-0.8,0.1-1.7,0.1-2.5,0.1
        c-0.4,0-0.8,0-1.3,0c-0.4,0-0.8-0.1-1.2-0.2c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.6-0.3-0.8-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
        c-0.5-0.7-1-1.3-1.3-2c-0.4-0.7-0.7-1.4-0.9-2.2c-0.8-3.1-1.5-6.1-2.1-9.2c-0.6-3.1-1.1-6.2-1.5-9.3c-0.2-1.3-0.2-2.5-0.2-3.8
        c0-1.3,0.2-2.5,0.2-3.8c0.2-0.8,0.3-1.5,0.4-2.3c0.2-0.8,0.3-1.5,0.5-2.3c0.5-2,1-4.1,1.6-6.1c0.8-2.6,1.5-5.3,2.3-7.9
        c0.8-2.6,1.7-5.2,2.5-7.8c1.3-3.9,2.5-7.8,3.9-11.7c0.4-1.2,0.8-2.3,1.3-3.4c0.5-1.2,0.9-2.4,1.3-3.4c0.2-0.5,0.4-1.1,0.7-1.5
        c0.2-0.5,0.6-1,0.9-1.4c0.1-0.2,0.3-0.3,0.3-0.5c0.2-0.2,0.2-0.3,0.4-0.4c0.3-0.3,0.7-0.7,1.1-1c0.3-0.3,0.8-0.6,1.2-0.9
        c0.5-0.4,1-0.8,1.6-1.2C169.3,1021.8,169.8,1021.4,170.4,1021L170.4,1021z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M245.6,981.7
        c1.8-18.9,2.8-37.8,3.6-56.7c1.8-44.4,3-88.8,3.8-133.2c0.3-15.9-0.1-31.9-0.6-47.8c-0.3-8.5-0.8-16.9-1.7-25.4
        c-0.8-8-1.8-16-3.4-23.9c-1.5-7.7-3.5-15.4-6.1-22.8c-1.1-3.2-2.7-6.1-4.6-8.5c-0.6-0.7-1.9,0.4-1.9,0.4s3.3,9.9,5.7,20.1
        c1.8,7.8,2.8,15.8,2.8,15.8s1.6,9.5,2.5,19c0.8,7.8,1.4,15.6,1.7,23.5c0.6,15.8,1.1,31.7,0.8,47.5c-0.4,31.4-1.2,62.6-2.7,93.9
        c-2.1,43.2-6,86.3-6,86.3l-1.1,15.7L237,1003c0,0,1.7-0.1,2.6-1c1.3-1.4,2.5-3.1,3.1-5C244.2,992,245.2,986.9,245.6,981.7
        L245.6,981.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M243.1,699.8c0,0,3,3.4,3.7,7.5c1.9,11.5,2.8,23.2,3.4,35c0.8,15.8,1.2,31.7,0.9,47.5c-0.7,37.2-2.1,74.4-3.5,111.6
        c-0.7,17.3-1.3,34.5-2.7,51.6c-0.4,5.1-1.3,10.1-2.8,14.8c-0.5,1.5-2.5,2.1-2.5,2.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="232.5" y1="659.1" x2="234.6" y2="663.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M237,1003c0,0-1.2,13.5-2.9,26.9c-1.1,8.4-2.7,16.7-2.7,16.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M221.8,1017.8c0,0,2.5,5.8,4.5,11.8c1.8,5.5,3.7,11.2,5.1,16.9c3,12.1,5.3,24.3,5.3,24.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M226.4,1007.6l6.7-3.2c1.6-0.8,3.4-1.2,4.5-0.4c1.7,1.1,2.8,3.4,3.9,5.7c1.9,3.7,3.1,7.8,3.1,7.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M191.2,919.5
        c0,0,1.3,8.6,2.7,17.1c1.3,8.1,2.8,16,4.2,24c1.3,7,2.3,14.2,3.9,21.1c0.4,2.1,1.1,4.2,2.2,5.5c0.6,0.7,1.5,0.8,2.4,0.3
        c3.6-1.9,7-4.4,10.4-7.1c2.6-2,5.4-4,7-6.6c1.8-2.9,2.6-6.4,3.1-9.9c1.4-9.7,1.9-19.7,2.8-29.6c1.4-17.6,2.5-35.1,2.5-35.1
        L191.2,919.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M187.5,893.6c0,0,1.1,7.9,2.2,15.7c0.8,5.1,1.5,10.2,1.5,10.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M167.7,1087.3
        c-0.1,2.7-0.3,5.3-0.8,7.9c-0.3,1.3-0.7,2.5-1.3,3.6c-0.3,0.8-1,1.3-1.8,1.8c-0.4,0.3-0.9,0.5-1.3,0.3c-0.3-0.1-0.5-0.6-0.7-1
        c-1-5.4-1.8-10.7-2.7-16.1c-0.2-0.9-0.2-1.8-0.1-2.6c0.1-0.3,0.4-0.7,0.8-0.9c2-1.2,4.1-2.3,6.2-3.4c0.5-0.2,0.9-0.4,1.3-0.2
        c0.3,0.2,0.5,0.8,0.5,1.3C167.9,1081.1,167.8,1084.2,167.7,1087.3L167.7,1087.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M163.3,1098.8
        c-0.4-2.3-0.9-4.8-1.3-7.2c-0.5-2.9-0.9-5.7-1.4-8.6c0-0.3-0.1-0.8,0-1.2c0,0,0-0.1,0.1-0.1c0.8-0.5,1.8-1.1,2.7-1.6
        c0.9-0.5,1.8-1,2.8-1.5c0.1,1.3,0.1,2.7,0.1,4c0,2.2-0.2,4.4-0.3,6.5c-0.1,1.3-0.3,2.6-0.4,3.9c-0.1,0.7-0.2,1.3-0.3,1.8
        c0,0.4-0.1,0.8-0.3,1.1c-0.1,0.3-0.2,0.7-0.3,1c-0.1,0.3-0.2,0.7-0.5,1.1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.3
        C163.5,1098.7,163.4,1098.8,163.3,1098.8L163.3,1098.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M148.8,1092.4c0,0,5.3-1.2,10.4-2.9c6.2-2,12.2-4.7,12.2-4.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M99.6,1113.5v-1.1c0-1.1,0-3.6,0.7-5.1c0.5-1.2,1.6-2,2.8-2.7c2.2-1.1,4.7-1.6,7.1-2.3c8.2-2.4,16.5-4.7,24.8-6.9
        c6.8-1.8,13.8-3.1,13.8-3.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="138.4" y1="1094.6" x2="138.7" y2="1110.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M112.5,1009.9c0,0,0.8,34.3,2.9,68.3c0.7,9.7,2.7,19.4,2.7,19.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M171.4,1084.8c0,0,4-1.4,6.9-4.2c2.2-2.1,3.7-4.9,5.4-7.5c6.9-10.6,13.2-21.5,13.2-21.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M227.3,1039.4c0,0-1.8-2.1-4-2.8c-1.8-0.6-3.9-0.5-5.9-0.3c-5.7,0.5-11.4,1.8-11.4,1.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M149.9,430c0,0,6.1,58.3,10.3,116.8c3.4,47.8,5.6,95.6,8,143.4c3.4,66.8,6.9,133.6,9.5,200.5c1.3,34.5,1.7,68.9,2.3,103.3
        c0.7,31,0.9,62.1,1.7,93.1c0.3,13.1,1.3,26.2,1.3,26.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M171,1024.5c0,0-3.4,2.6-6.1,5.6c-0.6,0.8-1,1.7-1.2,2.6c-0.3,1-0.2,2-0.2,3v23.9c0,0.5,0,1-0.3,1.3c-0.3,0.5-0.8,0.9-1.4,1.3
        c-2.6,1.7-5.4,3.1-7.9,4.9c-1.3,0.9-2.2,2.3-3.3,3.5c-1,1.2-1.8,2.6-1.8,2.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M67.1,1018.7c0,0,4.4,1.7,6.5,5c2.3,3.4,3.5,7.6,4.6,11.9c3.4,13.7,6.3,27.8,9,41.8c0.3,1.6,0.5,3.1,0,4.4c-0.3,0.7-1.2,1-2.2,1
        c-1.8,0.1-3.8,0-5.4-0.7c-1.8-0.8-3.4-2.3-4.9-3.8c-1.7-1.6-2.8-3.8-2.8-3.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M101.7,1112.9v-5.1c0-0.5,0-1.1,0.1-1.6c0.2-0.6,0.5-1.1,0.5-1.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M147.6,351.2c0,0,11,32.6,17.5,66.1c8.4,43.6,12.5,88.1,12.5,88.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="165.9" y1="1112.2" x2="165.5" y2="1106.3"/>
    </g>
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M870.7,532.6
        c-0.9-0.9-2.8-0.8-4.8-0.7c-5.4-0.1-10.9-0.4-16.8-0.9c-6-0.5-12.6-1.1-18.9-2.8c-6.4-1.8-12.5-4.6-18.1-8.5
        c-5.5-3.9-10.5-8.9-14.5-14.4c-4-5.6-7.1-11.7-9.2-18.4c-2-6.6-2.9-13.7-2.9-20.5c0-6.9,1-13.6,3-20c2-6.4,5.1-12.6,9.1-18.1
        c4-5.6,9-10.6,14.6-14.6c5.5-4,11.7-7.1,18-9.1c6.4-1.9,12.9-2.7,18.6-3.1c5.8-0.4,10.7-0.5,15.6-0.1c2.3-0.2,4.4-0.3,5.7-1.3
        c1.2-1,1.4-2.8,1.6-4.7l-3.3-66.9c-2.3-2.4-4.4-4.7-5.5-6.5c-1.2-1.8-1.1-2.8-1.1-4v-10.3c-1.6,0-3.3,0-4.4,0.4
        c-1.2,0.3-2.4,0.8-2.4,1.8v1.9l-23.6-2.4c-0.2-0.7-0.3-1.3-1-1.6c-0.6-0.2-1.7-0.2-2.7-0.2h-14.8c-1.1,0-2.1,0.2-3,0.6
        c-0.9,0.4-1.6,1.3-2.3,2.1l-2.8,1.3c-2.9,0.1-5.9,0.3-8.6,0.8c-2.6,0.5-4.9,1.5-7.2,2.4c-0.7-0.6-1.3-1.2-2.2-1.1
        c-0.9,0.1-2.1,0.8-3.2,1.5c-6.8,5.3-12.2,12.2-17.3,23.3c-5,11.2-9.8,26.8-14,43.8c-4.2,17-7.8,35.5-10.7,54.8
        c-2.9,19.4-5,39.6-6,50.8c-1,11.3-0.9,13.6-0.6,17.6c0.3,4,0.8,9.7,1.4,15.4c0.8,4.9,1.1,10,0.9,15.8c-0.2,5.9-0.8,12.6-1.8,17.4
        c-1,4.9-2.4,8.1-4.5,10.8c-5.4,8.5-10.8,16.9-19,31.2c-8.2,14.3-19.3,34.5-30.3,54.7c-0.3,0.6-0.7,1.2-1.1,1.3
        c-0.4,0.1-1.1-0.2-1.4-0.7l-4.2-6.8c-3.8,9.3-6.5,18.9-9,31.4c-2.4,12.6-4.4,27.9-5.3,47.5c-0.9,19.5-0.8,43.2-0.7,63.2
        c0,19.9-0.2,36.3,0.3,58.1c0.5,21.8,1.8,49.1,2.8,71.6c1.1,22.4,2.1,39.9,3.3,57.4c-0.3,0.9-0.6,1.9-1,3.8
        c-0.5,1.8-1.2,4.5-1.8,7.2c-0.8,2.7-1.6,5.4-2.2,7.2c-0.7,1.8-1.1,2.8-1.3,3.6c-0.2,0.8-0.4,1.4-0.4,2c0.1,0.7,0.3,1.3,0.6,1.8
        c0.3,0.5,0.8,0.8,1.5,0.8c0.7-0.2,1.5-0.8,2.3-1.1c0.8-0.4,1.7-0.4,2.4-0.3c0.8,0.1,1.4,0.3,2,0.7l0.8,9.7
        c0.7,8.1,1.6,16.3,2.6,24.6c1.1,8.3,2.3,16.7,3.4,22c1.1,5.3,2,7.4,3.3,9.3c2-9.9,4-19.8,6.5-28.7c2.4-8.9,5.3-16.9,8.1-24.8
        c4.9,5.9,9.8,11.7,13.8,17c3.9,5.3,7,10,10,14.7c2.6,4.8,4.7,9.9,6.6,16.5c1.9,6.6,3.8,14.8,4.6,22.4c0.8,7.6,0.7,14.8-0.4,21.8
        c1.7,0.9,3.4,1.3,6.1,1.3c2.6-0.1,6.1-0.7,8.6-1c2.5-0.4,3.9-0.6,5.9-0.4c2,0.2,4.5,0.8,6.9,1.5c2.4,0.7,4.7,1.3,7,1.8
        c2.3,0.5,4.7,0.8,6.5,1c1.8,0.2,3.3,0.3,4.4-0.1c1-0.4,1.7-1.3,1.8-2.2l0.2-3.9c0.1-1,0.9-1.9,2.9-2.1c2-0.2,5.1,0.4,8.2,0.7
        c3.1,0.3,6.2,0.2,8.1,0.3c2,0.1,2.8,0.4,3.3,1c0.5,0.5,0.6,1.3,1.1,1.8c0.5,0.5,1.3,0.3,2.1,0.4l9.1,0.2c1.4,0,2.9,0.2,4.4,0.9
        c1.5,0.8,3.2,1.9,4.6,2.6c1.5,0.7,2.7,0.8,3.8,0.8h1.9l0.4-5c0-1.3,0.2-2.8,0.6-4.7c0.5-1.9,1.2-4.4,2.3-6.5
        c1.1-2.2,2.6-3.9,4.4-5.4c4.4-3.1,8.7-6.2,12.9-9.6c4.2-3.3,8.3-6.9,12.4-10.5l7.7-68.4c0.2-1.8,0.1-3.8-0.5-4.7
        c-0.7-0.9-2-0.8-3.4-0.8c-3.7,0.7-7.5,0.9-12.7,0.7c-5.2-0.2-11.7-1-18.1-2.8c-6.4-1.8-12.5-4.6-18.1-8.6
        c-5.5-3.9-10.5-8.9-14.5-14.5c-4-5.5-7.1-11.7-9.2-18.3c-2-6.6-3-13.7-2.9-20.6c0-6.9,1-13.5,3-20c2-6.4,5.1-12.5,9.1-18.1
        c4-5.5,9-10.5,14.5-14.6c5.6-4,11.7-7.1,18-9c6.4-1.8,12.8-2.5,18.4-2.6c5.5-0.2,10.1,0.3,14.6,1.3c2.2-0.1,4.4-0.3,5.6-1.3
        c1.2-0.9,1.7-2.8,1.7-4.7l0.5-322.9C871.7,535.5,871.7,533.5,870.7,532.6L870.7,532.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M720.3,1074.6
        c-2.4-8.8-5.7-17.3-9-23.9c-3.4-6.6-6.8-11.4-9.6-14.1c-2.8-2.8-5.1-3.5-7.3-3c-2.1,0.5-4.4,1.9-5,4.1l-4.2,17.2l-2.6,6.8
        c-0.5,1.3-1.3,2.6-2,3.2c-0.7,0.6-1.3,0.5-1.9,0.4c2-9.9,4-19.8,6.5-28.7c2.4-8.9,5.3-16.9,8.1-24.8c4.9,5.9,9.8,11.7,13.8,17
        c3.9,5.3,7,10,10,14.7c2.6,4.8,4.7,9.9,6.6,16.5c1.9,6.6,3.8,14.8,4.6,22.4c0.8,7.6,0.7,14.8-0.4,21.8l-3.1-2.4
        C724.2,1092.7,722.7,1083.5,720.3,1074.6L720.3,1074.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M720.3,1074.6
        c-2.4-8.8-5.7-17.3-9-23.9c-3.4-6.6-6.8-11.4-9.6-14.1c-2.8-2.8-5.1-3.5-7.3-3c-2.1,0.5-4.4,1.9-5,4.1l-4.2,17.2
        c5.8,8.4,11.7,16.8,18.3,24.6c6.5,7.8,13.9,15,21.3,22.2C724.2,1092.7,722.7,1083.5,720.3,1074.6L720.3,1074.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M784.7,320.3
        c1.3-2.8,2.7-4.1,4.1-5.4c-0.7-0.6-1.3-1.2-2.2-1.1c-0.9,0.1-2.1,0.8-3.2,1.5c-1.7,2.4-2.8,5.2-4.4,12.9
        c-1.6,7.7-3.7,20.3-5.9,36.2c-2.3,15.8-4.8,35-7,54.2l2.9-2.7c2.4-18.1,4.8-36.3,6.9-50.3c2.1-14,3.9-24,5.6-33.9
        C782.5,327.5,783.5,323.1,784.7,320.3L784.7,320.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M791.5,326.1
        c3-1.5,5.6-2.4,8.2-3.3l1.4-0.3c-0.8,1.7-1.4,3.4-1.8,5.1c-0.4,1.7-0.8,3.3,0.1,5c0.8,1.8,2.8,3.7,3.9,5.3
        c1.2,1.5,1.6,2.5,1.2,4.5c-0.4,2-1.8,5-3.7,10.6c-2,5.5-4.7,13.7-7,20.5c-2.4,6.8-4.5,12.3-6.7,17.1c-2.1,4.8-4.2,8.8-6,11.9
        c-1.8,3.1-3.4,5.3-5.4,7.4c-1.8,2.2-4.1,4.2-6.5,6c2.4-18.1,4.8-36.3,6.9-50.3c2.1-14,3.9-24,5.6-33.9
        C785.1,329.7,788.5,327.6,791.5,326.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M771.3,412.1
        c1.3-1.3,2.6-2.5,3.8-3.9c1.2-1.4,2.2-2.9,3.3-4.4c0.7-1.2,1.4-2.4,2.2-3.6c0.7-1.3,1.3-2.5,1.9-3.8c1.2-2.3,2.3-4.6,3.4-7
        c1.1-2.4,2-4.8,2.9-7.2c0.6-1.5,1.2-3.1,1.8-4.6c0.6-1.6,1.2-3.1,1.7-4.7c1.8-5.3,3.7-10.6,5.4-15.9c0.7-1.8,1.3-3.7,1.9-5.6
        c0.7-1.8,1.4-3.7,2.1-5.5c0.4-1,0.8-2,1.1-3.1c0.1-0.4,0.3-0.8,0.3-1.3c0-0.3,0-0.7,0-0.9c-0.1-0.3-0.3-0.5-0.3-0.8
        c-0.3-0.3-0.5-0.6-0.7-0.9c-0.5-0.7-1.1-1.3-1.6-1.9c-0.3-0.3-0.5-0.6-0.8-0.9c-0.2-0.3-0.5-0.6-0.7-0.9c-0.2-0.3-0.4-0.6-0.6-0.9
        c-0.3-0.3-0.4-0.7-0.6-1.1c-0.2-0.4-0.2-0.8-0.4-1.2c-0.1-0.6-0.2-1.2-0.2-1.8c0-0.5,0.1-1.1,0.2-1.7c0.2-0.6,0.3-1.2,0.4-1.8
        c0.2-0.6,0.4-1.2,0.6-1.7c-1.1,0.3-2.1,0.7-3.1,1.1c-1,0.4-2,0.9-3,1.3c-0.6,0.3-1.2,0.7-1.8,0.9c-0.6,0.3-1.2,0.7-1.7,1
        c-1.9,1.1-3.9,2.3-5.7,3.4c-1,5.4-2,11-2.9,16.5c-0.9,5.5-1.8,11.1-2.6,16.6c-1.2,7.9-2.3,15.8-3.4,23.6
        C773.3,397.1,772.3,404.5,771.3,412.1L771.3,412.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M796,312.5
        c-2.6,0.5-4.9,1.5-7.2,2.4c-1.4,1.3-2.8,2.6-4.1,5.4c-1.2,2.8-2.2,7.2-3.1,11.6c3.4-2.2,6.9-4.3,9.9-5.8c3-1.5,5.6-2.4,8.2-3.3
        l4.9-11.1C801.7,311.8,798.7,312,796,312.5L796,312.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M807.5,310.5
        c-1,1.3-1.9,2.8-3,4.8c-1.1,2-2.2,4.6-3.4,7.3l-1.4,0.3l4.9-11.1L807.5,310.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M867.9,312.9l0.5,15.7
        c-2.3-2.4-4.4-4.7-5.5-6.5c-1.2-1.8-1.1-2.8-1.1-4v-10.3c1.9-0.1,3.8-0.1,4.8,0.8C867.5,309.4,867.8,311.1,867.9,312.9
        L867.9,312.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M685,987
        c1.2,8.1,2,11.2,3.3,14.5c1.3,3.4,3,7,5,10.3l-3.9-42.1c-1.3-2.3-2.3-4.8-3-7.5c-0.7-2.8-1-5.8-2.3-19.9
        c-1.3-14.1-3.4-39.2-4.8-66c-1.5-26.8-2.4-55.2-3-79.7c-0.7-24.4-1.1-44.9-0.4-63.3c0.8-18.4,2.6-34.8,5.7-48.9
        c3-14.1,7.3-25.8,11.7-36.1c4.4-10.2,8.9-19,14.3-28.2c5.4-9.1,11.6-18.6,18-27.2c6.5-8.6,13.3-16.4,20.5-23.6l0.3-4.9
        c-8.3,7.9-16.1,16.3-23.1,25.6c-7,9.2-13.3,19.1-18.7,28.4c-5.4,9.2-9.9,17.9-14.4,28.2c-4.5,10.4-9.1,22.6-12.1,37
        c-3.1,14.4-4.8,31.1-5.7,49.4c-0.9,18.4-1.1,38.2-0.6,62.5c0.6,24.1,1.9,52.6,3.5,79.6c1.5,27,3.4,52.6,5,71.8
        C682.2,966.1,683.9,979,685,987L685,987z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M724.6,922.1
        c-2.3,15.5-5.6,33.3-7.6,43.7c-1.9,10.4-2.4,13.4-3.3,15.6c-0.8,2.2-2,3.4-4.2,3c-2.2-0.3-5.4-2.4-9.1-5.1
        c-3.5-2.8-7.5-6-11.1-9.6c-1.3-2.3-2.3-4.8-3-7.5c-0.7-2.8-1-5.8-2.3-19.9c-1.3-14.1-3.4-39.2-4.8-66c-1.5-26.8-2.4-55.2-3-79.7
        c-0.7-24.4-1.2-44.9-0.4-63.3c0.8-18.4,2.6-34.8,5.7-48.9c3-14.1,7.3-25.8,11.7-36.1c4.4-10.2,8.9-19,14.3-28.2
        c5.4-9.1,11.6-18.6,18-27.2c6.5-8.6,13.3-16.4,20.5-23.6c-2.8,58.6-5.7,117.3-7.7,162.5c-2,45.1-3.3,76.8-4.5,99.3
        c-1.3,22.5-2.5,35.6-3.9,48.9C728.6,893.3,727,906.6,724.6,922.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M678.4,646.4
        c0.4,0.7,0.9,1.3,1.3,1.3c0.4,0.1,0.8-0.3,1.2-0.8c10.4-19.1,20.9-38.3,28.8-52.4c8-14.2,13.5-23.2,18.9-32.4
        c1.5-2.3,2.6-4.8,3.4-9.1c0.8-4.4,1.5-10.5,1.8-15.3c0.2-4.8,0.1-8.3-0.4-11.7l2.8-4.9c0.8,4.9,1.1,10,0.9,15.8
        c-0.2,5.9-0.8,12.6-1.8,17.4c-1,4.9-2.4,8.1-4.5,10.8c-5.4,8.5-10.8,16.9-19,31.2c-8.2,14.3-19.3,34.5-30.3,54.7
        c-0.3,0.6-0.7,1.2-1.1,1.3c-0.4,0.1-1.1-0.2-1.4-0.7l-4.2-6.8l0.9-2.8L678.4,646.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M678.4,646.4
        c0.4,0.7,0.9,1.3,1.3,1.3c0.4,0.1,0.8-0.3,1.2-0.8c10.4-19.1,20.9-38.3,28.8-52.4c8-14.2,13.5-23.2,18.9-32.4
        c1.5-2.3,2.6-4.8,3.4-9.1c0.8-4.4,1.5-10.5,1.8-15.3c0.2-4.8,0.1-8.3-0.4-11.7c-11,18.2-21.9,36.4-31.5,55.8
        c-9.6,19.3-17.9,39.7-26.2,60.2L678.4,646.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M853,1104.6
        c-0.8,1.2-1.7,1.6-2.4,1.8c-0.8,0.2-1.6,0.1-2.4-0.3c-0.8-0.4-1.7-1.1-2.5-1.3c-0.8-0.3-1.5,0-2.5,0.6c-0.9,0.6-2.3,1.5-4.2,2.3
        c-1.8,0.7-4.4,1.3-6.9,1.3H825l0.4-5c0-1.3,0.2-2.8,0.6-4.7c0.5-1.9,1.2-4.4,2.3-6.5c1.1-2.2,2.6-3.9,4.4-5.4
        c4.4-3.1,8.7-6.2,12.9-9.6c4.2-3.3,8.3-6.9,12.4-10.5l-3.6,32.2C854.1,1101.4,853.7,1103.4,853,1104.6L853,1104.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M829.5,1102.1l-4.1,1.8
        c0-1.3,0.2-2.8,0.6-4.7c0.5-1.9,1.2-4.4,2.3-6.5c1.1-2.2,2.6-3.9,4.4-5.4l-0.7,9.4c0,1,0,2.1-0.4,2.9
        C831.1,1100.6,830.5,1101.6,829.5,1102.1L829.5,1102.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M830.7,1091.9
        c-0.2,0.3-0.3,0.5-0.5,0.8c-0.2,0.2-0.3,0.6-0.4,0.8c-0.3,0.3-0.4,0.8-0.6,1.1c-0.2,0.4-0.2,0.8-0.4,1.2c-0.4,1-0.7,2.1-0.9,3.1
        c-0.3,0.8-0.4,1.6-0.5,2.3l1.5-0.7c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.2-0.4,0.4-0.6
        c0.1-0.3,0.2-0.6,0.3-0.8c0.2-0.8,0.2-1.4,0.2-2.1L830.7,1091.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M849.1,531
        c-6-0.5-12.6-1.1-18.9-2.8c-6.4-1.8-12.5-4.6-18.1-8.5c-5.5-3.9-10.5-8.9-14.5-14.4c-4-5.6-7.1-11.7-9.2-18.4
        c-2-6.6-2.9-13.7-2.9-20.5c0-6.9,1-13.6,3-20c2-6.4,5.1-12.6,9.1-18.1c4-5.6,9-10.6,14.6-14.6c5.5-4,11.7-7.1,18-9.1
        c6.4-1.9,12.9-2.7,18.6-3.1c5.8-0.4,10.7-0.5,15.6-0.1v12.7c-5.5-1.8-11.5-2.9-17.4-2.9c-5.9-0.1-11.7,0.8-17.3,2.5
        c-5.5,1.8-10.9,4.4-15.7,8c-4.9,3.4-9.1,7.8-12.7,12.6c-3.4,4.8-6.1,10.1-7.9,15.7c-1.8,5.4-2.6,11.2-2.6,16.9
        c0,5.7,0.8,11.3,2.6,16.9c1.8,5.5,4.4,10.8,7.9,15.7c3.5,4.8,7.8,9.1,12.7,12.6c4.8,3.4,10.1,6.1,15.9,7.9
        c5.9,1.8,12.3,2.7,18.4,2.4c6.2-0.2,12.1-1.4,17.6-3.6v13.2C860.5,531.8,855.1,531.5,849.1,531L849.1,531z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M849.1,994
        c-5.2-0.2-11.7-1-18.1-2.8c-6.4-1.8-12.5-4.6-18.1-8.6c-5.5-3.9-10.5-8.9-14.5-14.5c-4-5.5-7.1-11.7-9.2-18.3
        c-2-6.6-3-13.7-2.9-20.6c0-6.9,1-13.5,3-20c2-6.4,5.1-12.5,9.1-18.1c4-5.5,9-10.5,14.5-14.6c5.6-4,11.7-7.1,18-9
        c6.4-1.8,12.8-2.5,18.4-2.6c5.5-0.2,10.1,0.3,14.6,1.3v10.8c-5.6-1.8-11.5-2.8-17.3-2.9c-5.8,0-11.5,0.8-17,2.5
        c-5.4,1.8-10.8,4.4-15.6,8c-4.9,3.4-9.1,7.7-12.7,12.6c-3.4,4.8-6.1,10.1-7.9,15.7c-1.8,5.4-2.6,11.1-2.6,16.9
        c0,5.6,0.8,11.3,2.6,16.9c1.8,5.4,4.4,10.8,7.9,15.6c3.5,4.9,7.8,9.1,12.7,12.7c4.8,3.4,10.1,6.1,15.6,7.9
        c5.5,1.8,11.2,2.6,16.7,2.6c5.4,0.1,10.6-0.8,15.5-2.3v10.2C858.1,994,854.3,994.3,849.1,994L849.1,994z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M801.3,499.4
        c3.5,4.8,7.8,9.1,12.7,12.6c4.8,3.4,10.1,6,15.6,7.8c5.5,1.8,11.2,2.7,16.9,2.7c5.7,0,11.4-0.9,16.9-2.7
        c5.4-1.8,10.8-4.4,15.6-7.8c4.9-3.5,9.1-7.8,12.7-12.6c3.4-4.9,6.1-10.1,7.9-15.7c1.8-5.5,2.6-11.2,2.6-16.9
        c0-5.7-0.8-11.4-2.6-16.9c-1.8-5.5-4.4-10.9-7.9-15.7c-3.5-4.8-7.8-9.1-12.7-12.6c-4.8-3.5-10.2-6.2-15.6-8
        c-5.5-1.8-11.2-2.6-16.9-2.6c-5.6,0-11.3,0.8-16.9,2.6c-5.4,1.8-10.8,4.4-15.6,8c-4.9,3.4-9.1,7.8-12.7,12.6
        c-3.4,4.8-6.1,10.1-7.9,15.7c-1.8,5.4-2.6,11.2-2.6,16.9c0,5.7,0.8,11.3,2.6,16.9C795.2,489.2,797.9,494.5,801.3,499.4
        L801.3,499.4z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M761.5,543.7
        c0,0.8,0,1.5,0.3,2.2c0.2,0.5,0.4,0.9,0.9,1c0.7,0.3,1.6,0.3,2.3,0c1.3-0.3,2.3-0.9,3.4-1.7c0.8-0.4,1.4-0.9,1.8-1.6
        c0.4-0.8,0.6-1.8,0.6-2.9l0.6-19.7c0-1.3,0-2.8-0.3-4.1c-0.3-1.7-0.8-3.4-1.5-5c-0.5-1.5-1.3-2.8-2-4.3c-0.3-0.7-0.7-1.3-1.2-1.7
        c-0.4-0.3-1-0.4-1.3-0.2c-0.5,0.3-0.8,1-1,1.8c-0.3,0.9-0.3,1.9-0.4,2.9C762.9,521.5,762.2,532.6,761.5,543.7L761.5,543.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M857.9,531.6l-4.5,299.6c0,4.3-0.8,8.5-3,11.7c-2.9,4.1-8,7-12.7,10.2c-9.5,6.7-19.6,12.5-29.3,19c-15,10.3-29.7,21-44.5,31.4
        c-4.8,3.4-9.4,7.2-14.3,10.1c-2.3,1.3-4.5,2.2-7,2.6c-2.4,0.3-4.9,0.3-7.3-0.4c-4.8-1.4-9.1-4.5-9.1-4.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M857.5,557.4c0,0-14.7-3.1-29.5-4.9c-9-1.1-17.9-1.6-26.9-1.8c-7.9-0.2-15.8-0.5-23.4,0.6c-7.4,1.1-14.5,3.1-21.3,6
        c-5.5,2.3-9.9,7.2-9.9,7.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M766.2,418.7c0,0-4.4,6.3-6.3,13.3c-2.4,9.4-3.4,19.3-4.6,29.1c-1.9,15.9-3.4,31.9-4.7,48c-2.3,27.6-4.1,55.3-4.1,55.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="786.9" y1="391.1" x2="799.2" y2="426"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="738" y1="742.4" x2="675.3" y2="758.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M733.8,758.3
        c0,0-1,26.3-2.2,52.6c-0.7,15.4-1.1,30.8-2.2,46.1c-1,14.3-3,28.5-3,28.5l-22.6,4.4l-23-10.5c0,0-1.3-25.1-2.3-50.1
        c-0.3-9.6-0.6-19-0.8-28.6c-0.5-16.7-1-33.4-1-33.4L733.8,758.3z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="680" y1="888.5" x2="726.2" y2="911.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="805.6" y1="976.6" x2="759.6" y2="1039.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M854.7,740.8c0,0-23.6-4-47.4-4.9c-15.3-0.7-30.7-0.3-45.9,1.2c-12,1.1-23.5,5.4-23.5,5.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M741.2,604.2l-5.8,123.2
        l-58.5,20.6c0,0,0-24.1,3.5-47.7c1.8-12.8,4.9-25.5,9-37.9c3.2-9.8,7.8-19.1,12.4-28.4c4.9-10.2,11.2-19.9,11.2-19.9L741.2,604.2z
        "/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="735.4" y1="727.5" x2="733.8" y2="758.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M854.9,319.6
        c0,2.3-0.3,4.5-1.4,6.4c-1,1.8-2.7,3.4-4.7,4.2c-6.8,2.8-14.5,3.9-14.5,3.9l0.3-8.9l-1.8-1.8c0,0-0.8-1-1.2-2.2
        c-0.2-0.8-0.2-1.7-0.2-2.5v-9.1l23.6,2.4V319.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M834.5,325.2l-1.8-1.8
        c0,0-1.7,6.5-2.1,13c-1.3,16.2-1.3,32.4-1.3,32.4l2.2-0.1c0,0,0.3-13.2,1.3-26.5c0.3-4.1,1.4-8.1,1.4-8.1L834.5,325.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M820,319.9c0,0-2.2,9.1-3.5,18.2c-0.9,6-1.3,12.1-1.8,18.2c-0.3,3.1-0.5,6.2-0.3,9.2c0,1.5,0.1,3,0.8,3.6c0.9,0.8,2.6,0.6,4.3,0.5
        l9.8-0.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M860.2,341.3l-7,16.2c-1.2,2.8-2.5,5.6-4.8,7.2c-2.6,1.8-6.1,2.5-9.6,3l-7.3,1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M828.1,329.2
        c0.1-0.4,0-0.8-0.2-1.3c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.2-0.1
        c-0.5-0.2-1-0.1-1.5,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3
        c-0.2,0.5-0.1,1,0.3,1.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
        c0.5,0.2,1,0.1,1.4-0.2c0.1,0,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.2-0.2C828,329.7,828.1,329.4,828.1,329.2 M827.1,328.2
        c-0.2-0.1-0.3-0.2-0.4-0.2c-0.3-0.1-0.6-0.1-0.9,0.2c-0.2,0.2-0.2,0.2-0.3,0.4c-0.1,0.3,0,0.7,0.3,0.9c0.1,0.2,0.2,0.3,0.3,0.3
        c0.3,0.1,0.7,0.1,1-0.3c0.2-0.2,0.3-0.3,0.3-0.5C827.4,328.8,827.4,328.5,827.1,328.2z"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="843.7" y1="849" x2="843.5" y2="865.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M852.3,537.9c-8.2,0.2-16.5-0.8-24-2.8c-7.5-2.1-14.3-5.3-20.4-9.6c-6.1-4.3-11.6-9.8-16-15.8c-4.4-6.1-7.8-12.8-10.1-20.1
        c-2.2-7.2-3.3-15-3.3-22.6c0-7.5,1.1-14.8,3.4-21.8c2.3-7,5.6-13.8,10.1-19.9c4.4-6.1,9.8-11.6,15.9-16c6.1-4.4,12.8-7.8,19.8-9.9
        c7-2.1,14.2-3,20.4-3.4c6.3-0.5,11.7-0.5,17.1-0.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M851.5,1002.5c-7.2-0.3-14.3-1.7-21.4-3.9c-7-2.3-14-5.4-20.3-9.7c-6.2-4.4-11.7-10-16.3-16.2c-4.5-6.3-8-13.2-10.2-20.5
        c-2.3-7.4-3.4-15.3-3.4-23.1c0.1-7.7,1.2-15.2,3.4-22.3c2.3-7.1,5.9-14,9.6-19.5c3.7-5.4,7.7-9.6,12.3-13"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M747.3,982.3
        c0.8,1.1,1.8,2.1,2.9,2.9c1.2,0.8,2.4,1.5,3.7,1.9c1.3,0.4,2.7,0.6,4,0.6c1.3,0,2.7-0.2,3.9-0.6c1.3-0.4,2.6-1.1,3.7-1.9
        c1.2-0.8,2.2-1.8,3-2.9c0.8-1.2,1.4-2.4,1.8-3.7c0.4-1.3,0.6-2.7,0.6-4c0-1.3-0.2-2.7-0.6-3.9c-0.4-1.3-1-2.6-1.8-3.7
        c-0.8-1.2-1.8-2.2-3-3c-1.1-0.8-2.3-1.4-3.7-1.8c-1.3-0.4-2.6-0.6-3.9-0.6c-1.3,0-2.7,0.2-4,0.6c-1.3,0.4-2.5,1.1-3.7,1.8
        c-1.1,0.8-2.1,1.8-2.9,3c-0.8,1.1-1.3,2.3-1.8,3.7c-0.4,1.3-0.7,2.6-0.7,3.9c0,1.3,0.3,2.7,0.7,4
        C746,979.9,746.5,981.2,747.3,982.3L747.3,982.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M771.1,329.3l1.9,23.2c0,0.7,0.2,1.3,0.6,1.8c0.3,0.4,0.8,0.5,0.8,0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M749.2,526.9c0,0-2.8,1.3-4.9,3.4c-4,4.2-7.2,9.2-7.2,9.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M772.5,345.8c0,0-3.4,7.3-5.4,14.9c-3.9,14.8-7.5,29.8-10.5,44.9c-4,21.2-7.3,42.6-10.2,64.1c-4.5,33.8-7.6,67.7-7.6,67.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M850.3,599.5c0,0-6,42.4-8.6,85c-1.8,29.8-2.6,59.6-2.3,89.5c0.2,10.6,2.1,21,3.6,31.4c1.9,13.4,4.9,26.6,4.9,26.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M734.2,620.7l-2.6-0.2
        c0,0-3.3-0.3-5.9-1.8c-2.1-1.3-4.1-2.6-5-4.5c-1.3-2.8-1.6-6-1.3-9.2c0.3-3.2,1.3-6.4,2.8-9.3c1-2.1,2.5-3.9,4.2-5.6
        c1.6-1.5,3.4-2.8,5.4-3.7c1.8-0.9,4.2-0.9,4.2-0.9l-0.3,6.6L734.2,620.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M734.2,620.7l-2.6-0.2
        l1-23.3c0.1-1.2,0.5-2.3,1.1-3.3c0.7-1.1,1.9-1.8,1.9-1.8L734.2,620.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M734.2,620.7
        c0,0,4.6,0.5,6.8-1.6c1.6-1.6,2.2-4.4,2.3-7.2c0.4-5.6,0.2-11.2-0.7-16.8c-0.4-2.9-1.5-5.7-3.1-8c-1-1.5-3.6-1.7-3.6-1.7l-0.3,6.6
        L734.2,620.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M757.1,699.6
        c0.2,2.2-0.3,4.4-0.4,6.8c-0.2,2.4-0.3,4.9-0.5,7.3c-0.1,0.8-0.2,1.5-0.5,2.2c-0.3,0.8-1.1,1.6-1.1,1.6s0,4.9,0.4,9.6
        c0.1,1.2,0,2.3,0.6,2.9c0.5,0.7,1.6,0.9,2.7,0.9c0.6,0.1,1.1-0.3,1.4-0.8c0.5-0.5,0.7-1.3,0.8-2.1c0.5-3.4,0.8-6.7,1.1-10.1
        c0.4-5.1,0.7-10.2,0.9-15.3c0.2-2.3,0.2-4.6,0.2-7c0-1.4,0.3-2.9-0.1-4.2c-0.2-0.8-0.8-1.4-1.4-1.6c-1.2-0.3-2.4-0.3-3.6,0.1
        c-0.5,0.3-0.8,0.8-1,1.5c-0.8,2.9-0.8,5.8-0.8,5.8C755.9,697.3,757,698.4,757.1,699.6L757.1,699.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M750.9,872.8
        c0.2,2.2-0.3,4.4-0.4,6.8c-0.2,2.4-0.3,4.9-0.5,7.3c-0.1,0.8-0.2,1.5-0.4,2.1c-0.4,0.9-1.2,1.7-1.2,1.7s0.1,4.8,0.5,9.6
        c0.1,1.2,0,2.3,0.5,2.9c0.6,0.7,1.7,0.8,2.7,0.9c0.6,0,1.2-0.3,1.5-0.8c0.4-0.6,0.7-1.3,0.8-2.1c0.5-3.4,0.8-6.8,1.1-10.1
        c0.4-5.1,0.8-10.2,1-15.3c0.1-2.3,0.2-4.6,0.2-7c0-1.4,0.3-2.9,0-4.2c-0.2-0.8-0.8-1.4-1.5-1.7c-1.1-0.3-2.4-0.3-3.5,0.2
        c-0.6,0.3-0.9,0.8-1.1,1.5c-0.7,2.8-0.8,5.8-0.8,5.8S750.8,871.4,750.9,872.8L750.9,872.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M761.6,718.3c0.5-0.2,0.9-0.4,1.3-0.7c0.3-0.3,0.7-0.5,1-0.8c0.3-0.3,0.7-0.6,1-0.9c0.2-0.3,0.6-0.8,0.8-1.2
        c0.3-0.3,0.5-0.8,0.8-1.3c0.3-0.4,0.5-0.9,0.7-1.3c0.2-0.5,0.3-1,0.5-1.7c0.1-0.6,0.3-1.4,0.3-2.2c0.1-0.7,0-1.6,0-2.3
        c-0.1-0.7-0.2-1.2-0.3-1.7c-0.1-0.5-0.3-1-0.4-1.4c-0.2-0.5-0.3-1-0.6-1.4c-0.2-0.4-0.4-0.8-0.7-1.3c-0.3-0.3-0.5-0.8-0.8-1.1
        c-0.3-0.3-0.6-0.6-0.9-0.8c-0.3-0.3-0.7-0.5-0.9-0.8c-0.3-0.2-0.5-0.3-0.8-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M755.5,891.5c0.4-0.3,0.8-0.4,1.3-0.7c0.4-0.2,0.8-0.5,1.1-0.8c0.3-0.3,0.6-0.7,0.9-1c0.3-0.3,0.6-0.7,0.9-1.1
        c0.3-0.4,0.5-0.8,0.8-1.3c0.3-0.4,0.4-0.9,0.6-1.4c0.2-0.4,0.3-0.9,0.5-1.6c0.2-0.7,0.3-1.4,0.3-2.2c0.1-0.8,0.1-1.6,0-2.3
        c0-0.7-0.1-1.2-0.2-1.7c-0.2-0.5-0.2-1-0.4-1.5c-0.2-0.4-0.3-0.9-0.6-1.3c-0.3-0.4-0.4-0.8-0.7-1.3c-0.3-0.4-0.6-0.8-0.8-1.1
        c-0.3-0.3-0.6-0.6-0.9-0.9c-0.2-0.3-0.6-0.5-0.8-0.7c-0.2-0.2-0.5-0.3-0.8-0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M755.9,697.2c-0.2,0.2-0.4,0.2-0.7,0.4c-0.3,0.2-0.6,0.4-0.9,0.8c-0.3,0.3-0.7,0.6-1,0.9c-0.3,0.3-0.6,0.7-0.8,1.1
        c-0.3,0.4-0.6,0.8-0.8,1.3c-0.3,0.5-0.5,0.9-0.7,1.4c-0.2,0.5-0.3,1-0.4,1.5c-0.4,1.5-0.6,3.1-0.4,4.7c0.1,0.5,0.2,1,0.2,1.5
        c0.1,0.5,0.3,1,0.4,1.5c0.2,0.5,0.3,0.9,0.5,1.3c0.2,0.5,0.5,0.9,0.8,1.3c0.2,0.4,0.5,0.8,0.8,1.1c0.3,0.3,0.6,0.7,0.9,0.9
        c0.3,0.2,0.5,0.4,0.8,0.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M749.8,870.3c-0.3,0.1-0.4,0.3-0.7,0.4c-0.3,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.6,0.7-0.9,1c-0.3,0.3-0.6,0.7-0.8,1.1
        c-0.3,0.4-0.5,0.8-0.8,1.3c-0.3,0.4-0.4,0.9-0.6,1.4c-0.2,0.4-0.3,0.9-0.5,1.4c-0.4,1.6-0.5,3.2-0.3,4.8c0,0.5,0.1,1,0.2,1.5
        c0.2,0.5,0.2,1,0.4,1.5c0.2,0.4,0.3,0.9,0.6,1.3c0.3,0.4,0.4,0.8,0.7,1.3c0.3,0.4,0.5,0.8,0.8,1.1c0.3,0.3,0.6,0.6,0.8,0.8
        c0.3,0.2,0.6,0.5,0.8,0.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M761.5,720.1c0,0-1.8,0.1-3.5,0c-1.7-0.1-3.4-0.3-3.4-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M755.3,893.2c0,0-1.8,0.1-3.5,0c-1.7-0.1-3.3-0.3-3.3-0.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M759.8,724.3
        c0-0.3,0-0.4-0.1-0.7c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.3-0.3-0.6-0.4c-0.3-0.1-0.5-0.1-0.7-0.1c-0.3,0-0.5,0-0.8,0.1
        c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.2-0.3,0.3-0.3,0.6c-0.1,0.3-0.2,0.4-0.2,0.7c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6
        c0.2,0.2,0.4,0.3,0.6,0.4c0.3,0.1,0.5,0.2,0.8,0.2c0.2,0,0.4-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.2,0.3-0.3,0.4-0.6
        C759.8,724.8,759.8,724.5,759.8,724.3L759.8,724.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M853,1010.3c0,0-2.2,28.8-6.3,56.9c-0.4,2.7-1.5,5-3,7.1c-1.5,1.9-4.1,3.1-6.2,4.9c-3.4,2.8-6.8,5.4-9.6,8.6
        c-1.8,1.8-3.3,4-4.4,6.3c-1.1,2.4-1.5,5-2,7.6c-0.7,3.4-0.8,7-0.8,7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M850.2,311.5v4.7c0,2.6-0.4,5.2-1.2,7.6c-0.5,1.5-1.2,2.9-2.3,3.7c-2.9,1.8-6.4,3-9.8,4.2c-0.5,0.2-0.9-0.2-0.9-0.7V310"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="839.9" y1="310.4" x2="839.9" y2="330.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="841.7" y1="310.6" x2="841.7" y2="329.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="844.5" y1="310.9" x2="844.5" y2="328.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="846.3" y1="311.1" x2="846.3" y2="327.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="864.8" y1="328.2" x2="863.8" y2="391"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M855.9,1085.6c0,0-0.9,1.8-2.4,2.8c-3.6,2.3-7.9,3.6-11.5,5.9c-1.5,0.9-2.8,2.2-3.5,3.7c-0.4,0.8-0.2,1.8,0.1,2.9
        c0.3,0.7,0.9,1.3,1.6,1.8c0.7,0.4,1.3,0.7,2.1,0.6c1.8-0.2,3.8-0.6,5.4-1.4c3.8-2,7-5,7-5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M843,1097.9
        c0,1.8,2.4,2.3,5.4,1.3c2.9-1,5.4-3.3,5.4-5c0-1.8-2.4-2.4-5.4-1.3C845.4,1093.9,843,1096.2,843,1097.9L843,1097.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M871.7,550.3c0,0-3.8,0.9-4.9,3.5c-1.8,4.2-2.5,9.1-2.6,14l-3.5,260.9c-0.1,5.5,0.9,11,2.9,16c2.3,6.2,7.6,11.2,7.6,11.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="799.5" y1="314.4" x2="799.5" y2="319.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="796.6" y1="315.5" x2="796.6" y2="320.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="793.7" y1="316.6" x2="793.7" y2="321.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="790.8" y1="317.8" x2="790.8" y2="322.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="788" y1="318.8" x2="788" y2="323.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="785.1" y1="319.9" x2="785.1" y2="325.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="803.7" y1="311.8" x2="803.7" y2="311.9"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="800.9" y1="311.9" x2="800.9" y2="313.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="798" y1="312.1" x2="798" y2="314.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="795.2" y1="312.6" x2="795.2" y2="315.3"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="792.3" y1="313.6" x2="792.3" y2="316.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="789.4" y1="314.7" x2="789.4" y2="317.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="786.6" y1="317.2" x2="786.6" y2="318.7"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="802.3" y1="313.2" x2="802.3" y2="317"/>
      
        <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" points="
        804.6,312.1 803.7,311.9 802.3,313.2 800.9,313.1 799.5,314.4 798,314.2 796.6,315.5 795.2,315.3 793.7,316.6 792.3,316.4 
        790.8,317.8 789.4,317.5 788,318.8 786.6,318.7 785.1,319.9 784.9,319.9 		"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="800.9" y1="319.6" x2="800.9" y2="320.2"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="798" y1="320.7" x2="798" y2="323.4"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="795.2" y1="321.9" x2="795.2" y2="324.5"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="792.3" y1="323" x2="792.3" y2="325.6"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="789.4" y1="324.1" x2="789.4" y2="327.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="786.6" y1="325.2" x2="786.6" y2="328.8"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="783.7" y1="326.3" x2="783.7" y2="330.5"/>
      
        <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" points="
        801.3,319.2 800.9,319.6 799.5,319.4 798,320.7 796.6,320.5 795.2,321.9 793.7,321.6 792.3,323 790.8,322.8 789.4,324.1 
        788,323.9 786.6,325.2 785.1,325.1 783.7,326.3 782.9,326.2 		"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M820.9,485.2
        c2,2.7,4.4,5.2,7.1,7.1c2.8,2,5.7,3.5,8.9,4.5c3.1,1,6.3,1.5,9.5,1.5c3.2,0,6.4-0.5,9.6-1.5c3.1-1,6.1-2.5,8.9-4.5
        c2.7-2,5.1-4.4,7.1-7.1c2-2.7,3.5-5.7,4.5-8.9c1-3.1,1.4-6.3,1.4-9.5s-0.4-6.4-1.4-9.6c-1-3.1-2.5-6.1-4.5-8.8
        c-2-2.8-4.4-5.2-7.1-7.2c-2.8-2-5.8-3.5-8.9-4.5c-3.2-1-6.4-1.4-9.6-1.4c-3.2,0-6.4,0.4-9.5,1.4c-3.2,1-6.1,2.5-8.9,4.5
        c-2.7,2-5.2,4.4-7.1,7.2c-1.9,2.7-3.5,5.7-4.5,8.8c-1,3.1-1.5,6.4-1.5,9.6s0.5,6.4,1.5,9.5C817.4,479.5,819,482.5,820.9,485.2
        L820.9,485.2z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M801.7,961.8
        c3.5,4.8,7.8,9.1,12.7,12.6c4.8,3.4,10.1,6,15.6,7.8c5.5,1.8,11.2,2.7,16.9,2.7c5.7,0,11.4-0.9,16.9-2.7
        c5.4-1.8,10.8-4.4,15.6-7.8c4.9-3.5,9.1-7.8,12.7-12.6c3.4-4.9,6.1-10.1,7.9-15.7c1.8-5.5,2.6-11.2,2.6-16.9
        c0-5.7-0.8-11.4-2.6-16.9c-1.8-5.5-4.4-10.9-7.9-15.7c-3.5-4.8-7.8-9.1-12.7-12.6c-4.8-3.5-10.2-6.2-15.6-8
        c-5.5-1.8-11.2-2.6-16.9-2.6c-5.6,0-11.3,0.8-16.9,2.6c-5.4,1.8-10.8,4.4-15.6,8c-4.9,3.4-9.1,7.8-12.7,12.6
        c-3.4,4.8-6.1,10.1-7.9,15.7c-1.8,5.4-2.6,11.2-2.6,16.9c0,5.7,0.8,11.3,2.6,16.9C795.5,951.7,798.2,957,801.7,961.8L801.7,961.8z
        "/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#F9F9F9" stroke="#231F20" stroke-miterlimit="10" d="M821.3,947.7
        c2,2.7,4.4,5.2,7.1,7.1c2.8,2,5.7,3.5,8.9,4.5c3.1,1,6.3,1.5,9.5,1.5c3.2,0,6.4-0.5,9.6-1.5c3.1-1,6.1-2.5,8.9-4.5
        c2.7-2,5.1-4.4,7.1-7.1c2-2.7,3.5-5.7,4.5-8.9c1-3.1,1.4-6.3,1.4-9.5c0-3.2-0.4-6.4-1.4-9.6c-1-3.1-2.5-6.1-4.5-8.8
        c-2-2.8-4.4-5.2-7.1-7.2c-2.8-2-5.8-3.5-8.9-4.5c-3.2-1-6.4-1.4-9.6-1.4c-3.2,0-6.4,0.4-9.5,1.4c-3.2,1-6.1,2.5-8.9,4.5
        c-2.7,2-5.2,4.4-7.1,7.2c-1.9,2.7-3.5,5.7-4.5,8.8c-1,3.1-1.5,6.4-1.5,9.6c0,3.2,0.5,6.4,1.5,9.5
        C817.8,941.9,819.3,945,821.3,947.7L821.3,947.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M807.6,366.9
        c-0.2,0.3-0.3,0.6-0.6,0.8c-0.3,0.3-0.7,0.4-1,0.4c-1.4,0.1-2.8,0-4.2-0.1c-0.3,0-0.6,0-0.8-0.3c-0.2-0.2-0.2-0.5-0.1-0.8
        c0.3-1.1,0.8-2.2,1.3-3.2c0.3-0.3,0.6-0.7,0.9-0.9c0.3-0.2,0.8-0.3,1.2-0.4c1.3-0.3,2.5-0.5,3.7-0.5c0.4,0,0.8,0.2,0.8,0.5
        c0.2,0.5,0,1.1-0.2,1.7C808.5,365,808,366,807.6,366.9z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M666.3,974.1c0,0,0.6,7.6,1.2,15.2c0.4,5.3,0.9,10.5,0.9,10.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M742.7,1079.1
        c0.2,4,0.9,8,1.8,12c0.5,2.3,1.1,4.6,2.3,6.2c0.8,1,2.2,1.3,3.6,1.5c2.3,0.3,4.8,0.5,7.1,0.2c1.2-0.2,2.3-0.7,3-1.5
        c1.3-1.3,2.2-3,2.7-4.9c1.6-6.1,2.9-12.4,3.7-18.7c0.3-2.7,0.3-5.4-0.1-8c-0.5-3.8-1.5-7.5-2.5-11.1c-1.4-5.3-3.1-10.6-4.8-15.8
        c-2-6.3-4.1-12.6-6.5-18.6c-0.7-1.7-1.5-3.2-2.7-4.4c-1.7-1.8-3.8-3.1-5.9-4.4c-0.6-0.3-1.2-0.5-1.7-0.2c-0.6,0.3-0.9,1.1-1.2,1.8
        c-0.3,1.3-0.3,2.5-0.3,3.8C741.6,1037.8,741.9,1058.5,742.7,1079.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M743.6,1013
        c-0.1,0.1-0.2,0.3-0.2,0.3c-0.1,0.3-0.2,0.6-0.2,0.8c-0.1,0.3-0.1,0.6-0.1,0.8c-0.1,0.7-0.1,1.3-0.1,2c0.2,10.3,0.3,20.7,0.5,31
        c0.1,5.2,0.3,10.3,0.3,15.5c0.2,5.1,0.4,10.3,0.6,15.4c0.1,1.3,0.2,2.4,0.3,3.7c0.1,1.2,0.3,2.4,0.5,3.6c0.2,1,0.4,2,0.6,3
        c0.2,1,0.5,2,0.7,3c0.2,0.7,0.3,1.3,0.6,2c0.2,0.3,0.3,0.7,0.5,1c0.2,0.3,0.3,0.7,0.6,0.9c0,0.1,0.1,0.1,0.2,0.2
        c0,0.1,0.1,0.1,0.2,0.1c0.3,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5,0.1,0.8,0.2c0.8,0.1,1.7,0.3,2.4,0.3c0.8,0.1,1.7,0.1,2.5,0.1
        c0.4,0,0.8,0,1.3,0c0.4,0,0.8-0.1,1.2-0.2c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.6-0.3,0.8-0.4c0.1-0.1,0.3-0.2,0.4-0.3
        c0.5-0.7,1-1.3,1.3-2c0.4-0.7,0.7-1.4,0.9-2.2c0.8-3.1,1.5-6.1,2.1-9.2c0.6-3.1,1.1-6.2,1.5-9.3c0.2-1.3,0.2-2.5,0.2-3.8
        c0-1.3-0.2-2.5-0.2-3.8c-0.2-0.8-0.3-1.5-0.4-2.3c-0.2-0.8-0.3-1.5-0.5-2.3c-0.5-2-1-4.1-1.6-6.1c-0.8-2.6-1.5-5.3-2.3-7.9
        c-0.8-2.6-1.7-5.2-2.5-7.8c-1.3-3.9-2.5-7.8-3.9-11.7c-0.4-1.2-0.8-2.3-1.3-3.4c-0.5-1.2-0.9-2.4-1.3-3.4
        c-0.2-0.5-0.4-1.1-0.7-1.5c-0.2-0.5-0.6-1-0.9-1.4c-0.1-0.2-0.3-0.3-0.3-0.5c-0.2-0.2-0.2-0.3-0.4-0.4c-0.3-0.3-0.7-0.7-1.1-1
        c-0.3-0.3-0.8-0.6-1.2-0.9c-0.5-0.4-1-0.8-1.6-1.2C744.7,1013.8,744.1,1013.4,743.6,1013L743.6,1013z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#DADADA" stroke="#231F20" stroke-miterlimit="10" d="M668.3,973.7
        c-1.8-18.9-2.8-37.8-3.6-56.7c-1.8-44.4-3-88.8-3.8-133.2c-0.3-15.9,0.1-31.9,0.6-47.8c0.3-8.5,0.8-16.9,1.7-25.4
        c0.8-8,1.8-16,3.4-23.9c1.5-7.7,3.5-15.4,6.1-22.8c1.1-3.2,2.7-6.1,4.6-8.5c0.6-0.7,1.9,0.4,1.9,0.4s-3.3,9.9-5.7,20.1
        c-1.8,7.8-2.8,15.8-2.8,15.8s-1.6,9.5-2.5,19c-0.8,7.8-1.4,15.6-1.7,23.5c-0.6,15.8-1.1,31.7-0.8,47.5c0.4,31.4,1.2,62.6,2.7,93.9
        c2.1,43.2,6,86.3,6,86.3l1.1,15.7L677,995c0,0-1.7-0.1-2.6-1c-1.3-1.4-2.5-3.1-3.1-5C669.8,984,668.8,978.9,668.3,973.7
        L668.3,973.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M670.9,691.8c0,0-3,3.4-3.7,7.5c-1.9,11.5-2.8,23.2-3.4,35c-0.8,15.8-1.2,31.7-0.9,47.5c0.7,37.2,2.1,74.4,3.5,111.6
        c0.7,17.3,1.3,34.5,2.7,51.6c0.4,5.1,1.3,10.1,2.8,14.8c0.5,1.5,2.5,2.1,2.5,2.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="681.5" y1="651.1" x2="679.3" y2="655.9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M677,995c0,0,1.2,13.5,2.9,26.9c1.1,8.4,2.7,16.7,2.7,16.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M692.2,1009.8c0,0-2.5,5.8-4.5,11.8c-1.8,5.5-3.7,11.2-5.1,16.9c-3,12.1-5.3,24.3-5.3,24.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M687.5,999.6l-6.7-3.2c-1.6-0.8-3.4-1.2-4.5-0.4c-1.7,1.1-2.8,3.4-3.9,5.7c-1.9,3.7-3.1,7.8-3.1,7.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M722.8,911.5
        c0,0-1.3,8.6-2.7,17.1c-1.3,8.1-2.8,16-4.2,24c-1.3,7-2.3,14.2-3.9,21.1c-0.4,2.1-1.1,4.2-2.2,5.5c-0.6,0.7-1.5,0.8-2.4,0.3
        c-3.6-1.9-7-4.4-10.4-7.1c-2.6-2-5.4-4-7-6.6c-1.8-2.9-2.6-6.4-3.1-9.9c-1.4-9.7-1.9-19.7-2.8-29.6c-1.4-17.6-2.5-35.1-2.5-35.1
        L722.8,911.5z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M726.5,885.6c0,0-1.1,7.9-2.2,15.7c-0.8,5.1-1.5,10.2-1.5,10.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M746.3,1079.3
        c0.1,2.7,0.3,5.3,0.8,7.9c0.3,1.3,0.7,2.5,1.3,3.6c0.3,0.8,1,1.3,1.8,1.8c0.4,0.3,0.9,0.5,1.3,0.3c0.3-0.1,0.5-0.6,0.7-1
        c1-5.4,1.8-10.7,2.7-16.1c0.2-0.9,0.2-1.8,0.1-2.6c-0.1-0.3-0.4-0.7-0.8-0.9c-2-1.2-4.1-2.3-6.2-3.4c-0.5-0.2-0.9-0.4-1.3-0.2
        c-0.3,0.2-0.5,0.8-0.5,1.3C746.1,1073.1,746.1,1076.2,746.3,1079.3L746.3,1079.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M750.7,1090.8
        c0.4-2.3,0.9-4.8,1.3-7.2c0.5-2.9,0.9-5.7,1.4-8.6c0-0.3,0.1-0.8,0-1.2c0,0,0-0.1-0.1-0.1c-0.8-0.5-1.8-1.1-2.7-1.6
        c-0.9-0.5-1.8-1-2.8-1.5c-0.1,1.3-0.1,2.7-0.1,4c0,2.2,0.2,4.4,0.3,6.5c0.1,1.3,0.3,2.6,0.4,3.9c0.1,0.7,0.2,1.3,0.3,1.8
        c0,0.4,0.1,0.8,0.3,1.1c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.2,0.7,0.5,1.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.3
        C750.5,1090.7,750.6,1090.8,750.7,1090.8L750.7,1090.8z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M765.2,1084.4c0,0-5.3-1.2-10.4-2.9c-6.2-2-12.2-4.7-12.2-4.7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M814.4,1105.5v-1.1c0-1.1,0-3.6-0.7-5.1c-0.5-1.2-1.6-2-2.8-2.7c-2.2-1.1-4.7-1.6-7.1-2.3c-8.2-2.4-16.5-4.7-24.8-6.9
        c-6.8-1.8-13.8-3.1-13.8-3.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="775.6" y1="1086.6" x2="775.2" y2="1102.3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M801.5,1001.9c0,0-0.8,34.3-2.9,68.3c-0.7,9.7-2.7,19.4-2.7,19.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M742.6,1076.8c0,0-4-1.4-6.9-4.2c-2.2-2.1-3.7-4.9-5.4-7.5c-6.9-10.6-13.2-21.5-13.2-21.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M686.7,1031.4c0,0,1.8-2.1,4-2.8c1.8-0.6,3.9-0.5,5.9-0.3c5.7,0.5,11.4,1.8,11.4,1.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M764.1,422c0,0-6.1,58.3-10.3,116.8c-3.4,47.8-5.6,95.6-8,143.4c-3.4,66.8-6.9,133.6-9.5,200.5c-1.3,34.5-1.7,68.9-2.3,103.3
        c-0.7,31-0.9,62.1-1.7,93.1c-0.3,13.1-1.3,26.2-1.3,26.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M743,1016.5c0,0,3.4,2.6,6.1,5.6c0.6,0.8,1,1.7,1.2,2.6c0.3,1,0.2,2,0.2,3v23.9c0,0.5,0,1,0.3,1.3c0.3,0.5,0.8,0.9,1.4,1.3
        c2.6,1.7,5.4,3.1,7.9,4.9c1.3,0.9,2.2,2.3,3.3,3.5c1,1.2,1.8,2.6,1.8,2.6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M846.8,1010.7c0,0-4.4,1.7-6.5,5c-2.3,3.4-3.5,7.6-4.6,11.9c-3.4,13.7-6.3,27.8-9,41.8c-0.3,1.6-0.5,3.1,0,4.4
        c0.3,0.7,1.2,1,2.2,1c1.8,0.1,3.8,0,5.4-0.7c1.8-0.8,3.4-2.3,4.9-3.8c1.7-1.6,2.8-3.8,2.8-3.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M812.3,1104.9v-5.1c0-0.5,0-1.1-0.1-1.6c-0.2-0.6-0.5-1.1-0.5-1.1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" d="
        M766.4,343.2c0,0-11,32.6-17.5,66.1c-8.4,43.6-12.5,88.1-12.5,88.1"/>
      
        <line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-linejoin="round" stroke-miterlimit="10" x1="748.1" y1="1104.2" x2="748.5" y2="1098.3"/>
    </g>
  </g>
  <g id="Layer_3">
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M298.7,1317.3v102.6c0,2.8,2.2,5,4.9,5h32.2c2.7,0,4.9-2.3,4.9-5
        v-54.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M613.7,1317.3v102.6c0,2.8-2.2,5-4.9,5h-32.2c-2.7,0-4.9-2.3-4.9-5
        v-54.4"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M337.7,1371.9h11.3v-1.9c0-6.3,5.1-11.4,11.4-11.4
          c6.3,0,11.4,5.1,11.4,11.4v1.5h3.9c0,0,3.5-7.7,11.7-7.8h71.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M574.4,1371.9h-11.3v-1.9c0-6.3-5.1-11.4-11.4-11.4
          s-11.4,5.1-11.4,11.4v1.5h-3.9c0,0-3.5-7.7-11.7-7.8h-71.9"/>
      </g>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="378.9" y1="1367.2" x2="533.4" y2="1367.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="375.6" y1="1371.4" x2="537.3" y2="1371.4"/>
      <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="360.5" cy="1369.9" r="6.9"/>
      <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="552" cy="1369.5" r="6.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M405.7,1371.5L405.7,1371.5c0,2-1.1,3.6-2.5,3.6h-21.3
        c-1.4,0-2.5-1.6-2.5-3.6v0"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M533.7,1371.6L533.7,1371.6c0,2-1.1,3.6-2.5,3.6h-21.3
        c-1.4,0-2.5-1.6-2.5-3.6l0,0"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M505.6,1371.7L505.6,1371.7c0,2-1.1,3.6-2.5,3.6h-93.3
        c-1.4,0-2.5-1.6-2.5-3.6l0,0"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="341.1" y1="1389" x2="572.1" y2="1389"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M396.6,1375.4c0,0-0.2,0.7-0.7,1.8c-3,6.2-9.4,10-16.3,10.1
        c-7.4,0.1-21.6-0.7-38.8-6.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M516.1,1375.4c0,0,0.2,0.7,0.7,1.8c3,6.2,9.4,10,16.3,10.1
        c7.4,0.1,21.6-0.7,38.8-6.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M611.8,1288.7
        c-0.9-16.4-5.8-23.3-5.8-23.3l-9.5-10.4l-7.8-5.5c-1.2-9.2-9.5-25.6-9.5-25.6l-17-33.4c-6.9-11.2-21.3-12.4-21.3-12.4
        c-20.3-2.1-66.9-3.7-82.3-4.2l0-0.1c0,0-0.9,0-2.5,0.1c-1.6,0-2.5-0.1-2.5-0.1l0,0.1c-15.4,0.5-62,2-82.3,4.2
        c0,0-14.4,1.2-21.3,12.4l-17,33.4c0,0-8.4,16.4-9.5,25.6l-7.8,5.5l-9.5,10.4c0,0-4.9,6.9-5.8,23.3l-2.4,9l0.4,14l2.2,31.3
        c0,0,4.6,24.6,6.4,30.8l2.3,7.3c0.2,0.6,0.9,0.8,1.4,0.4c3.2-2.7,13.6-10.5,25.7-8.5c0.6,0.1,1.2-0.3,1.4-0.9
        c1-3.6,5.5-15.2,23.6-17.8h93.1h3.4h93.1c18.2,2.6,22.7,14.2,23.6,17.8c0.2,0.6,0.8,1,1.4,0.9c12.1-2,22.5,5.8,25.7,8.5
        c0.5,0.4,1.2,0.2,1.4-0.4l2.3-7.3c1.8-6.1,6.4-30.8,6.4-30.8l2.2-31.3l0.4-14L611.8,1288.7z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M323.9,1249.2
        c0,0,11-3.1,19-27.5c0,0,8.9-20.9,7.7-32.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M588.4,1249.2
        c0,0-11-3.1-19-27.5c0,0-8.9-20.9-7.7-32.8"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#231F20" stroke-miterlimit="10" d="M562.5,1229.3l-7.6-23.4
        c-0.4-1.4-1.6-2.4-3-2.8c-4.2-1.1-11.6-1-11.6-1l-83.5-1.7l0,0l-0.4,0l-0.4,0v0l-83.5,1.7c0,0-7.3-0.1-11.6,1
        c-1.4,0.4-2.5,1.4-3,2.8l-7.6,23.4l-3.6,17.5h109.2h0.2h109.9L562.5,1229.3z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M491.3,1242.1h59.2c2.6,0,4.5-2.4,3.9-4.9l-2.2-9.7l-6.9-19.5
        c-0.4-1.1-1.4-2-2.7-2.3c-3.8-0.9-10.5-0.8-10.5-0.8l-75.7-1.4l0,0l-0.4,0l-0.4,0v0l-75.7,1.4c0,0-6.7-0.1-10.5,0.8
        c-1.3,0.3-2.3,1.2-2.7,2.3l-6.9,19.5l-2.2,10.1c-0.5,2.3,1.2,4.5,3.6,4.5h59.8l15.2-9.9h36L491.3,1242.1z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M356.4,1183.6c0,0,0.1,14.9,2.4,20.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M556.1,1183.6c0,0-0.1,14.9-2.4,20.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M424.9,1200.7L424.9,1200.7c0-2,1.6-3.6,3.6-3.6h54.9
        c2,0,3.6,1.6,3.6,3.6l0,0"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M312.6,1258.1c0,0,6.8,1.5,17.4-0.3c0,0,66.8-8,125.9-6.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M599.6,1258.1c0,0-6.8,1.5-17.4-0.3c0,0-67.4-8-126.5-6.9"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M346.6,1246.8c0,0-2.9,4.1-1.9,10.1l7.7,40c1,5,3.2,9.7,6.5,13.7
          c1.9,2.3,4.3,4.9,6.9,7.4c4.7,4.5,10.8,7.4,17.3,8.1c1.2,0.1,2.6,0.3,4.2,0.4c1,0.1,2,0.1,2.9,0.1h65"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M566,1246.8c0,0,2.9,4.1,1.9,10.1l-7.7,40c-1,5-3.2,9.7-6.5,13.7
          c-1.9,2.3-4.3,4.9-6.9,7.4c-4.7,4.5-10.8,7.4-17.3,8.1c-1.2,0.1-2.6,0.3-4.2,0.4c-1,0.1-2,0.1-2.9,0.1h-67.1"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M357.4,1308c0,0,96.7,10.3,198.5-0.5"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M398.8,1272.9l-7,10.7c-2.2,3.3-5.8,5.5-9.8,5.7
        c-0.1,0-0.1,0-0.2,0h-61.4c-1.6,0-3.2-0.8-4-2.2c-0.3-0.5-0.4-1-0.4-1.6v-10.4c0-2.8,0.4-5.5,1.5-8.1c0.4-1,0.9-2.1,1.6-3
        c2.5-3.6,8.3-2.1,8.5,2.3c0,0.1,0,0.1,0,0.2l70.5,4.8C398.8,1271.3,399.3,1272.2,398.8,1272.9z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M513.6,1272.9l7,10.7c2.2,3.3,5.8,5.5,9.8,5.7c0.1,0,0.1,0,0.2,0
        H592c1.6,0,3.2-0.8,4-2.2c0.3-0.5,0.4-1,0.4-1.6v-10.4c0-2.8-0.4-5.5-1.5-8.1c-0.4-1-0.9-2.1-1.6-3c-2.5-3.6-8.3-2.1-8.5,2.3
        c0,0.1,0,0.1,0,0.2l-70.5,4.8C513.6,1271.3,513.1,1272.2,513.6,1272.9z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M319.8,1361.8v-30.6c0-0.7,0.5-1.5,1.2-1.7c0.5-0.2,1.2-0.1,1.9,0.8
        l8,9h30.9c0.3,0,0.5,0.3,0.3,0.6c-1.2,1.3-4.3,4.8-7.4,8.3c-0.7,0.8-1.7,1.2-2.7,1.2h-21.3l-6.4,12.4c-0.4,0.7-1.1,1.1-1.8,1.1
        h-1.3C320.4,1363,319.8,1362.5,319.8,1361.8z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M592.2,1361.8v-30.6c0-0.7-0.5-1.5-1.2-1.7
        c-0.5-0.2-1.2-0.1-1.9,0.8l-8,9h-30.9c-0.3,0-0.5,0.3-0.3,0.6c1.2,1.3,4.3,4.8,7.4,8.3c0.7,0.8,1.7,1.2,2.7,1.2h21.3l6.4,12.4
        c0.4,0.7,1.1,1.1,1.8,1.1h1.3C591.6,1363,592.2,1362.5,592.2,1361.8z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M321.3,1364.2l-2.1,1c-0.9,0.4-1.8-0.2-1.8-1.2v-34.6
        c0,0,0.3-4.6,5.1-3.9c0,0,43.2,6.3,64,9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M591.6,1364.2l2.1,1c0.9,0.4,1.8-0.2,1.8-1.2v-34.6
        c0,0-0.3-4.6-5.1-3.9c0,0-43.2,6.3-64,9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M399.1,1272.2c0,0,44,1.8,113.9-0.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M312.9,1258.2c0,0-1.6,17.7-12.4,31.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M298.8,1316c0,0,14.7-16.5,18.3-28.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M316.1,1279c0,0-2.7,1.1-2.8,7.8v71.1c0,0,0.2,9.2-6.5,15.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M599.8,1257.4c0,0,1.6,17.7,12.4,31.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M614,1315.2c0,0-14.7-16.5-18.3-28.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M596.6,1278.2c0,0,2.7,1.1,2.8,7.8v71.1c0,0-0.2,9.2,6.5,15.4"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M358.6,1203.5c0,0,1.4-6,3.8-9.8c1-1.5,2.5-2.6,4.3-3.1
          c3.2-0.9,9.4-2.4,17.7-3c0,0,40-3.1,71.1-2.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M552.2,1203.4c0,0-1.4-6-3.8-9.8c-1-1.5-2.5-2.6-4.3-3.1
          c-3.2-0.9-9.4-2.4-17.7-3c0,0-39.7-3-70.8-2.8"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M316.2,1254.6H310v-15.9c0-0.8-0.2-1.6-0.6-2.3
        c-0.5-0.9-1.3-1.8-2.9-1.9l-24.9,2.7c-2.5,0.3-4.7,2.2-5,4.7c0,0.2,0,0.4,0,0.6v4.6c0,0,0,0.4,0.2,1.1c1,4.7,5.1,8.1,9.9,8.6
        l25.1,2.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M596.1,1254.6h6.2v-15.9c0-0.8,0.2-1.6,0.6-2.3
        c0.5-0.9,1.3-1.8,2.9-1.9l24.9,2.7c2.5,0.3,4.7,2.2,5,4.7c0,0.2,0,0.4,0,0.6v4.6c0,0,0,0.4-0.2,1.1c-1,4.7-5.1,8.1-9.9,8.6
        l-25.1,2.3"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M463.4,1179.6c0.4,0.6,0,1.4-0.7,1.4l-6,0l-6.8,0
        c-0.5,0-0.9-0.6-0.6-1l4-6.2l2.8-6c0.1-0.2,0.4-0.2,0.5,0l2.9,6.4L463.4,1179.6z"/>
    </g>
  </g>
  <g id="Layer_4">
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M615.5,464.7V391c-0.4-32.5-11.3-42.5-11.3-42.5
        c-21-19.2-51.7-33.4-51.7-33.4c-13-5.9-28.3-9.2-28.3-9.2c-18.8-0.9-67-2.1-67-2.1l0,0c-2.6,0.1-48.5,1.2-66.8,2.1
        c0,0-15.2,3.3-28.3,9.2c0,0-30.7,14.2-51.7,33.4c0,0-10.9,10-11.3,42.5v73.7L298.3,667v228.8l2,94.5c0,1.7,0.2,3.4,0.4,5.1
        l10.8,69.3c0,0,3.3,21.6,37.8,39.5c4.1,2.1,8.4,3.7,12.9,4.8l14.8,3.7c1.9,0.5,3.9,0.8,5.9,0.8l72.3,3.2v0l0.1,0l0.1,0v0l76.4-3.2
        c2-0.1,4-0.4,5.9-0.8l14.8-3.7c4.5-1.1,8.8-2.7,12.9-4.8c34.5-17.9,37.8-39.5,37.8-39.5l10.8-69.3c0.3-1.7,0.4-3.4,0.4-5.1l2-94.5
        V667L615.5,464.7z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M586.6,566.7l-2.7-4.2c-27.6-39.1-125.4-34.6-125.4-34.6l0,0
        c-3-0.1-98.5-4-125.7,34.6l-2.7,4.2c-0.7,1-0.9,2.3-0.6,3.5l23.4,93.1c0,0,0.7,5.4,4.1,5.4c0,0,43.7-12.8,101.3-12.8
        c57.6,0,101.4,12.8,101.4,12.8c3.4,0,4.1-5.4,4.1-5.4l23.4-93.1C587.5,569,587.2,567.7,586.6,566.7z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M562.4,569.5c-39.2-18.8-100.9-17.1-104.1-17
        c-3.2-0.1-65.8-1.9-105,17c-8.4,4-12.6,13.7-10,22.6c0,0.1,0.1,0.3,0.1,0.4l13.8,53.8c0,0,0.4,5.9,8,8.4c2.7,0.9,5.5,1,8.3,0.4
        c8.1-1.5,31.3-5.7,41.4-6.2c0,0,3.7,0,5.3-2.6l4.9-8.7c1.3-2.3,3.6-3.9,6.2-4.2c1.4-0.2,3.1-0.3,5.3-0.3h6.2
        c2.7,0,5.2-1.5,6.3-3.9c0,0,0-0.1,0-0.1l2-5.1c0.6-1.6,2.1-2.9,3.8-3.1c0.7-0.1,1.6-0.2,2.7-0.1l0,0c0.1,0,0.1,0,0.2,0
        c0.1,0,0.1,0,0.2,0l0,0c1.1-0.1,2,0,2.7,0.1c1.7,0.3,3.2,1.5,3.8,3.1l2,5.1c0,0,0,0.1,0,0.1c1.1,2.4,3.6,3.9,6.3,3.9h6.2
        c2.2,0,4,0.1,5.3,0.3c2.6,0.3,4.9,1.9,6.2,4.2l4.9,8.7c1.6,2.6,5.3,2.6,5.3,2.6c10.1,0.5,33.3,4.7,41.4,6.2
        c2.8,0.5,5.6,0.4,8.3-0.4c7.5-2.4,8-8.4,8-8.4l13.8-53.8c0-0.1,0.1-0.3,0.1-0.4C575.1,583.1,570.8,573.5,562.4,569.5z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M516.2,308.9h-58.9h0h-58.9c-3.8,0-6.8,3.1-6.8,6.8
        c0,0.5,0.4,0.8,0.8,0.8h64.9h0.1h64.9c0.5,0,0.8-0.4,0.8-0.8C523.1,312,520,308.9,516.2,308.9z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="394" y1="310.5" x2="521.2" y2="310.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="392.8" y1="311.9" x2="521.8" y2="311.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="391.9" y1="313.5" x2="522.9" y2="313.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="391.8" y1="314.9" x2="522.7" y2="314.9"/>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="400.4" y1="310.5" x2="402" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="401.8" y1="311.9" x2="400.2" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="400.4" y1="313.6" x2="402" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="401.8" y1="315" x2="400.2" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="400.1" y1="310.4" x2="398.5" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="398.7" y1="311.9" x2="400.2" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="399.9" y1="313.5" x2="398.3" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="398.5" y1="314.9" x2="400.1" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="398.6" y1="314.9" x2="400.2" y2="316.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="401.6" y1="314.8" x2="400" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="404.3" y1="310.5" x2="405.9" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="405.7" y1="311.9" x2="404.1" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="404.3" y1="313.6" x2="405.9" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="405.7" y1="315" x2="404.1" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="404" y1="310.4" x2="402.4" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="402.6" y1="311.9" x2="404.2" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="403.8" y1="313.5" x2="402.2" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="402.5" y1="315" x2="404" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="402.5" y1="314.9" x2="404.1" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="405.5" y1="314.8" x2="403.9" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="408" y1="310.5" x2="409.7" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="409.4" y1="311.9" x2="407.9" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="408" y1="313.6" x2="409.7" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="409.4" y1="315" x2="407.9" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="407.7" y1="310.4" x2="406.1" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="406.4" y1="311.9" x2="407.9" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="407.6" y1="313.5" x2="406" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="406.2" y1="314.9" x2="407.7" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="406.3" y1="314.9" x2="407.8" y2="316.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="409.3" y1="314.8" x2="407.6" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="411.5" y1="310.5" x2="413.1" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="412.9" y1="311.9" x2="411.4" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="411.5" y1="313.6" x2="413.1" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="412.9" y1="315" x2="411.4" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="411.2" y1="310.4" x2="409.6" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="409.9" y1="311.9" x2="411.4" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="411" y1="313.5" x2="409.4" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="409.7" y1="314.9" x2="411.2" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="409.7" y1="314.9" x2="411.3" y2="316.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="412.8" y1="314.8" x2="411.1" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="415" y1="310.5" x2="416.6" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="416.3" y1="311.9" x2="414.8" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="415" y1="313.5" x2="416.6" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="416.3" y1="315" x2="414.8" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="414.7" y1="310.4" x2="413.1" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="413.3" y1="311.9" x2="414.8" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="414.5" y1="313.5" x2="412.9" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="413.1" y1="314.9" x2="414.6" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="413.2" y1="314.8" x2="414.8" y2="316.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="416.2" y1="314.8" x2="414.5" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="418.4" y1="310.7" x2="420" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="419.8" y1="312.1" x2="418.3" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="418.4" y1="313.8" x2="420" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="419.8" y1="315.2" x2="418.3" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="418.1" y1="310.6" x2="416.5" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="416.8" y1="312.1" x2="418.3" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="417.9" y1="313.7" x2="416.3" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="416.6" y1="315.2" x2="418.1" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="416.6" y1="315.1" x2="418.2" y2="316.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="419.7" y1="315" x2="418" y2="316.6"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="421.7" y1="310.7" x2="423.3" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="423" y1="312.2" x2="421.5" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="421.7" y1="313.8" x2="423.3" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="423" y1="315.3" x2="421.5" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="421.4" y1="310.7" x2="419.8" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="420" y1="312.2" x2="421.5" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="421.2" y1="313.8" x2="419.6" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="419.8" y1="315.2" x2="421.4" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="419.9" y1="315.1" x2="421.5" y2="316.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="422.9" y1="315" x2="421.2" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="424.8" y1="310.7" x2="426.4" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="426.2" y1="312.2" x2="424.7" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="424.8" y1="313.8" x2="426.4" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="426.2" y1="315.3" x2="424.7" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="424.5" y1="310.7" x2="422.9" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="423.1" y1="312.2" x2="424.7" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="424.3" y1="313.8" x2="422.7" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="423" y1="315.2" x2="424.5" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="423" y1="315.1" x2="424.6" y2="316.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="426.1" y1="315" x2="424.4" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="428" y1="311" x2="429.6" y2="309.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="429.3" y1="312.4" x2="427.8" y2="310.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="428" y1="314.1" x2="429.6" y2="312.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="429.3" y1="315.5" x2="427.8" y2="314"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="427.7" y1="310.9" x2="426.1" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="426.3" y1="312.4" x2="427.8" y2="310.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="427.5" y1="314" x2="425.9" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="426.1" y1="315.5" x2="427.7" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="426.2" y1="315.4" x2="427.8" y2="317"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="429.2" y1="315.3" x2="427.6" y2="316.9"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="431.3" y1="310.8" x2="432.9" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432.6" y1="312.2" x2="431.1" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="431.3" y1="313.9" x2="432.9" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432.6" y1="315.3" x2="431.1" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="431" y1="310.7" x2="429.4" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="429.6" y1="312.2" x2="431.1" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="430.8" y1="313.8" x2="429.2" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="429.4" y1="315.3" x2="430.9" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="429.5" y1="315.2" x2="431.1" y2="316.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432.5" y1="315.1" x2="430.8" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="434.6" y1="310.8" x2="436.2" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="435.9" y1="312.3" x2="434.4" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="434.6" y1="313.9" x2="436.2" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="435.9" y1="315.4" x2="434.4" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="434.2" y1="310.8" x2="432.6" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432.9" y1="312.2" x2="434.4" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="434.1" y1="313.8" x2="432.5" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432.7" y1="315.3" x2="434.2" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="432.8" y1="315.2" x2="434.3" y2="316.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="435.8" y1="315.1" x2="434.1" y2="316.8"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="437.9" y1="310.8" x2="439.5" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="439.3" y1="312.3" x2="437.7" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="437.9" y1="313.9" x2="439.5" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="439.3" y1="315.4" x2="437.7" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="437.6" y1="310.8" x2="436" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="436.2" y1="312.2" x2="437.7" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="437.4" y1="313.8" x2="435.8" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="436" y1="315.3" x2="437.6" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="436.1" y1="315.2" x2="437.7" y2="316.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="439.1" y1="315.1" x2="437.5" y2="316.8"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="441.4" y1="310.8" x2="443" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="442.7" y1="312.3" x2="441.2" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="441.4" y1="313.9" x2="443" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="442.7" y1="315.4" x2="441.2" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="441" y1="310.8" x2="439.4" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="439.7" y1="312.2" x2="441.2" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="440.9" y1="313.8" x2="439.3" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="439.5" y1="315.3" x2="441" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="439.6" y1="315.2" x2="441.1" y2="316.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="442.6" y1="315.1" x2="440.9" y2="316.8"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="444.6" y1="310.8" x2="446.2" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="445.9" y1="312.2" x2="444.4" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="444.6" y1="313.9" x2="446.2" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="445.9" y1="315.3" x2="444.4" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="444.3" y1="310.7" x2="442.7" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="442.9" y1="312.2" x2="444.4" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="444.1" y1="313.8" x2="442.5" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="442.7" y1="315.3" x2="444.2" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="442.8" y1="315.2" x2="444.4" y2="316.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="445.8" y1="315.1" x2="444.1" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="447.8" y1="310.7" x2="449.4" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="449.2" y1="312.2" x2="447.7" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="447.8" y1="313.8" x2="449.4" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="449.2" y1="315.3" x2="447.7" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="447.5" y1="310.7" x2="445.9" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="446.1" y1="312.2" x2="447.7" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="447.3" y1="313.8" x2="445.7" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="446" y1="315.2" x2="447.5" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="446" y1="315.1" x2="447.6" y2="316.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="449" y1="315.1" x2="447.4" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="451.1" y1="310.6" x2="452.7" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="452.5" y1="312" x2="451" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="451.1" y1="313.7" x2="452.7" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="452.5" y1="315.1" x2="451" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="450.8" y1="310.5" x2="449.2" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="449.5" y1="312" x2="451" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="450.7" y1="313.6" x2="449.1" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="449.3" y1="315" x2="450.8" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="449.3" y1="315" x2="450.9" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="452.4" y1="314.9" x2="450.7" y2="316.5"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="454.5" y1="310.4" x2="456.1" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="455.8" y1="311.9" x2="454.3" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="454.5" y1="313.5" x2="456.1" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="455.8" y1="315" x2="454.3" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="454.2" y1="310.4" x2="452.6" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="452.8" y1="311.8" x2="454.3" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="454" y1="313.4" x2="452.4" y2="311.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="452.6" y1="314.9" x2="454.2" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="452.7" y1="314.8" x2="454.3" y2="316.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="455.7" y1="314.7" x2="454" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="457.5" y1="310.4" x2="459.1" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="458.9" y1="311.9" x2="457.4" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="457.5" y1="313.5" x2="459.1" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="458.9" y1="315" x2="457.4" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="457.2" y1="310.4" x2="455.6" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="455.9" y1="311.8" x2="457.4" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="457.1" y1="313.4" x2="455.4" y2="311.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="455.7" y1="314.9" x2="457.2" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="455.7" y1="314.8" x2="457.3" y2="316.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="458.8" y1="314.7" x2="457.1" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="460.4" y1="310.4" x2="462" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="461.8" y1="311.9" x2="460.3" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="460.4" y1="313.5" x2="462" y2="311.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="461.8" y1="315" x2="460.3" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="460.1" y1="310.4" x2="458.5" y2="308.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="458.7" y1="311.8" x2="460.3" y2="310.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="459.9" y1="313.4" x2="458.3" y2="311.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="458.6" y1="314.9" x2="460.1" y2="313.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="458.6" y1="314.8" x2="460.2" y2="316.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="461.7" y1="314.7" x2="460" y2="316.4"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="463.1" y1="311" x2="464.7" y2="309.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="464.5" y1="312.5" x2="463" y2="310.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="463.1" y1="314.1" x2="464.7" y2="312.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="464.5" y1="315.6" x2="463" y2="314"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="462.8" y1="311" x2="461.2" y2="309.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="461.5" y1="312.4" x2="463" y2="310.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="462.6" y1="314" x2="461" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="461.3" y1="315.5" x2="462.8" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="461.3" y1="315.4" x2="462.9" y2="317"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="464.4" y1="315.3" x2="462.7" y2="317"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="466.3" y1="311" x2="468" y2="309.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="467.7" y1="312.4" x2="466.2" y2="310.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="466.3" y1="314.1" x2="468" y2="312.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="467.7" y1="315.5" x2="466.2" y2="314"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="466" y1="310.9" x2="464.4" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="464.7" y1="312.4" x2="466.2" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="465.9" y1="314" x2="464.3" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="464.5" y1="315.5" x2="466" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="464.6" y1="315.4" x2="466.1" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="467.6" y1="315.3" x2="465.9" y2="316.9"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="469.6" y1="310.9" x2="471.2" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="471" y1="312.4" x2="469.4" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="469.6" y1="314" x2="471.2" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="471" y1="315.5" x2="469.4" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="469.3" y1="310.9" x2="467.7" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="467.9" y1="312.3" x2="469.5" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="469.1" y1="313.9" x2="467.5" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="467.7" y1="315.4" x2="469.3" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="467.8" y1="315.3" x2="469.4" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="470.8" y1="315.2" x2="469.2" y2="316.9"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="472.9" y1="310.7" x2="474.5" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="474.3" y1="312.2" x2="472.8" y2="310.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="472.9" y1="313.8" x2="474.5" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="474.3" y1="315.3" x2="472.8" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="472.6" y1="310.7" x2="471" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="471.2" y1="312.2" x2="472.8" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="472.4" y1="313.8" x2="470.8" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="471.1" y1="315.2" x2="472.6" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="471.1" y1="315.1" x2="472.7" y2="316.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="474.2" y1="315.1" x2="472.5" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="476.3" y1="310.6" x2="477.9" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="477.6" y1="312.1" x2="476.1" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="476.3" y1="313.7" x2="477.9" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="477.6" y1="315.2" x2="476.1" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="475.9" y1="310.6" x2="474.3" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="474.6" y1="312" x2="476.1" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="475.8" y1="313.6" x2="474.2" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="474.4" y1="315.1" x2="475.9" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="474.5" y1="315" x2="476" y2="316.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="477.5" y1="314.9" x2="475.8" y2="316.6"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="479.3" y1="310.6" x2="480.9" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="480.7" y1="312.1" x2="479.2" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="479.3" y1="313.7" x2="480.9" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="480.7" y1="315.2" x2="479.2" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="479" y1="310.6" x2="477.4" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="477.6" y1="312" x2="479.2" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="478.8" y1="313.6" x2="477.2" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="477.5" y1="315.1" x2="479" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="477.5" y1="315" x2="479.1" y2="316.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="480.5" y1="314.9" x2="478.9" y2="316.6"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="482.2" y1="310.6" x2="483.8" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="483.6" y1="312.1" x2="482" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="482.2" y1="313.7" x2="483.8" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="483.6" y1="315.2" x2="482" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="481.9" y1="310.6" x2="480.3" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="480.5" y1="312" x2="482.1" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="481.7" y1="313.6" x2="480.1" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="480.3" y1="315.1" x2="481.9" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="480.4" y1="315" x2="482" y2="316.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="483.4" y1="314.9" x2="481.8" y2="316.6"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="485.2" y1="311" x2="486.8" y2="309.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="486.6" y1="312.4" x2="485.1" y2="310.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="485.2" y1="314.1" x2="486.8" y2="312.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="486.6" y1="315.5" x2="485.1" y2="314"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="484.9" y1="310.9" x2="483.3" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="483.6" y1="312.4" x2="485.1" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="484.8" y1="314" x2="483.1" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="483.4" y1="315.5" x2="484.9" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="483.4" y1="315.4" x2="485" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="486.5" y1="315.3" x2="484.8" y2="316.9"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="488.5" y1="310.9" x2="490.1" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="489.8" y1="312.4" x2="488.3" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="488.5" y1="314" x2="490.1" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="489.8" y1="315.5" x2="488.3" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="488.1" y1="310.9" x2="486.5" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="486.8" y1="312.3" x2="488.3" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="488" y1="313.9" x2="486.4" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="486.6" y1="315.4" x2="488.1" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="486.7" y1="315.3" x2="488.3" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="489.7" y1="315.2" x2="488" y2="316.9"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="491.7" y1="310.9" x2="493.3" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="493.1" y1="312.4" x2="491.5" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="491.7" y1="314" x2="493.3" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="493.1" y1="315.4" x2="491.5" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="491.4" y1="310.8" x2="489.8" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="490" y1="312.3" x2="491.6" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="491.2" y1="313.9" x2="489.6" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="489.8" y1="315.4" x2="491.4" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="489.9" y1="315.3" x2="491.5" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="492.9" y1="315.2" x2="491.3" y2="316.8"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="495" y1="310.7" x2="496.6" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.4" y1="312.2" x2="494.9" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="495" y1="313.8" x2="496.6" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.4" y1="315.3" x2="494.9" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="494.7" y1="310.7" x2="493.1" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="493.4" y1="312.1" x2="494.9" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="494.5" y1="313.7" x2="492.9" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="493.2" y1="315.2" x2="494.7" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="493.2" y1="315.1" x2="494.8" y2="316.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.3" y1="315" x2="494.6" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="498.4" y1="310.6" x2="500" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.7" y1="312" x2="498.2" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="498.4" y1="313.7" x2="500" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.7" y1="315.1" x2="498.2" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="498.1" y1="310.5" x2="496.5" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.7" y1="312" x2="498.2" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="497.9" y1="313.6" x2="496.3" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.5" y1="315" x2="498" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.6" y1="315" x2="498.2" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.6" y1="314.9" x2="497.9" y2="316.5"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="501.4" y1="310.6" x2="503" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="502.8" y1="312" x2="501.3" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="501.4" y1="313.7" x2="503" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="502.8" y1="315.1" x2="501.3" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="501.1" y1="310.5" x2="499.5" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.7" y1="312" x2="501.3" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="500.9" y1="313.6" x2="499.3" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.6" y1="315" x2="501.1" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.6" y1="315" x2="501.2" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="502.7" y1="314.9" x2="501" y2="316.5"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504.3" y1="310.6" x2="505.9" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="505.7" y1="312" x2="504.1" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504.3" y1="313.7" x2="505.9" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="505.7" y1="315.1" x2="504.1" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504" y1="310.5" x2="502.4" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="502.6" y1="312" x2="504.2" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="503.8" y1="313.6" x2="502.2" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="502.5" y1="315" x2="504" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="502.5" y1="315" x2="504.1" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="505.5" y1="314.9" x2="503.9" y2="316.5"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="498.5" y1="311" x2="500.1" y2="309.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.8" y1="312.4" x2="498.3" y2="310.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="498.5" y1="314.1" x2="500.1" y2="312.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.8" y1="315.5" x2="498.3" y2="314"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="498.2" y1="310.9" x2="496.6" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.8" y1="312.4" x2="498.3" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="498" y1="314" x2="496.4" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.6" y1="315.5" x2="498.2" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="496.7" y1="315.4" x2="498.3" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.7" y1="315.3" x2="498" y2="316.9"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="501.7" y1="310.9" x2="503.3" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="503.1" y1="312.4" x2="501.5" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="501.7" y1="314" x2="503.3" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="503.1" y1="315.5" x2="501.5" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="501.4" y1="310.9" x2="499.8" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="500" y1="312.3" x2="501.5" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="501.2" y1="313.9" x2="499.6" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.8" y1="315.4" x2="501.4" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="499.9" y1="315.3" x2="501.5" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="502.9" y1="315.2" x2="501.3" y2="316.9"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504.9" y1="310.9" x2="506.5" y2="309.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="506.3" y1="312.4" x2="504.8" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504.9" y1="314" x2="506.5" y2="312.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="506.3" y1="315.4" x2="504.8" y2="313.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504.6" y1="310.8" x2="503" y2="309.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="503.3" y1="312.3" x2="504.8" y2="310.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="504.4" y1="313.9" x2="502.8" y2="312.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="503.1" y1="315.4" x2="504.6" y2="313.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="503.1" y1="315.3" x2="504.7" y2="316.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="506.2" y1="315.2" x2="504.5" y2="316.8"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="508.3" y1="310.7" x2="509.9" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="509.6" y1="312.2" x2="508.1" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="508.3" y1="313.8" x2="509.9" y2="312.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="509.6" y1="315.3" x2="508.1" y2="313.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="508" y1="310.7" x2="506.4" y2="309.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="506.6" y1="312.1" x2="508.1" y2="310.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="507.8" y1="313.7" x2="506.2" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="506.4" y1="315.2" x2="507.9" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="506.5" y1="315.1" x2="508.1" y2="316.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="509.5" y1="315" x2="507.8" y2="316.7"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="511.6" y1="310.6" x2="513.2" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="513" y1="312" x2="511.4" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="511.6" y1="313.7" x2="513.2" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="513" y1="315.1" x2="511.4" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="511.3" y1="310.5" x2="509.7" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="509.9" y1="312" x2="511.5" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="511.1" y1="313.6" x2="509.5" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="509.7" y1="315" x2="511.3" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="509.8" y1="315" x2="511.4" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="512.8" y1="314.9" x2="511.2" y2="316.5"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="514.7" y1="310.6" x2="516.3" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="516" y1="312" x2="514.5" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="514.7" y1="313.7" x2="516.3" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="516" y1="315.1" x2="514.5" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="514.4" y1="310.5" x2="512.7" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="513" y1="312" x2="514.5" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="514.2" y1="313.6" x2="512.6" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="512.8" y1="315" x2="514.3" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="512.9" y1="315" x2="514.5" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="515.9" y1="314.9" x2="514.2" y2="316.5"/>
      </g>
      <g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="517.5" y1="310.6" x2="519.1" y2="309"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="518.9" y1="312" x2="517.4" y2="310.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="517.5" y1="313.7" x2="519.1" y2="312.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="518.9" y1="315.1" x2="517.4" y2="313.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="517.2" y1="310.5" x2="515.6" y2="308.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="515.9" y1="312" x2="517.4" y2="310.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="517.1" y1="313.6" x2="515.5" y2="312"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="515.7" y1="315" x2="517.2" y2="313.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="515.7" y1="315" x2="517.3" y2="316.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="518.8" y1="314.9" x2="517.1" y2="316.5"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M380.7,321c-5.6,3.6-34.3,22.4-51.6,39.4c-1.4,1.3-2.6,2.8-3.7,4.4
        c-2.3,3.3-6.5,9.8-10.1,18.7c0,0-1.2,4.6-3.3,8.8c-0.7,1.4-3,0.8-3.4-0.7c-0.5-1.7-0.8-4.7,0.3-9.7c0,0,4.1-18.4,10.7-26
        c0,0,4.3-7.1,30.1-19.9l30.9-15.1C380.7,320.8,380.8,320.9,380.7,321z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="391" y1="316.2" x2="380.6" y2="320.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M310.1,393.1c0,0-3,9-10.8,13.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M332.2,562.9c0,0-1.9-5.7-1.7-25c0-2.9,0.2-5.9,0.5-8.8l8.1-87.3
        c1-9.2,1.5-11.5,2.9-17.1c4.3-16.7,13.9-53.1,21.1-67.4c0,0,14.3-32.4,29.9-40.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M535.1,321c5.6,3.6,34.3,22.4,51.6,39.4c1.4,1.3,2.6,2.8,3.7,4.4
        c2.3,3.3,6.5,9.8,10.1,18.7c0,0,1.2,4.6,3.3,8.8c0.7,1.4,3,0.8,3.4-0.7c0.5-1.7,0.8-4.7-0.3-9.7c0,0-4.1-18.4-10.7-26
        c0,0-4.3-7.1-30.1-19.9l-30.9-15.1C535.1,320.8,535.1,320.9,535.1,321z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="524.8" y1="316.2" x2="535.2" y2="320.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M605.7,393.1c0,0,3,9,10.8,13.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M583.7,562.9c0,0,1.9-5.7,1.7-25c0-2.9-0.2-5.9-0.5-8.8l-8.1-87.3
        c-1-9.2-1.5-11.5-2.9-17.1c-4.3-16.7-13.9-53.1-21.1-67.4c0,0-14.3-32.4-29.9-40.9"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M325.7,573.2c0,0,25.9,92.4,28.3,113.4c0,0,5.4,41.2,5.1,75.8
          l2.1,176.6l0.6,72.5c0,0-13.5,47.5-13.2,74c0,0-13.8-12.3-20.8-37.6l-7.8-79.1l-3.3-47.8l8.1,5.7c0,0-6.9-95.7-8.1-146.3
          l3.3-159.5l2.1-37.6C322.1,583.4,323,578,325.7,573.2z"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M307,461.6l0.3,64.7l0.3,97.8l2.4,129.4l6.6,166.7
          c0,0-5.8-2.9-6.5-7c-0.7-4.1-6.1-12.9-11.2-15.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M322,583.2c0,0-2.5-184.5,5-220.6"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M324.5,618.3l8.2,4.1c0,0,15.3,55.5,17,75.9l3.7,60.3l-29.3-19.7
          L324.5,618.3z"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M325.2,773.9l28.3,4.1v118.1l-22.1,22.1
          C331.3,918.2,324.5,838.9,325.2,773.9z"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M331.6,931.2c0,0-0.7-2.7,1.4-6.1l13.3-11.9c0,0,4.1-1.4,4.1,4.1
          l2.4,68.4c0,0-1.4,11.9-7.1,31.3c0,0-3.1,10.6-5.4,10.6c0,0-5.1-2-5.1-13.3L331.6,931.2z"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="327.9" y1="867.9" x2="353.8" y2="876"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="327.9" y1="873.3" x2="353.1" y2="881.5"/>
        <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="353.4,768.5 316.3,752.8 310.2,751.4 297.2,740.5 			"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M322,574.5c0,0-4.3-16.7-23.7-20.1"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M589.4,573.2c0,0-25.9,92.4-28.3,113.4c0,0-5.4,41.2-5.1,75.8
          l-2.1,176.6l-0.6,72.5c0,0,13.5,47.5,13.2,74c0,0,13.8-12.3,20.8-37.6l7.8-79.1l3.3-47.8l-8.1,5.7c0,0,6.9-95.7,8.1-146.3
          L595.1,621l-2.1-37.6C593,583.4,592.1,578,589.4,573.2z"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M608,461.6l-0.3,64.7l-0.3,97.8L605,753.5l-6.6,166.7
          c0,0,5.8-2.9,6.5-7c0.7-4.1,6.1-12.9,11.2-15.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M593,583.2c0,0,2.5-184.5-5-220.6"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M590.5,618.3l-8.2,4.1c0,0-15.3,55.5-17,75.9l-3.7,60.3l29.3-19.7
          L590.5,618.3z"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M589.9,773.9l-28.3,4.1v118.1l22.1,22.1
          C583.7,918.2,590.5,838.9,589.9,773.9z"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M583.4,931.2c0,0,0.7-2.7-1.4-6.1l-13.3-11.9c0,0-4.1-1.4-4.1,4.1
          l-2.4,68.4c0,0,1.4,11.9,7.1,31.3c0,0,3.1,10.6,5.4,10.6c0,0,5.1-2,5.1-13.3L583.4,931.2z"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="587.1" y1="867.9" x2="561.3" y2="876"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="587.1" y1="873.3" x2="561.9" y2="881.5"/>
        <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="561.6,768.5 598.7,752.8 604.8,751.4 617.8,740.5 			"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M593,574.5c0,0,4.3-16.7,23.7-20.1"/>
      </g>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="353.4" y1="778" x2="353.4" y2="758.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="561.4" y1="777.8" x2="561.4" y2="758.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M362.3,1010.8c0,0,0.9-2.7,2-5.4c1.3-3,4.2-4.9,7.5-4.8
        c2.5,0.1,6.2,0.4,11.3,1.4c0,0,37,5.8,74.4,5.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M553.2,1010.8c0,0-0.9-2.7-2-5.4c-1.3-3-4.2-4.9-7.5-4.8
        c-2.5,0.1-6.2,0.4-11.3,1.4c0,0-37.5,5.8-74.9,5.2"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M359,1021.7c0,0,2.4,8.3,17.1,15.1c5.4,2.5,11.2,4,17.1,4.8
          c12,1.6,40,4.8,66.5,4.8"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M556,1021.7c0,0-2.4,8.3-17.1,15.1c-5.4,2.5-11.2,4-17.1,4.8
          c-12,1.6-40,4.8-66.5,4.8"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M348.6,1077.4c0,0,11.1,15.8,70,23.7c0,0,3.9,1.2,42.7,1.4"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M566.4,1077.4c0,0-11.1,15.8-70,23.7c0,0-3.9,1.2-42.7,1.4"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M368.6,1033.1c0,0-5.1,25.9-6.1,35.7c0,0-1.5,6.6,0.3,9.2
        c0,0,3.9,6.3,35.4,11.4l13.8,1.9c0,0,0.2,0,0.4,0.1c5.4,0.9,11,0,15.9-2.4c3.3-1.6,7.6-3.4,10.8-3.4h18.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M546.4,1033.1c0,0,5.1,25.9,6.1,35.7c0,0,1.5,6.6-0.3,9.2
        c0,0-3.9,6.3-35.4,11.4l-13.8,1.9c0,0-0.2,0-0.4,0.1c-5.4,0.9-11,0-15.9-2.4c-3.3-1.6-7.6-3.4-10.8-3.4h-18.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M359.8,667.6c0,0,9.6,117.2,8.1,226l1,106.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M554.9,667.6c0,0-9.6,117.2-8.1,226l-1,106.7"/>
      <g>
        <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M314.3,619.6l1.5-10.1c0,0,1.2-7-1.5-9.2
          c-2.3-1.9-6.5-3.4-12,1.4l-18.2,13.6c0,0-3.4,3.9-4.2,16.8c0,0,0.4,6.6,2.7,7c0,0,1.8,1.2,6.6-2.8L314.3,619.6z"/>
        <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M282.6,638.7c0,0,11.8-11.7,31.8-21.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="314.4" y1="600" x2="321.5" y2="595.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="316.2" y1="609.8" x2="320.8" y2="609.7"/>
      </g>
      <g>
        <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M600.8,619.6l-1.5-10.1c0,0-1.2-7,1.5-9.2
          c2.3-1.9,6.5-3.4,12,1.4l18.2,13.6c0,0,3.4,3.9,4.2,16.8c0,0-0.4,6.6-2.7,7c0,0-1.8,1.2-6.6-2.8L600.8,619.6z"/>
        <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M632.5,638.7c0,0-11.8-11.7-31.8-21.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="600.7" y1="600" x2="593.6" y2="595.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="598.9" y1="609.8" x2="594.3" y2="609.7"/>
      </g>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="304.4" cy="715.2" rx="1.3" ry="19.6"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="309.1" cy="881" rx="1.3" ry="19.6"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="612.1" cy="715.2" rx="1.3" ry="19.6"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="607.4" cy="881" rx="1.3" ry="19.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M363.9,1094.5c0,0-9.1-3.8-15.4-8.3c0,0-16.1-17.6-21.2-38.5
        l-5.3-15c0,0-3.6,28.6,2.8,43.2C324.9,1075.9,335.3,1090.7,363.9,1094.5z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M551.6,1094.5c0,0,9.1-3.8,15.4-8.3c0,0,16.1-17.6,21.2-38.5l5.3-15
        c0,0,3.6,28.6-2.8,43.2C590.6,1075.9,580.2,1090.7,551.6,1094.5z"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M363,1094.2c0,0,40.1,13.5,100.4,13.5"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M553.8,1094.2c0,0-40.1,13.5-100.4,13.5"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M338.2,1097.4l21.3-0.8c0,0,10.3,11.1,25.1,16.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M578.6,1097.4l-21.3-0.8c0,0-10.3,11.1-25.1,16.8"/>
    </g>
  </g></>),
  },
};
