export const UPDATE_WEBSITE = "UPDATE_WEBSITE";
export const SET_WEBSITE = "SET_WEBSITE";

interface IUpdateWebsite {
  type: typeof UPDATE_WEBSITE;
  payload: any;
}

interface ISetWebsite {
  type: typeof SET_WEBSITE;
  payload: any;
}

export type WebsiteAction =
  | IUpdateWebsite
  | ISetWebsite
