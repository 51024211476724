import { Checkbox, TextField as InputField, Fab, FormControlLabel, FormGroup, Grid, Hidden, InputBase, Paper, Theme, Typography, createStyles, makeStyles, withStyles } from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import AddressPicker from "../../../../AddressPicker/AddressPicker";
import { getDefaultCountryCode, getLocalizedTaxSyntex } from "../../../../../utils/localized.syntex";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CREATE_CONSUMER_BUSINESS_CUSTOMER } from "../../../../../graphql/customers/createBusinessCustomerMutation";
import { useSnackBar } from "../../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from "apollo-boost";
import { formatGraphQLErrorMessage } from "../../../../utils";
import { BusinessCustomerStatus } from "../../../../../reducers/auth/types";

const initialValues = {
  businessName: "",
  registrationNumber: "",
  VAT: "",
  registeredAddress: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
  tradingAddress: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
  billing: {
    accountNumber: "",
    email: "",
    phoneNumber: {
      country: "",
      phone: ""
    }
  },
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: {
      phone: "",
      country: ""
    }
  },
  tradingAddressSame: false,
  approvedDrivers: [],
  authorizedSignatories: [],
  documents: [],
  overrideNetPaymentTerms: false,
  invoicingDay: 0,
  requiresBookingApproval: false,
  notes: [],
  netPaymentTerms: 0,
  overrideInvoiceDate: false,
  dailyBillingCycle: 0,
  weeklyBillingCycle: 0,
  monthlyBillingCycle: 0,
  status: BusinessCustomerStatus.PENDING
}

const StyledInput = withStyles(() =>
  createStyles({
    root: {
      border: "1px solid #c0c0c0",
      borderRadius: 6,
      padding: "8px 10px",
      marginTop: 5,
      '&.Mui-focused': {
        borderColor: "var(--primary-color)"
      },
      '&.Mui-error': {
        borderColor: "#D13135",
        color: "#D13135"
      }
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "16px !important"
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    }
  })
);
interface IProps {
  onSubmit(): void
}
export const NewCustomerInfo = (props: IProps) => {
  const organisationData = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const { country } = organisationData.address;
  const snackbar = useSnackBar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [values, setValues] = useState<any>(initialValues);

  const [createConsumerBusinessCustomer] = useMutation(CREATE_CONSUMER_BUSINESS_CUSTOMER, {
    onCompleted: () => {
      snackbar({
        message: "Business Customer created.",
        variant: SnackBarVariant.SUCCESS
      });
      props.onSubmit()
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    const defaultCountryCode = getDefaultCountryCode(country);
    setValues((prevValues: any) => ({
      ...prevValues,
      billing: {
        ...prevValues.billing,
        phoneNumber: {
          ...prevValues.billing.phoneNumber,
          country: defaultCountryCode
        }
      },
      contact: {
        ...prevValues.contact,
        phoneNumber: {
          ...prevValues.contact.phoneNumber,
          country: defaultCountryCode
        }
      }
    }));
  }, [country]);

  const getCountryFromLocaleString = (localeString: string) => {
    const alpha2 = localeString.slice(-2);
    if (alpha2) {
      return alpha2;
    }
    return "GB"
  }
  function handleCheckbox(
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) {
    if (event.target.checked) {
      setValues({
        ...values,
        tradingAddress: data,
        tradingAddressSame: true
      });
    } else {
      setValues({
        ...values,
        tradingAddressSame: false
      });
    }
  }

  const validationSchema = Yup.object().shape({
    billing: Yup.object().shape({
      email: Yup.string()
        .required("Billing email is required.")
        .email("Please enter a valid Billing email."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-is-b-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" });
            }
            return true;
          })
          .required("Phone number is required.")
      })
    }),
    businessName: Yup.string().required("Business name is required."),
    contact: Yup.object().shape({
      email: Yup.string()
        .required("Contact email is required.")
        .email("Please enter a valid Contact email."),
      firstName: Yup.string().required("First name is required."),
      lastName: Yup.string().required("Last name is required."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-c-is-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" });
            }
            return true;
          })
          .required("Phone number is required.")
      })
    }),
    registeredAddress: Yup.object().shape({
      fullAddress: Yup.string().required("Registered Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required")
    }).required("Registered Address is required"),
    tradingAddress: Yup.object().shape({
      fullAddress: Yup.string().required("Trading Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required")
    }).required("Trading Address is required")
  });

  return (
    <div>
      <Paper elevation={0} className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            values.authorizedSignatories.push(values.contact);
            createConsumerBusinessCustomer({
              variables: {
                businessCustomer: values
              }
            })
            setSubmitting(false)
          }}
        >
          {(props) => (
            <Form noValidate style={{ margin: 'auto' }} >
              <Grid spacing={2} container>
                <Grid item container xs={12}>
                  <Typography variant="h2">COMPANY INFORMATION</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Business Name*</Typography>
                  <Field name="businessName">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Business Name"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Company Reg. Number</Typography>
                  <Field name="registrationNumber">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Company Reg. Number"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">{`${getLocalizedTaxSyntex(organisationData.locale)} Registration Number`}</Typography>
                  <Field name="VAT">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder={`${getLocalizedTaxSyntex(organisationData.locale)} Registration Number`}
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="h3">REGISTERED ADDRESS</Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography variant="h3">TRADING ADDRESS</Typography>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="body1" className="opacity60 padding-bottom--small">Address*</Typography>
                    <AddressPicker
                      fieldName={"registeredAddress"}
                      required
                      onChange={(address: any) => {
                        if (address) {
                          props.setFieldValue("registeredAddress", address)
                          setValues({ ...props.values, registeredAddress: address })
                        }
                      }}
                    />
                  </Grid>
                  <Hidden smUp>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h3">TRADING ADDRESS</Typography>
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="body1" className="opacity60 padding-bottom--small">Address*</Typography>
                    {!props.values.tradingAddressSame ? (
                      <AddressPicker
                      fieldName={"tradingAddress"}
                      required
                      onChange={(address: any) => {
                        if (address) {
                          props.setFieldValue("tradingAddress", address)
                          setValues({ ...props.values, tradingAddress: address })
                        }
                      }}
                      />
                      ) : (
                        <InputField
                              placeholder="Trading Address"
                              label="Trading Address"
                              disabled
                              fullWidth
                              value={props.values.registeredAddress.fullAddress}
                          ></InputField>
                      )
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <FormGroup row className={classes.checkboxLabel}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.tradingAddressSame}
                            onChange={(e) =>
                              handleCheckbox(e, values.registeredAddress)
                            }
                            color="secondary"
                            name={"premium"}
                          />
                        }
                        label={
                          <Typography variant="body1">
                            {"Use Registered Address"}
                          </Typography>
                        }
                        classes={{ label: classes.checkboxLabel }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container xs={6}>
                    <Typography variant="h3" gutterBottom>
                      BILLING INFORMATION
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small" > Billing Email Address* </Typography>
                  <Field name="billing.email">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Billing Email Address"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Account Number</Typography>
                  <Field name="billing.accountNumber">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Account Number"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Billing Phone No.*</Typography>
                  <Field
                    component={StyledInput}
                    name={"billing.phoneNumber.phone"}
                    required
                  >
                    {({ field, meta: { touched, error } }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`} style={{ marginTop: 5 }}>
                          <div style={{ border: `1px solid ${(touched && Boolean(error)) ? '#D13135' : '#c0c0c0'}`, borderRadius: 6 }}>
                            <PhoneInput
                              {...field}
                              international={false}
                              defaultCountry={getCountryFromLocaleString(organisationData.locale)}
                              placeholder="Enter phone number *"
                              className={(touched && Boolean(error)) ? "PhoneInputInputError" : ""}
                              name={"billing.phoneNumber.phone"}
                              onChange={(val) => {
                                props.setFieldValue('billing.phoneNumber.phone', val)
                              }}
                              onCountryChange={(val) => {
                                props.setFieldValue('billing.phoneNumber.country', val)
                              }}
                            />
                          </div>
                          <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item container xs={12}>
                  <Typography variant="h3" gutterBottom>
                    CUSTOMER CONTACT
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">First Name*</Typography>
                  <Field name="contact.firstName">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="First Name"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Last Name*</Typography>
                  <Field name="contact.lastName">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Last Name"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Contact Email Address*</Typography>
                  <Field name="contact.email">
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <StyledInput
                          {...field}
                          placeholder="Email Address"
                          fullWidth
                          error={touched && Boolean(error)}
                        />
                        <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Contact Phone No.*</Typography>
                  <Field
                    component={StyledInput}
                    name={"contact.phoneNumber.phone"}
                    required
                  >
                    {({ field, meta: { touched, error } }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`} style={{ marginTop: 5 }}>
                          <div style={{ border: `1px solid ${(touched && Boolean(error)) ? '#D13135' : '#c0c0c0'}`, borderRadius: 6 }}>
                            <PhoneInput
                              {...field}
                              international={false}
                              defaultCountry={getCountryFromLocaleString(organisationData.locale)}
                              placeholder="Enter phone number *"
                              className={(touched && Boolean(error)) ? "PhoneInputInputError" : ""}
                              name={"contact.phoneNumber.phone"}
                              onChange={(val) => {
                                props.setFieldValue("contact.phoneNumber.phone", val)
                              }}
                              onCountryChange={(val) => {
                                props.setFieldValue("contact.phoneNumber.country", val)
                              }}
                            />
                          </div>
                          <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    type="submit"
                  >
                    Create
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  )
}