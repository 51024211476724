import axios from 'axios';
import HttpHeader from '../../../../../header';

const httpHeader = HttpHeader.getInstance();

const instance = axios.create({
  baseURL: process.env.REACT_APP_REPORTS_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(async function (config) {
  config.headers = {
    ...config.headers,
    ...httpHeader.headers,
    authorization: `Bearer ${httpHeader.headers.authorization}`
  };
  return config;
});

export default {
  getTelematicsMapData: (data: object) => {
    return instance.post(`/fleetDashboard/telematics`, data)
  },
};