import { ADD_NEW_DRIVERS, DriverAction } from "../../actions/drivers/types";
import { initialDriverState } from "./const";
import { IDriverState } from "./types";

export enum DriverStates {  
	ADD_NEW_DRIVERS = "ADD_NEW_DRIVERS"
}


export default function (
  state: IDriverState = initialDriverState,
  action: DriverAction
): IDriverState {
  switch (action.type) {
    case ADD_NEW_DRIVERS:
      return {
        ...state,
        bookingDrivers: [...state.bookingDrivers,  ...action.payload]
      };    
    default:
      return state;
  }
}