
import React from "react"

export const CoupeSVG = {
  interior: {
    width: 245,
    height: 557,
    svg:(<><g id="Layer_2">
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M222.1,187.8V98.5c0-8.7-0.9-17.5-2.6-26c-1.7-8.2-4.1-18.2-6.9-25.4
      c0,0-4.8-12.7-23.1-17.5l-31-7.9l-26.2-4.8c0,0-7.9-0.8-12.7-0.8L71,24.8l-25.4,7.9c0,0-14.3,4.8-19.1,27.8c0,0-3.2,10.3-4.8,31
      v107.3v154.2l-1.6,81.1c0,0,0,26.2,0.8,31l9.5,40.5c0,0,6.4,23.8,19.1,30.2c0,0,23.8,11.1,73.1,11.9c0,0,39.7,0.8,68.4-10.3
      c0,0,12.7-7.2,17.5-19.1c0,0,9.5-27.8,14.3-57.2l-0.8-89.8C222.1,371.4,222.6,187.8,222.1,187.8z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M188.7,29.6c0,0-7.2-4.8-13.5-6.4l-34.8-7.9c-0.1,0-0.3-0.1-0.4-0.1
      c-1.5-0.2-10.1-1.5-15.3-2.1c-2.1-0.2-4.2-0.2-6.3,0.2l-32.2,5.8l-15.8,3.3c-2.3,0.5-4.6,1.2-6.7,2.2c-3.1,1.4-7.6,3.7-10.1,5.6"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M125.1,169.5h21.5c0,0,35.8,4,56.4,17.5c0,0,8.7-4.8,9.5-19.1
      c0,0,4.8-56.4-12.7-97.8c0,0-4.8-15.1-19.1-30.2c0,0-32.6-9.5-58.8-10.3c0,0-35,3.2-58.8,10.3c0,0-12.7,11.1-19.9,30.2
      c0,0-12.7,21.5-12.7,97.8c0,0,1.6,13.5,8.7,19.1c0,0,31.8-16.7,60.4-17.5H125.1z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M69.4,25.6c0,0-25.4,38.2-32.6,158.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M174.4,26.4c0,0,25.4,38.2,32.6,158.2"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M151.4,38.1c0.6,11.9,4.9,97.1,11.3,115.6c0.3,0.9,1.1,1.5,2.1,1.5
        h0c0.5,0,0.9-0.4,0.9-0.9c0-8.4-0.8-79.4-11.7-116.6c-0.2-0.6-0.7-1-1.3-1l0,0C152,36.7,151.4,37.3,151.4,38.1z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="152.9" y1="36.7" x2="164.8" y2="155.2"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M77.5,154.6C78,142.7,82.4,57.5,88.7,39c0.3-0.9,1.1-1.5,2.1-1.5
        l0,0c0.5,0,0.9,0.4,0.9,0.9c0,8.4-0.8,79.4-11.7,116.6c-0.2,0.6-0.7,1-1.3,1l0,0C78,156,77.4,155.3,77.5,154.6z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="79" y1="156" x2="90.9" y2="37.5"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M203,187c0,0,0,23.1-4.8,35.8s-10.3,46.9-10.3,46.9
        s-6.4,45.3-7.2,63.6l-3.2,87.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M199.8,185.4c0,0,0.8,23.8-6.4,42.1c0,0-5.6,23.1-7.9,39
        c0,0-4.8,31.8-6.4,52.5l-4.8,98.6"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M40,186.2c0,0,0,23.1,4.8,35.8s10.3,46.9,10.3,46.9
        s6.4,45.3,7.2,63.6l3.2,87.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M43.2,184.6c0,0-0.8,23.8,6.4,42.1c0,0,5.6,23.1,7.9,39
        c0,0,4.8,31.8,6.4,52.5l4.8,98.6"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M118.7,415.1h42.8c3.1,0,6.2,0.5,9.1,1.6c2.8,1,5.8,2.5,7.7,4.8
        c0,0,7.2,7.9,10.3,29.4c0,0,1.1,6.1,0.3,12.1c-0.6,4.6-3.1,8.7-6.8,11.5c-4.9,3.7-14.7,9.1-30.9,10.7l-27,0.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124.7,415.1H81.9c-3.1,0-6.2,0.5-9.1,1.6c-2.8,1-5.8,2.5-7.7,4.8
        c0,0-7.2,7.9-10.3,29.4c0,0-1.1,6.1-0.3,12.1c0.6,4.6,3.1,8.7,6.8,11.5c4.9,3.7,14.7,9.1,30.9,10.7l32.2,0.8"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M118.9,421.9h37.8c2.7,0,5.4,0.4,8,1.3c2.4,0.8,5.2,2.1,6.8,4
        c0,0,6.3,6.7,9.1,24.6c0,0,1,5.1,0.3,10.1c-0.5,3.8-2.7,7.3-6,9.6c-4.4,3.1-13,7.6-27.3,8.9l-23.8,0.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124.1,421.9H86.3c-2.7,0-5.4,0.4-8,1.3c-2.4,0.8-5.2,2.1-6.8,4
        c0,0-6.3,6.7-9.1,24.6c0,0-1,5.1-0.3,10.1c0.5,3.8,2.7,7.3,6,9.6c4.4,3.1,13,7.6,27.3,8.9l28.4,0.7"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M178.4,27.2c32.6,42.9,34.2,53.3,34.2,53.3s4.8,2.4,8.7,11.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M181.5,31.2c0,0,14.3,5.6,25.4,19.1c0,0,11.1,15.1,5.6,30.2"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M64.7,27.2C32.1,70.1,30.5,80.4,30.5,80.4s-4.8,2.4-8.7,11.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M61.5,31.2c0,0-14.3,5.6-25.4,19.1c0,0-11.1,15.1-5.6,30.2"/>
    </g>
    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="209.4" y1="73.3" x2="199" y2="69.3"/>
    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="32.9" y1="74.1" x2="44" y2="69.3"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M211.8,194.8l-2.4,135.2c0,0-3.2,70.8-8.7,97
        c-6.4-6.4-8.7-44.5-8.7-44.5c-2.4-35-2.4-70-2.4-70c2.4-32.6,7.2-61.2,7.2-61.2l11.9-48.5c2.4-13.5,13.5-15.1,13.5-15.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M209.4,200.1l-1.6,130c0,0-5.6,81.7-8.8,94.5"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="207.7,326.8 189.7,336.5 190.1,341.2 207.7,331.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="207.8,330.1 219.7,338 222.1,338 		"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M31.2,194.8l2.4,135.2c0,0,3.2,70.8,8.7,97
        c6.4-6.4,8.7-44.5,8.7-44.5c2.4-35,2.4-70,2.4-70c-2.4-32.6-7.2-61.2-7.2-61.2l-11.9-48.5c-2.4-13.5-13.5-15.1-13.5-15.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M33.5,200.1l1.8,130c0,0,5.6,81.7,8.8,94.5"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="35.3,326.8 53.3,336.5 52.9,341.2 35.3,331.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="35.3,330.1 23.3,338 20.9,338 		"/>
    </g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M191.1,474.8c0,0,6.4-19.9,9.5-48.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M51.9,475.6c0,0-6.4-19.9-9.5-48.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M203,192.5c0,0,4.8,3.2,5.6,10.3"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M40,191.7c0,0-4.8,3.2-5.6,10.3"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M30.5,80.4c0,0-6.4,49.3-6.4,106.5v154.2l-0.8,73.1
      c0,0,0,16.7-2.4,21.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M212.5,80.4c0,0,6.4,49.3,6.4,106.5v154.2l0.8,73.1
      c0,0,0,16.7,2.4,21.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M186.3,470.8c0,0,5.4,1.5,6.3,10.6c0,0.3,0,0.7,0,1
      c0,3.1-0.4,19.1-4,31.3c0,0-2.4,15.1-17.5,23.8"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M56.7,470.8c0,0-5.4,1.5-6.3,10.6c0,0.3,0,0.7,0,1
      c0,3.1,0.4,19.1,4,31.3c0,0,2.4,15.1,17.5,23.8"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M121.9,532c24.6-0.8,35.8-4.8,35.8-4.8
      c39.1-12.6,48.8-32.5,50.5-37.1c0.3-0.7,1.1-1,1.7-0.5c0.5,0.4,1,1.2,1,2.7c0,0-1.6,27.8-18.3,36.6c0,0-18.9,9.8-33.1,8.8
      c-0.7,0-1.2-0.5-1.4-1.1v0c-0.3-0.8-1-1.2-1.8-1.1c-3.7,0.6-15.3,2.9-32.8,2.9"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M121.9,532c-23.8-0.8-35.8-4.8-35.8-4.8
      c-39.1-12.6-48.8-32.5-50.5-37.1c-0.3-0.7-1.1-1-1.7-0.5c-0.5,0.4-1,1.2-1,2.7c0,0,1.6,27.8,18.3,36.6c0,0,18.9,9.8,33.1,8.8
      c0.7,0,1.2-0.5,1.4-1.1v0c0.3-0.8,1-1.2,1.8-1.1c3.7,0.6,21.7,3.7,36,2.9"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M207,493c0,0-3.2,19.1-13.5,25.4c0,0-20.6,11.5-31.2,11.9
      c-0.5,0-1,0.1-1.4,0.3c-1.1,0.6-3,2-3.2,5.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M36.8,493c0,0,3.2,19.1,13.5,25.4c0,0,20.6,11.5,31.2,11.9
      c0.5,0,1,0.1,1.4,0.3c1.1,0.6,3,2,3.2,5.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M222.9,453.3c0,0-6.4,29.4-11.9,40.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M20.9,455.7c0,0,6.4,29.4,11.9,40.5"/>
    
      <ellipse transform="matrix(0.1821 -0.9833 0.9833 0.1821 -275.0785 587.7402)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="215.8" cy="459.2" rx="13.3" ry="3.2"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.1,322.9c-1.8,0-3.2,0.7-3.2-6.3c0-6.9,1.4-18.8,3.2-18.8
        s3.2,11.9,3.2,18.8C219.3,323.6,217.9,322.9,216.1,322.9z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="216.5" y1="298.3" x2="216.5" y2="322.9"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M27.7,322.5c1.8,0,3.2,0.7,3.2-6.3c0-6.9-1.4-18.8-3.2-18.8
        c-1.8,0-3.2,11.9-3.2,18.8C24.5,323.2,25.9,322.5,27.7,322.5z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="27.3" y1="297.9" x2="27.3" y2="322.5"/>
    </g>
    <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M32.1,218c0,0-12.4,1.4-21,13.3c-0.8,1.1-1.2,2.4-1.2,3.8v5.9
      c0,0,0,0.1,0,0.2c0.1,0.8,1,1.3,1.7,0.9l20.6-12.2V218z"/>
    <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M211.7,218c0,0,12.4,1.4,21,13.3c0.8,1.1,1.2,2.4,1.2,3.8v5.9
      c0,0,0,0.1,0,0.2c-0.1,0.8-1,1.3-1.7,0.9l-20.6-12.2V218z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M197.4,532.8c0,0-70,25.4-154.2-2.4"/>
  </g>
  <g id="Layer_3">
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M138.1,197.6h29c0,0,4.2,0.8,5.9,8.3l0.3,18.5
        c0,0-2.2,10.6-8.1,13.9H139c0,0-5.3-4.1-7.6-13.8v-18.9C131.4,205.5,133.6,198.5,138.1,197.6z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M173.8,235.1l-0.6-1.1h-3.6l-2.4,2.7l-2.9,1.5l-12.1-0.1v0
        l-12.3,0.4l-2.6-2.1l-1.9-2.6l-4.1,0.2L130,236l-0.6,5.4v16.4l0.5,4.2l1.5,2.9l1.9,1.9l2.9,1.5h4.1h1.8l0.1,1.2h9.9v0h9.9l0.1-1.2
        h1.8h4.1l2.9-1.5l1.9-1.9l1.5-2.9l0.5-4.2v-16.4l-0.5-4.3C174.4,236.4,174.2,235.7,173.8,235.1z"/>
      <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="152.2,257.2 139.8,257.2 139.8,268 142.1,270.6 
        152.2,270.6 162.3,270.6 164.6,268 164.6,257.2 		"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M75.1,198.8h29c0,0,4.2,0.8,5.9,8.3l0.3,18.5c0,0-2.2,10.6-8.1,13.9
        H76.1c0,0-5.3-4.1-7.6-13.8v-18.9C68.5,206.7,70.6,199.7,75.1,198.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M110.9,236.3l-0.6-1.1h-3.6l-2.4,2.7l-2.9,1.5l-12.1-0.1v0
        l-12.3,0.4l-2.6-2.1l-1.9-2.6l-4.1,0.2l-1.1,2.1l-0.6,5.4V259l0.5,4.2l1.5,2.9l1.9,1.9l2.9,1.5h4.1h1.8l0.1,1.2h9.9v0h9.9l0.1-1.2
        h1.8h4.1l2.9-1.5l1.9-1.9l1.5-2.9l0.5-4.2v-16.4l-0.5-4.3C111.5,237.6,111.2,236.9,110.9,236.3z"/>
      <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="89.2,258.4 76.8,258.4 76.8,269.3 79.1,271.8 89.2,271.8 
        99.3,271.8 101.6,269.3 101.6,258.4 		"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M78.8,322.8h29c0,0,4.2,0.8,5.9,8.3l0.3,18.5c0,0-2.2,10.6-8.1,13.9
        H79.8c0,0-5.3-4.1-7.6-13.8v-18.9C72.1,330.8,74.3,323.8,78.8,322.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M114.6,360.4l-0.6-1.1h-3.6l-2.4,2.7l-2.9,1.5l-12.1-0.1v0
        l-12.3,0.4l-2.6-2.1L76,359l-4.1,0.2l-1.1,2.1l-0.6,5.4V383l0.5,4.2l1.5,2.9L74,392l2.9,1.5h4.1h1.8l0.1,1.2h9.9v0h9.9l0.1-1.2
        h1.8h4.1l2.9-1.5l1.9-1.9l1.5-2.9l0.5-4.2v-16.4l-0.5-4.3C115.1,361.7,114.9,361,114.6,360.4z"/>
      <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="92.9,382.4 80.5,382.4 80.5,393.3 82.8,395.8 92.9,395.8 
        103,395.8 105.3,393.3 105.3,382.4 		"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M133.8,322.2h29c0,0,4.2,0.8,5.9,8.3L169,349c0,0-2.2,10.6-8.1,13.9
        h-26.1c0,0-5.3-4.1-7.6-13.8v-18.9C127.1,330.2,129.3,323.1,133.8,322.2z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M169.6,359.8l-0.6-1.1h-3.6l-2.4,2.7l-2.9,1.5l-12.1-0.1v0
        l-12.3,0.4l-2.6-2.1l-1.9-2.6l-4.1,0.2l-1.1,2.1l-0.6,5.4v16.4l0.5,4.2l1.5,2.9l1.9,1.9l2.9,1.5h4.1h1.8l0.1,1.2h9.9v0h9.9
        l0.1-1.2h1.8h4.1l2.9-1.5l1.9-1.9l1.5-2.9l0.5-4.2V366l-0.5-4.3C170.1,361.1,169.9,360.4,169.6,359.8z"/>
      <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="147.9,381.8 135.5,381.8 135.5,392.7 137.8,395.2 
        147.9,395.2 158,395.2 160.3,392.7 160.3,381.8 		"/>
    </g>
  </g></>)
  },

  exterior: {
    width: 622,
    height: 955,
    svg: (<><g id="Layer_1">
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M34.9,661l8,58.1l-1.6,1.6l3.2,13.5H46v-2.4l7.2,3.2h3.2h9.5v-0.8h4
        l8,2.4v2.8h7.6l2.8-2.8v-3.2h10.3l11.1-6.4h3.2l8.8-4.8h4l3.2-27l1.6-19.9c9.5-15.1,25.5-62.8,25.5-62.8
        c4.8-13.8,9.1-45.7,10.1-53.8c0.2-1.2,0.2-2.5,0.2-3.7l0.8-49.9v-26.3c-0.8-25.5-11.1-46.9-11.1-46.9L124,373.8
        c-0.8-42.2-7.2-82.7-7.2-82.7c-4.8-38.2-14.3-62.8-14.3-62.8c-5.6-16.7-11.9-22.3-11.9-22.3s0.6,6.7-3,10c-0.8,0.8-2,1.1-3.1,1.1
        l-10.6,0c0,0-0.3,0-0.9-0.2c-4-1-7.4-3.7-9.3-7.4l-1-1.9H49.2l-8.5,6c-5.5,3.9-9.1,10-9.7,16.8c0,0.1,0,0.2,0,0.3v34.2
        c0,0,1.3,0.6,1.6,0.4h21.9c21.7,0,39.4,18.9,39.4,42.2s-17.6,42.2-39.4,42.2H33.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M33.3,577.4h21.9c21.7,0,39.4,18.7,39.4,41.8
        c0,23.1-17.6,41.8-39.4,41.8H34.5"/>
      
        <rect x="54.4" y="200" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 261.9106 150.7675)" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.4" height="12.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="33.3" y1="577.8" x2="33.3" y2="349.1"/>
      <g>
        <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="52" cy="307" rx="37" ry="37.4"/>
        <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="52" cy="307.4" r="25.9"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="52" cy="618.8" rx="37" ry="37.4"/>
        <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="52" cy="619.2" r="25.9"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="36.5" y1="349.9" x2="39.7" y2="576.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M72.3,575.9c19.9,11.1,26.3,19.9,27,43.4
        c0.9,25.7-17.5,50.5-62.1,48.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M45.2,355.5c39.8-1.6,52.6-22.7,53.3-46.1
        c0.8-26.3-17.5-52.5-62.8-48.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="78.6" y1="659.4" x2="85" y2="738.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M77.8,730.2v-10.3c0,0-3.2-15.1-19.1-23.1l-1.6-1.6h-4v12.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="48.4" y1="697.6" x2="46" y2="661"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="33.3" y1="577.4" x2="33.3" y2="586.2"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="33.3,416.8 30.9,414.4 30.9,338 		"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="34.1" y1="265.6" x2="34.1" y2="274.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M128.8,663.4c0,0-4.8,1.6-7.2,18.3c0,0-1.6,24.7-7.2,31
        c0,0-13.5,16.7-26.3,16.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M101.7,687.2c0,0-4-3.2-7.2-12.7l-5.6-24.2"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="125.6" y1="721.4" x2="118.6" y2="702.6"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="96.9" y1="270.4" x2="88.2" y2="285.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M58.8,207.5v7.2c0,0,14.3,7.2,15.9,9.5"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="54.8,207.5 54.8,214.7 58.8,214.7 		"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M64.2,217.9c0,0,2.5,8-0.7,13.5l-20.7,15.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M58,214.7c0,0,4,4,3.2,8.8c0,0-0.8,4-3.2,5.6l-18.3,11.9h-0.5
        c-1.5,0-2.7-1.2-2.7-2.7v-20.2c0,0,0.1-0.1,0.1,0c0.6,1.1,3.9,7.5,3.9,12.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M38.9,214.7c0,0-0.8-8.8-2.4-8.8c0,0-3.2-4-2.4,14.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M59.5,227.4c0,0-13.5-3.2-19.1,3.2c0,0-1.8,2.4-1.3,10.3"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="35.7" y1="264.8" x2="35.7" y2="247.3"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="36.1" y1="247.3" x2="30.9" y2="247.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M61.1,224.2c0,0,1.6,3.2,0,5.6l-25.5,17.5"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="75.5" cy="262.4" r="1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M99.3,270.4c0,0,12.7,58.1,10.3,124.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M88.2,215.5c0,0,7.2,12.7,10.3,35"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89,213.9c0,0,7.2,8,10.3,16.7"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.9" cy="690" r="2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M64.3,735c0,0,0-4.8-0.8-6.4c-0.8-1.6-10.3-11.9-22.3-22.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M42,711.9l16.5,15.7c0.9,0.9,1.4,2,1.6,3.2c0.1,0.5,0.2,1.1,0.2,1.8
        v2.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M42.8,717.5L54.6,728c0.9,0.9,1.4,2,1.6,3.2
        c0.1,0.5,0.2,1.1,0.2,1.8v2.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M41.3,720.6l9.3,8.1c0.9,0.9,1.4,2,1.6,3.2c0.1,0.5,0.2,1.1,0.2,1.8
        v0.8"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="81.8" cy="725.4" r="1.6"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="88.2" y1="733.4" x2="88.2" y2="725.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M32.5,275.2c0,0,0-7.2-1.6-10.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M55.6,695.2c0,0,1.7,3.7-2.4,9.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124,374.6c0,0,0.8,8.8,1.6,10.3c0,0,20.7,41.5,29.1,61.3
        c1.2,2.9,2.2,6,3,9.1c1.2,4.9,3,13.1,4.3,20.5c0.7,3.8,1,7.6,0.9,11.4l-1.4,63.5c-0.1,2.6-0.3,5.1-0.7,7.6
        c-1.4,9.5-5.3,34.1-9.8,48.6c0,0,4.8-0.8,7.2-0.8"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="161.4" y1="553.6" x2="166.1" y2="558.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M151,606.9l-20,46c-0.9,2.2-1.4,4.5-1.3,6.9l0.6,15.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M155,614h-2.5c-0.9,0-1.7,0.5-2,1.3l-16.7,37.8c-0.6,1.5-1,3-1,4.6
        v13.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="159" y1="438.2" x2="154.2" y2="445.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="160.6" y1="443" x2="156.6" y2="450.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M123.2,373.8c0,0-8-6.4-9.5-29.4c0,0-4-60.5-10.3-82.7
        c0,0-4.8-27-4-39.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M75,224.3l12.5,0.7c0.9,0,1.7,0.6,2,1.4c1.6,4.1,6.4,16.4,9,24.9
        c0,0,3,11.3,2.2,16.4c-0.2,1.4-1.5,2.4-2.9,2.2c-2-0.2-5.2-1.9-8.8-8.3l-14.8-31.9c-0.2-0.5-0.3-1-0.3-1.5v-2.8
        C73.9,224.8,74.4,224.3,75,224.3z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M98.5,269.6c0,0-1.6-13.7-4.8-23.1c-1.2-3.5-3.5-6.6-6.4-8.8
        c-3.2-2.4-8-5.7-13.1-7.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M96.9,260.8c0,0-10.8-1.8-19.3-24"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.2,243.3c0,0-1.6-11.9-4.8-13.5l-13.2-5.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M113.6,726.2v-43.8c0,0,0-2.6-2.5-4c-1.1-0.6-2.6-0.3-3.3,0.9
        l-6.9,8.7V709c0,0.9,0.3,1.7,0.9,2.3l2.2,2.2v16.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M111.3,726.2v-40c0-1.6-2-2.5-3.1-1.4c0,0,0,0,0,0l-7.2,8.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M111.3,722.2l-6.5,5.6c0,0-1.4,1.6-3.6,0.8c0,0-1.4-0.8-1.4-2.4
        v-7.2"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="100.1" y1="725" x2="100.1" y2="709.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M73.1,582.2c41.4,8.8,52.5,27.8,52.5,27.8v5.6l7.2,0.8
        c18.3-32.6,23.1-70.8,23.1-70.8c5.6-26.3,1.6-70,1.6-70c-1.6-21.5-17.5-52.5-17.5-52.5l-16.7-31c-5.6-10.3-12.7-13.5-12.7-13.5
        s-38.2-2.4-64.4,3.2"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="120.2" y1="387.9" x2="130.4" y2="616.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="128.8" y1="615.6" x2="118.4" y2="385.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124.3,520.3c0,0-7.5,6.2-13.8,7.8c0,0-23.9,3.2-41.4-5.6
        c0,0-15.9-4.8-19.1-22.3l-4.8-151.1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="118.6" y1="603" x2="116" y2="709.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M37.3,365.8c0,0,5.6,96.3,6.4,134.4c0,0,0.8,57.3-4,60.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M120.8,392.9c0,0,26.3,42.2,33.4,75.6c0,0,4.7,24.3,2.4,60.5
        c0,0.5-0.1,1-0.2,1.5c-1.1,7.4-12.6,67.6-26.1,85.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M126.4,518.6l29.6,10.9c0.3,0.1,0.5,0.5,0.4,0.8l-0.4,1.1
        c-0.1,0.4-0.6,0.6-0.9,0.4L126.4,521V518.6z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M113.2,515.4c-2.6,0-4.8-0.5-4.8-6c0-5.5,2.1-13.9,4.8-13.9
        s4.8,8.4,4.8,13.9C118,514.9,115.9,515.4,113.2,515.4z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M113.6,495.5c0,0-2.4,11.9,0,19.9"/>
      <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M127.6,432.7c-4.6,0-8.4,0.3-8.4-7.2c0-7.5,3.7-19.9,8.4-19.9
        s8.4,12.4,8.4,19.9C135.9,433,132.2,432.7,127.6,432.7z"/>
      <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M123.2,408.8c0,0-3.2-2.4-4.8-3.2c-1.4-0.7-2.8-0.8-3.6,0.3
        c-0.2,0.3-0.3,0.6-0.3,1v7.8c0,0.3,0.1,0.6,0.4,0.7c1.2,1,5,4,5.1,3.8C120,419.1,122.4,409.6,123.2,408.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M90.6,205.9c0,0-17.5-2.4-27.8,1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M90.6,208.3c0,0-16.7-2.4-27,1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M90.6,210.7c0,0-15.1-2.4-25.5,1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.8,213.1c0,0-11.1-2.4-22.3,0.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89,215.5c0,0-8-2.4-19.1,0"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.2" y1="205.1" x2="84.2" y2="217.1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="79.4" y1="205.5" x2="79.4" y2="217.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="74.7" y1="205.5" x2="74.7" y2="217.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="69.9" y1="205.5" x2="69.9" y2="215.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124,372.2c0,0-8.8-83.5-24.7-140"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M577.1,661l-8,58.1l1.6,1.6l-3.2,13.5h-1.6v-2.4l-7.2,3.2h-3.2H546
        v-0.8h-4l-8,2.4v2.8h-7.6l-2.8-2.8v-3.2h-10.3l-11.1-6.4h-3.2l-8.8-4.8h-4l-3.2-27l-1.6-19.9c-9.5-15.1-25.5-62.8-25.5-62.8
        c-4.8-13.8-9.1-45.7-10.1-53.8c-0.2-1.2-0.2-2.5-0.2-3.7l-0.8-49.9v-26.3c0.8-25.5,11.1-46.9,11.1-46.9l31.8-58.1
        c0.8-42.2,7.2-82.7,7.2-82.7c4.8-38.2,14.3-62.8,14.3-62.8c5.6-16.7,11.9-22.3,11.9-22.3s-0.6,6.7,3,10c0.8,0.8,2,1.1,3.1,1.1
        l10.6,0c0,0,0.3,0,0.9-0.2c4-1,7.4-3.7,9.3-7.4l1-1.9h13.5l8.5,6c5.5,3.9,9.1,10,9.7,16.8c0,0.1,0,0.2,0,0.3v34.2
        c0,0-1.3,0.6-1.6,0.4h-21.9c-21.7,0-39.4,18.9-39.4,42.2s17.6,42.2,39.4,42.2h20.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M578.6,577.4h-21.9c-21.7,0-39.4,18.7-39.4,41.8
        c0,23.1,17.6,41.8,39.4,41.8h20.7"/>
      
        <rect x="555.2" y="200" transform="matrix(-4.420660e-11 1 -1 -4.420660e-11 762.7119 -350.0338)" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.4" height="12.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="578.6" y1="577.8" x2="578.6" y2="349.1"/>
      <g>
        <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="560" cy="307" rx="37" ry="37.4"/>
        <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="560" cy="307.4" r="25.9"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="560" cy="618.8" rx="37" ry="37.4"/>
        <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="560" cy="619.2" r="25.9"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="575.5" y1="349.9" x2="572.3" y2="576.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M539.7,575.9c-19.9,11.1-26.3,19.9-27,43.4
        c-0.9,25.7,17.5,50.5,62.1,48.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M566.7,355.5c-39.8-1.6-52.6-22.7-53.3-46.1
        c-0.8-26.3,17.5-52.5,62.8-48.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="533.3" y1="659.4" x2="526.9" y2="738.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M534.1,730.2v-10.3c0,0,3.2-15.1,19.1-23.1l1.6-1.6h4v12.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="563.5" y1="697.6" x2="565.9" y2="661"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="578.6" y1="577.4" x2="578.6" y2="586.2"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="578.6,416.8 581,414.4 581,338 		"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="577.9" y1="265.6" x2="577.9" y2="274.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M483.2,663.4c0,0,4.8,1.6,7.2,18.3c0,0,1.6,24.7,7.2,31
        c0,0,13.5,16.7,26.3,16.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M510.2,687.2c0,0,4-3.2,7.2-12.7l5.6-24.2"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="486.4" y1="721.4" x2="493.3" y2="702.6"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="515" y1="270.4" x2="523.8" y2="285.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M553.2,207.5v7.2c0,0-14.3,7.2-15.9,9.5"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="557.2,207.5 557.2,214.7 553.2,214.7 		"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M547.8,217.9c0,0-2.5,8,0.7,13.5l20.7,15.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M554,214.7c0,0-4,4-3.2,8.8c0,0,0.8,4,3.2,5.6l18.3,11.9h0.5
        c1.5,0,2.7-1.2,2.7-2.7v-20.2c0,0-0.1-0.1-0.1,0c-0.6,1.1-3.9,7.5-3.9,12.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M573.1,214.7c0,0,0.8-8.8,2.4-8.8c0,0,3.2-4,2.4,14.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M552.4,227.4c0,0,13.5-3.2,19.1,3.2c0,0,1.8,2.4,1.3,10.3"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="576.3" y1="264.8" x2="576.3" y2="247.3"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="575.9" y1="247.3" x2="581" y2="247.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M550.8,224.2c0,0-1.6,3.2,0,5.6l25.5,17.5"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="536.5" cy="262.4" r="1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M512.6,270.4c0,0-12.7,58.1-10.3,124.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M523.8,215.5c0,0-7.2,12.7-10.3,35"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M523,213.9c0,0-7.2,8-10.3,16.7"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="548" cy="690" r="2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M547.6,735c0,0,0-4.8,0.8-6.4c0.8-1.6,10.3-11.9,22.3-22.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M569.9,711.9l-16.5,15.7c-0.9,0.9-1.4,2-1.6,3.2
        c-0.1,0.5-0.2,1.1-0.2,1.8v2.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M569.1,717.5L557.4,728c-0.9,0.9-1.4,2-1.6,3.2
        c-0.1,0.5-0.2,1.1-0.2,1.8v2.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M570.7,720.6l-9.3,8.1c-0.9,0.9-1.4,2-1.6,3.2
        c-0.1,0.5-0.2,1.1-0.2,1.8v0.8"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="530.1" cy="725.4" r="1.6"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="523.8" y1="733.4" x2="523.8" y2="725.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M579.4,275.2c0,0,0-7.2,1.6-10.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M556.4,695.2c0,0-1.7,3.7,2.4,9.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M488,374.6c0,0-0.8,8.8-1.6,10.3c0,0-20.7,41.5-29.1,61.3
        c-1.2,2.9-2.2,6-3,9.1c-1.2,4.9-3,13.1-4.3,20.5c-0.7,3.8-1,7.6-0.9,11.4l1.4,63.5c0.1,2.6,0.3,5.1,0.7,7.6
        c1.4,9.5,5.3,34.1,9.8,48.6c0,0-4.8-0.8-7.2-0.8"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="450.6" y1="553.6" x2="445.8" y2="558.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M460.9,606.9l20,46c0.9,2.2,1.4,4.5,1.3,6.9l-0.6,15.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M456.9,614h2.5c0.9,0,1.7,0.5,2,1.3l16.7,37.8c0.6,1.5,1,3,1,4.6
        v13.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="453" y1="438.2" x2="457.7" y2="445.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="451.4" y1="443" x2="455.3" y2="450.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M488.8,373.8c0,0,8-6.4,9.5-29.4c0,0,4-60.5,10.3-82.7
        c0,0,4.8-27,4-39.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M536.9,224.3l-12.5,0.7c-0.9,0-1.7,0.6-2,1.4
        c-1.6,4.1-6.4,16.4-9,24.9c0,0-3,11.3-2.2,16.4c0.2,1.4,1.5,2.4,2.9,2.2c2-0.2,5.2-1.9,8.8-8.3l14.8-31.9c0.2-0.5,0.3-1,0.3-1.5
        v-2.8C538.1,224.8,537.5,224.3,536.9,224.3z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M513.4,269.6c0,0,1.6-13.7,4.8-23.1c1.2-3.5,3.5-6.6,6.4-8.8
        c3.2-2.4,8-5.7,13.1-7.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M515,260.8c0,0,10.8-1.8,19.3-24"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M519.8,243.3c0,0,1.6-11.9,4.8-13.5l13.2-5.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M498.3,726.2v-43.8c0,0,0-2.6,2.5-4c1.1-0.6,2.6-0.3,3.3,0.9
        l6.9,8.7V709c0,0.9-0.3,1.7-0.9,2.3l-2.2,2.2v16.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M500.7,726.2v-40c0-1.6,2-2.5,3.1-1.4c0,0,0,0,0,0l7.2,8.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M500.7,722.2l6.5,5.6c0,0,1.4,1.6,3.6,0.8c0,0,1.4-0.8,1.4-2.4v-7.2
        "/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="511.8" y1="725" x2="511.8" y2="709.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M538.9,582.2c-41.4,8.8-52.5,27.8-52.5,27.8v5.6l-7.2,0.8
        c-18.3-32.6-23.1-70.8-23.1-70.8c-5.6-26.3-1.6-70-1.6-70c1.6-21.5,17.5-52.5,17.5-52.5l16.7-31c5.6-10.3,12.7-13.5,12.7-13.5
        s38.2-2.4,64.4,3.2"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="491.7" y1="387.9" x2="481.6" y2="616.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="483.2" y1="615.6" x2="493.6" y2="385.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M487.7,520.3c0,0,7.5,6.2,13.8,7.8c0,0,23.9,3.2,41.4-5.6
        c0,0,15.9-4.8,19.1-22.3l4.8-151.1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="493.3" y1="603" x2="495.9" y2="709.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M574.7,365.8c0,0-5.6,96.3-6.4,134.4c0,0-0.8,57.3,4,60.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M491.1,392.9c0,0-26.3,42.2-33.4,75.6c0,0-4.7,24.3-2.4,60.5
        c0,0.5,0.1,1,0.2,1.5c1.1,7.4,12.6,67.6,26.1,85.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.6,518.6l-29.6,10.9c-0.3,0.1-0.5,0.5-0.4,0.8l0.4,1.1
        c0.1,0.4,0.6,0.6,0.9,0.4l28.7-10.9V518.6z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M498.7,515.4c2.6,0,4.8-0.5,4.8-6c0-5.5-2.1-13.9-4.8-13.9
        c-2.6,0-4.8,8.4-4.8,13.9C493.9,514.9,496.1,515.4,498.7,515.4z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M498.3,495.5c0,0,2.4,11.9,0,19.9"/>
      <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M484.4,432.7c4.6,0,8.4,0.3,8.4-7.2c0-7.5-3.7-19.9-8.4-19.9
        S476,418,476,425.5C476,433,479.8,432.7,484.4,432.7z"/>
      <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M488.8,408.8c0,0,3.2-2.4,4.8-3.2c1.4-0.7,2.8-0.8,3.6,0.3
        c0.2,0.3,0.3,0.6,0.3,1v7.8c0,0.3-0.1,0.6-0.4,0.7c-1.2,1-5,4-5.1,3.8C491.9,419.1,489.5,409.6,488.8,408.8z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.4,205.9c0,0,17.5-2.4,27.8,1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.4,208.3c0,0,16.7-2.4,27,1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M521.4,210.7c0,0,15.1-2.4,25.5,1.6"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M522.2,213.1c0,0,11.1-2.4,22.3,0.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M523,215.5c0,0,8-2.4,19.1,0"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="527.7" y1="205.1" x2="527.7" y2="217.1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="532.5" y1="205.5" x2="532.5" y2="217.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="537.3" y1="205.5" x2="537.3" y2="217.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="542.1" y1="205.5" x2="542.1" y2="215.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M488,372.2c0,0,8.8-83.5,24.7-140"/>
    </g>
  </g>
  <g id="Layer_2">
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M406.1,377.8v-89.3c0-8.7-0.9-17.5-2.6-26
      c-1.7-8.2-4.1-18.2-6.9-25.4c0,0-4.8-12.7-23.1-17.5l-31-7.9l-26.2-4.8c0,0-7.9-0.8-12.7-0.8l-48.5,8.7l-25.4,7.9
      c0,0-14.3,4.8-19.1,27.8c0,0-3.2,10.3-4.8,31v107.3v154.2l-1.6,81.1c0,0,0,26.2,0.8,31l9.5,40.5c0,0,6.4,23.8,19.1,30.2
      c0,0,23.8,11.1,73.1,11.9c0,0,39.7,0.8,68.4-10.3c0,0,12.7-7.2,17.5-19.1c0,0,9.5-27.8,14.3-57.2l-0.8-89.8
      C406.1,561.4,406.6,377.8,406.1,377.8z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M372.7,219.6c0,0-7.2-4.8-13.5-6.4l-34.8-7.9c-0.1,0-0.3-0.1-0.4-0.1
      c-1.5-0.2-10.1-1.5-15.3-2.1c-2.1-0.2-4.2-0.2-6.3,0.2l-32.2,5.8l-15.8,3.3c-2.3,0.5-4.6,1.2-6.7,2.2c-3.1,1.4-7.6,3.7-10.1,5.6"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309.1,359.5h21.5c0,0,35.8,4,56.4,17.5c0,0,8.7-4.8,9.5-19.1
      c0,0,4.8-56.4-12.7-97.8c0,0-4.8-15.1-19.1-30.2c0,0-32.6-9.5-58.8-10.3c0,0-35,3.2-58.8,10.3c0,0-12.7,11.1-19.9,30.2
      c0,0-12.7,21.5-12.7,97.8c0,0,1.6,13.5,8.7,19.1c0,0,31.8-16.7,60.4-17.5H309.1z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M253.4,215.6c0,0-25.4,38.2-32.6,158.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M358.4,216.4c0,0,25.4,38.2,32.6,158.2"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M335.4,228.1c0.6,11.9,4.9,97.1,11.3,115.6c0.3,0.9,1.1,1.5,2.1,1.5
        l0,0c0.5,0,0.9-0.4,0.9-0.9c0-8.4-0.8-79.4-11.7-116.6c-0.2-0.6-0.7-1-1.3-1l0,0C336,226.7,335.4,227.3,335.4,228.1z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="336.9" y1="226.7" x2="348.8" y2="345.2"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M261.5,344.6c0.6-11.9,4.9-97.1,11.3-115.6c0.3-0.9,1.1-1.5,2.1-1.5
        l0,0c0.5,0,0.9,0.4,0.9,0.9c0,8.4-0.8,79.4-11.7,116.6c-0.2,0.6-0.7,1-1.3,1l0,0C262,346,261.4,345.3,261.5,344.6z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="263" y1="346" x2="274.9" y2="227.5"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M387,377c0,0,0,23.1-4.8,35.8c-4.8,12.7-10.3,46.9-10.3,46.9
        s-6.4,45.3-7.2,63.6l-3.2,87.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M383.8,375.4c0,0,0.8,23.8-6.4,42.1c0,0-5.6,23.1-7.9,39
        c0,0-4.8,31.8-6.4,52.5l-4.8,98.6"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M224,376.2c0,0,0,23.1,4.8,35.8c4.8,12.7,10.3,46.9,10.3,46.9
        s6.4,45.3,7.2,63.6l3.2,87.4"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M227.2,374.6c0,0-0.8,23.8,6.4,42.1c0,0,5.6,23.1,7.9,39
        c0,0,4.8,31.8,6.4,52.5l4.8,98.6"/>
    </g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M370.3,450.9c0,0-52.5-21.5-129.4-0.2"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M305.1,419.1h9.5c0.8,4,4,4,4,4c2.4,0.8,4.8,4,4.8,4v10.3
        c18.9,1.3,36.2,5.3,41.8,6.7c1.1,0.3,2.2-0.4,2.5-1.4c1.9-6.2,7.8-26.4,11.8-50c0.3-2.1-0.4-4.2-2-5.5c-1.9-1.6-5.5-3.6-11.9-5.3
        c-14.3-4-31.8-7.2-59.6-7.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M307.2,419.1h-9.5c-0.8,4-4,4-4,4c-2.4,0.8-4.8,4-4.8,4v10.3
        c-18.9,1.3-36.2,5.3-41.8,6.7c-1.1,0.3-2.2-0.4-2.5-1.4c-1.9-6.2-7.8-26.4-11.8-50c-0.3-2.1,0.4-4.2,2-5.5
        c1.9-1.6,5.5-3.6,11.9-5.3c14.3-4,31.8-7.2,59.6-7.2"/>
    </g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M360.8,553.2c0,0-43.8,9.8-110.6,0.2"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M302.7,605.1h42.8c3.1,0,6.2,0.5,9.1,1.6c2.8,1,5.8,2.5,7.7,4.8
        c0,0,7.2,7.9,10.3,29.4c0,0,1.1,6.1,0.3,12.1c-0.6,4.6-3.1,8.7-6.8,11.5c-4.9,3.7-14.7,9.1-30.9,10.7l-27,0.8"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M308.7,605.1h-42.8c-3.1,0-6.2,0.5-9.1,1.6c-2.8,1-5.8,2.5-7.7,4.8
        c0,0-7.2,7.9-10.3,29.4c0,0-1.1,6.1-0.3,12.1c0.6,4.6,3.1,8.7,6.8,11.5c4.9,3.7,14.7,9.1,30.9,10.7l32.2,0.8"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M302.9,611.9h37.8c2.7,0,5.4,0.4,8,1.3c2.4,0.8,5.2,2.1,6.8,4
        c0,0,6.3,6.7,9.1,24.6c0,0,1,5.1,0.3,10.1c-0.5,3.8-2.7,7.3-6,9.6c-4.4,3.1-13,7.6-27.3,8.9l-23.8,0.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M308.1,611.9h-37.8c-2.7,0-5.4,0.4-8,1.3c-2.4,0.8-5.2,2.1-6.8,4
        c0,0-6.3,6.7-9.1,24.6c0,0-1,5.1-0.3,10.1c0.5,3.8,2.7,7.3,6,9.6c4.4,3.1,13,7.6,27.3,8.9l28.4,0.7"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M362.4,217.2c32.6,42.9,34.2,53.3,34.2,53.3s4.8,2.4,8.7,11.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.5,221.2c0,0,14.3,5.6,25.4,19.1c0,0,11.1,15.1,5.6,30.2"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M248.7,217.2c-32.6,42.9-34.2,53.3-34.2,53.3s-4.8,2.4-8.7,11.9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M245.5,221.2c0,0-14.3,5.6-25.4,19.1c0,0-11.1,15.1-5.6,30.2"/>
    </g>
    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="393.4" y1="263.3" x2="383" y2="259.3"/>
    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="216.9" y1="264.1" x2="228" y2="259.3"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M395.8,384.8l-2.4,135.2c0,0-3.2,70.8-8.7,97
        c-6.4-6.4-8.7-44.5-8.7-44.5c-2.4-35-2.4-70-2.4-70c2.4-32.6,7.2-61.2,7.2-61.2l11.9-48.5c2.4-13.5,13.5-15.1,13.5-15.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M393.4,390.1l-1.6,130c0,0-5.6,81.7-8.8,94.5"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="391.7,516.8 373.7,526.5 374.1,531.2 391.7,521.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="391.8,520.1 403.7,528 406.1,528 		"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M215.2,384.8l2.4,135.2c0,0,3.2,70.8,8.7,97
        c6.4-6.4,8.7-44.5,8.7-44.5c2.4-35,2.4-70,2.4-70c-2.4-32.6-7.2-61.2-7.2-61.2l-11.9-48.5c-2.4-13.5-13.5-15.1-13.5-15.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M217.5,390.1l1.8,130c0,0,5.6,81.7,8.8,94.5"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="219.3,516.8 237.3,526.5 236.9,531.2 219.3,521.2 		"/>
      <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="219.3,520.1 207.3,528 204.9,528 		"/>
    </g>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M375.1,664.8c0,0,6.4-19.9,9.5-48.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M235.9,665.6c0,0-6.4-19.9-9.5-48.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M387,382.5c0,0,4.8,3.2,5.6,10.3"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M224,381.7c0,0-4.8,3.2-5.6,10.3"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M214.5,270.4c0,0-6.4,49.3-6.4,106.5v154.2l-0.8,73.1
      c0,0,0,16.7-2.4,21.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M396.5,270.4c0,0,6.4,49.3,6.4,106.5v154.2l0.8,73.1
      c0,0,0,16.7,2.4,21.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M370.3,660.8c0,0,5.4,1.5,6.3,10.6c0,0.3,0,0.7,0,1
      c0,3.1-0.4,19.1-4,31.3c0,0-2.4,15.1-17.5,23.8"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M240.7,660.8c0,0-5.4,1.5-6.3,10.6c0,0.3,0,0.7,0,1
      c0,3.1,0.4,19.1,4,31.3c0,0,2.4,15.1,17.5,23.8"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M305.9,722c24.6-0.8,35.8-4.8,35.8-4.8
      c39.1-12.6,48.8-32.5,50.5-37.1c0.3-0.7,1.1-1,1.7-0.5c0.5,0.4,1,1.2,1,2.7c0,0-1.6,27.8-18.3,36.6c0,0-18.9,9.8-33.1,8.8
      c-0.7,0-1.2-0.5-1.4-1.1v0c-0.3-0.8-1-1.2-1.8-1.1c-3.7,0.6-15.3,2.9-32.8,2.9"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M305.9,722c-23.8-0.8-35.8-4.8-35.8-4.8
      c-39.1-12.6-48.8-32.5-50.5-37.1c-0.3-0.7-1.1-1-1.7-0.5c-0.5,0.4-1,1.2-1,2.7c0,0,1.6,27.8,18.3,36.6c0,0,18.9,9.8,33.1,8.8
      c0.7,0,1.2-0.5,1.4-1.1v0c0.3-0.8,1-1.2,1.8-1.1c3.7,0.6,21.7,3.7,36,2.9"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M391,683c0,0-3.2,19.1-13.5,25.4c0,0-20.6,11.5-31.2,11.9
      c-0.5,0-1,0.1-1.4,0.3c-1.1,0.6-3,2-3.2,5.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M220.8,683c0,0,3.2,19.1,13.5,25.4c0,0,20.6,11.5,31.2,11.9
      c0.5,0,1,0.1,1.4,0.3c1.1,0.6,3,2,3.2,5.2"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M406.9,643.3c0,0-6.4,29.4-11.9,40.5"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M204.9,645.7c0,0,6.4,29.4,11.9,40.5"/>
    
      <ellipse transform="matrix(0.1821 -0.9833 0.9833 0.1821 -311.4094 924.0627)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="399.8" cy="649.2" rx="13.3" ry="3.2"/>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M400.1,512.9c-1.8,0-3.2,0.7-3.2-6.3c0-6.9,1.4-18.8,3.2-18.8
        c1.8,0,3.2,11.9,3.2,18.8C403.3,513.6,401.9,512.9,400.1,512.9z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="400.5" y1="488.3" x2="400.5" y2="512.9"/>
    </g>
    <g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M211.7,512.5c1.8,0,3.2,0.7,3.2-6.3c0-6.9-1.4-18.8-3.2-18.8
        s-3.2,11.9-3.2,18.8C208.5,513.2,209.9,512.5,211.7,512.5z"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="211.3" y1="487.9" x2="211.3" y2="512.5"/>
    </g>
    <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M216.1,408c0,0-12.4,1.4-21,13.3c-0.8,1.1-1.2,2.4-1.2,3.8v5.9
      c0,0,0,0.1,0,0.2c0.1,0.8,1,1.3,1.7,0.9l20.6-12.2V408z"/>
    <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M395.7,408c0,0,12.4,1.4,21,13.3c0.8,1.1,1.2,2.4,1.2,3.8v5.9
      c0,0,0,0.1,0,0.2c-0.1,0.8-1,1.3-1.7,0.9l-20.6-12.2V408z"/>
    <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M381.4,722.8c0,0-70,25.4-154.2-2.4"/>
  </g>
  <g id="Layer_3">
    <g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M303.5,22.7c0,0,45.8,0.8,63,4.1c0,0,4.9,0,10.6,9
          c0,0,12.3,19.6,15.5,29.4v4.1l9,9l3.3,4.1l0.8,10.6l-3.3,57.2l-0.7,5.2c-0.1,0.9-0.4,1.7-0.9,2.4l-1.8,2.7
          c-0.4,0.7-1.2,1.1-2,1.1h-26c-0.9,0-1.8-0.3-2.5-0.8l-16.1-12.2c0,0-3.3-3.3-10.6-3.3h-38"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M305.1,22.7c0,0-45.8,0.8-63,4.1c0,0-4.9,0-10.6,9
          c0,0-12.3,19.6-15.5,29.4v4.1l-9,9l-3.3,4.1L202.9,93l3.3,57.2l0.7,5.2c0.1,0.9,0.4,1.7,0.9,2.4l1.8,2.7c0.4,0.7,1.2,1.1,2,1.1
          h26c0.9,0,1.8-0.3,2.5-0.8l16.1-12.2c0,0,3.3-3.3,10.6-3.3h38"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M304.3,66h-84.2c0,0-1.6,1.6-0.8,3.3l36,32.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M303.9,66h85c0,0,1.6,1.6,0.8,3.3l-36,32.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M220.1,66c0,0-4.5,4-6.8,7.5c-1.4,2-1.2,4.6,0.4,6.5
        c2.9,3.3,10.6,8.5,30.9,13h59.7"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M388.9,66c0,0,4.5,4,6.8,7.5c1.4,2,1.2,4.6-0.4,6.5
        c-2.9,3.3-10.6,8.5-30.9,13h-59.7"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M233.2,66C233.2,66,233.1,66,233.2,66c-3.4-1.2-4.9-5-3.4-8.1
          l9.4-20.2c0.8-1.7,2.4-2.9,4.2-3.2c1.3-0.2,3-0.4,5.3-0.4l37.4-0.8c0.6,0,1.1,0.3,1.3,0.9c0.7,2,3.1,6.7,9.5,7.3h7.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M375.8,66C375.8,66,375.9,66,375.8,66c3.4-1.2,4.9-5,3.4-8.1
          l-9.4-20.2c-0.8-1.7-2.4-2.9-4.2-3.2c-1.3-0.2-3-0.4-5.3-0.4l-37.4-0.8c-0.6,0-1.1,0.3-1.3,0.9c-0.7,2-3.1,6.7-9.5,7.3h-8.2"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M244.6,26.7c0,0-18.8,26.2-19.6,39.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M241.3,26.7c0,0-18.8,26.2-19.6,39.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M364.8,26.7c0,0,18.8,26.2,19.6,39.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M368.1,26.7c0,0,18.8,26.2,19.6,39.2"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M240.6,33c0,0,34.2-3,62.9-3"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M368.9,33c0,0-36.8-3-65.4-3"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M303.5,101.1h46.3c0.9,0,1.8,0.1,2.6,0.4c0,0,0.1,0,0.1,0
          c4.3,1.3,6.9,5.6,6.3,10c-0.4,3.1-1.9,6.9-5.9,10.7c-1.2,1.1-2.7,1.9-4.3,2.3c-5.7,1.3-19.8,4.4-45.1,4.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M304.7,101.1h-46.3c-0.9,0-1.8,0.1-2.6,0.4c0,0-0.1,0-0.1,0
          c-4.3,1.3-6.9,5.6-6.3,10c0.4,3.1,1.9,6.9,5.9,10.7c1.2,1.1,2.7,1.9,4.3,2.3c5.7,1.3,19.8,4.4,45.1,4.4"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="260.1" y1="101.1" x2="260.1" y2="124.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="268.3" y1="101.1" x2="268.3" y2="126.1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="275.7" y1="101.1" x2="275.7" y2="127.3"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="283" y1="101.6" x2="283" y2="127.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="290.4" y1="101.6" x2="290.4" y2="128.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="298.6" y1="101.6" x2="298.6" y2="128.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="304.3" y1="101.6" x2="304.3" y2="128.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="310" y1="101.6" x2="310" y2="128.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="317.4" y1="101.6" x2="317.4" y2="128.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="324.7" y1="101.6" x2="324.7" y2="128.1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="332.9" y1="101.6" x2="332.9" y2="127.3"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="340.3" y1="101.6" x2="340.3" y2="126.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="347.6" y1="101.6" x2="347.6" y2="124.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="355" y1="102.8" x2="355" y2="120"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="252.8" y1="102.8" x2="252.8" y2="119.1"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="252" y1="103.6" x2="355.8" y2="103.6"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="265" y1="125.7" x2="342.7" y2="125.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="249.5" y1="107.7" x2="358.2" y2="107.7"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="249.5" y1="112.6" x2="358.2" y2="112.6"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="251.1" y1="117.5" x2="357" y2="117.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="255.2" y1="122.4" x2="353.3" y2="122.4"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.8,91.3h-3.3c0,0-2.5,0-3.3,3.3v8.2c0,0,1.6,7,21.4,13.4
          c0.9,0.3,1.9,0.5,2.9,0.5h7.1c0.7,0,1.3-0.6,1.3-1.3v-1.2l-2.9-12.9c-0.3-1.2-1-2.2-2.1-2.8C234,96.4,223.9,91.3,216.8,91.3z"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="239.7" y1="99.5" x2="250.3" y2="104.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M242.9,115.9c0,0,2.5,2.4,12.3,6.5"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="224.2" y1="86.4" x2="214.4" y2="91.3"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="210.3" y1="94.6" x2="203.7" y2="100.3"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M205.4,80.7c0,0,1.6,6.5,6.5,10.6"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="207.8" y1="85.6" x2="202.9" y2="93"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M391.8,91.3h3.3c0,0,2.5,0,3.3,3.3v8.2c0,0-1.6,7-21.4,13.4
          c-0.9,0.3-1.9,0.5-2.9,0.5h-7.1c-0.7,0-1.3-0.6-1.3-1.3v-1.2l2.9-12.9c0.3-1.2,1-2.2,2.1-2.8C374.6,96.4,384.7,91.3,391.8,91.3z"
          />
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="368.9" y1="99.5" x2="357.4" y2="104.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M365.7,115.9c0,0-3.3,2.4-13.1,6.5"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="384.4" y1="86.4" x2="394.2" y2="91.3"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="398.3" y1="94.6" x2="404.9" y2="100.3"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M403.2,80.7c0,0-1.6,6.5-6.5,10.6"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="400.8" y1="85.6" x2="405.7" y2="93"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M224.2,100.7v5.7c0,0.7-0.6,1.3-1.3,1.2c-1.8-0.1-5.2-0.8-8-4.2
        c-0.4-0.4-0.6-1-0.6-1.6v-6.4c0-0.4,0.5-0.7,0.9-0.5l7.9,3.9C223.7,99.3,224.2,100,224.2,100.7z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M237.2,106.5v5.7c0,0.7-0.6,1.3-1.3,1.2c-1.8-0.1-5.2-0.8-8-4.2
        c-0.4-0.4-0.6-1-0.6-1.6v-6.4c0-0.4,0.5-0.7,0.9-0.5l7.9,3.9C236.8,105,237.2,105.7,237.2,106.5z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M384.4,99.9v5.7c0,0.7,0.6,1.3,1.3,1.2c1.8-0.1,5.2-0.8,8-4.2
        c0.4-0.4,0.6-1,0.6-1.6v-6.4c0-0.4-0.5-0.7-0.9-0.5l-7.9,3.9C384.8,98.5,384.4,99.2,384.4,99.9z"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M371.3,105.6v5.7c0,0.7,0.6,1.3,1.3,1.2c1.8-0.1,5.2-0.8,8-4.2
        c0.4-0.4,0.6-1,0.6-1.6v-6.4c0-0.4-0.5-0.7-0.9-0.5l-7.9,3.9C371.8,104.2,371.3,104.9,371.3,105.6z"/>
      <rect x="277.3" y="130.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="54" height="12.3"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M218.4,153.5h16.4l13.1-10.6c0,0,3-3.7-0.3-6.9
          c-0.3-0.3-0.7-0.4-1.1-0.4h-20.7c-0.7,0-1.4,0.1-2.1,0.4c-1.6,0.7-4.1,2.5-5.2,6.9V153.5z"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="249.5" y1="139.6" x2="277.3" y2="139.6"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M276.5,141.2H265c0,0-4.9-0.8-11.4,4.9l-13.1,9.8
          c0,0-1.6,1.6-4.9,1.6h-18c0,0-2.5,0-3.3-2.5v-9.8c0,0,0-7.4,6.5-12.3c0,0,1.6-1.6,4.1-1.6h30.3l13.9,8.2"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="276.5" y1="133" x2="258.5" y2="133"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M391,153.5h-16.4l-13.1-10.6c0,0-3-3.7,0.3-6.9
          c0.3-0.3,0.7-0.4,1.1-0.4h20.7c0.7,0,1.4,0.1,2.1,0.4c1.6,0.7,4.1,2.5,5.2,6.9V153.5z"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="359.9" y1="139.6" x2="331.7" y2="139.6"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M331.7,141.2h12.7c0,0,4.9-0.8,11.4,4.9l13.1,9.8
          c0,0,1.6,1.6,4.9,1.6h18c0,0,2.5,0,3.3-2.5v-9.8c0,0,0-7.4-6.5-12.3c0,0-1.6-1.6-4.1-1.6h-30.3l-13.9,8.2"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="331.7" y1="133" x2="350.9" y2="133"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="249.5" y1="153.5" x2="359.1" y2="153.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="243.8" y1="157.6" x2="364" y2="157.6"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="240.1" y1="160.8" x2="368.5" y2="160.8"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="270.8" y1="145.3" x2="270.8" y2="153.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="273.2" y1="145.7" x2="273.2" y2="153.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="335.4" y1="145.3" x2="335.4" y2="153.5"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="337.8" y1="145.7" x2="337.8" y2="153.9"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M202.9,94.2l-0.8,72.6c0,2.5,0.6,5,1.9,7.1
          c1.5,2.5,4.2,4.9,8.7,4.9h8.2"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.8,178.8h8.2c4.5,0,7.2-2.5,8.7-4.9c1.3-2.1,1.9-4.6,1.9-7.1
          v-5.2"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M405.7,94.2l0.8,72.6c0,2.5-0.6,5-1.9,7.1
          c-1.5,2.5-4.2,4.9-8.7,4.9h-8.2"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M391.8,178.8h-8.2c-4.5,0-7.2-2.5-8.7-4.9
          c-1.3-2.1-1.9-4.6-1.9-7.1v-5.2"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M211.9,73.3v-5.7c0,0-4.1,0.8-5.7,2.5v9L211.9,73.3"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M211.9,67.6c0,0,1.6-2.5,1.6-4.1v-7.4c0,0,0-1.6-1.6-1.6h-10.7
          c-0.6,0-1.1,0.1-1.7,0.2c-1.9,0.4-5.9,1.6-7.2,4.7c0,0-0.8,1.6-0.8,4.9v2.3c0,0.8,0.2,1.7,0.7,2.3c0.5,0.7,1.3,1.5,2.6,1.9h11.4"
          />
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M191.5,61.1c0,0,6.5,4.1,15.5,4.1h6.1"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="211.9" y1="67.6" x2="191.5" y2="67.6"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397.5,73.3v-5.7c0,0,4.1,0.8,5.7,2.5v9L397.5,73.3"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397.5,67.6c0,0-1.6-2.5-1.6-4.1v-7.4c0,0,0-1.6,1.6-1.6h10.7
          c0.6,0,1.1,0.1,1.7,0.2c1.9,0.4,5.9,1.6,7.2,4.7c0,0,0.8,1.6,0.8,4.9v2.3c0,0.8-0.2,1.7-0.7,2.3c-0.5,0.7-1.3,1.5-2.6,1.9h-11.4"
          />
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M417.9,61.1c0,0-6.5,4.1-15.5,4.1h-6.1"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="397.5" y1="67.6" x2="417.9" y2="67.6"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="262.6" y1="66" x2="274.9" y2="93"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="346" y1="66" x2="333.7" y2="93"/>
    </g>
  </g>
  <g id="Layer_4">
    <g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M312.7,771c0,0,43.5,0.8,68.3,3.4c1.7,0.2,3.4,0.6,5,1.3
          c2.1,1,5,2.7,7,5.2v3.6l8.1,25.3c0,0,1.8,2.7,3.6,4.5l15.3,9.9c0,0,6.3,6.3,7.2,15.3v18l-0.9,23.5l-1.8,17.1
          c0,0-2.7,17.1-6.3,19.8h-14.4c0,0-9,0-11.7,1.8h-51.4c0,0-4.5,0.9-8.1,0.9c0,0-5.4,0-9-3.6h-10.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M319,771c0,0-43.5,0.8-68.3,3.4c-1.7,0.2-3.4,0.6-5,1.3
          c-2.1,1-5,2.7-7,5.2v3.6l-8.1,25.3c0,0-1.8,2.7-3.6,4.5l-15.3,9.9c0,0-6.3,6.3-7.2,15.3v18l0.9,23.5l1.8,17.1
          c0,0,2.7,17.1,6.3,19.8h14.4c0,0,9,0,11.7,1.8H291c0,0,4.5,0.9,8.1,0.9c0,0,5.4,0,9-3.6h10.4"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313.6,782.7h28c0,0,20.8,0.7,33.2,5.1c3,1.1,5.6,2.8,7.9,5
          c2.4,2.3,5.5,5.9,7.5,9.8c1.6,3.2,1.9,6.9,1.1,10.4l-0.1,0.4h-77.6"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M319.5,782.7h-28c0,0-20.8,0.7-33.2,5.1c-3,1.1-5.6,2.8-7.9,5
          c-2.4,2.3-5.5,5.9-7.5,9.8c-1.6,3.2-1.9,6.9-1.1,10.4l0.1,0.4h77.6"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M313.7,785h24.7c0,0,18.3,0.6,29.3,4.1c2.6,0.9,5,2.3,7,4
          c2.1,1.9,4.9,4.7,6.6,7.9c1.4,2.6,1.7,5.6,0.9,8.4l-0.1,0.3h-68.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.9,785h-24.7c0,0-18.3,0.6-29.3,4.1c-2.6,0.9-5,2.3-7,4
          c-2.1,1.9-4.9,4.7-6.6,7.9c-1.4,2.6-1.7,5.6-0.9,8.4l0.1,0.3h68.4"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M290.8,785.4c0,0,0.2,1.8,2,2.7h24.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M341.3,785.4c0,0-0.2,1.8-2,2.7h-24.4"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M210.8,825.1c0,0,8.1,5.4,18.9,3.6c0,0,32.5-9.9,62.2-11.7h24.4"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M420.5,825.1c0,0-8.1,5.4-18.9,3.6c0,0-32.5-9.9-62.2-11.7h-24.4"
          />
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M241.4,813.4l-3,3c-0.7,0.7-1.2,1.6-1.4,2.5
        c-0.1,0.8-0.1,1.8,0.7,2.6l6.3,9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M391.3,813.4l3,3c0.7,0.7,1.2,1.6,1.4,2.5c0.1,0.8,0.1,1.8-0.7,2.6
        l-6.3,9"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M268.9,859.4h-29.3l16.2-12.6l8.1,9.9c0,0,2.7,2.7,5.4,2.7h48.7"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M364.3,859.4H393l-15.8-12.6l-8.1,9.9c0,0-2.7,2.7-5.4,2.7h-48.7"
          />
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M269.1,832.1l5.3,5.3c2,2,2.6,5,1.4,7.5c0,0,0,0.1-0.1,0.1
          c0,0-0.9,1.8-6.3,1.8h-41.2c-0.2,0-0.4,0-0.6,0c-1-0.1-4.2,0-6.4-1c-1.3-0.6-2.5-1.1-3.3-2.3l0,0c-0.5-0.8-0.8-1.7-0.8-2.7v-3.7
          c0-2.7,1.7-5.2,4.2-6c1-0.3,2.3-0.5,3.9-0.5h40.2C266.8,830.5,268.1,831.1,269.1,832.1z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M228.2,844.4l5.1-2.2h37.9c0,0,0.6-0.2,1.3-0.5
          c0.9-0.4,1.1-1.6,0.4-2.3l-3.9-3.9c-0.9-0.9-2.1-1.4-3.3-1.4h-43.5c-0.8,0-1.4,0.6-1.4,1.4v7.6c0,0,0,0.9,5,1.6
          C226.5,844.9,227.4,844.8,228.2,844.4z"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M363.3,832.1l-5.3,5.3c-2,2-2.6,5-1.4,7.5c0,0,0,0.1,0.1,0.1
          c0,0,0.9,1.8,6.3,1.8h41.2c0.2,0,0.4,0,0.6,0c1-0.1,4.2,0,6.4-1c1.3-0.6,2.5-1.1,3.3-2.3l0,0c0.5-0.8,0.8-1.7,0.8-2.7v-3.7
          c0-2.7-1.7-5.2-4.2-6c-1-0.3-2.3-0.5-3.9-0.5H367C365.6,830.5,364.2,831.1,363.3,832.1z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M404.2,844.4l-5.1-2.2h-37.9c0,0-0.6-0.2-1.3-0.5
          c-0.9-0.4-1.1-1.6-0.4-2.3l3.9-3.9c0.9-0.9,2.1-1.4,3.3-1.4h43.5c0.8,0,1.4,0.6,1.4,1.4v7.6c0,0,0,0.9-5,1.6
          C405.8,844.9,405,844.8,404.2,844.4z"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="269.4" y1="831.4" x2="364.1" y2="831.4"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="270.8" y1="834.1" x2="360.5" y2="834.1"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M204.5,858.5c0,0,10.8-7.2,13.5-15.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M427.2,857.6c0,0-9-6.3-12.6-14.4"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M205.4,870.2c0,0,13.5-8.1,28.9-8.1h83.9"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M426.8,870.2c0,0-13.5-8.1-28.9-8.1H314"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M257.7,871.1h-24.4c0,0-8.1-0.9-10.8,3.6c0,0-4.5,4.5-5.4,19.8
        c0,0,0,4.5,4.5,4.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M375.4,871.1h24.4c0,0,8.1-0.9,10.8,3.6c0,0,4.5,4.5,5.4,19.8
        c0,0,0,4.5-4.5,4.5"/>
      <rect x="285.6" y="871.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="61.3" height="16.2"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.6,871.1c0,0-16.2,1.8-23.5,16.2"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.6,873.8c0,0-11.7,0.9-20.7,13.5"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M285.6,887.4h-32.5h-3.6c0,0-12.6,0.9-16.2,5.4c0,0-2.1,2.5-4,5.6
          c-1.5,2.4-2.3,5.2-2.3,8v3.6h32.9c1.1,0,2.2-0.3,3.1-0.9c1.7-1,4-3,4.5-6.4v-15.3"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M347,871.1c0,0,16.2,1.8,23.5,16.2"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M347,873.8c0,0,11.7,0.9,20.7,13.5"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M347,887.4h32.5h3.6c0,0,12.6,0.9,16.2,5.4c0,0,2.1,2.5,4,5.6
          c1.5,2.4,2.3,5.2,2.3,8v3.6h-32.9c-1.1,0-2.2-0.3-3.1-0.9c-1.7-1-4-3-4.5-6.4v-15.3"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="265.8" y1="907.2" x2="366.8" y2="907.2"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="263.1" y1="909" x2="370" y2="909"/>
      <rect x="285.6" y="894.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.7" height="12.6"/>
      <rect x="315.4" y="894.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.7" height="12.6"/>
      <rect x="344.3" y="894.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="2.7" height="12.6"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="233.3" cy="865.7" r="1.8"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="288.3" cy="865.7" r="1.8"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="345.2" cy="865.7" r="1.8"/>
      <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="400.2" cy="865.7" r="1.8"/>
      
        <ellipse transform="matrix(0.9575 -0.2886 0.2886 0.9575 -223.4498 157.4471)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="422.3" cy="836.6" rx="2.2" ry="8.1"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M231.2,896.2h29.2c0,0,2.6,0.8,7.2-4.9"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M232.8,898.5c0.3-0.2,0.7-0.3,1-0.3h10.3c0,0,1.8,0,1.8,1.8v2.7
          c0,0-1.8,3.6-3.6,4.5h-11.3c-1.1,0-2-0.7-2.2-1.8c-0.1-0.3-0.1-0.6-0.1-0.9C228.8,904.5,229.6,900.4,232.8,898.5z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M251.8,898.5c0.3-0.2,0.7-0.3,1-0.3h10.3c0,0,1.8,0,1.8,1.8v2.7
          c0,0-1.8,3.6-3.6,4.5H250c-1.1,0-2-0.7-2.2-1.8c-0.1-0.3-0.1-0.6-0.1-0.9C247.8,904.5,248.6,900.4,251.8,898.5z"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.4,896.2h-29.2c0,0-2.6,0.8-7.2-4.9"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M399.7,898.5c-0.3-0.2-0.7-0.3-1-0.3h-10.3c0,0-1.8,0-1.8,1.8v2.7
          c0,0,1.8,3.6,3.6,4.5h11.3c1.1,0,2-0.7,2.2-1.8c0.1-0.3,0.1-0.6,0.1-0.9C403.7,904.5,402.9,900.4,399.7,898.5z"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M380.8,898.5c-0.3-0.2-0.7-0.3-1-0.3h-10.3c0,0-1.8,0-1.8,1.8v2.7
          c0,0,1.8,3.6,3.6,4.5h11.3c1.1,0,2-0.7,2.2-1.8c0.1-0.3,0.1-0.6,0.1-0.9C384.8,904.5,384,900.4,380.8,898.5z"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="209" y1="906.3" x2="227.9" y2="900.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="216.2" y1="918" x2="227" y2="909.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="233.8" y1="909.9" x2="227" y2="918"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="424.1" y1="906.3" x2="405.1" y2="900.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="416.9" y1="918" x2="406" y2="909.9"/>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="399.3" y1="909.9" x2="406" y2="918"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M261.3,910l4.5,4.4c0,0,2.7,2.7,6.3,2.7h36.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M371.4,910l-4.5,4.4c0,0-2.7,2.7-6.3,2.7H324"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M272.1,917.1h36.5"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M204.5,855.8l-0.9,72.8c0,2.5,0.3,5,1.2,7.4c1.1,2.9,3,6.1,6.5,7.1
          c0.7,0.2,1.4,0.2,2.1,0.2h9.1"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M240.5,920.3l0,8.3c0,2.5-0.3,5-1.2,7.4c-1.1,2.9-3,6.1-6.5,7.1
          c-0.7,0.2-1.4,0.2-2.1,0.2h-9.1"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M427.3,855.8l0.9,72.8c0,2.5-0.3,5-1.2,7.4c-1.1,2.9-3,6.1-6.5,7.1
          c-0.7,0.2-1.4,0.2-2.1,0.2h-9.1"/>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M391.2,920.3l0,8.3c0,2.5,0.3,5,1.2,7.4c1.1,2.9,3,6.1,6.5,7.1
          c0.7,0.2,1.4,0.2,2.1,0.2h9.1"/>
      </g>
      <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="240.5" y1="923.4" x2="391.2" y2="923.4"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M238.7,780.9c0,0-15.3,22.5-21.6,39.7"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="238.7" y1="782.7" x2="221.6" y2="817.9"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M393,780.9c0,0,15.3,22.5,21.6,39.7"/>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="393" y1="782.7" x2="410.1" y2="817.9"/>
      </g>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M249.6,774.6c0,0,3.6,1.8,3.6,7.2v9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M253.2,774.2c0,0,3.6,1.8,3.6,7.2v7.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M383,774.6c0,0-3.6,1.8-3.6,7.2v9"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M379.4,774.2c0,0-3.6,1.8-3.6,7.2v7.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M236,909.9c0,0,2.7,3.6,6.3,3.6h22.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M254.1,913.5c0,0,0.9,3.6,5.4,6.3"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M397,909.9c0,0-2.7,3.6-6.3,3.6h-22.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M379,913.5c0,0-0.9,3.6-5.4,6.3"/>
      <rect x="290.1" y="912.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.9" height="4.5"/>
      <rect x="322.6" y="912.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="18.9" height="4.5"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M275.7,916.7c0-1.7,1.7-3.2,3.8-3.2s3.8,1.4,3.8,3.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M346.1,916.7c0-1.7,1.7-3.2,3.8-3.2s3.8,1.4,3.8,3.2"/>
      <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M312.7,916.7c0-1.7,1.7-3.2,3.8-3.2s3.8,1.4,3.8,3.2"/>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M211.7,823.3l2.8-4.2c0.5-0.8,0.8-1.7,0.8-2.6V809
          c0-0.9-0.4-1.8-1.1-2.3c-0.4-0.3-0.9-0.5-1.6-0.5h-10.8c0,0-4.7,1-7.8,3.5c-1.4,1.1-2.2,2.9-2.2,4.7v5c0,1.1,0.3,2.2,1,3
          c0.6,0.7,1.4,1.4,2.6,1.8h15.3L211.7,823.3z"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M210,822.1l2.4-3.5c0.4-0.6,0.7-1.4,0.7-2.2
          v-6.2c0-0.7-0.3-1.5-0.9-1.9c-0.4-0.2-0.8-0.4-1.3-0.4h-9.2c0,0-4,0.9-6.6,2.9c-1.2,0.9-1.8,2.4-1.8,3.9v4.1
          c0,0.9,0.3,1.8,0.9,2.5c0.5,0.6,1.2,1.2,2.2,1.5h13L210,822.1z"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M420,823.3l-2.8-4.2c-0.5-0.8-0.8-1.7-0.8-2.6V809
          c0-0.9,0.4-1.8,1.1-2.3c0.4-0.3,0.9-0.5,1.6-0.5H430c0,0,4.7,1,7.8,3.5c1.4,1.1,2.2,2.9,2.2,4.7v5c0,1.1-0.3,2.2-1,3
          c-0.6,0.7-1.4,1.4-2.6,1.8h-15.3L420,823.3z"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M421.7,822.1l-2.4-3.5
          c-0.4-0.6-0.7-1.4-0.7-2.2v-6.2c0-0.7,0.3-1.5,0.9-1.9c0.4-0.2,0.8-0.4,1.3-0.4h9.2c0,0,4,0.9,6.6,2.9c1.2,0.9,1.8,2.4,1.8,3.9
          v4.1c0,0.9-0.3,1.8-0.9,2.5c-0.5,0.6-1.2,1.2-2.2,1.5h-13L421.7,822.1z"/>
      </g>
    </g>
  </g></>)
  },
};
