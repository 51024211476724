import { gql } from "apollo-boost";

export const CREATE_PAYMENT = gql`
  mutation consumerCreatePayment($payment: ConsumerCreatePaymentInput!, $saveDetails: Boolean, $collectDeposit: Boolean, $creditNotesToAdjust: [AdjustCreditNoteInput]) {
    consumerCreatePayment(payment: $payment, saveDetails: $saveDetails, collectDeposit: $collectDeposit, creditNotesToAdjust: $creditNotesToAdjust) {
      success
      message
      payment {
        amount
        convergePaymentSessionUrl
        convergeUSSessionToken
        stripePaymentIntentSecret
      }
    }
  }
`;

export const CREATE_INVOICE_PAYMENT = gql`
  mutation createPayment($payment: CreatePaymentInput!, $saveDetails: Boolean, $collectDeposit: Boolean) {
    createPayment(payment: $payment, saveDetails: $saveDetails, collectDeposit: $collectDeposit) {
      success
      message
      payment {
        id
        convergePaymentSessionUrl
        convergeUSSessionToken
        stripeCheckoutSessionId
        stripePaymentIntentSecret
      }
    }
  }
`;

export const GET_PAYMENT = gql`
  query consumerGetPayment($id : String!) {
    consumerGetPayment(id : $id) {
      id
      status
      booking {
        id
      }
    }
  }
`;
