import { Dialog, DialogTitle, Typography, DialogContent, Slide, makeStyles, Theme, createStyles, Grid, Paper, Box, Avatar, CircularProgress, Fab, Card, CardHeader, IconButton, CardContent, CardActions } from '@material-ui/core'
import styles from "./index.module.css";
import { TransitionProps } from '@material-ui/core/transitions';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { IBooking, ISwapRequest, ISwapRequestCreateInput, IVehicle, SwapRequestStatus } from '../../../../reducers/bookings/types';
import { DateTime as d } from "luxon";
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import { WhiteCircularProgress } from '../../../TopBar/TopBar';
import { SimpleDateTimePicker } from '../../../SimpleDateTimePicker/intex';
import { useLazyQuery } from '@apollo/client';
import { GET_CONSUMER_AVAILABLE_VEHICLES_FOR_SWAP } from '../../../../graphql/bookings/getAvailableVehiclesForSwap';
import { Storage } from "aws-amplify";
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { getFeatureTypeIcon, getVehicleTypeIcon } from '../../../utils';
import { capitalizeFirstLetter } from '../../utils';
import { cloneDeep } from "lodash";

const swapRequestInitials: ISwapRequestCreateInput = {
  dateForSwap: d.now().set({ hour: 0, minute: 0, millisecond: 0 }).toUTC().toISO(),
  notes: "",
  bookingId: "",
  vehicleAllocated: ""
}

interface IProps {
  open: boolean;
  onClose: () => void;
  onCreateSwapRequest: (data: ISwapRequestCreateInput) => void;
  activeSubscription: any;
  swapRequest?: ISwapRequest;
  cancelSwapRequest: (swapRequestId: string) => void
  loadingCancelSwap: boolean;
  loadingUpdateBookingSwapData: boolean;
  loadingCreateSwapRequest: boolean;
  booking: IBooking;
}

interface IDateTime {
  date: string;
  time: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    iconStyle: {
      padding: 2
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    paperStyle: {
      flexGrow: 1
    },
    transparentInput: {
      border: 0,
      outline: "none",
      cursor: "pointer",
      width: 136,
      opacity: 0
    },
    actionBtn: {
      backgroundColor: theme.palette.primary.main
    },
    subButn: {
      backgroundColor: theme.palette.primary.main,
      border: "none",
      fontSize: 16,
      color: "#fff",
      width: "100%",
      height: 0,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      }
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IEvents {
  eventBy: string;
  eventTime: string;
  notes: string;
  userType: string
}

const GenerateSwapRequestDialog: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const branchData = website.branches;

  const [values, setValues] = useState<ISwapRequestCreateInput>(swapRequestInitials);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [availableVehiclesForSwap, setAvailableVehiclesForSwap] = useState<IVehicle[]>([])
  const [searchClicked, setSearchClicked] = useState<boolean>(false)
  const [vehicleImages, setVehicleImages] = useState<any>([]);
  const [events, setEvents] = useState<IEvents[]>([])
  const [
    getVehiclesForSwap,
    { data: vehiclesData, loading: loadingVehicleForSwap }
  ] = useLazyQuery(GET_CONSUMER_AVAILABLE_VEHICLES_FOR_SWAP, {
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (vehiclesData && vehiclesData.consumerAvailableVehiclesForSwap.length) {
      getVehicleImages(vehiclesData.consumerAvailableVehiclesForSwap)
      const updatedVehicles: IVehicle[] = []
      vehiclesData.consumerAvailableVehiclesForSwap.forEach((vehicle: IVehicle) => {
        if (vehicle.id === props.swapRequest?.vehicleAllocated?.id) {
          updatedVehicles.push({ ...vehicle, selected: true })
        } else {
          updatedVehicles.push({ ...vehicle, selected: false })
        }
      });
      setAvailableVehiclesForSwap(updatedVehicles)
    }
  }, [vehiclesData])

  useEffect(() => {
    if (props.swapRequest?.id && props.swapRequest.vehicleAllocated && props.swapRequest.vehicleAllocated.id) {
      getVehiclesForSwap({
        variables: {
          bookingId: props.booking.id,
          startDate: props.swapRequest.dateForSwap,
          endDate: props.booking.dropoffDateTime
        }
      })
      setIsUpdate(true)
      if (props.swapRequest.events) {
        setEvents(props.swapRequest.events)
      }
      setValues({
        ...values,
        dateForSwap: props.swapRequest.dateForSwap,
        vehicleAllocated: props.swapRequest.vehicleAllocated.id
      })
    }
  }, [props.swapRequest])

  const getVehicleImages = (vehicles: IVehicle[]) => {
    vehicles.map(async (vehicle: any) => {
      try {
        if (vehicle && vehicle.imageUrl) {
          const config = { contentType: "image/*", level: "public" };
          const file = await Storage.get(vehicle.imageUrl, config).then((result) => {
            return result;
          });
          const response = await fetch(file as string).then((res) => {
            return res;
          });
          if (response.ok) {
            setVehicleImages([...vehicleImages, {
              vehicleId: vehicle.id,
              imageUrl: file
            }]);
          }
        }
      } catch (error) {
        snackbar({
          message: "Invalid File Type. Allowed type: jpeg or png",
          variant: SnackBarVariant.ERROR
        });
      }
    })
  }

  const onSearchVehicleAvailabilityForSwap = (bookingId: string, startDate: string, endDate: string) => {
    getVehiclesForSwap({
      variables: {
        bookingId: bookingId,
        startDate: startDate,
        endDate: endDate
      }
    })
  }

  const onSelectVehicle = (vehicle: IVehicle) => {
    const allVehicles = cloneDeep(availableVehiclesForSwap)
    const newVehicleList: IVehicle[] = allVehicles.map(v => {
      if (v.id === vehicle.id) {
        if (!v.selected) {
          v.selected = true
          setValues({
            ...values,
            vehicleAllocated: vehicle.id || ""
          })
          return v
        } else {
          v.selected = false
          setValues({
            ...values,
            vehicleAllocated: ""
          })
          return v
        }
      }
      v.selected = false
      return v
    })
    setAvailableVehiclesForSwap(newVehicleList)
  }

  const renderAvailableVehicles = (availableVehicles: IVehicle[]) => {
    const vehicles = cloneDeep(availableVehicles)
    if (vehicles && vehicles.length) {
      return <>
        <Grid item xs={12}>
          <Typography variant='h4' >Select Vehicle</Typography>
        </Grid>
        {
          vehicles.map((vehicle: IVehicle, index: number) => {
            if (vehicleImages && vehicleImages.length > 0) {
              vehicleImages.find((vehicleImage: any, index: number) => {
                if (vehicleImage.vehicleId === vehicle.id) {
                  vehicle.imageUrl = vehicleImage.imageUrl;
                }
              });
            }
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
              >
                <Card style={{ boxShadow: vehicle.selected ? "rgba(0, 0, 0, 0.19) 0px 10px 20px" : "" }}>
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">

                      </IconButton>
                    }
                    title={<Typography variant='h4' style={{ fontSize: 20 }}>{`${vehicle.make} ${vehicle.model}`}</Typography>}
                    subheader={<Typography variant='caption'></Typography>}
                  />
                  <CardContent>
                    <Grid item xs={12} container justify='center'>
                      {
                        vehicle.imageUrl ? (

                          <img
                            src={vehicle.imageUrl}
                            alt='vehicle'
                            style={{ width: "100%", height: "150px" }}
                          />
                        ) : (
                          <img
                            src={getVehicleTypeIcon(capitalizeFirstLetter(vehicle.bodyType))}
                            alt='vehicle'
                            style={{ width: "100%", height: "150px" }}
                          />
                        )
                      }
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <img
                          style={{
                            height: "12px",
                            marginRight: "10px",
                            width: "16px",
                          }}
                          src={getFeatureTypeIcon("numberOfDoors")}
                          alt={"numberOfDoors"}
                        />
                        <span style={{ fontSize: 12 }}>{`${vehicle.numberOfDoors} Doors`}</span>
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          style={{
                            height: "12px",
                            marginRight: "10px",
                            width: "16px",
                          }}
                          src={getFeatureTypeIcon("numberOfSeats")}
                          alt={"numberOfSeats"}
                        />
                        <span style={{ fontSize: 12 }}>{`${vehicle.numberOfSeats} Seats`}</span>
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          style={{
                            height: "12px",
                            marginRight: "10px",
                            width: "16px",
                          }}
                          src={getFeatureTypeIcon("transmission")}
                          alt={"transmission"}
                        />
                        <span style={{ fontSize: 12 }}>{`${vehicle.transmission}`}</span>
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          style={{
                            height: "12px",
                            marginRight: "10px",
                            width: "16px",
                          }}
                          src={getFeatureTypeIcon("fuelType")}
                          alt={"fuelType"}
                        />
                        <span style={{ fontSize: 12 }}>{`${vehicle.fuelType}`}</span>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.actionBtn} disableSpacing>
                    <Grid item xs={12} container justify='center'>
                      <Fab
                        className={`${classes.subButn} test `}
                        onClick={() => {
                          onSelectVehicle(vehicle)
                        }}
                        disabled={props.swapRequest?.status === SwapRequestStatus.COMPLETED}
                      >
                        {
                          <Typography variant="h3" style={{ fontWeight: 600, color: "#D3D3D3" }}>{vehicle.selected ? "Remove" : "Select Vehicle"}</Typography>
                        }
                      </Fab>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )
          })
        }
      </>
    }

  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
      aria-describedby="pricing-edit-fields"
      maxWidth={"md"}
      style={{ flexGrow: 1 }}
      classes={{
        paper: classes.paperStyle
      }}
    >
      <DialogTitle id="simple-dialog-title">
        <Grid item xs={12} spacing={2} container justify='space-between' style={{ marginTop: 5 }}>
          <Typography variant='h3'>{isUpdate && "Update"} Vehicle Swap Request</Typography>
          <Typography variant='h4'> Requested on: <span style={{ fontWeight: 300 }}>{d.now().toFormat("dd/MM/yyyy hh:mm a")}</span></Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.root}>
            <Formik
              enableReinitialize
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                if (props.swapRequest?.id) {
                  values.id = props.swapRequest.id
                }
                props.onCreateSwapRequest(values)
                setSubmitting(false)
              }}
            >
              {(formikProps) => (
                <Form>
                  <Grid item xs={12}>
                    <Grid container xs={12} spacing={2} >
                      {
                        props.swapRequest?.status !== SwapRequestStatus.COMPLETED && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant='h3'>Check Vehicle Availability</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <SimpleDateTimePicker
                                classId="flatpickr-container"
                                date={formikProps.values.dateForSwap}
                                handleChange={(date: IDateTime) => {
                                  if (date && date.date) {
                                    setSearchClicked(false)
                                    setValues({
                                      ...formikProps.values,
                                      vehicleAllocated: "",
                                      dateForSwap: date.date
                                    })
                                    setAvailableVehiclesForSwap([])
                                  }
                                }}
                                required={true}
                                name={"dateForSwap"}
                                dateTitle={"Swap Date"}
                                timeTitle={"Swap Time"}
                                minDate={d.now().toUTC().toISO()}
                                maxDate={props?.activeSubscription?.endDate}
                              />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                              <Fab
                                variant="extended"
                                size="small"
                                disabled={!values.dateForSwap}
                                onClick={() => {
                                  if (props.booking && props.booking.dropoffDateTime) {
                                    setSearchClicked(true)
                                    onSearchVehicleAvailabilityForSwap(props.booking.id, formikProps.values.dateForSwap, props.booking.dropoffDateTime)
                                  }
                                }}
                              >
                                Search
                              </Fab>
                            </Grid>
                          </>
                        )
                      }
                      {
                        <>
                          {
                            loadingVehicleForSwap ? (
                              <Grid item xs={3}>
                                <CircularProgress size={20} />
                              </Grid>
                            ) : renderAvailableVehicles(availableVehiclesForSwap)
                          }
                          {
                            !formikProps.values.dateForSwap && !searchClicked ? <Grid item xs={12}><Typography variant='h4'>Please select date and click on search for vehicle availability</Typography></Grid>
                              : !loadingVehicleForSwap && searchClicked && !availableVehiclesForSwap.length && <Grid item xs={12}><Typography variant='h4'>No Vehicle found for the selected date</Typography></Grid>
                          }
                        </>
                      }
                      <Grid item xs={12}>
                        <Typography variant='h4' >Notes</Typography>
                        <Box mt={0.5}></Box>
                        <Field
                          component={TextField}
                          name={`note`}
                          placeholder="Write your note here"
                          fullWidth
                          required
                          rows={4}
                          multiline
                          inputProps={{
                            value: formikProps.values.notes,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...values,
                                notes: e.target.value
                              })
                            }
                          }}
                        ></Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container justify='space-between' style={{ marginTop: 20, marginBottom: 30 }}>
                      <Fab
                        variant="extended"
                        color="primary"
                        size="small"
                        type='submit'
                        style={{
                          background: "var(--primary-color)",
                          boxShadow: "none",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                        disabled={props.swapRequest?.status === SwapRequestStatus.CANCELLED || props.swapRequest?.status === SwapRequestStatus.COMPLETED || (props.loadingCreateSwapRequest || props.loadingUpdateBookingSwapData) || !values.vehicleAllocated}
                      >
                        {(props.loadingUpdateBookingSwapData || props.loadingCreateSwapRequest) && <WhiteCircularProgress style={{ marginRight: 10 }} size="small" thickness={5} />}
                        {props.swapRequest?.id ? "Update Request" : "Generate Request"}
                      </Fab>
                      {
                        (props.swapRequest?.status === SwapRequestStatus.PENDING ||
                          props.swapRequest?.status === SwapRequestStatus.APPROVED) ? (
                          <Fab
                            variant="extended"
                            color="primary"
                            size="small"
                            onClick={() => {
                              if (props.swapRequest?.id) {
                                props.cancelSwapRequest(props.swapRequest.id)
                              }
                            }}
                          >
                            {props.loadingCancelSwap && <WhiteCircularProgress size="small" thickness={5} style={{ marginRight: 5 }} />}
                            {"Cancel Request"}
                          </Fab>
                        ) : null
                      }

                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20, marginBottom: 30 }}>
                      {
                        events && events.length > 0 &&
                        <div style={{ maxHeight: "100vh", overflow: "scroll" }}>
                          <Typography variant='h2'>Notes</Typography>
                          {
                            [...events].sort((a, b) => b.eventTime > a.eventTime ? 1 : -1).map(event => {
                              return (
                                <>
                                  {
                                    event.userType === "operator" ?
                                      (
                                        <Grid item xs={12} container spacing={2}>
                                          <Grid item>
                                            <Typography variant={"h3"}><Avatar><PersonSharpIcon fontSize='small' ></PersonSharpIcon></Avatar></Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Typography variant='h4'><span style={{ fontSize: 15 }}>Operator</span></Typography>
                                            <div className={styles.swapNoteBox}>
                                              <Typography variant={"h3"}><span style={{ fontSize: 14 }}>{event.notes}</span></Typography>
                                              <Typography variant={"caption"}>{d.fromISO(event.eventTime).toFormat("dd/mm/yyyy hh:mm a")}</Typography>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      ) :
                                      (
                                        <Grid item xs={12} container spacing={2} justify='flex-end'>
                                          <Grid item>
                                            <Typography variant={"h3"}><Avatar><PersonSharpIcon fontSize='small' ></PersonSharpIcon></Avatar></Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Typography variant='h4'><span style={{ fontSize: 15 }}>You</span></Typography>
                                            <div className={styles.swapNoteBox}>
                                              <Typography variant={"h3"}><span style={{ fontSize: 14 }}>{event.notes}</span></Typography>
                                              <Typography variant={"caption"}>{d.fromISO(event.eventTime).toFormat("dd/mm/yyyy hh:mm a")}</Typography>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      )
                                  }
                                </>
                              )
                            })
                          }
                        </div>
                      }
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default GenerateSwapRequestDialog;
