import React from "react"
export const VanLutonSvg = {
	interior: {
		width: 162.3,
		height: 360.2,
		svg: (<><g id="Layer_1">
			<g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M18.5,106.1" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M18.3,105.7v-61c0,0,0.4-8.7,4.1-15.4c0,0,8.6-24.2,25.4-24.9h32.7"
				/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.5,105.7v-61c0,0-0.4-8.7-4.1-15.4c0,0-8.6-24.2-25.4-24.9H80.4
			  "/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M18.2,45c0,0,1.1-0.2,2.6-3.7c0,0,7.8-27,23.2-29c0,0,0-5.1,4.9-8"
				/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M142.8,45c0,0-1.1-0.2-2.6-3.7c0,0-7.8-27-23.2-29c0,0,0-5.1-4.9-8"
				/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="43.8" y1="12.1" x2="116.8" y2="12.1" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M42.3,20.2v12.4c0,0-8.3,3.1-13,14.4c-0.1,0.3-0.3,0.5-0.6,0.6
			  c-0.7,0.2-2,0.5-3.5,0.1c-0.6-0.2-1-0.8-1-1.4v-6.4c0-0.7,0.1-1.4,0.4-2C26,34.2,31.5,22.2,42.3,20.2z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M118.4,20.2v12.4c0,0,8.3,3.1,13,14.4c0.1,0.3,0.3,0.5,0.6,0.6
			  c0.7,0.2,2,0.5,3.5,0.1c0.6-0.2,1-0.8,1-1.4v-6.4c0-0.7-0.1-1.4-0.4-2C134.7,34.2,129.3,22.2,118.4,20.2z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M42.5,20.1c0,0,38.8-4.4,75.7,0" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M42.5,30.8c0,0,36.3-7.2,75.9,0.2" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="44.2" y1="20" x2="44.2" y2="11.9" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="116.8" y1="19.9" x2="116.8" y2="11.9" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="41.7" y1="32.7" x2="41.7" y2="42.4" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="119.1" y1="32.7" x2="119.1" y2="42.4" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M81,93.3H32.1c-5.2-1-5.2-5.2-5.2-5.2l0.4-34.8
			  c3.2-6.9,14.6-10.8,14.6-10.8c15.7-4.3,39-3.2,39-3.2l-0.2,0c0,0,23-1.1,38.5,3.2c0,0,11.3,3.9,14.4,10.8l0.4,34.8
			  c0,0,0,4.2-5.2,5.2H80.5"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M80.8,90.9l-45.9,0c-1.1,0-2.1-0.2-3.1-0.6
			  c-3.1-1.4-3.1-4.1-3.1-4.1L29,58.8c0-3.4,1.6-6.6,4.3-8.6c3.6-2.7,8.1-4.4,9.4-4.8c0.2-0.1,0.5-0.2,0.7-0.2
			  c15.1-3.8,37.2-2.8,37.2-2.8h-0.2c0,0,21.8-1,36.7,2.8c0.3,0.1,0.5,0.1,0.8,0.2c1.5,0.5,6.4,2.4,10,5.4c2.2,1.8,3.5,4.6,3.5,7.5
			  l0.4,27.9c0,0,0,2.7-3.1,4.1c-0.9,0.4-2,0.6-3,0.6l-45.3,0"/>
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M56.3,4.3L50.8,8c0,0-1.4,1.1,0.7,1.3h29" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M104.6,4.3L110,8c0,0,1.4,1.1-0.7,1.3h-29" />
				</g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M27.2,53.4c0,0-4.9,3.2-8.9,8" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M133.7,53.4c0,0,4.9,3.2,8.9,8" />
				<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M21.9,73.8c0,0,0.1-2.5-4-2.8c-0.2,0-0.5-0.1-0.7-0.2
			  c-0.7-0.4-2.5-1.5-3.9-2.3c-0.3-0.2-0.7-0.3-1.1-0.4c-1.6-0.2-6.1-0.6-8.9,2.4C3,71,2.6,71.7,2.4,72.2c-0.2,0.7-0.4,1.6-0.3,2.5
			  c0.1,1.2,1.3,2.2,2.5,1.9c0.9-0.2,2.1-0.7,3.9-2.1c0,0,2.8-2.1,6.6-0.7C15.1,73.9,21.5,79.1,21.9,73.8z"/>
				<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M140.5,73.8c0,0-0.1-2.5,4-2.8c0.2,0,0.5-0.1,0.7-0.2
			  c0.7-0.4,2.5-1.5,3.9-2.3c0.3-0.2,0.7-0.3,1.1-0.4c1.6-0.2,6.1-0.6,8.9,2.4c0.4,0.4,0.8,1.1,0.9,1.6c0.2,0.7,0.4,1.6,0.3,2.5
			  c-0.1,1.2-1.3,2.2-2.5,1.9c-0.9-0.2-2.1-0.7-3.9-2.1c0,0-2.8-2.1-6.6-0.7C147.3,73.9,140.9,79.1,140.5,73.8z"/>
				<rect x="16.6" y="77" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="127.5" height="280.3" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M106.9,38.1c-4-0.6-27.5-3.6-52.4-0.6c-0.4,0.1-0.8-0.1-1.1-0.5
			  c-0.5-0.8-1.2-2.4,0-5c0.2-0.5,0.8-0.9,1.3-1c4.8-0.7,28.4-3.6,50.9,0.1c0.5,0.1,1,0.4,1.2,0.9c0.7,1.3,1.8,3.9,1,5.7
			  C107.8,37.9,107.3,38.1,106.9,38.1z"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="55" y1="30.8" x2="55" y2="37.3" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="57.1" y1="30.8" x2="57.1" y2="37.4" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="59.3" y1="30.7" x2="59.3" y2="37.2" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="61.4" y1="30.1" x2="61.4" y2="36.7" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63.5" y1="30.1" x2="63.5" y2="36.7" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="65.6" y1="30.1" x2="65.6" y2="36.6" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="67.7" y1="30" x2="67.7" y2="36.5" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="69.8" y1="29.7" x2="69.8" y2="36.2" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="72" y1="29.5" x2="72" y2="36" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="74.1" y1="29.4" x2="74.1" y2="35.9" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.2" y1="29.3" x2="76.2" y2="35.8" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="78.3" y1="29.3" x2="78.3" y2="35.8" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="80.4" y1="29.3" x2="80.4" y2="35.9" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="82.5" y1="29.4" x2="82.5" y2="35.9" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.7" y1="29.5" x2="84.7" y2="36" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.8" y1="29.6" x2="86.8" y2="36.1" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="88.9" y1="29.7" x2="88.9" y2="36.2" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="91" y1="29.8" x2="91" y2="36.3" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.1" y1="29.8" x2="93.1" y2="36.6" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="95.2" y1="29.7" x2="95.2" y2="36.9" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.4" y1="30.1" x2="97.4" y2="36.9" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="99.5" y1="30.3" x2="99.5" y2="37.1" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="101.6" y1="30.5" x2="101.6" y2="37.3" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="103.7" y1="30.7" x2="103.7" y2="37.6" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="105.8" y1="31.1" x2="105.8" y2="37.9" />
			</g>
		</g>
			<g id="Layer_2">
				<g>
					<path fill="#FFFFFF" stroke="#010101" stroke-miterlimit="10" d="M46.6,55.1H70c0,0,3.4,0.6,4.8,5.9l0.2,13.2c0,0-1.8,7.5-6.5,9.9
			  h-21c0,0-4.3-2.9-6.1-9.8V60.7C41.3,60.7,43,55.7,46.6,55.1z"/>
					<path fill="none" stroke="#010101" stroke-miterlimit="10" d="M75.4,81.8L74.9,81H72L70,83l-2.3,1.1L58,84v0L48,84.2l-2.1-1.5
			  l-1.6-1.9L41,81l-0.9,1.5l-0.5,3.8V98l0.4,3l1.2,2.1l1.5,1.3l2.4,1h3.3h1.4l0.1,0.8h8v0h8l0.1-0.8h1.4h3.3l2.4-1l1.5-1.3l1.2-2.1
			  l0.4-3V86.3l-0.4-3.1C75.9,82.8,75.7,82.3,75.4,81.8z"/>
					<polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="58,97.6 48,97.6 48,105.3 49.9,107.1 58,107.1 66.1,107.1 
			  68,105.3 68,97.6 		"/>
				</g>
				<g>
					<path fill="#FFFFFF" stroke="#010101" stroke-miterlimit="10" d="M92.7,55.4H116c0,0,3.4,0.6,4.8,5.9l0.2,13.2
			  c0,0-1.8,7.5-6.5,9.9h-21c0,0-4.3-2.9-6.1-9.8V61C87.3,61,89.1,56,92.7,55.4z"/>
					<path fill="none" stroke="#010101" stroke-miterlimit="10" d="M121.5,82.1l-0.5-0.8h-2.9l-2,1.9l-2.3,1.1l-9.7-0.1v0l-9.9,0.3
			  L92,83l-1.6-1.9l-3.3,0.2l-0.9,1.5l-0.5,3.8v11.7l0.4,3l1.2,2.1l1.5,1.3l2.4,1h3.3H96l0.1,0.8h8v0h8l0.1-0.8h1.4h3.3l2.4-1
			  l1.5-1.3l1.2-2.1l0.4-3V86.6l-0.4-3.1C121.9,83.1,121.8,82.6,121.5,82.1z"/>
					<polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="104.1,97.9 94.1,97.9 94.1,105.6 95.9,107.4 104.1,107.4 
			  112.2,107.4 114,105.6 114,97.9 		"/>
				</g>
			</g></>)
	},
	exterior: {
		width: 597.7,
		height: 918,
		svg: (<><g id="left">
			<g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M64.3,298l4.9,0.5c0,0,7.7,3.9,10.2,9.5c0,0,3.9,8,4.9,13.3l4.8,5.3
			  l35.2,36.4c0,0,7.7,8,8.2,21.8v27.9c0,0-0.2,3.6-4.3,3.6H28.3v-53.1l14.3-1c0,0,15.5-3.1,15-21.4c0,0,0.2-8.3-1.9-14.1
			  c0,0-2.3-12.4-28-11.9c-0.5,0-1.1-0.2-1.4-0.6c-0.6-0.6-1.3-1.9-1.4-4.4c0,0,1.8-7.9,2.8-10.6c0.1-0.4,0.3-0.8,0.3-1.2l0.5-3.5
			  c0.2-1.6,1.1-2.9,2.5-3.7l4.5-2.8l8.2-0.5C43.8,287.4,64.8,287.9,64.3,298z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M89.1,326.6l0.7,6.1l31.7,33.7c0,0,2,1.5,3.7-2.6" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M122.4,366.7c0,0,8.4,9.2,8.2,20.2v28.6" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.2,339.1l31.4,29.7c1.1,1,2,2.2,2.7,3.6c0.9,1.7,1.7,4.1,1.8,7.2
			  v17.9c0,0,0,0.1,0,0.2c-0.4,1.8-2.2,2.9-4,2.7c-4.1-0.6-17.4-2.6-21.8-4.2c0,0-13.2-4.9-14.4-20.8l-1-35.1c0,0,0.5-1.9,2.6-2.2
			  C84.4,338,85.4,338.4,86.2,339.1z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M80.5,345.3H59.9c0,0,0.6,20.1-27.6,20.6v26.7c0,0-0.4,2.4,5.6,4.3
			  l35.6,6.6h51.7"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M125.3,416.4l-0.2-32.2c0,0,0-9.1-6.9-14.7" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M66.5,298.2c0,0,0.9,9.4,5.6,20.6c0,0,1,3.1,4.8,6.1
			  c1.8,1.4,4.1,2.3,6.4,2.6l2.3,0.3c0.7,0.1,1.2-0.6,0.9-1.3l-2-5"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="55.7" y1="326.6" x2="72.1" y2="318.7" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.3" y1="298" x2="66.2" y2="321.8" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M37.8,287.9v11.7c0,1.7-1.4,3-3.1,3h0c-1.7,0-3.1-1.4-3.1-3v-9.2" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M50.9,288.4v6.2c0,1.1-0.8,2-1.7,2h-3.2c-0.9,0-1.7-0.9-1.7-2v-7" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="85.1" y1="338.3" x2="89.9" y2="332.8" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="70.4" y1="415.9" x2="70.4" y2="319.2" />
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M35.1,416.1v-36.8c0.1-0.9,0.7-1.7,1.9-2.1l7.5-2
			  c1.6-0.2,1.9,0.8,1.9,1.7v39.3"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="130.6" y1="412.6" x2="28.4" y2="412.6" />
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="130" y1="409.6" x2="28.6" y2="409.6" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M72.7,360v-11.4c0-0.7,0.6-1.3,1.3-1.3l0,0
			  c0.7,0,1.3,0.6,1.3,1.3V360c0,0.7-0.6,1.3-1.3,1.3l0,0C73.3,361.3,72.7,360.7,72.7,360z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M71.4,396.5L71.4,396.5c0-2.1,1.7-3.9,3.9-3.9
			  h4.3c2.1,0,3.9,1.7,3.9,3.9l0,0c0,2.1-1.7,3.9-3.9,3.9h-4.3C73.1,400.4,71.4,398.6,71.4,396.5z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M71.9,398.5c0-1.5,2.3-2.1,5.6-2.1
			  s5.5,0.6,5.5,2.1"/>
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="81.7" y1="368" x2="121.8" y2="377" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M83.4,365.7c0,0.4,0.3,0.8,0.7,0.9l29.2,6.3
			  c0,0,3.1,0.3,1.6-2.5l-21.7-20.8c-1.1-1-2.6-1.4-4-0.9c-2.6,0.9-5.3,3.9-5.9,9.3L83.4,365.7z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M55.3,325.4c3.3,5.8,4.2,9.3,4.6,15.9
			  c0.5,8.2-2.3,13.8-5.6,17"/>
				<g>
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="201.1,639.6 63.1,639.6 63.1,419.8 
				  138.8,419.8 			"/>
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="139,420.2 139,382.4 201.2,382.4 
				  201.2,640 			"/>
				</g>
				<g>
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="194.6,632.8 67.5,632.8 67.5,425.5 
				  153.6,425.5 			"/>
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="153.4,425.9 153.4,387.8 
				  194.8,387.8 194.8,633.1 			"/>
				</g>
				<polygon fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="59.8,637 59.8,420.7 63,419.9 63,637 
					  "/>
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="49.4" y1="416.3" x2="49.4" y2="543.4" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="49.4" y1="586.3" x2="49.4" y2="627.5" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="42.6" y1="588.7" x2="42.6" y2="627.2" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="39.4" y1="589.3" x2="39.4" y2="626.8" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="43.1" y1="416.9" x2="43.1" y2="540.2" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="37.5" y1="416.4" x2="37.5" y2="539.7" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="49.8" y1="627.2" x2="39" y2="627.2" />
				<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="49.7,419.6 56.9,419.6 59.8,420.7 		
			  "/>
				<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="59.7,632.1 57.7,632.1 49.4,622.4 		
			  "/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M35.7,416.2l0,6.2h1.8v34.1l-2,1.8v43.5h-2.8
			  V460h-3.2v-5.4l0.9-1.6v-31h2.1v-5.9C32.5,416.2,34.7,416.2,35.7,416.2z"/>
				<polygon fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="37.5,520.4 30.2,520.4 30.2,525.5 
			  37.5,530.4 		"/>
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="31.9" y1="526.8" x2="31.9" y2="545.1" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="31.9" y1="584.8" x2="31.9" y2="619.9" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M39.3,615.7l-9.1,4.8v1.6
			  c0,0.5,0.4,0.9,0.9,0.9h0.2c0.7,0,1.4-0.2,2.1-0.5l6-2.9C39.4,619.7,39.3,615.8,39.3,615.7z"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M39.4,621.8l-5.5,2.6v2c0,0,1.7,0.3,1.7,1.7
			  v3.7h11.8v-2.3l-1.8-2.2l-6.2,0L39.4,621.8z"/>
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="34.8" y1="542" x2="34.8" y2="546.1" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="34.8" y1="584.3" x2="34.8" y2="587.1" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="27.7" y1="314.4" x2="27.4" y2="319.9" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="25.2" y1="358.9" x2="28.3" y2="372.4" />
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M31.8,541.1c0,0,17.5,2.8,21.6,11.8v23
			  c0,0-2.6,9-21.2,11.4"/>
				<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M32.2,538.7c0,0,21.6,0.7,25.6,13.2v25.6
			  c0,0-6.1,13.1-25.5,12.8"/>
				<g>
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="29.3" cy="565.2" rx="19.6" ry="19.9" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="29.3" cy="565.4" rx="11.2" ry="11.4" />
				</g>
				<g>
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="29.8" cy="339.5" rx="19.6" ry="19.9" />
					<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="29.9" cy="339.7" rx="11.2" ry="11.4" />
				</g>
				<path fill="#F8F7F7" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M82.1,351.9v-2.2c0-1.7,1.4-3.2,3.2-3.2
			  h11.9c1.7,0,3.2,1.4,3.2,3.2v2.2c0,1.7-1.4,3.2-3.2,3.2H85.2C83.5,355.1,82.1,353.7,82.1,351.9z"/>
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="89.1" y1="341.9" x2="89.1" y2="349.6" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="84.5" y1="338.5" x2="84.5" y2="349.5" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="89.5" y1="349.6" x2="84.1" y2="349.6" />
			</g>
		</g>
			<g id="right">
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M534.7,297.7l-4.9,0.5c0,0-7.7,3.9-10.2,9.5c0,0-3.9,8-4.9,13.3
			  l-4.8,5.3l-35.2,36.4c0,0-7.7,8-8.2,21.8v27.9c0,0,0.2,3.6,4.3,3.6h99.9v-53.1l-14.3-1c0,0-15.5-3.1-15-21.4c0,0-0.2-8.3,1.9-14.1
			  c0,0,2.3-12.4,28-11.9c0.5,0,1.1-0.2,1.4-0.6c0.6-0.6,1.3-1.9,1.4-4.4c0,0-1.8-7.9-2.8-10.6c-0.1-0.4-0.3-0.8-0.3-1.2l-0.5-3.5
			  c-0.2-1.6-1.1-2.9-2.5-3.7l-4.5-2.8l-8.2-0.5C555.1,287.2,534.2,287.7,534.7,297.7z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M509.8,326.3l-0.7,6.1l-31.7,33.7c0,0-2,1.5-3.7-2.6" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M476.6,366.4c0,0-8.4,9.2-8.2,20.2v28.6" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M512.8,338.9l-31.4,29.7c-1.1,1-2,2.2-2.7,3.6
			  c-0.9,1.7-1.7,4.1-1.8,7.2v17.9c0,0,0,0.1,0,0.2c0.4,1.8,2.2,2.9,4,2.7c4.1-0.6,17.4-2.6,21.8-4.2c0,0,13.2-4.9,14.4-20.8l1-35.1
			  c0,0-0.5-1.9-2.6-2.2C514.5,337.8,513.5,338.2,512.8,338.9z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M518.4,345.1h20.6c0,0-0.6,20.1,27.6,20.6v26.7c0,0,0.4,2.4-5.6,4.3
			  l-35.6,6.6h-51.7"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M473.6,416.1l0.2-32.2c0,0,0-9.1,6.9-14.7" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M532.5,297.9c0,0-0.9,9.4-5.6,20.6c0,0-1,3.1-4.8,6.1
			  c-1.8,1.4-4.1,2.3-6.4,2.6l-2.3,0.3c-0.7,0.1-1.2-0.6-0.9-1.3l2-5"/>
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="543.2" y1="326.3" x2="526.9" y2="318.5" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="534.7" y1="297.7" x2="532.7" y2="321.6" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M561.2,287.6v11.7c0,1.7,1.4,3,3.1,3l0,0c1.7,0,3.1-1.4,3.1-3v-9.2"
					/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M548,288.1v6.2c0,1.1,0.8,2,1.7,2h3.2c0.9,0,1.7-0.9,1.7-2v-7" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="513.9" y1="338.1" x2="509" y2="332.6" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="528.5" y1="415.6" x2="528.5" y2="319" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M563.9,415.8V379c-0.1-0.9-0.7-1.7-1.9-2.1l-7.5-2
			  c-1.6-0.2-1.9,0.8-1.9,1.7v39.3"/>
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="468.3" y1="412.3" x2="570.5" y2="412.3" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="468.9" y1="409.4" x2="570.4" y2="409.4" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M526.3,359.7v-11.4c0-0.7-0.6-1.3-1.3-1.3l0,0
			  c-0.7,0-1.3,0.6-1.3,1.3v11.4c0,0.7,0.6,1.3,1.3,1.3l0,0C525.7,361,526.3,360.5,526.3,359.7z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M527.5,396.2L527.5,396.2
			  c0-2.1-1.7-3.9-3.9-3.9h-4.3c-2.1,0-3.9,1.7-3.9,3.9l0,0c0,2.1,1.7,3.9,3.9,3.9h4.3C525.8,400.1,527.5,398.4,527.5,396.2z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M527.1,398.3c0-1.5-2.3-2.1-5.6-2.1
			  s-5.5,0.6-5.5,2.1"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="517.3" y1="367.7" x2="477.2" y2="376.8" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M515.5,365.5c0,0.4-0.3,0.8-0.7,0.9l-29.2,6.3
			  c0,0-3.1,0.3-1.6-2.5l21.7-20.8c1.1-1,2.6-1.4,4-0.9c2.6,0.9,5.3,3.9,5.9,9.3L515.5,365.5z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M543.6,325.2c-3.3,5.8-4.2,9.3-4.6,15.9
			  c-0.5,8.2,2.3,13.8,5.6,17"/>
					<g>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="397.8,639.3 535.9,639.3 
				  535.9,419.6 460.1,419.6 			"/>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="460,420 460,382.2 397.7,382.2 
				  397.7,639.7 			"/>
					</g>
					<g>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="404.3,632.5 531.5,632.5 
				  531.5,425.3 445.3,425.3 			"/>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="445.5,425.6 445.5,387.6 
				  404.2,387.6 404.2,632.9 			"/>
					</g>
					<polygon fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="539.1,636.7 539.1,420.4 535.9,419.7 
			  535.9,636.7 		"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="549.5" y1="416" x2="549.5" y2="543.2" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="549.5" y1="586.1" x2="549.5" y2="627.3" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="556.3" y1="588.5" x2="556.3" y2="626.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="559.5" y1="589" x2="559.5" y2="626.6" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="555.8" y1="416.7" x2="555.8" y2="539.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="561.4" y1="416.2" x2="561.4" y2="539.4" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="549.1" y1="626.9" x2="559.9" y2="626.9" />
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="549.2,419.3 542.1,419.3 539.1,420.4 
					  "/>
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="539.3,631.9 541.2,631.9 549.5,622.2 
					  "/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M563.3,416l0,6.2h-1.8v34.1l2,1.8v43.5h2.8
			  v-41.9h3.2v-5.4l-0.9-1.6v-31h-2.1v-5.9C566.4,415.9,564.3,416,563.3,416z"/>
					<polygon fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="561.4,520.2 568.8,520.2 568.8,525.3 
			  561.5,530.2 		"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="567.1" y1="526.5" x2="567.1" y2="544.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="567.1" y1="584.5" x2="567.1" y2="619.6" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M559.6,615.4l9.1,4.8v1.6
			  c0,0.5-0.4,0.9-0.9,0.9h-0.2c-0.7,0-1.4-0.2-2.1-0.5l-6-2.9C559.6,619.4,559.6,615.5,559.6,615.4z"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M559.6,621.6l5.5,2.6v2c0,0-1.7,0.3-1.7,1.7
			  v3.7h-11.8v-2.3l1.8-2.2l6.2,0L559.6,621.6z"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="564.1" y1="541.7" x2="564.1" y2="545.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="564.1" y1="584.1" x2="564.1" y2="586.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="571.2" y1="314.2" x2="571.6" y2="319.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="573.8" y1="358.7" x2="570.7" y2="372.1" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M567.1,540.9c0,0-17.5,2.8-21.6,11.8v23
			  c0,0,2.6,9,21.2,11.4"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M566.8,538.4c0,0-21.6,0.7-25.6,13.2v25.6
			  c0,0,6.1,13.1,25.5,12.8"/>
					<g>
						<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="569.6" cy="565" rx="19.6" ry="19.9" />
						<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="569.6" cy="565.2" rx="11.2" ry="11.4" />
					</g>
					<g>
						<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="569.1" cy="339.3" rx="19.6" ry="19.9" />
						<ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="569.1" cy="339.5" rx="11.2" ry="11.4" />
					</g>
					<path fill="#F8F7F7" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M516.9,351.7v-2.2c0-1.7-1.4-3.2-3.2-3.2
			  h-11.9c-1.7,0-3.2,1.4-3.2,3.2v2.2c0,1.7,1.4,3.2,3.2,3.2h11.9C515.5,354.8,516.9,353.4,516.9,351.7z"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="509.8" y1="341.7" x2="509.8" y2="349.3" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="514.5" y1="338.2" x2="514.5" y2="349.2" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="509.4" y1="349.3" x2="514.8" y2="349.3" />
				</g>
			</g>
			<g id="rear">
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M245,886.5V911c0,0.8-0.8,1.5-1.7,1.5h-13.9
			  c-1,0-1.7-0.7-1.7-1.5v-24.5"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M378,886.2v24.5c0,0.8-0.8,1.5-1.7,1.5h-13.9
			  c-1,0-1.7-0.7-1.7-1.5v-24.5"/>
					<g>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="244.9,895.1 248.8,890.3 
				  305.8,890.3 			"/>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="360.7,895.1 356.8,890.3 
				  299.9,890.3 			"/>
					</g>

					<rect x="226.9" y="848.1" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="151.2" height="21.6" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="226.4" y1="852.6" x2="378.5" y2="852.6" />
					<rect x="256.9" y="852.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="8.5" height="14.2" />
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="256.8,857.7 244.4,857.7 230.1,852.5 
					  "/>

					<rect x="339.1" y="852.6" transform="matrix(-1 -4.006496e-11 4.006496e-11 -1 686.8248 1719.3301)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="8.5" height="14.2" />
					<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="347.7,857.7 360.2,857.7 374.5,852.5 
					  "/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="265.5" y1="857" x2="339.1" y2="857" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="265.1" y1="863.3" x2="338.6" y2="863.3" />
					<g>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="226.9,869.7 226.9,886.1 
				  251.9,886.1 251.9,883.7 260.7,883.7 260.7,886 304.1,886 			"/>
						<polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="378.1,869.7 378.1,886.1 
				  352.9,886.1 352.9,883.7 344.1,883.7 344.1,886 300.4,886 			"/>
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M254.5,880.4h-21.2c-0.6,0-1-0.5-1-1v-5.6
				  c0-0.6,0.5-1,1-1h21.2c0.6,0,1,0.5,1,1v5.6C255.6,880,255.1,880.4,254.5,880.4z"/>
						<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="239.2" y1="872.8" x2="239.2" y2="880.4" />
						<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="248.6" y1="872.8" x2="248.6" y2="880.4" />
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M372.6,880.4h-21.2c-0.6,0-1-0.5-1-1v-5.6
				  c0-0.6,0.5-1,1-1h21.2c0.6,0,1,0.5,1,1v5.6C373.6,880,373.1,880.4,372.6,880.4z"/>
						<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="357.2" y1="872.8" x2="357.2" y2="880.4" />
						<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="366.6" y1="872.8" x2="366.6" y2="880.4" />
					</g>
					<g>
						<rect x="280.8" y="872.7" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="43.4" height="7.7" />
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M280.5,880.4h-4.3c-1.9,0-3.4-1.4-3.4-3.2
				  v-1.4c0-1.8,1.6-3.2,3.4-3.2h4.3"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M324.6,880.4h4.3c1.9,0,3.4-1.4,3.4-3.2v-1.4
				  c0-1.8-1.6-3.2-3.4-3.2h-4.3"/>
					</g>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="347.6" y1="858" x2="339.5" y2="866.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257" y1="858" x2="265.1" y2="866.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="256.4" y1="858.1" x2="248.3" y2="866.8" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="248.3" y1="857.9" x2="248.3" y2="869.6" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="347.8" y1="858.1" x2="356" y2="866.8" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="356" y1="857.9" x2="356" y2="869.6" />

					<rect x="222.8" y="680.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="158.4" height="167.5" />
					<rect x="229.7" y="688.7" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="143.7" height="152" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="692.6" x2="373.7" y2="692.6" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="696.3" x2="373.7" y2="696.3" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="699.9" x2="373.7" y2="699.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="703.5" x2="373.7" y2="703.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="707.1" x2="373.7" y2="707.1" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="710.8" x2="373.7" y2="710.8" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="714.4" x2="373.7" y2="714.4" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="718" x2="373.7" y2="718" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="721.6" x2="373.7" y2="721.6" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="725.3" x2="373.7" y2="725.3" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="728.9" x2="373.7" y2="728.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="732.5" x2="373.7" y2="732.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="736.2" x2="373.7" y2="736.2" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="739.8" x2="373.7" y2="739.8" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="743.4" x2="373.7" y2="743.4" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="747" x2="373.7" y2="747" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="750.7" x2="373.7" y2="750.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="754.3" x2="373.7" y2="754.3" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="757.9" x2="373.7" y2="757.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="761.5" x2="373.7" y2="761.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="765.2" x2="373.7" y2="765.2" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="768.8" x2="373.7" y2="768.8" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="772.4" x2="373.7" y2="772.4" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="776.1" x2="373.7" y2="776.1" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="779.7" x2="373.7" y2="779.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="783.3" x2="373.7" y2="783.3" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="786.9" x2="373.7" y2="786.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="790.6" x2="373.7" y2="790.6" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="794.2" x2="373.7" y2="794.2" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="797.8" x2="373.7" y2="797.8" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="801.5" x2="373.7" y2="801.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="805.1" x2="373.7" y2="805.1" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="808.7" x2="373.7" y2="808.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="812.3" x2="373.7" y2="812.3" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="816" x2="373.7" y2="816" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="819.6" x2="373.7" y2="819.6" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="823.2" x2="373.7" y2="823.2" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="826.8" x2="373.7" y2="826.8" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="830.5" x2="373.7" y2="830.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="834.1" x2="373.7" y2="834.1" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="229.8" y1="837.7" x2="373.7" y2="837.7" />
				</g>
			</g>
			<g id="front">
				<g>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M248.8,250.6v19.2c0,1-0.8,1.8-1.8,1.8h-13.3
			  c-1,0-1.8-0.8-1.8-1.8v-29.3"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M361.5,250.6v19.2c0,1,0.8,1.8,1.8,1.8h13.3
			  c1,0,1.8-0.8,1.8-1.8v-30.4"/>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M228.6,197.5c0,0-0.1,27,3.1,41.6
				  c0.6,2.8,1.8,5.6,3.5,7.9c1.3,1.7,3.1,3.3,5.1,3.6h67.1"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M381.5,197.5c0,0,0.1,27-3.1,41.6
				  c-0.6,2.8-1.8,5.6-3.5,7.9c-1.3,1.7-3.1,3.3-5.1,3.6h-67.1"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M351.6,196.6l-5.3,20.1c-0.1,0.5-0.3,1-0.5,1.5
			  c-1.7,4-3.2,6.9-7.2,6.9l-34.9,0.2h3.3l-34.9-0.2c-3.9,0-5.5-2.9-7.2-6.9c-0.2-0.5-0.4-1-0.5-1.5l-5.3-20.1"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M305.1,221.3h29.8c2.2,0,2.8-1.7,3.9-3.8
			  l5.4-12.5c0.9-2-0.2-4.4-2.3-5c-0.3-0.1-0.7-0.1-1-0.1h-35.2h0h-35.2c-0.4,0-0.7,0-1,0.1c-2.2,0.6-3.2,3-2.3,5l5.4,12.5
			  c1.2,2.1,1.8,3.8,3.9,3.8H305.1"/>
					<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="245.9" cy="240.1" r="4.5" />
					<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="362.8" cy="241" r="4.5" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="250.4" y1="238.7" x2="358.9" y2="238.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="272.6" y1="250.4" x2="263.4" y2="212.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="338" y1="250.4" x2="347.2" y2="212.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="261" y1="204.3" x2="228.5" y2="202.4" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="349.6" y1="204.3" x2="382" y2="202.4" />
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M259.5,226h-22.1c-0.9,0-1.7-0.8-1.7-1.7v-5.6
				  c0-0.9,0.8-1.7,1.7-1.7h22.1c0.9,0,1.7,0.8,1.7,1.7v5.6C261.2,225.2,260.5,226,259.5,226z"/>
						<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="245.6" y1="217.1" x2="247.7" y2="226.1" />
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M350,226h22.1c0.9,0,1.7-0.8,1.7-1.7v-5.6
				  c0-0.9-0.8-1.7-1.7-1.7H350c-0.9,0-1.7,0.8-1.7,1.7v5.6C348.3,225.2,349.1,226,350,226z"/>
						<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="364" y1="217.1" x2="361.9" y2="226.1" />
					</g>
					<rect x="285.2" y="231.3" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="39.4" height="7.4" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="267" y1="228.7" x2="343.4" y2="228.7" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M333,238.5c0,0-5.4,6-8.8,7.9
			  c-0.5,0.3-1.1,0.4-1.6,0.4H305h0.7h-17.6c-0.6,0-1.1-0.1-1.6-0.4c-3.3-1.8-8.8-7.9-8.8-7.9"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M287.8,246.7H255c-0.3,0-0.6,0-1-0.1
			  c-1.1-0.2-3.5-0.8-5.8-2.4"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M320.5,246.7h32.8c0.3,0,0.6,0,1-0.1
			  c1.1-0.2,3.5-0.8,5.8-2.4"/>
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M336.9,178.1h-61.7c-1,0-1.8-0.8-1.8-1.8v-3.6
			  c0-1,0.8-1.8,1.8-1.8h61.7c1,0,1.8,0.8,1.8,1.8v3.6C338.7,177.2,337.9,178.1,336.9,178.1z"/>
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="275.2" y1="171.1" x2="275.2" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="276.5" y1="171.1" x2="276.5" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="277.7" y1="171.1" x2="277.7" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="279" y1="171.1" x2="279" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="280.3" y1="171.1" x2="280.3" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="281.6" y1="171.1" x2="281.6" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="282.9" y1="171.1" x2="282.9" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="284.2" y1="171.1" x2="284.2" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="285.5" y1="171.1" x2="285.5" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="286.8" y1="171.1" x2="286.8" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="288.1" y1="171.1" x2="288.1" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="289.4" y1="171.1" x2="289.4" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="290.7" y1="171.1" x2="290.7" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="292" y1="171.1" x2="292" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="293.3" y1="171.1" x2="293.3" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="294.6" y1="171.1" x2="294.6" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="295.9" y1="171.1" x2="295.9" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="297.2" y1="171.1" x2="297.2" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="298.4" y1="171.1" x2="298.4" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="299.7" y1="171.1" x2="299.7" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="301" y1="171.1" x2="301" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="302.3" y1="171.1" x2="302.3" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="303.6" y1="171.1" x2="303.6" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="304.9" y1="171.1" x2="304.9" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="306.2" y1="171.1" x2="306.2" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="307.5" y1="171.1" x2="307.5" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="308.8" y1="171.1" x2="308.8" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="310.1" y1="171.1" x2="310.1" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="311.4" y1="171.1" x2="311.4" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="312.7" y1="171.1" x2="312.7" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="314" y1="171.1" x2="314" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="315.3" y1="171.1" x2="315.3" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="316.6" y1="171.1" x2="316.6" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="317.9" y1="171.1" x2="317.9" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="319.2" y1="171.1" x2="319.2" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="320.4" y1="171.1" x2="320.4" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="321.7" y1="171.1" x2="321.7" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="323" y1="171.1" x2="323" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="324.3" y1="171.1" x2="324.3" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="325.6" y1="171.1" x2="325.6" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="326.9" y1="171.1" x2="326.9" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="328.2" y1="171.1" x2="328.2" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="329.5" y1="171.1" x2="329.5" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="330.8" y1="171.1" x2="330.8" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="332.1" y1="171.1" x2="332.1" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="333.4" y1="171.1" x2="333.4" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="334.7" y1="171.1" x2="334.7" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="336" y1="171.1" x2="336" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="337.3" y1="171.1" x2="337.3" y2="177.9" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M381.1,198.1c0.3,0.2,0.6,0,0.6-0.3l-2.1-25.3
			  l-4.1-52l0-3.4c0-2.3-0.5-4.6-1.7-6.6c-2.5-4.1-5.8-4.3-5.8-4.3h-9.8h0.4c0,0-48.5-6-107.3,0l-0.1,0h-9.8c0,0-4.5,0.3-6.9,6.8
			  c-0.4,1-0.6,2.1-0.6,3.1v4.4l-4.1,52l-1.2,25.1c0,0,0,0.1,0.1,0c0.5-0.3,2.7-1.2,6.2-1.6c0,0,2.9-0.5,7.7,1h63.1h56.7l4,0
			  c0,0,4-1.3,6.4-1.3C372.8,195.6,377.2,195.4,381.1,198.1z"/>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M304.4,115.6l53.4,0.3c0,0,3.6-0.2,7,1.3
				  c2.8,1.2,4.6,4.2,4.6,7.3l0,32.9c0,1.3-0.2,2.7-0.7,4c-1,2.9-3.6,7.5-9.5,9.3c-0.7,0.2-1.4,0.3-2.1,0.3h-52.9"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M305.5,115.6l-53.4,0.3c0,0-3.6-0.2-7,1.3
				  c-2.8,1.2-4.6,4.2-4.6,7.3l0,32.9c0,1.3,0.2,2.7,0.7,4c1,2.9,3.6,7.5,9.5,9.3c0.7,0.2,1.4,0.3,2.1,0.3h52.9"/>
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M305.4,117.9c0,0,40.8-0.1,55.6,0.7
				  c0,0,1.4,0,2.8,0.6c1.7,0.7,2.8,2.5,2.8,4.4l0,31.2c0,0.3,0,0.6-0.1,0.9c-0.3,1-1.2,3.3-3.6,5.2c-2.2,1.8-5.1,2.7-8,2.7h-49.6"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M305.5,117.9c0,0-41.6-0.1-56.7,0.7
				  c0,0-1.4,0-2.9,0.6c-1.8,0.7-2.9,2.5-2.9,4.4l0,31.2c0,0.3,0,0.6,0.1,0.9c0.3,1,1.2,3.3,3.6,5.2c2.3,1.8,5.2,2.7,8.2,2.7h50.6"/>
					</g>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M251.3,106.5v5.6c0,1-0.9,1.9-1.9,1.9h-0.7
			  c-1,0-1.9-0.9-1.9-1.9v-5.6"/>
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M363,106.4v5.6c0,1-0.9,1.9-1.9,1.9h-0.7
			  c-1,0-1.9-0.9-1.9-1.9v-5.6"/>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.7" y1="177.9" x2="352.1" y2="177.9" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="368.2" y1="190.7" x2="380.6" y2="190.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="228.8" y1="190.7" x2="241.2" y2="190.7" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="257.1" y1="176.2" x2="257.1" y2="171" />
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="352.6" y1="176.3" x2="352.6" y2="171.2" />
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M257,176.3c-2.3-0.4-11-2.5-15.3-8.5
				  c-0.1-0.1-0.3-0.2-0.4,0c-1.5,1.8-10.5,13.5-0.3,22.6c0.6,0.6,1.4,0.9,2.2,0.9l13.5,0c0.4,0,0.7-0.3,0.7-0.7
				  c0-2.5,0.1-11.2,0.1-13.5C257.6,176.7,257.3,176.4,257,176.3z"/>
						<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="245.2" cy="182.4" r="6.1" />
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M257.7,189.8" />
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M257.6,185.5c-0.7-0.9-1.7-1.5-2.9-1.5
				  c-2,0-3.6,1.6-3.6,3.5c0,2,1.6,3.5,3.6,3.5c1.1,0,2.1-0.5,2.7-1.2"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M257.6,185.2" />
					</g>
					<g>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M352.8,176.3c2.3-0.4,11-2.5,15.3-8.5
				  c0.1-0.1,0.3-0.2,0.4,0c1.5,1.8,10.5,13.5,0.3,22.6c-0.6,0.6-1.4,0.9-2.2,0.9l-13.5,0c-0.4,0-0.7-0.3-0.7-0.7
				  c0-2.5-0.1-11.2-0.1-13.5C352.2,176.7,352.4,176.4,352.8,176.3z"/>
						<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="364.5" cy="182.4" r="6.1" />
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M352,189.8" />
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M352.2,185.5c0.7-0.9,1.7-1.5,2.9-1.5
				  c2,0,3.6,1.6,3.6,3.5c0,2-1.6,3.5-3.6,3.5c-1.1,0-2.1-0.5-2.7-1.2"/>
						<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M352.2,185.2" />
					</g>
					<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="251.2" y1="110.2" x2="358.6" y2="110.2" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M241.5,162.2c0,0-6.6,2.7-11.9,13.1" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M368.7,162.2c0,0,6.2,2.7,11.2,13.1" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M234,118.1c0,0,3.1-1.7,4.3,1.1l-2.4,47.2" />
					<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M375.6,118.1c0,0-3.1-1.7-4.3,1.1l2.4,47.2" />
					<path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M228.8,199.3" />
					<polyline fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="228.8,199.3 225.9,199.3 225.9,95.8 
			  383.6,95.8 383.6,199.3 381.7,199.3 		"/>
					<rect x="225.8" y="11" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="157.8" height="84.8" />
					<g>
						<path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M218.5,177.2h-5.3c-3.4,0-6.1-2.8-6.1-6.1
				  v-17.9c0-3.4,2.8-6.1,6.1-6.1h5.3c3.4,0,6.1,2.8,6.1,6.1v17.9C224.6,174.4,221.9,177.2,218.5,177.2z"/>
						<path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M229.8,157.6h-16.4c-0.8,0-1.4-0.6-1.4-1.4
				  v-1c0-0.8,0.6-1.4,1.4-1.4h16.4c0.8,0,1.4,0.6,1.4,1.4v1C231.2,157,230.5,157.6,229.8,157.6z"/>
						<path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M228.8,169.3h-15.6c-0.8,0-1.4-0.6-1.4-1.4
				  v-1c0-0.8,0.6-1.4,1.4-1.4h15.6c0.8,0,1.4,0.6,1.4,1.4v1C230.2,168.7,229.6,169.3,228.8,169.3z"/>
					</g>
					<g>
						<path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M391.1,177.2h5.3c3.4,0,6.1-2.8,6.1-6.1
				  v-17.9c0-3.4-2.8-6.1-6.1-6.1h-5.3c-3.4,0-6.1,2.8-6.1,6.1v17.9C385,174.4,387.8,177.2,391.1,177.2z"/>
						<path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M379.9,157.6h16.4c0.8,0,1.4-0.6,1.4-1.4v-1
				  c0-0.8-0.6-1.4-1.4-1.4h-16.4c-0.8,0-1.4,0.6-1.4,1.4v1C378.5,157,379.1,157.6,379.9,157.6z"/>
						<path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M380.8,169.3h15.6c0.8,0,1.4-0.6,1.4-1.4v-1
				  c0-0.8-0.6-1.4-1.4-1.4h-15.6c-0.8,0-1.4,0.6-1.4,1.4v1C379.4,168.7,380.1,169.3,380.8,169.3z"/>
					</g>
				</g>
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="260.5" y1="163.7" x2="330.1" y2="151.3" />
				<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="301.5" y1="163.7" x2="362.4" y2="154.2" />
			</g>
			<g id="top">
				<g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M238.3,389.4" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M238,389v-61c0,0,0.4-8.7,4.1-15.4c0,0,8.6-24.2,25.4-24.9h32.7" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M362.3,389v-61c0,0-0.4-8.7-4.1-15.4c0,0-8.6-24.2-25.4-24.9h-32.7"
					/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M237.9,328.3c0,0,1.1-0.2,2.6-3.7c0,0,7.8-27,23.2-29
			  c0,0,0-5.1,4.9-8"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M362.5,328.3c0,0-1.1-0.2-2.6-3.7c0,0-7.8-27-23.2-29
			  c0,0,0-5.1-4.9-8"/>
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="263.5" y1="295.4" x2="336.5" y2="295.4" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M262,303.6V316c0,0-8.3,3.1-13,14.4c-0.1,0.3-0.3,0.5-0.6,0.6
			  c-0.7,0.2-2,0.5-3.5,0.1c-0.6-0.2-1-0.8-1-1.4v-6.4c0-0.7,0.1-1.4,0.4-2C245.7,317.6,251.2,305.5,262,303.6z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M338.2,303.6V316c0,0,8.3,3.1,13,14.4c0.1,0.3,0.3,0.5,0.6,0.6
			  c0.7,0.2,2,0.5,3.5,0.1c0.6-0.2,1-0.8,1-1.4v-6.4c0-0.7-0.1-1.4-0.4-2C354.4,317.6,349,305.5,338.2,303.6z"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M262.3,303.5c0,0,38.8-4.4,75.7,0" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M262.2,314.2c0,0,36.3-7.2,75.9,0.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="263.9" y1="303.3" x2="263.9" y2="295.3" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="336.5" y1="303.2" x2="336.5" y2="295.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="261.4" y1="316.1" x2="261.4" y2="325.7" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="338.8" y1="316.1" x2="338.8" y2="325.7" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M300.7,376.7h-48.9c-5.2-1-5.2-5.2-5.2-5.2l0.4-34.8
			  c3.2-6.9,14.6-10.8,14.6-10.8c15.7-4.3,39-3.2,39-3.2h-0.2c0,0,23-1.1,38.5,3.2c0,0,11.3,3.9,14.4,10.8l0.4,34.8
			  c0,0,0,4.2-5.2,5.2h-48.2"/>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M300.5,374.2h-45.9c-1.1,0-2.1-0.2-3.1-0.6
			  c-3.1-1.4-3.1-4.1-3.1-4.1l0.4-27.4c0-3.4,1.6-6.6,4.3-8.6c3.6-2.7,8.1-4.4,9.4-4.8c0.2-0.1,0.5-0.2,0.7-0.2
			  c15.1-3.8,37.2-2.8,37.2-2.8h-0.2c0,0,21.8-1,36.7,2.8c0.3,0.1,0.5,0.1,0.8,0.2c1.5,0.5,6.4,2.4,10,5.4c2.2,1.8,3.5,4.6,3.5,7.5
			  l0.4,27.9c0,0,0,2.7-3.1,4.1c-0.9,0.4-2,0.6-3,0.6H300"/>
					<g>
						<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M276,287.6l-5.4,3.7c0,0-1.4,1.1,0.7,1.3h29" />
						<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.3,287.6l5.4,3.7c0,0,1.4,1.1-0.7,1.3h-29" />
					</g>
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M247,336.7c0,0-4.9,3.2-8.9,8" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M353.4,336.7c0,0,4.9,3.2,8.9,8" />
					<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M241.6,357.2c0,0,0.1-2.5-4-2.8c-0.2,0-0.5-0.1-0.7-0.2
			  c-0.7-0.4-2.5-1.5-3.9-2.3c-0.3-0.2-0.7-0.3-1.1-0.4c-1.6-0.2-6.1-0.6-8.9,2.4c-0.4,0.4-0.8,1.1-0.9,1.6c-0.2,0.7-0.4,1.6-0.3,2.5
			  c0.1,1.2,1.3,2.2,2.5,1.9c0.9-0.2,2.1-0.7,3.9-2.1c0,0,2.8-2.1,6.6-0.7C234.8,357.3,241.2,362.4,241.6,357.2z"/>
					<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M360.2,357.2c0,0-0.1-2.5,4-2.8c0.2,0,0.5-0.1,0.7-0.2
			  c0.7-0.4,2.5-1.5,3.9-2.3c0.3-0.2,0.7-0.3,1.1-0.4c1.6-0.2,6.1-0.6,8.9,2.4c0.4,0.4,0.8,1.1,0.9,1.6c0.2,0.7,0.4,1.6,0.3,2.5
			  c-0.1,1.2-1.3,2.2-2.5,1.9c-0.9-0.2-2.1-0.7-3.9-2.1c0,0-2.8-2.1-6.6-0.7C367,357.3,360.6,362.4,360.2,357.2z"/>
					<rect x="236.4" y="390.4" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="127.5" height="250.2" />
					<rect x="236.4" y="359.6" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="127.5" height="30.8" />
					<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M326.7,321.4c-4-0.6-27.5-3.6-52.4-0.6c-0.4,0.1-0.8-0.1-1.1-0.5
			  c-0.5-0.8-1.2-2.4,0-5c0.2-0.5,0.8-0.9,1.3-1c4.8-0.7,28.4-3.6,50.9,0.1c0.5,0.1,1,0.4,1.2,0.9c0.7,1.3,1.8,3.9,1,5.7
			  C327.5,321.3,327.1,321.5,326.7,321.4z"/>
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="274.7" y1="314.1" x2="274.7" y2="320.7" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="276.9" y1="314.2" x2="276.9" y2="320.7" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="279" y1="314" x2="279" y2="320.5" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="281.1" y1="313.5" x2="281.1" y2="320" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="283.2" y1="313.5" x2="283.2" y2="320" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="285.3" y1="313.4" x2="285.3" y2="319.9" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="287.4" y1="313.3" x2="287.4" y2="319.8" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="289.6" y1="313" x2="289.6" y2="319.6" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="291.7" y1="312.8" x2="291.7" y2="319.4" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="293.8" y1="312.7" x2="293.8" y2="319.3" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="295.9" y1="312.7" x2="295.9" y2="319.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="298" y1="312.7" x2="298" y2="319.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="300.1" y1="312.7" x2="300.1" y2="319.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="302.3" y1="312.7" x2="302.3" y2="319.3" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="304.4" y1="312.8" x2="304.4" y2="319.4" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="306.5" y1="312.9" x2="306.5" y2="319.4" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="308.6" y1="313" x2="308.6" y2="319.6" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="310.7" y1="313.1" x2="310.7" y2="319.7" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="312.8" y1="313.1" x2="312.8" y2="320" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="315" y1="313.1" x2="315" y2="320.2" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="317.1" y1="313.4" x2="317.1" y2="320.3" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="319.2" y1="313.6" x2="319.2" y2="320.5" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="321.3" y1="313.8" x2="321.3" y2="320.7" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="323.4" y1="314" x2="323.4" y2="320.9" />
					<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="325.5" y1="314.4" x2="325.5" y2="321.3" />
				</g>
			</g></>)
	}
}