import React from "react";

export const SafariSVG = {
  interior: {
    width: 212,
    height: 496,
    svg: (
      <>
        <g>
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M36.6,126.2c0,0-1.3-69.8,24.1-100.8c0,0,46.8-28.1,91,0
		c0,0,25.4,27.3,23.1,100.8C174.8,126.2,117.6,110.8,36.6,126.2z"
          />
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M47.3,124.1c0,0-1.1-66.1,20.5-96.4c0,0,39.7-27.4,77.1,0
		c0,0,21.7,24.5,19.7,96.2C164.5,124,115.8,114.2,47.3,124.1z"
          />
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M187.3,147.9c0.2-4.1,2.5-13,2.5-13V63.6c-3.4-19.4-18-30.8-18-30.8
		C142.2,9.4,106,10.7,106,10.7S69.7,9.4,40.1,32.8c0,0-14.6,11.4-18,30.8v71.3c0,0,2.3,8.9,2.5,13l0.9,147.9v39.4l-2.5,7.3v71.1
		l5.2,14.8l1.7,35.4c0,1.5,0.3,2.9,0.9,4.3c1.4,3.2,4.1,7.6,11.6,8.3H106h62.5c0.7,0,1.5-0.1,2.1-0.4c8.1-3.8,8.8-11.4,8.8-11.4
		l3-34.6l6.6-16.4v-71.1l-2.5-7.3v-39.4L187.3,147.9z"
          />
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M61.1,25.1c0,0-20.4,3.3-26.3,20.9c0,0-3.8,10.1,0,13.8
		c0,0,8.7-5.4,13.4-11.5C48.2,48.4,56.9,27.3,61.1,25.1z"
          />
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M151.8,25.1c0,0,20.4,3.3,26.3,20.9c0,0,3.8,10.1,0,13.8
		c0,0-8.7-5.4-13.4-11.5C164.7,48.4,156,27.3,151.8,25.1z"
          />
          <polyline
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            points="34.8,60 33.1,147.9 24.6,147.9 	"
          />
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M24.4,146c0,0,2.2-5.3,3.2-10.3V69.5c0,0-1.8-7-3.8-10.6"
          />
          <polyline
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            points="178,60 178.5,147.9 187,147.9 	"
          />
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M187.2,146c0,0-2.2-5.3-3.2-10.3V69.5c0,0,1.3-6,4.6-11.2"
          />
          <path
            fill="none"
            stroke="#231F20"
            stroke-width="0.75"
            stroke-miterlimit="10"
            d="M185.8,335.8c0,0-4.9,6.9-5,10l-1.3,48.7v22.8
		c0,0,1.6,10.7,2.8,12.6"
          />
          <path
            fill="none"
            stroke="#231F20"
            stroke-width="0.75"
            stroke-miterlimit="10"
            d="M24.8,334.3c0,0,4.9,6.9,5,10l1.3,48.7v22.8
		c0,0-1.6,10.7-2.8,12.6"
          />
          <path
            fill="none"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M30.2,466c0,0-5.5,13.4,7.2,16.8c0.5,0.1,1,0.2,1.5,0.2h132.4
		c0.8,0,1.6-0.2,2.4-0.5c3-1.1,9.1-6.4,5.3-16"
          />
          <path
            fill="#F9F9F9"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M203.1,178.5c-0.6,3.9-15.1-1.7-22.5-5.8c-4-2.2-5.5-8.8-3.5-12.7
		c2-3.9,7.7-3.6,11.8-1.5C193,160.6,204.2,170.5,203.1,178.5z"
          />
          <path
            fill="#F9F9F9"
            stroke="#020202"
            stroke-miterlimit="10"
            d="M6.8,178.5c0.6,3.9,15.1-1.7,22.5-5.8c4-2.2,5.5-8.8,3.5-12.7
		s-7.7-3.6-11.8-1.5C16.9,160.6,5.6,170.5,6.8,178.5z"
          />
          <g>
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M38.5,126.5c0,0,70-15,135,0c0,0-7,6-9,8c0,0-47-6-120-1
			C44.5,133.5,42.5,130.5,38.5,126.5z"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M166.5,136.5c0,0-49-6-122-1"
            />
            <line
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              x1="44.5"
              y1="135.5"
              x2="36.5"
              y2="126.5"
            />
            <line
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              x1="165.5"
              y1="136.5"
              x2="175.5"
              y2="126.5"
            />
          </g>
          <line
            fill="none"
            stroke="#231F20"
            stroke-miterlimit="10"
            x1="186.5"
            y1="222.5"
            x2="24.5"
            y2="222.5"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M50.2,144.8H89c0,0,5.7,0.8,7.9,8.3l0.4,18.5c0,0-2.9,10.6-10.9,14
		H51.6c0,0-7.1-4.1-10.2-13.8v-19C41.4,152.7,44.2,145.7,50.2,144.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M98.1,182.4l-0.8-1.1h-4.9l-3.2,2.7l-3.9,1.5l-16.2-0.1l0,0
		l-16.4,0.4l-3.4-2.1l-2.6-2.6l-5.5,0.2l-1.5,2.1l-0.8,5.4v16.4l0.7,4.2l2,2.9l2.6,1.9l3.9,1.5h5.5h2.4l0.2,1.2h13.3l0,0h13.3
		l0.2-1.2h2.4h5.5l3.9-1.5l2.6-1.9l2-2.9l0.7-4.2v-16.5l-0.7-4.3C98.9,183.7,98.5,183,98.1,182.4z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="69.1,204.5 52.4,204.5 52.4,215.5 55.5,218 69.1,218 
		82.6,218 85.7,215.5 85.7,204.5 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M37.8,235.8H68c0,0,4.4,0.8,6.1,8.1l0.3,18c0,0-2.3,10.3-8.5,13.6
		H38.8c0,0-5.5-4-7.9-13.4v-18.4C30.9,243.5,33.1,236.6,37.8,235.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M75.1,272.1l-0.6-0.8h-3.8l-2.5,2l-3,1.2l-12.6-0.1l0,0l-12.8,0.3
		l-2.7-1.6l-2-2l-4.3,0.2l-1.2,1.6L29,277v12.4l0.6,3.2l1.6,2.2l2,1.4l3,1.2h4.3h1.9l0.1,0.9h10.3l0,0h10.3l0.1-0.9h1.9h4.3l3-1.2
		l2-1.4l1.6-2.2l0.6-3.2v-12.4l-0.6-3.3C75.7,273.1,75.4,272.6,75.1,272.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="52.4,288.8 39.5,288.8 39.5,297.1 41.9,299 52.4,299 63,299 
		65.4,297.1 65.4,288.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M91,236.8h30.3c0,0,4.4,0.8,6.1,8.1l0.3,18c0,0-2.3,10.3-8.5,13.6H92
		c0,0-5.5-4-7.9-13.4v-18.4C84.1,244.5,86.3,237.6,91,236.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M128.4,273.1l-0.6-0.8h-3.8l-2.5,2l-3,1.2l-12.6-0.1l0,0L93,275.7
		l-2.7-1.6l-2-2l-4.3,0.2l-1.2,1.6l-0.6,4.1v12.4l0.6,3.2l1.6,2.2l2,1.4l3,1.2h4.3h1.9l0.1,0.9H106l0,0h10.3l0.1-0.9h1.9h4.3l3-1.2
		l2-1.4l1.6-2.2l0.6-3.2v-12.4l-0.6-3.3C129,274.1,128.6,273.6,128.4,273.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="105.7,289.8 92.7,289.8 92.7,298.1 95.1,300 105.7,300 
		116.2,300 118.6,298.1 118.6,289.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M145.2,236.8h30.3c0,0,4.4,0.8,6.1,8.1l0.3,18c0,0-2.3,10.3-8.5,13.6
		h-27.2c0,0-5.5-4-7.9-13.4v-18.4C138.3,244.5,140.5,237.6,145.2,236.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M182.6,273.1l-0.6-0.8h-3.8l-2.5,2l-3,1.2l-12.6-0.1l0,0
		l-12.8,0.3l-2.7-1.6l-2-2l-4.3,0.2l-1.2,1.6l-0.6,4.1v12.4l0.6,3.2l1.6,2.2l2,1.4l3,1.2h4.3h1.9l0.1,0.9h10.3l0,0h10.3l0.1-0.9h1.9
		h4.3l3-1.2l2-1.4l1.6-2.2l0.6-3.2v-12.4l-0.6-3.3C183.2,274.1,182.8,273.6,182.6,273.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="159.9,289.8 146.9,289.8 146.9,298.1 149.3,300 159.9,300 
		170.4,300 172.8,298.1 172.8,289.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M42,320.8h27.7c0,0,4,0.8,5.6,8.1l0.3,18c0,0-2.1,10.3-7.8,13.6H43
		c0,0-5.1-4-7.3-13.4v-18.4C35.7,328.5,37.7,321.6,42,320.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M76.2,357.1l-0.6-0.8h-3.5l-2.3,2l-2.8,1.2l-11.6-0.1l0,0
		l-11.7,0.3l-2.5-1.6l-1.8-2l-3.9,0.2l-1.1,1.6L34,362v12.4l0.5,3.2l1.5,2.2l1.8,1.4l2.8,1.2h3.9h1.7l0.1,0.9h9.5l0,0h9.5l0.1-0.9
		h1.7H71l2.8-1.2l1.8-1.4l1.5-2.2l0.5-3.2v-12.4l-0.5-3.3C76.8,358.1,76.5,357.6,76.2,357.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="55.5,373.8 43.6,373.8 43.6,382.1 45.8,384 55.5,384 
		65.1,384 67.3,382.1 67.3,373.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M90.8,321.8h27.7c0,0,4,0.8,5.6,8.1l0.3,18c0,0-2.1,10.3-7.8,13.6
		H91.7c0,0-5.1-4-7.3-13.4v-18.4C84.5,329.5,86.5,322.6,90.8,321.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M125,358.1l-0.6-0.8H121l-2.3,2l-2.8,1.2l-11.6-0.1l0,0l-11.7,0.3
		l-2.5-1.6l-1.8-2l-3.9,0.2l-1.1,1.6l-0.6,4.1v12.4l0.5,3.2l1.5,2.2l1.8,1.4l2.8,1.2h3.9H95l0.1,0.9h9.5l0,0h9.5l0.1-0.9h1.7h3.9
		l2.8-1.2l1.8-1.4l1.5-2.2l0.5-3.2v-12.4l-0.5-3.3C125.6,359.1,125.3,358.6,125,358.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="104.2,374.8 92.4,374.8 92.4,383.1 94.6,385 104.2,385 
		113.9,385 116.1,383.1 116.1,374.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M140.5,321.8h27.7c0,0,4,0.8,5.6,8.1l0.3,18c0,0-2.1,10.3-7.8,13.6
		h-24.9c0,0-5.1-4-7.3-13.4v-18.4C134.1,329.5,136.2,322.6,140.5,321.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M174.7,358.1l-0.6-0.8h-3.5l-2.3,2l-2.8,1.2l-11.6-0.1l0,0
		l-11.7,0.3l-2.5-1.6l-1.8-2l-3.9,0.2l-1.1,1.6l-0.6,4.1v12.4l0.5,3.2l1.5,2.2l1.8,1.4l2.8,1.2h3.9h1.7l0.1,0.9h9.5l0,0h9.5l0.1-0.9
		h1.7h3.9l2.8-1.2l1.8-1.4l1.5-2.2l0.5-3.2v-12.4l-0.5-3.3C175.2,359.1,174.9,358.6,174.7,358.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="153.9,374.8 142,374.8 142,383.1 144.2,385 153.9,385 
		163.6,385 165.8,383.1 165.8,374.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M40.2,406.8h28.3c0,0,4.1,0.8,5.7,8.1l0.3,18c0,0-2.1,10.3-7.9,13.6
		H41.2c0,0-5.2-4-7.4-13.4v-18.4C33.7,414.5,35.8,407.6,40.2,406.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M75.1,443.1l-0.6-0.8H71l-2.3,2l-2.8,1.2L54,445.4l0,0l-12,0.3
		l-2.5-1.6l-1.9-2l-4,0.2l-1.1,1.6L32,448v12.4l0.5,3.2l1.5,2.2l1.9,1.4l2.8,1.2h4h1.7l0.1,0.9h9.7l0,0h9.7l0.1-0.9h1.7h4l2.8-1.2
		l1.9-1.4l1.5-2.2l0.5-3.2v-12.4l-0.5-3.3C75.7,444.1,75.4,443.6,75.1,443.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="53.9,459.8 41.8,459.8 41.8,468.1 44.1,470 53.9,470 
		63.8,470 66,468.1 66,459.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M90,407.8h28.3c0,0,4.1,0.8,5.7,8.1l0.3,18c0,0-2.1,10.3-7.9,13.6H91
		c0,0-5.2-4-7.4-13.4v-18.4C83.6,415.5,85.6,408.6,90,407.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M124.9,444.1l-0.6-0.8h-3.5l-2.3,2l-2.8,1.2l-11.8-0.1l0,0
		l-12,0.3l-2.5-1.6l-1.9-2l-4,0.2l-1.1,1.6l-0.6,4.1v12.4l0.5,3.2l1.5,2.2l1.9,1.4l2.8,1.2h4h1.7l0.1,0.9h9.7l0,0h9.7l0.1-0.9h1.7h4
		l2.8-1.2l1.9-1.4l1.5-2.2l0.5-3.2v-12.4l-0.5-3.3C125.5,445.1,125.2,444.6,124.9,444.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="103.7,460.8 91.6,460.8 91.6,469.1 93.9,471 103.7,471 
		113.6,471 115.9,469.1 115.9,460.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M140.7,407.8H169c0,0,4.1,0.8,5.7,8.1l0.3,18c0,0-2.1,10.3-7.9,13.6
		h-25.5c0,0-5.2-4-7.4-13.4v-18.4C134.3,415.5,136.3,408.6,140.7,407.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M175.6,444.1l-0.6-0.8h-3.5l-2.3,2l-2.8,1.2l-11.8-0.1l0,0
		l-12,0.3l-2.5-1.6l-1.9-2l-4,0.2l-1.1,1.6l-0.6,4.1v12.4l0.5,3.2l1.5,2.2l1.9,1.4l2.8,1.2h4h1.7l0.1,0.9h9.7l0,0h9.7l0.1-0.9h1.7h4
		l2.8-1.2l1.9-1.4l1.5-2.2l0.5-3.2v-12.4l-0.5-3.3C176.2,445.1,175.9,444.6,175.6,444.1z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="154.4,460.8 142.3,460.8 142.3,469.1 144.6,471 154.4,471 
		164.3,471 166.6,469.1 166.6,460.8 	"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M120.5,144.8h43.3c0,0,6.3,0.9,8.8,8.6l0.5,19
		c0,0-3.3,10.9-12.1,14.3H122c0,0-7.9-4.2-11.3-14.2V153C110.7,152.9,113.8,145.7,120.5,144.8z"
          />
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-miterlimit="10"
            d="M173.9,183.4l-0.9-1.1h-5.4l-3.5,2.8l-4.3,1.6l-18-0.1l0,0
		l-18.3,0.4l-3.8-2.1l-2.9-2.7l-6.1,0.2l-1.7,2.1L108,190v16.8l0.8,4.4l2.3,3l2.9,1.9l4.3,1.6h6.1h2.7l0.2,1.2H142l0,0h14.8l0.2-1.2
		h2.7h6.1l4.3-1.6l2.9-1.9l2.3-3l0.8-4.4V190l-0.8-4.4C174.8,184.8,174.3,184.1,173.9,183.4z"
          />
          <polygon
            fill="none"
            stroke="#000000"
            stroke-miterlimit="10"
            points="141.5,206.2 123,206.2 123,217.4 126.4,220 141.5,220 
		156.6,220 160,217.4 160,206.2 	"
          />
        </g>
      </>
    )
  },

  exterior: {
    width: 701,
    height: 1005,
    svg: (
      <>
        <g id="Layer_1">
          <g>
            <path
              fill="none"
              stroke="#0B0B0B"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M48.7,577.9V408.6c0-1.2,1-2.2,2.2-2.2h1.1
			c1.2,0,2.2,1,2.2,2.2v169.3c0,1.2-1,2.2-2.2,2.2h-1.1C49.7,580.1,48.7,579.1,48.7,577.9z"
            />

            <rect
              x="52.2"
              y="428.3"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 486.4611 374.0686)"
              fill="none"
              stroke="#0B0B0B"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="8"
              height="3.9"
            />

            <rect
              x="52.3"
              y="553.3"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 611.5122 498.9301)"
              fill="none"
              stroke="#0B0B0B"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="8"
              height="3.9"
            />
            <g>
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="54"
                cy="356.8"
                rx="34"
                ry="35.8"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="54"
                cy="356.8"
                rx="23.4"
                ry="24.6"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="54.4"
                cy="356.8"
                rx="2.7"
                ry="2.9"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="41.3,356.8 33.1,351.7 33.1,361.8 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="51,369.2 43.1,374.8 51.6,379.2 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="51.6,344.1 51,334.1 43.1,339.7 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="66.5,373.5 72.8,366.1 63.5,364 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="73,346.6 65.9,339.9 63.9,349.7 			
				"
              />
            </g>
            <g>
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="54"
                cy="639.8"
                rx="34"
                ry="35.8"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="54"
                cy="639.8"
                rx="23.4"
                ry="24.6"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="54.4"
                cy="639.8"
                rx="2.7"
                ry="2.9"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="41.3,639.8 33.1,634.8 33.1,644.8 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="51,652.2 43.1,657.8 51.6,662.2 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="51.6,627.1 51,617.1 43.1,622.7 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="66.5,656.5 72.8,649.1 63.5,647 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="73,629.6 65.9,622.9 63.9,632.7 			
				"
              />
            </g>
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M133.5,394.7l0.6,335.6c0,0,0.6,4.3-4.9,6.1
			H83.1c0,0-12.7,0.9-15.5-7.9l-1.6-31.9l-2.6-16.2c0,0,36.5-1.8,39.3-39.6c0,0,2.8-41.6-44.2-43.4V400.4c0,0,40.3-1.8,41-42.7
			c0,0,2.1-42.2-43.7-44v-22L70.7,275h14.1l4.3,3.8l13.2,1.6c0,0,10.5,0.7,16.4,9c0,0,10.7,22.2,14.7,93c0,0-3.2,4.5-2.6,10.1
			c0,0,13.7,10.8,41,30.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M86.3,736.4v-41.8c0,0,39-16,36.7-57.7
			c0,0-1.9-51.3-57.2-61V415.2c0,0,19.2-6.6,67.8-4.6"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M120.5,484.1v-17.3c0-0.9,0.8-1.7,1.7-1.7h0.9
			c0.9,0,1.7,0.8,1.7,1.7v17.3c0,0.9-0.8,1.7-1.7,1.7h-0.9C121.2,485.8,120.5,485,120.5,484.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M133.7,491.1c0,0-48.1,2.5-67.7-0.5"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M64.1,282.5c0,0,11.3,0.9,13.3,7.4v13.5
			l3.1,1.5c0,0,5.6-2.9,8.5-14.3l0.2-11.7l-4.3-3.8H70.7L64.1,282.5z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M95.6,337.8v-20c0,0,2.6-8,6.2-8.8l4.2,10
			c0.2,0.6,2.1,3.9,2.5,4.3c2.9,3.9,8.6,16.7,8.5,29.3c0,0,2.2,29.5-20.4,47"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M113.2,284.5l0.5,29.2c0,0,4.2,10.2,5.8,16.6
			c0,0,5.1,25.4,5.5,44.2c0,0,0.7,11.4,5.9,18.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M75.1,303.3c0,0-1,1.8-5.4,2
			c0,0-2.6-0.2-4.6-1.2c-1.1-0.6-1.9-1.2-2-1.8l0-10.5c-0.1-0.8,0.2-1.5,0.8-2c1.2-1.2,3.8-2.9,7.1-1.2c0.3,0.2,0.6,0.4,0.9,0.7
			C73.1,290.8,76.4,295.1,75.1,303.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M55.8,313.1h-7.9v-17.2c0,0,2.8-3.6,7.9-4.3
			V313.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M83.1,736.4l-0.1,7.5c0,0-0.4,1.9-2.7,2.1h-8.8
			c0,0-3.3,0-4-2.3l-0.1-16.1l0,0C67.6,727.5,68.2,736.7,83.1,736.4z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M100.4,282.3l3.6,1c0.6,0.2,1.2,0.6,1.6,1.2
			c1.1,1.8,3.7,6.2,6.7,15.3c0,0,1,7.8,0,13.2l-3.3,8.1c-0.2,0.5-1.1,0.8-1.3,0.3l-5.5-11.7c0,0-3-9.6-2.8-26.7
			C99.2,282.5,99.8,282,100.4,282.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M111.1,736.4v-12.9c0,0-12.2,2.4-19.4,12.6
			L111.1,736.4z"
            />
            <g>
              <path
                fill="#FFFFFF"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M143.4,416.8c-3.6,0-6.6,4-6.6,0.2
				s2.9-8.2,6.6-8.2c3.6,0,6.6,4.4,6.6,8.2S147,416.8,143.4,416.8z"
              />
              <path
                fill="#F7F6F8"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M137.7,411.9l-6.6-7
				c-0.6-0.7-0.6-1.7,0-2.4l0,0c0.6-0.7,1.6-0.7,2.3,0l6.3,6.8c0.6,0.7,0,1-0.6,1.7l0,0C138.4,411.6,138.3,412.5,137.7,411.9z"
              />
            </g>
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="133.7"
              y1="382.4"
              x2="171.9"
              y2="421.6"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="171.9"
              y1="423.2"
              x2="171.9"
              y2="421.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M133.2,469.3l17.4,5.3c2.1,0.5,3.3,2.8,2.8,4.9
			l-1,4.3c-0.5,2.2-2.6,3.5-4.7,2.9l-14-3.9"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M180.9,636.3l0.3-1.8c0.4-2.6-1.2-5.1-3.7-5.6
			l-32.8-6.1v-35.1c0-2.7-2.1-4.8-4.6-4.8h-1.7c-2.5,0-4.6,2.2-4.6,4.8v40.6c0,1.7,0.8,3.2,2.1,4c0.5,0.4,1.2,0.7,1.9,0.8l37.9,7.1
			C178,640.7,180.4,639,180.9,636.3z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M184.3,715.4l0.3-1.8c0.4-2.6-1.2-5.1-3.7-5.6
			l-32.8-6.1v-35.1c0-2.7-2.1-4.8-4.6-4.8h-1.7c-2.5,0-4.6,2.2-4.6,4.8v40.6c0,1.7,0.8,3.2,2.1,4c0.5,0.4,1.2,0.7,1.9,0.8l37.9,7.1
			C181.5,719.8,183.9,718,184.3,715.4z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M133.7,560.3c0.1,0,0.3,0.1,0.5,0.1l37.9,7.1
			c2.5,0.5,4.9-1.3,5.3-3.9l0.3-1.8c0.4-2.6-1.2-5.1-3.7-5.6l-32.8-6.1v-35.1c0-2.7-2.1-4.8-4.6-4.8h-1.7c-0.4,0-0.8,0.1-1.1,0.2
			V560.3z"
            />
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="133.4"
                y1="571.6"
                x2="232.3"
                y2="571.6"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                d="M153.6,571.8v-27.3c0-2.5-1.7-4.6-4-5l-15.8-2.6"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="133.9"
                y1="644.4"
                x2="232.4"
                y2="644.8"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                d="M157.1,644.6v-27.3c0-2.5-1.7-4.6-4-5l-19.1-3"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="133.9"
                y1="723.4"
                x2="223.9"
                y2="723.4"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                d="M156.6,723.6v-27.3c0-2.5-1.7-4.6-4-5l-18.6-3"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="133"
                y1="491.2"
                x2="232.3"
                y2="491.2"
              />
            </g>
            <path
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              d="M223.4,723.4V428.8c0-0.7,0.9-0.9,1.1-0.2l7.4,25.5
			c0.3,1,0.4,2,0.4,3.1V720c0,1.9-1.3,3.4-3,3.4H223.4z"
            />

            <rect
              x="135.1"
              y="674"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 810.9347 539.6238)"
              fill="none"
              stroke="#231F20"
              stroke-width="2"
              stroke-miterlimit="10"
              width="1"
              height="2.5"
            />

            <rect
              x="135.1"
              y="699.2"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 836.0353 564.8089)"
              fill="none"
              stroke="#231F20"
              stroke-width="2"
              stroke-miterlimit="10"
              width="1"
              height="2.5"
            />
          </g>
        </g>
        <g id="Layer_3">
          <g>
            <path
              fill="none"
              stroke="#0B0B0B"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M654.4,578.6V409.3c0-1.2-1-2.2-2.2-2.2h-1.1
			c-1.2,0-2.2,1-2.2,2.2v169.3c0,1.2,1,2.2,2.2,2.2h1.1C653.4,580.8,654.4,579.8,654.4,578.6z"
            />

            <rect
              x="643"
              y="429"
              transform="matrix(-4.485941e-11 1 -1 -4.485941e-11 1077.9386 -215.9838)"
              fill="none"
              stroke="#0B0B0B"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="8"
              height="3.9"
            />

            <rect
              x="642.9"
              y="554"
              transform="matrix(-4.463115e-11 1 -1 -4.463115e-11 1202.8 -90.9327)"
              fill="none"
              stroke="#0B0B0B"
              stroke-width="0.75"
              stroke-miterlimit="10"
              width="8"
              height="3.9"
            />
            <g>
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="649.2"
                cy="357.5"
                rx="34"
                ry="35.8"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="649.2"
                cy="357.5"
                rx="23.4"
                ry="24.6"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="648.8"
                cy="357.5"
                rx="2.7"
                ry="2.9"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="661.8,357.5 670,352.5 670,362.5 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="652.2,369.9 660.1,375.5 651.5,379.9 
							"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="651.5,344.8 652.2,334.8 660.1,340.4 
							"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="636.7,374.2 630.3,366.8 639.6,364.7 
							"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="630.2,347.3 637.2,340.6 639.2,350.4 
							"
              />
            </g>
            <g>
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="649.2"
                cy="640.5"
                rx="34"
                ry="35.8"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                cx="649.2"
                cy="640.5"
                rx="23.4"
                ry="24.6"
              />
              <ellipse
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                cx="648.8"
                cy="640.5"
                rx="2.7"
                ry="2.9"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="661.8,640.5 670,635.5 670,645.5 			
				"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="652.2,652.9 660.1,658.5 651.5,662.9 
							"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="651.5,627.8 652.2,617.8 660.1,623.4 
							"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="636.7,657.2 630.3,649.8 639.6,647.7 
							"
              />
              <polygon
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="630.2,630.3 637.2,623.6 639.2,633.4 
							"
              />
            </g>
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M569.7,395.4L569.1,731c0,0-0.6,4.3,4.9,6.1
			H620c0,0,12.7,0.9,15.5-7.9l1.6-31.9l2.6-16.2c0,0-36.5-1.8-39.3-39.6c0,0-2.8-41.6,44.2-43.4V401.1c0,0-40.3-1.8-41-42.7
			c0,0-2.1-42.2,43.7-44v-22l-14.9-16.6h-14.1l-4.3,3.8l-13.2,1.6c0,0-10.5,0.7-16.4,9c0,0-10.7,22.2-14.7,93c0,0,3.2,4.5,2.6,10.1
			c0,0-13.7,10.8-41,30.7"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M616.8,737.1v-41.8c0,0-39-16-36.7-57.7
			c0,0,1.9-51.3,57.2-61V415.9c0,0-19.2-6.6-67.8-4.6"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M582.7,484.8v-17.3c0-0.9-0.8-1.7-1.7-1.7h-0.9
			c-0.9,0-1.7,0.8-1.7,1.7v17.3c0,0.9,0.8,1.7,1.7,1.7h0.9C581.9,486.5,582.7,485.7,582.7,484.8z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M569.5,491.8c0,0,48.1,2.5,67.7-0.5"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M639.1,283.2c0,0-11.3,0.9-13.3,7.4v13.5
			l-3.1,1.5c0,0-5.6-2.9-8.5-14.3l-0.2-11.7l4.3-3.8h14.1L639.1,283.2z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M607.6,338.6v-20c0,0-2.6-8-6.2-8.8l-4.2,10
			c-0.2,0.6-2.1,3.9-2.5,4.3c-2.9,3.9-8.6,16.7-8.5,29.3c0,0-2.2,29.5,20.4,47"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M590,285.2l-0.5,29.2c0,0-4.2,10.2-5.8,16.6
			c0,0-5.1,25.4-5.5,44.2c0,0-0.7,11.4-5.9,18.1"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M628,304c0,0,1,1.8,5.4,2c0,0,2.6-0.2,4.6-1.2
			c1.1-0.6,1.9-1.2,2-1.8l0-10.5c0.1-0.8-0.2-1.5-0.8-2c-1.2-1.2-3.8-2.9-7.1-1.2c-0.3,0.2-0.6,0.4-0.9,0.7
			C630.1,291.5,626.8,295.8,628,304z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M647.3,313.9h7.9v-17.2c0,0-2.8-3.6-7.9-4.3
			V313.9z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M620,737.1l0.1,7.5c0,0,0.4,1.9,2.7,2.1l8.8,0
			c0,0,3.3,0,4-2.3l0.1-16.1l0,0C635.6,728.2,634.9,737.5,620,737.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M602.8,283l-3.6,1c-0.6,0.2-1.2,0.6-1.6,1.2
			c-1.1,1.8-3.7,6.2-6.7,15.3c0,0-1,7.8,0,13.2l3.3,8.1c0.2,0.5,1.1,0.8,1.3,0.3l5.5-11.7c0,0,3-9.6,2.8-26.7
			C604,283.2,603.4,282.7,602.8,283z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M592.1,737.1v-12.9c0,0,12.2,2.4,19.4,12.6
			L592.1,737.1z"
            />
            <g>
              <path
                fill="#FFFFFF"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M559.8,417.5c3.6,0,6.6,4,6.6,0.2
				s-2.9-8.2-6.6-8.2c-3.6,0-6.6,4.4-6.6,8.2S556.2,417.5,559.8,417.5z"
              />
              <path
                fill="#F7F6F8"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M565.5,412.6l6.6-7c0.6-0.7,0.6-1.7,0-2.4
				h0c-0.6-0.7-1.6-0.7-2.3,0l-6.3,6.8c-0.6,0.7,0,1,0.6,1.7l0,0C564.7,412.3,564.8,413.3,565.5,412.6z"
              />
            </g>
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="569.5"
              y1="383.1"
              x2="531.2"
              y2="422.3"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="531.2"
              y1="423.9"
              x2="531.2"
              y2="421.8"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M570,470l-17.4,5.3c-2.1,0.5-3.3,2.8-2.8,4.9
			l1,4.3c0.5,2.2,2.6,3.5,4.7,2.9l14-3.9"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M522.3,637l-0.3-1.8c-0.4-2.6,1.2-5.1,3.7-5.6
			l32.8-6.1v-35.1c0-2.7,2.1-4.8,4.6-4.8h1.7c2.5,0,4.6,2.2,4.6,4.8V629c0,1.7-0.8,3.2-2.1,4c-0.5,0.4-1.2,0.7-1.9,0.8l-37.9,7.1
			C525.1,641.4,522.7,639.7,522.3,637z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M518.8,716.1l-0.3-1.8
			c-0.4-2.6,1.2-5.1,3.7-5.6l32.8-6.1v-35.1c0-2.7,2.1-4.8,4.6-4.8h1.7c2.5,0,4.6,2.2,4.6,4.8v40.6c0,1.7-0.8,3.2-2.1,4
			c-0.5,0.4-1.2,0.7-1.9,0.8l-37.9,7.1C521.7,720.5,519.3,718.7,518.8,716.1z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M569.5,561c-0.1,0-0.3,0.1-0.5,0.1l-37.9,7.1
			c-2.5,0.5-4.9-1.3-5.3-3.9l-0.3-1.8c-0.4-2.6,1.2-5.1,3.7-5.6l32.8-6.1v-35.1c0-2.7,2.1-4.8,4.6-4.8h1.7c0.4,0,0.8,0.1,1.1,0.2
			V561z"
            />
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="569.7"
                y1="572.3"
                x2="470.8"
                y2="572.3"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                d="M549.6,572.5v-27.3c0-2.5,1.7-4.6,4-5l15.8-2.6"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="569.2"
                y1="645.1"
                x2="470.7"
                y2="645.5"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                d="M546.1,645.3V618c0-2.5,1.7-4.6,4-5l19.1-3"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="569.2"
                y1="724.2"
                x2="479.3"
                y2="724.2"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                d="M546.6,724.4v-27.3c0-2.5,1.7-4.6,4-5l18.6-3"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#231F20"
                stroke-miterlimit="10"
                x1="570.2"
                y1="491.9"
                x2="470.8"
                y2="491.9"
              />
            </g>
            <path
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              d="M479.8,724.2V429.5c0-0.7-0.9-0.9-1.1-0.2l-7.4,25.5
			c-0.3,1-0.4,2-0.4,3.1v262.8c0,1.9,1.3,3.4,3,3.4H479.8z"
            />

            <rect
              x="567"
              y="674.7"
              transform="matrix(-4.516672e-11 1 -1 -4.516672e-11 1243.4937 108.4898)"
              fill="none"
              stroke="#231F20"
              stroke-width="2"
              stroke-miterlimit="10"
              width="1"
              height="2.5"
            />

            <rect
              x="567"
              y="699.9"
              transform="matrix(-4.516672e-11 1 -1 -4.516672e-11 1268.6788 133.5904)"
              fill="none"
              stroke="#231F20"
              stroke-width="2"
              stroke-miterlimit="10"
              width="1"
              height="2.5"
            />
          </g>
        </g>
        <g id="Layer_2">
          <g>
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M278.6,389.2c0,0-1.3-69.8,24.1-100.8c0,0,46.8-28.1,91,0
			c0,0,25.4,27.3,23.1,100.8C416.8,389.2,359.6,373.8,278.6,389.2z"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M289.3,387.1c0,0-1.1-66.1,20.5-96.4c0,0,39.7-27.4,77.1,0
			c0,0,21.7,24.5,19.7,96.2C406.5,387,357.8,377.2,289.3,387.1z"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M429.3,410.9c0.2-4.1,2.5-13,2.5-13v-71.3
			c-3.4-19.4-18-30.8-18-30.8c-29.6-23.5-65.8-22.1-65.8-22.1s-36.2-1.4-65.8,22.1c0,0-14.6,11.4-18,30.8v71.3c0,0,2.3,8.9,2.5,13
			l0.9,147.9v39.4l-2.5,7.3v71.1l5.2,14.8l1.7,35.4c0,1.5,0.3,2.9,0.9,4.3c1.4,3.2,4.1,7.6,11.6,8.3H348h62.5c0.7,0,1.5-0.1,2.1-0.4
			c8.1-3.8,8.8-11.4,8.8-11.4l3-34.6l6.6-16.4v-71.1l-2.5-7.3v-39.4L429.3,410.9z"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M303.1,288.1c0,0-20.4,3.3-26.3,20.9c0,0-3.8,10.1,0,13.8
			c0,0,8.7-5.4,13.4-11.5C290.2,311.4,298.9,290.3,303.1,288.1z"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M393.8,288.1c0,0,20.4,3.3,26.3,20.9c0,0,3.8,10.1,0,13.8
			c0,0-8.7-5.4-13.4-11.5C406.7,311.4,398,290.3,393.8,288.1z"
            />
            <polyline
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              points="276.8,323 275.1,410.9 266.6,410.9 		"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M266.4,409c0,0,2.2-5.3,3.2-10.3v-66.2c0,0-1.8-7-3.8-10.6"
            />
            <polyline
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              points="420,323 420.5,410.9 429,410.9 		"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M429.2,409c0,0-2.2-5.3-3.2-10.3v-66.2c0,0,1.3-6,4.6-11.2"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              d="M279.4,406v307.1c0,10.6,4.2,19.2,9.4,19.2h117.9
			c5.1,0,9.4-8.6,9.4-19.2V406H279.4z"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M427.8,598.8c0,0-4.9,6.9-5,10l-1.3,48.7v22.8
			c0,0,1.6,10.7,2.8,12.6"
            />
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M266.8,597.3c0,0,4.9,6.9,5,10l1.3,48.7v22.8
			c0,0-1.6,10.7-2.8,12.6"
            />
            <path
              fill="none"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M272.2,729c0,0-5.5,13.4,7.2,16.8c0.5,0.1,1,0.2,1.5,0.2h132.4
			c0.8,0,1.6-0.2,2.4-0.5c3-1.1,9.1-6.4,5.3-16"
            />
            <path
              fill="#F9F9F9"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M445.1,441.5c-0.6,3.9-15.1-1.7-22.5-5.8
			c-4-2.2-5.5-8.8-3.5-12.7c2-3.9,7.7-3.6,11.8-1.5S446.2,433.5,445.1,441.5z"
            />
            <path
              fill="#F9F9F9"
              stroke="#020202"
              stroke-miterlimit="10"
              d="M248.8,441.5c0.6,3.9,15.1-1.7,22.5-5.8c4-2.2,5.5-8.8,3.5-12.7
			s-7.7-3.6-11.8-1.5C258.9,423.6,247.6,433.5,248.8,441.5z"
            />
            <g>
              <path
                fill="none"
                stroke="#020202"
                stroke-miterlimit="10"
                d="M280.5,389.5c0,0,70-15,135,0c0,0-7,6-9,8c0,0-47-6-120-1
				C286.5,396.5,284.5,393.5,280.5,389.5z"
              />
              <path
                fill="none"
                stroke="#020202"
                stroke-miterlimit="10"
                d="M408.5,399.5c0,0-49-6-122-1"
              />
              <line
                fill="none"
                stroke="#020202"
                stroke-miterlimit="10"
                x1="286.5"
                y1="398.5"
                x2="278.5"
                y2="389.5"
              />
              <line
                fill="none"
                stroke="#020202"
                stroke-miterlimit="10"
                x1="407.5"
                y1="399.5"
                x2="417.5"
                y2="389.5"
              />
            </g>
            <line
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              x1="280"
              y1="673.5"
              x2="273"
              y2="673.5"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              x1="279"
              y1="591.5"
              x2="267"
              y2="591.5"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              x1="279.5"
              y1="493.5"
              x2="267.5"
              y2="493.5"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              x1="422"
              y1="673.5"
              x2="415"
              y2="673.5"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              x1="428"
              y1="591.5"
              x2="416"
              y2="591.5"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-miterlimit="10"
              x1="427.5"
              y1="493.5"
              x2="415.5"
              y2="493.5"
            />
          </g>
        </g>
        <g id="Layer_4">
          <g>
            <g>
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M270.5,214c0,0-2.7-0.9-4.4-3.1
				c-1.5-1.9-4.1-6.4-5.2-8.8c-0.5-1.1-1.8-4.6-3-8v53.1c0,3.2,2.6,5.8,5.8,5.8h18.1c3.2,0,5.8-2.6,5.8-5.8l-0.1-32.7h-5.7
				C274.5,214.5,270.5,214,270.5,214z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M415.9,213.9c0,0,2.8-0.3,4.9-3
				c1.5-1.9,4.1-6,5.2-8.8c0.5-1.1,1.8-4.6,3-8v53.1c0,3.2-2.6,5.8-5.8,5.8h-18.1c-3.2,0-5.8-2.6-5.8-5.8v-32.7h5.7
				C408.8,214.7,415.9,213.9,415.9,213.9z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M400.3,144.2c-0.3,0.1-5.9,0.8-6.2,0.8
				l-46.3,1.5c-0.7,0.1-38-0.5-55.3-2.5c-0.8-0.1-3.6-0.5-4.4-0.7l-4.9-1.3c-0.5-0.1-0.9-0.3-1.3-0.4c-7.4-3.2-12-13.6-12-15.3
				l15.9-37.1c1.9-2.4,4.8-5.4,6.5-5.4h102.1c1.7,0,7.3,5.3,10.3,10.4l12.1,31.5C416.9,127.5,415.2,140.4,400.3,144.2z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M412.7,129.1l-69.1-0.9l-69.1,0.9
				c-1.2,0,15.5-33.4,15.5-33.4c0.5-1.5,1.7-2.1,3.8-2.1h98.8c1.2,0,3,0.7,4.1,2.2C396.8,95.9,413.9,129.1,412.7,129.1z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M416.9,125.8l8.2,10c0.5,0.6,1.6,2.9,2.3,4.2
				c0.2,0.5,1.1,2.5,1.2,3l1.8,5.1c0.5,1.5,1.1,6.8,1,8.4l0.1,28.4c0,0.6-0.1,1.1-0.2,1.7c-0.7,3.5-3.9,16.4-10.3,24.5
				c-1.5,1.9-3.8,2.9-6.2,2.9l-77.3,0"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M270.1,125.8l-8.2,10
				c-0.5,0.6-1.6,2.9-2.3,4.2c-0.2,0.5-1.1,2.5-1.2,3l-1.8,5.1c-0.5,1.5-1.1,6.8-1,8.4l-0.1,28.4c0,0.6,0.1,1.1,0.2,1.7
				c0.7,3.5,3.9,16.4,10.3,24.5c1.5,1.9,3.8,2.9,6.2,2.9l77.3,0"
              />
              <g>
                <path
                  fill="none"
                  stroke="#231F20"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  d="M383,201.7l-78.5,0.2c-1.4,0-3.1-6.5-3.1-6.5
					c0-1.4,0.1-2.6,1.5-2.6h81.5c1.4,0,1.6,1.1,1.6,2.6C386.1,195.4,384.4,201.7,383,201.7z"
                />
                <path
                  fill="none"
                  stroke="#231F20"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  d="M343.8,207.1h-46.3c0,0-3.4-2.6-3.6-5.9
					c0,0-3.2-11.9-18.1-12.6c0,0-9.4-0.7-9.9-1.6c0,0-0.7-3.4,11.7-5.1h66.3"
                />
                <path
                  fill="none"
                  stroke="#231F20"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  d="M343.8,207.1h46.3c0,0,3.4-2.6,3.6-5.9
					c0,0,3.2-11.9,18.1-12.6c0,0,9.4-0.7,9.9-1.6c0,0,0.7-3.4-11.7-5.1h-66.3"
                />
              </g>
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M363.3,176h-40.5c-2.4,0-26.7-4.2-28.4-8v-3.6
				c0-2.4,18.6-11.9,21.1-11.9h56.5c2.9,0,16.3,5.1,20.8,12.2v4.1C392.9,171,371.8,176,363.3,176z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M427.7,141.3l-7.5,8.8l-4.5,10.7
				c0,0,2.1,5.3,5.5,7.2c0,0,4.7,1.9,10.2,3l0-15.4c0-2.6-0.3-4.1-0.4-4.9C430.4,148.5,429.6,145.7,427.7,141.3z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M259.2,141.1l7.5,8.8l4.5,10.7
				c0,0-2.1,5.3-5.5,7.2c0,0-4.7,1.9-10.2,3l0-15.4c0-2.6,0.3-4.1,0.4-4.9C256.5,148.4,257.2,145.6,259.2,141.1z"
              />
              <path
                fill="#F4EFF0"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M279.7,162.5c0,0,8,0.2,19.3-5.8
				c1.5-0.8,1.9-2.8,0.8-4.1c0,0,0,0-0.1-0.1c0,0-10.5-3.6-20-4.1c0,0-3.8-0.6-8.9-0.4c-3.2,0.1-5.7,2.8-5.5,6
				c0,0.8,0.2,1.6,0.4,2.2c0.1,0.3,0.3,0.6,0.5,0.8C267.3,158.4,271.5,162.3,279.7,162.5z"
              />
              <path
                fill="#F7F6F8"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M408.2,162.7c0,0-8,0.2-19.3-5.8
				c-1.5-0.8-1.9-2.8-0.8-4.1c0,0,0,0,0.1-0.1c0,0,10.5-3.6,20-4.1c0,0,3.8-0.6,8.9-0.4c3.2,0.1,5.7,2.8,5.5,6
				c0,0.8-0.2,1.6-0.4,2.2c-0.1,0.3-0.3,0.6-0.5,0.8C420.6,158.6,416.5,162.5,408.2,162.7z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M282.8,195.4c-0.6,0-1.2,0.1-1.8,0.3
				c-1.1-2.1-3.2-3.5-5.7-3.5c-3.5,0-6.4,2.9-6.4,6.4c0,3.5,2.9,6.4,6.4,6.4c2.1,0,4-1,5.1-2.6c0.7,0.3,1.5,0.5,2.3,0.5
				c2.6,0,4.8-1.7,4.8-3.7C287.6,197,285.4,195.4,282.8,195.4z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M405,195.4c0.6,0,1.2,0.1,1.8,0.3
				c1.1-2.1,3.2-3.5,5.7-3.5c3.5,0,6.4,2.9,6.4,6.4c0,3.5-2.9,6.4-6.4,6.4c-2.1,0-4-1-5.1-2.6c-0.7,0.3-1.5,0.5-2.3,0.5
				c-2.6,0-4.8-1.7-4.8-3.7C400.2,197,402.3,195.4,405,195.4z"
              />
              <g>
                <ellipse
                  fill="none"
                  stroke="#231F20"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  cx="255.1"
                  cy="124.3"
                  rx="12.4"
                  ry="8.3"
                />
                <path
                  fill="none"
                  stroke="#231F20"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  d="M259.7,139.9c0,0-1.1-6.3-3.3-7.3
					c0,0,6.2,0.2,10.1-5l0.9,1.5C267.4,129.1,261.4,135.8,259.7,139.9z"
                />
              </g>
              <g>
                <ellipse
                  fill="none"
                  stroke="#231F20"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  cx="431.5"
                  cy="124.3"
                  rx="12.4"
                  ry="8.3"
                />
                <path
                  fill="none"
                  stroke="#231F20"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  d="M427,139.9c0,0,1.1-6.3,3.3-7.3
					c0,0-6.2,0.2-10.1-5l-0.9,1.5C419.3,129.1,425.3,135.8,427,139.9z"
                />
              </g>
            </g>
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M420.1,69.5H265.9c-1.3,0-2.4-1.1-2.4-2.4v-0.2
			c0-1.3,1.1-2.4,2.4-2.4h154.2c1.3,0,2.4,1.1,2.4,2.4v0.2C422.5,68.4,421.4,69.5,420.1,69.5z"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="269.5"
              y1="69.5"
              x2="269.5"
              y2="127"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="417.5"
              y1="69.5"
              x2="417.5"
              y2="127"
            />
          </g>
        </g>
        <g id="Layer_5">
          <g>
            <g>
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M426.7,860H274c-1.4,0,0.7-1.5,0.7-2.9
				l14.4-29.8c2.7-4.5,6.9-9.4,10.2-9.4h102.8c1.4,0,5.3,1.7,10.1,9.3l13.6,29.3C425.8,857.9,428.1,860,426.7,860z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M420.6,857.7H280.3c-1.1,0-1.6-0.6-1.6-1.7
				l19.3-32.9c0.5-0.6,0.5-1.6,1.9-2.1h99.6c1.9,0,2.1-0.2,3.2,1.7l19.3,32.1C422,856,423.4,857.3,420.6,857.7z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.5"
                stroke-miterlimit="10"
                d="M427.9,860c0,0,6.7,15.3,7.2,18.4
				c0,0,2.5,4.3,3.8,5.1v21.2l-5.5,27.5c0,0-1.5,5.6-5.5,6.5H273.5c0,0-4.4-2.5-6.8-9.2c0,0-5.6-20.8-5.7-22.1s0-23.9,0-23.9
				L274,860H427.9z"
              />
              <rect
                x="284.7"
                y="860"
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                width="132.5"
                height="54.8"
              />
              <line
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                x1="417.3"
                y1="893.6"
                x2="284.7"
                y2="893.6"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M284.7,873.3v34.9c0,0-11.1-16.5-14.2-34.9
				H284.7z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M417.3,873.3v34.9c0,0,11.1-16.5,14.2-34.9
				H417.3z"
              />
              <polyline
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                points="265.5,875.3 271.8,914.7 
				429.8,914.7 435.2,878.4 			"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M357.8,877.5h-15.5c-0.8,0-1.4-0.6-1.4-1.4v-4
				c0-0.8,0.6-1.4,1.4-1.4h15.5c0.8,0,1.4,0.6,1.4,1.4v4C359.1,876.9,358.5,877.5,357.8,877.5z"
              />
              <path
                fill="none"
                stroke="#231F20"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M383.4,934l5.4-15.4h41.1c0,0,2.1-0.4,2.2,2
				v13.8c0,0-0.4,3.2-4.1,4.2H350h-76.5c0,0-4.4-3.1-5.8-6.9v-11.3c0,0-0.5-2.4,1.8-2.4h42.9l5.2,15.7L383.4,934z"
              />
              <path
                fill="none"
                stroke="#020202"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M293.8,938.6c0,0,6.3,6.6,0,12.2h-25.2
				l-3.1-2.2l-0.6-26.2c0,0,1.6,12.6,8.6,16.1H293.8z"
              />
              <path
                fill="none"
                stroke="#020202"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M406.9,938.6c0,0-6.5,6.4,0,12h25.2l3.1-2.2
				v-26.2c0,0,0,13.1-7,16.6L406.9,938.6z"
              />
              <path
                fill="none"
                stroke="#221F1F"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M292.9,951.2v29.2c0,0-0.2,5-5.1,6.6h-20.7
				c0,0-4.9-1.1-4.9-6.9v-69.8l2.7,12.2l0.6,26.2l3.1,2.2L292.9,951.2z"
              />
              <path
                fill="none"
                stroke="#221F1F"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M407.6,950.6v29.8c0,0,0.2,5,5.1,6.6h20.7
				c0,0,4.9-1.1,4.9-6.9v-69.8l-2.7,12.2l-0.6,26.2l-3.1,2.2L407.6,950.6z"
              />
              <path
                fill="none"
                stroke="#221F1F"
                stroke-width="0.75"
                stroke-miterlimit="10"
                d="M407.6,951.8H293.4v6.9h45.5
				c0,0.2,0,0.4,0,0.6c0,3.9,5.5,7.1,12.3,7.1c6.8,0,12.3-3.2,12.3-7.1c0-0.2,0-0.4,0-0.6h44.1V951.8z"
              />
              <g>
                <ellipse
                  fill="none"
                  stroke="#000000"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  cx="441"
                  cy="857.5"
                  rx="11.7"
                  ry="8.3"
                />
                <ellipse
                  fill="none"
                  stroke="#000000"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                  cx="441"
                  cy="857.5"
                  rx="9.3"
                  ry="6.6"
                />
                <path
                  fill="none"
                  stroke="#020202"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                  d="M435.2,877.9c0,0,1.7-10.8,3-12.3
					c0,0-4.6-0.6-7.6-4.4l-1.4,1.6L435.2,877.9z"
                />
              </g>
              <g>
                <ellipse
                  fill="none"
                  stroke="#000000"
                  stroke-width="0.75"
                  stroke-miterlimit="10"
                  cx="260.2"
                  cy="857.8"
                  rx="11.7"
                  ry="8.3"
                />
                <ellipse
                  fill="none"
                  stroke="#000000"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                  cx="260.2"
                  cy="857.8"
                  rx="9.3"
                  ry="6.6"
                />
                <path
                  fill="none"
                  stroke="#020202"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                  d="M266,878.2c0,0-1.7-10.8-3-12.3
					c0,0,4.6-0.6,7.6-4.4l1.4,1.6L266,878.2z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  stroke="#000000"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                  d="M399,953.4h-16.1c-2.7,0-5-2.2-5-5v-4.2
					c0-2.7,2.2-5,5-5H399c2.7,0,5,2.2,5,5v4.2C403.9,951.1,401.7,953.4,399,953.4z"
                />
                <circle
                  fill="none"
                  stroke="#000000"
                  stroke-width="0.5"
                  stroke-miterlimit="10"
                  cx="391.1"
                  cy="946.3"
                  r="4.6"
                />
              </g>
            </g>
            <path
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M428.6,782.3H274.4c-1.3,0-2.4-1.1-2.4-2.4
			v-0.2c0-1.3,1.1-2.4,2.4-2.4h154.2c1.3,0,2.4,1.1,2.4,2.4v0.2C431,781.3,429.9,782.3,428.6,782.3z"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="275"
              y1="782.3"
              x2="275"
              y2="860"
            />
            <line
              fill="none"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              x1="427"
              y1="782.3"
              x2="427"
              y2="860"
            />
            <path
              fill="#FFFFFF"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M319.4,859.5h-21.9c-2.8,0-5.1-2.3-5.1-5.1
			v-35.9c0-2.8,2.3-5.1,5.1-5.1h21.9c2.8,0,5.1,2.3,5.1,5.1v35.9C324.5,857.2,322.2,859.5,319.4,859.5z"
            />
            <path
              fill="#FFFFFF"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M362.4,859.5h-21.9c-2.8,0-5.1-2.3-5.1-5.1
			v-35.9c0-2.8,2.3-5.1,5.1-5.1h21.9c2.8,0,5.1,2.3,5.1,5.1v35.9C367.5,857.2,365.2,859.5,362.4,859.5z"
            />
            <path
              fill="#FFFFFF"
              stroke="#231F20"
              stroke-width="0.75"
              stroke-miterlimit="10"
              d="M404.4,859.5h-21.9c-2.8,0-5.1-2.3-5.1-5.1
			v-35.9c0-2.8,2.3-5.1,5.1-5.1h21.9c2.8,0,5.1,2.3,5.1,5.1v35.9C409.5,857.2,407.2,859.5,404.4,859.5z"
            />
          </g>
        </g>
      </>
    )
  }
};
