import { RATE_TYPES } from "../../components/views/utils";
import { BookingStatus, BOOKING_SOURCE, IBookingState, IConfirmationDialogData, VehicleSelectionView } from "./types";

export const bookingInitialState: IBookingState = {
  booking: {
    pickupServiceLocation: {
      id: "",
      name: "",
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      }
    },
    dropoffServiceLocation: {
      id: "",
      name: "",
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      }
    },
    excess: 0,
    dateUpdated: "",
    insuranceRate: 0,
    customerType: "individual",
    discount: {
      amount: 0,
      appliedBy: "",
      appliedDate: "",
      isPercent: true
    },
    rateTypeName: RATE_TYPES.DAILY,
    rateTypeDuration: 1440,
    addonRequirements: [],
    createdBy: "",
    customer: {
      email: "",
      firstName: "",
      id: "",
      lastName: "",
      phoneNumber: {
        phone: "",
        country: ""
      },
      location: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      }
    },
    paymentDetails: {
      addonsAmount: 0,
      discountAmount: 0,
      endDate: "",
      insuranceAmount: 0,
      rentalAmount: 0,
      rentalTime: 0,
      startDate: "",
      taxAmount: 0,
      totalPayableAmount: 0
    },
    dateCreated: "",
    smartCarVehicle: false,
    flightNumber: "",
    id: "",
    longTermBooking: false,
    pickupDateTime: "",
    referenceNumber: "",
    status: BookingStatus.QUOTE,
    organisationId: "",
    vehicleGroups: [],
    invoices: [],
    businessCustomer: {
      id: "",
      businessName: "",
      registrationNumber: "",
      VAT: "",
      registeredAddress: {
        street: "",
        city: "",
        fullAddress: "",
        state: "",
        zipcode: "",
        country: ""
      },
      tradingAddressSame: true,
      tradingAddress: {
        street: "",
        city: "",
        fullAddress: "",
        state: "",
        zipcode: "",
        country: ""
      },
      approvedDrivers: [],
      authorizedSignatories: [],
      billing: {
        phoneNumber: {
          phone: "",
          country: ""
        },
        email: ""
      },
      contact: {
        firstName: "",
        lastName: "",
        phoneNumber: {
          phone: "",
          country: ""
        },
        email: ""
      },
      documents: []
    },
    approvedDrivers: [],
    currentBookingSchedules: [],
    previousBookingSchedules: [],
    preBookingQuestions: [],
    deliveryQuestions: [],
    locationSurcharges: [],
    taxExempted: false,
    isSubscription: false
  },
  vehicleSelectionView: VehicleSelectionView.CATEGORY,
};

export const defaultConfirmationDialogData: IConfirmationDialogData = {
  description: "",
  isOpen: false,
  title: "",
  onCancel: () => {
    return;
  },
  onConfirm: () => {
    return;
  }
};
