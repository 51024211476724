import React from "react"
export const VanLargePanelSvg = {
	interior: {
		width: 235.1,
		height: 636.8,
		svg: (<><g id="Layer_1">
			<g>
				<path fill="none" stroke="#010101" stroke-width="0.75" stroke-miterlimit="10" d="M89.7,10.1c-0.3,0.1-34,8.2-48.4,16.5
			  c-5.9,3.3-9.3,7.6-9.9,12.3c-0.6,4.5-3,30.7-3,31c-1.8,11.4-3.4,21.7-3.5,22.7c0,0.1,0,0.3-0.1,15c-1.3,1.1-2.2,1.8-2.7,2.2
			  c-1-0.6-3.9-2.2-7.1-0.2c-3.4,2.2-7.5,5.5-7.5,9.1c0,0.3,0.1,0.6,0.1,1c0.4,1.8,1,2.9,2,3.3c0.6,0.3,1.4,0.2,2.3-0.3l0.3-0.2
			  c1.3-0.7,4.9-2.7,12.6-8.6c0,11.4,0,31.1,0.1,56c-0.1-0.1-0.3-0.1-0.4-0.1h-2.1c-0.8,0-1.3,0.6-1.3,1.3v25.4
			  c0,0.7,0.6,1.3,1.3,1.3h2.1c0.1,0,0.3-0.1,0.4-0.1c0.1,65.4,0.3,153.1,0.4,224.7l-0.9,1.9c-1,2.2-1.7,4.7-1.8,7.2
			  c-0.8,12.8-1.1,25.8-1.1,38.6c0.1,11.9,0.4,23.8,1.1,35.7c0.1,2,0.5,3.8,1.2,5.7l1.7,4.5c0,2.3,0,4.5,0,6.6
			  c0.1,19.7,0.1,31.7,0.1,32.2c0.1,13.3,0.1,43,0.1,44.2v0.6c0,4-0.1,13.3,6.2,19.6c4.2,4.2,10.5,6.4,18.8,6.4h132.1
			  c8.3,0,14.7-2.2,18.9-6.4c6.2-6.2,6.2-15.6,6.1-19.5V599c0-0.2,0-1.5,0-3.7c0-8.9,0.1-29.8,0.1-40.5c0-1.5,0.1-19.2,0.1-36.3
			  c0-0.8,0-1.7,0-2.5l1.7-4.5c0.6-1.8,1.1-3.7,1.2-5.7c0.8-11.8,1.1-23.8,1.1-35.7c0-0.6,0-1.3,0-1.8c0-12.2-0.4-24.5-1.1-36.7
			  c-0.1-2.5-0.8-5-1.8-7.2l-0.8-1.8c0.1-53.2,0.2-121.3,0.3-181.9c0.1,0,0.3,0.1,0.4,0.1h2.1c0.7,0,1.3-0.6,1.3-1.3v-25.6
			  c0-0.7-0.6-1.3-1.3-1.3h-2c-0.1,0-0.3,0-0.3,0.1c0-5.1,0-10.1,0-15c0.1,0.1,0.3,0.1,0.4,0.1h2.1c0.7,0,1.3-0.6,1.3-1.3V171
			  c0-0.7-0.6-1.3-1.3-1.3h-2.1c-0.1,0-0.3,0-0.4,0.1c0-22.9,0.1-42.3,0.1-56c7.4,5.7,11,7.7,12.4,8.5c0.2,0.1,0.3,0.2,0.4,0.3
			  c0.9,0.5,1.7,0.6,2.4,0.3c0.9-0.4,1.5-1.5,1.9-3.3c0.1-0.3,0.1-0.6,0.1-1c0-3.5-4.1-6.9-7.6-9.1c-3.3-2-6.1-0.4-7.1,0.2
			  c-0.4-0.4-1.4-1.1-2.6-2.1c0-14.7,0-14.9-0.1-14.9c-0.1-1-1.7-11.3-3.5-22.6c0-0.3-2.5-26.5-3.1-31c-0.6-4.8-4-9-9.9-12.3
			  c-14.4-8.3-48.1-16.4-48.4-16.5c-2.2-0.6-15.9-2.1-24.4-2.1H114C105.5,8,91.8,9.5,89.7,10.1z M25.8,92.7c0.1-1,1.7-11.2,3.5-22.6
			  l0,0c0.4-2.8,0.9-5.7,1.4-8.5c0.6-4,1.3-7.8,1.8-11.2c0.4-2.6,0.8-5.1,1.1-7c0.4-2.9,0.8-4.9,1-5.6c0.9-3.3,4.8-6.3,8.2-8.4
			  c-2,2-3.4,4.5-3.6,7.4l-6.9,55.1c-0.8,1.5-5.9,11.3-5.3,14c-0.4,0.3-0.8,0.7-1.1,1C25.7,95.5,25.8,92.9,25.8,92.7z M88.3,13.4
			  c1.1-0.3,1.8-0.4,1.8-0.4c3.8-0.9,15.7-1.9,25.9-1.9h1.1c10.3,0,22.2,1,25.9,1.9c0.4,0.1,1.1,0.3,1.9,0.4
			  c-7.6-0.6-21.7-1.5-27.7-1.5h-1.3C110.1,11.9,95.8,12.9,88.3,13.4z M116,12.9h1.3c8.2,0,32.4,1.9,32.6,1.9v-0.1
			  c3.4,0.9,7.5,2.1,12,3.4c7.5,10.6,14.2,21.8,20,33.3c4.2,8.4,8,17.1,11.3,25.9c-31.5-12.7-58.3-15.4-75.2-15.4h-2.5
			  c-16.9,0-43.6,2.7-75.1,15.4c3.3-8.9,7.1-17.7,11.3-26.1c5.7-11.5,12.4-22.7,19.8-33.2c4.5-1.3,8.7-2.5,12-3.4v0.1
			  C83.6,14.8,107.8,12.9,116,12.9z M184.1,25.9L184.1,25.9c4,1.9,8.2,5.4,8.9,9.8v0.1c0.1,0.6,0.1,1.3,0.1,1.9c0,0.6,0,1.1,0,1.8
			  c-1.2-2.5-2.7-4.7-4.5-6.8c-1.3-1.5-2.8-2.7-6.9-5.5c-2.8-1.9-5.7-3.8-8.7-5.5C177.2,23.1,181.1,24.5,184.1,25.9z M168.7,20.3
			  c4.3,2.4,8.5,5,12.5,7.7c4,2.7,5.4,4,6.8,5.4c2.2,2.4,4,5.3,5.2,8.5c0,8.8,0.1,23.4,0.3,33.8c-3.2-8.4-6.8-16.6-10.8-24.6
			  c-5.6-11.2-12.1-22.2-19.3-32.5C165.1,19.1,166.9,19.7,168.7,20.3z M207.4,213.5c0,0.1-0.1,0.2-0.1,0.3v25.4c0,0.1,0,0.1,0,0.2
			  c-0.1,26.6-0.1,54.6-0.1,82.3l-13.6-0.1c0-65.3,0-101.7,0.1-108.6h13.8C207.4,213.3,207.4,213.4,207.4,213.5z M205.2,599.3
			  c0,3.9,0,11.8-5.6,17.4c-1.7,1.7-3.7,3-6.1,4c-0.2-2.2-1.1-4.1-2.6-5.6c-1.2-1.1-2.8-2-4.7-2.4c4.7-1.6,7.1-6,7.5-13.4
			  L205.2,599.3z M96.8,622.7c-12.9,0-29.5,0.1-44.8,0.1c-4.3,0-8-0.6-11.2-1.7c6.2,0,50.4-0.1,73-0.1h5.5c22.6,0,66.6,0.1,72.9,0.1
			  c-3.1,1.1-6.8,1.7-11.1,1.7c-15.3,0-31.9,0-44.8-0.1c-8.2,0-14.9,0-18.9,0h-1.9C111.7,622.7,105,622.7,96.8,622.7z M39.6,599.3
			  c0.3,7.4,2.7,11.9,7.5,13.4c-1.9,0.4-3.6,1.2-4.8,2.4c-1.5,1.5-2.4,3.4-2.5,5.5c-2.4-1-4.5-2.3-6.2-4c-5.5-5.6-5.6-13.5-5.6-17.3
			  H39.6z M182.4,612.4c-0.3,0-0.4,0-0.7,0h-130c-0.4,0-0.9,0-1.3-0.1c4.5-2.4,7.5-4.2,9.3-5.7h114.1c1.8,1.5,4.7,3.3,9.2,5.7
			  C182.7,612.4,182.5,612.4,182.4,612.4L182.4,612.4z M62,209.6c0,0.2,0,1,0,2.4l-21.5,0.1c-0.1-14.4,1.9-46.4,2.7-58.5
			  c0.1-2.2,0.3-3.6,0.3-4.1c0.1-1.4,0.4-2.3,0.8-2.8C46.4,153.7,61.3,203,62,209.6z M39.6,213.1c0.1,6.8,0.1,43.2,0.1,108.7
			  L26,321.8c-0.1-37.9-0.1-75.5-0.2-108.7L39.6,213.1z M40.5,213.1L62,213c0,12,0,55.2-0.1,108.7l-21.4,0.1
			  C40.6,256.3,40.6,219.9,40.5,213.1z M61.9,322.6c-0.1,104.7-0.1,248.4-0.1,275.7l-21.4,0.1c0-0.1,0-0.3,0-0.4c0-1.3,0-8.7,0-20.7
			  c0.1-109.2,0.1-194.2,0.1-254.6L61.9,322.6z M61.9,599.2c0,1.9,0,3.1,0,3.5c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0
			  c0,0.1-0.1,2.7-13.3,9.4c-5.2-1.1-7.8-5.2-8.1-12.8L61.9,599.2z M184.6,612.2c-13.1-6.8-13.2-9.4-13.2-9.3c0-0.1,0-0.1,0-0.1
			  c0-0.4,0-1.6,0-3.5l21.3,0.1C192.4,606.9,189.8,611.1,184.6,612.2z M171.4,598.3c0-27.3-0.1-170.9-0.1-275.7l21.4,0.1
			  c0,60.4,0,145.4,0.1,254.6c0,11.9,0,19.3,0,20.7c0,0.1,0,0.3,0,0.4L171.4,598.3z M171.3,321.7c0-53.4-0.1-96.5-0.1-108.6l21.5,0.1
			  c-0.1,6.8-0.1,41.3-0.1,103.4c0,1.7,0,3.5,0,5.2L171.3,321.7z M171.3,212.1c0-1.4,0-2.2,0-2.4c0.7-6.7,15.6-56.2,17.7-63
			  c0.3,0.5,0.8,1.4,0.8,2.9c0,0.4,0.1,1.9,0.3,4.1c0.8,11.9,2.7,42.8,2.7,57.6c0,0.3,0,0.6,0,0.9L171.3,212.1z M118.3,131.4h-3.5
			  c-37.7,0-65.5,11.6-70,13.6l-5.1-56.7c33.8-13.8,61.8-15.9,75.5-15.9h2.7c13.7,0,41.7,2,75.5,15.9l-5.2,56.7
			  C183.9,143,156.1,131.4,118.3,131.4z M118,71.4h-2.7c-13.7,0-41.8,2-75.5,15.8c0-1.3,0.1-4.5,0.1-8.6C71.4,65.7,98.3,63,115.2,63
			  h2.5c17,0,43.9,2.7,75.6,15.7c0.1,4.1,0.1,7.3,0.1,8.6C159.7,73.5,131.7,71.4,118,71.4z M50.6,51c-4,8-7.6,16.3-10.8,24.7
			  c0.1-10.2,0.3-24.4,0.3-33.3c1.3-3.4,3.1-6.5,5.4-9.1c1.3-1.5,2.7-2.7,6.8-5.4c4-2.7,8.2-5.4,12.6-7.8c1.7-0.5,3.4-1.1,5-1.5
			  C62.8,28.9,56.3,39.8,50.6,51z M51.7,27.1c-4.1,2.8-5.5,4.1-6.9,5.5c-1.9,2.1-3.5,4.5-4.7,7.2c0-0.8,0-1.6,0-2.3c0-0.3,0-0.6,0-1
			  l0.1-1.1c0.8-4.4,5-7.8,8.9-9.6l-0.1-0.1c3.2-1.4,7.1-2.9,11.3-4.3C57.5,23.4,54.5,25.2,51.7,27.1z M30.8,108.9
			  c0.2-0.2,0.4-0.3,0.6-0.5c0.1,0,0.8-0.7,0.8-1.9l0,0c0-0.6-0.2-1.2-0.6-1.9c-0.3-0.4-0.7-0.6-1.2-0.6c-0.8-0.1-1.7,0.2-2.3,0.7
			  c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.8,0.4-2,0.9-3.4l7.3,2.7l0.8,8.5l-4.1-2.7c-0.1,0-0.1,0-0.1-0.1l-0.1-0.1c-0.5-0.3-1-0.6-1.3-0.9
			  C31,109,30.9,108.9,30.8,108.9z M33,92.2l1.6-1.1l1.2,12l-6.8-2.5C30.1,97.9,31.7,94.6,33,92.2z M36.9,114L36.9,114L36.9,114
			  C36.9,114.1,36.9,114.1,36.9,114L36.9,114L36.9,114v0.1l0,0l0,0v0.1l0,0l0,0l0,0c0,0,0,0,0,0.1l0,0l0,0l0,0l0,0
			  c0,0.1,0,0.3,0.1,0.4l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0,0l0,0l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0l0,0c0,0,0,0,0,0.1l0,0
			  c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1v0.1c0,0,0,0,0,0.1v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1
			  c0,0,0,0,0,0.1l0,0l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0
			  c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0,0l0,0l0,0c0,0,0,0,0,0.1v0.1l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0,0l0,0c0,0.1,0,0.1,0,0.1
			  l0,0c1.3,15,2.6,31.9,2.5,33.9l0,0l0,0c-0.1,2.7-2,37.7-2.2,42.5c-1.3-0.2-4.5-0.8-6.9-1.3c-2-0.5-2.1-2.2-2-4.9
			  c0-0.4,0-0.8,0-1.1c0-1.7,3-52.5,4.3-74.4L36.9,114C36.9,114,36.9,114,36.9,114z M30.1,109.6c0.1,0.1,0.3,0.2,0.5,0.3
			  c0.1,0.1,0.3,0.2,0.4,0.3c-1.8,27.9-4.8,74.7-4.8,76.4c0,0.4,0,0.8-0.1,1.2c-0.1,2.5-0.2,5.7,3.4,6.5c3.9,0.8,9.7,1.7,9.8,1.7
			  c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.4,2.3-44.9,2.5-48.1c0-0.1,0-0.3,0-0.4c0-5.5-3.6-46.9-5-57.9
			  c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.4,0.1l-1.8,1.2c0,0,0,0-0.1,0l-1.5,1L39,44.3C39,59.8,38.7,87.6,38.7,88l0,0
			  c0,0,0,0,0,0.1l5.2,57.5c-0.4,0.4-1.3,1.5-1.4,3.9c0,0.4-0.1,1.9-0.3,4.1c-0.8,12.1-2.8,44.2-2.7,58.6l-13.7,0.1
			  c-0.1-45.3-0.1-82.1-0.1-99.2C27.1,112,28.5,110.8,30.1,109.6z M24.6,511c-0.6-1.7-1-3.5-1.1-5.4c-0.8-11.8-1.1-23.8-1.1-35.6
			  c-0.1-12.8,0.3-25.8,1.1-38.6c0.1-2.4,0.8-4.8,1.7-6.8l0.1-0.1c0.1,35.1,0.1,66.1,0.1,88.6L24.6,511z M26.4,554.7
			  c0-0.5,0-12.5-0.1-32.2c-0.1-40.9-0.2-120.6-0.3-199.7l13.6-0.1c0,60.4,0,145.4-0.1,254.6c0,11.9,0,19.3,0,20.7c0,0.1,0,0.3,0,0.4
			  l-13.1,0.1C26.5,594.4,26.5,567.2,26.4,554.7z M206.8,554.7c0,10.7-0.1,31.6-0.1,40.5c0,1.5,0,2.6,0,3.3l-13-0.1
			  c0-0.1,0-0.3,0-0.4c0-1.3,0-8.7,0-20.7c-0.1-109.2-0.1-194.2-0.1-254.6l13.6,0.1c-0.1,35.3-0.1,69.9-0.2,99.7H207l0.1,0.1
			  c-0.1,23-0.1,43.3-0.1,58.8c0,1.5-0.1,19.6-0.1,36.9C206.8,535.5,206.8,553.2,206.8,554.7z M209.8,431.5
			  c0.8,12.7,1.1,25.8,1.1,38.6c-0.1,11.9-0.4,23.8-1.1,35.6c-0.1,1.8-0.5,3.7-1.1,5.4l-0.8,2.1c0.1-15.6,0.1-30.2,0.1-31.6
			  c0.1-15.1,0.1-34.6,0.1-56.9C209.1,426.7,209.6,429.1,209.8,431.5z M208.7,213.5h2.1c0.2,0,0.4,0.2,0.4,0.3v25.4
			  c0,0.1-0.2,0.3-0.4,0.3h-2.1c-0.2,0-0.3-0.1-0.4-0.2c0-8.7,0-17.3,0-25.7C208.4,213.6,208.6,213.5,208.7,213.5z M207.4,212.2
			  h-13.8c0-0.3,0-0.6,0-0.9c0-14.9-2-45.8-2.7-57.7c-0.1-2.2-0.3-3.6-0.3-4.1c-0.1-2.4-1-3.5-1.5-4l5.2-57.5c0,0,0,0,0-0.1l0,0l0,0
			  c0-0.3-0.3-28.3-0.4-43.7l5.9,46.7l-1.5-1c0,0,0,0-0.1,0l-1.8-1.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0.1-0.3,0.2-0.3,0.4
			  c-1.4,11.4-5.2,54.9-5,58.3c0.1,3.2,2.5,47.7,2.5,48.1c0,0.1,0.1,0.3,0.2,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,5.9-0.8,9.7-1.7
			  c3.1-0.6,3.6-2.9,3.6-5.2c0-0.4,0-0.9-0.1-1.3c0-0.4,0-0.8,0-1.2c0-1.7-3.1-48.5-4.8-76.4c0.1-0.1,0.2-0.1,0.3-0.2
			  c0.2-0.1,0.4-0.3,0.6-0.4c1.6,1.3,3,2.4,4.3,3.4C207.5,134.4,207.5,170.4,207.4,212.2z M202.8,103.8c-0.5,0.1-1,0.3-1.2,0.7
			  c-0.4,0.6-0.6,1.3-0.6,1.9c0,1.1,0.7,1.8,0.8,1.8c0.3,0.2,0.4,0.4,0.7,0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.3-0.8,0.5-1.2,0.8
			  l-0.1,0.1c-0.1,0-0.2,0-0.3,0.1l-4.1,2.7l0.9-8.4l7.3-2.7c0.5,1.5,0.9,2.7,0.9,3.4c-0.2-0.1-0.3-0.3-0.5-0.4
			  C204.5,104.1,203.5,103.8,202.8,103.8z M196.3,114l4.1-2.6c1.5,23.4,4.4,72.8,4.4,74.4c0,0.4,0,0.8,0,1.1c0.1,2.7,0,4.4-2,4.9
			  c-2.4,0.6-5.7,1.1-7,1.3c-0.3-4.8-2-39.8-2.2-42.5C193.5,148.7,194.9,130.2,196.3,114z M204.2,100.7l-6.9,2.5l1.3-12l1.6,1.1
			  C201.5,94.6,203.1,97.9,204.2,100.7z M207.5,92.7c0,0.3,0,5,0,14c-0.4-0.3-0.8-0.6-1.1-1c0.1-0.2,0.1-0.4,0.1-0.6
			  c0-3.3-4.6-12-5.4-13.4l-6.9-55.1c0-0.4-0.1-0.8-0.1-1.1v-0.1l0,0c-0.4-2.3-1.7-4.4-3.4-6.1c3.4,2.1,7.3,5.2,8.2,8.4
			  c0.2,0.7,0.5,2.7,1,5.6c0.3,2,0.7,4.4,1.1,7c0.6,3.4,1.1,7.3,1.8,11.2c0.4,2.9,0.9,5.7,1.4,8.5l0,0
			  C205.8,81.5,207.4,91.7,207.5,92.7z"/>
				<path fill="none" stroke="#010101" stroke-width="0.75" stroke-miterlimit="10" d="M82.8,59.2L81.8,61c-0.5,0.8-0.3,1.9,0.4,2.5
			  c0.4,0.4,1,0.5,1.6,0.4c10.6-1.9,21.3-2.9,31.6-2.9h2.5c10.5,0,20.8,1,31.6,2.9c0.6,0.1,1.1-0.1,1.6-0.4s0.7-0.9,0.7-1.5
			  c0-0.3-0.1-0.7-0.3-1l-1-1.8c-0.6-1.1-1.8-1.9-3.1-2.1c-10.3-1.7-20.8-2.5-31.2-2.5c-10.1,0-20.3,0.9-30.2,2.5
			  C84.6,57.4,83.5,58.1,82.8,59.2z"/>
			</g>
		</g>
			<g id="Layer_2">
				<g>
					<path fill="#FFFFFF" stroke="#010101" stroke-miterlimit="10" d="M75.2,145.3h29c0,0,4.2,0.7,5.9,7.3l0.3,16.4
			  c0,0-2.2,9.4-8.1,12.3H76.2c0,0-5.3-3.6-7.6-12.2v-16.8C68.6,152.4,70.7,146.1,75.2,145.3z"/>
					<path fill="none" stroke="#010101" stroke-miterlimit="10" d="M111,178.6l-0.6-1h-3.6l-2.4,2.4l-2.9,1.3l-12.1-0.1v0L77,181.5
			  l-2.6-1.8l-1.9-2.3l-4.1,0.2l-1.1,1.8l-0.6,4.8v14.6l0.5,3.8l1.5,2.6l1.9,1.6l2.9,1.3h4.1h1.8l0.1,1.1h10v0h10l0.1-1.1h1.8h4.1
			  l2.9-1.3l1.9-1.6l1.5-2.6l0.5-3.8v-14.6l-0.5-3.8C111.6,179.7,111.4,179.1,111,178.6z"/>
					<polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="89.3,198.2 76.9,198.2 76.9,207.8 79.3,210 89.3,210 
			  99.4,210 101.8,207.8 101.8,198.2 		"/>
				</g>
				<g>
					<path fill="#FFFFFF" stroke="#010101" stroke-miterlimit="10" d="M132.6,145.7h29c0,0,4.2,0.7,5.9,7.3l0.3,16.4
			  c0,0-2.2,9.4-8.1,12.3h-26.1c0,0-5.3-3.6-7.6-12.2v-16.8C125.9,152.7,128.1,146.5,132.6,145.7z"/>
					<path fill="none" stroke="#010101" stroke-miterlimit="10" d="M168.3,179l-0.6-1h-3.6l-2.4,2.4l-2.9,1.3l-12.1-0.1v0l-12.4,0.3
			  l-2.6-1.8l-1.9-2.3l-4.1,0.2l-1.1,1.8l-0.6,4.8v14.6l0.5,3.8l1.5,2.6l1.9,1.6l2.9,1.3h4.1h1.8l0.1,1.1h10v0h10l0.1-1.1h1.8h4.1
			  l2.9-1.3l1.9-1.6l1.5-2.6l0.5-3.8v-14.6l-0.5-3.8C168.9,180.1,168.7,179.5,168.3,179z"/>
					<polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="146.7,198.5 134.2,198.5 134.2,208.2 136.6,210.4 
			  146.7,210.4 156.8,210.4 159.1,208.2 159.1,198.5 		"/>
				</g>
			</g></>)
	},
	exterior: {
		width: 1260,
		height: 1879,
		svg: (<><g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M573.2,430.4c-0.5,0.1-53.3,12.9-76,25.9
		  c-9.2,5.2-14.6,11.9-15.5,19.3c-0.9,7-4.7,48.2-4.7,48.6c-2.9,17.9-5.4,34.1-5.5,35.6c0,0.1,0,0.5-0.1,23.5c-2,1.7-3.5,2.9-4.2,3.5
		  c-1.5-1-6.1-3.4-11.2-0.3c-5.4,3.4-11.8,8.6-11.8,14.2c0,0.5,0.1,1,0.2,1.5c0.6,2.9,1.6,4.5,3.1,5.2c1,0.5,2.2,0.3,3.6-0.5l0.5-0.3
		  c2.1-1.1,7.7-4.2,19.8-13.5c0,17.9,0,48.8,0.1,87.8c-0.2-0.1-0.4-0.1-0.7-0.1h-3.3c-1.2,0-2.1,0.9-2.1,2v39.9c0,1.1,0.9,2,2.1,2
		  h3.3c0.2,0,0.5-0.1,0.7-0.1c0.1,102.6,0.4,240.2,0.6,352.5l-1.4,3c-1.6,3.4-2.6,7.3-2.9,11.3c-1.2,20.1-1.8,40.4-1.8,60.6
		  c0.1,18.6,0.7,37.4,1.8,56c0.2,3.1,0.8,6,1.9,8.9l2.6,7.1c0,3.6,0,7,0,10.3c0.1,30.9,0.1,49.7,0.1,50.5c0.1,20.9,0.1,67.4,0.1,69.4
		  l0,0.9c0,6.2-0.1,20.8,9.7,30.7c6.6,6.6,16.5,10,29.5,10h207.2c13,0,23-3.4,29.6-10c9.8-9.8,9.7-24.4,9.6-30.6l0-1
		  c0-0.3,0-2.4,0-5.8c0-14,0.1-46.7,0.1-63.6c0-2.4,0.1-30.1,0.2-56.9c0-1.3,0-2.6,0-3.9l2.6-7.1c1-2.8,1.7-5.8,1.9-8.9
		  c1.2-18.5,1.8-37.4,1.8-56c0-1,0-2,0-2.9c0-19.2-0.6-38.5-1.8-57.6c-0.2-3.9-1.2-7.8-2.9-11.3l-1.3-2.8
		  c0.2-83.5,0.3-190.3,0.5-285.3c0.2,0,0.4,0.1,0.6,0.1h3.3c1.1,0,2.1-0.9,2.1-2V750c0-1.1-1-2-2.1-2H760c-0.2,0-0.4,0-0.5,0.1
		  c0-8,0-15.9,0-23.6c0.2,0.1,0.4,0.1,0.7,0.1h3.3c1.1,0,2.1-0.9,2.1-2v-39.9c0-1.1-1-2-2.1-2h-3.3c-0.2,0-0.4,0-0.6,0.1
		  c0-36,0.1-66.3,0.1-87.8c11.6,9,17.3,12.1,19.4,13.3c0.3,0.2,0.5,0.3,0.7,0.4c1.4,0.8,2.6,1,3.7,0.5c1.4-0.6,2.4-2.3,3-5.1
		  c0.1-0.5,0.2-1,0.2-1.5c0-5.5-6.5-10.8-11.9-14.2c-5.1-3.2-9.6-0.7-11.1,0.3c-0.7-0.6-2.2-1.8-4.1-3.3c0-23,0-23.3-0.1-23.4
		  c-0.1-1.5-2.6-17.7-5.5-35.5c0-0.4-4-41.6-4.9-48.6c-1-7.6-6.2-14.1-15.5-19.3c-22.6-13-75.4-25.8-75.9-25.9
		  c-3.4-0.9-24.9-3.3-38.2-3.3h-8.2C598.1,427.1,576.6,429.5,573.2,430.4z M467.6,723c-0.3,0-0.6-0.2-0.6-0.5v-39.9
		  c0-0.3,0.3-0.5,0.6-0.5h3.3c0.3,0,0.6,0.3,0.6,0.5v39.9c0,0.2-0.3,0.5-0.6,0.5H467.6z M763.4,682.1c0.3,0,0.6,0.3,0.6,0.5v39.9
		  c0,0.2-0.3,0.5-0.6,0.5h-3.3c-0.4,0-0.6-0.2-0.6-0.5v-39.9c0-0.3,0.3-0.5,0.6-0.5H763.4z M473,559.9c0.1-1.5,2.6-17.6,5.5-35.5l0,0
		  c0.7-4.4,1.4-9,2.2-13.4c1-6.2,2-12.2,2.8-17.6c0.7-4.1,1.3-8,1.7-11c0.7-4.5,1.2-7.7,1.5-8.8c1.4-5.1,7.6-9.9,12.8-13.1
		  c-3.1,3.2-5.4,7.1-5.6,11.6L483,558.5c-1.2,2.3-9.3,17.7-8.3,21.9c-0.6,0.5-1.2,1.1-1.8,1.5C472.9,564.3,473,560.2,473,559.9z
		   M571,435.6c1.8-0.5,2.8-0.7,2.9-0.7c5.9-1.4,24.6-3,40.7-3h1.7c16.2,0,34.8,1.6,40.7,3c0.7,0.2,1.7,0.4,3,0.7
		  c-11.9-0.9-34.1-2.4-43.4-2.4h-2C605.2,433.2,582.9,434.8,571,435.6z M614.5,434.7h2c12.9,0,50.8,3,51.2,3l0-0.1
		  c5.3,1.4,11.8,3.3,18.8,5.3c11.7,16.6,22.2,34.2,31.3,52.3c6.6,13.2,12.5,26.9,17.7,40.7c-49.4-20-91.4-24.2-117.9-24.2h-3.9
		  c-26.5,0-68.4,4.2-117.8,24.2c5.1-13.9,11.1-27.7,17.7-40.9c9-18.1,19.5-35.6,31.1-52.1c7-2.1,13.7-3.9,18.8-5.3l0,0.1
		  C563.7,437.7,601.6,434.7,614.5,434.7z M721.4,455.2l0,0.1c6.3,2.9,12.8,8.3,14,15.3l0,0.1c0.2,1,0.2,2,0.2,3c0,0.9,0,1.8,0,2.9
		  c-1.9-3.9-4.2-7.4-7.1-10.6c-2.1-2.3-4.4-4.3-10.8-8.7c-4.4-3-8.9-5.9-13.6-8.6C710.5,450.8,716.6,453,721.4,455.2z M697.2,446.3
		  c6.8,3.8,13.4,7.9,19.6,12.1c6.3,4.3,8.5,6.2,10.6,8.5c3.5,3.8,6.2,8.3,8.2,13.4c0,13.8,0.2,36.7,0.4,53
		  c-5-13.1-10.6-26.1-16.9-38.6c-8.8-17.6-19-34.8-30.3-51C691.5,444.5,694.4,445.4,697.2,446.3z M727.7,643.5
		  c-2.5,8.4-25.9,85.6-27.9,99.1c-3.2-0.1-17.3-0.4-35.5-0.6c-0.7,0-1.3,0-1.9,0c-17.6-0.3-17.6-0.3-45.3-0.3h-3.2
		  c-27.8,0-27.8,0-45.3,0.3c-0.6,0-1.2,0-1.8,0c-18.2,0.3-32.4,0.6-35.6,0.6c-2-13.2-25.5-90.7-28.1-99.1
		  c5.5-2.5,49.5-21.3,109.5-21.3h5.5C678.3,622.2,722.2,641,727.7,643.5z M757.9,749.4c0,0.2-0.1,0.3-0.1,0.5v39.9c0,0.1,0,0.2,0,0.3
		  c-0.1,41.7-0.1,85.7-0.2,129.1l-21.4-0.1c0-102.5,0-159.6,0.1-170.4l21.6,0C757.9,749.1,757.9,749.3,757.9,749.4z M754.4,1354.7
		  c0,6.1,0,18.5-8.8,27.3c-2.6,2.6-5.8,4.7-9.5,6.2c-0.3-3.4-1.7-6.5-4.1-8.8c-1.9-1.8-4.4-3.1-7.4-3.8c7.4-2.5,11.2-9.4,11.7-21
		  L754.4,1354.7z M584.4,1391.4c-20.2,0-46.2,0.1-70.2,0.1c-6.7,0-12.5-0.9-17.5-2.6c9.8,0,79-0.1,114.5-0.1h8.6
		  c35.4,0,104.4,0.1,114.4,0.1c-4.9,1.7-10.7,2.6-17.4,2.6c-24,0-50,0-70.2-0.1c-12.9,0-23.4,0-29.6,0H614
		  C607.8,1391.4,597.3,1391.4,584.4,1391.4z M494.7,1354.7c0.4,11.6,4.3,18.6,11.8,21c-3,0.6-5.6,1.9-7.6,3.8c-2.3,2.3-3.8,5.4-4,8.7
		  c-3.8-1.5-7.1-3.6-9.7-6.3c-8.7-8.8-8.8-21.1-8.8-27.2L494.7,1354.7z M617.1,743.1c16,0,32.8,0.2,47.2,0.4
		  c7.6,0.1,18.4,0.3,35.4,0.6c0,1.1,0,3.3,0,6.6c-18.4-0.3-29.6-0.5-37.3-0.7c-17.6-0.3-17.6-0.3-45.3-0.3h-3.2
		  c-27.8,0-27.8,0-45.3,0.3c-7.7,0.1-18.9,0.4-37.3,0.7c0-3.3,0-5.5,0-6.6c17.1-0.3,27.9-0.5,35.6-0.6c14.3-0.2,31.1-0.4,47-0.4
		  H617.1z M699.9,1359.9c-0.1,0.6,0.2,2.1,3.3,4.8H527.8c3.1-2.8,3.4-4.3,3.3-4.8c0-14.2,0.2-532.8,0.2-607.7
		  c18.4-0.3,29.6-0.5,37.3-0.7c17.5-0.3,17.5-0.3,45.3-0.3h3.2c27.8,0,27.8,0,45.3,0.3c7.7,0.1,18.9,0.4,37.3,0.7
		  C699.7,826.8,699.9,1345.6,699.9,1359.9z M718.6,1375.2c-0.4,0-0.7,0-1.1,0h-204c-0.7,0-1.4,0-2.1-0.1c7.1-3.7,11.7-6.6,14.6-8.9
		  h179c2.9,2.3,7.4,5.2,14.5,8.9C719.2,1375.1,718.9,1375.1,718.6,1375.2L718.6,1375.2z M529.8,743.4c0,0.3,0,1.5,0,3.8l-33.7,0.1
		  c-0.1-22.6,3-72.8,4.2-91.8c0.2-3.4,0.4-5.7,0.4-6.5c0.1-2.2,0.7-3.6,1.2-4.4C505.3,655.6,528.7,733,529.8,743.4z M494.6,748.8
		  c0.1,10.7,0.1,67.8,0.1,170.5l-21.3,0.1c-0.1-59.4-0.2-118.5-0.3-170.5L494.6,748.8z M496.1,748.8l33.7-0.1
		  c0,18.9,0,86.6-0.1,170.5l-33.5,0.1C496.2,816.6,496.2,759.5,496.1,748.8z M529.7,920.6c-0.1,164.3-0.1,389.6-0.2,432.4l-33.5,0.1
		  c0-0.2,0-0.4,0-0.6c0-2.1,0-13.7,0-32.4c0.1-171.3,0.1-304.6,0.1-399.4L529.7,920.6z M529.6,1354.5c0,3,0,4.8,0,5.5c0,0,0,0,0,0.1
		  c0,0,0,0,0,0.1v0c0,0.1-0.2,4.2-20.8,14.7c-8.2-1.7-12.2-8.2-12.7-20.1L529.6,1354.5z M722.1,1374.8
		  c-20.6-10.6-20.7-14.7-20.7-14.6c0-0.1,0-0.1,0-0.2c0-0.6,0-2.5,0-5.5l33.4,0.1C734.4,1366.6,730.3,1373.2,722.1,1374.8z
		   M701.4,1353c0-42.8-0.1-268.1-0.2-432.4l33.5,0.1c0,94.8,0,228.1,0.1,399.4c0,18.6,0,30.2,0,32.4c0,0.2,0,0.4,0,0.6L701.4,1353z
		   M701.3,919.1c0-83.8-0.1-151.4-0.1-170.4l33.7,0.1c-0.1,10.6-0.1,64.8-0.1,162.2c0,2.7,0,5.5,0,8.2L701.3,919.1z M701.2,747.3
		  c0-2.2,0-3.5,0-3.8c1.1-10.5,24.5-88.2,27.8-98.9c0.5,0.8,1.2,2.2,1.3,4.5c0,0.7,0.2,3,0.4,6.5c1.2,18.6,4.2,67.2,4.2,90.4
		  c0,0.5,0,1,0,1.4L701.2,747.3z M618.2,620.7h-5.5c-59.1,0-102.8,18.2-109.8,21.3l-8-89c53-21.6,96.9-24.9,118.4-24.9h4.3
		  c21.5,0,65.4,3.2,118.4,24.9l-8.1,89C721,638.9,677.4,620.7,618.2,620.7z M617.6,526.6h-4.3c-21.5,0-65.5,3.2-118.5,24.8
		  c0-2.1,0.1-7,0.1-13.5c49.7-20.3,91.8-24.6,118.4-24.6h3.9c26.7,0,68.8,4.3,118.6,24.6c0.1,6.4,0.1,11.4,0.1,13.5
		  C683.1,529.8,639.2,526.6,617.6,526.6z M512,494.5c-6.3,12.6-12,25.6-17,38.8c0.2-16,0.4-38.3,0.4-52.2c2-5.4,4.8-10.2,8.5-14.3
		  c2.1-2.3,4.3-4.2,10.6-8.5c6.3-4.3,12.9-8.4,19.8-12.2c2.6-0.8,5.3-1.7,7.9-2.4C531,459.9,520.8,476.9,512,494.5z M513.7,457.1
		  c-6.4,4.4-8.7,6.4-10.8,8.7c-3,3.3-5.5,7.1-7.4,11.3c0-1.3,0-2.5,0-3.6c0-0.5,0-1,0-1.5l0.2-1.7c1.3-6.9,7.8-12.2,14-15.1l-0.1-0.1
		  c5-2.2,11.2-4.5,17.8-6.8C522.7,451.2,518.1,454.1,513.7,457.1z M480.9,585.4c0.3-0.3,0.6-0.5,0.9-0.8c0.1,0,1.2-1.1,1.2-3
		  c0,0,0,0,0,0c0-0.9-0.3-1.9-0.9-3c-0.4-0.6-1.1-1-1.9-1c-1.2-0.1-2.7,0.3-3.6,1.1c-0.2,0.2-0.4,0.3-0.6,0.5c0-1.2,0.6-3.1,1.4-5.4
		  l11.4,4.3l1.3,13.3l-6.5-4.2c-0.1,0-0.1,0-0.2-0.1l-0.2-0.1c-0.8-0.5-1.5-1-2.1-1.4C481.2,585.5,481.1,585.4,480.9,585.4z
		   M484.3,559.2l2.5-1.7l1.9,18.9l-10.7-4C479.8,568.1,482.3,563,484.3,559.2z M490.5,593.4C490.5,593.4,490.5,593.4,490.5,593.4
		  C490.5,593.4,490.5,593.4,490.5,593.4c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0
		  c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0.2,0,0.5,0.1,0.7
		  c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
		  c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1
		  c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0
		  c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
		  c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
		  c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c2.1,23.6,4.1,50,3.9,53.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,4.3-3.1,59.2-3.5,66.7
		  c-2-0.3-7.1-1.2-10.9-2.1c-3.2-0.8-3.3-3.5-3.2-7.7c0-0.6,0-1.2,0-1.8c0-2.6,4.7-82.3,6.8-116.7L490.5,593.4
		  C490.5,593.3,490.5,593.4,490.5,593.4z M477.7,579.8c0.6-0.5,1.6-0.9,2.4-0.8c0.4,0,0.7,0.2,0.8,0.4c0.4,0.8,0.6,1.5,0.6,2.1
		  c0,0,0,0,0,0c0,1.2-0.7,1.9-0.7,1.9c-3.8,3.1-7.1,5.8-10.1,8.1c-1-2.2-1.7-3.4-2.2-4C470.1,586.3,473.7,583.4,477.7,579.8z
		   M450.3,605.5c-0.9,0.5-1.6,0.7-2.2,0.4c-0.9-0.4-1.7-1.8-2.2-4.1c-0.1-0.4-0.1-0.8-0.1-1.2c0-5.2,6.9-10.3,11.1-12.9
		  c5.2-3.3,9.8,0.4,10,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.3l1.3,2.2c0.2,0.5,0.5,1,0.8,1.6
		  c-11.3,8.7-16.6,11.6-18.7,12.7L450.3,605.5z M479.7,586.4c0.2,0.2,0.5,0.3,0.8,0.5c0.2,0.1,0.4,0.3,0.6,0.4
		  c-2.9,43.8-7.6,117.2-7.6,119.9c0,0.6,0,1.3-0.1,1.9c-0.2,4-0.3,9,5.4,10.2c6.1,1.3,15.2,2.6,15.3,2.6c0.2,0,0.4,0,0.6-0.2
		  c0.2-0.1,0.3-0.3,0.3-0.5c0-0.7,3.6-70.4,3.9-75.5c0-0.2,0-0.4,0-0.6c0-8.7-5.7-73.5-7.8-90.9c0-0.3-0.2-0.5-0.4-0.6
		  c-0.2-0.1-0.5-0.1-0.7,0.1l-2.9,1.9c0,0,0,0-0.1,0l-2.4,1.6l9.2-73.1c-0.1,24.2-0.6,67.9-0.6,68.4l0,0c0,0,0,0,0,0.1l8.1,90.2
		  c-0.7,0.7-2,2.4-2.2,6.1c0,0.7-0.2,3-0.4,6.4c-1.2,19-4.4,69.3-4.2,91.9l-21.5,0.1c-0.1-71.1-0.2-128.8-0.2-155.6
		  C475,590.2,477.3,588.4,479.7,586.4z M471.2,1216.1c-1-2.7-1.6-5.5-1.8-8.4c-1.2-18.5-1.8-37.3-1.8-55.9
		  c-0.1-20.1,0.5-40.4,1.8-60.5c0.2-3.8,1.2-7.5,2.7-10.7l0.1-0.2c0.1,55,0.2,103.7,0.2,139L471.2,1216.1z M474,1284.6
		  c0-0.8,0-19.6-0.1-50.5c-0.1-64.1-0.3-189.2-0.5-313.3l21.3-0.1c0,94.8,0,228.1-0.1,399.4c0,18.6,0,30.2,0,32.4c0,0.2,0,0.4,0,0.6
		  l-20.5,0.1C474.1,1346.9,474.1,1304.3,474,1284.6z M756.9,1284.6c0,16.8-0.1,49.6-0.1,63.6c0,2.4,0,4.1,0,5.1l-20.4-0.1
		  c0-0.2,0-0.4,0-0.6c0-2.1,0-13.7,0-32.4c-0.1-171.3-0.1-304.6-0.1-399.4l21.4,0.1c-0.1,55.4-0.2,109.6-0.3,156.4l-0.1,0l0.1,0.2
		  c-0.1,36.1-0.1,67.9-0.2,92.3c0,2.4-0.1,30.7-0.2,57.9C757,1254.5,756.9,1282.3,756.9,1284.6z M761.7,1091.4
		  c1.2,20,1.8,40.4,1.8,60.5c-0.1,18.6-0.7,37.4-1.8,55.9c-0.2,2.9-0.8,5.8-1.8,8.4l-1.2,3.3c0.1-24.4,0.1-47.4,0.1-49.6
		  c0.1-23.7,0.1-54.3,0.2-89.2C760.5,1083.9,761.4,1087.6,761.7,1091.4z M760,749.5h3.3c0.3,0,0.6,0.3,0.6,0.5v39.9
		  c0,0.2-0.3,0.5-0.6,0.5H760c-0.3,0-0.5-0.1-0.6-0.3c0-13.7,0-27.2,0-40.3C759.5,749.6,759.7,749.5,760,749.5z M757.9,747.4l-21.6,0
		  c0-0.5,0-0.9,0-1.4c0-23.3-3.1-71.9-4.2-90.5c-0.2-3.4-0.4-5.7-0.4-6.4c-0.2-3.7-1.6-5.5-2.3-6.2l8.2-90.2c0,0,0,0,0-0.1
		  c0,0,0,0,0,0h0c0-0.5-0.5-44.4-0.6-68.5l9.2,73.2l-2.4-1.6c0,0,0,0-0.1,0l-2.9-1.9c-0.2-0.1-0.5-0.2-0.7-0.1
		  c-0.2,0.1-0.4,0.3-0.4,0.6c-2.2,17.9-8.1,86.1-7.8,91.5c0.2,5,3.9,74.8,3.9,75.5c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4,0.2,0.6,0.2
		  c0.1,0,9.2-1.3,15.2-2.6c4.9-1,5.6-4.6,5.6-8.2c0-0.7,0-1.4-0.1-2.1c0-0.7,0-1.3,0-1.9c0-2.7-4.8-76.1-7.6-119.9
		  c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.6-0.4,0.9-0.6c2.5,2,4.7,3.8,6.8,5.4C758.1,625.4,758,681.8,757.9,747.4z M750.6,577.4
		  c-0.8,0.1-1.5,0.5-1.9,1.1c-0.6,1-0.9,2-0.9,3c0,1.8,1.1,2.9,1.2,2.9c0.4,0.3,0.7,0.6,1.1,0.9c-0.2,0.1-0.3,0.2-0.5,0.3
		  c-0.6,0.4-1.2,0.8-1.9,1.3l-0.2,0.1c-0.1,0-0.3,0-0.4,0.1l-6.5,4.2l1.4-13.2l11.5-4.3c0.8,2.3,1.4,4.2,1.4,5.4
		  c-0.3-0.2-0.5-0.4-0.8-0.7C753.3,577.8,751.8,577.3,750.6,577.4z M740.4,593.3l6.4-4.1c2.3,36.7,6.9,114.2,6.9,116.7
		  c0,0.6,0,1.2,0,1.8c0.1,4.2,0,6.9-3.2,7.7c-3.7,0.9-9,1.8-11,2.1c-0.4-7.5-3.1-62.5-3.5-66.7C736.1,647.8,738.3,618.7,740.4,593.3z
		   M752.9,572.5l-10.8,4l2-18.9l2.5,1.7C748.7,563,751.2,568.1,752.9,572.5z M773.9,587.6c8,5,12,10,11.1,14.1
		  c-0.5,2.3-1.3,3.7-2.2,4.1c-0.6,0.3-1.4,0.1-2.4-0.4c-0.2-0.1-0.4-0.3-0.8-0.4c-2.1-1.1-7.4-4.1-18.3-12.5c0.3-0.6,0.6-1.2,0.8-1.7
		  l1.2-2c0.4-0.5,0.6-0.6,0.6-0.6l0,0c0,0,0.1,0,0.1-0.1C764.1,588,768.7,584.3,773.9,587.6z M762.5,587.5c-0.5,0.6-1.3,1.8-2.3,4
		  c-3-2.3-6.4-5-10.2-8.2c0,0-0.7-0.6-0.7-1.8c0-0.7,0.2-1.4,0.7-2.2c0.2-0.3,0.5-0.4,0.8-0.4c0.8-0.1,1.8,0.2,2.4,0.8
		  C757.3,583.4,761,586.4,762.5,587.5z M758,559.9c0,0.4,0,7.9,0,22c-0.6-0.5-1.2-1-1.8-1.5c0.1-0.3,0.1-0.6,0.1-0.9
		  c0-5.2-7.2-18.9-8.4-21L737.1,472c0-0.6-0.1-1.2-0.2-1.8l0-0.1l0,0c-0.7-3.6-2.7-6.9-5.3-9.6c5.3,3.3,11.4,8.1,12.8,13.1
		  c0.3,1.1,0.8,4.3,1.5,8.8c0.5,3.1,1.1,6.9,1.7,11c0.9,5.4,1.8,11.4,2.8,17.6c0.7,4.5,1.4,9,2.2,13.4c0,0,0,0,0,0
		  C755.4,542.3,757.9,558.4,758,559.9z"/>
			<path d="M492,910.7V758.5c0-1.7-1.4-3.2-3-3.2h-9.1c-1.7,0-3,1.5-3,3.2v152.2c0,1.8,1.3,3.2,3,3.2h9.1
		  C490.7,913.9,492,912.5,492,910.7z M479.9,912.4c-1,0-1.7-0.7-1.7-1.7V758.5c0-0.9,0.8-1.7,1.7-1.7h9.1c0.9,0,1.7,0.8,1.7,1.7
		  v152.2c0,1-0.8,1.7-1.7,1.7H479.9z"/>
			<path d="M562.5,507.4l-1.7,2.8c-0.8,1.3-0.5,3,0.7,3.9c0.7,0.6,1.6,0.8,2.5,0.7c16.7-3,33.4-4.5,49.5-4.5h3.9
		  c16.4,0,32.6,1.5,49.5,4.5c0.9,0.2,1.8-0.1,2.5-0.7c0.7-0.6,1.1-1.4,1.1-2.3c0-0.5-0.1-1.1-0.4-1.5l-1.6-2.8c-1-1.8-2.8-3-4.8-3.3
		  c-16.2-2.7-32.7-4-49-3.9c-15.8,0-31.8,1.4-47.4,3.9C565.3,504.5,563.6,505.7,562.5,507.4z M663.5,505.6c1.6,0.3,2.9,1.2,3.7,2.6
		  l1.6,2.8c0.4,0.7,0.2,1.5-0.4,2c-0.3,0.3-0.8,0.4-1.3,0.3c-17-3-33.3-4.5-49.8-4.5h-3.9c-16.2,0-32.9,1.5-49.7,4.5
		  c-0.5,0.1-0.9,0-1.3-0.3c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.3,0.1-0.5,0.2-0.8l1.7-2.8c0.8-1.4,2.2-2.3,3.7-2.5
		  c15.5-2.5,31.4-3.9,47.1-3.9C631,501.7,647.4,503,663.5,505.6z"/>
			<path d="M739,758.5v152.2c0,1.8,1.3,3.2,3,3.2h9.1c1.7,0,3-1.4,3-3.2V758.5c0-1.7-1.4-3.2-3-3.2H742
		  C740.4,755.3,739,756.7,739,758.5z M751.1,756.8c0.9,0,1.7,0.8,1.7,1.7v152.2c0,1-0.8,1.7-1.7,1.7H742c-1,0-1.7-0.7-1.7-1.7V758.5
		  c0-0.9,0.8-1.7,1.7-1.7H751.1z"/>
		</g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1075.1,413.3c-1.4,1-2.1,2.4-2.4,3.5l-36.9,1.4
	  c-0.5,0-11.7,1.2-20.2,11.7c-4.5,5.7-14.6,20.1-23.4,32.9c-7.3,10.6-14.3,20.6-16.5,23.3c-4.8,5.7-10.6,10.1-10.7,10.2
	  c-0.2,0.1-0.3,0.4-0.3,0.6v13c-12.8,9.1-87.5,78.9-97.1,94.5c-0.1,0-0.2,0.1-0.2,0.2c-2.7,3-67.3,74.1-68.2,115.7
	  c0,4.2-0.7,424.6-0.7,430.4v130h-0.3v1.5h0.3v51.1c-0.2,1.2-2.1,15,3.9,22.4c2.2,2.8,5.3,4.3,9.1,4.6c16,1,149,9.9,150.4,10
	  l127.1,1.4c1.5,0,2.8,0.6,3.8,1.5c1,0.9,1.7,2,2.1,3c0.9,2.3,2.8,3.9,5.2,4.3l0.3,0.1c5.1,0.6,16.4,0.1,16.9,0.1
	  c0.2,0,0.3-0.1,0.5-0.2c0.3-0.3,7.5-6.4,11.3-22.9c0.2,0.4,0.4,0.8,0.7,1.2c1.8,2.8,4.2,5.1,7.2,6.9c0.4,0.2,0.8,0.1,1-0.2l4.2-6.8
	  c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.6c-1.3-1.2-2.4-2.6-3.4-4c-0.6-1-1.2-2.1-1.7-3.1c-0.7-1.8-1.1-3.7-1-5.6
	  c0.2-8.4,0.7-24.6,0.7-24.6l0.8-40.3c0.1-2.5,3.1-61.5,0.6-71.6c-1.1-4.6-3-6.5-3.8-7.1l-1.2-15.5c0,0,0,0,0.1,0
	  c23.3-5.7,39.6-26.5,39.6-50.5c0-4.2-0.5-8.4-1.5-12.5c-5.2-21.2-22.6-36.2-42.9-39.2l-0.1-10.6c0.3-0.1,0.6-0.2,0.8-0.4
	  c1.5-0.7,5.6-2.6,5.9-86c0-3.3,0-6.7,0-10c0-37.4-0.7-72.1-0.7-72.5c0,0,0-0.1,0-0.1c-0.1-6.8-0.9-298-0.9-301
	  c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-5.3,0.5-12.8,0.2v-5.1c4.4,0.1,9-0.4,13.5-1.5
	  c23.3-5.7,39.6-26.5,39.6-50.5c0-4.2-0.5-8.4-1.5-12.5c-6-24.6-28.7-40.9-53.1-39.7v-5c4.7-0.3,8.2-0.2,9.4-0.1
	  c1.7,0.2,2.9,0,3.7-0.6c0.6-0.5,0.9-1.3,0.9-2.3c0-1.1-0.3-2.3-0.6-3.7l-0.1-0.5c-1.7-6.9-2.5-13.9-2.5-20.9c0-5.8,0.6-9.7,0.8-10.8
	  c0-0.3,0.1-0.5,0-0.8c-1.1-13.8-11-26.9-16.9-31.9c-2-1.7-3.6-2.5-4.7-2.4c-3,0.3-12.8-0.6-16.9-1c-0.8-0.1-1.6,0.2-2.3,0.8
	  c-0.6,0.6-1,1.3-1,2.2c0,0.4-0.2,0.8-0.5,1c-0.3,0.3-0.7,0.4-1.1,0.4l-1.2-0.1c-0.7,0-1.3-0.6-1.3-1.4l0-0.8c0-1.3-0.9-2.5-2.2-2.8
	  C1078.8,411.9,1076.7,412.2,1075.1,413.3z M1072.6,499.6c-18.1,11.5-17.3,41.1-17.2,42.3c-0.2,4.3-0.1,8.4,0.3,12.3
	  c-3.2-0.2-7.9-0.5-13.5-0.6c-2.2-18.6,2.1-35.9,12.2-49c4.9-6.3,11-11.6,18.2-15.7l0,9.1V499.6z M1104.1,727.9h-67.3v-15.2
	  c28.9-0.8,51.4-1.7,67.3-2.7L1104.1,727.9z M1105.6,709.9c14.5-0.9,23.3-1.9,26.3-3c0,6.9,0,13.9,0.1,21h-26.3L1105.6,709.9z
	   M831.9,1360.1c0-5-0.1-34.3-0.3-77.9h17l0,79C842.6,1360.8,836.9,1360.4,831.9,1360.1z M832.2,766.3h16.3l0.1,262.9h-17.4
	  C831.2,926.6,831.5,825.2,832.2,766.3z M829.7,1029.2h-29.6c0.1-87.5,0.3-200.7,0.4-262.9h30.2c-0.8,57.8-1,156.5-1,257.1
	  C829.7,1025.4,829.7,1027.3,829.7,1029.2z M866.6,626.8c0.1-0.1,0.1-0.2,0.2-0.3l0,0c0.9-1.3,1.4-2,1.4-2l-0.6-0.4l0.7,0l0-0.5
	  l63.2-58.3c0,0.1,0,0.2,0,0.3c0.1,1.5,0.6,2.7,1.4,3.7l-52.1,48.9c-0.9,0.6-10.4,7.3-10.7,18.4c-0.3,11-1.1,61.1-1.1,61.6
	  c0,0.4-0.8,10.9,3.3,15.3c1.2,1.3,2.6,1.9,4.3,1.9c5.7,0.2,67-0.3,129.1-1.7c6.2-0.1,12.1-0.3,17.8-0.4c0,4.9,0,9.8-0.1,14.9H849.8
	  c-0.5-16.9-0.8-67.6,12.2-93.6C863.9,631,865.5,628.4,866.6,626.8z M976.7,524c-0.2,6.7-0.4,12-0.4,13.4c0,0,0,0,0,0l-16.5,15.3
	  c-4.3-0.1-9.3,0-14.7,0l20.7-19.2l0.1,0.1L976.7,524z M1046.9,428.7c4.1-0.6,8.1-1.4,11.8-2.2c3.8-0.8,7.7-0.3,9.7,1.2
	  c0.7,0.5,1,1.1,1,1.8c0,0.1,0,0.3-0.1,0.5l-7.7,34.6c-2.6,11.7-14.9,12-15.4,12c0,0-12.8,0.5-23.1,1c-1.4,0.1-2.7,0.1-3.9,0.2
	  c-3.9,0.1-5.6-0.7-6.3-1.4c-0.5-0.4-0.7-1-0.7-1.6c0-0.8,0.3-1.5,0.3-1.5l25.5-40.1c0.2-0.2,0.4-0.5,0.6-0.7
	  c0.2-0.2,0.3-0.3,0.5-0.5C1040.7,430.4,1043.6,429.2,1046.9,428.7z M973.2,570.9l-8.9,104.4c0,0.9-0.2,21.2-17.4,21.5l-57.3,5.7
	  c-1.8,0.2-3.6,0-5.2-0.6c-2.8-1-6-3-5.6-6.9l0.3-54.4c0-0.1,0.1-11.2,7-17.7c1.7-1.6,20.5-19,55.9-51.7h28.4
	  C971.6,571.1,972.5,571,973.2,570.9z M884,703.2c1.8,0.7,3.8,0.9,5.8,0.7l57.2-5.7c18.5-0.3,18.8-22.5,18.8-22.9c0,0,0,0,0,0
	  l9-105.3c0.2-0.2,0.3-0.4,0.3-0.5c0-0.1,0-0.1,0-0.2c0-0.4,1-9.8,1-12c0-0.2,0-0.4-0.1-0.6c6-1.1,17.6-1.7,30.3-1.9
	  c-10.3,51-22.1,109.5-25.2,123.3c-5.3,24.3-21.3,24.7-22,24.7c-0.7,0-71,4.7-75.5,4.3c-1.2-0.1-2.1-0.6-2.9-1.6
	  c-1.2-1.5-1.7-3.7-1.9-5.7C880,701.3,881.7,702.4,884,703.2z M976,555.3l0.8-9.1c9.3-13.8,17.8-28.4,25.2-43.2
	  c4-8,7.8-16.2,11.3-24.5c1.4,0.6,3.4,0.9,5.9,0.8c0.8,0,1.6-0.1,2.4-0.1c-2.5,12.3-8.3,41.6-14.9,74.2
	  C993.9,553.6,982.2,554.2,976,555.3z M1011.1,472.6c0,0-0.5,1-0.5,2.3c0,1.1,0.4,2,1.1,2.7c0.1,0.1,0.2,0.1,0.2,0.2
	  c-3.5,8.3-7.3,16.6-11.3,24.6c-7,14-15,27.7-23.6,40.8l0.4-5c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l0.2,0c0-0.4,0.1-2.8,0.2-6.4l0.1,0
	  c6.6-23.2,16.9-44.8,30.4-64.4c7.4-10.7,15.9-20.7,25.1-30L1011.1,472.6z M971.1,553.6c-1-0.4-4.4-0.7-9.2-0.8l14-12.9l-1.3,14.9
	  c-0.4-0.2-0.8-0.3-1.2-0.4C972.7,554.2,971.9,554,971.1,553.6z M970.5,554.9c1,0.4,1.8,0.7,2.5,0.9c1.5,0.4,1.7,0.5,1.7,1.6
	  c0,2-0.8,10.2-1,11.7c-0.2,0.2-1,0.6-3.2,0.6l-31.7,0c0,0-2.6,0.3-4.3-1.1c-0.9-0.7-1.3-1.8-1.4-3.2c-0.1-1-0.1-1.9-0.1-2.8
	  c0-3.3,0.4-5.2,0.4-5.2c0-0.1,0-0.1,0-0.2c0-0.1,0-2.9,3.2-2.9c0.6,0,1.9,0,3.5,0C947.8,554.2,968.2,554,970.5,554.9z M1035.2,712.7
	  v15.2h-10.2c0-5.1,0-10,0.1-14.9C1028.6,712.9,1031.9,712.8,1035.2,712.7z M1025.1,711.5c0.2-57.4,0.5-99.5,0.9-113.3
	  c0.1-4,1.8-7.7,4.8-10c1.2-0.9,2.2-1.4,3-1.5c0,0,1.3,0.1,1.4,2.2v122.3C1031.9,711.4,1028.5,711.5,1025.1,711.5z M1026,1101.5
	  c0,0.4,0.3,0.8,0.7,0.8h5.5c0.4,0,0.7-0.3,0.7-0.8V904h2.2v421.6c0,0.7-0.6,1.2-1.4,1.2h-8.3c-0.8,0-1.5-0.6-1.5-1.2
	  c0-3.8,0.1-223.7,0.6-421.6h1.4V1101.5z M1033,902.5v-32.9c0-0.4-0.3-0.8-0.7-0.8h-5.5c-0.4,0-0.7,0.3-0.7,0.8v32.9h-1.4
	  c0.1-62.5,0.3-122.7,0.5-173.1h10.2v173.1H1033z M850,733.6c0-0.1-0.1-1.6-0.2-4.2h173.7c-0.2,50.4-0.4,110.6-0.5,173.1H850
	  L850,733.6z M848.5,733.6l0,31.2h-16.2c0.3-23.4,0.7-39.9,1.3-46.7c2.3-29.4,13.6-56.3,22.7-73.6
	  C845.4,678.2,848.3,730.9,848.5,733.6z M848.6,1030.7l0.1,249.9h-17.1c-0.2-63.7-0.4-157.2-0.4-249.9H848.6z M915.2,1338.1
	  c0.1,0.2,0.3,0.5,0.3,0.8v14.7c0,0.6-0.4,1.1-0.7,1.1h-5c-0.2,0-0.7-0.5-0.7-1.1v-14.7c0-0.3,0.1-0.6,0.3-0.8H915.2z M1065,1338.1
	  c0.1,0.2,0.3,0.5,0.3,0.8v14.7c0,0.6-0.4,1.1-0.7,1.1h-5c-0.2,0-0.7-0.5-0.7-1.1v-14.7c0-0.3,0.1-0.6,0.3-0.8H1065z M1065.5,1336.6
	  c-0.3-0.1-0.6-0.2-0.9-0.2h-5c-0.3,0-0.6,0.1-0.9,0.2h-143c-0.3-0.1-0.6-0.2-0.9-0.2h-5c-0.3,0-0.6,0.1-0.9,0.2h-58.6L850.1,904h173
	  c-0.4,197.8-0.6,417.7-0.6,421.6c0,1.5,1.3,2.7,3,2.7h8.3c1.6,0,2.9-1.2,2.9-2.7V904h67.1l0,2.2c0,1,0.5,99.3-1,155.4
	  c-10.3,0.8-29,4.5-43.7,20c-17.8,18.7-17.7,41.9-17.6,54.4l0,0.1c0.1,12.9,0.2,34.4,16.7,52.1c16.6,17.8,41,19.3,50.2,19.9
	  c1,0.1,1.9,0.1,2.5,0.2l-1.5,128.3H1065.5z M1036.7,902.5V729.4h67.3l-0.2,173.1H1036.7z M1036.7,711.2V589c0,0,0,0,0,0
	  c-0.1-2.8-1.9-3.6-2.9-3.7c-1.2,0-2.6,0.6-4,1.8c-3.3,2.7-5.2,6.7-5.3,11.2c-0.3,13.8-0.6,55.9-0.9,113.3
	  c-65.8,1.7-138.7,2.4-146.9,2.1c-1.3,0-2.4-0.5-3.3-1.4c-3.6-3.9-2.9-14-2.9-14.2c0-0.5,0.8-50.5,1.1-61.6
	  c0.2-10.6,9.9-17.1,10-17.2c0,0,0.1-0.1,0.1-0.1l52.2-49.1c2.1,1.3,4.7,1,4.8,1h0.9c-34.6,31.9-53,49-54.7,50.6
	  c-7.4,7-7.4,18.3-7.4,18.8l-0.3,54.3c0,0.4-0.8,7.9,2.3,11.7c1,1.3,2.3,2,3.9,2.1c4.6,0.5,72.9-4.1,75.7-4.3
	  c0.2,0,17.8-0.3,23.4-25.9c3.1-13.8,15-72.5,25.3-123.6c11.3-0.2,23.3-0.1,32.9,0.2c0.1,0.4,0.1,0.8,0.1,1.2
	  c2.5,17,9.5,30.9,20.8,41.4c11.9,11,24.2,15,32.5,16.4c3.2,0.5,6.5,0.8,9.9,0.8l-0.1,93.6C1086.7,709.6,1062.7,710.5,1036.7,711.2z
	   M1008.2,553.4c6.6-32.8,12.5-62.2,14.9-74.3c10.2-0.5,23-1,23-1c0.1,0,14-0.3,16.9-13.2l7.7-34.6c0.1-0.3,0.1-0.5,0.1-0.8
	  c0-1.1-0.5-2.1-1.6-2.9c-2.3-1.8-6.7-2.4-10.9-1.5c-3.7,0.8-7.6,1.5-11.7,2.2c-1.5,0.2-2.9,0.6-4.3,1.1v-1.7l29.9-5.2l0.3,65.7
	  c-7.7,4.3-14.2,9.8-19.4,16.5c-10.3,13.3-14.7,30.9-12.5,49.8C1031.1,553.3,1019.3,553.2,1008.2,553.4z M1072.3,418.6l0,1.4
	  l-30.8,5.3c-1.2,0.2-2.5-0.1-3.4-0.9c-1-0.8-1.5-2-1.5-3.2v-1.4l35.7-1.4C1072.3,418.5,1072.3,418.5,1072.3,418.6z M1016.7,430.9
	  c6.9-8.4,15.7-10.5,18.4-11v1.3c0,1.7,0.7,3.3,2,4.4c1.1,0.9,2.4,1.4,3.8,1.3v2.1c-1,0.5-2,1.1-2.7,1.7l0,0
	  c-11.6,10.7-22,22.7-30.9,35.6c-12.7,18.3-22.5,38.3-29.1,59.8c0-1.1,0.1-2.2,0.1-3.4l0,0l0,0c0.2-6,0.3-13,0.3-19.3
	  c0-7.5-0.2-14.2-0.7-17.4c2.9-3.8,8.8-12.4,15.6-22.2C1002.2,451,1012.2,436.5,1016.7,430.9z M976.5,487.5
	  c0.7,6.7,0.5,22.3,0.2,34.4l-10.5,9.4l-0.1-6.1v-28C967.4,496.4,972.2,492.5,976.5,487.5z M964.4,512l0.2,13.4v6.2h0.1l0,0.9
	  l-22,20.3c-0.9,0-1.8,0-2.7,0c-1.6,0-2.9,0-3.5,0c-3.6,0-4.7,2.7-4.7,4.3c-0.1,0.4-0.4,2.3-0.4,5.4c0,0.2,0,0.4,0,0.6l-62.9,58.1
	  l-0.1-6.8l0.1-8.9C876.6,591.4,948.9,523.8,964.4,512z M866.9,607.3l0.1,7.1l-0.1,9.3c-0.2,0.3-0.7,1.1-1.5,2.2
	  c-1.8,2.3-3.5,4.9-4.9,7.9c-9.3,15.8-25.7,48-28.5,84.2c-0.5,6.8-0.9,23.3-1.3,46.8h-30.2c0-26.7,0.1-43.6,0.1-44.5
	  C801.4,682.8,857,618.5,866.9,607.3z M799.9,1150.7c0-2,0.1-53.4,0.2-120h29.6c0,92.7,0.2,186.2,0.4,249.9h-30.2V1150.7z
	   M803.5,1354.7c-5.7-7.1-3.6-21.2-3.5-21.3c0,0,0-0.1,0-0.1v-51.1h30.2c0.1,43.3,0.2,72.6,0.3,77.8c-9.2-0.6-16-1.1-18.9-1.2
	  C808.1,1358.5,805.4,1357.2,803.5,1354.7z M1096.2,1375.7c-0.5-1.3-1.3-2.4-2.4-3.5c-1.3-1.3-3-2-4.9-2l-127-1.4
	  c-0.9-0.1-67-4.5-111.7-7.5l0-23.2h57.5c-0.1,0.2-0.1,0.5-0.1,0.8v14.7c0,1.4,1,2.6,2.2,2.6h5c1.2,0,2.2-1.2,2.2-2.6v-14.7
	  c0-0.3,0-0.5-0.1-0.8h140.7c-0.1,0.2-0.1,0.5-0.1,0.8v14.7c0,1.4,1,2.6,2.2,2.6h5c1.2,0,2.2-1.2,2.2-2.6v-14.7c0-0.3,0-0.5-0.1-0.8
	  h33.1v40.8C1098.1,1378.5,1096.9,1377.3,1096.2,1375.7z M1116.9,1379.3c-1.7,0.1-11,0.4-15.7-0.1v-41.1h28.5
	  c-0.2,6.2-0.8,12.6-1.8,17.1l0.7,0.1l0,0l-0.7-0.1C1124.7,1371.3,1118.1,1378.2,1116.9,1379.3z M1148.9,1128.3
	  c0.6,2.3,0.8,4.7,0.8,7c0,13.5-9.1,25.2-22.2,28.4c-7.6,1.8-15.4,0.6-22.1-3.4c-6.7-4.1-11.4-10.5-13.2-18.1
	  c-0.6-2.3-0.8-4.6-0.8-6.9c0-13.5,9.2-25.2,22.3-28.4C1129.3,1103,1145.1,1112.7,1148.9,1128.3z M1126.6,1083.5
	  c-6-0.7-12.2-0.4-18.3,1.1c-23.4,5.7-39.7,26.5-39.7,50.6c0,4.1,0.5,8.3,1.5,12.3c3.3,13.5,11.7,25,23.6,32.2
	  c11.4,7,24.8,9.2,37.8,6.5l1.2,15.7c0,0.2,0.2,0.5,0.4,0.6c0,0,2.2,1.3,3.5,6.4c2.4,9.9-0.6,70.6-0.6,71.2l-0.8,40.3
	  c0,0-0.4,16.1-0.7,24.6c-0.1,2.2,0.3,4.3,1.2,6.3c0.5,1.2,1.1,2.3,1.8,3.4c0.9,1.4,2,2.7,3.2,3.9l-3.5,5.6c-2.5-1.6-4.5-3.7-6.1-6.1
	  c-0.6-0.9-1-1.8-1.5-2.7c1-5.1,1.7-12.5,1.8-19.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.6-0.2l-1.8,0.1l0.1-129.3
	  c0-0.4,0.2-10.7-5-11.7c-0.6-0.1-1.6-0.2-3.1-0.2c-40.8-2.4-61.2-22.5-60.6-59.7c0.8-48.9,31.1-54,53.2-57.8
	  c5.1-0.9,9.6-1.6,13.2-2.9L1126.6,1083.5z M1132.5,904.2L1132.5,904.2C1132.5,904.2,1132.5,904.2,1132.5,904.2L1132.5,904.2
	  c0,0.4,0.9,41.5,0.7,82.5c-0.3,74.5-3.6,84-5,84.7c-3.6,1.7-8.9,2.6-15.1,3.7c-21.4,3.6-53.6,9.1-54.4,59.2
	  c-0.4,24.5,8.2,41.9,25.6,51.9c13.9,8,29.7,8.9,36.4,9.3c1.3,0.1,2.4,0.1,2.9,0.2c3.3,0.6,3.9,7.6,3.8,10.2l-0.1,130.1v0
	  c0,0.2,0.1,0.4,0.2,0.5c0,0,0.1,0,0.1,0h-16.6l1.5-128.9c0,0,0,0,0,0c0-0.4-0.3-0.7-0.6-0.7c-0.6-0.1-1.8-0.2-3.3-0.3
	  c-9-0.6-32.9-2.1-49.2-19.4c-16.1-17.2-16.3-38.4-16.3-51.1l0-0.1c-0.1-12.3-0.2-35.1,17.2-53.4c14.7-15.4,33.5-18.9,43.4-19.6
	  c0.4,0,0.7-0.3,0.7-0.7c1.5-56,1-155.2,1-156.1l0-2.2h27.1C1132.5,904.1,1132.5,904.1,1132.5,904.2z M1132.3,857.2
	  c0,14.1,0.1,25.9,0.1,34.1c0,4.1,0,7.4,0,9.6c0,0.7,0,1.2,0,1.7h-27.1l0.2-173.1h26.4c0,8,0,16.2,0.1,24.3
	  C1132.1,791.3,1132.2,829,1132.3,857.2z M1131.9,705.3c-1.5,1.2-11.3,2.2-26.3,3.1l0.1-94.3v0c0-0.2-0.1-0.4-0.2-0.5
	  c-0.1-0.1-0.3-0.2-0.5-0.2c-3.5,0-6.9-0.2-10.3-0.8c-8.1-1.4-20.1-5.2-31.7-16c-11.1-10.3-17.9-23.9-20.4-40.6
	  c0-0.3-0.1-0.6-0.1-0.9c5.6,0.2,10.2,0.4,13.4,0.6c1.6,12.6,6.5,22.9,14.8,30.8c20.5,19.5,55.2,17.8,61,17.4
	  C1131.6,610.3,1131.7,653.6,1131.9,705.3z M1146.2,539c0.5,2.1,0.8,4.2,0.8,6.3c0,12.2-8.3,22.8-20.1,25.7
	  c-14.2,3.5-28.5-5.3-32-19.5c-0.5-2.1-0.8-4.2-0.8-6.3c0-12.2,8.3-22.8,20.2-25.7C1128.5,516.1,1142.8,524.8,1146.2,539z
	   M1116.5,493.3c-2.7,0.2-5.5,0.6-8.2,1.3c-23.4,5.7-39.7,26.5-39.7,50.6c0,4.1,0.5,8.3,1.5,12.3c5.6,22.9,25.5,38.6,47.9,39.8v5.1
	  c-13.3-0.8-32.9-4.3-46.3-17c-10.8-10.3-15.8-24.9-14.8-43.5c0-0.3-0.8-30.4,16.9-41.3c0.2-0.1,0.3-0.3,0.3-0.5
	  c0.8,0.2,1.8-0.1,2.8-0.8c10.9-7.4,28.2-10.2,39.6-11V493.3z M1089.7,415.1c0.3-0.3,0.7-0.4,1.1-0.4c5.1,0.5,14.2,1.3,17.1,1
	  c0.5,0,1.7,0.5,3.6,2.1c6.2,5.2,15.3,18.2,16.4,30.9c0,0.1,0,0.3,0,0.4c-0.2,1.1-0.8,5.1-0.8,11c0,7.1,0.9,14.2,2.6,21.2l0.1,0.5
	  c0.3,1.3,0.6,2.4,0.6,3.3c0,0.8-0.2,1-0.4,1.2c-0.4,0.3-1.2,0.4-2.5,0.3c-3.8-0.5-22.4-0.1-38.4,5.2v-75.7
	  C1089.2,415.7,1089.4,415.3,1089.7,415.1z M1087.7,492.3c-1.4,0.5-2.7,1-4,1.6l0-75.3c0.4,0.2,0.8,0.3,1.3,0.4l1.2,0.1
	  c0.6,0,1.1-0.1,1.6-0.4V492.3z M1081.1,413.9c0.6,0.1,1.1,0.7,1.1,1.4l0,0.8v0l0.1,78.5c-2.2,1-4.3,2.2-6.2,3.5
	  c-0.9,0.6-1.5,0.7-1.7,0.5c-0.2-0.1-0.2-0.4-0.2-0.6v-80.5c0-0.1,0-0.2,0-0.2c0.3-0.9,0.8-2,1.9-2.8
	  C1077.2,413.6,1078.9,413.4,1081.1,413.9z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M155.2,418.5c-1.3,0.3-2.2,1.4-2.2,2.8l0,0.8c0,0.8-0.6,1.4-1.3,1.4
	  l-1.2,0.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.5-0.6-0.5-1c0-0.8-0.3-1.6-1-2.2c-0.6-0.6-1.4-0.8-2.3-0.8c-4.1,0.4-13.9,1.3-16.9,1
	  c-1.1-0.1-2.7,0.7-4.7,2.4c-5.9,5-15.8,18.1-16.9,31.9c0,0.3,0,0.5,0,0.8c0.2,1.1,0.8,5,0.8,10.8c0,6.9-0.9,14-2.5,20.9l-0.1,0.5
	  c-0.3,1.4-0.6,2.6-0.6,3.7c0,1,0.3,1.8,0.9,2.3c0.8,0.7,1.9,0.9,3.7,0.6c1.2-0.2,4.7-0.2,9.4,0.1v5c-24.4-1.2-47.1,15-53.1,39.7
	  c-1,4.1-1.5,8.3-1.5,12.5c0,24.1,16.3,44.9,39.6,50.5c4.5,1.1,9.1,1.6,13.5,1.5v5.1c-7.5,0.4-12.7-0.1-12.8-0.2
	  c-0.2,0-0.4,0-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.6c0,3-0.8,294.1-0.9,301c0,0,0,0.1,0,0.1c0,0.4-0.7,35.1-0.7,72.5c0,3.3,0,6.7,0,10
	  c0.3,83.4,4.4,85.3,5.9,86c0.3,0.1,0.6,0.2,0.8,0.4l-0.1,10.6c-20.3,3-37.8,18-42.9,39.2c-1,4.1-1.5,8.3-1.5,12.5
	  c0,24.1,16.3,44.9,39.6,50.5c0,0,0,0,0.1,0l-1.2,15.5c-0.8,0.6-2.7,2.5-3.8,7.1c-2.5,10.1,0.5,69.1,0.6,71.6l0.8,40.3
	  c0,0,0.4,16.1,0.7,24.6c0.1,2-0.3,3.9-1,5.6c-0.5,1.1-1,2.1-1.7,3.1c-1,1.5-2.1,2.8-3.4,4c-0.2,0.1-0.2,0.3-0.2,0.6
	  c0,0.1,0,0.3,0.1,0.4l4.2,6.8c0.2,0.4,0.7,0.5,1,0.2c3-1.8,5.4-4.1,7.2-6.9c0.2-0.4,0.5-0.8,0.7-1.2c3.8,16.5,11,22.7,11.3,22.9
	  c0.1,0.1,0.3,0.2,0.5,0.2c0.5,0,11.8,0.4,16.9-0.1l0.3-0.1c2.3-0.4,4.3-2.1,5.2-4.3c0.4-1.1,1.1-2.1,2.1-3c1-1,2.4-1.5,3.8-1.5
	  l127.1-1.4c1.4-0.1,134.4-9,150.4-10c3.8-0.2,6.9-1.8,9.1-4.6c6-7.4,4-21.2,3.9-22.4v-51.1h0.3v-1.5h-0.3v-130
	  c0-5.8-0.7-426.1-0.7-430.4c-0.9-41.6-65.4-112.7-68.2-115.7c-0.1-0.1-0.1-0.1-0.2-0.2c-9.6-15.6-84.3-85.4-97.1-94.5v-13
	  c0-0.2-0.1-0.5-0.3-0.6c-0.1,0-5.9-4.4-10.7-10.2c-2.2-2.7-9.2-12.7-16.5-23.3c-8.8-12.8-18.8-27.3-23.4-32.9
	  c-8.6-10.5-19.8-11.6-20.2-11.7l-36.9-1.4c-0.4-1.1-1.1-2.5-2.4-3.5C159.9,418.2,157.7,417.9,155.2,418.5z M163.9,504.1l0-9.1
	  c7.2,4.1,13.3,9.4,18.2,15.7c10.1,13.1,14.4,30.4,12.2,49c-5.5,0.2-10.2,0.4-13.5,0.6c0.4-3.9,0.5-8,0.3-12.3
	  c0-1.2,0.8-30.8-17.2-42.3V504.1z M132.5,716c15.9,1,38.4,1.9,67.3,2.7v15.2h-67.3L132.5,716z M131,733.9h-26.3c0-7.1,0-14.1,0.1-21
	  c3,1.1,11.8,2,26.3,3L131,733.9z M387.8,1367.2l0-79h17c-0.1,43.6-0.2,72.9-0.3,77.9C399.6,1366.4,393.9,1366.8,387.8,1367.2z
	   M405.3,1035.2H388l0.1-262.9h16.3C405.1,831.2,405.3,932.6,405.3,1035.2z M406.8,1029.4c0-100.6-0.2-199.3-1-257.1H436
	  c0.1,62.2,0.3,175.4,0.4,262.9h-29.6C406.8,1033.3,406.8,1031.4,406.8,1029.4z M374.6,640.3c13,26.1,12.7,76.7,12.2,93.6H213
	  c0-5.1,0-10-0.1-14.9c5.7,0.1,11.6,0.3,17.8,0.4c62.1,1.4,123.4,2,129.1,1.7c1.7,0,3.2-0.6,4.3-1.9c4-4.3,3.3-14.8,3.3-15.3
	  c0-0.5-0.8-50.5-1.1-61.6c-0.2-11.1-9.7-17.8-10.7-18.4l-52.1-48.9c0.8-0.9,1.3-2.2,1.4-3.7c0-0.1,0-0.2,0-0.3l63.2,58.3l0,0.5
	  l0.7,0l-0.6,0.4c0,0.1,0.5,0.8,1.4,2l0,0c0.1,0.1,0.1,0.2,0.2,0.3C371,634.4,372.7,637,374.6,640.3z M270.7,539.7l0.1-0.1l20.7,19.2
	  c-5.4,0-10.5-0.1-14.7,0l-16.5-15.3c0,0,0,0,0,0c-0.1-1.5-0.2-6.7-0.4-13.4L270.7,539.7z M197.6,438c0.2,0.2,0.3,0.3,0.5,0.5
	  c0.2,0.2,0.4,0.5,0.6,0.7l25.5,40.1c0,0,0.3,0.7,0.3,1.5c0,0.7-0.2,1.2-0.7,1.6c-0.7,0.7-2.4,1.5-6.3,1.4c-1.2-0.1-2.6-0.1-3.9-0.2
	  c-10.3-0.5-23.1-1-23.1-1c-0.5,0-12.9-0.3-15.4-12l-7.7-34.6c0-0.2-0.1-0.3-0.1-0.5c0-0.6,0.3-1.2,1-1.8c1.9-1.5,5.9-2,9.7-1.2
	  c3.7,0.8,7.6,1.5,11.8,2.2C192.9,435.2,195.8,436.4,197.6,438z M266.1,577.1h28.4c35.3,32.7,54.1,50.1,55.9,51.7
	  c6.9,6.6,7,17.6,7,17.7l0.3,54.4c0.4,3.9-2.9,5.9-5.6,6.9c-1.6,0.6-3.4,0.8-5.2,0.6l-57.3-5.7c-17.2-0.3-17.4-20.6-17.4-21.5
	  l-8.9-104.4C264,577,264.9,577.1,266.1,577.1z M357.7,705.9c-0.2,2-0.7,4.2-1.9,5.7c-0.8,0.9-1.7,1.5-2.9,1.6
	  c-4.5,0.4-74.8-4.2-75.5-4.3c-0.7,0-16.7-0.3-22-24.7c-3.1-13.8-14.9-72.3-25.2-123.3c12.7,0.2,24.3,0.8,30.3,1.9
	  c0,0.2-0.1,0.4-0.1,0.6c0,2.3,1,11.6,1,12c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.3,0.3,0.5l9,105.3c0,0,0,0,0,0c0,0.4,0.3,22.6,18.8,22.9
	  l57.2,5.7c2,0.2,4,0,5.8-0.7C354.8,708.4,356.6,707.3,357.7,705.9z M229.9,559.4c-6.6-32.6-12.4-61.9-14.9-74.2
	  c0.8,0,1.7,0.1,2.4,0.1c2.5,0.1,4.5-0.2,5.9-0.8c3.5,8.3,7.3,16.5,11.3,24.5c7.5,14.9,15.9,29.4,25.2,43.2l0.8,9.1
	  C254.4,560.2,242.7,559.6,229.9,559.4z M202.9,443.2c9.3,9.2,17.7,19.3,25.1,30c13.6,19.6,23.8,41.2,30.4,64.4l0.1,0
	  c0.1,3.6,0.2,6,0.2,6.4l0.2,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1l0.4,5c-8.6-13.1-16.6-26.8-23.6-40.8c-4-8-7.8-16.3-11.3-24.6
	  c0.1-0.1,0.2-0.1,0.2-0.2c0.7-0.7,1.1-1.7,1.1-2.7c0-1.2-0.5-2.2-0.5-2.3L202.9,443.2z M263.2,560.4c-0.4,0.1-0.8,0.3-1.2,0.4
	  l-1.3-14.9l14,12.9c-4.8,0.1-8.2,0.3-9.2,0.8C264.6,560,263.9,560.2,263.2,560.4z M296.4,560.3c1.6,0,2.9,0,3.5,0
	  c3.2,0,3.2,2.7,3.2,2.9c0,0.1,0,0.1,0,0.2c0,0,0.4,1.9,0.4,5.2c0,0.9,0,1.9-0.1,2.8c-0.1,1.4-0.6,2.5-1.4,3.2
	  c-1.7,1.4-4.3,1.1-4.3,1.1l-31.7,0c-2.2,0-3-0.4-3.2-0.6c-0.2-1.5-1-9.7-1-11.7c0-1,0.2-1.1,1.7-1.6c0.7-0.2,1.5-0.4,2.5-0.9
	  C268.4,560,288.8,560.2,296.4,560.3z M211.4,719c0,4.9,0,9.8,0.1,14.9h-10.2v-15.2C204.6,718.8,208,718.9,211.4,719z M201.3,717.3
	  V595c0.1-2.1,1.4-2.2,1.4-2.2c0.8,0,1.9,0.5,3,1.5c2.9,2.4,4.7,6,4.8,10c0.3,13.8,0.6,55.8,0.9,113.3
	  C208,717.5,204.7,717.4,201.3,717.3z M210.5,910h1.4c0.4,197.8,0.6,417.7,0.6,421.6c0,0.7-0.7,1.2-1.5,1.2h-8.3
	  c-0.8,0-1.4-0.5-1.4-1.2V910h2.2v197.4c0,0.4,0.3,0.8,0.7,0.8h5.5c0.4,0,0.7-0.3,0.7-0.8V910z M201.3,908.5V735.4h10.2
	  c0.2,50.4,0.4,110.6,0.5,173.1h-1.4v-32.9c0-0.4-0.3-0.8-0.7-0.8h-5.5c-0.4,0-0.7,0.3-0.7,0.8v32.9H201.3z M386.5,908.5h-173
	  c-0.1-62.5-0.3-122.7-0.5-173.1h173.7c-0.1,2.6-0.2,4.1-0.2,4.2L386.5,908.5z M380.3,650.5c9.1,17.3,20.4,44.1,22.7,73.6
	  c0.5,6.8,0.9,23.3,1.3,46.7H388l0-31.2C388.2,736.9,391.1,684.2,380.3,650.5z M405.3,1036.7c0,92.7-0.2,186.3-0.4,249.9h-17.1
	  l0.1-249.9H405.3z M327.2,1344.1c0.2,0.2,0.3,0.4,0.3,0.8v14.7c0,0.6-0.4,1.1-0.7,1.1h-5c-0.3,0-0.7-0.5-0.7-1.1v-14.7
	  c0-0.3,0.1-0.6,0.3-0.8H327.2z M177.4,1344.1c0.2,0.2,0.3,0.4,0.3,0.8v14.7c0,0.6-0.4,1.1-0.7,1.1h-5c-0.3,0-0.7-0.5-0.7-1.1v-14.7
	  c0-0.3,0.1-0.6,0.3-0.8H177.4z M127.1,1342.6l-1.5-128.3c0.6-0.1,1.5-0.1,2.5-0.2c9.2-0.6,33.5-2.1,50.2-19.9
	  c16.5-17.7,16.7-39.2,16.7-52.1l0-0.1c0.1-12.5,0.2-35.7-17.6-54.4c-14.7-15.4-33.4-19.1-43.7-20c-1.5-56.1-1-154.4-1-155.4l0-2.2
	  h67.1v421.6c0,1.5,1.3,2.7,2.9,2.7h8.3c1.7,0,3-1.2,3-2.7c0-3.8-0.1-223.7-0.6-421.6h173l-0.2,432.6h-58.6c-0.3-0.1-0.6-0.2-0.9-0.2
	  h-5c-0.3,0-0.6,0.1-0.9,0.2h-143c-0.3-0.1-0.6-0.2-0.9-0.2h-5c-0.3,0-0.6,0.1-0.9,0.2H127.1z M132.7,908.5l-0.2-173.1h67.3v173.1
	  H132.7z M132.5,714.5l-0.1-93.6c3.3,0,6.6-0.3,9.9-0.8c8.3-1.4,20.6-5.4,32.5-16.4c11.3-10.5,18.3-24.4,20.8-41.4
	  c0.1-0.4,0.1-0.8,0.1-1.2c9.7-0.3,21.7-0.4,32.9-0.2c10.3,51.1,22.2,109.8,25.3,123.6c5.6,25.6,23.2,25.9,23.4,25.9
	  c2.9,0.2,71.1,4.7,75.7,4.3c1.6-0.2,2.9-0.9,3.9-2.1c3.1-3.8,2.3-11.3,2.3-11.7l-0.3-54.3c0-0.5-0.1-11.8-7.4-18.8
	  c-1.7-1.6-20.1-18.6-54.7-50.6h0.9c0,0,2.6,0.3,4.8-1l52.2,49.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,9.8,6.6,10,17.2
	  c0.3,11,1.1,61.1,1.1,61.6c0,0.1,0.7,10.3-2.9,14.2c-0.9,1-2,1.4-3.3,1.4c-8.2,0.3-81.1-0.4-146.9-2.1c-0.2-57.5-0.5-99.5-0.9-113.3
	  c-0.1-4.4-2.1-8.5-5.3-11.2c-1.4-1.2-2.8-1.8-4-1.8c-1,0-2.8,0.9-2.9,3.7c0,0,0,0,0,0v122.2C173.9,716.5,149.9,715.6,132.5,714.5z
	   M195.9,559.6c2.2-18.9-2.2-36.5-12.5-49.8c-5.2-6.7-11.7-12.3-19.4-16.5l0.3-65.7l29.9,5.2v1.7c-1.3-0.5-2.8-0.9-4.3-1.1
	  c-4.1-0.6-8-1.3-11.7-2.2c-4.2-0.9-8.6-0.3-10.9,1.5c-1,0.8-1.6,1.8-1.6,2.9c0,0.3,0,0.5,0.1,0.8l7.7,34.6
	  c2.8,12.8,16.7,13.2,16.9,13.2c0,0,12.7,0.5,23,1c2.4,12,8.3,41.5,14.9,74.3C217.2,559.2,205.4,559.3,195.9,559.6z M164.2,424.3
	  l35.7,1.4v1.4c0,1.2-0.5,2.4-1.5,3.2c-1,0.8-2.2,1.1-3.4,0.9l-30.8-5.3l0-1.4C164.3,424.5,164.3,424.5,164.2,424.3z M243.2,469.7
	  c6.8,9.8,12.7,18.4,15.6,22.2c-0.5,3.2-0.7,9.9-0.7,17.4c0,6.3,0.1,13.3,0.3,19.3l0,0l0,0c0,1.2,0.1,2.3,0.1,3.4
	  c-6.7-21.4-16.5-41.5-29.1-59.8c-8.9-12.9-19.3-24.9-30.9-35.6l0,0c-0.8-0.6-1.7-1.2-2.7-1.7v-2.1c1.4,0,2.7-0.4,3.8-1.3
	  c1.3-1.1,2-2.7,2-4.4v-1.3c2.6,0.5,11.5,2.6,18.4,11C224.4,442.5,234.4,457,243.2,469.7z M270.4,503.3v28l-0.1,6.1l-10.5-9.4
	  c-0.3-12.1-0.5-27.8,0.2-34.4C264.3,498.5,269.2,502.4,270.4,503.3z M368,611.6l0.1,8.9l-0.1,6.8l-62.9-58.1c0-0.2,0-0.4,0-0.6
	  c0-3.1-0.3-5-0.4-5.4c0-1.5-1-4.3-4.7-4.3c-0.6,0-1.9,0-3.5,0c-0.9,0-1.8,0-2.7,0l-22-20.3l0-0.9h0.1v-6.2l0.2-13.4
	  C287.7,529.8,359.9,597.4,368,611.6z M435.9,726.3c0,0.8,0,17.8,0.1,44.5h-30.2c-0.3-23.5-0.7-40-1.3-46.8
	  c-2.8-36.2-19.1-68.5-28.5-84.2c-1.5-2.9-3.1-5.6-4.9-7.9c-0.7-1.1-1.2-1.8-1.5-2.2l-0.1-9.3l0.1-7.1
	  C379.6,624.5,435.1,688.8,435.9,726.3z M436.6,1286.7h-30.2c0.2-63.7,0.4-157.2,0.4-249.9h29.6c0.1,66.6,0.2,118,0.2,120V1286.7z
	   M425,1364.7c-2.9,0.2-9.7,0.6-18.9,1.2c0-5.2,0.1-34.5,0.3-77.8h30.2v51.1c0,0,0,0.1,0,0.1c0,0.1,2.1,14.2-3.5,21.3
	  C431.1,1363.2,428.4,1364.5,425,1364.7z M136.9,1385v-40.8H170c-0.1,0.2-0.1,0.5-0.1,0.8v14.7c0,1.4,1,2.6,2.2,2.6h5
	  c1.1,0,2.2-1.2,2.2-2.6v-14.7c0-0.3,0-0.5-0.1-0.8h140.7c-0.1,0.2-0.1,0.5-0.1,0.8v14.7c0,1.4,1,2.6,2.2,2.6h5
	  c1.1,0,2.2-1.2,2.2-2.6v-14.7c0-0.3,0-0.5-0.1-0.8h57.5l0,23.2c-44.7,3-110.8,7.4-111.7,7.5l-127,1.4c-1.9,0-3.6,0.7-4.9,2
	  c-1.1,1.1-1.9,2.3-2.4,3.5C139.7,1383.3,138.4,1384.5,136.9,1385z M108.6,1361.3l-0.7,0.1l0,0L108.6,1361.3
	  c-0.9-4.5-1.5-10.9-1.7-17.2h28.5v41.1c-4.7,0.4-14,0.1-15.7,0.1C118.4,1384.2,111.8,1377.3,108.6,1361.3z M122.9,1112.8
	  c13.1,3.2,22.3,14.9,22.3,28.4c0,2.3-0.3,4.7-0.8,6.9c-1.9,7.6-6.6,14-13.2,18.1c-6.7,4.1-14.5,5.3-22.1,3.4
	  c-13.1-3.2-22.2-14.9-22.2-28.4c0-2.3,0.3-4.7,0.8-7C91.5,1118.7,107.3,1109,122.9,1112.8z M110.1,1079.7c3.5,1.2,8.1,2,13.2,2.9
	  c22.1,3.7,52.4,8.9,53.2,57.8c0.6,37.2-19.8,57.3-60.6,59.7c-1.4,0.1-2.5,0.1-3.1,0.2c-5.2,1-5,11.3-5,11.7l0.1,129.3l-1.8-0.1
	  c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0.1,6.9,0.8,14.3,1.8,19.4c-0.4,0.9-0.9,1.9-1.5,2.7c-1.6,2.4-3.6,4.4-6.1,6.1
	  l-3.5-5.6c1.2-1.2,2.3-2.5,3.2-3.9c0.7-1.1,1.3-2.2,1.8-3.4c0.8-2,1.2-4.1,1.2-6.3c-0.2-8.4-0.7-24.6-0.7-24.6l-0.8-40.3
	  c0-0.6-3-61.3-0.6-71.2c1.2-5.1,3.5-6.4,3.5-6.4c0.2-0.1,0.4-0.4,0.4-0.6l1.2-15.7c13,2.8,26.4,0.5,37.8-6.5
	  c11.9-7.2,20.3-18.7,23.6-32.2c1-4.1,1.5-8.2,1.5-12.3c0-24.1-16.3-44.9-39.7-50.6c-6.2-1.5-12.4-1.8-18.3-1.1L110.1,1079.7z
	   M104,910h27.1l0,2.2c0,1-0.5,100.2,1,156.1c0,0.4,0.3,0.7,0.7,0.7c9.9,0.7,28.7,4.2,43.4,19.6c17.4,18.3,17.3,41.1,17.2,53.4l0,0.1
	  c-0.1,12.7-0.2,33.8-16.3,51.1c-16.2,17.3-40.2,18.8-49.2,19.4c-1.5,0.1-2.7,0.2-3.3,0.3c-0.4,0.1-0.6,0.4-0.6,0.7c0,0,0,0,0,0
	  l1.5,128.9h-16.6c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.3,0.2-0.5v0l-0.1-130.1c-0.1-2.7,0.5-9.6,3.8-10.2c0.5-0.1,1.6-0.1,2.9-0.2
	  c6.7-0.4,22.5-1.3,36.4-9.3c17.4-10,26-27.5,25.6-51.9c-0.8-50.2-33.1-55.6-54.4-59.2c-6.2-1-11.5-2-15.1-3.7
	  c-1.4-0.7-4.8-10.2-5-84.7c-0.2-41,0.7-82.1,0.7-82.5l0,0c0,0,0,0,0,0l0,0C104.1,910.1,104.1,910.1,104,910z M104.6,759.7
	  c0-8.1,0-16.2,0.1-24.3H131l0.2,173.1h-27.1c0-0.5,0-1,0-1.7c0-2.2,0-5.4,0-9.6c0-8.2,0.1-20,0.1-34.1
	  C104.4,835,104.5,797.3,104.6,759.7z M105,609.9c5.8,0.4,40.5,2.1,61-17.4c8.3-7.9,13.2-18.2,14.8-30.8c3.2-0.3,7.9-0.5,13.4-0.6
	  c0,0.3-0.1,0.6-0.1,0.9c-2.5,16.7-9.3,30.3-20.4,40.6c-11.6,10.8-23.7,14.6-31.7,16c-3.4,0.6-6.9,0.8-10.3,0.8
	  c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v0l0.1,94.3c-14.9-0.9-24.7-2-26.3-3.1C104.8,659.6,105,616.3,105,609.9z
	   M122.3,525.5c11.9,2.9,20.2,13.5,20.2,25.7c0,2.1-0.3,4.2-0.8,6.3c-3.5,14.2-17.8,23-32,19.5c-11.9-2.9-20.1-13.5-20.1-25.7
	  c0-2.1,0.3-4.3,0.8-6.3C93.8,530.8,108.1,522.1,122.3,525.5z M120.1,494.3c11.4,0.8,28.7,3.6,39.6,11c1,0.7,2,1,2.8,0.8
	  c0,0.2,0.1,0.4,0.3,0.5c17.7,10.9,16.9,41,16.9,41.3c1,18.6-4,33.2-14.8,43.5c-13.4,12.7-33,16.2-46.3,17v-5.1
	  c22.4-1.2,42.3-16.9,47.9-39.8c1-4.1,1.5-8.2,1.5-12.3c0-24.1-16.3-44.9-39.7-50.6c-2.7-0.7-5.5-1.1-8.2-1.3V494.3z M147.3,422.1
	  v75.7c-15.9-5.3-34.6-5.7-38.4-5.2c-1.2,0.2-2.1,0.1-2.5-0.3c-0.2-0.1-0.4-0.4-0.4-1.2c0-0.9,0.3-2.1,0.6-3.3l0.1-0.5
	  c1.7-7,2.6-14.2,2.6-21.2c0-6-0.6-10-0.8-11c0-0.1,0-0.3,0-0.4c1-12.6,10.2-25.6,16.4-30.9c1.9-1.6,3.1-2.1,3.6-2.1
	  c3,0.3,12-0.5,17.1-1c0.4,0,0.8,0.1,1.1,0.4C147.2,421.3,147.3,421.7,147.3,422.1z M148.8,424.7c0.5,0.3,1,0.4,1.6,0.4l1.2-0.1
	  c0.5,0,0.9-0.2,1.3-0.4l0,75.3c-1.3-0.6-2.7-1.1-4-1.6V424.7z M160.6,420.6c1.1,0.8,1.6,1.9,1.9,2.8c0,0.1,0,0.2,0,0.2v80.5
	  c0,0.1,0,0.5-0.2,0.6c-0.2,0.1-0.8,0-1.7-0.5c-1.9-1.3-4-2.4-6.2-3.5l0.1-78.5v0l0-0.8c0-0.6,0.5-1.2,1.1-1.4
	  C157.6,419.4,159.3,419.6,160.6,420.6z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M482.8,291.7c0.1,2.1,1.2,7,8.1,8.3l35.3,6.7c0,0,0.1,0,0.1,0
	  c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.2-0.5,0.2-0.7c-1-3.7-1.8-7.7-2.6-11.9c-0.3-1.8-1.1-3.2-1.6-3.7c-0.1-0.1-0.1-0.1-0.2-0.1
	  l-40.1-21.7c-0.2-0.1-0.5-0.1-0.8,0c-0.2,0.1-0.4,0.4-0.3,0.7c0.6,10,1.2,22.3,1.2,22.4L482.8,291.7z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M725.6,268.3L685.6,290c-0.1,0-0.1,0.1-0.2,0.1
	  c-0.5,0.5-1.3,1.9-1.6,3.7c-0.7,4.1-1.6,8.1-2.6,11.9c-0.1,0.2,0,0.5,0.2,0.7c0.1,0.2,0.3,0.2,0.5,0.2c0,0,0.1,0,0.1,0l35.3-6.7
	  c6.9-1.3,8-6.2,8.1-8.3l0-0.3c0-0.1,0.6-12.4,1.2-22.4c0-0.3-0.1-0.5-0.3-0.7C726.2,268.2,725.9,268.2,725.6,268.3z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M781.4,213.2v-30.6c0-7.3-6.7-7.8-6.8-7.9c0,0-1,0-2.6,0
	  c-3.9,0-11,0.1-13.9,0.5c-3.8,0.4-4.4,4-4.3,5.8l-1,15.1l-5.2,0.2c-1.3-14.2-2.8-28.5-4.4-42.6c-1.4-12.1-3-24.3-4.7-36.4
	  c0,0,0,0,0,0l0,0c-1.4-9.8-2.8-18.3-3.8-23.3c-3.7-18.6-14.8-38.3-19.4-45.9c-1.2-2-3.3-3.4-5.9-3.8c-0.2,0-0.4-0.1-0.6-0.1
	  c-2.1-0.5-66.7-0.6-104.6-0.7l0,0.8l0-0.8c-37.9,0-102.6,0.2-104.6,0.7c-0.2,0.1-0.4,0.1-0.6,0.1c-2.5,0.4-4.6,1.8-5.9,3.8
	  c-4.6,7.6-15.7,27.3-19.4,45.9c-1,4.9-2.4,13.4-3.8,23.3l0,0c0,0,0,0,0,0c-1.7,12-3.3,24.3-4.7,36.4c-1.7,14.1-3.1,28.5-4.4,42.6
	  l-5.2-0.2l-1-15.1c0.2-1.9-0.5-5.4-4.3-5.8c-2.9-0.4-10-0.5-13.9-0.5c-1.5,0-2.6,0-2.6,0c-0.1,0-6.8,0.6-6.8,7.9l0,30.5
	  c0,0.1-0.1,3.2,1.8,5.2c1.1,1.2,2.7,1.8,4.6,1.8h15.8c0.7,0,4.1-0.1,5.7-2c0.7-0.8,0.9-1.9,0.7-3c-0.2-1.1-0.3-2-0.5-2.7l4.4,3.1
	  c-0.4,5.1-0.7,10.5-1.1,16h-0.7c-1.6,0-2.8,1.3-2.8,2.8v2.9c0,1.6,1.3,2.8,2.8,2.8h0.2c-1.1,24.2-1.2,48.7,2.1,60.7
	  c0,0.1,2.2,7.5,2.5,18.2c0,0.2,0,0.3,0.2,0.5c0,0.1,0,0.1,0,0.2c-0.3,2.5,0.4,11.9,2.1,22.8v64.7c0,3.1,2.9,5.5,6.7,5.5h28.1
	  c3.8,0,6.7-2.4,6.7-5.5v-26.4c8.2-0.9,20.3-1.6,38.7-1.8c11.8-0.1,21-0.1,28.6-0.2c13.9-0.1,22.3-0.2,30.7-0.2l0-1.5
	  c-8.4,0-16.8,0.1-30.7,0.2c-7.6,0.1-16.8,0.1-28.6,0.2c-44.1,0.4-52.7,4.1-56.8,5.9c-2,0.9-2.7,1.2-6.5,0.2
	  c-1.8-0.4-3.7-2.3-5.6-5.7c-0.5-1-1.1-2.1-1.6-3.3v-0.1h-0.1c-2.9-6.8-5.2-16.3-6.9-25.5H560v5.3c0,2.2,1.8,4.1,4.1,4.1h40.1v-1.5
	  H564c-1.4,0-2.6-1.1-2.6-2.6v-9c0-1.4,1.1-2.6,2.6-2.6h40.1v-1.5H564c-2.2,0-4.1,1.8-4.1,4.1v2.2h-92.8c-0.3-1.8-0.6-3.6-0.9-5.3
	  V341h-0.2c-0.2-1.1-0.3-2.2-0.5-3.3h90.9h47.5h0.2h47.5h90.9c-0.1,1.1-0.3,2.2-0.5,3.3h-0.2v1.2c-0.3,1.7-0.6,3.5-0.9,5.3h-92.8
	  v-2.2c0-2.2-1.8-4.1-4.1-4.1h-40.1v1.5h40.1c1.4,0,2.6,1.1,2.6,2.6v9c0,1.4-1.1,2.6-2.6,2.6h-40.1v1.5h40.1c2.2,0,4.1-1.8,4.1-4.1
	  V349h92.6c-1.7,9.2-4,18.7-6.9,25.5h-0.1v0.1c-0.5,1.2-1,2.3-1.6,3.3c-1.9,3.4-3.7,5.3-5.6,5.7c-3.8,0.9-4.4,0.6-6.4-0.2
	  c-4.1-1.8-12.7-5.5-56.8-5.9c-11.8-0.1-21-0.1-28.6-0.2c-13.9-0.1-22.3-0.2-30.7-0.2l0,1.5c8.4,0,16.8,0.1,30.7,0.2
	  c7.6,0.1,16.8,0.1,28.6,0.2c18.3,0.2,30.5,0.9,38.7,1.8v26.4c0,3.1,2.9,5.5,6.7,5.5h28.1c3.7,0,6.7-2.4,6.7-5.5v-64.7
	  c1.7-10.9,2.4-20.3,2.1-22.7c0-0.1,0-0.1,0-0.2c0.2-0.2,0.2-0.3,0.2-0.5c0.2-10.7,2.4-18.1,2.5-18.2c3.3-12.1,3.3-36.9,2.1-61.3h0.1
	  c1.6,0,2.8-1.3,2.8-2.8v-2.9c0-1.6-1.3-2.8-2.8-2.8h-0.5c-0.3-5.3-0.7-10.5-1-15.5l4.4-3.1c-0.1,0.7-0.3,1.6-0.5,2.7
	  c-0.2,1.2,0.1,2.3,0.8,3.1c1.6,1.9,4.9,2,5.6,2c0,0,0,0,0,0H775c1.9,0,3.5-0.6,4.6-1.8C781.5,216.3,781.4,213.3,781.4,213.2z
	   M741.7,153.8c1.6,14.1,3.1,28.4,4.4,42.5l-4.3,0.1c-1.5-0.2-3-0.5-3.8-0.7c0,0,0,0,0,0l-0.1,0c-1.9-19-2.5-36.4-3.1-51.8
	  c-0.2-7.2-0.5-14-0.8-19.9l0-0.3c-0.1-1.3-0.3-4.4,1-5.4c0.5-0.4,1.1-0.5,2-0.3C738.7,129.9,740.3,141.9,741.7,153.8z M736.8,116.4
	  c-1.1-0.1-2,0.1-2.7,0.6c-1.9,1.5-1.7,5-1.6,6.7l0,0.3c0.3,5.9,0.6,12.7,0.8,19.9c0.5,15.6,1.1,33.2,3.1,52.5c0,0.1,0,0.2,0.1,0.3
	  c1.3,13,2.5,23.8,2.9,25.7c0.7,4.3-2.3,26.2-4,38.1c-0.3-1.2-0.8-2.2-1.5-2.9c-0.8-0.9-2-1.3-3.3-1.3c-0.9,0-1.7,0.2-2.2,0.4
	  c6.7-44-1.4-147.6-1.5-148.6l-0.7,0.1l0.7-0.1c-0.1-0.9-1.8-4.8-4.4-10.3c1.4-0.1,2.9-0.3,4.2-0.8c2.9-1,3.6-1.4,5.6-5.9
	  c0.3,1.1,0.5,2.2,0.7,3.4C734.1,99.1,735.5,107.1,736.8,116.4z M749.2,282.6c-1.2,0.6-2.3,1.3-3.3,2.2c-4.2,3.4-6.1,7.9-8.6,16.7
	  c-1.7,5.7-3.1,11.6-4.3,17.5c-2.3,0.1-4.9,0.1-7.8,0.2v-12.6c9-4.8,9.2-14.6,9.2-14.7c0-0.1,0.6-12.7,1.2-22.8
	  c0.5-0.5,1.2-1.1,2-1.8c3.5-3,9-7.8,11.8-12.6C749.7,265.7,749.6,275,749.2,282.6z M662.7,320l10.3-32.9h3.8
	  c-0.8,1.6-1.4,3.3-1.8,5.2c-0.7,4.1-1.6,8-2.5,11.7c-1,4-0.5,8.1,1.4,10.5c1,1.3,2.3,2,3.7,2c0.3,0,0.6,0,0.9-0.1l40.6-7.7
	  c1.7-0.3,3.3-0.8,4.6-1.4v11.9c-6.6,0.2-14.4,0.3-23,0.4C690.5,319.8,678,319.9,662.7,320z M507.2,319.7c-8.4-0.1-16.1-0.3-22.6-0.4
	  v-11.9c1.4,0.6,2.9,1.1,4.6,1.4l40.6,7.7c0.3,0.1,0.6,0.1,0.9,0.1c1.4,0,2.7-0.7,3.7-2c1.9-2.4,2.4-6.6,1.4-10.5
	  c-0.9-3.6-1.8-7.6-2.5-11.7c-0.3-1.9-1-3.6-1.8-5.2h3.8l10.3,32.9C530.1,319.9,517.5,319.8,507.2,319.7z M458.9,254.7
	  c2.8,4.8,8.3,9.5,11.8,12.6c0.8,0.7,1.5,1.3,2,1.8c0.6,10.1,1.2,22.6,1.2,22.7c0,0.1,0.3,9.9,9.2,14.8v12.6
	  c-2.8-0.1-5.3-0.1-7.6-0.2c-1.2-5.9-2.7-11.8-4.3-17.5c-2.5-8.8-4.4-13.3-8.6-16.7c-1.1-0.9-2.2-1.6-3.5-2.2
	  C458.7,274.9,458.6,265.6,458.9,254.7z M479.5,258.1l47,25.5c2.5,1.3,4.6,4.9,5.3,8.9c0.7,4.1,1.6,8.1,2.6,11.8
	  c0.9,3.5,0.5,7.2-1.1,9.2c-0.6,0.7-1.7,1.6-3.1,1.3l-40.6-7.7c-13.8-2.6-14.1-14.9-14.1-15.5c0-0.1-0.7-12.9-1.2-23.1
	  c-0.1-1.4-0.2-2.7-0.2-3.9c-0.1-2.9,0.4-5.1,1.4-6.1c0.6-0.6,1.3-0.9,2.2-0.9C478.6,257.8,479.5,258.1,479.5,258.1z M530.7,285.6
	  c-1-1.5-2.2-2.6-3.4-3.3l-45.9-24.8l0,0c-1.4-9-2.2-20.8-2.6-33.7c5.3,7.5,12,15.6,20.6,24.9c11.1,12,22.7,22.2,35.4,31.3
	  c-0.7,1.3-0.9,2.8-0.4,4.2l0.5,1.5H530.7z M535.8,278.7c-12.7-9-24.2-19.2-35.2-31.1c-8.9-9.6-15.8-17.9-21.1-25.6
	  c13.5,0.2,27.3,0.6,40.6,0.9c27.6,0.6,56.1,1.3,84.1,1.3c28,0,56.5-0.7,84.1-1.3c13.3-0.3,27-0.6,40.6-0.9
	  c-5.3,7.6-12.2,16-21.1,25.6c-11,11.9-22.6,22.1-35.2,31.1c-1-1-2.3-1.6-3.8-1.6h-64.6h-64.6C538.1,277.2,536.8,277.7,535.8,278.7z
	   M673.4,279.9c12.7-9.1,24.3-19.3,35.4-31.3c8.6-9.3,15.3-17.4,20.6-24.9c-0.3,12.9-1.1,24.7-2.6,33.7l0,0l-45.8,24.8
	  c-1.3,0.7-2.4,1.8-3.4,3.3h-4.3l0.5-1.5C674.3,282.7,674.2,281.2,673.4,279.9z M734.4,264.8c-0.1,1.2-0.2,2.6-0.2,3.9
	  c-0.6,10.2-1.2,22.9-1.2,23.1c0,0.1-0.4,12.8-14.1,15.4l-40.6,7.7c-0.2,0-0.4,0.1-0.6,0.1c-1.2,0-2-0.8-2.5-1.4
	  c-1.6-2-2-5.7-1.1-9.2c1-3.7,1.8-7.6,2.6-11.8c0.7-4,2.9-7.6,5.3-8.9l47-25.5c0,0,0.9-0.4,1.9-0.4c0.9,0,1.7,0.3,2.2,0.9
	  C734,259.7,734.5,261.9,734.4,264.8z M709.1,45.7c2.1,0.4,3.8,1.5,4.8,3.1c4.1,6.8,13.3,23.1,17.8,39.7c-2.4,5.8-2.7,6-5.4,6.9
	  c-1.4,0.5-2.9,0.8-4.4,0.8c-7.7-16.2-22.2-44-29-50.9c9.3,0.1,14.9,0.2,15.5,0.3C708.7,45.7,708.9,45.7,709.1,45.7z M690.6,45.3
	  c5.2,2.6,21.3,33.4,29.7,51H604.2v1.5H721c2.7,5.6,4.4,9.6,4.5,10.5c0.1,1.5,5,64.1,4,112.4c-13.8,0.2-27.8,0.6-41.3,0.9
	  c-27.5,0.6-56,1.3-84,1.3c-28,0-56.5-0.7-84.1-1.3c-13.5-0.3-27.5-0.6-41.3-0.9c-1-48.5,4-111.6,4-112.4c0.1-0.8,1.8-4.9,4.5-10.5
	  h116.9v-1.5H488c8.4-17.6,24.5-48.3,29.7-51c18.4-0.2,49.5-0.3,86.4-0.3C641.1,45,672.2,45.1,690.6,45.3z M494.4,48.9
	  c1-1.7,2.7-2.8,4.8-3.1c0.2,0,0.4-0.1,0.7-0.1c0.6-0.1,6.1-0.2,15.5-0.3c-6.8,6.9-21.3,34.7-29,50.9c-1.5,0-3-0.3-4.4-0.8
	  c-2.7-0.9-3-1.1-5.4-6.9C481,72,490.3,55.6,494.4,48.9z M475.2,94.3c0.2-1.1,0.5-2.2,0.7-3.3c2,4.5,2.7,4.9,5.6,5.9
	  c1.4,0.5,2.8,0.7,4.2,0.8c-2.6,5.5-4.3,9.5-4.4,10.4c-0.1,1-8.2,104.6-1.5,148.6c-0.4-0.2-1.3-0.4-2.2-0.4c-1.3,0-2.5,0.5-3.3,1.3
	  c-0.7,0.7-1.2,1.7-1.5,2.9c-1.7-11.8-4.7-33.8-4-38.1c0.4-2,1.6-12.7,2.9-25.7c0-0.1,0.1-0.2,0.1-0.3c2-19.3,2.6-36.9,3.1-52.5
	  c0.2-7.2,0.5-14,0.8-19.9l0-0.3c0.1-1.8,0.3-5.2-1.6-6.7c-0.7-0.6-1.6-0.8-2.7-0.6C472.9,107.1,474.2,99.1,475.2,94.3z M466.7,153.8
	  c1.4-11.9,2.9-24,4.6-35.8c0.9-0.2,1.5-0.1,2,0.3c1.3,1,1.1,4.1,1,5.4l0,0.3c-0.4,5.9-0.6,12.7-0.8,19.9
	  c-0.5,15.4-1.1,32.8-3.1,51.8l-0.1,0c0,0,0,0,0,0c-0.8,0.2-2.3,0.5-3.8,0.7l-4.3-0.1C463.5,182.2,465,167.9,466.7,153.8z
	   M454.6,210.2c-0.1-0.2-0.3-0.5-0.7-0.5c-0.4,0-0.7,0.4-0.8,0.7c0,0,0,0.1,0,0.2c0,0.2,0.1,0.4,0.2,0.6c0.1,0.5,0.4,1.7,0.8,4.2
	  c0.1,0.7,0,1.3-0.4,1.8c-1,1.2-3.4,1.5-4.5,1.5h0h-15.8c-1.5,0-2.7-0.4-3.5-1.3c-1.5-1.6-1.4-4.1-1.4-4.2v-30.6
	  c0-5.9,5.2-6.3,5.3-6.4c0,0,1,0,2.5,0c3.9,0,10.9,0.1,13.7,0.5c3.3,0.4,3,4.1,2.9,4.3c0,0,0,0.1,0,0.1l1,15.8c0,0.4,0.3,0.7,0.7,0.7
	  l15,0.5L467,219L454.6,210.2z M467.1,220.9c0.1,0.1,0.3,0.1,0.4,0.1c-0.1,0.5-0.1,0.9-0.2,1.2c-0.9,5.5,3.7,36.7,4.8,44.4
	  c-0.2-0.2-0.4-0.3-0.5-0.5c-4.1-3.6-11-9.5-12.7-14.8c0.3-10.3,1-22,1.9-34.9L467.1,220.9z M457.7,238.6c-0.7,0-1.3-0.6-1.3-1.3
	  v-2.9c0-0.7,0.6-1.3,1.3-1.3h0.6c-0.1,1.8-0.2,3.7-0.3,5.6H457.7z M461.5,300.4c-1.1-3.8-1.8-9.2-2.3-16.1c0.9,0.5,1.7,1,2.4,1.6
	  c3.9,3.2,5.6,7.3,8.1,15.9c1.6,5.6,3,11.3,4.2,17.1c-3.9-0.1-6.8-0.2-8.5-0.3c-0.7-0.1-1.2-0.1-1.5-0.2
	  C463.7,307.8,461.6,300.7,461.5,300.4z M465.4,336.2c-1.1-8.1-1.5-14.5-1.3-16.2c0.1,0,0.3,0,0.4,0c2.8,0.4,9.6,0.6,25.9,0.9
	  c5.2,0.1,11.2,0.2,17.8,0.3c12.8,0.2,26.3,0.3,37.9,0.4l3.4,10.9c0.5,1.5,1.4,2.7,2.5,3.6H465.4z M604.3,336.2h-0.2h-47.5
	  c-2.6,0-4.8-1.6-5.5-4.1l-15.1-48.4c-0.4-1.2-0.2-2.4,0.6-3.4c0.7-1,1.9-1.6,3.1-1.6h64.6h64.6c1.2,0,2.4,0.6,3.1,1.6
	  c0.7,1,0.9,2.3,0.6,3.4l-15.1,48.4c-0.8,2.4-3,4.1-5.5,4.1H604.3z M744.2,320c0.2,1.8-0.2,8.1-1.3,16.2h-86.7
	  c1.2-0.9,2.1-2.1,2.5-3.6l3.4-10.9c11.5-0.1,25.1-0.2,37.9-0.4c6.6-0.1,12.6-0.2,17.8-0.3c16-0.3,22.8-0.5,25.7-0.9
	  C743.8,320,744,320,744.2,320z M746.8,300.4c-0.1,0.3-2.2,7.5-2.5,18.1c-0.1,0-0.3,0-0.5,0.1c-1.3,0.1-4.6,0.3-9.4,0.4
	  c1.2-5.7,2.6-11.5,4.2-17.1c2.5-8.6,4.2-12.8,8.1-15.9c0.7-0.6,1.5-1.1,2.3-1.5C748.6,291.2,747.9,296.6,746.8,300.4z M750.4,232.5
	  c0.7,0,1.3,0.6,1.3,1.3v2.9c0,0.7-0.6,1.3-1.3,1.3h-0.2c-0.1-1.9-0.2-3.7-0.3-5.6H750.4z M749.3,251.4c-1.6,5.3-8.5,11.2-12.7,14.8
	  c-0.2,0.2-0.4,0.3-0.5,0.5c1.2-7.7,5.7-39,4.8-44.5c0-0.2-0.1-0.6-0.2-1.2c0.2,0,0.3,0,0.4-0.1l6.2-4.4
	  C748.4,229.4,749,241,749.3,251.4z M778.5,217.3c-0.8,0.9-2,1.3-3.5,1.3l-15.8,0c-1.2,0-3.4-0.3-4.4-1.4c-0.4-0.5-0.6-1.1-0.4-1.9
	  c0.4-2.5,0.7-3.7,0.8-4.2c0.2-0.1,0.2-0.3,0.2-0.6c0,0,0-0.1,0-0.2c0-0.4-0.4-0.7-0.8-0.7c-0.4,0-0.6,0.3-0.7,0.5l-12.4,8.7
	  l-2.8-20.9l15-0.5c0.4,0,0.7-0.3,0.7-0.7l1-15.8c0,0,0-0.1,0-0.1c0-0.2-0.3-3.9,3-4.3c2.8-0.4,9.8-0.5,13.7-0.5c1.5,0,2.5,0,2.5,0
	  c0.2,0,5.4,0.5,5.4,6.4l0,30.6C779.9,213.2,780,215.7,778.5,217.3z"/>
			<path d="M662.2,287.3c-0.6-0.8-1.5-1.3-2.5-1.3h-55.5h0h-55.5c-1,0-1.9,0.5-2.5,1.3c-0.6,0.8-0.8,1.8-0.5,2.8l11.4,36.4
	  c0.4,1.3,1.6,2.2,3,2.2h44h0.2h44c1.4,0,2.6-0.9,3-2.2l11.4-36.4C663,289.2,662.8,288.2,662.2,287.3z M604.2,315h-0.1v0l-49.1,0
	  l-4.1-13.3h53.1h0.5h53.1l-4.1,13.3l-49.1,0v0H604.2z M649.9,326.1c-0.2,0.7-0.8,1.2-1.6,1.2h-44h-0.2h-44c-0.7,0-1.4-0.5-1.6-1.2
	  l-3-9.7l48.7,0l48.7,0L649.9,326.1z M661.3,289.7l-3.3,10.5h-53.6h-0.5h-53.6l-3.3-10.5c-0.2-0.5-0.1-1,0.2-1.5
	  c0.3-0.4,0.8-0.7,1.3-0.7h55.5h0h55.5c0.5,0,1,0.2,1.3,0.7C661.3,288.7,661.4,289.2,661.3,289.7z"/>
			<path d="M774.4,207H760c-1.4,0-2.5,1.1-2.5,2.5v3.8c0,1.4,1.1,2.5,2.5,2.5h14.4c1.4,0,2.5-1.1,2.5-2.5v-3.8
	  C776.9,208.2,775.8,207,774.4,207z M775.4,213.4c0,0.6-0.5,1-1,1H760c-0.6,0-1-0.5-1-1v-3.8c0-0.6,0.5-1,1-1h14.4c0.6,0,1,0.5,1,1
	  V213.4z"/>
			<path d="M447.8,207h-14.4c-1.4,0-2.5,1.1-2.5,2.5v3.8c0,1.4,1.1,2.5,2.5,2.5h14.4c1.4,0,2.5-1.1,2.5-2.5v-3.8
	  C450.3,208.2,449.2,207,447.8,207z M448.8,213.4c0,0.6-0.5,1-1,1h-14.4c-0.6,0-1-0.5-1-1v-3.8c0-0.6,0.5-1,1-1h14.4c0.6,0,1,0.5,1,1
	  V213.4z"/>
			<path d="M490.7,121.2l-10.5,80.5c-0.3,2.1,0.4,4.3,1.8,5.9c2.1,2.3,4.8,2.6,5.5,2.5c38.7,1.9,78,2.9,116.7,2.9h0.4
	  c38.6,0,77.8-1,116.3-2.9c2.1,0,4.2-0.9,5.6-2.5c1.4-1.6,2.1-3.7,1.8-5.9l-10.5-80.5c-0.5-3.7-3.6-6.5-7.4-6.5H498.1
	  C494.3,114.7,491.2,117.5,490.7,121.2z M716.4,121.4l10.5,80.5c0.2,1.7-0.3,3.4-1.4,4.7c-1.1,1.3-2.8,2-4.5,2
	  c-38.5,1.9-77.7,2.9-116.2,2.9h-0.4c-38.7,0-78-1-116.7-2.9c-0.4,0-2.8-0.1-4.5-2c-1.1-1.3-1.6-3-1.4-4.7l10.5-80.5
	  c0.4-2.9,2.9-5.2,5.9-5.2h212.5C713.5,116.2,716,118.4,716.4,121.4z"/>
			<path d="M549,231.8C549,231.8,549,231.8,549,231.8c18.3,1.1,36.8,1.6,55.2,1.6h0c18.3,0,36.9-0.5,55.1-1.6c0.4,0,1.3-0.1,2.1-0.9
	  c0.6-0.6,0.9-1.3,0.9-2.1v-1c0-1.6-1.3-3-3-3c-18.3,0.4-36.8,0.7-55.1,0.7c-18.3,0-36.8-0.2-55.1-0.7c-0.4,0-1.3,0.1-2.1,0.9
	  c-0.6,0.6-0.9,1.3-0.9,2.1v1C546.1,230.5,547.4,231.8,549,231.8z M547.6,227.8c0-0.4,0.2-0.8,0.4-1c0.4-0.4,0.9-0.4,1-0.4
	  c18.3,0.4,36.8,0.7,55.2,0.7c18.3,0,36.8-0.2,55.1-0.7c0.8,0,1.5,0.7,1.5,1.5v1c0,0.4-0.2,0.8-0.4,1c-0.4,0.4-0.9,0.4-1.1,0.4
	  c-18.2,1.1-36.8,1.6-55,1.6l0,0.8v-0.8c-18.3,0-36.8-0.5-55.1-1.6c-0.8,0-1.5-0.7-1.5-1.5V227.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M561.6,361.2h85.3c4.2,0,7.6-2.6,7.6-5.8v-1h16.9
	  c-0.2,2-1,4.7-3.5,7.5l-0.1,0.1c-3.3,3.6-9,5.7-15.3,5.7l-96.6,0c-6.2,0-12-2.1-15.3-5.7l-0.1-0.1c-2.5-2.7-3.3-5.5-3.5-7.5H554v1
	  C554,358.6,557.4,361.2,561.6,361.2z"/>
			<path d="M508.9,354.7l-12.1,0.1c-2.8,0-3.5,2-3.7,2.6c0,0,0,0.1,0,0.1c0,0-0.9,4-1.7,7.4c-0.1,0.3,0,0.5,0.2,0.7
	  c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.2,0l14.6-4.8c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.7-0.7,0.9-1.4c0.5-1.3,1-2.6,1.5-3.7
	  c0.1-0.2,0.1-0.5-0.1-0.7C509.4,354.9,509.2,354.7,508.9,354.7z M506.6,359c-0.1,0.3-0.3,0.5-0.4,0.6l-13.1,4.3
	  c0.7-3,1.4-6.1,1.4-6.1c0.2-0.7,0.7-1.5,2.2-1.5l10.9-0.1C507.4,357.1,507,358.1,506.6,359z"/>
			<path d="M510.7,351.7l-13.9,0.1c-4.4,0-6.2,3.3-6.6,5.1c0,0-1,4.2-1.8,7.6l-0.3,1.3c-0.3,1.3-0.2,2.4,0.4,3c0.4,0.5,1,0.8,1.7,0.8
	  c0.3,0,0.6-0.1,0.7-0.1l17.1-5.7c1.2-0.4,2.3-1.7,2.9-3.3c0.5-1.3,1-2.5,1.5-3.7c0.7-1.5,0.7-3.2,0-4.2
	  C512,352.1,511.4,351.7,510.7,351.7z M511,356.3c-0.5,1.2-1.1,2.5-1.6,3.8c-0.4,1.1-1.2,2.1-2,2.3l-17,5.6c0,0-0.1,0-0.3,0
	  c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.3-0.6-0.1-1.8l0.3-1.3c0.8-3.4,1.8-7.6,1.8-7.6c0-0.2,0.8-3.8,5.1-3.9l13.9-0.1
	  c0.1,0,0.3,0,0.4,0.3C511.5,354,511.5,355.1,511,356.3z"/>
			<path d="M701.2,360.6c0.1,0,0.1,0.1,0.2,0.1l14.6,4.8c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.3-0.5,0.2-0.7
	  c-0.8-3.3-1.7-7.3-1.7-7.3c0,0,0-0.1,0-0.1c-0.2-0.6-0.9-2.6-3.7-2.6l-12.1-0.1c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.1,0.7
	  c0.5,1.2,1,2.4,1.5,3.7C700.5,359.8,700.9,360.3,701.2,360.6z M711.5,356c1.5,0,2,0.8,2.2,1.6c0,0,0.7,3.1,1.4,6.1l-13.1-4.3
	  c-0.1-0.1-0.2-0.3-0.4-0.6c-0.4-1-0.7-1.9-1.1-2.8L711.5,356z"/>
			<path d="M700.4,363.5l17.1,5.7c0,0,0.3,0.1,0.6,0.1c0,0,0,0,0,0c0.7,0,1.3-0.3,1.7-0.8c0.5-0.7,0.6-1.7,0.3-3l-0.3-1.3
	  c-0.8-3.4-1.8-7.5-1.8-7.5c-0.3-1.7-2.1-5-6.6-5.1l-13.9-0.1c-0.7,0-1.3,0.3-1.7,1c-0.7,1-0.7,2.7,0,4.2c0.5,1.1,1,2.4,1.5,3.7
	  C698.1,361.8,699.2,363.1,700.4,363.5z M697.2,353.1c0.2-0.2,0.3-0.3,0.5-0.3h0l13.9,0.1c4.3,0,5.1,3.7,5.1,3.9c0,0,1,4.2,1.8,7.6
	  l0.3,1.3c0.3,1.2,0,1.6-0.1,1.8c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0l-17.1-5.7c-0.8-0.2-1.6-1.2-2-2.3
	  c-0.5-1.3-1-2.6-1.6-3.8C696.8,354.8,696.9,353.7,697.2,353.1z"/>
			<g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M479.7,1673.5l4.1-48.5c0.2-2.7,2.6-4.8,5.4-4.8h6.9
		  c1.3,0,2.5,0.5,3.3,1.4c0.8,0.9,1.2,2,1.1,3.3l-4.1,48.5c-0.2,2.7-2.6,4.8-5.4,4.8h-6.9c-1.3,0-2.5-0.5-3.3-1.4
		  C480,1675.9,479.6,1674.8,479.7,1673.5z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M493.2,1658.2h-7.8l1.4-16.7h7.8L493.2,1658.2z M488.3,1625.4
		  c0-0.3,0.5-0.8,0.9-0.8h6.9l-1.3,15.4H487L488.3,1625.4z M485.4,1659.7h7.8L492,1673c0,0.3-0.5,0.8-0.9,0.8h-6.9L485.4,1659.7z"/>
				<path d="M527.9,1587.8H601c0,0,0,0,0,0c0.1,0,0.3,0,0.7,0c1.6,0,4.6-0.4,7.2-2.7c2.7-2.4,4.1-6.1,4.1-11.2V1506
		  c0-0.9-0.1-5.6-2.2-8.5c-2.2-2.4-5.4-3.6-9.7-3.6h-67.8c-0.1,0-0.3,0-0.7,0c-0.7,0-6.8,0.1-11,4.1c-2.4,2.2-3.6,5.5-3.6,9.3h0
		  c-0.1,0.5-0.1,1.4-0.2,2.9l-3.9,64.3c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c-0.4,3.3,0.4,6.4,2.3,8.7
		  C518.5,1586.3,522.4,1587.8,527.9,1587.8z M515.3,1575.1c0-0.1,0-0.2,0.1-0.2c0-0.7,4-66.7,4.1-67.6c0,0,0,0,0-0.1
		  c0-3.4,1.1-6.3,3.1-8.3c3.8-3.6,9.3-3.7,10-3.7c0.2,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0H601c3.8,0,6.7,1.1,8.5,3.1
		  c1.9,2.5,1.9,7,1.9,7.6v67.9c0,4.6-1.2,8-3.6,10.1c-2.4,2.2-5.4,2.3-6.6,2.3c0,0-0.1,0-0.1,0h-73.2c-5,0-8.5-1.3-10.6-3.9
		  C515.7,1580.4,515,1577.9,515.3,1575.1z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M519.3,1696.7h82.4c3,0,5.5-2.7,5.5-6.1v-34.4c0-1.8-1.2-3.1-2.9-3.1
		  h-87.7c-1.6,0-2.9,1.4-2.9,3.1l0.1,34.4C513.8,1694,516.3,1696.7,519.3,1696.7z M516.6,1654.7h87.7c0.8,0,1.4,0.7,1.4,1.6v9.6
		  h-90.4l0-9.6C515.2,1655.3,515.9,1654.7,516.6,1654.7z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M602.3,1690.2c0,0.8-0.7,1.5-1.4,1.5h-80.1c-0.8,0-1.5-0.7-1.5-1.5
		  v-16.9c0-0.8,0.6-1.4,1.5-1.4h80.1c0.7,0,1.4,0.5,1.4,1.4V1690.2z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M465.2,1560.9h-11.3c-2.9,0-4.9,2.1-4.9,5v29.7c0,2.9,2.1,5,4.9,5
		  h11.3c2.9,0,5.1-2.2,5.1-5v-29.7C470.3,1563,468.1,1560.9,465.2,1560.9z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M667.6,1614.7v-2.5c0-1.7-1.4-3-3.1-3h-20c-1.7,0-3.1,1.4-3.1,3v2.3
		  h-6.6c-2.4,0-4.3,1.9-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3h6.6v3.5c0,1.6,1.4,3,3.1,3h20c1.7,0,3.1-1.3,3.1-3v-3.7c1.8-0.6,3-2.2,3-4.1
		  C670.6,1616.8,669.4,1615.2,667.6,1614.7z M666.7,1621.5c-0.1,0-0.3,0-0.4,0h-31.5c-1.5,0-2.8-1.3-2.8-2.8c0-1.5,1.3-2.8,2.8-2.8
		  h31.5c1.5,0,2.8,1.3,2.8,2.8C669.1,1620.1,668.1,1621.3,666.7,1621.5z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M800.8,1565.7c0-7.3-6.8-8-7-8c-0.5,0-12.3,0-16.5,0.5
		  c-3.7,0.5-4.3,4.1-4.1,6l-1,15.2l-9.9,0.3c-7.2-60.2-16.4-120.9-22-134.7c-5.3-12.9-17.5-17.2-21.7-16.8c-0.6,0-1.1,0.3-1.5,0.7
		  c-0.5,0.6-0.7,1.5-0.8,2.2c-9.6-1-78.6-1.8-93.1-1.9l0,0.8l0-0.8c-14.5,0.2-83.5,1-93.1,1.9c-0.1-0.7-0.2-1.6-0.8-2.2
		  c-0.4-0.4-0.9-0.7-1.5-0.7c-4.2-0.4-16.5,3.9-21.7,16.8c-5.6,13.9-14.9,74.6-22,134.7l-9.9-0.3l-1-15.2c0.2-1.9-0.4-5.4-4.2-6
		  c-4.2-0.5-15.9-0.4-16.5-0.5c-0.1,0-6.9,0.7-6.9,8c0,6.6,0.1,30.4,0.1,30.6c0,0.1-0.2,3.2,1.8,5.3c1.1,1.2,2.6,1.7,4.5,1.7H468
		  c0.2,0,0.9,0.1,1.7,0.1c1.2,0,2.7-0.2,3.7-1.2c0.9-0.9,1.2-2.3,0.9-4c-0.2-1.1-0.3-2-0.5-2.7l7.8,5.5c-0.5,4.7-1,9.4-1.5,14h-0.7
		  c-1.6,0-2.8,1.3-2.8,2.8v2.9c0,1.5,1.2,2.7,2.6,2.8c-3.6,36.1-5.8,67.2-5.1,81.3l0.7,0l-0.7,0.1c0.1,0.8,1.1,10.4,2.8,20.9v66.7
		  c0,3.1,2.9,5.5,6.7,5.5h28.1c3.8,0,6.7-2.4,6.7-5.5v-29.3l6.4-2.9h13.5v4c0,2,2.5,3.6,5.8,3.6c3.2,0,5.8-1.6,5.8-3.6v-4h73.5h98.5
		  l6.4,2.9v29.3c0,3.1,2.9,5.5,6.7,5.5h28.1c3.7,0,6.7-2.4,6.7-5.5v-66.7c1.7-10.5,2.7-20.1,2.8-20.9l-0.7-0.1l0.7,0
		  c0.7-14.2-1.5-45.4-5.1-81.6c1.4-0.1,2.6-1.3,2.6-2.8v-2.9c0-1.6-1.3-2.8-2.8-2.8h-0.6c-0.5-4.5-1-9.1-1.5-13.8l7.8-5.5
		  c-0.1,0.7-0.3,1.6-0.5,2.7c-0.3,1.7,0,3.1,0.9,4c1,1,2.5,1.2,3.7,1.2c0.8,0,1.4-0.1,1.7-0.1h15.8c1.9,0,3.4-0.6,4.5-1.7
		  c1.9-2,1.8-5.1,1.8-5.2C800.7,1596.1,800.8,1572.3,800.8,1565.7z M745.2,1720.7c0,3.4-2.7,6.1-6.1,6.1H623.3H507.6
		  c-3.4,0-6.1-2.7-6.1-6.1l0-1c0,0,0.1,0,0.1,0h121.7H745c0.1,0,0.1,0,0.1,0L745.2,1720.7z M504.2,1684.4c1,0,1.6,0.4,1.6,0.8v5.7
		  c0,0.3-0.6,0.8-1.6,0.8h-19.7c-0.9,0-1.5-0.5-1.5-0.8v-5.7c0-0.4,0.7-0.8,1.5-0.8H504.2z M504.2,1693.2c1.8,0,3.1-1,3.1-2.3v-5.7
		  c0-1.3-1.3-2.3-3.1-2.3h-2.6c0.9-38.1,4.6-94.4,9.2-140.6c1.4-0.2,2.4-1,2.4-2.1v-5c0-0.8-0.6-1.5-1.5-1.9
		  c4.4-41.4,9.1-68.8,13.2-77.2c2-4,3.2-4.7,3.7-4.8c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0.1,0.1,0.3,0.2,0.5,0.2h93.1v266.8H502.2
		  c-0.4-2.8-0.6-7-0.7-12.5l0,0h0c-0.1-3.6-0.1-7.8,0-12.5H504.2z M491.6,1535.2c0-0.2,0.5-0.7,1.3-0.7h17.4c0.9,0,1.5,0.5,1.5,0.7v5
		  c0,0.2-0.6,0.7-1.5,0.7h-17.4c-0.8,0-1.3-0.4-1.3-0.7V1535.2z M605.1,1441.8c-0.1,0.2-0.1,0.5-0.1,0.7v2.5c0,1.2,1,2.2,2.2,2.2
		  h32.1c1.2,0,2.2-1,2.2-2.2v-2.5c0-0.3-0.1-0.5-0.1-0.7H717l3.1,9.2c-0.7-0.7-1.3-1.1-2-1.2c-0.5-0.1-0.9,0-1.2,0.2h-93.6h-93.6
		  c-0.3-0.2-0.7-0.3-1.2-0.2c-0.6,0.1-1.3,0.5-2,1.2l3.1-9.2H605.1z M640,1442.5v2.5c0,0.4-0.3,0.7-0.7,0.7h-32.1
		  c-0.4,0-0.7-0.3-0.7-0.7v-2.5c0-0.4,0.3-0.7,0.7-0.7h16.1h16C639.7,1441.8,640,1442.1,640,1442.5z M742.4,1691.7
		  c-1,0-1.6-0.4-1.6-0.8v-5.7c0-0.4,0.7-0.8,1.6-0.8h19.7c0.8,0,1.5,0.4,1.5,0.8v5.7c0,0.3-0.6,0.8-1.5,0.8H742.4z M742.4,1682.9
		  c-1.8,0-3.1,1-3.1,2.3v5.7c0,1.3,1.3,2.3,3.1,2.3h2.7c0,4.7,0,8.9,0,12.5h0l0,0c-0.1,5.5-0.4,9.7-0.7,12.5H624.1v-266.8h93.1
		  c0.2,0,0.3,0,0.5-0.2c0,0,0.1-0.1,0.3,0c0.5,0.1,1.7,0.8,3.7,4.8c4.1,8.4,8.8,35.8,13.2,77.2c-0.9,0.4-1.5,1.1-1.5,1.9v5
		  c0,1.1,1,1.9,2.4,2.1c4.6,46.2,8.3,102.5,9.2,140.6H742.4z M736.3,1540.9c-0.9,0-1.5-0.4-1.5-0.7v-5c0-0.2,0.6-0.7,1.5-0.7h17.4
		  c0.8,0,1.3,0.5,1.3,0.7v5c0,0.3-0.6,0.7-1.3,0.7H736.3z M755,1533.3c-0.4-0.2-0.8-0.3-1.3-0.3h-17.4c-2.9-27.6-7.3-61.9-12.3-75.1
		  l0,0l-5.5-16.2h18.7c0.7,1.1,1.3,2.3,1.8,3.6C743.1,1455.6,749.3,1491.2,755,1533.3z M527.8,1429.5c0.2,0,0.3,0.1,0.4,0.2
		  c0.4,0.5,0.5,1.7,0.4,2c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.2,0.7,0.1c2.4-0.7,57.3-1.6,93.6-2c36.3,0.4,91.2,1.3,93.5,2
		  c0.2,0.1,0.5,0.1,0.8,0c0.2-0.2,0.3-0.4,0.3-0.7c0-0.5,0-1.6,0.4-2c0.1-0.1,0.3-0.2,0.5-0.2c2.4-0.2,11.6,2.2,17.5,10.8h-97h-16
		  h-16.1h-96.9C516.3,1431.7,525.4,1429.3,527.8,1429.5z M507.6,1445.4c0.5-1.3,1.1-2.5,1.8-3.6h18.7l-5.5,16.2l0,0
		  c-4.9,13.2-9.3,47.5-12.3,75.1h-17.4c-0.5,0-0.9,0.1-1.3,0.3C497.4,1491.2,503.5,1455.6,507.6,1445.4z M478.2,1620.8v-2.9
		  c0-0.7,0.6-1.3,1.3-1.3h0.5c-0.2,1.9-0.4,3.7-0.6,5.6C478.8,1622.1,478.2,1621.5,478.2,1620.8z M548.3,1756v6
		  c-1-0.7-2.6-1.1-4.3-1.1c-1.7,0-3.2,0.4-4.3,1.1v-6C542.6,1756,545.4,1756,548.3,1756z M544.1,1766.5c-2.5,0-4.3-1.1-4.3-2.1
		  c0-1,1.8-2.1,4.3-2.1c2.5,0,4.3,1.1,4.3,2.1C548.3,1765.4,546.6,1766.5,544.1,1766.5z M770.9,1704.8c-1.3,12.2-5.6,44.6-10.4,48.6
		  c-5.9,4.8-22.3,1.5-22.6,1.4c-6.1-0.6-110.1,0-114.6,0c-4.5,0-108.5-0.6-114.7,0c-0.2,0-16.6,3.4-22.5-1.4
		  c-4.8-4-9.2-36.4-10.4-48.6c-1.2-24.5,6.2-99.8,14.8-163.5c0.5,0.6,1.4,1,2.4,1h16.4c-4.6,46.2-8.3,102.5-9.2,140.6h-15.7
		  c-1.7,0-3,1-3,2.3v5.7c0,1.3,1.3,2.3,3,2.3H500c0,4.7,0,8.9,0,12.5l-0.1,14.9c0,4.2,3.4,7.6,7.6,7.6h115.7h115.7
		  c4.2,0,7.6-3.4,7.6-7.6l-0.1-14.9c0.1-3.6,0.1-7.8,0-12.5h15.5c1.7,0,3-1,3-2.3v-5.7c0-1.3-1.3-2.3-3-2.3h-15.7
		  c-0.9-38.1-4.6-94.3-9.2-140.6h16.4c1.1,0,1.9-0.4,2.4-1C764.7,1605.1,772.1,1680.4,770.9,1704.8z M767,1616.3
		  c0.7,0,1.3,0.6,1.3,1.3v2.9c0,0.7-0.5,1.3-1.2,1.3c-0.2-1.8-0.4-3.7-0.6-5.5H767z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M762.8,1625l4.1,48.5c0.1,1.2-0.3,2.4-1.1,3.3
		  c-0.8,0.9-2,1.4-3.3,1.4h-6.9c-2.7,0-5.1-2.2-5.4-4.8l-4.1-48.5c-0.1-1.2,0.3-2.4,1.1-3.3c0.8-0.9,2-1.4,3.3-1.4h6.9
		  C760.2,1620.2,762.6,1622.3,762.8,1625z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M761.2,1658.2h-7.8l-1.4-16.7h7.8L761.2,1658.2z M755.6,1673.8
		  c-0.4,0-0.9-0.4-0.9-0.8l-1.1-13.3h7.8l1.2,14.1H755.6z M758.4,1625.4l1.2,14.7h-7.8l-1.3-15.4h6.9
		  C757.9,1624.6,758.4,1625,758.4,1625.4z"/>
				<path d="M732.8,1574.4l-3.9-64.3c-0.1-1.5-0.1-2.3-0.2-2.9h0c0-3.9-1.3-7.1-3.6-9.3c-4.2-4-10.3-4.1-11-4.1c-0.3,0-0.5,0-0.7,0
		  h-67.8c-4.3,0-7.5,1.2-9.7,3.7c-2.1,2.9-2.2,7.5-2.2,8.5v67.9c0,5,1.4,8.8,4.1,11.2c2.5,2.4,5.6,2.7,7.2,2.7c0.3,0,0.6,0,0.7,0
		  h73.1c5.5,0,9.4-1.5,11.7-4.4c1.9-2.3,2.8-5.4,2.3-8.7C732.8,1574.5,732.8,1574.5,732.8,1574.4z M729.3,1582.4
		  c-2.1,2.6-5.6,3.9-10.6,3.9h-73.2c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.4,0c-1.4,0-4-0.3-6.2-2.3c-2.4-2.1-3.6-5.4-3.6-10V1506
		  c0-0.5,0-5,1.8-7.5c1.9-2.1,4.8-3.1,8.6-3.1h67.9c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c0.6,0,6.2,0.1,10,3.7c2.1,2,3.2,4.8,3.2,8.3
		  c0,0,0,0,0,0.1c0.1,0.9,4,66.9,4.1,67.6c0,0.1,0,0.2,0.1,0.2C731.7,1577.9,731,1580.4,729.3,1582.4z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M792.8,1560.9h-11.3c-2.9,0-5.1,2.2-5.1,5v29.7c0,2.9,2.2,5,5.1,5
		  h11.3c2.9,0,4.9-2.1,4.9-5v-29.7C797.7,1563,795.6,1560.9,792.8,1560.9z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M527,1737.6h-25c-1.1,0-2.1,0.9-2.1,2.1v1.5c0,1.1,0.9,2.1,2.1,2.1
		  h25c1.1,0,2.1-0.9,2.1-2.1v-1.5C529.1,1738.5,528.2,1737.6,527,1737.6z"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M744,1737.6h-25c-1.1,0-2.1,0.9-2.1,2.1v1.5c0,1.1,0.9,2.1,2.1,2.1
		  h25c1.1,0,2.1-0.9,2.1-2.1v-1.5C746.1,1738.5,745.2,1737.6,744,1737.6z"/>
			</g>
			<g>
				<path fill="#FFFFFF" d="M244.5,754.5c-2.8,0-5-2.2-5-5s2.2-5,5-5h19c2.8,0,5,2.2,5,5s-2.2,5-5,5H244.5z" />
				<path d="M263.5,745c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5h-19c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5H263.5 M263.5,744h-19
		  c-3,0-5.5,2.5-5.5,5.5s2.5,5.5,5.5,5.5h19c3,0,5.5-2.5,5.5-5.5S266.5,744,263.5,744L263.5,744z"/>
			</g>
			<g>
				<path fill="#FFFFFF" d="M242.5,700.5c-2.8,0-5-2.2-5-5v-19c0-2.8,2.2-5,5-5s5,2.2,5,5v19C247.5,698.3,245.3,700.5,242.5,700.5z" />
				<path d="M242.5,672c2.5,0,4.5,2,4.5,4.5v19c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5v-19C238,674,240,672,242.5,672 M242.5,671
		  c-3,0-5.5,2.5-5.5,5.5v19c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5v-19C248,673.5,245.5,671,242.5,671L242.5,671z"/>
			</g>
			<g>
				<path fill="#FFFFFF" d="M993.5,696.5c-2.8,0-5-2.2-5-5v-19c0-2.8,2.2-5,5-5s5,2.2,5,5v19C998.5,694.3,996.3,696.5,993.5,696.5z" />
				<path d="M993.5,668c2.5,0,4.5,2,4.5,4.5v19c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5v-19C989,670,991,668,993.5,668 M993.5,667
		  c-3,0-5.5,2.5-5.5,5.5v19c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5v-19C999,669.5,996.5,667,993.5,667L993.5,667z"/>
			</g>
			<g>
				<path fill="#FFFFFF" d="M973.5,751.5c-2.8,0-5-2.2-5-5s2.2-5,5-5h20c2.8,0,5,2.2,5,5s-2.2,5-5,5H973.5z" />
				<path d="M993.5,742c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5h-20c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5H993.5 M993.5,741h-20
		  c-3,0-5.5,2.5-5.5,5.5s2.5,5.5,5.5,5.5h20c3,0,5.5-2.5,5.5-5.5S996.5,741,993.5,741L993.5,741z"/>
			</g>
			<path id="path4209_1_" fill="none" stroke="#000000" stroke-width="0.75" d="M652.9,533.8l21.5,21.5h27.2h-74.7" />
			<path id="path4209-7_1_" fill="none" stroke="#000000" stroke-width="0.75" d="M572.1,533.4l21.5,21.5h27.2H546" />
			<line fill="none" stroke="#000000" stroke-width="0.75" x1="527.5" y1="210.5" x2="611.5" y2="203.5" />
			<line fill="none" stroke="#000000" stroke-width="0.75" x1="599.5" y1="211.5" x2="668.5" y2="206.5" />
			</>)
	}
}