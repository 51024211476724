import React from "react"
export const PickupSvg = {
    interior: {
      width:158,
      height:368,
      svg: (<>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M27,95.1c0,0-1-52.1,18-75.3c0,0,35-21,68,0c0,0,19,20.4,17.2,75.3
      C130.2,95.1,87.5,83.6,27,95.1z"/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M34.9,93.5c0,0-0.8-49.3,15.3-72c0,0,29.6-20.5,57.6,0
      c0,0,16.2,18.3,14.7,71.9C122.5,93.5,86.1,86.2,34.9,93.5z"/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M139.5,111.3c0.2-3.1,1.9-9.7,1.9-9.7V48.3c-2.6-14.5-13.4-23-13.4-23
      C105.8,7.8,78.8,8.9,78.8,8.9s-27.1-1-49.2,16.5c0,0-10.9,8.5-13.4,23v53.3c0,0,1.7,6.6,1.9,9.7l0.7,110.5v29.4l-1.9,5.4v53.1
      l3.9,11.1l1.3,26.5c0,1.1,0.2,2.2,0.7,3.2c1,2.4,3,5.7,8.6,6.2h47.5h46.7c0.5,0,1.1-0.1,1.6-0.3c6.1-2.9,6.6-8.5,6.6-8.5l2.2-25.9
      l4.9-12.3v-53.1l-1.9-5.4v-29.4L139.5,111.3z"/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M45.3,19.6c0,0-15.2,2.5-19.7,15.6c0,0-2.8,7.5,0,10.3
      c0,0,6.5-4.1,10-8.6C35.6,37,42.2,21.2,45.3,19.6z"/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M113,19.6c0,0,15.2,2.5,19.7,15.6c0,0,2.8,7.5,0,10.3
      c0,0-6.5-4.1-10-8.6C122.7,37,116.1,21.2,113,19.6z"/>
    <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="25.6,45.7 24.3,111.3 18,111.3 "/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M17.9,109.9c0,0,1.6-3.9,2.4-7.7V52.8c0,0-1.3-5.3-2.9-7.9"/>
    <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="132.6,45.7 133,111.3 139.3,111.3 "/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M139.4,109.9c0,0-1.6-3.9-2.4-7.7V52.8c0,0,1-4.5,3.4-8.3"/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M19.1,221.8c0,0,66.2-3.2,119.3,0"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M24.3,111.3c0,0,10.6,21.9,14.3,42.6l1,67.1"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M132.6,111.8c0,0-10.6,21.9-14.3,42.6l-1,66.7"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M35.1,212.9l0.4-35c0,0-3.1-27-3.5-31.7l-7.6-34.5v76.4v20.2
      c0,0-0.1,1.7,0.2,3.5c0.4,2.4,2.6,4,5,3.7C31,215.3,32.9,214.6,35.1,212.9z"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="35" y1="182.8" x2="24.2" y2="181.7"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="35" y1="188.7" x2="24.2" y2="188.7"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="35" y1="177.4" x2="24.2" y2="174"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="23.9" y1="181.4" x2="18.9" y2="181.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M122.3,213.9l-0.4-35c0,0,3.1-27,3.5-31.7l7.6-34.5v76.4v20.2
      c0,0,0.1,2.7-0.7,5.3c-0.3,1-1.1,1.7-2.1,1.8C128.5,216.8,125.4,216.7,122.3,213.9z"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="122.4" y1="183.8" x2="133.2" y2="182.7"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="122.4" y1="189.7" x2="133.2" y2="189.7"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="122.4" y1="178.4" x2="133.2" y2="175.1"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="133.4" y1="182.4" x2="138.4" y2="182.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M27.6,224.5v119.4c0,4.1,3.1,7.5,7,7.5h88.1c3.8,0,7-3.4,7-7.5V224.5
      H27.6z"/>
    <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M42.2,312H31.4c-1.8,0-3.3-1.5-3.3-3.3v-49.4
      c0-1.8,1.5-3.3,3.3-3.3h10.8c1.8,0,3.3,1.5,3.3,3.3v49.4C45.5,310.6,44,312,42.2,312z"/>
    <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M125.5,312h-10.8c-1.8,0-3.3-1.5-3.3-3.3v-49.4
      c0-1.8,1.5-3.3,3.3-3.3h10.8c1.8,0,3.3,1.5,3.3,3.3v49.4C128.8,310.6,127.3,312,125.5,312z"/>
    <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M138.4,251.7c0,0-3.6,5.1-3.7,7.5l-1,36.4v17
      c0,0,1.2,8,2.1,9.4"/>
    <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M18.1,250.5c0,0,3.6,5.1,3.7,7.5l1,36.4v17
      c0,0-1.2,8-2.1,9.4"/>
    <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M22.2,348.9c0,0-4.1,10,5.3,12.6c0.4,0.1,0.8,0.1,1.2,0.1h98.9
      c0.6,0,1.2-0.1,1.8-0.4c2.3-0.8,6.8-4.8,4-12"/>
    <path fill="#F9F9F9" stroke="#020202" stroke-miterlimit="10" d="M151.3,134.2c-0.4,2.9-11.3-1.3-16.8-4.3c-3-1.6-4.1-6.6-2.6-9.5
      c1.5-2.9,5.8-2.7,8.8-1.1C143.8,120.8,152.2,128.2,151.3,134.2z"/>
    <path fill="#F9F9F9" stroke="#020202" stroke-miterlimit="10" d="M4.7,134.2c0.4,2.9,11.3-1.3,16.8-4.3c3-1.6,4.1-6.6,2.6-9.5
      c-1.5-2.9-5.8-2.7-8.8-1.1S3.8,128.2,4.7,134.2z"/>
    <g id="Layer_39">
      <g>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M89.9,98.9h22.7c0,0,3.3,0.6,4.6,5.8l0.2,13c0,0-1.7,7.5-6.4,9.8
          H90.6c0,0-4.2-2.9-6-9.7v-13.3C84.6,104.5,86.4,99.6,89.9,98.9z"/>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M117.9,125.4l-0.5-0.8h-2.9l-1.9,1.9l-2.3,1.1l-9.5-0.1l0,0
          l-9.7,0.3l-2.1-1.5l-1.5-1.9l-3.2,0.2l-0.9,1.4l-0.5,3.8v11.6l0.4,3l1.2,2.1l1.5,1.3l2.3,1h3.2h1.4l0.1,0.8h7.8l0,0h7.8l0.1-0.8
          h1.4h3.2l2.3-1l1.5-1.3l1.2-2.1l0.4-3v-11.6l-0.4-3C118.4,126.3,118.2,125.9,117.9,125.4z"/>
        <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="100.9,141 91.2,141 91.2,148.7 93,150.4 100.9,150.4 
          108.9,150.4 110.7,148.7 110.7,141 		"/>
      </g>
      <g>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M45.8,99.7h22.7c0,0,3.3,0.6,4.6,5.8l0.2,13c0,0-1.7,7.5-6.4,9.8
          H46.5c0,0-4.2-2.9-6-9.7v-13.3C40.5,105.3,42.2,100.3,45.8,99.7z"/>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M73.8,126.2l-0.5-0.8h-2.9l-1.9,1.9l-2.3,1.1l-9.5-0.1l0,0l-9.7,0.3
          l-2.1-1.5l-1.5-1.9l-3.2,0.2l-0.9,1.4l-0.5,3.8v11.6l0.4,3l1.2,2.1l1.5,1.3l2.3,1h3.2h1.4l0.1,0.8h7.8l0,0h7.8l0.1-0.8H66h3.3
          l2.3-1l1.5-1.3l1.2-2.1l0.4-3v-11.6l-0.4-3C74.3,127.1,74.1,126.6,73.8,126.2z"/>
        <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="56.8,141.7 47.1,141.7 47.1,149.4 48.9,151.2 56.8,151.2 
          64.7,151.2 66.6,149.4 66.6,141.7 		"/>
      </g>
    </g>
    <g id="Layer_39_1_">
      <g>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M87.9,163.9h22.7c0,0,3.3,0.6,4.6,5.8l0.2,12.9c0,0-1.7,7.4-6.4,9.7
          H88.6c0,0-4.2-2.9-6-9.6v-13.2C82.6,169.5,84.4,164.6,87.9,163.9z"/>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M115.9,190.2l-0.5-0.8h-2.9l-1.9,1.9l-2.3,1.1l-9.5-0.1l0,0
          l-9.7,0.3l-2.1-1.5l-1.5-1.9l-3.2,0.2l-0.9,1.4l-0.5,3.8v11.5l0.4,3l1.2,2.1l1.5,1.3l2.3,1h3.2h1.4l0.1,0.8h7.8l0,0h7.8l0.1-0.8
          h1.4h3.2l2.3-1l1.5-1.3l1.2-2.1l0.4-3v-11.5l-0.4-3C116.4,191.1,116.2,190.7,115.9,190.2z"/>
        <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="98.9,205.7 89.2,205.7 89.2,213.3 91,215 98.9,215 
          106.9,215 108.7,213.3 108.7,205.7 		"/>
      </g>
      <g>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M47.8,164.7h22.7c0,0,3.3,0.6,4.6,5.8l0.2,13c0,0-1.7,7.5-6.4,9.8
          H48.5c0,0-4.2-2.9-6-9.7v-13.3C42.5,170.3,44.2,165.3,47.8,164.7z"/>
        <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M75.8,191.2l-0.5-0.8h-2.9l-1.9,1.9l-2.3,1.1l-9.5-0.1l0,0l-9.7,0.3
          l-2.1-1.5l-1.5-1.9l-3.2,0.2l-0.9,1.4l-0.5,3.8v11.6l0.4,3l1.2,2.1l1.5,1.3l2.3,1h3.2h1.4l0.1,0.8h7.8l0,0h7.8l0.1-0.8H68h3.3
          l2.3-1l1.5-1.3l1.2-2.1l0.4-3v-11.6l-0.4-3C76.3,192.1,76.1,191.6,75.8,191.2z"/>
        <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="58.8,206.7 49.1,206.7 49.1,214.4 50.9,216.2 58.8,216.2 
          66.7,216.2 68.6,214.4 68.6,206.7 		"/>
      </g>
    </g> 
    </>)
    }, 
    exterior: {
      width:405,
      height:619,
      svg: (<>
      <g id="Layer_1_1_">
      <path fill="none" stroke="#0B0B0B" stroke-width="0.75" stroke-miterlimit="10" d="M26.8,346.1V218.9c0-1.2,1-2.2,2.2-2.2l0,0
        c1.2,0,2.2,1,2.2,2.2v127.2c0,1.2-1,2.2-2.2,2.2l0,0C27.8,348.3,26.8,347.3,26.8,346.1z"/>
      <rect x="31.2" y="231.7" fill="none" stroke="#0B0B0B" stroke-width="0.75" stroke-miterlimit="10" width="3.1" height="6.1"/>
      <rect x="31.3" y="326.4" fill="none" stroke="#0B0B0B" stroke-width="0.75" stroke-miterlimit="10" width="3.1" height="6.1"/>
      <g>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="31" cy="179.1" r="27.1"/>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="31" cy="179.1" r="18.6"/>
        <circle fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="31.3" cy="179.1" r="2.2"/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="20.9,179.1 14.3,175.3 14.3,182.9 		
          "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="28.6,188.5 22.3,192.7 29.1,196.1 		
          "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="29.1,169.5 28.6,161.9 22.3,166.2 		
          "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="41,191.8 46,186.1 38.6,184.6 		"/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="46.2,171.4 40.5,166.3 38.9,173.7 		
          "/>
      </g>
      <g>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="31" cy="393.4" r="27.1"/>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="31" cy="393.4" r="18.6"/>
        <circle fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="31.3" cy="393.4" r="2.2"/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="20.9,393.4 14.3,389.6 14.3,397.2 		
          "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="28.6,402.9 22.3,407.1 29.1,410.4 		
          "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="29.1,383.8 28.6,376.3 22.3,380.5 		
          "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="41,406.1 46,400.5 38.6,398.9 		"/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="46.2,385.8 40.5,380.7 38.9,388.1 		
          "/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M32.5,146.4v-16.7l11.9-12.6h11.2L59,120
        l10.6,1.2c0,0,8.3,0.5,13.1,6.8c0,0,8.5,16.9,11.7,70.5c0,0-2.6,3.4-2,7.7c0,0,26,41.5,30,56.8c0,0,2.2,7,2.6,11.7v49.4
        c0,0,0.5,4.4-5.1,6.1H94.5L95,461.9c0,0,0.5,3.2-3.9,4.6H54.2c0,0-10.1,0.7-12.4-6l-1.2-24.2l-2-12.3c0,0,29.1-1.4,31.3-30
        c0,0,2.2-31.5-35.2-32.9v-149c0,0,32.2-1.4,32.7-32.3C67.3,179.8,69,147.8,32.5,146.4z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M56.8,466.6v-31.7c0,0,31.1-12.1,29.3-43.7
        c0,0-1.5-38.9-45.6-46.2V223.4c0,0,14.8-5.1,53.6-3.6c0,0,20.3,20.1,26.9,47.7l1,56.7c0,0-0.9,4.6-5.1,6.1c0,0-60.9,1.5-76.3-1.6"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M91.3,224.9l0.8,88.2c0,0,0.1,3,3.2,7.9
        c1.2,1.8,3.4,3,5,3.2c4.7,0.5,15.2,0.1,17.9-3.9v-44.6c0,0,1.2-21.4-23.2-50.3C95,225.4,92.3,224.4,91.3,224.9z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M92.9,227.8l0.7,56.1l22.9,2.5
        c0,0,0.2-12.8-2.2-20.8C114.3,265.6,104.9,236.1,92.9,227.8z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M116.5,297.3H94.2v16.4c0,0,0.8,5.3,5.2,8
        c0,0,13.2,0.6,17.1-2C116.5,319.7,116.5,297.3,116.5,297.3z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M80.1,282.2V270c0-0.9,0.8-1.7,1.7-1.7l0,0
        c0.9,0,1.7,0.8,1.7,1.7v12.3c0,0.9-0.8,1.7-1.7,1.7l0,0C80.9,283.9,80.1,283.2,80.1,282.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M118.2,291.5c0,0-53.8-1.9-77.6,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M39,122.8c0,0,9.1,0.7,10.6,5.6v10.2l2.4,1.1
        c0,0,4.5-2.2,6.8-10.9L59,120l-3.4-2.9H44.4L39,122.8z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M64.2,164.8v-15.1c0,0,2.1-6.1,4.9-6.6l3.3,7.6
        c0.2,0.4,1.7,2.9,2,3.3c2.3,3,6.8,12.7,6.8,22.2c0,0,1.7,22.3-16.3,35.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M78.2,124.3l0.4,22.1c0,0,3.4,7.7,4.6,12.6
        c0,0,4,19.3,4.4,33.5c0,0,0.5,8.6,4.7,13.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M47.9,138.6c0,0-0.8,1.4-4.3,1.5
        c0,0-2.1-0.2-3.6-0.9c-0.9-0.4-1.5-0.9-1.6-1.4v-7.9c0-0.6,0.2-1.2,0.6-1.6c1-0.9,3-2.2,5.6-0.9c0.3,0.1,0.5,0.3,0.7,0.6
        C46.2,129.1,48.8,132.4,47.9,138.6z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M32.5,146h-6.3v-13c0,0,2.2-2.8,6.3-3.2V146z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M54.2,466.6v5.7c0,0-0.3,1.4-2.1,1.6h-7
        c0,0-2.6,0-3.2-1.7L41.8,460l0,0C41.8,459.9,42.4,466.9,54.2,466.6z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M94.4,198.5c0,0,0.8,6.5,11.4,23.4
        c0,0,17.6,29.3,18.6,49.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M68,122.6l2.8,0.8c0.5,0.1,1,0.5,1.3,0.9
        c0.9,1.4,2.9,4.7,5.3,11.6c0,0,0.8,5.9,0,10l-2.7,6.1c-0.2,0.4-0.8,0.6-1,0.3l-4.4-8.8c0,0-2.4-7.2-2.3-20.2
        C67.1,122.8,67.5,122.5,68,122.6z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M86.2,466.6v-9.8c0,0-15.8,1.8-25.1,9.6
        L86.2,466.6z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M97.2,242.4c-2.9,0-5.2,3-5.2,0.1
        s2.3-6.2,5.2-6.2c2.9,0,5.2,3.3,5.2,6.2C102.4,245.4,100.1,242.4,97.2,242.4z"/>
      <path fill="#F7F6F8" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M92.6,238.7l-5.2-5.3c-0.5-0.5-0.5-1.3,0-1.8
        l0,0c0.5-0.5,1.3-0.5,1.8,0l5,5.1c0.5,0.5,0,0.8-0.5,1.3l0,0C93.2,238.5,93.1,239.2,92.6,238.7z"/>
      <path fill="none" stroke="#0B0B0B" stroke-width="0.75" stroke-miterlimit="10" d="M375,346.1V218.9c0-1.2-1-2.2-2.2-2.2l0,0
        c-1.2,0-2.2,1-2.2,2.2v127.2c0,1.2,1,2.2,2.2,2.2l0,0C374,348.3,375,347.3,375,346.1z"/>
      <rect x="367.5" y="231.7" fill="none" stroke="#0B0B0B" stroke-width="0.75" stroke-miterlimit="10" width="3.1" height="6.1"/>
      <rect x="367.4" y="326.4" fill="none" stroke="#0B0B0B" stroke-width="0.75" stroke-miterlimit="10" width="3.1" height="6.1"/>
      <g>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="370.8" cy="179.1" r="27.1"/>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="370.8" cy="179.1" r="18.6"/>
        <circle fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="370.5" cy="179.1" r="2.2"/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="380.9,179.1 387.4,175.3 387.4,182.9 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="373.2,188.5 379.5,192.7 372.6,196.1 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="372.6,169.5 373.2,161.9 379.5,166.2 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="360.8,191.8 355.7,186.1 363.2,184.6 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="355.6,171.4 361.2,166.3 362.8,173.7 
              "/>
      </g>
      <g>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="370.8" cy="393.4" r="27.1"/>
        <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="370.8" cy="393.4" r="18.6"/>
        <circle fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="370.5" cy="393.4" r="2.2"/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="380.9,393.4 387.4,389.6 387.4,397.2 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="373.2,402.9 379.5,407.1 372.6,410.4 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="372.6,383.8 373.2,376.3 379.5,380.5 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="360.8,406.1 355.7,400.5 363.2,398.9 
              "/>
        <polygon fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="355.6,385.8 361.2,380.7 362.8,388.1 
              "/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M369.3,146.4v-16.7l-11.9-12.6h-11.2l-3.4,2.9
        l-10.6,1.2c0,0-8.3,0.5-13.1,6.8c0,0-8.5,16.9-11.7,70.5c0,0,2.6,3.4,2,7.7c0,0-26,41.5-30,56.8c0,0-2.2,7-2.6,11.7v49.4
        c0,0-0.5,4.4,5.1,6.1h25.4L306.8,462c0,0-0.5,3.2,3.9,4.6h36.8c0,0,10.1,0.7,12.4-6l1.2-24.2l2-12.3c0,0-29.1-1.4-31.3-30
        c0,0-2.2-31.5,35.2-32.9V212.1c0,0-32.2-1.4-32.7-32.3C334.4,179.8,332.7,147.8,369.3,146.4z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M345,466.6v-31.7c0,0-31.1-12.1-29.3-43.7
        c0,0,1.5-38.9,45.6-46.2V223.4c0,0-14.8-5.1-53.6-3.6c0,0-20.3,20.1-26.9,47.7l-1,56.7c0,0,0.9,4.6,5.1,6.1c0,0,60.9,1.5,76.3-1.6"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M310.5,224.9l-0.8,88.2c0,0-0.1,3-3.2,7.9
        c-1.2,1.8-3.4,3-5,3.2c-4.7,0.5-15.2,0.1-17.9-3.9v-44.6c0,0-1.2-21.4,23.2-50.3C306.8,225.4,309.4,224.4,310.5,224.9z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M308.8,227.8l-0.7,56.1l-22.9,2.5
        c0,0-0.2-12.8,2.2-20.8C287.5,265.6,296.8,236.1,308.8,227.8z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M285.2,297.3h22.3v16.4c0,0-0.8,5.3-5.2,8
        c0,0-13.2,0.6-17.1-2L285.2,297.3L285.2,297.3z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M321.7,282.2V270c0-0.9-0.8-1.7-1.7-1.7l0,0
        c-0.9,0-1.7,0.8-1.7,1.7v12.3c0,0.9,0.8,1.7,1.7,1.7l0,0C320.9,283.9,321.7,283.2,321.7,282.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M283.6,291.5c0,0,53.8-1.9,77.6,0.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M362.7,122.8c0,0-9.1,0.7-10.6,5.6v10.2
        l-2.4,1.1c0,0-4.5-2.2-6.8-10.9l-0.2-8.9l3.4-2.9h11.2L362.7,122.8z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M337.6,164.8v-15.1c0,0-2.1-6.1-4.9-6.6
        l-3.3,7.6c-0.2,0.4-1.7,2.9-2,3.3c-2.3,3-6.8,12.7-6.8,22.2c0,0-1.7,22.3,16.3,35.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M323.5,124.3l-0.4,22.1c0,0-3.4,7.7-4.6,12.6
        c0,0-4,19.3-4.4,33.5c0,0-0.5,8.6-4.7,13.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M353.9,138.6c0,0,0.8,1.4,4.3,1.5
        c0,0,2.1-0.2,3.6-0.9c0.9-0.4,1.5-0.9,1.6-1.4v-7.9c0-0.6-0.2-1.2-0.6-1.6c-1-0.9-3-2.2-5.6-0.9c-0.3,0.1-0.5,0.3-0.7,0.6
        C355.5,129.1,352.9,132.4,353.9,138.6z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M369.3,146h6.3v-13c0,0-2.2-2.8-6.3-3.2V146z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M347.5,466.6v5.7c0,0,0.3,1.4,2.1,1.6h7
        c0,0,2.6,0,3.2-1.7l0.1-12.2l0,0C359.9,459.9,359.4,466.9,347.5,466.6z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M307.4,198.5c0,0-0.8,6.5-11.4,23.4
        c0,0-17.6,29.3-18.6,49.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M333.8,122.6l-2.8,0.8c-0.5,0.1-1,0.5-1.3,0.9
        c-0.9,1.4-2.9,4.7-5.3,11.6c0,0-0.8,5.9,0,10l2.7,6.1c0.2,0.4,0.8,0.6,1,0.3l4.4-8.8c0,0,2.4-7.2,2.3-20.2
        C334.7,122.8,334.2,122.5,333.8,122.6z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M315.6,466.6v-9.8c0,0,15.8,1.8,25.1,9.6
        L315.6,466.6z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M304.6,242.4c2.9,0,5.2,3,5.2,0.1
        s-2.3-6.2-5.2-6.2c-2.9,0-5.2,3.3-5.2,6.2C299.4,245.4,301.7,242.4,304.6,242.4z"/>
      <path fill="#F7F6F8" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M309.1,238.7l5.2-5.3c0.5-0.5,0.5-1.3,0-1.8
        l0,0c-0.5-0.5-1.3-0.5-1.8,0l-5,5.1c-0.5,0.5,0,0.8,0.5,1.3l0,0C308.5,238.5,308.6,239.2,309.1,238.7z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M150.3,99.1c0,0-1.9-0.6-3.2-2.2
        c-1.1-1.4-2.9-4.6-3.7-6.3c-0.4-0.8-1.3-3.3-2.1-5.7v37.9c0,2.3,1.9,4.2,4.2,4.2h12.9c2.3,0,4.2-1.9,4.2-4.2v-17h-4.1
        C153,103.9,150.3,99.1,150.3,99.1z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M254.3,99c0,0,2-0.2,3.5-2.1
        c1.1-1.4,2.9-4.3,3.7-6.3c0.3-0.8,1.3-3.3,2.1-5.7v37.9c0,2.3-1.9,4.2-4.2,4.2h-12.9c-2.3,0-4.2-1.9-4.2-4.2v-17h4.1
        C249.2,105.9,254.3,99,254.3,99z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M243.1,49.2c-0.2,0-4.2,0.6-4.4,0.6l-33.1,1.1
        c-0.5,0-27.2-0.4-39.5-1.8c-0.5-0.1-2.6-0.3-3.1-0.5l-3.5-0.9c-0.3-0.1-0.6-0.2-1-0.3c-5.3-2.3-8.6-9.7-8.6-10.9L161.3,10
        c1.3-1.7,3.4-3.9,4.7-3.9h72.9c1.2,0,5.2,3.8,7.4,7.5L255,36C255,37.3,253.7,46.5,243.1,49.2z"/>
      <path fill="#F7F6F8" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M246.9,105.9h-88.4c-3.9-0.8-8.4-6.8-7.5-6.8
        l102.9,0.3C254.9,99.3,249.4,105.9,246.9,105.9z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M252,38.4l-49.4-0.7l-49.4,0.7
        c-0.8,0,11-23.9,11-23.9c0.3-1.1,1.2-1.5,2.7-1.5h70.6c0.8,0,2.1,0.5,2.9,1.6C240.5,14.7,252.8,38.4,252,38.4z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M255,36l5.9,7.2c0.3,0.4,1.2,2.1,1.6,3
        c0.2,0.3,0.8,1.8,0.9,2.2l1.3,3.6c0.3,1.1,0.8,4.8,0.7,6l0.1,20.3c0,0.4,0,0.8-0.1,1.2c-0.5,2.5-2.8,11.7-7.4,17.5
        c-1.1,1.3-2.7,2.1-4.4,2.1h-55.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M150.1,36l-5.9,7.2c-0.3,0.4-1.2,2.1-1.6,3
        c-0.2,0.3-0.8,1.8-0.9,2.2l-1.3,3.6c-0.3,1.1-0.8,4.8-0.7,6l-0.1,20.3c0,0.4,0,0.8,0.1,1.2c0.5,2.5,2.8,11.7,7.4,17.5
        c1.1,1.3,2.7,2.1,4.4,2.1h55.2"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M230.7,90.3l-56,0.2c-1,0-2.2-4.7-2.2-4.7
          c0-1,0.1-1.8,1.1-1.8h58.2c1,0,1.1,0.8,1.1,1.8C232.9,85.8,231.7,90.3,230.7,90.3z"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M202.7,94.1h-33.1c0,0-2.4-1.8-2.6-4.2
          c0,0-2.3-8.5-12.9-9c0,0-6.7-0.5-7.1-1.2c0,0-0.5-2.4,8.3-3.6h47.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M202.7,94.1h33.1c0,0,2.4-1.8,2.6-4.2
          c0,0,2.3-8.5,12.9-9c0,0,6.7-0.5,7.1-1.2c0,0,0.5-2.4-8.3-3.6h-47.4"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M216.7,71.9h-28.9c-1.7,0-19.1-3-20.3-5.7v-2.6
        c0-1.7,13.3-8.5,15.1-8.5H223c2.1,0,11.6,3.7,14.9,8.7v2.9C237.8,68.3,222.7,71.9,216.7,71.9z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M262.6,47.1l-5.4,6.3l-3.3,7.7
        c0,0,1.5,3.8,3.9,5.1c0,0,3.3,1.4,7.3,2.1v-11c0-1.8-0.2-2.9-0.3-3.5C264.5,52.3,264,50.3,262.6,47.1z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M142.3,47l5.4,6.3L151,61c0,0-1.5,3.8-3.9,5.1
        c0,0-3.3,1.4-7.3,2.1v-11c0-1.8,0.2-2.9,0.3-3.5C140.3,52.2,140.9,50.2,142.3,47z"/>
      <path fill="#F4EFF0" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M156.9,62.3c0,0,5.7,0.1,13.8-4.2
        c1.1-0.6,1.4-2,0.6-2.9l0,0c0,0-7.5-2.6-14.3-2.9c0,0-2.7-0.4-6.4-0.3c-2.3,0.1-4.1,2-3.9,4.3c0,0.6,0.1,1.1,0.3,1.6
        c0.1,0.2,0.2,0.4,0.4,0.6C148.1,59.3,151,62.1,156.9,62.3z"/>
      <path fill="#F7F6F8" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M248.7,62.4c0,0-5.7,0.1-13.8-4.2
        c-1.1-0.6-1.4-2-0.6-2.9l0,0c0,0,7.5-2.6,14.3-2.9c0,0,2.7-0.4,6.4-0.3c2.3,0.1,4.1,2,3.9,4.3c0,0.6-0.1,1.1-0.3,1.6
        c-0.1,0.2-0.2,0.4-0.4,0.6C257.6,59.5,254.6,62.3,248.7,62.4z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M159.2,85.8c-0.5,0-0.9,0.1-1.3,0.2
        c-0.8-1.5-2.3-2.5-4-2.5c-2.5,0-4.6,2-4.6,4.6s2,4.6,4.6,4.6c1.5,0,2.8-0.7,3.7-1.9c0.5,0.2,1.1,0.3,1.7,0.3c1.9,0,3.4-1.2,3.4-2.6
        C162.6,86.9,161,85.8,159.2,85.8z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M246.4,85.8c0.5,0,0.9,0.1,1.3,0.2
        c0.8-1.5,2.3-2.5,4-2.5c2.5,0,4.6,2,4.6,4.6s-2,4.6-4.6,4.6c-1.5,0-2.8-0.7-3.7-1.9c-0.5,0.2-1.1,0.3-1.7,0.3
        c-1.9,0-3.4-1.2-3.4-2.6C243,86.9,244.5,85.8,246.4,85.8z"/>
      <g>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="139.4" cy="34.9" rx="8.9" ry="5.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M142.6,46.1c0,0-0.8-4.5-2.4-5.2
          c0,0,4.4,0.1,7.2-3.5l0.6,1.1C148.1,38.4,143.9,43.2,142.6,46.1z"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="265.4" cy="34.9" rx="8.9" ry="5.9"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M262.1,46.1c0,0,0.8-4.5,2.4-5.2
          c0,0-4.4,0.1-7.2-3.5l-0.6,1.1C256.6,38.4,260.9,43.2,262.1,46.1z"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M255.1,522.4H147.4c-1,0,0.5-1.1,0.5-2l10.2-21
          c1.9-3.1,4.9-6.6,7.2-6.6h72.5c1,0,3.7,1.2,7.1,6.6l9.6,20.7C254.5,520.9,256.1,522.4,255.1,522.4z"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M246.6,520.8H156c-0.7,0-1.1-0.4-1.1-1.1
          l9.4-21.2c0-0.7,0.6-1.4,1.4-1.4H236c1.4,0,1.4-0.1,2.2,1.1l9.4,20.7C247.6,519.7,248.6,520.5,246.6,520.8z"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M208.9,496h-16c-0.3,0-0.5-0.2-0.5-0.5v-1.2
          c0-0.3,0.2-0.5,0.5-0.5h16c0.3,0,0.5,0.2,0.5,0.5v1.2C209.4,495.8,209.2,496,208.9,496z"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="165.7" y1="497.1" x2="165.7" y2="492.3"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="236.7" y1="497.4" x2="236.7" y2="492.5"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M256,522.4c0,0,4.7,10.8,5.1,13
          c0,0,1.8,3.1,2.7,3.6v14.9l-3.9,19.4c0,0-1.1,4-3.9,4.6H147c0,0-3.1-1.8-4.8-6.5c0,0-4-14.7-4-15.6c-0.1-0.9,0-16.9,0-16.9
          l9.1-16.5H256z"/>
        <rect x="154.9" y="522.4" fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" width="93.5" height="38.6"/>
        <line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="248.5" y1="546.1" x2="154.9" y2="546.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M154.9,531.8v24.6c0,0-7.8-11.6-10-24.6H154.9z
          "/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M248.5,531.8v24.6c0,0,7.8-11.6,10-24.6H248.5z
          "/>
        <polyline fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="141.4,533.2 145.8,561.1 257.3,561.1 
          261.1,535.4 		"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M206.1,534.8h-10.2c-0.8,0-1.4-0.6-1.4-1.4v-2
          c0-0.8,0.6-1.4,1.4-1.4h10.2c0.8,0,1.4,0.6,1.4,1.4v2C207.4,534.1,206.8,534.8,206.1,534.8z"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M224.6,574.7l3.8-10.9h29c0,0,1.5-0.3,1.5,1.4
          v9.7c0,0-0.3,2.2-2.9,3h-55h-54c0,0-3.1-2.2-4.1-4.9v-8c0,0-0.4-1.7,1.3-1.7h30.3l3.7,11.1L224.6,574.7z"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M161.4,577.9c0,0,4.5,4.7,0,8.6h-17.8l-2.2-1.5
          l-0.4-18.5c0,0,1.1,8.9,6.1,11.4H161.4z"/>
        <path fill="none" stroke="#020202" stroke-width="0.75" stroke-miterlimit="10" d="M241.1,577.9c0,0-4.6,4.5,0,8.4h17.8l2.2-1.5
          v-18.5c0,0,0,9.2-5,11.7L241.1,577.9z"/>
        <path fill="none" stroke="#221F1F" stroke-width="0.75" stroke-miterlimit="10" d="M160.7,586.8v20.6c0,0-0.1,3.5-3.6,4.7h-14.6
          c0,0-3.5-0.8-3.5-4.9v-49.3l1.9,8.6l0.4,18.5l2.2,1.5L160.7,586.8z"/>
        <path fill="none" stroke="#221F1F" stroke-width="0.75" stroke-miterlimit="10" d="M241.6,586.4v21c0,0,0.1,3.5,3.6,4.7h14.6
          c0,0,3.5-0.8,3.5-4.9v-49.3l-1.9,8.6L261,585l-2.2,1.5L241.6,586.4z"/>
        <path fill="none" stroke="#221F1F" stroke-width="0.75" stroke-miterlimit="10" d="M241.6,587.2H161v4.9h32.1c0,0.1,0,0.3,0,0.4
          c0,2.8,3.9,5,8.7,5s8.7-2.3,8.7-5c0-0.1,0-0.3,0-0.4h31.1V587.2z"/>
        <g>
          <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="265.2" cy="520.7" rx="8.3" ry="5.9"/>
          <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="265.2" cy="520.7" rx="6.5" ry="4.7"/>
          <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M261.1,535.1c0,0,1.2-7.6,2.1-8.7
            c0,0-3.2-0.5-5.4-3.1l-1,1.1L261.1,535.1z"/>
        </g>
        <g>
          <ellipse fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="137.6" cy="520.9" rx="8.3" ry="5.9"/>
          <ellipse fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="137.6" cy="520.9" rx="6.5" ry="4.7"/>
          <path fill="none" stroke="#020202" stroke-width="0.5" stroke-miterlimit="10" d="M141.7,535.3c0,0-1.2-7.6-2.1-8.7
            c0,0,3.2-0.5,5.4-3.1l1,1.1L141.7,535.3z"/>
        </g>
        <g>
          <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M234.1,588.3h-8.4c-2.7,0-5-2.2-5-5l0,0
            c0-2.7,2.2-5,5-5h8.4c2.7,0,5,2.2,5,5l0,0C239.1,586.1,236.8,588.3,234.1,588.3z"/>
          <circle fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" cx="230" cy="583.3" r="3.2"/>
        </g>
      </g>
    </g>
    <g id="Layer_2_1_">
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M150.7,209.3c0,0,56.5-11.5,103.3,0c0,0-12.3,46-13.3,51.1
        c0,0-41-6.7-76.1,0C164.6,260.4,154.7,215.6,150.7,209.3z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M150.7,209.3c0,0-1-52.1,18-75.3c0,0,35-21,68,0
        c0,0,19,20.4,17.2,75.3C254,209.3,211.2,197.8,150.7,209.3z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M158.7,207.8c0,0-0.8-49.3,15.3-72c0,0,29.6-20.5,57.6,0
        c0,0,16.2,18.3,14.7,71.9C246.3,207.7,209.8,200.4,158.7,207.8z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M263.3,225.5c0.2-3.1,1.9-9.7,1.9-9.7v-53.3
        c-2.6-14.5-13.4-23-13.4-23C229.7,122,202.6,123,202.6,123s-27.1-1-49.2,16.5c0,0-10.9,8.5-13.4,23v53.3c0,0,1.7,6.6,1.9,9.7
        l0.7,110.5v29.4l-1.9,5.4V424l3.9,11.1l1.3,26.5c0,1.1,0.2,2.2,0.7,3.2c1,2.4,3,5.7,8.6,6.2h47.5h46.7c0.5,0,1.1-0.1,1.6-0.3
        c6.1-2.9,6.6-8.5,6.6-8.5l2.2-25.9l4.9-12.3v-53.1l-1.9-5.4V336L263.3,225.5z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M169,133.8c0,0-15.2,2.5-19.7,15.6c0,0-2.8,7.5,0,10.3
        c0,0,6.5-4.1,10-8.6C159.4,151.2,165.9,135.4,169,133.8z"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M236.8,133.8c0,0,15.2,2.5,19.7,15.6c0,0,2.8,7.5,0,10.3
        c0,0-6.5-4.1-10-8.6C246.4,151.2,239.9,135.4,236.8,133.8z"/>
      <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="149.4,159.9 148.1,225.5 141.8,225.5 	"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M141.6,224.1c0,0,1.6-3.9,2.4-7.7V167c0,0-1.3-5.3-2.9-7.9"/>
      <polyline fill="none" stroke="#020202" stroke-miterlimit="10" points="256.4,159.9 256.7,225.5 263,225.5 	"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M263.2,224.1c0,0-1.6-3.9-2.4-7.7V167c0,0,1-4.5,3.4-8.3"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M142.8,336c0,0,66.2-3.2,119.3,0"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M164.6,260.4c0,0,3.9,33.5,2.4,74.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M240.7,260.4c0,0-4.4,33.7-2.8,74.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M148.1,225.5c0,0,10.6,21.9,14.3,42.6l1,67.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M256.4,226c0,0-10.6,21.9-14.3,42.6l-1,66.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M158.8,327.1l0.4-35c0,0-3.1-27-3.5-31.7l-7.6-34.5v76.4v20.2
        c0,0-0.1,1.7,0.2,3.5c0.4,2.4,2.6,4,5,3.7C154.8,329.5,156.7,328.8,158.8,327.1z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="158.7" y1="297" x2="147.9" y2="295.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="158.7" y1="302.9" x2="147.9" y2="302.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="158.7" y1="291.6" x2="147.9" y2="288.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="147.6" y1="295.6" x2="142.7" y2="295.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M246,328.1l-0.4-35c0,0,3.1-27,3.5-31.7l7.6-34.5v76.4v20.2
        c0,0,0.1,2.7-0.7,5.3c-0.3,1-1.1,1.7-2.1,1.8C252.2,331,249.2,330.9,246,328.1z"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="246.1" y1="298" x2="256.9" y2="296.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="246.1" y1="303.9" x2="256.9" y2="303.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="246.1" y1="292.6" x2="256.9" y2="289.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="257.2" y1="296.6" x2="262.1" y2="296.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M151.3,338.7v119.4c0,4.1,3.1,7.5,7,7.5h88.1c3.8,0,7-3.4,7-7.5
        V338.7H151.3z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M165.9,426.2h-10.8c-1.8,0-3.3-1.5-3.3-3.3
        v-49.4c0-1.8,1.5-3.3,3.3-3.3h10.8c1.8,0,3.3,1.5,3.3,3.3v49.4C169.2,424.8,167.8,426.2,165.9,426.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M249.2,426.2h-10.8c-1.8,0-3.3-1.5-3.3-3.3
        v-49.4c0-1.8,1.5-3.3,3.3-3.3h10.8c1.8,0,3.3,1.5,3.3,3.3v49.4C252.5,424.8,251,426.2,249.2,426.2z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M262.1,365.9c0,0-3.6,5.1-3.7,7.5l-1,36.4v17
        c0,0,1.2,8,2.1,9.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M141.9,364.7c0,0,3.6,5.1,3.7,7.5l1,36.4v17
        c0,0-1.2,8-2.1,9.4"/>
      <path fill="none" stroke="#020202" stroke-miterlimit="10" d="M145.9,463.1c0,0-4.1,10,5.3,12.6c0.4,0.1,0.8,0.1,1.2,0.1h98.9
        c0.6,0,1.2-0.1,1.8-0.4c2.3-0.8,6.8-4.8,4-12"/>
      <path fill="#F9F9F9" stroke="#020202" stroke-miterlimit="10" d="M275.1,248.4c-0.4,2.9-11.3-1.3-16.8-4.3c-3-1.6-4.1-6.6-2.6-9.5
        s5.8-2.7,8.8-1.1C267.5,235,275.9,242.4,275.1,248.4z"/>
      <path fill="#F9F9F9" stroke="#020202" stroke-miterlimit="10" d="M128.4,248.4c0.4,2.9,11.3-1.3,16.8-4.3c3-1.6,4.1-6.6,2.6-9.5
        s-5.8-2.7-8.8-1.1C136,235,127.6,242.4,128.4,248.4z"/>
    </g>
    </>)
    }
  }