import React, { useEffect, useState } from "react";
import { Dialog, Slide, IconButton, Typography, DialogContent, Grid, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles } from "@material-ui/core/styles";
import { updateAuthStateAction } from "../../../../actions/auth/actions";
import { AuthStates } from "../../../../reducers/auth/types";
import { useDispatch } from "react-redux";
import { NewCustomerInfo } from "./Component/NewCustomerInfo";


const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(2),
    borderBottom: "1px solid #ccc",
  },
  stepper: {
    flexGrow: 1,
  },
}));

export const SignUpBusinessCustomer = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    handleClickOpen()
  }, [])

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))
    setOpen(false);
  };

  return (
    <>
      {!isRegistered ? (
        <Dialog maxWidth="lg" open={open} onClose={handleClose} TransitionComponent={Transition} >
          <div className={classes.dialog}>
            <div className={classes.header}>
              <Typography variant="h1" color="primary">
                &nbsp;Create Account
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: 'black' }} />
              </IconButton>
            </div>
          </div>
          <DialogContent style={{ padding: 20 }}>
            <NewCustomerInfo onSubmit={() => setIsRegistered(true)} />
          </DialogContent>
        </Dialog>
      ) : (
        <div style={{ maxWidth: 400, margin: "auto", padding: 16 }}>
          <Grid container style={{ padding: "0 48px", marginTop: 16 }}>
            <Grid item xs={12}>
              <h2 style={{ textAlign: "center", padding: 0, margin: 0 }}>Thank you</h2>
            </Grid>
          </Grid>
          <div style={{ padding: "1 10%" }}>
            <p style={{}}>We have emailed you on how to get started.</p>
            <p style={{}}>If you do not see the email in a few minutes, check your "Junk mail" or "spam folder".</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#3A3A3A",
                marginTop: 20,
                boxShadow: "none",
                fontSize: 12,
                marginBottom: 16
              }}
              onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))}
            >
              Sign In
            </Button>
          </div>
        </div>
      )}</>
  );
};
