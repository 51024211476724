import { SaloonSvg } from "./svgs/saloon"
import { VanSvg } from "./svgs/van"
import { PickupSvg } from "./svgs/pickup"
import { SUVSvg } from './svgs/suv'
import { CampervanSVG } from "./svgs/campervan"
import { Van7TonneSVG } from "./svgs/van-7-tonne"
import { Hatchback3DoorSVG } from "./svgs/hatchback3Door"
import { Mini3DoorSVG } from "./svgs/mini3Door"
import { VanLutonSvg } from "./svgs/vanLuton"
import { VanLargePanelSvg } from "./svgs/vanLargePanel"
import { CDVSVG } from "./svgs/cdv"
import { ConvertableSVG } from "./svgs/convertable"
import { EstateSVG } from "./svgs/estate"
import { Hatchback5DoorSVG } from "./svgs/hatchback5Door"
import { Van7SeaterSVG } from "./svgs/van-7-seater"
import { Van9SeaterSVG } from "./svgs/van-9-seater"
import { Van18TonneSVG } from "./svgs/van-18-tonne"
import { VanMidSizeSVG } from "./svgs/van-mid-size"
import { Van26TonneSVG } from "./svgs/van-26-tonne"
import { CoupeSVG } from "./svgs/coupe"
import { Van41TonneSVG } from "./svgs/van-41-tonne"
import { TipperVanSVG } from './svgs/tipper-van'
import { TipperTruckSVG } from './svgs/tipper-truck'
import { MPVSVG } from "./svgs/mpv"
import { SafariSVG } from "./svgs/safari"
import { MPV7SeaterSVG } from "./svgs/mpv-7-seater"
import { Van7Seater3WindowsSVG } from "./svgs/van-7-seater-3-windows"
import { VanFlatBedSVG } from "./svgs/van-flat-bed"
import { VanFlatBedBoxedSVG } from "./svgs/van-flat-bed-boxed"
import { Estate4x4Svg } from "./svgs/estate-4x4-5-seater"
import { Estate4x4_7SeaterSvg } from "./svgs/estate-4x4-7-seater"
import { LightUtility4x4Svg } from "./svgs/light-utility-4x4"
import { LightUtility4x4_2SeaterSvg } from "./svgs/light-utility-4x4-2-seater"
import { TruckmanTop4DoorSVG } from "./svgs/truckman-top-4-door"
import { TruckmanWithoutTop4DoorSVG } from "./svgs/truckman-without-top-4-door"
import { HGVTrailerSVG } from './svgs/hgv-trailer';
import { ScooterSvg } from "./svgs/scooter";
import { SwbVanSVG } from "./svgs/swb-van";
import { MiniBusSVG } from "./svgs/minibus"
import { LutonVan3Seater2Door } from "./svgs/luton-van3-seater";
import {ATV} from "./svgs/atv";
import { flatbedTrailerSvg } from "./svgs/flat-bed-trailer";
import { stepFrameTrailerSvg } from "./svgs/step-frame-trailer";
import { straightFrametrailerSvg } from "./svgs/straight-frame-trailer";

export enum FrogDiagramType {
  EXTERIOR = "EXTERIOR",
  INTERIOR = "INTERIOR",
}

export enum DamagesApprovalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING'
}

export function predictFrogDiagram(bodyType: string) {
  try {
    const BodyType = bodyType.toUpperCase()
    if (BodyType === VehicleBodyTypes.PANEL_VAN || BodyType.includes('VAN')) {
      return VehicleBodyTypes.PANEL_VAN
    }
    if (BodyType === VehicleBodyTypes.SUV) {
      return VehicleBodyTypes.SUV
    }
    if (BodyType.includes('TRUCK')) {
      return VehicleBodyTypes.PICK_UP_TRUCK
    }
    else {
      return VehicleBodyTypes.SALOON
    }
  } catch (err) {
    console.error(err)
  }
}


export enum VehicleBodyTypes {
  // Current Vehicles
  SUV = 'SUV',
  SEDAN='SEDAN',
  CAMPERVAN='CAMPERVAN',
  SALOON = 'SALOON',
  PANEL_VAN = 'PANEL VAN',
  LARGE_PANEL_VAN = 'LARGE_PANEL_VAN',
  VAN_7_TONNE = 'VAN_7_TONNE',
  LUTON_VAN = 'LUTON_VAN',
  PICK_UP_TRUCK = 'PICK_UP_TRUCK',
  HATCHBACK_3_DOOR ='HATCHBACK_3_DOOR',
  MINI_3_DOOR = 'MINI_3_DOOR',
  HATCHBACK_5_DOOR ='HATCHBACK_5_DOOR',
  CONVERTABLE_3_DOOR='CONVERTABLE_3_DOOR',
  VAN_9_SEATER='VAN_9_SEATER',
  VAN_7_SEATER='VAN_7_SEATER',
  ESTATE='ESTATE',
  CDV='CDV',
  VAN_18_TONNE='VAN_18_TONNE',
  VAN_MIDSIZE='VAN_MIDSIZE',
  VAN_26_TONNE='VAN_26_TONNE', 
  COUPE='COUPE',
  VAN_41_TONNE='VAN_41_TONNE',
  TIPPER_TRUCK='TIPPER_TRUCK',
  TIPPER_VAN='TIPPER_VAN',
  SAFARI='SAFARI',
  MPV='MPV',
  MPV_7_SEATER='MPV_7_SEATER',
  VAN_7_SEATER_3_WINDOWS='VAN_7_SEATER_3_WINDOWS',
  VAN_FLAT_BED='VAN_FLAT_BED',
  VAN_FLAT_BED_BOXED='VAN_FLAT_BED_BOXED',
  LIGHT_UTILITY_4X4_2_SEATER='LIGHT_UTILITY_4X4_2_SEATER',
  LIGHT_UTILITY_4X4='LIGHT_UTILITY_4X4',
  ESTATE_4X4_7_SEATER='ESTATE_4X4_7_SEATER',
  ESTATE_4X4='ESTATE_4X4',
  TRUCKMAN_TOP_4_DOOR='TRUCKMAN_TOP_4_DOOR',
  TRUCKMAN_WITHOUT_TOP_4_DOOR='TRUCKMAN_WITHOUT_TOP_4_DOOR',
  HGV_TRAILER='HGV_TRAILER',
  SCOOTER='SCOOTER',
  SWB_VAN='SWB_VAN',
  MINIBUS = "MINIBUS",
  LUTON_3_SEATER = 'LUTON_3_SEATER',
  ATV = 'ATV',
  FLATBED_TRAILER = 'FLATBED_TRAILER',
  STEPFRAME_TRAILER = 'STEPFRAME_TRAILER',
  STRAIGHT_FRAME_TRAILER = 'STRAIGHT_FRAME_TRAILER'
} 
      
export const FrogDiagram: { [key in VehicleBodyTypes]: IFrogDiagram }  = {
  [VehicleBodyTypes.MINIBUS]: {
    interior: {
      ...MiniBusSVG.interior,
      markerRadius: 10,
      markerFont: 0.8,
    },
    exterior: {
      ...MiniBusSVG.exterior,
      markerRadius: 12,
      markerFont: 1.2,
    }
  },
  [VehicleBodyTypes.SWB_VAN]: {
    exterior: {
      ...SwbVanSVG.exterior,  
      markerRadius: 12,
      markerFont: 1.2,
    },
    interior: {
      ...SwbVanSVG.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  },
  [VehicleBodyTypes.LIGHT_UTILITY_4X4]: {
    exterior: {
      ...LightUtility4x4Svg.exterior,
      markerRadius: 12,
      markerFont:1.2,
    },
    interior: {
      ...LightUtility4x4Svg.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  }, 
  [VehicleBodyTypes.LIGHT_UTILITY_4X4_2_SEATER]: {
    exterior: {
      ...LightUtility4x4_2SeaterSvg.exterior,
      markerRadius: 12,
      markerFont:1.2,
    },
    interior: {
      ...LightUtility4x4_2SeaterSvg.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  }, 
  [VehicleBodyTypes.ESTATE_4X4_7_SEATER]: {
    exterior: {
      ...Estate4x4_7SeaterSvg.exterior,
      markerRadius: 12,
      markerFont:1.2,
    },
    interior: {
      ...Estate4x4_7SeaterSvg.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  }, 
  [VehicleBodyTypes.ESTATE_4X4]: {
    exterior: {
      ...Estate4x4Svg.exterior,
      markerRadius: 12,
      markerFont:1.2,
    },
    interior: {
      ...Estate4x4Svg.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  }, 
  [VehicleBodyTypes.SALOON]: {
    exterior: {
      ...SaloonSvg.exterior,
      markerRadius: 6,
      markerFont:0.5,
    },
    interior: {
      ...SaloonSvg.interior,
      markerRadius: 12,
      markerFont:1,
    }
  }, 
  [VehicleBodyTypes.SEDAN]: {
    exterior: {
      ...SaloonSvg.exterior,
      markerRadius: 6,
      markerFont:0.5,
    },
    interior: {
      ...SaloonSvg.interior,
      markerRadius: 12,
      markerFont:1,
    }
  }, 
  [VehicleBodyTypes.PANEL_VAN]: {
    exterior: {
      ...VanSvg.exterior,
      markerRadius: 10,
      markerFont:1,
    },
    interior: {
      ...VanSvg.interior,
      markerRadius: 6,
      markerFont:0.5,
    }
  }, 
  [VehicleBodyTypes.CDV]: {
    exterior: {
      ...CDVSVG.exterior,
      markerRadius: 10,
      markerFont:1,
    },
    interior: {
      ...CDVSVG.interior,
      markerRadius: 6,
      markerFont:0.5,
    }
  }, 
  [VehicleBodyTypes.VAN_7_TONNE]: {
    exterior: {
      ...Van7TonneSVG.exterior,
      markerRadius: 24,
      markerFont:2,
    },
    interior: {
      ...Van7TonneSVG.interior,
      markerRadius: 16,
      markerFont:1.5,
    }
  },
  [VehicleBodyTypes.VAN_18_TONNE]: {
    exterior: {
      ...Van18TonneSVG.exterior,
      markerRadius: 24,
      markerFont:2,
    },
    interior: {
      ...Van18TonneSVG.interior,
      markerRadius: 16,
      markerFont:1.5,
    }
  },
  [VehicleBodyTypes.VAN_MIDSIZE]: {
    exterior: {
      ...VanMidSizeSVG.exterior,
      markerRadius: 24,
      markerFont:2,
    },
    interior: {
      ...VanMidSizeSVG.interior,
      markerRadius: 16,
      markerFont:1.5,
    }
  },
  [VehicleBodyTypes.CONVERTABLE_3_DOOR]:{
    interior: {
      ...ConvertableSVG.interior,
      markerRadius: 14,
      markerFont:1.5,
    },
    exterior: {
      ...ConvertableSVG.exterior,
      markerRadius: 24,
      markerFont:2.5,
    },
  }, 
  [VehicleBodyTypes.MPV]:{
    interior: {
      ...MPVSVG.interior,
      markerRadius: 14,
      markerFont:1.5,
    },
    exterior: {
      ...MPVSVG.exterior,
      markerRadius: 24,
      markerFont:2.5,
    },
  }, 
  [VehicleBodyTypes.MPV_7_SEATER]:{
    interior: {
      ...MPV7SeaterSVG.interior,
      markerRadius: 14,
      markerFont:1.5,
    },
    exterior: {
      ...MPV7SeaterSVG.exterior,
      markerRadius: 24,
      markerFont:2.5,
    },
  }, 
  [VehicleBodyTypes.HATCHBACK_5_DOOR]: {
    interior: {
      ...Hatchback5DoorSVG.interior,
      markerRadius: 16,
      markerFont:1.5,
     
    },
    exterior: {
      ...Hatchback5DoorSVG.exterior,
      markerRadius: 28,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.ESTATE]: {
    interior: {
      ...EstateSVG.interior,
      markerRadius: 16,
      markerFont:1.5,
     
    },
    exterior: {
      ...EstateSVG.exterior,
      markerRadius: 28,
      markerFont:2.5,
    },
  },  
  [VehicleBodyTypes.VAN_9_SEATER]: {
    interior: {
      ...Van9SeaterSVG.interior,
      markerRadius: 14,
      markerFont:1.5,

    },
    exterior: {
      ...Van9SeaterSVG.exterior,
      markerRadius: 30,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.VAN_7_SEATER]: {
    interior: {
      ...Van7SeaterSVG.interior,
      markerRadius: 14,
      markerFont:1.5,

    },
    exterior: {
      ...Van7SeaterSVG.exterior,
      markerRadius: 30,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.VAN_7_SEATER_3_WINDOWS]: {
    interior: {
      ...Van7Seater3WindowsSVG.interior,
      markerRadius: 14,
      markerFont:1.5,

    },
    exterior: {
      ...Van7Seater3WindowsSVG.exterior,
      markerRadius: 30,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.LARGE_PANEL_VAN]: {
    exterior: {
      ...VanLargePanelSvg.exterior,
      markerRadius: 24,
      markerFont:2,
    },
    interior: {
      ...VanLargePanelSvg.interior,
      markerRadius: 16,
      markerFont:1.5,
    }
  },
  [VehicleBodyTypes.LUTON_VAN]: {
    exterior: {
      ...VanLutonSvg.exterior,
      markerRadius: 12,
      markerFont:1.2,
    },
    interior: {
      ...VanLutonSvg.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  },
  [VehicleBodyTypes.LUTON_3_SEATER]: {
    exterior: {
      ...LutonVan3Seater2Door.exterior,  
      markerRadius: 12,
      markerFont: 1.2,
    },
    interior: {
      ...LutonVan3Seater2Door.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  },
  [VehicleBodyTypes.ATV]: {
    exterior: {
      ...ATV.exterior,  
      markerRadius: 12,
      markerFont: 1.2,
    },
    interior: {
      ...ATV.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  },
  [VehicleBodyTypes.PICK_UP_TRUCK]: {
    exterior: {
      ...PickupSvg.exterior,
      markerRadius: 12,
      markerFont:1.2,
    },
    interior: {
      ...PickupSvg.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  },
  [VehicleBodyTypes.SUV]: {
    exterior: {
      ...SUVSvg.exterior,
      markerRadius: 24,
      markerFont:2,
    },
    interior: {
      ...SUVSvg.interior,  
      markerRadius: 16,
      markerFont:1.5,
    }
  },
  [VehicleBodyTypes.HATCHBACK_3_DOOR]: {
    exterior: {
      ...Hatchback3DoorSVG.exterior,
      markerRadius: 12,
      markerFont:1,
    },
    interior: {
      ...Hatchback3DoorSVG.interior,
      markerRadius: 12,
      markerFont:1,
    }
  },
  [VehicleBodyTypes.MINI_3_DOOR]: {
    exterior: {
      ...Mini3DoorSVG.exterior,
      markerRadius: 24,
      markerFont:2,
    },
    interior: {
      ...Mini3DoorSVG.interior,
      markerRadius: 16,
      markerFont:1.5,
    }
  },
  [VehicleBodyTypes.CAMPERVAN]: {
    exterior: {
      ...CampervanSVG.exterior,
      markerRadius: 16,
      markerFont:1.5,
    },
    interior: {
      ...CampervanSVG.interior,
      markerRadius: 10,
      markerFont:1,
    }
  },

  [VehicleBodyTypes.VAN_26_TONNE]: {
    exterior: {
      ...Van26TonneSVG.exterior,
      markerRadius: 24,
      markerFont:2,
    },
    interior: {
      ...Van26TonneSVG.interior,
      markerRadius: 16,
      markerFont:1.5,
    }
  },
  [VehicleBodyTypes.COUPE]: {
    interior: {
      ...CoupeSVG.interior,
      markerRadius: 14,
      markerFont:1.5,
    },
    exterior: {
      ...CoupeSVG.exterior,
      markerRadius: 24,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.VAN_41_TONNE]: {
    exterior: {
      ...Van41TonneSVG.exterior,
      markerRadius: 24,
      markerFont: 2,
    },
    interior: {
      ...Van41TonneSVG.interior,
      markerRadius: 16,
      markerFont: 1.5,
    }
  },
  [VehicleBodyTypes.TIPPER_VAN]: {
    interior: {
      ...TipperVanSVG.interior,
      markerRadius: 6,
      markerFont:0.5,

    },
    exterior: {
      ...TipperVanSVG.exterior,
      markerRadius: 12,
      markerFont: 1,
    },
  },
  [VehicleBodyTypes.VAN_FLAT_BED]: {
    interior: {
      ...VanFlatBedSVG.interior,
      markerRadius: 6,
      markerFont:0.5,
    },
    exterior: {
      ...VanFlatBedSVG.exterior,
      markerRadius: 12,
      markerFont: 1,
    },
  },
  [VehicleBodyTypes.VAN_FLAT_BED_BOXED]: {
    interior: {
      ...VanFlatBedBoxedSVG.interior,
      markerRadius:6,
      markerFont:0.5,
    },
    exterior: {
      ...VanFlatBedBoxedSVG.exterior,
      markerRadius: 12,
      markerFont: 1,
    },
  },
  [VehicleBodyTypes.TIPPER_TRUCK]: {
    interior: {
      ...TipperTruckSVG.interior,
      markerRadius: 14,
      markerFont:1.5,

    },
    exterior: {
      ...TipperTruckSVG.exterior,
      markerRadius: 30,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.SAFARI]: {
    interior: {
      ...SafariSVG.interior,
      markerRadius: 14,
      markerFont:1.5,

    },
    exterior: {
      ...SafariSVG.exterior,
      markerRadius: 30,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.TRUCKMAN_TOP_4_DOOR]: {
    interior: {
      ...TruckmanTop4DoorSVG.interior,
      markerRadius: 16,
      markerFont: 1.5,

    },
    exterior: {
      ...TruckmanTop4DoorSVG.exterior,
      markerRadius: 24,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.TRUCKMAN_WITHOUT_TOP_4_DOOR]: {
    interior: {
      ...TruckmanWithoutTop4DoorSVG.interior,
      markerRadius: 16,
      markerFont: 1.5,
    },
    exterior: {
      ...TruckmanWithoutTop4DoorSVG.exterior,
      markerRadius: 24,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.HGV_TRAILER]: {
    interior: {
      ...HGVTrailerSVG.interior,
      markerRadius: 16,
      markerFont: 1.5,
    },
    exterior: {
      ...HGVTrailerSVG.exterior,
      markerRadius: 24,
      markerFont:2.5,
    },
  },
  [VehicleBodyTypes.SCOOTER]: {
    exterior: {
      ...ScooterSvg.exterior,
      markerRadius: 12,
      markerFont:1.2,
    },
    interior: {
      ...ScooterSvg.interior,
      markerRadius: 10,
      markerFont:0.8,
    }
  },
  [VehicleBodyTypes.FLATBED_TRAILER]: {
    interior: {
      ...flatbedTrailerSvg.interior,
      markerRadius: 12,
      markerFont: 1.2,
    },
    exterior: {
      ...flatbedTrailerSvg.exterior,
      markerRadius: 10,
      markerFont:0.8,
    },
  },
  [VehicleBodyTypes.STEPFRAME_TRAILER]: {
    interior: {
      ...stepFrameTrailerSvg.interior,
      markerRadius: 12,
      markerFont: 1.2,
    },
    exterior: {
      ...stepFrameTrailerSvg.exterior,
      markerRadius: 10,
      markerFont: 0.8,
    },
  },
  [VehicleBodyTypes.STRAIGHT_FRAME_TRAILER]: {
    interior: {
      ...straightFrametrailerSvg.interior,
      markerRadius: 12,
      markerFont: 1.2,
    },
    exterior: {
      ...straightFrametrailerSvg.exterior,
      markerRadius: 10,
      markerFont: 0.8,
    },
  }
}

export interface IFrogDiagram {
  interior: IVehicleDiagramConsts;
  exterior: IVehicleDiagramConsts;
}


interface IVehicleDiagramConsts {
  width: number;
  height: number;
  markerRadius: number;
  markerFont: number;
  svg: JSX.Element;
}

export function shouldPushDamage(vehicle: any, damage: any, damageType: string, showSvg?: boolean): boolean {

  if (damage?.approvalStatus === DamagesApprovalStatus.PENDING && !showSvg) {
    return false;
  }

  const damageMatchesFrogDiagram = () => {
    if (damage?.frogDiagram) {
      if (vehicle?.frogDiagram === damage.frogDiagram) {
        return true;
      } else {
        return false;
      }
    } else {
      if (vehicle?.frogDiagram === VehicleBodyTypes.SALOON) {
        return true;
      } else {
        return false;
      }
    }

  }

  if (damage?.location?.x !== 0 && damage?.location?.y !== 0) {

    if (damageType === FrogDiagramType.EXTERIOR) {
      if (damage.damageType === FrogDiagramType.EXTERIOR && damage.damageKind) {
        return damageMatchesFrogDiagram();
      } else {
        return false
      }
    } else {
      if (damage.damageType === FrogDiagramType.INTERIOR && damage.damageArea) {
        return damageMatchesFrogDiagram();
      } else {
        return false;
      }

    }
  } else {
    return false
  }
}
