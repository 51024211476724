import React from "react"
export const CDVSVG = {
  interior: {
    width: 155.2,
    height: 297.7,
    svg: (<><g id="top_view">
    <g id="bonnet">
      <path fill="none" stroke="#080707" stroke-miterlimit="10" d="M132.6,83.8c0,0,2.1-26.9,0.9-40.5c0,0-7.6-17.5-24.7-21.7
        l-32.2-2.3h0.8l-32.2,2.3c-17.1,4.2-24.7,21.7-24.7,21.7c-1.2,13.6,1,40.9,1,40.9s3-10.7,17-13.8c0,0,28-10,77,0
        C115.6,70.4,124.5,73.1,132.6,83.8z"/>
    </g>
    <g id="wind_shield">
      <path fill="none" stroke="#080707" stroke-width="0.5" stroke-miterlimit="10" d="M76.2,127.3"/>
      <path fill="none" stroke="#080707" stroke-width="0.5" stroke-miterlimit="10" d="M28.8,127.3l-7.4-41.7c0,0,4.6-6.7,10.7-8.3
        c24.3-6.2,53.4-7.4,87.8,0.1c0,0,7.3,2.8,12.7,6.4l-10,43.5"/>
    </g>
    <g id="Layer_4">
    </g>
    <g id="roof">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M74.8,139.9"/>
      <polyline fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" points="122.7,127.3 122.7,276 29.6,276 
        29.6,127.3 		"/>
    </g>
    <g id="front_right_lamp">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M109.1,21.6c0,0,0,10.5,2,14.3
        c1.8,3.4,11.7,14.6,13.8,16.5c2,1.9,3.6,0.2,5.5-0.8c0,0,2.6-3.1,2.2-8.5C132.6,43,124.5,25.9,109.1,21.6z"/>
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M82.8,28.5H69.6c-1.8,0-1.1-1.5-1.1-3.2v0
        c0-1.8,1.5-3.2,3.2-3.2h9.1c1.8,0,3.2,1.5,3.2,3.2v0C84.1,27,84.6,28.5,82.8,28.5z"/>
    </g>
    <g id="front-left-lamp">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M43.8,22c0,0,0,10.5-2,14.3
        C39.9,39.6,30,50.9,28,52.8S24.3,53,22.5,52c0,0-2.6-3.1-2.2-8.5C20.3,43.4,28.3,26.2,43.8,22z"/>
    </g>
    <g id="body_outline">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M137.3,274.7V31.6l-8.4-11l-14.5-10.4l-6.6-2
        H78.3h-3.2H45.6l-6.6,2L24.5,20.6l-8.4,11v243.1c0,0-0.7,10.1,8.4,11.2l52.5,3.2h4l47.8-3.2C138,285.5,137.3,274.7,137.3,274.7z"
        />
    </g>
    <g id="bacl_light">
      <g>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M28.7,284.7c-2.4,2.4-7.5,1.3-9.8-1.3
          c-2.3-2.6-3.5-6.7-1.1-9.1"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="17.6" y1="274.1" x2="28.7" y2="285"/>
      </g>
      <g>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M124.9,284.7c2.4,2.4,7.5,1.3,9.8-1.3
          c2.3-2.6,3.5-6.7,1.1-9.1"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="135.9" y1="274.1" x2="124.8" y2="285"/>
      </g>
    </g>
    <g id="right_side_panels">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M137.3,162.5c0,0-3.1,6-8.6,6
        c0,0-5.6,0-5.6-3.8v-36.5l6.6-32.6l7.6-2.4L137.3,162.5z"/>
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M137.1,172.5c0,0-7.3,2.2-14,1.5v52
        c0,0,1.1,7,14,6V172.5z"/>
    </g>
    <g id="left_side_panels_copy">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M16.1,162.5c0,0,2.9,6,8,6c0,0,5.2,0,5.2-3.8
        v-36.5l-6.1-32.6l-7.1-2.4L16.1,162.5z"/>
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M16.5,172.5c0,0,6.7,2.2,12.8,1.5v52
        c0,0-1,7-12.8,6V172.5z"/>
    </g>
    <g id="right_side_mirror">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M137.7,107.3l10.8,6.5c0,0,0.9-2.8,0-5.2
        c0,0-2.7-5-10.8-7.1V107.3z"/>
    </g>
    <g id="left_side_mirror_copy">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M16.1,106.6l-10.8,6.5c0,0-0.9-2.8,0-5.2
        c0,0,2.7-5,10.8-7.1V106.6z"/>
    </g>
  </g>
  <g id="tyre">
  </g>
  <g id="vehicle">
    <g id="Layer_17">
    </g>
  </g>
  <g id="vehicle_back">
    <g id="back_frame">
    </g>
    <g id="back_glass">
    </g>
    <g id="Layer_21">
    </g>
    <g id="Layer_22">
    </g>
    <g id="Layer_23">
    </g>
    <g id="Layer_24">
    </g>
    <g id="Layer_25">
    </g>
  </g>
  <g id="vehicle_front">
    <g id="front_outer_frame">
    </g>
    <g id="front_glass">
    </g>
    <g id="Layer_29">
    </g>
    <g id="bonnet_1_">
    </g>
    <g id="front_right_light">
    </g>
    <g id="front_right_light_copy">
    </g>
    <g id="front_grill">
    </g>
    <g id="Layer_34">
    </g>
    <g id="Layer_35">
    </g>
    <g id="Layer_36">
    </g>
    <g id="Layer_37">
    </g>
    <g id="Layer_38">
    </g>
  </g>
  <g id="Layer_39">
    <g>
      <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M88.6,83.9h22.7c0,0,3.3,0.6,4.6,5.8l0.2,13c0,0-1.7,7.5-6.4,9.8
        H89.3c0,0-4.2-2.9-6-9.7V89.5C83.3,89.5,85.1,84.6,88.6,83.9z"/>
      <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M116.6,110.4l-0.5-0.8h-2.9l-1.9,1.9l-2.3,1.1l-9.5-0.1v0l-9.7,0.3
        l-2.1-1.5l-1.5-1.9l-3.2,0.2l-0.9,1.4l-0.5,3.8v11.6l0.4,3l1.2,2.1l1.5,1.3l2.3,1h3.2h1.4l0.1,0.8h7.8v0h7.8l0.1-0.8h1.4h3.2
        l2.3-1l1.5-1.3l1.2-2.1l0.4-3v-11.6l-0.4-3C117.1,111.3,116.9,110.9,116.6,110.4z"/>
      <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="99.6,126 89.9,126 89.9,133.7 91.7,135.4 99.6,135.4 
        107.6,135.4 109.4,133.7 109.4,126 		"/>
    </g>
    <g>
      <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M44.5,84.7h22.7c0,0,3.3,0.6,4.6,5.8l0.2,13c0,0-1.7,7.5-6.4,9.8
        H45.2c0,0-4.2-2.9-6-9.7V90.3C39.2,90.3,40.9,85.3,44.5,84.7z"/>
      <path fill="none" stroke="#010101" stroke-miterlimit="10" d="M72.5,111.2l-0.5-0.8h-2.9l-1.9,1.9l-2.3,1.1l-9.5-0.1v0l-9.7,0.3
        l-2.1-1.5l-1.5-1.9l-3.2,0.2l-0.9,1.4l-0.5,3.8v11.6l0.4,3l1.2,2.1l1.5,1.3l2.3,1h3.2h1.4l0.1,0.8h7.8v0h7.8l0.1-0.8h1.4H68l2.3-1
        l1.5-1.3l1.2-2.1l0.4-3v-11.6l-0.4-3C73,112.1,72.8,111.6,72.5,111.2z"/>
      <polygon fill="none" stroke="#010101" stroke-miterlimit="10" points="55.5,126.7 45.8,126.7 45.8,134.4 47.6,136.2 55.5,136.2 
        63.4,136.2 65.3,134.4 65.3,126.7 		"/>
    </g>
  </g></>)
  },

  exterior: {
    width: 409,
    height: 560.2,
    svg: (<><g id="top_view">
    <g id="bonnet">
      <path fill="none" stroke="#080707" stroke-miterlimit="10" d="M262.7,210.3c0,0,2.1-26.9,0.9-40.5c0,0-7.6-17.5-24.7-21.7
        l-32.2-2.3h0.8l-32.2,2.3c-17.1,4.2-24.7,21.7-24.7,21.7c-1.2,13.6,1,40.9,1,40.9s3-10.7,17-13.8c0,0,28-10,77,0
        C245.7,196.9,254.6,199.5,262.7,210.3z"/>
    </g>
    <g id="wind_shield">
      <path fill="none" stroke="#080707" stroke-width="0.5" stroke-miterlimit="10" d="M151.5,212.1c0,0,4.6-6.7,10.7-8.3
        c24.3-6.2,53.4-7.4,87.8,0.1c0,0,7.3,2.8,12.7,6.4l-10,43.5h-93.8L151.5,212.1z"/>
    </g>
    <g id="Layer_4">
    </g>
    <g id="roof">
      
        <rect x="159.4" y="253.8" fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" width="93.1" height="149.8"/>
    </g>
    <g id="front_right_lamp">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M239.1,148.1c0,0,0,10.5,2,14.3
        c1.8,3.4,11.7,14.6,13.8,16.5c2,1.9,3.6,0.2,5.5-0.8c0,0,2.6-3.1,2.2-8.5C262.7,169.5,254.6,152.3,239.1,148.1z"/>
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M212.9,154.9h-13.1c-1.8,0-1.1-1.5-1.1-3.2v0
        c0-1.8,1.5-3.2,3.2-3.2h9.1c1.8,0,3.2,1.5,3.2,3.2v0C214.1,153.5,214.6,154.9,212.9,154.9z"/>
    </g>
    <g id="front-left-lamp">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M173.9,148.4c0,0,0,10.5-2,14.3
        c-1.8,3.4-11.7,14.6-13.8,16.5c-2,1.9-3.6,0.2-5.5-0.8c0,0-2.6-3.1-2.2-8.5C150.3,169.9,158.4,152.7,173.9,148.4z"/>
    </g>
    <g id="body_outline">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M267.4,401.1V158l-8.4-11l-14.5-10.4l-6.6-2
        h-29.5h-3.2h-29.5l-6.6,2L154.6,147l-8.4,11v243.1c0,0-0.7,10.1,8.4,11.2l52.5,3.2h4l47.8-3.2C268,411.9,267.4,401.1,267.4,401.1z
        "/>
    </g>
    <g id="bacl_light">
      <g>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M158.8,411.2c-2.4,2.4-7.5,1.3-9.8-1.3
          c-2.3-2.6-3.5-6.7-1.1-9.1"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="147.7" y1="400.6" x2="158.8" y2="411.5"/>
      </g>
      <g>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M254.9,411.2c2.4,2.4,7.5,1.3,9.8-1.3
          c2.3-2.6,3.5-6.7,1.1-9.1"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="266" y1="400.6" x2="254.9" y2="411.5"/>
      </g>
    </g>
    <g id="right_side_panels">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M267.4,288.9c0,0-3.1,6-8.6,6
        c0,0-5.6,0-5.6-3.8v-36.5l6.6-32.6l7.6-2.4L267.4,288.9z"/>
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M267.1,299c0,0-7.3,2.2-14,1.5v52
        c0,0,1.1,7,14,6V299z"/>
    </g>
    <g id="left_side_panels_copy">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M146.2,288.9c0,0,2.9,6,8,6c0,0,5.2,0,5.2-3.8
        v-36.5l-6.1-32.6l-7.1-2.4L146.2,288.9z"/>
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M146.5,299c0,0,6.7,2.2,12.8,1.5v52
        c0,0-1,7-12.8,6V299z"/>
    </g>
    <g id="right_side_mirror">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M267.8,233.8l10.8,6.5c0,0,0.9-2.8,0-5.2
        c0,0-2.7-5-10.8-7.1V233.8z"/>
    </g>
    <g id="left_side_mirror_copy">
      <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M146.1,233.1l-10.8,6.5c0,0-0.9-2.8,0-5.2
        c0,0,2.7-5,10.8-7.1V233.1z"/>
    </g>
  </g>
  <g id="tyre">
  </g>
  <g id="vehicle">
    <g id="Layer_17">
      <g>
        <g>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="27.9" cy="191.9" r="22"/>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="27.9" cy="191.9" r="14"/>
        </g>
        <g>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="27.9" cy="377.8" r="22"/>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="27.9" cy="377.8" r="14"/>
        </g>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M21.3,165.1l1.9-22.4c0,0,3.1-4.1,6.6-4.6
          c0,0,21.4-1.2,29.6,1.7l6,8.7c0,0,9,4.8,13.1,14.8c0,0,3.6,8.9,4.9,18.4l3.6,24.1c0,0,21.8,21.9,36.4,52.7c0,0,4.8,12,6.8,29.6
          c0,0,2.7,87,0,109.6c0,0,0.5,9.7-7.8,13.1c0,0-24.7,3.4-26.7,3.6c0,0-44.6,3.1-55.8,2.4l-1.4,2.7h-5.3l-7.3-6.8v-4.4
          c0,0-1.5-2.9,9.4-3.1c0,0,16.3-3.2,22.1-17.9c0,0,8.2-29.6-24.2-36.6h-8.7V220.8c0,0,17.7-1.4,26.2-10.2c0,0,15.8-18.9,0-35.4
          c0,0-7.1-9.5-20.3-10.1H21.3z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M57.6,361.8h66.6c0,0,3.6-2.4,2.9-8.9l-1-61.6
          c0,0-1.7-18.6-8-34.2c0,0-13.1-24.9-21.8-33.2l-3.9-1H36c0,0-7,0.7-8.1,2.2L30,347C30,347,49.8,347.6,57.6,361.8z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M49.8,353.4c10.1,6.5,16.8,23.2,9.8,38
          c-6.8,14.5-24.8,18.4-33.3,18.4"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M46.1,344.9l-1.9-111.6c0-0.4,0-0.7,0.1-1
          c-3.5,0.7-7.5,3.8-7.5,7.8l1.7,101.1c0,4.1,4.3,5.4,7.7,5.4C46.1,346.1,46.1,345.5,46.1,344.9z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M126.2,303.7c0,0-62.8-1.2-97-4.2"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M116.2,295.1L87.5,293
          c-3.4-0.1-5.5-3.7-5.5-6.4l-4.5-56.3c0-1.5,1.3-2.8,2.8-2.8h13.6c1.6,0.4,2.4,1.8,3.4,2.6c0.7,0.6,11.5,13.1,19.9,38l2.2,24.8
          C119.3,294.9,117.8,295.1,116.2,295.1z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M64.4,416.2l30.8-1.8v-6c0,0-2.2-3.6-4.5-4
          l-17.9,0.4c0,0-6.8,1.5-8.4,3.3L64.4,416.2z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M76.2,300.2H67c-1.9,0-3.5-1.6-3.5-3.5v-1.2
          c0-1.9,1.6-3.5,3.5-3.5h9.2c1.9,0,3.5,1.6,3.5,3.5v1.2C79.7,298.6,78.1,300.2,76.2,300.2z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.5" stroke-miterlimit="10" d="M76.9,209.4v3.9c0,1.1-0.9,2-2,2h0
          c-1.1,0-2-0.9-2-2v-3.9c0-1.1,0.9-2,2-2h0C76,207.3,76.9,208.3,76.9,209.4z"/>
        <ellipse fill="none" stroke="#100E0E" stroke-width="0.5" stroke-miterlimit="10" cx="51.7" cy="145.4" rx="5.6" ry="3.3"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.5" stroke-miterlimit="10" x1="30.2" y1="165.1" x2="30.2" y2="138.1"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="44.3" y1="232.2" x2="44.3" y2="222.9"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M21.7,160.8c17.6-3,39.4,11.2,39.7,29.5
          c0.3,15.9-5.9,19.7-17.1,32.5"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M65.1,148l4.3,23.4c0,0,3.4,7.4,14,10.3
          C83.4,181.7,80.6,156.2,65.1,148z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M30.2,149.5l29.5,2.1c0,0,5.6,5.5,8.5,13.5"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M91.7,242.2H81.2c-0.8,0-1.5-0.7-1.5-1.5v-7
          c0-0.8,0.7-1.5,1.5-1.5h10.5c0.8,0,1.5,0.7,1.5,1.5v7C93.2,241.5,92.5,242.2,91.7,242.2z"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="93.2" y1="236.6" x2="102.2" y2="236.6"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="46.3" y1="346.6" x2="46.3" y2="351.1"/>
      </g>
      <g>
        <g>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="382.9" cy="191.3" r="22"/>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="382.9" cy="191.3" r="14"/>
        </g>
        <g>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="382.9" cy="377.2" r="22"/>
          <circle fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" cx="382.9" cy="377.2" r="14"/>
        </g>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M389.5,164.6l-1.9-22.4c0,0-3.1-4.1-6.6-4.6
          c0,0-21.4-1.2-29.6,1.7l-6,8.7c0,0-9,4.8-13.1,14.8c0,0-3.6,8.9-4.9,18.4l-3.6,24.1c0,0-21.8,21.9-36.4,52.7c0,0-4.8,12-6.8,29.6
          c0,0-2.7,87,0,109.6c0,0-0.5,9.7,7.8,13.1c0,0,24.7,3.4,26.7,3.6c0,0,44.6,3.1,55.8,2.4l1.4,2.7h5.3l7.3-6.8v-4.4
          c0,0,1.5-2.9-9.4-3.1c0,0-16.3-3.2-22.1-17.9c0,0-8.2-29.6,24.2-36.6h8.7V220.3c0,0-17.7-1.4-26.2-10.2c0,0-15.8-18.9,0-35.4
          c0,0,7.1-9.5,20.3-10.1H389.5z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M353.2,361.2h-66.6c0,0-3.6-2.4-2.9-8.9
          l1-61.6c0,0,1.7-18.6,8-34.2c0,0,13.1-24.9,21.8-33.2l3.9-1h56.4c0,0,7,0.7,8.1,2.2l-2.2,121.9
          C380.8,346.4,361,347.1,353.2,361.2z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M361,352.8c-10.1,6.5-16.8,23.2-9.8,38
          c6.8,14.5,24.8,18.4,33.3,18.4"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M364.7,344.4l1.9-111.6c0-0.4,0-0.7-0.1-1
          c3.5,0.7,7.5,3.8,7.5,7.8l-1.7,101.1c0,4.1-4.3,5.4-7.7,5.4C364.6,345.5,364.7,344.9,364.7,344.4z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M284.6,303.2c0,0,62.8-1.2,97-4.2"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M294.6,294.6l28.7-2.2
          c3.4-0.1,5.5-3.7,5.5-6.4l4.5-56.3c0-1.5-1.3-2.8-2.8-2.8h-13.6c-1.6,0.4-2.4,1.8-3.4,2.6c-0.7,0.6-11.5,13.1-19.9,38l-2.2,24.8
          C291.4,294.3,293,294.6,294.6,294.6z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M346.4,415.6l-30.8-1.8v-6c0,0,2.2-3.6,4.5-4
          l17.9,0.4c0,0,6.8,1.5,8.4,3.3L346.4,415.6z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M334.6,299.6h9.2c1.9,0,3.5-1.6,3.5-3.5V295
          c0-1.9-1.6-3.5-3.5-3.5h-9.2c-1.9,0-3.5,1.6-3.5,3.5v1.2C331.1,298,332.7,299.6,334.6,299.6z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.5" stroke-miterlimit="10" d="M333.9,208.8v3.9c0,1.1,0.9,2,2,2l0,0
          c1.1,0,2-0.9,2-2v-3.9c0-1.1-0.9-2-2-2l0,0C334.8,206.8,333.9,207.7,333.9,208.8z"/>
        <ellipse fill="none" stroke="#100E0E" stroke-width="0.5" stroke-miterlimit="10" cx="359.1" cy="144.9" rx="5.6" ry="3.3"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.5" stroke-miterlimit="10" x1="380.6" y1="164.6" x2="380.6" y2="137.6"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="366.5" y1="231.7" x2="366.5" y2="222.3"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M389.1,160.3c-17.6-3-39.4,11.2-39.7,29.5
          c-0.3,15.9,5.9,19.7,17.1,32.5"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M345.7,147.4l-4.3,23.4c0,0-3.4,7.4-14,10.3
          C327.3,181.1,330.2,155.6,345.7,147.4z"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M380.6,149l-29.5,2.1c0,0-5.6,5.5-8.5,13.5"/>
        <path fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" d="M319.1,241.6h10.5c0.8,0,1.5-0.7,1.5-1.5v-7
          c0-0.8-0.7-1.5-1.5-1.5h-10.5c-0.8,0-1.5,0.7-1.5,1.5v7C317.6,241,318.3,241.6,319.1,241.6z"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="317.6" y1="236" x2="308.6" y2="236"/>
        <line fill="none" stroke="#100E0E" stroke-width="0.75" stroke-miterlimit="10" x1="364.5" y1="346.1" x2="364.5" y2="350.6"/>
      </g>
    </g>
  </g>
  <g id="vehicle_back">
    <g id="back_frame">
      <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M256,530.5l-11.1,0.3l-2,2.4h-71.1l-2.4-2.1
        l-11,0c-7.8-2.5-12-5.4-12.9-15.4l0.1-26.6c3.7-33.9,10.4-63.3,17-63.3h88.9c9.8,4.2,16.2,57.3,16.2,63.9l0.4,25.8
        C268.2,523.1,262.6,530.5,256,530.5z"/>
    </g>
    <g id="back_glass">
      <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M248.1,471.6h-81.4c-2.7,0-5-2.2-5-5l1.8-23.2
        c0-2.7,2.6-5.1,5.4-5.1h77c2.7,0,4.2,2.5,4.2,5.2l2.8,22.5C252.8,468.8,250.8,471.6,248.1,471.6z"/>
    </g>
    <g id="Layer_21">
      <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M208.4,507.5h-42.1c-1.4,0-2.6-1.2-2.6-2.6
        v-9.1c0-1.4,1.2-2.6,2.6-2.6h42.1c1.4,0,2.6,1.2,2.6,2.6v9.1C210.9,506.3,209.8,507.5,208.4,507.5z"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M202.3,489.6L202.3,489.6
        c-1.9,0-3.4-1.5-3.4-3.4V478c0-1.9,1.5-3.4,3.4-3.4l0,0c1.9,0,3.4,1.5,3.4,3.4v8.1C205.7,488,204.1,489.6,202.3,489.6z"/>
    </g>
    <g id="Layer_22">
      <g>
        <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M248.9,425.8c0,0,0.5,9.6,1,10.9
          c0.5,1.3,5,3.6,6.4,22.4l0.6,45.1c0,0,0.1,10.7-3.8,13.6h-45.3"/>
        <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M164.6,425.8c0,0-0.3,9.1-0.8,10.4
          c-0.5,1.3-5,3.6-6.4,22.5l-0.6,45.4c0,0-0.1,10.8,3.8,13.7h47.1"/>
      </g>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M267.8,491.6c0-0.1-0.2-4.8-0.2-4.9l-1.9-16
        c0-1.7-1.2-7.7-1.4-8.7l-8.1-1.7c0.1,2.5,0.1,5,0.1,6.7l0.5,24.2c0,0.1,0,0.3,0,0.4H267.8z"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M145.6,491.6c0-0.1,0.2-4.8,0.2-4.9l1.9-16
        c0-1.7,1.2-7.7,1.4-8.7l8.1-1.7c-0.1,2.5-0.1,5-0.1,6.7l-0.5,24.2c0,0.1,0,0.3,0,0.4H145.6z"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M266,491c0,0,0.8,18.3-1.5,25.7
        c0,0-1.4,4.3-3.4,6.2H206"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M147.9,492c0,0-0.3,17.4,2,24.7
        c0,0,1.4,4.3,3.4,6.2h55.1"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="147.9" y1="496.5" x2="156.9" y2="496.5"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="256.8" y1="496.5" x2="266.6" y2="496.5"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="150.1" y1="517.7" x2="264.2" y2="517.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="157.2" y1="509.9" x2="256.3" y2="509.9"/>
      <line fill="none" stroke="#111010" stroke-width="2" stroke-miterlimit="10" x1="216.9" y1="425.8" x2="216.9" y2="517.7"/>
    </g>
    <g id="Layer_23">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M266,472.7h3.1v-9.4h7.8c0.5,0,1,0.1,1.4,0.4
        c0.6,0.5,1.4,1.3,1.8,3v6.3c0,0.5-0.2,1-0.5,1.5c-0.5,0.7-1.4,1.4-2.7,2.1h-10.4L266,472.7z"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M148.1,472.7H145v-9.4h-7.8
        c-0.5,0-1,0.1-1.4,0.4c-0.6,0.5-1.4,1.3-1.8,3v6.3c0,0.5,0.2,1,0.5,1.5c0.5,0.7,1.4,1.4,2.7,2.1h10.4L148.1,472.7z"/>
    </g>
    <g id="Layer_24">
    </g>
    <g id="Layer_25">
      <g>
        <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M158.9,531.1c0,0-9.1-1.9-12.2-8.7l0-0.1
          c-0.1,0.4-0.2,0.8-0.2,1.2V547c0,1.9,1.5,3.4,3.3,3.4h8.3c1.8,0,3.3-1.5,3.3-3.4v-15.9H158.9z"/>
      </g>
      <g>
        <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M254.5,531.1c0,0,9.1-1.9,12.2-8.7l0-0.1
          c0.1,0.4,0.2,0.8,0.2,1.2V547c0,1.9-1.5,3.4-3.3,3.4h-8.3c-1.8,0-3.3-1.5-3.3-3.4v-15.9H254.5z"/>
      </g>
    </g>
  </g>
  <g id="vehicle_front">
    <g id="front_outer_frame">
      <g>
        <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M205.7,8l46.9,1.9c0,0,7.3,2,12.3,31.7l4,34.2
          v26.4c0,0,1.1,8.6-8.4,15.6c0,0-10.9-2-17.4-1.8h-37.3"/>
        <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M209.5,8l-46.9,1.9c0,0-7.3,2-12.3,31.7
          l-4,34.2v26.4c0,0-1.1,8.6,8.4,15.6c0,0,10.9-2,17.4-1.8h37.3"/>
      </g>
    </g>
    <g id="front_glass">
      <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M257.7,53.3l-100.7,0c-1.9-1.2-2.2-2.5-2.2-4.3
        l5.1-27.1c0-0.8,1.1-2.9,1.8-2.9h90.2c0.8,0,2.2,2.3,2.2,3.1l6.2,28.2C260.3,52.4,258.4,53.3,257.7,53.3z"/>
    </g>
    <g id="Layer_29">
      <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M161.4,19h90.5c0,0-0.3-5.2-2.8-9.3L207.6,8
        l-43.1,1.8C164.5,9.8,162.7,12.9,161.4,19z"/>
    </g>
    <g id="bonnet_1_">
      <path fill="none" stroke="#111010" stroke-width="0.75" stroke-miterlimit="10" d="M158,53.9c0,0,0.1,3.3,1.1,3.7
        c0,0,13.9,0.4,15.7,8v8.4l32.8,0.6l32.9-0.6v-8.7c0,0,1.1-5.9,14.7-8.2c0,0,1.7-1.3,1.7-3.3H158z"/>
    </g>
    <g id="front_right_light">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M174.8,74.1c0,0-15.3-0.5-22-4.1
        c0,0-1-8.3,2-11.4c0,0,3.1-1,4.3-0.9c0,0,13.8,0.3,15.7,8V74.1z"/>
    </g>
    <g id="front_right_light_copy">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M240.2,74.1c0,0,15.3-0.5,22-4.1
        c0,0,1-8.3-2-11.4c0,0-3.1-1-4.3-0.9c0,0-13.8,0.3-15.7,8V74.1z"/>
    </g>
    <g id="front_grill">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M174.8,74.1l3.8,34.3c0,1.3,1.1,2.4,2.5,2.4h53
        c1.4,0,2.5-1.1,2.5-2.4l3.6-34.3H174.8z"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="175.4" y1="79.5" x2="239.5" y2="79.5"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="175.5" y1="82.7" x2="239.6" y2="82.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="176.5" y1="92.1" x2="238.3" y2="92.1"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="186.7" y1="85.7" x2="228.2" y2="85.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="186.6" y1="88.9" x2="228.1" y2="88.9"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="178.4" y1="103.9" x2="237.3" y2="103.9"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="178.4" y1="107.5" x2="236.7" y2="107.5"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="177.8" y1="100.8" x2="237.3" y2="100.8"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="186.6" y1="82.7" x2="186.6" y2="92.1"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="197.3" y1="82.7" x2="197.3" y2="92.1"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="188.5" y1="100.8" x2="188.5" y2="110.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="198.1" y1="100.8" x2="198.1" y2="110.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="207.8" y1="100.8" x2="207.8" y2="110.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="217.4" y1="100.8" x2="217.4" y2="110.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="226.4" y1="100.8" x2="226.4" y2="110.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="207.6" y1="82.7" x2="207.6" y2="92.1"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="217.7" y1="82.7" x2="217.7" y2="92.1"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="227.9" y1="82.7" x2="227.9" y2="92.1"/>
    </g>
    <g id="Layer_34">
      <circle fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" cx="161.4" cy="99" r="5"/>
      <circle fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" cx="254" cy="99.4" r="5"/>
    </g>
    <g id="Layer_35">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M166.5,73.7c0,0,5.3,28.4,5.7,42"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M155.2,117.9c0,0-1.9-46.8-2.5-47.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="154.8" y1="108.6" x2="178.5" y2="108.6"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M248.1,73.7c0,0-5.3,28.4-5.7,42"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M259.5,117.9c0,0,1.9-46.8,2.5-47.7"/>
      <line fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" x1="259.9" y1="108.6" x2="236.2" y2="108.6"/>
    </g>
    <g id="Layer_36">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M266.4,56.1h3.1v-9.4h7.8c0.5,0,1,0.1,1.4,0.4
        c0.6,0.5,1.4,1.3,1.8,3v6.3c0,0.5-0.2,1-0.5,1.5c-0.5,0.7-1.4,1.4-2.7,2.1H267L266.4,56.1z"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M148.5,56.1h-3.1v-9.4h-7.8
        c-0.5,0-1,0.1-1.4,0.4c-0.6,0.5-1.4,1.3-1.8,3v6.3c0,0.5,0.2,1,0.5,1.5c0.5,0.7,1.4,1.4,2.7,2.1H148L148.5,56.1z"/>
    </g>
    <g id="Layer_37">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M155.1,117.9c-3-2.1-5.3-5.5-6.4-7.4
        c0,0.2-0.1,0.4-0.1,0.6v19c0,1.8,1.4,3.2,3.1,3.2h7.1c1.7,0,3.1-1.4,3.1-3.2v-13.2L155.1,117.9z"/>
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M260.6,117.9c3-2.1,5.3-5.5,6.4-7.4
        c0,0.2,0.1,0.4,0.1,0.6v19c0,1.8-1.4,3.2-3.1,3.2h-7.1c-1.7,0-3.1-1.4-3.1-3.2v-13.2L260.6,117.9z"/>
    </g>
    <g id="Layer_38">
      <path fill="none" stroke="#111010" stroke-width="0.5" stroke-miterlimit="10" d="M215.8,69.8C215.8,69.8,215.8,69.8,215.8,69.8
        l0,1.3c0,0.4-0.7,0.8-1.7,0.8h-12c-0.9,0-1.7-0.4-1.7-0.8v-1.3c0,0,0,0,0,0H215.8z"/>
    </g>
  </g></>)
  },
};
