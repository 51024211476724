export default {
  root: {
    padding: 0,
    "& .MuiAutocomplete-inputRoot": {
      padding: "0px !important",
      "&.MuiInputBase-root .MuiOutlinedInput-input": {
        padding: "12px 14px 10px"
      }
    }
  }
};
