import './index.css';
import React from "react";
import { Skeleton } from '@material-ui/lab';

interface IProps {
  imageKey: string;
  isGallery?: boolean;
}

const Image: React.FC<IProps> = (props) => {

  return (
    <div className={"imageContainer"}>
      {
        props.imageKey ? (
          <img className={"image"} alt="loading.." src={props.imageKey} />
        ) :
          <Skeleton className={"image"} variant="rect" height={400} />
      }
    </div>
  )
};

export default Image;
