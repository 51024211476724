import "./index.scss";
import {
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  TextField as InputField,
  Theme,
  createStyles,
  withStyles,
  makeStyles
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import * as Yup from "yup";
import { IAddress } from '../../reducers/bookings/types';

interface IProps {
  address?: IAddress;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  addGeometry?: boolean;
  setAddress(address?: IAddress): void;
  required?: boolean;
  validationError?: any;
  touched?: any;
}

const active = {
  backgroundColor: "rgba(0, 0, 0, 0.08)",
  cursor: "pointer"
} as React.CSSProperties;

const inactive = {
  backgroundColor: "#ffffff",
  cursor: "pointer"
} as React.CSSProperties;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      height: 28,
      margin: 4
    },
    error: {
      borderColor: "#f44336 !important"
    },
    iconButton: {
      padding: 10
    },
    root: {
      alignItems: "center",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderStyle: "solid",
      borderWidth: "1px",
      boxSizing: "content-box",
      display: "flex",
      minHeight: "40px",
      left: "-19px",
      position: "absolute",
      top: "-1px",
      width: "100%"
    }
  })
);

export const AddressPickerInput = (props: IProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (props.address?.fullAddress || props.address?.street) {
      setSelected(true);
    }
  }, [props.address]);
  const handleChangeAddress = (value: string) => {
    const location = {
      city: "",
      country: "",
      fullAddress: value,
      state: "",
      street: "",
      zipcode: ""
    };
    props.setAddress(location);
  };

  const handleSelectAddress = (address: string) => {
    geocodeByAddress(address)
      .then((results) => {
        if (results.length) {
          const place = results[0];
          const loc: IAddress = {
            city: "",
            country: "",
            fullAddress: "",
            state: "",
            street: "",
            zipcode: ""
          };
          const streetName: string[] = [];
          place.address_components.forEach((item) => {
            if (item.types.includes("country")) {
              loc.country = item.long_name;
            } else if (item.types.includes("postal_code")) {
              loc.zipcode = item.long_name;
            } else if (item.types.includes("administrative_area_level_2")) {
              loc.state = item.short_name;
            } else if (
              item.types.includes("postal_town") ||
              item.types.includes("locality")
            ) {
              loc.city = item.long_name;
            } else if (
              item.types.includes("street_number") ||
              item.types.includes("route")
            ) {
              streetName.push(item.long_name);
            }
          });
          loc.street = streetName.join(", ");
          loc.fullAddress = address;
          // loc.fullAddress = `${loc.street} ${loc.city} ${loc.state} ${loc.country} ${loc.zipcode}`
          if (props.addGeometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const geometry = {
              type: "Point",
              latitude: lat,
              longitude: lng
            };
            loc.geometry = geometry;
          }
          setSelected(true);
          props.setAddress(loc);
        }
      })
      .catch((error) => console.error("Error", error));
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDisplay(true);
    const ele = event.currentTarget;
    if (ele) {
      setAnchorEl(ele);
    }
  };

  const handleReset = () => {
    setDisplay(false);
    setSelected(false);
    props.setAddress({
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    });
  };
  const handleClose = (values: IAddress) => {
    setDisplay(false);
    props.setAddress(values);
  };

  const locationSchema = Yup.object().shape({
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required."),
    state: Yup.string().required("Region/State is required."),
    street: Yup.string().required("Street is required."),
    zipcode: Yup.string().required("Zip/Postal Code is required."),
  });

  return (
    <Grid container>
      <Grid container item xs={12}>
        <PlacesAutocomplete
          value={props.address?.fullAddress || props.address?.street || ""}
          onChange={(value) => handleChangeAddress(value)}
          onSelect={(value) => handleSelectAddress(value)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div className="location-search" id="addressPickerInput">
              <Grid
                component="form"
                className={`address-picker-form ${props.required && props.validationError && props.touched &&
                  classes.error}`}
              >
                <InputField
                  style={{ width: "100%" }}
                  {...getInputProps({
                    name: "state",
                    placeholder: props.placeholder ? props.placeholder : ""
                  })}
                  error={(props.required && props.validationError && props.touched) ? true : false}
                  helperText={props.required && props.validationError && props.touched && props.validationError[Object.keys(props.validationError)[0]]}
                  onBlur={(e) => {
                    props.setAddress();
                  }}
                  required={props.required}
                  variant="outlined"
                  label={undefined}
                  disabled={props.disabled}
                  InputProps={{
                    style: {
                      padding: "4px 0px",
                      borderRadius: 6,
                      marginTop: 5
                    },
                    endAdornment: selected && (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          className={classes.iconButton}
                          aria-label="edit"
                          onClick={(e) => handleClick(e)}
                          disabled={props.disabled}
                        >
                          <CreateIcon />
                        </IconButton>
                        <Divider
                          className={classes.divider}
                          orientation="vertical"
                        />
                        <IconButton
                          className={classes.iconButton}
                          aria-label="clear"
                          disabled={props.disabled}
                          onClick={() => handleReset()}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <div
                className="autocomplete-dropdown-container"
              // style={{ border: suggestions.length > 0 ? "1px solid red" : " " }}
              >
                {suggestions.map((suggestion, idx) => {
                  const style = suggestion.active ? active : inactive;
                  return (
                    <div
                      className="suggestions"
                      {...getSuggestionItemProps(suggestion, {
                        style
                      })}
                      key={idx}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Grid>
      <Grid container item xs={12}>
        {display && (
          <Popover
            className="popover-address"
            open={true}
            onClose={() => setDisplay(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Formik
              enableReinitialize
              validationSchema={locationSchema}
              initialValues={props.address || {
                fullAddress: "",
                street: "",
                city: "",
                state: "",
                country: "",
                zipcode: ""
              }}
              onSubmit={(values, { setSubmitting }) => {
                values.fullAddress = `${values.street} ${values.city} ${values.state} ${values.country} ${values.zipcode}`
                handleClose(values);
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form>
                  <Grid
                    item
                    container
                    className="popoverGrid"
                    spacing={2}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Field
                        component={TextField}
                        placeholder="Street"
                        label="Street"
                        name={"street"}
                        required
                        onChange={formikProps.handleChange}
                        fullWidth
                      ></Field>
                    </Grid>

                    <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Field
                        component={TextField}
                        placeholder="City"
                        label="City"
                        name={"city"}
                        onChange={formikProps.handleChange}
                        required
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Field
                        component={TextField}
                        placeholder="Region/State"
                        label="Region/State"
                        name={"state"}
                        required
                        onChange={formikProps.handleChange}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Field
                        component={TextField}
                        placeholder="Country"
                        label="Country"
                        name={"country"}
                        required
                        onChange={formikProps.handleChange}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Field
                        component={TextField}
                        placeholder="Zip/Postal code"
                        label="Zip/Postal code"
                        name={"zipcode"}
                        required
                        onChange={formikProps.handleChange}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} justify="flex-start">
                      <Fab
                        variant={"extended"}
                        size="medium"
                        type="submit"
                        aria-label="add"
                      >
                        SAVE
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Popover>
        )}
      </Grid>
    </Grid>
  );
};
