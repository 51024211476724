import React, { useEffect, useState } from 'react';
import Amplify from "aws-amplify";
import ReactGA from 'react-ga';
import awsconfig from "./aws-exports";
import './App.css';
import './theme/common.css';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { IAppState } from "./store";
import { GET_APP_SETTING } from './graphql/websiteData/websiteQuery';
import { setOrganisation } from './actions/organisation/actions';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomRoute from './routes/index';
import { IAppSetting, IConsumerWebsite } from './reducers/website/types';
import 'react-phone-number-input/style.css'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from "dayjs/plugin/isBetween";
import weekday from "dayjs/plugin/weekday";
import { GET_USER } from './graphql/user/getUser';
import HttpHeader from './header';
import { addUserDataAction, setAuthEvaporateClientAction, updateAuthStateAction } from './actions/auth/actions';
import { AccountType, AuthStates, IAuthState } from './reducers/auth/types';
import { getEvaporateClient } from './utils/evaporate';
//import TagManager from 'react-gtm-module';


dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(weekday)
Amplify.configure(awsconfig);
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");

function hexToRGBA(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const httpHeader = HttpHeader.getInstance();

function App() {
  const dispatch = useDispatch();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const authUser = useSelector((state: IAppState) => state.authReducer.authUser);
  const authState = useSelector((state: IAppState) => state.authReducer);
  const [googleGTMId, setGoogleGTMId] = useState<string>("");

  const [loadOrganisation,
    { data: appSettingData }
  ] = useLazyQuery(
    GET_APP_SETTING, {
    onCompleted: () => {
      const appSetting: IAppSetting = appSettingData.consumerGetAppSetting;
      setGoogleGTMId(appSetting.webAppSetting.googleGTMId || "");
      dispatch(setOrganisation({
        ...appSetting,
        ...appSetting.webAppSetting
      }));
      httpHeader.setHeaders({
        ...(appSetting.clientId && { clientId: appSetting.clientId }),
        tenantId: appSetting.tenantId,
        organisationId: appSetting.organisationId
      });
      const rootEl: HTMLElement | null = document.querySelector(':root');
      rootEl?.style.setProperty('--primary-color', hexToRGBA(appSetting.branding.buttonColor, 1));
      rootEl?.style.setProperty('--brand-accent', hexToRGBA(appSetting.branding.accentColor, 1));
      rootEl?.style.setProperty('--accent-shade', hexToRGBA(appSetting.branding.buttonColor, 0.1));
      rootEl?.style.setProperty('--accent-shade-d', hexToRGBA(appSetting.branding.buttonColor, 0.2));
      rootEl?.style.setProperty('--accent-shade-light', hexToRGBA(appSetting.branding.buttonColor, 0.05));
      rootEl?.style.setProperty('--primary-color-d', hexToRGBA(appSetting.branding.buttonColor, 1));

    },
    onError: () => {
      const rootEl: HTMLElement | null = document.querySelector(':root');
      rootEl?.style.setProperty('--primary-color', hexToRGBA("#000000", 1));
    },
    fetchPolicy: "network-only"
  });

  const [getUser, { loading: _userLoading, data: userData, error }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      httpHeader.resetHeaders();
      localStorage.clear();
      window.location.replace("/");
    }
  });

  const signIn = (authUser: any, token: string, accountType: AccountType) => {
    httpHeader.setHeaders({ authorization: token, userId: authUser._id });
    dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_IN, authUser, token, accountType }));
    getUser({
      variables: {
        id: authUser._id,
      },
    });
  }

  useEffect(() => {
    if (userData && userData.user) {
      const _user = {
        ...userData.user,
        businesscustomers: authState.accountType === AccountType.BUSINESS ? userData.user.businesscustomers : undefined
      }
      dispatch(addUserDataAction(_user));
    }
  }, [userData]);

  useEffect(() => {
    loadOrganisation();
    const auth = localStorage.getItem("auth");
    if (auth) {
      const authObject: IAuthState = JSON.parse(auth);
      if (authObject.state === "SIGNED_IN" && authObject.authUser && authObject.token && authObject.accountType) {
        signIn(authObject.authUser, authObject.token, authObject.accountType)
      }
      else if (authObject.state === "SET_PASSWORD" && authObject.authUser && authObject.token) {
        dispatch(updateAuthStateAction({ state: AuthStates.SET_PASSWORD, authUser: authObject.authUser, token: authObject.token }));
      }
      else {
        dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }));
      }
    }
    else {
      dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }));
    }
  }, []);

  useEffect(() => {
    const appendGTMCode = async () => {
      try {
        if (googleGTMId) {
          console.log("googleGTMId----test", googleGTMId);
          let content = document.createElement('script');
          content.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${googleGTMId}');`;
          document.head.appendChild(content);
        }
      } catch (error) {
        console.error('Failed to append GTM code:', error);
      }
    };
    appendGTMCode();
  }, [googleGTMId]);

  useEffect(() => {
    if (authUser) {
      getEvaporateClient().then((_e_) => {
        dispatch(setAuthEvaporateClientAction({ _e_ }))
      })
    }
  }, [authUser])

  return (
    <>
      {website.id ? <>
        <CustomRoute />
      </> :
        <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress thickness={5} /></div>}
    </>
  );
}

export default App;