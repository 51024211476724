
import React, { useEffect, useState } from "react";
import { VehicleSelectionView } from "../../reducers/bookings/types";
import { Checkbox, createStyles, FormControlLabel, FormGroup, makeStyles, Radio, RadioGroup, Theme, Typography } from "@material-ui/core";
import { NuvvenExpansionPanel } from "../NuvvenExpansionPanel/NuvvenExpansionPanel";

interface IProps {
  disabled?: boolean;
  searchFilters?: { [key: string]: string[] }
  filter: IFilter;
  radioButtons?: boolean;
  radioValue?: VehicleSelectionView;
  onChange(filters: any): void;
}

export interface IFilter {
  name: string;
  id: string;
  options: IFilterOption[];
}

export interface IFilterOption {
  id: string;
  name: string;
  value?: boolean | VehicleSelectionView;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlLabel: {
      marginRight: 0
    }
  })
);
export const FilterSection = (props: IProps) => {
  const classes = useStyles();
  const [values, setValues] = useState<{ [key: string]: boolean }>({});
  const [radioValue, setRadioValue] = useState<VehicleSelectionView>(
    typeof props.radioValue !== "undefined"
      ? props.radioValue
      : VehicleSelectionView.CATEGORY
  );

  useEffect(() => {
    if (!props.radioButtons) {
      const options: { [key: string]: boolean } = {};
      props.filter.options.forEach((option: IFilterOption) => {
        if (
          typeof option.value === "boolean" ||
          typeof option.value === "undefined"
        ) { // FIXME: Fix this condition and related imports
          options[option.id] = option.value ? option.value : false;
        }
      });
      props.searchFilters?.[props.filter.id]?.forEach((OptionName: string) => {
        options[OptionName] = true;
      })
      setValues({ ...options });
    }
  }, [props.filter]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValues = { ...values, [event.target.name]: event.target.checked };
    const filters = Object.keys(newValues).filter(
      (key: string) => newValues[key] === true
    );
    setValues({ ...newValues });
    props.onChange({ [props.filter.id]: [...filters] });
  }

  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      const myValue = parseInt(event.target.value, 10);
      setRadioValue(myValue);
      props.onChange(myValue);
    } catch(error) {
      // captureErrorException(error)
      return;
    }
  }

  return (
    <NuvvenExpansionPanel title={props.filter.name}>
      {!props.radioButtons ? (
        props.filter.options.map((option: IFilterOption, index: number) => {
          return (
            <FormGroup row key={index}>
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={values[option.id] ? values[option.id] : false}
                    onChange={handleChange}
                    value={option.id}
                    color="secondary"
                    name={option.id}
                    disabled={props.disabled}
                  />
                }
                label={<Typography variant="body1">{option.name}</Typography>}
              />
            </FormGroup>
          );
        })
      ) : (
        <RadioGroup
          aria-label={props.filter.id}
          name={props.filter.id}
          value={radioValue}
          onChange={handleRadioChange}
        >
          {props.filter.options.map((option: IFilterOption, index: number) => {
            return (
              <FormControlLabel
                className={classes.formControlLabel}
                value={option.value}
                control={<Radio color={"secondary"} />}
                label={<Typography variant="body1">{option.name}</Typography>}
                key={index}
                disabled={props.disabled}
              />
            );
          })}
        </RadioGroup>
      )}
    </NuvvenExpansionPanel>
  );
};
