export interface IConfirmationDialogData {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const defaultConfirmationDialogData: IConfirmationDialogData = {
  description: "",
  isOpen: false,
  title: "",
  onCancel: () => {
    return;
  },
  onConfirm: () => {
    return;
  },
  isLoading: false
};

export const CANCEL_DESCRIPTION =
  "Are you sure you want to cancel this booking?";
export const CANCEL_TITLE = "Cancel Booking";
export const CANCEL_DESCRIPTION_QUOTE =
  "Are you sure you want to cancel this quote?";
export const CANCEL_TITLE_QUOTE = "Cancel Quote";
export const COMPLETE_DESCRIPTION =
  "Are you sure you want to complete this booking?";
export const START_DESCRIPTION = "Are you sure you want to start this booking?";
export const COMPLETE_TITLE = "Complete Booking";
export const START_TITLE = "Start Booking";
export const DELETE_DRIVER_DESCRIPTION = "Are you sure you want to delete the driver?";
export const DELETE_DRIVER = "Delete Driver";

export const BOOKING_STATUS = {
  QUOTE: "QUOTE",
  CONFIRMED: "CONFIRMED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED"
};

export const VEHICLE_STATUS = {
  CONFIRMED: "CONFIRMED",
  DROPOFF_CHECK_COMPLETE: "DROPOFF_CHECK_COMPLETE",
  PICKUP_CHECK_COMPLETE: "PICKUP_CHECK_COMPLETE"
};

export const CHECK_TYPE = {
  COLLECTION: "COLLECTION",
  DELIVERY: "DELIVERY"
};
