import React from "react";

export const VanFlatBedSVG = {
  interior: {
    width: 133,
    height: 212,
    svg: (
      <>
<g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M67.2,5.6l24,1.1c0,0,23,1.6,27,14.7V94h-51"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M68.2,5.6l-24,1.1c0,0-23,1.6-27,14.7V94h51"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M21.2,92.4l0-67.7c0,0,0.3-3.2,3.3-3.2"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="21.2" y1="92.1" x2="17.2" y2="94"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M113.7,92.4l0-68.7c0,0-0.2-2.2-3.2-2.2"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="113.7" y1="92.1" x2="117.7" y2="94"/>
	</g>
	<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="21.2" y1="91.8" x2="113.7" y2="91.8"/>
	<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M17.8,52.9L4.9,54.3
		c-0.8,0.1-1.6-0.5-1.7-1.3l0,0c-0.1-0.8,0.5-1.6,1.3-1.7l12.9-1.5c0.8-0.1,1.6,0.5,1.7,1.3v0C19.2,52,18.6,52.8,17.8,52.9z"/>
	<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M116.5,52.9l12.9,1.5
		c0.8,0.1,1.6-0.5,1.7-1.3v0c0.1-0.8-0.5-1.6-1.3-1.7l-12.9-1.5c-0.8-0.1-1.6,0.5-1.7,1.3v0C115.1,52,115.7,52.8,116.5,52.9z"/>
	<rect x="18.2" y="97.1" fill="#9E9E9E" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="101" height="2.6"/>
	<rect x="43.2" y="93.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="7" height="3.5"/>
	<rect x="53.2" y="93.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="10" height="3.5"/>
	
		<rect x="85.2" y="93.6" transform="matrix(-1 -3.845869e-11 3.845869e-11 -1 177.3354 190.6625)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="7" height="3.5"/>
	
		<rect x="72.2" y="93.6" transform="matrix(-1 -3.892632e-11 3.892632e-11 -1 154.3354 190.6625)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="10" height="3.5"/>
	<rect x="20.2" y="99.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="97" height="103.6"/>
	<rect x="22.2" y="101.4" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="93" height="100.2"/>
	<rect x="18.2" y="99.6" fill="#9E9E9E" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="2" height="3.5"/>
	<rect x="117.2" y="99.6" fill="#9E9E9E" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="2" height="3.5"/>
	<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="43" cy="28.5" rx="4.5" ry="4"/>
	<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="89" cy="28.5" rx="4.5" ry="4"/>
	<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="24" y1="21.5" x2="111" y2="21.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M19,17.5h23.5c0,0,9-6,24-6"/>
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M117.5,18.5l-25-1c0,0-11-6-26-6"/>
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M21.7,49.8c0,0,39.1-13.7,92,0"/>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M35.5,50.9h18.8c0,0,2.7,0.4,3.8,4l0.2,9c0,0-1.4,5.2-5.3,6.8h-17
			c0,0-3.4-2-4.9-6.7v-9.2C31.2,54.7,32.5,51.3,35.5,50.9z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M58.7,69.1l-0.4-0.5H56l-1.5,1.3l-1.9,0.7l-7.9,0l0,0l-8,0.2
			l-1.7-1l-1.2-1.3l-2.7,0.1l-0.7,1L30,72.3v8l0.3,2.1l1,1.4l1.2,0.9l1.9,0.7h2.7h1.2l0.1,0.6h6.4l0,0h6.4l0.1-0.6h1.2h2.7l1.9-0.7
			l1.2-0.9l1-1.4l0.3-2.1v-8l-0.3-2.1C59.1,69.8,58.9,69.5,58.7,69.1z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="44.6,79.9 36.5,79.9 36.5,85.2 38,86.4 44.6,86.4 
			51.2,86.4 52.7,85.2 52.7,79.9 		"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M79.5,50.9h18.8c0,0,2.7,0.4,3.8,4l0.2,9c0,0-1.4,5.2-5.3,6.8h-17
			c0,0-3.4-2-4.9-6.7v-9.2C75.2,54.7,76.5,51.3,79.5,50.9z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M102.7,69.1l-0.4-0.5H100l-1.5,1.3l-1.9,0.7l-7.9,0l0,0l-8,0.2
			l-1.7-1l-1.2-1.3l-2.7,0.1l-0.7,1L74,72.3v8l0.3,2.1l1,1.4l1.2,0.9l1.9,0.7h2.7h1.2l0.1,0.6h6.4l0,0h6.4l0.1-0.6h1.2h2.7l1.9-0.7
			l1.2-0.9l1-1.4l0.3-2.1v-8l-0.3-2.1C103.1,69.8,102.9,69.5,102.7,69.1z"/>
		<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="88.6,79.9 80.5,79.9 80.5,85.2 82,86.4 88.6,86.4 
			95.2,86.4 96.7,85.2 96.7,79.9 		"/>
	</g>
</g>
      </>
    ),
  },

  exterior: {
    width: 412,
    height: 524,
    svg: (
      <>
<g id="Layer_1">
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M63.7,171.2c20,9,64.3,43.7,65.7,44.9c1.3,1,2.3,2.6,2.3,4.1
			l-0.3,31.1c0,2.1-1.8,3.9-3.9,3.9H59.1c-0.5,0-0.8-0.4-0.8-0.8v-17.8c0-0.9-0.3-1.7-0.9-2.3l-9.6-10"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M32.7,197.2c9.5,2.3,16.5,8.5,16.5,19.1c0,10.1-6.8,16.8-16.6,18.9"
			/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M32.7,338.2c9.5,2.3,16.5,9.5,16.5,20.1c0,10.1-6.8,16.8-16.6,18.9"
			/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M80.7,243.2v-10c0-0.5,0.4-1,1-1l0,0c0.6,0,1,0.4,1,1v10
			c0,0.5-0.4,1-1,1l0,0C81.2,244.2,80.7,243.8,80.7,243.2z"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="33.2" y1="338.7" x2="33.2" y2="234.8"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M33.2,377.4l2.7,36.3c0,0,22.3-7.5,22.3-16.5V253.7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M33.2,197.3l1-24.8c0-0.9,0.7-1.6,1.6-1.6l28.4,0.4"/>
		
			<rect x="93.8" y="221.8" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 354.0407 163.0407)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="73.6"/>
		
			<rect x="-7.2" y="326.2" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 401.4711 267.4711)" fill="none" stroke="#000000" stroke-miterlimit="10" width="148.5" height="16.6"/>
		
			<rect x="-3.8" y="329.5" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 401.2527 267.2527)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="141.7" height="9.4"/>
		<g>
			<circle fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="30.5" cy="216.5" r="16.2"/>
			<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="30.5" cy="216.5" r="8.3"/>
		</g>
		<g>
			<circle fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="30.5" cy="358.5" r="16.2"/>
			<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="30.5" cy="358.5" r="8.3"/>
		</g>
		<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M89.8,206.4c0-0.7,2.4-3,3.6-4.2
			c0.4-0.4,1.2-0.4,1.6-0.1c1.6,1.1,4.8,3.5,4.8,4.2c0,1-1,1.8-2.2,1.8H92C90.8,208.2,89.8,207.4,89.8,206.4z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M126.9,246.8l-0.2-26.4c0-1.4-0.7-2.6-1.8-3.4l-38.3-27.7
			c0,0-17-12-23-14H39.1c-1,0-1.8,0.8-1.8,1.8l-0.5,18.2c0,0,15,5,15,16v13l7.7,8.3c0.8,1.1,1.3,2.5,1.3,3.9l-0.1,8.8
			c0.1,2.3,1.9,4.1,4.2,4.1l59.1,0.6C125.6,249.8,126.9,248.5,126.9,246.8z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M88.7,244.3v-46.7c0-1,1.2-1.6,2-1l29.7,21.6
			c0.8,0.6,1.3,1.5,1.3,2.5v23.6c0,1-0.8,1.9-1.9,1.9H90.6C89.6,246.2,88.7,245.4,88.7,244.3z"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="94.7" y1="202.2" x2="86.7" y2="196.2"/>
		<path d="M85.2,197.3v-1.6c0-0.2,0.2-0.4,0.4-0.4h1.6c0.2,0,0.4,0.2,0.4,0.4v1.6c0,0.2-0.2,0.4-0.4,0.4h-1.6
			C85.4,197.7,85.2,197.5,85.2,197.3z"/>
		<path fill="#C1BFBF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M34.7,402.2c14.4-2.4,24-9,24-9v13
			c0,0-2,3-23,9L34.7,402.2z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M41.7,170.7v-2.6c0-1.4,1.1-2.5,2.5-2.4l13.7,2.4
			c0.5,0.1,0.8,0.5,0.8,0.9v2.2L41.7,170.7z"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M349.3,164.7c-20,9-64.3,43.7-65.7,44.9c-1.3,1-2.3,2.6-2.3,4.1
			l0.3,31.1c0,2.1,1.8,3.9,3.9,3.9h68.4c0.5,0,0.8-0.4,0.8-0.8v-17.8c0-0.9,0.3-1.7,0.9-2.3l9.6-10"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M380.3,190.7c-9.5,2.3-16.5,8.5-16.5,19.1
			c0,10.1,6.8,16.8,16.6,18.9"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M380.3,331.7c-9.5,2.3-16.5,9.5-16.5,20.1
			c0,10.1,6.8,16.8,16.6,18.9"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M332.3,236.7v-10c0-0.5-0.4-1-1-1l0,0c-0.5,0-1,0.4-1,1v10
			c0,0.5,0.5,1,1,1l0,0C331.8,237.7,332.3,237.3,332.3,236.7z"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="379.8" y1="332.2" x2="379.8" y2="228.3"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M379.8,370.9l-2.7,36.3c0,0-22.3-7.5-22.3-16.5V247.2"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M379.8,190.8l-1-24.8c0-0.9-0.7-1.6-1.6-1.6l-28.4,0.4"/>
		
			<rect x="315.8" y="215.3" transform="matrix(-4.492336e-11 1 -1 -4.492336e-11 569.5408 -65.4593)" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="73.6"/>
		
			<rect x="271.8" y="319.7" transform="matrix(-4.489316e-11 1 -1 -4.489316e-11 673.9711 -18.0289)" fill="none" stroke="#000000" stroke-miterlimit="10" width="148.5" height="16.6"/>
		
			<rect x="275.2" y="323" transform="matrix(-4.491270e-11 1 -1 -4.491270e-11 673.7527 -18.2473)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="141.7" height="9.4"/>
		<g>
			<circle fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="382.5" cy="210" r="16.2"/>
			<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="382.5" cy="210" r="8.3"/>
		</g>
		<g>
			<circle fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="382.5" cy="352" r="16.2"/>
			<circle fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" cx="382.5" cy="352" r="8.3"/>
		</g>
		<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M323.2,199.9c0-0.7-2.4-3-3.6-4.2
			c-0.4-0.4-1.2-0.4-1.6-0.1c-1.6,1.1-4.8,3.5-4.8,4.2c0,1,1,1.8,2.2,1.8h5.6C322.2,201.7,323.2,200.9,323.2,199.9z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M286.1,240.3l0.2-26.4c0-1.4,0.7-2.6,1.8-3.4l38.3-27.7
			c0,0,17-12,23-14h24.6c1,0,1.8,0.8,1.8,1.8l0.5,18.2c0,0-15,5-15,16v13l-7.7,8.3c-0.8,1.1-1.3,2.5-1.3,3.9l0.1,8.8
			c-0.1,2.3-1.9,4.1-4.2,4.1l-59.1,0.6C287.4,243.3,286.1,242,286.1,240.3z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M324.3,237.8v-46.7c0-1-1.2-1.6-2-1l-29.7,21.6
			c-0.8,0.6-1.3,1.5-1.3,2.5v23.6c0,1,0.8,1.9,1.9,1.9h29.3C323.4,239.7,324.3,238.9,324.3,237.8z"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="318.3" y1="195.7" x2="326.3" y2="189.7"/>
		<path d="M327.8,190.8v-1.6c0-0.2-0.2-0.4-0.4-0.4h-1.6c-0.2,0-0.4,0.2-0.4,0.4v1.6c0,0.2,0.2,0.4,0.4,0.4h1.6
			C327.6,191.2,327.8,191,327.8,190.8z"/>
		<path fill="#C1BFBF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M378.3,395.7c-14.4-2.4-24-9-24-9v13
			c0,0,2,3,23,9L378.3,395.7z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M371.3,164.2v-2.6c0-1.4-1.1-2.5-2.5-2.4l-13.7,2.4
			c-0.5,0.1-0.8,0.5-0.8,0.9v2.2L371.3,164.2z"/>
	</g>
</g>
<g id="Layer_2">
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M252,135.3v21.2c0,0,0,2.5-2.7,2.5h-9.5
		c0,0-2.7,0-2.7-2.5v-12.3"/>
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M160.1,136.5v20c0,0,0,2.5,2.7,2.5h9.5
		c0,0,2.7,0,2.7-2.5v-12.3"/>
	<g>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M196.4,21.2h49.5c0,0,0.6,0,1.4,0.1
			c4.3,0.4,7.7,4.3,7.8,9l1,59c0,1.1,0.2,12.5-1,18.8c-0.4,2.2-2.2,3.8-4.4,3.8l-14,0c-1,0-30-0.1-30-0.1"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M215.4,21.2h-49.5c0,0-0.6,0-1.4,0.1
			c-4.3,0.4-7.7,4.3-7.8,9l-1,59c0,1.1-0.2,12.5,1,18.8c0.4,2.2,2.2,3.8,4.4,3.8l14,0c1,0,31.4-0.1,31.4-0.1"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M157,109.2c0,0,0.6,10.3,1.4,19.5
			c0.3,3.3,0.9,6.5,2.1,9.6c1,2.8,2.7,5.7,4,5.7h8.1c0.5,0,35.2,0,35.2,0"/>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M254.4,109.2c0,0-0.6,10.3-1.4,19.5
			c-0.3,3.3-0.9,6.5-2.1,9.6c-1,2.8-2.7,5.7-4,5.7h-8.1c-0.5,0-35.2,0-35.2,0"/>
	</g>
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M168.1,107.3h-1.8c-2,0-3.7-1.6-3.7-3.7v-1.8
		c0-2,1.6-3.7,3.7-3.7h1.8c2,0,3.7,1.6,3.7,3.7v1.8C171.7,105.6,170.1,107.3,168.1,107.3z"/>
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M245.1,107.3h-1.8c-2,0-3.7-1.6-3.7-3.7v-1.8
		c0-2,1.6-3.7,3.7-3.7h1.8c2,0,3.7,1.6,3.7,3.7v1.8C248.7,105.6,247.1,107.3,245.1,107.3z"/>
	<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M155.4,93.2h-3.6v-8.5c0-1.4-0.5-2.7-1.5-3.6
		c-0.9-0.8-2.3-1.6-4.5-1.6c0,0-2.3,0-4.1,0.8c-1.1,0.5-1.9,1.7-1.9,3l0,14.8c0,0,0,2.5,2.4,2.5h6.8c0.3,0,0.5-0.1,0.8-0.2
		c0.5-0.3,1.3-0.8,1.7-1.5c0.3-0.5,0.8-0.8,1.3-0.8h1c0.9,0,1.6-0.7,1.6-1.6V93.2z"/>
	<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M256.6,93.2h3.6v-8.5c0-1.4,0.5-2.7,1.5-3.6
		c0.9-0.8,2.3-1.6,4.5-1.6c0,0,2.3,0,4.1,0.8c1.1,0.5,1.9,1.7,1.9,3l0,14.8c0,0,0,2.5-2.4,2.5h-6.8c-0.3,0-0.5-0.1-0.8-0.2
		c-0.5-0.3-1.3-0.8-1.7-1.5c-0.3-0.5-0.8-0.8-1.3-0.8h-1c-0.9,0-1.6-0.7-1.6-1.6V93.2z"/>
	<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M244.5,91.8h-78.1c-3.1,0-5.7-2.5-5.7-5.7
		l1.2-56.2c0-1.5,1.2-2.7,2.7-2.7h81.5c1.5,0,2.7,1.2,2.7,2.7l1.2,56.2C250.1,89.3,247.6,91.8,244.5,91.8z"/>
	<g>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M158.2,122.9c0,0,21.1,0,26.1,2.5
			c4.7,2.4,8.7,8.7,22.4,8.7"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M253.8,122.9c0,0-21.1,0-26.1,2.5
			c-4.7,2.4-8.7,8.7-22.4,8.7"/>
	</g>
	<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M223.6,147.7h-34.4c-0.2,0-0.4-0.2-0.4-0.4
		v-7.8c0-0.2,0.2-0.4,0.4-0.4h34.4c0.2,0,0.4,0.2,0.4,0.4v7.8C224,147.5,223.8,147.7,223.6,147.7z"/>
	<path fill="#D8D8D8" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M249,37.2l-0.2-7.2c0-1.5-1.2-2.7-2.7-2.7
		h-81.5c-1.5,0-2.7,1.2-2.7,2.7l-0.2,7.2H249z"/>
	<path fill="#D8D8D8" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M160.9,80.7l-0.3,5.5c0,3.1,2.5,5.7,5.7,5.7
		h78.1c3.1,0,5.6-2.5,5.6-5.6l-0.3-5.5H160.9z"/>
	
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" x1="195.5" y1="86.9" x2="168.4" y2="77"/>
	
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" x1="226.5" y1="85.6" x2="199.4" y2="75.8"/>
</g>
<g id="Layer_3">
	<g>
		<polygon fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" points="254.7,434.6 
			159.7,434.6 160.7,383.6 253.7,383.6 		"/>
		<polygon fill="#EAEAEA" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" points="
			252.7,433.1 162.2,433.1 163.2,385.6 251.7,385.6 		"/>
		
			<rect x="156.7" y="434.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" width="100" height="19"/>
		
			<rect x="158.7" y="436.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" width="96" height="15"/>
		
			<rect x="155.7" y="453.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" width="102" height="5"/>
		
			<rect x="158.7" y="439.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" width="2" height="5"/>
		
			<rect x="252.6" y="439.4" fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" width="2" height="5"/>
		<polygon fill="none" stroke="#000000" stroke-width="0.75" stroke-linecap="round" stroke-miterlimit="10" points="255.7,472.1 
			158.7,472.1 157.7,458.6 256.7,458.6 		"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M169.7,472"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M158.7,472"/>
		<g>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M158.7,471.1V487c0,1.6,1.4,3,3.3,3
				c0,0,0.1,0,0.1,0h6.7"/>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M171,472v15.1c0,1.5-1.4,2.8-3.3,2.9
				c0,0-0.1,0-0.1,0h-3.6"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M256,471.1V487c0,1.6-1.4,3-3.3,3
				c0,0-0.1,0-0.1,0h-6.7"/>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M243.7,472v15.1c0,1.5,1.4,2.8,3.3,2.9
				c0,0,0.1,0,0.1,0h3.6"/>
		</g>
		<rect x="163.7" y="462.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="12" height="4"/>
		<rect x="238.7" y="462.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="12" height="4"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M218.8,469.6h-23.3c-0.5,0-0.9-0.4-0.9-0.9
			v-6.3c0-0.5,0.4-0.9,0.9-0.9h23.3c0.5,0,0.9,0.4,0.9,0.9v6.3C219.7,469.3,219.3,469.6,218.8,469.6z"/>
	</g>
</g>
<g id="Layer_4">
	<g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M205.2,171.6l24,1.1c0,0,23,1.6,27,14.7V260h-51"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M206.2,171.6l-24,1.1c0,0-23,1.6-27,14.7V260h51"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M205.2,205.6h16c0,0,6,0,13.1,0.8
				c4.6,0.5,8.9,2.3,11.9,5c1.3,1.2,2.4,2.6,3,4.2v24.8c0,0,0,1.5-2,1.5c0,0-17-1.5-23-1.5h-20"/>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M207.2,205.6h-16c0,0-6,0-13.1,0.8
				c-4.6,0.5-8.9,2.3-11.9,5c-1.3,1.2-2.4,2.6-3,4.2v24.8c0,0,0,1.5,2,1.5c0,0,17-1.5,23-1.5h20"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M159.2,258.4l0-67.7c0,0,0.3-3.2,3.3-3.2"/>
			<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="159.2" y1="258.1" x2="155.2" y2="260"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M251.7,258.4l0-68.7c0,0-0.2-2.2-3.2-2.2"/>
			<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="251.7" y1="258.1" x2="255.7" y2="260"/>
		</g>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="159.2" y1="257.8" x2="251.7" y2="257.8"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M155.8,218.9l-12.9,1.5
			c-0.8,0.1-1.6-0.5-1.7-1.3l0,0c-0.1-0.8,0.5-1.6,1.3-1.7l12.9-1.5c0.8-0.1,1.6,0.5,1.7,1.3l0,0C157.2,218,156.6,218.8,155.8,218.9
			z"/>
		<path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M254.5,218.9l12.9,1.5
			c0.8,0.1,1.6-0.5,1.7-1.3l0,0c0.1-0.8-0.5-1.6-1.3-1.7l-12.9-1.5c-0.8-0.1-1.6,0.5-1.7,1.3l0,0C253.1,218,253.7,218.8,254.5,218.9
			z"/>
		
			<rect x="156.2" y="263.1" fill="#9E9E9E" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="101" height="2.6"/>
		<rect x="181.2" y="259.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="7" height="3.5"/>
		<rect x="191.2" y="259.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="10" height="3.5"/>
		
			<rect x="223.2" y="259.6" transform="matrix(-1 -3.845869e-11 3.845869e-11 -1 453.3354 522.6625)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="7" height="3.5"/>
		
			<rect x="210.2" y="259.6" transform="matrix(-1 -3.892632e-11 3.892632e-11 -1 430.3354 522.6625)" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="10" height="3.5"/>
		<rect x="158.2" y="265.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="97" height="103.6"/>
		<rect x="160.2" y="267.4" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="93" height="100.2"/>
		<rect x="156.2" y="265.6" fill="#9E9E9E" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="2" height="3.5"/>
		<rect x="255.2" y="265.6" fill="#9E9E9E" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="2" height="3.5"/>
		<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="176" cy="194.5" rx="4.5" ry="4"/>
		<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="227" cy="194.5" rx="4.5" ry="4"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="162" y1="187.5" x2="249" y2="187.5"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M157,183.5h23.5c0,0,9-6,24-6"/>
		<path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M255.5,184.5l-25-1c0,0-11-6-26-6"/>
	</g>
</g>
      </>
    ),
  },
};
