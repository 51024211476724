import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import React, { useEffect } from "react";

interface IProps {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const ConfirmationDialog = (props: IProps) => {
  const [open, setOpen] = React.useState(props.isOpen);

  const isOpen = props.isOpen;

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
    props.onCancel();
  };

  const handleConfirm = () => {
    setOpen(false);
    props.onConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} disabled={props.isLoading} color="primary">
          {props.isLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
          Confirm
        </Button>
        <Button onClick={handleCancel} disabled={props.isLoading} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
