import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";
import theme from "../src/components/Theme";
import configureStore from "./store";
import DayjsUtils from "@date-io/dayjs";
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import errorImage from "./assets/images/car-broken-error-meta.png";
import { Fab, Typography } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import { SnackBarProvider } from "./components/SnackBarContext/SnackBarContext";
import HttpHeader from './header';
import configStore from "./store";
import { updateAuthStateAction } from './actions/auth/actions';
import { AuthStates } from './reducers/auth/types';
import { createBrowserHistory } from "history";

const store = configStore();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "develop"
});

const httpHeader = HttpHeader.getInstance();

const errorLink = onError(({ networkError, forward, operation, graphQLErrors }) => {

  if (graphQLErrors) {
    graphQLErrors.forEach(async ({ message, path, locations }) => {
      Sentry.captureMessage(message)
    })
  }

  if (networkError) {
    Sentry.captureException(networkError)
  }

  const unAuthenticated = graphQLErrors?.find((err) => err.extensions && err.extensions.code === "UNAUTHENTICATED");
  if (unAuthenticated) {
    httpHeader.resetHeaders();
    localStorage.clear();
    store.dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT, message: "Session expired, please sign in again" }))
    createBrowserHistory().push('/');
    window.location.reload();
  }

  forward(operation);
});

const setAuthorizationLink = setContext((request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    ...httpHeader.headers,
    authorization: `Bearer ${httpHeader.headers.authorization}`
  }
}));

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_SERVER
})
const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: ApolloLink.from([errorLink, setAuthorizationLink, httpLink]),
});

function ErrorFallback({ resetError }: any) {
  return (
    <div role="alert" className="flex cross-center col-flex error-message">
      <div className="react-error-image">
        <img alt="error message" src={errorImage} />
      </div>
      <h1>Oops!</h1>
      <span>Looks like something went wrong here</span>
      <div className="error-action flex col-flex cross-center">
        <Fab
          className="blackBackButton"
          variant="extended"
          size="medium"
          type="button"
          onClick={() => {
            resetError();
          }}

        >
          <span>Retry</span>
          <RefreshIcon fontSize="small" color="inherit" />
        </Fab>
        <div style={{ padding: 20 }}></div>
        <Typography variant="body2">Do not worry, we have captured the error.</Typography>
        <Typography variant="body2">We are constantly working on improving the product to make it better.</Typography>
      </div>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={configureStore()}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <BrowserRouter>
              <Sentry.ErrorBoundary fallback={ErrorFallback}>
                <SnackBarProvider>
                  <App />
                </SnackBarProvider>
              </Sentry.ErrorBoundary>
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
