import { gql } from "@apollo/client";

export const ASSIGN_DRIVER_TO_VEHICLE = gql`
  mutation assignDriverToVehicle($bookingScheduleId: ID!, $businessCustomerId: ID!, $licencePlate: String!, $driver: ID ,$note: String) {
    assignDriverToVehicle(bookingScheduleId: $bookingScheduleId, businessCustomerId: $businessCustomerId, licencePlate: $licencePlate, driver: $driver, note: $note) {
      driver {
        id
        note
      }
      id
    }
  }
`;