import {
  Divider,
  Grid,
  Theme,
  createStyles,
  makeStyles
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: "25px",
      marginTop: "25px"
    }
  })
);

export enum IOrientation {
  VERTICAL = "vertical",
  Horizontal = "horizontal"
}
interface IProps {
  noMargin?: boolean;
  variant?: DividerVariant;
  orientation?: IOrientation;
}

export enum DividerVariant {
  FULL_WIDTH = "fullWidth",
  INSET = "inset",
  MIDDLE = "middle"
}

export const NuvvenDivider = (props: IProps) => {
  const styles = useStyles();

  return (
    <Grid className={!props.noMargin ? styles.divider : ""} item xs={12}>
      <Divider
        orientation={props.orientation ? props.orientation : "horizontal"}
        variant={props.variant ? props.variant : DividerVariant.FULL_WIDTH}
      />
    </Grid>
  );
};
