export interface IAddonType {
  id?: string;
  name: string;
  category: string;
  displayName: string;
  imageUrl: string;
  hasFixedRate: boolean;
  hasQuantity: boolean;
  fixedRate?: number;
  description: string;
  recurringRate?: IAddonRate[];
  totalQuantity?: number;
  availableQuantity?: number;
  isActivated: boolean;
  taxable: boolean;
  tax: IAddOnTaxInput;
}

export interface IAddOnTaxInput {
  id?: string;
  title: string;
  type: string;
  value: number;
}

export interface IAddonRate {
  rateTypeName: string;
  rateTypeDuration: number;
  rate: number;
  longTerm: boolean;
}


export enum DefaultAddons {
  VALET = "VALET",
  GPS = "gps",
  BABY_SEAT = "baby seat",
  ADDITIONAL_DRIVER = "ADDITIONAL_DRIVER",
  CUSTOM_ADDON = "custom-addon"
}

export enum ValetType {
  DELIVERY = 'Delivery',
  COLLECTION =  'Collection',
  DELIVERY_AND_COLLECTION = 'Delivery and Collection'
}

export enum LocationType {
  PICKUP_LOCATION = 'pickupOtherLocation',
  DROP_OFF_LOCATION = 'dropoffOtherLocation',
}

export const valetTypes = [
  'Delivery',
  'Collection',
  'Delivery and Collection'
]