import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HttpHeader from "../../../header";
import { IAppState } from "../../../store";
import styles from "./index.module.css";
import { ResetPassword, SelectAccount, SetPassword, SignIn, VerifyOTP } from "./AuthPages";
import { AccountType, AuthStates, IUser } from "../../../reducers/auth/types";
import { updateAuthStateAction } from '../../../actions/auth/actions';
import { AuthRegister } from "./AuthRegister";
import { useHistory } from "react-router-dom";
import CustomerSelection from "./CustomerSelection";
import { SignUpBusinessCustomer } from "./NewBusinessCustomer/SignUpBusinessCustomer";
import * as Sentry from "@sentry/react";

interface IProps {
  onSignIn: () => void
}

export const history = createBrowserHistory();

const httpHeader = HttpHeader.getInstance();

const Authenticator: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const authState = useSelector((state: IAppState) => state.authReducer);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const history = useHistory();

  const authorize = (authUser: any, token: string) => {
    localStorage.setItem("auth", JSON.stringify({ state: AuthStates.SELECT_ACCOUNT, token, authUser }));
    httpHeader.setHeaders({ authorization: token, userId: authUser._id });
    dispatch(updateAuthStateAction({ state: AuthStates.SELECT_ACCOUNT, authUser, token }));
  }

  const signIn = (user: IUser, accountType: AccountType) => {
    localStorage.setItem("auth", JSON.stringify({ ...authState, state: AuthStates.SIGNED_IN, user, accountType }));
    dispatch(updateAuthStateAction({ ...authState, state: AuthStates.SIGNED_IN, user, accountType }));

    Sentry.setContext("org", {
      userId: user.id,
    });

    if (accountType === AccountType.BUSINESS) {
      history.push("/account")
    }
    props.onSignIn();
  }

  const handleAuthorise = (result: string, authUser?: any, token?: string) => {
    if (result === "OTP_SENT" && authUser) {
      dispatch(updateAuthStateAction({ state: AuthStates.OTP_SENT, authUser, message: "Due to some security related changes, you are required to reset your password after 4 Nov 21. Please verify OTP sent to your email to proceed" }));
    } else if (result === "SELECT_ACCOUNT" && token) {
      authorize(authUser, token);
    }
  }

  const handleVerifyOTP = (authUser: any, token: string) => {
    localStorage.setItem("auth", JSON.stringify({ state: "SET_PASSWORD", token, authUser }));
    dispatch(updateAuthStateAction({ state: AuthStates.SET_PASSWORD, authUser, token }));
  }

  const handleSetPassword = (authUser: any, token: string) => {
    authorize(authUser, token);
  }

  const handleResetInitiate = (authUser: any) => {
    dispatch(updateAuthStateAction({ state: AuthStates.OTP_SENT, authUser }));
  }

  const logoSize = website.logoSize || 40
  const topBarHeight = logoSize > 50 ? logoSize + 20 : 70;

  return (
    <>
      {authState.state === AuthStates.SIGNED_OUT && <SignIn onAuthorize={handleAuthorise} />}
      {authState.state === AuthStates.SELECT_ACCOUNT && <SelectAccount onSignIn={signIn} />}
      {authState.state === AuthStates.OTP_SENT && authState.authUser && <VerifyOTP email={authState.authUser.email} onVerifyOTP={handleVerifyOTP} />}
      {authState.state === AuthStates.SET_PASSWORD && authState.authUser && authState.token && <SetPassword email={authState.authUser.email} token={authState.token} onSetPassword={handleSetPassword} />}
      {authState.state === AuthStates.RESET_PASSWORD && <ResetPassword onResetPasswordInitiate={handleResetInitiate} />}
      {authState.state === AuthStates.SIGN_UP && <AuthRegister />}
      {authState.state === AuthStates.CUSTOMER_SELECTION && <CustomerSelection />}
      {authState.state === AuthStates.BUSINESS_CUSTOMER && <SignUpBusinessCustomer />}
    </>
  );
};

export default React.memo(Authenticator);
