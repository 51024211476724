import React from "react"

export const ConvertableSVG = {
  interior: {
    width: 287,
    height: 650,
    svg: (<><g>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.7,6.9c0,0,63.9,2.4,103.4,41.9c0,0,14.6,10.7,20.5,25.2l2,100.9
      l-3.1,55.9l-1.3,81.4l-2,106.7c0,0,4.6,28.6,6.9,35.7l0.5,74.3l-1.3,24l-14.6,44.2l-4.9,11.7c0,0-0.8,8.9-8.4,11.7
      c0,0-45.6,20.9-97.7,21.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.8,6.9c0,0-63.8,2.4-103.3,41.9c0,0-14.6,10.7-20.5,25.2
      l-2,100.9l3.1,55.9l1.3,81.4l2,106.7c0,0-4.6,28.6-6.9,35.7L16,528.9l1.3,24l14.6,44.2l4.9,11.7c0,0,0.8,8.9,8.4,11.7
      c0,0,45.8,20.9,97.8,21.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.6,17.1c-26.8-0.2-64.9,13.7-64.9,13.7
      C52.9,41.5,33,66.3,33,66.3c-2.3,0,0.5-8.2,0.5-8.2S64.4,16.5,143.1,8.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.6,17.1c27-0.3,65.4,13.7,65.4,13.7
      c24.8,10.7,44.7,35.5,44.7,35.5c2.3,0-0.5-8.2-0.5-8.2S221.6,16.5,142.9,8.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M143.3,29.5c0,0-24-0.5-59,18.6c0,0-29.9,12.5-40.1,47.7
      c0,0-6.1,24.8-8.7,50.6l-1.3,46.2L46.5,210"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M22.3,67.6c0,0,0.5,28.1,22,28.3"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M143.2,29.5c0,0,24-0.5,59,18.6c0,0,29.9,12.5,40.1,47.7
      c0,0,6.1,24.8,8.7,50.6l1.3,46.2L240,210.1"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M263.5,66.6c0,0-0.5,28.1-22,28.3"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.1,191.9l-40.6,0.5c0,0-31.4,4.6-40.3,9.2l-14,7.9
      c0,0-5.6,2.3-5.1,18.9c0,0-7.1,3.6-4.9,12.3l17.4,78.4c0,0,1.5,3.8,6.1,2.3c0,0,37.2-16,81.4-16"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.1,191.9l40.6,0.5c0,0,20.6,3.7,33.8,7c4.5,1.1,8.9,2.8,12.9,5.1
      l10.3,5.7c0,0,5.5,6,4,19.3c0,0,7.4,2.4,5.1,11.1L230.9,319c0,0-1.6,3.8-6.3,2.3c0,0-37.3-15.9-82.7-15.9"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M221,223.6c-29.6-10.5-78.8-10.8-78.8-10.8s-47.9,0.3-77.5,10.8
      c0,0-16.9,2-14.8,18.1l10.5,41.6c0,0-0.5,10,9.2,6.9c0,0,33.7-11.4,73.5-9.9v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
      c39.8-1.5,73.1,9.9,73.1,9.9c9.7,3.1,9.2-6.9,9.2-6.9l10.5-41.6C237.8,225.6,221,223.6,221,223.6z"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M41.9,228.8l17.2,64.1c0,0,1.7,7,9.9,6c0,0,32.8-11.3,73.8-10.5"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M243.5,229.9l-16.7,63c0,0-1.7,7-9.9,6c0,0-33.2-11.3-74.2-10.5"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M88.9,46.1c0,0-32.7,22.5-44.4,71.7c0,0-7.1,35-8.7,78.9l4.6,193.8
      l4.1,66.9l4.9,48c0,0,1.5,28.3,31.9,39.6c0,0,15.3,4.3,64.6,5.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M196.9,46.1c0,0,32.7,22.5,44.4,71.7c0,0,7.1,35,8.7,78.9l-4.6,193.8
      l-4.1,66.9l-4.9,48c0,0-1.5,28.3-31.9,39.6c0,0-15.3,4.3-64.6,5.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M37,73.1c0,0-12.3,116.4-6.8,176.7l5.4,156.9l8.5,91.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M248.7,72.8c0,0,12.3,116.4,6.8,176.7l-5.4,156.9l-8.5,91.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M19.6,229.4c0,0,11.6,0.3,17,11.9"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M266.1,229.7c0,0-11.6,0.3-17,11.9"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M44.3,457.1l-9.2,10.2c0,0-3.3,3.1-3.6,14.8l5.4,58
      c0,0,0.3,20.4,15.6,24.3c0,0,31.9,5.1,40.9,5.6c8.9,0.5,51.1,0,51.1,0"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M242.1,457.1l9.2,10.2c0,0,3.3,3.1,3.6,14.8l-5.4,58
      c0,0-0.3,20.4-15.6,24.3c0,0-31.9,5.1-40.9,5.6c-8.9,0.5-51.1,0-51.1,0"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="40" y1="404.4" x2="22.8" y2="419.8"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="244.7" y1="404.2" x2="262.5" y2="419.5"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M30.5,413.4c0,0-2.6,65.7-0.3,100.4c0,0,10.2,73.3,15.8,88.7"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M255.4,413.4c0,0,2.6,65.7,0.3,100.4c0,0-10.2,73.3-15.8,88.7"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M45.3,602.2c0,0,20.7,18.4,56,25.3c0,0,15.6,2.6,45,2.8"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M41,581.5c0,0-0.5-5.9,3.6-4.9c0,0,12.8,16.6,35.5,24.8
      c0,0,28.9,10.7,64.7,10.5"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M240.3,602.2c0,0-20.7,18.4-56,25.3c0,0-15.6,2.6-45,2.8"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M244.6,581.5c0,0,0.5-5.9-3.6-4.9c0,0-12.8,16.6-35.5,24.8
      c0,0-28.9,10.7-64.7,10.5"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M51.9,585.1l30.9,29.5c0,0,10.3,11.4,59.9,11.9"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M233.3,585.1l-30.9,29.5c0,0-10.3,11.4-59.9,11.9"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M28.8,545.1c0,0,6.7,47,16.6,57.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M256,546c0,0-6.7,47-16.6,57.2"/>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M45.5,456.4h25.9c0,0-2.7,30.6,16.7,35.7h56.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M241.2,456.4h-25.9c0,0,2.7,30.6-16.7,35.7h-56.2"/>
    </g>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M69.8,457c0,0,4.7,54.7,9,69.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M215.6,457c0,0-4.7,54.7-9,69.6"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M50.2,456.7l7,54.9c0,0,4,22.5,33.9,29.9c0,0,19,2.5,53.2,2.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M235.2,456.7l-7,54.9c0,0-4,22.5-33.9,29.9c0,0-19,2.5-53.2,2.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M36.9,419.5c0,0-2.5,45,3.3,89.4l7.8,52.9l4.2,23.5"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M248.9,419.7c0,0,2.2,44.8-3.6,89.2l-7.8,52.9l-4.2,23.5"/>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M60.7,321.4c0,0,1.5,22.3,4.7,26.4c0,0-2.2,0.2-2.7,4.7v27.2
        c0,0-0.7,24.7,16.6,27.2H96"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M67.4,319.2c0,0-0.2,22.5,1.2,28.2l-2.4,0.7c0,0,4.5,1.3,7.3,2.8
        c1.4,0.7,2.8,1.2,4.3,1.4c3.8,0.6,9.6,0.7,15.8,0.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="73.9" y1="350.8" x2="73.9" y2="316.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M80.2,406.4c0,0-0.9-1.1-1.8-2.4c-0.9-1.4-1.4-3.1-1.4-4.8v-8
        c0-1.5,0.5-3,1.7-4c0.6-0.5,1.3-1,2.2-1.2c3-0.6,7.8-0.5,7.8-0.5c-7.9-1.5-9.8-2.7-9.8-2.7c-10.9-5.8-12.7-17-12.7-17v-12
        c-0.1-3.3,2.1-4.9,2.1-4.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M74.1,333.7c0,0,6.3-5.1,19.1-5.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M75.3,351.6v10.5c0,1-0.3,1.9-0.7,2.8c-0.4,0.7-0.9,1.8-1.3,2.9
        c-0.6,1.7-0.2,3.7,0.9,5.1c1.3,1.8,3.7,4.4,7.4,6.8c0.9,0.6,2,0.9,3.2,0.9H93"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M82.4,353.2V372c0,0-3.7-0.1-5,4.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M82.3,371.9c0,0,2.6,2,11.4,1.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M123.6,306.4c0,0,3.4,36.5-2.2,41.5c0,0,2.2,0.2,2.7,4.7v27.2
        c0,0,0.7,24.7-16.6,27.2H90.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M119.3,306.7c0,0,0.4,35-1.1,40.7l2.4,0.7c0,0-4.5,1.3-7.3,2.8
        c-1.4,0.7-2.8,1.2-4.3,1.4c-3.8,0.6-9.1,0.7-15.3,0.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="112.8" y1="350.8" x2="112.8" y2="307.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M106.6,406.4c0,0,0.9-1.1,1.8-2.4c0.9-1.4,1.4-3.1,1.4-4.8v-8
        c0-1.5-0.5-3-1.7-4c-0.6-0.5-1.3-1-2.2-1.2c-3-0.6-7.8-0.5-7.8-0.5c7.9-1.5,9.8-2.7,9.8-2.7c10.9-5.8,12.7-17,12.7-17v-12
        c0.1-3.3-2.1-4.9-2.1-4.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M112.7,333.7c0,0-6.9-5.1-19.6-5.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M111.5,351.6v10.5c0,1,0.3,1.9,0.7,2.8c0.4,0.7,0.9,1.8,1.3,2.9
        c0.6,1.7,0.2,3.7-0.9,5.1c-1.3,1.8-3.7,4.4-7.4,6.8c-0.9,0.6-2,0.9-3.2,0.9h-9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M104.4,353.2V372c0,0,3.7-0.1,5,4.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M104.5,371.9c0,0-2.6,2-11.4,1.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="82.6" y1="365.1" x2="104.5" y2="365.1"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="82.6" y1="356.4" x2="104.5" y2="356.4"/>
    </g>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M224.6,321.4c0,0-1.5,22.3-4.7,26.4c0,0,2.2,0.2,2.7,4.7v27.2
        c0,0,0.7,24.7-16.6,27.2h-16.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M218,319.2c0,0,0.2,22.5-1.2,28.2l2.4,0.7c0,0-4.5,1.3-7.3,2.8
        c-1.4,0.7-2.8,1.2-4.3,1.4c-3.8,0.6-9.6,0.7-15.8,0.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="211.4" y1="350.8" x2="211.4" y2="316.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M205.2,406.4c0,0,0.9-1.1,1.8-2.4c0.9-1.4,1.4-3.1,1.4-4.8v-8
        c0-1.5-0.5-3-1.7-4c-0.6-0.5-1.3-1-2.2-1.2c-3-0.6-7.8-0.5-7.8-0.5c7.9-1.5,9.8-2.7,9.8-2.7c10.9-5.8,12.7-17,12.7-17v-12
        c0.1-3.3-2.1-4.9-2.1-4.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M211.3,333.7c0,0-6.8-5.2-19.5-5.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M210.1,351.6v10.5c0,1,0.3,1.9,0.7,2.8c0.4,0.7,0.9,1.8,1.3,2.9
        c0.6,1.7,0.2,3.7-0.9,5.1c-1.3,1.8-3.7,4.4-7.4,6.8c-0.9,0.6-2,0.9-3.2,0.9h-8.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M203,353.2V372c0,0,3.7-0.1,5,4.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M203.1,371.9c0,0-2.6,2-11.4,1.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M161.8,306.4c0,0-3.4,36.5,2.2,41.5c0,0-2.2,0.2-2.7,4.7v27.2
        c0,0-0.7,24.7,16.6,27.2h16.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M166.1,306.7c0,0-0.4,35,1.1,40.7l-2.4,0.7c0,0,4.5,1.3,7.3,2.8
        c1.4,0.7,2.8,1.2,4.3,1.4c3.8,0.6,9.1,0.7,15.3,0.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="172.5" y1="350.8" x2="172.5" y2="307.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M178.7,406.4c0,0-0.9-1.1-1.8-2.4c-0.9-1.4-1.4-3.1-1.4-4.8v-8
        c0-1.5,0.5-3,1.7-4c0.6-0.5,1.3-1,2.2-1.2c3-0.6,7.8-0.5,7.8-0.5c-7.9-1.5-9.8-2.7-9.8-2.7c-10.9-5.8-12.7-17-12.7-17v-12
        c-0.1-3.3,2.1-4.9,2.1-4.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M172.6,333.7c0,0,6.4-5.2,19.1-5.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M173.9,351.6v10.5c0,1-0.3,1.9-0.7,2.8c-0.4,0.7-0.9,1.8-1.3,2.9
        c-0.6,1.7-0.2,3.7,0.9,5.1c1.3,1.8,3.7,4.4,7.4,6.8c0.9,0.6,2,0.9,3.2,0.9h9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M181,353.2V372c0,0-3.7-0.1-5,4.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M180.8,371.9c0,0,2.6,2,11.4,1.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="202.7" y1="365.1" x2="180.8" y2="365.1"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="202.7" y1="356.4" x2="180.8" y2="356.4"/>
    </g>
    <g>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="71.8,404.6 71.8,455.8 79.5,455.8 79.5,406.8 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M76.7,456.2v9.2c0,0,1,16.1,23.7,18.7h6.4c0,0,6.6-0.9,9.5-1.7
        c0,0,10.2-4.7,10.9-17.5V455"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="124.8,455.1 124.8,396.9 119.8,396.9 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M79.4,455.5c0,0,4.1,3.4,8.5,5.1c0.8,0.3,2.7,0.8,3.6,0.8l10.3,0.1"
        />
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M125,454.9c0,0-4.3,4.1-8.7,5.7c-0.8,0.3-2.7,0.8-3.6,0.8l-10.8,0.1
        "/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M79.7,448.8c0,0,2.1-4.2,9.7-5.8h13.8"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M124.9,449c0,0-2.5-4.4-10.1-5.9h-13.8"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M79.6,428.1c0,0,2.1-4.2,9.7-5.8h13.8"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M124.7,428.3c0,0-2.5-4.4-10.1-5.9h-13.8"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M85.8,460.3v6.5c0,0-2.2,1.7-2.2,4.3c0,0,0.5,5.4,9,8.6
        c0,0,5.1,1.4,10.5,1.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M118.9,460.3v6.5c0,0,2.2,1.7,2.2,4.3c0,0-0.5,5.4-9,8.6
        c0,0-5.1,1.4-10.5,1.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="91.7" y1="461.2" x2="91.7" y2="478.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="113" y1="461.6" x2="113" y2="479.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M71.4,430.2c0,0-0.9-18.3-19.4-30.7c-0.9-0.6-2-0.8-3-0.7
        c-2.1,0.3-5.9,2.4-9,5.6"/>
    </g>
    <g>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="213.4,404.6 213.4,455.8 205.8,455.8 205.8,406.8 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M208.5,456.2v9.2c0,0-1,16.1-23.7,18.7h-6.4c0,0-6.6-0.9-9.5-1.7
        c0,0-10.2-4.7-10.9-17.5V455"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="160.4,455.1 160.4,396.9 165.4,396.9 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M205.8,455.5c0,0-4.1,3.4-8.5,5.1c-0.8,0.3-2.7,0.8-3.6,0.8
        l-10.3,0.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M160.2,454.9c0,0,4.3,4.1,8.7,5.7c0.8,0.3,2.7,0.8,3.6,0.8l10.8,0.1
        "/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M205.5,448.8c0,0-2.1-4.2-9.7-5.8h-13.8"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M160.4,449c0,0,2.5-4.4,10.1-5.9h13.8"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M205.7,428.1c0,0-2.1-4.2-9.7-5.8h-13.8"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M160.5,428.3c0,0,2.5-4.4,10.1-5.9h13.8"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M199.4,460.3v6.5c0,0,2.2,1.7,2.2,4.3c0,0-0.5,5.4-9,8.6
        c0,0-5.1,1.4-10.5,1.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M166.3,460.3v6.5c0,0-2.2,1.7-2.2,4.3c0,0,0.5,5.4,9,8.6
        c0,0,5.1,1.4,10.5,1.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="193.5" y1="461.2" x2="193.5" y2="478.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="172.2" y1="461.6" x2="172.2" y2="479.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M213.8,430.2c0,0,0.9-18.3,19.4-30.7c0.9-0.6,2-0.8,3-0.7
        c2.1,0.3,5.9,2.4,9,5.6"/>
    </g>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="125" y1="455.2" x2="160.6" y2="455.2"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="127.9" y1="306.3" x2="127.9" y2="370.6"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="157.9" y1="306.5" x2="157.9" y2="370.8"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M158,370.5l-30,0c0,0-0.6,18.5,5.1,20.7h10.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M128,370.5l30,0c0,0,0.6,18.5-5.1,20.7h-10.4"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="127.4" y1="355" x2="157.8" y2="354.9"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M132.8,390.8c0,0,4.6,18.9,2.6,64.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M152.7,390.7c0,0-4.6,18.9-2.6,64.4"/>
    <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="49.6,398 49.6,308.7 43.8,302.9 44.1,400.4 44.7,457.4 	"/>
    <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="43.3,270 39.8,270.1 41.5,402.5 	"/>
    <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="235.8,398 235.8,308.7 241.6,302.9 241.3,400.4 
      240.7,457.4 	"/>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M68.1,456.2v-15.8c0,0-0.2-3.2-3.8-2.8h-5.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M51.6,456.2v-15.8c0,0,0.2-3.2,3.8-2.8h5.1"/>
    </g>
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M233.5,457.3v-15.8c0,0-0.2-3.2-3.8-2.8h-5.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M217.1,457.3v-15.8c0,0,0.2-3.2,3.8-2.8h5.1"/>
    </g>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="43.8" y1="302.5" x2="42.7" y2="270.2"/>
    <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="241.6,302.4 242.1,270.1 245.6,270.1 243.8,402.5 	"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M142.3,477.1h9.8c0,0,6.3,8.9,7.3,36.8v29.7"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M143.4,477.1h-9.8c0,0-6.3,8.9-7.3,36.8v29.7"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M128.1,549.9v18.5c0,0,1.6,30.9,5.1,42.7"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M157.2,549.9v18.5c0,0-1.6,30.9-5.1,42.7"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M64.3,615.2c0,0-2.2,4.7-6.1,4.3l-15.9-8.5c0,0-4.3-4.3-5.3-10.2
      l-1.6-6.7c0,0-0.2-3.3,3.7-0.4l1.4,1"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M71.8,618.9c0,0,1,7.7,7.3,9.2c0,0,8.5,2.8,23.4,0.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M220.3,615.2c0,0,2.2,4.7,6.1,4.3l15.9-8.5c0,0,4.3-4.3,5.3-10.2
      l1.6-6.7c0,0,0.2-3.3-3.7-0.4l-1.4,1"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M212.8,618.9c0,0-1,7.7-7.3,9.2c0,0-8.5,2.8-23.4,0.4"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M29.7,401.2v-32.6c0,0-4.3,1.8-4.7,3.6v26.8
      C25.1,398.9,26.6,400.8,29.7,401.2z"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M256.1,401.2v-32.6c0,0,4.3,1.8,4.7,3.6v26.8
      C260.7,398.9,259.2,400.8,256.1,401.2z"/>
    <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M31.9,266.5l3.7-6.1c0.4-0.6,0.4-1.4-0.1-2l-3.6-5.2
      c-0.3-0.4-0.9-0.4-1.2-0.1l-8.6,10.1c0,0,0,0-0.1,0c-3.4,0.7-6.5,2.4-9.1,4.7l-4.4,4c-0.7,0.6-1,1.4-1,2.3v5.9c0,1.1,1.1,2,2.2,1.7
      l21.4-5.5c0.9-0.2,1.5-1,1.4-1.9c-0.1-1.9-0.3-5.3-0.8-8L31.9,266.5z"/>
    <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M254.1,266.5l-3.7-6.1c-0.4-0.6-0.4-1.4,0.1-2l3.6-5.2
      c0.3-0.4,0.9-0.4,1.2-0.1l8.6,10.1c0,0,0,0,0.1,0c3.4,0.7,6.5,2.4,9.1,4.7l4.4,4c0.7,0.6,1,1.4,1,2.3v5.9c0,1.1-1.1,2-2.2,1.7
      l-21.4-5.5c-0.9-0.2-1.5-1-1.4-1.9c0.1-1.9,0.3-5.3,0.8-8L254.1,266.5z"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M100.5,192l-0.1-85.6c0,0,6.3-58.8,22.7-87.2"/>
    <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M185.1,192l0.1-85.6c0,0-6.3-58.8-22.7-87.2"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="78.6" y1="30.3" x2="81.2" y2="23.5"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="113.8" y1="20.5" x2="115.4" y2="12.8"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="132.9" y1="17.3" x2="134.3" y2="9.4"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="78.6" y1="30.3" x2="81.2" y2="23.5"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="113.8" y1="20.5" x2="115.4" y2="12.8"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="132.9" y1="17.3" x2="134.3" y2="9.4"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="206.6" y1="30.2" x2="204.1" y2="23.4"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="171.4" y1="20.4" x2="169.8" y2="12.6"/>
    <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="152.3" y1="17.2" x2="151" y2="9.2"/>
  </g></>)
  },

  exterior: {
    width: 781,
    height: 1230,
    svg: (<><g id="Layer_1">
    <g>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="40.7" y1="471.4" x2="41.2" y2="459.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="43.2" y1="378" x2="43.2" y2="370.8"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="46.7" y1="743.5" x2="46.7" y2="751"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="51.3" y1="846.7" x2="51.3" y2="838.1"/>
      <g>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="64.8" cy="419.4" rx="46" ry="46"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="65.1" cy="419.7" rx="37.1" ry="36.9"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="66.4" cy="419.4" rx="11.9" ry="11.8"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="66" cy="419.5" rx="3" ry="3"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="67.7" cy="426.8" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="59.3" cy="423.9" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="59.7" cy="414.9" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="67.5" cy="411.9" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="74.3" cy="419.5" rx="2.2" ry="2.2"/>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="77.3" y1="414.1" x2="101.6" y2="412.3"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="78.4" y1="417.1" x2="102.2" y2="417.1"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="78.2" y1="421.6" x2="102.4" y2="421.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="101.7" y1="425.8" x2="77.5" y2="424.4"/>
        </g>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="64.9" y1="407.5" x2="67.9" y2="383.1"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="67.7" y1="407.2" x2="73.3" y2="384.2"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="72" y1="408.5" x2="78.3" y2="385.1"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="83.1" y1="387.1" x2="73.8" y2="409.8"/>
        </g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="59.4" y1="409.5" x2="40.9" y2="391.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="57.4" y1="411" x2="36.9" y2="395.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="55.6" y1="414.5" x2="34.2" y2="399.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="54.6" y1="416.9" x2="31.6" y2="405"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="72.9" y1="429.3" x2="85" y2="450.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="70.8" y1="430.4" x2="79.4" y2="453.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="67.7" y1="431.3" x2="73.2" y2="455.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="65.3" y1="431.4" x2="67.1" y2="456.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="57" y1="427.3" x2="35.9" y2="442.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="59.1" y1="429" x2="40.8" y2="447.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="55.4" y1="424.5" x2="33.1" y2="438.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="54.6" y1="421.6" x2="30.7" y2="433.5"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="64.6" cy="794" rx="46" ry="46"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="64.8" cy="794.2" rx="37.1" ry="36.9"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="66.2" cy="793.9" rx="11.9" ry="11.8"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="65.8" cy="794" rx="3" ry="3"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="67.5" cy="801.3" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="59" cy="798.4" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="59.4" cy="789.5" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="67.3" cy="786.4" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="74" cy="794" rx="2.2" ry="2.2"/>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="77" y1="788.6" x2="101.3" y2="786.8"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="78.2" y1="791.6" x2="101.9" y2="791.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="77.9" y1="796.2" x2="102.1" y2="796.2"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="101.4" y1="800.4" x2="77.3" y2="798.9"/>
        </g>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="64.6" y1="782" x2="67.6" y2="757.6"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="67.4" y1="781.7" x2="73.1" y2="758.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="71.7" y1="783.1" x2="78" y2="759.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="82.9" y1="761.7" x2="73.6" y2="784.4"/>
        </g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="59.1" y1="784" x2="40.7" y2="765.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="57.2" y1="785.6" x2="36.6" y2="770.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="55.3" y1="789.1" x2="33.9" y2="773.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="54.4" y1="791.5" x2="31.3" y2="779.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="72.6" y1="803.9" x2="84.7" y2="825.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="70.5" y1="805" x2="79.1" y2="828.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="67.4" y1="805.9" x2="73" y2="830.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="65" y1="805.9" x2="66.8" y2="831.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="56.7" y1="801.9" x2="35.7" y2="817.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="58.9" y1="803.5" x2="40.5" y2="821.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="55.1" y1="799" x2="32.9" y2="812.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="54.3" y1="796.2" x2="30.5" y2="808.1"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M106.2,494.1v-6.6c0-1.2,0.9-2.1,2.1-2.1h0c1.2,0,2.1,0.9,2.1,2.1
        v6.6c0,1.2-0.9,2.1-2.1,2.1h0C107.2,496.2,106.2,495.2,106.2,494.1z"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="132.4" cy="673.3" rx="6.1" ry="9.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M129.5,688.3v-25.3c0-1.5,1.2-2.7,2.7-2.7l0,0
        c1.5,0,2.7,1.2,2.7,2.7v25.3c0,1.5-1.2,2.7-2.7,2.7l0,0C130.7,691,129.5,689.8,129.5,688.3z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M160.7,870.4c0,0,6.3,8.9,6.6,23.9h-5.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M153.3,678.4h7.7c3.9,0,7.7,0.8,11.2,2.6c2.3,1.2,4.4,2.9,5.1,5
        c0,0,2.4,4.3-7.1,5.6l-16.6,2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M152.8,676.2h11.1c3.9,0,7.7,1.1,10.9,3.2c2.4,1.6,4.8,3.9,5.6,6.9
        c0,0,2.4,5.7-7.1,7.4l-19,2.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M42.3,371.1l4.5-1.5h23.9c28.1,6.4,44.7,24.3,44.8,50.4
        c0.1,28.2-23.2,50.7-45.8,51.8H40.6l5.9,271.7l24,0.3c29.4,5.1,48.5,25.8,48.6,52c0.1,28.2-23.2,50.1-45.8,51.2H51.2l0,6.4
        c-2.1,3.1-2,6.5-2,6.5l0.3,28.2c2,4.9,11.6,11,11.6,11l-1.8,6h-2.7l-0.1,1.4l1.8,3.9c2.3,19,17.4,16.9,17.4,16.9h9.9l0.9,1.1
        c2.7,3.7,6.5-2.6,6.5-2.6h8.5c0,0,11.6,0.9,15.1,4.4s6.6,0.1,6.6,0.1l8.3-9.9h25c1.8-1.3-0.8-9.2-0.8-9.2
        c-1.3-12.5-0.3-53.9-0.3-53.9V752.3c-0.8-10.5-6.9-54.9-6.9-54.9c-2-15.6-3.1-51.1-3.1-51.1l-1.8-123.1l-1.5-36l-3.8-61.5
        c0-10-5.1-40.3-5.1-40.3c-1-7.4-9.4-30.6-9.4-30.6c-2.6-8.7-7.9-44.7-7.9-44.7s0.6-3.3-2-5.5c0,0-5.1-3.1-8.5-3.7
        c0,0-3.6,0.5-4.6,2c0,0-3.1,1.4-6-0.5l-4.3-2.2c0,0-1-5.8-4.3-5.6H72c0,0-2.2,0.2-2.4,4.3v6h-4.6v1.7h-4.9v1.2h-5.6
        c0,0-1-7.5-3.6-8.7c0,0-3.6,0.3-3.9,3.4L42.3,371.1z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M115.9,310.1c0,0,19.1,34.1,26.6,93.4c0,0,8,64,8,79h-5.8l1.5,17.9"
        />
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M123.1,327.2c0,0,3.6,29.8,11.6,54.8c0,0,9,46.6,10,100.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M149.7,549.8c0,0,1,110.2,3.7,143.1l7.1,52.9l2.6,93.8l-2.7,1.3
        l-0.2,28.7c0,0-2,9.7,2.3,27.2c0,0,2.7,13.4,2.7,21.9c0,0-0.9,5.4-3.3,0.9l-0.8-12.9h-5.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M111.9,303.5l6,44.8c0,0,2,8.9-1.2,8.7c0,0-10.4-5.1-19.9-26.2
        c0,0-8.7-19.2-11.1-36.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M113.3,314.8c0,0-1.8,4.8-4.4,8.1c-2.2,2.8-6.1,3.1-8.7,0.7
        c-3.1-2.9-6.6-9-9.5-23.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M72.6,294.2v19l-13.1,13.1l0.6,16.3h23.2c0,0,4.7,3.8,6.8,5
        c0,0,3.6-0.3,1.9-4.5l-13.1-21.6c0,0-3.8-5.2-10.5-4.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M87,345.4H61l-4.1,4.3l-0.1,4.9c0,0-1.8,3.3-2.7,1.8L51,299.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="69.7" y1="304.6" x2="69.7" y2="316.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="64.7" y1="306.6" x2="64.7" y2="317.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="60.1" y1="308" x2="60.1" y2="317.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="68.8" y1="317.1" x2="51.9" y2="317.1"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="54.5" y1="307.2" x2="54.5" y2="317.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M42.9,364.1H70c25.7,2.7,50.4,25.4,50.4,56.9S94.7,477.8,68,479.5
        H40.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M70,364.1c0,0-1.1,3.3,6,7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M46,737.1l29.2,0.2c25.7,2.7,50.4,25.6,50.4,57.6
        S99.8,852.3,73.1,854H50.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M152.4,919.9c0,0-2-26.4-7.5-37.1c0,0-2.9-2.6-5.3-1
        c0,0-7.8,4.6-15.7,23.5c0,0-1.9,8.9-1.9,26.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M100.3,925.9L100,903c0,0-4.9-26.7-4.9-33.9l-0.1-8.6
        c0-1.4-0.3-2.7-1-3.9c-0.7-1.3-2-2.5-3.9-2.1c0,0-2.5-0.1-3.8,2.6c-0.5,1-0.6,2-0.6,3.1L88,902l1.4,27.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="123.6" y1="906.1" x2="100.1" y2="904.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M87.6,901.8l-1.7-0.2l-18.8-17.2c0,0-4.9-25.7-5.2-29.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M85.4,927.2v-13.5c0,0,0.1-6.7-5-10.1l-14.4-13
        c-1.4-1.3-2.6-2.9-3.3-4.7C60.4,880,55,866,52.4,854.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M53.1,857.8c0,0,2.5,19.5,4.8,27.7c0.6,2,1.7,5.5,3.4,6.7
        c5.6,4,20.2,15.1,20.8,23.6V927"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="78.2" y1="907.6" x2="78.2" y2="927.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="61.3" y1="899.3" x2="78.4" y2="914.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="78.2" y1="922.5" x2="62.7" y2="922.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M71.1,907.9v9.9c0,0,0.2,1.7-1.5,1.9H61"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="70.6" y1="916" x2="59.4" y2="916"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="70.9" y1="911.3" x2="58.4" y2="911.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="71.4" y1="916" x2="78.4" y2="916"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M94.5,379.1v-16.9c0,0,0.3-3.4,5.3-1.9l33.2,23.3"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="44.8,336.7 39.2,336.7 39.2,363.9 44.6,363.9 		"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="61.3,471.9 62.2,529.2 71.5,679.4 73.4,736.9 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M61.7,481.2c0,0,15.5,25.7,18.6,41.4l6.5,68.9l10.4,113.7
        c0,0,2,35.1,2,49.9"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="55,743.2 49.7,610.4 44.1,471.2 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M49.4,471.5l3.4,115.7l3.4,80.5c0,0,1.5,15,15,28.6
        c0,0,4.1,4.8,24.3,9c0,0,11.9,1.5,22,2.4c7.9,0.7,15.8-0.8,22.8-4.5c2.8-1.5,5.6-3.3,8-5.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M140.3,703.3c0,0,11.9,51.8,14.9,94.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M131.8,888.9L113.2,861c0,0-7-9-6.8-22.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M139.3,837.6c0,0,11.6,41.4,15.3,61.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M51.8,521.9c0,0,6.3-1.7,14.1-0.9c0,0,11.4,2.6,17.7,0.9
        c0,0,39-7,60.2,2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M123.9,355.1c0,0,2.4,31.1,5.4,38.3c0,0,2.1,5.4,6.4,7.4"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="111.3,319.5 111.3,325.8 104.2,334 104,343.6 		"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="115" y1="327.7" x2="104.2" y2="340.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M113.5,329.2l0,14.4c0,0,1.8,1.5,1,6.4h3.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="113.5" y1="343.6" x2="107" y2="348.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M134,891.1c4.4-2.8,10.3-0.8,12.2,4.1c0.7,1.7,1.3,3.9,1.3,6.4
        c0,0,0.1,11-4.8,14.4h-11.1l-3.9,3.9v-15.7c0-1.1,0.2-2.3,0.5-3.4c0.8-2.4,2.5-6.7,5.4-9.4C133.6,891.4,133.7,891.2,134,891.1z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M140.3,915.4v-8.5c0,0-4-1.4-4-1.1s0,9.9,0,9.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="111.2" y1="318.6" x2="93.4" y2="311.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="109.3" y1="322.4" x2="95.8" y2="317.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="112.6" y1="309.1" x2="98.6" y2="303.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="112.9" y1="313.8" x2="92" y2="306"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="94.9" y1="302.7" x2="94.9" y2="314.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="99.4" y1="303.4" x2="99.4" y2="322.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="103.6" y1="301.1" x2="103.6" y2="325.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="107.2" y1="301.2" x2="107.2" y2="324.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="110.7" y1="302.8" x2="110.7" y2="319.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M147.1,499.9l25.5,44.4l17.7,35.6v14.5c0,0-1.4,3.2-3.1,4.6v8.2
        c0,0-1.2,4.9-9.4,4.4c0,0,5.4-7.1-31.7-67.6L147.1,499.9z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M187.4,575l0.4,10.3c0,0,0,0,0,0c-0.5,1.6-2.8,1.7-3.5,0.2
        c-3.7-8.1-25.4-54.9-37.4-66.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M146.8,527.7c0,0,34.6,54,40.1,79.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M146.8,531.5c0,0,31,50.4,39.1,77.9"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M143.6,541.3c0,0-4.5,2.4-4.5,9c0,6.6,7.7,12.9,7.7,12.9l0.2,6
        h12c0,0,3.1,0.8,4.6-2.3v-6.5c0,0-1.4-8.1-8.3-8.8l-5.8-10C149.5,541.6,146.8,539.7,143.6,541.3z"/>
    </g>
    <g>
      <line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="743.2" y1="471.4" x2="742.7" y2="459.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="740.6" y1="378" x2="740.6" y2="370.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="737.1" y1="743.5" x2="737.1" y2="751"/>
      <line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="732.6" y1="846.7" x2="732.6" y2="838.1"/>
      <g>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="719" cy="419.4" rx="46" ry="46"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="718.8" cy="419.7" rx="37.1" ry="36.9"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="717.4" cy="419.4" rx="11.9" ry="11.8"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="717.8" cy="419.5" rx="3" ry="3"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="716.1" cy="426.8" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="724.6" cy="423.9" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="724.2" cy="414.9" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="716.3" cy="411.9" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="709.6" cy="419.5" rx="2.2" ry="2.2"/>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="706.6" y1="414.1" x2="682.3" y2="412.3"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="705.4" y1="417.1" x2="681.7" y2="417.1"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="705.7" y1="421.6" x2="681.5" y2="421.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="682.2" y1="425.8" x2="706.3" y2="424.4"/>
        </g>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="719" y1="407.5" x2="716" y2="383.1"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="716.2" y1="407.2" x2="710.5" y2="384.2"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="711.9" y1="408.5" x2="705.6" y2="385.1"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="700.7" y1="387.1" x2="710" y2="409.8"/>
        </g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="724.5" y1="409.5" x2="742.9" y2="391.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="726.4" y1="411" x2="747" y2="395.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="728.3" y1="414.5" x2="749.7" y2="399.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="729.2" y1="416.9" x2="752.3" y2="405"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="711" y1="429.3" x2="698.9" y2="450.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="713.1" y1="430.4" x2="704.4" y2="453.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="716.1" y1="431.3" x2="710.6" y2="455.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="718.6" y1="431.4" x2="716.8" y2="456.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="726.9" y1="427.3" x2="747.9" y2="442.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="724.7" y1="429" x2="743.1" y2="447.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="728.5" y1="424.5" x2="750.7" y2="438.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="729.3" y1="421.6" x2="753.1" y2="433.5"/>
      </g>
      <g>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="719.3" cy="794" rx="46" ry="46"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="719" cy="794.2" rx="37.1" ry="36.9"/>
        <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="717.7" cy="793.9" rx="11.9" ry="11.8"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="718.1" cy="794" rx="3" ry="3"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="716.4" cy="801.3" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="724.8" cy="798.4" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="724.4" cy="789.5" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="716.6" cy="786.4" rx="2.2" ry="2.2"/>
        <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="709.8" cy="794" rx="2.2" ry="2.2"/>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="706.9" y1="788.6" x2="682.5" y2="786.8"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="705.7" y1="791.6" x2="681.9" y2="791.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="705.9" y1="796.2" x2="681.7" y2="796.2"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="682.4" y1="800.4" x2="706.6" y2="798.9"/>
        </g>
        <g>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="719.2" y1="782" x2="716.2" y2="757.6"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="716.4" y1="781.7" x2="710.8" y2="758.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="712.1" y1="783.1" x2="705.9" y2="759.7"/>
          <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="701" y1="761.7" x2="710.3" y2="784.4"/>
        </g>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="724.7" y1="784" x2="743.2" y2="765.9"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="726.7" y1="785.6" x2="747.2" y2="770.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="728.5" y1="789.1" x2="749.9" y2="773.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="729.5" y1="791.5" x2="752.5" y2="779.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="711.3" y1="803.9" x2="699.1" y2="825.5"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="713.3" y1="805" x2="704.7" y2="828.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="716.4" y1="805.9" x2="710.9" y2="830.4"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="718.8" y1="805.9" x2="717" y2="831.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="727.1" y1="801.9" x2="748.2" y2="817.1"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="725" y1="803.5" x2="743.3" y2="821.8"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="728.7" y1="799" x2="751" y2="812.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="729.6" y1="796.2" x2="753.4" y2="808.1"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M677.6,494.1v-6.6c0-1.2-0.9-2.1-2.1-2.1l0,0
        c-1.2,0-2.1,0.9-2.1,2.1v6.6c0,1.2,0.9,2.1,2.1,2.1l0,0C676.7,496.2,677.6,495.2,677.6,494.1z"/>
      <ellipse fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" cx="651.5" cy="673.3" rx="6.1" ry="9.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M654.3,688.3v-25.3c0-1.5-1.2-2.7-2.7-2.7l0,0
        c-1.5,0-2.7,1.2-2.7,2.7v25.3c0,1.5,1.2,2.7,2.7,2.7l0,0C653.1,691,654.3,689.8,654.3,688.3z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M623.2,870.4c0,0-6.3,8.9-6.6,23.9h5.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M630.6,678.4h-7.7c-3.9,0-7.7,0.8-11.2,2.6
        c-2.3,1.2-4.4,2.9-5.1,5c0,0-2.4,4.3,7.1,5.6l16.6,2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M631.1,676.2H620c-3.9,0-7.7,1.1-10.9,3.2
        c-2.4,1.6-4.8,3.9-5.6,6.9c0,0-2.4,5.7,7.1,7.4l19,2.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M741.5,371.1l-4.5-1.5h-23.9c-28.1,6.4-44.7,24.3-44.8,50.4
        c-0.1,28.2,23.2,50.7,45.8,51.8h29.1l-5.9,271.7l-24,0.3c-29.4,5.1-48.5,25.8-48.6,52c-0.1,28.2,23.2,50.1,45.8,51.2h22.1l0,6.4
        c2.1,3.1,2,6.5,2,6.5l-0.3,28.2c-2,4.9-11.6,11-11.6,11l1.8,6h2.7l0.1,1.4l-1.8,3.9c-2.3,19-17.4,16.9-17.4,16.9h-9.9l-0.9,1.1
        c-2.7,3.7-6.5-2.6-6.5-2.6h-8.5c0,0-11.6,0.9-15.1,4.4s-6.6,0.1-6.6,0.1l-8.3-9.9h-25c-1.8-1.3,0.8-9.2,0.8-9.2
        c1.3-12.5,0.3-53.9,0.3-53.9V752.3c0.8-10.5,6.9-54.9,6.9-54.9c2-15.6,3.1-51.1,3.1-51.1l1.8-123.1l1.5-36l3.8-61.5
        c0-10,5.1-40.3,5.1-40.3c1-7.4,9.4-30.6,9.4-30.6c2.6-8.7,7.9-44.7,7.9-44.7s-0.6-3.3,2-5.5c0,0,5.1-3.1,8.5-3.7
        c0,0,3.6,0.5,4.6,2c0,0,3.1,1.4,6-0.5l4.3-2.2c0,0,1-5.8,4.3-5.6h14.3c0,0,2.2,0.2,2.4,4.3v6h4.6v1.7h4.9v1.2h5.6
        c0,0,1-7.5,3.6-8.7c0,0,3.6,0.3,3.9,3.4L741.5,371.1z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M668,310.1c0,0-19.1,34.1-26.6,93.4c0,0-8,64-8,79h5.8l-1.5,17.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M660.7,327.2c0,0-3.6,29.8-11.6,54.8c0,0-9,46.6-10,100.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M634.1,549.8c0,0-1,110.2-3.7,143.1l-7.1,52.9l-2.6,93.8l2.7,1.3
        l0.2,28.7c0,0,2,9.7-2.3,27.2c0,0-2.7,13.4-2.7,21.9c0,0,0.9,5.4,3.3,0.9l0.8-12.9h5.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M672,303.5l-6,44.8c0,0-2,8.9,1.2,8.7c0,0,10.4-5.1,19.9-26.2
        c0,0,8.7-19.2,11.1-36.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M670.5,314.8c0,0,1.8,4.8,4.4,8.1c2.2,2.8,6.1,3.1,8.7,0.7
        c3.1-2.9,6.6-9,9.5-23.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M711.3,294.2v19l13.1,13.1l-0.6,16.3h-23.2c0,0-4.7,3.8-6.8,5
        c0,0-3.6-0.3-1.9-4.5l13.1-21.6c0,0,3.8-5.2,10.5-4.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M696.8,345.4h26l4.1,4.3l0.1,4.9c0,0,1.8,3.3,2.7,1.8l3.1-57.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="714.2" y1="304.6" x2="714.2" y2="316.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="719.2" y1="306.6" x2="719.2" y2="317.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="723.8" y1="308" x2="723.8" y2="317.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="715.1" y1="317.1" x2="731.9" y2="317.1"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="729.3" y1="307.2" x2="729.3" y2="317.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M741,364.1h-27.2c-25.7,2.7-50.4,25.4-50.4,56.9
        s25.8,56.8,52.5,58.5h27.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M713.8,364.1c0,0,1.1,3.3-6,7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M737.9,737.1l-29.2,0.2c-25.7,2.7-50.4,25.6-50.4,57.6
        s25.8,57.5,52.5,59.2h22.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M631.5,919.9c0,0,2-26.4,7.5-37.1c0,0,2.9-2.6,5.3-1
        c0,0,7.8,4.6,15.7,23.5c0,0,1.9,8.9,1.9,26.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M683.6,925.9l0.3-22.9c0,0,4.9-26.7,4.9-33.9l0.1-8.6
        c0-1.4,0.3-2.7,1-3.9c0.7-1.3,2-2.5,3.9-2.1c0,0,2.5-0.1,3.8,2.6c0.5,1,0.6,2,0.6,3.1l-2.5,41.8l-1.4,27.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="660.3" y1="906.1" x2="683.7" y2="904.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M696.2,901.8l1.7-0.2l18.8-17.2c0,0,4.9-25.7,5.2-29.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M698.4,927.2v-13.5c0,0-0.1-6.7,5-10.1l14.4-13
        c1.4-1.3,2.6-2.9,3.3-4.7c2.4-5.8,7.7-19.8,10.3-31.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M730.7,857.8c0,0-2.5,19.5-4.8,27.7c-0.6,2-1.7,5.5-3.4,6.7
        c-5.6,4-20.2,15.1-20.8,23.6V927"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="705.7" y1="907.6" x2="705.7" y2="927.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="722.5" y1="899.3" x2="705.5" y2="914.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="705.7" y1="922.5" x2="721.2" y2="922.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M712.7,907.9v9.9c0,0-0.2,1.7,1.5,1.9h8.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="713.2" y1="916" x2="724.4" y2="916"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="713" y1="911.3" x2="725.4" y2="911.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="712.5" y1="916" x2="705.4" y2="916"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M689.3,379.1v-16.9c0,0-0.3-3.4-5.3-1.9l-33.2,23.3"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="739,336.7 744.7,336.7 744.7,363.9 739.2,363.9 		"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="722.5,471.9 721.7,529.2 712.3,679.4 710.4,736.9 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M722.2,481.2c0,0-15.5,25.7-18.6,41.4l-6.5,68.9l-10.4,113.7
        c0,0-2,35.1-2,49.9"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="728.8,743.2 734.1,610.4 739.7,471.2 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M734.4,471.5L731,587.3l-3.4,80.5c0,0-1.5,15-15,28.6
        c0,0-4.1,4.8-24.3,9c0,0-11.9,1.5-22,2.4c-7.9,0.7-15.8-0.8-22.8-4.5c-2.8-1.5-5.6-3.3-8-5.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M643.5,703.3c0,0-11.9,51.8-14.9,94.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M652.1,888.9l18.6-27.9c0,0,7-9,6.8-22.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M644.6,837.6c0,0-11.6,41.4-15.3,61.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M732.1,521.9c0,0-6.3-1.7-14.1-0.9c0,0-11.4,2.6-17.7,0.9
        c0,0-39-7-60.2,2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M659.9,355.1c0,0-2.4,31.1-5.4,38.3c0,0-2.1,5.4-6.4,7.4"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="672.6,319.5 672.6,325.8 679.7,334 679.8,343.6 		"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="668.8" y1="327.7" x2="679.7" y2="340.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M670.4,329.2l0,14.4c0,0-1.8,1.5-1,6.4h-3.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="670.3" y1="343.6" x2="676.8" y2="348.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M649.9,891.1c-4.4-2.8-10.3-0.8-12.2,4.1c-0.7,1.7-1.3,3.9-1.3,6.4
        c0,0-0.1,11,4.8,14.4h11.1l3.9,3.9v-15.7c0-1.1-0.2-2.3-0.5-3.4c-0.8-2.4-2.5-6.7-5.4-9.4C650.3,891.4,650.1,891.2,649.9,891.1z"
        />
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M643.5,915.4v-8.5c0,0,4-1.4,4-1.1s0,9.9,0,9.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="672.7" y1="318.6" x2="690.4" y2="311.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="674.5" y1="322.4" x2="688.1" y2="317.3"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="671.2" y1="309.1" x2="685.3" y2="303.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="671" y1="313.8" x2="691.9" y2="306"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="689" y1="302.7" x2="689" y2="314.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="684.4" y1="303.4" x2="684.4" y2="322.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="680.2" y1="301.1" x2="680.2" y2="325.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="676.7" y1="301.2" x2="676.7" y2="324.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="673.2" y1="302.8" x2="673.2" y2="319.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M636.7,499.9l-25.5,44.4l-17.7,35.6v14.5c0,0,1.4,3.2,3.1,4.6v8.2
        c0,0,1.2,4.9,9.4,4.4c0,0-5.4-7.1,31.7-67.6L636.7,499.9z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M596.5,575l-0.4,10.3c0,0,0,0,0,0c0.5,1.6,2.8,1.7,3.5,0.2
        c3.7-8.1,25.4-54.9,37.4-66.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M637.1,527.7c0,0-34.6,54-40.1,79.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M637.1,531.5c0,0-31,50.4-39.1,77.9"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M640.2,541.3c0,0,4.5,2.4,4.5,9
        c0,6.6-7.7,12.9-7.7,12.9l-0.2,6h-12c0,0-3.1,0.8-4.6-2.3v-6.5c0,0,1.4-8.1,8.3-8.8l5.8-10C634.4,541.6,637,539.7,640.2,541.3z"/>
    </g>
  </g>
  <g id="Layer_2">
    <g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M218.9,172v68.7c0,0,2.8,15.4,23,14.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M264.9,229.8v10.8c0,0-2.8,15.4-23,14.4"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M562.3,172v68.7c0,0-2.8,15.4-23,14.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M516.3,229.8v10.8c0,0,2.8,15.4,23,14.4"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M390.9,23.9l96.9,1c0,0,15.1-1.4,21.1,7.5
        l17.1,33.1l13.3,5.5l11.3,16.5l11.9,21.5v63l-3.8,3.8v38.8c0,1.9-0.4,3.7-1.2,5.4c-1.7,3.5-5.3,8.9-11.6,9.5l-75.2,0.9v-3h-82.1"
        />
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M391.5,23.9l-98.8,0.5c0,0-11.8-1.3-20.4,7.9
        l-17.1,33.1l-13.3,5.5l-11.3,16.5l-11.9,21.5v63l3.8,3.8v38.8c0,1.9,0.4,3.7,1.2,5.4c1.7,3.5,5.3,8.9,11.6,9.5l75.2,0.9v-3h82.1"
        />
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M386.9,32.7l105.9,1.8c0,0,6.2,0.5,9.7,7.1
          l12,24.6c0,0,3.9,10.6-3.5,12.7l-125.4-1.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M394.4,32.7l-105.9,1.8c0,0-6.2,0.5-9.7,7.1
          l-12,24.6c0,0-3.9,10.6,3.5,12.7l125.4-1.4"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M389,28.3l95.5,0.9c0,0,15.9-0.5,20.7,7.8
        l20.7,44.1c0.5,1.1-0.3,2.4-1.5,2.5l-50.7,1.1c0,0-11.7,0.5-24.9-6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M391.7,28.3l-95.5,0.9c0,0-15.9-0.5-20.7,7.8
        l-20.7,44.1c-0.5,1.1,0.3,2.4,1.5,2.5l50.7,1.1c0,0,11.7,0.5,24.9-6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M526.1,65.3l14.3,22.1l5.4,8.3
        c1,1.5,1.8,3.1,2.4,4.8l1.7,4.7c0.5,1.2,1.2,2.3,2.1,3.2l10.6,10"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M548.2,100.5c0,0-0.4,1.4-1.1,3.3
        c-2.6,6.2-8.1,10.7-14.7,12c-7.5,1.5-20.4,3.8-30.3,4.5l-55.2,4.7c-1.4,0.1-2.7,0.2-4.1,0.2l-53.6,0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M536.8,83.4c0,0-16.3,17.1-65.2,27.6
        c-1.4,0.3-2.8,0.5-4.2,0.7c-7.4,1-32.1,4.4-42.5,5.3c0,0-26.9,1.6-35.7,1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M539.9,86.6c0,0-19.8,24.4-85.7,27.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M542.4,90.4c0,0-1.6,15.5-12.9,26.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M526.5,102.3c0,0-16.2,11.4-51.8,20.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M255.4,65.3l-14.3,22.1l-5.4,8.3
        c-1,1.5-1.8,3.1-2.4,4.8l-1.7,4.7c-0.5,1.2-1.2,2.3-2.1,3.2l-10.6,10"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M233.3,100.5c0,0,0.4,1.4,1.1,3.3
        c2.6,6.2,8.1,10.7,14.7,12c7.5,1.5,20.4,3.8,30.3,4.5l55.2,4.7c1.4,0.1,2.7,0.2,4.1,0.2l53.6,0.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M244.7,83.4c0,0,16.3,17.1,65.2,27.6
        c1.4,0.3,2.8,0.5,4.2,0.7c7.4,1,32.1,4.4,42.5,5.3c0,0,26.9,1.6,35.7,1.5"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M241.6,86.6c0,0,19.8,24.4,85.7,27.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M239.1,90.4c0,0,1.6,15.5,12.9,26.1"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M255,102.3c0,0,16.2,11.4,51.8,20.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M256.8,97.6c0,0-18-0.6-24.2,23.2l-2.7,32.9
        c-0.1,1.6-1.5,2.8-3,2.8h-8.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M524.7,97.8c0,0,18-0.6,24.2,23.2l2.7,32.9
        c0.1,1.6,1.5,2.8,3,2.8h8.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M388.6,130.6c0,0,94.1-0.9,149.3-9.4
        c0,0,3.2-0.2,3.8,3.4v10.6c0,0,0.3,6.4-23.3,14.4c-0.7,0.2-1.3,0.4-2,0.6l-45.1,10.2c-2.4,0.6-4.9,0.9-7.4,1
        c-12.4,0.7-56.6,2.8-76.3,2.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M392.8,130.6c0,0-94.1-0.9-149.3-9.4
        c0,0-3.2-0.2-3.8,3.4v10.6c0,0-0.3,6.4,23.3,14.4c0.7,0.2,1.3,0.4,2,0.6l45.1,10.2c2.4,0.6,4.9,0.9,7.4,1
        c12.4,0.7,56.6,2.8,76.3,2.7"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M240.5,122.3c0,0,4.7,22.9,43.1,19.7
        c1.3-0.1,2.6-0.4,3.9-0.9c4-1.4,14.7-6.2,22.8-13.6"/>
      <circle fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" cx="270.9" cy="132.9" r="5.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M541.4,122.3c0,0-4.7,22.9-43.1,19.7
        c-1.3-0.1-2.6-0.4-3.9-0.9c-4-1.4-14.7-6.2-22.8-13.6"/>
      <circle fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" cx="511" cy="132.9" r="5.6"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M246,142"/>
      <g>
        <g>
          <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M341.3,132.7h-30.7c-6.7,0-12.3,5.5-12.3,12.3
            s5.5,12.3,12.3,12.3h30.7V132.7z"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="302" y1="136.1" x2="341.5" y2="136.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="299.6" y1="139.3" x2="341.6" y2="139.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="298.2" y1="143" x2="341" y2="143"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="298.8" y1="146.9" x2="341.2" y2="146.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="299.6" y1="150.8" x2="341" y2="150.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="302.9" y1="154.3" x2="341.2" y2="154.3"/>
        </g>
        <g>
          <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M441.7,132.7h30.7c6.7,0,12.3,5.5,12.3,12.3
            s-5.5,12.3-12.3,12.3h-30.7V132.7z"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="481" y1="136.1" x2="441.6" y2="136.1"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="483.4" y1="139.3" x2="441.5" y2="139.3"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="484.9" y1="143" x2="442" y2="143"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="484.3" y1="146.9" x2="441.8" y2="146.9"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="483.4" y1="150.8" x2="442.1" y2="150.8"/>
          <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="480.1" y1="154.3" x2="441.8" y2="154.3"/>
        </g>
        <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="341.3" y1="132.7" x2="443" y2="132.7"/>
        <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="340.6" y1="157.2" x2="442.3" y2="157.2"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M288,178.5l-51.7-21.2v40.5
          c0,0,13.2,13.5,37,10c2.1-0.3,4.1-1.3,5.5-2.9l19.5-20.2c0,0,84.5,0.1,96.9,0.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M492.6,178.5l51.7-21.2v40.5
          c0,0-13.2,13.5-37,10c-2.1-0.3-4.1-1.3-5.5-2.9l-19.5-20.2c0,0-84.5,0.1-96.9,0.1"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M277.1,206.4c0,0,93.3,9.7,225.2-0.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="294.3" y1="188.6" x2="486.3" y2="188.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="290.8" y1="192.3" x2="488.8" y2="192.3"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="286.8" y1="196.8" x2="494.3" y2="196.8"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="281.9" y1="201.7" x2="499" y2="201.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="277.7" y1="205.9" x2="503" y2="205.9"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="294.6" y1="188.6" x2="486.3" y2="188.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="313.8" y1="208.9" x2="307.1" y2="184.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="321.9" y1="209.2" x2="310.5" y2="184.2"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="467.5" y1="208.4" x2="474.4" y2="184.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="459.5" y1="208.8" x2="470.5" y2="184.4"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="332.1" y1="184.5" x2="337.6" y2="210.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="355.4" y1="184.5" x2="359.7" y2="210.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="378.2" y1="184.5" x2="380.4" y2="210.6"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="450.7" y1="184.9" x2="445.5" y2="209.7"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="428" y1="184.7" x2="423.8" y2="210.5"/>
      <line fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" x1="404.8" y1="184.5" x2="402.6" y2="210.5"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M222.2,208.3c0,0,41,7.8,173.7,7.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M558.1,208.3c0,0-41,7.8-173.7,7.4"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M222.2,215.9c0,0,41,7.8,173.7,7.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-miterlimit="10" d="M558.1,215.9c0,0-41,7.8-173.7,7.4"/>
      </g>
      <line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="310.3" y1="227.1" x2="310.3" y2="222.1"/>
      <line fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" x1="470.9" y1="227.3" x2="470.9" y2="222.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M247.1,199.1H245c-1.6,0-3-1.3-3-3v-26.5
        c0-1.6,1.3-3,3-3h2.1c1.6,0,3,1.3,3,3v26.5C250,197.8,248.7,199.1,247.1,199.1z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M537.5,198.4h-2.1c-1.6,0-3-1.3-3-3v-26.5
        c0-1.6,1.3-3,3-3h2.1c1.6,0,3,1.3,3,3v26.5C540.5,197,539.1,198.4,537.5,198.4z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M439.4,184.3v-15.4c0-1.4-1.4-2.5-3.1-2.5
        h-91.2c-1.7,0-3.1,1.1-3.1,2.5v15.4H439.4z"/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="342.9,78.3 342.9,92 364.4,125.1 		
        "/>
      <polyline fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" points="438.9,78.7 438.9,92.4 417.3,125.5 		
        "/>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M240.7,72.5V66c0-1.4-0.4-2.9-1.2-4.1
          c-1-1.6-2.7-3.4-5.3-3.4h-16.4c-1.5,0-3,0.4-4.2,1.3c-2,1.3-4.8,3.8-7.3,8.4c-1.2,2.2-1.3,4.9-0.1,7.1c0.4,0.9,1,1.8,1.9,2.6
          c1.2,1.3,2.9,2,4.6,2.2c3.2,0.4,9.4,1.1,13.5,1.7c2.4,0.3,4.4,1.8,5.6,3.8v0"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M205.7,70.9c0,0,15-1.3,32.1,5.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M205.6,74.3c0,0,14.7-0.4,30,5.2"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M540.6,72.5V66c0-1.4,0.4-2.9,1.2-4.1
          c1-1.6,2.7-3.4,5.3-3.4h16.4c1.5,0,3,0.4,4.2,1.3c2,1.3,4.8,3.8,7.3,8.4c1.2,2.2,1.3,4.9,0.1,7.1c-0.4,0.9-1,1.8-1.9,2.6
          c-1.2,1.3-2.9,2-4.6,2.2c-3.2,0.4-9.4,1.1-13.5,1.7c-2.4,0.3-4.4,1.8-5.6,3.8v0"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M575.6,70.9c0,0-15-1.3-32.1,5.4"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M575.8,74.3c0,0-14.7-0.4-30,5.2"/>
      </g>
    </g>
  </g>
  <g id="Layer_3">
    <g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M233.6,1066.3v134.1c0,0,2.9,15.9,23.8,14.9"
          />
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M281.3,1170v30.5c0,0-2.9,15.9-23.8,14.9"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M589.3,1066.3v134.1c0,0-2.9,15.9-23.8,14.9"
          />
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M541.6,1170v30.5c0,0,2.9,15.9,23.8,14.9"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M408.2,975.3l104.3,0.7c0,0,14,0.2,20.7,6.9
          l16.9,32.6c1,1.9,2.6,3.2,4.6,3.8c4.1,1.2,11,3.3,12.7,4.9l5.5,9.4c0,0,7.3,18.2,14.8,28.4c0.9,1.3,1.4,2.9,1.3,4.4l-5.1,76.2
          c0,0-3.4,23.9-13.6,28.5c0,0-5.3,0.2-6.7-1.4h-73.5c0,0,0,7.3-7.8,8h-72.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M414.5,975.3L310.2,976c0,0-14,0.2-20.7,6.9
          l-16.9,32.6c-1,1.9-2.6,3.2-4.6,3.8c-4.1,1.2-11,3.3-12.7,4.9l-5.5,9.4c0,0-7.3,18.2-14.8,28.4c-0.9,1.3-1.4,2.9-1.3,4.4
          l5.1,76.2c0,0,3.4,23.9,13.6,28.5c0,0,5.3,0.2,6.7-1.4h73.5c0,0,0,7.3,7.8,8h72.1"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M271,1017.5c0,0,23.8-5.1,47.2-5l94.2-1.1"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M551.9,1017.5c0,0-23.8-5.1-47.2-5l-94.2-1.1"
          />
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M278,1016c0,0,8.8-20.4,18.7-30.9
          c2.8-3,6.9-4.3,10.9-4.3l103.3-0.8"/>
        <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M545.8,1016c0,0-8.8-20.4-18.7-30.9
          c-2.8-3-6.9-4.3-10.9-4.3L411,979.9"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M289.6,1014.5c0,0,8.9-17.8,17.2-25.6
        c2.2-2.1,5.1-3.2,8.1-3.3l96.7-1.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M533.5,1014.5c0,0-8.9-17.8-17.2-25.6
        c-2.2-2.1-5.1-3.2-8.1-3.3l-96.7-1.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="320.9" y1="1017.3" x2="501.3" y2="1017.3"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M515.3,1017c0,0,17.9,8.9,28.4,11c0,0,9.5,0.8,23.3-4.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M516.1,1017c0,4-3.8,7.5-7.8,7.5s-7.8-3.9-7.8-7.9"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M306.8,1017c0,0-17.9,8.9-28.4,11c0,0-9.5,0.8-23.3-4.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M306,1017c0,4,3.8,7.5,7.8,7.5s7.8-3.9,7.8-7.9"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M285.1,1031.7c0,0,62.9-4.3,124.6-4.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M536.5,1031.6c0,0-65.1-4.2-126.8-4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M414.4,1073.7l-128.1-2.5c-6.3-0.1-10.9-0.4-13.8-0.7
        c-3.8-0.4-7.4-2-10-4.7c-3.3-3.5-3.4-7.1-3.4-7.1v-7.9c0-2,0.3-4,1-5.8c1.2-3,3.1-5.3,4.9-6.9c3.7-3.4,8.9-4.8,13.9-3.9
        c3.5,0.6,6.4,1.7,6.4,1.7v-4.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M414.4,1073.7l121.5-2.5c6.3-0.1,10.9-0.4,13.8-0.7
        c3.8-0.4,7.4-2,10-4.7c3.3-3.5,3.4-7.1,3.4-7.1v-7.9c0-2-0.3-4-1-5.8c-1.2-3-3.1-5.3-4.9-6.9c-3.7-3.4-8.9-4.8-13.9-3.9
        c-3.5,0.6-6.4,1.7-6.4,1.7v-4.8"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="281.6" cy="1051.8" rx="18" ry="13.7"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="323.1" cy="1051.8" rx="14.7" ry="11.2"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="537.6" cy="1052.3" rx="18" ry="13.7"/>
      <ellipse fill="none" stroke="#231F20" stroke-miterlimit="10" cx="496.1" cy="1052.3" rx="14.7" ry="11.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M473.1,1060.8H346.2c-1.6,0-3-1.3-3-3v-12.2c0-1.6,1.3-3,3-3h126.9
        c1.6,0,3,1.3,3,3v12.2C476.1,1059.4,474.8,1060.8,473.1,1060.8z"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M410.3,1033.7h-25.2c-1.6,0-3-0.8-3.9-2.1c0,0,0,0,0,0l-1.4-3.5"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M409.6,1033.7h25.2c1.6,0,3-0.8,3.9-2.1c0,0,0,0,0,0l1.4-3.5"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M235.9,1094.4c0,0,5.1-1.5,9.1-6.4c0.8-1,1.4-2,1.9-3.2l12.2-26.9"
          />
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M273.8,1027.7c0,0-12.4,7.5-17.1,12c-0.4,0.4-1.1,0-0.9-0.6
          c1-3,3.4-7.9,8.3-12.5"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M586.9,1094.8c0,0-5.9-1.7-9.9-6.6c-0.8-1-1.4-2-1.9-3.2
          l-12.2-26.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M548.3,1028c0,0,12.4,7.5,17.1,12c0.4,0.4,1.1,0,0.9-0.6
          c-1-3-3.4-7.9-8.3-12.5"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M237.5,1122.7c0,0,9-14.5,26.1-18.8c0.8-0.2,1.7-0.3,2.6-0.3h72.3
        l13.1-21.9c1.3-2.2,3.5-3.8,6-4.2c1.2-0.2,2.6-0.4,4.2-0.3h49.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M585.2,1123.3c0,0-3.4-16-28.9-19.6h-73.6l-13.1-21.9
        c-1.3-2.2-3.5-3.8-6-4.2c-1.2-0.2-2.6-0.4-4.2-0.3H410"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M238,1129.5c0,0,9.2-14.6,25.2-20.9c1.2-0.5,2.5-0.7,3.7-0.7h76
          l9.4,9.4c0.8,0.8,1.9,1.3,3.1,1.3h56.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M584.8,1129.5c0,0-9.2-14.6-25.2-20.9c-1.2-0.5-2.5-0.7-3.7-0.7
          h-76l-9.4,9.4c-0.8,0.8-1.9,1.3-3.1,1.3h-56.3"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M342.6,1107.1l13.3-21.5c1.6-2.6,4.5-4.3,7.6-4.3c0,0,0.1,0,0.1,0
        h47.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M479.2,1107.9l-13.9-22.3c-1.6-2.6-4.5-4.3-7.6-4.3c0,0-0.1,0-0.1,0
        h-47.1"/>
      <rect x="361.1" y="1086.7" fill="none" stroke="#231F20" stroke-miterlimit="10" width="100" height="25.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M241.2,1125.2c0,0,12.9-6.2,26.8-6.6h87.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M581.8,1125.2c0,0-12.9-6.2-26.8-6.6h-87.2"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M242.4,1158c0,0,13.1-1.7,18.8-13.8c0,0,4.4-11.4,7.5-16.5
          c0.8-1.3,2.1-2,3.6-2h143.7"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M579.9,1158c0,0-13.1-1.7-18.8-13.8c0,0-4.4-11.4-7.5-16.5
          c-0.8-1.3-2.1-2-3.6-2H406.2"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M257.6,1170.6l17.9-35.2c1.9-3.8,5.8-6.4,10.1-6.4
        c0.1,0,0.1,0,0.2,0h127.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M565.4,1170.6l-17.9-35.2c-1.9-3.8-5.8-6.4-10.1-6.4
        c-0.1,0-0.1,0-0.2,0H409.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M288.5,1169.5c0,0,0.9-23.5,8.5-29.9c1.1-0.9,2.5-1.4,4-1.4h35.2
        c1.1,0,2.1,0.6,2.6,1.6c0.5,1.1,1.2,2.8,1.5,5.3v32.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M532.7,1169.3c0,0-0.9-23.5-8.5-29.9c-1.1-0.9-2.5-1.4-4-1.4H485
        c-1.1,0-2.1,0.6-2.6,1.6c-0.5,1.1-1.2,2.8-1.5,5.3v32.9"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="341.1" y1="1164.2" x2="481" y2="1164.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="340.5" y1="1150.5" x2="480.5" y2="1150.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="341.2" y1="1155.8" x2="481.2" y2="1155.8"/>
      <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="303.9" cy="1153" r="6.3"/>
      <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="326.1" cy="1153" r="6.3"/>
      <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="495.5" cy="1152.6" r="6.3"/>
      <circle fill="none" stroke="#231F20" stroke-miterlimit="10" cx="517.7" cy="1152.6" r="6.3"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M343.4,1150.9v-10.5c0,0,0.2-3.6,4.1-4.1h67.5"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M477.4,1150.9v-10.5c0,0-0.2-3.6-4.1-4.1h-67.5"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M254.5,1025.4v-6.7c0-0.3,0-0.7-0.1-1
        c-0.3-1.4-1.5-5.2-5.6-5.9h-14.4c0,0-6.6-0.5-11.5,7.1c-0.1,0.1-0.2,0.3-0.3,0.5l-3,5.8c-1,2-0.6,4.5,1.1,5.8
        c0.1,0.1,0.1,0.1,0.2,0.2c0.5,0.4,1.2,0.7,1.8,0.8l25.4,5.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M567.5,1025.4v-6.7c0-0.3,0-0.7,0.1-1
        c0.3-1.4,1.5-5.2,5.6-5.9h14.4c0,0,6.6-0.5,11.5,7.1c0.1,0.1,0.2,0.3,0.3,0.5l3,5.8c1,2,0.6,4.5-1.1,5.8c-0.1,0.1-0.1,0.1-0.2,0.2
        c-0.5,0.4-1.2,0.7-1.8,0.8l-25.4,5.2"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M362.7,1011.8l0.2-17.1c0-2.4-2.3-4.3-5.2-4.3
        h-31.5c-2.9,0-5.2,1.9-5.2,4.3l-0.2,17.6L362.7,1011.8z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M458.9,1012l-0.2-17.1c0-2.4,2.3-4.3,5.2-4.3
        h31.5c2.9,0,5.2,1.9,5.2,4.3l0.2,17.6L458.9,1012z"/>
      <path fill="#F9F9F9" stroke="#231F20" stroke-width="0.75" stroke-miterlimit="10" d="M432.7,994.5h-45c-1.9,0-3.4-1.5-3.4-3.4
        v-2.7c0-1.9,1.5-3.4,3.4-3.4h45c1.9,0,3.4,1.5,3.4,3.4v2.7C436.1,993,434.5,994.5,432.7,994.5z"/>
    </g>
  </g>
  <g id="Layer_4">
    <g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M388.7,280.9c0,0,63.9,2.4,103.4,41.9c0,0,14.6,10.7,20.5,25.2
        l2,100.9l-3.1,55.9l-1.3,81.4l-2,106.7c0,0,4.6,28.6,6.9,35.7l0.5,74.3l-1.3,24l-14.6,44.2l-4.9,11.7c0,0-0.8,8.9-8.4,11.7
        c0,0-45.6,20.9-97.7,21.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M388.8,280.9c0,0-63.8,2.4-103.3,41.9c0,0-14.6,10.7-20.5,25.2
        l-2,100.9l3.1,55.9l1.3,81.4l2,106.7c0,0-4.6,28.6-6.9,35.7L262,803l1.3,24l14.6,44.2l4.9,11.7c0,0,0.8,8.9,8.4,11.7
        c0,0,45.8,20.9,97.8,21.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M388.6,291.2c-26.8-0.2-64.9,13.7-64.9,13.7
        c-24.8,10.7-44.7,35.5-44.7,35.5c-2.3,0,0.5-8.2,0.5-8.2s30.9-41.6,109.6-49.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M388.6,291.2c27-0.3,65.4,13.7,65.4,13.7
        c24.8,10.7,44.7,35.5,44.7,35.5c2.3,0-0.5-8.2-0.5-8.2s-30.6-41.6-109.2-49.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M389.3,303.6c0,0-24-0.5-59,18.6c0,0-29.9,12.5-40.1,47.7
        c0,0-6.1,24.8-8.7,50.6l-1.3,46.2l12.3,17.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M268.3,341.6c0,0,0.5,28.1,22,28.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M389.2,303.6c0,0,24-0.5,59,18.6c0,0,29.9,12.5,40.1,47.7
        c0,0,6.1,24.8,8.7,50.6l1.3,46.2L486,484.1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M509.5,340.6c0,0-0.5,28.1-22,28.3"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M388.1,466l-40.6,0.5c0,0-31.4,4.6-40.3,9.2l-14,7.9
        c0,0-5.6,2.3-5.1,18.9c0,0-7.1,3.6-4.9,12.3l17.4,78.4c0,0,1.5,3.8,6.1,2.3c0,0,37.2-16,81.4-16"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M388.1,466l40.6,0.5c0,0,20.6,3.7,33.8,7c4.5,1.1,8.9,2.8,12.9,5.1
        l10.3,5.7c0,0,5.5,6,4,19.3c0,0,7.4,2.4,5.1,11.1l-17.8,78.4c0,0-1.6,3.8-6.3,2.3c0,0-37.3-15.9-82.7-15.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M467,497.6c-29.6-10.5-78.8-10.8-78.8-10.8s-47.9,0.3-77.5,10.8
        c0,0-16.9,2-14.8,18.1l10.5,41.6c0,0-0.5,10,9.2,6.9c0,0,33.7-11.4,73.5-9.9v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
        c39.8-1.5,73.1,9.9,73.1,9.9c9.7,3.1,9.2-6.9,9.2-6.9l10.5-41.6C483.8,499.7,467,497.6,467,497.6z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M287.9,502.8l17.2,64.1c0,0,1.7,7,9.9,6c0,0,32.8-11.3,73.8-10.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M489.5,503.9l-16.7,63c0,0-1.7,7-9.9,6c0,0-33.2-11.3-74.2-10.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M334.9,320.2c0,0-32.7,22.5-44.4,71.7c0,0-7.1,35-8.7,78.9
        l4.6,193.8l4.1,66.9l4.9,48c0,0,1.5,28.3,31.9,39.6c0,0,15.3,4.3,64.6,5.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M442.9,320.2c0,0,32.7,22.5,44.4,71.7c0,0,7.1,35,8.7,78.9
        l-4.6,193.8l-4.1,66.9l-4.9,48c0,0-1.5,28.3-31.9,39.6c0,0-15.3,4.3-64.6,5.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M283,347.2c0,0-12.3,116.4-6.8,176.7l5.4,156.9l8.5,91.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M494.7,346.8c0,0,12.3,116.4,6.8,176.7l-5.4,156.9l-8.5,91.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M265.6,503.4c0,0,11.6,0.3,17,11.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M512.1,503.8c0,0-11.6,0.3-17,11.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M290.3,731.1l-9.2,10.2c0,0-3.3,3.1-3.6,14.8l5.4,58
        c0,0,0.3,20.4,15.6,24.3c0,0,31.9,5.1,40.9,5.6c8.9,0.5,51.1,0,51.1,0"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M488.1,731.1l9.2,10.2c0,0,3.3,3.1,3.6,14.8l-5.4,58
        c0,0-0.3,20.4-15.6,24.3c0,0-31.9,5.1-40.9,5.6c-8.9,0.5-51.1,0-51.1,0"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="286" y1="678.5" x2="268.8" y2="693.8"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="490.7" y1="678.2" x2="508.5" y2="693.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M276.5,687.4c0,0-2.6,65.7-0.3,100.4c0,0,10.2,73.3,15.8,88.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M501.4,687.4c0,0,2.6,65.7,0.3,100.4c0,0-10.2,73.3-15.8,88.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M291.3,876.3c0,0,20.7,18.4,56,25.3c0,0,15.6,2.6,45,2.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M287,855.6c0,0-0.5-5.9,3.6-4.9c0,0,12.8,16.6,35.5,24.8
        c0,0,28.9,10.7,64.7,10.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M486.3,876.3c0,0-20.7,18.4-56,25.3c0,0-15.6,2.6-45,2.8"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M490.6,855.6c0,0,0.5-5.9-3.6-4.9c0,0-12.8,16.6-35.5,24.8
        c0,0-28.9,10.7-64.7,10.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M297.9,859.1l30.9,29.5c0,0,10.3,11.4,59.9,11.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M479.3,859.1l-30.9,29.5c0,0-10.3,11.4-59.9,11.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M274.8,819.2c0,0,6.7,47,16.6,57.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M502,820c0,0-6.7,47-16.6,57.2"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M291.5,730.5h25.9c0,0-2.7,30.6,16.7,35.7h56.2"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M487.2,730.5h-25.9c0,0,2.7,30.6-16.7,35.7h-56.2"/>
      </g>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M315.8,731.1c0,0,4.7,54.7,9,69.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M461.6,731.1c0,0-4.7,54.7-9,69.6"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M296.2,730.7l7,54.9c0,0,4,22.5,33.9,29.9c0,0,19,2.5,53.2,2.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M481.2,730.7l-7,54.9c0,0-4,22.5-33.9,29.9c0,0-19,2.5-53.2,2.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M282.9,693.5c0,0-2.5,45,3.3,89.4l7.8,52.9l4.2,23.5"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M494.9,693.8c0,0,2.2,44.8-3.6,89.2l-7.8,52.9l-4.2,23.5"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M306.7,595.5c0,0,1.5,22.3,4.7,26.4c0,0-2.2,0.2-2.7,4.7v27.2
          c0,0-0.7,24.7,16.6,27.2H342"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M313.4,593.3c0,0-0.2,22.5,1.2,28.2l-2.4,0.7c0,0,4.5,1.3,7.3,2.8
          c1.4,0.7,2.8,1.2,4.3,1.4c3.8,0.6,9.6,0.7,15.8,0.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="319.9" y1="624.9" x2="319.9" y2="590.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M326.2,680.5c0,0-0.9-1.1-1.8-2.4c-0.9-1.4-1.4-3.1-1.4-4.8v-8
          c0-1.5,0.5-3,1.7-4c0.6-0.5,1.3-1,2.2-1.2c3-0.6,7.8-0.5,7.8-0.5c-7.9-1.5-9.8-2.7-9.8-2.7c-10.9-5.8-12.7-17-12.7-17v-12
          c-0.1-3.3,2.1-4.9,2.1-4.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M320.1,607.8c0,0,6.3-5.1,19.1-5.2"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M321.3,625.7v10.5c0,1-0.3,1.9-0.7,2.8c-0.4,0.7-0.9,1.8-1.3,2.9
          c-0.6,1.7-0.2,3.7,0.9,5.1c1.3,1.8,3.7,4.4,7.4,6.8c0.9,0.6,2,0.9,3.2,0.9h8.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M328.4,627.2V646c0,0-3.7-0.1-5,4.5"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M328.3,645.9c0,0,2.6,2,11.4,1.7"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M369.6,580.4c0,0,3.4,36.5-2.2,41.5c0,0,2.2,0.2,2.7,4.7v27.2
          c0,0,0.7,24.7-16.6,27.2h-16.6"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M365.3,580.8c0,0,0.4,35-1.1,40.7l2.4,0.7c0,0-4.5,1.3-7.3,2.8
          c-1.4,0.7-2.8,1.2-4.3,1.4c-3.8,0.6-9.1,0.7-15.3,0.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="358.8" y1="624.9" x2="358.8" y2="581.6"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M352.6,680.5c0,0,0.9-1.1,1.8-2.4c0.9-1.4,1.4-3.1,1.4-4.8v-8
          c0-1.5-0.5-3-1.7-4c-0.6-0.5-1.3-1-2.2-1.2c-3-0.6-7.8-0.5-7.8-0.5c7.9-1.5,9.8-2.7,9.8-2.7c10.9-5.8,12.7-17,12.7-17v-12
          c0.1-3.3-2.1-4.9-2.1-4.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M358.7,607.8c0,0-6.9-5.1-19.6-5.2"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M357.5,625.7v10.5c0,1,0.3,1.9,0.7,2.8c0.4,0.7,0.9,1.8,1.3,2.9
          c0.6,1.7,0.2,3.7-0.9,5.1c-1.3,1.8-3.7,4.4-7.4,6.8c-0.9,0.6-2,0.9-3.2,0.9h-9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M350.4,627.2V646c0,0,3.7-0.1,5,4.5"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M350.5,645.9c0,0-2.6,2-11.4,1.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="328.6" y1="639.2" x2="350.5" y2="639.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="328.6" y1="630.5" x2="350.5" y2="630.5"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M470.6,595.5c0,0-1.5,22.3-4.7,26.4c0,0,2.2,0.2,2.7,4.7v27.2
          c0,0,0.7,24.7-16.6,27.2h-16.6"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M464,593.3c0,0,0.2,22.5-1.2,28.2l2.4,0.7c0,0-4.5,1.3-7.3,2.8
          c-1.4,0.7-2.8,1.2-4.3,1.4c-3.8,0.6-9.6,0.7-15.8,0.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="457.4" y1="624.9" x2="457.4" y2="590.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M451.2,680.5c0,0,0.9-1.1,1.8-2.4c0.9-1.4,1.4-3.1,1.4-4.8v-8
          c0-1.5-0.5-3-1.7-4c-0.6-0.5-1.3-1-2.2-1.2c-3-0.6-7.8-0.5-7.8-0.5c7.9-1.5,9.8-2.7,9.8-2.7c10.9-5.8,12.7-17,12.7-17v-12
          c0.1-3.3-2.1-4.9-2.1-4.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M457.3,607.8c0,0-6.8-5.2-19.5-5.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M456.1,625.7v10.5c0,1,0.3,1.9,0.7,2.8c0.4,0.7,0.9,1.8,1.3,2.9
          c0.6,1.7,0.2,3.7-0.9,5.1c-1.3,1.8-3.7,4.4-7.4,6.8c-0.9,0.6-2,0.9-3.2,0.9h-8.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M449,627.2V646c0,0,3.7-0.1,5,4.5"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M449.1,645.9c0,0-2.6,2-11.4,1.7"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M407.8,580.4c0,0-3.4,36.5,2.2,41.5c0,0-2.2,0.2-2.7,4.7v27.2
          c0,0-0.7,24.7,16.6,27.2h16.6"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M412.1,580.8c0,0-0.4,35,1.1,40.7l-2.4,0.7c0,0,4.5,1.3,7.3,2.8
          c1.4,0.7,2.8,1.2,4.3,1.4c3.8,0.6,9.1,0.7,15.3,0.6"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="418.5" y1="624.9" x2="418.5" y2="581.6"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M424.7,680.5c0,0-0.9-1.1-1.8-2.4c-0.9-1.4-1.4-3.1-1.4-4.8v-8
          c0-1.5,0.5-3,1.7-4c0.6-0.5,1.3-1,2.2-1.2c3-0.6,7.8-0.5,7.8-0.5c-7.9-1.5-9.8-2.7-9.8-2.7c-10.9-5.8-12.7-17-12.7-17v-12
          c-0.1-3.3,2.1-4.9,2.1-4.9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M418.6,607.8c0,0,6.4-5.2,19.1-5.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M419.9,625.7v10.5c0,1-0.3,1.9-0.7,2.8c-0.4,0.7-0.9,1.8-1.3,2.9
          c-0.6,1.7-0.2,3.7,0.9,5.1c1.3,1.8,3.7,4.4,7.4,6.8c0.9,0.6,2,0.9,3.2,0.9h9"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M427,627.2V646c0,0-3.7-0.1-5,4.5"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M426.8,645.9c0,0,2.6,2,11.4,1.7"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="448.7" y1="639.2" x2="426.8" y2="639.2"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="448.7" y1="630.5" x2="426.8" y2="630.5"/>
      </g>
      <g>
        <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="317.8,678.6 317.8,729.9 325.5,729.9 325.5,680.9 			"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M322.7,730.2v9.2c0,0,1,16.1,23.7,18.7h6.4c0,0,6.6-0.9,9.5-1.7
          c0,0,10.2-4.7,10.9-17.5V729"/>
        <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="370.8,729.2 370.8,671 365.8,671 			"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M325.4,729.6c0,0,4.1,3.4,8.5,5.1c0.8,0.3,2.7,0.8,3.6,0.8
          l10.3,0.1"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M371,729c0,0-4.3,4.1-8.7,5.7c-0.8,0.3-2.7,0.8-3.6,0.8l-10.8,0.1"
          />
        <g>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M325.7,722.9c0,0,2.1-4.2,9.7-5.8h13.8"/>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M370.9,723.1c0,0-2.5-4.4-10.1-5.9h-13.8"/>
        </g>
        <g>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M325.6,702.1c0,0,2.1-4.2,9.7-5.8h13.8"/>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M370.7,702.3c0,0-2.5-4.4-10.1-5.9h-13.8"/>
        </g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M331.8,734.3v6.5c0,0-2.2,1.7-2.2,4.3c0,0,0.5,5.4,9,8.6
          c0,0,5.1,1.4,10.5,1.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M364.9,734.3v6.5c0,0,2.2,1.7,2.2,4.3c0,0-0.5,5.4-9,8.6
          c0,0-5.1,1.4-10.5,1.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="337.7" y1="735.3" x2="337.7" y2="753"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="359" y1="735.7" x2="359" y2="753.4"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M317.4,704.3c0,0-0.9-18.3-19.4-30.7c-0.9-0.6-2-0.8-3-0.7
          c-2.1,0.3-5.9,2.4-9,5.6"/>
      </g>
      <g>
        <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="459.4,678.6 459.4,729.9 451.8,729.9 451.8,680.9 			"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M454.5,730.2v9.2c0,0-1,16.1-23.7,18.7h-6.4c0,0-6.6-0.9-9.5-1.7
          c0,0-10.2-4.7-10.9-17.5V729"/>
        <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="406.4,729.2 406.4,671 411.4,671 			"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M451.8,729.6c0,0-4.1,3.4-8.5,5.1c-0.8,0.3-2.7,0.8-3.6,0.8
          l-10.3,0.1"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M406.2,729c0,0,4.3,4.1,8.7,5.7c0.8,0.3,2.7,0.8,3.6,0.8l10.8,0.1"
          />
        <g>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M451.5,722.9c0,0-2.1-4.2-9.7-5.8h-13.8"/>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M406.4,723.1c0,0,2.5-4.4,10.1-5.9h13.8"/>
        </g>
        <g>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M451.7,702.1c0,0-2.1-4.2-9.7-5.8h-13.8"/>
          <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M406.5,702.3c0,0,2.5-4.4,10.1-5.9h13.8"/>
        </g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M445.4,734.3v6.5c0,0,2.2,1.7,2.2,4.3c0,0-0.5,5.4-9,8.6
          c0,0-5.1,1.4-10.5,1.3"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M412.3,734.3v6.5c0,0-2.2,1.7-2.2,4.3c0,0,0.5,5.4,9,8.6
          c0,0,5.1,1.4,10.5,1.3"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="439.5" y1="735.3" x2="439.5" y2="753"/>
        <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="418.2" y1="735.7" x2="418.2" y2="753.4"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M459.8,704.3c0,0,0.9-18.3,19.4-30.7c0.9-0.6,2-0.8,3-0.7
          c2.1,0.3,5.9,2.4,9,5.6"/>
      </g>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="371" y1="729.2" x2="406.6" y2="729.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="373.9" y1="580.4" x2="373.9" y2="644.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="403.9" y1="580.6" x2="403.9" y2="644.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M404,644.5l-30,0c0,0-0.6,18.5,5.1,20.7h10.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M374,644.5l30,0c0,0,0.6,18.5-5.1,20.7h-10.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="373.4" y1="629" x2="403.8" y2="628.9"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M378.8,664.9c0,0,4.6,18.9,2.6,64.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M398.7,664.8c0,0-4.6,18.9-2.6,64.4"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="295.6,672.1 295.6,582.7 289.8,576.9 290.1,674.4 
        290.7,731.5 		"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="289.3,544.1 285.8,544.2 287.5,676.6 		"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="481.8,672.1 481.8,582.7 487.6,576.9 487.3,674.4 
        486.7,731.5 		"/>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M314.1,730.3v-15.8c0,0-0.2-3.2-3.8-2.8h-5.1"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M297.6,730.3v-15.8c0,0,0.2-3.2,3.8-2.8h5.1"/>
      </g>
      <g>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M479.5,731.3v-15.8c0,0-0.2-3.2-3.8-2.8h-5.1"/>
        <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M463.1,731.3v-15.8c0,0,0.2-3.2,3.8-2.8h5.1"/>
      </g>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="289.8" y1="576.6" x2="288.7" y2="544.2"/>
      <polyline fill="none" stroke="#231F20" stroke-miterlimit="10" points="487.6,576.5 488.1,544.2 491.6,544.2 489.8,676.6 		"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M388.3,751.2h9.8c0,0,6.3,8.9,7.3,36.8v29.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M389.4,751.2h-9.8c0,0-6.3,8.9-7.3,36.8v29.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M374.1,824v18.5c0,0,1.6,30.9,5.1,42.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M403.2,824v18.5c0,0-1.6,30.9-5.1,42.7"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M310.3,889.3c0,0-2.2,4.7-6.1,4.3l-15.9-8.5c0,0-4.3-4.3-5.3-10.2
        l-1.6-6.7c0,0-0.2-3.3,3.7-0.4l1.4,1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M317.8,893c0,0,1,7.7,7.3,9.2c0,0,8.5,2.8,23.4,0.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M466.3,889.3c0,0,2.2,4.7,6.1,4.3l15.9-8.5c0,0,4.3-4.3,5.3-10.2
        l1.6-6.7c0,0,0.2-3.3-3.7-0.4l-1.4,1"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M458.8,893c0,0-1,7.7-7.3,9.2c0,0-8.5,2.8-23.4,0.4"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M275.7,675.2v-32.6c0,0-4.3,1.8-4.7,3.6V673
        C271.1,673,272.6,674.8,275.7,675.2z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M502.1,675.2v-32.6c0,0,4.3,1.8,4.7,3.6V673
        C506.7,673,505.2,674.8,502.1,675.2z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M277.9,540.6l3.7-6.1c0.4-0.6,0.4-1.4-0.1-2l-3.6-5.2
        c-0.3-0.4-0.9-0.4-1.2-0.1l-8.6,10.1c0,0,0,0-0.1,0c-3.4,0.7-6.5,2.4-9.1,4.7l-4.4,4c-0.7,0.6-1,1.4-1,2.3v5.9
        c0,1.1,1.1,2,2.2,1.7l21.4-5.5c0.9-0.2,1.5-1,1.4-1.9c-0.1-1.9-0.3-5.3-0.8-8L277.9,540.6z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-miterlimit="10" d="M500.1,540.6l-3.7-6.1c-0.4-0.6-0.4-1.4,0.1-2l3.6-5.2
        c0.3-0.4,0.9-0.4,1.2-0.1l8.6,10.1c0,0,0,0,0.1,0c3.4,0.7,6.5,2.4,9.1,4.7l4.4,4c0.7,0.6,1,1.4,1,2.3v5.9c0,1.1-1.1,2-2.2,1.7
        l-21.4-5.5c-0.9-0.2-1.5-1-1.4-1.9c0.1-1.9,0.3-5.3,0.8-8L500.1,540.6z"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M346.5,466l-0.1-85.6c0,0,6.3-58.8,22.7-87.2"/>
      <path fill="none" stroke="#231F20" stroke-miterlimit="10" d="M431.1,466l0.1-85.6c0,0-6.3-58.8-22.7-87.2"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="324.6" y1="304.3" x2="327.2" y2="297.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="359.8" y1="294.6" x2="361.4" y2="286.8"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="378.9" y1="291.4" x2="380.3" y2="283.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="324.6" y1="304.3" x2="327.2" y2="297.6"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="359.8" y1="294.6" x2="361.4" y2="286.8"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="378.9" y1="291.4" x2="380.3" y2="283.4"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="452.6" y1="304.2" x2="450.1" y2="297.5"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="417.4" y1="294.4" x2="415.8" y2="286.7"/>
      <line fill="none" stroke="#231F20" stroke-miterlimit="10" x1="398.3" y1="291.3" x2="397" y2="283.3"/>
    </g>
  </g></>)
  },
};
