import React from "react"
export const Van7TonneSVG = {
  interior: {
    width: 393,
    height: 824,
    svg: (<>
      <rect x="45" y="265" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="305" height="541" />
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M195,25h56c0,0,45,1,72,12c0,0,18,6,16,32
        l-1,163c0,0,2,15-8,16c0,0-18,4-131,5"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M200.2,25h-56c0,0-45,1-72,12c0,0-18,6-16,32
        l1,163c0,0-2,15,8,16c0,0,20.8,4,133.8,5"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M198.6,33.8h53.8c0,0,37.2,0,63.4,10.1
        c0,0,17.1,6,15.1,24.1l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M198.6,33.8h-55.7c0,0-37.2,0-63.4,10.1
        c0,0-17.1,6-15.1,24.1l6,26.2c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"/>
      </g>
      <g>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M198.9,37.5h52.7c0,0,36.4,0,62,8.9
        c0,0,16.7,5.3,14.8,21.4L322.5,91c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"/>
        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M198.9,37.5h-54.5c0,0-36.4,0-62,8.9
        c0,0-16.7,5.3-14.8,21.4L73.6,91c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"/>
      </g>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M73,100v140c0,0-2,7-11,7" />
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M322,100v140c0,0,2,7,11,7" />
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M73.3,241c0,0,119.7,14,249.7,1" />
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M60,88c0,0,5,11,7,22l0,111.7c0,0.2,0,0.4,0,0.6
      c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C61,224,60.5,87.5,60,88z"/>
      <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M335,88c0,0-5,11-7,22v111.7c0,0.2,0,0.4,0,0.6
      c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C334,224,334.6,87.5,335,88z"/>
      <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="78" y1="250" x2="78" y2="265" />
      <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="318" y1="249" x2="318" y2="265" />
      <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="109" y1="28" x2="284" y2="28" />
      <rect x="46" y="70" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="10" height="44" />
      <rect x="339" y="71" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="10" height="44" />
      <g>
        <polyline fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="12,91 24,82 36,82 48,91 	" />
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="47,94 13,94 12,91 48,91 	" />
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="31,94 62,144 65,144 65,143 34,94 	
        "/>
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="37.1,94.1 56,111 56,107 41.2,94 	
        "/>
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="66,140 66,144 62,144 62,149 66,149 
        66,153 69,153 69,140 	"/>
      </g>
      <g>
        <polyline fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="383,91 371,82 359,82 347,91 	" />
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="348,94 382,94 383,91 347,91 	" />
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="364,94 333,144 330,144 330,143 
        361,94 	"/>
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="358,94.1 339,111 339,107 353.9,94 	
        "/>
        <polygon fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="329,140 329,144 333,144 333,149 
        329,149 329,153 326,153 326,140 	"/>
      </g>
      <g>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M138.4,117.4c0,0,27.3-1.9,39.3,18.9
          c0,0,5.7,13.8,0,33.7c0,0-3.7,14.1-2.1,20.9c0,0,5,14,3.4,21.5c0,0-2.9,8.5-12,9.9c0,0-9.5-0.5-15,4.8l-13.5-0.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M139.6,117.4c0,0-27.3-1.9-39.3,18.9
          c0,0-5.7,13.8,0,33.7c0,0,3.7,14.1,2.1,20.9c0,0-5,14-3.4,21.5c0,0,2.9,8.5,12,9.9c0,0,9.5-0.5,15,4.8l13.5-0.5"/>
        </g>
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M102.7,189c0,0,39.3,7.7,72.9-0.1" />
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M98.7,205.3c0,0,41.5,12.6,80.4,0" />
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M120.1,223.5c0,0-0.2-6.6,5.1-8.6h15.3" />
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M158.1,223.5c0,0,0.2-6.6-5.1-8.6h-15.3" />
      </g>
      <g>
        <g>
          <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M263.4,114.5c0,0,27.3-1.9,39.3,18.9
          c0,0,5.7,13.8,0,33.7c0,0-3.7,14.1-2.1,20.9c0,0,5,14,3.4,21.5c0,0-2.9,8.5-12,9.9c0,0-9.5-0.5-15,4.8l-13.5-0.5"/>
          <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M264.6,114.5c0,0-27.3-1.9-39.3,18.9
          c0,0-5.7,13.8,0,33.7c0,0,3.7,14.1,2.1,20.9c0,0-5,14-3.4,21.5c0,0,2.9,8.5,12,9.9c0,0,9.5-0.5,15,4.8l13.5-0.5"/>
        </g>
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M227.7,186.1c0,0,39.3,7.7,72.9-0.1" />
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M223.7,202.4c0,0,41.5,12.6,80.4,0" />
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M245.1,220.7c0,0-0.2-6.6,5.1-8.6h15.3" />
        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M283.1,220.7c0,0,0.2-6.6-5.1-8.6h-15.3" />
      </g>
    </>),
  },
  exterior: {
    width: 1240,
    height: 1706,
    svg: (<>
      <g id="Layer_1_1_">
        <g>
          <rect x="52.6" y="670" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M147.4,605.7c-2-24-18-43-38-51.7v-55.3h-15h-4l-5-32c0-2,3-2,3-2
          h29v6h3l3-3h72l30,9c12,5,52,24,52,24l25,14l19,12c13,9,13,27,13,27v116c0,11-12,12.6-12,12.6c-1.4,0.3-13.7,0.4-15.2,0.4H199.4
          c-2-1-3-4-3-4h-20c-2,0-29-4-29-4V605.7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.4,674.7c17,0,51-8,61-46" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M86.4,669.7c12.2-0.8,23.6-5.5,32.5-13.9
          c16.2-15.3,23.4-38.3,17.8-60c-5.4-20.7-21.3-38.2-42.3-43.1"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.4" y1="705.7" x2="86.4" y2="669.2" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="94.4" y1="498.7" x2="94.4" y2="553.2" />
          <rect x="73.4" y="506.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="80.4" y1="550.7" x2="80.4" y2="506.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M94.3,498.8c0,0-5.9,0-6.9,0c-0.9,0-6-9.2-6.9-10.8
          c-0.1-0.2-0.1-0.3-0.1-0.5v-19.7c0-0.6,0.5-1.1,1.1-1.1h3.9"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M94.4,549.7c8,1,15,4,15,4" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M265.9,518.7l-10-3l-51-16.7c-0.6-0.2-1.3-0.3-2-0.3h-94" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M307.5,555.8L307.5,555.8c0-4.3-1.8-8.5-5-11.3
          c-4.8-4.1-11-7.7-11-7.7l-40-16l-29.7-8.7c-3.5-1.1-7.2-1.9-10.9-2.4c-4.9-0.7-5.8-0.3-8,0.1c-2.3,0.4-4,2.4-4.1,4.7
          c-0.1,3.9,1.1,7.6,1.5,8.8c0.1,0.3,0.3,0.7,0.4,1.1l2.4,7.2c0.3,0.7,0.5,1.4,0.7,2.2l13.7,49.9l16.5,46.7c0.3,0.8,0.7,1.7,1.1,2.5
          l6.6,13.3c0.7,1.3,1.5,2.5,2.5,3.6c1.4,1.5,2.9,2.8,4.4,3.8c3.6,2.6,7.9,4.1,12.3,4.7l10.5,1.4h25c10,0,12-10,12-10v-54
          L307.5,555.8z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M304.2,557.6c-0.3-6.3-4.1-12-9.7-14.9l-15.3-8.1l-39-14l-29-8
          c-3.1-0.6-5.2-0.6-6.7-0.3c-2.1,0.5-3.3,2.6-2.8,4.7c1.1,4.1,3.6,11.7,3.6,11.7l12,43l15,46l10.5,23.3c2.7,5.9,7.5,10.4,13.6,12.5
          c8.1,2.8,17,3.2,17,3.2h22.4c2.1,0,4.1-0.7,5.7-2.1c0.7-0.7,1.3-1.3,1.7-1.9c0.8-1,1.2-2.4,1.2-3.7v-90.3
          C304.4,558.3,304.4,558,304.2,557.6z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M221.4,475.7l2,11c2,9,14,14,14,14l21,9l54,26c4,2,6-1,6-1l5-7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M224.5,476.7l0.4,4c0.5,4,2.1,7.8,4.8,10.8c4.7,5.2,8.2,6.3,8.2,6.3
          l20.3,8.7l52.1,25.1c3.9,1.9,5.8-1,5.8-1l3.3-4.9"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M147.4,646.8l27.2,18.5c0.5,0.3,1.1,0.5,1.7,0.5h124.1
          c15,0,13.7-12.9,14-14.8c0-0.2,0-0.4,0-0.6v-91.6c0-1.5-0.1-2.8-0.2-4.1c-0.8-6.7-4.5-12.8-10-16.7l-8.8-6.3"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M203.4,498.7c2-12-9-31-9-31" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M191.4,498.7c0-13-10-31-10-31" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M155.4,498.7c1-18-9-31-9-31" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M113.4,498.7c-2-4-2.6-10.2-2.9-12.4c-0.1-0.4-0.1-0.8-0.1-1.2
          v-20.4"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M125.4,498.6c-4-6-5.3-13.4-5.8-16.5c-0.1-0.8-0.2-1.6-0.2-2.5v-8.9
          "/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M310.4,534.7c0,0,16,9,17,34l1,95c0,0,0,12-11,12c-10,0-140,0-140,0
          v-10c0-39-5-62-5-62c-6-40-45-105-45-105"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="177.4" y1="675.7" x2="177.4" y2="678.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M124.4,498.7c31,52,41,85,41,85c11,41,9,81.5,9,81.5v13" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M121.4,498.7c20,35,29,57,29,57c19,42,19,76,19,76v30v14l-22-3" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M182,629.7c2.2-3.8,8.2,1.4,11.4,4.7c1.3,1.4,2.1,3.2,2.1,5.2v16.8
          c0,1.8-1.5,3.3-3.3,3.3h-7.5c-1.8,0-3.3-1.5-3.3-3.3v-24.7C181.4,631,181.5,630.3,182,629.7z"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="188.4" y1="659.7" x2="188.4" y2="630.2" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M92.4,469.7h12c0.5,0,1-0.5,1-1v-1c0-0.6-0.5-1-1-1h-12
          c-0.6,0-1,0.4-1,1v1C91.4,469.2,91.8,469.7,92.4,469.7z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M256.4,519.7l-10.9,8.1c-0.4,0.3-0.2,0.9,0.3,0.9h53.3
          c0.7,0,1-0.9,0.4-1.3l-11.1-7.7H256.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M245.5,528.7l1.7,2.6c0.1,0.2,0.5,0.4,0.8,0.4h49
          c0.3,0,0.5-0.2,0.7-0.4l1.7-2.6H245.5z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M249.4,524.7h-9.3c-0.5,0-1,0.2-1.3,0.5l-20,18.1
          c-0.4,0.2-0.8,0.4-1.2,0.4h-27.3v-3h26.2c0.6,0,1.1-0.2,1.5-0.6l19.4-18c0.3-0.2,0.7-0.4,1.1-0.4H253h0.4L249.4,524.7z"/>
          <rect x="185.4" y="537.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9" />
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M294.4,523.7h16l5.3,3.6c0.5,0.3,0.7,0.7,0.7,1.2v48.2h2v-47.9
          c0-0.8-0.3-1.7-1-2.3c-2.3-2.1-7-4.8-7-4.8h-19L294.4,523.7z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M316.4,528.7" />
          <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="123.4,1083.2 123.4,1187.7 142.4,1187.7 142.4,663.7 
          123.4,663.7 123.4,985.5 		"/>
          <rect x="116.4" y="1187.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="146.9" y1="663.7" x2="141.9" y2="663.7" />
          <rect x="77.4" y="705.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="256" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.4" y1="961.7" x2="93.4" y2="705.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="82.4" y1="961.2" x2="82.4" y2="705.2" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="123.4" y1="741.7" x2="99.4" y2="741.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="123.4" y1="747.7" x2="99.4" y2="747.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="123.9" y1="910.7" x2="99.4" y2="910.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="123.4" y1="916.7" x2="99.4" y2="916.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.4" y1="1187.7" x2="86.4" y2="1104.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.4" y1="965.7" x2="86.4" y2="961.7" />
          <rect x="82.4" y="1187.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.4" y1="1177.7" x2="123.4" y2="1177.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="93.4" y1="1187.7" x2="93.4" y2="1177.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M75.9,1103.7c15.7,0,30.9-5.6,42.9-15.7
          c21.9-18.4,30.1-49.5,20.7-76.5c-9.3-26.7-34.7-45.6-63.1-45.8"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M76.4,1095.7c14-0.8,27-5.3,38-14.2c19.2-15.6,26.1-42.7,18.5-66
          c-7.9-24.5-31.1-40.7-56.5-41.8"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.4" y1="974.2" x2="76.4" y2="965.2" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.4" y1="1095.2" x2="76.4" y2="1103.7" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="75.4" cy="1033.7" rx="54" ry="55" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="75.4" cy="1034.5" rx="31" ry="30.8" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="77.4" cy="609.7" rx="54" ry="55" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="77.4" cy="610.5" rx="31" ry="30.8" />
          <rect x="53.4" y="1097.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3" />
          <rect x="142.4" y="689.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="304" height="543" />
        </g>
        <g>
          <rect x="1152.2" y="670.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1091.4,605.7c2-24,18-43,38-51.7v-55.3h15h4l5-32c0-2-3-2-3-2h-29
          v6h-3l-3-3h-72l-30,9c-12,5-52,24-52,24l-25,14l-19,12c-13,9-13,27-13,27v116c0,11,12,12.6,12,12.6c1.4,0.3,13.7,0.4,15.2,0.4
          h107.8c2-1,3-4,3-4h20c2,0,29-4,29-4V605.7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1152.4,674.7c-17,0-51-8-61-46" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1152.4,669.7c-12.2-0.8-23.6-5.5-32.5-13.9
          c-16.2-15.3-23.4-38.3-17.8-60c5.4-20.7,21.3-38.2,42.3-43.1"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1152.4" y1="705.7" x2="1152.4" y2="669.2" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1144.4" y1="498.7" x2="1144.4" y2="553.2" />
          <rect x="1144.4" y="506.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1158.4" y1="550.7" x2="1158.4" y2="506.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1144.5,498.8c0,0,5.9,0,6.9,0c0.9,0,6-9.2,6.9-10.8
          c0.1-0.2,0.1-0.3,0.1-0.5v-19.7c0-0.6-0.5-1.1-1.1-1.1h-3.9"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1144.4,549.7c-8,1-15,4-15,4" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M972.9,518.7l10-3l51-16.7c0.6-0.2,1.3-0.3,2-0.3h94" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M931.3,555.8L931.3,555.8c0-4.3,1.8-8.5,5-11.3
          c4.8-4.1,11-7.7,11-7.7l40-16l29.7-8.7c3.5-1.1,7.2-1.9,10.9-2.4c4.9-0.7,5.8-0.3,8,0.1c2.3,0.4,4,2.4,4.1,4.7
          c0.1,3.9-1.1,7.6-1.5,8.8c-0.1,0.3-0.3,0.7-0.4,1.1l-2.4,7.2c-0.3,0.7-0.5,1.4-0.7,2.2l-13.7,49.9l-16.5,46.7
          c-0.3,0.8-0.7,1.7-1.1,2.5l-6.6,13.3c-0.7,1.3-1.5,2.5-2.5,3.6c-1.4,1.5-2.9,2.8-4.4,3.8c-3.6,2.6-7.9,4.1-12.3,4.7l-10.5,1.4h-25
          c-10,0-12-10-12-10v-54L931.3,555.8z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M934.6,557.6c0.3-6.3,4.1-12,9.7-14.9l15.3-8.1l39-14l29-8
          c3.1-0.6,5.2-0.6,6.7-0.3c2.1,0.5,3.3,2.6,2.8,4.7c-1.1,4.1-3.6,11.7-3.6,11.7l-12,43l-15,46L996,641c-2.7,5.9-7.5,10.4-13.6,12.5
          c-8.1,2.8-17,3.2-17,3.2H943c-2.1,0-4.1-0.7-5.7-2.1c-0.7-0.7-1.3-1.3-1.7-1.9c-0.8-1-1.2-2.4-1.2-3.7v-90.3
          C934.4,558.3,934.4,558,934.6,557.6z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1017.4,475.7l-2,11c-2,9-14,14-14,14l-21,9l-54,26c-4,2-6-1-6-1
          l-5-7"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1014.3,476.7l-0.4,4c-0.5,4-2.1,7.8-4.8,10.8
          c-4.7,5.2-8.2,6.3-8.2,6.3l-20.3,8.7l-52.1,25.1c-3.9,1.9-5.8-1-5.8-1l-3.3-4.9"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1091.4,646.8l-27.2,18.5c-0.5,0.3-1.1,0.5-1.7,0.5H938.4
          c-15,0-13.7-12.9-14-14.8c0-0.2,0-0.4,0-0.6v-91.6c0-1.5,0.1-2.8,0.2-4.1c0.8-6.7,4.5-12.8,10-16.7l8.8-6.3"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1035.4,498.7c-2-12,9-31,9-31" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1047.4,498.7c0-13,10-31,10-31" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1083.4,498.7c-1-18,9-31,9-31" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1125.4,498.7c2-4,2.6-10.2,2.9-12.4c0.1-0.4,0.1-0.8,0.1-1.2v-20.4
          "/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1113.4,498.6c4-6,5.3-13.4,5.8-16.5c0.1-0.8,0.2-1.6,0.2-2.5v-8.9"
          />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M928.4,534.7c0,0-16,9-17,34l-1,95c0,0,0,12,11,12c10,0,140,0,140,0
          v-10c0-39,5-62,5-62c6-40,45-105,45-105"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1061.4" y1="675.7" x2="1061.4" y2="678.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1114.4,498.7c-31,52-41,85-41,85c-11,41-9,81.5-9,81.5v13" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1117.4,498.7c-20,35-29,57-29,57c-19,42-19,76-19,76v30v14l22-3" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1056.8,629.7c-2.2-3.8-8.2,1.4-11.4,4.7c-1.3,1.4-2.1,3.2-2.1,5.2
          v16.8c0,1.8,1.5,3.3,3.3,3.3h7.5c1.8,0,3.3-1.5,3.3-3.3v-24.7C1057.4,631,1057.3,630.3,1056.8,629.7z"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1050.4" y1="659.7" x2="1050.4" y2="630.2" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1146.4,469.7h-12c-0.5,0-1-0.5-1-1v-1c0-0.6,0.5-1,1-1h12
          c0.6,0,1,0.4,1,1v1C1147.4,469.2,1146.9,469.7,1146.4,469.7z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M982.4,519.7l10.9,8.1c0.4,0.3,0.2,0.9-0.3,0.9h-53.3
          c-0.7,0-1-0.9-0.4-1.3l11.1-7.7H982.4z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M993.3,528.7l-1.7,2.6c-0.1,0.2-0.5,0.4-0.8,0.4h-49
          c-0.3,0-0.5-0.2-0.7-0.4l-1.7-2.6H993.3z"/>
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M989.4,524.7h9.3c0.5,0,1,0.2,1.3,0.5l20,18.1
          c0.4,0.2,0.8,0.4,1.2,0.4h27.3v-3h-26.2c-0.6,0-1.1-0.2-1.5-0.6l-19.4-18c-0.3-0.2-0.7-0.4-1.1-0.4h-14.5h-0.4L989.4,524.7z"/>
          <rect x="1038.4" y="537.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9" />
          <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M944.4,523.7h-16l-5.3,3.6c-0.5,0.3-0.7,0.7-0.7,1.2v48.2h-2
          v-47.9c0-0.8,0.3-1.7,1-2.3c2.3-2.1,7-4.8,7-4.8h19L944.4,523.7z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M922.4,528.7" />
          <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="1115.4,1083.2 1115.4,1187.7 1096.4,1187.7 1096.4,663.7 
          1115.4,663.7 1115.4,985.5 		"/>
          <rect x="1096.4" y="1187.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1091.9" y1="663.7" x2="1096.9" y2="663.7" />
          <rect x="1139.4" y="705.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="256" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1145.4" y1="961.7" x2="1145.4" y2="705.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1156.4" y1="961.2" x2="1156.4" y2="705.2" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1115.4" y1="741.7" x2="1139.4" y2="741.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1115.4" y1="747.7" x2="1139.4" y2="747.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1114.9" y1="910.7" x2="1139.4" y2="910.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1115.4" y1="916.7" x2="1139.4" y2="916.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1152.4" y1="1187.7" x2="1152.4" y2="1104.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1152.4" y1="965.7" x2="1152.4" y2="961.7" />
          <rect x="1140.4" y="1187.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1152.4" y1="1177.7" x2="1115.4" y2="1177.7" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1145.4" y1="1187.7" x2="1145.4" y2="1177.7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1162.9,1103.7c-15.7,0-30.9-5.6-42.9-15.7
          c-21.9-18.4-30.1-49.5-20.7-76.5c9.3-26.7,34.7-45.6,63.1-45.8"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1162.4,1095.7c-14-0.8-27-5.3-38-14.2
          c-19.2-15.6-26.1-42.7-18.5-66c7.9-24.5,31.1-40.7,56.5-41.8"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1162.4" y1="974.2" x2="1162.4" y2="965.2" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1162.4" y1="1095.2" x2="1162.4" y2="1103.7" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1163.4" cy="1033.7" rx="54" ry="55" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1163.4" cy="1034.5" rx="31" ry="30.8" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1161.4" cy="609.7" rx="54" ry="55" />
          <ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1161.4" cy="610.5" rx="31" ry="30.8" />
          <rect x="1162.4" y="1097.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3" />
          <rect x="792.4" y="689.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="304" height="543" />
        </g>
      </g>
      <g id="Layer_2_1_">
        <g>
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M474,1651.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
            c1.5,0,2.7-1.4,2.7-3.1v-34.4H474z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M511,1651.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
            c1.5,0,2.7-1.4,2.7-3.1v-34.4H511z"/>
          </g>
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M691,1651.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
            c1.5,0,2.7-1.4,2.7-3.1v-34.4H691z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M728,1651.8v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
            c1.5,0,2.7-1.4,2.7-3.1v-34.4H728z"/>
          </g>
          <rect x="464" y="1259.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="308" height="331" />
          <rect x="469" y="1270.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="298" height="286" />
          <rect x="473" y="1273.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279" />
          <rect x="620" y="1273.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="464" y1="1563.8" x2="771" y2="1563.8" />
          <rect x="475" y="1566.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20" />
          <rect x="709" y="1566.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20" />
          <rect x="577" y="1567.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="73" height="17" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="464" y1="1262.8" x2="772" y2="1262.8" />
          <rect x="473" y="1262.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4" />
          <rect x="748" y="1262.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4" />
          <g>
            <rect x="464" y="1299.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23" />
            <rect x="465.1" y="1309.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3" />
          </g>
          <g>
            <rect x="464" y="1405.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23" />
            <rect x="465.1" y="1415.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3" />
          </g>
          <g>
            <rect x="464" y="1512.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23" />
            <rect x="465.1" y="1522.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3" />
          </g>
          <g>
            <rect x="768.1" y="1299.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23" />
            <rect x="749" y="1309.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3" />
          </g>
          <g>
            <rect x="768.1" y="1405.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23" />
            <rect x="749" y="1415.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3" />
          </g>
          <g>
            <rect x="768.1" y="1512.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23" />
            <rect x="749" y="1522.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3" />
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M605.4,1487.8h-21.9c-0.9,0-1.6-0.7-1.6-1.6v-48.9
            c0-0.9,0.7-1.6,1.6-1.6h21.9c0.9,0,1.6,0.7,1.6,1.6v48.9C607,1487,606.3,1487.8,605.4,1487.8z"/>
            <g>
              <rect x="595.5" y="1441.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="601.2" y1="1442.4" x2="601.2" y2="1481.6" />
            </g>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M629.6,1487.8h21.9c0.9,0,1.6-0.7,1.6-1.6v-48.9
            c0-0.9-0.7-1.6-1.6-1.6h-21.9c-0.9,0-1.6,0.7-1.6,1.6v48.9C628,1487,628.7,1487.8,629.6,1487.8z"/>
            <g>
              <rect x="633" y="1441.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5" />
              <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="633.8" y1="1442.4" x2="633.8" y2="1481.6" />
            </g>
          </g>
          <rect x="471" y="1590.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61" />
          <rect x="688" y="1590.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="549" y1="1646.8" x2="688" y2="1646.8" />
          <rect x="474" y="1612.8" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="290" height="15" />
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M464,1461.8v-56h-11c0,0-4,0-7.4,1c-2.7,0.8-4.6,3.3-4.6,6.1v24.9
              "/>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M464,1405.8v56h-11c0,0-4,0-7.4-1c-2.7-0.8-4.6-3.3-4.6-6.1v-24.9
              "/>
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M453,1461.8v6.7c0,0.9,0.3,1.7,0.9,2.3l10.1,10.1v-5l-6.3-6.3
            c-0.4-0.4-0.7-1-0.7-1.6v-6L453,1461.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M457,1405.8v-11.2c0-0.5,0.3-0.9,0.7-1.1l6.3-2.7v-3l-8.6,3.4
            c-0.9,0.3-1.4,1.2-1.4,2.1v12.5L457,1405.8L457,1405.8z"/>
          </g>
          <g>
            <g>
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M772,1461.8v-56h11c0,0,4,0,7.4,1c2.7,0.8,4.6,3.3,4.6,6.1v24.9"
              />
              <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M772,1405.8v56h11c0,0,4,0,7.4-1c2.7-0.8,4.6-3.3,4.6-6.1v-24.9"
              />
            </g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M783,1461.8v6.7c0,0.9-0.3,1.7-0.9,2.3l-10.1,10.1v-5l6.3-6.3
            c0.4-0.4,0.7-1,0.7-1.6v-6L783,1461.8z"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M779,1405.8v-11.2c0-0.5-0.3-0.9-0.7-1.1l-6.3-2.7v-3l8.6,3.4
            c0.9,0.3,1.4,1.2,1.4,2.1v12.5L779,1405.8L779,1405.8z"/>
          </g>
        </g>
      </g>
      <g id="Layer_3">
        <g>
          <polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="483.2,317.8 465.3,317.8 465.3,13.8 772.3,13.8 
          772.3,317.8 754.3,317.8 		"/>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,123.8l99.5,1c0,0,22-1,25,17l4,24l4,35l2,36l1,73v8
            c0,0-1,16-2,20l1,9c0,0,1,13-2,22l-1,8c0,0-3,12-11,12h-32c0,0-8,1-12-7h-76.5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,123.8l-99.5,1c0,0-22-1-25,17l-4,24l-4,35l-2,36l-1,73v8
            c0,0,1,16,2,20l-1,9c0,0-1,13,2,22l1,8c0,0,3,12,11,12h32c0,0,8,1,12-7h76.5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.3,138.8l104.3,2c10.3,0,17.7,8,18.7,18.7l6,62.3
            c0,10.3,1,19-21,19l-108,2"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.3,138.8l-104.3,2c-10.3,0-17.7,8-18.7,18.7l-6,62.3
            c0,10.3-1,19,21,19l108,2"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.6,141.8l102.9,1.9c10.1,0,17.4,7.6,18.4,17.7l5.9,59
            c0,9.7,1,18-20.7,18l-106.5,1.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.6,141.8l-102.9,1.9c-10.1,0-17.4,7.6-18.4,17.7l-5.9,59
            c0,9.7-1,18,20.7,18l106.5,1.9"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,313.8h59.5c6,0,8,3,8,3c-18,10-18,27-18,27h-49.5" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M618.8,313.8h-59.5c-6,0-8,3-8,3c18,10,18,27,18,27h50.5" />
          </g>
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="552.3" y1="315.8" x2="685.3" y2="315.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="552.8" y1="317.8" x2="684.3" y2="317.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="555.8" y1="319.8" x2="682.3" y2="319.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="558.8" y1="321.8" x2="679.3" y2="321.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="560.8" y1="323.8" x2="677.3" y2="323.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="561.8" y1="325.8" x2="675.3" y2="325.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="563.8" y1="327.8" x2="674.3" y2="327.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="564.8" y1="329.8" x2="672.3" y2="329.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="565.8" y1="331.8" x2="671.3" y2="331.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="566.8" y1="333.8" x2="670.3" y2="333.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="567.8" y1="335.8" x2="670.3" y2="335.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="567.8" y1="337.8" x2="669.3" y2="337.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="568.8" y1="339.8" x2="669.3" y2="339.8" />
          <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="568.8" y1="341.8" x2="668.3" y2="341.8" />
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M615.3,357.8h74c0,0-1,12-7,15h-67" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M622.8,357.8h-74c0,0,1,12,7,15h67" />
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.3,256.8c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M753.3,256.8c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3" />
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.3,269.8c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M753.3,269.8c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3" />
          </g>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="529.3" y1="264.8" x2="537.3" y2="277.8" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="708.6" y1="265" x2="700.6" y2="278" />
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,339.8c0,0,4,6,13,6h123" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.3,347.8c0,0,4.5,6,13.5,6h123" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M752.3,339.8c0,0-4,6-13,6h-123" />
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M753.3,347.8c0,0-4.5,6-13.5,6h-123" />
          </g>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="487.3" y1="376.8" x2="750.3" y2="376.8" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="508.3" y1="309.8" x2="508.3" y2="275.8" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="729.3" y1="309.8" x2="729.3" y2="275.8" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M566.3,340.8c0,0-2-23-34-28l-46-6v22.5c0,3.9,1.9,7.8,5.2,9.9
          c1.5,1,3.4,1.6,5.8,1.6C497.3,340.8,566.6,341,566.3,340.8z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M671.3,340.8c0,0,2-23,34-28l46-6v22.5c0,3.9-1.9,7.8-5.2,9.9
          c-1.5,1-3.4,1.6-5.8,1.6C740.3,340.8,671,341,671.3,340.8z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M537,371.8h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
          c0-1.3,1.1-2.4,2.4-2.4H537c1.3,0,2.4,1.1,2.4,2.4v8.3C539.3,370.7,538.2,371.8,537,371.8z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M718,370.8h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
          c0-1.3,1.1-2.4,2.4-2.4H718c1.3,0,2.4,1.1,2.4,2.4v8.3C720.3,369.7,719.2,370.8,718,370.8z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,362.8v75c0,0,0,0,0,0.1c0.1,2.2,1.9,3.9,4.1,3.9h27.9
          c0,0,4,0,4-3v-50"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M752.3,362.8v75c0,0,0,0,0,0.1c-0.1,2.2-1.9,3.9-4.1,3.9h-27.9
          c0,0-4,0-4-3v-50"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="521.3" y1="407.8" x2="716.3" y2="407.8" />
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M465.6,216.8h-19.5c-3.7,0-6.7-3-6.7-6.7v-42.5
            c0-3.7,3-6.7,6.7-6.7h19.5c3.7,0,6.7,3,6.7,6.7V210C472.3,213.7,469.3,216.8,465.6,216.8z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M455.3,199.8L455.3,199.8c-1.1,0-2-0.9-2-2v-19c0-1.1,0.9-2,2-2
            l0,0c1.1,0,2,0.9,2,2v19C457.3,198.9,456.4,199.8,455.3,199.8z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M457.3,160.8v-11.2c0-0.5,0.2-1,0.6-1.3l2.9-2.2
            c0.3-0.2,0.7-0.4,1.1-0.4h31.4l1-4h-32.1c-0.6,0-1.1,0.2-1.5,0.5l-5.6,4c-0.5,0.3-0.7,0.8-0.7,1.4v13.2H457.3z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M454.8,216.9v6.7c0,0.8,0.3,1.5,0.9,2.1l19.7,19.2
            c0.6,0.6,0.9,1.4,0.9,2.3v16.7h3v-19.7c0-0.8-0.3-1.6-0.9-2.2l-18.1-17.2c-0.6-0.6-1-1.4-1-2.3v-5.7L454.8,216.9z"/>
            <rect x="474.3" y="263.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11" />
          </g>
          <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M772.1,216.8h19.5c3.7,0,6.7-3,6.7-6.7v-42.5
            c0-3.7-3-6.7-6.7-6.7h-19.5c-3.7,0-6.7,3-6.7,6.7V210C765.3,213.7,768.3,216.8,772.1,216.8z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M782.3,199.8L782.3,199.8c1.1,0,2-0.9,2-2v-19c0-1.1-0.9-2-2-2
            l0,0c-1.1,0-2,0.9-2,2v19C780.3,198.9,781.2,199.8,782.3,199.8z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M780.3,160.8v-11.2c0-0.5-0.2-1-0.6-1.3l-2.9-2.2
            c-0.3-0.2-0.7-0.4-1.1-0.4h-31.4l-1-4h32.1c0.6,0,1.1,0.2,1.5,0.5l5.6,4c0.5,0.3,0.7,0.8,0.7,1.4v13.2H780.3z"/>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M782.9,216.9v6.7c0,0.8-0.3,1.5-0.9,2.1l-19.7,19.2
            c-0.6,0.6-0.9,1.4-0.9,2.3v16.7h-3v-19.7c0-0.8,0.3-1.6,0.9-2.2l18.1-17.2c0.6-0.6,1-1.4,1-2.3v-5.7L782.9,216.9z"/>
            <rect x="754.3" y="263.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11" />
          </g>
        </g>
      </g>
      <g id="Layer_4">
        <g>
          <rect x="466.2" y="699.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="305" height="532.9" />
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M616.2,459.6h56c0,0,45,1,72,12c0,0,18,6,16,32l-1,163
            c0,0,2,15-8,16c0,0-18,4-131,5"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M621.4,459.6h-56c0,0-45,1-72,12c0,0-18,6-16,32l1,163
            c0,0-2,15,8,16c0,0,20.8,4,133.8,5"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M619.7,468.4h53.8c0,0,37.2,0,63.4,10.1c0,0,17.1,6,15.1,24.1
            l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M619.7,468.4H564c0,0-37.2,0-63.4,10.1c0,0-17.1,6-15.1,24.1
            l6,26.2c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"/>
          </g>
          <g>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M620.1,472.1h52.7c0,0,36.4,0,62,8.9c0,0,16.7,5.3,14.8,21.4
            l-5.9,23.2c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"/>
            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M620.1,472.1h-54.5c0,0-36.4,0-62,8.9c0,0-16.7,5.3-14.8,21.4
            l5.9,23.2c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"/>
          </g>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M494.2,534.6v140c0,0-2,7-11,7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M743.2,534.6v140c0,0,2,7,11,7" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M494.5,675.6c0,0,119.7,14,249.7,1" />
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M481.2,522.6c0,0,5,11,7,22v111.7c0,0.2,0,0.4,0,0.6
          c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C482.2,658.6,481.7,522.1,481.2,522.6z"/>
          <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M756.2,522.6c0,0-5,11-7,22v111.7c0,0.2,0,0.4,0,0.6
          c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C755.2,658.6,755.8,522.1,756.2,522.6z"/>
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="499.2" y1="684.6" x2="499.2" y2="699.6" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="739.2" y1="683.6" x2="739.2" y2="699.6" />
          <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="530.2" y1="462.6" x2="705.2" y2="462.6" />
          <rect x="467.2" y="504.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44" />
          <rect x="760.2" y="505.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44" />
          <g>
            <polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="433.2,525.6 445.2,516.6 457.2,516.6 469.2,525.6 			
            "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="468.2,528.6 434.2,528.6 433.2,525.6 469.2,525.6 			
            "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="452.2,528.6 483.2,578.6 486.2,578.6 486.2,577.6 
            455.2,528.6 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="458.3,528.7 477.2,545.6 477.2,541.6 462.4,528.6 			
            "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="487.2,574.6 487.2,578.6 483.2,578.6 483.2,583.6 
            487.2,583.6 487.2,587.6 490.2,587.6 490.2,574.6 			"/>
          </g>
          <g>
            <polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="804.2,525.6 792.2,516.6 780.2,516.6 768.2,525.6 			
            "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="769.2,528.6 803.2,528.6 804.2,525.6 768.2,525.6 			
            "/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="785.2,528.6 754.2,578.6 751.2,578.6 751.2,577.6 
            782.2,528.6 			"/>
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="779.1,528.7 760.2,545.6 760.2,541.6 775,528.6 			" />
            <polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="750.2,574.6 750.2,578.6 754.2,578.6 754.2,583.6 
            750.2,583.6 750.2,587.6 747.2,587.6 747.2,574.6 			"/>
          </g>
        </g>
      </g></>),
  },
}