import { WebsitePayloadType } from "../../reducers/website/types";
import {
  WebsiteAction,
  SET_WEBSITE,
  UPDATE_WEBSITE,
} from "./types";

export function updateOrganisation(
  payload: WebsitePayloadType
): WebsiteAction {
  return {
    payload,
    type: UPDATE_WEBSITE
  };
}

export function setOrganisation(payload: WebsitePayloadType): WebsiteAction {
  return {
    payload,
    type: SET_WEBSITE
  };
}
