import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, CircularProgress, Fab, Grid } from '@material-ui/core';
import { useSnackBar } from '../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../SnackbarWrapper/SnackbarWrapper';
import {Typography} from '@material-ui/core';
interface IProps {
  bookingId: string;
  returnUrl: string;
}
const CheckoutForm: React.FC<IProps> = (props) => {
  const stripe: any = useStripe();
  const snackbar = useSnackBar();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/${props.returnUrl}`,
      },
    });

    if (error) {
      console.error(error)
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Your payment has failed! Please check your card details or try again later.",
      })
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "40%", margin: "0 auto" }}>
      <PaymentElement />
      <Grid container xs={12}>
        <Box mt={2}></Box>
      </Grid>
      <Grid container xs={12}>
        <Fab
          type="submit"
          variant="extended"
          size="medium"
          style={{ margin: "0 auto" }}
          disabled={loading}
        >
          {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
          Submit
        </Fab>
      </Grid>
      {loading &&(
        <Grid container justify='center' alignItems='center'>
          <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />
          <Typography variant='body1'>Payment in progress</Typography>
        </Grid>
        )
      }
    </form>
  );
};

export default CheckoutForm;