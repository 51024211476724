import { useLazyQuery, useMutation } from "@apollo/client";
import { CircularProgress, Fab } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { ApolloError } from "apollo-boost";
import { Storage } from "aws-amplify";
import React, { useState } from "react";
import { GENERATE_INVOICE_PDF } from "../../graphql/bookings/generateInvoicePdfMutation";

import { GENERATE_QUOTE_PDF } from "../../graphql/bookings/pdfQueryAndMutation";
import { GET_BOOKING_SUMMARY_PDF } from "../../graphql/bookings/pdfQueryAndMutation";
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import {
  checkDeleteOrDownloadFileType,
  formatGraphQLErrorMessage,
} from "../utils";
import styles from "./index.module.css";

interface ISource {
  id: string;
  type: string;
}
const DownloadPdf = (props: any) => {
  const snackbar = useSnackBar();
  const [loading, setLoading] = useState<boolean>(false);
  async function download(pdfSource: string) {
    try {
      if (pdfSource) {
        setLoading(true);
        const { contentType } = checkDeleteOrDownloadFileType(pdfSource);
        const config = { contentType, level: "public" };
        const file = await Storage.get(pdfSource, config).then((result) => {
          return result;
        });
        const url: any = file;
        fetch(url, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = props.fileName ? props.fileName : `sample.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR,
        });
      }
    } catch (e) {
      snackbar({
        message: e.message,
        variant: SnackBarVariant.ERROR,
      });
    }
  }

  const [generateQuotePdf] = useMutation(GENERATE_QUOTE_PDF, {
    onCompleted: (data: any) => {
      if (data.consumerGenerateQuotePdf.pdf) {
        download(data.consumerGenerateQuotePdf.pdf);
      } else {
        setLoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR,
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR,
      });
    },
  });
  const [booking] = useLazyQuery(GET_BOOKING_SUMMARY_PDF, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.booking.bookingConfirmationUrl) {
        download(data.booking.bookingConfirmationUrl);
      } else {
        setLoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR,
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR,
      });
    },
  });

  const [generateInvoicePdf, { loading: generateInvoicePdfLoading }] = useMutation(GENERATE_INVOICE_PDF, {
    onCompleted: (data: any) => {
      if (data.generateInvoicePdf.invoiceUrl) {
        download(data.generateInvoicePdf.invoiceUrl);
      } else {
        setLoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const getSource = () => {
    const source: ISource = props.getSource;
    switch (source.type) {
      case "QUOTE":
        setLoading(true);
        generateQuotePdf({
          variables: {
            id: source.id,
          },
        });
        break;
      case "BOOKING_SUMMARY":
        setLoading(true);
        booking({
          variables: {
            bookingId: source.id,
          },
        });
        break;
      case "INVOICE":
        setLoading(true);
        generateInvoicePdf({
          variables: {
            invoiceId: source.id,
          },
        });
        break;
      default:
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR,
        });
    }
  };
  const color = "white";
  return (
    props.styledButton ? <button
      className={styles.button}
      type="button"
      disabled={loading}
      onClick={() => {
        if (props.pdfSource) {
          download(props.pdfSource);
        } else {
          getSource();
        }
      }}
    >
      {loading && (
        <CircularProgress size={14} style={{ marginRight: "10px" }} />
      )}
      {"Download"}
    </button> : <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      style={{
        background: "var(--primary-color)",
        textTransform: "initial",
        boxShadow: "none",
        fontSize: 14,
        fontWeight: 500,
      }}
      disabled={loading}
      onClick={() => {
        if (props.pdfSource) {
          download(props.pdfSource);
        } else {
          getSource();
        }
      }}
    >
        {loading && (
          <CircularProgress size={14} style={{ marginRight: "10px" }} />
        )}
        {"Download"}
      </Button>
  );
};
export default DownloadPdf;
