import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

import { SnackBar } from "../SnackBar/SnackBar";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";

const SnackBarContext = createContext<(alerts: IAlert) => Promise<void>>(
  Promise.reject.bind(Promise)
);

export const useSnackBar = () => useContext(SnackBarContext);

const AUTO_DISMISS = 2000;

interface IProps {
  children: any;
}

export interface IAlert {
  message: string;
  variant: SnackBarVariant;
}

export function SnackBarProvider(props: IProps) {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  let showing = false;

  useEffect(() => {
    if (alerts.length > 0 && !showing) {
      showing = true;
      const timer = setTimeout(() => {
        setAlerts(alerts.slice(1));
        showing = false;
      }, AUTO_DISMISS);
      return () => clearTimeout(timer);
    }
  }, [alerts, showing]);

  const addAlert = (alert: IAlert) => {
    setAlerts([...alerts, alert]);
    return Promise.resolve();
  };

  return (
    <>
      <SnackBarContext.Provider value={addAlert}>
        {props.children}
        {alerts.length > 0 && (
          <SnackBar message={alerts[0].message} variant={alerts[0].variant} />
        )}
      </SnackBarContext.Provider>
    </>
  );
}
