import { gql } from "@apollo/client";

export const GET_ALL_DOCUMENT_TYPES = gql`
  query getAllDocumentTypes {
    getAllDocumentTypes {
      id
      typeName
      hasExpiry
      description
      reminderBefore
      isMandatory
      applicableFor
    }
  }
`;