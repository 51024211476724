import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Theme,
  createStyles,
  makeStyles,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

interface IProps {
  title: string;
  children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightBold
    },
    expansionSummary: {
      padding: "0px 15px"
    },
    expansionPannelDetails: {
      padding: "0px 15px"
    }
  })
);

const ExpansionSummary = withStyles({
  root: {
    padding: "0px 15px",
    minHeight:48,
    '&.Mui-expanded': {
      minHeight: 48,
    },
  },
})(ExpansionPanelSummary);

const StyledExpansionPanel = withStyles({
  root: {
    boxShadow:'none !important',
    '&.Mui-expanded': {
      margin: 0,
      '&:before':{
        opacity:1
      }
    },
    '&:before':{
      backgroundColor:'var(--background-secondary)'
    }
  },
})(ExpansionPanel);

export const NuvvenExpansionPanel = (props: IProps) => {
  const classes = useStyles();

  return (
    <StyledExpansionPanel square>
      <ExpansionSummary
        expandIcon={<ExpandMoreIcon fontSize="small"/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <span className="font-medium bold capitalize">{props.title.toLowerCase()}</span>
      </ExpansionSummary>
      <ExpansionPanelDetails className={classes.expansionPannelDetails}>
        <div style={{ width: "100%"}}>{props.children}</div>
      </ExpansionPanelDetails>
    </StyledExpansionPanel>
  );
};
