import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DefaultAddons, IAddonType } from "../SelectAddOns/types";
import { IAddonRequirementInput } from "../../../../reducers/bookings/types";
import { IAppState } from "../../../../store";
import { getAddonIcon, toCurrency } from "../../../utils";
import MinusIcon from "@material-ui/icons/Remove";
import PlusIcon from "@material-ui/icons/Add";
import DeleteIcon from '@material-ui/icons/DeleteForever';
import styles from './index.module.css';

interface IProps {
  addon: IAddonType;
  rateTypeName: string;
  selectedAddon?: IAddonRequirementInput;
  onSelect(addon?: IAddonType, rate?: number, quantity?: number): void;
}
export const CustomCard: React.FC<IProps> = (props) => {
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const [selected, setSelected] = useState<boolean>(
    !!props.selectedAddon?.addon
  );
  const { addon, selectedAddon, onSelect } = props;
  const { locale, currency } = organisation;          //TODO from API
  const recurringRate = props.addon.recurringRate?.find(
    (rate) => rate.rateTypeName === props.rateTypeName
  );
  const rate = props.addon.hasFixedRate
    ? props.addon.fixedRate
      ? props.addon.fixedRate
      : 0
    : recurringRate
      ? recurringRate.rate
      : 0;
  useEffect(() => {
    setSelected(!!props.selectedAddon?.name);
  }, [props.selectedAddon]);

  useEffect(() => {
    if (!selected && props.selectedAddon?.name) {      
      props.onSelect(addon);
    }
  }, [selected]);

  return (
    <>
      <div className={styles.addonIcon}>
        <img
          src={getAddonIcon(props.addon.name === "ADDITIONAL_DRIVER" ? "ADDITIONAL_DRIVER" : "custom-addon")}
          alt="custom addon"
        />
      </div>
      <div className={styles.addonInfo}>
        <p>{addon.displayName ? addon.displayName : addon.name}</p>
        <span className="font-medium">{addon.description}</span>
      </div>
      <div className="flex col-flex cross-center">
        <div className={styles.addonRate}>
          {addon.name !== 'VALET' ?
            <span> {toCurrency(rate, currency, locale)} </span>
            :
            ''
          }          
        </div>
        {/* { addon.name !== "ADDITIONAL_DRIVER" ? */}
          <div className={styles.addCounter}>
            <div
              style={{
                width: 160,
                height: 34,
                borderRadius: 8,
                background: '#fff'
              }}
              className="flex cross-center"
            >
              <div
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 8,
                  backgroundColor: (selectedAddon?.quantity || 0) > 0 ? '#ffcfcb' : '#fff',
                  color: (selectedAddon?.quantity || 1) > 0 ? '#e00101' : '#969696',
                  cursor: 'pointer',
                  border: '2px solid var(--background-secondary)'
                }}
                className="flex cross-center main-center"
                onClick={() =>
                  onSelect(addon, rate, (selectedAddon?.quantity || 1) - 1)
                }
              >
                {(selectedAddon?.quantity || 0) === 1 ? <DeleteIcon style={{ fontSize: 18 }} /> : <MinusIcon style={{ fontSize: 18 }} />}
              </div>
              
              <div
                className={`flex fill main-center font-small ${(selectedAddon?.quantity || 1) > 0 && 'bold'}`}
              >
                Selected: {props.selectedAddon?.quantity || 0}
              </div>
              <div
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 8,
                  backgroundColor: 'var(--primary-color)',
                  color: '#fff',
                  cursor: 'pointer',
                  border: '2px solid var(--background-secondary)'
                }}
                className="flex cross-center main-center"
                onClick={() => {
                  if (props.addon.name === DefaultAddons.ADDITIONAL_DRIVER) {
                    onSelect(addon, rate, (selectedAddon?.quantity || 0) + 1)
                  } 
                  else {
                    const count = (selectedAddon?.quantity || 0) + 1;
                    if ((props.addon.availableQuantity || 0) >= count) {
                      onSelect(addon, rate, count);
                    }
                  }
                }}
              >
                <PlusIcon style={{ fontSize: 18 }} />
              </div>
            </div>            
            {(selectedAddon?.quantity || 0) < 1 &&
              <div className={styles.addonSelectButtonWrap}>
                <input
                  className={styles.addonSelectCheck}
                  type="checkbox"
                  id={addon.id}
                  onChange={() => {
                    if(props.addon.name !== 'ADDITIONAL_DRIVER') {
                      const count = (selectedAddon?.quantity || 0) + 1;
                      if ((props.addon.availableQuantity || 0) >= count) {
                        onSelect(addon, rate, count);
                      }
                    }
                    else {                      
                      onSelect(addon, rate, (selectedAddon?.quantity || 0) + 1)                      
                    }
                  }}
                  checked={selected}
                />
                <label htmlFor={addon.id} className={styles.buttonStyledCheck} />
              </div>
            }
          </div>
        {/* : } */}
        <span className="font-medium padding-top"> 
        {addon.name !== 'VALET' && addon.name !== 'ADDITIONAL_DRIVER' ?
          <span>
            Available: {(props.addon.availableQuantity || 0) - (props.selectedAddon?.quantity || 0) || 0}
          </span>
          :
          ''
        }
        </span>
      </div>
    </>
  );
};
