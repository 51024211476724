import styles from "../index.module.css"
import { Box, Button, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, FormGroup, Grid, TextField, Theme, Typography, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IBookingCreateInput, ICustomerInfoInput } from '../../../../../reducers/bookings/types'
import { IBusinessCustomer, ICustomer } from '../../../../../reducers/auth/types'
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client"
import { CONSUMER_VERIFY_CUSTOMER_LICENCE_DVLA } from "../../../../../graphql/customers/consumerVerifyCustomerLicenceDVLA"
import { SOCKET_EVENTS } from "../../../utils"
import { socket } from "../../../../../utils/socket"
import { IAppState } from "../../../../../store"
import { useSelector } from "react-redux"
import { GET_CONSUMER_CUSTOMER } from "../../../../../graphql/customers/getConsumerCustomer"
import { useSnackBar } from "../../../../SnackBarContext/SnackBarContext"
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../../../utils"
import { SnackBarVariant } from "../../../../SnackbarWrapper/SnackbarWrapper"
import { GET_BUSINESS_CREDIT_SCORE } from "../../../../../graphql/customers/getConsumerBusinessCreditScroeMutation"
import { GET_CREDIT_SCORE } from "../../../../../graphql/customers/getCreditScoreMutation"
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex"

interface IProps {
  bookingData: IBookingCreateInput;
  onSubmit: (data: IBookingCreateInput) => void
  customerInfo?: ICustomerInfoInput
}

export interface IDvlaCheckValues {
  licenseNumber: string;
  insuranceNumber: string;
  postCode: string;
}


export enum DvlaVerificationStatus {
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed"
}

const dvlaCheckInitialValues: IDvlaCheckValues = {
  licenseNumber: "GUPTA907293A99KR",
  insuranceNumber: "SY140267D",
  postCode: "EH6 4EP"
}

const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // marginBottom: 20,
      '& .MuiOutlinedInput-input': {
        padding: '14px 14px'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px 4px'
      },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: '#e2e2e2',
        borderRadius: 8
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      },
      '& .MuiSelect-select': {
        padding: '10px 14px'
      },
      '& .MuiSelect-select ~ fieldset': {
        border: 0
      },
    },
  }),
)(TextField);

const Verification: React.FC<IProps> = (props) => {

  const snackbar = useSnackBar();

  const { customerInfo } = props;

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const { locale, currency } = organisation;
  const { country } = website.organisation.address;

  const [values, setValues] = useState(dvlaCheckInitialValues)
  const [agreedDvla, setAgreedDvla] = useState<boolean>(false);
  const [dvlaVerificationStatus, setDvlaVerificationStatus] = useState<string>(
    ""
  );
  const [isCheckedCreditScore, setIsCheckedCreditScore] = useState<boolean>(false)
  const [customer, setCustomer] = useState<ICustomer>()
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>()

  const [getConsumerCustomer, { data }] = useLazyQuery(GET_CONSUMER_CUSTOMER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.customer && data.customer.licence) {
        setDvlaVerificationStatus(data.customer.licence.dvlaVerificationStatus)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (user) {
      if (user.customer) {
        setCustomer(user.customer)
      } else {
        setBusinessCustomer(user?.businesscustomers)
      }
    }

  }, [user])
  useEffect(() => {
    if (customer) {
      setValues({
        ...values,
        licenseNumber: customer.license.licenseNumber,
        insuranceNumber: customer.nationalInsuranceNumber,
        postCode: customer.location?.zipcode || ""
      })
    }
  }, [customer])

  useEffect(() => {
    if (user && user.customer) {
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_CUSTOMER, { customerId: user.customer.id });
    }
  }, [user]);

  useEffect(() => {
    socket.auth = {
      userId: user?.id
    };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_CUSTOMER, (data) => {
      getConsumerCustomer({
        variables: {
          customerId: data.customerId
        }
      })
    })

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_CUSTOMER);
    }
  }, [])

  const [consumerVerifyCustomerLicenceDvla] = useLazyQuery(
    CONSUMER_VERIFY_CUSTOMER_LICENCE_DVLA,
    {
      fetchPolicy: "no-cache"
    }
  );

  const verifyLicenceWithDvla = (data: IDvlaCheckValues) => {
    consumerVerifyCustomerLicenceDvla({
      variables: {
        ...data,
        customerId: customer?.id
      }
    });
    setDvlaVerificationStatus(DvlaVerificationStatus.PENDING);
  };

  const [
    getCreditScoreForBusinessCustomer,
    { loading: scoreLoading }
  ] = useMutation(GET_BUSINESS_CREDIT_SCORE, {
    onCompleted: (data) => {
      if (!data.getConsumerBusinessCreditScore) {
        snackbar({
          message: "No valid credit reading found for this company.",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const getBusinessCreditScore = () => {
    getCreditScoreForBusinessCustomer({
      variables: {
        businessCustomerId: businessCustomer?.id
      }
    });
  };

  const [getCreditScoreForCustomer, { loading: customerScoreLoading }] = useMutation(
    GET_CREDIT_SCORE,
    {
      onCompleted: (data) => {
        getConsumerCustomer({
          variables: {
            customerId: customer?.id
          }
        });
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const getCreditScore = () => {
    getCreditScoreForCustomer({
      variables: {
        customerId: customer?.id
      }
    });
  };

  const schema = Yup.object().shape({
    drivingLicenceNumber: Yup.string().required("Driving licence number is required"),
    nationalInsuranceNumber: Yup.string().required("National insurance number is required"),
    postCode: Yup.string().required("Post code is required")
  });

  return (
    <Grid item xs={12} container spacing={2}>
      {
        customer && (
          <Grid item xs={6}>
            <Box className={styles.licence_check_container}>
              <Box className={styles.dvla_main_container}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} container justify="center">
                    <Typography variant="h2">{"VERIFY YOUR LICENCE"}</Typography>
                  </Grid>
                  <Grid item xs={12} container justify="center">
                    <Typography variant="body1">
                      Please validate or enter following details to proceed for licence check. All fields are mandatory
                    </Typography>
                  </Grid>
                  <Formik
                    initialValues={values}
                    validationSchema={schema}
                    onSubmit={values => {

                    }}
                  >
                    {(formikProps) => (
                      <Form style={{ flexGrow: 1, marginTop: 10, marginBottom: 20 }}>
                        <Grid container spacing={2} justify="center">
                          <Grid item xs={12} container justify="center">
                            <Grid item xs={6}>
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={StyledInput}
                                  placeholder="Driving Licence Number"
                                  label="Driving Licence Number"
                                  name={"drivingLicenceNumber"}
                                  value={formikProps.values.licenseNumber}
                                  onChange={formikProps.handleChange}
                                  fullWidth
                                  required
                                ></Field>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} container justify="center" >
                            <Grid item xs={6}>
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={StyledInput}
                                  placeholder="National Insurance Number"
                                  label="National Insurance Number"
                                  name={"nationalInsuranceNumber"}
                                  value={formikProps.values.insuranceNumber}
                                  onChange={formikProps.handleChange}
                                  fullWidth
                                  required
                                ></Field>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} container justify="center" >
                            <Grid item xs={6}>
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={StyledInput}
                                  placeholder="Post Code"
                                  label="Post Code"
                                  name={"postCode"}
                                  value={formikProps.values.postCode}
                                  onChange={formikProps.handleChange}
                                  fullWidth
                                  required
                                ></Field>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={agreedDvla}
                                    onChange={(e: any) => {
                                      setAgreedDvla(e.target.checked)
                                    }}
                                    value={agreedDvla}
                                    color="primary"
                                    name={"agreed"}
                                  />
                                }
                                label={
                                  <Typography variant="body1">
                                    This agreement allows  and our insurance partner to carry out checks
                                    on your driving licence and pass the data provided by you to insurers and other related third
                                    parties (Coastr) for the administration of your vehicle rental, insurance policy, etc.
                                    Do you accept and consent to the above?
                                  </Typography>
                                }
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} container justify="center">
                            <Grid item xs={6}>
                              <Button
                                className={styles.next_step_btn}
                                onClick={() => {
                                  verifyLicenceWithDvla(values)
                                }}
                                fullWidth
                                disabled={!values.licenseNumber || !values.insuranceNumber || !values.postCode}
                              >
                                Verify Licence
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
                {
                  dvlaVerificationStatus === DvlaVerificationStatus.PENDING && (
                    <>
                      <Grid container xs={12} justify="center">
                        <Typography variant="h2">
                          Licence Checking In Progress
                        </Typography>
                      </Grid>
                      <Grid container xs={12} justify="center">
                        <Typography variant="body1">
                          It may take up to 2 minutes, please wait and do not refresh or close your browser
                        </Typography>
                      </Grid>
                      <Grid container xs={12} justify="center">
                        <CircularProgress />
                      </Grid>
                    </>
                  )
                }
                {
                  dvlaVerificationStatus === DvlaVerificationStatus.FAILED && (
                    <>
                      <Grid container xs={12} justify="center">
                        <Typography variant="h2">
                          Licence Checking Failed
                        </Typography>
                      </Grid>
                      <Grid container xs={12} justify="center">
                        <Typography variant="body1">
                          Please check if you have entered the correct details.
                        </Typography>
                      </Grid>
                      <Grid container xs={12} justify="center">
                        <Typography variant="body1">
                          If the issue still persists please try again later.
                        </Typography>
                      </Grid>
                    </>
                  )
                }
                {dvlaVerificationStatus === DvlaVerificationStatus.SUCCEEDED && <>
                  <Grid container xs={12} justify="center">
                    <Typography variant="h2">
                      Licence Checked Successfully
                    </Typography>
                  </Grid>
                  <Grid container xs={12} justify="center">
                    <Typography variant="body1">
                      Licence Summary added to customer record
                    </Typography>
                  </Grid>
                </>
                }
              </Box>
            </Box>
          </Grid>
        )
      }

      {
        (customer || businessCustomer) && (
          <Grid item xs={6}>
            <Box className={styles.licence_check_container}>
              <Box className={styles.dvla_main_container}>
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={12} justify="center" style={{ paddingBottom: "20px" }}>
                    {
                      businessCustomer?.businessCreditData &&
                      <Typography variant="h4">
                        Credit Score:{" "}
                        {businessCustomer?.businessCreditData?.creditScore === 0 ? (
                          <CircularProgress size={12} />
                        ) : (
                          businessCustomer?.businessCreditData?.creditScore
                        )}{" "}
                      </Typography>
                    }
                    {
                      customer?.creditSafeData &&
                      <Typography variant="h4">
                        Credit Score:{" "}
                        {customer?.creditSafeData?.creditScore === 0 ? (
                          <CircularProgress size={12} />
                        ) : (
                          customer?.creditSafeData?.creditScore
                        )}{" "}
                      </Typography>
                    }
                    {
                      customer?.creditSafeData &&
                      <Typography variant="h4">
                        Credit Score:{" "}
                        {customer?.creditSafeData?.creditScore === 0 ? (
                          <CircularProgress size={12} />
                        ) : (
                          customer?.creditSafeData?.creditScore
                        )}{" "}
                      </Typography>
                    }
                  </Grid>
                  <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
                    <Typography variant="h4">
                      {" "}
                      Last Checked:{" "}
                      {businessCustomer?.businessCreditData?.lastChecked === "" ? (
                        <CircularProgress size={12} />
                      ) : (
                        getLocalizedDateFormat(
                          country,
                          businessCustomer?.businessCreditData?.lastChecked || "",
                          DATE_TYPE.EXPANDED
                        )
                      )}
                    </Typography>
                  </Grid>
                  {
                    (!customer?.creditSafeData || !businessCustomer?.businessCreditData) && (
                      <>
                        <Grid item xs={12} container justify="center">
                          <Typography variant="h2">{"CHECK YOUR CREDIT SCORE"}</Typography>
                        </Grid>
                        <Grid item xs={12} container justify="center">
                          <Grid item xs={6}>
                            <Button
                              className={styles.next_step_btn}
                              onClick={() => {
                                if (user && user.businesscustomers) {
                                  getBusinessCreditScore()
                                } else {
                                  getCreditScore()
                                }
                              }}
                              fullWidth
                            >
                              GET YOUR SCORE
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )
                  }

                  {
                    (scoreLoading || customerScoreLoading) && (
                      <><CircularProgress /></>
                    )
                  }


                </Grid>
              </Box>
            </Box>
          </Grid>

        )
      }
      <Grid item xs={12} container justify="flex-end">
        <Grid item>
          <Button
            className={styles.next_step_btn}
            onClick={() => {
              if (props.bookingData) {
                props.onSubmit(props.bookingData)
              }
            }}
            fullWidth
            type="submit"
          >
            Get Quote
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Verification