import { IBookingCreateInput, VehicleSelectionView } from "../../reducers/bookings/types";

export const UPDATE_BOOKING = "UPDATE_BOOKING";

export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const UPDATE_BOOKING_INVOICE = "UPDATE_BOOKING_INVOICE";
export const SET_VEHICLE_SELECTION_VIEW = "SET_VEHICLE_SELECTION_VIEW";
export const UPDATE_VEHICLE_CHECKS = "UPDATE_VEHICLE_CHECKS";
export const SET_CREATE_BOOKING_INPUT = "SET_CREATE_BOOKING_INPUT";
interface IUpdateBooking {
  type: typeof UPDATE_BOOKING;
  payload: any;
}

interface IClearBooking {
  type: typeof CLEAR_BOOKING;
}

interface IUpdateInvoice {
  payload: string;
  type: typeof UPDATE_BOOKING_INVOICE;
}

interface ISetVehicleSelectionView {
  payload: VehicleSelectionView;
  type: typeof SET_VEHICLE_SELECTION_VIEW;
}
interface IUpdateVehicleChecks {
  payload: any;
  type: typeof UPDATE_VEHICLE_CHECKS;
}

interface ISetCreateBookingInput {
  payload: IBookingCreateInput;
  type: typeof SET_CREATE_BOOKING_INPUT;
}
export type BookingAction =
  | IUpdateBooking
  | IClearBooking
  | IUpdateInvoice
  | IUpdateVehicleChecks
  | ISetVehicleSelectionView
  | ISetCreateBookingInput;
