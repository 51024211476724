import * as React from "react"

export const Van26TonneSVG = {
    interior:{
        width:387,
        height:802,
        svg:(
            <>
 <g>
	<g>
		<rect x="41.2" y="254" fill="none" stroke="#000000" stroke-miterlimit="10" width="305" height="532.9"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M191.2,14h56c0,0,45,1,72,12c0,0,18,6,16,32l-1,163c0,0,2,15-8,16
				c0,0-18,4-131,5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M196.4,14h-56c0,0-45,1-72,12c0,0-18,6-16,32l1,163c0,0-2,15,8,16
				c0,0,20.8,4,133.8,5"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M194.7,22.8h53.8c0,0,37.2,0,63.4,10.1c0,0,17.1,6,15.1,24.1
				l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M194.7,22.8H139c0,0-37.2,0-63.4,10.1c0,0-17.1,6-15.1,24.1l6,26.2
				c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M195.1,26.5h52.7c0,0,36.4,0,62,8.9c0,0,16.7,5.3,14.8,21.4
				L318.6,80c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M195.1,26.5h-54.5c0,0-36.4,0-62,8.9c0,0-16.7,5.3-14.8,21.4
				L69.8,80c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M69.2,89v140c0,0-2,7-11,7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M318.2,89v140c0,0,2,7,11,7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M69.5,230c0,0,119.7,14,249.7,1"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M56.2,77c0,0,5,11,7,22l0,111.7c0,0.2,0,0.4,0,0.6
			c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C57.2,213,56.7,76.6,56.2,77z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M331.2,77c0,0-5,11-7,22l0,111.7c0,0.2,0,0.4,0,0.6
			c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C330.2,213,330.7,76.6,331.2,77z"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="74.2" y1="239" x2="74.2" y2="254"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="314.2" y1="238" x2="314.2" y2="254"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="105.2" y1="17" x2="280.2" y2="17"/>
		<rect x="42.2" y="59" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
		<rect x="335.2" y="60" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
		<g>
			<polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="8.2,80 20.2,71 32.2,71 44.2,80 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="43.2,83 9.2,83 8.2,80 44.2,80 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="27.2,83 58.2,133 61.2,133 61.2,132 30.2,83 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="33.3,83.1 52.2,100 52.2,96 37.4,83 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="62.2,129 62.2,133 58.2,133 58.2,138 62.2,138 
				62.2,142 65.2,142 65.2,129 			"/>
		</g>
		<g>
			<polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="379.2,80 367.2,71 355.2,71 343.2,80 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="344.2,83 378.2,83 379.2,80 343.2,80 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="360.2,83 329.2,133 326.2,133 326.2,132 357.2,83 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="354.1,83.1 335.2,100 335.2,96 350,83 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="325.2,129 325.2,133 329.2,133 329.2,138 325.2,138 
				325.2,142 322.2,142 322.2,129 			"/>
		</g>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M74,91"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M72.5,91c0,0,10.8,122.9,10,126c-1,4-13,13-13,13"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M314.5,91c0,0-10.8,122.9-10,126c1,4,13,13,13,13"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M82.5,217c0,0,104.1,13,222,0"/>
</g>
<g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M123.7,97.7h44.2c0,0,6.5,1.2,9,12.6l0.4,28.2
		c0,0-3.3,16.1-12.4,21.2h-39.7c0,0-8.1-6.3-11.6-21v-28.9C113.5,109.8,116.8,99.1,123.7,97.7z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M178.2,154.9l-1-1.7h-5.5l-3.7,4.2l-4.4,2.3l-18.4-0.2v-0.1
		l-18.8,0.6l-4-3.1l-3-4l-6.3,0.3l-1.7,3.1l-1,8.2v25l0.8,6.5l2.3,4.4l2.9,2.8l4.5,2.2h6.2h2.7l0.2,1.8h15.2v0.1h15.2l0.2-1.8h2.7
		h6.2l4.5-2.2l2.9-2.8l2.3-4.4l0.8-6.5v-25l-0.8-6.5C179.1,156.9,178.7,155.9,178.2,154.9z"/>
	<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="145.2,188.6 126.2,188.6 126.2,205.1 129.8,209 145.2,209 
		160.5,209 164.1,205.1 164.1,188.6 	"/>
</g>
<g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M221.7,98.7h44.2c0,0,6.5,1.2,9,12.6l0.4,28.2
		c0,0-3.3,16.1-12.4,21.2h-39.7c0,0-8.1-6.3-11.6-21v-28.9C211.5,110.8,214.8,100.1,221.7,98.7z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M276.2,155.9l-1-1.7h-5.5l-3.7,4.2l-4.4,2.3l-18.4-0.2v-0.1
		l-18.8,0.6l-4-3.1l-3-4l-6.3,0.3l-1.7,3.1l-1,8.2v25l0.8,6.5l2.3,4.4l2.9,2.8l4.5,2.2h6.2h2.7l0.2,1.8h15.2v0.1h15.2l0.2-1.8h2.7
		h6.2l4.5-2.2l2.9-2.8l2.3-4.4l0.8-6.5v-25l-0.8-6.5C277.1,157.9,276.7,156.9,276.2,155.9z"/>
	<polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="243.2,189.6 224.2,189.6 224.2,206.1 227.8,210 243.2,210 
		258.5,210 262.1,206.1 262.1,189.6 	"/>
</g>
            </>
        ),
    },

    exterior:{
        width:1224.7,
        height:1928.1,
        svg:(
            <>
<g id="Layer_2_00000128465917794039431330000007439254303631224460_">
	<g>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M464.8,1875.3v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H464.8z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M501.8,1875.3v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H501.8z"/>
		</g>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M681.8,1875.3v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H681.8z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M718.8,1875.3v34.4c0,1.7,1.2,3.1,2.7,3.1h29.6
				c1.5,0,2.7-1.4,2.7-3.1v-34.4H718.8z"/>
		</g>
		<rect x="454.8" y="1483.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="308" height="331"/>
		<rect x="459.8" y="1494.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="298" height="286"/>
		<rect x="463.8" y="1497.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279"/>
		<rect x="610.8" y="1497.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="142" height="279"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="454.8" y1="1787.3" x2="761.8" y2="1787.3"/>
		<rect x="465.8" y="1790.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20"/>
		<rect x="699.8" y="1790.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="51" height="20"/>
		<rect x="567.8" y="1791.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="73" height="17"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="454.8" y1="1486.3" x2="762.8" y2="1486.3"/>
		<rect x="463.8" y="1486.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4"/>
		<rect x="738.8" y="1486.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="4"/>
		<g>
			<rect x="454.8" y="1523.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="455.9" y="1533.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="454.8" y="1629.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="455.9" y="1639.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="454.8" y="1736.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="455.9" y="1746.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="758.9" y="1523.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="739.8" y="1533.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="758.9" y="1629.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="739.8" y="1639.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<rect x="758.9" y="1736.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="3.4" height="23"/>
			<rect x="739.8" y="1746.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="21.4" height="3"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M596.2,1711.3h-21.9c-0.9,0-1.6-0.7-1.6-1.6v-48.9
				c0-0.9,0.7-1.6,1.6-1.6h21.9c0.9,0,1.6,0.7,1.6,1.6v48.9C597.8,1710.5,597.1,1711.3,596.2,1711.3z"/>
			<g>
				<rect x="586.3" y="1665.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="592" y1="1665.9" x2="592" y2="1705.1"/>
			</g>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M620.4,1711.3h21.9c0.9,0,1.6-0.7,1.6-1.6v-48.9
				c0-0.9-0.7-1.6-1.6-1.6h-21.9c-0.9,0-1.6,0.7-1.6,1.6v48.9C618.8,1710.5,619.5,1711.3,620.4,1711.3z"/>
			<g>
				<rect x="623.8" y="1665.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="6.5" height="40.5"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="624.6" y1="1665.9" x2="624.6" y2="1705.1"/>
			</g>
		</g>
		<rect x="461.8" y="1814.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61"/>
		<rect x="678.8" y="1814.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="78" height="61"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="539.8" y1="1870.3" x2="678.8" y2="1870.3"/>
		<rect x="464.8" y="1836.3" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" width="290" height="15"/>
		<g>
			<g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M454.8,1685.3v-56h-11c0,0-4,0-7.4,1c-2.7,0.8-4.6,3.3-4.6,6.1
					v24.9"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M454.8,1629.3v56h-11c0,0-4,0-7.4-1c-2.7-0.8-4.6-3.3-4.6-6.1
					v-24.9"/>
			</g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M443.8,1685.3v6.7c0,0.9,0.3,1.7,0.9,2.3l10.1,10.1v-5l-6.3-6.3
				c-0.4-0.4-0.7-1-0.7-1.6v-6L443.8,1685.3z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M447.8,1629.3v-11.2c0-0.5,0.3-0.9,0.7-1.1l6.3-2.7v-3l-8.6,3.4
				c-0.9,0.3-1.4,1.2-1.4,2.1v12.5L447.8,1629.3L447.8,1629.3z"/>
		</g>
		<g>
			<g>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M762.8,1685.3v-56h11c0,0,4,0,7.4,1c2.7,0.8,4.6,3.3,4.6,6.1v24.9
					"/>
				<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M762.8,1629.3v56h11c0,0,4,0,7.4-1c2.7-0.8,4.6-3.3,4.6-6.1v-24.9
					"/>
			</g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M773.8,1685.3v6.7c0,0.9-0.3,1.7-0.9,2.3l-10.1,10.1v-5l6.3-6.3
				c0.4-0.4,0.7-1,0.7-1.6v-6L773.8,1685.3z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M769.8,1629.3v-11.2c0-0.5-0.3-0.9-0.7-1.1l-6.3-2.7v-3l8.6,3.4
				c0.9,0.3,1.4,1.2,1.4,2.1v12.5L769.8,1629.3L769.8,1629.3z"/>
		</g>
	</g>
</g>
<g id="Layer_3">
	<g>
		<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="474.2,316.6 472.7,316.6 472.7,31.4 746.7,31.4 
			746.7,316.6 745.2,316.6 		"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609.5,122.6l99.5,1c0,0,22-1,25,17l4,24l4,35l2,36l1,73v8
				c0,0-1,16-2,20l1,9c0,0,1,13-2,22l-1,8c0,0-3,12-11,12h-32c0,0-8,1-12-7h-76.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609.5,122.6l-99.5,1c0,0-22-1-25,17l-4,24l-4,35l-2,36l-1,73v8
				c0,0,1,16,2,20l-1,9c0,0-1,13,2,22l1,8c0,0,3,12,11,12h32c0,0,8,1,12-7h76.5"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609,137.6l104.3,2c10.3,0,17.7,8,18.7,18.7l6,62.3
				c0,10.3,1,19-21,19l-108,2"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609,137.6l-104.3,2c-10.3,0-17.7,8-18.7,18.7l-6,62.3
				c0,10.3-1,19,21,19l108,2"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609.3,140.6l102.9,1.9c10.1,0,17.4,7.6,18.4,17.7l5.9,59
				c0,9.7,1,18-20.7,18l-106.5,1.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609.3,140.6l-102.9,1.9c-10.1,0-17.4,7.6-18.4,17.7l-5.9,59
				c0,9.7-1,18,20.7,18l106.5,1.9"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609.5,312.6H669c6,0,8,3,8,3c-18,10-18,27-18,27h-49.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M609.5,312.6H550c-6,0-8,3-8,3c18,10,18,27,18,27h50.5"/>
		</g>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="543" y1="314.6" x2="676" y2="314.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="543.5" y1="316.6" x2="675" y2="316.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="546.5" y1="318.6" x2="673" y2="318.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="549.5" y1="320.6" x2="670" y2="320.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="551.5" y1="322.6" x2="668" y2="322.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="552.5" y1="324.6" x2="666" y2="324.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="554.5" y1="326.6" x2="665" y2="326.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="555.5" y1="328.6" x2="663" y2="328.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="556.5" y1="330.6" x2="662" y2="330.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="557.5" y1="332.6" x2="661" y2="332.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="558.5" y1="334.6" x2="661" y2="334.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="558.5" y1="336.6" x2="660" y2="336.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="559.5" y1="338.6" x2="660" y2="338.6"/>
		<line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="559.5" y1="340.6" x2="659" y2="340.6"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M606,356.6h74c0,0-1,12-7,15h-67"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M613.5,356.6h-74c0,0,1,12,7,15h67"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M475,255.6c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M744,255.6c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M475,268.6c0,0,17,7,45,8c-1.1-0.1,64.6,3.6,90,3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M744,268.6c0,0-17,7-45,8c1.1-0.1-64.6,3.6-90,3"/>
		</g>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="520" y1="263.6" x2="528" y2="276.6"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="699.3" y1="263.8" x2="691.3" y2="276.8"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M476,338.6c0,0,4,6,13,6h123"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M475,346.6c0,0,4.5,6,13.5,6h123"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M743,338.6c0,0-4,6-13,6H607"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M744,346.6c0,0-4.5,6-13.5,6h-123"/>
		</g>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="478" y1="375.6" x2="741" y2="375.6"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="499" y1="308.6" x2="499" y2="274.6"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="720" y1="308.6" x2="720" y2="274.6"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M557,339.6c0,0-2-23-34-28l-46-6v22.5c0,3.9,1.9,7.8,5.2,9.9
			c1.5,1,3.4,1.6,5.8,1.6C488,339.6,557.3,339.8,557,339.6z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M662,339.6c0,0,2-23,34-28l46-6v22.5c0,3.9-1.9,7.8-5.2,9.9
			c-1.5,1-3.4,1.6-5.8,1.6C731,339.6,661.7,339.8,662,339.6z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M527.7,370.6h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
			c0-1.3,1.1-2.4,2.4-2.4h16.3c1.3,0,2.4,1.1,2.4,2.4v8.3C530,369.5,528.9,370.6,527.7,370.6z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M708.7,369.6h-16.3c-1.3,0-2.4-1.1-2.4-2.4v-8.3
			c0-1.3,1.1-2.4,2.4-2.4h16.3c1.3,0,2.4,1.1,2.4,2.4v8.3C711,368.5,709.9,369.6,708.7,369.6z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M476,361.6v75c0,0,0,0,0,0.1c0.1,2.2,1.9,3.9,4.1,3.9H508
			c0,0,4,0,4-3v-50"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M743,361.6v75c0,0,0,0,0,0.1c-0.1,2.2-1.9,3.9-4.1,3.9H711
			c0,0-4,0-4-3v-50"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="512" y1="406.6" x2="707" y2="406.6"/>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M456.3,215.6h-19.5c-3.7,0-6.7-3-6.7-6.7v-42.5
				c0-3.7,3-6.7,6.7-6.7h19.5c3.7,0,6.7,3,6.7,6.7v42.4C463,212.5,460,215.6,456.3,215.6z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M446,198.6L446,198.6c-1.1,0-2-0.9-2-2v-19c0-1.1,0.9-2,2-2l0,0
				c1.1,0,2,0.9,2,2v19C448,197.7,447.1,198.6,446,198.6z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M448,159.6v-11.2c0-0.5,0.2-1,0.6-1.3l2.9-2.2
				c0.3-0.2,0.7-0.4,1.1-0.4H484l1-4h-32.1c-0.6,0-1.1,0.2-1.5,0.5l-5.6,4c-0.5,0.3-0.7,0.8-0.7,1.4v13.2H448z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M445.5,215.7v6.7c0,0.8,0.3,1.5,0.9,2.1l19.7,19.2
				c0.6,0.6,0.9,1.4,0.9,2.3v16.7h3V243c0-0.8-0.3-1.6-0.9-2.2L451,223.6c-0.6-0.6-1-1.4-1-2.3v-5.7L445.5,215.7z"/>
			<rect x="465" y="262.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11"/>
		</g>
		<g>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M762.8,215.6h19.5c3.7,0,6.7-3,6.7-6.7v-42.5
				c0-3.7-3-6.7-6.7-6.7h-19.5c-3.7,0-6.7,3-6.7,6.7v42.4C756,212.5,759,215.6,762.8,215.6z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M773,198.6L773,198.6c1.1,0,2-0.9,2-2v-19c0-1.1-0.9-2-2-2l0,0
				c-1.1,0-2,0.9-2,2v19C771,197.7,771.9,198.6,773,198.6z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M771,159.6v-11.2c0-0.5-0.2-1-0.6-1.3l-2.9-2.2
				c-0.3-0.2-0.7-0.4-1.1-0.4H735l-1-4h32.1c0.6,0,1.1,0.2,1.5,0.5l5.6,4c0.5,0.3,0.7,0.8,0.7,1.4v13.2H771z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M773.6,215.7v6.7c0,0.8-0.3,1.5-0.9,2.1L753,243.7
				c-0.6,0.6-0.9,1.4-0.9,2.3v16.7h-3V243c0-0.8,0.3-1.6,0.9-2.2l18.1-17.2c0.6-0.6,1-1.4,1-2.3v-5.7L773.6,215.7z"/>
			<rect x="745" y="262.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="9" height="11"/>
		</g>
	</g>
	<g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M490.7,129.3l5.3-87c0,0,0-5,6.4-5h105"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M725.7,128.3l-5.3-86c0,0,0-5-6.4-5H606.3"/>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M686.4,115.2l9.8-26.1c0.4-1.1,1.5-1.8,2.6-1.8h16
		c1.2,0,2.2,1,2.1,2.3l-0.9,17c-0.1,2-1.5,3.6-3.5,4l-25.8,5.2C686.5,115.7,686.3,115.5,686.4,115.2z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M529.9,115.2l-9.8-26.1c-0.4-1.1-1.5-1.8-2.6-1.8h-16
		c-1.2,0-2.2,1-2.1,2.3l0.9,17c0.1,2,1.5,3.6,3.5,4l25.8,5.2C529.8,115.7,530,115.5,529.9,115.2z"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M529.2,115.3c0,0,74.2,4.9,158,0"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M499.2,90.3c0,0,0-40-0.2-52"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M717,90.3c0,0,2.2-31,2.2-51"/>
</g>
<g id="Layer_4">
	<g>
		<rect x="457" y="700.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="305" height="740.3"/>
		<rect x="467.7" y="709.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="282.6" height="720.4"/>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M607,460.1h56c0,0,45,1,72,12c0,0,18,6,16,32l-1,163c0,0,2,15-8,16
				c0,0-18,4-131,5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M612.2,460.1h-56c0,0-45,1-72,12c0,0-18,6-16,32l1,163
				c0,0-2,15,8,16c0,0,20.8,4,133.8,5"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M610.5,468.9h53.8c0,0,37.2,0,63.4,10.1c0,0,17.1,6,15.1,24.1
				l-6,26.2c0,0-1,9.1-11.1,7c0,0-18.5-5.8-45.9-7.9c-0.9-0.1-1.9-0.1-2.8-0.1l-68.5-1"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M610.5,468.9h-55.7c0,0-37.2,0-63.4,10.1c0,0-17.1,6-15.1,24.1
				l6,26.2c0,0,1,9.1,11.1,7c0,0,18.6-5.9,46.2-8c0.7-0.1,1.5-0.1,2.2-0.1l66.6-1"/>
		</g>
		<g>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M610.9,472.6h52.7c0,0,36.4,0,62,8.9c0,0,16.7,5.3,14.8,21.4
				l-5.9,23.2c0,0-1,8-10.8,6.2c0,0-18.1-5.2-44.9-7c-0.9-0.1-1.8-0.1-2.7-0.1l-67-0.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M610.9,472.6h-54.5c0,0-36.4,0-62,8.9c0,0-16.7,5.3-14.8,21.4
				l5.9,23.2c0,0,1,8,10.8,6.2c0,0,18.2-5.2,45.2-7.1c0.7,0,1.5-0.1,2.2-0.1l65.2-0.9"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485,535.1v140c0,0-2,7-11,7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M734,535.1v140c0,0,2,7,11,7"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M485.3,676.1c0,0,119.7,14,249.7,1"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M472,523.1c0,0,5,11,7,22v111.7c0,0.2,0,0.4,0,0.6
			c-0.2,1.2,0,5.7-2.2,7.3c-0.5,0.4-1.1,0.4-1.7,0.1c-0.9-0.5-2.1-1.9-2.1-5.7C473,659.1,472.5,522.6,472,523.1z"/>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M747,523.1c0,0-5,11-7,22v111.7c0,0.2,0,0.4,0,0.6
			c0.2,1.2,0,5.7,2.2,7.3c0.5,0.4,1.1,0.4,1.7,0.1c0.9-0.5,2.1-1.9,2.1-5.7C746,659.1,746.6,522.6,747,523.1z"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="490" y1="685.1" x2="490" y2="700.1"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="730" y1="684.1" x2="730" y2="700.1"/>
		<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="521" y1="463.1" x2="696" y2="463.1"/>
		<rect x="458" y="505.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
		<rect x="751" y="506.1" fill="none" stroke="#000000" stroke-miterlimit="10" width="10" height="44"/>
		<g>
			<polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="424,526.1 436,517.1 448,517.1 460,526.1 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="459,529.1 425,529.1 424,526.1 460,526.1 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="443,529.1 474,579.1 477,579.1 477,578.1 446,529.1 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="449.1,529.2 468,546.1 468,542.1 453.2,529.1 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="478,575.1 478,579.1 474,579.1 474,584.1 478,584.1 
				478,588.1 481,588.1 481,575.1 			"/>
		</g>
		<g>
			<polyline fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="795,526.1 783,517.1 771,517.1 759,526.1 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="760,529.1 794,529.1 795,526.1 759,526.1 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="776,529.1 745,579.1 742,579.1 742,578.1 773,529.1 			
				"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="769.9,529.2 751,546.1 751,542.1 765.8,529.1 			"/>
			<polygon fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" points="741,575.1 741,579.1 745,579.1 745,584.1 741,584.1 
				741,588.1 738,588.1 738,575.1 			"/>
		</g>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M489.9,537.1"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M488.3,537c0,0,10.8,122.9,10,126c-1,4-13,13-13,13"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M730.3,537c0,0-10.8,122.9-10,126c1,4,13,13,13,13"/>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M498.3,663c0,0,104.1,13,222,0"/>
</g>
<g>
	<g>
		<g>
			<rect x="42.5" y="680.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M137.3,615.8c-2-24-18-43-38-51.8v-55.4h-15h-4l-5-32c0-2,3-2,3-2
				h29v6h3l3-3h72l30,9c12,5,52,24,52,24l25,14l19,12c13,9,13,27,13,27v116.1c0,11-12,12.6-12,12.6c-1.4,0.3-13.7,0.4-15.2,0.4
				H189.3c-2-1-3-4-3-4h-20c-2,0-29-4-29-4V615.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M76.3,684.9c17,0,51-8,61-46"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M76.3,679.9c12.2-0.8,23.6-5.5,32.5-13.9
				c16.2-15.3,23.4-38.3,17.8-60.1c-5.4-20.7-21.3-38.2-42.3-43.1"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.3" y1="692.5" x2="76.3" y2="679.4"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.3" y1="508.7" x2="84.3" y2="563.3"/>
			<rect x="63.3" y="516.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="70.3" y1="560.8" x2="70.3" y2="516.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M84.2,508.8c0,0-5.9,0-6.9,0c-0.9,0-6-9.2-6.9-10.8
				c-0.1-0.2-0.1-0.3-0.1-0.5v-19.7c0-0.6,0.5-1.1,1.1-1.1h3.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M84.3,559.8c8,1,15,4,15,4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M255.8,528.8l-10-3l-51-16.7c-0.6-0.2-1.3-0.3-2-0.3h-94"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M297.4,565.9L297.4,565.9c0-4.3-1.8-8.5-5-11.3
				c-4.8-4.1-11-7.7-11-7.7l-40-16l-29.7-8.7c-3.5-1.1-7.2-1.9-10.9-2.4c-4.9-0.7-5.8-0.3-8,0.1c-2.3,0.4-4,2.4-4.1,4.7
				c-0.1,3.9,1.1,7.6,1.5,8.8c0.1,0.3,0.3,0.7,0.4,1.1l2.4,7.2c0.3,0.7,0.5,1.4,0.7,2.2l13.7,50l16.5,46.7c0.3,0.8,0.7,1.7,1.1,2.5
				l6.6,13.3c0.7,1.3,1.5,2.5,2.5,3.6c1.4,1.5,2.9,2.8,4.4,3.8c3.6,2.6,7.9,4.1,12.3,4.7l10.5,1.4h25c10,0,12-10,12-10v-54.1
				L297.4,565.9z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M294.1,567.7c-0.3-6.3-4.1-12-9.7-14.9l-15.3-8.1l-39-14l-29-8
				c-3.1-0.6-5.2-0.6-6.7-0.3c-2.1,0.5-3.3,2.6-2.8,4.7c1.1,4.1,3.6,11.7,3.6,11.7l12,43l15,46l10.5,23.3
				c2.7,5.9,7.5,10.4,13.6,12.5c8.1,2.8,17,3.2,17,3.2h22.4c2.1,0,4.1-0.7,5.7-2.1c0.7-0.7,1.3-1.3,1.7-1.9c0.8-1,1.2-2.4,1.2-3.7
				v-90.4C294.3,568.4,294.3,568.1,294.1,567.7z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M211.3,485.7l2,11c2,9,14,14,14,14l21,9l54,26c4,2,6-1,6-1l5-7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M214.4,486.7l0.4,4c0.5,4,2.1,7.8,4.8,10.8
				c4.7,5.2,8.2,6.3,8.2,6.3l20.3,8.7l52.1,25.1c3.9,1.9,5.8-1,5.8-1l3.3-4.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M137.3,657l27.2,18.5c0.5,0.3,1.1,0.5,1.7,0.5h124.1
				c15,0,13.7-12.9,14-14.8c0-0.2,0-0.4,0-0.6v-91.7c0-1.5-0.1-2.8-0.2-4.1c-0.8-6.7-4.5-12.8-10-16.7l-8.8-6.3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M193.3,508.7c2-12-9-31-9-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M181.3,508.7c0-13-10-31-10-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M145.3,508.7c1-18-9-31-9-31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M103.3,508.7c-2-4-2.6-10.2-2.9-12.4c-0.1-0.4-0.1-0.8-0.1-1.2
				v-20.4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M115.3,508.6c-4-6-5.3-13.4-5.8-16.5c-0.1-0.8-0.2-1.6-0.2-2.5
				v-8.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M300.3,544.8c0,0,16,9,17,34l1,95.1c0,0,0,12-11,12
				c-10,0-140,0-140,0v-10c0-39-5-62.1-5-62.1c-6-40-45-105.1-45-105.1"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="167.3" y1="685.9" x2="167.3" y2="688.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M114.3,508.7c31,52.1,41,85.1,41,85.1c11,41,9,81.6,9,81.6v13"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M111.3,508.7c20,35,29,57.1,29,57.1c19,42,19,76.1,19,76.1v30v14
				l-22-3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M171.9,639.9c2.2-3.8,8.2,1.4,11.4,4.7c1.3,1.4,2.1,3.2,2.1,5.2
				v16.8c0,1.8-1.5,3.3-3.3,3.3h-7.5c-1.8,0-3.3-1.5-3.3-3.3v-24.7C171.3,641.2,171.4,640.5,171.9,639.9z"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="178.3" y1="669.9" x2="178.3" y2="640.4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M82.3,479.7h12c0.5,0,1-0.5,1-1v-1c0-0.6-0.5-1-1-1h-12
				c-0.6,0-1,0.4-1,1v1C81.3,479.2,81.7,479.7,82.3,479.7z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M246.3,529.8l-10.9,8.1c-0.4,0.3-0.2,0.9,0.3,0.9H289
				c0.7,0,1-0.9,0.4-1.3l-11.1-7.7H246.3z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M235.4,538.8l1.7,2.6c0.1,0.2,0.5,0.4,0.8,0.4h49
				c0.3,0,0.5-0.2,0.7-0.4l1.7-2.6H235.4z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M239.3,534.8H230c-0.5,0-1,0.2-1.3,0.5l-20,18.1
				c-0.4,0.2-0.8,0.4-1.2,0.4h-27.3v-3h26.2c0.6,0,1.1-0.2,1.5-0.6l19.4-18c0.3-0.2,0.7-0.4,1.1-0.4h14.5h0.4L239.3,534.8z"/>
			<rect x="175.3" y="547.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M284.3,533.8h16l5.3,3.6c0.5,0.3,0.7,0.7,0.7,1.2v48.2h2v-47.9
				c0-0.8-0.3-1.7-1-2.3c-2.3-2.1-7-4.8-7-4.8h-19L284.3,533.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M306.3,538.8"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="113.3" y1="942.1" x2="113.3" y2="1210.5"/>
			<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="113.3,1308.5 113.3,1412.4 132.3,1412.4 132.3,674.5 
				113.3,674.5 113.3,843.2 			"/>
			<rect x="106.3" y="1412.4" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="136.8" y1="673.9" x2="131.8" y2="673.9"/>
			<rect x="67.3" y="965.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="220.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="84.3" y1="1186.2" x2="84.3" y2="965.5"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="72.3" y1="1185.7" x2="72.3" y2="965.5"/>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="112.9" y1="1030" x2="67.7" y2="1030"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="113.7" y1="1032.8" x2="67.5" y2="1032.8"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="113.7" y1="1112.9" x2="67.7" y2="1113.1"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="113.7" y1="1115.9" x2="67.7" y2="1115.9"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="112.7" y1="1174" x2="66.7" y2="1174.2"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="112.7" y1="1177" x2="66.7" y2="1177"/>
			</g>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.3" y1="1412.4" x2="76.3" y2="1329.3"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.3" y1="1191" x2="76.3" y2="1186.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="74.3" y1="966" x2="74.3" y2="961.2"/>
			<rect x="72.3" y="1412.4" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.3" y1="1402.4" x2="113.3" y2="1402.4"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="83.3" y1="1412.4" x2="83.3" y2="1402.4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M65.8,1328.3c15.7,0,30.9-5.6,42.9-15.7
				c21.9-18.4,30.1-49.6,20.7-76.6c-9.3-26.7-34.7-45.6-63.1-45.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M66.3,1320.3c14-0.8,27-5.3,38-14.2c19.2-15.6,26.1-42.7,18.5-66.1
				c-7.9-24.5-31.1-40.7-56.5-41.8"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="66.3" y1="1198.7" x2="66.3" y2="1189.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="65.3" y1="961.7" x2="65.3" y2="952.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="65.3" y1="831.7" x2="65.3" y2="822.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="66.3" y1="1319.8" x2="66.3" y2="1328.3"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="65.3" cy="1258.3" rx="54" ry="55.1"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="65.3" cy="1259.1" rx="31" ry="30.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M64.8,961.3c15.7,0,30.9-5.6,42.9-15.7
				c21.9-18.4,30.1-49.6,20.7-76.6c-9.3-26.7-34.7-45.6-63.1-45.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M65.3,953.3c14-0.8,27-5.3,38-14.2c19.2-15.6,26.1-42.7,18.5-66.1
				c-7.9-24.5-31.1-40.7-56.5-41.8"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="64.3" cy="891.3" rx="54" ry="55.1"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="64.3" cy="892.1" rx="31" ry="30.8"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="66.3" cy="619.8" rx="54" ry="55.1"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="66.3" cy="620.6" rx="31" ry="30.8"/>
			<rect x="43.3" y="1322.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
			<rect x="42.3" y="955.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
			<rect x="132.3" y="699.9" fill="none" stroke="#000000" stroke-miterlimit="10" width="285.7" height="739.5"/>
			<rect x="139.1" y="705.9" fill="none" stroke="#000000" stroke-miterlimit="10" width="273" height="727.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M65.6,823.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M65.6,831.5"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M308.7,692.7h105.6c1.7,0,3-1.6,2.6-3.3
			c-4.3-17.6-25.5-100.4-29.2-111.4c-4.5-13.4-67.5-30.4-67.5-30.4"/>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M65.6,812.4V696.5c0-1.9,1.6-3.5,3.5-3.5H103c1.9,0,3.5,1.6,3.5,3.5
		v115.9c0,1.9-1.6,3.5-3.5,3.5H69.1C67.2,816,65.6,814.4,65.6,812.4z"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="106.6" y1="711" x2="65.6" y2="711"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="107.1" y1="732" x2="66.1" y2="732"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="107.1" y1="753" x2="66.1" y2="753"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="107.1" y1="773" x2="66.1" y2="773"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="107.1" y1="795" x2="66.1" y2="795"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="76.6" y1="816.5" x2="76.6" y2="824.5"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="106.6" y1="725" x2="113.6" y2="725"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="106.1" y1="783" x2="113.1" y2="783"/>
</g>
<g>
	<g>
		<g>
			<rect x="1147.9" y="680.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="34" height="3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1087.1,615.8v69.1c0,0-27,4-29,4h-20c0,0-1,3-3,4H927.3
				c-1.5,0-13.8-0.1-15.2-0.4c0,0-12-1.6-12-12.6V563.8c0,0,0-18,13-27l19-12l25-14c0,0,40-19,52-24l30-9h72l3,3h3v-6h29
				c0,0,3,0,3,2l-5,32h-4h-15v55.4C1105.1,572.8,1089.1,591.8,1087.1,615.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1087.1,638.9c10,38,44,46,61,46"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1140.1,562.8c-21,4.9-36.9,22.4-42.3,43.1
				c-5.6,21.7,1.6,44.7,17.8,60.1c8.9,8.4,20.3,13.1,32.5,13.9"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1148.1" y1="679.4" x2="1148.1" y2="692.5"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1140.1" y1="563.3" x2="1140.1" y2="508.7"/>
			<rect x="1140.1" y="516.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="21" height="44"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1154.1" y1="516.7" x2="1154.1" y2="560.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1149.1,476.7h3.9c0.6,0,1.1,0.5,1.1,1.1v19.7c0,0.2,0,0.3-0.1,0.5
				c-0.9,1.6-6,10.8-6.9,10.8c-1,0-6.9,0-6.9,0"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1125.1,563.8c0,0,7-3,15-4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1125.6,508.7h-94c-0.7,0-1.4,0.1-2,0.3l-51,16.7l-10,3"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M926.1,605.8v54.1c0,0,2,10,12,10h25l10.5-1.4
				c4.4-0.6,8.7-2.1,12.3-4.7c1.5-1,3-2.3,4.4-3.8c1-1.1,1.8-2.3,2.5-3.6l6.6-13.3c0.4-0.8,0.8-1.7,1.1-2.5l16.5-46.7l13.7-50
				c0.2-0.8,0.4-1.5,0.7-2.2l2.4-7.2c0.1-0.4,0.3-0.8,0.4-1.1c0.4-1.2,1.6-4.9,1.5-8.8c-0.1-2.3-1.8-4.3-4.1-4.7
				c-2.2-0.4-3.1-0.8-8-0.1c-3.7,0.5-7.4,1.3-10.9,2.4l-29.7,8.7l-40,16c0,0-6.2,3.6-11,7.7c-3.2,2.8-5,7-5,11.3l0,0L926.1,605.8z"
				/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M930.1,568.8v90.4c0,1.3,0.4,2.7,1.2,3.7c0.4,0.6,1,1.2,1.7,1.9
				c1.6,1.4,3.6,2.1,5.7,2.1h22.4c0,0,8.9-0.4,17-3.2c6.1-2.1,10.9-6.6,13.6-12.5l10.5-23.3l15-46l12-43c0,0,2.5-7.6,3.6-11.7
				c0.5-2.1-0.7-4.2-2.8-4.7c-1.5-0.3-3.6-0.3-6.7,0.3l-29,8l-39,14l-15.3,8.1c-5.6,2.9-9.4,8.6-9.7,14.9
				C930.1,568.1,930.1,568.4,930.1,568.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M911.1,537.8l5,7c0,0,2,3,6,1l54-26l21-9c0,0,12-5,14-14l2-11"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M915.1,535.8l3.3,4.9c0,0,1.9,2.9,5.8,1l52.1-25.1l20.3-8.7
				c0,0,3.5-1.1,8.2-6.3c2.7-3,4.3-6.8,4.8-10.8l0.4-4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M939.1,541.8l-8.8,6.3c-5.5,3.9-9.2,10-10,16.7
				c-0.1,1.3-0.2,2.6-0.2,4.1v91.7c0,0.2,0,0.4,0,0.6c0.3,1.9-1,14.8,14,14.8h124.1c0.6,0,1.2-0.2,1.7-0.5l27.2-18.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1040.1,477.7c0,0-11,19-9,31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1053.1,477.7c0,0-10,18-10,31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1088.1,477.7c0,0-10,13-9,31"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1124.1,474.7v20.4c0,0.4,0,0.8-0.1,1.2c-0.3,2.2-0.9,8.4-2.9,12.4
				"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1115.1,480.7v8.9c0,0.9-0.1,1.7-0.2,2.5
				c-0.5,3.1-1.8,10.5-5.8,16.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1107.1,508.7c0,0-39,65.1-45,105.1c0,0-5,23-5,62.1v10
				c0,0-130,0-140,0c-11,0-11-12-11-12l1-95.1c1-25,17-34,17-34"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1057.1" y1="688.9" x2="1057.1" y2="685.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1060.1,688.4v-13c0,0-2-40.5,9-81.6c0,0,10-33,41-85.1"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1087.1,682.9l-22,3v-14v-30c0,0,0-34,19-76.1c0,0,9-22,29-57.1"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1053.1,641.9v24.7c0,1.8-1.5,3.3-3.3,3.3h-7.5
				c-1.8,0-3.3-1.5-3.3-3.3v-16.8c0-2,0.8-3.8,2.1-5.2c3.2-3.3,9.2-8.5,11.4-4.7C1053,640.5,1053.1,641.2,1053.1,641.9z"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1046.1" y1="640.4" x2="1046.1" y2="669.9"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1143.1,478.7v-1c0-0.6-0.4-1-1-1h-12c-0.5,0-1,0.4-1,1v1
				c0,0.5,0.5,1,1,1h12C1142.7,479.7,1143.1,479.2,1143.1,478.7z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M946.1,529.8l-11.1,7.7c-0.6,0.4-0.3,1.3,0.4,1.3h53.3
				c0.5,0,0.7-0.6,0.3-0.9l-10.9-8.1H946.1z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M935.1,538.8l1.7,2.6c0.2,0.2,0.4,0.4,0.7,0.4h49
				c0.3,0,0.7-0.2,0.8-0.4l1.7-2.6H935.1z"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M981.1,531.8h0.4H996c0.4,0,0.8,0.2,1.1,0.4l19.4,18
				c0.4,0.4,0.9,0.6,1.5,0.6h26.2v3h-27.3c-0.4,0-0.8-0.2-1.2-0.4l-20-18.1c-0.3-0.3-0.8-0.5-1.3-0.5h-9.3L981.1,531.8z"/>
			<rect x="1034.1" y="547.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="9"/>
			<path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M943.1,531.8h-19c0,0-4.7,2.7-7,4.8c-0.7,0.6-1,1.5-1,2.3v47.9
				h2v-48.2c0-0.5,0.2-0.9,0.7-1.2l5.3-3.6h16L943.1,531.8z"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M918.1,538.8"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111.1" y1="1210.5" x2="1111.1" y2="942.1"/>
			<polyline fill="none" stroke="#000000" stroke-miterlimit="10" points="1111.1,843.2 1111.1,674.5 1092.1,674.5 1092.1,1412.4 
				1111.1,1412.4 1111.1,1308.5 			"/>
			<rect x="1092.1" y="1412.4" fill="none" stroke="#000000" stroke-miterlimit="10" width="26" height="26"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1092.6" y1="673.9" x2="1087.6" y2="673.9"/>
			<rect x="1135.1" y="965.5" fill="none" stroke="#000000" stroke-miterlimit="10" width="22" height="220.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1140.1" y1="965.5" x2="1140.1" y2="1186.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1152.1" y1="965.5" x2="1152.1" y2="1185.7"/>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1156.7" y1="1030" x2="1111.5" y2="1030"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1156.9" y1="1032.8" x2="1110.7" y2="1032.8"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1156.7" y1="1113.1" x2="1110.7" y2="1112.9"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1156.7" y1="1115.9" x2="1110.7" y2="1115.9"/>
			</g>
			<g>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1157.7" y1="1174.2" x2="1111.7" y2="1174"/>
				<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1157.7" y1="1177" x2="1111.7" y2="1177"/>
			</g>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1148.1" y1="1329.3" x2="1148.1" y2="1412.4"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1148.1" y1="1186.2" x2="1148.1" y2="1191"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1150.1" y1="961.2" x2="1150.1" y2="966"/>
			<rect x="1136.1" y="1412.4" fill="none" stroke="#000000" stroke-miterlimit="10" width="16" height="10"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1111.1" y1="1402.4" x2="1148.1" y2="1402.4"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1141.1" y1="1402.4" x2="1141.1" y2="1412.4"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1158.1,1190.2c-28.4,0.2-53.8,19.1-63.1,45.8
				c-9.4,27-1.2,58.2,20.7,76.6c12,10.1,27.2,15.7,42.9,15.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1158.1,1198.2c-25.4,1.1-48.6,17.3-56.5,41.8
				c-7.6,23.3-0.7,50.5,18.5,66.1c11,8.9,24,13.4,38,14.2"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1158.1" y1="1189.7" x2="1158.1" y2="1198.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1159.1" y1="952.7" x2="1159.1" y2="961.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1159.1" y1="822.7" x2="1159.1" y2="831.7"/>
			<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1158.1" y1="1328.3" x2="1158.1" y2="1319.8"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1159.1" cy="1258.3" rx="54" ry="55.1"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1159.1" cy="1259.1" rx="31" ry="30.8"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1159.1,823.2c-28.4,0.2-53.8,19.1-63.1,45.8
				c-9.4,27-1.2,58.2,20.7,76.6c12,10.1,27.2,15.7,42.9,15.7"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1159.1,831.2c-25.4,1.1-48.6,17.3-56.5,41.8
				c-7.6,23.3-0.7,50.5,18.5,66.1c11,8.9,24,13.4,38,14.2"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1160.1" cy="891.3" rx="54" ry="55.1"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1160.1" cy="892.1" rx="31" ry="30.8"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1158.1" cy="619.8" rx="54" ry="55.1"/>
			<ellipse fill="none" stroke="#000000" stroke-miterlimit="10" cx="1158.1" cy="620.6" rx="31" ry="30.8"/>
			<rect x="1158.1" y="1322.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
			<rect x="1159.1" y="955.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="23" height="3"/>
			<rect x="806.3" y="699.9" fill="none" stroke="#000000" stroke-miterlimit="10" width="285.7" height="739.5"/>
			<rect x="812.3" y="705.9" fill="none" stroke="#000000" stroke-miterlimit="10" width="273" height="727.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1158.8,823.5"/>
			<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1158.8,831.5"/>
		</g>
		<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M904.2,547.6c0,0-63,17-67.5,30.4c-3.7,11-24.9,93.8-29.2,111.4
			c-0.4,1.7,0.9,3.3,2.6,3.3h105.6"/>
	</g>
	<path fill="none" stroke="#000000" stroke-miterlimit="10" d="M1158.8,812.4V696.5c0-1.9-1.6-3.5-3.5-3.5h-33.9
		c-1.9,0-3.5,1.6-3.5,3.5v115.9c0,1.9,1.6,3.5,3.5,3.5h33.9C1157.2,816,1158.8,814.4,1158.8,812.4z"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1117.8" y1="711" x2="1158.8" y2="711"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1117.3" y1="732" x2="1158.3" y2="732"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1117.3" y1="753" x2="1158.3" y2="753"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1117.3" y1="773" x2="1158.3" y2="773"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1117.3" y1="795" x2="1158.3" y2="795"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1147.8" y1="816.5" x2="1147.8" y2="824.5"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1117.8" y1="725" x2="1110.8" y2="725"/>
	<line fill="none" stroke="#000000" stroke-miterlimit="10" x1="1118.3" y1="783" x2="1111.3" y2="783"/>
</g>
            </>
        ),
    },

}  